import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';

const IconViewDetails: React.FC = () => (
  <Icon
    type={IconType.fileCircleInfo}
    size={IconSize.lg}
    family={IconFamily.solid}
    color={IconColor.primary}
  />
);
export default IconViewDetails;
