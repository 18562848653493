import * as React from 'react';
import type { SafeNetworkSettingsInfoModalProps } from '../../containers/Modal/SafeNetworkSettingsInfoModalContainer';
import {
  AlertBox,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
  Icon,
  IconType,
  Layout,
  Modal,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import { SafeNetworkInfoModalProps } from '../../containers/Modal/SafeNetworkInfoModalContainer';

const SafeNetworkSettingsModal = ({
  onClose,
  onGoToSettings,
}: SafeNetworkSettingsInfoModalProps &
  SafeNetworkInfoModalProps): JSX.Element => {
  const buttons = [
    <Button
      key="par-safe-network-settings-modal__close"
      onClick={onClose}
      size={ButtonSize.medium}
    >
      {t('OK')}
    </Button>,
  ];
  if (onGoToSettings) {
    buttons.push(
      <Button
        key="par-safe-network-settings-modal__go-to-settings"
        onClick={onGoToSettings}
        size={ButtonSize.medium}
        color={ButtonColor.secondary}
        buttonType={ButtonType.plain}
      >
        {t('Go to settings')}
      </Button>
    );
  }

  return (
    <Modal
      testId="safeNetworkSettingsInfoModal"
      title={t('School Safe Network')}
      header={{
        icon: <Icon type={IconType.infoCircle} color="secondary" size="lg" />,
      }}
      onClickClose={onClose}
      width={modalDimensions.regular.width}
      buttons={buttons}
    >
      <ModalStyledText>
        {t(
          'When a device is connected to a Safe Network, it will benefit from improved security and better connectivity. Coming soon for iOS.'
        )}
      </ModalStyledText>
      <Layout marginTop="32">
        <AlertBox type="info" centered>
          {t('Your rules will be temporarily overridden by school protection.')}
        </AlertBox>
      </Layout>
    </Modal>
  );
};

export default SafeNetworkSettingsModal;
