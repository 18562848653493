import { Record } from 'immutable';
import { PanicRulesContactType, PanicRulesContactStatus } from '../constants';

export type ContactRecord = {
  id: number;
  name: string;
  contact: string;
  type: PanicRulesContactType;
  status: PanicRulesContactStatus;
  phone?: string;
};
export const ContactRecord = Record<
  ContactRecord,
  {
    fromPayload: Record.Parser<any, ContactRecord>;
    serialize: Record.Serializer<ContactRecord, any>;
  }
>({
  id: -1,
  name: '',
  contact: '',
  type: PanicRulesContactType.Phone,
  status: PanicRulesContactStatus.Verified,
});

ContactRecord.fromPayload = (payload: {
  [key: string]: any;
  type: PanicRulesContactType;
  status: PanicRulesContactStatus;
}) => {
  return ContactRecord({
    ...payload,
    type: payload.type,
    status: payload.status,
  });
};

ContactRecord.serialize = record => {
  return {
    id: record.id,
    name: record.name,
    contact: record.contact,
    type: record.type,
    status: record.status,
  };
};

export default ContactRecord;
