import * as React from 'react';
import { connect } from 'react-redux';
import ActivitySummaryPage from '../../components/ActivitySummary/ActivitySummaryPage';
import State, { Dispatch, RouterParams } from '../../store/state';
import {
  getProfileOrDefault,
  getActiveDateRange,
  getLicense,
} from '../../selectors';
import { SummaryDateRanges } from '../../constants';
import { selectedNewDateRangeForProfile } from '../../actions/ProfileActions';
import { track, Events } from '../../helpers/analytics';
import { ActivityType } from '../../components/ActivitySummary/sharedModels';
import { isFree } from '../../records/license';
import flags, { Experiment } from '../../sideEffects/flags';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import ScreenTimeDetailsPageContainer from './ScreenTimeDetailsPageContainer';

const mapStateToProps = (
  state: State,
  { params: { profileId }, type, ...overrides }
) => ({
  type,
  profile: getProfileOrDefault(state, parseInt(profileId, 10)),
  activeDateRange: getActiveDateRange(state),
  ...overrides,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId }, ...overrides }
) => ({
  onClickEditProfile: () => {
    track(Events.ClickedProfileEdit);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onSelectDateRange: (range: SummaryDateRanges) =>
    dispatch(selectedNewDateRangeForProfile(range, profileId)),
  ...overrides,
});

const ActivitySummaryPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitySummaryPage);

export const TopActivitySummaryPageContainer = props => (
  <ActivitySummaryPageContainer
    {...props}
    type={ActivityType.AppsAndWebActivity}
  />
);

export const BlockedWebsActivitySummaryPageContainer = props => (
  <ActivitySummaryPageContainer {...props} type={ActivityType.BlockedWebs} />
);

export const ScreenTimeActivitySummaryPageContainer = props => (
  <ScreenTimeDetailsPageContainer {...props} />
);

export const CallsAndSmsActivitySummaryPageContainer = props => (
  <ActivitySummaryPageContainer {...props} type={ActivityType.CallsAndSms} />
);

export const SearchActivitySummaryPageContainer = props => (
  <ActivitySummaryPageContainer
    {...props}
    type={
      flags.questionableWebSearches.getValue() === Experiment.VariantA
        ? ActivityType.QuestionableWebSearches
        : ActivityType.Search
    }
  />
);

(CallsAndSmsActivitySummaryPageContainer as any).load =
  (params: RouterParams) => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    if (isFree(getLicense(getState()).type)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${params.profileId}`,
        })
      );
    }
  };

export default ActivitySummaryPageContainer;
