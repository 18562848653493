import { EventType } from '../../constants';
import { ActivityEventFilters } from './types';

export const eventTypeToActivityEventFilterMapper = {
  [EventType.Web]: ActivityEventFilters.Web,
  [EventType.App]: ActivityEventFilters.Apps,
  [EventType.Search]: ActivityEventFilters.Searches,
  [EventType.Location]: ActivityEventFilters.Locations,
  [EventType.LocationLast]: ActivityEventFilters.Locations,
  [EventType.LocationUnvailable]: ActivityEventFilters.Locations,
  [EventType.Calls]: ActivityEventFilters.CallsAndSMS,
  [EventType.Sms]: ActivityEventFilters.CallsAndSMS,
} as const;
