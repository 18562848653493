import { BaseThunk } from '../../store/state';
import { getProfile } from '../../selectors';
import { profileHasBeenInSafeNetwork } from '../../selectors/safeNetwork';
import { fetchSafeNetworksSettings } from '../safeNetworksSettings';

/**
 * Fetches safe network settings only if the profile has been into a safe network.
 *
 * This is an optimization to avoid fetching the network settings for any profile when loading
 * the dashboard, as the only place used in the dashboard is for a ProfileNotificationTopBar.
 * The notification renders under two conditions are met. This checks for the first condition
 * before getting the settings, that are used to check for the second condition.
 */
export const fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork =
  (profileId: number): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profile = getProfile(state, profileId);
    return profileHasBeenInSafeNetwork(state, profileId)
      ? dispatch(fetchSafeNetworksSettings(profile.uid))
      : Promise.resolve();
  };
