import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Layout,
  Icon,
  IconSize,
  IconType,
  TransparentButton,
} from 'styleguide-react';
import * as qinit from '../../qinit';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import illustration from '../../assets/base/images/v3/flyovers/illustration_wrong_app.svg';
import PreOnboardingBase from './PreOnboardingBase';

const WrongAppInstalled: React.FunctionComponent<{
  onClickBack: () => any;
  onClickGetKidsApp: () => any;
}> = ({ onClickBack, onClickGetKidsApp }) => (
  <PageLayout
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
        hideHeader: true,
        fullHeightCard: true,
      },
      {
        screenSize: ScreenSize.Mobile,
        columns: 1,
        hideHeader: true,
        useNonFlexLayout: true,
      },
    ]}
  >
    <FlexLayout flexGrow="1" mainaxis="column" crossaxisAlignment="flex-start">
      <TransparentButton onClick={onClickBack}>
        <Icon type={IconType.arrowLeft} size={IconSize.lg} />
      </TransparentButton>
    </FlexLayout>
    <Layout>
      <PreOnboardingBase
        alignement="space-between"
        descriptionAlignement="flex-start"
        description={
          <FlexLayout mainaxis="column" maxWidth="400">
            <p>
              {t(
                "There are two {{shortName}} apps: one for kids' devices, and one for parents' devices."
              )}
            </p>
            <p>
              {t(
                'If this is your kid’s device and you want to protect it with {{shortName}}, {1}download the {{correctAppName}} instead.{/1}',
                {
                  correctAppName: `Kids App ${qinit.tenant.common.dashboard.shortname}`,
                  1: str => <strong>{str}</strong>,
                }
              )}
            </p>
          </FlexLayout>
        }
        title={t('Get the Kids App')}
        illustrationSrc={illustration}
        button={
          <Button size={ButtonSize.big} onClick={onClickGetKidsApp} block>
            {t('Get Kids App')}
          </Button>
        }
      />
    </Layout>
  </PageLayout>
);

export default WrongAppInstalled;
