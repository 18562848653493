import { fromJS, List, NonEmptyMapRecord } from 'immutable';
import api from '../api';
import {
  NotificationRecord,
  NotificationsRecord,
  NotificationFilter,
  fromFilterToQapiParams,
} from '../records/notification';
import flags from '../sideEffects/flags';
import { getAccount } from '../selectors/account';
import {
  hasMoreNotifications,
  getNotificationsPage,
  hasNotifications,
  getNotificationsFilter,
} from '../selectors/notificationCenter';
import { getProfileId } from '../selectors/profile';
import { getProfile } from '../selectors/profileRaw';
import { executeIfAllFlags } from '../helpers/flagInterceptors';
import { showErrorToast } from '../containers/AccountSettings/helpers';
import { NotificationType } from '../constants';

export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';
export const RECEIVE_MORE_NOTIFICATIONS = 'RECEIVE_MORE_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const RECEIVE_NEW_COUNT = 'RECEIVE_NEW_COUNT';
export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED';
export const SET_NOTIFICATION_FILTER = 'SET_NOTIFICATION_FILTER';

const defaultBatchNotification = 20;

export const defaultState = {
  newCount: 0,
  notifications: List<NotificationRecord>(),
  isFetching: true,
  hasMoreNotifications: true,
  filter: {},
  isEmpty: false,
  page: 0,
};

export const fetchingNotifications = () => ({ type: FETCHING_NOTIFICATIONS });
export const receiveNotifications = payload => ({
  type: RECEIVE_NOTIFICATIONS,
  payload: NotificationsRecord.fromPayload(payload),
});
export const receiveNewCount = payload => ({
  type: RECEIVE_NEW_COUNT,
  payload: NotificationsRecord.fromPayload(payload).count,
});

export const setFilter = (filter: NotificationFilter) => ({
  type: SET_NOTIFICATION_FILTER,
  payload: filter,
});

export const fetchNotificationFailed = () => ({
  type: FETCH_NOTIFICATIONS_FAILED,
});

export function receiveMoreNotifications(payload = []) {
  return {
    type: RECEIVE_MORE_NOTIFICATIONS,
    payload: NotificationsRecord.fromPayload(payload),
  };
}

export default function notificationCenter(
  state: NonEmptyMapRecord<{
    newCount: number;
    notifications: List<NotificationRecord>;
    isFetching: boolean;
    hasMoreNotifications: boolean;
    filter: NotificationFilter;
    isEmpty: boolean;
    page: number;
  }> = fromJS(defaultState),
  action
) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_FAILED:
      return state.set('isFetching', false);
    case FETCHING_NOTIFICATIONS:
      return state.set('isFetching', true);
    case RECEIVE_NOTIFICATIONS:
      return state.merge({
        notifications: action.payload.items,
        isFetching: false,
        hasMoreNotifications: true,
        isEmpty: action.payload.items.size === 0,
        page: 1,
      });
    case RECEIVE_MORE_NOTIFICATIONS: {
      const currPage = state.get('page');
      const updatedNotif = state
        .get('notifications')
        .concat(action.payload.items);
      const hasMore =
        action.payload.count !== 0 && updatedNotif.size < action.payload.count;
      return state.merge({
        notifications: updatedNotif,
        isFetching: false,
        hasMoreNotifications: hasMore,
        page: hasMore ? currPage + 1 : currPage,
      });
    }
    case RECEIVE_NEW_COUNT:
      return state.set('newCount', action.payload);
    case SET_NOTIFICATION_FILTER:
      return state
        .merge({
          newCount: 0,
          notifications: List(),
          isEmpty: false,
          page: 0,
        })
        .set('filter', action.payload);
    default:
      return state;
  }
}

export const setFilterWithProfileUid =
  (type: NotificationType) => (dispatch, getState) => {
    const state = getState();
    const profileId = getProfileId(state);
    const profile = getProfile(state, profileId);
    dispatch(
      setFilter({
        type,
        profileUid: profile?.uid || undefined,
      })
    );
  };

export const fetchNewCount = executeIfAllFlags([flags.insights], () =>
  Promise.resolve()
)(() => (dispatch, getState) => {
  const state = getState();
  const filter = getNotificationsFilter(state);
  const { uid: accountUid } = getAccount(state);
  return api.notificationCenter
    .get(
      { accountUid },
      {
        ...fromFilterToQapiParams(filter),
        not_seen_from_profile: true,
        limit: 0,
      }
    )
    .then(result => {
      dispatch(receiveNewCount(result));
    })
    .catch(err => {
      dispatch(showErrorToast(err));
    });
});

export const fetchMoreNotifications = executeIfAllFlags([flags.insights], () =>
  Promise.resolve()
)(() => (dispatch, getState) => {
  const state = getState();
  const { uid: accountUid } = getAccount(state);
  const filter = getNotificationsFilter(state);
  const offset = getNotificationsPage(state) * defaultBatchNotification;
  const isEmpty = !hasNotifications(state);
  const hasMore = hasMoreNotifications(state);
  if (!hasMore || isEmpty) {
    return Promise.resolve();
  }
  dispatch(fetchingNotifications());
  return api.notificationCenter
    .get(
      { accountUid },
      {
        ...fromFilterToQapiParams(filter, offset),
        limit: defaultBatchNotification,
      }
    )
    .then(json => {
      dispatch(receiveMoreNotifications(json));
    })
    .catch(err => {
      dispatch(fetchNotificationFailed());
      dispatch(showErrorToast(err));
    });
});

export const fetchNotifications = executeIfAllFlags([flags.insights], () =>
  Promise.resolve()
)(() => (dispatch, getState) => {
  const state = getState();
  const { uid: accountUid } = getAccount(state);
  const filter = getNotificationsFilter(state);
  dispatch(fetchingNotifications());
  return api.notificationCenter
    .get(
      { accountUid },
      {
        ...fromFilterToQapiParams(filter),
        limit: defaultBatchNotification,
      }
    )
    .then(result => {
      dispatch(receiveNotifications(result));
    })
    .catch(err => {
      dispatch(fetchNotificationFailed());
      dispatch(showErrorToast(err));
    });
});
