import { NonEmptyMapRecord, fromJS, List } from 'immutable';
import * as camelize from 'camelize';
import { normalize, Schema, arrayOf } from 'normalizr';
import * as R from 'ramda';
import api from '../api';
import { ParentDeviceRecord } from '../records';

const REQUEST_PARENT_DEVICES = 'REQUEST_PARENT_DEVICES';
const RECEIVE_PARENT_DEVICES = 'RECEIVE_PARENT_DEVICES';

export default function parentDevices(
  state: NonEmptyMapRecord<{
    result: List<string>;
    isFetching: boolean;
    lastUpdated: string;
  }> = fromJS({
    result: [],
    isFetching: false,
    lastUpdated: '',
  }),
  action
) {
  switch (action.type) {
    case REQUEST_PARENT_DEVICES:
      return state.set('isFetching', true);
    case RECEIVE_PARENT_DEVICES:
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
        result: action.payload.result,
      });
    default:
      return state;
  }
}

export function requestParentDevices() {
  return {
    type: REQUEST_PARENT_DEVICES,
  };
}

export function receiveParentDevices(json) {
  const {
    result,
    entities: { parentDevices },
  } = normalize(camelize(json), arrayOf(new Schema('parentDevices')));

  return {
    type: RECEIVE_PARENT_DEVICES,
    payload: {
      result,
      records: {
        parentDevices: parentDevices
          ? R.map(ParentDeviceRecord.fromPayload, parentDevices)
          : [],
      },
    },
    receivedAt: Date.now(),
  };
}

export function fetchParentDevices() {
  return dispatch => {
    dispatch(requestParentDevices());
    return api.parentDevices
      .get({})
      .then(json => dispatch(receiveParentDevices(json)));
  };
}
