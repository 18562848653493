import * as React from 'react';

import { Link } from 'react-router';

import {
  Icon as CustomIcon,
  Avatar,
  Button,
  IconSize,
  IconType,
  IconColor,
  AvatarSize,
  FlexLayout,
  ButtonSize,
  ButtonIconPosition,
  AvatarVariant,
  IconFamily,
} from 'styleguide-react';

import { genderImageAssetFromPng } from '../../helpers';
import { svgAvatarsMap } from '../../helpers/avatarImageMaps';
import ProfileForm, { formName, ProfileFormRecord } from './ProfileForm';
import Icon from '../Icons/Icon';
import MobileLayoutScssUtils from '../Layout/mobile/MobileLayoutScssUtils';
import { getProfileNameInitial } from '../../records/profile';

const getImage = genderImageAssetFromPng(svgAvatarsMap, svgAvatarsMap.boy[1]);

const ProfileContainerForm: React.FunctionComponent<{
  disabled: boolean;
  buttonLabel: string;
  onNextClick: () => any;
  form: ProfileFormRecord;
  initialValues?: ProfileFormRecord;
}> = ({ form, disabled, buttonLabel, onNextClick, initialValues }) => {
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    onNextClick();
  };

  return (
    <FlexLayout
      mainaxis="column"
      flexGrow="2"
      className={MobileLayoutScssUtils.HeightWithoutPageTitle}
    >
      <FlexLayout
        mainaxis="column"
        paddingTop="24"
        mainaxisAlignment="center"
        flexGrow="0"
      >
        <Link
          to={
            initialValues && initialValues.id
              ? `/profiles/${initialValues.id}/edit/modal/AvatarSelectionModal`
              : '/profiles/add/modal/AvatarSelectionModal'
          }
        >
          <FlexLayout mainaxis="row" mainaxisAlignment="center">
            {getAvatar(form)}
          </FlexLayout>
        </Link>
      </FlexLayout>
      <ProfileForm onSubmit={onSubmit} initialValues={initialValues} />
      <FlexLayout
        mainaxis="row"
        padding="32"
        mainaxisAlignment="flex-end"
        flexGrow="0"
      >
        <Button
          icon={
            <CustomIcon
              size={IconSize.regular}
              type={IconType.angleRight}
              color={IconColor.contrast}
            />
          }
          form={formName}
          loading={loading}
          htmlType="submit"
          size={ButtonSize.medium}
          iconPosition={ButtonIconPosition.right}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

const getAvatar = (form: ProfileFormRecord) => (
  <Avatar
    icon={
      <CustomIcon
        circle
        type={IconType.pencil}
        size={IconSize.regular}
        color={IconColor.greenDecorationContrast}
        family={IconFamily.solid}
      />
    }
    // eslint-disable-next-line react/jsx-no-bind, @typescript-eslint/no-empty-function
    onClick={function noRefCheck() {}}
    size={AvatarSize.large}
    variant={form.picture === '' ? AvatarVariant.text : AvatarVariant.default}
    emptyBackground={form.picture === ''}
  >
    {form.picture === '' ? (
      getProfileNameInitial(form.name)
    ) : (
      <Icon path={getImage(form.picture)} />
    )}
  </Avatar>
);

export default ProfileContainerForm;
