import { connect } from 'react-redux';
import { setItemsIfNotExist } from '../../ducks/persistentStorage';
import { isSmartAppBannerVisible } from '../../selectors';
import * as qinit from '../../qinit';
import SmartAppBanner from '../../components/SmartAppBanner/SmartAppBanner';
import { getOS, OS } from '../../helpers/userAgent';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

export const mapStateToProps = state => ({
  isVisible: isSmartAppBannerVisible(state),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(setItemsIfNotExist({ userClosedSmartAppBanner: 'true' }));
  },
  onNavigate: () => {
    const appStorePath = {
      [OS.IOS]: qinit.application.downloads.ios_parents_app,
      [OS.Android]: qinit.application.downloads.android_parents_app,
    }[getOS()];
    const navigate = getMultiPlatformNavigation();

    navigate({
      type: 'location:href',
      src: appStorePath,
    });
    dispatch(setItemsIfNotExist({ userClosedSmartAppBanner: 'true' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartAppBanner);
