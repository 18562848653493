import { Record } from 'immutable';

type Experiment = {
  name: string;
  result: string;
  startDate: string;
};

export type ExperimentRecord = Record<Experiment>;

export const ExperimentRecord = Record<
  Experiment,
  {
    fromPayload: (response: any) => ExperimentRecord;
  }
>({
  name: '',
  result: '',
  startDate: '',
});

ExperimentRecord.fromPayload = (payload: any) => {
  return ExperimentRecord({
    ...payload,
  });
};

export default ExperimentRecord;
