import { connect } from 'react-redux';
import { navigateToSchoolSignature } from '../../actions/Navigation';
import {
  openSchoolSummaryInfoModalFor,
  openSeeAllSignatureModalInfo,
} from '../../actions/SchoolActions';
import StudentSignaturesCard from '../../components/Schools/StudentSignaturesCard';
import { StudentSummaryCardMaxItems } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { getProfileId } from '../../selectors';
import {
  getStudentSummarySignatures,
  getSummaryFetchError,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';

const onClickSignature =
  (signatureId: string) => (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState()) as string;
    return dispatch(navigateToSchoolSignature(profileId, signatureId));
  };

const mapStateToProps = (state: State, props) => ({
  signaturesActivity: getStudentSummarySignatures(state),
  maxSignatureActivityItems:
    props.maxSignatureActivityItems || StudentSummaryCardMaxItems.signatures,
  showPercent: props.showPercent || false,
  single: props.single || false,
  isFeatureDisabled:
    getSummaryFetchError(state) === StudentSummaryFetchError.FeatureDisabled,
  extraHeight: props.extraHeight || false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickSignature: (signatureId: string) => {
    dispatch(onClickSignature(signatureId));
  },
  onClickInfo: () =>
    dispatch(openSchoolSummaryInfoModalFor('MostUsedAppsAndWebsites')),
  onClickSeeAll: () => dispatch(openSeeAllSignatureModalInfo()),
});

const StudentAppsAndWebsitesCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSignaturesCard);

export type StudentSignaturesCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentAppsAndWebsitesCardContainer;
