import * as React from 'react';
import { t } from '../../lib/i18n';
import illustrationDownloadIconDeviceSvg from '../../assets/base/images/v3/decorators/illustration_download_icon_device.svg';
import { CardButtonWithIconText } from '../Button/CardButton';

interface ProtectDeviceRowCardProps {
  onClick: () => void;
}
const ProtectDeviceRowCard: React.FC<ProtectDeviceRowCardProps> = ({
  onClick,
}: ProtectDeviceRowCardProps) => (
  <CardButtonWithIconText
    onClick={onClick}
    icon={
      <img height={42} src={illustrationDownloadIconDeviceSvg} alt="icon" />
    }
    text={t('Protect a device')}
  />
);

export default ProtectDeviceRowCard;
