import { StackedBarChartData } from './types/StackedBarChartData.types';

/**
 * It takes an object data and an array of categories as input.
 * It returns a new object that contains the first and last
 * non-null categories for each key in the data object.
 */
export const getTopAndBottomSegmentsOfEachBar = <
  T extends Record<string, Record<string, unknown>>
>(
  data: T,
  categories: string[]
): Record<string, { last: string; first: string }> => {
  const firstAndLastBarCategories = Object.keys(data).reduce((acc, curr) => {
    acc[curr] = { last: null, first: null };
    return acc;
  }, {});

  Object.entries(data).forEach(([k, v]) => {
    categories.forEach(category => {
      if (v[category] != null) {
        if (!firstAndLastBarCategories[k].last) {
          firstAndLastBarCategories[k].last = category;
        }
        firstAndLastBarCategories[k].first = category;
      }
    });
  });

  return firstAndLastBarCategories;
};

export const getCategories = (
  data: StackedBarChartData[],
  exclude: string[]
): string[] => {
  const categoriesSet = new Set<string>();

  categoriesSet.add('default');

  Object.values(data).forEach(entry => {
    Object.keys(entry).forEach(key => {
      if (!exclude.includes(key)) categoriesSet.add(key);
    });
  });

  return Array.from(categoriesSet);
};
