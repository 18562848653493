import * as React from 'react';
import {
  EmptyPlaceholder,
  FlexLayout,
  FreeStyleListItem,
  Layout,
  List,
  Svg,
  Typography,
} from 'styleguide-react';
import {
  MappedRoutinesScreenTimeData,
  aggregateTimeScreen,
  mapToRoutinesData,
} from './helpers';
import { minutesToHMFormat } from '../../helpers/dates';
import IconTextCombo from '../Routines/IconTextCombo/IconTextCombo';
import { getRoutinesIcon } from '../Routines/helpers/routineIcons';
import Loader from '../base/Loader';
import { t } from '../../lib/i18n';
import InfoIcon from '../Icons/InfoIcon';
import ActivityListIcon from '../Icons/summary/ActivityListIcon';
import type { ScreenTimeDetailsProps } from '../../containers/ScreenTimeDetailsContainer/ScreenTimeDetailsContainer';
import FadedScroll from '../FadedScroll/FadedScroll';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { SummaryDateRanges } from '../../constants';
import {
  doesRoutineConsumeDailyTimeQuota,
  getSanitisedDisabledRoutineName,
} from '../../ducks/routines/helpers';

const ScreenTimeDetailsLoading = () => (
  <EmptyPlaceholder
    testId="par-screen-time-details__loading"
    icon={<Loader size="small" color="secondary" />}
    text={t('Loading...')}
    fillAvailableSpace
    centered
    smallText
  />
);

const ScreenTimeDetailsError = ({ title }: { title: string }) => (
  <EmptyPlaceholder
    icon={<InfoIcon />}
    text={t("{{activityTitle}} couldn't load", {
      activityTitle: title,
    })}
    fillAvailableSpace
    centered
    maxWidth="75%"
    smallText
  />
);

const getEmptyStateLabel = (
  profile: ProfileRecord | undefined,
  activeDateRange: SummaryDateRanges,
  formattedSelectedDate: string
) => {
  if (activeDateRange === SummaryDateRanges.Today) {
    return t('{{name}} hasn’t spent any time on devices today.', {
      name: profile?.name || '',
    });
  }

  if (activeDateRange === SummaryDateRanges.CustomPastDay) {
    return t('{{name}} didn’t spend any time on devices on {{date}}.', {
      name: profile?.name || '',
      date: formattedSelectedDate,
    });
  }
  return '';
};

const EmptyState = ({ text }: { text: string }) => (
  <EmptyPlaceholder
    centered
    smallText
    maxWidth="75%"
    fillAvailableSpace
    icon={
      <Svg height="64" width="64" color="gray-light">
        <ActivityListIcon />
      </Svg>
    }
    text={text}
  />
);

const ScreenTimeDetails = ({
  data,
  routines,
  testId,
  isFetchingInfo,
  hasError,
  profile,
  activeDateRange,
  formattedSelectedDate,
}: ScreenTimeDetailsProps & { testId?: string }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [mappedScreenTimeData, setMappedData] = React.useState<
    Array<MappedRoutinesScreenTimeData>
  >([]);

  React.useEffect(() => {
    // To avoid visual flikers, we show the loading state at the begining
    // and after some delay we start listening to api call status for loading state.
    const timeout = setTimeout(() => {
      setIsLoading(isFetchingInfo);
    }, 400);

    const aggregateData = aggregateTimeScreen(data);

    // skedaddle if there's no screen time
    if (!aggregateData) return undefined;
    if (Object.keys(aggregateData).length === 1 && aggregateData.default === 0)
      return undefined;

    const mappedRoutinesData = mapToRoutinesData(aggregateData, routines);
    setMappedData(mappedRoutinesData);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [data, routines, isFetchingInfo]);

  if (hasError) {
    return <ScreenTimeDetailsError title={t('Screen time details')} />;
  }

  if (isLoading) {
    return <ScreenTimeDetailsLoading />;
  }

  const isEmpty = mappedScreenTimeData.length === 0;

  if (isEmpty)
    return (
      <EmptyState
        text={getEmptyStateLabel(
          profile,
          activeDateRange,
          formattedSelectedDate
        )}
      />
    );

  return (
    <FadedScroll fadeHeight={80} className="par-screen-time-details">
      <List testId={testId} listItemPaddingLeft="8" listItemPaddingRight="8">
        {mappedScreenTimeData.map(entry => (
          <FreeStyleListItem key={entry.uid} noBorder>
            <FlexLayout mainaxis="row" padding="16">
              <FlexLayout mainaxis="row" flexGrow="1" marginRight="8">
                <IconTextCombo
                  iconSize="small"
                  iconGap="x2"
                  icon={getRoutinesIcon(entry.routine?.icon)}
                  subtitle={getSanitisedDisabledRoutineName(
                    entry.routine?.name || ''
                  )}
                  iconColor={entry.routine?.color}
                  showBadge={doesRoutineConsumeDailyTimeQuota(entry.routine)}
                />
              </FlexLayout>
              <FlexLayout
                mainaxis="row"
                flexGrow="2"
                mainaxisAlignment="end"
                crossaxisAlignment="center"
                flexShrink="0"
              >
                <Layout textAlignement="right">
                  <Typography type="h5" weight="semibold" component="span">
                    {minutesToHMFormat(entry.minutes)}
                  </Typography>
                </Layout>
              </FlexLayout>
            </FlexLayout>
          </FreeStyleListItem>
        ))}
      </List>
    </FadedScroll>
  );
};

export default ScreenTimeDetails;
