import * as React from 'react';
import { FlexLayout, EmptyPlaceholder, Layout, Svg } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import {
  AppFilter,
  FILTER_APPS_ALL,
  FILTER_APPS_ALLOWED,
  FILTER_APPS_BLOCKED,
  FILTER_APPS_HASLIMIT,
} from '../../../constants';
import AppsIcon from '../../Icons/GamesAndApps/AppsIcon';
import AllowedIcon from '../../Icons/GamesAndApps/AllowedIcon';
import BlockedIcon from '../../Icons/GamesAndApps/BlockedIcon';
import LimitSetIcon from '../../Icons/GamesAndApps/LimitSetIcon';
import type { AppRulesProps } from '../../../containers/ProfileRules/AppRules/AppRulesContainer';

const getEmptyResultInfo = (filter: AppFilter) => {
  switch (filter) {
    case FILTER_APPS_ALLOWED:
      return {
        copy: t('No allowed apps found'),
        icon: <AllowedIcon />,
      };
    case FILTER_APPS_BLOCKED:
      return {
        copy: t('No blocked apps found'),
        icon: <BlockedIcon />,
      };
    case FILTER_APPS_HASLIMIT:
      return {
        copy: t('No apps with time limits found'),
        icon: <LimitSetIcon />,
      };
    case FILTER_APPS_ALL:
    default:
      return {
        copy: t('No results found'),
        icon: <AppsIcon />,
      };
  }
};

const getNoAppsDetectedEmptyState = () => (
  <React.Fragment>
    <Layout marginBottom="8">
      <strong>{t('No apps yet')}</strong>
    </Layout>
    {t('Apps will appear here as soon as your child accesses them.')}
  </React.Fragment>
);

export const renderAppRulesEmptyState = ({
  noAppsDetected,
  activeFilter,
}: Pick<AppRulesProps, 'activeFilter' | 'noAppsDetected'>) => (
  <FlexLayout mainaxis="column" marginTop="112" marginBottom="112">
    <EmptyPlaceholder
      icon={
        <Svg height="40" width="40" color="gray-light">
          {getEmptyResultInfo(activeFilter).icon}
        </Svg>
      }
      text={
        noAppsDetected
          ? getNoAppsDetectedEmptyState()
          : getEmptyResultInfo(activeFilter).copy
      }
      testId={
        noAppsDetected
          ? 'AppRulesNoAppDetectedEmpty'
          : 'AppRulesEmptyFilteredAppList'
      }
      maxWidth="75%"
      centered
    />
  </FlexLayout>
);
