import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { PolicyAction } from '../records/routines/policy/types/Policy.types';
import {
  RoutineMode,
  RoutineSteps,
  RoutineRuleExceptionType,
} from '../components/Routines/routines.types';

export const navigateToRoutines = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner:replace',
    src: `/profiles/${profileId}/routines`,
  });
};

export const navigateToTimeLimitsModal = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/TimeLimitsModal`,
  });
};

export const navigateToDeleteRoutineModal = (
  profileId: string,
  routineUid: string
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/DeleteRoutineModal?routineUid=${routineUid}`,
  });
};

export const navigateToDeleteRoutineTimeSlotModal = (
  profileId: string,
  routineUid: string,
  timeSlotUid: string,
  origin: string
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/DeleteTimeSlotModal?routineUid=${routineUid}&timeSlotUid=${timeSlotUid}&origin=${origin}`,
  });
};

export const navigateToCantEnableTimeSlotsModal = (
  profileId: string,
  routineUid: string
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/CantEnableTimeSlotsModal?routineUid=${routineUid}`,
  });
};

export const navigateDisconnectTheInternetModal = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/DisconnectTheInternetModal`,
  });
};

export const navigateLockDeviceModal = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/LockDeviceModal`,
  });
};

export const navigateToChangeContentFilterRulesModal = (options: {
  profileId: string;
  routineUid: string;
  policyAction: PolicyAction;
  mode: RoutineMode;
  type: RoutineRuleExceptionType;
}) => {
  const { profileId, routineUid, policyAction, mode, type } = options;
  const navigate = getMultiPlatformNavigation();
  const routineIdParam = routineUid ? `&routineUid=${routineUid}` : '';

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/ChangeContentFilterRulesModal?&policyAction=${policyAction}&mode=${mode}&type=${type}${routineIdParam}`,
  });
};

export const navigateToChangeSetContentFilterRulesModal = (options: {
  profileId: string;
  type: RoutineRuleExceptionType;
}) => {
  const { profileId, type } = options;
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/SetContentFilterRulesModal?type=${type}`,
  });
};

export const navigateToRoutineLeaveWithoutSavingModal = (
  profileId: string,
  mode: RoutineMode,
  step: RoutineSteps
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/LeaveWithoutSavingModal?&mode=${mode}&step=${step}`,
  });
};

export const navigateToRemoveAllExceptionsModal = (options: {
  profileId: string;
  routineUid?: string;
  type: RoutineRuleExceptionType;
  mode: RoutineMode;
  policyAction?: PolicyAction;
}) => {
  const { profileId, routineUid, type, mode, policyAction } = options;
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/RemoveAllExceptionsModal?type=${type}&mode=${mode}&policyAction=${policyAction}${
      routineUid ? `&routineUid=${routineUid}` : ''
    }`,
  });
};

export const navigateToEditRoutine = (options: {
  profileId: number;
  routineUid?: string;
}) => {
  const { profileId, routineUid } = options;
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/edit/${routineUid}`,
  });
};

export const navigateToRoutinesInfoModal = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/RoutinesInfoModal`,
  });
};

export const navigateToUpgradeModal = (profileId: string) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/routines/modal/ExpandPlanModal`,
  });
};

export const navigateToUpgradePage = () => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: '/upgrade',
  });
};
