import { parseQuery, QueryWebAutologin } from '../../helpers/query';
import { deserializeSsoState } from '../../helpers/sso';
import { DirectPurchase } from './types/state';

/**
 * Obtain Direct Purchase data if available regardless of source.
 *
 * The ensureSetDirectPurchaseData thunk is called at varios points in the app
 * boot process and the data can come from different sources.
 *
 * - bootApp: The Direct Purchase data is obtained before fetchProducts() so that
 *   the API request contains the Direct Purchase data.
 *
 * - resetHistoryAfterWebSSOAuthFlowEnds: After login/signup via SSO, the
 *   Direct Purchase data can be obtained from the deserialized 'state' query
 *   param.
 *
 * - DirectPurchaseContainer.load: In case the user skips bootApp due to already
 *   being logged in, if going through the Direct Purchase flow, retrieve the
 *   Direct Purchase data again from the URL query params.
 *
 * This helper contemplates all possible cases and returns the Direct Purchase
 * data if available.
 */
export const getDirectPurchaseQuery = (
  query: null | string | QueryWebAutologin | DirectPurchase = null
): DirectPurchase => {
  let originalQuery = query;

  /**
   * If no query string or parsedQuery object provided, try to get Direct
   * Purchase data from the current URL query params.
   */
  if (originalQuery === null) {
    originalQuery = window.location.search;
  }

  /**
   * If query string provided, parse into a QueryInitializer object.
   */
  let parsedQuery =
    typeof originalQuery === 'string'
      ? parseQuery(originalQuery)
      : originalQuery;

  /**
   * If `query` is a QueryWebAutologin object (after SSO signup/login), or a
   * QueryWebAutologin-compatible query string, get the Direct Purchase data
   * from the 'state' param.
   *
   * When not in the Direct Purchase flow, the query parameters get stripped
   * out and at some point and the parsedQuery object becomes:
   * { kind: 'none', state: '' }
   *
   * In order to avoid errors in this case, we need to ensure the 'state' prop
   * is not empty before deserialize in order to avoid errors.
   */
  if (parsedQuery?.state) {
    const ssoState = deserializeSsoState(parsedQuery.state);
    parsedQuery = ssoState?.query;
  }

  return parsedQuery;
};

export const isDirectPurchaseQuery = (
  query: null | string | QueryWebAutologin | DirectPurchase = null
) => {
  const directPurchaseQuery = getDirectPurchaseQuery(query);
  return directPurchaseQuery?.flow === 'direct-purchase';
};
