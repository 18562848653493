import * as React from 'react';
import TimelineState from './TimelineState';
import { IconType } from 'styleguide-react';
import { t } from '../../../../lib/i18n';

interface DisabledTimelineStateProps {
  profileName: string;
}

const DisabledTimelineState: React.FC<DisabledTimelineStateProps> = ({
  profileName,
}) => {
  return (
    <TimelineState
      iconType={IconType.chartBar}
      text={t('Your school hasn’t enabled timeline monitoring for parents.', {
        profileName,
      })}
    />
  );
};

export default DisabledTimelineState;
