import { connect } from 'react-redux';
import { getFormValues, isValid } from 'redux-form/immutable';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import { modifyEmail, requestModifyAccountError } from '../../ducks/account';
import State, { BaseThunk, Dispatch } from '../../store/state';
import { showErrorToast } from '../AccountSettings/helpers';
import { formRecord } from '../../components/AccountSettings/EditEmailForm';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import { t } from '../../lib/i18n';
import EditEmailConfirmationModal from '../../components/Modal/EditEmailConfirmationModal';
import { fetchAppData } from '../../ducks/app/fetchAppData';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const formName = 'editEmail';
const values = getFormValues(formName);

const mapStateToProps = (state: State) => {
  const isFormValid = isValid(formName)(state);
  return {
    enableConfirmAction: isFormValid,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onConfirm: () => dispatch(onFormSubmit()),
  onCancel: () => dispatch(close()),
});

const EditEmailConfirmationModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailConfirmationModal);

const onFormSubmit = (): BaseThunk<void> => (dispatch, getState) => {
  const form = formRecord(values(getState()));

  return Promise.resolve(form)
    .then(record => ({
      password: record.password,
      new_email: record.email.toLowerCase(),
    }))
    .then(data => dispatch(modifyEmail(data)))
    .then(() => {
      const navigate = getMultiPlatformNavigation();
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: '/account-settings',
        })
      );
    })
    .then(() =>
      dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
    )
    .then(() => dispatch(fetchAppData()))
    .catch(error => {
      dispatch(requestModifyAccountError());
      dispatch(showErrorToast(error));
    });
};

export type EditEmailConfirmationModalProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default EditEmailConfirmationModalContainer;
