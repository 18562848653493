import { connect } from 'react-redux';
import SignatureDetails from '../../../components/Schools/signature/SignatureDetails';
import { goBackWithFallback } from '../../../ducks/routing';
import { fetchSignatureDetails } from '../../../ducks/signatureDetails';
import { PageNames } from '../../../helpers/analytics';
import { trackablePage } from '../../../helpers/hocs';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import {
  getActiveProfile,
  getActiveProfileUid,
} from '../../../selectors/profile';
import {
  isErrorSignatureDetails,
  getSignatureDetails,
  getSignatureDetailsFetchStatus,
} from '../../../selectors/signature/signatureDetails';
import { getStudentSummarySignatures } from '../../../selectors/studentSummary';
import State, { Dispatch, RouterParams } from '../../../store/state';

const mapStateToProps = (state: State, { params: { signatureId } }) => {
  const signaturesData = getStudentSummarySignatures(state);
  const signaturesDataItem = signaturesData.find(
    item => item.id === signatureId
  );

  return {
    signature: getSignatureDetails(state),
    fetchStatus: getSignatureDetailsFetchStatus(state),
    isError: isErrorSignatureDetails(state),
    signaturesDataItem,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickGoBack: () =>
    dispatch((dispatch: Dispatch, getState: () => State) => {
      const profileId = getActiveProfile(getState())?.id;
      const path = profileId
        ? `/profiles/${profileId}/school/summary`
        : undefined;

      dispatch(goBackWithFallback(path));
    }),
  goToLink: (url: string) => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'external',
        src: url,
      })
    );
  },
});

const SignatureDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(
    SignatureDetails,
    PageNames.ExpertInsightsDetails,
    (_, { params: { signatureId } }) => ({
      signature: signatureId,
    })
  )
);

export type SignatureDetailsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

SignatureDetailsContainer.load =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    const profileUid = getActiveProfileUid(getState());
    const { signatureId } = params;

    if (profileUid !== null && signatureId != null) {
      dispatch(fetchSignatureDetails(profileUid, signatureId));
    }
  };

export default SignatureDetailsContainer;
