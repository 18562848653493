import * as React from 'react';

const usePullToRefresh = (
  el: React.RefObject<HTMLDivElement>,
  children: React.ReactNode,
  pullRefreshElement?: JSX.Element
): readonly [React.ReactNode] => {
  const childrenWithPullRefresh = pullRefreshElement
    ? React.cloneElement(
        pullRefreshElement,
        {
          getScrollContainer: () => el.current,
        },
        children
      )
    : children;

  return [childrenWithPullRefresh] as const;
};

export default usePullToRefresh;
