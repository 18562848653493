export const when = (
  shouldRender: boolean,
  component: JSX.Element | string | Array<JSX.Element>
): JSX.Element | null | string | Array<JSX.Element> =>
  shouldRender ? component : null;

export const ifElse = (
  shouldRender: boolean,
  componentX: JSX.Element | string,
  componentY: JSX.Element | string
): JSX.Element | string => (shouldRender ? componentX : componentY);
