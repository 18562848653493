import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import { PLATFORM_WINDOWS, PLATFORM_MAC } from '../constants';
import { getProtectionStatus } from '../records/device/deviceProtectionStatus';
import State from '../store/state';

import { tryUndefined } from './helpers';
import { getProfiles, getProfileIds } from './profile';
import { getDevicesMap, getProfileDevices } from './profileDevices';
import { DeviceRecord } from '../records/device/types/Device.types';

export const getDeviceIds = (state: State) =>
  state.get('devices').get('result');

export const getDeviceType = (state: State) =>
  state.get('devices').get('deviceType');

export const getDevice = tryUndefined((state: State, id: DeviceRecord['id']) =>
  state.get('records').get('devices').get(id.toString())
);

export const getDeviceUpdateAssignProfileStatus = (state: State) =>
  state.get('devices').get('isUpdatingAssignedProfile');

export const getAllDevices = createSelector(
  getDeviceIds,
  getDevicesMap,
  (deviceIds, devicesMap: Immutable.Map<string, DeviceRecord>) =>
    devicesMap
      .filter(
        R.pipe<string, number, boolean>(
          R.nthArg(1),
          parseInt,
          deviceIds.contains.bind(deviceIds)
        )
      )
      .toOrderedSet()
);

export const getDevicesWithProfilesAndStatus = createSelector(
  getAllDevices,
  getProfiles,
  (devices, profiles) =>
    devices
      .map(device => {
        const deviceProfiles = profiles.filter(profile =>
          profile.deviceIds.contains(device.id)
        );
        return {
          device,
          profiles: deviceProfiles,
          status: getProtectionStatus(device, deviceProfiles),
        };
      })
      .toList()
);

export const getProfileDevicesSortedByName = createSelector(
  getProfileDevices,
  devices =>
    devices.sort(
      R.comparator<any>((a, b) => R.toLower(a.name) < R.toLower(b.name))
    ) as Immutable.List<DeviceRecord>
);

export const getFirstDeviceFromSortedDevices = createSelector(
  getProfileDevicesSortedByName,
  devices => devices.first()
);

export const getAdjacentDeviceFromSortedDevices: (
  state: any,
  profileId: number,
  deviceId: number,
  direction: number
) => any = createSelector(
  getProfileDevicesSortedByName,
  R.nthArg(2),
  R.nthArg(3),
  (devices, currDeviceId, relativePosition) => {
    if (devices.size) {
      const index = R.clamp(
        0,
        devices.size - 1,
        devices.findIndex(d => d.id === parseInt(currDeviceId, 10)) +
          relativePosition
      );
      return devices.get(index);
    }
    return null;
  }
);

export const getNextDeviceFromSortedDevices = (
  state: State,
  profileId,
  deviceId
) => getAdjacentDeviceFromSortedDevices(state, profileId, deviceId, 1);

export const getPrevDeviceFromSortedDevices = (
  state: State,
  profileId,
  deviceId
) => getAdjacentDeviceFromSortedDevices(state, profileId, deviceId, -1);

export const isLastDeviceOfSortedDevices = createSelector(
  getProfileDevicesSortedByName,
  R.nthArg(2),
  (devices, deviceId) =>
    devices.size && devices.last()!.id === parseInt(deviceId, 10)
);

export const isFirstDeviceOfSortedDevices = createSelector(
  getProfileDevicesSortedByName,
  R.nthArg(2),
  (devices, deviceId) =>
    devices.size && devices.first()!.id === parseInt(deviceId, 10)
);

export const getProfileComputerDevices = createSelector(
  getProfileDevices,
  devices =>
    devices.filter(device =>
      R.contains(device.platform, [PLATFORM_WINDOWS, PLATFORM_MAC])
    )
);

export const getProfileCount = createSelector(getProfileIds, ids =>
  ids.count()
);

export const getUnassignedDevices = createSelector(
  getAllDevices,
  getProfiles,
  (devices, profiles) => {
    const assignedDeviceIds = profiles.reduce(
      (ids, profile) => ids.concat(profile.deviceIds),
      Immutable.Set()
    );
    return devices.filter(
      R.complement(device => assignedDeviceIds.contains(device.id))
    );
  }
);
