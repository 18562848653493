import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  SafeNetworkInfo,
  SafeNetworkInfoPayload,
} from './types/SafeNetworkInfo.types';

export const SafeNetworkInfoOperations = getRecordOperations<
  SafeNetworkInfo,
  SafeNetworkInfoPayload
>({
  defaultFields: {
    name: null,
  },
});
