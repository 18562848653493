import * as React from 'react';
import type { LogBookTopBarProps } from './types';
import IconChart from '../../Icons/IconInsertChart';
import { t } from '../../../lib/i18n';

export const LogBookTopBar: React.FC<LogBookTopBarProps> = (
  props: LogBookTopBarProps
) => {
  const { profile, onClickStatsNotification } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="Profile__StatsNotification Profile__StatsNotification--visible"
      onClick={onClickStatsNotification}
      data-testid="topbar-logbook"
    >
      <IconChart />
      <span>
        {' '}
        {t("Check out {{childName}}'s 1-year stats! >", {
          childName: profile.name,
        })}{' '}
      </span>
    </div>
  );
};
