import * as React from 'react';
import { Card, GlobalType } from 'styleguide-react';
import { SummaryDateRanges } from '../../constants';
import ScreenSummaryChartContainer from '../../containers/ScreenTimeSummary/ScreenSummaryChartContainer';
import type { ScreenSummaryCardProps } from '../../containers/ScreenTimeSummary/ScreenSummaryCardContainer';
import { t } from '../../lib/i18n';

const getTitle = (todayOrCustomPastDay: boolean) => {
  return !todayOrCustomPastDay
    ? t('Screen time by day')
    : t('Screen time by hour');
};

const ScreenSummaryCard = ({
  activeDateRange,
  profile,
  height,
  width,
}: ScreenSummaryCardProps) => {
  const todayOrCustomPastDay = [
    SummaryDateRanges.Today,
    SummaryDateRanges.CustomPastDay,
  ].includes(activeDateRange);

  const title = getTitle(todayOrCustomPastDay);

  return (
    <Card
      height={height}
      width={width}
      className="par-screen-time-summary-card"
      type={GlobalType.white}
      header={{ title }}
    >
      <ScreenSummaryChartContainer profile={profile} />
    </Card>
  );
};

export default ScreenSummaryCard;
