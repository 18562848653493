import { Map } from 'immutable';

import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { ProfileSummaryAlertsOperations } from './profileSummaryAlerts';
import { commonMapInFn } from '../../api/mappings/common';

import {
  SummaryAlerts,
  SummaryAlertsPayload,
} from './types/summaryAlerts.type';

export const SummaryAlertsOperations = getRecordOperations<
  SummaryAlerts,
  SummaryAlertsPayload
>({
  defaultFields: {
    profiles: Map({}),
  },
  mapIn: payload => {
    const { fromPayload } = ProfileSummaryAlertsOperations;
    const { profiles } = payload;

    return {
      ...commonMapInFn(payload),
      profiles: Map(profiles ?? {}).map(fromPayload),
    };
  },
});
