const setTimeout = (func, milliseconds) => {
  return window.setTimeout(func, milliseconds);
};
export default setTimeout;

export const setInterval = (func, milliseconds) => {
  return window.setInterval(func, milliseconds);
};

export const clearInterval = timeoutId => {
  return window.clearInterval(timeoutId);
};

export const clearTimeout = timeoutId => {
  return window.clearTimeout(timeoutId);
};

export const now = () => Date.now();
