import State from '../store/state';

export const getCallsAndSmsActivity = (state: State) =>
  state.get('socialActivity').get('callsAndSms');

export const getSearchActivity = (state: State) =>
  state.get('socialActivity').get('searches');

export const isFetchingMoreSocialActivity = (state: State) =>
  state.get('socialActivity').get('isFetchingMore');

export const isFetchingCallAndSms = (state: State) =>
  state.get('socialActivity').get('isFetchingCallAndSms');

export const isFetchingSearches = (state: State) =>
  state.get('socialActivity').get('isFetchingSearches');

export const getCallAndSmsError = (state: State) =>
  state.get('socialActivity').get('callAndSmsError');

export const getSearchesError = (state: State) =>
  state.get('socialActivity').get('searchesError');
