/* eslint-disable camelcase */

/**
 * This is a singleton module outside of the redux state,
 * but it only affects data sent to google analytics.
 */

import * as queryString from 'query-string';
import timeout from '../lib/timeout';
import { APIError } from '../lib/errors';
import {
  ERROR_TIMEOUT,
  ERROR_4XX,
  ERROR_5XX,
  ERROR_UNKNOWN,
  ERROR_UNAUTHORIZED,
  ERROR_NETWORK,
} from '../constants';
import { isiOSPlatform } from '.';

let currentScreenKey: string;
let accountId = 0;

export default function gaSetup({
  tenant: {
    parents_app: {
      google_analytics: { tracking_id, app_name, app_id },
    },
  },
  version,
}) {
  if (isiOSPlatform()) return; /* straight from GA's instructions */

  /* tslint:disable */ window.ga =
    window.ga ||
    function addArgs(...args) {
      (ga.q = ga.q || []).push(args);
    };
  ga.l = +new Date();
  ga('create', tracking_id, {
    cookieDomain: 'none',
  });
  ga('set', 'checkProtocolTask', null); // allow sending from file:// protocol
  ga('set', 'appId', app_id);
  ga('set', 'appName', app_name);
  ga('set', 'title', app_name);
  ga('set', 'appVersion', version);
  ga('send', 'screenview', { screenName: 'Home' });
  /* tslint:enable */
  const script = document.createElement('script');
  script.src = 'https://www.google-analytics.com/analytics.js';
  script.async = true;
  document.body.appendChild(script);
}

/**
 * Send a "screenview" to GA if location.key has changed since last call.
 */
export const gaScreenView = ({
  key,
  pathname,
}: {
  key: string;
  pathname: string;
}) => {
  if (isiOSPlatform()) return;

  timeout(() => {
    if (currentScreenKey !== key) {
      currentScreenKey = key;
      send('screenview', { screenName: pathname });
    }
  }, 0);
};

export const gaSetAccountId = (id: number) => (accountId = id); // eslint-disable-line

export const gaEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string
): void => {
  if (isiOSPlatform()) return;

  send('event', {
    eventCategory,
    eventAction,
    eventLabel: eventLabel || accountId.toString(),
  });
};

export const gaCampaignFromUrl = (url: string) => {
  if (isiOSPlatform()) return;

  const {
    utm_source: campaignSource,
    utm_medium: campaignMedium,
    utm_campaign: campaignName,
  } = queryString.parse(queryString.extract(url));

  ga('set', 'campaignName', campaignName);
  ga('set', 'campaignMedium', campaignMedium);
  ga('set', 'campaignSource', campaignSource);
};

export const send = (...args) =>
  !isiOSPlatform() && window.ga ? ga('send', ...args) : undefined;

export const gaTiming = (
  category: string,
  varName: string,
  milliseconds: number,
  label?: string
): void => {
  if (isiOSPlatform()) return;

  send('timing', category, varName, milliseconds, label);
};

export const gaEventNavigationResult =
  (path: string) => (error?: APIError | unknown) => {
    if (isiOSPlatform()) return;

    if (error instanceof Error) {
      gaEvent(`screen${path}`, 'navigate', errorEventName(error));
    } else {
      gaEvent(`screen${path}`, 'navigate', 'success');
    }
  };

export const errorEventName = (error: Error) => {
  if (error instanceof APIError) {
    if (error.type === ERROR_5XX) {
      return 'server error';
    }
    if (error.type === ERROR_4XX) {
      return 'request error';
    }
    if (error.type === ERROR_UNAUTHORIZED) {
      return 'unauthorized';
    }
    if (error.type === ERROR_NETWORK || error.type === ERROR_UNKNOWN) {
      return 'network error';
    }
    if (error.type === ERROR_TIMEOUT) {
      return 'network timeout error';
    }
    return error.statusText;
  }
  return error.message;
};

export const gaEventAppOnboarding = (step: number, label: string) => {
  if (isiOSPlatform()) return;

  gaEvent(`Step ${step} - Kid Device Activation`, 'click', label);
};
