import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';

export const trackSubscribeNowButtonClicked = () => {
  return trackButtonClicked(
    GenericPageNames.WellbeingActivities,
    ButtonNames.SubscribeNow
  );
};
