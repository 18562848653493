import { Map } from 'immutable';
import { connect } from 'react-redux';

import { t } from '../../../lib/i18n';
import { goBackIfHistory } from '../../../ducks/routing';
import AddDomainException from '../../../components/WebFilters/AddDomainException';
import { FormRecord } from '../../../components/WebFilters/AddDomainExceptionForm';
import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import {
  getSelectedDomainAction,
  getProfileRulesModifyStatus,
  getWebFilterDomains,
  getProfileRules,
} from '../../../selectors';
import { setSelectedDomainAction } from '../../../ducks/profileRules';
import { TOAST_ICON_TICK, showToast } from '../../../ducks/toast';
import {
  WEBFILTER_ALERT,
  WEBFILTER_ALLOW,
  WebfilterAction,
} from '../../../constants';
import { extractDomain } from '../../../helpers/string';
import { WebRuleNames, trackWebRules } from '../../../helpers/analytics';
import { setLastRuleTypeModified, RuleType } from '../../../ducks/appRate';
import { Dispatch } from '../../../store/state';
import { showSetupAlertsModal } from '../../../actions/PremiumFeaturesActions';
import { SetupAlertsModalReferrer } from '../../Modal/SetupAlertsModalContainer';
import { ruleNeedsUpgrade } from '../../../businessLogic/rules/features';
import { getFeatureByCode } from '../../../ducks/features';

const mapStateToProps = (state, { params: { profileId } }) => {
  const isAlertMeOptionPremium = ruleNeedsUpgrade(
    getFeatureByCode(state, 'web_domain_exceptions_alert')
  );
  return {
    selectedDomainAction: getSelectedDomainAction(state),
    isSaving: getProfileRulesModifyStatus(state),
    domains:
      getWebFilterDomains(state, profileId) || Map<string, WebfilterAction>(),
    isAlertMeOptionPremium,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickDomainAction: action => dispatch(setSelectedDomainAction(action)),
  onSubmit: (newException: FormRecord) => {
    dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
    dispatch(handleNewException({ profileId, newException }));
  },
  onClickBackButton: () => {
    dispatch(setSelectedDomainAction(WEBFILTER_ALLOW));
    dispatch(goBackIfHistory());
  },
});

export const handleNewException =
  ({
    profileId,
    newException,
  }: {
    profileId: string;
    newException: FormRecord;
  }) =>
  (dispatch: Dispatch, getState) => {
    if (
      getSelectedDomainAction(getState()) === WEBFILTER_ALERT &&
      ruleNeedsUpgrade(
        getFeatureByCode(getState(), 'web_domain_exceptions_alert')
      )
    ) {
      return dispatch(
        showSetupAlertsModal(
          SetupAlertsModalReferrer.webFilteringAlertMeException
        )
      );
    }

    return dispatch(
      dispatchModifyProfileRules(
        profileId,
        addDomainException(extractDomain(newException.domain))
      )
    )
      .then(() => {
        trackWebRules(WebRuleNames.WEBSITE_EXCEPTION_CHANGE);
      })
      .then(() => dispatch(goBackIfHistory()))
      .then(() => dispatch(setSelectedDomainAction(WEBFILTER_ALLOW)))
      .then(() =>
        dispatch(showToast(t('Exception successfully added'), TOAST_ICON_TICK))
      );
  };

const addDomainException = domain => (state, profileId) => {
  const action = getSelectedDomainAction(state);
  return getProfileRules(state, profileId)!.setIn(
    ['web', 'domains', domain],
    action
  );
};

const AddDomainExceptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDomainException);

AddDomainExceptionContainer.load = () => (dispatch, getState) =>
  dispatch(
    setSelectedDomainAction(
      getSelectedDomainAction(getState()) || WEBFILTER_ALLOW
    )
  );

export default AddDomainExceptionContainer;
