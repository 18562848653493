import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import {
  updateAccount,
  modifyAccount,
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { getAccount, getCurrentTime } from '../../selectors';
import { showErrorToast } from './helpers';
import EditTimezone from '../../components/AccountSettings/EditTimezone';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';

const mapStateToProps = state => {
  const { timezone } = getAccount(state);
  return {
    timezone,
    currentTime: getCurrentTime(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickSaveButton: timezone => dispatch(onClickSaveButton(timezone)),
});

function onClickSaveButton(timezone) {
  return (dispatch, getState) => {
    return Promise.resolve(getAccount(getState()).set('timezone', timezone))
      .then(updatedAccount => {
        dispatch(updateAccount(updatedAccount));
        return updatedAccount;
      })
      .then(updatedAccount => {
        dispatch(goBackIfHistory());
        return updatedAccount;
      })
      .then(updatedAccount => dispatch(modifyAccount(updatedAccount)))
      .then(() =>
        dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
      )
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(reverseAccountUpdate());
        dispatch(showErrorToast(error));
      });
  };
}

const EditTimezoneContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTimezone);

export default EditTimezoneContainer;
