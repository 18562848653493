import { getLoginRedirectLocation } from '../../selectors/routing';
import { RouteLocation } from '../../types/RouteLocation.types';
import { removeLoginRedirectLocation } from '../routing';
import {
  SET_LOADED_ROOT,
  FINISHED_LOADING_INITIAL_DATA,
  SET_CONTENT_HIDDEN,
} from '../actionNames/app';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { BaseThunk } from '../../store/state';
import { bootApp } from './bootApp';

export const finishedLoadingInitialData = (): BaseThunk<void> => {
  return dispatch => {
    dispatch({ type: FINISHED_LOADING_INITIAL_DATA });
    dispatch({
      type: SET_CONTENT_HIDDEN,
      payload: false,
    });
  };
};

export const redirectAfterLogin = () => (dispatch, getState) => {
  const state = getState();
  const location =
    getLoginRedirectLocation(state) ??
    ({ pathname: '/', key: null } as RouteLocation);
  dispatch(removeLoginRedirectLocation());
  dispatch({ type: SET_LOADED_ROOT, payload: true });
  return dispatch(bootApp(window.location.search, location, false)).then(() => {
    const navigate = getMultiPlatformNavigation();
    dispatch(finishedLoadingInitialData());
    dispatch(
      navigate({
        type: 'inner:replace',
        src: location,
      })
    );
  });
};
