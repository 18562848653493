import * as React from 'react';
import {
  Button,
  ButtonSize,
  Icon,
  IconColor,
  IconType,
  Modal,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';

const NonBlockableAppModal: React.FunctionComponent<{
  appName: string;
  onClickClose: () => any;
}> = ({ appName, onClickClose }) => (
  <Modal
    testId="non-blockable-app-modal"
    onClickClose={onClickClose}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
    header={{
      icon: <Icon type={IconType.infoCircle} color={IconColor.secondary} />,
    }}
    title={t('{{appName}} can’t be restricted', { appName })}
    buttons={[
      <Button
        key="button"
        size={ButtonSize.medium}
        block
        onClick={onClickClose}
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    <p>
      {t(
        `{{shortName}} can report {{appName}}'s activity but can’t block it or apply time limits on iOS devices.`,
        { appName }
      )}
    </p>
  </Modal>
);

export default NonBlockableAppModal;
