import { connect } from 'react-redux';
import { openSchoolSummaryInfoModalFor } from '../../actions/SchoolActions';
import StudentTotalUsageCardWithObservable from '../../components/Schools/StudentTotalUsageCardWithObservable';
import { SummaryDateRanges } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import {
  getAccountCurrentTime,
  getProfile,
  getTimezone,
} from '../../selectors';
import {
  getStudentSummaryActiveDateRange,
  getStudentSummaryTotalUsage,
  getSummaryFetchTotalUsageError,
  isFetchingTotalUsage,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';
import { loadStudentTotalUsageActivity } from '../../ducks/studentSummary';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { createMySchoolEmailHref } from '../../helpers/email';
import { t } from '../../lib/i18n';

const mapStateToProps = (state: State, { profileId }) => ({
  totalUsageActivity: getStudentSummaryTotalUsage(state),
  timezone: getTimezone(state),
  currentTime: getAccountCurrentTime(state),
  todaySelected:
    getStudentSummaryActiveDateRange(state) === SummaryDateRanges.Today,
  errorOnLoading: getSummaryFetchTotalUsageError(state),
  isFeatureDisabled:
    getSummaryFetchTotalUsageError(state) ===
    StudentSummaryFetchError.FeatureDisabled,
  isLoadingTotalUseInfo: isFetchingTotalUsage(state),
  profileName: getProfile(state, profileId)?.name || '',
});

const mapDispatchToProps = (dispatch: Dispatch, { profileId }) => ({
  onClickInfo: () => dispatch(openSchoolSummaryInfoModalFor('TotalDailyUsage')),
  loadInfo: () => dispatch(loadStudentTotalUsageActivity({ profileId })),
  onClickContactUs: (event: Event): void => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.ContactSupport
    );
    const navigate = getMultiPlatformNavigation(event);

    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(t('Error School dashboard activity')),
    });
  },
});

const StudentTotalUsageCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTotalUsageCardWithObservable);

export type StudentTotalUsageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentTotalUsageCardContainer;
