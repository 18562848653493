import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  Modal,
  ModalButtonsAlignment,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants';

const SafetyNetInfoModal = ({ onClickClose }) => {
  return (
    <Modal
      title={t('Blocked by school')}
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            color="secondary"
            size="lg"
            testId="safety-net-info-modal__Icon"
          />
        ),
      }}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="safety-net-info-modal__Button"
          onClick={onClickClose}
          size={ButtonSize.medium}
          testId="safety-net-info-modal-close"
        >
          {t('OK')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="safety-net-info-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
          {t(
            'To make sure your child is always protected, some school rules remain active outside of school hours.'
          )}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default SafetyNetInfoModal;
