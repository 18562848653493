import * as React from 'react';
import { t } from '../../lib/i18n';
import { TimeSettingsRecord } from '../../records/profileRules';
import { SettingsToggleNames } from '../../constants';
import {
  FlexLayout,
  InstructionBlock,
  List as RegularStyleList,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';

export interface TimeSettingsProps {
  timeRestrictions: TimeSettingsRecord;
  toggleDisabled: boolean;
  onToggle: (target: SettingsToggleNames, checked: boolean) => void;
  onClickDisabled: () => any;
  onClickBackButton: () => any;
}

const TimeSettings: React.FunctionComponent<TimeSettingsProps> = ({
  timeRestrictions: { isLockNavigation, isLockComputer },
  toggleDisabled,
  onToggle,
  onClickDisabled,
}) => (
  <FlexLayout mainaxis="column">
    <FlexLayout mainaxis="row" paddingBottom="8">
      <InstructionBlock>
        {t('Choose what happens when time is up.')}
      </InstructionBlock>
    </FlexLayout>
    <RegularStyleList listItemPaddingTop="24">
      <RegularStyleListItem
        title={t('Lock navigation')}
        upperSubtitle={t('Prevents web browsing and internet access.')}
        checked={isLockNavigation}
        actionElement={
          <Switch
            id={SettingsToggleNames.IsLockNavigation}
            checked={isLockNavigation}
            onClick={
              isLockNavigation && toggleDisabled
                ? () => onClickDisabled
                : ev =>
                    onToggle(
                      SettingsToggleNames.IsLockNavigation,
                      (ev.target as HTMLInputElement).checked
                    )
            }
            disabled={isLockNavigation && toggleDisabled}
          />
        }
      />
      <RegularStyleListItem
        title={t('Lock device')}
        upperSubtitle={t(
          'Disables most apps on mobile, logs you out of your session on desktop.'
        )}
        checked={isLockComputer}
        actionElement={
          <Switch
            id={SettingsToggleNames.IsLockComputer}
            checked={isLockComputer}
            onClick={
              isLockComputer && toggleDisabled
                ? onClickDisabled
                : ev =>
                    onToggle(
                      SettingsToggleNames.IsLockComputer,
                      (ev.target as HTMLInputElement).checked
                    )
            }
            disabled={isLockComputer && toggleDisabled}
          />
        }
      />
    </RegularStyleList>
  </FlexLayout>
);

export default TimeSettings;
