import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { AppCategory, AppCategoryPayload } from './types';

export const AppCategoriesOperations = getRecordOperations<
  AppCategory,
  AppCategoryPayload
>({
  defaultFields: {
    categoryId: '',
    name: '',
    icon: '',
  },
});
