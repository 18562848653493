import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import type { CallsAndSMSRulesAddDeviceFlyoverProps } from '../../containers/ProfileRules/CallsSMSRules/CallsAndSMSRulesAddDeviceFlyoverContainer';
import CallsSMSRulesFlyover from './CallsSMSRulesFlyover';

const ADD_DEVICE_FLYOVER_TEST_ID = 'calls-and-sms-rules-add-device-flyover';

const CallsAndSMSRulesAddDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickAddDevice,
}: CallsAndSMSRulesAddDeviceFlyoverProps) => {
  return (
    <CallsSMSRulesFlyover
      accountLocale={accountLocale}
      testId={ADD_DEVICE_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Add a device'),
        onClick: onClickAddDevice,
        testId: `${ADD_DEVICE_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t(
        '*Calls & messages monitoring for iOS requires a Mac computer.'
      )}
      topbarText={t(
        'Protect an Android or iOS device to monitor calls & messages'
      )}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default CallsAndSMSRulesAddDeviceFlyover;
