import * as React from 'react';
import {
  Avatar,
  AvatarSize,
  GlobalType,
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  Layout,
  List,
  Tag,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineRulesListItem from '../../RoutineRulesList/RoutineRulesListItem';
import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { PolicyBlockType } from '../../../../records/routines/policy/types/Policy.types';

export type PremiumBasicSelectRoutineTypeProps =
  RoutineStepWrapperHandlerProps<PolicyBlockType | null>;

export enum PremiumBasicSelectRoutineTypeTest {
  prefix = 'routines-premium-basic-select-routine-step',
}

const renderUpgradeTag = () => (
  <Layout display="inline-block">
    <Tag text={t('Upgrade')} type={GlobalType.booster} variant="squared" />
  </Layout>
);

const PremiumBasicSelectRoutineType = ({
  close,
  next,
}: PremiumBasicSelectRoutineTypeProps) => {
  const blockTypes: Record<'lockNavigation' | 'lockDevice', PolicyBlockType> = {
    lockNavigation: 'POLICY_BLOCK_TYPE_LOCK_NAVIGATION',
    lockDevice: 'POLICY_BLOCK_TYPE_LOCK_DEVICE',
  };

  const options = [
    {
      title: t('Disconnect the internet'),
      subtitle: t('Prevent web browsing and internet access'),
      icon: IconType.wifiSlash,
      iconColor: GlobalType.error,
      onClick: () => next?.(blockTypes.lockNavigation),
      testId: `${PremiumBasicSelectRoutineTypeTest.prefix}-option-disconnect-internet`,
    },
    {
      title: t('Lock devices'),
      subtitle: t('Block apps and log out of active sessions'),
      icon: IconType.lock,
      iconColor: GlobalType.error,
      onClick: () => next?.(blockTypes.lockDevice),
      testId: `${PremiumBasicSelectRoutineTypeTest.prefix}-option-lock-device`,
    },
  ];

  return (
    <RoutineStepWrapper
      testId={PremiumBasicSelectRoutineTypeTest.prefix}
      close={close}
      fixedFooter
    >
      <Layout marginBottom="40">
        <Typography type="h4" weight="semibold">
          {t('Choose the type of routine you want to create')}
        </Typography>
      </Layout>

      <List allowCustomListItemStyle={RoutineRulesListItem}>
        {options.map(
          ({ title, subtitle, icon, iconColor, onClick, testId }) => (
            <RoutineRulesListItem
              key={title}
              title={title}
              subtitle={subtitle}
              icon={icon}
              iconColor={iconColor}
              onClick={onClick}
              testId={testId}
            />
          )
        )}
        <RoutineRulesListItem
          key="preset"
          title={t('Preset rules')}
          titleTag={renderUpgradeTag()}
          subtitle={t('Choose ready-made rules')}
          icon={IconType.wandMagicSparkles}
          iconFamily={IconFamily.solid}
          iconColor={GlobalType.secondary}
          onClick={() => next?.(null)}
          testId={`${PremiumBasicSelectRoutineTypeTest.prefix}-option-preset`}
          className="par-premium-basic-select-routine-type__preset-button"
        />
        <RoutineRulesListItem
          key="custom"
          title={t('Custom')}
          titleTag={renderUpgradeTag()}
          icon={
            <Avatar emptyBackground size={AvatarSize.small}>
              <Icon
                type={IconType.plus}
                color={IconColor.regular}
                size={IconSize.lg}
              />
            </Avatar>
          }
          onClick={() => next?.(null)}
          testId={`${PremiumBasicSelectRoutineTypeTest.prefix}-option-custom`}
        />
      </List>
    </RoutineStepWrapper>
  );
};

export default PremiumBasicSelectRoutineType;
