import { connect } from 'react-redux';
import { getAccountLocale } from '../../../selectors';
import { goBackIfHistory } from '../../../ducks/routing';
import State, {
  BaseThunk,
  Dispatch,
  RouterParamsProps,
} from '../../../store/state';
import { redirectToHomeIfNotEligibleForTrial } from '../../../actions/redirectStartTrialFlyover';
import LocationRulesStartTrialFlyover from '../../../components/LocationRules/LocationRulesStartTrialFlyover';
import {
  startManualTrial,
  trackStartManualTrial,
} from '../../../actions/StartTrialActions';
import { ProfileIdField } from '../../../records/profile';
import {
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
} from '../../../helpers/analytics';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps<ProfileIdField>
) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickStartTrial: async () => {
    await dispatch(
      startManualTrial(`/profiles/${profileId}/rules/locationRules`)
    );
    trackStartManualTrial(GenericPageNames.LocationRules, {
      context: FeatureBlockingContexts.FullBlockedFeature,
      reason: ProfileRuleNames.Location,
    });
  },
});

const LocationRulesStartTrialFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRulesStartTrialFlyover);

LocationRulesStartTrialFlyoverContainer.redirect =
  (): BaseThunk => dispatch => {
    // if a user that can't start the trial period access to the url send them to the home page
    dispatch(redirectToHomeIfNotEligibleForTrial());
  };

export type LocationRulesStartTrialFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default LocationRulesStartTrialFlyoverContainer;
