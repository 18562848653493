import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { SetupDeviceInstructionsList } from './SetupDeviceInstructionsList';
import { DevicePlatform } from '../../constants';
import * as qinit from '../../qinit';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { track, Events } from '../../helpers/analytics';
import {
  ActionInput,
  ActionInputIconPosition,
  Dropdown,
  DropdownMenuPlacement,
  DropdownOption,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
} from 'styleguide-react';
import { capitalize } from '../../helpers/string';

export const formName = 'selectAddDeviceInstructions';

export const SelectAddDeviceInstructionsForm: React.FunctionComponent<{
  platform: DevicePlatform;
  title: string;
  onChange: (value: string) => any;
  setSelectedPlatform: (newPlatform: string) => any;
}> = ({ platform, title, onChange, setSelectedPlatform }) => {
  const showDownloadLink =
    platform === DevicePlatform.Windows || platform === DevicePlatform.Mac;

  const downloadLink =
    platform === DevicePlatform.Windows
      ? qinit.application.downloads.windows
      : qinit.application.downloads.mac;

  const platforms: string[] = qinit.tenant.parents_app.platforms_supported;
  const platformsMapping = {
    Android: DevicePlatform.Android,
    iOS: DevicePlatform.Ios,
    Windows: DevicePlatform.Windows,
    Mac: DevicePlatform.Mac,
    Kindle: DevicePlatform.Kindle,
    Chromebook: DevicePlatform.Chromebook,
  };

  const getSelectedPlatformText = (platform = DevicePlatform.None) => {
    switch (platform) {
      case DevicePlatform.None:
        return t('Select device');
      case DevicePlatform.Ios:
        return 'iOS';
      default:
        return capitalize(platform);
    }
  };

  return (
    <FlexLayout
      mainaxis="column"
      flexGrow="0"
      matchParentHeight
      mainaxisAlignment="space-between"
    >
      <FlexLayout mainaxis="column" marginBottom="8">
        <Label testId="selectPlatformForDevice">{title}</Label>
      </FlexLayout>
      <Dropdown
        actionElement={
          <ActionInput
            type="text"
            text={getSelectedPlatformText(platform)}
            readOnly
            testId="selectDevice"
            block
            icon={
              <Icon
                type={IconType.angleDown}
                color={IconColor.primary}
                size={IconSize.lg}
              />
            }
            iconPosition={ActionInputIconPosition.right}
          />
        }
        maxHeight={145}
        onChange={newPlatform => {
          setSelectedPlatform(newPlatform);
          onChange(newPlatform);
        }}
        testId="selectPlatformForDevice"
        menuPlacement={DropdownMenuPlacement.bottom}
        fixedMenuPlacement
      >
        <DropdownOption value={DevicePlatform.None} key={DevicePlatform.None}>
          {t('Select device')}
        </DropdownOption>
        {platforms.map(platform => (
          <DropdownOption
            value={platformsMapping[platform]}
            key={platformsMapping[platform]}
          >
            {platform}
          </DropdownOption>
        ))}
      </Dropdown>
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="space-between"
        flexGrow="0"
        matchParentHeight
      >
        <FlexLayout mainaxis="column" marginTop="8">
          <SetupDeviceInstructionsList platform={platform} />
        </FlexLayout>
      </FlexLayout>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {showDownloadLink && (
          <FlexLayout
            mainaxis="row"
            mainaxisAlignment="flex-start"
            crossaxisAlignment="center"
            marginBottom="48"
            marginTop="32"
          >
            <FlexLayout mainaxis="column" marginRight="8">
              <Icon type={IconType.download} color={IconColor.primary} />
            </FlexLayout>

            <a data-testid="link_protect_device" href={downloadLink}>
              {t('I want to protect this device')}
            </a>
          </FlexLayout>
        )}
      </RenderWhen>
    </FlexLayout>
  );
};

const values = getFormValues(formName);

const mapStateToProps = (
  state,
  { title, platform }: { title: string; platform: DevicePlatform }
) => {
  const formValues = values(state);
  return {
    platform: formValues
      ? (formValues.get('platform') as DevicePlatform)
      : platform,
    title,
  };
};
const mapDispatchToProps = (_state, props) => ({
  onChange: platform => {
    track(Events.KidDevicePlatformValueChanged, {
      target_platform: platform,
    });
  },
  setSelectedPlatform: props.setSelectedPlatform,
});

export const SelectAddDeviceInstructions = reduxForm({
  form: formName,
})(
  connect(mapStateToProps, mapDispatchToProps)(SelectAddDeviceInstructionsForm)
);
