import * as React from 'react';
import * as Moment from 'moment-timezone';
import { Link } from 'react-router';
import { LicenseInfoContainer } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { AccountRecord } from '../../records/account';
import {
  LicenseRecord,
  LicenseType,
  getLicenseDaysLeft,
  licenseLinkTo,
  isAutoRenewal,
  expiresSoon,
  isLicenseActionable,
  isNonExpiringPremium,
  shouldShowConversionElements,
  licenseDisplayName,
} from '../../records/license';
import { expiryDate } from '../../helpers/dates';
import { getColorByLicense } from '../LicenseBadge/LicenseBadge';

const licenseTimeLeft = (
  license: LicenseRecord,
  account: AccountRecord,
  currentTime: Moment.Moment,
  timezone: string
) => {
  const days = getLicenseDaysLeft(license, currentTime, timezone);
  if (license.type === LicenseType.Premium) {
    if (days === 0) {
      return t('Expires today');
    }
    if (days === 1) {
      return t('Expires tomorrow');
    }
    if (days === 2) {
      return t('Expires in 2 days');
    }
    if (days <= 10) {
      return t('Expires in {{days}} days', { days });
    }
    if (days < 30) {
      return t('Expires in less than 30 days');
    }
    const expirationDate = expiryDate(account.locale, license.endDate);
    return (
      <span>
        {t('Expires on {1}{{expirationDate}}{/1}', {
          1: (str: string) => <strong>{str}</strong>,
          expirationDate,
        })}
      </span>
    );
  }
  if (license.type === LicenseType.Trial) {
    if (days === 0) {
      return t('Expires today');
    }
    if (days === 1) {
      return t('Expires tomorrow');
    }
    return t('Expires in {{days}} days', { days });
  }
  return t('Get more devices and features with Premium.');
};

const licenseAction = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  timezone: string
) => {
  if (isAutoRenewal(license.renewal)) {
    return t('Upgrade');
  }
  if (expiresSoon(license, currentTime, timezone)) {
    return t('Renew now');
  }
  return t('Upgrade');
};
interface SideBarLicenseBadgeProps {
  license: LicenseRecord;
  account: AccountRecord;
  currentTime: Moment.Moment;
  showAffiliateBadge?: boolean;
  onBadgeClick: any;
  onClickTryNow: () => void;
  isLinkedToSchool?: boolean;
  onClickStartTrial: () => void;
}

const SideBarLicenseBadge: React.FunctionComponent<
  SideBarLicenseBadgeProps
> = ({
  license,
  account,
  currentTime,
  showAffiliateBadge,
  onBadgeClick,
  onClickTryNow,
  isLinkedToSchool,
  onClickStartTrial,
}: SideBarLicenseBadgeProps) => {
  const shouldShowLicenseBadge = license =>
    !isNonExpiringPremium(license) && shouldShowConversionElements(license);

  if (showAffiliateBadge && license.affiliate.icon) {
    return (
      <LicenseInfoContainer
        affiliateBadgeIconSrc={showAffiliateBadge && license.affiliate.icon}
      />
    );
  }
  // account should be linked to a school and the license subtype is school free
  if (isLinkedToSchool) {
    // if no device added:
    if (account.devicesCount === 0) {
      return (
        <LicenseInfoContainer
          licenseBadgeClick={onClickTryNow}
          licenseBadgeText={t('Try now')}
          licenseSubtitle={t('Try {{shortName}} on your family devices')}
        />
      );
    }

    // if has device and is available for trial
    if (license.isEligibleForManualTrial) {
      return (
        <LicenseInfoContainer
          licenseBadgeClick={onClickStartTrial}
          licenseBadgeText={t('Try now')}
          licenseSubtitle={t('Get more devices and features with Premium.')}
        />
      );
    }

    // if has device but not eligible for trial
    return (
      <LicenseInfoContainer
        licenseLinkTo={licenseLinkTo(
          license,
          currentTime,
          '',
          account.timezone
        )}
        licenseBadgeClick={onBadgeClick(license)}
        licenseBadgeText={t('Upgrade')}
        licenseSubtitle={t('Get more devices and features with Premium.')}
        useAsLink={Link}
      />
    );
  }

  if (shouldShowLicenseBadge(license)) {
    return (
      <LicenseInfoContainer
        licenseBadgeText={licenseAction(license, currentTime, account.timezone)}
        licenseBadgeType={getColorByLicense(license, true)}
        licenseLinkTo={
          isLicenseActionable(license, currentTime, account.timezone)
            ? licenseLinkTo(license, currentTime, '', account.timezone)
            : undefined
        }
        licenseTitle={licenseDisplayName(license).toUpperCase()}
        licenseSubtitle={
          !isAutoRenewal(license.renewal) && (
            <span>
              {licenseTimeLeft(license, account, currentTime, account.timezone)}
            </span>
          )
        }
        licenseBadgeClick={
          isLicenseActionable(license, currentTime, account.timezone)
            ? onBadgeClick(license)
            : undefined
        }
        useAsLink={Link}
      />
    );
  }

  return <React.Fragment />;
};

export default SideBarLicenseBadge;
