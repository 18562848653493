import { fromJS, NonEmptyMapRecord, Map } from 'immutable';
import * as R from 'ramda';
import * as MemoryStorage from 'localstorage-memory';
import { AuthorizationApplication } from '../constants';
import { CLEAR } from './actionNames/app';
import { isTesting } from '../helpers/env';
import { getLocalStorage } from '../sideEffects/browser/storage';

export const PERSISTENT_STORAGE_LOAD = 'PERSISTENT_STORAGE_LOAD';
export const PERSISTENT_STORAGE_SET_STORAGE = 'PERSISTENT_STORAGE_SET_STORAGE';
export const PERSISTENT_STORAGE_SET = 'PERSISTENT_STORAGE_SET';
export const PERSISTENT_STORAGE_SET_IF_NOT_EXIST =
  'PERSISTENT_STORAGE_SET_IF_NOT_EXIST';
export const PERSISTENT_STORAGE_CLEAR = 'PERSISTENT_STORAGE_CLEAR';
export const PERSISTENT_STORAGE_REMOVE = 'PERSISTENT_STORAGE_REMOVE';
export const UPDATE_LAST_INTERACTION_TS = 'UPDATE_LAST_INTERACTION_TS';
export const PERSISTENT_STORAGE_SET_ITEMS = 'PERSISTENT_STORAGE_SET_ITEMS';
export const PERSISTENT_STORAGE_SET_ITEMS_IF_NOT_EXIST =
  'PERSISTENT_STORAGE_SET_ITEMS_IF_NOT_EXIST';

const persistentStorageKeys: Key[] = [
  'closedDateForIosPreConsentTrackingModal',
  'state',
  'wellbeingBannerClosed',
];

export const storageKeys: Key[] = [
  'welcomeModalShown',
  'authorizationApplication',
  'accessToken',
  'refreshToken',
  'parentDeviceId',
  'paymentProvider',
  'installationTs',
  'sessionStartTs',
  'appRateRateTs',
  'appRateFeedbackTs',
  'appRateRemindMeLaterTs',
  'lastAppVersion',
  'lastPushPermission',
  'lastRegistrationId',
  'panicRulesTourShown',
  'externalNavigationAccepted',
  'lastInteractionTs',
  'purchaseStartedTs',
  'appOnboardingFinished',
  'track',
  'userClosedSmartAppBanner',
  'parentAppStandaloneConfirmed',
  'lockState',
  'onboardingTokenData',
  ...persistentStorageKeys,
];

type KeysMap = {
  authorizationApplication: AuthorizationApplication;
  welcomeModalShown: string;
  accessToken: string;
  refreshToken: string;
  parentDeviceId: string;
  paymentProvider: string;
  installationTs: ISODateString;
  sessionStartTs: ISODateString;
  appRateRateTs: ISODateString;
  appRateFeedbackTs: ISODateString;
  appRateRemindMeLaterTs: ISODateString;
  lastAppVersion: string;
  lastPushPermission: string;
  lastRegistrationId: string;
  panicRulesTourShown: string;
  externalNavigationAccepted: string;
  lastInteractionTs: ISODateString;
  purchaseStartedTs: ISODateString;
  appOnboardingFinished: string;
  track: string;
  userClosedSmartAppBanner: string;
  state: string;
  parentAppStandaloneConfirmed: string;
  onboardingTokenData: string;
  closedDateForIosPreConsentTrackingModal: string;
  lockState: string;
  wellbeingBannerClosed: string;
};
export type Key = keyof KeysMap;
type State = NonEmptyMapRecord<KeysMap>;
const initialState: State = fromJS(
  storageKeys.reduce((result, key) => R.set(R.lensProp(key), '', result), {})
);

export default function persistentStorage(state = initialState, action) {
  switch (action.type) {
    case PERSISTENT_STORAGE_LOAD:
      return storageLoadAll();
    case PERSISTENT_STORAGE_SET:
      return storageSaveAll(
        state.set(action.payload.key, action.payload.value)
      );
    case PERSISTENT_STORAGE_SET_IF_NOT_EXIST:
      return storageSaveAll(
        state.set(
          action.payload.key,
          state.get(action.payload.key) || action.payload.value
        )
      );
    case CLEAR:
    case PERSISTENT_STORAGE_CLEAR: {
      const stateToPersist: State = persistentStorageKeys.reduce(
        (acc, persistentKey) => {
          acc[persistentKey] = state.get(persistentKey);
          return acc;
        },
        {} as State
      );

      clearStorageExceptFor(persistentStorageKeys);
      return initialState.merge(stateToPersist);
    }
    case PERSISTENT_STORAGE_REMOVE:
      return storageSaveAll(state.set(action.payload.key, ''));
    case UPDATE_LAST_INTERACTION_TS:
      return storageSaveAll(
        state.set('lastInteractionTs', action.payload as ISODateString)
      );
    case PERSISTENT_STORAGE_SET_ITEMS:
      return storageSaveAll(state.merge(action.payload));
    case PERSISTENT_STORAGE_SET_ITEMS_IF_NOT_EXIST:
      return storageSaveAll(
        state.merge(action.payload.merge(state.filter(key => !!key)))
      );

    default:
      return state;
  }
}

export function setStorageObject(setStorage = window.localStorage) {
  storage = setStorage;
  return {
    type: PERSISTENT_STORAGE_SET_STORAGE,
  };
}

/**
 * Set the underlying storage to an in-memory mock-like object.
 * This is useful to create a session that does not read or write to the actual
 * LocalStorage interface.
 */
export const setMemoryStorageObject = () => setStorageObject(MemoryStorage);

/**
 * Load state from provided values and LocalStorage.
 * Values in LocalStorage will always override provided values, unless they are
 * empty or falsey.
 */
export const setStateFromStorage = () => ({ type: PERSISTENT_STORAGE_LOAD });

export const setItems = (items: Partial<KeysMap>) => ({
  type: PERSISTENT_STORAGE_SET_ITEMS,
  payload: Map<string, string>(items),
});

export const setItemsIfNotExist = (items: Partial<KeysMap>) => ({
  type: PERSISTENT_STORAGE_SET_ITEMS_IF_NOT_EXIST,
  payload: Map<string, string>(items),
});

/**
 * Returns a thunk that resolves to the "value" argument
 */
export function setItem<T extends Key>(key: T, value: KeysMap[T]) {
  if (typeof value !== 'string') {
    qlog(
      'Warning: persistenStorage.setItem will stringify value with Object.protoype.toString()'
    )([key, value]);
  }
  return dispatch => {
    dispatch({
      type: PERSISTENT_STORAGE_SET,
      payload: { key, value: value.toString() },
    });
    return Promise.resolve(value);
  };
}

/**
 * Returns a thunk that resolves to the "value" argument
 */
export const setItemIfNotExist = (key: Key, value: string) => {
  if (typeof value !== 'string') {
    qlog(
      'Warning: persistenStorage.setItem will stringify value with Object.protoype.toString()'
    )([key, value]);
  }
  return dispatch => {
    dispatch({
      type: PERSISTENT_STORAGE_SET_IF_NOT_EXIST,
      payload: { key, value: value.toString() },
    });
    return Promise.resolve(value);
  };
};

export const updateLastInteractionTs =
  (timestamp: string) => (dispatch, getState) => {
    if (!getState().get('app').get('isLoadingInitialData')) {
      return dispatch({
        type: UPDATE_LAST_INTERACTION_TS,
        payload: timestamp,
      });
    }
    return undefined;
  };

export function incrementItem(key: Key) {
  return (dispatch, getState) => {
    const value = parseInt(getState().getIn(['persistentStorage', key]), 10);
    return dispatch(setItem(key, R.inc(value || 0).toString()));
  };
}

export function removeItem(key: Key) {
  return {
    type: PERSISTENT_STORAGE_REMOVE,
    payload: { key },
  };
}

let storage = getLocalStorage();

const storageLoadAll = () =>
  storageKeys.reduce(
    (result, key) => result.set(key, storage.getItem(key) || ''),
    Map<Key, string>() as NonEmptyMapRecord<KeysMap>
  );

const storageSaveAll = (state: State) => {
  storageKeys.forEach(key => storage.setItem(key, state.get(key) || ''));
  return state;
};

const storageClear = () => storage.clear();

const clearStorageExceptFor = (keys: Key[]): void => {
  if (isTesting()) {
    return;
  }
  const storageKeysToKeep = keys
    .map(key => ({
      key,
      value: storage.getItem(key),
    }))
    .filter(({ value }) => value !== null) as {
    key: string;
    value: string;
  }[];

  storageClear();

  storageKeysToKeep.forEach(({ key, value }) => storage.setItem(key, value));
};

export const clearStorage = () => {
  return {
    type: PERSISTENT_STORAGE_CLEAR,
  };
};
