import * as React from 'react';
import { Typography } from 'styleguide-react';
import {
  MINUTES_IN_AN_HOUR,
  WEEKDAYS,
  weekdaysShort3letterTranslations,
  weekdaysShortTranslations,
} from '../../helpers/dates';
import {
  CalendarViewProps,
  CalendarViewData,
  CalendarViewWeekday,
} from './types/CalendarView.types';
import {
  CALENDAR_VIEW_WEEKDAYS,
  ROW_HEIGHT,
  getNowIndicatorPosition,
  calendarViewHoursRange,
} from '../../helpers/calendarView';
import CalendarViewSlot from './CalendarViewSlot';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { classNames, debounce } from '../../helpers';
import { scrollElement } from '../../helpers/dom';

type DailySlots = Record<CalendarViewWeekday, CalendarViewData[]>;

const CALENDAR_VIEW_TEST_ID = 'CalendarView';

const emptyWeekdayBuckets: DailySlots = {
  MO: [],
  TU: [],
  WE: [],
  TH: [],
  FR: [],
  SA: [],
  SU: [],
};

const debouncedScroll = debounce(
  (autoScrollMinutes: number, element: HTMLDivElement) => {
    if (typeof autoScrollMinutes !== 'number') return undefined;

    const scrollValue = (autoScrollMinutes / MINUTES_IN_AN_HOUR) * ROW_HEIGHT;
    scrollElement(element, { top: scrollValue });
  },
  500
);

const CalendarView = ({
  testId,
  slots,
  isMilitaryTime,
  currentTime,
  timezone,
  isLoading = false,
  autoScrollMinutes = undefined,
  onClickSlot,
  onCreateNewSlot,
}: CalendarViewProps) => {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [bodyHasScroll, setBodyHasScroll] = React.useState(false);
  const [nowIndicator, setnowIndicator] = React.useState<{
    weekday: CalendarViewWeekday;
    top: number;
  } | null>(null);

  const [dailySlots, setDailySlots] =
    React.useState<DailySlots>(emptyWeekdayBuckets);

  // separates calendar slots into weekday buckets
  React.useEffect(() => {
    if (!slots) return undefined;
    const tmpDailySlots = { ...emptyWeekdayBuckets };

    for (const slot of slots) {
      tmpDailySlots[slot.weekday] = tmpDailySlots[slot.weekday].concat(slot);
    }

    setDailySlots(tmpDailySlots);
  }, [slots]);

  // positions "now" indicator
  React.useEffect(() => {
    if (!currentTime || !timezone) return undefined;
    const nowIndicatorInfo = getNowIndicatorPosition(currentTime, timezone);
    setnowIndicator(nowIndicatorInfo);
  }, [currentTime, timezone]);

  // autoscrolls the body
  React.useLayoutEffect(() => {
    if (Number.isNaN(autoScrollMinutes) || !bodyRef.current) return undefined;
    debouncedScroll(autoScrollMinutes, bodyRef.current);
  }, [autoScrollMinutes]);

  const onBodyScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop > 0 && !bodyHasScroll) {
      setBodyHasScroll(true);
    }
    if (!e.currentTarget.scrollTop && bodyHasScroll) {
      setBodyHasScroll(false);
    }
  };

  const onCreateNewSlotHandler =
    (weekday: CalendarViewWeekday) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      onCreateNewSlot?.(weekday, '00:00');
    };

  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screenSize => (
        <div
          data-testId={testId ?? CALENDAR_VIEW_TEST_ID}
          className={classNames(
            'par-calendar-view',
            screenSize === ScreenSize.Mobile ? 'par-calendar-view--mobile' : ''
          )}
        >
          <div
            className={classNames(
              'par-calendar-view__header',
              bodyHasScroll ? 'par-calendar-view__header--scrolled' : ''
            )}
          >
            <div className="par-gap" />
            <div className="par-calendar-view__x-axis">
              {WEEKDAYS.map(day => (
                <div key={day} className="par-tick">
                  <Typography type="body1" color="gray" weight="semibold">
                    {screenSize === ScreenSize.Mobile
                      ? weekdaysShortTranslations()[day]
                      : weekdaysShort3letterTranslations()[day]}
                  </Typography>
                </div>
              ))}
            </div>
            <div className="par-fake-scrollbar" />
            {isLoading && <div className="par-loader" />}
          </div>

          <div
            className="par-calendar-view__body"
            onScroll={onBodyScroll}
            ref={bodyRef}
          >
            <div className="par-calendar-view__overflow-wrapper">
              <div className="par-calendar-view__y-axis">
                {calendarViewHoursRange.map(({ ampm, hhmm }) => (
                  <div key={hhmm} className="par-tick">
                    <Typography type="caption1" color="gray">
                      {isMilitaryTime ? hhmm : ampm}
                    </Typography>
                  </div>
                ))}
              </div>

              <div className="par-calendar-view__content">
                <div className="par-calendar-view__h-lines">
                  {calendarViewHoursRange.map(({ hhmm }) => (
                    <div key={hhmm} className="par-line" />
                  ))}
                </div>

                {CALENDAR_VIEW_WEEKDAYS.map(weekday => (
                  <div
                    key={weekday}
                    data-testId={`${
                      testId ?? CALENDAR_VIEW_TEST_ID
                    }--${weekday}`}
                    className="par-calendar-view__day-lane"
                    role="presentation"
                    onClick={onCreateNewSlotHandler(weekday)}
                  >
                    {nowIndicator && nowIndicator.weekday === weekday && (
                      <div
                        className="par-calendar-view__now-indicator"
                        style={{ top: nowIndicator.top }}
                      />
                    )}

                    {(dailySlots[weekday] ?? []).map(slot => (
                      <CalendarViewSlot
                        key={`${slot.uid}-${slot.time}-${slot.duration}-${slot.weekday}`}
                        slot={slot}
                        onClick={onClickSlot}
                        isMobile={screenSize === ScreenSize.Mobile}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </RenderWhen>
  );
};

CalendarView.displayName = 'CalendarView';

export default React.memo(CalendarView);
