import { fromJS, NonEmptyMapRecord, List } from 'immutable';
import * as camelize from 'camelize';
import * as R from 'ramda';
import api from '../api';
import { ContactRecord } from '../records/contact';
import { updatePrevState, ACTION_TARGET_CONTACTS } from './previousState';
import { getContacts } from '../selectors';
import { tapReject } from '../helpers';
import { showErrorToast } from '../containers/AccountSettings/helpers';

export const REQUEST_CONTACTS = 'REQUEST_CONTACTS';
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';
export const UPDATING_CONTACTS = 'RECEIVE_CONTACTS';
export const REQUEST_MODIFY_CONTACTS = 'REQUEST_MODIFY_CONTACTS';
export const RECEIVE_MODIFY_CONTACTS = 'RECEIVE_MODIFY_CONTACTS';
export const REQUEST_MODIFY_CONTACTS_ERROR = 'REQUEST_MODIFY_CONTACTS_ERROR';
export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';

export default function events(
  state: NonEmptyMapRecord<{
    items: List<ContactRecord>;
    isFetching: boolean;
    isUpdating: boolean;
    selectedContact: ContactRecord;
  }> = fromJS({
    items: [],
    isFetching: false,
    isUpdating: false,
    selectedContact: null,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_CONTACTS:
      return state.set('isFetching', true);
    case RECEIVE_CONTACTS:
      return state.merge({
        isFetching: false,
        items: action.payload,
      });
    case REQUEST_MODIFY_CONTACTS:
      return state.set('isUpdating', true);
    case RECEIVE_MODIFY_CONTACTS:
      return state.set('isUpdating', false);
    case REQUEST_MODIFY_CONTACTS_ERROR:
      return state.set('isUpdating', false);
    case SET_SELECTED_CONTACT:
      return state.set('selectedContact', action.payload);
    default:
      return state;
  }
}

function requestContacts() {
  return {
    type: REQUEST_CONTACTS,
  };
}

export function receiveContacts(payload) {
  return {
    type: RECEIVE_CONTACTS,
    payload: List(camelize(payload)).map(ContactRecord.fromPayload),
  };
}

export function fetchContacts() {
  return dispatch => {
    dispatch(requestContacts());
    return api.contacts.get({}).then(R.compose(dispatch, receiveContacts));
  };
}

function shouldFetchContacts(state) {
  return !state.get('contacts').get('isFetching');
}

export function fetchContactsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchContacts(getState())) {
      return dispatch(fetchContacts());
    }
    return Promise.resolve(false);
  };
}

export function updateContacts(contacts) {
  return (dispatch, getState) => {
    const prevContacts = getContacts(getState())
      .map(ContactRecord.serialize)
      .toJS();
    dispatch(updatePrevState(prevContacts, ACTION_TARGET_CONTACTS));
    dispatch(receiveContacts(contacts.map(ContactRecord.serialize).toJS()));
  };
}

function requestModifyContacts() {
  return {
    type: REQUEST_MODIFY_CONTACTS,
  };
}

function receiveModifyContacts() {
  return {
    type: RECEIVE_MODIFY_CONTACTS,
  };
}

export function requestProfilesRulesError() {
  return {
    type: REQUEST_MODIFY_CONTACTS_ERROR,
  };
}

export function createContact(record) {
  return (dispatch, getState) => {
    dispatch(requestModifyContacts());
    const contact = ContactRecord.serialize(record);
    return api.contacts
      .post(contact)
      .then(
        R.tap(json => {
          dispatch(
            updateContacts(
              getContacts(getState()).push(
                ContactRecord.fromPayload(camelize(json))
              )
            )
          );
          dispatch(receiveModifyContacts());
        })
      )
      .catch(
        tapReject(error => {
          dispatch(requestProfilesRulesError());
          dispatch(showErrorToast(error));
        })
      );
  };
}

export function setSelectedContact(contact?: ContactRecord) {
  return {
    type: SET_SELECTED_CONTACT,
    payload: contact,
  };
}

export function deleteContact(record) {
  return (dispatch, getState) => {
    dispatch(requestModifyContacts());
    return api.contact
      .delete({ contactId: record.id })
      .then(
        R.tap(() => {
          dispatch(
            updateContacts(
              getContacts(getState()).filter(
                R.complement(R.propEq('id', record.id))
              )
            )
          );
          dispatch(receiveModifyContacts());
        })
      )
      .catch(
        tapReject(error => {
          dispatch(requestProfilesRulesError());
          dispatch(showErrorToast(error));
        })
      );
  };
}
