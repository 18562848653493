export enum LicenseLabel {
  None = 'None',
  Xs = 'XS',
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  Xl = 'XL',
  Xxl = 'XXL',
}

export type LicenseSubtype =
  | 'free'
  | 'trial'
  | 'premium_grandfather'
  | 'premium_basic'
  | 'school_free'
  | 'premium_complete';
