import * as React from 'react';
import { Tooltip, Typography } from 'styleguide-react';
import { getPositionInCalendar } from '../../helpers/calendarView';
import {
  CalendarViewData,
  CalendarViewSlotProps,
} from './types/CalendarView.types';
import { classNames } from '../../helpers';

const getTooltipContent = (description?: string | string[]) => {
  if (!description) return '';
  if (!Array.isArray(description)) return description;
  return (
    <div>
      {description.map(text => (
        <React.Fragment key={text}>
          <span>{text}</span>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const getSlotLabel = (
  label: CalendarViewData['label'],
  shortLabel: CalendarViewData['shortLabel'],
  isMobile: boolean
) => {
  if (isMobile && shortLabel) return shortLabel;
  if (!isMobile && label) return label;
  return null;
};

const CalendarViewSlot = ({
  slot,
  isMobile = false,
  onClick,
}: CalendarViewSlotProps) => {
  const {
    time,
    duration,
    description,
    label,
    shortLabel,
    bgBolor,
    tooltipLight,
    isGrouped,
  } = slot;
  const topAndHeightStyle = getPositionInCalendar(time, duration);
  const tooltipTitle = getTooltipContent(description);
  const slotLabel = getSlotLabel(label, shortLabel, isMobile);

  const onClickHandler =
    (slot: CalendarViewData<Record<string, unknown>>) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(slot);
    };

  const renderSlot = () => (
    <button
      title={label}
      type="button"
      className={classNames(
        'par-calendar-view__slot',
        bgBolor ? `par-calendar-view__slot--color-${bgBolor}` : undefined,
        isGrouped
          ? 'par-grouped par-calendar-view__slot--color-grouped'
          : undefined
      )}
      style={topAndHeightStyle}
      onClick={onClickHandler(slot)}
    >
      {slotLabel && (
        <div className="par-text">
          <Typography renderAs="div" type="caption2" weight="semibold">
            {slotLabel}
          </Typography>
        </div>
      )}
    </button>
  );

  if (description) {
    return (
      <Tooltip
        title={tooltipTitle as string}
        label={undefined}
        placement="bottom"
        isMobile={isMobile}
        className={
          tooltipLight ? 'par-calendar-view__tooltip-light' : undefined
        }
      >
        {renderSlot()}
      </Tooltip>
    );
  }

  return renderSlot();
};

CalendarViewSlot.displayName = 'CalendarViewSlot';

export default React.memo(CalendarViewSlot);
