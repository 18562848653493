import { List as ImmutableList } from 'immutable';
import { Moment } from 'moment';
import * as React from 'react';
import {
  GroupHeader,
  Layout,
  List,
  Spinner,
  EmptyPlaceholder,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { isTodaysDate, formatDateNice } from '../../helpers/dates';
import { isUsingAMPMFormat } from '../../helpers/languages';
import { getDocument } from '../../sideEffects/browser';
import { DayRecord } from '../../selectors';
import LocationItem from './LocationItem';

class LocationList extends React.Component<
  {
    days: ImmutableList<DayRecord>;
    locale: string;
    country: string;
    currentTime: Moment;
    timezone: string;
    showFetchingMore: boolean;
    hasMoreEvents: boolean;
    handleMore: () => void;
    kidName: string;
    clickLocation: (event: any) => void;
  },
  {
    scrollToTopVisible: boolean;
    selectedItemIndex: number;
    selectedItemDay: string;
  }
> {
  constructor(props, context?) {
    super(props, context);
    this.state = {
      scrollToTopVisible: false,
      selectedItemIndex: -1,
      selectedItemDay: '',
    };
  }

  componentDidMount() {
    this.registerScrollHandlers();
  }

  componentWillUnmount() {
    this.unregisterScrollHandlers();
    this.setState({ selectedItemIndex: -1, selectedItemDay: '' });
  }

  registerScrollHandlers = () => {
    getDocument().addEventListener('scroll', this.onScroll, true);
  };

  unregisterScrollHandlers = () => {
    getDocument().removeEventListener('scroll', this.onScroll, true);
  };

  onScroll = ({ target }) => {
    const { handleMore } = this.props;

    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
      handleMore();
    }

    const isOverTheTop = target.scrollTop > window.innerHeight;
    // eslint-disable-next-line react/destructuring-assignment
    if (isOverTheTop !== this.state.scrollToTopVisible) {
      this.setState({ scrollToTopVisible: isOverTheTop });
    }
  };

  setSelectedItem = (selectedItemIndex, selectedItemDay) => {
    const {
      selectedItemIndex: selectedItemIndexState,
      selectedItemDay: selectedItemDayState,
    } = this.state;

    if (
      selectedItemIndex === selectedItemIndexState &&
      selectedItemDay === selectedItemDayState
    ) {
      this.setState({ selectedItemIndex: -1, selectedItemDay: '' });
    } else {
      this.setState({ selectedItemIndex, selectedItemDay });
    }
  };

  render() {
    const {
      days,
      currentTime,
      timezone,
      locale,
      country,
      showFetchingMore,
      kidName,
      hasMoreEvents,
      clickLocation,
    } = this.props;

    const { selectedItemIndex, selectedItemDay } = this.state;

    const isUsingAMPM = isUsingAMPMFormat(country);

    return (
      <Layout paddingTop="24" paddingBottom="40">
        {days.map(
          day =>
            day.events.size > 0 && (
              <React.Fragment>
                <Layout marginBottom="24">
                  <GroupHeader>
                    {isTodaysDate(currentTime, day.day, timezone)
                      ? t('Today')
                      : formatDateNice(day.day, timezone, locale)}
                  </GroupHeader>
                </Layout>

                <List allowCustomListItemStyle={LocationItem}>
                  {day.events.map((event, index) => (
                    <LocationItem
                      key={event[0].key}
                      country={country}
                      index={index}
                      event={event[0]}
                      isUsingAMPM={isUsingAMPM}
                      timezone={timezone}
                      clickLocation={() => clickLocation(event[0])}
                      selectItem={this.setSelectedItem}
                      selectedItemIndex={selectedItemIndex}
                      selectedItemDay={selectedItemDay}
                    />
                  ))}
                </List>
              </React.Fragment>
            )
        )}
        {hasMoreEvents ? (
          <div className="ProfileFetchingNew">
            <div
              style={{ visibility: showFetchingMore ? 'visible' : 'hidden' }}
            >
              <Spinner color="regular" size="small" />
            </div>
          </div>
        ) : (
          <EmptyPlaceholder
            text={t("This is the start of {{name}}'s timeline", {
              name: kidName,
            })}
          />
        )}
      </Layout>
    );
  }
}

export default LocationList;
