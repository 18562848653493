import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { navigateToAddPlace } from '../../actions/FamilyLocatorActions';
import AddNewPlace from '../../components/FamilyLocator/AddNewPlace';
import State from '../../store/state';
import { getPlaces, getLocationPathname } from '../../selectors/index';

const mapStateToProps = (state: State) => ({
  buttonText: t('Add to your places'),
  places: getPlaces(state),
  locationPathname: getLocationPathname(state),
});

const mapDispatchToProps = (dispatch, { params: { mapResizingHandler } }) => ({
  onClickAddNewPlace: (placesSize, locationPathname) => {
    mapResizingHandler();
    return dispatch(navigateToAddPlace(placesSize, locationPathname));
  },
});

const AddNewPlaceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewPlace);

export default AddNewPlaceContainer;
