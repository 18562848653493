import * as React from 'react';
import ProductOffer from './ProductOffer';
import { AccountRecord, ProductRecord } from '../../records';
import WelcomeBack from './WelcomeBack';
import { useEnsureHidePreconcertCookieModal } from './helpers';

/*
This page is intended to open through an iframe from the public site.
To manage the size, it is necessary to use @iframe-resizer/child loaded in root component.
*/

interface DirectPurchaseProps {
  account: AccountRecord;
  product?: ProductRecord;
  productWithLongerDuration?: ProductRecord;
  isPremiumLicense: boolean;
  onUpgradePlan: (url: string) => void;
  onContinueToCheckout: (url: string) => void;
  goToHome: () => void;
}

const DirectPurchase = ({
  account,
  product,
  productWithLongerDuration,
  isPremiumLicense,
  onUpgradePlan,
  onContinueToCheckout,
  goToHome,
}: DirectPurchaseProps) => {
  useEnsureHidePreconcertCookieModal();

  return isPremiumLicense ? (
    <WelcomeBack goToHome={goToHome} />
  ) : (
    <ProductOffer
      account={account}
      onUpgradePlan={onUpgradePlan}
      onContinueToCheckout={onContinueToCheckout}
      product={product}
      productWithLongerDuration={productWithLongerDuration}
    />
  );
};

export default DirectPurchase;
