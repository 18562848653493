import { createSelector } from 'reselect';
import { t } from '../lib/i18n';
import {
  LocationServicesDisabledCard,
  LocationTrackingOffCard,
  PermissionsRevokedCard,
} from '../components/FamilyLocator/MissingPermissionsCard';
import {
  LocationTypes,
  getFormattedAddress,
  irregularLocationTypes,
} from '../records/location';
import type { LocationRecord } from '../records/location';
import { ProfileRulesRecord } from '../records';
import { PLATFORM_ANDROID, PLATFORM_IOS } from '../constants';
import { getProfileRules } from './profileRules';
import { getProfile } from './profileRaw';
import { ProfileRecord } from '../records/profile/types/Profile.types';

export enum ProfileStatus {
  LOCATION_TRACKING_OFF = 'LOCATION_TRACKING_OFF',
  LOCATION_SERVICES_DISABLED = 'LOCATION_SERVICES_DISABLED',
  LAST_LOCATION_ADDRESS_VALID = 'LAST_LOCATION_ADDRESS_VALID',
  LAST_LOCATION_ADDRESS_UNAVAILABLE = 'LAST_LOCATION_ADDRESS_UNAVAILABLE',
  PERMISSIONS_REVOKED = 'PERMISSIONS_REVOKED',
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
}

export const irregularStatus = [
  ProfileStatus.LOCATION_TRACKING_OFF,
  ProfileStatus.LOCATION_SERVICES_DISABLED,
  ProfileStatus.PERMISSIONS_REVOKED,
  ProfileStatus.UNKNOWN_STATUS,
];

export const cardComponents = {
  [ProfileStatus.LOCATION_SERVICES_DISABLED]: LocationServicesDisabledCard,
  [ProfileStatus.LOCATION_TRACKING_OFF]: LocationTrackingOffCard,
  [ProfileStatus.PERMISSIONS_REVOKED]: PermissionsRevokedCard,
};

export const permissionsInstructionsModalNames = {
  [PLATFORM_ANDROID]: {
    [ProfileStatus.PERMISSIONS_REVOKED]:
      'MissingPermissionsInstructionsAndroidModal',
    [ProfileStatus.LOCATION_SERVICES_DISABLED]:
      'LocationServicesDisabledInstructionsAndroidModal',
  },
  [PLATFORM_IOS]: {
    [ProfileStatus.PERMISSIONS_REVOKED]:
      'MissingPermissionsInstructionsIosModal',
    [ProfileStatus.LOCATION_SERVICES_DISABLED]:
      'LocationServicesDisabledInstructionsIosModal',
  },
};

export const locationTypeToProfileStatus = {
  [LocationTypes.LOCATION_SERVICES_DISABLED]:
    ProfileStatus.LOCATION_SERVICES_DISABLED,
  [LocationTypes.PERMISSIONS_REVOKED]: ProfileStatus.PERMISSIONS_REVOKED,
};

export const getProfileLocationStatus = createSelector(
  getProfile,
  getProfileRules,
  (profile: ProfileRecord, profileRules: ProfileRulesRecord) => {
    if (!profileRules) {
      return ProfileStatus.UNKNOWN_STATUS;
    }

    if (!profileRules.location.enabled) {
      return ProfileStatus.LOCATION_TRACKING_OFF;
    }

    if (profile.location.type in locationTypeToProfileStatus) {
      return locationTypeToProfileStatus[profile.location.type];
    }

    return profile.location.addressLines.length
      ? ProfileStatus.LAST_LOCATION_ADDRESS_VALID
      : ProfileStatus.LAST_LOCATION_ADDRESS_UNAVAILABLE;
  }
);

export const eventHasIrregularStatus = (location: LocationRecord): boolean =>
  irregularLocationTypes.includes(location.type);

export const getEventLocationDescription = (
  location: LocationRecord
): string => {
  const descriptions = {
    [LocationTypes.LOCATION_SERVICES_DISABLED]: t('Location Services are OFF'),
    [LocationTypes.PERMISSIONS_REVOKED]: t('Location permissions needed'),
  };

  if (location.type in descriptions) {
    return descriptions[location.type];
  }

  return getFormattedAddress(location);
};
