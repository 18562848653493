import * as React from 'react';
import { Dropdown, DropdownOption, TimePickerInput } from 'styleguide-react';
import timePickerHelpers from './helpers';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  NativeTimePickerProps,
  TimePickerProps,
  WebTimePickerProps,
} from './types/TimePicker.types';
import useTimePicker from './useTimePicker';

const {
  createTimeSlotsTimeRange,
  getDateWithHourAndMinutes,
  getFormattedTime,
  getTimeValue,
  isMobileBuildPlatform,
} = timePickerHelpers;

// https://developer.android.com/reference/android/R.style.html#Theme_Holo_InputMethod
// This android theme code is used to show the swiper picker instead of the radial clock
const THEME_HOLO_INPUTMETHOD = 16973951;

const NativeTimePicker = ({
  testId,
  className,
  value: selectedTime = '00:00',
  isMilitaryTime = true,
  titleText,
  okText,
  cancelText,
  clearText,
  onChange,
}: NativeTimePickerProps) => {
  const onClickHandler = () => {
    const minuteInterval = 1;
    const [hour, minutes] = selectedTime.split(':');
    const date = getDateWithHourAndMinutes(hour, minutes);

    global.cordova.plugins.DateTimePicker.show({
      mode: 'time',
      date,
      minuteInterval,
      success: (selectedDate?: Date) => {
        if (!selectedDate) return;
        const time = getTimeValue(selectedDate);
        onChange(time);
      },
      android: {
        is24HourView: isMilitaryTime,
        theme: THEME_HOLO_INPUTMETHOD,
      },
      ...(titleText && { titleText }),
      ...(okText && { okText }),
      ...(cancelText && { cancelText }),
      ...(clearText && { clearText }),
    });
  };

  return (
    <React.Fragment>
      <TimePickerInput
        testId={testId}
        className={className}
        value={getFormattedTime(isMilitaryTime, selectedTime)}
        onClick={onClickHandler}
        onClickTrigger={onClickHandler}
        readOnly
      />
    </React.Fragment>
  );
};

const BrowserTimePicker = ({
  className,
  testId,
  value: selectedTime = '00:00',
  dropdownHeight = 240,
  isMilitaryTime = true,
  minuteInterval = 15,
  onChange,
  onValidationError,
}: WebTimePickerProps) => {
  const timeRange = createTimeSlotsTimeRange(isMilitaryTime, minuteInterval);
  const [dropdownActive, setDropdownActive] = React.useState(false);
  const { inputValue, invalid, onInputChangeHandler, onDropdownChangeHandler } =
    useTimePicker(selectedTime, isMilitaryTime);

  React.useEffect(() => {
    if (onChange && inputValue.value) onChange(inputValue.value.trim());
  }, [inputValue]);

  React.useEffect(() => {
    if (onValidationError) onValidationError(invalid);
  }, [invalid]);

  return (
    <Dropdown
      testId={testId}
      className={className}
      classNameList={className ? `${className}__list` : undefined}
      showSelectedOption
      maxHeight={dropdownHeight}
      // See footnote [1] for onChange
      onChange={dropdownActive ? onDropdownChangeHandler : undefined}
      onActiveChange={(isActive: boolean) => setDropdownActive(isActive)}
      actionElement={
        <TimePickerInput
          testId={testId}
          placeholder={isMilitaryTime ? 'HH:mm' : 'hh:mm am'}
          value={inputValue.text}
          onChange={onInputChangeHandler}
          hasError={invalid}
        />
      }
    >
      {timeRange.map(({ text, value }) => (
        <DropdownOption
          text={text}
          value={value}
          key={value}
          selected={value === inputValue.value}
        />
      ))}
    </Dropdown>
  );
};

const MobileBrowserTimePicker = ({
  className,
  testId,
  value: selectedTime = '00:00',
  dropdownHeight = 240,
  isMilitaryTime = true,
  minuteInterval = 15,
  onChange,
  onValidationError,
}: WebTimePickerProps) => {
  const [dropdownActive, setDropdownActive] = React.useState(false);
  const timeRange = createTimeSlotsTimeRange(isMilitaryTime, minuteInterval);
  const { inputValue, invalid, onInputChangeHandler, onDropdownChangeHandler } =
    useTimePicker(selectedTime, isMilitaryTime);

  React.useEffect(() => {
    if (onChange && inputValue.value) onChange(inputValue.value.trim());
  }, [inputValue]);

  React.useEffect(() => {
    if (onValidationError) onValidationError(invalid);
  }, [invalid]);

  return (
    <div>
      <Dropdown
        // This Dropdown does not have an action element. It's triggered programmatically.
        actionElement={<React.Fragment />}
        active={dropdownActive}
        onActiveChange={(active: boolean) => setDropdownActive(active)}
        // See footnote [1] for onChange
        onChange={dropdownActive ? onDropdownChangeHandler : undefined}
        asModal
        testId={testId}
        className={className}
        classNameList={className ? `${className}__list` : undefined}
        showSelectedOption
        maxHeight={dropdownHeight}
      >
        {timeRange.map(({ text, value }) => (
          <DropdownOption
            text={text}
            value={value}
            key={value}
            selected={value === inputValue.value}
          />
        ))}
      </Dropdown>
      <TimePickerInput
        testId={testId}
        placeholder={isMilitaryTime ? 'HH:mm' : 'hh:mm am'}
        value={inputValue.text}
        onChange={onInputChangeHandler}
        hasError={invalid}
        // To avoid opening the native keyboard when opening the dropdown we make the input as readonly.
        readOnly={dropdownActive}
        onClickTrigger={() => setDropdownActive(true)}
      />
    </div>
  );
};

/**
 * __Timepicker component__
 *
 * This component offers a way to use the time picker component
 * in different build environments (browser, mobile browser, PASX).
 *
 * This component has 3 variants:
 * - browser time picker:
 *    - when user clicks on input component it opens the dropdown and sets focus in input
 *    - it allows to select from dropdown and simultaneously set value in input
 * - mobile browser time picker:
 *    - is shown when the breakpoint is `ScreenSize.Mobile`
 *    - when user clicks on input component it only focuses in input
 *    - to open the dropdown user has to click the clock icon trigger
 *    - the dropdown variant is of type modal
 * - PASX
 *    - when user click on the input it open the OS native time picker
 *
 * More info:
 * - This component allows for 24h clock and also am/pm clock
 * - Allows to set minutes increment for the dropdown list
 * - accepts a validation function to which it injects the validation status as first param
 */
const TimePicker = (props: TimePickerProps) => {
  if (isMobileBuildPlatform()) return <NativeTimePicker {...props} />;
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize =>
        screenSize === ScreenSize.Mobile ? (
          <MobileBrowserTimePicker {...props} />
        ) : (
          <BrowserTimePicker {...props} />
        )
      }
    </RenderWhen>
  );
};

TimePicker.displayName = 'TimePicker';

export default React.memo<TimePickerProps>(TimePicker);

/**
 * Footnotes:
 * [1]    Dropdown component even when it's not active, it still listens to the ENTER keypress.
 *        When enter key is pressed it sets the value to the dropdown "active option" which initially is 00:00.
 *        We have an editable text input for the time and when user types a time and presses enter, the value
 *        is overwritten by the dropdown "active option" which we do not want
 *        So we only listen to onChange when dropdown is active.
 *
 */
