import { connect } from 'react-redux';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import OnboardingDownload from '../../components/Onboarding/Views/OnboardingDownload';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import {
  trackOpenedBurgerMenu,
  PageNames,
  track,
  Events,
} from '../../helpers/analytics';
import {
  getOnboardingProfile,
  getOnboardingDeviceType,
  getOnboardingDevicePlatform,
  getOnboardingPendingDeviceConnection,
} from '../../selectors';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { clickDownloadButton } from '../../actions/onboarding/OnboardingAddDeviceActions';

import { t } from '../../lib/i18n';
import store from '../../store';
import {
  socialShareCopy,
  navigateToFinish,
  navigateToAddChild,
} from '../../actions/onboarding/OnboardingActions';
import { trackablePage } from '../../helpers/hocs';

import { ONBOARDING_CURRENT_DEVICE_ANSWER_YES } from '../../ducks/appOnboarding';
import { mapUserAgentOsToPlatform } from '../../helpers/userAgent';

const mapStateToProps = state => ({
  profile: getOnboardingProfile(state),
  onboardingDeviceType: getOnboardingDeviceType(state),
  onboardingDevicePlatform: getOnboardingDevicePlatform(state),
  pendingDeviceConnection: getOnboardingPendingDeviceConnection(state),
  platform: mapUserAgentOsToPlatform(),
});

const mapDispatchToProps = dispatch => ({
  onClickDownloadButton: () => dispatch(clickDownloadButton()),
  onClickSocialShareCopy: () => {
    track(Events.OnboardingClickedCopyLink);
    return dispatch(socialShareCopy());
  },
  onClickContinue: () => dispatch(navigateToFinish()),
  onClickBack: () => dispatch(navigateToAddChild()),
});

const OnboardingDownloadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(OnboardingDownload, props =>
    props.currentDeviceAnswer === ONBOARDING_CURRENT_DEVICE_ANSWER_YES
      ? PageNames.OnboardingDownloadProtectionCurrentDevice
      : PageNames.OnboardingDownloadProtectionOtherDevice
  )
);

export type OnboardingDownloadProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default OnboardingWrapper({
  activeStep: 2,
  showSupport: true,
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
      mainReducedSize: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(OnboardingDownloadContainer);
