import * as React from 'react';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import {
  DetailsItemRecord,
  VideoDetailsRecord,
  UrlDetailsPageRecord,
} from '../../records/urlDetails';
import { EventRecord } from '../../records';
import Date from '../Date/Date';
import IconYoutubeWithBrandName from '../Icons/IconYoutubeWithBrandName';
import { FlexLayout, Svg, TransparentButton } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacingProps } from 'styleguide-react/build/components/Layout/types';

const UrlDetailsVideoInfo: React.FunctionComponent<{
  event?: EventRecord;
  items: List<DetailsItemRecord>;
  onClick: () => any;
}> = ({ event, items, onClick }) => {
  const renderInfo = (spacing: LayoutSpacingProps) => (
    <FlexLayout mainaxis="row" minHeight="160">
      <FlexLayout
        mainaxis="row"
        minWidth="50%"
        mainaxisAlignment="center"
        marginBottom="24"
        minHeight="160"
        backgroundColor="gray-ligther"
        crossaxisAlignment="center"
        {...spacing}
      >
        <TransparentButton onClick={onClick} fillAvailableSpace>
          <Svg width="104" height="104">
            <IconYoutubeWithBrandName />
          </Svg>
        </TransparentButton>
      </FlexLayout>
      <FlexLayout mainaxis="row">
        {event ? eventData(event, items) : undefined}
      </FlexLayout>
    </FlexLayout>
  );

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        {renderInfo({ marginRight: '48', padding: '24' })}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        {renderInfo({ marginRight: '8', padding: '8' })}
      </RenderWhen>
    </React.Fragment>
  );
};

const getVideoCount = (items: List<DetailsItemRecord>) =>
  items.count(item => item instanceof VideoDetailsRecord);

const getPagesCount = (items: List<DetailsItemRecord>) =>
  items.count(item => item instanceof UrlDetailsPageRecord);

const eventData = (event: EventRecord, items) => (
  <FlexLayout mainaxis="column" noWrap>
    <Date time={event.time} />
    <div>
      <strong>{t('Videos:')}</strong> {getVideoCount(items)}
    </div>
    <div>
      <strong>{t('Pages:')}</strong> {getPagesCount(items)}
    </div>
  </FlexLayout>
);

export default UrlDetailsVideoInfo;
