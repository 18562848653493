import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { shouldShowTwoYearsPromotionModal } from '../../../actions/UpgradeActions';
import ProductDurationPicker from '../ProductDurationPicker';
import UpgradeAppleInfo from '../UpgradeAppleInfo';
import UpgradeHero from '../UpgradeHero';
import { PAYMENT_PROVIDER_APPLE } from '../../../constants';
import type { UpgradeProps } from '../../../containers/UpgradeContainer';
import { isBrowserPlatform } from '../../../helpers';
import { trackClickedPurchaseButton } from '../../../helpers/analytics';
import { t } from '../../../lib/i18n';
import { ProductRecord } from '../../../records';
import ProductListB from './ProductListB';
import UpgradePremiumFeaturesB from './UpgradePremiumFeaturesB';

const UpgradeContentB = ({
  productList,
  account,
  planDuration,
  isPurchaseInProgress,
  isRenew,
  productListDedicatedSupport,
  isEnabledDedicatedSupportV2,
  areTwoYearProductsAvailable,
  areUpsellProducts,
  paymentProvider,
  showTwoYearsPromotionModal,
  onClickOpenLink,
  onClickProduct,
}: UpgradeProps) => {
  const [state, setState] = React.useState({ selectedProductCode: '' });
  const shouldRenderDurationPicker = () =>
    isBrowserPlatform() && areTwoYearProductsAvailable && !areUpsellProducts;
  const shouldRenderComparisonTable =
    productList.some(product => product?.licenseSubtype === 'premium_basic') &&
    productList.some(product => product?.licenseSubtype === 'premium_complete');
  const handleClickProduct = (product: ProductRecord) => {
    if (isPurchaseInProgress) return;

    trackClickedPurchaseButton(paymentProvider, product.code);
    if (
      shouldShowTwoYearsPromotionModal(
        product.duration,
        isRenew,
        areTwoYearProductsAvailable,
        areUpsellProducts
      )
    ) {
      showTwoYearsPromotionModal(product.code);
    } else {
      setState({ selectedProductCode: product.code });
      onClickProduct(product.code);
    }
  };

  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      flexShrink="0"
      marginBottom="32"
    >
      <UpgradeHero />
      {shouldRenderDurationPicker() && <ProductDurationPicker />}
      <ProductListB
        account={account}
        planDuration={planDuration}
        products={productList}
        productListDedicatedSupport={productListDedicatedSupport}
        selectedProductCode={state.selectedProductCode}
        areUpsellProducts={areUpsellProducts}
        onClickProduct={handleClickProduct}
        isPurchaseInProgress={isPurchaseInProgress}
        isEnabledDedicatedSupportV2={isEnabledDedicatedSupportV2}
      />
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        marginLeft="24"
        marginRight="24"
      >
        <p className="exp-upgrade-content__additional-info">
          {t(
            '*Calls & messages for iOS requires a Mac computer. Unlimited includes your family devices and does not apply to schools or businesses.'
          )}
        </p>
      </FlexLayout>
      {paymentProvider === PAYMENT_PROVIDER_APPLE && (
        <UpgradeAppleInfo onClickOpenLink={onClickOpenLink} />
      )}
      {shouldRenderComparisonTable && <UpgradePremiumFeaturesB />}
    </FlexLayout>
  );
};

export default UpgradeContentB;
