import { t } from '../../lib/i18n';
import { GenderMap, Gender } from './types/Gender.types';

export const genderMap: GenderMap = {
  MALE: Gender.MALE,
  FEMALE: Gender.FEMALE,
  UNSPECIFIED: Gender.UNSPECIFIED,
};

export const defaultPicture = (picture?: string) => {
  if (picture) {
    const [prefix, name] = picture.split('_');
    if (prefix === 'avatar') {
      // for avatars coming from Desktop
      return `boy_${name}`;
    }
    return picture;
  }

  return '';
};

export const getAvatarTabs = (
  genders: Gender[],
  count: number,
  selected?: string
) =>
  genders.map(gender => {
    const prefix = gender === Gender.MALE ? 'boy' : 'girl';
    return {
      label: gender === Gender.MALE ? t('Boy') : t('Girl'),
      gender,
      active: (selected && selected.indexOf(prefix) === 0) as boolean,
      avatars: Array.from(Array(count).keys()).map(
        i => `${prefix}_${i + 1}.png`
      ),
    };
  });
