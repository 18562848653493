import * as React from 'react';
import { FlexLayout, Typography } from 'styleguide-react';
import ProgressBar from '../../../ProgressBar/ProgressBar';
import { classNames } from '../../../../helpers';
// eslint-disable-next-line import/no-unresolved
import { LayoutContentSpacing } from 'styleguide-react/build/components/Layout/types';

interface UnifiedNowCardTimeProps {
  screenTime: string;
  usedScreenTimePercentage: number;
  subtitle?: string;
  progressBarClassName?: string;
  width?: LayoutContentSpacing;
}

const UnifiedNowCardTime = ({
  screenTime,
  usedScreenTimePercentage,
  subtitle,
  progressBarClassName,
  width = '160',
}: UnifiedNowCardTimeProps) => {
  return (
    <FlexLayout
      mainaxis="column"
      className="par-unified-now-card__time-wrapper"
      testId="unified-now-card-time"
      width={width}
    >
      <Typography
        type="h4"
        weight="semibold"
        className="par-unified-now-card__time-bar-title"
      >
        {screenTime}
      </Typography>
      <div
        className="par-unified-now-card__time-bar-wrapper"
        data-testId="unified-now-card-time__progress-bar"
      >
        <ProgressBar
          className={classNames(
            'par-unified-now-card__time-bar',
            progressBarClassName
          )}
          percent={usedScreenTimePercentage}
          warning={false}
        />
      </div>
      {Boolean(subtitle) && (
        <div className="par-unified-now-card__time-subtitle">{subtitle}</div>
      )}
    </FlexLayout>
  );
};

export default UnifiedNowCardTime;
