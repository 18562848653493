import * as React from 'react';
import { List as ListInm } from 'immutable';
import { ProductRecord, AccountRecord } from '../../../records';
import { getEquivalentProductWithDedicatedSupport } from '../../../selectors';
import { ProductDuration } from '../../../records/product';

import { classNames } from '../../../helpers';

import CustomPlanBoxB from './CustomPlanBoxB';

interface ProductListBProps {
  products: ListInm<ProductRecord>;
  productListDedicatedSupport?: ListInm<ProductRecord>;
  account: AccountRecord;
  onClickProduct: (product: ProductRecord) => any;
  selectedProductCode: null | string;
  planDuration: ProductDuration;
  areUpsellProducts: boolean;
  isPurchaseInProgress: boolean;
  isEnabledDedicatedSupportV2: boolean;
}

const ProductListB = ({
  products,
  productListDedicatedSupport = ListInm(),
  account,
  onClickProduct,
  selectedProductCode,
  planDuration,
  areUpsellProducts,
  isPurchaseInProgress,
  isEnabledDedicatedSupportV2,
}: ProductListBProps) => {
  return (
    <div
      className={classNames(
        'exp-product-list',
        isPurchaseInProgress ? 'exp-product-list--disabled' : ''
      )}
    >
      {products.map((product: ProductRecord) => {
        const productDedicatedSupport = !product.isDedicatedSupport
          ? getEquivalentProductWithDedicatedSupport(
              product,
              productListDedicatedSupport
            )
          : undefined;

        const upsellProduct = areUpsellProducts && !product.current;
        const props = {
          account,
          product,
          productDedicatedSupport,
          isPurchaseInProgress,
          planDuration,
          selectedProductCode,
          upsellProduct,
          areUpsellProducts,
          onClickProduct,
          isEnabledDedicatedSupportV2,
        };
        return <CustomPlanBoxB key={product.code} {...props} />;
      })}
    </div>
  );
};

export default ProductListB;
