import * as React from 'react';
import ScrollManager from './ScrollManager';
import store from '../../../../store/index';

const useKeepScroll = (
  el: React.RefObject<HTMLDivElement>,
  scrollKey?: string
): void => {
  const isBackHistory = () => {
    const { action } = store
      .getState()
      .getIn(['routing', 'locationBeforeTransitions']);

    return action === 'POP';
  };

  const calculateScrollPosition = (): number | undefined => {
    return el.current?.scrollTop;
  };

  const restoreScrollPosition = () => {
    if (scrollKey) {
      const managerInstance = ScrollManager.getInstance();
      const settings = managerInstance.get(scrollKey);

      if (
        settings &&
        el.current &&
        isBackHistory() &&
        settings.position !== null
      ) {
        managerInstance.reset(scrollKey);

        if (el.current) {
          el.current.scrollTop = settings.position;
        }
      }
    }
  };

  const setScrollPosition = (scrollKey: string, value: number) => {
    if (scrollKey) {
      ScrollManager.getInstance().updatePosition(scrollKey, value);
    }
  };

  React.useLayoutEffect(() => {
    if (scrollKey) {
      const managerInstance = ScrollManager.getInstance();
      if (!isBackHistory()) {
        managerInstance.reset(scrollKey);
      } else if (managerInstance.hasPosition(scrollKey)) {
        restoreScrollPosition();
      }
    }

    return () => {
      const positionOnUnmount = calculateScrollPosition();

      if (scrollKey && positionOnUnmount && !isBackHistory()) {
        setScrollPosition(scrollKey, positionOnUnmount);
      }
    };
  });
};

export default useKeepScroll;
