import * as React from 'react';
import { DelegationTopBarProps } from './types';
import { t } from '../../../lib/i18n';
import {
  AlertBox,
  AlertBoxType,
  Icon,
  IconType,
  Layout,
  Link,
} from 'styleguide-react';

export const DelegationTopBar = ({
  onClickDelegation,
}: DelegationTopBarProps) => {
  return (
    <Layout width="100%" marginBottom="24">
      <AlertBox
        showInfoIcon
        fullWidth
        type={AlertBoxType.info}
        icon={<Icon type={IconType.rocket} />}
        rounded
      >
        {t(
          'Want to apply rules on school devices to extend protection and visibility? {1}Set up now{/1}',
          {
            1: (str: string) => <Link onClick={onClickDelegation}>{str}</Link>,
          }
        )}
      </AlertBox>
    </Layout>
  );
};
