import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import LocationRulesFlyover from './LocationRulesFlyover';
import type { LocationRulesStartTrialFlyoverProps } from '../../containers/ProfileRules/LocationRules/LocationRulesStartTrialFlyoverContainer';
import { SchoolTrialDuration } from '../../constants';

const START_TRIAL_FLYOVER_TEST_ID = 'location-rules-start-trial-flyover';

const LocationRulesStartTrialFlyover = ({
  accountLocale,
  onClickClose,
  onClickStartTrial,
}: LocationRulesStartTrialFlyoverProps) => {
  return (
    <LocationRulesFlyover
      accountLocale={accountLocale}
      testId={START_TRIAL_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Try for free'),
        onClick: onClickStartTrial,
        testId: `${START_TRIAL_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android and iOS')}
      topbarText={t('Try location monitoring free for {{days}} days!', {
        days: SchoolTrialDuration,
      })}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default LocationRulesStartTrialFlyover;
