import * as React from 'react';
import WellbeingCard from '../shared/WellbeingCard';
import { FlexLayout, Link, Typography } from 'styleguide-react';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import { t } from '../../../lib/i18n';
import WellbeingGradientBackgroundImageSection from '../shared/WellbeingGradientBackgroundImageSection';

interface WellbeingSubscribedProps {
  nextActivity: string;
  onNeedHelpClick: () => void;
}

const WellbeingSubscribed = ({
  nextActivity,
  onNeedHelpClick,
}: WellbeingSubscribedProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <WellbeingGradientBackgroundImageSection
          showImage={!isDesktop(screenSize)}
        >
          <FlexLayout mainaxis="column" marginTop="24">
            <WellbeingCard type="therapy" />
          </FlexLayout>
          <FlexLayout mainaxis="column" marginTop="24">
            <WellbeingCard type="activities" nextActivity={nextActivity} />
          </FlexLayout>
          <FlexLayout mainaxis="column" marginTop="48" marginBottom="48">
            <Link onClick={onNeedHelpClick}>
              <Typography type="body1" weight="semibold">
                {t('Need help? Get in touch')}
              </Typography>
            </Link>
          </FlexLayout>
        </WellbeingGradientBackgroundImageSection>
      )}
    </RenderWhen>
  );
};

export default WellbeingSubscribed;
