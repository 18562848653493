import * as React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
  Icon,
  IconType,
  Modal,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import type { SafeNetworkAllowModalProps } from '../../containers/Modal/SafeNetworkAllowModalContainer';

const SafeNetworkAllowModal = ({
  onClose,
  onClickHelpLink,
  profileName,
}: SafeNetworkAllowModalProps): JSX.Element => {
  const childName = profileName || '';

  return (
    <Modal
      testId="safe-network-allow-modal"
      className="par-safe-network-allow-modal"
      title={t('School Safe Networks')}
      header={{
        icon: (
          <Icon type={IconType.graduationCap} color="secondary" size="lg" />
        ),
      }}
      onClickClose={onClose}
      width={modalDimensions.medium.width}
      buttons={[
        <Button
          key="safeNetworkAllowModalCancel__Button"
          onClick={onClose}
          size={ButtonSize.medium}
        >
          {t('OK')}
        </Button>,
        <Button
          key="safeNetworkAllowModalLearnMore__Button"
          onClick={onClickHelpLink}
          size={ButtonSize.medium}
          buttonType={ButtonType.plain}
          color={ButtonColor.secondary}
        >
          {t('Learn more')}
        </Button>,
      ]}
    >
      <p>
        {t(
          'Safe Networks enable school protection to temporarily override your Qustodio web filtering and time limit rules. Devices automatically connect to Safe Networks when on school Wi-Fi.'
        )}
      </p>
      <p>
        {t(
          'This provides {{childName}} with improved security and better connectivity.',
          {
            childName,
          }
        )}
      </p>
    </Modal>
  );
};

export default SafeNetworkAllowModal;
