import * as Immutable from 'immutable';
import * as R from 'ramda';
import { toast as toastHelper } from '../helpers/toast';
import { ensureArray } from '../helpers';
import { ToastAction } from './types/action/ToastAction.types';

export const CLEAR = 'CLEAR';
export const RELOAD_REQUEST = 'RELOAD_REQUEST';
export const RELOAD_RECEIVE = 'RELOAD_RECEIVE';
export const RELOAD_ERROR = 'RELOAD_ERROR';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS';
export const SHOW_TOAST = 'SHOW_TOAST';

export const TOAST_ICON_WARNING = Symbol('TOAST_ICON_WARNING');
export const TOAST_ICON_TICK = Symbol('TOAST_ICON_TICK');

const getReactToastType = R.flip(R.prop)({
  [TOAST_ICON_WARNING]: 'warning',
  [TOAST_ICON_TICK]: 'success',
});

/**
 * A toast reducer that does not actually save the toast into the state,
 * it just fires materialize.css's $.toast() as a side effect.
 * This could be changed to be fully in the state when the need arrives, e.g.
 * if we need logic for clicking on a toast, a toast timeout, only showing one
 * toast at a time, etc.
 */
export default function toast(state = Immutable.Map({}), action) {
  switch (action.type) {
    case SHOW_TOAST:
      toastHelper(
        action.payload.texts,
        getReactToastType(action.payload.type),
        action.payload.icon
      );
      return state;
    default:
      return state;
  }
}

/**
 * Show a toast with given text and icon.
 * "text" may be a string or array of string.
 */
export function showToast(
  text: string | string[],
  type: string | symbol,
  icon?: JSX.Element
): ToastAction {
  return {
    type: SHOW_TOAST,
    payload: { texts: ensureArray(text), type, icon },
  };
}
