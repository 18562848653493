import * as React from 'react';
import PageLayout from '../Layout/PageLayout';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { t } from '../../lib/i18n';
import {
  AlertBox,
  AlertBoxType,
  Card,
  FlexLayout,
  Icon,
  IconFamily,
  IconType,
  Label,
  Layout,
  Switch,
  TransparentButton,
  Typography,
} from 'styleguide-react';
import ProfileRuleCard from '../ProfileRuleCard/ProfileRuleCard';
import type { ProfileRulesProps } from '../../containers/ProfileRules/ProfileRulesContainer';
import { getIcon, getSubTitle, getTitle } from './helpers';
import PageSectionLayout from '../Layout/PageSectionLayout';

const DelegationAlertBox = () => {
  return (
    <AlertBox
      data-testId="AlertBox--get-started"
      type={AlertBoxType.info}
      icon={<Icon type={IconType.graduationCap} family={IconFamily.solid} />}
      showInfoIcon
      fullWidth
      className="par-profile-rules-page__alert-box-delegation"
    >
      {t(
        'Apply rules on school devices to extend protection and visibility outside of school hours'
      )}
    </AlertBox>
  );
};

const ProfileRules = ({
  profileId,
  rulesList,
  onClick,
  getFooterType,
  shouldShowDelegation,
  isCombinedProfile,
  isProfileDelegatedToTheAccount,
  shouldRulesBeEnabled,
  shouldShowTheDelegationAlertBox,
  isFetchingStudentData,
  showInAppRatingPopup,
  onToggleDelegation,
  onLoadStudentPolicies,
  onClickDelegationRuleInfo,
}: ProfileRulesProps) => {
  React.useEffect(() => {
    showInAppRatingPopup();
    if (shouldShowDelegation) {
      onLoadStudentPolicies(Number(profileId));
    }
  }, []);

  const getPageSubtitle = (
    isCombinedDashboard: boolean,
    isProfileDelegatedToTheAccount: boolean,
    screenSize: ScreenSize
  ): string | undefined => {
    if (isCombinedDashboard) {
      if (!isProfileDelegatedToTheAccount) {
        return t(
          'Select the cards below to customize protection on {1}personal devices{/1}.',
          {
            1: str => <strong>{str}</strong>,
          }
        );
      }
      return t('Select the cards below to customize protection.');
    }

    if (screenSize === ScreenSize.Desktop)
      return t(
        'Select the cards below to customize protection and create a safe digital environment.'
      );
    return undefined;
  };

  const pageSubtitle = (
    isCombinedDashboard: boolean,
    isProfileDelegatedToTheAccount: boolean,
    screenSize: ScreenSize
  ) => {
    return (
      <Typography type="body1">
        {getPageSubtitle(
          isCombinedDashboard,
          isProfileDelegatedToTheAccount,
          screenSize
        )}
      </Typography>
    );
  };

  const renderContent = (screen: ScreenSize) => {
    const subtitleText = getPageSubtitle(
      isCombinedProfile,
      isProfileDelegatedToTheAccount,
      screen
    );
    return (
      <React.Fragment>
        {!shouldShowTheDelegationAlertBox && subtitleText && (
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <FlexLayout mainaxis="row">
              <FlexLayout
                mainaxis="row"
                width="100%"
                margin="16"
                marginLeft="8"
                textAlignement="left"
              >
                {pageSubtitle(
                  isCombinedProfile,
                  isProfileDelegatedToTheAccount,
                  screen
                )}
              </FlexLayout>
            </FlexLayout>
          </RenderWhen>
        )}
        {shouldShowDelegation && (
          <Card
            fullWidth
            className="par-profile-rules-list__delegation-wrapper"
            testId="delegation-wrapper"
          >
            <FlexLayout
              mainaxis="row"
              flexBasis="100%"
              mainaxisAlignment="space-between"
              crossaxisAlignment="center"
            >
              <Label
                htmlFor="SwitchEnableDelegation"
                className="par-profile-rules-page__delegation-label"
              >
                {t('Apply rules on school devices')}
              </Label>
              <TransparentButton
                className="par-profile-rules-page__delegation-label-info"
                onClick={onClickDelegationRuleInfo}
                testId="delegation-info-button"
              >
                <Icon type={IconType.infoCircle} color="secondary" />
              </TransparentButton>
              <Switch
                id="SwitchEnableDelegation"
                testId="enable-delegation"
                checked={isProfileDelegatedToTheAccount}
                disabled={isFetchingStudentData}
                onClick={ev =>
                  onToggleDelegation(
                    Number(profileId),
                    (ev.target as HTMLInputElement).checked
                  )
                }
              />
            </FlexLayout>
          </Card>
        )}
        <Layout testId="profile-rules-list" className="par-profile-rules-list">
          {rulesList.map(rule => (
            <ProfileRuleCard
              key={rule}
              testId={rule}
              active={false}
              title={getTitle(rule)}
              subtitle={getSubTitle(rule)}
              icon={getIcon(rule)}
              onClick={() =>
                profileId && shouldRulesBeEnabled
                  ? onClick(Number(profileId), rule)
                  : undefined
              }
              footer={getFooterType(rule)}
              notAvailable={!shouldRulesBeEnabled}
            />
          ))}
        </Layout>
      </React.Fragment>
    );
  };

  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screen => (
        <PageLayout
          title={t('Rules')}
          subtitle={
            !shouldShowTheDelegationAlertBox
              ? pageSubtitle(
                  isCombinedProfile,
                  isProfileDelegatedToTheAccount,
                  screen
                )
              : undefined
          }
          configuration={[
            {
              screenSize: ScreenSize.Desktop,
              mainReducedSize: true,
              columns: 1,
            },
            {
              screenSize: ScreenSize.Tablet,
              mainReducedSize: true,
              columns: 1,
            },
            {
              screenSize: ScreenSize.Mobile,
              mainColumnBackgroundColor: 'gray-ligther',
              columns: 2,
            },
          ]}
          actions={[{ actionName: 'back' }]}
          className="par-profile-rules-page"
        >
          {screen === ScreenSize.Desktop && shouldShowTheDelegationAlertBox && (
            <PageSectionLayout placeholder="before-page-title">
              {DelegationAlertBox()}
            </PageSectionLayout>
          )}

          {renderContent(screen)}

          {screen !== ScreenSize.Desktop && shouldShowTheDelegationAlertBox && (
            <PageSectionLayout placeholder="after-page-title">
              {DelegationAlertBox()}
            </PageSectionLayout>
          )}
        </PageLayout>
      )}
    </RenderWhen>
  );
};

export default ProfileRules;
