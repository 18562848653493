import { connect } from 'react-redux';
import { getAccountLocale } from '../../selectors';
import { goBackIfHistory } from '../../ducks/routing';
import State, { Dispatch } from '../../store/state';
import {
  GenericPageNames,
  ProfileRuleNames,
  trackProtectADeviceBlockedFeature,
} from '../../helpers/analytics';
import { navigateToAddDeviceAction } from '../../actions/ProfileActions';
import YoutubeAddDeviceFlyover from '../../components/YoutubeRules/YoutubeAddDeviceFlyover';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickAddDevice: () => {
    trackProtectADeviceBlockedFeature(
      GenericPageNames.YouTubeRules,
      ProfileRuleNames.Youtube
    );
    dispatch(navigateToAddDeviceAction());
  },
});

export type YoutubeAddDeviceFlyoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const YoutubeAddDeviceFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubeAddDeviceFlyover);

export default YoutubeAddDeviceFlyoverContainer;
