import * as React from 'react';
import { Banner, Layout } from 'styleguide-react';
import { Link } from 'react-router';
import { LicenseType } from '../../../records/license';
import { t } from '../../../lib/i18n';
import { ConversionTopBarProps } from './types';

const getExpirationCopy = (
  licenseType: LicenseType,
  days: number,
  url: string
): string => {
  const isPremium = licenseType === LicenseType.Premium;
  return t(
    isPremium
      ? 'Your Premium plan expires in {{days}} days - {1}Renew now for seamless protection!{/1}'
      : 'Your Premium trial expires in {{days}} days - {1}Upgrade now to keep advanced protection!{/1}',
    {
      days,
      1: (str: string) => (
        <Link className="TopBar__link" to={url} data-testid="TopBar_action">
          {str}
        </Link>
      ),
    }
  );
};

const getBannerType = (daysToExpire: number): string =>
  daysToExpire <= 2 ? 'error' : 'warning';

type LimitExceededTopBarProps = Pick<ConversionTopBarProps, 'conversionLink'>;

export const LimitExceededTopBar: React.FC<LimitExceededTopBarProps> = ({
  conversionLink,
}: LimitExceededTopBarProps) => (
  <Banner type="warning" fullWidth rounded>
    <Link className="TopBar__link" to={conversionLink}>
      <span data-testid="TopBar_description" className="Topbar__description">
        {t('Your account is blocked!')}
      </span>
    </Link>
  </Banner>
);

type ExpirationTimeTopBarProps = Pick<
  ConversionTopBarProps,
  'license' | 'daysToExpire' | 'conversionLink'
>;

export const ExpirationTimeTopBar: React.FC<ExpirationTimeTopBarProps> = ({
  license,
  daysToExpire,
  conversionLink,
}: ExpirationTimeTopBarProps) => (
  <Layout width="100%" marginBottom="24">
    <Banner
      type={getBannerType(daysToExpire)}
      fullWidth
      rounded
      testId="conversion-top-bar"
    >
      <span data-testid="TopBar_description">
        {getExpirationCopy(license.type, daysToExpire, conversionLink)}
      </span>
    </Banner>
  </Layout>
);

export const ConversionTopBar: React.FC<ConversionTopBarProps> = ({
  license,
  daysToExpire,
  conversionLink,
  isProfileLimitExceeded,
}: ConversionTopBarProps) =>
  isProfileLimitExceeded ? (
    <LimitExceededTopBar conversionLink={conversionLink} />
  ) : (
    <ExpirationTimeTopBar
      license={license}
      daysToExpire={daysToExpire}
      conversionLink={conversionLink}
    />
  );
