import { Record } from 'immutable';
import { snakeCaseToCamelCase } from '../../api/mappings/case';
import {
  SignaturesActivity,
  SignaturesActivityRecordType,
  SignaturesActivityMethods,
} from './types/signatures.types';

export const SignaturesActivityRecord = Record<
  SignaturesActivity,
  SignaturesActivityMethods
>({
  id: '',
  name: '',
  categoryName: '',
  iconUrl: '',
  usagePercentage: 0,
  recommendation: 'NOT_RATED',
});

SignaturesActivityRecord.fromPayload = payload => {
  const camelized = snakeCaseToCamelCase(payload);
  const usagePercentage = Math.round(camelized.usagePercentage);
  return SignaturesActivityRecord({
    ...camelized,
    usagePercentage,
  }) as SignaturesActivityRecordType;
};

SignaturesActivityRecord.serialize = record => ({
  ...record.toJS(),
});
