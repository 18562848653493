import * as React from 'react';
import { List } from 'immutable';
import {
  List as AppList,
  ExpandCollapse,
  ExpandCollapseRowItem,
  FlexLayout,
} from 'styleguide-react';
import AppRulesCard, { AppRulesCardProps } from './AppRulesCard';
import { AppRuleRecord, makeAppKey } from '../../records/profileRules';
import flags from '../../sideEffects/flags';
import { replaceS3Url } from '../../helpers';
import AppRulesRightElement from './AppRulesRightElement';
import { groupAppsByCategory } from '../../businessLogic/profileRules/apps';
import { AppCategories } from '../../ducks/appCategories';
import AppRulesGroupedSubtitle from './AppRulesGroupedSubtitle';
import AppRulesGroupedTotalApps from './AppRulesGroupedTotalApps';

export interface AppRulesCardListProps {
  profileName: string;
  list: List<AppRuleRecord>;
  isBlockedUnsupportedBrowsers: boolean;
  enabled: boolean;
  categories: AppCategories;
  totalAppsByCategory: { [key: string]: number };
  searchQuery?: string;
  onSelectStatus: AppRulesCardProps['onSelectStatus'];
  onClickApp: AppRulesCardProps['onClickApp'];
}

const GroupedContent = ({
  list,
  enabled,
  isBlockedUnsupportedBrowsers,
  categories,
  totalAppsByCategory,
  onSelectStatus,
  onClickApp,
}: Omit<AppRulesCardListProps, 'profileName'>) => {
  const groupedAppList = groupAppsByCategory(list);

  return (
    <div>
      {Array.from(categories.valueSeq().toArray()).map((category, index) => {
        const catApps = groupedAppList[category.categoryId] ?? [];
        const totalFilteredApps = catApps.length ?? 0;
        const isDisabled = !enabled || totalFilteredApps === 0;
        const totalAppsInCategory =
          totalAppsByCategory[category.categoryId] ?? 0;
        return (
          <ExpandCollapse
            leftImgSrc={category.icon}
            key={category.categoryId}
            title={category.name}
            testId={category.categoryId}
            rightElement={
              <AppRulesGroupedTotalApps
                categoryAppsTotal={totalAppsInCategory}
                filteredAppsTotal={totalFilteredApps}
              />
            }
            isDisabled={isDisabled}
            initialOpenState={index === 0}
          >
            {catApps.map(app => (
              <ExpandCollapseRowItem
                key={makeAppKey(app)}
                iconSrc={replaceS3Url(app.thumbnail)}
                title={app.name}
                subtitle={<AppRulesGroupedSubtitle app={app} />}
                rightElement={
                  <AppRulesRightElement
                    app={app}
                    enabled={enabled}
                    isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
                    onSelectStatus={onSelectStatus}
                    onClickApp={onClickApp}
                  />
                }
                className="par-util-word-wrap"
              />
            ))}
          </ExpandCollapse>
        );
      })}
    </div>
  );
};

const ListContent = ({
  list,
  enabled,
  isBlockedUnsupportedBrowsers,
  onSelectStatus,
  onClickApp,
}: Omit<
  AppRulesCardListProps,
  'profileName' | 'categories' | 'totalAppsByCategory'
>) => {
  return (
    <AppList
      allowCustomListItemStyle={AppRulesCard}
      listItemPaddingBottom="8"
      listItemPaddingTop="8"
      listItemMarginBottom="16"
      disabled={!enabled}
      testId="AppRulesAppList"
    >
      {list.map(app => (
        <AppRulesCard
          key={makeAppKey(app)}
          app={app}
          enabled={enabled}
          isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
          onSelectStatus={onSelectStatus}
          onClickApp={onClickApp}
        />
      ))}
    </AppList>
  );
};

const AppRulesCardList: React.FunctionComponent<AppRulesCardListProps> = ({
  list,
  isBlockedUnsupportedBrowsers,
  enabled,
  categories,
  totalAppsByCategory,
  searchQuery,
  onSelectStatus,
  onClickApp,
}: AppRulesCardListProps) => (
  <FlexLayout mainaxis="column">
    {flags.useAppCategories.isEnabled() && !searchQuery ? (
      <GroupedContent
        list={list}
        enabled={enabled}
        onClickApp={onClickApp}
        onSelectStatus={onSelectStatus}
        isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
        categories={categories}
        totalAppsByCategory={totalAppsByCategory}
      />
    ) : (
      <ListContent
        list={list}
        enabled={enabled}
        onClickApp={onClickApp}
        onSelectStatus={onSelectStatus}
        isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
      />
    )}
  </FlexLayout>
);

export default AppRulesCardList;
