import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { StudentSchoolRecordOperations } from './studentSchool';
import { Student, StudentPayload } from './types/Student.type';

export const StudentRecordOperations = getRecordOperations<
  Student,
  StudentPayload
>({
  defaultFields: {
    id: -1,
    uid: '',
    firstName: '',
    lastName: '',
    school: StudentSchoolRecordOperations.create(),
    status: 'pending',
  },
  recordDependencies: [
    {
      field: 'school',
      dependencyRecordOperations: StudentSchoolRecordOperations,
    },
  ],
});
