import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { RemoveAllExceptionsModalProps } from '../../../containers/Routines/Modal/RemoveAllExceptionsModalContainer';

const getTitleCopy = (type: RemoveAllExceptionsModalProps['type']) => {
  if (type === 'APP') return t('Remove app exceptions?');
  if (type === 'WEB') return t('Remove website category exceptions?');
};

const getBodyCopy = (
  type: RemoveAllExceptionsModalProps['type'],
  policyAction: RemoveAllExceptionsModalProps['policyAction']
) => {
  if (type === 'APP') {
    if (policyAction === 'POLICY_ACTION_ALLOWED')
      return t('All apps will be allowed during this routine.');
    if (policyAction === 'POLICY_ACTION_BLOCKED')
      return t('All apps will be blocked during this routine.');
  }
  if (type === 'WEB') {
    if (policyAction === 'POLICY_ACTION_ALLOWED')
      return t('All website categories will be allowed during this routine.');
    if (policyAction === 'POLICY_ACTION_BLOCKED')
      return t('All website categories will be blocked during this routine.');
  }
};

const RemoveAllExceptionsModal = ({
  profileUid,
  routineUid,
  mode,
  type,
  policyAction,
  onClickConfirm,
  onClickClose,
}: RemoveAllExceptionsModalProps) => {
  const MODAL_TEST_ID = 'remove-all-exception-routine-modal';

  const onConfirm = () => {
    if (profileUid && mode) {
      onClickConfirm(profileUid, routineUid, mode, type);
    }
  };

  return (
    <Modal
      testId={MODAL_TEST_ID}
      header={{
        icon: (
          <Icon
            type={IconType.trashAlt}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.error}
          />
        ),
      }}
      title={getTitleCopy(type)}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-remove`}
          key="button1"
          onClick={onConfirm}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Remove')}
        </Button>,
        <Button
          testId={`${MODAL_TEST_ID}-cancel`}
          key="button2"
          onClick={onClickClose}
          size={ButtonSize.medium}
          disabled={false}
          buttonType={ButtonType.secondary}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout
        mainaxis="column"
        height="100%"
        testId={`${MODAL_TEST_ID}__body`}
      >
        <ModalStyledText>{getBodyCopy(type, policyAction)}</ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default RemoveAllExceptionsModal;
