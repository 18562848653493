/* eslint-disable @typescript-eslint/no-explicit-any */
import { inAppPurchaseError } from './types/inAppPurchaseError.type';

export class PurchaseError extends Error {
  code: number | undefined;

  transactionId: string | undefined;

  response: any;

  constructor(
    error: Error | inAppPurchaseError | string,
    transactionId?: string,
    response?: any
  ) {
    if (typeof error === 'string') {
      super(error);
      this.transactionId = transactionId;
      this.response = response;
    } else if (
      !(error instanceof Error) &&
      error !== null &&
      error.code &&
      error.message
    ) {
      super(`${error.message} (code: ${error.code})`);
      this.code = error.code;
      this.transactionId = transactionId;
      this.response = response;
    } else {
      super();
    }
  }
}
