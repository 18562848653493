import { combineEpics, ofType } from 'redux-observable';
import { switchMap, first } from 'rxjs/operators';
import { LocationChangeAction } from 'react-router-redux';
import { AppEpicWithoutStateEffect, AppAction } from '../types';
import { doWithoutStateEffect } from '../operators';
import {
  isProfilePage,
  isProfileSubPage,
  ofLocationChangeActionWhen,
} from '../router';
import { Dependencies } from '../middleware';
import { not } from '../../helpers/predicates';
import { forkJoin } from 'rxjs';
import {
  getActivityTimelineFilterBy,
  getProfileActiveRoutineUid,
  getProfileId,
} from '../../selectors';
import { getProfileTopBarTrackingFormatted } from './ProfileTopBar';

export const sendProfileStatus = (
  values: {
    cardState;
    devicesStatus;
    profileTopNotification;
    activeRoutineUid;
    activeTimelineFilter;
  },
  analytics: Dependencies['analytics']
) => {
  const { format, trackPageLoaded, GenericPageNames } = analytics;

  const data = format({
    options: {
      nowCardStatus: values.cardState?.toLowerCase(),
      devicesStatus: values.devicesStatus,
      profileTopNotification: values.profileTopNotification,
      routineActive: values.activeRoutineUid,
      activeTimelineFilter: values.activeTimelineFilter,
    },
  });

  trackPageLoaded(GenericPageNames.Profile, data);
};

const sendLoadPageAnalytics = (analytics: Dependencies['analytics']) => {
  const { page, PageNames } = analytics;
  page(PageNames.ProfileActivity);
};

/*
ensureOfProfilePage: 
Due to analytics specifications, the metrics should only be sent when the profile page is accessed for the first time. 
There is a corner case when editing the card pause because closing the modal redirects back to the profile page. 

This operator prevents navigation actions when the source of navigation is the pause modal.
*/
const ensureOfProfilePage = ofLocationChangeActionWhen({
  to: isProfilePage,
  from: not(isProfileSubPage),
});

export const nowCardStatusEpic: AppEpicWithoutStateEffect<
  LocationChangeAction
> = ($actions, $state, { analytics }) => {
  return $actions.pipe(
    ensureOfProfilePage,
    switchMap(() => {
      const $unifiedNowCardLoading = $actions.pipe(
        ofType('NOW_CARD_LOADED_STATE'),
        first()
      );
      const $smartDeviceListLoading = $actions.pipe(
        ofType('SMART_DEVICES_UPDATE_LOADED'),
        first()
      );
      const $topProfileNotificationBarLoading = $actions.pipe(
        getProfileTopBarTrackingFormatted
      );
      return forkJoin([
        $unifiedNowCardLoading,
        $smartDeviceListLoading,
        $topProfileNotificationBarLoading,
      ]).pipe(
        doWithoutStateEffect(values => {
          const cardState = values[0].payload.state;
          const devicesStatus = values[1].payload.devices;
          const profileTopNotification = values[2].payload.state;
          const profileId = getProfileId($state.value);
          const activeTimelineFilter = getActivityTimelineFilterBy(
            $state.value
          );
          const activeRoutineUid = getProfileActiveRoutineUid(
            $state.value,
            profileId
          );

          sendProfileStatus(
            {
              cardState,
              devicesStatus,
              profileTopNotification,
              activeRoutineUid,
              activeTimelineFilter,
            },
            analytics
          );
        })
      );
    })
  );
};

export const loadProfileEpic: AppEpicWithoutStateEffect<AppAction> = (
  $actions,
  _$state,
  { analytics }
) => {
  return $actions.pipe(
    ensureOfProfilePage,
    doWithoutStateEffect(() => sendLoadPageAnalytics(analytics))
  );
};

export const profileTrackingEpics = combineEpics(
  nowCardStatusEpic,
  loadProfileEpic
);
