import { ascend, prop } from 'ramda';
import { ScheduleRecord } from '../../records/routines/schedule/types/Schedule.types';
import {
  MINS_IN_A_WEEK,
  MINUTES_IN_WEEK_STARTING_ON,
  getTimeMinutesOfWeek,
} from '../../helpers/dates';
import { RoutineRecord } from '../../records/routines/types/Routine.types';
import { List, Map, Record } from 'immutable';
import * as moment from 'moment';
import {
  isPremiumGrandfather,
  isValidLicenseSubtype,
} from '../../records/license/helpers';
import { LicenseSubtype } from '../../records/license/types/License.types';
import { RoutineFeatureAccessLevel } from '../../components/Routines/routines.types';
import { isRoutinePaused } from '../../ducks/routines/helpers';
import { Datetime } from '../../types/dates';
import { isFeatureEnabled } from '../features';
import { Feature } from '../../records/features/types';

type Day = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';

export interface ScheduleEvent {
  day: Day;
  uid: string;
  routineUid: string;
  /** Minutes of week */
  start: number;
  /** Minutes of week */
  end: number;
  durationMinutes: number;
  /** HH:mm */
  startTime: string;
}

const toWeekMinutes = (day: Day, startTime: string, duration: number) => {
  const [hours, minutes] = startTime.split(':');
  const dayMinutes = Number(hours) * 60 + Number(minutes);
  const start = dayMinutes + MINUTES_IN_WEEK_STARTING_ON[day];
  const end = start + duration;
  return [start, end];
};

const toEventPerDay = (day: Day, schedule: ScheduleRecord) => {
  const { startTime, durationMinutes, uid, routineUid } = schedule;
  const [start, end] = toWeekMinutes(day, startTime, durationMinutes);
  return { day, uid, routineUid, start, end, startTime, durationMinutes };
};

const isOverlapped = (currEvent: ScheduleEvent, nextEvent?: ScheduleEvent) => {
  if (!nextEvent) return false;
  if (currEvent.uid === nextEvent.uid) return false;
  return currEvent.end > nextEvent.start;
};

const normalizeEventEndTime = (event: ScheduleEvent): ScheduleEvent => {
  if (event.end <= MINS_IN_A_WEEK) return event;
  return {
    ...event,
    end: event.end % MINS_IN_A_WEEK,
  };
};

const toScheduleEventsPerDay = (
  schedule: ScheduleRecord
): Array<ScheduleEvent> =>
  schedule.weekdays.map(day => toEventPerDay(day, schedule));

/**
 * Converts `ScheduleRecord` -> `ScheduleEvent` for each weekday
 * in the `ScheduleRecord` and orders them by `start` prop of the event.
 * */
export const scheduleRecordsToEvents = (
  schedules: ScheduleRecord[]
): ScheduleEvent[] => {
  return schedules.flatMap(toScheduleEventsPerDay).sort(ascend(prop('start')));
};

/**
 * Returns first overlapped event, if there is no overlap return undefined.
 *
 * For this algorithm works correctly, a series of heuristics must be derived from the uuids register for each routine.
 * 1. newSchedule is the new schedule to be added, it may not have uuid
 * 2. registeredSchedules are already registered, that is, they all already have a uuid
 * 3. The pause of the routine influences the overlap, only those schedules that belong to a routine that is not paused will be taken into account
 *
 * how does this algorithm work?
 *
 * 1. Filter out registered schedules belonging to paused routines.
 * 2. Combine target and registered schedules and convert them to events; one event per schedule's weekday.
 * 4. Start and end minutes of each event are calculated relative to the week minutes.
 * 5. Sort by start time.
 * 6. Iterate through each event to check for overlaps with the next event.
 * 7. Overlaps are discriminated for events that have the same uuid.
 * 8. We only check the last event against the first if the last one spans beyond the current week. (sun -> mon)
 * 9. If an overlap is found, store the conflicting event pair.
 * 10. Find the conflicting schedule from the registered schedules based on the overlapped events pair.
 *
 * @see https://qustodio.atlassian.net/wiki/spaces/FAM/pages/3817439521/Solving+Routine+Conflicts#Algorithm
 */
export const findEventOverlap = (
  targetSchedule: ScheduleRecord,
  registeredSchedules: ScheduleRecord[],
  routines: Map<string, RoutineRecord>
) => {
  if (!registeredSchedules.length) return undefined;

  // we do not want to consider schedules that belong to a paused routine
  const enabledRegisteredSchedules = registeredSchedules.filter(
    schedule => !isRoutinePaused(routines.get(schedule.routineUid))
  );

  if (!enabledRegisteredSchedules.length) return undefined;

  const conflictedEventPair: ScheduleEvent[] = [];
  const combinedSchedulesList = [...enabledRegisteredSchedules, targetSchedule];
  scheduleRecordsToEvents(combinedSchedulesList).forEach(
    (currentEvent, index, eventsList) => {
      const nextIndex = (index + 1) % eventsList.length;

      // we only want to test the last event against the first if the last one overflows to the next day
      const isLastEvent = nextIndex === 0;
      if (isLastEvent && currentEvent.end <= MINS_IN_A_WEEK) return;

      // otherwise if it's the last event and it overflows to the next week (sunday, 22:00 +5h), we rollover
      // the end value to the start of the week to test against the next event (monday, 01:00 +1h).
      // If the end time is under the total week minutes, no rollover is applied.
      const normalizedCurrEvent = isLastEvent
        ? normalizeEventEndTime(currentEvent)
        : currentEvent;

      if (isOverlapped(normalizedCurrEvent, eventsList[nextIndex])) {
        // we have to return both conflicted events to later determine which one should be returned.
        // we do this because at this instance we do not have the knowledge about which of the event
        // belongs to the initial target schedule.
        conflictedEventPair.push(currentEvent, eventsList[nextIndex]);
      }
    }
  );

  if (!conflictedEventPair.length) return undefined;
  // We look for conflict only in the registered events because we do not want to look for
  // conflicts with the target schedule, the one that we want to create.
  return enabledRegisteredSchedules.find(schedule =>
    conflictedEventPair.some(event => event.uid === schedule.uid)
  );
};

export const findFirstOverlapWithRoutineUid = (
  newSchedule: ScheduleRecord,
  registeredSchedules: Array<ScheduleRecord>,
  routines: Map<string, RoutineRecord>
) => {
  return findEventOverlap(newSchedule, registeredSchedules, routines);
};

export const findFirstOverlapInRoutineIsEnabled = (
  schedules: List<ScheduleRecord>,
  registeredSchedules: Array<ScheduleRecord>,
  routines: Map<string, RoutineRecord>
) => {
  return schedules
    .toArray()
    .some(schedule =>
      findEventOverlap(schedule, registeredSchedules, routines)
    );
};

/**
 *
 * Calculate elapsed time in minutes between two hours.
 * It is important to consider that when hours are equals duration must to be 1440 (24h * 60m).
 * When "from" is greater than "to" a duration must to be less than 1440 minutes (<24h * 60).
 */
export const calculateDurationAsMinutes = (from: string, to: string) => {
  const fromTime = moment(from, 'HH:mm');
  const toTime = moment(to, 'HH:mm');

  if (fromTime.isSame(toTime)) return 24 * 60;

  if (fromTime.isAfter(toTime)) {
    return moment.duration(toTime.add(1, 'day').diff(fromTime)).asMinutes();
  }

  return moment.duration(toTime.diff(fromTime)).asMinutes();
};

/**
 * Function to determine if the routines feature should be enabled for the user
 * according to the account.routines flag and the subtype of license.
 */
export const ensureRoutinesFeatureEnabled = (
  accountHasRoutines: boolean,
  licenseSubtype: LicenseSubtype
) => {
  const routinesEnabled =
    accountHasRoutines &&
    isValidLicenseSubtype(licenseSubtype) &&
    !isPremiumGrandfather(licenseSubtype);

  return routinesEnabled;
};

/**
 * Function to determine the level of access to the routines feature according to the
 * license subtype of the user account.
 *
 * In this function we are assuming that the user has the routines flag enabled.
 */
export const getRoutineFeatureAccessLevel = (
  licenseSubtype: LicenseSubtype,
  routineContentFilteringFeature: Record<Feature> | undefined,
  routineBlock: Record<Feature> | undefined
): RoutineFeatureAccessLevel => {
  if (isPremiumGrandfather(licenseSubtype))
    return RoutineFeatureAccessLevel.none;

  // TODO pending a discussion of what we should do with rules that are not in the license feature
  const allowRoutineBlocking = routineBlock
    ? isFeatureEnabled(routineBlock)
    : false;
  // if we still haven't fetched the license features block access
  const allowRoutineContentFiltering = routineContentFilteringFeature
    ? isFeatureEnabled(routineContentFilteringFeature)
    : false;

  if (allowRoutineBlocking && allowRoutineContentFiltering) {
    return RoutineFeatureAccessLevel.full;
  }

  if (!allowRoutineBlocking && !allowRoutineContentFiltering) {
    return RoutineFeatureAccessLevel.guest;
  }

  if (allowRoutineBlocking) {
    return RoutineFeatureAccessLevel.routineBlocking;
  }

  return RoutineFeatureAccessLevel.none;
};

/** Returns the nearest next `ScheduleEvent` from the current time. */
export const getNextScheduleEventFromTime = (
  time: Datetime,
  timezone: string,
  schedules: List<ScheduleRecord>
) => {
  if (!time || !schedules) return undefined;

  const events = scheduleRecordsToEvents(schedules.toArray());
  const currentTimeMinutesOfWeek = getTimeMinutesOfWeek(time, timezone);

  const nextEventIndex = events.findIndex(
    event => event.start > currentTimeMinutesOfWeek
  );

  if (nextEventIndex === -1) {
    // if we haven't found any event and we have one or more events,
    // it means that the next possible event is the first one in the next week.
    // We update the start and end time of that event so it's clear
    // that it's in the next week.
    if (events.length > 0) {
      const firstEvent = events[0];
      return {
        ...firstEvent,
        start: MINS_IN_A_WEEK + firstEvent.start,
        end: MINS_IN_A_WEEK + firstEvent.end,
      };
    }
    return undefined;
  }

  const nextEvent = events[nextEventIndex];

  return nextEvent;
};
