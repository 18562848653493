import * as React from 'react';
import {
  Button,
  EmptyPlaceholder,
  Layout,
  List as RegularStyleList,
  FlexLayout,
  ButtonSize,
  StyledHeader,
  RegularStyleListItem,
  ClickableListItem,
  ListTitle,
  Avatar,
  AvatarSize,
  InstructionBlock,
  ListSubtitle,
  TransparentButton,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import * as PlaceImage from '../../assets/base/images/place.svg';
import BaseLoader from '../base/Loader';
import PageLayout from '../Layout/PageLayout';
import locationSvg from '../../assets/base/images/v3/decorators/illustration_location.svg';
import MobileLayoutScssUtils from '../Layout/mobile/MobileLayoutScssUtils';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import type { PlacesProps } from '../../containers/FamilyLocator/PlacesContainer';

const Places: React.FunctionComponent<PlacesProps> = ({
  places,
  isFetching,
  locationPathname,
  addPlaceClick,
  editPlaceClick,
}: PlacesProps) => {
  let ComponentBody = <EmptyState />;
  if (isFetching) {
    ComponentBody = <Loader />;
  } else if (places.size > 0) {
    ComponentBody = (
      <PlacesList places={places} editPlaceClick={editPlaceClick} />
    );
  }

  return (
    <PageLayout
      title={t('Your places')}
      actions={[{ actionName: 'back' }]}
      configuration={[
        { screenSize: ScreenSize.MobileOrTablet, useNonFlexLayout: true },
      ]}
    >
      <FlexLayout
        padding="16"
        paddingBottom="0"
        mainaxis="column"
        flexGrow="2"
        className={MobileLayoutScssUtils.HeightWithoutPageTitle}
      >
        {ComponentBody}
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          marginTop="24"
        >
          <Button
            size={ButtonSize.medium}
            onClick={() => addPlaceClick(places.size, locationPathname)}
          >
            {t('Add a new place')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </PageLayout>
  );
};

type PlacesListProps = Pick<PlacesProps, 'places' | 'editPlaceClick'>;
const PlacesList: React.FC<PlacesListProps> = ({
  places,
  editPlaceClick,
}: PlacesListProps) => (
  <FlexLayout mainaxis="column" flexGrow="2">
    <Layout marginBottom="24">
      <InstructionBlock>
        {t(
          "You'll get a notification when your kids arrive at or leave these places."
        )}
      </InstructionBlock>
    </Layout>

    <RegularStyleList listItemMarginBottom="24" listItemPaddingBottom="8">
      {places.map(place => (
        <RegularStyleListItem
          key={place.uid}
          title={
            <ClickableListItem onClick={() => editPlaceClick(place.uid)}>
              <ListTitle>{place.name}</ListTitle>
            </ClickableListItem>
          }
          upperSubtitle={
            <ListSubtitle>
              <TransparentButton
                fillAvailableSpace
                className="PlacesList__Subtitle"
                onClick={() => editPlaceClick(place.uid)}
              >
                {place.address}
              </TransparentButton>
            </ListSubtitle>
          }
          iconVerticalAlign="center"
          icon={
            <ClickableListItem onClick={() => editPlaceClick(place.uid)}>
              <Avatar size={AvatarSize.small}>
                <img src={PlaceImage} alt="marker" />
              </Avatar>
            </ClickableListItem>
          }
        />
      ))}
    </RegularStyleList>
  </FlexLayout>
);

const EmptyState: React.FC = () => (
  <FlexLayout
    mainaxis="column"
    flexGrow="2"
    paddingLeft="8"
    paddingRight="8"
    crossaxisAlignment="center"
  >
    <img src={locationSvg} width="224" height="224" alt="location" />

    <FlexLayout
      mainaxis="row"
      marginBottom="16"
      mainaxisAlignment="center"
      textAlignement="center"
      marginTop="16"
    >
      <StyledHeader type="h3">
        <strong>{t('No places yet')}</strong>
      </StyledHeader>
    </FlexLayout>

    <Layout marginBottom="16">
      <EmptyPlaceholder
        centered
        text={t(
          'Add the places your family visits regularly to receive notifications when they arrive or leave.'
        )}
      />
    </Layout>

    <EmptyPlaceholder centered text={t('Add home or school to get started.')} />
  </FlexLayout>
);

const Loader: React.FC = () => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    crossaxisAlignment="center"
  >
    <BaseLoader size="small" color="" />
  </FlexLayout>
);

export default Places;
