import * as React from 'react';

const BottomBar: React.FunctionComponent<{
  fixed?: boolean;
}> = ({ fixed, children }) => {
  const isFixed = fixed ? 'BottomBar--isFixed' : '';

  return <div className={`BottomBar ${isFixed}`}>{children}</div>;
};

export default BottomBar;
