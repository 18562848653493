import * as moment from 'moment';
import { createSelector } from 'reselect';
import { tryUndefined } from '../helpers';
import { API_BIRTHDATE_FORMAT } from '../../helpers/dates';

import type { ExperimentNames } from '../../ducks/experiments';
import type { SchoolLinkedStatus } from '../../records/account';
import type State from '../../store/state';
import { ISOLanguage } from '../../constants';

export const getAccount = (state: State) => state.get('account').get('item');

export const getAccountId = tryUndefined((state: State) =>
  getAccount(state).get('id')
);

export const getAccountUid = tryUndefined((state: State) =>
  getAccount(state).get('uid')
);

export const getAccountEmail = tryUndefined((state: State) =>
  getAccount(state).get('email')
);

export const getAccountLocale = (state: State): ISOLanguage =>
  getAccount(state).get('locale') ?? ISOLanguage.english;

export const getAccountCountry = tryUndefined((state: State) =>
  getAccount(state).get('country')
);

/**
 * We only exclude US from military time since it's the only one that
 * exclusively uses am/pm format.
 * Most of the countries use military time with some exceptions
 * where they use a combination of both, so to avoid any friction
 * we will use the military time as default for all the other countries.
 */
export const isMilitaryTimeFormat = (state: State) =>
  getAccount(state).get('country') !== 'US';

export const isUsingImperialSystem = (state: State) =>
  ['US', 'LR', 'MM'].includes(getAccountCountry(state));

export const getAccountModifyStatus = tryUndefined((state: State) =>
  getAccount(state).get('isUpdating')
);

export const getAccountDeviceCount = (state: State) =>
  getAccount(state).get('devicesCount');

export const isNewAccount = (state: State) =>
  state.get('account').get('isNewAccount');

export const hasSeenAdditionalParentWelcomeModal = (state: State) =>
  state
    .get('account')
    .get('options')
    .get('additional_parent_welcome_modal_viewed');

export const getRatingPopupLastDate = (state: State) =>
  state.get('account').get('options').get('rating_popup_last_date');

export const hasVisitedFamilyLocator = (state: State): boolean =>
  state.get('account').get('options').get('family_locator_visited');

export const getAccountOptionWebOnboardingFinished = (state: State): boolean =>
  state.get('account').get('options').get('web_onboarding_finished') || false;

export const getAccountOptionsFeatureFlags = (state: State) =>
  state.get('account').get('options').get('flags');

export const getAccountOptionsDelegationRulesModalDismissed = (state: State) =>
  state.get('account').get('options').get('delegation_rules_modal_dismissed');

export const getAdditionalParentAccountOptionsDelegationRulesModalDismissed = (
  state: State
) =>
  state
    .get('account')
    .get('options')
    .get('additional_parent_delegation_rules_modal_dismissed');

export const getExperiments = (state: State) => state.get('experiments');

export const getExperiment = (state: State, name: ExperimentNames) =>
  state.get('experiments').get(name);

export const getTimezone = tryUndefined((state: State) =>
  getAccount(state).get('timezone')
);

export const hasAtLeastOneProfileAndOneDevice = createSelector(
  getAccount,
  account => account.profilesCount > 0 && account.devicesCount > 0
);

export const getAccountMasterId = (state: State): number | null =>
  getAccount(state).get('masterAccountId');

export const getAccountMasterUid = (state: State): string | null =>
  getAccount(state).get('masterAccountUid');

export const isSlaveAccount = (state: State) => !!getAccountMasterUid(state);

export const getAccountMasterName = (state: State): string | null =>
  state.get('account').get('masterAccountData').get('name');

export const getAccountMasterEmail = (state: State): string | null =>
  state.get('account').get('masterAccountData').get('email');

export const getAccountOptionMultiParentFeatureFlagEnabled = (
  state: State
): boolean =>
  state.get('account').get('options').get('multiparent_feature_flag_enabled');

export const getMultiParentFeatureFlagEnabled = (state: State): boolean =>
  state.get('account').get('multiparentFeatureFlagEnabled');

export const getSchoolLinkedStatus = (
  state: State
): SchoolLinkedStatus | null => getAccount(state).schoolLinkedStatus;

export const getAccountCreationDate = (state: State) => {
  const accountCreationDate = state.get('account').get('item').get('created');

  return moment(accountCreationDate).format(API_BIRTHDATE_FORMAT);
};

export const getCurrentDayDate = () => {
  return moment(Date.now()).format(API_BIRTHDATE_FORMAT);
};

export const getDaysSinceAccountCreated = (state: State) => {
  const accountCreationDate = getAccountCreationDate(state);
  const currentDayDate = getCurrentDayDate();

  return moment(currentDayDate).diff(moment(accountCreationDate), 'days');
};

export const isAccountRoutinesFlagEnabled = createSelector(
  getAccount,
  account => account.get('routines')
);
