import * as React from 'react';
import { IconFamily, IconType } from 'styleguide-react';
import FullPremiumFlyover from '../../FullPremiumFlyover/FullPremiumFlyover';
import { t } from '../../../lib/i18n';
import { ISOLanguage } from '../../../constants';

const ROUTINES_PREMIUM_FLYOVER_TEST_ID = 'routine-premium-flyover';
interface RoutinesPremiumFlyoverProps {
  accountLocale: ISOLanguage;
  onUpgrade?: () => void;
}

const RoutinesPremiumFlyover = ({
  accountLocale,
  onUpgrade,
}: RoutinesPremiumFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Routines') }}
      testId={ROUTINES_PREMIUM_FLYOVER_TEST_ID}
      topbarText={t('{1}Upgrade your plan{/1} to manage routines!', {
        1: x => x,
      })}
      title={t('What can you do with routines?')}
      imageSrc={`/illustrations/Routines/illustration_premium_routines__${accountLocale}.png`}
      actionButton={{
        title: t('Upgrade'),
        onClick: onUpgrade,
        testId: `${ROUTINES_PREMIUM_FLYOVER_TEST_ID}-upgrade`,
      }}
      infoItems={[
        {
          iconType: IconType.calendarCheck,
          title: t('Create a custom screen time schedule'),
          subtitle: t('Help your child focus when they need to'),
        },
        {
          iconType: IconType.wandMagicSparkles,
          iconFamily: IconFamily.solid,
          title: t('Get started quickly with presets'),
          subtitle: t('Pick from a list of ready-made rules'),
        },
        {
          iconType: IconType.slidersHorizontal,
          title: t('Enjoy full customization'),
          subtitle: t(
            'Create your own web and app rules for complete personalization'
          ),
        },
      ]}
    />
  );
};

export default RoutinesPremiumFlyover;
