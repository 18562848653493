import { connect } from 'react-redux';
import ParentOrKidAppChoice from '../../components/PreOnboarding/ParentOrKidAppChoice';
import { markParentAppStandaloneAsConfirmed } from '../../actions/StandaloneAppActions';
import { ROUTE_PARENTS_APP_STANDALONE_WRONG_APP } from '../../constants';
import { getAccount } from '../../selectors/account';
import { resumeSetup } from '../../ducks/app';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => ({
  accountName: getAccount(state).name,
});

const mapDispatchToProps = dispatch => ({
  onClickParent: () => {
    dispatch(markParentAppStandaloneAsConfirmed());
    dispatch(resumeSetup());
  },
  onClickKid: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({ type: 'inner', src: ROUTE_PARENTS_APP_STANDALONE_WRONG_APP })
    );
  },
});

const ParentOrKidAppChoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentOrKidAppChoice);

export default ParentOrKidAppChoiceContainer;
