import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import SetContentFilterRulesModal from '../../../components/Routines/Modals/SetContentFilterRulesModal';
import { getQueryParam } from '../../../selectors';
import { RoutineRuleExceptionType } from '../../../components/Routines/routines.types';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickOk: () => dispatch(goBackIfHistory()),
  };
};

const mapStateToProps = (state: State) => {
  const type = getQueryParam(state, 'type') as RoutineRuleExceptionType;

  return {
    type,
  };
};

const ChangeContentFilterRulesModalContainerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetContentFilterRulesModal);

export type SetContentFilterRulesModalProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default ChangeContentFilterRulesModalContainerContainer;
