import * as React from 'react';
import { t } from '../../lib/i18n';
import { ProfileFormRecord } from './ProfileForm';
import ProfileContainerForm from './ProfileContainerForm';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const ProfileAdd: React.FunctionComponent<{
  disabled: boolean;
  onNextClick: () => any;
  form: ProfileFormRecord;
}> = ({ form, disabled, onNextClick }) => (
  <PageLayout
    title={t('Add a child')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Mobile,
        useNonFlexLayout: false,
      },
    ]}
  >
    <ProfileContainerForm
      form={form}
      disabled={disabled}
      buttonLabel={t('Next')}
      onNextClick={onNextClick}
    />
  </PageLayout>
);

export default ProfileAdd;
