import { fromJS, List, NonEmptyMapRecord } from 'immutable';
import { CategoriesActivityRecord } from '../records/studentActivity/categories';
import {
  CategoriesActivity,
  CategoriesActivityPayload,
} from '../records/studentActivity/types/categories.types';
import {
  SignaturesActivity,
  SignaturesActivityPayload,
} from '../records/studentActivity/types/signatures.types';
import { TotalUsageActivity } from '../records/studentActivity/types/totalUsage.types';
import { TotalUsageActivityRecord } from '../records/studentActivity/totalUsage';
import {
  StudentSummaryAction,
  StudentSummaryFetchError,
} from './types/action/StudentSummaryAction.types';
import { SummaryDateRanges } from '../constants/index';
import State, { BaseThunk, Dispatch } from '../store/state';
import { SignaturesActivityRecord } from '../records/studentActivity/signatures';
import api from '../api';
import { getSelectedDateRange } from '../selectors/studentSummary';
import { APIError } from '../lib/errors';
import { fetchProfiles } from './profiles';
import { ProfileId } from '../records/profile';
import {
  API_BIRTHDATE_FORMAT,
  fromStringToUTCISOString,
  getMoment,
} from '../helpers/dates';
import { ttl1Minute } from '../lib/QApiCache/commonCacheStrategies';

export const LOAD_STUDENT_TOTAL_USAGE_ACTIVITY =
  'LOAD_STUDENT_TOTAL_USAGE_ACTIVITY';
export const LOAD_STUDENT_MOST_USED_CATEGORIES_ACTIVITY =
  'LOAD_STUDENT_MOST_USED_CATEGORIES_ACTIVITY';
export const LOAD_STUDENT_MOST_USED_SIGNATURES_ACTIVITY =
  'LOAD_STUDENT_MOST_USED_SIGNATURES_ACTIVITY';
export const STUDENT_SELECTED_PAST_DATE_CHANGED =
  'STUDENT_SELECTED_PAST_DATE_CHANGED';

export default function studentSummary(
  state: NonEmptyMapRecord<{
    initialLoading: boolean;
    lastUpdated?: string;
    isFetchingTotalUsage: boolean;
    totalUsageData: List<TotalUsageActivity>;
    isFetchingSignatures: boolean;
    signaturesData: List<SignaturesActivity>;
    isFetchingCategories: boolean;
    categoriesData: List<CategoriesActivity>;
    summaryFetchError?: StudentSummaryFetchError;
    summaryFetchTotalUsageError?: StudentSummaryFetchError;
    summaryFetchCategoriesError?: StudentSummaryFetchError;
    summaryFetchSignaturesError?: StudentSummaryFetchError;
    activeDateRange: SummaryDateRanges;
    lastFetched: number;
    resultProfileUid?: string;
    selectedPastDate: string;
  }> = fromJS({
    initialLoading: true,
    lastUpdated: undefined,
    isFetchingTotalUsage: false,
    totalUsageData: List(),
    isFetchingSignatures: false,
    signaturesData: List(),
    isFetchingCategories: false,
    categoriesData: List(),
    summaryFetchError: undefined,
    summaryFetchTotalUsageError: undefined,
    summaryFetchCategoriesError: undefined,
    summaryFetchSignaturesError: undefined,
    activeDateRange: SummaryDateRanges.Today,
    lastFetched: undefined,
    resultProfileUid: undefined,
    selectedPastDate: getMoment().format(API_BIRTHDATE_FORMAT),
  }),
  action: StudentSummaryAction
) {
  switch (action.type) {
    case 'SUMMARY_IS_LOADING_DATA':
      return state.merge({
        initialLoading: true,
      });
    case 'SUMMARY_FINISH_LOADING_DATA':
      return state.merge({
        initialLoading: false,
        lastFetched: Date.now(),
        resultProfileUid: action.payload.profileUid,
      });
    case 'REQUEST_SUMMARY_TOTAL_USAGE':
      return state.merge({
        isFetchingTotalUsage: true,
      });
    case 'RECEIVE_SUMMARY_TOTAL_USAGE':
      return state.merge({
        isFetchingTotalUsage: false,
        totalUsageData: action.payload.totalUsage,
        lastUpdated: action.payload.lastUpdated,
      });
    case 'REQUEST_SUMMARY_SIGNATURES':
      return state.merge({
        isFetchingSignatures: true,
      });
    case 'RECEIVE_SUMMARY_SIGNATURES':
      return state.merge({
        isFetchingSignatures: false,
        signaturesData: action.payload,
      });
    case 'REQUEST_SUMMARY_CATEGORIES':
      return state.merge({
        isFetchingCategories: true,
      });
    case 'RECEIVE_SUMMARY_CATEGORIES':
      return state.merge({
        isFetchingCategories: false,
        categoriesData: action.payload,
      });
    case 'RESET_SUMMARY_ERROR':
      return state.merge({
        summaryFetchError: undefined,
      });
    case 'RECEIVE_SUMMARY_ERROR':
      return state.merge({
        summaryFetchError: action.payload,
      });
    case 'RECEIVE_SUMMARY_FETCH_TOTAL_USAGE_ERROR':
      return state.merge({
        summaryFetchTotalUsageError: action.payload,
      });
    case 'RECEIVE_SUMMARY_FETCH_CATEGORIES_ERROR':
      return state.merge({
        summaryFetchCategoriesError: action.payload,
      });
    case 'RECEIVE_SUMMARY_FETCH_SIGNATURES_ERROR':
      return state.merge({
        summaryFetchSignaturesError: action.payload,
      });
    case 'CHANGE_DATE_RANGE':
      return state.set('activeDateRange', action.payload);
    case 'STUDENT_SELECTED_PAST_DATE_CHANGED':
      return state.merge({ selectedPastDate: action.payload });
    default:
      return state;
  }
}

export const summaryIsLoadingData = () => ({
  type: 'SUMMARY_IS_LOADING_DATA',
});

export const summaryFinishLoadingData = (
  profileUid: string
): StudentSummaryAction => ({
  type: 'SUMMARY_FINISH_LOADING_DATA',
  payload: { profileUid },
});

export const changeDateRange = (payload: SummaryDateRanges) => ({
  type: 'CHANGE_DATE_RANGE',
  payload,
});

export const requestTotalUsage = (): StudentSummaryAction => ({
  type: 'REQUEST_SUMMARY_TOTAL_USAGE',
});

export const receiveTotalUsage = (
  lastUpdated: string,
  totalUsage: List<TotalUsageActivity>
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_TOTAL_USAGE',
  payload: {
    lastUpdated: fromStringToUTCISOString(lastUpdated),
    totalUsage,
  },
});

export const fetchTotalUsage =
  (profileUid: string) => (dispatch: Dispatch<StudentSummaryAction>) => {
    dispatch(requestTotalUsage());
    return api.summaryStudentTotalUsage
      .withCache(ttl1Minute)
      .get({
        profileUid,
      })
      .then(
        (totalUsage: {
          // eslint-disable-next-line camelcase
          last_updated: string;
          // eslint-disable-next-line camelcase
          total_usage: TotalUsageActivity[];
        }) =>
          dispatch(
            receiveTotalUsage(
              totalUsage.last_updated,
              List(
                totalUsage.total_usage.map(usagePerDay =>
                  TotalUsageActivityRecord.fromPayload(usagePerDay)
                )
              )
            )
          )
      );
  };

export const requestSignatures = (): StudentSummaryAction => ({
  type: 'REQUEST_SUMMARY_SIGNATURES',
});

export const receiveSignatures = (
  signatures: List<SignaturesActivity>
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_SIGNATURES',
  payload: signatures,
});

export const fetchSignatures =
  (profileUid: string) =>
  (dispatch: Dispatch<StudentSummaryAction>, getState: () => State) => {
    dispatch(requestSignatures());
    const { minDate, maxDate } = getSelectedDateRange(getState());
    return api.summaryStudentSignatures
      .withCache(ttl1Minute)
      .get({
        profileUid,
        minDate,
        maxDate,
      })
      .then((signatures: { usage: SignaturesActivityPayload[] }) => {
        return dispatch(
          receiveSignatures(
            List(
              signatures.usage.map(signature =>
                SignaturesActivityRecord.fromPayload(signature)
              )
            )
          )
        );
      });
  };

export const requestCategories = (): StudentSummaryAction => ({
  type: 'REQUEST_SUMMARY_CATEGORIES',
});

export const receiveCategories = (
  categories: List<CategoriesActivity>
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_CATEGORIES',
  payload: categories,
});

export const fetchCategories =
  (profileUid: string) =>
  (dispatch: Dispatch<StudentSummaryAction>, getState: () => State) => {
    dispatch(requestCategories());
    const { minDate, maxDate } = getSelectedDateRange(getState());
    return api.summaryStudentCategories
      .withCache(ttl1Minute)
      .get({
        profileUid,
        minDate,
        maxDate,
      })
      .then((categories: { usage: CategoriesActivityPayload[] }) => {
        return dispatch(
          receiveCategories(
            List(
              categories.usage.map(category =>
                CategoriesActivityRecord.fromPayload(category)
              )
            )
          )
        );
      });
  };

export const resetSummaryError = (): StudentSummaryAction => ({
  type: 'RESET_SUMMARY_ERROR',
});

export const receiveSummaryError = (
  error: StudentSummaryFetchError
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_ERROR',
  payload: error,
});

export const receiveSummaryTotalUsageError = (
  error: StudentSummaryFetchError
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_FETCH_TOTAL_USAGE_ERROR',
  payload: error,
});

export const receiveSummaryCategoriesError = (
  error: StudentSummaryFetchError
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_FETCH_CATEGORIES_ERROR',
  payload: error,
});

export const receiveSummarySignaturesError = (
  error: StudentSummaryFetchError
): StudentSummaryAction => ({
  type: 'RECEIVE_SUMMARY_FETCH_SIGNATURES_ERROR',
  payload: error,
});

export const studentUnlinkedErrors = [403, 410, 502];

export const handleSummaryFetchError =
  (error: APIError): BaseThunk<void> =>
  dispatch => {
    if (studentUnlinkedErrors.includes(error.status)) {
      dispatch(receiveSummaryError(StudentSummaryFetchError.Unlinked));
      return dispatch(fetchProfiles());
    }
    if (error.status === 412) {
      return dispatch(
        receiveSummaryError(StudentSummaryFetchError.FeatureDisabled)
      );
    }
    return dispatch(
      receiveSummaryError(StudentSummaryFetchError.UnhandledServerError)
    );
  };

export const fetchStudentSummaryData =
  (profileUid: string): BaseThunk<void> =>
  async dispatch => {
    dispatch(resetSummaryError());
    return Promise.all([
      dispatch(fetchTotalUsage(profileUid)),
      dispatch(fetchCategories(profileUid)),
      dispatch(fetchSignatures(profileUid)),
    ])
      .then(() => {
        dispatch(summaryFinishLoadingData(profileUid));
      })
      .catch((error: APIError) => dispatch(handleSummaryFetchError(error)));
  };

interface LoadActivityPayload {
  profileId: ProfileId;
}

export const loadStudentTotalUsageActivity = (
  payload: LoadActivityPayload
) => ({
  type: LOAD_STUDENT_TOTAL_USAGE_ACTIVITY,
  payload,
});

export const loadStudentMostUsedCategoriesActivity = (
  payload: LoadActivityPayload
) => ({
  type: LOAD_STUDENT_MOST_USED_CATEGORIES_ACTIVITY,
  payload,
});

export const loadStudentMostUsedSignaturesActivity = (
  payload: LoadActivityPayload
) => ({
  type: LOAD_STUDENT_MOST_USED_SIGNATURES_ACTIVITY,
  payload,
});

export const studentPastDateChanged = (payload: string) => ({
  type: STUDENT_SELECTED_PAST_DATE_CHANGED,
  payload,
});
