import * as React from 'react';
import { t } from '../../lib/i18n';
import IconBlockedDevice from '../Icons/IconBlockedDevice';
import IconAlert2 from '../Icons/IconAlert2';
import IconLocation2 from '../Icons/IconLocation2';
import IconBubble from '../Icons/IconBubble';
import IconYoutube2 from '../Icons/IconYoutube2';
import IconLockKeyholeVertical from '../Icons/IconLockKeyholeVertical';
import ft, { DISABLE_CALLS_SMS_RULES } from '../../lib/ft';
import { Icon, IconColor, IconSize, IconType } from 'styleguide-react';

export enum DisplayMode {
  table,
  list,
}

export interface PremiumFeature {
  icon: JSX.Element;
  title: string;
  text: string;
  flag?: string;
  label?: string;
}

export const getFeatures = () => ({
  timeLimits: {
    icon: (
      <Icon
        type={IconType.clock}
        color={IconColor.primary}
        size={IconSize.x2}
      />
    ),
    title: t('Set time limits & downtimes'),
    text: t(
      'Easy consistent screen time routines for each person in your family across devices and per app.'
    ),
  },
  apps: {
    icon: <IconBlockedDevice />,
    title: t('Block unwanted apps & websites'),
    text: t(
      'Unlimited protection to games, apps and inappropriate content online including gambling and porn.'
    ),
  },
  reports: {
    icon: <IconLockKeyholeVertical />,
    title: t('Extended reports'),
    text: t(
      'On-demand access to all your child’s activity online. Plus a detailed, daily or weekly email summary.'
    ),
  },
  alerts: {
    icon: <IconAlert2 />,
    title: t('Real-time alerts & SOS'),
    text: t(
      'Get alerts when your child uses a new app, reaches a time limit, visits adult content or needs help (panic button available for Android).'
    ),
  },
  location: {
    icon: <IconLocation2 />,
    title: t('Family Locator'),
    text: t(
      'Geolocation & geofencing: See where all your family members are on a map and get alerts when they arrive or leave designated places. Available for Android & iOS.'
    ),
    label: t('New'),
  },
  sms: {
    icon: <IconBubble />,
    title: t('Track SMS'),
    text: t(
      'See who your child calls or texts most, what was said and when, and block incoming messages. Available for Android.'
    ),
    flag: DISABLE_CALLS_SMS_RULES,
  },
  youtube: {
    icon: <IconYoutube2 />,
    title: t('YouTube Monitoring'),
    text: t(
      'Know what your child watches and searches for on YouTube. Available for Android (web and app), iOS, Windows & Mac (web).'
    ),
    label: t('New'),
  },
});

const PremiumFeaturesInfo: React.FunctionComponent<{
  displayMode: DisplayMode;
}> = ({ displayMode }) => {
  return (
    <React.Fragment>
      <div className="PremiumFeaturesInfo">
        <PremiumFeaturesInfoList features={getPremiumFeatures(displayMode)} />
      </div>
    </React.Fragment>
  );
};

export const getPremiumFeatures: (
  displayMode: DisplayMode
) => PremiumFeature[] = displayMode => {
  let features: PremiumFeature[];
  const premiumFeatures = getFeatures();
  features = [
    premiumFeatures.timeLimits,
    premiumFeatures.apps,
    premiumFeatures.reports,
    premiumFeatures.alerts,
    premiumFeatures.location,
    premiumFeatures.sms,
    premiumFeatures.youtube,
  ];

  features = features.filter(feature => !ft.active(feature.flag));

  let featuresRow: PremiumFeature[] = [];
  features.forEach((featureRow: PremiumFeature) => {
    featuresRow.push({ ...featureRow });
  });

  if (displayMode === DisplayMode.list) {
    const featuresLeft = featuresRow.filter((_feature, index) => !(index % 2));
    const featuresRight = featuresRow.filter((_feature, index) => index % 2);
    featuresRow = featuresLeft.concat(featuresRight);
  }

  return featuresRow;
};

export const PremiumFeaturesInfoList: React.FunctionComponent<{
  features: PremiumFeature[];
}> = ({ features }) =>
  features.map(feature => (
    <PremiumFeature
      key={feature.title}
      icon={feature.icon}
      title={feature.title}
      text={feature.text}
      label={feature.label}
    />
  )) as any;

const PremiumFeature: React.FunctionComponent<PremiumFeature> = ({
  icon,
  title,
  text,
  label,
}) => {
  return (
    <div className="PremiumFeaturesInfo__feature">
      <div className="PremiumFeaturesInfo__feature-icon">{icon}</div>
      <div className="PremiumFeaturesInfo__feature-content">
        <div className="PremiumFeaturesInfo__feature-title">
          {title}
          {label && (
            <span className="PremiumFeaturesInfo__feature-label">{label}</span>
          )}
        </div>
        <div className="PremiumFeaturesInfo__feature-text">{text}</div>
      </div>
    </div>
  );
};

export default PremiumFeaturesInfo;
