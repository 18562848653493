import * as React from 'react';
import { t } from '../../../lib/i18n';
import welcomeSvg from '../../../assets/base/images/onboarding/welcome.svg';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Label,
  Layout,
} from 'styleguide-react';

const OnboardingWelcome: React.FunctionComponent<{
  onClickNext: () => any;
}> = ({ onClickNext }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    crossaxisAlignment="center"
  >
    <Layout minHeight="200" minWidth="200">
      <img src={welcomeSvg} alt="welcome" />
    </Layout>
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      marginTop="16"
      marginBottom="48"
    >
      <h3>
        <strong>{t("Let's start")}</strong>
      </h3>
      <Label>
        {t('Yes, this is the very first thing you need to do, so go ahead!')}
      </Label>
    </FlexLayout>
    <Button onClick={onClickNext} size={ButtonSize.medium}>
      {t('Create Profile')}
    </Button>
  </FlexLayout>
);

export default OnboardingWelcome;
