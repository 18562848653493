import * as React from 'react';
import { List } from 'immutable';
import { EmptyPlaceholder, GlobalType, Icon, IconType } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { ActivityRecord } from '../../records/activity';
import ActivityList, { DisplayUnit } from './ActivityList';
import { ActivityType } from './sharedModels';
import { SummaryDateRanges } from '../../constants';
import SummaryCard from '../ActivityCards/SummaryCard';
import { infiniteScroll } from '../../helpers/hocs';
import Loader from '../base/Loader';
import { AnyError } from '../../helpers/errorHandling';
import InfoIcon from '../Icons/InfoIcon';

export interface ActivitySummaryListProps {
  displayAll?: boolean;
  type: ActivityType;
  title: string;
  iconEmptyCard: JSX.Element;
  icon: JSX.Element;
  items: List<ActivityRecord>;
  displayUnit: DisplayUnit;
  noActivityText: string;
  showAllAvailableActivity: boolean;
  needsPagination: boolean;
  activeDateRange: SummaryDateRanges;
  isFetching: boolean;
  width?: string;
  height?: string;
  profileUid: string;
  isFetchingInfo: boolean;
  hasAnyError?: AnyError;
  loadingText: string;
  errorText: string;
  onExpand: () => {};
  handleMore: (limit: string) => any;
  hasExpandLink?: boolean;
  goToInfoModal: () => {};
  loadWidget: () => void;
}

export const ActivitySummaryList: React.FunctionComponent<
  ActivitySummaryListProps
> = ({
  displayAll,
  type,
  title,
  iconEmptyCard,
  icon,
  items,
  displayUnit,
  noActivityText,
  onExpand,
  showAllAvailableActivity,
  width,
  height,
  needsPagination,
  activeDateRange,
  isFetching,
  profileUid,
  isFetchingInfo,
  hasAnyError,
  loadingText,
  errorText,
  handleMore,
  goToInfoModal,
  loadWidget,
}: ActivitySummaryListProps) => {
  const isEmpty = items.size === 0;
  const hasSeeAllButton = !isEmpty && onExpand;
  React.useEffect(() => {
    loadWidget();
  }, []);
  const bodyContent = () => {
    if (hasAnyError) {
      return (
        <EmptyPlaceholder
          icon={<InfoIcon />}
          text={errorText}
          fillAvailableSpace
          centered
          maxWidth="75%"
          smallText
        />
      );
    }
    if (isFetchingInfo) {
      return (
        <EmptyPlaceholder
          icon={<Loader size="small" color="secondary" />}
          text={loadingText}
          fillAvailableSpace
          centered
          maxWidth="75%"
          smallText
        />
      );
    }
    if (isEmpty) {
      return (
        <EmptyPlaceholder
          icon={iconEmptyCard}
          text={noActivityText}
          fillAvailableSpace
          centered
          maxWidth="75%"
          smallText
        />
      );
    }
    return (
      <ActivityList
        profileUid={profileUid}
        type={type}
        items={items}
        displayUnit={displayUnit}
        showAllAvailableActivity={showAllAvailableActivity}
      />
    );
  };
  return (
    <SummaryCard
      displayAll={displayAll}
      type={GlobalType.white}
      isEmpty={isEmpty}
      onExpand={onExpand}
      needsPagination={needsPagination}
      handleMore={handleMore}
      isFetchingMore={isFetching}
      itemsLength={items.size}
      showAllAvailableActivity={showAllAvailableActivity}
      activeDateRange={activeDateRange}
      width={width}
      height={height}
      body={bodyContent()}
      header={{
        title,
        icon,
        actionElement: <Icon type={IconType.infoCircle} />,
        onClickAction: goToInfoModal,
        type: getSummaryCardType(type),
      }}
      footer={{
        actionElement:
          hasSeeAllButton && !isFetchingInfo && !hasAnyError
            ? t('See more')
            : undefined,
        onClickAction: onExpand,
      }}
    />
  );
};

const getSummaryCardType = (type: ActivityType) =>
  type === ActivityType.QuestionableWebSearches ||
  type === ActivityType.BlockedWebs
    ? GlobalType.error
    : GlobalType.default;

export default infiniteScroll<ActivitySummaryListProps>(ActivitySummaryList);
