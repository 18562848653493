import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getFeatureFlag } from './featureFlags';
import { tryUndefined } from './helpers';
import { getLicense } from './license';
import { ProductDuration, ProductRecord } from '../records/product';
import State from '../store/state';
import { PAYMENT_PROVIDER_CHARGEBEE, PaymentProvider } from '../constants';

export const getProducts = (state: State) =>
  state.get('records').get('products');

export const getDefaultProductCode = (state: State) =>
  state.get('products').get('defaultProductCode');

export const getProductsResult = (state: State) =>
  state.get('products').get('result');

export const getProductNotification = (state: State) =>
  state.get('products').get('notification');

export const getSelectedProductDuration = (state: State) =>
  state.get('products').get('selectedProductDuration');

export const getSelectedCodeProduct = (state: State) =>
  state.get('products').get('selectedProduct');

export const isPurchaseInProgress = (state: State) =>
  state.get('products').get('isPurchaseInProgress');

export const isFetchingProducts = (state: State) =>
  state.get('products').get('isFetching');

export const shouldRequestDefaultProducts = (state: State) =>
  state.get('products').get('lastProductsRequested') !== 'default';

export const getProduct = tryUndefined(
  (state: State, code): ProductRecord | undefined =>
    state.getIn(['records', 'products', code.toString()])
);

export const filterProductsByPaymentProvider = (
  products: Immutable.List<ProductRecord>,
  paymentProvider: PaymentProvider
) => {
  if (paymentProvider === PAYMENT_PROVIDER_CHARGEBEE) {
    return products;
  }
  return products.filter(product => product.storeProduct !== undefined);
};

export const getProductList: (state: State) => Immutable.List<ProductRecord> =
  createSelector(getProducts, getProductsResult, (records, result) =>
    result.size
      ? Immutable.List(result.map(id => records.get(id.toString())))
      : Immutable.List()
  );

/**
 * @deprecated this function is deprecated in favour of filterProductListByDurationAndNonDedicatedSupport
 * to filter all elements by duration and dedicated support.
 *
 */
export const filterProductListByDuration = (
  productList,
  selectedProductDuration
): Immutable.List<ProductRecord> => {
  const numberOfProducts = 2;
  return Immutable.List(
    productList
      .filter(product => product.duration === selectedProductDuration)
      .slice(0, numberOfProducts)
  );
};

export const filterProductListByDurationAndNonDedicatedSupport = (
  productList,
  selectedProductDuration
): Immutable.List<ProductRecord> => {
  return Immutable.List(
    productList.filter(
      prd => prd.duration === selectedProductDuration && !prd.isDedicatedSupport
    )
  );
};

export const getSelectedProductList: (
  state: State
) => Immutable.List<ProductRecord> = createSelector(
  getProductList,
  getSelectedProductDuration,
  filterProductListByDurationAndNonDedicatedSupport
);

/**
 * todo: check if the second and third selectors are really being used in here
 * as the final selector gets the first element containing all the products and not only the filtered one.
 */
export const getSelectedProductWithDedicatedSupportList: (
  state: State
) => Immutable.List<ProductRecord> = createSelector(
  getProductList,
  getSelectedProductDuration,
  filterProductListByDuration,
  products =>
    Immutable.List(
      products.filter((product: ProductRecord) => product.isDedicatedSupport)
    )
);

export const getDefaultProductIndex = createSelector(
  getDefaultProductCode,
  getProductList,
  (defaultProductCode, productList) =>
    productList.findKey(R.propEq('code', defaultProductCode))
);

const buildLicenseName = product => `${product.code}_${product.num_devices}`;

export const getSuggestedProductIndex = createSelector(
  getLicense,
  getProductList,
  (license, productList) =>
    productList.findKey(product => buildLicenseName(product) === license.name)
);

export const getProductsDiscount = createSelector(
  getProductList,
  productList => {
    if (productList.size === 0) {
      return 0;
    }

    const product = productList.find(
      product => product.discount !== 0 && product.discount !== undefined
    );
    return product?.discount ?? 0;
  }
);

/**
 * todo: check if this is still relevant is only used in the up sell modal
 * if it is check if filtering the number of products in here is correct
 * or we just want all the products of a given duration
 *
 */
export const filterProductListByTwoYearsDuration = (
  state,
  productList
): Immutable.List<ProductRecord> => {
  const numberOfProducts = 2;
  return Immutable.List(
    productList
      .filter(product => product.duration === ProductDuration.TwoYears)
      .slice(
        0,
        getFeatureFlag(state, 'dedicatedSupportV2')
          ? numberOfProducts * 2
          : numberOfProducts
      )
  );
};

export const getSelectedProductsOfTwoYearsDurationList = (state: State) =>
  filterProductListByTwoYearsDuration(state, getProductList(state));

export const getSelectedProduct = (state: State, productId: string) => {
  const selectedCodeProduct = getSelectedCodeProduct(state) || productId;
  const product = getProductList(state).find(
    product => product.code === selectedCodeProduct
  );
  return product === undefined ? ProductRecord() : product;
};

/**
 * todo: check if we could simplify the product comparison just by using the product subtype
 *
 */
export const getSameProductForLongerDuration = (
  state: State,
  productId: string
) => {
  const selectedProduct = getSelectedProduct(state, productId);
  const product = getSelectedProductsOfTwoYearsDurationList(state).find(
    product =>
      product.maxDevices === selectedProduct.maxDevices &&
      product.maxProfiles === selectedProduct.maxProfiles &&
      product.duration === ProductDuration.TwoYears &&
      product.isDedicatedSupport === selectedProduct.isDedicatedSupport
  );
  return product === undefined ? ProductRecord() : product;
};

export const getAreTwoYearProductsAvailable = createSelector(
  getProductList,
  productList =>
    filterProductListByDuration(productList, ProductDuration.TwoYears).size > 0
);

export const getEquivalentProductWithDedicatedSupport = (
  product,
  productListDedicatedSupport
) =>
  productListDedicatedSupport &&
  (productListDedicatedSupport as Immutable.List<ProductRecord>).find(
    (productDedicatedSupport: ProductRecord) =>
      productDedicatedSupport.isDedicatedSupport &&
      productDedicatedSupport.type === product.type &&
      productDedicatedSupport.duration === product.duration &&
      productDedicatedSupport.maxDevices === product.maxDevices &&
      productDedicatedSupport.maxProfiles === product.maxProfiles
  );

export const getCurrentProductDuration = (state: State) => {
  const currentProduct = getCurrentProduct(state);
  return currentProduct instanceof ProductRecord
    ? currentProduct.duration
    : undefined;
};

export const getProductListFilteredByIfIsDedicatedSupport = (state: State) =>
  Immutable.List(
    getProductList(state).filter(
      p =>
        p.current ||
        (!p.isDedicatedSupport &&
          getCurrentProductDuration(state) === p.duration)
    )
  );

export const isCurrentProductDedicatedSupport = (state: State) => {
  const currentProduct = getCurrentProduct(state);
  return currentProduct instanceof ProductRecord
    ? currentProduct.isDedicatedSupport
    : false;
};

export const getCurrentProduct = createSelector(getProductList, products =>
  products.size > 0 ? products.filter(p => p.current).first() : undefined
);
