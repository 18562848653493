import * as React from 'react';
import { List } from 'immutable';
import { Tag, GlobalType, FlexLayout } from 'styleguide-react';

interface InappropriateCategoryListProps {
  categories: List<string>;
}

const InappropriateCategoryList = ({
  categories,
}: InappropriateCategoryListProps) => (
  <FlexLayout mainaxis="row">
    {categories.map(category => (
      <FlexLayout
        mainaxis="column"
        key={category}
        marginTop="16"
        marginRight="8"
      >
        <Tag
          variant="squared"
          text={category}
          type={GlobalType.warning}
          invertColor
        />
      </FlexLayout>
    ))}
  </FlexLayout>
);

export default InappropriateCategoryList;
