import { Map } from 'immutable';
import {
  SummaryAlertsState,
  SummaryAlertsTypes,
} from './types/SummaryAlertsState.type';
import { ReceiveSummaryAlertsAction } from './types/actions.types';
import { recordOf } from '../../helpers/state';

export const computeViewedAlerts = (
  alertsByProfile: ReceiveSummaryAlertsAction['payload']['profiles']
): SummaryAlertsState => {
  const state = Map(alertsByProfile)
    .map(alertTypes => {
      return Map<SummaryAlertsTypes, number>(alertTypes)
        .map(count => ({
          count,
          viewed: count === 0,
        }))
        .map(recordOf)
        .toMap();
    })
    .toMap();

  return state;
};
