import { fromJS, NonEmptyMapRecord } from 'immutable';
import * as qinit from '../qinit';
import { ScenarioName } from './scenarios/scenarios';
import { markRatingPopupAsViewed } from '../actions/appRate';
import { BaseThunk } from '../store/state';
import { captureException } from '../helpers/sentry';
import { Events, track } from '../helpers/analytics';
import { getCurrentScenario } from '../selectors';

export const SHOW_APPRATE = 'SHOW_APPRATE';
export const SET_CURRENT_SCENARIO = 'SET_CURRENT_SCENARIO';
export const SET_LAST_RULE_TYPE_MODIFIED = 'SET_LAST_RULE_TYPE_ACTION';
export const SET_ANY_RULE_IN_TIMELINE = 'SET_ANY_RULE_IN_TIMELINE';

export enum RuleType {
  DailyTimeLimits = 'Daily Time Limits',
  GamesAndApps = 'Games & Apps',
  WebFiltering = 'Web Filtering',
  YoutubeMonitoring = 'Youtube Monitoring',
}

export default function appRate(
  state: NonEmptyMapRecord<{
    currentScenario?: ScenarioName;
    lastRuleTypeModified?: RuleType;
    isAnyRuleEditedInTimeline: boolean;
  }> = fromJS({
    currentScenario: undefined,
    lastRuleTypeModified: undefined,
    isAnyRuleEditedInTimeline: false,
  }),
  action
) {
  switch (action.type) {
    case SET_CURRENT_SCENARIO:
      return state.merge({ currentScenario: action.payload });

    case SET_LAST_RULE_TYPE_MODIFIED:
      return state.merge({ lastRuleTypeModified: action.payload });

    case SET_ANY_RULE_IN_TIMELINE:
      return state.merge({ isAnyRuleEditedInTimeline: true });

    default:
      return state;
  }
}

export function showAppRate(): BaseThunk {
  return (dispatch, getState) => {
    try {
      track(Events.TryToOpenRateNativeModal, {
        scenario: getCurrentScenario(getState()),
      });

      global.AppRate.setPreferences({
        ...global.AppRate.preferences,
        openUrl: (url: string | URL | undefined) => {
          window.open(url, '_blank', 'location=yes');
        },
        storeAppURL: {
          android: `market://details?id=${qinit.tenant.parents_app.platforms.android.bundle_id}`,
          ios: qinit.tenant.parents_app.platforms.ios.app_id,
        },
        reviewType: {
          android: 'InAppReview',
          ios: 'InAppReview',
        },
      });

      dispatch(markRatingPopupAsViewed());
      global.AppRate.navigateToAppStore();
    } catch (e) {
      captureException(e);
    }
  };
}

export const setLastRuleTypeModified = (ruleType: RuleType) => ({
  type: SET_LAST_RULE_TYPE_MODIFIED,
  payload: ruleType !== undefined ? ruleType : undefined,
});

export const setAnyRuleInTimeline = () => ({
  type: SET_ANY_RULE_IN_TIMELINE,
});
