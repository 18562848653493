import * as React from 'react';
import Responsive from 'react-responsive';
import { DeviceWidthBreakpoints } from '../../constants';
import { isTesting } from '../../helpers/env';
import { getWindow } from '../../sideEffects/browser/window';

export enum ScreenSize {
  Mobile = 'mobile',
  Desktop = 'desktop',
  DesktopSmall = 'desktopSmall',
  DesktopBig = 'desktopBig',
  MobileOrDesktopBig = 'mobile-desktopBig',
  Tablet = 'tablet',
  MobileOrTablet = 'mobile-tablet',
  TabletOrDesktop = 'tablet-desktop',
  All = 'all',
}

export const hasScreenSize = (screenSize: ScreenSize) =>
  ({
    [ScreenSize.Mobile]: isMobileScreenSize,
    [ScreenSize.Desktop]: isDesktopScreenSize,
    [ScreenSize.DesktopSmall]: isDesktopSmallScreenSize,
    [ScreenSize.DesktopBig]: isDesktopBigScreenSize,
    [ScreenSize.Tablet]: isTabletScreenSize,
    [ScreenSize.MobileOrTablet]: isMobileOrTabletScreenSize,
    [ScreenSize.TabletOrDesktop]: isTabletOrDesktopScreenSize,
  }[screenSize](getWindow().innerWidth));

const isMobileScreenSize = (size: number) =>
  size <= DeviceWidthBreakpoints.medium - 1;
const isDesktopScreenSize = (size: number) =>
  size >= DeviceWidthBreakpoints.medium;
const isDesktopSmallScreenSize = (size: number) =>
  size >= DeviceWidthBreakpoints.medium &&
  size <= DeviceWidthBreakpoints.extraLarge;
const isDesktopBigScreenSize = (size: number) =>
  size >= DeviceWidthBreakpoints.extraLarge + 1;
const isTabletScreenSize = (size: number) =>
  size >= DeviceWidthBreakpoints.medium && size < DeviceWidthBreakpoints.large;
const isMobileOrTabletScreenSize = (size: number) =>
  isTabletScreenSize(size) || isMobileScreenSize(size);
const isTabletOrDesktopScreenSize = (size: number) =>
  isDesktopScreenSize(size) || isTabletScreenSize(size);

const mobile = `(max-width: ${DeviceWidthBreakpoints.medium - 1}px)`;
const desktop = `(min-width: ${DeviceWidthBreakpoints.large}px)`;
const tablet = `(min-width: ${
  DeviceWidthBreakpoints.medium
}px) and (max-width: ${DeviceWidthBreakpoints.large - 1}px)`;
const desktopSmall = `(min-width: ${DeviceWidthBreakpoints.medium}px) and (max-width: ${DeviceWidthBreakpoints.extraLarge}px)`;
const desktopBig = `(min-width: ${DeviceWidthBreakpoints.extraLarge + 1}px)`;

export const screenSizes = {
  [ScreenSize.Mobile]: [mobile],
  [ScreenSize.Desktop]: [desktop],
  [ScreenSize.Tablet]: [tablet],
  [ScreenSize.DesktopSmall]: [desktopSmall],
  [ScreenSize.DesktopBig]: [desktopBig],
  [ScreenSize.MobileOrDesktopBig]: [mobile, desktopBig],
  [ScreenSize.MobileOrTablet]: [mobile, tablet],
  [ScreenSize.TabletOrDesktop]: [tablet, desktop],
  [ScreenSize.All]: [mobile, tablet, desktop],
};

type RenderCaseProps = {
  screenSize: ScreenSize;
  children: React.ReactNode;
  onChange?: (isRendered: boolean) => void;
};

const RenderCase: React.FC<RenderCaseProps> = ({
  screenSize,
  onChange,
  children,
}: RenderCaseProps) => (
  <React.Fragment>
    {screenSizes[screenSize].map(query => (
      <Responsive query={query} onChange={onChange} key={query}>
        {children}
      </Responsive>
    ))}
  </React.Fragment>
);

interface RenderFunctionProps {
  children: (screen: ScreenSize) => React.ReactNode;
  screenSize: Array<ScreenSize>;
}

export const RenderFunction: React.FC<RenderFunctionProps> = ({
  children,
  screenSize,
}: RenderFunctionProps) => {
  return (
    <React.Fragment>
      {screenSize.map(size => (
        <RenderCase screenSize={size} key={size}>
          {children(size)}
        </RenderCase>
      ))}
    </React.Fragment>
  );
};

export interface RenderWhenProps {
  screenSize: RenderCaseProps['screenSize'] | RenderFunctionProps['screenSize'];
  children: RenderCaseProps['children'] | RenderFunctionProps['children'];
  onChange?: (isRendered: boolean) => void;
}

// TODO: have to check and fix the tests when the Desktop size is activated
type RenderWhenForTestEnvProps = Omit<RenderWhenProps, 'onChange'>;

const RenderWhenForTestEnv: React.FC<RenderWhenForTestEnvProps> = ({
  screenSize,
  children,
}: RenderWhenForTestEnvProps) => {
  const allowedTestScreenSize = [
    ScreenSize.Mobile,
    ScreenSize.MobileOrTablet,
    ScreenSize.MobileOrDesktopBig,
  ];

  const isVisible =
    typeof screenSize === 'string'
      ? allowedTestScreenSize.includes(screenSize)
      : screenSize.filter(size => allowedTestScreenSize.includes(size));

  const resolvedChildren =
    typeof children === 'function' ? children(ScreenSize.Mobile) : children;

  return <React.Fragment>{isVisible ? resolvedChildren : null}</React.Fragment>;
};

/**
 * Allow render components based on screen size.
 *
 * This component can be used in two modes:
 *  1. Passing a function as a children.
 *  2. As Cases.
 *
 * It is recommended to use "function as children" mode when cases are very similar, this significantly improves maintenance.
 *
 * IMPORTANT: When use legacy suite test screen size will be resolved to Mobile.
 *
 * Example Passing a function as a children:
 *
 * ```tsx
 *  <React.Fragment>
 *    <RenderWhen
 *      screenSize={[
 *        ScreenSize.Desktop,
 *        ScreenSize.Tablet
 *      ]}>
 *      {screenSize => screenSize === ScreenSize.Tablet ? 'A' : 'B'}
 *     </RenderWhen>
 *  </React.Fragment>
 * ```
 * Example as cases:
 *
 * ```tsx
 *  <React.Fragment>
 *    <RenderWhen screenSize={ScreenSize.Desktop}>A</RenderWhen>
 *    <RenderWhen screenSize={ScreenSize.Tablet}>B</RenderWhen>
 *    <RenderWhen screenSize={ScreenSize.Mobile}>C</RenderWhen>
 *  </React.Fragment>
 * ```
 */
const RenderWhen: React.FC<RenderWhenProps> = ({
  screenSize,
  children,
  onChange = () => undefined,
}: RenderWhenProps) => {
  if (isTesting()) {
    return (
      <RenderWhenForTestEnv screenSize={screenSize}>
        {children}
      </RenderWhenForTestEnv>
    );
  }

  if (typeof children === 'function' && Array.isArray(screenSize)) {
    return (
      <RenderFunction screenSize={screenSize}>
        {children as RenderFunctionProps['children']}
      </RenderFunction>
    );
  }

  if (typeof screenSize === 'string') {
    return (
      <RenderCase screenSize={screenSize} onChange={onChange}>
        {children}
      </RenderCase>
    );
  }

  throw new Error(
    'Invalid Props, children as function must to use screenSize as Array<ScreenSize>'
  );
};

export const isDesktop = (screenSize: ScreenSize) =>
  screenSize === ScreenSize.Desktop;

export const isMobile = (screenSize: ScreenSize) =>
  screenSize === ScreenSize.Mobile;

export const isTablet = (screenSize: ScreenSize) =>
  screenSize === ScreenSize.Tablet;

export default RenderWhen;
