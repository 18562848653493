import * as React from 'react';
import { t } from '../../lib/i18n';
import LocationRulesFlyover from './LocationRulesFlyover';
import type { LocationRulesPremiumFlyoverProps } from '../../containers/ProfileRules/LocationRules/LocationRulesPremiumFlyoverContainer';

const LOCATION_PREMIUM_FLYOVER = 'location-premium-flyover';

const LocationRulesPremiumFlyover = ({
  accountLocale,
  onClickUpgrade,
  onClickClose,
}: LocationRulesPremiumFlyoverProps) => {
  return (
    <LocationRulesFlyover
      accountLocale={accountLocale}
      testId={LOCATION_PREMIUM_FLYOVER}
      topbarText={t('{1}Upgrade your plan{/1} to enable location monitoring!', {
        1: x => x,
      })}
      actionButton={{
        title: t('Upgrade'),
        onClick: onClickUpgrade,
        testId: `${LOCATION_PREMIUM_FLYOVER}-upgrade`,
      }}
      onClickClose={onClickClose}
    />
  );
};

export default LocationRulesPremiumFlyover;
