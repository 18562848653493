import * as React from 'react';
import ActivityStyledItem from '../../ActivityTimeline/ActivityStyledItem/ActivityStyledItem';
import {
  SchoolTimelineIcon,
  getUpperSubtitle,
} from './common/schoolEventsCommon';
import { t } from '../../../lib/i18n';
import { IconColor, IconFamily, IconType } from 'styleguide-react';
import * as GoogleLogo from '../../../assets/base/images/icons/GoogleLogo.svg';
import * as BingLogo from '../../../assets/base/images/icons/BingLogo.svg';
import { BaseLinewizeEvent } from '../types/LinewizeEventsBase.types';
import ActivityStyledItemAction from '../../ActivityTimeline/ActivityStyledItemAction';
import Icon from '../../Icons/Icon';
import {
  SEARCH_SIGNATURE_NAME_BING,
  SEARCH_SIGNATURE_NAME_GOOGLE,
  SEARCH_SIGNATURE_NAME_OTHER,
  SEARCH_SIGNATURE_NAME_YOUTUBE,
} from '../../../records/linewizeEvent/types/linewizeEvents';

const SearchEvent: React.FC<BaseLinewizeEvent> = ({
  event,
  eventActions,
  onActionClick,
}) => {
  const { searchTerms, dt, action, atSchoolHours, signatureName } = event;
  // convert to lower case the signature name to prevent errors with capitalization
  const lowerSignatureName = signatureName?.toLocaleLowerCase();

  const defaultIcon = SchoolTimelineIcon(
    IconType.globe,
    IconColor.secondary,
    'par-activity-styled-item__icon-wrapper--secondary'
  );

  const defaultTitle = 'Web';

  const searchSignatures = {
    [SEARCH_SIGNATURE_NAME_GOOGLE]: {
      icon: <Icon path={GoogleLogo} />,
      engine: 'Google',
    },
    [SEARCH_SIGNATURE_NAME_YOUTUBE]: {
      icon: SchoolTimelineIcon(
        IconType.youtube,
        IconColor.error,
        'par-activity-styled-item__icon-wrapper--gray',
        IconFamily.brands
      ),
      engine: 'Youtube',
    },
    [SEARCH_SIGNATURE_NAME_BING]: {
      icon: <Icon path={BingLogo} />,
      engine: 'Bing',
    },
    [SEARCH_SIGNATURE_NAME_OTHER]: {
      icon: defaultIcon,
      engine: defaultTitle,
    },
  };

  const eventTitle = t('{{searchEngineName}} search', {
    searchEngineName:
      searchSignatures[lowerSignatureName!]?.engine || defaultTitle,
  });

  return (
    <ActivityStyledItem
      title={eventTitle}
      icon={searchSignatures[lowerSignatureName!]?.icon || defaultIcon}
      upperSubtitle={getUpperSubtitle(action, dt)}
      quoteText={searchTerms}
      isSchoolTime={atSchoolHours}
      actionElement={
        <ActivityStyledItemAction
          actions={eventActions}
          event={event}
          title={eventTitle}
          onActionClick={onActionClick}
        />
      }
    />
  );
};

export default SearchEvent;
