import * as React from 'react';
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import {
  Icon,
  IconColor,
  IconSize,
  IconType,
  List,
  RegularStyleListItem,
  ClickableListItem,
  ListTitle,
  EmptyPlaceholder,
  Layout,
} from 'styleguide-react';
import { isBrowserPlatform } from '../../helpers';
import { t } from '../../lib/i18n';
import { getForm } from '../../selectors';
import { PlaceAddressData } from '../../containers/FamilyLocator/types';
import ResetableTextField from '../Form/ResetableTextField';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

export const formName = 'search-address';

const ResolveAddressForm: React.FC<{
  searchString: string;
  searchResults: PlaceAddressData[];
  isFetchingAddresses: boolean;
  sessionToken: string;
  platform: string;
  onChangeHandler: (value: string, token: string) => any;
  onSearchResultsItemClick: (address: string, token: string) => any;
  keyboardHideHandler: () => any;
  keyboardShowHandler: () => any;
  formRecord: PlaceNewRecord;
}> = ({
  sessionToken,
  searchResults,
  searchString,
  isFetchingAddresses,
  onChangeHandler,
  onSearchResultsItemClick,
}) => (
  <PageLayout
    title={t('Search address')}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        mainInsideCard: true,
        columns: 1,
      },
    ]}
    actions={[{ actionName: 'back' }]}
  >
    <Layout margin="16" marginTop="24" marginBottom="40">
      <form
        id={formName}
        onSubmit={e => {
          e.preventDefault();
          if (!isBrowserPlatform()) {
            global.cordova.plugins.Keyboard.close();
          }
        }}
      >
        <Field
          name="name"
          component={ResetableTextField}
          icon={
            <Icon
              type={IconType.search}
              color={IconColor.primary}
              size={IconSize.sm}
            />
          }
          type="text"
          placeholder={t('Enter address')}
          onChange={event => onChangeHandler(event.target.value, sessionToken)}
        />
      </form>

      {searchResults.length ? (
        <List listItemMarginBottom="24" listItemPaddingBottom="8">
          {searchResults.map(result => (
            <RegularStyleListItem
              key={result.id}
              title={
                <ClickableListItem
                  onClick={() =>
                    onSearchResultsItemClick(result.id, sessionToken)
                  }
                >
                  <ListTitle>{result.main_text}</ListTitle>
                </ClickableListItem>
              }
              upperSubtitle={result.secondary_text}
            />
          ))}
        </List>
      ) : (
        searchString &&
        !isFetchingAddresses && (
          <Layout marginTop="56">
            <EmptyPlaceholder
              text={t('No results for {1}"{{searchString}}"{/1}.', {
                searchString,
                1: str => <strong>{str}</strong>,
              })}
            />

            <EmptyPlaceholder text={t('Try with another address.')} />
          </Layout>
        )
      )}
    </Layout>
  </PageLayout>
);

export type PlaceNewRecord = {
  searchString: string | null;
};

export const PlaceNewRecord = Record<PlaceNewRecord, {}>({
  searchString: null,
});

export const formRecord = (values: PlaceNewRecord): PlaceNewRecord => {
  if (values === null || values === undefined) {
    return PlaceNewRecord();
  }

  return values instanceof PlaceNewRecord ? values : PlaceNewRecord(values);
};

const ResolveAddressFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(ResolveAddressForm);

const mapStateToProps = (state, ownProps: any) => {
  return {
    formRecord: formRecord(getForm(state, formName) as PlaceNewRecord),
    ownProps,
  };
};

const ResolveAddressFormConnected = connect(mapStateToProps)(
  ResolveAddressFormReduxForm
);

export default ResolveAddressFormConnected;
