import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  ButtonType,
  IconAdapter,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import AppDailyTimeLimitCard, {
  AppDailyTimeLimitCardProps,
} from '../AppDailyTimeLimit/AppDailyTimeLimitCard';

export type AppDailyTimeLimitModalProps = Omit<
  AppDailyTimeLimitCardProps,
  'type'
> & {
  onDone: () => void;
};

const AppDailyTimeLimitModal: React.FunctionComponent<
  AppDailyTimeLimitModalProps
> = ({
  onDone,
  dayQuotaMap,
  icon,
  onChangeQuota,
  onSelectDay,
  selectedDay,
  title,
}: AppDailyTimeLimitModalProps) => (
  <Modal
    size="medium"
    onClickClose={onDone}
    header={{
      icon: (
        <IconAdapter
          icon={<img alt={title} src={icon} height="48px" width="48px" />}
        />
      ),
    }}
    title={title}
    buttons={[
      <Button
        key={1}
        onClick={onDone}
        size={ButtonSize.medium}
        block
        buttonType={ButtonType.primary}
      >
        {t('Done')}
      </Button>,
    ]}
  >
    <AppDailyTimeLimitCard
      type="desktop"
      dayQuotaMap={dayQuotaMap}
      icon={icon}
      onChangeQuota={onChangeQuota}
      onSelectDay={onSelectDay}
      selectedDay={selectedDay}
      title={title}
    />
  </Modal>
);

export default AppDailyTimeLimitModal;
