import * as React from 'react';
import { t } from '../../lib/i18n';
import Flyover from '../Flyover/Flyover';
import Svg from '../../assets/base/images/v3/flyovers/illustration_start_profile.svg';
import { Button, ButtonSize } from 'styleguide-react';

export interface ProfileStartFlyoverProps {
  onClickStart: () => void;
}

const ProfileStartFlyover: React.FC<ProfileStartFlyoverProps> = ({
  onClickStart,
}) => (
  <Flyover
    description={t("Let's protect your child's device with {{shortName}}!")}
    canClose={false}
    illustrationSrc={Svg}
    button={
      <Button size={ButtonSize.medium} onClick={onClickStart}>
        {t('Start now')}
      </Button>
    }
  />
);

export default ProfileStartFlyover;
