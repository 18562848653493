import * as React from 'react';

import { connect } from 'react-redux';
import { Layout, FlexLayout } from 'styleguide-react';

import State, { Dispatch } from '../../store/state';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

import PastDateSelector from '../PastDateSelector/PastDateSelector';

import { SummaryDateRanges } from '../../constants';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { selectedPastDateChanged } from '../../ducks/summary';
import {
  getSelectedPastDate,
  getDaysSinceAccountCreated,
} from '../../selectors';
import DateRangeSelectorTab from './DateRangeTab';
import { studentPastDateChanged } from '../../ducks/studentSummary';

type Props<T> = React.FunctionComponent<
  {
    activeRange: SummaryDateRanges;
    onChangeDateRange: (range: SummaryDateRanges) => any;
  } & T
>;

interface PropsDateRange {
  activeRange: SummaryDateRanges;
  onChangeDateRange: (selectDate: number | string) => void;
  numDays: number;
  selectedDate: string;
  onChangeSelectedDate: (selectedDate: string) => void;
  showCustomPastDate: boolean;
}

const DateRangeSelector: React.FunctionComponent<PropsDateRange> = ({
  numDays,
  activeRange,
  selectedDate,
  onChangeDateRange,
  showCustomPastDate,
  onChangeSelectedDate,
}: PropsDateRange) => {
  const ranges = [
    SummaryDateRanges.Today,
    SummaryDateRanges.Days7,
    SummaryDateRanges.Days15,
    SummaryDateRanges.Days30,
    ...(showCustomPastDate ? [SummaryDateRanges.CustomPastDay] : []),
  ];

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <FlexLayout mainaxis="row" mainaxisAlignment="left" marginLeft="8">
          <Layout minWidth="360">
            <DateRangeSelectorTab
              ranges={ranges}
              activeRange={activeRange}
              onChangeDateRange={onChangeDateRange}
            />
          </Layout>
        </FlexLayout>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Tablet}>
        <FlexLayout mainaxis="row" mainaxisAlignment="center" marginBottom="16">
          <Layout minWidth="360">
            <DateRangeSelectorTab
              ranges={ranges}
              activeRange={activeRange}
              onChangeDateRange={onChangeDateRange}
            />
          </Layout>
        </FlexLayout>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <FlexLayout
          margin="16"
          marginTop="16"
          mainaxis="column"
          marginBottom="16"
          mainaxisAlignment="space-around"
        >
          <DateRangeSelectorTab
            ranges={ranges}
            activeRange={activeRange}
            onChangeDateRange={onChangeDateRange}
          />
        </FlexLayout>
      </RenderWhen>
      {selectedDate && (
        <PastDateSelector
          numDays={numDays}
          selectedDate={selectedDate}
          onChangeSelectedDate={onChangeSelectedDate}
        />
      )}
    </React.Fragment>
  );
};

export const mapStateToProps = (state: State, props) => ({
  selectedDate: getSelectedPastDate(state),
  numDays: getNumDaysToDisplayInCalendarPicker(state),
  showCustomPastDate: getFeatureFlag(state, 'calendarPicker'),
  ...props,
});

const mapDispatchToProps = (dispatch: Dispatch, props) => ({
  onChangeDateRange: (dateRange: SummaryDateRanges) => {
    props.onChangeDateRange(dateRange);
  },
  onChangeSelectedDate: (selectedDate: string) => {
    dispatch(selectedPastDateChanged(selectedDate));
    dispatch(studentPastDateChanged(selectedDate));
    props.onChangeDateRange(SummaryDateRanges.CustomPastDay);
  },
});

export const getNumDaysToDisplayInCalendarPicker = (state: State) => {
  const daysSinceAccountCreated = getDaysSinceAccountCreated(state);
  return daysSinceAccountCreated >= 30 ? 29 : daysSinceAccountCreated;
};

const DateRangeSelectorConnected: Props<{}> = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateRangeSelector) as any;

export default DateRangeSelectorConnected;
