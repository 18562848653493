import { goBackIfHistory } from '../ducks/routing';
import { setItem, removeItem } from '../ducks/persistentStorage';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

export const navigateToLink = (url: string) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(goBackIfHistory());
  dispatch(
    navigate({
      type: 'external',
      src: url,
    })
  );
};

export const close = () => dispatch => {
  dispatch(removeItem('externalNavigationAccepted'));
  dispatch(goBackIfHistory());
};

export const clickCheckbox = (isChecked: boolean) => dispatch => {
  dispatch(
    isChecked
      ? setItem('externalNavigationAccepted', 'true')
      : removeItem('externalNavigationAccepted')
  );
};
