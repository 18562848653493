import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import PauseProfileModal from '../../components/Modal/PauseProfileModal/PauseProfileModal';
import {
  postProfilePause,
  trackPauseButtonClick,
} from '../../ducks/pause/thunks';
import { goBackIfHistory } from '../../ducks/routing';
import { getIsProfilePaused } from '../../ducks/pause/selectors';
import {
  getAccountLocale,
  getProfile,
  getProfileDevices,
  getTimezone,
} from '../../selectors';
import { durationInMinutes, localNow } from '../../helpers/dates';
import { getCalendarRestrictionPauseEnd } from '../../selectors/stateSelectors/calendarRestrictionsPause';
import { hasPauseInternetOverlap } from '../../selectors/entitySelectors/pauseInternetOverlap';
import { numOfDevicesDisabledInSafeNetwork } from '../../selectors/safeNetwork';
import flags from '../../sideEffects/flags';
import {
  getIsPauseEnabled247,
  getSchoolStartEnd,
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { getAllProfileDevicesByStatus } from '../../businessLogic/safeNetwork/safeNetwork';
import { getIsSchoolOnlyProfile } from '../Dashboard/helpers/profileDashboardHelpers';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const pId = Number(profileId);
  const isProfilePaused = getIsProfilePaused(state, pId);
  const timezone = getTimezone(state);
  const currentTime = localNow(timezone);
  const pausedUntilTime = getCalendarRestrictionPauseEnd(state);
  const pausedUntilTimeMinutes =
    pausedUntilTime && currentTime.isBefore(pausedUntilTime)
      ? durationInMinutes(pausedUntilTime, currentTime.clone())
      : 0;
  const profile = getProfile(state, profileId);
  const hasAnyDeviceInSafeNetwork =
    numOfDevicesDisabledInSafeNetwork(state, Number(profileId)) > 0 &&
    flags.safeNetworkSettings.isEnabled();
  const studentPause247Enabled = getIsPauseEnabled247(state);
  const schoolStartAndEndDatetime = getSchoolStartEnd(state);
  const devices = getProfileDevices(state, profileId).toJS();
  const devicesWithSafeNetworkActive = getAllProfileDevicesByStatus(
    pId,
    devices
  );
  const isStudentDelegatedToAnotherAccount =
    flags.useDelegation.isEnabled() &&
    profile?.isLinewizestudentLinked &&
    isDelegationEnabled(state) &&
    isStudentOptInForDelegation(state) &&
    !isStudentOptInForDelegationForThisAccount(state);
  const isSchoolOnlyProfile = getIsSchoolOnlyProfile(state, pId);

  return {
    isProfilePaused,
    pausedUntilTimeMinutes,
    currentTime,
    timezone,
    locale: getAccountLocale(state) as string,
    isOverlapped: hasPauseInternetOverlap(state, Number(profileId)),
    profile,
    hasAnyDeviceInSafeNetwork,
    devicesWithSafeNetworkActive,
    startPeriodDatetime: schoolStartAndEndDatetime.start,
    endPeriodDatetime: schoolStartAndEndDatetime.end,
    studentPause247Enabled,
    isStudentDelegatedToAnotherAccount,
    isSchoolOnlyProfile,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => {
  const pId = Number(profileId);
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onSetPauseDuration: async (duration: number) => {
      dispatch(trackPauseButtonClick(duration));
      dispatch(postProfilePause(duration, pId));
      dispatch(goBackIfHistory());
    },
  };
};

const PauseProfileModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PauseProfileModal);

export type PauseProfileModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default PauseProfileModalContainer;
