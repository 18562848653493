import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { t } from '../../lib/i18n';
import IconInsights from '../Icons/IconInsights';
import IconKeyboardArrowRight from '../Icons/IconKeyboardArrowRight';
import InsightsNewBalloon from './InsightsNewBalloon';
import { getProfileId } from '../../selectors/index';

const InsightsButton: React.FunctionComponent<{
  profileId: string;
}> = ({ profileId }) => (
  <Link to={`/profiles/${profileId}/insights`} className="InsightButton">
    <div className="InsightButton__content-left">
      <i className="InsightButton__content-left__col">
        <IconInsights />
      </i>
      <p className="InsightButton__content-left__col">{t('App Insights')}</p>
      <span className="InsightButton__content-left__col">
        <InsightsNewBalloon />
      </span>
    </div>
    <i className="InsightButton__content-right">
      <IconKeyboardArrowRight />
    </i>
  </Link>
);

const mapStateToProps = state => ({
  profileId: getProfileId(state) || '',
});

const InsightButtonContainer = connect(mapStateToProps)(InsightsButton);

export { InsightsButton };
export default InsightButtonContainer;
