/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { t } from '../../../lib/i18n';

import type { SignatureDetailsProps } from '../../../containers/Schools/signature/SignatureDetailsContainer';
import PageLayout from '../../Layout/PageLayout';
import { ScreenSize } from '../../RenderWhen/RenderWhen';
import ExpertReviewerCard from './ExpertReviewerCard';
import SignatureInfoCard, { SignatureInfoCardProps } from './SignatureInfoCard';
import SignatureDescriptionCard from './SignatureDescriptionCard';
import SignatureKnownRisksCard from './SignatureKnownRisksCard';
import SignatureAdditionalLinkCard from './SignatureAdditionalLinkCard';
import SignatureNoDataCard from './SignatureNoDataCard';
import SignatureLoadingPlaceholder from './SignatureLoadingPlaceholder';

type NonNullSignature = NonNullable<SignatureDetailsProps['signature']>;

const SignatureDetails: React.FunctionComponent<SignatureDetailsProps> = ({
  fetchStatus,
  signature,
  signaturesDataItem,
  isError,
  onClickGoBack,
  goToLink,
}: SignatureDetailsProps) => {
  // if signature has review
  //   If has expert reviewer show it
  //   If has age rating show it
  //   If has known risks show it
  //   If has link to hub (learn more) show it

  // If signature does not have review
  //   If has age rating show it
  //   If has description show it
  //   If has links to the stores show them
  //   If has link to hub (learn more) show it
  //   If nothing of the above exists, show empty/no information status

  const { showInfoCard, signatureInfo } = getSignatureData({
    signature,
    signaturesDataItem,
  });

  const showLoader = fetchStatus === 'fetching';
  const showEmptyPlaceholder =
    fetchStatus === 'settled' && !signature?.name && !isError;

  const review = signature?.get('review')?.review;
  let reviewer = signature?.get('review')?.reviewer?.toJS();
  reviewer = reviewer?.name ? reviewer : undefined;

  return (
    <PageLayout
      title={t('Insights')}
      isProfileEditable
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          titleCentered: false,
          mainExtendedSize: true,
          columns: 1,
          cardCentered: false,
        },
      ]}
      className="SignatureDetails"
      actions={[{ actionName: 'back', onClick: onClickGoBack }]}
    >
      <div className="flex-container">
        <FlexLayout mainaxis="row">
          <FlexLayout mainaxis="column" className="left-column">
            {showInfoCard && <SignatureInfoCard {...signatureInfo} />}
            {showLoader && <SignatureLoadingPlaceholder />}
            {review && <SignatureDescriptionCard review={review} />}
            {signature?.name &&
              reviewContent(signature as NonNullSignature, goToLink)}
          </FlexLayout>
          <FlexLayout mainaxis="column" className="right-column">
            {reviewer && <ExpertReviewerCard {...reviewer} />}
          </FlexLayout>
        </FlexLayout>

        {showEmptyPlaceholder ? (
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column" className="left-column">
              <SignatureNoDataCard />
            </FlexLayout>
            <FlexLayout mainaxis="column" className="right-column">
              <React.Fragment />
            </FlexLayout>
          </FlexLayout>
        ) : null}
      </div>
    </PageLayout>
  );
};

const getSignatureData = ({
  signaturesDataItem,
  signature,
}: Pick<SignatureDetailsProps, 'signature' | 'signaturesDataItem'>) => {
  /**
   * IF user navigates from /school/summary
   * - IF app is fetching:
   *   THEN we retrive the signature item data from the signaturesData
   *
   * - IF app has finished fetching:
   *   THEN we read the signature data from its corresponding state.
   *
   * IF user navigates to /school/signature/xxx.yyy.zzz directly
   * THEN we do not show the signature info header
   */

  const initialValue: SignatureInfoCardProps = {
    name: '',
    iconUrl: '',
    categoryName: '',
    recommendation: 'NOT_RATED',
    ageRating: undefined,
  };

  let signatureInfo = initialValue;
  let showInfoCard = true;

  if (signature?.name) {
    signatureInfo = {
      name: signature.get('name'),
      iconUrl: signature.get('iconUrl'),
      categoryName: signature.get('categoryName'),
      recommendation: signature.getIn(['review', 'recommendation']),
      ageRating: signature.getIn(['review', 'ageRating']),
    };
  } else if (signaturesDataItem) {
    signatureInfo = {
      name: signaturesDataItem.name,
      iconUrl: signaturesDataItem.iconUrl,
      categoryName: signaturesDataItem.categoryName,
      recommendation: signaturesDataItem.recommendation,
      ageRating: undefined,
    };
  } else {
    showInfoCard = false;
  }

  return {
    showInfoCard,
    signatureInfo,
  };
};

const reviewContent = (
  signature: NonNullSignature,
  goToLink: SignatureDetailsProps['goToLink']
) => {
  const risks = signature.get('review')?.risks;
  const hubLink = signature.get('hubLink');
  const playStoreUrl = signature.get('playStoreUrl');
  const appStoreUrl = signature.get('appStoreUrl');

  return (
    <React.Fragment>
      {risks && !risks.isEmpty() && <SignatureKnownRisksCard risks={risks} />}

      {(hubLink || playStoreUrl || appStoreUrl) && (
        <React.Fragment>
          {hubLink && (
            <SignatureAdditionalLinkCard
              link={hubLink}
              onClick={() => goToLink(hubLink)}
              text={t('Learn more')}
            />
          )}
          {playStoreUrl && (
            <SignatureAdditionalLinkCard
              link={playStoreUrl}
              onClick={() => goToLink(playStoreUrl)}
              text={t('Go to Google Play')}
            />
          )}
          {appStoreUrl && (
            <SignatureAdditionalLinkCard
              link={appStoreUrl}
              onClick={() => goToLink(appStoreUrl)}
              text={t('Go to the App Store')}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SignatureDetails;
