import * as moment from 'moment-timezone';
import {
  SCHEDULE_WEEKDAYS,
  ScheduleRecord,
} from '../../records/routines/schedule/types/Schedule.types';
import { List } from 'immutable';
import {
  API_BIRTHDATE_FORMAT,
  DATE_FORMAT_HOUR,
  convertDate,
  dayCountDiff,
  isDatetimeBetweenDatetimes,
  militaryTimeToAMPMFormatWithHoursAndMinutes,
} from '../../helpers/dates';
import { RoutineRecord } from '../../records/routines/types/Routine.types';

export const isRoutinePaused = (routine?: RoutineRecord | null) =>
  routine ? routine?.paused : true;

export const isRoutineTypeBlocked = (routine?: RoutineRecord | null) =>
  routine?.type === 'ROUTINE_TYPE_BLOCKED';

/** routine !enabled => deleted */
export const isRoutineEnabled = (routine?: RoutineRecord | null) =>
  Boolean(routine?.enabled);

export const isRoutineScheduleOverridden = (schedule: ScheduleRecord | null) =>
  Boolean(schedule?.overrides);

/**
 * Gets a sanitized routine name by removing the `_[uuid]_disabled` suffix.
 *
 * The suffix is formed using `_`, the first 5 chars or the full routine uid
 * and the word `_disabled`.
 */
export const getSanitisedDisabledRoutineName = (routineName: string) =>
  routineName.replace(/_[A-Za-z0-9]{5,}_disabled$/, '');

export const doesRoutineConsumeDailyTimeQuota = (
  routine?: RoutineRecord | null
) => routine != null && routine.policy.countsTowardsDailyTimeQuota;

const isScheduleStartInTheFuture = (
  currentTime: moment.Moment,
  scheduleFromDate: string
) =>
  dayCountDiff(
    moment(scheduleFromDate, API_BIRTHDATE_FORMAT),
    currentTime.clone()
  ) > 0;

const isScheduleEndInThePast = (
  currentTime: moment.Moment,
  scheduleToDate?: string
) =>
  scheduleToDate
    ? dayCountDiff(
        moment(scheduleToDate, API_BIRTHDATE_FORMAT),
        currentTime.clone()
      ) < 0
    : false;

const currentTimeIsBetweenSchedule = (
  currentTime: moment.Moment,
  duration: number
) => {
  const nowDate = currentTime.clone();
  // Normalise schedule start time to start from 00:00
  const start = moment(nowDate.startOf('day').toISOString());
  const end = start.clone().add('minutes', duration);
  const inclusivity = '[]'; // [] indicates inclusivness of the range start & end for equality case.
  return isDatetimeBetweenDatetimes(
    currentTime.clone(),
    start,
    end,
    inclusivity
  );
};

const getMinutesFromStartOfDay = (hours: string) =>
  moment(hours, DATE_FORMAT_HOUR).diff(
    moment('00:00', DATE_FORMAT_HOUR),
    'minutes'
  );

/**
 * Get the active schedule.
 * We shift/normalise the schedule start time so we don't have to
 * deal with schedule going into the next day.
 *
 * ```
 * // Original:        18h   0h now 6h   12h
 * // ...---------------|-----|--^--|-----|-----...
 * //                      XXXXXXXXXX schedule
 *
 * // Normalized schedule:
 * //      now-------------------*
 * // 0h   6h    12h   18h    0h    6h
 * // |-----|-----|-----|-----|-----|---...
 * // XXXXXXXXX------------*
 * ```
 */
export const getActiveScheduleFromSchedulesList = (
  routineSchedules: List<ScheduleRecord> | null,
  currentTime: moment.Moment
): ScheduleRecord | null => {
  if (!routineSchedules) return null;
  const schedules = routineSchedules?.filter(schedule => {
    if (!schedule) return false;

    const now = currentTime.clone();
    if (isScheduleStartInTheFuture(now, schedule.fromDate)) return false;
    if (isScheduleEndInThePast(now, schedule.toDate)) return false;

    const shift = getMinutesFromStartOfDay(schedule.startTime);
    const nowShifted = now.subtract(shift, 'minutes');
    const weekday = SCHEDULE_WEEKDAYS[nowShifted.isoWeekday() - 1];
    if (!schedule.weekdays.includes(weekday)) return false;
    return currentTimeIsBetweenSchedule(now, schedule.durationMinutes);
  });

  if (schedules.size <= 1) return schedules.first() ?? null;

  // There can be a scenario where the user creates an override schedule for the
  // currently active routine. Because of this we will find multiple "active" schedules.
  // We have to return the "overridden" schedule.
  const overriddenSchedule = schedules.find(isRoutineScheduleOverridden);
  if (!overriddenSchedule)
    throw new Error('There cannot be multiple active schedules');

  return overriddenSchedule;
};

export const momentifySchedule = (
  schedule: ScheduleRecord | null,
  isMilitaryTime: boolean,
  accountTimezone: string
) => {
  if (!schedule) return null;

  const startTimeMoment = moment(schedule.startTime, DATE_FORMAT_HOUR);
  const endTimeMoment = startTimeMoment
    .clone()
    .add('minutes', schedule.durationMinutes);

  const startTime = !isMilitaryTime
    ? militaryTimeToAMPMFormatWithHoursAndMinutes(startTimeMoment)
    : schedule.startTime;

  const endTime = !isMilitaryTime
    ? militaryTimeToAMPMFormatWithHoursAndMinutes(endTimeMoment)
    : endTimeMoment.format(DATE_FORMAT_HOUR);

  return {
    ...schedule.toJS(),
    fromDate: convertDate(schedule.fromDate, accountTimezone),
    toDate: schedule.toDate
      ? convertDate(schedule.toDate, accountTimezone)
      : undefined,
    startTime,
    endTime,
    startTimeMoment,
    endTimeMoment,
  };
};
