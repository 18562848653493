import { combineEpics, ofType } from 'redux-observable';
import { switchMap, first } from 'rxjs/operators';
import { LocationChangeAction } from 'react-router-redux';
import { AppEpicWithoutStateEffect, AppAction } from '../types';
import { doWithoutStateEffect } from '../operators';
import {
  isProfileFamilySchoolPage,
  isProfileFamilySchoolSubPage,
  ofLocationChangeActionWhen,
} from '../router';
import { Dependencies } from '../middleware';
import { not } from '../../helpers/predicates';
import flags from '../../sideEffects/flags';
import { forkJoin } from 'rxjs';
import { getSummaryFetchError } from '../../selectors/studentSummary';
import { isFeatureDisabled } from '../../containers/NowCard/helpers/schools/helper';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { getProfileTopBarTrackingFormatted } from './ProfileTopBar';

export const sendProfileFamilySchoolStatus = (
  values: {
    cardState;
    devicesStatus;
    profileTopNotification;
    schoolHasEnabledSummary;
    schoolHasPauseEnabled;
    studentDelegationPolicies;
  },
  analytics: Dependencies['analytics']
) => {
  const { format, trackPageLoaded, GenericPageNames } = analytics;

  const data = format({
    options: {
      nowCardStatus: values.cardState?.toLowerCase(),
      devicesStatus: values.devicesStatus,
      profileTopNotification: values.profileTopNotification,
      routineActive: null,
      schoolHasEnabledPause: values.schoolHasPauseEnabled,
      schoolHasEnabledSummary: values.schoolHasEnabledSummary,
      isDelegationEnabled: values.studentDelegationPolicies.enabled,
      isDelegated: values.studentDelegationPolicies.optedIn,
      isDelegatedToThisAccount:
        values.studentDelegationPolicies.optedInByThisAccount,
    },
  });

  trackPageLoaded(GenericPageNames.ProfileUnifiedActivity, data);
};

const sendLoadPageAnalytics = (analytics: Dependencies['analytics']) => {
  const { page, PageNames } = analytics;
  page(PageNames.ProfileUnifiedActivity);
};

const ensureOfProfileFamilySchoolPage = ofLocationChangeActionWhen({
  to: isProfileFamilySchoolPage,
  from: not(isProfileFamilySchoolSubPage),
});

export const pageDataLoadedEpic: AppEpicWithoutStateEffect<
  LocationChangeAction
> = ($actions, $state, { analytics }) => {
  return $actions.pipe(
    ensureOfProfileFamilySchoolPage,
    switchMap(() => {
      const featureFlagUnifiedDashboardEnabled =
        flags.useUnifiedDashboard.isEnabled();
      if (featureFlagUnifiedDashboardEnabled) {
        const $unifiedNowCardLoading = $actions.pipe(
          ofType('NOW_CARD_LOADED_STATE'),
          first()
        );
        const $smartDeviceListLoading = $actions.pipe(
          ofType('SMART_DEVICES_UPDATE_LOADED'),
          first()
        );
        const $topProfileNotificationBarLoading = $actions.pipe(
          getProfileTopBarTrackingFormatted
        );
        const $receivedStudents = $actions.pipe(
          ofType('RECEIVE_STUDENT_POLICIES'),
          first()
        );
        return forkJoin([
          $unifiedNowCardLoading,
          $smartDeviceListLoading,
          $topProfileNotificationBarLoading,
          $receivedStudents,
        ]).pipe(
          doWithoutStateEffect(values => {
            const cardState = values[0].payload.state;
            const devicesStatus = values[1].payload.devices;
            const profileTopNotification = values[2].payload.state;
            const schoolHasEnabledSummary =
              getSummaryFetchError($state.value) !==
              StudentSummaryFetchError.FeatureDisabled;
            const schoolHasPauseEnabled = !isFeatureDisabled($state.value);
            const studentDelegationPolicies =
              values[3].payload.records.studentDelegation;
            sendProfileFamilySchoolStatus(
              {
                cardState,
                devicesStatus,
                profileTopNotification,
                schoolHasEnabledSummary,
                schoolHasPauseEnabled,
                studentDelegationPolicies,
              },
              analytics
            );
          })
        );
      }
    })
  );
};

export const loadProfileEpic: AppEpicWithoutStateEffect<AppAction> = (
  $actions,
  _$state,
  { analytics }
) => {
  return $actions.pipe(
    ensureOfProfileFamilySchoolPage,
    doWithoutStateEffect(() => sendLoadPageAnalytics(analytics))
  );
};

export const profileFamilySchoolTrackingEpics = combineEpics(
  pageDataLoadedEpic,
  loadProfileEpic
);
