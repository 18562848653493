/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { Button, FlexLayout, Typography } from 'styleguide-react';
import type { ThankYouProps } from '../containers/ThankYou/ThankYouContainer';
import * as qinit from '../qinit';
import { t } from '../lib/i18n';
import RenderWhen, { ScreenSize } from './RenderWhen/RenderWhen';
import IconThankYou from '../assets/base/images/thank-you-icon.svg';
import SingleLayout from './Layout/SingleLayout';

const ThankYou = ({
  renewalDate,
  orderAmount,
  orderFrequency,
  onClickGoToDashboard,
  onClickExternalLink,
}: ThankYouProps) => {
  const customerPortalUrl = 'https://qustodio.chargebee.com/portal/v2/login';

  const renderLink = (url: string, text: string, bold: boolean) => (
    <a onClick={() => onClickExternalLink(url)} href="javascript:void(0)">
      {bold ? <strong>{text}</strong> : text}
    </a>
  );

  return (
    <SingleLayout>
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        className="par-thank-you"
      >
        <img className="par-thank-you__logo" alt="logo" src={IconThankYou} />
        <Typography type="h3" align="center" renderAs="h1" weight="semibold">
          {t('Thank you for your order!')}
        </Typography>
        <FlexLayout mainaxis="column" marginBottom="32">
          <Typography type="body1" align="center" renderAs="p">
            {t(
              'Your subscription is active and your next renewal will be on {{renewalDate}}. You will be charged {{orderAmount}} every {{orderFrequency}}.',
              {
                renewalDate,
                orderAmount,
                orderFrequency,
              }
            )}
          </Typography>
          <Typography type="body1" align="center" renderAs="p">
            {t(
              'To manage your subscription please visit our {1}customer portal{/1}.',
              {
                1: (str: string) => renderLink(customerPortalUrl, str, true),
              }
            )}
          </Typography>
        </FlexLayout>
        <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
          <Typography type="h3" renderAs="h2" weight="semibold">
            {t(
              'You can now enjoy all your {{shortName}} subscription has to offer.'
            )}
          </Typography>
        </RenderWhen>
        <FlexLayout mainaxis="column" marginBottom="24">
          <Button
            onClick={onClickGoToDashboard}
            testId="par-thank-you-main-cta"
          >
            {t('Start now')}
          </Button>
        </FlexLayout>
        <Typography type="body1" align="center" renderAs="p">
          {t(
            '{1}Love {{shortName}}?{/1} Rate us now in the {2}App Store{/2} or in {3}Google Play Store{/3}.',
            {
              1: (str: string) => <strong>{str}</strong>,
              2: (str: string) =>
                renderLink(
                  qinit.application.downloads.ios_parents_app,
                  str,
                  false
                ),
              3: (str: string) =>
                renderLink(
                  qinit.application.downloads.android_parents_app,
                  str,
                  false
                ),
            }
          )}
        </Typography>
      </FlexLayout>
    </SingleLayout>
  );
};

export default ThankYou;
