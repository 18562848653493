import * as React from 'react';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import PanicButtonRulesInviteContactForm, {
  formName,
} from './PanicButtonRulesInviteContactForm';
import { ContactRecord } from '../../records/contact';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const PanicButtonRulesInviteContact: React.FunctionComponent<{
  isSaving: boolean;
  contactType: string;
  country: string;
  contacts: List<ContactRecord>;
  onSubmit: (event: any) => void;
}> = ({ isSaving, contactType, contacts, onSubmit, country }) => (
  <PageLayout
    title={t('Panic Button')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        mainInsideCard: true,
        columns: 2,
      },
      {
        screenSize: ScreenSize.Tablet,
        mainInsideCard: true,
        columns: 1,
      },
    ]}
  >
    <FlexLayout mainaxis="column" matchParentHeight>
      <FlexLayout mainaxis="column" flexGrow="2">
        <PanicButtonRulesInviteContactForm
          onSubmit={onSubmit}
          contactType={contactType}
          contacts={contacts}
          country={country}
        />
      </FlexLayout>
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          size={ButtonSize.medium}
          htmlType="submit"
          form={formName}
          loading={isSaving}
          disabled={isSaving}
        >
          {t('send invitation')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  </PageLayout>
);

export default PanicButtonRulesInviteContact;
