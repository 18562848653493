import * as React from 'react';
import { Layout } from 'styleguide-react';
import { ProfileRecord } from '../../records';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { SummaryDateRanges } from '../../constants';
import ActivitySummaryListContainer from '../../containers/ActivitySummary/ActivitySummaryListContainer';
import { ActivityType } from './sharedModels';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

type Props<T> = React.FunctionComponent<
  {
    type: ActivityType;
    profile: ProfileRecord;
  } & T
>;

const ActivitySummaryPage: Props<{
  activeDateRange: SummaryDateRanges;
  onClickEditProfile: () => any;
  onSelectDateRange: (range: SummaryDateRanges) => any;
}> = ({ profile, onClickEditProfile, ...props }) => {
  return (
    <PageLayout
      title={profile.name}
      configuration={[{ screenSize: ScreenSize.Desktop, columns: 2 }]}
      actions={[
        { actionName: 'back' },
        {
          actionName: 'edit',
          screenSize: ScreenSize.MobileOrTablet,
          onClick: () => {
            onClickEditProfile();
          },
        },
      ]}
    >
      <ActivitySummaryPageContent profile={profile} {...props} />
    </PageLayout>
  );
};

const ActivitySummaryPageContent: Props<{
  activeDateRange: SummaryDateRanges;
  onSelectDateRange: (range: SummaryDateRanges) => any;
}> = ({ activeDateRange, onSelectDateRange, ...props }) => (
  <React.Fragment>
    <Layout marginBottom="8">
      <DateRangeSelector
        activeRange={activeDateRange}
        onChangeDateRange={onSelectDateRange}
      />
    </Layout>
    <ActivitySummaryListWrapper {...props} />
  </React.Fragment>
);

const ActivitySummaryListWrapper: Props<{}> = ({ type, profile }) => (
  <ActivitySummaryListContainer
    type={type}
    profile={profile}
    hasExpandLink={false}
    showAllAvailableActivity
    displayAll
  />
);

export default ActivitySummaryPage;
