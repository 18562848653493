import * as debounce from 'lodash.debounce';
import { change } from 'redux-form';
import { t } from '../lib/i18n';
import { EventRecord } from '../records/event';
import { Dispatch } from '../store/state';
import { requestProfilesError } from '../ducks/profiles';
import { navigateBack, goBackIfHistory } from '../ducks/routing';
import { showToast, TOAST_ICON_TICK, TOAST_ICON_WARNING } from '../ducks/toast';
import { tapReject } from '../helpers';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import {
  trackLocationItemClick,
  trackOlderLocationsLoaded,
  FamilyLocatorViews,
} from '../helpers/analytics';
import {
  FamilyLocatorScreen,
  RouteActions,
} from '../containers/FamilyLocator/types';
import {
  getValidMarkers,
  getProfileMarker,
  getEventMarker,
  getMarkerFromAddPlaceForm,
  getFormMarker,
} from '../selectors/marker';
import { getPlaceFormMarker } from '../selectors/place';
import { getLicense } from '../selectors/license';
import { formName as addPlaceForm } from '../components/FamilyLocator/PlaceForm';
import { formName as searchAddressForm } from '../components/FamilyLocator/ResolveAddress';
import {
  addPlace,
  fetchPlaces,
  updateSearchResults,
  updateSearchString,
  updateFetchingAddresses,
  updateToken,
  updatePlace as updatePlaceDuck,
} from '../ducks/places';
import { PlaceRecord } from '../records';

import {
  resolveAddressFromString,
  getAddressFromPlaceId,
} from '../lib/geocode';
import { Address } from '../lib/vendor/googleGeocoding';
import { getMilisecondsTimestamp } from '../helpers/dates';
import { mapMarkerSafetyStatus } from '../components/FamilyLocator/ProfileLocationStatusHelper';
import { Marker } from '../records/marker';
import { feetToMeters } from '../helpers/conversions';
import { NO_EVENT_KEY } from '../constants';
import { isFree } from '../records/license';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { showUnlockCompleteModal } from './PremiumFeaturesActions';
import { shouldBlockThisFeature } from '../helpers/premiumFeatures';

export const LIMIT_OF_PLACES_CREATED_BY_THE_USER = 15;

export const handleRefresh = debounce(
  (dispatch: Dispatch, fetchFunctions, stopRefresh: () => any) => {
    // eslint-disable-next-line no-unused-expressions
    Promise.all(fetchFunctions)
      .then(() => {
        stopRefresh();
        dispatch(showToast(t('Locations updated'), TOAST_ICON_TICK));
      })
      .catch(() =>
        tapReject(() => {
          dispatch(requestProfilesError());
          dispatch(showToast(t('Location update failed'), TOAST_ICON_WARNING));
        })
      );
  },
  600,
  {
    leading: true,
    trailing: false,
  }
);

export const clickLocation =
  (event: EventRecord, profileId: number) => dispatch => {
    trackLocationItemClick(event);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner',
        src: `/familyLocator/${profileId}/location/${event.key}/action/${RouteActions.fullMap}`,
      })
    );
  };

export const getMarkersByScreen = (
  state,
  screen: FamilyLocatorScreen,
  params: {
    profileId: string;
    action: string;
    eventKey?: string;
    placeUid?: string;
  }
) => {
  if (FamilyLocatorScreen.AddNewPlace === screen) {
    return getMarkerFromAddPlaceForm(state);
  }

  const intProfileId = parseInt(params.profileId, 10);
  if (FamilyLocatorScreen.MaximizedMap === screen) {
    return getEventMarker(state, intProfileId, params.eventKey!);
  }

  if (FamilyLocatorScreen.EditPlace === screen) {
    return getPlaceFormMarker(state, params.placeUid);
  }

  if (FamilyLocatorScreen.AddPlace === screen) {
    return getFormMarker(state, intProfileId, params.eventKey!);
  }

  const markers =
    FamilyLocatorScreen.KidTimeline === screen
      ? getProfileMarker(state, intProfileId)
      : getValidMarkers(state, intProfileId);

  return mapMarkerSafetyStatus(state, markers);
};

export const getMarkersCoordinatesString = markers =>
  markers
    .map(
      marker =>
        `${marker.latitude}-${marker.longitude}-${marker.safetyStatus}-${marker.accuracy}`
    )
    .join(',');

export const trackIfDataWasFetched =
  (shouldFetchMoreEvents: any) => dispatch => {
    const result = dispatch(shouldFetchMoreEvents);
    if (result !== undefined) {
      trackOlderLocationsLoaded();
    }
  };

export const navigateToPlaces = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: getPlacesLink(),
    })
  );
};

export const getPlacesLink = () => 'familyLocator/places';

export const navigateToResolveAddress = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(
    navigate({
      type: 'inner',
      src: 'familyLocator/places/resolve-address',
    })
  );
};

export const navigateToAddPlace =
  (placesSize: number, locationPathname: string) => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const license = getLicense(getState());

    if (shouldBlockThisFeature('savedPlacesAlerts', license.subtype)) {
      return dispatch(showUnlockCompleteModal());
    }

    if (placesSize >= LIMIT_OF_PLACES_CREATED_BY_THE_USER) {
      return dispatch(
        navigate({
          type: 'inner',
          src: `${locationPathname}/modal/PlacesLimitReachedModal`,
        })
      );
    }

    const location = locationPathname.replace(
      `/action/${RouteActions.fullMap}`,
      ''
    );
    return dispatch(
      navigate({
        type: 'inner',
        src: `${location}/action/${RouteActions.addPlace}`,
      })
    );
  };

export const navigateToEditPlace = (placeUid: string) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(
    navigate({
      type: 'inner',
      src: `/familyLocator/places/place/${placeUid}/action/${RouteActions.editPlace}`,
    })
  );
};

export function createPlace(isUsingImperialSystem: boolean, form, stepsBack) {
  form.radius = transformToAppropriateUnit(isUsingImperialSystem, form.radius);
  return dispatch => {
    dispatch(navigateBack(-stepsBack));
    dispatch(regenerateToken());
    return dispatch(
      addPlace(PlaceRecord.serialize(PlaceRecord.fromPayload(form)))
    )
      .then(() => dispatch(fetchPlaces()))
      .then(() => dispatch(showToast(t('Place added'), TOAST_ICON_TICK)))
      .then(() => dispatch(resetPlaceForm()))
      .catch((error: AnyError) => handleApiErrorMessage(error));
  };
}

export function updatePlace(
  placeUid: string,
  isUsingImperialSystem: boolean,
  form,
  stepsBack: number
) {
  form.radius = transformToAppropriateUnit(isUsingImperialSystem, form.radius);
  return dispatch => {
    dispatch(navigateBack(-stepsBack));
    dispatch(regenerateToken());
    return dispatch(updatePlaceDuck(placeUid, PlaceRecord.fromPayload(form)))
      .then(() => dispatch(showToast(t('Place updated'), TOAST_ICON_TICK)))
      .then(() => dispatch(resetAllPlaceForms()))
      .catch((error: AnyError) => handleApiErrorMessage(error));
  };
}

export const transformToAppropriateUnit = (
  isUsingImperialSystem: boolean,
  value: number
) =>
  Math.round((isUsingImperialSystem ? feetToMeters(value) : value) * 100) / 100;

export const navigateToDirections = (marker: Marker) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'map:direction',
      src: marker,
    })
  );
};

export const getViewFromScreen = (screen: FamilyLocatorScreen) => {
  if (screen === FamilyLocatorScreen.KidTimeline) {
    return FamilyLocatorViews.KidLocations;
  }

  return FamilyLocatorViews.FamilyLocator;
};

export const resetPlaceForm = () => dispatch => {
  dispatch(change(addPlaceForm, 'address', ''));
  dispatch(change(addPlaceForm, 'name', ''));
  dispatch(change(addPlaceForm, 'latitude', 0));
  dispatch(change(addPlaceForm, 'longitude', 0));
  dispatch(change(addPlaceForm, 'radius', 0));
};

export const updateAddPlaceFormAddress = (address: Address) => dispatch => {
  dispatch(change(addPlaceForm, 'address', address.formatted));
  dispatch(change(addPlaceForm, 'latitude', address.coordinates.latitude));
  dispatch(change(addPlaceForm, 'longitude', address.coordinates.longitude));
};

export const resetSearchAddressForm = () => dispatch => {
  dispatch(change(searchAddressForm, 'name', ''));
};

export const resetSearchAddressResults = () => dispatch => {
  dispatch(updateSearchResults([]));
  dispatch(updateSearchString(''));
};

export const addressClickHandler = eventKey => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return eventKey === NO_EVENT_KEY
    ? dispatch(
        navigate({
          type: 'inner',
          src: '/familyLocator/places/resolve-address',
        })
      )
    : null;
};

export const onSearchTextChangeHandler =
  dispatch => (searchString: string, sessionToken: string) => {
    dispatch(updateFetchingAddresses(true));
    dispatch(updateSearchString(searchString));
    resolveAddressFromString(searchString, sessionToken).then(results => {
      dispatch(updateSearchResults(results));
      dispatch(updateFetchingAddresses(false));
    });
  };

export const onSearchResultsItemClick =
  (placeId: string, sessionToken: string) => dispatch => {
    return dispatch(() => getAddressFromPlaceId(placeId, sessionToken)).then(
      (address: Address) => {
        dispatch(regenerateToken());
        return dispatch(handleSearchResultsItemClick(address));
      }
    );
  };

export const handleSearchResultsItemClick = (address: Address) => dispatch => {
  dispatch(updateAddPlaceFormAddress(address));
  dispatch(updateSearchString(''));
  dispatch(resetSearchAddressForm());
  dispatch(resetSearchAddressResults());
  dispatch(goBackIfHistory());
};

export const regenerateToken = () => updateToken(getMilisecondsTimestamp());

export const resetAllPlaceForms = () => dispatch => {
  dispatch(regenerateToken());
  dispatch(resetSearchAddressForm());
  dispatch(resetSearchAddressResults());
  return dispatch(resetPlaceForm());
};

export const seeLocationsClickHandler =
  (profileId: number) => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();

    return dispatch(
      navigate({
        type: 'inner',
        src: isFree(getLicense(getState()).type)
          ? `/profiles/${profileId}/rules/locationRules/premiumFlyover`
          : `/familyLocator/${profileId}/locations`,
      })
    );
  };
