import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import type { PageTitleLayoutCommonProps } from './PageTitleLayout';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import DesktopPageLayout from './desktop/DesktopPageLayout';
import MobilePageLayout from './mobile/MobilePageLayout';
import connectToLayout from './connectToLayout';
import TabletPageLayout from './tablet/TabletPageLayout';
import {
  filterAndMergeConfigurations,
  filterPageSections,
  findPageSection,
} from './helpers/mergeConfiguration';
import { BaseConfigurationProps } from './types';
import { DesktopPageLayoutConfigurationProps } from './desktop/types';
import { MobilePageLayoutConfigurationProps } from './mobile/types';
import { TabletPageLayoutConfigurationProps } from './tablet/types';

export type PageLayoutConfigurationProps = (
  | DesktopPageLayoutConfigurationProps
  | MobilePageLayoutConfigurationProps
  | TabletPageLayoutConfigurationProps
) &
  BaseConfigurationProps;

export interface PageLayoutProps {
  children?: React.ReactNode;
  layoutSetProfileEditable: (isEditable: boolean) => void;
  isProfileEditable?: boolean;
  configuration?: PageLayoutConfigurationProps[];
  className?: string;
}

const PageLayout: React.FunctionComponent<
  PageTitleLayoutCommonProps & PageLayoutProps
> = ({
  children,
  layoutSetProfileEditable,
  isProfileEditable = true,
  title,
  actions,
  subtitle,
  configuration,
  className,
}: PageTitleLayoutCommonProps & PageLayoutProps) => {
  React.useEffect(() => {
    layoutSetProfileEditable(!!isProfileEditable);
  }, [isProfileEditable]);

  const arrChildren = React.Children.toArray(children);
  const mainSide = filterPageSections(
    arrChildren,
    pageSection =>
      !pageSection.props.placeholder &&
      (!pageSection.props.side || pageSection.props.side === 'main')
  );
  const rightSide = findPageSection(
    arrChildren,
    pageSection => pageSection.props.side === 'right'
  );
  const beforePullRefresh = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'before-pullrefresh'
  );
  const afterPullRefresh = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'after-pullrefresh'
  );
  const topbar = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'topbar'
  );
  const cardBanner = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'card-banner'
  );
  const customPageTitle = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'page-title'
  );
  const beforeCardChildren = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'before-card'
  );
  const afterCardChildren = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'after-card'
  );
  const beforePageTitle = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'before-page-title'
  );
  const afterPageTitle = findPageSection(
    arrChildren,
    pageSection => pageSection.props.placeholder === 'after-page-title'
  );

  return (
    <FlexLayout
      mainaxis="column"
      height="100%"
      className={className}
      testId="PageLayoutWrapper"
    >
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <DesktopPageLayout
          mainChildren={mainSide}
          rightChildren={rightSide}
          beforePullRefreshChildren={beforePullRefresh}
          afterPullRefreshChildren={afterPullRefresh}
          topbarChildren={topbar?.props.children}
          cardBannerChildren={cardBanner?.props.children}
          beforeCardChildren={beforeCardChildren}
          afterCardChildren={afterCardChildren}
          configuration={filterAndMergeConfigurations(
            configuration,
            ScreenSize.Desktop
          )}
          title={title}
          beforePageTitle={beforePageTitle}
          afterPageTitle={afterPageTitle}
          actions={actions}
          subtitle={subtitle}
          customPageTitle={customPageTitle?.props.children}
        />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Tablet}>
        <TabletPageLayout
          title={title}
          actions={actions}
          mainChildren={mainSide}
          rightChildren={rightSide}
          topbarChildren={topbar?.props.children}
          cardBannerChildren={cardBanner?.props.children}
          beforePullRefreshChildren={beforePullRefresh}
          afterPullRefreshChildren={afterPullRefresh}
          customPageTitle={customPageTitle?.props.children}
          beforeCardChildren={beforeCardChildren}
          afterCardChildren={afterCardChildren}
          beforePageTitle={beforePageTitle}
          afterPageTitle={afterPageTitle}
          configuration={filterAndMergeConfigurations(
            configuration,
            ScreenSize.Tablet
          )}
        />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <MobilePageLayout
          title={title}
          actions={actions}
          mainChildren={mainSide}
          topbarChildren={topbar?.props.children}
          beforePullRefreshChildren={beforePullRefresh}
          afterPullRefreshChildren={afterPullRefresh}
          cardBannerChildren={cardBanner?.props.children}
          customPageTitle={customPageTitle?.props.children}
          beforeCardChildren={beforeCardChildren}
          afterCardChildren={afterCardChildren}
          beforePageTitle={beforePageTitle}
          afterPageTitle={afterPageTitle}
          configuration={filterAndMergeConfigurations(
            configuration,
            ScreenSize.Mobile
          )}
        />
      </RenderWhen>
    </FlexLayout>
  );
};

const ConnectedPageLayout = connectToLayout(PageLayout);

export default ConnectedPageLayout;
