import { connect } from 'react-redux';
import { getLicense } from '../../selectors';
import RenewalModal from '../../components/Modal/RenewalModal';
import { clickModalButton } from '../../actions/UpgradeActions';
import { goBackIfHistory } from '../../ducks/routing';

const mapStateToProps = state => ({
  license: getLicense(state),
});

const mapDispatchToProps = dispatch => ({
  onClickLink: () => dispatch(clickModalButton()),
  onClickClose: () => dispatch(goBackIfHistory()),
});

const RenewalModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewalModal);

export default RenewalModalContainer;
