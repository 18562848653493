import { connect } from 'react-redux';
import { close } from '../../ducks/notifications';
import PlacesLimitReachedModal from '../../components/Modal/PlacesLimitReachedModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => {
    return dispatch(close());
  },
});

const PlacesLimitReachedModalContainer = connect(
  null,
  mapDispatchToProps
)(PlacesLimitReachedModal);

export default PlacesLimitReachedModalContainer;
