import { t } from '../i18n';
import PlaceRecord, { Place } from '../../records/place';
import { formRecord } from '../../components/FamilyLocator/PlaceForm';
import { metersToFeet } from '../../helpers/conversions';

const MIN_RADIUS_VALUE_METERS = 100;
const MIN_RADIUS_VALUE_FEET = 350;
const MAX_RADIUS_VALUE_METERS = 200;
const MAX_RADIUS_VALUE_FEET = 650;

const convertToRoundedFeet = (radius: number) => {
  const feetRadius = Math.round(metersToFeet(radius));
  if (feetRadius < MIN_RADIUS_VALUE_FEET) {
    return MIN_RADIUS_VALUE_FEET;
  }

  if (feetRadius > MAX_RADIUS_VALUE_FEET) {
    return MAX_RADIUS_VALUE_FEET;
  }

  return feetRadius;
};

const getInitialFormValues = (place: Place, isUsingFeet: boolean): Place => {
  const editedPlace = { ...place };
  editedPlace.radius = isUsingFeet
    ? convertToRoundedFeet(place.radius)
    : Math.round(place.radius);

  return editedPlace;
};

export const getFormData = (place: PlaceRecord, isUsingFeet: boolean) => ({
  minValue: isUsingFeet ? MIN_RADIUS_VALUE_FEET : MIN_RADIUS_VALUE_METERS,
  maxValue: isUsingFeet ? MAX_RADIUS_VALUE_FEET : MAX_RADIUS_VALUE_METERS,
  formatLabel: isUsingFeet ? t('f') : t('m'),
  initialValues: formRecord(getInitialFormValues(place.toJS(), isUsingFeet)),
});
