import { List, Record } from 'immutable';
import * as camelize from 'camelize';
import * as R from 'ramda';
import {
  ACTION_ALERT,
  ACTION_ALLOW,
  ACTION_BLOCK,
  ACTION_IGNORE,
  DOMAIN_YOUTUBE,
  EventType,
  Platform,
  PLATFORM_ANDROID,
  PLATFORM_CHROME,
  PLATFORM_IOS,
  REASON_CATEGORY_ALERT,
  REASON_CATEGORY_BLOCK,
  REASON_DOMAIN_LIST_ALERT,
  REASON_DOMAIN_LIST_ALLOW,
  REASON_DOMAIN_LIST_BLOCK,
  REASON_DOMAIN_LIST_IGNORE,
  REASON_NOT_ALLOW_UNKNOWN_SITES,
  REASON_TIME_RESTRICTIONS_QUOTA,
  REASON_TIME_RESTRICTIONS_RANGE,
  SearchEngine,
} from '../constants';
import { LocationRecord } from './location';
import { isDesktopPlatform, mapPlatform } from './device';
import { CategoryRecord, hiddenCategories } from './category';
import { capitalize } from '../helpers/string';
import flags from '../sideEffects/flags';
import { SafeNetworkInfoOperations } from './event/safeNetworkInfo';
import { SafeNetworkInfoRecord } from './event/types/SafeNetworkInfo.types';

type ActionBlock = {
  action: typeof ACTION_BLOCK;
  reason:
    | typeof REASON_DOMAIN_LIST_BLOCK
    | typeof REASON_CATEGORY_BLOCK
    | typeof REASON_NOT_ALLOW_UNKNOWN_SITES
    | typeof REASON_TIME_RESTRICTIONS_QUOTA
    | typeof REASON_TIME_RESTRICTIONS_RANGE
    | null;
};
type ActionAlert = {
  action: typeof ACTION_ALERT;
  reason: typeof REASON_DOMAIN_LIST_ALERT | typeof REASON_CATEGORY_ALERT;
};
type ActionAllow = {
  action: typeof ACTION_ALLOW;
  reason: typeof REASON_DOMAIN_LIST_ALLOW | null;
};
type ActionIgnore = {
  action: typeof ACTION_IGNORE;
  reason: typeof REASON_DOMAIN_LIST_IGNORE | null;
};

export type Action = ActionBlock | ActionAlert | ActionAllow | ActionIgnore;

export type EventRecord = {
  key: string;
  label: string;
  time: ISODateString | '';
  badge: string;
  type: number;
  action: Action;
  device: string;
  platform: Platform;
  appName: string;
  minutes: number;
  name: string;
  accountId: number;
  profileId: number;
  deviceId: number;
  socialContactId: string;
  text: string;
  socialContactName: string;
  socialObjectPic: string;
  pictureBig: string;
  blockedPages: number;
  pages: number;
  location: any;
  panicMode: boolean;
  thumbnail: string;
  host: string;
  url: string;
  reason: number;
  categories: List<CategoryRecord>;
  searchEngineName: string;
  searchEngine: SearchEngine;
  network?: SafeNetworkInfoRecord;
  searchCategories?: List<string>;
  searchInappropriate?: boolean;
};
export interface SafeNetworkEvent extends EventRecord {
  type:
    | EventType.SafeNetworkEnablingProtection
    | EventType.SafeNetworkDisablingProtection;
  network: SafeNetworkInfoRecord;
}

export interface SafeNetworkEventEnablingProtection extends SafeNetworkEvent {
  type: EventType.SafeNetworkEnablingProtection;
}

export interface SafeNetworkEventDisablingProtection extends SafeNetworkEvent {
  type: EventType.SafeNetworkDisablingProtection;
}

export const EventRecord = Record<
  EventRecord,
  {
    fromPayload: (response: any) => EventRecord;
    fromLocation: (response: any) => EventRecord;
  }
>({
  key: '',
  label: '',
  time: '',
  badge: '',
  type: EventType.Unknown,
  action: { action: ACTION_IGNORE, reason: null },
  device: '',
  platform: mapPlatform(undefined),
  appName: '',
  minutes: 0,
  name: '',
  accountId: 0,
  profileId: 0,
  deviceId: 0,
  socialContactId: '',
  text: '',
  socialContactName: '',
  socialObjectPic: '',
  pictureBig: '',
  blockedPages: -1,
  pages: -1,
  location: LocationRecord(),
  panicMode: false,
  thumbnail: '',
  host: '',
  url: '',
  reason: 0,
  categories: List(),
  searchEngineName: '',
  searchEngine: SearchEngine.Unknown,
  network: SafeNetworkInfoOperations.create(),
  searchCategories: undefined,
  searchInappropriate: undefined,
});

// App and Web has new prop platform in devices, pending impl in others. Fallback
const getPlatform = payload =>
  mapPlatform(
    payload.device && payload.device.platform !== undefined
      ? payload.device.platform
      : payload.platform
  );

const getEventAction = payload => {
  if (isCallSms(payload.subtype)) {
    return { action: ACTION_BLOCK, reason: null };
  }
  // TODO remove this after BCK-8888 is merged
  // we check if the categories includes 40, as this is the category used for safety net
  if (
    payload.categories &&
    payload.categories.includes(hiddenCategories.safetyNet)
  ) {
    // here we need to enforce the reason, as we may be receiving a different one from
    // other rules, and the school block is applied only to a category
    return { action: ACTION_BLOCK, reason: REASON_CATEGORY_BLOCK };
  }
  return makeAction(payload);
};

EventRecord.fromPayload = R.pipe<any, any, EventRecord>(camelize, payload => {
  const action = getEventAction(payload);
  const location = LocationRecord.fromEventResponse(payload);
  const platform = getPlatform(payload);

  return EventRecord({
    ...payload,
    platform,
    thumbnail: payload.thumbnail || '',
    location,
    action,
    type: convertSubtypeToType(payload, platform),
    time: `${payload.dt}`,
    timestamp: Date.parse(payload.dt) / 1000,
    panicMode: payload.panicModeEnabled || payload.enabled,
    categories: payload.categories
      ? List(
          payload.categories.map(category => CategoryRecord({ id: category }))
        )
      : List(),
    searchEngine: payload.searchEngine
      ? SearchEngine[capitalize(payload.searchEngine)] || SearchEngine.Unknown
      : undefined,
    searchEngineName: payload.searchEngine,
    network:
      payload.network && SafeNetworkInfoOperations.fromPayload(payload.network),
    searchCategories: payload?.searchInappropriate
      ? List(payload?.searchCategories)
      : undefined,
    searchInappropriate: payload?.searchInappropriate
      ? payload?.searchInappropriate
      : undefined,
  });
});

EventRecord.fromLocation = location =>
  EventRecord(<any>{
    type: EventType.LocationLast,
    time: location.time,
    deviceId: location.deviceId,
    location,
  });

export const isActionBlock = (action: Action): boolean =>
  action.action === ACTION_BLOCK;
export const isActionAlert = (action: Action): boolean =>
  action.action === ACTION_ALERT;
export const isActionAllow = (action: Action): boolean =>
  action.action === ACTION_ALLOW;

export const makeAction = ({ reason }: { reason?: number }): Action => {
  switch (reason) {
    case REASON_CATEGORY_BLOCK:
    case REASON_DOMAIN_LIST_BLOCK:
    case REASON_NOT_ALLOW_UNKNOWN_SITES:
    case REASON_TIME_RESTRICTIONS_QUOTA:
    case REASON_TIME_RESTRICTIONS_RANGE:
      return { action: ACTION_BLOCK, reason };
    case REASON_CATEGORY_ALERT:
    case REASON_DOMAIN_LIST_ALERT:
      return { action: ACTION_ALERT, reason };
    case REASON_DOMAIN_LIST_ALLOW:
      return { action: ACTION_ALLOW, reason };
    default:
      return { action: ACTION_IGNORE, reason: null };
  }
};

const isCallSms: (subtype: number | undefined) => boolean = subtype =>
  subtype === undefined
    ? false
    : R.contains(subtype)([
        EventType.CallsBlockedIncoming,
        EventType.CallsBlockedOutgoing,
        EventType.SmsBlockedIncoming,
        EventType.SmsBlockedOutgoing,
      ]);

// TODO: review - We are making a conversion from BCK types and subtypes into
// unique ux type.
// In some points of our code we have multiple switch-cases because we lost the
// BCK type. Eg:
// case EventType.FacebookSocial:
// case EventType.FacebookSocialContact:
// case EventType.FacebookSocialNetwork:
// case EventType.FacebookSocialContactNew:
// case EventType.FacebookSocialActivity:
// case EventType.FacebookSocialActivityPost:
// (...)
// With the original BCK type mapped as a new prop "group" in the record we could do something like:
// switch(event.group)
// case GroupType.Facebook
const convertSubtypeToType = (payload, platform) => {
  if (isYoutubeType(payload, platform)) return EventType.Youtube;
  const safeSubEvent = EventType[payload.subtype];
  return safeSubEvent ? payload.subtype : payload.type;
};

const isYoutubeType = (payload, platform) => {
  return (
    payload.host !== undefined &&
    ((platform === PLATFORM_ANDROID &&
      flags.youtubeAndroidPlatformReporting.isEnabled()) ||
      (platform === PLATFORM_IOS &&
        flags.youtubeIOSPlatformReporting.isEnabled()) ||
      isDesktopPlatform(platform) ||
      platform === PLATFORM_CHROME) &&
    payload.host.toLowerCase().indexOf(DOMAIN_YOUTUBE) !== -1
  );
};

export const isSafeNetwork = (event?: EventRecord): event is SafeNetworkEvent =>
  isSafeNetworkEnablingProtection(event) ||
  isSafeNetworkDisablingProtection(event);

export const isSafeNetworkEnablingProtection = (
  event?: EventRecord
): event is SafeNetworkEventEnablingProtection =>
  event?.type === EventType.SafeNetworkEnablingProtection;

export const isSafeNetworkDisablingProtection = (
  event?: EventRecord
): event is SafeNetworkEventDisablingProtection =>
  event?.type === EventType.SafeNetworkDisablingProtection;

export default EventRecord;
