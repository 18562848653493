import { Set } from 'immutable';
import { isNil, isEmpty } from 'ramda';
import { EventType } from '../../constants';
import { EventRecord } from '../../records';
import { AppRuleRecord, makeAppKey } from '../../records/profileRules';

export const FETCH_EVENTS_LIMIT = 15;

export const hasMoreEvents = <Events, Filter>(
  events: Array<Events>,
  filter: Array<Filter>,
  pageId?: string
) =>
  isNil(pageId) || // Schools uses pageId as string | undefined
  isEmpty(pageId) || // timeline uses pageId as empty or string with values
  (filter && events.length >= FETCH_EVENTS_LIMIT);

/**
 * Gets page identifier to do paginated request.
 *
 * IMPORTANT: the server uses last id/uuid as page identifier.
 *
 */
export const getLinewizeEventsPageId = (events: Set<string>) => events.last();

const isRepeatedPageIdRequested = (prevPageId, pageId) => {
  if (pageId === undefined || prevPageId === undefined) return false;
  return prevPageId === pageId;
};

/**
 *
 * returns true if there have more events when using cache otherwise false.
 *
 * When make requests to determine if have more events it is necessary to make an additional
 * request with the same last pageId,
 * this strategy generates conflicts when using cache because for the last pageId there will always be elements.
 *
 *
 * IMPORTANT: if it don't use cache use the method hasMoreEvents
 *
 * IMPORTANT: prevPageId as undefined or prevPageId as undefined means it's the first time it's called
 *
 */
export const hasMoreWhenUseCache = <Events, Filter>(params: {
  events: Array<Events>;
  filter: Array<Filter>;
  pageId?: string;
  prevPageId?: string;
}) => {
  const { events, filter, pageId, prevPageId } = params;
  return (
    hasMoreEvents<Events, Filter>(events, filter, pageId) &&
    !isRepeatedPageIdRequested(prevPageId, pageId)
  );
};

/**
 *
 * An event if blockable when:
 *
 * 1. Is Only type APP.
 * 2. The associated app is blockable.
 *
 * IMPORTANT: to get associated app use selector getAppRuleApplicationByEvent
 *
 */
export const isBlockableEvent = (event: EventRecord, app: AppRuleRecord) => {
  if (event.type !== EventType.App) return true;
  return app?.blockable;
};

/**
 *
 * To identify apps associated to event use correlation
 *
 * 1. app.exe = event.appName
 * 2. app.platform = event.platform
 *
 */
export const getAppKeyFromEvent = (event: EventRecord) => {
  return makeAppKey({
    exe: event.appName,
    platform: event.platform,
  } as AppRuleRecord);
};
