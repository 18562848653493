import {
  getLicense,
  getProfileDesktopDelegatedDevices,
  getProfileDevices,
} from '../../selectors';
import { isSchoolAccount, isSchoolReferral } from '../account';

const validLicensesForSchoolRules: Array<LicenseSubtype> = [
  'school_free',
  'trial',
  'premium_basic',
  'premium_complete',
];

/**
 * Is the profile a school license, with Qustodio devices or delegated to this account and has a device type of PC delegated
 * this is used to grant a bypass and allow the profile to access to certain rules.
 */
export const isSchoolLicenseWithValidDevices = (state, profileId): boolean => {
  const profileDelegatedDesktopDevices = getProfileDesktopDelegatedDevices(
    state,
    profileId
  );
  const license = getLicense(state);
  const profileDevices = getProfileDevices(state, profileId);
  const linkedToSchool = isSchoolAccount(state);
  const schoolReferral = isSchoolReferral(state);
  if (
    (linkedToSchool || schoolReferral) &&
    validLicensesForSchoolRules.includes(license.subtype) &&
    (!profileDelegatedDesktopDevices.isEmpty() || !profileDevices.isEmpty())
  ) {
    return true;
  }

  return false;
};
