import * as Immutable from 'immutable';
import { PolicyAction } from '../../../../records/routines/policy/types/Policy.types';
import { PolicyApplication } from '../../../../records/routines/policy/types/PolicyApplication.types';
import { AppRuleRecord, makeAppKey } from '../../../../records/profileRules';
import { replaceS3Url } from '../../../../helpers';
import { toInverseAction } from '../../helpers/steps';

export const transformAppExceptionStepState = (
  apps: Immutable.List<AppRuleRecord>,
  selectedAppskeys: (string | undefined)[],
  action: PolicyAction
): Immutable.List<PolicyApplication> => {
  return apps
    .filter(app => selectedAppskeys.includes(makeAppKey(app)))
    .map(app => ({
      name: app.name,
      exe: app.exe,
      platform: app.platform,
      action: toInverseAction(action),
      src: replaceS3Url(app.thumbnail), // @todo rename to thumbnail
    }));
};
