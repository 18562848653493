import { fromJS, List, NonEmptyMapRecord } from 'immutable';
import { normalize, Schema } from 'normalizr';
import * as camelize from 'camelize';
import * as R from 'ramda';
import { tapReject } from '../helpers';
import api from '../api';
import { getEventByKey } from '../selectors';
import { UrlDetailsRecord, DetailsItemRecord } from '../records/urlDetails';
import {
  EventType,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  VideoSources,
} from '../constants';
import flags from '../sideEffects/flags';
import { isMobilePlatform } from '../records/device';
import { isSafeNetwork } from '../records/event';

const RECEIVE_URL_DETAILS = 'RECEIVE_URL_DETAILS';
const REQUEST_URL_DETAILS_ERROR = 'REQUEST_URL_DETAILS_ERROR';
const SHOW_DETAILS_ACTIONS = 'SHOW_DETAILS_ACTIONS';
const FETCHING_URL_DETAILS = 'FETCHING_URL_DETAILS';

export default function urlDetails(
  state: NonEmptyMapRecord<{
    isFetching: boolean;
    result: List<string>;
    lastUpdated: Date;
    showDetailsAction?: DetailsItemRecord | undefined;
  }> = fromJS({
    result: List(),
  }),
  action: {
    isFetching: false;
    receivedAt: Date;
    type:
      | typeof RECEIVE_URL_DETAILS
      | typeof REQUEST_URL_DETAILS_ERROR
      | typeof FETCHING_URL_DETAILS;
    payload: any;
  }
) {
  switch (action.type) {
    case RECEIVE_URL_DETAILS:
      return state.merge({
        isFetching: false,
        result: action.payload.result,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_URL_DETAILS_ERROR:
      return state.merge({
        isFetching: false,
      });
    case FETCHING_URL_DETAILS:
      return state.merge({
        isFetching: true,
      });
    case SHOW_DETAILS_ACTIONS:
      return state.merge({
        showDetailsAction: action.payload,
      });
    default:
      return state;
  }
}

const schema = new Schema('urlDetails', {
  idAttribute: 'key',
});

export function showDetailsActions(item) {
  return {
    type: SHOW_DETAILS_ACTIONS,
    payload: item,
  };
}

export const normalizeUrlDetails = json => {
  const {
    result,
    entities: { urlDetails },
  } = normalize(camelize(json), schema);
  return {
    result: List([result]),
    records: {
      urlDetails: R.map(UrlDetailsRecord.fromPayload, urlDetails || []),
    },
  };
};

const fetchingUrlDetails = () => ({
  type: FETCHING_URL_DETAILS,
});

export const receiveUrlDetails = response => {
  const { result, records } = normalizeUrlDetails(response);

  return {
    type: RECEIVE_URL_DETAILS,
    payload:
      response.key == null
        ? {}
        : {
            result,
            records,
          },
    receivedAt: Date.now(),
  };
};

const extendWithFeatureCheck = (ev, params, provider?: VideoSources) => {
  if (ev.type !== EventType.Youtube) return params;

  if (
    isMobilePlatform(ev.platform) &&
    ((ev.platform === PLATFORM_ANDROID &&
      !flags.youtubeAndroidPlatformReporting.isEnabled()) ||
      (ev.platform === PLATFORM_IOS &&
        !flags.youtubeIOSPlatformReporting.isEnabled()))
  ) {
    return params;
  }

  return { ...params, beta: true, search_engine: provider || '' };
};

export const fetchUrlDetails =
  (profileId: number, eventKey: string) => (dispatch, getState) => {
    const event = getEventByKey(getState(), eventKey);
    if (event == null) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`Event with key ${eventKey} not present in state`);
    }
    if (isSafeNetwork(event)) {
      return Promise.resolve();
    }

    dispatch(fetchingUrlDetails());
    return api.urlDetails
      .get(
        { profileId },
        extendWithFeatureCheck(
          event,
          {
            key: event.key,
            limit: event.pages,
          },
          VideoSources.Youtube
        )
      )
      .then(response =>
        dispatch(receiveUrlDetails({ key: event.key, ...response }))
      )
      .catch(tapReject(error => dispatch(requestUrlDetailsError(error))));
  };

const requestUrlDetailsError = error => ({
  type: REQUEST_URL_DETAILS_ERROR,
  payload: { error },
});
