import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { t } from '../../../lib/i18n';
import { setItem } from '../../../ducks/persistentStorage';
import PanicButtonRules from '../../../components/PanicButtonRules/PanicButtonRules';
import {
  getProfileAndroidDevices,
  getProfileOrDefault,
  getPanicRulesAccountHolder,
  getPanicRulesEnabled,
  getPersistentStorageItem,
  getPanicRulesContacts,
  getContacts,
  getPanicRulesSelectedContact,
} from '../../../selectors';
import { delayedUpdateProfileRules } from '../../../ducks/profileRules';
import {
  fetchContactsIfNeeded,
  setSelectedContact,
} from '../../../ducks/contacts';
import * as qinit from '../../../qinit';
import { gaEvent } from '../../../helpers/ga';
import {
  trackPanicButtonRule,
  PanicButtonRuleNames,
} from '../../../helpers/analytics';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import {
  panicContactActionSheetClick,
  checkMaxContacts,
} from '../../../actions/ActionSheetActions';
import State, {
  BaseThunk,
  Dispatch,
  RouterParamsProps,
} from '../../../store/state';
import { ProfileRulesRecord } from '../../../records';
import { ProfileIdField } from '../../../records/profile';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { redirectToAddDeviceFlyover } from '../../../actions/redirectAddDeviceFlyover';
import { redirectToStartTrialFlyover } from '../../../actions/redirectStartTrialFlyover';

const mapStateToProps = (state: State, { params: { profileId } }) => ({
  profileId,
  accountHolder: getPanicRulesAccountHolder(state, profileId) || Map(),
  profileName: getProfileOrDefault(state, profileId).name,
  panicButtonEnabled: !!getPanicRulesEnabled(state, profileId),
  contacts: getContacts(state),
  profileContacts: getPanicRulesContacts(state, profileId) || List(),
  selectedContactToEdit: getPanicRulesSelectedContact(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps<ProfileIdField>
) => ({
  onTogglePanicButtonRulesEnabled: (toggleOn: boolean) => {
    dispatch(
      delayedUpdateProfileRules(profileId, (profileRules: ProfileRulesRecord) =>
        profileRules.setIn(['panic', 'enabled'], toggleOn)
      )
    );
    gaEvent('panic-button', toggleOn ? 'on' : 'off', 'switch');
    trackPanicButtonRule(
      toggleOn
        ? PanicButtonRuleNames.ENABLE_PANIC_BUTTON
        : PanicButtonRuleNames.DISABLE_PANIC_BUTTON
    );
  },
  onClickInviteContact: () =>
    dispatch((dispatch, getState) =>
      checkMaxContacts(getState(), profileId, () => {
        const navigate = getMultiPlatformNavigation();
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/panicButtonRules/inviteContact`,
          })
        );
        gaEvent('panic-button', 'click', 'invite-contact');
      })
    ),
  onClickContactAction: action => () =>
    dispatch(panicContactActionSheetClick(action)),
  onClickContact: contact => () => dispatch(setSelectedContact(contact)),
  onClickMoreInfo: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'external:public:site',
        src: qinit.tenant.marketing.public_site
          .how_to_setup_qustodio_panic_button_mobile_devices,
        openInIOS: false,
      })
    );
    gaEvent('panic-button', 'click', 'more-info');
  },
  onClickAccountHolder: () =>
    navigator.notification.alert(
      t(
        "This trusted contact is the owner of the account and can't be edited or removed from the list."
      ),
      () => undefined,
      '',
      t('I got it')
    ),
});

const PanicButtonRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanicButtonRules as any);

PanicButtonRulesContainer.redirect =
  ({ profileId }): BaseThunk =>
  async (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();

    await dispatch(
      redirectToAddDeviceFlyover(
        profileId,
        `/profiles/${profileId}/rules/panicButtonRules/addDeviceFlyover`
      )
    ).then(redirectedToAddDevice => {
      if (redirectedToAddDevice) return;
      dispatch(
        redirectToStartTrialFlyover(
          `/profiles/${profileId}/rules/panicButtonRules/startTrial`
        )
      ).then(redirectedToTrial => {
        if (redirectedToTrial) return;

        dispatch(
          redirectFreeToPremiumAction(
            profileId,
            'rules/panicButtonRules/premiumFlyover'
          )
        ).then(wasRedirected => {
          if (!wasRedirected) {
            const hasAndroidDevices = !getProfileAndroidDevices(
              getState(),
              profileId
            ).isEmpty();
            if (!hasAndroidDevices) {
              dispatch(
                navigate({
                  type: 'inner:replace',
                  src: `/profiles/${profileId}/rules/panicButtonRules/connectDeviceFlyover`,
                })
              );
            } else if (
              !getPersistentStorageItem(getState(), 'panicRulesTourShown')
            ) {
              dispatch(setItem('panicRulesTourShown', 'true'));
              dispatch(
                navigate({
                  type: 'inner:replace',
                  src: `/profiles/${profileId}/rules/panicButtonRules/modal/PanicRulesTourModal`,
                })
              );
            }
          }
        });
      });
    });
  };

PanicButtonRulesContainer.load = () => fetchContactsIfNeeded();

export default PanicButtonRulesContainer;
