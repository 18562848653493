import { TopBarPredicatesProps } from '../types/Topbar.types';
import { hasSomePremium } from '../../../records/license/helpers';
import { LicenseRenewal, isNonExpiring } from '../../../records/license';

export const isVisiblePremiumExpiresTopbar = ({
  license,
  licenseDaysLeft,
}: TopBarPredicatesProps) => {
  const isAnyPremiumLicense = hasSomePremium(license.subtype);
  const expireSoon = licenseDaysLeft <= 30;
  const hasNoAutoRenewal = license.renewal === LicenseRenewal.None;
  const hasExpiryDate = !isNonExpiring(license);

  return isAnyPremiumLicense && expireSoon && hasNoAutoRenewal && hasExpiryDate;
};
