import { SummaryAlertsPlain } from '../../records/summaryAlerts/types/summaryAlerts.type';
import { SummaryAlertsTypes } from './types/SummaryAlertsState.type';

export const requestSummaryAlertsAction = () => ({
  type: 'REQUEST_SUMMARY_ALERTS' as const,
});

export const receiveSummaryAlertsAction = (
  summaryAlerts: SummaryAlertsPlain
) => ({
  type: 'RECEIVE_SUMMARY_ALERTS' as const,
  payload: summaryAlerts,
});

export const requestResetSummaryAlertsCounterAction = (
  profileUuid: string,
  type: SummaryAlertsTypes
) => ({
  type: 'REQUEST_RESET_SUMMARY_ALERTS_COUNTER' as const,
  payload: { profileUuid, type },
});

export const receiveResetSummaryAlertsCounterAction = (
  profileUuid: string,
  type: SummaryAlertsTypes
) => ({
  type: 'RECEIVE_RESET_SUMMARY_ALERTS_COUNTER' as const,
  payload: { profileUuid, type },
});

export const markAsViewedSummaryAlertsAction = (
  profileUuid: string,
  type: SummaryAlertsTypes
) => ({
  type: 'MARK_AS_VIEWED_SUMMARY_ALERTS' as const,
  payload: {
    profileUuid,
    type,
  },
});
