import { fromJS } from 'immutable';
// eslint-disable-next-line import/no-unresolved
import { PageTitleActionName } from 'styleguide-react/build/components/PageTitle/PageTitle';

export const SET_LAYOUT_ACTION_NAME = 'SET_LAYOUT_ACTION_NAME';

export const setLayoutActionName = (actionName: PageTitleActionName) => ({
  type: SET_LAYOUT_ACTION_NAME,
  payload: actionName,
});

export const SET_PROFILE_HEADER_EDITABLE = 'SET_PROFILE_HEADER_EDITABLE';

export const setProfileHeaderEditable = (isEditable: boolean) => ({
  type: SET_PROFILE_HEADER_EDITABLE,
  payload: isEditable,
});

export default function layout(
  state = fromJS({
    actionName: 'none',
    isProfileHeaderEditable: false,
  }),
  action
) {
  switch (action.type) {
    case SET_LAYOUT_ACTION_NAME:
      return state.merge({
        actionName: action.payload,
      });
    case SET_PROFILE_HEADER_EDITABLE:
      return state.merge({
        isProfileHeaderEditable: action.payload,
      });
    default:
      return state;
  }
}
