import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import ActivityTimelineDelegationInfoModal from '../../components/Modal/ActivityTimelineDelegationInfoModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
});

const ActivityTimelineDelegationInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(ActivityTimelineDelegationInfoModal);

export type ActivityTimelineDelegationInfoModalProps = ReturnType<
  typeof mapDispatchToProps
>;

export default ActivityTimelineDelegationInfoModalContainer;
