import {
  ProfileRulesRecord,
  AppRuleRecord,
  makeAppKey,
} from '../records/profileRules';

export const replaceAppId = (
  profileRules: ProfileRulesRecord,
  appRule: AppRuleRecord
) => {
  const appKey = makeAppKey(appRule);

  const appIndex = profileRules.appRules.applicationList.findIndex(
    appItem => makeAppKey(appItem) === appKey
  );

  return profileRules.setIn(
    ['appRules', 'applicationList', appIndex, 'id'],
    appRule.id
  );
};
