import * as React from 'react';
import { connect } from 'react-redux';
import AppRoutes from '../routes';
import { setRouteParams } from '../ducks/routing';
import SpinnerContainer from './SpinnerContainer';
import EnsureTranslations from './EnsureTranslations/EnsureTranslationsContainer';

const App: React.FunctionComponent<{
  onChange: () => any;
}> = ({ onChange }) => (
  <div className="App">
    <EnsureTranslations>
      <AppRoutes onChange={onChange} />
      <SpinnerContainer />
    </EnsureTranslations>
  </div>
);

const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line no-underscore-dangle
  onChange: (_prevState, nextState) => {
    dispatch(setRouteParams(nextState.params));
  },
});

export default connect(null, mapDispatchToProps)(App);
