import { connect } from 'react-redux';
import WellbeingTherapy from '../../components/Wellbeing/WellbeingTherapy';
import State, { Dispatch } from '../../store/state';
import {
  getProductAddonOffer,
  getProductAddonOfferStatus,
} from '../../ducks/productAddonsOffer';
import {
  formatProductAddonPrice,
  calculateKickoffSessionPrice,
  calculateWeeklyPrice,
} from '../../businessLogic/productAddonsOffer/productAddonsOffer';
import {
  ensureWellbeingFeatureNavigation,
  openWellbeingSubscriptionWebview,
} from '../WellbeingActivitiesContainer/thunks';
import { getFeatureStatus } from '../../ducks/features';
import {
  trackGetStartedFamilyTherapy,
  trackSubscribeToFamilyTherapy,
} from './tracking';

const mapStateToProps = (state: State) => {
  const product = getProductAddonOffer(state, 'wellbeing_therapy');
  const weeklyPrice = product ? calculateWeeklyPrice(product) : 0;
  const kickoffSessionPrice = product
    ? calculateKickoffSessionPrice(product)
    : 0;
  const hasDiscount = kickoffSessionPrice < weeklyPrice;
  const isLoading =
    getProductAddonOfferStatus(state) === 'LOADING' ||
    getFeatureStatus(state) === 'LOADING';

  return {
    kickoffSessionPrice: hasDiscount
      ? formatProductAddonPrice(kickoffSessionPrice)
      : undefined,
    weeklyPrice: formatProductAddonPrice(weeklyPrice),
    isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onLoad: () => {
      dispatch(
        ensureWellbeingFeatureNavigation({
          type: 'wellbeing_therapy',
          allowPurchase: status =>
            status === 'none' || status === 'activities_only',
        })
      );
    },
    onGetStarted: () => trackGetStartedFamilyTherapy(),
    onSubscribe: data => {
      trackSubscribeToFamilyTherapy(data);
      dispatch(openWellbeingSubscriptionWebview('wellbeing_therapy'));
    },
  };
};

const WellbeingTherapyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WellbeingTherapy);

export type WellbeingTherapyContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default WellbeingTherapyContainer;
