import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import api from '../../api';
import {
  errorSafeNetwork,
  loadedAccountSafeNetworks,
  loadingAccountSafeNetworks,
  receiveSafeNetworks,
} from './actions';
import { normalizeResponse } from './helpers';
import { getProfilesUidWithDeviceAssigned } from '../../selectors';
import { getProfileSafeNetworkSettings } from '../../selectors/safeNetworksSettings';
import { showToast, TOAST_ICON_WARNING } from '../toast';
import { t } from '../../lib/i18n';
import { captureException } from '../../helpers/sentry';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { SafeNetworksSettings } from '../../records/safeNetworksSettings/types/safeNetworkSetting';
import { BaseThunk } from '../../store/state';
import { ttl3Seconds } from '../../lib/QApiCache/commonCacheStrategies';

export const fetchSafeNetworksSettings =
  (profileUid: string): BaseThunk =>
  dispatch => {
    return api.safeNetworksSettings
      .withCache(ttl3Seconds)
      .get({
        profileUid,
      })
      .then(response => {
        const records = normalizeResponse(response, profileUid);
        return dispatch(receiveSafeNetworks(records));
      });
  };

export const patchSafeNetworkSettingWithPreviousState =
  (profileUid, body: Partial<SafeNetworksSettings>) =>
  async (dispatch, getState) => {
    const prevSafeNetworksSettingsState = getProfileSafeNetworkSettings(
      getState(),
      profileUid
    ).toJS();
    const nextSafeNetworksSettingsState = {
      ...prevSafeNetworksSettingsState,
      ...body,
    };
    dispatch(
      receiveSafeNetworks(
        normalizeResponse(nextSafeNetworksSettingsState, profileUid)
      )
    );
    try {
      return await dispatch(patchSafeNetworkSetting(profileUid, body));
    } catch (e) {
      dispatch(
        receiveSafeNetworks(
          normalizeResponse(prevSafeNetworksSettingsState, profileUid)
        )
      );
      captureException(e);
    }
  };

export const patchSafeNetworkSetting =
  (profileUid, body: Partial<SafeNetworksSettings>) => async dispatch => {
    const decamelizeBody = decamelizeKeysDeep(body);
    try {
      const response = await api.safeNetworksSettings.patch(
        { profileUid },
        decamelizeBody
      );
      const records = normalizeResponse(response, profileUid);
      return dispatch(receiveSafeNetworks(records));
    } catch (e) {
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
      throw e;
    }
  };

export const fetchAllProfileWithLinkedDevices = () => (dispatch, getState) => {
  dispatch(loadingAccountSafeNetworks());
  const profilesUids = getProfilesUidWithDeviceAssigned(getState());
  const promises = profilesUids
    .map(uid => dispatch(fetchSafeNetworksSettings(uid)))
    .toArray();
  return Promise.all(promises)
    .then(() => {
      dispatch(loadedAccountSafeNetworks());
    })
    .catch(e => {
      dispatch(errorSafeNetwork());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
      captureException(e);
    });
};

export const patchProfileSafeNetworkProtection =
  (profileUid: string, protectionStatus: boolean, withPreviousState = true) =>
  async dispatch => {
    const body = {
      disableProtectionInSafeNetwork: protectionStatus,
    };

    if (withPreviousState) {
      await dispatch(
        patchSafeNetworkSettingWithPreviousState(profileUid, body)
      );
    } else {
      await dispatch(patchSafeNetworkSetting(profileUid, body));
    }
    trackButtonClicked(
      GenericPageNames.Account,
      ButtonNames.SafeNetworkToggle,
      {
        options: JSON.stringify({
          enabled: protectionStatus,
          profile_uid: profileUid,
        }),
      }
    );
  };

export const dismissCampaign =
  (profileUid: string): BaseThunk =>
  async dispatch => {
    const body = {
      isCampaignDismissed: true,
    };
    await dispatch(patchSafeNetworkSettingWithPreviousState(profileUid, body));
  };
