import * as React from 'react';
import {
  Card,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  TransparentButton,
} from 'styleguide-react';
import { classNames } from '../../helpers';

export interface CardButtonProps {
  className?: string;
  onClick?: () => void;
}

/**
 * A card that's a button, a ">" and stack on mobile versions.
 *
 * ┌───────────┐
 * │content   >│
 * └───────────┘
 * ┌───────────┐
 * │content   >│
 * │           │
 * @constructor
 */
export const CardButton: React.FC<CardButtonProps> = ({
  className,
  onClick,
  children,
}) => (
  <Layout className={classNames(className, 'par-card-button')}>
    <TransparentButton
      onClick={onClick}
      fillAvailableSpace
      testId="button"
      className="par-card-button__button"
    >
      <Card fullWidth className="par-card-button__card">
        <FlexLayout
          mainaxis="row"
          className="container"
          mainaxisAlignment="space-between"
        >
          <FlexLayout mainaxis="row" crossaxisAlignment="center">
            {children}
          </FlexLayout>
          <Icon
            type={IconType.angleRight}
            size={IconSize.lg}
            color={IconColor.grayLight}
          />
        </FlexLayout>
      </Card>
    </TransparentButton>
  </Layout>
);

export interface CardButtonWithIconTextProps extends CardButtonProps {
  icon: JSX.Element;
  text: JSX.Element;
}

/**
 * A CardButton with a predefined layout for an icon and a text.
 *
 *  ┌──────────────┐
 *  │icon  text   >│
 *  └──────────────┘
 * @constructor
 */
export const CardButtonWithIconText: React.FC<CardButtonWithIconTextProps> = ({
  icon,
  text,
  ...cardButtonProps
}) => (
  <CardButton {...cardButtonProps} className="par-card-button--with-icon-text">
    {icon}
    <span className="card-text">{text}</span>
  </CardButton>
);
