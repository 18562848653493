import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  GlobalType,
  MarketingBanner,
  Tag,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import MarketingBannerImage from '../../assets/base/images/wellbeing/family-therapy-small.png';
import RenderWhen, { ScreenSize, isMobile } from '../RenderWhen/RenderWhen';

export interface WellbeingBannerProps {
  onCloseBanner: () => void;
  onClickButton: () => void;
  shouldDisplayWellbeingNewTag: boolean;
}

const WellbeingBanner = ({
  onCloseBanner,
  onClickButton,
  shouldDisplayWellbeingNewTag,
}: WellbeingBannerProps) => (
  <FlexLayout
    className="par-wellbeing-banner"
    mainaxis="row"
    mainaxisAlignment="center"
    alignSelf="center"
    testId="WellbeingBanner"
  >
    <RenderWhen screenSize={[ScreenSize.Mobile, ScreenSize.TabletOrDesktop]}>
      {screen => (
        <MarketingBanner
          backgroundType="gradient"
          backgroundColor="yolk"
          backgroundShapes
          showCloseIcon
          closeIconColor="yolk"
          title={t('Family wellbeing')}
          description={t(
            'Elevate your family’s wellbeing with one-to-one therapy sessions and weekly activities created by experts.'
          )}
          onClose={onCloseBanner}
          button={
            <Button
              label={t('Discover more')}
              size={ButtonSize.small}
              onClick={onClickButton}
            />
          }
          tag={
            shouldDisplayWellbeingNewTag ? (
              <Tag
                testId="wellbeing-banner-new"
                type={GlobalType.booster}
                text={t('New!')}
                variant="rounded"
                invertColor
              />
            ) : undefined
          }
          alignment={isMobile(screen) ? 'center' : 'left'}
          imageSrc={isMobile(screen) ? undefined : MarketingBannerImage}
        />
      )}
    </RenderWhen>
  </FlexLayout>
);

export default WellbeingBanner;
