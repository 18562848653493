import * as React from 'react';
import { FreeStyleListItem, TransparentButton } from 'styleguide-react';
import { RoutineIconProps } from '../RoutineIcon/RoutineIcon';
import IconTextCombo, {
  IconTextComboProps,
} from '../IconTextCombo/IconTextCombo';
import { classNames } from '../../../helpers';

export interface RoutineRulesListItemProps {
  testId: string;
  className?: string;
  title: string;
  titleTag?: React.ReactNode;
  subtitle?: string;
  icon: IconTextComboProps['icon'];
  iconFamily?: IconTextComboProps['iconFamily'];
  iconColor?: RoutineIconProps['color'];
  onClick?: () => void;
}

const RoutineRulesListItem = ({
  testId,
  className,
  title,
  titleTag,
  subtitle,
  icon,
  iconColor,
  iconFamily,
  onClick,
}: RoutineRulesListItemProps) => {
  return (
    <FreeStyleListItem key={title} noBorder>
      <TransparentButton
        testId={testId}
        className={classNames('par-routine-rules-list__clickable', className)}
        onClick={onClick}
      >
        <div className="par-routine-rules-list__item">
          <IconTextCombo
            showBadge={false}
            title={title}
            titleTag={titleTag}
            titleSize="medium"
            subtitle={subtitle}
            subtitleSize="large"
            icon={icon}
            iconColor={iconColor}
            iconFamily={iconFamily}
            iconSize="xlarge"
            iconGap="x2"
          />
        </div>
      </TransparentButton>
    </FreeStyleListItem>
  );
};

export default RoutineRulesListItem;
