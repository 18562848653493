import * as React from 'react';
import {
  ContentSeparator,
  FlexLayout,
  SelectionBar as SelectionBarCustom,
} from 'styleguide-react';

const SelectionBar: React.FunctionComponent<{
  title: string;
  onClickNext: (ev: React.SyntheticEvent) => {};
  onClickPrev: (ev: React.SyntheticEvent) => {};
  hidden: boolean;
  hasNext: boolean;
  hasPrev: boolean;
}> = ({ title, onClickNext, onClickPrev, hidden, hasNext, hasPrev }) => {
  if (hidden) {
    return null;
  }
  return (
    <FlexLayout mainaxis="column" paddingBottom="24">
      <SelectionBarCustom
        title={title}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <ContentSeparator />
    </FlexLayout>
  );
};

export default SelectionBar;
