import * as React from 'react';
import { Icon, IconType, TabPanel, Tabs } from 'styleguide-react';
import { SummaryDateRanges } from '../../constants';
import { t } from '../../lib/i18n';

const LabelTextByDate = () => ({
  [SummaryDateRanges.Today]: t('Today'),
  [SummaryDateRanges.Days7]: t('{{numDays}} Days', { numDays: 7 }),
  [SummaryDateRanges.Days15]: t('{{numDays}} Days', { numDays: 15 }),
  [SummaryDateRanges.Days30]: t('{{numDays}} Days', { numDays: 30 }),
  [SummaryDateRanges.CustomPastDay]: <Icon type={IconType.calendarAlt} />,
});

interface PropsDate {
  ranges: SummaryDateRanges[];
  activeRange: SummaryDateRanges;
  onChangeDateRange: (selectDate: SummaryDateRanges) => void;
}

const DateRangeSelectorTab: React.FunctionComponent<PropsDate> = ({
  ranges,
  activeRange,
  onChangeDateRange,
}: PropsDate) => (
  <Tabs
    animateOnChange
    testId="DateRangeSelector_Tabs"
    onChange={(range: string) => onChangeDateRange(+range)}
  >
    {ranges.map((range: number | string) => (
      <TabPanel
        key={range}
        name={`${range}`}
        active={range === activeRange}
        content={LabelTextByDate()[range]}
      >
        <React.Fragment />
      </TabPanel>
    ))}
  </Tabs>
);

export default DateRangeSelectorTab;
