import * as React from 'react';
import { t } from '../../../../lib/i18n';
import { Typography } from 'styleguide-react';

const AndroidAndIosLabel = () => {
  return (
    <Typography type="body2" color="gray-semi" weight="semibold">
      {t('For Android & iOS')}
    </Typography>
  );
};

export { isVisibleAndroidAndIosLabel } from './predicate';
export default AndroidAndIosLabel;
