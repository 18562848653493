/* eslint-disable no-console */
import { combineEpics, ofType } from 'redux-observable';
import { AppActionWithPayload, AppEpic } from '../../epics/types';
import { ProfileNotificationTopBarLoadedAction } from './types';
import {
  Observable,
  distinctUntilChanged,
  map,
  merge,
  of,
  switchMap,
  zip,
} from 'rxjs';
import { RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS } from '../safeNetworksSettings';
import { profileNotificationTopBarLoaded } from './actions';
import { profileHasBeenInSafeNetwork } from '../../selectors/safeNetwork';
import { getIsProfileLinkedToLW } from '../../containers/Dashboard/helpers/profileDashboardHelpers';

export const profileNotificationTopBarLoadedEpic: AppEpic<
  AppActionWithPayload,
  ProfileNotificationTopBarLoadedAction
> = (actions$, state$) => {
  /**
   * Returns an observable that emits an action to update the top bar status to loaded
   * when the receive SN action is triggered and it's different from the previous one.
   */
  const receivedSafeNetworkSettings$ = actions$.pipe(
    ofType(RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS),
    distinctUntilChanged()
  );

  const receiveStudentPolicies$ = actions$.pipe(
    ofType('RECEIVE_STUDENT_POLICIES'),
    distinctUntilChanged()
  );

  /**
   * Returns observable that emits an array with the values of receive profiles and devices,
   * when they occur and the values have changed.
   */
  const updateEvent$ = zip([
    actions$.pipe(ofType('RECEIVE_PROFILE')),
    actions$.pipe(ofType('RECEIVE_DEVICES')),
  ]);

  /**
   * Returns an observable that emits the profile id when the updateEvent emits
   * extracting the value from the payload of the profile
   */
  const getProfileIdWhenUpdateEvent$ = updateEvent$.pipe(
    map(([profiles]) => Number(profiles.payload.result))
  );

  /**
   * returns an observable that depending on whether the profiles has been or no into a safe network
   * emits the action of update the top bar notification status to loaded or the one to wait for the action of
   * receive the SN settings
   */
  return getProfileIdWhenUpdateEvent$.pipe(
    switchMap(profileId => {
      const streams: Array<Observable<any>> = [];
      if (profileHasBeenInSafeNetwork(state$.value, profileId)) {
        streams.push(receivedSafeNetworkSettings$);
      }
      if (getIsProfileLinkedToLW(state$.value, profileId)) {
        streams.push(receiveStudentPolicies$);
      }
      return streams.length > 0
        ? merge(streams).pipe(map(profileNotificationTopBarLoaded))
        : of(profileNotificationTopBarLoaded());
    })
  );
};

const profileNotificationTopBarEpic = combineEpics(
  profileNotificationTopBarLoadedEpic
);

export default profileNotificationTopBarEpic;
