import * as Immutable from 'immutable';
import * as R from 'ramda';
import { ContactRecord, EventRecord } from '../records';
import { Weekday } from '../helpers/dates';
import {
  WEBFILTER_ALERT,
  WEBFILTER_BLOCK,
  WEBFILTER_IGNORE,
  WEBFILTER_ALLOW,
  ONE_DAY_IN_MINUTES,
  DOMAIN_YOUTUBE,
  VideoSources,
  VideoActions,
  SettingsToggleNames,
  Platform,
  CALLSMS_CONTACT_ACTIONS,
  YoutubeBundleNameByPlatform,
  ApplicationAction,
} from '../constants';
import State from '../store/state';
import {
  CallsSmsContactRecord,
  AppRuleRecord,
  TimeSettingsRecord,
  makeAppKey,
  WebCategoryRecord,
} from '../records/profileRules';
import type { ProfileRulesRecord } from '../records/profileRules';

import { categoryLabels } from '../records/category';
import { tryUndefined, nullableProp } from './helpers';
import { getMultiDeviceTimeRestrictions } from './summary';
import { daytimeCollapsedPropertyName } from '../constants/profileRules';
import { createSelector } from './registry/core';
import { ProfileId } from '../records/profile';
import { getAppKeyFromEvent } from '../businessLogic/events/events';
import { filterOrSearchAppList } from '../helpers/appRules';
import { getLicense } from './license';
import { isPremiumBasic } from '../records/license/helpers';

export const getCallsSMSContactByPhone = tryUndefined(
  (state, profileId, phone) =>
    getCallsSMSContacts(state, profileId).find(contact =>
      contact ? contact.phone === phone : false
    )
);

export const getProfileRules = createSelector((state: State, id) =>
  getAllProfileRules(state).get(id.toString())
);

const getCallsSMSRules = createSelector(
  getProfileRules,
  nullableProp('callSmsMonitoring')
);

const getContactBlock = createSelector(
  getCallsSMSRules,
  nullableProp('contactBlock')
);

export const getCallsSMSContacts: (
  state: State,
  id: string
) => Immutable.List<CallsSmsContactRecord> = createSelector(
  getContactBlock,
  contacts =>
    contacts
      ? contacts.sort((a, b) =>
          R.pipe<any, number[], number>(
            R.map<any, number>(contact =>
              R.indexOf(contact.mode, CALLSMS_CONTACT_ACTIONS)
            ),
            ([a, b]) => a - b
          )([a, b])
        )
      : Immutable.List<CallsSmsContactRecord>()
);

export const getProfileVideoRules = createSelector(getProfileRules, rules =>
  rules.get('videos')
);

export const getTimeRestrictions = createSelector(
  getProfileRules,
  nullableProp('timeRestrictions')
);

export const getUnsupportedBrowsers = createSelector(
  getProfileRules,
  nullableProp('unsupportedBrowsers')
);

export const getWebRules = createSelector(getProfileRules, nullableProp('web'));

const getAppRules = createSelector(getProfileRules, nullableProp('appRules'));

export const getAppRulesApplicationList = createSelector(
  getAppRules,
  nullableProp('applicationList')
) as (
  state: State,
  profileId: string
) => Immutable.List<AppRuleRecord> | undefined;

export const hasFetchedAllProfileRules = (state: State) =>
  state.get('profileRules').get('hasFetchedAllRules');

export const getIsAlertNewApps = createSelector(
  getProfileRules,
  nullableProp('isAlertNewApps')
);

const getLocationRules = createSelector(
  getProfileRules,
  nullableProp('location')
);

export const getLocationRulesEnabled = createSelector(
  getLocationRules,
  nullableProp('enabled')
);

export const getLocationRulesUpdateFrequency = createSelector(
  getLocationRules,
  nullableProp('locationUpdateFrequency')
);

export const getCallsSMSRulesEnabled = createSelector(
  getCallsSMSRules,
  nullableProp('enabled')
);

export const getMonitorSMSContent = createSelector(
  getCallsSMSRules,
  nullableProp('monitorSmsContent')
);

export const getIncomingCallsBlock = createSelector(
  getCallsSMSRules,
  nullableProp('incomingCallsBlock')
);

export const getOutgoingCallsBlock = createSelector(
  getCallsSMSRules,
  nullableProp('outgoingCallsBlock')
);

const getSocialMonitoringRules = createSelector(
  getProfileRules,
  nullableProp('socialMonitoring')
);

export const getSocialMonitoringRulesEnabled = createSelector(
  getSocialMonitoringRules,
  nullableProp('enabled')
);

export const getSocialMonitoringRulesFacebookConnected = createSelector(
  getSocialMonitoringRules,
  nullableProp('facebookConnected')
);

export const getPanicRules = createSelector(
  getProfileRules,
  nullableProp('panic')
);

export const getPanicRulesAccountHolder = createSelector(
  getPanicRules,
  nullableProp('accountHolder')
);

export const getPanicRulesEnabled = createSelector(
  getPanicRules,
  nullableProp('enabled')
);

export const getPanicRulesContacts = createSelector(
  getPanicRules,
  nullableProp('contacts')
);

export const getProfileRulesModifyStatus = (state: State) =>
  state.get('profileRules').get('isUpdating');

export const getEditDomainException = (state: State) =>
  state.get('profileRules').get('editDomainException');

export const getEditCategory = (state: State) =>
  state.get('profileRules').get('editCategory');

export const getSelectedDomainAction = (state: State) =>
  state.get('profileRules').get('selectedDomainAction');

export const getWebFiltersActiveSection = (state: State) =>
  state.get('profileRules').get('webFiltersActiveSection');

export const getUnsupportedBrowserNameSelected = (state: State) =>
  state.get('profileRules').get('unsupportedBrowserNameSelected');

export const isMultiDevices = createSelector(
  getProfileRules,
  profileRules =>
    !R.isNil(profileRules) && profileRules.timeRestrictions.isMultiDevices
);

export const getTimeRanges = createSelector(
  getTimeRestrictions,
  nullableProp('timeRanges')
);

export const getIsAllowUnknownSites = createSelector(
  getWebRules,
  nullableProp(SettingsToggleNames.UnknownSites)
);

export const getIsSafeSearch = createSelector(
  getWebRules,
  nullableProp(SettingsToggleNames.SafeSearch)
);

export const getIsReportBlockedSites = createSelector(
  getWebRules,
  nullableProp(SettingsToggleNames.ReportBlockedSites)
);

export const getIsReportBlockedSummaryAlerts = createSelector(
  getWebRules,
  nullableProp(SettingsToggleNames.IsSearchAlertsNotificationsEnabled)
);

export const getWebCategories = createSelector(
  getWebRules,
  nullableProp('categories')
);

export const getWebFilterEnabled = createSelector(
  getWebRules,
  nullableProp('isCategoryList')
);

export const getWebFilterDomains = createSelector(
  getWebRules,
  nullableProp('domains')
);

export const getYoutubeAppRules = createSelector(
  getAppRulesApplicationList,
  appRules =>
    appRules &&
    appRules.filter(rule =>
      Object.values(YoutubeBundleNameByPlatform).includes(
        rule.exe as YoutubeBundleNameByPlatform
      )
    )
);

export const getWebFilterDomainsSortedByAction = createSelector(
  getWebFilterDomains,
  R.when(R.identity as (v: any) => boolean, (domains: any) =>
    [
      WEBFILTER_ALLOW,
      WEBFILTER_ALERT,
      WEBFILTER_BLOCK,
      WEBFILTER_IGNORE,
    ].reduce(
      (result, action) =>
        result.merge(
          // eslint-disable-next-line no-underscore-dangle
          domains.filter(R.equals(action)).sortBy((_val, key) => key)
        ),
      Immutable.OrderedMap<string, string>()
    )
  )
);

export const isYoutubeWebRuleEnabled = createSelector(
  getWebFilterDomains,
  domains => {
    return !(
      domains &&
      domains.get(DOMAIN_YOUTUBE) &&
      domains.get(DOMAIN_YOUTUBE) === WEBFILTER_BLOCK
    );
  }
);

export const getAppRulesEnabled = createSelector(
  getAppRules,
  nullableProp('isApplicationList')
);

export const getAppFilterSearchQuery = (state: State) =>
  state.get('profileRules').get('appFilterSearch');

export const getAppFilter = (state: State) =>
  state.get('profileRules').get('appFilter');

export const getAllProfileRules = (state: State) =>
  state.get('records').get('profileRules');

export const getAppRulesApp: (
  state: State,
  profileId: string,
  appKey: string
) => AppRuleRecord | undefined = createSelector(
  getAppRulesApplicationList,
  R.nthArg(2),
  (applicationList, appKey: string) =>
    applicationList
      ? applicationList.find(app => makeAppKey(app) === appKey)
      : undefined
);

export const getSelectedContactAction = (state: State) =>
  state.get('profileRules').get('selectedContactAction');

export const getCallsSMSRulesActiveSection = (state: State) =>
  state.get('profileRules').get('callsSMSActiveSection');

export const getCallsSMSBlockingOption = (state: State) =>
  state.get('profileRules').get('callsSMSBlockingOption');

export const getCallsSMSActiveContact = (state: State) =>
  state
    .get('profileRules')
    .get('callsSMSActiveContact') as ContactRecord | null;

export const getDaytimeCollapsed = (state, daytime) => {
  const isDaytimeCollapsed = daytimeCollapsedPropertyName(daytime);

  return state.get('profileRules').get(isDaytimeCollapsed);
};

export const getWebCategoriesMap = createSelector(
  getWebCategories,
  categoriesList => {
    const profileRulesCategoriesMap = categoriesList
      ? categoriesList.reduce(
          (categoriesMap, categoryRecord) =>
            categoriesMap.set(categoryRecord.category, categoryRecord),
          Immutable.Map<number, WebCategoryRecord>()
        )
      : Immutable.Map<number, WebCategoryRecord>();

    const allCategoriesMap = categoryLabels().reduce(
      (categoriesMap, { category }) =>
        !categoriesMap.has(category)
          ? categoriesMap.set(
              category,
              WebCategoryRecord({
                category,
                action: WEBFILTER_ALLOW,
              }) as WebCategoryRecord
            )
          : categoriesMap,
      profileRulesCategoriesMap
    );

    return allCategoriesMap;
  }
);

/** params: profileId, deviceId, weekday */
export const getTimeQuotas = createSelector(
  isMultiDevices,
  getProfileRules,
  getMultiDeviceTimeRestrictions as (
    state: State,
    ...args: any[]
  ) => TimeSettingsRecord,
  R.nthArg(3) as (...args: any[]) => Weekday,
  (isMultiDevices, profileRules, timeRestrictions, weekday) => {
    if (
      (isMultiDevices && !timeRestrictions) ||
      (!isMultiDevices && !profileRules)
    ) {
      return ONE_DAY_IN_MINUTES;
    }
    return isMultiDevices
      ? timeRestrictions.quotas.get(weekday)
      : profileRules.timeRestrictions.quotas.get(weekday);
  }
);

export const getIsBlockedUnsupportedBrowsers = createSelector(
  getUnsupportedBrowsers,
  nullableProp(SettingsToggleNames.BlockedUnsupportedBrowsers)
);

const excludedAppRules = createSelector(getLicense, license =>
  isPremiumBasic(license.subtype)
    ? []
    : ([...Object.values(YoutubeBundleNameByPlatform)] as string[])
);
export const getAppRulesAppListWithExclusions = createSelector(
  excludedAppRules,
  getAppRulesApplicationList,
  (exclusions, appRules = Immutable.List([])) =>
    appRules.filter(appRule => !exclusions.includes(appRule.exe))
);

export const getAppRulesApplicationListFiltered = createSelector(
  getAppFilter,
  getAppRulesAppListWithExclusions,
  getAppFilterSearchQuery,
  (filter, list, search) =>
    list === undefined
      ? Immutable.List<AppRuleRecord>()
      : filterOrSearchAppList(filter, list, search)
);

/**
 * Input selector to get the category ID from the arguments.
 *
 * This selector is necessary to allow `createSelector` to recompute results
 * based on different `categoryId` values. By explicitly defining `categoryId`
 * as an input selector, we ensure that `createSelector` can use it as part of
 * its memoization process, meaning that it will only recompute the results when
 * the `categoryId` changes.
 *
 * @param state - The Redux state.
 * @param profileId - The profile ID.
 * @param categoryId - The category ID.
 * @returns The category ID.
 */
const getCategoryId = (state, profileId, categoryId) => categoryId;

/**
 * Selector to get the apps in a specific category.
 *
 * This selector filters the list of apps based on the given `categoryId`.
 *
 * @param appsExcluded - The list of apps with exclusions.
 * @param categoryId - The category ID used to filter apps.
 * @returns The filtered list of apps belonging to the specified category.
 */
export const getAppsInCategory = createSelector(
  getAppRulesAppListWithExclusions,
  getCategoryId,
  (appsExcluded, categoryId) => {
    if (appsExcluded === undefined) return Immutable.List<AppRuleRecord>();
    return appsExcluded.filter(app => app.get('categoryId') === categoryId);
  }
);

export const getAppsUnfilteredInCategory = createSelector(
  getAppRulesApplicationList,
  getCategoryId,
  (appsUnfiltered, categoryId) => {
    if (appsUnfiltered === undefined) return Immutable.List<AppRuleRecord>();
    return appsUnfiltered.filter(app => app.get('categoryId') === categoryId);
  }
);

export const getProfileVideoRuleSource = (source: VideoSources) =>
  createSelector(getProfileVideoRules, videoRules => {
    if (videoRules === undefined) return undefined;
    switch (source) {
      case VideoSources.Youtube:
        return videoRules.youtube;
      default:
        return undefined;
    }
  });

export const getProfileVideoRule = (source: VideoSources, key: string) =>
  createSelector(
    getProfileVideoRuleSource(source),
    source =>
      (source && source.ids.has(key) && source.ids.get(key)) ||
      VideoActions.Unknown
  );

export const getApplicationListObject = (state: State, profileId) => {
  const applicationList = getAppRulesApplicationList(state, profileId);

  if (applicationList === undefined) {
    return {};
  }

  return applicationList.toJS().reduce((acc, application) => {
    acc[application.exe] = application;
    return acc;
  }, {});
};

export const getAppFromAppNameAndPlatform = (
  appName: string,
  platform: Platform
) =>
  createSelector(
    getAppRulesApplicationList,
    apps =>
      (apps || []).find(
        app => app.name === appName && app.platform === platform
      ) || null
  );

export const hasAppPremiumFlyoverShown = (state: State) =>
  state.get('profileRules').get('appPremiumFlyoverShown');

export const getAppRuleApplicationByKey: (
  state: State,
  profileId: string,
  appRuleKey: string
) => AppRuleRecord | undefined = (state, profileId, appRuleKey) => {
  const appsRules =
    getAppRulesApplicationList(state, profileId) || Immutable.List([]);
  return appsRules.find(appRule => {
    return makeAppKey(appRule) === appRuleKey;
  });
};

export const getAppDayDialCheckedDay = (state: State): Weekday =>
  state.getIn(['profileRules', 'appDayDialCheckedDay']);

export const getAppDayDial = (state: State): Weekday =>
  state.getIn(['profileRules', 'appDayDial'], null);

// find index of application record
export const indexOfApp = (state, profileId, appKey): number => {
  const list = getAppRulesApplicationList(
    state,
    profileId
  ) as Immutable.List<AppRuleRecord>;
  const index = list.findIndex(appItem => makeAppKey(appItem) === appKey);
  if (index === -1) {
    throw new Error(`could not find app "${appKey}"`);
  }
  return index;
};

export const setAppQuotas = (
  profileRules: ProfileRulesRecord,
  {
    appKey,
    quotas,
    profileId,
    action,
  }: {
    appKey: string;
    quotas: Immutable.Map<Weekday, number>;
    profileId: string;
    action: ApplicationAction;
  },
  state: State
): ProfileRulesRecord => {
  const appIndex = indexOfApp(state, profileId, appKey);
  const app = profileRules.get('appRules').get('applicationList').get(appIndex);

  return profileRules.setIn(
    ['appRules', 'applicationList', appIndex],
    app.set('quotas', quotas).set('action', action)
  );
};

export const getAppRuleApplicationByEvent = (
  state: State,
  profileId: ProfileId,
  event: EventRecord
) => {
  const appKey = getAppKeyFromEvent(event);
  return getAppRuleApplicationByKey(state, profileId, appKey);
};
