import { isFree } from '../records/license';
import { getLicense } from '../selectors/license';
import { ProfileId } from '../records/profile';
import { BaseThunk } from '../store/state';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

const cleanRoute = (route: string): string =>
  route.startsWith('/') ? route.slice(1) : route;

// TODO: Replace the use of this action using the license subtype
export const redirectFreeToPremiumAction =
  (
    profileId: ProfileId,
    premiumRoute: string,
    isPushRoute?: boolean
  ): BaseThunk<Promise<boolean>> =>
  (dispatch, getState) =>
    new Promise(resolve => {
      const license = getLicense(getState());
      const navigate = getMultiPlatformNavigation();
      if (isFree(license.type)) {
        resolve(true);
        if (isPushRoute) {
          dispatch(
            navigate({
              type: 'inner',
              src: `/profiles/${profileId}/${cleanRoute(premiumRoute)}`,
            })
          );
        } else {
          dispatch(
            navigate({
              type: 'inner:replace',
              src: `/profiles/${profileId}/${cleanRoute(premiumRoute)}`,
            })
          );
        }
      } else {
        resolve(false);
      }
    });
