import * as React from 'react';
import {
  FlexLayout,
  List,
  RegularStyleListItem,
  Avatar,
  ListItemVerticalAlign,
  AvatarSize,
  Icon,
  IconType,
  IconColor,
  IconSize,
  ClickableListItem,
  ListTitle,
  FreeText,
} from 'styleguide-react';
import type { ProtectProfilesListProps } from '../../containers/Schools/ProtectProfilesListContainer';
import PageLayout from '../Layout/PageLayout';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import RenderWhen, {
  hasScreenSize,
  ScreenSize,
} from '../RenderWhen/RenderWhen';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';

const ProtectProfilesList: React.FunctionComponent<
  ProtectProfilesListProps
> = props => {
  return (
    <PageLayout
      title={
        hasScreenSize(ScreenSize.Desktop)
          ? t('Whose device do you want to protect?')
          : t('Protect a device')
      }
      actions={[{ actionName: 'back' }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          useNonFlexLayout: false,
        },
      ]}
    >
      <ProfilesList {...props} />
    </PageLayout>
  );
};

const ProfilesList: React.FunctionComponent<ProtectProfilesListProps> = ({
  onClickProtectProfile,
  onClickAddNewChild,
  accountProfiles,
}: ProtectProfilesListProps) => {
  const listItemProps = ({
    onClickItem,
    title,
  }: {
    onClickItem: () => void;
    title: string;
  }) => ({
    centerMiddleContent: true,
    iconVerticalAlign: ListItemVerticalAlign.center,
    actionElementVerticalAlign: ListItemVerticalAlign.center,
    actionElement: (
      <ClickableListItem onClick={onClickItem}>
        <Icon
          type={IconType.angleRight}
          size={IconSize.lg}
          color={IconColor.grayLight}
        />
      </ClickableListItem>
    ),
    title: (
      <ClickableListItem onClick={onClickItem}>
        <ListTitle>{title}</ListTitle>
      </ClickableListItem>
    ),
  });

  return (
    <div className="ProtectProfilesList">
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        matchParentHeight
      >
        <RenderWhen screenSize={ScreenSize.Mobile}>
          <FreeText className="ProtectProfilesList__title">
            {t('Whose device do you want to protect?')}
          </FreeText>
        </RenderWhen>
        <FlexLayout
          mainaxis="column"
          marginTop="32"
          marginBottom="32"
          className="ProtectProfilesList__container"
        >
          <List listItemMarginTop="16" listItemPaddingBottom="8">
            {accountProfiles && !accountProfiles.isEmpty()
              ? accountProfiles.map((profile: ProfileRecord) => (
                  <RegularStyleListItem
                    key={profile.id}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...listItemProps({
                      title: profile.name,
                      onClickItem: () => onClickProtectProfile(profile.id),
                    })}
                    icon={
                      <ProfileAvatar
                        size={AvatarSize.small}
                        profile={profile}
                        onClick={() => onClickProtectProfile(profile.id)}
                      />
                    }
                  />
                ))
              : null}

            <RegularStyleListItem
              className="ProtectProfilesList__ListItem--Add-new-child"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...listItemProps({
                title: t('Add a child'),
                onClickItem: onClickAddNewChild,
              })}
              icon={
                <Avatar
                  emptyBackground
                  size={AvatarSize.small}
                  onClick={onClickAddNewChild}
                >
                  <Icon
                    type={IconType.plus}
                    color={IconColor.regular}
                    size={IconSize.regular}
                  />
                </Avatar>
              }
            />
          </List>
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

export default ProtectProfilesList;
