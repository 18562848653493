import api from '../api';
import { startLoadingInitialData } from '../ducks/app';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';
import { fetchLicense } from '../ducks/license';
import { replaceInModal } from '../ducks/routing';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { Dispatch } from '../store/state';

export enum ActivateRetrialStatus {
  Succeed = 'succeed',
  Failed = 'failed',
}

export const activateRetrial =
  (pathToRedirect: string | null) => async (dispatch: Dispatch) => {
    const navigate = getMultiPlatformNavigation();

    try {
      dispatch(startLoadingInitialData());

      await api.activateRetrial.post({});

      await dispatch(fetchLicense());

      const successPath = `modal/ActivateRetrialModal?status=${ActivateRetrialStatus.Succeed}`;
      if (pathToRedirect) {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: `${pathToRedirect}/${successPath}`,
          })
        );
      } else {
        dispatch(replaceInModal(successPath));
      }
    } catch {
      const failurePath = `modal/ActivateRetrialModal?status=${ActivateRetrialStatus.Failed}`;
      if (pathToRedirect) {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: `${pathToRedirect}/${failurePath}`,
          })
        );
      } else {
        dispatch(replaceInModal(failurePath));
      }
    } finally {
      dispatch(finishedLoadingInitialData());
    }
  };
