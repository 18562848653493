import { List } from 'immutable';
import { YoutubeSummaryRecord } from '../../records/youtubeSummary';
import {
  FETCHING_YOUTUBE_SUMMARY,
  FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED,
  FETCH_YOUTUBE_SUMMARY_FAILED,
  LOAD_YOUTUBE_ACTIVITY,
  RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  RECEIVE_YOUTUBE_SUMMARY,
  RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS,
  YOUTUBE_SUMMARY_ERROR,
} from './constants';
import { VideoDetailsRecord } from '../../records/urlDetails';
import { LoadPayload } from './types';
import { AnyError } from '../../helpers/errorHandling';

export function fetchingYoutubeSummary() {
  return {
    type: FETCHING_YOUTUBE_SUMMARY,
  };
}

export function receiveYoutubeSummary(payload) {
  return {
    type: RECEIVE_YOUTUBE_SUMMARY,
    payload: {
      summary: YoutubeSummaryRecord.fromPayload(payload),
    },
  };
}

export function youtubeSummaryLoadingError(payload: AnyError) {
  return {
    type: YOUTUBE_SUMMARY_ERROR,
    payload,
  };
}

export function receiveMoreYoutubeSummaryDetailsLastVideos(payload = []) {
  return {
    type: RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
    payload: {
      lastVideos: List(payload.map(v => VideoDetailsRecord.fromPayload(v))),
    },
  };
}

export function receiveYoutubeSummaryDetailsLastVideos(payload) {
  return {
    type: RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
    payload: {
      lastVideos: List(payload.map(v => VideoDetailsRecord.fromPayload(v))),
    },
  };
}

export function fetchYoutubeSummaryFailed() {
  return {
    type: FETCH_YOUTUBE_SUMMARY_FAILED,
  };
}

export function fetchYoutubeSummaryDetailLastVideosFailed() {
  return {
    type: FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED,
  };
}

export function fetchingYoutubeSummaryDetailsLastVideos() {
  return {
    type: FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  };
}

export function showYoutubeSummaryDetailsActions(item) {
  return {
    type: SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS,
    payload: item,
  };
}

export const loadYoutubeSummaryCardInfo = (payload: LoadPayload) => ({
  type: LOAD_YOUTUBE_ACTIVITY,
  payload,
});
