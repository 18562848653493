import * as React from 'react';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { t } from '../../lib/i18n';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import FamilyTherapy from './WellbeingFeaturePage/FamilyTherapy';

interface WellbeingTherapyProps {
  kickoffSessionPrice?: string;
  weeklyPrice: string;
  isLoading: boolean;
  onLoad: () => void;
  onGetStarted: () => void;
  onSubscribe: (data: string[]) => void;
}

const WellbeingTherapy = ({
  kickoffSessionPrice,
  weeklyPrice,
  onLoad,
  isLoading,
  onGetStarted,
  onSubscribe,
}: WellbeingTherapyProps) => {
  React.useEffect(() => {
    onLoad();
  }, []);
  return (
    <PageLayout
      className="par-wellbeing-page"
      title={t('Wellbeing Therapy')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: false,
          useNonFlexLayout: false,
          removePaddings: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          columns: 1,
          removePaddings: true,
          useNonFlexLayout: false,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.All }]}
    >
      <PageSectionLayout>
        <FamilyTherapy
          kickoffSessionPrice={kickoffSessionPrice}
          weeklyPrice={weeklyPrice}
          isLoading={isLoading}
          onGetStarted={onGetStarted}
          onSubscribe={onSubscribe}
        />
      </PageSectionLayout>
    </PageLayout>
  );
};

export default WellbeingTherapy;
