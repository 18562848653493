import * as React from 'react';
import Swiper from 'react-id-swiper';
import {
  FlexLayout,
  Layout,
  Card,
  IconType,
  StyledHeader,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import PullRefresh from '../PullRefresh/PullRefresh';
import ActivityTimelineContainer from '../../containers/ActivityTimeline/ActivityTimelineContainer';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import SpeedDial from '../SpeedDial/SpeedDial';
import { SummaryDateRanges, CardDimensions } from '../../constants';
import ScreenSummaryCardContainer from '../../containers/ScreenTimeSummary/ScreenSummaryCardContainer';
import TotalScreenTimeCardContainer from '../../containers/TotalScreenTimeContainer/TotalScreenTimeCardContainer';

import ActivitySummaryListContainer from '../../containers/ActivitySummary/ActivitySummaryListContainer';
import RenderWhen, {
  ScreenSize,
  hasScreenSize,
} from '../RenderWhen/RenderWhen';
import { track, Events } from '../../helpers/analytics';
import YoutubeSummaryCardContainer from '../../containers/Summary/YoutubeSummaryCardContainer';
import EmptyActivityPlaceholder from '../EmptyActivityPlaceholder/EmptyActivityPlaceholder';
import LoadingActivityPlaceholder from '../LoadingActivityPlaceholder/LoadingActivityPlaceholder';
import { ActivityType } from '../ActivitySummary/sharedModels';
import InsightButton from '../Insights/InsightButton';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import ScrollManager from '../Layout/hooks/useKeepScroll/ScrollManager';
import { captureException } from '../../helpers/sentry';
import { RouterAction } from '../../helpers/multiPlatformNavigation';
import ProfileNotificationTopBarContainer from '../../containers/ProfileNotificationTopBar/ProfileNotificationTopBarContainer';
import { RouterParams } from '../../store/state';
import { EventRecord } from '../../records';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import MobileScreenSummaryCardContainer from '../../containers/ScreenTimeSummary/MobileScreenSummaryCardContainer';
import UnifiedNowCardContainer from '../../containers/NowCard/UnifiedNowCard/UnifiedNowCardContainer';
import SmartDevicesStatusContainer from '../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';

const scrollKey = ScrollManager.getInstance().register('profileNew');

class ProfileNew extends React.Component<
  {
    profile: ProfileRecord;
    isFetching: boolean;
    isFetchingNewEvents: boolean;
    activeDateRange: SummaryDateRanges;
    appRuleEvent: EventRecord | undefined;
    callsSMSEvent: EventRecord | undefined;
    webfilterEvent: EventRecord | undefined;
    youtubeEvent: EventRecord | undefined;
    panicEvent: EventRecord | undefined;
    isMenuOpen: boolean;
    showStatsNotification: boolean;
    isEmpty: boolean;
    showYoutubeCard: boolean;
    showCallsSMSCard: boolean;
    showInsights: boolean;
    isQuestionableWebSearchesFeactureActive: boolean;
    activitySwiperIndex: number;
    hasTamperedDevice: boolean;
    showProfileSummarySelector: boolean;
    routerParams: RouterParams;
    handleMore: () => void;
    handleRefresh: () => void;
    onClickEdit: () => RouterAction;
    onSelectDateRange: (range: SummaryDateRanges) => any;
    // Floating button + menu
    onToggleMenu: () => any;
    onClickAddDevices: () => any;
    onClickSpeedDialAddDevices: () => any;
    onClickRules: () => any;
    // Events Action Sheets
    onAppRuleActionSheetClick: () => any;
    onCallSmsActionSheetClick: () => any;
    onWebActionSheetClick: () => any;
    onPanicActionSheetClick: () => any;
    onClickGoBack: () => any;
  },
  {
    scrollToTopVisible: boolean;
  }
> {
  swiperRef = React.createRef<any>();

  constructor(props, context?) {
    super(props, context);
    this.state = {
      scrollToTopVisible: false,
    };
  }

  render() {
    const {
      profile,
      isFetching,
      isFetchingNewEvents,
      activeDateRange,
      isMenuOpen,
      handleMore,
      handleRefresh,
      isEmpty,
      onClickEdit,
      onToggleMenu,
      onClickSpeedDialAddDevices,
      onClickRules,
      onClickGoBack,
      onSelectDateRange,
      activitySwiperIndex,
      showYoutubeCard,
      showCallsSMSCard,
      showInsights,
      isQuestionableWebSearchesFeactureActive,
      showProfileSummarySelector,
      routerParams,
    } = this.props;

    const { scrollToTopVisible } = this.state;

    const onScroll = ({ target }) => {
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        handleMore();
      }

      const isOverTheTop = target.scrollTop > window.innerHeight;
      if (isOverTheTop !== scrollToTopVisible) {
        this.setState({ scrollToTopVisible: isOverTheTop });
      }
    };

    const swiperOptions = {
      pagination: {
        el: '.swiper-pagination',
      },
      initialSlide: activitySwiperIndex,
      on: {
        slideChange: () => {
          const swiperWrapper = this.swiperRef.current;
          try {
            if (swiperWrapper?.props?.children && swiperWrapper?.swiper) {
              const activityCardIndex = swiperWrapper.swiper.realIndex;
              const activityCard =
                swiperWrapper.props.children[activityCardIndex];
              const activityCardTrackableKey =
                activityCard.props['data-trackable-key'];

              track(Events.SwipedActivityCard, {
                page: activityCardTrackableKey,
                category: 'Activity cards',
              });
            }
          } catch (err) {
            captureException(err, {
              extra: {
                message:
                  'ActivitySummary Swiper slideChange() function has failed',
              },
            });
          }
        },
      },
      spaceBetween: 10,
      slidesPerView: 'auto',
      centeredSlides: true,
    };

    const content = (
      <React.Fragment>
        <FlexLayout mainaxis="column">
          {isFetching ? (
            <LoadingActivityPlaceholder />
          ) : (
            <React.Fragment>
              {isEmpty ? (
                <EmptyActivityPlaceholder profileName={profile.name} />
              ) : (
                <React.Fragment>
                  <RenderWhen screenSize={ScreenSize.Desktop}>
                    <FlexLayout mainaxis="column">
                      <FlexLayout mainaxis="row">
                        <Layout
                          margin="8"
                          testId="par-total-screen-time-card-container"
                        >
                          <TotalScreenTimeCardContainer
                            profile={profile}
                            width={CardDimensions.width}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        <Layout
                          margin="8"
                          testId="par-screen-summary-card-container"
                        >
                          <ScreenSummaryCardContainer
                            profile={profile}
                            height={CardDimensions.height}
                            width={CardDimensions.width}
                          />
                        </Layout>
                      </FlexLayout>
                      <FlexLayout mainaxis="row">
                        <Layout margin="8">
                          <ActivitySummaryListContainer
                            data-testid="blockedWebActivitySummaryCard"
                            type={ActivityType.BlockedWebs}
                            profile={profile}
                            width={CardDimensions.width}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        <Layout margin="8">
                          <ActivitySummaryListContainer
                            type={ActivityType.AppsAndWebActivity}
                            profile={profile}
                            data-testid="topActivitySummaryCard"
                            width={CardDimensions.width}
                            height={CardDimensions.height}
                          />
                        </Layout>
                      </FlexLayout>
                      <FlexLayout mainaxis="row">
                        <Layout margin="8">
                          <ActivitySummaryListContainer
                            data-testid="searchActivitySummaryCard"
                            type={
                              isQuestionableWebSearchesFeactureActive
                                ? ActivityType.QuestionableWebSearches
                                : ActivityType.Search
                            }
                            profile={profile}
                            width={CardDimensions.width}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        <Layout margin="8">
                          {showCallsSMSCard && (
                            <ActivitySummaryListContainer
                              data-testid="callsAndSmsActivitySummaryCard"
                              type={ActivityType.CallsAndSms}
                              profile={profile}
                              width={CardDimensions.width}
                              height={CardDimensions.height}
                            />
                          )}
                        </Layout>
                      </FlexLayout>

                      {showYoutubeCard && (
                        <FlexLayout mainaxis="row">
                          <Layout margin="8">
                            {showYoutubeCard && (
                              <YoutubeSummaryCardContainer
                                profile={profile}
                                needsBorder
                                width={CardDimensions.width}
                              />
                            )}
                          </Layout>
                        </FlexLayout>
                      )}
                    </FlexLayout>
                  </RenderWhen>
                  <RenderWhen screenSize={ScreenSize.Tablet}>
                    <div>
                      <FlexLayout mainaxis="row" marginBottom="16">
                        <Layout
                          width="50%"
                          paddingLeft="16"
                          paddingRight="16"
                          testId="par-total-screen-time-card-container--tablet"
                        >
                          <TotalScreenTimeCardContainer
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        <Layout
                          width="50%"
                          paddingLeft="16"
                          paddingRight="16"
                          testId="par-screen-summary-card-container--tablet"
                        >
                          <ScreenSummaryCardContainer
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                      </FlexLayout>

                      <Swiper {...swiperOptions} ref={this.swiperRef}>
                        <div data-trackable-key="Top Activity Card">
                          <FlexLayout mainaxis="row">
                            <Layout
                              width="50%"
                              marginRight="16"
                              marginLeft="16"
                            >
                              <ActivitySummaryListContainer
                                type={ActivityType.AppsAndWebActivity}
                                profile={profile}
                                height={CardDimensions.height}
                              />
                            </Layout>
                            <Layout
                              width="50%"
                              marginRight="16"
                              marginLeft="16"
                            >
                              <ActivitySummaryListContainer
                                data-testid="blockedWebActivitySummaryCard"
                                type={ActivityType.BlockedWebs}
                                profile={profile}
                                height={CardDimensions.height}
                              />
                            </Layout>
                          </FlexLayout>
                        </div>
                        <div data-trackable-key="Youtube Activity Card">
                          <FlexLayout mainaxis="row">
                            <Layout
                              width="50%"
                              marginRight="16"
                              marginLeft="16"
                            >
                              <ActivitySummaryListContainer
                                data-testid="searchActivitySummaryCard"
                                type={
                                  isQuestionableWebSearchesFeactureActive
                                    ? ActivityType.QuestionableWebSearches
                                    : ActivityType.Search
                                }
                                profile={profile}
                                height={CardDimensions.height}
                              />
                            </Layout>
                            {showYoutubeCard && (
                              <Layout
                                width="50%"
                                marginRight="16"
                                marginLeft="16"
                              >
                                <YoutubeSummaryCardContainer
                                  profile={profile}
                                />
                              </Layout>
                            )}
                            {!showYoutubeCard && showCallsSMSCard && (
                              <Layout
                                width="50%"
                                marginRight="16"
                                marginLeft="16"
                              >
                                <ActivitySummaryListContainer
                                  data-testid="callsAndSmsActivitySummaryCard"
                                  type={ActivityType.CallsAndSms}
                                  profile={profile}
                                  height={CardDimensions.height}
                                />
                              </Layout>
                            )}
                          </FlexLayout>
                        </div>
                        {showYoutubeCard && showCallsSMSCard && (
                          <div data-trackable-key="Calls and SMS Activity Card">
                            <FlexLayout mainaxis="row">
                              <Layout
                                width="50%"
                                marginRight="16"
                                marginLeft="16"
                              >
                                <ActivitySummaryListContainer
                                  data-testid="callsAndSmsActivitySummaryCard"
                                  type={ActivityType.CallsAndSms}
                                  profile={profile}
                                  height={CardDimensions.height}
                                />
                              </Layout>
                            </FlexLayout>
                          </div>
                        )}
                      </Swiper>
                      {showInsights && <InsightButton />}
                      <Card fullHeight fullWidth>
                        <ActivityTimelineContainer profile={profile} />
                      </Card>
                    </div>
                  </RenderWhen>
                  <RenderWhen screenSize={ScreenSize.Mobile}>
                    <div>
                      <Layout marginBottom="16">
                        <Layout
                          padding="16"
                          width="100%"
                          testId="par-mobile-screen-summary-card-container"
                        >
                          <MobileScreenSummaryCardContainer
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                      </Layout>
                      <Swiper {...swiperOptions} ref={this.swiperRef}>
                        <div data-trackable-key="Top Activity Card">
                          <Layout marginLeft="16" marginRight="16">
                            <ActivitySummaryListContainer
                              type={ActivityType.AppsAndWebActivity}
                              profile={profile}
                              height={CardDimensions.height}
                            />
                          </Layout>
                        </div>
                        <div data-trackable-key="Blocked Web Activity Card">
                          <Layout marginLeft="16" marginRight="16">
                            <ActivitySummaryListContainer
                              data-testid="blockedWebActivitySummaryCard"
                              type={ActivityType.BlockedWebs}
                              profile={profile}
                              height={CardDimensions.height}
                            />
                          </Layout>
                        </div>
                        {showYoutubeCard && (
                          <div data-trackable-key="Youtube Activity Card">
                            <Layout marginLeft="16" marginRight="16">
                              <YoutubeSummaryCardContainer profile={profile} />
                            </Layout>
                          </div>
                        )}

                        <div data-trackable-key="Search Activity Card">
                          <Layout marginLeft="16" marginRight="16">
                            <ActivitySummaryListContainer
                              data-testid="searchActivitySummaryCard"
                              type={
                                isQuestionableWebSearchesFeactureActive
                                  ? ActivityType.QuestionableWebSearches
                                  : ActivityType.Search
                              }
                              profile={profile}
                              height={CardDimensions.height}
                            />
                          </Layout>
                        </div>

                        {showCallsSMSCard && (
                          <div data-trackable-key="Calls and SMS Activity Card">
                            <Layout marginLeft="16" marginRight="16">
                              <ActivitySummaryListContainer
                                data-testid="callsAndSmsActivitySummaryCard"
                                type={ActivityType.CallsAndSms}
                                profile={profile}
                                height={CardDimensions.height}
                              />
                            </Layout>
                          </div>
                        )}
                      </Swiper>
                      {showInsights && <InsightButton />}
                      <Card fullHeight fullWidth>
                        <ActivityTimelineContainer profile={profile} />
                      </Card>
                    </div>
                  </RenderWhen>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </FlexLayout>
      </React.Fragment>
    );

    return (
      <PageLayout
        isProfileEditable
        configuration={[
          { screenSize: ScreenSize.Desktop, columns: 1, mainReducedSize: true },
          {
            screenSize: ScreenSize.MobileOrTablet,
            showProfile: true,
            showProfileSummarySelector,
            title: ' ',
            mainColumnBackgroundColor: 'gray-ligther',
            removePaddings: true,
          },
          {
            screenSize: ScreenSize.All,
            pullToRefresh: (
              <PullRefresh
                isFetching={isFetchingNewEvents}
                onRefresh={handleRefresh}
                onScroll={onScroll}
              />
            ),
          },
          {
            screenSize: ScreenSize.MobileOrTablet,
            keepScrollKey: scrollKey,
          },
        ]}
        actions={[
          {
            actionName: 'edit',
            onClick: onClickEdit,
            screenSize: ScreenSize.MobileOrTablet,
          },
          {
            actionName: 'back',
            onClick: onClickGoBack,
            screenSize: ScreenSize.MobileOrTablet,
          },
        ]}
      >
        {content}

        <PageSectionLayout placeholder="before-pullrefresh">
          <Layout
            marginBottom="16"
            marginLeft={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '8'}
            marginRight={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '24'}
          >
            {!isFetching && (
              <Layout marginBottom="24">
                <ProfileNotificationTopBarContainer params={routerParams} />
                <Layout marginBottom="16">
                  <StyledHeader type="h4" testId="studentSummaryTitle">
                    {t("What's happening now")}
                  </StyledHeader>
                </Layout>
                <React.Fragment>
                  <Layout marginBottom="8">
                    <UnifiedNowCardContainer profileId={profile.id} />
                  </Layout>
                  <FlexLayout mainaxis="row">
                    <SmartDevicesStatusContainer
                      profileId={String(profile.id)}
                    />
                  </FlexLayout>
                </React.Fragment>
              </Layout>
            )}

            <StyledHeader type="h4" testId="studentSummaryTitle">
              {t('Activity summary')}
            </StyledHeader>
          </Layout>

          <Layout marginBottom="8" centerX={hasScreenSize(ScreenSize.Tablet)}>
            <DateRangeSelector
              activeRange={activeDateRange}
              onChangeDateRange={onSelectDateRange}
            />
          </Layout>
        </PageSectionLayout>

        <PageSectionLayout placeholder="after-pullrefresh">
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <SpeedDial
              isOpen={isMenuOpen}
              actions={[
                {
                  icon: IconType.settings,
                  label: t('Set rules'),
                  onClick: onClickRules,
                },
                {
                  icon: IconType.mobile,
                  label: t('Add a device'),
                  onClick: onClickSpeedDialAddDevices,
                },
              ]}
              onToggleMenu={onToggleMenu}
            />
          </RenderWhen>
        </PageSectionLayout>
      </PageLayout>
    );
  }
}

export default ProfileNew;
