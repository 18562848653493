import * as React from 'react';
import * as R from 'ramda';
import { Icon, IconColor, IconSize, IconType } from 'styleguide-react';
import { t } from '../../lib/i18n';
import HourLimitSlider from './HourLimitSlider';
import {
  WEEKDAYS,
  mapWeekDays,
  weekdaysShortTranslations,
  Weekday,
} from '../../helpers/dates';
import { classNames } from '../../helpers';
import { TimesRecord } from '../../records/profileRules';

const weekdaysTranslations = day =>
  mapWeekDays([
    t('Mondays'),
    t('Tuesdays'),
    t('Wednesdays'),
    t('Thursdays'),
    t('Fridays'),
    t('Saturdays'),
    t('Sundays'),
  ])[day];

export interface DayDialProps {
  dayQuotaMap: TimesRecord;
  selectedDay: Weekday;
  onSelectDay: (day: Weekday) => void;
  onChangeQuota: (minutes: number) => void;
}

const DayDial = ({
  dayQuotaMap,
  selectedDay,
  onSelectDay,
  onChangeQuota,
}: DayDialProps) => {
  if (!dayQuotaMap) return null;

  return (
    <div>
      <div className="DayDial__DayButtonsList">
        {WEEKDAYS.map(renderDayButton(onSelectDay, selectedDay, dayQuotaMap))}
      </div>
      <HourLimitSlider
        value={dayQuotaMap.get(selectedDay) / 60}
        dayLabel={weekdaysTranslations(selectedDay)}
        onChange={onChangeQuota}
      />
      <div className="DailyTimeLimit__ButtonContainer">
        {renderQuotaActionButton(
          R.partial(onChangeQuota, [1440]),
          dayQuotaMap.get(selectedDay) === 1440,
          <Icon
            type={IconType.clock}
            color={IconColor.neutral}
            size={IconSize.x2}
            testId="dayDial-allow"
          />,
          t('Allow'),
          'left'
        )}
        {renderQuotaActionButton(
          R.partial(onChangeQuota, [
            dayQuotaMap.get(selectedDay) > 0 ? 0 : 1440,
          ]),
          false,
          dayQuotaMap.get(selectedDay) > 0 ? (
            <Icon
              type={IconType.lockAlt}
              color={IconColor.neutral}
              size={IconSize.x2}
              testId="dayDial-lock"
            />
          ) : (
            <Icon
              type={IconType.unlock}
              color={IconColor.neutral}
              size={IconSize.x2}
              testId="dayDial-unblock"
            />
          ),
          dayQuotaMap.get(selectedDay) > 0 ? t('Block') : t('Unblock'),
          'right'
        )}
      </div>
    </div>
  );
};

const renderDayButton =
  (
    onClickDay: (day: Weekday) => void,
    checkedDay: Weekday,
    dayQuotaMap: TimesRecord
  ) =>
  (day: Weekday, index: number) =>
    (
      <div
        data-testId={`DayDial__day-button-${index}`}
        key={index}
        onClick={R.partial(onClickDay, [day])}
        className={classNames(
          'DayDial__DayButton',
          `DayDial__DayButton__${weekdaysShortTranslations()[day]}`,
          dayButtonClass(day, checkedDay, dayQuotaMap.get(day))
        )}
      >
        {weekdaysShortTranslations()[day]}
      </div>
    );

const dayButtonClass = (day, checkedDay, dayQuota) => {
  const isButtonSelected = checkedDay === day;
  switch (dayQuota) {
    case 0:
      return isButtonSelected
        ? 'DayDial__DayButton--selectedBlocked'
        : 'DayDial__DayButton--blocked';
    case 1440:
      return isButtonSelected
        ? 'DayDial__DayButton--selected'
        : 'DayDial__DayButton--noTimeLimit';
    default:
      return isButtonSelected
        ? 'DayDial__DayButton--selected'
        : 'DayDial__DayButton--timeLimitSet';
  }
};

const renderQuotaActionButton = (
  onClick,
  disabled: boolean,
  icon: React.ReactNode,
  label: string,
  side: 'left' | 'right'
) => (
  <div className={`DayDial__ActionButton DayDial__ActionButton--${side}`}>
    <button type="button" onClick={onClick} disabled={disabled}>
      {icon}
    </button>
    {label}
  </div>
);

export default DayDial;
