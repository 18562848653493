import * as React from 'react';
import {
  Button,
  Modal,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  AlertBox,
  AlertBoxType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

export interface TotalScreenTimeInfoModalProps {
  isRoutinesEnabled: boolean;
  onClickClose: () => void;
}

const TotalScreenTimeInfoModal = ({
  isRoutinesEnabled,
  onClickClose,
}: TotalScreenTimeInfoModalProps) => {
  return (
    <Modal
      size="small"
      header={{
        icon: (
          <Icon
            type={IconType.hourglassHalf}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={isRoutinesEnabled ? t('Total screen time') : t('Screen time')}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button1"
          onClick={onClickClose}
          disabled={false}
          size={ButtonSize.medium}
          block
        >
          {t('Ok')}
        </Button>,
      ]}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t(
            "Screen time is counted whenever your child's screens are switched on."
          )}
        </ModalStyledText>
      </FlexLayout>

      {isRoutinesEnabled && (
        <FlexLayout mainaxis="column" marginTop="16">
          <AlertBox type={AlertBoxType.info} centered>
            {t(
              'Total screen time can be higher than daily time limits if your child uses routines where no limits apply.'
            )}
          </AlertBox>
        </FlexLayout>
      )}
    </Modal>
  );
};
export default TotalScreenTimeInfoModal;
