import * as React from 'react';
import {
  FlexLayout,
  RegularStyleListItem,
  ClickableListItem,
  ListTitle,
  TransparentButton,
  List,
  Button,
  ButtonSize,
  Typography,
} from 'styleguide-react';
import { CALLSMS_RULES_BLOCK } from '../../../constants';
import { t } from '../../../lib/i18n';
import { CallsSMSRulesProps } from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import DropDownActionSheet from '../../DropDown/DropDownActionSheet';
import IconAllow from '../../Icons/Actions/IconAllow';
import IconBlock from '../../Icons/Actions/IconBlock';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

export type CallsSMSContactsProps = Pick<
  CallsSMSRulesProps,
  'contacts' | 'onClickContact' | 'activeContact' | 'onClickAddException'
>;

const CallsSMSContacts: React.FC<CallsSMSContactsProps> = ({
  contacts,
  onClickContact,
  activeContact,
  onClickAddException,
}: CallsSMSContactsProps) => {
  return (
    <FlexLayout mainaxis="column" marginTop="8" className="contacts">
      <Typography type="title3" weight="semibold">
        {t('Allow or block numbers')}
      </Typography>
      <p>{t('Add phone numbers and set customized rules for them.')}</p>
      {contacts.size ? (
        <List
          listItemMarginTop="8"
          listItemPaddingTop="8"
          listItemMarginBottom="8"
          listItemPaddingBottom="8"
        >
          {contacts.map(contact => {
            return (
              <RegularStyleListItem
                key={contact.phone}
                title={
                  <React.Fragment>
                    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
                      <ClickableListItem
                        onClick={() => onClickContact(contact)}
                      >
                        <ListTitle isBold={false}>
                          {contact.name || t('Unknown')}
                        </ListTitle>
                      </ClickableListItem>
                    </RenderWhen>
                    <RenderWhen screenSize={ScreenSize.Desktop}>
                      <ListTitle isBold={false}>
                        {contact.name || t('Unknown')}
                      </ListTitle>
                    </RenderWhen>
                  </React.Fragment>
                }
                upperSubtitle={contact.phone}
                icon={
                  contact.mode === CALLSMS_RULES_BLOCK ? (
                    <IconBlock />
                  ) : (
                    <IconAllow />
                  )
                }
                actionElement={
                  <TransparentButton onClick={() => onClickContact(contact)}>
                    <DropDownActionSheet
                      minWidth={300}
                      selectedActionSheetKey={contact.phone}
                      title={activeContact ? activeContact.name : undefined}
                    />
                  </TransparentButton>
                }
              />
            );
          })}
        </List>
      ) : (
        ''
      )}
      <FlexLayout
        mainaxis="column"
        marginTop="72"
        width="100%"
        crossaxisAlignment="center"
      >
        <Button
          size={ButtonSize.medium}
          onClick={onClickAddException}
          className="rck-layout--center-x"
        >
          {t('Add a number')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

export default CallsSMSContacts;
