import { connect } from 'react-redux';
import SafeNetworkSettings from '../../components/AccountSettings/SafeNetworkSettings';
import State, { Dispatch } from '../../store/state';
import { getProfilesWithDeviceAssigned } from '../../selectors';
import {
  getIsAccountSafeNetworkSettingsLoading,
  getProfileSafeNetworkSettings,
} from '../../selectors/safeNetworksSettings';
import {
  fetchAllProfileWithLinkedDevices,
  patchProfileSafeNetworkProtection,
} from '../../ducks/safeNetworksSettings';
import { pushRelativeLocation } from '../../actions/Navigation';

const mapStateToProps = (state: State) => {
  const profilesWithDeviceLinked = getProfilesWithDeviceAssigned(state);
  const accountSafeNetworkSettings = profilesWithDeviceLinked.map(profile => {
    const safeNetworkSettings = getProfileSafeNetworkSettings(
      state,
      profile!.uid
    );
    return {
      profile,
      safeNetworkActive: !!safeNetworkSettings?.disableProtectionInSafeNetwork,
    };
  });
  return {
    accountSafeNetworkSettings,
    isLoading: getIsAccountSafeNetworkSettingsLoading(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadProfilesSafeNetworkSettings: () => {
    dispatch(fetchAllProfileWithLinkedDevices());
  },
  toggleProfileSafeNetworkProtection: (
    profileUid: string,
    toggleValue: boolean
  ) => {
    dispatch(patchProfileSafeNetworkProtection(profileUid, toggleValue));
  },
  openInfoModal: () => {
    dispatch(pushRelativeLocation(`modal/SafeNetworkSettingsInfoModal`));
  },
});

export type SafeNetworksSettingsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SafeNetworksSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SafeNetworkSettings);

export default SafeNetworksSettingsContainer;
