import { Record } from 'immutable';
import { Feature } from '../../records/features/types';

export const isFeatureEnabled = (feature: Record<Feature>): boolean => {
  return feature.status === 'available';
};

export const isFeatureDisabled = (feature: Record<Feature>): boolean => {
  return feature.status === 'not_available';
};

export const isFeatureRequireUpgrade = (feature: Record<Feature>): boolean => {
  return feature.status === 'requires_upgrade';
};
