import { Experiment as Variants } from '../../../sideEffects/flags';
import { getProfileIds } from '../../../selectors/profile';
import ProtectYourChildsDevice from './ProtectYourChildsDevice';
import type { ExperimentInterface } from '../../variants';

const variants = {
  profileList: {
    // Original and VariantA leave the add profile button as it is
    [Variants.VariantB]: ProtectYourChildsDevice,
    [Variants.VariantC]: ProtectYourChildsDevice,
  },
  addProfile: {
    // Original and VariantA leave the add profile button as it is
    [Variants.VariantB]: () => null,
    [Variants.VariantC]: () => null,
  },
};

const getComponentForVariant = (componentId, variant) =>
  variants[componentId] && variants[componentId][variant];

const Experiment: ExperimentInterface = {
  compliesWithPrerequisites: state => getProfileIds(state).size === 0,
  getComponentToRender: getComponentForVariant,
};

export default Experiment;
