import { NonEmptyMapRecord, fromJS } from 'immutable';
import {
  ACCOUNT_SAFE_NETWORK_SETTINGS_ERROR,
  LOADED_ACCOUNT_SAFE_NETWORKS_SETTINGS,
  LOADING_ACCOUNT_SAFE_NETWORKS_SETTINGS,
  RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS,
} from './constants';
import { SafeNetworksState } from './types';

export default function safeNetworksSettings(
  state: NonEmptyMapRecord<SafeNetworksState> = fromJS({
    isFetching: false,
    hasError: false,
  }),
  action
) {
  switch (action.type) {
    case LOADING_ACCOUNT_SAFE_NETWORKS_SETTINGS:
      return state.merge({
        isFetching: true,
        hasError: false,
      });
    case LOADED_ACCOUNT_SAFE_NETWORKS_SETTINGS:
      return state.merge({
        isFetching: false,
      });
    case RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS:
      return state;
    case ACCOUNT_SAFE_NETWORK_SETTINGS_ERROR:
      return state.merge({
        hasError: true,
        isFetching: false,
      });
    default:
      return state;
  }
}
