import { Map } from 'immutable';
import { getProfile } from '../../../../selectors/profileRaw';
import {
  getScreenTimeMinutes,
  getTodayScreenTimeMinutes,
} from '../../../../selectors/summary';
import { getExtraTimeInMinutes } from '../../../../selectors/extraTime';
import {
  getProfileRules,
  getTimeQuotas,
  getTimeRanges,
} from '../../../../selectors/profileRules';

import {
  getKidDevices,
  getKidTamperedDevices,
  getProfileDevices,
} from '../../../../selectors/profileDevices';
import { getProfileId } from '../../../../selectors/profile';
import State from '../../../../store/state';
import {
  getAccountCurrentTime,
  getTimezone,
} from '../../../../selectors/account';
import {
  getAllRestrictedHours,
  localNow,
  Weekday,
  WEEKDAYS,
} from '../../../../helpers/dates';
import { isCalendarRestrictionPaused } from '../../../../selectors/stateSelectors/calendarRestrictionsPause';
import type { CardPauseStatusTracking } from '../../../../epics/tracking/types';

const hasTamperedDevices = (state: State, profileId: string) => {
  return (
    getProfileDevices(state, profileId).size ===
    getKidTamperedDevices(state, getProfileId(state)).length
  );
};

export const hasTimeRestriction = (state: State, profileId: string) => {
  const currentTime = getAccountCurrentTime(state);
  const currentWeekday = WEEKDAYS[currentTime.isoWeekday() - 1];

  const timeRestrictionsEnabled =
    getProfileRules(state, profileId)?.timeRestrictions?.isDailyLimit ?? false;
  const restrictedHours = getAllRestrictedHours({
    timeRanges:
      (getTimeRanges(state, profileId) as Map<Weekday, number>) || Map(),
    currentWeekday,
  });

  const restrictedTime =
    timeRestrictionsEnabled && restrictedHours.includes(currentTime.hour());

  return restrictedTime;
};

/**
 * Warning: this function return if has reached the screen time of the selected date,
 * if you want to ignore the selected date and get todays provide the third argument
 * ofCurrentDay
 */
export const hasScreenTimeReached = (
  state: State,
  profileId: string,
  ofCurrentDay = false
) => {
  const profile = getProfile(state, profileId);
  const deviceId = profile.deviceIds.first() || 0;
  const currentTime = getAccountCurrentTime(state);
  const currentWeekday = WEEKDAYS[currentTime.isoWeekday() - 1];

  const dailyLimitMinutes = (getTimeQuotas as any)(
    state,
    profileId,
    deviceId,
    currentWeekday
  );
  const spentMinutes = ofCurrentDay
    ? getTodayScreenTimeMinutes(state)
    : getScreenTimeMinutes(state);
  const extraTime = getExtraTimeInMinutes(state) ?? 0;

  const screenTimeReached = dailyLimitMinutes + extraTime - spentMinutes <= 0;

  return screenTimeReached;
};

export const isInternetPaused = (state: State) => {
  const timezone = getTimezone(state);
  const currentTime = localNow(timezone);
  return isCalendarRestrictionPaused(state, currentTime);
};

export const isPartialTampering = (state: State, profileId: string) => {
  const tampered = getKidTamperedDevices(state, profileId);
  const devices = getKidDevices(state, profileId);
  return tampered.length > 0 && tampered.length !== devices.length;
};

export const getNowTrackingCardStatus = (
  state: State,
  profileId: string
): CardPauseStatusTracking => {
  if (hasTamperedDevices(state, profileId)) return 'total_tampering';
  if (isInternetPaused(state)) return 'paused';
  if (isPartialTampering(state, profileId))
    return 'active_with_tampered_devices';
  if (hasTimeRestriction(state, profileId)) return 'locked_by_restricted_time';
  if (hasScreenTimeReached(state, profileId)) return 'locked_by_quota';
  if (getExtraTimeInMinutes(state) !== null) return 'active_with_extra_time';
  return 'active';
};
