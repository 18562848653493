import * as React from 'react';
import { Layout } from 'styleguide-react';
import SummaryAlertsBanner from '../../SummaryAlerts/SummaryAlertsBanner/SummaryAlertsBanner';
import { SummaryAlertsTopBarProps } from './types';

export const SummaryAlertsTopBar = ({
  alertsCount,
  onCloseSummaryAlerts,
  onReviewSummaryAlerts,
}: SummaryAlertsTopBarProps) => (
  <Layout width="100%" marginBottom="24">
    <SummaryAlertsBanner
      count={alertsCount}
      onClose={onCloseSummaryAlerts}
      onReview={onReviewSummaryAlerts}
    />
  </Layout>
);
