import { getLicense } from '../stateSelectors/license';
import { getCurrentTime } from '../stateSelectors/time';
import { getTimezone } from '../stateSelectors/account';

import type State from '../../store/state';

import {
  getLicenseDaysLeft,
  isPremium,
  isAutoRenewal,
} from '../../records/license';
import { isBrowserPlatform } from '../../helpers';

export const areUpsellProducts = (state: State) => {
  const license = getLicense(state);
  return (
    isBrowserPlatform() &&
    isPremium(license.type) &&
    (isAutoRenewal(license.renewal) ||
      getLicenseDaysLeft(license, getCurrentTime(state), getTimezone(state)) >
        30)
  );
};
