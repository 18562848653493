import * as React from 'react';
import { LinewizeEventFilter } from '../../../../ducks/linewizeEvent';
import TimelineState from './TimelineState';
import { IconFamily, IconType } from 'styleguide-react';
import { t } from '../../../../lib/i18n';

interface TimelineEmptyStateProps {
  filter: LinewizeEventFilter;
  profileName: string;
  isCombined: boolean;
  isDelegated: boolean;
}

const getEmptyAllText = (isCombined, isDelegated, profileName) => {
  if (isCombined && isDelegated) {
    return t(
      "{{profileName}} hasn't accessed anything during school hours yet",
      { profileName }
    );
  }
  return t('{{profileName}} hasn’t accessed anything yet', {
    profileName,
  });
};

const TimelineEmptyState: React.FC<TimelineEmptyStateProps> = ({
  filter,
  profileName,
  isCombined,
  isDelegated,
}) => {
  const emptyStateConfiguration = {
    searches: {
      iconType: IconType.search,
      text: t(
        'If your school has enabled timeline monitoring, you’ll see {{profileName}}’s {1}search activity{/1} here',
        {
          profileName,
          1: (str: string) => <strong>{str}</strong>,
        }
      ),
    },
    videos: {
      iconType: IconType.youtube,
      text: t(
        'If your school has enabled timeline monitoring, you’ll see {{profileName}}’s {1}video activity{/1} here',
        {
          profileName,
          1: (str: string) => <strong>{str}</strong>,
        }
      ),
    },
    web_apps: {
      iconType: IconType.globe,
      text: t(
        'If your school has enabled timeline monitoring, you’ll see {{profileName}}’s {1}website & app activity{/1} here',
        {
          profileName,
          1: (str: string) => <strong>{str}</strong>,
        }
      ),
    },
    blocked: {
      iconType: IconType.ban,
      text: t(
        'If your school has enabled timeline monitoring, you’ll see {{profileName}}’s attempts to access {1}blocked websites and apps{/1} here',
        {
          profileName,
          1: (str: string) => <strong>{str}</strong>,
        }
      ),
    },
    all: {
      iconType: IconType.chartBar,
      text: getEmptyAllText(isCombined, isDelegated, profileName),
    },
  };

  return (
    <TimelineState
      iconType={
        emptyStateConfiguration[filter]
          ? emptyStateConfiguration[filter].iconType
          : emptyStateConfiguration.all.iconType
      }
      iconFamily={filter === 'videos' ? IconFamily.brands : undefined}
      text={
        emptyStateConfiguration[filter]
          ? emptyStateConfiguration[filter].text
          : emptyStateConfiguration.all.text
      }
    />
  );
};

export default TimelineEmptyState;
