/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { mergeAll } from 'ramda';
import { createSelector as _createSelector, Selector } from 'reselect';
import { Selectors } from './types/Selector.types';

type CreateDISelector<S, Args> = (
  selectors: Partial<Selectors>[],
  combiner: Selector<S, Args>
) => ReturnType<typeof combiner>;

export const createDISelector: <S, Args>(
  selectorCreator
) => ReturnType<CreateDISelector<S, Args>> = (selectorCreator =>
  (selectorDependencies, createSelector) =>
    selectorCreator(selectorDependencies, createSelector)) as any;

type RegisterSelector = <T>(selectors: T) => T;

export const registerSelectors = ((...allDISelectors) => {
  const dISelectors = mergeAll(allDISelectors);
  const selectors = { isSelector: true };

  Object.entries<any>(dISelectors).forEach(entry => {
    const [key, di]: [string, Function] = entry;
    if (key in selectors) {
      throw new Error(`Selector ${key} already registered`);
    }
    selectors[key] = di(selectors, _createSelector);
  });

  return selectors;
}) as unknown as RegisterSelector;

export interface CreateSelector {
  <T>(selector: T): T;
  (
    selectors: Partial<Selectors>[],
    combiner: typeof _createSelector
  ): ReturnType<typeof combiner>;
}

export const createSelector: CreateSelector = (...params) => {
  let selector;
  return (selectorsOrState, ...createSelector) => {
    const isState = selectorsOrState && !selectorsOrState.isSelector;
    if (isState) {
      if (!selector) {
        selector =
          params.length > 1
            ? _createSelector(...(params as [any, any]))
            : (...args) => params[0](...args);
      }
      return selector(selectorsOrState, ...createSelector);
    }
    return createDISelector(() => params[0])(selectorsOrState, createSelector);
  };
};
