import { GlobalType } from 'styleguide-react';
import { ParColor } from '../../helpers/theme';
import { PieChartDataType } from './types';
import { minutesToHMFormat } from '../../helpers/dates';
import { t } from '../../lib/i18n';
import { SummaryDateRanges } from '../../constants';

export const hasRoutines = (screenTimeRoutines: Array<PieChartDataType>) =>
  screenTimeRoutines.length > 0;

export const getDailyTimeLimitsFormattedTime = (
  dailyTimeLimits: number | null,
  extraTime: number | null
) => {
  if (dailyTimeLimits && extraTime) {
    const formattedLimits = minutesToHMFormat(dailyTimeLimits);
    const formattedExtratime = minutesToHMFormat(extraTime);
    return `${formattedLimits} + ${formattedExtratime}`;
  }

  if (dailyTimeLimits) {
    return minutesToHMFormat(dailyTimeLimits);
  }

  throw new Error('Invalid params');
};

export const getRemainingSegmentColor = (
  hasRoutinesScreenTime: boolean,
  isForSeveralDays: boolean,
  hasAvailableScreenTime: boolean,
  reportedDays: SummaryDateRanges,
  hasScreenTime: boolean
) => {
  if (
    reportedDays === SummaryDateRanges.CustomPastDay &&
    hasAvailableScreenTime
  ) {
    return ParColor.secondaryLighter;
  }

  if (!hasRoutinesScreenTime) {
    if (isForSeveralDays && hasScreenTime) return GlobalType.secondary;
    if (!isForSeveralDays && !hasAvailableScreenTime)
      return GlobalType.decorationOrange;
  }

  return ParColor.secondaryLighter;
};

export const getLabel = (
  label: string,
  isForSeveralDays: boolean,
  hasAvailableScreenTime: boolean,
  reportedDays: SummaryDateRanges
) => {
  if (isForSeveralDays || reportedDays === SummaryDateRanges.CustomPastDay)
    return label;
  if (!hasAvailableScreenTime) return t(`Time's up!`);
  return label;
};
