import * as React from 'react';
import { ContentSeparator } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { CallsSMSRulesProps } from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import LabeledSwitch from './LabeledSwitch';

type CallsSMSActivateSwitchProps = Pick<
  CallsSMSRulesProps,
  'onToggleCallsSMSRulesEnabled' | 'callsSMSRulesEnabled'
> & {
  deviceNames: string;
};
const CallsSMSActivateSwitch: React.FC<CallsSMSActivateSwitchProps> = ({
  deviceNames,
  onToggleCallsSMSRulesEnabled,
  callsSMSRulesEnabled,
}: CallsSMSActivateSwitchProps) => (
  <div className="calls-and-messages-activate-switch">
    {LabeledSwitch({
      label: t('Calls & messages monitoring'),
      checked: callsSMSRulesEnabled,
      id: 'calls-and-messages-activate-switch',
      onChange: ev => {
        onToggleCallsSMSRulesEnabled((ev.target as HTMLInputElement).checked);
      },
      subtitle: t('You’re monitoring {{deviceName}}', {
        deviceName: deviceNames,
      }),
    })}
    <ContentSeparator />
  </div>
);

export default CallsSMSActivateSwitch;
