import * as React from 'react';
import { Link } from 'react-router';
import { OrderedMap } from 'immutable';
import {
  List as RegularStyleList,
  RegularStyleListItem,
  InstructionBlock,
  ClickableListItem,
  ListTitle,
  FlexLayout,
  EmptyPlaceholder,
  IconType,
  Icon,
  MoreActionsButton,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import DropDownActionSheet from '../DropDown/DropDownActionSheet';
import WebFiltersMapToIcon from './WebFiltersMapToIcon';

interface WebFiltersExceptionsProps {
  domains: OrderedMap<string, string>;
  editDomainException?: string;
  profileId: string;
  onClickDomainException: (domain: string) => void;
}

interface DropDownActionElementProps {
  renderDropDownActionSheet: boolean;
  domain: string;
  onClick: () => void;
}

/**
 * We use this function to fix the following problem: PAR-5074
 *
 * When we have a very large list, the user interaction becomes extremly laggy.
 * This is due to the fact that DropDown component (Rocket) re-calculates it's position every time a scroll event is emitted,
 * even when the DropDown sheet is not visible.
 *
 * To aliviate that issue what has been done is to:
 * - Show a simple "more" button (MoreActionsButton) by default.
 * - onClick, check what domain needs to be edited via (state.profileRules.editDomainException).
 * - Hide the "more" button in the list item and render the DropDown for the domain to be edited.
 * - When the DropDown is closed (it clears the state) and we show the "more" button back again.
 *
 * With this change, when the list is in idle no DropDown is rendered hence avoiding lag form unnecessary internal updates.
 */
const DropDownActionElement = ({
  renderDropDownActionSheet,
  domain,
  onClick,
}: DropDownActionElementProps) =>
  !renderDropDownActionSheet ? (
    <MoreActionsButton onClick={onClick} testId="more-actions-button" />
  ) : (
    <DropDownActionSheet selectedActionSheetKey={domain} title={domain} />
  );

const WebFiltersExceptions: React.FunctionComponent<
  WebFiltersExceptionsProps
> = ({
  domains,
  profileId,
  editDomainException,
  onClickDomainException,
}: WebFiltersExceptionsProps) => {
  const onClickItem = (domain: string) => () =>
    setTimeout(() => onClickDomainException(domain), 50);
  return (
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="row" marginTop="24" marginBottom="8">
        <InstructionBlock>
          {t(
            'Override category rules for a specific website you wish to monitor, block, always allow or ignore.'
          )}
          &nbsp;
          <Link
            to={`/profiles/${profileId}/rules/webFilters/modal/WebFiltersLegendModal`}
          >
            <Icon type={IconType.questionCircle} />
          </Link>
        </InstructionBlock>
      </FlexLayout>

      {domains && domains.size > 0 ? (
        <FlexLayout mainaxis="column" marginBottom="24">
          <RegularStyleList
            listItemMarginTop="8"
            listItemPaddingTop="8"
            listItemMarginBottom="8"
            listItemPaddingBottom="8"
          >
            {domains.entrySeq().map(([domain, action]) => (
              <RegularStyleListItem
                key={domain}
                listItemMarginBottom="small"
                icon={<WebFiltersMapToIcon action={action} />}
                title={
                  <React.Fragment>
                    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
                      <ClickableListItem onClick={onClickItem(domain)}>
                        <ListTitle isBold={false}>{domain}</ListTitle>
                      </ClickableListItem>
                    </RenderWhen>
                    <RenderWhen screenSize={ScreenSize.Desktop}>
                      <ListTitle isBold={false}>{domain}</ListTitle>
                    </RenderWhen>
                  </React.Fragment>
                }
                actionElement={
                  <DropDownActionElement
                    domain={domain}
                    onClick={onClickItem(domain)}
                    renderDropDownActionSheet={editDomainException === domain}
                  />
                }
              />
            ))}
          </RegularStyleList>
        </FlexLayout>
      ) : (
        <FlexLayout mainaxis="column" marginTop="56">
          <EmptyPlaceholder text={t('No exception created')} />
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default WebFiltersExceptions;
