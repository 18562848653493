import { camelCaseToSnakeCase } from '../../api/mappings/case';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { StudentDelegationPayload } from '../../records/studentRules/types/studentDelegation.type';
import { getStudentDelegation } from '../../selectors/studentPolicies';
import State, { BaseThunk, Dispatch } from '../../store/state';
import {
  fetchStudentPolicies,
  requestStudentPolicies,
  updateDelegationPolicies,
} from '../studentPolicies';
import { updateDelegationRule } from './delegation/thunk';

const normalizeProfileDelegation = (
  profileDelegationPolicies
): StudentDelegationPayload => {
  return camelCaseToSnakeCase(profileDelegationPolicies);
};

export const toggleProfileDelegation =
  (profileId: number, enabled: boolean): BaseThunk<any> =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const oldStudentDelegationPolicies = getStudentDelegation(state).toJS();
    const modStudentDelegationPolicies = normalizeProfileDelegation({
      delegation: {
        ...oldStudentDelegationPolicies,
        optedIn: enabled,
        optedInByThisAccount: enabled,
      },
    });
    dispatch(updateDelegationPolicies(modStudentDelegationPolicies));
    dispatch(requestStudentPolicies());
    try {
      await dispatch(updateDelegationRule(profileId, enabled));
      await dispatch(fetchStudentPolicies(profileId, true));
      trackButtonClicked(
        GenericPageNames.ProfileRules,
        ButtonNames.toggleProfileDelegation,
        { options: JSON.stringify({ enabled }) }
      );
    } catch (e) {
      dispatch(
        updateDelegationPolicies(
          normalizeProfileDelegation({
            delegation: oldStudentDelegationPolicies,
          })
        )
      );
      throw e;
    }
  };
