import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import HowToAssignUsersModal from '../../components/Modal/HowToAssignUsersModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const HowToAssignUsersModalContainer = connect(
  null,
  mapDispatchToProps
)(HowToAssignUsersModal);

export default HowToAssignUsersModalContainer;
