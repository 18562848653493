import * as React from 'react';
import UnifiedNowCardWrapper from '../cardComponents/UnifiedNowCardWrapper';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';
import Loader from '../../../base/Loader';
import { FlexLayout } from 'styleguide-react';
import LoadingPlaceholder from '../../../LoadingPlaceholder/LoadingPlaceholder';

const UnifiedNowCardLoadingStatus = () => {
  return (
    <UnifiedNowCardWrapper>
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        <span data-testid="Loader__unified-now-card-loading">
          <Loader size="small" color="secondary" />
        </span>
      </RenderWhen>
      <FlexLayout mainaxis="column" width="50%" marginLeft="16">
        <LoadingPlaceholder animated testId="unified-now-card-loading" />
        <LoadingPlaceholder animated testId="unified-now-card-loading" />
      </FlexLayout>
    </UnifiedNowCardWrapper>
  );
};

export default UnifiedNowCardLoadingStatus;
