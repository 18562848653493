import {
  getLicense,
  getPaymentProvider,
  isPurchaseFlow,
  isPurchaseInProgress,
} from '../selectors/index';
import { LicenseRecord, isPremium } from '../records/license';
import { ProductDuration } from '../records/product';
import * as inAppPurchaseProvider from '../helpers/purchases/inAppPurchaseProvider';
import * as WebPurchaseProvider from '../helpers/purchases/webPurchaseProvider';
import { requiresInAppPurchasesPlugin } from '../helpers/purchase-provider';
import { fetchLicense } from '../ducks/license';
import { BaseThunk } from '../store/state';
import { isBrowserPlatform } from '../helpers';
import { trackPurchaseSucceed } from '../helpers/analytics';
import {
  SET_CONTENT_HIDDEN,
  START_LOADING_INITIAL_DATA,
  IS_NAVIGATING_TO_PURCHASE_PRODUCT,
} from '../ducks/actionNames/app';
import {
  finishPurchaseProcess,
  startPurchaseProcess,
  productChanged,
} from '../ducks/products/actions';
import {
  getMultiPlatformNavigation,
  RouterAction,
} from '../helpers/multiPlatformNavigation';
import { captureException } from '../helpers/sentry';

export const clickProduct =
  (productId: string): BaseThunk<void | Promise<void>> =>
  (dispatch, getState) => {
    const state = getState();
    const navigate = getMultiPlatformNavigation();
    if (isPurchaseInProgress(state)) {
      return Promise.resolve(undefined);
    }

    const paymentProvider = getPaymentProvider(state);

    dispatch(setIsNavigatingToPurchaseProduct(true));

    const oldLicense = getLicense(state);
    return dispatch(buyProduct(productId))
      .then(() => {
        const currentLicense = getLicense(getState());
        if (!isPremium(currentLicense.type)) {
          return Promise.resolve(undefined);
        }
        trackPurchaseSucceed(paymentProvider, productId);

        return dispatch(
          navigate({
            type: 'inner:replace',
            src: selectWelcomeModal(oldLicense),
          })
        );
      })
      .catch(error => {
        captureException(error);
        return Promise.resolve(undefined);
      });
  };

export const shouldShowTwoYearsPromotionModal = (
  productDuration: ProductDuration,
  isPremium: boolean,
  areTwoYearProductsAvailable: boolean,
  areUpsellProducts: boolean
) =>
  productDuration === ProductDuration.OneYear &&
  !isPremium &&
  isBrowserPlatform() &&
  areTwoYearProductsAvailable &&
  !areUpsellProducts;

export const showTwoYearsPromotionModal =
  (productId: string): BaseThunk<RouterAction> =>
  dispatch => {
    dispatch(productChanged(productId));
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner',
        src: {
          pathname: `upgrade/promotion/${productId}/modal/TwoYearsPromotionModal`,
        },
      })
    );
  };

const buyProduct =
  (productId: string): BaseThunk<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState();
    const resolveBuyProduct = async () => {
      await dispatch(fetchLicense());
      dispatch(finishPurchaseProcess());
    };
    const rejectBuyProduct = err => {
      dispatch(finishPurchaseProcess());
      throw err;
    };
    dispatch(startPurchaseProcess());
    if (requiresInAppPurchasesPlugin(getPaymentProvider(state))) {
      return dispatch(inAppPurchaseProvider.buyProduct(productId))
        .then(resolveBuyProduct)
        .catch(rejectBuyProduct);
    }
    return dispatch(
      WebPurchaseProvider.buyProduct(productId, getLicense(state))
    )
      .then(resolveBuyProduct)
      .catch(rejectBuyProduct);
  };

const selectWelcomeModal = (oldLicense: LicenseRecord): string => {
  /**
   * TODO: this is dangerous and it is working because after purchasing an
   * upsell we are not coming back to the upgrade page, otherwise we would be
   * showing the renewal modal to those users.
   *  */
  return isPremium(oldLicense.type)
    ? '/upgrade/modal/RenewalModal'
    : '/upgrade/modal/WelcomeToPremiumModal';
};

export const clickModalButton = (): BaseThunk<void> => (dispatch, getState) => {
  const navigate = getMultiPlatformNavigation();
  if (isPurchaseFlow(getState())) {
    dispatch(returnToPreviousPage());
  } else {
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  }
};

export const clickPremiumFeatures = (): BaseThunk<void> => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(
    navigate({
      type: 'inner',
      src: '/upgrade/premium-features',
    })
  );
};

/**
 * Exit by navigating backwards to the las history entry before the first PAR entry.
 */
export const returnToPreviousPage =
  (): BaseThunk<void> => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    dispatch({ type: SET_CONTENT_HIDDEN, payload: true });
    dispatch({ type: START_LOADING_INITIAL_DATA });
    const steps = getState().get('routing').get('historyKeys').count();
    dispatch(navigate({ type: 'inner:go', src: -steps }));
  };

export function setIsNavigatingToPurchaseProduct(payload: boolean) {
  return {
    type: IS_NAVIGATING_TO_PURCHASE_PRODUCT,
    payload,
  };
}
