import * as React from 'react';
import { Card, FlexLayout, StyledHeader } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SignatureReview } from '../../../records/studentActivity/signature/types/SignatureReview.types';

type SignatureDescriptionCardProps = Pick<SignatureReview, 'review'>;

const SignatureDescriptionCard: React.FunctionComponent<
  SignatureDescriptionCardProps
> = ({ review }: SignatureDescriptionCardProps) => (
  <Card fullWidth className="SignatureDescriptionCard">
    <FlexLayout mainaxis="column">
      <StyledHeader type="h5">{t('Description')}</StyledHeader>
      <p>{review}</p>
    </FlexLayout>
  </Card>
);

export default SignatureDescriptionCard;
