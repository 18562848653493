import * as React from 'react';
import {
  Modal,
  Icon,
  IconType,
  Button,
  ButtonSize,
  IconColor,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { PlaceRecord } from '../../records';

const RemovePlaceModal: React.FunctionComponent<{
  place: PlaceRecord;
  onClickClose: () => void;
  onRemovalConfirm: (uid: string) => void;
}> = ({ place, onClickClose, onRemovalConfirm }) => {
  return (
    <Modal
      size="small"
      onClickClose={onClickClose}
      className="RemovePlace"
      header={{
        icon: <Icon type={IconType.trashAlt} color={IconColor.error} />,
      }}
      title={t('Are you sure you want to remove {{placeName}}?', {
        placeName: place.name,
      })}
      buttons={[
        <Button
          key="button"
          onClick={() => onRemovalConfirm(place.uid)}
          block
          icon={<Icon type={IconType.smile} />}
          size={ButtonSize.medium}
        >
          {t('Remove')}
        </Button>,
      ]}
    >
      <Typography type="body1">
        {t('You will no longer receive notifications about this place.')}
      </Typography>
    </Modal>
  );
};

export default RemovePlaceModal;
