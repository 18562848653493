import * as React from 'react';
import { Icon, IconColor, IconType, TransparentButton } from 'styleguide-react';
import { t } from '../../lib/i18n';
import type { EventRecord, LicenseRecord } from '../../records';
import { EventType, SearchEngine } from '../../constants';
import { shouldBlockThisFeature } from '../../helpers/premiumFeatures';

type TimelineType = 'family' | 'school';
export interface TimelineSubtitleProps {
  isUnifiedProfile: boolean;
  isDelegatedToThisAccount: boolean;
  timelineType: TimelineType;
  onDelegationInfoClick: () => void;
}

export const getTimelineSubtitle = ({
  isUnifiedProfile,
  isDelegatedToThisAccount,
  timelineType,
  onDelegationInfoClick,
}: TimelineSubtitleProps) => {
  const detailedHistorySubtitle = t(
    'See a detailed history of your child’s activity.'
  );

  const schoolAndFamilySubtitle = t(
    'See your child’s activity on personal and school-issued devices.'
  );

  if (isUnifiedProfile && isDelegatedToThisAccount) {
    return (
      <span>
        {schoolAndFamilySubtitle}
        <TransparentButton
          className="par-activity-timeline__subtitle-info-icon"
          onClick={onDelegationInfoClick}
          testId="activity-timeline-subtitle-info-button"
        >
          <Icon type={IconType.infoCircle} color={IconColor.secondary} />
        </TransparentButton>
      </span>
    );
  }

  if (isUnifiedProfile) return schoolAndFamilySubtitle;

  if (isDelegatedToThisAccount) return detailedHistorySubtitle;

  return timelineType === 'school' ? detailedHistorySubtitle : undefined;
};

export const isEventYoutubeSearchEngine = (event: EventRecord) =>
  event?.searchEngine === SearchEngine.Youtube;

/**
 * Determines whether an upgrade is required for YouTube event based on the provided license and event.
 *
 * This function takes into consideration the following `EventType`:
 * - `Youtube`: for youtube web activity event (youtube history)
 * - `Search`: for searches made in the youtube website
 *
 * @note It does not take into consideration the Youtube `App` events
 */
export const isEventPremiumYoutube = (
  license: LicenseRecord,
  event: EventRecord,
  isDelegatedWithPCDevices: boolean
): boolean => {
  const needsUpgrade =
    shouldBlockThisFeature('youtubeRules', license.subtype) &&
    !isDelegatedWithPCDevices;

  if (!needsUpgrade) return false;

  switch (event.type) {
    case EventType.Youtube:
      return true;
    case EventType.Search:
      return isEventYoutubeSearchEngine(event);
    default:
      return false;
  }
};
