import { getBuildPlatform } from '../../globals';
import { BuildPlatform } from '../../../constants';
import * as qinit from '../../../qinit';

export const getInAppPurchasePlatform = () => {
  const { Platform } = global.CdvPurchase;

  const platform = getBuildPlatform();
  if (platform === BuildPlatform.ios) {
    return Platform.APPLE_APPSTORE;
  }

  if (platform === BuildPlatform.android) {
    return Platform.GOOGLE_PLAY;
  }

  throw new Error(
    '[getInAppPurchasePlatform] Build platform provided is not supported. You must not use in app purchase provider.'
  );
};

export const logPurchase = (message: string, metadata: unknown) => {
  if (qinit.verbose) {
    // eslint-disable-next-line no-console
    console.log(`[Purchase] ${message}`, metadata);
  }
};

export const getNativeTransaction = (
  transaction: CdvPurchase.Validator.Request.ApiValidatorBodyTransaction
): CdvPurchase.Validator.Response.NativeTransaction => {
  const purchasePlatform = getInAppPurchasePlatform();
  if (purchasePlatform === CdvPurchase.Platform.GOOGLE_PLAY) {
    const transactionGoogle =
      transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle;
    if (transactionGoogle.receipt === undefined) {
      throw new Error(
        '[getNativeTransaction] transaction.receipt is undefined'
      );
    }

    return JSON.parse(transactionGoogle.receipt);
  }
  if (purchasePlatform === CdvPurchase.Platform.APPLE_APPSTORE) {
    const transactionApple =
      transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple;
    if (transactionApple.appStoreReceipt === undefined) {
      throw new Error(
        '[getNativeTransaction] transaction.appStoreReceipt is undefined'
      );
    }

    // appStoreReceipt is a Base64 encode object from Apple Store provider
    try {
      return JSON.parse(window.atob(transactionApple.appStoreReceipt));
    } catch (e) {
      // appStoreReceipt is a file in base64
      return [window.atob(transactionApple.appStoreReceipt)] as any;
    }
  }
  throw new Error('[getNativeTransaction] Platform provided is not supported.');
};

export const getTransactionFromPlatform = (
  transaction?: CdvPurchase.Validator.Request.ApiValidatorBodyTransaction
):
  | CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle
  | CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple => {
  if (transaction === undefined) {
    throw new Error('[getTransactionFromPlatform] transaction is undefined');
  }
  const purchasePlatform = getInAppPurchasePlatform();
  if (purchasePlatform === CdvPurchase.Platform.GOOGLE_PLAY) {
    return transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle;
  }
  return transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple;
};
