import * as React from 'react';
import { t } from '../../lib/i18n';
import { Banner, Svg } from 'styleguide-react';
import LogoIcon from '../Icons/v3/LogoIcon';

const SmartAppBanner: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  onNavigate: () => any;
}> = ({ onClose, onNavigate, isVisible }) =>
  isVisible ? (
    <Banner
      icon={
        <Svg height="40" width="40">
          <LogoIcon />
        </Svg>
      }
      fullWidth
      showCloseIcon
      onClose={onClose}
      type="white"
      className="SmartAppBanner"
    >
      {t('Download {1}{{shortName}}{/1} App', {
        1: str => (
          <a data-testid="SmartAppBanner_download_link" onClick={onNavigate}>
            {str}
          </a>
        ),
      })}
      <br />
      {t(
        'Supervise your family on your device. Being a digital parent has never been easier.'
      )}
    </Banner>
  ) : null;

export default SmartAppBanner;
