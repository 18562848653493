import { Map, fromJS } from 'immutable';
import api from '../api';
import { getAccountId, getExperiment, isNewAccount } from '../selectors';
import { filterNulls } from '../helpers';
import { splitOnLast } from '../helpers/string';
import { gaEvent } from '../helpers/ga';
import { ExperimentRecord } from '../records/experiment';
import State, { BaseThunk } from '../store/state';

export type ExperimentNames = 'app_onboarding';

export const APP_ONBOARDING_EXPERIMENT: ExperimentNames = 'app_onboarding';

export const REQUEST_EXPERIMENTS = 'REQUEST_EXPERIMENTS';
export const RECEIVE_EXPERIMENTS = 'RECEIVE_EXPERIMENTS';

export const currentExperiments: {
  [index: string]: {
    isElegible: (state: State, experiment: ExperimentRecord) => boolean;
  };
} = {
  [APP_ONBOARDING_EXPERIMENT]: {
    isElegible: state => isNewAccount(state),
  },
};

export default function experiments(
  state: Map<ExperimentNames, ExperimentRecord | undefined> = fromJS({}),
  action
) {
  switch (action.type) {
    case RECEIVE_EXPERIMENTS:
      return state.merge(action.payload);
    default:
      return state;
  }
}

export const receiveExperiments = (
  experiments: Map<ExperimentNames, ExperimentRecord>
) => {
  return {
    type: RECEIVE_EXPERIMENTS,
    payload: experiments,
  };
};

const isInBucket = ({ buckets, bucket_count }, accountId) => {
  const bucket = accountId % bucket_count;
  return buckets.indexOf(bucket) > -1;
};

const getSingleExperimentWithResult = (
  experiment,
  accountId
): ExperimentRecord | null => {
  const { code } = experiment;
  const [name, result] = splitOnLast(code, '_');
  const startDate = experiment.start_date;

  return isInBucket(experiment, accountId) && currentExperiments[name]
    ? ExperimentRecord.fromPayload({ name, result, startDate })
    : null;
};

const getExperimentsWithResults = (
  payload: any[],
  accountId
): ExperimentRecord[] =>
  filterNulls(
    payload.map(experiment =>
      getSingleExperimentWithResult(experiment, accountId)
    )
  );

const getAccountOptions = (accountOptionsResponse: any) =>
  accountOptionsResponse.reduce((options, currentOption) => {
    return {
      ...options,
      [currentOption.key.replace('_result', '')]: currentOption.value,
    };
  }, {});

const reportExperiments = (experiments: ExperimentRecord[]) => {
  if (experiments.length > 0) {
    const experimentsToReport = experiments.reduce(
      (parsedExperiments, currentExperiment) => {
        return {
          ...parsedExperiments,
          [`${currentExperiment.name}_result`]: currentExperiment.result,
        };
      },
      {}
    );

    api.options.post(experimentsToReport);
  }
};

export const fetchExperiments =
  (): BaseThunk<Promise<any>> => (dispatch, getState) =>
    Promise.all([api.experiments.get({}), api.options.get({})]).then(
      ([experimentsResponse, accountOptionsResponse]) => {
        const state = getState();

        const experimentsWithResults = getExperimentsWithResults(
          experimentsResponse,
          getAccountId(state)
        );
        const reportedExperiments = getAccountOptions(accountOptionsResponse);
        const reportedExperimentsNames: string[] =
          Object.keys(reportedExperiments);

        // Valid experiments
        //  - previously reported
        //  - not previously reported and elegible
        const validExperiments = experimentsWithResults.filter(experiment => {
          const { name } = experiment;
          const isExperimentAlreadyReported =
            reportedExperimentsNames.indexOf(name) >= 0;
          return (
            isExperimentAlreadyReported ||
            currentExperiments[name].isElegible(state, experiment)
          );
        });

        // Experiments to report
        //  - not previously reported
        //  - previously reported and result changed
        const experimentsToReport = validExperiments.filter(
          ({ name, result }) => {
            const isExperimentAlreadyReported =
              reportedExperimentsNames.indexOf(name) >= 0;
            return (
              !isExperimentAlreadyReported ||
              result !== reportedExperiments[name]
            );
          }
        );

        const experimentsToSave = validExperiments.reduce(
          (experiments, currentExperiment) => {
            return currentExperiment
              ? { ...experiments, [currentExperiment.name]: currentExperiment }
              : experiments;
          },
          {}
        );

        reportExperiments(experimentsToReport);
        dispatch(
          receiveExperiments(
            Map<ExperimentNames, ExperimentRecord>(experimentsToSave)
          )
        );
      }
    );

export const gaEventIfExperimentIsAssigned =
  (
    experimentName: ExperimentNames,
    gaCategory: string,
    gaAction: string
  ): BaseThunk<void> =>
  // eslint-disable-next-line no-underscore-dangle
  (_dispatch, getState) => {
    const experiment = getExperiment(getState(), experimentName);
    if (experiment !== undefined) {
      gaEvent(gaCategory, gaAction, experiment.result);
    }
  };
