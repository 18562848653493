import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { LinewizeEventAction } from '../../../businessLogic/LinewizeEvents/LinewizeEvent.types';
import EventsDate from './EventsDate';
import { infiniteScroll } from '../../../helpers/hocs';
import { LinewizeEventRecord } from '../../../records/linewizeEvent/types/linewizeEvents';
import { groupLinewizeEventsByDateAndType } from '../../../businessLogic/LinewizeEvents/LinewizeEvent';
import {
  LinewizeEventFilter,
  LinewizeEventStatus,
} from '../../../ducks/linewizeEvent';
import ChildUnlinkedState from './States/ChildUnlinkedState';
import DisabledTimelineState from './States/DisabledTimelineState';
import TimelineEmptyState from './States/TimelineEmptyState';
import TimelineErrorState from './States/TimelineErrorState';
import Loader from '../../base/Loader';

export interface SchoolTimelineProps {
  events: ReturnType<typeof groupLinewizeEventsByDateAndType>;
  isFetching: boolean;
  hasMore: boolean;
  profileName: string;
  currentFilter: LinewizeEventFilter;
  timeLineStatus: LinewizeEventStatus;
  accountLocale: string;
  accountTimezone: string;
  isCombined: boolean;
  isDelegated: boolean;
  onLoad: () => void;
  handleMore: () => void;
  onActionClick: (
    event: LinewizeEventRecord,
    action: LinewizeEventAction
  ) => void;
}

const SchoolTimeline: React.FC<SchoolTimelineProps> = ({
  events,
  profileName,
  currentFilter,
  timeLineStatus,
  accountLocale,
  accountTimezone,
  isCombined,
  isDelegated,
  onLoad,
  onActionClick,
}) => {
  React.useEffect(() => {
    onLoad();
  }, []);
  if (timeLineStatus === 'CHILD_UNLINKED') {
    return <ChildUnlinkedState profileName={profileName} />;
  }

  if (timeLineStatus === 'REPORT_DISABLED') {
    return <DisabledTimelineState profileName={profileName} />;
  }

  if (timeLineStatus === 'NO_ACTIVITY') {
    return (
      <TimelineEmptyState
        profileName={profileName}
        filter={currentFilter}
        isCombined={isCombined}
        isDelegated={isDelegated}
      />
    );
  }

  if (timeLineStatus === 'ERROR') {
    return <TimelineErrorState />;
  }

  return (
    <FlexLayout
      mainaxis="column"
      scrollY
      mainaxisAlignment="start"
      className="par-school-timeline__container"
    >
      {events &&
        events.map(event => (
          <EventsDate
            key={event.date}
            eventsByDate={event}
            onActionClick={onActionClick}
            accountLocale={accountLocale}
            accountTimezone={accountTimezone}
          />
        ))}
      {timeLineStatus === 'LOADING' && (
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          padding="24"
          testId="school-timeline-loader"
        >
          <Loader color="secondary" />
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default infiniteScroll<SchoolTimelineProps>(SchoolTimeline);
