import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import {
  getActiveProfileUid,
  getProfilesMap,
  getTimezone,
} from '../../selectors';
import RoutineSwitchModal, {
  RoutineSwitchModalProps,
  SwitchRoutineChoice,
  SwitchRoutineFnParams,
} from '../../components/Modal/RoutineSwitchModal';
import { navigateToRoutines } from '../../actions/RoutinesActions';
import RoutineSwitchInfoModal, {
  RoutineSwitchInfoModalProps,
} from '../../components/Modal/RoutineSwitchInfoModal';
import {
  getAllUnPausedRoutinesSchedules,
  getAllRoutinesList,
  isRoutinesFeatureEnabled,
} from '../../ducks/routines/selectors';
import {
  getNextScheduleEventFromTime,
  getRoutineFeatureAccessLevel,
} from '../../businessLogic/routines/routines';
import {
  MINS_IN_A_DAY,
  addToMoment,
  formatDayToAPI,
  formatHours,
  getDayOfWeekConverted,
  getTimeMinutesOfWeek,
  localNow,
} from '../../helpers/dates';
import { ScheduleOperations } from '../../records/routines/schedule/schedule';
import { Datetime } from '../../types/dates';
import { List } from 'immutable';
import {
  SCHEDULE_WEEKDAYS,
  ScheduleRecord,
} from '../../records/routines/schedule/types/Schedule.types';
import { scheduleRecordToPayload } from '../../ducks/routines/transformations/scheduleContextToPayload';
import { createRoutineSchedules } from '../../ducks/routines';
import { TOAST_ICON_TICK, showToast } from '../../ducks/toast';
import { t } from '../../lib/i18n';
import { trackQuickActionClick } from '../../ducks/unifiedNowCard/thunks';
import { ButtonNames } from '../../helpers/analytics';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { RoutineFeatureAccessLevel } from '../../components/Routines/routines.types';
import { isRoutineTypeBlocked } from '../../ducks/routines/helpers';
import { RoutineRecord } from '../../records/routines/types/Routine.types';
import { APPThunk } from '../../helpers/thunks';
import flags from '../../sideEffects/flags';
import { getUnifiedNowCardStatus } from '../../selectors/unifiedNowCard';
import { updateProfileActiveRoutineAction } from '../../ducks/profiles';
import { Action } from 'redux';
import { getHumanFormattedTime } from '../../selectors/entitySelectors/time';
import { getFeatureByCode } from '../../ducks/features';

const getCurrentScheduleWeekDay = (time: Datetime, timezone: string) => {
  const weekdayIndex = getDayOfWeekConverted(time, timezone);
  const weekday = SCHEDULE_WEEKDAYS[weekdayIndex];
  return weekday;
};

const isTimeMoreThan24hAway = (minutesStart: number, minutesEnd: number) => {
  const timeDiff = minutesEnd - minutesStart;
  const isMoreThan24hAway = timeDiff > MINS_IN_A_DAY;
  return { isMoreThan24hAway, timeDiff };
};

const getMinutesFromCurrentTimeToNextEvent = (
  currentTime: Datetime,
  timezone: string,
  schedules: List<ScheduleRecord>
) => {
  const nextEvent = getNextScheduleEventFromTime(
    currentTime,
    timezone,
    schedules
  );

  if (!nextEvent) return undefined;

  const currentTimeMinutesOfWeek = getTimeMinutesOfWeek(currentTime, timezone);
  const { isMoreThan24hAway, timeDiff } = isTimeMoreThan24hAway(
    currentTimeMinutesOfWeek,
    nextEvent.start
  );

  if (isMoreThan24hAway) return undefined;
  return timeDiff;
};

export const createSchedulePayload = (
  currentTime: Datetime,
  timezone: string,
  duration: number,
  endDate: Datetime
) => {
  const currentWeekday = getCurrentScheduleWeekDay(currentTime, timezone);
  return scheduleRecordToPayload(
    ScheduleOperations.create({
      overrides: true,
      durationMinutes: duration,
      weekdays: [currentWeekday],
      startTime: formatHours(currentTime),
      fromDate: formatDayToAPI(currentTime),
      toDate: formatDayToAPI(endDate),
    })
  );
};

/**
 * - Filter out all routines when: license has "guest" access
 * - Filter out custom_filtering routines when: license has "routineBlocking" access
 */
const filterRoutinesByLicense =
  (routinesAccess: RoutineFeatureAccessLevel) => (routines: RoutineRecord) =>
    !(
      routinesAccess === RoutineFeatureAccessLevel.guest ||
      (routinesAccess === RoutineFeatureAccessLevel.routineBlocking &&
        !isRoutineTypeBlocked(routines))
    );

const trackSwitchRoutine = (
  endTime: Datetime,
  duration: number,
  choice: SwitchRoutineChoice,
  timezone: string
) =>
  trackQuickActionClick(ButtonNames.ScheduleSwitchRoutine, {
    end_switch_time_stamp: endTime.unix().toString(),
    user_local_timezone: timezone,
    duration_in_minutes: duration.toString(),
    choice,
  });

export const onSwitchRoutineSuccessActions =
  (
    profilesMap: ReturnType<typeof getProfilesMap>,
    formattedEndTime: string,
    { profileId, routine }: SwitchRoutineFnParams
  ) =>
  (): Action[] => {
    const toastAction = showToast(
      [
        t('Routine switched!'),
        t('{{routineName}} is now active until {{time}}', {
          routineName: routine.name,
          time: formattedEndTime,
        }),
      ],
      TOAST_ICON_TICK
    );

    // Manually update the current profile active routine when "switching routine" in the now card.
    // Since the api call did not fail, we can be sure that the routine was switched correctly in the backend.
    const updateActiveRoutineAction = updateProfileActiveRoutineAction(
      profilesMap,
      profileId,
      routine.uid
    );

    return [updateActiveRoutineAction, toastAction];
  };

export const onSwitchRoutineThunk =
  (options: SwitchRoutineFnParams): APPThunk =>
  (dispatch, getState) => {
    const { currentTime, profileUid, routine, choice, duration, timezone } =
      options;

    const state = getState();
    const profilesMap = getProfilesMap(state);
    const endTime = addToMoment(duration, 'minutes', currentTime);
    const formattedEndTime = getHumanFormattedTime(state, formatHours(endTime));

    const schedulePayload = createSchedulePayload(
      currentTime,
      timezone,
      duration,
      endTime
    );

    dispatch(
      createRoutineSchedules(
        profileUid,
        routine.uid,
        schedulePayload,
        onSwitchRoutineSuccessActions(profilesMap, formattedEndTime, options)
      )
    );

    dispatch(trackSwitchRoutine(endTime, duration, choice, timezone));
    dispatch(goBackIfHistory());
  };

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const licenseSubtype = getLicenseSubtype(state);
  const timezone = getTimezone(state) as string;
  const currentTime = localNow(timezone);
  const routineContentFilteringFeature = getFeatureByCode(
    state,
    'routine_content_filtering'
  );
  const routineBlockFeature = getFeatureByCode(state, 'routines_block');
  const routinesAccess = getRoutineFeatureAccessLevel(
    licenseSubtype,
    routineContentFilteringFeature,
    routineBlockFeature
  );
  const routinesList = getAllRoutinesList(state).filter(
    filterRoutinesByLicense(routinesAccess)
  );
  const routinesFeatureEnabled = isRoutinesFeatureEnabled(state);
  const isSwitchRoutineFeatureEnabled =
    routinesFeatureEnabled && flags.tmpRoutineSwitch.isEnabled();

  const schedulesList = getAllUnPausedRoutinesSchedules(state);
  const timeToNextSchedule = getMinutesFromCurrentTimeToNextEvent(
    currentTime,
    timezone,
    schedulesList
  );
  const isNowCardLoaded = getUnifiedNowCardStatus(state) === 'LOADED';

  return {
    currentTime,
    timezone,
    profileId,
    profileUid: getActiveProfileUid(state),
    routinesList,
    hasRoutines: routinesList && routinesList.size > 0,
    timeToNextSchedule,
    isSwitchRoutineFeatureEnabled,
    isNowCardLoaded,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
  onNavigateToRoutines: (profileId: string) => {
    dispatch(navigateToRoutines(profileId));
  },
  onSwitchRoutine: (options: SwitchRoutineFnParams) => {
    dispatch(onSwitchRoutineThunk(options));
  },
});

const getModal = ({
  hasRoutines,
  currentTime,
  timezone,
  profileId,
  profileUid,
  routinesList,
  timeToNextSchedule,
  isSwitchRoutineFeatureEnabled,
  isNowCardLoaded,
  onClickClose,
  onSwitchRoutine,
  onNavigateToRoutines,
}: {
  hasRoutines: boolean;
  isSwitchRoutineFeatureEnabled: boolean;
  isNowCardLoaded: boolean;
} & RoutineSwitchModalProps &
  RoutineSwitchInfoModalProps) => {
  if (!isSwitchRoutineFeatureEnabled || !isNowCardLoaded) return () => null;
  if (!hasRoutines) {
    return RoutineSwitchInfoModal({
      profileId,
      onClickClose,
      onNavigateToRoutines,
    });
  }

  return RoutineSwitchModal({
    profileId,
    currentTime,
    timezone,
    profileUid,
    routinesList,
    timeToNextSchedule,
    onClickClose,
    onSwitchRoutine,
  });
};

const RoutineSwitchModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(getModal);

export default RoutineSwitchModalContainer;
