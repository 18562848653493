import { HmacSHA1 } from 'crypto-js';
import * as Base64 from 'crypto-js/enc-base64';
import * as qinit from '../qinit';

export * from './vendor/googleGeocoding';

const STATIC_MAP_ZOOM = 15;
const STATIC_MAP_SIZE = '640x540';
const STATIC_MAP_URL_ROOT = 'https://maps.googleapis.com/maps/api/staticmap';

const signStaticMapUrl = url => {
  const key = qinit.tenant.parents_app.google_api.static_maps_api_key;
  const signSecret =
    qinit.tenant.parents_app.google_api.static_maps_api_signature_secret;

  const urlWithKey = `${url}&key=${key}`;
  const host = urlWithKey.replace(/(^[^/]*\/\/[^/]+)(\/.*$)/, '$1');
  const path = encodeURI(urlWithKey.substr(host.length));

  const signature = Base64.stringify(HmacSHA1(path, Base64.parse(signSecret)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
  return `${url}&key=${key}&signature=${signature}`;
};

export const staticMapUrl = (lat, lng) =>
  encodeURI(
    signStaticMapUrl(
      // tslint:disable-next-line:max-line-length
      `${STATIC_MAP_URL_ROOT}?center=${lat},${lng}&zoom=${STATIC_MAP_ZOOM}&size=${STATIC_MAP_SIZE}&scale=2&markers=|${lat},${lng}`
    )
  );
