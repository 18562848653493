import { List } from 'immutable';
import { add, binary } from 'ramda';
import { MinutesPerHourActivityRecord } from '../../records/activity/types/MinutesPerHourActivity.types';
import { RoutineRecord } from '../../records/routines/types/Routine.types';

/**
 *
 * Calculate the total screen time; it's important to note that to calculate the actual screen time,
 * you need to subtract the screen time consumed by routines that do not use quota.
 */
export const calculateTotalScreenTimeWithRoutines = (
  summary: List<MinutesPerHourActivityRecord>,
  routines: List<RoutineRecord>
) => {
  const freeQuotaRoutines = routines
    .filter(routine => !routine.policy.countsTowardsDailyTimeQuota)
    .map(routine => routine.uid);

  const summaryWithAdjustedScreenTime = summary.map(activity => {
    const totalScreenTime = activity.minutes;

    const freeQuotaScreenTime = (activity.routineScreenTime ?? [])
      .filter(routine => freeQuotaRoutines.includes(routine.uid))
      .map(routine => routine.minutes)
      .reduce(add, 0);

    const adjustedScreenTime = totalScreenTime - freeQuotaScreenTime;
    return activity.set('minutes', adjustedScreenTime);
  });

  const screenTime = summaryWithAdjustedScreenTime
    .map(activity => activity.minutes)
    .reduce(binary(add), 0);

  return screenTime;
};
