import { EMPTY, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';

import { captureException } from '../../../helpers/sentry';

import { AppAction, AppEpic } from '../../../epics/types';
import { ReceiveFeaturesAlertsAction } from '../types/actions';
import { requestReceiveFeatures } from '../helpers';

export const loadFeatureEpic: AppEpic<
  AppAction,
  ReceiveFeaturesAlertsAction
> = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType('REQUEST_FEATURES'),
    switchMap(action =>
      of(action).pipe(
        switchMap(() => requestReceiveFeatures(api)),
        catchError((e: Error) => {
          captureException(e);
          return EMPTY;
        })
      )
    )
  );
};
