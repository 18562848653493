import { deleteProfilePause } from '../../../ducks/pause/thunks';
import { ActionWrapper } from './types';
import {
  navigateToExtraTimeModal,
  navigateToPauseProfileModal,
} from '../../../actions/Navigation';
import {
  cancelNowCardUpdateWhenStateFinish,
  trackQuickActionClick,
} from '../../../ducks/unifiedNowCard/thunks';
import { ButtonNames } from '../../../helpers/analytics';
import { UnifiedNowCardState } from '../../../businessLogic/unifiedNowCard/types';

export const actionPauseButton = (
  actionWrapper: ActionWrapper,
  state: UnifiedNowCardState
) => {
  actionWrapper(trackQuickActionClick(ButtonNames.PauseInternet, { state }));
  actionWrapper(navigateToPauseProfileModal());
};

export const actionResumeButton = (
  profileId: number,
  actionWrapper: ActionWrapper,
  state: UnifiedNowCardState
) => {
  actionWrapper(
    trackQuickActionClick(ButtonNames.DeletePauseInternet, { state })
  );
  actionWrapper(deleteProfilePause(profileId, true));
  actionWrapper(cancelNowCardUpdateWhenStateFinish());
};

export const actionPlusButton = (
  profileId: number,
  actionWrapper: ActionWrapper,
  state: UnifiedNowCardState
) => {
  actionWrapper(
    trackQuickActionClick(ButtonNames.EditDailyTimeLimits, { state })
  );
  actionWrapper(navigateToExtraTimeModal(String(profileId), 'unifiedNowCard'));
};
