import { connect } from 'react-redux';
import { getAccountLocale } from '../../../selectors';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import { navigateToAddDeviceAction } from '../../../actions/ProfileActions';
import {
  GenericPageNames,
  ProfileRuleNames,
  trackProtectADeviceBlockedFeature,
} from '../../../helpers/analytics';
import LocationRulesAddDeviceFlyover from '../../../components/LocationRules/LocationRulesAddDeviceFlyover';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickAddDevice: () => {
    trackProtectADeviceBlockedFeature(
      GenericPageNames.LocationRules,
      ProfileRuleNames.Location
    );
    dispatch(navigateToAddDeviceAction());
  },
});

const LocationRulesAddDeviceFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRulesAddDeviceFlyover);

export type LocationRulesAddDeviceFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default LocationRulesAddDeviceFlyoverContainer;
