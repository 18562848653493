import { connect } from 'react-redux';
import Initial from '../components/Initial/Initial';
import { startSso } from '../ducks/app';
import { isBrowserPlatform } from '../helpers';
import { SsoStartMode } from '../constants';
import { Dispatch } from '../store/state';
import { getSsoStatusFailed } from '../selectors/app';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';

const mapStateToProps = state => ({
  hasError: getSsoStatusFailed(state),
});

const mapDispatchToProps = dispatch => ({
  onClickLogin: () => {
    dispatch(startSso(SsoStartMode.login));
  },
  onClickSignup: () => {
    dispatch(startSso(SsoStartMode.signUp));
  },
});

const InitialContainer = connect(mapStateToProps, mapDispatchToProps)(Initial);

InitialContainer.redirect = () => (dispatch: Dispatch) => {
  const navigate = getMultiPlatformNavigation();
  if (isBrowserPlatform()) {
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/login',
      })
    );
  } else {
    dispatch(finishedLoadingInitialData());
  }
};

export default InitialContainer;
