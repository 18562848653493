import * as React from 'react';
import { t } from '../../lib/i18n';
import EditEmailForm, { formName, EditEmailFormRecord } from './EditEmailForm';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const EditEmail: React.FunctionComponent<{
  initialValues: EditEmailFormRecord;
  onFormSubmit: () => void;
  currentEmail: string;
  showSpinner?: boolean;
}> = ({ initialValues, onFormSubmit, currentEmail, showSpinner = false }) => (
  <PageLayout
    title={t('Change Email Address')}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
    actions={[{ actionName: 'back' }]}
  >
    <EditEmailFields
      initialValues={initialValues}
      onFormSubmit={onFormSubmit}
      showSpinner={showSpinner}
      currentEmail={currentEmail}
    />
  </PageLayout>
);

const EditEmailFields: React.FunctionComponent<{
  initialValues: EditEmailFormRecord;
  currentEmail: string;
  showSpinner?: boolean;
  onFormSubmit: () => void;
}> = ({ initialValues, showSpinner = false, currentEmail, onFormSubmit }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="space-between"
    flexGrow="2"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <EditEmailForm
        className="EditEmailForm"
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        currentEmail={currentEmail}
      />
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          centered
          size={ButtonSize.medium}
          data-testid="save-edit-name"
          htmlType="submit"
          form={formName}
          loading={showSpinner}
        >
          {t('Save')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);

export default EditEmail;
