import { connect } from 'react-redux';
import RoutinesInfoModal from '../../components/Modal/RoutinesInfoModal';
import { goBackIfHistory } from '../../ducks/routing';

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(goBackIfHistory());
  },
});

const RoutinesInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(RoutinesInfoModal);

export default RoutinesInfoModalContainer;
