import * as React from 'react';
import { Card, EmptyPlaceholder, Svg } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import InfoIcon from '../../Icons/InfoIcon';

const SignatureNoDataCard: React.FunctionComponent = () => (
  <Card fullWidth className="SignatureNoDataCard">
    <EmptyPlaceholder
      icon={
        <Svg height="64" width="64" color="gray-light">
          <InfoIcon />
        </Svg>
      }
      fillAvailableSpace
      centered
      smallText
      text={t(
        'It looks like we don’t have a review or description for this app yet.'
      )}
    />
  </Card>
);

export default SignatureNoDataCard;
