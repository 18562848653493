import { onEnterOnboardingAddDevice } from './OnboardingAddDeviceActions';
import {
  triggerDeviceConnectionLoop,
  navigateToFinish,
  NO_ONBOARDING_PROFILE_ERROR,
} from './OnboardingActions';
import State, { Dispatch } from '../../store/state';
import { isAuthenticated } from '../../selectors';

export const onEnterOnboardingPendingDeviceConnection =
  ({ profileId }: { profileId: string }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    try {
      await dispatch(onEnterOnboardingAddDevice({ profileId }));
      await dispatch(triggerDeviceConnectionLoop());
      return dispatch(navigateToFinish());
    } catch (error) {
      /**
       * If the user session has expired the triggerDeviceConnectionLoop interval
       * can return an Error Exception because the state is cleaned and the
       * onboarding profile does not exist.
       */
      if (
        !isAuthenticated(getState()) &&
        error.message === NO_ONBOARDING_PROFILE_ERROR
      ) {
        return;
      }
      throw error;
    }
  };
