import { List } from 'immutable';
import { Action } from 'redux';
import { CategoriesActivity } from '../../../records/studentActivity/types/categories.types';
import { SignaturesActivity } from '../../../records/studentActivity/types/signatures.types';
import { TotalUsageActivity } from '../../../records/studentActivity/types/totalUsage.types';
import { SummaryDateRanges } from '../../../constants/index';

export type StudentSummaryAction =
  | Action<'SUMMARY_IS_LOADING_DATA'>
  | (Action<'SUMMARY_FINISH_LOADING_DATA'> & {
      payload: {
        profileUid: string;
      };
    })
  | Action<'REQUEST_SUMMARY_TOTAL_USAGE'>
  | Action<'REQUEST_SUMMARY_SIGNATURES'>
  | Action<'REQUEST_SUMMARY_CATEGORIES'>
  | Action<'RESET_SUMMARY_ERROR'>
  | (Action<'RECEIVE_SUMMARY_ERROR'> & {
      payload: StudentSummaryFetchError;
    })
  | (Action<'RECEIVE_SUMMARY_FETCH_TOTAL_USAGE_ERROR'> & {
      payload: StudentSummaryFetchError;
    })
  | (Action<'RECEIVE_SUMMARY_FETCH_CATEGORIES_ERROR'> & {
      payload: StudentSummaryFetchError;
    })
  | (Action<'RECEIVE_SUMMARY_FETCH_SIGNATURES_ERROR'> & {
      payload: StudentSummaryFetchError;
    })
  | (Action<'RECEIVE_SUMMARY_TOTAL_USAGE'> & {
      payload: {
        lastUpdated: string;
        totalUsage: List<TotalUsageActivity>;
      };
    })
  | (Action<'RECEIVE_SUMMARY_SIGNATURES'> & {
      payload: List<SignaturesActivity>;
    })
  | (Action<'RECEIVE_SUMMARY_CATEGORIES'> & {
      payload: List<CategoriesActivity>;
    })
  | (Action<'CHANGE_DATE_RANGE'> & {
      payload: SummaryDateRanges;
    })
  | (Action<'STUDENT_SELECTED_PAST_DATE_CHANGED'> & {
      payload: string;
    });

export enum StudentSummaryFetchError {
  Unlinked = 'unlinked',
  UnhandledServerError = 'UnhandledServerError',
  FeatureDisabled = 'FeatureDisabled',
}
