import * as React from 'react';
import { t } from '../../lib/i18n';
import Loader from '../base/Loader';
import { Icon, IconType, PageTitle } from 'styleguide-react';

class FamilyLocatorHeader extends React.Component<{
  onBack: () => void;
  onClickRefreshLastLocation: (stopRefresh: () => void) => any;
}> {
  state = {
    isRefreshing: false,
  };

  stopRefresh = () => {
    this.setState({ isRefreshing: false });
  };

  render() {
    const { isRefreshing } = this.state;
    const { onBack, onClickRefreshLastLocation } = this.props;
    return (
      <PageTitle
        title={t('Family Locator')}
        testId="pageTitle"
        size="small"
        actions={[
          { actionName: 'back', onClick: onBack },
          isRefreshing
            ? {
                actionName: 'loading',
                icon: (
                  <div className="FamilyLocator__container-loader">
                    <Loader
                      className="FamilyLocator__loader"
                      size=""
                      color=""
                    />
                  </div>
                ),
                onClick: () => {},
              }
            : {
                actionName: 'refresh',
                icon: <Icon type={IconType.syncAlt} />,
                onClick: () => {
                  this.setState({ isRefreshing: true });
                  onClickRefreshLastLocation(this.stopRefresh);
                },
              },
        ]}
      />
    );
  }
}

export default FamilyLocatorHeader;
