import * as React from 'react';
import {
  FlexLayout,
  StyledHeader,
  Button,
  ButtonSize,
  ButtonType,
  List,
  RegularStyleListItem,
  Avatar,
  ListItemVerticalAlign,
  AvatarSize,
  AvatarVariant,
} from 'styleguide-react';
import type { LinkStudentsProps } from '../../containers/Schools/LinkStudentsContainer';
import { t } from '../../lib/i18n';
import { getProfileNameInitial } from '../../records/profile';

const LinkStudents: React.FunctionComponent<LinkStudentsProps> = ({
  onClickNext,
  onClickSomethingNotRight,
  isCallingLinkPendingStudents,
  students,
}: LinkStudentsProps) => {
  const studentsList = (students: LinkStudentsProps['students']) =>
    students && !students.isEmpty() ? (
      <FlexLayout mainaxis="column" marginTop="32" marginBottom="32">
        <List listItemMarginTop="16" listItemPaddingBottom="8">
          {students.map(student => (
            <RegularStyleListItem
              key={student?.id}
              centerMiddleContent
              iconVerticalAlign={ListItemVerticalAlign.center}
              icon={
                <Avatar variant={AvatarVariant.text} size={AvatarSize.small}>
                  {[student?.firstName].reduce(getInitial, '')}
                </Avatar>
              }
              title={[student?.firstName, student?.lastName]
                .filter(s => s)
                .join(' ')}
              lowerSubtitle={student.school.name}
            />
          ))}
        </List>
      </FlexLayout>
    ) : null;

  return (
    <div className="LinkStudents">
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        matchParentHeight
      >
        <FlexLayout
          mainaxis="column"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          marginTop="32"
        >
          <FlexLayout mainaxis="column" marginBottom="16">
            <StyledHeader type="h5" testId="LinkStudents-header">
              <strong>{t("Connect your child's school account")}</strong>
            </StyledHeader>
          </FlexLayout>
          <p className="LinkStudents__subheader">
            {t('This will let you view the activity on their school devices.')}
          </p>
        </FlexLayout>

        <div className="LinkStudents__List">{studentsList(students)}</div>

        <FlexLayout
          mainaxis="column"
          marginTop="32"
          marginBottom="32"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          width="100%"
        >
          <FlexLayout
            mainaxis="column"
            marginBottom="32"
            className="OnboardingButton__container"
          >
            <Button
              data-testid="Button--continue"
              onClick={onClickNext}
              loading={isCallingLinkPendingStudents}
              disabled={isCallingLinkPendingStudents}
              size={ButtonSize.medium}
              block
            >
              {t('Connect')}
            </Button>
          </FlexLayout>

          <Button
            data-testid="Button--something-is-wrong"
            onClick={onClickSomethingNotRight}
            loading={false}
            disabled={false}
            size={ButtonSize.medium}
            buttonType={ButtonType.plain}
          >
            {t('Something not right?')}
          </Button>
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

const getInitial = (acc: string, value: string | undefined) =>
  value ? acc + getProfileNameInitial(value) : acc;

export default LinkStudents;
