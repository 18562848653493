import * as React from 'react';
import {
  Card,
  Layout,
  EmptyPlaceholder,
  Svg,
  Icon,
  IconType,
} from 'styleguide-react';
import type { StudentTotalUsageProps } from '../../containers/Schools/StudentTotalUsageCardContainer';
import { t } from '../../lib/i18n';
import StudentTotalUsageBarChart from './StudentTotalUsageBarChart';
import InfoIcon from '../Icons/InfoIcon';
import SchoolFeatureDisabled from './SchoolFeatureDisabled';

const StudentTotalUsageCard: React.FunctionComponent<
  StudentTotalUsageProps
> = ({
  totalUsageActivity,
  timezone,
  currentTime,
  todaySelected,
  isFeatureDisabled,
  onClickInfo,
}: StudentTotalUsageProps) => {
  const cardContent = () => {
    if (isFeatureDisabled) {
      return <SchoolFeatureDisabled />;
    }
    const totalHits = totalUsageActivity.reduce((acc, val) => {
      return acc + val.hits;
    }, 0);
    if (totalUsageActivity.size && totalHits > 0) {
      return (
        <StudentTotalUsageBarChart
          data={totalUsageActivity}
          todaySelected={todaySelected}
          currentTime={currentTime}
          timezone={timezone}
        />
      );
    }
    return (
      <EmptyPlaceholder
        icon={
          <Svg height="64" width="64" color="gray-light">
            <InfoIcon />
          </Svg>
        }
        fillAvailableSpace
        centered
        smallText
        text={t('No activity during this period')}
      />
    );
  };
  return (
    <Card
      className="StudentSummary__card"
      header={{
        title: t('Total daily usage'),
        actionElement: (
          <Icon
            type={IconType.infoCircle}
            testId="student-total-usage-card-info"
          />
        ),
        onClickAction: onClickInfo,
      }}
    >
      <Layout marginTop="32" marginBottom="32" height="100%">
        {cardContent()}
      </Layout>
    </Card>
  );
};

export default StudentTotalUsageCard;
