import * as React from 'react';
import type { RoutineWithoutTimeLimitInfoModalProps } from '../../containers/Modal/RoutineWithoutTimeLimitInfoModalContainer';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  Modal,
  ModalStyledText,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants';
import UnifiedNowCardTime from '../NowCard/unifiedNowCard/cardComponents/UnifiedNowCardTime';

const RoutineWithoutTimeLimitInfoModal = ({
  screenTime,
  screenTimeDailyLimit,
  consumedPercent,
  onClose,
}: RoutineWithoutTimeLimitInfoModalProps): JSX.Element => {
  return (
    <Modal
      testId="routine-without-time-limit-info-modal"
      title={t('Daily Time Limits')}
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            color="secondary"
            size="sm"
            testId="routine-without-time-limit-info-modal__header-icon"
          />
        ),
      }}
      onClickClose={onClose}
      width={modalDimensions.regular.width}
      buttons={[
        <Button
          key="routineWithoutTimeLimit__Button"
          onClick={onClose}
          size={ButtonSize.medium}
          testId="routine-without-time-limit-info-modal__close-button"
        >
          {t('OK')}
        </Button>,
      ]}
    >
      <ModalStyledText>
        {t(
          'This routine doesn’t count towards daily time limits. You can see the time used so far today here:'
        )}
      </ModalStyledText>
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <UnifiedNowCardTime
          progressBarClassName="par-routine-without-time-limit-info-modal__progressBar"
          screenTime={screenTime}
          usedScreenTimePercentage={consumedPercent}
          subtitle={t('Limit today: {{dailyLimit}}', {
            dailyLimit: screenTimeDailyLimit,
          })}
          width="100%"
        />
      </FlexLayout>
    </Modal>
  );
};

export default RoutineWithoutTimeLimitInfoModal;
