import * as React from 'react';
import {
  API_BIRTHDATE_FORMAT,
  HUMAN_DATE_FORMAT_MONTH_DAY,
  minutesToHMFormat,
  weekdaysShortTranslations,
} from '../../../helpers/dates';
import StackedBarChart from '../../StackedBarChart/StackedBarChart';
import { Label, ReferenceLine } from 'recharts';
import { HoursPerDayActivity } from '../../../records/activity/types/HoursPerDayActivity.types';
import { List, Map } from 'immutable';
import { theme } from 'styleguide-react';
import * as moment from 'moment-timezone';
import { BarChartDataType } from '../types/BarChartDataType.types';
import {
  calculateActiveBarCoords,
  getAverageValue,
  getBarSegmentColorMappings,
  getBestFitChartWidth,
  transformDaysDataToChartData,
} from '../helpers';
import { ActiveBarData } from '../../StackedBarChart/types/RechartsActiveBarData.types';
import { classNames } from '../../../helpers';
import { SummaryDateRanges } from '../../../constants';
import ChartsCustomTooltip from '../../ChartsCustomTooltip/ChartsCustomTooltip';
import { t } from '../../../lib/i18n';
import { RoutineColor } from '../../../palettes/RoutineColor';

type DayScreenTimePartial = { date: string; hours: number };
type DayBarChartDataType = BarChartDataType<DayScreenTimePartial>;

interface ScreenTimeByDayBarChartProps {
  data: List<HoursPerDayActivity>;
  currentDate?: string;
  className?: string;
  activeDateRange: SummaryDateRanges;
  routineColorsMap: Map<string, RoutineColor>;
  hasSeparatedScreenTime: boolean;
}

const labelFormatter =
  (
    weekdaysShortTranslations: {
      [index: number]: string;
    },
    dataLength: number
  ) =>
  (tick: string) => {
    if (dataLength <= SummaryDateRanges.Days7) {
      const weekDayIndex = moment(tick, API_BIRTHDATE_FORMAT).isoWeekday();
      return weekdaysShortTranslations[weekDayIndex - 1];
    }
    return moment(tick, API_BIRTHDATE_FORMAT).format('D');
  };

const getTooltipTitle = (total: number) => {
  if (total != null) {
    return minutesToHMFormat(total * 60);
  }
  return '';
};

const getTooltipSubtitle = (date: string) => {
  if (date) {
    return moment(date, API_BIRTHDATE_FORMAT).format(
      HUMAN_DATE_FORMAT_MONTH_DAY
    );
  }
  return '';
};

const ScreenTimeByDayBarChart = ({
  data,
  currentDate,
  className,
  activeDateRange,
  routineColorsMap,
  hasSeparatedScreenTime,
}: ScreenTimeByDayBarChartProps) => {
  const avgLabelRef = React.useRef<HTMLSpanElement>(null);
  const chartWrapperRef = React.useRef<HTMLDivElement>(null);
  const [tooltipActive, setTooltipActive] = React.useState(false);
  const [activeBarData, setActiveBarData] =
    React.useState<ActiveBarData | null>(null);
  const [chartMargins, setChartMargins] = React.useState({
    left: 0,
    right: 10,
    bottom: 20,
  });

  const [computedData, setComputedData] = React.useState<{
    chartData: DayBarChartDataType[];
    barsColorMappings: Record<string, string>;
    averageValue: number;
  }>();

  React.useEffect(() => {
    const chartData = transformDaysDataToChartData<DayScreenTimePartial>(
      data,
      hasSeparatedScreenTime
    );
    const averageValue = getAverageValue(chartData);

    setComputedData({
      chartData,
      averageValue,
      barsColorMappings: getBarSegmentColorMappings(routineColorsMap),
    });
  }, [data, routineColorsMap]);

  React.useEffect(() => {
    if (avgLabelRef.current) {
      const { width } = avgLabelRef.current.getBoundingClientRect();
      setChartMargins(curr => ({ ...curr, left: width + 20 }));
    }
  }, [avgLabelRef]);

  const updateActiveBarData = React.useCallback(
    (action: 'in' | 'out') => (newBarData: ActiveBarData) => {
      if (action === 'out') return;
      if (newBarData) {
        const updatedBarData = calculateActiveBarCoords(
          newBarData,
          activeBarData,
          chartWrapperRef
        );

        setActiveBarData(updatedBarData);
      }
    },
    [setActiveBarData]
  );

  const toggleTooltip = React.useCallback(
    (action: 'in' | 'out') => () => {
      setTooltipActive(action === 'in');
    },
    [setTooltipActive]
  );

  const xAxisTicks = React.useMemo(
    () => computedData?.chartData.map(v => v.date),
    [computedData?.chartData]
  );

  /**
   * Nasty bug:
   * We have to use this hook to avoid re-invoking weekdaysShortTranslations.
   * For some strange reason when user updates the location of a location event,
   * this function is called and crashes the app.
   *
   * The crash stack is: weekdaysShortTranslations -> t -> getLocale -> getState()
   * Error: "Error: You may not call getState() while the reducer is executing."
   *
   * We think that at some point during re-renders the translation function (t)
   * is executed outside the context of react and causes a crash.
   */
  const weekdaysShortTranslationsMemo = React.useMemo(
    () => weekdaysShortTranslations(),
    []
  );

  const getLabelFormatter = React.useCallback(
    labelFormatter(
      weekdaysShortTranslationsMemo,
      computedData?.chartData.length || 0
    ),
    [computedData?.chartData]
  );

  const averageLineMemoizedComponent = React.useMemo(
    () => (
      <ReferenceLine
        isFront
        y={computedData?.averageValue || 0}
        stroke={theme.palette.secondaryLighter}
        strokeWidth={2}
        strokeDasharray="6 6"
        shapeRendering="crispEdges"
        className="par-screen-time-day-bar-chart__reference-line"
        label={
          <Label
            offset={8}
            value={t('Avg')}
            fontSize={14}
            position="left"
            fontWeight={500}
            fill={theme.palette.secondary}
          />
        }
      />
    ),
    [computedData?.averageValue]
  );

  const calculateChartWidth = React.useCallback(
    (calculatedWidth: number) => {
      const box = chartWrapperRef.current?.getBoundingClientRect();
      return getBestFitChartWidth(calculatedWidth, box?.width, activeDateRange);
    },
    [chartWrapperRef, activeDateRange]
  );

  // NB: we need to set the tooltip initial coords to the position of the chart wrapper.
  // If we don't do this, the tooltip will take the position of document.body and on the
  // first hover on a bar in the chart the tooltip will travel from body (0,0) to the
  // bar position.
  const chartBox = chartWrapperRef.current?.getBoundingClientRect();

  return (
    <div
      ref={chartWrapperRef}
      className={classNames('par-screen-time-day-bar-chart', className)}
      onPointerEnter={toggleTooltip('in')}
      onPointerLeave={toggleTooltip('out')}
      data-testid="par-screen-time-by-day-bar-chart"
    >
      <ChartsCustomTooltip
        id="par-screen-time-day-bar-chart-tooltip"
        coords={{
          x: activeBarData?.x || chartBox?.x || 0,
          y: activeBarData?.y || chartBox?.y || 0,
        }}
        hide={!tooltipActive || !activeBarData}
        title={getTooltipTitle(
          activeBarData?.tooltipPayload[0].payload.total ?? 0
        )}
        subtitle={getTooltipSubtitle(
          activeBarData?.tooltipPayload[0].payload.date
        )}
      />

      <StackedBarChart
        barSize={29}
        xDataKey="date"
        fixedWidth={calculateChartWidth}
        margin={chartMargins}
        showCartesianGrid={false}
        activeCategory={currentDate}
        data={computedData?.chartData || []}
        onMouseOverBar={updateActiveBarData}
        xAxisTicks={xAxisTicks || []}
        segmentsColorMappings={computedData?.barsColorMappings || {}}
        xAxisTickFormatter={getLabelFormatter}
      >
        {averageLineMemoizedComponent}
      </StackedBarChart>
      {!chartMargins?.left ? (
        <span style={{ visibility: 'hidden' }} ref={avgLabelRef}>
          {t('Avg')}
        </span>
      ) : null}
    </div>
  );
};

export default ScreenTimeByDayBarChart;
