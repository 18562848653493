import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { ProductAddonsOffer, ProductAddonsOfferPayload } from './types';

export const ProductAddonsOfferOperations = getRecordOperations<
  ProductAddonsOffer,
  ProductAddonsOfferPayload
>({
  defaultFields: {
    code: null,
    amount: null,
    currency: null,
    description: null,
    duration: null,
    options: [],
    url: null,
    durationType: null,
    addonLicenseCode: null,
    addonType: null,
    discountAmount: null,
  },
});
