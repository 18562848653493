import * as React from 'react';
import { List } from 'immutable';
import { FlexLayout, StepBar as StyledStepBar } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacing } from 'styleguide-react/build/components/Layout/types';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

const StepBar: React.FunctionComponent<{
  activeStep: number;
  className?: string;
  inProgress?: boolean;
  steps: List<{ label: string }>;
}> = ({ activeStep = 0, steps }) => {
  const renderStep = (marginLeftRight?: LayoutSpacing) => (
    <FlexLayout
      mainaxis="column"
      marginBottom="24"
      marginLeft={marginLeftRight}
      marginRight={marginLeftRight}
    >
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <StyledStepBar
          activeStep={activeStep - 1}
          steps={steps.map(step => step.label).toArray()}
        />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <StyledStepBar
          hideLabels
          activeStep={activeStep - 1}
          steps={steps.map(step => step.label).toArray()}
        />
      </RenderWhen>
    </FlexLayout>
  );
  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {renderStep('48')}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderStep()}
      </RenderWhen>
    </React.Fragment>
  );
};

export default StepBar;
