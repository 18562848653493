import * as React from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import ModalSelector from './Modal/ModalSelector';
import { RouterParams } from '../store/state';
import Layout from './Layout/Layout';

export const MainComponent: React.FunctionComponent<{
  content: null | JSX.Element | JSX.Element[];
  params: RouterParams;
  location: Location;
}> = ({ content, params, location }) => {
  return (
    <div className="Main">
      <ModalSelector params={params} location={location} />
      <Layout>{content}</Layout>
    </div>
  );
};

const mapStateToProps = (state, { params, location, children }) => ({
  content: state.getIn(['app', 'isContentHidden']) ? null : children,
  params,
  location,
});

const Main = connect(mapStateToProps)(MainComponent);

export default Main;
