import * as React from 'react';
import {
  Avatar,
  AvatarSize,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  List,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineRulesListItem, {
  RoutineRulesListItemProps,
} from '../../RoutineRulesList/RoutineRulesListItem';

import RoutineStepWrapper from '../RoutineStepWrapper';
import { routinePresets } from '../../helpers/presets';
import {
  getPresetColor,
  getPresetContext,
  getPresetIcon,
  getPresetSubtitle,
  getPresetTitle,
} from './helpers';
import { getRoutinesIcon } from '../../helpers/routineIcons';
import { SelectPresetProps } from './types/SelectPresetProps.types';

export enum SelectPresetTest {
  prefix = 'routines-select-preset-step',
}

const SelectPreset = ({ appRulesList, close, next }: SelectPresetProps) => {
  const options: Array<RoutineRulesListItemProps> = Object.values(
    routinePresets
  ).map(preset => ({
    title: getPresetTitle(preset),
    subtitle: getPresetSubtitle(preset),
    icon: getRoutinesIcon(getPresetIcon(preset)),
    iconColor: getPresetColor(preset),
    onClick: () => next?.(getPresetContext(preset, { appRulesList })),
    testId: `${SelectPresetTest.prefix}-option-${preset}`,
  }));

  return (
    <RoutineStepWrapper
      testId={SelectPresetTest.prefix}
      close={close}
      fixedFooter
    >
      <Layout marginBottom="40">
        <Typography type="h4" weight="semibold">
          {t('Choose a preset or create your own set of rules')}
        </Typography>
      </Layout>

      <List allowCustomListItemStyle={RoutineRulesListItem}>
        {options.map(
          ({ title, subtitle, icon, iconColor, onClick, testId }) => (
            <RoutineRulesListItem
              key={title}
              title={title}
              subtitle={subtitle}
              icon={icon}
              iconColor={iconColor}
              onClick={onClick}
              testId={testId}
            />
          )
        )}
        <RoutineRulesListItem
          key="custom"
          title={t('Custom')}
          icon={
            <Avatar emptyBackground size={AvatarSize.small}>
              <Icon
                type={IconType.plus}
                color={IconColor.regular}
                size={IconSize.lg}
              />
            </Avatar>
          }
          onClick={() => next?.(null)}
          testId={`${SelectPresetTest.prefix}-option-custom`}
        />
      </List>
    </RoutineStepWrapper>
  );
};

export default SelectPreset;
