import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  LINK_FOOTER,
  LINK_CONTENT,
  ModalLink,
  modalDimensions,
} from '../../constants';
import {
  LicenseRecord,
  devicesString,
  profilesString,
  LicenseType,
  shouldShowConversionElements,
  isPremium,
} from '../../records/license';
import ft from '../../lib/ft';
import {
  Modal,
  ButtonSize,
  Button,
  ModalStyledText,
  Icon,
  IconType,
} from 'styleguide-react';
import * as qinit from '../../qinit';

const AtLimitModal: React.FunctionComponent<{
  license: LicenseRecord;
  onClickLink: (link: ModalLink) => any;
  onClickClose: () => any;
}> = ({ license, onClickLink, onClickClose }) => {
  const { pText, footerButtonText } = texts(license);

  const contentLinkText =
    license.type === LicenseType.Premium
      ? qinit.tenant.common.tenant.support_email
      : t('View All Premium Features');

  return (
    <Modal
      onClickClose={onClickClose}
      header={{
        icon: <Icon type={IconType.exclamationCircle} />,
      }}
      title={t('Account Limits Reached')}
      width={modalDimensions.regular.width}
      buttons={
        footerButtonText
          ? [
              <Button
                key="button"
                onClick={() => onClickLink(LINK_FOOTER)}
                block
                size={ButtonSize.medium}
              >
                {footerButtonText}
              </Button>,
            ]
          : []
      }
    >
      <ModalStyledText>{pText}</ModalStyledText>
      <ModalStyledText>
        {ft.active('show_support_link') &&
        shouldShowConversionElements(license) ? (
          <a onClick={() => onClickLink(LINK_CONTENT)}>{contentLinkText}</a>
        ) : null}
      </ModalStyledText>
    </Modal>
  );
};

const texts = (license: LicenseRecord) =>
  shouldShowConversionElements(license)
    ? conversionElementsTexts(license)
    : {
        pText: t(
          // tslint:disable-next-line:max-line-length
          "You've reached your limit of devices and children. To continue first go to Settings and remove any devices or children you don't need to protect any more."
        ),
        footerButtonText: null,
      };

const conversionElementsTexts = (license: LicenseRecord) =>
  isPremium(license.type)
    ? {
        pText: t(
          'Your current plan limits protection to {{maxDevices}} and {{maxProfiles}}. ' +
            'You can remove devices/kids from your account or switch to a bigger plan.',
          {
            maxProfiles: profilesString(license),
            maxDevices: devicesString(license),
          }
        ),
        footerButtonText: t('Contact support'),
      }
    : {
        pText: t(
          'Your current license limits protection to {{maxDevices}} and {{maxProfiles}}. ' +
            'Upgrade to Premium to increase your limits.',
          {
            maxDevices: devicesString(license),
            maxProfiles: profilesString(license),
          }
        ),
        footerButtonText: t('Upgrade to Premium'),
      };

export default AtLimitModal;
