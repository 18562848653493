import { EventType } from '../../constants';
import { ActivityEventFilters } from './types';
import { EventPayload } from '../../records/event/types/Event.types';
import { eventTypeToActivityEventFilterMapper } from './constants';

/**
 * It is the core part that allows categorizing an event with different filters.
 * Not all events belong to an activity category but
 * By default, any event belongs to ALL.
 * Additionally, if its type is part of the activity category, it will be added.
 * Finally, it computes whether it belongs to other filters independent of the type
 * and dependent on the information of the event.
 * This allows us to easily extend filters in the timeline.
 */
export const getActivityEventFilters = (event: EventPayload) => {
  const filters = [ActivityEventFilters.All];

  const activityEventFilter: ActivityEventFilters | undefined =
    eventTypeToActivityEventFilterMapper[event.type];

  // Not all events belong to an activity category
  if (activityEventFilter) {
    filters.push(activityEventFilter);
  }

  if (event.type === EventType.Search && event?.search_inappropriate) {
    filters.push(ActivityEventFilters.InappropriateSearches);
  }

  return filters;
};
