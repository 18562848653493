import * as React from 'react';
import UnifiedNowCardWrapper from '../cardComponents/UnifiedNowCardWrapper';
import { FlexLayout, IconType } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';
import UnifiedNowCardIcon from '../cardComponents/UnifiedNowCardIcon';
import UnifiedNowCardTitle from '../cardComponents/UnifiedNowCardTitle';
import { t } from '../../../../lib/i18n';
import UnifiedNowCardSubtitle from '../cardComponents/UnifiedNowCardSubtitle';

const UnifiedNowCardErrorStatus = () => {
  return (
    <UnifiedNowCardWrapper className="par-unified-now-card--yellow">
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        <UnifiedNowCardIcon
          iconType={IconType.exclamationTriangle}
          iconClassName="par-unified-now-card__icon--yellow"
        />
      </RenderWhen>
      <FlexLayout mainaxis="column">
        <FlexLayout
          mainaxis="row"
          className="par-unified-now-card__title-wrapper"
        >
          <UnifiedNowCardTitle
            title={t('Something went wrong')}
            className="par-unified-now-card__title--yellow"
          />
        </FlexLayout>
        <UnifiedNowCardSubtitle subtitle={t('Please refresh the page')} />
      </FlexLayout>
    </UnifiedNowCardWrapper>
  );
};

export default UnifiedNowCardErrorStatus;
