import State from '../store/state';

export const getExtraTime = (state: State) =>
  state.get('extraTime').get('item');

export const getExtraTimeInSeconds = (state: State) => {
  const extraTime = getExtraTime(state);
  return extraTime ? extraTime.get('seconds') : null;
};
export const getExtraTimeInMinutes = (state: State) => {
  const seconds = getExtraTimeInSeconds(state);
  return seconds ? seconds / 60 : null;
};

export const getIfExtraTimeIsFetching = (state: State) =>
  state.get('extraTime').get('isFetching');
