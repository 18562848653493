import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { formName } from '../../components/base/SelectAddDeviceInstructions';
import AddDeviceInstructions from '../../components/Devices/AddDeviceInstructions';
import { DevicePlatform } from '../../constants';
import { trackablePage } from '../../helpers/hocs';
import { PageNames } from '../../helpers/analytics';

const values = getFormValues(formName);

const mapStateToProps = (state): { platform: DevicePlatform } => {
  const formValues = values(state);
  return {
    platform: formValues ? formValues.get('platform') : DevicePlatform.None,
  };
};

export default connect(mapStateToProps)(
  trackablePage(AddDeviceInstructions, PageNames.KidDeviceInstallInstructions)
);
