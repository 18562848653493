import { NonEmptyMapRecord, fromJS, Record } from 'immutable';
import * as R from 'ramda';

import api from '../api';
import { tapReject } from '../helpers';
import { APIError } from '../lib/errors';
import { t } from '../lib/i18n';
import { Profile } from '../records/profile';
import { SignatureDetailsRecordOperations } from '../records/studentActivity/signature/signatureDetails';
import {
  SignatureDetails,
  SignatureDetailsPayload,
} from '../records/studentActivity/signature/types/SignatureDetails.types';

import { Dispatch } from '../store/state';
import { showToast, TOAST_ICON_WARNING } from './toast';
import { SignatureDetailsAction } from './types/action/SignatureDetailsAction.types';

export default function signatureDetails(
  state: NonEmptyMapRecord<{
    result: Record<SignatureDetails> | null;
    lastUpdated: number;
    isError: boolean;
    fetchStatus: 'idle' | 'fetching' | 'settled';
  }> = fromJS({
    result: null,
    lastUpdated: undefined,
    isError: false,
    fetchStatus: 'idle',
  }),
  action: SignatureDetailsAction
) {
  switch (action.type) {
    case 'REQUEST_SIGNATURE_DETAILS':
      return state.merge({
        result: null,
        isError: false,
        fetchStatus: 'fetching',
      });
    case 'REQUEST_SIGNATURE_DETAILS_ERROR':
      return state.merge({
        isError: action.payload,
        fetchStatus: 'settled',
      });
    case 'RECEIVE_SIGNATURE_DETAILS':
      return state.merge({
        lastUpdated: action.receivedAt,
        result: action.payload,
        fetchStatus: 'settled',
      });
    default:
      return state;
  }
}

export const receiveSignatureDetails = R.pipe(
  SignatureDetailsRecordOperations.fromPayload,
  signatureDetails =>
    ({
      type: 'RECEIVE_SIGNATURE_DETAILS',
      payload: signatureDetails,
      receivedAt: Date.now(),
    } as SignatureDetailsAction)
);

export const requestSignatureDetails = (): SignatureDetailsAction => ({
  type: 'REQUEST_SIGNATURE_DETAILS',
});

export const requestSignatureDetailsError = (
  isError = true
): SignatureDetailsAction => ({
  type: 'REQUEST_SIGNATURE_DETAILS_ERROR',
  payload: isError,
});

export const fetchSignatureDetails = (
  profileUid: Profile['uid'],
  signatureId: SignatureDetails['id']
) => {
  return (dispatch: Dispatch<SignatureDetailsAction>) => {
    dispatch(requestSignatureDetails());
    return api.signatureDetails
      .get({ profileUid, signatureId })
      .then((signatureDetails: SignatureDetailsPayload) =>
        dispatch(receiveSignatureDetails(signatureDetails))
      )
      .catch(
        tapReject(error => {
          // error 404 is also empty state
          const error404 = isError404(error);
          dispatch(requestSignatureDetailsError(!error404));

          if (!error404) {
            dispatch(
              showToast(
                [t('Something went wrong.'), t('Please try again.')],
                TOAST_ICON_WARNING
              )
            );
          }
        })
      );
  };
};

const isError404 = (error: APIError) =>
  Boolean(error && error?.status && error.status === 404);
