import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { SummaryDateRanges } from '../../constants/index';
import { getActiveDateRange, getProfileOrDefault } from '../../selectors';
import { selectedNewDateRange } from '../../actions/StudentSummaryActions';
import StudentSeeAllCategories from '../../components/Schools/StudentSeeAllCategories';
import { PageNames } from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';

const mapStateToProps = (state: State, routeProps: RouterParamsProps) => ({
  profile: getProfileOrDefault(
    state,
    parseInt(routeProps.params.profileId, 10)
  ),
  activeDataRange: getActiveDateRange(state),
  maxCategoriesActivityItems: Infinity,
  single: true,
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  routerProps: RouterParamsProps
) => ({
  onChangeDateRange: (dateRange: SummaryDateRanges) => {
    dispatch(selectedNewDateRange(dateRange, routerProps.params.profileId));
  },
});

const SeeAllCategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(
    StudentSeeAllCategories,
    PageNames.ProfileSchoolActivityAllCategories
  )
);

export type AllSignaturesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default SeeAllCategoriesContainer;
