import { fromJS, List, OrderedSet } from 'immutable';
import type { LinewizeEventsState, LinewizeEventsAction } from './types';

export const initialState: LinewizeEventsState = fromJS({
  uuids: OrderedSet([]),
  status: null,
  hasMore: true,
  filter: ['all'],
  lastUpdated: null,
  timelineSearchEnabled: false,
  timelineVideoEnabled: false,
  timelineBlockedEnabled: false,
  timelineAllowedEnabled: false,
});

const linewizeEvents = (
  state: LinewizeEventsState = initialState,
  action: LinewizeEventsAction
): LinewizeEventsState => {
  switch (action.type) {
    case 'LINEWIZE_EVENTS_REQUEST':
      return state.merge({
        status: 'LOADING',
      });
    case 'LINEWIZE_EVENTS_SUCCESS': {
      const newUuids = state.get('uuids').union(action.payload.uuids);
      return state.merge({
        status: newUuids.size > 0 ? 'SUCCESS' : 'NO_ACTIVITY',
        uuids: newUuids,
        hasMore: action.payload.hasMore,
        filter: List(action.payload.filter),
        lastUpdated: action.payload.lastUpdated,
        timelineSearchEnabled: action.payload.timelineSearchEnabled,
        timelineVideoEnabled: action.payload.timelineVideoEnabled,
        timelineBlockedEnabled: action.payload.timelineBlockedEnabled,
        timelineAllowedEnabled: action.payload.timelineAllowedEnabled,
      });
    }
    case 'LINEWIZE_EVENTS_EXCEPTION':
    case 'LINEWIZE_EVENTS_CHANGE_STATUS':
      return state.merge({
        status: action.payload.status,
      });
    case 'LINEWIZE_EVENTS_ERROR':
      return state.merge({
        status: 'ERROR',
      });
    case 'LINEWIZE_EVENTS_RESET':
      return initialState;
    case 'LINEWIZE_EVENT_FILTER_CHANGE':
      return state.merge({
        uuids: OrderedSet([]),
        hasMore: true,
        filter: List(action.payload.filter),
      });
    case 'LINEWIZE_EVENTS_REFRESH':
      return state.merge({
        uuids: OrderedSet([]),
        status: 'REFRESH',
        hasMore: true,
      });
    default:
      return state;
  }
};

export default linewizeEvents;
