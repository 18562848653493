import State from '../store/state';

export const getPrevAccountState = (state: State) =>
  state.get('prevState').get('account');

export const getPrevProfileRulesState = (state: State) =>
  state.get('prevState').get('profileRules');

export const getPrevDeviceState = (state: State) =>
  state.get('prevState').get('device');
