import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  ButtonType,
  ButtonColor,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { ChangeContentFilterRulesModalProps } from '../../../containers/Routines/Modal/ChangeContentFilterRulesModalContainer';
import { RoutineRuleExceptionType } from '../routines.types';
import { PolicyAction } from '../../../records/routines/policy/types/Policy.types';

const getText = (
  type: RoutineRuleExceptionType,
  policyAction: PolicyAction
) => {
  const isTypeApp = type === 'APP';
  const isTypeWeb = type === 'WEB';

  const formatter = {
    1: str => <strong>{str}</strong>,
  };

  if (isTypeApp && policyAction === 'POLICY_ACTION_ALLOWED') {
    return t(
      'Your app {1}exceptions will be deleted{/1} and all apps will be allowed during this routine.',
      formatter
    );
  }
  if (isTypeApp && policyAction === 'POLICY_ACTION_BLOCKED') {
    return t(
      'Your app {1}exceptions will be deleted{/1} and all apps will be blocked during this routine.',
      formatter
    );
  }
  if (isTypeWeb && policyAction === 'POLICY_ACTION_ALLOWED') {
    return t(
      'The website category exceptions that you’ve set will be {1}deleted{/1}.',
      formatter
    );
  }

  return t(
    'Your website category {1}exceptions will be deleted{/1} and all categories will be blocked during this routine.',
    formatter
  );
};

const ChangeContentFilterRulesModal = ({
  type,
  profileUid,
  routineUid,
  policyAction,
  mode,
  onClickChange,
  onClickClose,
}: ChangeContentFilterRulesModalProps) => {
  const MODAL_TEST_ID = 'change-content-filter-modal';

  if (!profileUid || !policyAction || !mode || !type) {
    return null;
  }
  const isTypeApp = type === 'APP';
  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-change-content-filter-rules-modal"
      header={{
        icon: (
          <Icon
            type={IconType.questionCircle}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={isTypeApp ? t('Change app rules?') : t('Change category rules?')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-change`}
          key="button1"
          onClick={() => onClickChange(routineUid, mode, type, policyAction)}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Change')}
        </Button>,
        <Button
          testId={`${MODAL_TEST_ID}-cancel`}
          key="button2"
          onClick={onClickClose}
          color={ButtonColor.error}
          buttonType={ButtonType.plain}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>{getText(type, policyAction)}</ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default ChangeContentFilterRulesModal;
