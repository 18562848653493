import * as React from 'react';
import { t } from '../../../../lib/i18n';
import { Typography } from 'styleguide-react';

const ProtectADeviceLabel = () => {
  return (
    <Typography
      type="body2"
      weight="semibold"
      className="profile-rules-label__protect-device-label"
      testId="profile-rules-label-protect-device"
    >
      {t('Protect a device')}
    </Typography>
  );
};

export { isVisibleProtectADeviceLabel } from './predicate';
export default ProtectADeviceLabel;
