import * as React from 'react';

const useMergedState = <T extends Record<string, unknown>>(
  initialState: T
): [T, (data: Partial<T>) => void] => {
  const [state, setState] = React.useState<T>(initialState);

  const updateState = (data: Partial<T>) =>
    setState(state => ({ ...state, ...data }));

  return [state, updateState];
};

export default useMergedState;
