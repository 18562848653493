import { Store } from 'redux';
import * as React from 'react';
import * as R from 'ramda';
import { RouterState, PlainRoute } from 'react-router';
import timeout from '../lib/timeout';
import {
  appSetupIncomplete,
  resumeSetup,
  isSchoolOnboardingFlow,
  routeToSchoolOnboarding,
  isDirectPurchaseFlow,
  handleNavigateToDirectPurchase,
} from '../ducks/app';
import { parseQuery, QueryWebAutologin, stripTokensFromQuery } from './query';
import { gaTiming } from '.';
import { setRouteParams } from '../ducks/routing';
import { gaEventNavigationResult } from './ga';
import State, { Dispatch } from '../store/state';
import { SET_LOADED_ROOT } from '../ducks/actionNames/app';
import { isAuthenticated, isLoadedRoot } from '../selectors/app';
import { OnBoardingRoutesBeta } from '../constants';
import { AccountRecord } from '../records';
import { showErrorAlert, AnyError } from './errorHandling';
import { RouteLocation } from '../types/RouteLocation.types';
import { bootApp } from '../ducks/app/bootApp';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';
import { getAccount } from '../selectors/stateSelectors/account';

export const handleNavigations = () => process.env.NODE_ENV !== 'testing';

const getRouteParentLoadPromise = <T = unknown>(
  component: React.ComponentType<T>,
  routes: PlainRoute[]
) =>
  R.takeWhile(
    route =>
      route.component !== component &&
      (!route.indexRoute || route.indexRoute.component !== component),
    routes
  ).reduce(
    (promise, route) => promise.then(() => getRouteLoadPromise(route)),
    Promise.resolve()
  );

const getRouteLoadPromise = (route: PlainRoute) => {
  if (route.component && route.component.loadPromise) {
    return route.component.loadPromise;
  }
  if (route.indexRoute) {
    return getRouteLoadPromise(route.indexRoute);
  }
  return Promise.resolve();
};

const load = (store: Store<any>, location: RouteLocation, callback: any) => {
  if (!handleNavigations()) {
    return callback();
  }
  const bootTime = (startTime =>
    window.performance
      ? () => window.performance.now()
      : () => Date.now() - startTime)(Date.now());

  const originalQuery = window.location.search;
  const queryObject = parseQuery(originalQuery);

  if (!isDirectPurchaseFlow(queryObject as QueryWebAutologin)) {
    stripTokensFromQuery();
  }

  /*
  This is a corner case that causes the 'Welcome to Premium' modal not to work.
  There is a case where the user purchases the subscription from the application,
  which implies they are logged in. If they are logged in and coming from the 'thank you' page,
  bootApp should not be relaunched since it has already been launched previously.
  */
  const initialization = isComingFromWelcomeToPremiumAndIsLogged(
    store.getState() as State
  )
    ? Promise.resolve()
    : (store.dispatch as Dispatch)(bootApp(originalQuery, location, true));

  return initialization
    .then(action => {
      callback();
      return action;
    })
    .then(qlog('app initialized from root-onEnter'))
    .then(action => {
      return action !== null && action !== undefined
        ? store.dispatch(action)
        : undefined;
    })
    .then(() => (store.dispatch as Dispatch)(finishedLoadingInitialData()))
    .then(() => gaTiming('boot', '/', bootTime(), 'Home'))
    .then(() => {
      const state = store.getState();
      if (isAuthenticated(state) && appSetupIncomplete(state)) {
        (store.dispatch as Dispatch)(resumeSetup(queryObject));
      }
    })
    .then(() => {
      if (isSchoolOnboardingFlow(queryObject)) {
        (store.dispatch as Dispatch)(routeToSchoolOnboarding(queryObject));
      }
    })
    .then(() => {
      if (isDirectPurchaseFlow(queryObject as QueryWebAutologin)) {
        (store.dispatch as Dispatch)(
          handleNavigateToDirectPurchase(queryObject as QueryWebAutologin)
        );
      }
    })
    .catch((error: AnyError) => {
      showErrorAlert(error, () => {
        timeout(() => {
          window.history.pushState(null, '/');
        }, 1000);
      });
      // eslint-disable-next-line no-console
      console.error(`Load Route error: ${error}`);
      callback(error);
    });
};

// eslint-disable-next-line no-underscore-dangle
export const loadRoot =
  (store: Store<any>) => (nextState, _replace, callback) => {
    const originalQuery = window.location.search;
    const queryObject = parseQuery(originalQuery);

    // Handle history error https://github.com/ReactTraining/history/issues/427
    const state = store.getState();
    if (isLoadedRoot(state)) {
      if (
        isAuthenticated(store.getState()) &&
        appSetupIncomplete(store.getState()) &&
        !isDirectPurchaseFlow(queryObject)
      ) {
        (store.dispatch as Dispatch)(resumeSetup(queryObject));
      }
      return callback();
    }
    store.dispatch({ type: SET_LOADED_ROOT, payload: true });

    return load(store, nextState.location, callback);
  };

/**
 * Navigation (and our custom route loading) should never be blocking, and also it should not be asynchronous,
 * so using the "callback" of onEnter is not an option.
 * Ideally this would be solved by redux, but there is no direct way of waiting for one async thunk to be finished,
 * from a inside different thunk.
 * Here, each component stores a "loadPromise" on its instance, and all child routes wait for all their
 * parent's loadPromises to be fulfilled by chaining their own loadPromise.
 * This only affects the "load" action, NOT the rendering of the components (they must still deal with nullables).
 * Not the most clean or intuitive solution, but it allows to keep the "each route only loads its own data"
 * logic from react-router.
 */
export const loadRouteData =
  (store: Store<any>) =>
  <T = unknown>(component: React.ComponentType<T>, skipAuth?: boolean) =>
  ({ params, routes }: RouterState) => {
    if (!handleNavigations()) {
      return;
    }
    if (!skipAuth && !isAuthenticated(store.getState())) {
      return;
    }
    store.dispatch(setRouteParams(params));

    if (component.load !== undefined) {
      const path = () =>
        (store.getState().getIn(['routing', 'locationBeforeTransitions']) || {})
          .pathname;
      const startTime = Date.now();

      // TODO try to make this function non destructive
      // eslint-disable-next-line no-param-reassign
      component.loadPromise = getRouteParentLoadPromise(component, routes)
        .then(() =>
          component.load ? store.dispatch(component.load(params)) : undefined
        )
        .then(gaEventNavigationResult(path()))
        .catch(gaEventNavigationResult(path()))
        .then(() =>
          gaTiming(
            'navigation',
            path(),
            Date.now() - startTime,
            component.WrappedComponent?.displayName ||
              component.WrappedComponent?.name
          )
        );
    }
  };

export const redirect =
  (store: Store<any>) =>
  <T = unknown>(component: React.ComponentType<T>, skipAuth?: boolean) =>
  ({ params, routes }, replace?) => {
    if (!handleNavigations()) {
      return;
    }
    if (!skipAuth && !isAuthenticated(store.getState())) {
      return;
    }
    if (typeof component.redirect === 'function') {
      getRouteParentLoadPromise(component, routes).then(() =>
        store.dispatch(component.redirect?.(params, replace))
      );
    }
  };

export const onEnterCallAction =
  store =>
  action =>
  ({ params, routes }) => {
    if (!handleNavigations()) {
      return;
    }

    Promise.resolve(Date.now())
      .then(result => {
        store.dispatch(action(params));
        return result;
      })
      .then(startTime =>
        gaTiming(
          'navigation',
          (
            store.getState().getIn(['routing', 'locationBeforeTransitions']) ||
            {}
          ).pathname,
          Date.now() - startTime,
          ((R.last(routes) as any).component.WrappedComponent || {}).name
        )
      );
  };

export const createDefaultEmtpyRouteLocation = (): RouteLocation => ({
  pathname: '/',
  key: null,
  query: {},
});

export const getOnboardingExperimentScreen = (
  account: AccountRecord
): string => {
  if (account.profilesCount === 0) {
    return OnBoardingRoutesBeta.welcomeModal;
  }

  if (account.devicesCount > 0) {
    return OnBoardingRoutesBeta.setupComplete;
  }

  return OnBoardingRoutesBeta.chooseDevice;
};

export const isSchoolDashboardRoute = (pathName: string) => {
  const profileRegExp = /profiles\/\d+\/school\/.*(\/?)$/;
  return profileRegExp.test(pathName);
};

export const isCombinedDashboard = (pathName: string) => {
  const profileRegExp = /profiles\/\d+\/family-and-school*(\/?)/;
  return profileRegExp.test(pathName);
};

export const isComingFromWelcomeToPremiumAndIsLogged = (state: State) => {
  const prevRoute = state?.getIn(['routing', 'history']).last();
  const account = getAccount(state);
  const isLogged = account.get('uid') !== '';
  const isComingFromWelcomeModal =
    prevRoute?.pathname === '/modal/WelcomeToPremiumModal';
  return isLogged && isComingFromWelcomeModal;
};
