import { ofType } from 'redux-observable';
import { List } from 'immutable';
import { catchError, concat, filter, from, map, of, switchMap } from 'rxjs';
import { AppAction, AppEpic } from '../types';
import {
  LOAD_STUDENT_MOST_USED_CATEGORIES_ACTIVITY,
  receiveCategories,
  receiveSummaryCategoriesError,
  requestCategories,
  studentUnlinkedErrors,
} from '../../ducks/studentSummary';
import { getProfile, getSelectedDateRange } from '../../selectors';
import { getNow, requestProfileFilter } from '../helpers/dataLoaderHelpers';
import { showErrorAlert } from '../../helpers/errorHandling';
import {
  ProfileId,
  ProfileRecord,
} from '../../records/profile/types/Profile.types';
import { APIError } from '../../lib/errors';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { CategoriesActivityRecord } from '../../records/studentActivity/categories';
import { CategoriesActivityPayload } from '../../records/studentActivity/types/categories.types';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryStudentCategoriesActivityAction = ReturnType<
  typeof receiveCategories
>;
interface LoadSummaryStudentCategoriesActivityAction {
  payload: {
    profileId: ProfileId;
  };
}
interface CategoriesResponse {
  // eslint-disable-next-line camelcase
  total_hits: number;
  usage: CategoriesActivityPayload[];
}

const errorHandling = error => {
  if (error instanceof APIError) {
    if (studentUnlinkedErrors.includes(error.status)) {
      return receiveSummaryCategoriesError(StudentSummaryFetchError.Unlinked);
    }
    if (error.status === 412) {
      return receiveSummaryCategoriesError(
        StudentSummaryFetchError.FeatureDisabled
      );
    }
  }
  showErrorAlert(error.message);
  return receiveSummaryCategoriesError(
    StudentSummaryFetchError.UnhandledServerError
  );
};

const notifyIfError = catchError(e => {
  return of(errorHandling(e));
});

const setLoading = () => {
  return requestCategories();
};

const getSummaryCategoriesActivity = (
  api,
  { profileUid, state }
): Promise<CategoriesResponse> => {
  const { minDate, maxDate } = getSelectedDateRange(state);
  return api.summaryStudentCategories.withCache(ttl1Minute).get({
    profileUid,
    minDate,
    maxDate,
  });
};

const toReceiveSummaryCategoryActivityAction = (
  categories: CategoriesResponse
) => {
  return receiveCategories(
    List(
      categories.usage.map(category =>
        CategoriesActivityRecord.fromPayload(category)
      )
    )
  );
};

export const SummaryStudentCategoriesEpic: AppEpic<
  AppAction,
  ReceiveSummaryStudentCategoriesActivityAction
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_STUDENT_MOST_USED_CATEGORIES_ACTIVITY),
    map((action: LoadSummaryStudentCategoriesActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter((profile: ProfileRecord) =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap((profile: ProfileRecord) => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(setLoading()),
        from(
          getSummaryCategoriesActivity(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ).pipe(
          map(categoriesResponse =>
            toReceiveSummaryCategoryActivityAction(categoriesResponse)
          ),
          notifyIfError
        )
      );
    })
  );

  return loadData;
};
