import * as React from 'react';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { t } from '../../lib/i18n';
import WellbeingSubscribedToActivities from './WellbeingSubscribedToActivities/WellbeingSubscribedToActivities';
import { WellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/types';
import WellbeingSubscribed from './WellbeingSubscribed/WellbeingSubscribed';
import WellbeingUnsubscribed from './WellbeingUnsubscribed/WellbeingUnsubscribed';
import { FlexLayout } from 'styleguide-react';
import Loader from '../base/Loader';

interface WellbeingPageProps {
  isLoading: boolean;
  subscriptionStatus?: WellbeingSubscriptionStatus;
  goToTherapy: () => void;
  goToActivities: () => void;
  onNeedHelpClick: () => void;
  nextActivity: string;
  onLoad: (subscriptionStatus?: WellbeingSubscriptionStatus) => void;
  onBack: () => void;
}

const Wellbeing = ({
  isLoading,
  subscriptionStatus,
  goToActivities,
  goToTherapy,
  onNeedHelpClick,
  nextActivity,
  onLoad,
  onBack,
}: WellbeingPageProps) => {
  const isSubscribedToWellbeing = (): boolean => {
    return subscriptionStatus === 'both' && !isLoading;
  };

  const isSubscribedToWellbeingActivitiesOnly = (): boolean => {
    return subscriptionStatus === 'activities_only' && !isLoading;
  };

  const isNotSubscribedToWellbeing = (): boolean => {
    return subscriptionStatus === 'none' && !isLoading;
  };

  React.useEffect(() => {
    onLoad(subscriptionStatus);
  }, []);

  return (
    <PageLayout
      className="par-wellbeing-page"
      title={t('Family wellbeing')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: false,
          useNonFlexLayout: false,
          removePaddings: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          columns: 1,
          removePaddings: true,
          useNonFlexLayout: false,
        },
      ]}
      actions={[
        {
          actionName: 'back',
          onClick: onBack,
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      <PageSectionLayout>
        {isLoading && (
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            crossaxisAlignment="center"
            height="100%"
            width="100%"
          >
            <Loader size="small" />
          </FlexLayout>
        )}
        {isSubscribedToWellbeing() && (
          <WellbeingSubscribed
            nextActivity={nextActivity}
            onNeedHelpClick={onNeedHelpClick}
          />
        )}
        {isSubscribedToWellbeingActivitiesOnly() && (
          <WellbeingSubscribedToActivities
            nextActivity={nextActivity}
            onNeedHelpClick={onNeedHelpClick}
            goToTherapy={goToTherapy}
          />
        )}
        {isNotSubscribedToWellbeing() && (
          <WellbeingUnsubscribed
            goToTherapy={goToTherapy}
            goToActivities={goToActivities}
          />
        )}
      </PageSectionLayout>
    </PageLayout>
  );
};

export default Wellbeing;
