import * as React from 'react';
import Loader from '../base/Loader';

export interface SpinnerProps {
  show: boolean;
  text: string | null;
  loaderColor?: string;
  backGroundColor?: string;
  className?: string;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  show,
  text,
  loaderColor = 'white',
  backGroundColor,
  className,
}) => {
  const modifiers = backGroundColor
    ? [`spinner--color-${backGroundColor}`]
    : [];

  if (show) {
    return (
      <div className={`spinner ${className} ${modifiers.join(' ')}`}>
        <Loader size="small" color={loaderColor} />
        {text ? <p>{text}</p> : null}
      </div>
    );
  }
  return null;
};

export default Spinner;
