import { t } from '../../lib/i18n';
import {
  MENU_OPTION_SUMMARY,
  MENU_OPTION_ACTIVITY_TIMELINE,
  MENU_OPTION_RULES,
  MENU_OPTION_STUDENT_SUMMARY,
  MENU_OPTION_STUDENT_ACTIVITY_TIMELINE,
  MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY,
} from '../../constants';
import type { MenuOptionType } from './types/MenuOptionType.types';
import flags from '../../sideEffects/flags';

interface getMenuOptionsProps {
  isCombinedSummary: boolean;
  isProfileDelegatedToThisAccount: boolean;
}

export const getMenuOptions = ({
  isCombinedSummary,
  isProfileDelegatedToThisAccount,
}: getMenuOptionsProps): MenuOptionType[] => [
  {
    key:
      isCombinedSummary && flags.useUnifiedDashboard.isEnabled()
        ? MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY
        : MENU_OPTION_SUMMARY,
    translation: t('Summary'),
    isPremium: false,
    needsData: !isProfileDelegatedToThisAccount,
    showContent: true,
  },
  {
    key: MENU_OPTION_ACTIVITY_TIMELINE,
    translation: t('Activity Timeline'),
    isPremium: false,
    needsData: !isProfileDelegatedToThisAccount,
    showContent: true,
  },
  {
    key: MENU_OPTION_RULES,
    translation: t('Rules'),
    isPremium: false,
    needsData: !isProfileDelegatedToThisAccount,
    showContent: true,
  },
];

export const getStudentMenuOptions = (
  showRulesInStudentProfile: boolean
): MenuOptionType[] => {
  const studentMenuOptions = [
    {
      key: MENU_OPTION_STUDENT_SUMMARY,
      translation: t('Summary'),
      isPremium: false,
      needsData: false,
      showContent: true,
    },
    {
      key: MENU_OPTION_STUDENT_ACTIVITY_TIMELINE,
      translation: t('Activity Timeline'),
      isPremium: false,
      needsData: false,
      showContent: flags.schoolTimeline.isEnabled(),
    },
  ];

  if (showRulesInStudentProfile) {
    studentMenuOptions.push({
      key: MENU_OPTION_RULES,
      translation: t('Rules'),
      isPremium: false,
      needsData: false,
      showContent: flags.useDelegation.isEnabled(),
    });
  }

  return studentMenuOptions;
};
