import { APPThunk } from '../helpers/thunks';
import { getProfile } from '../selectors/profileRaw';
import { getProfileId } from '../selectors/profile';
import { getAccount } from '../selectors/account';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { getActiveCalendarRestriction } from '../selectors/stateSelectors/calendarRestrictionsPause';
import { fetchDeleteCalendarRestrictionPause } from '../ducks/calendarRestrictionsPause';

export const goToRestrictedTime =
  (profileId: number): APPThunk =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const state = getState();
    const profile = getProfile(state, getProfileId(state));
    const deviceId = profile.deviceIds.first() || 0;

    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/restrictedTimes/devices/${deviceId}`,
      })
    );
  };

export const goToEditDailyLimit =
  (profileId: number): APPThunk =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const state = getState();
    const profile = getProfile(state, profileId);
    const deviceId = profile.deviceIds.first() || 0;

    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/dailyTimeLimit/devices/${deviceId}`,
      })
    );
  };

export const unPauseInternet =
  (profileId: number): APPThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profileUid = getProfile(state, profileId).uid;
    const accountUid = getAccount(state).uid;
    const calendarRestrictionUid = getActiveCalendarRestriction(state)?.uid;

    return dispatch(
      fetchDeleteCalendarRestrictionPause(
        calendarRestrictionUid,
        profileUid,
        accountUid
      )
    );
  };

export const goToEditProfileRules = (profileId: number) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/rules`,
  });
};

export const goToEditProfileRulesTimeRestrictions = (
  profileId: number,
  deviceId = 0
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/rules/restrictedTimes/devices/${deviceId}`,
  });
};

export const goToEditProfileRulesReplacingUrl = (profileId: number) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner:replace',
    src: `/profiles/${profileId}/rules`,
  });
};
