import { Record } from 'immutable';
import { LocationRecord } from '.';

export type Place = {
  uid: string;
  name: string;
  latitude: number;
  longitude: number;
  radius: number;
  address: string;
};

type PlaceRecord = Record<Place>;

const PlaceRecord = Record<
  Place,
  {
    serialize: Record.Serializer<Record<Place>, Place>;
    fromPayload: Record.Parser<Place, Record<Place>>;
    fromLocationAndRadius: Record.Parser<
      { location: LocationRecord; radius: number },
      Record<Place>
    >;
  }
>({
  uid: '',
  name: '',
  latitude: 0,
  longitude: 0,
  radius: 100,
  address: '',
});

PlaceRecord.fromPayload = payload => PlaceRecord(payload);

PlaceRecord.fromLocationAndRadius = ({ location, radius }) => {
  return PlaceRecord({
    uid: '',
    name: '',
    latitude: location.latitude,
    longitude: location.longitude,
    radius,
    address: location.addressLines[0],
  });
};

PlaceRecord.serialize = record => record.toJS();

export default PlaceRecord;

export const getUpdatedFields = (placeA: PlaceRecord, placeB: PlaceRecord) => ({
  address_updated: placeA.address !== placeB.address,
  name_updated: placeA.name !== placeB.name,
  radius_updated: placeA.radius !== placeB.radius,
});
