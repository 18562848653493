/* eslint-disable no-console */
import type { Logger } from './types';

export const logger = <C, R>({
  meta,
  context,
  result,
  fulfilled,
}: Logger<C, R>) => {
  console.groupCollapsed(
    `@decision  ${fulfilled ? 'FULFILLED' : 'NOT FULFILLED'} ${
      meta?.name ?? 'UNKNOWN'
    }`
  );
  console.log(meta?.description ?? 'UNKNOWN');
  fulfilled && console.log('Result:');
  fulfilled && console.table(result);
  console.log('Context:');
  console.table(context);
  console.groupEnd();
};
