import { connect } from 'react-redux';
import type {
  ActiveViewTab,
  RoutinesPageProps,
} from '../../components/Routines/RoutinesPage/RoutinesPage';
import State, { BaseThunk, Dispatch, RouterParams } from '../../store/state';
import {
  getIsOpenMenu,
  getActiveMenuStep,
  getAllRoutinesList,
  getRoutinesStatus,
  getRoutineDetailsStatus,
  getRoutine,
  isRoutinesFeatureEnabled,
  getSchedulesStatus,
  getAllSchedulesList,
} from '../../ducks/routines/selectors';
import Routines from '../../components/Routines/Routines';
import { navigateToProfileDashboard } from '../../actions/ProfileRulesActions';
import {
  onEditRoutineThunk,
  onAddNewRoutineThunk,
  handleNavigateToRoutinesInfoModal,
  handleNavigateToUpgradePage,
} from '../../ducks/routines/thunks';
import { RoutineSteps } from '../../components/Routines/routines.types';
import { redirectToAddDeviceIfNoDeviceAssignedTo } from '../../actions/ProfileActions';
import {
  ButtonNames,
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
  trackButtonClicked,
  trackUpgradeBlockedFeature,
} from '../../helpers/analytics';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { localNow } from '../../helpers/dates';
import {
  getAccountLocale,
  getTimezone,
  isMilitaryTimeFormat,
} from '../../selectors';
import { getRoutineFeatureAccessLevel } from '../../businessLogic/routines/routines';
import { CalendarViewWeekday } from '../../components/CalendarView/types/CalendarView.types';
import { getFeatureByCode } from '../../ducks/features/selectors';

type StatePops = Omit<
  RoutinesPageProps,
  | 'onAddNewRoutine'
  | 'onEditRoutine'
  | 'onOpenInfoModal'
  | 'onUpgrade'
  | 'trackToggleRoutineView'
  | 'trackCalendarSlotClick'
>;

type DispatchProps = Omit<
  RoutinesPageProps,
  | 'routinesList'
  | 'isOpenMenu'
  | 'activeMenuStep'
  | 'isFetching'
  | 'profileId'
  | 'isError'
  | 'accountLocale'
  | 'featureAccessLevel'
  | 'isFetchingSchedules'
  | 'schedulesList'
  | 'accountTimezone'
  | 'currentTime'
  | 'isMilitaryTime'
>;

const trackNewRoutine = (tempRoutineUid: string) => {
  trackButtonClicked(GenericPageNames.RoutinesList, ButtonNames.AddARoutine, {
    options: JSON.stringify({ temp_routine_uid: tempRoutineUid }),
  });
};

const trackViewRoutine = (routineUid: string) => {
  trackButtonClicked(GenericPageNames.RoutinesList, ButtonNames.ViewRoutine, {
    options: JSON.stringify({ routine_uid: routineUid }),
  });
};

const trackToggleRoutineView = (activeTab: ActiveViewTab) => {
  trackButtonClicked(
    GenericPageNames.RoutinesList,
    ButtonNames.ChangeRoutinesListView,
    {
      options: JSON.stringify({ view_type: activeTab }),
    }
  );
};

const trackingWeekdaysMapping: Record<CalendarViewWeekday, string> = {
  MO: 'monday',
  TU: 'tuesday',
  WE: 'wednesday',
  TH: 'thursday',
  FR: 'friday',
  SA: 'saturday',
  SU: 'sunday',
};

const trackCalendarSlotClick = (
  routineUids: string[],
  weekday: CalendarViewWeekday
) => {
  trackButtonClicked(GenericPageNames.RoutinesList, ButtonNames.CalendarSlot, {
    options: JSON.stringify({
      routine_uids: routineUids,
      weekday: trackingWeekdaysMapping[weekday],
    }),
  });
};

const mapStateToProps = (
  state: State,
  { params: { profileId } }
): StatePops => {
  const accountLocale = getAccountLocale(state);
  const accountTimezone = getTimezone(state);
  const currentTime = localNow(accountTimezone);
  const isMilitaryTime = isMilitaryTimeFormat(state);

  const routinesStatus = getRoutinesStatus(state);
  const routineDetailsStatus = getRoutineDetailsStatus(state);
  const isFetching = [
    routinesStatus.get('read'),
    routinesStatus.get('create'),
    routineDetailsStatus.get('delete'),
  ].includes('loading');

  const schedulesStatus = getSchedulesStatus(state);
  const isFetchingSchedules = schedulesStatus.get('read') === 'loading';

  const isError = routinesStatus.get('read') === 'error';

  const licenseSubtype = getLicenseSubtype(state);
  const routineContentFilteringFeature = getFeatureByCode(
    state,
    'routine_content_filtering'
  );
  const routineBlockFeature = getFeatureByCode(state, 'routines_block');
  const featureAccessLevel = getRoutineFeatureAccessLevel(
    licenseSubtype,
    routineContentFilteringFeature,
    routineBlockFeature
  );

  return {
    profileId,
    isFetching,
    isFetchingSchedules: isFetchingSchedules || isFetching,
    accountTimezone,
    currentTime,
    isMilitaryTime,
    isError,
    activeMenuStep: getActiveMenuStep(state) as RoutineSteps | null,
    isOpenMenu: getIsOpenMenu(state),
    routinesList: getAllRoutinesList(state),
    schedulesList: getAllSchedulesList(state),
    accountLocale,
    featureAccessLevel,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onAddNewRoutine: () => dispatch(onAddNewRoutineThunk(trackNewRoutine)),
  onEditRoutine: routineUid =>
    dispatch(onEditRoutineThunk(routineUid, trackViewRoutine)),
  onOpenInfoModal: (profileId: string) => () =>
    dispatch(handleNavigateToRoutinesInfoModal(profileId)),
  onUpgrade: () => {
    trackUpgradeBlockedFeature(
      GenericPageNames.RoutinesList,
      FeatureBlockingContexts.FullBlockedFeature,
      ProfileRuleNames.Routines
    );
    dispatch(handleNavigateToUpgradePage());
  },
  trackToggleRoutineView,
  trackCalendarSlotClick,
});

const RoutinesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routines);

RoutinesContainer.load =
  (params: RouterParams): BaseThunk =>
  (dispatch, getState) => {
    if (params.editRoutineUid) {
      if (getRoutine(getState(), params.editRoutineUid) == null) return;
      dispatch(onEditRoutineThunk(params.editRoutineUid, trackViewRoutine));
    }
  };

RoutinesContainer.redirect =
  (params: RouterParams): BaseThunk =>
  (dispatch, getState) => {
    dispatch(redirectToAddDeviceIfNoDeviceAssignedTo(params.profileId));
    if (!isRoutinesFeatureEnabled(getState())) {
      dispatch(navigateToProfileDashboard(params.profileId));
    }
  };

export default RoutinesContainer;
