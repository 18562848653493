import { connect } from 'react-redux';
import NonBlockableAppModal from '../../components/Modal/NonBlockableAppModal';
import { goBackIfHistory } from '../../ducks/routing';
import { getAppRuleApplicationByKey } from '../../selectors';

const mapStateToProps = (state, { params: { profileId, appKey } }) => {
  const app = getAppRuleApplicationByKey(state, profileId, appKey);
  return { appName: app?.name };
};

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const NonBlockableAppModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NonBlockableAppModal);

export default NonBlockableAppModalContainer;
