import { connect } from 'react-redux';
import CloseAdditionalParentAccountModal from '../../components/Modal/CloseAdditionalParentAccountModal';
import { goBackIfHistory } from '../../ducks/routing';
import State, { Dispatch } from '../../store/state';
import { closeAdditionalParentAccount } from '../../ducks/invitations/invitationsActions';
import { getAccountMasterName } from '../../selectors/account';

export default connect(
  (state: State) => ({
    masterAccountName: getAccountMasterName(state),
  }),
  (dispatch: Dispatch) => ({
    onCloseAccount: () => dispatch(closeAdditionalParentAccount()),
    onCloseModal: () => {
      dispatch(goBackIfHistory());
    },
  })
)(CloseAdditionalParentAccountModal);
