import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import {
  SignatureReviewer,
  SignatureReviewerPayload,
} from './types/SignatureReviewer.types';

export const SignatureReviewerRecordOperations = getRecordOperations<
  SignatureReviewer,
  SignatureReviewerPayload
>({
  defaultFields: {
    name: '',
    description: '',
    profileImageUrl: '',
  },
});
