import { Record } from 'immutable';
import * as Moment from 'moment-timezone';
import * as R from 'ramda';
import { t } from '../lib/i18n';
import { daysDiff } from '../helpers/dates';
import { getBuildPlatform } from '../helpers/globals';
import { BuildPlatform } from '../constants';
import { LicenseLabel, LicenseSubtype } from './license/types/License.types';
import { ProductSize } from './product';
import {
  isPremiumBasic,
  isPremiumComplete,
  isTrialOrFree,
} from './license/helpers';
import { exhaustiveCheck } from '../helpers/validations';

export enum LicenseType {
  None = 'LICENSE_NONE',
  Trial = 'LICENSE_TRIAL',
  Free = 'LICENSE_FREE',
  Premium = 'LICENSE_PREMIUM',
}

export enum LicenseRenewal {
  None = 'RENEWAL_NONE',
  Auto = 'RENEWAL_AUTO',
}

export const RENEWAL_NONE = 'RENEWAL_NONE';
export const RENEWAL_AUTO = 'RENEWAL_AUTO';

export type Affiliate = {
  name: string;
  displayName: string;
  icon: string;
  showClientConversionElements: boolean;
};
export type AffiliateRecordType = Record<Affiliate>;
export const AffiliateRecord = Record<Affiliate, {}>({
  name: 'Qustodio',
  displayName: 'Qustodio',
  icon: '',
  showClientConversionElements: false,
});

export type PurchaseProvider = 'Chargebee' | 'GooglePlay' | 'Apple';

export type Purchase = {
  provider: PurchaseProvider;
};
export type PurchaseRecordType = Record<Purchase>;
export const PurchaseRecord = Record<Purchase, unknown>({
  provider: 'Chargebee',
});

export type LicenseRecord = {
  id: number;
  name: string;
  endDate: string;
  startDate: string;
  type: LicenseType;
  subtype: LicenseSubtype;
  maxProfiles: number;
  maxDevices: number;
  renewal: LicenseRenewal;
  showConversionElements: boolean;
  affiliate: AffiliateRecordType;
  label: LicenseLabel;
  sourceCampaign: string;
  purchase: PurchaseRecordType | null;
  durationDays: number | null;
  isEligibleForManualTrial: boolean;
};
export const LicenseRecord = Record<
  LicenseRecord,
  {
    fromPayload: (response: any) => LicenseRecord;
  }
>({
  id: 0,
  name: '',
  endDate: '',
  startDate: '',
  type: LicenseType.None,
  subtype: 'free',
  maxProfiles: 0,
  maxDevices: 0,
  renewal: LicenseRenewal.None,
  showConversionElements: true,
  affiliate: AffiliateRecord() as AffiliateRecordType,
  label: LicenseLabel.None,
  sourceCampaign: '',
  purchase: null,
  durationDays: null,
  isEligibleForManualTrial: false,
});

LicenseRecord.fromPayload = payload => {
  const type = licenseType(payload);
  return LicenseRecord({
    id: payload.id,
    name: payload.name,
    endDate: payload.endDate,
    startDate: payload.startDate,
    maxDevices: payload.maxDevices,
    maxProfiles: payload.maxProfiles,
    type,
    renewal: payload.autorenewal ? LicenseRenewal.Auto : LicenseRenewal.None,
    showConversionElements:
      payload.showConversionElements !== undefined
        ? payload.showConversionElements
        : true,
    affiliate: AffiliateRecord(payload.affiliate) as AffiliateRecordType,
    label: payload.label,
    sourceCampaign: payload.sourceCampaign,
    purchase:
      payload.purchase !== null
        ? (PurchaseRecord(payload.purchase) as PurchaseRecordType)
        : null,
    durationDays: payload.durationDays,
    subtype: payload.licenseSubType,
    isEligibleForManualTrial: payload.isEligibleForManualTrial ?? false,
  });
};

function licenseType(payload) {
  if (payload.type === LicenseType.Premium) {
    return payload.trial ? LicenseType.Trial : LicenseType.Premium;
  }
  return LicenseType.Free;
}

export function licenseDisplayName(license: LicenseRecord) {
  const displayNameMap = new Map<string, string>([
    [LicenseLabel.None, ''],
    [LicenseLabel.Xs, t('Premium Small')],
    [LicenseLabel.Small, t('Premium Small')],
    [LicenseLabel.Medium, t('Premium Medium')],
    [LicenseLabel.Large, t('Premium Large')],
    [LicenseLabel.Xl, t('Premium Large')],
    [LicenseLabel.Xxl, t('Premium Large')],
  ]);

  switch (license.subtype) {
    case 'free':
      return t('{{shortName}} Free');
    case 'trial':
      return t('{{shortName}} Premium Trial');
    case 'premium_grandfather':
      return displayNameMap.get(license.label) || '';
    case 'premium_basic':
      return t('Premium Basic');
    case 'premium_complete':
      return t('Premium Complete');
    case 'school_free':
      return t('School Free');
    default:
      exhaustiveCheck(license.subtype);
      throw new Error('The license record provided is malformed');
  }
}

/**
 * @deprecated use areProfilesExceeded selector instead
 */
export const profileLimitExceeded = (license, profileCount) =>
  profileCount > license.maxProfiles;

export const getLicenseDaysLeft = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  timezone: string
): number => {
  return daysDiff(currentTime, license.endDate, timezone);
};

export const isTrial = (licenseType: LicenseType) =>
  licenseType === LicenseType.Trial;

export const isFree = (licenseType: LicenseType) =>
  licenseType === LicenseType.Free;

export const isPremium = (licenseType: LicenseType) =>
  licenseType === LicenseType.Premium;

export const isFreeOrTrial = (licenseType: LicenseType) =>
  isFree(licenseType) || isTrial(licenseType);

export const isPremiumOrTrial = (licenseType: LicenseType) =>
  isPremium(licenseType) || isTrial(licenseType);

export const isAutoRenewal = R.equals(LicenseRenewal.Auto);

export const isNonExpiringPremium = (license: LicenseRecord) =>
  isPremium(license.type) && R.equals(license.endDate, null);

export const isNonExpiring = (license: LicenseRecord) =>
  R.equals(license.endDate, null);

// TODO: Maybe we could move this to qinit in some way
export const noAffiliateList = ['Qustodio', 'LineWize_Schools'];

export const isAffiliate = (license: LicenseRecord) =>
  !noAffiliateList.includes(license.affiliate.name);

export const shouldShowConversionElements = (license: LicenseRecord) =>
  license.affiliate.showClientConversionElements;

export const devicesString = license => {
  return license.get('maxDevices') > 1
    ? t('{{maxDevices}} devices', {
        maxDevices: license.get('maxDevices'),
      })
    : t('1 device');
};

export const profilesString = license => {
  return license.get('maxProfiles') > 1
    ? t('{{maxProfiles}} children', {
        maxProfiles: license.get('maxProfiles'),
      })
    : t('1 child');
};

export const expiresSoon = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  timezone: string
) =>
  isPremium(license.type) &&
  getLicenseDaysLeft(license, currentTime, timezone) < 30;

export function licenseUpgraded(oldLicense, newLicense) {
  if (!isPremium(oldLicense.type) && isPremium(newLicense.type)) {
    return true;
  }
  if (oldLicense.endDate !== newLicense.endDate) {
    return true;
  }
  return false;
}

export const licenseLinkTo = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  currentPage = '',
  timezone: string
) => {
  if (license.type !== LicenseType.Premium) {
    return '/upgrade';
  }

  if (isAutoRenewal(license.renewal)) {
    return `${currentPage}/modal/ExpandPlanModal`;
  }

  if (getLicenseDaysLeft(license, currentTime, timezone) > 30) {
    return `${currentPage}/modal/ExpandPlanModal`;
  }

  return '/renew';
};

export const isLicenseActionable = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  timezone: string
) => {
  if (isPremiumComplete(license.subtype)) return false;
  if (getBuildPlatform() !== BuildPlatform.ios) return true;
  if (isTrialOrFree(license.subtype)) return true;
  if (isFreeOrTrial(license.type)) return true;
  if (expiresSoon(license, currentTime, timezone)) return true;
  return false;
};

export const getLicenseLabelFromProductType = (productType: ProductSize) => {
  const map = {
    [ProductSize.xs]: LicenseLabel.Xs,
    [ProductSize.small]: LicenseLabel.Small,
    [ProductSize.medium]: LicenseLabel.Medium,
    [ProductSize.large]: LicenseLabel.Large,
    [ProductSize.xl]: LicenseLabel.Xl,
    [ProductSize.xxl]: LicenseLabel.Xxl,
  };
  return map[productType];
};

export const getProperLicenseText = (
  licenseSubtype: LicenseSubtype,
  textForBasic: string | JSX.Element,
  textForComplete: string | JSX.Element,
  fallbackText: string | JSX.Element
) => {
  if (isPremiumBasic(licenseSubtype)) {
    return textForBasic;
  }

  if (isPremiumComplete(licenseSubtype)) {
    return textForComplete;
  }

  return fallbackText;
};

export default LicenseRecord;
