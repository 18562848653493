import { ofType } from 'redux-observable';
import {
  Observable,
  catchError,
  concat,
  filter,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { AppActionWithPayload, AppEpic } from '../types';
import { LOAD_CALL_AND_SMS_ACTIVITY } from '../../ducks/summary';
import { getProfile, getSelectedDateRange } from '../../selectors';
import type { ProfileId } from '../../records/profile/types/Profile.types';
import {
  receiveCallsAndSms,
  requestCallsAndSms,
  requestCallsAndSmsError,
} from '../../ducks/socialActivity';
import {
  getNow,
  requestProfileFilter,
  summaryErrorHandler,
} from '../helpers/dataLoaderHelpers';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryCallAndSmsActivityAction = ReturnType<
  typeof receiveCallsAndSms
>;

type RequestCallsAndSms = ReturnType<typeof requestCallsAndSms>;
interface LoadSummaryCallAndSmsActivityAction {
  payload: {
    profileId: ProfileId;
  };
}

const notifyIfError = catchError(e => {
  return of(summaryErrorHandler(e, requestCallsAndSmsError));
});

const getSummaryCallAndSmsActivity = (
  api,
  { profileUid, state }
): Promise<ReceiveSummaryCallAndSmsActivityAction> => {
  const { minDate, maxDate } = getSelectedDateRange(state);
  return api.callsAndSmsSummary
    .withCache(ttl1Minute)
    .get({ profileUid, minDate, maxDate });
};

const toReceiveSummaryCallAndSmsActivityAction = list =>
  receiveCallsAndSms(list.items);

export const SummaryCallAndSmsActivityEpic: AppEpic<
  AppActionWithPayload,
  ReceiveSummaryCallAndSmsActivityAction
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_CALL_AND_SMS_ACTIVITY),
    map((action: LoadSummaryCallAndSmsActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter(profile =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap(profile => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(requestCallsAndSms()),
        from(
          getSummaryCallAndSmsActivity(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ).pipe(
          map(list => toReceiveSummaryCallAndSmsActivityAction(list)),
          notifyIfError
        )
      ) as Observable<
        RequestCallsAndSms & ReceiveSummaryCallAndSmsActivityAction
      >;
    })
  );

  return loadData;
};

export default SummaryCallAndSmsActivityEpic;
