import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label } from 'styleguide-react';
import { getAccount } from '../../selectors';
import { formatDateIncludingHours } from '../../helpers/dates';

const DateIncludingHours = connect(
  (state, props) => ({ ...props, ...getAccount(state).toJS() }),
  null
)(({ time, timezone, locale }) => (
  <Label>{formatDateIncludingHours(time, timezone, locale)}</Label>
));

DateIncludingHours.propTypes = {
  time: PropTypes.string.isRequired,
};

export default DateIncludingHours;
