import { filter } from 'rxjs';
import * as qinit from '../../qinit';

export const isPageInIframe = () => window.self !== window.top;

export const whenIsPageInIframe = <T>() => filter<T>(isPageInIframe);

export const sendMessageToPublicSite = <
  T extends { type: string; payload?: unknown }
>(
  message: T
) => {
  const targetWindow = window.parent;
  const targetOrigin = qinit.tenant.marketing.public_site.link;
  targetWindow.postMessage(message, targetOrigin);
};
