import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form/immutable';
import { goBackIfHistory } from '../../ducks/routing';
import { formName, formRecord } from '../../components/Profiles/ProfileForm';
import AvatarSelectionModal from '../../components/Modal/AvatarSelectionModal';

const values = getFormValues(formName);

const mapStateToProps = state => {
  const { picture, name } = formRecord(values(state));
  return {
    picture,
    name,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
  onClickAvatar: (picture: string) => {
    dispatch(change(formName, 'picture', picture));
  },
});

const AvatarSelectionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarSelectionModal);

export default AvatarSelectionModalContainer;
