import * as React from 'react';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';

interface ButtonFooterProps {
  text: string;
  onClick: (data?: unknown) => void;
  testId?: string;
  disabled: boolean;
}

const ButtonFooter = ({
  text,
  testId = undefined,
  onClick,
  disabled,
}: ButtonFooterProps) => {
  return (
    <FlexLayout
      testId={testId}
      mainaxis="column"
      crossaxisAlignment="center"
      padding="24"
      paddingTop="8"
      width="100%"
    >
      <Button
        testId={`${testId}-button`}
        block
        size={ButtonSize.medium}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Button>
    </FlexLayout>
  );
};

ButtonFooter.displayName = 'ButtonFooter';

export default ButtonFooter;
