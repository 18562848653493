import * as React from 'react';
import { t } from '../../lib/i18n';
import { Modal, ModalStyledText, Icon, IconType } from 'styleguide-react';
import { modalDimensions } from '../../constants';

const PlacesLimitReachedModal: React.FunctionComponent<{
  onClickClose: () => any;
}> = ({ onClickClose }) => (
  <Modal
    height={modalDimensions.regular.height}
    width={modalDimensions.regular.width}
    onClickClose={onClickClose}
    header={{
      icon: <Icon type={IconType.locationUnavailable} />,
    }}
    title={t('Places limit reached')}
  >
    <ModalStyledText>
      {t('To add a new place, first remove an existing one.')}
    </ModalStyledText>
  </Modal>
);

export default PlacesLimitReachedModal;
