import { fromJS, List, Map, NonEmptyMapRecord } from 'immutable';
import { BaseThunk } from '../store/state';
// eslint-disable-next-line import/no-cycle
import {
  isPurchaseFlow,
  getHistoryKeys,
  getLastLocationPathname as getLastLocationPathnameSelector,
  isAuthenticated,
} from '../selectors';
import { RouteLocation } from '../types/RouteLocation.types';
import api from '../api';
// eslint-disable-next-line import/no-cycle
import {
  getMultiPlatformNavigation,
  LOCATION_CHANGE,
} from '../helpers/multiPlatformNavigation';

const SET_ROUTE_PARAMS = 'SET_ROUTE_PARAMS';
const SET_LOGIN_REDIRECT_LOCATION = 'SET_LOGIN_REDIRECT_LOCATION';

export type RouteParams =
  | 'profileId'
  | 'deviceType'
  | 'promotion'
  | 'appKey'
  | 'routinesSelectedStep'
  | 'filter';

export type State = NonEmptyMapRecord<{
  locationBeforeTransitions: Readonly<RouteLocation> | null;
  lastLocationPathname: string | null;
  historyKeys: List<string>;
  routeParams: Map<RouteParams, string>;
  history: List<HistoryRouteItem>;
  loginRedirectLocation: Readonly<RouteLocation> | null;
}>;

/**
 * Custom react-router-redux reducer.
 * Manages "routing" slice as immutable Map, althoug the "locationBeforeTransitions"
 * part must still be mutable, however react-router-redux does not mutate it.
 * It also keeps track of a history stack in "historyKeys".
 */
export default function routing(
  state: State = fromJS({
    locationBeforeTransitions: null,
    lastLocationPathname: null,
    historyKeys: List(),
    history: List<HistoryRouteItem>(),
    routeParams: Map(),
    loginRedirectLocation: null,
  }),
  action
) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state
        .set('lastLocationPathname', getLastLocationPathname(state, action))
        .set('locationBeforeTransitions', action.payload)
        .set(
          'historyKeys',
          updateKeys(
            state.get('historyKeys'),
            action.payload.action,
            action.payload.key
          )
        )
        .set(
          'history',
          updateHistory(
            state.get('history'),
            action.payload.action,
            action.payload.key,
            action.payload.pathname
          )
        );

    case SET_ROUTE_PARAMS:
      return state.set('routeParams', Map<RouteParams, string>(action.payload));
    case SET_LOGIN_REDIRECT_LOCATION:
      return state.set('loginRedirectLocation', action.payload);
    default:
      return state;
  }
}

export const setLoginRedirectLocation = (location: RouteLocation) => ({
  type: SET_LOGIN_REDIRECT_LOCATION,
  payload: location,
});

export const removeLoginRedirectLocation = () => ({
  type: SET_LOGIN_REDIRECT_LOCATION,
  payload: null,
});

export const setRouteParams = (params: object) => ({
  type: SET_ROUTE_PARAMS,
  payload: params,
});

export const setLoginRedirectLocationIfNeeded =
  (location: RouteLocation) => (dispatch, getState) => {
    if (!isAuthenticated(getState()) && location.pathname !== '/login') {
      dispatch(setLoginRedirectLocation(location));
    }
  };

export const shouldRedirectToInitialScreen = () => (_dispatch, getState) => {
  return !isAuthenticated(getState()) || !api.accessToken;
};

const getLastLocationPathname = (
  state: State,
  action: { payload: { pathname: string } }
) => {
  const locationBeforeTransitions = state.get('locationBeforeTransitions');

  const hasLocationPathnameChanged =
    locationBeforeTransitions &&
    locationBeforeTransitions.pathname !== action.payload.pathname;

  const lastLocationPathname =
    hasLocationPathnameChanged && locationBeforeTransitions
      ? locationBeforeTransitions.pathname
      : state.get('lastLocationPathname');

  return lastLocationPathname;
};

const updateHistory = (
  history: List<HistoryRouteItem>,
  action: RouteAction,
  key: string,
  pathname: string
) => {
  switch (action) {
    case 'PUSH':
      return history.push({ key, pathname, action });
    case 'POP':
      if (!history.some(item => item!.key === key)) {
        return history.push({ key, pathname, action });
      }
      return history.slice(
        0,
        history.findIndex(item => item.key === key) + 1
      ) as List<HistoryRouteItem>;
    case 'REPLACE':
      return (history.butLast() as List<HistoryRouteItem>).push({
        key,
        pathname,
        action,
      });
    default:
      return history;
  }
};
/**
 * Given a LOCATION_CHANGE-action and a new key, apply it to the key-stack.
 * PUSH just pushes key, REPLACE removes last and pushes key.
 * POP removes N elements up to key in stack if found.
 * If POP does not find the key then it is pushed. This scenario is possible for
 * the first LOCATION_CHANGE action when the app starts.
 */
const updateKeys = (historyKeys: List<string>, action: string, key: string) => {
  switch (action) {
    case 'PUSH':
      return historyKeys.push(key);

    case 'POP':
      if (!historyKeys.contains(key)) {
        return historyKeys.push(key);
      }
      return historyKeys.slice(0, historyKeys.indexOf(key) + 1) as List<string>;

    case 'REPLACE':
      return (historyKeys.butLast() as List<string>).push(key);

    default:
      return historyKeys;
  }
};

export const goBackIfHistory = (): BaseThunk<void> => (dispatch, getState) => {
  const navigate = getMultiPlatformNavigation();
  return isPurchaseFlow(getState()) || getHistoryKeys(getState()).length > 0
    ? dispatch(
        navigate({
          type: 'inner:back',
          src: null,
        })
      )
    : dispatch(
        navigate({
          type: 'inner:replace',
          src: '/',
        })
      );
};

export const navigateBack =
  (steps: number): BaseThunk<void> =>
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(navigate({ type: 'inner:go', src: steps }));
  };

export const resetHistory = (location: RouteLocation) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner:replace',
      src: '/',
    })
  );
  if (location.pathname !== '/') {
    dispatch(
      navigate({
        type: 'inner',
        src: {
          pathname: location.pathname,
          query: { ...location.query },
        },
      })
    );
  }
};

export const replaceInModal =
  (location: string): BaseThunk<void> =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const formatLocation =
      location.slice(0, 1) === '/' ? location : `/${location}`;
    const locationBeforeModal = getLastLocationPathnameSelector(getState());
    const path =
      locationBeforeModal === '/'
        ? formatLocation
        : `${locationBeforeModal}${formatLocation}`;
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: path,
      })
    );
  };

export const goBackWithFallback =
  (fallbackLocation?: string): BaseThunk<void> =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    return getLastLocationPathnameSelector(getState()) === '/' &&
      fallbackLocation
      ? dispatch(navigate({ type: 'inner', src: fallbackLocation }))
      : dispatch(navigate({ type: 'inner:back', src: null }));
  };
