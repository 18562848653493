import * as React from 'react';

const ToolTip: React.FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ className = '', children }) => (
  <div className={`ToolTip ${className}`}>
    <div className="ToolTip__balloon">
      <div className="ToolTip__content">{children}</div>
      <div className="ToolTip__arrow" />
    </div>
  </div>
);

export default ToolTip;
