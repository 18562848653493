import { Record } from 'immutable';

type ExtraTime = {
  uid: string;
  seconds: number;
};

type ExtraTimeRecord = Record<ExtraTime>;

const ExtraTimeRecord = Record<
  ExtraTime,
  {
    fromPayload: Record.Parser<ExtraTime, Record<ExtraTime>>;
    serialize: Record.Serializer<Record<ExtraTime>, ExtraTime>;
  }
>({
  uid: '',
  seconds: 0,
});

ExtraTimeRecord.fromPayload = payload => {
  return ExtraTimeRecord({
    ...payload,
  });
};

export default ExtraTimeRecord;
