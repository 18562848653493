import * as React from 'react';
import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { getLicense, getAccount, getCurrentTime } from '../../selectors';
import {
  devicesString,
  profilesString,
  LicenseType,
  isNonExpiringPremium,
  getLicenseDaysLeft,
  getProperLicenseText,
} from '../../records/license';
import { expiryDate } from '../../helpers/dates';
import WelcomeFreeLicenseModal from '../../components/Modal/WelcomeModal';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => {
  const license = getLicense(state);
  const account = getAccount(state);

  const expiryDay = expiryDate(account.locale, license.endDate) || '';
  const days = getLicenseDaysLeft(
    license,
    getCurrentTime(state),
    account.timezone
  );
  const daysRemaining =
    days > 1 ? t('for {{days}} days.', { days }) : t('until tomorrow.');

  const footerButtonText =
    license.type === LicenseType.Free
      ? t('Continue')
      : t('Start using Premium');

  const footerClass =
    license.type === LicenseType.Premium ? 'premium-license' : 'free-license';

  return {
    pText: pText(license, expiryDay, daysRemaining),
    footerButtonText,
    footerClass,
  };
};

const pText = (license, expiryDay, daysRemaining) => {
  switch (license.type) {
    case LicenseType.Free:
      return t(
        "View all your childrens' online activity here. " +
          'You can protect {{maxProfiles}} and {{maxDevices}} with all Free features.',
        {
          maxDevices: devicesString(license),
          maxProfiles: profilesString(license),
        }
      );
    case LicenseType.Trial:
      return t(
        "View all your childrens' online activity here. " +
          "You'll be able to use your app to {1}supervise up to {{maxProfiles}} or devices{/1} " +
          'and access all {2}Premium features{/2} free {{daysRemaining}}',
        {
          1: str => <span>{str}</span>,
          2: str => <span>{str}</span>,
          maxDevices: devicesString(license),
          maxProfiles: profilesString(license),
          daysRemaining,
        }
      );
    case LicenseType.Premium:
      return isNonExpiringPremium(license)
        ? getProperLicenseText(
            license.subtype,
            t(
              "View all your children's online activity here. " +
                'You can {1}protect {{maxDevices}} with ' +
                '{{planName}} features{/1}.',
              {
                1: str => <span>{str}</span>,
                planName: 'Basic',
                maxDevices: devicesString(license),
              }
            ),
            t(
              "View all your children's online activity here. " +
                'You can {1}protect {{maxDevices}} with ' +
                '{{planName}} features{/1}.',
              {
                1: str => <span>{str}</span>,
                planName: 'Complete',
                maxDevices: devicesString(license),
              }
            ),
            t(
              "View all your children's online activity here. " +
                'You can {1}protect {{maxProfiles}} and {{maxDevices}} with all ' +
                'Premium features{/1}.',
              {
                1: str => <span>{str}</span>,
                maxDevices: devicesString(license),
                maxProfiles: profilesString(license),
              }
            )
          )
        : getProperLicenseText(
            license.subtype,
            t(
              "View all your childrens' online activity here. " +
                'You can {1}protect {{maxDevices}} with' +
                '{{planName}} features{/1} until {{days}}.',
              {
                1: str => <span>{str}</span>,
                planName: 'Basic',
                maxDevices: devicesString(license),
                days: expiryDay,
              }
            ),
            t(
              "View all your childrens' online activity here. " +
                'You can {1}protect {{maxDevices}} with' +
                '{{planName}} features{/1} until {{days}}.',
              {
                1: str => <span>{str}</span>,
                maxDevices: devicesString(license),
                planName: 'Complete',
                days: expiryDay,
              }
            ),
            t(
              "View all your childrens' online activity here. " +
                'You can {1}protect {{maxProfiles}} and {{maxDevices}} ' +
                'with all Premium features{/1} until {{days}}.',
              {
                1: str => <span>{str}</span>,
                maxDevices: devicesString(license),
                maxProfiles: profilesString(license),
                days: expiryDay,
              }
            )
          );
    default:
      return '';
  }
};

const mapDispatchToProps = dispatch => ({
  onClickLink: (l = '/') => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: l,
      })
    );
  },
});

const WelcomeFreeLicenseModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeFreeLicenseModal);

export default WelcomeFreeLicenseModalContainer;
