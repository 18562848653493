import State from '../store/state';

export const getPushPlatform = (state: State) =>
  state.get('pushService').get('pushPlatform');

export const getPushRegistrationId = (state: State) =>
  state.get('pushService').get('registrationId');

export const hasPushPermission = (state: State) =>
  state.get('pushService').get('hasPermission');

export const isInitializing = (state: State) =>
  state.get('pushService').get('initializing');

export const isInitialized = (state: State) =>
  state.get('pushService').get('initialized');
