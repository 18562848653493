import { connect } from 'react-redux';
import { Iterable } from 'immutable';
import State, { Dispatch } from '../../store/state';
import {
  getAccountCurrentTime,
  getAccountLocale,
  getRangeScreenTime,
  getAverageScreenTime,
  getTimeQuotas,
  getTimezone,
  getSelectedPastDate,
  getScreenTimeMinutes,
  getActiveDateRangeNormalized,
  isFetchingProfile,
  getSummaryScreenTimeActivityError,
  getSelectedDateDefaultRulesScreenTimeMinutes,
  getSelectedDateRoutinesScreenTimeMinutes,
  getTotalDefaultRulesScreenTimeForDateRange,
  getTotalRoutinesScreenTimeForDateRange,
  hasSeparatedScreenTimeForSelectedDate,
  hasSeparatedScreenTimeForDateRange,
} from '../../selectors';
import {
  formatDateNiceShort,
  MINS_IN_A_DAY,
  Weekday,
  WEEKDAYS,
} from '../../helpers/dates';
import { SummaryDateRanges } from '../../constants';
import { ProfileRecord } from '../../records';
import { getExtraTimeInMinutes } from '../../selectors/extraTime';
import TotalScreenTime from '../../components/TotalScreenTime/TotalScreenTime';
import { t } from '../../lib/i18n';
import { loadScreenTimeActivityCard } from '../../ducks/summary';
import type { ProfileRecord as ProfileRecordType } from '../../records/profile/types/Profile.types';
import { HoursPerDayActivityRecord } from '../../records/activity/types/HoursPerDayActivity.types';
import { getRoutinesStatus } from '../../ducks/routines/selectors';
import { getUnfilteredRoutinesRecordsMap } from '../../ducks/routines/selectors/unfiltered';

interface Props {
  profile?: ProfileRecordType;
  isMobileView?: boolean;
}

const todayOrCustomPastDay = [
  SummaryDateRanges.Today,
  SummaryDateRanges.CustomPastDay,
];

export const getTotalSpendMinutes = (
  activeDateRange: SummaryDateRanges,
  state: State
) => {
  if (todayOrCustomPastDay.includes(activeDateRange))
    return getScreenTimeMinutes(state);
  return sumHoursToMinutes(getRangeScreenTime(state).takeLast(activeDateRange));
};

const getDefaultAndRoutinesSpentMinutes = (
  activeDateRange: SummaryDateRanges,
  state: State
) => {
  if (todayOrCustomPastDay.includes(activeDateRange)) {
    return {
      defaultRules: getSelectedDateDefaultRulesScreenTimeMinutes(state),
      routines: getSelectedDateRoutinesScreenTimeMinutes(state),
    };
  }

  return {
    defaultRules: getTotalDefaultRulesScreenTimeForDateRange(state),
    routines: getTotalRoutinesScreenTimeForDateRange(state),
  };
};

const getLabel = (activeDateRange: SummaryDateRanges, state: State) => {
  if (activeDateRange === SummaryDateRanges.Today) {
    return t('Today');
  }

  if (activeDateRange === SummaryDateRanges.CustomPastDay) {
    const locale = getAccountLocale(state);
    const timezone = getTimezone(state);
    const customDay = getSelectedPastDate(state);
    return formatDateNiceShort(customDay, timezone, locale);
  }

  return t(`${activeDateRange}-day total`);
};

const sumHoursToMinutes = (
  screenTime: Iterable<number, HoursPerDayActivityRecord>
) => screenTime.reduce((total, item) => item?.hours + total, 0) * 60;

const calculateIncrementScreenTimeAverage = (
  activeDateRange: SummaryDateRanges,
  state: State
) => {
  const withoutAverage = [
    SummaryDateRanges.Today,
    SummaryDateRanges.CustomPastDay,
    SummaryDateRanges.Days30,
  ];
  if (withoutAverage.includes(activeDateRange)) {
    return {
      hasIncrementScreenTimeAverage: null,
      incrementScreenTimeAverage: null,
    };
  }

  const screenTimeRange = getRangeScreenTime(state).takeLast(
    activeDateRange * 2
  );
  const pastScreenTimeRange = screenTimeRange.take(activeDateRange);
  const currentScreenTimeRange = screenTimeRange.takeLast(activeDateRange);
  const pastScreenTime = sumHoursToMinutes(pastScreenTimeRange);
  const currentScreenTime = sumHoursToMinutes(currentScreenTimeRange);

  if (pastScreenTime === 0 || currentScreenTime === 0) {
    return {
      hasIncrementScreenTimeAverage: null,
      incrementScreenTimeAverage: null,
    };
  }
  const difference = currentScreenTime - pastScreenTime;
  const quotient = difference / Math.abs(pastScreenTime);
  const average = Math.abs(Math.round(quotient * 100));

  return {
    hasIncrementScreenTimeAverage:
      average === 0 ? null : currentScreenTime > pastScreenTime,
    incrementScreenTimeAverage: average === 0 ? null : average,
  };
};

const calculateIsBlocked = (
  dailyLimitMinutes: number | null,
  extraTime: number | null,
  totalSpentMinutes: number
) => {
  if (extraTime && dailyLimitMinutes) {
    return totalSpentMinutes >= dailyLimitMinutes + extraTime;
  }

  if (!extraTime && dailyLimitMinutes) {
    return totalSpentMinutes >= dailyLimitMinutes;
  }

  return totalSpentMinutes >= MINS_IN_A_DAY;
};

/**
 * Calculates the daily time limits.
 * Daily time limits only can exist for the current day.
 * If daily time limits is 24 hours, it means that there is no limit.
 *
 */
const calculateDailyTimeLimits = ({
  activeDateRange,
  state,
  profileId,
  profile,
  currentWeekday,
}: {
  activeDateRange: SummaryDateRanges;
  state: State;
  profileId: string;
  profile: ProfileRecordType;
  currentWeekday: Weekday;
}) => {
  const dailyLimitMinutes =
    activeDateRange === SummaryDateRanges.Today
      ? (getTimeQuotas as any)(
          state,
          profileId,
          profile.deviceIds.first() || 0,
          currentWeekday
        )
      : 0;

  return dailyLimitMinutes === MINS_IN_A_DAY ? null : dailyLimitMinutes;
};

const isScreenTimeDataSeparated = (activeDateRange: SummaryDateRanges) =>
  todayOrCustomPastDay.includes(activeDateRange)
    ? hasSeparatedScreenTimeForSelectedDate
    : hasSeparatedScreenTimeForDateRange;

const mapStateToProps = (state: State, { profile, isMobileView }: Props) => {
  const profileId = profile?.id;
  const currentTime = getAccountCurrentTime(state);
  const currentWeekday = WEEKDAYS[currentTime.isoWeekday() - 1];
  const activeDateRange = getActiveDateRangeNormalized(state);
  const extraTime = getExtraTimeInMinutes(state);
  const totalSpentMinutes = getTotalSpendMinutes(activeDateRange, state);
  const isFetchingInfo = isFetchingProfile(state);
  const isFetchingRoutines = getRoutinesStatus(state).get('read') === 'loading';
  const hasError = Boolean(getSummaryScreenTimeActivityError(state));
  const { hasIncrementScreenTimeAverage, incrementScreenTimeAverage } =
    calculateIncrementScreenTimeAverage(activeDateRange, state);
  const averageSpendMinutes = Math.floor(
    getAverageScreenTime(state, activeDateRange) * 60
  );

  const dailyLimitMinutes = calculateDailyTimeLimits({
    activeDateRange,
    state,
    profileId: profileId?.toString() || '',
    profile: profile || ProfileRecord({}),
    currentWeekday,
  });

  const isBlocked = calculateIsBlocked(
    dailyLimitMinutes,
    extraTime,
    totalSpentMinutes
  );

  const routinesMap = getUnfilteredRoutinesRecordsMap(state);
  const {
    defaultRules: defaultRulesSpentMinutes,
    routines: routinesSpentMinutes,
  } = getDefaultAndRoutinesSpentMinutes(activeDateRange, state);
  const hasSeparatedScreenTime =
    isScreenTimeDataSeparated(activeDateRange)(state);

  return {
    activeDateRange,
    label: getLabel(activeDateRange, state),
    averageSpendMinutes,
    dailyLimitMinutes,
    isBlocked,
    totalSpentMinutes,
    extraTime,
    hasIncrementScreenTimeAverage,
    incrementScreenTimeAverage,
    isFetchingInfo: isFetchingInfo || isFetchingRoutines,
    hasError,
    isMobileView,
    routinesMap,
    defaultRulesSpentMinutes,
    routinesSpentMinutes,
    hasSeparatedScreenTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { profile }: Props) => {
  return {
    loadWidget: () => {
      dispatch(
        loadScreenTimeActivityCard({ profileId: profile?.id.toString() || '' })
      );
    },
  };
};

const TotalScreenTimeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalScreenTime);

export default TotalScreenTimeContainer;

export type TotalScreenTimeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
