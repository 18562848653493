import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { getFormValues, isInvalid } from 'redux-form/immutable';
import { t } from '../lib/i18n';
import { goBackIfHistory } from '../ducks/routing';
import { formRecord, formName } from '../components/Profiles/ProfileForm';
import ProfileEdit from '../components/Profiles/ProfileEdit';
import { modifyProfile } from '../ducks/profiles';
import { ProfileRecord } from '../records';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import { confirmDeleteProfile } from '../actions/ProfileActions';
import { showToast, TOAST_ICON_TICK } from '../ducks/toast';
import { getProfile } from '../selectors';
import {
  trackSuccessfulProfileEditAttempt,
  trackFailedProfileEditAttempt,
} from '../helpers/analytics';

const values = getFormValues(formName);
const invalid = isInvalid(formName);

const mapStateToProps = (state, { params }) => {
  const profileId = parseInt(params.profileId, 10);
  const { id, name, gender, birthDate, picture } = ProfileRecord.serialize(
    state
      .get('records')
      .getIn(['profiles', profileId.toString()], ProfileRecord())
  ) as any;

  return {
    initialValues: formRecord({ id, name, gender, birthDate, picture }),
    form: formRecord(values(state)),
    disabled: !!(
      state.getIn(['form', formName], fromJS({})).get('submitFailed') &&
      invalid(state)
    ),
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onNextClick: () =>
    dispatch((dispatch, getState) => {
      dispatch(
        showModifyProfile(
          parseInt(profileId, 10),
          formRecord(values(getState()))
        )
      );
    }),
  onClickDelete: () => dispatch(confirmDeleteProfile(parseInt(profileId, 10))),
});

const ProfileEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEdit);

export function showModifyProfile(id, form) {
  return (dispatch, getState) => {
    const currentProfileValues = ProfileRecord.serialize(
      ProfileRecord.fromPayload(getProfile(getState(), id))
    );
    const formValues = form.toJS();
    const profile = ProfileRecord.fromPayload(formValues).set('id', id);
    return dispatch(selectModifyProfile(ProfileRecord.serialize(profile)))
      .then(() => {
        return dispatch(
          showToast(
            t('{{name}} profile is updated', {
              name: getProfile(getState(), id).name,
            }),
            TOAST_ICON_TICK
          )
        );
      })
      .then(() => {
        trackSuccessfulProfileEditAttempt(currentProfileValues, formValues);
        dispatch(goBackIfHistory());
      })
      .catch((error: AnyError) => {
        trackFailedProfileEditAttempt(currentProfileValues, formValues);
        return handleApiErrorMessage(error);
      });
  };
}

export function selectModifyProfile(profile) {
  return dispatch => dispatch(modifyProfile(profile));
}

export default ProfileEditContainer;
