import * as React from 'react';
import { classNames } from '../../helpers';

export interface DrawerStepWrapperProps {
  header: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  fixedFooter?: boolean;
  className?: string;
  testId?: string;
}

const DrawerStepWrapper = ({
  header,
  footer,
  fixedFooter = false,
  children,
  className,
  testId = undefined,
}: DrawerStepWrapperProps) => {
  return (
    <div
      data-testid={testId}
      className={classNames(
        'par-step-wrapper',
        fixedFooter ? 'par-step-wrapper--fixed-footer' : null,
        className
      )}
    >
      <div className="par-step-wrapper__header">{header}</div>
      <div className="par-step-wrapper__body">{children}</div>
      {footer && <div className="par-step-wrapper__footer">{footer}</div>}
    </div>
  );
};

export default DrawerStepWrapper;
