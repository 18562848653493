import { BaseThunk } from '../../store/state';
import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import {
  updateAccount,
  modifyAccount,
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { AccountRecord } from '../../records';
import { getAccount } from '../../selectors';
import { showErrorToast } from './helpers';
import EditLanguage from '../../components/AccountSettings/EditLanguage';
import { setMomentLocale } from '../../sideEffects/globals';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import { ISOLanguage } from '../../constants';

const mapStateToProps = state => {
  const { locale } = getAccount(state);
  return {
    locale,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickSaveButton: (language: ISOLanguage) =>
    dispatch(onClickSaveButton(language)),
});

const onClickSaveButton =
  (language: ISOLanguage): BaseThunk<any> =>
  (dispatch, getState) => {
    Promise.resolve(setAccountRecord(language, getState()))
      .then(updatedAccount => {
        dispatch(updateAccount(updatedAccount));
        return updatedAccount;
      })
      .then(updatedAccount => {
        dispatch(setMomentLocale());
        return updatedAccount;
      })
      .then(updatedAccount => {
        dispatch(goBackIfHistory());
        return updatedAccount;
      })
      .then(updatedAccount => {
        dispatch(modifyAccount(updatedAccount));
        return updatedAccount;
      })
      .then(() =>
        dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
      )
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(reverseAccountUpdate());
        dispatch(showErrorToast(error));
      });
  };

function setAccountRecord(locale, state) {
  const account = getAccount(state).set('locale', locale);
  return AccountRecord(account);
}

const EditLanguageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLanguage);

export default EditLanguageContainer;
