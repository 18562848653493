import * as R from 'ramda';
import { List, OrderedSet } from 'immutable';
import { convertDate, durationInMinutes, localNow } from '../../helpers/dates';
import { UserDevice, getProtectionStatus } from '../../records/device';
import {
  getProfileDevices,
  getProfileRules,
  getProfiles,
  getTimezone,
} from '../../selectors';
import {
  hasScreenTimeReached,
  hasTimeRestriction,
  isInternetPaused,
} from '../../containers/NowCard/helpers/family/helper';
import { DeviceRecord } from '../../records/device/types/Device.types';
import State from '../../store/state';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import {
  getCurrentSchoolEndDatetime,
  getCurrentSchoolStartDatetime,
  getIsPauseActive,
  getIsPauseEnabled247,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import {
  isPauseCurrentlyActive,
  isSchoolTime,
} from '../../containers/NowCard/helpers/schools/helper';
import {
  LongPeriodNoActivityInMInutes,
  MaxNoActivityTimeInMinutes,
  PLATFORM_IOS,
} from '../../constants';
import { t } from '../../lib/i18n';
import { getActiveRoutine } from '../../ducks/routines/selectors';
import {
  doesRoutineConsumeDailyTimeQuota,
  isRoutineTypeBlocked,
} from '../../ducks/routines/helpers';
import { RoutineRecord } from '../../records/routines/types/Routine.types';
import flags from '../../sideEffects/flags';
import { TimeSettingsRecord } from '../../records';
import { DeviceAdvanceProtectionStatus } from './types';

export interface getDeviceStatusProps {
  profileId: string;
  device: DeviceRecord;
  state: State;
  profiles: OrderedSet<ProfileRecord>;
  activeRoutine?: RoutineRecord | null;
  deviceUser?: UserDevice | null;
}

export interface GetSchoolAndDelegatedDeviceProps {
  profileId: string;
  state: State;
}

export const DEVICE_STATUS_TYPES = [
  'paused',
  'locked',
  'tampered',
  'online',
  'lastActivity',
  'schoolHours',
  'disabled',
  'offline',
  'safeNetwork',
  'outsideSchoolHours',
  'unknown',
] as const;

export type DeviceStatusType = typeof DEVICE_STATUS_TYPES[number];

export interface DeviceStatus {
  name: DeviceRecord['name'];
  lastseen: UserDevice['lastseen'];
  isOnline: UserDevice['isOnline'];
  status: DeviceStatusType;
  type: DeviceRecord['type'];
  id: DeviceRecord['id'] | null;
  isDelegated: boolean;
  affiliation: 'family' | 'school';
}

export type DevicesStatus = Array<DeviceStatus>;

const getDeviceStatus = ({
  profileId,
  device,
  state,
  profiles,
  activeRoutine,
  deviceUser,
}: getDeviceStatusProps): DeviceStatusType => {
  const deviceLastSeen = deviceUser?.lastseen
    ? deviceUser?.lastseen
    : device.lastseen;
  const deviceOnline = Boolean(deviceUser?.isOnline);
  const status = getProtectionStatus(device, profiles);
  const timezone = getTimezone(state);
  const currentTime = localNow(timezone);
  const lastseen = convertDate(deviceLastSeen, timezone);
  const minutes = durationInMinutes(currentTime, lastseen);
  const currentTimeRestrictions = getProfileRules(
    state,
    profileId
  )?.timeRestrictions;

  if (status === 'ProtectionTampered') return 'tampered';
  if (status === 'ProtectionDisabled') return 'disabled';
  if (minutes >= MaxNoActivityTimeInMinutes) return 'offline';
  if (status === 'SafeNetwork') return 'safeNetwork';
  if (isInternetPaused(state)) return 'paused';
  if (
    isStatusBlocked({
      activeRoutine,
      state,
      profileId,
      currentTimeRestrictions,
    })
  )
    return 'locked';
  if (status === 'ProtectionEnabled' && !deviceOnline) return 'lastActivity';
  if (status === 'ProtectionEnabled' && deviceOnline) return 'online';
  return 'unknown';
};

export const toDevicesWithStatus = (
  profileId: string,
  state: State
): DevicesStatus => {
  const devices = getProfileDevices(state, profileId);
  const profiles = getProfiles(state);
  const { activeRoutine } = getActiveRoutine(state, profileId);

  return devices
    .map(device => {
      const deviceUser = device.users
        .filter(user => {
          return user.profileId === Number(profileId);
        })
        .first();
      return {
        status: getDeviceStatus({
          profileId,
          device,
          profiles,
          state,
          activeRoutine,
          deviceUser,
        }),
        name: device.name,
        lastseen: deviceUser?.get('lastseen')
          ? deviceUser?.get('lastseen')
          : device.lastseen,
        type: device.type,
        id: device.id,
        isDelegated: false,
        affiliation: 'family',
        isOnline: deviceUser?.get('isOnline') ?? null,
      };
    })
    .toArray();
};

export const getVirtualSchoolDeviceStatus = ({
  state,
  profileId,
}: GetSchoolAndDelegatedDeviceProps): DeviceStatusType => {
  const currentStartSchoolTimes = getCurrentSchoolStartDatetime(state);
  const currentEndSchoolTimes = getCurrentSchoolEndDatetime(state);
  const isPauseEnabled247 = getIsPauseEnabled247(state);
  const isPauseActive = getIsPauseActive(state);
  const isClassTime = isSchoolTime(
    currentStartSchoolTimes,
    currentEndSchoolTimes
  );
  const hasProfileDelegated = isStudentOptInForDelegationForThisAccount(state);
  const currentTimeRestrictions = getProfileRules(
    state,
    profileId
  )?.timeRestrictions;
  const { activeRoutine } = getActiveRoutine(state, profileId);

  if (isClassTime) return 'schoolHours';
  if (isPauseCurrentlyActive(isPauseActive, isClassTime, isPauseEnabled247))
    return 'paused';
  if (hasProfileDelegated && flags.useDelegation.isEnabled()) {
    if (
      ((hasTimeRestriction(state, profileId) ||
        hasScreenTimeReached(state, profileId, true)) &&
        (currentTimeRestrictions?.isLockComputer ||
          currentTimeRestrictions?.isLockNavigation)) ||
      isRoutineTypeBlocked(activeRoutine)
    )
      return 'locked';
  }
  return 'outsideSchoolHours';
};

/**
 * return an extra virtual device non registered in Qustodio, it may be a school only device
 * a delegated device, or a delegated device by another account, the latter
 * is considered the same as a only school device
 */
export const getVirtualSchoolDevice = (
  state: State,
  profileId: string
): DeviceStatus => {
  const hasProfileDelegated = isStudentOptInForDelegationForThisAccount(state);
  return {
    name: t('School device'),
    lastseen: null,
    isOnline: null,
    status: getVirtualSchoolDeviceStatus({ state, profileId }),
    type: 'PC',
    id: null,
    isDelegated: hasProfileDelegated,
    affiliation: 'school',
  };
};

export const getDevicesOffline = (
  devices: List<DeviceRecord>,
  timezone: string
) => {
  const currentTime = localNow(timezone);
  return devices.filter(device => {
    const lastseen = convertDate(device.lastseen, timezone);
    const minutes = durationInMinutes(currentTime, lastseen);
    return minutes >= MaxNoActivityTimeInMinutes;
  });
};

// we consider a device has been offline for a long period after the 30 days of inactivity
export const getDevicesOfflineLongPeriod = (
  devices: List<DeviceRecord>,
  timezone: string
) => {
  const currentTime = localNow(timezone);
  return devices.filter(device => {
    const lastseen = convertDate(device.lastseen, timezone);
    const minutes = durationInMinutes(currentTime, lastseen);
    return minutes >= LongPeriodNoActivityInMInutes;
  });
};

const isStatusBlocked = ({
  state,
  profileId,
  currentTimeRestrictions,
  activeRoutine,
}: {
  state: State;
  profileId: string;
  currentTimeRestrictions: TimeSettingsRecord;
  activeRoutine: RoutineRecord | null | undefined;
}) => {
  if (activeRoutine && !doesRoutineConsumeDailyTimeQuota(activeRoutine))
    return false;
  if (activeRoutine && isRoutineTypeBlocked(activeRoutine)) return true;

  // If the routine consumes quota, apply the same conditions as the default rules. Therefore, it is called recursively without routine.
  if (
    activeRoutine &&
    doesRoutineConsumeDailyTimeQuota(activeRoutine) &&
    isStatusBlocked({
      state,
      profileId,
      currentTimeRestrictions,
      activeRoutine: null,
    })
  )
    return true;

  if (
    !activeRoutine &&
    hasTimeRestriction(state, profileId) &&
    currentTimeRestrictions?.isLockComputer
  )
    return true;
  if (
    !activeRoutine &&
    hasTimeRestriction(state, profileId) &&
    currentTimeRestrictions?.isLockNavigation
  )
    return true;
  if (
    !activeRoutine &&
    hasScreenTimeReached(state, profileId, true) &&
    currentTimeRestrictions?.isLockComputer
  )
    return true;
  if (
    !activeRoutine &&
    hasScreenTimeReached(state, profileId, true) &&
    currentTimeRestrictions?.isLockNavigation
  )
    return true;
  return false;
};

/**
 * Check device Advance Anti Tampering Protection status
 * @see https://qustodio.atlassian.net/browse/ATI-44
 */
export const getDeviceAdvanceProtectionStatus = (
  device?: DeviceRecord
): DeviceAdvanceProtectionStatus => {
  if (!device) {
    return 'unknown';
  }

  if (device.platform !== PLATFORM_IOS) {
    return 'unknown';
  }

  const { mdm } = device;

  if (isMdmProfileStatusInvalid(mdm)) {
    return 'unknown';
  }

  // We need to do this check for typescript even though this check has been done already
  if (!mdm || !mdm.status) {
    return 'unknown';
  }

  const { isMdmRemovable, isSupervised } = mdm.status;

  // Advance protection is NOT INSTALLED
  if (!isSupervised && isMdmRemovable) {
    return 'disabled';
  }

  // Advance protection is INSTALLED
  if (isSupervised && !isMdmRemovable) {
    return 'enabled';
  }

  // Edge case
  // Advance protection is NOT INSTALLED yet, only supervised
  if (isSupervised && isMdmRemovable) {
    return 'disabled';
  }

  // This is a problematic state
  // The device has an unremovable mdm but it’s not supervised. We won’t be able to fix this scenario.
  if (!isSupervised && !isMdmRemovable) {
    return 'unknown';
  }

  // Unreachable
  throw new Error('Unreachable: Incorrect mdm information was received');
};

const isMdmProfileStatusInvalid = (mdm: DeviceRecord['mdm']): boolean =>
  R.isNil(mdm) ||
  R.isNil(mdm.status) ||
  R.isNil(mdm.status?.isSupervised) ||
  R.isNil(mdm.status?.isMdmRemovable);
