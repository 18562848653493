import { Record, List } from 'immutable';
import * as Moment from 'moment-timezone';
import * as camelize from 'camelize';
import * as R from 'ramda';
import { CategoryRecord } from './category';
import { isActionBlock, Action, makeAction } from './event';
import { ACTION_IGNORE, VideoSources, DOMAIN_YOUTUBE } from '../constants';

export enum ReputationType {
  REPUTATION_CHILD_SAFETY,
  REPUTATION_TRUSTWORTHINESS,
}

export enum ReputationLevel {
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  LEVEL_5,
  LEVEL_UNKNOWN,
}
export interface UrlDetailsEvent {
  url: string;
  action: Action;
}

export type UrlReputationItemRecord = {
  type: ReputationType | null;
  level: ReputationLevel;
};

export const UrlReputationItemRecord = Record<
  UrlReputationItemRecord,
  {
    fromPayload: (response: any) => UrlReputationItemRecord;
  }
>({
  type: null,
  level: ReputationLevel.LEVEL_UNKNOWN,
});

UrlReputationItemRecord.fromPayload = R.pipe<any, any, UrlReputationItemRecord>(
  camelize,
  payload => {
    return UrlReputationItemRecord({
      type: payload.type,
      level: getReputationLevelByPercentage(payload.level),
    });
  }
);

export type UrlReputationRecord = {
  childSafety: UrlReputationItemRecord;
  trustworthiness: UrlReputationItemRecord;
};

export const UrlReputationRecord = Record<
  UrlReputationRecord,
  {
    fromPayload: (response: any) => UrlReputationRecord;
  }
>({
  childSafety: UrlReputationItemRecord({
    type: ReputationType.REPUTATION_CHILD_SAFETY,
    level: ReputationLevel.LEVEL_UNKNOWN,
  }),
  trustworthiness: UrlReputationItemRecord({
    type: ReputationType.REPUTATION_TRUSTWORTHINESS,
    level: ReputationLevel.LEVEL_UNKNOWN,
  }),
});
export type DetailsItemRecord = UrlDetailsPageRecord | VideoDetailsRecord;

export type UrlDetailsRecord = {
  key: string | null;
  screenshot: string;
  reputation: UrlReputationRecord;
  items: List<DetailsItemRecord>;
};

export const UrlDetailsRecord = Record<
  UrlDetailsRecord,
  {
    fromPayload: (response: any) => UrlDetailsRecord;
    defaultRecord: () => UrlDetailsRecord;
  }
>({
  key: null,
  screenshot: '',
  reputation: UrlReputationRecord(),
  items: List(),
});

const composeUrlDetailsRecordItems = (
  payload: any
): List<DetailsItemRecord> => {
  const videos: List<DetailsItemRecord> =
    payload && payload.videos
      ? List(
          payload.videos
            .map(VideoDetailsRecord.fromPayload)
            .filter(vr => vr.source === VideoSources.Youtube)
        )
      : List();
  const pages: List<DetailsItemRecord> =
    payload && payload.items
      ? List(payload.items.map(UrlDetailsPageRecord.fromPayload))
      : List();
  if (videos.size === 0) return pages;
  return videos.concat(
    pages.filter(
      page =>
        !videos.some(video => video !== undefined && video.key === page.key)
    )
  );
};

UrlDetailsRecord.fromPayload = R.pipe<any, any, UrlDetailsRecord>(
  camelize,
  payload => {
    return UrlDetailsRecord({
      key: payload.key ? payload.key : null,
      screenshot: payload.screenshot,
      reputation: payload.reputation
        ? UrlReputationRecord.fromPayload(payload.reputation)
        : UrlReputationRecord(),
      items: composeUrlDetailsRecordItems(payload),
    });
  }
);

UrlReputationRecord.fromPayload = R.pipe<any, any, UrlReputationRecord>(
  camelize,
  payload => {
    return UrlReputationRecord({
      childSafety: UrlReputationItemRecord({
        type: ReputationType.REPUTATION_CHILD_SAFETY,
        level: getReputationLevelByPercentage(payload.childSafetyReputation),
      }),
      trustworthiness: UrlReputationItemRecord({
        type: ReputationType.REPUTATION_TRUSTWORTHINESS,
        level: getReputationLevelByPercentage(
          payload.trustworthinessReputation
        ),
      }),
    });
  }
);

export const getReputationLevelByPercentage = (
  reputation: number
): ReputationLevel => {
  switch (Math.ceil(reputation / 20)) {
    case 5:
      return ReputationLevel.LEVEL_5;
    case 4:
      return ReputationLevel.LEVEL_4;
    case 3:
      return ReputationLevel.LEVEL_3;
    case 2:
      return ReputationLevel.LEVEL_2;
    case 1:
      return ReputationLevel.LEVEL_1;
    default:
      return ReputationLevel.LEVEL_UNKNOWN;
  }
};

export const getTimeStampFromVideoKey = (video: VideoDetailsRecord): string =>
  video.key.split('|')[2];

export const getUTCDateFromVideoKey = (
  video: VideoDetailsRecord
): Moment.Moment =>
  Moment(new Date(parseInt(getTimeStampFromVideoKey(video), 10) * 1000)).utc();

export type VideoDetailsRecord = UrlDetailsEvent & {
  videoId: string;
  categoryTitle: string;
  channelTitle: string;
  thumbnail: string;
  action: Action;
  time: string;
  url: string;
  title: string;
  key: string;
  source: VideoSources;
  searchUrl: string;
};

export const VideoDetailsRecord = Record<
  VideoDetailsRecord,
  {
    fromPayload: (response: any) => VideoDetailsRecord;
  }
>({
  videoId: '',
  categoryTitle: '',
  channelTitle: '',
  thumbnail: '',
  action: { action: ACTION_IGNORE, reason: null },
  time: '',
  url: '',
  title: '',
  key: '',
  source: VideoSources.Unknown,
  searchUrl: '',
});

export const getVideoRuleSource = (url: string): VideoSources => {
  if (url.toLowerCase().indexOf(DOMAIN_YOUTUBE) !== -1) {
    return VideoSources.Youtube;
  }
  return VideoSources.Unknown;
};

VideoDetailsRecord.fromPayload = R.pipe<any, any, VideoDetailsRecord>(
  camelize,
  payload => {
    return VideoDetailsRecord({
      ...payload,
      action: makeAction(payload),
      time: payload.dt,
      source: getVideoRuleSource(payload.url),
    });
  }
);

export type UrlDetailsPageRecord = UrlDetailsEvent & {
  url: string;
  title?: string | null;
  categories: List<CategoryRecord>;
  categoriesMatch: List<CategoryRecord>;
  action: Action;
  time: string;
  key: string;
};

export const UrlDetailsPageRecord = Record<
  UrlDetailsPageRecord,
  {
    fromPayload: (response: any) => UrlDetailsPageRecord;
  }
>({
  url: '',
  title: null,
  categories: List(),
  categoriesMatch: List(),
  action: { action: ACTION_IGNORE, reason: null },
  time: '',
  key: '',
});

UrlDetailsPageRecord.fromPayload = R.pipe<any, any, UrlDetailsPageRecord>(
  camelize,
  payload => {
    return UrlDetailsPageRecord({
      url: payload.url,
      title: payload.title,
      categories: payload.categories
        ? List(payload.categories.map(CategoryRecord.fromPayload))
        : List(),
      categoriesMatch: payload.categoriesMatch
        ? List(payload.categoriesMatch.map(CategoryRecord.fromPayload))
        : List(),
      action: makeAction(payload),
      time: payload.dt,
      key: payload.key,
    });
  }
);

export const filterBlockedUrlDetailsItem = (
  pages: List<DetailsItemRecord>
): List<DetailsItemRecord> =>
  pages.filter(page => (page ? isActionBlock(page.action) : false));

export const filterNonBlockedUrlDetailsItem = (
  pages: List<DetailsItemRecord>
): List<DetailsItemRecord> =>
  pages.filter(page => (page ? !isActionBlock(page.action) : false));

export const filterUrlDetailsVideos = (
  items: List<DetailsItemRecord>
): List<DetailsItemRecord> =>
  items.filter(item => (item ? item instanceof VideoDetailsRecord : false));

export const filterUrlDetailsPages = (
  items: List<DetailsItemRecord>
): List<DetailsItemRecord> =>
  items.filter(item => (item ? item instanceof UrlDetailsPageRecord : false));

export default UrlDetailsRecord;
