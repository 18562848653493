import * as React from 'react';
import TamperedDeviceBannerList from '../TamperedDeviceBannerList/TamperedDeviceBannerList';
import ActivityTimelineContainer from '../../containers/ActivityTimeline/ActivityTimelineContainer';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PullRefresh from '../PullRefresh/PullRefresh';
import LoadingActivityPlaceholder from '../LoadingActivityPlaceholder/LoadingActivityPlaceholder';
import EmptyActivityPlaceholder from '../EmptyActivityPlaceholder/EmptyActivityPlaceholder';
import PageLayout from '../Layout/PageLayout';
import { t } from '../../lib/i18n';
import PageSectionLayout from '../Layout/PageSectionLayout';
import ScrollManager from '../Layout/hooks/useKeepScroll/ScrollManager';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { getTimelineSubtitle } from './activityTimelineHelpers';

const scrollKey = ScrollManager.getInstance().register('timeline');

type DefaultProps = {
  isFetching: boolean;
  isEmpty: boolean;
  profile: ProfileRecord;
};
type Props<T> = React.FunctionComponent<DefaultProps & T>;

const ActivityTimelinePage: Props<{
  isFetchingNewEvents: boolean;
  profile: ProfileRecord;
  shouldShowAlertTampered: boolean;
  isDelegatedToTheAccount: boolean;
  onRefreshExistentEvents: () => void;
  onFetchingMoreEvents: () => void;
  onDelegationInfoClick: () => void;
  onload: () => void;
}> = ({ ...props }) => {
  const { profile, shouldShowAlertTampered, isDelegatedToTheAccount } = props;
  React.useEffect(() => {
    props.onload();
  }, []);

  return (
    <PageLayout
      title={t('Activity Timeline')}
      subtitle={getTimelineSubtitle({
        isUnifiedProfile:
          profile.isLinewizestudentLinked && Boolean(profile.deviceCount),
        isDelegatedToThisAccount: isDelegatedToTheAccount,
        timelineType: 'family',
        // eslint-disable-next-line react/destructuring-assignment
        onDelegationInfoClick: props.onDelegationInfoClick,
      })}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainReducedSize: true,
        },
        {
          screenSize: ScreenSize.All,
          pullToRefresh: (
            <PullRefresh
              // eslint-disable-next-line react/destructuring-assignment
              isFetching={props.isFetchingNewEvents}
              // eslint-disable-next-line react/destructuring-assignment
              onRefresh={props.onRefreshExistentEvents}
              onScroll={({ target }) => {
                const targetHtml = target as HTMLElement;
                if (
                  targetHtml.scrollTop + targetHtml.clientHeight >=
                  targetHtml.scrollHeight - 100
                ) {
                  props.onFetchingMoreEvents();
                }
              }}
            />
          ),
          keepScrollKey: scrollKey,
        },
      ]}
    >
      {shouldShowAlertTampered && (
        <PageSectionLayout placeholder="card-banner">
          <RenderWhen screenSize={ScreenSize.Mobile}>
            <TamperedDeviceBannerList rounded={false} />
          </RenderWhen>
        </PageSectionLayout>
      )}
      <TimelineWrapper {...props} />
    </PageLayout>
  );
};

const TimelineWrapper: Props<{
  isFetchingNewEvents: boolean;
  onRefreshExistentEvents: () => void;
  onFetchingMoreEvents: () => void;
  // eslint-disable-next-line react/jsx-props-no-spreading
}> = ({ ...props }) => <Timeline {...props} />;

const Timeline: Props<unknown> = ({
  isFetching,
  isEmpty,
  profile,
}: DefaultProps) => {
  if (isFetching) {
    return <LoadingActivityPlaceholder />;
  }

  if (isEmpty) {
    return <EmptyActivityPlaceholder profileName={profile.name} />;
  }

  return <ActivityTimelineContainer profile={profile} />;
};

export default ActivityTimelinePage;
