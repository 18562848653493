import { ofType } from 'redux-observable';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Observable, concat, from, of } from 'rxjs';
import { AppActionWithPayload, AppEpic } from '../types';
import { getProfile } from '../../selectors/profileRaw';
import type { ProfileId } from '../../records/profile/types/Profile.types';
import {
  getNow,
  requestProfileFilter,
  summaryErrorHandler,
} from '../helpers/dataLoaderHelpers';
import {
  LOAD_YOUTUBE_ACTIVITY,
  fetchingYoutubeSummary,
  receiveYoutubeSummary,
  youtubeSummaryLoadingError,
} from '../../ducks/youtubeSummary';
import { getSelectedDateRange } from '../../selectors';
import { createUniqueResponseObjectWithSummaryJsons } from '../../records/youtubeSummary';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryYoutubeActivityAction = ReturnType<
  typeof receiveYoutubeSummary
>;
type FetchingYoutubeSummary = ReturnType<typeof fetchingYoutubeSummary>;
interface LoadSummaryYoutubeActivityAction {
  payload: {
    profileId: ProfileId;
  };
}
interface TotalVideosResponse {
  datetime: string;
  provider: string;
  total_videos: number;
}

interface TotalSearchesResponse {
  datetime: string;
  provider: string;
  total_searches: number;
}

interface lastSearchesResponse {
  text: string;
  datetime: string;
  provider: string;
}

const notifyIfError = catchError(e => {
  return of(summaryErrorHandler(e, youtubeSummaryLoadingError));
});

const getSummaryYoutubeActivity = (
  api,
  { profileUid, state }
): Promise<
  [TotalVideosResponse[], TotalSearchesResponse[], lastSearchesResponse[]]
> => {
  const { maxDate, minDate } = getSelectedDateRange(state);
  return Promise.all([
    api.youtubeSummaryTotalVideos.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
    api.youtubeSummaryTotalSearches.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
    api.youtubeSummaryLastVideoSearches.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
  ]);
};

const toReceiveSummaryYoutubeActivityAction = jsons => {
  return receiveYoutubeSummary(
    createUniqueResponseObjectWithSummaryJsons(jsons)
  );
};

export const SummaryYoutubeActivityEpic: AppEpic<
  AppActionWithPayload,
  ReceiveSummaryYoutubeActivityAction & FetchingYoutubeSummary
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_YOUTUBE_ACTIVITY),
    map((action: LoadSummaryYoutubeActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter(profile =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap(profile => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(fetchingYoutubeSummary()),
        from(
          getSummaryYoutubeActivity(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ).pipe(
          map(list => toReceiveSummaryYoutubeActivityAction(list)),
          notifyIfError
        )
      ) as Observable<
        FetchingYoutubeSummary & ReceiveSummaryYoutubeActivityAction
      >;
    })
  );

  return loadData;
};

export default SummaryYoutubeActivityEpic;
