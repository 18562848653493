import * as React from 'react';
import {
  Button,
  Modal,
  ButtonIconPosition,
  Icon,
  ButtonSize,
  IconType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

const RatingAppBadFeedbackModal: React.FunctionComponent<{
  isFree: boolean;
  text: string;
  buttonText: string;
  onClose: () => any;
  goToQustodioHelpCenter: () => any;
  openMailApp: () => any;
}> = ({
  isFree,
  text,
  buttonText,
  onClose,
  goToQustodioHelpCenter,
  openMailApp,
}) => (
  <Modal
    title={t("We're sorry you had a problem.")}
    buttons={[
      <Button
        key="button"
        onClick={isFree ? () => goToQustodioHelpCenter() : () => openMailApp()}
        iconPosition={ButtonIconPosition.left}
        size={ButtonSize.medium}
      >
        {buttonText}
      </Button>,
    ]}
    header={{
      icon: <Icon type={IconType.comments} />,
    }}
    size="small"
    onClickClose={onClose}
  >
    {text}
  </Modal>
);

export default RatingAppBadFeedbackModal;
