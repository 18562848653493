import { Map } from 'immutable';
import { Moment } from 'moment';
import {
  dayCountDiff,
  getAllRestrictedHours,
  minutesCountDiff,
  Weekday,
  WEEKDAYS,
} from '../../helpers/dates';
import { MINUTES_IN_HOUR, ONE_DAY_IN_MINUTES } from '../../constants';

interface PauseOverlapsTimeQuotaParams {
  pauseMinutes: number;
  spentMinutes: number;
  extraTime: number;
  currentTime: Moment;
  getTimeQuotasForDayInMinutes: (nextDay: Weekday) => number;
}

interface PauseOverlapsRestrictionTimeParams {
  pauseMinutes: number;
  currentTime: Moment;
  timeRanges: Map<Weekday, number>;
  isRestrictionsTimeEnabled: boolean;
}

/**
 * hoursRange[][] -> [[0, 1, 5], [0, 1]]
 * output[] -> [0, 1, 5, 24, 25]
 */
const getRestrictedTimeRangesBetweenDays = (
  timeRanges: Map<Weekday, number>,
  daysRange: Weekday[]
) => {
  return daysRange
    .map((currentWeekday: Weekday) =>
      getAllRestrictedHours({ timeRanges, currentWeekday })
    )
    .map((hoursRange, dayIndex) => hoursRange.map(hour => hour + dayIndex * 24))
    .flat();
};

const isPausingIntoTomorrow = (currentTime: Moment, pauseMinutes: number) => {
  return (
    dayCountDiff(
      currentTime.clone().add('days', 1),
      currentTime.clone().add('minutes', pauseMinutes)
    ) === 0
  );
};

// Pause Internet can be applied to 2 days at a time
// So we have to check for RestrictionTime for today and tomorrow if necessary.
export const pauseOverlapsRestrictionTime = ({
  pauseMinutes,
  currentTime,
  timeRanges,
  isRestrictionsTimeEnabled,
}: PauseOverlapsRestrictionTimeParams): boolean => {
  if (!isRestrictionsTimeEnabled) return false;

  const currentWeekday = WEEKDAYS[currentTime.isoWeekday() - 1];
  const nextWeekday =
    WEEKDAYS[currentTime.clone().add('days', 1).isoWeekday() - 1];

  const restrictedRangeInMinutes = getRestrictedTimeRangesBetweenDays(
    timeRanges,
    [currentWeekday, nextWeekday]
  ).map(hour => hour * MINUTES_IN_HOUR);

  const minutesUntilNow = minutesCountDiff(
    currentTime,
    currentTime.clone().startOf('day')
  );
  const pauseUntilMinutes = minutesUntilNow + pauseMinutes;

  return (
    restrictedRangeInMinutes
      .filter(pauseMinutes => pauseMinutes >= minutesUntilNow)
      .filter(pauseMinutes => pauseMinutes <= pauseUntilMinutes).length > 0
  );
};

export const pauseOverlapsTimeQuota = ({
  pauseMinutes,
  spentMinutes,
  extraTime,
  currentTime,
  getTimeQuotasForDayInMinutes,
}: PauseOverlapsTimeQuotaParams): boolean => {
  let todayTotalQuota = 0;
  let tomorrowQuota = 0;

  // Get quota for today
  const todayQuota = getTimeQuotasForDayInMinutes(
    WEEKDAYS[currentTime.isoWeekday() - 1]
  );

  todayTotalQuota = todayQuota + extraTime - spentMinutes;

  // If we have today's quota surplus, we only take the ammount till the end of the day.
  const maxAppliableQuotaForToday = minutesCountDiff(
    currentTime.clone().endOf('day'),
    currentTime.clone()
  );
  const todayQuotaMinutes = Math.min(
    maxAppliableQuotaForToday,
    todayTotalQuota
  );

  // If we are pausing into the next day we calculate the tomorrow's quota
  if (isPausingIntoTomorrow(currentTime, pauseMinutes)) {
    tomorrowQuota = getTimeQuotasForDayInMinutes(
      WEEKDAYS[currentTime.clone().add('days', 1).isoWeekday() - 1]
    );
  }

  // If today we don't have the full day available we do not want to consider tomorrow
  const tomorrowQuotaMinutes =
    todayQuota + extraTime < ONE_DAY_IN_MINUTES ? 0 : tomorrowQuota;

  // If both days are fully allowed then no overlap
  if (todayTotalQuota + tomorrowQuota === ONE_DAY_IN_MINUTES * 2) return false;
  return pauseMinutes > todayQuotaMinutes + tomorrowQuotaMinutes;
};

export const isInternetPauseOverlapped = ({
  pauseMinutes,
  currentTime,
  timeRanges,
  spentMinutes,
  extraTime,
  getTimeQuotasForDayInMinutes,
  isRestrictionsTimeEnabled,
}: PauseOverlapsTimeQuotaParams & PauseOverlapsRestrictionTimeParams) => {
  return (
    pauseOverlapsRestrictionTime({
      pauseMinutes,
      currentTime,
      timeRanges,
      isRestrictionsTimeEnabled,
    }) ||
    pauseOverlapsTimeQuota({
      pauseMinutes,
      spentMinutes,
      extraTime,
      currentTime,
      getTimeQuotasForDayInMinutes,
    })
  );
};
