import { Map } from 'immutable';
import { getTimeRanges, getTimeQuotas, getProfileRules } from '../profileRules';
import { Weekday } from '../../helpers/dates';
import State from '../../store/state';
import { Profile } from '../../records/profile';
import { getAccountCurrentTime } from './account';
import { getProfile } from '../profileRaw';
import { getScreenTimeMinutes } from '../summary';
import { getExtraTimeInMinutes } from '../extraTime';

import { isInternetPauseOverlapped } from '../../businessLogic/timeRules/pauseInternetOverlap';

export const hasPauseInternetOverlap =
  (state: State, profileId: Profile['id']) =>
  (minutes: number): boolean => {
    const deviceId = getProfile(state, profileId)?.deviceIds?.first() || 0;
    const isRestrictionsTimeEnabled =
      getProfileRules(state, profileId)?.timeRestrictions?.isDailyLimit ||
      false;

    return isInternetPauseOverlapped({
      isRestrictionsTimeEnabled,
      pauseMinutes: minutes,
      currentTime: getAccountCurrentTime(state),
      timeRanges: getTimeRanges(state, profileId) as Map<Weekday, number>,
      spentMinutes: getScreenTimeMinutes(state),
      extraTime: getExtraTimeInMinutes(state) as number,
      getTimeQuotasForDayInMinutes: day =>
        (getTimeQuotas as any)(state, profileId, deviceId, day),
    });
  };
