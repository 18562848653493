import * as React from 'react';
import * as R from 'ramda';
import { t } from '../../lib/i18n';
import { OrderedSet } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { Button, ButtonSize, FlexLayout, Label } from 'styleguide-react';
import InputTextField from '../Form/InputTextField';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import { DeviceRecord } from '../../records/device/types/Device.types';

const DeviceEdit: React.FunctionComponent<{
  device: DeviceRecord | undefined;
  deviceNames: OrderedSet<string>;
  onSave: () => any;
}> = ({ device, deviceNames, onSave }) => (
  <PageLayout
    title={t('Edit device')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
  >
    <EditDeviceForm
      initialValues={{ name: device ? device.name : '' }}
      deviceNames={deviceNames}
      onSubmit={onSave}
    />
  </PageLayout>
);

export default DeviceEdit;

export const formName = 'editDevice';

const validate = (values, deviceNames) => {
  const errors: { name?: string } = {};
  if (!values.name) {
    errors.name = t('Field required');
  } else if (values.name.length > 40) {
    errors.name = t('This name is too long (40 characters max.)');
  } else if (deviceNames.map(R.toLower).has(R.toLower(values.name))) {
    errors.name = t('A device with the same name already exists');
  }
  return errors;
};

export const editDeviceId = 'EditDevice';

const EditDeviceForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values, { deviceNames }) => validate(values.toJS(), deviceNames),
})(({ handleSubmit }) => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-between"
      flexGrow="0"
      matchParentHeight
    >
      <FlexLayout mainaxis="column">
        <form
          className="Form EditDeviceForm"
          onSubmit={handleSubmit}
          id={formName}
          data-testId="Form__editDevice"
        >
          <FlexLayout mainaxis="column" marginBottom="8">
            <Label htmlFor={editDeviceId}>{t('Name')}</Label>
          </FlexLayout>
          <FlexLayout mainaxis="column" marginBottom="8">
            <Field
              id={editDeviceId}
              name="name"
              component={InputTextField}
              type="text"
              placeholder={t('Device name')}
              testId="edit-device"
            />
          </FlexLayout>
          {/* include an invisible submit-button so that the mobile-keyboard
        has a "submit" button: */}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      </FlexLayout>

      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          centered
          size={ButtonSize.medium}
          testId="save-edit-device"
          htmlType="submit"
          form={formName}
        >
          {t('Save')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});
