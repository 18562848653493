import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import { formRecord } from '../../components/AccountSettings/EditPasswordForm';
import {
  modifyPassword,
  requestModifyAccountError,
  requestModifyAccount,
} from '../../ducks/account';
import {
  getAccount,
  getAccountModifyStatus,
  getPasswordFormValues,
} from '../../selectors';
import { showErrorToast } from './helpers';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import EditPassword from '../../components/AccountSettings/EditPassword';

const mapStateToProps = state => {
  const { id } = getAccount(state);
  return {
    initialValues: formRecord({
      id,
      password: '',
      passwordNew: '',
      confirmPasswordNew: '',
    }),
    showSpinner: getAccountModifyStatus(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFormSubmit: () => dispatch(onFormSubmit()),
});

function onFormSubmit() {
  return (dispatch, getState) => {
    const form = formRecord(getPasswordFormValues(getState()));
    dispatch(requestModifyAccount());
    return Promise.resolve(serializePasswords(form))
      .then(data => dispatch(modifyPassword(data)))
      .then(() => dispatch(goBackIfHistory()))
      .then(() =>
        dispatch(
          showToast(t('Password changed successfully.'), TOAST_ICON_TICK)
        )
      )
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(showErrorToast(error));
      });
  };
}

export const serializePasswords = record => {
  const { password } = record;
  const { passwordNew } = record;
  return { old_password: password, new_password: passwordNew };
};

const EditPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPassword);

export default EditPasswordContainer;
