import {
  AppRuleExceptions,
  AppRuleType,
  MultiStepDataContext,
  TimeLimitType,
  WebRuleExceptions,
} from '../../../components/Routines/routines.types';
import { policyBlockTypeRecordToPayload } from '../../../records/routines/policy/policy';
import { PolicyApplicationOperations } from '../../../records/routines/policy/policyApplication';
import { policyApplicationDefaultActionRecordToPayload } from '../../../records/routines/policy/policyApplicationRules';
import { PolicyWebCategoryOperations } from '../../../records/routines/policy/policyWebCategory';
import {
  PolicyBlockTypePayload,
  PolicyPayload,
  policyAction,
} from '../../../records/routines/policy/types/Policy.types';
import { PolicyApplicationPayload } from '../../../records/routines/policy/types/PolicyApplication.types';
import { PolicyWebCategoryPayload } from '../../../records/routines/policy/types/PolicyWebCategory.types';

const policyCountsTowardsDailyTimeQuotaRecordToPayload = (
  timeLimitType: TimeLimitType
) => timeLimitType === 'APPLY_DAILY_TIME_LIMITS';

const ruleTypeToPolicyDefaultActionPayload = (appRuleType?: AppRuleType) => {
  const type = appRuleType === 'DEFAULT' ? undefined : appRuleType;
  const typePayload = policyApplicationDefaultActionRecordToPayload(type);
  // When routines is blocking type then this value is irrelevant but required for api call.
  if (typePayload == null) return 1;
  return typePayload;
};

const appsExceptionToApplicationListPayload = (
  appsException: AppRuleExceptions
) =>
  appsException.map(app => {
    return PolicyApplicationOperations.serialize(
      PolicyApplicationOperations.create(app)
    ) as PolicyApplicationPayload;
  });

const websExceptionsToWebCategoryListPayload = (
  websException: WebRuleExceptions
) =>
  websException.map(
    webException =>
      PolicyWebCategoryOperations.serialize(
        PolicyWebCategoryOperations.create(webException)
      ) as PolicyWebCategoryPayload
  );

export const policyContextToPayload = (
  data: MultiStepDataContext
): PolicyPayload => {
  // blocking type routine policy
  if (data.block !== 'POLICY_BLOCK_TYPE_NONE') {
    return {
      counts_towards_daily_time_quota: false,
      block_type: policyBlockTypeRecordToPayload(
        data.block
      ) as PolicyBlockTypePayload,
      app_rules: {
        default_action: policyAction.POLICY_ACTION_ALLOWED,
        app_rules_list: [],
      },
      web_rules: {
        default_action: policyAction.POLICY_ACTION_ALLOWED,
        web_rules_list: [],
      },
    };
  }

  // custom filtering type routine policy
  return {
    counts_towards_daily_time_quota:
      policyCountsTowardsDailyTimeQuotaRecordToPayload(
        data?.contentFilter?.timeLimitType
      ),
    block_type: policyBlockTypeRecordToPayload(
      data.block
    ) as PolicyBlockTypePayload,
    app_rules: {
      default_action: ruleTypeToPolicyDefaultActionPayload(
        data.contentFilter.appRuleType
      ),
      app_rules_list: appsExceptionToApplicationListPayload(
        data?.contentFilter?.appsException
      ),
    },
    web_rules: {
      default_action: ruleTypeToPolicyDefaultActionPayload(
        data?.contentFilter?.webRuleType
      ),
      web_rules_list: websExceptionsToWebCategoryListPayload(
        data?.contentFilter?.websException
      ),
    },
  };
};
