import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import TrustedContactsLegendModal from '../../components/Modal/TrustedContactsLegendModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const TrustedContactsLegendModalContainer = connect(
  null,
  mapDispatchToProps
)(TrustedContactsLegendModal);

export default TrustedContactsLegendModalContainer;
