import * as React from 'react';
import { AlertBox } from 'styleguide-react';
import { Link } from 'react-router';
import { TopbarAlertType } from './types/Topbar.types';

const TopbarBase = (alert: TopbarAlertType) => {
  const {
    type,
    solidType,
    icon,
    rounded,
    goToLink,
    centered,
    text,
    action,
    trackAction,
  } = alert;

  const textString = text ? text() : undefined;
  const actionString = action ? action() : undefined;

  // We want to ensure the correct tracking info when testing
  // but we cannot navigate using <Link /> in the test runner.
  // We use <a /> in test suite to ignore `to` prop and only trigger `onClick` prop when clicking.
  const isTestingEnv = process.env.NODE_ENV === 'testing';
  const LinkComponent = isTestingEnv ? 'a' : Link;

  return (
    <AlertBox
      testId="account-topbar"
      className="par-topbar"
      fullWidth
      type={type}
      icon={icon}
      rounded={rounded}
      solid={solidType}
      centered={centered}
    >
      <LinkComponent
        className="par-topbar__link"
        to={goToLink}
        onClick={trackAction}
        role="link"
        title="topbar-link"
        data-test-href={goToLink}
      >
        {textString && (
          <span data-testid="TopBar_description">{textString}</span>
        )}
        {actionString && (
          <span className="par-topbar__action" data-testid="TopBar_action">
            {actionString}
          </span>
        )}
      </LinkComponent>
    </AlertBox>
  );
};

export default TopbarBase;
