import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import FamilyLocatorFlyover from '../../components/FamilyLocator/FamilyLocatorFlyover';
import {
  PageNames,
  trackUpgradeBlockedFeature,
  GenericPageNames,
  FeatureBlockingContexts,
} from '../../helpers/analytics';
import { canPurchase, getAccountLocale } from '../../selectors';
import { trackablePage } from '../../helpers/hocs';
import { navigateToUpgrade } from '../../actions/Navigation';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
  canPurchase: canPurchase(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickUpgrade: () => {
    trackUpgradeBlockedFeature(
      GenericPageNames.FamilyLocator,
      FeatureBlockingContexts.FullBlockedFeature,
      'family_locator'
    );
    dispatch(navigateToUpgrade());
  },
});

const FamilyLocatorFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(FamilyLocatorFlyover, PageNames.FamilyLocatorPremiumFlyover));

export type FamilyLocatorFlyoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default FamilyLocatorFlyoverContainer;
