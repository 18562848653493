import * as React from 'react';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';
import {
  Button,
  ButtonSize,
  IconType,
  Tag,
  Typography,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import familyTherapySmallImg from '../../../assets/base/images/wellbeing/family-therapy-small.png';
import familyTherapyBigImg from '../../../assets/base/images/wellbeing/family-therapy-big.png';
import { t } from '../../../lib/i18n';
import { WellbeingUnsubscribedSection } from './WellbeingUnsubscribedSection';

interface FamilyTherapyProps {
  onClickButton: () => void;
}

const FamilyTherapy = ({ onClickButton }: FamilyTherapyProps) => (
  <RenderWhen screenSize={[ScreenSize.Desktop, ScreenSize.MobileOrTablet]}>
    {screenSize => {
      const isDesktop = () => screenSize === ScreenSize.Desktop;
      return (
        <BackgroundGradient
          gradient={isDesktop() ? 'yolk-large' : 'yolk-small'}
          bottomShape="wave"
        >
          <WellbeingUnsubscribedSection
            screenSize={screenSize}
            classPrefix="par-wellbeing-therapy-unsubscribed"
            paddingBottom="0"
            leftColVerticalAlign={isDesktop() ? 'start' : 'center'}
            leftColHorizontalAlign={isDesktop() ? 'start' : 'center'}
            desktopTitle={
              <Typography type="headline2" weight="semibold" marginBottom="8">
                {t('The support your family deserves')}
              </Typography>
            }
            mobileTitle={
              <Typography
                type="h3"
                weight="semibold"
                align="center"
                marginBottom="0"
              >
                {t('Family Therapy')}
              </Typography>
            }
            subtitle={t('FAMILY THERAPY')}
            description={
              <Typography type="body1" marginBottom={isDesktop() ? '16' : '0'}>
                {t(
                  'Discover a unique experience that combines your child’s Qustodio insights with the latest techniques in family therapy.'
                )}
              </Typography>
            }
            tag={
              <Tag
                text={t('Includes wellbeing activities')}
                variant="rounded"
                iconType={IconType.check}
                size="large"
                className="par-wellbeing-therapy-unsubscribed__tag"
              />
            }
            image={
              <img
                src={isDesktop() ? familyTherapyBigImg : familyTherapySmallImg}
                alt=""
                width={isDesktop() ? 333.5 : 107}
                height={isDesktop() ? 287 : 101}
                className="par-wellbeing-therapy-unsubscribed__img"
              />
            }
            button={
              <Button centered onClick={onClickButton} size={ButtonSize.medium}>
                {t('See how it works')}
              </Button>
            }
          />
        </BackgroundGradient>
      );
    }}
  </RenderWhen>
);

export default FamilyTherapy;
