import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  Icon,
  IconType,
  IconColor,
  ButtonType,
} from 'styleguide-react';

import { modalDimensions } from '../../constants/index';

const RemoveInvitationModal = ({
  title,
  text,
  mainCtaText,
  mainCtaAction,
  secondaryCtaText,
  secondaryCtaAction,
}: {
  title: string;
  text: string;
  mainCtaText: string;
  mainCtaAction: () => void;
  secondaryCtaText: string;
  secondaryCtaAction: () => void;
}) => (
  <Modal
    testId="removeInvitation"
    title={title}
    header={{
      icon: <Icon type={IconType.trashAlt} color={IconColor.error} />,
    }}
    onClickClose={secondaryCtaAction}
    height={modalDimensions.regular.height}
    width={modalDimensions.regular.width}
    buttons={[
      <Button
        key="button1"
        onClick={mainCtaAction}
        size={ButtonSize.medium}
        block
        testId="removeInvitation"
      >
        {mainCtaText}
      </Button>,
      <Button
        key="button2"
        block
        buttonType={ButtonType.secondary}
        size={ButtonSize.medium}
        onClick={secondaryCtaAction}
        testId="closeModal"
      >
        {secondaryCtaText}
      </Button>,
    ]}
  >
    {text}
  </Modal>
);

export default RemoveInvitationModal;
