import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  TransparentButton,
} from 'styleguide-react';

interface UnifiedNowCardPauseButtonProps {
  iconType: typeof IconType[keyof typeof IconType];
  iconColor?: IconColor;
  onClick: () => void;
}

const UnifiedNowCardPauseButton = ({
  iconType,
  iconColor = IconColor.contrast,
  onClick,
}: UnifiedNowCardPauseButtonProps) => {
  return (
    <TransparentButton
      onClick={onClick}
      className="par-unified-now-card__pause-button"
      testId="unified-now-card-pause-button"
    >
      <Icon
        type={iconType}
        color={iconColor}
        size={IconSize.x4}
        family={IconFamily.solid}
      />
    </TransparentButton>
  );
};

export default UnifiedNowCardPauseButton;
