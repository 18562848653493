import * as React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconFamily,
  IconType,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import SummaryCardInfoModal from '../../components/Modal/SummaryCardInfoModal';
import State, { Dispatch } from '../../store/state';
import { ActivityType } from '../../components/ActivitySummary/sharedModels';
import { getQueryParam } from '../../selectors';
import {
  getAndroidConnectHelpLink,
  getIOSConnectHelpLink,
} from '../../helpers/callsMessages';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = (state: State) => {
  const type = getQueryParam(state, 'type') || ActivityType.AppsAndWebActivity;

  const title = {
    [ActivityType.Top]: t('Top activity'),
    [ActivityType.Questionable]: t('Questionable activity'),
    [ActivityType.Search]: t('Search activity'),
    [ActivityType.QuestionableWebSearches]: t('Questionable Web Searches'),
    [ActivityType.CallsAndSms]: t('Calls & messages'),
    [ActivityType.AppsAndWebActivity]: t('Apps and Web Activity'),
    [ActivityType.BlockedWebs]: t('Blocked web activity'),
    [ActivityType.Youtube]: t('YouTube Summary'),
    [ActivityType.TimeSpent]: t('Device time'),
  }[type];

  const text = {
    [ActivityType.Top]: (
      <Typography type="body1">
        {t(
          'See how long your child spent on specific web categories and apps.The percentage refers to the amount of time your child has spent sofar.'
        )}
      </Typography>
    ),
    [ActivityType.Questionable]: (
      <Typography type="body1">
        {t(
          'See any blocked web categories your child tried to access unsuccessfully.'
        )}
      </Typography>
    ),
    [ActivityType.Search]: (
      <React.Fragment>
        <Typography type="body1" marginBottom="16">
          {t(
            'See how many times your child searched for specific individual words, ranked from most to least often. We only list individual words, not the entire term they searched for.'
          )}
        </Typography>
        <Typography type="body1">
          {t('Go to the Activity Timeline to see whole search terms.')}
        </Typography>
      </React.Fragment>
    ),
    [ActivityType.QuestionableWebSearches]: (
      <Typography type="body1">
        {t(
          'See any unusual or concerning searches your child made based on a list of questionable terms and categories defined by {{shortName}}.'
        )}
      </Typography>
    ),
    [ActivityType.CallsAndSms]: (
      <React.Fragment>
        <Typography type="body1" marginBottom="16">
          {t(
            'See who your child has exchanged calls with and view the content of their messages.'
          )}
        </Typography>
        <Typography type="body1" marginBottom="24">
          {t('Learn how to activate this feature for your child’s devices:')}
        </Typography>

        <FlexLayout mainaxis="column" gap="24">
          <Button
            buttonType={ButtonType.plain}
            size={ButtonSize.small}
            block
            onClick={() => {
              const navigate = getMultiPlatformNavigation();

              return navigate({
                type: 'external',
                src: getAndroidConnectHelpLink(),
              });
            }}
          >
            <Typography type="body1">
              {t('Set up for Android')} {'>'}
            </Typography>
          </Button>

          <Button
            buttonType={ButtonType.plain}
            size={ButtonSize.small}
            block
            onClick={() => {
              const navigate = getMultiPlatformNavigation();
              return navigate({
                type: 'external',
                src: getIOSConnectHelpLink(),
              });
            }}
          >
            <Typography type="body1">
              {t('Set up for iOS')} {'>'}
            </Typography>
          </Button>
        </FlexLayout>
      </React.Fragment>
    ),
    [ActivityType.AppsAndWebActivity]: (
      <Typography type="body1">
        {t(
          'See specific websites your child visited, which apps they used and how much time they spent on each. Any websites belonging to categories or domains you marked as “Alert me” will be highlighted.'
        )}
      </Typography>
    ),
    [ActivityType.BlockedWebs]: (
      <Typography type="body1">
        {t(
          'See the URL of any websites your child tried to access unsuccessfully, as well as the category assigned to it. View how many times your child tried to access each page from most to least attempts.'
        )}
      </Typography>
    ),
    [ActivityType.Youtube]: (
      <React.Fragment>
        <Typography type="body1" marginBottom="16">
          {t(
            'See your child’s YouTube searches and the number of videos they’ve watched.'
          )}
        </Typography>
        <Typography type="body1">
          {t('Select {1}See videos{/1} to watch the videos yourself.', {
            1: str => <strong>{str}</strong>,
          })}
        </Typography>
      </React.Fragment>
    ),
    [ActivityType.TimeSpent]: (
      <React.Fragment>
        <Typography type="body1" marginBottom="16">{`${t(
          'Screen time is the amount of time that the device’s screen is active.'
        )}`}</Typography>
        <Typography type="body1" marginBottom="16">{`${t(
          "See your child's screen time and if they've reached the limit you've set."
        )}`}</Typography>
        <Typography type="body1">{`${t(
          'Add extra time to extend the limit if you want.'
        )}`}</Typography>
      </React.Fragment>
    ),
  }[type];

  const icon = {
    [ActivityType.Top]: <Icon type={IconType.infoCircle} />,
    [ActivityType.Questionable]: <Icon type={IconType.infoCircle} />,
    [ActivityType.Search]: <Icon type={IconType.search} />,
    [ActivityType.QuestionableWebSearches]: <Icon type={IconType.infoCircle} />,
    [ActivityType.CallsAndSms]: <Icon type={IconType.phoneAlt} />,
    // FIXME(rocket): Implement this variant in rocket
    [ActivityType.AppsAndWebActivity]: (
      <Icon className="fa-solid fa-chart-column" />
    ),
    [ActivityType.BlockedWebs]: <Icon type={IconType.ban} />,
    [ActivityType.Youtube]: (
      <Icon type={IconType.youtube} family={IconFamily.brands} />
    ),
    [ActivityType.TimeSpent]: <Icon type={IconType.clock} />,
  }[type];

  return {
    title,
    text,
    icon,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(goBackIfHistory());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryCardInfoModal);
