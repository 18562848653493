import { DesktopPageLayoutConfigurationProps } from '../types';
import { DesktopMainSizes } from './constants';

export const getPixels = (
  configuration?: DesktopPageLayoutConfigurationProps
): DesktopMainSizes => {
  if (!configuration || configuration.columns !== 1) {
    return DesktopMainSizes.small;
  }
  if (configuration.mainReducedSize) {
    return DesktopMainSizes.reduced;
  }
  if (configuration.mainExtendedSize) {
    return DesktopMainSizes.fullWidth;
  }
  return DesktopMainSizes.big;
};
