import { connect } from 'react-redux';
import KidLocationsHistoryItemHeader from '../../components/FamilyLocator/KidLocationsHistoryItemHeader';
import { PageNames } from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import { goBackIfHistory } from '../../ducks/routing';
import { getLocale } from '../../lib/i18n';
import { getTimezone } from '../../selectors/account';
import { formatDateNice, formatHoursFromISO } from '../../helpers/dates';

const mapStateToProps = (state, { params }) => {
  const timezone = getTimezone(state);

  return {
    day: params.marker
      ? formatDateNice(params.marker.time, timezone, getLocale())
      : '',
    hour: params.marker ? formatHoursFromISO(params.marker.time, timezone) : '',
  };
};

const mapDispatchToProps = (dispatch, { params }) => ({
  onClick: () => {
    dispatch(params.backClickCallback);
    dispatch(goBackIfHistory());
  },
});

const KidLocationsHistoryItemHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(
    KidLocationsHistoryItemHeader,
    PageNames.FamilyLocatorReportedLocation
  )
);

export default KidLocationsHistoryItemHeaderContainer;
