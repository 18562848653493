import * as React from 'react';
import { SafeNetworkComponentProps } from './types';
import { t } from '../../../lib/i18n';
import {
  AlertBox,
  AlertBoxType,
  Icon,
  IconType,
  Layout,
  Link,
} from 'styleguide-react';

export const SafeNetworkTopBar: React.FC<SafeNetworkComponentProps> = ({
  profileName,
  onClickAllow,
  onClickClose,
}) => {
  return (
    <Layout width="100%" marginBottom="24">
      <AlertBox
        showInfoIcon
        fullWidth
        type={AlertBoxType.info}
        icon={<Icon type={IconType.wifi} />}
        showCloseIcon
        onClose={onClickClose}
        rounded
      >
        {t(
          '{{childName}} has connected to a School Safe Network for the first time. {1}Learn more{/1}',
          {
            childName: profileName,
            1: (str: string) => <Link onClick={onClickAllow}>{str}</Link>,
          }
        )}
      </AlertBox>
    </Layout>
  );
};
