import { Record } from 'immutable';
import { PartialDeep } from '../../types/PartialDeep.types';

import { snakeCaseToCamelCase, camelCaseToSnakeCase } from './case';

/**
 * Most applied map function to convert a payload to a Record compatible
 * object
 */
export const commonMapInFn = <T, Payload>(payload: Payload): T =>
  snakeCaseToCamelCase(payload);

/**
 * Most applied map function to convert a Record to a payload
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const commonMapOutFn = <T, Payload extends object>(
  record?: Record<T>
): PartialDeep<Payload> => record && camelCaseToSnakeCase(record.toJS());
