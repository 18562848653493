import { List, NullableMap } from 'immutable';
import { MinutesPerHourActivity } from '../../records/activity/types/MinutesPerHourActivity.types';
import { getRoutineColorKey } from '../../palettes/RoutineColor';
import { RoutineOperations } from '../../records/routines/routine';
import { RoutineRecord } from '../../records/routines/types/Routine.types';
import { t } from '../../lib/i18n';
import { theme } from 'styleguide-react';

export const aggregateTimeScreen = (data: List<MinutesPerHourActivity>) => {
  if (!data || !data.size) return null;

  const aggregate = {
    default: 0,
  };

  data.forEach(entry => {
    aggregate.default += entry?.defaultScreenTime || 0;

    (entry?.routineScreenTime || []).forEach(routine => {
      if (routine.uid in aggregate) {
        aggregate[routine.uid] += routine.minutes;
      } else {
        aggregate[routine.uid] = routine.minutes;
      }
    });
  });

  return aggregate;
};

export type MappedRoutinesScreenTimeData = {
  uid: string;
  minutes: number;
  routine: RoutineRecord;
};

export const mapToRoutinesData = (
  data: Record<string, number>,
  routines: NullableMap<string, RoutineRecord>
): Array<MappedRoutinesScreenTimeData> => {
  return Object.entries(data).map(([uid, minutes]) => {
    if (uid === 'default') {
      return {
        uid: 'default',
        minutes,
        routine: RoutineOperations.create({
          uid: 'default',
          name: t('Default rules'),
          color: getRoutineColorKey(theme.palette.secondaryLighter),
          icon: 'shield',
        }),
      };
    }

    return { uid, minutes, routine: routines.get(uid) };
  });
};
