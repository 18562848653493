import * as tz from 'moment-timezone';
import { where, equals } from 'ramda';
import { Weekday, WEEKDAYS } from '../../helpers/dates';

const MINUTES_IN_A_DAY = 1440 as const;

const isDayWithFullQuota = equals<number>(MINUTES_IN_A_DAY);

const isDayWithoutQuota = equals(0);

const hasQuotaInEveryDayWith =
  (haveQuotaPredicate: (minutes: number) => boolean) =>
  (quotas: Record<Weekday, number>) =>
    where(
      {
        [Weekday.Monday]: haveQuotaPredicate,
        [Weekday.Tuesday]: haveQuotaPredicate,
        [Weekday.Wednesday]: haveQuotaPredicate,
        [Weekday.Thursday]: haveQuotaPredicate,
        [Weekday.Friday]: haveQuotaPredicate,
        [Weekday.Saturday]: haveQuotaPredicate,
        [Weekday.Sunday]: haveQuotaPredicate,
      },
      quotas
    );

export const hasFullQuotaInEveryDay =
  hasQuotaInEveryDayWith(isDayWithFullQuota);

export const hasZeroQuotaInEveryDay = hasQuotaInEveryDayWith(isDayWithoutQuota);

export const everyDayWithFullQuota = (): Array<[Weekday, number]> => {
  return [
    [Weekday.Monday, MINUTES_IN_A_DAY],
    [Weekday.Tuesday, MINUTES_IN_A_DAY],
    [Weekday.Wednesday, MINUTES_IN_A_DAY],
    [Weekday.Thursday, MINUTES_IN_A_DAY],
    [Weekday.Friday, MINUTES_IN_A_DAY],
    [Weekday.Saturday, MINUTES_IN_A_DAY],
    [Weekday.Sunday, MINUTES_IN_A_DAY],
  ];
};

export const everyDayWithZeroQuota = (): Array<[Weekday, number]> => {
  return [
    [Weekday.Monday, 0],
    [Weekday.Tuesday, 0],
    [Weekday.Wednesday, 0],
    [Weekday.Thursday, 0],
    [Weekday.Friday, 0],
    [Weekday.Saturday, 0],
    [Weekday.Sunday, 0],
  ];
};

export const getDefaultQuotaValues = (quota: 'full' | 'empty') =>
  ({
    full: everyDayWithFullQuota(),
    empty: everyDayWithZeroQuota(),
  }[quota]);

export const getCurrentWeekday = (currentTime: tz.Moment) => {
  return WEEKDAYS[currentTime.isoWeekday() - 1];
};
