import * as React from 'react';
import { AlertBoxType, IconType } from 'styleguide-react';
import TopbarBase from '../TopbarBase';
import { TopBarCasesProps, TopbarAlertType } from '../types/Topbar.types';
import { t } from '../../../lib/i18n';
import { trackMyFamilyTopbarAction } from '../../../helpers/analytics';

export { isVisiblePremiumExpiresTopbar as isVisiblePremiumTopbar } from './predicate';

const premiumTextsByDaysLeft = (days: number) => {
  const prefix = t('Your Premium plan expires');
  if (days === 0) return t('{{prefix}} today.', { prefix });
  if (days === 1) return t('{{prefix}} tomorrow.', { prefix });
  return t('{{prefix}} in {{days}} days.', { prefix, days });
};

const PremiumExpiresTopbar = ({
  isMobile,
  licenseDaysLeft,
}: TopBarCasesProps) => {
  const twoDaysLeft = licenseDaysLeft <= 2;
  const icon = twoDaysLeft ? IconType.exclamationCircle : IconType.rocketLaunch;
  const solidType = twoDaysLeft;

  const props: TopbarAlertType = {
    rounded: !isMobile,
    centered: !isMobile,
    type: AlertBoxType.booster,
    solidType,
    icon,
    text: () => premiumTextsByDaysLeft(licenseDaysLeft),
    goToLink: '/renew',
    action: () => t('Renew now to keep advanced protection!'),
    trackAction: () => trackMyFamilyTopbarAction({ reason: 'premium_expires' }),
  };

  return <TopbarBase {...props} />;
};

export default PremiumExpiresTopbar;
