import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import State from '../store/state';
// eslint-disable-next-line import/named
import { StudentRecord } from '../records/student/types/Student.type';
import { getProfilesMap } from './profile';
import { ProfileRecord } from '../records/profile/types/Profile.types';

export const getStudentIds = (state: State) =>
  state.get('students').get('result');

export const getStudentsMap = (state: State) =>
  state.get('records').get('students');

export const isStudent = (student: ProfileRecord): boolean =>
  student.isLinewizestudentLinked;

export const getStudentFromProfile = (
  uid: ProfileRecord['uid'],
  state: State
): ProfileRecord => {
  const findStudentByUID = profs =>
    profs.filter(isStudent).find(prof => prof.uid === uid);
  const studentFrom = createSelector(getProfilesMap, findStudentByUID);
  return studentFrom(state);
};

export const getStudentsFromProfileMap = state =>
  getProfilesMap(state).filter(isStudent);

export const getStudents = createSelector(
  getStudentsMap,
  (students): Immutable.OrderedSet<StudentRecord> => students.toOrderedSet()
);

export const getStudent = (studentUid: StudentRecord['uid']) =>
  createSelector(getStudents, students =>
    students?.find(student => student.uid === studentUid)
  );

export const getNextStudentUid = (currentUid?: ProfileRecord['uid']) =>
  createSelector(getStudentsFromProfileMap, students =>
    currentUid
      ? students
          .reduce(
            (acc, student) => {
              if (acc.found) {
                return acc.student !== undefined
                  ? acc
                  : { found: true, student };
              }
              return {
                found: student.get('uid') === currentUid,
                student: undefined,
              };
            },
            {
              found: false,
              student: undefined as ProfileRecord | undefined,
            }
          )
          .student?.get('uid')
      : students.first()?.get('uid')
  );

export const isFetchingStudents = (state: State) =>
  state.getIn(['students', 'isFetching']);

export const isCallingLinkPendingStudents = (state: State): boolean =>
  state.getIn(['students', 'callingLinkPendingStudents']) || false;
