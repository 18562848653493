import { connect } from 'react-redux';
import { openSchoolSummaryInfoModalFor } from '../../actions/SchoolActions';
import StudentTotalUsageCard from '../../components/Schools/StudentTotalUsageCard';
import { SummaryDateRanges } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { getAccountCurrentTime, getTimezone } from '../../selectors';
import {
  getStudentSummaryActiveDateRange,
  getStudentSummaryTotalUsage,
  getSummaryFetchError,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State) => ({
  totalUsageActivity: getStudentSummaryTotalUsage(state),
  timezone: getTimezone(state),
  currentTime: getAccountCurrentTime(state),
  todaySelected:
    getStudentSummaryActiveDateRange(state) === SummaryDateRanges.Today,
  isFeatureDisabled:
    getSummaryFetchError(state) === StudentSummaryFetchError.FeatureDisabled,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickInfo: () => dispatch(openSchoolSummaryInfoModalFor('TotalDailyUsage')),
});

const StudentTotalUsageCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTotalUsageCard);

export type StudentTotalUsageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentTotalUsageCardContainer;
