import { Record } from 'immutable';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  Routine,
  RoutinePayload,
  routineType,
  routineIconType,
} from './types/Routine.types';
import { PolicyOperations } from './policy/policy';
import { commonMapInFn, commonMapOutFn } from '../../api/mappings/common';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../api/mappings/valueRestrictions';
import {
  getRoutineColorKey,
  getRoutineColorValue,
} from '../../palettes/RoutineColor';
import {
  PolicyBlockType,
  PolicyBlockTypePayload,
  policyBlockType,
} from './policy/types/Policy.types';

export const routineTypePayloadToRecord = (type?: PolicyBlockTypePayload) => {
  if (type === policyBlockType.POLICY_BLOCK_TYPE_NONE) {
    return restrictKeyFromValue(
      routineType.ROUTINE_TYPE_MANAGEMENT,
      routineType
    );
  }
  return restrictKeyFromValue(routineType.ROUTINE_TYPE_BLOCKED, routineType);
};

export const routineTypeRecordToPayload = (type?: PolicyBlockType) => {
  if (type === 'POLICY_BLOCK_TYPE_NONE') {
    return routineType.ROUTINE_TYPE_MANAGEMENT;
  }
  return routineType.ROUTINE_TYPE_BLOCKED;
};

export const routineIconPayloadToRecord = (icon?: RoutinePayload['icon']) =>
  restrictKeyFromValue(icon, routineIconType);

export const routineIconRecordToPayload = (icon?: Routine['icon']) =>
  restrictValueFromKey(icon, routineIconType);

export const RoutineOperations = getRecordOperations<Routine, RoutinePayload>({
  defaultFields: {
    uid: null,
    name: null,
    description: null,
    icon: null,
    color: null,
    type: null,
    paused: false,
    enabled: true,
    policy: Record({}),
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    type: routineTypePayloadToRecord(payload?.policy?.block_type),
    icon: routineIconPayloadToRecord(payload?.icon),
    color: payload?.color
      ? getRoutineColorKey(payload.color) || payload.color
      : undefined,
    policy: PolicyOperations.fromPayload(payload?.policy),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    type: routineTypeRecordToPayload(record?.policy?.blockType),
    icon: routineIconRecordToPayload(record?.icon),
    color: record?.color
      ? getRoutineColorValue(record.color) || record.color
      : undefined,
    policy: PolicyOperations.serialize(record?.policy),
  }),
});
