/* eslint-disable camelcase */
import { Record } from 'immutable';
import { ACTION_ALLOW, ACTION_BLOCK } from '../../../constants';
import { DatetimeISO6801 } from '../../../types/dates';

export const WEB_APP = 5101;
export const VIDEO = 5600;
export const SEARCH = 5103;

export const linewizeEventTypes = [WEB_APP, VIDEO, SEARCH] as const;

export const linewizeEventActions = [ACTION_ALLOW, ACTION_BLOCK] as const;

export type LinewizeEventTypes = typeof linewizeEventTypes[number];

export type LinewizeEventActions = typeof linewizeEventActions[number];

interface LinewizeEventBasePayload {
  key: string;
  dt: DatetimeISO6801;
  type: LinewizeEventTypes;
  action: LinewizeEventActions;
  urls?: string[];
  category_name?: string;
  category_id?: string;
  signature_name?: string;
  signature_id?: string;
  at_school_hours?: boolean;
  signature_identified_as_app: boolean;
}

interface LinewizeEventWebAppPayload {
  has_review: boolean;
  icon?: string;
}

interface LinewizeEventSearchPayload {
  search_terms?: string;
}

interface LinewizeEventVideoPayload {
  title?: string;
  url?: string;
  thumbnail?: string;
}

export type LinewizeEventPayload = LinewizeEventBasePayload &
  LinewizeEventWebAppPayload &
  LinewizeEventSearchPayload &
  LinewizeEventVideoPayload;

interface LinewizeEventBase {
  key: string;
  dt: DatetimeISO6801;
  type: LinewizeEventPayload['type'];
  action: LinewizeEventActions;
  urls: string[];
  categoryName?: string;
  categoryId?: string;
  signatureName?: string;
  signatureId?: string;
  atSchoolHours: boolean;
  signatureIdentifiedAsApp: boolean;
}

interface LinewizeEventTypeWebApp {
  hasReview: boolean;
  icon?: string;
}

interface LinewizeEventTypeSearch {
  searchTerms?: string;
}

interface LinewizeEventTypeVideo {
  title?: string;
  url?: string;
  thumbnail?: string;
}
export type LinewizeEvent = LinewizeEventBase &
  LinewizeEventTypeWebApp &
  LinewizeEventTypeSearch &
  LinewizeEventTypeVideo;

export type LinewizeEventRecord = Record<LinewizeEvent>;

export type LinewizeArrayEventRecord = Array<Record<LinewizeEvent>>;

export const SEARCH_SIGNATURE_NAME_YOUTUBE = 'youtube';
export const SEARCH_SIGNATURE_NAME_GOOGLE = 'google search';
export const SEARCH_SIGNATURE_NAME_BING = 'bing';
export const SEARCH_SIGNATURE_NAME_OTHER = 'search engines';

export const linewizeSearchEventSignatureNames = [
  SEARCH_SIGNATURE_NAME_GOOGLE,
  SEARCH_SIGNATURE_NAME_YOUTUBE,
  SEARCH_SIGNATURE_NAME_OTHER,
] as const;

export type LinewizeSearchEventSignatureNames =
  typeof linewizeSearchEventSignatureNames[number];
