import * as React from 'react';
import * as R from 'ramda';
import { t } from '../../lib/i18n';
import { EventRecord } from '../../records';
import { isFree, LicenseRecord } from '../../records/license';
import {
  EVENT_APP_CALCULATOR,
  EVENT_APP_MESSAGING,
  EventType,
  PLATFORM_IOS,
} from '../../constants';
import { capitalize } from '../../helpers/string';
import { getEventLocationDescription } from '../../selectors/profileLocationStatus';
import DropDownActionSheet, {
  getDropDownActionSheetTitle,
} from '../DropDown/DropDownActionSheet';
import { Icon, IconColor, IconSize, IconType } from 'styleguide-react';
import { isFreeOrPremiumBasic } from '../../records/license/helpers';
import { isSafetyNet } from '../../records/category';
import Clickable from '../Clickable/Clickable';
import { isEventPremiumYoutube } from './activityTimelineHelpers';

interface ActivityTimelineItemLabelProps {
  event: EventRecord;
  license: LicenseRecord;
  contact: any;
  onClickLabelIcon: () => any;
  isBlockable: boolean;
  isDelegatedWithPCDevices: boolean;
  onInfoClick: () => void;
  onSafetyNetClick: () => void;
}

const ActivityTimelineItemLabel: React.FunctionComponent<
  ActivityTimelineItemLabelProps
> = ({
  event,
  license,
  contact,
  onClickLabelIcon,
  isBlockable,
  onInfoClick,
  onSafetyNetClick,
  isDelegatedWithPCDevices,
}: ActivityTimelineItemLabelProps) => {
  const typeClass = R.cond<any, string>([
    [R.equals(EventType.Panic), R.always('typePanic')],
    [R.equals(EventType.Web), R.always('typeWeb')],
    [R.T, R.always('')],
  ])(event.type);

  const getLabel = (event: EventRecord): string => {
    let result;
    const contactName = contact ? contact.name : event.name;
    const eventName =
      event.name === '<hidden>' ? t('Hidden Number') : contactName;
    const premiumEvent = eventName || event.socialContactId;
    switch (event.type) {
      case EventType.Youtube:
        return t('YouTube History');
      case EventType.Web:
        return event.host;
      case EventType.App:
        switch (event.name) {
          case EVENT_APP_CALCULATOR:
            return t('Calculator');
          case EVENT_APP_MESSAGING:
            return t('Messaging');
          default:
            return event.name;
        }
      case EventType.CallsIncoming:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Incoming Call')
          : premiumEvent;
      case EventType.CallsOutgoing:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Outgoing Call')
          : premiumEvent;
      case EventType.CallsMissed:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Missed Call')
          : premiumEvent;
      case EventType.CallsUnanswered:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Unanswered Call')
          : premiumEvent;
      case EventType.CallsBlockedIncoming:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Incoming Call Blocked')
          : premiumEvent;
      case EventType.CallsBlockedOutgoing:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Outgoing Call Blocked')
          : premiumEvent;
      case EventType.SmsIncoming:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Received SMS')
          : premiumEvent;
      case EventType.SmsOutgoing:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Sent SMS')
          : premiumEvent;
      case EventType.SmsBlockedIncoming:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Incoming SMS Blocked')
          : premiumEvent;
      case EventType.SmsBlockedOutgoing:
        return isFreeOrPremiumBasic(license.subtype)
          ? t('Outgoing SMS Blocked')
          : premiumEvent;

      case EventType.FacebookSocial:
      case EventType.FacebookSocialContact:
      case EventType.FacebookSocialNetwork:
      case EventType.FacebookSocialContactNew:
      case EventType.FacebookSocialActivity:
      case EventType.FacebookSocialActivityPost:
      case EventType.FacebookSocialActivityUserPost:
      case EventType.FacebookSocialActivityUserProfile:
      case EventType.FacebookSocialActivityUserComment:
      case EventType.FacebookSocialActivityUserLike:
      case EventType.FacebookSocialActivityUserEvent:
      case EventType.FacebookSocialActivityContactPost:
      case EventType.FacebookSocialActivityContactComment:
      case EventType.FacebookSocialActivityContactLike:
      case EventType.FacebookSocialActivityUserContactPost:
      case EventType.FacebookSocialActivityUserLink:
      case EventType.FacebookSocialActivityUserSharePhoto:
      case EventType.FacebookSocialMedia:
      case EventType.FacebookSocialMediaUserPhoto:
      case EventType.FacebookSocialMediaUserVideo:
      case EventType.FacebookSocialMediaContactPhoto:
      case EventType.FacebookSocialMediaContactVideo:
        return 'Facebook';

      case EventType.Twitter:
        return 'Twitter';
      case EventType.Panic:
        if (event.panicMode) {
          result = t('Panic Alert Activated');
        } else {
          result = t('Panic Alert Ended');
        }
        break;

      case EventType.Location:
      case EventType.LocationUnvailable:
        result = isFree(license.type)
          ? t('Location Updated')
          : getEventLocationDescription(event.location);
        break;

      case EventType.LocationLast:
        result = getEventLocationDescription(event.location);
        break;

      case EventType.Search:
        result = t('Search on {{searchEngine}}', {
          searchEngine: capitalize(event.searchEngineName),
        });
        break;

      case EventType.SafeNetworkDisablingProtection:
        result = t('Connected to School Safe Network');
        break;

      case EventType.SafeNetworkEnablingProtection:
        result = t('Disconnected from School Safe Network');
        break;

      default:
        result = event.host;
    }
    return result;
  };

  const getLabelIcon = (
    event: EventRecord,
    onInfoClick: ActivityTimelineItemLabelProps['onInfoClick'],
    onSafetyNetClick: ActivityTimelineItemLabelProps['onSafetyNetClick'],
    isBlockable: boolean,
    isDelegatedWithPCDevices: boolean
  ): JSX.Element | null => {
    if (isSafetyNet(event.categories)) {
      return getSafetyNetInfoIcon(onSafetyNetClick);
    }
    if (isBlockable) {
      return getIcon(event, license, isDelegatedWithPCDevices);
    }
    return getNonBlockableIcon(onInfoClick);
  };

  return (
    <div className={`ActivityTimelineItemLabel ${typeClass}`}>
      <div className="ActivityTimelineItemLabel__title">{getLabel(event)}</div>
      <div
        className="ActivityTimelineItemLabel__icon"
        data-testid="ActivityTimelineItemLabel__icon"
        onClick={onClickLabelIcon}
      >
        {getLabelIcon(
          event,
          onInfoClick,
          onSafetyNetClick,
          isBlockable,
          isDelegatedWithPCDevices
        )}
      </div>
    </div>
  );
};

export const supportedEventTypes = [
  EventType.Web,
  EventType.App,
  EventType.Youtube,
  EventType.CallsIncoming,
  EventType.CallsOutgoing,
  EventType.CallsMissed,
  EventType.CallsUnanswered,
  EventType.CallsBlockedIncoming,
  EventType.CallsBlockedOutgoing,
  EventType.SmsIncoming,
  EventType.SmsOutgoing,
  EventType.SmsBlockedIncoming,
  EventType.SmsBlockedOutgoing,
  EventType.Panic,
  EventType.SafeNetworkDisablingProtection,
  EventType.SafeNetworkEnablingProtection,
];

export const unsupportedEventTypesByPlatform = {
  [PLATFORM_IOS]: [
    EventType.CallsIncoming,
    EventType.CallsOutgoing,
    EventType.CallsMissed,
    EventType.CallsUnanswered,
    EventType.CallsBlockedIncoming,
    EventType.CallsBlockedOutgoing,
    EventType.SmsIncoming,
    EventType.SmsOutgoing,
    EventType.SmsBlockedIncoming,
    EventType.SmsBlockedOutgoing,
  ],
};

export const isActionableEvent = (
  event: EventRecord,
  license: LicenseRecord,
  isDelegatedWithPCDevices: boolean
) => {
  const isUnsupported =
    unsupportedEventTypesByPlatform[event.platform]?.includes(event.type) ||
    false;

  const isYoutubeRelatedEventPremium = isEventPremiumYoutube(
    license,
    event,
    isDelegatedWithPCDevices
  );

  return (
    supportedEventTypes.includes(event.type) &&
    !isUnsupported &&
    !isSafetyNet(event.categories) &&
    !isYoutubeRelatedEventPremium
  );
};

const getIcon = (
  event: EventRecord,
  license: LicenseRecord,
  isDelegatedWithPCDevices: boolean
) => {
  if (!isActionableEvent(event, license, isDelegatedWithPCDevices)) {
    return null;
  }

  return (
    <DropDownActionSheet
      minWidth={300}
      selectedActionSheetKey={event.key}
      title={getDropDownActionSheetTitle(event)}
    />
  );
};

const getNonBlockableIcon = (onInfoClick: () => void) => {
  return (
    <Icon
      type={IconType.infoCircle}
      color={IconColor.secondary}
      size={IconSize.sm}
      onClick={onInfoClick}
    />
  );
};

const getSafetyNetInfoIcon = (onSafetyNetClick: () => void) => {
  return (
    <Clickable>
      <Icon
        type={IconType.infoCircle}
        color={IconColor.secondary}
        size={IconSize.lg}
        onClick={onSafetyNetClick}
        testId="safety-net-info"
      />
    </Clickable>
  );
};

export default ActivityTimelineItemLabel;
