import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import { getActiveDateRangeNormalized } from '../../selectors';
import MobileScreenSummaryCard from '../../components/ActivityCards/MobileScreenSummaryCard';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { navigateToTotalScreenTimeInfoModal } from '../../actions/Navigation';
import { hasReportedRoutines } from '../../ducks/routines/selectors';

interface Props {
  profile?: ProfileRecord;
  height?: string;
  width?: string;
}

const mapStateToProps = (
  state: State,
  { profile, height, width = '100%' }: Props
) => {
  const activeDateRange = getActiveDateRangeNormalized(state);

  return {
    profile,
    height,
    width,
    activeDateRange,
    hasReportedRoutines: hasReportedRoutines(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    goToInfoModal: () => dispatch(navigateToTotalScreenTimeInfoModal()),
  };
};

const MobileScreenSummaryCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileScreenSummaryCard);

export default MobileScreenSummaryCardContainer;

export type MobileScreenSummaryCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
