import { mergeAll } from 'ramda';
import State, { Dispatch } from '../store/state';

type unknownProps = Record<string, unknown>;

type MapStateToProps<ReturnedProps> = (
  state: State,
  ownProps?: unknownProps
) => ReturnedProps;

type MapDispatchToProps<ReturnedProps> = (
  dispatch: Dispatch,
  ownProps?: unknownProps
) => ReturnedProps;
/**
 *
 * Allows to combine different mapStateToProps functions in one,
 * this is very useful when you want to share behavior without creating a new container component.
 *
 * example:
 *
 * ```ts
 * const mapStateToPropsUser = (state: State) => {
 *  return {
 *    user: getUserSelector(state)
 *  }
 * }
 *
 * const mapStateToPropsProfile = (state: State) => {
 *  return {
 *    profile: getProfileSelector(state)
 *  }
 * }
 *
 * const mapStateToPropsUserAndProfile = combineMapStateToProps(mapStateToPropsUser, mapStateToPropsProfile);
 *
 * // container haver user and profile props.
 * const Container = connect(mapStateToPropsUserAndProfile)(Component)
 * ```
 *
 */
export const combineMapStateToProps = <ReturnedProps>(
  ...mappers: Array<MapStateToProps<unknownProps>>
) => {
  const combinedMapStateToProps = (state, ownProps) => {
    return mergeAll(
      mappers.map(mapStateToProps => mapStateToProps(state, ownProps))
    );
  };

  return combinedMapStateToProps as MapStateToProps<ReturnedProps>;
};

/**
 *
 * Allows to combine different mapDispatchToProps functions in one,
 * this is very useful when you want to share behavior without creating a new container component.
 *
 * Example:
 *
 * ```ts
 * const mapDispatchToPropsUser = (dispatch: Dispatch) => {
 *  return {
 *    onChangeUser: (id) => dispatch(changeUserAction(id))
 *  }
 * }
 *
 * const mapDispatchToPropsProfile = (dispatch: Dispatch) => {
 *  return {
 *    onChangeProfile: (id) => dispatch(changeProfileAction(id))
 *  }
 * }
 *
 * const mapDispatchToPropsUserAndProfile = combineMapStateToProps(mapDispatchToPropsUser, mapDispatchToPropsProfile);
 *
 * // container haver onChangeUser and onChangeProfile props.
 * const Container = connect(null, mapDispatchToPropsUserAndProfile)(Component)
 * ```
 *
 */
export const combineMapDispatchToProps = <ReturnedProps>(
  ...mappers: Array<MapDispatchToProps<unknownProps>>
) => {
  const combinedMapDispatchToProps = (dispatch, ownProps) => {
    return mergeAll(
      mappers.map(mapDispatchToProps => mapDispatchToProps(dispatch, ownProps))
    );
  };

  return combinedMapDispatchToProps as MapDispatchToProps<ReturnedProps>;
};
