import { t } from '../../lib/i18n';
import { CallsSMSRulesProps } from '../../types/rules/profile/callsAndMessages/CallsSMSRules.types';

export const getDeviceNames = ({
  compatibleAndroidDevices,
  compatibleIphoneDevices,
}: Pick<
  CallsSMSRulesProps,
  'compatibleAndroidDevices' | 'compatibleIphoneDevices'
>) => {
  const allDevices = compatibleAndroidDevices
    .concat(compatibleIphoneDevices)
    .map(device => device.name);

  const size = allDevices.count();

  if (size === 1 || size === 0) {
    return allDevices.join();
  }

  return `${allDevices.slice(0, -1).join(', ')} ${t(
    'and'
  )} ${allDevices.last()}`;
};
