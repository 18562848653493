import * as React from 'react';
import { Card, FlexLayout, Svg } from 'styleguide-react';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { t } from '../../lib/i18n';
import InfoIcon from '../Icons/InfoIcon';

interface StudentSummaryError {
  error: StudentSummaryFetchError;
  profileName: string;
  onClickContactUs: (e: any) => void;
}

const StudentSummaryError: React.FunctionComponent<StudentSummaryError> = ({
  error,
  profileName,
  onClickContactUs,
}: StudentSummaryError) => {
  const title =
    error === StudentSummaryFetchError.Unlinked
      ? t('No activity available')
      : t('Something went wrong');
  const content =
    error === StudentSummaryFetchError.Unlinked
      ? t(
          '{{profileName}}’s school account isn’t available at the moment. Please come back later.',
          {
            profileName,
          }
        )
      : t(
          'Please try again later or contact our {1}customer support team{/1} for help.',
          {
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
            1: (string: string) => <a onClick={onClickContactUs}>{string}</a>,
          }
        );
  return (
    <Card fullWidth className="SignatureNoDataCard">
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        textAlignement="center"
      >
        <FlexLayout mainaxis="column" maxWidth="400">
          <Svg height="64" width="64" color="gray-light">
            <InfoIcon />
          </Svg>
          <p className="rck-fw-semibold">{title}</p>
          <p>{content}</p>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export default StudentSummaryError;
