import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';

import { AppEpicWithoutStateEffect } from '../types';
import { whenIsPageInIframe, sendMessageToPublicSite } from './helpers';
import { notifyLogoutAction } from './actions';
import { doWithoutStateEffect } from '../operators';

type LogoutAction = ReturnType<typeof notifyLogoutAction>;

export const notifyLogoutToParent: AppEpicWithoutStateEffect<
  LogoutAction
> = $actions => {
  return $actions.pipe(
    /*
    The logout notification is handled in a granular way instead of using a global
    thunk because it caused inconsistencies when the app was running inside an iframe.
    Apparently, the global logout thunk was also executing when the app
    was not logged in during the bootstrap process.
    */
    ofType('NOTIFY_LOGOUT'),
    whenIsPageInIframe(),
    doWithoutStateEffect(() => {
      sendMessageToPublicSite({ type: 'PAR_LOGOUT' });
    }),
    catchError(() => {
      return EMPTY;
    })
  );
};
