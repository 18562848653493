import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Checkbox,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const ExternalUrlNavigationModal = ({
  url,
  domain,
  checkboxChecked,
  onClickClose,
  onClickLink,
  onClickCheckbox,
}: {
  url: string;
  domain?: string;
  checkboxChecked: boolean;
  onClickClose: () => any;
  onClickLink: () => any;
  onClickCheckbox: (isChecked: boolean) => void;
}) => (
  <div className="ExternalUrlNavigationModal modal--plain">
    <Modal
      onClickClose={onClickClose}
      height={modalDimensions.regular.height}
      width={modalDimensions.regular.width}
      title={t('Go to {{domainName}}', { domainName: domain || url })}
      buttons={[
        <Button
          key="button"
          onClick={onClickLink}
          size={ButtonSize.medium}
          block
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      <ModalStyledText>
        {t(
          'You are about to be redirected to the website "{{domainName}}". Are you sure to leave {{shortName}}?',
          { domainName: domain || url }
        )}
      </ModalStyledText>
      <p>
        <Checkbox
          name="ExternalUrlNavigationModal__not-show-checkbox"
          onClick={onClickCheckbox}
          checked={!!checkboxChecked}
        >
          <ModalStyledText>{t('Do not show this again')}</ModalStyledText>
        </Checkbox>
      </p>
    </Modal>
  </div>
);

export default ExternalUrlNavigationModal;
