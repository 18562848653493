import * as Moment from 'moment-timezone';
import { isBrowserPlatform } from './index';
import { getRatingPopupLastDate } from '../selectors/account';
import { getLicense } from '../selectors/license';
import { dayCountDiff, parseISODateString } from './dates';
import { LicenseRecord, isFreeOrTrial, isPremium } from '../records/license';
import State from '../store/state';
import { hasProfileWithActiveDevice } from '../selectors/profile';
import { getPersistentStorageItem } from '../selectors';

export const shouldShowRatingPopup = (state: State) => {
  const license = getLicense(state);
  const ratingPopupLastDate = getRatingPopupLastDate(state);
  const activeDevicesExist = hasProfileWithActiveDevice(state);
  const daysBetweenTodayAndRatingPopupLastDay = dayCountDiff(
    Moment(),
    Moment(ratingPopupLastDate)
  );

  /*
    We want to check if the user has already rated the app in the past,
    using previous implementations of the in-app-rating modal.

    The reasoning is that we don't want to ask these users again for feedback.
    Atleast for now, since we're not sure if they'll vote negatively. This restriction may be removed in the future.
  */
  const hasRatedAppUsingPreviousImplementations = parseISODateString(
    getPersistentStorageItem(state, 'appRateRateTs')
  ).isValid();

  return (
    !hasRatedAppUsingPreviousImplementations &&
    shouldShowRatingPopupByLicense(license) &&
    activeDevicesExist &&
    (ratingPopupLastDate === '' ||
      daysBetweenTodayAndRatingPopupLastDay >= 14) &&
    !isBrowserPlatform()
  );
};

export const shouldShowRatingPopupByLicense = (license: LicenseRecord) => {
  const daysBetweenTodayAndLicenseStartDay = dayCountDiff(
    Moment(),
    Moment(license.startDate)
  );
  return (
    (isFreeOrTrial(license.type) && daysBetweenTodayAndLicenseStartDay >= 7) ||
    (isPremium(license.type) && daysBetweenTodayAndLicenseStartDay >= 4)
  );
};
