import * as React from 'react';
import * as R from 'ramda';

const FormField: React.FunctionComponent<{
  autoComplete: boolean;
  autoCorrect: boolean;
  autoCapitalize: boolean;
  autoFocus: boolean;
  spellCheck: any;
  input: any;
  type: 'text' | 'password' | 'email' | 'tel' | 'number' | 'select';
  placeholder?: string;
  disabled?: boolean;
  onClick: (event: MouseEvent) => any;
  readOnly: boolean;
  icon: JSX.Element;
  meta: { touched: any; error: any };
  maxlength: number;
  onFocus: () => any;
  onBlur: () => any;
}> = ({
  autoComplete,
  autoCorrect,
  autoCapitalize,
  autoFocus,
  spellCheck,
  input,
  type,
  children,
  placeholder,
  disabled,
  onClick,
  readOnly,
  icon = null,
  meta: { touched, error },
  maxlength,
  onFocus,
  onBlur,
}) => {
  const inputClassName = `Form__input${
    touched && error ? ' Form__input--error' : ''
  }`;
  const wrapperClassName = `Form__field__wrapper${
    icon ? ' Form__field__wrapper--withIcon' : ''
  }`;
  const inputElementContent = (
    <div className={wrapperClassName}>
      {icon}
      <input
        {...input}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        autoFocus={autoFocus}
        spellCheck={spellCheck}
        placeholder={placeholder}
        className={inputClassName}
        type={type}
        disabled={disabled}
        onClick={onClick}
        readOnly={readOnly}
        maxLength={maxlength}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
  const inputElement = R.cond<string, JSX.Element>([
    [
      R.equals('select'),
      R.always(
        <select {...input} className={inputClassName}>
          {children}
        </select>
      ),
    ],
    // radio is not used currently. Just ProfileFormNew.js
    // is using this but this form seems not to be used from any other place.
    // so i do not fix type adding radio as valid string
    [
      R.equals('radio'),
      R.always(
        <div {...input} className={inputClassName}>
          {children}
        </div>
      ),
    ],
    [R.T, R.always(inputElementContent)],
  ])(type);
  const messageElement =
    touched && error ? (
      <div className="Form__message Form__message--error">{error}</div>
    ) : null;
  return (
    <div className="Form__field">
      {inputElement}
      {messageElement}
    </div>
  );
};

FormField.propTypes = {};

export default FormField;
