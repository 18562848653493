import * as qinit from '../../../qinit';
import { isBrowserPlatform } from '../../index';
import { BaseThunk } from '../../../store/state';
import { finishPurchaseProcess } from '../../../ducks/products/actions';
import {
  getLicense,
  getPaymentProvider,
  getProduct,
  isPurchaseInProgress,
} from '../../../selectors';
import { updateProductRecord } from '../../../ducks/records';
import { ProductRecord } from '../../../records';
import { PurchaseError } from '../../../lib/errors/purchaseError';

import { validator } from './validator';
import { getInAppPurchasePlatform, logPurchase } from './helpers';
import {
  trackPurchaseCancelled,
  trackPurchaseFailed,
  trackPurchaseInitiated,
  trackPurchaseReceiptReceived,
} from '../../analytics';
import { captureException } from '../../sentry';
import { isPremium } from '../../../records/license';

export const setupProducts =
  (productCodes: string[]): BaseThunk =>
  dispatch => {
    if (isBrowserPlatform()) {
      return;
    }
    const { store, ProductType, LogLevel } = global.CdvPurchase;

    if (qinit.verbose) {
      store.verbosity = LogLevel.DEBUG;
    }

    productCodes.forEach(code => {
      if (code !== undefined) {
        store.register({
          id: code,
          type: ProductType.PAID_SUBSCRIPTION,
          platform: getInAppPurchasePlatform(),
        });
      }
    });

    store.error(err => dispatch(onError(err)));

    /**
     * Defines the different states of purchase except the finished one which
     * will be treated in buyProduct action
     */
    store
      .when()
      .productUpdated(storeProduct => {
        logPurchase('productUpdated', storeProduct);
        dispatch(onProductUpdated(storeProduct));
      })
      .approved(transaction => {
        logPurchase('approved', transaction);
        dispatch(onTransactionApproved(transaction));
      })
      .verified(receipt => {
        logPurchase('verified', receipt);
        receipt.finish();
      });

    store.validator = (receipt, callback) => {
      dispatch(validator(receipt, callback));
    };

    store.initialize([getInAppPurchasePlatform()]);
    store.update();
  };

export const buyProduct =
  (productId: string): BaseThunk<Promise<void>> =>
  (_dispatch, getState) => {
    const { store } = global.CdvPurchase;
    return new Promise((resolve, reject) => {
      try {
        const product = store.get(productId);
        /**
         * We handle the finished state here for resolving the promise
         * and finish the purchase flow.
         */
        store.when().finished(receipt => {
          logPurchase('finished', receipt);
          resolve();
        });
        if (product) {
          const offer = product.getOffer();
          if (offer) {
            store.order(offer);
            trackPurchaseInitiated(getPaymentProvider(getState()), productId);
          } else {
            throw new Error(
              '[buyProduct] Product does not have any offer available'
            );
          }
        } else {
          throw new Error('[buyProduct] Product does not exist in the store');
        }
      } catch (error) {
        reject(error);
      }
    });
  };

export const onProductUpdated =
  (storeProduct: CdvPurchase.Product): BaseThunk =>
  (dispatch, getState) => {
    logPurchase('Product updated', JSON.stringify(storeProduct));
    const state = getState();
    if (storeProduct.canPurchase) {
      const product = getProduct(state, storeProduct.id);
      logPurchase('Product from state', product?.toJS());
      if (product !== undefined) {
        dispatch(
          updateProductRecord(
            ProductRecord({ ...product.toJS(), storeProduct }),
            product.code
          )
        );
      }
    }
  };

export const onTransactionApproved =
  (transaction: CdvPurchase.Transaction): BaseThunk =>
  (_dispatch, getState) => {
    /**
     * For premium users who have purchased through apps the purchase is always approved
     * we want to avoid the verification flow in this case
     */
    const license = getLicense(getState());
    if (
      transaction.transactionId === 'appstore.application' ||
      isPremium(license.type)
    ) {
      transaction.finish();
    } else {
      if (isPurchaseInProgress(getState())) {
        trackPurchaseReceiptReceived(
          getPaymentProvider(getState()),
          transaction.products[0].id
        );
      }
      transaction.verify();
    }
  };

export const onError =
  (err: CdvPurchase.IError): BaseThunk =>
  (dispatch, getState) => {
    const paymentProvider = getPaymentProvider(getState());

    // We return this error when the plugin tries to validate
    // a transaction id when the account is already premium
    if (err.code === global.CdvPurchase.ErrorCode.FINISH) {
      return;
    }

    if (err.code !== global.CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
      captureException(new PurchaseError(err.message));
      trackPurchaseFailed(paymentProvider);
    } else {
      trackPurchaseCancelled(paymentProvider);
    }
    dispatch(finishPurchaseProcess());
  };
