import { AppEpicWithoutStateEffect } from '../types';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  RECEIVE_NOTIFICATION_PUSH_SERVICE,
  receiveNotificationPushService,
} from '../../ducks/pushService';
import { mergeMapThunkActionsWithoutStateEffect } from '../operators';
import { showAppRate } from '../../ducks/appRate';

type ReceivePushNotificationAction = ReturnType<
  typeof receiveNotificationPushService
>;

export const openAppRating: AppEpicWithoutStateEffect<
  ReceivePushNotificationAction
> = ($actions, $state) => {
  return $actions.pipe(
    switchMap(action =>
      of(action).pipe(
        ofType(RECEIVE_NOTIFICATION_PUSH_SERVICE),
        filter(
          action => action.payload?.additionalData?.pn_type === 'ask_app_rating'
        ),
        mergeMapThunkActionsWithoutStateEffect(showAppRate, $state),
        catchError(() => EMPTY)
      )
    )
  );
};
