import * as React from 'react';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import familyTherapyImg from '../../../assets/base/images/wellbeing/family-therapy-big.png';
import { t } from '../../../lib/i18n';
import WellbeingFeaturePage from './WellbeingFeaturePage';
import WellbeingFeatureGetStartedSection from './WellbeingFeatureGetStartedSection';
import WellbeingAccordionSection from './WellbeingAccordionSection';
import { FlexLayout, IconType, Typography } from 'styleguide-react';
import FamilyTherapyListSection from './FamilyTherapyListSection';
import WellbeingTherapyDrawer from '../Drawer/WellbeingTherapyDrawer';
import useMergedState from '../../../hooks/useMergedState';

interface FamilyTherapyProps {
  kickoffSessionPrice?: string;
  weeklyPrice: string;
  isLoading?: boolean;
  onGetStarted: () => void;
  onSubscribe: (data: string[]) => void;
}

const FamilyTherapy = ({
  kickoffSessionPrice,
  weeklyPrice,
  isLoading = false,
  onGetStarted,
  onSubscribe,
}: FamilyTherapyProps) => {
  const [state, setState] = useMergedState({ isOpenDrawer: false });
  const openDrawer = () => setState({ isOpenDrawer: true });
  const closeDrawer = () => setState({ isOpenDrawer: false });

  return (
    <RenderWhen screenSize={[ScreenSize.Desktop, ScreenSize.MobileOrTablet]}>
      {screenSize => (
        <WellbeingFeaturePage colorScheme="magenta">
          <WellbeingFeatureGetStartedSection
            screenSize={screenSize}
            weeklyPrice={weeklyPrice}
            kickoffSessionPrice={kickoffSessionPrice}
            title={t('Take your {1}family’s wellbeing{/1} to the next level', {
              1: (str: string) => (
                <span className="par-wellbeing-feature__title-highlighted">
                  {str}
                </span>
              ),
            })}
            description={t(
              'Look after your family’s wellbeing with a unique approach to family therapy that combines professional expertise with your child’s online behavioral insights.'
            )}
            image={
              <img
                src={familyTherapyImg}
                alt=""
                width={isDesktop(screenSize) ? 333.5 : 198}
                height={isDesktop(screenSize) ? 287 : 170.6}
              />
            }
            buttonText={t('Get started')}
            onClickButton={() => {
              onGetStarted();
              openDrawer();
            }}
            isDisabledButton={isLoading}
          />
          <FamilyTherapyListSection screenSize={screenSize} />
          <FlexLayout
            mainaxis="column"
            crossaxisAlignment="center"
            width="100%"
            paddingLeft={isDesktop(screenSize) ? '32' : '24'}
            paddingRight={isDesktop(screenSize) ? '32' : '24'}
            paddingBottom={isDesktop(screenSize) ? '32' : '24'}
          >
            <FlexLayout
              mainaxis="column"
              crossaxisAlignment="center"
              maxWidth={isDesktop(screenSize) ? '100%' : '520'}
            >
              <Typography type="caption1" align="center" color="gray">
                {t(
                  '*We encourage you to share your child’s Qustodio insights for the most personalized support.'
                )}
              </Typography>
            </FlexLayout>
          </FlexLayout>
          <WellbeingAccordionSection
            title={t('The support {1}every family{/1} deserves', {
              1: str => (
                <span className="par-wellbeing-feature__title-highlighted">
                  {str}
                </span>
              ),
            })}
            description={t(
              'Learn more about how we can help your family thrive with a personalized approach to family wellbeing'
            )}
            colorScheme="magenta"
            items={[
              {
                icon: IconType.heartCircleCheck,
                isOpen: false,
                title: t('Improve your relationships'),
                content: t(
                  'Build trust, resolve conflicts, and develop meaningful connections.'
                ),
              },
              {
                isOpen: false,
                icon: IconType.messageSmile,
                title: t('Find tailor-made solutions'),
                content: t(
                  'Discover practical solutions and take steps to reach your family’s goals.'
                ),
              },
              {
                isOpen: false,
                icon: IconType.familyPants,
                title: t('Strengthen your parenting skills'),
                content: t(
                  'Gain confidence as a parent and establish ways to co-parent that work for everyone involved.'
                ),
              },
              {
                isOpen: false,
                icon: IconType.commentsQuestionCheck,
                title: t('Improve communication'),
                content: t(
                  'Allow everyone in your family to express their feelings and concerns in a safe space.'
                ),
              },
            ]}
            isDisabledButton={isLoading}
            onClickButton={() => {
              onGetStarted();
              openDrawer();
            }}
          />
          <WellbeingTherapyDrawer
            closeDrawer={closeDrawer}
            isOpen={state.isOpenDrawer}
            isDisabledButton={isLoading}
            onClickButton={data => {
              onSubscribe(data);
              closeDrawer();
            }}
          />
        </WellbeingFeaturePage>
      )}
    </RenderWhen>
  );
};

export default FamilyTherapy;
