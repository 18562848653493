import { receiveData, requestFetchData } from '.';
import {
  getAccountOptionMultiParentFeatureFlagEnabled,
  isSlaveAccount,
} from '../../selectors';
import {
  fetchAccount,
  fetchAccountOptions,
  fetchMasterAccount,
  fetchMultiParentFeatureFlag,
} from '../account';
import { fetchDevices } from '../devices';
import { fetchGifts } from '../gifts/gifts';
import { fetchParentDevices } from '../parentDevices';
import { fetchProfilesIfNeeded } from '../profiles';
import { setIsDataFetched } from './bootApp';
import { fetchFeatures } from '../features/thunks';

export const fetchAppData = () => async (dispatch, getState) => {
  dispatch(requestFetchData());
  await dispatch(fetchAccount());
  const accountOptionsReady = dispatch(fetchAccountOptions());
  const profilesReady = dispatch(fetchProfilesIfNeeded());
  const devicesReady = dispatch(fetchDevices());
  const parentDevicesReady = dispatch(fetchParentDevices());
  const giftsReady = dispatch(fetchGifts());
  const masterAccountReady = dispatch(fetchMasterAccount());
  const featuresReady = dispatch(fetchFeatures());

  await Promise.all([
    accountOptionsReady,
    profilesReady,
    devicesReady,
    parentDevicesReady,
    giftsReady,
    masterAccountReady,
    featuresReady,
  ]);

  /**
   * Multi-parent feature visibility
   *
   * We need to request to backend if the multi-parent feature is visible, only if the flag
   * is not already set to `true` in account options.
   */
  const state = getState();
  if (
    !isSlaveAccount(state) &&
    !getAccountOptionMultiParentFeatureFlagEnabled(state)
  ) {
    await dispatch(fetchMultiParentFeatureFlag());
  }

  dispatch(receiveData());
  dispatch(setIsDataFetched(true));
  return Promise.resolve(undefined);
};
