import * as React from 'react';
import { t } from '../../../../lib/i18n';
import { Typography } from 'styleguide-react';

const StartTrialLabel = () => {
  return (
    <Typography
      type="body2"
      weight="semibold"
      className="profile-rules-label__start-trial-label"
      testId="profile-rules-label-start-trial"
    >
      {t('Try for free')}
    </Typography>
  );
};

export { isVisibleStartTrialLabel } from './predicate';
export default StartTrialLabel;
