import * as React from 'react';
import SpeedDialMainButton from './SpeedDialMainButton';

const { Children } = React;

const getChildrenButtons = (
  children
): { main: JSX.Element; childs: JSX.Element[] } => {
  const buttons: { main: JSX.Element; childs: JSX.Element[] } = {
    main: <div />,
    childs: [],
  };

  Children.forEach(children, (child: any) => {
    if (child) {
      if (child.type === SpeedDialMainButton) {
        buttons.main = child;
        return;
      }
      buttons.childs.push(child);
    }
  });

  return buttons;
};

const SpeedDialMenu: React.FunctionComponent<{
  isOpen: boolean;
  onToggle: () => void;
}> = ({ children, isOpen, onToggle }) => {
  const toggleMenu = evt => {
    evt.preventDefault();
    onToggle();
  };

  const buttons = getChildrenButtons(children);
  const main =
    buttons.main &&
    React.cloneElement(buttons.main, {
      onClick: toggleMenu,
    });

  return (
    <ul
      className="mfb-zoomin mfb-component--br"
      data-mfb-toggle="click"
      data-mfb-state={isOpen ? 'open' : 'closed'}
    >
      <li className="mfb-component__wrap">
        {main}
        <ul className="mfb-component__list">{buttons.childs}</ul>
      </li>
    </ul>
  );
};

export default SpeedDialMenu;
