import * as React from 'react';
import { t } from '../../lib/i18n';
import EditPasswordFormConnected, {
  PasswordFormRecord,
} from './EditPasswordForm';
import { FormNames } from '../../constants';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const EditPassword: React.FunctionComponent<{
  initialValues: PasswordFormRecord;
  onFormSubmit: () => void;
  showSpinner: boolean;
}> = ({ initialValues, onFormSubmit, showSpinner }) => (
  <PageLayout
    title={t('Change Password')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
  >
    <EditPasswordFields
      initialValues={initialValues}
      onFormSubmit={onFormSubmit}
      showSpinner={showSpinner}
    />
  </PageLayout>
);

export const EditPasswordFields: React.FunctionComponent<{
  initialValues: PasswordFormRecord;
  showSpinner?: boolean;
  onFormSubmit: () => void;
}> = ({ initialValues, showSpinner, onFormSubmit }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="space-between"
    flexGrow="2"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <EditPasswordFormConnected
        className="EditPasswordForm"
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      />
    </FlexLayout>
    <FlexLayout mainaxis="row" mainaxisAlignment="center">
      <Button
        centered
        size={ButtonSize.medium}
        data-testid="save-edit-name"
        htmlType="submit"
        form={FormNames.editPassword}
        loading={showSpinner}
      >
        {t('Save')}
      </Button>
    </FlexLayout>
  </FlexLayout>
);

export default EditPassword;
