import * as R from 'ramda';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import * as Moment from 'moment';
import { ExperimentRecord } from '../../records/experiment';
import {
  receiveExperiments,
  ExperimentNames,
  currentExperiments,
} from '../../ducks/experiments';
import {
  getExperiment,
  getRatingPopupLastDate,
  getPersistentStorageItem,
} from '../../selectors/index';
import Debug from '../../components/Debug/Debug';
import {
  setItem,
  storageKeys,
  Key,
  UPDATE_LAST_INTERACTION_TS,
} from '../../ducks/persistentStorage';

import timeout from '../../lib/timeout';
import { setModal } from '../../ducks/notifications';
import { StandaloneFlowFlag } from '../../helpers/query';
import { setRatingPopupLastDate } from '../../ducks/account';
import { API_TIMESTAMP_FORMAT_WITH_T } from '../../helpers/dates';
import {
  ScenarioName,
  setCurrentScenario,
  scenarios,
} from '../../ducks/scenarios/scenarios';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { isiOSPlatform } from '../../helpers';

const makeFakeExperiment = state => {
  const getExperimentResult = name => {
    const experimentExist = getExperiment(state, name);
    return experimentExist ? experimentExist.result : 'a';
  };
  // eslint-disable-next-line no-underscore-dangle
  return Map(currentExperiments).map((_currentExperiments, name) =>
    ExperimentRecord.fromPayload({ name, result: getExperimentResult(name) })
  ) as Map<ExperimentNames, ExperimentRecord>;
};

const mapStateToProps = (state, { params }) => {
  const profileId = parseInt(params.profileId, 10);
  const getItem = R.partial(getPersistentStorageItem, [state]);
  const pairs = storageKeys.map<[Key, string]>(key => [key, getItem(key)]);
  return {
    profileId,
    storageValues: Map(R.fromPairs(pairs)),
    experiments: makeFakeExperiment(state).toList(),
    ratingPopupLastDate: Moment(getRatingPopupLastDate(state)).format(
      API_TIMESTAMP_FORMAT_WITH_T
    ),
    scenarios,
    isIOS: isiOSPlatform(),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickEdit: profileId => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onToggle: key => {
    dispatch((dispatch, getState) => {
      const value = getPersistentStorageItem(getState(), key);
      return dispatch(setItem(key, value !== 'true' ? 'true' : ''));
    });
  },
  onChangeDate: (key, value) => {
    const time = Moment.utc(value);
    dispatch(setItem(key, momentToStorageString(time)));
  },
  onChangeLastInteractionTs: value => {
    const time = Moment.utc(value);
    dispatch({
      type: UPDATE_LAST_INTERACTION_TS,
      payload: momentToStorageString(time),
    });
  },
  onClickErrorButton: withTimeout => {
    if (withTimeout) {
      timeout(() => {
        throw new Error('Forced runtime error in timeout');
      });
    } else {
      dispatch({
        badAction: 'Forced runtime error in dispatch from bad action',
      });
    }
  },
  onClickSetNotificationModal: () => {
    dispatch(
      setModal({
        headerText: 'Alarming header!',
        markdown: `
This modal has customized:

1. markdown
2. icon by URL
3. header text (although that could be in the markdown too!)
4. button text

Here's a link to [a website](http://www.yesnoif.com/), here one to a [local
screen](#some/route).

![gifffffff](https://thumbs.gfycat.com/QuarrelsomeHonorableAracari-max-1mb.gif)
`,
        icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Elm_logo.svg',
        redirect: '/account-settings',
      })
    );
  },
  onChangeExperiment: (name, value) =>
    dispatch((dispatch, getState) => {
      const experiments = makeFakeExperiment(getState());
      const setResult = experiments.get(name).set('result', value);
      const newExperimentsMap = experiments.set(name, setResult);
      dispatch(receiveExperiments(newExperimentsMap));
    }),
  onClickStandalone: (flow: StandaloneFlowFlag) => () => {
    // eslint-disable-next-line no-underscore-dangle
    dispatch((_dispatch, getState) => {
      const accessToken = getPersistentStorageItem(getState(), 'accessToken');
      const refreshToken = getPersistentStorageItem(getState(), 'refreshToken');
      window.location.assign(
        [
          window.location.protocol,
          '//',
          window.location.hostname,
          window.location.port ? `:${window.location.port}` : '',
          '?',
          queryString.stringify({
            access_token: accessToken,
            refresh_token: refreshToken,
            standalone: flow,
          }),
        ].join('')
      );
    });
  },
  setRatingPopUpLastDate: (newDate: string) =>
    dispatch(setRatingPopupLastDate(newDate)),
  onChangeAppRateScenario: (scenarioName: ScenarioName) => {
    const scenarioSelected = scenarios.find(
      scenario => scenario.name === scenarioName
    );
    dispatch(setCurrentScenario(scenarioSelected));
  },
  onClickNavigateToUpgradeWithPromotion: (promotion: string) => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(navigate({ type: 'inner', src: `/upgrade/${promotion}` }));
  },
});

const momentToStorageString = time =>
  time.isValid() ? time.toISOString() : '';

const DebugContainer = connect(mapStateToProps, mapDispatchToProps)(Debug);

export default DebugContainer;
