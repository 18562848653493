import * as React from 'react';
import { ToastManager, ToastType } from 'styleguide-react';

export const toast = (
  texts: string[],
  type?: ToastType,
  icon?: JSX.Element
) => {
  // HACK: forcing a context switch so the reducer in which this function is invoked doesn't "break" its purity rule.
  setTimeout(() => {
    ToastManager.getInstance().send(
      '',
      <div>
        {texts.map(text => (
          <p key={text}>{text}</p>
        ))}
      </div>,
      type,
      icon
    );
  }, 0);
};
