import * as React from 'react';
import { IconColor, PageTitle } from 'styleguide-react';

// eslint-disable-next-line react/prefer-stateless-function
class AddPlaceHeader extends React.Component<{
  title: string;
  showDeleteButton: boolean;
  onClick: () => any;
  onClickDelete: () => any;
}> {
  render() {
    const { title, showDeleteButton, onClick, onClickDelete } = this.props;
    return (
      <PageTitle
        title={title}
        size="small"
        className="PlaceFormHeader"
        testId="pageTitle"
        actions={[
          { actionName: 'back', onClick },
          showDeleteButton
            ? {
                actionName: 'delete',
                onClick: onClickDelete,
                iconColor: IconColor.error,
              }
            : { actionName: '' },
        ]}
      />
    );
  }
}

export default AddPlaceHeader;
