/**
 * Useful to validate all enum cases.
 *
 * @example
 *
 * ```
 * const getText = (timeLimitType: TimeLimitType): string => {
 *   switch (timeLimitType) {
 *    case TimeLimitType.APPLY:
 *        return t('Apply daily time limits');
 *    case TimeLimitType.DONT_APPLY:
 *       return t('Don’t apply daily time limits');
 *    default:
 *       return exhaustiveCheck(timeLimitType);
 *       ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *   }
 * };
 * ```
 */
export const exhaustiveCheck = (value: never) => value;
