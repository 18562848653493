import { connect } from 'react-redux';
import { t } from '../../../lib/i18n';
import { goBackIfHistory } from '../../../ducks/routing';
import CallsSMSRulesAddContact from '../../../components/CallsSMSRules/CallsSMSRulesAddContact';
import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import {
  getSelectedContactAction,
  getProfileRulesModifyStatus,
  getProfileRules,
  getCallsSMSContacts,
  getAccountCountry,
} from '../../../selectors';
import { setSelectedContactAction } from '../../../ducks/profileRules';
import { CallsSmsContactRecord } from '../../../records/profileRules';
import { TOAST_ICON_TICK, showToast } from '../../../ducks/toast';
import { CALLSMS_RULES_ALLOW } from '../../../constants';
import {
  trackCallsAndSMSRule,
  CallsAndSMSRuleNames,
} from '../../../helpers/analytics';
import { BaseThunk } from '../../../store/state';

const mapStateToProps = (state, { params: { profileId } }) => ({
  selectedContactAction: getSelectedContactAction(state),
  isSaving: getProfileRulesModifyStatus(state),
  contacts: getCallsSMSContacts(state, profileId),
  country: getAccountCountry(state),
});

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickContactAction: action => dispatch(setSelectedContactAction(action)),
  onSubmit: contact => {
    dispatch(handleNewContact({ contact, profileId }));
  },
  onClickBackButton: () => {
    dispatch(goBackIfHistory());
  },
});

export const handleNewContact =
  ({ contact, profileId }): BaseThunk =>
  async (dispatch, getState) => {
    trackCallsAndSMSRule(CallsAndSMSRuleNames.INDIVIDUAL_PHONE_NUMBERS_CHANGE);

    await dispatch(
      dispatchModifyProfileRules(
        profileId,
        addContact(contact.name, contact.phone)
      )
    );

    await dispatch(goBackIfHistory());

    const action = getSelectedContactAction(getState());

    return dispatch(
      showToast(
        t('All calls with {{name}} will be {1}blocked{/1}{2}allowed{/2}', {
          name: contact.name,
          1: (blockedStr: string) =>
            action !== CALLSMS_RULES_ALLOW ? blockedStr : '',
          2: (allowedStr: string) =>
            action === CALLSMS_RULES_ALLOW ? allowedStr : '',
        }).join(''),
        TOAST_ICON_TICK
      )
    );
  };

export const addContact = (name, phone) => (state, profileId) => {
  const action = getSelectedContactAction(state);
  return getProfileRules(state, profileId).setIn(
    ['callSmsMonitoring', 'contactBlock'],
    getCallsSMSContacts(state, profileId).push(
      CallsSmsContactRecord({ name, phone, mode: action })
    )
  );
};

const CallsSMSRulesAddContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsSMSRulesAddContact);

CallsSMSRulesAddContactContainer.load = () => dispatch =>
  dispatch(setSelectedContactAction(CALLSMS_RULES_ALLOW));

export default CallsSMSRulesAddContactContainer;
