import { Record } from 'immutable';
import { ProductAddonsOffer } from '../../records/productAddonsOffer/types';

export const calculateKickoffSessionPrice = (
  productAddon: Record<ProductAddonsOffer>
) => {
  /* The amount with discount is already calculated by the backend 
  (be cautious with the property name, it's somewhat confusing).
  */
  const priceWithDiscount = productAddon.get('discountAmount');
  return priceWithDiscount;
};

export const calculateWeeklyPrice = (
  productAddon: Record<ProductAddonsOffer>
) => {
  const price = productAddon.get('amount');
  return price;
};

export const formatProductAddonPrice = (price: number) => {
  const formattedPrice = price % 1 !== 0 ? price.toFixed(2) : price;
  return `$${formattedPrice}`;
};
