import { getTimezone, getAccountCurrentTime } from '../../../selectors';
import { getProfile } from '../../../selectors/profileRaw';
import { getAccount } from '../../../selectors/account';
import { APPThunk, sequentially } from '../../../helpers/thunks';
import {
  fetchCreateCalendarRestrictionPause,
  fetchCalendarRestrictionPause,
  fetchDeleteCalendarRestrictionPause,
} from '../../../ducks/calendarRestrictionsPause';
import {
  trackButtonClicked,
  GenericPageNames,
  ButtonNames,
} from '../../../helpers/analytics';
import { getActiveCalendarRestriction } from '../../../selectors/stateSelectors/calendarRestrictionsPause';
import { localNow } from '../../../helpers/dates';

const setInternetPauseDuration =
  (minutes: number, profileId: number): APPThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profileUid = getProfile(state, profileId).uid;
    const accountUid = getAccount(state).uid;

    const timezone = getTimezone(state);
    const currentTime = localNow(timezone);

    const start = currentTime.clone();
    const end = currentTime.clone().add('minutes', minutes);

    return dispatch(
      sequentially(
        fetchCreateCalendarRestrictionPause(start, end, profileUid, accountUid),
        fetchCalendarRestrictionPause(profileUid, String(profileId))
      )
    );
  };

const trackScheduleCalendarPause =
  (minutes: number): APPThunk =>
  (_, getState) => {
    const state = getState();
    const currentTime = getAccountCurrentTime(state);
    const end = currentTime.clone().add('minutes', minutes);

    return trackButtonClicked(
      GenericPageNames.Profile,
      ButtonNames.SchedulePauseInternet,
      {
        options: JSON.stringify({
          quick_action: true,
          end_pause_timestamp: end.unix(),
          user_local_timezone: getTimezone(state),
        }),
      }
    );
  };

export const unPauseInternet =
  (profileId: number): APPThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profileUid = getProfile(state, profileId).uid;
    const accountUid = getAccount(state).uid;
    const calendarRestrictionUid = getActiveCalendarRestriction(state)?.uid;

    return dispatch(
      fetchDeleteCalendarRestrictionPause(
        calendarRestrictionUid,
        profileUid,
        accountUid
      )
    );
  };

export const createCalendarRestrictionPause = (
  minutes: number,
  profileId: string
): APPThunk => {
  const id = parseInt(profileId, 10);

  return sequentially(
    trackScheduleCalendarPause(minutes),
    setInternetPauseDuration(minutes, id)
  );
};

export const editCalendarRestrictionPause = (
  minutes: number,
  profileId: string
): APPThunk => {
  const id = parseInt(profileId, 10);

  return sequentially(
    trackScheduleCalendarPause(minutes),
    unPauseInternet(id), // we remove the latest instance of the restriction rule before setting the edited one.
    setInternetPauseDuration(minutes, id)
  );
};
