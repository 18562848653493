import * as React from 'react';
import { List } from 'immutable';
import { Field } from 'redux-form/immutable';
import {
  Avatar,
  FlexLayout,
  Icon as StyledIcon,
  IconColor,
  IconFamily,
  IconType,
} from 'styleguide-react';
import { genderImageAssetFromPng } from '../../helpers';
import { svgAvatarsMap } from '../../helpers/avatarImageMaps';
import Icon from '../Icons/Icon';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
// eslint-disable-next-line import/no-unresolved
import { LayoutContentSpacing } from 'styleguide-react/build/components/Layout/types';

const getImage = genderImageAssetFromPng(svgAvatarsMap, svgAvatarsMap.boy[1]);

const AvatarRadioButtonGroup: React.FunctionComponent<{
  avatars: List<string>;
  name: string;
  disabled: boolean;
  selectedValue: string | null;
}> = ({ avatars, name, disabled, selectedValue }) => {
  const renderAvatars = (maxWidth?: LayoutContentSpacing) => (
    <FlexLayout
      mainaxis="row"
      wrap="wrap"
      mainaxisAlignment="space-evenly"
      maxWidth={maxWidth}
    >
      {avatars.map(image => (
        <label key={image}>
          <Field
            type="radio"
            component="input"
            name={name}
            value={image}
            disabled={disabled}
          />
          <Avatar
            key={image}
            disabled={disabled}
            discarded={selectedValue !== image}
            icon={
              selectedValue === image ? (
                <StyledIcon
                  type={IconType.pencil}
                  color={IconColor.primary}
                  family={IconFamily.solid}
                />
              ) : undefined
            }
          >
            <Icon path={getImage(image)} />
          </Avatar>
        </label>
      ))}
    </FlexLayout>
  );

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderAvatars('320')}
      </RenderWhen>

      <RenderWhen screenSize={ScreenSize.Desktop}>{renderAvatars()}</RenderWhen>
    </React.Fragment>
  );
};

export default AvatarRadioButtonGroup;
