type Status =
  | 'granted_newly_after_rationale'
  | 'granted_newly_without_rationale'
  | 'granted_newly_after_settings'
  | 'granted_already'
  | 'denied_permanently_already'
  | 'denied_permanently_newly'
  | 'denied_not_permanently_already'
  | 'denied_not_permanently_newly'
  | 'denied_permanently_already_after_settings'
  | 'denied_through_rationale_dialog'
  | 'denied_through_last_resort_dialog'
  | 'not_needed'
  | 'not_android'
  | 'error';

type Permissions = {
  [key in Status]: boolean;
};

const interpretStatus = (status: Status) => {
  const permissions: Permissions = {
    granted_newly_after_rationale: true,
    granted_newly_without_rationale: true,
    granted_newly_after_settings: true,
    granted_already: true,
    not_needed: true,
    denied_permanently_already: false,
    denied_permanently_newly: false,
    denied_not_permanently_already: false,
    denied_not_permanently_newly: false,
    denied_permanently_already_after_settings: false,
    denied_through_rationale_dialog: false,
    denied_through_last_resort_dialog: false,
    not_android: false,
    error: false,
  };

  return permissions[status];
};

/**
 * Requests native notification permissions asynchronously (only works for Android).
 *
 * This plugin, by default, attempts to prompt the user to grant notification permissions to the application.
 *
 * It is important to note that the native notification permission modal will only be shown 2 times.
 * If the user chooses 'No', it will no longer be displayed (this is Android's behavior).
 * The only possible way to grant permissions is through the settings explicitly.
 *
 * It's important to note that the native modal will only appear on Android with API 33 (Android 13),
 * as in lower versions, permissions are granted by default.
 * This behavior can be somewhat confusing, and it's recommended to
 * read the documentation at https://developer.android.com/develop/ui/views/notifications/notification-permission?hl=es-419#existing-apps.
 *
 */
export const maybeRequestNativeNotificationPermissions =
  (): Promise<boolean> => {
    const notificationsPermissionPlugin =
      window.cordova.notifications_permission;

    const modalOptions = {
      show: false, // Avoid additional flows to native behavior.
      title: '',
      msg: '',
      okButton: '',
      cancelButton: '',
      theme:
        notificationsPermissionPlugin.themes.Theme_DeviceDefault_Dialog_Alert,
    };

    return new Promise((resolve, reject) => {
      notificationsPermissionPlugin.maybeAskPermission(
        (status: Status) => {
          status === 'error'
            ? reject(new Error('Failed to obtain notification permissions'))
            : resolve(interpretStatus(status));
        },
        modalOptions,
        modalOptions
      );
    });
  };
