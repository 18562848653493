import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Label,
  Layout,
  StyledHeader,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import shieldSvg from '../../../assets/base/images/onboarding/shield.svg';
import type { OnboardingFinishProps } from '../../../containers/Onboarding/OnboardingFinishContainer';

const OnboardingFinish: React.FunctionComponent<OnboardingFinishProps> = ({
  profile,
  onClickNext,
  isLoadingProfiles,
}: OnboardingFinishProps) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    crossaxisAlignment="center"
    matchParentHeight
  >
    <Layout minHeight="200" minWidth="200">
      <img src={shieldSvg} alt="Icon Shield" />
    </Layout>
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      marginTop="16"
      marginBottom="48"
    >
      <FlexLayout mainaxis="column" marginBottom="24">
        <StyledHeader type="h5" className="Main__Onboarding--title">
          <strong>
            {t('Well done! {{profileName}}’s device is now protected', {
              profileName: profile?.name,
            })}
          </strong>
        </StyledHeader>
      </FlexLayout>
      <Label className="Main__Onboarding--text">
        {t(
          'Keep an eye on your inbox. You’ll receive a notification once {{profileName}} detects activity on this device.',
          {
            profileName: profile?.name,
          }
        )}
      </Label>
    </FlexLayout>
    <Button
      data-testid="Button--explore-my-dashboard"
      onClick={onClickNext}
      loading={isLoadingProfiles}
      disabled={isLoadingProfiles}
      size={ButtonSize.medium}
    >
      {t('explore my dashboard')}
    </Button>
  </FlexLayout>
);

export default OnboardingFinish;
