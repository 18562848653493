import { Map, Record } from 'immutable';
import { ProductAddonsOfferState } from './types/state';
import { ProductAddonsOfferActions } from './types/actions';
import { ProductAddonsOffer } from '../../records/productAddonsOffer/types';

export const createState = Record({
  status: 'IDLE',
  addons: Map({}),
});

export const initialState = createState() as ProductAddonsOfferState;

const updateAddonsAndStatus = (
  state: ProductAddonsOfferState,
  products: Record<ProductAddonsOffer>[]
): ProductAddonsOfferState => {
  const addons = products.reduce(
    (addons, product) => addons.set(product.addonType, product),
    state.addons
  );
  return state.set('status', 'IDLE').set('addons', addons);
};

const productAddonsOfferReducer = (
  state: ProductAddonsOfferState = initialState,
  action: ProductAddonsOfferActions
) => {
  switch (action.type) {
    case 'REQUEST_PRODUCT_ADDONS_OFFER':
      return state.set('status', 'LOADING');
    case 'RECEIVE_PRODUCT_ADDONS_OFFER':
      return updateAddonsAndStatus(state, action.payload.products);
    default:
      return state;
  }
};

export default productAddonsOfferReducer;
