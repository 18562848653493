import { connect } from 'react-redux';
import ResolveAddress from '../../components/FamilyLocator/ResolveAddress';
import * as debounce from 'lodash.debounce';
import {
  getAddressSearchString,
  getAddressSearchResults,
  isFetchingAddresses,
} from '../../selectors';
import {
  onSearchTextChangeHandler,
  onSearchResultsItemClick,
  regenerateToken,
  resetSearchAddressResults,
  resetSearchAddressForm,
} from '../../actions/FamilyLocatorActions';
import { getSessionToken, hasSessionToken } from '../../selectors/place';
import { setKeyboardVisiblityAtPlaces } from '../../ducks/app';
import { getBuildPlatform } from '../../helpers/globals';

const DEBOUNCE_DELAY = 600;

const mapStateToProps = state => ({
  searchString: getAddressSearchString(state),
  searchResults: getAddressSearchResults(state),
  isFetchingAddresses: isFetchingAddresses(state),
  sessionToken: getSessionToken(state),
  platform: getBuildPlatform(),
});

const mapDispatchToProps = dispatch => {
  return {
    onChangeHandler: (searchString, sessionToken) => {
      if (!searchString) {
        dispatch(resetSearchAddressForm());
        return dispatch(resetSearchAddressResults());
      }

      debounce(onSearchTextChangeHandler(dispatch), DEBOUNCE_DELAY)(
        searchString,
        sessionToken
      );
    },
    onSearchResultsItemClick: (placeId: string, token: string) =>
      dispatch(onSearchResultsItemClick(placeId, token)),
    keyboardHideHandler: () => dispatch(setKeyboardVisiblityAtPlaces(false)),
    keyboardShowHandler: () => dispatch(setKeyboardVisiblityAtPlaces(true)),
  };
};

const ResolveAddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResolveAddress);

ResolveAddressContainer.load = () => (dispatch, getState) => {
  if (!hasSessionToken(getState())) {
    dispatch(regenerateToken());
  }
};

export default ResolveAddressContainer;
