import * as Moment from 'moment-timezone';
import { List } from 'immutable';
import { addHours, convertDate, formatHours } from '../../helpers/dates';
import {
  ActivityTypes,
  MinutesPerHourActivityRecord,
} from '../../records/activity';
import { replaceS3Url } from '../../helpers';
import State from '../../store/state';
import { getAppIcon } from '../../selectors';
import { getAppNameWithPlatform } from '../../records/profileRules';
import { mapPlatform } from '../../records/device';
import {
  MinutesPerHourActivityPayload,
  MinutesPerHourActivityTransformedPayload,
} from '../../records/activity/types/MinutesPerHourActivity.types';

export const shouldFetchSummary = state => !state.get('isFetching');

type EnsureDateTimeProp<T> = T & { datetime: ISODateString };
type EnsureMomentDateProp<T> = EnsureDateTimeProp<T> & {
  momentDate: Moment.Moment;
};
export const addMomentObjectToScreenTimeList = <T>(
  data: EnsureDateTimeProp<T>[],
  timezone: string
): EnsureMomentDateProp<T>[] =>
  data.map(value => ({
    ...value,
    momentDate: convertDate(value.datetime, timezone),
  }));

export const formatTo24HoursScreenTime = (
  data: MinutesPerHourActivityPayload[],
  timezone: string
) => {
  const timezoneHours = addMomentObjectToScreenTimeList(data, timezone);

  const numberOfHours = timezoneHours.length;
  if (numberOfHours === 0) {
    return List([]);
  }

  const numberOfMissingHours = 24 - numberOfHours;
  const lastMomentDate = timezoneHours.slice(-1)[0].momentDate;

  const missingHours = Array.from(Array(numberOfMissingHours).keys()).map(
    index => ({
      datetime: '' as ISODateString,
      momentDate: addHours(index + 1, lastMomentDate),
      screen_time_seconds: 0,
      default_rules_screen_time: null,
      routines_screen_time: null,
    })
  );

  return List([...timezoneHours, ...missingHours])
    .map<MinutesPerHourActivityTransformedPayload>(value => ({
      hour: formatHours(value.momentDate),
      screen_time_seconds: value.screen_time_seconds,
      default_rules_screen_time: value.default_rules_screen_time,
      routines_screen_time: value.routines_screen_time,
    }))
    .map(MinutesPerHourActivityRecord.fromPayload);
};

export const setAppThumbnail = (item, state: State) =>
  item.type === ActivityTypes.App
    ? {
        ...item,
        thumbnail: replaceS3Url(
          getAppIcon(
            state,
            getAppNameWithPlatform(item.exe, mapPlatform(item.platform))
          )
        ),
      }
    : item;
