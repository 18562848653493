import * as React from 'react';

export type PageSectionLayoutSide = 'right' | 'main';
export type PageSectionLayoutPlaceholder =
  | 'before-pullrefresh'
  | 'after-pullrefresh'
  | 'topbar'
  | 'card-banner'
  | 'page-title'
  | 'before-card'
  | 'after-card'
  | 'before-page-title'
  | 'after-page-title';
export interface PageSectionLayoutProps {
  side?: PageSectionLayoutSide;
  children: React.ReactNode;
  placeholder?: PageSectionLayoutPlaceholder;
}

const PageSectionLayout: React.FunctionComponent<PageSectionLayoutProps> = ({
  children,
}) => <React.Fragment>{children}</React.Fragment>;

export default PageSectionLayout;
