import { getEligibleForManualTrial } from '../selectors/stateSelectors/license';
import { BaseThunk } from '../store/state';
import { navigateToProfileList } from './Navigation';
import { showPremiumFlyover } from './PremiumFeaturesActions';

export const redirectToStartTrialFlyover =
  (redirectUrl: string): BaseThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const state = getState();
    const isAvailableForTrial = getEligibleForManualTrial(state);
    if (isAvailableForTrial) {
      dispatch(showPremiumFlyover(redirectUrl));
      return true;
    }

    return false;
  };

export const redirectToHomeIfNotEligibleForTrial =
  (): BaseThunk => (dispatch, getState) => {
    const state = getState();
    const isEligibleForTrial = getEligibleForManualTrial(state);
    if (!isEligibleForTrial) {
      dispatch(navigateToProfileList({ replaceNavigation: true }));
    }
  };
