import * as React from 'react';
import { GlobalType, Tag } from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import { ProfileRulesLabelCasesProps } from '../types/ProfileRulesLabel.types';

const getDaysLeftText = (days: number) => {
  if (days >= 2) return t('{{days}} days left', { days });
  if (days === 1) return t('1 day left');
};

const TrialDaysLeftLabel = ({
  licenseDaysLeft,
}: ProfileRulesLabelCasesProps) => {
  return (
    <Tag
      text={getDaysLeftText(Math.max(0, licenseDaysLeft))}
      testId="trial-days-left"
      variant="squared"
      type={GlobalType.booster}
    />
  );
};

export { isVisibleTrialDaysLeftLabel } from './predicate';
export default TrialDaysLeftLabel;
