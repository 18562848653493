import * as React from 'react';
import { List } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { Label, FlexLayout, Layout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  isValidPhoneNumber,
  isValidEmail,
  isNumberMatch,
  getPhoneNumberInternationalFormat,
} from '../../helpers/string';
import { MAX_EMAIL_LENGTH, PanicRulesContactType } from '../../constants';
import { ContactRecord } from '../../records/contact';
import InputTextField from '../Form/InputTextField';
import RadioButtonField from '../Form/RadioButtonField';

export const formName = 'panicButtonRulesInviteContact';

type PanicButtonRulesInviteContactFormProps = {
  handleSubmit: (event: any) => void;
  contactType: string;
};

const PanicButtonRulesInviteContactForm: React.FunctionComponent<
  PanicButtonRulesInviteContactFormProps
> = ({ handleSubmit, contactType }: PanicButtonRulesInviteContactFormProps) => (
  <form className="Form" onSubmit={handleSubmit} id={formName}>
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="column" marginBottom="48">
        <Layout marginBottom="8">
          <Label htmlFor="name">
            {t('Add the name of your trusted contact.')}
          </Label>
        </Layout>
        <Field
          id="name"
          name="name"
          component={InputTextField}
          type="text"
          placeholder={t('Name')}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
        />
      </FlexLayout>
      <FlexLayout mainaxis="row" crossaxisAlignment="center" marginBottom="8">
        <Field
          id={PanicRulesContactType.Phone}
          name="type"
          type="radio"
          value={PanicRulesContactType.Phone}
          label={t('Phone number')}
          component={RadioButtonField}
        />
        <Layout marginLeft="16" marginRight="72">
          <Label htmlFor={PanicRulesContactType.Phone}>
            {t('Phone number')}
          </Label>
        </Layout>
        <Field
          id={PanicRulesContactType.Email}
          name="type"
          type="radio"
          value={PanicRulesContactType.Email}
          label={t('Email')}
          component={RadioButtonField}
        />
        <Layout marginLeft="16">
          <Label htmlFor={PanicRulesContactType.Email}>{t('Email')}</Label>
        </Layout>
      </FlexLayout>
      {contactType === PanicRulesContactType.Phone ? (
        <Field
          id="textPhone"
          name="contact"
          component={InputTextField}
          type="tel"
          placeholder={t('Phone number')}
          autoComplete="off"
          autoCorrect="off"
        />
      ) : (
        <Field
          id="textEmail"
          name="contact"
          component={InputTextField}
          type="email"
          placeholder={t('Email')}
          autoComplete="off"
          autoCorrect="off"
        />
      )}

      {/* include an invisible submit-button so that the mobile-keyboard
        has a "submit" button: */}
      <button type="submit" style={{ display: 'none' }} />
    </FlexLayout>
  </form>
);

const emailExists = (contacts: List<ContactRecord>, email) =>
  // contacts.some(R.propEq('contact', email));
  contacts.some(({ contact }) => contact.toLowerCase() === email.toLowerCase());

const phoneNumberExists = (
  contacts: List<ContactRecord>,
  phonenumber,
  country
) =>
  contacts.some(contact =>
    isNumberMatch(getPhoneNumberInternationalFormat(phonenumber, country))(
      contact!.contact
    )
  );

const validate = (values, { contacts, country }) => {
  const errors: { [key: string]: string } = {};
  if (!values.name) {
    errors.name = t('Field required');
  }
  if (!values.contact) {
    errors.contact = t('Field required');
  } else if (values.type === PanicRulesContactType.Phone) {
    if (!isValidPhoneNumber(values.contact, country)) {
      errors.contact = t('Add a valid phone number');
    } else if (phoneNumberExists(contacts, values.contact, country)) {
      errors.contact = t(
        'This phone number already exists in your trusted contacts.'
      );
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!isValidEmail(values.contact)) {
      errors.contact = t('Invalid email address');
    } else if (values.contact.length > MAX_EMAIL_LENGTH) {
      errors.contact = t(
        'Email should not be longer than {{MAX_EMAIL_LENGTH}} characters',
        {
          MAX_EMAIL_LENGTH,
        }
      );
    } else if (emailExists(contacts, values.contact)) {
      errors.contact = t(
        "This email already exists in your trusted contacts' lists."
      );
    }
  }
  return errors;
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: ContactRecord(),
  validate,
})(PanicButtonRulesInviteContactForm);
