import { connect } from 'react-redux';
import CallsSMSRulesConnectDeviceFlyover from '../../../components/CallsSMSRules/CallsSMSRulesConnectDeviceFlyover';
import { goBackIfHistory } from '../../../ducks/routing';
import {
  getAndroidConnectHelpLink,
  getIOSConnectHelpLink,
} from '../../../helpers/callsMessages';
import State, { Dispatch } from '../../../store/state';
import {
  CallsSMSRulesConnectDeviceFlyoverDispatchProps,
  CallsSMSRulesConnectDeviceFlyoverStateProps,
} from '../../../types/rules/profile/callsAndMessages/CallsSMSRulesConnectDevice.types';
import { getAccountLocale } from '../../../selectors';
import { navigateToExternalQustodioUrl } from '../../../actions/Navigation';

const mapStateToProps = (
  state: State
): CallsSMSRulesConnectDeviceFlyoverStateProps => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch
): CallsSMSRulesConnectDeviceFlyoverDispatchProps => {
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onClickOpenAndroidHelpLink: () => {
      dispatch(navigateToExternalQustodioUrl(getAndroidConnectHelpLink()));
    },
    onClickOpenIOSHelpLink: () => {
      dispatch(navigateToExternalQustodioUrl(getIOSConnectHelpLink()));
    },
  };
};

const CallsSMSRulesConnectDeviceFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsSMSRulesConnectDeviceFlyover);

export default CallsSMSRulesConnectDeviceFlyoverContainer;
