import * as React from 'react';
import useMergedState from '../../hooks/useMergedState';

const IOSPurchaseInfo = () => {
  const [state, setState] = useMergedState<{
    localReceipts: CdvPurchase.Receipt[];
    localTransactions: CdvPurchase.Transaction[];
    products: CdvPurchase.Product[];
    verifiedPurchases: CdvPurchase.VerifiedPurchase[];
    verifiedReceipts: CdvPurchase.VerifiedReceipt[];
    refreshCounter: number;
  }>({
    localReceipts: [],
    localTransactions: [],
    products: [],
    verifiedPurchases: [],
    verifiedReceipts: [],
    refreshCounter: 0,
  });

  React.useEffect(() => {
    const { store } = CdvPurchase;

    setState({
      localReceipts: store.localReceipts,
      localTransactions: store.localTransactions,
      products: store.products,
      verifiedPurchases: store.verifiedPurchases,
      verifiedReceipts: store.verifiedReceipts,
    });
  }, [state.refreshCounter]);

  return (
    <div className="section container">
      <h4>💸 IOS Purchase info</h4>
      <label>Data:</label>
      <pre>{JSON.stringify(state, null, 2)}</pre>
      <button
        className="btn"
        type="button"
        onClick={() => setState({ refreshCounter: state.refreshCounter + 1 })}
      >
        Refresh data
      </button>
    </div>
  );
};

export default IOSPurchaseInfo;
