export const notifyLogoutAction = () => ({
  type: 'NOTIFY_LOGOUT',
});

export const notifyNavigationAction = (url: string) => ({
  type: 'NOTIFY_NAVIGATION',
  payload: {
    url,
  },
});
