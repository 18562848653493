import { RouterAction } from 'react-router-redux';
import {
  showAppPremiumFlyover,
  delayedModifyProfileRules,
  delayedModifyProfileVideoRules,
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
} from '../ducks/profileRules';

import { ProfileRulesRecord } from '../records/profileRules';
import { getProfileDevicesSortedByName, isMultiDevices } from '../selectors';
import {
  RULE_DAILY_TIME_LIMITS,
  RULE_RESTRICTED_TIMES,
  RULE_WEB_FILTERING,
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  VideoSources,
  MENU_OPTION_SUMMARY,
  MENU_OPTION_ACTIVITY_TIMELINE,
  MENU_OPTION_INSIGHTS,
  MENU_OPTION_RULES,
  MENU_OPTION_STUDENT_SUMMARY,
  MENU_OPTION_STUDENT_ACTIVITY_TIMELINE,
  RULE_ROUTINES,
  RULE_YOUTUBE,
  MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY,
} from '../constants';
import { tapReject } from '../helpers';
import { gaEvent } from '../helpers/ga';
import { showErrorToast } from '../containers/AccountSettings/helpers';
import { updateCurrentTime } from '../ducks/times';
import State from '../store/state';
import {
  track,
  Events,
  trackButtonClicked,
  GenericPageNames,
  ButtonNames,
  ProfileRuleNames,
} from '../helpers/analytics';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import flags from '../sideEffects/flags';
import { getIsSchoolOnlyProfile } from '../containers/Dashboard/helpers/profileDashboardHelpers';
import { isStudentOptInForDelegationForThisAccount } from '../selectors/studentPolicies';

const trackRuleClick = (ruleName: ProfileRuleNames) => {
  trackButtonClicked(GenericPageNames.ProfileRules, ButtonNames.Rule, {
    options: JSON.stringify({ rule_name: ruleName }),
  });
};

/**
 * Check if the provided profile is a valid profile for delegation,
 * and has a virtual device (school device and delegated device are considered virtual devices for now)
 * also prevents accessing the rule is has the multi devices rule enabled,
 * as the multi device required a correct device id
 */
const isDelegatedDeviceWithVirtualDeviceOnly = (
  state: State,
  profileId: number
) =>
  flags.useDelegation.isEnabled() &&
  (getIsSchoolOnlyProfile(state, profileId) ||
    isStudentOptInForDelegationForThisAccount(state)) &&
  !isMultiDevices(state, profileId);

/**
 * Clicking a rule/section in the rules list screen has some delicate logic
 */
export const clickRule =
  (profileId: number) => (key: string) => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    switch (key) {
      case MENU_OPTION_SUMMARY: {
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}`,
          })
        );
        break;
      }
      case MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY: {
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/family-and-school`,
          })
        );
        break;
      }
      case MENU_OPTION_INSIGHTS: {
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/insights`,
          })
        );
        break;
      }
      case RULE_DAILY_TIME_LIMITS: {
        trackRuleClick(ProfileRuleNames.DailyTimeLimits);
        dispatch(updateCurrentTime());
        const state = getState();
        const device = getProfileDevicesSortedByName(state, profileId).first();
        // allow profile with only virtual devices (so they do not have deviceId) to access this rule as long as they do not use multi device
        if (
          device ||
          isDelegatedDeviceWithVirtualDeviceOnly(state, profileId)
        ) {
          const deviceId = device ? device.id : 0;
          dispatch(
            navigate({
              type: 'inner',
              src: `/profiles/${profileId}/rules/dailyTimeLimit/devices/${deviceId}`,
            })
          );
        }
        break;
      }
      case RULE_RESTRICTED_TIMES: {
        trackRuleClick(ProfileRuleNames.RestrictedTimes);
        dispatch(updateCurrentTime());
        const state = getState();
        const device = getProfileDevicesSortedByName(
          getState(),
          profileId
        ).first();
        if (
          device ||
          isDelegatedDeviceWithVirtualDeviceOnly(state, profileId)
        ) {
          const deviceId = device ? device.id : 0;
          dispatch(
            navigate({
              type: 'inner',
              src: `/profiles/${profileId}/rules/restrictedTimes/devices/${deviceId}`,
            })
          );
        }
        break;
      }
      case RULE_WEB_FILTERING:
        trackRuleClick(ProfileRuleNames.WebFiltering);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/webFilters`,
          })
        );
        break;
      case RULE_GAMES_AND_APPS:
        trackRuleClick(ProfileRuleNames.GamsAndApps);
        dispatch(showAppPremiumFlyover(false));
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/appRules`,
          })
        );
        break;
      case RULE_LOCATION:
        trackRuleClick(ProfileRuleNames.Location);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/locationRules`,
          })
        );
        gaEvent('rules-list', 'click', 'location');
        break;
      case RULE_CALLS_AND_SMS:
        trackRuleClick(ProfileRuleNames.CallsAndMessages);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/callsSMSRules`,
          })
        );
        gaEvent('rules-list', 'click', 'calls-sms');
        break;
      case RULE_PANIC_BUTTON:
        trackRuleClick(ProfileRuleNames.PanicButton);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/panicButtonRules`,
          })
        );
        break;
      case RULE_YOUTUBE:
        trackRuleClick(ProfileRuleNames.Youtube);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/youtube-rules`,
          })
        );
        break;
      case RULE_ROUTINES:
        trackRuleClick(ProfileRuleNames.Routines);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/routines`,
          })
        );
        break;
      case MENU_OPTION_ACTIVITY_TIMELINE:
        track(Events.ClickedActivityTimelineLink);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/activity-timeline`,
          })
        );
        break;
      case MENU_OPTION_RULES:
        track(Events.BtnClickRules);
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules`,
          })
        );
        break;
      case MENU_OPTION_STUDENT_SUMMARY:
        trackButtonClicked(
          GenericPageNames.LeftMenu,
          ButtonNames.StudentSummaryNavigation,
          { profile_id: profileId }
        );
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/school/summary`,
          })
        );
        break;
      case MENU_OPTION_STUDENT_ACTIVITY_TIMELINE:
        trackButtonClicked(
          GenericPageNames.LeftMenu,
          ButtonNames.StudentTimelineNavigation,
          { profile_id: profileId }
        );
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/school/timeline`,
          })
        );
        break;
      default:
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/modal/GenericFeatureUpgradeModal`,
          })
        );
        break;
    }
  };

export const dispatchModifyProfileRules =
  (
    profileId: string,
    webFilterModifier: (state: State, profileId: string) => ProfileRulesRecord
  ) =>
  (dispatch, getState) =>
    Promise.resolve(
      // applies generic function on web filters and returns profileRules
      webFilterModifier(getState(), profileId)
    )
      .then(profileRules =>
        dispatch(delayedModifyProfileRules([profileRules, profileId], 0))
      )
      .catch(
        tapReject(error => {
          dispatch(requestModifyProfileRulesError());
          dispatch(reverseProfileRulesUpdate());
          dispatch(showErrorToast(error));
        })
      );

export const dispatchModifyVideoRules =
  (
    profileId: string,
    source: VideoSources,
    webFilterModifier: (state: State, profileId: string) => ProfileRulesRecord
  ) =>
  (dispatch, getState) =>
    Promise.resolve(
      // applies generic function on web filters and returns profileRules
      webFilterModifier(getState(), profileId)
    )
      .then(profileRules => {
        return dispatch(
          delayedModifyProfileVideoRules([profileRules, profileId, source], 0)
        );
      })
      .catch(
        tapReject(error => {
          dispatch(requestModifyProfileRulesError());
          dispatch(reverseProfileRulesUpdate());
          dispatch(showErrorToast(error));
        })
      );

export const navigateToDailyTimeLimit = ({
  profileId,
  appKey,
  isMobile,
}: {
  profileId: string;
  isMobile: boolean;
  appKey: string;
}): RouterAction => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: isMobile
      ? `profiles/${profileId}/rules/appRules/${appKey}`
      : `profiles/${profileId}/rules/appRules/modal/appDailyTimeLimit/${appKey}`,
  });
};

export const navigateToProfileDashboard = (profileId: string): RouterAction => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `profiles/${profileId}`,
  });
};
