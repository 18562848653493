import { connect } from 'react-redux';
import {
  openSchoolSummaryInfoModalFor,
  openSeeAllCategories,
} from '../../actions/SchoolActions';
import StudentCategoriesCardWithObserver from '../../components/Schools/StudentCategoriesCardWithObserver';
import { StudentSummaryCardMaxItems } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import {
  getStudentSummaryCategories,
  getSummaryFetchCategoriesError,
  isFetchingCategories,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';
import { loadStudentMostUsedCategoriesActivity } from '../../ducks/studentSummary';
import { getProfile } from '../../selectors/profileRaw';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { createMySchoolEmailHref } from '../../helpers/email';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { t } from '../../lib/i18n';

const mapStateToProps = (state: State, props) => ({
  categoriesActivity: getStudentSummaryCategories(state),
  maxCategoriesActivityItems:
    props.maxCategoriesActivityItems || StudentSummaryCardMaxItems.categories,
  single: props.single || false,
  errorOnLoading: getSummaryFetchCategoriesError(state),
  isFeatureDisabled:
    getSummaryFetchCategoriesError(state) ===
    StudentSummaryFetchError.FeatureDisabled,
  isLoadingCategories: isFetchingCategories(state),
  profileName: getProfile(state, props.profileId)?.name || '',
});

const mapDispatchToProps = (dispatch: Dispatch, { profileId }) => ({
  onClickInfo: () => {
    dispatch(openSchoolSummaryInfoModalFor('MostUsedCategories'));
  },
  onClickSeeAll: () => dispatch(openSeeAllCategories()),
  loadInfo: () =>
    dispatch(loadStudentMostUsedCategoriesActivity({ profileId })),
  onClickContactUs: (event: Event): void => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.ContactSupport
    );
    const navigate = getMultiPlatformNavigation(event);

    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(t('Error School dashboard activity')),
    });
  },
});

const StudentCategoriesCardWithObserverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentCategoriesCardWithObserver);

export type StudentCategoriesCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentCategoriesCardWithObserverContainer;
