import * as React from 'react';
import ft from '../ft';
import { getDefaultLocale, translate as coreTranslate } from './core';
import { replaceVars } from '../../helpers/string';
import { captureException } from '../../helpers/sentry';

let getLocaleSelector;
let getState;
/**
 * This module causes circular references by importing the store and "selectors"
 * modules. Since the export style if this module was "overwriting module.exports",
 * this caused problems because node.js' "require" system exports an empty object
 * first, to solve circular dependencies.
 * To solve this problem (of "t()" being undefined), the dependencies are cached
 * manually here, and the "require()" calls are delayed until "t()" is actually
 * called.
 */
export const getLocale = () => {
  /**
   * We have to wrap this function in a try catch block because there have been
   * some instances where getState function is executed while some other reducer
   * is updating the state.
   * In case of exception, we fallback to the default Locale and report it to
   * sentry as a tagged log message.
   */
  try {
    getLocaleSelector =
      getLocaleSelector || require('../../selectors').getLocale; // eslint-disable-line
    getState = getState || require('../../store').default.getState; // eslint-disable-line
    return getLocaleSelector(getState()) || getDefaultLocale();
  } catch (error) {
    try {
      // Attempt to send the error to Sentry
      captureException(error, {
        level: 'log',
        tags: [{ getLocaleException: true }],
      });
    } catch (sentryErr) {
      // Handle errors that occur during Sentry error reporting
      // eslint-disable-next-line no-console
      console.error(sentryErr);
    }

    // Return the default locale or handle it according to your needs
    return getDefaultLocale();
  }
};

const MISSING_STRING_PREFIX = '⚠️';

const transformations = {
  sup: content => React.createElement('sup', {}, content),
};

// locale ID is defined in the po file header (not the file name)

export const transformTags = (str, vars) => {
  const transformationsExp = Object.keys(transformations).join('|');
  const formatExp = new RegExp(
    `{(\\d|${transformationsExp})}(.)*?{/(\\d|${transformationsExp})}`,
    'g'
  );
  const matchResult = formatExp.exec(str);
  if (matchResult !== null) {
    // eslint-disable-line no-cond-assign
    const fullMatch = matchResult[0];
    const openTag = matchResult[1];
    const closeTag = matchResult[3];

    if (openTag !== closeTag) {
      throw new Error(`Malformed translation expression: ${str}`);
    }

    const tag = openTag;
    const index = parseInt(tag, 10);
    const contentMatch = str.match(
      new RegExp(`\\{${tag}}(.*){\/${tag}}`) // eslint-disable-line no-useless-escape
    )[1];

    let replacement;
    if (transformations[tag]) {
      replacement = transformations[tag](contentMatch);
    } else if (typeof vars[index] === 'function') {
      replacement = vars[index](contentMatch);
    } else {
      throw new Error(
        `Transform function tag ${tag} does not exist for "${str}"`
      );
    }

    const component =
      typeof replacement === 'object'
        ? React.cloneElement(replacement, { key: `t_${tag}` }) // inject "key" prop or React will complain all over
        : replacement;

    const parts = str.split(fullMatch);

    // http://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
    return [].concat.apply(
      [],
      [transformTags(parts[0], vars), component, transformTags(parts[1], vars)]
    );
  }
  return str;
};

export const translate = (str, locale) => {
  const translation = coreTranslate(str, locale);
  if (translation === str && ft.active('show_missing_translations')) {
    // show prefix to spot missing translations
    return [MISSING_STRING_PREFIX, str].join(' ');
  }
  return translation;
};

export const t = (str, params = {}) => {
  const locale = getLocale();
  // if you add more translations to the glossary, modify the gettext script
  // to append them to template.pot file
  const glossary = {
    shortName: translate('Qustodio', locale),
    fullAppName: translate("Qustodio Parent's App", locale),
    portalName: translate('Family Portal', locale),
  };
  return transformTags(
    replaceVars(params)(replaceVars(glossary)(translate(str, locale))),
    params
  );
};

export const tt = (str, params = {}) => {
  const locale = getLocale();

  return transformTags(replaceVars(params)(translate(str, locale)), params);
};
