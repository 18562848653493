import { createSelector } from 'reselect';
import * as R from 'ramda';

import State from '../store/state';
import { getParentDeviceMinInactiveDays } from './parentDevices';
import { isBrowserPlatform } from '../helpers';
import { isIosOrAndroid } from '../helpers/userAgent';
import { AppNameWithPlatform } from '../records/profileRules';
import { getPersistentStorageItem } from './helpers';
import { parseISODateString } from '../helpers/dates';
import {
  PaymentProvider,
  PAYMENT_PROVIDER_DISABLE,
  PAYMENT_PROVIDER_CHARGEBEE,
  StandaloneFlow,
  BuildPlatform,
} from '../constants';
import { isPremium } from '../records/license';
import { hasChildsAndTrackableDevices } from './profile';
import { getLicense } from './license';
import {
  hasVisitedFamilyLocator,
  getAccount,
  getAccountOptionWebOnboardingFinished,
} from './account';
import { getFeatureFlag } from './featureFlags';
import { getBuildPlatform } from '../helpers/globals';
import { isSlaveAccount } from '../records/account';

export const getAppReloadStatus = (state: State) =>
  state.get('app').get('isReloading');

export const getAppIcon = (state: State, appName: AppNameWithPlatform) =>
  state.get('records').get('appIcons').get(appName);

// Handle history error https://github.com/ReactTraining/history/issues/427
export const isLoadedRoot = (state: State) =>
  state.get('app').get('loadedRoot');

export const isOpenedFromBackground = (state: State) =>
  state.get('app').get('openedFromBackground');

export const isFloatingActionMenuOpen = (state: State) =>
  state.get('app').get('isFloatingActionMenuOpen');

export const getIsKeyboardVisibleAtPlaces = (state: State): boolean =>
  state.get('app').get('isKeyboardVisibleAtPlaces');

export const isFreshlyLoggedOut = (state: State) =>
  state.get('app').get('isFreshlyLoggedOut');

export const isLoggingOut = (state: State) =>
  state.get('app').get('isLoggingOut');

export const isRolloutUserConfigSent = (state: State): boolean =>
  state.get('app').get('rolloutUserConfigSent');

export const getAccessType = (state: State) =>
  state.get('app').get('accessType');

export const isPurchaseFlow = (state: State) =>
  R.any(R.equals(state.get('app').get('standaloneFlow')))([
    StandaloneFlow.Purchase,
    StandaloneFlow.PurchaseAtlimit,
  ]);

export const getUserClosedSmartAppBanner = (state: State) =>
  getPersistentStorageItem(state, 'userClosedSmartAppBanner') === 'true';

export const isSmartAppBannerVisible = createSelector(
  getUserClosedSmartAppBanner,
  getParentDeviceMinInactiveDays,
  (userClosedSmartAppBanner, deviceMinInactiveDays) => {
    return (
      // User is using a browser
      isBrowserPlatform() &&
      // User is using an android or ios device
      isIosOrAndroid() &&
      // User haven't close the the SmartAppBanner during the session (sessions means while logged in)
      !userClosedSmartAppBanner &&
      // User has never or atleast been inactive 30 days using the app through a mobile device
      deviceMinInactiveDays >= 30
    );
  }
);

export const isAuthenticated = (state: State) =>
  !!(
    getPersistentStorageItem(state, 'accessToken') &&
    getPersistentStorageItem(state, 'refreshToken')
  );

export const getAttributionLastSource = (state: State) =>
  state.get('attribution').get('lastSource').toObject();

export const getLastInteractionTs = (state: State) =>
  parseISODateString(state.get('persistentStorage').get('lastInteractionTs'));

export const getPaymentProvider = (state: State): PaymentProvider =>
  getPersistentStorageItem(state, 'paymentProvider') as PaymentProvider;

export const canPurchase = (state: State): boolean => {
  const paymentProvider = getPaymentProvider(state);
  const license = getLicense(state);
  // We don't allow upsells in PASA and PASI
  if (
    isPremium(license.type) &&
    paymentProvider !== PAYMENT_PROVIDER_CHARGEBEE
  ) {
    return false;
  }

  if (paymentProvider === PAYMENT_PROVIDER_DISABLE) {
    return false;
  }

  return true;
};

export const didErrorHappenedWhileAutologin = (state: State) =>
  state.get('autologin').get('error');

export const isFamilyLocatorAccentActive = (state: State) => {
  return (
    getFeatureFlag(state, 'familyLocator') &&
    !isBrowserPlatform() &&
    isPremium(getLicense(state).type) &&
    hasChildsAndTrackableDevices(state) &&
    !hasVisitedFamilyLocator(state)
  );
};

export const isNavigatingToPurchaseProduct = (state: State) =>
  state.get('app').get('isNavigatingToPurchaseProduct');

export const isInitialDataLoaded = (state: State) =>
  state.get('app').get('isDataFetched');

export const shouldRedirectToOnBoarding = createSelector(
  getAccount,
  getAccountOptionWebOnboardingFinished,
  isInitialDataLoaded,
  (account, isWebOnboardingFinished, dataLoaded) =>
    dataLoaded &&
    getBuildPlatform() === BuildPlatform.browser &&
    !isSlaveAccount(account) &&
    !isWebOnboardingFinished &&
    (account.profilesCount === 0 ||
      (account.profilesCount === 1 && account.devicesCount <= 1))
);

export const getSsoStatusFailed = (state: State) =>
  state.get('app').get('ssoStatusFailed');

export const isConfirmedStandaloneApp = (state: State): boolean =>
  getPersistentStorageItem(state, 'parentAppStandaloneConfirmed') === 'true';

export const isRedirectingToSSOLogin = (state: State) =>
  state.get('app').get('isRedirectingToSSOLogin');

export const getLocale = (state: State) => state.get('app').get('locale');
