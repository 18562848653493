import * as React from 'react';
import type { PieChartDataType } from './types';
import { getColor, ParColor, Color } from '../../helpers/theme';
import { GlobalType } from 'styleguide-react';

export interface PieChartProps {
  children?: React.ReactNode;
  totalMinutes: number;
  segments: Array<PieChartDataType>;
  size?: number;
  width?: number;
  borderWidth?: number;
  borderColor?: Color;
  remainingSegmentColor?: Color;
  onConsumedPointerEventHandler?: (
    action: 'in' | 'out'
  ) => (e: React.PointerEvent<Element>, data?: PieChartDataType) => void;
  onRemainingPointerEventHandler?: (
    action: 'in' | 'out'
  ) => (e: React.PointerEvent<Element>, data?: PieChartDataType) => void;
}

const PaiChart = ({
  children,
  totalMinutes,
  segments,
  size = 200,
  width = 22,
  borderWidth = 6,
  borderColor = GlobalType.white,
  remainingSegmentColor = ParColor.secondaryLighter,
  onConsumedPointerEventHandler,
  onRemainingPointerEventHandler,
}: PieChartProps) => {
  const radius = (size - Math.max(width, borderWidth)) / 2;
  const halfSize = size / 2;
  const strokeWidth = width;
  let startAngle = -90;

  return (
    <div
      className="par-screen-time-pie-chart"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
      }}
    >
      <svg width={size} height={size}>
        <path
          d={`
        M ${halfSize} ${halfSize}
        m -${radius}, 0
        a ${radius},${radius} 0 1,0 ${radius * 2},0
        a ${radius},${radius} 0 1,0 -${radius * 2},0
      `}
          fill="none"
          stroke={getColor(remainingSegmentColor)}
          strokeWidth={strokeWidth}
          onPointerEnter={onRemainingPointerEventHandler?.('in')}
          onPointerLeave={onRemainingPointerEventHandler?.('out')}
        />
        {segments
          .map(item => {
            const position = halfSize;
            const angle = (item.minutes / totalMinutes) * 360 - 0.001;
            const endAngle = startAngle + angle;
            const startAngleRadians = (startAngle * Math.PI) / 180;
            const endAngleRadians = (endAngle * Math.PI) / 180;
            const startX = Math.cos(startAngleRadians) * radius + position;
            const startY = Math.sin(startAngleRadians) * radius + position;
            const endX = Math.cos(endAngleRadians) * radius + position;
            const endY = Math.sin(endAngleRadians) * radius + position;
            const largeArcFlag = angle <= 180 ? '0' : '1';
            startAngle = endAngle;

            return (
              <React.Fragment key={item.uid}>
                <path
                  key={`${item.uid}-outer`}
                  d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
                  fill="none"
                  stroke={getColor(borderColor)}
                  strokeWidth={strokeWidth + borderWidth}
                  strokeLinecap="round"
                />
                <path
                  key={`${item.uid}-inner`}
                  d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
                  fill="none"
                  stroke={getColor(item.color)}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                  onPointerEnter={e =>
                    onConsumedPointerEventHandler?.('in')(e, item)
                  }
                  onPointerLeave={e =>
                    onConsumedPointerEventHandler?.('out')(e, item)
                  }
                />
              </React.Fragment>
            );
          })
          .reverse()}
      </svg>
      <div className="par-screen-time-pie-chart__content">{children}</div>
    </div>
  );
};

export default PaiChart;
