import * as React from 'react';
import { OrderedSet } from 'immutable';
import {
  FlexLayout,
  EmptyPlaceholder,
  StyledHeader,
  Layout,
  List,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import FamilyLocatorItem from './FamilyLocatorItem';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

const FamilyLocator: React.FunctionComponent<{
  profiles: OrderedSet<ProfileRecord>;
  hasFetchedProfileRules: boolean;
  showProfiles: boolean;
}> = ({ profiles, hasFetchedProfileRules, showProfiles }) => {
  const renderEmptyPlaceholder = () => (
    <FlexLayout
      mainaxis="column"
      crossaxisAlignment="center"
      mainaxisAlignment="center"
      flexGrow="1"
    >
      <Layout marginBottom="24">
        <StyledHeader type="h3">{t('Family Locator')}</StyledHeader>
      </Layout>

      <Layout marginLeft="8" marginRight="8">
        <EmptyPlaceholder
          text={t(
            'Here you will see the latest location of any mobile devices you are protecting'
          )}
        />
      </Layout>
    </FlexLayout>
  );

  return (
    <FlexLayout
      mainaxis="column"
      padding="16"
      paddingTop="24"
      paddingBottom="24"
      matchParentHeight
      flexShrink="0"
    >
      {showProfiles ? (
        <FlexLayout mainaxis="column" flexGrow="1">
          <List
            listItemPaddingBottom="8"
            listItemMarginBottom="24"
            allowCustomListItemStyle={FamilyLocatorItem}
          >
            {profiles.map(profile => (
              <FamilyLocatorItem
                key={profile.id}
                profile={profile}
                hasFetchedProfileRules={hasFetchedProfileRules}
              />
            ))}
          </List>
        </FlexLayout>
      ) : (
        renderEmptyPlaceholder()
      )}
    </FlexLayout>
  );
};

export default FamilyLocator;
