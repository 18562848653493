import * as React from 'react';
import { FlexLayout, IconType, Layout, StyledHeader } from 'styleguide-react';
import type { DashboardContainerProps } from '../../containers/Dashboard/ProfileDashboardContainer';
import ProfileDashboardTopCardsContainer from '../../containers/Dashboard/DashboardTopCards/ProfileDashboardTopCards';
import { t } from '../../lib/i18n';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import ScrollManager from '../Layout/hooks/useKeepScroll/ScrollManager';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import PullRefresh from '../PullRefresh/PullRefresh';
import RenderWhen, {
  hasScreenSize,
  ScreenSize,
} from '../RenderWhen/RenderWhen';
import ProfileDashboardWidgetsFamilyContainer from '../../containers/Dashboard/DashboardWidgets/ProfileDashboardWidgetsFamilyContainer';
import ProfileDashboardWidgetsSchoolContainer from '../../containers/Dashboard/DashboardWidgets/ProfileDashboardWidgetsSchoolContainer';
import SpeedDial from '../SpeedDial/SpeedDial';
import SmartDevicesStatusContainer from '../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';
import UnifiedNowCardContainer from '../../containers/NowCard/UnifiedNowCard/UnifiedNowCardContainer';

const scrollKey = ScrollManager.getInstance().register('ProfileDashboard');

const ProfileDashboard = ({
  profileId,
  isFetchingNewEvents,
  isSchoolOnlyProfile,
  handleRefresh,
  onScroll,
  onClickEdit,
  onClickGoBack,
  onChangeDateRange,
  onToggleMenu,
  isFetching,
  isMenuOpen,
  activeDateRange,
}: DashboardContainerProps) => {
  const actionsSpeedDial = [
    {
      icon: IconType.mobile,
      label: t('Add a device'),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
    },
  ];

  if (!isSchoolOnlyProfile) {
    actionsSpeedDial.push({
      icon: IconType.settings,
      label: t('Set rules'),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
    });
  }

  return (
    <PageLayout
      isProfileEditable
      configuration={[
        { screenSize: ScreenSize.Desktop, columns: 1, mainReducedSize: true },
        {
          screenSize: ScreenSize.MobileOrTablet,
          showProfile: true,
          title: ' ',
          mainColumnBackgroundColor: 'gray-ligther',
          removePaddings: true,
        },
        {
          screenSize: ScreenSize.All,
          pullToRefresh: (
            <PullRefresh
              isFetching={isFetchingNewEvents}
              onRefresh={handleRefresh}
              onScroll={onScroll}
            />
          ),
        },
        {
          screenSize: ScreenSize.MobileOrTablet,
          keepScrollKey: scrollKey,
        },
      ]}
      actions={[
        {
          actionName: 'edit',
          onClick: onClickEdit,
          screenSize: ScreenSize.MobileOrTablet,
        },
        {
          actionName: 'back',
          onClick: onClickGoBack,
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      {!isFetching && (
        <React.Fragment>
          <PageSectionLayout>
            <Layout
              marginBottom="16"
              marginLeft={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '8'}
              marginRight={
                hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '24'
              }
            >
              <Layout marginBottom="24">
                <Layout marginBottom="16">
                  <StyledHeader type="h4" testId="studentSummaryTitle">
                    {t("What's happening now")}
                  </StyledHeader>
                </Layout>
                <div className="Profile__StatsNotification Profile__StatsNotification--visible">
                  <span>profile top notification area</span>
                </div>
                <Layout marginBottom="8">
                  <UnifiedNowCardContainer profileId={profileId} />
                </Layout>
                <FlexLayout mainaxis="row">
                  <SmartDevicesStatusContainer profileId={profileId} />
                </FlexLayout>
              </Layout>
            </Layout>
          </PageSectionLayout>
          <PageSectionLayout>
            <Layout
              marginBottom="16"
              marginLeft={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '8'}
              marginRight={
                hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '24'
              }
            >
              <FlexLayout mainaxis="column" marginLeft="8" marginRight="8">
                <FlexLayout mainaxis="row" mainaxisAlignment="space-between">
                  <StyledHeader type="h4" testId="studentSummaryTitle">
                    {t('Activity summary')}
                  </StyledHeader>
                  <DateRangeSelector
                    activeRange={activeDateRange}
                    onChangeDateRange={onChangeDateRange}
                  />
                </FlexLayout>
              </FlexLayout>
              <ProfileDashboardTopCardsContainer profileId={profileId} />
              {isSchoolOnlyProfile ? (
                <ProfileDashboardWidgetsSchoolContainer profileId={profileId} />
              ) : (
                <ProfileDashboardWidgetsFamilyContainer profileId={profileId} />
              )}
            </Layout>
          </PageSectionLayout>
        </React.Fragment>
      )}

      <PageSectionLayout placeholder="after-pullrefresh">
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <SpeedDial
            isOpen={isMenuOpen}
            actions={actionsSpeedDial}
            onToggleMenu={onToggleMenu}
          />
        </RenderWhen>
      </PageSectionLayout>
    </PageLayout>
  );
};

export default ProfileDashboard;
