import { t } from '../lib/i18n';
import { showToast, TOAST_ICON_TICK, TOAST_ICON_WARNING } from '../ducks/toast';
import { fetchPlaces, removePlace } from '../ducks/places';
import { deletePlaceRecord } from '../ducks/records';
import { getPlace } from '../selectors';

export const remove = placeId => (dispatch, getState) => {
  const place = getPlace(getState(), placeId);
  if (!place) {
    return;
  }

  return dispatch(removePlace(place))
    .then(() =>
      dispatch(
        showToast(
          t('{{placeName}} has been removed.', {
            placeName: place.name,
          }),
          TOAST_ICON_TICK
        )
      )
    )
    .then(() => dispatch(fetchPlaces()))
    .then(() => dispatch(deletePlaceRecord(place.uid)))
    .catch(() => {
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
};
