import * as React from 'react';
import { connect } from 'react-redux';
import { ModalStyledText } from 'styleguide-react';
import SchoolSummaryInfoModal from '../../components/Modal/SchoolSummaryInfoModal';
import { goBackIfHistory } from '../../ducks/routing';
import State, { Dispatch } from '../../store/state';
import { getQueryParam } from '../../selectors';
import { StudentSummaryInfoTypes } from '../../components/Schools/types/StudentSummaryInfoTypes.types';
import { t } from '../../lib/i18n';

const getTitle = (type: StudentSummaryInfoTypes): string => {
  const TITLE: { [type in StudentSummaryInfoTypes]: string } = {
    TotalDailyUsage: t('Total daily usage'),
    MostUsedAppsAndWebsites: t('Most used apps & websites'),
    MostUsedCategories: t('Most used categories'),
  };

  return TITLE[type];
};

const getText = (type: StudentSummaryInfoTypes): React.ReactElement => {
  const TEXT = {
    TotalDailyUsage: (
      <React.Fragment>
        <ModalStyledText>
          {t(
            'This graph shows your child’s daily usage on their school devices so you can easily compare each day.'
          )}
        </ModalStyledText>
        <ModalStyledText>
          {t('Usage is counted by network hits on apps and websites.')}
        </ModalStyledText>
      </React.Fragment>
    ),
    MostUsedAppsAndWebsites: (
      <React.Fragment>
        <ModalStyledText>
          {t(
            'Here you can see the apps and websites your child has visited, with a quick view of their safety ratings when available.'
          )}
        </ModalStyledText>
        <ModalStyledText>
          {t(
            'You can also click on each one for more detailed safety insights.'
          )}
        </ModalStyledText>
      </React.Fragment>
    ),
    MostUsedCategories: (
      <React.Fragment>
        <ModalStyledText>
          {t(
            'Here you can view your child’s usage divided into categories, to easily see how they spend their time online.'
          )}
        </ModalStyledText>
        <ModalStyledText>
          {t('Usage is counted by network hits on apps and websites.')}
        </ModalStyledText>
      </React.Fragment>
    ),
  };

  return TEXT[type];
};

const getCloseText = (type: StudentSummaryInfoTypes): string => {
  const CLOSE_TEXT = {
    TotalDailyUsage: t('Ok'),
    MostUsedAppsAndWebsites: t('Ok'),
    MostUsedCategories: t('Ok'),
  };

  return CLOSE_TEXT[type];
};

const mapStateToProps = (state: State) => {
  const type = getQueryParam(state, 'type') as StudentSummaryInfoTypes;

  return {
    title: getTitle(type),
    content: getText(type),
    closeText: getCloseText(type),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(goBackIfHistory()),
});

const SchoolSummaryInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolSummaryInfoModal);

export type SchoolSummaryInfoModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default SchoolSummaryInfoModalContainer;
