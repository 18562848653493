import * as React from 'react';
import { Typography } from 'styleguide-react';
import { classNames } from '../../../../helpers';

interface UnifiedNowCardTitleProps {
  title: string;
  className?: string;
}

const UnifiedNowCardTitle = ({
  title,
  className,
}: UnifiedNowCardTitleProps) => {
  return (
    <Typography
      type="h5"
      weight="semibold"
      className={classNames('par-unified-now-card__title', className)}
    >
      {title}
    </Typography>
  );
};

export default UnifiedNowCardTitle;
