import * as React from 'react';
import {
  Button,
  ButtonSize,
  Icon,
  IconFamily,
  IconType,
  Modal,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

const YoutubeAgreementWebBlock: React.FunctionComponent<{
  profileName: string;
  onClickClose: () => void;
  onClickConfirm: () => void;
}> = ({ profileName, onClickClose, onClickConfirm }) => (
  <Modal
    size="small"
    title={t('Web access to YouTube.com')}
    header={{
      icon: <Icon type={IconType.youtube} family={IconFamily.brands} />,
    }}
    buttons={[
      <Button
        key="button"
        onClick={onClickConfirm}
        size={ButtonSize.medium}
        block
      >
        {t('Confirm')}
      </Button>,
    ]}
    onClickClose={onClickClose}
  >
    <Typography type="body1" marginTop="16">
      {t(
        "Are you sure you want to allow access to YouTube.com from all of {1}{{name}}{/1}'s devices?",
        {
          1: str => <strong>{str}</strong>,
          name: profileName,
        }
      )}
    </Typography>
  </Modal>
);

export default YoutubeAgreementWebBlock;
