import { t } from '../../lib/i18n';
import { IconFamily, IconType } from 'styleguide-react';
import {
  RULE_CALLS_AND_SMS,
  RULE_DAILY_TIME_LIMITS,
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_PANIC_BUTTON,
  RULE_RESTRICTED_TIMES,
  RULE_ROUTINES,
  RULE_WEB_FILTERING,
  RULE_YOUTUBE,
} from '../../constants';

export const getTitle = (rule: string) =>
  ({
    [RULE_WEB_FILTERING]: t('Web Filtering'),
    [RULE_GAMES_AND_APPS]: t('Games & Apps'),
    [RULE_DAILY_TIME_LIMITS]: t('Daily Time Limits'),
    [RULE_RESTRICTED_TIMES]: t('Restricted Times'),
    [RULE_LOCATION]: t('Location'),
    [RULE_CALLS_AND_SMS]: t('Calls & messages'),
    [RULE_PANIC_BUTTON]: t('Panic Button'),
    [RULE_YOUTUBE]: t('YouTube Monitoring'),
    [RULE_ROUTINES]: t('Routines'),
  }[rule]);

export const getSubTitle = (rule: string) =>
  ({
    [RULE_WEB_FILTERING]: t('Allow and block websites and content categories'),
    [RULE_GAMES_AND_APPS]: t('Allow, block, and set time limits for apps'),
    [RULE_DAILY_TIME_LIMITS]: t('Set daily screen time allowances'),
    [RULE_RESTRICTED_TIMES]: t('Schedule screen-free hours throughout the day'),
    [RULE_LOCATION]: t('Monitor where your child is and has been'),
    [RULE_CALLS_AND_SMS]: t('See call details and message content'),
    [RULE_PANIC_BUTTON]: t(
      'Allow your child to send alerts to trusted contacts'
    ),
    [RULE_YOUTUBE]: t('Allow, block, and limit access to YouTube'),
    [RULE_ROUTINES]: t('Schedule custom rules for specific moments of the day'),
  }[rule]);

export const getIcon = (rule: string) =>
  ({
    [RULE_WEB_FILTERING]: IconType.ban,
    [RULE_GAMES_AND_APPS]: IconType.icons,
    [RULE_DAILY_TIME_LIMITS]: IconType.hourglassHalf,
    [RULE_RESTRICTED_TIMES]: IconType.lockAlt,
    [RULE_LOCATION]: IconType.mapMarkerAlt,
    [RULE_CALLS_AND_SMS]: IconType.phonePlus,
    [RULE_PANIC_BUTTON]: IconType.sirenOn,
    [RULE_YOUTUBE]: `${IconType.youtube} ${IconFamily.brands}`,
    [RULE_ROUTINES]: IconType.calendarAlt,
  }[rule]);
