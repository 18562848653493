import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { SetContentFilterRulesModalProps } from '../../../containers/Routines/Modal/SetContentFilterRulesModalContainer';

const SetContentFilterRulesModal = ({
  type,
  onClickOk,
}: SetContentFilterRulesModalProps) => {
  if (!type) {
    return null;
  }
  const isTypeApp = type === 'APP';

  const MODAL_TEST_ID = 'set-content-filter-rules-modal';

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-set-content-filter-rules-modal"
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={
        isTypeApp ? t('Choose app rules') : t('Choose website category rules')
      }
      width={modalDimensions.regular.width}
      onClickClose={onClickOk}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-ok`}
          key="button1"
          onClick={onClickOk}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Ok')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {isTypeApp
            ? t('Use the dropdown to choose app rules and then add exceptions.')
            : t(
                'Use the dropdown to choose website category rules and then add exceptions.'
              )}
        </ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default SetContentFilterRulesModal;
