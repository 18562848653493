import { Record } from 'immutable';
import { SafeNetworksSettings } from '../records/safeNetworksSettings/types/safeNetworkSetting';
import State from '../store/state';
import flags from '../sideEffects/flags';

export const getSafeNetworksSettings = (state: State) =>
  state.get('records').get('safeNetworkSettings');

export const getProfileSafeNetworkSettings = (
  state: State,
  profileUid: string
) =>
  state.get('records').get('safeNetworkSettings').get(profileUid) as
    | Record<SafeNetworksSettings>
    | undefined;

export const getIsAccountSafeNetworkSettingsLoading = (state: State) =>
  state.get('safeNetworksSettings').get('isFetching');

export const getAccountSafeNetworkSettingsHasError = (state: State) =>
  state.get('safeNetworksSettings').get('hasError');

export const shouldSeeCampaign = (
  state: State,
  profileUid: string
): boolean => {
  const settings = getProfileSafeNetworkSettings(state, profileUid);

  return (
    flags.safeNetworkSettings.isEnabled() &&
    Boolean(settings?.isEligibleForOptInCampaign) &&
    !settings.isCampaignDismissed
  );
};
