import * as React from 'react';
import { classNames } from '../../helpers';

interface FadedScrollProps {
  children: JSX.Element;
  fadeHeight?: number;
  testId?: string;
  className?: string;
}

const FadedScroll = ({
  fadeHeight = 50,
  children,
  testId,
  className,
}: FadedScrollProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const childRef = React.useRef<HTMLDivElement>(null);
  const topFadeRef = React.useRef<HTMLDivElement>(null);
  const bottomFadeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const onScroll = (event: React.SyntheticEvent) => {
      if (
        !containerRef.current ||
        !childRef.current ||
        !topFadeRef.current ||
        !bottomFadeRef.current
      ) {
        return undefined;
      }

      topFadeRef.current.style.opacity =
        event.currentTarget.scrollTop === 0 ? '0' : '1';

      bottomFadeRef.current.style.opacity =
        event.currentTarget.scrollTop + event.currentTarget.clientHeight ===
        childRef.current.offsetHeight
          ? '0'
          : '1';
    };

    const scrollInit = () => {
      if (!containerRef.current || !childRef.current || !bottomFadeRef.current)
        return;
      if (containerRef.current.offsetHeight < childRef.current.offsetHeight)
        bottomFadeRef.current.style.opacity = '1';
    };

    scrollInit();

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', onScroll as never);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', onScroll as never);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      data-testid={testId}
      className={classNames('par-faded-scroll', className)}
    >
      <div
        ref={topFadeRef}
        className={classNames(
          'par-faded-scroll__fade',
          'par-faded-scroll__fade--top'
        )}
        style={{ height: fadeHeight }}
      />
      {React.createElement(
        'div',
        {
          ref: childRef,
          className: 'par-faded-scroll__children',
          style: { marginTop: -fadeHeight, marginBottom: -fadeHeight },
        },
        children
      )}
      <div
        ref={bottomFadeRef}
        className={classNames(
          'par-faded-scroll__fade',
          'par-faded-scroll__fade--bottom'
        )}
        style={{ height: fadeHeight }}
      />
    </div>
  );
};

FadedScroll.displayName = 'FadedScroll';

export default FadedScroll;
