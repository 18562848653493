import * as React from 'react';
import { t } from '../../lib/i18n';
import ft from '../../lib/ft';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const WelcomeModal: React.FunctionComponent<{
  pText: string;
  footerButtonText: string;
  footerClass: string;
  onClickLink: (link?: string) => void;
}> = ({ pText, footerButtonText, onClickLink }) => (
  <Modal
    width={modalDimensions.regular.width}
    onClickClose={onClickLink}
    header={{
      icon: <Icon type={IconType.star} />,
    }}
    title={t('Welcome to Premium!')}
    buttons={[
      <Button key="button" size={ButtonSize.medium} block onClick={onClickLink}>
        {footerButtonText}
      </Button>,
    ]}
  >
    <ModalStyledText textAlign="center">{pText}</ModalStyledText>
    <ModalStyledText textAlign="center">
      {ft.active('show_support_link') ? (
        <a onClick={() => onClickLink('/upgrade/premium-features')}>
          {t('View All Premium Features')}
        </a>
      ) : null}
    </ModalStyledText>
  </Modal>
);

export default WelcomeModal;
