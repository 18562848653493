import * as React from 'react';
import {
  Bar,
  XAxis,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  Cell,
  YAxis,
  TooltipProps,
} from 'recharts';
import { List } from 'immutable';
import { pathOr, prop } from 'ramda';
import { fontFamily, axisFontSize } from '../../constants/charts';

import { t } from '../../lib/i18n';
import { isTesting } from '../../helpers/env';
import { dateFormatToDayMonth, isTodaysDate } from '../../helpers/dates';
import { classNames } from '../../helpers';
import { TotalUsageActivity } from '../../records/studentActivity/types/totalUsage.types';

interface StudentTotalUsageBarChartProps {
  data: List<TotalUsageActivity>;
  todaySelected: boolean;
  currentTime: unknown;
  timezone: unknown;
}

const StudentTotalUsageBarChart: React.FunctionComponent<
  StudentTotalUsageBarChartProps
> = ({
  data,
  todaySelected,
  currentTime,
  timezone,
}: StudentTotalUsageBarChartProps) => {
  // We need this to avoid rendering on the tests,
  // as recharts have some rendering issues in the test environment
  if (isTesting()) {
    return <div>TestStudentTotalUsageBarChart</div>;
  }

  const barWidth = 21;
  const chartData: TotalUsageActivity[] = data.toJS().slice(-7);
  const xAxisTicks = chartData.map(entry => entry.date);
  const maxHitsValue = Math.max(...chartData.map(value => value.hits));

  const xAxisTickFormatter = (date: string) =>
    isTodaysDate(currentTime, date, timezone)
      ? t('Today')
      : dateFormatToDayMonth(date);

  const isBarActive = (date: string) => {
    if (todaySelected) {
      return isTodaysDate(currentTime, date, timezone);
    }
    return true;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={300}>
      <BarChart data={chartData} barSize={barWidth} margin={{ top: 10 }}>
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          ticks={xAxisTicks}
          tick={({ payload, className, ...props }) => (
            <TickContent
              className={classNames(
                className,
                `StudentTotalUsageBarChart__xtick`,
                isBarActive(payload.value) ? 'active' : ''
              )}
              {...props}
            >
              {xAxisTickFormatter(payload.value)}
            </TickContent>
          )}
          interval={0}
        />

        <YAxis hide dataKey="hits" domain={[0, maxHitsValue]} />

        <Bar type="monotone" stackId="bar" dataKey="hits" radius={2}>
          {chartData.map(entry => (
            <Cell
              key={`cell-${entry.date}`}
              cursor="pointer"
              className={classNames(
                `StudentTotalUsageBarChart__bar`,
                isBarActive(entry.date) ? 'active' : ''
              )}
            />
          ))}
        </Bar>
        <Tooltip
          cursor={false}
          content={props => <TooltipContent {...props} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const TooltipContent = (props: TooltipProps<any, any>) => {
  const payload = pathOr(
    {},
    ['payload', 0, 'payload'],
    props
  ) as TotalUsageActivity;

  const active = prop('active', props);

  if (!active || !payload) {
    return null;
  }

  return (
    <div className="StudentTotalUsageBarChart__tooltip">
      {getTooltipVisited(payload.hits)}
    </div>
  );
};

const getTooltipVisited = (hits: number) =>
  hits === 1 ? t('1 hit') : t('{{pages}} hits', { pages: hits });

const TickContent = ({ x, y, children, ...props }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fontSize={axisFontSize}
        fontFamily={fontFamily}
        textAnchor="middle"
        {...props}
      >
        {children}
      </text>
    </g>
  );
};

export default StudentTotalUsageBarChart;
