import {
  parseQuery,
  QueryWebAutologin,
  parseQueryParams,
} from '../helpers/query';
import { getBuildPlatform } from '../helpers/globals';
import { removeItem, setItem } from '../ducks/persistentStorage';
import { getStateFromStorage, hasPersistedSSOState } from '../selectors/sso';
import {
  deserializeSsoState,
  createRedirectToSsoUrl,
  getSsoURI,
  TIMEOUT_DURATION_MILISECONDS,
  createSsoState,
  isSSOEnabled,
  getSSOMode,
  getSSOCampaign,
  getSSOSource,
  isDirectPurchase,
} from '../helpers/sso';
import {
  SsoResult,
  SsoStartMode,
  SsoParsingResponseFailures,
  BuildPlatform,
  SSO_BACK_URI,
  AuthorizationApplication,
} from '../constants';
import { BaseThunk, Dispatch } from '../store/state';
import {
  startLoadingInitialData,
  setIsRedirectingToSSOLogin,
} from '../ducks/app';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';
import { serializeInAppBrowserConfig } from '../helpers/inAppBrowser';
import { resetHistory } from '../ducks/routing';
import { isBrowserPlatform } from '../helpers';
import { isAuthenticated } from '../selectors';
import {
  storeVisitorAttribution,
  getAttributionFrom,
} from '../ducks/attribution';
import { RouteLocation } from '../types/RouteLocation.types';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { InAppBrowserEvent } from '../types/inAppBrowser';
import { ensureSetDirectPurchaseData } from '../ducks/directPurchase';

export const removeStateFromStorage = () => dispatch =>
  dispatch(removeItem('state'));

export const saveSsoStateToStorage = (ssoState: string) => dispatch =>
  dispatch(setItem('state', ssoState));

export const checkStateAndExpiration =
  (
    ssoState: string,
    currentDate: Date = new Date()
  ): BaseThunk<Promise<boolean>> =>
  (dispatch, getState) =>
    new Promise<boolean>((resolve, reject) => {
      const state = getState();

      if (!hasPersistedSSOState(state) || !ssoState) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(false);
      }

      const receivedSsoState = deserializeSsoState(ssoState);

      const persistedSsoState = deserializeSsoState(getStateFromStorage(state));

      const stateKey = Object.keys(receivedSsoState)[0];

      const persistedDataState = persistedSsoState[stateKey];

      const isValid =
        persistedDataState &&
        new Date(persistedDataState.expiresOn).getTime() >
          currentDate.getTime();

      if (isValid) {
        dispatch(removeStateFromStorage());
        resolve(isValid);
      } else {
        reject(isValid);
      }
    });

const isSsoPage = (url: string, mode: SsoStartMode): boolean => {
  const domainCapture =
    /https:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
  const expected = domainCapture.exec(getSsoURI(mode));
  const current = domainCapture.exec(url);
  return expected?.length && current?.length
    ? expected[0] === current[0]
    : false;
};

export const onSsoSuccess =
  (url: string, windowSso, mode: SsoStartMode): BaseThunk<Promise<SsoResult>> =>
  dispatch =>
    new Promise<SsoResult>((resolve, reject) => {
      const query = parseQuery(url) as QueryWebAutologin;
      if (query.authorizationCode) {
        windowSso.close();
        const authCodeClean = query.authorizationCode.replace('#', '');
        dispatch(checkStateAndExpiration(query.state))
          .then(hasValidState => {
            if (hasValidState) {
              dispatch(startLoadingInitialData());
              resolve({
                code: authCodeClean,
                state: query.state,
                isNewAccount: query.isNewAccount,
                wait: false,
              });
            } else {
              reject(SsoParsingResponseFailures.invalidState);
            }
          })
          .catch(() => {
            windowSso.close();
            reject(SsoParsingResponseFailures.invalidState);
          });
      } else if (!isSsoPage(url, mode)) {
        windowSso.close();
        reject(SsoParsingResponseFailures.invalidCode);
      } else {
        resolve({
          code: '',
          state: '',
          wait: true,
        });
      }
    });

export const onSsoFail =
  (windowSso: Window): BaseThunk<void> =>
  (dispatch: Dispatch) => {
    dispatch(finishedLoadingInitialData());
    windowSso.close();
  };

export const navigateToSsoLogin = async ({
  mode,
  ssoState,
  authorizationApplication,
  campaign,
  source,
}: {
  mode: SsoStartMode;
  ssoState: string;
  authorizationApplication?: AuthorizationApplication;
  campaign?: string;
  source?: string;
}) => {
  const navigate = getMultiPlatformNavigation();

  navigate({
    type: 'location:href',
    src: createRedirectToSsoUrl({
      mode,
      ssoState,
      authorizationApplication,
      campaign,
      source,
    }),
  });

  /**
   * Assigning something to window.location.href is synchronous
   * but the navigation to an outside page that happens due this takes some time.
   *
   * To avoid performing any additional work in this span of time, we need
   * to wait for a promise that will never fulfill.
   */

  await new Promise(() => {});
};

export const handleCloseSsoWindow = (
  windowSso: Window,
  url: string,
  stateTimeout: number
): boolean => {
  if (url.endsWith(SSO_BACK_URI)) {
    windowSso.close();
    clearTimeout(stateTimeout);
    return true;
  }
  return false;
};

const getSsoInAppBrowserConfig = (): string =>
  serializeInAppBrowserConfig({
    hidden: 'yes',
    hidespinner: 'yes',
    zoom: 'no',
    location: 'no',
    toolbar: 'no',
    transitionstyle: 'crossdissolve',
    enableViewportScale: 'no',
  });

export const redirectToSsoLogin =
  (mode: SsoStartMode, ssoState: string): BaseThunk<Promise<SsoResult>> =>
  dispatch =>
    new Promise<SsoResult>((resolve, reject) => {
      let stateTimeout;
      const _inAppBrowser = window.cordova.InAppBrowser.open(
        createRedirectToSsoUrl({ mode, ssoState }),
        getBuildPlatform() === BuildPlatform.browser ? null : '_blank',
        getSsoInAppBrowserConfig()
      );

      const loadStartHandler = data => {
        stateTimeout = setTimeout(() => {
          _inAppBrowser.close();
        }, TIMEOUT_DURATION_MILISECONDS);
        if (handleCloseSsoWindow(_inAppBrowser, data.url, stateTimeout)) return;
        // TODO: in web mode it should work in a diff way
        // it should be called when receive redirection from sso probably in Authentication during the bootApp
        dispatch(startLoadingInitialData());
        dispatch(onSsoSuccess(data.url, _inAppBrowser, mode))
          .then(result => {
            if (!result.wait) {
              clearTimeout(stateTimeout);
              resolve(result);
            }
          })
          .catch(() => {
            clearTimeout(stateTimeout);
            dispatch(onSsoFail(_inAppBrowser));
          });
      };

      const loadStopHandler = () => {
        _inAppBrowser.show();
        dispatch(finishedLoadingInitialData());
      };

      const loadErrorHandler = (data: InAppBrowserEvent) => {
        dispatch(onSsoFail(_inAppBrowser));
        clearTimeout(stateTimeout);
        reject(data);
      };

      const exitHandler = () => {
        clearTimeout(stateTimeout);
      };

      const messageHandler = params => {
        if (params.data.url) {
          const _inAppBrowserSystem = window.cordova.InAppBrowser.open(
            params.data.url,
            '_system'
          );
          /**
           * NOTE: this is needed due to a cordova bug https://issues.apache.org/jira/browse/CB-13198
           * The bug forces us to link the same handlers to the external window as to the internal
           * inapp browser.
           */
          _inAppBrowserSystem.addEventListener('loadstart', loadStartHandler);
          _inAppBrowserSystem.addEventListener('loadstop', loadStopHandler);
          _inAppBrowserSystem.addEventListener('loaderror', loadErrorHandler);
          _inAppBrowserSystem.addEventListener('message', messageHandler);
          _inAppBrowserSystem.addEventListener('exit', exitHandler);
        }
      };

      _inAppBrowser.addEventListener('loadstart', loadStartHandler);
      _inAppBrowser.addEventListener('loaderror', loadErrorHandler);
      _inAppBrowser.addEventListener('loadstop', loadStopHandler);
      _inAppBrowser.addEventListener('message', messageHandler);
      _inAppBrowser.addEventListener('exit', exitHandler);
    });

export const startWebSSOIfConditionsAreMet =
  (location: RouteLocation): BaseThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    if (isBrowserPlatform() && isSSOEnabled() && !isAuthenticated(getState())) {
      dispatch(setIsRedirectingToSSOLogin(true));

      await storeVisitorAttribution(getAttributionFrom(location.query));

      await dispatch(startWebSSOAuthFlow(location));

      return true;
    }

    return false;
  };

export const startWebSSOAuthFlow =
  (location: RouteLocation): BaseThunk<Promise<void>> =>
  async dispatch => {
    const url = window.location.href;

    const ssoState = createSsoState({
      location,
      /*
      In the case of direct purchase,
      it is necessary to save the query parameters in the SSOstate because
      if the user is not logged in,
      they will be injected into the Redux state to be consumed
      by their respective container."
      */
      ...(isDirectPurchase(location) ? { query: parseQueryParams(url) } : {}),
    });

    const mode = getSSOMode(location);
    const campaign = getSSOCampaign(url);
    const source = getSSOSource({ mode, url });

    await dispatch(saveSsoStateToStorage(ssoState));

    await navigateToSsoLogin({
      mode,
      ssoState,
      authorizationApplication: AuthorizationApplication.web,
      campaign,
      source,
    });
  };

export const resetHistoryAfterWebSSOAuthFlowEnds =
  (location: RouteLocation, ssoState: string): BaseThunk<void> =>
  async dispatch => {
    try {
      const hasValidSSOState = await dispatch(
        checkStateAndExpiration(ssoState)
      );

      if (!hasValidSSOState) {
        dispatch(resetHistory(location));
        return;
      }

      const { location: ssoStateLocation } = deserializeSsoState(
        ssoState || ''
      );

      if (isDirectPurchase(ssoStateLocation)) {
        await dispatch(ensureSetDirectPurchaseData());
      }

      dispatch(resetHistory(ssoStateLocation));
    } catch (err) {
      dispatch(resetHistory(location));
    }
  };
