import * as React from 'react';
import * as Moment from 'moment-timezone';
import {
  LocationStyleListItem,
  ClickableListItem,
  ListTitle,
  Icon,
  IconType,
  IconColor,
  InstructionBlock,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { formatDayToAPI } from '../../helpers/dates';
import { getLocaleByCountry } from '../../helpers/languages';
import { EventRecord } from '../../records/event';
import type { LocationRecord } from '../../records/location';
import { LocationTypes, getFormattedAddress } from '../../records/location';

const getLocationText = (location: LocationRecord) => {
  const locationText = {
    [LocationTypes.ENTER_PLACE]: t('Arrived to {{location}}', {
      location: location.placeName,
    }),
    [LocationTypes.LEAVE_PLACE]: t('Left {{location}}', {
      location: location.placeName,
    }),
  };
  return (
    locationText[location.type] ||
    (location.addressLines.length
      ? getFormattedAddress(location)
      : t('Address is unavailable'))
  );
};

const LocationItem: React.FunctionComponent<{
  country: string;
  event: EventRecord;
  index: number;
  timezone: string;
  isUsingAMPM: boolean;
  selectedItemIndex: number;
  selectedItemDay: string;
  clickLocation: () => any;
  selectItem: (index: number, day: string) => void;
}> = ({
  country,
  event,
  index,
  timezone,
  isUsingAMPM,
  selectedItemIndex,
  selectedItemDay,
  clickLocation,
  selectItem,
}) => {
  const eventDay = formatDayToAPI(Moment(event.time));
  const actionsVisible =
    selectedItemIndex === index && selectedItemDay === eventDay;

  return (
    <LocationStyleListItem
      key={event.location.key}
      wideLeftSubtitle={isUsingAMPM}
      upperTitle={
        <ClickableListItem onClick={() => selectItem(index, eventDay)}>
          <ListTitle>{getLocationText(event.location)}</ListTitle>
        </ClickableListItem>
      }
      leftSubtitle={Moment.tz(Moment.utc(event.time), timezone)
        .locale(getLocaleByCountry(country))
        .format('LT')}
      showActions={actionsVisible}
      actions={
        <InstructionBlock>
          <a onClick={clickLocation}>
            <Icon color={IconColor.primary} type={IconType.mapMarkedAlt} />
            &nbsp;{t('View on map')}
          </a>
        </InstructionBlock>
      }
    />
  );
};

export default LocationItem;
