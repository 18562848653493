import * as R from 'ramda';
import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';
import {
  AccountActions,
  ACTION_VIEW_DETAILS,
  ACTION_VIEW_YOUTUBE_SETTINGS,
  ACTION_VISIT,
  APPLICATION_ALLOWED,
  APPLICATION_BLOCKED,
  BuildPlatform,
  CALLSMS_RULES_ALLOW,
  CALLSMS_RULES_BLOCK,
  CALLSMS_RULES_REMOVE,
  PANIC_ACTION_SEE_CONTACTS,
  PANIC_ACTION_SEE_DETAILS,
  PANIC_ACTION_STOP,
  PANIC_RULES_ALLOW,
  PANIC_RULES_DISABLE,
  PANIC_RULES_REMOVE,
  PanicRulesContactStatus,
  VideoActions,
  WEBFILTER_ALERT,
  WEBFILTER_ALLOW,
  WEBFILTER_BLOCK,
  WEBFILTER_IGNORE,
  WEBFILTER_REMOVE,
} from '../../constants';
import { getBuildPlatform } from '../../helpers/globals';
import { t } from '../../lib/i18n';
import {
  DetailsItemRecord,
  VideoDetailsRecord,
} from '../../records/urlDetails';
import IconAlert from '../Icons/Actions/IconAlert';
import IconAllow from '../Icons/Actions/IconAllow';
import IconBlock from '../Icons/Actions/IconBlock';
import IconIgnore from '../Icons/Actions/IconIgnore';
import IconViewDetails from '../Icons/IconViewDetails';

const getViewDetailsDropdownAction = (
  onClick: (action: symbol) => any,
  action: symbol = ACTION_VIEW_DETAILS
) => [
  onClick(action),
  <IconViewDetails key="View-details" />,
  t('View details'),
  'View-details',
];

export const getDomainActions = isVisit => onClick => {
  return [
    [onClick(WEBFILTER_ALLOW), <IconAllow key="Allow" />, t('Allow'), 'Allow'],
    [
      onClick(WEBFILTER_ALERT),
      <IconAlert key="Alert-me" />,
      t('Alert me'),
      'Alert-me',
    ],
    [onClick(WEBFILTER_BLOCK), <IconBlock key="Block" />, t('Block'), 'Block'],
    [
      onClick(WEBFILTER_IGNORE),
      <IconIgnore key="Ignore" />,
      t('Ignore'),
      'Ignore',
    ],
    isVisit
      ? [
          onClick(ACTION_VISIT),
          <Icon
            key="Visit-site"
            type={IconType.arrowCircleRight}
            size={IconSize.lg}
            family={IconFamily.solid}
            color={IconColor.primary}
          />,
          t('Visit site'),
          'Visit-site',
        ]
      : getViewDetailsDropdownAction(onClick),
  ];
};

export const getWebCategoryActions = onClick => [
  [onClick(WEBFILTER_ALLOW), <IconAllow key="Allow" />, t('Allow'), 'Allow'],
  [
    onClick(WEBFILTER_ALERT),
    <IconAlert key="Alert-me" />,
    t('Alert me'),
    'Alert-me',
  ],
  [onClick(WEBFILTER_BLOCK), <IconBlock key="Block" />, t('Block'), 'Block'],
];

export const getAppActions = onClick => [
  [
    onClick(APPLICATION_ALLOWED),
    <IconAllow key="Allow" />,
    t('Allow'),
    'Allow',
  ],
  [
    onClick(APPLICATION_BLOCKED),
    <IconBlock key="Block" />,
    t('Block'),
    'Block',
  ],
];

export const getContactCallsSMSActions = onClick => [
  [
    onClick(CALLSMS_RULES_ALLOW),
    <IconAllow key="Always-allow-calls" />,
    t('Always allow calls'),
    'Always-allow-calls',
  ],
  [
    onClick(CALLSMS_RULES_BLOCK),
    <IconBlock key="Always-block-calls" />,
    t('Always block calls'),
    'Always-block-calls',
  ],
];

export const getPanicActions = (onClick, panicEvent) =>
  R.concat([
    getViewDetailsDropdownAction(onClick, PANIC_ACTION_SEE_DETAILS),
    [
      onClick(PANIC_ACTION_SEE_DETAILS),
      <Icon
        key="See-details"
        type={IconType.eye}
        size={IconSize.lg}
        family={IconFamily.solid}
        color={IconColor.primary}
      />,
      t('See details'),
      'See-details',
    ],
    [
      onClick(PANIC_ACTION_SEE_CONTACTS),
      <Icon
        key="View-trusted-contacts"
        type={IconType.eye}
        size={IconSize.lg}
        family={IconFamily.solid}
        color={IconColor.primary}
      />,
      t('View trusted contacts'),
      'View-trusted-contacts',
    ],
  ])(
    panicEvent && panicEvent.panicMode
      ? [
          [
            onClick(PANIC_ACTION_STOP),
            <Icon
              key="Turn-off-panic-mode"
              type={IconType.powerOff}
              size={IconSize.lg}
              family={IconFamily.solid}
              color={IconColor.primary}
            />,
            t('Turn off panic mode'),
            'Turn-off-panic-mode',
          ],
        ]
      : []
  );

export const getWebFilterDomainActions = (onClick: Function) => [
  [onClick(WEBFILTER_ALLOW), <IconAllow key="Allow" />, t('Allow'), 'Allow'],
  [
    onClick(WEBFILTER_ALERT),
    <IconAlert key="Alert-me" />,
    t('Alert me'),
    'Alert-me',
  ],
  [onClick(WEBFILTER_BLOCK), <IconBlock key="Block" />, t('Block'), 'Block'],
  [
    onClick(WEBFILTER_IGNORE),
    <IconIgnore key="Ignore" />,
    t('Ignore'),
    'Ignore',
  ],
  [
    onClick(WEBFILTER_REMOVE),
    <Icon
      key="Remove-exception"
      type={IconType.trashAlt}
      size={IconSize.lg}
      color={IconColor.primary}
    />,
    t('Remove exception'),
    'Remove-exception',
  ],
];

export const getWebFilterCategoryActions = (onClick: Function) => [
  [onClick(WEBFILTER_ALLOW), <IconAllow key="Allow" />, t('Allow'), 'Allow'],
  [
    onClick(WEBFILTER_ALERT),
    <IconAlert key="Alert-me" />,
    t('Alert me'),
    'Alert-me',
  ],
  [onClick(WEBFILTER_BLOCK), <IconBlock key="Block" />, t('Block'), 'Block'],
];

export const getContactActions = onClick => [
  [
    onClick(CALLSMS_RULES_ALLOW),
    <IconAllow key="Always-allow-calls" />,
    t('Always allow calls'),
    'Always-allow-calls',
  ],
  [
    onClick(CALLSMS_RULES_BLOCK),
    <IconBlock key="Always-block-calls" />,
    t('Always block calls'),
    'Always-block-calls',
  ],
  [
    onClick(CALLSMS_RULES_REMOVE),
    <Icon
      key="Remove-contact"
      type={IconType.trashAlt}
      size={IconSize.lg}
      color={IconColor.primary}
    />,
    t('Remove contact'),
    'Remove-contact',
  ],
];

export const getPanicButtonRulesActions = (selectedContactToEdit, onClick) => [
  selectedContactToEdit &&
  selectedContactToEdit.status === PanicRulesContactStatus.Pending
    ? [
        onClick(PANIC_RULES_ALLOW),
        <IconAllow key="Enable-waiting" />,
        t('Enable (waiting)'),
        'Enable-waiting',
      ]
    : [
        onClick(PANIC_RULES_ALLOW),
        <IconAllow key="Enable" />,
        t('Enable'),
        'Enable',
      ],
  [
    onClick(PANIC_RULES_DISABLE),
    <IconIgnore key="Disable" />,
    t('Disable'),
    'Disable',
  ],
  [
    onClick(PANIC_RULES_REMOVE),
    <Icon
      key="Remove-contact"
      type={IconType.trashAlt}
      size={IconSize.lg}
      color={IconColor.regular}
    />,
    t('Remove contact'),
    'Remove-contact',
  ],
];

export const getDetailActions = (urlDetails?: DetailsItemRecord) => onClick => {
  if (urlDetails === undefined) return [];
  if (urlDetails instanceof VideoDetailsRecord) {
    return [
      [
        onClick(VideoActions.Play),
        <Icon
          key="Play-video"
          type={IconType.playCircle}
          size={IconSize.lg}
          family={IconFamily.solid}
          color={IconColor.primary}
        />,
        t('Play video'),
        'Play-video',
      ],
    ];
  }
  return [];
};

export const getYoutubeActions = isVisit => onClick => {
  return [
    isVisit ? [] : getViewDetailsDropdownAction(onClick),
    [
      onClick(ACTION_VIEW_YOUTUBE_SETTINGS),
      <Icon
        key="Manage-rules"
        type={IconType.cog}
        size={IconSize.lg}
        family={IconFamily.solid}
        color={IconColor.primary}
      />,
      t('Manage rules'),
      'Manage-rules',
    ],
  ];
};

export const getCallsAndSmsActions = onClick => [
  [onClick(false), <IconAllow key="Allow-all" />, t('Allow all'), 'Allow-all'],
  [onClick(true), <IconBlock key="Block-all" />, t('Block all'), 'Block-all'],
];

export const getAccountActions = (
  onClick,
  isCurrentProductDedicatedSupport
) => [
  isCurrentProductDedicatedSupport && [
    onClick(AccountActions.carePlus),
    <Icon
      key="Care-plus"
      type={IconType.userHeadSet}
      color={IconColor.regular}
      family={IconFamily.solid}
    />,
    t('Care Plus'),
    'Care-plus',
  ],
  getBuildPlatform() !== BuildPlatform.ios
    ? [
        onClick(AccountActions.helpCenter),
        <Icon
          key="Help-center"
          type={IconType.infoCircle}
          color={IconColor.regular}
        />,
        t('Help Center'),
        'Help-center',
      ]
    : [],
  [
    onClick(AccountActions.logout),
    <Icon key="Logout" type={IconType.signOut} color={IconColor.regular} />,
    t('Logout'),
    'Logout',
  ],
];

export const getSafeNetworkActions = onClick => [
  getViewDetailsDropdownAction(onClick),
];
