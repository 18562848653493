import { connect } from 'react-redux';
import {
  finishPurchaseProcess,
  productChanged,
} from '../../ducks/products/actions';
import {
  clickProduct,
  setIsNavigatingToPurchaseProduct,
} from '../../actions/UpgradeActions';
import TwoYearsPromotionModal from '../../components/Modal/TwoYearsPromotionModal';
import { fetchProducts } from '../../ducks/products';
import { goBackIfHistory } from '../../ducks/routing';
import { getAccount } from '../../selectors/account';
import {
  Events,
  trackTwoYearsPromotionModal,
  PageNames,
} from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import {
  getPrice,
  ProductRecord,
  ProductDuration,
} from '../../records/product';
import { isNavigatingToPurchaseProduct } from '../../selectors/app';
import { getLicense } from '../../selectors/license';
import {
  getSameProductForLongerDuration,
  getSelectedProduct,
  getProductList,
  isPurchaseInProgress,
} from '../../selectors/product';

const mapStateToProps = (state, { params: { productId } }) => {
  const productWithTwoYearDuration =
    getSameProductForLongerDuration(state, productId) || ProductRecord;
  const product = getSelectedProduct(state, productId) || ProductRecord;
  return {
    isLoading: getProductList(state).size === 0,
    product,
    productWithTwoYearDuration,
    licenseType: getLicense(state).type,
    amountWithDiscount: getPrice(
      getAccount(state),
      productWithTwoYearDuration as ProductRecord
    ),
    isNavigatingToPurchaseProduct: isNavigatingToPurchaseProduct(state),
    isPurchaseInProgress: isPurchaseInProgress(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickLink: (product: ProductRecord) => {
    const eventName =
      product.duration === ProductDuration.OneYear
        ? Events.ClickedBuy1YearPromo
        : Events.ClickedBuy2YearPromo;
    trackTwoYearsPromotionModal(eventName, product);
    return dispatch(clickProduct(product.code));
  },
  onClose: (product: ProductRecord) => {
    dispatch(goBackIfHistory());
    dispatch(finishPurchaseProcess());
    dispatch(productChanged(''));
    trackTwoYearsPromotionModal(Events.Closed2YearPromoDialog, product);
  },
});

const TwoYearsPromotionModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(TwoYearsPromotionModal, PageNames.TwoYearPromotionDialog));

TwoYearsPromotionModalContainer.load = () => (dispatch, getState) => {
  dispatch(setIsNavigatingToPurchaseProduct(false));
  if (getProductList(getState()).size === 0) {
    dispatch(fetchProducts());
  }
};

export default TwoYearsPromotionModalContainer;
