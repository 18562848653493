import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import SideBarContainer from '../../../containers/SideBarContainer';
import { LayoutConfigurationProps } from '../types';

const MobileLayout: React.FunctionComponent<{
  children: React.ReactNode;
  configuration?: LayoutConfigurationProps;
}> = ({ children, configuration }) => (
  <FlexLayout
    mainaxis="column"
    height="100%"
    backgroundColor="gray-ligther"
    testId="MobileLayout_Container"
  >
    {!configuration?.hideSideBar && <SideBarContainer />}
    {children}
  </FlexLayout>
);

export default MobileLayout;
