import * as queryString from 'query-string';
import { fetchLicense } from '../../ducks/license';
import {
  startValidation,
  finishValidation,
  timeoutValidation,
} from '../../ducks/products/actions';
import { licenseUpgraded } from '../../records/license';
import { AccountRecord, ProductRecord } from '../../records';
import { timeoutPromise, getCleverbridgeEnv } from '..';
import { simpleTemplate } from '../string';
import * as qinit from '../../qinit';
import {
  getLicense,
  getAccount,
  getProduct,
  getAttributionLastSource,
} from '../../selectors/index';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { BaseThunk } from '../../store/state';
import { getBuildPlatform } from '../globals';
import { chargebeeLocale } from '../chargebee';

const getPurchaseLink = {
  chargebee: getChargebeePurchaseLink,
  cleverbridge: getCleverbridgePurchaseLink,
}[qinit.tenant.common.payment_processor.default];

function getCleverbridgePurchaseLink(
  account: AccountRecord,
  product = ProductRecord() as ProductRecord,
  isDedicatedSupport: boolean
): string {
  const baseUrl = 'https://www.cleverbridge.com/970/purl-qustodio';
  const params = {
    scope: 'checkout',
    cb_ident: '473d1ccb',
    cart: product.code,
    aid: account.uid,
    'x-source': `parents-app-${
      isDedicatedSupport ? 'ds-' : ''
    }${getBuildPlatform()}-${qinit.tenant.common.tenant.name}-locale-${
      account.locale
    }`,
    languages: account.locale,
    deliveryFirstname: account.name,
    deliveryLastname: account.surname,
    deliveryEmail: account.email,
    deliveryEmailRetype: account.email,
    e: getCleverbridgeEnv(),
  };
  const query = queryString.stringify(params);
  return `${baseUrl}?${query}`;
}

function getChargebeePurchaseLink(
  account: AccountRecord,
  product = ProductRecord() as ProductRecord,
  isDedicatedSupport = false,
  lastSource: {}
): string {
  /*
    See https://qustodio.atlassian.net/browse/PAR-3056
    If the coupon is embedded in the url, backend returns the purchase url using qustodio shortener domain 'qtd.io',
    in this case we should use this url instead of make the url in frontend side
  */
  if (product.url && product.url.includes('qtd.io')) {
    return product.url;
  }

  const params = {
    product_code: product.code,
    ac_email: encodeURIComponent(account.email),
    ac_uid: account.uid,
    source_campaign: `parents-app-${
      isDedicatedSupport ? 'ds-' : ''
    }${getBuildPlatform()}-${
      qinit.tenant.common.tenant.name
    }-locale-${chargebeeLocale(account.locale)}`,
    lang: chargebeeLocale(account.locale),
  };
  const purchaseLink = `${simpleTemplate(
    qinit.tenant.common.payment_processor.url.chargebee,
    params
  )}${addAttributionLastSource(lastSource)}`;
  return product.coupon
    ? `${purchaseLink}&subscription[coupon]=${product.coupon}`
    : purchaseLink;
}

const addAttributionLastSource = (lastSource): string => {
  const sourceKeys = Object.keys(lastSource);
  if (sourceKeys.length) {
    const query = sourceKeys.reduce((q, key) => {
      const returnValue = q;
      returnValue[`customer[cf_${key}]`] = lastSource[key];
      return returnValue;
    }, {});
    return `&${queryString.stringify(query)}`;
  }
  return '';
};

function pollValidation(startTs, oldLicense): BaseThunk<Promise<void>> {
  return (dispatch, getState) => {
    return dispatch(fetchLicense()).then(() => {
      if (licenseUpgraded(oldLicense, getLicense(getState()))) {
        dispatch(finishValidation());
        return undefined;
      }
      if (Date.now() - startTs > 10000) {
        dispatch(timeoutValidation());
        throw new Error('License polling timeout');
      }
      return timeoutPromise(1000, () =>
        dispatch(pollValidation(startTs, oldLicense))
      );
    });
  };
}

function validatePurchase(license): BaseThunk<Promise<void>> {
  return dispatch => {
    dispatch(startValidation());
    return dispatch(pollValidation(Date.now(), license));
  };
}

export function buyProduct(productId, license): BaseThunk<Promise<void>> {
  return (dispatch, getState) => {
    const link = getPurchaseLink(
      getAccount(getState()),
      getProduct(getState(), productId),
      getFeatureFlag(getState(), 'dedicatedSupportV2'),
      getAttributionLastSource(getState())
    );
    const ref = window.cordova.InAppBrowser.open(link);
    return new Promise((resolve, reject) => {
      ref.addEventListener('exit', () => {
        dispatch(validatePurchase(license)).then(resolve).catch(reject);
      });
    });
  };
}
