import { connect } from 'react-redux';
import DisconnectTheInternetModal from '../../../components/Routines/Modals/DisconnectTheInternetModal';
import { goBackIfHistory } from '../../../ducks/routing';
import { Dispatch } from '../../../store/state';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const DisconnectTheInternetModalContainer = connect(
  null,
  mapDispatchToProps
)(DisconnectTheInternetModal);

export default DisconnectTheInternetModalContainer;
