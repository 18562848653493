import { goBackIfHistory } from '../../ducks/routing';
import { connect } from 'react-redux';
import { getWebFiltersActiveSection } from '../../selectors';
import WebFiltersLegendModal from '../../components/Modal/WebFiltersLegendModal';

const mapStateToProps = state => ({
  activeSection: getWebFiltersActiveSection(state),
});

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const WebFiltersLegendModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFiltersLegendModal);

export default WebFiltersLegendModalContainer;
