import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import familyTherapyImg from '../../../assets/base/images/wellbeing/family-therapy-small.png';
import familyActivitiesImg from '../../../assets/base/images/wellbeing/wellbeing-activities-small.png';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';

interface WellbeingGradientBackgroundImageSectionProps {
  showImage: boolean;
  children: React.ReactNode;
  imageType?: 'activities' | 'therapy';
}

const WellbeingGradientBackgroundImageSection = ({
  showImage,
  children,
  imageType = 'therapy',
}: WellbeingGradientBackgroundImageSectionProps) => {
  return (
    <FlexLayout mainaxis="column" width="100%">
      <BackgroundGradient gradient="yolk-large" topShape="curve">
        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          className="par-wellbeing-subscribed"
          paddingLeft="16"
          paddingRight="16"
        >
          {showImage && (
            <FlexLayout mainaxis="column" marginTop="24" marginBottom="16">
              <img
                src={
                  imageType === 'therapy'
                    ? familyTherapyImg
                    : familyActivitiesImg
                }
                alt=""
                className="par-wellbeing-subscribed__image"
              />
            </FlexLayout>
          )}
          {children}
        </FlexLayout>
      </BackgroundGradient>
    </FlexLayout>
  );
};

export default WellbeingGradientBackgroundImageSection;
