import { Record } from 'immutable';
import { Feature } from '../../records/features/types';
import { isFeatureDisabled, isFeatureRequireUpgrade } from '../features';

export const ruleNeedsUpgrade = (
  feature: Record<Feature> | undefined
): boolean => {
  // if the feature is not yet loaded we should return true to avoid
  // users being able to use a feature when they cannot
  if (!feature) return true;
  return isFeatureRequireUpgrade(feature);
};

export const shouldHideRule = (
  feature: Record<Feature> | undefined
): boolean => {
  // if the feature is not yet loaded we should return true to avoid
  // users seeing a feature when they cannot
  if (!feature) return true;
  return isFeatureDisabled(feature);
};
