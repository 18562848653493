import * as React from 'react';
import { Modal, Button, ButtonSize, Layout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants/index';
import type { AdditionalParentInformationModalProps } from '../../containers/Modal/AdditionalParentInformationModalContainer';

const AdditionalParentInformationModal: React.FC<
  AdditionalParentInformationModalProps
> = ({ onClose }: AdditionalParentInformationModalProps) => (
  <Modal
    testId="additionalParentInformation"
    title={t('What’s this?')}
    onClickClose={onClose}
    width={modalDimensions.regular.width}
    buttons={[
      <Button
        block
        key="ok-button"
        onClick={onClose}
        size={ButtonSize.medium}
        testId="additionalParentInformation__Button--OK"
      >
        {t('OK')}
      </Button>,
    ]}
  >
    <Layout marginBottom="16">
      {t(
        'Here you can invite {1}one parent or guardian{/1} to join you in setting rules and supervising your kids’ digital activity.',
        { 1: (str: string) => <strong>{str}</strong> }
      )}
    </Layout>
    {t(
      'If you invite someone that already has a {{shortName}} account they’ll need to create a new one to join yours.'
    )}
  </Modal>
);

export default AdditionalParentInformationModal;
