import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import SafeNetworkAllowModal from '../../components/Modal/SafeNetworkAllowModal';
import { getProfile } from '../../selectors';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import * as qinit from '../../qinit';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const profile = getProfile(state, Number(profileId));

  return {
    profileName: profile?.name,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  onClose: () => {
    // We can't use back because users might enter through push notification, having no "back" to go
    // As this renders on top of the profile dashboard, our back should be just rendering the dashboard
    goToDashboard(dispatch, profileId);
  },
  onClickHelpLink: () => {
    const navigate = getMultiPlatformNavigation();

    dispatch(
      navigate({
        type: 'external',
        src: qinit.tenant.marketing.public_site.help_safe_networks,
      })
    );
  },
});

const goToDashboard = async (dispatch: Dispatch, profileId) => {
  const navigate = getMultiPlatformNavigation();
  await dispatch(
    navigate({
      type: 'inner:replace',
      src: `/profiles/${profileId}`,
    })
  );
};

const SafeNetworkAllowModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SafeNetworkAllowModal);

export type SafeNetworkAllowModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default SafeNetworkAllowModalContainer;
