import * as React from 'react';
import {
  List as AppList,
  EmptyPlaceholder,
  InstructionBlock,
  FlexLayout,
} from 'styleguide-react';
import { Link } from 'react-router';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import AppRulesCard from '../AppRules/AppRulesCard';
import { makeAppKey, AppRuleRecord } from '../../records/profileRules';
import IconYoutube from '../Icons/IconYoutube';
import { PLATFORM_IOS, APPLICATION_BLOCKED } from '../../constants';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import type { AppRulesProps } from '../../containers/ProfileRules/AppRules/AppRulesContainer';

interface YoutubeRulesListProps {
  profile: ProfileRecord;
  list: List<AppRuleRecord>;
  appRulesEnabled: boolean;
  isBlockedUnsupportedBrowsers: boolean;
  onSelectStatus: AppRulesProps['onSelectStatus'];
  onClickApp: AppRulesProps['onClickApp'];
}

const YoutubeRulesList = ({
  profile,
  list = List<AppRuleRecord>(),
  appRulesEnabled,
  isBlockedUnsupportedBrowsers,
  onSelectStatus,
  onClickApp,
}: YoutubeRulesListProps) => {
  const appIsBeingTracked = list && list.size !== 0;

  if (!appIsBeingTracked) {
    return (
      <FlexLayout mainaxis="column">
        {getEmptyMsg(profile, 'yt-app-not-tracked-msg')}
      </FlexLayout>
    );
  }

  if (!appRulesEnabled) {
    return (
      <FlexLayout mainaxis="column">
        {getEmptyMsg(profile, 'apprules-disabled-msg')}
      </FlexLayout>
    );
  }

  return (
    <FlexLayout mainaxis="column">
      {list.map(youtubeDeviceRule => (
        <AppList
          key={makeAppKey(youtubeDeviceRule)}
          allowCustomListItemStyle={AppRulesCard}
          listItemPaddingBottom="8"
          listItemPaddingTop="8"
          listItemMarginBottom="16"
        >
          <AppRulesCard
            enabled
            app={youtubeDeviceRule}
            isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
            icon={<IconYoutube />}
            info={
              youtubeDeviceRule.action === APPLICATION_BLOCKED &&
              youtubeDeviceRule.platform === PLATFORM_IOS ? (
                <InstructionBlock>
                  {t(
                    'Blocking access to the YouTube app will also block YouTube website and all iOS devices'
                  )}
                </InstructionBlock>
              ) : undefined
            }
            onSelectStatus={onSelectStatus}
            onClickApp={onClickApp}
          />
        </AppList>
      ))}
    </FlexLayout>
  );
};

const getEmptyMsg = (
  profile: ProfileRecord,
  placeholderVariant: 'yt-app-not-tracked-msg' | 'apprules-disabled-msg'
) => {
  if (placeholderVariant === 'yt-app-not-tracked-msg') {
    return (
      <EmptyPlaceholder
        testId="YoutubeRulesNoYoutubeAppsTrackedPlaceHolder"
        text={t(
          'Not currently tracking YouTube app activity on any iOS or Android devices for {1}{{profileName}}{/1}',
          {
            profileName: profile.name,
            1: str => <strong>{str}</strong>,
          }
        )}
      />
    );
  }
  if (placeholderVariant === 'apprules-disabled-msg') {
    return (
      <InstructionBlock testId="YoutubeRulesAppRulesDisabledPlaceHolder">
        <p>
          {t(
            'To enable YouTube app controls, go to {1}Games & Apps{/1} and enable application controls.',
            {
              1: str => (
                <Link to={`/profiles/${profile.id}/rules/appRules`}>{str}</Link>
              ),
            }
          )}
        </p>
      </InstructionBlock>
    );
  }
  return null;
};

export default YoutubeRulesList;
