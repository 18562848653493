import * as React from 'react';
import {
  Dropdown,
  DropdownOption,
  DropdownElementOrientation,
  MoreActionsButton,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

export interface Option {
  onClick: (name?: string, event?: React.SyntheticEvent) => any;
  icon: JSX.Element;
  label: string;
  index: string;
}

interface Props {
  active?: boolean;
  options: Option[];
  title?: string;
  actionElement?: JSX.Element;
  asModal?: boolean;
  menuOrietation?: DropdownElementOrientation;
  minWidth?: number;
  disabled?: boolean;
  useSinglePortalHook?: boolean;
  onOpen?: () => void;
  onClose?: () => any;
}

const DropDown: React.FunctionComponent<Props> = ({
  useSinglePortalHook = true,
  title,
  options,
  active,
  actionElement,
  asModal,
  disabled,
  menuOrietation = DropdownElementOrientation.left,
  minWidth = 240,
  onClose,
  onOpen,
}) => {
  const renderDropdown = (asModal: boolean) => (
    <Dropdown
      disabled={disabled}
      actionElement={
        !actionElement ? (
          <MoreActionsButton testId="more-actions-button" />
        ) : (
          actionElement
        )
      }
      showSelectedOption={false}
      asModal={asModal}
      header={asModal && title ? <span>{title}</span> : undefined}
      footer={asModal ? <span>{t('Cancel')}</span> : undefined}
      testId="dropdown-actions"
      useSinglePortalHook={useSinglePortalHook}
      onOpen={onOpen}
      onClose={onClose}
      active={active}
      attachedMenuOriention={menuOrietation}
      minWidth={minWidth}
    >
      {options.map(
        ({ onClick, icon, label, index }) =>
          label &&
          index && (
            <DropdownOption
              key={index}
              icon={icon}
              onClick={(name, event) => {
                event.stopPropagation();
                onClick(name, event);
              }}
              value={index}
              text={label}
            />
          )
      )}
    </Dropdown>
  );

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {renderDropdown(asModal ?? false)}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderDropdown(asModal ?? true)}
      </RenderWhen>
    </React.Fragment>
  );
};

export default DropDown;
