import { connect } from 'react-redux';
import * as qinit from '../qinit';
import LockScreen from '../components/Login/LockScreen';
import { checkBiometricLogin } from '../actions/BiometricLoginActions';
import { doLoginWithPassword } from '../actions/LockScreenActions';
import { getAccount } from '../selectors/account';
import State, { Dispatch } from '../store/state';
import { APPThunk } from '../helpers/thunks';
import { isLockScreenEnabled } from '../selectors/lockState';
import { getFeatureFlag } from '../selectors/featureFlags';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

const mapStateToProps = (state: State) => {
  const account = getAccount(state);
  return {
    accountName: account.fullname,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: () => dispatch(doLoginWithPassword()),
  onForgotPasswordClick: () => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'external',
        src: qinit.tenant.dashboard.wizard.forget_password_link,
      })
    );
  },
  checkBiometricLogin: () => {
    return dispatch(checkBiometricLogin());
  },
});

const LockScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LockScreen);

LockScreenContainer.redirect = (): APPThunk => (dispatch, gerState) => {
  const state = gerState();
  const navigate = getMultiPlatformNavigation();
  if (!getFeatureFlag(state, 'lockScreen') || !isLockScreenEnabled(state)) {
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  }
};

export type LockScreenProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default LockScreenContainer;
