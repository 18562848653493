import * as React from 'react';
import * as Moment from 'moment';
import { t } from '../../lib/i18n';
import { HUMAN_DATE_FORMAT } from '../../helpers/dates';
import { LicenseRecord } from '../../records';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Icon,
  IconType,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { getProperLicenseText } from '../../records/license';

const RenewalModal: React.FunctionComponent<{
  license: LicenseRecord;
  onClickLink: () => any;
  onClickClose: () => any;
}> = ({ license, onClickLink, onClickClose }) => {
  const endDate = Moment(license.endDate).format(HUMAN_DATE_FORMAT);
  return (
    <Modal
      onClickClose={onClickClose}
      header={{
        icon: <Icon type={IconType.checkCircle} />,
      }}
      title={t('Your renewal was successful!')}
      buttons={[
        <Button
          key="button"
          onClick={onClickLink}
          block
          size={ButtonSize.medium}
        >
          {t('Continue')}
        </Button>,
      ]}
      width={modalDimensions.regular.width}
      height={modalDimensions.regular.height}
    >
      <ModalStyledText>
        {getProperLicenseText(
          license.subtype,
          t(
            '{1}Thank you for trusting in {{shortName}}! {/1}' +
              'Your new license will expire on {{endDate}}. ' +
              'Now you can access {{planName}} features on up to {{maxDevices}} devices.',
            {
              1: str => <strong>{str}</strong>,
              planName: 'Basic',
              endDate,
              maxDevices: license.maxDevices,
            }
          ),
          t(
            '{1}Thank you for trusting in {{shortName}}! {/1}' +
              'Your new license will expire on {{endDate}}. ' +
              'Now you can access {{planName}} features on an unlimited number of devices.',
            {
              1: str => <strong>{str}</strong>,
              planName: 'Complete',
              endDate,
              maxDevices: license.maxDevices,
            }
          ),
          t(
            '{1}Thank you for trusting in {{shortName}}! {/1}' +
              'Your new license will expire on {{endDate}}. ' +
              'Now you can access all Premium features on up to {{maxDevices}} devices.',
            {
              1: str => <strong>{str}</strong>,
              endDate,
              maxDevices: license.maxDevices,
            }
          )
        )}
      </ModalStyledText>
    </Modal>
  );
};

export default RenewalModal;
