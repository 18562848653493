import { totalDevicesInProfileWithVirtualDevices } from '../../helpers/virtualDevices';
import { where } from '../decision';
import { getDevicesOffline, getDevicesOfflineLongPeriod } from '../devices';
import { UnifiedNowCardStateContext } from './types';
import { isProfileWithActiveRoutine } from '../../records/profile';
import {
  isRoutineTypeBlocked,
  doesRoutineConsumeDailyTimeQuota,
} from '../../ducks/routines/helpers';
import { complement, isNil } from 'ramda';

export const whereProfileHasOnlySchoolDevice =
  where<UnifiedNowCardStateContext>({
    profile: value => value.isLinewizestudentLinked,
    profileDevices: value => value.size === 0, // When we have delegation this will be 1, and we should check if has no devices or have 1 and it's delegated
  });

export const whereSchoolDeviceIsDelegated = where<UnifiedNowCardStateContext>({
  isProfileDelegated: value => Boolean(value),
});

export const whereSchoolDeviceIsDelegatedToThisAccount =
  where<UnifiedNowCardStateContext>({
    isProfileDelegatedToThisAccount: value => Boolean(value),
  });

export const whereTheSchoolDevicePausedIsEnabled =
  where<UnifiedNowCardStateContext>({
    studentPause: value => Boolean(value?.get('enabled')),
  });

export const whereTheSchoolDeviceIsPaused = where<UnifiedNowCardStateContext>({
  internetPaused: value => Boolean(value),
});

export const whereTheSchoolDeviceIsOutsideSchoolOr247 = ({
  studentPause,
  inSchoolPeriod,
}: UnifiedNowCardStateContext) =>
  Boolean(studentPause.get('pause247')) || Boolean(inSchoolPeriod) === false;

export const whereSchoolDeviceHasEnabledPause247 = ({
  studentPause,
}: UnifiedNowCardStateContext) => Boolean(studentPause.get('pause247'));

export const whereTheSchoolDeviceIsInsideSchool = ({
  inSchoolPeriod,
}: UnifiedNowCardStateContext) => Boolean(inSchoolPeriod);

export const whereProfileHasAllDevicesTampered = ({
  kidTamperedDevices,
  profile,
  profileDevices,
}: UnifiedNowCardStateContext) => {
  return (
    Boolean(kidTamperedDevices) &&
    kidTamperedDevices.length ===
      totalDevicesInProfileWithVirtualDevices(profile, profileDevices)
  );
};

export const whereProfileHasOnlyOneTamperedDevice =
  where<UnifiedNowCardStateContext>({
    kidTamperedDevices: value => value.length === 1,
  });

export const whereProfileHasAllDevicesDisabled = ({
  profile,
  profileDevices,
}: UnifiedNowCardStateContext) => {
  return (
    totalDevicesInProfileWithVirtualDevices(profile, profileDevices) ===
    profileDevices.filter(device =>
      device.users.every(
        user => user?.getIn(['status', 'disableProtection', 'status']) || false
      )
    ).size
  );
};

export const whereProfileHasAllDevicesOffline = ({
  timezone,
  profile,
  profileDevices,
}: UnifiedNowCardStateContext) => {
  return (
    totalDevicesInProfileWithVirtualDevices(profile, profileDevices) ===
    getDevicesOffline(profileDevices, timezone).size
  );
};

export const whereAllDevicesOfflineLongPeriod = ({
  timezone,
  profileDevices,
}: UnifiedNowCardStateContext) => {
  return (
    getDevicesOffline(profileDevices, timezone).size ===
    getDevicesOfflineLongPeriod(profileDevices, timezone).size
  );
};

export const whereProfileHasAllDevicesInSafeNetworks = ({
  numOfDevicesInSafeNetworks,
  profile,
  profileDevices,
}: UnifiedNowCardStateContext) =>
  Boolean(numOfDevicesInSafeNetworks) &&
  numOfDevicesInSafeNetworks ===
    totalDevicesInProfileWithVirtualDevices(profile, profileDevices);

export const whereProfileHasDevicesPaused = where<UnifiedNowCardStateContext>({
  internetPaused: value => value,
});

export const whereProfileHasOnlyOneDevice = (
  ctx: UnifiedNowCardStateContext
): boolean =>
  totalDevicesInProfileWithVirtualDevices(ctx.profile, ctx.profileDevices) ===
  1;

export const whereProfileHasDeviceRestrictedTimeAndLocked = (
  ctx: UnifiedNowCardStateContext
) => {
  return (
    ctx.timeRestrictions &&
    (ctx.isLockComputerInTimeRestriction ||
      ctx.isLockNavigationInTimeRestrictions)
  );
};

export const whereProfileHasDeviceRestrictedTimesAndAlert = (
  ctx: UnifiedNowCardStateContext
) => {
  return (
    ctx.timeRestrictions &&
    !ctx.isLockComputerInTimeRestriction &&
    !ctx.isLockNavigationInTimeRestrictions &&
    ctx.isTimeRestrictionAlert
  );
};

export const whereProfileHasReachedTimeLimitWithLockSettings = (
  ctx: UnifiedNowCardStateContext
) => {
  return (
    ctx.screenTimeReached &&
    (ctx.isLockComputerInTimeRestriction ||
      ctx.isLockNavigationInTimeRestrictions)
  );
};

export const whereProfileHasReachedTimeLimitWithOnlyAlert = (
  ctx: UnifiedNowCardStateContext
) => {
  return (
    ctx.screenTimeReached &&
    !ctx.isLockComputerInTimeRestriction &&
    !ctx.isLockNavigationInTimeRestrictions &&
    ctx.isTimeRestrictionAlert
  );
};

export const whereProfileHasAtLeastOneDeviceInSafeNetworks =
  where<UnifiedNowCardStateContext>({
    numOfDevicesInSafeNetworks: value => value > 0,
  });

export const whereProfileIsRunningOverTimeLimit =
  where<UnifiedNowCardStateContext>({
    profileHasTimeLimitRunning: value => Boolean(value),
  });

export const whereProfileHasExtraTime = where<UnifiedNowCardStateContext>({
  extraTimeInMinutes: value => !Number.isNaN(value) && value > 0,
});

/// Routines
export const whereProfileHasAnActiveRoutine = where<UnifiedNowCardStateContext>(
  {
    isRoutinesFeatureEnabled: enabled => enabled,
    profile: isProfileWithActiveRoutine,
    activeRoutine: complement(isNil),
  }
);

export const whereProfileHasAnActiveRoutineBlockType =
  where<UnifiedNowCardStateContext>({
    activeRoutine: isRoutineTypeBlocked,
  });

export const whereProfileHasAnActiveRoutineNoCountingToTimeLimit =
  where<UnifiedNowCardStateContext>({
    activeRoutine: routine => !doesRoutineConsumeDailyTimeQuota(routine),
  });
