import { isProfileDelegatedToThisAccount } from '../businessLogic/delegation/delegation';
import { fetchStudentPolicies } from '../ducks/studentPolicies';
import { ProfileId } from '../records/profile/types/Profile.types';
import {
  getProfileDevices,
  getProfileOrDefault,
} from '../selectors/profileDevices';
import {
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../selectors/studentPolicies';
import { BaseThunk } from '../store/state';
import { showPremiumFlyover } from './PremiumFeaturesActions';

export const redirectToAddDeviceFlyover =
  (profileId: ProfileId, redirectUrl: string): BaseThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let state = getState();
    let isDelegatedToAccount = false;
    const profile = getProfileOrDefault(state, profileId);

    if (profile.isLinewizestudentLinked) {
      await dispatch(fetchStudentPolicies(Number(profileId)));
      state = getState();
      isDelegatedToAccount = isProfileDelegatedToThisAccount(
        isDelegationEnabled(state),
        isStudentOptInForDelegation(state),
        isStudentOptInForDelegationForThisAccount(state)
      );
    }
    if (
      profile.isLinewizestudentLinked &&
      isDelegatedToAccount &&
      getProfileDevices(state, profileId).isEmpty()
    ) {
      dispatch(showPremiumFlyover(redirectUrl));
      return true;
    }
    return false;
  };
