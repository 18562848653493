import * as React from 'react';
import {
  Card,
  GlobalType,
  Icon,
  IconColor,
  IconSize,
  StyledHeader,
} from 'styleguide-react';
import { classNames } from '../../helpers';
import Clickable from '../Clickable/Clickable';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import type { ProfileRuleCardProps } from './types/ProfileRuleCardProps.types';

const ProfileRuleCard = ({
  title,
  subtitle,
  icon,
  active,
  footer,
  notAvailable = false,
  focused = false,
  className,
  testId,
  onClick,
}: ProfileRuleCardProps) => {
  const baseClass = `par-profile-rule-card`;
  const statusColor = active ? 'primary' : 'secondary';
  return (
    <div
      className={classNames(
        baseClass,
        `${baseClass}--status-color-${statusColor}`,
        className
      )}
      data-testid={testId ? `ProfileRuleCard__${testId}` : undefined}
    >
      <Clickable onClick={onClick} role="button">
        <Card
          type={GlobalType.white}
          className={classNames(
            `${baseClass}__container`,
            notAvailable ? `${baseClass}__container--not-available` : null,
            focused ? `${baseClass}__container--focused` : null
          )}
        >
          <div className={`${baseClass}__body`}>
            <div className={`${baseClass}__icon`}>
              <RenderWhen
                screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}
              >
                {screenSize => (
                  <Icon
                    square
                    type={icon}
                    color={IconColor[statusColor]}
                    size={
                      screenSize === ScreenSize.TabletOrDesktop
                        ? IconSize.x3
                        : IconSize.x2
                    }
                  />
                )}
              </RenderWhen>
            </div>
            <div className={`${baseClass}__content`}>
              <StyledHeader type="h5" className={`${baseClass}__title`}>
                <strong>{title}</strong>
              </StyledHeader>
              <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
                <p className={`${baseClass}__subtitle`}>{subtitle}</p>
              </RenderWhen>
              {footer && <div className={`${baseClass}__footer`}>{footer}</div>}
            </div>
          </div>
        </Card>
      </Clickable>
    </div>
  );
};

ProfileRuleCard.displayName = 'ProfileRuleCard';

export default ProfileRuleCard;
