import { createSelector } from 'reselect';
import * as Moment from 'moment-timezone';
import {
  getTimezone,
  getMultiParentFeatureFlagEnabled,
  getAccountOptionMultiParentFeatureFlagEnabled,
  getAccount,
} from '../stateSelectors/account';
import { getCurrentTimeRaw } from '../stateSelectors/time';
import { getLicense, getPurchaseProvider } from '../stateSelectors/license';

import type State from '../../store/state';

import { memoizedMoment } from '../helpers';
import { isAndroidPlatform } from '../../helpers'; // <-- to remove

import {
  parseISODateString,
  getCurrentLocalTimeForTimezone,
  API_TIMESTAMP_FORMAT_WITHOUT_SEPARATORS,
} from '../../helpers/dates';

import flags from '../../sideEffects/flags'; // <-- to remove

export const shouldDisplayManageSubscriptionLink = (state: State): boolean =>
  isAndroidPlatform() && Boolean(getPurchaseProvider(state));

/**
 * Get the current time with the account's timezone applied
 */
export const getAccountCurrentTime = createSelector(
  getCurrentTimeRaw,
  getTimezone,
  memoizedMoment((timeStamp: ISODateString, timezone: string) =>
    Moment.tz(parseISODateString(timeStamp), timezone)
  )
);

export const getAccountCurrentLocalTime = (state: State) =>
  getCurrentLocalTimeForTimezone(
    getTimezone(state),
    API_TIMESTAMP_FORMAT_WITHOUT_SEPARATORS
  );

export const shouldDisplayMultiparentFeature = (state: State): boolean =>
  flags.multiParentInitiative.isEnabled() &&
  (getMultiParentFeatureFlagEnabled(state) ||
    getAccountOptionMultiParentFeatureFlagEnabled(state));

export const areProfilesExceeded = createSelector(
  getAccount,
  getLicense,
  (account, license) => {
    const { profilesCount } = account;
    const { maxProfiles } = license;
    return profilesCount > maxProfiles;
  }
);

export const areDevicesExceeded = createSelector(
  getAccount,
  getLicense,
  (account, license) => {
    const { devicesCount } = account;
    const { maxDevices } = license;
    return devicesCount > maxDevices;
  }
);

export const isAccountOverLimit = (state: State) => {
  return areDevicesExceeded(state) || areProfilesExceeded(state);
};

export const areProfilesAtLimit = createSelector(
  getAccount,
  getLicense,
  (account, license) => {
    const { profilesCount } = account;
    const { maxProfiles } = license;
    return profilesCount === maxProfiles;
  }
);
