import { ofType } from 'redux-observable';
import { List } from 'immutable';
import { catchError, concat, filter, from, map, of, switchMap } from 'rxjs';
import { AppAction, AppEpic } from '../types';
import {
  LOAD_STUDENT_TOTAL_USAGE_ACTIVITY,
  receiveSummaryTotalUsageError,
  receiveTotalUsage,
  requestTotalUsage,
  studentUnlinkedErrors,
} from '../../ducks/studentSummary';
import { getProfile } from '../../selectors';
import { getNow, requestProfileFilter } from '../helpers/dataLoaderHelpers';
import { showErrorAlert } from '../../helpers/errorHandling';
import { TotalUsageActivity } from '../../records/studentActivity/types/totalUsage.types';
import { TotalUsageActivityRecord } from '../../records/studentActivity/totalUsage';
import {
  ProfileId,
  ProfileRecord,
} from '../../records/profile/types/Profile.types';
import { APIError } from '../../lib/errors';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryStudentTotalUsageActivityAction = ReturnType<
  typeof receiveTotalUsage
>;
interface LoadSummaryStudentTotalUsageActivityAction {
  payload: {
    profileId: ProfileId;
  };
}
interface TotalUsageResponse {
  // eslint-disable-next-line camelcase
  last_updated: string;
  // eslint-disable-next-line camelcase
  total_usage: TotalUsageActivity[];
}

const errorHandling = error => {
  if (error instanceof APIError) {
    if (studentUnlinkedErrors.includes(error.status)) {
      return receiveSummaryTotalUsageError(StudentSummaryFetchError.Unlinked);
    }
    if (error.status === 412) {
      return receiveSummaryTotalUsageError(
        StudentSummaryFetchError.FeatureDisabled
      );
    }
  }
  showErrorAlert(error.message);
  return receiveSummaryTotalUsageError(
    StudentSummaryFetchError.UnhandledServerError
  );
};

const notifyIfError = catchError(e => {
  return of(errorHandling(e));
});

const setLoading = () => {
  return requestTotalUsage();
};

const getSummaryStudentTotalUsageActivity = (
  api,
  { profileUid }
): Promise<TotalUsageResponse> => {
  return api.summaryStudentTotalUsage.withCache(ttl1Minute).get({
    profileUid,
  });
};

const toReceiveSummaryAppWebActivityAction = (
  totalUsage: TotalUsageResponse
) => {
  return receiveTotalUsage(
    totalUsage.last_updated,
    List(
      totalUsage.total_usage.map(usagePerDay =>
        TotalUsageActivityRecord.fromPayload(usagePerDay)
      )
    )
  );
};

export const SummaryStudentTotalUsageEpic: AppEpic<
  AppAction,
  ReceiveSummaryStudentTotalUsageActivityAction
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_STUDENT_TOTAL_USAGE_ACTIVITY),
    map((action: LoadSummaryStudentTotalUsageActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter((profile: ProfileRecord) =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap((profile: ProfileRecord) => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(setLoading()),
        from(
          getSummaryStudentTotalUsageActivity(api, {
            profileUid: profile?.uid,
          })
        ).pipe(
          map(totalUsageResponse =>
            toReceiveSummaryAppWebActivityAction(totalUsageResponse)
          ),
          notifyIfError
        )
      );
    })
  );

  return loadData;
};
