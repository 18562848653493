import * as React from 'react';
import { t } from '../../lib/i18n';
import { TimeSettingsRecord } from '../../records/profileRules';
import { SettingsToggleNames } from '../../constants';
import {
  List as RegularStyleList,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';

export interface TimeNotificationsProps {
  timeRestrictions: TimeSettingsRecord;
  toggleDisabled: boolean;
  onToggle: (target: SettingsToggleNames, checked: boolean) => void;
  onClickDisabled: () => any;
  onClickBackButton: () => any;
}

const TimeNotifications: React.FunctionComponent<TimeNotificationsProps> = ({
  timeRestrictions: { isReportAlerts },
  toggleDisabled,
  onToggle,
  onClickDisabled,
}) => (
  <RegularStyleList>
    <RegularStyleListItem
      title={t('Alert me')}
      upperSubtitle={t(
        'Sends you an alert when your child reaches his or her limit.'
      )}
      checked={isReportAlerts}
      actionElement={
        <Switch
          id={SettingsToggleNames.IsReportAlerts}
          checked={isReportAlerts}
          onClick={
            isReportAlerts && toggleDisabled
              ? onClickDisabled
              : ev =>
                  onToggle(
                    SettingsToggleNames.IsReportAlerts,
                    (ev.target as HTMLInputElement).checked
                  )
          }
          disabled={isReportAlerts && toggleDisabled}
        />
      }
    />
  </RegularStyleList>
);
export default TimeNotifications;
