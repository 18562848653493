import Immutable from 'immutable';
import ScrollBehavior from 'scroll-behavior';
import * as R from 'ramda';
import { toJS } from './helpers';

export const SCROLL_SAVE = 'SCROLL_SAVE';
export const SCROLL_DELETE = 'SCROLL_DELETE';

const getLocationKey = R.curry(
  (getFallbackLocationKey, location) =>
    location.key || getFallbackLocationKey(location)
);

const getState = (store, locationKey, elementKey) =>
  toJS(store.getState().getIn(['scrollHistory', locationKey, elementKey]));

/*
 * Scroll-behavior wants a storage with "read" and "save", and it saves on each
 * scroll. To avoid thrashing the state, we buffer the scroll data and flush
 * when a navigation happens. We remove entries when going back (history POP).
 */
const stateStorage = (store, getLocationKey) => {
  let buffer;
  return {
    read: (location, key) =>
      getState(store, getLocationKey(location), key || 'window'),
    save: (location, key, value) => {
      buffer = [getLocationKey(location), key || 'window', value];
    },
    flush: () => (buffer ? store.dispatch(scrollChange(...buffer)) : undefined),
    remove: location => store.dispatch(scrollDelete(getLocationKey(location))),
  };
};

const isModalPath = pathname => pathname && !!pathname.match(/\/modal\/\w+/);

export function scrollChange(locationKey, elementKey, coords) {
  return {
    type: SCROLL_SAVE,
    payload: [locationKey, elementKey, Immutable.List(coords)],
  };
}

export function scrollDelete(locationKey) {
  return {
    type: SCROLL_DELETE,
    payload: locationKey,
  };
}

export default function withScroll(history, store) {
  // history v2 will invoke the onChange callback synchronously, so
  // currentLocation will always be defined when needed.
  let currentLocation = {};
  const registeredElementKeys = [];

  const storage = stateStorage(store, getLocationKey(history.createPath));

  function getCurrentLocation() {
    return currentLocation;
  }

  const scrollBehavior = new ScrollBehavior({
    addTransitionHook: history.listenBefore,
    stateStorage: storage,
    getCurrentLocation,
  });

  history.listen(location => {
    const prevLocation = currentLocation;
    currentLocation = location;
    const isBackNavigation = !!(
      prevLocation && currentLocation.action === 'POP'
    );

    if (
      isModalPath((isBackNavigation ? prevLocation : currentLocation).pathname)
    ) {
      return;
    }

    if (isBackNavigation) {
      storage.remove(prevLocation);
    } else {
      storage.flush();
    }
    scrollBehavior.updateScroll();
  });

  /**
   * Scrollable elements/views that want their offset reapplied when going back,
   * must register. Best called on componentDidMount/WillUnmount.
   * See <PullRefresh /> for an example.
   */

  // eslint-disable-next-line no-param-reassign
  history.registerScrollElement = (key, element) => {
    const unregisterElementIfExists = key => {
      const registeredElementKeyIndex = registeredElementKeys.indexOf(key);
      if (registeredElementKeyIndex >= 0) {
        scrollBehavior.unregisterElement(key);
        registeredElementKeys.splice(registeredElementKeyIndex, 1);
      }
    };

    if (element !== null) {
      unregisterElementIfExists(key);
      scrollBehavior.registerElement(key, element);
      registeredElementKeys.push(key);
    } else {
      unregisterElementIfExists(key);
    }
  };

  // eslint-disable-next-line no-param-reassign
  history.scrollToTop = () => {
    storage.remove(getCurrentLocation()); // must remove saved position first
    scrollBehavior.updateScroll();
  };

  return history;
}

// state structure is elementKey.locationKey = scrollValue
export function reducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case SCROLL_SAVE:
      return state.setIn(action.payload.slice(0, 2), action.payload[2]);
    case SCROLL_DELETE:
      return state.delete(action.payload);
    default:
      return state;
  }
}
