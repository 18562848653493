import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { StudentSchool } from './types/StudentSchool.type';

export const StudentSchoolRecordOperations = getRecordOperations<
  StudentSchool,
  StudentSchool
>({
  defaultFields: {
    name: '',
  },
});
