/* eslint-disable react/prop-types */
import * as React from 'react';
import { Layout } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import * as Records from '../../records/profile/types/Profile.types';
import { SummaryDateRanges } from '../../constants';
import DateRangeSelectorSchool from '../DateRangeSelector/DateRangeSelectorSchool';
import StudentCategoriesCardContainer from '../../containers/Schools/StudentCategoriesCardContainer';

type StudentAllCategoriesComponentType = React.FunctionComponent<{
  profile: Records.ProfileRecord;
  activeDataRange: SummaryDateRanges;
  onChangeDateRange: (dateRange: SummaryDateRanges) => void;
  maxCategoriesActivityItems: number;
  single: boolean;
}>;

const StudentAllCategories: StudentAllCategoriesComponentType = ({
  profile,
  activeDataRange,
  onChangeDateRange,
  maxCategoriesActivityItems,
  single,
}) => (
  <PageLayout
    title={profile.name}
    configuration={[{ screenSize: ScreenSize.Desktop }]}
    actions={[{ actionName: 'back' }]}
  >
    <Layout marginBottom="8">
      <DateRangeSelectorSchool
        activeRange={activeDataRange}
        onChangeDateRange={onChangeDateRange}
      />
    </Layout>
    <Layout scrollY>
      <StudentCategoriesCardContainer
        maxCategoriesActivityItems={maxCategoriesActivityItems}
        single={single}
      />
    </Layout>
  </PageLayout>
);

export default StudentAllCategories;
