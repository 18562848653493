import * as setFeatures from 'feature-toggle';
import * as R from 'ramda';
import * as qinit from '../qinit';

export const DISABLE_APP_RULES = 'disable_app_rules';
export const DISABLE_LOCATION_RULES = 'disable_location_rules';
export const DISABLE_CALLS_SMS_RULES = 'disable_calls_sms_rules';
export const DISABLE_MULTIDEVICES = 'disable_multidevices';
export const DISABLE_TIME_LIMITS = 'disable_time_limits';
export const DISABLE_SOCIAL_MONITORING_RULES =
  'disable_social_monitoring_rules';
export const DISABLE_PANIC_BUTTON_RULES = 'disable_panic_button_rules';
export const DISABLE_DEVICE_MANAGEMENT = 'disable_device_management';
export const DISABLE_WEB_FILTERING = 'disable_web_filtering';
export const DISABLE_URL_MONITORING = 'disable_url_monitoring';
export const DISABLE_SEGMENT_TRACKING = 'disable_segment_tracking';
export const DISABLE_BRAZE = 'disable_braze';
export const DISABLE_NEW_ACTIVITY_SUMMARY = 'disable_new_activity_summary';
export const DISABLE_PURCHASES = 'disable_purchases';

export type FeatureFlag =
  | typeof DISABLE_APP_RULES
  | typeof DISABLE_LOCATION_RULES
  | typeof DISABLE_CALLS_SMS_RULES
  | typeof DISABLE_MULTIDEVICES
  | typeof DISABLE_SOCIAL_MONITORING_RULES
  | typeof DISABLE_PANIC_BUTTON_RULES
  | typeof DISABLE_DEVICE_MANAGEMENT
  | typeof DISABLE_WEB_FILTERING
  | typeof DISABLE_URL_MONITORING
  | typeof DISABLE_SEGMENT_TRACKING
  | typeof DISABLE_BRAZE
  | typeof DISABLE_NEW_ACTIVITY_SUMMARY
  | typeof DISABLE_PURCHASES;

const qinitActiveFeatures = Object.keys(
  R.filter(
    R.identity as (arg: any) => boolean,
    R.merge(
      qinit.tenant.parents_app.feature_flags,
      qinit.beta ? qinit.tenant.parents_app.beta_feature_flags : {}
    )
  )
);

const ft = setFeatures(R.union(active_features, qinitActiveFeatures));

export const restoreFeatures = () => {
  ft.deactivate(qinit.tenant.parents_app.feature_flags);
  ft.activate(qinitActiveFeatures);
};

export default ft;
