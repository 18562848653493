import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import { SignatureReviewRecordOperations } from './signatureReview';
import {
  SignatureDetails,
  SignatureDetailsPayload,
} from './types/SignatureDetails.types';

export const SignatureDetailsRecordOperations = getRecordOperations<
  SignatureDetails,
  SignatureDetailsPayload
>({
  defaultFields: {
    name: '',
    id: '',
    iconUrl: '',
    hubLink: '',
    review: SignatureReviewRecordOperations.create(),
    playStoreUrl: '',
    appStoreUrl: '',
    categoryName: '',
  },
  recordDependencies: [
    {
      field: 'review',
      dependencyRecordOperations: SignatureReviewRecordOperations,
    },
  ],
});
