import { connect } from 'react-redux';
import State from '../../store/state';
import {
  getYoutubeSummary,
  hasYoutubeSummaryData,
  getActiveDateRange,
  getIsYoutubeSummaryLoading,
  hasYoutubeSummaryError,
} from '../../selectors';
import YoutubeSummaryCardWithObserver from '../../components/ActivityCards/YoutubeSummary/YoutubeSummaryCardWithObserver';
import { activitySwiperIndexChanged } from '../../ducks/ui';
import {
  navigateToYoutubeActivity,
  openSummaryCardInfoModalFor,
} from '../../actions/ProfileActions';
import { track, Events } from '../../helpers/analytics';

import { ActivityType } from '../../components/ActivitySummary/sharedModels';
import { loadYoutubeSummaryCardInfo } from '../../ducks/youtubeSummary';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { isCombinedDashboardProfile } from '../Dashboard/helpers/profileDashboardHelpers';

interface YoutubeSummaryCardWithObserverContainerProps {
  profile: ProfileRecord;
  className?: string;
  width?: string;
  needsBorder?: boolean;
  needsMargin?: boolean;
}

const mapStateToProps = (
  state: State,
  { profile }: Partial<YoutubeSummaryCardWithObserverContainerProps>
) => ({
  summary: getYoutubeSummary(state),
  isEmpty: !hasYoutubeSummaryData(state),
  dateRange: getActiveDateRange(state),
  isLoading: getIsYoutubeSummaryLoading(state),
  hasError: hasYoutubeSummaryError(state),
  isCombinedProfile: isCombinedDashboardProfile(state, profile!.id),
  profileName: profile?.name,
});

const mapDispatchToProps = (
  dispatch,
  {
    profile,
    className,
    width,
    needsBorder,
  }: YoutubeSummaryCardWithObserverContainerProps
) => ({
  className,
  width,
  needsBorder,
  onExpand: () => {
    dispatch(activitySwiperIndexChanged(3));
    dispatch(navigateToYoutubeActivity(profile.id));
    track(Events.ClickedSeeAllButton, {
      page: 'Youtube Summary',
      category: 'Activity cards',
    });
  },
  goToInfoModal: () => {
    track(Events.ClickedInfoButtoninSummaryCard, {
      card: 'Youtube Activity',
    });
    dispatch(openSummaryCardInfoModalFor(ActivityType.Youtube));
  },
  loadInfo: () => {
    dispatch(loadYoutubeSummaryCardInfo({ profileId: profile.id.toString() }));
  },
});

export type YoutubeSummaryOverviewCardWithObserverContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const YoutubeSummaryOverviewCardWithObserverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubeSummaryCardWithObserver);
export default YoutubeSummaryOverviewCardWithObserverContainer;
