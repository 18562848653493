import * as React from 'react';
import { classNames } from '../../helpers';

/**
 * @deprecated this component is deprecated, please use the button component from Rocket
 *
 * This component allows us to wrap any component with a style-less button.
 * This has been done to have semantically correct clickable elements.
 *
 * @example
 * <Clickable onClick={onClick}>
 *   <div>My clickable element</div>
 * </Clickable>
 */
const Clickable = ({
  type = 'button',
  className,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={classNames('par-clickable', className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default Clickable;
