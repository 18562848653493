import * as React from 'react';
import { List } from 'immutable';
import {
  Dropdown,
  Icon,
  IconColor,
  IconSize,
  IconType,
  DropdownOption,
  ActionInput,
  Layout,
  FlexLayout,
  FreeText,
  Card,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import ActivityTimelineFetchingMore from './ActivityTimelineFetchingMore';
import ActivityTimelineItem from './ActivityTimelineItem';
import { LicenseRecord, EventRecord } from '../../records';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import TimeSeparator from '../TimeSeparator/TimeSeparator';
import Loader from '../base/Loader';
import { ActivityEventFilters } from '../../businessLogic/timeline/types';
import { infiniteScroll } from '../../helpers/hocs';
import TimelineSelectorContainer from '../../containers/TimelineSelector/TimelineSelectorContainer';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { getTimelineSubtitle } from './activityTimelineHelpers';
import { EventCategoryType } from './types';

const getCurrentSelectedText = (filterBy: ActivityEventFilters) => {
  switch (filterBy) {
    case ActivityEventFilters.Locations:
      return t('Locations');
    case ActivityEventFilters.Apps:
      return t('App activity');
    case ActivityEventFilters.CallsAndSMS:
      return t('Calls & messages');
    case ActivityEventFilters.Searches:
      return t('Searches');
    case ActivityEventFilters.Web:
      return t('Web activity');
    case ActivityEventFilters.InappropriateSearches:
      return t('Search alerts');
    default:
      return t('All activity');
  }
};

interface ActivityTimelineFilterSelectProps {
  filterBy: ActivityEventFilters;
  onChange: (filter: ActivityEventFilters) => any;
  isEnabledSummaryAlertsFeature: boolean;
}

const ActivityTimelineFilterSelect: React.FunctionComponent<
  ActivityTimelineFilterSelectProps
> = ({
  filterBy,
  onChange,
  isEnabledSummaryAlertsFeature,
}: ActivityTimelineFilterSelectProps) => {
  return (
    <Layout minWidth="240">
      <Dropdown
        onChange={value => onChange(value as ActivityEventFilters)}
        actionElement={
          <ActionInput
            block
            iconRight={
              <Icon
                type={IconType.angleDown}
                color={IconColor.black}
                size={IconSize.lg}
              />
            }
            iconLeft={
              <Icon
                type="far fa-filter"
                color={IconColor.secondary}
                size={IconSize.lg}
              />
            }
            text={getCurrentSelectedText(filterBy)}
          />
        }
      >
        <DropdownOption
          value={ActivityEventFilters.All}
          text={t('All activity')}
        />
        <DropdownOption
          value={ActivityEventFilters.Web}
          text={t('Web activity')}
        />
        <DropdownOption
          value={ActivityEventFilters.Apps}
          text={t('App activity')}
        />
        <DropdownOption
          value={ActivityEventFilters.Searches}
          text={t('Searches')}
        />
        <DropdownOption
          value={ActivityEventFilters.CallsAndSMS}
          text={t('Calls & messages')}
        />
        <DropdownOption
          value={ActivityEventFilters.Locations}
          text={t('Locations')}
        />
        {isEnabledSummaryAlertsFeature && (
          <DropdownOption
            value={ActivityEventFilters.InappropriateSearches}
            text={t('Search alerts')}
          />
        )}
      </Dropdown>
    </Layout>
  );
};

interface RefreshLasLocationIconProps {
  isRefreshingLastLocation: boolean;
  isLocationRuleEnable: boolean;
}

const RefreshLasLocationIcon = ({
  isRefreshingLastLocation,
  isLocationRuleEnable,
}: RefreshLasLocationIconProps) => {
  if (isLocationRuleEnable && isRefreshingLastLocation) {
    return (
      <div className="ActivityTimeline__location-loader-wrapper">
        <Loader
          size="small"
          color=""
          className="ActivityTimeline__location-loader"
        />
      </div>
    );
  }
  if (isLocationRuleEnable && !isRefreshingLastLocation) {
    return (
      <Icon
        type={IconType.syncAlt}
        color={IconColor.primary}
        testId="timeline-refresh-last-location"
      />
    );
  }

  return null;
};

interface ActivityTimelineProps {
  currentTime: any;
  showFetchingMore: boolean;
  isRefreshingLastLocation: boolean;
  hasNoEvents: boolean;
  lastLocation: any;
  days: List<any>;
  license: LicenseRecord;
  profile: ProfileRecord;
  timezone: string;
  locale: string;
  filterBy: ActivityEventFilters;
  isDelegatedToTheAccount: boolean;
  isDelegatedWithPCDevices: boolean;
  onClickRefreshLastLocation: () => any;
  onClick: (event: EventRecord) => any;
  onClickLabelIcon: (event: EventRecord) => (e: React.MouseEvent<any>) => any;
  onChangeFilter: (filter) => any;
  isLocationRuleEnable: boolean;
  isEventBlockable: (event: EventRecord) => boolean;
  onInfoClick: (event: EventRecord) => void;
  isEnabledSummaryAlertsFeature: boolean;
  onLoad: () => void;
  getEventAlertType: (event: EventRecord) => EventCategoryType;
  onSafetyNetClick: () => void;
  onDelegationInfoClick: () => void;
}

const getEmptyMessage = (
  filterBy: ActivityEventFilters,
  profileName: string
) => {
  if (filterBy === ActivityEventFilters.All) {
    return t('{{profileName}} hasn’t accessed anything yet', { profileName });
  }
  return t('No activity for this profile');
};

const ActivityTimeline: React.FunctionComponent<ActivityTimelineProps> = ({
  currentTime,
  showFetchingMore,
  isRefreshingLastLocation,
  hasNoEvents,
  lastLocation,
  days,
  license,
  profile,
  timezone,
  locale,
  filterBy,
  isDelegatedToTheAccount,
  isDelegatedWithPCDevices,
  onClick,
  onClickLabelIcon,
  onClickRefreshLastLocation,
  onChangeFilter,
  isLocationRuleEnable,
  isEventBlockable,
  onInfoClick,
  isEnabledSummaryAlertsFeature,
  onLoad,
  getEventAlertType,
  onSafetyNetClick,
  onDelegationInfoClick,
}: ActivityTimelineProps) => {
  const showLastLocation =
    lastLocation &&
    (filterBy === ActivityEventFilters.All ||
      filterBy === ActivityEventFilters.Locations);

  const subtitle = getTimelineSubtitle({
    isUnifiedProfile:
      profile.isLinewizestudentLinked && Boolean(profile.deviceCount),
    isDelegatedToThisAccount: isDelegatedToTheAccount,
    timelineType: 'family',
    onDelegationInfoClick,
  });

  React.useEffect(() => {
    onLoad();
  }, []);

  return (
    <Card fullWidth>
      <FlexLayout mainaxis="column">
        {subtitle && (
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <Layout renderAs="span" marginBottom="8">
              <FreeText fontSize="16px">{subtitle}</FreeText>
            </Layout>
          </RenderWhen>
        )}
        <TimelineSelectorContainer profileId={profile.id} />
        <FlexLayout
          mainaxis="column"
          mainaxisAlignment="start"
          crossaxisAlignment="start"
        >
          <ActivityTimelineFilterSelect
            filterBy={filterBy}
            onChange={onChangeFilter}
            isEnabledSummaryAlertsFeature={isEnabledSummaryAlertsFeature}
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout mainaxis="column" className="ActivityTimeline">
        {showLastLocation ? (
          <div className="ActivityTimeline__last-location-event">
            {renderActivityTimelineItem({
              license,
              profile,
              onClick,
              onClickLabelIcon,
              timezone,
              data: lastLocation,
              displayTopRight: true,
              isDelegatedWithPCDevices,
              isEventBlockable,
              onInfoClick,
              getEventAlertType,
              onSafetyNetClick,
            })}
            <div
              className="ActivityTimeline__refresh-indicator"
              onClick={onClickRefreshLastLocation}
            >
              <div className="ActivityTimeline__refresh-indicator-content">
                <RefreshLasLocationIcon
                  isRefreshingLastLocation={isRefreshingLastLocation}
                  isLocationRuleEnable={isLocationRuleEnable}
                />
              </div>
            </div>
          </div>
        ) : null}
        {hasNoEvents && !showLastLocation ? (
          <div className="ActivityMessage">
            <p>{getEmptyMessage(filterBy, profile.name)}</p>
          </div>
        ) : null}
        {days.map(day =>
          renderDay(
            currentTime,
            timezone,
            locale,
            event =>
              renderActivityTimelineItem({
                license,
                profile,
                onClick,
                onClickLabelIcon,
                timezone,
                data: event,
                isEventBlockable,
                onInfoClick,
                getEventAlertType,
                onSafetyNetClick,
                isDelegatedWithPCDevices,
              }),
            day
          )
        )}
        <ActivityTimelineFetchingMore visible={showFetchingMore} />
      </FlexLayout>
    </Card>
  );
};

const renderActivityTimelineItem = ({
  license,
  profile,
  onClick,
  onClickLabelIcon,
  timezone,
  data,
  displayTopRight = false,
  isDelegatedWithPCDevices,
  onInfoClick,
  isEventBlockable,
  getEventAlertType,
  onSafetyNetClick,
}) => {
  const [event, device, contact] = data;

  return (
    <ActivityTimelineItem
      key={event.key}
      license={license}
      profile={profile}
      onClick={onClick}
      onClickLabelIcon={onClickLabelIcon}
      timezone={timezone}
      displayTopRight={displayTopRight}
      event={event}
      device={device}
      contact={contact}
      isBlockable={isEventBlockable(event)}
      onInfoClick={onInfoClick}
      type={getEventAlertType(event)}
      onSafetyNetClick={onSafetyNetClick}
      isDelegatedWithPCDevices={isDelegatedWithPCDevices}
    />
  );
};

const renderDay = (
  currentTime,
  timezone,
  locale,
  renderEvent,
  { day, events }
) => (
  <TimeSeparator
    key={day}
    currentTime={currentTime}
    timezone={timezone}
    locale={locale}
    renderEvent={renderEvent}
    day={day}
    events={events}
  />
);

export default infiniteScroll<ActivityTimelineProps>(ActivityTimeline);
