import {
  anyPass,
  map,
  assoc,
  when,
  mergeDeepRight,
  is,
  assocPath,
  curry,
  all,
  allPass,
  complement,
  toPairs,
  pipe,
  apply,
  reduce,
  binary,
} from 'ramda';
import {
  Map,
  List,
  Record as ImmutableRecord,
  Iterable,
  Set,
  OrderedMap,
  OrderedSet,
} from 'immutable';
import { mergeDeep } from 'immutable4';
import * as moment from 'moment';
import { DeepPartial } from 'redux';
import State from '../store/state';

export type PartialState = DeepPartial<State>;

export type SerializedKey = string | number;

export type Primitive = number | boolean | string | null;

export type SerializedState = {
  type:
    | '$Map'
    | '$List'
    | '$Record'
    | '$Moment'
    | '$Set'
    | '$OrderedMap'
    | '$OrderedSet'
    | '$JS';
  value: Record<SerializedKey, SerializedState> | SerializedState[] | Primitive;
};

export const recordOf = <T>(data: T) => ImmutableRecord(data)();

export const isImmutable = value => value instanceof Iterable;

export const isInstanceof = curry((Constructor, x) => x instanceof Constructor);

/*
  This method is necessary because (Immutable) fromJS converts all the values to Map/List recursively,
  in some scenarios (as records) we need deepest object to be different type. This method allows to keep the type of the deepest object.
  */
const toStateTypes = json => {
  return isImmutable(json)
    ? json
    : Object.entries(json).reduce((acc, [key, value]) => {
        if (is(Object, value) && !is(Array, value) && !isImmutable(value)) {
          return acc.set(key, toStateTypes(value));
        }

        if (is(Array, value)) {
          return acc.set(
            key,
            List(value as unknown[]).map(val => toStateTypes(val))
          );
        }

        return acc.set(key, value);
      }, Map({}));
};

const createPartialStateFromKeyValue = (route, value): PartialState => {
  const path = is(String, route) ? route.split('.') : route;
  const jsState = assocPath(path, value, {});
  return toStateTypes(jsState);
};

const isObject = allPass([complement(Array.isArray), is(Object)]);
const isEntries = allPass([complement(isObject), all(Array.isArray)]);
const combine = reduce(binary(mergeDeep), Map({}));

const createPartialStateFromEntries = pipe(
  map(apply(createPartialStateFromKeyValue)),
  combine
);

const createPartialStateFromObject = pipe(
  toPairs,
  createPartialStateFromEntries
);

export const createPartialState = (route, value?) => {
  if (isEntries(route)) return createPartialStateFromEntries(route);
  if (isObject(route)) return createPartialStateFromObject(route);
  return createPartialStateFromKeyValue(route, value);
};

const isMap = isInstanceof(Map);
const isList = isInstanceof(List);
const isSet = isInstanceof(Set);
const isOrderedMap = isInstanceof(OrderedMap);
const isOrderedSet = isInstanceof(OrderedSet);
const isRecord = isInstanceof(ImmutableRecord);
const isMappable = anyPass([isMap, isList, isSet, isOrderedMap, isOrderedSet]);
const { isMoment } = moment;

const valueOf = src => src.reduce((acc, v, k) => assoc(k, v, acc), {});
const mapRecord = (f, record) => recordOf(map(f, valueOf(record)));

const getType = (value): SerializedState['type'] => {
  if (isOrderedMap(value)) return '$OrderedMap';
  if (isOrderedSet(value)) return '$OrderedSet';
  if (isSet(value)) return '$Set';
  if (isMap(value)) return '$Map';
  if (isList(value)) return '$List';
  if (isRecord(value)) return '$Record';
  if (isMoment(value)) return '$Moment';
  return '$JS';
};

export const toSerializable = (state: PartialState): SerializedState => {
  const getValue = value => {
    if (isMappable(value)) return value.map(toSerializable).toJS();
    if (isRecord(value)) return map(toSerializable, valueOf(value)); // Record not implements functor laws
    if (isMoment(value)) return value.toISOString();
    return value;
  };

  const serializeImmutable = state =>
    state.reduce((acc, value, key) => {
      const serialization = { type: getType(value), value: getValue(value) };
      return assoc(key, serialization, acc);
    }, {});

  return {
    type: getType(state),
    value: when(isImmutable, serializeImmutable, state),
  };
};

const liftType = (type: SerializedState['type'], value) => {
  if (type === '$Set') return Set(Object.values(value));
  if (type === '$OrderedMap') return OrderedMap(Object.values(value));
  if (type === '$OrderedSet') return OrderedSet(Object.values(value));
  if (type === '$Map') return Map(value);
  if (type === '$List') return List(Object.values(value));
  if (type === '$Record') return recordOf(value);
  if (type === '$Moment') return moment(value);
  return value;
};

export const fromSerializable = (state: SerializedState): PartialState => {
  const value = liftType(state.type, state.value);
  if (isMappable(value)) return value.map(fromSerializable);
  if (isRecord(value)) return mapRecord(fromSerializable, value); // Record not implements functor laws
  return value;
};

export const overwrite = (
  immutablePartialState: PartialState,
  serializedState: SerializedState
): SerializedState => {
  const overrides = toSerializable(immutablePartialState);
  return mergeDeepRight(serializedState, overrides);
};
