import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  InstructionBlock,
  StyledHeader,
  ButtonType,
  ButtonColor,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

interface CloseAccountProps {
  onClickCloseAccount: () => void;
}

const CloseAccount: React.FunctionComponent<CloseAccountProps> = ({
  onClickCloseAccount,
}: CloseAccountProps) => (
  <div className="CloseAccount" data-testid="CloseAccount">
    <StyledHeader type="h3">{t('Close account')}</StyledHeader>
    <FlexLayout mainaxis="column" marginTop="16">
      <InstructionBlock>
        {t('Permanently delete your {{shortName}} account.')}
      </InstructionBlock>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginTop="16" marginBottom="24">
      <Button
        testId="close-my-account"
        centered
        size={ButtonSize.medium}
        buttonType={ButtonType.secondary}
        color={ButtonColor.error}
        onClick={() => onClickCloseAccount()}
      >
        {t('Close my account')}
      </Button>
    </FlexLayout>
  </div>
);

export default CloseAccount;
