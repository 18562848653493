import * as React from 'react';
import {
  AlertBox,
  Icon,
  IconFamily,
  IconSize,
  IconType,
  Typography,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SummaryAlertsPrimaryColor } from '../constants';

export interface SummaryAlertsBannerProps {
  count: number;
  onClose: () => void;
  onReview: () => void;
}

const getMessage = ({
  count,
  link,
}: {
  count: number;
  link: (text: string) => JSX.Element;
}) => {
  return count === 1
    ? t('1 new search alert - {1}Review now{/1}', { 1: link })
    : t('{{number}} new search alerts - {1}Review now{/1}', {
        1: link,
        number: count,
      });
};

const SummaryAlertsBanner = ({
  count,
  onClose,
  onReview,
}: SummaryAlertsBannerProps) => {
  return (
    <AlertBox
      className="par-summary-alerts-banner"
      fullWidth
      type="warning"
      onClose={onClose}
      showCloseIcon
      rounded
      showInfoIcon
      icon={
        <Icon
          type={IconType.exclamationCircle}
          family={IconFamily.solid}
          color={SummaryAlertsPrimaryColor}
          size={IconSize.regular}
        />
      }
    >
      <Typography weight="semibold" type="body2">
        {getMessage({
          count,
          link: review => (
            <span
              className="par-summary-alerts-banner__link"
              onClick={onReview}
            >
              {review}
            </span>
          ),
        })}
      </Typography>
    </AlertBox>
  );
};

export default SummaryAlertsBanner;
