export enum ActivityType {
  AppsAndWebActivity = 'appsAndWebActivity',
  Top = 'top',
  Questionable = 'questionable',
  BlockedWebs = 'blockedWebs',
  CallsAndSms = 'callsAndSms',
  Search = 'search',
  QuestionableWebSearches = 'questionableWebSearches',
  Youtube = 'youtube',
  TimeSpent = 'timeSpent',
}
