import { EMPTY, merge, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { catchError, switchMap } from 'rxjs/operators';

import { AppAction, AppEpic } from '../../../epics/types';
import { ReceiveSummaryAlertsAction } from '../types/actions.types';
import { receiveSummaryAlertsAction } from '../actions';
import {
  SummaryAlertsPayload,
  SummaryAlertsPlain,
} from '../../../records/summaryAlerts/types/summaryAlerts.type';
import { SummaryAlertsOperations } from '../../../records/summaryAlerts/summaryAlerts';
import { whenIsEnabledBySummaryAlertsFeature } from './helpers';
import { captureException } from '../../../helpers/sentry';
import { showErrorAlert } from '../../../helpers/errorHandling';

export const loadSummaryAlertsEpic: AppEpic<
  AppAction,
  ReceiveSummaryAlertsAction
> = (actions$, state$, { api }) => {
  const requests = actions$.pipe(
    whenIsEnabledBySummaryAlertsFeature(state$),
    ofType('REQUEST_SUMMARY_ALERTS')
  );

  const features = actions$.pipe(
    ofType('RECEIVE_FEATURES'),
    whenIsEnabledBySummaryAlertsFeature(state$)
  );

  const loadActions = merge(requests, features);

  return loadActions.pipe(
    switchMap(action =>
      of(action).pipe(
        switchMap(async () => {
          const resp = await api.summaryAlerts.get<SummaryAlertsPayload>({});
          const record = SummaryAlertsOperations.fromPayload(resp);
          const data = record.toJS() as unknown as SummaryAlertsPlain;
          return receiveSummaryAlertsAction(data);
        }),
        catchError((e: Error) => {
          captureException(e);
          showErrorAlert(e);
          return EMPTY;
        })
      )
    )
  );
};
