import {
  applyTo,
  pipe,
  groupBy,
  prop,
  map,
  sortWith,
  descend,
  ascend,
} from 'ramda';
import { AppRuleRecord } from '../../records/profileRules';
import { List } from 'immutable';

/**
 * This function will group the app list by the apps category
 * and order apps in the same category by the installed data and name
 */
export const groupAppsByCategory = (
  appList: List<AppRuleRecord>
): { [key: string]: AppRuleRecord[] } => {
  return applyTo(
    appList.toArray(),
    pipe(
      groupBy<AppRuleRecord>(prop('categoryId')),
      map(
        sortWith([
          descend((app: AppRuleRecord) =>
            app.appInstalledAt
              ? new Date(app.appInstalledAt).getTime()
              : -Infinity
          ),
          ascend(prop('name')),
        ])
      )
    )
  );
};
