import * as React from 'react';
import { t } from '../../lib/i18n';
import { UrlDetailsPageRecord } from '../../records/urlDetails';
import { Action, isActionBlock, isActionAlert } from '../../records/event';
import Date from '../Date/Date';
import { formatHumanTwelveClockAMPMFormat } from '../../helpers/dates';
import {
  Badge,
  BadgeType,
  FlexLayout,
  FreeStyleListItem,
  List,
  ListColoredText,
  ListSubtitle,
} from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { ListTextColors } from 'styleguide-react/build/components/List/types';
import { getCategoryLabel } from '../../records/category';

const getColor = (action: Action): ListTextColors | null => {
  if (isActionBlock(action)) return 'error';
  if (isActionAlert(action)) return 'warning';
  return 'secondary';
};

const getBadgeType = (action: Action): BadgeType | null => {
  if (isActionBlock(action)) return BadgeType.error;
  if (isActionAlert(action)) return BadgeType.warning;
  return null;
};

const UrlDetailsSiteListItem: React.FunctionComponent<{
  site: UrlDetailsPageRecord;
  dateFormat?: (time, timezone, locale) => string;
  onClickPage: (site: UrlDetailsPageRecord) => any;
}> = ({ site, dateFormat = formatHumanTwelveClockAMPMFormat, onClickPage }) => (
  <FreeStyleListItem key={site.key}>
    <FlexLayout mainaxis="column" marginBottom="8">
      <ListSubtitle testId="urlDetailsSiteListItem-date">
        {site.time ? (
          <span>
            <Date time={site.time} dateFormatCallback={dateFormat} />
            {' - '}
          </span>
        ) : null}
        <span data-testid="ListSubtitle__urlDetailsSiteListItem-title">
          {site.title ? site.title : t('No title')}
        </span>
      </ListSubtitle>
      <ListColoredText
        color={getColor(site.action)}
        testId="urlDetailsSiteListItem-action"
        wordBreak="break-all"
      >
        <a onClick={onClickPage(site)}>{site.url}</a>
      </ListColoredText>
      <List
        horizontalOrientation
        listItemPaddingRight="8"
        listItemMarginBottom="0"
      >
        {site.categories.map(category =>
          getBadgeType(site.action) ? (
            <FreeStyleListItem key={category.id}>
              <Badge
                type={getBadgeType(site.action)}
                text={getCategoryLabel(category.id)}
                testId={`urlDetailsSiteListItem-badge-${category.id}`}
              />
            </FreeStyleListItem>
          ) : null
        )}
      </List>
    </FlexLayout>
  </FreeStyleListItem>
);

export default UrlDetailsSiteListItem;
