import { fromJS } from 'immutable';
import {
  ProfileNotificationTopBarAction,
  ProfileNotificationTopBarState,
} from './types';

export const initialState: ProfileNotificationTopBarState = fromJS({
  isFetching: true,
});

export default function profileNotificationTopBar(
  state: ProfileNotificationTopBarState = initialState,
  action: ProfileNotificationTopBarAction
) {
  switch (action.type) {
    case 'PROFILE_NOTIFICATION_TOP_BAR_LOADING': {
      return state.merge({
        isFetching: true,
      });
    }
    case 'PROFILE_NOTIFICATION_TOP_BAR_LOADED': {
      return state.merge({
        isFetching: false,
      });
    }
    default:
      return state;
  }
}
