import * as React from 'react';
import {
  Button,
  ModalInfo,
  ButtonSize,
  ModalStyledText,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import IconShuffle from '../Icons/IconShuffle';

export type RoutineSwitchInfoModalProps = {
  profileId: string;
  onClickClose: () => void;
  onNavigateToRoutines: (profileId: string) => void;
};

const RoutineSwitchInfoModal = ({
  profileId,
  onClickClose,
  onNavigateToRoutines,
}: RoutineSwitchInfoModalProps) => {
  return (
    <ModalInfo
      testId="RoutineSwitchInfoModal"
      header={{
        icon: <IconShuffle />,
      }}
      title={t('Switch routine')}
      alertBoxText={t('Create a routine to use this feature.')}
      size="medium"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button1"
          onClick={() => onNavigateToRoutines(profileId)}
          size={ButtonSize.medium}
          testId="go-to-routines"
          block
        >
          {t('Go to routines')}
        </Button>,
      ]}
    >
      <ModalStyledText>
        {t(
          'Change your rules at the touch of a button when something unexpected happens - and change them back when you need.'
        )}
      </ModalStyledText>
    </ModalInfo>
  );
};
export default RoutineSwitchInfoModal;
