import * as React from 'react';
import { BoxSelector, FlexLayout, Typography } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { BoxSelectorProps } from 'styleguide-react/src/components/BoxSelector/BoxSelector';

interface WellbeingTherapyPreferencesSelectorProps {
  items: BoxSelectorProps['items'];
  onChangeItemSelection: (value: string, checked: boolean) => void;
}

const WellbeingTherapyPreferencesSelector = ({
  items,
  onChangeItemSelection,
}: WellbeingTherapyPreferencesSelectorProps) => {
  return (
    <FlexLayout mainaxis="column" mainaxisAlignment="center" paddingBottom="72">
      <FlexLayout mainaxis="column" marginBottom="16">
        <Typography type="title1" weight="semibold">
          {t('Well done for taking the first step!')}
        </Typography>
      </FlexLayout>
      <Typography type="body1">
        {t(
          'Tell us how your wellbeing coach can help your family. Select all that apply.'
        )}
      </Typography>
      <FlexLayout mainaxis="column" paddingBottom="32" paddingTop="32">
        <BoxSelector
          handleInputCheckedChange={onChangeItemSelection}
          fieldGroupName="wellbeing-therapy-preferences"
          items={items}
        />
      </FlexLayout>
    </FlexLayout>
  );
};

export default WellbeingTherapyPreferencesSelector;
