import * as React from 'react';
import { t } from '../../lib/i18n';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';
import { IconType } from 'styleguide-react';
import { ISOLanguage } from '../../constants';
import { PremiumFlyoverProps } from '../FullPremiumFlyover/types/FullPremiumFlyoverProps.types';

interface AppRulesFlyoverProps {
  testId: string;
  accountLocale: ISOLanguage;
  actionButton: PremiumFlyoverProps['actionButton'];
  footNote?: PremiumFlyoverProps['footNote'];
  topbarText?: PremiumFlyoverProps['topbarText'];
  alertBoxProps?: PremiumFlyoverProps['alertBoxProps'];
  onClickClose: () => void;
}

const AppRulesFlyover = ({
  testId,
  accountLocale,
  actionButton,
  footNote,
  topbarText,
  alertBoxProps,
  onClickClose,
}: AppRulesFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Games & Apps') }}
      testId={testId}
      topbarText={topbarText}
      title={t('What can you do with games & apps?')}
      imageSrc={`/illustrations/GamesAndApps/premium_games_and_apps__${accountLocale}.png`}
      actionButton={actionButton}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.ban,
          title: t('Block unsafe apps'),
          subtitle: t('Protect your family from inappropriate content'),
        },
        {
          iconType: IconType.stopWatch,
          title: t('Set time limits for games'),
          subtitle: t('Bring balance to your child’s favorite games'),
        },
        {
          iconType: IconType.bell,
          title: t('Receive app alerts'),
          subtitle: t('Know straight away if your child uses a new app'),
        },
      ]}
      footNote={footNote}
      alertBoxProps={alertBoxProps}
    />
  );
};

export default AppRulesFlyover;
