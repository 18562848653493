import { camelCaseToSnakeCase } from '../../api/mappings/case';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import {
  isCombinedDashboard,
  isSchoolDashboardRoute,
} from '../../helpers/routes';
import { getLocationPathname } from '../../selectors';
import State, { Dispatch } from '../../store/state';
import { cancelUpdateNowCardOnFinishState } from './actions';

export const trackQuickActionClick =
  (buttonName: ButtonNames, options?: { [key: string]: string }) =>
  (_dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const trackOptions: { quickAction: boolean; state?: string } = {
      quickAction: true,
      ...options,
    };
    const pageName = getCurrentDashboardPageName(state);
    if (trackOptions.state) {
      trackOptions.state = trackOptions.state.toLowerCase();
    }
    trackButtonClicked(pageName, buttonName, {
      options: JSON.stringify(camelCaseToSnakeCase(trackOptions)),
    });
  };

export const getCurrentDashboardPageName = (state: State) => {
  if (isSchoolDashboardRoute(getLocationPathname(state))) {
    return GenericPageNames.ProfileSchoolActivity;
  }
  if (isCombinedDashboard(getLocationPathname(state))) {
    return GenericPageNames.ProfileUnifiedActivity;
  }
  return GenericPageNames.Profile;
};

export const cancelNowCardUpdateWhenStateFinish = () => dispatch => {
  dispatch(cancelUpdateNowCardOnFinishState());
};
