import * as R from 'ramda';
import * as Moment from 'moment-timezone';
import { t } from '../lib/i18n';
import {
  fetchBlockedWebActivity,
  fetchSummary,
  changeDateRange,
  refreshSelectedDateScreenTime,
  refreshRangeScreenTime,
  fetchAppsAndWebActivity,
  fetchSelectedDateScreenTime,
} from '../ducks/summary';
import {
  fetchCallsAndSmsIfUserIsPremiumOrTrial,
  fetchSearches,
} from '../ducks/socialActivity';
import { Dispatch, BaseThunk } from '../store/state';

import { gaEvent, CONFIRM_YES, confirmDialog } from '../helpers';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import { track, Events } from '../helpers/analytics';

import {
  STATS_NOTIFICATION_DAY_RANGES,
  SummaryDateRanges,
  SummaryExpandedView,
} from '../constants';

import {
  LicenseRecord,
  isFree,
  isPremium,
  getLicenseDaysLeft,
} from '../records/license';

import { fetchNewEvents } from '../ducks/events';
import { updateCurrentTime } from '../ducks/times';
import { fetchProfiles, removeProfile } from '../ducks/profiles';

import { showToast, TOAST_ICON_TICK } from '../ducks/toast';
import { panicPush, fetchDevices } from '../ducks/devices';
import { updateCheckedDay } from '../ducks/profileRules';

import {
  getAccount,
  getDevice,
  getLicense,
  getProfileOrDefault,
  hasDeviceAssigned,
} from '../selectors';
import { getToday } from '../selectors/shared';

import {
  fetchYoutubeSummary,
  fetchYoutubeSummaryDetailLastVideos,
} from '../ducks/youtubeSummary';
import { getFeatureFlag } from '../selectors/featureFlags';

import { pushRelativeLocation } from './Navigation';
import { ActivityType } from '../components/ActivitySummary/sharedModels';
import { fetchExtraTime } from '../ducks/extraTime';
import flags, { Experiment } from '../sideEffects/flags';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { isStudentOptInForDelegationForThisAccount } from '../selectors/studentPolicies';
import { fetchAccount } from '../ducks/account';

export const addDevice =
  (profileId: string): BaseThunk<any> =>
  (dispatch, getState) => {
    const account = getAccount(getState());
    const license = getLicense(getState());
    const navigate = getMultiPlatformNavigation();

    if (account.devicesCount < license.maxDevices) {
      dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/add-device`,
        })
      );
    } else {
      dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/modal/AtLimitModal`,
        })
      );
    }
  };

export const redirectToAddDeviceIfNoDeviceAssignedTo =
  (profileId: number | string): BaseThunk<unknown> =>
  (dispatch, getState) => {
    const state = getState();
    const navigate = getMultiPlatformNavigation();
    const isDelegatedToThisAccount =
      flags.useDelegation.isEnabled() &&
      isStudentOptInForDelegationForThisAccount(state);
    if (
      !hasDeviceAssigned(state, Number(profileId)) &&
      !isDelegatedToThisAccount
    ) {
      return dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/add-device`,
        })
      );
    }
  };

export const refreshProfile = (id: number) => (dispatch, getState) => {
  dispatch(updateCurrentTime());
  const profile = getProfileOrDefault(getState(), id);

  dispatch(fetchProfiles());
  dispatch(fetchSummary(id));
  dispatch(fetchDevices());

  dispatch(refreshSelectedDateScreenTime(profile.uid));
  dispatch(refreshRangeScreenTime(profile.id.toString()));

  return dispatch(fetchNewEvents(profile.uid));
};

export const showStatsNotification = (
  license: LicenseRecord,
  currentTime: Moment.Moment,
  timezone: string
) => {
  const days = getLicenseDaysLeft(license, currentTime, timezone);
  const dayWithinAnyRange = R.any(R.equals(true))(
    STATS_NOTIFICATION_DAY_RANGES.map(
      ([startDay, endDay]) => days >= startDay && days <= endDay
    )
  );

  return isPremium(license.type) && dayWithinAnyRange;
};

export const logStatsNotificationEvent =
  (event: string) =>
  (
    // eslint-disable-next-line no-underscore-dangle
    _dispatch: Dispatch,
    getState
  ) => {
    const account = getAccount(getState());
    const accountId = account.get('id');
    const locale = account.get('locale');
    const country = account.get('country');
    gaEvent(
      'recognition-pa-topbar',
      event,
      [locale, country, accountId].join(',')
    );
  };

type TimeRoulesRoutes = 'restrictedTimes' | 'dailyTimeLimit';
export const pushTimeRules =
  (profileId, timeRulesRoute: TimeRoulesRoutes) => (dispatch, getState) => {
    const profile = getProfileOrDefault(getState(), parseInt(profileId, 10));
    const navigate = getMultiPlatformNavigation();

    return dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${
          profile.id
        }/rules/${timeRulesRoute}/devices/${profile.deviceIds.first()}`,
      })
    );
  };

export const setTimeLimit =
  (profileId: string) => (dispatch: Dispatch, getState) => {
    dispatch(updateCheckedDay(getToday(getState())));
    dispatch(pushTimeRules(profileId, 'dailyTimeLimit'));
  };

export const turnOffPanic =
  (deviceId): BaseThunk<any> =>
  (dispatch, getState) =>
    Promise.resolve(getDevice(getState(), deviceId))
      .then(device => panicPush(device!.uid).then(R.always(device)))
      .then(device =>
        dispatch(
          showToast(
            t('Panic mode has been turned off on {{deviceName}}', {
              deviceName: device!.name,
            }),
            TOAST_ICON_TICK
          )
        )
      );

export const selectRemoveProfileMethod = id => dispatch =>
  dispatch(removeProfile(id));

export const onConfirmDeleteProfile = id => dispatch =>
  dispatch(selectRemoveProfileMethod(id))
    /** After removing a profile, we refetch user account to get the updated profile count. */
    .then(() => dispatch(fetchAccount()))
    .then(() => {
      const navigate = getMultiPlatformNavigation();
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: '/',
        })
      );
    })
    .catch((error: AnyError) => handleApiErrorMessage(error));

export const confirmDeleteProfile = id => dispatch =>
  confirmDialog(
    t('Remove child profile?'),
    t(
      'This user will no longer be supervised and all their activity data will be deleted.'
    ),
    [t('Remove'), t('Cancel')]
  ).then(button =>
    button === CONFIRM_YES ? dispatch(onConfirmDeleteProfile(id)) : undefined
  );

export const selectDateRange =
  (profileId, range: SummaryDateRanges) => (dispatch, getState) => {
    sendDateRangeGAEvent(range);
    const navigate = getMultiPlatformNavigation();
    if (
      !isRangeAvailableForFreeUsers(range) &&
      isFree(getLicense(getState()).type)
    ) {
      dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/premiumSummaryFlyover`,
        })
      );
    } else {
      dispatch(changeDateRange(range));
    }
  };

const isRangeAvailableForFreeUsers = (range: SummaryDateRanges) =>
  range === SummaryDateRanges.Today || range === SummaryDateRanges.Days7;

const sendDateRangeGAEvent = (range: SummaryDateRanges) => {
  const gaLabelMap = {
    [SummaryDateRanges.Today]: 'today-tab',
    [SummaryDateRanges.Days7]: '7-days-tab',
    [SummaryDateRanges.Days15]: '15-days-tab',
    [SummaryDateRanges.Days30]: '30-days-tab',
    [SummaryDateRanges.CustomPastDay]: 'CalendarPicker-tab',
  };
  return gaEvent('kid-profile-time-selector', 'click', gaLabelMap[range]);
};

export const navigateToTopActivity = profileId => dispatch => {
  gaEvent('kid-profile-summary', 'click', 'see_top_activity_events');
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/top-activity`,
    })
  );
};

export const navigateToBlockedWebActivity = profileId => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/questionable-activity`,
    })
  );
};

export const navigateToScreenTimeDetails = profileId => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/screen-time`,
    })
  );
};

export const navigateToCallsAndSmsActivity = profileId => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/calls-and-sms-activity`,
    })
  );
};

export const navigateToSearchActivity = profileId => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/search-activity`,
    })
  );
};

export const navigateToYoutubeActivity = profileId => dispatch => {
  gaEvent('youtube-activity-summary', 'click', 'see_youtube_activity');
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/youtube-activity`,
    })
  );
};

export const selectedNewDateRangeForProfile =
  (
    range: SummaryDateRanges,
    profileId: string,
    expandedView: SummaryExpandedView = SummaryExpandedView.NotExpanded
  ) =>
  (dispatch, getState) => {
    dispatch(selectDateRange(profileId, range));
    if (range === SummaryDateRanges.CustomPastDay) {
      track(Events.ClickedCalendarPicker, {
        isFreeUser: isFree(getLicense(getState()).type),
      });
    }

    const profile = getProfileOrDefault(getState(), parseInt(profileId, 10));
    dispatch(fetchAppsAndWebActivity(profile.uid));
    dispatch(fetchBlockedWebActivity(profile.uid));
    dispatch(fetchYoutubeSummary(profile.uid));
    dispatch(fetchCallsAndSmsIfUserIsPremiumOrTrial(profile.uid));
    dispatch(fetchSearches(profile.uid));
    if (range === SummaryDateRanges.Today) {
      dispatch(fetchExtraTime(profile.uid));
    }
    if (
      [SummaryDateRanges.Today, SummaryDateRanges.CustomPastDay].includes(range)
    ) {
      dispatch(fetchSelectedDateScreenTime(profile.uid));
    }

    if (expandedView === SummaryExpandedView.YoutubeActivity) {
      dispatch(fetchYoutubeSummaryDetailLastVideos(profile.uid));
    }
  };

export const openSummaryCardInfoModalFor =
  (type: ActivityType): BaseThunk<void> =>
  dispatch => {
    dispatch(pushRelativeLocation(`modal/SummaryCardInfoModal?type=${type}`));
  };

export const navigateToAddDeviceAction = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: '/devices/add',
  });
};

export const addProfileClickHandler = () => (dispatch, getState) => {
  track(Events.ClickedAddProfile);
  const navigate = getMultiPlatformNavigation();

  const variant = getFeatureFlag(
    getState(),
    'kidDeviceSetupFromParentsApp'
  ) as Experiment;
  if ([Experiment.VariantA, Experiment.VariantC].includes(variant)) {
    return dispatch(navigateToAddDeviceAction());
  }

  return dispatch(
    navigate({
      type: 'inner',
      src: '/profiles/add',
    })
  );
};
