import * as React from 'react';
import { t } from '../../lib/i18n';
import * as Moment from 'moment-timezone';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  Button,
  ButtonSize,
  Dropdown,
  DropdownMenuPlacement,
  DropdownOption,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  Label,
  TextField,
} from 'styleguide-react';
import {
  filterTimezonesByString,
  getAllTimezones,
} from '../../helpers/languages';
import PageLayout from '../Layout/PageLayout';

const EditTimezone: React.FunctionComponent<{
  timezone: string;
  currentTime: Moment.Moment;
  onClickSaveButton: (timezone: string) => void;
}> = ({ timezone, currentTime, onClickSaveButton }) => {
  return (
    <PageLayout
      title={t('Edit Timezone')}
      actions={[{ actionName: 'back' }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
        },
      ]}
    >
      <EditTimezoneFields
        timezone={timezone}
        currentTime={currentTime}
        onClickSaveButton={onClickSaveButton}
      />
    </PageLayout>
  );
};

export const EditTimezoneFields: React.FunctionComponent<{
  timezone: string;
  currentTime: Moment.Moment;
  onClickSaveButton: (timezone: string) => void;
}> = ({ timezone, currentTime, onClickSaveButton }) => {
  const [selectedTimezone, setSelectedTimezone] = React.useState(timezone);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-between"
      flexGrow="2"
      matchParentHeight
    >
      <FlexLayout mainaxis="column">
        <FlexLayout mainaxis="column" marginBottom="8">
          <Label>{t('Edit Timezone')}</Label>
        </FlexLayout>
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <DropdownEditTimezone
            maxHeight={145}
            timezone={timezone}
            selectedTimezone={selectedTimezone}
            currentTime={currentTime}
            setSelectedTimezone={setSelectedTimezone}
            setButtonDisabled={setButtonDisabled}
          />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <DropdownEditTimezone
            maxHeight={230}
            timezone={timezone}
            selectedTimezone={selectedTimezone}
            currentTime={currentTime}
            setSelectedTimezone={setSelectedTimezone}
            setButtonDisabled={setButtonDisabled}
          />
        </RenderWhen>
      </FlexLayout>
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          centered
          htmlType="submit"
          size={ButtonSize.medium}
          onClick={() => onClickSaveButton(selectedTimezone)}
          testId="saveEditTimezone"
          disabled={buttonDisabled}
        >
          {t('Save')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

const DropdownEditTimezone: React.FunctionComponent<{
  maxHeight: number;
  timezone: string;
  selectedTimezone: string;
  currentTime: Moment.Moment;
  setSelectedTimezone: (value) => void;
  setButtonDisabled: (value) => void;
}> = ({
  maxHeight,
  timezone,
  selectedTimezone,
  currentTime,
  setSelectedTimezone,
  setButtonDisabled,
}) => {
  const [textToFilterBy, setTextToFilterBy] = React.useState('');
  const [dropdownIsOpen, setDropdownOpeness] = React.useState(false);

  const handleChange = value => {
    setTextToFilterBy('');
    setSelectedTimezone(value);
    setButtonDisabled(false);
  };
  const handleTextChange = ev => {
    setTextToFilterBy(ev.target.value);
    setButtonDisabled(true);
  };
  const handleIsDropdownOpen = newDropdownOpeness => {
    if (!dropdownIsOpen) {
      setTextToFilterBy('');
    }
    setDropdownOpeness(newDropdownOpeness);
  };
  const getSelectedTimezoneValue = () => {
    if (!dropdownIsOpen) {
      if (selectedTimezone) {
        const timezoneIndex = getAllTimezones(currentTime).findIndex(
          timezone => {
            return timezone[0] === selectedTimezone;
          }
        );
        if (timezoneIndex !== -1) {
          setButtonDisabled(false);
          const newTimezone = getAllTimezones(currentTime)[timezoneIndex];
          return `(${newTimezone[1]}) ${newTimezone[2]}`;
        }
        return '';
      }
      return '';
    }
    return textToFilterBy;
  };
  return (
    <Dropdown
      actionElement={
        <TextField
          type="text"
          name={timezone}
          onChange={handleTextChange}
          value={getSelectedTimezoneValue()}
          testId="editTimeZone"
          block
          iconLeft={<Icon type={IconType.search} color={IconColor.primary} />}
          keepErrorLabelArea={false}
        />
      }
      onChange={handleChange}
      onActiveChange={handleIsDropdownOpen}
      testId="timezones"
      maxHeight={maxHeight}
      menuPosition={DropdownMenuPlacement.bottom}
      fixedMenuPlacement
    >
      {filterTimezonesByString(textToFilterBy, currentTime).map(timezones => (
        <DropdownOption
          text={`(${timezones[1]}) ${timezones[2]}`}
          value={timezones[0]}
          selected={timezones[0] === timezone}
          key={timezones[0]}
        />
      ))}
    </Dropdown>
  );
};

export default EditTimezone;
