import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  Icon,
  IconColor,
  ModalStyledText,
  IconFamily,
  IconType,
  ButtonType,
} from 'styleguide-react';

import { modalDimensions } from '../../constants/index';
import type { EditEmailConfirmationModalProps } from '../../containers/Modal/EditEmailConfirmationModalContainer';
import { t } from '../../lib/i18n';

const EditEmailConfirmationModal = ({
  enableConfirmAction,
  onConfirm,
  onCancel,
}: EditEmailConfirmationModalProps) => (
  <Modal
    testId="EditEmailConfirmation"
    title={`${t('Change email')}?`}
    header={{
      icon: (
        <Icon
          type={IconType.exclamationTriangle}
          family={IconFamily.solid}
          color={IconColor.error}
        />
      ),
    }}
    onClickClose={onCancel}
    height={modalDimensions.regular.height}
    width={modalDimensions.regular.width}
    buttons={[
      <Button
        key="changeEmail"
        onClick={enableConfirmAction ? onConfirm : undefined}
        size={ButtonSize.medium}
        block
        testId="changeEmail"
        disabled={!enableConfirmAction}
      >
        {t('Change email')}
      </Button>,
      <Button
        block
        buttonType={ButtonType.secondary}
        key="closeModal"
        size={ButtonSize.medium}
        onClick={onCancel}
        testId="closeModal"
      >
        {t('Cancel')}
      </Button>,
    ]}
  >
    <ModalStyledText>
      {t(
        'Your school connection will be deleted and you’ll no longer have visibility on any school devices.'
      )}
    </ModalStyledText>
  </Modal>
);

export default EditEmailConfirmationModal;
