import * as R from 'ramda';
import { getAccountLocale } from '../selectors/account';
import { t } from '../lib/i18n';
import {
  AccountActions,
  ACTION_VIEW_DETAILS,
  ACTION_VIEW_YOUTUBE_SETTINGS,
  ACTION_VISIT,
  APPLICATION_ALLOWED,
  CALLSMS_EDIT_INCOMING_CALLS,
  CALLSMS_EDIT_OUTGOING_CALLS,
  CALLSMS_RULES_ALLOW,
  CALLSMS_RULES_REMOVE,
  CallsSmsEditOption,
  EDIT_CATEGORY_ALL,
  PANIC_ACTION_SEE_CONTACTS,
  PANIC_ACTION_SEE_DETAILS,
  PANIC_ACTION_STOP,
  PANIC_RULES_ALLOW,
  PANIC_RULES_DISABLE,
  PANIC_RULES_MAX_CONTACTS,
  PANIC_RULES_REMOVE,
  VideoActions,
  WEBFILTER_REMOVE,
  WebfilterCategoryAction,
  ApplicationAction,
  WEBFILTER_ALERT,
} from '../constants/index';
import {
  getAppRulesApplicationList,
  getCallsSMSActiveContact,
  getCallsSMSContactByPhone,
  getCallsSMSContacts,
  getEditCategory,
  getEditDomainException,
  getLicense,
  getPanicRulesSelectedContact,
  getProfileId,
  getProfileRules,
  getShowEventActions,
} from '../selectors/index';
import {
  getProfileDesktopDelegatedDevices,
  getProfileOrDefault,
} from '../selectors/profileDevices';
import { getPanicRulesContacts } from '../selectors/profileRules';
import { goToAppRulesModal } from './AppRulesActions';
import { getPanicLink, showEventActions } from '../ducks/events';
import { showToast, TOAST_ICON_TICK } from '../ducks/toast';

import { dispatchModifyProfileRules } from './ProfileRulesActions';

import {
  CONFIRM_YES,
  confirmDialog,
  gaEvent,
  isBrowserPlatform,
  tapReject,
} from '../helpers';
import {
  AppRuleNames,
  CallsAndSMSRuleNames,
  Events,
  track,
  trackAppRuleUpdate,
  trackCallsAndSMSRule,
  trackWebRules,
  WebRuleNames,
} from '../helpers/analytics';
import {
  AppRuleRecord,
  CallsSmsContactRecord,
  updateContact,
  WebCategoryRecord,
} from '../records/profileRules';
import {
  delayedUpdateProfileRules,
  editCategory,
  editDomainException,
  setCallsSMSActiveContact,
  setCallsSMSBlockingOption,
} from '../ducks/profileRules';
import { LicenseRecord } from '../records';
import { isFree } from '../records/license';
import { turnOffPanic } from './ProfileActions';
import { BaseThunk } from '../store/state';
import { deleteContact, setSelectedContact } from '../ducks/contacts';
import { videoRuleSheetClick } from './UrlDetailsActions';
import {
  navigateToUrlDetails,
  shouldShowUnsupportedBrowsersBlockedModal,
} from './actionSheetActionHelpers';
import {
  navigateToExternalUrl,
  showYoutubeSummaryDetailsActions,
} from '../ducks/youtubeSummary';
import { VideoDetailsRecord } from '../records/urlDetails';
import { setAnyRuleInTimeline } from '../ducks/appRate';
import {
  shouldShowIosPreConsentTrackingModal,
  showInAppRatingPopupForCurrentScenario,
  showIosPreConsentTrackingModalForCurrentScenario,
} from '../ducks/scenarios/scenarios';
import { logout } from '../sideEffects/logout';
import * as qinit from '../qinit';
import { simpleTemplate } from '../helpers/string';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { getDefaultQuotaValues } from '../businessLogic/timeRules/timeQuota';
import { Map } from 'immutable';
import {
  showSetupAlertsModal,
  showUnlockCompleteModal,
} from './PremiumFeaturesActions';
import { shouldBlockThisFeature } from '../helpers/premiumFeatures';
import { isFreeOrPremiumBasic } from '../records/license/helpers';
import { SetupAlertsModalReferrer } from '../containers/Modal/SetupAlertsModalContainer';
import { isSchoolLicenseWithValidDevices } from '../businessLogic/profileRules/delegation';
import { ruleNeedsUpgrade } from '../businessLogic/rules/features';
import { notifyLogoutAction } from '../epics/contentCommunication/actions';
import { getFeatureByCode } from '../ducks/features';

export const accountActionsClick =
  (action?: AccountActions): BaseThunk<void> =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    switch (action) {
      case AccountActions.carePlus:
        dispatch(
          navigate({
            type: 'external',
            src: simpleTemplate(
              qinit.tenant.marketing.public_site.care_plus_contact,
              {
                lang: getAccountLocale(getState()),
              }
            ),
          })
        );
        break;
      case AccountActions.helpCenter:
        track(Events.ClickedHelpCenter);
        dispatch(
          navigate({
            type: 'external',
            src: qinit.tenant.marketing.public_site.general_help_link,
          })
        );
        break;
      case AccountActions.logout:
        track(Events.ClickedLogout);
        if (isBrowserPlatform()) {
          dispatch(notifyLogoutAction());
          dispatch(logout());
        } else {
          dispatch(
            navigate({
              type: 'inner',
              src: '/logout/modal/LogoutModal',
            })
          );
        }
        break;
      default:
        break;
    }
  };

export const appEventActionSheetClick =
  (action?: string): BaseThunk<any> =>
  (dispatch, getState) => {
    const quotaFromAction = (action: ApplicationAction) =>
      action === APPLICATION_ALLOWED ? 'full' : 'empty';

    const updateAppRule = (state, profileId: string, action) => appIndex => {
      const profileRules = getProfileRules(state, profileId);
      const app = profileRules.getIn(['appRules', 'applicationList', appIndex]);
      return profileRules.setIn(
        ['appRules', 'applicationList', appIndex],
        app
          .set('action', action)
          .set('quotas', Map(getDefaultQuotaValues(quotaFromAction(action))))
      );
    };

    const createAppRule = (state, profileId, action, exe, platform) => () =>
      getProfileRules(state, profileId).setIn(
        ['appRules', 'applicationList'],
        getAppRulesApplicationList(state, profileId)?.push(
          AppRuleRecord({
            exe,
            platform,
            action,
            quotas: Map(getDefaultQuotaValues(quotaFromAction(action))),
          }) as AppRuleRecord
        )
      );

    const state = getState();
    const profileId = getProfileId(state);
    const event = getShowEventActions(state);
    const navigate = getMultiPlatformNavigation();

    dispatch(showEventActions(undefined));

    if (!action || !profileId || !event) {
      return;
    }

    if (
      isFree(getLicense(state).type) &&
      !isSchoolLicenseWithValidDevices(state, profileId)
    ) {
      dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/rules/appRules/premiumFlyover`,
        })
      );
      return;
    }
    if (
      shouldShowUnsupportedBrowsersBlockedModal(
        state,
        profileId,
        event.appName,
        event.platform,
        action
      )
    ) {
      dispatch(goToAppRulesModal(profileId));
      return;
    }

    return dispatch(
      dispatchModifyProfileRules(profileId, (state, profileId) =>
        R.pipe(
          // find app index
          () =>
            getAppRulesApplicationList(state, profileId)?.findIndex(
              appItem =>
                appItem.exe === event.appName &&
                appItem.platform === event.platform
            ),
          // create/update appRule
          R.ifElse(
            R.gt(0), // appIndex is -1 when app is not contained in appRules
            createAppRule(
              state,
              profileId,
              action,
              event.appName,
              event.platform
            ),
            updateAppRule(state, profileId, action)
          )
        )()
      )
    ).then(() => {
      trackAppRuleUpdate(
        action === APPLICATION_ALLOWED
          ? AppRuleNames.AllowApp
          : AppRuleNames.BlockApp
      );
      return dispatch(
        showToast(
          t('App rules updated for {{appName}}', {
            appName: event.name || event.appName,
          }),
          TOAST_ICON_TICK
        )
      );
    });
  };

export const webEventActionSheetClickWithMobileActions = action => dispatch => {
  dispatch(webEventActionSheetClick(action));
  return dispatch(showModalUnderRightConditions(action));
};

export const callsAndSMSEventActionSheetClickWithMobileActions =
  action => dispatch => {
    dispatch(callsAndSMSEventActionSheetClick(action));
    return dispatch(showModalUnderRightConditions(action));
  };

export const appEventActionSheetClickWithMobileActions = action => dispatch => {
  dispatch(appEventActionSheetClick(action));
  return dispatch(showModalUnderRightConditions(action));
};

export const panicEventContactActionSheetClickWithMobileActions =
  action => dispatch => {
    dispatch(panicEventContactActionSheetClick(action));
    return dispatch(showModalUnderRightConditions(action));
  };

const showModalUnderRightConditions =
  (action: any): BaseThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (isBrowserPlatform()) return;

    if (!action) {
      return;
    }
    dispatch(setAnyRuleInTimeline());

    if (await shouldShowIosPreConsentTrackingModal(getState())) {
      dispatch(showIosPreConsentTrackingModalForCurrentScenario());
    } else {
      dispatch(showInAppRatingPopupForCurrentScenario());
    }
  };

export const webEventActionSheetClick = action => (dispatch, getState) => {
  const state = getState();
  const event = getShowEventActions(state);
  const profileId = getProfileId(state);
  const navigate = getMultiPlatformNavigation();
  const license = getLicense(state);
  const hasPCDelegatedDevices = getProfileDesktopDelegatedDevices(
    state,
    profileId
  );

  dispatch(showEventActions(undefined));
  if (!action || !profileId || !event) {
    return;
  }

  if (
    action === WEBFILTER_ALERT &&
    ruleNeedsUpgrade(getFeatureByCode(state, 'web_domain_exceptions_alert'))
  ) {
    return dispatch(
      showSetupAlertsModal(
        SetupAlertsModalReferrer.webFilteringAlertMeException
      )
    );
  }

  if (action === ACTION_VISIT) {
    track(Events.ClickedWebActionVisit);
    dispatch(
      navigate({
        type: 'external',
        src: event.url,
      })
    );
    return;
  }
  if (action === ACTION_VIEW_DETAILS) {
    if (
      shouldBlockThisFeature('youtubeRules', license.subtype) &&
      !hasPCDelegatedDevices
    ) {
      return dispatch(showUnlockCompleteModal());
    }
    track(Events.ClickedWebActionViewDetails);
    dispatch(navigateToUrlDetails(profileId, event.key));
    return;
  }
  if (action === ACTION_VIEW_YOUTUBE_SETTINGS) {
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/youtube-rules`,
      })
    );
    return;
  }

  dispatch(
    dispatchModifyProfileRules(profileId, (state, profileId) =>
      getProfileRules(state, profileId).setIn(
        ['web', 'domains', event.host],
        action
      )
    )
  ).then(() => {
    trackWebRules(WebRuleNames.WEBSITE_EXCEPTION_CHANGE);
    dispatch(
      showToast(
        t('{{domain}} rule updated and added to your exception list', {
          domain: event.host,
        }),
        TOAST_ICON_TICK
      )
    );
  });
};
// (domain, action) ->  (state, profileId) -> profileRules
const updateDomainException = (domain, action) => (state, profileId) => {
  if (action === WEBFILTER_REMOVE) {
    return getProfileRules(state, profileId).deleteIn([
      'web',
      'domains',
      domain,
    ]);
  }
  return getProfileRules(state, profileId).setIn(
    ['web', 'domains', domain],
    action
  );
};

export const webExceptionActionSheetClick = action => (dispatch, getState) => {
  const state = getState();
  const domain = getEditDomainException(state);

  if (
    action === WEBFILTER_ALERT &&
    ruleNeedsUpgrade(getFeatureByCode(state, 'web_domain_exceptions_alert'))
  ) {
    return dispatch(
      showSetupAlertsModal(
        SetupAlertsModalReferrer.webFilteringAlertMeException
      )
    );
  }

  dispatch(editDomainException(undefined));
  trackWebRules(WebRuleNames.WEBSITE_EXCEPTION_CHANGE);

  const profileId = getProfileId(state);

  if (!profileId) {
    return;
  }

  dispatch(
    dispatchModifyProfileRules(profileId, updateDomainException(domain, action))
  )
    .then(R.always(action))
    .then(
      R.when(R.equals(WEBFILTER_REMOVE), () =>
        dispatch(
          showToast(t('Exception successfully removed'), TOAST_ICON_TICK)
        )
      )
    );
};

const getEditCategoryRuleName = (
  action: WebfilterCategoryAction
): WebRuleNames | undefined => {
  switch (action) {
    case WebfilterCategoryAction.WEBFILTER_CATEGORY_ALLOW:
      return WebRuleNames.ALLOW_CATEGORY;
    case WebfilterCategoryAction.WEBFILTER_CATEGORY_BLOCK:
      return WebRuleNames.BLOCK_CATEGORY;
    case WebfilterCategoryAction.WEBFILTER_CATEGORY_ALERT:
      return WebRuleNames.ALERT_CATEGORY;
    default:
      return undefined;
  }
};

const updateCategories = updatedCategories => (state, profileId) =>
  getProfileRules(state, profileId).withMutations(rules => {
    const updateCategory = categories => category => {
      const index = categories.findIndex(
        R.propEq('category', category.category)
      );
      return index !== -1
        ? categories.set(index, category)
        : categories.push(category);
    };

    updatedCategories.forEach(category => {
      rules.setIn(
        ['web', 'categories'],
        updateCategory(rules.web.categories)(category)
      );
    });
  });

const editCategories =
  (profileId, categories, action) => (dispatch, getState) => {
    const isBulkEdit = getEditCategory(getState()) === EDIT_CATEGORY_ALL;
    dispatch(editCategory());
    const promise = dispatch(
      dispatchModifyProfileRules(
        profileId,
        updateCategories(
          categories.map(category => WebCategoryRecord({ category, action }))
        )
      )
    );
    return isBulkEdit
      ? promise
          .then(gaEvent('web-filter', 'change-all', 'success'))
          .catch(tapReject(() => gaEvent('web-filter', 'change-all', 'error')))
      : promise;
  };

export const webCategoryActionSheetClickInRules =
  (action, categories?) => (dispatch, getState) => {
    const state = getState();
    if (
      action === WEBFILTER_ALERT &&
      ruleNeedsUpgrade(getFeatureByCode(state, 'web_category_alert'))
    ) {
      return dispatch(
        showSetupAlertsModal(
          SetupAlertsModalReferrer.webFilteringAlertMeCategory
        )
      );
    }

    const editCategoryRuleName = getEditCategoryRuleName(action);
    if (editCategoryRuleName) trackWebRules(editCategoryRuleName);

    const selectedCategories = categories || [getEditCategory(state).category];

    dispatch(editCategories(getProfileId(state), selectedCategories, action));
  };

export const panicEventContactActionSheetClick =
  action => (dispatch, getState) => {
    const state = getState();
    const event = getShowEventActions(state);
    const profileId = getProfileId(state);
    const navigate = getMultiPlatformNavigation();

    dispatch(showEventActions(undefined));

    if (!action || !profileId || !event) {
      return;
    }

    if (action === PANIC_ACTION_SEE_DETAILS) {
      dispatch(
        navigate({
          type: 'external',
          src: dispatch(getPanicLink(profileId, event)),
        })
      );
    } else if (action === PANIC_ACTION_SEE_CONTACTS) {
      dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${profileId}/rules/panicButtonRules`,
        })
      );
    } else if (action === PANIC_ACTION_STOP) {
      confirmDialog(
        t('Turn off panic mode'),
        t(
          // tslint:disable-next-line:max-line-length
          'If you turn off panic mode, all trusted contacts for this profile will be notified that this alert has ended. Are you sure you want to do this?'
        ),
        [t('Yes'), t('Cancel')]
      ).then(button =>
        button === CONFIRM_YES
          ? dispatch(turnOffPanic(event.deviceId))
          : undefined
      );
    }
  };

// As PANIC_RULES_MAX_CONTACTS includes the account holder contact,
// we need to check one less contact when comparing the value
export const checkMaxContacts = (state, profileId, fn) =>
  getPanicRulesContacts(state, profileId).size < PANIC_RULES_MAX_CONTACTS - 1
    ? fn()
    : (<any>navigator).notification.alert(
        t(
          // tslint:disable-next-line:max-line-length
          'You already have {{maxContacts}} trusted contacts activated for {{name}}. To add new one, first disable or remove an existing contact.',
          {
            maxContacts: PANIC_RULES_MAX_CONTACTS,
            name: getProfileOrDefault(state, profileId).name,
          }
        ),
        () => undefined,
        t("You're maxed out!"),
        t('I got it')
      );

const enableContact = (profileId, contact) => (dispatch, getState) =>
  checkMaxContacts(getState(), profileId, () =>
    dispatch(
      delayedUpdateProfileRules(profileId, profileRules =>
        profileRules.setIn(
          ['panic', 'contacts'],
          getPanicRulesContacts(getState(), profileId).push(contact)
        )
      )
    )
  );

export const unlinkContactFromProfile =
  (profileId, contact) => (dispatch, getState) =>
    dispatch(
      delayedUpdateProfileRules(profileId, profileRules =>
        profileRules.setIn(
          ['panic', 'contacts'],
          getPanicRulesContacts(getState(), profileId).filter(
            R.complement(R.propEq('contact', contact.contact))
          )
        )
      )
    );

const removeContact = (profileId, contact) => dispatch => {
  dispatch(unlinkContactFromProfile(profileId, contact));
  dispatch(deleteContact(contact));
};

export const panicContactActionSheetClick = action => (dispatch, getState) => {
  const state = getState();
  const profileId = getProfileId(state);
  const contact = getPanicRulesSelectedContact(state);

  dispatch(setSelectedContact()); // hides action sheet

  if (!profileId) {
    return;
  }

  return R.cond<any, Function>([
    [
      R.equals(PANIC_RULES_ALLOW),
      () => dispatch(enableContact(profileId, contact)),
    ],
    [
      R.equals(PANIC_RULES_DISABLE),
      () => dispatch(unlinkContactFromProfile(profileId, contact)),
    ],
    [
      R.equals(PANIC_RULES_REMOVE),
      () => dispatch(removeContact(profileId, contact)),
    ],
  ])(action);
};

export const callsAndSMSEventActionSheetClick =
  action => (dispatch, getState) => {
    const hasPremiumRuleAccess = (license: LicenseRecord) =>
      !isFreeOrPremiumBasic(license.subtype);

    const state = getState();
    const event = getShowEventActions(state);
    const profileId = getProfileId(state);
    const navigate = getMultiPlatformNavigation();

    dispatch(showEventActions(undefined));

    if (!action || !profileId || !event) {
      return;
    }

    trackCallsAndSMSRule(CallsAndSMSRuleNames.INDIVIDUAL_PHONE_NUMBERS_CHANGE);

    dispatch((dispatch, getState) => {
      if (!hasPremiumRuleAccess(getLicense(getState()))) {
        dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${profileId}/rules/callsSMSRules/premiumFlyover`,
          })
        );
        return;
      }
      dispatch((dispatch, getState) => {
        const contact = R.defaultTo(
          CallsSmsContactRecord({
            name: event.name === event.socialContactId ? '' : event.name || '',
            phone: event.socialContactId,
          }),
          getCallsSMSContactByPhone(
            getState(),
            profileId,
            event.socialContactId
          )
        ).set('mode', action);
        const updatedContactList = getCallsSMSContacts(getState(), profileId)!
          .filter(R.complement(R.propEq('phone', contact.phone)))
          .push(contact);
        dispatch(
          delayedUpdateProfileRules(profileId, profileRules =>
            profileRules.setIn(
              ['callSmsMonitoring', 'contactBlock'],
              updatedContactList
            )
          )
        ).then(() =>
          dispatch(
            showToast(
              (
                t(
                  'Calls with {{name}} will always be {1}allowed{/1}{2}blocked{/2}.',
                  {
                    name: R.isEmpty(contact.name)
                      ? contact.phone
                      : contact.name,
                    1: allowedStr =>
                      action === CALLSMS_RULES_ALLOW ? allowedStr : '',
                    2: blockedStr =>
                      action !== CALLSMS_RULES_ALLOW ? blockedStr : '',
                  }
                ) as any
              ).join(''), // without join we would have phrases in separated html paragraphs inside the toast
              TOAST_ICON_TICK
            )
          )
        );
      });
    });
  };

export const toggleCallsSMSRule = (toggleOn, rule) => (state, profileId) =>
  getProfileRules(state, profileId).setIn(
    ['callSmsMonitoring', rule],
    toggleOn
  );

const trackChangeBlockingOptionRuleName = (
  option: CallsSmsEditOption,
  enable: boolean
): void => {
  switch (option) {
    case CALLSMS_EDIT_INCOMING_CALLS:
      trackCallsAndSMSRule(
        enable
          ? CallsAndSMSRuleNames.ENABLE_BLOCK_ALL_INCOMING_CALLS
          : CallsAndSMSRuleNames.DISABLE_BLOCK_ALL_INCOMING_CALLS
      );
      break;
    case CALLSMS_EDIT_OUTGOING_CALLS:
      trackCallsAndSMSRule(
        enable
          ? CallsAndSMSRuleNames.ENABLE_BLOCK_ALL_OUTGOING_CALLS
          : CallsAndSMSRuleNames.DISABLE_BLOCK_ALL_OUTGOING_CALLS
      );
      break;
    default:
      break;
  }
};

export const callsAndSMSRuleActionSheetClick =
  (value, option) => (dispatch, getState) => {
    dispatch(setCallsSMSBlockingOption());
    trackChangeBlockingOptionRuleName(option, value);

    const profileId = getProfileId(getState());

    if (!profileId) {
      return;
    }

    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleCallsSMSRule(
          value,
          R.cond<any, string>([
            [
              R.equals(CALLSMS_EDIT_INCOMING_CALLS),
              R.always('incomingCallsBlock'),
            ],
            [
              R.equals(CALLSMS_EDIT_OUTGOING_CALLS),
              R.always('outgoingCallsBlock'),
            ],
          ])(option)
        )
      )
    );
  };

export const callsAndSMSExceptionActionSheetClick =
  action => (dispatch, getState) => {
    trackCallsAndSMSRule(CallsAndSMSRuleNames.INDIVIDUAL_PHONE_NUMBERS_CHANGE);

    const state = getState();
    const contact = getCallsSMSActiveContact(state);
    const profileId = getProfileId(state);

    if (!action || !profileId || !contact) {
      return dispatch(setCallsSMSActiveContact(null));
    }

    dispatch(
      delayedUpdateProfileRules(profileId, updateContact(contact, action))
    ).then(
      R.when(R.always(action === CALLSMS_RULES_REMOVE), () =>
        dispatch(
          showToast(
            t('Calling rule removed for {{name}}.', {
              name: contact.name,
            }),
            TOAST_ICON_TICK
          )
        )
      )
    );
    dispatch(setCallsSMSActiveContact(null));
  };

export const youtubeDetailsActionSheetClick =
  ({ action, event, eventKey }) =>
  (dispatch, getState) => {
    const profileId = getProfileId(getState());

    if (!profileId) {
      return dispatch(videoRuleSheetClick(eventKey));
    }

    return dispatch(videoRuleSheetClick(eventKey, profileId, event, action));
  };

export const youtubeSummaryActionSheetClick =
  (action: VideoActions, detail?: VideoDetailsRecord): BaseThunk<any> =>
  dispatch => {
    dispatch(showYoutubeSummaryDetailsActions(undefined));
    if (!detail || action === VideoActions.Unknown) return;
    if (action === VideoActions.Play) {
      dispatch(navigateToExternalUrl(detail));
    }
  };

export const safeNetworkEventSheetClickWithMobileActions =
  action => dispatch => {
    dispatch(safeNetworkEventSheetClick(action));
    return dispatch(showModalUnderRightConditions(action));
  };

export const safeNetworkEventSheetClick = (action): BaseThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const event = getShowEventActions(state);
    const profileId = getProfileId(state);

    dispatch(showEventActions(undefined));

    if (!action || !profileId || !event) {
      return;
    }

    if (action === ACTION_VIEW_DETAILS) {
      track(Events.ClickedSafeNetworkViewDetails);
      dispatch(navigateToUrlDetails(profileId, event.key));
    }
  };
};
