import State from '../store/state';
import {
  getAppRulesApplicationList,
  getIsBlockedUnsupportedBrowsers,
  getLicense,
} from '../selectors';
import { APPLICATION_ALLOWED } from '../constants';
import { isPremiumOrTrial } from '../records/license';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

export const shouldShowUnsupportedBrowsersBlockedModal = (
  state: State,
  profileId: string,
  exe: string,
  platform: string,
  action: string
): boolean =>
  isPremiumOrTrial(getLicense(state).type) &&
  getIsBlockedUnsupportedBrowsers(state, profileId) &&
  isUnsupportedBrowser(state, profileId, exe, platform) &&
  action === APPLICATION_ALLOWED;

const isUnsupportedBrowser = (
  state: State,
  profileId: string,
  exe: string,
  platform: string
): boolean => {
  const appRules = getAppRulesApplicationList(state, profileId).find(
    app => app.exe === exe && app.platform === platform
  );

  return appRules ? appRules.isUnsupportedBrowser : false;
};

export const navigateToUrlDetails = (
  profileId: number | string,
  eventKey: string
) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/url-details/${encodeURI(eventKey)}`,
  });
};
