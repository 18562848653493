import * as React from 'react';
import { FlexLayout, RckBaseColor } from 'styleguide-react';

interface WellbeingFeaturePageProps {
  children: React.ReactNode;
  colorScheme: Extract<RckBaseColor, 'magenta' | 'green'>;
}

const WellbeingFeaturePage = ({
  children,
  colorScheme,
}: WellbeingFeaturePageProps) => (
  <FlexLayout
    mainaxis="column"
    backgroundColor="white"
    width="100%"
    className={`par-wellbeing-feature par-wellbeing-feature--color-${colorScheme}`}
  >
    {children}
  </FlexLayout>
);

export default WellbeingFeaturePage;
