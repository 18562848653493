import * as React from 'react';
import FixedSideBar from './FixedSideBar';
import DraggableSideBar from '../DraggableSideBar/DraggableSideBar';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

const SideBar = props => {
  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <DraggableSideBar {...props} expand />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <FixedSideBar {...props} screenSize={ScreenSize.Desktop} />
      </RenderWhen>
    </React.Fragment>
  );
};

export default SideBar;
