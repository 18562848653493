import { connect } from 'react-redux';
import LockDeviceModal from '../../../components/Routines/Modals/LockDeviceModal';
import { goBackIfHistory } from '../../../ducks/routing';
import { Dispatch } from '../../../store/state';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const LockDeviceModalContainer = connect(
  null,
  mapDispatchToProps
)(LockDeviceModal);

export default LockDeviceModalContainer;
