import * as React from 'react';
import {
  GlobalType,
  IconType,
  Layout,
  List,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineRulesListItem, {
  RoutineRulesListItemProps,
} from '../../RoutineRulesList/RoutineRulesListItem';

import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { MultiStepDataContext } from '../../routines.types';

export enum SelectRoutineTypeTest {
  prefix = 'routines-select-routine-type-step',
}

export type SelectRoutineTypeProps = RoutineStepWrapperHandlerProps<
  MultiStepDataContext['type']
>;

const SelectRoutineType = ({ prev, close, next }: SelectRoutineTypeProps) => {
  const options: Array<RoutineRulesListItemProps> = [
    {
      title: t('App & website rules'),
      subtitle: t('Manage apps, websites, and time limits'),
      icon: IconType.slidersHorizontal,
      iconColor: GlobalType.secondary,
      onClick: () => next?.('ROUTINE_TYPE_MANAGEMENT'),
      testId: `${SelectRoutineTypeTest.prefix}-option-content-filter`,
    },
    {
      title: t('Device blocks'),
      subtitle: t('Schedule screen-free times'),
      icon: IconType.ban,
      iconColor: GlobalType.error,
      onClick: () => next?.('ROUTINE_TYPE_BLOCKED'),
      testId: `${SelectRoutineTypeTest.prefix}-option-block-device`,
    },
  ];

  return (
    <RoutineStepWrapper
      testId={SelectRoutineTypeTest.prefix}
      prev={prev}
      close={close}
      fixedFooter={false}
    >
      <Layout marginBottom="40">
        <Typography type="h4" weight="semibold">
          {t('Select the type of rules you want to customize')}
        </Typography>
      </Layout>

      <List allowCustomListItemStyle={RoutineRulesListItem}>
        {options.map(
          ({ title, subtitle, icon, iconColor, onClick, testId }) => (
            <RoutineRulesListItem
              key={title}
              title={title}
              subtitle={subtitle}
              icon={icon}
              iconColor={iconColor}
              onClick={onClick}
              testId={testId}
            />
          )
        )}
      </List>
    </RoutineStepWrapper>
  );
};

export default SelectRoutineType;
