import * as React from 'react';
import { GlobalType, Layout, Tag, TagProps } from 'styleguide-react';
import { t } from '../../lib/i18n';

interface UpgradeTagProps {
  /** @default "regular" */
  size?: TagProps['size'];
  onClick?: () => void;
  text?: () => string;
  testId?: string;
}

export const UpgradeTag = ({
  size = 'small',
  onClick,
  text,
  testId,
}: UpgradeTagProps) => {
  const TagComponent = (
    <Tag
      testId={testId ? `upgrade-${testId}` : 'upgrade'}
      type={GlobalType.booster}
      text={text?.() ?? t('Upgrade')}
      variant="squared"
      size={size}
      onClick={onClick}
    />
  );
  return TagComponent;
};

interface WithUpgradeTagProps extends UpgradeTagProps {
  /** Prop to show or hide the tag */
  when: boolean;
  /** @default "8" */
  gap?: Parameters<typeof Layout>['0']['marginRight'];
  children: JSX.Element;
  /** @default "end" */
  align?: 'start' | 'end';
  text?: () => string;
  testId?: string;
}

const getChildren = (
  gap: WithUpgradeTagProps['gap'] | undefined,
  children: WithUpgradeTagProps['children'],
  align: WithUpgradeTagProps['align']
) => {
  if (!gap) return children;

  const marginX = align === 'start' ? 'marginLeft' : 'marginRight';
  return (
    <Layout renderAs="span" {...{ [marginX]: gap }}>
      {children}
    </Layout>
  );
};

export const WithUpgradeTag = ({
  when,
  children,
  gap = '8',
  align = 'end',
  ...props
}: WithUpgradeTagProps) => {
  if (!when) return children;
  return (
    <Layout display="inline" testId="upgrade">
      {align === 'start' && <UpgradeTag {...props} />}
      {getChildren(gap, children, align)}
      {align === 'end' && <UpgradeTag {...props} />}
    </Layout>
  );
};
