import * as React from 'react';
import { t } from '../../lib/i18n';
import { VideoDetailsRecord } from '../../records/urlDetails';
import { Action, isActionBlock, isActionAlert } from '../../records/event';
import Date from '../Date/Date';
import { formatHumanTwelveClockAMPMFormat } from '../../helpers/dates';
import {
  ListSubtitle,
  FreeStyleListItem,
  TransparentButton,
  ListThumbnail,
  ListTitle,
  ListColoredText,
  FlexLayout,
} from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { ListTextColors } from 'styleguide-react/build/components/List/types';
import DropDownActionSheet from '../DropDown/DropDownActionSheet';

const getColor = (action: Action): ListTextColors | null => {
  if (isActionBlock(action)) return 'error';
  if (isActionAlert(action)) return 'warning';
  return null;
};

const UrlDetailsVideoListItem: React.FunctionComponent<{
  video: VideoDetailsRecord;
  dateFormat?: (time, timezone, locale) => string;
  onClickPage: (page: VideoDetailsRecord) => any;
  onClickAction: (item: VideoDetailsRecord) => any;
}> = ({
  video,
  dateFormat = formatHumanTwelveClockAMPMFormat,
  onClickPage,
  onClickAction,
}) => (
  <FreeStyleListItem>
    <FlexLayout mainaxis="column" marginBottom="8">
      <FlexLayout mainaxis="row" mainaxisAlignment="space-between">
        <ListSubtitle testId="urlDetailsVideoListItem-date">
          {video.time ? (
            <Date time={video.time} dateFormatCallback={dateFormat} />
          ) : null}
        </ListSubtitle>
        {video.videoId ? (
          <TransparentButton
            onClick={onClickAction(video)}
            testId="urlDetailsVideoListItem-action"
          >
            <DropDownActionSheet
              selectedActionSheetKey={video.key}
              title={t('YouTube')}
            />
          </TransparentButton>
        ) : null}
      </FlexLayout>
      {video.title && (
        <ListTitle>
          <ListColoredText
            color={getColor(video.action)}
            testId="urlDetailsVideoListItem-title"
          >
            <strong>{video.title}</strong>
          </ListColoredText>
        </ListTitle>
      )}
      {!video.title && (
        <ListSubtitle testId="urlDetailsVideoListItem-title">
          <strong>{t('Title unavailable')}</strong>
        </ListSubtitle>
      )}
      <ListSubtitle testId="urlDetailsVideoListItem-channel">
        <i>
          {video.channelTitle
            ? `${t('By')} ${video.channelTitle}`
            : t('Channel unavailable')}
        </i>
      </ListSubtitle>
      <FlexLayout mainaxis="row" paddingTop="8">
        {video.thumbnail ? (
          <FlexLayout mainaxis="row" minWidth="50%" mainaxisAlignment="start">
            <TransparentButton
              fillAvailableSpace
              role="link"
              onClick={onClickPage(video)}
            >
              <ListThumbnail
                thumbnailUrl={video.thumbnail}
                testId="urlDetailsVideoListItem-thumbnail"
                imageMinHeight="150"
              />
            </TransparentButton>
          </FlexLayout>
        ) : null}
        {video.searchUrl && (
          <ListColoredText
            color={getColor(video.action)}
            testId="urlDetailsVideoListItem-link"
          >
            <a onClick={onClickPage(video)}>{t('Search on YouTube')}</a>
          </ListColoredText>
        )}
      </FlexLayout>
    </FlexLayout>
  </FreeStyleListItem>
);

export default UrlDetailsVideoListItem;
