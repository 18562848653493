import * as React from 'react';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  Layout,
  List,
  RegularStyleListItem,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import RocketLaunchIllustration from '../../../../assets/base/images/v3/flyovers/illustration_rocket_launch_qustodio_icon.svg';

export type PremiumUpgradeInfoProps = RoutineStepWrapperHandlerProps & {
  onDelete?: () => void;
};

export enum PremiumUpgradeInfoTest {
  prefix = 'routines-premium-upgrade-info-step',
}

const renderDeleteRoutineButton = (onDeleteRoutine: () => void) => {
  return (
    <FlexLayout mainaxis="column" crossaxisAlignment="center">
      <Button
        block
        buttonType={ButtonType.plain}
        size={ButtonSize.medium}
        color={ButtonColor.error}
        onClick={onDeleteRoutine}
        testId={`${PremiumUpgradeInfoTest.prefix}--delete`}
      >
        {t('Remove this routine')}
      </Button>
    </FlexLayout>
  );
};

const PremiumUpgradeInfo = ({
  close,
  next,
  prev,
  onDelete,
}: PremiumUpgradeInfoProps) => {
  return (
    <RoutineStepWrapper
      testId={PremiumUpgradeInfoTest.prefix}
      close={close}
      prev={prev}
      next={() => next?.(null)}
      footerText={t('Upgrade')}
      footerContent={onDelete ? renderDeleteRoutineButton(onDelete) : undefined}
    >
      <Layout>
        <Typography type="h4" weight="semibold">
          {t('What can you do when you upgrade?')}
        </Typography>
      </Layout>

      <Layout marginBottom="48" textAlignement="center" maxHeight="160">
        <img src={RocketLaunchIllustration} alt="premium routines" />
      </Layout>

      <FlexLayout
        mainaxis="column"
        padding="16"
        paddingTop="8"
        paddingBottom="48"
      >
        <List
          listItemMarginBottom="24"
          listItemPaddingTop="16"
          showSeparator={false}
        >
          <RegularStyleListItem
            title={t('Get started quickly with presets')}
            upperSubtitle={t('Pick from a list of ready-made rules')}
            iconVerticalAlign="top"
            icon={
              <Layout minWidth="48" marginTop="8">
                <Icon
                  type={IconType.calendarCheck}
                  color={IconColor.secondary}
                  size={IconSize.x2}
                />
              </Layout>
            }
          />
          <RegularStyleListItem
            title={t('Set personalized app rules')}
            upperSubtitle={t('Choose which apps your child can use, and when')}
            iconVerticalAlign="top"
            icon={
              <Layout minWidth="48" marginTop="8">
                <Icon
                  type={IconType.wandMagicSparkles}
                  family={IconFamily.solid}
                  color={IconColor.secondary}
                  size={IconSize.x2}
                />
              </Layout>
            }
          />
          <RegularStyleListItem
            title={t('Create customized web filters')}
            upperSubtitle={t(
              'Block distracting apps at specific times of the day'
            )}
            iconVerticalAlign="top"
            icon={
              <Layout minWidth="48" marginTop="8">
                <Icon
                  type={IconType.slidersHorizontal}
                  color={IconColor.secondary}
                  size={IconSize.x2}
                />
              </Layout>
            }
          />
        </List>
      </FlexLayout>
    </RoutineStepWrapper>
  );
};

export default PremiumUpgradeInfo;
