import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import { PageTitleActionElement } from 'styleguide-react/build/components/PageTitle/PageTitle';
import { PageTitle as PageTitleStyleguide } from 'styleguide-react';
import State from '../../store/state';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { setLayoutActionName } from '../../ducks/layout/layout';
import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import { filterActions } from './helpers/mergeConfiguration';

export interface PageTitleActionElementProps extends PageTitleActionElement {
  screenSize?: ScreenSize;
}

export interface PageTitleLayoutConfigurableProps {
  showProfile?: boolean;
  centered?: boolean;
  topBar?: React.ReactNode;
  customPageTitle?: React.ReactNode;
  subtitle?: string | JSX.Element;
  beforePageTitle?: React.ReactNode;
  afterPageTitle?: React.ReactNode;
}

export interface PageTitleLayoutProps
  extends PageTitleLayoutConfigurableProps,
    PageTitleLayoutCommonProps {}

export interface PageTitleLayoutCommonProps {
  title?: string;
  subtitle?: string | JSX.Element;
  actions?: PageTitleActionElementProps[];
}

const PageTitleLayout: React.FunctionComponent<PageTitleLayoutProps> = ({
  title,
  actions,
  showProfile,
  centered,
  topBar,
  subtitle,
  customPageTitle,
}) => (
  <React.Fragment>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      {customPageTitle && customPageTitle}
      {!customPageTitle && (
        <PageTitleStyleguide
          testId="pageTitle"
          title={title}
          size="medium"
          centered={centered}
          subtitle={subtitle}
          actions={filterActions(actions, ScreenSize.Desktop)}
        />
      )}
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Tablet}>
      {topBar && topBar}
      {!topBar && customPageTitle && customPageTitle}
      {!topBar && !customPageTitle && (
        <PageTitleStyleguide
          testId="pageTitle"
          title={title}
          size="small"
          centered={centered}
          actions={filterActions(actions, ScreenSize.Tablet)}
          profileInfoHeader={
            showProfile ? (
              <ProfileHeader screenSize={ScreenSize.Mobile} editable={false} />
            ) : undefined
          }
        />
      )}
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Mobile}>
      {topBar && topBar}
      {!topBar && customPageTitle && customPageTitle}
      {!topBar && !customPageTitle && (
        <PageTitleStyleguide
          testId="pageTitle"
          title={title}
          size="small"
          centered={centered}
          actions={filterActions(actions, ScreenSize.Mobile)}
          profileInfoHeader={
            showProfile ? (
              <ProfileHeader screenSize={ScreenSize.Mobile} editable={false} />
            ) : undefined
          }
        />
      )}
    </RenderWhen>
  </React.Fragment>
);

const mapStateToProps = (_state: State, props: PageTitleLayoutProps) => ({
  showProfile: props.showProfile,
});

const mapDispatchToProps = (dispatch, props: PageTitleLayoutProps) => ({
  actions: props.actions?.map(action => {
    const handler = () => {
      dispatch(setLayoutActionName(action.actionName));
      if (action.onClick) action.onClick();
      if (action.actionName === 'back') {
        if (!action.onClick) {
          dispatch(goBackIfHistory());
        }
        dispatch(setLayoutActionName('none'));
      }
    };
    return { ...action, onClick: handler } as PageTitleActionElementProps;
  }),
});

const ConnectedPageTitleLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTitleLayout);

export default ConnectedPageTitleLayout;
