import { connect } from 'react-redux';
import { setWebFilterActiveSection } from '../../../ducks/profileRules';
import {
  getIsAllowUnknownSites,
  getIsSafeSearch,
  getIsReportBlockedSites,
  getIsBlockedUnsupportedBrowsers,
  getIsReportBlockedSummaryAlerts,
} from '../../../selectors/index';

import State from '../../../store/state';
import {
  WEBFILTER_CATEGORIES_SECTION,
  SettingsToggleNames,
} from '../../../constants';
import { getFeatureFlag } from '../../../selectors/featureFlags';
import { setLastRuleTypeModified, RuleType } from '../../../ducks/appRate';
import WebFilterSettingsAndNotificationsPage from '../../../components/WebFilters/WebFilterSettingsAndNotificationsPage';
import { handleToggleWebFilterSetting } from '../../../actions/WebFiltersActions';
import {
  ruleNeedsUpgrade,
  shouldHideRule,
} from '../../../businessLogic/rules/features';
import { getFeatureByCode } from '../../../ducks/features/selectors';
import { FeatureCode } from '../../../records/features/types';

const mapStateToProps = (state: State, { params: { profileId } }) => {
  return {
    isAllowUnknownSites: getIsAllowUnknownSites(state, profileId),
    isSafeSearch: getIsSafeSearch(state, profileId),
    isReportBlockedSites: getIsReportBlockedSites(state, profileId),
    isBlockedUnsupportedBrowsers: getIsBlockedUnsupportedBrowsers(
      state,
      profileId
    ),
    isEnabledBlockUnsupportedBrowsers: getFeatureFlag(
      state,
      'blockUnsupportedBrowsers'
    ) as boolean,
    isReportBlockedSummaryAlerts: getIsReportBlockedSummaryAlerts(
      state,
      profileId
    ) as boolean,
    ruleNeedsUpgrade: (code: FeatureCode) =>
      ruleNeedsUpgrade(getFeatureByCode(state, code)),
    shouldHideRule: (code: FeatureCode) =>
      shouldHideRule(getFeatureByCode(state, code)),
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onToggleWebFilterSetting: (
    target: SettingsToggleNames,
    toggleOn: boolean,
    needLicenseUpgrade = false
  ) => {
    dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
    dispatch(
      handleToggleWebFilterSetting({
        target,
        toggleOn,
        profileId,
        needLicenseUpgrade,
      })
    );
  },
});

const WebFiltersSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFilterSettingsAndNotificationsPage);

WebFiltersSettingsContainer.load = () => (dispatch, getState) => {
  // we only want to set categories as active if user doesn't come from a subview like for
  // example WebfilterSettings or AddDomainException
  const action = getState()
    ?.get('routing')
    ?.get('locationBeforeTransitions')?.action;

  // user used forward/back button
  if (action === 'PUSH') {
    dispatch(setWebFilterActiveSection(WEBFILTER_CATEGORIES_SECTION));
  }
};

export default WebFiltersSettingsContainer;
