import { connect } from 'react-redux';
import SchoolsWelcomeModal from '../../components/Modal/SchoolsWelcomeModal';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { Dispatch } from '../../store/state';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: '/?school-onboarding=done',
      })
    );
  },
});

const SchoolsWelcomeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolsWelcomeModal);

export type SchoolsWelcomeModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default SchoolsWelcomeModalContainer;
