import * as React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import InputTextField from '../Form/InputTextField';
import { FlexLayout, Label } from 'styleguide-react';
import { isValidPhoneNumber } from '../../helpers/string';
import { Record } from 'immutable';

export const formName = 'editPhone';
export const savePhoneButtonId = 'SavePhoneButton';

export type EditPhoneFormRecord = {
  telephone: string;
};

export const FormRecord = Record<EditPhoneFormRecord, {}>({
  telephone: '',
});

export const formRecord = (values?: EditPhoneFormRecord) => {
  if (values === undefined) {
    return FormRecord();
  }
  const record = values instanceof Record ? values : FormRecord(values);
  return record;
};

const EditPhoneForm: React.FunctionComponent<{
  handleSubmit: () => any;
  className?: string;
}> = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} id={formName}>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={savePhoneButtonId}>{t('Phone')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <Field
        id={savePhoneButtonId}
        name="telephone"
        component={InputTextField}
        type="text"
        placeholder={t('Phone number')}
      />
    </FlexLayout>
    {/* include an invisible submit-button so that the mobile-keyboard
       has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

interface FormError {
  [key: string]: string;
}

const validate = (values, { country }) => {
  const errors: FormError = {};
  if (!values.telephone) {
    errors.telephone = t('Field required');
    return errors;
  }

  if (!isValidPhoneNumber(values.telephone, country)) {
    errors.telephone = t('Add a valid phone number');
    return errors;
  }

  return errors;
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: { telephone: '' },
  validate: (fields, props) => validate(fields.toJS(), props),
})(EditPhoneForm);
