import { fromJS, Map, NonEmptyMapRecord, NullableMap } from 'immutable';
import {
  ContactRecord,
  EventRecord,
  ParentDeviceRecord,
  ProductRecord,
  ProfileRulesRecord,
  TimeSettingsRecord,
  UrlDetailsRecord,
} from '../records';
import { DeviceRecord } from '../records/device/types/Device.types';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { AppNameWithPlatform } from '../records/profileRules';
import { Student } from '../records/student/types/Student.type';
import { SchoolTimes } from '../records/studentRules/types/schoolTimes.type';
import { StudentPause } from '../records/studentRules/types/studentPause.type';
import { SafeNetworksSettings } from '../records/safeNetworksSettings/types/safeNetworkSetting';
import { LinewizeEventRecord } from '../records/linewizeEvent/types/linewizeEvents';
import { RoutineRecord } from '../records/routines/types/Routine.types';
import { ScheduleRecord } from '../records/routines/schedule/types/Schedule.types';
import { StudentDelegation } from '../records/studentRules/types/studentDelegation.type';
import { StudentActiveClass } from '../records/studentRules/types/studentActiveClass.type';

export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const RESET_RECORD = 'RESET_RECORD';

type State = NonEmptyMapRecord<{
  events: NullableMap<string, EventRecord>;
  profiles: NullableMap<string, ProfileRecord>;
  profileRules: NullableMap<string, ProfileRulesRecord>;
  devices: NullableMap<string, DeviceRecord>;
  parentDevices: NullableMap<string, ParentDeviceRecord>;
  products: NullableMap<string, ProductRecord>;
  productsWithDedicatedSupport: NullableMap<string, ProductRecord>;
  contacts: NullableMap<string, ContactRecord>;
  timeRestrictions: NullableMap<string, TimeSettingsRecord>;
  urlDetails: NullableMap<string, UrlDetailsRecord>;
  appIcons: NullableMap<AppNameWithPlatform, string>;
  students: NullableMap<string, Student>;
  studentPause: NullableMap<string, StudentPause>;
  schoolTimes: NullableMap<string, SchoolTimes>;
  studentDelegation: NullableMap<string, StudentDelegation>;
  studentActiveClass: NullableMap<string, StudentActiveClass>;
  safeNetworkSettings: NullableMap<string, SafeNetworksSettings>;
  linewizeEvents: NullableMap<string, LinewizeEventRecord>;
  routines: NullableMap<string, RoutineRecord>;
  routinesSchedules: NullableMap<string, ScheduleRecord>;
}>;

export const initialState: State = fromJS({
  events: {},
  profiles: {},
  profileRules: {},
  devices: {},
  parentDevices: {},
  products: {},
  productsWithDedicatedSupport: {},
  contacts: {},
  timeRestrictions: {},
  urlDetails: {},
  appIcons: {},
  students: {},
  safeNetworkSettings: {},
  linewizeEvents: {},
  routines: {},
  routinesSchedules: {},
});

export default function records(state: State = initialState, action): State {
  if (action.type === UPDATE_RECORD) {
    return state.setIn(
      [action.payload.type, action.payload.id],
      action.payload.record
    );
  }
  if (action.type === DELETE_RECORD) {
    return state.deleteIn([action.payload.type, action.payload.id]);
  }

  if (action.type === RESET_RECORD) {
    return state.set(action.payload.type, Map({}));
  }

  if (action.payload && action.payload.records) {
    /*
  It is very important to note that this reducer is global,
  meaning it will apply to any action that contains the "records" property in its payload,
  with behavior that can vary in the following cases:

  1. Overwriting totally the data of the records (as is the case with devices, profiles).
  2. Updating the data of the records using a merge on their data (this is the case with products that need to be updated to maintain store information).

  If action.payload.reducerBehavior.merge is set to true,
  the data should be merged rather than completely overwritten and replaced in the action.
  If no such property is provided, the data will be replaced and overwritten
  (this is considered the default behavior and is used in most cases).

  It is important to note that this behavior cannot be trivially replaced because it can be a source of bugs.
  In the current development, there is only the case of products where the data needs to be merged.
  For other cases, the data should be replaced.
  */
    if (
      action.payload.reducerBehavior &&
      action.payload.reducerBehavior.merge
    ) {
      return upsertRecordsWithMerge(state, action.payload.records);
    }
    return upsertRecords(state, action.payload.records);
  }

  return state;
}

/**
 * Inserts new records into the state or updates existing records by completely
 * replacing the old version, instead of performing a deep merge.
 */
const upsertRecords = <
  T extends Record<string, unknown>,
  U extends Record<string, unknown>
>(
  state: State,
  payloadRecords: T | T[]
) =>
  Map<T, U>(payloadRecords).reduce(
    (state, records, recordType) =>
      Map(records).reduce(
        (state, record, id) => state?.setIn([recordType, id], record),
        state
      ),
    state
  );

/**
 * Inserts new records into the state or updates existing records by merging
 * the new data with the old version, instead of completely replacing it.
 */
const upsertRecordsWithMerge = <
  T extends Record<string, unknown>,
  U extends Record<string, unknown>
>(
  state: State,
  payloadRecords: T | T[]
) =>
  Map<T, U>(payloadRecords).reduce(
    (state, records, recordType) =>
      Map(records).reduce((state, record, id) => {
        const previous = state?.getIn([recordType, id]);
        if (previous) {
          const updated = record?.merge(previous);
          return state.setIn([recordType, id], updated);
        }
        return state.setIn([recordType, id], record);
      }, state),
    state
  );

const updateRecord = type => (record, id?) => ({
  type: UPDATE_RECORD,
  payload: {
    type,
    id: (id || record.id).toString(),
    record,
  },
});

const deleteRecord = type => id => ({
  type: DELETE_RECORD,
  payload: {
    type,
    id: id.toString(),
  },
});

const resetRecord = type => () => ({
  type: RESET_RECORD,
  payload: { type },
});

export const updateEventRecord = updateRecord('events');
export const updateProfileRecord = updateRecord('profiles');
export const updateProfileRulesRecord = updateRecord('profileRules');
export const updateDeviceRecord = updateRecord('devices');
export const updateProductRecord = updateRecord('products');
export const updateStudentRecord = updateRecord('students');
export const updateLinewizeEventRecord = updateRecord('linewizeEvents');
export const updateRoutinesSchedulesRecord = updateRecord('routinesSchedules');

export const deleteEventRecord = deleteRecord('events');
export const deleteProfileRecord = deleteRecord('profiles');
export const deleteProfileRulesRecord = deleteRecord('profileRules');
export const deleteDeviceRecord = deleteRecord('devices');
export const deletePlaceRecord = deleteRecord('places');
export const deleteProductRecord = deleteRecord('products');
export const deleteStudentRecord = deleteRecord('students');
export const deleteLinewizeEventRecord = deleteRecord('linewizeEvents');
export const deleteRoutineRecord = deleteRecord('routines');
export const deleteRoutinesSchedulesRecord = deleteRecord('routinesSchedules');

export const resetLinewizeEventRecord = resetRecord('linewizeEvents');
export const resetRoutinesSchedulesRecord = resetRecord('routinesSchedules');
export const resetRoutinesRecord = resetRecord('routines');
