import * as React from 'react';
import {
  Card,
  FlexLayout,
  FreeText,
  GlobalType,
  Icon,
  IconSize,
  IconType,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import { classNames } from '../../../../helpers';
import Loader from '../../../base/Loader';
import { CardDimensions } from '../../../../constants';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';

interface SchoolMiniActivitySummaryProps {
  type: 'info' | 'loading' | 'error' | 'no-activity' | 'no-shared';
  profileName?: string;
  children?: React.ReactNode;
  isCombined?: boolean;
  isDelegated?: boolean;
}

interface NoActivityTextProps {
  profileName: string;
  isCombined: boolean;
  isDelegated: boolean;
}

const getNoActivityText = ({
  isCombined,
  isDelegated,
  profileName,
}: NoActivityTextProps) => {
  if (isDelegated) {
    return t(
      'No activity on {{profileName}}’s school-issued device during school hours',
      { profileName }
    );
  }
  if (isCombined) {
    return t("No activity on {{profileName}}'s school device yet", {
      profileName,
    });
  }
  return t('No activity on {{profileName}}’s school device yet', {
    profileName,
  });
};

const MiniActivitySummaryWrapper = ({ children }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    crossaxisAlignment="center"
    textAlignement="center"
    matchParentHeight
    className="par-school-mini-activity-summary__summary-activity-list--big-gap"
    testId="school-mini-activity-summary-header"
  >
    {children}
  </FlexLayout>
);

const SchoolMiniActivitySummary = ({
  children,
  type,
  profileName,
  isCombined = false,
  isDelegated = false,
}: SchoolMiniActivitySummaryProps) => {
  const getSchoolSummaryContent = () => {
    switch (type) {
      case 'loading':
        return (
          <MiniActivitySummaryWrapper>
            <Loader size="xs" color="secondary" />
          </MiniActivitySummaryWrapper>
        );
      case 'error':
        return (
          <MiniActivitySummaryWrapper>
            <Icon
              type={IconType.exclamationCircle}
              color="secondary"
              size={IconSize.x2}
            />
            <FreeText color="gray-semi">
              {t('School device highlights couldn’t load')}
            </FreeText>
          </MiniActivitySummaryWrapper>
        );
      case 'no-activity':
        return (
          <MiniActivitySummaryWrapper>
            <Icon
              type={IconType.graduationCap}
              color="grayLight"
              size={IconSize.x2}
            />
            <FreeText color="gray-semi">
              {getNoActivityText({ profileName, isCombined, isDelegated })}
            </FreeText>
          </MiniActivitySummaryWrapper>
        );
      case 'no-shared':
        return (
          <MiniActivitySummaryWrapper>
            <Icon
              type={IconType.graduationCap}
              color="grayLight"
              size={IconSize.x2}
            />
            <FreeText color="gray-semi">
              {t('Your school hasn’t enabled activity monitoring for parents')}
            </FreeText>
          </MiniActivitySummaryWrapper>
        );
      default:
        return children;
    }
  };
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <Card
          width={
            screenSize === ScreenSize.Desktop ? CardDimensions.width : '100%'
          }
          height={240}
          type={GlobalType.white}
          header={
            type === 'info'
              ? {
                  banner: (
                    <FlexLayout
                      mainaxis="row"
                      flexGrow="1"
                      className={classNames(
                        'par-school-mini-activity-summary__header',
                        'par-school-mini-activity-summary__header--info'
                      )}
                      crossaxisAlignment="center"
                      testId="school-mini-activity-summary-header"
                    >
                      <Icon
                        type={IconType.graduationCap}
                        size={IconSize.lg}
                        className={classNames(
                          'par-school-mini-activity-summary__header-left-icon',
                          'par-school-mini-activity-summary__header-left-icon--school'
                        )}
                        testId="school-mini-activity-summary-header-left-icon"
                      />
                      <FreeText
                        fontSize="16px"
                        fontWeight="normal"
                        className="par-school-mini-activity-summary__header-title"
                        testId="school-mini-activity-summary-header-title"
                      >
                        {t('School device highlights')}
                      </FreeText>
                    </FlexLayout>
                  ),
                }
              : undefined
          }
          className={classNames(
            'par-school-mini-activity-summary__container',
            `par-school-mini-activity-summary__container--${type}`
          )}
          testId="school-mini-activity-summary"
        >
          <FlexLayout
            mainaxis="column"
            minWidth="320"
            matchParentHeight
            className="par-school-mini-activity-summary__summary-activity-list"
          >
            {getSchoolSummaryContent()}
          </FlexLayout>
        </Card>
      )}
    </RenderWhen>
  );
};

export default SchoolMiniActivitySummary;
