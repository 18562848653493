import { Record } from 'immutable';

type ParentDevice = {
  account: number;
  created: string;
  enabled: boolean;
  id: number;
  modified: string;
  name: string;
  platform: number;
  pushToken: string | null;
  registrationId: string | null;
  timezone: string;
  version: string;
  visits: number;
  lastip: string;
  lastseen: string;
};

type ParentDeviceRecord = Record<ParentDevice>;

const ParentDeviceRecord = Record<
  ParentDevice,
  {
    serialize: Record.Serializer<Record<ParentDevice>, ParentDevice>;
    fromPayload: Record.Parser<ParentDevice, Record<ParentDevice>>;
  }
>({
  account: 0,
  created: '',
  enabled: false,
  id: 0,
  modified: '',
  name: '',
  platform: 0,
  pushToken: null,
  registrationId: null,
  timezone: '',
  version: '',
  visits: 0,
  lastip: '',
  lastseen: '',
});

ParentDeviceRecord.fromPayload = payload => ParentDeviceRecord(payload);

ParentDeviceRecord.serialize = record => record.toJS();

export default ParentDeviceRecord;
