import * as React from 'react';
import {
  ExpandCollapse,
  ExpandCollapseRowItem,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  List,
  ListBckImageIcon,
  SelectableListItem,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineStepWrapper from '../RoutineStepWrapper';

import { makeAppKey } from '../../../../records/profileRules';
import { replaceS3Url } from '../../../../helpers';
import { filterOrSearchAppList } from '../../../../helpers/appRules';
import { renderAppRulesEmptyState } from '../../../AppRules/EmptyStates/AppRulesEmptyStates';
import AppRulesPlatform from '../../../AppRules/AppRulesPlatform';
import {
  AppGroupedContentProps,
  AppListContentProps,
  SelectAppExceptionsProps,
} from './types/SelectAppExceptionsProps.types';
import { transformAppExceptionStepState } from './helpers';
import { FILTER_APPS_ALL } from '../../../../constants';
import FiltersAndSearchForm from './components/FiltersAndSearchForm';
import { groupAppsByCategory } from '../../../../businessLogic/profileRules/apps';
import flags from '../../../../sideEffects/flags';
import AppRulesGroupedSubtitle from '../../../AppRules/AppRulesGroupedSubtitle';
import AppRulesGroupedTotalApps from '../../../AppRules/AppRulesGroupedTotalApps';

export const SELECT_APP_EXCEPTIONS_TEST_ID =
  'routines-select-app-exceptions-step';

const AppListContent = ({
  appsListState,
  onItemClick,
  selectedAppKeysState,
}: AppListContentProps) => {
  return (
    <List
      listItemMarginBottom="16"
      onClickItem={key => onItemClick(key as string)}
    >
      {appsListState.map(app => (
        <SelectableListItem
          testId={`${SELECT_APP_EXCEPTIONS_TEST_ID}-item-${makeAppKey(app)}`}
          key={makeAppKey(app)}
          id={makeAppKey(app)}
          selected={selectedAppKeysState.includes(makeAppKey(app))}
          icon={<ListBckImageIcon url={replaceS3Url(app.thumbnail)} />}
          title={app.name}
          subtitle={
            <Typography type="body2">
              <AppRulesPlatform app={app} />
            </Typography>
          }
        />
      ))}
    </List>
  );
};

const AppGroupedContent = ({
  categories,
  groupedApps,
  totalAppsByCategory,
  selectedAppKeysState,
  onItemClick,
}: AppGroupedContentProps) => {
  const [openedCategories, setOpenedCategories] = React.useState<Array<string>>(
    categories.first()?.get('categoryId')
      ? [categories.first()?.get('categoryId') as string]
      : []
  );
  const onCategoryClick = (categoryId: string) => {
    if (openedCategories.includes(categoryId)) {
      setOpenedCategories(currState =>
        currState.filter(cat => cat !== categoryId)
      );
      return;
    }
    setOpenedCategories(currState => [...currState, categoryId]);
  };

  const isCategoryExpanded = (categoryId: string) =>
    openedCategories.includes(categoryId);

  return (
    <React.Fragment>
      {Array.from(categories.valueSeq().toArray()).map(category => {
        const catApps = groupedApps[category.categoryId] ?? [];
        const totalFilteredApps = catApps.length ?? 0;
        const isDisabled = totalFilteredApps === 0;
        const totalAppsInCategory =
          totalAppsByCategory[category.categoryId] ?? 0;

        return (
          <ExpandCollapse
            leftImgSrc={category.icon}
            key={category.categoryId}
            title={category.name}
            testId={category.categoryId}
            rightElement={
              <AppRulesGroupedTotalApps
                categoryAppsTotal={totalAppsInCategory}
                filteredAppsTotal={totalFilteredApps}
              />
            }
            isDisabled={isDisabled}
            initialOpenState={isCategoryExpanded(category.categoryId)}
            onClick={() => {
              onCategoryClick(category.categoryId);
            }}
          >
            {catApps.map(app => {
              const appKey = makeAppKey(app);
              const appSelected = selectedAppKeysState.includes(
                makeAppKey(app)
              );
              return (
                <span
                  key={appKey}
                  onClick={() => onItemClick(appKey)}
                  role="checkbox"
                  aria-checked={appSelected}
                  onKeyDown={() => onItemClick(appKey)}
                  tabIndex={0}
                >
                  <ExpandCollapseRowItem
                    key={`expand-${appKey}`}
                    iconSrc={replaceS3Url(app.thumbnail)}
                    title={app.name}
                    subtitle={<AppRulesGroupedSubtitle app={app} />}
                    rightElement={
                      <Icon
                        type={
                          appSelected ? IconType.circleDot : IconType.circle
                        }
                        color={
                          appSelected ? IconColor.secondary : IconColor.black
                        }
                        size={IconSize.lg}
                      />
                    }
                    className="par-util-word-wrap"
                  />
                </span>
              );
            })}
          </ExpandCollapse>
        );
      })}
    </React.Fragment>
  );
};

const SelectAppExceptions = ({
  mode,
  action,
  appRulesList,
  selectedApps,
  categories,
  totalAppsByCategory,
  prev,
  close,
  next,
}: SelectAppExceptionsProps) => {
  const [appsListState, setAppsListState] = React.useState(appRulesList);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedAppKeysState, updateSelectedAppKeysState] = React.useState(
    selectedApps?.map(webCategory => makeAppKey(webCategory)) || []
  );

  React.useEffect(() => {
    const filteredApps = filterOrSearchAppList(
      FILTER_APPS_ALL,
      appRulesList,
      searchQuery
    );
    setAppsListState(filteredApps);
  }, [searchQuery]);

  const onSearch = (_e: unknown, value: string) => {
    setSearchQuery(value);
  };

  const onItemClick = (selectedAppKey?: string) => {
    if (!selectedAppKey) return;

    if (selectedAppKeysState.includes(selectedAppKey)) {
      updateSelectedAppKeysState(state =>
        state.filter(app => app !== selectedAppKey)
      );
      return;
    }
    updateSelectedAppKeysState(state => [...state, selectedAppKey]);
  };

  const nextFnHandler = (noAppDetected: boolean) => {
    if (noAppDetected) return undefined;

    const transformedState = transformAppExceptionStepState(
      appRulesList,
      selectedAppKeysState,
      action
    ).toJS();

    return () => next?.(transformedState);
  };

  const noAppDetected = appRulesList.size === 0;
  const noResultsFound = !appsListState || appsListState.size === 0;

  const groupedApps = groupAppsByCategory(appsListState);

  return (
    <RoutineStepWrapper
      testId={SELECT_APP_EXCEPTIONS_TEST_ID}
      prev={prev}
      close={close}
      next={nextFnHandler(noAppDetected)}
      footerText={mode === 'EDIT' ? t('Save and go back') : t('Save')}
      fixedFooter
    >
      <Layout marginBottom="40">
        <Typography type="h4" weight="semibold">
          {t('Add app exceptions')}
        </Typography>
        <Typography type="body2">
          {action === 'POLICY_ACTION_ALLOWED' &&
            t('Select the apps you want to {1}block{/1}.', {
              1: (str: string) => <strong>{str}</strong>,
            })}
          {action === 'POLICY_ACTION_BLOCKED' &&
            t('Select the apps you want to {1}allow{/1}.', {
              1: (str: string) => <strong>{str}</strong>,
            })}
        </Typography>
      </Layout>

      {!noAppDetected ? (
        <FiltersAndSearchForm
          testId={`${SELECT_APP_EXCEPTIONS_TEST_ID}-filter`}
          searchQuery={searchQuery}
          onChangeSearch={onSearch}
        />
      ) : null}

      {noResultsFound ? (
        renderAppRulesEmptyState({
          noAppsDetected: appRulesList.size === 0,
          activeFilter: FILTER_APPS_ALL,
        })
      ) : (
        <React.Fragment>
          {flags.useAppCategories.isEnabled() && !searchQuery ? (
            <AppGroupedContent
              onItemClick={onItemClick}
              categories={categories}
              selectedAppKeysState={selectedAppKeysState}
              groupedApps={groupedApps}
              totalAppsByCategory={totalAppsByCategory}
            />
          ) : (
            <AppListContent
              appsListState={appsListState}
              onItemClick={onItemClick}
              selectedAppKeysState={selectedAppKeysState}
            />
          )}
        </React.Fragment>
      )}
    </RoutineStepWrapper>
  );
};

export default SelectAppExceptions;
