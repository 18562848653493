import * as React from 'react';
import {
  FlexLayout,
  Layout,
  Button,
  ButtonSize,
  ButtonType,
  Dropdown,
  ActionInput,
  Icon,
  DropdownMenuPlacement,
  ActionInputIconPosition,
  IconType,
  IconSize,
} from 'styleguide-react';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import type { CompleteStudentProps } from '../../containers/Schools/CompleteStudentContainer';
import { page, PageNames } from '../../helpers/analytics';
import { birthDayDropdown } from '../BirthDateDropdown/BirthDateDropdown';
import {
  toProfileRecordBirthDate,
  toUIYear,
} from '../../helpers/appDataArrangement';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';

export const formName = 'studentYear';
const addStudentYearFormBirthYearFieldId = 'AddStudentYearFormBirthYearFieldId';

const CompleteStudent: React.FunctionComponent<CompleteStudentProps> = ({
  student,
  onClickNext,
  onClickSkip,
}: CompleteStudentProps) => {
  const onClickNextHandler = formValuesMap => {
    const birthDate = formValuesMap.get('birthDate');
    onClickNext(student, toProfileRecordBirthDate(birthDate));
  };

  React.useEffect(() => {
    page(PageNames.StudentSetup, {
      profile_id: student.id,
    });
  }, [student.id]);

  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      matchParentHeight
      textAlignement="center"
    >
      <ProfileAvatar profile={student} />
      <h5 className="rck-fw-semibold">
        {t("Enter {1}{{studentName}}'s{/1} birth year", {
          1: str => <span className="rck-link">{str}</span>,
          studentName: student.name,
        })}
      </h5>
      <p>
        {t('You’ll get tailored safety insights about the apps they’re using.')}
      </p>
      <StudentYearForm
        initialValues={{ birthDate: toUIYear(student?.birthDate) }}
        onSubmit={onClickNextHandler}
      />
      <Button
        data-testid="Button--skip"
        onClick={onClickSkip}
        loading={false}
        disabled={false}
        size={ButtonSize.medium}
        buttonType={ButtonType.plain}
      >
        {t('I prefer not to say')}
      </Button>
    </FlexLayout>
  );
};

const StudentYearForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: values => validate(values.toJS()),
})(({ handleSubmit, valid }) => {
  const yearsOptions = birthDayDropdown();

  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      marginTop="24"
      marginBottom="24"
      width="100%"
    >
      <Layout minWidth="200" marginBottom="72">
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <DropdownStudentYear yearsOptions={yearsOptions} maxWidth={70} />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <DropdownStudentYear yearsOptions={yearsOptions} />
        </RenderWhen>
      </Layout>

      <FlexLayout mainaxis="column" className="OnboardingButton__container">
        <Button
          data-testid="Button--continue"
          onClick={handleSubmit}
          loading={false}
          disabled={!valid}
          size={ButtonSize.medium}
          block
        >
          {t('Continue')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
});

const validate = values => {
  const errors: { birthDate?: string } = {};
  if (!values.birthDate) {
    errors.birthDate = t('Field required');
  }
  return errors;
};
interface DropdownStudentYearProps {
  yearsOptions: unknown[];
  minWidth?: number;
  maxWidth?: number;
}

export const DropdownStudentYear: React.FunctionComponent<
  DropdownStudentYearProps
> = ({ yearsOptions, minWidth, maxWidth }: DropdownStudentYearProps) => {
  return (
    <Field
      name="birthDate"
      id={addStudentYearFormBirthYearFieldId}
      component={({ input: props }) => (
        <Dropdown
          minWidth={minWidth}
          maxWidth={maxWidth}
          maxHeight={200}
          actionElement={
            <ActionInput
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              block
              // eslint-disable-next-line react/prop-types
              text={toUIYear(props.value)}
              iconPosition={ActionInputIconPosition.right}
              icon={<Icon type={IconType.angleDown} size={IconSize.regular} />}
              onKeyDown={(ev: React.SyntheticEvent) => {
                ev.preventDefault();
              }}
            />
          }
          testId="birthDate"
          // eslint-disable-next-line react/prop-types
          onChange={props.onChange}
          menuPlacement={DropdownMenuPlacement.top}
          fixedMenuPlacement
        >
          {yearsOptions}
        </Dropdown>
      )}
    />
  );
};

export default CompleteStudent;
