import * as React from 'react';
import { Badge, BadgeType, Card, FlexLayout } from 'styleguide-react';
import { SignatureDetails } from '../../../records/studentActivity/signature/types/SignatureDetails.types';
import { SignatureReview } from '../../../records/studentActivity/signature/types/SignatureReview.types';
import SignatureRecommendation from './SignatureRecommendation';

export type SignatureInfoCardProps = Pick<
  SignatureDetails,
  'name' | 'iconUrl' | 'categoryName'
> &
  Pick<SignatureReview, 'recommendation' | 'ageRating'>;

const SignatureInfoCard: React.FunctionComponent<SignatureInfoCardProps> = ({
  name,
  iconUrl,
  categoryName,
  recommendation,
  ageRating,
}: SignatureInfoCardProps) => (
  <Card fullWidth className="SignatureInfoCard">
    <FlexLayout mainaxis="row">
      <FlexLayout mainaxis="column">
        <img src={iconUrl} alt={`${name} icon`} className="icon" />
      </FlexLayout>
      <FlexLayout mainaxis="column" className="main-info">
        <h3 className="name">{name}</h3>
        <span className="category-name">{categoryName}</span>
        {ageRating !== undefined && (
          <Badge
            text={`Ages ${ageRating}+`}
            type={BadgeType.neutral}
            className="age-rating"
            testId="signature-info-age-rating"
          />
        )}
      </FlexLayout>
      <FlexLayout mainaxis="column">
        <SignatureRecommendation recommendation={recommendation} />
      </FlexLayout>
    </FlexLayout>
  </Card>
);

export default SignatureInfoCard;
