import { connect } from 'react-redux';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../../../store/state';
import { goBackIfHistory } from '../../../ducks/routing';
import SchoolTimelineDetail, {
  SchoolTimelineDetailProps,
} from '../../../components/Schools/SchoolTimeline/SchoolTimelineDetail';
import { getLinewizeEventRecordByKey } from '../../../ducks/linewizeEvent/selector';
import { groupUrlsByHost } from '../../../businessLogic/LinewizeEvents/LinewizeEvent';
import { getAccount, getProfile } from '../../../selectors';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import flags from '../../../sideEffects/flags';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../../helpers/analytics';
import { camelCaseToSnakeCase } from '../../../api/mappings/case';
import { cleanInvalidUrls } from '../../../helpers/string';
import { InvalidFileTypesInLinewizeEvents } from '../../../constants';
import { formatHumanTwelveClockFormat } from '../../../helpers/dates';

const mapStateToProps = (
  state: State,
  { params: { profileId, eventKey } }: RouterParamsProps
): Partial<SchoolTimelineDetailProps> => {
  const { action, icon, urls, signatureName, dt } =
    getLinewizeEventRecordByKey(state, eventKey) || {};
  const cleanUrls = cleanInvalidUrls(urls, InvalidFileTypesInLinewizeEvents);
  const eventUrlsByHost = groupUrlsByHost(cleanUrls);

  const profileName = getProfile(state, profileId)?.name;

  const { timeZone, locale } = getAccount(state);
  const eventTimeFormatted = formatHumanTwelveClockFormat(dt, timeZone, locale);

  return {
    profileName,
    eventUrlsByHost,
    eventAction: action,
    eventTitle: signatureName,
    eventIcon: icon,
    time: eventTimeFormatted,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { eventKey } }: RouterParamsProps
): Partial<SchoolTimelineDetailProps> => ({
  onClickGoBack: () => {
    dispatch(goBackIfHistory());
  },
  onLinkClick: url => {
    dispatch((dispatch, getState) => {
      const navigate = getMultiPlatformNavigation();
      const { key, signatureName, type } = getLinewizeEventRecordByKey(
        getState(),
        eventKey
      );
      const trackOptions = {
        eventKey: key,
        eventTitle: signatureName,
        eventType: type,
      };
      trackButtonClicked(
        GenericPageNames.ProfileSchoolTimelineDetails,
        ButtonNames.StudentTimelineActionVisitUrl,
        {
          options: JSON.stringify(camelCaseToSnakeCase(trackOptions)),
        }
      );
      return dispatch(
        navigate({
          type: 'external',
          src: url,
        })
      );
    });
  },
});

const SchoolTimelineDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolTimelineDetail);

SchoolTimelineDetailContainer.redirect =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const navigate = getMultiPlatformNavigation();
    const { profileId, eventKey } = params;
    const event = getLinewizeEventRecordByKey(state, eventKey);

    if (!flags.schoolTimeline.isEnabled()) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: `/profiles/${profileId}/school/summary`,
          },
        })
      );
    }

    if (!event) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: `/profiles/${profileId}/school/timeline`,
          },
        })
      );
    }
  };

export default SchoolTimelineDetailContainer;
