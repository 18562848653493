import { List } from 'immutable';
import api from '../../api';
import {
  receiveSummary,
  receiveSummaryAppsAndWebActivity,
  receiveSummaryBlockedWebActivity,
  receiveSummaryPerDays,
  receiveSummaryPerHours,
  receiveTodaysSummaryPerHours,
  refreshSummaryPerDays,
  refreshSummaryPerHours,
  requestSummary,
  requestSummaryAppsAndWebActivity,
  requestSummaryBlockedWebActivity,
  requestSummaryPerDays,
  requestSummaryPerHours,
} from './actions';
import { formatDayToAPI, isSameDate } from '../../helpers/dates';
import {
  ActivityTypes,
  AppActivityRecord,
  HoursPerDayActivityRecord,
  WebDomainActivityRecord,
} from '../../records/activity';
import {
  REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY,
  REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY,
} from './constants';
import {
  getAccountCurrentTime,
  getCurrentTime,
  getSelectedDateRange,
  getTimezone,
} from '../../selectors';
import {
  ActivitySummaryFilters,
  ActivitySummaryOrderBy,
} from '../../types/summary';
import {
  formatTo24HoursScreenTime,
  setAppThumbnail,
  shouldFetchSummary,
} from './helpers';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';
import { MinutesPerHourActivityPayload } from '../../records/activity/types/MinutesPerHourActivity.types';
import { HoursPerDayActivityPayload } from '../../records/activity/types/HoursPerDayActivity.types';

export const fetchSummary = profileId => dispatch => {
  dispatch(requestSummary());
  return api.summary
    .get({
      profileId,
    })
    .then(json => {
      return dispatch(receiveSummary(json));
    });
};

export const fetchSummaryIfNeeded = profileId => (dispatch, getState) => {
  if (shouldFetchSummary(getState().get('summary'))) {
    return dispatch(fetchSummary(profileId));
  }
  return Promise.resolve(false);
};

export const refreshSelectedDateScreenTime =
  (profileUid: string) => dispatch => {
    dispatch(refreshSummaryPerHours());
    return dispatch(fetchSelectedDateScreenTime(profileUid));
  };

export const refreshRangeScreenTime = (profileId: string) => dispatch => {
  dispatch(refreshSummaryPerDays());
  return dispatch(fetchRangeScreenTime(profileId));
};

export const refreshAppsAndWebActivity = (profileUid: string) => dispatch => {
  dispatch({
    type: REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY,
  });
  return dispatch(fetchAppsAndWebActivity(profileUid));
};

export const refreshBlockedWebActivity = (profileUid: string) => dispatch => {
  dispatch({
    type: REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY,
  });
  return dispatch(fetchBlockedWebActivity(profileUid));
};

export const fetchSelectedDateScreenTimePerHour = (
  api,
  { profileUid, state }
): Promise<MinutesPerHourActivityPayload[]> => {
  const { minDate: date } = getSelectedDateRange(state);
  return api.summaryPerHours.withCache(ttl1Minute).get({ profileUid, date });
};

export const fetchSelectedDateScreenTimePerDay = (
  api,
  { profileId, state }
): Promise<HoursPerDayActivityPayload[]> => {
  const currentTime = getAccountCurrentTime(state);
  const numberOfDays = 30; // We request always 30 days so we get 7 and 15 in one go

  const maxDate = formatDayToAPI(currentTime);
  const minDate = formatDayToAPI(
    currentTime.clone().subtract(numberOfDays - 1, 'd')
  );

  return api.screenTimePerDay.withCache(ttl1Minute).get({
    profileId: profileId.toString(),
    minDate,
    maxDate,
  });
};

export const fetchSelectedDateScreenTime =
  (profileUid: string) => (dispatch, getState) => {
    dispatch(requestSummaryPerHours());

    const state = getState();

    return fetchSelectedDateScreenTimePerHour(api, { profileUid, state }).then(
      data => {
        const timezone = getTimezone(state);
        const currTime = getCurrentTime(state);
        const { minDate: date } = getSelectedDateRange(state);

        const dataFormatted = formatTo24HoursScreenTime(data, timezone);

        if (isSameDate(currTime.format('YYYY-MM-DD'), date, timezone)) {
          dispatch(receiveTodaysSummaryPerHours(dataFormatted));
        }
        return dispatch(receiveSummaryPerHours(dataFormatted));
      }
    );
  };

export const fetchRangeScreenTime =
  (profileId: string) => (dispatch, getState) => {
    dispatch(requestSummaryPerDays());
    const state = getState();
    return fetchSelectedDateScreenTimePerDay(api, { profileId, state }).then(
      data =>
        dispatch(
          receiveSummaryPerDays(
            List(data.map(HoursPerDayActivityRecord.fromPayload))
          )
        )
    );
  };

export const fetchAppsAndWebActivity =
  (profileUid: string) => (dispatch, getState) => {
    dispatch(requestSummaryAppsAndWebActivity());
    const { minDate, maxDate } = getSelectedDateRange(getState());
    return api.summaryDomainsAndApps
      .withCache(ttl1Minute)
      .get({
        profileUid,
        filter: ActivitySummaryFilters.NotBlocked,
        minDate,
        maxDate,
      })
      .then(json =>
        dispatch(
          receiveSummaryAppsAndWebActivity(
            List(
              json.items
                .map(item => setAppThumbnail(item, getState()))
                .map(item =>
                  item.type === ActivityTypes.WebDomain
                    ? WebDomainActivityRecord.fromPayload(item)
                    : AppActivityRecord.fromPayload(item)
                )
            )
          )
        )
      );
  };

export const fetchBlockedWebActivity =
  (profileUid: string) => (dispatch, getState) => {
    dispatch(requestSummaryBlockedWebActivity());
    const state = getState();
    const { minDate, maxDate } = getSelectedDateRange(state);

    return api.summaryDomainActivity
      .withCache(ttl1Minute)
      .get({
        profileUid,
        filter: ActivitySummaryFilters.BlockedExplicitly,
        minDate,
        maxDate,
        order: [
          ActivitySummaryOrderBy.BlockedPagesDesc,
          ActivitySummaryOrderBy.MinutesDesc,
          ActivitySummaryOrderBy.Id,
        ],
      })
      .then(json =>
        dispatch(
          receiveSummaryBlockedWebActivity(
            List(
              json.items.map(item => WebDomainActivityRecord.fromPayload(item))
            )
          )
        )
      );
  };
