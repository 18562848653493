// TODO this is a temporal file to make everything compatible remove and refactor imports
import { ProfileRecord } from './profile';

export * from './gender';
export * from './profile';
export * from './profileStatus';
export * from './types/Gender.types';
export {
  Profile,
  ProfileBase,
  ProfileMethods,
  ProfilePayload,
  ProfileId,
  ProfileIdField,
  ProfileIdRaw,
} from './types/Profile.types';
export * from './types/ProfileStatus.types';

export default ProfileRecord;
