import * as React from 'react';
import { getLicenseDaysLeft } from '../../records/license';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { None, isDefaultCase, withCase, withSwitch } from '../../helpers/hocs';
import ProfilesLimitExceededTopbar, {
  isVisibleProfileLimitExceededTopbar,
} from './ProfilesLimitExceededTopbar';
import FreeTopbar, { isVisibleFreeTopbar } from './FreeTopbar';
import TrialTopbar, { isVisibleTrialTopbar } from './TrialTopbar';
import PremiumExpiresTopbar, {
  isVisiblePremiumTopbar,
} from './PremiumExpiresTopbar';
import DevicesLimitExceededTopbar, {
  isVisibleDeviceLimitExceededTopbar,
} from './DevicesLimitExceededTopbar';
import type { TopbarProps } from '../../containers/Topbar/TopbarContainer';
import { isHiddenTopbar } from './Topbar/predicate';

const getTopbar = withSwitch(
  withCase(isHiddenTopbar, None),
  withCase(isVisibleProfileLimitExceededTopbar, ProfilesLimitExceededTopbar),
  withCase(isVisibleDeviceLimitExceededTopbar, DevicesLimitExceededTopbar),
  withCase(isVisibleFreeTopbar, FreeTopbar),
  withCase(isVisibleTrialTopbar, TrialTopbar),
  withCase(isVisiblePremiumTopbar, PremiumExpiresTopbar),
  withCase(isDefaultCase, None)
);

const Topbar = ({
  license,
  profileCount,
  deviceCount,
  currentTime,
  timezone,
}: TopbarProps) => {
  const licenseDaysLeft = getLicenseDaysLeft(license, currentTime, timezone);

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        {getTopbar({
          license,
          profileCount,
          deviceCount,
          licenseDaysLeft,
          isMobile: false,
        })}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        {getTopbar({
          license,
          profileCount,
          deviceCount,
          licenseDaysLeft,
          isMobile: true,
        })}
      </RenderWhen>
    </React.Fragment>
  );
};

export default Topbar;
