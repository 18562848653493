import * as qinit from '../qinit';
import { RouteLocation } from '../types/RouteLocation.types';

export enum Environment {
  DEV = 'dev',
  PRE = 'pre',
  PRO = 'pro',
}

export enum iOSBundleName {
  MDMAPP = 'com.qustodio.mdm.app.family',
  PAR = 'com.qustodio.par.qustodio-family-parental-control-app',
}

export enum EventType {
  App = 301,
  Calls = 201, // we use subtype instead of this type
  Facebook = 220, // we use subtype instead of this type
  Panic = 500,
  Sms = 102, // we use subtype instead of this type
  Web = 101,
  Search = 103,
  Location = 400,
  LocationUnvailable = 401,
  LocationLast = 402,
  CallsIncoming = 280,
  CallsOutgoing = 281,
  CallsMissed = 282,
  CallsUnanswered = 283,
  CallsBlockedIncoming = 286,
  CallsBlockedOutgoing = 287,
  SmsIncoming = 284,
  SmsOutgoing = 285,
  SmsBlockedIncoming = 288,
  SmsBlockedOutgoing = 289,
  Twitter = 1,
  FacebookSocial = 200,
  FacebookSocialContact = 201,
  FacebookSocialNetwork = 202,
  FacebookSocialContactNew = 210,
  FacebookSocialActivity = 220,
  FacebookSocialActivityPost = 230,
  FacebookSocialActivityUserPost = 221,
  FacebookSocialActivityUserProfile = 222,
  FacebookSocialActivityUserComment = 223,
  FacebookSocialActivityUserLike = 224,
  FacebookSocialActivityUserEvent = 225,
  FacebookSocialActivityContactPost = 226,
  FacebookSocialActivityContactComment = 227,
  FacebookSocialActivityContactLike = 228,
  FacebookSocialActivityUserContactPost = 229,
  FacebookSocialActivityUserLink = 231,
  FacebookSocialActivityUserSharePhoto = 232,
  FacebookSocialMedia = 270,
  FacebookSocialMediaUserPhoto = 271,
  FacebookSocialMediaUserVideo = 272,
  FacebookSocialMediaContactPhoto = 273,
  FacebookSocialMediaContactVideo = 274,
  // BCK is sending event type as regular web-domain. So we have to create a fictional key
  Youtube = -100,
  // Events SafeNetworkEntering and SafeNetworkLeaving are not exposed to the user
  SafeNetworkDisablingProtection = 703,
  SafeNetworkEnablingProtection = 704,
  Unknown = 0,
}

export const EVENT_APP_CALCULATOR = 'Calculator';
export const EVENT_APP_MESSAGING = 'Messaging';

export const PLATFORM_UNKNOWN = 'PLATFORM_UNKNOWN';
export const PLATFORM_WINDOWS = 'PLATFORM_WINDOWS';
export const PLATFORM_MAC = 'PLATFORM_MAC';
export const PLATFORM_ANDROID = 'PLATFORM_ANDROID';
export const PLATFORM_IOS = 'PLATFORM_IOS';
export const PLATFORM_CHROME = 'PLATFORM_CHROME';
export type Platform =
  | typeof PLATFORM_UNKNOWN
  | typeof PLATFORM_WINDOWS
  | typeof PLATFORM_MAC
  | typeof PLATFORM_ANDROID
  | typeof PLATFORM_IOS
  | typeof PLATFORM_CHROME;

export enum SearchEngine {
  Youtube = 'youtube',
  Google = 'google',
  Bing = 'bing',
  Unknown = 'unknown',
}

export const ACTION_ALLOW = 0;
export const ACTION_BLOCK = 1;
export const ACTION_ALERT = 2;
export const ACTION_IGNORE = 3;

export const REASON_NO_MATCH = 0;
export const REASON_WHITELIST = 1;
export const REASON_TIME_RESTRICTIONS_QUOTA = 2;
export const REASON_TIME_RESTRICTIONS_RANGE = 3;
export const REASON_DOMAIN_LIST_ALLOW = 4;
export const REASON_DOMAIN_LIST_BLOCK = 5;
export const REASON_DOMAIN_LIST_ALERT = 6;
export const REASON_DOMAIN_LIST_IGNORE = 12;
export const REASON_CATEGORY_BLOCK = 7;
export const REASON_CATEGORY_ALERT = 8;
export const REASON_NOT_ALLOW_UNKNOWN_SITES = 9;
export const REASON_CONTACT_NEW = 201;
export const REASON_APP_BLOCK = 301;

export const DEFAULT_PROFILE_ID = 0;
export const ROUTE_URL_PROFILE = '/profiles/:profileId';
export const ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION =
  '/parents-app-standalone-confirmation';
export const ROUTE_PARENTS_APP_STANDALONE_WRONG_APP =
  '/parents-app-standalone-wrong-app';

export enum Routes {
  lockScreen = '/lockScreen',
  root = '/',
}

export enum BuildPlatform {
  browser = 'browser',
  ios = 'ios',
  android = 'android',
}

export const OS_MINIMUM_ANDROID_VERSION = 4.4;

export const ERROR_TIMEOUT = 'timeout';
export const ERROR_5XX = '5xx';
export const ERROR_4XX = '4xx';
export const ERROR_UNKNOWN = 'unknown';
export const ERROR_UNAUTHORIZED = 'unauthorized';
export const ERROR_ABORT = 'abort';
export const ERROR_NETWORK = 'network';
export const ERROR_LOGIN = 'login';

export const LINK_FOOTER = 'FOOTER';
export const LINK_CONTENT = 'CONTENT';
export type ModalLink = typeof LINK_FOOTER | typeof LINK_CONTENT;

// NUMBERS PROVIDED BY BACKEND
export const MAX_PASSWORD_LENGTH = 56;
export const MIN_PASSWORD_LENGTH = 6;
export const MAX_EMAIL_LENGTH = 235;
export const MAX_NAME_LENGTH = 40;

export const LINK_NAME = '/edit-name';
export const LINK_EMAIL = '/edit-email';
export const LINK_PASSWORD = '/edit-password';
export const LINK_LANGUAGE = '/edit-language';
export const LINK_TIMEZONE = '/edit-timezone';
export const LINK_COUNTRY = '/edit-country';
export const LINK_ACCOUNT_SETTINGS = '/account-settings';
export const LINK_DEVICES = '/devices';
export const LINK_FAMILY_LOCATOR = '/familyLocator';
export const LINK_PHONE = '/edit-phone';

export const DAYTIME_MORNING = Symbol('DAYTIME_MORNING');
export const DAYTIME_AFTERNOON = Symbol('DAYTIME_AFTERNOON');
export const DAYTIME_NIGHT = Symbol('DAYTIME_NIGHT');

export const apiWeekdayKeys: (
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thr'
  | 'fri'
  | 'sat'
  | 'sun'
)[] = ['mon', 'tue', 'wed', 'thr', 'fri', 'sat', 'sun'];

export const DEBUG_PROFILE_NAME = 'debug';

export const WEBFILTER_BLOCK = 'WEBFILTER_BLOCK';
export const WEBFILTER_ALERT = 'WEBFILTER_ALERT';
export const WEBFILTER_ALLOW = 'WEBFILTER_ALLOW';
export const WEBFILTER_IGNORE = 'WEBFILTER_IGNORE';
export const WEBFILTER_REMOVE = 'WEBFILTER_REMOVE';
export type WebfilterAction =
  | typeof WEBFILTER_BLOCK
  | typeof WEBFILTER_ALERT
  | typeof WEBFILTER_ALLOW
  | typeof WEBFILTER_IGNORE
  | typeof WEBFILTER_REMOVE;

// easy mapping between API-keys and Symbols:
export const WEBFILTER_ACTION_KEYS = ['BLOCK', 'ALERT', 'ALLOW'];
export type WebfilterActionKeys = 'BLOCK' | 'ALERT' | 'ALLOW';
export const WEBFILTER_ACTIONS = [
  WEBFILTER_BLOCK,
  WEBFILTER_ALERT,
  WEBFILTER_ALLOW,
];
export const WEBFILTER_DOMAIN_ACTIONS = [
  // array index equals state provided by api
  WEBFILTER_ALLOW,
  WEBFILTER_BLOCK,
  WEBFILTER_ALERT,
  WEBFILTER_IGNORE,
];
export const inverseWebFilterDomainActionToNumber = (action: WebfilterAction) =>
  WEBFILTER_DOMAIN_ACTIONS.findIndex(wda => wda === action);

export const WEBFILTER_CATEGORIES_SECTION = 'WEBFILTER_CATEGORIES_SECTION';
export const WEBFILTER_EXCEPTIONS_SECTION = 'WEBFILTER_EXCEPTIONS_SECTION';
export const WEBFILTER_SETTINGS_SECTION = 'WEBFILTER_SETTINGS_SECTION';

export type WebfilterSection =
  | typeof WEBFILTER_CATEGORIES_SECTION
  | typeof WEBFILTER_EXCEPTIONS_SECTION
  | typeof WEBFILTER_SETTINGS_SECTION;

export const EDIT_CATEGORY_ALL = Symbol('EDIT_CATEGORY_ALL');

export enum WebfilterCategoryAction {
  WEBFILTER_CATEGORY_BLOCK = 'WEBFILTER_BLOCK',
  WEBFILTER_CATEGORY_ALERT = 'WEBFILTER_ALERT',
  WEBFILTER_CATEGORY_ALLOW = 'WEBFILTER_ALLOW',
}

export enum SettingsToggleNames {
  // AccountSettingsToggleNames
  IsDailyReport = 'isDailyReport',
  IsWeeklyReport = 'isWeeklyReport',
  IsLockScreenEnabled = 'isLockScreenEnabled',
  // TimeLimitsToggleNames
  IsLockNavigation = 'isLockNavigation',
  IsLockComputer = 'isLockComputer',
  IsReportAlerts = 'isReportAlerts',
  // WebWebFilterToggleNames
  UnknownSites = 'isAllowUnknownSites',
  SafeSearch = 'isSafeSearch',
  ReportBlockedSites = 'isReportBlockedSites',
  BlockedUnsupportedBrowsers = 'isBlockedUnsupportedBrowsers',
  // AppRulesToggleNames
  IsAlertNewApps = 'isAlertNewApps',
  // SummaryAlerts
  IsSearchAlertsNotificationsEnabled = 'isSearchAlertsNotificationsEnabled',
}

// day ranges:
// if the amount of days left until license expires
// is within these ranges the stats notification
// will be displayed on the profile screen
export const STATS_NOTIFICATION_DAY_RANGES = [
  // [startDay, endDay]
  [375, 381],
  [10, 16],
];

export const LOCATION_REQUEST_DEBOUNCE_TIME = 2; // Minutes

export const APPLICATION_ALLOWED = 'APPLICATION_ALLOWED';
export const APPLICATION_BLOCKED = 'APPLICATION_BLOCKED';
export type ApplicationAction =
  | typeof APPLICATION_ALLOWED
  | typeof APPLICATION_BLOCKED;
export const APPLICATION_ACTIONS: ApplicationAction[] = [
  APPLICATION_ALLOWED,
  APPLICATION_BLOCKED,
];
export const inverseApplicationActionToNumber = (action: ApplicationAction) =>
  APPLICATION_ACTIONS.findIndex(ca => action === ca);

// onboarding device "type", does not match "platform"
export const DEVICE_TYPE_NONE = Symbol('DEVICE_TYPE_NONE') as unknown as string;
export const DEVICE_TYPE_ANDROID = Symbol(
  'DEVICE_TYPE_ANDROID'
) as unknown as string;
export const DEVICE_TYPE_IOS = Symbol('DEVICE_TYPE_IOS') as unknown as string;
export const DEVICE_TYPE_LAPTOP = Symbol(
  'DEVICE_TYPE_LAPTOP'
) as unknown as string;
export const DEVICE_TYPE_KINDLE = Symbol(
  'DEVICE_TYPE_KINDLE'
) as unknown as string;
export type DeviceTypeName =
  | typeof DEVICE_TYPE_NONE
  | typeof DEVICE_TYPE_ANDROID
  | typeof DEVICE_TYPE_IOS
  | typeof DEVICE_TYPE_LAPTOP
  | typeof DEVICE_TYPE_KINDLE;

export const FILTER_APPS_NONE = 'FILTER_APPS_NONE';
export const FILTER_APPS_ALL = 'FILTER_APPS_ALL';
export const FILTER_APPS_ANDROID = 'FILTER_APPS_ANDROID';
export const FILTER_APPS_IOS = 'FILTER_APPS_IOS';
export const FILTER_APPS_WINDOWS = 'FILTER_APPS_WINDOWS';
export const FILTER_APPS_MAC = 'FILTER_APPS_MAC';
export const FILTER_APPS_ALLOWED = 'FILTER_APPS_ALLOWED';
export const FILTER_APPS_BLOCKED = 'FILTER_APPS_BLOCKED';
export const FILTER_APPS_HASLIMIT = 'FILTER_APPS_HASLIMIT';
export type AppFilter =
  | typeof FILTER_APPS_NONE
  | typeof FILTER_APPS_ALL
  | typeof FILTER_APPS_ALLOWED
  | typeof FILTER_APPS_BLOCKED
  | typeof FILTER_APPS_HASLIMIT
  | typeof FILTER_APPS_ANDROID
  | typeof FILTER_APPS_IOS
  | typeof FILTER_APPS_WINDOWS
  | typeof FILTER_APPS_MAC;

export const CALLSMS_RULES_SECTION = 'CALLSMS_RULES_SECTION';
export const CALLSMS_CONTACTS_SECTION = 'CALLSMS_CONTACTS_SECTION';
export type CallsSmsRulesSection =
  | typeof CALLSMS_RULES_SECTION
  | typeof CALLSMS_CONTACTS_SECTION;

export const CALLSMS_EDIT_INCOMING_CALLS = 'CALLSMS_EDIT_INCOMING_CALLS';
export const CALLSMS_EDIT_OUTGOING_CALLS = 'CALLSMS_EDIT_OUTGOING_CALLS';
export type CallsSmsEditOption =
  | typeof CALLSMS_EDIT_INCOMING_CALLS
  | typeof CALLSMS_EDIT_OUTGOING_CALLS;

export const CALLSMS_RULES_BLOCK = 'CALLSMS_RULES_BLOCK';
export const CALLSMS_RULES_ALLOW = 'CALLSMS_RULES_ALLOW';
export const CALLSMS_RULES_REMOVE = 'CALLSMS_RULES_REMOVE';
export type CallSmsRulesAction =
  | typeof CALLSMS_RULES_BLOCK
  | typeof CALLSMS_RULES_ALLOW
  | typeof CALLSMS_RULES_REMOVE;
export const CALLSMS_CONTACT_ACTIONS: CallSmsRulesAction[] = [
  CALLSMS_RULES_ALLOW,
  CALLSMS_RULES_BLOCK,
];

export const SOCIAL_MONITORING_RULES_STATE_DISABLED = Symbol(
  'SOCIAL_MONITORING_RULES_STATE_DISABLED'
);
export const SOCIAL_MONITORING_RULES_STATE_MONITORING = Symbol(
  'SOCIAL_MONITORING_RULES_STATE_MONITORING'
);
export const SOCIAL_MONITORING_RULES_STATE_WAITING_COMPUTER = Symbol(
  'SOCIAL_MONITORING_RULES_STATE_WAITING_COMPUTER'
);
export const SOCIAL_MONITORING_RULES_STATE_WAITING_FACEBOOK_LOGIN = Symbol(
  'SOCIAL_MONITORING_RULES_STATE_WAITING_FACEBOOK_LOGIN'
);
export const SOCIAL_MONITORING_RULES_STATES = [
  SOCIAL_MONITORING_RULES_STATE_DISABLED,
  SOCIAL_MONITORING_RULES_STATE_MONITORING,
  SOCIAL_MONITORING_RULES_STATE_WAITING_COMPUTER,
  SOCIAL_MONITORING_RULES_STATE_WAITING_FACEBOOK_LOGIN,
];

export const CACHE_GEOCODE_KEY = 'geocode';
export const CACHE_GEOCODE_SIZE = 1000;

export enum PanicRulesContactType {
  Phone = 'PHONE',
  Email = 'EMAIL',
}
export enum PanicRulesContactStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}
export const PANIC_RULES_DISABLE = Symbol('PANIC_RULES_DISABLE');
export const PANIC_RULES_ALLOW = Symbol('PANIC_RULES_ALLOW');
export const PANIC_RULES_REMOVE = Symbol('PANIC_RULES_REMOVE');
export const PANIC_RULES_MAX_CONTACTS = 4;
export const PANIC_ACTION_SEE_DETAILS = Symbol('PANIC_ACTION_SEE_DETAILS');
export const PANIC_ACTION_SEE_CONTACTS = Symbol('PANIC_ACTION_SEE_CONTACTS');
export const PANIC_ACTION_STOP = Symbol('PANIC_ACTION_SEE_STOP');
export const ACTION_VISIT = Symbol('ACTION_VISIT');
export const ACTION_VIEW_DETAILS = Symbol('ACTION_VIEW_DETAILS');
export const ACTION_VIEW_YOUTUBE_SETTINGS = Symbol(
  'ACTION_VIEW_YOUTUBE_SETTINGS'
);
export enum AccountActions {
  logout,
  helpCenter,
  carePlus,
}

export const TICK_INTERVAL_MS = 60 * 1000;
export const SESSION_TIMEOUT_MS = 5 * 60 * 1000;

export enum AuthorizationApplication {
  standaloneAndroid = 'family-parent_only_app_android', // Used by Android Standalone app
  standaloneIos = 'family-parent_only_app_ios', // Used by iOS Standalone app
  WebOrLegacyMobile = 'family-mobile', // Used by old Android/iOS apps, and for web
  authorizationCode = 'family-authorization-code', // Used only for exchanging the authorization codes
  web = 'family-parweb_auth_code', // Used by web SSO Client
}

export const PAYMENT_PROVIDER_GOOGLEPLAY = 'googleplay';
export const PAYMENT_PROVIDER_CHARGEBEE = 'chargebee';
export const PAYMENT_PROVIDER_APPLE = 'apple';
export const PAYMENT_PROVIDER_DISABLE = 'disable';
export type PaymentProvider = 'apple' | 'googleplay' | 'chargebee' | 'disable';

export const MINUTES_IN_HOUR = 60;
export const ONE_DAY_IN_MINUTES = 24 * 60;
export const ONE_WEEK_IN_MINUTES = ONE_DAY_IN_MINUTES * 7;

export const UPDATE_PROFILE_RULES_DELAY = 1000;

export enum SummaryDateRanges {
  Today = 1,
  Days7 = 7,
  Days15 = 15,
  Days30 = 30,
  CustomPastDay = Infinity,
}

export enum SummaryExpandedView {
  TopActivity,
  YoutubeActivity,
  NotExpanded,
}

export const MENU_OPTION_ACTIVITY = 'activity';
export const MENU_OPTION_SUMMARY = 'summary';
export const MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY = 'familyAndSchoolSummary';
export const MENU_OPTION_ACTIVITY_TIMELINE = 'activityTimeline';
export const MENU_OPTION_RULES = 'rules';
export const MENU_OPTION_INSIGHTS = 'insights';
export const MENU_OPTION_STUDENT_SUMMARY = 'studentSummary';
export const MENU_OPTION_STUDENT_ACTIVITY_TIMELINE = 'studentActivityTimeline';

export const RULE_DAILY_TIME_LIMITS = 'dailyTimeLimits';
export const RULE_RESTRICTED_TIMES = 'restrictedTimes';
export const RULE_ROUTINES = 'routines';
export const RULE_WEB_FILTERING = 'webFiltering';
export const RULE_GAMES_AND_APPS = 'gamesAndApps';
export const RULE_CALLS_AND_SMS = 'callsAndSms';
export const RULE_LOCATION = 'location';
export const RULE_SOCIAL_MONITORING = 'socialMonitoring';
export const RULE_PANIC_BUTTON = 'panicButton';
export const RULE_YOUTUBE = 'youtube';
export const DOMAIN_YOUTUBE = 'youtube.com';
export const SUBDOMAIN_MOBILE_YOUTUBE = 'm.youtube.com';
export const PLACE_FORM_MIN_RADIUS_VALUE_METERS = 100;

export const MICRO_TO_BASE = 1000000;

export type Domains = typeof DOMAIN_YOUTUBE | typeof SUBDOMAIN_MOBILE_YOUTUBE;

export enum DeviceWidthBreakpoints {
  small = 768,
  medium = 1024,
  large = 1280,
  extraLarge = 1536,
}

export enum VideoActions {
  Allowed = 0,
  Blocked = 1,
  Play = 800,
  Unknown = 999,
}

export enum VideoSources {
  Youtube = 'youtube',
  Unknown = 'unknown',
}

// we could add more action types here.
export type UrlDetailsAction = VideoActions;

export enum DevicePlatform {
  None = 'none',
  Android = 'android',
  Ios = 'ios',
  Windows = 'windows',
  Mac = 'mac',
  Kindle = 'kindle',
  Chromebook = 'chromebook',
}

export enum DeviceType {
  None = 'none',
  Smartphone = 'smartphone',
  Tablet = 'tablet',
  PC = 'pc',
}

export enum PathTitles {
  yourFamily = 'Your Family',
  yourDevices = 'Your Devices',
  familyLocator = 'Family Locator',
  yourAccount = 'Your Account',
  carePlus = 'Care Plus',
  helpCenter = 'Help Center',
  logout = 'Logout',
  wellbeing = 'Wellbeing',
}

export enum ISOLanguage {
  english = 'en',
  german = 'de',
  spanish = 'es',
  finnish = 'fi',
  french = 'fr',
  italian = 'it',
  lithuanian = 'lt',
  norwegian = 'nb',
  portugueseBrazil = 'pt_BR',
  swedish = 'sv',
  chineseHans = 'zh-Hans',
  japanese = 'ja',
  notSet = '',
}

export enum Language {
  english = 'English',
  german = 'Deutsch',
  spanish = 'Español',
  finnish = 'Suomi, Suomen Kieli',
  french = 'Français',
  italian = 'Italiano',
  lithuanian = 'Lietuvių',
  norwegian = 'Norsk',
  portugueseBrazil = 'Português (Brasil)',
  swedish = 'Svenska',
  chineseHans = 'Chinese',
  japanese = 'Japanese',
  notSet = '',
}

export enum NotificationType {
  ApplicationUsageIncreased = 1,
  Unknown = -1,
}

export enum StandaloneFlow {
  None,
  Purchase,
  PurchaseAtlimit,
}

export enum SortOutput {
  GreaterThan = 1,
  LessThan = -1,
  Equals = 0,
}

// Family Locator related constants
export const NO_EVENT_KEY = '';
export const FEET_IN_ONE_METER = 3.28084;

export enum FormNames {
  editPassword = 'editPassword',
  login = 'login',
  lockScreen = 'lockScreen',
}

export enum OnBoardingRoutes {
  onboardingRoot = '/onboarding',
  addChild = '/onboarding/add-child',
  addDevice = '/onboarding/add-device',
  finish = '/onboarding/finish',
}

export enum OnBoardingRoutesBeta {
  onboardingRoot = '/onboarding-beta',
  addProfile = '/onboarding-beta/add-profile',
  chooseDevice = '/onboarding-beta/choose-device',
  setupRemoteDevice = '/onboarding-beta/setup-remote-device',
  setupLocalDevice = '/onboarding-beta/setup-local-device',
  setupComplete = '/onboarding-beta/setup-complete',
  welcomeModal = 'welcomeModal',
}

export enum BuildType {
  par = 'par',
}

export enum CardDimensions {
  width = '375px',
  heightSmall = '320px',
  height = '420px',
  heightLarge = '856px',
}

export type SsoStateData = {
  [key: string]: {
    createdAt: string;
    expiresOn: string;
  };
} & { location: RouteLocation; query: { [key: string]: any } };

export type SsoResult = {
  code: string;
  state: string;
  isNewAccount?: string;
  wait: boolean;
};

export enum PushPluginConfig {
  CHANNEL_ID = 'PushPluginChannel',
  CHANNEL_DESCRIPTION = 'Qustodio',
  CHANNEL_IMPORTANCE = 4,
  CHANNEL_VISIBILITY = -1,
  NOTIFICATION_ICON = 'notification_icon',
}

export const SSO_LOGIN_URI = `${qinit.tenant.common.sso.uri}login`;
export const SSO_SIGNUP_URI = `${qinit.tenant.common.sso.uri}sign-up`;
export const SSO_BACK_URI = 'mobile/close';
export const SSO_DIRECT_PURCHASE_SIGNUP_URI = `${qinit.tenant.common.sso.uri}direct-purchase-sign-up`;

export type InAppBrowserConfig = {
  hidden: 'yes' | 'no';
  hidespinner: 'yes' | 'no';
  zoom: 'yes' | 'no';
  location: 'yes' | 'no';
  toolbar: 'yes' | 'no';
  transitionstyle: 'fliphorizontal' | 'crossdissolve' | 'coververtical';
  enableViewportScale: 'yes' | 'no';
};

export enum SsoStartMode {
  signUp,
  login,
  directPurchase,
}

export enum SsoParsingResponseFailures {
  invalidState = 'Invalid State',
  invalidCode = 'Invalid Code',
}

export enum SsoSourcePlatforms {
  ios = 'iOS',
  web = 'Web',
  android = 'Android',
}

export enum SmallModalDimensions {
  width = 320,
  height = 385,
}

export enum MediumModalDimensions {
  width = 640,
  height = 738,
}

export const modalDimensions = {
  regular: {
    width: 320,
    height: 395,
  },
  small: SmallModalDimensions,
  medium: MediumModalDimensions,
};

export const REMOTE_ONBOARDING_TOKEN_LIFESPAN_MS = 30 * 60 * 1000;

export type AppCategory = {
  filter:
    | typeof FILTER_APPS_ALL
    | typeof FILTER_APPS_ALLOWED
    | typeof FILTER_APPS_BLOCKED
    | typeof FILTER_APPS_HASLIMIT
    | typeof FILTER_APPS_ANDROID
    | typeof FILTER_APPS_IOS
    | typeof FILTER_APPS_WINDOWS
    | typeof FILTER_APPS_MAC;
  label: string;
};

export type SideBarMode = 'regular' | 'onboarding';

export enum StudentSummaryCardMaxItems {
  signatures = 14,
  categories = 5,
}

export enum Email {
  mySchool = 'myschool@qustodio.com',
  support = 'support@qustodio.com',
  therapy = 'therapy@qustodio.com',
}

/*
The birth year field now is nullable in backend See https://qustodio.atlassian.net/browse/BCK-7812.
When a null is stored in the db, the API returns 01-01-1970. This date becomes the - option on the PAR side.
When a user chooses the - option on the PAR side, we can either send null, as the API will store null on the db either way.
*/
export enum AppDataArrangement {
  noBirthDayUI = '-',
  noBirthDayServerResponse = '1970-01-01',
}

export type LockState = 'disabled' | 'unlocked' | 'locked';
export interface BiometricResult {
  show: boolean;
}

export const LastBiometricValidTimeInSeconds = 30;

export enum YoutubeBundleNameByPlatform {
  ios = 'com.google.ios.youtube',
  android = 'com.google.android.youtube',
}

export const InvalidFileTypesInLinewizeEvents = [
  '.js',
  '.css',
  '.jpg',
  '.jpeg',
  '.gif',
  '.php',
  '.png',
];

// we consider any device that has no activity in the last 72 hours offline
export const MaxNoActivityTimeInMinutes = 60 * 72;

// We consider any device a long time offline when the device has no activity for the last 30 days
export const LongPeriodNoActivityInMInutes = 60 * 24 * 30;

// We hardcode the school trial duration until we can get it from BCK
export const SchoolTrialDuration = 3;

// hardcoded campaign code for school referrals
export const SchoolReferralCampaignCodes = ['trial30school'];
