import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import {
  updateAccount,
  modifyAccount,
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { getAccount } from '../../selectors';
import { showErrorToast } from './helpers';
import EditCountry from '../../components/AccountSettings/EditCountry';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';

const mapStateToProps = state => {
  const { country } = getAccount(state);
  return {
    country,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickSaveButton: country => dispatch(onClickSaveButton(country)),
});

function onClickSaveButton(country) {
  return (dispatch, getState) => {
    return Promise.resolve(getAccount(getState()).set('country', country))
      .then(updatedAccount => {
        dispatch(updateAccount(updatedAccount));
        return updatedAccount;
      })
      .then(account => {
        dispatch(goBackIfHistory());
        return account;
      })
      .then(account => dispatch(modifyAccount(account)))
      .then(() =>
        dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
      )
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(reverseAccountUpdate());
        dispatch(showErrorToast(error));
      });
  };
}

const EditCountryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCountry);

export default EditCountryContainer;
