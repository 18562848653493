import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { getLicense, canPurchase } from '../../selectors';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import State, { Dispatch } from '../../store/state';
import {
  navigateToGeneralHelp,
  navigateToUpgrade,
} from '../../actions/Navigation';
import ProfileAtLimitModal from '../../components/Modal/ProfileAtLimitModal';

export type ProfileAtLimitModalActionType = 'upgrade' | 'support' | 'ok' | null;

const mapStateToProps = (state: State) => {
  return {
    license: getLicense(state),
    canPurchase: canPurchase(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickFooterButton: (actionType: ProfileAtLimitModalActionType) => {
    if (actionType === 'upgrade') {
      trackButtonClicked(GenericPageNames.MyFamily, ButtonNames.Upgrade, {
        options: JSON.stringify({
          context: 'add_profile',
          reason: 'atlimit_profiles',
        }),
      });
      return dispatch(navigateToUpgrade());
    }

    if (actionType === 'support') {
      dispatch(goBackIfHistory());
      return dispatch(navigateToGeneralHelp());
    }

    // close
    return dispatch(goBackIfHistory());
  },
});
export type ProfileAtLimitModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ProfileAtLimitModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileAtLimitModal);

export default ProfileAtLimitModalContainer;
