import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  DurationInput,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  ModalStyledText,
  IconFamily,
  IconColor,
  Layout,
} from 'styleguide-react';
import { Moment } from 'moment';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import { formatHumanTwelveClockFormat, isTomorrow } from '../../helpers/dates';
import { when } from '../../helpers/render';
import {
  PauseTheInternetModalActionType,
  PauseTheInternetModalProps,
} from './types/PauseTheInternetModal.types';

const getTitle = (type: PauseTheInternetModalActionType): string =>
  ({
    add: t('Pause the internet for...'),
    edit: t('Pause the internet'),
  }[type]);

type EditInformationTextProps = {
  text: string;
  warning?: boolean;
};

const EditInformationText: React.FC<EditInformationTextProps> = ({
  text,
  warning = false,
}: EditInformationTextProps) => (
  <ModalStyledText>
    {when(
      warning,
      <Layout renderAs="span" marginRight="8">
        <Icon
          type={IconType.exclamationCircle}
          family={IconFamily.solid}
          color={IconColor.warning}
        />
      </Layout>
    )}
    <strong>{text}</strong>
  </ModalStyledText>
);

type EditTextProps = {
  pausedUntilTimeMinutes: number;
  currentTime: Moment;
  minutes: number;
  timezone: string;
  locale: string;
  isPauseTomorrow: boolean;
};

const EditText: React.FC<EditTextProps> = ({
  pausedUntilTimeMinutes,
  currentTime,
  minutes,
  timezone,
  locale,
  isPauseTomorrow,
}: EditTextProps) => {
  const pauseTime: Moment = currentTime.clone().add('minutes', minutes);
  const internetWillResume = minutes === 0;
  const newPauseTimeSet = minutes !== pausedUntilTimeMinutes;

  if (internetWillResume) {
    return (
      <EditInformationText
        text={t('The internet will be resumed now')}
        warning
      />
    );
  }

  const time = formatHumanTwelveClockFormat(pauseTime, timezone, locale);

  if (!internetWillResume && !newPauseTimeSet) {
    return (
      <EditInformationText
        text={
          isPauseTomorrow
            ? t('Current pause: until {{time}} tomorrow', { time })
            : t('Current pause: until {{time}}', { time })
        }
      />
    );
  }

  if (newPauseTimeSet) {
    return (
      <EditInformationText
        text={
          isPauseTomorrow
            ? t('New pause: until {{time}} tomorrow', { time })
            : t('New pause: until {{time}}', { time })
        }
      />
    );
  }

  return null;
};

type AddTextProps = {
  currentTime: Moment;
  minutes: number;
  timezone: string;
  locale: string;
  isPauseTomorrow: boolean;
};

const AddText: React.FC<AddTextProps> = ({
  currentTime,
  timezone,
  minutes,
  locale,
  isPauseTomorrow,
}: AddTextProps) => {
  const time = formatHumanTwelveClockFormat(
    currentTime.clone().add('minutes', minutes),
    timezone,
    locale
  );

  return (
    <Layout marginTop="24">
      <ModalStyledText>
        <strong>
          {isPauseTomorrow
            ? t('Until {{time}} tomorrow', {
                time,
              })
            : t('Until {{time}}', {
                time,
              })}
        </strong>
      </ModalStyledText>
    </Layout>
  );
};

const PauseTheInternetModal: React.FunctionComponent<
  PauseTheInternetModalProps
> = ({
  type,
  timezone,
  locale,
  pausedUntilTimeMinutes,
  familyPauseButtonFeatureFlag,
  onSetPauseDuration,
  onClickClose,
  isOverlapped,
  getCurrentTime,
}: PauseTheInternetModalProps) => {
  const [minutes, setValue] = React.useState(pausedUntilTimeMinutes ?? 0);
  const currentTime = getCurrentTime(timezone);
  const pauseTime: Moment = currentTime.clone().add('minutes', minutes);
  const isPauseTomorrow = isTomorrow(pauseTime, currentTime);

  if (!familyPauseButtonFeatureFlag) return null;

  return (
    <Modal
      title={getTitle(type)}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={() => onSetPauseDuration(type, minutes)}
          size={ButtonSize.medium}
          disabled={type === 'add' && !minutes}
          block
        >
          {t('Confirm')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="pause-internet-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        {when(
          type === 'edit',
          <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
            <ModalStyledText marginBottom="8">
              {t('Edit the remaining time below:')}
            </ModalStyledText>
          </FlexLayout>
        )}

        <Layout marginTop="24">
          <DurationInput
            value={minutes}
            onChange={(minutes: number) => setValue(minutes)}
            maxMinutes={24 * 60}
          />
        </Layout>

        {when(
          type === 'edit',
          <FlexLayout
            mainaxis="column"
            maxWidth="240"
            alignSelf="center"
            marginTop="16"
          >
            <EditText
              pausedUntilTimeMinutes={pausedUntilTimeMinutes}
              currentTime={currentTime}
              minutes={minutes}
              timezone={timezone}
              locale={locale}
              isPauseTomorrow={isPauseTomorrow}
            />
          </FlexLayout>
        )}

        {when(
          type === 'add' && minutes > 0,
          <AddText
            currentTime={currentTime}
            minutes={minutes}
            timezone={timezone}
            locale={locale}
            isPauseTomorrow={isPauseTomorrow}
          />
        )}

        {when(
          isOverlapped(minutes),
          <FlexLayout mainaxis="row" maxWidth="240" alignSelf="center">
            <Layout renderAs="span">
              <Icon
                type={IconType.infoCircle}
                family={IconFamily.solid}
                color={IconColor.warning}
              />
            </Layout>
            <ModalStyledText textAlign="center">
              {t('This pause will overrule your scheduled time limit rules')}
            </ModalStyledText>
          </FlexLayout>
        )}
      </FlexLayout>
    </Modal>
  );
};
export default PauseTheInternetModal;
