import { isPremiumComplete } from '../../../records/license/helpers';
import { TopBarPredicatesProps } from '../types/Topbar.types';

export const isVisibleDeviceLimitExceededTopbar = ({
  license,
  deviceCount,
}: TopBarPredicatesProps) => {
  // UX: we need to hide this topbar when premium_complete license even if there is over limit.
  const isNotPremiumComplete = !isPremiumComplete(license.subtype);
  return deviceCount > license.maxDevices && isNotPremiumComplete;
};
