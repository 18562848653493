import * as React from 'react';
import type { StudentTopWidgetsProps } from '../../containers/Schools/StudentTopWidgetsContainer';
import SchoolMiniActivity from '../Dashboard/DashboardTopCards/SchoolMiniActivitySummary/SchoolMiniActivity';
import { t } from '../../lib/i18n';

import SchoolMiniActivitySummary from '../Dashboard/DashboardTopCards/SchoolMiniActivitySummary/SchoolMiniActivitySummary';

const StudentTopWidgets = ({
  topCategory,
  topSignature,
  errorOnLoading,
  isFeatureDisabled,
  isLoadingCategories,
  profileName,
  isCombined,
  isDelegated,
  loadWidget,
  navigateToAllCategories,
  navigateToAllSignatures,
}: StudentTopWidgetsProps) => {
  React.useEffect(() => {
    loadWidget();
  });

  if (isFeatureDisabled) {
    return (
      <SchoolMiniActivitySummary type="no-shared" profileName={profileName} />
    );
  }
  if (errorOnLoading) {
    return <SchoolMiniActivitySummary type="error" />;
  }
  if (isLoadingCategories) {
    return <SchoolMiniActivitySummary type="loading" />;
  }
  if (topCategory === undefined || topSignature === undefined) {
    return (
      <SchoolMiniActivitySummary
        type="no-activity"
        profileName={profileName}
        isDelegated={isDelegated}
        isCombined={isCombined}
      />
    );
  }
  return (
    <SchoolMiniActivitySummary type="info">
      <SchoolMiniActivity
        title={t(topCategory)}
        subTitle={t('Most used category')}
        onClick={navigateToAllCategories}
      />
      <SchoolMiniActivity
        title={t(topSignature)}
        subTitle={t('Most used app')}
        onClick={navigateToAllSignatures}
      />
    </SchoolMiniActivitySummary>
  );
};

export default StudentTopWidgets;
