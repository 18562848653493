import State, { Dispatch } from '../store/state';
import { SummaryDateRanges } from '../constants';
import { getProfileOrDefault } from '../selectors';
import { fetchSignatures, fetchCategories } from '../ducks/studentSummary';
import { selectDateRange } from './ProfileActions';

export const selectedNewDateRange =
  (range: SummaryDateRanges, profileId: string) =>
  (dispatch: Dispatch, getState: () => State) => {
    dispatch(selectDateRange(profileId, range));

    const profile = getProfileOrDefault(getState(), Number(profileId));
    dispatch(fetchSignatures(profile.uid));
    dispatch(fetchCategories(profile.uid));
  };
