import { connect } from 'react-redux';
import StudentPauseInfoModal from '../../components/Modal/StudentPauseInfoModal';
import { StudentPauseInfoModalProps } from '../../components/Modal/types/StudentPauseInfoModal.type';
import { goBackIfHistory } from '../../ducks/routing';
import {
  getCurrentSchoolEndDatetime,
  getCurrentSchoolStartDatetime,
  getNextSchoolEndDatetime,
  getNextSchoolStartDatetime,
} from '../../selectors/studentPolicies';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State): Partial<StudentPauseInfoModalProps> => {
  const startNextPeriod =
    getCurrentSchoolStartDatetime(state) ||
    getNextSchoolStartDatetime(state) ||
    undefined;
  const endNextPeriod =
    getCurrentSchoolEndDatetime(state) ||
    getNextSchoolEndDatetime(state) ||
    undefined;
  return {
    startNextPeriod,
    endNextPeriod,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch
): Partial<StudentPauseInfoModalProps> => {
  return {
    onClickClose: () => dispatch(goBackIfHistory()),
  };
};

const StudentPauseInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentPauseInfoModal);

export default StudentPauseInfoModalContainer;
