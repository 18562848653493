import * as React from 'react';
import { t } from '../../lib/i18n';
import { IconType } from 'styleguide-react';
import type { YoutubeFlyoverProps } from '../../containers/YoutubeRules/YoutubeFlyoverContainer';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';

const PREMIUM_FLYOVER_TEST_ID = 'youtube-rules-premium-flyover';

const YoutubePremiumFlyover = ({
  canPurchase,
  accountLocale,
  onClickClose,
  onClickUpgrade,
}: YoutubeFlyoverProps) => {
  const actionButtonTitle = canPurchase ? t('Upgrade') : t('Ok');
  const actionButtonFn = canPurchase ? onClickUpgrade : onClickClose;

  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('YouTube Monitoring') }}
      testId={PREMIUM_FLYOVER_TEST_ID}
      topbarText={t('{1}Upgrade your plan{/1} to manage YouTube monitoring!', {
        1: x => x,
      })}
      title={t('What can you do with YouTube monitoring?')}
      imageSrc={`/illustrations/Youtube/premium_youtube-rules__${accountLocale}.png`}
      actionButton={{
        title: actionButtonTitle,
        onClick: actionButtonFn,
        testId: `${PREMIUM_FLYOVER_TEST_ID}-upgrade`,
      }}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.search,
          title: t('View searches'),
          subtitle: t('See exactly what your child is searching for'),
        },
        {
          iconType: IconType.film,
          title: t('See what your child watched'),
          subtitle: t('Ensure they’re sticking to appropriate content'),
        },
        {
          iconType: IconType.stopWatch,
          title: t('Set healthy limits'),
          subtitle: t(
            'Encourage intentional viewing with time limits and blocks'
          ),
        },
      ]}
    />
  );
};

export default YoutubePremiumFlyover;
