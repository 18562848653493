import { NullableMap } from 'immutable';
import State from '../../store/state';
import { LinewizeEventRecord } from '../../records/linewizeEvent/types/linewizeEvents';

export const linewizeEvents = (state: State) => state.get('linewizeEvents');

export const getLinewizeEventsStatus = (state: State) =>
  linewizeEvents(state).get('status');

export const getLinewizeEventsUuids = (state: State) =>
  linewizeEvents(state).get('uuids');

export const getLinewizeEventsHasMore = (state: State) =>
  linewizeEvents(state).get('hasMore');

export const getLinewizeEventsRecords = (
  state: State
): NullableMap<string, LinewizeEventRecord> =>
  state.get('records').get('linewizeEvents');

export const getLinewizeEventRecordByKey = (state: State, eventKey: string) =>
  getLinewizeEventsRecords(state).get(eventKey);

export const getLinewizeEventsFilter = (state: State) =>
  linewizeEvents(state).get('filter');

export const getTimelineSearchEnabled = (state: State) =>
  linewizeEvents(state).get('timelineSearchEnabled');

export const getTimelineVideoEnabled = (state: State) =>
  linewizeEvents(state).get('timelineVideoEnabled');

export const getTimelineBlockedEnabled = (state: State) =>
  linewizeEvents(state).get('timelineBlockedEnabled');

export const getTimelineAllowedEnabled = (state: State) =>
  linewizeEvents(state).get('timelineAllowedEnabled');

export const getLinewizeEventsLastUpdated = (state: State) =>
  linewizeEvents(state).get('lastUpdated');
