import { List } from 'immutable';
import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';
import {
  PolicyAction,
  PolicyActionPayload,
  policyAction,
} from './types/Policy.types';
import type {
  PolicyApplicationRules,
  PolicyApplicationRulesPayload,
} from './types/PolicyApplicationRules.types';
import { PolicyApplicationOperations } from './policyApplication';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../../api/mappings/valueRestrictions';

export const policyApplicationDefaultActionRecordToPayload = (
  defaultAction?: PolicyAction
) => restrictValueFromKey(defaultAction, policyAction);

export const policyApplicationDefaultActionPayloadToRecord = (
  defaultAction?: PolicyActionPayload
) => restrictKeyFromValue(defaultAction, policyAction);

export const PolicyApplicationRulesOperations = getRecordOperations<
  PolicyApplicationRules,
  PolicyApplicationRulesPayload
>({
  defaultFields: {
    defaultAction: null,
    appRulesList: List([]),
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    defaultAction: policyApplicationDefaultActionPayloadToRecord(
      payload?.default_action
    ),
    appRulesList: payload.app_rules_list
      ? List(
          payload.app_rules_list.map(v =>
            PolicyApplicationOperations.fromPayload(v)
          )
        )
      : List([]),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    default_action: policyApplicationDefaultActionRecordToPayload(
      record?.defaultAction
    ),
    app_rules_list: record?.appRulesList?.size
      ? record.appRulesList
          .map(v => PolicyApplicationOperations.serialize(v))
          .toJS()
      : [],
  }),
});
