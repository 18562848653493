import * as React from 'react';
import {
  FlexLayout,
  RckBaseColor,
  Typography,
  ShadowedCard,
} from 'styleguide-react';

import greenCircleIcon from '../../../assets/base/images/wellbeing/check-circle-green.svg';
import redCircleIcon from '../../../assets/base/images/wellbeing/check-circle-red.svg';
import greenTitleIcon from '../../../assets/base/images/wellbeing/title-icon-green.svg';
import redTitleIcon from '../../../assets/base/images/wellbeing/title-icon-red.svg';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';

interface WellbeingListProps {
  title: string;
  iconTitle?: boolean;
  items: string[];
  colorScheme: Extract<RckBaseColor, 'red' | 'green'>;
}

const ItemIcon = ({ colorScheme }: Pick<WellbeingListProps, 'colorScheme'>) => {
  /*
  This icon style is not implemented.
  It has been decided to use SVG for now and then
  replace it with the corresponding icon when it's implemented
  */
  return (
    <img
      src={colorScheme === 'green' ? greenCircleIcon : redCircleIcon}
      width="32px"
      alt=""
    />
  );
};

const TitleIcon = ({
  colorScheme,
}: Pick<WellbeingListProps, 'colorScheme'>) => {
  /*
  This icon style is not implemented.
  It has been decided to use SVG for now and then
  replace it with the corresponding icon when it's implemented
  */
  return (
    <img
      src={colorScheme === 'green' ? greenTitleIcon : redTitleIcon}
      width="40px"
      alt=""
    />
  );
};

const WellbeingList = ({
  title,
  iconTitle,
  items,
  colorScheme,
}: WellbeingListProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screenSize => (
        <FlexLayout
          mainaxis="column"
          paddingLeft={isDesktop(screenSize) ? '32' : '24'}
          paddingRight={isDesktop(screenSize) ? '32' : '24'}
          width="100%"
        >
          <FlexLayout
            mainaxis="column"
            width="100%"
            maxWidth={isDesktop(screenSize) ? '800' : '520'}
            alignSelf="center"
          >
            <ShadowedCard className="par-wellbeing-list">
              <FlexLayout
                mainaxis="row"
                alignSelf="center"
                paddingBottom="32"
                mainaxisAlignment="center"
              >
                {iconTitle && (
                  <FlexLayout
                    mainaxis="column"
                    marginRight="8"
                    alignSelf="center"
                  >
                    <TitleIcon colorScheme={colorScheme} />
                  </FlexLayout>
                )}
                <FlexLayout mainaxis="column" alignSelf="center">
                  <Typography
                    type={!isDesktop(screenSize) ? 'title2' : 'title1'}
                    weight="semibold"
                    align="center"
                  >
                    {title}
                  </Typography>
                </FlexLayout>
              </FlexLayout>
              <FlexLayout mainaxis="column">
                <ul className="par-wellbeing-list__list">
                  {items.map(item => (
                    <li key={item} className="par-wellbeing-list__list-item">
                      <FlexLayout mainaxis="row">
                        <FlexLayout
                          mainaxis="column"
                          marginRight="16"
                          alignSelf="center"
                        >
                          <ItemIcon colorScheme={colorScheme} />
                        </FlexLayout>
                        <FlexLayout mainaxis="column" alignSelf="center">
                          <Typography
                            type={!isDesktop(screenSize) ? 'body2' : 'body1'}
                          >
                            {item}
                          </Typography>
                        </FlexLayout>
                      </FlexLayout>
                    </li>
                  ))}
                </ul>
              </FlexLayout>
            </ShadowedCard>
          </FlexLayout>
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

export default WellbeingList;
