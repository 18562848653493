import * as React from 'react';
import { LinewizeEventRecord } from '../../../records/linewizeEvent/types/linewizeEvents';
import { StyledHeader } from 'styleguide-react';
import EventsSchoolPeriod from './EventsSchoolPeriod';
import {
  LinewizeEventAction,
  LinewizeEventWithActions,
  SchoolHours,
} from '../../../businessLogic/LinewizeEvents/LinewizeEvent.types';
import {
  formatDateNice,
  isTodayDatetime,
  parseISODateString,
} from '../../../helpers/dates';
import { t } from '../../../lib/i18n';

interface EventsDateProps {
  eventsByDate: {
    date: string;
    events: Array<{
      type: SchoolHours;
      events: LinewizeEventWithActions[];
    }>;
  };
  accountLocale: string;
  accountTimezone: string;
  onActionClick: (
    event: LinewizeEventRecord,
    action: LinewizeEventAction
  ) => void;
}

const EventsDate: React.FC<EventsDateProps> = ({
  eventsByDate: { date, events },
  accountLocale,
  accountTimezone,
  onActionClick,
}) => {
  const isToday = (date: string): boolean => {
    const dateTime = parseISODateString(date);
    return isTodayDatetime(dateTime);
  };
  const formatDate = (date: string): string => {
    if (isToday(date)) {
      return t('Today');
    }
    return formatDateNice(date, accountTimezone, accountLocale);
  };
  return (
    <React.Fragment>
      <StyledHeader
        type="h4"
        className="par-school-timeline__date"
        testId="events-date-filter"
      >
        {formatDate(date)}
      </StyledHeader>
      {events.map((eventType, index, array) => {
        const isFirstEvent = index === 0;
        const isTodaysFirstEvent = isFirstEvent && isToday(date);
        const isLastEvent = index === array.length - 1;
        return (
          <EventsSchoolPeriod
            key={`${date}-${eventType.type}`}
            eventsByPeriod={eventType}
            isFirstEvent={isFirstEvent}
            isTodayFirstEvent={isTodaysFirstEvent}
            isLastEvent={isLastEvent}
            onActionClick={onActionClick}
          />
        );
      })}
    </React.Fragment>
  );
};

export default EventsDate;
