import State from '../../store/state';

export const getAppCategoriesStatus = (state: State) =>
  state.getIn(['appCategories', 'status']);

export const getAppCategories = (state: State) =>
  state.getIn(['appCategories', 'appCategories']);

export const getAppCategory = (state: State, categoryId: string) =>
  state.getIn(['appCategories', 'appCategories', categoryId]);
