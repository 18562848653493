import * as React from 'react';
import { NotificationRecord } from '../../records/notification';
import { NotificationType } from '../../constants';
import UsageIncreasedNotification from './UsageIncreasedNotification';
import IconKeyboardArrowRight from '../Icons/IconKeyboardArrowRight';

const hasNotificationContent = (notification: NotificationRecord): boolean =>
  Object.values(NotificationType).includes(notification.type) &&
  !!notification.data;

const getNotificationContent = (
  notification: NotificationRecord,
  locale: string
): JSX.Element | null => {
  if (!notification.data) {
    return null;
  }
  switch (notification.type) {
    case NotificationType.ApplicationUsageIncreased: {
      const { usageIncrement, name, fileIconPath } = notification.data;
      const { createdAt } = notification;
      return (
        <UsageIncreasedNotification
          appIconPath={fileIconPath}
          title={name}
          increment={usageIncrement}
          date={createdAt}
          locale={locale}
        />
      );
    }
    default:
      return null;
  }
};

const Notification: React.FunctionComponent<{
  notification: NotificationRecord;
  locale: string;
}> = ({ notification, locale }) =>
  hasNotificationContent(notification) ? (
    <div className="Notification">
      <div className="Notification__content">
        {getNotificationContent(notification, locale)}
        <div className="Notification__arrow-right">
          <IconKeyboardArrowRight />
        </div>
      </div>
      <hr />
    </div>
  ) : null;

export default Notification;
