import intl from 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/it';
import 'intl/locale-data/jsonp/pt-BR';

if (!global.Intl) {
  global.Intl = intl;
}

export default intl;
