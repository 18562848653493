import * as React from 'react';
import { isDefaultCase, None, withCase, withSwitch } from '../../helpers/hocs';

import { isLoadingProfileNotificationTopBar } from './LoadingTopBar/isVisible';

import { TamperingTopBar } from './TamperingTopBar';
import { isVisibleTamperingTopBar } from './TamperingTopBar/isVisible';

import { ConversionTopBar } from './ConversionTopBar';
import { isVisibleConversionTopBar } from './ConversionTopBar/isVisible';

import { LogBookTopBar } from './LogBookTopBar';
import { isVisibleLogBookTapBar } from './LogBookTopBar/isVisible';

import { SafeNetworkTopBar } from './SafeNetworkTopBar';
import { isVisibleSafeNetworkTopBar } from './SafeNetworkTopBar/isVisible';

import { DelegationTopBar } from './DelegationTopBar';
import { isVisibleDelegationTopBar } from './DelegationTopBar/isVisible';

import { SummaryAlertsTopBar } from './SummaryAlertsTopBar';
import { isVisibleSummaryAlertsTopBar } from './SummaryAlertsTopBar/isVisible';

import { topBarProfileNotificationStates } from './types';

const ProfileNotificationTopBarContent = withSwitch(
  withCase(isLoadingProfileNotificationTopBar, None),
  withCase(isVisibleSummaryAlertsTopBar, SummaryAlertsTopBar),
  withCase(isVisibleTamperingTopBar, TamperingTopBar),
  withCase(isVisibleLogBookTapBar, LogBookTopBar),
  withCase(isVisibleConversionTopBar, ConversionTopBar),
  withCase(isVisibleDelegationTopBar, DelegationTopBar),
  withCase(isVisibleSafeNetworkTopBar, SafeNetworkTopBar),
  withCase(isDefaultCase, None)
);

const profileNotificationTopBarState = (
  props
): topBarProfileNotificationStates => {
  if (isVisibleSummaryAlertsTopBar(props)) return 'summaryAlerts';
  if (isVisibleSafeNetworkTopBar(props)) return 'safeNetwork';
  if (isVisibleLogBookTapBar(props)) return 'logBook';
  if (isVisibleTamperingTopBar(props)) return 'tampering';
  if (isVisibleConversionTopBar(props)) return 'conversion';
  return null;
};

const ProfileNotificationTopBar = props => {
  React.useEffect(() => {
    props.onLoad();
  }, []);

  React.useEffect(() => {
    if (!props.isLoading) {
      props.reportToAnalytics(profileNotificationTopBarState(props));
    }
  }, [props]);

  return ProfileNotificationTopBarContent({ ...props });
};
export default ProfileNotificationTopBar;
