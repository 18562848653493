import { connect } from 'react-redux';
import { WEBFILTER_SETTINGS_SECTION } from '../../constants/index';
import { getUnsupportedBrowserNameSelected } from '../../selectors/index';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import UnsupportedBrowsersBlockedModal from '../../components/Modal/UnsupportedBrowsersBlockedModal';
import { setWebFilterActiveSection } from '../../ducks/profileRules';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => ({
  appName: getUnsupportedBrowserNameSelected(state),
});

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickClose: () => dispatch(close()),
  onClickLink: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(setWebFilterActiveSection(WEBFILTER_SETTINGS_SECTION));
    dispatch(
      navigate({
        type: 'inner:replace',
        src: `/profiles/${profileId}/rules/webFilters`,
      })
    );
  },
});

const UnsupportedBrowsersBlockedModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsupportedBrowsersBlockedModal);

export default UnsupportedBrowsersBlockedModalContainer;
