import { NonEmptyMapRecord, List, fromJS } from 'immutable';
import * as R from 'ramda';
import { navigateToErrorPage } from '../actions/onboarding/schools/OnboardingSchoolsActions';

import api from '../api';
import { StudentRecordOperations } from '../records/student/student';
import { StudentPayload } from '../records/student/types/Student.type';
import { Dispatch } from '../store/state';

import { StudentAction } from './types/action/StudentAction.types';
import { schoolOnboardingErrorLog } from '../helpers/logger/onboarding';

export default function students(
  state: NonEmptyMapRecord<{
    result: List<string | number>;
    isFetching: boolean;
    lastUpdated: number;
    callingLinkPendingStudents: boolean;
  }> = fromJS({
    result: [],
    isFetching: false,
    lastUpdated: undefined,
  }),
  action: StudentAction
) {
  switch (action.type) {
    case 'REQUEST_STUDENTS':
      return state.set('isFetching', true);
    case 'RECEIVE_STUDENTS':
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
        result: List(action.payload.result),
      });
    case 'CALLING_LINK_PENDING_STUDENTS':
      return state.set('callingLinkPendingStudents', action.payload);
    default:
      return state;
  }
}

export const receiveStudents = R.pipe(
  R.map(StudentRecordOperations.fromPayload),
  students =>
    ({
      type: 'RECEIVE_STUDENTS',
      payload: {
        result: students.map(student => student.id),
        records: {
          students: students.reduce(
            (acc, student) => ({ ...acc, [student.id]: student }),
            {}
          ),
        },
      },
      receivedAt: Date.now(),
    } as StudentAction)
);

export const fetchStudents = () => (dispatch: Dispatch<StudentAction>) => {
  return api.students
    .get({})
    .then((students: StudentPayload[]) => dispatch(receiveStudents(students)))
    .catch(e => {
      schoolOnboardingErrorLog(e);
      return dispatch(navigateToErrorPage());
    });
};

export const requestLinkPendingStudents = (waiting = true): StudentAction => ({
  type: 'CALLING_LINK_PENDING_STUDENTS',
  payload: waiting,
});
