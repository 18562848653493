import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Icon,
  IconType,
  Button,
  ButtonSize,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const GenericFeatureUpgradeModal: React.FunctionComponent<{
  onClickClose: () => any;
  onClickLink: (link) => any;
}> = ({ onClickLink, onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Premium is More!')}
    header={{
      icon: <Icon type={IconType.rocket} />,
    }}
    width={modalDimensions.regular.width}
    buttons={[
      <Button
        key="button"
        block
        size={ButtonSize.medium}
        onClick={() => onClickLink('/upgrade')}
      >
        {t('Upgrade to Premium')}
      </Button>,
    ]}
  >
    <ModalStyledText>
      {t(
        'With Premium you can set a rule for this activity and ' +
          'access even more features and settings.'
      )}
    </ModalStyledText>
    <ModalStyledText>
      <a onClick={() => onClickLink('/upgrade/premium-features')}>
        {t('View All Premium Features')}
      </a>
    </ModalStyledText>
  </Modal>
);

export default GenericFeatureUpgradeModal;
