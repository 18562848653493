import * as React from 'react';
import { FlexLayout, Layout, Typography } from 'styleguide-react';
import DaySelector, {
  SelectedDaysType,
  SelectedDaysValueType,
} from '../../../../DaySelector/DaySelector';
import { isEmpty, remove } from 'ramda';
import { Map } from 'immutable';
import { t } from '../../../../../lib/i18n';
import { TimeSlot } from '../../../routines.types';
import { SCHEDULE_WEEKDAYS } from '../../../../../records/routines/schedule/types/Schedule.types';
import { getSelectedDaysText } from '../../../helpers/steps';

const updateDays = (
  index: number,
  days: TimeSlot['days']
): TimeSlot['days'] => {
  const day = SCHEDULE_WEEKDAYS[index];
  const hasDays = days.includes(day);

  if (hasDays) {
    const dayIndex = days.indexOf(day);
    return remove(dayIndex, 1, days);
  }
  return [...days, SCHEDULE_WEEKDAYS[index]];
};

const toDaySelector = (
  days: TimeSlot['days'],
  daysValue: SelectedDaysValueType
): SelectedDaysType => {
  if (isEmpty(days)) return Map();
  return Map(days.map(day => [SCHEDULE_WEEKDAYS.indexOf(day), daysValue]));
};

export interface DaysSelectProps {
  days?: TimeSlot['days'];
  daysValue?: SelectedDaysValueType;
}

export interface DaysSelectComponentProps extends DaysSelectProps {
  onChangeDays: (days: TimeSlot['days']) => void;
}

const DaysSelect = ({
  days = [],
  daysValue = 'secondary-solid',
  onChangeDays,
}: DaysSelectComponentProps) => {
  return (
    <Layout>
      <FlexLayout mainaxis="column" marginBottom="24">
        <Typography type="body1">{t('Days:')}</Typography>
      </FlexLayout>

      <FlexLayout mainaxis="column" marginBottom="16">
        <DaySelector
          selectedDays={toDaySelector(days, daysValue)}
          onSelectDay={index => {
            onChangeDays(updateDays(index, days));
          }}
        />
      </FlexLayout>

      <FlexLayout mainaxis="column">
        <Typography type="body2">{getSelectedDaysText(days)}</Typography>
      </FlexLayout>
    </Layout>
  );
};

export default DaysSelect;
