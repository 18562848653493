import { connect } from 'react-redux';
import State, {
  RouterParamsProps,
  Dispatch,
  BaseThunk,
} from '../../store/state';
import {
  getProfileOrDefault,
  getActiveDateRange,
  getYoutubeSummary,
  getYoutubeSummaryDetailsLastVideos,
  getYoutubeSummarySelectedLastVideo,
  getAccountId,
  isLoadingLastVideos,
  hasMoreLastVideosToLoad,
} from '../../selectors';

import {
  track,
  Events,
  PageNames,
  youtubeActivitySummaryPageTrackerProps,
} from '../../helpers/analytics';
import { SummaryDateRanges, SummaryExpandedView } from '../../constants';
import {
  openSummaryCardInfoModalFor,
  selectedNewDateRangeForProfile,
} from '../../actions/ProfileActions';
import YoutubeActivity from '../../components/ActivityCards/YoutubeSummary/YoutubeActivity';

import { VideoDetailsRecord } from '../../records/urlDetails';
import {
  navigateToExternalUrl,
  showYoutubeSummaryDetailsActions,
  fetchYoutubeSummaryDetailLastVideos,
  fetchMoreYoutubeSummaryDetailLastVideos,
  fetchYoutubeSummary,
} from '../../ducks/youtubeSummary';
import { youtubeSummaryActionSheetClick } from '../../actions/ActionSheetActions';
import { trackablePage } from '../../helpers/hocs';
import { ActivityType } from '../../components/ActivitySummary/sharedModels';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { shouldBlockThisFeature } from '../../helpers/premiumFeatures';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { showPremiumFlyover } from '../../actions/PremiumFeaturesActions';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const profile = getProfileOrDefault(state, Number(profileId));

  return {
    profile,
    activeDateRange: getActiveDateRange(state),
    summary: getYoutubeSummary(state),
    videos: getYoutubeSummaryDetailsLastVideos(state),
    lastVideoActionSelected: getYoutubeSummarySelectedLastVideo(state),
    accountId: getAccountId(state),
    isFetchingVideos: isLoadingLastVideos(state),
    hasMoreVideos: hasMoreLastVideosToLoad(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  goToInfoModal: () => {
    track(Events.ClickedInfoButtoninSummaryCard, {
      card: 'Youtube Activity',
    });
    dispatch(openSummaryCardInfoModalFor(ActivityType.Youtube));
  },
  onClickAction: video => () =>
    dispatch(showYoutubeSummaryDetailsActions(video)),
  onClickPage: video => () => dispatch(navigateToExternalUrl(video)),
  onActionSheetClick: (video?: VideoDetailsRecord) => action => () =>
    dispatch(youtubeSummaryActionSheetClick(action, video)),
  onClickEdit: () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedProfileEdit);
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onSelectDateRange: (range: SummaryDateRanges) =>
    dispatch(
      selectedNewDateRangeForProfile(
        range,
        profileId,
        SummaryExpandedView.YoutubeActivity
      )
    ),
  handleMore: () => {
    dispatch(fetchMoreYoutubeSummaryDetailLastVideos(profileId));
  },
});

const YoutubeActivityContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(
    YoutubeActivity,
    PageNames.YoutubeActivitySummary,
    youtubeActivitySummaryPageTrackerProps
  )
);

YoutubeActivityContainer.load =
  ({ profileId }: { profileId: string }): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profile = getProfileOrDefault(state, Number(profileId));
    const licenseSubtype = getLicenseSubtype(state);
    const isYoutubeFeatureBlocked = shouldBlockThisFeature(
      'youtubeRules',
      licenseSubtype
    );
    if (isYoutubeFeatureBlocked) {
      return dispatch(
        showPremiumFlyover(
          `/profiles/${profileId}/rules/youtube-rules/premiumFlyover`
        )
      );
    }

    dispatch(fetchYoutubeSummaryDetailLastVideos(profile.uid));
    dispatch(fetchYoutubeSummary(profile.uid));
  };

export default YoutubeActivityContainer;
