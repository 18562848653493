import { combineEpics } from 'redux-observable';
import trackingEpics from './tracking';
import dataLoaderEpics from './dataLoaders';
import { modifyQuota } from '../ducks/profileRules/epics';
import routinesEpics from '../ducks/routines/epics';
import { setLocaleFromAccountEpic } from '../ducks/app/epics';
import linewizeEventsEpic from '../ducks/linewizeEvent/epics';
import profileNotificationTopBarEpic from '../ducks/profileNotificationTopBar/epic';
import nowCardEpic from '../ducks/unifiedNowCard/epic';
import { summaryAlertsEpics } from '../ducks/summaryAlerts';
import unloadDataEpics from './dataUnloader';
import { featuresEpics } from '../ducks/features/epics';
import updateNowCardStateEpic from './updateNowCard/updateNowCard';
import deviceSideEffectsEpic from './deviceSideEffects';
import { productAddonsOfferEpics } from '../ducks/productAddonsOffer/epics';
import { loadAppCategoriesEpic } from '../ducks/appCategories/epic';
import contentCommunicationEpics from './contentCommunication';

export { createAppEpicMiddleware } from './middleware';
export const rootEpic = combineEpics(
  trackingEpics,
  dataLoaderEpics,
  modifyQuota,
  routinesEpics,
  linewizeEventsEpic,
  setLocaleFromAccountEpic,
  profileNotificationTopBarEpic,
  nowCardEpic,
  summaryAlertsEpics,
  unloadDataEpics,
  featuresEpics,
  updateNowCardStateEpic,
  deviceSideEffectsEpic,
  productAddonsOfferEpics,
  loadAppCategoriesEpic,
  contentCommunicationEpics
);
