import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  Icon,
  IconType,
  IconColor,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import type { UnlockCompleteModalProps } from '../../containers/Modal/UnlockCompleteModalContainer';

const UnlockCompleteModal = ({
  canPurchase,
  onClickUpgradeNow,
  onClickClose,
}: UnlockCompleteModalProps) => (
  <Modal
    header={{
      icon: <Icon type={IconType.rocketLaunch} color={IconColor.secondary} />,
    }}
    title={t('Unlock complete protection')}
    onClickClose={onClickClose}
    width={modalDimensions.small.width}
    buttons={
      canPurchase
        ? [
            <Button
              key="button"
              block
              size={ButtonSize.medium}
              onClick={onClickUpgradeNow}
            >
              {t('Upgrade now')}
            </Button>,
          ]
        : []
    }
  >
    {t(
      'Upgrade to Premium Complete to get advanced safety features with full customization.'
    )}
  </Modal>
);

export default UnlockCompleteModal;
