import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import { formName as addPlaceForm } from '../components/FamilyLocator/PlaceForm';
import {
  Marker,
  fromProfile as markerFromProfile,
  fromPlace,
} from '../records/marker';
import { getFormattedAddress, isValidLatLng } from '../records/location';
import { getEvents, getLocationEvents } from './event';
import { getTrackableProfiles } from './profile';
import { getProfile } from './profileRaw';
import { getFormValues } from '../lib/wrappers';
import State from '../store/state';

export const getMarkerFromAddPlaceForm = (state): Immutable.List<Marker> => {
  const formValues = getFormValues(addPlaceForm)(state);
  if (!formValues || formValues.address === '') {
    return Immutable.List<Marker>();
  }
  return Immutable.List<Marker>([
    {
      latitude: formValues.latitude,
      longitude: formValues.longitude,
      label: formValues.address,
      icon: '',
      id: formValues.address,
      title: formValues.address,
      snippet: '',
      timestamp: '',
    },
  ]);
};

export const getEventMarker: (
  state: State,
  profileId: number,
  eventKey: string
) => Immutable.List<Marker> = (state, profileId, eventKey) => {
  const events = getEvents(state).filter(event => event.key === eventKey);
  const profile = getProfile(state, profileId);
  return events.map(eventItem => ({
    latitude: eventItem.location.latitude,
    longitude: eventItem.location.longitude,
    accuracy: eventItem.location.accuracy,
    title: profile.name,
    snippet: getFormattedAddress(eventItem.location),
    label: profile.name[0],
    icon: profile.picture,
    id: eventItem.key,
    timestamp: eventItem.time,
  }));
};

export const getFormMarker: (
  state: State,
  profileId: number,
  eventKey: string
) => Immutable.List<Marker> = (state, profileId, eventKey) =>
  getEventMarker(state, profileId, eventKey).map(eventMarker =>
    Object.assign(eventMarker, { accuracy: 0 })
  );

export const getValidMarkers = createSelector(getTrackableProfiles, profiles =>
  profiles
    .filter(profile => isValidLatLng(profile.location))
    .map(markerFromProfile)
);

export const getProfileMarker = (state, profileId) => {
  const markers = getValidMarkers(state).filter(
    marker => marker.id === profileId
  );
  if (markers.size) {
    return markers;
  }

  // When profile last location is not valid we need to fetch the latest valid location event if any
  const eventList = getLocationEvents(state).filter(event =>
    isValidLatLng(event.location)
  );
  if (eventList.size) {
    return getEventMarker(state, profileId, eventList.get(0).key);
  }

  return Immutable.List();
};

export const getMarkerFromPlace = (
  state,
  fallbackPlace
): Immutable.List<Marker> => {
  const formValues = getFormValues(addPlaceForm)(state);
  if (!formValues || formValues.address === '') {
    return Immutable.List<Marker>([fromPlace(fallbackPlace)]);
  }

  return Immutable.List<Marker>([
    {
      latitude: formValues.latitude,
      longitude: formValues.longitude,
      label: formValues.address,
      icon: '',
      id: fallbackPlace.uid,
      title: formValues.address,
      snippet: '',
      timestamp: '',
    },
  ]);
};
