import * as React from 'react';
import { t } from '../../lib/i18n';
import { ProductRecord } from '../../records';
import { getProperLicenseText, LicenseType } from '../../records/license';
import Loader from '../base/Loader';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Icon,
  IconType,
  TransparentButton,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const TwoYearsPromotionModal: React.FunctionComponent<{
  isLoading: boolean;
  product: ProductRecord;
  productWithTwoYearDuration: ProductRecord;
  licenseType: LicenseType;
  amountWithDiscount: string;
  isNavigatingToPurchaseProduct: boolean;
  isPurchaseInProgress: boolean;
  onClickLink: (product: ProductRecord) => any;
  onClose: (product: ProductRecord) => any;
}> = ({
  isLoading,
  product,
  productWithTwoYearDuration,
  amountWithDiscount,
  isNavigatingToPurchaseProduct,
  isPurchaseInProgress,
  onClickLink,
  onClose,
}) => {
  const handleOnClickLink = prod => {
    if (!isPurchaseInProgress) {
      onClickLink(prod);
    }
  };
  if (isLoading) {
    return <Loader size="small" color="" />;
  }
  return (
    <Modal
      width={modalDimensions.regular.width}
      className="TwoYearsPromotionModal"
      header={{
        icon: <Icon type={IconType.gift} />,
      }}
      title={t('Special premium offer!')}
      buttons={[
        <Button
          key="button"
          loading={isNavigatingToPurchaseProduct}
          disabled={isPurchaseInProgress || isNavigatingToPurchaseProduct}
          size={ButtonSize.medium}
          onClick={() => handleOnClickLink(productWithTwoYearDuration)}
        >
          {t('Upgrade for 2 years')}
        </Button>,
      ]}
      onClickClose={() => onClose(product)}
    >
      <ModalStyledText>
        {getProperLicenseText(
          product.licenseSubtype,
          t('Core protection for {{maxDevices}}', {
            maxDevices: product.maxDevices,
          }),
          t('Advanced safety for unlimited devices'),
          t('Protects up to {{maxDevices}} devices', {
            maxDevices: product.maxDevices,
          })
        )}
      </ModalStyledText>
      <ModalStyledText>
        <strong>{amountWithDiscount}</strong>
      </ModalStyledText>
      <ModalStyledText>
        {t(
          'Add an extra year to your Premium subscription and save 25% on your 2nd year'
        )}
      </ModalStyledText>
      <ModalStyledText>
        <TransparentButton
          disabled={isPurchaseInProgress}
          onClick={() =>
            !isPurchaseInProgress ? handleOnClickLink(product) : false
          }
        >
          <a>{t('Continue with 1-year subscription')}</a>
        </TransparentButton>
      </ModalStyledText>
    </Modal>
  );
};

export default TwoYearsPromotionModal;
