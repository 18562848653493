export type Cache = Map<string, any>;

export type CacheSelectorStrategy = (
  cache: Cache,
  cacheKey: string,
  pullData: () => Promise<any>,
  endpointName?: string
) => Promise<any>;

/**
 *
 * Returns any previously cached http requests, It is the default strategy.
 *
 */
export const session: CacheSelectorStrategy = (cache, cacheKey, pullData) => {
  return cache.has(cacheKey)
    ? cache.get(cacheKey)
    : cache.set(cacheKey, pullData()).get(cacheKey);
};

/**
   * Defines a TTL for a previously cached request, once time passes, cache is refreshed and fresh data is obtained
   * and then a TTL is applied again to the fresh data obtained.
  
   *
   * For it to work correctly, the same TTL must be used in all the requests that are needed.
   *
   * @example
   *
   * In cacheStrategies.js
   * ```ts
   *
   * import { ttl } from '../lib/QapiCache';
   *
   * const ttl1Minute = ttl(60000);
   * ```
   *
   * In profileThunk.js
   *
   * ```ts
   *
   * import { qapi } from '../lib/qapi';
   * import { ttl1Minute } from '../lib/cacheStrategies';
   *
   * const fetchProfile = (profileId) => (dispatch) => {
   *  return qapi
   *          .profiles
   *          .withCache(ttl1Minute)
   *          ^^^^^^^^^^^^^^^^^^^^^^ use cache strategy
   *          .get({ profileId })
   *          .then(profile => dispatch(profileSuccessAction(profile)));
   *
   * };
   *
   * ```
   *
   */
export const ttl = (ms: number): CacheSelectorStrategy => {
  const ttls = {};

  return (cache, cacheKey, pullData) => {
    let result;
    ttls[cacheKey] = ttls[cacheKey] ? ttls[cacheKey] : false;
    const isRegisterTTL = ttls[cacheKey];

    if (isRegisterTTL && cache.has(cacheKey)) {
      result = cache.get(cacheKey);
    }

    if (!isRegisterTTL || !cache.has(cacheKey)) {
      result = cache.set(cacheKey, pullData()).get(cacheKey);
    }

    if (!isRegisterTTL) {
      ttls[cacheKey] = true;
      setTimeout(() => {
        ttls[cacheKey] = false;
      }, ms);
    }

    return result;
  };
};
