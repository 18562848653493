import * as React from 'react';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile';
import {
  Icon,
  IconType,
  Modal,
  Button,
  ButtonSize,
  Typography,
} from 'styleguide-react';

const PanicRulesTourModal: React.FunctionComponent<{
  profile: ProfileRecord;
  onClickClose: () => void;
}> = ({ profile, onClickClose }) => (
  <Modal
    testId="PanicRulesTourModal"
    size="small"
    onClickClose={onClickClose}
    header={{
      icon: <Icon type={IconType.sirenOn} />,
    }}
    title={t('Instructions')}
    buttons={[
      <Button
        key="button1"
        onClick={onClickClose}
        size={ButtonSize.medium}
        block
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    <Typography type="body1" align="left" marginTop="16">
      <ol>
        <li>
          {t('{1}Turn on the switch{/1} to enable the Panic Button feature', {
            1: str => <strong>{str}</strong>,
          })}
        </li>
        <li>
          {t('{1}Invite some trusted contacts{/1} for {{profileName}}', {
            1: str => <strong>{str}</strong>,
            profileName: profile.name,
          })}
        </li>
        <li>
          {t(
            "{1}Open the app on {{profileName}}'s phone{/1} to show your child the Panic Button",
            {
              profileName: profile.name,
              1: str => <strong>{str}</strong>,
            }
          )}
        </li>
      </ol>
    </Typography>
  </Modal>
);

export default PanicRulesTourModal;
