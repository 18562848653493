import { List } from 'immutable';
import * as queryString from 'query-string';
import * as aesjs from 'aes-js';
import * as R from 'ramda';
import {
  BuildPlatform,
  DEVICE_TYPE_ANDROID,
  DEVICE_TYPE_IOS,
  DEVICE_TYPE_LAPTOP,
  DEVICE_TYPE_KINDLE,
  DEBUG_PROFILE_NAME,
} from '../constants';
import timeout, { now, clearTimeout } from '../lib/timeout';
import State from '../store/state';
import * as qinit from '../qinit';
import { getBuildPlatform } from './globals';

// eslint-disable-next-line import/no-cycle
import _gaSetup, {
  gaScreenView as _gaScreenView,
  gaEvent as _gaEvent,
  gaTiming as _gaTiming,
  gaCampaignFromUrl as _gaCampaignFromUrl,
  gaSetAccountId as _gaSetAccountId,
} from './ga';

/*

This form of export is used because if aliases are used,
the TypeScript transpiler translates the code to Object.defineProperty without setters.
This causes failures when spying on functions in the legacy test suite.
*/
export const gaSetup = _gaSetup;
export const gaScreenView = _gaScreenView;
export const gaEvent = _gaEvent;
export const gaTiming = _gaTiming;
export const gaCampaignFromUrl = _gaCampaignFromUrl;
export const gaSetAccountId = _gaSetAccountId;

// eslint-disable-next-line import/no-cycle
export { safeFn } from './safeFn';

export const downloadLink =
  qinit.tenant.common.endpoint.download_choose_your_os.replace(
    /https?:\/\//,
    ''
  );

export function getCleverbridgeEnv() {
  switch (qinit.env) {
    case 'dev':
      return 'development';
    case 'pre':
      return 'staging';
    case 'pro':
    default:
      return '';
  }
}

export const isBrowserPlatform = () =>
  getBuildPlatform() === BuildPlatform.browser;
export const isiOSPlatform = () => getBuildPlatform() === BuildPlatform.ios;
export const isAndroidPlatform = () =>
  getBuildPlatform() === BuildPlatform.android;

/**
 * Return arg.toJS() if it exists, or return arg.
 */
export const toJS: <T extends any>(
  arg: T
) => // tslint:disable-next-line:no-unused
T extends { toJS: () => infer U } ? U : T = R.cond([
  [
    arg =>
      typeof arg === 'object' && arg !== null && typeof arg.toJS === 'function',
    R.invoker(0, 'toJS'),
  ],
  [R.T, R.identity],
]) as any;

const logFunction = console.log.bind(console); // eslint-disable-line no-console
/**
 * Log argument to console and return the argument
 * Useful to hook logging between nested function calls.
 */
// eslint-disable-next-line no-multi-assign
export const qlog = (global.qlog = message =>
  R.tap(
    R.pipe(toJS, R.tap(logFunction.bind(null, message)) as any)
  ) as typeof R.identity);

export const isNotNil = R.complement(R.isNil);
export const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
export const isNotNilOrEmpty = R.complement(isNilOrEmpty);
/**
 * Join all arguments with space separator, excluding "falsey" values
 */
export const classNames = (...classNames) =>
  R.filter(isNotNil, classNames).join(' ');

export const getLocationPathname = (state: State) =>
  (state.get('routing').get('locationBeforeTransitions') || { pathname: '' })
    .pathname;

export const constructModalPathname = (state: State, name, query) => {
  const locationPathname = getLocationPathname(state);
  const basePathname =
    locationPathname.indexOf('/modal/') > -1
      ? locationPathname.split('/modal/')[0]
      : locationPathname;
  const queryStr = queryString.stringify(query);
  const errorPathname = `/modal/${name}?${queryStr}`;
  return locationPathname === '/'
    ? errorPathname
    : `${basePathname}${errorPathname}`;
};

export const CONFIRM_YES = Symbol('CONFIRM_YES');
export const CONFIRM_NO = Symbol('CONFIRM_NO');
const mapCordovaButtons = buttonId =>
  ({
    1: CONFIRM_YES,
    2: CONFIRM_NO,
  }[buttonId]);

export function confirmDialog(title, msg, buttons) {
  return new Promise<typeof CONFIRM_YES | typeof CONFIRM_NO>(resolve => {
    navigator.notification.confirm(
      msg,
      R.pipe(R.toString, mapCordovaButtons, resolve),
      title,
      buttons
    );
  });
}

/**
 * Example:
 * minimum = minimumPromiseDuration(5000);
 * doSomePromise().then(minimum, minimum).then(...).catch(...);
 * Will not resolve or reject until at least 5000ms have passed.
 * Rejects if provided value has Error object in its prototype chain.
 */
export const minimumPromiseDuration =
  (minimum = 1000, ts = now()) =>
  (value: any | void) =>
    new Promise<any>((resolve, reject) => {
      timeout(
        () => (value instanceof Error ? reject(value) : resolve(value)),
        R.clamp(0, minimum, minimum - Math.max(0, now() - ts))
      );
    });

type Gender = 'boy' | 'girl';
type GenderImagePath = string;
export type GenderImageMap = {
  [index: string]: {
    [index: string]: GenderImagePath;
  };
};
/** Split "boy_1.png" into ["boy", "1"] */
const genderNumber = (filename: GenderImagePath) =>
  filename.replace('.png', '').split('_') as [Gender, string];
/** Get value from map with fallback */
const genderImageMap = (
  map: GenderImageMap,
  defaultAsset: GenderImagePath,
  gender: Gender,
  index
) => (map[gender] ? map[gender][index] || map[gender][1] : defaultAsset);
/**
 * Provided with a map like { boy: { 1: <value>, 2: <value>}, girl: { ... }} and
 * a fallback value,
 * returns a function with two parameters "gender" and "number" that will return
 * the correct value in the map or use the fallback value.
 */
export const genderImageAssetFromPng =
  (map: GenderImageMap, defaultAsset: GenderImagePath) => filename => {
    const [gender, index] = genderNumber(filename);
    return genderImageMap(map, defaultAsset, gender, index);
  };

export const delayedModifyTargetActionCreator = (
  delayedModifyTimeoutId,
  updateActionCreator,
  modifyActionCreator,
  timeoutId,
  args,
  delay
) => {
  return (dispatch, getState) => {
    const id = getState().getIn(['app', 'timeoutIds', timeoutId]);
    if (id !== -1) {
      clearTimeout(id);
    }
    if (updateActionCreator) {
      dispatch(R.apply(updateActionCreator, args));
    }
    return new Promise((resolve, reject) => {
      const newId = timeout(() => {
        dispatch(delayedModifyTimeoutId(-1, timeoutId));
        Promise.resolve(dispatch(R.apply(modifyActionCreator, args)))
          .then(resolve)
          .catch(reject);
      }, delay);
      dispatch(delayedModifyTimeoutId(newId, timeoutId));
    });
  };
};

export const ensureArray = R.when(R.complement(R.is(Array)), R.of);

export const timeoutPromise = <T>(
  ms: number,
  callback: () => T
): T extends Promise<any> ? T : Promise<T> =>
  new Promise((resolve, reject) =>
    timeout(() => {
      try {
        resolve(callback());
      } catch (e) {
        reject(e);
      }
    }, ms)
  ) as T extends Promise<any> ? T : Promise<T>;

export const decodeDeepLink = R.pipe(
  R.match(/^\w+:\/\/(.+)/),
  R.defaultTo([]),
  R.nth(1)
);

/**
 * Tap into a promise rejection.
 * (e → *) → e → Promise.reject(e)
 * This is useful because if the result of a `catch()` handler does not return an
 * unrejected promise nor throws an error, the promise is considered resolved.
 * ```
 * promise.catch(tapReject(error => console.log(error)))
 * // is still rejected even though the function returned undefined
 * ```
 */
export const tapReject: <T extends Error>(
  fn: (error: T) => any
) => (error: T) => Promise<never> = (fn: any) => (error: any) => {
  fn(error);
  return Promise.reject(error);
};

export const DEVICE_TYPES = [
  DEVICE_TYPE_ANDROID,
  DEVICE_TYPE_IOS,
  DEVICE_TYPE_LAPTOP,
  DEVICE_TYPE_KINDLE,
];

export const mapDeviceTypes = R.zipObj(DEVICE_TYPES);

export const fixThumbnailEnv = url =>
  qinit.env === 'pro'
    ? url.replace(
        /^s3:\/\/static-pre.qustodio.com\//,
        's3://static.qustodio.com/'
      )
    : url;

/**
 * Count the number of occurrences in a list of strings
 * Returns tuples of [string, count].
 */
export const countSymbols = (list: List<string>): [string, number][] => {
  const countMap = list.reduce(
    (acc, value) => ({ ...acc, [value]: R.inc(R.defaultTo(0)(acc[value])) }),
    {}
  );
  return Object.getOwnPropertyNames(countMap).map(sym => [
    sym,
    countMap[sym],
  ]) as [string, number][];
};

const EVENT_ENCRYPT_LITTLE_ENDIAN = true;
/**
 * Encrypt ({ key, initVector}, profileId, deviceId, timestamp) to a token,
 * like backend does. Key and initVector are associated to the acocunt.
 * Returns a url-safe base64 string.
 * Reference python snippet:

    dec_token = struct.pack('<llq', profile.id, device.id, timestamp)
    key = device.account.token_key.key.bytes
    init_vector = device.account.token_key.init_vector.bytes
    cipher = AES.new(key, AES.MODE_CBC, init_vector)
    enc_token = cipher.encrypt(dec_token)
    try:
        bin_token = struct.pack('<l16s', device.id, enc_token)
    except struct.error:
        raise TokenEncryptionError
    token = base64.urlsafe_b64encode(bin_token)
    return token

 */
export const encryptEventAsToken = (
  { key, initVector },
  profileId,
  deviceId,
  timestamp
) => {
  const cipher = new aesjs.ModeOfOperation.cbc(
    aesjs.utils.hex.toBytes(key),
    aesjs.utils.hex.toBytes(initVector)
  );

  // fill a buffer with two longs (4 bytes each) and one long long (8 bytes)
  const buffer = new ArrayBuffer(16);
  const view = new DataView(buffer);
  view.setInt32(0, profileId, EVENT_ENCRYPT_LITTLE_ENDIAN);
  view.setInt32(4, deviceId, EVENT_ENCRYPT_LITTLE_ENDIAN);
  // TODO: fix this Y38K bug before the year 2038
  // JavaScript max int size is 53bits, so there is no native DataView.setInt64.
  // We could use some BigInteger library which can convert up to 53bits into an
  // ArrayBuffer, or just count on the world to end before 2038.
  view.setInt32(8, timestamp, EVENT_ENCRYPT_LITTLE_ENDIAN);

  const eventTokenArray = new Uint8Array(buffer);

  // let's hope this is always little endian, even if the host's
  // architecture is not (e.g. some android device)
  const encryptedToken = cipher.encrypt(eventTokenArray);

  // fill a buffer with one long (4 bytes) and the encrypted token (16 bytes)
  const combinedTokenBuffer = new ArrayBuffer(20);
  const combinedView = new DataView(combinedTokenBuffer);
  combinedView.setInt32(0, deviceId, EVENT_ENCRYPT_LITTLE_ENDIAN);
  const urlTokenArray = new Uint8Array(combinedTokenBuffer);
  urlTokenArray.set(encryptedToken, 4);

  return urlSafeBase64FromUint8Array(urlTokenArray);
};

/**
 * https://docs.python.org/2/library/base64.html#base64.urlsafe_b64decode
 */
const urlSafeBase64FromUint8Array = R.pipe(
  array => btoa(String.fromCharCode.apply(null, array)),
  R.replace(/\+/g, '-'),
  R.replace(/\//g, '_')
);

export const replaceS3Url = url =>
  url ? url.replace('s3://', 'https://') : null;

export const wrapStopPropagation: (
  handler: Function
) => (e: React.MouseEvent<any>) => void = onClick => e => {
  e.stopPropagation();
  onClick();
};

/**
 * R.cond with R.equals + R.always for each tuple, and optionally one last R.T + R.always with default value.
 */
export const mapCond: <T, R>(
  fromTo: [T, R][],
  defaultValue?: R
) => (from: T) => R = (conditions, defaultValue) => {
  const mappedConditions = R.concat(
    R.map<any, any>(([from, to]) => [R.equals(from), R.always(to)])(conditions)
  )(defaultValue !== undefined ? [[R.T, R.always(defaultValue)]] : []);
  return R.cond(mappedConditions as any);
};

export const filterNulls = <T>(list: (T | null)[]): T[] => {
  return list.filter(entry => entry !== null) as T[];
};

export const isMagicDebugProfile = profile => {
  return (
    profile.name.toLowerCase() === DEBUG_PROFILE_NAME.toLowerCase() &&
    profile.birthDate.isSame('1970-01-01', 'day')
  );
};

export const omit =
  <T>(object: T) =>
  <K extends keyof T>(properties: K[]): Omit<T, K> =>
    R.omit(properties as any)(object as any) as any;

/**
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 * To be used for the `default` case of a `switch` that should have a
 * discriminating `case` for each member of a union type.
 * The `default` case can be omitted if e.g. the function return type is
 * annotated and does not contain `undefined`, or else this function can be
 * used.
 * Additionally throw an error, useful for testing.
 */
export const assertNever = (value: never): never => {
  throw new Error(`Unexpected value (type: never): ${R.toString(value)}`);
};

/**
 * The function passed as callback will only fire once after delay
 * instead of as quickly as it's triggered
 */
export const debounce = (cb, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = timeout(() => {
      cb(...args);
    }, delay);
  };
};

export const calcPercent = (spent: number, total: number) => {
  if (spent < 0 || total < 0) {
    return 0;
  }
  return (spent * 100) / total;
};

export const strToBool = (str: string) => str === 'true';

export const sortArrayByObjectKey = <T>(array: T[], key: string) =>
  array.sort((firstElement: T, secondElement: T) => {
    const firstValue = firstElement[key];
    const secondValue = secondElement[key];
    if (firstValue < secondValue) {
      return -1;
    }
    return firstValue > secondValue ? 1 : 0;
  });
