import { isAndroidPlatform, isiOSPlatform } from '../../helpers';
import { isInitializing } from '../../selectors/pushService';
import State from '../../store/state';
import {
  FirebasePluginNotificationPayload,
  PushPluginNotificationData,
  PushPluginNotificationPayload,
} from './types/PushPayload';

export const pushServiceNotReadyForAndroid = (state: State) =>
  isAndroidPlatform() && isInitializing(state);

export const demandUserPushNotificationPermissionForPASI = () => {
  if (isiOSPlatform()) {
    /**
     * The plugin requires a callback but in this case we don't need to
     * do anything with it because this only try to show the system modal
     * to allow push notification in our app.
     * See more details here https://qustodio.atlassian.net/browse/PAR-5540
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.FirebasePlugin.grantPermission(() => {});
  }
};

const isUsingFirebasePlugin = () => isiOSPlatform();

export const normalizePushNotificationPayload = (
  data: PushPluginNotificationPayload | FirebasePluginNotificationPayload
): PushPluginNotificationData => {
  if (isUsingFirebasePlugin()) {
    const firebaseData = data as FirebasePluginNotificationPayload;

    return {
      additionalData: {
        foreground: firebaseData.tap !== 'background',
        deep_link: firebaseData.deep_link,
        pn_type: firebaseData?.data?.pn_type,
        ...firebaseData.aps,
      },
    };
  }
  return data;
};
