import { connect } from 'react-redux';
import {
  SOCIAL_MONITORING_RULES_STATE_DISABLED,
  SOCIAL_MONITORING_RULES_STATE_MONITORING,
  SOCIAL_MONITORING_RULES_STATE_WAITING_COMPUTER,
  SOCIAL_MONITORING_RULES_STATE_WAITING_FACEBOOK_LOGIN,
} from '../../../constants';
import {
  getProfileComputerDevices,
  getProfileOrDefault,
  getProfileRules,
  getSocialMonitoringRulesEnabled,
  getSocialMonitoringRulesFacebookConnected,
} from '../../../selectors';
import { fetchProfileRules } from '../../../ducks/profileRules';
import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import SocialMonitoringRules from '../../../components/SocialMonitoringRules/SocialMonitoringRules';
import * as qinit from '../../../qinit';
import { isBrowserPlatform } from '../../../helpers';
import { gaEvent } from '../../../helpers/ga';
import { trackSocialRules, SocialRuleNames } from '../../../helpers/analytics';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { shouldBlockThisFeature } from '../../../helpers/premiumFeatures';

const getMonitoringState = (
  ruleEnabled,
  facebookConnected,
  computerConnected
) => {
  if (ruleEnabled) {
    if (facebookConnected) {
      return SOCIAL_MONITORING_RULES_STATE_MONITORING;
    }
    if (!computerConnected) {
      return SOCIAL_MONITORING_RULES_STATE_WAITING_COMPUTER;
    }

    return SOCIAL_MONITORING_RULES_STATE_WAITING_FACEBOOK_LOGIN;
  }

  return SOCIAL_MONITORING_RULES_STATE_DISABLED;
};

const toggleSocialMonitoringRulesEnabled = enabled => (state, profileId) =>
  getProfileRules(state, profileId).setIn(
    ['socialMonitoring', 'enabled'],
    enabled
  );

const mapStateToProps = (state, { params: { profileId } }) => {
  const ruleEnabled =
    getSocialMonitoringRulesEnabled(state, profileId) || false;
  const facebookConnected =
    getSocialMonitoringRulesFacebookConnected(state, profileId) || false;
  const computerConnected = !getProfileComputerDevices(
    state,
    profileId
  ).isEmpty();
  return {
    ruleEnabled,
    monitoringState: getMonitoringState(
      ruleEnabled,
      facebookConnected,
      computerConnected
    ),
    profileName: getProfileOrDefault(state, profileId).name,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onToggleSocialMonitoringEnabled: (enabled: boolean) => {
    gaEvent('social-monitoring', enabled ? 'on' : 'off', 'enable-FB');
    trackSocialRules(
      enabled
        ? SocialRuleNames.ENABLE_SOCIAL_MONITORING
        : SocialRuleNames.DISABLE_SOCIAL_MONITORING
    );
    return dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleSocialMonitoringRulesEnabled(enabled)
      )
    );
  },
  onClickAction: () =>
    dispatch((dispatch, getState) => {
      const onResumeApp = () => {
        dispatch(
          fetchProfileRules(
            profileId,
            getProfileOrDefault(getState(), profileId).get('deviceIds'),
            true
          )
        );
        document.removeEventListener('resume', onResumeApp, false);
      };

      document.addEventListener('resume', onResumeApp, false);

      const returnUrl = encodeURIComponent(
        isBrowserPlatform()
          ? `${qinit.tenant.common.dashboard.home_link}/parents-app#/profiles/${profileId}/rules/socialMonitoringRules`
          : `${qinit.tenant.ios.app_url_scheme}:///profiles/${profileId}/rules/socialMonitoringRules`
      );

      const url = `${qinit.tenant.common.dashboard.facebook_app_redirect_url}/user/${profileId}/returnUrl/${returnUrl}/source/parents-app`;
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'external',
          src: url,
        })
      );
      gaEvent('social-monitoring', 'click', 'fb-credentials-url');
    }),
});

const SocialMonitoringRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMonitoringRules);

SocialMonitoringRulesContainer.redirect =
  ({ profileId }) =>
  dispatch => {
    if (shouldBlockThisFeature(''))
      dispatch(
        redirectFreeToPremiumAction(
          profileId,
          'rules/socialMonitoringRules/premiumFlyover'
        )
      );
  };

export default SocialMonitoringRulesContainer;
