import State from '../store/state';

import {
  NotificationFilter,
  matchNotificationFilter,
} from '../records/notification';
import { createSelector } from 'reselect';

export const getNotificationCenter = (state: State) =>
  state.get('notificationCenter');

export const getNewNotificationsCount = (state: State) =>
  state.get('notificationCenter').get('newCount');

export const getNotificationsFilter = (state: State) =>
  state.get('notificationCenter').get('filter');

export const getNewNotificationsCountByFilter = (
  state: State,
  filter: NotificationFilter
) => {
  const currFilter = getNotificationsFilter(state);
  return matchNotificationFilter(currFilter, filter)
    ? getNewNotificationsCount(state)
    : 0;
};

export const getNotifications = createSelector(
  getNotificationCenter,
  notifCenter => notifCenter.get('notifications')
);

export const isFetchingNotifications = createSelector(
  getNotificationCenter,
  notifCenter => notifCenter.get('isFetching')
);

export const hasMoreNotifications = createSelector(
  getNotificationCenter,
  notifCenter => notifCenter.get('hasMoreNotifications')
);

export const hasNotifications = createSelector(
  getNotificationCenter,
  notifCenter => !notifCenter.get('isEmpty')
);

export const getNotificationsPage = createSelector(
  getNotificationCenter,
  notifCenter => notifCenter.get('page')
);
