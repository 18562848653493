import * as React from 'react';
import { t } from '../../lib/i18n';
import { EventRecord, UrlDetailsRecord } from '../../records';
import Date from '../Date/Date';
import { replaceS3Url } from '../../helpers';
import {
  FlexLayout,
  TransparentButton,
  Layout,
  FreeStyleListItem,
  BadgeType,
  Badge,
  List,
  Icon,
  IconType,
  IconSize,
  IconColor,
  EmptyPlaceholder,
} from 'styleguide-react';
import { getCategoryLabel } from '../../records/category';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacingProps } from 'styleguide-react/build/components/Layout/types';

const UrlDetailsSiteInfo: React.FunctionComponent<{
  event: EventRecord | undefined;
  urlDetails: UrlDetailsRecord;
  onClick: () => any;
}> = ({ event, urlDetails, onClick }) => {
  const renderInfo = (spacing: LayoutSpacingProps) => (
    <FlexLayout mainaxis="row">
      <FlexLayout
        mainaxis="row"
        minWidth="50%"
        maxWidth="50%"
        maxHeight="160"
        {...spacing}
      >
        <TransparentButton fillAvailableSpace onClick={onClick}>
          {urlDetails.screenshot ? (
            <img
              src={replaceS3Url(urlDetails.screenshot)}
              alt="screenshot"
              height="100%"
              width="100%"
            />
          ) : (
            <EmptyPlaceholder
              fillAvailableSpace
              centered
              coloredBackground
              icon={
                <Icon
                  type={IconType.globe}
                  size={IconSize.x3}
                  color={IconColor.neutral}
                />
              }
            />
          )}
        </TransparentButton>
      </FlexLayout>
      <FlexLayout mainaxis="row" maxWidth="50%">
        {event ? eventData(event) : undefined}
      </FlexLayout>
    </FlexLayout>
  );
  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {renderInfo({ marginRight: '48' })}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderInfo({ marginRight: '8' })}
      </RenderWhen>
    </React.Fragment>
  );
};
const eventData = (event: EventRecord) => (
  <FlexLayout mainaxis="column">
    <Date time={event.time} />
    <div>
      <strong>{t('Time spent:')}</strong> {event.minutes} {t('min')}
    </div>
    <div>
      <strong>{t('Pages visited:')}</strong> {event.pages}
    </div>
    <Layout marginTop="8">
      <List listItemMarginBottom="8">
        {event.categories.map(category => (
          <FreeStyleListItem key={category.id}>
            <Badge
              type={BadgeType.neutral}
              text={getCategoryLabel(category.id)}
            />
          </FreeStyleListItem>
        ))}
      </List>
    </Layout>
  </FlexLayout>
);

export default UrlDetailsSiteInfo;
