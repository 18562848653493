import { BaseThunk } from '../store/state';
import { fromJS, Map, NonEmptyMapRecord } from 'immutable';
import api from '../api';
import { isDevelopment } from '../helpers/env';
import * as queryString from 'query-string';
import { getHostname } from '../sideEffects/browser/core';

export const REQUEST_ATTRIBUTION_LAST_SOURCE =
  'REQUEST_ATTRIBUTION_LAST_SOURCE';
export const REQUEST_ATTRIBUTION_LAST_SOURCE_ERROR =
  'REQUEST_ATTRIBUTION_LAST_SOURCE_ERROR';
export const RECEIVE_ATTRIBUTION_LAST_SOURCE =
  'RECEIVE_ATTRIBUTION_LAST_SOURCE';

export default function attribution(
  state: NonEmptyMapRecord<{
    lastSource: Map<string, string>;
    isFetching: boolean;
  }> = fromJS({
    lastSource: {},
    isFetching: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_ATTRIBUTION_LAST_SOURCE:
      return state.set('isFetching', true);
    case REQUEST_ATTRIBUTION_LAST_SOURCE_ERROR:
      return state.set('isFetching', false);
    case RECEIVE_ATTRIBUTION_LAST_SOURCE:
      return state.merge({
        isFetching: false,
        lastSource: action.payload,
      });
    default:
      return state;
  }
}

export const requestAttributionLastSource = () => {
  return {
    type: REQUEST_ATTRIBUTION_LAST_SOURCE,
  };
};

export const requestAttributionLastSourceError = () => {
  return {
    type: REQUEST_ATTRIBUTION_LAST_SOURCE_ERROR,
  };
};

export const receiveAttributionLastSource = payload => {
  return {
    type: RECEIVE_ATTRIBUTION_LAST_SOURCE,
    payload,
  };
};

export const fetchAttributionLastSource =
  (): BaseThunk<void> => async dispatch => {
    dispatch(requestAttributionLastSource());

    try {
      const attribution = await api.lastAttribution.get({});
      dispatch(receiveAttributionLastSource(attribution));
    } catch (_) {
      dispatch(requestAttributionLastSourceError());
    }
  };

export const storeVisitorAttribution = (attribution: object) => {
  if (isDevelopment() || !attribution.utm_source) {
    /**
     * The API in DEV enviroment does not support credentialed request.
     * To enable them, "cors_origin_allow_all" and "cors_origin_whitelist" DEV fields in qinit, need
     *  to be updated to match PRE environment.
     * */
    return;
  }

  return api.visit.get({
    url: encodeURIComponent(
      `https://${getHostname()}?${queryString.stringify(attribution)}`
    ),
  });
};

export const reportAttribution = ({
  type,
  attribution,
}: {
  type: 'visit' | 'registration';
  attribution: object;
}) => {
  if (isDevelopment()) {
    /**
     * The API in DEV enviroment does not support credentialed request.
     * To enable them, "cors_origin_allow_all" and "cors_origin_whitelist" DEV fields in qinit, need
     *  to be updated to match PRE environment.
     * */
    return;
  }

  return api.attribution.post({
    type,
    ...attribution,
  });
};

export const getAttributionFrom = (object: { [property: string]: unknown }) => {
  const attribution: { [property: string]: string } = {};

  for (const propertyName in object) {
    if ({}.hasOwnProperty.call(object, propertyName)) {
      const value = object[propertyName];

      if (
        propertyName.startsWith('utm_') &&
        typeof value === 'string' &&
        value !== ''
      ) {
        attribution[propertyName] = value;
      }
    }
  }

  return attribution;
};
