import { connect } from 'react-redux';
import ProfileList from '../components/Profiles/ProfileList';
import { reloadAppData } from '../ducks/app';
import { sideBarStateModeChanged, sideBarStateChanged } from '../ducks/ui';
import {
  getProfiles,
  getProfileCount,
  getAppReloadStatus,
  getProfileOrDefault,
  isSmartAppBannerVisible,
  hasProfileWithActiveDevice,
  getQueryParam,
  areProfilesAtLimit,
  areProfilesExceeded,
  isAccountOverLimit,
} from '../selectors';
import { getLicense } from '../selectors/license';
import { isMagicDebugProfile } from '../helpers';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import { PROFILE_STATUS_NO_DEVICE } from '../records/profile';
import {
  trackOpenedBurgerMenu,
  track,
  Events,
  PageNames,
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../helpers/analytics';
import { shouldShowGift } from '../ducks/gifts/giftsSelectors';
import { addProfileClickHandler } from '../actions/ProfileActions';
import store from '../store';
import State, { BaseThunk, Dispatch } from '../store/state';
import { trackablePage } from '../helpers/hocs';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import flags from '../sideEffects/flags';
import { isStudentOptInForDelegationForThisAccount } from '../selectors/studentPolicies';
import { requestSummaryAlertsAction } from '../ducks/summaryAlerts';
import { requestFeaturesAction } from '../ducks/features';
import { OverLimitModalReferrer } from './Modal/OverLimitModalContainer';
import {
  shouldDisplayWellbeingBanner,
  shouldDisplayWellbeingNewTag,
} from '../businessLogic/wellbeing/wellbeing';
import { setItem } from '../ducks/persistentStorage';
import { isSchoolAccount } from '../businessLogic/account';

const mapStateToProps = (state: State) => {
  const profiles = getProfiles(state);
  const profileCount = getProfileCount(state);
  const schoolOnboardingStatus = getQueryParam(state, 'school-onboarding') as
    | 'done'
    | null;

  return {
    profiles,
    profileCount,
    license: getLicense(state),
    isRefreshing: getAppReloadStatus(state),
    showGift: shouldShowGift(state),
    isTopbarVisible: isSmartAppBannerVisible(state),
    isAccountLinkedToSchool: isSchoolAccount(state),
    shouldDisplayWellbeingNewTag: shouldDisplayWellbeingNewTag(),
    isWellbeingBannerVisible: shouldDisplayWellbeingBanner(state),
    accountHasProtectedDevices: hasProfileWithActiveDevice(state),
    schoolOnboardingDone: schoolOnboardingStatus === 'done',
  };
};

const profileListItemClick =
  (id: number): BaseThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const profile = getProfileOrDefault(state, id);
    const navigate = getMultiPlatformNavigation();
    const isProfileDelegatedToThisAccount =
      isStudentOptInForDelegationForThisAccount(state);

    if (profile.isLinewizestudentLinked) {
      if (
        flags.useUnifiedDashboard.isEnabled() &&
        ((profile.deviceCount && profile.deviceCount > 0) ||
          (flags.useDelegation.isEnabled() && isProfileDelegatedToThisAccount))
      ) {
        return dispatch(
          navigate({
            type: 'inner',
            src: `/profiles/${id}/family-and-school`,
          })
        );
      }
      return dispatch(
        navigate({
          type: 'inner',
          src: `/profiles/${id}/school/summary`,
        })
      );
    }

    if (isMagicDebugProfile(profile)) {
      return dispatch(navigate({ type: 'inner', src: `/debug/${id}` }));
    }

    if (isAccountOverLimit(state)) {
      return dispatch(
        navigate({
          type: 'inner',
          src: {
            pathname: `/modal/OverLimitModal`,
            query: {
              profileId: id,
              referrer: OverLimitModalReferrer.overlimitAccessProfile,
            },
          },
        })
      );
    }

    if (profile.status === PROFILE_STATUS_NO_DEVICE) {
      return dispatch(
        navigate({ type: 'inner', src: `/profiles/${id}/add-device` })
      );
    }

    return dispatch(navigate({ type: 'inner', src: `/profiles/${id}` }));
  };

const profileAddClick = (): BaseThunk<void> => (dispatch, getState) => {
  const state = getState();
  const navigate = getMultiPlatformNavigation();
  if (areProfilesExceeded(state)) {
    track(Events.ClickedAddProfile);
    return dispatch(
      navigate({
        type: 'inner',
        src: '/modal/OverLimitModal',
      })
    );
  }
  if (areProfilesAtLimit(state)) {
    track(Events.ClickedAddProfile);
    return dispatch(
      navigate({
        type: 'inner',
        src: '/modal/ProfileAtLimitModal',
      })
    );
  }
  dispatch(addProfileClickHandler());
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onProfileListItemClick: (id: number) => dispatch(profileListItemClick(id)),
  onProfileAddClick: () => {
    trackButtonClicked(GenericPageNames.MyFamily, ButtonNames.AddAChild);
    dispatch(profileAddClick());
  },
  onIconMenuClick: () => {
    trackOpenedBurgerMenu();
    store.dispatch(sideBarStateModeChanged('regular'));
    return dispatch(sideBarStateChanged(true));
  },
  onRefresh: () =>
    dispatch(reloadAppData()).catch((error: AnyError) =>
      handleApiErrorMessage(error)
    ),
  onClickUpgradeToPremium: () => {
    track(Events.ClickedOverlimitBannerUpgrade);
    const navigate = getMultiPlatformNavigation();
    dispatch(navigate({ type: 'inner', src: '/upgrade' }));
  },
  onClickGift: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(navigate({ type: 'inner', src: '/trial/modal/ReTrialModal' }));
  },
  onClickProtectDevice: (isBanner: boolean) => {
    const navigate = getMultiPlatformNavigation();
    trackButtonClicked(GenericPageNames.MyFamily, ButtonNames.ProtectADevice, {
      onboarding_banner: isBanner,
    });
    dispatch(navigate({ type: 'inner', src: '/schools/choose-a-profile' }));
  },
  onCloseWellbeingBanner: () => {
    trackButtonClicked(GenericPageNames.WellbeingBanner, ButtonNames.Close);
    dispatch(setItem('wellbeingBannerClosed', 'true'));
  },
  onClickWellbeingBannerButton: () => {
    const navigate = getMultiPlatformNavigation();
    trackButtonClicked(
      GenericPageNames.WellbeingBanner,
      ButtonNames.DiscoverMore
    );
    dispatch(navigate({ type: 'inner', src: '/wellbeing' }));
  },
  onLoad: () => {
    dispatch(requestSummaryAlertsAction());
    dispatch(requestFeaturesAction());
  },
});

const ProfileListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(ProfileList, PageNames.MyFamily, (_, { profiles }) => {
    const protectedProfiles = profiles.filter(
      (profile: ProfileRecord) => profile.isLinewizestudentLinked
    );
    return {
      number_of_profiles: profiles.size || 0,
      number_of_linked_profiles: protectedProfiles.size || 0,
    };
  })
);

export type ProfileListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default ProfileListContainer;
