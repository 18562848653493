import { isAutoRenewal, LicenseType } from '../../../records/license';
import type { ConversionTopBarProps } from './types';

export const isVisibleConversionTopBar = ({
  daysToExpire,
  license,
}: ConversionTopBarProps): boolean => {
  if (isAutoRenewal(license.renewal)) return false;
  if (license.type === LicenseType.Premium && daysToExpire <= 30) return true;
  if (license.type === LicenseType.Trial && daysToExpire <= 3) return true;
  return false;
};
