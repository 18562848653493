/* eslint-disable jsx-a11y/anchor-is-valid */
import { isEmpty } from 'ramda';
import * as React from 'react';
import {
  Typography,
  FlexLayout,
  Link,
  Layout,
  Card,
  GlobalType,
  Icon,
  IconType,
  IconSize,
  IconColor,
  FreeText,
} from 'styleguide-react';
import { t } from '../../../../../lib/i18n';
import AddCircleButton from '../../../AddCircleButton/AddCircleButton';
import { getCategoryIcon } from '../../../helpers/routineIcons';
import RoutineIcon from '../../../RoutineIcon/RoutineIcon';
import { PolicyApplication } from '../../../../../records/routines/policy/types/PolicyApplication.types';
import { PolicyWebCategory } from '../../../../../records/routines/policy/types/PolicyWebCategory.types';
import {
  ContextContentFilter,
  RoutineRuleExceptionType,
  RoutineMode,
  RoutineRuleType,
  AppRuleExceptions,
  WebRuleExceptions,
} from '../../../routines.types';
import { RoutineColor } from '../../../../../palettes/RoutineColor';
import { getCategoryLabelById } from '../../../../../records/category';
import { makeAppKey } from '../../../../../records/profileRules';

export interface ExceptionsSelectorProps {
  appsException: AppRuleExceptions;
  websException: WebRuleExceptions;
  onRemoveExceptions: (
    apps: AppRuleExceptions | WebRuleExceptions,
    type: RoutineRuleExceptionType,
    removeAll?: boolean
  ) => void;
  onAddExceptions: (data: ContextContentFilter) => void;
}

export interface CreateExceptionsSelectorI<T> {
  disabled: boolean;
  showLoader?: boolean;
  exceptions: Array<T>;
  onRemoveExceptions: (
    data: Array<T>,
    type: RoutineRuleExceptionType,
    removeAll?: boolean
  ) => void;
  onAddExceptions: (type: RoutineRuleExceptionType) => void;
  ruleType: RoutineRuleType;
  mode: RoutineMode;
  activeTab: RoutineRuleExceptionType;
  testId: string;
}

const getExceptionMessageText = (
  activeTab: RoutineRuleExceptionType,
  ruleType: RoutineRuleType
) => {
  const bold = {
    1: text => <strong>{text}</strong>,
  };

  if (activeTab === 'WEB' && ruleType === 'DEFAULT') {
    return t('Once you’ve chosen website rules you can add exceptions here.');
  }
  if (activeTab === 'WEB' && ruleType === 'POLICY_ACTION_ALLOWED') {
    return t('Add the website categories you want to {1}block{/1}.', bold);
  }
  if (activeTab === 'WEB' && ruleType === 'POLICY_ACTION_BLOCKED') {
    return t('Add the website categories you want to {1}allow{/1}.', bold);
  }
  if (activeTab === 'APP' && ruleType === 'DEFAULT') {
    return t('Once you’ve chosen app rules you can add exceptions here.');
  }
  if (activeTab === 'APP' && ruleType === 'POLICY_ACTION_ALLOWED') {
    return t('Add the apps you want to {1}block{/1}.', bold);
  }
  if (activeTab === 'APP' && ruleType === 'POLICY_ACTION_BLOCKED') {
    return t('Add the apps you want to {1}allow{/1}.', bold);
  }
};

interface CreateExceptionsSelectorProps<T> {
  type: RoutineRuleExceptionType;
  getIcon: (data: T) => JSX.Element;
  getName: (data: T) => string;
  getId: (data: T) => string;
}

const CreateExceptionsSelector =
  <T,>({ type, getIcon, getName, getId }: CreateExceptionsSelectorProps<T>) =>
  ({
    disabled = false,
    showLoader = false,
    testId,
    exceptions,
    activeTab,
    ruleType,
    onRemoveExceptions,
    onAddExceptions,
  }: CreateExceptionsSelectorI<T>) => {
    const hasApps = !isEmpty(exceptions);

    const onRemoveExceptionsHandler = React.useCallback(
      (data, type, removeAll = false) =>
        () =>
          onRemoveExceptions(data, type, removeAll),
      [onRemoveExceptions]
    );

    const onAddExceptionsHandler = React.useCallback(
      type => () => onAddExceptions(type),
      [onAddExceptions]
    );

    return (
      <FlexLayout
        mainaxis="column"
        className="par-routines-exceptions-selector"
      >
        <FlexLayout
          mainaxis="row"
          width="100%"
          mainaxisAlignment="space-between"
          marginBottom="8"
        >
          <Typography type="body2" weight="semibold">
            {type === 'APP' ? t('App exceptions') : t('Category exceptions')}
          </Typography>
        </FlexLayout>

        <FlexLayout mainaxis="row" marginBottom="24">
          <Typography type="body2">
            {getExceptionMessageText(activeTab, ruleType)}
          </Typography>
        </FlexLayout>

        <Card
          testId={testId}
          type={GlobalType.gray}
          className={
            hasApps
              ? 'par-routines-exceptions-selector__panel'
              : 'par-routines-exceptions-selector__panel--empty'
          }
        >
          <AddCircleButton
            disabled={disabled}
            showLoader={showLoader}
            testId={`${testId}-add-button`}
            onAddExceptions={onAddExceptionsHandler(type)}
          />

          {exceptions.map(data => {
            const id = getId(data);
            const name = getName(data);
            return (
              <div
                key={id}
                title={name}
                className="par-routines-exceptions-selector-icon"
                data-testid={`${testId}-item-${id}`}
              >
                <div className="par-routines-exceptions-selector-icon__container">
                  {getIcon(data)}
                  <div className="par-routines-exceptions-selector-icon__float-right-icon">
                    <Icon
                      testId={`${testId}-item-${id}-icon-remove`}
                      type={IconType.minus}
                      size={IconSize.regular}
                      color={IconColor.secondary}
                      onClick={
                        !disabled
                          ? onRemoveExceptionsHandler([data], type)
                          : undefined
                      }
                    />
                  </div>
                  <FreeText className="par-routines-exceptions-selector-icon__text">
                    {name}
                  </FreeText>
                </div>
              </div>
            );
          })}
        </Card>

        {hasApps && (
          <Layout
            className="par-routines-exceptions-selector__remove-link"
            marginTop="16"
          >
            <Link
              testId={`${testId}-remove-all`}
              onClick={
                !disabled
                  ? onRemoveExceptionsHandler(exceptions, type, true)
                  : undefined
              }
            >
              {t('Remove all')}
            </Link>
          </Layout>
        )}
      </FlexLayout>
    );
  };

export const AppsException = CreateExceptionsSelector<PolicyApplication>({
  type: 'APP',
  getIcon: app => (
    <img
      className="par-routines-exceptions-selector-icon__img"
      src={app.src}
      alt={app.name}
    />
  ),
  getName: app => app.name,
  getId: app => makeAppKey(app),
});

export const WebsException = CreateExceptionsSelector<PolicyWebCategory>({
  type: 'WEB',
  getIcon: web => (
    <RoutineIcon
      icon={getCategoryIcon(web.category)}
      size="xlarge"
      color={
        web.action === 'POLICY_ACTION_ALLOWED'
          ? RoutineColor.green
          : RoutineColor.red
      }
    />
  ),
  getName: web => getCategoryLabelById(web.category),
  getId: web => web.category,
});
