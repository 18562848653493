import { getDefaultLocale } from '../lib/i18n/core';
import {
  Platform,
  PLATFORM_MAC,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_WINDOWS,
} from '../constants';
import IconDeviceAndroid from '../components/Icons/Onboarding/IconDeviceAndroid';
import IconDeviceIos from '../components/Icons/Onboarding/IconDeviceIos';
import IconDeviceMac from '../components/Icons/Onboarding/IconDeviceMac';
import IconDeviceWindows from '../components/Icons/Onboarding/IconDeviceWindows';
import IconDeviceGeneric from '../components/Icons/Onboarding/IconDeviceGeneric';

export const getImagePathByLanguage = (imagesByLanguage: string[]) => {
  const locale = getDefaultLocale();
  let imagePathByLanguage = '';
  imagesByLanguage.map(imagePath => {
    if (
      imagePath.split('__')[1] &&
      imagePath.split('__')[1].split('.')[0] &&
      locale === imagePath.split('__')[1].split('.')[0]
    ) {
      imagePathByLanguage = imagePath;
    }
  });
  return imagePathByLanguage;
};

export const getOnboardingIconByPlatform = (platform: Platform) => {
  const images = {
    [PLATFORM_ANDROID]: IconDeviceAndroid,
    [PLATFORM_IOS]: IconDeviceIos,
    [PLATFORM_MAC]: IconDeviceMac,
    [PLATFORM_WINDOWS]: IconDeviceWindows,
    generic: IconDeviceGeneric,
  };

  return images[platform] || images.generic;
};
