import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import {
  getYoutubeSummary,
  hasYoutubeSummaryData,
  getActiveDateRange,
} from '../../selectors';
import YoutubeSummaryCard from '../../components/ActivityCards/YoutubeSummary/YoutubeSummaryCard';
import { activitySwiperIndexChanged } from '../../ducks/ui';
import {
  navigateToYoutubeActivity,
  openSummaryCardInfoModalFor,
} from '../../actions/ProfileActions';
import { track, Events } from '../../helpers/analytics';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { ActivityType } from '../../components/ActivitySummary/sharedModels';
import { isCombinedDashboardProfile } from '../Dashboard/helpers/profileDashboardHelpers';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { shouldBlockThisFeature } from '../../helpers/premiumFeatures';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { navigateToYoutubeAppRules } from '../../actions/YoutubeRuleActions';

interface YoutubeSummaryCardContainerProps {
  profile: ProfileRecord;
  className?: string;
  width?: string;
  needsBorder?: boolean;
  needsMargin?: boolean;
}

const mapStateToProps = (
  state: State,
  { profile }: Partial<YoutubeSummaryCardContainerProps>
) => {
  const licenseSubtype = getLicenseSubtype(state);
  const isYoutubeFeatureBlocked = shouldBlockThisFeature(
    'youtubeRules',
    licenseSubtype
  );

  return {
    summary: getYoutubeSummary(state),
    isEmpty: !hasYoutubeSummaryData(state),
    dateRange: getActiveDateRange(state),
    isCombinedAndUndelegatedProfile:
      !!profile?.id &&
      isCombinedDashboardProfile(state, profile.id) &&
      !isProfileDelegatedToThisAccount(
        isDelegationEnabled(state),
        isStudentOptInForDelegationForThisAccount(state),
        Boolean(profile?.isLinewizestudentLinked)
      ),
    profileName: profile?.name,
    isYoutubeFeatureBlocked,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profile, className, width, needsBorder }: YoutubeSummaryCardContainerProps
) => ({
  className,
  width,
  needsBorder,
  onExpand: (isYoutubeFeatureBlocked: boolean) => {
    dispatch(activitySwiperIndexChanged(2));
    if (isYoutubeFeatureBlocked) {
      dispatch(navigateToYoutubeAppRules(profile.id));
    } else {
      dispatch(navigateToYoutubeActivity(profile.id));
      track(Events.ClickedSeeAllButton, {
        page: 'Youtube Summary',
        category: 'Activity cards',
      });
    }
  },
  goToInfoModal: () => {
    track(Events.ClickedInfoButtoninSummaryCard, {
      card: 'Youtube Activity',
    });
    dispatch(openSummaryCardInfoModalFor(ActivityType.Youtube));
  },
});

const YoutubeSummaryOverviewCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubeSummaryCard);

export type YoutubeSummaryCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default YoutubeSummaryOverviewCardContainer;
