import * as React from 'react';
import { t } from '../../../lib/i18n';
import { ScreenSize } from '../../RenderWhen/RenderWhen';
import PageLayout from '../../Layout/PageLayout';
import {
  Card,
  FlexLayout,
  IconAdapter,
  Link,
  Typography,
} from 'styleguide-react';
import { LinewizeEventActions } from '../../../records/linewizeEvent/types/linewizeEvents';
import { getWebAppIcon } from './common/schoolEventsCommon';
import { ACTION_ALLOW, ACTION_BLOCK } from '../../../constants';

export interface EventTitleAndUrl {
  url?: string;
  title: string;
}
export interface EventUrlsByHost {
  [host: string]: Array<EventTitleAndUrl>;
}

export interface SchoolTimelineDetailProps {
  profileName: string;
  eventUrlsByHost: EventUrlsByHost;
  eventAction: LinewizeEventActions;
  eventTitle: string;
  eventIcon?: string;
  onClickGoBack: () => void;
  onLinkClick: (url: string) => void;
}

const SchoolTimelineDetail: React.FC<SchoolTimelineDetailProps> = ({
  profileName,
  eventUrlsByHost,
  eventAction,
  eventTitle,
  eventIcon,
  time,
  onClickGoBack,
  onLinkClick,
}) => {
  const multipleDomainSubtitles = {
    [ACTION_ALLOW]:
      '{{profileName}} accessed the following websites at {{time}}:',
    [ACTION_BLOCK]:
      '{{profileName}} tried to access the following websites at {{time}}:',
  };

  const subtitle = multipleDomainSubtitles[eventAction];

  return (
    <PageLayout
      title={eventTitle}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          titleCentered: false,
          mainExtendedSize: true,
          columns: 1,
          cardCentered: false,
        },
      ]}
      actions={[
        {
          actionName: 'back',
          onClick: onClickGoBack,
        },
      ]}
    >
      <Card>
        <FlexLayout mainaxis="column" scrollY>
          <FlexLayout
            mainaxis="row"
            className="par-school-timeline-detail__container"
          >
            <Typography type="body1">
              {t(subtitle, {
                profileName,
                time,
              })}
            </Typography>
          </FlexLayout>
          <FlexLayout
            mainaxis="column"
            className="par-school-timeline-detail__list"
          >
            {Object.entries(eventUrlsByHost).map(([host]) => {
              return (
                <FlexLayout
                  key={host}
                  mainaxis="row"
                  textAlignement="center"
                  crossaxisAlignment="center"
                  className="par-school-timeline-detail__host-wrapper"
                >
                  <div
                    className="par-school-timeline-detail__icon"
                    data-testid="school-timeline-details-icon"
                  >
                    <IconAdapter
                      icon={getWebAppIcon(eventIcon, eventAction, false)}
                    />
                  </div>
                  <Link onClick={() => onLinkClick(`https://${host}`)}>
                    {host}
                  </Link>
                </FlexLayout>
              );
            })}
          </FlexLayout>
        </FlexLayout>
      </Card>
    </PageLayout>
  );
};

export default SchoolTimelineDetail;
