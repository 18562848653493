import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';
import { LinewizeEventAction } from '../../businessLogic/LinewizeEvents/LinewizeEvent.types';
import { t } from '../../lib/i18n';
import { LinewizeEventRecord } from '../../records/linewizeEvent/types/linewizeEvents';
import Dropdown from '../DropDown/DropDown';

export interface ActivityStyledItemActionProps {
  title: string;
  event: LinewizeEventRecord;
  actions: Array<LinewizeEventAction>;
  onActionClick: (
    event: LinewizeEventRecord,
    action: LinewizeEventAction
  ) => void;
}

const getLabel = (type: LinewizeEventAction['type']) => {
  if (type === 'SIGNATURE') return 'See Insights';
  if (type === 'LINK') return 'Visit website';
  if (type === 'DETAILS') return 'See details';
  throw new Error(`Impossible to get label for type ${type}.`);
};

const getIcon = (type: LinewizeEventAction['type']) => {
  if (type === 'SIGNATURE') return IconType.arrowCircleRight;
  if (type === 'LINK') return IconType.arrowUpRightFromSquare;
  if (type === 'DETAILS') return IconType.eye;
  throw new Error(`Impossible to get icon for type ${type}.`);
};

const ActivityStyledItemAction: React.FC<ActivityStyledItemActionProps> = ({
  title,
  event,
  actions,
  onActionClick,
}) => {
  if (actions.length <= 0) {
    return null;
  }
  return (
    <Dropdown
      minWidth={300}
      title={title}
      options={actions.map((action, i) => ({
        index: String(i),
        label: t(getLabel(action.type)),
        onClick: () => onActionClick(event, action),
        icon: (
          <Icon
            color={IconColor.primary}
            family={IconFamily.solid}
            size={IconSize.lg}
            type={getIcon(action.type)}
          />
        ),
      }))}
    />
  );
};

export default ActivityStyledItemAction;
