import * as React from 'react';
import { t } from '../../lib/i18n';
import { isTodaysDate, formatDateNice } from '../../helpers/dates';
import { Layout, Typography } from 'styleguide-react';

const TimeSeparator: React.FunctionComponent<{
  currentTime: any;
  timezone: string;
  locale: string;
  renderEvent: any;
  day: any;
  events: any;
}> = ({ currentTime, timezone, locale, renderEvent, day, events }) => (
  <div
    className={
      events.size === 0 || events.size === undefined ? 'ActivityMessage' : ''
    }
    key={`day-${day}`}
  >
    <Layout marginBottom="32">
      <Typography type="title2" weight="semibold" testId="TimeSeparator">
        {isTodaysDate(currentTime, day, timezone)
          ? t('Today')
          : formatDateNice(day, timezone, locale)}
      </Typography>
    </Layout>
    {events.size === 0 ? (
      <p>{t('No activity today')}</p>
    ) : (
      events.map((event, index) => renderEvent(event, events, index))
    )}
  </div>
);

export default TimeSeparator;
