import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import { formRecord } from '../../components/AccountSettings/EditEmailForm';
import {
  modifyEmail,
  receiveAccount,
  requestModifyAccountError,
} from '../../ducks/account';
import { AccountRecord } from '../../records';
import {
  getAccount,
  getAccountModifyStatus,
  getLocation,
  getSchoolLinkedStatus,
} from '../../selectors';
import { showErrorToast } from './helpers';
import EditEmail from '../../components/AccountSettings/EditEmail';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import State, { BaseThunk, Dispatch } from '../../store/state';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const formName = 'editEmail';
const values = getFormValues(formName);

const mapStateToProps = (state: State) => {
  const { id } = getAccount(state);
  return {
    initialValues: formRecord({
      id,
      email: '',
      confirmEmail: '',
      password: '',
    }),
    showSpinner: getAccountModifyStatus(state),
    currentEmail: getAccount(state).email,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFormSubmit: () => dispatch(confirmSubmit()),
});

function confirmSubmit(): BaseThunk<void> {
  return (dispatch, getState) => {
    const state = getState();
    const { pathname } = getLocation(state);
    // if the user has anything that is not null we want to show the alert modal,
    // to prevent them from unlinking without knowing.
    const isAccountLinkedToSchool = getSchoolLinkedStatus(state);
    const navigate = getMultiPlatformNavigation();
    if (isAccountLinkedToSchool) {
      return dispatch(
        navigate({
          type: 'inner',
          src: `${pathname}/modal/EditEmailConfirmationModal`,
        })
      );
    }

    return dispatch(onFormSubmit());
  };
}

function onFormSubmit(): BaseThunk<void> {
  return (dispatch, getState) => {
    const form = formRecord(values(getState()));

    return Promise.resolve(form)
      .then(serializeEmailChangeRequest)
      .then(data => dispatch(modifyEmail(data)))
      .then(() =>
        dispatch(
          receiveAccount(
            AccountRecord.serialize(setAccountRecord(form, getState()))
          )
        )
      )
      .then(() =>
        dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
      )
      .then(() => dispatch(goBackIfHistory()))
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(showErrorToast(error));
      });
  };
}

function serializeEmailChangeRequest(record) {
  return { password: record.password, new_email: record.email.toLowerCase() };
}

const setAccountRecord = (form, state): AccountRecord => {
  const account = getAccount(state).set('email', form.email);
  return AccountRecord(account);
};

const EditEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmail);

export default EditEmailContainer;
