import * as React from 'react';
import {
  FlexLayout,
  Tabs,
  TabPanel,
  DeactivableContent,
} from 'styleguide-react';
import {
  CallsSmsRulesSection,
  CALLSMS_RULES_SECTION,
  CALLSMS_CONTACTS_SECTION,
} from '../../../constants';
import { t } from '../../../lib/i18n';
import { CallsSMSRulesProps } from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import CallsSMSCallsSection, {
  CallsSMSCallsSectionProps,
} from './CallsSMSCallsSection';
import CallsSMSContacts, { CallsSMSContactsProps } from './CallsSMSContacts';
import CallsSMSMessagesSection, {
  CallsSMSMessagesSectionProps,
} from './CallsSMSMessagesSection';

export type CallsSMSTabsProps = Pick<
  CallsSMSRulesProps,
  | 'onClickDisabledSection'
  | 'onClickSection'
  | 'callsSMSRulesEnabled'
  | 'activeSection'
> &
  CallsSMSCallsSectionProps &
  CallsSMSMessagesSectionProps &
  CallsSMSContactsProps;

const CallsSMSTabs: React.FC<CallsSMSTabsProps> = (
  props: CallsSMSTabsProps
) => {
  const {
    callsSMSRulesEnabled,
    onClickDisabledSection,
    onClickSection,
    activeSection,
  } = props;
  const handleTabClick = (name: CallsSmsRulesSection) => {
    if (name === CALLSMS_CONTACTS_SECTION && !callsSMSRulesEnabled) {
      return onClickDisabledSection();
    }

    return onClickSection(name);
  };

  const renderTabPanelContent: <T>(elem: T) => JSX.Element = elem => (
    <FlexLayout
      mainaxis="column"
      paddingTop="16"
      className="calls-and-messages"
    >
      {elem}
    </FlexLayout>
  );

  return (
    <DeactivableContent disabled={!callsSMSRulesEnabled}>
      <Tabs
        onChange={(name: string) =>
          handleTabClick(name as CallsSmsRulesSection)
        }
        animateOnChange={false}
        testId="calls-and-sms-tabs"
        className="c&m-tabs"
      >
        <TabPanel
          name={CALLSMS_RULES_SECTION}
          content={t('Calls')}
          testId={`tabs-header-${CALLSMS_RULES_SECTION}`}
          active={activeSection === CALLSMS_RULES_SECTION}
        >
          {renderTabPanelContent(
            <React.Fragment>
              {CallsSMSCallsSection(props)}
              {CallsSMSContacts(props)}
            </React.Fragment>
          )}
        </TabPanel>

        <TabPanel
          name={CALLSMS_CONTACTS_SECTION}
          content={t('Messages')}
          testId={`tabs-header-${CALLSMS_CONTACTS_SECTION}`}
          active={activeSection === CALLSMS_CONTACTS_SECTION}
        >
          {renderTabPanelContent(CallsSMSMessagesSection(props))}
        </TabPanel>
      </Tabs>
    </DeactivableContent>
  );
};

export default CallsSMSTabs;
