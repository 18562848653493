import * as React from 'react';
import { t } from '../../lib/i18n';
import type { CallsSMSRulesPremiumFlyoverProps } from '../../containers/ProfileRules/CallsSMSRules/CallsSMSRulesPremiumFlyoverContainer';
import CallsSMSRulesFlyover from './CallsSMSRulesFlyover';

const FLYOVER_TEST_ID = 'calls-and-messages-rules-premium-flyover';

const CallsSMSRulesPremiumFlyover = ({
  canPurchase,
  accountLocale,
  onClickClose,
  onClickUpgrade,
}: CallsSMSRulesPremiumFlyoverProps) => {
  const actionButtonTitle = canPurchase ? t('Upgrade') : t('Ok');
  const actionButtonFn = canPurchase ? onClickUpgrade : onClickClose;

  return (
    <CallsSMSRulesFlyover
      accountLocale={accountLocale}
      testId={FLYOVER_TEST_ID}
      topbarText={t('{1}Upgrade your plan{/1} to monitor calls & messages!', {
        1: x => x,
      })}
      actionButton={{
        title: actionButtonTitle,
        onClick: actionButtonFn,
        testId: `${FLYOVER_TEST_ID}-upgrade`,
      }}
      onClickClose={onClickClose}
      footNote={t(
        '*Calls & messages monitoring for iOS requires a Mac computer.'
      )}
    />
  );
};

export default CallsSMSRulesPremiumFlyover;
