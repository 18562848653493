import { connect } from 'react-redux';
import {
  isProfileLinkedWithLinewizeStudent,
  isStudentSummaryPath,
} from '../selectors/profile';
import ft, { DISABLE_CALLS_SMS_RULES } from '../lib/ft';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../store/state';
import {
  getAccountCurrentTime,
  getActiveDateRange,
  getActivitySwiperIndex,
  getIsEmptyTimeline,
  getKidHasTamparedDevice,
  getLicense,
  getProfileOrDefault,
  getRangeScreenTime,
  getSelectedDateScreenTime,
  getSummaryAppsAndWebActivity,
  getTimezone,
  hasDeviceAssigned,
  isFetchingNewEvents,
  isFetchingProfile,
  isFloatingActionMenuOpen,
  isRefreshingProfile,
  isAccountOverLimit,
  getProfileDevices,
} from '../selectors';

import { isNotExistingProfile } from '../records/profile';
import {
  closeFloatingActionMenu,
  toggleFloatingActionMenu,
} from '../ducks/app';
import { fetchDevices } from '../ducks/devices';
import {
  fetchEvents,
  hideTimelineActionSheets,
  setFilter,
  shouldFetchMoreEvents,
} from '../ducks/events';
import { ActivityEventFilters } from '../businessLogic/timeline/types';
import { updateCurrentTime } from '../ducks/times';
import { fetchProfile, fetchProfiles } from '../ducks/profiles';
import {
  fetchAppsAndWebActivity,
  fetchBlockedWebActivity,
  fetchRangeScreenTime,
  fetchSelectedDateScreenTime,
  fetchSummary,
  resetSummary,
} from '../ducks/summary';
import { fetchProfileRules } from '../ducks/profileRules';
import { activitySwiperIndexChanged } from '../ducks/ui';
import { goBackIfHistory } from '../ducks/routing';
import {
  fetchCallsAndSmsIfUserIsPremiumOrTrial,
  fetchSearches,
} from '../ducks/socialActivity';

import { isMagicDebugProfile } from '../helpers';
import { AnyError, handleApiErrorMessage } from '../helpers/errorHandling';
import { SummaryDateRanges } from '../constants';
import {
  addDevice,
  refreshProfile,
  selectedNewDateRangeForProfile,
  showStatsNotification,
} from '../actions/ProfileActions';
import ProfileNew from '../components/Profiles/ProfileNew';
import { gaEvent } from '../helpers/ga';
import { Events, track } from '../helpers/analytics';
import { fetchYoutubeSummary } from '../ducks/youtubeSummary';
import {
  getFeatureFlag,
  isQuestionableWebSearchesFeactureActive,
} from '../selectors/featureFlags';
import { fetchExtraTime } from '../ducks/extraTime';
import { summaryIsLoadingData } from '../ducks/studentSummary';
import { isStudentSummaryDataStale } from '../selectors/studentSummary';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { getNowTrackingCardStatus } from './NowCard/helpers/family/helper';
import { fetchCalendarRestrictionPause } from '../ducks/calendarRestrictionsPause';
import { fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork } from '../ducks/safeNetwork/thunks';
import { fetchStudentPolicies } from '../ducks/studentPolicies';
import { isProfileDelegatedToThisAccount } from '../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../selectors/studentPolicies';

const mapStateToProps = (state: State, routerParams: RouterParamsProps) => {
  const { profileId } = routerParams.params;
  const timezone = getTimezone(state);
  const currentTime = getAccountCurrentTime(state);

  const activeDateRange = getActiveDateRange(state);

  const selectedDateScreenTime = getSelectedDateScreenTime(state);
  const rangeScreenTime = getRangeScreenTime(state).takeLast(activeDateRange);
  const isEmptyHistogram =
    activeDateRange === SummaryDateRanges.Today
      ? selectedDateScreenTime.toJS().every(value => value.minutes === 0)
      : rangeScreenTime.toJS().every(value => value.hours === 0);
  const isEmptyTimeline = getIsEmptyTimeline(state);
  const isEmptyTopActivity = getSummaryAppsAndWebActivity(state).size === 0;
  return {
    ...routerParams,
    profile: getProfileOrDefault(state, parseInt(profileId, 10)),
    isFetching: isFetchingProfile(state) && !isRefreshingProfile(state),
    isFetchingNewEvents: isFetchingNewEvents(state),
    activeDateRange: getActiveDateRange(state),
    isMenuOpen: isFloatingActionMenuOpen(state),
    selectedDateScreenTime,
    rangeScreenTime,
    showStatsNotification: showStatsNotification(
      getLicense(state),
      currentTime,
      timezone
    ),

    isEmpty: isEmptyHistogram && isEmptyTimeline && isEmptyTopActivity,
    activitySwiperIndex: getActivitySwiperIndex(state),
    hasTamperedDevice: getKidHasTamparedDevice(state, profileId),
    showYoutubeCard: getFeatureFlag(state, 'youtubeSummary'),
    showCallsSMSCard: !ft.active(DISABLE_CALLS_SMS_RULES),
    showInsights: getFeatureFlag(state, 'insights'),
    isQuestionableWebSearchesFeactureActive:
      isQuestionableWebSearchesFeactureActive(state),
    showProfileSummarySelector: isProfileLinkedWithLinewizeStudent(
      state,
      profileId
    ),
    routerParams: routerParams.params,
    nowTrackingCardStatus: getNowTrackingCardStatus(state, profileId),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  handleRefresh: () => dispatch(refreshProfile(parseInt(profileId, 10))),
  handleMore: () => dispatch(shouldFetchMoreEvents(parseInt(profileId, 10))),
  onClickEdit: () => {
    track(Events.ClickedProfileEdit);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onSelectDateRange: (range: SummaryDateRanges) =>
    dispatch(selectedNewDateRangeForProfile(range, profileId)),

  onClickAddDevices: () => {
    return dispatch(addDevice(profileId));
  },
  // Floating button + menu
  onToggleMenu: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'open-close');
    return dispatch(toggleFloatingActionMenu());
  },

  onClickSpeedDialAddDevices: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'add-device');
    dispatch(closeFloatingActionMenu());
    return dispatch(addDevice(profileId));
  },
  onClickRules: () => {
    const navigate = getMultiPlatformNavigation();
    gaEvent('kid-profile-time-floating-button', 'click', 'set-rules');
    track(Events.BtnClickRules);
    dispatch(closeFloatingActionMenu());
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/rules` })
    );
  },
  onClickGoBack: () => {
    dispatch(activitySwiperIndexChanged(0));
    dispatch(goBackIfHistory());
  },
});

const ProfileNewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileNew);

ProfileNewContainer.load =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    /**
     * Not very fan of this, but since we have the student summaries under
     * "/profiles/pf_id" route, this load is executed and we are fetching the
     * summaries for family as well. Apart from that, this prevent a glitch
     * loading the summary data in the student summary.
     *
     * We also check that StudentSummaryData is stale and needs to be refetched.
     * If that's the case only then we have to show loading state to avoid the glitch,
     * otherwise, the data is already present in the state and StudentSummaryContainer
     * will not refetch, hence avoiding the glitch.
     * If we don't do this check, the app gets stuck in loading state, because no api call
     * is executed to disable the loading flag at finalizing.
     */

    if (
      isStudentSummaryPath(getState()) &&
      isStudentSummaryDataStale(getState())
    ) {
      dispatch(summaryIsLoadingData());
    }

    dispatch(resetSummary());
    dispatch(setFilter(ActivityEventFilters.All));
    dispatch(hideTimelineActionSheets());
    dispatch(updateCurrentTime());

    const id = parseInt(params.profileId, 10);
    const state = getState();
    const profile = getProfileOrDefault(state, id);
    const navigate = getMultiPlatformNavigation();
    const profileUid = profile.uid;

    if (isNotExistingProfile(profile)) {
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: '/',
        })
      );
    }

    dispatch(fetchKidDataFor(id));
    dispatch(fetchProfile(params.profileId));
    return dispatch(
      fetchCalendarRestrictionPause(profileUid, params.profileId)
    );
  };

export const fetchKidDataFor =
  (id: number) => (dispatch: Dispatch, getState: () => State) => {
    const profile = getProfileOrDefault(getState(), id);

    return Promise.all([
      dispatch(fetchEvents(profile.uid)),

      dispatch(fetchProfiles()),

      dispatch(fetchProfileRules(id, profile.deviceIds)).then(() =>
        /**
         * The app activities that come from the endpoint
         * "v2/accounts/<ac_uid>/profiles/<profile_uid>/summary/domains-and-apps"
         * do not include a thumbnail link. We add the thumbnail link to each app
         * activity ourselves in case it's available, if not we default to "null".
         *
         * To obtain the thumbnail links for each app we rely on the
         * kidProfileRules endpoint "v1/accounts/${this.accountId}/profiles/${profileId}/rules"
         * This endpoint returns a list of apps that the kid used. For each app in the list,
         * the thumbnail link it's included if available, if not it defaults to "null"
         *
         * So before attempting to call `fetchAppsAndWebActivity` we need to ensure
         * that we have the "kid profile rules" data (which include the thumbnail links).
         * Ideally the thumbnail link will be included along the app activities
         * data that is fetched through `fetchAppsAndWebActivity`
         * I created a backend ticket for handling this at some point (BCK-6335)
         */
        dispatch(fetchAppsAndWebActivity(profile.uid))
      ),
      dispatch(fetchExtraTime(profile.uid)),
      dispatch(fetchSummary(id)),

      dispatch(fetchSelectedDateScreenTime(profile.uid)),
      dispatch(fetchRangeScreenTime(profile.id.toString())),
      dispatch(fetchBlockedWebActivity(profile.uid)),
      dispatch(fetchYoutubeSummary(profile.uid)),
      dispatch(fetchDevices()),
      dispatch(fetchCallsAndSmsIfUserIsPremiumOrTrial(profile.uid)),
      dispatch(fetchSearches(profile.uid)),
    ])
      .then(() => {
        dispatch(
          // This function needs the profile and its devices in the state
          fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork(profile.id)
        );
        if (profile.isLinewizestudentLinked) {
          dispatch(fetchStudentPolicies(profile.id));
        }
      })
      .catch((error: AnyError) => handleApiErrorMessage(error, true));
  };

ProfileNewContainer.redirect =
  (params: RouterParams) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const profileId = Number(params.profileId);
    const state = getState();
    const profile = getProfileOrDefault(state, profileId);
    const navigate = getMultiPlatformNavigation();

    if (profile.isLinewizestudentLinked) {
      await fetchStudentPolicies(profileId);
      const updatedState = getState();
      const isDelegatedToThisAccount = isProfileDelegatedToThisAccount(
        isDelegationEnabled(updatedState),
        isStudentOptInForDelegation(updatedState),
        isStudentOptInForDelegationForThisAccount(updatedState)
      );
      const profileHasDevices = !getProfileDevices(
        updatedState,
        profileId
      ).isEmpty();
      if (isDelegatedToThisAccount || profileHasDevices) {
        return dispatch(
          navigate({
            type: 'inner:replace',
            src: `/profiles/${profileId}/family-and-school`,
          })
        );
      }

      return dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${profileId}/school/summary`,
        })
      );
    }

    if (isAccountOverLimit(state)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: `/modal/OverLimitModal`,
            query: {
              profileId,
            },
          },
        })
      );
    } else if (isMagicDebugProfile(profile)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/debug/${profileId}`,
        })
      );
    } else if (!hasDeviceAssigned(state, profileId)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${profileId}/add-device`,
        })
      );
    }

    return Promise.resolve();
  };

export default ProfileNewContainer;
