import * as React from 'react';
import {
  Icon,
  IconFamily,
  IconType,
  List,
  SelectableListItem,
} from 'styleguide-react';
import Loader from '../../../../base/Loader';
import { RoutineMode, RoutineRuleType } from '../../../routines.types';

const ALLOWED = 'POLICY_ACTION_ALLOWED';
const BLOCKED = 'POLICY_ACTION_BLOCKED';

export const getIcon = <T,>(icon: T, showLoader: boolean) => {
  if (showLoader) return <Loader size="xxs" color="secondary" />;
  return <Icon type={icon} family={IconFamily.solid} />;
};

export const getRulesList =
  ({
    mode,
    ruleType,
    testId,
    onUpdateRule,
    showRuleLoader,
    isUpdatingRoutine,
  }: {
    mode: RoutineMode;
    ruleType: RoutineRuleType;
    isUpdatingRoutine: boolean;
    testId: string;
    onUpdateRule: () => void;
    showRuleLoader: (ruleType: RoutineRuleType) => boolean;
  }) =>
  (title: { allowed: string; blocked: string }) => {
    return (
      <List
        listItemMarginBottom="16"
        ariaRole="radiogroup"
        onClickItem={onUpdateRule}
        testId={testId}
      >
        <SelectableListItem
          id={ALLOWED}
          type="radio"
          selected={ruleType === ALLOWED}
          isDisabled={mode === 'EDIT' && isUpdatingRoutine}
          icon={getIcon(IconType.check, showRuleLoader(ALLOWED))}
          boldTitleOnSelected
          title={title.allowed}
        />
        <SelectableListItem
          id={BLOCKED}
          type="radio"
          selected={ruleType === BLOCKED}
          isDisabled={mode === 'EDIT' && isUpdatingRoutine}
          icon={getIcon(IconType.ban, showRuleLoader(BLOCKED))}
          boldTitleOnSelected
          title={title.blocked}
        />
      </List>
    );
  };
