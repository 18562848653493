import type {
  CalendarRestrictionDeletePauseAction,
  CalendarRestrictionPauseAction,
  CalendarRestrictionPauseSuccessAction,
  CalendarRestrictionPauseCreateSuccessAction,
} from './types';
import type {
  CalendarRestrictionPauseList,
  CalendarRestrictionPause,
} from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';

export const requestCalendarRestrictionPauseAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'REQUEST_CALENDAR_RESTRICTION_PAUSE',
  });

export const requestCalendarRestrictionPauseSuccessAction = (
  items: CalendarRestrictionPauseList,
  profileId: string
): CalendarRestrictionPauseSuccessAction => ({
  type: 'REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS',
  payload: { items, profileId },
});

export const requestCalendarRestrictionPauseErrorAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR',
  });

export const createRequestCalendarRestrictionPauseAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE',
  });

export const createRequestCalendarRestrictionPauseSuccessAction = (
  items: CalendarRestrictionPauseList
): CalendarRestrictionPauseCreateSuccessAction => ({
  type: 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS',
  payload: { items },
});

export const createRequestCalendarRestrictionPauseErrorAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR',
  });

export const deleteRequestCalendarRestrictionPauseAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE',
  });

export const deleteRequestCalendarRestrictionPauseSuccessAction = (
  calendarRestrictionUid: CalendarRestrictionPause['uid']
): CalendarRestrictionDeletePauseAction => ({
  type: 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS',
  payload: { calendarRestrictionUid },
});

export const deleteRequestCalendarRestrictionPauseErrorAction =
  (): CalendarRestrictionPauseAction => ({
    type: 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR',
  });
