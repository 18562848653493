/* eslint-disable camelcase */
import { Record } from 'immutable';

export const SCHEDULE_WEEKDAYS = [
  'MO',
  'TU',
  'WE',
  'TH',
  'FR',
  'SA',
  'SU',
] as const;

export interface ScheduleBase {
  uid: string;
  weekdays: Array<typeof SCHEDULE_WEEKDAYS[number]>;
  overrides: boolean;
}

export interface Schedule extends ScheduleBase {
  routineUid: string;
  /** HH:mm */
  startTime: string;
  durationMinutes: number;
  /** YYYY-MM-DD */
  fromDate: string;
  /** YYYY-MM-DD */
  toDate?: string;
}

export interface SchedulePayload extends ScheduleBase {
  routine_uid: string;
  start_time: string;
  duration_minutes: number;
  from_date: string;
  to_date?: string;
}

export type ScheduleCreatePayload = Omit<
  SchedulePayload,
  'uid' | 'routine_uid'
>;

export type ScheduleRecord = Record<Schedule>;
