import * as React from 'react';
import {
  None,
  isDefaultCase,
  withCase,
  withSwitch,
} from '../../../helpers/hocs';
import AndroidAndIosLabel, {
  isVisibleAndroidAndIosLabel,
} from './AndroidAndIosLabel';
import AndroidLabel, { isVisibleAndroidLabel } from './AndroidLabel';
import TrialDaysLeftLabel, {
  isVisibleTrialDaysLeftLabel,
} from './TrialDaysLeftLabel';
import UpgradeLabel, { isVisibleUpgradeLabel } from './UpgradeLabel';
import { shouldShowConversionElements } from '../../../records/license';
import type { LicenseRecord } from '../../../records/license';
import { ProfileRulesListType } from '../../../constants/profileRules';
import ProtectADeviceLabel, {
  isVisibleProtectADeviceLabel,
} from './ProtectADeviceLabel';
import { isHiddenLabelOnDelegationWithDesktopDevices } from './delegation';
import StartTrialLabel, { isVisibleStartTrialLabel } from './StartTrial';

const profileRulesLabel = withSwitch(
  withCase(isHiddenLabelOnDelegationWithDesktopDevices, None),
  withCase(isVisibleProtectADeviceLabel, ProtectADeviceLabel),
  withCase(isVisibleTrialDaysLeftLabel, TrialDaysLeftLabel),
  withCase(isVisibleStartTrialLabel, StartTrialLabel),
  withCase(isVisibleUpgradeLabel, UpgradeLabel),
  withCase(isVisibleAndroidAndIosLabel, AndroidAndIosLabel),
  withCase(isVisibleAndroidLabel, AndroidLabel),
  withCase(isDefaultCase, None)
);

export const getProfileRulesLabel =
  ({
    license,
    licenseDaysLeft,
    profileAndroidDevicesCount,
    profileIosDevicesCount,
    isDelegatedToThisAccount,
    totalDevicesCount,
    isDelegatedWithDesktopDevice,
    isValidForTrial,
    isAccountLinkedToSchool,
    isSchoolReferralLicense,
  }: {
    license: LicenseRecord;
    licenseDaysLeft: number;
    profileAndroidDevicesCount: number;
    profileIosDevicesCount: number;
    isDelegatedToThisAccount: boolean;
    totalDevicesCount: number;
    isDelegatedWithDesktopDevice: boolean;
    isValidForTrial: boolean;
    isAccountLinkedToSchool: boolean;
    isSchoolReferralLicense: boolean;
  }) =>
  (profileRule: ProfileRulesListType) => {
    const showConversion = shouldShowConversionElements(license);

    return (
      <React.Fragment>
        {profileRulesLabel({
          profileRule,
          license,
          licenseDaysLeft,
          showConversion,
          profileAndroidDevicesCount,
          profileIosDevicesCount,
          isDelegatedToThisAccount,
          totalDevicesCount,
          isDelegatedWithDesktopDevice,
          isValidForTrial,
          isAccountLinkedToSchool,
          isSchoolReferralLicense,
        })}
      </React.Fragment>
    );
  };
