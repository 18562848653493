import * as React from 'react';
import {
  Card,
  GlobalType,
  FlexLayout,
  FilterGroup,
  Filter,
  IconType,
  IconColor,
  Icon,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { exhaustiveCheck } from '../../helpers/validations';
import { SummaryDateRanges } from '../../constants';
import { classNames } from '../../helpers';
import type { MobileScreenSummaryCardProps } from '../../containers/ScreenTimeSummary/MobileScreenSummaryCardContainer';
import ScreenSummaryChartContainer from '../../containers/ScreenTimeSummary/ScreenSummaryChartContainer';
import TotalScreenTimeContainer from '../../containers/TotalScreenTimeContainer/TotalScreenTimeContainer';
import ScreenTimeDetailsContainer from '../../containers/ScreenTimeDetailsContainer/ScreenTimeDetailsContainer';

type TabType = 'total' | 'details' | 'byHour' | 'byDay';

const getTabLabel = (tab: TabType) => {
  switch (tab) {
    case 'total':
      return t('Total');
    case 'byDay':
      return t('By day');
    case 'byHour':
      return t('By hour');
    case 'details':
      return t('Details');
    default:
      return exhaustiveCheck(tab);
  }
};

const ChartSelectorTabs = ({
  tabs,
  selectedTab,
  onChange,
}: {
  tabs: Array<{
    id: string;
    label: string;
    isHidden?: boolean;
  }>;
  selectedTab: TabType;
  onChange?: (tab: TabType) => void;
}) => {
  const onChangeTab = (tab: string | number) => onChange?.(tab as TabType);

  return (
    <FilterGroup
      testId="par-chart-selector-tabs"
      active={selectedTab}
      onClick={onChangeTab}
      size="regular"
    >
      {tabs
        .filter(tab => !tab.isHidden)
        .map(tab => (
          <Filter key={tab.id} id={tab.id} text={tab.label} />
        ))}
    </FilterGroup>
  );
};

const MobileScreenSummaryCard = ({
  profile,
  height,
  width,
  activeDateRange,
  hasReportedRoutines,
  goToInfoModal,
}: MobileScreenSummaryCardProps) => {
  const [selectedTab, setSelectedTab] = React.useState<TabType>('total');

  React.useEffect(() => {
    setSelectedTab('total');
  }, [activeDateRange]);

  const todayOrCustomPastDay = [
    SummaryDateRanges.Today,
    SummaryDateRanges.CustomPastDay,
  ].includes(activeDateRange);

  const isTotalTabSelected = selectedTab === 'total';

  const renderTabs = () => {
    return (
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        marginBottom="40"
      >
        <ChartSelectorTabs
          selectedTab={selectedTab}
          onChange={t => setSelectedTab(t)}
          tabs={[
            { id: 'total', label: getTabLabel('total') },
            {
              id: 'details',
              label: getTabLabel('details'),
              isHidden: !(hasReportedRoutines && todayOrCustomPastDay),
            },
            {
              id: 'byHour',
              label: getTabLabel('byHour'),
              isHidden: !todayOrCustomPastDay,
            },
            {
              id: 'byDay',
              label: getTabLabel('byDay'),
              isHidden: todayOrCustomPastDay,
            },
          ]}
        />
      </FlexLayout>
    );
  };

  const renderCharts = () => {
    if (selectedTab === 'total')
      return <TotalScreenTimeContainer profile={profile} isMobileView />;
    if (selectedTab === 'details')
      return <ScreenTimeDetailsContainer profile={profile} />;
    return <ScreenSummaryChartContainer profile={profile} isMobileView />;
  };

  return (
    <Card
      height={height}
      width={width}
      className={classNames(
        'par-screen-time-summary-mobile-card',
        !isTotalTabSelected
          ? 'par-screen-time-summary-mobile-card--no-padding'
          : ''
      )}
      type={GlobalType.white}
      header={{
        title: t('Screen Time'),
        actionElement: (
          <Icon type={IconType.infoCircle} color={IconColor.secondary} />
        ),
        onClickAction: goToInfoModal,
        testId: 'par-mobile-screen-summary-card-link',
      }}
    >
      {renderTabs()}
      {renderCharts()}
    </Card>
  );
};

export default MobileScreenSummaryCard;
