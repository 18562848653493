import {
  FlexLayoutFlexBasisOptions,
  FlexLayoutFlexOptions,
  // eslint-disable-next-line import/no-unresolved
} from 'styleguide-react/build/components/Layout/types';

export const enum TabletMainSizes {
  small = '584',
}

export const ExpandedRowProps = {
  flexBasis: '100%' as FlexLayoutFlexBasisOptions,
  flexShrink: '0' as FlexLayoutFlexOptions,
  flexGrow: '0' as FlexLayoutFlexOptions,
};
