import { range } from 'ramda';
import {
  convertDate,
  formatHours,
  getDayOfWeekConverted,
  militaryTimeToAMPMFormat,
} from './dates';
import { pad } from './string';
import { CalendarViewWeekday } from '../components/CalendarView/types/CalendarView.types';
import { Datetime } from '../types/dates';

// keep this in sync with respective SASS variable value
export const ROW_HEIGHT = 32;

export const CALENDAR_VIEW_WEEKDAYS: CalendarViewWeekday[] = [
  'MO',
  'TU',
  'WE',
  'TH',
  'FR',
  'SA',
  'SU',
];

export const createTimeSeries = (): Record<
  /** HH:mm */
  string,
  { index: number; ampm: string; hhmm: string }
> => {
  const hours = range(0, 24);

  return hours.reduce((acc, index) => {
    const time = `${pad(index, 2)}:00`;
    const formattedTime = militaryTimeToAMPMFormat(time);

    return {
      ...acc,
      [time]: { ampm: formattedTime, hhmm: time, index },
    };
  }, {});
};

const hoursRangeTable = createTimeSeries();
export const calendarViewHoursRange = Object.values(hoursRangeTable);

export const getPositionInCalendar = (time: string, duration: number) => {
  const [startHour, startMinutes] = time.split(':');
  const cellIndex = hoursRangeTable[`${startHour}:00`].index;
  const minutesOffset = (Number(startMinutes) / 60) * ROW_HEIGHT;

  return {
    top: Math.round(ROW_HEIGHT * cellIndex + minutesOffset),
    height: Math.round((duration / 60) * ROW_HEIGHT),
  };
};

export const getNowIndicatorPosition = (
  currentTime: Datetime,
  timezone: string
): null | {
  weekday: CalendarViewWeekday;
  top: number;
} => {
  if (!currentTime) return null;
  const convertedTime = convertDate(currentTime, timezone);
  const weekdayIndex = getDayOfWeekConverted(convertedTime, timezone);
  const weekday = CALENDAR_VIEW_WEEKDAYS[weekdayIndex];
  const { top } = getPositionInCalendar(formatHours(convertedTime), 0);
  return { weekday, top };
};
