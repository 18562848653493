import { combineEpics, ofType } from 'redux-observable';
import { AppEpic } from '../../epics/types';
import type {
  UnifiedNowCardRefreshAction,
  UnifiedNowCardRequestAction,
} from './types';

import {
  catchError,
  combineLatest,
  filter,
  from,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { fetchProfile } from '../profiles';
import { mergeMapThunkActions } from '../../epics/operators';
import { fetchStudentPolicies } from '../studentPolicies';
import { fetchExtraTime } from '../extraTime';
import { fetchDevices } from '../devices';
import { fetchProfileRules } from '../profileRules';
import { fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork } from '../safeNetwork/thunks';
import { fetchCalendarRestrictionPause } from '../calendarRestrictionsPause';
import { updateStatusNowCardAction } from './actions';
import { profileHasBeenInSafeNetwork } from '../../selectors/safeNetwork';
import {
  RoutineDetailsReceiveAction,
  RoutineSchedulesReceiveAction,
  requestRoutineDetails,
  requestRoutineSchedules,
} from '../routines';
import { isProfileWithActiveRoutine } from '../../records/profile';
import { isRoutinesFeatureEnabled } from '../routines/selectors';

type RoutinesDataReceivedActions =
  | RoutineDetailsReceiveAction
  | RoutineSchedulesReceiveAction;

export const nowCardLoadEpic: AppEpic<
  | UnifiedNowCardRequestAction
  | RoutinesDataReceivedActions
  | UnifiedNowCardRefreshAction,
  any
> = (actions$, state$) => {
  const request = actions$.pipe<
    UnifiedNowCardRequestAction | UnifiedNowCardRefreshAction
  >(ofType('NOW_CARD_REQUEST', 'NOW_CARD_REFRESH'));
  const routinesDataReceived = actions$.pipe<RoutinesDataReceivedActions>(
    ofType('ROUTINE_DETAILS_RECEIVE', 'ROUTINE_SCHEDULES_RECEIVE')
  );

  const profileActions = request.pipe(
    mergeMapThunkActions(
      action => fetchProfile(String(action.payload.profileId)),
      state$
    ),
    shareReplay(1)
  );

  const profile = profileActions.pipe(
    ofType('RECEIVE_PROFILE'),
    map(action => {
      const id = action.payload.result;
      return action.payload.records.profiles[id];
    })
  );

  const devicesActions = request.pipe(
    mergeMapThunkActions(() => fetchDevices(), state$),
    shareReplay(1)
  );

  const extraTimeActions = profile.pipe(
    mergeMapThunkActions(
      profile => fetchExtraTime(String(profile.uid)),
      state$
    ),
    shareReplay(1)
  );

  const calendarRestrictionsPause = profile.pipe(
    mergeMapThunkActions(
      profile => fetchCalendarRestrictionPause(profile.uid, profile.id),
      state$
    ),
    shareReplay(1)
  );

  const profileRulesActions = profile.pipe(
    mergeMapThunkActions(
      profile => fetchProfileRules(profile.id, profile.deviceIds),
      state$
    ),
    shareReplay(1)
  );

  const studentPoliciesActions = profile.pipe(
    filter(profile => {
      return profile.isLinewizestudentLinked;
    }),
    mergeMapThunkActions(
      profile => fetchStudentPolicies(profile.id, true),
      state$
    ),
    shareReplay(1)
  );

  const profileSafeNetworksSettings = profile.pipe(
    mergeMapThunkActions(
      profile =>
        fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork(profile.id),
      state$
    ),
    shareReplay(1)
  );

  const activeRoutineActions = profile.pipe(
    filter(profile => profile?.activeRoutine != null),
    switchMap(profile =>
      from([
        requestRoutineDetails(profile.uid, profile.activeRoutine),
        requestRoutineSchedules(profile.uid, profile.activeRoutine),
      ])
    ),
    shareReplay(1)
  );

  const loadCardData = profile.pipe(
    switchMap(profile => {
      // TODO check if profile has been in SN and is linked to schools to determine what w should expect
      const requestsToBeReceived = [
        calendarRestrictionsPause.pipe(
          ofType('REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS')
        ),
        devicesActions.pipe(ofType('RECEIVE_DEVICES')),
        extraTimeActions.pipe(ofType('RECEIVE_EXTRATIME')),
        profileRulesActions.pipe(ofType('RECEIVE_PROFILE_RULES')),
      ];

      if (profile.isLinewizestudentLinked) {
        requestsToBeReceived.push(
          studentPoliciesActions.pipe(ofType('RECEIVE_STUDENT_POLICIES'))
        );
      }

      if (profileHasBeenInSafeNetwork(state$.value, profile.id)) {
        requestsToBeReceived.push(
          profileSafeNetworksSettings.pipe(
            ofType('RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS')
          )
        );
      }

      if (
        isRoutinesFeatureEnabled(state$.value) &&
        isProfileWithActiveRoutine(profile)
      ) {
        requestsToBeReceived.push(routinesDataReceived);
      }

      return combineLatest(requestsToBeReceived);
    }),
    map(() => {
      return updateStatusNowCardAction('LOADED');
    })
  );

  return merge(
    studentPoliciesActions,
    profileSafeNetworksSettings,
    activeRoutineActions,
    extraTimeActions,
    calendarRestrictionsPause,
    profileRulesActions,
    profileActions,
    devicesActions,
    loadCardData
  ).pipe(
    catchError(() => {
      return of(updateStatusNowCardAction('ERROR'));
    })
  );
};

const nowCardEpic = combineEpics(nowCardLoadEpic);

export default nowCardEpic;
