/* eslint-disable camelcase */
import { List } from 'immutable';
import { Moment } from 'moment';
import type { Profile } from '../../records/profile/types/Profile.types';
import type { Dispatch } from '../../store/state';
import type {
  CalendarRestrictionPauseAction,
  CalendarRestrictionDeletePauseAction,
} from './types';

import api from '../../api';
import {
  requestCalendarRestrictionPauseAction,
  requestCalendarRestrictionPauseSuccessAction,
  requestCalendarRestrictionPauseErrorAction,
  createRequestCalendarRestrictionPauseAction,
  createRequestCalendarRestrictionPauseSuccessAction,
  createRequestCalendarRestrictionPauseErrorAction,
  deleteRequestCalendarRestrictionPauseAction,
  deleteRequestCalendarRestrictionPauseSuccessAction,
  deleteRequestCalendarRestrictionPauseErrorAction,
} from './actions';
import { tapReject } from '../../helpers';
import { CalendarRestrictionPauseOperation } from '../../records/calendarRestrictionsPause/CalendarRestrictionPause';
import {
  CalendarRestrictionPausePayloadArray,
  CalendarRestrictionPauseList,
  CalendarRestrictionPause,
  CalendarRestrictionPausePayload,
} from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';
import { Account } from '../../records/account';
import { toCalendarRestrictionDateStringFormat } from '../../selectors/stateSelectors/calendarRestrictionsPause';
import { ttl3Seconds } from '../../lib/QApiCache/commonCacheStrategies';
import { CollectionResponse } from '../../types/api';

const toActionFormat = (response: {
  items_list: CalendarRestrictionPausePayloadArray;
}): CalendarRestrictionPauseList =>
  List<CalendarRestrictionPause>(
    response.items_list.map(
      (calendarRestriction: CalendarRestrictionPausePayload) =>
        CalendarRestrictionPauseOperation.fromPayload(calendarRestriction)
    )
  );

export const fetchCalendarRestrictionPause =
  (profileUid: Profile['uid'], profileId: string) =>
  (dispatch: Dispatch<CalendarRestrictionPauseAction>) => {
    dispatch(requestCalendarRestrictionPauseAction());

    return api.calendarRestrictions
      .withCache(ttl3Seconds)
      .get<CollectionResponse<CalendarRestrictionPausePayload>>({
        profileUid,
        filter: 'newest_today_pause_internet',
      })
      .then(response => {
        return dispatch(
          requestCalendarRestrictionPauseSuccessAction(
            toActionFormat(response),
            profileId
          )
        );
      })
      .catch(
        tapReject(() => dispatch(requestCalendarRestrictionPauseErrorAction()))
      );
  };

export const fetchCreateCalendarRestrictionPause =
  (
    dateStart: Moment,
    dateEnd: Moment,
    profileUid: Profile['uid'],
    accountUid: Account['uid']
  ) =>
  (dispatch: Dispatch<CalendarRestrictionPauseAction>) => {
    const start = toCalendarRestrictionDateStringFormat(dateStart);
    const end = toCalendarRestrictionDateStringFormat(dateEnd);

    dispatch(createRequestCalendarRestrictionPauseAction());

    const restriction = CalendarRestrictionPauseOperation.create({
      accountUid,
      profileUid,
      rrule: `DTSTART:${start}\nRRULE:FREQ=DAILY;COUNT=1;UNTIL=${end}`,
    });

    return api.calendarRestrictions
      .post(CalendarRestrictionPauseOperation.serialize(restriction), null, {
        accountUid,
        profileUid,
      })
      .then(response => {
        return dispatch(
          createRequestCalendarRestrictionPauseSuccessAction(
            List([CalendarRestrictionPauseOperation.fromPayload(response)])
          )
        );
      })
      .catch(
        tapReject(() =>
          dispatch(createRequestCalendarRestrictionPauseErrorAction())
        )
      );
  };

export const fetchDeleteCalendarRestrictionPause =
  (
    calendarRestrictionUid: CalendarRestrictionPause['uid'],
    profileUid: Profile['uid'],
    accountUid: Account['uid']
  ) =>
  (
    dispatch: Dispatch<
      CalendarRestrictionPauseAction | CalendarRestrictionDeletePauseAction
    >
  ) => {
    dispatch(deleteRequestCalendarRestrictionPauseAction());

    return api.calendarRestrictions
      .delete({
        calendarRestrictionUid,
        profileUid,
        accountUid,
      })
      .then(() => {
        return dispatch(
          deleteRequestCalendarRestrictionPauseSuccessAction(
            calendarRestrictionUid
          )
        );
      })
      .catch(
        tapReject(() =>
          dispatch(deleteRequestCalendarRestrictionPauseErrorAction())
        )
      );
  };
