import { Record, List } from 'immutable';
import { t } from '../lib/i18n';
import { formatCurrency, AccountRecord } from './account';
import { capitalize } from '../helpers/string';
import { LicenseSubtype } from './license/types/License.types';
import { isPremiumBasic, isPremiumComplete } from './license/helpers';
import { MICRO_TO_BASE } from '../constants';

export enum ProductSize {
  xs,
  small,
  medium,
  large,
  xl,
  xxl,
}

export enum ProductDuration {
  OneMonth = 30,
  OneYear = 365,
  TwoYears = 730,
}

type Product = {
  code: string;
  amount: number;
  amountWithDiscount: number;
  discount: number;
  currency: string;
  description: string;
  duration: number;
  maxDevices: number;
  maxProfiles: number;
  type: ProductSize;
  coupon: string;
  url: string;
  fullAmount: number;
  current: boolean;
  isDedicatedSupport: boolean;
  licenseSubtype: LicenseSubtype;
  storeProduct?: CdvPurchase.Product;
};

export type ProductRecord = Record<Product>;
export const ProductRecord = Record<Product, {}>({
  code: '-1',
  amount: 0,
  amountWithDiscount: 0,
  discount: 0,
  currency: 'EUR',
  description: '',
  duration: 0,
  maxDevices: 0,
  maxProfiles: 0,
  type: ProductSize.small,
  coupon: '',
  url: '',
  fullAmount: 0,
  current: false,
  isDedicatedSupport: false,
  licenseSubtype: 'premium_grandfather',
  storeProduct: undefined,
});

export const getProductType = (label: string): ProductSize => {
  const labelToType = new Map<string, ProductSize>([
    ['XS', ProductSize.xs],
    ['SMALL', ProductSize.small],
    ['MEDIUM', ProductSize.medium],
    ['LARGE', ProductSize.large],
    ['XL', ProductSize.xl],
    ['XXL', ProductSize.xxl],
  ]);
  const labelToTypeResult = labelToType.get(label);
  return labelToTypeResult !== undefined
    ? labelToTypeResult
    : ProductSize.small;
};

export const getProductTypeAsString = (
  type: ProductSize,
  subtype: LicenseSubtype
): string => {
  const typeToString = new Map<ProductSize, string>([
    [ProductSize.xs, 'xs'],
    [ProductSize.small, 'small'],
    [ProductSize.medium, 'medium'],
    [ProductSize.large, 'large'],
    [ProductSize.xl, 'xl'],
    [ProductSize.xxl, 'xxl'],
  ]);

  if (isPremiumBasic(subtype) || isPremiumComplete(subtype)) {
    return isPremiumBasic(subtype) ? 'basic' : 'complete';
  }

  const typeToStringResult = typeToString.get(type);
  return typeToStringResult !== undefined ? typeToStringResult : 'small';
};

export const getDisplayDuration = (product: ProductRecord) => {
  if (product.duration === ProductDuration.OneMonth) {
    return t('month');
  }
  const years = product.duration / 365;
  if (years > 1) {
    return t('{{years}} years', { years });
  }
  return t('year');
};

export const getPrice = (account: AccountRecord, product: ProductRecord) => {
  if (product.storeProduct?.pricing) {
    return formatCurrency(
      account.locale,
      product.storeProduct.pricing.currency,
      product.storeProduct.pricing.priceMicros / MICRO_TO_BASE
    );
  }
  return formatCurrency(
    account.locale,
    product.currency,
    product.amountWithDiscount ? product.amountWithDiscount : product.amount
  );
};

export const getPriceDS = (
  account: AccountRecord,
  product: ProductRecord,
  productDedicatedSupport: ProductRecord
) => {
  let amount;
  let amountDS;
  let currency;
  if (
    product.storeProduct?.pricing &&
    productDedicatedSupport.storeProduct?.pricing
  ) {
    amount = product.storeProduct.pricing.priceMicros / MICRO_TO_BASE;
    amountDS =
      productDedicatedSupport.storeProduct.pricing.priceMicros / MICRO_TO_BASE;
    currency = product.storeProduct.pricing.currency;
  } else {
    amount = product.amountWithDiscount
      ? product.amountWithDiscount
      : product.amount;
    amountDS = productDedicatedSupport.amountWithDiscount
      ? productDedicatedSupport.amountWithDiscount
      : productDedicatedSupport.amount;
    currency = product.currency;
  }
  return formatCurrency(account.locale, currency, amountDS - amount);
};

export const getSavePrice = (
  account: AccountRecord,
  product: ProductRecord
) => {
  return product.amountWithDiscount
    ? formatCurrency(
        account.locale,
        product.currency,
        product.amount - product.amountWithDiscount
      )
    : '';
};

export const getWasPrice = (account: AccountRecord, product: ProductRecord) => {
  return product.amountWithDiscount
    ? formatCurrency(account.locale, product.currency, product.amount)
    : '';
};

export const getPricePerMonth = (
  account: AccountRecord,
  product: ProductRecord,
  duration: ProductDuration = ProductDuration.OneYear
) => {
  const basePrice = product.amountWithDiscount
    ? product.amountWithDiscount
    : product.amount;
  return formatCurrency(
    account.locale,
    product.currency,
    basePrice / Math.floor(duration / 30)
  );
};

export const getUpsellFullPrice = (
  account: AccountRecord,
  product: ProductRecord
) => formatCurrency(account.locale, product.currency, product.fullAmount);

export const getAnnualPrices = (
  account: AccountRecord,
  products: List<ProductRecord>
) =>
  products.reduce((annualPrices, product) => {
    if (!product.current) {
      return annualPrices === ''
        ? `${getUpsellFullPrice(account, product)} ${t('for')} ${t(
            'Premium {{plan}}',
            {
              plan: capitalize(
                getProductTypeAsString(product.type, product.licenseSubtype)
              ),
            }
          )}`
        : `${annualPrices} ${t('and')} ${getUpsellFullPrice(
            account,
            product
          )} ${t('for')} ${t('Premium {{plan}}', {
            plan: capitalize(
              getProductTypeAsString(product.type, product.licenseSubtype)
            ),
          })}`;
    }
    return annualPrices;
  }, '');

export default ProductRecord;
