import { connect } from 'react-redux';
import ActivateRetrialModal from '../../components/Modal/ActivateRetrialModal';
import { goBackIfHistory } from '../../ducks/routing';
import { getLicenseDurationDays, getQueryParam } from '../../selectors';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State) => ({
  status: getQueryParam(state, 'status'),
  licenseDurationDays: getLicenseDurationDays(state) ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickLink: () => dispatch(goBackIfHistory()),
});

const ActivateRetrialModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateRetrialModal);

export default ActivateRetrialModalContainer;
