import * as React from 'react';
import { isPageInIframe } from '../../epics/contentCommunication/helpers';

/**
 * We want that if cookies consent has been accepted on the public site,
 * it should not be displayed in the parent app when rendered in an iframe,
 * but this needs to be done through the Cookiebot configuration,
 * which is quite complex. As a temporary solution,
 * we will hide the modal only on the direct purchase page.
 * It's important to note that this page can only be accessed from the public site,
 * and hiding the modal does not affect other parts.

 * As a strategy,
 * a hook in JavaScript adds a class par-direct-purchase-flow to the body when we are in an iframe,
 * and only if that class exists is the modal hidden.
*/
export const useEnsureHidePreconcertCookieModal = () => {
  React.useEffect(() => {
    if (!isPageInIframe()) {
      return;
    }

    const className = 'par-direct-purchase-flow';
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, []);
};
