import State from '../../store/state';
import {
  Addons,
  ProductAddonsOffer,
} from '../../records/productAddonsOffer/types';
import { Record } from 'immutable';

export const getProductAddonOffer = (
  state: State,
  addonType: Addons
): Record<ProductAddonsOffer> | undefined =>
  state.get('productAddonsOffer').addons.get(addonType);

export const getProductAddonOfferStatus = (state: State) =>
  state.get('productAddonsOffer').status;
