import * as React from 'react';
import {
  AlertBox,
  AlertBoxType,
  Icon,
  IconType,
  Layout,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineStepWrapper from '../RoutineStepWrapper';
import { isCreateRoutine, isEditRoutine } from '../../helpers/steps';
import { AppRuleType } from '../../routines.types';
import { AppsException } from './components/ExceptionsSelector';
import { ChooseRulesProps } from './types/ChooseRulesProps.types';
import useChooseRules from './useChooseRules';
import { PolicyApplication } from '../../../../records/routines/policy/types/PolicyApplication.types';
import useLastInteraction from '../../../../hooks/useLastInteraction';
import { getRulesList } from './components/common';

export enum ChooseAppRulesTest {
  prefix = 'routine-choose-app-rules-step',
}

const renderValidationAlert = (selectedAppRule: AppRuleType) => {
  if (selectedAppRule === 'DEFAULT') {
    return (
      <AlertBox
        type={AlertBoxType.error}
        rounded
        showInfoIcon
        fullWidth
        icon={<Icon type={IconType.exclamationCircle} />}
      >
        {t('Please choose app rules')}
      </AlertBox>
    );
  }
  return null;
};

const ChooseAppRules = ({
  prev,
  close,
  next,
  mode,
  isUpdatingRoutine,
  ruleType = 'DEFAULT',
  exceptions,
  onSelectRuleType,
  onRemoveExceptions,
  onAddExceptions,
}: ChooseRulesProps<PolicyApplication>) => {
  const { lastInteraction, withInteraction } = useLastInteraction<
    'selectRule' | 'exceptions'
  >();

  const {
    selectedRule: clickedRule,
    hasError,
    onSubmit,
    onUpdateRule,
  } = useChooseRules({
    next,
    ruleType,
    onSelectRuleType,
  });

  // In edit mode, we have to show a loader when
  // routine is being updated.
  const showRuleLoader = <T,>(ruleType: T) =>
    mode === 'EDIT' &&
    lastInteraction === 'selectRule' &&
    isUpdatingRoutine &&
    ruleType === clickedRule;

  const showExceptionLoader =
    mode === 'EDIT' && lastInteraction !== 'selectRule' && isUpdatingRoutine;

  const renderRulesList = getRulesList({
    mode,
    ruleType,
    isUpdatingRoutine,
    testId: `${ChooseAppRulesTest.prefix}-list`,
    onUpdateRule: withInteraction('selectRule', onUpdateRule),
    showRuleLoader,
  });

  return (
    <RoutineStepWrapper
      testId={ChooseAppRulesTest.prefix}
      prev={prev}
      close={close}
      next={onSubmit}
      fixedFooter
      footerText={isCreateRoutine(mode) ? t('Continue') : t('Done')}
      footerButtonDisabled={hasError}
      footerAlert={hasError && renderValidationAlert(ruleType)}
    >
      <Layout>
        <Typography type="h4" weight="semibold" className="par-util-break-word">
          {isCreateRoutine(mode) && t('Choose app rules')}
          {isEditRoutine(mode) && t('Edit app rules')}
        </Typography>
      </Layout>

      <Layout marginTop="40">
        {renderRulesList({
          allowed: t('Allow all apps'),
          blocked: t('Block all apps'),
        })}
      </Layout>

      <Layout marginTop="40">
        <AppsException
          showLoader={showExceptionLoader}
          disabled={ruleType === 'DEFAULT' || isUpdatingRoutine}
          testId={`${ChooseAppRulesTest.prefix}-app-exceptions`}
          mode={mode}
          activeTab="APP"
          ruleType={ruleType}
          exceptions={exceptions}
          onRemoveExceptions={withInteraction('exceptions', onRemoveExceptions)}
          onAddExceptions={withInteraction('exceptions', () =>
            onAddExceptions({ appRuleType: ruleType })
          )}
        />
      </Layout>
    </RoutineStepWrapper>
  );
};

export default ChooseAppRules;
