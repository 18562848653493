import * as React from 'react';
import * as R from 'ramda';
import { t } from '../../lib/i18n';
import { EventRecord } from '../../records';
import {
  ACTION_BLOCK,
  ACTION_ALERT,
  ACTION_ALLOW,
  REASON_DOMAIN_LIST_ALLOW,
  REASON_DOMAIN_LIST_BLOCK,
  REASON_DOMAIN_LIST_ALERT,
  REASON_CATEGORY_BLOCK,
  REASON_NOT_ALLOW_UNKNOWN_SITES,
  EventType,
} from '../../constants';
import { HUMAN_12CLOCK_FORMAT, convertDate } from '../../helpers/dates';
import { isSafetyNet } from '../../records/category';
import {
  EventCategoryType,
  isTypeAlert,
  isTypeAlertHighlighted,
} from './types';

interface ActivityTimelineItemTopProps {
  event: EventRecord;
  timezone: string;
  type: EventCategoryType;
}

const ActivityTimelineItemTop: React.FunctionComponent<
  ActivityTimelineItemTopProps
> = ({ event, timezone, type }: ActivityTimelineItemTopProps) => {
  const moment = convertDate(event.time, timezone);

  const { levelClass, appendLabel, prependLabel } = getLabels(event);

  const timeString = moment.format(HUMAN_12CLOCK_FORMAT);

  return (
    <div className={`ActivityTimelineItemTop ${levelClass}`}>
      <div>
        {[prependLabel, timeString, appendLabel ? `- ${appendLabel}` : null]
          .filter(R.identity)
          .join(' ')}
        {(isTypeAlert(type) || isTypeAlertHighlighted(type)) && (
          <span>
            {' - '}
            <span className="ActivityTimelineItemTop--search-label">
              {t('Search alert')}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

type Labels = {
  levelClass?: 'danger' | 'success' | 'warning';
  appendLabel?: string;
  prependLabel?: string;
  isTimeFromNowFormat?: boolean;
};
const getLabels = (event: EventRecord): Labels => {
  switch (event.type) {
    case EventType.CallsBlockedIncoming:
    case EventType.CallsBlockedOutgoing:
      return { levelClass: 'danger', appendLabel: t('Blocked call') };
    case EventType.SmsBlockedIncoming:
    case EventType.SmsBlockedOutgoing:
      return { levelClass: 'danger', appendLabel: t('Blocked SMS') };
    case EventType.Location:
      if (event.panicMode) {
        return { levelClass: 'danger', appendLabel: t('Location alert') };
      }
      return {};
    case EventType.LocationLast:
      return { appendLabel: t('Last location') };
    case EventType.Panic:
      if (event.panicMode) {
        return { levelClass: 'danger' };
      }
      return {};
    case EventType.Youtube:
      switch (event.action.action) {
        case ACTION_ALERT:
          if (event.action.reason === REASON_DOMAIN_LIST_ALERT) {
            return { levelClass: 'warning', appendLabel: t('Browsing alert') };
          }
          return { levelClass: 'warning', appendLabel: t('Alerted category') };
        case ACTION_BLOCK:
          return { levelClass: 'danger', appendLabel: t('Blocked') };
        default:
          return {};
      }
    case EventType.Web:
      switch (event.action.action) {
        case ACTION_ALLOW:
          if (event.action.reason === REASON_DOMAIN_LIST_ALLOW) {
            return { levelClass: 'success', appendLabel: t('Allowed website') };
          }
          return { levelClass: 'success', appendLabel: t('Always allowed') };
        case ACTION_BLOCK:
          if (event.action.reason === REASON_DOMAIN_LIST_BLOCK) {
            return { levelClass: 'danger', appendLabel: t('Blocked website') };
          }
          if (
            event.action.reason === REASON_CATEGORY_BLOCK ||
            event.action.reason === REASON_NOT_ALLOW_UNKNOWN_SITES
          ) {
            if (isSafetyNet(event.categories)) {
              return {
                levelClass: 'danger',
                appendLabel: t('Blocked by school'),
              };
            }
            return {
              levelClass: 'danger',
              appendLabel: t('Blocked category'),
            };
          }
          return {};
        case ACTION_ALERT:
          if (event.action.reason === REASON_DOMAIN_LIST_ALERT) {
            return { levelClass: 'warning', appendLabel: t('Browsing alert') };
          }
          return { levelClass: 'warning', appendLabel: t('Alerted category') };
        default:
          return {};
      }
    default:
      return {};
  }
};

export default ActivityTimelineItemTop;
