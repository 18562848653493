import * as React from 'react';
import {
  List as RegularStyleList,
  RegularStyleListItem,
  Switch,
  FlexLayout,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { SettingsToggleNames } from '../../constants';
import { FeatureCode } from '../../records/features/types';

export interface WebFiltersNotificationsProps {
  isReportBlockedSites: boolean;
  isReportBlockedSummaryAlerts: boolean;
  onToggleWebFilterSetting: (
    target: SettingsToggleNames,
    checked: boolean,
    needLicenseUpgrade: boolean
  ) => void;
  ruleNeedsUpgrade: (code: FeatureCode) => boolean;
  shouldHideRule: (code: FeatureCode) => boolean;
}

const WebFiltersNotifications: React.FunctionComponent<
  WebFiltersNotificationsProps
> = ({
  isReportBlockedSites,
  isReportBlockedSummaryAlerts,
  onToggleWebFilterSetting,
  ruleNeedsUpgrade,
  shouldHideRule,
}) => {
  const features = {
    blockSiteNotification: {
      needUpgrade: ruleNeedsUpgrade('web_blocked_alert'),
    },
    summaryAlerts: {
      needUpgrade: ruleNeedsUpgrade('threat_alerts_search'),
      hideRule: shouldHideRule('threat_alerts_search'),
    },
  };

  return (
    <FlexLayout mainaxis="column" marginTop="16" marginBottom="24">
      <RegularStyleList
        listItemMarginBottom="8"
        listItemPaddingTop="8"
        listItemPaddingBottom="8"
      >
        <RegularStyleListItem
          title={t('Blocked Site Notifications')}
          upperSubtitle={t(
            "When enabled, you'll receive an email alert when a blocked website is visited."
          )}
          actionElement={
            <Switch
              testId="blocked-site-notifications"
              checked={isReportBlockedSites}
              onClick={ev => {
                onToggleWebFilterSetting(
                  SettingsToggleNames.ReportBlockedSites,
                  (ev.target as HTMLInputElement).checked,
                  features.blockSiteNotification.needUpgrade
                );
              }}
            />
          }
        />
        {!features.summaryAlerts.hideRule && (
          <RegularStyleListItem
            title={t('Searches')}
            upperSubtitle={t(
              'Receive an alert when my child searches for concerning content.'
            )}
            actionElement={
              <Switch
                testId="searches"
                checked={isReportBlockedSummaryAlerts}
                onClick={ev => {
                  onToggleWebFilterSetting(
                    SettingsToggleNames.IsSearchAlertsNotificationsEnabled,
                    (ev.target as HTMLInputElement).checked,
                    features.summaryAlerts.needUpgrade
                  );
                }}
              />
            }
          />
        )}
      </RegularStyleList>
    </FlexLayout>
  );
};

export default WebFiltersNotifications;
