import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import DeleteTimeSlotModal from '../../../components/Routines/Modals/DeleteTimeSlotModal';
import {
  getActiveProfileUid,
  getQueryParam,
  isMilitaryTimeFormat,
} from '../../../selectors';
import {
  getRoutine,
  getRoutineSchedule,
} from '../../../ducks/routines/selectors';
import { deleteRoutineSchedule } from '../../../ducks/routines';
import {
  calculateTimeSlotFrom,
  calculateTimeSlotTo,
} from '../../../ducks/routines/transformations';
import { militaryTimeToAMPMFormatWithHoursAndMinutes } from '../../../helpers/dates';

const mapDispatchToProps = (dispatch: Dispatch) => {
  const back = () => dispatch(goBackIfHistory());
  return {
    onClickDelete: (
      profileUid: string,
      routineUid: string,
      scheduleUid: string,
      origin: string
    ) => {
      dispatch(
        deleteRoutineSchedule({ profileUid, routineUid, scheduleUid, origin })
      );
      back();
    },
    onClickClose: back,
  };
};

const formatTime = (state: State, time: string) => {
  return isMilitaryTimeFormat(state)
    ? time
    : militaryTimeToAMPMFormatWithHoursAndMinutes(time);
};

const mapStateToProps = (state: State) => {
  const profileUid = getActiveProfileUid(state);
  const routineUid = getQueryParam(state, 'routineUid');
  const timeSlotUid = getQueryParam(state, 'timeSlotUid');
  const timeSlot = getRoutineSchedule(state, routineUid!, timeSlotUid!);
  const origin = getQueryParam(state, 'origin');

  return {
    profileUid,
    routineUid,
    timeSlotUid,
    routineName: routineUid ? getRoutine(state, routineUid).name : '',
    from: formatTime(state, calculateTimeSlotFrom(timeSlot)),
    to: formatTime(state, calculateTimeSlotTo(timeSlot)),
    origin,
  };
};

const DeleteTimeSlotModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTimeSlotModal);

export type DeleteTimeSlotModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default DeleteTimeSlotModalContainer;
