import { fromJS, Record, Map } from 'immutable';
import * as R from 'ramda';
import { goBackIfHistory } from './routing';
import api from '../api';
import { doAutologinToPortal } from './autologin';
import { replaceVars } from '../helpers/string';
import { gaEvent } from '../helpers/ga';
import * as qinit from '../qinit';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

export type ModalRecord = {
  className: string | null;
  icon: string | null;
  headerText: string;
  buttonText: string;
  redirect: string | null;
  markdown: string;
  footerClass: string | null;
  tracking: Map<string, Map<string, string>>;
};
export const ModalRecord = Record<ModalRecord, {}>({
  className: null,
  icon: null,
  headerText: '',
  buttonText: 'ok',
  redirect: null,
  markdown: '',
  footerClass: null,
  tracking: Map(),
});

const replaceMarkdownVars = (dict: Map<string, string>) =>
  replaceVars(dict.toJS());

export const icon = R.prop('icon');

export const SET_MODAL = 'SET_MODAL';
export const SET_DICT = 'SET_DICT';

export default function notifications(
  state = fromJS({
    modal: ModalRecord(),
    notificationDictionary: null,
  }),
  action
) {
  switch (action.type) {
    case SET_MODAL:
      return state.set('modal', action.payload);
    case SET_DICT:
      return state.set(
        'notificationDictionary',
        Record(action.payload)(action.payload)
      );
    default:
      return state;
  }
}

export const setNotificationDictionary = dict => ({
  type: SET_DICT,
  payload: dict,
});

export const setModal = modal => ({ type: SET_MODAL, payload: modal });

export const fetchNotification = notificationKey => (dispatch, getState) => {
  const markdownVars = getState().getIn([
    'notifications',
    'notificationDictionary',
  ]);
  // @TODO use type qapi
  return (<any>api).notifications
    .get({ notificationKey })
    .then(R.pipe(toModal(markdownVars), setModal, dispatch));
};

export const toModal = (vars: Map<string, string> | null) => json =>
  ModalRecord({
    icon: json.icon_url,
    markdown:
      vars !== null ? replaceMarkdownVars(vars)(json.markup) : json.markup,
    buttonText: json.button.text,
    headerText: json.header_text,
    redirect:
      json.button.redirect_link === null ? null : json.button.redirect_link,
    footerClass: json.button.style,
    className: json.modal_type === null ? null : `modal--${json.modal_type}`,
    tracking: Map<string, Map<string, string>>({
      footer: Map(json.ga_track_footer),
      close: Map(json.ga_track_close),
      markdown: Map(json.ga_track_markup),
    }),
  });

export const redirect =
  (source: string) => (href: string) => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    track(source)(getState());

    if (href.indexOf('/') === 0) {
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: href,
        })
      );
    }
    if (href.indexOf(qinit.tenant.common.dashboard.home_link) === 0) {
      dispatch(goBackIfHistory());
      return dispatch(doAutologinToPortal(href));
    }
    dispatch(goBackIfHistory());
    return dispatch(
      navigate({
        type: 'external',
        src: href,
      })
    );
  };

export const close = () => (dispatch, getState) => {
  track('close')(getState());
  dispatch(goBackIfHistory());
};

const track = source => state => {
  const trackingArgs = state.getIn([
    'notifications',
    'modal',
    'tracking',
    source,
  ]);
  if (trackingArgs) {
    R.apply(
      gaEvent,
      R.props(['category', 'event', 'label'], trackingArgs.toJS())
    );
  }
};
