import * as React from 'react';
import { Moment } from 'moment';
import { Link } from 'react-router';
import {
  FlexLayout,
  GlobalType,
  InstructionBlock,
  StyledHeader,
  Tag,
} from 'styleguide-react';
import * as moment from 'moment';
import { t } from '../../lib/i18n';
import LicenseBadge from '../LicenseBadge/LicenseBadge';
import { AccountRecord, LicenseRecord } from '../../records';
import {
  isFree,
  licenseLinkTo,
  isAutoRenewal,
  isLicenseActionable,
  licenseDisplayName,
  getProperLicenseText,
} from '../../records/license';
import { commonStrings } from '../../helpers/string';
import { dayCountDiff, expiryDateShort } from '../../helpers/dates';

interface AccountSettingsLicenseProps {
  account: AccountRecord;
  license: LicenseRecord;
  currentTime: Moment;
  currentPage: string;
  onBadgeClick: (license: LicenseRecord) => () => any;
}

const AccountSettingsLicense: React.FunctionComponent<
  AccountSettingsLicenseProps
> = ({
  account,
  license,
  currentTime,
  currentPage,
  onBadgeClick,
}: AccountSettingsLicenseProps) => {
  const actionableLicense = isLicenseActionable(
    license,
    currentTime,
    account.timezone
  );

  const isSchoolFreeLicense = license.subtype === 'school_free';
  const licenseInfo = (
    <div>
      <InstructionBlock>{protectNDevicesString(license)}</InstructionBlock>
      <InstructionBlock>
        {licenseExpiration(account, license, currentTime)}
      </InstructionBlock>
    </div>
  );

  const schoolFreeInfo = (
    <div>
      <InstructionBlock>
        {t('Digital safety from school to home.')}
      </InstructionBlock>
    </div>
  );

  const conversionLicenseInfo = (
    <FlexLayout mainaxis="column">
      <Link
        to={
          actionableLicense
            ? licenseLinkTo(license, currentTime, currentPage, account.timezone)
            : ''
        }
      >
        {isSchoolFreeLicense ? schoolFreeInfo : licenseInfo}
        <FlexLayout mainaxis="row" marginTop="24">
          {actionableLicense && (
            <LicenseBadge
              license={license}
              showAction
              currentTime={currentTime}
              timezone={account.timezone}
            />
          )}
        </FlexLayout>
      </Link>
    </FlexLayout>
  );

  return (
    <FlexLayout mainaxis="column" marginBottom="16">
      <FlexLayout mainaxis="column" marginBottom="8">
        <StyledHeader type="h4">
          {licenseDisplayName(license) || commonStrings.nonBreakingSpace}
        </StyledHeader>
      </FlexLayout>
      <div
        className="AccountSettingsLicense__licenseInfo"
        onClick={actionableLicense ? onBadgeClick(license) : undefined}
      >
        {conversionLicenseInfo}
      </div>
    </FlexLayout>
  );
};

const protectNDevicesString = (license: LicenseRecord) => {
  if (license.maxDevices === 1) {
    return t('Protect 1 device');
  }

  return getProperLicenseText(
    license.subtype,
    t('Core protection for {{maxDevices}} devices', {
      maxDevices: license.maxDevices,
    }),
    t('Advanced safety for unlimited devices'),
    t('Protect up to {{maxDevices}} devices', {
      maxDevices: license.maxDevices,
    })
  );
};

const licenseExpiration = (
  account: AccountRecord,
  license: LicenseRecord,
  currentTime: Moment
) => {
  if (isFree(license.type)) {
    return t('Get more with Premium!');
  }

  if (
    !license.endDate ||
    dayCountDiff(moment(license.endDate), currentTime) <= 0
  ) {
    return null;
  }

  const expirationDate = expiryDateShort(account.locale, license.endDate);

  if (isAutoRenewal(license.renewal)) {
    return (
      <Tag
        text={t('Next billing date: {1}{{date}}{/1}', {
          date: expirationDate,
          1: x => x,
        })}
        type={GlobalType.secondary}
        size="large"
      />
    );
  }

  return (
    <Tag
      text={t('Expires on {{expirationDate}}', { expirationDate })}
      type={GlobalType.secondary}
      size="large"
    />
  );
};

export default AccountSettingsLicense;
