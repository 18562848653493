import * as React from 'react';
import * as moment from 'moment';
import IconKeyboardArrowDown from '../Icons/IconKeyboardArrowDown';
import { momentToLocaleDateString } from '../../helpers/dates';

const PastDateSelector: React.FunctionComponent<{
  numDays: number;
  currentDate?: string;
  selectedDate?: string | null;
  onChangeSelectedDate?: (newDate: string) => any;
}> = ({
  numDays,
  currentDate,
  selectedDate = null,
  onChangeSelectedDate = () => {},
}) => {
  const valueFormat = 'YYYY-MM-DD';

  const currentDateMoment =
    currentDate && moment(currentDate).isValid()
      ? moment(currentDate)
      : moment();

  const currentDateValue = currentDateMoment.format(valueFormat);

  const options = [
    <option key={currentDateValue} value={currentDateValue}>
      {momentToLocaleDateString(currentDateMoment)}
    </option>,

    ...Array(numDays)
      .fill(null)
      .map(() => {
        const dayMoment = currentDateMoment.subtract(1, 'days');
        const value = dayMoment.format(valueFormat);

        return (
          <option key={value} value={value}>
            {momentToLocaleDateString(dayMoment)}
          </option>
        );
      }),
  ].reverse();

  const selectedDateIsValidDate =
    selectedDate !== null && moment(selectedDate).isValid();

  return (
    <div className="PastDateSelector">
      <div className="PastDateSelector__custom-select">
        <span>
          {!selectedDateIsValidDate
            ? momentToLocaleDateString(currentDateMoment)
            : momentToLocaleDateString(moment(selectedDate))}
        </span>

        <IconKeyboardArrowDown />
      </div>

      <select
        className="PastDateSelector__original-select"
        defaultValue={currentDateValue}
        value={!selectedDateIsValidDate ? undefined : selectedDate!}
        onChange={evt => onChangeSelectedDate(evt.target.value)}
      >
        {options}
      </select>
    </div>
  );
};

export default PastDateSelector;
