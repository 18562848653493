import { connect } from 'react-redux';
import InsightsNotificationPage from '../../components/Insights/InsightsNotificationPage';
import {
  fetchNotifications,
  setFilterWithProfileUid,
  fetchMoreNotifications,
} from '../../ducks/notificationCenter';
import { NotificationType } from '../../constants';
import {
  getAccountLocale,
  hasMoreNotifications,
  isFetchingNotifications,
  getNotifications,
  hasNotifications,
} from '../../selectors';
import { Dispatch } from '../../store/state';

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  locale: getAccountLocale(state),
  isFetching: isFetchingNotifications(state),
  hasMore: hasMoreNotifications(state),
  isEmpty: !hasNotifications(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleMore: () => {
    dispatch(fetchMoreNotifications());
  },
});

const InsightsNotificationPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsNotificationPage);

InsightsNotificationPageContainer.load = () => dispatch => {
  dispatch(setFilterWithProfileUid(NotificationType.ApplicationUsageIncreased));
  dispatch(fetchNotifications());
};

export default InsightsNotificationPageContainer;
