import { FamilyLocatorScreen } from '../containers/FamilyLocator/types';

export const isPinMarkerScreen = (screen: FamilyLocatorScreen) =>
  [
    FamilyLocatorScreen.AddPlace,
    FamilyLocatorScreen.AddNewPlace,
    FamilyLocatorScreen.EditPlace,
    FamilyLocatorScreen.MaximizedMap,
  ].includes(screen);

export const isAddPlaceScreen = (screen: FamilyLocatorScreen) =>
  [
    FamilyLocatorScreen.AddPlace,
    FamilyLocatorScreen.AddNewPlace,
    FamilyLocatorScreen.EditPlace,
  ].includes(screen);
