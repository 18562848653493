/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Link } from 'react-router';
import {
  SideNavigationMenu,
  Icon,
  IconType,
  IconFamily,
  AccountInfoHeader,
  IconSize,
  Tag,
  GlobalType,
} from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { SideNavigationMenuItemType } from 'styleguide-react/build/components/SideNavigationMenu/SideNavigationMenu';
import { t } from '../../lib/i18n';
import { AccountRecord, LicenseRecord } from '../../records';
import { isAffiliate, isFreeOrTrial } from '../../records/license';
import SideBarLicenseBadge from './SideBarLicenseBadge';
import {
  BuildPlatform,
  PathTitles,
  LINK_DEVICES,
  LINK_ACCOUNT_SETTINGS,
  SideBarMode,
} from '../../constants';
import { isBrowserPlatform } from '../../helpers';
import { getBuildPlatform } from '../../helpers/globals';
import LogoIcon from '../../assets/base/images/icons/Logo.svg';
import LogoIconCompact from '../../assets/base/images/icons/Logo-compact.svg';
import { ScreenSize } from '../RenderWhen/RenderWhen';

// TODO give a less generic name to this type
export interface Props {
  screenSize?: ScreenSize;
  selectedPathTitle: string;
  account: AccountRecord;
  license: LicenseRecord;
  currentTime: any;
  disableDeviceManagement: boolean;
  disableFamilyManagement?: boolean;
  disableAccountManagement?: boolean;
  disableLicenseBadge?: boolean;
  isEnabledFamilyLocator: boolean;
  isEnabledWellbeing: boolean;
  shouldDisplayWellbeingNewTag: boolean;
  disableFamilyLocator: boolean;
  active: boolean;
  sideBarRef: any;
  showFamilyLocatorAccent: boolean;
  showGift: boolean;
  expand?: boolean;
  sideBarMode?: SideBarMode;
  licenseIsWithDedicatedSupport?: boolean;
  isAccountLinkedToSchool: boolean;
  onClose: () => any;
  onHelpCenterClick: () => any;
  onAccountClick: () => any;
  onDevicesClick: () => any;
  onFamilyLocatorClick: () => any;
  onWellbeingClick: () => any;
  onLogoutClick: () => any;
  onBadgeClick: (license) => any;
  onClickTryNow: () => any;
  onClickGift: () => any;
  onCarePlusContactClick: (locale) => any;
  onClickStartTrial: () => {};
}

const FixedSideBar: React.FunctionComponent<Props> = (props: Props) => {
  const {
    screenSize,
    selectedPathTitle,
    account,
    license,
    currentTime,
    disableDeviceManagement,
    disableFamilyManagement,
    disableAccountManagement,
    disableLicenseBadge,
    isEnabledFamilyLocator,
    isEnabledWellbeing,
    shouldDisplayWellbeingNewTag,
    showFamilyLocatorAccent,
    showGift,
    expand,
    sideBarMode,
    licenseIsWithDedicatedSupport,
    isAccountLinkedToSchool,
    onHelpCenterClick,
    onAccountClick,
    onDevicesClick,
    onLogoutClick,
    onBadgeClick,
    onClickTryNow,
    onFamilyLocatorClick,
    onWellbeingClick,
    onClickGift,
    onClose,
    onCarePlusContactClick,
    onClickStartTrial,
  } = props;
  const showAffiliateBadge = isAffiliate(license);

  const defaultOptions = [
    {
      key: PathTitles.yourFamily,
      title: t(PathTitles.yourFamily),
      icon: (
        <Icon
          type={IconType.home}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.yourFamily,
      useAsLink: Link,
      linkTo: '/',
      shouldShow: !disableFamilyManagement,
      onClick: onClose,
    },
    {
      key: PathTitles.yourDevices,
      title: t(PathTitles.yourDevices),
      icon: (
        <Icon
          type={IconType.phoneLaptop}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.yourDevices,
      useAsLink: Link,
      linkTo: LINK_DEVICES,
      shouldShow: !disableDeviceManagement,
      onClick: onDevicesClick,
    },
    {
      key: PathTitles.familyLocator,
      title: t(PathTitles.familyLocator),
      icon: (
        <Icon
          type={IconType.mapMarkerAlt}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.familyLocator,
      shouldShow: isEnabledFamilyLocator && !isBrowserPlatform(),
      onClick: onFamilyLocatorClick,
      showBadge: isFreeOrTrial(license.type),
      showAccent: showFamilyLocatorAccent,
    },
    {
      key: PathTitles.wellbeing,
      title: t(PathTitles.wellbeing),
      icon: (
        <Icon
          type={IconType.handHoldingHeard}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.wellbeing,
      shouldShow: isEnabledWellbeing,
      onClick: onWellbeingClick,
      badge: shouldDisplayWellbeingNewTag ? (
        <Tag
          testId="wellbeing-new"
          text={t('New!')}
          variant="rounded"
          type={GlobalType.warning}
          invertColor
          size="small"
        />
      ) : undefined,
    },
    {
      key: PathTitles.yourAccount,
      title: t(PathTitles.yourAccount),
      icon: (
        <Icon
          type={IconType.settings}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.yourAccount,
      shouldShow: !disableAccountManagement,
      onClick: onAccountClick,
      useAsLink: Link,
      linkTo: LINK_ACCOUNT_SETTINGS,
    },
  ] as SideNavigationMenuItemType[];

  const mobileTabletOptions = [
    {
      key: PathTitles.carePlus,
      title: t(PathTitles.carePlus),
      icon: (
        <Icon
          type={IconType.userHeadSet}
          family={IconFamily.solid}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.carePlus,
      shouldShow: licenseIsWithDedicatedSupport,
      onClick: () => onCarePlusContactClick(account.locale),
      useAsLink: Link,
      linkTo: LINK_ACCOUNT_SETTINGS,
    },
    {
      key: PathTitles.helpCenter,
      title: t(PathTitles.helpCenter),
      icon: (
        <Icon
          type={IconType.questionCircle}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.helpCenter,
      shouldShow: getBuildPlatform() !== BuildPlatform.ios,
      onClick: onHelpCenterClick,
      useAsLink: Link,
    },
    {
      key: PathTitles.logout,
      title: t(PathTitles.logout),
      icon: (
        <Icon
          type={IconType.signOut}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      shouldShow: true,
      onClick: onLogoutClick,
    },
  ] as SideNavigationMenuItemType[];

  const onboardingOptions = [
    {
      key: PathTitles.carePlus,
      title: t(PathTitles.carePlus),
      icon: (
        <Icon
          type={IconType.userHeadSet}
          family={IconFamily.solid}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.carePlus,
      shouldShow: licenseIsWithDedicatedSupport,
      onClick: () => onCarePlusContactClick(account.locale),
      useAsLink: Link,
      linkTo: LINK_ACCOUNT_SETTINGS,
    },
    {
      key: PathTitles.helpCenter,
      title: t(PathTitles.helpCenter),
      icon: (
        <Icon
          type={IconType.questionCircle}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      isActive: selectedPathTitle === PathTitles.helpCenter,
      shouldShow: getBuildPlatform() !== BuildPlatform.ios,
      onClick: onHelpCenterClick,
      useAsLink: Link,
    },
    {
      key: PathTitles.logout,
      title: t(PathTitles.logout),
      icon: (
        <Icon
          type={IconType.signOut}
          family={IconFamily.regular}
          size={IconSize.lg}
        />
      ),
      shouldShow: true,
      onClick: onLogoutClick,
    },
  ] as SideNavigationMenuItemType[];

  const isLinkedToSchool = (
    account?: AccountRecord,
    license?: LicenseRecord
  ) => {
    return (
      account && isAccountLinkedToSchool && license?.subtype === 'school_free'
    );
  };

  const getOptions = () => {
    if (sideBarMode === 'onboarding') {
      return onboardingOptions;
    }
    return screenSize === ScreenSize.Desktop
      ? defaultOptions
      : defaultOptions.concat(mobileTabletOptions);
  };

  const licenseInfo = () => {
    if (!disableLicenseBadge) {
      return (
        <SideBarLicenseBadge
          license={license}
          account={account}
          currentTime={currentTime}
          showAffiliateBadge={showAffiliateBadge}
          onBadgeClick={onBadgeClick}
          onClickTryNow={onClickTryNow}
          isLinkedToSchool={isLinkedToSchool(account, license)}
          onClickStartTrial={onClickStartTrial}
        />
      );
    }

    return undefined;
  };

  return (
    <React.Fragment>
      <SideNavigationMenu
        accountInfo={
          <AccountInfoHeader
            name={account.fullname}
            logoImage={
              <img
                src={
                  screenSize === ScreenSize.Desktop ? LogoIconCompact : LogoIcon
                }
                alt="logo"
              />
            }
            showGift={showGift}
            onClickGift={onClickGift}
            expand={expand}
          />
        }
        licenseInfo={licenseInfo()}
        expand={expand}
        items={getOptions()}
      />
    </React.Fragment>
  );
};

export default FixedSideBar;
