import { connect } from 'react-redux';
import { t } from '../lib/i18n';
import Spinner, { SpinnerProps } from '../components/Spinner/Spinner';
import { classNames, isBrowserPlatform } from '../helpers';

const mapStateToProps = state => {
  const isFetching =
    !isBrowserPlatform() && state.get('autologin').get('isFetching');
  const text = isFetching
    ? t('Now taking you to your desktop {{portalName}}')
    : null;

  return {
    show: isFetching || state.get('app').get('isLoadingInitialData'),
    text,
    className: classNames(isFetching ? 'autologinSpinner' : null),
  } as SpinnerProps;
};

const SpinnerContainer = connect(mapStateToProps)(Spinner);

export default SpinnerContainer;
