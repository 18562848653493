import { Record, List } from 'immutable';
import * as Moment from 'moment';
import { Platform, NotificationType, PLATFORM_UNKNOWN } from '../constants';
import { mapPlatform } from './device';
import { API_TIMESTAMP_FORMAT } from '../helpers/dates';
import { replaceS3Url } from '../helpers';

type NotificationDataUsageIncreased = {
  fileIconPath: string;
  name: string;
  usage: number;
  usageIncrement: number;
  usageAverage: number;
  usageIncrementPc: number;
  usageIncrementRatio: number;
  platform: Platform;
  daysSinceEpoc: number;
};
export type NotificationDataUsageIncreasedRecord =
  Record<NotificationDataUsageIncreased>;
// in the future | Notification... | Notification...
export type NotificationData = NotificationDataUsageIncreasedRecord;

export const NotificationDataUsageIncreasedRecord = Record<
  NotificationDataUsageIncreased,
  {
    fromPayload: Record.Parser<any, NotificationDataUsageIncreasedRecord>;
  }
>({
  fileIconPath: '',
  name: '',
  usage: 0,
  usageIncrement: 0,
  usageAverage: 0,
  usageIncrementPc: 0,
  usageIncrementRatio: 0,
  platform: PLATFORM_UNKNOWN,
  daysSinceEpoc: 0,
});

NotificationDataUsageIncreasedRecord.fromPayload = payload =>
  NotificationDataUsageIncreasedRecord({
    fileIconPath: replaceS3Url(payload.app_icon),
    name: payload.app_name,
    usage: payload.app_usage_current,
    usageIncrement: payload.app_usage_increment_minutes,
    usageAverage: payload.app_usage_average,
    usageIncrementPc: payload.app_usage_increment_percentage,
    usageIncrementRatio: payload.app_usage_increment_ratio,
    platform: mapPlatform(payload.device_platform),
    daysSinceEpoc: payload.days_since_epoc,
  });

const parseNotificationData = (
  type: NotificationType,
  payload: any
): NotificationData | undefined => {
  if (!payload) return undefined;
  switch (type) {
    case NotificationType.ApplicationUsageIncreased:
      return NotificationDataUsageIncreasedRecord.fromPayload(payload);
    default:
      return undefined;
  }
};

export type Notification = {
  uid: string;
  type: NotificationType;
  accountId: string;
  profileId?: string;
  deviceId?: string;
  data?: NotificationData;
  version: number;
  viewedAt?: Moment.Moment;
  createdAt: Moment.Moment;
};

export type NotificationRecord = Record<Notification>;
export const NotificationRecord = Record<
  Notification,
  {
    fromPayload: Record.Parser<any, NotificationRecord>;
  }
>({
  uid: '',
  type: NotificationType.Unknown,
  accountId: '',
  profileId: '',
  deviceId: '',
  data: undefined,
  version: 0,
  viewedAt: undefined,
  createdAt: Moment(),
});

NotificationRecord.fromPayload = payload =>
  NotificationRecord({
    uid: payload.uid,
    type: payload.notification_type,
    accountId: payload.account,
    profileId: payload.profile,
    deviceId: payload.device,
    data: parseNotificationData(
      payload.notification_type,
      payload.notification_data
    ),
    version: payload.version,
    viewedAt: payload.viewed_at
      ? Moment(payload.viewed_at, API_TIMESTAMP_FORMAT)
      : undefined,
    createdAt: Moment(payload.created_at, API_TIMESTAMP_FORMAT),
  });

type Notifications = {
  items: List<NotificationsRecord>;
  count: number;
};
export type NotificationsRecord = Record<Notifications>;
export const NotificationsRecord = Record<
  Notifications,
  {
    fromPayload: Record.Parser<any, NotificationsRecord>;
  }
>({
  items: List<NotificationsRecord>(),
  count: 0,
});

NotificationsRecord.fromPayload = payload =>
  NotificationsRecord({
    items: List<NotificationsRecord>(
      (payload || []).items_list.map(item =>
        NotificationRecord.fromPayload(item)
      )
    ),
    count: payload.total_count,
  });

export type NotificationFilter = {
  profileUid?: string;
  type?: NotificationType;
};

export const matchNotificationFilter = (
  filter: NotificationFilter,
  filterToMatch: NotificationFilter
) =>
  filter.profileUid === filterToMatch.profileUid &&
  filter.type === filterToMatch.type;

export const fromFilterToQapiParams = (
  filter: NotificationFilter,
  offset?: number
) => ({
  notification_type: filter.type,
  profile__uid: filter.profileUid,
  offset,
});
