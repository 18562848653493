import {
  RULE_CALLS_AND_SMS,
  RULE_DAILY_TIME_LIMITS,
  RULE_PANIC_BUTTON,
  RULE_RESTRICTED_TIMES,
  RULE_ROUTINES,
} from '../../constants';
import ft, {
  DISABLE_CALLS_SMS_RULES,
  DISABLE_PANIC_BUTTON_RULES,
  DISABLE_TIME_LIMITS,
} from '../../lib/ft';
import { ProfileRulesListType } from '../../constants/profileRules';
import { LicenseSubtype } from '../../records/license/types/License.types';
import { ensureRoutinesFeatureEnabled } from '../routines/routines';

/**
 * Function to remove profile rules acording to the feature flags
 *
 * @param rules Array of profile rules to filter through the feature flags
 * @param accountHasRoutines
 * @param licenseSubtype
 * @returns Array of rules filtered through the feature flags
 */
export const flagFilterProfileRules = (
  rules: ProfileRulesListType[],
  accountHasRoutines: boolean,
  licenseSubtype: LicenseSubtype
) => {
  // Apply filters chain
  const filteredRules = rules
    .filter(qinitFlagFilterProfileRulesFilter)
    .filter(
      routineAndRestrictedTimeRulesFilter(accountHasRoutines, licenseSubtype)
    );

  return filteredRules;
};

// filter rules for "Routines" and "Restricted times"
const routineAndRestrictedTimeRulesFilter = (
  accountHasRoutines: boolean,
  licenseSubtype: LicenseSubtype
) => {
  const shouldShowRoutines = ensureRoutinesFeatureEnabled(
    accountHasRoutines,
    licenseSubtype
  );
  const shouldShowRestrictedTimes = !shouldShowRoutines;

  return (rule: ProfileRulesListType) => {
    // We only care about “Routines” and “Restricted times” for this filter
    if (rule === RULE_ROUTINES || rule === RULE_RESTRICTED_TIMES) {
      if (rule === RULE_ROUTINES) return shouldShowRoutines;
      if (rule === RULE_RESTRICTED_TIMES) return shouldShowRestrictedTimes;
    }

    return true;
  };
};

// filter rules for qInit flags
const qinitFlagFilterProfileRulesFilter = (rule: ProfileRulesListType) => {
  // when "disable_time_limits" flag is enabled
  // hide "dailyTimeLimits" and "restrictedTimes"
  if (rule === RULE_DAILY_TIME_LIMITS || rule === RULE_RESTRICTED_TIMES) {
    if (ft.active(DISABLE_TIME_LIMITS)) return false;
  }

  // when "disable_calls_sms_rules" flag is enabled
  // hide "callsAndSms"
  if (rule === RULE_CALLS_AND_SMS) {
    if (ft.active(DISABLE_CALLS_SMS_RULES)) return false;
  }

  // when "disable_panic_button_rules" flag is enabled
  // hide "panicButton"
  if (rule === RULE_PANIC_BUTTON) {
    if (ft.active(DISABLE_PANIC_BUTTON_RULES)) return false;
  }

  return true;
};
