import * as React from 'react';
import { classNames } from '../../helpers';

interface LoadingPlaceholderProps {
  animated?: boolean;
  testId?: string;
}

const LoadingPlaceholder = ({
  animated = false,
  testId,
}: LoadingPlaceholderProps) => {
  return (
    <div
      className={classNames(
        'par-loading-placeholder',
        animated && 'par-loading-placeholder--animated'
      )}
      data-testid={`Loading-placeholder__${testId}`}
    />
  );
};

export default LoadingPlaceholder;
