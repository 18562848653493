import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  getProfileRules,
  isMultiDevices,
  getMultiDeviceTimeRestrictions,
} from '../../../selectors';
import {
  getDailyTimeLimitsCheckedDay,
  getToday,
} from '../../../selectors/shared';
import DailyTimeLimit from '../../../components/TimeLimits/DailyTimeLimit';
import { ProfileRulesRecord } from '../../../records';
import { showErrorToast } from '../../AccountSettings/helpers';
import {
  updateCheckedDay,
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
  delayedModifyProfileRules,
  delayedModifyTimeLimits,
  reverseTimeLimitsUpdate,
} from '../../../ducks/profileRules';
import { UPDATE_PROFILE_RULES_DELAY } from '../../../constants';
import {
  trackTimeRulesChange,
  TimeLimitsRuleNames,
} from '../../../helpers/analytics';
import * as appRateDuck from '../../../ducks/appRate';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';

const { RuleType, setLastRuleTypeModified } = appRateDuck;

const mapStateToProps = (state, { params: { profileId, deviceId } }) => ({
  profileId,
  deviceId,
  timeSettings: isMultiDevices(state, profileId)
    ? R.defaultTo(
        ProfileRulesRecord().timeRestrictions,
        getMultiDeviceTimeRestrictions(state, deviceId)
      )
    : R.defaultTo(ProfileRulesRecord(), getProfileRules(state, profileId))
        .timeRestrictions,
  checkedDay: getDailyTimeLimitsCheckedDay(state),
});

const mapDispatchToProps = (dispatch, { params: { profileId, deviceId } }) => ({
  onClickDay: day => {
    dispatch(updateCheckedDay(day));
  },
  onClickSettings: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `profiles/${profileId}/rules/dailyTimeLimit/devices/${deviceId}/timeSettings`,
      })
    );
  },
  onChangeMinutes: day => minutes => {
    dispatch(onChangeMinutes(profileId, deviceId, day, minutes));
  },
  onChangeDevice: device => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: `/profiles/${profileId}/rules/dailyTimeLimit/devices/${device.id}`,
      })
    );
  },
  onClickMoreInfo: deviceId => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/dailyTimeLimit/devices/${deviceId}/modal/ScreenTimeModal`,
      })
    );
  },
});

const onChangeMinutes =
  (profileId, deviceId, day, minutes) => (dispatch, getState) =>
    dispatch(
      isMultiDevices(getState(), profileId)
        ? changeTimeLimits(profileId, deviceId, day, minutes)
        : changeProfileRules(profileId, day, minutes)
    );

const changeProfileRules =
  (profileId, day, minutes) => (dispatch, getState) => {
    dispatch(setLastRuleTypeModified(RuleType.DailyTimeLimits));

    return Promise.resolve(getProfileRules(getState(), profileId))
      .then(profileRules =>
        profileRules.update('timeRestrictions', setQuotas(day, minutes))
      )
      .then(profileRules => {
        trackTimeRulesChange(TimeLimitsRuleNames.TIME_LIMITS_CHANGE);
        return dispatch(
          delayedModifyProfileRules(
            [profileRules, profileId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseProfileRulesUpdate());
        dispatch(showErrorToast(error));
      });
  };

const changeTimeLimits =
  (profileId, deviceId, day, minutes) => (dispatch, getState) =>
    Promise.resolve(getMultiDeviceTimeRestrictions(getState(), deviceId))
      .then(setQuotas(day, minutes))
      .then(timeRestrictions => {
        trackTimeRulesChange(TimeLimitsRuleNames.TIME_LIMITS_CHANGE);
        dispatch(
          delayedModifyTimeLimits(
            [timeRestrictions, profileId, deviceId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseTimeLimitsUpdate(profileId, deviceId));
        dispatch(showErrorToast(error));
      });

const setQuotas = R.curry((day, minutes, timeRestrictionsRecord) =>
  timeRestrictionsRecord.setIn(['quotas', day], minutes)
);

const DailyTimeLimitContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyTimeLimit);

DailyTimeLimitContainer.load = () => (dispatch, getState) => {
  if (
    getState().getIn(['routing', 'locationBeforeTransitions']).action === 'PUSH'
  ) {
    return dispatch(updateCheckedDay(getToday(getState())));
  }
  return undefined;
};

export default DailyTimeLimitContainer;
