import {
  CREATE_INVITATION_STARTED,
  CREATE_INVITATION_SUCCEED,
  CREATE_INVITATION_FAILED,
  REMOVE_INVITATION_STARTED,
  REMOVE_INVITATION_SUCCEED,
  REMOVE_INVITATION_FAILED,
  FETCH_INVITATIONS_STARTED,
  FETCH_INVITATIONS_SUCCEED,
  FETCH_INVITATIONS_FAILED,
} from './invitationsActions';

interface State {
  isFetching: boolean;
  isFetchingFailed: boolean;
  hasFetchedSuccessfullyAtleastOnce: boolean;
  isCreating: boolean;
  isRemoving: boolean;
  invitations: {
    uid: string;
    status: 'pending' | 'expired' | 'accepted';
    invitationEmail: string;
    createdAt: string;
    expiresAt: string;
    invitedAccessProfile: {
      email: string;
      name: string;
      uid: string;
    } | null;
  }[];
}

export default function invitations(
  state: State = {
    isFetching: true,
    isFetchingFailed: false,
    hasFetchedSuccessfullyAtleastOnce: false,
    isCreating: false,
    isRemoving: false,
    invitations: [],
  },
  action
): State {
  switch (action.type) {
    case CREATE_INVITATION_STARTED: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case CREATE_INVITATION_SUCCEED: {
      return {
        ...state,
        isCreating: false,
        invitations: [action.payload, ...state.invitations],
      };
    }
    case CREATE_INVITATION_FAILED: {
      return {
        ...state,
        isCreating: false,
      };
    }
    case REMOVE_INVITATION_STARTED: {
      return {
        ...state,
        isRemoving: true,
      };
    }
    case REMOVE_INVITATION_SUCCEED: {
      return {
        ...state,
        invitations: state.invitations.filter(
          inv => inv.uid !== action.payload
        ),
        isRemoving: false,
      };
    }
    case REMOVE_INVITATION_FAILED: {
      return {
        ...state,
        isRemoving: false,
      };
    }
    case FETCH_INVITATIONS_STARTED: {
      return {
        ...state,
        isFetching: true,
        isFetchingFailed: false,
      };
    }
    case FETCH_INVITATIONS_SUCCEED: {
      return {
        ...state,
        isFetching: false,
        invitations: action.payload,
        hasFetchedSuccessfullyAtleastOnce: true,
      };
    }
    case FETCH_INVITATIONS_FAILED: {
      return {
        ...state,
        isFetching: false,
        isFetchingFailed: true,
      };
    }
    default:
      return state;
  }
}
