import * as React from 'react';
import ProfileListVariants from './components/ProfileList/ProfileListVariants';
import type { FeatureFlagsExperimentsKey } from '../ducks/featureFlags';
import { getFeatureFlag } from '../selectors/featureFlags';
import { Experiment } from '../sideEffects/flags';
import State from '../store/state';

export interface ExperimentInterface {
  compliesWithPrerequisites: (
    state: State,
    experimentName: FeatureFlagsExperimentsKey
  ) => boolean;
  getComponentToRender: (
    componentId: string,
    variant: Experiment
  ) => React.FunctionComponent<any>;
}

const Experiments = {
  kidDeviceSetupFromParentsApp: ProfileListVariants,
};

export const getExperiment = (
  experimentName
): ExperimentInterface | undefined => Experiments[experimentName];

export const getComponentToRender =
  state =>
  (experimentName: FeatureFlagsExperimentsKey, componentId: string) => {
    const experiment = getExperiment(experimentName);
    if (!experiment) {
      return;
    }

    if (!experiment.compliesWithPrerequisites(state, experimentName)) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return experiment.getComponentToRender(
      componentId,
      getFeatureFlag(state, experimentName) as Experiment
    );
  };
