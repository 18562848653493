import * as React from 'react';
import { connect } from 'react-redux';
import { FlexLayout, StyledHeader } from 'styleguide-react';
import { toggleLockScreen } from '../../actions/LockScreenActions';
import { SettingsToggleNames } from '../../constants';
import { updateParentDeviceLockScreenStatus } from '../../ducks/parentDevice';
import { isBrowserPlatform } from '../../helpers';
import { trackAccountSettingUpdate } from '../../helpers/analytics';
import { t } from '../../lib/i18n';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { isLockScreenEnabled } from '../../selectors/lockState';
import State, { Dispatch } from '../../store/state';
import flags from '../../sideEffects/flags';
import {
  getAllDevices,
  getProfilesWithDeviceAssigned,
  isSlaveAccount,
} from '../../selectors';
import CloseAdditionalParentAccount from './CloseAdditionalParentAccount';
import LockScreenSettings from './LockScreenSettings';
import SafeNetworksSettingsContainer from '../../containers/AccountSettings/SafeNetworksSettingsContainer';
import { getAccountSafeNetworkSettingsHasError } from '../../selectors/safeNetworksSettings';
import { accountHasBeenInSafeNetwork } from '../../businessLogic/safeNetwork/safeNetwork';

const AccountSettingsExtra: React.FunctionComponent<
  AccountSettingsExtraProps
> = ({
  onToggle,
  lockScreen,
  multiParentInitiative,
  safeNetworkSettings,
}: AccountSettingsExtraProps) => {
  const settingOptions: {
    key: string;
    order: number;
    Component: JSX.Element;
  }[] = [];

  if (!isBrowserPlatform()) {
    if (lockScreen.shouldDisplayFeature) {
      settingOptions.push({
        order: 0,
        key: 'LockScreenSettings',
        Component: (
          <LockScreenSettings onToggle={onToggle} lockScreen={lockScreen} />
        ),
      });
    }
  }

  if (multiParentInitiative.shouldDisplayFeature) {
    settingOptions.push({
      order: 1,
      key: 'CloseAdditionalParentAccount',
      Component: <CloseAdditionalParentAccount />,
    });
  }

  if (safeNetworkSettings.shouldDisplayFeature) {
    settingOptions.push({
      order: 2,
      key: 'SafeSchoolNetworksSettings',
      Component: <SafeNetworksSettingsContainer />,
    });
  }

  if (settingOptions.length) {
    settingOptions.sort((a, b) => a.order - b.order);
  }

  return (
    <React.Fragment>
      {settingOptions.length ? (
        <FlexLayout mainaxis="column" marginTop="32" marginBottom="32">
          <StyledHeader type="h3">{t('Settings')}</StyledHeader>
          {settingOptions.map(({ Component, key }) => (
            <FlexLayout key={key} mainaxis="column" marginTop="24">
              {Component}
            </FlexLayout>
          ))}
        </FlexLayout>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({
  lockScreen: {
    shouldDisplayFeature: getFeatureFlag(state, 'lockScreen') as boolean,
    enabled: isLockScreenEnabled(state),
  },
  multiParentInitiative: {
    shouldDisplayFeature:
      flags.multiParentInitiative.isEnabled() && isSlaveAccount(state),
  },
  safeNetworkSettings: {
    shouldDisplayFeature:
      getProfilesWithDeviceAssigned(state).size > 0 &&
      !getAccountSafeNetworkSettingsHasError(state) &&
      flags.safeNetworkSettings.isEnabled() &&
      accountHasBeenInSafeNetwork(getAllDevices(state).toJS()),
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onToggle: (toggleTarget: SettingsToggleNames, toggleOn: boolean) => {
    trackAccountSettingUpdate(toggleTarget, toggleOn);
    if (toggleTarget === SettingsToggleNames.IsLockScreenEnabled) {
      dispatch(toggleLockScreen(toggleOn));
      dispatch(updateParentDeviceLockScreenStatus(toggleOn));
    }
  },
});

export type AccountSettingsExtraProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsExtra);
