import po from '../../po'; // eslint-disable-line
import { getBrowserLanguage } from '../../sideEffects/browser/core';

export const getDefaultLocale = () => {
  const language = getBrowserLanguage();

  if (language === 'zh-cn' || language === 'zh') {
    return 'zh-Hans';
  }

  const locale = language.substr(0, 2);

  if (locale === 'pt') {
    return 'pt_BR';
  }

  return typeof po[locale] === 'undefined' ? 'en' : locale;
};

export const translate = (str, locale) => {
  if (po[locale] && typeof po[locale][str] === 'string') {
    return po[locale][str];
  }
  return str;
};
