import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  combineLatest,
  from,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import type { AppEpic } from '../../../epics/types';
import type {
  RoutineStatusAction,
  UpdateRoutineScheduleAction,
  DeleteRoutineScheduleAction,
  SetRoutineMachineAction,
} from '../types';
import {
  resetRoutineMachineAction,
  setRoutineMachineAction,
  setScheduleDetailsStatus,
} from '../actions';
import { APIError } from '../../../lib/errors';
import { showErrorAlert } from '../../../helpers/errorHandling';
import { SchedulePayload } from '../../../records/routines/schedule/types/Schedule.types';
import { ScheduleOperations } from '../../../records/routines/schedule/schedule';
import {
  deleteRoutinesSchedulesRecord,
  updateRoutinesSchedulesRecord,
} from '../../records';
import { Action } from 'redux';

export const updateRoutineScheduleEpic: AppEpic<
  UpdateRoutineScheduleAction,
  RoutineStatusAction | ReturnType<typeof updateRoutinesSchedulesRecord>
> = (actions$, _state$, { api }) => {
  const updateActions = actions$.pipe(
    ofType('ROUTINE_SCHEDULE_DETAILS_UPDATE')
  );

  const loadingActions = updateActions.pipe(
    map(() => setScheduleDetailsStatus('update', 'loading'))
  );

  const requestActions = updateActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(
          ({ payload: { profileUid, routineUid, scheduleUid, body } }) =>
            combineLatest([
              of({ routineUid, scheduleUid }),
              api.routinesSchedulesDetails.patch<SchedulePayload>(
                {
                  profileUid,
                  routineUid,
                  scheduleUid,
                },
                body,
                null
              ),
            ])
        ),
        mergeMap(([{ routineUid, scheduleUid }, response]) =>
          from([
            setScheduleDetailsStatus('update', 'success'),
            updateRoutinesSchedulesRecord(
              ScheduleOperations.fromPayload({
                ...response,
                routine_uid: routineUid,
              }),
              scheduleUid
            ),
          ])
        ),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setScheduleDetailsStatus('update', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

export const deleteRoutineScheduleEpic: AppEpic<
  DeleteRoutineScheduleAction,
  | RoutineStatusAction
  | SetRoutineMachineAction
  | ReturnType<typeof deleteRoutinesSchedulesRecord>
  | Action
> = (actions$, _state$, { api }) => {
  const deleteActions = actions$.pipe(
    ofType('ROUTINE_SCHEDULE_DETAILS_DELETE')
  );

  const loadingActions = deleteActions.pipe(
    map(() => setScheduleDetailsStatus('delete', 'loading'))
  );

  const requestActions = deleteActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(
          ({
            payload: { profileUid, routineUid, scheduleUid, origin, onSuccess },
          }) =>
            combineLatest([
              of({ scheduleUid, origin, onSuccess }),
              api.routinesSchedulesDetails.delete({
                profileUid,
                routineUid,
                scheduleUid,
              }),
            ])
        ),
        mergeMap(([{ scheduleUid, origin, onSuccess }]) => {
          const defaultActions = [
            setScheduleDetailsStatus('delete', 'success'),
            deleteRoutinesSchedulesRecord(scheduleUid),
          ];

          if (origin === 'detail') {
            const detailOriginActions = [
              setRoutineMachineAction({ type: 'PREV', params: null }),
              resetRoutineMachineAction(),
            ];

            return from([...defaultActions, ...detailOriginActions]);
          }

          if (onSuccess && typeof onSuccess === 'function') {
            return from([...defaultActions, ...onSuccess()]);
          }

          return from(defaultActions);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setScheduleDetailsStatus('delete', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

const routineScheduleDetailsEpic = combineEpics(
  updateRoutineScheduleEpic,
  deleteRoutineScheduleEpic
);

export default routineScheduleDetailsEpic;
