import * as React from 'react';
import PieChart from './PieChart';
import ChartsCustomTooltip from '../ChartsCustomTooltip/ChartsCustomTooltip';
import useMergedState from '../../hooks/useMergedState';
import { getLabel, getRemainingSegmentColor, hasRoutines } from './helpers';
import { PieChartDataType } from './types';
import { FlexLayout, Layout, Typography } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { MINS_IN_A_DAY, minutesToHMFormat } from '../../helpers/dates';
import {
  calculateScreenTime,
  sumScreenTime,
} from '../../businessLogic/screenTime/screenTime';
import { DefaultRulesScreenTime } from '../../businessLogic/screenTime/types';
import { SummaryDateRanges } from '../../constants';
import { getSanitisedDisabledRoutineName } from '../../ducks/routines/helpers';

export interface ScreenTimePieChartProps {
  dailyTimeLimits: number | null;
  extraTime: number | null;
  label: string;
  screenTimeDefaultRules: Array<PieChartDataType>;
  screenTimeRoutines: Array<PieChartDataType>;
  reportedDays: SummaryDateRanges;
}

export type ScreenTimePieChartState = {
  tooltipX?: number;
  tooltipY?: number;
  tooltipActive: boolean;
  tooltipTitle: string;
  tooltipSubtitle: string;
  screenTime: number;
  remainingScreenTime: number;
  availableScreenTime: number;
  hasAvailableScreenTime: boolean;
  isForSeveralDays: boolean;
  screenTimeAverage: number;
  hasRoutines: boolean;
  label: string;
  reportedDays: number;
};

const isForSeveralDays = (reportedDays: SummaryDateRanges) =>
  [
    SummaryDateRanges.Days7,
    SummaryDateRanges.Days15,
    SummaryDateRanges.Days30,
  ].includes(reportedDays);

const getSegments = (
  segments: Array<PieChartDataType>,
  state: ScreenTimePieChartState
) => {
  if (state.hasRoutines || state.hasAvailableScreenTime) return segments;
  return [];
};

const ScreenTimePieChart = ({
  dailyTimeLimits,
  extraTime,
  label,
  screenTimeDefaultRules,
  screenTimeRoutines,
  reportedDays,
}: ScreenTimePieChartProps) => {
  const chartWrapperRef = React.useRef<HTMLDivElement>(null);
  const segments = [...screenTimeDefaultRules, ...screenTimeRoutines];
  const screenTime = sumScreenTime(segments);
  // if dailyTimeLimits is null it mean that there is no limit => 24h
  const normalizedDailyTimeLimits =
    dailyTimeLimits === null ? MINS_IN_A_DAY : dailyTimeLimits;

  const [businessLogic, updateBusinessLogic] =
    useMergedState<DefaultRulesScreenTime>(
      calculateScreenTime({
        reportedDays,
        screenTime: screenTime ?? 0,
        dailyTimeLimits: normalizedDailyTimeLimits,
        extraTime: extraTime ?? 0,
        screenTimeDefaultRules,
        screenTimeRoutines,
      })
    );

  React.useEffect(() => {
    updateBusinessLogic(
      calculateScreenTime({
        reportedDays,
        screenTime: screenTime ?? 0,
        dailyTimeLimits: normalizedDailyTimeLimits,
        extraTime: extraTime ?? 0,
        screenTimeDefaultRules,
        screenTimeRoutines,
      })
    );
  }, [
    reportedDays,
    screenTime,
    dailyTimeLimits,
    extraTime,
    screenTimeDefaultRules,
    screenTimeRoutines,
  ]);

  const [state, updateState] = useMergedState<ScreenTimePieChartState>({
    tooltipActive: false,
    tooltipTitle: '',
    tooltipSubtitle: '',
    screenTime: businessLogic.screenTime,
    remainingScreenTime: businessLogic.remainingScreenTime,
    availableScreenTime: businessLogic.availableScreenTime,
    hasAvailableScreenTime: businessLogic.remainingScreenTime > 0,
    isForSeveralDays: isForSeveralDays(reportedDays),
    screenTimeAverage: screenTime / reportedDays,
    hasRoutines: hasRoutines(screenTimeRoutines),
    label,
    reportedDays,
  });

  React.useEffect(() => {
    updateState({
      screenTime: businessLogic.screenTime,
      remainingScreenTime: businessLogic.remainingScreenTime,
      availableScreenTime: businessLogic.availableScreenTime,
      hasAvailableScreenTime: businessLogic.remainingScreenTime > 0,
      isForSeveralDays: isForSeveralDays(reportedDays),
      screenTimeAverage: screenTime / reportedDays,
      hasRoutines: hasRoutines(screenTimeRoutines),
      label,
      reportedDays,
    });
  }, [label, businessLogic, reportedDays, screenTimeRoutines]);

  const onSegmentPointerEventHandler = React.useCallback(
    (segment: 'consumed' | 'remaining') =>
      (action: 'in' | 'out') =>
      (e: React.PointerEvent<Element>, data?: PieChartDataType) => {
        if (action === 'out') {
          updateState({ tooltipActive: false });
          return;
        }

        const partialState = {
          tooltipX: e.clientX,
          tooltipY: e.clientY,
        };

        if (segment === 'consumed' && data) {
          Object.assign(partialState, {
            tooltipActive: true,
            tooltipTitle: minutesToHMFormat(data.minutes),
            tooltipSubtitle: state.hasRoutines ? data.name : t('Time spent'),
          });
        }

        if (segment === 'remaining') {
          Object.assign(partialState, {
            tooltipActive:
              state.remainingScreenTime > 0 && state.screenTime > 0,
            tooltipTitle: minutesToHMFormat(state.remainingScreenTime),
            tooltipSubtitle: t('Time remaining'),
          });
        }

        updateState(partialState);
      },
    [updateState]
  );

  const chartBox = chartWrapperRef.current?.getBoundingClientRect();

  /**
   * Nasty bug:
   * We have to use this hook to avoid re-invoking minutesToHMFormat.
   * For some strange reason when user updates the location of a location event,
   * this function is called and crashes the app.
   *
   * The crash stack is: minutesToHMFormat -> t -> getLocale -> getState()
   * Error: "Error: You may not call getState() while the reducer is executing."
   *
   * We think that at some point during re-renders the translation function (t)
   * is executed outside the context of react and causes a crash.
   */
  const formattedScreenTime = React.useMemo(
    () => minutesToHMFormat(state.screenTime),
    [state.screenTime]
  );

  const labelMemo = React.useMemo(() => {
    const realScreenTime = sumScreenTime(
      segments.filter(routine => routine.consumeQuota)
    );
    const hasScreenTime = normalizedDailyTimeLimits >= realScreenTime;

    return getLabel(
      state.label,
      state.isForSeveralDays,
      hasScreenTime,
      state.reportedDays
    );
  }, [
    state.label,
    state.isForSeveralDays,
    state.hasAvailableScreenTime,
    state.reportedDays,
    normalizedDailyTimeLimits,
  ]);

  return (
    <div ref={chartWrapperRef} className="par-screen-time-pie-chart">
      <ChartsCustomTooltip
        id="par-screen-time-pie-chart-tooltip"
        className="par-screen-time-pie-chart__tooltip"
        coords={{
          x: state.tooltipX || chartBox?.x || 0,
          y: state.tooltipY || chartBox?.y || 0,
        }}
        hide={!state.tooltipActive}
        title={state?.tooltipTitle || ''}
        subtitle={
          state?.tooltipSubtitle
            ? getSanitisedDisabledRoutineName(state.tooltipSubtitle)
            : ''
        }
      />
      <FlexLayout mainaxis="column">
        <FlexLayout mainaxis="column" alignSelf="center">
          <PieChart
            totalMinutes={state.availableScreenTime}
            segments={getSegments(segments, state)}
            remainingSegmentColor={getRemainingSegmentColor(
              state.hasRoutines,
              state.isForSeveralDays,
              state.hasAvailableScreenTime,
              state.reportedDays,
              state.screenTime > 0
            )}
            onConsumedPointerEventHandler={onSegmentPointerEventHandler(
              'consumed'
            )}
            onRemainingPointerEventHandler={onSegmentPointerEventHandler(
              'remaining'
            )}
          >
            <Layout textAlignement="center">
              <Typography
                type="h3"
                weight="semibold"
                className="par-screen-time-pie-chart__screen-time-title"
              >
                {formattedScreenTime}
              </Typography>
              <Typography type="body1">{labelMemo}</Typography>
            </Layout>
          </PieChart>
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

export default ScreenTimePieChart;
