import * as React from 'react';
import { AlertBoxType, IconType } from 'styleguide-react';
import { TopBarCasesProps, TopbarAlertType } from '../types/Topbar.types';
import { t } from '../../../lib/i18n';
import TopbarBase from '../TopbarBase';
import { actionWrapper } from '../helpers';
import { OverLimitModalReferrer } from '../../../containers/Modal/OverLimitModalContainer';

export { isVisibleDeviceLimitExceededTopbar } from './predicate';

const DevicesLimitExceededTopbar = ({ isMobile }: TopBarCasesProps) => {
  const props: TopbarAlertType = {
    rounded: !isMobile,
    centered: !isMobile,
    type: AlertBoxType.booster,
    icon: IconType.rocketLaunch,
    goToLink: `/modal/OverLimitModal?referrer=${OverLimitModalReferrer.overlimitTopbar}`,
    text: () =>
      t('You’ve exceeded your device limit - {1}Upgrade now!{/1}', {
        1: actionWrapper,
      }),
  };

  return <TopbarBase {...props} />;
};

export default DevicesLimitExceededTopbar;
