import * as React from 'react';
import { t } from '../../lib/i18n';
import { getFormattedAddress } from '../../records/location';
import {
  getProfile,
  getProfileLocationStatus,
  irregularStatus,
  ProfileStatus,
  cardComponents,
  permissionsInstructionsModalNames,
  hasFetchedAllProfileRules,
} from '../../selectors';
import { ProfileRecord } from '../../records';
import { getSafetyStatus, Marker } from '../../records/marker';
import { metersToFeet } from '../../helpers/conversions';

export const getDetailedStatusCard = (
  profile: ProfileRecord,
  status: ProfileStatus,
  helpTipUrl: string | undefined
): JSX.Element | null => getStatusCard(profile, status, helpTipUrl, true);

export const getStatusCard = (
  profile: ProfileRecord,
  status: ProfileStatus,
  helpTipUrl: string | undefined,
  isDetailed = false
): JSX.Element | null => {
  if (status in cardComponents) {
    return React.createElement(cardComponents[status], {
      name: profile.name,
      isDetailed,
      helpTipUrl,
    });
  }

  return null;
};

export const getLocationDescription = (state, profileId) => {
  const descriptions = {
    [ProfileStatus.LOCATION_SERVICES_DISABLED]: t('Location Services are OFF'),
    [ProfileStatus.LOCATION_TRACKING_OFF]: t('Location tracking is OFF'),
    [ProfileStatus.LAST_LOCATION_ADDRESS_UNAVAILABLE]: t(
      'Address is unavailable'
    ),
    [ProfileStatus.PERMISSIONS_REVOKED]: t('Location permissions needed'),
  };

  const status = getProfileLocationStatus(state, profileId);
  if (status in descriptions) {
    return descriptions[status];
  }

  return getFormattedAddress(getProfile(state, profileId).location);
};

export const profileHasIrregularStatus = (state, profileId): boolean => {
  return irregularStatus.includes(getProfileLocationStatus(state, profileId));
};

export const profileHasTrackingDisabled = (state, profileId): boolean =>
  getProfileLocationStatus(state, profileId) ===
  ProfileStatus.LOCATION_TRACKING_OFF;

export const getHelpTipUrl = (
  profileId: number,
  profileStatus: ProfileStatus,
  platform: string,
  currentUrl: string
): string | undefined => {
  if (profileStatus === ProfileStatus.LOCATION_TRACKING_OFF) {
    return `/profiles/${profileId}/rules/locationRules`;
  }

  return platform in permissionsInstructionsModalNames
    ? `${currentUrl}/modal/${permissionsInstructionsModalNames[platform][profileStatus]}`
    : undefined;
};

export const mapMarkerSafetyStatus = (state, markers) => {
  const hasFetchedProfileRules = hasFetchedAllProfileRules(state);
  return markers.map(marker => {
    marker.safetyStatus = getSafetyStatus(
      hasFetchedProfileRules && profileHasIrregularStatus(state, marker.id),
      profileHasTrackingDisabled(state, marker.id)
    );

    return marker;
  });
};

export const getAccuracyInfo = (
  marker: Marker,
  isUsingImperialSystem: boolean
): string =>
  t('{{value}} {{unit_abbreviation}} accuracy', {
    unit_abbreviation: isUsingImperialSystem ? 'ft' : 'm',
    value: isUsingImperialSystem
      ? Math.round(metersToFeet(marker.accuracy))
      : marker.accuracy,
  });
