import * as React from 'react';
import {
  Button,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  Typography,
} from 'styleguide-react';
import type { DelegationOptOutModalProps } from '../../containers/Modal/DelegationOptOutModalContainer';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';

const DelegationOptOutModal = ({
  onClickClose,
  onClickOptOut,
}: DelegationOptOutModalProps) => {
  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="opt-out"
          onClick={onClickOptOut}
          size={ButtonSize.medium}
          block
          testId="Delegation-opt-out-modal__opt-out-button"
        >
          {t('Switch off')}
        </Button>,
        <Button
          key="cancel"
          onClick={onClickClose}
          buttonType={ButtonType.secondary}
          size={ButtonSize.medium}
          block
          testId="Delegation-opt-out-modal__cancel-button"
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="Delegation-opt-out-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" alignSelf="center">
          <Layout marginBottom="24" textAlignement="center">
            <Icon
              type={IconType.exclamationTriangle}
              color={IconColor.warning}
              family={IconFamily.solid}
              size={IconSize.x4}
            />
          </Layout>
          <Layout textAlignement="center">
            <Typography type="h5" weight="semibold" align="center">
              {t('Switch off rules on school-issued devices?')}
            </Typography>

            <Typography type="body2" align="center">
              {t(
                'You’ll no longer be able to protect school devices with your own rules or access extensive reports.'
              )}
            </Typography>
          </Layout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default DelegationOptOutModal;
