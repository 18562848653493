import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import { getQueryParam } from '../../../selectors';
import { getRoutine } from '../../../ducks/routines/selectors';
import CantEnableTimeSlotsModal from '../../../components/Routines/Modals/CantEnabletimeSlots';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickClose: () => dispatch(goBackIfHistory()),
  };
};

const mapStateToProps = (state: State) => {
  const routineUid = getQueryParam(state, 'routineUid');
  const routine = getRoutine(state, routineUid!);

  return {
    routine,
  };
};

const CantEnableTimeSlotsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CantEnableTimeSlotsModal);

export default CantEnableTimeSlotsModalContainer;
