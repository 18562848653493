import { connect } from 'react-redux';

import AppDailyTimeLimitModal from '../../components/Modal/AppDailyTimeLimitModal';
import { combineMapDispatchToProps } from '../../helpers/connect';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { Dispatch } from '../../store/state';

import {
  mapStateToProps as appDailyTimeLimitContainerMapStateToProps,
  mapDispatchToProps as appDailyTimeLimitContainerMapDispatchTo,
} from '../AppDailyTimeLimit/AppDailyTimeLimitContainer';

export const goBack = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner:back',
    src: null,
  });
};

const mapBackToDispatch = (dispatch: Dispatch) => ({
  onDone: () => dispatch(goBack()),
});

const mapDispatchToProps = combineMapDispatchToProps<
  ReturnType<typeof mapBackToDispatch> &
    ReturnType<typeof appDailyTimeLimitContainerMapDispatchTo>
>(appDailyTimeLimitContainerMapDispatchTo, mapBackToDispatch);

const AppDailyTimeLimitModalContainer = connect(
  appDailyTimeLimitContainerMapStateToProps,
  mapDispatchToProps
)(AppDailyTimeLimitModal);

export default AppDailyTimeLimitModalContainer;
