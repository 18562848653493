import { connect } from 'react-redux';
import DirectPurchase from '../../components/DirectPurchase/DirectPurchase';
import State, { BaseThunk, Dispatch } from '../../store/state';
import { finishedLoadingInitialData } from '../../ducks/app/redirectAfterLogin';
import {
  getProduct,
  getSameProductForLongerDuration,
} from '../../selectors/product';
import { getAccount } from '../../selectors/account';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { startLoadingInitialData } from '../../ducks/app';
import { fetchProduct } from '../../ducks/products/helpers';
import { getPaymentProvider } from '../../selectors';
import { receiveProducts } from '../../ducks/products';
import { getLicense } from '../../selectors/license';
import { isPremium } from '../../records/license';
import * as qinit from '../../qinit';
import {
  getDirectPurchase,
  getDirectPurchaseProduct,
  ensureSetDirectPurchaseData,
} from '../../ducks/directPurchase';
import { notifyNavigationAction } from '../../epics/contentCommunication/actions';
import { isPageInIframe } from '../../epics/contentCommunication/helpers';

/**
 * When the URL to purchase a product is sent from the public site,
 * it is necessary to request that product again by its code and long-term version because to generate the purchase URL,
 * it is necessary to inform the backend of the purchase source.
 * In the case of direct purchase, even if the purchase is made in the parent app,
 * the purchase source is the public site. It is very important to keep in mind that this will result in
 * products being placed in the state with the source public site.
 */
const updateProduct = (): BaseThunk => {
  return async (dispatch, getState) => {
    const directPurchaseState = getDirectPurchase(getState());
    const cart = directPurchaseState.get('cart');
    const pg = directPurchaseState.get('pg');

    if (!cart || !pg) return;
    dispatch(startLoadingInitialData());

    const paymentProvider = getPaymentProvider(getState());
    const products = await fetchProduct(
      {
        productCode: cart as string,
        paymentProvider,
        size: true,
        xsource: pg as string,
      },
      {
        requestTwoYears: true,
      }
    );

    dispatch(receiveProducts(products, paymentProvider));
    await dispatch(finishedLoadingInitialData());
  };
};

const goToHome = (): BaseThunk => dispatch => {
  const navigation = getMultiPlatformNavigation();
  const url = qinit.tenant.common.dashboard.home_link;

  if (isPageInIframe()) {
    dispatch(notifyNavigationAction(url));
  } else {
    return navigation({
      type: 'location:href',
      src: url,
    });
  }
};

const isPremiumLicense = (state: State) => {
  const license = getLicense(state);
  return isPremium(license.type);
};

const mapStateToProps = (state: State) => {
  const directPurchaseProduct = getDirectPurchaseProduct(state);

  const productCode = directPurchaseProduct
    ? directPurchaseProduct.code
    : undefined;

  const product = getProduct(state, productCode);

  const productWithLongerDuration = productCode
    ? getSameProductForLongerDuration(state, productCode)
    : undefined;

  return {
    isPremiumLicense: isPremiumLicense(state),
    account: getAccount(state),
    product,
    productWithLongerDuration,
  };
};

const navigateToSelectedProduct =
  (url: string): BaseThunk =>
  dispatch => {
    const navigation = getMultiPlatformNavigation();

    if (isPageInIframe()) {
      dispatch(notifyNavigationAction(url));
    } else {
      return navigation({
        type: 'location:href',
        src: url,
      });
    }
  };

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onUpgradePlan: (url: string) => dispatch(navigateToSelectedProduct(url)),
  onContinueToCheckout: (url: string) =>
    dispatch(navigateToSelectedProduct(url)),
  goToHome: () => dispatch(goToHome()),
});

const DirectPurchaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectPurchase);

/**
 * The data is loaded in the router's load instead of using useEffect because the component,
 * for some strange reason, enters into an update cycle.
 * */
DirectPurchaseContainer.load = (): BaseThunk => async (dispatch, getState) => {
  if (!isPremiumLicense(getState())) {
    await dispatch(ensureSetDirectPurchaseData());
    await dispatch(updateProduct());
  }
};

export default DirectPurchaseContainer;
