import { PushPluginNotificationData } from './types/PushPayload';

export const INITIALIZE_PUSH_SERVICE = 'INITIALIZE_PUSH_SERVICE';
export const ERROR_PUSH_SERVICE = 'ERROR_PUSH_SERVICE';
export const INITIALIZED_PUSH_SERVICE = 'INITIALIZED_PUSH_SERVICE';
export const RECEIVE_REGISTRATION_ID_PUSH_SERVICE =
  'RECEIVE_REGISTRATION_ID_PUSH_SERVICE';
export const RECEIVE_NOTIFICATION_PUSH_SERVICE =
  'RECEIVE_NOTIFICATION_PUSH_SERVICE';
export const RECEIVE_PERMISSION_PUSH_SERVICE =
  'RECEIVE_PERMISSION_PUSH_SERVICE';

export const initializedPushService = () => ({
  type: INITIALIZED_PUSH_SERVICE,
});

export const errorPushService = e => ({
  type: ERROR_PUSH_SERVICE,
  payload: e,
  receivedAt: Date.now(),
});

export const receivePushPermission = hasPermission => ({
  type: RECEIVE_PERMISSION_PUSH_SERVICE,
  payload: hasPermission,
  receivedAt: Date.now(),
});

export const receivePushRegistrationId = (registrationId, pushPlatform) => ({
  type: RECEIVE_REGISTRATION_ID_PUSH_SERVICE,
  payload: {
    registrationId,
    pushPlatform,
  },
  receivedAt: Date.now(),
});

export const receiveNotificationPushService = (
  data: PushPluginNotificationData
) => ({
  type: RECEIVE_NOTIFICATION_PUSH_SERVICE,
  payload: data,
  receivedAt: Date.now(),
});
