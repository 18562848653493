/* eslint-disable camelcase */
export type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

export enum RequestTransformer {
  ParamsTransformer = 'params_transformer',
}

export type RequestTransformerType = {
  name: RequestTransformer;
  transformApplies: (params: {
    name: string;
    methods?: Method[];
    path?: (params) => string;
  }) => boolean;
  transform: (params: object) => object;
};

export enum endpointName {
  license = 'license',
  profiles = 'profiles',
}

export type RequestStatusType = 'create' | 'read' | 'update' | 'delete';
export type RequestStatus =
  | 'idle'
  | 'loading'
  | 'success'
  | 'settled'
  | 'error';

export type CollectionResponse<T extends unknown> = {
  items_list: T[];
  total_count: number;
};
