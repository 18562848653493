import * as React from 'react';
import { AlertBox, AlertBoxType, Button } from 'styleguide-react';
import { t } from '../../lib/i18n';
import * as IcRocket from '../../assets/base/images/ic_rocket.svg';
import { classNames } from '../../helpers';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import mainLogotypeByQoria from '../../assets/base/images/v3/logo/main-logotype-by-qoria.svg';

const Logout: React.FunctionComponent<{
  error: boolean;
  loading: boolean;
  onSubmit: () => any;
}> = ({ error, loading, onSubmit }) => (
  <PageLayout
    configuration={[{ columns: 1, screenSize: ScreenSize.TabletOrDesktop }]}
  >
    <div className="Logout">
      <div className="Logout--container">
        <div className={classNames('Logout__body', 'Logout__body--full')}>
          <div
            className={classNames(
              'Logout__body__header',
              error ? 'Logout__body__header--error' : ''
            )}
          >
            <img src={mainLogotypeByQoria} alt="logo" />
          </div>
          <div>
            {error && (
              <div className="Logout__body__alert--container">
                <AlertBox
                  type={AlertBoxType.error}
                  iconClass="far fa-lg fa-exclamation-circle"
                >
                  <strong>
                    {t("Something went wrong. Let's try that again")}
                  </strong>
                </AlertBox>
              </div>
            )}
            <div className="Logout__body__box">
              <div className="Logout__body__box__icon">
                <img src={IcRocket} alt="rocket" />
              </div>
              <p className="Logout__body__box__title">{t('Ready to start?')}</p>
              <p className="Logout__body__box__subtitle">
                {t('Log in now to view your child’s online activity!')}
              </p>
              <Button
                className="Logout__body__box__button"
                onClick={onSubmit}
                loading={loading}
                disabled={loading}
              >
                {t('Log in')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
);

export default Logout;
