import * as React from 'react';
import { t } from '../../lib/i18n';
import { connect } from 'react-redux';
import { close } from '../../ducks/notifications';
import {
  List,
  FreeStyleListItem,
  Modal,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

export const MissingPermissionsInstructionsIosModal: React.FunctionComponent<{
  onClickClose: () => any;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Allow permissions')}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
  >
    <ModalStyledText marginBottom="small" textAlign="left">
      {t("On your child's iPhone:")}
    </ModalStyledText>
    <List showSeparator={false} listItemMarginBottom="8">
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>1. </strong>
          {t('Go to Settings')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>2. </strong>
          {t('Tap on Privacy > Location Services')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>3. </strong>
          {t('Scroll down to find {{shortName}}')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>4. </strong>
          {t('Tap on {{shortName}} and select "Always"')}
        </ModalStyledText>
      </FreeStyleListItem>
    </List>
  </Modal>
);

export const MissingPermissionsInstructionsAndroidModal: React.FunctionComponent<{
  onClickClose: () => any;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Allow permissions')}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
  >
    <ModalStyledText marginBottom="8" textAlign="left">
      {t("On your child's Android:")}
    </ModalStyledText>
    <List showSeparator={false} listItemMarginBottom="8">
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>1. </strong>
          {t('Go to Settings > Location')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>2. </strong>
          {t('Select App permissions')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>3. </strong>
          {t('Tap {{shortName}} and select "Allow all the time"')}{' '}
        </ModalStyledText>
      </FreeStyleListItem>
    </List>
  </Modal>
);

export const LocationServicesDisabledInstructionsIosModal: React.FunctionComponent<{
  onClickClose: () => any;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Turn Location Services ON')}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
  >
    <ModalStyledText marginBottom="8" textAlign="left">
      {t("On your child's iPhone:")}
    </ModalStyledText>
    <List showSeparator={false} listItemMarginBottom="8">
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>1. </strong>
          {t('Go to Settings')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>2. </strong>
          {t('Tap on Privacy > Location Services')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>3. </strong>
          {t('Turn Location Services ON')}
        </ModalStyledText>
      </FreeStyleListItem>
    </List>
  </Modal>
);

export const LocationServicesDisabledInstructionsAndroidModal: React.FunctionComponent<{
  onClickClose: () => any;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Turn Location Services ON')}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
  >
    <ModalStyledText marginBottom="8" textAlign="left">
      {t("On your child's Android:")}
    </ModalStyledText>
    <List showSeparator={false} listItemMarginBottom="8">
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>1. </strong>
          {t('Go to Settings')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>2. </strong>
          {t('Tap Location')}
        </ModalStyledText>
      </FreeStyleListItem>
      <FreeStyleListItem>
        <ModalStyledText textAlign="left">
          <strong>3. </strong>
          {t('Turn Use Location ON')}
        </ModalStyledText>
      </FreeStyleListItem>
    </List>
  </Modal>
);

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(close()),
});

export const MissingPermissionsInstructionsIosModalContainer = connect(
  null,
  mapDispatchToProps
)(MissingPermissionsInstructionsIosModal);

export const MissingPermissionsInstructionsAndroidModalContainer = connect(
  null,
  mapDispatchToProps
)(MissingPermissionsInstructionsAndroidModal);

export const LocationServicesDisabledInstructionsIosModalContainer = connect(
  null,
  mapDispatchToProps
)(LocationServicesDisabledInstructionsIosModal);

export const LocationServicesDisabledInstructionsAndroidModalContainer =
  connect(
    null,
    mapDispatchToProps
  )(LocationServicesDisabledInstructionsAndroidModal);
