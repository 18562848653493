import * as React from 'react';
import { LinewizeEventRecord } from '../../../records/linewizeEvent/types/linewizeEvents';
import { Badge, Layout } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import ActivityStyledItem from '../../ActivityTimeline/ActivityStyledItem/ActivityStyledItem';
import {
  LinewizeEventAction,
  LinewizeEventWithActions,
  SchoolHours,
} from '../../../businessLogic/LinewizeEvents/LinewizeEvent.types';
import WebAppEvent from './WebAppEvent';
import SearchEvent from './SearchEvent';
import VideoEvent from './VideoEvent';
import {
  isLinewizeSearchEvent,
  isLinewizeVideoEvent,
  isLinewizeWebAppEvent,
} from '../../../records/linewizeEvent/linewizeEvents';
import { classNames } from '../../../helpers';

interface EventsSchoolPeriodProps {
  eventsByPeriod: {
    type: SchoolHours;
    events: LinewizeEventWithActions[];
  };
  isFirstEvent: boolean;
  isLastEvent: boolean;
  isTodayFirstEvent: boolean;
  onActionClick: (
    event: LinewizeEventRecord,
    action: LinewizeEventAction
  ) => void;
}

const EventsSchoolPeriod: React.FC<EventsSchoolPeriodProps> = ({
  eventsByPeriod: { events, type },
  isFirstEvent,
  isLastEvent,
  isTodayFirstEvent,
  onActionClick,
}) => {
  const isSchoolPeriod = type === 'IN_SCHOOL_HOURS';

  const getEventItem = (
    event: LinewizeEventRecord,
    actions: LinewizeEventAction[]
  ): React.ReactElement<typeof ActivityStyledItem> | null => {
    if (isLinewizeWebAppEvent(event)) {
      return (
        <WebAppEvent
          event={event}
          eventActions={actions}
          onActionClick={onActionClick}
        />
      );
    }
    if (isLinewizeSearchEvent(event)) {
      return (
        <SearchEvent
          event={event}
          eventActions={actions}
          onActionClick={onActionClick}
        />
      );
    }
    if (isLinewizeVideoEvent(event)) {
      return (
        <VideoEvent
          event={event}
          eventActions={actions}
          onActionClick={onActionClick}
        />
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {isSchoolPeriod && !isTodayFirstEvent && (
        <Layout
          className={classNames(
            'par-school-timeline__school-badge',
            'par-school-timeline__school-badge--finish',
            isFirstEvent && 'par-school-timeline__school-badge--without-before'
          )}
          testId="school-timeline-period-end-container"
        >
          <Badge type="school" text={t('School finished')} />
        </Layout>
      )}
      {events.map(({ event, actions }) => getEventItem(event, actions))}
      {isSchoolPeriod && (
        <Layout
          className={classNames(
            'par-school-timeline__school-badge',
            'par-school-timeline__school-badge--start',
            isLastEvent && 'par-school-timeline__school-badge--without-after'
          )}
          testId="school-timeline-period-start-container"
        >
          <Badge type="school" text={t('School started')} />
        </Layout>
      )}
    </React.Fragment>
  );
};

export default EventsSchoolPeriod;
