// eslint-disable-next-line max-classes-per-file
import * as React from 'react';
import { connect } from 'react-redux';
import { T } from 'ramda';
import { gaScreenView } from '.';
import { page, PageNames } from './analytics';
import { getExperiment, getLocation } from '../selectors';
import { ExperimentNames } from '../ducks/experiments';
import { BaseThunk } from '../store/state';
import { getDocument } from '../sideEffects/browser';
import Loader from '../components/base/Loader';
import { RouterProps } from './multiPlatformNavigation';
import { ComponentProps } from 'react';

export const experiment = (
  name: ExperimentNames,
  containers: {
    [index: string]: React.ComponentType<any>;
    default: React.ComponentType<any>;
  }
): React.ComponentType<any> => {
  const DefaultComponent = containers.default;

  const ExperimentSelector: React.FunctionComponent<{
    experimentResult: string;
  }> = props => {
    const { experimentResult } = props;
    const WrappedComponent = containers[experimentResult] || DefaultComponent;

    return <WrappedComponent {...props} />;
  };

  const getComponent = experimentResult =>
    containers[experimentResult] || DefaultComponent;

  const mapStateToProps = (state, ownProps) => {
    const experiment = getExperiment(state, name);
    const component = experiment
      ? getComponent(experiment.result)
      : DefaultComponent;

    ExperimentSelector.displayName = (component.displayName || component.name)
      .replace('Connect(', '')
      .replace(')', '');

    return {
      ...ownProps,
      experimentResult: experiment ? experiment.result : '',
    };
  };

  const ConnectedExperiment = connect(mapStateToProps)(ExperimentSelector);

  ConnectedExperiment.load =
    (params): BaseThunk<void> =>
    (dispatch, getState) => {
      const state = getState();
      const experiment = getExperiment(state, name);
      const component = experiment
        ? getComponent(experiment.result)
        : DefaultComponent;
      if (experiment) {
        const location = getLocation(state);
        const { result } = experiment;
        gaScreenView({
          key: `${location.key}-${result}`,
          pathname: `${location.pathname}-${result}`,
        });
      }
      if ((component as any).load) {
        return dispatch((component as any).load(params));
      }
    };

  ConnectedExperiment.redirect = params => (dispatch, getState) => {
    const state = getState();
    const experiment = getExperiment(state, name);
    const component = experiment
      ? getComponent(experiment.result)
      : DefaultComponent;
    if ((component as any).redirect) {
      return dispatch((component as any).redirect(params));
    }
  };

  return ConnectedExperiment;
};

interface InfiniteScrollProps {
  isFetching: boolean;
  hasMore?: boolean;
  handleMore: () => void;
}

export const infiniteScroll = <Props extends object>(
  WrappedComponent: React.ComponentType<Props>
) => {
  class InfiniteScroll extends React.Component<Props & InfiniteScrollProps> {
    componentDidMount() {
      this.registerScrollHandler();
    }

    componentWillUnmount() {
      this.unregisterScrollHandler();
    }

    registerScrollHandler = () => {
      getDocument().addEventListener('scroll', this.onScroll, true);
    };

    unregisterScrollHandler = () => {
      getDocument().removeEventListener('scroll', this.onScroll, true);
    };

    onScroll = evt => {
      const target =
        evt.target === getDocument().scrollingElement ||
        evt.target === getDocument()
          ? getDocument().scrollingElement || getDocument().documentElement
          : evt.target;
      const { hasMore, isFetching, handleMore } = this.props;
      // TODO update this, the calc maybe flawed see:
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
      // we should update this component to use intersection observer
      // updated the cal so use the same used in other scrolls on the app
      const isOnTop =
        target.scrollTop + target.clientHeight >= target.scrollHeight - 100;
      if (hasMore && !isFetching && isOnTop) {
        handleMore();
      }
    };

    render() {
      const { isFetching, hasMore } = this.props;
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
          {isFetching && hasMore && (
            <div className="infiniteScroll-container">
              <Loader size="small" />
            </div>
          )}
        </React.Fragment>
      );
    }
  }
  (
    InfiniteScroll as React.ComponentType<any>
  ).displayName = `InfiniteScroll(${WrappedComponent.name})`;

  return InfiniteScroll;
};

export const trackablePage = (
  WrappedComponent: React.ComponentType<any>,
  pageName: ((props: any) => PageNames) | PageNames,
  getTrackableProps?: (
    router: RouterProps,
    props: Record<string, any>
  ) => Record<string, string>
): React.ComponentType<any> => {
  class TrackablePage extends React.Component<any> {
    componentDidMount() {
      const { router, ...otherProps } = this.props;
      page(
        typeof pageName === 'function' ? pageName(this.props) : pageName,
        getTrackableProps ? getTrackableProps(router, otherProps) : undefined
      );
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  (
    TrackablePage as React.ComponentType<any>
  ).displayName = `TrackablePage(${WrappedComponent.name})`;

  return TrackablePage;
};

type Predicate<Props> = (props: Props) => boolean;

type Case<PredicateProps, ComponentProps> = {
  case: Predicate<PredicateProps>;
  return: React.FC<ComponentProps>;
};

export const withCase = <PredicateProps, ComponentProps>(
  predicate: Predicate<PredicateProps>,
  component: React.FC<ComponentProps>
): Case<PredicateProps, ComponentProps> => ({
  case: predicate,
  return: component,
});

export const withSwitch = <T extends Array<Case<any, any>>>(...cases: T) =>
  (props => {
    return cases.find(options => options.case(props))?.return(props);
  }) as React.FC<
    Parameters<T[number]['case']>[0] & ComponentProps<T[number]['return']>
  >;

export const None: React.FC = () => null;

export const isDefaultCase = T;
