import { combineEpics, ofType } from 'redux-observable';
import { OrderedSet } from 'immutable';
import { mergeMap, from, pairwise, startWith, catchError, of, iif } from 'rxjs';
import { AppAction, AppEpic } from '../../epics/types';
import { LinewizeEventsOperation } from '../../records/linewizeEvent/linewizeEvents';
import { normalizeLinewizeEvents, keysLinewizeEvent } from './transformations';
import {
  linewizeEventsSuccessAction,
  linewizeEventsReceiveAction,
  linewizeEventsRequestAction,
  linewizeEventsResetAction,
  linewizeEventsErrorAction,
  linewizeEventsExceptionAction,
  linewizeEventsChangeStatusAction,
} from './actions';

import type {
  LinewizeEventsSuccessAction,
  LinewizeEventsRequestAction,
  LinewizeEventsReceiveAction,
  LinewizeEventsErrorAction,
  LinewizeEventsResetAction,
  LinewizeEventsExceptionAction,
  LinewizeEventsChangeStatusAction,
} from './types';

import {
  FETCH_EVENTS_LIMIT,
  hasMoreWhenUseCache,
} from '../../businessLogic/events/events';
import {
  isSchoolSignaturePage,
  isSchoolTimelineDetailPage,
  isSchoolTimelinePage,
  isSchoolTimelineSubpage,
  ofLocationChangeActionWhen,
} from '../../epics/router';
import { resetLinewizeEventRecord } from '../records';
import { not } from '../../helpers/predicates';
import { APIError } from '../../lib/errors';
import { showErrorAlert } from '../../helpers/errorHandling';
import { captureException } from '../../helpers/sentry';
import { allPass } from 'ramda';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

const unlinkedStatus = [410, 403, 502];

export const requestLinewizeEventsEpic: AppEpic<
  LinewizeEventsRequestAction,
  | LinewizeEventsSuccessAction
  | LinewizeEventsReceiveAction
  | LinewizeEventsErrorAction
  | LinewizeEventsExceptionAction
  | LinewizeEventsChangeStatusAction
> = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType('LINEWIZE_EVENTS_REQUEST'),
    startWith(linewizeEventsRequestAction([], '')),
    pairwise(),
    mergeMap(requestAction => {
      return of(requestAction).pipe(
        mergeMap(async ([prevAction, action]) => {
          const { profileUid, filter, pageId } = action.payload;
          const prevPageId = prevAction.payload.pageId;
          const [selectedFilter] = filter;

          const response = await api.studentEvents.withCache(ttl1Minute).get(
            {
              profileUid,
            },
            {
              filter: selectedFilter !== 'all' ? selectedFilter : null,
              page_id: pageId || '',
              limit: FETCH_EVENTS_LIMIT,
            }
          );

          // TODO This is temporal until BCK does this at their side
          if (
            (filter === 'videos' && !response.timeline_video_enabled) ||
            (filter === 'blocked' && !response.timeline_blocked_enabled) ||
            (filter === 'searches' && !response.timeline_search_enabled)
          ) {
            throw new APIError({ status: 412 }, 'studentEvents', 'GET', null);
          }

          const events = response.events.map(event =>
            LinewizeEventsOperation.fromPayload(event)
          );

          return {
            pageId,
            filter,
            events,
            timelineSearchEnabled: response.timeline_search_enabled,
            timelineVideoEnabled: response.timeline_video_enabled,
            timelineBlockedEnabled: response.timeline_blocked_enabled,
            timelineAllowedEnabled: response.timeline_allowed_enabled,
            lastUpdated: response.last_updated,
            uuids: OrderedSet(keysLinewizeEvent(events)),
            hasMore: hasMoreWhenUseCache({
              events,
              filter,
              pageId,
              prevPageId,
            }),
          };
        }),
        mergeMap(
          ({
            uuids,
            hasMore,
            filter,
            events,
            lastUpdated,
            timelineSearchEnabled,
            timelineVideoEnabled,
            timelineBlockedEnabled,
            timelineAllowedEnabled,
          }) =>
            iif(
              () => hasMore || uuids.size > 0,
              from([
                linewizeEventsSuccessAction({
                  uuids,
                  hasMore,
                  filter,
                  lastUpdated,
                  timelineSearchEnabled,
                  timelineVideoEnabled,
                  timelineBlockedEnabled,
                  timelineAllowedEnabled,
                }),
                linewizeEventsReceiveAction(normalizeLinewizeEvents(events)),
              ]),
              of(linewizeEventsChangeStatusAction('SUCCESS'))
            )
        ),
        catchError((e: APIError) => {
          if (unlinkedStatus.includes(e.status)) {
            return of(linewizeEventsExceptionAction('CHILD_UNLINKED'));
          }
          if (e.status === 412) {
            return of(linewizeEventsExceptionAction('REPORT_DISABLED'));
          }
          captureException(e);
          showErrorAlert(e);
          return of(linewizeEventsErrorAction());
        })
      );
    })
  );
};

export const resetLinewizeEvents: AppEpic<
  AppAction,
  LinewizeEventsResetAction | ReturnType<typeof resetLinewizeEventRecord>
> = actions$ => {
  return actions$.pipe(
    ofLocationChangeActionWhen({
      from: isSchoolTimelinePage,
      to: allPass([
        not(isSchoolSignaturePage),
        not(isSchoolTimelineDetailPage),
        not(isSchoolTimelineSubpage),
      ]),
    }),
    mergeMap(() => {
      return from([resetLinewizeEventRecord(), linewizeEventsResetAction()]);
    })
  );
};

const linewizeEventsEpic = combineEpics(
  requestLinewizeEventsEpic,
  resetLinewizeEvents
);

export default linewizeEventsEpic;
