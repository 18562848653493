import * as React from 'react';
import * as Moment from 'moment';
import * as R from 'ramda';
import { FlexLayout, InstructionBlock } from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  WEEKDAYS,
  isHourState,
  weekdaysShortTranslations,
  Weekday,
} from '../../helpers/dates';
import { classNames } from '../../helpers';
import {
  DAYTIME_MORNING,
  DAYTIME_AFTERNOON,
  DAYTIME_NIGHT,
} from '../../constants';

interface RestrictedTimesGridProps {
  isMorningCollapsed: boolean;
  isAfternoonCollapsed: boolean;
  isNightCollapsed: boolean;
  isDisabled: boolean;
  timeRanges: any;
  onToggleDayTime: (daytime: any, isCollapsed: boolean) => any;
  onCellTouchEnd: (daytime: any, weekday: Weekday, hour: number) => any;
}

const RestrictedTimesGrid = ({
  isDisabled,
  ...props
}: RestrictedTimesGridProps) => {
  return (
    <div
      data-testId="RestrictedTimesGrid__Container"
      className={classNames(
        'RestrictedTimesGrid__Container',
        isDisabled ? 'RestrictedTimesGrid__Container--disabled' : undefined
      )}
    >
      <FlexLayout mainaxis="column">
        <FlexLayout
          mainaxis="row"
          flexGrow="0"
          paddingBottom="24"
          paddingTop="24"
        >
          <InstructionBlock>
            {t('Prevent device use by highlighting restricted times in red.')}
          </InstructionBlock>
        </FlexLayout>

        <FlexLayout mainaxis="column" flexGrow="0">
          <div className="RestrictedTimesGrid__Header">
            <table>
              <tbody>
                <tr>
                  <td />
                  <WeekDays />
                </tr>
              </tbody>
            </table>
          </div>

          <Table isDisabled={isDisabled} {...props} />
        </FlexLayout>
      </FlexLayout>
    </div>
  );
};

const WeekDays = () => (
  <React.Fragment>
    {WEEKDAYS.map((weekday, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <td key={index}>
        <span>{weekdaysShortTranslations()[weekday]}</span>
      </td>
    ))}
  </React.Fragment>
);

const Table = (props: RestrictedTimesGridProps) => {
  const {
    isMorningCollapsed,
    isAfternoonCollapsed,
    isNightCollapsed,
    isDisabled,
    onToggleDayTime,
    timeRanges,

    onCellTouchEnd,
  } = props;

  const info = [
    [DAYTIME_MORNING, isMorningCollapsed, R.range(5, 13), t('Morning')],
    [DAYTIME_AFTERNOON, isAfternoonCollapsed, R.range(13, 21), t('Afternoon')],
    [
      DAYTIME_NIGHT,
      isNightCollapsed,
      R.range(21, 24).concat(R.range(0, 5)),
      t('Night'),
    ],
  ];

  return (
    <table className="RestrictedTimesGrid">
      {info.map(([daytime, isCollapsed, range, label], index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <DayTimeLabel
            text={label}
            onToggle={() => onToggleDayTime(daytime, !isCollapsed)}
          />
          <HourRows
            isCollapsed={isCollapsed}
            hours={range}
            timeRanges={timeRanges}
            onCellTouchEnd={
              isDisabled
                ? () => undefined
                : (weekday, hour) => onCellTouchEnd(daytime, weekday, hour)
            }
          />
        </React.Fragment>
      ))}
    </table>
  );
};

export const DayTimeLabel = ({
  text,
  icon = null,
  onToggle,
}: {
  text: string;
  icon?: React.ReactNode;
  onToggle: () => void;
}) => (
  <tbody onClick={onToggle} className="RestrictedTimesGrid__DayTimeGroupHeader">
    <tr>
      <td className="RestrictedTimesGrid__HourLabel" />
      <td colSpan={7} className="RestrictedTimesGrid__DayTimeGroupLabel">
        {icon}
        {text}
      </td>
    </tr>
  </tbody>
);

export const HourRows = ({ isCollapsed, hours, ...props }: any) => (
  <tbody
    className={
      isCollapsed
        ? 'RestrictedTimesGrid__DayTimeGroup--collapsed'
        : 'RestrictedTimesGrid__DayTimeGroup--open'
    }
  >
    {hours.map(hour => (
      <HourRow key={hour} hour={hour} {...props} />
    ))}
  </tbody>
);

export const HourRow = ({ hour, timeRanges, onCellTouchEnd }) => {
  const formattedHour = Moment().hours(hour).format('ha');

  return (
    <tr key={hour} onClick={() => undefined}>
      <td className="RestrictedTimesGrid__HourLabel">
        <span>{formattedHour}</span>
      </td>
      {WEEKDAYS.map((weekday, index) => {
        // Hours 0 to 4 belong to the next day,
        // not to the current column (as each day starts at 5am)
        const realWeekday =
          hour >= 0 && hour < 5 ? WEEKDAYS[(index + 1) % 7] : weekday;

        const blockedClassName = isHourState(timeRanges, realWeekday, hour)
          ? 'RestrictedTimesGrid__Hour--blocked'
          : '';

        return (
          <td
            key={weekday}
            onClick={() => onCellTouchEnd(realWeekday, hour)}
            className={`RestrictedTimesGrid__Hour ${blockedClassName}`}
          >
            <span className="RestrictedTimesGrid__Spacer" />
          </td>
        );
      })}
    </tr>
  );
};

export default RestrictedTimesGrid;
