import * as React from 'react';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { t } from '../../lib/i18n';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import FamilyActivities from './WellbeingFeaturePage/FamilyActivities';
import { WellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/types';

interface WellbeingActivitiesProps {
  weeklyPrice: string;
  isLoading: boolean;
  subscribeToActivities: () => void;
  onLoad: () => void;
  subscriptionStatus: WellbeingSubscriptionStatus;
}

const WellbeingActivities = ({
  weeklyPrice,
  isLoading,
  subscribeToActivities,
  onLoad,
  subscriptionStatus,
}: WellbeingActivitiesProps) => {
  React.useEffect(() => {
    onLoad();
  }, [subscriptionStatus]);

  return (
    <PageLayout
      className="par-wellbeing-page"
      title={t('Wellbeing Activities')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: false,
          useNonFlexLayout: false,
          removePaddings: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          columns: 1,
          removePaddings: true,
          useNonFlexLayout: false,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.All }]}
    >
      <PageSectionLayout>
        <FamilyActivities
          weeklyPrice={weeklyPrice}
          onClickButton={subscribeToActivities}
          isDisabledButton={isLoading}
        />
      </PageSectionLayout>
    </PageLayout>
  );
};

export default WellbeingActivities;
