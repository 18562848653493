import type {
  LinewizeEventsRequestAction,
  LinewizeEventsSuccessAction,
  LinewizeEventsExceptionAction,
  LinewizeEventsErrorAction,
  LinewizeEventsReceiveAction,
  LinewizeEventsResetAction,
  LinewizeEventFilter,
  LinewizeEventsRefreshAction,
  LinewizeEventsChangeStatusAction,
  LinewizeEventStatus,
  LinewizeEventsSuccessPayload,
} from './types';
import { LinewizeEventRecord } from '../../records/linewizeEvent/types/linewizeEvents';

export const linewizeEventsRequestAction = (
  filter: Array<LinewizeEventFilter>,
  profileUid: string,
  pageId?: string
): LinewizeEventsRequestAction => ({
  type: 'LINEWIZE_EVENTS_REQUEST',
  payload: { profileUid, filter, pageId },
});

export const linewizeEventsSuccessAction = (
  payload: LinewizeEventsSuccessPayload
): LinewizeEventsSuccessAction => ({
  type: 'LINEWIZE_EVENTS_SUCCESS',
  payload,
});

export const linewizeEventsExceptionAction = (
  status: 'NO_ACTIVITY' | 'REPORT_DISABLED' | 'CHILD_UNLINKED'
): LinewizeEventsExceptionAction => ({
  type: 'LINEWIZE_EVENTS_EXCEPTION',
  payload: { status },
});

export const linewizeEventsErrorAction = (): LinewizeEventsErrorAction => ({
  type: 'LINEWIZE_EVENTS_ERROR',
});

export const linewizeEventsReceiveAction = (
  normalizedLinewizeEvents: Record<string, LinewizeEventRecord>
): LinewizeEventsReceiveAction => ({
  type: 'LINEWIZE_EVENTS_RECEIVE',
  payload: {
    records: {
      linewizeEvents: normalizedLinewizeEvents,
    },
  },
});

export const linewizeEventsResetAction = (): LinewizeEventsResetAction => ({
  type: 'LINEWIZE_EVENTS_RESET',
});

export const linewizeEventsRefreshAction = (): LinewizeEventsRefreshAction => ({
  type: 'LINEWIZE_EVENTS_REFRESH',
});

export const linewizeEventsChangeStatusAction = (
  status: LinewizeEventStatus
): LinewizeEventsChangeStatusAction => ({
  type: 'LINEWIZE_EVENTS_CHANGE_STATUS',
  payload: {
    status,
  },
});

export const linewizeEventChangeFilterAction = (
  filter: Array<LinewizeEventFilter>
) => ({
  type: 'LINEWIZE_EVENT_FILTER_CHANGE',
  payload: {
    filter,
  },
});
