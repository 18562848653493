import * as React from 'react';
import DrawerStepWrapper from '../../DrawerStepWrapper/DrawerStepWrapper';
import NavHeader from '../Headers/NavHeader';
import ButtonFooter from '../Footers/ButtonFooter';
import { Layout } from 'styleguide-react';

export interface RoutineStepWrapperHandlerProps<NextFnParams = unknown> {
  next?: (data: NextFnParams) => void;
  prev?: () => void;
  close: () => void;
  footerText?: string;
  footerButtonDisabled?: boolean;
  testId?: string;
  hasShadow?: boolean;
}

interface RoutineStepWrapperProps extends RoutineStepWrapperHandlerProps {
  children: React.ReactNode;
  fixedFooter?: boolean;
  footerContent?: React.ReactNode;
  footerAlert?: React.ReactNode;
}

const RoutineStepWrapper = ({
  next,
  close,
  prev,
  children,
  footerText,
  testId,
  hasShadow = false,
  footerButtonDisabled = false,
  fixedFooter = false,
  footerContent = null,
  footerAlert = null,
}: RoutineStepWrapperProps) => {
  return (
    <DrawerStepWrapper
      testId={testId}
      fixedFooter={fixedFooter}
      header={
        <NavHeader
          hasShadow={hasShadow}
          testId="routines-multi-step-nav-header"
          onClose={close}
          onPrev={prev}
        />
      }
      footer={
        <React.Fragment>
          {footerAlert && (
            <Layout marginLeft="24" marginRight="24">
              {footerAlert}
            </Layout>
          )}
          {next && footerText ? (
            <ButtonFooter
              testId="routines-multi-step-footer"
              text={footerText as string}
              onClick={next}
              disabled={footerButtonDisabled}
            />
          ) : null}
          {footerContent && (
            <Layout marginBottom="24" width="100%">
              {footerContent}
            </Layout>
          )}
        </React.Fragment>
      }
    >
      {children}
    </DrawerStepWrapper>
  );
};

export default RoutineStepWrapper;
