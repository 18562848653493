import { List, NonEmptyMapRecord, fromJS } from 'immutable';
import { VideoDetailsRecord } from '../../records/urlDetails';
import { YoutubeSummaryRecord } from '../../records/youtubeSummary';
import {
  FETCHING_YOUTUBE_SUMMARY,
  FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED,
  FETCH_YOUTUBE_SUMMARY_FAILED,
  RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  RECEIVE_YOUTUBE_SUMMARY,
  RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS,
  SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS,
  YOUTUBE_SUMMARY_ERROR,
} from './constants';
import { AnyError } from '../../helpers/errorHandling';

export const defaultState = {
  summary: YoutubeSummaryRecord(),
  lastVideos: List<VideoDetailsRecord>(),
  isLoadingLastVideos: false,
  isLoadingSummary: false,
  selectedLastVideo: undefined,
  hasMoreLastVideosToLoad: true,
  youtubeSummaryError: undefined,
};

export default function youtubeSummary(
  state: NonEmptyMapRecord<{
    summary: YoutubeSummaryRecord;
    lastVideos: List<VideoDetailsRecord>;
    isLoadingLastVideos: boolean;
    isLoadingSummary: boolean;
    selectedLastVideo?: VideoDetailsRecord;
    hasMoreLastVideosToLoad: boolean;
    youtubeSummaryError?: AnyError;
  }> = fromJS(defaultState),
  action
) {
  switch (action.type) {
    case FETCHING_YOUTUBE_SUMMARY:
      return state.merge({
        isLoadingSummary: true,
        youtubeSummaryError: undefined,
      });

    case RECEIVE_YOUTUBE_SUMMARY:
      return state.merge({
        isLoadingSummary: false,
        summary: action.payload.summary,
      });
    case YOUTUBE_SUMMARY_ERROR:
      return state.merge({
        isLoadingSummary: false,
        youtubeSummaryError: action.payload,
      });
    case FETCH_YOUTUBE_SUMMARY_FAILED:
      return state.merge(defaultState);
    case FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS:
      return state.set('isLoadingLastVideos', true);
    case RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS:
      return state.merge({
        lastVideos: action.payload.lastVideos,
        isLoadingLastVideos: false,
        hasMoreLastVideosToLoad: true,
      });
    case RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS:
      return state.merge({
        lastVideos: state.get('lastVideos').concat(action.payload.lastVideos),
        isLoadingLastVideos: false,
        hasMoreLastVideosToLoad: action.payload.lastVideos.size !== 0,
      });
    case SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS:
      return state.merge({
        selectedLastVideo: action.payload,
      });
    case FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED:
      return state.merge({
        isLoadingLastVideos: false,
      });
    default:
      return state;
  }
}
