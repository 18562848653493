import * as React from 'react';
import { TextField } from 'styleguide-react';

const ResetableTextField: React.FunctionComponent<{
  id: string;
  autoComplete: boolean;
  autoCorrect: boolean;
  autoCapitalize: boolean;
  autoFocus: boolean;
  spellCheck: any;
  input: any;
  type: 'text' | 'password' | 'email' | 'tel' | 'number';
  placeholder?: string;
  disabled?: boolean;
  onClick: (event: MouseEvent) => any;
  readOnly: boolean;
  icon: JSX.Element;
  testId?: string;
  meta: { touched: any; error: any };
  maxlength: number;
  onFocus: () => any;
  onBlur: () => any;
}> = ({
  id,
  autoComplete,
  autoCorrect,
  autoCapitalize,
  autoFocus,
  spellCheck,
  input,
  type,
  placeholder,
  disabled,
  onClick,
  readOnly,
  icon = null,
  testId,
  meta: { touched, error },
  maxlength,
  onFocus,
  onBlur,
}) => (
  <TextField
    {...input}
    id={id}
    autoComplete={autoComplete}
    autoCorrect={autoCorrect}
    autoCapitalize={autoCapitalize}
    autoFocus={autoFocus}
    spellCheck={spellCheck}
    placeholder={placeholder}
    type={type}
    disabled={disabled}
    onClick={onClick}
    readOnly={readOnly}
    maxLength={maxlength}
    error={touched && error}
    onFocus={onFocus}
    onBlur={onBlur}
    iconLeft={icon}
    testId={testId}
    block
    canBeCleared
    onChange={input.onChange}
    supportTextConfiguration={
      error && touched
        ? {
            text: error,
            allowedLines: 1,
          }
        : undefined
    }
  />
);

export default ResetableTextField;
