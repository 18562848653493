import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { UnifiedNowCardStatus } from '../../../ducks/unifiedNowCard';
import { cardStateMatcher } from './unifiedNowCardMatcher';
import UnifiedNowCardTitle from './cardComponents/UnifiedNowCardTitle';
import UnifiedNowCardIcon from './cardComponents/UnifiedNowCardIcon';
import UnifiedNowCardTitleInfo from './cardComponents/UnifiedNowCardTitleInfo';
import UnifiedNowCardSubtitle from './cardComponents/UnifiedNowCardSubtitle';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import UnifiedNowCardTime from './cardComponents/UnifiedNowCardTime';
import UnifiedNowCardPauseButton from './cardComponents/UnifiedNowCardPauseButton';
import UnifiedNowCardActions from './cardComponents/UnifiedNowCardActions';
import { ActionWrapper, UnifiedNowCardData } from './types';
import {
  SyntheticUnifiedNowCardState,
  UnifiedNowCardState,
} from '../../../businessLogic/unifiedNowCard/types';
import { isNil } from 'ramda';
import UnifiedNowCardWrapper from './cardComponents/UnifiedNowCardWrapper';
import UnifiedNowCardErrorStatus from './cardStatus/UnifiedNowCardErrorStatus';
import UnifiedNowCardLoadingStatus from './cardStatus/UnifiedNowCardLoadingStatus';
import { classNames } from '../../../helpers';
import { MILLISECONDS_IN_A_SECOND } from '../../../helpers/dates';

export interface UnifiedNowCardProps {
  nowCardState: UnifiedNowCardState;
  nowCardStatus: UnifiedNowCardStatus;
  unifiedNowCardConfigData: UnifiedNowCardData;
  pauseDuration: number;
  activeRoutineSwitchDuration: number;
  onLoad: () => void;
  actionWrapper: ActionWrapper;
  reportToAnalytics: (
    state: UnifiedNowCardState | SyntheticUnifiedNowCardState
  ) => void;
  updateNowCardOnFinishState: (durationMilliseconds: number) => void;
  cancelUpdateNowCardOnFinishState: () => void;
}

const pausedStatus: Array<UnifiedNowCardState> = [
  'SCHOOL_PAUSED',
  'INTERNET_PAUSED_MULTIPLE_DEVICES',
  'INTERNET_PAUSED_SINGLE_DEVICE',
  'SCHOOL_ACTIVE_INSIDE_DELEGATED_PAUSED',
];

const activeRoutineIsSwitched = (
  unifiedNowCardConfigData: UnifiedNowCardData,
  nowCardState: UnifiedNowCardState
) => {
  return (
    unifiedNowCardConfigData?.isActiveRoutineSwitched &&
    nowCardState.startsWith('ROUTINE')
  );
};

const UnifiedNowCard = ({
  nowCardState,
  nowCardStatus,
  unifiedNowCardConfigData,
  pauseDuration,
  activeRoutineSwitchDuration,
  onLoad,
  actionWrapper,
  reportToAnalytics,
  updateNowCardOnFinishState,
  cancelUpdateNowCardOnFinishState,
}: UnifiedNowCardProps) => {
  React.useEffect(() => {
    onLoad();
  }, []);

  React.useEffect(() => {
    if (nowCardStatus === 'LOADED') {
      // we calculate this state synthetically just for tracking purposes
      if (activeRoutineIsSwitched(unifiedNowCardConfigData, nowCardState)) {
        reportToAnalytics('ROUTINE_SWITCHED');
        return;
      }

      reportToAnalytics(nowCardState);
    }
  }, [nowCardStatus]);

  React.useEffect(() => {
    if (nowCardStatus === 'LOADED') {
      if (pausedStatus.includes(nowCardState)) {
        updateNowCardOnFinishState(pauseDuration);
      } else if (
        activeRoutineIsSwitched(unifiedNowCardConfigData, nowCardState)
      ) {
        // We add some delay to give backend some time to finish it's operations
        const delay = MILLISECONDS_IN_A_SECOND * 10; // 10s
        updateNowCardOnFinishState(activeRoutineSwitchDuration + delay);
      }
    }
    return () => {
      // clean up the timer
      cancelUpdateNowCardOnFinishState();
    };
  }, [nowCardState, nowCardStatus]);

  if (
    nowCardStatus === 'LOADING' ||
    (nowCardState === 'NON_CALCULATED' && nowCardStatus !== 'ERROR')
  ) {
    return <UnifiedNowCardLoadingStatus />;
  }

  if (nowCardStatus === 'ERROR') {
    return <UnifiedNowCardErrorStatus />;
  }

  const nowCardConfig = cardStateMatcher(
    nowCardState,
    unifiedNowCardConfigData,
    actionWrapper
  );

  const showRightInfo =
    !Number.isNaN(nowCardConfig.usedScreenTimePercentage) ||
    nowCardConfig.pauseButtonOnClick ||
    nowCardConfig.actionOptions;

  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screen => (
        <UnifiedNowCardWrapper
          type={nowCardConfig.type}
          shapes={nowCardConfig.shapes}
          gradient={nowCardConfig.gradient}
          testId={`par-unified-now-card--${nowCardState}`}
          className={classNames(
            `par-unified-now-card--${nowCardState.toLowerCase()}`,
            nowCardConfig.routineType
              ? `par-unified-now-card--routine-type-${nowCardConfig.routineType}`
              : ''
          )}
        >
          <FlexLayout mainaxis="row" crossaxisAlignment="center" flexGrow="1">
            {(screen === ScreenSize.Desktop ||
              screen === ScreenSize.Tablet) && (
              <UnifiedNowCardIcon
                iconType={nowCardConfig.iconType}
                iconColor={nowCardConfig.iconColor}
                iconClassName={nowCardConfig.iconClassName}
              />
            )}
            <FlexLayout
              mainaxis="row"
              mainaxisAlignment="space-between"
              width="100%"
            >
              <FlexLayout mainaxis="column">
                <FlexLayout
                  mainaxis="row"
                  className="par-unified-now-card__title-wrapper"
                >
                  <UnifiedNowCardTitle title={nowCardConfig.title} />
                  {nowCardConfig.onClickTitleInfo &&
                    (screen === ScreenSize.Desktop ||
                      screen === ScreenSize.Tablet) && (
                      <UnifiedNowCardTitleInfo
                        onClick={nowCardConfig.onClickTitleInfo}
                      />
                    )}
                </FlexLayout>
                <UnifiedNowCardSubtitle subtitle={nowCardConfig.subtitle} />
              </FlexLayout>
              {nowCardConfig.onClickTitleInfo &&
                screen === ScreenSize.Mobile && (
                  <UnifiedNowCardTitleInfo
                    onClick={nowCardConfig.onClickTitleInfo}
                  />
                )}
            </FlexLayout>
          </FlexLayout>
          {showRightInfo && (
            <FlexLayout
              mainaxis="row"
              crossaxisAlignment="center"
              className="par-unified-now-card__right-wrapper"
            >
              {!isNil(nowCardConfig.usedScreenTimePercentage) &&
                !isNil(nowCardConfig.screenTimeFormatted) && (
                  <UnifiedNowCardTime
                    screenTime={nowCardConfig.screenTimeFormatted}
                    usedScreenTimePercentage={
                      nowCardConfig.usedScreenTimePercentage
                    }
                    subtitle={nowCardConfig.timeSubtitle}
                    progressBarClassName="par-unified-now-card__time-bar--white"
                  />
                )}
              {nowCardConfig.pauseButtonOnClick &&
                nowCardConfig.pauseButtonIconType && (
                  <UnifiedNowCardPauseButton
                    iconType={nowCardConfig.pauseButtonIconType}
                    iconColor={nowCardConfig.pauseButtonIconColor}
                    onClick={nowCardConfig.pauseButtonOnClick}
                  />
                )}

              {nowCardConfig.actionOptions && (
                <UnifiedNowCardActions
                  buttonIcon={nowCardConfig.actionIconType}
                  options={nowCardConfig.actionOptions}
                />
              )}
            </FlexLayout>
          )}
        </UnifiedNowCardWrapper>
      )}
    </RenderWhen>
  );
};

export default UnifiedNowCard;
