import * as React from 'react';

const Loader: React.FunctionComponent<{
  size?: 'small' | 'big' | 'xs' | 'xxs';
  color?: string;
  className?: string;
}> = ({ size = 'small', color = 'brand', className = '' }) => {
  const loaderSize = `${size}`;
  const loaderColor = ` spinner--${color}`;
  return (
    <div className={`preloader-wrapper active ${loaderSize} ${className}`}>
      <div className={`spinner-layer ${loaderColor}`}>
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
