import * as React from 'react';
import { Drawer, FlexLayout } from 'styleguide-react';
import { CreateRoutineMultiStepProps } from '../RoutinesMultiStep/CreateRoutineMultiStep';
import CreateRoutineMultiStepContainer from '../../../containers/Routines/CreateRoutineMultiStepContainer';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

export interface RoutinesDrawerMenuProps {
  profileId: string | null;
  activeStep: CreateRoutineMultiStepProps['activeStep'];
  isOpen: boolean;
  toggle: CreateRoutineMultiStepProps['closeDrawer'];
}

const RoutinesDrawerMenu = ({
  isOpen,
  profileId,
  toggle,
  activeStep,
}: RoutinesDrawerMenuProps) => {
  return (
    <Drawer from="right" isOpen={isOpen} className="par-routines-drawer-menu">
      <RenderWhen
        screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
      >
        {screenSize => (
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            crossaxisAlignment="center"
            width={screenSize === ScreenSize.Mobile ? '100vw' : '440'}
            className="par-routines-drawer-menu__multi-step"
          >
            <CreateRoutineMultiStepContainer
              profileId={profileId}
              activeStep={activeStep}
              closeDrawer={toggle}
            />
          </FlexLayout>
        )}
      </RenderWhen>
    </Drawer>
  );
};

export default RoutinesDrawerMenu;
