import { connect } from 'react-redux';
import {
  setWebFilterActiveSection,
  editDomainException,
  editCategory,
} from '../../../ducks/profileRules';
import {
  getIsAllowUnknownSites,
  getIsSafeSearch,
  getIsReportBlockedSites,
  getIsBlockedUnsupportedBrowsers,
  getProfileId,
  getWebCategories,
  getWebFilterEnabled,
  getProfileRules,
  getWebFiltersActiveSection,
  getWebFilterDomainsSortedByAction,
  getEditDomainException,
  getEditCategory,
  getIsReportBlockedSummaryAlerts,
} from '../../../selectors/index';
import WebFilters from '../../../components/WebFilters/WebFilters';

import {
  WEBFILTER_CATEGORIES_SECTION,
  EDIT_CATEGORY_ALL,
  WebfilterCategoryAction,
  SettingsToggleNames,
} from '../../../constants';
import { DEFAULT_WEB_CATEGORIES } from '../../../records/profileRules';

import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import { WebRuleNames, trackWebRules } from '../../../helpers/analytics';
import State, { BaseThunk } from '../../../store/state';
import { webCategoryActionSheetClickInRules } from '../../../actions/ActionSheetActions';
import { getFeatureFlag } from '../../../selectors/featureFlags';
import { setLastRuleTypeModified, RuleType } from '../../../ducks/appRate';
import { push } from '../../../lib/wrappers';
import { APPThunk } from '../../../helpers/thunks';
import { handleToggleWebFilterSetting } from '../../../actions/WebFiltersActions';
import { requestFeaturesAction } from '../../../ducks/features/actions';
import { getFeatureByCode } from '../../../ducks/features/selectors';
import {
  ruleNeedsUpgrade,
  shouldHideRule,
} from '../../../businessLogic/rules/features';
import { FeatureCode } from '../../../records/features/types';

const onClickSettings = (dispatch, getState) => {
  dispatch(
    push(`/profiles/${getProfileId(getState())}/rules/webFilters-settings`)
  );
};

const mapStateToProps = (state: State, { params: { profileId } }) => {
  return {
    profileId,
    categoryRestrictionsEnabled: getWebFilterEnabled(state, profileId),
    categories: getWebCategories(state, profileId),
    domains: getWebFilterDomainsSortedByAction(state, profileId),
    activeSection: getWebFiltersActiveSection(state),
    editDomainException: getEditDomainException(state),
    editCategory: getEditCategory(state),
    isAllowUnknownSites: getIsAllowUnknownSites(state, profileId),
    isSafeSearch: getIsSafeSearch(state, profileId),
    isReportBlockedSites: getIsReportBlockedSites(state, profileId) as boolean,
    isReportBlockedSummaryAlerts: getIsReportBlockedSummaryAlerts(
      state,
      profileId
    ) as boolean,
    isBlockedUnsupportedBrowsers: getIsBlockedUnsupportedBrowsers(
      state,
      profileId
    ),
    isEnabledBlockUnsupportedBrowsers: getFeatureFlag(
      state,
      'blockUnsupportedBrowsers'
    ) as boolean,
    webBlockedAlertFeatureNeedUpgrade: ruleNeedsUpgrade(
      getFeatureByCode(state, 'web_blocked_alert')
    ),
    ruleNeedsUpgrade: (code: FeatureCode) =>
      ruleNeedsUpgrade(getFeatureByCode(state, code)),
    shouldHideRule: (code: FeatureCode) =>
      shouldHideRule(getFeatureByCode(state, code)),
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickSettings: () => dispatch(onClickSettings),
  setActiveSection: section => dispatch(setWebFilterActiveSection(section)),
  onToggleWebFilterEnabled: toggleOn => {
    dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
    dispatch(handleToggleWebFilter({ toggleOn, profileId }));
  },
  onClickDomainException: domain => dispatch(editDomainException(domain)),
  onClickCategory: category => dispatch(editCategory(category)),
  onClickBulkChange: () => dispatch(editCategory(EDIT_CATEGORY_ALL)),
  onEditCategories:
    (categories: any) => (action: WebfilterCategoryAction) => () => {
      dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
      dispatch(webCategoryActionSheetClickInRules(action, categories));
    },
  onResetCategories: () => {
    dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
    dispatch(resetCategories(profileId));
  },
  onToggleWebFilterSetting: (
    target: SettingsToggleNames,
    toggleOn: boolean,
    needLicenseUpgrade: boolean
  ) => {
    dispatch(setLastRuleTypeModified(RuleType.WebFiltering));
    dispatch(
      handleToggleWebFilterSetting({
        target,
        toggleOn,
        profileId,
        needLicenseUpgrade,
      })
    );
  },
});

export const handleToggleWebFilter =
  ({ toggleOn, profileId }): BaseThunk =>
  dispatch => {
    trackWebRules(
      toggleOn
        ? WebRuleNames.ENABLE_WEBSITE_CATEGORY_RESTRICTIONS
        : WebRuleNames.DISABLE_WEBSITE_CATEGORY_RESTRICTIONS
    );
    return dispatch(
      dispatchModifyProfileRules(profileId, toggleWebFilterEnabled(toggleOn))
    );
  };

export const resetCategories = profileId => dispatch => {
  dispatch(editCategory());

  return dispatch(
    dispatchModifyProfileRules(profileId, state =>
      getProfileRules(state, profileId).setIn(
        ['web', 'categories'],
        DEFAULT_WEB_CATEGORIES
      )
    )
  );
};

// toggleOn -> (state, profileId) -> profileRules
const toggleWebFilterEnabled = toggleOn => (state, profileId) =>
  getProfileRules(state, profileId).setIn(['web', 'isCategoryList'], toggleOn);

const WebFiltersCategoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFilters);

WebFiltersCategoryContainer.load = (): APPThunk => (dispatch, getState) => {
  // we only want to set categories as active if user doesn't come from a subview like for
  // example WebfilterSettings or AddDomainException
  const action = getState()
    ?.get('routing')
    ?.get('locationBeforeTransitions')?.action;

  // user used forward/back button
  if (action === 'PUSH') {
    dispatch(setWebFilterActiveSection(WEBFILTER_CATEGORIES_SECTION));
  }

  dispatch(requestFeaturesAction());
};

export default WebFiltersCategoryContainer;
