import * as R from 'ramda';
import { UserDevice, UserDeviceStatus } from '../../records/device';
import { isNotNilOrEmpty } from '../../helpers';
import { EventType } from '../../constants';
import { DeviceRecord } from '../../records/device/types/Device.types';

type DeviceForSN = {
  // Regular Device users are immutable; we want plain JS objects
  // in here due to ramda
  users: {
    status: Pick<UserDeviceStatus, 'safeNetwork'>;
    profileId: UserDevice['profileId'];
  }[];
};
type status = DeviceForSN['users'][number]['status']['safeNetwork']['status'];

/**
 * Returns whether there has been at least one device user from
 * the passed-in profile that has been in a safe network.
 */
export const profileHasBeenInSafeNetwork = (
  profileId: number,
  devices: DeviceForSN[]
): boolean =>
  Boolean(matchSafeNetworkStatus(profileId, devices, isNotNilOrEmpty));

/**
 * Returns whether there has been at least one device user from
 * this account that has been in a safe network.
 */
export const accountHasBeenInSafeNetwork = (devices: DeviceForSN[]): boolean =>
  Boolean(matchSafeNetworkStatus(null, devices, isNotNilOrEmpty));

/**
 * Returns the number of devices from the passed-in profile that
 * are currently disabled due to being in a safe network.
 */
export const numOfDevicesDisabledInSafeNetwork = (
  profileId: number,
  devices: DeviceForSN[]
): number => {
  const isSafeNetworkDisablingProtectionEvent = R.equals(
    EventType.SafeNetworkDisablingProtection
  ) as (value) => value is EventType.SafeNetworkDisablingProtection;

  return matchSafeNetworkStatus(
    profileId,
    devices,
    isSafeNetworkDisablingProtectionEvent
  );
};

const matchSafeNetworkStatus = (
  profileId: number | null,
  devices: DeviceForSN[],
  matcher: (value: status) => boolean
): number => {
  const userHasBeenInSafeNetwork = R.pipe(
    R.path(['status', 'safeNetwork', 'status']) as (
      user: DeviceForSN['users'][number]
    ) => status,
    matcher
  );

  const deviceUsersOfDeviceHaveBeenInSafeNetwork = R.pipe(
    R.prop('users') as (device: DeviceForSN) => DeviceForSN['users'],
    R.when(
      () => !R.isNil(profileId),
      R.filter(R.propEq('profileId', profileId))
    ),
    R.any(userHasBeenInSafeNetwork)
  );

  const numOfDevicesBeingInSafeNetwork = R.pipe(
    R.map(deviceUsersOfDeviceHaveBeenInSafeNetwork),
    R.map(Number),
    R.reduce<number, number>(R.add, 0)
  );
  return numOfDevicesBeingInSafeNetwork(devices);
};

/**
 * Returns whether all the device users from the passed-in profile
 * are currently disabled due to being in a safe network.
 */
export const areAllDevicesDisabledInSafeNetwork = (
  profileId: number,
  devices: DeviceForSN[]
): boolean =>
  devices.length === numOfDevicesDisabledInSafeNetwork(profileId, devices);

/**
 * Return an array with all the user devices
 * which are currently connected to a Safe network
 */
export const getAllProfileDevicesByStatus = (
  profileId: number,
  devices: DeviceRecord[]
): DeviceRecord[] => {
  return devices.filter(device => {
    const { users } = device;
    const user = users.find(user => user.profileId === profileId);
    return (
      user &&
      user.status.safeNetwork.status ===
        EventType.SafeNetworkDisablingProtection
    );
  });
};
