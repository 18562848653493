import * as camelize from 'camelize';
import { TOAST_ICON_TICK, showToast, TOAST_ICON_WARNING } from '../toast';
import State, { Dispatch } from '../../store/state';
import api from '../../api';
import { t } from '../../lib/i18n';
import { getInvitation } from './invitationsSelector';
import { goBackIfHistory } from '../routing';
import { track, Events } from '../../helpers/analytics';
import { pushRelativeLocation } from '../../actions/Navigation';
import {
  hasSeenAdditionalParentWelcomeModal,
  isSlaveAccount,
  getAccountUid,
} from '../../selectors/account';
import { setAdditionalParentWelcomeModalViewed } from '../account';
import { logout } from '../../sideEffects/logout';

export const CREATE_INVITATION_STARTED = 'CREATE_INVITATION_STARTED';
export const CREATE_INVITATION_SUCCEED = 'CREATE_INVITATION_SUCCEED';
export const CREATE_INVITATION_FAILED = 'CREATE_INVITATION_FAILED';

export const createInvitation =
  (_email: string) => async (dispatch: Dispatch) => {
    const email = _email.toLowerCase();

    dispatch({ type: CREATE_INVITATION_STARTED });

    try {
      const newInvitation = await api.invitations.post({ email });
      track(Events.InvitationSent, {
        email,
      });
      dispatch({
        type: CREATE_INVITATION_SUCCEED,
        payload: camelize(newInvitation),
      });
      dispatch(showToast(t('Invitation sent'), TOAST_ICON_TICK));
    } catch (err) {
      dispatch({ type: CREATE_INVITATION_FAILED });
      dispatch(
        showToast(
          t('Something went wrong. Please try again'),
          TOAST_ICON_WARNING
        )
      );
    }
  };

export const REMOVE_INVITATION_STARTED = 'REMOVE_INVITATION_STARTED';
export const REMOVE_INVITATION_SUCCEED = 'REMOVE_INVITATION_SUCCEED';
export const REMOVE_INVITATION_FAILED = 'REMOVE_INVITATION_FAILED';

export const removeInvitation =
  () => async (dispatch: Dispatch, getState: () => State) => {
    dispatch({ type: REMOVE_INVITATION_STARTED });
    dispatch(goBackIfHistory());

    try {
      const invitation = getInvitation(getState())!;

      if (invitation.status === 'accepted') {
        await api.accessProfile.delete({
          uid: invitation.invitedAccessProfile!.uid,
        });
      } else {
        await api.invitation.delete({ uid: invitation.uid });
      }

      track(
        {
          pending: Events.RemovedPendingInvitation,
          expired: Events.RemoveExpiredInvitation,
          accepted: Events.RemovedAdditionalParent,
        }[invitation.status],
        {
          email:
            invitation.invitedAccessProfile?.email ??
            invitation.invitationEmail,
        }
      );

      dispatch({ type: REMOVE_INVITATION_SUCCEED, payload: invitation.uid });
      dispatch(
        showToast(
          invitation.status === 'pending' || invitation.status === 'expired'
            ? t('Invitation canceled')
            : t('Additional parent removed'),
          TOAST_ICON_TICK
        )
      );
    } catch (err) {
      dispatch({ type: REMOVE_INVITATION_FAILED });
      dispatch(
        showToast(
          t('Something went wrong. Please try again'),
          TOAST_ICON_WARNING
        )
      );
    }
  };

export const FETCH_INVITATIONS_STARTED = 'FETCH_INVITATIONS_STARTED';
export const FETCH_INVITATIONS_SUCCEED = 'FETCH_INVITATIONS_SUCCEED';
export const FETCH_INVITATIONS_FAILED = 'FETCH_INVITATIONS_FAILED';

export const fetchInvitations = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_INVITATIONS_STARTED });

  try {
    const invitations = await api.invitations
      .get({})
      .then(res => res.items_list.map(camelize));
    dispatch({ type: FETCH_INVITATIONS_SUCCEED, payload: invitations });
  } catch (err) {
    dispatch({ type: FETCH_INVITATIONS_FAILED });
  }
};

export const welcomeNewAdditionalParent =
  () => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();

    if (isSlaveAccount(state) && !hasSeenAdditionalParentWelcomeModal(state)) {
      dispatch(pushRelativeLocation(`modal/AdditionalParentWelcomeModal`));

      dispatch(setAdditionalParentWelcomeModalViewed(true));
      api.guestAccountOptions.post({
        additional_parent_welcome_modal_viewed: 'true',
      });
    }
  };

export const closeAdditionalParentAccount =
  () => async (dispatch: Dispatch, getState: () => State) => {
    const accountUid = getAccountUid(getState());

    await api.accessProfile.delete({ uid: accountUid });

    track(Events.LeftAdditionalParentAccount);

    dispatch(logout());
  };
