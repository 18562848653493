import { connect } from 'react-redux';
import { fetchUrlDetails, showDetailsActions } from '../ducks/urlDetails';
import {
  getEventByKey,
  getLocale,
  getProfileVideoRule,
  getShowDetailsAction,
  getShowWebfilterEvent,
  getShowYoutubeEvent,
  getTimezone,
  getUrlDetailsByEventKey,
  isUrlDetailsFetching,
} from '../selectors';
import UrlDetailsPage from '../components/UrlDetails/UrlDetailsPage';
import { EventRecord, UrlDetailsRecord } from '../records';
import { DetailsItemRecord, VideoDetailsRecord } from '../records/urlDetails';
import {
  changeRules,
  navigateToExternalUrl,
} from '../actions/UrlDetailsActions';
import {
  webEventActionSheetClick,
  youtubeDetailsActionSheetClick,
} from '../actions/ActionSheetActions';
import {
  ISOLanguage,
  LINK_ACCOUNT_SETTINGS,
  UrlDetailsAction,
} from '../constants';

import State from '../store/state';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import * as qinit from '../qinit';

type Props = { params: { profileId: string; eventKey: string } };

const mapStateToProps = (
  state: State,
  { params: { eventKey, profileId } }: Props
): {
  isFetching: boolean;
  event: EventRecord | undefined;
  urlDetails: UrlDetailsRecord;
  webFilterEvent?: EventRecord | undefined;
  youtubeEvent?: EventRecord | undefined;
  detailsActionSelected?: DetailsItemRecord | undefined;
  rule: UrlDetailsAction | undefined;
  timezone: string;
  locale: ISOLanguage;
} => {
  const eventKeyDecoded = decodeURI(eventKey);
  const detailsActionSelected = getShowDetailsAction(state);
  let rule: UrlDetailsAction | undefined;
  if (detailsActionSelected instanceof VideoDetailsRecord) {
    const videoDetail = detailsActionSelected as VideoDetailsRecord;
    rule = getProfileVideoRule(videoDetail.source, videoDetail.videoId)(
      state,
      profileId
    );
  }

  return {
    isFetching: isUrlDetailsFetching(state),
    event: getEventByKey(state, eventKeyDecoded),
    urlDetails:
      getUrlDetailsByEventKey(state, eventKeyDecoded) || UrlDetailsRecord(),
    webFilterEvent: getShowWebfilterEvent(state),
    youtubeEvent: getShowYoutubeEvent(state),
    detailsActionSelected,
    rule,
    timezone: getTimezone(state),
    locale: getLocale(state) ?? ISOLanguage.english,
  };
};

const mapDispatchToProps = (dispatch, { params: { eventKey } }: Props) => ({
  onClickRuleOptions: event => () => dispatch(changeRules(event)),
  onClickPage: page => () => dispatch(navigateToExternalUrl(eventKey, page)),
  onWebActionSheetClick: () => action => () =>
    dispatch(webEventActionSheetClick(action)),
  onClickAction: item => () => dispatch(showDetailsActions(item)),
  onClickScreenshot: () => dispatch(navigateToExternalUrl(eventKey, null)),
  onDetailActionSheetClick: urlVideoItem => action => () =>
    dispatch(
      youtubeDetailsActionSheetClick({
        action,
        eventKey,
        event: urlVideoItem,
      })
    ),
  onClickAdjustSettings: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(navigate({ type: 'inner', src: LINK_ACCOUNT_SETTINGS }));
  },
  onClickReadHelpArticle: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'external:public:site',
        src: qinit.tenant.marketing.public_site.help_safe_networks,
      })
    );
  },
});

const UrlDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UrlDetailsPage);

UrlDetailsContainer.load =
  ({ profileId, eventKey }: { profileId: string; eventKey: string }) =>
  dispatch =>
    dispatch(fetchUrlDetails(parseInt(profileId, 10), decodeURI(eventKey)));

export default UrlDetailsContainer;
