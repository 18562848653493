import { topBarProfileNotificationTrackingStates } from '../../components/ProfileNotificationTopBar/types';
import {
  ProfileNotificationTopBarLoadedAction,
  ProfileNotificationTopBarLoadingAction,
} from './types';

export const profileNotificationTopBarLoading =
  (): ProfileNotificationTopBarLoadingAction => ({
    type: 'PROFILE_NOTIFICATION_TOP_BAR_LOADING',
  });

export const profileNotificationTopBarLoaded =
  (): ProfileNotificationTopBarLoadedAction => ({
    type: 'PROFILE_NOTIFICATION_TOP_BAR_LOADED',
  });

export const profileNotificationTopBarLoadedState = (
  state: topBarProfileNotificationTrackingStates
) => ({
  type: 'PROFILE_NOTIFICATION_TOP_BAR_LOADED_STATE',
  payload: {
    state,
  },
});
