import * as React from 'react';
import {
  AlertBox,
  Button,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  Typography,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import type { DelegationRuleInfoModalProps } from '../../containers/Modal/DelegationRuleInfoModalContainer';

const DelegationRuleInfoModal = ({
  onClickClose,
}: DelegationRuleInfoModalProps) => {
  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={onClickClose}
          size={ButtonSize.medium}
          testId="delegation-rule-info-modal__confirm-button"
        >
          {t('Got it')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="delegation-rule-info-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" alignSelf="center">
          <Layout marginTop="16" marginBottom="24" textAlignement="center">
            <Icon
              type={IconType.phoneLaptop}
              color="secondary"
              size="x3"
              circle
            />
          </Layout>
          <Layout textAlignement="center">
            <Typography type="h5" weight="semibold" align="center">
              {t('Apply rules on school-issued devices')}
            </Typography>
            <Layout marginBottom="32" textAlignement="center">
              <Typography type="body2" align="center">
                {t(
                  'Set up web filtering, daily time limits, and routines to manage devices outside of school hours.'
                )}
              </Typography>
            </Layout>
            <AlertBox type="info">
              {t('Safe Search and school web filters will still apply.')}
            </AlertBox>
          </Layout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default DelegationRuleInfoModal;
