import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  combineLatest,
  from,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  forkJoin,
} from 'rxjs';
import type { AppEpic } from '../../../epics/types';
import type {
  RoutineStatusAction,
  RoutineSchedulesRequestAction,
  RoutineSchedulesReceiveAction,
  CreateRoutineScheduleAction,
  RoutineScheduleDetailsReceiveAction,
  RoutineBulkSchedulesRequestAction,
} from '../types';
import { APIError } from '../../../lib/errors';
import { showErrorAlert } from '../../../helpers/errorHandling';
import {
  receiveRoutineScheduleDetails,
  receiveRoutineSchedules,
  setRoutineSchedulesStatus,
} from '../actions';
import { ScheduleOperations } from '../../../records/routines/schedule/schedule';
import { SchedulePayload } from '../../../records/routines/schedule/types/Schedule.types';
import { normalizeRoutinesData } from '../transformations';
import { CollectionResponse } from '../../../types/api';
import {
  ttl10Seconds,
  ttlZero,
} from '../../../lib/QApiCache/commonCacheStrategies';
import { Action } from 'redux';

const concurrent = 10;
export const requestRoutineSchedulesEpic: AppEpic<
  RoutineSchedulesRequestAction,
  RoutineSchedulesReceiveAction | RoutineStatusAction
> = (actions$, _state$, { api }) => {
  const request$ = actions$.pipe(ofType('ROUTINE_SCHEDULES_REQUEST'));

  const loadingActions = request$.pipe(
    map(() => setRoutineSchedulesStatus('read', 'loading'))
  );

  const requestActions = request$.pipe(
    ofType('ROUTINE_SCHEDULES_REQUEST'),
    mergeMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, routineUid } }) =>
          combineLatest([
            of({ routineUid }),
            api.routinesSchedules
              .withCache(ttl10Seconds)
              .get<CollectionResponse<SchedulePayload>>({
                profileUid,
                routineUid,
              }),
          ])
        ),
        mergeMap(([{ routineUid }, { items_list: schedulesPayload }]) => {
          const schedules = schedulesPayload.map(schedule =>
            ScheduleOperations.fromPayload({
              ...schedule,
              routine_uid: routineUid,
            })
          );

          return from([
            setRoutineSchedulesStatus('read', 'success'),
            receiveRoutineSchedules(normalizeRoutinesData(schedules, 'uid')),
          ]);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutineSchedulesStatus('read', 'error'));
        })
      );
    }, concurrent)
  );

  return merge(loadingActions, requestActions);
};

export const createRoutineSchedulesEpic: AppEpic<
  CreateRoutineScheduleAction,
  RoutineScheduleDetailsReceiveAction | RoutineStatusAction | Action
> = (actions$, _state$, { api }) => {
  const createActions = actions$.pipe(ofType('ROUTINE_SCHEDULES_CREATE'));

  const loadingActions = createActions.pipe(
    map(() => setRoutineSchedulesStatus('create', 'loading'))
  );

  const requestActions = createActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, routineUid, body, onSuccess } }) =>
          combineLatest([
            of({ routineUid, onSuccess }),
            api.routinesSchedules.post<SchedulePayload>(body, null, {
              routineUid,
              profileUid,
            }),
          ])
        ),
        mergeMap(([{ routineUid, onSuccess }, schedulePayload]) => {
          const defaultActions = [
            setRoutineSchedulesStatus('create', 'success'),
            receiveRoutineScheduleDetails(
              schedulePayload?.uid,
              ScheduleOperations.fromPayload({
                ...schedulePayload,
                routine_uid: routineUid,
              })
            ),
          ];

          if (onSuccess && typeof onSuccess === 'function') {
            return from([...defaultActions, ...onSuccess()]);
          }

          return from(defaultActions);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutineSchedulesStatus('create', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

/** Epic to request schedules for multiple routines at once. */
export const requestBulkRoutinesSchedulesEpic: AppEpic<
  RoutineBulkSchedulesRequestAction,
  RoutineSchedulesReceiveAction | RoutineStatusAction
> = (actions$, _state$, { api }) => {
  const actions = actions$.pipe(ofType('ROUTINE_BULK_SCHEDULES_REQUEST'));
  const loadingActions = actions.pipe(
    map(() => setRoutineSchedulesStatus('read', 'loading'))
  );

  const requestActions = actions.pipe(
    switchMap(action =>
      of(action).pipe(
        switchMap(({ payload: { routineUids, purgeCache, profileUid } }) => {
          if (!profileUid) {
            throw new Error(
              'No profile uid was provided to fetch routine schedules'
            );
          }

          // make N api calls in parallel and wait for all of them to finish
          return forkJoin(
            routineUids.reduce<
              Record<string, Promise<CollectionResponse<SchedulePayload>>>
            >(
              (acc, routineUid) => ({
                ...acc,
                [routineUid]: api.routinesSchedules
                  .withCache(purgeCache ? ttlZero : ttl10Seconds)
                  .get<CollectionResponse<SchedulePayload>>({
                    profileUid,
                    routineUid,
                  }),
              }),
              {}
            )
          );
        }),
        mergeMap(routineScheduleResponsesObject => {
          const responsesList = Object.entries(routineScheduleResponsesObject);
          const recieveSchedulePayloadEvents = responsesList.map(
            ([routineUid, { items_list: schedulePayload }]) => {
              const schedules = schedulePayload.map(schedule =>
                ScheduleOperations.fromPayload({
                  ...schedule,
                  routine_uid: routineUid,
                })
              );
              return receiveRoutineSchedules(
                normalizeRoutinesData(schedules, 'uid')
              );
            }
          );
          return from([
            ...recieveSchedulePayloadEvents,
            setRoutineSchedulesStatus('read', 'success'),
          ]);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutineSchedulesStatus('read', 'error'));
        })
      )
    )
  );

  return merge(loadingActions, requestActions);
};

const routineSchedulesEpic = combineEpics(
  requestRoutineSchedulesEpic,
  requestBulkRoutinesSchedulesEpic,
  createRoutineSchedulesEpic
);

export default routineSchedulesEpic;
