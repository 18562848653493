import { List } from 'immutable';
import { ActivityRecord } from '../../records/activity';
import { MinutesPerHourActivityRecord } from '../../records/activity/types/MinutesPerHourActivity.types';
import { HoursPerDayActivityRecord } from '../../records/activity/types/HoursPerDayActivity.types';
import {
  RECEIVE_SUMMARY,
  REFRESH_SUMMARY_PER_HOURS,
  REQUEST_SUMMARY,
  REQUEST_SUMMARY_PER_HOURS,
  RESET_SUMMARY,
  RECEIVE_SUMMARY_PER_HOURS,
  REQUEST_SUMMARY_PER_DAYS,
  REFRESH_SUMMARY_PER_DAYS,
  RECEIVE_SUMMARY_PER_DAYS,
  REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY,
  RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY,
  REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY,
  RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY,
  CHANGE_DATE_RANGE,
  LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY,
  LOAD_WIDGET_BLOCKED_WEB_ACTIVITY,
  LOAD_WIDGET_APPS_WEB_ACTIVITY,
  LOAD_SEARCH_ACTIVITY,
  LOAD_CALL_AND_SMS_ACTIVITY,
  SELECTED_PAST_DATE_CHANGED,
  SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR,
  SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR,
  SUMMARY_SCREEN_TIME_ACTIVITY_ERROR,
  RECEIVE_TODAY_SUMMARY_PER_HOURS,
} from './constants';
import { LoadPayload } from './types';
import { AnyError } from '../../helpers/errorHandling';

export const resetSummary = () => ({
  type: RESET_SUMMARY,
});

export const requestSummary = () => ({
  type: REQUEST_SUMMARY,
});

export const receiveSummary = payload => ({
  type: RECEIVE_SUMMARY,
  payload: payload[0],
});

export const requestSummaryPerHours = () => ({
  type: REQUEST_SUMMARY_PER_HOURS,
});

export const refreshSummaryPerHours = () => ({
  type: REFRESH_SUMMARY_PER_HOURS,
});

export const receiveSummaryPerHours = (
  payload: List<MinutesPerHourActivityRecord>
) => ({
  type: RECEIVE_SUMMARY_PER_HOURS,
  payload,
});

export const receiveTodaysSummaryPerHours = (
  payload: List<MinutesPerHourActivityRecord>
) => ({
  type: RECEIVE_TODAY_SUMMARY_PER_HOURS,
  payload,
});

export const requestSummaryPerDays = () => ({
  type: REQUEST_SUMMARY_PER_DAYS,
});

export const refreshSummaryPerDays = () => ({
  type: REFRESH_SUMMARY_PER_DAYS,
});

export const receiveSummaryPerDays = (
  payload: List<HoursPerDayActivityRecord>
) => ({
  type: RECEIVE_SUMMARY_PER_DAYS,
  payload,
});

export const requestSummaryAppsAndWebActivity = () => ({
  type: REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY,
});

export const receiveSummaryAppsAndWebActivity = (
  payload: List<ActivityRecord>
) => ({
  type: RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY,
  payload,
});

export const summaryAppsAndWebsActivityError = (payload: AnyError) => ({
  type: SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR,
  payload,
});

export const requestSummaryBlockedWebActivity = () => ({
  type: REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY,
});

export const receiveSummaryBlockedWebActivity = (
  payload: List<ActivityRecord>
) => ({
  type: RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY,
  payload,
});

export const summaryBlockedWebActivityError = (payload: AnyError) => ({
  type: SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR,
  payload,
});
export const changeDateRange = payload => ({
  type: CHANGE_DATE_RANGE,
  payload,
});

export const screenTimeActivityError = (payload: AnyError) => ({
  type: SUMMARY_SCREEN_TIME_ACTIVITY_ERROR,
  payload,
});

export const loadScreenTimeActivityCard = (payload: LoadPayload) => ({
  type: LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY,
  payload,
});

export const loadBlockedWebActivityCard = (payload: LoadPayload) => ({
  type: LOAD_WIDGET_BLOCKED_WEB_ACTIVITY,
  payload,
});

export const loadAppsAndWebActivityCard = (payload: LoadPayload) => ({
  type: LOAD_WIDGET_APPS_WEB_ACTIVITY,
  payload,
});

export const loadSearchActivityCard = (payload: LoadPayload) => ({
  type: LOAD_SEARCH_ACTIVITY,
  payload,
});

export const loadCallAndSmsActivityCard = (payload: LoadPayload) => ({
  type: LOAD_CALL_AND_SMS_ACTIVITY,
  payload,
});

export const selectedPastDateChanged = (payload: string) => ({
  type: SELECTED_PAST_DATE_CHANGED,
  payload,
});
