import * as React from 'react';
import { Button, ButtonType, ButtonSize, Typography } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import familyActivitiesImg from '../../../assets/base/images/wellbeing/family-activities.png';
import { WellbeingUnsubscribedSection } from './WellbeingUnsubscribedSection';

interface FamilyActivitiesProps {
  onClickButton: () => void;
}

const FamilyActivities = ({ onClickButton }: FamilyActivitiesProps) => (
  <RenderWhen screenSize={[ScreenSize.Desktop, ScreenSize.MobileOrTablet]}>
    {screenSize => {
      const isDesktop = () => screenSize === ScreenSize.Desktop;
      return (
        <WellbeingUnsubscribedSection
          screenSize={screenSize}
          classPrefix="par-wellbeing-activities-unsubscribed"
          desktopTitle={
            <Typography
              type="title1"
              weight="semibold"
              marginBottom="0"
              align="left"
            >
              {t('Your new favorite family ritual')}
            </Typography>
          }
          mobileTitle={
            <Typography
              type="title3"
              weight="semibold"
              marginBottom="0"
              align="center"
            >
              {t('Family wellbeing activities')}
            </Typography>
          }
          subtitle={t('WELLBEING ACTIVITIES')}
          description={
            <Typography type="body1">
              {t(
                'Receive weekly activities straight to your inbox to help you create meaningful moments with your children away from screens.'
              )}
            </Typography>
          }
          image={
            <img
              src={familyActivitiesImg}
              alt=""
              width={isDesktop() ? 130 : 77.5}
              height={isDesktop() ? 124 : 74}
              className="par-wellbeing-activities-unsubscribed__img"
            />
          }
          button={
            <Button
              buttonType={ButtonType.plain}
              size={ButtonSize.medium}
              centered
              block={false}
              onClick={onClickButton}
              className="par-wellbeing-activities-unsubscribed__btn"
            >
              {t('Find out more')}
            </Button>
          }
        />
      );
    }}
  </RenderWhen>
);

export default FamilyActivities;
