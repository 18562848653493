import * as React from 'react';
import { connect } from 'react-redux';
import { Icon, IconType, Svg } from 'styleguide-react';
import { t } from '../../lib/i18n';
import State, { Dispatch, BaseThunk } from '../../store/state';
import {
  getCallsAndSmsActivity,
  getSearchActivity,
  isFetchingMoreSocialActivity,
  getActiveDateRange,
  getSummaryAppsAndWebActivity,
  getSummaryBlockedWebActivity,
} from '../../selectors';
import {
  navigateToTopActivity,
  navigateToBlockedWebActivity,
  navigateToCallsAndSmsActivity,
  navigateToSearchActivity,
  openSummaryCardInfoModalFor,
} from '../../actions/ProfileActions';
import { activitySwiperIndexChanged } from '../../ducks/ui';
import { DisplayUnit } from '../../components/ActivitySummary/ActivityList';
import ActivitySummaryList from '../../components/ActivitySummary/ActivitySummaryList';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { ActivityType } from '../../components/ActivitySummary/sharedModels';

import { fetchMoreSearches } from '../../ducks/socialActivity';
import { track, Events } from '../../helpers/analytics';
import AppWebActivityIcon from '../../components/Icons/summary/AppWebActivityIcon';
import BlockedActivityIcon from '../../components/Icons/summary/BlockedActivityIcon';
import CallsSMSIcon from '../../components/Icons/summary/CallsSMSIcon';
import SearchesIcon from '../../components/Icons/summary/SearchesIcon';
import { isCombinedDashboardProfile } from '../Dashboard/helpers/profileDashboardHelpers';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { shouldBlockThisFeature } from '../../helpers/premiumFeatures';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { navigateToCallsAndSmsAppRules } from '../../actions/Navigation';

interface Props {
  profile: ProfileRecord;
  type: ActivityType;
  hasExpandLink?: boolean;
  showAllAvailableActivity?: boolean;
  displayAll?: boolean;
}

const getSummaryDetails = (state: State, type: ActivityType) => {
  const title = {
    [ActivityType.AppsAndWebActivity]: t('Apps and Web Activity'),
    [ActivityType.BlockedWebs]: t('Blocked web activity'),
    [ActivityType.CallsAndSms]: t('Calls & messages'),
    [ActivityType.Search]: t('Search activity'),
    [ActivityType.QuestionableWebSearches]: t('Questionable Web Searches'),
  }[type];

  const iconEmptyCard = {
    [ActivityType.AppsAndWebActivity]: (
      <Svg height="64" width="64" color="gray-light">
        <AppWebActivityIcon />
      </Svg>
    ),
    [ActivityType.BlockedWebs]: (
      <Svg height="64" width="64" color="gray-light">
        <BlockedActivityIcon />
      </Svg>
    ),
    [ActivityType.CallsAndSms]: (
      <Svg height="64" width="64" color="gray-light">
        <CallsSMSIcon />
      </Svg>
    ),
    [ActivityType.Search]: (
      <Svg height="64" width="64" color="gray-light">
        <SearchesIcon />
      </Svg>
    ),
    [ActivityType.QuestionableWebSearches]: (
      <Svg height="64" width="64" color="gray-light">
        <SearchesIcon />
      </Svg>
    ),
  }[type];

  const icon = {
    [ActivityType.AppsAndWebActivity]: <Icon type={IconType.chartBar} />,
    [ActivityType.BlockedWebs]: <Icon type={IconType.globe} />,
    [ActivityType.CallsAndSms]: <Icon type={IconType.phoneAlt} />,
    [ActivityType.Search]: <Icon type={IconType.search} />,
    [ActivityType.QuestionableWebSearches]: (
      <Icon type={IconType.searchMinus} />
    ),
  }[type];

  const displayUnit = {
    [ActivityType.AppsAndWebActivity]: DisplayUnit.Minutes,
    [ActivityType.BlockedWebs]: DisplayUnit.Minutes,
    [ActivityType.CallsAndSms]: DisplayUnit.Minutes,
    [ActivityType.Search]: DisplayUnit.Minutes,
    [ActivityType.QuestionableWebSearches]: DisplayUnit.Minutes,
  }[type];

  const activityList = {
    [ActivityType.AppsAndWebActivity]: () =>
      getSummaryAppsAndWebActivity(state),
    [ActivityType.BlockedWebs]: () => getSummaryBlockedWebActivity(state),
    [ActivityType.CallsAndSms]: () => getCallsAndSmsActivity(state),
    [ActivityType.Search]: () => getSearchActivity(state),
    [ActivityType.QuestionableWebSearches]: () => getSearchActivity(state),
  }[type]();

  return { title, iconEmptyCard, icon, displayUnit, activityList };
};

const getFooterText = (
  state: State,
  type: ActivityType
): string | undefined => {
  if (type === ActivityType.CallsAndSms) {
    const licenseSubtype = getLicenseSubtype(state);
    if (shouldBlockThisFeature('callsSMSRules', licenseSubtype)) {
      return t('Upgrade to see more');
    }
  }
  return undefined;
};

const isFeaturePremium = (state: State, type: ActivityType) => {
  const licenseSubtype = getLicenseSubtype(state);
  switch (type) {
    case ActivityType.CallsAndSms:
      return shouldBlockThisFeature('callsSMSRules', licenseSubtype);
    default:
      return false;
  }
};

const mapStateToProps = (
  state: State,
  { profile, type, showAllAvailableActivity = false }: Props
) => {
  const { title, iconEmptyCard, icon, displayUnit, activityList } =
    getSummaryDetails(state, type);
  const footerText = getFooterText(state, type);

  const needsPagination =
    type === ActivityType.Search ||
    type === ActivityType.QuestionableWebSearches;

  const isCombinedProfile = isCombinedDashboardProfile(state, profile.id);
  const isDelegatedProfile = isProfileDelegatedToThisAccount(
    isDelegationEnabled(state),
    isStudentOptInForDelegationForThisAccount(state),
    profile.isLinewizestudentLinked
  );

  return {
    profileUid: profile.uid,
    type,
    title,
    iconEmptyCard,
    icon,
    displayUnit,
    footerText,
    isFeaturePremium: isFeaturePremium(state, type),
    showAllAvailableActivity,
    needsPagination,
    activeDateRange: getActiveDateRange(state),
    isFetching: isFetchingMoreSocialActivity(state),
    noActivityText: getTextIfNoActivityAtCard(
      type,
      profile.name,
      isCombinedProfile,
      isDelegatedProfile
    ),
    items: showAllAvailableActivity
      ? activityList
      : filterItemsByType(activityList, type),
  };
};

const filterItemsByType = (items, type: ActivityType) => {
  const numItemsByType =
    {
      [ActivityType.QuestionableWebSearches]: 6,
    }[type] || 5;

  return items.take(numItemsByType);
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profile, type, hasExpandLink = true }: Props
) => {
  return {
    onExpand: hasExpandLink
      ? (isFeaturePremium?: boolean) =>
          dispatch(
            handleOnExpand({ profileId: profile.id, type, isFeaturePremium })
          )
      : undefined,
    handleMore: (limit: string) =>
      dispatch(fetchMoreSearches(profile.uid.toString(), limit)),
    goToInfoModal: () => {
      dispatch(handleGoToInfoModal(type));
    },
  };
};

export const handleGoToInfoModal =
  (type): BaseThunk<void> =>
  dispatch => {
    const trackingHandlerFor = {
      [ActivityType.AppsAndWebActivity]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Apps and Web Activity',
        });
      },
      [ActivityType.Top]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Top Activity',
        });
      },
      [ActivityType.Questionable]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Questionable Activity',
        });
      },
      [ActivityType.CallsAndSms]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Calls & SMS Activity',
        });
      },
      [ActivityType.Search]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Search Activity',
        });
      },
      [ActivityType.QuestionableWebSearches]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Questionable Web Searches Activity',
        });
      },
      [ActivityType.BlockedWebs]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Blocked Webs Activity',
        });
      },
    };
    trackingHandlerFor[type]();
    dispatch(openSummaryCardInfoModalFor(type));
  };

export const handleOnExpand =
  ({ profileId, type, isFeaturePremium }): BaseThunk<void> =>
  dispatch => {
    const expandHandlersForActivityTypes = {
      [ActivityType.AppsAndWebActivity]: () => {
        dispatch(activitySwiperIndexChanged(1));
        dispatch(navigateToTopActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Apps and web activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.BlockedWebs]: () => {
        dispatch(activitySwiperIndexChanged(2));
        dispatch(navigateToBlockedWebActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Blocked web activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.Search]: () => {
        dispatch(activitySwiperIndexChanged(4));
        dispatch(navigateToSearchActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Search Activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.QuestionableWebSearches]: () => {
        dispatch(activitySwiperIndexChanged(4));
        dispatch(navigateToSearchActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Questionable Web Searches',
          category: 'Activity cards',
        });
      },
      [ActivityType.CallsAndSms]: () => {
        dispatch(activitySwiperIndexChanged(5));
        if (isFeaturePremium) {
          return dispatch(navigateToCallsAndSmsAppRules(profileId));
        }
        dispatch(navigateToCallsAndSmsActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Calls & SMS',
          category: 'Activity cards',
        });
      },
    };

    expandHandlersForActivityTypes[type]();
  };

const noActivityText = ({
  isCombinedProfile,
  isDelegatedProfile,
  texts: { defaultText, combinedProfileText, delegatedText },
}) => {
  if (isCombinedProfile) {
    if (isDelegatedProfile) return delegatedText;
    return combinedProfileText;
  }
  return defaultText;
};

const getTextIfNoActivityAtCard = (
  type: ActivityType,
  name: string,
  isCombinedProfile: boolean,
  isDelegatedProfile: boolean
): string => {
  switch (type) {
    case ActivityType.CallsAndSms:
      return noActivityText({
        isCombinedProfile,
        isDelegatedProfile,
        texts: {
          defaultText: t(
            'No calls & SMS activity for {{name}} during the specified period',
            { name }
          ),
          combinedProfileText: t(
            '{{profileName}} hasn’t made or received any calls or messages on personal devices',
            {
              profileName: name,
            }
          ),
          delegatedText: t(
            '{{profileName}} hasn’t made or received any calls or messages',
            {
              profileName: name,
            }
          ),
        },
      });
    case ActivityType.Search:
      return noActivityText({
        isCombinedProfile,
        isDelegatedProfile,
        texts: {
          defaultText: t(
            'No Search activity for {{name}} reported on Youtube, Google or Bing',
            {
              name,
            }
          ),
          combinedProfileText: t(
            '{{profileName}} hasn’t searched for anything online on personal devices',
            {
              profileName: name,
            }
          ),
          delegatedText: t(
            '{{profileName}} hasn’t searched for anything online',
            {
              profileName: name,
            }
          ),
        },
      });
    case ActivityType.QuestionableWebSearches:
      return t(
        'No Search activity for {{name}} reported on Youtube, Google or Bing',
        {
          name,
        }
      );
    case ActivityType.BlockedWebs:
      return noActivityText({
        isCombinedProfile,
        isDelegatedProfile,
        texts: {
          defaultText: t(
            'No activity for {{name}} during the specified period',
            {
              name,
            }
          ),
          delegatedText: t(
            '{{profileName}} hasn’t tried to open any blocked websites',
            {
              profileName: name,
            }
          ),
          combinedProfileText: t(
            '{{profileName}} hasn’t tried to open any blocked websites on personal devices',
            {
              profileName: name,
            }
          ),
        },
      });
    case ActivityType.AppsAndWebActivity:
      return noActivityText({
        isCombinedProfile,
        isDelegatedProfile,
        texts: {
          defaultText: t(
            'No activity for {{name}} during the specified period',
            {
              name,
            }
          ),
          combinedProfileText: t(
            '{{profileName}} hasn’t used any apps or websites on personal devices',
            {
              profileName: name,
            }
          ),
          delegatedText: t('{{profileName}} hasn’t used any apps or websites', {
            profileName: name,
          }),
        },
      });
    default:
      return t('No activity for {{name}} during the specified period', {
        name,
      });
  }
};

const ActivitySummaryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitySummaryList);

export default ActivitySummaryListContainer;
