import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  ProfileSummaryAlerts,
  ProfileSummaryAlertsPayload,
} from './types/summaryAlerts.type';

export const ProfileSummaryAlertsOperations = getRecordOperations<
  ProfileSummaryAlerts,
  ProfileSummaryAlertsPayload
>({
  defaultFields: {
    search: 0,
  },
});
