import * as React from 'react';
import { IconSize } from 'styleguide-react';
import { SignatureRecommendation } from '../../records/studentActivity/types/signatures.types';
import IconAllow from '../Icons/Actions/IconAllow';
import IconUnsafe from '../Icons/Actions/IconUnsafe';

type IconRecommendationPropsType = { type: SignatureRecommendation };

const IconRecommendation: React.FunctionComponent<
  IconRecommendationPropsType
> = (props: IconRecommendationPropsType) => {
  switch (props.type) {
    case 'SAFE':
    case 'RECOMMENDED':
      return <IconAllow size={IconSize.xs} />;
    case 'UNSAFE':
    case 'HAZARDOUS':
      return <IconUnsafe size={IconSize.xs} />;
    default:
      return null;
  }
};

export default IconRecommendation;
