import * as React from 'react';
import { List } from 'immutable';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';
import { PlaceRecord } from '../../records';

const AddNewPlace: React.FunctionComponent<{
  buttonText: string;
  places: List<PlaceRecord>;
  onClickAddNewPlace: any;
  locationPathname: string;
}> = ({ buttonText, onClickAddNewPlace, places, locationPathname }) => (
  <FlexLayout
    mainaxis="row"
    marginTop="24"
    marginBottom="40"
    mainaxisAlignment="center"
  >
    <Button
      size={ButtonSize.medium}
      onClick={() => onClickAddNewPlace(places.size, locationPathname)}
    >
      {buttonText}
    </Button>
  </FlexLayout>
);

export default AddNewPlace;
