import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import { inverseMapPlatform, mapPlatform } from '../../device';
import {
  PolicyApplication,
  PolicyApplicationPayload,
} from './types/PolicyApplication.types';
import { policyAction } from './types/Policy.types';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../../api/mappings/valueRestrictions';

export const PolicyApplicationOperations = getRecordOperations<
  PolicyApplication,
  PolicyApplicationPayload
>({
  defaultFields: {
    name: null,
    exe: null,
    platform: mapPlatform(undefined),
    action: null,
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    platform: mapPlatform(payload.platform),
    action: restrictKeyFromValue(payload?.action, policyAction),
  }),
  mapOut: record => {
    let platform = inverseMapPlatform(
      record?.platform || mapPlatform(undefined)
    );
    // inverseMapPlatform() wraps the index in a string
    if (!Number.isNaN(platform)) {
      platform = Number(platform);
    }
    return {
      ...commonMapOutFn(record),
      platform,
      action: restrictValueFromKey(record?.action, policyAction),
    };
  },
});
