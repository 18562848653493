import * as React from 'react';
import { ModalRecord } from '../../ducks/notifications';
import { markdownJSX } from '../../helpers/string';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  ModalStyledText,
  ScrollableContent,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const CustomNotificationModal: React.FunctionComponent<{
  modal: ModalRecord;
  onRedirect: (source: string) => (href: string) => void;
  onClickClose: () => any;
}> = ({ modal, onRedirect, onClickClose }) => (
  <Modal
    testId="custom-notification-modal"
    onClickClose={onClickClose}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
    header={{
      icon:
        modal.className === 'modal--alert' ? (
          <Icon type={IconType.exclamationCircle} />
        ) : undefined,
    }}
    title={modal.headerText}
    buttons={[
      <Button
        key="button"
        size={ButtonSize.medium}
        block
        onClick={
          modal.redirect !== null
            ? () => onRedirect('footer')(modal.redirect!)
            : onClickClose
        }
      >
        {modal.buttonText}
      </Button>,
    ]}
  >
    <ModalStyledText textAlign="center">
      {' '}
      <div
        data-testid="markdown_custom_notification"
        onClick={event => {
          event.preventDefault();
          const href = (event.target as Element).getAttribute('href');
          if (href !== null) {
            onRedirect('markdown')(href);
          }
        }}
      >
        <ScrollableContent height={150}>
          {markdownJSX(modal.markdown)}
        </ScrollableContent>
      </div>
    </ModalStyledText>
  </Modal>
);

export default CustomNotificationModal;
