import * as React from 'react';
import TimelineState from './TimelineState';
import { IconType } from 'styleguide-react';
import { t } from '../../../../lib/i18n';

const TimelineErrorState: React.FC<{}> = () => {
  return (
    <TimelineState
      iconType={IconType.exclamationCircle}
      text={t('Something went wrong. Please try again')}
    />
  );
};

export default TimelineErrorState;
