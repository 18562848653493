import * as React from 'react';
import * as R from 'ramda';
import { LocationRecord, isLoadedMap } from '../../records/location';
import Icon from '../Icons/Icon';
import marker from '../../assets/base/images/map-marker.svg';

const LocationMap: React.FunctionComponent<{
  location: LocationRecord;
}> = ({ location: { map } }) => (
  <div className="LocationMap">
    <div className="LocationMap__map--empty">
      <Icon path={marker} />
    </div>
    {mapElement(map)}
  </div>
);

const mapElement = R.cond<any, JSX.Element | null>([
  [
    isLoadedMap,
    R.pipe(R.prop('url'), url => (
      <div
        className="LocationMap__map--image"
        style={{ backgroundImage: `url(${url})` }}
      />
    )),
  ],
  [R.T, R.always(null)],
]);

export default LocationMap;
