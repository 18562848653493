import * as React from 'react';
import {
  FlexLayout,
  Layout,
  StyledHeader,
  Button,
  ButtonSize,
  IconType,
  Icon,
  Svg,
  FreeText,
  TransparentButton,
  IconColor,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import IllustrationPanicSvg from '../../assets/base/images/v3/flyovers/illustration_panic.svg';

export interface SchoolsOnboardingErrorContentDispatchProps {
  onClickContactUs?: (event: Event) => void;
  onClickNavigateBack?: () => void;
}

type SchoolsOnboardingErrorContentProps =
  SchoolsOnboardingErrorContentDispatchProps & {
    title: string;
    subtitle: string;
    showBackButton: boolean;
  };

const SchoolsOnboardingErrorContent: React.FunctionComponent<
  SchoolsOnboardingErrorContentProps
> = ({
  title,
  subtitle,
  showBackButton = false,
  onClickContactUs,
  onClickNavigateBack,
}: SchoolsOnboardingErrorContentProps) => {
  const backButtonWrapper = () => (
    <FlexLayout mainaxis="column" mainaxisAlignment="flex-start">
      <TransparentButton
        onClick={onClickNavigateBack}
        data-testid="Button--go-back"
      >
        <FlexLayout mainaxis="row" crossaxisAlignment="center">
          <Layout marginRight="8" renderAs="span">
            <Icon type={IconType.arrowLeft} color={IconColor.black} />
          </Layout>
          <FreeText fontSize="16px">{t('Back')}</FreeText>
        </FlexLayout>
      </TransparentButton>
    </FlexLayout>
  );

  return (
    <div className="SchoolsOnboardingErrorContent">
      {showBackButton && backButtonWrapper()}
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        matchParentHeight
      >
        <Svg className="SchoolsOnboardingErrorContent__Icon">
          <img src={IllustrationPanicSvg} alt="Panic" />
        </Svg>
        <FlexLayout
          maxWidth="520"
          marginTop="16"
          marginBottom="48"
          mainaxis="column"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          className="SchoolsOnboardingErrorContent__Text"
          textAlignement="center"
        >
          <FlexLayout
            mainaxis="column"
            marginBottom="24"
            textAlignement="center"
          >
            <StyledHeader type="h5" data-testid="StyledHeader--title">
              <strong>{title}</strong>
            </StyledHeader>
          </FlexLayout>
          <p data-testid="Label--subtitle">{subtitle}</p>
        </FlexLayout>
        <Button
          data-testid="Button--contact-us"
          onClick={onClickContactUs}
          loading={false}
          disabled={false}
          size={ButtonSize.medium}
        >
          {t('Contact us')}
        </Button>
      </FlexLayout>
    </div>
  );
};

export default SchoolsOnboardingErrorContent;
