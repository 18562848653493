import * as React from 'react';
import {
  FlexLayout,
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  Layout,
  Typography,
} from 'styleguide-react';

interface InfoItemProps {
  orientation: 'horizontal' | 'vertical';
  iconType: unknown;
  iconFamily?: IconFamily;
  title: string;
  subtitle: string;
  animDelay: number;
}

const InfoItem = ({
  orientation,
  iconType,
  iconFamily = IconFamily.regular,
  title,
  subtitle,
  animDelay,
}: InfoItemProps) => {
  const isHorizontal = orientation === 'horizontal';
  let mainaxis: 'row' | 'column' = 'row';
  if (isHorizontal) {
    mainaxis = 'column';
  }

  return (
    <FlexLayout
      mainaxis={mainaxis}
      flexBasis="100%"
      margin="16"
      className={`par-full-premium-flyover__anim-reveal delay-${animDelay}`}
    >
      <Layout
        minWidth="56"
        marginBottom="8"
        textAlignement={isHorizontal ? 'center' : undefined}
      >
        <Icon
          type={iconType}
          family={iconFamily}
          color={IconColor.secondary}
          size={IconSize.x2}
        />
      </Layout>
      <FlexLayout mainaxis="column">
        <Layout
          textAlignement={isHorizontal ? 'center' : undefined}
          marginBottom={isHorizontal ? '16' : '8'}
        >
          <Typography type="body1" weight="semibold">
            {title}
          </Typography>
        </Layout>
        <Layout textAlignement={isHorizontal ? 'center' : undefined}>
          <Typography type="body2">{subtitle}</Typography>
        </Layout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default InfoItem;
