import * as React from 'react';
import { t } from '../../lib/i18n';
import Location from './Location';
import LocationMap from './LocationMap';
import { ProfileRecord } from '../../records/profile';
import { DeviceRecord } from '../../records/device';
import { LicenseRecord, isPremium } from '../../records/license';
import {
  FlexLayout,
  GroupHeader,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
  List,
  RegularStyleListItem,
  Switch,
  EmptyPlaceholder,
  TransparentButton,
  DeactivableContent,
  Card,
  GlobalType,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { OrderedSet } from 'immutable';
import DeviceAssignedTo from './DeviceAssignedTo';
import PageLayout from '../Layout/PageLayout';
import DeviceEnableProtection from './DeviceEnableProtection';
import PageSectionLayout from '../Layout/PageSectionLayout';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import { classNames } from '../../helpers';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';

const DeviceAndroid: React.FunctionComponent<{
  license: LicenseRecord;
  device: DeviceRecord | undefined;
  profile: ProfileRecord | undefined;
  accountProfiles: OrderedSet<ProfileRecord>;
  protectionEnabled: boolean;
  vpnEnabled: boolean;
  onClickAssignProfileFromDropdown: () => any;
  onClickOpenDropdown: () => any;
  onClickMap: () => any;
  onClickEdit: () => any;
  onClickDelete: () => any;
  onToggleProtectAndroidSettings: (checked: boolean) => any;
  onToggleProtectQustodioConfiguration: (checked: boolean) => any;
}> = ({
  license,
  device,
  profile,
  accountProfiles,
  protectionEnabled,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
  onClickMap,
  onClickEdit,
  onClickDelete,
  onToggleProtectAndroidSettings,
  onToggleProtectQustodioConfiguration,
}) => (
  <PageLayout
    title={device !== undefined ? device.name : ''}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 2,
        mainInsideCard: true,
      },
    ]}
    actions={[
      { actionName: 'back' },
      { actionName: 'edit', onClick: onClickEdit },
      {
        actionName: 'delete',
        onClick: onClickDelete,
        iconColor: IconColor.error,
      },
    ]}
  >
    <PageSectionLayout side="main">
      <DeactivableContent disabled={!protectionEnabled}>
        <DeviceEnableProtection protectionEnabled={protectionEnabled} />
        <DeviceAndroidFields
          device={device}
          profile={profile}
          license={license}
          profiles={accountProfiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          onToggleProtectAndroidSettings={onToggleProtectAndroidSettings}
          onToggleProtectQustodioConfiguration={
            onToggleProtectQustodioConfiguration
          }
          onClickMap={onClickMap}
        />
      </DeactivableContent>
    </PageSectionLayout>
    <PageSectionLayout side="right">
      <Card
        type={GlobalType.white}
        header={{
          title: t('Settings'),
          border: true,
        }}
        className={classNames(
          DesktopLayoutScssUtils.CardRight,
          TabletLayoutScssUtils.CardRight
        )}
      >
        <DeactivableContent disabled={!protectionEnabled}>
          <FlexLayout mainaxis="column" marginTop="8" marginBottom="8">
            {androidSettingsSwitches(
              device,
              onToggleProtectAndroidSettings,
              onToggleProtectQustodioConfiguration
            )}
          </FlexLayout>
        </DeactivableContent>
      </Card>
    </PageSectionLayout>
  </PageLayout>
);

const DeviceAndroidFields: React.FunctionComponent<{
  profile: ProfileRecord | undefined;
  license: LicenseRecord;
  profiles: OrderedSet<ProfileRecord>;
  device: DeviceRecord | undefined;
  onClickAssignProfileFromDropdown: () => void;
  onClickOpenDropdown: () => void;
  onToggleProtectAndroidSettings: (checked: boolean) => any;
  onToggleProtectQustodioConfiguration: (checked: boolean) => any;
  onClickMap: () => any;
}> = ({
  profile,
  license,
  profiles,
  device,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
  onToggleProtectAndroidSettings,
  onToggleProtectQustodioConfiguration,
  onClickMap,
}) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="flex-start"
    flexGrow="0"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <GroupHeader>{t('Assigned to:')}</GroupHeader>
    </FlexLayout>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout
        mainaxis="column"
        marginTop="8"
        marginBottom="8"
        marginRight="16"
        marginLeft="16"
      >
        <DeviceAssignedTo
          device={device}
          profile={profile}
          profiles={profiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceAndroid"
        />
      </FlexLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlexLayout mainaxis="column" marginTop="8" marginBottom="8">
        <DeviceAssignedTo
          device={device}
          profile={profile}
          profiles={profiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceAndroid"
        />
      </FlexLayout>
    </RenderWhen>

    <RenderWhen screenSize={ScreenSize.Mobile}>
      <FlexLayout mainaxis="column">
        <GroupHeader>{t('Settings')}</GroupHeader>
      </FlexLayout>
      <FlexLayout
        mainaxis="column"
        marginTop="8"
        marginBottom="8"
        marginRight="16"
        marginLeft="16"
      >
        {androidSettingsSwitches(
          device,
          onToggleProtectAndroidSettings,
          onToggleProtectQustodioConfiguration
        )}
      </FlexLayout>
    </RenderWhen>

    <FlexLayout mainaxis="column">
      <GroupHeader>{t('Last Location')}</GroupHeader>
    </FlexLayout>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout
        mainaxis="column"
        marginBottom="8"
        marginRight="16"
        marginLeft="16"
      >
        {deviceLocationLicenseMessage(license, device) ||
          deviceLocationUnassignedMessage(profile) ||
          deviceLocation(device ? device.location : null)(onClickMap)}
      </FlexLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlexLayout mainaxis="column" marginBottom="8">
        {deviceLocationLicenseMessage(license, device) ||
          deviceLocationUnassignedMessage(profile) ||
          deviceLocation(device ? device.location : null)(onClickMap)}
      </FlexLayout>
    </RenderWhen>
  </FlexLayout>
);

const androidSettingsSwitches = (
  device,
  onToggleProtectAndroidSettings,
  onToggleProtectQustodioConfiguration
) => (
  <List
    listItemMarginTop="8"
    listItemPaddingTop="8"
    listItemMarginBottom="8"
    listItemPaddingBottom="8"
  >
    <RegularStyleListItem
      title={t('Protect Android Settings')}
      upperSubtitle={t(
        'Require your {{shortName}} password to access Android settings.'
      )}
      actionElement={
        <Switch
          checked={
            device !== undefined
              ? device.settings.platform.lockAndroidSettings
              : true
          }
          testId="protectAndroidSettings"
          onClick={ev =>
            onToggleProtectAndroidSettings(
              (ev.target as HTMLInputElement).checked
            )
          }
        />
      }
    />
    <RegularStyleListItem
      title={t('Protect {{shortName}} Settings')}
      upperSubtitle={t(
        'Require your {{shortName}} password to log in to the {{shortName}} app on this device.'
      )}
      actionElement={
        <Switch
          checked={
            device !== undefined
              ? device.settings.platform.lockQustodioConfiguration
              : true
          }
          testId="lockQustodioConfiguration"
          onClick={ev =>
            onToggleProtectQustodioConfiguration(
              (ev.target as HTMLInputElement).checked
            )
          }
        />
      }
    />
  </List>
);

const deviceLocationLicenseMessage = (
  license: LicenseRecord,
  device?: DeviceRecord
) =>
  (!isPremium(license.type) || device === undefined) && (
    <FlexLayout mainaxis="column" marginTop="24">
      <EmptyPlaceholder
        text={t("To see device's location, upgrade to premium")}
      />
    </FlexLayout>
  );

const deviceLocationUnassignedMessage = (profile: ProfileRecord | undefined) =>
  profile == null && (
    <FlexLayout mainaxis="row" marginTop="24" marginBottom="24">
      <FlexLayout mainaxis="row" marginRight="8">
        <Icon
          type={IconType.mapMarkerSlash}
          color={IconColor.primary}
          size={IconSize.lg}
        />
      </FlexLayout>
      <Label>{t('Device is unassigned, Location not available')}</Label>
    </FlexLayout>
  );

const deviceLocation = location => onClick =>
  [
    <Location location={location} key="1" />,
    <FlexLayout mainaxis="column" key="1">
      <TransparentButton onClick={onClick}>
        <LocationMap location={location} />
      </TransparentButton>
    </FlexLayout>,
  ];

export default DeviceAndroid;
