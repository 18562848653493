import {
  WEEKDAYS_WORKING,
  WEEKDAYS_WEEKEND,
  WEEKDAYS,
  Weekday,
  weekdaysShort3letterTranslations,
} from '../../../helpers/dates';
import { t } from '../../../lib/i18n';
import { exhaustiveCheck } from '../../../helpers/validations';
import { PolicyAction } from '../../../records/routines/policy/types/Policy.types';
import { SCHEDULE_WEEKDAYS } from '../../../records/routines/schedule/types/Schedule.types';
import { RoutineMode, TimeSlot } from '../routines.types';
import { equals, init, last, map, join, pipe, take, takeLast } from 'ramda';

const translateDay = (day: number) => weekdaysShort3letterTranslations()[day];
const translateDays = pipe(map(translateDay), join(', '));
const hasOnlyTwoDays = days => days.length === 2;
const hasOnlyOneDay = days => days.length === 1;

const sortAsIndexedDays = days =>
  SCHEDULE_WEEKDAYS.reduce(
    (acc: Array<Weekday>, day, idx: number) =>
      days.includes(day) ? [...acc, idx] : acc,
    []
  );

export const getSelectedDaysText = (daysSelected: TimeSlot['days']) => {
  if (daysSelected.length === 0) return null;

  const days: Array<Weekday> = sortAsIndexedDays(daysSelected);

  if (equals(days, WEEKDAYS_WEEKEND)) {
    return t('Weekends');
  }

  if (equals(days, WEEKDAYS_WORKING)) {
    return t('Weekdays');
  }

  if (equals(days, WEEKDAYS)) {
    return t('Every day');
  }

  if (hasOnlyTwoDays(days)) {
    return t(`Every {{firstDay}} and {{lastDay}}`, {
      firstDay: translateDay(days[0]),
      lastDay: translateDay(days[1]),
    });
  }

  if (hasOnlyOneDay(days)) {
    return t(`Every {{day}}`, {
      day: translateDay(days[0]),
    });
  }

  return t(`Every {{dayList}} and {{lastDay}}`, {
    dayList: translateDays(init(days)),
    lastDay: translateDay(last(days)!),
  });
};

export const toInverseAction = (type: PolicyAction): PolicyAction => {
  if (type === 'POLICY_ACTION_ALLOWED') return 'POLICY_ACTION_BLOCKED';
  if (type === 'POLICY_ACTION_BLOCKED') return 'POLICY_ACTION_ALLOWED';
  return exhaustiveCheck(type);
};

export const getTimeSlotWeekDays = (): TimeSlot['days'] =>
  take(5, SCHEDULE_WEEKDAYS);
export const getTimeSlotWeekend = (): TimeSlot['days'] =>
  takeLast(2, SCHEDULE_WEEKDAYS);

export const getUniqueName = (names: string[], newName: string): string => {
  let counter = 2;
  let uniqueName = newName;

  while (names.includes(uniqueName)) {
    uniqueName = `${newName} ${counter}`;
    counter += 1;
  }

  return uniqueName;
};

export const isCreateRoutine = (mode: RoutineMode) => mode === 'CREATE';
export const isEditRoutine = (mode: RoutineMode) => mode === 'EDIT';
