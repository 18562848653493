import { commonMapInFn } from '../../api/mappings/common';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  StudentActiveClass,
  StudentActiveClassPayload,
} from './types/studentActiveClass.type';

export const studentActiveClassRecordOperations = getRecordOperations<
  StudentActiveClass,
  StudentActiveClassPayload
>({
  defaultFields: {
    status: false,
    endDatetime: '',
  },
  mapIn: payload => {
    const commonMappedResult = commonMapInFn(payload);
    return {
      ...(commonMappedResult as { activeClass: StudentActiveClass })
        .activeClass,
    };
  },
});
