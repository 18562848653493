import * as React from 'react';
import WellbeingAccordionSection from './WellbeingAccordionSection';
import { FlexLayout, IconType } from 'styleguide-react';
import { t } from '../../../lib/i18n';

interface FamilyActivitiesMakeRoomSectionProps {
  onClickButton: () => void;
  isDisabledButton: boolean;
}

const FamilyActivitiesMakeRoomSection = ({
  onClickButton,
  isDisabledButton,
}: FamilyActivitiesMakeRoomSectionProps) => {
  return (
    <FlexLayout mainaxis="column">
      <WellbeingAccordionSection
        colorScheme="green"
        imageType="activities"
        isDisabledButton={isDisabledButton}
        onClickButton={onClickButton}
        title={t('Make room for {1}quality family time{/1}', {
          1: (str: string) => (
            <span className="par-wellbeing-feature__title-highlighted">
              {str}
            </span>
          ),
        })}
        items={[
          {
            icon: IconType.heartCircleCheck,
            isOpen: false,
            title: t('Create meaningful moments'),
            content: t(
              'Build trust, resolve conflicts, and develop meaningful connections.'
            ),
          },
          {
            isOpen: false,
            icon: IconType.messageSmile,
            title: t('Establish strong family bonds'),
            content: t(
              'Discover practical solutions and take steps to reach your family’s goals.'
            ),
          },
          {
            isOpen: false,
            icon: IconType.familyPants,
            title: t('Develop and grow as a family'),
            content: t(
              'Gain confidence as a parent and establish ways to co-parent that work for everyone involved.'
            ),
          },
        ]}
      />
    </FlexLayout>
  );
};

export default FamilyActivitiesMakeRoomSection;
