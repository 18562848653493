import * as Immutable from 'immutable';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import {
  Platform,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_MAC,
  PLATFORM_WINDOWS,
} from '../constants';
import { ProfileRecord, PROFILE_STATUS_NO_DEVICE } from '../records/profile';
import State from '../store/state';

import { getProfile } from './profileRaw';
import { countSymbols } from '../helpers';
import * as qinit from '../qinit';
import { isVersionGreaterOrEqualThan } from '../helpers/string';
import { DeviceRecord } from '../records/device/types/Device.types';

export const getProfileOrDefault = (state: State, id: string | number) =>
  state
    .get('records')
    .get('profiles')
    .get(typeof id === 'string' ? id : id?.toString()) || ProfileRecord();

/**
 * Filter delegated devices as PAR does not support them yet.
 * once we update PAR to support delegated devices as real devices we should remove this filter
 */
export const getDevicesMap = createSelector(
  (state: State) => state.get('records').get('devices'),
  devices => devices.filter(device => device.isDelegated === false)
);

export const getProfileDevices = createSelector(
  getProfileOrDefault,
  getDevicesMap,
  (profile, devicesMap) =>
    devicesMap
      .filter(
        R.pipe(
          R.nthArg(1),
          Number,
          Immutable.List.prototype.includes.bind(profile.get('deviceIds'))
        )
      )
      .toList()
);

export const getProfileMobileDevices = createSelector(
  getProfileDevices,
  devices =>
    devices.filter(device =>
      R.contains(device.platform, [PLATFORM_ANDROID, PLATFORM_IOS])
    )
);

export const getKidTamperedDevices = createSelector(
  getDevicesMap,
  getProfile,
  (devices, profile) => {
    return devices && profile
      ? profile.deviceIds
          .map(deviceId => devices.get(deviceId.toString()))
          .filter(device => device?.alerts?.unauthorizedRemove)
          .toArray()
      : [];
  }
);

const getProfileDevicesOfPlatform = (platform: Platform) =>
  createSelector(getProfileDevices, devices =>
    devices.filter(R.propEq('platform', platform))
  );

export const getProfileAndroidDevices =
  getProfileDevicesOfPlatform(PLATFORM_ANDROID);

export const getProfileIOSDevices = getProfileDevicesOfPlatform(PLATFORM_IOS);

export const hasTrackableDevices = state => profileId =>
  getProfileMobileDevices(state, profileId).size > 0;

export const getKidHasTamparedDevice = createSelector(
  getKidTamperedDevices,
  getProfileDevices,
  (tamparedDevices, devices) =>
    tamparedDevices.length > 0 && tamparedDevices.length === devices.size
);

const getProfileDevicePlatformCounts = createSelector(
  getProfileDevices,
  devices => countSymbols(devices.map<string>(R.prop('platform')))
);

export const getProfileDevicePlatformCount = createSelector(
  getProfileDevicePlatformCounts,
  platformCounts => platform => {
    const platformWithCount = platformCounts.find(([platformName]) => {
      return platformName === platform;
    });

    return platformWithCount ? platformWithCount[1] : 0;
  }
);

/**
 * Get any profile Android devices compatible with the calls and
 * messages feature. The app version should ve Sideloaded
 */
export const getCompatibleAndroidDevicesWithCallsSMS = createSelector(
  getProfileAndroidDevices,
  androidDevices => {
    const maximumVersion =
      qinit.tenant.common.callsms.not_available.maximum_version;

    return androidDevices.filter(
      (device?: DeviceRecord) =>
        device !== undefined &&
        (isVersionGreaterOrEqualThan(device.version, maximumVersion) ?? false)
    );
  }
);

/**
 * Get any profile iOS devices compatible with the calls and
 * messages feature. The device must has set the
 * enabledCallsAndMessagesMonitoring flag to true
 */
export const getCompatibleIOSDevicesWithCallsSMS = createSelector(
  getProfileIOSDevices,
  iosDevices => {
    return iosDevices.filter(
      (device?: DeviceRecord) =>
        device?.options?.enabledCallsAndMessagesMonitoring ?? false
    );
  }
);

/**
 * Check if there is any profile Android devices compatible with the calls and
 * messages feature. The app version should be Sideloaded
 */
export const hasCompatibleAndroidDeviceWithCallsSMS = createSelector(
  getCompatibleAndroidDevicesWithCallsSMS,
  androidDevices => !androidDevices.isEmpty()
);

/**
 * Check if there is any profile iOS devices compatible with the calls and
 * messages feature. The device must has set the
 * enabledCallsAndMessagesMonitoring flag to true
 */
export const hasCompatibleIOSDeviceWithCallsSMS = createSelector(
  getCompatibleIOSDevicesWithCallsSMS,
  iosDevices => !iosDevices.isEmpty()
);

export const hasDeviceAssigned = (state: State, id: number) => {
  const profile = getProfileOrDefault(state, id);
  return profile.status !== PROFILE_STATUS_NO_DEVICE;
};

export const getKidDevices = createSelector(
  getDevicesMap,
  getProfile,
  (devices, profile) => {
    return devices && profile
      ? profile.deviceIds
          .map(deviceId => devices.get(deviceId.toString()))
          .toArray()
      : [];
  }
);

/**
 * this duplicates the getDeviceMap but changing the filter, I decided to duplicate it to avoid problems
 * with already existing code, once we add support to delegated devices we may combine those selectors.
 */
export const getDelegatedDevicesMap = createSelector(
  (state: State) => state.get('records').get('devices'),
  devices => devices.filter(device => device.isDelegated === true)
);

export const getProfileDesktopDelegatedDevices = createSelector(
  getProfile,
  getDelegatedDevicesMap,
  (profile, devicesMap) =>
    devicesMap
      .filter(device => {
        return (
          device.users?.first()?.profileId === profile?.id &&
          [PLATFORM_WINDOWS, PLATFORM_MAC].includes(device.platform)
        );
      })
      .toList()
);
