import * as React from 'react';
import { Card } from 'styleguide-react';
import { DesktopPageLayoutProps } from '../types';
import { DesktopMinHeightCard } from './constants';

const DesktopCard: React.FC<DesktopPageLayoutProps> = ({
  mainChildren,
  cardBannerChildren,
  beforePullRefreshChildren,
  afterPullRefreshChildren,
}) => (
  <Card
    fullWidth
    minHeight={DesktopMinHeightCard}
    header={
      cardBannerChildren
        ? {
            banner: cardBannerChildren as React.ReactElement,
          }
        : undefined
    }
  >
    {beforePullRefreshChildren}
    {mainChildren}
    {afterPullRefreshChildren}
  </Card>
);

export default DesktopCard;
