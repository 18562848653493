import * as React from 'react';
import * as SVGInjector from 'svg-injector';
import * as R from 'ramda';

class Icon extends React.Component<
  {
    path: string;
    className?: string;
    id?: string;
    fallbackPath?: string;
    style?: any;
    verticalAlign?: string;
  },
  {}
> {
  isInjected = false;

  /**
   * Prevent re-injecting the same image path
   */
  shouldComponentUpdate(nextProps) {
    return !this.isInjected || !R.whereEq(nextProps, this.props as any);
  }

  inject(element) {
    const { verticalAlign } = this.props;
    this.isInjected = true;
    SVGInjector(element, {
      evalScripts: false,
      each: svg => {
        if (svg && svg.setAttribute) {
          svg.style.verticalAlign = verticalAlign || 'middle';
        }
      },
    });
  }

  ref(span) {
    const { path, fallbackPath, id, className } = this.props;

    if (!span || span.children.length === 0) {
      return;
    }

    if (this.isInjected) {
      const newImg = new Image();
      newImg.setAttribute('data-src', path);
      newImg.setAttribute('data-fallback', fallbackPath || '');
      newImg.setAttribute('id', id || '');
      newImg.setAttribute('class', className || '');
      swapElements(span, newImg);
      span.replaceChild(newImg, span.children[0]);
    }

    this.inject(span.children[0]);
  }

  render() {
    const { id, className, path, fallbackPath, style } = this.props;

    return (
      <span
        className={`IconWrapper ${className ? `${className}__wrapper` : ''}`}
        ref={span => this.ref(span)}
        style={style}
      >
        <img
          alt=""
          className={className}
          id={id || ''}
          data-src={path}
          data-fallback={fallbackPath}
        />
      </span>
    );
  }
}

/**
 * Removes first child from `parent`, insert it into a new wrapper element,
 * and then inserts `newChild` into `parent`. Like `parent.replaceChild(new, old)`
 * but `old` will have a parent node (but not attached to DOM).
 * This is so that SVGInjector does not fail due to the old child not having a
 * `.parentNode`.
 */
const swapElements = (parent, newChild) => {
  const parentPlaceholder = window.document.createElement('span');
  parentPlaceholder.appendChild(parent.children[0]);
  parent.appendChild(newChild);
};

export default Icon;
