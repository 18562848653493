import * as React from 'react';
import { IconType, FlexLayout, AlertBox } from 'styleguide-react';

import { t } from '../../../lib/i18n';
import { CallsSMSRulesProps } from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import LabeledSwitch from '../switches/LabeledSwitch';

export type CallsSMSCallsSectionProps = Pick<
  CallsSMSRulesProps,
  | 'incomingCallsBlocked'
  | 'callsSMSRulesEnabled'
  | 'onToggleBlockIncomingCalls'
  | 'onToggleBlockOutgoingCalls'
  | 'outgoingCallsBlocked'
> & {
  hasCompatibleIphoneDevices: boolean;
};
const CallsSMSCallsSection: React.FC<CallsSMSCallsSectionProps> = ({
  incomingCallsBlocked,
  callsSMSRulesEnabled,
  onToggleBlockIncomingCalls,
  onToggleBlockOutgoingCalls,
  outgoingCallsBlocked,
  hasCompatibleIphoneDevices,
}: CallsSMSCallsSectionProps) => (
  <div className="calls-section">
    <FlexLayout
      mainaxis="column"
      paddingBottom="8"
      className="calls-and-messages"
    >
      <FlexLayout mainaxis="column" paddingBottom="24">
        {hasCompatibleIphoneDevices && (
          <AlertBox showInfoIcon type="info">
            {t('Calls on iOS devices can be monitored but not blocked.')}
          </AlertBox>
        )}
      </FlexLayout>
      {LabeledSwitch({
        label: t('Allow Incoming Calls'),
        id: 'allow-incoming-calls',
        checked: !incomingCallsBlocked,
        onChange: ev => {
          if (callsSMSRulesEnabled) {
            onToggleBlockIncomingCalls(
              !(ev.target as HTMLInputElement).checked
            );
          }
        },
        icon: {
          type: IconType.incomingCall,
          source: 'custom',
        },
      })}
      {LabeledSwitch({
        label: t('Allow Outgoing Calls'),
        id: 'allow-outgoing-calls',
        checked: !outgoingCallsBlocked,
        onChange: ev => {
          if (callsSMSRulesEnabled) {
            onToggleBlockOutgoingCalls(
              !(ev.target as HTMLInputElement).checked
            );
          }
        },
        icon: {
          type: IconType.outgoingCall,
          source: 'custom',
        },
      })}
    </FlexLayout>
  </div>
);

export default CallsSMSCallsSection;
