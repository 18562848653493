import * as React from 'react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import DesktopLayout from './desktop/DesktopLayout';
import { filterAndMergeConfigurations } from './helpers/mergeConfiguration';
import MobileLayout from './mobile/MobileLayout';
import TabletLayout from './tablet/TabletLayout';
import { LayoutConfigurationProps } from './types';

const Layout: React.FunctionComponent<{
  children: React.ReactNode;
  configurations?: LayoutConfigurationProps[];
}> = ({ children, configurations }) => (
  <React.Fragment>
    <RenderWhen screenSize={ScreenSize.Mobile}>
      <MobileLayout>{children}</MobileLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Tablet}>
      <TabletLayout>{children}</TabletLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <DesktopLayout
        configuration={filterAndMergeConfigurations(
          configurations,
          ScreenSize.Desktop
        )}
      >
        {children}
      </DesktopLayout>
    </RenderWhen>
  </React.Fragment>
);

export default Layout;
