import * as React from 'react';
import {
  WebfilterAction,
  WEBFILTER_ALERT,
  WEBFILTER_ALLOW,
  WEBFILTER_BLOCK,
  WEBFILTER_IGNORE,
} from '../../constants';
import IconAlert from '../Icons/Actions/IconAlert';
import IconBlock from '../Icons/Actions/IconBlock';
import IconIgnore from '../Icons/Actions/IconIgnore';
import IconAllow from '../Icons/Actions/IconAllow';

const icons = {
  [WEBFILTER_ALERT]: <IconAlert />,
  [WEBFILTER_BLOCK]: <IconBlock />,
  [WEBFILTER_IGNORE]: <IconIgnore />,
  [WEBFILTER_ALLOW]: <IconAllow />,
};

const geIcon = (action: WebfilterAction): JSX.Element =>
  icons[action] ?? <IconAllow />;

const WebFiltersMapToIcon: React.FunctionComponent<{
  action: WebfilterAction;
}> = ({ action }) => geIcon(action);

export default React.memo(WebFiltersMapToIcon);
