import * as React from 'react';

/**
 * A custom React hook that helps track and manage the last interaction type.
 *
 * This hook provides functionality to store and retrieve the most recent interaction type.
 * It also offers a utility function to wrap a given function in a way that automatically updates
 * the last interaction type before executing the function.
 *
 * @template T - The type of the interaction being tracked.
 * @returns An object containing the following properties:
 *   - lastInteraction: The most recent interaction type that occurred.
 *   - withInteraction: A function that wraps another function to update the last interaction
 *     before executing the wrapped function.
 *
 * @example
 * // Using the hook to track and manage the last interaction type.
 * const InteractionTracker = () => {
 *   const { lastInteraction, withInteraction } = useLastInteraction<'click'|'hover'>();
 *
 *   const handleClick = withInteraction('click', () => {
 *     // Handle click event
 *   });
 *
 *   const handleHover = withInteraction('hover', () => {
 *     // Handle hover event
 *   });
 *
 *   return (
 *     <div>
 *       <button onClick={handleClick}>Click me</button>
 *       <div onMouseOver={handleHover}>Hover over me</div>
 *       <p>Last interaction: {lastInteraction}</p>
 *     </div>
 *   );
 * };
 */
const useLastInteraction = <T>() => {
  const lastInteraction = React.useRef<T | undefined>(undefined);

  const withInteraction = React.useCallback(
    (interaction: T, fn) =>
      (...args: unknown[]) => {
        lastInteraction.current = interaction;
        fn(...args);
      },
    []
  );

  return {
    lastInteraction: lastInteraction.current,
    withInteraction,
  };
};

export default useLastInteraction;
