import * as React from 'react';
import PageLayout from '../Layout/PageLayout';
import { t } from '../../lib/i18n';
import { ScreenSize } from '../RenderWhen/RenderWhen';

import AppDailyTimeLimitCard, {
  AppDailyTimeLimitCardProps,
} from './AppDailyTimeLimitCard';

export type AppDailyTimeLimitProps = Omit<AppDailyTimeLimitCardProps, 'type'>;

const AppDailyTimeLimit: React.FC<AppDailyTimeLimitProps> = ({
  title,
  icon,
  dayQuotaMap,
  selectedDay,
  onSelectDay,
  onChangeQuota,
}: AppDailyTimeLimitProps) => {
  return (
    <PageLayout
      title={t('Edit limits')}
      actions={[{ screenSize: ScreenSize.MobileOrTablet, actionName: 'back' }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 1,
        },
      ]}
    >
      <AppDailyTimeLimitCard
        type="mobile"
        title={title}
        icon={icon}
        dayQuotaMap={dayQuotaMap}
        selectedDay={selectedDay}
        onSelectDay={onSelectDay}
        onChangeQuota={onChangeQuota}
      />
    </PageLayout>
  );
};

export default AppDailyTimeLimit;
