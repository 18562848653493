import { createSelector } from 'reselect';
import * as Immutable from 'immutable';
import * as Moment from 'moment-timezone';
import * as R from 'ramda';
import { EventRecord, DeviceRecord, ContactRecord } from '../records';
import { isRegularStatusLocationEvent } from '../records/location';
import {
  parseISODateString,
  dateWithoutTime,
  isTodaysDate,
  convertDate,
} from '../helpers/dates';
import { EventType } from '../constants';
import State from '../store/state';
import { getCurrentTimeRaw, getCurrentTime } from './time';
import { ActivityEventFilters } from '../businessLogic/timeline/types';
import { getCallsSMSContacts } from './profileRules';
import { getActiveProfile } from './profile';
import { getDevicesMap, getProfileOrDefault } from './profileDevices';
import { getTimezone } from './account';
import { captureException } from '../helpers/sentry';

export type DayRecord = {
  day: Moment.Moment;
  events: Immutable.List<[EventRecord, DeviceRecord, ContactRecord]>;
};
const Day = Immutable.Record<DayRecord, {}>({
  day: Moment(),
  events: Immutable.List<[EventRecord, DeviceRecord, ContactRecord]>(),
});

const addToday = currentTime => timezone => days =>
  days.size > 0 &&
  currentTime.diff(days.first().day, 'days') >= 1 &&
  !isTodaysDate(currentTime, days.first().day, timezone)
    ? days.unshift(
        Day({
          day: convertDate(currentTime, timezone),
          events: Immutable.List(),
        })
      )
    : days;

const createEmptyDays = (events, timezone) => {
  if (events.size < 1) {
    return Immutable.List();
  }

  const firstDay = dateWithoutTime(events.first().time, timezone);
  const lastDay = dateWithoutTime(events.last().time, timezone);
  const dayCount = firstDay.diff(lastDay, 'days') + 1;

  return Immutable.List(
    R.range(0, dayCount).map(index => {
      return Day({
        day: firstDay.clone().subtract(index, 'days'),
        events: Immutable.List(),
      });
    })
  );
};

const reduceEventDays = (events, devicesMap, contacts, timezone, rawTime) => {
  const currentTime = parseISODateString(rawTime);
  return events.reduce((days, event) => {
    const device = devicesMap.get(event.deviceId.toString());
    // Finds contact in blocked contacts list, first by the social id and then by the event name
    const contact =
      contacts && event.socialContactId
        ? contacts.find(contact =>
            contact
              ? event.socialContactId.indexOf(contact.phone) !== -1 ||
                (event.name ? event.name.indexOf(contact.phone) !== -1 : false)
              : false
          )
        : null;
    const entry = days.findEntry(day =>
      day.get('day').isSame(convertDate(event.time, timezone), 'day')
    );
    if (!entry) {
      // This should never happen given that the days list contains every day
      // from first to last day of the event list.
      // Nevertheless there are sentry reports for `days.findEntry` not finding
      // a matching day. So there might be bad event-dates, or the events might
      // be out of order, or our date-logic might be skewed, or something else.
      // We just insert a new Day in the list in this case and report to sentry.
      captureException(new Error('Event time not in created day list'), {
        extra: {
          events,
          event,
          days,
        },
      });

      return days.push(
        Day({
          day: dateWithoutTime(event.time, timezone),
          events: Immutable.List<[EventRecord, DeviceRecord, ContactRecord]>([
            [event, device, contact],
          ]),
        })
      );
    }
    const [keyOfDay, day] = entry;
    return days.setIn(
      [keyOfDay, 'events'],
      day.events.push([event, device, contact])
    );
  }, addToday(currentTime)(timezone)(createEmptyDays(events, timezone)));
};

export const isFetchingEvents = (state: State) =>
  state.get('events').get('isFetching');

export const getShowEventActions = (state: State) =>
  state.get('events').get('showEventActions');

export const getEventByKey = (state: State, key: string) =>
  state.get('records').get('events').get(key);

export const getActivityTimelineFilterBy = state =>
  state.get('events').get('filterBy');

export const hasMoreEventsTimeline = (state, filter: ActivityEventFilters) =>
  state.get('events').get('result').get(filter).hasMore;

export const eventDataMatchesProfile = (state, profileUid: string) =>
  state.get('events').get('profileUid') === profileUid;

export const getEventsByFilter = state => state.get('events').get('result');

export const getEventsMap = (state: State) =>
  state.get('records').get('events');

export const getEventIds: (state: State) => Immutable.List<string> =
  createSelector(
    getEventsByFilter,
    getActivityTimelineFilterBy,
    (eventsByFilter, filterBy) =>
      eventsByFilter.get(filterBy).ids || Immutable.List()
  );

export const getEvents: (state: State) => Immutable.List<EventRecord> =
  createSelector(
    getEventIds,
    getEventsMap,
    (eventIds, eventsMap) =>
      eventIds
        .map(id => eventsMap.get(id))
        .filter(event => event.type !== EventType.Twitter)
        .sort((a, b) => (Moment(a.time).diff(b.time) > 0 ? -1 : 1)) // descending order
  );

export const isFetchingNewEvents = (state: State) =>
  !!state.get('events').get('isFetchingNew');

export const showFetchingMore = (state: State, eventCount: number) =>
  getEvents(state).size >= eventCount &&
  state.get('events').get('isFetchingMore') &&
  !state.get('events').get('isFetching') &&
  !state.get('events').get('fetchMoreError');

export const getLastReceiveEventsTimestamp = (state: State) =>
  state.get('events').get('lastReceiveEventsAt');

export const getRegularStatusProfileLocationEventIds = (
  state: State,
  profileUid
) => {
  const eventsById = state.get('events').get('resultById');
  if (!eventsById.has(profileUid)) {
    return Immutable.List();
  }

  const eventsMap = getEventsMap(state);
  return eventsById
    .get(profileUid)
    .get('locations')
    .get('ids')
    .filter(id => isRegularStatusLocationEvent(eventsMap.get(id).location));
};

export const getEventDays = createSelector(
  getEvents,
  getDevicesMap,
  getCallsSMSContacts,
  getTimezone,
  getCurrentTimeRaw,
  (events, devicesMap, contacts, timezone, rawTime) => {
    return reduceEventDays(events, devicesMap, contacts, timezone, rawTime);
  }
);

export const getLocationEvents: (state: State) => Immutable.List<EventRecord> =
  createSelector(
    getEventIds,
    getEventsMap,
    (eventIds, eventsMap) =>
      eventIds
        .map(id => eventsMap.get(id))
        .filter(event => event.type === EventType.Location)
        .sort((a, b) => (Moment(a.time).diff(b.time) > 0 ? -1 : 1)) // descending order
  );

export const getLocationEventDays = createSelector(
  getLocationEvents,
  getDevicesMap,
  getCallsSMSContacts,
  getTimezone,
  getCurrentTimeRaw,
  (events, devicesMap, contacts, timezone, rawTime) => {
    return reduceEventDays(events, devicesMap, contacts, timezone, rawTime);
  }
);

const onlyEventTypes =
  (types: EventType[]) => (event: EventRecord | undefined) =>
    event && types.includes(event.type) ? event : undefined;

export const getShowWebfilterEvent = createSelector(
  getShowEventActions,
  onlyEventTypes([EventType.Web])
);

export const getShowYoutubeEvent = createSelector(
  getShowEventActions,
  onlyEventTypes([EventType.Youtube])
);

export const getShowAppRuleEvent = createSelector(
  getShowEventActions,
  onlyEventTypes([EventType.App])
);

export const getShowCallsSMSEvent = createSelector(
  getShowEventActions,
  onlyEventTypes([
    EventType.CallsIncoming,
    EventType.CallsOutgoing,
    EventType.CallsMissed,
    EventType.CallsUnanswered,
    EventType.CallsBlockedIncoming,
    EventType.CallsBlockedOutgoing,
    EventType.SmsIncoming,
    EventType.SmsOutgoing,
    EventType.SmsBlockedIncoming,
    EventType.SmsBlockedOutgoing,
  ])
);

export const getShowPanicEvent = createSelector(
  getShowEventActions,
  onlyEventTypes([EventType.Panic])
);

export const getProfileLocationEvents = createSelector(
  getLocationEvents,
  getActiveProfile,
  (locationEvents, activeProfile) => {
    return activeProfile
      ? locationEvents.filter(event =>
          activeProfile.deviceIds.map(String).includes(String(event.deviceId))
        )
      : Immutable.List();
  }
);

const lastLocation = (event, getDevice) => [
  event,
  event.location.deviceId
    ? getDevice(event.location.deviceId.toString())
    : undefined,
];

const shouldShowLastLocation = (locationTime, currentTime, timezone) =>
  locationTime !== null && isTodaysDate(currentTime, locationTime, timezone);

const isMoreRecent = (a: EventRecord, b: EventRecord): boolean =>
  Moment(a.time).diff(b.time) > 0;

const latestEvent = (
  timelineLocationEvent: EventRecord | undefined,
  profileLocationEvent: EventRecord
): EventRecord => {
  if (!timelineLocationEvent) return profileLocationEvent;

  if (R.equals(timelineLocationEvent, profileLocationEvent))
    return timelineLocationEvent;

  if (isMoreRecent(timelineLocationEvent, profileLocationEvent))
    return timelineLocationEvent;

  return profileLocationEvent;
};

export const getProfileLastLocation = createSelector(
  getProfileOrDefault,
  getCurrentTime,
  getTimezone,
  getDevicesMap,
  getProfileLocationEvents,
  (profile, currentTime, timezone, devicesMap, profileLocationEvents) => {
    const location = latestEvent(
      profileLocationEvents.first() as EventRecord | undefined,
      EventRecord.fromLocation(profile.location)
    );

    return shouldShowLastLocation(profile.location.time, currentTime, timezone)
      ? lastLocation(location, devicesMap.get.bind(devicesMap))
      : undefined;
  }
);

export const getIsEmptyTimeline = (state: State) =>
  getEvents(state).size === 0 &&
  getActivityTimelineFilterBy(state) === ActivityEventFilters.All;
