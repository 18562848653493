import * as React from 'react';
import PageSectionLayout from '../../Layout/PageSectionLayout';
import PageLayout from '../../Layout/PageLayout';
import { t } from '../../../lib/i18n';
import { ScreenSize } from '../../RenderWhen/RenderWhen';
import RoutinesDrawerMenu from '../RoutinesDrawerMenu/RoutinesDrawerMenu';
import type { RoutinesDrawerMenuProps } from '../RoutinesDrawerMenu/RoutinesDrawerMenu';
import { FlexLayout, Layout, List as StyleGuideList } from 'styleguide-react';
import { List } from 'immutable';
import { RoutineRecord } from '../../../records/routines/types/Routine.types';
import RoutinesEmptyState from '../RoutinesEmptyState/RoutinesEmptyState';
import RoutineListItem, {
  RoutineListItemProps,
} from '../RoutinesList/RoutineListItem';
import Loader from '../../base/Loader';
import { getRoutinesIcon } from '../helpers/routineIcons';
import { RoutineColor } from '../../../palettes/RoutineColor';
import {
  doesRoutineConsumeDailyTimeQuota,
  isRoutinePaused,
  isRoutineTypeBlocked,
} from '../../../ducks/routines/helpers';
import {
  renderErrorState,
  renderPageSubtitle,
  renderRoutinesPageSubtitle,
  renderRoutinesTopMenu,
} from './common';
import RoutinesPremiumFlyover from './RoutinesPremiumFlyover';
import { ISOLanguage } from '../../../constants';
import { RoutineFeatureAccessLevel } from '../routines.types';
import CalendarView from '../../CalendarView/CalendarView';
import { Moment } from 'moment-timezone';
import { ScheduleRecord } from '../../../records/routines/schedule/types/Schedule.types';
import { RoutinesCalendarViewData, routinesToCalendarData } from './helpers';
import { CalendarViewWeekday } from '../../CalendarView/types/CalendarView.types';
import { MINUTES_IN_AN_HOUR } from '../../../helpers/dates';

const ROUTINE_PAGE_TEST_ID = 'routine-page';

export type ActiveViewTab = 'list' | 'calendar';
export interface RoutinesPageProps {
  profileId: string;
  accountTimezone: string;
  currentTime: Moment;
  isMilitaryTime: boolean;
  accountLocale: ISOLanguage;
  isFetching: boolean;
  isFetchingSchedules: boolean;
  isError: boolean;
  featureAccessLevel: RoutineFeatureAccessLevel;
  isOpenMenu: RoutinesDrawerMenuProps['isOpen'];
  activeMenuStep: RoutinesDrawerMenuProps['activeStep'];
  routinesList: List<RoutineRecord>;
  schedulesList: List<ScheduleRecord>;
  onAddNewRoutine: () => void;
  onEditRoutine: (routineUid: RoutineRecord['uid']) => void;
  onOpenInfoModal: (profileId: string) => () => void;
  onUpgrade: () => void;
  trackToggleRoutineView: (activeTab: ActiveViewTab) => void;
  trackCalendarSlotClick: (
    routineUids: string[],
    weekday: CalendarViewWeekday
  ) => void;
}

type RenderRoutinesContainerProps = Pick<
  RoutinesPageProps,
  | 'routinesList'
  | 'onAddNewRoutine'
  | 'onEditRoutine'
  | 'featureAccessLevel'
  | 'accountTimezone'
  | 'currentTime'
  | 'isMilitaryTime'
  | 'isFetchingSchedules'
  | 'accountLocale'
> & {
  activeViewTab: ActiveViewTab;
  onChangeActiveViewTab: (tab: ActiveViewTab) => void;
  calendarSlotData: RoutinesCalendarViewData[];
  onClickCalendarSlot: (slot: RoutinesCalendarViewData) => void;
  onClickNewCalendarSlot: (
    weekday: CalendarViewWeekday,
    startTime: string
  ) => void;
};

const getRoutineStatus = (
  routine: RoutineRecord,
  featureAccessLevel: RoutineFeatureAccessLevel
): RoutineListItemProps['status'] => {
  if (featureAccessLevel === RoutineFeatureAccessLevel.guest) return 'upgrade';
  if (
    featureAccessLevel === RoutineFeatureAccessLevel.routineBlocking &&
    !isRoutineTypeBlocked(routine)
  ) {
    return 'upgrade';
  }

  return isRoutinePaused(routine) ? 'disabled' : 'enabled';
};

const renderRoutinesContainer = ({
  routinesList,
  featureAccessLevel,
  activeViewTab,
  accountTimezone,
  accountLocale,
  currentTime,
  isMilitaryTime,
  calendarSlotData,
  isFetchingSchedules,
  onAddNewRoutine,
  onEditRoutine,
  onChangeActiveViewTab,
  onClickCalendarSlot,
  onClickNewCalendarSlot,
}: RenderRoutinesContainerProps) => {
  const isRoutinesListEmpty = !routinesList || routinesList.size === 0;

  if (isRoutinesListEmpty) {
    return (
      <RoutinesEmptyState
        accountLocale={accountLocale}
        onAddNewRoutine={onAddNewRoutine}
      />
    );
  }

  const RoutinesList = () => (
    <StyleGuideList
      testId={`${ROUTINE_PAGE_TEST_ID}-list-view`}
      allowCustomListItemStyle={RoutineListItem}
    >
      {routinesList.map(routine => (
        <RoutineListItem
          testId={`${ROUTINE_PAGE_TEST_ID}-item-${routine.name}`}
          key={routine.uid}
          title={routine.name}
          subtitle={routine.description}
          icon={getRoutinesIcon(routine.icon)}
          color={routine.color as RoutineColor}
          status={getRoutineStatus(routine, featureAccessLevel)}
          onClick={() => onEditRoutine(routine.uid)}
          showBadge={doesRoutineConsumeDailyTimeQuota(routine)}
        />
      ))}
    </StyleGuideList>
  );

  const RoutineCalendarView = () => (
    <CalendarView
      testId={`${ROUTINE_PAGE_TEST_ID}-calendar-view`}
      slots={calendarSlotData}
      isMilitaryTime={isMilitaryTime}
      currentTime={currentTime}
      timezone={accountTimezone}
      isLoading={isFetchingSchedules}
      autoScrollMinutes={MINUTES_IN_AN_HOUR * 6} // 6am
      onClickSlot={onClickCalendarSlot}
      onCreateNewSlot={onClickNewCalendarSlot}
    />
  );

  return (
    <React.Fragment>
      <Layout width="100%" marginBottom="32" testId={ROUTINE_PAGE_TEST_ID}>
        {renderRoutinesTopMenu({
          activeViewTab,
          onChangeActiveViewTab,
          onAddNewRoutine,
          testId: ROUTINE_PAGE_TEST_ID,
        })}
      </Layout>
      {activeViewTab === 'list' && RoutinesList()}
      {activeViewTab === 'calendar' && (
        <Layout height="584">{RoutineCalendarView()}</Layout>
      )}
    </React.Fragment>
  );
};

const RoutinesPage = ({
  profileId,
  accountLocale,
  isFetching,
  isFetchingSchedules,
  isError,
  featureAccessLevel,
  isOpenMenu,
  activeMenuStep,
  routinesList,
  schedulesList,
  accountTimezone,
  currentTime,
  isMilitaryTime,
  onAddNewRoutine,
  onEditRoutine,
  onOpenInfoModal,
  onUpgrade,
  trackToggleRoutineView,
  trackCalendarSlotClick,
}: RoutinesPageProps) => {
  const [activeViewTab, setActiveViewTab] =
    React.useState<ActiveViewTab>('list');

  const [calendarSlotData, setCalendarSlotData] = React.useState<
    RoutinesCalendarViewData[]
  >([]);

  React.useEffect(() => {
    if (
      routinesList.size &&
      schedulesList.size &&
      activeViewTab === 'calendar'
    ) {
      const data = routinesToCalendarData(
        routinesList,
        schedulesList,
        isMilitaryTime,
        accountTimezone
      );
      setCalendarSlotData(data);
    }
  }, [
    schedulesList,
    routinesList,
    schedulesList,
    accountTimezone,
    activeViewTab,
  ]);

  const onChangeActiveViewTab = (tab: ActiveViewTab) => {
    if (activeViewTab !== tab) {
      setActiveViewTab(tab);
      trackToggleRoutineView(tab);
    }
  };

  const onClickCalendarSlot = (slot: RoutinesCalendarViewData) => {
    if (!slot.metadata?.routineUids?.length) return undefined;

    const isSingleCalendarSlot = slot.metadata.routineUids.length === 1;
    if (isSingleCalendarSlot) onEditRoutine(slot.metadata.routineUids[0]);
    trackCalendarSlotClick(slot.metadata.routineUids, slot.weekday);
  };

  const onClickNewCalendarSlot = (weekday: CalendarViewWeekday) => {
    if (!weekday) return undefined;
    trackCalendarSlotClick([], weekday);
  };

  const isRoutinesListEmpty = !routinesList || routinesList.size === 0;
  const isGuestFeatureAccess =
    isRoutinesListEmpty &&
    featureAccessLevel === RoutineFeatureAccessLevel.guest;

  const subtitle = !isRoutinesListEmpty
    ? renderPageSubtitle(ROUTINE_PAGE_TEST_ID, onOpenInfoModal(profileId))
    : undefined;

  if (!isFetching && !isError && isGuestFeatureAccess) {
    return (
      <RoutinesPremiumFlyover
        onUpgrade={onUpgrade}
        accountLocale={accountLocale}
      />
    );
  }

  return (
    <PageLayout
      title={t('Routines')}
      subtitle={subtitle}
      configuration={[
        {
          screenSize: ScreenSize.All,
          mainInsideCard: true,
          mainReducedSize: true,
          columns: 1,
        },
      ]}
      actions={[{ actionName: 'back' }]}
    >
      <PageSectionLayout>
        {renderRoutinesPageSubtitle(subtitle, isRoutinesListEmpty)}

        {isFetching && (
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            crossaxisAlignment="center"
            flexGrow="1"
            testId="par-loader"
          >
            <Loader size="small" />
          </FlexLayout>
        )}
        {!isFetching && isError && renderErrorState()}
        {!isFetching &&
          !isError &&
          renderRoutinesContainer({
            featureAccessLevel,
            routinesList,
            activeViewTab,
            accountTimezone,
            currentTime,
            isMilitaryTime,
            calendarSlotData,
            isFetchingSchedules,
            accountLocale,
            onAddNewRoutine,
            onEditRoutine,
            onChangeActiveViewTab,
            onClickCalendarSlot,
            onClickNewCalendarSlot,
          })}

        <RoutinesDrawerMenu
          profileId={profileId}
          isOpen={isOpenMenu}
          toggle={onAddNewRoutine}
          activeStep={activeMenuStep}
        />
      </PageSectionLayout>
    </PageLayout>
  );
};

export default RoutinesPage;
