import { UnlockAlertsAndTimeLimitsModalReferrer } from '../containers/Modal/UnlockAlertsAndTimeLimitsModalContainer';
import { SetupAlertsModalReferrer } from '../containers/Modal/SetupAlertsModalContainer';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { getLocationPathname } from '../selectors/routing';
import { BaseThunk } from '../store/state';

export const showUnlockCompleteModal =
  (): BaseThunk => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const currentPath = getLocationPathname(getState());
    return dispatch(
      navigate({
        type: 'inner',
        src: `${currentPath}/modal/UnlockComplete`,
      })
    );
  };

export const showPremiumFlyover =
  (premiumFlyoverUrl: string): BaseThunk =>
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: premiumFlyoverUrl,
      })
    );
  };

export const showUnlockAlertsAndTimeLimitsModal =
  (referrer: UnlockAlertsAndTimeLimitsModalReferrer): BaseThunk =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const currentPath = getLocationPathname(getState());
    return dispatch(
      navigate({
        type: 'inner',
        src: `${currentPath}/modal/UnlockAlertsAndTimeLimitsModal?referrer=${referrer}`,
      })
    );
  };

export const showSetupAlertsModal =
  (referrer: SetupAlertsModalReferrer): BaseThunk =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const currentPath = getLocationPathname(getState());
    return dispatch(
      navigate({
        type: 'inner',
        src: `${currentPath}/modal/SetupAlertsModal?referrer=${referrer}`,
      })
    );
  };
