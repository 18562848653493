import TagManager from 'react-gtm-module';
import { isBrowserPlatform } from './index';
import * as qinit from '../qinit';

export enum TagManagerCustomEvents {
  Navigation = 'par.navigation',
  AccountLoggedIn = 'par.account_logged_in',
  AccountLoggedOut = 'par.account_logged_out',
  PurchaseComplete = 'par.purchase_complete',
}

export const tagManagerSetup = (
  accountUid?: string,
  accountLocale?: string,
  currency?: string
) => {
  setupDataLayer();
  if (isBrowserPlatform() && !isTagManagerInitialized()) {
    // eslint-disable-next-line camelcase
    const { gtm_id, auth, preview } =
      qinit.tenant.parents_app.google_tag_manager;
    const tagManagerArgs = {
      gtmId: gtm_id,
      auth,
      preview,
      dataLayer: {
        accountUid,
        accountLocale,
        currency,
      },
      events: {
        event: [TagManagerCustomEvents.Navigation],
        currentPath: window.location.href,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const isTagManagerInitialized = () =>
  window.dataLayer.find(element => element.event === 'gtm.start') !== undefined;

const setupDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
};

export const sendTagManagerNavigationEvent = () => {
  setupDataLayer();
  const filteredData = window.dataLayer
    .filter(
      data =>
        Object.prototype.hasOwnProperty.call(data, 'event') &&
        data.event === TagManagerCustomEvents.Navigation
    )
    .slice(-1);
  if (filteredData.length === 0) {
    window.dataLayer.push({
      event: TagManagerCustomEvents.Navigation,
      currentPath: window.location.href,
    });
    return;
  }
  if (filteredData[0].currentPath !== window.location.href) {
    window.dataLayer.push({
      event: TagManagerCustomEvents.Navigation,
      currentPath: window.location.href,
    });
  }
};

export const sendTagManagerEvent = (
  eventName: TagManagerCustomEvents,
  extraData?: Record<string, unknown>
) => {
  setupDataLayer();
  // check last event sent to GTM, if it is the same event we don't send it again
  const filteredData = window.dataLayer
    // eslint-disable-next-line no-prototype-builtins
    .filter(data => data.hasOwnProperty('event'))
    .slice(-1);
  if (filteredData.length > 0 && filteredData[0].event !== eventName) {
    window.dataLayer.push({ event: eventName, ...extraData });
  }
};
