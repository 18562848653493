import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import {
  loadStudentMostUsedCategoriesActivity,
  loadStudentMostUsedSignaturesActivity,
} from '../../ducks/studentSummary';
import {
  getStudentSummaryCategories,
  getStudentSummarySignatures,
  getSummaryFetchCategoriesError,
  getSummaryFetchSignaturesError,
  isFetchingCategories,
  isFetchingSignatures,
} from '../../selectors/studentSummary';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import StudentTopWidgets from '../../components/Schools/StudentTopWidget';
import { getProfile } from '../../selectors/profileRaw';
import {
  navigateAllCategoriesDetailPage,
  navigateAllSignaturesDetailPage,
} from '../../actions/SchoolActions';
import { getCurrentDashboardPageName } from '../../ducks/unifiedNowCard/thunks';
import { ButtonNames, trackButtonClicked } from '../../helpers/analytics';
import { getIsSchoolAndFamilyProfile } from '../Dashboard/helpers/profileDashboardHelpers';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  getIsFetchingStudentPolicy,
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';

interface Props {
  profileId: string;
}

const mapStateToProps = (state: State, { profileId }: Props) => {
  const topCategory = getStudentSummaryCategories(state).first()?.categoryName;
  const topSignature = getStudentSummarySignatures(state).first()?.name;

  const isCombined = getIsSchoolAndFamilyProfile(state, Number(profileId));
  const isDelegated = isProfileDelegatedToThisAccount(
    isDelegationEnabled(state),
    isStudentOptInForDelegationForThisAccount(state),
    true
  );

  return {
    topCategory,
    topSignature,
    errorOnLoading:
      getSummaryFetchCategoriesError(state) ||
      getSummaryFetchSignaturesError(state),
    isFeatureDisabled:
      getSummaryFetchCategoriesError(state) ===
        StudentSummaryFetchError.FeatureDisabled ||
      getSummaryFetchSignaturesError(state) ===
        StudentSummaryFetchError.FeatureDisabled,
    isLoadingCategories:
      isFetchingCategories(state) ||
      isFetchingSignatures(state) ||
      getIsFetchingStudentPolicy(state),
    profileName: getProfile(state, profileId)?.name ?? '',
    isCombined,
    isDelegated,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { profileId }: Props) => ({
  loadWidget: () => {
    dispatch(loadStudentMostUsedCategoriesActivity({ profileId }));
    dispatch(loadStudentMostUsedSignaturesActivity({ profileId }));
  },
  navigateToAllCategories: () => {
    dispatch((_dispatch, getState) => {
      const state = getState();
      const pageName = getCurrentDashboardPageName(state);

      trackButtonClicked(pageName, ButtonNames.seeAllStudentCategories);
    });
    dispatch(navigateAllCategoriesDetailPage(profileId));
  },
  navigateToAllSignatures: () => {
    dispatch((_dispatch, getState) => {
      const state = getState();
      const pageName = getCurrentDashboardPageName(state);

      trackButtonClicked(pageName, ButtonNames.seeAllStudentSignatures);
    });
    dispatch(navigateAllSignaturesDetailPage(profileId));
  },
});

const StudentTopWidgetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentTopWidgets);

export type StudentTopWidgetsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentTopWidgetsContainer;
