import * as React from 'react';
import { t } from '../../lib/i18n';
import { UrlReputationRecord, ReputationLevel } from '../../records/urlDetails';
import * as providerLogo from '../../assets/base/images/wot.png';
import { DomainTrustSeal, FlexLayout } from 'styleguide-react';

const mapReputationLevel = {
  [ReputationLevel.LEVEL_1]: {
    level: 'very-poor',
    description: 'Very poor',
  },
  [ReputationLevel.LEVEL_2]: { level: 'poor', description: 'Poor' },
  [ReputationLevel.LEVEL_3]: {
    level: 'unsatisfactory',
    description: 'Unsatisfactory',
  },
  [ReputationLevel.LEVEL_4]: { level: 'good', description: 'Good' },
  [ReputationLevel.LEVEL_5]: {
    level: 'excellent',
    description: 'Excellent',
  },
  [ReputationLevel.LEVEL_UNKNOWN]: {
    level: 'unknown',
    description: 'Unknown',
  },
};

const UrlDetailsDomainTrust: React.FunctionComponent<{
  reputation: UrlReputationRecord;
}> = ({ reputation }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    marginTop="32"
    marginBottom="24"
  >
    <FlexLayout mainaxis="row" mainaxisAlignment="space-around">
      <FlexLayout mainaxis="row" margin="16" maxWidth="240">
        <DomainTrustSeal
          mode="trustworthiness"
          level={mapReputationLevel[reputation.trustworthiness.level].level}
          description={t(
            mapReputationLevel[reputation.trustworthiness.level].description
          )}
          title={t('Trustworthiness')}
        />
      </FlexLayout>
      <FlexLayout mainaxis="row" margin="16" maxWidth="240">
        <DomainTrustSeal
          mode="child-safety"
          level={mapReputationLevel[reputation.childSafety.level].level}
          description={t(
            mapReputationLevel[reputation.childSafety.level].description
          )}
          title={t('Child safety')}
        />
      </FlexLayout>
    </FlexLayout>
    <FlexLayout mainaxis="row" mainaxisAlignment="center" marginTop="24">
      <img src={providerLogo} alt="provider-logo" width="150" />
    </FlexLayout>
  </FlexLayout>
);

export default UrlDetailsDomainTrust;
