import * as React from 'react';
import { GlobalType, Tag } from 'styleguide-react';
import { t } from '../../../../lib/i18n';

const UpgradeLabel = () => {
  return (
    <Tag
      text={t('Upgrade')}
      testId="upgrade"
      variant="squared"
      type={GlobalType.booster}
    />
  );
};

export { isVisibleUpgradeLabel } from './predicate';
export default UpgradeLabel;
