import { HoursPerDayActivityPayload } from '../records/activity/types/HoursPerDayActivity.types';
import { MinutesPerHourActivityTransformedPayload } from '../records/activity/types/MinutesPerHourActivity.types';
import { captureException } from './sentry';

export const captureScreenTimeRangeMismatch = (
  payload: HoursPerDayActivityPayload
) => {
  if (
    payload?.default_rules_minutes == null &&
    payload?.routines_minutes == null
  ) {
    return;
  }

  const totalMinutesSum =
    (payload?.default_rules_minutes || 0) +
    (payload?.routines_minutes || []).reduce(
      (sum, routine) => sum + routine.minutes,
      0
    );

  if (payload.minutes === totalMinutesSum) return;

  captureException(
    new Error('Screentime summary range: Total and rules screentime mismatch'),
    {
      extra: { payload },
      level: 'error',
    }
  );
};

export const captureScreenTimeSelectedDayMismatch = (
  payload: MinutesPerHourActivityTransformedPayload
) => {
  if (
    payload?.default_rules_screen_time == null &&
    payload?.routines_screen_time == null
  ) {
    return;
  }

  const totalMinutesSum =
    (payload?.default_rules_screen_time || 0) +
    (payload?.routines_screen_time || []).reduce(
      (sum, routine) => sum + routine.seconds,
      0
    );

  if (payload.screen_time_seconds === totalMinutesSum) return;

  captureException(
    new Error(
      'Screentime summary selected day: Total and rules screentime mismatch'
    ),
    {
      extra: { payload },
      level: 'error',
    }
  );
};
