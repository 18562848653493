import * as React from 'react';

import {
  FlexLayout,
  IconType,
  Layout,
  TransparentButton,
  IconColor,
  Icon,
  FreeText,
} from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacing } from 'styleguide-react/build/components/Layout/types';

import { t } from '../../../lib/i18n';
import downloadSvg from '../../../assets/base/images/onboarding/download-link.svg';
import OnboardingShareSection from './OnboardingShareSection';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import type { OnboardingDownloadProps } from '../../../containers/Onboarding/OnboardingDownloadContainer';

const OnboardingDownload: React.FunctionComponent<OnboardingDownloadProps> = ({
  profile,
  onClickSocialShareCopy,
  onClickSocialShareFacebook,
  onClickBack,
}: OnboardingDownloadProps) => {
  const renderContent = (margin?: LayoutSpacing) => (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      marginLeft={margin}
      marginRight={margin}
    >
      <FlexLayout
        mainaxis="row"
        alignSelf="flex-start"
        crossaxisAlignment="center"
      >
        <TransparentButton onClick={onClickBack}>
          <FlexLayout mainaxis="row" crossaxisAlignment="center">
            <Layout marginRight="8" renderAs="span">
              <Icon type={IconType.arrowLeft} color={IconColor.black} />
            </Layout>
            <FreeText fontSize="12px">{t('Back')}</FreeText>
          </FlexLayout>
        </TransparentButton>
      </FlexLayout>
      <Layout minHeight="200" minWidth="200">
        <img src={downloadSvg} alt="Icon Download" />
      </Layout>
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        marginTop="16"
        marginBottom="48"
      >
        <h3>
          <strong>{t("Great! Now let's add a device")}</strong>
        </h3>
        <OnboardingShareSection
          shareSectionText={t(
            'Head to {{profileName}}’s device and open the link below in a browser window.',
            {
              profileName: profile?.name,
            }
          )}
          onClickSocialShareCopy={onClickSocialShareCopy}
          onClickSocialShareFacebook={onClickSocialShareFacebook}
          socialShareGroupText={t(
            'Share the link with {{profileName}}’s device',
            { profileName: profile?.name }
          )}
          showDownloadLink
        />
      </FlexLayout>
    </FlexLayout>
  );
  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {renderContent('104')}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderContent()}
      </RenderWhen>
    </React.Fragment>
  );
};
export default OnboardingDownload;
