import { RequestTransformer } from '../../types/api';

export const createRequestParamsTransformer = ({
  endpoints,
  swappableParams,
}: {
  endpoints: string[];
  swappableParams: object;
}) => {
  return {
    name: RequestTransformer.ParamsTransformer,
    transformApplies: ({ name }): boolean => endpoints.includes(name),
    transform: (params: object) => Object.assign(params, swappableParams),
  };
};
