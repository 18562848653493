import * as React from 'react';
import { TabPanel, Tabs } from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  TabsInfo,
  TimelineSelectorProps,
  TimelineType,
} from './TimelineSelector.types';

const getFamilyTabTitle = (isOnlyDelegatedDevice: boolean): string => {
  if (isOnlyDelegatedDevice) return t('Outside school hours');

  return t('Family activity');
};

const getSchoolTabTitle = (isOnlyDelegatedDevice: boolean): string => {
  if (isOnlyDelegatedDevice) return t('During school hours');

  return t('School activity');
};

const TimelineSelector = ({
  tabSelected,
  displayTabs,
  isOnlyDelegatedDevice,
  navigateToTimeline,
}: TimelineSelectorProps) => {
  const tabsInfo: TabsInfo = [
    { name: 'family', title: getFamilyTabTitle(isOnlyDelegatedDevice) },
    { name: 'school', title: getSchoolTabTitle(isOnlyDelegatedDevice) },
  ];
  if (!displayTabs) {
    return null;
  }
  return (
    <Tabs
      onChange={type => {
        if (type !== tabSelected) {
          navigateToTimeline(type as TimelineType);
        }
      }}
      className="par-timeline-selector__tabs"
    >
      {tabsInfo.map(tab => (
        <TabPanel
          key={tab.name}
          name={tab.name}
          content={tab.title}
          active={tabSelected === tab.name}
          testId={`timeline-selector-${tab.name}`}
        >
          <React.Fragment />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default TimelineSelector;
