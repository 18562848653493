import * as React from 'react';
import { GroupHeader } from 'styleguide-react';
import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import AppRulesNotifications, {
  AppRulesNotificationsProps,
} from './AppRulesNotifications';

const AppRulesSettingsPage = (props: AppRulesNotificationsProps) => (
  <PageLayout
    title={t('Settings')}
    actions={[{ screenSize: ScreenSize.MobileOrTablet, actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        mainInsideCard: true,
        columns: 1,
      },
    ]}
  >
    <GroupHeader>{t('Notifications')}</GroupHeader>
    <AppRulesNotifications {...props} />
  </PageLayout>
);

export default AppRulesSettingsPage;
