import * as React from 'react';
import { Modal, Button, ButtonSize } from 'styleguide-react';
import { modalDimensions } from '../../constants/index';
import type { SchoolSummaryInfoModalProps } from '../../containers/Modal/SchoolSummaryInfoModalContainer';

const SchoolSummaryInfoModal: React.FunctionComponent<
  SchoolSummaryInfoModalProps
> = ({ title, content, closeText, onClose }: SchoolSummaryInfoModalProps) => {
  return (
    <Modal
      testId="schoolSummaryInfoModal"
      title={title}
      onClickClose={onClose}
      width={modalDimensions.regular.width}
      buttons={[
        <Button
          key="schoolSummaryInfoModalClose__Button"
          onClick={onClose}
          size={ButtonSize.medium}
          testId="schoolSummaryInfoModalClose"
        >
          {closeText}
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

export default SchoolSummaryInfoModal;
