import * as React from 'react';
import { List } from 'immutable';
import { EmptyPlaceholder, GlobalType, Icon, IconType } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { ActivityRecord } from '../../records/activity';
import ActivityList, { DisplayUnit } from './ActivityList';
import { ActivityType } from './sharedModels';
import { SummaryDateRanges } from '../../constants';
import SummaryCard from '../ActivityCards/SummaryCard';
import { infiniteScroll } from '../../helpers/hocs';

export interface ActivitySummaryListProps {
  displayAll?: boolean;
  type: ActivityType;
  title: string;
  iconEmptyCard: JSX.Element;
  icon: JSX.Element;
  items: List<ActivityRecord>;
  displayUnit: DisplayUnit;
  noActivityText: string;
  showAllAvailableActivity: boolean;
  needsPagination: boolean;
  activeDateRange: SummaryDateRanges;
  isFetching: boolean;
  width?: string;
  height?: string;
  profileUid: string;
  footerText?: string;
  isFeaturePremium?: boolean;
  onExpand: (isFeaturePremium?: boolean) => {};
  handleMore: (limit: string) => any;
  hasExpandLink?: boolean;
  goToInfoModal: () => {};
}

export const ActivitySummaryListComponent: React.FunctionComponent<
  ActivitySummaryListProps
> = ({
  displayAll,
  type,
  title,
  iconEmptyCard,
  icon,
  items,
  displayUnit,
  noActivityText,
  onExpand,
  showAllAvailableActivity,
  width,
  height,
  needsPagination,
  activeDateRange,
  isFetching,
  profileUid,
  footerText,
  isFeaturePremium,
  handleMore,
  goToInfoModal,
}: ActivitySummaryListProps) => {
  const isEmpty = items.size === 0;
  const hasSeeAllButton = !isEmpty && Boolean(onExpand);
  return (
    <SummaryCard
      displayAll={displayAll}
      type={GlobalType.white}
      isEmpty={isEmpty}
      onExpand={onExpand}
      needsPagination={needsPagination}
      handleMore={handleMore}
      isFetchingMore={isFetching}
      itemsLength={items.size}
      showAllAvailableActivity={showAllAvailableActivity}
      activeDateRange={activeDateRange}
      width={width}
      height={height}
      body={
        isEmpty ? (
          <EmptyPlaceholder
            icon={iconEmptyCard}
            text={noActivityText}
            fillAvailableSpace
            centered
            maxWidth="75%"
            smallText
          />
        ) : (
          <ActivityList
            profileUid={profileUid}
            type={type}
            items={items}
            displayUnit={displayUnit}
            showAllAvailableActivity={showAllAvailableActivity}
            isFeaturePremium={isFeaturePremium}
          />
        )
      }
      header={{
        title,
        icon,
        actionElement: <Icon type={IconType.infoCircle} />,
        onClickAction: goToInfoModal,
        type: getSummaryCardType(type),
      }}
      footer={{
        actionElement: getFooterActionElement(hasSeeAllButton, footerText),
        onClickAction: () => onExpand(isFeaturePremium),
      }}
    />
  );
};

const getFooterActionElement = (showAction: boolean, text?: string) => {
  if (!showAction) return undefined;
  if (text) return text;
  return t('See more');
};

const getSummaryCardType = (type: ActivityType) =>
  type === ActivityType.QuestionableWebSearches ||
  type === ActivityType.BlockedWebs
    ? GlobalType.error
    : GlobalType.default;

const ActivitySummaryList = infiniteScroll<ActivitySummaryListProps>(
  ActivitySummaryListComponent
);
export default ActivitySummaryList;
