/*
 * @todo: Move helper to:
 * - src/helpers/multiPlatformNavigation.ts.
 * - src/sideEffects/browser/index.ts.
 */
import { Email } from '../constants';

const parseEmailParams = (subject: string, body: string): string => {
  const params = {
    subject,
    body,
  };

  return Object.keys(params)
    .filter(key => typeof params[key] === 'string' && params[key] !== '')
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
};

export const createEmailHref = (
  email: Email,
  subject = '',
  body = ''
): string => {
  return [
    `mailto:${encodeURIComponent(email)}`,
    parseEmailParams(subject, body),
  ].join('?');
};

export const createMySchoolEmailHref = (subject: string): string =>
  createEmailHref(Email.mySchool, subject);
