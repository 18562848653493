import * as React from 'react';
import {
  Icon,
  IconType,
  Layout,
  List,
  SelectableListItem,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineStepWrapper from '../RoutineStepWrapper';
import { ChooseTimeLimitProps } from './types/ChooseTimeLimitProps.types';
import { TimeLimitType } from '../../routines.types';
import RoutinesInfoAlert from '../../RoutinesInfoAlert/RoutinesInfoAlert';

enum ChooseTimeLimitTest {
  prefix = 'routine-choose-time-limit-step',
}

const ChooseTimeLimit = ({
  prev,
  close,
  next,
  timeLimitType,
}: ChooseTimeLimitProps) => {
  const [selectedTimeLimitType, setSelectedTimeLimitType] =
    React.useState<TimeLimitType>(
      timeLimitType || 'DONT_APPLY_DAILY_TIME_LIMIT'
    );

  const onUpdateRule = (id: TimeLimitType) => {
    setSelectedTimeLimitType(id);
  };

  const onNext = () => {
    next?.(selectedTimeLimitType);
  };

  return (
    <RoutineStepWrapper
      testId={ChooseTimeLimitTest.prefix}
      prev={prev}
      close={close}
      next={onNext}
      fixedFooter
      footerText={t('Continue')}
      footerAlert={
        <RoutinesInfoAlert
          icon={IconType.infoCircle}
          text={t(
            'This setting can’t be changed after you’ve created the routine'
          )}
        />
      }
    >
      <Layout>
        <Typography type="h4" weight="semibold" className="par-util-break-word">
          {t('Choose a time limit option')}
        </Typography>
        <Typography type="body2">
          {t(
            'Decide how you want this routine to interact with the daily time limits you’ve set.'
          )}
        </Typography>
      </Layout>

      <Layout marginTop="40">
        <List
          listItemMarginBottom="16"
          ariaRole="radiogroup"
          onClickItem={onUpdateRule as never}
          testId={ChooseTimeLimitTest.prefix}
        >
          <SelectableListItem
            id="DONT_APPLY_DAILY_TIME_LIMIT"
            title={t('Recommended')}
            description={t('Routine will ignore any daily time limits')}
            icon={<Icon type={IconType.star} />}
            selected={selectedTimeLimitType === 'DONT_APPLY_DAILY_TIME_LIMIT'}
            type="radio"
            testId="dont-apply-time-limit"
          />
          <SelectableListItem
            id="APPLY_DAILY_TIME_LIMITS"
            title={t('Apply daily time limits')}
            description={t(
              'Devices will be blocked when daily time limit is reached'
            )}
            icon={<Icon type={IconType.hourglassHalf} />}
            selected={selectedTimeLimitType === 'APPLY_DAILY_TIME_LIMITS'}
            type="radio"
            testId="apply-daily-time-limits"
          />
        </List>
      </Layout>
    </RoutineStepWrapper>
  );
};

export default ChooseTimeLimit;
