import * as React from 'react';
import {
  AlertBox,
  Button,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import type { NowCardInfoModalProps } from '../../containers/Modal/NowCardInfoModalContainer';
import { UnifiedNowCardState } from '../../businessLogic/unifiedNowCard/types';
import { t } from '../../lib/i18n';

const isNoActivityCase = (status: UnifiedNowCardState) =>
  status === 'QUSTODIO_NO_ACTIVITY_ALL' ||
  status === 'QUSTODIO_NO_ACTIVITY_ALL_LONG_TIME';

const getTitle = (
  status: UnifiedNowCardState,
  { daysUntilLastSeen }: Partial<NowCardInfoModalProps>
): string => {
  if (isNoActivityCase(status)) {
    return t('No activity for {{days}} days', { days: daysUntilLastSeen });
  }
  throw new Error(`Invalid state ${status} in the now card info modal`);
};

const getText = (
  status: UnifiedNowCardState,
  { daysUntilLastSeen, profileName }: Partial<NowCardInfoModalProps>
) => {
  if (isNoActivityCase(status)) {
    return (
      <ModalStyledText marginBottom="8">
        {t(
          'We haven’t detected any activity on {{profileName}}’s device for {{days}} days.',
          { days: daysUntilLastSeen, profileName }
        )}
      </ModalStyledText>
    );
  }
  throw new Error(`Invalid state ${status} in the now card info modal`);
};

const getButtons = (
  status: UnifiedNowCardState,
  { onClickClose, onNavigateToNoActivityHelp }: Partial<NowCardInfoModalProps>
) => {
  const buttons = [
    <Button
      key="button"
      onClick={onClickClose}
      size={ButtonSize.medium}
      block
      testId="smart-device-info-modal__confirm-button"
    >
      {t('Ok')}
    </Button>,
  ];
  if (isNoActivityCase(status)) {
    buttons.push(
      <Button
        buttonType={ButtonType.plain}
        key="button-need-help"
        onClick={onNavigateToNoActivityHelp}
        size={ButtonSize.medium}
        block
        testId="unified-now-card-info-modal__help-button"
      >
        {t('Need help?')}
      </Button>
    );
  }
  return buttons;
};

const statusWithInfoBox: Array<UnifiedNowCardState> = [
  'QUSTODIO_NO_ACTIVITY_ALL',
  'QUSTODIO_NO_ACTIVITY_ALL_LONG_TIME',
];
const getInfoBoxText = (status: UnifiedNowCardState) => {
  if (isNoActivityCase(status)) {
    return t(
      'Browse the internet for a few seconds on their device to check that protection is working.'
    );
  }
};
const getInfoBox = (status: UnifiedNowCardState) => {
  return statusWithInfoBox.includes(status) ? (
    <Layout marginTop="16">
      <AlertBox type="info" centered>
        {getInfoBoxText(status)}
      </AlertBox>
    </Layout>
  ) : null;
};

const NowCardInfoModal = ({
  status,
  daysUntilLastSeen,
  profileName,
  onClickClose,
  onNavigateToNoActivityHelp,
}: NowCardInfoModalProps) => {
  if (status === null) return null;
  return (
    <Modal
      title={getTitle(status, {
        daysUntilLastSeen,
      })}
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            color="secondary"
            size="regular"
            testId="smart-device-info-modal__Icon"
          />
        ),
      }}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={getButtons(status, { onClickClose, onNavigateToNoActivityHelp })}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="now-card-info-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
          {getText(status, { daysUntilLastSeen, profileName })}
          {getInfoBox(status)}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default NowCardInfoModal;
