import { connect } from 'react-redux';
import MenuOptions from '../../components/MenuOptions/MenuOptions';
import {
  getSelectedRule,
  getProfileId,
  getProfileRules,
  getProfileDevices,
  isStudentSummaryPath,
  getProfile,
  isRulesOrSubPagePath,
} from '../../selectors';
import { clickRule } from '../../actions/ProfileRulesActions';
import { getMenuOptions, getStudentMenuOptions } from './menuOptions';
import State from '../../store/state';
import type { MenuOptionsDeviceType } from './types/MenuOptionsDeviceType.types';
import { getIsSchoolOnlyProfile } from '../Dashboard/helpers/profileDashboardHelpers';
import flags from '../../sideEffects/flags';
import { isStudentOptInForDelegationForThisAccount } from '../../selectors/studentPolicies';
import { showRulesNavigationToStudentProfile } from '../../helpers/menu';

const mapStateToProps = (
  state: State,
  props: { deviceType?: MenuOptionsDeviceType }
) => {
  const profileId = getProfileId(state);

  let isDataReady = false;
  let isLinkedToSchool = false;
  let isOnlySchoolProfile = true;
  let useCombinedDashboardMenu = false;
  let isProfileDelegatedToThisAccount = false;
  let showRulesInStudentProfile = false;

  if (profileId) {
    isDataReady =
      getProfileRules(state, profileId) !== null &&
      getProfileDevices(state, profileId).size > 0;
    isLinkedToSchool =
      getProfile(state, profileId)?.isLinewizestudentLinked ?? false;
    isOnlySchoolProfile = getIsSchoolOnlyProfile(state, Number(profileId));
    useCombinedDashboardMenu =
      flags.useUnifiedDashboard.isEnabled() && flags.useDelegation.isEnabled();
    isProfileDelegatedToThisAccount =
      isStudentOptInForDelegationForThisAccount(state);
    showRulesInStudentProfile = showRulesNavigationToStudentProfile(
      state,
      Number(profileId)
    );
  }

  return {
    deviceType: props.deviceType,
    menuOptions:
      (isStudentSummaryPath(state) ||
        (isRulesOrSubPagePath(state) && isOnlySchoolProfile)) &&
      (isOnlySchoolProfile || !useCombinedDashboardMenu)
        ? getStudentMenuOptions(showRulesInStudentProfile)
        : getMenuOptions({
            isCombinedSummary: isLinkedToSchool,
            isProfileDelegatedToThisAccount,
          }),
    selectedRule: getSelectedRule(state),
    isDataReady,
    shouldRender: Boolean(profileId),
  };
};

const mapDispatchToProps = _dispatch => ({
  onClick: (menuOptionName: string) => {
    _dispatch((dispatch, getState) => {
      const profileId = getProfileId(getState())!;
      dispatch(clickRule(parseInt(profileId, 10))(menuOptionName));
    });
  },
});

const MenuOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuOptions);

export type MenuOptionsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default MenuOptionsContainer;
