import { ISOLanguage } from '../../../constants';
import { PeriodUnit } from '../../../helpers/dates';

/**
 * Since chargebee redirect url after a purchase has a character restriction we need to use shorter query parameters
 * otherwise we can't include all of them.
 *
 * Url example:
 * https://dev.qtd.io/r/t?p=ii%3D{{invoice.id}}%26a%3D{{invoice.amount}}%26c%3D{{invoice.currency_code}}%26pi%3D{{plan.id}}%26pn%3D{{plan.name}}%26pq%3D{{plan.quantity}}%26pp%3D{{plan.period}}%26ppu%3D{{plan.period_unit}}%26ce%3D{{customer.email}}%26ci%3D{{customer.id}}%26aui%3D{{customer.cf_uid}}%26nb%3D{{subscription.next_billing_at}}
 */
export type ChargebeeParams = {
  /** accountUid */
  aui: string;
  /** customerEmail */
  ce: string;
  /** customerId */
  ci: string;
  /** lang */
  l: ISOLanguage;
  /** planId */
  pi: string;
  /** planPrice */
  ppe: number;
  /** planName */
  pn: string;
  /** planQuantity */
  pq: string;
  /** planPeriod */
  pp: string;
  /** planPeriodUnit */
  ppu: string | PeriodUnit;
  /** currency */
  c: string;
  /** amount */
  a: string;
  /** invoiceId */
  ii: string;
  /** nextBilling */
  nb: string;
};

export const chargebeeParamsValidKeys: Array<keyof ChargebeeParams> = [
  'aui',
  'ce',
  'ci',
  'l',
  'pi',
  'ppe',
  'pn',
  'pq',
  'pp',
  'ppu',
  'c',
  'a',
  'ii',
  'nb',
];
