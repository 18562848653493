import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import CallsSMSRulesFlyover from './CallsSMSRulesFlyover';
import type { CallAndSMSRulesStartTrialFlyoverProps } from '../../containers/ProfileRules/CallsSMSRules/CallsAndSMSStartTrialFlyoverContainer';
import { SchoolTrialDuration } from '../../constants';

const START_TRIAL_FLYOVER_TEST_ID = 'calls-and-sms-rules-start-trial-flyover';

const CallsAndSMSRulesStartTrialFlyover = ({
  accountLocale,
  onClickClose,
  onClickStartTrial,
}: CallAndSMSRulesStartTrialFlyoverProps) => {
  return (
    <CallsSMSRulesFlyover
      accountLocale={accountLocale}
      testId={START_TRIAL_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Try for free'),
        onClick: onClickStartTrial,
        testId: `${START_TRIAL_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t(
        '*Calls & messages monitoring for iOS requires a Mac computer.'
      )}
      topbarText={t('Try calls & messages monitoring free for {{days}} days!', {
        days: SchoolTrialDuration,
      })}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default CallsAndSMSRulesStartTrialFlyover;
