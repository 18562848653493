import * as React from 'react';
import {
  AlertBox,
  AlertBoxType,
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  Typography,
} from 'styleguide-react';

import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { t } from '../../../../lib/i18n';
import IconTextCombo from '../../IconTextCombo/IconTextCombo';
import { getRoutinesIcon } from '../../helpers/routineIcons';
import TimeRangeSelect, {
  TimeRangeSelectProps,
} from './components/TimeRangeSelect';
import DaysSelect, { DaysSelectProps } from './components/DaysSelect';
import useMergedState from '../../../../hooks/useMergedState';
import {
  ContextStyle,
  MultiStepDataContext,
  RoutineMode,
  TimeSlot,
} from '../../routines.types';
import { when } from '../../../../helpers/render';
import { RoutineRecord } from '../../../../records/routines/types/Routine.types';

export enum ScheduleATimeSlotTest {
  prefix = 'routines-multi-step-schedule-time-slot',
}

export interface ScheduleATimeSlotProps
  extends RoutineStepWrapperHandlerProps<TimeSlot>,
    TimeRangeSelectProps,
    DaysSelectProps {
  name: ContextStyle['name'];
  description: ContextStyle['description'];
  color: ContextStyle['color'];
  icon: ContextStyle['icon'];
  mode: RoutineMode;
  timeSlotUid: MultiStepDataContext['editedTimeSlotUid'];
  routineUid: MultiStepDataContext['uid'];
  showBadge: boolean;
  isMilitaryTime: boolean;
  onDelete: (
    routineUid: MultiStepDataContext['uid'],
    timeSlotUid: MultiStepDataContext['editedTimeSlotUid']
  ) => void;
  isOverlapped: (timeSlot: TimeSlot) => {
    hasOverlap: boolean;
    routineUid: string | null;
    routine: RoutineRecord | null;
  };
}

const hasDaysSelected = (days: TimeSlot['days']) => days && days.length > 0;

const ScheduleAtimeSlot = ({
  name,
  description,
  icon,
  color,
  isMilitaryTime,
  timeRangeValues,
  days = [],
  daysValue = 'secondary-solid',
  timeSlotUid,
  routineUid,
  mode,
  showBadge = false,
  isOverlapped,
  onDelete,
  prev,
  close,
  next,
}: ScheduleATimeSlotProps) => {
  const [state, updateState] = useMergedState({
    days,
    from: timeRangeValues.from,
    to: timeRangeValues.to,
    uid: timeSlotUid,
    routineUid,
  });

  const [uiState, updateUiState] = useMergedState({
    hasValidationError: false,
    isFirstValidation: true,
  });

  const [hasInvalidTimeValue, setHasInvalidTimeValue] = useMergedState({
    from: false,
    to: false,
  });

  const overlap = isOverlapped(state);

  React.useEffect(() => {
    const isTimeValid = !hasInvalidTimeValue.from && !hasInvalidTimeValue.to;
    const isValid =
      uiState.isFirstValidation ||
      (hasDaysSelected(state.days) && !overlap.hasOverlap && isTimeValid);

    updateUiState({
      hasValidationError: !isValid,
      isFirstValidation: false,
    });
  }, [
    state.days,
    state.from,
    state.to,
    hasInvalidTimeValue.from,
    hasInvalidTimeValue.to,
  ]);

  return (
    <RoutineStepWrapper
      testId={ScheduleATimeSlotTest.prefix}
      prev={prev}
      close={close}
      next={() => {
        if (hasDaysSelected(state.days) && !overlap.hasOverlap) {
          next?.(state);
        } else {
          updateUiState({
            hasValidationError: true,
            isFirstValidation: false,
          });
        }
      }}
      footerText={mode === 'EDIT' ? t('Save') : t('Save routine')}
      fixedFooter
      footerButtonDisabled={uiState.hasValidationError || overlap.hasOverlap}
      footerContent={
        mode === 'EDIT' && state.uid ? (
          <FlexLayout mainaxis="column" crossaxisAlignment="center">
            <Button
              block
              buttonType={ButtonType.plain}
              size={ButtonSize.medium}
              color={ButtonColor.error}
              onClick={() => onDelete(state.routineUid, state.uid)}
              testId={`${ScheduleATimeSlotTest.prefix}-delete-time-slot`}
            >
              {t('Delete time slot')}
            </Button>
          </FlexLayout>
        ) : null
      }
      footerAlert={
        <Layout>
          {when(
            overlap.hasOverlap,
            <Layout marginBottom="8">
              <AlertBox
                type={AlertBoxType.error}
                rounded
                fullWidth
                showInfoIcon
                icon={<Icon type={IconType.exclamationCircle} />}
              >
                {t('{{routineName}} is already scheduled at this time', {
                  routineName: overlap?.routine?.name,
                })}
              </AlertBox>
            </Layout>
          )}
          {when(
            uiState.hasValidationError && !overlap.hasOverlap,
            <Layout>
              <AlertBox
                type={AlertBoxType.error}
                rounded
                fullWidth
                showInfoIcon
                icon={<Icon type={IconType.exclamationCircle} />}
              >
                {t('Please enter a time slot and select at least one day')}
              </AlertBox>
            </Layout>
          )}
        </Layout>
      }
    >
      <Layout>
        <Typography type="h4" weight="semibold">
          {mode === 'CREATE' && t('Schedule a time slot')}
          {mode === 'EDIT' &&
            (timeSlotUid ? t('Edit time slot') : t('Add a new time slot'))}
        </Typography>
      </Layout>

      <Layout marginBottom="56">
        <IconTextCombo
          title={name}
          subtitle={description}
          icon={getRoutinesIcon(icon)}
          iconColor={color}
          iconSize="xlarge"
          showBadge={showBadge}
        />
      </Layout>

      <Layout marginBottom="56">
        <TimeRangeSelect
          testId={`${ScheduleATimeSlotTest.prefix}-time-range`}
          isMilitaryTime={isMilitaryTime}
          timeRangeValues={{
            from: state.from,
            to: state.to,
          }}
          onChangeTimeRange={(label, value) => updateState({ [label]: value })}
          onValidationError={(label, isInvalid) =>
            setHasInvalidTimeValue({ [label]: isInvalid })
          }
        />
      </Layout>

      <DaysSelect
        days={state.days}
        daysValue={daysValue}
        onChangeDays={days => updateState({ days })}
      />
    </RoutineStepWrapper>
  );
};

export default ScheduleAtimeSlot;
