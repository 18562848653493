/* eslint-disable max-classes-per-file */
import * as R from 'ramda';
import {
  ERROR_TIMEOUT,
  ERROR_4XX,
  ERROR_5XX,
  ERROR_UNKNOWN,
  ERROR_UNAUTHORIZED,
  ERROR_NETWORK,
  ERROR_ABORT,
} from '../../constants';

export const STATUS_TIMEOUT = -1;
export const STATUS_NETWORK_ERROR = -2;
export const STATUS_MISSING_TOKENS_ERROR = -3;
export const STATUS_ABORT_ERROR = -4;

type FetchErrorStatus =
  | typeof STATUS_TIMEOUT
  | typeof STATUS_NETWORK_ERROR
  | typeof STATUS_MISSING_TOKENS_ERROR
  | typeof STATUS_ABORT_ERROR;

type RawErrorStatus = { status: FetchErrorStatus; statusText: string };

/**
 * Custom error class for API call errors.
 * Has a `type` property that classifies the error by HTTP status or timeout, etc.
 * Has information about the request: endpoint, method, and request body.
 * If built from a HTTP `Response` object, then potential JSON can be accessed.
 */
export class APIError extends Error {
  status: number;

  statusText: string;

  url: string;

  endpoint: string;

  method: string;

  requestBody: BodyInit | undefined | null;

  constructor(
    response: Response | RawErrorStatus,
    endpoint: string,
    method: string,
    requestBody?: BodyInit | null
  ) {
    if (response instanceof Response) {
      super(
        `QApi Error in request: ${method} ${endpoint} ${response.status} ${response.statusText}`
      );
      this.url = response.url;
      this.json = R.once(() => response.json());
    } else {
      super(
        `QApi Error in request: ${method} ${response.status} ${response.statusText}`
      );
      this.url = endpoint;
      this.json = R.once(() =>
        Promise.reject(new Error('no json due to fetch error'))
      );
    }
    Object.setPrototypeOf(this, APIError.prototype);
    this.name = 'APIError';
    this.status = response.status;
    this.statusText = response.statusText;
    this.endpoint = endpoint;
    this.method = method;
    this.requestBody = requestBody;
  }

  get type() {
    if (this.status === STATUS_TIMEOUT) {
      return ERROR_TIMEOUT;
    }
    if (this.status === 401) {
      return ERROR_UNAUTHORIZED;
    }
    if (this.status >= 400 && this.status < 500) {
      return ERROR_4XX;
    }
    if (this.status >= 500) {
      return ERROR_5XX;
    }
    if (this.status === STATUS_ABORT_ERROR) {
      return ERROR_ABORT;
    }
    if (this.status === STATUS_NETWORK_ERROR) {
      return ERROR_NETWORK;
    }
    return ERROR_UNKNOWN;
  }

  /**
   * Return promise with message of {error: "message"} in response, or empty
   * string, or string of internal error if applicable.
   */
  errorMessage() {
    return this.json()
      .then(json =>
        typeof json === 'object' && typeof json.error === 'string'
          ? json.error
          : Promise.reject(
              new Error('Reponse body did not contain "error" field')
            )
      )
      .catch(error => Object.prototype.toString.call(error));
  }

  /**
   * Returns the reponse's json() promise. Unlike `Response.json()`, it can be
   * called multiple times.
   */
  json: () => Promise<any>;

  static isUnauthorizedAuthorization(error: APIError | Error) {
    return (
      error instanceof APIError &&
      error.status === 401 &&
      /oauth2\/access_token$/.test(error.url)
    );
  }

  static isMissingAuthTokens(error: APIError | Error) {
    return (
      error instanceof APIError && error.status === STATUS_MISSING_TOKENS_ERROR
    );
  }
}

export class APITimeoutRequestError extends APIError {
  constructor(
    response: RawErrorStatus,
    endpoint: string,
    method: string,
    requestBody?: BodyInit | null
  ) {
    super(response, endpoint, method, requestBody);
    Object.setPrototypeOf(this, APITimeoutRequestError.prototype);
    this.name = 'APITimeoutRequestError';
    this.status = STATUS_TIMEOUT;
  }
}

export class APINetworkRequestError extends APIError {
  constructor(
    response: RawErrorStatus,
    endpoint: string,
    method: string,
    requestBody?: BodyInit | null
  ) {
    super(response, endpoint, method, requestBody);
    Object.setPrototypeOf(this, APINetworkRequestError.prototype);
    this.name = 'APINetworkRequestError';
    this.status = STATUS_NETWORK_ERROR;
  }
}

export class APIAbortRequestError extends APIError {
  constructor(
    response: RawErrorStatus,
    endpoint: string,
    method: string,
    requestBody?: BodyInit | null
  ) {
    super(response, endpoint, method, requestBody);
    Object.setPrototypeOf(this, APIAbortRequestError.prototype);
    this.name = 'APIAbortRequestError';
    this.status = STATUS_ABORT_ERROR;
  }
}

export class SessionTimeoutError extends Error {
  constructor() {
    super('Session timeout');
    Object.setPrototypeOf(this, SessionTimeoutError.prototype);
  }
}
