import { APP_ONBOARDING_EXPERIMENT } from '../ducks/experiments';
import { ExperimentRecord } from '../records/experiment';
import {
  getLicense,
  getPersistentStorageItem,
  getExperiment,
} from '../selectors';
import { getLocation, getLocationPathname } from '../selectors/routing';
import { shouldShowConversionElements } from '../records/license';
import { BaseThunk } from '../store/state';
import { isBrowserPlatform } from '../helpers';
import { setItem } from '../ducks/persistentStorage';
import { extractDomain } from '../helpers/string';
import { StandaloneFlow } from '../constants';
import {
  getMultiPlatformNavigation,
  RouterAction,
} from '../helpers/multiPlatformNavigation';
import { DeviceStatus } from '../businessLogic/devices';
import { UnifiedNowCardState } from '../businessLogic/unifiedNowCard/types';
import { ActivityEventFilters } from '../businessLogic/timeline/types';
import * as qinit from '../qinit';

/**
 * Ensure that the correct screen is opened on app start, and that back-navigation
 * is well defined here.
 * Must return the action, not dispatch it, for the router's onEnter callback.
 */
export function openInitialScreen(): BaseThunk<RouterAction | null> {
  return (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    if (
      getState().get('app').get('standaloneFlow') === StandaloneFlow.Purchase
    ) {
      return navigate({
        type: 'inner:replace',
        src: '/upgrade',
      });
    }
    if (
      getState().get('app').get('standaloneFlow') ===
      StandaloneFlow.PurchaseAtlimit
    ) {
      return navigate({
        type: 'inner:replace',
        src: '/upgrade/modal/AtLimitModal',
      });
    }

    if (
      !isBrowserPlatform() &&
      getPersistentStorageItem(getState(), 'welcomeModalShown') !== 'true'
    ) {
      // push location so user may return to profile-list, i.e. close dialog with back-button
      dispatch(setItem('welcomeModalShown', 'true'));

      const appOnboardingExperiment =
        getExperiment(getState(), APP_ONBOARDING_EXPERIMENT) ||
        ExperimentRecord();

      if (
        shouldShowConversionElements(getLicense(getState())) &&
        appOnboardingExperiment.result !== 'b'
      ) {
        return navigate({
          type: 'inner',
          src: '/modal/WelcomeModal',
        });
      }
    }

    // stay in profile-list screen
    return null;
  };
}

export const navigateToExternalUrl =
  (url: string, host?: string) => (dispatch, getState) => {
    const hostname = host || extractDomain(url);
    const state = getState();
    const isExternalNavigationAccepted = getPersistentStorageItem(
      state,
      'externalNavigationAccepted'
    );
    const navigate = getMultiPlatformNavigation();

    if (isExternalNavigationAccepted || isBrowserPlatform()) {
      dispatch(
        navigate({
          type: 'external',
          src: url,
        })
      );
      return;
    }
    dispatch(
      navigate({
        type: 'inner',
        src: `${getLocationPathname(state)}/domain/${encodeURIComponent(
          hostname
        )}/url/${encodeURIComponent(url)}/modal/ExternalUrlNavigationModal`,
      })
    );
  };

/**
 * This function will be used when we want to redirect the user
 * to an external url owned by qustodio e.g. help articles or qustodio public site
 * This will not show the confirm external navigation on mobile,
 * as we consider our own sites part of the app.
 */
export const navigateToExternalQustodioUrl = (url: string) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'external',
    src: url,
  });
};

export const pushRelativeLocation =
  (relativeUrl: string) => (dispatch, getState) => {
    const currentPathname = getLocation(getState()).pathname;
    const navigate = getMultiPlatformNavigation();

    const nextPathname =
      currentPathname === '/'
        ? `${currentPathname}${relativeUrl}`
        : `${currentPathname}/${relativeUrl}`;

    return dispatch(
      navigate({
        type: 'inner',
        src: nextPathname,
      })
    );
  };

export const navigateToSchoolSignature = (
  profileId: string,
  signatureId: string
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `profiles/${profileId}/school/signature/${signatureId}`,
  });
};

export const navigateToNonBlockableAppModalFrom = (
  origin: 'appRules' | 'timeline',
  profileId: string,
  appKey: string
) => {
  const navigate = getMultiPlatformNavigation();

  const urls = {
    appRules: `profiles/${profileId}/rules/appRules/modal/NonBlockableAppModal/${appKey}`,
    timeline: `profiles/${profileId}/activity-timeline/modal/NonBlockableAppModal/${appKey}`,
  };

  return navigate({
    type: 'inner',
    src: urls[origin],
  });
};

export const navigateToFamilyTimeline = ({
  profileId,
  replaceNavigation = false,
  filter,
}: {
  profileId: string;
  replaceNavigation?: boolean;
  filter?: ActivityEventFilters;
}) => {
  const navigate = getMultiPlatformNavigation();
  const selectedFilter = filter ? `/filter/${filter}` : '';

  return navigate({
    type: replaceNavigation ? 'inner:replace' : 'inner',
    src: `/profiles/${profileId}/activity-timeline${selectedFilter}`,
  });
};

export const navigateToSchoolTimeline = (
  profileId: string,
  replaceNavigation = false
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: replaceNavigation ? 'inner:replace' : 'inner',
    src: `profiles/${profileId}/school/timeline`,
  });
};

export const navigateToSchoolEventDetails = (
  profileId: string,
  eventKey: string
) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `profiles/${profileId}/school/timeline/details/${eventKey}`,
  });
};

export const navigateToExtraTimeModal = (profileId: string, origin: string) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/modal/ExtraTimeModal?origin=${origin}`,
  });
};

export const navigateToRoutineWithoutDailyTimeLimitInfoModal = () => {
  return pushRelativeLocation('modal/RoutineWithoutTimeLimit');
};

export const navigateToTotalScreenTimeInfoModal = () => {
  return pushRelativeLocation('modal/TotalScreenTimeInfo');
};

export const navigateToPauseProfileModal = () => {
  return pushRelativeLocation('modal/PauseProfile');
};

export const navigateToUndoRoutineSwitchModal = (
  routineUid?: string,
  scheduleUid?: string,
  nowCardState?: string
) => {
  return pushRelativeLocation(
    `modal/UndoRoutineSwitch?routineUid=${routineUid}&scheduleUid=${scheduleUid}&nowCardState=${nowCardState}`
  );
};

export const navigateToRoutineSwitchModal = () => {
  return pushRelativeLocation(`modal/RoutineSwitch`);
};

export const navigateToSmartDeviceInfoModal = (
  status: DeviceStatus['status'],
  affiliation: DeviceStatus['affiliation'],
  id: DeviceStatus['id']
) => {
  return pushRelativeLocation(
    `modal/DeviceInfo?status=${status}&id=${id}&affiliation=${affiliation}`
  );
};

export const navigateToNowCardInfoModal = (
  state: UnifiedNowCardState,
  deviceId?: number
) => {
  return pushRelativeLocation(
    `modal/UnifiedNowCardInfoModal?state=${state}${
      deviceId ? `&id=${deviceId}` : ''
    }`
  );
};

export const navigateToExternalNoActivityHelp = () => {
  return navigateToExternalQustodioUrl(
    'https://help.qustodio.com/hc/en-us/articles/360005216277-Why-am-I-not-seeing-any-activity-'
  );
};

export const navigateToExternalDelegationHelp = () => {
  return navigateToExternalQustodioUrl(
    'https://help.qustodio.com/hc/en-us/articles/15373767795986'
  );
};

export const navigateToDelegationEnabledModal = () => {
  return pushRelativeLocation('modal/DelegationEnabled');
};

export const navigateToDelegationRuleInfoModal = () => {
  return pushRelativeLocation('modal/DelegationRuleInfo');
};

export const navigateToProfile = ({
  profileId,
  replaceNavigation = false,
}: {
  profileId: string;
  replaceNavigation?: boolean;
}) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: replaceNavigation ? 'inner:replace' : 'inner',
    src: `/profiles/${profileId}`,
  });
};

export const navigateToProfileList = ({
  replaceNavigation = false,
}: {
  replaceNavigation?: boolean;
}) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: replaceNavigation ? 'inner:replace' : 'inner',
    src: `/profiles`,
  });
};

export const navigateToSafetyNetInfoModal = () => {
  return pushRelativeLocation('modal/SafetyNetInfo');
};

export const navigateToUpgrade = () => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner:replace',
    src: `/upgrade`,
  });
};
export const navigateToActivityTimelineDelegationInfoModal = () => {
  return pushRelativeLocation('modal/ActivityTimelineDelegationInfo');
};

export const navigateToGeneralHelp = (openInIOS = false) => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'external:public:site',
    src: qinit.tenant.marketing.public_site.general_help_link,
    openInIOS,
  });
};

export const navigateToDelegationOptOutModal = () => {
  return pushRelativeLocation('modal/DelegationOptOut');
};

export const navigateToCallsAndSmsAppRules =
  (profileId: string | number): BaseThunk =>
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/callsSMSRules`,
      })
    );
  };

export const navigateToWellbeing = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/wellbeing`,
  });
};

export const navigateToWellbeingContactUsModal = () => {
  return pushRelativeLocation('modal/WellbeingContactUs');
};

export const navigateToWellbeingActivities = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/wellbeing-activities`,
  });
};

export const navigateToWellbeingTherapy = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/wellbeing-therapy`,
  });
};

export const navigateToMyFamily = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/`,
  });
};

export const navigateToWelcomeSubscriptionModal = () => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: '/modal/WelcomeToPremiumModal',
  });
};

export const navigateToExternalAntiTamperingHelp = () => {
  return navigateToExternalQustodioUrl(
    'https://help.qustodio.com/hc/en-us/articles/20084558366482'
  );
};

export const navigateToDirectPurchase = (productCode: string) => {
  const navigate = getMultiPlatformNavigation();

  return navigate({
    type: 'inner',
    src: `/direct-purchase/${productCode}`,
  });
};
