import { combineEpics } from 'redux-observable';
import { profileTrackingEpics } from './profile';
import { studentSummaryTrackingEpics } from './studentSummary';
import { studentTimelinePageTrackingEpic } from './studentTimeline';
import { profileFamilySchoolTrackingEpics } from './ProfileFamilySchool';

const trackingEpics = combineEpics(
  profileTrackingEpics,
  studentSummaryTrackingEpics,
  studentTimelinePageTrackingEpic,
  profileFamilySchoolTrackingEpics
);

export default trackingEpics;
