import type { Decision, DecisionOptions } from './types';
import { logger } from './logger';

export const decision = <I, O>(
  params: Decision<I, O>,
  baseOptions: DecisionOptions = { log: false }
) => {
  const { meta, cond, yes, no, error } = params;

  return (context: I, options: DecisionOptions = baseOptions) => {
    const { log } = options;

    try {
      let result: O;
      let fulfilled: boolean;

      if (cond(context)) {
        result = yes(context, options);
        fulfilled = true;
      } else {
        fulfilled = false;
        result = no(context, options);
      }

      log && logger<I, O>({ meta, context, result, fulfilled });
      return result;
    } catch (e) {
      if (error) {
        return error(e, context);
      }
      throw e;
    }
  };
};

export type ObjectPredicates<T> = {
  [K in keyof Partial<T>]: (value: T[K], context: T) => boolean;
};

export const where =
  <T>(predicates: ObjectPredicates<T>) =>
  (src: T) => {
    return Object.entries(predicates).every(([key, predicate]) => {
      const value = src[key];

      if (typeof predicate === 'function') {
        return predicate(value, src);
      }

      return false;
    });
  };

export const all =
  <T, C>(...fns: Array<(v: T, c: C) => boolean>) =>
  (val: T, context: C) => {
    return fns.every(f => f(val, context));
  };
