import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'styleguide-react';
import protectChildDevice from '../../../assets/base/images/experiments/protect-child-device.svg';
import { addProfileClickHandler } from '../../../actions/ProfileActions';
import { t } from '../../../lib/i18n';
import Icon from '../../../components/Icons/Icon';

const ProtectYourChildsDevice: React.FunctionComponent<{
  onClick: () => void;
}> = ({ onClick }) => (
  <div className="ProtectYourChildsDevice">
    <Icon
      path={protectChildDevice}
      className="ProtectYourChildsDevice__illustration"
    />

    <p className="ProtectYourChildsDevice__intro">
      {t("Let's protect your child's device with {{shortName}}!")}
    </p>

    <Button onClick={onClick}>{t('Start now')}</Button>
  </div>
);

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(addProfileClickHandler()),
});

export default connect(null, mapDispatchToProps)(ProtectYourChildsDevice);
