import { applyTo, filter, forEach, is, pipe, toPairs } from 'ramda';
import { CacheSelectorStrategy, Cache, session } from './strategy';

interface CacheOptions {
  removeWhen: (...params: Array<any>) => boolean;
}

type WithCache<T> = {
  [K in keyof T]: T[K] extends { get: (...args: any[]) => any }
    ? {
        withCache: (
          cacheStrategy?: CacheSelectorStrategy,
          options?: CacheOptions
        ) => {
          get: <R extends any, T = any>(params: T, query?: any) => Promise<R>;
        };
        removeCache: () => T[K];
      } & T[K]
    : T[K];
};

/**
 * Add to QAPI instances the ability to use cache.
 *
 * For all endpoints that have a get method,
 * add the with cache method that allows you to make get requests using a cache strategy.
 *
 * @example
 * QAPi instance should be decorated
 * ```ts
 *
 * QApiCache.create(new QApi(baseUrl));
 *
 * ```
 *
 * Cache strategies can now be used using the withCache method (default uses session):
 *
 * ```ts
 * export const fetchProfile = (profileId: string) => (dispatch) => {
 *  return api.profiles
 *             .withCache()
 *             .get({ profileId })
 *             .then(json => dispatch(receiveProfile(json)))
 *
 * }
 *
 * ```
 *
 *  Also can use custom strategies:
 *
 * ```ts
 * import { ttl } from '../../lib/QapiCache';
 *
 * const ttl10s = ttl(10000);
 *
 * export const fetchProfile = (profileId: string) => (dispatch) => {
 *  return api.profiles
 *             .withCache(ttl10s)
 *             .get({ profileId })
 *             .then(json => dispatch(receiveProfile(json)))
 *
 * }
 *
 * ```
 */
export default class QApiCache {
  private cache: Cache;

  constructor(qapi) {
    this.cache = new Map();
    this.apply(qapi);
    return qapi;
  }

  static create<T extends Record<string, any>>(qapi: T) {
    return new QApiCache(qapi) as WithCache<T>;
  }

  static applyCacheSelectorStrategy(
    getMethod: (...params: Array<any>) => Promise<any>,
    cacheSelectorStrategy: CacheSelectorStrategy,
    endpointName: string,
    cache: Cache,
    options?: CacheOptions
  ) {
    return (...args: Array<any>): Promise<any> => {
      const cacheKey = `${endpointName}:get:${JSON.stringify(args)}`;
      const pullData = () => getMethod(...args);

      if (options && options.removeWhen(args)) {
        cache.delete(cacheKey);
      }

      return cacheSelectorStrategy(cache, cacheKey, pullData, endpointName);
    };
  }

  static addCacheMode(cache: Cache, endpoint: string, methods: any) {
    // eslint-disable-next-line no-param-reassign
    methods.withCache = (strategy = session, options) => ({
      get: QApiCache.applyCacheSelectorStrategy(
        methods.get,
        strategy,
        endpoint,
        cache,
        options
      ),
    });
  }

  apply(qapi) {
    applyTo(
      qapi,
      pipe(
        toPairs,
        filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, methods]) => is(Object, methods) && is(Function, methods.get)
        ),
        forEach(([endpoint, methods]) =>
          QApiCache.addCacheMode(this.cache, endpoint, methods)
        )
      )
    );
  }
}
