import * as React from 'react';
import {
  FlexLayout,
  Typography,
  Accordion,
  RckBaseColor,
  Button,
  ButtonSize,
} from 'styleguide-react';
import { AccordionItemProps } from 'styleguide-react/src/components/Accordion/AccordionItem';

import WellbeingGradientBackgroundImageSection from '../shared/WellbeingGradientBackgroundImageSection';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import { t } from '../../../lib/i18n';

interface WellbeingAccordionSectionProps {
  colorScheme: Extract<RckBaseColor, 'magenta' | 'green'>;
  title: React.ReactNode;
  description?: React.ReactNode;
  items: Omit<AccordionItemProps, 'onClick' | 'key'>[];
  imageType?: 'activities' | 'therapy';
  isDisabledButton?: boolean;
  onClickButton: () => void;
}

const WellbeingAccordionSection = ({
  colorScheme,
  title,
  description,
  items,
  imageType = 'therapy',
  isDisabledButton,
  onClickButton,
}: WellbeingAccordionSectionProps) => {
  return (
    <FlexLayout mainaxis="column" width="100%">
      <RenderWhen
        screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
      >
        {screenSize => (
          <WellbeingGradientBackgroundImageSection
            showImage
            imageType={imageType}
          >
            <FlexLayout
              mainaxis="column"
              marginBottom={isDesktop(screenSize) ? '32' : '16'}
              maxWidth={isDesktop(screenSize) ? '100%' : '50%'}
            >
              <Typography
                type={isDesktop(screenSize) ? 'headline2' : 'title1'}
                weight="semibold"
                align="center"
                className="par-wellbeing-feature__title"
              >
                {title}
              </Typography>
            </FlexLayout>

            <FlexLayout mainaxis="column" maxWidth="584">
              <Typography type="body1" align="center">
                {description}
              </Typography>
            </FlexLayout>
            <FlexLayout
              mainaxis="column"
              width={!isDesktop(screenSize) ? '100%' : '75%'}
              marginTop="8"
              padding="16"
              alignSelf="center"
              minHeight={!isDesktop(screenSize) ? '360' : '296'}
            >
              <div
                className={
                  !isDesktop(screenSize)
                    ? 'par-wellbeing-accordion-section--button-space'
                    : ''
                }
              >
                <Accordion
                  color={colorScheme}
                  highlightOnOpen
                  maxColumns={2}
                  items={items.map(item => ({
                    ...item,
                    onClick: () => {},
                    key: item.title,
                  }))}
                />
                <RenderWhen screenSize={ScreenSize.Desktop}>
                  <FlexLayout mainaxis="column" paddingTop="32">
                    <FlexLayout mainaxis="column" alignSelf="center">
                      <Button
                        loading={isDisabledButton}
                        onClick={onClickButton}
                        size={ButtonSize.medium}
                        centered
                      >
                        {t('Start now')}
                      </Button>
                    </FlexLayout>
                  </FlexLayout>
                </RenderWhen>
              </div>
            </FlexLayout>
          </WellbeingGradientBackgroundImageSection>
        )}
      </RenderWhen>
    </FlexLayout>
  );
};

export default WellbeingAccordionSection;
