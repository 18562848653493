import { connect } from 'react-redux';

import State, { Dispatch } from '../../store/state';
import Wellbeing from '../../components/Wellbeing/Wellbeing';
import {
  formatWellbeingSession,
  getWellbeingSubscriptionStatus,
  getNextWellbeingSession,
} from '../../businessLogic/wellbeing/wellbeing';
import {
  navigateToMyFamily,
  navigateToWellbeingActivities,
  navigateToWellbeingContactUsModal,
  navigateToWellbeingTherapy,
} from '../../actions/Navigation';
import {
  trackSeeFindOutMoreButtonClicked,
  trackSeeHowItWorksButtonClicked,
} from './tracking';
import { getCurrentTimeFromSystem } from '../../helpers/dates';
import { WellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/types';
import {
  getProductAddonOfferStatus,
  requestProductAddonsOfferAction,
} from '../../ducks/productAddonsOffer';
import { getFeatureStatus, requestFeaturesAction } from '../../ducks/features';

export const getFormattedNextWellbeingSession = () => {
  const today = getCurrentTimeFromSystem();
  const nextSession = getNextWellbeingSession(today);
  const formatted = formatWellbeingSession(nextSession, today);
  return formatted;
};

const mapStateToProps = (state: State) => {
  const isLoading =
    getProductAddonOfferStatus(state) === 'LOADING' ||
    getFeatureStatus(state) === 'LOADING';

  return {
    isLoading,
    subscriptionStatus: getWellbeingSubscriptionStatus(state),
    nextActivity: getFormattedNextWellbeingSession(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    goToActivities: () => {
      trackSeeFindOutMoreButtonClicked();
      dispatch(navigateToWellbeingActivities());
    },
    goToTherapy: () => {
      trackSeeHowItWorksButtonClicked();
      dispatch(navigateToWellbeingTherapy());
    },
    onNeedHelpClick: () => dispatch(navigateToWellbeingContactUsModal()),
    onLoad: (status?: WellbeingSubscriptionStatus) => {
      dispatch(requestFeaturesAction());
      if (status === 'none') {
        dispatch(
          requestProductAddonsOfferAction([
            'wellbeing_activities',
            'wellbeing_therapy',
          ])
        );
      }
    },
    onBack: () => dispatch(navigateToMyFamily()),
  };
};

const WellbeingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wellbeing);

export type WellbeingContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default WellbeingContainer;
