import { List } from 'immutable';
import { ProductRecord } from '../../records';
import { BuildPlatform } from '../../constants';

/**
 * Sort products by the license type; if there is no sorting criterion, place the product at the end.
 */
const sortByLicense = (
  products: List<ProductRecord>,
  order: LicenseSubtype[]
) => {
  return products.sortBy(product => {
    const position = order.findIndex(
      subType => subType === product.licenseSubtype
    );
    return position !== -1 ? position : Infinity;
  });
};

/**
 * Sort products according to their platform or features flags.
 */
export const orderProductsByPreferences = (
  products: List<ProductRecord>,
  {
    platform,
    switchPlansOnAndroid = false,
    isMobileScreen = false,
  }: {
    platform: BuildPlatform;
    switchPlansOnAndroid?: boolean;
    isMobileScreen?: boolean;
  }
) => {
  if (platform === BuildPlatform.browser) {
    if (isMobileScreen) {
      /* There is a case where on the web it can be seen on a small 
      screen and a different order is wanted to be maintained. */
      return sortByLicense(products, ['premium_complete', 'premium_basic']);
    }
    return sortByLicense(products, ['premium_basic', 'premium_complete']);
  }

  if (platform === BuildPlatform.android) {
    return sortByLicense(
      products,
      switchPlansOnAndroid
        ? ['premium_basic', 'premium_complete']
        : ['premium_complete', 'premium_basic']
    );
  }

  if (platform === BuildPlatform.ios) {
    return sortByLicense(products, ['premium_complete', 'premium_basic']);
  }

  return products;
};
