import { Record, List } from 'immutable';
import * as R from 'ramda';
import { PLATFORM_UNKNOWN } from '../../constants';
import { LocationRecord } from '../location';
import { UserDeviceRecord } from './userDevice';
import {
  Device,
  DeviceRecord as DeviceRecordType,
  AlertRecord as AlertRecordType,
  DeviceMethods,
} from './types/Device.types';
import { DeviceSettingsRecord } from './deviceSettings';
import {
  DeviceSettingsPayload,
  DeviceSettingsRecord as DeviceSettingsRecordType,
} from './types/DeviceSettings.types';
import { mapPlatform, inverseMapPlatform } from './devicePlatform';
import { DeviceOptionsRecord } from './deviceOptions';
import {
  DeviceOptionsPayload,
  DeviceOptionsRecord as DeviceOptionsRecordType,
} from './types/DeviceOptions.types';
import { snakeCaseToCamelCase } from '../../api/mappings/case';
import { DeviceMdmRecordOperations } from './deviceMdm';

const AlertRecord = Record({
  unauthorizedRemove: false,
});

export const DeviceRecord = Record<Device, DeviceMethods>({
  id: 0,
  uid: '',
  name: '',
  platform: PLATFORM_UNKNOWN,
  location: LocationRecord() as LocationRecord,
  type: '',
  users: List(),
  defaultProfileId: null,
  settings: DeviceSettingsRecord() as DeviceSettingsRecordType,
  hideTrayicon: 0,
  lastseen: '',
  alerts: AlertRecord() as AlertRecordType,
  version: '0.0.1',
  options: DeviceOptionsRecord() as DeviceOptionsRecordType,
  isDelegated: false,
  mdm: DeviceMdmRecordOperations.create(),
});

DeviceRecord.fromPayload = payload => {
  const camelized = snakeCaseToCamelCase(payload);

  return DeviceRecord({
    ...R.omit(['platform', 'location', 'settings', 'options', 'users', 'mdm'])(
      camelized
    ),
    platform: mapPlatform(camelized.platform),
    users: List(camelized.users.map(UserDeviceRecord.fromPayload)),
    location: LocationRecord.fromDeviceResponse(camelized),
    settings: DeviceSettingsRecord.fromPayload(camelized.settings ?? {}),
    options: DeviceOptionsRecord.fromPayload(camelized.options ?? {}),
    ...(camelized.mdm
      ? { mdm: DeviceMdmRecordOperations.fromPayload(camelized.mdm) }
      : {}),
  }) as DeviceRecordType;
};

DeviceRecord.serialize = record => ({
  ...R.omit(['platform', 'location', 'settings', 'options'])(record.toJS()),
  platform: inverseMapPlatform(record.platform),
  locationLatitude: record.location.latitude,
  locationLongitude: record.location.longitude,
  locationTime: record.location.time,
  settings: DeviceSettingsRecord.serialize(
    record.settings
  ) as DeviceSettingsPayload,
  options: DeviceOptionsRecord.serialize(
    record.options
  ) as DeviceOptionsPayload,
  ...(record.mdm
    ? { mdm: DeviceMdmRecordOperations.serialize(record.mdm) }
    : {}),
});

export const getUserByUid = (device: DeviceRecordType, userUid: string) =>
  device.users.filter(user => user.uid === userUid).first();

export const isVpnEnabled = (device: DeviceRecordType) =>
  !(
    device.users.size === 0 ||
    device.users.every(
      user => user?.getIn(['status', 'vpnDisable', 'status']) || false
    )
  );

export default DeviceRecord;
