import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { Dispatch } from '../../store/state';
import SafetyNetInfoModal from '../../components/Modal/SafetyNetInfoModal';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const SafetyNetInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(SafetyNetInfoModal);

export type SafetyNetInfoModalProps = ReturnType<typeof mapDispatchToProps>;

export default SafetyNetInfoModalContainer;
