import { goBackIfHistory } from '../../ducks/routing';
import { connect } from 'react-redux';
import TotalScreenTimeInfoModal from '../../components/Modal/TotalScreenTimeInfoModal';
import State, { Dispatch } from '../../store/state';
import { isRoutinesFeatureEnabled } from '../../ducks/routines/selectors';

const mapStateToProps = (state: State) => {
  const isRoutinesEnabled = isRoutinesFeatureEnabled(state);
  return {
    isRoutinesEnabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const TotalScreenTimeInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalScreenTimeInfoModal);

export default TotalScreenTimeInfoModalContainer;
