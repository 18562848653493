import * as Moment from 'moment-timezone';
import * as R from 'ramda';
import { tt } from '../lib/i18n';
import * as qinit from '../qinit';
import { ISOLanguage, Language, SortOutput } from '../constants';
import { getEnumKeyByEnumValue } from './enums';
import { capitalize } from './string';

export const getLanguage = (langApiCode: ISOLanguage): Language => {
  const langKey =
    getEnumKeyByEnumValue<keyof ISOLanguage>(ISOLanguage, langApiCode) ||
    Object.keys(ISOLanguage)[0];
  return Language[langKey];
};

export function getAllLanguages() {
  return [
    [ISOLanguage.english, Language.english],
    [ISOLanguage.spanish, Language.spanish],
    [ISOLanguage.french, Language.french],
    [ISOLanguage.italian, Language.italian],
    [ISOLanguage.portugueseBrazil, Language.portugueseBrazil],
    [ISOLanguage.german, Language.german],
    [ISOLanguage.chineseHans, Language.chineseHans],
    [ISOLanguage.japanese, Language.japanese],
  ].filter(([apiCode]) =>
    qinit.tenant.common.tenant.supported_locales.includes(apiCode)
  );
}

export function isSetLanguage(accountLocale, languageApiCode) {
  return accountLocale === languageApiCode;
}

export const getTimezone = (currentTime, tzApiCode) =>
  R.ifElse(
    // @ts-ignore
    R.identity,
    R.pipe(
      R.tail,
      // @ts-ignore
      ([offset, label]) => `(${offset}) ${label}`
    ),
    R.always(tzApiCode)
  )(getAllTimezones(currentTime).find(R.pipe(R.head, R.equals(tzApiCode))));

const timezones = [
  'Etc/GMT+12',
  'Etc/GMT+11',
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Chihuahua',
  'America/Denver',
  'America/Phoenix',
  'America/Regina',
  'America/Guatemala',
  'America/Chicago',
  'America/Mexico_City',
  'America/New_York',
  'America/Bogota',
  'America/Indianapolis',
  'America/Caracas',
  'America/Halifax',
  'America/Cuiaba',
  'America/Santiago',
  'America/Asuncion',
  'America/La_Paz',
  'Canada/Newfoundland',
  'America/Buenos_Aires',
  'America/Sao_Paulo',
  'America/Godthab',
  'America/Montevideo',
  'America/Cayenne',
  'Atlantic/South_Georgia',
  'Etc/GMT+2',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Europe/London',
  'Atlantic/Reykjavik',
  'Africa/Casablanca',
  'UTC',
  'Europe/Budapest',
  'Europe/Warsaw',
  'Africa/Windhoek',
  'Europe/Paris',
  'Africa/Lagos',
  'Europe/Berlin',
  'Africa/Cairo',
  'Europe/Helsinki',
  'Europe/Bucharest',
  'Asia/Jerusalem',
  'Asia/Amman',
  'Asia/Beirut',
  'Africa/Johannesburg',
  'Asia/Damascus',
  'Europe/Istanbul',
  'Europe/Minsk',
  'Asia/Riyadh',
  'Asia/Baghdad',
  'Africa/Nairobi',
  'Europe/Kaliningrad',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Yerevan',
  'Asia/Baku',
  'Asia/Tbilisi',
  'Indian/Mauritius',
  'Europe/Moscow',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Tashkent',
  'Asia/Calcutta',
  'Asia/Colombo',
  'Asia/Katmandu',
  'Asia/Dhaka',
  'Asia/Almaty',
  'Asia/Yekaterinburg',
  'Asia/Rangoon',
  'Asia/Novosibirsk',
  'Asia/Bangkok',
  'Asia/Shanghai',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Australia/Perth',
  'Asia/Seoul',
  'Asia/Irkutsk',
  'Asia/Tokyo',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Pacific/Port_Moresby',
  'Asia/Yakutsk',
  'Pacific/Guadalcanal',
  'Asia/Vladivostok',
  'Pacific/Fiji',
  'Asia/Kamchatka',
  'Asia/Magadan',
  'Pacific/Auckland',
  'Etc/GMT-12',
  'Pacific/Tongatapu',
  'Pacific/Apia',
];

// Moment doesn't follow the time notation standard for some countries (IE, UK),
// while for others I couldn't find a specific locale.
// In both cases we're defaulting to US English format.
const countriesUsingAMPMformat = {
  GB: 'en',
  IE: 'en',
  US: 'en',
  CA: 'en-ca',
  AU: 'en-au',
  NZ: 'en-nz',
  IN: 'en',
  PK: 'en',
  BD: 'en',
  MY: 'en',
  MT: 'en',
  EG: 'en',
  MX: 'en',
  PH: 'en',
};

const zipConcat = R.zipWith((list1: any[], list2: any[]) => {
  return [...list1, ...list2];
});

const timezoneLabelTuple = (currentTime: Moment.Moment) => (apiCode: string) =>
  [apiCode, 'GMT'.concat(currentTime.tz(apiCode).format('Z'))];

/**
 * Return list of [api-code, timezone-offset (e.g. GTM+03:00), translation-label] of all timezones
 */
export function getAllTimezones(currentTime: Moment.Moment): string[][] {
  return zipConcat(
    timezones.map(timezoneLabelTuple(currentTime)),
    R.map(R.of, [
      tt('Int. Date Line West'),
      tt('Coord. Universal Time-11'),
      tt('Hawaii'),
      tt('Alaska'),
      tt('Pacific Time (US & Canada)'),
      tt('Baja California'),
      tt('Chihuahua, La Paz'),
      tt('Denver'),
      tt('Arizona'),
      tt('Saskatchewan'),
      tt('Central America'),
      tt('Central Time (US & Canada)'),
      tt('Guadalajara, Mexico City'),
      tt('Eastern Time (US & Canada)'),
      tt('Bogota, Lima, Quito'),
      tt('Indiana (East)'),
      tt('Caracas'),
      tt('Atlantic Time (Canada)'),
      tt('Cuiaba'),
      tt('Santiago'),
      tt('Asuncion'),
      tt('Georgetown, La Paz'),
      tt('Newfoundland'),
      tt('Buenos Aires'),
      tt('Brasilia'),
      tt('Greenland'),
      tt('Montevideo'),
      tt('Cayenne, Fortaleza'),
      tt('Mid-Atlantic'),
      tt('Coord. Universal Time-02'),
      tt('Azores'),
      tt('Cape Verde Is.'),
      tt('Dublin, Lisbon, London'),
      tt('Monrovia, Reykjavik'),
      tt('Casablanca'),
      tt('Coord. Universal Time'),
      tt('Belgrade, Bratislava'),
      tt('Sarajevo, Skopje, Warsaw'),
      tt('Windhoek'),
      tt('Brussels, Madrid, Paris'),
      tt('West Central Africa'),
      tt('Amsterdam, Berlin, Rome'),
      tt('Cairo'),
      tt('Helsinki, Kyiv, Riga'),
      tt('Athens, Bucharest'),
      tt('Jerusalem'),
      tt('Amman'),
      tt('Beirut'),
      tt('Harare, Pretoria'),
      tt('Damascus'),
      tt('Istanbul'),
      tt('Minsk'),
      tt('Kuwait, Riyadh'),
      tt('Baghdad'),
      tt('Nairobi'),
      tt('Kaliningrad'),
      tt('Tehran'),
      tt('Abu Dhabi, Muscat'),
      tt('Yerevan'),
      tt('Baku'),
      tt('Tbilisi'),
      tt('Port Louis'),
      tt('Moscow, St. Petersburg'),
      tt('Kabul'),
      tt('Islamabad, Karachi'),
      tt('Tashkent'),
      tt('Chennai, New Delhi'),
      tt('Sri Jayawardenepura'),
      tt('Kathmandu'),
      tt('Dhaka'),
      tt('Astana'),
      tt('Ekaterinburg'),
      tt('Yangon (Rangoon)'),
      tt('Novosibirsk'),
      tt('Bangkok, Hanoi, Jakarta'),
      tt('Beijing, Hong Kong'),
      tt('Krasnoyarsk'),
      tt('Kuala Lumpur, Singapore'),
      tt('Taipei'),
      tt('Ulaanbaatar'),
      tt('Perth'),
      tt('Seoul'),
      tt('Irkutsk'),
      tt('Osaka, Sapporo, Tokyo'),
      tt('Darwin'),
      tt('Adelaide'),
      tt('Canberra, Melbourne'),
      tt('Brisbane'),
      tt('Hobart'),
      tt('Guam, Port Moresby'),
      tt('Yakutsk'),
      tt('Solomon Is., New Caledonia'),
      tt('Vladivostok'),
      tt('Fiji'),
      tt('Petropavlovsk-Kamchatsky'),
      tt('Magadan'),
      tt('Auckland, Wellington'),
      tt('Coord. Universal Time+12'),
      tt("Nuku'alofa"),
      tt('Samoa'),
    ])
  );
}

export function isSetTimezone(accountTimezone, timezoneApiCode) {
  return accountTimezone === timezoneApiCode;
}

export function getCountry(accountCountry) {
  const country = getAllCountries().find(
    country => country[0] === accountCountry
  );
  return country ? country[1] : accountCountry;
}

export function getAllCountries() {
  return [
    ['AF', tt('Afghanistan')],
    ['AX', tt('Åland Islands')],
    ['AL', tt('Albania')],
    ['DZ', tt('Algeria')],
    ['AS', tt('American Samoa')],
    ['AD', tt('Andorra')],
    ['AO', tt('Angola')],
    ['AI', tt('Anguilla')],
    ['AQ', tt('Antarctica')],
    ['AG', tt('Antigua and Barbuda')],
    ['AR', tt('Argentina')],
    ['AM', tt('Armenia')],
    ['AW', tt('Aruba')],
    ['AU', tt('Australia')],
    ['AT', tt('Austria')],
    ['AZ', tt('Azerbaijan')],
    ['BS', tt('Bahamas')],
    ['BH', tt('Bahrain')],
    ['BD', tt('Bangladesh')],
    ['BB', tt('Barbados')],
    ['BY', tt('Belarus')],
    ['BE', tt('Belgium')],
    ['BZ', tt('Belize')],
    ['BJ', tt('Benin')],
    ['BM', tt('Bermuda')],
    ['BT', tt('Bhutan')],
    ['BO', tt('Bolivia, Pl. State of')],
    ['BQ', tt('Bonaire, Sint Eust. and Saba')],
    ['BA', tt('Bosnia and Herzegovina')],
    ['BW', tt('Botswana')],
    ['BV', tt('Bouvet Island')],
    ['BR', tt('Brazil')],
    ['IO', tt('British Indian Ocean Territory')],
    ['BN', tt('Brunei Darussalam')],
    ['BG', tt('Bulgaria')],
    ['BF', tt('Burkina Faso')],
    ['BI', tt('Burundi')],
    ['KH', tt('Cambodia')],
    ['CM', tt('Cameroon')],
    ['CA', tt('Canada')],
    ['CV', tt('Cape Verde')],
    ['KY', tt('Cayman Islands')],
    ['CF', tt('Central African Republic')],
    ['TD', tt('Chad')],
    ['CL', tt('Chile')],
    ['CN', tt('China')],
    ['CX', tt('Christmas Island')],
    ['CC', tt('Cocos (Keeling) Islands')],
    ['CO', tt('Colombia')],
    ['KM', tt('Comoros')],
    ['CG', tt('Congo')],
    ['CD', tt('Congo, The DR')],
    ['CK', tt('Cook Islands')],
    ['CR', tt('Costa Rica')],
    ['CI', tt("Côte d'Ivoire")],
    ['HR', tt('Croatia')],
    ['CU', tt('Cuba')],
    ['CW', tt('Curaçao')],
    ['CY', tt('Cyprus')],
    ['CZ', tt('Czech Republic')],
    ['DK', tt('Denmark')],
    ['DJ', tt('Djibouti')],
    ['DM', tt('Dominica')],
    ['DO', tt('Dominican Republic')],
    ['EC', tt('Ecuador')],
    ['EG', tt('Egypt')],
    ['SV', tt('El Salvador')],
    ['GQ', tt('Equatorial Guinea')],
    ['ER', tt('Eritrea')],
    ['EE', tt('Estonia')],
    ['ET', tt('Ethiopia')],
    ['FK', tt('Falkland Islands (Malvinas)')],
    ['FO', tt('Faroe Islands')],
    ['FJ', tt('Fiji')],
    ['FI', tt('Finland')],
    ['FR', tt('France')],
    ['GF', tt('French Guiana')],
    ['PF', tt('French Polynesia')],
    ['TF', tt('French Southern Territories')],
    ['GA', tt('Gabon')],
    ['GM', tt('Gambia')],
    ['GE', tt('Georgia')],
    ['DE', tt('Germany')],
    ['GH', tt('Ghana')],
    ['GI', tt('Gibraltar')],
    ['GR', tt('Greece')],
    ['GL', tt('Greenland')],
    ['GD', tt('Grenada')],
    ['GP', tt('Guadeloupe')],
    ['GU', tt('Guam')],
    ['GT', tt('Guatemala')],
    ['GG', tt('Guernsey')],
    ['GN', tt('Guinea')],
    ['GW', tt('Guinea-Bissau')],
    ['GY', tt('Guyana')],
    ['HT', tt('Haiti')],
    ['HM', tt('Heard Is. and McDonald Is.')],
    ['VA', tt('Holy See (Vatican City State)')],
    ['HN', tt('Honduras')],
    ['HK', tt('Hong Kong')],
    ['HU', tt('Hungary')],
    ['IS', tt('Iceland')],
    ['IN', tt('India')],
    ['ID', tt('Indonesia')],
    ['IR', tt('Iran, Islamic Republic of')],
    ['IQ', tt('Iraq')],
    ['IE', tt('Ireland')],
    ['IM', tt('Isle of Man')],
    ['IL', tt('Israel')],
    ['IT', tt('Italy')],
    ['JM', tt('Jamaica')],
    ['JP', tt('Japan')],
    ['JE', tt('Jersey')],
    ['JO', tt('Jordan')],
    ['KZ', tt('Kazakhstan')],
    ['KE', tt('Kenya')],
    ['KI', tt('Kiribati')],
    ['KP', tt('Korea, DPR')],
    ['KR', tt('Korea, Republic of')],
    ['KW', tt('Kuwait')],
    ['KG', tt('Kyrgyzstan')],
    ['LA', tt('Lao PDR')],
    ['LV', tt('Latvia')],
    ['LB', tt('Lebanon')],
    ['LS', tt('Lesotho')],
    ['LR', tt('Liberia')],
    ['LY', tt('Libya')],
    ['LI', tt('Liechtenstein')],
    ['LT', tt('Lithuania')],
    ['LU', tt('Luxembourg')],
    ['MO', tt('Macao')],
    ['MK', tt('Macedonia, Republic of')],
    ['MG', tt('Madagascar')],
    ['MW', tt('Malawi')],
    ['MY', tt('Malaysia')],
    ['MV', tt('Maldives')],
    ['ML', tt('Mali')],
    ['MT', tt('Malta')],
    ['MH', tt('Marshall Islands')],
    ['MQ', tt('Martinique')],
    ['MR', tt('Mauritania')],
    ['MU', tt('Mauritius')],
    ['YT', tt('Mayotte')],
    ['MX', tt('Mexico')],
    ['FM', tt('Micronesia, Fed. States of')],
    ['MD', tt('Moldova, Republic of')],
    ['MC', tt('Monaco')],
    ['MN', tt('Mongolia')],
    ['ME', tt('Montenegro')],
    ['MS', tt('Montserrat')],
    ['MA', tt('Morocco')],
    ['MZ', tt('Mozambique')],
    ['MM', tt('Myanmar')],
    ['NA', tt('Namibia')],
    ['NR', tt('Nauru')],
    ['NP', tt('Nepal')],
    ['NL', tt('Netherlands')],
    ['NC', tt('New Caledonia')],
    ['NZ', tt('New Zealand')],
    ['NI', tt('Nicaragua')],
    ['NE', tt('Niger')],
    ['NG', tt('Nigeria')],
    ['NU', tt('Niue')],
    ['NF', tt('Norfolk Island')],
    ['MP', tt('Northern Mariana Islands')],
    ['NO', tt('Norway')],
    ['OM', tt('Oman')],
    ['PK', tt('Pakistan')],
    ['PW', tt('Palau')],
    ['PS', tt('Palestine, State of')],
    ['PA', tt('Panama')],
    ['PG', tt('Papua New Guinea')],
    ['PY', tt('Paraguay')],
    ['PE', tt('Peru')],
    ['PH', tt('Philippines')],
    ['PN', tt('Pitcairn')],
    ['PL', tt('Poland')],
    ['PT', tt('Portugal')],
    ['PR', tt('Puerto Rico')],
    ['QA', tt('Qatar')],
    ['RE', tt('Réunion')],
    ['RO', tt('Romania')],
    ['RU', tt('Russian Federation')],
    ['RW', tt('Rwanda')],
    ['BL', tt('St. Barthélemy')],
    ['SH', tt('St. Helena, Ascension and ...')],
    ['KN', tt('St. Kitts and Nevis')],
    ['LC', tt('St. Lucia')],
    ['MF', tt('St. Martin (French part)')],
    ['PM', tt('St. Pierre and Miquelon')],
    ['VC', tt('St. Vincent & the Grenadines')],
    ['WS', tt('Samoa')],
    ['SM', tt('San Marino')],
    ['ST', tt('Sao Tome and Principe')],
    ['SA', tt('Saudi Arabia')],
    ['SN', tt('Senegal')],
    ['RS', tt('Serbia')],
    ['SC', tt('Seychelles')],
    ['SL', tt('Sierra Leone')],
    ['SG', tt('Singapore')],
    ['SX', tt('Sint Maarten (Dutch part)')],
    ['SK', tt('Slovakia')],
    ['SI', tt('Slovenia')],
    ['SB', tt('Solomon Islands')],
    ['SO', tt('Somalia')],
    ['ZA', tt('South Africa')],
    ['GS', tt('South Georgia and the S.S.Is.')],
    ['ES', tt('Spain')],
    ['LK', tt('Sri Lanka')],
    ['SD', tt('Sudan')],
    ['SR', tt('Suriname')],
    ['SS', tt('South Sudan')],
    ['SJ', tt('Svalbard and Jan Mayen')],
    ['SZ', tt('Swaziland')],
    ['SE', tt('Sweden')],
    ['CH', tt('Switzerland')],
    ['SY', tt('Syrian Arab Republic')],
    ['TW', tt('Taiwan, Province of China')],
    ['TJ', tt('Tajikistan')],
    ['TZ', tt('Tanzania, United Republic of')],
    ['TH', tt('Thailand')],
    ['TL', tt('Timor-Leste')],
    ['TG', tt('Togo')],
    ['TK', tt('Tokelau')],
    ['TO', tt('Tonga')],
    ['TT', tt('Trinidad and Tobago')],
    ['TN', tt('Tunisia')],
    ['TR', tt('Turkey')],
    ['TM', tt('Turkmenistan')],
    ['TC', tt('Turks and Caicos Islands')],
    ['TV', tt('Tuvalu')],
    ['UG', tt('Uganda')],
    ['UA', tt('Ukraine')],
    ['AE', tt('United Arab Emirates')],
    ['GB', tt('United Kingdom')],
    ['US', tt('United States')],
    ['UM', tt('US Minor Outlying Islands')],
    ['UY', tt('Uruguay')],
    ['UZ', tt('Uzbekistan')],
    ['VU', tt('Vanuatu')],
    ['VE', tt('Venezuela, BR')],
    ['VN', tt('Viet Nam')],
    ['VG', tt('Virgin Islands, British')],
    ['VI', tt('Virgin Islands, U.S.')],
    ['WF', tt('Wallis and Futuna')],
    ['EH', tt('Western Sahara')],
    ['YE', tt('Yemen')],
    ['ZM', tt('Zambia')],
    ['ZW', tt('Zimbabwe')],
  ];
}

export const countrySorter = (
  countryPairA: string[],
  countryPairB: string[]
): SortOutput => {
  if (countryPairA[1] > countryPairB[1]) {
    return SortOutput.GreaterThan;
  }
  if (countryPairA[1] < countryPairB[1]) {
    return SortOutput.LessThan;
  }
  return SortOutput.Equals;
};

// Defaulting to Spanish for the rest to display the short hour format (no leading 0)
// see: https://qustodio.atlassian.net/browse/PAR-2710
export const getLocaleByCountry = countryCode =>
  countriesUsingAMPMformat[countryCode] || 'es';

export const isUsingAMPMFormat = countryCode =>
  countriesUsingAMPMformat[countryCode] !== undefined;

export function isSetCountry(accountCountry, countryApiCode) {
  return accountCountry === countryApiCode;
}

export const filterCountriesByString = (filter: string) => {
  const capitalizedFilter = capitalize(filter);
  return getAllCountries().filter(
    country =>
      !capitalizedFilter ||
      (capitalizedFilter && country[1].indexOf(capitalizedFilter) !== -1)
  );
};

export const filterTimezonesByString = (
  filter: string,
  currentTime: Moment.Moment
) => {
  const capitalizedFilter = capitalize(filter);
  return getAllTimezones(currentTime).filter(
    timezone =>
      !capitalizedFilter ||
      (capitalizedFilter && timezone[2].indexOf(capitalizedFilter) !== -1)
  );
};

export const getPhone = telephone => {
  return telephone || '';
};
