/**
 * These are supposed to be only selectors that use other selectors.
 * We don't want to expose this in the selectors index.
 */
import { createSelector } from 'reselect';

import State from '../store/state';
import { getDayOfWeekConverted } from '../helpers/dates';
import { getTimezone } from './account';
import { getCurrentTime } from './time';

export const getToday = createSelector(
  getCurrentTime,
  getTimezone,
  (currentTime, timezone) => getDayOfWeekConverted(currentTime, timezone)
);

export const getDailyTimeLimitsCheckedDay = (state: State) => {
  const checkedDay = state.get('profileRules').get('checkedDay');
  return checkedDay !== null ? checkedDay : getToday(state);
};
