/* this file is not transformed with babel */
/* eslint-disable */
var Sentry = require('@sentry/react');

// fetch() polyfill for making API calls
require('whatwg-fetch');

// abortController polyfill to handle fetch() request timeouts
require('abort-controller/polyfill');

// Log unhandled promise rejections to console
// Promise rejections that are handled in another tick (setTimeout) are not taken into account and
// will be logged by Sentry internal window.onerror handler.
window.onunhandledrejection = function (e) {
  Sentry.captureException(e.reason);
  console.error('Unhandled promise rejection', e.reason, e.promise);
};
