import { Record } from 'immutable';
import { snakeCaseToCamelCase } from '../../api/mappings/case';
import {
  CategoriesActivity,
  CategoriesActivityMethods,
  CategoriesActivityRecordType,
} from './types/categories.types';

export const CategoriesActivityRecord = Record<
  CategoriesActivity,
  CategoriesActivityMethods
>({
  hits: 0,
  parentCategoryIcon: '',
  parentCategoryName: '',
  categoryName: '',
  usagePercentage: 0,
});

CategoriesActivityRecord.fromPayload = payload => {
  const camelized = snakeCaseToCamelCase(payload);
  const usagePercentage = Math.round(camelized.usagePercentage);
  return CategoriesActivityRecord({
    ...camelized,
    usagePercentage,
  }) as CategoriesActivityRecordType;
};

CategoriesActivityRecord.serialize = record => ({
  ...record.toJS(),
});
