import { Action } from 'redux';
import * as R from 'ramda';
import api from '../../../api';
import { fetchAccount } from '../../../ducks/account';
import { fetchProfiles, modifyProfile } from '../../../ducks/profiles';
import { requestLinkPendingStudents } from '../../../ducks/students';
import { ProfileRecord } from '../../../records/profile/types/Profile.types';
import {
  // eslint-disable-next-line import/named
  StudentRecord,
} from '../../../records/student/types/Student.type';
import { getNextStudentUid } from '../../../selectors';
import State, { Dispatch } from '../../../store/state';
import * as Records from '../../../records';
import { sequentially, parallel } from '../../../helpers/thunks';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { schoolOnboardingErrorLog } from '../../../helpers/logger/onboarding';

const nextPagePath = (studentUid: string) =>
  `/onboarding-schools/complete-student/${studentUid}`;
const errorPagePath = '/onboarding-schools/error';
const wrongInfoErrorPagePath = '/onboarding-schools/error-wrong-information';
const completePath = '/modal/SchoolsWelcomeModal';

export const navigateToNextStudent =
  (currentUid?: StudentRecord['uid']) =>
  (dispatch: Dispatch, getState: () => State) => {
    const studentUid = getNextStudentUid(currentUid)(getState());
    const nextPage = studentUid ? nextPagePath(studentUid) : completePath;
    const navigate = getMultiPlatformNavigation();
    return dispatch(navigate({ type: 'inner', src: nextPage }));
  };

export const navigateToErrorPage = () => (dispatch: Dispatch) => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(navigate({ type: 'inner', src: errorPagePath }));
};

export const navigateToWrongInfoErrorPage = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(navigate({ type: 'inner', src: wrongInfoErrorPagePath }));
};

const studentApiCall =
  <T = Record<string, string | number>>({
    endPoint: endpoint,
    method,
    onLoad,
    onSuccess,
    onError = navigateToErrorPage,
    params = {},
    body,
  }: {
    endPoint: keyof typeof api;
    method: 'post' | 'put';
    onLoad: (loading: boolean) => Action;
    onSuccess: () => (d: Dispatch, getState: () => State) => Action;
    params?: Record<string, string | number>;
    body?: T;
    onError?: () => (d: Dispatch) => Action;
  }) =>
  (dispatch: Dispatch) => {
    dispatch(onLoad(true));
    return api[endpoint][method](params, body)
      .then(() => dispatch(onSuccess()))
      .catch(e => {
        schoolOnboardingErrorLog(e);
        return dispatch(onError());
      })
      .finally(() => dispatch(onLoad(false)));
  };

export const updateProfileBirthDateAndGoToNextStudentProfile = (
  uid: ProfileRecord['uid'],
  student: ProfileRecord,
  birthDate: ProfileRecord['birthDate']
) => {
  return (dispatch: Dispatch) => {
    return R.applyTo(
      student,
      R.pipe(
        record => record.set('birthDate', birthDate),
        Records.ProfileRecord.serialize,
        modifyProfile,
        dispatch,
        R.then(() => dispatch(navigateToNextStudent(uid)))
      )
    );
  };
};

export const linkAllPendingStudents = () =>
  studentApiCall({
    endPoint: 'linkAllPendingStudents',
    method: 'post',
    onLoad: requestLinkPendingStudents,
    onSuccess: () =>
      parallel(
        fetchAccount(),
        sequentially(fetchProfiles(), navigateToNextStudent())
      ),
  });
