import { createSelector } from 'reselect';
import { parseISODateString } from '../../helpers/dates';
import { memoizedMoment } from '../helpers';

import type State from '../../store/state';

export const getCurrentTimeRaw = (state: State) =>
  state.get('times').get('currentTime');

/**
 * Returns a new Moment instance each time called, even if state hasn't changed.
 * When creating memoized selectors (createSelector) use getCurrentTimeRaw instead.
 */
export const getCurrentTime = createSelector(
  getCurrentTimeRaw,
  memoizedMoment(parseISODateString)
);
