import * as React from 'react';
import { classNames } from '../../helpers';
import { Layout, Tooltip, Typography } from 'styleguide-react';
import { useGuessInputDevice } from '../../hooks/useGuessInputDevice';

interface ChartsCustomTooltipProps {
  id: string;
  hide?: boolean;
  title?: string;
  subtitle?: string;
  coords: { x: number; y: number };
  className?: string;
}

const getContent = (title?: string, subtitle?: string) => {
  if (!title && !subtitle) return null;
  return (
    <Layout maxWidth="200">
      {title && (
        <Typography type="body2" weight="semibold">
          {title}
        </Typography>
      )}
      {subtitle && <Typography type="caption1">{subtitle}</Typography>}
    </Layout>
  );
};

const ChartsCustomTooltip = ({
  id,
  coords,
  title,
  subtitle,
  hide,
  className,
}: ChartsCustomTooltipProps) => {
  const deviceType = useGuessInputDevice();
  return (
    <Tooltip
      id={id}
      isOpen
      strategy="fixed"
      label={getContent(title, subtitle)}
      // we have to take into account the thumb size (almost 35px)
      gutter={deviceType === 'touch' ? 35 : undefined}
      smoothTransition={deviceType !== 'touch'}
      className={classNames(className, hide ? 'par-util-visibility--hide' : '')}
      coords={coords}
    />
  );
};

export default ChartsCustomTooltip;
