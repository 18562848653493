import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  Typography,
} from 'styleguide-react';

const ScreenTimeModal = ({ onClickClose }) => {
  return (
    <Modal
      onClickClose={onClickClose}
      size="small"
      title={t('What is screen time?')}
      header={{ icon: <Icon type={IconType.infoCircle} /> }}
      buttons={[
        <Button
          onClick={onClickClose}
          size={ButtonSize.medium}
          block
          key="ScreenTimeModal__button"
        >
          {t('Ok')}
        </Button>,
      ]}
    >
      <Typography type="body1">
        {t(
          'Screen time is the amount of time that the device’s screen is active, including when no apps or websites are in use.'
        )}
      </Typography>
    </Modal>
  );
};

export default ScreenTimeModal;
