import { RULE_PANIC_BUTTON } from '../../../../constants';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

// set of rules that only apply to Android devices
const androidRules = [RULE_PANIC_BUTTON];

export const isVisibleAndroidLabel = ({
  profileRule,
  profileAndroidDevicesCount,
}: ProfileRulesLabelPredicatesProps) => {
  // this should only be shown to the user if the account don't have any Android device
  if (!profileAndroidDevicesCount) {
    return androidRules.includes(profileRule);
  }

  return false;
};
