import * as React from 'react';
import {
  FlexLayout,
  FreeText,
  Icon,
  IconSize,
  IconType,
  Layout,
  TransparentButton,
} from 'styleguide-react';
import { classNames } from '../../../helpers';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

interface NavHeaderProps {
  title?: React.ReactNode;
  hasShadow?: boolean;
  testId?: string;
  onPrev?: () => void;
  onClose?: () => void;
}

const NavHeader = ({
  title,
  hasShadow = true,
  testId = undefined,
  onPrev,
  onClose,
}: NavHeaderProps) => {
  return (
    <RenderWhen screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}>
      {screenSize => (
        <FlexLayout
          testId={testId}
          className={classNames(
            'par-nav-header',
            hasShadow ? 'par-nav-header--has-shadow' : null
          )}
          mainaxis="row"
          crossaxisAlignment="center"
          mainaxisAlignment="space-between"
          padding={screenSize === ScreenSize.TabletOrDesktop ? '16' : '8'}
          paddingLeft="24"
          paddingRight="24"
          backgroundColor="white"
          width="100%"
        >
          <FlexLayout mainaxis="row" crossaxisAlignment="center">
            {onPrev && (
              <Layout position="relative" left={-8}>
                <TransparentButton onClick={onPrev}>
                  <Icon
                    square
                    type={IconType.arrowLeft}
                    size={IconSize.lg}
                    testId={`${testId}-back`}
                  />
                </TransparentButton>
              </Layout>
            )}
            {title ? getTitle(title) : null}
          </FlexLayout>
          {onClose && (
            <TransparentButton onClick={onClose}>
              <Icon
                square
                type={IconType.times}
                size={IconSize.lg}
                testId={`${testId}-close`}
              />
            </TransparentButton>
          )}
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

NavHeader.displayName = 'NavHeader';

const getTitle = (title: React.ReactNode) => {
  if (typeof title === 'string')
    return (
      <Layout marginTop="8" marginBottom="8">
        <FreeText fontSize="16" color="gray-semi">
          {title}
        </FreeText>
      </Layout>
    );

  return title;
};

export default NavHeader;
