import * as React from 'react';
import { t } from '../../lib/i18n';
import { Button, ButtonSize, Modal, Typography } from 'styleguide-react';
import RoutineIcon from '../Routines/RoutineIcon/RoutineIcon';
import { RoutineColor } from '../../palettes/RoutineColor';
import { RoutineIconType } from '../../records/routines/types/Routine.types';
import { getRoutinesIcon } from '../Routines/helpers/routineIcons';

const colorOptions: RoutineColor[] = [
  RoutineColor.yellow,
  RoutineColor.green,
  RoutineColor.blue,
  RoutineColor.marine,
  RoutineColor.red,
  RoutineColor.purple,
  RoutineColor.pink,
];

const iconOptions: RoutineIconType[] = [
  'bookOpen',
  'gamepadModern',
  'bullseye',
  'moon',
  'ban',
  'music',
  'utensils',
];

const RoutinesHeaderIcon = () => {
  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    const animate = () => {
      setIndex(i => (i + 1) % colorOptions.length);
    };

    const interval = setInterval(animate, 2000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const icon = getRoutinesIcon(iconOptions[index]);
  const color = colorOptions[index];

  return (
    <div className="par-routines-info-modal__header-icon-container">
      <RoutineIcon size="xlarge" icon={icon} color={color} />
      <RoutineIcon className="icon-2" size="xlarge" icon={icon} color={color} />
      <RoutineIcon className="icon-3" size="xlarge" icon={icon} color={color} />
      <RoutineIcon className="icon-4" size="xlarge" icon={icon} color={color} />
    </div>
  );
};

const RoutinesInfoModal = ({ onClose }: { onClose: () => void }) => (
  <Modal
    className="par-routines-info-modal"
    size="small"
    onClickClose={onClose}
    header={{
      icon: <RoutinesHeaderIcon />,
    }}
    title={t('What are routines?')}
    buttons={[
      <Button key="button" size={ButtonSize.medium} onClick={onClose} block>
        {t('Ok')}
      </Button>,
    ]}
  >
    <Typography type="body1" marginBottom="16">
      {t(
        'Routines allow you to schedule different rules throughout the day to help your kids focus when they need to.'
      )}
    </Typography>

    <Typography type="body1">
      {t(
        'Pick from ready-made presets to get set up quickly, or create your own rules for more personalization.'
      )}
    </Typography>
  </Modal>
);

export default RoutinesInfoModal;
