import * as React from 'react';

import {
  Card,
  FlexLayout,
  GlobalType,
  ToggleContainer,
} from 'styleguide-react';
import { DevicesStatus } from '../../businessLogic/devices';

import DeviceStatusCard from './DeviceStatusCard/DeviceStatusCard';
import AddNew, { AddNewProps } from './AddNew/AddNew';
import { ProfileId } from '../../records/profile';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import type { NavigateToDeviceInfoModal } from '../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';
import { t } from '../../lib/i18n';
import { SmartDevicesAnalyticsReport } from '../../ducks/smartDeviceStatus/types';

export enum SmartDevicesStatusTest {
  prefix = 'SmartDevicesStatus',
}

interface SmartDevicesStatusProps {
  profileId: ProfileId;
  devices: DevicesStatus;
  timezone: string;
  locale: string;
  isLoaded: boolean;
  onAddNew: AddNewProps['onAddNew'];
  loadData: (profileId: ProfileId) => void;
  navigateToInfoModal: NavigateToDeviceInfoModal;
  reportToAnalytics: (devices: Array<SmartDevicesAnalyticsReport>) => void;
  cleanData: () => void;
}

const SmartDevicesStatus = ({
  profileId,
  devices,
  timezone,
  locale,
  isLoaded,
  onAddNew,
  loadData,
  navigateToInfoModal,
  reportToAnalytics,
  cleanData,
}: SmartDevicesStatusProps) => {
  React.useEffect(() => {
    loadData(profileId);
  }, []);

  React.useEffect(() => {
    if (!isLoaded) {
      const obj = devices.reduce((o, device) => {
        return [
          ...o,
          {
            deviceId: device.id ? device.id : 'school',
            status: device.status,
          },
        ] as Array<SmartDevicesAnalyticsReport>;
      }, [] as Array<SmartDevicesAnalyticsReport>);
      reportToAnalytics(obj);
    }
    return cleanData();
  }, [isLoaded]);

  return (
    <div className="SmartDevicesStatus__container">
      <RenderWhen
        screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
      >
        {screenSize => (
          <ToggleContainer
            buttonOpenText={t('Show devices')}
            buttonCloseText={t('Hide devices')}
            initiallyOpen={screenSize !== ScreenSize.Mobile}
          >
            <Card type={GlobalType.white}>
              <FlexLayout
                mainaxis={screenSize === ScreenSize.Mobile ? 'column' : 'row'}
                mainaxisAlignment="start"
                wrap="wrap"
              >
                {devices.map(device => (
                  <DeviceStatusCard
                    key={`${device.id ?? device.name}-${device.status}`}
                    name={device.name}
                    lastseen={device.lastseen}
                    isOnline={device.isOnline}
                    type={device.type}
                    status={device.status}
                    isDelegated={device.isDelegated}
                    affiliation={device.affiliation}
                    id={device.id}
                    timezone={timezone}
                    locale={locale}
                    navigateToInfoModal={navigateToInfoModal}
                  />
                ))}
                <AddNew
                  onAddNew={onAddNew}
                  className={
                    devices.length <= 2 && screenSize !== ScreenSize.Mobile
                      ? 'AddNew__pull-right'
                      : 'AddNew__pull-left'
                  }
                />
              </FlexLayout>
            </Card>
          </ToggleContainer>
        )}
      </RenderWhen>
    </div>
  );
};
export default SmartDevicesStatus;
