import * as React from 'react';

import { FlexLayout, Layout, SafeArea } from 'styleguide-react';
import { classNames } from '../../../helpers';
import useKeepScroll from '../hooks/useKeepScroll/useKeepScroll';
import usePullToRefresh from '../hooks/usePullToRefresh';
import type { PageTitleLayoutProps } from '../PageTitleLayout';
import MobilePageTitle from './page/MobilePageTitle';
import { MobilePageLayoutProps } from './types';

const MobilePageLayout: React.FunctionComponent<
  PageTitleLayoutProps & MobilePageLayoutProps
> = (props: PageTitleLayoutProps & MobilePageLayoutProps) => {
  const {
    configuration,
    mainChildren,
    beforeCardChildren,
    cardBannerChildren,
    beforePullRefreshChildren,
    afterPullRefreshChildren,
    afterCardChildren,
  } = props;

  const divScroll = React.useRef<HTMLDivElement>(null);
  const children = usePullToRefresh(
    divScroll,
    mainChildren,
    configuration?.pullToRefresh
  );
  useKeepScroll(divScroll, configuration?.keepScrollKey);
  const Tag = configuration?.useNonFlexLayout ? Layout : FlexLayout;
  return (
    <FlexLayout
      mainaxis="column"
      height="100%"
      className="par-page-layout par-page-layout--size-mobile"
      testId="MobilePageLayout_Container"
    >
      {!configuration?.hideHeader && <MobilePageTitle {...props} />}
      {beforeCardChildren}
      <Tag
        mainaxis="column"
        flexGrow="2"
        ref={divScroll}
        scrollY={!configuration?.disableScroll}
        height="100%"
        backgroundColor={configuration?.mainColumnBackgroundColor ?? 'white'}
        className="par-page-layout__content"
        testId="MobilePageLayout_Content_Container"
        paddingTop="16"
        paddingLeft={configuration?.removePaddings ? undefined : '16'}
        paddingRight={configuration?.removePaddings ? undefined : '16'}
        paddingBottom="16"
        minHeight="0"
      >
        {cardBannerChildren && (
          <Layout
            marginBottom="16"
            testId="MobilePageLayout_Banner_Container"
            className={classNames(
              'MobilePageLayout__card-banner',
              configuration?.removePaddings
                ? 'MobilePageLayout__card-banner--no-paddings'
                : ''
            )}
          >
            {cardBannerChildren}
          </Layout>
        )}
        {beforePullRefreshChildren}
        {children}
        {afterPullRefreshChildren}
      </Tag>
      {afterCardChildren}
      <SafeArea />
    </FlexLayout>
  );
};
export default MobilePageLayout;
