import { SafeNetworksSettingsOperation } from '../../records/safeNetworksSettings/safeNetworkSettings';
import { SafeNetworksSettingsPayload } from '../../records/safeNetworksSettings/types/safeNetworkSetting';
import { NormalizedResponse } from './types';

export const normalizeResponse = (
  response: SafeNetworksSettingsPayload,
  profileUid: string
): NormalizedResponse => {
  const safeNetworkRecord = SafeNetworksSettingsOperation.fromPayload(response);
  const records = { safeNetworkSettings: {} };
  records.safeNetworkSettings[profileUid] = safeNetworkRecord;

  return records;
};
