import { connect } from 'react-redux';
import { SyntheticEvent } from 'react';
import { Map, List } from 'immutable';
import { t } from '../../../lib/i18n';
import {
  track,
  Events,
  AppRuleNames,
  trackSetUpRuleName,
} from '../../../helpers/analytics';
import AppRules from '../../../components/AppRules/AppRules';
import {
  getAppRulesEnabled,
  getAppFilter,
  getAppRulesApplicationListFiltered,
  getProfileOrDefault,
  getAppFilterSearchQuery,
  getLicense,
  getIsBlockedUnsupportedBrowsers,
  getIsAlertNewApps,
  getProfileId,
  getAppRulesAppListWithExclusions,
  getQueryParam,
} from '../../../selectors';
import {
  FILTER_APPS_ALL,
  FILTER_APPS_BLOCKED,
  FILTER_APPS_HASLIMIT,
  SettingsToggleNames,
  AppCategory,
  APPLICATION_BLOCKED,
  APPLICATION_ALLOWED,
  FILTER_APPS_ALLOWED,
  AppFilter,
} from '../../../constants';
import {
  toggleAppRulesEnabled,
  delayedSearch,
  clickCategory,
  onToggleAppRulesSetting,
  requestModifyQuotas,
  clearSearchQuery,
} from '../../../actions/AppRulesActions';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import { isFree } from '../../../records/license';
import State, { BaseThunk, RouterParams } from '../../../store/state';

import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { navigateToDailyTimeLimit } from '../../../actions/ProfileRulesActions';
import {
  everyDayWithFullQuota,
  everyDayWithZeroQuota,
} from '../../../businessLogic/timeRules/timeQuota';
import { AppRuleRecord } from '../../../records/profileRules';
import { AppRulesCardProps } from '../../../components/AppRules/AppRulesCard';
import { FilterProps } from 'styleguide-react';
import { goBackIfHistory } from '../../../ducks/routing';
import { navigateToNonBlockableAppModalFrom } from '../../../actions/Navigation';
import { shouldBlockThisFeature } from '../../../helpers/premiumFeatures';
import { showUnlockAlertsAndTimeLimitsModal } from '../../../actions/PremiumFeaturesActions';
import { isSettingsToggleBlocked } from '../../../helpers/premiumFeatures/isSettingsToggleBlocked';
import { UnlockAlertsAndTimeLimitsModalReferrer } from '../../Modal/UnlockAlertsAndTimeLimitsModalContainer';
import { redirectToAddDeviceFlyover } from '../../../actions/redirectAddDeviceFlyover';
import { getFeatures } from '../../../ducks/features';
import { isSchoolLicenseWithValidDevices } from '../../../businessLogic/profileRules/delegation';
import { getAppCategories } from '../../../ducks/appCategories';
import { getTotalAppsInCategories } from '../../../helpers/appsCategories';

type EnhancedAppCategory = AppCategory & {
  color: FilterProps['type'];
  searchPlaceholder: string;
};

const getEnhancedAppCategories: () => EnhancedAppCategory[] = () => [
  {
    filter: FILTER_APPS_ALL,
    label: t('All'),
    color: 'secondary',
    searchPlaceholder: t('Search all apps'),
  },
  {
    filter: FILTER_APPS_ALLOWED,
    label: t('Allowed'),
    color: 'primary',
    searchPlaceholder: t('Search allowed apps'),
  },
  {
    filter: FILTER_APPS_BLOCKED,
    label: t('Blocked'),
    color: 'error',
    searchPlaceholder: t('Search blocked apps'),
  },
  {
    filter: FILTER_APPS_HASLIMIT,
    label: t('Limits set'),
    color: 'secondary',
    searchPlaceholder: t('Search apps with limits set'),
  },
];

const onClickSettings = (dispatch, getState) => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${getProfileId(getState())}/rules/appRules-settings`,
    })
  );
};

export const createOnSelectStatusHandler =
  (
    data: Parameters<AppRulesCardProps['onSelectStatus']>[0],
    profileId: string
  ): BaseThunk =>
  (dispatch, getState) => {
    if (data.type === 'SetLimits') {
      const license = getLicense(getState());
      if (shouldBlockThisFeature('gamesAppsTimeLimits', license.subtype)) {
        return dispatch(
          showUnlockAlertsAndTimeLimitsModal(
            UnlockAlertsAndTimeLimitsModalReferrer.gamesAndAppsSetLimits
          )
        );
      }
      return dispatch(
        navigateToDailyTimeLimit({
          profileId,
          appKey: data.appKey,
          isMobile: data.isMobile,
        })
      );
    }

    // only track Allow/Block status change here
    trackAppRuleStatusChange(data);

    return dispatch(
      requestModifyQuotas({
        appKey: data.appKey,
        profileId,
        action:
          data.type === 'Allowed' ? APPLICATION_ALLOWED : APPLICATION_BLOCKED,
        quotas: Map(
          data.type === 'Allowed'
            ? everyDayWithFullQuota()
            : everyDayWithZeroQuota()
        ),
      })
    );
  };

const mapStateToProps = (state: State, { params: { profileId } }) => {
  const license = getLicense(state);
  const searchQuery = getAppFilterSearchQuery(state);
  const activeFilter = getAppFilter(state) as AppFilter;
  const appEnhancedCategories = getEnhancedAppCategories();
  const activeFilterAppCategory = appEnhancedCategories.find(
    category => category.filter === activeFilter
  );
  const apps = getAppRulesAppListWithExclusions(
    state,
    profileId
  ) as List<AppRuleRecord>;
  const features = getFeatures(state);

  const categories = getAppCategories(state);
  const totalAppsByCategory = getTotalAppsInCategories(
    state,
    categories,
    profileId
  );

  return {
    searchQuery,
    activeFilter,
    appEnhancedCategories,
    activeFilterAppCategory,
    noAppsDetected: apps.size === 0,
    applicationList: getAppRulesApplicationListFiltered(
      state,
      profileId
    ) as List<AppRuleRecord>,
    profileName: getProfileOrDefault(state, profileId).name,
    appRulesEnabled: ((!isFree(getLicense(state).type) ||
      isSchoolLicenseWithValidDevices(state, profileId)) &&
      getAppRulesEnabled(state, profileId)) as boolean,
    isBlockedUnsupportedBrowsers: getIsBlockedUnsupportedBrowsers(
      state,
      profileId
    ) as boolean,
    isAlertNewApps: getIsAlertNewApps(state, profileId) as boolean,
    isSettingTogglePremium: isSettingsToggleBlocked(license.subtype, features),
    categories,
    totalAppsByCategory,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickSettings: () => dispatch(onClickSettings),
  onToggleAppRulesEnabled: (ev: SyntheticEvent) => {
    const { checked } = ev.target as HTMLInputElement;
    track(Events.SetupRuleApp, {
      rule_name: checked
        ? AppRuleNames.EnableRestrictions
        : AppRuleNames.DisableRestrictions,
    });
    dispatch(toggleAppRulesEnabled(profileId, checked));
  },
  onClickBack: () => dispatch(goBackIfHistory()),
  onChangeSearch: (value: string) => dispatch(delayedSearch(500)(value)),
  onClickFilter: (category: EnhancedAppCategory) => () =>
    dispatch(clickCategory(category)),
  onToggleAppRulesSetting: (target: SettingsToggleNames, toggleOn: boolean) =>
    dispatch(onToggleAppRulesSetting(profileId, target, toggleOn)),
  onSelectStatus: (data: Parameters<AppRulesCardProps['onSelectStatus']>[0]) =>
    dispatch(createOnSelectStatusHandler(data, profileId)),
  onClickApp: (appKey: string) => {
    dispatch(navigateToNonBlockableAppModalFrom('appRules', profileId, appKey));
  },
});

const trackAppRuleStatusChange = (
  data: Parameters<AppRulesCardProps['onSelectStatus']>[0]
) => {
  const ruleName =
    data.type === 'Allowed'
      ? AppRuleNames.AlwaysAllowApp
      : AppRuleNames.AlwaysBlockApp;

  trackSetUpRuleName(ruleName, data.appName, data.appPlatform);
};

const AppRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRules);

export type AppRulesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

AppRulesContainer.load = (): BaseThunk => (dispatch, getState) => {
  dispatch(clearSearchQuery());
  const urlQueryParam = getQueryParam(getState(), 'app_name');
  if (urlQueryParam) {
    dispatch(delayedSearch(0)(urlQueryParam));
  }
};

AppRulesContainer.redirect =
  ({ profileId }: RouterParams): BaseThunk =>
  async (dispatch, getState) => {
    const state = getState();

    const allowAccessToRulesPage = isSchoolLicenseWithValidDevices(
      state,
      profileId
    );

    if (allowAccessToRulesPage) return;

    await dispatch(
      redirectToAddDeviceFlyover(
        profileId,
        `/profiles/${profileId}/rules/appRules/addDeviceFlyover`
      )
    ).then(redirectedToAddDevice => {
      if (redirectedToAddDevice) return;
      dispatch(
        redirectFreeToPremiumAction(profileId, 'rules/appRules/premiumFlyover')
      );
    });
  };

export default AppRulesContainer;
