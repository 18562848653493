import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { ofType } from 'redux-observable';
import type { Observable, Dependencies, AppAction } from '../../epics/types';
import { receiveProfile, RECEIVE_PROFILE, requestProfilesError } from '.';
import { not } from '../../helpers/predicates';
import { ProfileId } from '../../records/profile';
import { APIError } from '../../lib/errors';
import { showErrorAlert } from '../../helpers/errorHandling';

export type RequestProfileErrorAction = ReturnType<typeof requestProfilesError>;
export type RequestProfileReceivedAction = ReturnType<typeof receiveProfile>;

type ActionsObservable = Observable<AppAction>;
type Api = Dependencies['api'];

const isProfileLoaded = ({ profileId, profileReceivedAction }) =>
  profileId === String(profileReceivedAction.payload.result);

const toProfileReceivedIfIsNotLoaded = (
  actions$: ActionsObservable,
  profileId$: Observable<ProfileId>,
  api: Api
): Observable<RequestProfileReceivedAction | RequestProfileErrorAction> => {
  const profileReceivedAction$ = actions$.pipe(ofType(RECEIVE_PROFILE));

  /*
  This implementation waits for another resource (nested router) 
  must to do profile by id request (combineLatest).
  When not depend on the nested router, must implement another system such as a race.
  */
  const ensuredProfileReceivedAction$ = combineLatest({
    profileId: profileId$,
    profileReceivedAction: profileReceivedAction$,
  }).pipe(
    filter(not(isProfileLoaded)), // Avoid making the request if the profile has already been obtained
    switchMap(({ profileId }) => api.profiles.get({ profileId })),
    map(profile => receiveProfile(profile)),
    catchError((e: APIError) => {
      showErrorAlert(e);
      return of(requestProfilesError());
    })
  );

  return ensuredProfileReceivedAction$;
};

export const loadProfileData = (
  actions$: ActionsObservable,
  profileId$: Observable<ProfileId>,
  api: Api
): Observable<RequestProfileErrorAction | RequestProfileReceivedAction> => {
  const profileReceivedAction$ = toProfileReceivedIfIsNotLoaded(
    actions$,
    profileId$,
    api
  );

  return profileReceivedAction$;
};
