import { LicenseSubtype } from '../../records/license/types/License.types';
import { BlockableFeature } from './types/Feature';
import { exhaustiveCheck } from '../validations';
import flags from '../../sideEffects/flags';

export const shouldBlockThisFeature = (
  feature: BlockableFeature,
  licenseSubtype: LicenseSubtype
) => getFeaturesBlockedByLicenseSubtype(licenseSubtype).includes(feature);

const getFeaturesBlockedByLicenseSubtype = (
  licenseSubtype: LicenseSubtype
): BlockableFeature[] => {
  switch (licenseSubtype) {
    case 'free':
      if (flags.blockToFreePremiumFeatures.isEnabled()) {
        return [
          'webActivityAlerts',
          'blockedWebsiteAlerts',
          'gamesAppsTimeLimits',
          'appDownloadAlerts',
          'savedPlacesAlerts',
          'callsSMSRules',
          'youtubeRules',
          'routineBlocking',
          'routineContentFiltering',
        ];
      }
      return [];
    case 'premium_grandfather':
      return ['routineBlocking', 'routineContentFiltering'];
    case 'trial':
    case 'premium_complete':
      return [];
    case 'school_free':
      return [
        'webActivityAlerts',
        'blockedWebsiteAlerts',
        'savedPlacesAlerts',
        'callsSMSRules',
      ];
    case 'premium_basic':
      return [
        'webActivityAlerts',
        'blockedWebsiteAlerts',
        'gamesAppsTimeLimits',
        'appDownloadAlerts',
        'savedPlacesAlerts',
        'callsSMSRules',
        'youtubeRules',
        'routineContentFiltering',
      ];
    default:
      exhaustiveCheck(licenseSubtype);
      break;
  }

  return [];
};
