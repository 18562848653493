import * as React from 'react';
import { Link as LinkRouter } from 'react-router';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Label,
  Layout,
  Link,
  StyledHeader,
} from 'styleguide-react';
import { Field, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import { t } from '../../lib/i18n';
import InputTextField from '../Form/InputTextField';
import { FormNames } from '../../constants';
import cornerRighIllustration from '../../assets/base/images/v3/decorators/illustration_corner_right.svg';
import type { LockScreenProps } from '../../containers/LockScreenContainer';

const LockScreen: React.FunctionComponent<LockScreenProps> = ({
  onSubmit,
  accountName,
  onForgotPasswordClick,
  checkBiometricLogin,
}: LockScreenProps) => {
  React.useEffect(() => {
    checkBiometricLogin();
  }, []);

  return (
    <PageLayout
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 2,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
          hideHeader: true,
          fullHeightCard: true,
        },
        { screenSize: ScreenSize.Mobile, columns: 1, hideHeader: true },
      ]}
    >
      <Layout position="absolute" right={0} top={0}>
        <img alt="" src={cornerRighIllustration} role="presentation" />
      </Layout>
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="start"
        marginTop="104"
      >
        <StyledHeader type="h4" testId="login-welcome">
          {t('Welcome back, {{profileName}} !', {
            profileName: accountName,
          })}
        </StyledHeader>
        <Layout marginTop="8" width="100%">
          <p>{t('Enter your {{shortName}} password to continue.')}</p>
        </Layout>
        <Layout marginTop="40" width="100%">
          <LoginForm onSubmit={onSubmit} autoFocus />
        </Layout>

        <Layout marginTop="40" width="100%">
          <Button
            size={ButtonSize.medium}
            block
            testId="login-submit"
            htmlType="submit"
            form={FormNames.lockScreen}
          >
            {t('Log in')}
          </Button>
        </Layout>
        <Layout centerX marginTop="40">
          <Link
            onClick={onForgotPasswordClick}
            useAsLink={LinkRouter}
            testId="login-forgot-password"
          >
            {t('Forgot your password?')}
          </Link>
        </Layout>
      </FlexLayout>
    </PageLayout>
  );
};

const validate = (values: Map<string, string>) =>
  Map({ password: '' })
    .merge(values)
    .reduce(
      (errors, value, key) =>
        !value ? { [key]: fieldError(key), ...errors } : errors,
      {}
    );

const fieldError = (key: string) => {
  switch (key) {
    case 'password':
    default:
      return t('Please enter a valid password');
  }
};

export const LoginForm = reduxForm({
  form: FormNames.lockScreen,
  enableReinitialize: true,
  validate,
})(({ handleSubmit }) => {
  return (
    <form
      className="Form"
      onSubmit={handleSubmit}
      id={FormNames.lockScreen}
      style={{ width: '100%' }}
    >
      <Label for="password">{t('Password')}</Label>
      <Field
        id="password"
        name="password"
        component={InputTextField}
        type="password"
      />
      {/* include an invisible submit-button so that the mobile-keyboard
       has a "submit" button: */}
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
});

export default LockScreen;
