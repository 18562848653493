import { Dispatch } from '../../store/state';
import {
  SetRoutineMachineAction,
  resetRoutineMachineAction,
} from '../../ducks/routines';
import {
  RoutineMode,
  RoutineSteps,
  WebRuleExceptions,
  WebRuleType,
} from '../../components/Routines/routines.types';
import { harmfulWebCategories } from '../../records/category';
import {
  PolicyWebCategory,
  RoutineWebCategory,
} from '../../records/routines/policy/types/PolicyWebCategory.types';
import { PolicyAction } from '../../records/routines/policy/types/Policy.types';

/**
 *
 * Due to the need to send actions to the multi step state machine,
 * it will always be mandatory to remove the last executed command in order to avoid inconsistent states
 * in subsequent updates. This function contains the logic to prevent forgetting
 * the reset and should only be called to perform the action using this method.
 */
export const safeDispatchChangeMachineAction = (
  dispatch: Dispatch,
  action: SetRoutineMachineAction,
  ms = 200
) => {
  dispatch(action);
  setTimeout(() => dispatch(resetRoutineMachineAction()), ms);
};

export const stepNeedConfirmationToClose = (
  mode: RoutineMode,
  step: RoutineSteps
) => {
  const stepsWithConfirmation = [
    RoutineSteps.chooseNameAndStyle,
    RoutineSteps.selectBlockType,
    RoutineSteps.scheduleATimeSlot,
    RoutineSteps.selectWebsException,
    RoutineSteps.selectAppsException,
  ];

  if (mode === 'CREATE') return true;
  if (mode === 'EDIT' && stepsWithConfirmation.includes(step)) return true;
  return false;
};

const createWebRuleException =
  (action: PolicyAction) =>
  (category: RoutineWebCategory): PolicyWebCategory => ({
    category,
    action,
  });

export const getHarmfulWebRuleExceptions = (withAction: PolicyAction) =>
  harmfulWebCategories.map(createWebRuleException(withAction));

/**
 * When allowing all the web categories, we have to block harmful
 * categories to stay consistent the web rules behaviour.
 */
export const getBlockedHarmfulWebRuleException = (
  action: WebRuleType
): WebRuleExceptions => {
  if (action !== 'POLICY_ACTION_ALLOWED') return [];
  return getHarmfulWebRuleExceptions('POLICY_ACTION_BLOCKED');
};
