import * as React from 'react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import { FlexLayout, Layout } from 'styleguide-react';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import TotalScreenTimeCardContainer from '../../containers/TotalScreenTimeContainer/TotalScreenTimeCardContainer';
import { CardDimensions } from '../../constants';
import ScreenSummaryCardContainer from '../../containers/ScreenTimeSummary/ScreenSummaryCardContainer';
import type { ScreenTimeDetailsPageProps } from '../../containers/ActivitySummary/ScreenTimeDetailsPageContainer';
import { t } from '../../lib/i18n';

const ScreenTimeDetailsPage = ({
  profile,
  activeDateRange,
  isProfileCombineOrDelegated,
  onClickEditProfile,
  onSelectDateRange,
}: ScreenTimeDetailsPageProps) => {
  return (
    <PageLayout
      title={profile.name}
      subtitle={
        isProfileCombineOrDelegated
          ? t('See how your child has spent their time on devices.')
          : t('See how your child has spent their screen time on all devices')
      }
      configuration={[{ screenSize: ScreenSize.Desktop, columns: 1 }]}
      actions={[
        { actionName: 'back' },
        {
          actionName: 'edit',
          screenSize: ScreenSize.MobileOrTablet,
          onClick: () => {
            onClickEditProfile();
          },
        },
      ]}
    >
      <Layout marginBottom="8">
        <DateRangeSelector
          activeRange={activeDateRange}
          onChangeDateRange={onSelectDateRange}
        />
      </Layout>
      <FlexLayout mainaxis="row" wrap="wrap">
        <Layout margin="8" testId="par-total-screen-time-card-container">
          <TotalScreenTimeCardContainer
            profile={profile}
            width={CardDimensions.width}
            height={CardDimensions.height}
          />
        </Layout>
        <Layout margin="8" testId="par-screen-summary-card-container">
          <ScreenSummaryCardContainer
            profile={profile}
            height={CardDimensions.height}
            width={CardDimensions.width}
          />
        </Layout>
      </FlexLayout>
    </PageLayout>
  );
};

export default ScreenTimeDetailsPage;
