import { getProfileDevices } from './profileDevices';
import State from '../store/state';
import * as businessLogic from '../businessLogic/safeNetwork/safeNetwork';

export const profileHasBeenInSafeNetwork = (
  state: State,
  profileId: number
): ReturnType<typeof businessLogic.profileHasBeenInSafeNetwork> => {
  const devices = getProfileDevices(state, profileId).toJS();
  return businessLogic.profileHasBeenInSafeNetwork(profileId, devices);
};

export const numOfDevicesDisabledInSafeNetwork = (
  state: State,
  profileId: number
): ReturnType<typeof businessLogic.numOfDevicesDisabledInSafeNetwork> => {
  const devices = getProfileDevices(state, profileId).toJS();
  return businessLogic.numOfDevicesDisabledInSafeNetwork(profileId, devices);
};

export const areAllDevicesDisabledInSafeNetwork = (
  state: State,
  profileId: number
): ReturnType<typeof businessLogic.areAllDevicesDisabledInSafeNetwork> => {
  const devices = getProfileDevices(state, profileId).toJS();
  return businessLogic.areAllDevicesDisabledInSafeNetwork(profileId, devices);
};
