import * as React from 'react';
import { t } from '../../lib/i18n';
import { SummaryDateRanges } from '../../constants';
import {
  EmptyPlaceholder,
  FlexLayout,
  GlobalType,
  IconType,
  Layout,
  Tag,
} from 'styleguide-react';
import ScreenTimePieChart from '../ScreenTimePieChart/ScreenTimePieChart';
import { getDailyTimeLimitsFormattedTime } from '../ScreenTimePieChart/helpers';
import { minutesToHMFormat } from '../../helpers/dates';
import useMergedState from '../../hooks/useMergedState';
import { PieChartDataType } from '../ScreenTimePieChart/types';
import Loader from '../base/Loader';
import InfoIcon from '../Icons/InfoIcon';
import type { TotalScreenTimeProps } from '../../containers/TotalScreenTimeContainer/TotalScreenTimeContainer';

const TotalScreenTimeLoading = () => (
  <EmptyPlaceholder
    icon={<Loader size="small" color="secondary" />}
    text={t('Loading...')}
    fillAvailableSpace
    centered
    smallText
  />
);

const TotalScreenTimeError = ({ title }: { title: string }) => (
  <EmptyPlaceholder
    icon={<InfoIcon />}
    text={t("{{activityTitle}} couldn't load", {
      activityTitle: title,
    })}
    fillAvailableSpace
    centered
    maxWidth="75%"
    smallText
  />
);

const calculateScreenTime = ({
  defaultRulesSpentMinutes,
  routinesSpentMinutes,
  routinesMap,
  totalSpentMinutes,
  hasSeparatedScreenTime,
}: Pick<
  TotalScreenTimeProps,
  | 'defaultRulesSpentMinutes'
  | 'routinesSpentMinutes'
  | 'routinesMap'
  | 'totalSpentMinutes'
  | 'hasSeparatedScreenTime'
>) => {
  // if routine and default_rules times are not present, 'minutes' | 'hours' value will be used as default rules time.
  // This is here for retro-compatibility.
  const defaultScreenTimeMinutes = hasSeparatedScreenTime
    ? defaultRulesSpentMinutes
    : totalSpentMinutes;

  const defaultScreenTime = defaultScreenTimeMinutes
    ? [
        {
          name: t('Default rules') as string,
          color: GlobalType.secondary,
          minutes: defaultScreenTimeMinutes,
          uid: 'default-rules',
          consumeQuota: true,
        },
      ]
    : [];

  const routineScreenTime = routinesMap?.size
    ? Object.entries(routinesSpentMinutes || {}).map(([uid, minutes]) => {
        const routine = routinesMap.get(uid);
        if (!routine) return {};
        return {
          uid,
          minutes,
          name: routine.name,
          color: routine.color,
          consumeQuota: Boolean(routine.policy?.countsTowardsDailyTimeQuota),
        };
      })
    : [];

  return {
    routineScreenTime,
    defaultScreenTime,
  };
};

const getScreenTimeDeltaTagText = (
  dateRange: SummaryDateRanges,
  value: string
): string => {
  if (dateRange === SummaryDateRanges.Days7)
    return t('{{value}} from week before', { value });
  return t('{{value}} from {{days}} days before', { days: dateRange, value });
};

const TotalScreenTime = ({
  label,
  activeDateRange,
  averageSpendMinutes,
  dailyLimitMinutes,
  extraTime,
  isBlocked,
  totalSpentMinutes,
  incrementScreenTimeAverage,
  hasIncrementScreenTimeAverage,
  isFetchingInfo,
  hasError,
  isMobileView = false,
  routinesMap,
  defaultRulesSpentMinutes,
  routinesSpentMinutes,
  hasSeparatedScreenTime,
  loadWidget,
}: TotalScreenTimeProps) => {
  React.useEffect(loadWidget, []);

  const todayOrCustomPastDay = [
    SummaryDateRanges.Today,
    SummaryDateRanges.CustomPastDay,
  ].includes(activeDateRange);

  const [state, updateState] = useMergedState<{
    screenTimeDefaultRules: Array<PieChartDataType>;
    screenTimeRoutines: Array<PieChartDataType>;
  }>({
    screenTimeDefaultRules: [],
    screenTimeRoutines: [],
  });

  React.useEffect(() => {
    const { routineScreenTime, defaultScreenTime } = calculateScreenTime({
      defaultRulesSpentMinutes,
      routinesSpentMinutes,
      routinesMap,
      totalSpentMinutes,
      hasSeparatedScreenTime,
    });
    updateState({
      screenTimeDefaultRules: defaultScreenTime,
      screenTimeRoutines: routineScreenTime,
    });
  }, [
    defaultRulesSpentMinutes,
    routinesSpentMinutes,
    routinesMap,
    totalSpentMinutes,
    hasSeparatedScreenTime,
  ]);

  const renderInfoPanel = () => {
    const hasNoInfoToShow = !averageSpendMinutes && !incrementScreenTimeAverage;
    if (todayOrCustomPastDay || hasNoInfoToShow) return null;

    return (
      <FlexLayout
        width="100%"
        crossaxisAlignment={isMobileView ? 'center' : undefined}
        mainaxisAlignment={isMobileView ? 'center' : undefined}
        mainaxis={!isMobileView ? 'row' : 'column'}
      >
        {averageSpendMinutes ? (
          <Layout marginRight="8" marginTop={isMobileView ? '8' : undefined}>
            <Tag
              variant="squared"
              text={t('Daily average: {{time}}', {
                time: minutesToHMFormat(averageSpendMinutes || 0),
              })}
              size="regular"
              type={GlobalType.secondary}
            />
          </Layout>
        ) : null}

        {incrementScreenTimeAverage ? (
          <Layout marginRight="8" marginTop={isMobileView ? '8' : undefined}>
            <Tag
              variant="squared"
              text={getScreenTimeDeltaTagText(
                activeDateRange,
                `${incrementScreenTimeAverage}%`
              )}
              size="regular"
              type={
                hasIncrementScreenTimeAverage
                  ? GlobalType.marketing
                  : GlobalType.success
              }
              iconType={
                hasIncrementScreenTimeAverage
                  ? IconType.circleArrowUp
                  : IconType.circleArrowDown
              }
              iconProps={{ size: 'lg' }}
            />
          </Layout>
        ) : null}
      </FlexLayout>
    );
  };

  const renderFooterPanel = () => {
    if (!dailyLimitMinutes) return null;

    const isBlockedWithRoutines = isBlocked && state.screenTimeRoutines.length;
    const timeLimitTagCopy = isBlockedWithRoutines
      ? 'Limit reached: {{limit}}'
      : 'Daily time limit: {{limit}}';

    return (
      <Layout marginTop="32">
        <Tag
          variant="squared"
          text={t(timeLimitTagCopy, {
            limit: getDailyTimeLimitsFormattedTime(
              dailyLimitMinutes,
              extraTime
            ),
          })}
          iconType={IconType.hourglassHalf}
          iconProps={{ size: 'lg' }}
          size="regular"
          type={isBlocked ? GlobalType.marketing : GlobalType.secondary}
          invertColor={false}
        />
      </Layout>
    );
  };

  const getContent = () => {
    if (isFetchingInfo) {
      return <TotalScreenTimeLoading />;
    }

    if (hasError) {
      return <TotalScreenTimeError title={t('Total screen time')} />;
    }

    return (
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout
          mainaxis="row"
          flexGrow="1"
          height="100%"
          mainaxisAlignment="start"
        >
          {!isMobileView && (
            <div data-testid="par-total-screen-time__info-panel--top">
              {renderInfoPanel()}
            </div>
          )}
        </FlexLayout>
        <FlexLayout mainaxis="row" flexGrow="2" height="100%">
          <ScreenTimePieChart
            label={label}
            reportedDays={activeDateRange}
            dailyTimeLimits={dailyLimitMinutes}
            extraTime={extraTime}
            screenTimeDefaultRules={state.screenTimeDefaultRules}
            screenTimeRoutines={state.screenTimeRoutines}
          />
        </FlexLayout>
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          crossaxisAlignment="end"
          flexGrow="1"
          height="100%"
        >
          {isMobileView && (
            <div data-testid="par-total-screen-time__info-panel--bottom">
              {renderInfoPanel()}
            </div>
          )}
          {renderFooterPanel()}
        </FlexLayout>
      </FlexLayout>
    );
  };

  return (
    <Layout height="100%" width="100%" testId="par-total-screen-time">
      {getContent()}
    </Layout>
  );
};

export default TotalScreenTime;
