import * as React from 'react';
import {
  Dropdown,
  DropdownElementOrientation,
  DropdownOption,
  Icon,
  IconColor,
  IconSize,
  IconType,
  TransparentButton,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';
import { ActionsOptions } from '../types';
import { when } from '../../../../helpers/render';
import { t } from '../../../../lib/i18n';

interface UnifiedNowCardActionsProps {
  buttonIcon?: typeof IconType[keyof typeof IconType];
  buttonIconColor?: IconColor;
  options: ActionsOptions;
}

const UnifiedNowCardActions = ({
  buttonIcon = IconType.cog,
  buttonIconColor = IconColor.contrast,
  options,
}: UnifiedNowCardActionsProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => {
        const isMobile = screenSize === ScreenSize.Mobile;
        return (
          <Dropdown
            attachedMenuOriention={
              screenSize === ScreenSize.Tablet
                ? DropdownElementOrientation.left
                : DropdownElementOrientation.center
            }
            actionElement={
              <TransparentButton testId="unified-now-card-action--button">
                <Icon
                  type={buttonIcon}
                  size={IconSize.lg}
                  color={buttonIconColor}
                />
              </TransparentButton>
            }
            className="par-unified-now-card__actions-dropdown"
            classNameList={
              isMobile
                ? 'par-unified-now-card__actions-list--mobile'
                : 'par-unified-now-card__actions-list--desktop'
            }
            testId="unified-now-card-action"
            asModal={isMobile}
            header={when(isMobile, <strong>{t('Quick actions')}</strong>)}
            footer={when(isMobile, <strong>{t('Cancel')}</strong>)}
          >
            {options.map(({ text, value, icon, onClick }) => (
              <DropdownOption
                key={value}
                value={value}
                text={text}
                onClick={onClick}
                icon={<Icon type={icon} />}
              />
            ))}
          </Dropdown>
        );
      }}
    </RenderWhen>
  );
};

export default UnifiedNowCardActions;
