import * as moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { API_TIMESTAMP_FORMAT_WITHOUT_SEPARATORS } from '../../helpers/dates';

import type State from '../../store/state';
import type { CalendarRestrictionPause } from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';
import { getTimezone } from './account';

type MaybeMoment = moment.Moment | undefined;

type MaybeString = string | undefined;

export const toMomentApiFormatWithT = (
  date: string,
  timezone: string
): Moment => moment.tz(date, timezone);

export const toCalendarRestrictionDateStringFormat = (date: Moment): string =>
  date.format(API_TIMESTAMP_FORMAT_WITHOUT_SEPARATORS);

const getCalendarRestrictionPauseStart = (state: State): MaybeMoment => {
  const date: MaybeString = state.getIn([
    'calendarRestrictionPause',
    'items',
    '0',
    'rruleDtstart',
  ]);

  const timezone = getTimezone(state);
  return !date ? undefined : toMomentApiFormatWithT(date, timezone);
};

export const getCalendarRestrictionPauseEnd = (state: State): MaybeMoment => {
  const date: MaybeString = state.getIn([
    'calendarRestrictionPause',
    'items',
    '0',
    'rruleUntil',
  ]);
  const timezone = getTimezone(state);
  return !date ? undefined : toMomentApiFormatWithT(date, timezone);
};

export const isCalendarRestrictionPaused = (
  state: State,
  currentTime: Moment
): boolean => {
  const start = getCalendarRestrictionPauseStart(state);
  const end = getCalendarRestrictionPauseEnd(state);

  if (!start || !end) return false;

  return (
    currentTime.isBetween(start, end) ||
    currentTime.isSame(start) ||
    currentTime.isSame(end)
  );
};

export const getActiveCalendarRestriction = (
  state: State
): CalendarRestrictionPause | undefined => {
  return state.getIn(['calendarRestrictionPause', 'items', 0]);
};
