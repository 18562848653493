import { connect } from 'react-redux';
import {
  FamilyLocatorViews,
  trackClickedRefreshLocations,
} from '../../helpers/analytics';
import { fetchEvents } from '../../ducks/events';
import { ActivityEventFilters } from '../../businessLogic/timeline/types';
import { getLocationRulesEnabled, getProfile } from '../../selectors/index';
import { handleRefresh } from '../../actions/FamilyLocatorActions';
import { fetchProfile } from '../../ducks/profiles';
import KidLocationsHistoryHeader from '../../components/FamilyLocator/KidLocationsHistoryHeader';
import { goBackIfHistory } from '../../ducks/routing';
import { locationPushAndRetryWith } from '../../actions/location';

const mapStateToProps = (state, { params: { profileId } }) => {
  return {
    profile: getProfile(state, profileId),
    isLocationRuleEnable: getLocationRulesEnabled(state, profileId),
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onBack: () => dispatch(goBackIfHistory()),
  onClickRefreshLocations: (profileUid, stopRefresh) => {
    trackClickedRefreshLocations(FamilyLocatorViews.KidLocations);

    const LocationPushAnThenFetchProfileWithRetry = locationPushAndRetryWith(
      fetchProfile(profileId),
      {
        profileId,
        retryIntents: 3,
        retryTime: 1500,
      }
    );

    handleRefresh(
      dispatch,
      [
        dispatch(LocationPushAnThenFetchProfileWithRetry),
        dispatch(fetchEvents(profileUid, ActivityEventFilters.Locations)),
      ],
      stopRefresh
    );
  },
});

const KidLocationsHistoryHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KidLocationsHistoryHeader);

export default KidLocationsHistoryHeaderContainer;
