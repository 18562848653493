/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FlexLayout, Icon, IconColor, IconType, Link } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

export interface AddNewProps {
  className: string;
  onAddNew: () => void;
}

const AddNew: React.FC<AddNewProps> = ({
  onAddNew,
  className,
}: AddNewProps) => (
  <RenderWhen
    screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
  >
    {screenSize => (
      <FlexLayout
        mainaxis="row"
        marginBottom="8"
        marginTop="8"
        minWidth={screenSize === ScreenSize.Mobile ? '120' : '200'}
        minHeight="48"
        className={className}
      >
        <Link onClick={onAddNew}>
          <Icon
            className="AddNew__icon"
            type={IconType.plus}
            color={IconColor.secondary}
          />
          <strong>{t('Add new')}</strong>
        </Link>
      </FlexLayout>
    )}
  </RenderWhen>
);

export default AddNew;
