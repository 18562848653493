import { SummaryDateRanges } from '../../constants';
import type { ScreenTimeReport, ScreenTimeData } from './types';

export const isNotReported = (value: number) => value === 0;

export const isReported = (value: number) => !isNotReported(value);

export const containsRoutines = (report: Array<ScreenTimeReport>) =>
  report.length > 0;

export const doesNotContainRoutines = (report: Array<ScreenTimeReport>) =>
  !containsRoutines(report);

export const isForOneDay = (reportedDays: number) => reportedDays === 1;

export const isForMoreThanOneDays = (reportedDays: number) =>
  reportedDays > SummaryDateRanges.Today &&
  reportedDays < SummaryDateRanges.CustomPastDay;

export const isScreenTimeGreaterThanDailyTimeLimits = (
  screenTime: number,
  { dailyTimeLimits }: ScreenTimeData
) => {
  return screenTime >= dailyTimeLimits;
};

export const isScreenTimeGreaterThanDailyTimeLimitsWithExtraTime = (
  screenTime: number,
  { dailyTimeLimits, extraTime }: ScreenTimeData
) => {
  return screenTime >= dailyTimeLimits + extraTime;
};

export const isScreenTimeLessThanDailyTimeLimitsAndExtraTime = (
  screenTime: number,
  context: ScreenTimeData
) => screenTime <= context.dailyTimeLimits + context.extraTime;

export const consumeQuota = (report: ScreenTimeReport) => report.consumeQuota;
