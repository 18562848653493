import * as R from 'ramda';

/**
 * Store in a list of objects `objs` into the `fieldName` field of every one, a
 * list of objects `fieldObjs`.
 *
 * @param fieldName destiny field name
 * @param fieldObjs objects to save in the specific field
 * @param objs destiny objects
 * @returns list of objects with the fieldObj saved in fieldName
 */
export const mergeListObjListFields =
  <T, K extends keyof T>(fieldName: K) =>
  (fieldObjs: T[K][]) =>
  (objs: T[]): T[] =>
    R.zipWith(R.set(R.lensProp(fieldName as string)))(fieldObjs, objs);
