import * as React from 'react';
import { List } from 'immutable';
import { FlexLayout } from 'styleguide-react';
import { PlaceRecord } from '../../records';
import PlaceForm from './PlaceForm';

class AddPlace extends React.Component<
  {
    places: List<PlaceRecord>;
    platform: string;
    initialValues: PlaceRecord;
    isUsingImperialSystem: boolean;
    isEditForm: boolean;
    radius: number;
    minValue: number;
    maxValue: number;
    formatLabel: string;
    onAddressClick: () => any;
    onSaveClick: (isUsingImperialSystem: boolean) => {};
    keyboardShowHandler: () => any;
    keyboardHideHandler: () => any;
    switchToShowHalfScreenCallback: () => {};
  },
  {
    contentClass: string;
    keyboardVisible: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      contentClass: '',
      keyboardVisible: false,
    };
  }

  componentDidMount() {
    this.registerKeyboardHandlers();
  }

  componentWillUnmount() {
    const { keyboardHideHandler } = this.props;
    keyboardHideHandler();
    this.unregisterKeyboardHandlers();
  }

  registerKeyboardHandlers = () => {
    window.addEventListener(
      'native.keyboardshow',
      this.handleKeyboardShowEvent
    );
    window.addEventListener(
      'native.keyboardhide',
      this.handleKeyboardHideEvent
    );
  };

  unregisterKeyboardHandlers = () => {
    const { keyboardShowHandler } = this.props;

    window.removeEventListener('native.keyboardshow', keyboardShowHandler);
    window.removeEventListener(
      'native.keyboardhide',
      this.handleKeyboardHideEvent
    );
  };

  handleKeyboardHideEvent = () => {
    const { switchToShowHalfScreenCallback } = this.props;
    switchToShowHalfScreenCallback();
    this.keyboardHideHandler();
  };

  keyboardHideHandler(): any {
    const { keyboardHideHandler } = this.props;
    keyboardHideHandler();
    this.setState({ contentClass: '', keyboardVisible: false });
  }

  keyboardShowHandler(): any {
    const { platform, keyboardShowHandler } = this.props;
    keyboardShowHandler();
    this.setState({
      contentClass: `show-keyboard show-keyboard-${platform}`,
      keyboardVisible: true,
    });
  }

  handleKeyboardShowEvent = () => {
    this.keyboardShowHandler();
  };

  render() {
    const {
      isUsingImperialSystem,
      initialValues,
      isEditForm,
      places,
      minValue,
      maxValue,
      formatLabel,
      onAddressClick,
      onSaveClick,
    } = this.props;
    const { contentClass, keyboardVisible } = this.state;
    return (
      <FlexLayout
        className={`AddPlace ${contentClass}`}
        mainaxis="column"
        padding="16"
        paddingTop="24"
        paddingBottom="24"
        matchParentHeight
        flexGrow={keyboardVisible ? '0' : '1'}
      >
        <PlaceForm
          onSubmit={() => onSaveClick(isUsingImperialSystem)}
          initialValues={initialValues}
          isEditForm={isEditForm}
          places={places}
          minValue={minValue}
          maxValue={maxValue}
          formatLabel={formatLabel}
          keyboardVisible={keyboardVisible}
          onFocusInput={this.handleKeyboardShowEvent}
          onBlurInput={this.handleKeyboardHideEvent}
          onAddressClick={onAddressClick}
        />
      </FlexLayout>
    );
  }
}

export default AddPlace;
