import * as React from 'react';
import { Card } from 'styleguide-react';
import { TabletPageLayoutProps } from '../types';

const TabletCard: React.FC<TabletPageLayoutProps> = ({
  configuration,
  mainChildren,
  cardBannerChildren,
  beforePullRefreshChildren,
  afterPullRefreshChildren,
}) => (
  <Card
    fullHeight={configuration?.fullHeightCard}
    minHeight={configuration?.fullHeightCard ? undefined : '520'}
    fullWidth
    header={
      cardBannerChildren
        ? {
            banner: cardBannerChildren as React.ReactElement,
          }
        : undefined
    }
  >
    {beforePullRefreshChildren}
    {mainChildren}
    {afterPullRefreshChildren}
  </Card>
);

export default TabletCard;
