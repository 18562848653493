import * as React from 'react';
import { t } from '../../../lib/i18n';
import { List as ImmutableList } from 'immutable';
import { getNoVideosWatchedMsg } from './uiHelpers';
import {
  DetailsItemRecord,
  UrlDetailsEvent,
  VideoDetailsRecord,
} from '../../../records/urlDetails';
import { formatHumanFullDate } from '../../../helpers/dates';
import { SummaryDateRanges } from '../../../constants';
import { infiniteScroll } from '../../../helpers/hocs';
import {
  EmptyPlaceholder,
  FlexLayout,
  Layout,
  List,
  GroupHeader,
} from 'styleguide-react';
import UrlDetailsVideoListItem from '../../UrlDetails/UrlDetailsVideoListItem';

interface YoutubeVideoListProps {
  activeDateRange: SummaryDateRanges;
  videos: ImmutableList<VideoDetailsRecord>;
  onClickAction: (item: DetailsItemRecord) => any;
  onClickPage: (item: UrlDetailsEvent) => any;
}

const YoutubeVideoList: React.FunctionComponent<YoutubeVideoListProps> = ({
  videos,
  onClickPage,
  onClickAction,
  activeDateRange,
}) => (
  <FlexLayout mainaxis="column">
    <Layout marginBottom="24">
      <GroupHeader>{t('Watched videos')}</GroupHeader>
    </Layout>
    {!videos ||
      (videos.size === 0 && (
        <Layout marginTop="72">
          <EmptyPlaceholder text={getNoVideosWatchedMsg(activeDateRange)} />
        </Layout>
      ))}
    {videos && videos.size !== 0 && (
      <List
        allowCustomListItemStyle={UrlDetailsVideoListItem}
        listItemMarginBottom="24"
        testId="url-detail-video-list"
      >
        {videos.map(item => (
          <UrlDetailsVideoListItem
            video={item as VideoDetailsRecord}
            onClickPage={onClickPage}
            onClickAction={onClickAction}
            key={item.key}
            dateFormat={formatHumanFullDate}
          />
        ))}
      </List>
    )}
  </FlexLayout>
);

export default infiniteScroll<YoutubeVideoListProps>(YoutubeVideoList);
