import { getLocationPathname } from '../../selectors/routing';
import {
  getAccountMasterUid,
  getAccountUid,
  getSchoolLinkedStatus,
} from '../../selectors/stateSelectors/account';
import { getLicense } from '../../selectors/stateSelectors/license';
import State from '../../store/state';

export const setSentryTags = (state: State) => {
  const license = getLicense(state);
  return {
    fragment: getLocationPathname(state),
    licenseSubtype: license.subtype,
    licenseName: license.name,
    accountLinkedToSchool: getSchoolLinkedStatus(state),
  };
};

export const setSentryUserContext = (state: State) => {
  const license = getLicense(state);
  return {
    uid: getAccountUid(state),
    masterUid: getAccountMasterUid(state),
    license_type: license.type,
    license_name: license.name,
  };
};
