import * as React from 'react';

const SpeedDialChildButton: React.FunctionComponent<{
  className?: string;
  icon?: any;
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
}> = ({
  className = '',
  icon = '',
  onClick = () => {},
  label = null,
  disabled = false,
}) => {
  const handleOnClick = e => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <li onClick={handleOnClick}>
      <div
        className={`mfb-component__button--child ${className} ${
          disabled ? 'mfb-component__button--disabled' : ''
        }`}
      >
        <i className="mfb-component__child-icon">{icon}</i>
        <div className="SpeedDialChildButton__label">{label}</div>
      </div>
    </li>
  );
};

export default SpeedDialChildButton;
