/* eslint-disable local-rules/dirty-translation-key */
import * as React from 'react';
import { AlertBoxType, IconType } from 'styleguide-react';
import { TopBarCasesProps, TopbarAlertType } from '../types/Topbar.types';
import { t } from '../../../lib/i18n';
import TopbarBase from '../TopbarBase';
import { actionWrapper } from '../helpers';
import { trackMyFamilyTopbarAction } from '../../../helpers/analytics';

export { isVisibleTrialTopbar } from './predicate';

const trialTextsByDaysLeft = (days: number) => {
  const replacer = {
    days,
    1: actionWrapper,
  };

  if (days === 0) {
    return t('Your trial ends today – {1}Upgrade now!{/1}', replacer);
  }
  if (days === 1) {
    return t('Your trial ends tomorrow – {1}Upgrade now!{/1}', replacer);
  }
  return t('Your trial ends in {{days}} days – {1}Upgrade now!{/1}', replacer);
};

const TrialTopbar = ({ isMobile, licenseDaysLeft }: TopBarCasesProps) => {
  const props: TopbarAlertType = {
    rounded: !isMobile,
    centered: !isMobile,
    icon: IconType.rocketLaunch,
    type: AlertBoxType.booster,
    text: () => trialTextsByDaysLeft(licenseDaysLeft),
    goToLink: '/upgrade',
    trackAction: () => trackMyFamilyTopbarAction({ reason: 'trial' }),
  };

  return <TopbarBase {...props} />;
};

export default TrialTopbar;
