import { isBrowserPlatform } from '..';
import { trackAdjustEventIfApplies } from '../../lib/vendor/adjust';
import { trackAuthFirebaseEvent } from '../../lib/vendor/firebase';
import { AuthEventOptions } from './types/AuthEventOptions.types';

/**
 * This helper handles all the third party attribution tracking on PASA and PASI
 * For now we are sending authentication events to:
 *  - Adjust
 *  - Firebase
 */
export const trackAuthenticationEventsOnApps = (options: AuthEventOptions) => {
  if (isBrowserPlatform()) {
    return;
  }
  trackAdjustEventIfApplies(options);
  trackAuthFirebaseEvent(options);
};
