import * as moment from 'moment';
import { CreateRoutinePayload } from '../../../records/routines/types/Routine.types';
import { API_BIRTHDATE_FORMAT } from '../../../helpers/dates';
import { MultiStepDataContext } from '../../../components/Routines/routines.types';
import {
  routineIconRecordToPayload,
  routineTypeRecordToPayload,
} from '../../../records/routines/routine';
import { calculateDurationAsMinutes } from '../../../businessLogic/routines/routines';
import { getRoutineColorValue } from '../../../palettes/RoutineColor';
import { policyContextToPayload } from './policyContextToPayload';

export const routineContextToPayload = (
  data: MultiStepDataContext
): CreateRoutinePayload => {
  return {
    uid: data.uid,
    paused: data.paused,
    type: routineTypeRecordToPayload(data.block)!,
    name: data.style.name,
    description: data.style.description,
    icon: routineIconRecordToPayload(data.style.icon)!,
    color: getRoutineColorValue(data.style.color) || data.style.color,
    policy: policyContextToPayload(data),
    schedules: data.timeSlot.map(slot => ({
      uid: slot.uid,
      overrides: false,
      weekdays: slot.days,
      start_time: slot.from,
      duration_minutes: calculateDurationAsMinutes(slot.from, slot.to),
      from_date: moment().format(API_BIRTHDATE_FORMAT),
      to_date: undefined,
      routine_uid: slot.routineUid,
    })),
  };
};
