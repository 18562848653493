import api from '../api';
import { getClientCredentials } from '../helpers/globals';
import { setItem } from '../ducks/persistentStorage';

import { AuthorizationApplication } from '../constants';
import { BaseThunk } from '../store/state';

export type QApiConfig = {
  authorizationApplication: AuthorizationApplication;
  accessToken: string;
  refreshToken: string;
  parentDeviceId?: string;
};

const saveTokens = dispatch => (accessToken: string, refreshToken: string) => {
  dispatch(setItem('accessToken', accessToken));
  dispatch(setItem('refreshToken', refreshToken));
};
/**
 * These actions exist to wrap the setting of some nasty globals
 */
export const setApiGlobals = ({
  authorizationApplication,
  accessToken,
  refreshToken,
  parentDeviceId,
}: QApiConfig): BaseThunk<void> => {
  return dispatch => {
    api.setGlobals({
      tokens: { accessToken, refreshToken },
      clientCredentials: getClientCredentials(authorizationApplication),
      saveTokensCallback: saveTokens(dispatch),
    });
    if (parentDeviceId) {
      api.setParentDeviceId(parentDeviceId);
    }
  };
};
