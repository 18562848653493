import { connect } from 'react-redux';
import WrongAppInstalled from '../../components/PreOnboarding/WrongAppInstalled';
import { PageNames, track, Events } from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import { trackWrongAppEventAndGoBack } from '../../actions/StandaloneAppActions';
import { getDownloadLinkByBuildPlatform } from '../../helpers/downloads';
import { logout } from '../../sideEffects/logout';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapDispatchToProps = dispatch => ({
  onClickBack: () => dispatch(trackWrongAppEventAndGoBack()),
  onClickGetKidsApp: () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedWrongAppButton, { button: 'get_app' });
    dispatch(
      navigate({
        type: 'external',
        src: getDownloadLinkByBuildPlatform(),
      })
    );
    dispatch(logout());
  },
});

const WrongAppInstalledContainer = connect(
  null,
  mapDispatchToProps
)(
  trackablePage(
    WrongAppInstalled,
    PageNames.ParentsAppStandaloneWrongAppInstalled
  )
);

WrongAppInstalledContainer.load = () => dispatch => {
  const backButtonHandler = () => {
    dispatch(trackWrongAppEventAndGoBack());
    document.removeEventListener('backbutton', backButtonHandler);
  };
  document.addEventListener('backbutton', backButtonHandler, false);
};

export default WrongAppInstalledContainer;
