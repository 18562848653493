import * as React from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  InstructionBlock,
  FlexLayout,
  Button,
  ButtonSize,
  FreeText,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { Dispatch } from '../../store/state';
import { pushRelativeLocation } from '../../actions/Navigation';

const CloseAdditionalParentAccount = ({
  onLeaveAccount,
}: {
  onLeaveAccount: () => void;
}) => (
  <Layout testId="settings-close-additional-account">
    <FreeText
      testId="settings-close-additional-account__title"
      fontSize="16px"
      color="gray"
    >
      <strong>{t('Close account')}</strong>
    </FreeText>
    <InstructionBlock testId="settings-close-additional-account__subtitle">
      {t('Permanently delete your {{shortName}} account.')}
      <br />
    </InstructionBlock>

    <FlexLayout mainaxis="column" marginTop="16">
      <Button
        buttonType={ButtonType.secondary}
        size={ButtonSize.medium}
        onClick={onLeaveAccount}
        testId="leaveAccount"
      >
        {t('Close my account')}
      </Button>
    </FlexLayout>
  </Layout>
);

export default connect(null, (dispatch: Dispatch) => ({
  onLeaveAccount: () => {
    dispatch(pushRelativeLocation(`modal/CloseAdditionalParentAccountModal`));
  },
}))(CloseAdditionalParentAccount);
