import { parseQuery } from '../../helpers/query';
import ft, { DISABLE_BRAZE, DISABLE_SEGMENT_TRACKING } from '../../lib/ft';

export const isBrazeAvailable = () => {
  if (ft.active(DISABLE_BRAZE)) {
    return false;
  }
  return !isFaradayGateActivated();
};

export const isSegmentAvailable = () => {
  if (ft.active(DISABLE_SEGMENT_TRACKING)) {
    return false;
  }
  return !isFaradayGateActivated();
};

export const isSentryAvailable = () => !isFaradayGateActivated();

export const isLoggedFromAdmin = () => isFaradayGateActivated();

/**
 * faradayCage query parameter activated means that we don't want to use any third-party
 * library to track user activity. This is mainly used when a customer support agent accesses
 * to the dashboard through the admin panel
 */
const isFaradayGateActivated = () => {
  const query = parseQuery(window.location.search);
  return query.faradayCage === 'activated';
};
