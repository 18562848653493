import * as React from 'react';
import { Button, ButtonSize, ModalInfo, Typography } from 'styleguide-react';
import type { DelegationEnabledModalProps } from '../../containers/Modal/DelegationEnabledModalContainer/DelegationEnabledModalContainer';
import { t } from '../../lib/i18n';
import shieldWithRocketIcon from '../../assets/base/images/ShieldWithRocket.png';

const DelegationEnabledModal = ({
  onClickClose,
}: DelegationEnabledModalProps) => {
  return (
    <ModalInfo
      size="medium"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={onClickClose}
          size={ButtonSize.medium}
          block
          testId="Delegation-enabled-modal__confirm-button"
        >
          {t('Get started')}
        </Button>,
      ]}
      testId="delegation-enabled-modal"
      title={t('You can now manage school-issued devices')}
      header={{
        illustration: (
          <img
            src={shieldWithRocketIcon}
            width="130px"
            alt="Enabled Delegation"
          />
        ),
      }}
      alertBoxText={t(
        'Add a personal device to use advanced features like location and message monitoring.'
      )}
    >
      <Typography type="body2" align="center" renderAs="p">
        {t(
          'Click web filtering, time limits, and routines to boost healthy online habits outside of school hours.'
        )}
      </Typography>
    </ModalInfo>
  );
};

export default DelegationEnabledModal;
