import { OrderedSet } from 'immutable';
import * as React from 'react';

import { classNames, timeoutPromise, isAndroidPlatform } from '../../helpers';
import { getRemoteIconUrl } from '../../helpers/avatarImageMaps';
import CircleButton from '../Button/CircleButton';
import DirectionsButton from '../Button/DirectionsButton';
import Icon from '../Icons/Icon';
import * as PlacesIcon from '../../assets/base/images/icons/icon-places.svg';
import * as DirectionsIcon from '../../assets/base/images/icons/icon-directions.svg';
import { FamilyLocatorScreen } from '../../containers/FamilyLocator/types';
import * as qinit from '../../qinit';
import { isAddPlaceScreen, isPinMarkerScreen } from '../../helpers/dynmap';
import flags from '../../sideEffects/flags';
import {
  Marker,
  MarkerPin,
  MarkerAvatar,
  isMarkerAvatar,
} from '../../records/marker';
import { getAccuracyInfo } from '../FamilyLocator/ProfileLocationStatusHelper';

const PIN_URL = `${qinit.shared.common.s3.static.url}/img/icons/map-pin.png`;
const MARKER_VERTICAL_OFFSET = 0.00015;
const MARKER_MINIMUM_ACCURACY = 30;
const FILL_COLOR = 'rgba(42, 171, 203, 0.18)';
const STROKE_COLOR = 'rgba(42, 171, 203, 0)';

const ANIMATION_DURATION = 300;
const ICON_SIZE = 80;
const PIN_SIZE = 40;
const MAX_ZOOM = 16;
const MAP_PREFERENCES = {
  zoom: {
    maxZoom: MAX_ZOOM,
  },
  padding: {
    top: ICON_SIZE + 10,
    left: ICON_SIZE / 2 + 10,
    right: ICON_SIZE / 2 + 10,
  },
};
const MAP_CONTROLS = {
  mapToolbar: false,
};

class DynamicMap extends React.Component<
  {
    markers: OrderedSet<Marker>;
    fullMap?: boolean;
    hideMap: boolean | false;
    markersCoordinatesString: any[];
    showPlaceholder: boolean;
    isDirectionIconEnabled: boolean;
    activeScreen: FamilyLocatorScreen;
    radius: number | undefined;
    shouldRender: boolean;
    isUsingImperialSystem: boolean;
    onReady: () => any;
    onClickPlaces: () => {};
    onClickDirections: (location: Marker) => {};
    didUpdateCallback: () => any;
  },
  {
    mapLoading: boolean;
    selectedMarker?: Marker;
    mapWritesLocked: boolean;
    nativeObjects: any;
    queuedMarkers: any;
    hasQueuedMarkers: boolean;
    activeScreen: FamilyLocatorScreen;
  }
> {
  dynMap: any;

  dynMapEl: any;

  circle: any;

  constructor(props) {
    super(props);
    this.dynMapEl = React.createRef();
    this.state = {
      mapLoading: true,
      selectedMarker: undefined,
      mapWritesLocked: false,
      nativeObjects: {},
      queuedMarkers: OrderedSet(),
      hasQueuedMarkers: false,
      activeScreen: FamilyLocatorScreen.ProfilesList,
    };
  }

  isMountedMapsPlugin() {
    return global.plugin && global.plugin.google !== undefined;
  }

  componentDidMount() {
    if (!this.isMountedMapsPlugin()) {
      return;
    }

    this.initMap();
  }

  componentWillUnmount() {
    if (this.dynMap !== undefined) {
      this.dynMap.remove();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { radius } = this.props;

    if (!nextProps.shouldRender || !this.isMountedMapsPlugin()) {
      return;
    }

    const relevantDataChanged = () => {
      const watchedProperties = [
        'markersCoordinatesString',
        'activeScreen',
        'showPlaceholder',
        'hideMap',
        'fullMap',
        'shouldRender',
      ];

      return (
        // eslint-disable-next-line react/destructuring-assignment
        watchedProperties.find(prop => nextProps[prop] !== this.props[prop]) !==
        undefined
      );
    };

    if (relevantDataChanged()) {
      this.updateMarkers(nextProps.markers, nextProps.activeScreen);
    }

    if (
      isAddPlaceScreen(nextProps.activeScreen) &&
      radius !== nextProps.radius
    ) {
      this.updateCircle(nextProps.radius);
    }

    if (nextProps.activeScreen === FamilyLocatorScreen.AddPlace) {
      this.setInteractionsWithMap(false);
    } else {
      this.setInteractionsWithMap(true);
    }
  }

  setInteractionsWithMap(newStateOfInteractionWithMap: boolean) {
    this.dynMap.setAllGesturesEnabled(newStateOfInteractionWithMap);
    this.dynMap.setClickable(newStateOfInteractionWithMap);
  }

  enableMarkerEventListeners = (marker, markerData) => {
    marker.on(global.plugin.google.maps.event.MARKER_CLICK, () => {
      this.updateDirectionsButtonMarker(markerData);
    });

    marker.on(global.plugin.google.maps.event.INFO_CLOSE, () => {
      const { selectedMarker } = this.state;
      const { activeScreen } = this.props;
      if (selectedMarker) {
        if (
          activeScreen !== FamilyLocatorScreen.KidTimeline &&
          markerData.id === selectedMarker.id
        ) {
          this.disableDirectionsButton();
        }
      }
    });
  };

  initMap() {
    if (this.dynMapEl.current === undefined) {
      return;
    }

    this.dynMap = global.plugin.google.maps.Map.getMap(this.dynMapEl.current, {
      preferences: MAP_PREFERENCES,
      controls: MAP_CONTROLS,
    });

    this.dynMap.one(global.plugin.google.maps.event.MAP_READY, () => {
      const { markers, shouldRender, activeScreen } = this.props;

      this.setState({ mapLoading: false });
      if (shouldRender) {
        this.updateMarkers(markers, activeScreen);
      }
    });
  }

  centerMap(markers: OrderedSet<Marker>) {
    if (!markers.size) {
      return Promise.resolve();
    }

    const bounds = markers
      .toList()
      .map(marker => ({
        lat: marker.latitude,
        lng: marker.longitude,
      }))
      .toJS();

    if (isAndroidPlatform()) {
      return new Promise(resolve =>
        this.dynMap.animateCamera(
          {
            target: bounds,
            duration: ANIMATION_DURATION,
          },
          () => resolve()
        )
      );
    }
    return new Promise(resolve =>
      this.dynMap.moveCamera(
        {
          target: bounds,
          duration: ANIMATION_DURATION,
        },
        () => resolve()
      )
    );
  }

  updateDirectionsButtonMarker = markerData =>
    this.setState({ selectedMarker: markerData });

  disableDirectionsButton = () => this.setState({ selectedMarker: undefined });

  lockMapWrites = (value = true) => this.setState({ mapWritesLocked: value });

  hasQueuedMarkers = () => {
    const { hasQueuedMarkers } = this.state;
    return hasQueuedMarkers;
  };

  enqueueMarkers = (queuedMarkers, activeScreen) =>
    this.setState({
      queuedMarkers,
      activeScreen,
      hasQueuedMarkers: true,
    });

  emptyMarkersQueue = () =>
    this.setState({
      queuedMarkers: OrderedSet(),
      activeScreen: FamilyLocatorScreen.ProfilesList,
      hasQueuedMarkers: false,
    });

  pushNativeObject = (mapObject: any): any =>
    this.setState(prevState => ({
      nativeObjects: {
        ...prevState.nativeObjects,
        [mapObject.hashCode]: mapObject,
      },
    }));

  clearNativeObjects = (): any => {
    const { nativeObjects } = this.state;
    return Promise.all(
      Object.keys(nativeObjects).map(key => {
        nativeObjects[key].remove(() => {
          return Promise.resolve();
        });
      })
    ).then(() => {
      this.setState({ nativeObjects: {} });
      return Promise.resolve();
    });
  };

  resetMap = () => {
    this.lockMapWrites();
    this.disableDirectionsButton();
    return this.clearNativeObjects().then(() => Promise.resolve());
  };

  updateMarkers(markers, activeScreen) {
    const { mapWritesLocked } = this.state;

    if (mapWritesLocked) {
      return this.enqueueMarkers(markers, activeScreen);
    }

    const checkMarkersQueue = () => {
      // eslint-disable-next-line react/destructuring-assignment
      const markers = this.state.queuedMarkers;
      const { activeScreen } = this.state;
      this.emptyMarkersQueue();
      this._updateMarkers(markers, activeScreen);

      if (!this.hasQueuedMarkers()) {
        return Promise.resolve();
      }

      return Promise.resolve().then(() => checkMarkersQueue());
    };

    this._updateMarkers(markers, activeScreen)
      .then(() => this.hasQueuedMarkers() && checkMarkersQueue())
      .then(() => this.lockMapWrites(false))
      .then(() => this.postUpdateMarkers());
  }

  _updateMarkers = (markers, activeScreen) =>
    this.resetMap()
      .then(() => this.centerMap(markers))
      .then(() => this.addElements(markers, activeScreen));

  postUpdateMarkers = () =>
    timeoutPromise(ANIMATION_DURATION * 2, () => {
      const { onReady, didUpdateCallback } = this.props;
      onReady();
      didUpdateCallback();
    });

  addMarker = (markerData, showPin, activeScreen): any =>
    new Promise(resolve => {
      const { isUsingImperialSystem } = this.props;
      this.dynMap.addMarker(
        {
          position: { lat: markerData.latitude, lng: markerData.longitude },
          title: `${markerData.title} - ${getAccuracyInfo(
            markerData,
            isUsingImperialSystem
          )}`,
          icon: {
            url: isMarkerAvatar(markerData)
              ? getRemoteIconUrl(markerData)
              : PIN_URL,
            size: {
              width: showPin ? PIN_SIZE : ICON_SIZE,
              height: showPin ? PIN_SIZE : ICON_SIZE,
            },
          },
          snippet: `${markerData.snippet}`,
        },
        markerObj => {
          this.pushNativeObject(markerObj);
          if (activeScreen === FamilyLocatorScreen.ProfilesList) {
            this.enableMarkerEventListeners(markerObj, markerData);
          }
          if (activeScreen === FamilyLocatorScreen.KidTimeline) {
            this.updateDirectionsButtonMarker(markerData);
          }
          resolve();
        }
      );
    });

  addCircle = ({
    latitude,
    longitude,
    radius,
    verticalOffset = MARKER_VERTICAL_OFFSET,
  }: {
    latitude: number;
    longitude: number;
    radius: number;
    verticalOffset?: number;
  }) =>
    new Promise(resolve => {
      this.dynMap.addCircle(
        {
          center: {
            lat: latitude + verticalOffset,
            lng: longitude,
          },
          radius,
          fillColor: FILL_COLOR,
          strokeColor: STROKE_COLOR,
        },
        circleObj => {
          this.pushNativeObject(circleObj);
          resolve(circleObj);
        }
      );
    });

  addElements = (markers, activeScreen) => {
    return Promise.all(
      markers.map((markerData: MarkerPin | MarkerAvatar) =>
        this.addElement(
          markerData,
          isPinMarkerScreen(activeScreen),
          activeScreen
        )
      )
    )
      .then(values => Promise.resolve(values))
      .then(() => Promise.resolve());
  };

  addElement = (markerData, showPin, activeScreen) =>
    Promise.all([
      new Promise(resolve => {
        this.addMarker(markerData, showPin, activeScreen).then(() => resolve());
      }),

      new Promise(resolve => {
        const { radius } = this.props;

        if (!isAddPlaceScreen(activeScreen)) {
          return resolve();
        }

        this.addCircle({
          latitude: markerData.latitude,
          longitude: markerData.longitude,
          radius: radius!, // !isAddPlaceScreen(activeScreen) gurantees that radius is set
          verticalOffset: showPin ? MARKER_VERTICAL_OFFSET : 0,
        }).then(circle => {
          this.circle = circle;
          resolve();
        });
      }),

      new Promise(resolve => {
        if (markerData.accuracy <= MARKER_MINIMUM_ACCURACY) {
          return resolve();
        }

        this.addCircle({
          latitude: markerData.latitude,
          longitude: markerData.longitude,
          radius: markerData.accuracy,
        }).then(() => {
          resolve();
        });
      }),
    ]).then(() => Promise.resolve());

  updateCircle = radius => this.circle && this.circle.setRadius(radius);

  getDynamicMapClassname = (fullMap, hideMap) => {
    if (fullMap) return 'DynamicMap--fullMap';
    if (hideMap) return 'DynamicMap--hideMap';
    return '';
  };

  render() {
    const {
      markers,
      fullMap,
      activeScreen,
      hideMap,
      showPlaceholder,
      onClickPlaces,
      onClickDirections,
    } = this.props;
    const { mapLoading, selectedMarker } = this.state;
    const showPlacesButton =
      flags.geofencing.isEnabled() &&
      (activeScreen === FamilyLocatorScreen.ProfilesList ||
        activeScreen === FamilyLocatorScreen.KidTimeline);

    return (
      <div
        id="dynamic-map-parent"
        className={classNames(
          'DynamicMap',
          this.getDynamicMapClassname(fullMap, hideMap)
        )}
      >
        <div
          id="dynamic-map"
          className="DynamicMap__inner"
          ref={this.dynMapEl}
        />
        <div
          className={classNames(
            'DynamicMap__placeholder',
            showPlaceholder || mapLoading
              ? 'DynamicMap__placeholder--visible'
              : ''
          )}
        />
        {showPlacesButton && (
          <CircleButton className="DynamicMap__button" onClick={onClickPlaces}>
            <Icon className="DynamicMap__icon" path={PlacesIcon} />
          </CircleButton>
        )}

        <DirectionsButton
          className={classNames(
            'DynamicMap__button',
            'DynamicMap__directionsButton',
            selectedMarker !== undefined ||
              (activeScreen === FamilyLocatorScreen.KidTimeline &&
                markers.size > 0)
              ? 'DynamicMap__directionsButton--visible'
              : ''
          )}
          marker={selectedMarker}
          onClick={onClickDirections}
        >
          <Icon className="DynamicMap__icon" path={DirectionsIcon} />
        </DirectionsButton>
      </div>
    );
  }
}

export default DynamicMap;
