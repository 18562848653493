import { connect } from 'react-redux';
import { handleRefresh } from '../../actions/FamilyLocatorActions';
import { fetchProfiles } from '../../ducks/profiles';
import {
  trackClickedRefreshLocations,
  FamilyLocatorViews,
} from '../../helpers/analytics';
import FamilyLocatorHeader from '../../components/FamilyLocator/FamilyLocatorHeader';
import { goBackIfHistory } from '../../ducks/routing';

const mapDispatchToProps = dispatch => ({
  onBack: () => dispatch(goBackIfHistory()),
  onClickRefreshLastLocation: stopRefresh => {
    trackClickedRefreshLocations(FamilyLocatorViews.FamilyLocator);
    handleRefresh(dispatch, [dispatch(fetchProfiles())], stopRefresh);
  },
});

const FamilyLocatorHeaderContainer = connect(
  null,
  mapDispatchToProps
)(FamilyLocatorHeader);

export default FamilyLocatorHeaderContainer;
