import * as React from 'react';
import {
  List as GenericList,
  ListTitle,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';
import { SettingsToggleNames } from '../../constants';
import { t } from '../../lib/i18n';
import { WithUpgradeTag } from '../UpgradeTag/UpgradeTag';

export interface AppRulesNotificationsProps {
  isAlertNewApps: boolean;
  isSettingTogglePremium: (setting: SettingsToggleNames) => boolean;
  onToggleAppRulesSetting: (
    target: SettingsToggleNames,
    toggleOn: boolean
  ) => void;
}

const getListTitle = (featureBlocked?: boolean) => {
  return (
    <WithUpgradeTag when={featureBlocked}>
      <ListTitle renderAs="span">{t('Alert me')}</ListTitle>
    </WithUpgradeTag>
  );
};

const AppRulesNotifications: React.FunctionComponent<
  AppRulesNotificationsProps
> = ({ isAlertNewApps, isSettingTogglePremium, onToggleAppRulesSetting }) => (
  <GenericList
    listItemPaddingBottom="8"
    listItemPaddingTop="8"
    testId="AppRulesSettingsList"
  >
    <RegularStyleListItem
      title={getListTitle(
        isSettingTogglePremium(SettingsToggleNames.IsAlertNewApps)
      )}
      upperSubtitle={t(
        'Receive an alert when your child uses an app for the first time.'
      )}
      key={SettingsToggleNames.IsAlertNewApps}
      testId={SettingsToggleNames.IsAlertNewApps}
      actionElement={
        <Switch
          checked={isAlertNewApps}
          id={SettingsToggleNames.IsAlertNewApps}
          onChange={ev => {
            onToggleAppRulesSetting(
              SettingsToggleNames.IsAlertNewApps,
              (ev.target as HTMLInputElement).checked
            );
          }}
        />
      }
    />
  </GenericList>
);

export default AppRulesNotifications;
