import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import ProfileSummarySelector from '../../../ProfileHeader/ProfileSummarySelector';
import PageTitleLayout, { PageTitleLayoutProps } from '../../PageTitleLayout';
import { TabletPageLayoutProps } from '../types';

const TabletPageTitle: React.FC<
  PageTitleLayoutProps & TabletPageLayoutProps
> = ({
  title,
  actions,
  configuration,
  customPageTitle,
  topbarChildren,
  beforePageTitle,
  afterPageTitle,
}: PageTitleLayoutProps & TabletPageLayoutProps) => (
  <React.Fragment>
    <FlexLayout
      mainaxis="column"
      marginBottom="8"
      backgroundColor="white"
      className="par-page-layout__header"
      testId="TabletPageLayout_PageTitle"
    >
      {beforePageTitle && (
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          padding="8"
          marginBottom="16"
          marginTop="56"
          testId="tablet_before_page_title"
        >
          {beforePageTitle}
        </FlexLayout>
      )}
      <PageTitleLayout
        showProfile={configuration?.showProfile}
        actions={actions}
        title={
          configuration?.title !== undefined ? configuration?.title : title
        }
        topBar={topbarChildren as React.ReactElement}
        customPageTitle={customPageTitle}
      />
      {configuration?.showProfileSummarySelector && <ProfileSummarySelector />}
    </FlexLayout>
    {afterPageTitle && (
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        margin="16"
        testId="tablet_after_page_title"
      >
        {afterPageTitle}
      </FlexLayout>
    )}
  </React.Fragment>
);

export default TabletPageTitle;
