import { lift, range } from 'ramda';
import {
  AMPMTimeToMilitaryFormatWithHoursAndMinutes,
  militaryTimeToAMPMFormatWithHoursAndMinutes,
} from '../../helpers/dates';
import { pad } from '../../helpers/string';
import { getBuildPlatform } from '../../helpers/globals';
import { BuildPlatform } from '../../constants';
import { TimePickerOption } from './types/TimePicker.types';

const isMobileBuildPlatform = () =>
  getBuildPlatform() !== BuildPlatform.browser;

const toClockFormat = (num: number) => pad(num, 2);

const getFormattedTime = (isMilitaryTime: boolean, time: string) => {
  return isMilitaryTime
    ? time
    : militaryTimeToAMPMFormatWithHoursAndMinutes(time);
};

const getNormalisedTime = (isMilitaryTime: boolean, time: string) => {
  return isMilitaryTime
    ? time
    : AMPMTimeToMilitaryFormatWithHoursAndMinutes(time);
};

const militaryTimeRange = (
  isMilitaryTime: boolean
): (<T>(...params: T[]) => TimePickerOption[]) =>
  lift((hours: number, minutes: number) => {
    const formattedHours = toClockFormat(hours);
    const formattedMinutes = toClockFormat(minutes);
    const time = `${formattedHours}:${formattedMinutes}`;
    return { text: getFormattedTime(isMilitaryTime, time), value: time };
  });

const createTimeSlotsTimeRange = (
  isMilitaryTime = false,
  minuteInterval = 1
) => {
  const hours = range(0, 24);
  const minutes = range(0, 60).filter((_, i) => i % minuteInterval === 0);
  return militaryTimeRange(isMilitaryTime)(hours, minutes);
};

/**
 * Constructs Date instance from 2000-01-01THH:mm:00 string from hour and minutes params.
 * We are only interested in the time, so date is irrelevant.
 * @returns Date
 */
const getDateWithHourAndMinutes = (
  hours: string | number,
  minutes: string | number
) =>
  new Date(`2000-01-01T${pad(Number(hours), 2)}:${pad(Number(minutes), 2)}:00`);

/**
 * Get HH:mm from Date instance
 * @param date input date
 * @returns HH:mm
 */
const getTimeValue = (date: Date) =>
  `${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}`;

// from 0 -> 24 hours and 0 -> 59 for minutes
const timeValidator24hRegEx = /^([0-1][0-9]|2[0-3]):([0-5]\d)$/;

// from 1 to 12 for hours and 0 -> 59 for minutes
const timeValidatorAMPMRegEx = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)$/i;

/**
 * Validate an input (case insensitive) if it has a the correct time format:
 * - HH:mm (24h) -> 01:00, 23:00
 * - HH:mm am|pm -> 1:00 am, 01:00 am, 12:00 pm
 *
 * @param time input time string (HH:mm | hh:mm aa)
 * @returns weather the input is a valid input string
 */
const isInputTimeValid = (isMilitaryTime: boolean, time: string) =>
  (isMilitaryTime ? timeValidator24hRegEx : timeValidatorAMPMRegEx).test(time);

// exports
const timePickerHelpers = {
  isMobileBuildPlatform,
  getFormattedTime,
  getNormalisedTime,
  createTimeSlotsTimeRange,
  getDateWithHourAndMinutes,
  getTimeValue,
  isInputTimeValid,
};

export default timePickerHelpers;
