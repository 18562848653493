import { useRef, useState, useEffect } from 'react';
import * as R from 'ramda';

const MINUTES_PER_HOUR = 60;
const DIVISION_COUNT = 60;
const LARGE_TICK_INTERVAL = 5;
const DEGREES_FACTOR = 360 / DIVISION_COUNT;

/* eslint-disable */
// hack the round slider to show minute ticks http://disq.us/p/1gk0ji0
$.fn.roundSlider.prototype._invertRange = true;

$.fn.roundSlider.prototype.defaults.create = function () {
  R.range(0, DIVISION_COUNT).forEach(index => {
    this._addSeperator(DEGREES_FACTOR * index, 'rs-custom')
      .children()
      .addClass(
        index % LARGE_TICK_INTERVAL === 0 ? 'rs-number-large' : 'rs-number'
      );
  });
};

$.fn.roundSlider.prototype._updateTooltipPos = () => undefined;

const getStepInHours = (minutes: number): number =>
  1 / (MINUTES_PER_HOUR / minutes);

const getSliderOptions = ({
  step,
  disabled,
  value,
  radius,
}: {
  step: number;
  disabled: boolean;
  value: number;
  radius: number;
}) => ({
  sliderType: 'min-range',
  startAngle: 90,
  editableTooltip: false,
  handleShape: 'dot',
  handleSize: '+15',
  radius,
  width: 24,
  min: 0,
  max: 24,
  step: getStepInHours(step),
  value,
  tooltipFormat: () => ({}), // build-in value renderer (we use vdom instead),
  disabled,
});

const useSlider = ({ value, step, disabled, onChange }) => {
  const sliderRef = useRef(null);
  const [valueState, setValue] = useState<number>(value);

  useEffect(() => {
    const slider = $(sliderRef.current);

    slider
      .roundSlider(
        getSliderOptions({
          value: parseFloat(String(valueState)),
          radius: Math.floor(slider.width() / 2),
          step,
          disabled,
        })
      )
      .on('drag', event => setValue(event.value))
      .on('change', event => onChange(event.value * 60));

      return () => {
        slider.off('change');
        slider.off('drag');
      }
  // Very important, the onChange is a partially applied function, therefore the slider must be modified every time this function changes.
  }, [onChange]);

  useEffect(() => {
    const slider = $(sliderRef.current);
    const parsedValue = parseFloat(String(value));
    slider.roundSlider('setValue', parsedValue);
    slider.roundSlider(disabled ? 'disable' : 'enable');
    setValue(parsedValue);
  }, [value, disabled]);

  return { sliderRef, valueState };
};

export default useSlider;
