import * as Moment from 'moment-timezone';
import { createSelector } from 'reselect';
import State from '../store/state';
import { LastBiometricValidTimeInSeconds, LockState } from '../constants';
import { isBrowserPlatform } from '../helpers';

type LockStatus = {
  state: LockState;
  timestamp: number;
};

const getLockStatus = (state: State): LockStatus => {
  const status = state.get('persistentStorage').get('lockState');

  return status
    ? JSON.parse(status)
    : { state: 'disabled', timestamp: Date.now() };
};

export const getLockStatusState = createSelector(
  getLockStatus,
  ({ state }) => state
);

export const getLockStateLastUpdate = createSelector(
  getLockStatus,
  ({ timestamp }) => Moment(new Date(timestamp))
);

export const isLockScreenEnabled = createSelector(
  getLockStatusState,
  state => state !== 'disabled' && !isBrowserPlatform()
);

export const isLocked = createSelector(
  getLockStatusState,
  state => state === 'locked'
);

export const hasLockScreenTimedOut = createSelector(
  getLockStateLastUpdate,
  lastUpdate => {
    const currentDate = Moment(new Date());
    return (
      currentDate.diff(lastUpdate, 'seconds') > LastBiometricValidTimeInSeconds
    );
  }
);

export const shouldBeLocked = createSelector(
  isLockScreenEnabled,
  isLocked,
  (lockScreenEnabled, locked) => lockScreenEnabled && locked
);

export const shouldBeLockedOnTimeout = createSelector(
  isLockScreenEnabled,
  hasLockScreenTimedOut,
  isLocked,
  (lockScreenEnabled, lockScreenTimedOut, locked) =>
    // - Check if lock timeout limit has been reached.
    // - We also check if device is already locked becasue when user pauses the app the timer is updated to 0.
    lockScreenEnabled && (lockScreenTimedOut || locked)
);
