import { connect } from 'react-redux';
import * as R from 'ramda';
import RestrictedTimes from '../../../components/TimeLimits/RestrictedTimes';
import {
  getProfileRules,
  isMultiDevices,
  getDaytimeCollapsed,
} from '../../../selectors/profileRules';
import { getMultiDeviceTimeRestrictions } from '../../../selectors/summary';
import { ProfileRulesRecord } from '../../../records';
import {
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
  delayedModifyProfileRules,
  toggleDayTimeCollapse,
  collapseAllDayTimes,
  delayedModifyTimeLimits,
  reverseTimeLimitsUpdate,
} from '../../../ducks/profileRules';
import { showErrorToast } from '../../AccountSettings/helpers';
import {
  DAYTIME_MORNING,
  DAYTIME_AFTERNOON,
  DAYTIME_NIGHT,
  UPDATE_PROFILE_RULES_DELAY,
} from '../../../constants';

import {
  TimeLimitsRuleNames,
  trackTimeLimitsRule,
  trackTimeRulesChange,
} from '../../../helpers/analytics';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';

const mapStateToProps = (state, { params: { profileId, deviceId } }) => ({
  profileId,
  deviceId,
  timeSettings: getTimeSettings(state, profileId, deviceId),
  isMorningCollapsed: getDaytimeCollapsed(state, DAYTIME_MORNING),
  isAfternoonCollapsed: getDaytimeCollapsed(state, DAYTIME_AFTERNOON),
  isNightCollapsed: getDaytimeCollapsed(state, DAYTIME_NIGHT),
});

const mapDispatchToProps = (dispatch, { params: { profileId, deviceId } }) => {
  const navigate = getMultiPlatformNavigation();

  return {
    onToggle: toggleOn =>
      dispatch(onToggleGlobal(toggleOn, profileId, deviceId)),
    onCellTouchEnd: (toggleTarget, weekday, hour) => {
      dispatch(
        onCellTouchEnd(profileId, deviceId, toggleTarget, weekday, hour)
      );
    },
    onClickSettings: () => {
      dispatch(
        navigate({
          type: 'inner',
          src: `profiles/${profileId}/rules/dailyTimeLimit/devices/${deviceId}/timeSettings`,
        })
      );
    },
    onToggleDayTime: (daytime, isCollapsed) => {
      dispatch(toggleDayTimeCollapse(daytime, isCollapsed));
    },
    onChangeDevice: device => {
      const navigate = getMultiPlatformNavigation();

      return dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${profileId}/rules/restrictedTimes/devices/${device.id}`,
        })
      );
    },
  };
};

const getTimeSettings = (state, profileId, deviceId) =>
  isMultiDevices(state, profileId)
    ? getMultiDeviceTimeRestrictions(state, deviceId) ||
      ProfileRulesRecord().timeRestrictions
    : (getProfileRules(state, profileId) || ProfileRulesRecord())
        .timeRestrictions;

const onToggleGlobal =
  (toggleOn, profileId, deviceId) => (dispatch, getState) => {
    // Changes in global toggle must be reported inmediately after user interaction.
    trackTimeLimitsRule(
      toggleOn
        ? TimeLimitsRuleNames.ENABLE_SCHEDULE
        : TimeLimitsRuleNames.DISABLE_SCHEDULE
    );

    if (!toggleOn) {
      dispatch(collapseAllDayTimes());
    }
    if (isMultiDevices(getState(), profileId)) {
      return Promise.resolve(
        getMultiDeviceTimeRestrictions(getState(), deviceId)
      )
        .then(timeLimits => timeLimits.set('isDailyLimit', toggleOn))
        .then(timeLimits =>
          dispatch(
            delayedModifyTimeLimits(
              [timeLimits, profileId, deviceId],
              UPDATE_PROFILE_RULES_DELAY
            )
          )
        )
        .catch(error => {
          dispatch(requestModifyProfileRulesError());
          dispatch(reverseTimeLimitsUpdate(profileId, deviceId));
          dispatch(showErrorToast(error));
        });
    }
    return Promise.resolve(getProfileRules(getState(), profileId))
      .then(profileRules =>
        profileRules.setIn(['timeRestrictions', 'isDailyLimit'], toggleOn)
      )
      .then(profileRules => {
        return dispatch(
          delayedModifyProfileRules(
            [profileRules, profileId, deviceId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseProfileRulesUpdate());
        dispatch(showErrorToast(error));
      });
  };

const onTimeRangeModify =
  (profileId, deviceId, weekday, hour) => (dispatch, getState) =>
    dispatch(
      isMultiDevices(getState(), profileId)
        ? changeTimeLimits(profileId, deviceId, weekday, hour)
        : changeProfileRules(profileId, weekday, hour)
    );

const changeProfileRules = (profileId, weekday, hour) => {
  return (dispatch, getState) => {
    return Promise.resolve(getProfileRules(getState(), profileId))
      .then(profileRules =>
        profileRules.update('timeRestrictions', setTimeRanges(weekday, hour))
      )
      .then(profileRules => {
        trackTimeRulesChange(TimeLimitsRuleNames.TIME_SCHEDULE_CHANGE);
        return dispatch(
          delayedModifyProfileRules(
            [profileRules, profileId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseProfileRulesUpdate());
        dispatch(showErrorToast(error));
      });
  };
};

const changeTimeLimits =
  (profileId, deviceId, weekday, hour) => (dispatch, getState) =>
    Promise.resolve(getMultiDeviceTimeRestrictions(getState(), deviceId))
      .then(setTimeRanges(weekday, hour))
      .then(timeRestrictions => {
        trackTimeRulesChange(TimeLimitsRuleNames.TIME_SCHEDULE_CHANGE);
        dispatch(
          delayedModifyTimeLimits(
            [timeRestrictions, profileId, deviceId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseTimeLimitsUpdate(profileId, deviceId));
        dispatch(showErrorToast(error));
      });

function onCellTouchEnd(profileId, deviceId, toggleTarget, weekday, hour) {
  return (dispatch, getState) => {
    if (getDaytimeCollapsed(getState(), toggleTarget)) {
      dispatch(toggleDayTimeCollapse(toggleTarget, false));
    } else {
      dispatch(onTimeRangeModify(profileId, deviceId, weekday, hour));
    }
  };
}

const getNewCellBitMask = (timeRanges, weekday, hour) => {
  const currentBitMask = timeRanges.get(weekday);
  const flipBitmask = 1 << hour; // eslint-disable-line no-bitwise
  return currentBitMask ^ flipBitmask; // eslint-disable-line no-bitwise
};

const setTimeRanges = R.curry((weekday, hour, timeRestrictions) => {
  const newBitmask = getNewCellBitMask(
    timeRestrictions.timeRanges,
    weekday,
    hour
  );
  return timeRestrictions.setIn(['timeRanges', weekday], newBitmask);
});

const RestrictedTimesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedTimes);

export default RestrictedTimesContainer;
