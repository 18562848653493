export const isValidAddress = ({
  places,
  address,
  initialValues,
  isEditForm,
}) => {
  const match = places.find(place => place.address === address);
  if (!match) {
    return true;
  }

  if (isEditForm && match.uid === initialValues.uid) {
    return true;
  }

  return false;
};

export const isValidName = ({ places, name, initialValues, isEditForm }) => {
  const match = places.find(place => place.name === name);
  if (!match) {
    return true;
  }

  if (isEditForm && match.uid === initialValues.uid) {
    return true;
  }

  return false;
};
