import { fromJS } from 'immutable';
import type { UnifiedNowCardStatusRecord, NowCardActions } from './types';

export const initialState: UnifiedNowCardStatusRecord = fromJS({
  status: null,
});

const unifiedNowCard = (
  state: UnifiedNowCardStatusRecord = initialState,
  action: NowCardActions
): UnifiedNowCardStatusRecord => {
  switch (action.type) {
    case 'NOW_CARD_REQUEST':
      return state.merge({
        status: 'LOADING',
      });
    case 'NOW_CARD_UPDATE_STATUS':
      return state.merge({
        status: action.payload.status,
      });
    default:
      return state;
  }
};

export default unifiedNowCard;
