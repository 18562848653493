import { DeviceRecord } from '../../records/device/types/Device.types';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { any, isNil } from 'ramda';
import { SafeNetworksSettings } from '../../records/safeNetworksSettings/types/safeNetworkSetting';

export const shouldLoadData = (
  profile?: ProfileRecord,
  devices?: Array<DeviceRecord>,
  safeNetworkSettings?: SafeNetworksSettings,
  checkSafeNetworks?: boolean
): boolean => {
  const checks: Array<
    ProfileRecord | Array<DeviceRecord> | SafeNetworksSettings | undefined
  > = [profile, devices];
  if (checkSafeNetworks) {
    checks.push(safeNetworkSettings);
  }
  return any(isNil)(checks);
};
