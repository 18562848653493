import { Map } from 'immutable';
import {
  CreateRoutinePayload,
  Routine,
  RoutinePayload,
  RoutineRecord,
} from '../../records/routines/types/Routine.types';
import type {
  RoutineDetailsReceiveAction,
  RoutineDetailsRequestAction,
  RoutineScheduleDetailsReceiveAction,
  RoutineSchedulesReceiveAction,
  RoutineSchedulesRequestAction,
  RoutineStatusAction,
  RoutineBulkSchedulesRequestAction,
  CreateRoutineAction,
  RoutinesReceiveAction,
  RoutinesRequestAction,
  RoutinesSetIsOpenMenuAction,
  RoutinesSetMenuTransitionAction,
  RoutinesToggleMenuAction,
  UpdateRoutineAction,
  DeleteRoutineAction,
  CreateRoutineScheduleAction,
  UpdateRoutineScheduleAction,
  DeleteRoutineScheduleAction,
  EditRoutineAction,
  NewRoutineAction,
  FinishRoutineAction,
  SetRoutineMachineAction,
  RoutinesPayload,
  SetTempRoutineUidAction,
  SetTempRoutineCreationModeAction,
} from './types';
import {
  ScheduleCreatePayload,
  SchedulePayload,
  ScheduleRecord,
} from '../../records/routines/schedule/types/Schedule.types';
import { RequestStatus, RequestStatusType } from '../../types/api';
import { RoutineCreationMode } from '../../components/Routines/routines.types';
import { Action } from 'redux';

export const routinesSetIsOpenMenuAction = (
  isOpen: boolean
): RoutinesSetIsOpenMenuAction => ({
  type: 'ROUTINES_SET_IS_OPEN_MENU',
  payload: isOpen,
});

export const routinesOpenMenuAction = (): RoutinesSetIsOpenMenuAction => ({
  type: 'ROUTINES_SET_IS_OPEN_MENU',
  payload: true,
});

export const routinesCloseMenuAction = (): RoutinesSetIsOpenMenuAction => ({
  type: 'ROUTINES_SET_IS_OPEN_MENU',
  payload: false,
});

export const routinesToggleMenuAction = (): RoutinesToggleMenuAction => ({
  type: 'ROUTINES_TOGGLE_MENU',
});

export const routinesSetMenuTransition = (
  activeMenuStep: RoutinesSetMenuTransitionAction['payload']['activeMenuStep'],
  isOpenMenu: RoutinesSetMenuTransitionAction['payload']['isOpenMenu']
): RoutinesSetMenuTransitionAction => ({
  type: 'ROUTINES_SET_MENU_TRANSITION',
  payload: {
    activeMenuStep,
    isOpenMenu,
  },
});

// routine
// -
export const requestRoutines = (
  profileUid: string,
  includeDisabled?: boolean,
  purgeCache?: boolean
): RoutinesRequestAction => ({
  type: 'ROUTINES_REQUEST',
  payload: { profileUid, includeDisabled, purgeCache },
});

export const receiveRoutines = (
  normalizedRoutines: Map<Routine['uid'], RoutineRecord>
): RoutinesReceiveAction => ({
  type: 'ROUTINES_RECEIVE',
  payload: {
    records: {
      routines: normalizedRoutines,
    },
  },
});

export const createRoutine = (
  profileUid: string,
  body: CreateRoutinePayload
): CreateRoutineAction => ({
  type: 'ROUTINES_CREATE',
  payload: { profileUid, body },
});

export const setRoutinesStatus = (
  type: RequestStatusType,
  status: RequestStatus
): RoutineStatusAction => ({
  type: 'ROUTINES_STATUS',
  payload: { type, status },
});

// routine detail
// -
export const requestRoutineDetails = (
  profileUid: string,
  routineUid: string
): RoutineDetailsRequestAction => ({
  type: 'ROUTINE_DETAILS_REQUEST',
  payload: { profileUid, routineUid },
});

export const receiveRoutineDetails = (
  routine: RoutineRecord
): RoutineDetailsReceiveAction => ({
  type: 'ROUTINE_DETAILS_RECEIVE',
  payload: {
    records: {
      routines: {
        [routine.uid]: routine,
      },
    },
  },
});

export const updateRoutine = (
  profileUid: string,
  routineUid: string,
  body: Partial<RoutinePayload>
): UpdateRoutineAction => ({
  type: 'ROUTINE_UPDATE',
  payload: { profileUid, routineUid, body },
});

export const deleteRoutine = (
  profileUid: string,
  routineUid: string
): DeleteRoutineAction => ({
  type: 'ROUTINE_DELETE',
  payload: { profileUid, routineUid },
});

export const setRoutinesDetailsStatus = (
  type: RequestStatusType,
  status: RequestStatus
): RoutineStatusAction => ({
  type: 'ROUTINE_DETAILS_STATUS',
  payload: { type, status },
});

// routine schedules
// -
export const requestRoutineSchedules = (
  profileUid: string,
  routineUid: string
): RoutineSchedulesRequestAction => ({
  type: 'ROUTINE_SCHEDULES_REQUEST',
  payload: { profileUid, routineUid },
});

export const requestBulkRoutineSchedules = (
  routineUids: string[],
  profileUid: string,
  purgeCache?: boolean
): RoutineBulkSchedulesRequestAction => ({
  type: 'ROUTINE_BULK_SCHEDULES_REQUEST',
  payload: { routineUids, purgeCache, profileUid },
});

export const receiveRoutineSchedules = (
  normalizedSchedule: Map<ScheduleRecord['uid'], ScheduleRecord>
): RoutineSchedulesReceiveAction => ({
  type: 'ROUTINE_SCHEDULES_RECEIVE',
  payload: {
    records: {
      routinesSchedules: normalizedSchedule,
    },
  },
});

export const createRoutineSchedules = (
  profileUid: string,
  routineUid: string,
  body: ScheduleCreatePayload,
  onSuccess?: () => Action[]
): CreateRoutineScheduleAction => ({
  type: 'ROUTINE_SCHEDULES_CREATE',
  payload: { profileUid, routineUid, body, onSuccess },
});

export const setRoutineSchedulesStatus = (
  type: RequestStatusType,
  status: RequestStatus
): RoutineStatusAction => ({
  type: 'ROUTINE_SCHEDULES_STATUS',
  payload: { type, status },
});

// routine schedules details
// -
export const receiveRoutineScheduleDetails = (
  scheduleUid: string,
  routineScheduleDetail: ScheduleRecord
): RoutineScheduleDetailsReceiveAction => ({
  type: 'ROUTINE_SCHEDULE_DETAILS_RECEIVE',
  payload: {
    records: {
      routinesSchedules: {
        [scheduleUid]: routineScheduleDetail,
      },
    },
  },
});

export const updateRoutineSchedule = (
  profileUid: string,
  routineUid: string,
  scheduleUid: string,
  body: Partial<SchedulePayload>
): UpdateRoutineScheduleAction => ({
  type: 'ROUTINE_SCHEDULE_DETAILS_UPDATE',
  payload: { profileUid, routineUid, scheduleUid, body },
});

export const deleteRoutineSchedule = (payload: {
  profileUid: string;
  routineUid: string;
  scheduleUid: string;
  origin?: 'detail';
  onSuccess?: () => Action[];
}): DeleteRoutineScheduleAction => ({
  type: 'ROUTINE_SCHEDULE_DETAILS_DELETE',
  payload,
});

export const setScheduleDetailsStatus = (
  type: RequestStatusType,
  status: RequestStatus
): RoutineStatusAction => ({
  type: 'ROUTINE_SCHEDULE_DETAILS_STATUS',
  payload: { type, status },
});

export const editRoutine = (
  routineUid: RoutineRecord['uid']
): EditRoutineAction => ({
  type: 'ROUTINES_EDIT',
  payload: {
    routineUid,
  },
});

export const newRoutineAction = (): NewRoutineAction => ({
  type: 'ROUTINES_NEW',
});

export const finishRoutine = (): FinishRoutineAction => ({
  type: 'ROUTINES_FINISH',
});

/**
 *
 * IMPORTANT: if this action is dispatched,
 * it must always be followed by a resetRoutineMachineAction to avoid
 * leaving actions in the state that the state machine will execute,
 * which could cause inconsistencies in future updates of the state machine.
 * For more context, refer to the file
 * components/Routines/RoutinesMultiStep/multiStepMachine.tsx
 *
 */
export const setRoutineMachineAction = (
  payload: RoutinesPayload['machineAction']
): SetRoutineMachineAction => ({
  type: 'ROUTINES_SET_MACHINE_ACTION',
  payload,
});

export const resetRoutineMachineAction = (): SetRoutineMachineAction => ({
  type: 'ROUTINES_SET_MACHINE_ACTION',
  payload: {
    type: null,
    params: null,
  },
});

export const setTempRoutineUid = (
  uid: string | null
): SetTempRoutineUidAction => ({
  type: 'ROUTINES_SET_TEMP_ROUTINE_UID',
  payload: { uid },
});

export const setTempRoutineMode = (
  creationMode: RoutineCreationMode | null
): SetTempRoutineCreationModeAction => ({
  type: 'ROUTINES_SET_TEMP_ROUTINE_CREATION_MODE',
  payload: { creationMode },
});
