import { track, format, Events } from '../../helpers/analytics';

export const trackGetStartedFamilyTherapy = () => {
  return track(Events.ClickedGetStartedFamilyTherapy);
};

export const trackSubscribeToFamilyTherapy = (properties: string[]) => {
  return track(
    Events.ClickedSubscribeFamilyTherapy,
    format({ options: { properties } })
  );
};
