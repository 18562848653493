import { List } from 'immutable';
import {
  CalendarRestrictionPause,
  CalendarRestrictionPauseList,
  CalendarRestrictionPausePayload,
} from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';
import { CalendarRestrictionPauseOperation } from '../../records/calendarRestrictionsPause/CalendarRestrictionPause';
import { CalendarRestrictionsResponse } from './types';

export const toActionFormat = (
  response: CalendarRestrictionsResponse
): CalendarRestrictionPauseList =>
  List<CalendarRestrictionPause>(
    response.items_list.map(
      (calendarRestriction: CalendarRestrictionPausePayload) =>
        CalendarRestrictionPauseOperation.fromPayload(calendarRestriction)
    )
  );
