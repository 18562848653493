import {
  sortBy,
  prop,
  pipe,
  groupBy,
  map,
  reduce,
  uniq,
  values,
  pluck,
  flatten,
  max,
  min,
  take,
  last,
  clone,
} from 'ramda';

export type SplitJoinSlotData = {
  ids: Array<string>;
  start: number;
  end: number;
};

const findIntersections = (data: SplitJoinSlotData[]) => {
  const intervals = sortBy(prop('start'), data);
  const intersections: SplitJoinSlotData[] = [];

  for (let i = 0; i < intervals.length; i += 1) {
    for (let j = i + 1; j < intervals.length; j += 1) {
      const intervalA = intervals[i];
      const intervalB = intervals[j];

      if (
        (intervalA.start >= intervalB.start &&
          intervalA.start < intervalB.end) ||
        (intervalB.start >= intervalA.start && intervalB.start < intervalA.end)
      ) {
        const intersectionStart = Math.max(intervalA.start, intervalB.start);
        const intersectionEnd = Math.min(intervalA.end, intervalB.end);

        intersections.push({
          ids: [...intervalA.ids, ...intervalB.ids],
          start: intersectionStart,
          end: intersectionEnd,
        } as SplitJoinSlotData);
      }
    }
  }

  return intersections;
};

const joinIntersections = pipe(
  groupBy(data => `${data.start}-${data.end}`),
  map(
    reduce((acc, data) => {
      return {
        ...data,
        ids: uniq(acc.ids ? [...(acc?.ids ?? []), ...data.ids] : data.ids),
      };
    }, {})
  ),
  values
);

const getOverlaps = pipe(findIntersections, joinIntersections);

const filterRedundantSlots = (data: SplitJoinSlotData[]) => {
  const groupedById = groupBy(prop('ids'), data);
  const filteredGroups = pipe(
    values,
    map(group => {
      const maxStart = pipe(pluck('start'), reduce(max, -Infinity))(group);
      const minEnd = pipe(pluck('end'), reduce(min, Infinity))(group);
      const unique = take(1, group);
      unique.start = maxStart;
      unique.end = minEnd;
      return [unique].filter(entry => entry.start !== entry.end);
    }),
    flatten
  )(groupedById);

  return filteredGroups;
};

const mergeEntries = data => {
  const mergedEntries = pipe(
    sortBy(prop('start')),
    reduce((result, entry) => {
      const lastEntry = last(result);
      if (lastEntry && lastEntry.end >= entry.start) {
        lastEntry.end = Math.max(lastEntry.end, entry.end);
        lastEntry.ids = uniq([...lastEntry.ids, ...entry.ids]);
      } else {
        result.push(clone(entry));
      }
      return result;
    }, [])
  )(data);

  return mergedEntries;
};

export const splitJoinSlotsWithOverlaps = (data: SplitJoinSlotData[]) => {
  const overlaps: SplitJoinSlotData[] = getOverlaps(data);

  if (!overlaps.length) return data;

  const splits: SplitJoinSlotData[] = [];

  for (const overlap of overlaps) {
    for (const slot of data) {
      const overlapDuration = overlap.end - overlap.start;
      if (overlap.start === slot.start && overlap.end === slot.end) {
        // overlap is same as the slot
        const remainigSlot = {
          ...slot,
          start: slot.start + overlapDuration,
          end: slot.end - overlapDuration,
        };
        if (remainigSlot.end > remainigSlot.start) {
          splits.push(remainigSlot);
        }
      } else if (overlap.start === slot.start) {
        // overlap start as the start of a slot
        const remainigSlot = {
          ...slot,
          start: slot.start + overlapDuration,
        };

        if (remainigSlot.end > remainigSlot.start) {
          splits.push(remainigSlot);
        }
      } else if (overlap.end === slot.end) {
        // overlap ends with the end of a slot
        const remainigSlot = {
          ...slot,
          end: slot.end - overlapDuration,
        };

        if (remainigSlot.end > remainigSlot.start) {
          splits.push(remainigSlot);
        }
      } else {
        // if the slot is not trimmed or overlapped, we just add it back in
        splits.push(slot);
      }
    }
  }

  const allMerged = sortBy(prop('start'), [
    ...mergeEntries(overlaps),
    ...filterRedundantSlots(splits),
  ]);

  return allMerged as SplitJoinSlotData[];
};
