import * as React from 'react';
import { t } from '../../../lib/i18n';
import { List as ImmutableList } from 'immutable';
import { YoutubeSummaryRecord } from '../../../records/youtubeSummary';
import { SummaryDateRanges } from '../../../constants';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import {
  DetailsItemRecord,
  UrlDetailsEvent,
  VideoDetailsRecord,
} from '../../../records/urlDetails';
import { getNoActivityMsg, getNoSearchesMsg } from './uiHelpers';
import ScrollableYoutubeVideoList from './YoutubeVideoList';
import SummaryCard from '../SummaryCard';
import {
  Card,
  CloudList,
  ContentSeparator,
  EmptyPlaceholder,
  FlexLayout,
  GroupHeader,
  Icon,
  IconFamily,
  IconType,
  Layout,
  Quote,
  Svg,
} from 'styleguide-react';
import YouTubeIcon from '../../Icons/summary/YouTubeIcon';
import PageLayout from '../../Layout/PageLayout';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import { ProfileRecord } from '../../../records/profile/types/Profile.types';

export interface YoutubeActivityProps {
  profile: ProfileRecord;
  summary: YoutubeSummaryRecord;
  videos: ImmutableList<VideoDetailsRecord>;
  activeDateRange: SummaryDateRanges;
  lastVideoActionSelected: VideoDetailsRecord;
  isFetchingVideos: boolean;
  hasMoreVideos: boolean;
  width?: string;
  handleMore: () => void;
  onClickEdit: () => any;
  goToInfoModal: () => any;
  onSelectDateRange: (range: SummaryDateRanges) => any;
  onClickAction: (item: DetailsItemRecord) => any;
  onClickPage: (item: UrlDetailsEvent) => any;
}

const YoutubeActivity: React.FunctionComponent<YoutubeActivityProps> = ({
  profile,
  summary,
  videos,
  activeDateRange,
  onSelectDateRange,
  isFetchingVideos,
  hasMoreVideos,
  width,
  goToInfoModal,
  handleMore,
  onClickAction,
  onClickPage,
}) => {
  const renderPageContent = () => (
    <React.Fragment>
      {summary.totalVideos === 0 && summary.totalSearches === 0 ? (
        <EmptyPlaceholder
          icon={
            <Svg height="64" width="64" color="neutral">
              <YouTubeIcon />
            </Svg>
          }
          text={getNoActivityMsg(activeDateRange)}
          maxWidth="75%"
          fillAvailableSpace
          centered
          testId="no-activity-youtube-videos"
        />
      ) : (
        <SummaryCard
          isEmpty={summary.totalVideos === 0 && summary.totalSearches === 0}
          displayAll
          needsMargin={false}
          width={width}
          header={{
            icon: <Icon type={IconType.youtube} family={IconFamily.brands} />,
            title: t('YouTube Summary'),
            actionElement: <Icon type={IconType.infoCircle} />,
            onClickAction: goToInfoModal,
          }}
          body={
            <FlexLayout mainaxis="column" marginTop="8">
              <FlexLayout
                mainaxis="row"
                mainaxisAlignment="space-between"
                marginBottom="24"
              >
                <span data-testid="total-videos-title">{t('Videos')}</span>
                <span data-testid="total-videos">{summary.totalVideos}</span>
              </FlexLayout>
              <ContentSeparator />
              <FlexLayout
                mainaxis="row"
                mainaxisAlignment="space-between"
                marginBottom="24"
                marginTop="16"
              >
                <span data-testid="total-searches-title">{t('Searches')}</span>
                <span data-testid="total-searches">
                  {summary.totalSearches}
                </span>
              </FlexLayout>
              <ContentSeparator />
              <FlexLayout mainaxis="column" marginTop="16">
                <Layout marginBottom="16">
                  <GroupHeader testId="latest-searches-header">
                    {t('Latest searches (Max. {1}{/1})', { 1: () => '5' })}
                  </GroupHeader>
                </Layout>
                {summary.lastSearches && summary.lastSearches.size !== 0 ? (
                  <CloudList
                    allowedListItemType={Quote}
                    listItemMaxWidth="100%"
                    testId="searches"
                  >
                    {summary.lastSearches
                      .sort(
                        (search1, search2) => search2.length - search1.length
                      )
                      .map(search => (
                        <Quote maxLines="none" key={search}>
                          {search}
                        </Quote>
                      ))}
                  </CloudList>
                ) : (
                  <EmptyPlaceholder
                    fillAvailableSpace
                    centered
                    smallText
                    text={getNoSearchesMsg(activeDateRange)}
                  />
                )}
              </FlexLayout>
              <FlexLayout mainaxis="column" marginTop="24">
                <ScrollableYoutubeVideoList
                  videos={videos}
                  onClickPage={onClickPage}
                  onClickAction={onClickAction}
                  handleMore={handleMore}
                  isFetching={isFetchingVideos}
                  hasMore={hasMoreVideos}
                  activeDateRange={activeDateRange}
                />
              </FlexLayout>
            </FlexLayout>
          }
        />
      )}
    </React.Fragment>
  );
  return (
    <PageLayout
      title={profile.name}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 2,
        },
      ]}
      actions={[{ actionName: 'back' }]}
    >
      <Layout marginBottom="8">
        <DateRangeSelector
          activeRange={activeDateRange}
          onChangeDateRange={onSelectDateRange}
        />
      </Layout>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <Card fullWidth minHeight="520">
          {renderPageContent()}
        </Card>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderPageContent()}
      </RenderWhen>
    </PageLayout>
  );
};

export default YoutubeActivity;
