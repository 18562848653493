import { commonMapInFn } from '../../api/mappings/common';
import { InvalidFileTypesInLinewizeEvents } from '../../constants';
import { cleanInvalidUrls } from '../../helpers/string';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  LinewizeEventPayload,
  LinewizeEvent,
  LinewizeEventRecord,
  WEB_APP,
  SEARCH,
  VIDEO,
} from './types/linewizeEvents';

export const LinewizeEventsOperation = getRecordOperations<
  LinewizeEvent,
  LinewizeEventPayload
>({
  defaultFields: {
    key: null,
    dt: null,
    type: null,
    action: null,
    urls: [],
    categoryName: null,
    categoryId: null,
    signatureName: null,
    signatureId: null,
    atSchoolHours: false,
    signatureIdentifiedAsApp: false,
    hasReview: null,
    icon: null,
    searchTerms: null,
    title: null,
    url: null,
    thumbnail: null,
  },
  mapIn: payload => {
    let updatedUrls: string[] = [];
    if (payload.urls && payload.urls.length > 0) {
      const cleanUrls = cleanInvalidUrls(
        payload.urls,
        InvalidFileTypesInLinewizeEvents
      );
      updatedUrls = cleanUrls.map(url => `https://${url}`);
    }
    return {
      ...commonMapInFn(payload),
      urls: updatedUrls,
    };
  },
});

export const isLinewizeWebAppEvent = (event: LinewizeEventRecord) =>
  event.type === WEB_APP;

export const isLinewizeSearchEvent = (event: LinewizeEventRecord) =>
  event.type === SEARCH;

export const isLinewizeVideoEvent = (event: LinewizeEventRecord) =>
  event.type === VIDEO;
