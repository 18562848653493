import { t } from '../../lib/i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import State from '../../store/state';
import {
  getShowEventActions,
  getEditCategory,
  getEditDomainException,
  getCallsSMSBlockingOption,
  getShowDetailsAction,
  getProfileId,
  getPanicRulesSelectedContact,
  getCallsSMSActiveContact,
  getYoutubeSummarySelectedLastVideo,
} from '../../selectors';

import { EventType, CallsSmsEditOption, VideoActions } from '../../constants';
import { VideoDetailsRecord } from '../../records/urlDetails';
import {
  webCategoryActionSheetClickInRules,
  webExceptionActionSheetClick,
  panicContactActionSheetClick,
  callsAndSMSExceptionActionSheetClick,
  callsAndSMSRuleActionSheetClick,
  youtubeDetailsActionSheetClick,
  youtubeSummaryActionSheetClick,
  appEventActionSheetClickWithMobileActions,
  webEventActionSheetClickWithMobileActions,
  safeNetworkEventSheetClickWithMobileActions,
  panicEventContactActionSheetClickWithMobileActions,
  callsAndSMSEventActionSheetClickWithMobileActions,
} from '../../actions/ActionSheetActions';
import {
  editCategory,
  editDomainException,
  setCallsSMSBlockingOption,
  setCallsSMSActiveContact,
} from '../../ducks/profileRules';
import ft, { DISABLE_URL_MONITORING } from '../../lib/ft';
import { videoRuleSheetClick } from '../../actions/UrlDetailsActions';
import { setSelectedContact } from '../../ducks/contacts';
import DropDown, { Option } from './DropDown';
import { EventRecord } from '../../records';
import {
  getAppActions,
  getCallsAndSmsActions,
  getContactActions,
  getContactCallsSMSActions,
  getDetailActions,
  getDomainActions,
  getPanicActions,
  getPanicButtonRulesActions,
  getYoutubeActions,
  getWebFilterCategoryActions,
  getWebFilterDomainActions,
  getSafeNetworkActions,
} from './DropDownActions';

const noopAction = { type: 'noop' };
interface DropDownActionSheetProps {
  selectedActionSheetKey?: string | number | CallsSmsEditOption;
  title?: string;
  isVisit?: boolean;
  minWidth?: number;
}

interface ActionCreatorProps {
  appEventActionSheetClickWithMobileActions: typeof appEventActionSheetClickWithMobileActions;
  webEventActionSheetClickWithMobileActions: typeof webEventActionSheetClickWithMobileActions;
  webExceptionActionSheetClick: typeof webExceptionActionSheetClick;
  webCategoryActionSheetClickInRules: typeof webCategoryActionSheetClickInRules;
  panicEventContactActionSheetClickWithMobileActions: typeof panicEventContactActionSheetClickWithMobileActions;
  panicContactActionSheetClick: typeof panicContactActionSheetClick;
  callsAndSMSEventActionSheetClickWithMobileActions: typeof callsAndSMSEventActionSheetClickWithMobileActions;
  callsAndSMSRuleActionSheetClick: typeof callsAndSMSRuleActionSheetClick;
  callsAndSMSExceptionActionSheetClick: typeof callsAndSMSExceptionActionSheetClick;
  youtubeDetailsActionSheetClick: typeof youtubeDetailsActionSheetClick;
  youtubeSummaryActionSheetClick: typeof youtubeSummaryActionSheetClick;
  safeNetworkEventSheetClickWithMobileActions: typeof safeNetworkEventSheetClickWithMobileActions;
  onClose: (actionCreator: (args?) => any) => any;
}

interface MergedDropDownActionSheetProps
  extends DropDownActionSheetProps,
    ActionCreatorProps {}

const mapStateToProps = (
  state: State,
  props: MergedDropDownActionSheetProps
) => {
  const { title } = props;

  const isCallsAndSMSExceptionActionSheetActive =
    getCallsSMSActiveContact(state);
  if (isCallsAndSMSExceptionActionSheetActive) {
    return {
      ...getDropDownPropsForCallsAndSMSException({ state, props }),
      title,
    };
  }

  const isPanicContactActionSheetActive = getPanicRulesSelectedContact(state);
  if (isPanicContactActionSheetActive) {
    return { ...getDropDownPropsForPanicContact({ state, props }), title };
  }

  const isYoutubeDetailsActionSheetActive = getShowDetailsAction(state);
  if (isYoutubeDetailsActionSheetActive) {
    return { ...getDropDownPropsForYoutubeDetails({ state, props }), title };
  }

  const isCallsAndSMSRuleActionSheetActive = getCallsSMSBlockingOption(state);
  if (isCallsAndSMSRuleActionSheetActive) {
    return { ...getDropDownPropsForCallsAndSMSRule({ state, props }), title };
  }

  const isWebExceptionActionSheetActive = getEditDomainException(state);
  if (isWebExceptionActionSheetActive) {
    return { ...getDropDownPropsForWebException({ state, props }), title };
  }

  const isWebCategoryActionSheetActive = getEditCategory(state);
  if (isWebCategoryActionSheetActive) {
    return { ...getDropDownPropsForWebCategory({ state, props }), title };
  }

  const isYoutubeSummaryActionSheetActive =
    getYoutubeSummarySelectedLastVideo(state);
  if (isYoutubeSummaryActionSheetActive) {
    return { ...getDropDownPropsForYoutubeSummary({ state, props }), title };
  }

  return { ...getDropDownPropsForEvent({ state, props }), title };
};

const DropDownActionSheet = connect(
  null,
  (dispatch): ActionCreatorProps =>
    bindActionCreators(
      {
        appEventActionSheetClickWithMobileActions,
        webEventActionSheetClickWithMobileActions,
        webExceptionActionSheetClick,
        webCategoryActionSheetClickInRules,
        panicEventContactActionSheetClickWithMobileActions,
        panicContactActionSheetClick,
        callsAndSMSEventActionSheetClickWithMobileActions,
        callsAndSMSRuleActionSheetClick,
        callsAndSMSExceptionActionSheetClick,
        youtubeDetailsActionSheetClick,
        youtubeSummaryActionSheetClick,
        safeNetworkEventSheetClickWithMobileActions,
        onClose: actionCreator => actionCreator(undefined),
      },
      dispatch
    ) as unknown as ActionCreatorProps
)(connect(mapStateToProps)(DropDown));

export default DropDownActionSheet;

type GetDropDownPropsFunc = (params: {
  state: State;
  props: MergedDropDownActionSheetProps;
}) => {
  active?: boolean;
  options: Option[];
  onClose?: () => any;
};

const getDropDownPropsForCallsAndSMSException: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const contact = getCallsSMSActiveContact(state);
  const active =
    contact === null ? false : props.selectedActionSheetKey === contact.phone;

  return {
    active,
    options: formatActionsToOptions(
      getContactActions(
        option => () => props.callsAndSMSExceptionActionSheetClick(option)
      )
    ),
    onClose: () => props.onClose(setCallsSMSActiveContact),
  };
};

const getDropDownPropsForPanicContact: GetDropDownPropsFunc = ({
  state,
  props,
}) => ({
  active:
    props.selectedActionSheetKey === getPanicRulesSelectedContact(state).id,
  options: formatActionsToOptions(
    getPanicButtonRulesActions(
      getPanicRulesSelectedContact(state),
      action => () => props.panicContactActionSheetClick(action)
    )
  ),
  onClose: () => props.onClose(setSelectedContact),
});

const getDropDownPropsForYoutubeDetails: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const video = getShowDetailsAction(state) as VideoDetailsRecord;
  const active = (props.selectedActionSheetKey &&
    props.selectedActionSheetKey === video.key) as boolean;

  const actions = getDetailActions(video)(
    action => () =>
      props.youtubeDetailsActionSheetClick({
        action,
        event: video,
        eventKey: video.key,
      })
  );

  const onClose = () =>
    props.onClose(() => videoRuleSheetClick(video.key, getProfileId(state)));

  return {
    active,
    options: formatActionsToOptions(actions),
    onClose,
  };
};

const getDropDownPropsForCallsAndSMSRule: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const option = getCallsSMSBlockingOption(state);
  const active = option && props.selectedActionSheetKey === option;

  return {
    active,
    options: formatActionsToOptions(
      getCallsAndSmsActions(
        value => () => props.callsAndSMSRuleActionSheetClick(value, option)
      )
    ),
    onClose: () => props.onClose(setCallsSMSBlockingOption),
  };
};

const getDropDownPropsForWebException: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const domain = getEditDomainException(state);
  const active = domain && domain === props.selectedActionSheetKey;

  return {
    active,
    options: formatActionsToOptions(
      getWebFilterDomainActions(action => () => {
        props.webExceptionActionSheetClick(action);
      })
    ),
    onClose: () =>
      props.onClose(active ? editDomainException : () => noopAction),
  };
};

const getDropDownPropsForYoutubeSummary: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const video = getYoutubeSummarySelectedLastVideo(state);
  const active =
    video === undefined
      ? false
      : ((props.selectedActionSheetKey &&
          props.selectedActionSheetKey === video.key) as boolean);

  const actions = getDetailActions(video)(
    action => () => props.youtubeSummaryActionSheetClick(action, video)
  );

  return {
    active,
    options: formatActionsToOptions(actions),
    onClose: () =>
      props.onClose(
        active
          ? () => youtubeSummaryActionSheetClick(VideoActions.Unknown)
          : () => noopAction
      ),
  };
};

const getDropDownPropsForWebCategory: GetDropDownPropsFunc = ({
  state,
  props,
}) => {
  const category = getEditCategory(state);
  const active = category && category.label === props.selectedActionSheetKey;

  return {
    active,
    options: formatActionsToOptions(
      active
        ? getWebFilterCategoryActions(action => () => {
            props.webCategoryActionSheetClickInRules(action);
          })
        : []
    ),
    onClose: () => props.onClose(active ? editCategory : () => noopAction),
  };
};

const getDropDownPropsForEvent: GetDropDownPropsFunc = ({ state, props }) => {
  const event = getShowEventActions(state);
  const isDomainVisited = props.isVisit || ft.active(DISABLE_URL_MONITORING);

  const active =
    event && props.selectedActionSheetKey
      ? event.key === props.selectedActionSheetKey
      : false;

  return {
    active,
    options: formatActionsToOptions(
      active ? getEventActions({ event, isDomainVisited, props }) : []
    ),
    onClose: () =>
      props.onClose(active ? getEventCloseHandler(event) : () => noopAction),
  };
};

const getEventActions = ({ event, props, isDomainVisited }) => {
  switch (event.type) {
    case EventType.Web: {
      return getDomainActions(isDomainVisited)(
        action => () => props.webEventActionSheetClickWithMobileActions(action)
      );
    }

    case EventType.App: {
      return getAppActions(
        action => () => props.appEventActionSheetClickWithMobileActions(action)
      );
    }

    case EventType.Youtube: {
      return getYoutubeActions(isDomainVisited)(
        action => () => props.webEventActionSheetClickWithMobileActions(action)
      );
    }
    case EventType.CallsIncoming:
    case EventType.CallsOutgoing:
    case EventType.CallsMissed:
    case EventType.CallsUnanswered:
    case EventType.CallsBlockedIncoming:
    case EventType.CallsBlockedOutgoing:
    case EventType.SmsIncoming:
    case EventType.SmsOutgoing:
    case EventType.SmsBlockedIncoming:
    case EventType.SmsBlockedOutgoing: {
      return getContactCallsSMSActions(
        action => () =>
          props.callsAndSMSEventActionSheetClickWithMobileActions(action)
      );
    }

    case EventType.Panic: {
      return getPanicActions(
        action => () =>
          props.panicEventContactActionSheetClickWithMobileActions(action),
        event
      );
    }

    case EventType.SafeNetworkDisablingProtection:
    case EventType.SafeNetworkEnablingProtection:
      return getSafeNetworkActions(
        action => () =>
          props.safeNetworkEventSheetClickWithMobileActions(action)
      );

    default: {
      return [];
    }
  }
};

const getEventCloseHandler = event => {
  switch (event.type) {
    case EventType.Web:
    case EventType.Youtube: {
      return webEventActionSheetClickWithMobileActions;
    }

    case EventType.App: {
      return appEventActionSheetClickWithMobileActions;
    }

    case EventType.CallsIncoming:
    case EventType.CallsOutgoing:
    case EventType.CallsMissed:
    case EventType.CallsUnanswered:
    case EventType.CallsBlockedIncoming:
    case EventType.CallsBlockedOutgoing:
    case EventType.SmsIncoming:
    case EventType.SmsOutgoing:
    case EventType.SmsBlockedIncoming:
    case EventType.SmsBlockedOutgoing: {
      return callsAndSMSEventActionSheetClickWithMobileActions;
    }

    case EventType.Panic: {
      return panicEventContactActionSheetClickWithMobileActions;
    }

    default: {
      return () => noopAction;
    }
  }
};

export const formatActionsToOptions = (actions): Option[] =>
  actions
    .filter(action => action.length !== 0)
    .map(([onClick, icon, label, index]) => ({ onClick, icon, label, index }));

export const getDropDownActionSheetTitle = (
  event: EventRecord
): string | undefined => {
  switch (event.type) {
    case EventType.Youtube:
      return t('YouTube');
    case EventType.Web:
      return event.host ?? 'domain';
    case EventType.App:
      return event.name;
    case EventType.Calls:
    case EventType.CallsIncoming:
    case EventType.CallsOutgoing:
    case EventType.CallsMissed:
    case EventType.CallsUnanswered:
    case EventType.CallsBlockedIncoming:
    case EventType.CallsBlockedOutgoing:
    case EventType.Sms:
    case EventType.SmsIncoming:
    case EventType.SmsOutgoing:
    case EventType.SmsBlockedIncoming:
    case EventType.SmsBlockedOutgoing:
      return !event.name ? event.socialContactId : event.name;
    case EventType.Panic:
      return t('Panic button');
    case EventType.SafeNetworkDisablingProtection:
      return t('School Safe Network connected');
    case EventType.SafeNetworkEnablingProtection:
      return t('School Safe Network disconnected');
    default:
      return undefined;
  }
};
