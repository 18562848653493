import * as React from 'react';
import { Map } from 'immutable';
import {
  Button,
  ButtonSize,
  ClickableListItem,
  FlexLayout,
  Label,
  Layout,
  List,
  ListItemVerticalAlign,
  ListTitle,
  RadioButton,
  RegularStyleListItem,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  WEBFILTER_ALERT,
  WEBFILTER_BLOCK,
  WEBFILTER_IGNORE,
  WEBFILTER_ALLOW,
  WebfilterAction,
} from '../../constants';
import AddDomainExceptionForm, {
  FormRecord,
  formName,
  addDomainExceptionFieldId,
} from './AddDomainExceptionForm';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import IconAlert from '../Icons/Actions/IconAlert';
import IconBlock from '../Icons/Actions/IconBlock';
import IconIgnore from '../Icons/Actions/IconIgnore';
import IconAllow from '../Icons/Actions/IconAllow';
import { WithUpgradeTag } from '../UpgradeTag/UpgradeTag';

export type AddDomainExceptionProps = {
  isSaving: boolean;
  domains: Map<string, WebfilterAction>;
  selectedDomainAction: WebfilterAction;
  isAlertMeOptionPremium: boolean;
  onClickDomainAction: (action: WebfilterAction) => any;
  onSubmit: (record: FormRecord) => any;
  onClickBackButton: () => any;
};

const AddDomainException = ({
  domains,
  selectedDomainAction,
  isSaving,
  isAlertMeOptionPremium,
  onClickDomainAction,
  onSubmit,
  onClickBackButton,
}: AddDomainExceptionProps) => {
  const renderButton = () => (
    <FlexLayout mainaxis="row" mainaxisAlignment="center">
      <Button
        size={ButtonSize.medium}
        form={formName}
        loading={isSaving}
        htmlType="submit"
      >
        {t('Add Exception')}
      </Button>
    </FlexLayout>
  );

  const domainActions = (): [
    WebfilterAction,
    JSX.Element,
    string | JSX.Element
  ][] => [
    [WEBFILTER_ALLOW, <IconAllow key="Allow" />, t('Allow')],
    [
      WEBFILTER_ALERT,
      <IconAlert key="Alert-me" />,
      <WithUpgradeTag key="tag" when={isAlertMeOptionPremium}>
        {t('Alert me')}
      </WithUpgradeTag>,
    ],
    [WEBFILTER_BLOCK, <IconBlock key="Block" />, t('Block')],
    [WEBFILTER_IGNORE, <IconIgnore key="Ignore" />, t('Ignore')],
  ];

  const renderDomainAction = ([action, icon, label], index) => {
    return (
      <RegularStyleListItem
        key={index}
        icon={icon}
        title={
          <ClickableListItem onClick={() => onClickDomainAction(action)}>
            <ListTitle>{label}</ListTitle>
          </ClickableListItem>
        }
        actionElementVerticalAlign={ListItemVerticalAlign.center}
        actionElement={
          <RadioButton
            id={action}
            value={action}
            checked={selectedDomainAction === action}
            onClick={() => onClickDomainAction(action)}
            onChange={() => onClickDomainAction(action)}
          />
        }
      />
    );
  };

  const renderContent = () => (
    <FlexLayout mainaxis="column" matchParentHeight>
      <FlexLayout mainaxis="row" mainaxisAlignment="space-between" flexGrow="0">
        <Layout marginBottom="8">
          <Label htmlFor={addDomainExceptionFieldId}>{t('Website URL')}</Label>
        </Layout>
      </FlexLayout>

      <FlexLayout mainaxis="column" flexGrow="1">
        <AddDomainExceptionForm onSubmit={onSubmit} domains={domains} />

        <List listItemMarginTop="16" listItemPaddingBottom="8">
          {domainActions().map(renderDomainAction)}
        </List>
      </FlexLayout>

      {renderButton()}
    </FlexLayout>
  );

  return (
    <PageLayout
      title={t('Add a website')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
      ]}
      actions={[{ actionName: 'back', onClick: onClickBackButton }]}
    >
      {renderContent()}
    </PageLayout>
  );
};

AddDomainException.propTypes = {};

export default AddDomainException;
