import { connect } from 'react-redux';
import { selectedNewDateRangeForProfile } from '../../actions/ProfileActions';
import { SummaryDateRanges } from '../../constants';
import { Events, PageNames, track } from '../../helpers/analytics';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { getActiveDateRange, getProfileOrDefault } from '../../selectors';
import State, { Dispatch } from '../../store/state';
import ScreenTimeDetailsPage from '../../components/ActivitySummary/ScreenTimeDetailsPage';
import { trackablePage } from '../../helpers/hocs';
import { isCombinedDashboardProfile } from '../Dashboard/helpers/profileDashboardHelpers';

const mapStateToProps = (state: State, { params: { profileId } }) => {
  const _profileId = Number(profileId);
  const isProfileCombineOrDelegated = isCombinedDashboardProfile(
    state,
    _profileId
  );
  return {
    profile: getProfileOrDefault(state, _profileId),
    activeDateRange: getActiveDateRange(state),
    isProfileCombineOrDelegated,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { params: { profileId } }) => ({
  onClickEditProfile: () => {
    track(Events.ClickedProfileEdit);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onSelectDateRange: (range: SummaryDateRanges) =>
    dispatch(selectedNewDateRangeForProfile(range, profileId)),
});

const ScreenTimeDetailsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(ScreenTimeDetailsPage, PageNames.ProfileScreenTimeDetails));

export type ScreenTimeDetailsPageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default ScreenTimeDetailsPageContainer;
