import * as React from 'react';
import { connect } from 'react-redux';
import {
  ListTitle,
  FreeStyleListItem,
  FlexLayout,
  ListSubtitle,
  AvatarSize,
  ClickableListItem,
  Icon,
  IconType,
  IconFamily,
} from 'styleguide-react';
import { timeFrom } from '../../helpers/dates';
import { getAccount } from '../../selectors';
import {
  profileHasIrregularStatus,
  getLocationDescription,
  profileHasTrackingDisabled,
} from './ProfileLocationStatusHelper';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

export const FamilyLocatorItemComponent: React.FunctionComponent<{
  profile: ProfileRecord;
  hasFetchedProfileRules: boolean;
  locationDescription: string;
  elapsedTime: string;
  customAlertIcon: boolean;
  onClick: () => void;
}> = ({
  profile,
  hasFetchedProfileRules,
  locationDescription,
  elapsedTime,
  customAlertIcon,
  onClick,
}) => (
  <FreeStyleListItem>
    <FlexLayout mainaxis="row">
      <FlexLayout
        mainaxis="column"
        flexGrow="0"
        flexShrink="1"
        paddingRight="8"
        mainaxisAlignment="center"
      >
        <ProfileAvatar
          size={AvatarSize.small}
          icon={
            customAlertIcon ? (
              <Icon family={IconFamily.solid} type={IconType.exclamation} />
            ) : undefined
          }
          onClick={onClick}
          profile={profile}
        />
      </FlexLayout>

      <FlexLayout mainaxis="column" flexGrow="1">
        <FlexLayout mainaxis="row" flexGrow="1">
          <FlexLayout mainaxis="row" flexGrow="1">
            <ClickableListItem onClick={onClick}>
              <ListTitle>{profile.name}</ListTitle>
            </ClickableListItem>
          </FlexLayout>

          <FlexLayout mainaxis="row" flexShrink="0" paddingLeft="8">
            <ClickableListItem onClick={onClick}>
              <ListSubtitle testId="elapsed-time">
                {profile.lastseen ? <span>{elapsedTime}</span> : null}
              </ListSubtitle>
            </ClickableListItem>
          </FlexLayout>
        </FlexLayout>

        <FlexLayout mainaxis="column" flexShrink="0">
          <ClickableListItem onClick={onClick}>
            <ListSubtitle testId="location-description">
              {hasFetchedProfileRules ? locationDescription : null}
            </ListSubtitle>
          </ClickableListItem>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </FreeStyleListItem>
);

type stateProps = {
  profile: ProfileRecord;
  hasFetchedProfileRules: boolean;
};

const mapStateToProps = (state, params: stateProps) => {
  const { profile, hasFetchedProfileRules } = params;
  const account = getAccount(state);
  const hasTrackingDisabled = profileHasTrackingDisabled(state, profile.id);
  const profileStatusIrregularAfterFetch =
    hasFetchedProfileRules && profileHasIrregularStatus(state, profile.id);
  return {
    profile,
    hasFetchedProfileRules,
    customAlertIcon: profileStatusIrregularAfterFetch && !hasTrackingDisabled,
    locationDescription: getLocationDescription(state, profile.id),
    elapsedTime: timeFrom(
      profile.location.time,
      account.timezone,
      account.locale
    ),
  };
};

const mapDispatchToProps = (dispatch, { profile }) => {
  const navigate = getMultiPlatformNavigation();

  return {
    onClick: () =>
      dispatch(
        navigate({
          type: 'inner',
          src: `/familyLocator/${profile.id}/locations`,
        })
      ),
  };
};

const FamilyLocatorItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyLocatorItemComponent);

export default FamilyLocatorItem;
