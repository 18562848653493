import { Map } from 'immutable';
import State from '../../../store/state';
import { RoutineColor } from '../../../palettes/RoutineColor';
import {
  getProfileActiveRoutineUid,
  getLocationPathname,
} from '../../../selectors';
import { createSelector } from 'reselect';
import {
  getActiveScheduleFromSchedulesList,
  isRoutineEnabled,
  isRoutinePaused,
  isRoutineScheduleOverridden,
} from '../helpers';
import { RoutineRecord } from '../../../records/routines/types/Routine.types';
import { ScheduleRecord } from '../../../records/routines/schedule/types/Schedule.types';
import { ensureRoutinesFeatureEnabled } from '../../../businessLogic/routines/routines';
import {
  getTimezone,
  isAccountRoutinesFlagEnabled,
} from '../../../selectors/stateSelectors/account';
import { getLicenseSubtype } from '../../../selectors/stateSelectors/license';
import {
  getUnfilteredRoutinesRecordsMap,
  getUnfilteredRoutinesList,
  getUnfilteredSchedulesRecordsMap,
  getRoutineUnfilteredSchedules,
} from './unfiltered';
import { localNow } from '../../../helpers/dates';

const routines = (state: State) => state.get('routines');

export const getIsOpenMenu = (state: State): boolean =>
  routines(state).get('isOpenMenu');

export const getActiveMenuStep = (state: State): string | null =>
  routines(state).get('activeMenuStep');

export const getRoutinesStatus = (state: State) =>
  routines(state).get('routinesStatus');

export const getRoutineDetailsStatus = (state: State) =>
  routines(state).get('routineDetailsStatus');

export const isRoutinesFeatureEnabled = createSelector(
  isAccountRoutinesFlagEnabled,
  getLicenseSubtype,
  (accountHasRoutines, licenseSubtype) =>
    ensureRoutinesFeatureEnabled(accountHasRoutines, licenseSubtype)
);

export const hasReportedRoutines = (state: State) =>
  getUnfilteredRoutinesList(state).size > 0;

/** Returns only enabled routines Map */
export const getRoutinesRecordsMap = createSelector(
  getUnfilteredRoutinesRecordsMap,
  routinesMap =>
    routinesMap.reduce((acc, routine) => {
      if (!isRoutineEnabled(routine)) return acc;
      return acc.set(routine.uid, routine);
    }, Map<string, RoutineRecord>({}))
);

export const getRoutine = (state: State, routineUid: string) =>
  getUnfilteredRoutinesRecordsMap(state).get(routineUid);

/** Returns all enabled routines List */
export const getAllRoutinesList = createSelector(
  getRoutinesRecordsMap,
  routinesMap => routinesMap.valueSeq().toList()
);

export const getUnPausedRoutinesList = createSelector(
  getRoutinesRecordsMap,
  routinesMap =>
    routinesMap
      .reduce((acc, routine) => {
        if (isRoutinePaused(routine)) return acc;
        return acc.set(routine.uid, routine);
      }, Map<string, RoutineRecord>({}))
      .valueSeq()
      .toList()
);

export const getRoutinesColorsMap = (state: State) =>
  getUnfilteredRoutinesList(state).reduce(
    (acc, { uid, color }) => acc.set(uid, color),
    Map<string, RoutineColor>({})
  );

export const getActiveRoutine = (state: State, profileId: string) => {
  const emptyResponse = {
    activeRoutine: null,
    activeSchedule: null,
  };

  const activeRoutineUid = getProfileActiveRoutineUid(state, profileId);
  if (!activeRoutineUid) return emptyResponse;

  const now = localNow(getTimezone(state));
  const routineSchedules = getRoutineUnfilteredSchedules(
    state,
    activeRoutineUid
  );
  const activeSchedule = getActiveScheduleFromSchedulesList(
    routineSchedules,
    now
  );
  if (!activeSchedule) return emptyResponse;

  const activeRoutine = getRoutine(state, activeRoutineUid);

  return { activeRoutine, activeSchedule };
};

export const getRoutineMode = (state: State) => routines(state).get('mode');

export const getEditedRoutineUid = (state: State) =>
  routines(state).get('editedRoutine');

export const hasEditRoutinePath = createSelector(
  getLocationPathname,
  pathname => new RegExp(/\/routines\/edit\//).test(pathname)
);

export const getRoutineMachineAction = (state: State) =>
  routines(state).get('machineAction');

// Schedules
export const getSchedulesStatus = (state: State) =>
  routines(state).get('schedulesStatus');

export const getScheduleDetailsStatus = (state: State) =>
  routines(state).get('scheduleDetailsStatus');

export const getSchedulesRecordsMap = (state: State) =>
  getUnfilteredSchedulesRecordsMap(state).reduce((acc, schedule) => {
    if (isRoutineScheduleOverridden(schedule)) return acc;
    return acc.set(schedule.uid, schedule);
  }, Map<string, ScheduleRecord>({}));

export const getRoutineSchedules = (state: State, routineUid: string) =>
  getSchedulesRecordsMap(state)
    .valueSeq()
    .filter(schedule => schedule.routineUid === routineUid)
    .toList();

export const getRoutineSchedule = (
  state: State,
  routineUid: string,
  routineScheduleUid: string
) =>
  getSchedulesRecordsMap(state).find(
    schedule =>
      schedule.uid === routineScheduleUid && schedule.routineUid === routineUid
  );

export const getAllSchedulesList = (state: State) =>
  getSchedulesRecordsMap(state).valueSeq().toList();

export const getAllUnPausedRoutinesSchedules = (state: State) => {
  const routinesUids = getUnPausedRoutinesList(state)
    .map(routine => routine.uid)
    .toArray();
  return getAllSchedulesList(state).filter(schedule =>
    routinesUids.includes(schedule.routineUid)
  );
};

export const getTempRoutine = (state: State) =>
  routines(state).get('tempRoutine');
