import { DeviceMdm, DeviceMdmPayload } from './types/DeviceMdm.types';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { DeviceMdmStatusRecordOperations } from './deviceMdmStatus';

export const DeviceMdmRecordOperations = getRecordOperations<
  DeviceMdm,
  DeviceMdmPayload
>({
  defaultFields: {
    status: DeviceMdmStatusRecordOperations.create(),
  },
  recordDependencies: [
    {
      field: 'status',
      dependencyRecordOperations: DeviceMdmStatusRecordOperations,
    },
  ],
});
