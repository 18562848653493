import {
  SyntheticUnifiedNowCardState,
  UnifiedNowCardState,
} from '../../businessLogic/unifiedNowCard/types';
import type {
  UnifiedNowCardCancelUpdateOnFinishStateAction,
  UnifiedNowCardLoadedStateAction,
  UnifiedNowCardRefreshAction,
  UnifiedNowCardRequestAction,
  UnifiedNowCardStatus,
  UnifiedNowCardUpdateOnFinishStateAction,
  UnifiedNowCardUpdateStatusAction,
} from './types';

export const loadNowCardAction = (
  profileId: number
): UnifiedNowCardRequestAction => ({
  type: 'NOW_CARD_REQUEST',
  payload: { profileId },
});

export const refreshNowCardAction = (
  profileId: number
): UnifiedNowCardRefreshAction => ({
  type: 'NOW_CARD_REFRESH',
  payload: { profileId },
});

export const updateStatusNowCardAction = (
  status: UnifiedNowCardStatus
): UnifiedNowCardUpdateStatusAction => ({
  type: 'NOW_CARD_UPDATE_STATUS',
  payload: { status },
});

export const loadedStateNowCardAction = (
  state: UnifiedNowCardState | SyntheticUnifiedNowCardState
): UnifiedNowCardLoadedStateAction => ({
  type: 'NOW_CARD_LOADED_STATE',
  payload: { state },
});

export const updateNowCardOnFinishState = (
  profileId: number,
  durationMilliseconds: number
): UnifiedNowCardUpdateOnFinishStateAction => ({
  type: 'UPDATE_NOW_CARD_STATE',
  payload: { profileId, durationMilliseconds },
});

export const cancelUpdateNowCardOnFinishState =
  (): UnifiedNowCardCancelUpdateOnFinishStateAction => ({
    type: 'CANCEL_UPDATE_NOW_CARD_STATE',
  });
