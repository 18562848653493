import { connect } from 'react-redux';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import SchoolsOnboardingError from '../../components/Schools/SchoolsOnboardingError';
import { SchoolsOnboardingErrorContentDispatchProps } from '../../components/Schools/SchoolsOnboardingErrorContent';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import { trackOpenedBurgerMenu } from '../../helpers/analytics';
import { createMySchoolEmailHref } from '../../helpers/email';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { t } from '../../lib/i18n';
import store from '../../store';

const mapStateToProps = () => ({});

const mapDispatchToProps = (): SchoolsOnboardingErrorContentDispatchProps => ({
  onClickContactUs: (e: Event) => {
    const navigate = getMultiPlatformNavigation(e);
    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(
        t('Error school onboarding connecting child')
      ),
    });
  },
});

const SchoolsOnboardingErrorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolsOnboardingError);

export default OnboardingWrapper({
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(SchoolsOnboardingErrorContainer);
