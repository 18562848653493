// Generate a secure random string using the browser crypto functions
export const generateRandomString = () => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => `0${dec.toString(16)}`.substr(-2)).join('');
};

// Base64-urlencodes the input string
export const base64urlencode = (buffer: ArrayBuffer | string) =>
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  ((buffer as ArrayBuffer).byteLength
    ? btoa(
        String.fromCharCode.apply(null, new Uint8Array(buffer as ArrayBuffer))
      )
    : btoa(buffer as string)
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

export const base64urldecode = (dataInBase64url: string) => {
  let dataInBase64 = dataInBase64url.replace(/-/g, '+').replace(/_/g, '/');

  while (dataInBase64.length % 4 !== 0) {
    dataInBase64 += '=';
  }

  return atob(dataInBase64);
};
