import { connect } from 'react-redux';

import ActivityTimelinePage from '../../components/ActivityTimeline/ActivityTimelinePage';
import State, {
  RouterParamsProps,
  Dispatch,
  RouterParams,
  BaseThunk,
} from '../../store/state';
import {
  getProfileOrDefault,
  isFetchingNewEvents,
  getLastReceiveEventsTimestamp,
  getIsEmptyTimeline,
  isFetchingEvents,
  isProfileLinkedWithLinewizeStudent,
  getKidTamperedDevices,
  getActivityTimelineFilterBy,
} from '../../selectors';
import {
  shouldFetchMoreEvents,
  showEventActions,
  fetchEvents,
  fetchNewEvents,
} from '../../ducks/events';
import { updateCurrentTime } from '../../ducks/times';
import { redirectToAddDeviceIfNoDeviceAssignedTo } from '../../actions/ProfileActions';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { GenericPageNames, trackPageLoaded } from '../../helpers/analytics';
import * as debounce from 'lodash.debounce';
import { getTimelineFilterTrackingParams } from '../TimelineSelector/TimelineSelectorTracking';
import { navigateToActivityTimelineDelegationInfoModal } from '../../actions/Navigation';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const hasDelegationEnabled = isDelegationEnabled(state);
  const delegatedToThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  const isDelegatedToTheAccount = isProfileDelegatedToThisAccount(
    hasDelegationEnabled,
    delegatedToThisAccount,
    isProfileLinkedWithLinewizeStudent(state, profileId)
  );
  return {
    isFetching: isFetchingEvents(state),
    isEmpty:
      getIsEmptyTimeline(state) &&
      !isProfileLinkedWithLinewizeStudent(state, profileId),
    isFetchingNewEvents: isFetchingNewEvents(state),
    profile: getProfileOrDefault(state, parseInt(profileId, 10)),
    shouldShowAlertTampered: getKidTamperedDevices(state, profileId).length > 0,
    isDelegatedToTheAccount,
  };
};

const trackOnLoadPageThunk = (): BaseThunk =>
  debounce((_dispatch, getState) => {
    const filter = getActivityTimelineFilterBy(getState());

    trackPageLoaded(
      GenericPageNames.Timeline,
      getTimelineFilterTrackingParams(filter)
    );
  }, 1000);

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  onRefreshExistentEvents: () => {
    dispatch((_dispatch, getState) => {
      const profile = getProfileOrDefault(getState(), parseInt(profileId, 10));
      _dispatch(updateCurrentTime());
      _dispatch(fetchNewEvents(profile.uid));
    });
  },
  onFetchingMoreEvents: () =>
    dispatch(shouldFetchMoreEvents(parseInt(profileId, 10))),
  onload: () => dispatch(trackOnLoadPageThunk()),
  onDelegationInfoClick: () => {
    dispatch(navigateToActivityTimelineDelegationInfoModal());
  },
});

const ActivityTimelinePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTimelinePage);

ActivityTimelinePageContainer.load =
  ({ profileId }: RouterParams) =>
  (dispatch, getState) => {
    dispatch(showEventActions(undefined));

    const state = getState();
    const profile = getProfileOrDefault(state, parseInt(profileId, 10));
    const lastReceiveEventsAt = getLastReceiveEventsTimestamp(state);
    const { action } = state.getIn(['routing', 'locationBeforeTransitions']);

    const fiveSecondsPassedSinceLastEventsReceived = lastReceiveEventsAt
      ? Date.now() > lastReceiveEventsAt + 5000
      : true;

    // do not refresh if you are comming back from details
    if (fiveSecondsPassedSinceLastEventsReceived && action !== 'POP') {
      dispatch(updateCurrentTime());
      dispatch(fetchEvents(profile.uid));
    }
  };

ActivityTimelinePageContainer.redirect =
  (params: RouterParams): BaseThunk =>
  dispatch => {
    dispatch(redirectToAddDeviceIfNoDeviceAssignedTo(params.profileId));
    return Promise.resolve();
  };

export default ActivityTimelinePageContainer;
