import { Record } from 'immutable';
import * as R from 'ramda';
import { t } from '../lib/i18n';
import { safeParseJSON, isNonEmptyString } from '../helpers/string';
import { isNotNilOrEmpty } from '../helpers';
import { captureException } from '../helpers/sentry';

export enum MapStates {
  EMPTY,
  LOADED,
  ERROR,
}

export const STATUS_NONE = Symbol('none');
export const STATUS_PENDING = Symbol('pending');
export const STATUS_AVAILABLE = Symbol('available');
export type Status =
  | typeof STATUS_NONE
  | typeof STATUS_PENDING
  | typeof STATUS_AVAILABLE;

export type MapImage = Readonly<{
  url: string | null;
  state: MapStates;
}>;

export const MapImage: (values?: {
  url?: string;
  state: MapStates;
}) => MapImage = <any>Record({
  url: null,
  state: MapStates.EMPTY,
});

export enum LocationTypes {
  ENTER_PLACE = 10,
  LEAVE_PLACE = 11,
  PERMISSIONS_REVOKED = 12,
  LOCATION_SERVICES_DISABLED = 13,
}

export const irregularLocationTypes = [
  LocationTypes.PERMISSIONS_REVOKED,
  LocationTypes.LOCATION_SERVICES_DISABLED,
];

/**
 * Create MapImage record with state inferred from argument.
 * String is a successfully loaded url
 * Error is an error
 * Nil is empty
 */
export const makeMapImage = R.cond([
  [R.is(Error), R.always(MapImage({ state: MapStates.ERROR }))],
  [R.isNil, R.always(MapImage({ state: MapStates.EMPTY }))],
  [R.T, url => MapImage({ url, state: MapStates.LOADED })],
]);

export const isLoadedMap: (r: LocationRecord) => boolean = R.propEq(
  'state',
  MapStates.LOADED
);

type Location = Readonly<{
  longitude: number;
  latitude: number;
  accuracy: number;
  addressLines: string[];
  locality: string;
  deviceId: string;
  time: any;
  map: MapImage;
  type: number;
  placeName: string;
}>;

export type LocationRecord = Record<Location>;
export const LocationRecord = Record<
  Location,
  {
    fromEventResponse: (response: any) => LocationRecord;
    fromProfileResponse: (response: any) => LocationRecord;
    fromDeviceResponse: (response: any) => LocationRecord;
  }
>({
  longitude: 0,
  latitude: 0,
  accuracy: 0,
  addressLines: [],
  locality: '',
  deviceId: '',
  time: null,
  map: MapImage(),
  type: 0,
  placeName: '',
});

LocationRecord.fromEventResponse = response => {
  const latitude = response.latitude || response.locationLatitude;
  const longitude = response.longitude || response.locationLongitude;
  const accuracy = response.locationAccuracy;
  const type = response.locationType;
  const { placeName } = response;
  const [addressLines, locality] = parseAddressEvent(response);

  const { deviceId, dt: time } = response;
  return LocationRecord({
    longitude,
    latitude,
    accuracy,
    addressLines,
    locality,
    deviceId,
    time,
    map: MapImage(),
    type,
    placeName,
  });
};

LocationRecord.fromProfileResponse = response => {
  const location =
    response.status && response.status.location ? response.status.location : {};
  const { latitude, longitude, accuracy, device, time, type } = location;
  const { placeName } = response;
  const [addressLines, locality] = parseAddressProfileLocation(location);
  return LocationRecord({
    longitude,
    latitude,
    accuracy,
    addressLines,
    locality,
    // Due to a backend bug (BCK-5583) we neeed to adapt the property name.
    // Once it gets fixed this "hack" will be removed
    deviceId: device,
    time,
    map: MapImage(),
    type,
    placeName,
  });
};

LocationRecord.fromDeviceResponse = response => {
  const [addressLines, locality] = parseAddressDeviceLocation(response);
  return LocationRecord({
    longitude: response.locationLongitude,
    latitude: response.locationLatitude,
    time: response.locationTime,
    deviceId: response.id,
    locality,
    addressLines,
    map: MapImage(),
    type: response.locationType,
  });
};

const parseAddressProp = propName =>
  R.pipe(
    R.ifElse(
      R.both(R.is(Object), R.has(propName)),
      R.pipe(
        R.prop(propName),
        R.ifElse(
          isNonEmptyString,
          safeParseJSON((error, jsonString) =>
            captureException(error, {
              extra: {
                value: jsonString,
              },
            })
          ),
          R.always(undefined)
        ),
        extractAddressLines
      ),
      R.always(undefined)
    ),
    <(arg: any) => [string[], string]>R.defaultTo([[], ''])
  );

const extractAddressLines = R.pipe(
  <(o: LocationRecord | undefined) => [string[], string] | undefined>R.cond([
    [R.complement(R.is(Object)), R.always(undefined)],
    [R.propIs(Array, 'addressLines'), R.props(['addressLines', 'locality'])],
    [
      R.propIs(String, 'addressLines'),
      (address: LocationRecord) => [[address.addressLines], address.locality],
    ],
    [R.T, R.always(undefined)],
  ]),
  address =>
    address === undefined
      ? undefined
      : R.over(R.lensIndex(1), R.defaultTo(''))(address)
);

const parseAddressEvent = parseAddressProp('locationAddress');
const parseAddressProfileLocation = parseAddressProp('address');
const parseAddressDeviceLocation = parseAddressProp('locationAddress');

const hasLatLng = (location: LocationRecord) => {
  return !(
    R.either(R.isEmpty, R.isNil)(location.latitude) ||
    R.either(R.isEmpty, R.isNil)(location.longitude)
  );
};

export const isValidLatLng = (location: LocationRecord) =>
  hasLatLng(location) && ![location.latitude, location.longitude].includes(-1);

export const hasPendingMap = R.both(
  R.pipe<LocationRecord, MapImage, boolean>(
    R.prop('map'),
    R.complement(isLoadedMap)
  ),
  hasLatLng
);

export const getFormattedAddress = (location: LocationRecord) => {
  if (location.addressLines.length === 0) return t('Address is unavailable');
  if (!R.isEmpty(location.locality))
    return `${location.addressLines[0]}, ${location.locality}`;
  return location.addressLines[0];
};

export const isRegularStatusLocationEvent = (
  location: LocationRecord
): boolean => !irregularLocationTypes.includes(location.type);

export const hasValidAddress = (location: LocationRecord) =>
  getAddressStatus(location) === STATUS_AVAILABLE;

export const getAddressStatus = (location: LocationRecord) => {
  if (isNotNilOrEmpty(location.addressLines[0])) {
    return STATUS_AVAILABLE;
  }

  if (location.latitude === null) {
    return STATUS_NONE;
  }

  return STATUS_PENDING;
};

export default LocationRecord;
