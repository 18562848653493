import { ofType } from 'redux-observable';
import { LocationChangeAction, LOCATION_CHANGE } from 'react-router-redux';
import { filter, distinctUntilChanged, pairwise, map } from 'rxjs/operators';
import { pipe, is } from 'ramda';
import {
  ObservableOperator,
  AppAction,
  ActionLocationPred,
  AppStateObservable,
} from './types';
import { getRouteParams } from '../selectors';
import { RouteParams } from '../ducks/routing';

export type NavigationParams = Partial<Record<RouteParams, string>>;

export const isPathName =
  (pathRegExp: RegExp) => (action: LocationChangeAction) =>
    pathRegExp.test(action.payload.pathname);

export const isProfilePage = isPathName(/profiles\/[0-9]*(\/?)$/);

export const isProfileSubPage = isPathName(/profiles\/[0-9].*/);

export const isStudentSummaryPage = (action: LocationChangeAction) => {
  const profileRegExp = /school\/summary*(\/?)$/; // redux routes sometimes use trailing slash
  return profileRegExp.test(action.payload.pathname);
};

export const isStudentSummarySubPage = (action: LocationChangeAction) => {
  const profileRegExp = /school\/summary.*/;
  return profileRegExp.test(action.payload.pathname);
};

export const isSchoolTimelinePage = (action: LocationChangeAction) => {
  return /school\/timeline(\/?)$/.test(action.payload.pathname);
};

export const isSchoolTimelineSubpage = (action: LocationChangeAction) => {
  return /school\/timeline.*(\/?)$/.test(action.payload.pathname);
};

export const isSchoolSignaturePage = (action: LocationChangeAction) => {
  return /school\/signature.*(\/?)$/.test(action.payload.pathname);
};

export const isSchoolTimelineDetailPage = (action: LocationChangeAction) => {
  return /school\/timeline\/details.*(\/?)$/.test(action.payload.pathname);
};

export const isProfileFamilySchoolPage = (action: LocationChangeAction) => {
  const profileRegExp = /profiles\/-?[0-9]+\/family-and-school*(\/?)$/; // redux routes sometimes use trailing slash
  return profileRegExp.test(action.payload.pathname);
};

export const isProfileFamilySchoolSubPage = (action: LocationChangeAction) => {
  const profileRegExp = /profiles\/-?[0-9]+\/family-and-school.*/;
  return profileRegExp.test(action.payload.pathname);
};

export const isTimelinePage = (action: LocationChangeAction) => {
  const timelineRegExp = /profiles\/-?\d+\/activity-timeline.*/;
  return timelineRegExp.test(action.payload.pathname);
};

export const isProfileListPage = (action: LocationChangeAction) => {
  const profileListRegExp = /profiles\/?$/;
  return profileListRegExp.test(action.payload.pathname);
};

export const ofLocationChangeAction: ObservableOperator<
  AppAction,
  LocationChangeAction
> = pipe(
  ofType(LOCATION_CHANGE),
  distinctUntilChanged<LocationChangeAction>(
    (curr, next) => curr.payload.pathname === next.payload.pathname
  )
);

type ofLocationChangeActionWhenParams =
  | {
      from: ActionLocationPred;
      to: ActionLocationPred;
    }
  | ((from: LocationChangeAction, to: LocationChangeAction) => boolean);

/*
This operator is very useful when you want filter navigation depends of navigation flow.
The action will only be emitted if both predicates (from and to) are true.
*/
export const ofLocationChangeActionWhen = (
  handler: ofLocationChangeActionWhenParams
) =>
  pipe(
    ofLocationChangeAction,
    pairwise<LocationChangeAction>(),
    filter(([prev, current]) => {
      if (typeof handler === 'function') {
        return handler(prev, current);
      }

      return handler.from(prev) && handler.to(current);
    })
  );

export const onLocation = (pathPredOrRegexp: RegExp | ActionLocationPred) => {
  const pred = is(Function, pathPredOrRegexp)
    ? pathPredOrRegexp
    : isPathName(pathPredOrRegexp as RegExp);
  return pipe(ofLocationChangeAction, filter(pred as ActionLocationPred));
};

/*
  In the current implementation the parameters are set in the state by redux-route, 
  this may change with another routing system.
*/

export const toRouterParams = (
  state$: AppStateObservable
): ObservableOperator<AppAction, NavigationParams> =>
  map(() => getRouteParams(state$.value).toJS());

/**
 *
 * Get an observable with router params in each location change.
 * This operator the way to decouple from the router and its state, the purpose of using this operator is to have a facade.
 *
 * IMPORTANT
 *
 * 1. If need new router params must to add to type RouteParams defined in duck routing.
 */
export const routerParams = (
  state$: AppStateObservable
): ObservableOperator<AppAction, NavigationParams> =>
  pipe(ofType(LOCATION_CHANGE), toRouterParams(state$));
