import * as React from 'react';
import Swiper from 'react-id-swiper';
import {
  FlexLayout,
  Layout,
  IconType,
  StyledHeader,
  Button,
  Icon,
  ButtonIconPosition,
  Card,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import PullRefresh from '../PullRefresh/PullRefresh';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import SpeedDial from '../SpeedDial/SpeedDial';
import { SummaryDateRanges, CardDimensions } from '../../constants';

import ActivitySummaryListContainer from '../../containers/ActivitySummary/ActivitySummaryListContainer';
import RenderWhen, {
  ScreenSize,
  hasScreenSize,
} from '../RenderWhen/RenderWhen';
import { track, Events } from '../../helpers/analytics';
import YoutubeSummaryCardContainer from '../../containers/Summary/YoutubeSummaryCardContainer';
import LoadingActivityPlaceholder from '../LoadingActivityPlaceholder/LoadingActivityPlaceholder';
import { ActivityType } from '../ActivitySummary/sharedModels';
import InsightButton from '../Insights/InsightButton';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import ScrollManager from '../Layout/hooks/useKeepScroll/ScrollManager';
import { captureException } from '../../helpers/sentry';
import { RouterAction } from '../../helpers/multiPlatformNavigation';
import ProfileNotificationTopBarContainer from '../../containers/ProfileNotificationTopBar/ProfileNotificationTopBarContainer';
import { RouterParams } from '../../store/state';
import { EventRecord } from '../../records';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import UnifiedNowCardContainer from '../../containers/NowCard/UnifiedNowCard/UnifiedNowCardContainer';
import SmartDevicesStatusContainer from '../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';
import ProfileDashboardTopCardsContainer from '../../containers/Dashboard/DashboardTopCards/ProfileDashboardTopCards';
import LazyImage from '../LazyImage/LazyImage';
import InfoItem from '../FullPremiumFlyover/InfoItem';

const scrollKey = ScrollManager.getInstance().register('profileFamilySchool');

class ProfileFamilySchool extends React.Component<
  {
    profile: ProfileRecord;
    isFetching: boolean;
    isFetchingNewEvents: boolean;
    activeDateRange: SummaryDateRanges;
    appRuleEvent: EventRecord | undefined;
    callsSMSEvent: EventRecord | undefined;
    webfilterEvent: EventRecord | undefined;
    youtubeEvent: EventRecord | undefined;
    panicEvent: EventRecord | undefined;
    isMenuOpen: boolean;
    showStatsNotification: boolean;
    showYoutubeCard: boolean;
    showCallsSMSCard: boolean;
    showInsights: boolean;
    isQuestionableWebSearchesFeactureActive: boolean;
    activitySwiperIndex: number;
    hasTamperedDevice: boolean;
    showProfileSummarySelector: boolean;
    routerParams: RouterParams;
    showDelegationNoActivity: boolean;
    handleMore: () => void;
    handleRefresh: () => void;
    onClickEdit: () => RouterAction;
    onSelectDateRange: (range: SummaryDateRanges) => any;
    // Floating button + menu
    onToggleMenu: () => any;
    onClickAddDevices: () => any;
    onClickSpeedDialAddDevices: () => any;
    onClickSpeedDialSeeTimeline: () => void;
    onClickRules: () => any;
    // Events Action Sheets
    onAppRuleActionSheetClick: () => any;
    onCallSmsActionSheetClick: () => any;
    onWebActionSheetClick: () => any;
    onPanicActionSheetClick: () => any;
    onClickGoBack: () => any;
  },
  {
    scrollToTopVisible: boolean;
  }
> {
  swiperRef = React.createRef<any>();

  constructor(props, context?) {
    super(props, context);
    this.state = {
      scrollToTopVisible: false,
    };
  }

  render() {
    const {
      profile,
      isFetching,
      isFetchingNewEvents,
      activeDateRange,
      isMenuOpen,
      handleMore,
      handleRefresh,
      onClickEdit,
      onToggleMenu,
      onClickSpeedDialAddDevices,
      onClickSpeedDialSeeTimeline,
      onClickRules,
      onClickGoBack,
      onSelectDateRange,
      activitySwiperIndex,
      showYoutubeCard,
      showCallsSMSCard,
      showInsights,
      isQuestionableWebSearchesFeactureActive,
      showProfileSummarySelector,
      routerParams,
      showDelegationNoActivity,
    } = this.props;
    const { scrollToTopVisible } = this.state;

    const onScroll = ({ target }) => {
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        handleMore();
      }

      const isOverTheTop = target.scrollTop > window.innerHeight;
      if (isOverTheTop !== scrollToTopVisible) {
        this.setState({ scrollToTopVisible: isOverTheTop });
      }
    };

    const swiperOptions = {
      pagination: {
        el: '.swiper-pagination',
      },
      initialSlide: activitySwiperIndex,
      on: {
        slideChange: () => {
          const swiperWrapper = this.swiperRef.current;
          try {
            if (swiperWrapper?.props?.children && swiperWrapper?.swiper) {
              const activityCardIndex = swiperWrapper.swiper.realIndex;
              const activityCard =
                swiperWrapper.props.children[activityCardIndex];
              const activityCardTrackableKey =
                activityCard.props['data-trackable-key'];

              track(Events.SwipedActivityCard, {
                page: activityCardTrackableKey,
                category: 'Activity cards',
              });
            }
          } catch (err) {
            captureException(err, {
              extra: {
                message:
                  'ActivitySummary Swiper slideChange() function has failed',
              },
            });
          }
        },
      },
      spaceBetween: 10,
      slidesPerView: 'auto',
      centeredSlides: true,
    };

    const TimelineButton = (
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        marginTop="32"
        marginBottom="16"
        width="100%"
      >
        <Button
          centered
          icon={<Icon type={IconType.chevronRight} />}
          iconPosition={ButtonIconPosition.right}
          onClick={onClickSpeedDialSeeTimeline}
        >
          {t('View timeline')}
        </Button>
      </FlexLayout>
    );

    const content = (
      <React.Fragment>
        <FlexLayout mainaxis="column">
          {isFetching ? (
            <LoadingActivityPlaceholder />
          ) : (
            <React.Fragment>
              <RenderWhen screenSize={ScreenSize.Desktop}>
                <FlexLayout mainaxis="column">
                  <FlexLayout mainaxis="row">
                    <ProfileDashboardTopCardsContainer profileId={profile.id} />
                  </FlexLayout>
                  <FlexLayout mainaxis="row">
                    <Layout margin="8">
                      <ActivitySummaryListContainer
                        data-testid="blockedWebActivitySummaryCard"
                        type={ActivityType.BlockedWebs}
                        profile={profile}
                        width={CardDimensions.width}
                        height={CardDimensions.height}
                      />
                    </Layout>
                    <Layout margin="8">
                      <ActivitySummaryListContainer
                        type={ActivityType.AppsAndWebActivity}
                        profile={profile}
                        data-testid="topActivitySummaryCard"
                        width={CardDimensions.width}
                        height={CardDimensions.height}
                      />
                    </Layout>
                  </FlexLayout>
                  <FlexLayout mainaxis="row">
                    <Layout margin="8">
                      <ActivitySummaryListContainer
                        data-testid="searchActivitySummaryCard"
                        type={
                          isQuestionableWebSearchesFeactureActive
                            ? ActivityType.QuestionableWebSearches
                            : ActivityType.Search
                        }
                        profile={profile}
                        width={CardDimensions.width}
                        height={CardDimensions.height}
                      />
                    </Layout>
                    <Layout margin="8">
                      {showCallsSMSCard && (
                        <ActivitySummaryListContainer
                          data-testid="callsAndSmsActivitySummaryCard"
                          type={ActivityType.CallsAndSms}
                          profile={profile}
                          width={CardDimensions.width}
                          height={CardDimensions.height}
                        />
                      )}
                    </Layout>
                  </FlexLayout>

                  {showYoutubeCard && (
                    <FlexLayout mainaxis="row">
                      <Layout margin="8">
                        <YoutubeSummaryCardContainer
                          profile={profile}
                          needsBorder
                          width={CardDimensions.width}
                        />
                      </Layout>
                    </FlexLayout>
                  )}
                </FlexLayout>
              </RenderWhen>
              <RenderWhen screenSize={ScreenSize.Tablet}>
                <div>
                  <FlexLayout mainaxis="row" marginBottom="16">
                    <ProfileDashboardTopCardsContainer profileId={profile.id} />
                  </FlexLayout>

                  <Swiper {...swiperOptions} ref={this.swiperRef}>
                    <div data-trackable-key="Top Activity Card">
                      <FlexLayout mainaxis="row">
                        <Layout width="50%" marginRight="16" marginLeft="16">
                          <ActivitySummaryListContainer
                            type={ActivityType.AppsAndWebActivity}
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        <Layout width="50%" marginRight="16" marginLeft="16">
                          <ActivitySummaryListContainer
                            data-testid="blockedWebActivitySummaryCard"
                            type={ActivityType.BlockedWebs}
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                      </FlexLayout>
                    </div>
                    <div data-trackable-key="Youtube Activity Card">
                      <FlexLayout mainaxis="row">
                        <Layout width="50%" marginRight="16" marginLeft="16">
                          <ActivitySummaryListContainer
                            data-testid="searchActivitySummaryCard"
                            type={
                              isQuestionableWebSearchesFeactureActive
                                ? ActivityType.QuestionableWebSearches
                                : ActivityType.Search
                            }
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                        {showYoutubeCard && (
                          <Layout width="50%" marginRight="16" marginLeft="16">
                            <YoutubeSummaryCardContainer profile={profile} />
                          </Layout>
                        )}
                        {!showYoutubeCard && showCallsSMSCard && (
                          <Layout width="50%" marginRight="16" marginLeft="16">
                            <ActivitySummaryListContainer
                              data-testid="callsAndSmsActivitySummaryCard"
                              type={ActivityType.CallsAndSms}
                              profile={profile}
                              height={CardDimensions.height}
                            />
                          </Layout>
                        )}
                      </FlexLayout>
                    </div>
                    {showYoutubeCard && showCallsSMSCard && (
                      <div data-trackable-key="Calls and SMS Activity Card">
                        <FlexLayout mainaxis="row">
                          <Layout width="50%" marginRight="16" marginLeft="16">
                            <ActivitySummaryListContainer
                              data-testid="callsAndSmsActivitySummaryCard"
                              type={ActivityType.CallsAndSms}
                              profile={profile}
                              height={CardDimensions.height}
                            />
                          </Layout>
                        </FlexLayout>
                      </div>
                    )}
                  </Swiper>
                  {showInsights && <InsightButton />}
                  {TimelineButton}
                </div>
              </RenderWhen>
              <RenderWhen screenSize={ScreenSize.Mobile}>
                <div>
                  <Layout marginBottom="16">
                    <FlexLayout mainaxis="row">
                      <ProfileDashboardTopCardsContainer
                        profileId={profile.id}
                      />
                    </FlexLayout>
                  </Layout>
                  <Swiper {...swiperOptions} ref={this.swiperRef}>
                    <div data-trackable-key="Top Activity Card">
                      <Layout marginLeft="16" marginRight="16">
                        <ActivitySummaryListContainer
                          type={ActivityType.AppsAndWebActivity}
                          profile={profile}
                          height={CardDimensions.height}
                        />
                      </Layout>
                    </div>
                    <div data-trackable-key="Blocked Web Activity Card">
                      <Layout marginLeft="16" marginRight="16">
                        <ActivitySummaryListContainer
                          data-testid="blockedWebActivitySummaryCard"
                          type={ActivityType.BlockedWebs}
                          profile={profile}
                          height={CardDimensions.height}
                        />
                      </Layout>
                    </div>
                    {showYoutubeCard && (
                      <div data-trackable-key="Youtube Activity Card">
                        <Layout marginLeft="16" marginRight="16">
                          <YoutubeSummaryCardContainer profile={profile} />
                        </Layout>
                      </div>
                    )}

                    <div data-trackable-key="Search Activity Card">
                      <Layout marginLeft="16" marginRight="16">
                        <ActivitySummaryListContainer
                          data-testid="searchActivitySummaryCard"
                          type={
                            isQuestionableWebSearchesFeactureActive
                              ? ActivityType.QuestionableWebSearches
                              : ActivityType.Search
                          }
                          profile={profile}
                          height={CardDimensions.height}
                        />
                      </Layout>
                    </div>

                    {showCallsSMSCard && (
                      <div data-trackable-key="Calls and SMS Activity Card">
                        <Layout marginLeft="16" marginRight="16">
                          <ActivitySummaryListContainer
                            data-testid="callsAndSmsActivitySummaryCard"
                            type={ActivityType.CallsAndSms}
                            profile={profile}
                            height={CardDimensions.height}
                          />
                        </Layout>
                      </div>
                    )}
                  </Swiper>
                  {showInsights && <InsightButton />}
                  {TimelineButton}
                </div>
              </RenderWhen>
            </React.Fragment>
          )}
        </FlexLayout>
      </React.Fragment>
    );

    const delegationEmptyContentItems = [
      {
        iconType: IconType.hourglassHalf,
        title: t('Screen time'),
        subtitle: t('Check how long your child spends on their device'),
      },
      {
        iconType: IconType.icons,
        title: t('Most visited apps & websites'),
        subtitle: t('Keep an eye on the content your child can access'),
      },
      {
        iconType: IconType.search,
        title: t('Most visited apps & websites'),
        subtitle: t('Keep an eye on the content your child can access'),
      },
    ];

    const delegationEmptyContent = (
      <RenderWhen screenSize={[ScreenSize.Mobile, ScreenSize.TabletOrDesktop]}>
        {screenSize => (
          <PageSectionLayout side="main">
            <Card>
              <FlexLayout
                mainaxis="column"
                mainaxisAlignment="space-between"
                crossaxisAlignment="center"
                testId=""
              >
                <Layout
                  marginBottom="24"
                  textAlignement="center"
                  minHeight="120"
                >
                  <LazyImage
                    src="/illustrations/DelegationEmptyDashboard/activity_illustration.png"
                    width="130px"
                    alt="activity icon"
                  />
                </Layout>

                <Layout textAlignement="center">
                  <Typography type="h4" weight="semibold">
                    <span>{t('You’re connected to your school')}</span>
                  </Typography>
                  <Typography type="body1">
                    <span>
                      {t(
                        'When we detect activity on {{profileName}}’s school-issued device, you’ll see a summary here showing:',
                        {
                          profileName: profile.name,
                        }
                      )}
                    </span>
                  </Typography>
                </Layout>

                <FlexLayout
                  mainaxis={screenSize === ScreenSize.Mobile ? 'column' : 'row'}
                  width={screenSize === ScreenSize.Mobile ? undefined : '100%'}
                  paddingTop="8"
                  paddingBottom="48"
                >
                  {delegationEmptyContentItems.map(item => (
                    <InfoItem
                      key={item.title}
                      orientation={
                        screenSize === ScreenSize.Mobile
                          ? 'vertical'
                          : 'horizontal'
                      }
                      title={item.title}
                      subtitle={item.subtitle}
                      iconType={item.iconType}
                      animDelay={0}
                    />
                  ))}
                </FlexLayout>
              </FlexLayout>
            </Card>
          </PageSectionLayout>
        )}
      </RenderWhen>
    );

    return (
      <PageLayout
        isProfileEditable
        configuration={[
          { screenSize: ScreenSize.Desktop, columns: 1, mainReducedSize: true },
          {
            screenSize: ScreenSize.MobileOrTablet,
            showProfile: true,
            showProfileSummarySelector,
            title: ' ',
            mainColumnBackgroundColor: 'gray-ligther',
            removePaddings: true,
          },
          {
            screenSize: ScreenSize.All,
            pullToRefresh: (
              <PullRefresh
                isFetching={isFetchingNewEvents}
                onRefresh={handleRefresh}
                onScroll={onScroll}
              />
            ),
          },
          {
            screenSize: ScreenSize.MobileOrTablet,
            keepScrollKey: scrollKey,
          },
        ]}
        actions={[
          {
            actionName: 'edit',
            onClick: onClickEdit,
            screenSize: ScreenSize.MobileOrTablet,
          },
          {
            actionName: 'back',
            onClick: onClickGoBack,
            screenSize: ScreenSize.MobileOrTablet,
          },
        ]}
      >
        {showDelegationNoActivity ? delegationEmptyContent : content}

        <PageSectionLayout placeholder="before-pullrefresh">
          <Layout
            marginBottom="16"
            marginLeft={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '8'}
            marginRight={hasScreenSize(ScreenSize.MobileOrTablet) ? '16' : '24'}
          >
            {!isFetching && (
              <Layout marginBottom="24">
                <ProfileNotificationTopBarContainer params={routerParams} />
                <Layout marginBottom="16">
                  <StyledHeader type="h4" testId="studentSummaryTitle">
                    {t("What's happening now")}
                  </StyledHeader>
                </Layout>
                <React.Fragment>
                  <Layout marginBottom="8">
                    <UnifiedNowCardContainer profileId={profile.id} />
                  </Layout>
                  <FlexLayout mainaxis="row">
                    <SmartDevicesStatusContainer
                      profileId={String(profile.id)}
                    />
                  </FlexLayout>
                </React.Fragment>
              </Layout>
            )}

            <StyledHeader type="h4" testId="studentSummaryTitle">
              {t('Activity summary')}
            </StyledHeader>
          </Layout>

          <Layout marginBottom="8" centerX={hasScreenSize(ScreenSize.Tablet)}>
            <DateRangeSelector
              activeRange={activeDateRange}
              onChangeDateRange={onSelectDateRange}
            />
          </Layout>
        </PageSectionLayout>

        <PageSectionLayout placeholder="after-pullrefresh">
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <SpeedDial
              isOpen={isMenuOpen}
              actions={[
                {
                  icon: IconType.settings,
                  label: t('Set rules'),
                  onClick: onClickRules,
                },
                {
                  icon: IconType.mobile,
                  label: t('Add a device'),
                  onClick: onClickSpeedDialAddDevices,
                },
                {
                  icon: IconType.listUl,
                  label: t('See timeline'),
                  onClick: onClickSpeedDialSeeTimeline,
                },
              ]}
              onToggleMenu={onToggleMenu}
            />
          </RenderWhen>
        </PageSectionLayout>
      </PageLayout>
    );
  }
}

export default ProfileFamilySchool;
