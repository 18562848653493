/* eslint-disable camelcase */
import * as queryString from 'query-string';
import * as moment from 'moment';
import * as R from 'ramda';
import {
  PaymentProvider,
  BuildPlatform,
  VideoSources,
  ERROR_4XX,
  ERROR_5XX,
} from '../constants/index';
import setTimeout from './timeout';
import {
  APIError,
  APITimeoutRequestError,
  APINetworkRequestError,
  APIAbortRequestError,
  STATUS_TIMEOUT,
  STATUS_NETWORK_ERROR,
  STATUS_ABORT_ERROR,
  STATUS_MISSING_TOKENS_ERROR,
} from './errors';
import { getBuildPlatform, getAppVersion } from '../helpers/globals';
import {
  ActivitySummaryFilters,
  ActivitySummaryOrderBy,
} from '../types/summary';
import { Method, RequestTransformerType } from '../types/api';
import { captureException } from '../helpers/sentry';
import { SignatureDetails } from '../records/studentActivity/signature/types/SignatureDetails.types';

type FetchParams = { [key: string]: string };
type PathId = string | number;
type UId = string;
type autoParams = {
  accountId?: PathId;
  accountUid?: UId;
  profileId?: PathId;
  parentDeviceId?: PathId;
};
type EndpointMethod<T> = {
  get: <R extends any>(params: T, query?: any) => Promise<R>;
  post: <R extends any>(body: any, query?: any, params?: T) => Promise<R>;
  put: <R extends any>(params: T, body: any, query?: any) => Promise<R>;
  patch: <R extends any>(params: T, body: any, query?: any) => Promise<R>;
  delete: <R extends any>(params: T, query?: any) => Promise<R>;
};

export type Endpoint<T extends Method, U> = { [P in T]: EndpointMethod<U>[P] };
export type QApiGlobals = {
  tokens: { accessToken: string; refreshToken: string };
  clientCredentials: { client_id: string; client_secret: string };
  saveTokensCallback: (accessToken: string, refreshToken: string) => any;
};

class QApi {
  private baseUrl: string;

  private parentDeviceId = 'unknown';

  private clientCredentials: {
    client_id: string | undefined;
    client_secret: string | undefined;
  } = { client_id: undefined, client_secret: undefined };

  private authTokens: {
    access_token: string | undefined;
    refresh_token: string | undefined;
  } = { access_token: undefined, refresh_token: undefined };

  private saveTokensCallback: (
    accessToken: string,
    refreshToken: string
  ) => void = () => undefined;

  private timeout = 10000;

  private accountId: number | string = '';

  private accountUid = '';

  private lastUpdateLastSeen: moment.Moment;

  private transformers: RequestTransformerType[] = [];

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.lastUpdateLastSeen = localStorage.lastUpdateLastSeen
      ? moment(localStorage.lastUpdateLastSeen, moment.ISO_8601)
      : moment().add(-1, 'd');
  }

  private getActionHeader() {
    let action;
    const lastDay = this.lastUpdateLastSeen.dayOfYear();
    const now = moment();
    const today = now.dayOfYear();
    if (lastDay !== today) {
      action = 'update-last-seen';
      this.lastUpdateLastSeen = now;
      localStorage.lastUpdateLastSeen = now.format();
    }
    return action
      ? {
          'Parent-Device-Action': action,
        }
      : {};
  }

  private getParentDeviceIdHeader() {
    return this.parentDeviceId
      ? { 'Parent-Device-Id': this.parentDeviceId }
      : {};
  }

  private getQustodioClientHeader() {
    const version = getAppVersion();
    const qustodioClient = R.cond<string, string>([
      [
        R.equals(BuildPlatform.ios as string),
        R.always(`QustodioParentsApp/${version} (p:ios)`),
      ],
      [
        R.equals(BuildPlatform.android as string),
        R.always(`QustodioParentsApp/${version} (p:android)`),
      ],
      [R.T, R.always(`QustodioPARWeb/${version} (p:browser)`)],
    ])(getBuildPlatform());

    return { 'Qustodio-Client': qustodioClient };
  }

  private getClientHeaders(): Headers {
    // We need to exclude iOS because with the Webkit Webview
    // there's a CORS issue with the User-Agent header
    const platform = getBuildPlatform();
    const userAgentHeaders =
      platform !== BuildPlatform.browser && platform !== BuildPlatform.ios
        ? {
            'User-Agent': `ParentsApp ${getAppVersion()} ${platform}`,
          }
        : {};

    return {
      ...userAgentHeaders,
      ...this.getParentDeviceIdHeader(),
      ...this.getActionHeader(),
      ...this.getQustodioClientHeader(),
    } as unknown as Headers;
  }

  private getAuthorizationHeaders() {
    return this.authTokens && this.authTokens.access_token
      ? { Authorization: `Bearer ${this.authTokens.access_token}` }
      : {};
  }

  private getRequestHeaders(headers: { [index: string]: string } = {}) {
    return Object.assign(
      this.getAuthorizationHeaders(),
      this.getClientHeaders(),
      headers
    );
  }

  private updateTokens(
    options,
    grant: { access_token: string; refresh_token: string }
  ) {
    this.authTokens = {
      access_token: grant.access_token,
      refresh_token: grant.refresh_token,
    };
    this.saveTokensCallback(grant.access_token, grant.refresh_token);
    return Object.assign(options, {
      headers: Object.assign(options.headers, this.getAuthorizationHeaders()),
    });
  }

  private refreshAccessToken() {
    const { refresh_token } = this.authTokens;
    const { client_id, client_secret } = this.clientCredentials;

    const body = {
      grant_type: 'refresh_token',
      refresh_token,
      client_id,
      client_secret,
    };
    return this.accessToken.post(body);
  }

  private refreshTokenIfNeeded(url, options, response) {
    if (response.status === 401) {
      return this.refreshAccessToken()
        .then(this.updateTokens.bind(this, options))
        .then(R.partial(fetch, [url]));
    }
    return response;
  }

  private toJson(response) {
    // BCK is returning the content type header as json in 204 responses, but there is not valid json in the response
    // this causes a bug and exceptions in sentry, se we will filter any request with a status 204
    if (response.headers && response.headers.get && response.status !== 204) {
      const contentType = response.headers.get('content-type');
      if (contentType && R.contains('application/json', contentType)) {
        return response.json();
      }
    }
    return null;
  }

  private captureError(error: APIError) {
    return error
      .json()
      .then(json => JSON.stringify(json))
      .catch(e => `No JSON in response: ${e}`)
      .then(responseBody => {
        const { url } = error;
        captureException(error, {
          fingerprint: ['QApi', error.endpoint, error.method],
          extra: {
            status: error.status,
            endpoint: error.endpoint,
            method: error.method,
            url,
            responseBody,
            requestBody: error.requestBody,
          },
          level: error instanceof APIError ? 'info' : 'error',
          tags: {
            request_method: error.method,
            request_status: error.status,
          },
        });
      })
      .then(() => error)
      .catch(() => error);
  }

  private getAbortController = () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(
      () => controller.abort('TimeoutError'),
      this.timeout
    );

    return {
      controller,
      timeoutId,
    };
  };

  private async handleFetchError(
    e: Error,
    controller: AbortController,
    endpoint: string,
    options: RequestInit
  ) {
    let error;
    if (e instanceof APIError) {
      /**
       * if it is a API error response we capture it to sentry
       * as info level. We exclude the 3XX error due to we want
       * to report redirects.
       */
      if (e.type === ERROR_4XX || e.type === ERROR_5XX) {
        error = await this.captureError(e);
      } else {
        error = e;
      }
      /**
       * Since fetch does not support a proper timeout, this error
       * is in reality an AbortError, in order to differentiate them
       * we use a custom signal reason
       * */
    } else if (controller.signal.reason === 'TimeoutError') {
      error = new APITimeoutRequestError(
        {
          status: STATUS_TIMEOUT,
          statusText: e.message,
        },
        endpoint,
        options.method as Method,
        options.body
      );
    } else if (e.name === 'AbortError') {
      error = new APIAbortRequestError(
        {
          status: STATUS_ABORT_ERROR,
          statusText: e.message,
        },
        endpoint,
        options.method as Method,
        options.body
      );
    } else {
      error = new APINetworkRequestError(
        {
          status: STATUS_NETWORK_ERROR,
          statusText: e.message,
        },
        endpoint,
        options.method as Method,
        options.body
      );
    }
    // Propage the error
    throw error;
  }

  private async fetchApi(
    path: string,
    pathParams: FetchParams,
    queryParams: FetchParams,
    options: RequestInit,
    endpoint: string
  ) {
    if (R.isEmpty(path)) {
      return Promise.reject(new Error('"path" must not be empty'));
    }

    if (
      !this.authTokens ||
      (!this.authTokens.access_token && !this.authTokens.refresh_token)
    ) {
      return Promise.reject(
        new APIError(
          {
            status: STATUS_MISSING_TOKENS_ERROR,
            statusText: `No access/refresh tokens for request /${endpoint}`,
          },
          endpoint,
          options.method as Method,
          options.body
        )
      );
    }

    const parametrizedPath: string = (<any>R.pipe)(
      R.defaultTo({}),
      R.toPairs,
      R.flip(R.concat)([
        ['accountId', this.accountId], // accountId lives in module/singleton state
        ['accountUid', this.accountUid], // accountUid lives in module/singleton state
        ['profileId', ''], // profileId and parentDeviceId, when omitted, are used to fetch lists.t
        ['parentDeviceId', ''], // TODO make distinct endpoints for these lists, so that we can remove this
      ]),
      R.map(R.adjust(0, key => `{${key}}`)),
      R.reduce(
        (path: string, [key, value]: [string, string]) =>
          R.replace(key, value, path),
        `/${path}`
      )
    )(pathParams);

    const url = R.join('', [
      this.baseUrl,
      parametrizedPath,
      Object.keys(queryParams || {}).length > 0
        ? `?${queryString.stringify(queryParams)}`
        : '',
    ]);

    const { controller, timeoutId } = this.getAbortController();
    try {
      let response = await fetch(url, {
        signal: controller.signal,
        cache: 'no-store',
        ...options,
      });
      response = await this.refreshTokenIfNeeded(url, options, response);
      if (!response.ok) {
        throw new APIError(
          response,
          endpoint,
          options.method as Method,
          options.body
        );
      }
      return this.toJson(response);
    } catch (error) {
      return this.handleFetchError(error, controller, endpoint, options);
    } finally {
      clearTimeout(timeoutId);
    }
  }

  public setGlobals(globals: QApiGlobals): void {
    if (globals.tokens) {
      this.authTokens = {
        access_token: globals.tokens.accessToken,
        refresh_token: globals.tokens.refreshToken,
      };
    }
    this.setClientCredentials(globals.clientCredentials);
    this.saveTokensCallback = globals.saveTokensCallback;
  }

  public setParentDeviceId(parentDeviceId: string): void {
    this.parentDeviceId = parentDeviceId;
  }

  public setAccountId(id: number | string): void {
    this.accountId = id;
  }

  public setAccountUid(uid: string): void {
    this.accountUid = uid;
  }

  public setClientCredentials(clientCredentials: {
    client_id: string;
    client_secret: string;
  }) {
    this.clientCredentials = clientCredentials;
  }

  public addRequestTransformer(transformer: RequestTransformerType) {
    this.transformers[transformer.name] = transformer;
  }

  // We only need this method for tests... :(
  public resetRequestTransformers() {
    this.transformers = [];
  }

  public get(endpoint: string, path: string, params?, query?) {
    return this.fetchApi(
      path,
      params,
      query,
      {
        method: 'GET',
        headers: this.getRequestHeaders(),
      },
      endpoint
    );
  }

  // @TODO change post, put and delete to private once referenced by TS
  public post(endpoint: string, path: string, body = {}, query, params) {
    return this.fetchApi(
      path,
      params,
      query,
      {
        method: 'POST',
        headers: this.getRequestHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
      },
      endpoint
    );
  }

  public put(endpoint: string, path: string, params, body = {}, query) {
    return this.fetchApi(
      path,
      params,
      query,
      {
        method: 'PUT',
        headers: this.getRequestHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
      },
      endpoint
    );
  }

  public patch(endpoint: string, path: string, params, body, query) {
    return this.fetchApi(
      path,
      params,
      query,
      {
        method: 'PATCH',
        headers: this.getRequestHeaders({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body || {}),
      },
      endpoint
    );
  }

  public delete(endpoint: string, path: string, params, query) {
    return this.fetchApi(
      path,
      params,
      query,
      {
        method: 'DELETE',
        headers: this.getRequestHeaders(),
      },
      endpoint
    );
  }

  protected endpoint<T extends Method, U extends {}>(
    name: string,
    methods: T[],
    path: (params: U) => string
  ) {
    const getAutoParams = () => ({
      accountId: this.accountId,
      accountUid: this.accountUid,
      profileId: '',
      parentDeviceId: '',
    });

    const processParams = params =>
      Object.values(this.transformers).reduce(
        (params, transformer: RequestTransformerType): object => {
          if (transformer.transformApplies({ name, methods, path })) {
            return transformer.transform(params);
          }
          return params;
        },
        params
      );

    return methods.reduce(
      (endpoint, method) =>
        Object.assign(endpoint, {
          [method]:
            method !== 'post'
              ? (params: any, ...rest: any[]) =>
                  this[method].call(
                    this,
                    name,
                    path(processParams({ ...getAutoParams(), ...params })),
                    {},
                    ...rest
                  )
              : // qapi.post has switched param order
                (body, query, params) =>
                  this[method].call(
                    this,
                    name,
                    path(processParams({ ...getAutoParams(), ...params })),
                    body,
                    query,
                    {}
                  ),
        }),
      {} as Endpoint<T, U>
    );
  }

  public profiles = this.endpoint(
    'profiles',
    ['get', 'post', 'put', 'delete'],
    (params: autoParams) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}`
  );

  public urlDetails = this.endpoint(
    'urlDetails',
    ['get'],
    (params: { profileId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/domains`
  );

  public me = this.endpoint(
    'me',
    ['get'],
    () => `v1/accounts/me?include-lw-school-status=true`
  );

  public account = this.endpoint(
    'account',
    ['put'],
    (params: autoParams) => `v1/accounts/${params.accountId}`
  );

  public otherAccount = this.endpoint(
    'account',
    ['get'],
    ({ id }: { id: number }) => `v1/accounts/${id}`
  );

  public password = this.endpoint(
    'password',
    ['put'],
    (params: autoParams) => `v1/accounts/${params.accountId}/password`
  );

  public contacts = this.endpoint(
    'contacts',
    ['get', 'post'],
    (params: autoParams) => `v1/accounts/${params.accountId}/contacts`
  );

  public option = this.endpoint(
    'option',
    ['get', 'delete', 'post'],
    (params: { optionKey: string } & autoParams) =>
      `v1/accounts/${params.accountId}/options/${params.optionKey}`
  );

  public options = this.endpoint(
    'options',
    ['get', 'post'],
    (params: autoParams) => `v1/accounts/${params.accountId}/options`
  );

  public guestAccountOptions = this.endpoint(
    'guestAccountOptions',
    ['get', 'post'],
    (params: autoParams) => `v1/accounts/${params.accountId}/options`
  );

  public contact = this.endpoint(
    'contact',
    ['delete'],
    (params: { contactId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/contacts/${params.contactId}`
  );

  public email = this.endpoint(
    'email',
    ['put'],
    (params: autoParams) => `v1/accounts/${params.accountId}/email`
  );

  public autologin = this.endpoint(
    'autologin',
    ['post'],
    () => `v1/authentication/single-sign-on/token`
  );

  public devices = this.endpoint(
    'devices',
    ['get'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/devices?include_lastseen_info=true&include_delegated_devices=true&include_mdm_status=true`
  );

  public device = this.endpoint(
    'device',
    ['put', 'delete'],
    (params: { deviceId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceId}?include_lastseen_info=true`
  );

  public deviceSettings = this.endpoint(
    'deviceSettings',
    ['get', 'put'],
    (params: { deviceId: string } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceId}/settings`
  );

  public deviceOptions = this.endpoint(
    'deviceOptions',
    ['get'],
    (params: { deviceId: string } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceId}/users/1/options`
  );

  public deviceUserStatus = this.endpoint(
    'deviceUserStatus',
    ['post'],
    (params: { deviceUId: UId; userUId: UId } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceUId}/users/${params.userUId}/status`
  );

  public deviceUserEvents = this.endpoint(
    'deviceUserEvents',
    ['post'],
    (params: { deviceUId: UId; userUId: UId } & autoParams) =>
      `v2/accounts/${params.accountUid}/devices/${params.deviceUId}/users/${params.userUId}/events`
  );

  public deviceUser = this.endpoint(
    'deviceUser',
    ['post'],
    (params: { deviceUId: UId } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceUId}/profiles`
  );

  public deviceUserProfile = this.endpoint(
    'deviceUserProfile',
    ['post', 'get'],
    (params: { profileId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/devices?include_lastseen_info=true`
  );

  public parentDevices = this.endpoint(
    'parentDevices',
    ['get', 'post', 'put', 'delete'],
    (params: { parentDeviceId?: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/parent-devices/${params.parentDeviceId}`
  );

  public parentDevicesOptions = this.endpoint(
    'parentDevicesOptions',
    ['post'],
    (params: { parentDeviceId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/parent-devices/${params.parentDeviceId}/options`
  );

  public license = this.endpoint('license', ['get'], (params: autoParams) => {
    return `v1/accounts/${params.accountId}/license`;
  });

  public events = this.endpoint(
    'events',
    ['get'],
    (params: { profileUid: PathId } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/events`
  );

  public summary = this.endpoint(
    'summary',
    ['get'],
    (params: { profileId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/summary`
  );

  // **** Youtube summary

  public youtubeSummaryTotalVideos = this.endpoint(
    'youtubeSummaryTotalVideos',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
        limit?: number;
      } & autoParams
    ) =>
      `v2/accounts/${
        params.accountUid
        // tslint:disable-next-line:max-line-length
      }/profiles/${params.profileUid}/summary/videos/total-videos?provider=${
        VideoSources.Youtube
      }&min_date=${params.minDate}&max_date=${params.maxDate}&limit=${
        params.limit
      }`
  );

  public youtubeSummaryTotalSearches = this.endpoint(
    'youtubeSummaryTotalSearches',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
        limit?: number;
      } & autoParams
    ) =>
      `v2/accounts/${
        params.accountUid
        // tslint:disable-next-line:max-line-length
      }/profiles/${params.profileUid}/summary/videos/total-searches?provider=${
        VideoSources.Youtube
      }&min_date=${params.minDate}&max_date=${params.maxDate}&limit=${
        params.limit
      }`
  );

  public youtubeSummaryLastVideoSearches = this.endpoint(
    'youtubeSummaryLastVideoSearches',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
        limit?: number;
      } & autoParams
    ) =>
      `v2/accounts/${
        params.accountUid
        // tslint:disable-next-line:max-line-length
      }/profiles/${params.profileUid}/summary/videos/last-searches?provider=${
        VideoSources.Youtube
      }&min_date=${params.minDate}&max_date=${params.maxDate}&limit=${
        params.limit
      }`
  );

  public youtubeSummaryLastVideos = this.endpoint(
    'youtubeSummaryLastVideos',
    ['get'],
    (
      params: {
        profileUid: PathId;
        maxDate?: string;
        minDate?: string;
        limit?: number;
      } & autoParams
    ) =>
      `v2/accounts/${
        params.accountUid
        // tslint:disable-next-line:max-line-length
      }/profiles/${params.profileUid}/summary/videos/last-videos?provider=${
        VideoSources.Youtube
      }&search_engine=${VideoSources.Youtube}${
        params.maxDate ? `&max_date=${params.maxDate}` : ''
      }${params.minDate ? `&min_date=${params.minDate}` : ''}${
        params.limit ? `&limit=${params.limit}` : ''
      }`
  );

  // **** End Youtube summary

  public callsAndSmsSummary = this.endpoint(
    'callsAndSmsSummary',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/summary/social/?min_date=${params.minDate}&max_date=${params.maxDate}`
  );

  public searchSummary = this.endpoint(
    'searchSummary',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
        limit?: string;
        filter?: string;
      } & autoParams
    ) => {
      const path = `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/summary/search/?min_date=${params.minDate}&max_date=${params.maxDate}`;
      return params.filter ? `${path}&filter=${params.filter}` : path;
    }
  );

  public summaryPerHours = this.endpoint(
    'summary',
    ['get'],
    (params: { profileUid: PathId; date: string } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/summary_hourly?date=${params.date}`
  );

  public screenTimePerDay = this.endpoint(
    'screenTimePerDay',
    ['get'],
    (
      params: {
        profileId: PathId;
        minDate: string;
        maxDate: string;
      } & autoParams
    ) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/session-time?min_date=${params.minDate}&max_date=${params.maxDate}`
  );

  public summaryDomainsAndApps = this.endpoint(
    'summaryDomainsAndApps',
    ['get'],
    (
      params: {
        profileUid: PathId;
        filter: string;
        minDate: string;
        maxDate: string;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/summary/domains-and-apps?filter=${params.filter}&min_date=${params.minDate}&max_date=${params.maxDate}`
  );

  public summaryDomainActivity = this.endpoint(
    'summary',
    ['get'],
    (
      params: {
        profileUid: PathId;
        minDate: string;
        maxDate: string;
        limit?: string;
        filter?: ActivitySummaryFilters;
        order?: ActivitySummaryOrderBy[];
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${
        params.profileUid
      }/summary/domains?min_date=${params.minDate}&max_date=${
        params.maxDate
      }&limit=${params.limit}${
        params.filter ? `&filter=${params.filter}` : ''
      }${params.order ? `&_order=${params.order.join(',')}` : ''}`
  );

  public products = this.endpoint(
    'products',
    ['get'],
    (
      params: {
        paymentProvider: PaymentProvider;
        promotion?: string;
        experiment?: string;
        xsource?: string;
      } & autoParams
    ) => {
      const endpoint = `v1/accounts/${params.accountId}/producttooffer`;
      const provider = `?provider=${params.paymentProvider}`;
      const promotion = params.promotion
        ? `&promotion=${params.promotion}`
        : '';
      const experiment = params.experiment
        ? `&experiment=${params.experiment}`
        : '';
      const xsource = params.xsource ? `&xsource=${params.xsource}` : '';
      return `${endpoint}${provider}${promotion}${experiment}${xsource}`;
    }
  );

  public product = this.endpoint(
    'product',
    ['get'],
    (
      params: {
        paymentProvider: PaymentProvider;
        productCode: string;
        duration?: boolean;
        size?: boolean;
        experiment?: string;
        xsource?: string;
        coupon?: string;
      } & autoParams
    ) =>
      `v1/accounts/${params.accountId}/products/${
        params.productCode
      }?provider=${params.paymentProvider}${
        params.duration ? '&upsell_products_duration=1' : ''
      }${params.size ? '&upsell_products_size=1' : ''}${
        params.experiment ? `&experiment=${params.experiment}` : ''
      }${params.xsource ? `&xsource=${params.xsource}` : ''}${
        params.coupon ? `&coupon=${params.coupon}` : ''
      }`
  );

  public validateTransaction = this.endpoint(
    'validateTransaction',
    ['get'],
    (
      params: {
        transactionId: string;
        paymentProvider: PaymentProvider;
      } & autoParams
    ) =>
      `v1/accounts/${params.accountId}/purchases/${params.paymentProvider}/${params.transactionId}`
  );

  public validateReceipt = this.endpoint(
    'validateReceipt',
    ['post'],
    (params: { paymentProvider: PaymentProvider }) =>
      `v1/payment/${params.paymentProvider}`
  );

  public profileRules = this.endpoint(
    'profileRules',
    ['get', 'put'],
    (
      params: {
        profileId: PathId;
        fetchAppRules?: boolean;
      } & autoParams
    ) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/rules${
        params.fetchAppRules ? '?app_rules=1' : ''
      }`
  );

  public appCategories = this.endpoint(
    'appCategories',
    ['get'],
    () => `v2/installed-apps/categories`
  );

  public profileVideoRules = this.endpoint(
    'profileVideoRules',
    ['get', 'put'],
    (params: { profileUid: PathId; source: VideoSources } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/rules/videos/${params.source}`
  );

  public timeLimits = this.endpoint(
    'timeLimits',
    ['get', 'put'],
    (params: { profileId: PathId; deviceId: PathId } & autoParams) =>
      `v1/accounts/${params.accountId}/profiles/${params.profileId}/rules/time_restrictions/${params.deviceId}`
  );

  public pushNotification = this.endpoint(
    'pushNotification',
    ['post'],
    (params: { deviceUId: UId } & autoParams) =>
      `v1/accounts/${params.accountId}/devices/${params.deviceUId}/notifications`
  );

  public notifications = this.endpoint(
    'notifications',
    ['get'],
    (params: { notificationKey: string } & autoParams) =>
      `v1/accounts/${params.accountId}/notification-template/${params.notificationKey}`
  );

  public sendDownloadLink = this.endpoint(
    'notifications',
    ['post'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/download_link/send_download_link`
  );

  public experiments = this.endpoint(
    'experiments',
    ['get'],
    () => `v1/analytics/surveys?platform=${window.cordova.platformId}`
  );

  public notificationCenter = this.endpoint(
    'notificationCenter',
    ['get'],
    ({ accountUid }: { accountUid: UId }) =>
      `v2/accounts/${accountUid}/notification_center/items/`
  );

  /**
   * The oauth2/access_token endpoint is an exception. The content type is application/x-www-form-urlencoded, and
   * errors are handled in a much simpler way: everything except status: 200 is treated as error.
   */
  public accessToken = {
    post: async (body: BodyInit) => {
      const { controller, timeoutId } = this.getAbortController();
      try {
        const response = await fetch(`${this.baseUrl}/v1/oauth2/access_token`, {
          method: 'POST',
          signal: controller.signal,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...this.getClientHeaders(),
          },
          body: queryString.stringify(body),
        });
        if (response.status !== 200) {
          throw new APIError(response, 'access_token', 'POST', body);
        }

        return response.json();
      } catch (e) {
        return this.handleFetchError(e, controller, 'access_token', {
          method: 'POST' as Method,
          body,
        });
      } finally {
        clearTimeout(timeoutId);
      }
    },
  };

  /**
   * This code is not using neither `this.endpoint` or `this.get`
   *  because:
   *    - it's interacting with a public endpoint that does not need authorization headers
   *    - it's a credentialed request which requires an additional header.
   *    - reports to Sentry "TypeError: Failed to fetch" errors instead of rethrowing.
   *      This error happens when performing a preflight credentialed request and
   *      the server does not return `Access-Control-Allow-Credentials: *` header.
   *      (this is achieved by relying directly in `QApi.prototype.captureError`,
   *      instead of going through `this.logError`)
   */
  public visit = {
    get: async ({ url }: { url: string }) => {
      const { controller, timeoutId } = this.getAbortController();
      try {
        const response = await fetch(`${this.baseUrl}/v2/visit?url=${url}`, {
          signal: controller.signal,
          method: 'GET',
          headers: {
            ...this.getClientHeaders(),
          },
          credentials: 'include',
        });
        if (response.status !== 200) {
          throw new APIError(response, 'visit', 'GET', undefined);
        }
        return response;
      } catch (e) {
        return this.handleFetchError(e, controller, 'visit', {
          method: 'GET' as Method,
          body: undefined,
        });
      } finally {
        clearTimeout(timeoutId);
      }
    },
  };

  public invitations = this.endpoint(
    'invitations',
    ['get', 'post'],
    () => `v2/accounts/${this.accountUid}/access-invitations`
  );

  public invitation = this.endpoint(
    'invitation',
    ['delete'],
    ({ uid }: { uid: string }) =>
      `v2/accounts/${this.accountUid}/access-invitations/${uid}`
  );

  public accessProfile = this.endpoint(
    'accessProfile',
    ['delete'],
    (params: autoParams & { uid: string }) =>
      `v2/accounts/${params.accountUid}/access-profiles/${params.uid}`
  );

  /**
   * This code is not using neither `this.endpoint` or `this.post`
   *  because:
   *    - it's a credentialed request which requires an additional header.
   *    - reports to Sentry "TypeError: Failed to fetch" errors instead of rethrowing.
   *      This error happens when performing a preflight credentialed request and
   *      the server does not return `Access-Control-Allow-Credentials: *` header.
   *      (this is achieved by relying directly in `QApi.prototype.captureError`,
   *      instead of going through `this.logError`)
   */
  public attribution = {
    post: async ({
      type,
      ...otherParams
    }: {
      type: 'visit' | 'registration';
      [otherParams: string]: string;
    }) => {
      const { controller, timeoutId } = this.getAbortController();
      const body = { type, ...otherParams } as BodyInit;
      try {
        const response = await fetch(
          `${this.baseUrl}/v2/accounts/${this.accountUid}/attribution`,
          {
            signal: controller.signal,
            method: 'POST',
            headers: {
              ...this.getRequestHeaders({
                'Content-Type': 'application/json',
              }),
            },
            credentials: 'include',
            body: JSON.stringify(body),
          }
        );
        if (!response.ok) {
          throw new APIError(
            response,
            'attribution',
            'POST',
            JSON.stringify(body)
          );
        }
        return response;
      } catch (e) {
        return this.handleFetchError(e, controller, 'attribution', {
          method: 'POST' as Method,
          body,
        });
      } finally {
        clearTimeout(timeoutId);
      }
    },
  };

  public places = this.endpoint(
    'places',
    ['get', 'post'],
    (params: autoParams) => `v2/accounts/${params.accountUid}/places`
  );

  public place = this.endpoint(
    'place',
    ['delete', 'put'],
    (params: { placeUid: PathId } & autoParams) =>
      `v2/accounts/${params.accountUid}/places/${params.placeUid}`
  );

  public giftNotifications = this.endpoint(
    'notifications',
    ['get'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/notifications?type=GIFT`
  );

  public consumeGift = this.endpoint(
    'consumeGift',
    ['post'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/gifts/consume_gift`
  );

  public activateRetrial = this.endpoint(
    'activateRetrial',
    ['post'],
    (params: autoParams) => `v2/accounts/${params.accountUid}/activate_retrial`
  );

  public calendarRestrictions = this.endpoint(
    'calendarRestrictions',
    ['get', 'post', 'put', 'delete'],
    (
      params: {
        profileUid: PathId;
        filter?: string;
        calendarRestrictionUid?: string;
      } & autoParams
    ) => {
      const { accountUid, profileUid, calendarRestrictionUid, filter } = params;
      const isDelete = !!calendarRestrictionUid;
      const path = `v2/accounts/${accountUid}/profiles/${profileUid}/rules/calendar_restrictions`;
      if (isDelete) return `${path}/${calendarRestrictionUid}`;
      return filter ? `${path}?custom_filter=${filter}` : path;
    }
  );

  public deviceRules = this.endpoint(
    'deviceRules',
    ['get'],
    (params: { deviceUId: UId } & autoParams) =>
      `v2/accounts/${params.accountUid}/devices/${params.deviceUId}/rules`
  );

  public sitesClassification = this.endpoint(
    'sitesClassification',
    ['get'],
    (params: { hostname: string }) =>
      `v1/sites/classification?hostname=${params.hostname}`
  );

  public onboardingToken = this.endpoint(
    'onboardingToken',
    ['post'],
    (params: { profileUid: UId } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/onboarding_token`
  );

  public lastAttribution = this.endpoint(
    'lastAttribution',
    ['get'],
    (params: autoParams) => `v2/accounts/${params.accountUid}/last-attribution`
  );

  public multiParentFeatureFlag = this.endpoint(
    'multiParentFeatureFlag',
    ['get'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/feature-flags/multiparent`
  );

  public students = this.endpoint(
    'students',
    ['get'],
    (params: autoParams) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/students`
  );

  public linkAllPendingStudents = this.endpoint(
    'linkAllPendingStudents',
    ['post'],
    (params: autoParams) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/students/link-all-pending-students`
  );

  public summaryStudentTotalUsage = this.endpoint(
    'summaryStudentTotalUsage',
    ['get'],
    (params: { profileUid: UId } & autoParams) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profile/${params.profileUid}/activity/summary/total-usage`
  );

  public summaryStudentSignatures = this.endpoint(
    'summaryStudentSignatures',
    ['get'],
    (
      params: { profileUid: UId; minDate: string; maxDate: string } & autoParams
    ) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profile/${params.profileUid}/activity/summary/signatures/?min_date=${params.minDate}&max_date=${params.maxDate}`
  );

  public summaryStudentCategories = this.endpoint(
    'summaryStudentCategories',
    ['get'],
    (
      params: { profileUid: UId; minDate: string; maxDate: string } & autoParams
    ) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profile/${params.profileUid}/activity/summary/categories/?min_date=${params.minDate}&max_date=${params.maxDate}`
  );

  public signatureDetails = this.endpoint(
    'signatureDetails',
    ['get'],
    (
      params: {
        profileUid: UId;
        signatureId: SignatureDetails['id'];
      } & autoParams
    ) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profile/${params.profileUid}/activity/advice/signature/${params.signatureId}`
  );

  public studentPolicies = this.endpoint(
    'studentPolicies',
    ['get'],
    (
      params: {
        profileUid: UId;
      } & autoParams
    ) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profiles/${params.profileUid}/policies`
  );

  public studentPoliciesPause = this.endpoint(
    'studentPoliciesPause',
    ['post', 'delete'],
    (
      params: {
        profileUid: UId;
      } & autoParams
    ) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profiles/${params.profileUid}/policies/pause`
  );

  public studentPoliciesDelegate = this.endpoint(
    'studentPoliciesDelegate',
    ['post', 'delete'],
    (
      params: {
        profileUid: UId;
      } & autoParams
    ) =>
      `/v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profiles/${params.profileUid}/policies/delegate/`
  );

  public safeNetworksSettings = this.endpoint(
    'safeNetworksSettings',
    ['get', 'patch'],
    (params: { profileUid: UId } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/rules/safe-networks`
  );

  public studentEvents = this.endpoint(
    'studentEvents',
    ['get'],
    (params: { profileUid: UId } & autoParams) =>
      `v2/partners/lw-school/qustodio-accounts/${params.accountUid}/profiles/${params.profileUid}/events/`
  );

  public routines = this.endpoint(
    'routines',
    ['get', 'post'],
    (
      params: {
        profileUid: UId;
        includeDisabled?: boolean;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/routines${
        params.includeDisabled ? '?include_disabled=1' : ''
      }`
  );

  public routinesDetails = this.endpoint(
    'routinesDetails',
    ['get', 'patch', 'delete'],
    (
      params: {
        profileUid: UId;
        routineUid: UId;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/routines/${params.routineUid}`
  );

  public routinesSchedules = this.endpoint(
    'routinesSchedules',
    ['get', 'post'],
    (
      params: {
        profileUid: UId;
        routineUid: UId;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/routines/${params.routineUid}/schedules`
  );

  public routinesSchedulesDetails = this.endpoint(
    'routinesSchedulesDetails',
    ['get', 'patch', 'delete'],
    (
      params: {
        profileUid: UId;
        routineUid: UId;
        scheduleUid: UId;
      } & autoParams
    ) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/routines/${params.routineUid}/schedules/${params.scheduleUid}`
  );

  public summaryAlerts = this.endpoint(
    'summaryAlerts',
    ['get'],
    (params: autoParams) =>
      `v2/accounts/${params.accountUid}/threat_alerts/summary`
  );

  public summaryAlertsReviewed = this.endpoint(
    'summaryAlertsReviewed',
    ['put'],
    (params: { profileUid: UId; alertType: 'search' } & autoParams) =>
      `v2/accounts/${params.accountUid}/profiles/${params.profileUid}/threat_alerts/reviewed/${params.alertType}`
  );

  public features = this.endpoint(
    'features',
    ['get'],
    (params: autoParams) => `v2/accounts/${params.accountUid}/features`
  );

  public productAddonsOffer = this.endpoint(
    'productAddonsOffer',
    ['get'],
    (
      params: {
        addons: ('wellbeing_therapy' | 'wellbeing_activities')[];
      } & autoParams
    ) =>
      `v2/accounts/${
        params.accountUid
      }/product-addons-to-offer?addon_types=${params.addons.join(',')}`
  );

  public getBaseUrl() {
    return this.baseUrl;
  }
}

export default QApi;
