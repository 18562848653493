import { connect } from 'react-redux';
import {
  setFilter,
  shouldFetchMoreEvents,
  fetchEventsIfProfileChanged,
} from '../../ducks/events';
import { ActivityEventFilters } from '../../businessLogic/timeline/types';
import { Dispatch } from '../../store/state';
import {
  getAccount,
  getAccountCurrentTime,
  getTimezone,
  isFetchingProfile,
  isRefreshingProfile,
  getProfile,
  showFetchingMore,
  hasMoreEventsTimeline,
  getAccountCountry,
  getDevice,
  getRegularStatusProfileLocationEventIds,
  getLocationEventDays,
  hasFetchedAllProfileRules,
  getTrackableProfiles,
  getLicense,
} from '../../selectors/index';
import KidLocationsHistory from '../../components/FamilyLocator/KidLocationsHistory';
import {
  trackIfDataWasFetched,
  clickLocation,
  navigateToPlaces,
} from '../../actions/FamilyLocatorActions';
import { PageNames } from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import {
  profileHasIrregularStatus,
  getStatusCard,
  getDetailedStatusCard,
  getHelpTipUrl,
} from '../../components/FamilyLocator/ProfileLocationStatusHelper';
import { DeviceRecord } from '../../records';
import { PLATFORM_UNKNOWN } from '../../constants';
import { getProfileLocationStatus } from '../../selectors/profileLocationStatus';
import { isBrowserPlatform } from '../../helpers';
import { handleApiErrorMessage, AnyError } from '../../helpers/errorHandling';
import { fetchAllProfileRules } from '../../ducks/profileRules';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { isFree } from '../../records/license';
import { fetchProfile } from '../../ducks/profiles';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const MAX_NUMBER_OF_EVENTS_REQUESTED = 20;

const mapStateToProps = (state, { params: { profileId } }) => {
  const account = getAccount(state);
  const profile = getProfile(state, profileId);
  const profileStatus = getProfileLocationStatus(state, profileId);
  const device = getDevice(state, profile.location.deviceId) as DeviceRecord;
  const platform = device ? device.platform : PLATFORM_UNKNOWN;
  const eventCount = getRegularStatusProfileLocationEventIds(
    state,
    profile.uid
  ).size;
  const currentUrl = `/familyLocator/${profile.id}/locations`;
  const helpTipUrl = getHelpTipUrl(
    profileId,
    profileStatus,
    platform,
    currentUrl
  );
  return {
    locale: account.locale,
    country: getAccountCountry(state),
    profile,
    hasIrregularStatus: profileHasIrregularStatus(state, profile.id),
    statusCard: getStatusCard(profile, profileStatus, helpTipUrl),
    detailedStatusCard: getDetailedStatusCard(
      profile,
      profileStatus,
      helpTipUrl
    ),
    days: getLocationEventDays(state, profile.id),
    currentTime: getAccountCurrentTime(state),
    timezone: getTimezone(state),
    hasNoEvents: eventCount === 0,
    hasMoreEvents: hasMoreEventsTimeline(state, ActivityEventFilters.Locations),
    isFetching: isFetchingProfile(state) && !isRefreshingProfile(state),
    showFetchingMore: showFetchingMore(state, MAX_NUMBER_OF_EVENTS_REQUESTED),
  };
};

const mapDispatchToProps = (
  dispatch,
  { params: { profileId, switchToFullScreenCallback } }
) => ({
  handleMore: profileId =>
    dispatch(
      trackIfDataWasFetched(
        shouldFetchMoreEvents(
          parseInt(profileId, 10),
          ActivityEventFilters.Locations
        )
      )
    ),
  clickLocation: event => {
    switchToFullScreenCallback();
    return dispatch(clickLocation(event, profileId));
  },
  onClickPlaces: () => dispatch(navigateToPlaces()),
});

const KidLocationsHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(KidLocationsHistory, PageNames.FamilyLocatorKidLocation));

export const load =
  ({ profileId }) =>
  (dispatch: Dispatch, getState) => {
    const state = getState();
    const navigate = getMultiPlatformNavigation();

    if (!getFeatureFlag(state, 'familyLocator') || isBrowserPlatform()) {
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: `/`,
        })
      );
    }

    if (isFree(getLicense(state).type)) {
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: '/familyLocator/premiumFlyover',
        })
      );
    }

    const profile = getProfile(state, profileId);
    Promise.all([
      dispatch(setFilter(ActivityEventFilters.Locations)),
      dispatch(
        fetchEventsIfProfileChanged(profile.uid, ActivityEventFilters.Locations)
      ),
      dispatch(fetchProfile(profileId)),
    ]).catch((error: AnyError) => handleApiErrorMessage(error, true));

    if (!hasFetchedAllProfileRules(state)) {
      dispatch(fetchAllProfileRules(getTrackableProfiles(state)));
    }
  };
KidLocationsHistoryContainer.load = load;

export default KidLocationsHistoryContainer;
