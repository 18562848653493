export const FETCHING_YOUTUBE_SUMMARY = 'FETCHING_YOUTUBE_SUMMARY';
export const RECEIVE_YOUTUBE_SUMMARY = 'RECEIVE_YOUTUBE_SUMMARY';
export const YOUTUBE_SUMMARY_ERROR = 'YOUTUBE_SUMMARY_ERROR';
export const FETCH_YOUTUBE_SUMMARY_FAILED = 'FETCH_YOUTUBE_SUMMARY_FAILED';
export const SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS =
  'SHOW_YOUTUBE_SUMMARY_DETAILS_ACTIONS';
export const FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS =
  'FETCHING_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS';
export const RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS =
  'RECEIVE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS';
export const FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED =
  'FETCH_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS_FAILED';
export const RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS =
  'RECEIVE_MORE_YOUTUBE_SUMMARY_DETAIL_LAST_VIDEOS';
export const LOAD_YOUTUBE_ACTIVITY = 'LOAD_YOUTUBE_ACTIVITY';
