import { getMultiPlatformNavigation } from './multiPlatformNavigation';

export const redirectIfPathDiffers =
  (newPath: string, currentPath: string) => dispatch => {
    if (newPath !== currentPath) {
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'inner:replace',
          src: newPath,
        })
      );
    }
  };
