import * as React from 'react';
import Loader from '../base/Loader';

const ActivityTimelineFetchingMore: React.FunctionComponent<{
  visible: boolean;
}> = ({ visible }) => (
  <div className="ProfileFetchingNew">
    <div style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <Loader size="small" />
    </div>
  </div>
);

export default ActivityTimelineFetchingMore;
