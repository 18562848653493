import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  RegularStyleListItem,
  List,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';

export interface LockDeviceModalProps {
  onClickClose: () => void;
}

const LockDeviceModal = ({ onClickClose }: LockDeviceModalProps) => {
  const MODAL_TEST_ID = 'lock-device-modal';

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-lock-device-modal"
      header={{
        icon: (
          <Icon
            type={IconType.lockAlt}
            size={IconSize.xs}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={t('Lock devices')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-go-it`}
          key="button1"
          onClick={onClickClose}
          disabled={false}
          size={ButtonSize.medium}
          block
        >
          {t('Got it')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t('Lock devices has the following effects:')}
        </ModalStyledText>

        <List listItemMarginBottom="16">
          <RegularStyleListItem
            title={t('Windows and Mac computers')}
            upperSubtitle={t(
              'Your child won’t be able to log in to their user account.'
            )}
            iconVerticalAlign="top"
            icon={<Icon type={IconType.laptop} color={IconColor.black} />}
          />
          <RegularStyleListItem
            title={t('Android devices')}
            upperSubtitle={t(
              'All usage is blocked, apart from emergency phone calls, trusted contact alerts, and the panic button.'
            )}
            iconVerticalAlign="top"
            icon={
              <Icon
                type={IconType.android}
                color={IconColor.black}
                family={IconFamily.brands}
              />
            }
          />
          <RegularStyleListItem
            title={t('iPhones and iPads')}
            upperSubtitle={t(
              'All apps that are categorized as being only for over 4-year-olds are blocked.'
            )}
            iconVerticalAlign="top"
            icon={
              <Icon
                type={IconType.apple}
                color={IconColor.black}
                family={IconFamily.brands}
                size={IconSize.lg}
              />
            }
          />
        </List>
      </FlexLayout>
    </Modal>
  );
};
export default LockDeviceModal;
