import * as React from 'react';
import { classNames } from '../../../helpers';
import {
  militaryTimeToAMPMFormat,
  minutesToHMFormat,
} from '../../../helpers/dates';
import StackedBarChart from '../../StackedBarChart/StackedBarChart';
import { Dot, ReferenceDot, ReferenceLine } from 'recharts';
import { MinutesPerHourActivity } from '../../../records/activity/types/MinutesPerHourActivity.types';
import { List, Map } from 'immutable';
import { BarChartDataType } from '../types/BarChartDataType.types';
import {
  calculateActiveBarCoords,
  getBarSegmentColorMappings,
  getBestFitChartWidth,
  transformHoursDataToChartData,
} from '../helpers';
import { ActiveBarData } from '../../StackedBarChart/types/RechartsActiveBarData.types';
import ChartsCustomTooltip from '../../ChartsCustomTooltip/ChartsCustomTooltip';
import { RoutineColor } from '../../../palettes/RoutineColor';

type HourScreenTimePartial = { hour: string; minutes: number };
type HourBarChartDataType = BarChartDataType<HourScreenTimePartial>;

interface ScreenTimeByHourBarChartProps {
  data: List<MinutesPerHourActivity>;
  routineColorsMap: Map<string, RoutineColor>;
  currentHour?: string;
  className?: string;
  isMilitaryTimeFormat?: boolean;
  hasSeparatedScreenTime: boolean;
}

const maxYAxisValue = 60;
const yAxisDomain = [0, maxYAxisValue];
const xAxisTicks = ['00:00', '06:00', '12:00', '18:00'];
const tooltipMargin = { bottom: 20, right: 10 };

const labelFormatter = (isMilitaryTimeFormat?: boolean) => (tick: string) =>
  isMilitaryTimeFormat ? tick : militaryTimeToAMPMFormat(tick);

const ScreenTimeByHourBarChart = ({
  data,
  currentHour,
  className,
  isMilitaryTimeFormat,
  routineColorsMap,
  hasSeparatedScreenTime,
}: ScreenTimeByHourBarChartProps) => {
  const isToday = Boolean(currentHour);

  const chartWrapperRef = React.useRef<HTMLDivElement>(null);
  const currentHourIndicatorRef = React.useRef<{ props: { cx: number } }>(null);
  const [tooltipActive, setTooltipActive] = React.useState(false);
  const [activeBarData, setActiveBarData] =
    React.useState<ActiveBarData | null>(null);

  const [computedData, setComputedData] = React.useState<{
    chartData: HourBarChartDataType[];
    barsColorMappings: Record<string, string>;
  }>();

  React.useEffect(() => {
    // scroll chart to right at load time
    const setTimeOutId = setTimeout(() => {
      if (
        isToday &&
        chartWrapperRef.current &&
        currentHourIndicatorRef.current
      ) {
        const chartWidth = chartWrapperRef.current.clientWidth || 0;
        const dotPosition = currentHourIndicatorRef.current.props.cx || 0;
        const scrollRightPadding = 16;
        const scrollAmmount = dotPosition - chartWidth + scrollRightPadding;

        chartWrapperRef.current.scroll({
          top: 0,
          left: scrollAmmount,
          behavior: 'smooth',
        });
      }
    }, 500);

    return () => {
      if (setTimeOutId) clearTimeout(setTimeOutId);
    };
  }, []);

  React.useEffect(() => {
    const chartData = transformHoursDataToChartData<HourScreenTimePartial>(
      data,
      hasSeparatedScreenTime,
      currentHour
    );

    setComputedData({
      chartData,
      barsColorMappings: getBarSegmentColorMappings(routineColorsMap),
    });
  }, [data, routineColorsMap]);

  const updateActiveBarData = React.useCallback(
    (action: 'in' | 'out') => (newBarData: ActiveBarData) => {
      if (action === 'out') return;
      if (newBarData) {
        const updatedBarData = calculateActiveBarCoords(
          newBarData,
          activeBarData,
          chartWrapperRef
        );

        setActiveBarData(updatedBarData);
      }
    },
    [setActiveBarData]
  );

  const toggleTooltip = React.useCallback(
    (action: 'in' | 'out') => () => {
      setTooltipActive(action === 'in');
    },
    [setTooltipActive]
  );

  const xAxisTickFormatter = React.useCallback(
    labelFormatter(isMilitaryTimeFormat),
    [isMilitaryTimeFormat]
  );

  const nowIndicatorMemoizedComponent = React.useMemo(
    () => (
      <React.Fragment>
        <ReferenceLine
          isFront={false}
          x={currentHour}
          stroke="inherit"
          strokeWidth={2}
          className="par-screen-time-hour-bar-chart__now-line"
          shapeRendering="crispEdges"
        />
        <ReferenceDot
          ifOverflow="visible"
          x={currentHour}
          y={maxYAxisValue}
          r={6}
          fill="inherit"
          stroke="none"
          shape={props => (
            <Dot
              {...props}
              className="par-screen-time-hour-bar-chart__now-dot"
              ref={currentHourIndicatorRef}
            />
          )}
        />
      </React.Fragment>
    ),
    [currentHour]
  );

  const calculateChartWidth = React.useCallback(
    (calculatedWidth: number) => {
      const box = chartWrapperRef.current?.getBoundingClientRect();
      return getBestFitChartWidth(calculatedWidth, box?.width);
    },
    [chartWrapperRef]
  );

  // NB: we need to set the tooltip initial coords to the position of the chart wrapper.
  // If we don't do this, the tooltip will take the position of document.body and on the
  // first hover on a bar in the chart the tooltip will travel from body (0,0) to the
  // bar position.
  const chartBox = chartWrapperRef.current?.getBoundingClientRect();

  return (
    <div
      ref={chartWrapperRef}
      className={classNames('par-screen-time-hour-bar-chart', className)}
      onPointerEnter={toggleTooltip('in')}
      onPointerLeave={toggleTooltip('out')}
      data-testid="par-screen-time-by-hour-bar-chart"
    >
      <ChartsCustomTooltip
        id="par-screen-time-hour-bar-chart-tooltip"
        coords={{
          x: activeBarData?.x || chartBox?.x || 0,
          y: activeBarData?.y || chartBox?.y || 0,
        }}
        hide={!tooltipActive || !activeBarData}
        title={
          activeBarData?.tooltipPayload
            ? minutesToHMFormat(activeBarData.tooltipPayload[0].payload.total)
            : ''
        }
        subtitle={
          activeBarData?.tooltipPayload
            ? xAxisTickFormatter(activeBarData.tooltipPayload[0].payload.hour)
            : ''
        }
      />

      <StackedBarChart
        data={computedData?.chartData || []}
        xDataKey="hour"
        xAxisTicks={xAxisTicks}
        yAxisDomain={yAxisDomain}
        segmentsColorMappings={computedData?.barsColorMappings || {}}
        xAxisTickFormatter={xAxisTickFormatter}
        onMouseOverBar={updateActiveBarData}
        childrenAtStart
        margin={tooltipMargin}
        fixedWidth={calculateChartWidth}
      >
        {isToday ? nowIndicatorMemoizedComponent : null}
      </StackedBarChart>
    </div>
  );
};

export default ScreenTimeByHourBarChart;
