import { toLower } from 'ramda';
import * as React from 'react';
import { IconSize } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SignatureReview } from '../../../records/studentActivity/signature/types/SignatureReview.types';
import IconAllow from '../../Icons/Actions/IconAllow';
import IconUnsafe from '../../Icons/Actions/IconUnsafe';

type SignatureRecommendationProps = Pick<SignatureReview, 'recommendation'>;

const SignatureRecommendation: React.FunctionComponent<
  SignatureRecommendationProps
> = ({ recommendation }: SignatureRecommendationProps) => {
  let text: string | undefined;

  if (recommendation === 'SAFE' || recommendation === 'RECOMMENDED') {
    text = 'Safe';
  } else if (recommendation === 'UNSAFE' || recommendation === 'HAZARDOUS') {
    text = 'Unsafe';
  }

  return text ? (
    <div className="SignatureRecommendation">
      {text === 'Safe' ? (
        <IconAllow size={IconSize.xs} />
      ) : (
        <IconUnsafe size={IconSize.xs} />
      )}
      <span
        className={`text ${toLower(text)}`}
        data-testid="signature-recommendation-text"
      >
        {t(text)}
      </span>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default SignatureRecommendation;
