import State from '../store/state';
import { getAppRulesApp } from './profileRules';

export const getActivitySwiperIndex = (state: State) =>
  state.get('ui').get('activitySwiperIndex');

export const hasCallsSMSTrackingModalBeenClosedOnce = (state: State) =>
  state.get('ui').get('hasCallsSMSTrackingModalBeenClosedOnce');

export const isSideBarActive = (state: State) =>
  state.get('ui').get('isSideBarActive');

export const getSideBarMode = (state: State) =>
  state.get('ui').get('sideBarMode');

export const getActionSheetApp = (state: State, profileId: string) =>
  getAppRulesApp(state, profileId, getActionSheetId(state) as string);

export const getActionSheetType = (state: State) =>
  state.get('actionSheet').get('type');

export const getActionSheetId = (state: State) =>
  state.get('actionSheet').get('id');

export const getContactModifyStatus = (state: State) =>
  state.get('contacts').get('isUpdating');

export const getPanicRulesSelectedContact = (state: State) =>
  state.get('contacts').get('selectedContact');

export const getContacts = (state: State) => state.get('contacts').get('items');
