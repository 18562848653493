import * as React from 'react';
import { Icon, IconSize, IconType } from 'styleguide-react';
import api from '../api';
import { SchoolTrialDuration } from '../constants';
import { startLoadingInitialData } from '../ducks/app';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';
import { fetchLicense } from '../ducks/license';
import { showToast, TOAST_ICON_WARNING } from '../ducks/toast';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { t } from '../lib/i18n';
import { getEligibleForManualTrial } from '../selectors/stateSelectors/license';
import { BaseThunk } from '../store/state';
import {
  ButtonNames,
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
  trackButtonClicked,
} from '../helpers/analytics';

interface StartTrialTrackingOptions {
  context: FeatureBlockingContexts.FullBlockedFeature;
  reason:
    | ProfileRuleNames.CallsAndMessages
    | ProfileRuleNames.Location
    | ProfileRuleNames.PanicButton;
}

export const startManualTrial =
  (redirectionFragment?: string): BaseThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (!getEligibleForManualTrial(getState())) {
      throw new Error('Profile not eligible for manual trial');
    }
    try {
      dispatch(startLoadingInitialData());
      await api.activateRetrial.post({});
      await dispatch(fetchLicense());
      dispatch(
        showToast(
          t('Your {{days}}-day free trial has started', {
            days: SchoolTrialDuration,
          }),
          '',
          <Icon type={IconType.shieldCheck} size={IconSize.x2} />
        )
      );
      // redirect if we need to
      if (redirectionFragment) {
        const navigate = getMultiPlatformNavigation();
        dispatch(
          navigate({
            type: 'inner:replace',
            src: redirectionFragment,
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    } finally {
      dispatch(finishedLoadingInitialData());
    }
  };

export const trackStartManualTrial = (
  page: GenericPageNames,
  trackingOptions: StartTrialTrackingOptions
) => {
  trackButtonClicked(page, ButtonNames.StartManualTrial, {
    options: JSON.stringify({
      ...trackingOptions,
    }),
  });
};
