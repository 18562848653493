import { setItems, setItemsIfNotExist } from '../ducks/persistentStorage';
import { AccessType, SET_ACCESS_TYPE } from '../ducks/actionNames/app';
import { getPersistentStorageItem } from '../selectors/helpers';
import { setApiGlobals, QApiConfig } from '../sideEffects/config';
import {
  QueryInitializer,
  QueryAppInitial,
  QueryWebAutologin,
} from '../helpers/query';
import { assertNever } from '../helpers';
import { AuthorizationApplication } from '../constants';
import api from '../api';
import { BaseThunk } from '../store/state';
import {
  getAuthorizationApplicationThroughAuthCodeFlowAndPlatform,
  getRedirectUri,
} from '../helpers/authentication';
import { getClientCredentials } from '../helpers/globals';
import { isRequestingTokensForWebClient } from '../helpers/sso';

/**
 * Parse query parameters and save them to persistent storage if not already existing.
 * Decide type of auth-application depending on params if it was not already set in storage.
 * Load persistent storage into state.
 * Set QApi and other globals.
 */
export const loadAuth =
  (query: QueryInitializer): BaseThunk<Promise<void>> =>
  dispatch => {
    return dispatch(saveAuthTokens(query)).then(authConfig => {
      return dispatch(setApiGlobals(authConfig));
    });
  };

/**
 * Save auth-tokens, credentials, and application to storage.
 * If access/refresh token combo, write to storage if not exist.
 * If auth code, exchange for tokens and always write to storage.
 * Otherwise load from storage.
 * The (tokens, application-id, parent-device-id) tuple is returned
 * according to the above, to be passed to QApi globals.
 */
const saveAuthTokens = (
  query: QueryInitializer
): BaseThunk<Promise<QApiConfig>> => {
  switch (query.kind) {
    case 'auth-code':
      return saveAuthTokensAuthCode(query);
    case 'access-tokens':
      return saveAuthTokensAccessTokens(query);
    case 'none':
      return saveAuthTokensNone();
    default:
      return assertNever(query);
  }
};

const saveAuthTokensAuthCode =
  (query: QueryWebAutologin): BaseThunk<Promise<QApiConfig>> =>
  async dispatch => {
    const { authorizationCode } = query;
    const parentDeviceId = 'unknown';
    dispatch(setAccessType(AccessType.AuthCode));
    const isRequestingTokensForWebSSOClient =
      isRequestingTokensForWebClient(query);

    const authorizationApplication = isRequestingTokensForWebSSOClient
      ? AuthorizationApplication.web
      : getAuthorizationApplicationThroughAuthCodeFlowAndPlatform();

    const { accessToken, refreshToken } = await dispatch(
      fetchAccessTokens(authorizationCode, authorizationApplication)
    );

    dispatch(
      setItems({
        authorizationApplication,
        accessToken,
        refreshToken,
        parentDeviceId,
      })
    );
    return {
      authorizationApplication,
      accessToken,
      refreshToken,
      parentDeviceId,
    };
  };

const saveAuthTokensAccessTokens =
  (query: QueryAppInitial): BaseThunk<Promise<QApiConfig>> =>
  dispatch => {
    const { accessToken, refreshToken, parentDeviceId } = query;
    dispatch(setAccessType(AccessType.QueryParams));
    dispatch(
      setItemsIfNotExist({
        authorizationApplication: AuthorizationApplication.WebOrLegacyMobile,
        accessToken,
        refreshToken,
        parentDeviceId,
      })
    );
    return Promise.resolve<QApiConfig>({
      authorizationApplication: AuthorizationApplication.WebOrLegacyMobile,
      accessToken,
      refreshToken,
      parentDeviceId,
    });
  };

const saveAuthTokensNone = (): BaseThunk<Promise<QApiConfig>> => dispatch => {
  dispatch(setAccessType(AccessType.LocalStorage));
  // eslint-disable-next-line no-underscore-dangle
  return dispatch((_dispatch, getState) => {
    const authorizationApplication =
      getPersistentStorageItem(getState(), 'authorizationApplication') ||
      AuthorizationApplication.WebOrLegacyMobile;
    return Promise.resolve<QApiConfig>({
      authorizationApplication,
      accessToken: getPersistentStorageItem(getState(), 'accessToken'),
      refreshToken: getPersistentStorageItem(getState(), 'refreshToken'),
      parentDeviceId: getPersistentStorageItem(getState(), 'parentDeviceId'),
    });
  });
};

/**
 * Exchange an authorization_code for access/refresh tokens.
 */
const fetchAccessTokens = (
  authorizationCode: string,
  authorizationApplication: AuthorizationApplication
): BaseThunk<Promise<{ accessToken: string; refreshToken: string }>> => {
  return () => {
    const body = {
      code: authorizationCode,
      grant_type: 'authorization_code',
      ...getClientCredentials(authorizationApplication),
      redirect_uri: getRedirectUri(),
    };
    return api.accessToken
      .post(body)
      .then(
        ({
          access_token: accessToken,
          refresh_token: refreshToken,
        }: {
          [index: string]: string;
        }) => ({
          accessToken,
          refreshToken,
        })
      );
  };
};

export function setAccessType(payload: AccessType) {
  return {
    type: SET_ACCESS_TYPE,
    payload,
  };
}
