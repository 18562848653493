/* eslint-disable prefer-destructuring */
import * as validateip from 'validate-ip';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { marksy } from 'marksy';
import { createElement } from 'react';
import * as R from 'ramda';
import {
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
  PLATFORM_CHROME,
} from '../constants';

import type { DeviceRecord } from '../records/device/types/Device.types';

export function pad(n, width) {
  const numberString = `${n}`;
  return n.length >= width
    ? n
    : new Array(width - numberString.length + 1).join('0') + n; // tslint:disable-line:prefer-array-literal
}

export const joinObjectSet = (objectSet, property, character) =>
  objectSet.map(obj => obj[property]).join(character);

export const capitalize = (str: string): string =>
  str.length !== 0 ? str.slice(0, 1).toUpperCase().concat(str.slice(1)) : '';

export const commonStrings = Object.freeze({
  nonBreakingSpace: '\u00a0',
});

export const getPlatformName = (device: DeviceRecord) =>
  R.cond<string, string>([
    [R.equals(PLATFORM_IOS), R.always('iOS')],
    [R.equals(PLATFORM_ANDROID), R.always('Android')],
    [R.equals(PLATFORM_WINDOWS), R.always('Windows')],
    [R.equals(PLATFORM_MAC), R.always('Mac')],
    [R.equals(PLATFORM_CHROME), R.always('Chromebook')],
  ])(device.platform);

export const testRegex = regex => RegExp.prototype.test.bind(regex);

export const isValidDomainException = (url: string) => {
  if (validateip(url)) return true;
  return /^[A-Z0-9._-]+\.[A-Z]{2,}$/i.test(extractDomain(url));
};

export const extractDomain = (url: string) => {
  let hostname;

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  hostname = hostname.toLowerCase();
  hostname = hostname.replace(/^www\./, '');

  return hostname.trim();
};

export const checkAllUrlHaveSameHostname = (urls: string[]) => {
  if (urls.length === 0) return false;
  const compareDomain = extractDomain(urls[0]);
  return urls.every(url => extractDomain(url) === compareDomain);
};

/**
 * Given a base string check if contains any of the given substrings in the substring array
 * throws an error if substrings does not exist and returns false if the base does not exist
 *
 * @param base
 * @param substrings
 * @returns
 */
export const checkStringContainsAny = (base: string, substrings: string[]) => {
  return new RegExp(substrings.join('|')).test(base);
};

export const cleanInvalidUrls = (
  urls: string[],
  InvalidSubstring: string[]
) => {
  return urls.filter(
    url =>
      !R.isEmpty(url) &&
      !R.isNil(url) &&
      !checkStringContainsAny(url, InvalidSubstring)
  );
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidPhoneNumber = (n, locale) =>
  R.tryCatch(
    // tries first validation with default locale
    () => phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(n, locale)),
    R.tryCatch(
      // tries retreive locale from location code in phone number
      () => phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(n, '')),
      R.F
    )
  )();

export const getPhoneNumberInternationalFormat = (n, locale) =>
  phoneUtil.format(phoneUtil.parse(n, locale), PhoneNumberFormat.INTERNATIONAL);

export const isNumberMatch = R.curry((a, b) =>
  R.equals(
    phoneUtil.isNumberMatch(a.toString(), b.toString()),
    PhoneNumberUtil.MatchType.EXACT_MATCH
  )
);

export const isValidEmail = testRegex(
  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/i
);

export const isNonEmptyString = R.both(
  R.is(String),
  R.complement(R.propEq('length', 0))
);

export const hasLowerCase = (str: string) => str.toUpperCase() !== str;

export const hasUpperCase = (str: string) => str.toLowerCase() !== str;

/**
 * Parse a JSON string safely. Instead of throwing on error, returns `undefined`
 * and calls `errorCallback` for side effect (e.g. logging).
 */
export const safeParseJSON = errorCallback => jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    errorCallback(e, jsonString);
    return undefined;
  }
};

/**
 * Insert (or replace) a query param (key/value) in a URL
 */
export const insertQueryParam = ([key, value]: [string, string]) => {
  const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  return (url: string) => {
    const [urlWithoutHash, originalHash] = url.split('#');
    const hash = originalHash ? `#${originalHash}` : '';
    const separator = urlWithoutHash.indexOf('?') !== -1 ? '&' : '?';

    const outputUrl = url.match(regex)
      ? url.replace(regex, `$1${key}=${value}$2`)
      : `${urlWithoutHash}${separator}${key}=${value}`;

    return `${outputUrl}${hash}`;
  };
};

/**
 * Relace "vars" dictionary in string. String should have placeholders wrapped in {{}}.
 * replaceVars({ who: 'you' })('{{who}} is cool?') // returns "you is cool?"
 */
export const replaceVars =
  (vars: { [index: string]: string | number }) => (str: string) =>
    str.replace(createReplaceVarsExp(vars), replaceSubmatch(vars));
const replaceSubmatch: (vars: {
  [index: string]: string | number;
}) => (substring: string, ...args: any[]) => string = vars =>
  R.pipe(R.nthArg(1), R.flip(R.prop)(vars) as any);
const createReplaceVarsExp = (obj: { [index: string]: string | number }) =>
  new RegExp(`{{(${Object.keys(obj).join('|')})}}`, 'g');

/**
 * Convert markdown string to react node, can be used in JSX.
 */
export const markdownJSX = markdown => marksy({ createElement })(markdown).tree;

export const simpleTemplate = (
  str: string,
  params: { [index: string]: string | number },
  defaultReplacement = ''
) =>
  Object.entries(params)
    .reduce(
      (result, [k, v]) =>
        result.replace(new RegExp(`{${k}}`, 'gi'), v.toString()),
      str
    )
    .replace(/{.*?}/g, defaultReplacement);

export const splitOnLast = (str, character) => {
  const lastIndex = str.lastIndexOf(character);
  return [str.substr(0, lastIndex), str.substr(lastIndex + 1)];
};

export const prefixIfNotEmpty = (prefix: string) => (str: string) =>
  typeof str === 'string' && str.length > 0 ? `${prefix}${str}` : str;

export enum Comparation {
  Lower,
  Greater,
  Equal,
}

export const compareVersions = (a: string, b: string): Comparation => {
  const aParts = a.split('.').map(Number).slice(0, 3);
  const bParts = b.split('.').map(Number).slice(0, 3);

  while (aParts.length < bParts.length) aParts.push(0);
  while (bParts.length < aParts.length) bParts.push(0);

  return compareVersionsArray(aParts, bParts);
};

const compareVersionsArray = (
  [aHead, ...aTail]: number[],
  [bHead, ...bTail]: number[]
): Comparation =>
  // eslint-disable-next-line no-nested-ternary
  aHead === bHead
    ? aTail.length === 0 && bTail.length === 0
      ? Comparation.Equal
      : compareVersionsArray(aTail, bTail)
    : aHead > bHead
    ? Comparation.Greater
    : Comparation.Lower;

export const getAsciiCharacter = letter => {
  return letter
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z]/, '');
};

export const isVersionGreaterThan = (a: string, b: string): boolean =>
  compareVersions(a, b) === Comparation.Greater;

export const isVersionLowerThan = (a: string, b: string): boolean =>
  compareVersions(a, b) === Comparation.Lower;

export const isSameVersion = (a: string, b: string): boolean =>
  compareVersions(a, b) === Comparation.Equal;

export const isVersionGreaterOrEqualThan = (a: string, b: string): boolean => {
  const comparation = compareVersions(a, b);
  return (
    comparation === Comparation.Greater || comparation === Comparation.Equal
  );
};

export const isVersionLowerOrEqualThan = (a: string, b: string): boolean => {
  const comparation = compareVersions(a, b);
  return comparation === Comparation.Lower || comparation === Comparation.Equal;
};

export const sliceLargeText = (text, length) =>
  text.length >= length ? `${text.substr(0, length)}...` : text;

export const replaceLargeText = (text, length, replacementText) =>
  text.length >= length ? replacementText : text;

export const getSemver = (version: string) => {
  const matches = version.match(/(([0-9]+)\.([0-9]+)\.([0-9]+))/);
  return matches && matches.length ? matches[0] : version;
};

/**
 * this helper converts an array of strings into a comma separated list
 * and before the last element add the conjunction particle e.g and in en
 * Adapts the conjunction particle depending on the given locale
 */
export const formatArrayIntoCommaList = (
  strings: Array<string> = [],
  locale = 'en'
) => {
  const formatter = new Intl.ListFormat(locale, { type: 'conjunction' });
  return formatter.format(strings);
};
