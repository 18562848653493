import { Action } from 'redux';
import { SchoolTimes } from '../../../records/studentRules/types/schoolTimes.type';
import { StudentPause } from '../../../records/studentRules/types/studentPause.type';
import { StudentDelegation } from '../../../records/studentRules/types/studentDelegation.type';
import { StudentActiveClass } from '../../../records/studentRules/types/studentActiveClass.type';

export type StudentPoliciesActionTypes =
  | 'REQUEST_STUDENT_POLICIES'
  | 'RECEIVE_STUDENT_POLICIES'
  | 'RECEIVE_DELEGATION_STUDENT_POLICIES'
  | 'CLEAR_STUDENT_POLICY'
  | 'FINISH_LOADING_STUDENT_POLICIES'
  | 'RESET_STUDENT_POLICY_ERROR'
  | 'RECEIVE_STUDENT_POLICY_ERROR';

export type StudentPoliciesAction =
  | Action<'REQUEST_STUDENT_POLICIES'>
  | (Action<'RECEIVE_STUDENT_POLICIES'> & {
      payload: {
        records: {
          studentPause?: StudentPause;
          schoolTimes?: SchoolTimes;
          studentDelegation?: StudentDelegation;
          studentActiveClass?: StudentActiveClass;
        };
      };
      receivedAt: number;
    })
  | (Action<'RECEIVE_DELEGATION_STUDENT_POLICIES'> & {
      payload: {
        records: {
          studentDelegation: StudentDelegation;
        };
      };
      receivedAt: number;
    })
  | (Action<'CLEAR_STUDENT_POLICY'> & {
      payload: {
        records: {
          studentPause?: StudentPause;
          schoolTimes?: SchoolTimes;
          studentDelegation?: StudentDelegation;
        };
      };
      receivedAt: number;
    })
  | (Action<'FINISH_LOADING_STUDENT_POLICIES'> & {
      receivedAt: number;
    })
  | Action<'RESET_STUDENT_POLICY_ERROR'>
  | (Action<'RECEIVE_STUDENT_POLICY_ERROR'> & {
      payload: StudentPolicyError;
    });

export enum StudentPolicyError {
  Unlinked = 'unlinked',
  UnhandledServerError = 'UnhandledServerError',
  FeatureDisabled = 'FeatureDisabled',
}
