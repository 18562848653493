import { connect } from 'react-redux';
import DelegationRuleInfoModal from '../../components/Modal/DelegationRuleInfoModal';
import { goBackIfHistory } from '../../ducks/routing';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const DelegationRuleInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(DelegationRuleInfoModal);

export type DelegationRuleInfoModalProps = ReturnType<
  typeof mapDispatchToProps
>;

export default DelegationRuleInfoModalContainer;
