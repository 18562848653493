import * as React from 'react';
import { Drawer, FlexLayout, IconType } from 'styleguide-react';
import WellbeingTherapyPreferencesSelector from '../WellbeingTherapyPreferencesSelector/WellbeingTherapyPreferencesSelector';
import RenderWhen, {
  ScreenSize,
  isDesktop,
  isMobile,
} from '../../RenderWhen/RenderWhen';
import DrawerStepWrapper from '../../DrawerStepWrapper/DrawerStepWrapper';
import NavHeader from '../../Routines/Headers/NavHeader';
import ButtonFooter from '../../Routines/Footers/ButtonFooter';
import { t } from '../../../lib/i18n';
import useMergedState from '../../../hooks/useMergedState';

const getItems = () =>
  ({
    communication_skills: {
      icon: IconType.comment,
      label: 'Communication skills',
      isSelected: false,
      isDisabled: false,
      value: 'communication_skills',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    healthy_lifesyle_behaviours: {
      icon: IconType.heartPulse,
      label: 'Healthy lifestyle behaviors',
      isSelected: false,
      isDisabled: false,
      value: 'healthy_lifesyle_behaviours',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    emotional_support: {
      icon: IconType.faceSmileRelaxed,
      label: 'Emotional support',
      isSelected: false,
      isDisabled: false,
      value: 'emotional_support',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    conflict_resolution: {
      icon: IconType.lightbulbOn,
      label: 'Conflict resolution',
      isSelected: false,
      isDisabled: false,
      value: 'conflict_resolution',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    quality_time: {
      icon: IconType.star,
      label: 'Quality time',
      isSelected: false,
      isDisabled: false,
      value: 'quality_time',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    education_and_learning: {
      icon: IconType.graduationCap,
      label: 'Education & learning',
      isSelected: false,
      isDisabled: false,
      value: 'education_and_learning',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    mental_health: {
      icon: IconType.headSideHeart,
      label: 'Mental health',
      isSelected: false,
      isDisabled: false,
      value: 'mental_health',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    eating_habits: {
      icon: IconType.utensils,
      label: 'Eating habits',
      isSelected: false,
      isDisabled: false,
      value: 'eating_habits',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    parenting_and_coparenting: {
      icon: IconType.handHoldingHeard,
      label: 'Parenting & Co-parenting',
      isSelected: false,
      isDisabled: false,
      value: 'parenting_and_coparenting',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
    overall_family_wellbeing: {
      icon: IconType.familyPants,
      label: 'Overall family wellbeing',
      isSelected: false,
      isDisabled: false,
      value: 'overall_family_wellbeing',
      fieldGroupName: 'wellbeing-therapy-preferences',
    },
  } as const);

type Items = ReturnType<typeof getItems>;

type ItemValue = keyof ReturnType<typeof getItems>;

const updateItems = (items: Items, key: ItemValue, checked: boolean) => {
  return {
    ...items,
    [key]: {
      ...items[key],
      isSelected: checked,
    },
  };
};

const getSelectedItemValues = (items: Items) => {
  return Object.values(items)
    .filter(item => item.isSelected)
    .map(item => item.value);
};

interface WellbeingTherapyDrawerProps {
  isOpen: boolean;
  onClickButton: (data: string[]) => void;
  closeDrawer: () => void;
  isDisabledButton: boolean;
}

type DrawerState = {
  items: Items;
};

const WellbeingTherapyDrawer = ({
  isOpen,
  onClickButton,
  closeDrawer,
  isDisabledButton,
}: WellbeingTherapyDrawerProps) => {
  const [state, setState] = useMergedState<DrawerState>({ items: getItems() });

  return (
    <Drawer from="right" isOpen={isOpen}>
      <RenderWhen
        screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
      >
        {screenSize => (
          <FlexLayout
            mainaxis="column"
            backgroundColor="white"
            width={isMobile(screenSize) ? '100vw' : '440'}
          >
            <DrawerStepWrapper
              header={
                <NavHeader
                  onPrev={isMobile(screenSize) ? closeDrawer : undefined}
                  onClose={isDesktop(screenSize) ? closeDrawer : undefined}
                  hasShadow={false}
                />
              }
              footer={
                <ButtonFooter
                  disabled={isDisabledButton}
                  text={t('Continue')}
                  onClick={() => {
                    onClickButton(getSelectedItemValues(state.items));
                    setState({ items: getItems() });
                  }}
                />
              }
            >
              <WellbeingTherapyPreferencesSelector
                items={Object.values(state.items)}
                onChangeItemSelection={(value: string, checked: boolean) => {
                  setState({
                    items: updateItems(
                      state.items,
                      value as ItemValue,
                      checked
                    ),
                  });
                }}
              />
            </DrawerStepWrapper>
          </FlexLayout>
        )}
      </RenderWhen>
    </Drawer>
  );
};

export default WellbeingTherapyDrawer;
