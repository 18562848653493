import { List } from 'immutable';
import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';
import { policyAction } from './types/Policy.types';
import {
  PolicyWebCategoryRules,
  PolicyWebCategoryRulesPayload,
} from './types/PolicyWebCategoryRules.types';
import { PolicyWebCategoryOperations } from './policyWebCategory';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../../api/mappings/valueRestrictions';

export const PolicyWebCategoryRulesOperations = getRecordOperations<
  PolicyWebCategoryRules,
  PolicyWebCategoryRulesPayload
>({
  defaultFields: {
    defaultAction: null,
    webRulesList: List([]),
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    defaultAction: restrictKeyFromValue(payload?.default_action, policyAction),
    webRulesList: payload.web_rules_list
      ? List(
          payload.web_rules_list.map(v =>
            PolicyWebCategoryOperations.fromPayload(v)
          )
        )
      : List([]),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    default_action: restrictValueFromKey(record?.defaultAction, policyAction),
    web_rules_list: record?.webRulesList?.size
      ? record.webRulesList
          .map(v => PolicyWebCategoryOperations.serialize(v))
          .toJS()
      : [],
  }),
});
