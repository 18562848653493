import * as React from 'react';
import { t } from '../../../lib/i18n';
import {
  FlexLayout,
  Typography,
  Button,
  ButtonSize,
  ButtonColor,
  FixedCtaCard,
} from 'styleguide-react';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';

interface WellbeingFeatureGetStartedSectionProps {
  screenSize: ScreenSize;
  title: string;
  description: string;
  image: React.ReactNode;
  weeklyPrice: string;
  kickoffSessionPrice?: string;
  buttonText: string;
  onClickButton: () => void;
  isDisabledButton?: boolean;
}

const WellbeingFeatureGetStartedSection = ({
  screenSize,
  title,
  description,
  image,
  weeklyPrice,
  kickoffSessionPrice,
  buttonText,
  onClickButton,
  isDisabledButton = false,
}: WellbeingFeatureGetStartedSectionProps) => {
  const responsiveProp = (desktopValue, mobileValue) => {
    return isDesktop(screenSize) ? desktopValue : mobileValue;
  };

  const priceAmount = (amount: string) => (
    <Typography type="title2" weight="semibold" renderAs="span">
      {amount}
    </Typography>
  );

  const priceSmallprint = (smallprint: string) => (
    <Typography
      type="caption1"
      weight="semibold"
      renderAs="span"
      color="gray-semi"
      className="par-wellbeing-feature__price-smallprint"
    >
      {smallprint}
    </Typography>
  );

  const subscriptionPrice = () => (
    <Typography type="body2">
      {t('{1}only{/1} {2}{{subscriptionPrice}}{/2}/week', {
        1: (str: string) => priceSmallprint(str),
        2: (str: string) => priceAmount(str),
        subscriptionPrice: weeklyPrice,
      })}
    </Typography>
  );

  const subscriptionPriceWithKickoff = () => (
    <Typography type="body2">
      {t(
        '{1}{{kickOffPrice}}{/1}/Kickoff session {2}then {{subscriptionPrice}}/week{/2}',
        {
          1: (str: string) => priceAmount(str),
          2: (str: string) => priceSmallprint(str),
          kickOffPrice: kickoffSessionPrice,
          subscriptionPrice: weeklyPrice,
        }
      )}
    </Typography>
  );

  return (
    <FlexLayout
      mainaxis={responsiveProp('row', 'column')}
      paddingLeft={responsiveProp('32', '24')}
      paddingRight={responsiveProp('32', '24')}
      paddingTop="24"
      paddingBottom="24"
      crossaxisAlignment={responsiveProp('stretch', 'center')}
      width="100%"
      gap={responsiveProp('32', '16')}
      className="par-wellbeing-feature-get-started"
    >
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        width={responsiveProp('50%', '100%')}
        className="par-wellbeing-feature-get-started__left-col"
      >
        {image}
      </FlexLayout>

      <FlexLayout
        gap="16"
        mainaxis="column"
        width={responsiveProp('50%', '100%')}
        crossaxisAlignment={responsiveProp('start', 'center')}
        mainaxisAlignment="center"
        paddingTop={responsiveProp('56', '0')}
        paddingBottom={responsiveProp('56', '0')}
      >
        <Typography
          type={responsiveProp('headline2', 'title2')}
          weight="semibold"
          align={responsiveProp('left', 'center')}
          className="par-wellbeing-feature__title"
        >
          {title}
        </Typography>

        <FlexLayout
          mainaxis="column"
          width="100%"
          maxWidth="520"
          mainaxisAlignment={responsiveProp('left', 'center')}
          textAlignement={responsiveProp('left', 'center')}
        >
          <Typography
            type={responsiveProp('body1', 'body2')}
            align={responsiveProp('left', 'center')}
          >
            {description}
          </Typography>
        </FlexLayout>

        <RenderWhen screenSize={ScreenSize.Desktop}>
          {kickoffSessionPrice
            ? subscriptionPriceWithKickoff()
            : subscriptionPrice()}
          <Button
            centered
            onClick={onClickButton}
            size={ButtonSize.medium}
            loading={isDisabledButton}
          >
            {buttonText}
          </Button>
        </RenderWhen>

        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <FixedCtaCard
            title={
              kickoffSessionPrice
                ? t('Kickoff session for {{kickOffPrice}}', {
                    kickOffPrice: kickoffSessionPrice,
                  })
                : t('Only {{subscriptionPrice}}/week', {
                    subscriptionPrice: weeklyPrice,
                  })
            }
            description={
              kickoffSessionPrice
                ? t('then {{subscriptionPrice}}/week', {
                    subscriptionPrice: weeklyPrice,
                  })
                : undefined
            }
            backgroundColor="gradient-brand"
            button={{
              block: true,
              onClick: onClickButton,
              size: ButtonSize.medium,
              loading: isDisabledButton,
              children: buttonText,
              color: ButtonColor.wellbeing,
            }}
          />
        </RenderWhen>
      </FlexLayout>
    </FlexLayout>
  );
};

export default WellbeingFeatureGetStartedSection;
