import { fromJS } from 'immutable';
import * as camelize from 'camelize';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import { AccountRecord } from '../records/account';
import { AccountOptionsRecord } from '../records/accountOptions';
import api from '../api';
import { fetchLicense } from './license';
import { gaSetAccountId, tapReject, strToBool } from '../helpers';
import { identify } from '../helpers/analytics';
import { APPThunk } from '../helpers/thunks';
import {
  updatePrevState,
  clearPrevState,
  ACTION_TARGET_ACCOUNT,
} from './previousState';
import {
  injectRequestParamsTransformerIfNeeded,
  setApiAccount,
} from '../sideEffects/globals';
import {
  getPrevAccountState,
  getAccount,
  hasVisitedFamilyLocator,
  getAccountMasterId,
  isSlaveAccount,
} from '../selectors';
import State, { BaseThunk, Dispatch } from '../store/state';
import { logout } from '../sideEffects/logout';

export const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT';
export const REQUEST_ACCOUNT_ERROR = 'REQUEST_ACCOUNT_ERROR';
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';
export const REQUEST_MODIFY_ACCOUNT = 'REQUEST_MODIFY_ACCOUNT';
export const REQUEST_MODIFY_ACCOUNT_ERROR = 'REQUEST_MODIFY_ACCOUNT_ERROR';
export const RECEIVE_MODIFIED_ACCOUNT = 'RECEIVE_MODIFIED_ACCOUNT';
export const REQUEST_PASSWORD_VALIDATION_ERROR =
  'REQUEST_PASSWORD_VALIDATION_ERROR';
export const RECEIVE_DATALESS_ACCOUNT_UPDATE =
  'RECEIVE_DATALESS_ACCOUNT_UPDATE';
export const RECEIVE_PASSWORD_UPDATE = 'RECEIVE_PASSWORD_UPDATE';
export const PENDING_REQUEST_MODIFY_ACCOUNT = 'PENDING_REQUEST_MODIFY_ACCOUNT';
export const DELAYED_MODIFY_ACCOUNT_TIMEOUT_ID =
  'DELAYED_MODIFY_ACCOUNT_TIMEOUT_ID';
export const SET_IS_NEW_ACCOUNT = 'SET_IS_NEW_ACCOUNT';
export const REQUEST_ACCOUNT_OPTIONS = 'REQUEST_ACCOUNT_OPTIONS';
export const RECEIVE_ACCOUNT_OPTIONS = 'RECEIVE_ACCOUNT_OPTIONS';
export const UPDATE_ACCOUNT_OPTION_FLAGS = 'UPDATE_ACCOUNT_OPTIONS_FLAGS';
export const SET_FAMILY_LOCATOR_AS_VISITED = 'SET_FAMILY_LOCATOR_AS_VISITED ';
export const SET_DELEGATION_WELCOME_DISMISSED =
  'SET_DELEGATION_WELCOME_DISMISSED';
export const SET_ADDITIONAL_PARENT_DELEGATION_WELCOME_DISMISSED =
  'SET_ADDITIONAL_PARENT_DELEGATION_WELCOME_DISMISSED';
export const SET_WEB_ONBOARDING_OPTION = 'SET_WEB_ONBOARDING_OPTION';
export const SET_RATING_POPUP_LAST_DATE = 'SET_RATING_POPUP_LAST_DATE';
export const SET_ADDITIONAL_PARENT_WELCOME_MODAL_VIEWED =
  'SET_ADDITIONAL_PARENT_WELCOME_MODAL_VIEWED';
export const SET_DELEGATION_RULES_MODAL_VIEWED =
  'SET_DELEGATION_RULES_MODAL_VIEWED';
export const SET_ADDITIONAL_PARENT_DELEGATION_RULES_MODAL_VIEWED =
  'SET_ADDITIONAL_PARENT_DELEGATION_RULES_MODAL_VIEWED';
export const SAVE_MASTER_ACCOUNT_DATA = 'SAVE_MASTER_ACCOUNT_DATA';
export const SAVE_MULTIPARENT_FEATURE_FLAG = 'SAVE_MULTIPARENT_FEATURE_FLAG';
export const FLAG_PREFIX = 'par_feat_flag';

export enum AccountStatus {
  Eanbled = 'enabled',
  Disabled = 'disabled',
  Suspended = 'suspended',
  AccessProfileZombie = 'access_profile_zombie',
}

export const extractFeatureFlagsFromOptions = payload =>
  Object.keys(payload)
    .filter(optK => optK.indexOf(FLAG_PREFIX) === 0)
    .reduce((accum, optK) => {
      // eslint-disable-next-line no-param-reassign
      accum[optK] = payload[optK];
      return accum;
    }, {});

export default function account(
  state = fromJS({
    item: AccountRecord(),
    isFetching: false,
    isUpdating: false,
    isNewAccount: false,
    options: AccountOptionsRecord(),
    masterAccountData: {
      name: null,
      email: null,
    },
    multiparentFeatureFlagEnabled: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_ACCOUNT:
      return state.merge({
        isFetching: true,
      });
    case REQUEST_ACCOUNT_ERROR:
      return state.merge({
        isFetching: false,
      });
    case RECEIVE_ACCOUNT:
      return state.merge({
        isFetching: false,
        item: action.payload,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_MODIFY_ACCOUNT:
      return state.merge({
        isUpdating: true,
      });
    case REQUEST_MODIFY_ACCOUNT_ERROR:
      return state.merge({
        isUpdating: false,
      });
    case REQUEST_PASSWORD_VALIDATION_ERROR:
      return state.merge({
        isUpdating: false,
      });
    case RECEIVE_MODIFIED_ACCOUNT:
      return state.merge({
        isUpdating: false,
        isFetching: false,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_DATALESS_ACCOUNT_UPDATE:
      return state.merge({
        isUpdating: false,
      });
    case SET_IS_NEW_ACCOUNT:
      return state.merge({
        isNewAccount: true,
      });
    case REQUEST_ACCOUNT_OPTIONS:
      return state.merge({
        isFetching: true,
      });
    case RECEIVE_ACCOUNT_OPTIONS:
      return state.mergeDeep({
        isFetching: false,
        options: {
          family_locator_visited: strToBool(
            action.payload.family_locator_visited
          ),
          flags: extractFeatureFlagsFromOptions(action.payload),
          web_onboarding_finished: strToBool(
            action.payload.trial_journey_finished
          ),
          rating_popup_last_date: action.payload.rating_popup_last_date || '',
          additional_parent_welcome_modal_viewed: strToBool(
            action.payload.additional_parent_welcome_modal_viewed
          ),
          multiparent_feature_flag_enabled: strToBool(
            action.payload.multiparent_feature_flag_enabled
          ),
          delegation_rules_modal_dismissed: strToBool(
            action.payload.delegation_rules_modal_dismissed
          ),
          additional_parent_delegation_rules_modal_dismissed: strToBool(
            action.payload.additional_parent_delegation_rules_modal_dismissed
          ),
        },
      });
    case SET_WEB_ONBOARDING_OPTION:
      return state.mergeDeep({
        options: {
          web_onboarding_finished: strToBool(action.payload),
        },
      });
    case UPDATE_ACCOUNT_OPTION_FLAGS:
      return state.mergeDeep({
        options: {
          flags: extractFeatureFlagsFromOptions(action.payload),
        },
      });
    case SET_FAMILY_LOCATOR_AS_VISITED:
      return state.mergeDeep({
        options: {
          family_locator_visited: true,
        },
      });
    case SET_DELEGATION_RULES_MODAL_VIEWED:
      return state.mergeDeep({
        options: {
          delegation_rules_modal_dismissed: true,
        },
      });
    case SET_ADDITIONAL_PARENT_DELEGATION_RULES_MODAL_VIEWED:
      return state.mergeDeep({
        options: {
          additional_parent_delegation_rules_modal_dismissed: true,
        },
      });
    case SET_RATING_POPUP_LAST_DATE:
      return state.mergeDeep({
        options: {
          rating_popup_last_date: action.payload,
        },
      });
    case SET_ADDITIONAL_PARENT_WELCOME_MODAL_VIEWED:
      return state.mergeDeep({
        options: {
          additional_parent_welcome_modal_viewed: action.payload,
        },
      });

    case SAVE_MASTER_ACCOUNT_DATA:
      return state.mergeDeep({
        masterAccountData: action.payload,
      });
    case SAVE_MULTIPARENT_FEATURE_FLAG:
      return state.mergeDeep({
        multiparentFeatureFlagEnabled: action.payload,
      });
    default:
      return state;
  }
}

const denormalizeAccountProfile = decamelizeKeysDeep;

export const setWebOnboardingOption = payload => ({
  type: SET_WEB_ONBOARDING_OPTION,
  payload,
});

export function updateAccountOptionFlags(flags) {
  return {
    type: UPDATE_ACCOUNT_OPTION_FLAGS,
    payload: flags,
  };
}

export function requestAccount() {
  return {
    type: REQUEST_ACCOUNT,
  };
}

export function requestAccountError() {
  return {
    type: REQUEST_ACCOUNT_ERROR,
  };
}

export function requestModifyAccount() {
  return {
    type: REQUEST_MODIFY_ACCOUNT,
  };
}

export function requestModifyAccountError() {
  return {
    type: REQUEST_MODIFY_ACCOUNT_ERROR,
  };
}

export function receiveAccount(payload) {
  return {
    type: RECEIVE_ACCOUNT,
    payload: AccountRecord.fromPayload(camelize(payload)),
  };
}

export function requestPasswordValidationError() {
  return {
    type: REQUEST_PASSWORD_VALIDATION_ERROR,
  };
}

export function receiveModifiedAccount() {
  return {
    type: RECEIVE_MODIFIED_ACCOUNT,
  };
}

export function receiveDatalessAccountUpdate() {
  return {
    type: RECEIVE_DATALESS_ACCOUNT_UPDATE,
  };
}

export function setIsNewAccount() {
  return {
    type: SET_IS_NEW_ACCOUNT,
  };
}

export function fetchAccount(): APPThunk {
  return dispatch => {
    dispatch(requestAccount());
    return api.me
      .get({})
      .then(json => {
        /**
         * When a co-parent account is deleted, we can only know it because their
         * `account_status` is returned as `access_profile_zombie`.
         * Ideally backend should invalidate the access tokens of deleted co-parents
         * for the parents-app different client ids, but nowadays that's not easy
         * to do on the API side, so we need to control it somehow.
         *
         * In order to not have this logic spread across the whole code base, we've
         * decided to put it here, so every time the account is requested, if we
         * identify it's from a deleted co-parent, we want to immediately logout the user.
         */
        if (json.account_status === AccountStatus.AccessProfileZombie) {
          dispatch(logout());
          return Promise.reject();
        }

        setApiAccount(json.id, json.uid);
        injectRequestParamsTransformerIfNeeded(
          AccountRecord.fromPayload(camelize(json))
        );

        // Analytics identification (GA and Segment)
        gaSetAccountId(json.id);
        identify(json.uid);

        dispatch(receiveAccount(json));
        return dispatch(fetchLicense());
      })
      .catch(tapReject(() => dispatch(requestAccountError())));
  };
}

export function updateAccount(account) {
  return (dispatch, getState) => {
    const prevAccount = AccountRecord.serialize(getAccount(getState()));
    dispatch(updatePrevState(prevAccount, ACTION_TARGET_ACCOUNT));
    dispatch(receiveAccount(AccountRecord.serialize(account)));
  };
}

export function reverseAccountUpdate() {
  return (dispatch, getState) => {
    const prevAccountState = getPrevAccountState(getState());
    dispatch(receiveAccount(AccountRecord.serialize(prevAccountState)));
    dispatch(clearPrevState(ACTION_TARGET_ACCOUNT));
  };
}

export function modifyAccount(account) {
  return dispatch => {
    dispatch(requestModifyAccount());
    return api.account
      .put({}, denormalizeAccountProfile(AccountRecord.serialize(account)))
      .then(() => dispatch(receiveModifiedAccount()));
  };
}

export function modifyPassword(passwords) {
  return dispatch => {
    return api.password.put({}, passwords).then(() => {
      dispatch(receiveDatalessAccountUpdate());
    });
  };
}

export function modifyEmail(emailChange) {
  return (dispatch, getState) => {
    return api.email
      .put({}, emailChange)
      .then(() => dispatch(receiveDatalessAccountUpdate()))
      .then(() => {
        const prevAccount = AccountRecord.serialize(getAccount(getState()));
        return dispatch(updatePrevState(prevAccount, ACTION_TARGET_ACCOUNT));
      });
  };
}

export const getAccountOptions = (accountOptionsResponse: any) =>
  accountOptionsResponse.reduce((options, currentOption) => {
    return {
      ...options,
      [currentOption.key]: currentOption.value,
    };
  }, {});

export const fetchAccountOptions =
  (): BaseThunk<Promise<any>> => (dispatch, getState) => {
    dispatch(requestAccountOptions());

    const requests = [api.options.get({})];
    if (isSlaveAccount(getState())) {
      requests.push(api.guestAccountOptions.get({}));
    }

    return Promise.all(requests).then(accountOptionsResponses => {
      const options = accountOptionsResponses.reduce((acc, current) => {
        return [...acc, ...current];
      }, []);
      return dispatch(receiveAccountOptions(getAccountOptions(options)));
    });
  };

export const requestAccountOptions = () => ({
  type: REQUEST_ACCOUNT_OPTIONS,
});

export const receiveAccountOptions = (options: any) => ({
  type: RECEIVE_ACCOUNT_OPTIONS,
  payload: options,
});

export const setFamilyLocatorAsVisited = () => ({
  type: SET_FAMILY_LOCATOR_AS_VISITED,
});

export const setDelegationRulesModalDismissed = () => ({
  type: SET_DELEGATION_RULES_MODAL_VIEWED,
});

export const setAdditionalParentRulesModalDismissed = () => ({
  type: SET_ADDITIONAL_PARENT_DELEGATION_RULES_MODAL_VIEWED,
});

export const setRatingPopupLastDate = (
  ratingPopupLastDate: string | undefined
) => ({
  type: SET_RATING_POPUP_LAST_DATE,
  payload: ratingPopupLastDate,
});

export const setAdditionalParentWelcomeModalViewed = (viewed: boolean) => ({
  type: SET_ADDITIONAL_PARENT_WELCOME_MODAL_VIEWED,
  payload: viewed,
});

export const setFamilyLocatorAsVisitedIfNeeded = () => (dispatch, getState) => {
  if (hasVisitedFamilyLocator(getState())) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return api.options
    .post({ family_locator_visited: 'true' })
    .then(() => dispatch(setFamilyLocatorAsVisited()));
};

export const postDelegationRulesModalDismissed =
  (): BaseThunk => (dispatch, getState) => {
    const additionalParent = isSlaveAccount(getState());
    const key = additionalParent
      ? 'additional_parent_delegation_rules_modal_dismissed'
      : 'delegation_rules_modal_dismissed';

    return api.guestAccountOptions
      .post({
        [key]: 'true',
      })
      .then(() =>
        additionalParent
          ? dispatch(setAdditionalParentRulesModalDismissed())
          : dispatch(setDelegationRulesModalDismissed())
      );
  };

export const fetchMasterAccount =
  () => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();

    if (isSlaveAccount(state)) {
      const { name, email } = await api.otherAccount.get({
        id: getAccountMasterId(state)!,
      });

      dispatch(
        saveMasterAccountData({
          name,
          email,
        })
      );
    }
  };

export const saveMasterAccountData = (payload: {
  name: string;
  email: string;
}) => ({
  type: SAVE_MASTER_ACCOUNT_DATA,
  payload,
});

export const saveMultiParentFeatureFlag = (payload: boolean) => ({
  type: SAVE_MULTIPARENT_FEATURE_FLAG,
  payload,
});

export const fetchMultiParentFeatureFlag = () => async (dispatch: Dispatch) => {
  const { enabled } = await api.multiParentFeatureFlag.get({});
  dispatch(saveMultiParentFeatureFlag(enabled));
};
