import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import {
  trackAccountSettingUpdate,
  trackUpgradeToPremium,
  UpgradeToPremiumRuleNames,
  trackDeleteAccountButtonClicked,
  GenericPageNames,
} from '../../helpers/analytics';
import { AccountRecord } from '../../records';
import { isPremium } from '../../records/license';
import type { LicenseRecord } from '../../records/license';
import AccountSettings from '../../components/AccountSettings/AccountSettings';
import { delayedModifyAccount } from '../../ducks/app';
import {
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { fetchDevices } from '../../ducks/devices';
import { SettingsToggleNames, LINK_DEVICES } from '../../constants';
import {
  getAccount,
  getLicense,
  getCurrentTime,
  getAccountLocale,
  isSlaveAccount,
  shouldDisplayMultiparentFeature,
  shouldDisplayManageSubscriptionLink,
  isPurchasedSubscriptionFromGooglePlay,
  getAccountMasterName,
} from '../../selectors';
import { fetchInvitations } from '../../ducks/invitations/invitationsActions';
import { showErrorToast } from './helpers';
import * as qinit from '../../qinit';
import flags from '../../sideEffects/flags';
import { simpleTemplate } from '../../helpers/string';
import {
  getGooglePlaySubscriptionCenterURL,
  getHelpCancelSubscriptionURL,
} from '../../helpers/googlePlay';
import State, { Dispatch } from '../../store/state';
import { APPThunk } from '../../helpers/thunks';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = (state: State) => {
  const toggles = [
    {
      id: SettingsToggleNames.IsDailyReport,
      label: t('Daily Email Reports'),
      description: t('Receive a daily activity report by email.'),
      checked: getAccount(state).isDailyReport,
      testId: 'dailyEmailReports',
    },
    {
      id: SettingsToggleNames.IsWeeklyReport,
      label: t('Weekly Email Summary'),
      description: t('Receive a weekly activity report by email.'),
      checked: getAccount(state).isWeeklyReport,
      testId: 'weeklyEmailSummary',
    },
  ];
  return {
    toggles,
    account: getAccount(state),
    license: getLicense(state),
    currentTime: getCurrentTime(state),
    isSlaveAccount: isSlaveAccount(state),
    masterAccountName: getAccountMasterName(state),
    multiParentInitiativeEnabled:
      flags.multiParentInitiative.isEnabled() && isSlaveAccount(state),
    showCloseAccount: flags.closeAccount.isEnabled() && !isSlaveAccount(state),
    shouldDisplayMultiparentFeature: shouldDisplayMultiparentFeature(state),
    shouldDisplayManageSubscriptionLink:
      shouldDisplayManageSubscriptionLink(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const navigate = getMultiPlatformNavigation();

  return {
    onClickLink: (link: string) => {
      dispatch(navigate({ type: 'inner', src: link }));
    },
    onClickChildrenAndDevices: () => {
      dispatch(navigate({ type: 'inner', src: { pathname: LINK_DEVICES } }));
    },
    onToggle: (toggleTarget: SettingsToggleNames, toggleOn: boolean) => {
      trackAccountSettingUpdate(toggleTarget, toggleOn);
      dispatch(onToggle(toggleTarget, toggleOn));
    },
    onBadgeClick: (license: LicenseRecord) => () => {
      trackUpgradeToPremium(
        isPremium(license.type)
          ? UpgradeToPremiumRuleNames.EXPAND_PLAN
          : UpgradeToPremiumRuleNames.UPGRADE_PLAN
      );
    },
    onClickCloseAccount: () => {
      dispatch(closeAccount());
    },
    onManageSubscription: () => {
      dispatch((_dispatch, getState) => {
        const url = isPurchasedSubscriptionFromGooglePlay(getState())
          ? getGooglePlaySubscriptionCenterURL()
          : getHelpCancelSubscriptionURL();

        _dispatch(
          navigate({
            type: 'external',
            src: url,
          })
        );
      });
    },
  };
};

const onToggle =
  (toggleTarget: SettingsToggleNames, toggleOn: boolean): APPThunk =>
  (dispatch, getState) =>
    Promise.resolve(setAccountRecord(toggleTarget, toggleOn, getState()))
      .then(account => {
        return dispatch(delayedModifyAccount([account], 1000));
      })
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(reverseAccountUpdate());
        dispatch(showErrorToast(error));
      });

const setAccountRecord = (
  toggleTarget: SettingsToggleNames,
  toggleOn: boolean,
  state: State
) => {
  const account = getAccount(state).set(toggleTarget, toggleOn);
  return AccountRecord(account);
};

const closeAccount = (): APPThunk => (dispatch, getState) => {
  const navigate = getMultiPlatformNavigation();
  trackDeleteAccountButtonClicked(GenericPageNames.Account);
  dispatch(
    navigate({
      type: 'external',
      src: simpleTemplate(
        qinit.tenant.marketing.public_site.help_close_account,
        {
          lang: getAccountLocale(getState()),
        }
      ),
      target: '_system',
    })
  );
};

const AccountSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);

AccountSettingsContainer.load = (): APPThunk => dispatch => {
  dispatch(fetchDevices());
  dispatch(fetchInvitations());
};

export type AccountSettingsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default AccountSettingsContainer;
