import * as React from 'react';
import * as R from 'ramda';
import { EventRecord } from '../../records';

import {
  ACTION_ALERT,
  ACTION_ALLOW,
  ACTION_BLOCK,
  EventType,
  PLATFORM_IOS,
  SearchEngine,
} from '../../constants';

import { replaceS3Url } from '../../helpers';
import {
  ActivityColors,
  ActivityIconSizes,
  AppActivityIcon,
  IncomingCallActivityIcon,
  IncomingSmsActivityIcon,
  LocationActivityIcon,
  LocationActivityState,
  MissedCallActivityIcon,
  OutgoingCallActivityIcon,
  OutgoingSmsActivityIcon,
  PanicActivityIcon,
  PanicActivityState,
  SafeNetworkActivityIcon,
  SearchActivityIcon,
  SocialActivityIcon,
  SocialActivityPlatforms,
  WebActivityColors,
  WebActivityIcon,
  YouTubeActivityIcon,
} from '../Activity/ActivityIcons';
import { EventCategoryType } from './types';

const ActivityTimelineItemIcon: React.FunctionComponent<{
  event: EventRecord;
  type: EventCategoryType;
}> = ({ event, type }) => {
  const className = 'ActivityTimelineItemIcon';

  switch (event.type) {
    case EventType.App:
      if (event.name && event.name.toLowerCase() === 'youtube') {
        return (
          <YouTubeActivityIcon
            className={className}
            size={ActivityIconSizes.Big}
          />
        );
      }
      return event.thumbnail ? (
        <AppActivityIcon
          className={className}
          src={replaceS3Url(event.thumbnail)}
          isIOS={event.platform === PLATFORM_IOS}
          size={ActivityIconSizes.Big}
        />
      ) : (
        <AppActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
          isIOS={false}
        />
      );

    case EventType.CallsIncoming:
    case EventType.CallsBlockedIncoming:
      return (
        <IncomingCallActivityIcon
          className={className}
          color={
            event.type === EventType.CallsBlockedIncoming
              ? ActivityColors.Danger
              : ActivityColors.Success
          }
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.CallsOutgoing:
    case EventType.CallsBlockedOutgoing:
      return (
        <OutgoingCallActivityIcon
          className={className}
          color={
            event.type === EventType.CallsBlockedOutgoing
              ? ActivityColors.Danger
              : ActivityColors.Success
          }
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.CallsMissed:
      return (
        <MissedCallActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
          color={ActivityColors.Warning}
        />
      );

    case EventType.SmsIncoming:
    case EventType.SmsBlockedIncoming:
      return (
        <IncomingSmsActivityIcon
          className={className}
          color={
            event.type === EventType.SmsBlockedIncoming
              ? ActivityColors.Danger
              : ActivityColors.Success
          }
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.SmsOutgoing:
    case EventType.SmsBlockedOutgoing:
      return (
        <OutgoingSmsActivityIcon
          className={className}
          color={
            event.type === EventType.SmsBlockedOutgoing
              ? ActivityColors.Danger
              : ActivityColors.Success
          }
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.FacebookSocial:
    case EventType.FacebookSocialContact:
    case EventType.FacebookSocialNetwork:
    case EventType.FacebookSocialContactNew:
    case EventType.FacebookSocialActivity:
    case EventType.FacebookSocialActivityPost:
    case EventType.FacebookSocialActivityUserPost:
    case EventType.FacebookSocialActivityUserProfile:
    case EventType.FacebookSocialActivityUserComment:
    case EventType.FacebookSocialActivityUserLike:
    case EventType.FacebookSocialActivityUserEvent:
    case EventType.FacebookSocialActivityContactPost:
    case EventType.FacebookSocialActivityContactComment:
    case EventType.FacebookSocialActivityContactLike:
    case EventType.FacebookSocialActivityUserContactPost:
    case EventType.FacebookSocialActivityUserLink:
    case EventType.FacebookSocialActivityUserSharePhoto:
    case EventType.FacebookSocialMedia:
    case EventType.FacebookSocialMediaUserPhoto:
    case EventType.FacebookSocialMediaContactPhoto:
      return (
        <SocialActivityIcon
          className={className}
          platform={SocialActivityPlatforms.Facebook}
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.Twitter:
      return (
        <SocialActivityIcon
          className={className}
          platform={SocialActivityPlatforms.Twitter}
          size={ActivityIconSizes.Big}
        />
      );

    case EventType.Location:
    case EventType.LocationLast:
    case EventType.LocationUnvailable: {
      let locationState;
      if (event.type === EventType.LocationUnvailable) {
        locationState = LocationActivityState.Unavailable;
      } else if (event.panicMode) {
        locationState = LocationActivityState.Panic;
      }
      return (
        <LocationActivityIcon
          className={className}
          state={locationState}
          size={ActivityIconSizes.Big}
        />
      );
    }
    case EventType.Panic:
      return (
        <PanicActivityIcon
          className={className}
          state={
            event.panicMode
              ? PanicActivityState.Enabled
              : PanicActivityState.Disabled
          }
          size={ActivityIconSizes.Big}
        />
      );
    case EventType.Youtube:
      return (
        <YouTubeActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
        />
      );
    case EventType.Web:
      return (
        <WebActivityIcon
          className={className}
          color={R.cond<any, WebActivityColors | undefined>([
            [R.equals(ACTION_ALLOW), R.always(WebActivityColors.Success)],
            [R.equals(ACTION_BLOCK), R.always(WebActivityColors.Danger)],
            [R.equals(ACTION_ALERT), R.always(WebActivityColors.Warning)],
            [R.T, R.always(undefined)],
          ])(event.action.action)}
          size={ActivityIconSizes.Big}
        />
      );
    case EventType.Search:
      return event.searchEngine &&
        event.searchEngine === SearchEngine.Youtube ? (
        <YouTubeActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
        />
      ) : (
        <SearchActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
          type={type}
        />
      );
    case EventType.SafeNetworkEnablingProtection:
    case EventType.SafeNetworkDisablingProtection:
      return (
        <SafeNetworkActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
        />
      );
    default:
      return (
        <AppActivityIcon
          className={className}
          size={ActivityIconSizes.Big}
          isIOS={false}
        />
      );
  }
};

export default ActivityTimelineItemIcon;
