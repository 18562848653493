export enum ActivitySummaryFilters {
  NotBlocked = 'not_blocked',
  BlockedExplicitly = 'blocked_explicitly',
}

export enum ActivitySummaryOrderBy {
  BlockedPagesDesc = '-blocked_pages',
  MinutesDesc = '-minutes',
  Id = 'id',
}
