import * as React from 'react';
import { FlexLayout, Layout } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import type { DashboardWidgetsSchoolContainerProps } from '../../../containers/Dashboard/DashboardWidgets/ProfileDashboardWidgetsSchoolContainer';
import StudentTotalUsageCardWithObserverContainer from '../../../containers/Schools/StudentTotalUsageCardWithObserverContainer';
import StudentCategoriesCardWithObserverContainer from '../../../containers/Schools/StudentCategoriesCardWithObserverContainer';
import StudentAppsAndWebsitesCardWithObserverContainer from '../../../containers/Schools/StudentSignaturesCardWithObserverContainer';

const DashboardWidgetSchool = ({
  profileId,
}: DashboardWidgetsSchoolContainerProps) => {
  return (
    <React.Fragment>
      <Layout marginBottom="8" centerX width="100%">
        <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column">
              <Layout margin="8">
                <StudentTotalUsageCardWithObserverContainer
                  profileId={profileId}
                />
              </Layout>
              <Layout margin="8">
                <StudentCategoriesCardWithObserverContainer
                  profileId={profileId}
                />
              </Layout>
            </FlexLayout>
            <FlexLayout mainaxis="column">
              <Layout margin="8">
                <StudentAppsAndWebsitesCardWithObserverContainer
                  profileId={profileId}
                  extraHeight
                />
              </Layout>
            </FlexLayout>
          </FlexLayout>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Mobile}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column" width="100%">
              <Layout margin="8">
                <StudentTotalUsageCardWithObserverContainer
                  profileId={profileId}
                />
              </Layout>
              <Layout margin="8">
                <StudentCategoriesCardWithObserverContainer
                  profileId={profileId}
                />
              </Layout>
              <Layout margin="8">
                <StudentAppsAndWebsitesCardWithObserverContainer
                  profileId={profileId}
                  extraHeight
                />
              </Layout>
            </FlexLayout>
          </FlexLayout>
        </RenderWhen>
      </Layout>
    </React.Fragment>
  );
};

export default DashboardWidgetSchool;
