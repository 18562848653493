import { connect } from 'react-redux';
import ft, { DISABLE_CALLS_SMS_RULES } from '../lib/ft';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../store/state';
import {
  getAccountCurrentTime,
  getActiveDateRange,
  getActivitySwiperIndex,
  getKidHasTamparedDevice,
  getLicense,
  getProfileOrDefault,
  getRangeScreenTime,
  getSelectedDateScreenTime,
  getTimezone,
  isFetchingNewEvents,
  isFetchingProfile,
  isFloatingActionMenuOpen,
  isRefreshingProfile,
  isAccountOverLimit,
  getProfileDevices,
  getIsEmptyTimeline,
  getSummaryAppsAndWebActivity,
} from '../selectors';

import { isNotExistingProfile } from '../records/profile';
import {
  closeFloatingActionMenu,
  toggleFloatingActionMenu,
} from '../ducks/app';
import { fetchDevices } from '../ducks/devices';
import { fetchEvents, setFilter, shouldFetchMoreEvents } from '../ducks/events';
import { ActivityEventFilters } from '../businessLogic/timeline/types';
import { updateCurrentTime } from '../ducks/times';
import { fetchProfile, fetchProfiles } from '../ducks/profiles';
import {
  fetchAppsAndWebActivity,
  fetchBlockedWebActivity,
  fetchRangeScreenTime,
  fetchSelectedDateScreenTime,
  fetchSummary,
  resetSummary,
} from '../ducks/summary';
import { fetchProfileRules } from '../ducks/profileRules';
import { activitySwiperIndexChanged } from '../ducks/ui';
import { goBackIfHistory } from '../ducks/routing';
import {
  fetchCallsAndSmsIfUserIsPremiumOrTrial,
  fetchSearches,
} from '../ducks/socialActivity';

import { isMagicDebugProfile } from '../helpers';
import { AnyError, handleApiErrorMessage } from '../helpers/errorHandling';
import { SummaryDateRanges } from '../constants';
import {
  addDevice,
  refreshProfile,
  selectedNewDateRangeForProfile,
  showStatsNotification,
} from '../actions/ProfileActions';
import { gaEvent } from '../helpers/ga';
import { Events, track } from '../helpers/analytics';
import { fetchYoutubeSummary } from '../ducks/youtubeSummary';
import {
  getFeatureFlag,
  isQuestionableWebSearchesFeactureActive,
} from '../selectors/featureFlags';
import { fetchExtraTime } from '../ducks/extraTime';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { getNowTrackingCardStatus } from './NowCard/helpers/family/helper';
import { fetchCalendarRestrictionPause } from '../ducks/calendarRestrictionsPause';
import { fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork } from '../ducks/safeNetwork/thunks';
import flags from '../sideEffects/flags';
import ProfileFamilySchool from '../components/Profiles/ProfileFamilySchool';
import { selectedNewDateRange } from '../actions/StudentSummaryActions';
import { navigateToFamilyTimeline } from '../actions/Navigation';
import { isCombinedDashboardProfile } from './Dashboard/helpers/profileDashboardHelpers';
import { fetchAccountOptions } from '../ducks/account';
import { convertDate, dayCountDiff, localNow } from '../helpers/dates';
import { isProfileDelegatedToThisAccount } from '../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../selectors/studentPolicies';

const mapStateToProps = (state: State, routerParams: RouterParamsProps) => {
  const { profileId } = routerParams.params;
  const profile = getProfileOrDefault(state, Number(profileId));
  const timezone = getTimezone(state);
  const currentTime = getAccountCurrentTime(state);

  const activeDateRange = getActiveDateRange(state);

  const selectedDateScreenTime = getSelectedDateScreenTime(state);
  const rangeScreenTime = getRangeScreenTime(state).takeLast(activeDateRange);

  const isProfileDelegatedToAccount = isProfileDelegatedToThisAccount(
    isDelegationEnabled(state),
    isStudentOptInForDelegationForThisAccount(state),
    profile.isLinewizestudentLinked
  );

  const isEmptyHistogram =
    activeDateRange === SummaryDateRanges.Today
      ? selectedDateScreenTime.toJS().every(value => value.minutes === 0)
      : rangeScreenTime.toJS().every(value => value.hours === 0);
  const isEmptyTimeline = getIsEmptyTimeline(state);
  const isEmptyTopActivity = getSummaryAppsAndWebActivity(state).size === 0;

  const noProfileActivity =
    isEmptyHistogram && isEmptyTimeline && isEmptyTopActivity;

  const profileWithoutDevices = getProfileDevices(state, profileId).isEmpty();

  const lastSeenInTheLast30Days =
    dayCountDiff(localNow(timezone), convertDate(profile.lastseen, timezone)) <
    30;

  const showDelegationNoActivity =
    flags.useDelegationAutoOptin.isEnabled() &&
    isProfileDelegatedToAccount &&
    activeDateRange === SummaryDateRanges.Today &&
    profileWithoutDevices &&
    noProfileActivity &&
    lastSeenInTheLast30Days;

  return {
    ...routerParams,
    profile,
    isFetching: isFetchingProfile(state) && !isRefreshingProfile(state),
    isFetchingNewEvents: isFetchingNewEvents(state),
    activeDateRange: getActiveDateRange(state),
    isMenuOpen: isFloatingActionMenuOpen(state),
    selectedDateScreenTime,
    rangeScreenTime,
    showStatsNotification: showStatsNotification(
      getLicense(state),
      currentTime,
      timezone
    ),
    activitySwiperIndex: getActivitySwiperIndex(state),
    hasTamperedDevice: getKidHasTamparedDevice(state, profileId),
    showYoutubeCard: getFeatureFlag(state, 'youtubeSummary'),
    showCallsSMSCard: !ft.active(DISABLE_CALLS_SMS_RULES),
    showInsights: getFeatureFlag(state, 'insights'),
    isQuestionableWebSearchesFeactureActive:
      isQuestionableWebSearchesFeactureActive(state),
    showProfileSummarySelector: false,
    routerParams: routerParams.params,
    nowTrackingCardStatus: getNowTrackingCardStatus(state, profileId),
    showDelegationNoActivity,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  handleRefresh: () => dispatch(refreshProfile(Number(profileId))),
  handleMore: () => dispatch(shouldFetchMoreEvents(Number(profileId))),
  onClickEdit: () => {
    track(Events.ClickedProfileEdit);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onSelectDateRange: (range: SummaryDateRanges) => {
    dispatch(selectedNewDateRangeForProfile(range, profileId));
    dispatch(selectedNewDateRange(range, profileId));
  },

  onClickAddDevices: () => {
    return dispatch(addDevice(profileId));
  },
  // Floating button + menu
  onToggleMenu: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'open-close');
    return dispatch(toggleFloatingActionMenu());
  },

  onClickSpeedDialAddDevices: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'add-device');
    dispatch(closeFloatingActionMenu());
    return dispatch(addDevice(profileId));
  },
  onClickSpeedDialSeeTimeline: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'see-timeline');
    dispatch(closeFloatingActionMenu());
    return dispatch(navigateToFamilyTimeline({ profileId }));
  },
  onClickRules: () => {
    const navigate = getMultiPlatformNavigation();
    gaEvent('kid-profile-time-floating-button', 'click', 'set-rules');
    track(Events.BtnClickRules);
    dispatch(closeFloatingActionMenu());
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/rules` })
    );
  },
  onClickGoBack: () => {
    dispatch(activitySwiperIndexChanged(0));
    dispatch(goBackIfHistory());
  },
});

const ProfileFamilySchoolContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileFamilySchool);

ProfileFamilySchoolContainer.load =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    dispatch(resetSummary());
    dispatch(setFilter(ActivityEventFilters.All));
    dispatch(updateCurrentTime());

    const id = Number(params.profileId);
    const state = getState();
    const profile = getProfileOrDefault(state, id);
    const navigate = getMultiPlatformNavigation();
    const profileUid = profile.uid;

    if (isNotExistingProfile(profile)) {
      return dispatch(
        navigate({
          type: 'inner:replace',
          src: '/',
        })
      );
    }

    dispatch(fetchKidDataFor(id));
    dispatch(fetchProfile(params.profileId));
    return dispatch(
      fetchCalendarRestrictionPause(profileUid, params.profileId)
    );
  };

export const fetchKidDataFor =
  (id: number) => (dispatch: Dispatch, getState: () => State) => {
    const profile = getProfileOrDefault(getState(), id);

    return Promise.all([
      dispatch(fetchEvents(profile.uid)),

      dispatch(fetchProfiles()),

      dispatch(fetchProfileRules(id, profile.deviceIds)).then(() =>
        /**
         * The app activities that come from the endpoint
         * "v2/accounts/<ac_uid>/profiles/<profile_uid>/summary/domains-and-apps"
         * do not include a thumbnail link. We add the thumbnail link to each app
         * activity ourselves in case it's available, if not we default to "null".
         *
         * To obtain the thumbnail links for each app we rely on the
         * kidProfileRules endpoint "v1/accounts/${this.accountId}/profiles/${profileId}/rules"
         * This endpoint returns a list of apps that the kid used. For each app in the list,
         * the thumbnail link it's included if available, if not it defaults to "null"
         *
         * So before attempting to call `fetchAppsAndWebActivity` we need to ensure
         * that we have the "kid profile rules" data (which include the thumbnail links).
         * Ideally the thumbnail link will be included along the app activities
         * data that is fetched through `fetchAppsAndWebActivity`
         * I created a backend ticket for handling this at some point (BCK-6335)
         */
        dispatch(fetchAppsAndWebActivity(profile.uid))
      ),
      dispatch(fetchExtraTime(profile.uid)),
      dispatch(fetchSummary(id)),

      dispatch(fetchSelectedDateScreenTime(profile.uid)),
      dispatch(fetchRangeScreenTime(profile.id.toString())),
      dispatch(fetchBlockedWebActivity(profile.uid)),
      dispatch(fetchYoutubeSummary(profile.uid)),
      dispatch(fetchDevices()),
      dispatch(fetchCallsAndSmsIfUserIsPremiumOrTrial(profile.uid)),
      dispatch(fetchSearches(profile.uid)),
    ])
      .then(() => {
        dispatch(
          // This function needs the profile and its devices in the state
          fetchSafeNetworkSettingsIfProfileHasBeenInSafeNetwork(profile.id)
        );
      })
      .catch((error: AnyError) => handleApiErrorMessage(error, true));
  };

ProfileFamilySchoolContainer.redirect =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    const profileId = Number(params.profileId);
    const state = getState();
    const profile = getProfileOrDefault(state, profileId);
    const navigate = getMultiPlatformNavigation();

    // we need to update the account options in order to know if the account
    // has or has not dismissed the welcome delegation modal
    dispatch(fetchAccountOptions());

    if (!flags.useUnifiedDashboard.isEnabled()) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: '/',
          },
        })
      );
    }

    if (!isCombinedDashboardProfile(state, profileId)) {
      if (profile.isLinewizestudentLinked) {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: `/profiles/${profileId}/school/summary`,
          })
        );
      } else {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: `/profiles/${profileId}`,
          })
        );
      }
    }

    if (isAccountOverLimit(state)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: `/modal/OverLimitModal`,
            query: {
              profileId,
            },
          },
        })
      );
    } else if (isMagicDebugProfile(profile)) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/debug/${profileId}`,
        })
      );
    }
    return Promise.resolve();
  };

export default ProfileFamilySchoolContainer;
