import { is, when } from 'ramda';
import { ofType } from 'redux-observable';
import { debounceTime, first, map, pipe } from 'rxjs';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';

export const getProfileTopBarTrackingFormatted = pipe(
  ofType('PROFILE_NOTIFICATION_TOP_BAR_LOADED_STATE'),
  /*
  Due to the implementation of alerts, 
  it is necessary to wait for the correct state to be computed because 
  the component emits the action more than once, as the first one is not valid.
  */
  debounceTime(600),
  first(),
  map(when(is(Object), decamelizeKeysDeep))
);
