import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import {
  getWellbeingSubscriptionStatus,
  getWellbeingSupportAddress,
  getWellbeingSupportEmailSubject,
} from '../../../businessLogic/wellbeing/wellbeing';
import { WellbeingSupportEmailAddress } from '../../../businessLogic/wellbeing/types';
import ContactUsModal from '../../../components/Wellbeing/modals/ContactUsModal';
import { createEmailHref } from '../../../helpers/email';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { getAccountEmail } from '../../../selectors/account';

type WellbeingContactProps = {
  recipientAddress: WellbeingSupportEmailAddress;
  emailSubject: string;
};

const mapStateToProps = (state: State): WellbeingContactProps => {
  const subscriptionStatus = getWellbeingSubscriptionStatus(state);
  const accountEmail = getAccountEmail(state);
  return {
    recipientAddress: getWellbeingSupportAddress(subscriptionStatus),
    emailSubject:
      subscriptionStatus !== 'none'
        ? getWellbeingSupportEmailSubject(subscriptionStatus, accountEmail)
        : '',
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
  /* @todo: Change after moving email helper to multiPlatformNavigation.ts */
  onContactUsClick: (
    recipientAddress: WellbeingSupportEmailAddress,
    emailSubject: string
  ) => {
    const navigate = getMultiPlatformNavigation();
    return navigate({
      type: 'location:href',
      src: createEmailHref(recipientAddress, emailSubject),
    });
  },
});

const WellbeingContactUsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsModal);

export default WellbeingContactUsModalContainer;
