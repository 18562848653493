import { List } from 'immutable';
import { ofType } from 'redux-observable';
import {
  from,
  map,
  switchMap,
  catchError,
  filter,
  concat,
  of,
  Observable,
} from 'rxjs';
import {
  LOAD_WIDGET_BLOCKED_WEB_ACTIVITY,
  receiveSummaryBlockedWebActivity,
  requestSummaryBlockedWebActivity,
  summaryBlockedWebActivityError,
} from '../../ducks/summary';
import { WebDomainActivityRecord } from '../../records/activity';
import { ProfileId } from '../../records/profile/types/Profile.types';
import { getProfile, getSelectedDateRange } from '../../selectors';
import {
  ActivitySummaryFilters,
  ActivitySummaryOrderBy,
} from '../../types/summary';

import { AppActionWithPayload, AppEpic } from '../types';
import {
  getNow,
  requestProfileFilter,
  summaryErrorHandler,
} from '../helpers/dataLoaderHelpers';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryBlockedWebActivityAction = ReturnType<
  typeof receiveSummaryBlockedWebActivity
>;

type ReceiveSummaryBlockedWebActivityResponse = { items: any[] };

type RequestSummaryBlockedWebActivity = ReturnType<
  typeof requestSummaryBlockedWebActivity
>;

interface LoadSummaryBlockedWebActivityAction {
  payload: {
    profileId: ProfileId;
  };
}

const notifyIfError = catchError(e => {
  return of(summaryErrorHandler(e, summaryBlockedWebActivityError));
});

const getSummaryDomainActivity = (
  api,
  { profileUid, state }
): Promise<ReceiveSummaryBlockedWebActivityResponse> => {
  const { minDate, maxDate } = getSelectedDateRange(state);
  return api.summaryDomainActivity.withCache(ttl1Minute).get({
    profileUid,
    filter: ActivitySummaryFilters.BlockedExplicitly,
    minDate,
    maxDate,
    order: [
      ActivitySummaryOrderBy.BlockedPagesDesc,
      ActivitySummaryOrderBy.MinutesDesc,
      ActivitySummaryOrderBy.Id,
    ],
  });
};

const toReceiveSummaryBlockedWebActivityAction = (
  response: ReceiveSummaryBlockedWebActivityResponse
): ReceiveSummaryBlockedWebActivityAction => {
  return receiveSummaryBlockedWebActivity(
    List(response.items.map(item => WebDomainActivityRecord.fromPayload(item)))
  );
};

export const SummaryBlockedWebActivityEpic: AppEpic<
  AppActionWithPayload,
  ReceiveSummaryBlockedWebActivityAction & RequestSummaryBlockedWebActivity
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_WIDGET_BLOCKED_WEB_ACTIVITY),
    map((action: LoadSummaryBlockedWebActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter(profile =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap(profile => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(requestSummaryBlockedWebActivity()),
        from(
          getSummaryDomainActivity(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ).pipe(
          map(list => toReceiveSummaryBlockedWebActivityAction(list)),
          notifyIfError
        )
      ) as Observable<
        ReceiveSummaryBlockedWebActivityAction &
          RequestSummaryBlockedWebActivity
      >;
    })
  );

  return loadData;
};

export default SummaryBlockedWebActivityEpic;
