import { BaseThunk } from '../../store/state';
import { getProductAddonOffer } from '../../ducks/productAddonsOffer/selectors';
import { awaitInAppBrowserEvent } from '../../helpers/inAppBrowser';
import { requestFeaturesAction } from '../../ducks/features';
import { isDesktopPlatform } from './helpers';
import { Addons } from '../../records/productAddonsOffer/types';
import { getWellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/wellbeing';
import { requestProductAddonsOfferAction } from '../../ducks/productAddonsOffer';
import { navigateToWellbeing } from '../../actions/Navigation';
import { WellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/types';

export const openWellbeingSubscriptionWebview =
  (type: Addons): BaseThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const addons = getProductAddonOffer(state, type);

    if (addons) {
      const ref = window.cordova.InAppBrowser.open(
        addons.url,
        isDesktopPlatform() ? '_self' : '_blank',
        'location=no'
      );

      if (!isDesktopPlatform()) {
        /*
      When the wellbeing purchase page opens on a native device (IOS or Android), 
      it's necessary to wait for the webview to close in order to reload the features again 
      and determine the wellbeing status (i.e., whether it has been purchased or not). 
      Depending on this status, navigation to the main wellbeing page is 
      performed (see onLoad prop function).

      Requesting the product addons is not necessary because 
      on Android and iOS, the state is not lost.
      */
        await awaitInAppBrowserEvent(ref, 'exit');
        await dispatch(requestFeaturesAction());
      }
    }
  };

export const openWellbeingActivitiesSubscriptionWebview = () => {
  return openWellbeingSubscriptionWebview('wellbeing_activities');
};

/**
 * When the device is desktop, the wellbeing purchase page will open in the same browser tab.
 * This necessitates pressing the back button to return from the purchase page to APP,
 * hence it's necessary to request the features and addons when component load
 * to calculate its status.
 */
export const ensureWellbeingFeatureNavigation =
  ({
    type,
    allowPurchase,
  }: {
    type: Addons;
    allowPurchase: (status: WellbeingSubscriptionStatus) => boolean;
  }): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const status = getWellbeingSubscriptionStatus(state);

    dispatch(requestProductAddonsOfferAction([type]));

    if (isDesktopPlatform()) {
      /*
        It's important to note that on desktop, 
        returning from the purchase page results in losing the application's entire state. 
        Therefore, it's necessary to request the orders again to have the product 
        information and features available in order to ascertain its status.
        */
      dispatch(requestFeaturesAction());
    }

    if (!allowPurchase(status)) {
      /*
          If the state of the wellbeing feature is 'both', 
          it means that the user has completed the purchase flow.
          in that case, the user will be directed to the main page.
          */
      dispatch(navigateToWellbeing());
    }
  };
