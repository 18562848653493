import * as R from 'ramda';

import * as qinit from '../qinit';
import { Dispatch, EmptyAction } from '../store/state';
import { getMultiPlatformNavigation } from './multiPlatformNavigation';

export const getAndroidConnectHelpLink = (): string =>
  qinit.tenant.marketing.public_site.help_android_how_to_setup_calls_sms;

export const getIOSConnectHelpLink = (): string =>
  qinit.tenant.marketing.public_site.help_ios_how_to_setup_calls_sms;

export const handleClickLink =
  (getUrlFn: () => string) => () => (dispatch: Dispatch<EmptyAction>) => {
    const navigate = getMultiPlatformNavigation();
    return R.pipe(
      getUrlFn,
      src => navigate({ type: 'external', src }),
      (a: EmptyAction) => dispatch(a)
    );
  };

export const handleClickCallMessagesHelpAndroid = handleClickLink(
  getAndroidConnectHelpLink
);

export const handleClickCallMessagesHelpIOS = handleClickLink(
  getIOSConnectHelpLink
);
