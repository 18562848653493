import { connect } from 'react-redux';
import StudentPauseModal from '../../components/Modal/StudentPauseModal';
import { goBackIfHistory } from '../../ducks/routing';
import {
  deleteStudentPause,
  finishLoadingStudentPolicies,
  postStudentPause,
} from '../../ducks/studentPolicies';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import {
  trackButtonClicked,
  GenericPageNames,
  ButtonNames,
} from '../../helpers/analytics';
import {
  addToMoment,
  durationInMinutes,
  getUserLocalTimezone,
  isDatetimeIsBefore,
} from '../../helpers/dates';
import { t } from '../../lib/i18n';

import {
  getCurrentSchoolEndDatetime,
  getCurrentSchoolStartDatetime,
  getEndActivePause,
  getIsFetchingStudentPolicy,
  getIsPauseActive,
  getIsPauseEnabled247,
  getNextSchoolEndDatetime,
  getNextSchoolStartDatetime,
} from '../../selectors/studentPolicies';
import State, { Dispatch } from '../../store/state';
import { Datetime } from '../../types/dates';
import { showErrorToast } from '../AccountSettings/helpers';
import { getAccountLocale, getTimezone } from '../../selectors';

const getInitialPauseDurationValue = (
  isPause247?: boolean,
  startPeriodDatetime?: Datetime
): number => {
  const defaultPauseDuration = 30;
  if (!startPeriodDatetime || isPause247) {
    return defaultPauseDuration;
  }
  const pauseDuration = isDatetimeIsBefore(
    startPeriodDatetime,
    addToMoment(defaultPauseDuration, 'minutes')
  )
    ? durationInMinutes(startPeriodDatetime)
    : defaultPauseDuration;
  return pauseDuration;
};

const mapStateToProps = (state: State) => {
  const endCurrentPeriodTime = getCurrentSchoolEndDatetime(state);
  const isCurrentPeriodFinished = endCurrentPeriodTime
    ? isDatetimeIsBefore(endCurrentPeriodTime)
    : true;
  const isPauseActive = getIsPauseActive(state);
  const startPeriodDatetime = isCurrentPeriodFinished
    ? getNextSchoolStartDatetime(state)
    : getCurrentSchoolStartDatetime(state);
  const isPauseEnabled247 = getIsPauseEnabled247(state);
  const initialPauseDuration = getInitialPauseDurationValue(
    isPauseEnabled247,
    startPeriodDatetime
  );
  return {
    isPauseActive,
    isPauseEnabled247,
    startPeriodDatetime,
    endPeriodDatetime: isCurrentPeriodFinished
      ? getNextSchoolEndDatetime(state)
      : endCurrentPeriodTime,
    isPeriodFinished: isCurrentPeriodFinished,
    endActivePause: isPauseActive ? getEndActivePause(state) : undefined,
    isDoingPolicyRequest: getIsFetchingStudentPolicy(state) || false,
    initialPauseDuration,
    locale: getAccountLocale(state),
    timezone: getTimezone(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { params: { profileId } }) => {
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onSchedulePause: async (pauseDurationInMinutes: number) => {
      let isRequestSuccessful;
      try {
        if (pauseDurationInMinutes === 0) {
          await dispatch(deleteStudentPause(profileId));
        } else {
          await dispatch(postStudentPause(profileId, pauseDurationInMinutes));
        }
        isRequestSuccessful = true;
      } catch (e) {
        dispatch(showErrorToast(e));
        dispatch(finishLoadingStudentPolicies());
        isRequestSuccessful = false;
        throw e;
      } finally {
        const localTimezone = getUserLocalTimezone();
        // Analytics has requested this to be send in a click button event instead in a track event
        trackButtonClicked(
          GenericPageNames.ProfileSchoolActivity,
          ButtonNames.StudentPauseScheduled,
          {
            profile_id: profileId,
            options: JSON.stringify({
              end_pause_timestamp: addToMoment(
                pauseDurationInMinutes,
                'minutes'
              ).toISOString(),
              schedule_succeed: isRequestSuccessful,
              user_local_timezone: localTimezone,
            }),
          }
        );
      }
      const toastMessage =
        pauseDurationInMinutes > 0
          ? t('Internet is now paused')
          : t('Internet is now unpaused');
      dispatch(showToast(toastMessage, TOAST_ICON_TICK));
      dispatch(goBackIfHistory());
    },
    isAfterPeriodStart: (
      endPauseTime: Datetime,
      startPeriodDatetime?: Datetime
    ) => {
      return (
        startPeriodDatetime &&
        endPauseTime.isAfter(startPeriodDatetime, 'minutes')
      );
    },
  };
};

export type StudentPauseModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const StudentPauseModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentPauseModal);

export default StudentPauseModalContainer;
