/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { Record } from 'immutable';
import { applyTo, pipe, identity, dissoc, assoc, omit, keys } from 'ramda';
import { commonMapInFn, commonMapOutFn } from '../../api/mappings/common';
import { PartialDeep } from '../../types/PartialDeep.types';
import { RecordDependency } from './types/RecordDependency.types';

import { RecordOperations } from './types/RecordOperations.types';
import { RecordOperationsGeneratorArgs } from './types/RecordOperationsGeneratorArgs.types';

const callIfNotNil = <T>(f: T, fallback: typeof identity | T = identity) =>
  (f ?? fallback) as NonNullable<T>;

const isRecord = <T>(obj: T): obj is Record<T> => obj instanceof Record;

const removeKey =
  <T>(key: string) =>
  (obj: T): T =>
    isRecord(obj)
      ? obj.set(key as keyof T, undefined as any)
      : dissoc(key, obj);

const addKey =
  <T>(key: string, value: unknown) =>
  (obj: T): T =>
    isRecord(obj)
      ? obj.set(key as keyof T, value as T[keyof T])
      : assoc(key, value, obj);

/**
 * Generates the most common record operations for a simple Record type. It
 * could be customized for a more complex mapping operations.
 *
 * By default, it applies a snake_case to camelCase mapping to the payload and
 * a camelCase to snake_case mapping to the serialized payload.
 *
 * @returns Record operations for the given record type
 */
export const getRecordOperations = <T extends object, Payload extends object>(
  generatorArgs: RecordOperationsGeneratorArgs<T, Payload>
): RecordOperations<T, Payload> => {
  const {
    defaultFields,
    mapIn = commonMapInFn,
    mapOut = commonMapOutFn,
    afterCreate = identity,
  } = // if there is any dependency record, add a hook for every dependency to
    // the current mapping functions
    (generatorArgs.recordDependencies?.reduce(
      // too complex type inference for TypeScript :(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createDependencyHooks as any,
      generatorArgs
    ) ?? generatorArgs) as Required<typeof generatorArgs>;

  const getDefaultFieldValues = () => defaultFields;

  const recordConstructor: (dataIn?: PartialDeep<T>) => Record<T> = Record(
    getDefaultFieldValues()
  );

  const recordOperations: RecordOperations<T, Payload> = {
    getDefaultFieldValues,
    create: dataIn =>
      applyTo(
        dataIn,
        pipe(recordConstructor, record => afterCreate(record, dataIn))
      ),
    fromPayload: (payload =>
      payload &&
      applyTo(
        payload,
        pipe(mapIn, recordOperations.create)
      )) as RecordOperations<T, Payload>['fromPayload'],
    serialize: mapOut,
  };
  return recordOperations;
};

/**
 * Decorate the current mapping functions with the maps required to inject a
 * record dependency in the specific field
 */
export const createDependencyHooks = <
  T extends object,
  Payload extends object,
  DepOps extends RecordOperations<any, any>
>(
  mappingFns: RecordOperationsGeneratorArgs<T, Payload>,
  {
    dependencyRecordOperations,
    field,
    payloadField = field as unknown as string & keyof Payload,
  }: Required<RecordDependency<T, Payload, DepOps>>
): RecordOperationsGeneratorArgs<T, Payload> =>
  applyTo(
    // add function fallbacks in case they are not defined and make the field types "required"
    {
      afterCreate: callIfNotNil(mappingFns.afterCreate),
      mapIn: callIfNotNil(mappingFns.mapIn, commonMapInFn),
      mapOut: callIfNotNil(mappingFns.mapOut, commonMapOutFn),
    },
    // inject the dependency record operations in the mapping functions
    mappingFnsNotNull => ({
      ...omit(keys(mappingFnsNotNull), mappingFns),
      afterCreate: (record, dataIn) =>
        applyTo(
          record,
          pipe(
            // call the default function
            record => mappingFnsNotNull.afterCreate(record, dataIn),
            // create the dependency and set it into the record field
            record =>
              dataIn
                ? record.set(
                    field,
                    dependencyRecordOperations.create(
                      dataIn[field]
                    ) as unknown as T[typeof field]
                  )
                : record
          )
        ),
      mapIn: payload =>
        applyTo(
          payload,
          pipe(
            // remove the field we are going to import using the dependency function
            removeKey(payloadField),
            // transform the current fields (with no dependencies)
            mappingFnsNotNull.mapIn,
            // use the dependency operations to import the dependency field and add it to our object
            mappedObj =>
              addKey<PartialDeep<T>>(
                field,
                dependencyRecordOperations.fromPayload(payload[payloadField])
              )(mappedObj)
          )
        ),
      mapOut: record =>
        record &&
        applyTo(
          record,
          pipe(
            // remove the field we are going to export using the dependency function
            removeKey<Record<T>>(field),
            // transform the current fields (with no dependencies)
            mappingFnsNotNull.mapOut,
            // use the dependency operations to export the dependency field and add it to our object
            mappedObj =>
              mappedObj &&
              addKey<PartialDeep<Payload>>(
                payloadField,
                dependencyRecordOperations.serialize(
                  record[field] as unknown as Record<T>
                )
              )(mappedObj)
          )
        ),
    })
  );
