import { fromJS, NonEmptyMapRecord } from 'immutable';

export const CLEAR_ACTION_SHEET = 'CLEAR_ACTION_SHEET';
export const SET_ACTION_SHEET = 'SET_ACTION_SHEET';

export enum ActionSheetTypes {
  App,
  WebCategory,
}

export default function actionSheet(
  state: NonEmptyMapRecord<{
    type?: ActionSheetTypes;
    id?: string | number;
  }> = fromJS({
    type: undefined,
    id: undefined,
  }),
  action
) {
  switch (action.type) {
    case CLEAR_ACTION_SHEET:
      return state.merge({ type: undefined, id: undefined });
    case SET_ACTION_SHEET:
      return state.merge({
        type: action.payload.type,
        id: action.payload.id,
      });
    default:
      return state;
  }
}

export function clearActionSheet() {
  return {
    type: CLEAR_ACTION_SHEET,
  };
}

export function setAppActionSheet(appKey: string) {
  return {
    type: SET_ACTION_SHEET,
    payload: { type: ActionSheetTypes.App, id: appKey },
  };
}

export function setWebCategoryActionSheet(categoryId: number) {
  return {
    type: SET_ACTION_SHEET,
    payload: { type: ActionSheetTypes.WebCategory, id: categoryId },
  };
}
