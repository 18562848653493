import * as Immutable from 'immutable';
import * as R from 'ramda';
import {
  AppFilter,
  FILTER_APPS_ANDROID,
  PLATFORM_ANDROID,
  FILTER_APPS_ALL,
  FILTER_APPS_NONE,
  FILTER_APPS_IOS,
  FILTER_APPS_WINDOWS,
  FILTER_APPS_MAC,
  FILTER_APPS_BLOCKED,
  FILTER_APPS_HASLIMIT,
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
  PLATFORM_IOS,
  APPLICATION_BLOCKED,
  FILTER_APPS_ALLOWED,
  APPLICATION_ALLOWED,
  YoutubeBundleNameByPlatform,
  Platform,
} from '../constants';
import { AppRuleRecord } from '../records/profileRules';
import {
  hasFullQuotaInEveryDay,
  hasZeroQuotaInEveryDay,
} from '../businessLogic/timeRules/timeQuota';

const searchAppList = (
  list: Immutable.List<AppRuleRecord>,
  search: string
): Immutable.List<AppRuleRecord> =>
  R.ifElse(
    v => !!v,
    R.pipe(R.toLower, search =>
      list.filter(
        R.pipe<any, string, string, string, boolean>(
          R.prop('name'),
          R.defaultTo(''),
          R.toLower,
          R.contains(search)
        )
      )
    ),
    R.always(Immutable.List())
  )(search);

const filterAppList = (
  filter: AppFilter,
  list: Immutable.List<AppRuleRecord>
): Immutable.List<AppRuleRecord> => {
  const filterPlatform = platform =>
    list.filter(R.propEq('platform', platform));
  switch (filter) {
    case FILTER_APPS_ANDROID:
      return filterPlatform(PLATFORM_ANDROID);
    case FILTER_APPS_IOS:
      return filterPlatform(PLATFORM_IOS);
    case FILTER_APPS_WINDOWS:
      return filterPlatform(PLATFORM_WINDOWS);
    case FILTER_APPS_MAC:
      return filterPlatform(PLATFORM_MAC);
    case FILTER_APPS_ALLOWED:
      return list.filter(
        rule =>
          rule.action === APPLICATION_ALLOWED &&
          hasFullQuotaInEveryDay(rule.quotas.toJS())
      );
    case FILTER_APPS_BLOCKED:
      return list.filter(
        rule =>
          rule.action === APPLICATION_BLOCKED ||
          hasZeroQuotaInEveryDay(rule.quotas.toJS())
      );
    case FILTER_APPS_HASLIMIT:
      return list.filter(
        rule =>
          rule.action === APPLICATION_ALLOWED &&
          !hasFullQuotaInEveryDay(rule.quotas.toJS()) &&
          !hasZeroQuotaInEveryDay(rule.quotas.toJS())
      );
    case FILTER_APPS_NONE:
    case FILTER_APPS_ALL:
    default:
      return list;
  }
};

export const filterOrSearchAppList = (
  filter: AppFilter,
  list: Immutable.List<AppRuleRecord>,
  search = ''
) => {
  const filteredList = filterAppList(filter, list);
  if (search !== '') return searchAppList(filteredList, search);
  return filteredList;
};

export const isYoutubeApp = (appName: string) => {
  const youtubeAppNames = [
    ...Object.values(YoutubeBundleNameByPlatform),
  ] as string[];
  return youtubeAppNames.includes(appName);
};

export const detectedBrowserAppsList: { exe: string; platform: Platform }[] = [
  { exe: 'msedge.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.microsoft.edgemac', platform: PLATFORM_MAC },
  { exe: 'com.google.chrome.canary', platform: PLATFORM_MAC },
  { exe: 'com.google.chrome.beta', platform: PLATFORM_MAC },
  { exe: 'microsoft.microsoftedge.stable', platform: PLATFORM_WINDOWS },
  { exe: 'com.opera.mini.native.beta', platform: PLATFORM_ANDROID },
  { exe: 'msedgewebview2.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.amazon.cloud9', platform: PLATFORM_ANDROID },
  { exe: 'opera_gx_splash.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.opera.mini.native', platform: PLATFORM_ANDROID },
  { exe: 'safari.exe', platform: PLATFORM_WINDOWS },
  { exe: 'org.mozilla.firefox.vpn', platform: PLATFORM_ANDROID },
  { exe: 'com.sec.android.app.sbrowser', platform: PLATFORM_ANDROID },
  { exe: 'com.apple.safari', platform: PLATFORM_MAC },
  { exe: 'opera.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.operasoftware.opera', platform: PLATFORM_MAC },
  { exe: 'com.huawei.browser', platform: PLATFORM_ANDROID },
  { exe: 'com.operasoftware.operagx', platform: PLATFORM_MAC },
  { exe: 'com.yandex.browser.alpha', platform: PLATFORM_ANDROID },
  { exe: 'org.mozilla.firefox_beta', platform: PLATFORM_ANDROID },
  { exe: 'com.samsung.android.app.sbrowseredge', platform: PLATFORM_ANDROID },
  { exe: 'microsoftedge.exe', platform: PLATFORM_WINDOWS },
  { exe: 'org.ungoogled.chromium.stable', platform: PLATFORM_ANDROID },
  { exe: 'palemoon-portable.exe', platform: PLATFORM_WINDOWS },
  { exe: 'firefox.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.vivaldi.vivaldi', platform: PLATFORM_MAC },
  { exe: 'yandex.exe', platform: PLATFORM_WINDOWS },
  { exe: 'palemoon.exe', platform: PLATFORM_WINDOWS },
  { exe: 'org.bromite.chromium', platform: PLATFORM_ANDROID },
  { exe: 'com.google.chrome', platform: PLATFORM_MAC },
  { exe: 'chrome.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.yandex.browser', platform: PLATFORM_ANDROID },
  { exe: 'com.opera.max.global', platform: PLATFORM_ANDROID },
  { exe: 'seamonkey.exe', platform: PLATFORM_WINDOWS },
  { exe: 'com.yandex.browser.lite', platform: PLATFORM_ANDROID },
  { exe: 'com.safari.browser', platform: PLATFORM_ANDROID },
  { exe: 'com.microsoft.msedge', platform: PLATFORM_IOS },
  { exe: 'com.chrome.canary', platform: PLATFORM_ANDROID },
  { exe: 'org.mozilla.firefoxdeveloperedition', platform: PLATFORM_MAC },
  { exe: 'com.chrome.beta', platform: PLATFORM_ANDROID },
  { exe: 'com.opera.browser.beta', platform: PLATFORM_ANDROID },
  { exe: 'com.safari.android', platform: PLATFORM_ANDROID },
  { exe: 'org.mozilla.firefox', platform: PLATFORM_ANDROID },
  { exe: 'com.opera.browser', platform: PLATFORM_ANDROID },
  { exe: 'vivaldi.exe', platform: PLATFORM_WINDOWS },
  { exe: 'org.chromium.chromium', platform: PLATFORM_MAC },
  { exe: 'org.mozilla.seamonkey', platform: PLATFORM_MAC },
  { exe: 'org.chromium.chrome', platform: PLATFORM_ANDROID },
  { exe: 'org.chromium.chrome.stable', platform: PLATFORM_ANDROID },
  { exe: 'com.android.chrome', platform: PLATFORM_ANDROID },
  { exe: 'org.mozilla.firefox', platform: PLATFORM_MAC },
  { exe: 'com.opera.gx', platform: PLATFORM_ANDROID },
  { exe: 'chromium.exe', platform: PLATFORM_WINDOWS },
  { exe: 'microsoft.microsoftedge', platform: PLATFORM_WINDOWS },
  { exe: 'com.vivaldi.browser', platform: PLATFORM_ANDROID },
  { exe: 'com.opera.touch', platform: PLATFORM_ANDROID },
  { exe: 'com.yandex.browser.beta', platform: PLATFORM_ANDROID },
];

export const isAppRuleDetectedBrowser = (app: {
  exe: string;
  platform: Platform;
}): boolean =>
  detectedBrowserAppsList.some(
    browser => browser.exe === app.exe && browser.platform === app.platform
  );
