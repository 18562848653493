import { connect } from 'react-redux';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import UnlockCompleteModal from '../../components/Modal/UnlockCompleteModal';
import State, { Dispatch } from '../../store/state';
import { canPurchase } from '../../selectors/app';

const mapStateToProps = (state: State) => ({
  canPurchase: canPurchase(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(close()),
  onClickUpgradeNow: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner:replace',
        src: `/upgrade`,
      })
    );
  },
});

export type UnlockCompleteModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UnlockCompleteModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnlockCompleteModal);

export default UnlockCompleteModalContainer;
