import * as React from 'react';
import { FlexLayout, Layout, Typography } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { DevicePlatform } from '../../constants';
import { SelectAddDeviceInstructions } from '../base/SelectAddDeviceInstructions';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayout from '../Layout/PageLayout';
import connectToLayout from '../Layout/connectToLayout';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

type AddDeviceInstructionsProps = {
  platform: DevicePlatform;
  profile?: ProfileRecord;
  layoutHasProfile: boolean;
  onClickEdit?: () => any;
  showProfileSummarySelector: boolean;
};

const AddDeviceInstructions: React.FunctionComponent<
  AddDeviceInstructionsProps
> = ({
  platform,
  profile,
  layoutHasProfile,
  onClickEdit,
  showProfileSummarySelector,
}: AddDeviceInstructionsProps) => {
  const [selectedPlatform, setSelectedPlatform] = React.useState(platform);
  const getPlatformIconClass = () => {
    const platformIconClass = {
      [DevicePlatform.Android]: 'fab fa-android',
      [DevicePlatform.Ios]: 'fab fa-apple',
      [DevicePlatform.Kindle]: 'fab fa-amazon',
      [DevicePlatform.Chromebook]: 'fab fa-chrome',
      PLATFORM_GENERIC: 'far fa-arrow-to-bottom',
    };

    return (
      platformIconClass[selectedPlatform] || platformIconClass.PLATFORM_GENERIC
    );
  };

  return (
    <PageLayout
      title={t('Protect a device')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: layoutHasProfile ? 2 : 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.MobileOrTablet,
          columns: 1,
          mainInsideCard: true,
          showProfile: layoutHasProfile,
          showProfileSummarySelector:
            layoutHasProfile && showProfileSummarySelector,
          title: layoutHasProfile ? ' ' : undefined,
          removePaddings: true,
        },
      ]}
      actions={
        !profile
          ? [{ actionName: 'back' }]
          : [
              { actionName: 'back' },
              { actionName: 'edit', onClick: onClickEdit },
            ]
      }
    >
      <div className="AddDeviceInstructions">
        <FlexLayout mainaxis="column" paddingLeft="24" paddingRight="24">
          <Layout centerX marginBottom="40">
            <div className="AddDeviceInstructions__icon">
              <i className={getPlatformIconClass()} />
            </div>
          </Layout>
          {layoutHasProfile && (
            <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
              <Typography
                type="title3"
                weight="semibold"
                testId="protect-a-device"
              >
                {t('Protect a device')}
              </Typography>
            </RenderWhen>
          )}
          <SelectAddDeviceInstructions
            title={t('Which device does your child use?')}
            platform={selectedPlatform}
            setSelectedPlatform={setSelectedPlatform}
          />
        </FlexLayout>
      </div>
    </PageLayout>
  );
};

export default connectToLayout(AddDeviceInstructions);
