import * as React from 'react';
import { AccountRecord, ProductRecord } from '../../records';
import {
  Button,
  ButtonType,
  ContentSeparator,
  FlexLayout,
  Typography,
  Link,
  ButtonSize,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { getPrice } from '../../records/product';

interface ProductOfferProps {
  account: AccountRecord;
  product?: ProductRecord;
  productWithLongerDuration?: ProductRecord;
  onUpgradePlan: (url: string) => void;
  onContinueToCheckout: (url: string) => void;
}

const ProductOffer = ({
  account,
  product,
  productWithLongerDuration,
  onUpgradePlan,
  onContinueToCheckout,
}: ProductOfferProps) => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      backgroundColor="white"
    >
      <Typography type="body1" weight="semibold" align="center">
        {t('Special offer for a 2-year subscription to Qustodio Premium')}
      </Typography>
      <Typography type="body1" align="center">
        {t(
          'Add an extra year to your Premium subscription and {1}save 25%{/1} on your 2nd year',
          {
            1: message => <b>{message}</b>,
          }
        )}
      </Typography>

      <FlexLayout
        mainaxis="column"
        marginTop="32"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        padding="24"
        className="par-direct-purchase-product-offer__price-box"
        width="360"
        alignSelf="center"
      >
        <Typography type="body1" weight="semibold" align="center">
          {t('Advanced safety for unlimited devices')}
        </Typography>
        <ContentSeparator className="par-direct-purchase-product-offer__separator" />
        <Typography type="h3" align="center" weight="semibold">
          {productWithLongerDuration
            ? getPrice(account, productWithLongerDuration)
            : null}
        </Typography>
        <Button
          size={ButtonSize.small}
          buttonType={ButtonType.primary}
          block
          loading={!productWithLongerDuration}
          onClick={() =>
            productWithLongerDuration &&
            onUpgradePlan(productWithLongerDuration.url)
          }
        >
          {t('Upgrade')}
        </Button>
      </FlexLayout>
      <FlexLayout
        mainaxis="column"
        marginTop="16"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
      >
        <Link onClick={() => product && onContinueToCheckout(product.url)}>
          {t('No, thanks, continue to checkout')}
        </Link>
      </FlexLayout>
    </FlexLayout>
  );
};

export default ProductOffer;
