import * as React from 'react';
import { t } from '../../lib/i18n';

import { LicenseRecord } from '../../records/license';
import {
  Modal,
  ButtonSize,
  Button,
  FlexLayout,
  Layout,
  Typography,
} from 'styleguide-react';
import {
  isFree,
  isPremiumBasic,
  isPremiumComplete,
  isSchoolFree,
  isTrial,
} from '../../records/license/helpers';
import shieldWithRocketIcon from '../../assets/base/images/ShieldWithRocket.png';
import type {
  ProfileAtLimitModalActionType,
  ProfileAtLimitModalProps,
} from '../../containers/Modal/ProfileAtLimitModalContainer';
import { exhaustiveCheck } from '../../helpers/validations';

const getTitle = (license: LicenseRecord) => {
  if (isPremiumBasic(license.subtype)) return t('Expand your plan');
  if (isTrial(license.subtype) || isFree(license.subtype)) {
    return t('Profile limit reached');
  }
  return t('Protect more profiles');
};

const getBody = (license: LicenseRecord, canPurchase: boolean) => {
  const { subtype, maxProfiles } = license;

  let copies: string[] = [];
  switch (subtype) {
    case 'free':
      copies = [
        t('Your plan limits protection to {{maxProfiles}} profiles.', {
          maxProfiles,
        }),
        t(
          'Upgrade to Premium to protect your whole family with Qustodio’s advanced safety features.'
        ),
      ];
      break;

    case 'trial':
      copies = [
        t(
          'Upgrade to Premium to protect your whole family with Qustodio’s advanced safety features.'
        ),
      ];
      break;

    case 'premium_basic':
      copies = [
        t(
          'Your current plan limits you to core protection for up to {{maxProfiles}} profiles.',
          { maxProfiles: license.maxProfiles }
        ),
        canPurchase
          ? t(
              'Expand your plan to protect your whole family with advanced safety features.'
            )
          : t(
              'Contact our support team to expand your plan and protect your whole family with advanced safety features.'
            ),
      ];
      break;

    case 'premium_complete':
      copies = [
        t(
          'Qustodio is designed exclusively for families. If you need to protect more than 25 profiles, please contact our support team to verify your requirements.'
        ),
      ];
      break;

    case 'premium_grandfather':
      copies = [
        t(
          'Your current plan limits you to core protection for up to {{maxProfiles}} profiles.',
          { maxProfiles: license.maxProfiles }
        ),
        t(
          'Expand your plan to protect your whole family with advanced safety features.'
        ),
      ];
      break;

    case 'school_free':
      copies = [
        t(
          "If you'd like to protect more profiles, please contact our support team."
        ),
      ];
      break;

    default:
      exhaustiveCheck(subtype);
      break;
  }

  return (
    <React.Fragment>
      {copies.map((copy, index) => (
        <Layout key={copy} marginTop={!index ? '0' : '8'}>
          {copy}
        </Layout>
      ))}
    </React.Fragment>
  );
};

const getActionType = (
  license: LicenseRecord,
  canPurchase: boolean
): ProfileAtLimitModalActionType => {
  if (isPremiumComplete(license.subtype) || isSchoolFree(license.subtype))
    return canPurchase ? 'support' : 'ok';
  return canPurchase ? 'upgrade' : 'ok';
};

const getActionCopy = (type: ProfileAtLimitModalActionType) => {
  if (type === 'upgrade') return t('Upgrade now');
  if (type === 'support') return t('Contact support');
  return t('Ok');
};

const ProfileAtLimitModal = ({
  license,
  canPurchase,
  onClickFooterButton,
  onClickClose,
}: ProfileAtLimitModalProps) => {
  const actionType = getActionType(license, canPurchase);
  return (
    <Modal
      size="large"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          size={ButtonSize.medium}
          onClick={() => onClickFooterButton(actionType)}
          testId="primary-action"
          block
        >
          {getActionCopy(actionType)}
        </Button>,
      ]}
    >
      <FlexLayout height="100%" mainaxis="column" mainaxisAlignment="center">
        <Layout textAlignement="center" marginTop="32" marginBottom="32">
          <img src={shieldWithRocketIcon} width="154px" alt="qustodio-shield" />
        </Layout>

        <Typography
          type="h5"
          weight="semibold"
          testId="ProfileAtLimitModal__title"
        >
          {getTitle(license)}
        </Typography>

        <FlexLayout mainaxis="column" crossaxisAlignment="center">
          <Layout marginBottom="16" testId="ProfileAtLimitModal__body">
            {getBody(license, canPurchase)}
          </Layout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default ProfileAtLimitModal;
