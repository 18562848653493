import * as queryString from 'query-string';
import * as camelize from 'camelize';
import { PaymentProvider } from '../constants';
import { omit } from '.';
import { prefixIfNotEmpty } from './string';

type QueryExtraParams = {
  parentDeviceId?: string;
  appVersion?: string;
  paymentProvider?: PaymentProvider;
  isNewAccount?: string;
  track?: string;
  standalone?: StandaloneFlowFlag;
  flow?: 'school-onboarding' | 'direct-purchase';
  faradayCage?: 'activated';
};
export type StandaloneFlowFlag = 'purchase' | 'purchase-at-limit';

export type QueryAppInitial = QueryExtraParams & {
  kind: 'access-tokens';
  accessToken: string;
  refreshToken: string;
};
export type QueryWebAutologin = QueryExtraParams & {
  kind: 'auth-code';
  authorizationCode: string;
  state: string;
}; // PAR Web autlogin
export type QueryNone = QueryExtraParams & { kind: 'none' }; // subsequent PAR App, or PAR Web
export type QueryInitializer = QueryAppInitial | QueryNone | QueryWebAutologin;

export type QueryBoolean = 'yes' | 'no';

export const parseQuery = (query: string): QueryInitializer => {
  const queryObject = camelize(queryString.parse(query));
  const { code, authorizationCode, state = '', ...rest } = queryObject;
  let kind = 'none';
  let authCode = {};

  if (queryObject.accessToken !== undefined) {
    kind = 'access-tokens';
  } else if (code !== undefined || authorizationCode !== undefined) {
    kind = 'auth-code';
    authCode = { authorizationCode: code ?? authorizationCode };
  }

  return {
    kind,
    ...rest,
    ...authCode,
    state,
  };
};

/**
 * Strip some query params out of a Location object and return a URL string.
 * Useful to remove sensitive tokens and such.
 */
export const stripQueryParams =
  (params: string[]) =>
  (location: Readonly<Location>): string =>
    [
      location.protocol,
      '//',
      location.host,
      location.pathname,
      prefixIfNotEmpty('?')(
        queryString.stringify(omit(queryString.parse(location.search))(params))
      ),
      location.hash,
    ].join('');

export const defaultTokensToStrip = [
  'access_token',
  'refresh_token',
  'code',
  'authorization_code',
  'kind',
  'track',
  'state',
];

export const stripTokensFromQuery = (
  tokens: string[] = defaultTokensToStrip
) => {
  const stripTokens = stripQueryParams(tokens);
  const query = window.location.search;
  if (query && process.env.NODE_ENV !== 'testing') {
    window.history.replaceState(
      {},
      document.title,
      stripTokens(window.location)
    );
  }
};

export const parseQueryParams = <T>(url: string): T => {
  const parser = new URL(url);
  const queryString = parser.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParams = Object.fromEntries(urlParams.entries());
  return queryParams as T;
};
