import { Map } from 'immutable';
import { SummaryAlertsState } from './types/SummaryAlertsState.type';
import { SummaryAlertsActions } from './types/actions.types';
import { computeViewedAlerts } from './reducerHelpers';
import { reset, setViewed } from './selectors';

const initialState: SummaryAlertsState = Map({});

const summaryAlertsReducer = (
  state: SummaryAlertsState = initialState,
  action: SummaryAlertsActions
) => {
  switch (action.type) {
    case 'RECEIVE_SUMMARY_ALERTS':
      return state.mergeDeep(computeViewedAlerts(action.payload.profiles));
    case 'MARK_AS_VIEWED_SUMMARY_ALERTS':
      return setViewed(
        state,
        action.payload.profileUuid,
        action.payload.type,
        true
      );
    case 'RECEIVE_RESET_SUMMARY_ALERTS_COUNTER':
      return reset(state, action.payload.profileUuid, action.payload.type);
    default:
      return state;
  }
};

export default summaryAlertsReducer;
