import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  Typography,
  Layout,
  FlexLayout,
} from 'styleguide-react';
import type { UnlockAlertsAndTimeLimitsModalProps } from '../../containers/Modal/UnlockAlertsAndTimeLimitsModalContainer';
import LazyImage from '../LazyImage/LazyImage';

const UnlockAlertsAndTimeLimitsModal = ({
  canPurchase,
  accountLocale,
  referrer,
  onClickUpgradeNow,
  onClickClose,
}: UnlockAlertsAndTimeLimitsModalProps) => (
  <Modal
    size="medium"
    animationEnabled
    onClickClose={onClickClose}
    buttons={[
      <Button
        key="button"
        block
        size={ButtonSize.medium}
        onClick={() => onClickUpgradeNow(canPurchase, referrer)}
      >
        {canPurchase ? t('Upgrade now') : t('OK')}
      </Button>,
    ]}
  >
    <FlexLayout height="100%" mainaxis="column" mainaxisAlignment="center">
      <Layout textAlignement="center" minHeight="240">
        <LazyImage
          src={`/illustrations/GamesAndApps/alerts_and_limits_upsell__${accountLocale}.png`}
          width="280px"
          alt="alerts and set limits"
        />
      </Layout>

      <Typography type="h5" weight="semibold">
        {t('Set up time limits and alerts')}
      </Typography>

      <FlexLayout mainaxis="column" crossaxisAlignment="center">
        <Layout maxWidth="360">
          <Typography type="body1" marginBottom="16">
            {t(
              'Set time limits for your kids’ apps and receive an alert when they open a new one for the first time.'
            )}
          </Typography>
          {!canPurchase ? (
            <Typography type="body1" marginBottom="16">
              {t(
                'Contact our support team to upgrade and set app time limits.'
              )}
            </Typography>
          ) : null}
        </Layout>
      </FlexLayout>
    </FlexLayout>
  </Modal>
);

export default UnlockAlertsAndTimeLimitsModal;
