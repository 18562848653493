import * as React from 'react';
import { t } from '../../lib/i18n';
import { IconType } from 'styleguide-react';
import type { AppRulesPremiumFlyoverProps } from '../../containers/ProfileRules/AppRules/AppRulesPremiumFlyoverContainer';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';

const APP_RULES_PREMIUM_FLYOVER = 'app-rules-premium-flyover';

const AppRulesPremiumFlyover = ({
  accountLocale,
  onClickClose,
  onClickUpgrade,
}: AppRulesPremiumFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Games & Apps') }}
      testId={APP_RULES_PREMIUM_FLYOVER}
      topbarText={t('{1}Upgrade your plan{/1} to manage games & apps!', {
        1: x => x,
      })}
      title={t('What can you do with games & apps?')}
      imageSrc={`/illustrations/GamesAndApps/premium_games_and_apps__${accountLocale}.png`}
      actionButton={{
        title: t('Upgrade'),
        onClick: onClickUpgrade,
        testId: `${APP_RULES_PREMIUM_FLYOVER}-upgrade`,
      }}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.ban,
          title: t('Block unsafe apps'),
          subtitle: t('Protect your family from inappropriate content'),
        },
        {
          iconType: IconType.stopWatch,
          title: t('Set time limits for games'),
          subtitle: t('Bring balance to your child’s favorite games'),
        },
        {
          iconType: IconType.bell,
          title: t('Receive app alerts'),
          subtitle: t('Know straight away if your child uses a new app'),
        },
      ]}
    />
  );
};

export default AppRulesPremiumFlyover;
