import { connect } from 'react-redux';
import SchoolTimelinePage, {
  SchoolTimelinePageProps,
} from '../../../components/Schools/SchoolTimeline/SchoolTimelinePage';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../../../store/state';
import { goBackIfHistory } from '../../../ducks/routing';
import flags from '../../../sideEffects/flags';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import {
  fetchLinewizeEvents,
  fetchLinewizeEventsPaginated,
  refreshLinewizeEvents,
  resetLinewizeEventsOnFilterChange,
} from '../../../ducks/linewizeEvent/thunk';
import {
  getLinewizeEventsFilter,
  getLinewizeEventsLastUpdated,
  getLinewizeEventsStatus,
} from '../../../ducks/linewizeEvent/selector';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../../helpers/analytics';
import { getProfileDevices } from '../../../selectors';
import { isProfileDelegatedToThisAccount } from '../../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../../selectors/studentPolicies';
import { navigateToActivityTimelineDelegationInfoModal } from '../../../actions/Navigation';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
): Partial<SchoolTimelinePageProps> => {
  const lastUpdatedIsoString = getLinewizeEventsLastUpdated(state);
  const timeLineStatus = getLinewizeEventsStatus(state);
  const currentFilter = getLinewizeEventsFilter(state).first();
  const profileHasQustodioDevices = Boolean(
    getProfileDevices(state, profileId).size
  );

  const delegationEnabled = isDelegationEnabled(state);
  const optedInForThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  const isDelegatedToThisAccount = isProfileDelegatedToThisAccount(
    delegationEnabled,
    optedInForThisAccount,
    true
  );
  return {
    profileId: Number(profileId),
    lastUpdated: lastUpdatedIsoString,
    isRefreshing: timeLineStatus === 'REFRESH',
    currentFilter,
    isUnifiedProfile: profileHasQustodioDevices,
    isDelegatedToThisAccount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
): Partial<SchoolTimelinePageProps> => ({
  onClickGoBack: () => {
    dispatch(goBackIfHistory());
  },
  onChangeFilter: filter => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolTimeline,
      ButtonNames.StudentTimelineFilter,
      {
        options: JSON.stringify({ filter }),
      }
    );
    dispatch(resetLinewizeEventsOnFilterChange([filter]));
    dispatch(fetchLinewizeEvents(profileId, filter));
  },
  onRefreshEvents: () => {
    dispatch(refreshLinewizeEvents());
    dispatch(fetchLinewizeEventsPaginated(profileId));
  },
  onDelegationInfoClick: () => {
    dispatch(navigateToActivityTimelineDelegationInfoModal());
  },
});

const SchoolTimelinePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolTimelinePage);

SchoolTimelinePageContainer.redirect =
  (params: RouterParams) => (dispatch: Dispatch) => {
    const profileId = Number(params.profileId);
    if (!flags.schoolTimeline.isEnabled()) {
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'inner:replace',
          src: {
            pathname: `/profiles/${profileId}/school/summary`,
          },
        })
      );
    }
  };

export default SchoolTimelinePageContainer;
