import { Record } from 'immutable';
import { snakeCaseToCamelCase } from '../../api/mappings/case';
import {
  TotalUsageActivity,
  TotalUsageActivityRecordType,
  TotalUsageActivityMethods,
} from './types/totalUsage.types';

export const TotalUsageActivityRecord = Record<
  TotalUsageActivity,
  TotalUsageActivityMethods
>({
  date: '',
  hits: 0,
});

TotalUsageActivityRecord.fromPayload = payload => {
  const camelized = snakeCaseToCamelCase(payload);
  return TotalUsageActivityRecord({
    ...camelized,
  }) as TotalUsageActivityRecordType;
};

TotalUsageActivityRecord.serialize = record => ({
  ...record.toJS(),
});
