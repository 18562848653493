import * as React from 'react';
import {
  FlexLayout,
  FreeStyleListItem,
  GlobalType,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  Tag,
  TransparentButton,
} from 'styleguide-react';
import IconTextCombo from '../IconTextCombo/IconTextCombo';
import { classNames } from '../../../helpers';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import { RoutineColor } from '../../../palettes/RoutineColor';
import { t } from '../../../lib/i18n';
import { UpgradeTag } from '../../UpgradeTag/UpgradeTag';

export interface RoutineListItemProps {
  testId?: string;
  title: string;
  subtitle: string;
  icon: typeof IconType[keyof typeof IconType];
  color: RoutineColor;
  status: 'enabled' | 'disabled' | 'upgrade';
  showBadge: boolean;
  onClick: () => void;
}

const getStatusColor = (status: RoutineListItemProps['status']) =>
  ({
    enabled: GlobalType.secondary,
    disabled: GlobalType.gray,
  }[status]);

const getStatusText = (status: RoutineListItemProps['status']) =>
  ({
    enabled: t('Enabled'),
    disabled: t('Disabled'),
  }[status]);

const renderTag = (
  status: RoutineListItemProps['status'],
  isMobile: boolean
) => {
  if (status === 'upgrade') {
    return <UpgradeTag size="regular" />;
  }

  return (
    <Tag
      className={classNames(
        'par-routine-list-item__tag',
        `par-routine-list-item__tag--status-${status}`
      )}
      type={getStatusColor(status)}
      iconType={IconType.calendarAlt}
      text={!isMobile ? getStatusText(status) : ''}
      variant="rounded"
      size="large"
    />
  );
};

const RoutineListItem = ({
  testId,
  title,
  subtitle,
  icon,
  color,
  status,
  showBadge,
  onClick,
}: RoutineListItemProps) => {
  const [isHovering, setIsHovering] = React.useState(false);
  return (
    <FreeStyleListItem key={title} noBorder>
      <TransparentButton
        testId={testId}
        className="par-routine-list-item-clickable"
        onClick={onClick}
      >
        <div
          className={classNames(
            'par-routine-list-item',
            `par-routine-list-item--color-${color}`
          )}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <RenderWhen
            screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}
          >
            {screenSize => (
              <FlexLayout
                className="par-routine-list-item__container"
                mainaxis="row"
                mainaxisAlignment="space-between"
                crossaxisAlignment="center"
                padding={screenSize === ScreenSize.Mobile ? '16' : '32'}
                paddingRight={
                  screenSize === ScreenSize.Mobile ? undefined : '24'
                }
              >
                <IconTextCombo
                  title={title}
                  titleSize="medium"
                  subtitle={subtitle}
                  subtitleSize={
                    screenSize === ScreenSize.Mobile ? 'small' : 'medium'
                  }
                  icon={icon}
                  iconColor={color}
                  iconSize={
                    screenSize === ScreenSize.Mobile ? 'medium' : 'large'
                  }
                  iconGap="x2"
                  invertColor={isHovering}
                  showBadge={showBadge}
                />

                <FlexLayout mainaxis="row" crossaxisAlignment="center">
                  {renderTag(status, screenSize === ScreenSize.Mobile)}
                  <Layout marginLeft="24" minWidth="24">
                    {(screenSize === ScreenSize.Mobile || isHovering) && (
                      <Icon
                        type={IconType.chevronRight}
                        size={
                          screenSize === ScreenSize.Mobile
                            ? IconSize.sm
                            : IconSize.regular
                        }
                        color={IconColor.secondary}
                      />
                    )}
                  </Layout>
                </FlexLayout>
              </FlexLayout>
            )}
          </RenderWhen>
        </div>
      </TransparentButton>
    </FreeStyleListItem>
  );
};

export default RoutineListItem;
