import * as React from 'react';
import { t } from '../../lib/i18n';
import { ButtonType } from 'styleguide-react';
import { CallsSMSRulesConnectDeviceFlyoverProps } from '../../types/rules/profile/callsAndMessages/CallsSMSRulesConnectDevice.types';
import CallsSMSRulesFlyover from './CallsSMSRulesFlyover';

const FLYOVER_TEST_ID = 'calls-and-messages-rules-connect-device-flyover';

const CallsSMSRulesConnectDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickOpenAndroidHelpLink,
  onClickOpenIOSHelpLink,
}: CallsSMSRulesConnectDeviceFlyoverProps) => {
  return (
    <CallsSMSRulesFlyover
      testId={FLYOVER_TEST_ID}
      accountLocale={accountLocale}
      actionButton={[
        {
          title: t('Instructions for Android'),
          onClick: onClickOpenAndroidHelpLink,
          type: ButtonType.secondary,
          testId: `${FLYOVER_TEST_ID}-android`,
        },
        {
          title: t('Instructions for iOS'),
          onClick: onClickOpenIOSHelpLink,
          type: ButtonType.secondary,
          testId: `${FLYOVER_TEST_ID}-ios`,
        },
      ]}
      onClickClose={onClickClose}
    />
  );
};

export default CallsSMSRulesConnectDeviceFlyover;
