import * as React from 'react';
import { t } from '../../lib/i18n';
import { EventRecord } from '../../records';
import { EventType } from '../../constants';

const ActivityTimelineItemText: React.FunctionComponent<{
  event: EventRecord;
  profileName: string;
}> = ({ event, profileName }) => {
  let itemText = '';
  const contactName = event.socialContactName;

  switch (event.type) {
    case EventType.FacebookSocialActivityUserPost:
      itemText = t('{{profileName}} added a new post', { profileName });
      break;
    case EventType.FacebookSocialActivityUserProfile:
      itemText = t('{{profileName}} has updated profile info', { profileName });
      break;
    case EventType.FacebookSocialActivityUserComment:
      itemText = t("{{profileName}} commented on it's own post", {
        profileName,
      });
      break;
    case EventType.FacebookSocialActivityUserEvent:
      itemText = t('{{profileName}} created new event', { profileName });
      break;
    case EventType.FacebookSocialActivityContactPost:
      itemText = t("{{profileName}} posted at {{contactName}}'s wall", {
        profileName,
        contactName,
      });
      break;
    case EventType.FacebookSocialActivityContactComment:
      itemText = t('{{contactName}} added a comment', { contactName });
      break;
    case EventType.FacebookSocialActivityContactLike:
      itemText = t('{{contactName}} liked something', { contactName });
      break;
    case EventType.FacebookSocialActivityUserContactPost:
      itemText = t("{{profileName}} posted on the {{contactName}}'s wall", {
        profileName,
        contactName,
      });
      break;
    case EventType.FacebookSocialActivityUserLink:
      itemText = t('{{profileName}} posted a link', {
        profileName,
      });
      break;
    case EventType.FacebookSocialActivityUserSharePhoto:
      itemText = t('{{profileName}} shared a {{contactName}} photo', {
        profileName,
        contactName,
      });
      break;
    case EventType.FacebookSocialMediaUserPhoto:
      itemText = t('{{profileName}} posted a new photo', {
        profileName,
      });
      break;
    case EventType.FacebookSocialMediaUserVideo:
      itemText = t('{{profileName}} posted a new video', {
        profileName,
      });
      break;
    case EventType.FacebookSocialMediaContactPhoto:
      itemText = t('{{contactName}} tagged {{profileName}} in a photo', {
        contactName,
        profileName,
      });
      break;
    case EventType.FacebookSocialMediaContactVideo:
      itemText = t('{{contactName}} tagged {{profileName}} in a video', {
        contactName,
        profileName,
      });
      break;
    default:
  }

  if (itemText === '') {
    return null;
  }

  return <div className="ActivityTimelineItemText">{itemText}</div>;
};

export default ActivityTimelineItemText;
