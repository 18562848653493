import { captureException } from './sentry';

export const safeFn = <FunctionType extends (...params: any[]) => any>(
  fn: FunctionType
): FunctionType => {
  const wrapped = (...args) => {
    try {
      return fn(...args);
    } catch (err) {
      try {
        return captureException(err);
      } catch (sentryErr) {
        // eslint-disable-next-line no-console
        return console.error(sentryErr);
      }
    }
  };

  return wrapped as FunctionType;
};
