import { connect } from 'react-redux';
import { getAccountLocale } from '../../../selectors';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import { navigateToAddDeviceAction } from '../../../actions/ProfileActions';

import {
  GenericPageNames,
  ProfileRuleNames,
  trackProtectADeviceBlockedFeature,
} from '../../../helpers/analytics';
import AppRulesAddDeviceFlyover from '../../../components/AppRules/AppRulesAddDeviceFlyover';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickAddDevice: () => {
    trackProtectADeviceBlockedFeature(
      GenericPageNames.GamesAndAppsRules,
      ProfileRuleNames.GamsAndApps
    );
    dispatch(navigateToAddDeviceAction());
  },
});

const AppRulesAddDeviceFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRulesAddDeviceFlyover);

export type AppRulesAddDeviceFlyoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default AppRulesAddDeviceFlyoverContainer;
