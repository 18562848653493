import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import { navigateToTotalScreenTimeInfoModal } from '../../actions/Navigation';
import TotalScreenTimeCard from '../../components/TotalScreenTime/TotalScreenTimeCard';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { getActiveDateRangeNormalized } from '../../selectors';
import { hasReportedRoutines } from '../../ducks/routines/selectors';

interface Props {
  profile?: ProfileRecord;
  height?: string;
  width?: string;
}

const mapStateToProps = (
  state: State,
  { profile, height, width = '100%' }: Props
) => {
  const activeDateRange = getActiveDateRangeNormalized(state);
  return {
    height,
    width,
    profile,
    activeDateRange,
    hasReportedRoutines: hasReportedRoutines(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToInfoModal: () => dispatch(navigateToTotalScreenTimeInfoModal()),
});

const TotalScreenTimeCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalScreenTimeCard);

export default TotalScreenTimeCardContainer;

export type TotalScreenTimeCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
