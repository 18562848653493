import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import DelegationOptOutModal from '../../components/Modal/DelegationOptOutModal';
import { enabledDelegation } from '../../ducks/profileRules/delegation/thunk';
import { Dispatch, RouterParamsProps } from '../../store/state';

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => {
  return {
    onClickClose: () => dispatch(goBackIfHistory()),
    onClickOptOut: () => {
      dispatch(enabledDelegation(Number(profileId), false));
      dispatch(goBackIfHistory());
    },
  };
};

const DelegationOptOutModalContainer = connect(
  null,
  mapDispatchToProps
)(DelegationOptOutModal);

export type DelegationOptOutModalProps = ReturnType<typeof mapDispatchToProps>;

export default DelegationOptOutModalContainer;
