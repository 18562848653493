import { StudentSummaryInfoTypes } from '../components/Schools/types/StudentSummaryInfoTypes.types';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { pushRelativeLocation } from './Navigation';

export const openSchoolSummaryInfoModalFor = (type: StudentSummaryInfoTypes) =>
  pushRelativeLocation(`modal/SchoolSummaryInfoModal?type=${type}`);

export const openSeeAllSignatureModalInfo = () =>
  pushRelativeLocation('signatures');

export const openSeeAllCategories = () => pushRelativeLocation('categories');

export const navigateAllCategoriesDetailPage = profileId => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/school/summary/categories`,
  });
};

export const navigateAllSignaturesDetailPage = profileId => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'inner',
    src: `/profiles/${profileId}/school/summary/signatures`,
  });
};
