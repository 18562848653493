import { fromJS, NonEmptyMapRecord } from 'immutable';
import api from '../api';
import { tapReject } from '../helpers';
import ExtraTimeRecord from '../records/extraTime';
import { BaseThunk } from '../store/state';
import {
  getProfileOrDefault,
  getAccount,
  getAccountCurrentLocalTime,
  getExtraTime,
} from '../selectors';
import { ttl3Seconds } from '../lib/QApiCache/commonCacheStrategies';
import { CalendarRestrictionPausePayloadArray } from '../records/calendarRestrictionsPause/types/CalendarRestriction.types';

export const REQUEST_EXTRATIME = 'REQUEST_EXTRATIME';
export const RECEIVE_EXTRATIME = 'RECEIVE_EXTRATIME';
export const REQUEST_EXTRATIME_ERROR = 'REQUEST_EXTRATIME_ERROR';

export const SAVE_EXTRA_TIME = 'SAVE_EXTRA_TIME';
export const SAVE_EXTRA_TIME_SUCCESS = 'SAVE_EXTRA_TIME_SUCCESS';
export const SAVE_EXTRA_TIME_FAILED = 'SAVE_EXTRA_TIME_FAILED';

export default function extraTime(
  state: NonEmptyMapRecord<{
    item: ExtraTimeRecord | null;
    isFetching: boolean;
    error: boolean;
    isSaving: boolean;
    isSavingFailed: boolean;
  }> = fromJS({
    item: null,
    isFetching: false,
    error: false,
    isSaving: false,
    isSavingFailed: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_EXTRATIME:
      return state.merge({
        item: null,
        isFetching: true,
        error: false,
      });
    case RECEIVE_EXTRATIME:
      return state.merge({
        isFetching: false,
        item: action.payload,
      });
    case REQUEST_EXTRATIME_ERROR:
      return state.merge({
        isFetching: false,
        error: true,
      });
    case SAVE_EXTRA_TIME:
      return state.merge({
        isSaving: true,
        isSavingFailed: false,
        item: action.payload,
      });
    case SAVE_EXTRA_TIME_SUCCESS:
      return state.merge({
        isSaving: false,
        item: action.payload,
      });
    case SAVE_EXTRA_TIME_FAILED:
      return state.merge({
        isSaving: false,
        isSavingFailed: true,
        item: action.payload,
      });
    default:
      return state;
  }
}

export const requestExtraTime = () => {
  return {
    type: REQUEST_EXTRATIME,
  };
};

const receiveExtraTime = payload => {
  return {
    type: RECEIVE_EXTRATIME,
    payload: payload === null ? null : ExtraTimeRecord.fromPayload(payload),
  };
};

const requestExtraTimeError = () => {
  return {
    type: REQUEST_EXTRATIME_ERROR,
  };
};

export const fetchExtraTime = (profileUid: string | number) => {
  return dispatch => {
    dispatch(requestExtraTime());
    return (
      api.calendarRestrictions.withCache(ttl3Seconds).get({
        profileUid,
        filter: 'newest_today_extra_time',
      }) as Promise<{
        items_list: CalendarRestrictionPausePayloadArray;
      }>
    )
      .then(json => {
        dispatch(
          receiveExtraTime(
            json.items_list[0]
              ? {
                  uid: json.items_list[0].uid,
                  seconds: json.items_list[0].duration,
                }
              : null
          )
        );
      })
      .catch(tapReject(() => dispatch(requestExtraTimeError())));
  };
};

export const saveExtraTimeTransaction =
  ({ minutes, profileId }): BaseThunk<void> =>
  async (dispatch, getState) => {
    const {
      oldExtraTime,
      temporalExtraTime,
      isCreatingExtraTime,
      calendar,
      pathParams,
    } = dataForSaveExtraTimeTransaction(getState(), { minutes, profileId });

    dispatch(saveExtraTime(temporalExtraTime));

    try {
      const result = isCreatingExtraTime
        ? await api.calendarRestrictions.post(calendar, null, pathParams)
        : await api.calendarRestrictions.put(pathParams, calendar);

      const newExtraTime = ExtraTimeRecord.fromPayload({
        uid: result.uid,
        seconds: result.duration,
      });

      dispatch(saveExtraTimeSuccess(newExtraTime));
    } catch (_) {
      dispatch(saveExtraTimeFailed(oldExtraTime));
      throw new Error('Transaction failed. Reverted to previous Extra Time.');
    }
  };

export const dataForSaveExtraTimeTransaction = (
  state,
  { minutes, profileId }
) => {
  const oldExtraTime = getExtraTime(state);
  const isCreatingExtraTime = oldExtraTime === null;
  const calendar = getCalendarRestriction(state, { minutes, profileId });

  return {
    oldExtraTime,
    temporalExtraTime: ExtraTimeRecord.fromPayload({
      uid: isCreatingExtraTime ? '' : oldExtraTime!.uid,
      seconds: calendar.duration,
    }),
    isCreatingExtraTime,
    calendar,
    pathParams: { profileUid: calendar.profile_uid },
  };
};

const getCalendarRestriction = (state, { minutes, profileId }) => ({
  account_uid: getAccount(state).uid,
  profile_uid: getProfileOrDefault(state, profileId).uid,
  restriction_type: 2,
  usage_type: 0,
  duration: minutes * 60,
  rrule: `DTSTART:${getAccountCurrentLocalTime(state)}\nFREQ=DAILY;COUNT=1`,
});

export const saveExtraTime = (extraTime: ExtraTimeRecord) => ({
  type: SAVE_EXTRA_TIME,
  payload: extraTime,
});

export const saveExtraTimeSuccess = (extraTime: ExtraTimeRecord) => ({
  type: SAVE_EXTRA_TIME_SUCCESS,
  payload: extraTime,
});

export const saveExtraTimeFailed = (
  extraTimeRecord: ExtraTimeRecord | null
) => ({
  type: SAVE_EXTRA_TIME_FAILED,
  payload: extraTimeRecord,
});
