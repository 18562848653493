import * as React from 'react';
import { IconFamily, IconSize, IconType, Tag } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SummaryAlertsPrimaryColor } from '../constants';

export interface SummaryAlertsCounterProps {
  count: number;
}

export const getText = (count: number) => {
  return count === 1 ? t('1 alert') : t('{{number}} alerts', { number: count });
};

const SummaryAlertsCounter = ({ count }: SummaryAlertsCounterProps) => {
  return (
    <Tag
      variant="rounded"
      text={getText(count)}
      type={SummaryAlertsPrimaryColor}
      iconType={IconType.exclamationCircle}
      iconProps={{ family: IconFamily.solid, size: IconSize.regular }}
    />
  );
};

export default SummaryAlertsCounter;
