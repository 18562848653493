import { Map } from 'immutable';
import { DirectPurchaseState } from './types/state';
import { ReceiveDirectPurchaseAction } from './types/actions';

const initialState = Map({
  source: null,
  locale: null,
  cart: null,
  campaign: null,
  clientCountry: null,
  pg: null,
  product: null,
}) as DirectPurchaseState;

const directPurchaseReducer = (
  state: DirectPurchaseState = initialState,
  action: ReceiveDirectPurchaseAction
) => {
  if (action.type === 'RECEIVE_DIRECT_PURCHASE') {
    return state.merge(Map(action.payload));
  }
  return state;
};

export default directPurchaseReducer;
