import * as moment from 'moment';
import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import {
  getAccountCurrentTime,
  getAccountLocale,
  isMilitaryTimeFormat,
  getSelectedDateScreenTime,
  getRangeScreenTime,
  getTimezone,
  getSelectedPastDate,
  getActiveDateRangeNormalized,
  getSummaryScreenTimeActivityError,
  isFetchingProfile,
  hasSeparatedScreenTimeForSelectedDate,
  hasSeparatedScreenTimeForDateRange,
} from '../../selectors';
import { formatDateNiceShort } from '../../helpers/dates';
import ScreenSummaryChart from '../../components/ScreenTimeSummary/ScreenSummaryChart';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { loadScreenTimeActivityCard } from '../../ducks/summary';
import {
  getRoutinesColorsMap,
  getRoutinesStatus,
} from '../../ducks/routines/selectors';
import { SummaryDateRanges } from '../../constants';

interface Props {
  profile?: ProfileRecord;
  isMobileView?: boolean;
}

const todayOrCustomPastDay = [
  SummaryDateRanges.Today,
  SummaryDateRanges.CustomPastDay,
];

const isScreenTimeDataSeparated = (activeDateRange: SummaryDateRanges) =>
  todayOrCustomPastDay.includes(activeDateRange)
    ? hasSeparatedScreenTimeForSelectedDate
    : hasSeparatedScreenTimeForDateRange;

const mapStateToProps = (state: State, { profile, isMobileView }: Props) => {
  const locale = getAccountLocale(state);
  const timezone = getTimezone(state);
  const currentTime = getAccountCurrentTime(state);
  const activeDateRange = getActiveDateRangeNormalized(state);
  const selectedPastDate = getSelectedPastDate(state);
  const selectedDayScreenTime = getSelectedDateScreenTime(state);
  const daysRangeScreenTime = getRangeScreenTime(state)
    .takeLast(activeDateRange)
    .toList();
  const lastDay = formatDateNiceShort(currentTime, timezone, locale);
  const firstDay = formatDateNiceShort(
    currentTime.clone().subtract(activeDateRange - 1, 'd'),
    timezone,
    locale
  );
  const hasError = Boolean(getSummaryScreenTimeActivityError(state));
  const isFetchingInfo: boolean = isFetchingProfile(state);
  const isFetchingRoutines = getRoutinesStatus(state).get('read') === 'loading';
  const routineColorsMap = getRoutinesColorsMap(state);
  const hasSeparatedScreenTime =
    isScreenTimeDataSeparated(activeDateRange)(state);

  return {
    profile,
    firstDay,
    lastDay,
    currentTime,
    activeDateRange,
    selectedDayScreenTime,
    daysRangeScreenTime,
    hasError,
    isFetchingInfo: isFetchingInfo || isFetchingRoutines,
    isMilitaryTimeFormat: isMilitaryTimeFormat(state),
    selectedPastDate: selectedPastDate
      ? formatDateNiceShort(moment(selectedPastDate), timezone, locale)
      : undefined,
    isMobileView,
    routineColorsMap,
    hasSeparatedScreenTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { profile }: Props) => {
  return {
    loadWidget: () => {
      dispatch(
        loadScreenTimeActivityCard({ profileId: profile?.id.toString() || '' })
      );
    },
  };
};

const ScreenSummaryChartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSummaryChart);

export default ScreenSummaryChartContainer;

export type ScreenSummaryChartProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
