import { DirectPurchase } from './types/state';
import { receiveDirectPurchase } from './actions';
import { BaseThunk } from '../../store/state';
import { fetchChargebeeProduct } from '../products/helpers';
import { ProductRecord } from '../../records';
import { isDirectPurchaseQuery, getDirectPurchaseQuery } from './helpers';

export const setDirectPurchaseData = (data: DirectPurchase) => dispatch =>
  dispatch(receiveDirectPurchase(data));

export const ensureSetDirectPurchaseData = (): BaseThunk => async dispatch => {
  const query = getDirectPurchaseQuery();
  if (!isDirectPurchaseQuery(query)) {
    return;
  }

  /*
    To maintain compatibility,
    we ensure to request the corresponding product for the account using the code
    that comes from the public site.
    It is important to note that the code sent by the public site is anonymous and may come with a different currency.
    This product must be contextualized to the account.
    */
  const product = await fetchChargebeeProduct(
    query.cart as string,
    undefined,
    query.pg as string
  );

  /*
    It's very important to keep in mind that the data for the direct purchase state can be obtained from two sources:
    1. When the user is not logged in,
    the information passed in query parameters is saved in the state that is passed to the SSO (Single Sign-On),
    and subsequently, upon the return from the SSO, it is persisted in the state. This means that there are no query parameters,
    if the query parameters are empty, it is because the data is already in the state.
    2. When the user is logged in and accesses via the URL,
    in that case, the data will be obtained from the query parameters and placed in the state.
    */
  await dispatch(
    setDirectPurchaseData({
      ...query,
      product: ProductRecord(product),
    })
  );
};
