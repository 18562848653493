import { connect } from 'react-redux';
import { fetchDevices } from '../../ducks/devices';
import {
  areDevicesExceeded,
  getDevicesWithProfilesAndStatus,
  getLocationPathname,
} from '../../selectors';
import DeviceList from '../../components/Devices/DeviceList';
import { Events, track } from '../../helpers/analytics';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import State, { BaseThunk, Dispatch } from '../../store/state';

const mapStateToProps = (state: State) => ({
  devicesWithProfilesAndStatus: getDevicesWithProfilesAndStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDeviceClick: (deviceId: number | string) => () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedDeviceSelect);
    return dispatch(navigate({ type: 'inner', src: `/devices/${deviceId}` }));
  },

  onDeviceAddClick: () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedAddDevice);
    dispatch((_dispatch, getState) => {
      const state = getState();
      if (areDevicesExceeded(state)) {
        const currentPath = getLocationPathname(state);
        return _dispatch(
          navigate({
            type: 'inner',
            src: `${currentPath}/modal/DeviceOverLimitModal`,
          })
        );
      }
      return _dispatch(navigate({ type: 'inner', src: '/devices/add' }));
    });
  },
});

const DeviceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceList);

DeviceListContainer.load = (): BaseThunk => dispatch => {
  return dispatch(fetchDevices());
};

export type DeviceListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default DeviceListContainer;
