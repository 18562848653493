import { createEpicMiddleware } from 'redux-observable';
import {
  page,
  PageNames,
  format,
  trackPageLoaded,
  GenericPageNames,
} from '../helpers/analytics';
import api from '../api';

const analytics = {
  page,
  PageNames,
  format,
  trackPageLoaded,
  GenericPageNames,
};

export const dependencies = { analytics, api };

export type Dependencies = typeof dependencies;

export const createAppEpicMiddleware = () =>
  createEpicMiddleware({ dependencies });
