import { connect } from 'react-redux';
import * as R from 'ramda';
import { t } from '../../../lib/i18n';
import { goBackIfHistory } from '../../../ducks/routing';
import PanicButtonRulesInviteContact from '../../../components/PanicButtonRules/PanicButtonRulesInviteContact';
import {
  getProfileRulesModifyStatus,
  getPanicRulesContacts,
  getContacts,
  getAccountCountry,
  getContactModifyStatus,
  getPanicRulesAccountHolder,
} from '../../../selectors';
import { TOAST_ICON_TICK, showToast } from '../../../ducks/toast';
import { delayedUpdateProfileRules } from '../../../ducks/profileRules';
import { createContact } from '../../../ducks/contacts';
import { ContactRecord } from '../../../records/contact';
import { gaEvent } from '../../../helpers/ga';
import { getPhoneNumberInternationalFormat } from '../../../helpers/string';
import { PanicRulesContactType } from '../../../constants';

const mapStateToProps = (state, { params: { profileId } }) => ({
  isSaving: getProfileRulesModifyStatus(state) || getContactModifyStatus(state),
  contactType: state.getIn([
    'form',
    'panicButtonRulesInviteContact',
    'values',
    'type',
  ]),
  contacts: getContacts(state).concat([
    ContactRecord.fromPayload(getPanicRulesAccountHolder(state, profileId)),
  ]),
  country: getAccountCountry(state),
});

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onSubmit: record =>
    dispatch((dispatch, getState) =>
      R.pipe(
        R.when(R.propEq('type', PanicRulesContactType.Phone), record =>
          record.set(
            'contact',
            getPhoneNumberInternationalFormat(
              record.contact,
              getAccountCountry(getState())
            )
          )
        ),
        record =>
          dispatch(addContact(profileId, record))
            .then(() => dispatch(goBackIfHistory()))
            .then(() =>
              dispatch(
                showToast(
                  t('Invitation sent to {{name}}', { name: record.name }),
                  TOAST_ICON_TICK
                )
              )
            )
            .then(() => gaEvent('panic-button', 'click', 'send-invitation'))
      )(record)
    ),
});

const addContact = (profileId, formRecord) => (dispatch, getState) =>
  dispatch(createContact(ContactRecord.fromPayload(formRecord.toJS()))).then(
    json =>
      dispatch(
        delayedUpdateProfileRules(profileId, profileRules =>
          profileRules.setIn(
            ['panic', 'contacts'],
            getPanicRulesContacts(getState(), profileId).push(
              ContactRecord.fromPayload(json)
            )
          )
        )
      )
  );

const PanicButtonRulesInviteContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanicButtonRulesInviteContact);

export default PanicButtonRulesInviteContactContainer;
