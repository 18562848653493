import { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import PullToRefresh from 'rmc-pull-to-refresh';
import { t } from '../../lib/i18n';
import Loader from '../base/Loader';
import { getDocument } from '../../sideEffects/browser';

class PullRefresh extends Component {
  classNameScrollableArea = 'PullRefresh__scrollable-area';

  componentDidMount() {
    const { onScroll } = this.props;

    if (onScroll) {
      this.registerScrollHandlers();
    }
  }

  componentWillUnmount() {
    const { onScroll } = this.props;

    if (onScroll) {
      this.unregisterScrollHandlers();
    }
  }

  onScroll = evt => {
    const { onScroll } = this.props;

    onScroll({
      ...evt,
      target:
        evt.target === getDocument().scrollingElement ||
        evt.target === getDocument()
          ? getDocument().scrollingElement || getDocument().documentElement
          : evt.target,
    });
  };

  get scrollElement() {
    return getDocument().getElementsByClassName(
      this.classNameScrollableArea
    )[0];
  }

  registerScrollHandlers = () => {
    const { router } = this.context;
    if (router && router.registerScrollElement) {
      router.registerScrollElement('pullRefresh', this.scrollElement);
      getDocument().addEventListener('scroll', this.onScroll, true);
    }
  };

  unregisterScrollHandlers = () => {
    const { router } = this.context;
    if (router && router.registerScrollElement) {
      router.registerScrollElement('pullRefresh', null);
      getDocument().removeEventListener('scroll', this.onScroll, true);
    }
  };

  // Do not convert this to JSX syntax,
  // will cause an error with the test suite not being able to transpile this.
  render() {
    const {
      className = '',
      isFetching,
      onRefresh,
      children,
      getScrollContainer,
    } = this.props;

    return createElement(
      'div',
      { className: `PullRefresh ${className}` },
      createElement(
        PullToRefresh,
        {
          getScrollContainer,
          prefixCls: this.classNameScrollableArea,
          style: { height: '100%', overflow: 'auto' },
          refreshing: isFetching,
          onRefresh,
          distanceToRefresh: 10,
          damping: 40,
          indicator: {
            activate: createElement(
              'div',
              { className: 'PullRefresh__message' },
              t('Pull down to refresh')
            ),
            deactivate: createElement('span'),
            release: createElement(
              'div',
              { className: 'PullRefresh__loader' },
              createElement(Loader, { size: 'small' })
            ),
            finish: createElement('span'),
          },
        },
        createElement('div', { className: 'PullRefresh__target' }, children)
      )
    );
  }
}

PullRefresh.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  getScrollContainer: PropTypes.func,
};

PullRefresh.contextTypes = {
  router: PropTypes.any,
};

export default PullRefresh;
