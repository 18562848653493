import {
  generateRandomString,
  base64urlencode,
  base64urldecode,
} from './crypto';
import {
  SsoStateData,
  SSO_LOGIN_URI,
  SsoStartMode,
  SSO_SIGNUP_URI,
  SSO_DIRECT_PURCHASE_SIGNUP_URI,
  SsoSourcePlatforms,
  BuildPlatform,
  AuthorizationApplication,
} from '../constants';
import {
  getAuthorizationApplicationThroughAuthCodeFlowAndPlatform,
  getRedirectUri,
} from './authentication';
import {
  getBuildPlatform,
  getAppVersion,
  getClientCredentials,
} from './globals';
import { getLocale } from '../lib/i18n';
import { isBrowserPlatform } from '.';
import { QueryWebAutologin, parseQueryParams } from './query';
import { isLocal, isTesting } from './env';
import { RouteLocation } from '../types/RouteLocation.types';

export const STATE_DURATION_SECONDS = 60 * 6;
export const TIMEOUT_DURATION_MILISECONDS = 1000 * 60 * 5;

const createDateAndExpiration = (
  date: Date,
  timeInSeconds: number = STATE_DURATION_SECONDS
) => {
  const createdAt: string = date.toUTCString();
  date.setSeconds(date.getSeconds() + timeInSeconds);
  const expiresOn: string = date.toUTCString();
  return {
    createdAt,
    expiresOn,
  };
};

export const createSsoState = <T>({
  location,
  date,
  query,
}: {
  location: RouteLocation;
  date?: Date;
  query?: T;
}) => {
  const expiration = createDateAndExpiration(date ?? new Date());

  return encodeURIComponent(
    base64urlencode(
      JSON.stringify({
        [generateRandomString()]: expiration,
        location,
        /*
        In some scenarios, it is necessary to save the query parameters.
        If they are passed, the 'query' key is added; otherwise, nothing is added.
        */
        ...(query && { query }),
      })
    )
  );
};

export const createSSOConf = () =>
  btoa(
    JSON.stringify({
      showBackButton: !isBrowserPlatform(),
    })
  );

export const deserializeSsoState = (ssoState: string): SsoStateData =>
  JSON.parse(base64urldecode(ssoState));

export const getSsoURI = (mode: SsoStartMode) => {
  switch (mode) {
    case SsoStartMode.login:
      return SSO_LOGIN_URI;
    case SsoStartMode.signUp:
      return SSO_SIGNUP_URI;
    case SsoStartMode.directPurchase:
      return SSO_DIRECT_PURCHASE_SIGNUP_URI;
    default:
      return SSO_SIGNUP_URI;
  }
};

export const getSourceOSVersion = () => {
  return global.device.version; // Using cordova-plugin-device
};

export const getSourcePlatform = () => {
  const platform = getBuildPlatform();
  switch (platform) {
    case BuildPlatform.android:
      return SsoSourcePlatforms.android;
    case BuildPlatform.browser:
      return SsoSourcePlatforms.web;
    case BuildPlatform.ios:
      return SsoSourcePlatforms.ios;
    default:
      return SsoSourcePlatforms.web;
  }
};

export const createSsoDetails = ({ source }: { source: string }) =>
  base64urlencode(
    JSON.stringify({
      source_platform: getSourcePlatform(),
      source_details: getAppVersion(),
      source_os_version: getSourceOSVersion(),
      source_touchpoint: source,
    })
  );

export const createRedirectToSsoUrl = ({
  mode,
  ssoState,
  authorizationApplication = getAuthorizationApplicationThroughAuthCodeFlowAndPlatform(),
  campaign,
  source = 'Parent Device',
}: {
  mode: SsoStartMode;
  ssoState: string;
  authorizationApplication?: AuthorizationApplication;
  source?: string;
  campaign?: string;
}) => {
  const urlParts = [
    `${getSsoURI(mode)}?locale=${getLocale()}`,
    `response_type=code`,
    `state=${encodeURIComponent(ssoState)}`,
    `client_id=${encodeURIComponent(
      getClientCredentials(authorizationApplication).client_id
    )}`,
    `redirect_uri=${encodeURIComponent(
      getRedirectUri(authorizationApplication)
    )}`,
    `details=${encodeURIComponent(createSsoDetails({ source }))}`,
    `conf=${encodeURIComponent(createSSOConf())}`,
  ];

  if (campaign) {
    urlParts.push(`campaign=${encodeURIComponent(campaign)}`);
  }

  return urlParts.join('&');
};

export const isRequestingTokensForWebClient = ({
  state: ssoState,
  kind,
}: QueryWebAutologin) =>
  isBrowserPlatform() &&
  kind === 'auth-code' &&
  typeof ssoState === 'string' &&
  ssoState.length > 0;

export const isSSOEnabled = () => {
  if (isLocal() || isTesting()) {
    return false;
  }

  return true;
};

export const isDirectPurchase = (location: RouteLocation) =>
  location.pathname.includes('/direct-purchase');

export const getSSOMode = (location: RouteLocation) => {
  if (isDirectPurchase(location)) {
    return SsoStartMode.directPurchase;
  }

  return SsoStartMode.login;
};

export const getSSOCampaign = (url: string) => {
  const { campaign } = parseQueryParams<{ campaign?: string }>(url);
  return campaign;
};

export const getSSOSource = ({
  mode,
  url,
}: {
  mode: SsoStartMode;
  url: string;
}) => {
  if (mode === SsoStartMode.directPurchase) {
    const { source } = parseQueryParams<{ source?: string }>(url);
    return source;
  }

  return 'Parent Device';
};
