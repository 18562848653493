import { setItems } from '../ducks/persistentStorage';
import { redirectAfterLogin } from '../ducks/app/redirectAfterLogin';
import {
  START_LOADING_INITIAL_DATA,
  FINISHED_LOADING_INITIAL_DATA,
} from '../ducks/actionNames/app';
import { setApiGlobals, QApiConfig } from '../sideEffects/config';
import { FormNames, AuthorizationApplication } from '../constants';
import api from '../api';
import { APIError } from '../lib/errors';
import { BaseThunk } from '../store/state';
import { getClientCredentials } from '../helpers/globals';
import { t } from '../lib/i18n';
import { unlock } from './LockScreenActions';
import { isLockScreenEnabled } from '../selectors/lockState';

export const loginForm = (): BaseThunk<void> => (dispatch, getState) => {
  const values = getState().get('form').get(FormNames.login).get('values');
  return dispatch(login(values.get('username'), values.get('password')));
};

export const login =
  (username: string, password: string) => (dispatch, getState) => {
    dispatch({ type: START_LOADING_INITIAL_DATA });

    const body = {
      grant_type: 'password',
      username,
      password,
      ...getClientCredentials(AuthorizationApplication.WebOrLegacyMobile),
    };
    return api.accessToken
      .post(body)
      .then(({ access_token: accessToken, refresh_token: refreshToken }) => {
        dispatch(
          setItems({
            authorizationApplication:
              AuthorizationApplication.WebOrLegacyMobile,
            accessToken,
            refreshToken,
          })
        );
        return {
          authorizationApplication: AuthorizationApplication.WebOrLegacyMobile,
          accessToken,
          refreshToken,
        };
      })
      .then(
        (qapiConfig): QApiConfig => ({
          ...qapiConfig,
          parentDeviceId: 'unknown',
        })
      )
      .then(qapiConfig => dispatch(setApiGlobals(qapiConfig)))
      .then(() => {
        if (isLockScreenEnabled(getState())) {
          // We need to disable the biometric login in this scenario
          return dispatch(unlock());
        }
        return dispatch(redirectAfterLogin());
      })
      .catch(error => {
        dispatch({ type: FINISHED_LOADING_INITIAL_DATA });
        return (
          APIError.isUnauthorizedAuthorization(error)
            ? error.json().then(json => json.error)
            : Promise.resolve(null)
        ).then(error =>
          error === 'invalid_grant' ? showInvalidCredentialsAlert() : null
        );
      });
  };

const showInvalidCredentialsAlert = () => {
  navigator.notification.alert(
    t('Invalid email or password.'),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    t('Whoops!')
  );
};
