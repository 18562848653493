import { Record } from 'immutable';
import { getMilisecondsTimestamp } from '../helpers/dates';

export type OnboardingToken = {
  token: string;
  receivedAt: number;
};

type OnboardingTokenRecord = Record<OnboardingToken>;

const OnboardingTokenRecord = Record<
  OnboardingToken,
  {
    serialize: Record.Serializer<Record<OnboardingToken>, OnboardingToken>;
    fromPayload: Record.Parser<{ token: string }, Record<OnboardingToken>>;
    fromLocalStorage: Record.Parser<OnboardingToken, Record<OnboardingToken>>;
  }
>({
  token: '',
  receivedAt: 0,
});

OnboardingTokenRecord.fromPayload = (payload: { token: string }) =>
  OnboardingTokenRecord({
    token: payload.token,
    receivedAt: getMilisecondsTimestamp(),
  });

OnboardingTokenRecord.fromLocalStorage = (payload: {
  token: string;
  receivedAt: number;
}) =>
  OnboardingTokenRecord({
    token: payload.token,
    receivedAt: payload.receivedAt,
  });

OnboardingTokenRecord.serialize = record => record.toJS();

export const isEmpty = (record: OnboardingTokenRecord) =>
  record.token === '' && record.receivedAt === 0;

export default OnboardingTokenRecord;
