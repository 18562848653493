import { List } from 'immutable';
import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import {
  SignatureReview,
  SignatureReviewPayload,
} from './types/SignatureReview.types';
import { SignatureReviewerRecordOperations } from './signatureReviewer';
import {
  SignatureRisks,
  SignatureRisksPayload,
} from './types/SignatureRisks.types';
import { camelCaseToSnakeCase } from '../../../api/mappings/case';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';

export const SignatureReviewRecordOperations = getRecordOperations<
  SignatureReview,
  SignatureReviewPayload
>({
  defaultFields: {
    recommendation: 'NOT_RATED',
    review: '',
    reviewDate: '',
    risks: List(),
    ageRating: undefined,
    reviewer: SignatureReviewerRecordOperations.create(),
  },
  recordDependencies: [
    {
      field: 'reviewer',
      dependencyRecordOperations: SignatureReviewerRecordOperations,
    },
  ],
  afterCreate: (record, dataIn) =>
    record.set('risks', List<SignatureRisks>(dataIn?.risks ?? [])),
  mapIn: payload => ({
    ...commonMapInFn(payload),
    risks: payload.risks
      ? List(payload.risks.map<SignatureRisks>(commonMapInFn))
      : List([]),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    risks: record?.risks
      ? (record.risks.map(
          camelCaseToSnakeCase
        ) as unknown as SignatureRisksPayload[])
      : (List([]) as unknown as SignatureRisksPayload[]),
  }),
});
