import * as React from 'react';
import {
  GlobalType,
  Icon,
  IconFamily,
  IconType,
  TransparentButton,
} from 'styleguide-react';
import { classNames } from '../../../helpers';
import { RoutineColor } from '../../../palettes/RoutineColor';

export type RoutineIconColor =
  | RoutineColor
  | GlobalType.secondary
  | GlobalType.error;
export interface RoutineIconProps {
  testId?: string;
  className?: string;
  icon: typeof IconType[keyof typeof IconType];
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  color?: RoutineIconColor;
  family?: typeof IconFamily[keyof typeof IconFamily];
  selected?: boolean;
  inverted?: boolean;
  showBadge?: boolean;
  onClick?: () => void;
}

const RoutineIcon = ({
  testId,
  className,
  icon,
  size = 'large',
  color = RoutineColor.yellow,
  family = IconFamily.regular,
  selected,
  inverted,
  showBadge,
  onClick,
}: RoutineIconProps) => {
  const wrappedInButton = (body: React.ReactNode) => (
    <TransparentButton
      testId={testId}
      className="par-routine-icon-clickable"
      onClick={onClick}
    >
      {body}
    </TransparentButton>
  );

  const iconBody = (
    <div
      data-testid={testId}
      className={classNames(
        'par-routine-icon',
        `par-routine-icon--size-${size}`,
        `par-routine-icon--color-${color}`,
        selected && `par-routine-icon--selected`,
        inverted && `par-routine-icon--inverted`,
        className
      )}
    >
      <div className="par-routine-icon__body">
        <Icon type={icon} family={family} />
        {showBadge && (
          <span className="par-routine-icon__badge">
            <Icon type={IconType.hourglassHalf} />
          </span>
        )}
      </div>
    </div>
  );

  if (onClick) return wrappedInButton(iconBody);
  return iconBody;
};

export default RoutineIcon;
