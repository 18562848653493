/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { connect } from 'react-redux';
import { getAccountOptionWebOnboardingFinished } from '../selectors/account';
import { trackOpenedBurgerMenu } from '../helpers/analytics';
import RenderWhen, { ScreenSize } from './RenderWhen/RenderWhen';
import { sideBarStateChanged } from '../ducks/ui';
import Layout from './Layout/Layout';
import ModalSelector from './Modal/ModalSelector';
import maskSvg from '../assets/base/images/onboarding/mask.svg';
import LogoSvg from '../assets/base/images/logo-green.svg';
import { isSchoolOnboardingPath } from '../selectors';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

export class Main extends React.Component<{
  content: null | JSX.Element | JSX.Element[];
  params: any;
  location: any;
  isWebOnboardingFinished: boolean;
  isSchoolOnboarding: boolean;
  redirectToRoot: () => void;
  onIconMenuClick: () => any;
}> {
  componentDidMount() {
    if (this.props.isWebOnboardingFinished && !this.props.isSchoolOnboarding) {
      this.props.redirectToRoot();
    }
  }

  render() {
    return (
      <div className="Main__Onboarding">
        <ModalSelector
          params={this.props.params}
          location={this.props.location}
        />
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <img className="Main__Onboarding--logo" alt="logo" src={LogoSvg} />
        </RenderWhen>
        <Layout
          configurations={[
            {
              screenSize: ScreenSize.Desktop,
              hideSideBar: true,
              hideMenuOptions: true,
            },
          ]}
        >
          {this.props.content}
        </Layout>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <img className="Main__Onboarding--mask" alt="mask" src={maskSvg} />
        </RenderWhen>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  redirectToRoot: () => {
    const navigate = getMultiPlatformNavigation();

    return dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  },
  onIconMenuClick: () => {
    trackOpenedBurgerMenu();
    return dispatch(sideBarStateChanged(true));
  },
});

const mapStateToProps = (state, { params, location, children }) => ({
  isWebOnboardingFinished: getAccountOptionWebOnboardingFinished(state),
  isSchoolOnboarding: isSchoolOnboardingPath(state),
  content: state.getIn(['app', 'isContentHidden']) ? null : children,
  params,
  location,
});

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main);

export default MainContainer;
