import * as React from 'react';
import {
  Badge,
  BadgeType,
  Card,
  CustomStyleListItem,
  List,
  StyledHeader,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SignatureReview } from '../../../records/studentActivity/signature/types/SignatureReview.types';
import { SignatureRisks } from '../../../records/studentActivity/signature/types/SignatureRisks.types';

type SignatureKnownRisksCardProps = Pick<SignatureReview, 'risks'>;

const getBadge = (riskValue: SignatureRisks['qustodioValue']) => {
  const { color, text } = (() => {
    switch (riskValue) {
      case 'LOW':
        return { color: BadgeType.success, text: t('Low') };
      case 'MEDIUM':
        return { color: BadgeType.warning, text: t('Med') };
      case 'HIGH':
        return { color: BadgeType.error, text: t('High') };
      default:
        return { color: BadgeType.neutral, text: t('Unknown') };
    }
  })();

  return <Badge className="risk-value" type={color} text={text} />;
};

const SignatureKnownRisksCard: React.FunctionComponent<
  SignatureKnownRisksCardProps
> = ({ risks }: SignatureKnownRisksCardProps) => {
  return (
    <Card fullWidth className="SignatureKnownRisksCard">
      <StyledHeader type="h5">{t('Known risks')}</StyledHeader>
      <List className="risk-list">
        {risks.toJS().map(risk => (
          <CustomStyleListItem
            key={risk.name}
            rightContent={getBadge(risk.qustodioValue)}
            title={<span className="name">{t(risk.name)}</span>}
          />
        ))}
      </List>
    </Card>
  );
};

export default SignatureKnownRisksCard;
