import { connect } from 'react-redux';
import { Dispatch } from '../../store/state';
import { LINK_ACCOUNT_SETTINGS } from '../../constants';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { goBackIfHistory } from '../../ducks/routing';
import SafeNetworkSettingsModal from '../../components/Modal/SafeNetworkSettingsModal';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(goBackIfHistory());
  },
  onGoToSettings: () => {
    return dispatch(async dispatch => {
      const navigate = getMultiPlatformNavigation();
      await dispatch(
        navigate({
          type: 'inner:replace',
          src: LINK_ACCOUNT_SETTINGS,
        })
      );
    });
  },
});

const SafeNetworkInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(SafeNetworkSettingsModal);

export type SafeNetworkInfoModalProps = ReturnType<typeof mapDispatchToProps>;

export default SafeNetworkInfoModalContainer;
