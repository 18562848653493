import * as React from 'react';

import { t } from '../../lib/i18n';
import { ProfileFormRecord } from './ProfileForm';
import ProfileContainerForm from './ProfileContainerForm';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import connectToLayout from '../Layout/connectToLayout';
import { IconColor } from 'styleguide-react';

const ProfileEdit: React.FunctionComponent<{
  form: ProfileFormRecord;
  initialValues: ProfileFormRecord;
  disabled: boolean;
  layoutHasProfile: boolean;
  onNextClick: () => any;
  onClickDelete: () => any;
}> = ({
  form,
  disabled,
  onNextClick,
  layoutHasProfile,
  initialValues,
  onClickDelete,
}) => (
  <PageLayout
    title={t('Edit child')}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: layoutHasProfile ? 2 : 1,
        mainInsideCard: true,
        mainReducedSize: !layoutHasProfile,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Mobile,
        useNonFlexLayout: false,
      },
    ]}
    actions={[
      { actionName: 'back' },
      {
        actionName: 'delete',
        onClick: onClickDelete,
        iconColor: IconColor.error,
      },
    ]}
  >
    <ProfileContainerForm
      form={form}
      disabled={disabled}
      buttonLabel={t('Done')}
      onNextClick={onNextClick}
      initialValues={initialValues}
    />
  </PageLayout>
);

export default connectToLayout(ProfileEdit);
