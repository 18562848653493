import {
  Card,
  GlobalType,
  Icon,
  IconColor,
  IconType,
  Layout,
  TabPanel,
  Tabs,
  TransparentButton,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import * as React from 'react';
import TotalScreenTimeContainer from '../../containers/TotalScreenTimeContainer/TotalScreenTimeContainer';
import type { TotalScreenTimeCardProps } from '../../containers/TotalScreenTimeContainer/TotalScreenTimeCardContainer';
import ScreenTimeDetailsContainer from '../../containers/ScreenTimeDetailsContainer/ScreenTimeDetailsContainer';
import { classNames } from '../../helpers';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { SummaryDateRanges } from '../../constants';
import { isTesting } from '../../helpers/env';

type ActiveTabType = 'chart' | 'details';

const DetailsTabs = ({
  selectedTab,
  onChange,
}: {
  selectedTab: ActiveTabType;
  onChange: (tab: ActiveTabType) => void;
}) => {
  return (
    <Tabs
      hugContent
      showSeparator={false}
      className="par-screen-time-card__tabs"
      testId="par-screen-time-card__tabs"
      onChange={tab => onChange(tab as ActiveTabType)}
    >
      <TabPanel
        name="chart"
        testId="par-screen-time-card__tabs--chart"
        active={selectedTab === 'chart'}
        content={
          <Icon
            type={IconType.chartPieSimple}
            color={
              selectedTab === 'chart' ? IconColor.contrast : IconColor.secondary
            }
          />
        }
      >
        <React.Fragment />
      </TabPanel>
      <TabPanel
        name="details"
        testId="par-screen-time-card__tabs--details"
        active={selectedTab === 'details'}
        content={
          <Icon
            type={IconType.listUl}
            color={
              selectedTab === 'details'
                ? IconColor.contrast
                : IconColor.secondary
            }
          />
        }
      >
        <React.Fragment />
      </TabPanel>
    </Tabs>
  );
};

const useTotalScreenTimeCardStrategy = (
  hasReportedRoutines: boolean,
  activeDateRange: SummaryDateRanges
) => {
  const [selectedTab, setSelectedTab] = React.useState<ActiveTabType>('chart');

  const todayOrCustomPastDay = React.useMemo(
    () =>
      [SummaryDateRanges.Today, SummaryDateRanges.CustomPastDay].includes(
        activeDateRange
      ),
    [activeDateRange]
  );

  React.useEffect(() => {
    setSelectedTab('chart');
  }, [activeDateRange]);

  const getCardHeaderProp = (onClickAction: () => void) => {
    if (hasReportedRoutines && todayOrCustomPastDay) {
      return {
        title: (
          <React.Fragment>
            <Typography type="body1" weight="semibold">
              {selectedTab === 'chart' && t('Total screen time')}
              {selectedTab === 'details' && t('Screen time details')}
            </Typography>
            <Layout marginLeft="8">
              <TransparentButton
                testId="par-total-screen-time-card-link__action"
                onClick={onClickAction}
              >
                <Icon type={IconType.infoCircle} color={IconColor.secondary} />
              </TransparentButton>
            </Layout>
          </React.Fragment>
        ),
        actionElement: (
          <DetailsTabs
            selectedTab={selectedTab}
            onChange={(tab: ActiveTabType) => setSelectedTab(tab)}
          />
        ),
      };
    }
    return {
      title: t('Total screen time'),
      actionElement: (
        <Icon type={IconType.infoCircle} color={IconColor.secondary} />
      ),
      onClickAction,
    };
  };

  const getComponent = (profile?: ProfileRecord) => {
    if (
      hasReportedRoutines &&
      todayOrCustomPastDay &&
      selectedTab === 'details'
    ) {
      if (isTesting())
        return <div data-testid="par-screen-time-card__details" />;
      return <ScreenTimeDetailsContainer profile={profile} />;
    }

    if (isTesting()) return <div data-testid="par-screen-time-card__chart" />;
    return <TotalScreenTimeContainer profile={profile} />;
  };

  return { selectedTab, getCardHeaderProp, getComponent };
};

const TotalScreenTimeCard = ({
  height,
  width,
  profile,
  hasReportedRoutines,
  activeDateRange,
  goToInfoModal,
}: TotalScreenTimeCardProps) => {
  const { selectedTab, getCardHeaderProp, getComponent } =
    useTotalScreenTimeCardStrategy(hasReportedRoutines, activeDateRange);

  return (
    <Card
      height={height}
      width={width}
      type={GlobalType.white}
      header={{
        ...getCardHeaderProp(goToInfoModal),
        testId: 'par-total-screen-time-card-link',
      }}
      className={classNames(
        'par-screen-time-card',
        selectedTab === 'details' && 'par-screen-time-card--no-padding'
      )}
    >
      {getComponent(profile)}
    </Card>
  );
};

export default TotalScreenTimeCard;
