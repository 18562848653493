import * as React from 'react';
import { List } from 'immutable';
import { ProductRecord, AccountRecord, LicenseRecord } from '../../records';
import { ProductDuration } from '../../records/product';
import { classNames } from '../../helpers';
import CustomPlanBox from './CustomPlanBox';
import { getEquivalentProductWithDedicatedSupport } from '../../selectors';

const ProductList: React.FunctionComponent<{
  products: List<ProductRecord>;
  productListDedicatedSupport?: List<ProductRecord>;
  account: AccountRecord;
  license: LicenseRecord;
  onClickProduct: (product: ProductRecord) => any;
  selectedProductCode: null | string;
  planDuration: ProductDuration;
  areUpsellProducts: boolean;
  isPurchaseInProgress: boolean;
  isProductDurationOneYear: boolean;
  isEnabledDedicatedSupportV2: boolean;
}> = ({
  products,
  productListDedicatedSupport = [],
  account,
  license,
  onClickProduct,
  selectedProductCode,
  planDuration,
  areUpsellProducts,
  isPurchaseInProgress,
  isProductDurationOneYear,
  isEnabledDedicatedSupportV2,
}) => (
  <div
    className={classNames(
      'ProductList',
      isPurchaseInProgress ? 'ProductList--disabled' : ''
    )}
  >
    {products.map((product: ProductRecord) => {
      const productDedicatedSupport = !product.isDedicatedSupport
        ? getEquivalentProductWithDedicatedSupport(
            product,
            productListDedicatedSupport
          )
        : undefined;

      const upsellProduct = areUpsellProducts && !product.current;
      const props = {
        account,
        product,
        productDedicatedSupport,
        license,
        isPurchaseInProgress,
        isProductDurationOneYear,
        planDuration,
        selectedProductCode,
        upsellProduct,
        areUpsellProducts,
        onClickProduct,
        isEnabledDedicatedSupportV2,
      };
      return <CustomPlanBox key={product.code} {...props} />;
    })}
  </div>
);

export default ProductList;
