import { combineEpics } from 'redux-observable';
import { notifyLogoutToParent } from './logout';
import { notifyNavigationToParent } from './navigation';

const contentCommunicationEpics = combineEpics(
  notifyLogoutToParent,
  notifyNavigationToParent
);

export default contentCommunicationEpics;
