import { getOnboardingProfile } from '../../selectors';
import { addProfile, setLoadingProfiles } from '../../ducks/appOnboarding';
import { fetchProfile } from '../../ducks/profiles';

export const onEnterOnboardingFinish =
  ({ profileId }: { profileId: string }) =>
  (dispatch, getState) => {
    dispatch(setLoadingProfiles(false));

    const currentOnboardingProfile = getOnboardingProfile(getState());
    if (currentOnboardingProfile) {
      return;
    }

    if (!profileId) {
      return;
    }

    return dispatch(fetchProfile(profileId))
      .then(action => action.payload.records.profiles[profileId])
      .then(profile => {
        dispatch(addProfile(profile));
      });
  };
