import { isNil } from 'ramda';
import { where } from '../decision';
import { PauseProfileDecisionContext } from './types';
import {
  isDatetimeBetweenDatetimes,
  isDatetimeTheSameOrAfter,
  isDatetimeTheSameOrBefore,
} from '../../helpers/dates';

export const hasSomeDeviceInSafeNetworks = where<PauseProfileDecisionContext>({
  deviceInSafeNetwork: value => Boolean(value),
});

export const hasMultipleDevicesInASafeNetwork =
  where<PauseProfileDecisionContext>({
    devicesWithSafeNetworkActive: value => value.length > 1,
  });

export const profileHasSchoolDevice = where<PauseProfileDecisionContext>({
  profile: value => Boolean(value.isLinewizestudentLinked),
});

export const hasProfileSchool247Enabled = where<PauseProfileDecisionContext>({
  studentPause247Enabled: value => Boolean(value),
});

export const isWholePauseDuringSchoolPeriod = (
  ctx: PauseProfileDecisionContext
) => {
  if (
    isNil(ctx.schoolStartDatetime) ||
    isNil(ctx.schoolEndDatetime) ||
    isNil(ctx.pauseStart) ||
    isNil(ctx.pauseEnd)
  ) {
    return false;
  }
  return (
    isDatetimeTheSameOrAfter(ctx.pauseStart, ctx.schoolStartDatetime) &&
    isDatetimeTheSameOrBefore(ctx.pauseEnd, ctx.schoolEndDatetime)
  );
};

export const isPausePartiallyInSchoolPeriod = (
  ctx: PauseProfileDecisionContext
) => {
  if (
    isNil(ctx.schoolStartDatetime) ||
    isNil(ctx.schoolEndDatetime) ||
    isNil(ctx.pauseStart) ||
    isNil(ctx.pauseEnd)
  ) {
    return false;
  }

  return (
    isDatetimeBetweenDatetimes(
      ctx.pauseStart,
      ctx.schoolStartDatetime,
      ctx.schoolEndDatetime
    ) ||
    isDatetimeBetweenDatetimes(
      ctx.pauseEnd,
      ctx.schoolStartDatetime,
      ctx.schoolEndDatetime
    ) ||
    isDatetimeBetweenDatetimes(
      ctx.schoolStartDatetime,
      ctx.pauseStart,
      ctx.pauseEnd
    ) ||
    isDatetimeBetweenDatetimes(
      ctx.schoolEndDatetime,
      ctx.pauseStart,
      ctx.pauseEnd
    )
  );
};

export const isDelegatedToOtherGuardian = where<PauseProfileDecisionContext>({
  isStudentDelegatedToAnotherAccount: value => Boolean(value),
});
