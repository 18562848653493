import * as React from 'react';
import { t } from '../../../../lib/i18n';
import { Typography } from 'styleguide-react';

const AndroidLabel = () => {
  return (
    <Typography type="body2" color="gray-semi" weight="semibold">
      {t('For Android')}
    </Typography>
  );
};

export { isVisibleAndroidLabel } from './predicate';
export default AndroidLabel;
