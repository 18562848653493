import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  DurationInput,
  ButtonSize,
  FlexLayout,
  ModalStyledText,
  Layout,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import { minutesToHMFormat } from '../../helpers/dates';
import { when } from '../../helpers/render';

interface ExtraTimeModalProps {
  type: 'add' | 'edit';
  dailyLimit: number;
  extraTime: number | null;
  onClickClose: () => void;
  onSetExtraTime: (
    actionType: 'add' | 'edit',
    minutes: number,
    origin: string
  ) => void;
  origin: string;
}

const isAddType = (type: ExtraTimeModalProps['type']) => type === 'add';

const hasExtraTime = (minutes: number) => minutes > 0;

const ExtraTimeModal: React.FunctionComponent<ExtraTimeModalProps> = ({
  type,
  dailyLimit,
  extraTime,
  origin,
  onSetExtraTime,
  onClickClose,
}: ExtraTimeModalProps) => {
  const [minutes, setValue] = React.useState(extraTime || 0);

  return (
    <Modal
      title={t('Extra time.8')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={() => onSetExtraTime(type, minutes, origin)}
          size={ButtonSize.medium}
          disabled={isAddType(type) && !minutes}
          block
        >
          {t('Confirm')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="extra-time-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
          <ModalStyledText marginBottom="8">
            {isAddType(type)
              ? t('Add extra time below:')
              : t('Edit the remaining extra time below:')}
          </ModalStyledText>
        </FlexLayout>

        <Layout marginTop="24">
          <DurationInput
            value={minutes}
            onChange={(minutes: number) => setValue(minutes)}
            maxMinutes={24 * 60}
          />
        </Layout>

        {when(
          hasExtraTime(minutes),
          <Layout marginTop="24">
            <ModalStyledText>
              {t(
                `Today's time limit is now {1}{{dailylimit}} + {{extratime}}{/1}`,
                {
                  1: str => <strong>{str}</strong>,
                  dailylimit: minutesToHMFormat(dailyLimit),
                  extratime: minutesToHMFormat(minutes),
                }
              )}
            </ModalStyledText>
          </Layout>
        )}
      </FlexLayout>
    </Modal>
  );
};
export default ExtraTimeModal;
