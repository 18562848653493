import * as React from 'react';
import { List as ImmutableList } from 'immutable';
import {
  RadioButton,
  Button,
  FlexLayout,
  ButtonSize,
  Label,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  CALLSMS_RULES_ALLOW,
  CALLSMS_RULES_BLOCK,
  CallSmsRulesAction,
} from '../../constants';
import CallsSMSRulesAddContactForm, {
  formName,
} from './CallsSMSRulesAddContactForm';
import { CallsSmsContactRecord } from '../../records/profileRules';
import type { ContactRecord } from '../../records/contact';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

interface CallsSMSRulesAddContactProps {
  contacts: ImmutableList<CallsSmsContactRecord>;
  selectedContactAction: CallSmsRulesAction;
  isSaving: boolean;
  country: string;
  onClickContactAction: (action: CallSmsRulesAction) => void;
  onClickBackButton: () => void;
  onSubmit: (contact: ContactRecord) => void;
}

const CallsSMSRulesAddContact: React.FunctionComponent<
  CallsSMSRulesAddContactProps
> = ({
  contacts,
  selectedContactAction,
  isSaving,
  country,
  onClickBackButton,
  onClickContactAction,
  onSubmit,
}: CallsSMSRulesAddContactProps) => {
  const renderContent = () => (
    <FlexLayout mainaxis="column" matchParentHeight>
      <CallsSMSRulesAddContactForm
        onSubmit={onSubmit}
        contacts={contacts}
        country={country}
      />
      <FlexLayout mainaxis="row" crossaxisAlignment="center" paddingBottom="16">
        <FlexLayout mainaxis="column" paddingRight="24">
          <RadioButton
            id={CALLSMS_RULES_ALLOW}
            value={CALLSMS_RULES_ALLOW}
            checked={selectedContactAction === CALLSMS_RULES_ALLOW}
            onChange={() => onClickContactAction(CALLSMS_RULES_ALLOW)}
          />
        </FlexLayout>
        <FlexLayout mainaxis="column">
          <Label htmlFor={CALLSMS_RULES_BLOCK}>{t('Allow all calls')}</Label>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout mainaxis="row" crossaxisAlignment="center" paddingBottom="16">
        <FlexLayout mainaxis="column" paddingRight="24">
          <RadioButton
            id={CALLSMS_RULES_BLOCK}
            value={CALLSMS_RULES_BLOCK}
            checked={selectedContactAction === CALLSMS_RULES_BLOCK}
            onChange={() => onClickContactAction(CALLSMS_RULES_BLOCK)}
          />
        </FlexLayout>
        <FlexLayout mainaxis="column" mainaxisAlignment="center">
          <Label htmlFor={CALLSMS_RULES_BLOCK}>{t('Block all calls')}</Label>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        mainaxis="column"
        marginTop="72"
        width="100%"
        crossaxisAlignment="center"
      >
        <Button
          size={ButtonSize.medium}
          form={formName}
          loading={isSaving}
          htmlType="submit"
        >
          {t('Save')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );

  return (
    <PageLayout
      title={t('Add Contact')}
      configuration={[
        { screenSize: ScreenSize.Desktop, mainInsideCard: true },
        { screenSize: ScreenSize.Tablet, mainInsideCard: true, columns: 1 },
      ]}
      actions={[{ actionName: 'back', onClick: onClickBackButton }]}
    >
      {renderContent()}
    </PageLayout>
  );
};

export default CallsSMSRulesAddContact;
