import * as React from 'react';
import { Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { MAX_NAME_LENGTH } from '../../constants';
import InputTextField from '../Form/InputTextField';
import { FlexLayout, Label } from 'styleguide-react';

export const formName = 'editName';

export const saveLastNameButtonId = 'SaveLastNameButton';
export const saveNameButtonId = 'SaveNameButton';

const EditNameForm: React.FunctionComponent<{
  handleSubmit: () => any;
  className?: string;
}> = ({ handleSubmit, className = '' }) => (
  <form className={`Form ${className}`} onSubmit={handleSubmit} id={formName}>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={saveNameButtonId}>{t('Name')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <Field
        id={saveNameButtonId}
        name="name"
        component={InputTextField}
        type="text"
        placeholder={t('First Name')}
      />
    </FlexLayout>

    <FlexLayout mainaxis="column" marginBottom="8" marginTop="8">
      <Label htmlFor={saveLastNameButtonId}>{t('Last Name')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Field
        id={saveLastNameButtonId}
        name="surname"
        component={InputTextField}
        type="text"
        placeholder={t('Last Name')}
      />
    </FlexLayout>
    {/* include an invisible submit-button so that the mobile-keyboard
       has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

interface FormError {
  [key: string]: string;
}

const validate = values => {
  const errors: FormError = {};
  if (!values.name) {
    errors.name = t('Field required');
  }
  if (values.name.length > MAX_NAME_LENGTH) {
    errors.name = t(
      'Name should not be longer than {{MAX_NAME_LENGTH}} characters',
      {
        MAX_NAME_LENGTH,
      }
    );
  }
  if (values.surname.length > MAX_NAME_LENGTH) {
    errors.surname = t(
      'Last name should not be longer than {{MAX_NAME_LENGTH}} characters',
      {
        MAX_NAME_LENGTH,
      }
    );
  }
  return errors;
};

export type EditNameFormRecord = {
  id: string;
  name: string;
  surname: string;
};

export const FormRecord = Record<EditNameFormRecord, {}>({
  id: '',
  name: '',
  surname: '',
});

export const formRecord = (values?: EditNameFormRecord) => {
  if (values === undefined) {
    return FormRecord();
  }

  const record = values instanceof Record ? values : FormRecord(values);

  return record;
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: formRecord(),
  validate: values => validate(values.toJS()),
})(EditNameForm);
