import * as React from 'react';
import {
  ActionInput,
  Card,
  Layout,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  StyledHeader,
  Switch,
  List,
  RegularStyleListItem,
  GlobalType,
  ContentSeparator,
  FreeText,
  InstructionBlock,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import ft from '../../lib/ft';
import AccountSettingsLicense from './AccountSettingsLicense';
import IconLaptopWindows from '../Icons/IconLaptopWindows';
import IconLaptopMac from '../Icons/IconLaptopMac';
import IconPhoneAndroid from '../Icons/IconPhoneAndroid';
import IconPhoneIphone from '../Icons/IconPhoneIphone';
import {
  LINK_NAME,
  LINK_EMAIL,
  LINK_PASSWORD,
  LINK_LANGUAGE,
  LINK_TIMEZONE,
  LINK_COUNTRY,
  LINK_ACCOUNT_SETTINGS,
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  SettingsToggleNames,
  Platform,
  LINK_PHONE,
  PLATFORM_CHROME,
} from '../../constants';
import {
  getLanguage,
  getTimezone,
  getCountry,
  getPhone,
} from '../../helpers/languages';
import {
  isNonExpiringPremium,
  shouldShowConversionElements,
} from '../../records/license';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';
import AdditionalParent from './AdditionalParent';
import AccountSettingsExtra from './AccountSettingsExtra';
import CloseAccount from './CloseAccount';
import type { AccountSettingsProps } from '../../containers/AccountSettings/AccountSettingsContainer';
import IconChrome from '../Icons/IconChrome';

type ManageSubscriptionProps = { onManageSubscription: () => void };

const ManageSubscription: React.FC<ManageSubscriptionProps> = ({
  onManageSubscription,
}: ManageSubscriptionProps) => (
  <FlexLayout mainaxis="column" marginBottom="24" marginTop="24">
    <ContentSeparator />
    <FlexLayout mainaxis="column" marginBottom="16" marginTop="16">
      <List listItemMarginBottom="24">
        <RegularStyleListItem
          title={
            <FreeText fontSize="16px">{t('Manage subscription')}</FreeText>
          }
          iconVerticalAlign="center"
          actionElement={
            <Icon
              type={IconType.angleRight}
              size={IconSize.lg}
              color={IconColor.black}
              onClick={onManageSubscription}
              testId="manage-subscription-link"
            />
          }
        />
      </List>
    </FlexLayout>
    <ContentSeparator />
  </FlexLayout>
);

const AccountSettings: React.FunctionComponent<AccountSettingsProps> = (
  accountSettingsProps: AccountSettingsProps
) => {
  const {
    toggles,
    isSlaveAccount,
    showCloseAccount,
    shouldDisplayMultiparentFeature,
    shouldDisplayManageSubscriptionLink,
    onToggle,
    onClickCloseAccount,
    onManageSubscription,
  } = accountSettingsProps;

  return (
    <PageLayout
      title={t('Your account')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 2,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 2,
          mainInsideCard: true,
          useNonFlexLayout: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          columns: 1,
          useNonFlexLayout: true,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
    >
      {shouldDisplayManageSubscriptionLink && (
        <PageSectionLayout>
          <ManageSubscription onManageSubscription={onManageSubscription} />
        </PageSectionLayout>
      )}

      <PageSectionLayout side="main">
        <AccountSettingsFields {...accountSettingsProps} />

        {shouldDisplayMultiparentFeature && !isSlaveAccount && (
          <FlexLayout mainaxis="column" marginTop="8">
            <AdditionalParent />
          </FlexLayout>
        )}
        <AccountSettingsExtra />
        {showCloseAccount && (
          <FlexLayout mainaxis="column" marginTop="8">
            <CloseAccount onClickCloseAccount={onClickCloseAccount} />
          </FlexLayout>
        )}
      </PageSectionLayout>
      <PageSectionLayout side="right">
        <Card
          type={GlobalType.white}
          header={{
            title: t('Notifications'),
            border: true,
          }}
          className={classNames(
            DesktopLayoutScssUtils.CardRight,
            TabletLayoutScssUtils.CardRight
          )}
        >
          <FlexLayout mainaxis="column">
            <NotificationsToggles toggles={toggles} onToggle={onToggle} />
          </FlexLayout>
        </Card>
      </PageSectionLayout>
    </PageLayout>
  );
};

const AccountSettingsFields: React.FunctionComponent<AccountSettingsProps> = ({
  account,
  license,
  toggles,
  currentTime,
  isSlaveAccount,
  masterAccountName,
  multiParentInitiativeEnabled,
  onToggle,
  onClickLink,
  onBadgeClick,
}: AccountSettingsProps) => (
  <FlexLayout mainaxis="column">
    {multiParentInitiativeEnabled &&
      showAdditionalParentInfo(masterAccountName)}
    <Layout>
      {!isNonExpiringPremium(license) &&
        ft.active('show_license') &&
        shouldShowConversionElements(license) && (
          <AccountSettingsLicense
            account={account}
            license={license}
            currentTime={currentTime}
            currentPage={LINK_ACCOUNT_SETTINGS}
            onBadgeClick={onBadgeClick}
          />
        )}
    </Layout>
    <Layout>
      <FlexLayout mainaxis="column" marginBottom="24">
        <ActionInput
          testId="fullNameAccountSetting"
          type="text"
          name="fullname"
          text={account.fullname}
          readOnly
          onClick={() => onClickLink(LINK_NAME)}
          icon={
            <Icon
              type={IconType.user}
              color={IconColor.secondary}
              size={IconSize.lg}
            />
          }
        />
      </FlexLayout>
      <FlexLayout mainaxis="column" marginBottom="24">
        <ActionInput
          testId="emailAccountSetting"
          type="text"
          name="email"
          text={account.email}
          readOnly
          onClick={
            ft.active('allow_edit_email')
              ? () => onClickLink(LINK_EMAIL)
              : undefined
          }
          icon={
            <Icon
              type={IconType.envelope}
              color={IconColor.secondary}
              size={IconSize.lg}
            />
          }
        />
      </FlexLayout>
      {ft.active('show_password_field') && (
        <FlexLayout mainaxis="column" marginBottom="24">
          <ActionInput
            testId="passwordAccountSetting"
            type="text"
            name="password"
            text="************"
            readOnly
            onClick={() => onClickLink(LINK_PASSWORD)}
            icon={
              <Icon
                type={IconType.eye}
                color={IconColor.secondary}
                size={IconSize.lg}
              />
            }
          />
        </FlexLayout>
      )}
      {ft.active('show_language_field') && (
        <FlexLayout mainaxis="column" marginBottom="24">
          <ActionInput
            testId="languageAccountSetting"
            type="text"
            name="language"
            text={getLanguage(account.locale)}
            readOnly
            onClick={() => onClickLink(LINK_LANGUAGE)}
            icon={
              <Icon
                type={IconType.flag}
                color={IconColor.secondary}
                size={IconSize.lg}
              />
            }
          />
        </FlexLayout>
      )}
      <FlexLayout mainaxis="column" marginBottom="24">
        <ActionInput
          testId="timezoneAccountSetting"
          type="text"
          name="timezone"
          text={getTimezone(currentTime, account.timezone)}
          readOnly
          disabled={isSlaveAccount}
          onClick={() => onClickLink(LINK_TIMEZONE)}
          icon={
            <Icon
              type={IconType.clock}
              color={isSlaveAccount ? IconColor.disabled : IconColor.secondary}
              size={IconSize.lg}
            />
          }
        />
      </FlexLayout>
      <FlexLayout mainaxis="column" marginBottom="24">
        <ActionInput
          testId="countryAccountSetting"
          type="text"
          name="country"
          text={getCountry(account.country)}
          readOnly
          disabled={isSlaveAccount}
          onClick={() => onClickLink(LINK_COUNTRY)}
          icon={
            <Icon
              type={IconType.globe}
              color={isSlaveAccount ? IconColor.disabled : IconColor.secondary}
              size={IconSize.lg}
            />
          }
        />
      </FlexLayout>

      <FlexLayout mainaxis="column">
        <ActionInput
          testId="AccountPhone"
          type="text"
          name="telephone"
          text={getPhone(account.telephone)}
          readOnly
          onClick={() => onClickLink(LINK_PHONE)}
          icon={
            <Icon
              type={IconType.phone}
              color={IconColor.secondary}
              size={IconSize.lg}
            />
          }
        />
      </FlexLayout>
    </Layout>
    <FlexLayout mainaxis="column" marginTop="32">
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <StyledHeader type="h4">{t('Notifications')}</StyledHeader>
        <NotificationsToggles toggles={toggles} onToggle={onToggle} />
      </RenderWhen>
    </FlexLayout>
  </FlexLayout>
);

interface NotificationsTogglesProps {
  toggles: AccountSettingsProps['toggles'];
  onToggle: (target: SettingsToggleNames, checked: boolean) => void;
}

const NotificationsToggles: React.FunctionComponent<
  NotificationsTogglesProps
> = ({ toggles, onToggle }: NotificationsTogglesProps) => (
  <FlexLayout mainaxis="column" marginTop="16" marginBottom="24">
    <List
      listItemMarginBottom="8"
      listItemPaddingTop="8"
      listItemPaddingBottom="8"
    >
      {toggles.map(toggle => (
        <RegularStyleListItem
          title={toggle.label}
          upperSubtitle={toggle.description}
          key={toggle.id}
          actionElement={
            <Switch
              id={toggle.id}
              checked={toggle.checked}
              onClick={ev =>
                onToggle(toggle.id, (ev.target as HTMLInputElement).checked)
              }
              testId={toggle.testId}
            />
          }
        />
      ))}
    </List>
  </FlexLayout>
);

NotificationsToggles.displayName = 'NotificationsToggles';

export const selectDeviceIcon = (devicePlatform: Platform) => {
  switch (devicePlatform) {
    case PLATFORM_WINDOWS:
      return <IconLaptopWindows className="AccountSettingsIcon" />;
    case PLATFORM_MAC:
      return <IconLaptopMac className="AccountSettingsIcon" />;
    case PLATFORM_ANDROID:
      return <IconPhoneAndroid className="AccountSettingsIcon" />;
    case PLATFORM_IOS:
      return <IconPhoneIphone className="AccountSettingsIcon" />;
    case PLATFORM_CHROME:
      return <IconChrome className="AccountSettingsIcon" />;
    default:
      return null;
  }
};

const showAdditionalParentInfo = (masterAccountName: string | null) => {
  return (
    <Layout marginBottom="24" marginTop="8">
      <Layout marginBottom="24">
        <InstructionBlock testId="closeAdditionalAccount--info">
          {t(
            "You are an additional parent in {1}{{masterAccountName}}{/1}'s {{shortName}} account:",
            {
              1: str => <strong>{str}</strong>,
              masterAccountName,
            }
          )}
        </InstructionBlock>
      </Layout>
      <ContentSeparator />
    </Layout>
  );
};

export default AccountSettings;
