import { Record } from 'immutable';
import { Feature, FeatureCode } from '../../records/features/types';
import State from '../../store/state';

export const getFeatureByCode = (
  state: State,
  code: FeatureCode
): Record<Feature> | undefined => {
  return state.getIn(['features', 'features', code]);
};

export const getWellbeingTherapyFeature = (
  state: State
): Record<Feature> | undefined => {
  return state.getIn(['features', 'features', 'wellbeing_therapy']);
};

export const getWellbeingActivitiesFeature = (
  state: State
): Record<Feature> | undefined => {
  return state.getIn(['features', 'features', 'wellbeing_activities']);
};

export const getFeatureStatus = (state: State) => {
  return state.getIn(['features', 'status']);
};

export const getFeatures = (state: State) => {
  return state.getIn(['features', 'features']);
};
