import { connect } from 'react-redux';
import {
  saveChildAndContinue,
  resetSelectedAvatar,
} from '../../actions/onboarding/OnboardingAddChildFormActions';
import OnboardingAddChild from '../../components/Onboarding/Views/OnboardingAddChild';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { trackablePage } from '../../helpers/hocs';
import { PageNames, trackOpenedBurgerMenu } from '../../helpers/analytics';
import { OnBoardingRoutes } from '../../constants';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import store from '../../store';
import { t } from '../../lib/i18n';

const mapDispatchToProps = dispatch => ({
  onClickSaveAndContinue: () =>
    dispatch(saveChildAndContinue(OnBoardingRoutes.addDevice)),
  onChangeGender: gender => dispatch(resetSelectedAvatar(gender)),
});

const OnboardingAddChildContainer = connect(
  null,
  mapDispatchToProps
)(trackablePage(OnboardingAddChild, PageNames.OnboardingAddKidActivity));

export default OnboardingWrapper({
  activeStep: 1,
  showSupport: true,
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(OnboardingAddChildContainer);
