import { Environment } from '../../constants';
import { isBrowserPlatform } from '../../helpers';
import { AuthEventOptions } from '../../helpers/attribution/types/AuthEventOptions.types';
import { getEnvironment } from '../../helpers/env';
import * as qinit from '../../qinit';
import {
  getAdjustConfig,
  createAdjust,
  getAdjustConfigConstants,
  trackEvent,
} from '../wrappers/adjust';

export const adjustApplies = () => !isBrowserPlatform();

export const setupAdjustIfNeeded = () => {
  if (!adjustApplies()) {
    return;
  }

  const adjustConstants = getAdjustConfigConstants(
    getEnvironment() === Environment.PRO
  );

  const adjustConfig = getAdjustConfig(
    qinit.tenant.common.adjust_sdk_parent_only_app.app_token,
    adjustConstants.Environment
  );

  adjustConfig.setLogLevel(adjustConstants.LogLevel);
  adjustConfig.setAppSecret(
    ...qinit.tenant.common.adjust_sdk_parent_only_app.app_secret
  );
  adjustConfig.setFbAppId(
    qinit.tenant.common.adjust_sdk_parent_only_app.fb_app_id
  );

  createAdjust(adjustConfig);
};

export const trackAdjustEventIfApplies = (options: AuthEventOptions) => {
  if (!adjustApplies()) {
    return;
  }

  const { isNewAccount, accountUid } = options;
  if (isNewAccount) {
    trackEvent(
      qinit.tenant.common.adjust_sdk_parent_only_app.event_registration_id,
      accountUid
    );
  } else {
    trackEvent(
      qinit.tenant.common.adjust_sdk_parent_only_app.event_first_login_id,
      accountUid
    );
  }
};
