import { connect } from 'react-redux';
import { navigateToSummary } from '../../actions/onboarding/OnboardingActions';
import OnboardingFinish from '../../components/Onboarding/Views/OnboardingFinish';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { getOnboardingProfile } from '../../selectors';
import { isLoadingProfiles } from '../../selectors/onboarding';
import { trackablePage } from '../../helpers/hocs';
import { PageNames, trackOpenedBurgerMenu } from '../../helpers/analytics';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import store from '../../store';
import { t } from '../../lib/i18n';

const mapStateToProps = state => ({
  profile: getOnboardingProfile(state),
  isLoadingProfiles: isLoadingProfiles(state),
});

const mapDispatchToProps = dispatch => ({
  onClickNext: () => dispatch(navigateToSummary()),
});

export type OnboardingFinishProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const OnboardingFinishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(
    OnboardingFinish,
    PageNames.OnboardingProtectionConfirmedActivity
  )
);

export default OnboardingWrapper({
  activeStep: 3,
  showSupport: true,
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(OnboardingFinishContainer);
