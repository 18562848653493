import State from '../../store/state';
import { militaryTimeToAMPMFormatWithHoursAndMinutes } from '../../helpers/dates';
import { isMilitaryTimeFormat } from '../stateSelectors/account';

/** @param time HH:mm */
export const getHumanFormattedTime = (state: State, time: string) => {
  const isMilitaryTime = isMilitaryTimeFormat(state);
  return isMilitaryTime
    ? time
    : militaryTimeToAMPMFormatWithHoursAndMinutes(time);
};
