import { LicenseSubtype } from './types/License.types';

export const isFree = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'free';

export const isPremiumGrandfather = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'premium_grandfather';

export const isPremiumBasic = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'premium_basic';

export const isPremiumComplete = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'premium_complete';

export const isFreeOrPremiumBasic = (licenseSubtype: LicenseSubtype) =>
  isFree(licenseSubtype) || isPremiumBasic(licenseSubtype);

export const isTrial = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'trial';

export const isTrialOrFree = (licenseSubtype: LicenseSubtype) =>
  isTrial(licenseSubtype) || isFree(licenseSubtype);

export const isSchoolFree = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'school_free';

export const isValidLicenseSubtype = (licenseSubtype: LicenseSubtype) =>
  [
    'free',
    'trial',
    'school_free',
    'premium_basic',
    'premium_complete',
    'premium_grandfather',
  ].includes(licenseSubtype);

/** @returns true if licenseSubtype belongs to any premium license */
export const hasSomePremium = (licenseSubtype: LicenseSubtype) =>
  ['premium_basic', 'premium_complete', 'premium_grandfather'].includes(
    licenseSubtype
  );
