import State, { Dispatch } from '../../store/state';
import {
  getSummaryAlertCounter,
  requestResetSummaryAlertsCounterAction,
} from '../../ducks/summaryAlerts';
import { getProfileId, getProfileUid } from '../../selectors';
import { SummaryAlertsTypes } from '../../ducks/summaryAlerts/types/SummaryAlertsState.type';
import {
  trackCloseSummaryAlertsBanner,
  trackReviewSummaryAlertsBanner,
} from './SummaryAlertsTracking';
import { navigateToFamilyTimeline } from '../../actions/Navigation';
import { ActivityEventFilters } from '../../businessLogic/timeline/types';

export const safeGetSummaryAlertCounter = (state: State) => {
  const profileUuid = getProfileUid(state);

  return profileUuid
    ? getSummaryAlertCounter(state, profileUuid, 'searches')
    : 0;
};

const trackCloseSummaryAlertsBannerThunk =
  () => (_dispatch: Dispatch, getState: () => State) => {
    const count = safeGetSummaryAlertCounter(getState());
    trackCloseSummaryAlertsBanner({ count, type: 'searches' });
  };

const trackReviewSummaryAlertsBannerThunk =
  () => (_dispatch: Dispatch, getState: () => State) => {
    const count = safeGetSummaryAlertCounter(getState());
    trackReviewSummaryAlertsBanner({ count, type: 'searches' });
  };

const resetAlertsCounterThunk =
  (type: SummaryAlertsTypes) => (dispatch: Dispatch, getState: () => State) => {
    const profileUid = getProfileUid(getState());
    if (!profileUid) return;
    dispatch(requestResetSummaryAlertsCounterAction(profileUid, type));
    dispatch(trackCloseSummaryAlertsBannerThunk());
  };

const reviewTimelineAlertsThunk =
  () => (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState());
    if (!profileId) return;

    dispatch(
      navigateToFamilyTimeline({
        profileId,
        filter: ActivityEventFilters.InappropriateSearches,
      })
    );
  };

export const summaryAlertsMapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCloseSummaryAlerts: () => dispatch(resetAlertsCounterThunk('searches')),
    onReviewSummaryAlerts: () => {
      dispatch(trackReviewSummaryAlertsBannerThunk());
      dispatch(reviewTimelineAlertsThunk());
    },
  };
};

export const summaryAlertsMapStateToProps = (state: State) => {
  return {
    alertsCount: safeGetSummaryAlertCounter(state),
  };
};
