import * as React from 'react';
import { t } from '../../lib/i18n';
import { isiOSPlatform } from '../../helpers';
import { secondsToHourFormat } from '../../helpers/dates';
import HourLimitSlider from '../TimeLimits/HourLimitSlider';
import {
  ActionInput,
  ContentSeparator,
  DeactivableContent,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  InstructionBlock,
  Label,
  Layout,
  Switch,
} from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import locationSvg from '../../assets/base/images/v3/decorators/illustration_location.svg';

const ANDROID_VERSION = '[180.23]';

interface LocationRulesProps {
  locationEnabled: boolean;
  updateFrequencySeconds: number;
  canChooseUpdateFrequency: boolean;
  hasIOSDevices: boolean;
  onToggleLocationEnabled: (toggleOn: boolean) => any;
  onChange: (minutes: string) => any;
  onClickAuto: () => any;
  onClickMoreInfo: () => any;
}

const LocationRules: React.FunctionComponent<
  LocationRulesProps
> = locationRulesProps => (
  <PageLayout
    title={t('Location')}
    actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        mainInsideCard: true,
        columns: 2,
      },
      {
        screenSize: ScreenSize.Tablet,
        mainInsideCard: true,
        columns: 1,
      },
    ]}
  >
    <LocationRulesContent {...locationRulesProps} />
  </PageLayout>
);

const LocationRulesContent: React.FunctionComponent<{
  locationEnabled: boolean;
  updateFrequencySeconds: number;
  canChooseUpdateFrequency: boolean;
  hasIOSDevices: boolean;
  onToggleLocationEnabled: (toggleOn: boolean) => any;
  onChange: (minutes: string) => any;
  onClickAuto: () => any;
  onClickMoreInfo: () => any;
}> = ({
  locationEnabled,
  updateFrequencySeconds,
  canChooseUpdateFrequency,
  hasIOSDevices,
  onToggleLocationEnabled,
  onChange,
  onClickAuto,
  onClickMoreInfo,
}) => (
  <FlexLayout mainaxis="column">
    <FlexLayout
      mainaxis="row"
      mainaxisAlignment="space-between"
      crossaxisAlignment="center"
      flexGrow="0"
      paddingBottom="8"
    >
      <Label htmlFor="SwitchIsDailyLimit">
        {t('Enable location tracking')}
      </Label>
      <Switch
        id="SwitchIsDailyLimit"
        checked={locationEnabled}
        onClick={ev =>
          onToggleLocationEnabled((ev.target as HTMLInputElement).checked)
        }
      />
    </FlexLayout>
    <ContentSeparator />
    <DeactivableContent disabled={!locationEnabled}>
      {canChooseUpdateFrequency &&
        renderAndroidTimeSettingsItem(
          onClickMoreInfo,
          updateFrequencySeconds,
          !locationEnabled,
          onChange
        )}
      {hasIOSDevices && (
        <React.Fragment>
          <FlexLayout mainaxis="column" flexGrow="0" paddingTop="24">
            {renderTimeSettingsItem(
              'iOS:',

              renderUpdatePeriodFieldAuto(onClickAuto, !locationEnabled)
            )}
          </FlexLayout>
          <ContentSeparator />
        </React.Fragment>
      )}
      {!canChooseUpdateFrequency && locationEnabled && (
        <FlexLayout
          mainaxis="column"
          minHeight="240"
          crossaxisAlignment="center"
        >
          <img src={locationSvg} alt="location" width="224" height="224" />
          <Layout marginTop="16">{t('Location tracking is active')}</Layout>
        </FlexLayout>
      )}
    </DeactivableContent>
  </FlexLayout>
);

const renderAndroidTimeSettingsItem = (
  onClickMoreInfo,
  updateFrequencySeconds,
  disabled,
  onChange
) => {
  return (
    <React.Fragment>
      <FlexLayout mainaxis="row" flexGrow="0" paddingBottom="8" paddingTop="24">
        <InstructionBlock>
          {`${t(
            'Select how often location updates should be sent from mobile devices.'
          )} `}
          {!isiOSPlatform() && (
            <a
              data-testid="location-rules__android__more-info"
              onClick={onClickMoreInfo}
            >
              <Icon type={IconType.questionCircle} />
            </a>
          )}
        </InstructionBlock>
      </FlexLayout>
      <FlexLayout mainaxis="column" flexGrow="0" paddingTop="24">
        {renderTimeSettingsItem(
          'Android:',
          renderUpdatePeriodField(updateFrequencySeconds, disabled)
        )}
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout mainaxis="column" marginTop="32">
        <HourLimitSlider
          value={updateFrequencySeconds / 3600}
          label={t('Frequency')}
          step={5}
          dayLabel={t('Location')}
          disabled={disabled}
          onChange={onChange}
        />
      </FlexLayout>

      <FlexLayout mainaxis="column" marginTop="24">
        <InstructionBlock>
          {t(
            'If your kid has {{shortName}} {{version}} or above installed, location is reported automatically.',
            {
              version: ANDROID_VERSION,
            }
          )}
        </InstructionBlock>
      </FlexLayout>
    </React.Fragment>
  );
};

const renderTimeSettingsItem = (text, inputField) => (
  <FlexLayout
    mainaxis="row"
    flexGrow="0"
    mainaxisAlignment="space-between"
    crossaxisAlignment="center"
  >
    <Label>
      <strong>{text}</strong>
    </Label>
    {inputField}
  </FlexLayout>
);

const renderUpdatePeriodField = (value, disabled) => (
  <FlexLayout
    mainaxis="row"
    flexGrow="0"
    crossaxisAlignment="center"
    mainaxisAlignment="space-between"
  >
    <FlexLayout mainaxis="row" paddingRight="8">
      <Label>{t('Every')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="row" paddingRight="8">
      <ActionInput
        type="text"
        name="period"
        text={secondsToHourFormat(value)}
        readOnly
        disabled={disabled}
        icon={
          <Icon
            type={IconType.clock}
            color={IconColor.primary}
            size={IconSize.lg}
          />
        }
      />
    </FlexLayout>
    <Label>{t('hours')}</Label>
  </FlexLayout>
);

const renderUpdatePeriodFieldAuto = (onClick, disabled) => (
  <FlexLayout
    mainaxis="row"
    flexGrow="0"
    crossaxisAlignment="center"
    mainaxisAlignment="space-between"
  >
    <FlexLayout mainaxis="row" paddingRight="8">
      <Label>{t('Every')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="row" paddingRight="8">
      <ActionInput
        text={t('Auto')}
        onClick={onClick}
        disabled={disabled}
        icon={
          <Icon
            type={IconType.clock}
            color={IconColor.primary}
            size={IconSize.lg}
          />
        }
      />
    </FlexLayout>
    <Label>{t('hours')}</Label>
  </FlexLayout>
);

LocationRules.propTypes = {};

export default LocationRules;
