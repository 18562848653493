import { combineEpics, ofType } from 'redux-observable';
import { catchError, from, map, merge, mergeMap, of, switchMap } from 'rxjs';
import type { AppEpic } from '../../../epics/types';
import type {
  RoutineDetailsReceiveAction,
  RoutineDetailsRequestAction,
  RoutineStatusAction,
  UpdateRoutineAction,
  DeleteRoutineAction,
  FinishRoutineAction,
} from '../types';
import {
  finishRoutine,
  receiveRoutineDetails,
  setRoutinesDetailsStatus,
} from '../actions';
import { APIError } from '../../../lib/errors';
import { showErrorAlert } from '../../../helpers/errorHandling';
import { RoutineOperations } from '../../../records/routines/routine';
import { RoutinePayload } from '../../../records/routines/types/Routine.types';
import {
  deleteRoutineRecord,
  deleteRoutinesSchedulesRecord,
} from '../../records';
import { getAllSchedulesList } from '../selectors';
import { ttl10Seconds } from '../../../lib/QApiCache/commonCacheStrategies';

export const requestRoutineDetailsEpic: AppEpic<
  RoutineDetailsRequestAction,
  RoutineDetailsReceiveAction | RoutineStatusAction
> = (actions$, _state$, { api }) => {
  const request$ = actions$.pipe(ofType('ROUTINE_DETAILS_REQUEST'));

  const loadingActions = request$.pipe(
    map(() => setRoutinesDetailsStatus('read', 'loading'))
  );

  const requestActions = request$.pipe(
    ofType('ROUTINE_DETAILS_REQUEST'),
    switchMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, routineUid } }) =>
          api.routinesDetails.withCache(ttl10Seconds).get<RoutinePayload>({
            profileUid,
            routineUid,
          })
        ),
        mergeMap(response => {
          return from([
            setRoutinesDetailsStatus('read', 'success'),
            receiveRoutineDetails(RoutineOperations.fromPayload(response)),
          ]);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutinesDetailsStatus('read', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

export const updateRoutinesEpic: AppEpic<
  UpdateRoutineAction,
  RoutineDetailsReceiveAction | RoutineStatusAction
> = (actions$, _state$, { api }) => {
  const updateActions = actions$.pipe(ofType('ROUTINE_UPDATE'));

  const loadingActions = updateActions.pipe(
    map(() => setRoutinesDetailsStatus('update', 'loading'))
  );

  const requestActions = updateActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, routineUid, body } }) =>
          api.routinesDetails.patch<RoutinePayload>(
            {
              profileUid,
              routineUid,
            },
            body,
            null
          )
        ),
        mergeMap(response =>
          from([
            setRoutinesDetailsStatus('update', 'success'),
            receiveRoutineDetails(RoutineOperations.fromPayload(response)),
          ])
        ),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutinesDetailsStatus('update', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

export const deleteRoutinesEpic: AppEpic<
  DeleteRoutineAction,
  | RoutineStatusAction
  | ReturnType<typeof deleteRoutineRecord>
  | ReturnType<typeof deleteRoutinesSchedulesRecord>
  | FinishRoutineAction
> = (actions$, state$, { api }) => {
  const deleteActions = actions$.pipe(ofType('ROUTINE_DELETE'));

  const loadingActions = deleteActions.pipe(
    map(() => setRoutinesDetailsStatus('delete', 'loading'))
  );

  const requestActions = deleteActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(async ({ payload: { profileUid, routineUid } }) => {
          await api.routinesDetails.delete({
            profileUid,
            routineUid,
          });
          return { routineUid };
        }),
        mergeMap(({ routineUid }) => {
          const scheduleUids = getAllSchedulesList(state$.value)
            .filter(schedule => schedule.routineUid === routineUid)
            .toJS();

          return from([
            ...scheduleUids.map(schedule =>
              deleteRoutinesSchedulesRecord(schedule.uid)
            ),
            deleteRoutineRecord(routineUid),
            setRoutinesDetailsStatus('delete', 'success'),
            finishRoutine(),
          ]);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutinesDetailsStatus('delete', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

const routineDetailsEpics = combineEpics(
  requestRoutineDetailsEpic,
  updateRoutinesEpic,
  deleteRoutinesEpic
);

export default routineDetailsEpics;
