import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import LocationRulesMobileFlyover from '../../../components/LocationRules/LocationRulesMobileFlyover';
import { getAccountLocale } from '../../../selectors';
import State, { Dispatch, RouterParamsProps } from '../../../store/state';
import { addDevice } from '../../../actions/ProfileActions';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onAddDevice: () => {
    dispatch(addDevice(profileId));
  },
});

const LocationRulesMobileFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRulesMobileFlyover);

export type LocationRulesMobileFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default LocationRulesMobileFlyoverContainer;
