import * as React from 'react';
import { FlexLayout, Icon, IconType, StyledHeader } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { FlexLayoutMainaxisAlignment } from 'styleguide-react/build/components/Layout/types';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import type { FlyoverProps } from './Flyover';

interface FlyoverContentProps extends FlyoverProps {
  alignement?: FlexLayoutMainaxisAlignment;
  descriptionAlignement?: FlexLayoutMainaxisAlignment;
}
const FlyoverContent: React.FC<FlyoverContentProps> = ({
  onClickClose,
  description,
  canClose = true,
  title,
  illustrationSrc,
  button,
  bottomContent,
  alignement,
  descriptionAlignement,
}: FlyoverContentProps) => (
  <FlexLayout mainaxis="column" minWidth="100%" flexGrow="2">
    {canClose && (
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <FlexLayout mainaxis="row" mainaxisAlignment="flex-end" flexGrow="0">
          <Icon type={IconType.times} onClick={onClickClose} testId="close" />
        </FlexLayout>
      </RenderWhen>
    )}
    <FlexLayout
      mainaxis="column"
      crossaxisAlignment="center"
      minWidth="100%"
      mainaxisAlignment={alignement}
      flexGrow="2"
      testId="FLyover__container"
    >
      <FlexLayout
        testId="FLyover__description-container"
        mainaxis="column"
        flexGrow="2"
        mainaxisAlignment={descriptionAlignement}
      >
        <FlexLayout
          mainaxis="column"
          marginBottom="48"
          crossaxisAlignment="center"
        >
          <img src={illustrationSrc} height="140" width="120" alt="" />
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          marginBottom="16"
          crossaxisAlignment="center"
          textAlignement="center"
        >
          <StyledHeader type="h3">{title}</StyledHeader>
        </FlexLayout>
        <RenderWhen screenSize={ScreenSize.Mobile}>
          <FlexLayout
            mainaxis="column"
            crossaxisAlignment="center"
            marginBottom="48"
            textAlignement="center"
          >
            {description}
          </FlexLayout>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <FlexLayout
            mainaxis="column"
            crossaxisAlignment="center"
            marginBottom="48"
            textAlignement="center"
            width="360"
          >
            {description}
          </FlexLayout>
        </RenderWhen>
      </FlexLayout>
      <FlexLayout flexGrow="0" mainaxis="column">
        {button}
      </FlexLayout>
      {bottomContent && (
        <FlexLayout flexGrow="0" mainaxis="column" textAlignement="center">
          {bottomContent}
        </FlexLayout>
      )}
    </FlexLayout>
  </FlexLayout>
);

export default FlyoverContent;
