import { getLinewizeEventsPageId } from '../../businessLogic/events/events';
import { getProfile } from '../../selectors';
import State, { Dispatch } from '../../store/state';
import { resetLinewizeEventRecord } from '../records';
import {
  linewizeEventsRefreshAction,
  linewizeEventsRequestAction,
  linewizeEventsResetAction,
  linewizeEventChangeFilterAction,
} from './actions';
import {
  getLinewizeEventsFilter,
  getLinewizeEventsHasMore,
  getLinewizeEventsUuids,
} from './selector';
import { LinewizeEventFilter } from './types';

export const fetchLinewizeEvents =
  (profileId: string, filter: LinewizeEventFilter) =>
  (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const hasMore = getLinewizeEventsHasMore(state);
    const profileUid = getProfile(getState(), profileId)?.get('uid');

    /*
    Events are requested from the present to the past, 
    if not have more events no more requests should be made.
    */
    if (!hasMore || !profileUid) return;

    const pageId = getLinewizeEventsPageId(getLinewizeEventsUuids(state));
    dispatch(linewizeEventsRequestAction([filter], profileUid, pageId));
  };

export const fetchLinewizeEventsPaginated =
  (profileId: string) => (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const [selectedFilter] = getLinewizeEventsFilter(state).toArray();

    if (!selectedFilter) {
      throw new Error('Invalid call: filter must exist');
    }

    return dispatch(fetchLinewizeEvents(profileId, selectedFilter));
  };

export const resetLinewizeEvents = () => (dispatch: Dispatch) => {
  dispatch(resetLinewizeEventRecord());
  dispatch(linewizeEventsResetAction());
};

export const refreshLinewizeEvents = () => (dispatch: Dispatch) => {
  dispatch(resetLinewizeEventRecord());
  dispatch(linewizeEventsRefreshAction());
};

export const resetLinewizeEventsOnFilterChange =
  (filters: Array<LinewizeEventFilter>) => (dispatch: Dispatch) => {
    dispatch(linewizeEventChangeFilterAction(filters));
    dispatch(resetLinewizeEventRecord());
  };
