import { IconType } from 'styleguide-react';
import { RoutineWebCategory } from '../../../records/routines/policy/types/PolicyWebCategory.types';
import { RoutineIconType } from '../../../records/routines/types/Routine.types';
import { categoryIcons } from '../../../records/category';

export const routineIconsList: Record<
  RoutineIconType,
  typeof IconType[keyof typeof IconType]
> = {
  backpack: IconType.backpack,
  utensils: IconType.utensils,
  books: IconType.books,
  checkCircle: IconType.checkCircle,
  palette: IconType.palette,
  ban: IconType.ban,
  basketball: IconType.basketball,
  headSet: IconType.headSet,
  music: IconType.music,
  bullseye: IconType.bullseye,
  bookOpen: IconType.bookOpen,
  gamepadModern: IconType.gamepadModern,
  moon: IconType.moon,
  home: IconType.home,
  joystick: IconType.joystick,
  shield: IconType.shieldCheck,
  lock: IconType.lock,
  wifiSlash: IconType.wifiSlash,
};

export const getRoutinesIcon = (expectedIcon: RoutineIconType) => {
  return routineIconsList[expectedIcon];
};

export const routineWebCategoriesIconsList: Record<
  RoutineWebCategory,
  typeof IconType[keyof typeof IconType]
> = categoryIcons;

export const getCategoryIcon = (category: RoutineWebCategory) =>
  routineWebCategoriesIconsList[category];
