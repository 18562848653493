import { BuildPlatform, AuthorizationApplication } from '../constants';
import * as qinit from '../qinit';

export const getBuildType = () => application_version.buildType;
export const getBuildPlatform = (): BuildPlatform => global.platform;
export const getAppVersion = (): string => application_version.version;

export const getClientCredentials = (
  authorizationApplication: AuthorizationApplication
): { client_id: string; client_secret: string } => {
  switch (authorizationApplication) {
    case AuthorizationApplication.WebOrLegacyMobile:
      return {
        client_id: qinit.tenant.mobile.server.client_id,
        client_secret: qinit.tenant.mobile.server.client_secret,
      };
    case AuthorizationApplication.standaloneAndroid:
      return {
        client_id: qinit.tenant.parents_app.platforms.android.client_id,
        client_secret: qinit.tenant.parents_app.platforms.android.client_secret,
      };
    case AuthorizationApplication.standaloneIos:
      return {
        client_id: qinit.tenant.parents_app.platforms.ios.client_id,
        client_secret: qinit.tenant.parents_app.platforms.ios.client_secret,
      };
    case AuthorizationApplication.web:
      return {
        client_id: qinit.tenant.parents_app.platforms.web.client_id,
        client_secret: qinit.tenant.parents_app.platforms.web.client_secret,
      };

    default:
      return {
        client_id: qinit.tenant.mobile.server.authorization_code_application_id,
        client_secret:
          qinit.tenant.mobile.server.authorization_code_application_secret,
      };
  }
};
