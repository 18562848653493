import * as React from 'react';
import { PremiumFlyoverProps } from './types/FullPremiumFlyoverProps.types';
import { Button, ButtonSize, ButtonType, FlexLayout } from 'styleguide-react';

type FooterActionButtonsProps = {
  isMobile: boolean;
  buttons: PremiumFlyoverProps['actionButton'];
};

const FooterActionButtons = ({
  isMobile,
  buttons,
}: FooterActionButtonsProps) => {
  if (!buttons) return null;
  return (
    <FlexLayout
      mainaxis={isMobile ? 'column' : 'row'}
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      width={isMobile ? '100%' : '75%'}
    >
      {(Array.isArray(buttons) ? buttons : [buttons]).map(
        (button, _, array) => (
          <FlexLayout
            key={button.title}
            mainaxis="column"
            crossaxisAlignment="center"
            marginBottom="24"
            // In mobile, the buttons are stacked so no need for marginRight
            marginRight={!isMobile && array.length > 1 ? '24' : undefined}
            width="100%"
          >
            <Button
              testId={button.testId}
              size={ButtonSize.medium}
              onClick={button.onClick}
              block={isMobile || array.length > 1}
              className="par-full-premium-flyover__anim-slideup"
              buttonType={button.type ?? ButtonType.primary}
            >
              {button.title}
            </Button>
          </FlexLayout>
        )
      )}
    </FlexLayout>
  );
};

export default FooterActionButtons;
