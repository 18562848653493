import { commonMapInFn } from '../../api/mappings/common';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { SchoolTimes, SchoolTimesPayload } from './types/schoolTimes.type';
// import { convertDate } from '../../helpers/dates';

export const studentSchoolTimesRecordOperations = getRecordOperations<
  SchoolTimes,
  SchoolTimesPayload
>({
  defaultFields: {
    currentStartDatetime: undefined,
    currentEndDatetime: undefined,
    nextStartDatetime: undefined,
    nextEndDatetime: undefined,
  },
  mapIn: payload => {
    const commonMappedResult = commonMapInFn(payload);
    return {
      ...(commonMappedResult as { schoolTimes: SchoolTimes }).schoolTimes,
    };
  },
});
