import * as React from 'react';
import { t } from '../../lib/i18n';
import { EventRecord } from '../../records';
import {
  LicenseRecord,
  isFreeOrTrial,
  shouldShowConversionElements,
} from '../../records/license';
import { EventType } from '../../constants';
import { isPremiumBasic } from '../../records/license/helpers';
import { isEventPremiumYoutube } from './activityTimelineHelpers';
import { GlobalType, Tag } from 'styleguide-react';
import { isYoutubeApp } from '../../helpers/appRules';

const ActivityTimelineItemBadge: React.FunctionComponent<{
  event: EventRecord;
  license: LicenseRecord;
  isDelegatedWithPCDevices: boolean;
}> = ({ event, license, isDelegatedWithPCDevices }) => {
  let badge: string | null = null;
  let badgeType: GlobalType | null = GlobalType.primary;

  const showConversionElements = shouldShowConversionElements(license);

  if (!showConversionElements) return null;

  const showBadgeForFree = isFreeOrTrial(license.type);

  if (showBadgeForFree) {
    switch (event.type) {
      case EventType.Location:
        badge = event.panicMode ? t('Premium Alerts') : t('Premium Location');
        break;
      case EventType.Panic:
        badge = t('Premium Alerts');
        break;
      case EventType.Youtube:
      case EventType.CallsBlockedIncoming:
      case EventType.CallsBlockedOutgoing:
      case EventType.SmsBlockedIncoming:
      case EventType.SmsBlockedOutgoing:
      case EventType.App:
        badge = t('Premium Control');
        break;
      case EventType.CallsIncoming:
      case EventType.CallsOutgoing:
      case EventType.CallsMissed:
      case EventType.CallsUnanswered:
      case EventType.SmsIncoming:
      case EventType.SmsOutgoing:
        badge = t('Premium Monitoring');
        break;
      default:
    }
  }

  const showBadgeForPremiumBasic = isPremiumBasic(license.subtype);

  if (showBadgeForPremiumBasic) {
    switch (event.type) {
      case EventType.App:
        if (isYoutubeApp(event.appName) && !isDelegatedWithPCDevices) {
          badge = t('Premium Control');
        }
        break;
      case EventType.CallsBlockedIncoming:
      case EventType.CallsBlockedOutgoing:
      case EventType.SmsBlockedIncoming:
      case EventType.SmsBlockedOutgoing:
        badge = t('Premium Control');
        break;
      case EventType.CallsIncoming:
      case EventType.CallsOutgoing:
      case EventType.CallsMissed:
      case EventType.CallsUnanswered:
      case EventType.SmsIncoming:
      case EventType.SmsOutgoing:
        badge = t('Premium Monitoring');
        break;
      default:
    }
  }

  const showBadgeForPremiumYoutube = isEventPremiumYoutube(
    license,
    event,
    isDelegatedWithPCDevices
  );

  if (showBadgeForPremiumYoutube) {
    switch (event.type) {
      case EventType.Youtube:
      case EventType.Search:
        badge = t('Upgrade for details');
        badgeType = GlobalType.booster;

        break;
      default:
    }
  }

  return badge ? (
    <Tag
      className="ActivityTimelineItem__badge"
      text={badge}
      type={badgeType}
    />
  ) : null;
};

export default ActivityTimelineItemBadge;
