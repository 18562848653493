import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';

const IconUnsafe: React.FC<{ size?: IconSize }> = ({
  size = IconSize.regular,
}) => (
  <Icon
    type={IconType.exclamation}
    size={size}
    family={IconFamily.solid}
    color={IconColor.error}
    square
  />
);

export default IconUnsafe;
