import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import type { Schedule, SchedulePayload } from './types/Schedule.types';

export const ScheduleOperations = getRecordOperations<
  Schedule,
  SchedulePayload
>({
  defaultFields: {
    uid: null,
    routineUid: null,
    weekdays: [],
    overrides: false,
    startTime: null,
    durationMinutes: null,
    fromDate: null,
    toDate: null,
  },
});
