import * as React from 'react';
import { t } from '../../lib/i18n';
import { Switch, Label, FlexLayout, ContentSeparator } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

const DeviceHeader: React.FunctionComponent<{
  protectionEnabled?: boolean;
  onTogglePlatformEnabled?: (checked: boolean) => any;
}> = ({ protectionEnabled = false, onTogglePlatformEnabled }) =>
  onTogglePlatformEnabled ? (
    <FlexLayout mainaxis="column">
      <FlexLayout
        mainaxis="row"
        crossaxisAlignment="center"
        mainaxisAlignment="space-between"
        marginBottom="8"
      >
        <Label htmlFor="SwitchEnableQustodio">{t('Enable Protection')}</Label>
        <Switch
          checked={protectionEnabled}
          id="SwitchEnableQustodio"
          onChange={ev =>
            onTogglePlatformEnabled
              ? onTogglePlatformEnabled((ev.target as HTMLInputElement).checked)
              : undefined
          }
        />
      </FlexLayout>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <ContentSeparator />
      </RenderWhen>
    </FlexLayout>
  ) : null;

export default DeviceHeader;
