import * as React from 'react';
import ActivityStyledItem from '../../ActivityTimeline/ActivityStyledItem/ActivityStyledItem';
import {
  SchoolTimelineIcon,
  getUpperSubtitle,
} from './common/schoolEventsCommon';
import {
  IconColor,
  IconFamily,
  IconType,
  Layout,
  ListThumbnail,
  TransparentButton,
} from 'styleguide-react';
import { BaseLinewizeEvent } from '../types/LinewizeEventsBase.types';
import ActivityStyledItemAction from '../../ActivityTimeline/ActivityStyledItemAction';

const VideoEvent: React.FC<BaseLinewizeEvent> = ({
  event,
  eventActions,
  onActionClick,
}) => {
  const { title, action, dt, atSchoolHours, thumbnail } = event;
  return (
    <ActivityStyledItem
      title={title}
      icon={SchoolTimelineIcon(
        IconType.youtube,
        IconColor.error,
        'par-activity-styled-item__icon-wrapper--gray',
        IconFamily.brands
      )}
      upperSubtitle={getUpperSubtitle(action, dt)}
      isSchoolTime={atSchoolHours}
      lowerSubtitle={
        <Layout renderAs="span" maxWidth="160" paddingTop="16">
          <TransparentButton
            role="link"
            onClick={() => onActionClick(event, { type: 'WATCH_VIDEO' })}
          >
            <ListThumbnail
              thumbnailUrl={thumbnail}
              testId="school-timeline-video-thumbnail"
            />
          </TransparentButton>
        </Layout>
      }
      actionElement={
        <ActivityStyledItemAction
          actions={eventActions}
          event={event}
          title={event.title}
          onActionClick={onActionClick}
        />
      }
    />
  );
};

export default VideoEvent;
