import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutContentSpacing } from 'styleguide-react/build/components/Layout/types';
import useKeepScroll from '../hooks/useKeepScroll/useKeepScroll';
import usePullToRefresh from '../hooks/usePullToRefresh';
import type { PageTitleLayoutProps } from '../PageTitleLayout';
import { ExpandedRowProps } from './page/constants';
import { getPixels } from './page/getPixels';
import TabletCard from './page/TabletCard';
import TabletPageTitle from './page/TabletPageTitle';
import { TabletPageLayoutProps } from './types';

const TabletPageLayout: React.FunctionComponent<
  PageTitleLayoutProps & TabletPageLayoutProps
> = (props: PageTitleLayoutProps & TabletPageLayoutProps) => {
  const {
    configuration,
    mainChildren: mainChildrenProp,
    cardBannerChildren,
    beforePullRefreshChildren,
    afterPullRefreshChildren,
    rightChildren,
    beforeCardChildren,
    afterCardChildren,
  } = props;

  const divScroll = React.useRef<HTMLDivElement>(null);

  const mainChildren = usePullToRefresh(
    divScroll,
    mainChildrenProp,
    configuration?.pullToRefresh
  );

  useKeepScroll(divScroll, configuration?.keepScrollKey);

  return (
    <FlexLayout
      mainaxis="column"
      height="100%"
      className="par-page-layout--size-tablet"
      testId="TabletPageLayout_Container"
    >
      {!configuration?.hideHeader && <TabletPageTitle {...props} />}
      <FlexLayout
        ref={divScroll}
        mainaxis="column"
        scrollY={!configuration?.disableScroll}
        height="100%"
        flexGrow="2"
        className="par-page-layout__content"
        testId="TabletPageLayout_Content_Container"
        paddingTop="16"
        paddingLeft={configuration?.removePaddings ? undefined : '16'}
        paddingRight={configuration?.removePaddings ? undefined : '16'}
        paddingBottom="16"
      >
        {!configuration?.columns && cardBannerChildren && (
          <FlexLayout
            mainaxis="column"
            margin="8"
            testId="TabletPageLayout_Banner"
          >
            {cardBannerChildren}
          </FlexLayout>
        )}
        {!configuration?.columns && beforePullRefreshChildren}
        {!configuration?.columns && mainChildren}
        {!configuration?.columns && afterPullRefreshChildren}
        {configuration?.columns && (
          <FlexLayout
            mainaxis="row"
            height="100%"
            {...(configuration?.columns === 1 ? ExpandedRowProps : {})}
            testId="TabletPageLayout_ColumnsRow_Container"
            mainaxisAlignment={
              configuration?.columns === 1 ? 'center' : undefined
            }
          >
            <FlexLayout
              mainaxis="column"
              minHeight="100%"
              {...(configuration?.columns === 1 ? ExpandedRowProps : {})}
              testId="TabletPageLayout_ColumnMain_Container"
            >
              {beforeCardChildren}
              <FlexLayout
                mainaxis="column"
                width={getPixels(configuration) as LayoutContentSpacing}
                className="par-page-layout__card-container"
                testId="TabletPageLayout_ColumnMain_Card_Container"
                marginLeft="16"
                marginRight="24"
              >
                {configuration?.mainInsideCard && <TabletCard {...props} />}
                {!configuration?.mainInsideCard && cardBannerChildren && (
                  <FlexLayout
                    mainaxis="column"
                    margin="8"
                    testId="TabletPageLayout_NoCard_Banner_Container"
                  >
                    {cardBannerChildren}
                  </FlexLayout>
                )}
                {!configuration?.mainInsideCard && beforePullRefreshChildren}
                {!configuration?.mainInsideCard && mainChildren}
                {!configuration?.mainInsideCard && afterPullRefreshChildren}
              </FlexLayout>
              {afterCardChildren}
            </FlexLayout>

            {rightChildren &&
              (!configuration ||
                (configuration?.columns !== 1 && (
                  <FlexLayout
                    mainaxis="column"
                    height="100%"
                    testId="TabletPageLayout_ColumnRight_Container"
                    marginRight="24"
                  >
                    {rightChildren}
                  </FlexLayout>
                )))}
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export default TabletPageLayout;
