import { t } from '../lib/i18n';
import { showEventActions } from '../ducks/events';
import {
  getEventByKey,
  getProfileRules,
  getProfileVideoRuleSource,
  getProfileVideoRules,
} from '../selectors';
import { DetailsItemRecord, VideoDetailsRecord } from '../records/urlDetails';
import { BaseThunk } from '../store/state';
import { VideoSources, VideoActions } from '../constants';
import { VideoRulesRecord } from '../records/profileRules';
import { showToast, TOAST_ICON_TICK } from '../ducks/toast';
import { dispatchModifyVideoRules } from './ProfileRulesActions';
import { showDetailsActions } from '../ducks/urlDetails';
import { gaEvent } from '../helpers';
import {
  trackVideoSetupBlockVideo,
  VideoDetailAction,
  trackClickedReportedVideo,
} from '../helpers/analytics';
import { navigateToExternalUrl as navToExternalUrl } from './Navigation';

export const changeRules = event => dispatch => {
  dispatch(showEventActions(event));
};

export const navigateToExternalUrl =
  (eventKey: string, page: DetailsItemRecord | null) =>
  (dispatch, getState) => {
    const event = getEventByKey(getState(), eventKey);
    let url = page !== null ? page.url : `http://${event.host}`;
    if (page && page instanceof VideoDetailsRecord) {
      const video = page as VideoDetailsRecord;
      url = video.searchUrl || video.url;
      trackClickedReportedVideo(
        video.url,
        video.title,
        video.channelTitle,
        video.source
      );
    }
    gaEvent('url-details-page', 'url-path', 'click');

    dispatch(navToExternalUrl(url, event ? event.host : undefined));
  };

const createOrUpdateProfileVideoRules = (
  state,
  profileId,
  source: VideoSources,
  id: string,
  action: VideoActions
) => {
  const currSource = getProfileVideoRuleSource(source)(state, profileId);
  const currRules = getProfileRules(state, profileId);
  if (currSource) {
    return currSource.ids.has(id)
      ? currRules.setIn(['videos', 'youtube', 'ids', `${id}`], action)
      : currRules.setIn(
          ['videos', 'youtube', 'ids'],
          currSource.ids.set(id, action)
        );
  }

  const currVideoRules =
    getProfileVideoRules(state, profileId) || VideoRulesRecord();

  return currRules.setIn(
    ['videos', source],
    currVideoRules.setIn([source, 'ids'], { id: action })
  );
};

export const videoRuleSheetClick =
  (
    eventKey: string,
    profileId?: string | null,
    detail?: VideoDetailsRecord,
    action?: VideoActions
  ): BaseThunk<any> =>
  dispatch => {
    dispatch(showDetailsActions(undefined));
    if (
      !profileId ||
      !detail ||
      action === undefined ||
      action === VideoActions.Unknown
    ) {
      return;
    }
    if (action === VideoActions.Play) {
      dispatch(navigateToExternalUrl(eventKey, detail));
    } else {
      trackVideoSetupBlockVideo(
        detail.url,
        detail.title,
        detail.categoryTitle,
        action === VideoActions.Blocked
          ? VideoDetailAction.Blocked
          : VideoDetailAction.Unblocked,
        detail.source
      );
      dispatch(
        dispatchModifyVideoRules(
          profileId.toString(),
          detail.source,
          (state, profileId) =>
            createOrUpdateProfileVideoRules(
              state,
              profileId,
              detail.source,
              detail.videoId,
              action
            )
        )
      ).then(() =>
        dispatch(
          showToast(
            t('YouTube rules updated for {{title}}', {
              title: detail.title,
            }),
            TOAST_ICON_TICK
          )
        )
      );
    }
  };
