import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { Dispatch } from '../../store/state';
import AdditionalParentInformationModal from '../../components/Modal/AdditionalParentInformationModal';

const mapStateToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(goBackIfHistory());
  },
});

export default connect(null, mapStateToProps)(AdditionalParentInformationModal);

export type AdditionalParentInformationModalProps = ReturnType<
  typeof mapStateToProps
>;
