import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import { getIsProfilePaused } from '../../ducks/pause/selectors';
import SmartDeviceInfoModal from '../../components/Modal/SmartDeviceInfoModal';
import { getQueryParam } from '../../selectors/routing';
import { DeviceStatus } from '../../businessLogic/devices';
import {
  getIsPauseEnabled247,
  getSchoolStartEnd,
} from '../../selectors/studentPolicies';
import { getDevice } from '../../selectors/device';
import { t } from '../../lib/i18n';
import { getCurrentTime, getLocale, getTimezone } from '../../selectors';
import {
  convertDate,
  daysDiff,
  formatHumanTwelveClockFormat,
} from '../../helpers/dates';
import { navigateToExternalNoActivityHelp } from '../../actions/Navigation';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { LINK_ACCOUNT_SETTINGS } from '../../constants';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const status = getQueryParam(state, 'status') as DeviceStatus['status'];
  const affiliation = getQueryParam(
    state,
    'affiliation'
  ) as DeviceStatus['affiliation'];
  const deviceId = getQueryParam(state, 'id') as DeviceStatus['id'];
  const timezone = getTimezone(state);
  const locale = getLocale(state);
  const device = deviceId ? getDevice(state, deviceId) : undefined;
  const daysUntilLastSeen = device?.lastseen
    ? daysDiff(convertDate(device?.lastseen, timezone), getCurrentTime(state))
    : 0;
  const isProfilePaused = getIsProfilePaused(state, Number(profileId));
  const schoolStartAndEndDatetime = getSchoolStartEnd(state);
  const isPause247Enabled = getIsPauseEnabled247(state);
  return {
    status,
    affiliation,
    isProfilePaused,
    schoolStart: formatHumanTwelveClockFormat(
      schoolStartAndEndDatetime?.start,
      timezone,
      locale
    ),
    schoolEnd: formatHumanTwelveClockFormat(
      schoolStartAndEndDatetime?.end,
      timezone,
      locale
    ),
    deviceName: device?.name ?? t('School device'),
    daysUntilLastSeen,
    isPause247Enabled,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onNavigateToHelp: () => {
      dispatch(navigateToExternalNoActivityHelp());
    },
    onNavigateToSafeNetworkSettings: () => {
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'inner:replace',
          src: LINK_ACCOUNT_SETTINGS,
        })
      );
    },
  };
};

const SmartDeviceInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartDeviceInfoModal);

export type SmartDeviceInfoModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default SmartDeviceInfoModalContainer;
