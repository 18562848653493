import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';

export const trackSeeHowItWorksButtonClicked = () => {
  return trackButtonClicked(
    GenericPageNames.WellbeingTherapy,
    ButtonNames.SeeHowItWorks
  );
};

export const trackSeeFindOutMoreButtonClicked = () => {
  return trackButtonClicked(
    GenericPageNames.WellbeingActivities,
    ButtonNames.FindOutMore
  );
};
