import * as React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { PageTitleActionName } from 'styleguide-react/build/components/PageTitle/PageTitle';
import {
  getLayoutActionName,
  isProfileHeaderEditable,
} from '../../selectors/layout';
import State from '../../store/state';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { getProfileId } from '../../selectors/profile';
import { setProfileHeaderEditable } from '../../ducks/layout/layout';
import { getProfile } from '../../selectors/profileRaw';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

export interface LayoutConnectedProps {
  layoutActionName: PageTitleActionName;
  layoutIsProfileHeaderEditable: boolean;
  layoutHasProfile: boolean;
  layoutProfile: ProfileRecord;
  layoutSetProfileEditable: (isEditable: boolean) => void;
  layoutScreenSize: ScreenSize;
}

const ConnectedToLayout =
  <Props extends object>(
    WrappedComponent: React.ComponentType<Props & LayoutConnectedProps>
  ): React.FunctionComponent<Props & LayoutConnectedProps> =>
  ({
    layoutActionName,
    layoutIsProfileHeaderEditable,
    layoutHasProfile,
    layoutSetProfileEditable,
    layoutProfile,
    ...props
  }: Props & LayoutConnectedProps) =>
    (
      <React.Fragment>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <WrappedComponent
            {...(props as Props)}
            layoutActionName={layoutActionName}
            layoutIsProfileHeaderEditable={layoutIsProfileHeaderEditable}
            layoutHasProfile={layoutHasProfile}
            layoutSetProfileEditable={layoutSetProfileEditable}
            layoutProfile={layoutProfile}
            layoutScreenSize={ScreenSize.Desktop}
          />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Tablet}>
          <WrappedComponent
            {...(props as Props)}
            layoutActionName={layoutActionName}
            layoutIsProfileHeaderEditable={layoutIsProfileHeaderEditable}
            layoutHasProfile={layoutHasProfile}
            layoutSetProfileEditable={layoutSetProfileEditable}
            layoutProfile={layoutProfile}
            layoutScreenSize={ScreenSize.Tablet}
          />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Mobile}>
          <WrappedComponent
            {...(props as Props)}
            layoutActionName={layoutActionName}
            layoutIsProfileHeaderEditable={layoutIsProfileHeaderEditable}
            layoutHasProfile={layoutHasProfile}
            layoutSetProfileEditable={layoutSetProfileEditable}
            layoutProfile={layoutProfile}
            layoutScreenSize={ScreenSize.Mobile}
          />
        </RenderWhen>
      </React.Fragment>
    );

const mapStateToProps = <Props extends object>(state: State, props: Props) =>
  ({
    ...props,
    layoutIsProfileHeaderEditable: isProfileHeaderEditable(state),
    layoutActionName: getLayoutActionName(state),
    layoutHasProfile: !!getProfileId(state),
    layoutProfile: getProfile(state, getProfileId(state)),
  } as Props & LayoutConnectedProps);

const mapDispatchToProps = dispatch => ({
  layoutSetProfileEditable: (isEditable: boolean) => {
    dispatch(setProfileHeaderEditable(isEditable));
  },
});

const connectToLayout = <Props extends object>(
  WrappedComponent: React.ComponentType<Props & LayoutConnectedProps>
) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectedToLayout(WrappedComponent));

export default connectToLayout;
