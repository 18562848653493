import { fromJS, List } from 'immutable';

import type {
  CalendarRestrictionPauseState,
  CalendarRestrictionPauseAction,
  CalendarRestrictionPauseActionPayload,
} from './types';

export const initialState: CalendarRestrictionPauseState = fromJS({
  items: List([]),
  isFetching: false,
  isSavingSuccess: false,
  isSavingFailed: false,
} as CalendarRestrictionPauseActionPayload);

const calendarRestrictionPause = (
  state: CalendarRestrictionPauseState = initialState,
  action: CalendarRestrictionPauseAction
) => {
  switch (action.type) {
    case 'REQUEST_CALENDAR_RESTRICTION_PAUSE':
    case 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE':
      return state.merge({
        isFetching: true,
        isSavingSuccess: false,
        isSavingFailed: false,
      });
    case 'REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS':
      return state.merge({
        items: action?.payload?.items,
        isFetching: false,
        isSavingSuccess: true,
        isSavingFailed: false,
      });
    case 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS':
      return state.merge({
        items: List([]),
        isFetching: false,
        isSavingSuccess: true,
        isSavingFailed: false,
      });
    case 'REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR':
      return state.merge({
        items: List([]),
        isFetching: false,
        isSavingSuccess: false,
        isSavingFailed: true,
      });
    case 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR':
      return state.merge({
        isFetching: false,
        isSavingSuccess: false,
        isSavingFailed: true,
      });
    default:
      return state;
  }
};

export default calendarRestrictionPause;
