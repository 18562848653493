import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Icon,
  IconColor,
  IconSize,
  IconType,
  TextField,
} from 'styleguide-react';

export const formName = 'appSearch';

const SearchForm: React.FunctionComponent<{
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}> = ({ value = '', placeholder = t('Search...'), onChange }) => {
  const [input, setInput] = React.useState({ value });

  React.useEffect(() => {
    setInput({
      ...input,
      value,
    });
  }, [value]);

  const handleOnChange = (_e: any, inputValue) => {
    setInput({
      ...input,
      value: inputValue,
    });
    onChange(inputValue);
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      <TextField
        block
        size="small"
        iconLeft={
          <Icon
            type={IconType.search}
            color={IconColor.black}
            size={IconSize.xs}
          />
        }
        name="search"
        type="search"
        keepErrorLabelArea={false}
        placeholder={placeholder}
        onChange={handleOnChange}
        canBeCleared
        value={input.value}
        testId="SearchFormTextFieldSearch"
      />
    </form>
  );
};

export default SearchForm;
