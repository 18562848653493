import * as React from 'react';
import {
  AlertBox,
  Button,
  ButtonSize,
  DurationInput,
  FlexLayout,
  Icon,
  IconColor,
  IconFamily,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../../constants';
import { t } from '../../../lib/i18n';
import {
  formatHumanTwelveClockFormat,
  isTomorrow,
} from '../../../helpers/dates';
import { Moment } from 'moment-timezone';
import { pauseProfileTextDecision } from '../../../businessLogic/pauseProfile/pauseProfile';
import { modalInfoTextMatcher } from './pauseProfileModalMatcher';
import type { PauseProfileModalProps } from '../../../containers/Modal/PauseProfileModalContainer';

interface DurationTextProps {
  minutes: number;
  isProfilePaused: boolean;
  currentTime: Moment;
  timezone: string;
  locale: string;
  pausedUntilTimeMinutes: number;
  isPausedUntilTomorrow: boolean;
}

const getText = (
  isProfilePaused,
  minutes,
  pausedUntilTimeMinutes,
  isPausedUntilTomorrow,
  time
) => {
  if (isProfilePaused) {
    if (minutes === 0) {
      return t('The internet will be resumed now');
    }
    const isDifferentDuration = minutes !== pausedUntilTimeMinutes;
    if (isDifferentDuration) {
      return isPausedUntilTomorrow
        ? t('New pause: until {{time}} tomorrow', { time })
        : t('New pause: until {{time}}', { time });
    }
    return isPausedUntilTomorrow
      ? t('Current pause: until {{time}} tomorrow', { time })
      : t('Current pause: until {{time}}', { time });
  }
  if (minutes === 0) return null;
  return isPausedUntilTomorrow
    ? t('Until {{time}} tomorrow', {
        time,
      })
    : t('Until {{time}}', {
        time,
      });
};

const DurationText = ({
  minutes,
  isProfilePaused,
  currentTime,
  timezone,
  locale,
  pausedUntilTimeMinutes,
  isPausedUntilTomorrow,
}: DurationTextProps) => {
  const isWarning = isProfilePaused && minutes === 0;
  const pauseTime = currentTime.clone().add('minutes', minutes);
  const time = formatHumanTwelveClockFormat(pauseTime, timezone, locale);
  const text = getText(
    isProfilePaused,
    minutes,
    pausedUntilTimeMinutes,
    isPausedUntilTomorrow,
    time
  );

  return (
    <ModalStyledText>
      {isWarning && (
        <Layout renderAs="span" marginRight="8">
          <Icon
            type={IconType.exclamationCircle}
            family={IconFamily.solid}
            color={IconColor.warning}
          />
        </Layout>
      )}
      <strong>{text}</strong>
    </ModalStyledText>
  );
};

const shouldShowTheOverlappedText = (
  isOverlapped: boolean,
  infoText: string | null,
  isSchoolOnlyProfile: boolean
) => {
  return isOverlapped && infoText === null && !isSchoolOnlyProfile;
};

const PauseProfileModal = ({
  isProfilePaused,
  timezone,
  locale,
  currentTime,
  pausedUntilTimeMinutes,
  profile,
  hasAnyDeviceInSafeNetwork,
  devicesWithSafeNetworkActive,
  startPeriodDatetime,
  endPeriodDatetime,
  studentPause247Enabled,
  isStudentDelegatedToAnotherAccount,
  isSchoolOnlyProfile,
  onSetPauseDuration,
  onClickClose,
  isOverlapped,
}: PauseProfileModalProps) => {
  const [minutes, setValue] = React.useState(pausedUntilTimeMinutes ?? 0);
  const pauseTime = currentTime.clone().add('minutes', minutes);
  const isPausedUntilTomorrow = isTomorrow(pauseTime, currentTime);

  const getTitle = (isProfilePaused: boolean): string =>
    isProfilePaused ? t('Pause the internet') : t('Pause the internet for...');

  const infoTextState = pauseProfileTextDecision({
    profile,
    studentPause247Enabled: Boolean(studentPause247Enabled),
    schoolStartDatetime: startPeriodDatetime,
    schoolEndDatetime: endPeriodDatetime,
    deviceInSafeNetwork: hasAnyDeviceInSafeNetwork,
    devicesWithSafeNetworkActive,
    pauseStart: currentTime,
    pauseEnd: pauseTime,
    isStudentDelegatedToAnotherAccount,
  });
  const infoText = modalInfoTextMatcher(infoTextState, {
    startPeriodDatetime,
    endPeriodDatetime,
    timezone,
    locale,
    devicesWithSafeNetworkActive,
  });

  return (
    <Modal
      title={getTitle(isProfilePaused)}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={() => {
            onSetPauseDuration(minutes);
          }}
          size={ButtonSize.medium}
          disabled={!isProfilePaused && !minutes}
          block
        >
          {t('Confirm')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="pause-internet-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        {isProfilePaused && (
          <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
            <ModalStyledText marginBottom="8">
              {t('Edit the remaining time below:')}
            </ModalStyledText>
          </FlexLayout>
        )}

        <Layout marginTop="24" marginBottom="16">
          <DurationInput
            value={minutes}
            onChange={(minutes: number) => setValue(minutes)}
            maxMinutes={24 * 60}
          />
        </Layout>

        <DurationText
          minutes={minutes}
          isProfilePaused={isProfilePaused}
          locale={locale}
          timezone={timezone}
          currentTime={currentTime}
          isPausedUntilTomorrow={isPausedUntilTomorrow}
          pausedUntilTimeMinutes={pausedUntilTimeMinutes}
        />

        {shouldShowTheOverlappedText(
          isOverlapped(minutes),
          infoText,
          isSchoolOnlyProfile
        ) && (
          <FlexLayout mainaxis="row" maxWidth="240" alignSelf="center">
            <Layout renderAs="span">
              <Icon
                type={IconType.infoCircle}
                family={IconFamily.solid}
                color={IconColor.warning}
              />
            </Layout>
            <ModalStyledText textAlign="center">
              {t('This pause will overrule your scheduled time limit rules')}
            </ModalStyledText>
          </FlexLayout>
        )}
        {infoText !== null && (
          <AlertBox type="info" centered>
            {infoText}
          </AlertBox>
        )}
      </FlexLayout>
    </Modal>
  );
};

export default PauseProfileModal;
