import { EMPTY, of } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';

import { captureException } from '../../helpers/sentry';
import { AppAction, AppEpic } from '../../epics/types';

import {
  RequestProductAddonsOfferAction,
  ReceiveProductAddonsOfferAction,
} from './types/actions';
import { ProductAddonsOfferOperations } from '../../records/productAddonsOffer/productAddonsOffer';
import { ProductAddonsOfferResponsePayload } from '../../records/productAddonsOffer/types';
import { receiveProductAddonsOfferAction } from './actions';

export const loadProductAddonsOffer: AppEpic<
  AppAction,
  ReceiveProductAddonsOfferAction
> = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType('REQUEST_PRODUCT_ADDONS_OFFER'),
    switchMap(action =>
      of(action).pipe(
        switchMap(async () => {
          const {
            payload: { addons },
          } = action as RequestProductAddonsOfferAction;

          const { product_list: productList } =
            await api.productAddonsOffer.get<ProductAddonsOfferResponsePayload>(
              {
                addons,
              }
            );

          const productAddons = productList.map(addon =>
            ProductAddonsOfferOperations.fromPayload(addon.product)
          );

          return receiveProductAddonsOfferAction(productAddons);
        }),
        catchError((e: Error) => {
          captureException(e);
          return EMPTY;
        })
      )
    )
  );
};

export const productAddonsOfferEpics = combineEpics(loadProductAddonsOffer);
