export const getAdjustConfigConstants = (isProductionEnvironment: boolean) => ({
  Environment: isProductionEnvironment
    ? global.AdjustConfig.EnvironmentProduction
    : global.AdjustConfig.EnvironmentSandbox,
  LogLevel: isProductionEnvironment
    ? global.AdjustConfig.LogLevelSuppress
    : global.AdjustConfig.LogLevelVerbose,
});

export const createAdjust = config => global.Adjust.create(config);

export const getAdjustConfig = (token, environment) =>
  new global.AdjustConfig(token, environment);

export const trackEvent = (eventId, accountUid) => {
  const adjustEvent = new global.AdjustEvent(eventId);
  adjustEvent.addCallbackParameter('account_uid', accountUid);
  global.Adjust.trackEvent(adjustEvent);
};

enum TrackingAuthorizationStatus {
  // We can request the user consent for tracking.
  NotDetermined = 0,
  // We cannot request user consent for tracking.
  Restricted = 1,
  // User denied the consent for tracking.
  // We cannot request it again, user must go to Settings -> Privacy and enable it manually.
  Denied = 2,
  // User accepted the consent for tracking.
  Authorized = 3,
}

export const requestTrackingConsentForIosDevices = (): Promise<boolean> =>
  new Promise(resolve => {
    global.Adjust.requestTrackingAuthorizationWithCompletionHandler(
      (status: number) => {
        const userHasGivenConsentToTrack =
          status === TrackingAuthorizationStatus.Authorized;
        resolve(userHasGivenConsentToTrack);
      }
    );
  });

const getTrackingConsentStatusForIosDevices =
  (): Promise<TrackingAuthorizationStatus> =>
    new Promise(resolve => {
      global.Adjust.getAppTrackingAuthorizationStatus(
        (status: TrackingAuthorizationStatus) => {
          resolve(status);
        }
      );
    });

export const canRequestTrackingConsentForIosDevices = async () => {
  const trackingAuthorizationStatus =
    await getTrackingConsentStatusForIosDevices();

  return (
    trackingAuthorizationStatus === TrackingAuthorizationStatus.NotDetermined
  );
};
