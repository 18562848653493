import { connect } from 'react-redux';
import {
  navigateToAddPlace,
  navigateToEditPlace,
} from '../../actions/FamilyLocatorActions';
import {
  isFetchingPlaces,
  getLocationPathname,
  getPlaces,
} from '../../selectors/index';
import Places from '../../components/FamilyLocator/Places';
import flags from '../../sideEffects/flags';
import { fetchPlacesIfNeeded } from '../../ducks/places';
import { trackablePage } from '../../helpers/hocs';
import { PageNames } from '../../helpers/analytics';
import State, { Dispatch } from '../../store/state';
import { APPThunk } from '../../helpers/thunks';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = (state: State) => ({
  places: getPlaces(state),
  isFetching: isFetchingPlaces(state),
  locationPathname: getLocationPathname(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addPlaceClick: (placesSize: number, locationPathname: string) =>
    dispatch(navigateToAddPlace(placesSize, locationPathname)),
  editPlaceClick: (placeUid: string) => dispatch(navigateToEditPlace(placeUid)),
});

const PlacesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(Places, PageNames.FamilyLocatorPlacesList));

PlacesContainer.load = (): APPThunk => dispatch => {
  const navigate = getMultiPlatformNavigation();
  if (!flags.geofencing.isEnabled()) {
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  }
  return dispatch(fetchPlacesIfNeeded());
};

export type PlacesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default PlacesContainer;
