import * as R from 'ramda';
import { t } from '../../lib/i18n';
import { showToast, TOAST_ICON_WARNING } from '../../ducks/toast';
import { APIError } from '../../lib/errors';

const ERROR_API_BAD_OLD_PASSWORD = 'old password does not match';
const ERROR_API_INVALID_CREDENTIALS = 'Invalid credentials given.';
const ERROR_API_BAD_PASSWORD = 'password does not match';
const ERROR_API_EMAIL_EXISTS = 'Account email already exists.';

/**
 * Helper to extract API error message and then dispatch toast
 */
export const showErrorToast = (error: Error) => {
  // eslint-disable-line import/prefer-default-export
  return dispatch => {
    return (
      error instanceof APIError ? parseApiError(error) : Promise.reject(error)
    )
      .catch(() => {
        return [
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING,
        ];
      })
      .then(([texts, icon]) => dispatch(showToast(texts, icon)));
  };
};

const parseApiError = error => {
  return error.json().then(result => {
    if (R.head(result.old_password || []) === ERROR_API_BAD_OLD_PASSWORD) {
      return [
        [t('Invalid current password.'), t('Please try again.')],
        TOAST_ICON_WARNING,
      ];
    }
    if (
      R.head(result.password || []) === ERROR_API_BAD_PASSWORD ||
      result.error_description === ERROR_API_INVALID_CREDENTIALS
    ) {
      return [
        [t('Invalid password.'), t('Please try again.')],
        TOAST_ICON_WARNING,
      ];
    }
    if (result.error === ERROR_API_EMAIL_EXISTS) {
      return [[t('This email address is already in use.')], TOAST_ICON_WARNING];
    }
    return Promise.reject(error);
  });
};
