import { List } from 'immutable';
import { RoutineRecord } from '../../../records/routines/types/Routine.types';
import {
  AppRuleExceptions,
  MultiStepDataContext,
  TimeLimitType,
} from '../../../components/Routines/routines.types';
import { AppRuleRecord, makeAppKey } from '../../../records/profileRules';
import { replaceS3Url } from '../../../helpers';
import { calculateTimeSlotFrom, calculateTimeSlotTo } from './transformations';
import { ScheduleRecord } from '../../../records/routines/schedule/types/Schedule.types';

const countsTowardsDailyTimeQuotaToTimeLimitType = (
  data: boolean
): TimeLimitType =>
  data ? 'APPLY_DAILY_TIME_LIMITS' : 'DONT_APPLY_DAILY_TIME_LIMIT';

const addApplicationsThumbnail = (
  appsExceptions: AppRuleExceptions,
  appList: List<AppRuleRecord>
) => {
  return appsExceptions.map(appException => {
    const app = appList.find(
      app => makeAppKey(app) === makeAppKey(appException)
    );

    return {
      ...appException,
      src: replaceS3Url(app?.thumbnail),
    };
  });
};

export const routineRecordToContext = (
  routine: RoutineRecord,
  schedules: List<ScheduleRecord>,
  appRulesList: List<AppRuleRecord>
): MultiStepDataContext => {
  return {
    uid: routine.uid,
    paused: routine.paused,
    finished: false,
    type: routine.type,
    mode: 'EDIT',
    block: routine.policy.blockType,
    contentFilter: {
      activeTab: 'APP',
      timeLimitType: countsTowardsDailyTimeQuotaToTimeLimitType(
        routine.policy.countsTowardsDailyTimeQuota
      ),
      appRuleType: routine.policy.appRules.defaultAction,
      webRuleType: routine.policy.webRules.defaultAction,
      appsException: addApplicationsThumbnail(
        routine.policy.appRules.appRulesList.toJS(),
        appRulesList
      ),
      websException: routine.policy.webRules.webRulesList.toJS(),
    },
    style: {
      name: routine.name,
      description: routine.description,
      color: routine.color,
      icon: routine.icon,
    },
    timeSlot: schedules.toArray().map(scheduleRecord => ({
      uid: scheduleRecord.uid,
      from: calculateTimeSlotFrom(scheduleRecord),
      to: calculateTimeSlotTo(scheduleRecord),
      days: scheduleRecord.weekdays,
    })),
  };
};
