import * as React from 'react';
import { t } from '../../lib/i18n';
import { EventRecord, isActionBlock } from '../../records/event';
import { LicenseRecord } from '../../records/license';
import { EventType } from '../../constants';
import { isFreeOrPremiumBasic } from '../../records/license/helpers';
import { isSafetyNet } from '../../records/category';
import { isEventPremiumYoutube } from './activityTimelineHelpers';

interface ActivityTimelineItemSublabelProps {
  event: EventRecord;
  deviceName: string;
  deviceCount: number;
  license: LicenseRecord;
  isDelegatedWithPCDevices: boolean;
}

const ActivityTimelineItemSublabel: React.FunctionComponent<
  ActivityTimelineItemSublabelProps
> = ({
  event,
  deviceName = '',
  deviceCount,
  license,
  isDelegatedWithPCDevices,
}: ActivityTimelineItemSublabelProps) => {
  const getSublabel = () => {
    let sublabel: string[] = [];
    const hasOtherDevices = deviceCount > 1;

    switch (event.type) {
      case EventType.Youtube:
        if (!isEventPremiumYoutube(license, event, isDelegatedWithPCDevices)) {
          sublabel = [getVideoSublabel(event)];
        }
        break;
      case EventType.Web:
        sublabel = [getWebSublabel(event)];
        break;
      case EventType.CallsIncoming:
      case EventType.CallsOutgoing:
      case EventType.CallsMissed:
      case EventType.CallsUnanswered:
      case EventType.CallsBlockedIncoming:
      case EventType.CallsBlockedOutgoing:
      case EventType.SmsIncoming:
      case EventType.SmsOutgoing:
      case EventType.SmsBlockedIncoming:
      case EventType.SmsBlockedOutgoing:
        return !isFreeOrPremiumBasic(license.subtype) &&
          event.name &&
          event.name !== event.socialContactId
          ? event.socialContactId
          : '';
      default:
    }

    if (hasOtherDevices && deviceName && !isSafetyNet(event.categories)) {
      sublabel =
        sublabel.length === 0
          ? [deviceName]
          : sublabel.concat([t('on {{deviceName}}', { deviceName })]);
    }
    return sublabel.join(' ');
  };

  const sublabel = getSublabel();

  return (
    <div className="ActivityTimelineItemSublabel">
      {sublabel}
      {hasDetails(event, license, isDelegatedWithPCDevices) && (
        <span>
          {sublabel && <React.Fragment> - </React.Fragment>}
          <a className="ActivityTimelineItemSublabel__link">
            {t('View detail')}
          </a>
        </span>
      )}
    </div>
  );
};

const eventsWithDetails: Set<EventType> = new Set([
  EventType.Web,
  EventType.Youtube,
  EventType.SafeNetworkDisablingProtection,
  EventType.SafeNetworkEnablingProtection,
]);

const hasDetails = (
  event: EventRecord,
  license: LicenseRecord,
  isDelegatedWithPCDevices: boolean
) =>
  eventsWithDetails.has(event.type) &&
  !isSafetyNet(event.categories) &&
  !isEventPremiumYoutube(license, event, isDelegatedWithPCDevices);

const getVideoSublabel = (event: EventRecord) =>
  isActionBlock(event.action)
    ? getWebSublabelBlocked(event)
    : getVideoSublabelVisited(event);

const getWebSublabel = (event: EventRecord) =>
  isActionBlock(event.action)
    ? getWebSublabelBlocked(event)
    : getWebSublabelVisited(event);

const getWebSublabelBlocked = (event: EventRecord) => {
  if (isSafetyNet(event.categories)) {
    return t('School device');
  }
  return event.blockedPages === 1
    ? t('1 attempt')
    : t('{{blockedPages}} attempts', { blockedPages: event.blockedPages });
};

const getWebSublabelVisited = (event: EventRecord) =>
  event.pages === 1
    ? t('1 page visited')
    : t('{{pages}} pages visited', { pages: event.pages });

const getVideoSublabelVisited = (event: EventRecord) =>
  event.pages === 1
    ? t('1 visit')
    : t('{{pages}} visits', { pages: event.pages });

export default ActivityTimelineItemSublabel;
