import * as parser from 'ua-parser-js';
import { Map } from 'immutable';
import {
  PLATFORM_MAC,
  PLATFORM_WINDOWS,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_UNKNOWN,
  BuildPlatform,
  Platform,
  DevicePlatform,
} from '../constants';
import { parseUserAgent, getUserAgent } from '../lib/wrappers';
import * as qinit from '../qinit';

export enum OS {
  Unknown,
  Android,
  IOS,
}

export enum Browser {
  Chrome,
  Other,
}

export const userAgentParser = new parser(window.navigator.userAgent);

export const mapUserAgentOsToPlatform = (): Platform => {
  const userAgentOsPlatformMap = Map<string, Platform>([
    ['Mac OS', PLATFORM_MAC],
    ['Windows', PLATFORM_WINDOWS],
    ['Android', PLATFORM_ANDROID],
    ['iOS', PLATFORM_IOS],
  ]);

  const userAgentParser = new parser(window.navigator.userAgent);
  const os = userAgentParser.getOS();
  const name = os ? os.name : null;

  return name && userAgentOsPlatformMap.has(name)
    ? userAgentOsPlatformMap.get(name)!
    : PLATFORM_UNKNOWN;
};

export const mapPlatformToDownloadLink = (platform: Platform): string => {
  return Map([
    [PLATFORM_ANDROID, qinit.application.downloads.android],
    [PLATFORM_IOS, qinit.application.downloads.ios],
    [PLATFORM_MAC, qinit.application.downloads.mac],
    [PLATFORM_WINDOWS, qinit.application.downloads.windows],
    [PLATFORM_UNKNOWN, null],
  ]).get(platform) as string;
};

export const mapUserAgentOsToDevicePlatform = (): DevicePlatform => {
  const userAgentOsDevicePlatformMap = Map<string, DevicePlatform>([
    ['Mac OS', DevicePlatform.Mac],
    ['Windows', DevicePlatform.Windows],
    ['Android', DevicePlatform.Android],
    ['iOS', DevicePlatform.Ios],
  ]);

  const userAgentParser = parseUserAgent(getUserAgent());

  // Fast return for Kindle devices.
  // While we don't consider *Fire OS* as Platform, we do have Kindle as a DevicePlatform
  if (userAgentParser.getDevice().vendor === 'Amazon') {
    return DevicePlatform.Kindle;
  }

  const os = userAgentParser.getOS();
  const name = os ? os.name : null;

  return name && userAgentOsDevicePlatformMap.has(name)
    ? userAgentOsDevicePlatformMap.get(name)!
    : DevicePlatform.None;
};

export const mapDevicePlatformToDevicePlatformName = (
  devicePlatform: DevicePlatform
): string => {
  const devicePlatformToDevicePlatformName = Map<DevicePlatform, string>([
    [DevicePlatform.Mac, 'Mac'],
    [DevicePlatform.Windows, 'Windows'],
    [DevicePlatform.Android, 'Android'],
    [DevicePlatform.Kindle, 'Kindle'],
    [DevicePlatform.Ios, 'iOS'],
    [DevicePlatform.None, 'Device'],
  ]);

  return devicePlatformToDevicePlatformName.get(devicePlatform);
};

export const getOS = (): OS => {
  const userAgentParser = new parser(window.navigator.userAgent);
  const os = userAgentParser.getOS();
  if (
    window.cordova.platformId === BuildPlatform.ios ||
    (os && os.name && os.name.toLowerCase() === BuildPlatform.ios)
  ) {
    return OS.IOS;
  }
  if (
    window.cordova.platformId === BuildPlatform.android ||
    (os && os.name && os.name.toLowerCase() === BuildPlatform.android)
  ) {
    return OS.Android;
  }
  return OS.Unknown;
};

export const getBrowser = (): Browser => {
  const userAgentParser = new parser(window.navigator.userAgent);
  return userAgentParser.getBrowser().name === 'Chrome'
    ? Browser.Chrome
    : Browser.Other;
};

export const isIosOrAndroid = () => {
  const os = getOS();
  return os === OS.IOS || os === OS.Android;
};
