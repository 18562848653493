import * as React from 'react';
import { Button, Modal, Icon, IconType } from 'styleguide-react';
import { t } from '../../lib/i18n';

const IosPreConsentTrackingModal: React.FunctionComponent<{
  onClickAllow: () => void;
}> = ({ onClickAllow }) => (
  <Modal
    onClickClose={() => {}}
    header={{
      icon: <Icon type={IconType.mobile} />,
    }}
    title={t('Allow {{shortName}} to improve your experience')}
    buttons={[
      <Button key="button" onClick={onClickAllow} block>
        {t('Next')}
      </Button>,
    ]}
    size="small"
    animationEnabled
    hideCloseButton
  >
    {t(
      'Help us deliver a more personalized experience by selecting {1}"Allow Tracking"{/1} on next screen.',
      {
        1: str => <strong>{str}</strong>,
      }
    )}
  </Modal>
);

export default IosPreConsentTrackingModal;
