import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import OnboardingStepBar from './OnboardingStepBar';
import OnboardingSupportContainer from '../../../containers/Onboarding/OnboardingSupportContainer';
import PageLayout, {
  PageLayoutConfigurationProps,
} from '../../Layout/PageLayout';
import { PageTitleActionElementProps } from '../../Layout/PageTitleLayout';
import PageSectionLayout from '../../Layout/PageSectionLayout';

const OnboardingWrapper =
  ({
    activeStep,
    inProgress,
    showSupport = false,
    configuration,
    actions,
  }: {
    activeStep?: number;
    inProgress?: boolean;
    showSupport?: boolean;
    configuration?: PageLayoutConfigurationProps[];
    actions?: PageTitleActionElementProps[];
  }) =>
  WrappedComponent => {
    const componentWithOnboardingWrapper = wrappedComponentProps => (
      <PageLayout configuration={configuration} actions={actions}>
        <PageSectionLayout placeholder="before-card">
          {activeStep && (
            <FlexLayout mainaxis="column" backgroundColor="transparent">
              <OnboardingStepBar
                activeStep={activeStep}
                inProgress={inProgress}
              />
            </FlexLayout>
          )}
        </PageSectionLayout>
        <WrappedComponent {...wrappedComponentProps} />
        <PageSectionLayout placeholder="after-card">
          {showSupport && (
            <FlexLayout mainaxis="column" backgroundColor="transparent">
              <OnboardingSupportContainer />
            </FlexLayout>
          )}
        </PageSectionLayout>
      </PageLayout>
    );
    componentWithOnboardingWrapper.load = WrappedComponent.load;
    return componentWithOnboardingWrapper;
  };

export default OnboardingWrapper;
