import * as React from 'react';
import {
  BrandsHeader,
  FlexLayout,
  Icon as CustomIcon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  Typography,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import Icon from '../Icons/Icon';
import * as IconIosPath from '../../assets/base/images/icons/icon-ios.svg';
import * as IconKindlePath from '../../assets/base/images/icons/icon-kindle.svg';
import { isiOSPlatform } from '../../helpers';

const UpgradeHero = () => (
  <div className="UpgradeHero">
    <Typography
      type="headline1"
      weight="semibold"
      align="center"
      marginBottom="16"
    >
      {t('Choose the right plan for your family')}
    </Typography>
    {/* Asked for a change in this copy, instead Qustodio we should use {{shortname}} */}
    <Typography type="title2" align="center" renderAs="h2" marginBottom="16">
      {t('Qustodio Premium is here for every stage of your digital journey')}
    </Typography>
    {!isiOSPlatform() ? (
      <React.Fragment>
        <BrandsHeader testId="UpgradeHero__BrandsHeader">
          <FlexLayout mainaxis="row" mainaxisAlignment="center">
            <CustomIcon
              family={IconFamily.brands}
              type={IconType.windows}
              color={IconColor.greenDecorationContrast}
              size={IconSize.x2}
            />
            <CustomIcon
              family={IconFamily.brands}
              type={IconType.apple}
              color={IconColor.greenDecorationContrast}
              size={IconSize.x2}
            />
            <CustomIcon
              family={IconFamily.brands}
              type={IconType.chrome}
              color={IconColor.greenDecorationContrast}
              size={IconSize.x2}
            />
            <CustomIcon
              family={IconFamily.brands}
              type={IconType.android}
              color={IconColor.greenDecorationContrast}
              size={IconSize.x2}
            />
            <Icon path={IconIosPath} />
            <Icon path={IconKindlePath} />
          </FlexLayout>
        </BrandsHeader>
      </React.Fragment>
    ) : null}
  </div>
);

export default UpgradeHero;
