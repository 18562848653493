import * as Moment from 'moment-timezone';
import { createSelector } from 'reselect';

import { getPersistentStorageItem } from './helpers';
import State from '../store/state';
import {
  parseISODateString,
  dayCountDiff,
  ISODateStringFromMoment,
} from '../helpers/dates';

export const getParentDevice = (state: State) =>
  state.get('parentDevice').get('item');

export const getParentDeviceVersion = (state: State) =>
  state.get('parentDevice').get('version');

export const getParentDeviceId = (state: State) =>
  parseInt(getPersistentStorageItem(state, 'parentDeviceId'), 10);

export const getParentDeviceIds = (state: State) =>
  state.get('parentDevices').get('result').toArray();

export const isFetchingParentDevices = (state: State) =>
  state.get('parentDevices').get('isFetching');

export const getParentDevices = (state: State) =>
  state.get('records').get('parentDevices').toObject();

export const getAllParentDevices = createSelector(
  getParentDeviceIds,
  getParentDevices,
  (parentDeviceIds, parentDevices) =>
    parentDeviceIds.map(id => parentDevices[id].toJS())
);

export const getParentDeviceInactivityDays = createSelector(
  getAllParentDevices,
  parentDevices =>
    parentDevices
      .map(({ lastseen }) => parseISODateString(lastseen))
      .map(
        dayCountDiff(
          parseISODateString(ISODateStringFromMoment(Moment(Moment.now())))
        )
      )
);

/**
 * Returns the expected number of days or Infinity in case there is no activity yet.
 */
export const getParentDeviceMinInactiveDays = createSelector(
  getParentDeviceInactivityDays,
  inactivityDays =>
    inactivityDays.length === 0 ? Infinity : Math.min(...inactivityDays)
);
