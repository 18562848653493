/* tslint:disable:max-line-length */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Router,
  Route,
  IndexRoute,
  Redirect,
  RouterProps,
  ChangeHook,
  EnterHook,
} from 'react-router';
import { history as defaultHistory } from './store';
import ProfileListContainer from './containers/ProfileListContainer';
import ProfileNewContainer from './containers/ProfileNewContainer';
import {
  TopActivitySummaryPageContainer,
  BlockedWebsActivitySummaryPageContainer,
  CallsAndSmsActivitySummaryPageContainer,
  SearchActivitySummaryPageContainer,
  ScreenTimeActivitySummaryPageContainer,
} from './containers/ActivitySummary/ActivitySummaryPageContainer';
import SummaryPremiumFlyoverContainer from './containers/SummaryPremiumFlyoverContainer';
import ProfileAddDeviceInstructionsContainer from './containers/ProfileAddDeviceInstructionsContainer';
import ProfileRulesContainer from './containers/ProfileRules/ProfileRulesContainer';
import ProfileAddContainer from './containers/ProfileAddContainer';
import ProfileEditContainer from './containers/ProfileEditContainer';
import Main from './components/Main';
import UpgradeContainer from './containers/UpgradeContainer';
import AccountSettingsContainer from './containers/AccountSettings/AccountSettingsContainer';
import EditNameContainer from './containers/AccountSettings/EditNameContainer';
import EditEmailContainer from './containers/AccountSettings/EditEmailContainer';
import EditPasswordContainer from './containers/AccountSettings/EditPasswordContainer';
import EditLanguageContainer from './containers/AccountSettings/EditLanguageContainer';
import EditTimezoneContainer from './containers/AccountSettings/EditTimezoneContainer';
import EditCountryContainer from './containers/AccountSettings/EditCountryContainer';
import DailyTimeLimitContainer from './containers/ProfileRules/TimeLimits/DailyTimeLimitContainer';
import RestrictedTimesContainer from './containers/ProfileRules/TimeLimits/RestrictedTimesContainer';
import TimeSettingsPageContainer from './containers/ProfileRules/TimeLimits/TimeSettingsAndNotificationsPageContainer';
import WebFiltersContainer from './containers/ProfileRules/WebFilters/WebFiltersContainer';
import AddDomainExceptionContainer from './containers/ProfileRules/WebFilters/AddDomainExceptionContainer';
import AppRulesContainer from './containers/ProfileRules/AppRules/AppRulesContainer';
import AppRulesPremiumFlyoverContainer from './containers/ProfileRules/AppRules/AppRulesPremiumFlyoverContainer';
import LocationRulesContainer from './containers/ProfileRules/LocationRules/LocationRulesContainer';
import LocationRulesPremiumFlyoverContainer from './containers/ProfileRules/LocationRules/LocationRulesPremiumFlyoverContainer';
import LocationRulesMobileFlyoverContainer from './containers/ProfileRules/LocationRules/LocationRulesMobileFlyoverContainer';
import CallsSMSRulesContainer from './containers/ProfileRules/CallsSMSRules/CallsSMSRulesContainer';
import CallsSMSRulesAddContactContainer from './containers/ProfileRules/CallsSMSRules/CallsSMSRulesAddContactContainer';
import CallsSMSRulesPremiumFlyoverContainer from './containers/ProfileRules/CallsSMSRules/CallsSMSRulesPremiumFlyoverContainer';
import CallsSMSRulesConnectDeviceFlyoverContainer from './containers/ProfileRules/CallsSMSRules/CallsSMSRulesConnectDeviceFlyoverContainer';
import PanicButtonRulesContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonRulesContainer';
import PanicButtonRulesPremiumFlyoverContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonRulesPremiumFlyoverContainer';
import PanicButtonRulesAndroidFlyoverContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonRulesAndroidFlyoverContainer';
import PanicButtonRulesInviteContactContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonRulesInviteContactContainer';
import SocialMonitoringRulesContainer from './containers/ProfileRules/SocialMonitoringRules/SocialMonitoringRulesContainer';
import SocialMonitoringRulesPremiumFlyoverContainer from './containers/ProfileRules/SocialMonitoringRules/SocialMonitoringRulesPremiumFlyoverContainer';
import LoginContainer from './containers/LoginContainer';
import InitialContainer from './containers/InitialContainer';
import DebugContainer from './containers/Debug/DebugContainer';
import DeviceListContainer from './containers/Devices/DeviceListContainer';
import AddDeviceInstructionsContainer from './containers/Devices/AddDeviceInstructionsContainer';
import DeviceContainer from './containers/Devices/DeviceContainer';
import DeviceEditContainer from './containers/DeviceEditContainer';
import FamilyLocatorContainer from './containers/FamilyLocator/FamilyLocatorContainer';
import UrlDetailsContainer from './containers/UrlDetailsContainer';
import YoutubeRulesContainer from './containers/YoutubeRules/YoutubeRulesContainer';
import YoutubeFlyoverContainer from './containers/YoutubeRules/YoutubeFlyoverContainer';
import FamilyLocatorFlyoverContainer from './containers/FamilyLocator/FamilyLocatorFlyoverContainer';
import YoutubeActivityContainer from './containers/Summary/YoutubeActivityContainer';
import PlacesContainer from './containers/FamilyLocator/PlacesContainer';
import ResolveAddressContainer from './containers/FamilyLocator/ResolveAddressContainer';
import MapWrapperContainer from './containers/FamilyLocator/MapWrapperContainer';
import KidLocationsHistoryContainer from './containers/FamilyLocator/KidLocationsHistoryContainer';
import ActivityTimelinePageContainer from './containers/ActivityTimeline/ActivityTimelinePageContainer';
import InsightsContainer from './containers/Insights/InsightsContainer';
import TwoYearsPromotionModalContainer from './containers/Modal/TwoYearsPromotionModalContainer';
import ReTrialModalContainer from './containers/Modal/ReTrialModalContainer';
import ActivateRetrialContainer from './containers/ActivateRetrial/ActivateRetrialContainer';
import {
  loadRoot,
  redirect,
  loadRouteData,
  onEnterCallAction,
} from './helpers/routes';

// onboarding
import OnboardingWelcomeContainer from './containers/Onboarding/OnboardingWelcomeContainer';
import OnboardingAddChildContainer from './containers/Onboarding/OnboardingAddChildContainer';
import OnboardingDownloadContainer from './containers/Onboarding/OnboardingDownloadContainer';
import OnboardingFinishContainer from './containers/Onboarding/OnboardingFinishContainer';
import { onEnterOnboardingPendingDeviceConnection } from './actions/onboarding/OnboardingPendingDeviceConnectionActions';
import { onEnterOnboardingFinish } from './actions/onboarding/OnboardingFinishActions';
import MainOnboarding from './components/MainOnboarding';
import ParentOrKidAppChoiceContainer from './containers/PreOnboarding/ParentOrKidAppChoiceContainer';
import WrongAppInstalledContainer from './containers/PreOnboarding/WrongAppInstalledContainer';
import {
  ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION,
  ROUTE_PARENTS_APP_STANDALONE_WRONG_APP,
} from './constants';
import LogoutContainer from './containers/LogoutContainer';
import AppRulesSettingsContainer from './containers/ProfileRules/AppRules/AppRulesSettings';
import WebFiltersSettingsContainer from './containers/ProfileRules/WebFilters/WebFiltersSettingsContainer';
import { isSSOEnabled } from './helpers/sso';
import LogoutModalContainer from './containers/Modal/LogoutModalContainer';
import LinkStudentsContainer from './containers/Schools/LinkStudentsContainer';
import CompleteStudentContainer from './containers/Schools/CompleteStudentContainer';
import SchoolsOnboardingErrorContainer from './containers/Schools/SchoolsOnboardingErrorContainer';
import StudentSummaryContainer from './containers/Schools/StudentSummaryContainer';
import SchoolsOnboardingErrorWrongInfoContainer from './containers/Schools/SchoolsOnboardingErrorWrongInfoContainer';
import SignatureDetailsContainer from './containers/Schools/signature/SignatureDetailsContainer';
import ProtectProfilesListContainer from './containers/Schools/ProtectProfilesListContainer';
import AllSignaturesContainer from './containers/Schools/signature/AllSignaturesContainer';
import StudentSeeAllCategoriesContainer from './containers/Schools/StudentSeeAllCategoriesContainer';
import LockScreenContainer from './containers/LockScreenContainer';
import ProfileDashboardContainer from './containers/Dashboard/ProfileDashboardContainer';
import AppDailyTimeLimitContainer from './containers/AppDailyTimeLimit/AppDailyTimeLimitContainer';
import RoutinesContainer from './containers/Routines/RoutinesContainer';
import SchoolTimelinePageContainer from './containers/Schools/SchoolTimeline/SchoolTimelinePageContainer';
import ThankYouContainer from './containers/ThankYou/ThankYouContainer';
import SchoolTimelineDetailContainer from './containers/Schools/SchoolTimeline/SchoolTimelineDetailContainer';
import UnifiedNowCardDevPage from './containers/NowCard/UnifiedNowCard/UnifiedNowCardDevPage';
import EditPhoneContainer from './containers/AccountSettings/EditPhoneContainer';
import ProfileFamilySchoolContainer from './containers/ProfileFamilySchoolContainer';
import PanicButtonAddDeviceFlyoverContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonAddDeviceFlyoverContainer';
import CallsAndSMSRulesAddDeviceFlyoverContainer from './containers/ProfileRules/CallsSMSRules/CallsAndSMSRulesAddDeviceFlyoverContainer';
import AppRulesAddDeviceFlyoverContainer from './containers/ProfileRules/AppRules/AppRulesAddDeviceFlyoverContainer';
import YoutubeAddDeviceFlyoverContainer from './containers/YoutubeRules/YoutubeAddDeviceFlyoverContainer';
import LocationRulesAddDeviceFlyoverContainer from './containers/ProfileRules/LocationRules/LocationRulesAddDeviceFlyoverContainer';
import WellbeingContainer from './containers/WellbeingContainer/WellbeingContainer';
import WellbeingTherapyContainer from './containers/WellbeingTherapyContainer/WellbeingTherapyContainer';
import WellbeingActivitiesContainer from './containers/WellbeingActivitiesContainer/WellbeingActivitiesContainer';
import PanicButtonRulesStartTrialFlyoverContainer from './containers/ProfileRules/PanicButtonRules/PanicButtonStartTrialFlyoverContainer';
import CallsAndSMSRulesStartTrialFlyoverContainer from './containers/ProfileRules/CallsSMSRules/CallsAndSMSStartTrialFlyoverContainer';
import LocationRulesStartTrialFlyoverContainer from './containers/ProfileRules/LocationRules/LocationRulesStartTrialFlyoverContainer';
import DirectPurchaseContainer from './containers/DirectPurchase/DirectPurchaseContainer';

type AppRoutesProps = RouterProps & { onChange: ChangeHook };
// route order matters
const AppRoutes: React.FunctionComponent<AppRoutesProps> = (
  { onChange, history = defaultHistory }: AppRoutesProps,
  { store }
) => {
  const onEnter: <Props = unknown>(
    component: React.ComponentType<Props>,
    skipAuth?: boolean | undefined
  ) => EnterHook = loadRouteData(store);

  const onEnterRedirect = redirect(store);
  const onEnterAction = onEnterCallAction(store);

  return (
    <Router history={history}>
      <Route
        path="/"
        component={Main}
        onChange={onChange}
        onEnter={loadRoot(store)}
      >
        <IndexRoute
          component={ProfileListContainer}
          onEnter={onEnterRedirect(ProfileListContainer)}
        />
        <Route path="modal/:modal" component={ProfileListContainer} />
        <Route
          path={`${ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION}(/modal/:modal)`}
          component={ParentOrKidAppChoiceContainer}
          onEnter={onEnter(ParentOrKidAppChoiceContainer)}
        />
        <Route
          path={`${ROUTE_PARENTS_APP_STANDALONE_WRONG_APP}(/modal/:modal)`}
          component={WrongAppInstalledContainer}
          onEnter={onEnter(WrongAppInstalledContainer)}
        />
        <Route
          path="trial/modal/:modal"
          component={ProfileListContainer}
          onEnter={onEnter(ReTrialModalContainer)}
        />
        <Route
          path="playground/:profileId(/modal/:modal)"
          component={ProfileDashboardContainer}
          onEnter={onEnterRedirect(ProfileDashboardContainer)}
        />
        <Route
          path="unifiedNowCard/:profileId(/modal/:modal)"
          component={UnifiedNowCardDevPage}
        />

        <Route path="profiles">
          <IndexRoute component={ProfileListContainer} />
          <Route path="modal/:modal" component={ProfileListContainer} />
          <Route
            path="add(/modal/:modal)"
            component={ProfileAddContainer}
            onEnter={onEnterRedirect(ProfileAddContainer)}
          />

          <Route path=":profileId" onEnter={onEnter(ProfileNewContainer)}>
            <IndexRoute
              component={ProfileNewContainer}
              onEnter={onEnterRedirect(ProfileNewContainer)}
            />
            <Route path="modal/:modal" component={ProfileNewContainer} />
            <Route
              path="premiumSummaryFlyover(/modal/:modal)"
              component={SummaryPremiumFlyoverContainer}
              onEnter={onEnter(SummaryPremiumFlyoverContainer)}
            />
            <Route
              path="add-device(/modal/:modal)"
              component={ProfileAddDeviceInstructionsContainer}
            />
            <Route
              path="family-and-school(/modal/:modal)"
              component={ProfileFamilySchoolContainer}
              onEnter={onEnterRedirect(ProfileFamilySchoolContainer)}
            />
            <Route path="school">
              <Route path="summary">
                <IndexRoute
                  component={StudentSummaryContainer}
                  onEnter={onEnter(StudentSummaryContainer)}
                />
                <Route
                  path="modal/:modal"
                  component={StudentSummaryContainer}
                />
                <Route
                  path="signatures"
                  component={AllSignaturesContainer}
                  onEnter={onEnter(AllSignaturesContainer)}
                >
                  <Route
                    path="modal/:modal"
                    component={StudentSummaryContainer}
                  />
                </Route>
                <Route
                  path="categories"
                  component={StudentSeeAllCategoriesContainer}
                  onEnter={onEnter(StudentSeeAllCategoriesContainer)}
                >
                  <Route
                    path="modal/:modal"
                    component={StudentSummaryContainer}
                  />
                </Route>
              </Route>
              <Route path="signature/:signatureId">
                <IndexRoute
                  component={SignatureDetailsContainer}
                  onEnter={onEnter(SignatureDetailsContainer)}
                />
                <Route
                  path="modal/:modal"
                  component={SignatureDetailsContainer}
                />
              </Route>
              <Route path="timeline(/modal/:modal)">
                <IndexRoute
                  component={SchoolTimelinePageContainer}
                  onEnter={onEnterRedirect(SchoolTimelinePageContainer)}
                />
                <Route
                  path="details/:eventKey(/modal/:modal)"
                  component={SchoolTimelineDetailContainer}
                  onEnter={onEnterRedirect(SchoolTimelineDetailContainer)}
                />
              </Route>
            </Route>

            <Route
              path="add-device(/modal/:modal)"
              component={ProfileAddDeviceInstructionsContainer}
            />

            <Route
              path="activity-timeline"
              onEnter={onEnter(ActivityTimelinePageContainer)}
            >
              <IndexRoute
                component={ActivityTimelinePageContainer}
                onEnter={onEnterRedirect(ActivityTimelinePageContainer)}
              />
              <Route
                path="modal/:modal(/:appKey)"
                component={ActivityTimelinePageContainer}
                onEnter={onEnter(ActivityTimelinePageContainer)}
              />
              <Route
                path="filter/:filter"
                component={ActivityTimelinePageContainer}
                onEnter={onEnter(ActivityTimelinePageContainer)}
              >
                <Route
                  path="modal/:modal(/:appKey)"
                  component={ActivityTimelinePageContainer}
                  onEnter={onEnter(ActivityTimelinePageContainer)}
                />
              </Route>
            </Route>
            <Route path="top-activity">
              <IndexRoute component={TopActivitySummaryPageContainer} />
              <Route
                path="modal/:modal"
                component={TopActivitySummaryPageContainer}
              />
            </Route>
            <Route path="questionable-activity">
              <IndexRoute component={BlockedWebsActivitySummaryPageContainer} />
              <Route
                path="modal/:modal"
                component={BlockedWebsActivitySummaryPageContainer}
              />
            </Route>
            <Route
              path="screen-time(/modal/:modal)"
              component={ScreenTimeActivitySummaryPageContainer}
            />
            <Route path="calls-and-sms-activity">
              <IndexRoute
                component={CallsAndSmsActivitySummaryPageContainer}
                onEnter={onEnter(CallsAndSmsActivitySummaryPageContainer)}
              />
              <Route
                path="modal/:modal"
                component={CallsAndSmsActivitySummaryPageContainer}
                onEnter={onEnter(CallsAndSmsActivitySummaryPageContainer)}
              />
            </Route>
            <Route path="search-activity">
              <IndexRoute
                component={SearchActivitySummaryPageContainer}
                onEnter={onEnter(SearchActivitySummaryPageContainer)}
              />
              <Route
                path="modal/:modal"
                component={SearchActivitySummaryPageContainer}
                onEnter={onEnter(SearchActivitySummaryPageContainer)}
              />
            </Route>
            <Route path="youtube-activity">
              <IndexRoute
                component={YoutubeActivityContainer}
                onEnter={onEnter(YoutubeActivityContainer)}
              />
              <Route path="modal/:modal" component={YoutubeActivityContainer} />
              <Route
                path="domain/:domain/url/:url/modal/:modal"
                component={YoutubeActivityContainer}
              />
            </Route>
            <Route path="insights">
              <IndexRoute
                component={InsightsContainer}
                onEnter={onEnter(InsightsContainer)}
              />
              <Route
                path="modal/:modal"
                component={InsightsContainer}
                onEnter={onEnter(InsightsContainer)}
              />
            </Route>
            <Route
              path="rules"
              onEnter={onEnterRedirect(ProfileRulesContainer)}
            >
              <IndexRoute component={ProfileRulesContainer} />
              <Route path="modal/:modal" component={ProfileRulesContainer} />
              <Route
                path="youtube-rules"
                onEnter={onEnter(YoutubeRulesContainer)}
              >
                <IndexRoute
                  component={YoutubeRulesContainer}
                  onEnter={onEnterRedirect(YoutubeRulesContainer)}
                />
                <Route
                  path="modal/:modal/toggleOn/:toggleOn"
                  component={YoutubeRulesContainer}
                />
                <Route path="modal/:modal" component={YoutubeRulesContainer} />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={YoutubeFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={YoutubeAddDeviceFlyoverContainer}
                />
              </Route>
              <Route
                path="dailyTimeLimit/devices/:deviceId"
                onEnter={onEnter(DailyTimeLimitContainer)}
              >
                <IndexRoute component={DailyTimeLimitContainer} />
                <Route
                  path="modal/:modal"
                  component={DailyTimeLimitContainer}
                />
                <Route
                  path="timeSettings(/modal/:modal)"
                  component={TimeSettingsPageContainer}
                />
              </Route>
              <Route
                path="restrictedTimes/devices/:deviceId(/modal/:modal)"
                onEnter={onEnter(RestrictedTimesContainer)}
              >
                <IndexRoute component={RestrictedTimesContainer} />
                <Route
                  path="modal/:modal"
                  component={RestrictedTimesContainer}
                />
              </Route>
              <Route path="webFilters" onEnter={onEnter(WebFiltersContainer)}>
                <IndexRoute component={WebFiltersContainer} />
                <Route path="modal/:modal" component={WebFiltersContainer} />

                <Route
                  path="addDomainException(/modal/:modal)"
                  component={AddDomainExceptionContainer}
                  onEnter={onEnter(AddDomainExceptionContainer)}
                />
              </Route>
              <Route
                path="webFilters-settings"
                onEnter={onEnter(WebFiltersSettingsContainer)}
              >
                <IndexRoute component={WebFiltersSettingsContainer} />
                <Route
                  path="modal/:modal"
                  component={WebFiltersSettingsContainer}
                />
              </Route>
              <Route path="appRules" onEnter={onEnter(AppRulesContainer)}>
                <IndexRoute
                  component={AppRulesContainer}
                  onEnter={onEnterRedirect(AppRulesContainer)}
                />
                <Route
                  path="modal/:modal(/:appKey)"
                  component={AppRulesContainer}
                />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={AppRulesPremiumFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={AppRulesAddDeviceFlyoverContainer}
                />
              </Route>
              <Route
                path="appRules-settings"
                onEnter={onEnter(AppRulesSettingsContainer)}
              >
                <IndexRoute
                  component={AppRulesSettingsContainer}
                  onEnter={onEnterRedirect(AppRulesSettingsContainer)}
                />
                <Route
                  path="modal/:modal"
                  component={AppRulesSettingsContainer}
                />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={AppRulesPremiumFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={AppRulesAddDeviceFlyoverContainer}
                />
              </Route>
              <Route
                path="locationRules"
                onEnter={onEnter(LocationRulesContainer)}
              >
                <IndexRoute
                  component={LocationRulesContainer}
                  onEnter={onEnterRedirect(LocationRulesContainer)}
                />
                <Route path="modal/:modal" component={LocationRulesContainer} />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={LocationRulesPremiumFlyoverContainer}
                />
                <Route
                  path="mobileFlyover(/modal/:modal)"
                  component={LocationRulesMobileFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={LocationRulesAddDeviceFlyoverContainer}
                />
                <Route
                  path="startTrial(/modal/:modal)"
                  component={LocationRulesStartTrialFlyoverContainer}
                  onEnter={onEnterRedirect(
                    LocationRulesStartTrialFlyoverContainer
                  )}
                />
              </Route>

              <Route
                path="callsSMSRules"
                onEnter={onEnter(CallsSMSRulesContainer)}
              >
                <IndexRoute
                  component={CallsSMSRulesContainer}
                  onEnter={onEnterRedirect(CallsSMSRulesContainer)}
                />
                <Route path="modal/:modal" component={CallsSMSRulesContainer} />
                <Route
                  path="addContact(/modal/:modal)"
                  component={CallsSMSRulesAddContactContainer}
                  onEnter={onEnter(CallsSMSRulesAddContactContainer)}
                />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={CallsSMSRulesPremiumFlyoverContainer}
                />
                <Route
                  path="connectDeviceFlyover(/modal/:modal)"
                  component={CallsSMSRulesConnectDeviceFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={CallsAndSMSRulesAddDeviceFlyoverContainer}
                />
                <Route
                  path="startTrial(/modal/:modal)"
                  component={CallsAndSMSRulesStartTrialFlyoverContainer}
                  onEnter={onEnterRedirect(
                    CallsAndSMSRulesStartTrialFlyoverContainer
                  )}
                />
              </Route>

              <Route
                path="panicButtonRules"
                onEnter={onEnter(PanicButtonRulesContainer)}
              >
                <IndexRoute
                  component={PanicButtonRulesContainer}
                  onEnter={onEnterRedirect(PanicButtonRulesContainer)}
                />
                <Route
                  path="modal/:modal"
                  component={PanicButtonRulesContainer}
                />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={PanicButtonRulesPremiumFlyoverContainer}
                />
                <Route
                  path="connectDeviceFlyover(/modal/:modal)"
                  component={PanicButtonRulesAndroidFlyoverContainer}
                />
                <Route
                  path="addDeviceFlyover(/modal/:modal)"
                  component={PanicButtonAddDeviceFlyoverContainer}
                />
                <Route
                  path="startTrial(/modal/:modal)"
                  component={PanicButtonRulesStartTrialFlyoverContainer}
                  onEnter={onEnterRedirect(
                    PanicButtonRulesStartTrialFlyoverContainer
                  )}
                />
                <Route
                  path="inviteContact(/modal/:modal)"
                  component={PanicButtonRulesInviteContactContainer}
                  onEnter={onEnter(PanicButtonRulesInviteContactContainer)}
                />
              </Route>

              <Route
                path="socialMonitoringRules"
                onEnter={onEnter(SocialMonitoringRulesContainer)}
              >
                <IndexRoute
                  component={SocialMonitoringRulesContainer}
                  onEnter={onEnterRedirect(SocialMonitoringRulesContainer)}
                />
                <Route
                  path="modal/:modal"
                  component={SocialMonitoringRulesContainer}
                />
                <Route
                  path="premiumFlyover(/modal/:modal)"
                  component={SocialMonitoringRulesPremiumFlyoverContainer}
                />
              </Route>

              <Route path="appRules">
                <Route
                  path=":appKey(/modal/:modal)"
                  component={AppDailyTimeLimitContainer}
                />
              </Route>
            </Route>

            <Route
              path="edit(/modal/:modal)"
              component={ProfileEditContainer}
            />

            <Route
              path="url-details/:eventKey"
              onEnter={onEnter(UrlDetailsContainer)}
            >
              <IndexRoute
                component={UrlDetailsContainer}
                onEnter={onEnterRedirect(UrlDetailsContainer)}
              />
              <Route path="modal/:modal" component={UrlDetailsContainer} />
              <Route
                path="domain/:domain/url/:url/modal/:modal"
                component={UrlDetailsContainer}
              />
            </Route>

            <Route
              path="routines(/edit/:editRoutineUid)(/modal/:modal)"
              onEnter={onEnter(RoutinesContainer)}
            >
              <IndexRoute
                component={RoutinesContainer}
                onEnter={onEnterRedirect(RoutinesContainer)}
              />
            </Route>
          </Route>
        </Route>

        <Route path="devices" onEnter={onEnter(DeviceListContainer)}>
          <IndexRoute component={DeviceListContainer} />

          <Route path="modal/:modal" component={DeviceListContainer} />

          <Route
            path="add(/modal/:modal)"
            component={AddDeviceInstructionsContainer}
          />

          <Route path=":deviceId" onEnter={onEnter(DeviceContainer)}>
            <IndexRoute component={DeviceContainer} />
            <Route
              path="edit(/modal/:modal)"
              component={DeviceEditContainer}
              onEnter={onEnter(DeviceEditContainer)}
            />
            <Route path="modal/:modal" component={DeviceContainer} />
            <Route path="assign/modal/:modal" component={DeviceContainer} />
            <Route
              path="assign/:deviceUserId/modal/:modal"
              component={DeviceContainer}
            />
          </Route>
        </Route>
        <Route path="upgrade" onEnter={onEnterRedirect(UpgradeContainer)}>
          <IndexRoute
            component={UpgradeContainer}
            onEnter={onEnter(UpgradeContainer)}
          />
          <Route path="modal/:modal" component={UpgradeContainer} />
          <Route
            path="promotion/:productId(/modal/:modal)"
            component={UpgradeContainer}
            onEnter={onEnter(TwoYearsPromotionModalContainer)}
          />
          <Route
            path=":promotion"
            component={UpgradeContainer}
            onEnter={onEnterRedirect(UpgradeContainer)}
          >
            <IndexRoute
              component={UpgradeContainer}
              onEnter={onEnter(UpgradeContainer)}
            />
            <Route path="modal/:modal" component={UpgradeContainer} />
          </Route>
        </Route>

        <Route
          path="activate-retrial(/modal/:modal)"
          component={ActivateRetrialContainer}
          onEnter={onEnter(ActivateRetrialContainer)}
        />

        <Route
          path="/renew(/modal/:modal)"
          component={UpgradeContainer}
          onEnter={onEnter(UpgradeContainer)}
        />
        <Route
          path="/account-settings(/modal/:modal)"
          component={AccountSettingsContainer}
          onEnter={onEnter(AccountSettingsContainer)}
        />
        <Route
          path="/logout/modal/:modal"
          component={AccountSettingsContainer}
          onEnter={onEnter(LogoutModalContainer)}
        />
        <Route path="familyLocator(/modal/:modal)">
          <IndexRoute
            component={MapWrapperContainer}
            onEnter={onEnterRedirect(FamilyLocatorContainer)}
          />
          <Route
            path="premiumFlyover(/modal/:modal)"
            component={FamilyLocatorFlyoverContainer}
          />
          <Route
            path=":profileId/locations(/modal/:modal)"
            component={MapWrapperContainer}
            onEnter={onEnter(KidLocationsHistoryContainer)}
          />
          <Route
            path="places/place/:placeUid(/action/:action)(/modal/:modal)"
            component={MapWrapperContainer}
          />
          <Route
            path="places/place/:placeUid(/modal/:modal)"
            component={PlacesContainer}
            onEnter={onEnter(PlacesContainer)}
          />
          <Route
            path="places/action/:action(/modal/:modal)"
            component={MapWrapperContainer}
            onEnter={onEnter(MapWrapperContainer)}
          />
          <Route
            path="places/resolve-address(/modal/:modal)"
            component={ResolveAddressContainer}
            onEnter={onEnter(ResolveAddressContainer)}
          />
          <Route
            path=":profileId/location/:eventKey(/action/:action)(/modal/:modal)"
            component={MapWrapperContainer}
            onEnter={onEnter(MapWrapperContainer)}
          />
          <Route
            path="places(/modal/:modal)"
            component={PlacesContainer}
            onEnter={onEnter(PlacesContainer)}
          />
        </Route>

        <Route
          path="/wellbeing(/modal/:modal)"
          component={WellbeingContainer}
        />

        <Route
          path="/wellbeing-therapy(/modal/:modal)"
          component={WellbeingTherapyContainer}
        />

        <Route
          path="/wellbeing-activities(/modal/:modal)"
          component={WellbeingActivitiesContainer}
        />

        <Route path="/edit-name(/modal/:modal)" component={EditNameContainer} />
        <Route
          path="/edit-email(/modal/:modal)"
          component={EditEmailContainer}
        />
        <Route
          path="/edit-language(/modal/:modal)"
          component={EditLanguageContainer}
        />
        <Route
          path="/edit-password(/modal/:modal)"
          component={EditPasswordContainer}
        />
        <Route
          path="/edit-timezone(/modal/:modal)"
          component={EditTimezoneContainer}
        />
        <Route
          path="/edit-country(/modal/:modal)"
          component={EditCountryContainer}
        />
        <Route
          path="/edit-phone(/modal/:modal)"
          component={EditPhoneContainer}
        />
        <Route
          path="debug/:profileId(/modal/:modal)"
          component={DebugContainer}
        />

        <Route path="schools">
          <Route
            path="choose-a-profile"
            component={ProtectProfilesListContainer}
            onEnter={onEnter(ProtectProfilesListContainer)}
          >
            <IndexRoute component={ProtectProfilesListContainer} />
            <Route
              path="modal/:modal"
              component={ProtectProfilesListContainer}
            />
          </Route>
        </Route>

        <Route
          path="/direct-purchase/:productCode(/modal/:modal)"
          component={DirectPurchaseContainer}
          onEnter={onEnter(DirectPurchaseContainer)}
        />
      </Route>

      <Route
        path="onboarding-schools"
        onEnter={loadRoot(store)}
        component={MainOnboarding}
      >
        <Route
          path="link-students"
          component={LinkStudentsContainer}
          onEnter={onEnter(LinkStudentsContainer)}
        >
          <IndexRoute component={LinkStudentsContainer} />
          <Route path="modal/:modal" component={LinkStudentsContainer} />
        </Route>

        <Route
          path="error"
          component={SchoolsOnboardingErrorContainer}
          onEnter={onEnter(SchoolsOnboardingErrorContainer)}
        >
          <IndexRoute component={SchoolsOnboardingErrorContainer} />
          <Route
            path="modal/:modal"
            component={SchoolsOnboardingErrorContainer}
          />
        </Route>

        <Route
          path="error-wrong-information"
          component={SchoolsOnboardingErrorWrongInfoContainer}
          onEnter={onEnter(SchoolsOnboardingErrorWrongInfoContainer)}
        >
          <IndexRoute component={SchoolsOnboardingErrorWrongInfoContainer} />
          <Route
            path="modal/:modal"
            component={SchoolsOnboardingErrorWrongInfoContainer}
          />
        </Route>

        <Route
          path="complete-student(/:studentUid)"
          component={CompleteStudentContainer}
          onEnter={onEnter(CompleteStudentContainer)}
        >
          <IndexRoute component={CompleteStudentContainer} />
          <Route path="modal/:modal" component={CompleteStudentContainer} />
        </Route>
      </Route>

      <Route
        path="onboarding"
        onEnter={loadRoot(store)}
        component={MainOnboarding}
      >
        <IndexRoute component={OnboardingWelcomeContainer} />

        <Route path="modal/:modal" component={OnboardingWelcomeContainer} />

        <Route
          path="add-child(/:profileId)(/modal/:modal)"
          component={OnboardingAddChildContainer}
        />

        <Route
          path="add-device(/:profileId)(/modal/:modal)"
          component={OnboardingDownloadContainer}
          onEnter={onEnterAction(onEnterOnboardingPendingDeviceConnection)}
        />

        <Route
          path="finish(/:profileId)(/modal/:modal)"
          component={OnboardingFinishContainer}
          onEnter={onEnterAction(onEnterOnboardingFinish)}
        />
      </Route>

      {isSSOEnabled() ? (
        <React.Fragment>
          <Redirect from="/login(/modal/:modal)" to="/" />
          <Redirect from="/login/:license(/modal/:modal)" to="/" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Route
            path="/login(/modal/:modal)"
            component={LoginContainer}
            onEnter={onEnter(LoginContainer, true)}
          />
          <Route
            path="/login/:license(/modal/:modal)"
            component={LoginContainer}
            onEnter={onEnter(LoginContainer, true)}
          />
        </React.Fragment>
      )}
      <Route
        path="/lockScreen(/modal/:modal)"
        component={LockScreenContainer}
        onEnter={onEnterRedirect(LockScreenContainer, true)}
      />
      <Route
        path="/initial(/modal/:modal)"
        component={InitialContainer}
        onEnter={onEnterRedirect(InitialContainer, true)}
      />
      <Route
        path="/logout(/:license)(/modal/:modal)"
        component={LogoutContainer}
        onEnter={onEnter(LogoutContainer, true)}
      />
      <Route
        path="/logout/:license(/:error)(/modal/:modal)"
        component={LogoutContainer}
        onEnter={onEnter(LogoutContainer, true)}
      />
      <Route
        path="/checkout/thank-you"
        component={ThankYouContainer}
        onEnter={onEnter(ThankYouContainer, true)}
      />
      <Redirect from="*" to="/" />
    </Router>
  );
};

AppRoutes.contextTypes = {
  store: PropTypes.object,
};

export default AppRoutes;
