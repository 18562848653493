import * as React from 'react';
import { t } from '../../lib/i18n';
import LocationRulesFlyover from './LocationRulesFlyover';
import type { LocationRulesMobileFlyoverProps } from '../../containers/ProfileRules/LocationRules/LocationRulesMobileFlyoverContainer';

const LOCATION_MOBILE_FLYOVER = 'location-mobile-flyover';

const LocationRulesMobileFlyover = ({
  accountLocale,
  onAddDevice,
  onClickClose,
}: LocationRulesMobileFlyoverProps) => {
  return (
    <LocationRulesFlyover
      testId={LOCATION_MOBILE_FLYOVER}
      accountLocale={accountLocale}
      actionButton={{
        title: t('Add a device'),
        onClick: onAddDevice,
        testId: `${LOCATION_MOBILE_FLYOVER}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android and iOS')}
    />
  );
};

export default LocationRulesMobileFlyover;
