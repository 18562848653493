import { Record, List } from 'immutable';
import { CategoryRecord } from '../category';
import { mapPlatform } from '../device';
import { Platform } from '../../constants';

export * from './MinutesPerHourActivity';
export * from './HoursPerDayActivity';

export enum ActivityTypes {
  WebDomain = 101,
  WebCategory = 102,
  App = 301,
  CallAndSms = 201,
  Search = 103,
  Other = -1,
}

interface Activity {
  percent: number;
  minutes: number;
  type: ActivityTypes;
}

export type AppActivityRecord = Activity & {
  name: string;
  platform: Platform;
  exe: string;
  thumbnail?: string;
};

export const AppActivityRecord = Record<
  AppActivityRecord,
  {
    fromPayload: (response: any) => AppActivityRecord;
  }
>({
  percent: 0,
  minutes: 0,
  type: ActivityTypes.App,
  name: '',
  platform: mapPlatform(undefined),
  exe: '',
  thumbnail: '',
});

AppActivityRecord.fromPayload = payload =>
  AppActivityRecord({
    type: ActivityTypes.App,
    percent: payload.percent,
    minutes: payload.minutes,
    platform: mapPlatform(payload.platform) as Platform,
    exe: payload.exe,
    name: payload.app_name,
    thumbnail: payload.thumbnail,
  });

export type WebActivityRecord = Activity & {
  category: CategoryRecord;
};

export const WebActivityRecord = Record<
  WebActivityRecord,
  {
    fromPayload: (response: any) => WebActivityRecord;
  }
>({
  percent: 0,
  minutes: 0,
  type: ActivityTypes.WebCategory,
  category: CategoryRecord(),
});

WebActivityRecord.fromPayload = payload =>
  WebActivityRecord({
    type: ActivityTypes.WebCategory,
    percent: payload.percent,
    minutes: payload.minutes,
    category: CategoryRecord.fromPayload(payload.category),
  });

export type WebDomainActivityRecord = Activity & {
  categories: List<CategoryRecord>;
  blockedPages: number;
  pages: number;
  host: string;
  action: number;
  reason: number;
};

export const WebDomainActivityRecord = Record<
  WebDomainActivityRecord,
  {
    fromPayload: (response: any) => WebDomainActivityRecord;
  }
>({
  percent: 0,
  minutes: 0,
  type: ActivityTypes.WebDomain,
  categories: List(),
  blockedPages: 0,
  pages: 0,
  host: '',
  action: 0,
  reason: 0,
});

WebDomainActivityRecord.fromPayload = payload =>
  WebDomainActivityRecord({
    type: ActivityTypes.WebDomain,
    minutes: payload.minutes,
    categories: List(
      payload.categories.map((category: number) =>
        CategoryRecord.fromPayload(category)
      )
    ),
    blockedPages: payload.blocked_pages,
    pages: payload.pages,
    host: payload.host,
    action: payload.action,
    reason: payload.reason,
  });

export type OtherActivityRecord = Activity;

export const OtherActivityRecord = Record<
  OtherActivityRecord,
  {
    fromPayload: (percent: any) => OtherActivityRecord;
  }
>({
  percent: 0,
  minutes: 0,
  type: ActivityTypes.Other,
});

OtherActivityRecord.fromPayload = percent =>
  OtherActivityRecord({
    type: ActivityTypes.Other,
    percent,
  });

export type CallsAndSmsActivityRecord = Activity & {
  socialContactId: string;
  socialContactName?: string;
  socialNetwork: string;
  accountId: number;
  userId: number;
  profileId: number;
  messages: number;
  blockedAttempts: number;
  calls: number;
  callsDuration: number;
};

export const CallsAndSmsActivityRecord = Record<
  CallsAndSmsActivityRecord,
  {
    fromPayload: (response: any) => CallsAndSmsActivityRecord;
  }
>({
  socialContactId: '',
  socialContactName: '',
  socialNetwork: '',
  accountId: 0,
  userId: 0,
  profileId: 0,
  type: 0,
  minutes: 0,
  messages: 0,
  blockedAttempts: 0,
  calls: 0,
  callsDuration: 0,
  percent: 0,
});

CallsAndSmsActivityRecord.fromPayload = payload =>
  CallsAndSmsActivityRecord({
    type: ActivityTypes.CallAndSms,
    socialContactId: payload.socialContactId,
    socialContactName: payload.socialContactName,
    socialNetwork: payload.socialNetwork,
    accountId: payload.accountId,
    userId: payload.userId,
    profileId: payload.profileId,
    minutes: payload.minutes,
    messages: payload.messages,
    blockedAttempts: payload.blockedAttempts,
    calls: payload.calls,
    callsDuration: payload.callsDuration,
  });

export type SearchActivityRecord = Activity & {
  id: string;
  term: string;
  type: number;
  count: number;
  accountId: number;
  profileId: number;
  userId: number;
  questionable: boolean;
};

export const SearchActivityRecord = Record<
  SearchActivityRecord,
  {
    fromPayload: (response: any) => SearchActivityRecord;
  }
>({
  id: '',
  term: '',
  type: ActivityTypes.Search,
  count: 0,
  accountId: 0,
  profileId: 0,
  userId: 0,
  questionable: false,
  percent: 0,
  minutes: 0,
});

SearchActivityRecord.fromPayload = payload =>
  SearchActivityRecord({
    id: payload._id,
    term: payload.term,
    type: ActivityTypes.Search,
    count: payload.count,
    accountId: payload.accountId,
    profileId: payload.profileId,
    userId: payload.userId,
    questionable: payload.questionable,
  });

export type ActivityRecord =
  | AppActivityRecord
  | WebActivityRecord
  | WebDomainActivityRecord
  | OtherActivityRecord
  | CallsAndSmsActivityRecord
  | SearchActivityRecord;
