import { connect } from 'react-redux';
import CallsSMSRules from '../../../components/CallsSMSRules/CallsSMSRules';
import {
  getCallsSMSRulesEnabled,
  getMonitorSMSContent,
  getIncomingCallsBlock,
  getOutgoingCallsBlock,
  getCallsSMSRulesActiveSection,
  getCallsSMSBlockingOption,
  getCallsSMSContacts,
  getCallsSMSActiveContact,
} from '../../../selectors/profileRules';
import {
  getCompatibleAndroidDevicesWithCallsSMS,
  getCompatibleIOSDevicesWithCallsSMS,
  hasCompatibleAndroidDeviceWithCallsSMS,
  hasCompatibleIOSDeviceWithCallsSMS,
} from '../../../selectors/profileDevices';

import { CALLSMS_RULES_SECTION } from '../../../constants';
import {
  setCallsSMSActiveSection,
  setCallsSMSActiveContact,
} from '../../../ducks/profileRules';

import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import {
  trackCallsAndSMSRule,
  CallsAndSMSRuleNames,
} from '../../../helpers/analytics';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import {
  callsAndSMSExceptionActionSheetClick,
  toggleCallsSMSRule,
} from '../../../actions/ActionSheetActions';
import State, {
  BaseThunk,
  Dispatch,
  RouterParamsProps,
} from '../../../store/state';
import { ProfileIdField } from '../../../records/profile/types/Profile.types';
import {
  CallsSMSRulesDispatchProps,
  CallsSMSRulesStateProps,
} from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { getLicense } from '../../../selectors';
import { shouldBlockThisFeature } from '../../../helpers/premiumFeatures';
import { showPremiumFlyover } from '../../../actions/PremiumFeaturesActions';
import { redirectToAddDeviceFlyover } from '../../../actions/redirectAddDeviceFlyover';
import { redirectToStartTrialFlyover } from '../../../actions/redirectStartTrialFlyover';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps<ProfileIdField>
): CallsSMSRulesStateProps => {
  return {
    profileId,
    callsSMSRulesEnabled: getCallsSMSRulesEnabled(state, profileId),
    monitorSMSContentEnabled: getMonitorSMSContent(state, profileId),
    incomingCallsBlocked: getIncomingCallsBlock(state, profileId),
    outgoingCallsBlocked: getOutgoingCallsBlock(state, profileId),
    activeSection: getCallsSMSRulesActiveSection(state),
    activeBlockingOption: getCallsSMSBlockingOption(state),
    contacts: getCallsSMSContacts(state, profileId),
    activeContact: getCallsSMSActiveContact(state),
    compatibleIphoneDevices: getCompatibleIOSDevicesWithCallsSMS(
      state,
      profileId
    ),
    compatibleAndroidDevices: getCompatibleAndroidDevicesWithCallsSMS(
      state,
      profileId
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps<ProfileIdField>
): CallsSMSRulesDispatchProps => ({
  onClickSection: section => {
    dispatch(setCallsSMSActiveSection(section));
  },
  onToggleCallsSMSRulesEnabled: toggleOn => {
    trackCallsAndSMSRule(
      toggleOn
        ? CallsAndSMSRuleNames.ENABLE_CALLS_AND_SMS_MONITORING
        : CallsAndSMSRuleNames.DISABLE_CALLS_AND_SMS_MONITORING
    );
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleCallsSMSRule(toggleOn, 'enabled')
      )
    );
  },
  onToggleMonitorSmsContentEnabled: toggleOn => {
    trackCallsAndSMSRule(
      toggleOn
        ? CallsAndSMSRuleNames.ENABLE_REPORT_CONTENT
        : CallsAndSMSRuleNames.DISABLE_REPORT_CONTENT
    );
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleCallsSMSRule(toggleOn, 'monitorSmsContent')
      )
    );
  },
  onClickDisabledSection: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `profiles/${profileId}/rules/callsSMSRules/modal/CallsSMSBlockedSectionModal`,
      })
    );
  },
  onToggleBlockIncomingCalls: toggleOn => {
    trackCallsAndSMSRule(
      toggleOn
        ? CallsAndSMSRuleNames.ENABLE_BLOCK_ALL_INCOMING_CALLS
        : CallsAndSMSRuleNames.DISABLE_BLOCK_ALL_INCOMING_CALLS
    );
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleCallsSMSRule(toggleOn, 'incomingCallsBlock')
      )
    );
  },
  onToggleBlockOutgoingCalls: toggleOn => {
    trackCallsAndSMSRule(
      toggleOn
        ? CallsAndSMSRuleNames.ENABLE_BLOCK_ALL_OUTGOING_CALLS
        : CallsAndSMSRuleNames.DISABLE_BLOCK_ALL_OUTGOING_CALLS
    );
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleCallsSMSRule(toggleOn, 'outgoingCallsBlock')
      )
    );
  },
  onClickAddException: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/callsSMSRules/addContact`,
      })
    );
  },
  onClickContact: contact => {
    dispatch(setCallsSMSActiveContact(contact));
  },
  callsAndSMSExceptionActionSheetClick: option => () => {
    dispatch(callsAndSMSExceptionActionSheetClick(option));
  },
});

const CallsSMSRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsSMSRules);

CallsSMSRulesContainer.redirect =
  (params: ProfileIdField): BaseThunk<void> =>
  async (dispatch, getState) => {
    const profileId = parseInt(params.profileId, 10);
    const state = getState();
    const navigate = getMultiPlatformNavigation();
    const license = getLicense(state);

    await dispatch(
      redirectToAddDeviceFlyover(
        params.profileId,
        `/profiles/${profileId}/rules/callsSMSRules/addDeviceFlyover`
      )
    ).then(redirectedToAddDevice => {
      if (redirectedToAddDevice) return;

      dispatch(
        redirectToStartTrialFlyover(
          `/profiles/${profileId}/rules/callsSMSRules/startTrial`
        )
      ).then(redirectedToAddDevice => {
        if (redirectedToAddDevice) return;

        if (shouldBlockThisFeature('callsSMSRules', license.subtype)) {
          return dispatch(
            showPremiumFlyover(
              `/profiles/${profileId}/rules/callsSMSRules/premiumFlyover`
            )
          );
        }

        dispatch(
          redirectFreeToPremiumAction(
            params.profileId,
            'rules/callsSMSRules/premiumFlyover'
          )
        ).then(wasRedirected => {
          if (wasRedirected) {
            return;
          }
          if (
            !hasCompatibleAndroidDeviceWithCallsSMS(state, profileId) &&
            !hasCompatibleIOSDeviceWithCallsSMS(state, profileId)
          ) {
            dispatch(
              navigate({
                type: 'inner:replace',
                src: `/profiles/${profileId}/rules/callsSMSRules/connectDeviceFlyover`,
              })
            );
          }
        });
      });
    });
  };

CallsSMSRulesContainer.load = (): BaseThunk<void> => (dispatch, getState) => {
  // we only want to reset active section user didn't navigate back
  // by using back/forward button
  // e.g. user goes to the subview CallsSMSRulesAddContainer and then
  // back to this screen. In this case the active section shouldn't be overridden
  if (
    getState()?.get('routing')?.get('locationBeforeTransitions')?.action ===
    'PUSH'
  ) {
    dispatch(setCallsSMSActiveSection(CALLSMS_RULES_SECTION));
  } // else user didn't use forward/back button
};

export default CallsSMSRulesContainer;
