import { fromJS } from 'immutable';
import { SmartDevicesStatusState, SmartDevicesStatusActions } from './types';

export const initialState: SmartDevicesStatusState = fromJS({
  status: 'LOADING',
});

export default function smartDeviceStatus(
  state: SmartDevicesStatusState = initialState,
  action: SmartDevicesStatusActions
) {
  switch (action.type) {
    case 'SMART_DEVICES_UPDATE_FETCHING':
      return state.merge({
        status: action.payload.status,
      });
    default:
      return state;
  }
}
