import { connect } from 'react-redux';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import SetupAlertsModal from '../../components/Modal/SetupAlertsModal';
import State, { Dispatch } from '../../store/state';
import { canPurchase } from '../../selectors/app';
import { getQueryParam } from '../../selectors';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { navigateToUpgrade } from '../../actions/Navigation';

export enum SetupAlertsModalReferrer {
  webFilteringSiteNotifications = 'blocked-site-notifications',
  webFilteringAlertMeCategory = 'alert-me-category',
  webFilteringAlertMeException = 'alert-me-exception',
}
export const getReferrerFromQuery = (state: State) =>
  getQueryParam(state, 'referrer') as SetupAlertsModalReferrer | undefined;

export const track = (
  reason:
    | 'web_filtering_blocked_site_notifications'
    | 'web_filtering_alert_me_category'
    | 'web_filtering_alert_me_exception'
) => {
  trackButtonClicked(GenericPageNames.WebFilteringRules, ButtonNames.Upgrade, {
    options: JSON.stringify({
      context: 'partial_block_modal',
      reason,
    }),
  });
};

const mapStateToProps = (state: State) => {
  const referrer = getReferrerFromQuery(state);
  return {
    referrer,
    canPurchase: canPurchase(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(close()),
  onClickUpgradeNow: (
    canPurchase: boolean,
    referrer?: SetupAlertsModalReferrer
  ) => {
    if (!canPurchase) {
      return dispatch(close());
    }
    switch (referrer) {
      case SetupAlertsModalReferrer.webFilteringSiteNotifications:
        track('web_filtering_blocked_site_notifications');
        break;
      case SetupAlertsModalReferrer.webFilteringAlertMeCategory:
        track('web_filtering_alert_me_category');
        break;
      case SetupAlertsModalReferrer.webFilteringAlertMeException:
        track('web_filtering_alert_me_exception');
        break;
      default:
        break;
    }
    dispatch(navigateToUpgrade());
  },
});

export type SetupAlertsModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SetupAlertsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupAlertsModal);

export default SetupAlertsModalContainer;
