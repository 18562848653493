import { reduce } from 'ramda';
import { Action } from 'redux';
import State, { Dispatch } from '../store/state';

export type APPThunk<T = any> = (
  d: Dispatch<Action<string>>,
  getState: () => State
) => T;

const asyncThunkIdentity = (): APPThunk => () => Promise.resolve();

const sequentially2 =
  (f: APPThunk, g: APPThunk): APPThunk =>
  async (dispatch: Dispatch, getState: () => State) => {
    await f(dispatch, getState);
    await g(dispatch, getState);
  };

export const sequentially = <T>(
  ...thunks: APPThunk<Promise<Action<string>> | Action<string>>[]
): APPThunk<T> => reduce(sequentially2, asyncThunkIdentity(), thunks);

const parallel2 =
  (f: APPThunk, g: APPThunk): APPThunk =>
  async (dispatch: Dispatch, getState: () => State) => {
    await Promise.all([f(dispatch, getState), g(dispatch, getState)]);
  };

export const parallel = <T>(
  ...thunks: APPThunk<Promise<Action<string>> | Action<string>>[]
): APPThunk<T> => reduce(parallel2, asyncThunkIdentity(), thunks);
