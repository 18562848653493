import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import {
  getActiveDateRangeNormalized,
  isFetchingProfile,
  getSummaryScreenTimeActivityError,
} from '../../selectors';

import { loadScreenTimeActivityCard } from '../../ducks/summary';
import type { ProfileRecord as ProfileRecordType } from '../../records/profile/types/Profile.types';
import TotalScreenTimeWidget from '../../components/TotalScreenTime/TotalScreenTimeWidget';
import { getTotalSpendMinutes } from './TotalScreenTimeContainer';
import { navigateToScreenTimeDetails } from '../../actions/ProfileActions';
import { ButtonNames, trackButtonClicked } from '../../helpers/analytics';
import { getCurrentDashboardPageName } from '../../ducks/unifiedNowCard/thunks';

interface Props {
  profileId: ProfileRecordType['id'];
}

const mapStateToProps = (state: State) => {
  const activeDateRange = getActiveDateRangeNormalized(state);
  const isFetchingInfo = isFetchingProfile(state);
  const hasError = Boolean(getSummaryScreenTimeActivityError(state));
  const screenTimeSpent = getTotalSpendMinutes(activeDateRange, state);

  return {
    isFetchingInfo,
    hasError,
    screenTimeSpent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { profileId }: Props) => {
  return {
    loadWidget: () => {
      dispatch(loadScreenTimeActivityCard({ profileId: profileId.toString() }));
    },
    navigateToDetails: () => {
      dispatch((_dispatch, getState) => {
        const state = getState();
        const pageName = getCurrentDashboardPageName(state);

        trackButtonClicked(pageName, ButtonNames.seeAllScreenTime);
      });
      dispatch(navigateToScreenTimeDetails(profileId));
    },
  };
};

const TotalScreenTimeWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalScreenTimeWidget);

export default TotalScreenTimeWidgetContainer;

export type TotalScreenTimeWidgetProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
