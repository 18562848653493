import { List, Record, Map } from 'immutable';
import * as R from 'ramda';
import {
  WEEKDAYS,
  WEEKDAYS_WEEKEND,
  WEEKDAYS_WORKING,
  Weekday,
} from '../helpers/dates';
import {
  apiWeekdayKeys,
  WEBFILTER_ACTION_KEYS,
  WEBFILTER_ACTIONS,
  WEBFILTER_DOMAIN_ACTIONS,
  APPLICATION_ACTIONS,
  CALLSMS_CONTACT_ACTIONS,
  CALLSMS_RULES_REMOVE,
  CallSmsRulesAction,
  ApplicationAction,
  APPLICATION_ALLOWED,
  Platform,
  CALLSMS_RULES_ALLOW,
  WebfilterAction,
  WebfilterActionKeys,
  VideoActions,
} from '../constants';
import { mapPlatform, inverseMapPlatform } from './device';
import { ContactRecord } from './contact';
import * as iconAppDefault from '../assets/base/images/icons/icon-app-default.png';

import { AppActivityRecord } from './activity';
import { PrivateNominalType } from '../types/PrivateNominalType.types';
import { PolicyApplication } from './routines/policy/types/PolicyApplication.types';
import { DatetimeISO6801 } from '../types/dates';

const COMPLETE_DAY_IN_MINUTES = 24 * 60;

/**
 * Not an actual immutable record!
 */
const TimesRecord = {
  fromPayload: (payload?: { [index: string]: number }): TimesRecord => {
    const map = payload
      ? R.zip(
          // key-value tuples
          WEEKDAYS, // key-list
          // get weekday values from parameter as value-list, default to zero
          apiWeekdayKeys.map(R.pipe(R.prop(R.__, payload), R.defaultTo(0)))
        )
      : {};
    return Map<Weekday, number>(map);
  },
  serialize: record =>
    R.zipObj(
      apiWeekdayKeys,
      WEEKDAYS.map(day => record.get(day))
    ),
};

export type TimesRecord = Map<Weekday, number>;

const setWeekday = R.curry((timesRecord, day, value) =>
  timesRecord.set(day, value)
);

type TimeSettings = {
  timeRanges: TimesRecord;
  quotas: TimesRecord;
  usage: Map<any, any>;
  isMultiDevices: boolean;
  isDailyLimit: boolean;
  isLockNavigation: boolean;
  isLockComputer: boolean;
  isReportAlerts: boolean;
};
export type TimeSettingsRecord = Record<TimeSettings>;
export const TimeSettingsRecord = Record<
  TimeSettings,
  {
    fromPayload: Record.Parser<any, TimeSettingsRecord>;
    serialize: Record.Serializer<TimeSettingsRecord, any>;
  }
>({
  timeRanges: TimesRecord.fromPayload(),
  quotas: TimesRecord.fromPayload(),
  usage: Map(),
  isMultiDevices: false,
  isDailyLimit: false,
  isLockNavigation: false,
  isLockComputer: false,
  isReportAlerts: false,
});

TimeSettingsRecord.fromPayload = payload => {
  const {
    timeRanges,
    quotas,
    usage,
    isMultiDevices,
    isDailyLimit,
    isWeekendQuota,
    isWeekdaysQuota,
    isLockNavigation,
    isLockComputer,
    isReportAlerts,
  } = payload;

  /**
   * API has weird format. If isWeekendQuota is true, then "sat" and "sun" have
   * a value of 120. If isWeekdaysQuota all other days also have that value.
   * The flags are redundant (from the perspective of the app), so we simple use
   * said flags to set the corresponding days to zero hours restriction.
   * The same applies for isWeekdaysQuota flag.
   * The reverse must be done when serializing the records for API.
   */
  const resetDays = (
    R.defaultTo(true, isWeekdaysQuota) ? [] : WEEKDAYS_WORKING
  ).concat(isWeekendQuota ? ([] as Weekday[]) : WEEKDAYS_WEEKEND);
  const quotasRecord = resetDays.length
    ? resetDays.reduce(
        setWeekday(R.__, R.__, COMPLETE_DAY_IN_MINUTES),
        TimesRecord.fromPayload(quotas)
      )
    : TimesRecord.fromPayload(quotas);
  return TimeSettingsRecord({
    isMultiDevices,
    isDailyLimit,
    isLockNavigation,
    isLockComputer,
    isReportAlerts,
    timeRanges: TimesRecord.fromPayload(timeRanges),
    quotas: quotasRecord,
    usage: Map(usage),
  });
};

TimeSettingsRecord.serialize = record => {
  const {
    timeRanges,
    quotas,
    isMultiDevices,
    isDailyLimit,
    isLockNavigation,
    isLockComputer,
    isReportAlerts,
  } = record;
  const isWeekendQuota = true;
  const isWeekdaysQuota = true;
  return {
    timeRanges: TimesRecord.serialize(timeRanges),
    quotas: TimesRecord.serialize(quotas),
    isWeekendQuota,
    isWeekdaysQuota,
    isMultiDevices,
    isDailyLimit,
    isLockNavigation,
    isLockComputer,
    isReportAlerts,
  };
};

type VideoRule = {
  ids: Map<string, VideoActions>;
};

export type VideoRuleRecord = Record<VideoRule>;
export const VideoRuleRecord = Record<
  VideoRule,
  {
    fromPayload: Record.Parser<any, VideoRuleRecord>;
    serialize: Record.Serializer<VideoRuleRecord, any>;
  }
>({
  ids: Map<string, VideoActions>(),
});

VideoRuleRecord.fromPayload = ({ ids }) =>
  VideoRuleRecord({
    ids: Map<string, VideoActions>(ids),
  });

VideoRuleRecord.serialize = ({ ids }) => ({
  ids: ids.toJS(),
});

type VideoRules = {
  youtube: VideoRuleRecord;
};
export type VideoRulesRecord = Record<VideoRules>;
export const VideoRulesRecord = Record<
  VideoRules,
  {
    fromPayload: Record.Parser<any, VideoRulesRecord>;
    serialize: Record.Serializer<VideoRulesRecord, any>;
  }
>({
  youtube: VideoRuleRecord(),
});

VideoRulesRecord.fromPayload = ({ youtube }) =>
  VideoRulesRecord({
    youtube: youtube ? VideoRuleRecord.fromPayload(youtube) : VideoRuleRecord(),
  });

VideoRulesRecord.serialize = ({ youtube }) => ({
  youtube: VideoRuleRecord.serialize(youtube),
});

type WebRules = {
  isCategoryList: boolean;
  isAllowUnknownSites: boolean;
  isSafeSearch: boolean;
  isReportBlockedSites: boolean;
  isSearchAlertsNotificationsEnabled: boolean;
  categories: List<WebCategoryRecord>;
  domains: Map<string, WebfilterAction>;
};
export type WebRulesRecord = Record<WebRules>;
export const WebRulesRecord = Record<
  WebRules,
  {
    fromPayload: Record.Parser<any, WebRulesRecord>;
    serialize: Record.Serializer<WebRulesRecord, any>;
  }
>({
  isCategoryList: false,
  isAllowUnknownSites: false,
  isSafeSearch: true,
  isReportBlockedSites: true,
  isSearchAlertsNotificationsEnabled: false,
  categories: List(),
  domains: Map<string, WebfilterAction>(),
});

WebRulesRecord.fromPayload = ({
  isCategoryList,
  isAllowUnknownSites,
  isSafeSearch,
  isReportBlockedSites,
  isSearchAlertsNotificationsEnabled,
  categories,
  domains,
}) =>
  WebRulesRecord({
    isCategoryList,
    isAllowUnknownSites,
    isSafeSearch,
    isReportBlockedSites,
    isSearchAlertsNotificationsEnabled,
    categories: List(categories).map(WebCategoryRecord.fromPayload),
    domains: webfilterDomainActionsToSymbols(domains),
  });

WebRulesRecord.serialize = ({
  isCategoryList,
  isAllowUnknownSites,
  isSafeSearch,
  isReportBlockedSites,
  isSearchAlertsNotificationsEnabled,
  categories,
  domains,
}) => ({
  isCategoryList,
  isAllowUnknownSites,
  isSafeSearch,
  isReportBlockedSites,
  isSearchAlertsNotificationsEnabled,
  categories: categories.map(WebCategoryRecord.serialize).toJS(),
  domains: webfilterDomainActionsToStrings(domains),
});

type WebCategory = {
  category: number;
  action: WebfilterAction | null;
};
export type WebCategoryRecord = Record<WebCategory>;
export const WebCategoryRecord = Record<
  WebCategory,
  Record.Payloads<WebCategoryRecord>
>({
  category: -1,
  action: null,
});

const inverseMapWebCategoryAction = (
  action: WebfilterActionKeys
): WebfilterAction =>
  R.prop(action, R.zipObj(WEBFILTER_ACTION_KEYS, WEBFILTER_ACTIONS)) as any;

WebCategoryRecord.fromPayload = payload =>
  WebCategoryRecord({
    category: payload.category,
    action: inverseMapWebCategoryAction(payload.action),
  });

const mapWebCategoryAction = (action: WebfilterAction): WebfilterActionKeys =>
  R.prop(action, R.zipObj(WEBFILTER_ACTIONS, WEBFILTER_ACTION_KEYS)) as any;

WebCategoryRecord.serialize = record => ({
  category: record.category,
  action: record.action ? mapWebCategoryAction(record.action) : undefined,
});

const webfilterDomainActionsToSymbols = (domains: {
  [index: string]: number;
}): Map<string, WebfilterAction> =>
  Map<string, number>(domains).reduce(
    (acc, index, domain) =>
      acc.set(domain, WEBFILTER_DOMAIN_ACTIONS[index] as any),
    Map({})
  );

const webfilterDomainActionsToStrings = (
  record: Map<string, WebfilterAction>
): {
  [index: string]: number;
} => record.map(action => WEBFILTER_DOMAIN_ACTIONS.indexOf(action)).toJS();

type AppRules = {
  isApplicationList: boolean;
  applicationList: List<AppRuleRecord>;
};

export type AppRulesRecord = Record<AppRules>;
export const AppRulesRecord = Record<
  AppRules,
  {
    fromPayload: Record.Parser<any, AppRulesRecord>;
    serialize: Record.Serializer<AppRulesRecord, any>;
  }
>({
  isApplicationList: false,
  applicationList: List(),
});

const APP_RULES_WEEKDAYS_KEYS = [
  'monQuota',
  'tueQuota',
  'wedQuota',
  'thrQuota',
  'friQuota',
  'satQuota',
  'sunQuota',
];

AppRulesRecord.fromPayload = payload =>
  AppRulesRecord({
    isApplicationList: payload.isApplicationList,
    applicationList: List(payload.applicationList).map(
      AppRuleRecord.fromPayload
    ),
  });

AppRulesRecord.serialize = record => ({
  isApplicationList: record.isApplicationList,
  applicationList: record.applicationList.toJS().map(AppRuleRecord.serialize),
});

export type AppRule = {
  id: string | null;
  exe: string;
  name: string;
  action: ApplicationAction;
  platform: Platform;
  quotas: Map<Weekday, number>;
  thumbnail: string;
  blockable: boolean;
  isUnsupportedBrowser: boolean;
  ageRating: number | null;
  categoryId: string;
  appInstalledAt: DatetimeISO6801 | null;
};

export type AppRuleRecord = Record<AppRule>;
export const AppRuleRecord = Record<AppRule, Record.Payloads<AppRuleRecord>>({
  id: null,
  exe: '',
  name: '',
  action: APPLICATION_ALLOWED,
  platform: mapPlatform(undefined),
  quotas: Map<Weekday, number>(
    R.zip(WEEKDAYS, R.map(R.always(1440), WEEKDAYS))
  ),
  thumbnail: iconAppDefault,
  blockable: true,
  isUnsupportedBrowser: false,
  ageRating: 0,
  categoryId: 'Other',
  appInstalledAt: null,
});

AppRuleRecord.fromPayload = payload =>
  AppRuleRecord({
    id: payload.id,
    exe: payload.exe,
    name: payload.name || payload.exe,
    action:
      typeof payload.action === 'number'
        ? APPLICATION_ACTIONS[payload.action]
        : APPLICATION_ALLOWED,
    platform: mapPlatform(payload.platform),
    thumbnail: payload.thumbnail || iconAppDefault,
    quotas: Map<Weekday, number>(
      R.zip(
        WEEKDAYS,
        R.map(R.pipe(R.prop(R.__, payload) as any, R.defaultTo(1440)))(
          APP_RULES_WEEKDAYS_KEYS
        )
      )
    ),
    blockable: payload.blockable !== false,
    isUnsupportedBrowser: payload.isUnsupportedBrowser,
    ageRating: payload.ageRating,
    categoryId: payload.categoryId,
    appInstalledAt: payload.appInstalledAt,
  });

AppRuleRecord.serialize = record =>
  R.merge(
    {
      id: record.id,
      exe: record.exe,
      name: record.name,
      action: R.indexOf(record.action, APPLICATION_ACTIONS),
      platform: inverseMapPlatform(record.platform),
      thumbnail: record.thumbnail,
      blockable: record.blockable,
      isUnsupportedBrowser: record.isUnsupportedBrowser,
      ageRating: record.ageRating,
      categoryId: record.categoryId,
      appInstalledAt: record.appInstalledAt,
    },
    R.zipObj(
      APP_RULES_WEEKDAYS_KEYS,
      R.map(
        R.pipe(
          R.prop(R.__, record.quotas) as any,
          R.ifElse(R.equals(1440), R.always(null), R.identity)
        ),
        WEEKDAYS
      ) as any
    )
  );

export const makeAppKey = (
  app: AppRuleRecord | AppActivityRecord | PolicyApplication
): string => app.exe + inverseMapPlatform(app.platform);

type UnsupportedBrowsers = {
  isBlockedUnsupportedBrowsers: boolean;
  browsersList: List<UnsupportedBrowserRecord>;
};

export type UnsupportedBrowsersRecord = Record<UnsupportedBrowsers>;
export const UnsupportedBrowsersRecord = Record<
  UnsupportedBrowsers,
  {
    fromPayload: Record.Parser<UnsupportedBrowsers, UnsupportedBrowsersRecord>;
    serialize: Record.Serializer<
      UnsupportedBrowsersRecord,
      UnsupportedBrowsers
    >;
  }
>({
  isBlockedUnsupportedBrowsers: false,
  browsersList: List(),
});

UnsupportedBrowsersRecord.fromPayload = payload =>
  UnsupportedBrowsersRecord({
    isBlockedUnsupportedBrowsers: payload.isBlockedUnsupportedBrowsers,
    browsersList: List(payload.browsersList).map(
      UnsupportedBrowserRecord.fromPayload
    ),
  });

UnsupportedBrowsersRecord.serialize = record => ({
  isBlockedUnsupportedBrowsers: record.isBlockedUnsupportedBrowsers,
  browsersList: record.browsersList
    .toJS()
    .map(UnsupportedBrowserRecord.serialize),
});

type UnsupportedBrowser = {
  name: string;
  androidName: string;
  osxName: string;
  windowsName: string;
  iosName: string;
  chromebookName: string;
};

export type UnsupportedBrowserRecord = Record<UnsupportedBrowser>;
export const UnsupportedBrowserRecord = Record<
  UnsupportedBrowser,
  Record.Payloads<UnsupportedBrowserRecord>
>({
  name: '',
  androidName: '',
  osxName: '',
  windowsName: '',
  iosName: '',
  chromebookName: '',
});

UnsupportedBrowserRecord.fromPayload = payload =>
  UnsupportedBrowserRecord({
    name: payload.name,
    androidName: payload.androidName,
    osxName: payload.osxName,
    windowsName: payload.windowsName,
    iosName: payload.iosName,
    chromebookName: payload.chromebookName,
  });

UnsupportedBrowserRecord.serialize = record => ({
  name: record.name,
  androidName: record.androidName,
  osxName: record.osxName,
  windowsName: record.windowsName,
  iosName: record.iosName,
  chromebookName: record.chromebookName,
});

type LocationRules = {
  enabled: boolean;
  locationUpdateFrequency: number;
};
export type LocationRulesRecord = Record<LocationRules>;
export const LocationRulesRecord = Record<LocationRules, {}>({
  enabled: false,
  locationUpdateFrequency: 0,
});

type PanicRules = {
  enabled: boolean;
  contacts: List<ContactRecord>;
  accountHolder: Readonly<{
    name: string;
    contact: string;
  }>;
};
export type PanicRulesRecord = Record<PanicRules>;
export const PanicRulesRecord = Record<
  PanicRules,
  {
    fromPayload: Record.Parser<any, PanicRulesRecord>;
    serialize: Record.Serializer<PanicRulesRecord, any>;
  }
>({
  enabled: false,
  contacts: List(),
  accountHolder: {
    name: '',
    contact: '',
  },
});

PanicRulesRecord.fromPayload = payload =>
  PanicRulesRecord({
    ...payload,
    enabled: Boolean(payload.mode),
    contacts: List(payload.contacts.map(ContactRecord.fromPayload)),
  });

PanicRulesRecord.serialize = record => ({
  mode: Number(record.enabled),
  contacts: record.contacts.map(ContactRecord.serialize).toJS(),
  accountHolder: record.accountHolder,
});

type CallsSmsContact = {
  name: string;
  phone: string;
  mode: CallSmsRulesAction;
};
export type CallsSmsContactRecord = Record<CallsSmsContact>;
export const CallsSmsContactRecord = Record<
  CallsSmsContact,
  {
    fromPayload: Record.Parser<any, CallsSmsContactRecord>;
    serialize: Record.Serializer<CallsSmsContactRecord, any>;
  }
>({
  name: '',
  phone: '',
  mode: CALLSMS_RULES_ALLOW,
});

CallsSmsContactRecord.fromPayload = payload =>
  CallsSmsContactRecord({
    ...payload,
    mode: R.nth(payload.mode, CALLSMS_CONTACT_ACTIONS),
  });

CallsSmsContactRecord.serialize = record => ({
  name: record.name,
  phone: record.phone,
  mode: R.indexOf(record.mode, CALLSMS_CONTACT_ACTIONS),
});

type CallSmsMonitoring = {
  enabled: boolean;
  monitorSmsContent: boolean;
  incomingCallsBlock: boolean;
  outgoingCallsBlock: boolean;
  incomingSmsBlock: boolean;
  contactBlock: List<CallsSmsContactRecord>;
};
export type CallSmsMonitoringRecord = Record<CallSmsMonitoring>;
export const CallSmsMonitoringRecord = Record<
  CallSmsMonitoring,
  {
    fromPayload: Record.Parser<any, CallSmsMonitoringRecord>;
    serialize: Record.Serializer<CallSmsMonitoringRecord, any>;
  }
>({
  enabled: false,
  monitorSmsContent: false,
  incomingCallsBlock: false,
  outgoingCallsBlock: false,
  incomingSmsBlock: false,
  contactBlock: List(),
});

CallSmsMonitoringRecord.fromPayload = payload =>
  CallSmsMonitoringRecord({
    ...payload,
    contactBlock: List(
      payload.contactBlock.map(CallsSmsContactRecord.fromPayload)
    ),
  });

export const updateContactList = R.ifElse(
  R.equals(CALLSMS_RULES_REMOVE),
  () => contact => list =>
    list.delete(list.findKey(R.propEq('phone', contact.phone))),
  option => contact => list =>
    list.update(
      list.findKey(R.propEq('phone', contact.phone)),
      R.always(contact.set('mode', option))
    )
);

CallSmsMonitoringRecord.serialize = record => ({
  enabled: record.enabled,
  monitorSmsContent: record.monitorSmsContent,
  incomingCallsBlock: record.incomingCallsBlock,
  outgoingCallsBlock: record.outgoingCallsBlock,
  incomingSmsBlock: record.incomingSmsBlock,
  contactBlock: record.contactBlock
    .map(CallsSmsContactRecord.serialize)
    .toArray(),
});

type SocialMonitoring = {
  enabled: boolean;
  facebookConnected: boolean;
};
export type SocialMonitoringRecord = Record<SocialMonitoring>;
export const SocialMonitoringRecord = Record<
  SocialMonitoring,
  {
    fromPayload: Record.Parser<any, SocialMonitoringRecord>;
    serialize: Record.Serializer<SocialMonitoringRecord, any>;
  }
>({
  enabled: false,
  facebookConnected: false,
});

SocialMonitoringRecord.fromPayload = payload =>
  SocialMonitoringRecord({
    enabled: payload.isSocialInspection,
    facebookConnected:
      payload.facebookAppUser && payload.facebookAppUser.connected,
  });

SocialMonitoringRecord.serialize = record => ({
  isSocialInspection: record.enabled,
  facebookAppUser: {
    connected: record.facebookConnected,
  },
});

export type ProfileRules = {
  profile: number;
  timeRestrictions: TimeSettingsRecord;
  web: WebRulesRecord;
  unsupportedBrowsers: UnsupportedBrowsersRecord;
  appRules: AppRulesRecord;
  location: LocationRulesRecord;
  panic: PanicRulesRecord;
  callSmsMonitoring: CallSmsMonitoringRecord;
  socialMonitoring: SocialMonitoringRecord;
  videos: VideoRulesRecord;
  isAlertNewApps: boolean;
};

export type ProfileRulesRecord = Record<ProfileRules>;
export const ProfileRulesRecord = Record<
  ProfileRules,
  {
    fromPayload: Record.Parser<any, ProfileRulesRecord>;
    serialize: Record.Serializer<ProfileRulesRecord, any>;
  }
>({
  profile: 0,
  timeRestrictions: TimeSettingsRecord(),
  web: WebRulesRecord(),
  unsupportedBrowsers: UnsupportedBrowsersRecord(),
  appRules: AppRulesRecord(),
  location: LocationRulesRecord(),
  panic: PanicRulesRecord(),
  callSmsMonitoring: CallSmsMonitoringRecord(),
  socialMonitoring: SocialMonitoringRecord(),
  videos: VideoRulesRecord(),
  isAlertNewApps: true,
});

ProfileRulesRecord.fromPayload = payload =>
  ProfileRulesRecord({
    profile: payload.profile,
    timeRestrictions: TimeSettingsRecord.fromPayload(payload.timeRestrictions),
    web: WebRulesRecord.fromPayload(payload.web),
    unsupportedBrowsers: UnsupportedBrowsersRecord.fromPayload(
      payload.unsupportedBrowsers
    ),
    appRules: AppRulesRecord.fromPayload(payload.appRules),
    location: LocationRulesRecord(payload.location),
    panic: PanicRulesRecord.fromPayload(payload.panic),
    callSmsMonitoring: CallSmsMonitoringRecord.fromPayload(
      payload.callSmsMonitoring
    ),
    socialMonitoring: SocialMonitoringRecord.fromPayload(payload),
    videos: payload.videos
      ? VideoRulesRecord.fromPayload(payload.videos)
      : VideoRulesRecord(),
    isAlertNewApps: payload.isAlertNewApps,
  });

ProfileRulesRecord.serialize = record => {
  const {
    profile,
    timeRestrictions,
    web,
    unsupportedBrowsers,
    appRules,
    location,
    panic,
    callSmsMonitoring,
    socialMonitoring,
    videos,
    isAlertNewApps,
  } = record;
  return {
    profile,
    timeRestrictions: TimeSettingsRecord.serialize(timeRestrictions),
    web: WebRulesRecord.serialize(web),
    unsupportedBrowsers:
      UnsupportedBrowsersRecord.serialize(unsupportedBrowsers),
    appRules: AppRulesRecord.serialize(appRules),
    location: getLocaton(location),
    panic: PanicRulesRecord.serialize(panic),
    callSmsMonitoring: CallSmsMonitoringRecord.serialize(callSmsMonitoring),
    ...SocialMonitoringRecord.serialize(socialMonitoring),
    videos: VideoRulesRecord.serialize(videos),
    isAlertNewApps,
  };
};

export const DEFAULT_WEB_CATEGORIES = List([
  {
    category: 1,
    action: 'BLOCK',
  },
  {
    category: 3,
    action: 'BLOCK',
  },
  {
    category: 6,
    action: 'ALLOW',
  },
  {
    category: 7,
    action: 'BLOCK',
  },
  {
    category: 12,
    action: 'BLOCK',
  },
  {
    category: 14,
    action: 'BLOCK',
  },
  {
    category: 15,
    action: 'BLOCK',
  },
  {
    category: 17,
    action: 'ALLOW',
  },
  {
    category: 18,
    action: 'ALLOW',
  },
  {
    category: 19,
    action: 'ALLOW',
  },
  {
    category: 20,
    action: 'ALLOW',
  },
  {
    category: 21,
    action: 'BLOCK',
  },
  {
    category: 23,
    action: 'BLOCK',
  },
  {
    category: 24,
    action: 'BLOCK',
  },
  {
    category: 29,
    action: 'BLOCK',
  },
]).map(WebCategoryRecord.fromPayload);

export const updateContact = (contact, option) => profileRules =>
  profileRules.updateIn(
    ['callSmsMonitoring', 'contactBlock'],
    updateContactList(option)(contact)
  );

const getLocaton = record => {
  const { enabled, locationUpdateFrequency } = record;
  return { enabled, locationUpdateFrequency };
};

export type AppNameWithPlatform = PrivateNominalType<
  string,
  'AppNameWithPlatform'
>;
export const getAppNameWithPlatform = (appName: string, platform: Platform) => {
  return `${appName}_${platform}` as AppNameWithPlatform;
};

export const getAppIcons = (record: ProfileRulesRecord) => {
  return record.appRules.applicationList.reduce((appsMap, app) => {
    return appsMap.set(
      getAppNameWithPlatform(app.exe, app.platform),
      app.thumbnail
    );
  }, Map<AppNameWithPlatform, string>());
};

export default ProfileRulesRecord;
