import { Record } from 'immutable';
import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import { Policy, PolicyPayload, policyBlockType } from './types/Policy.types';
import { PolicyApplicationRulesOperations } from './policyApplicationRules';
import { PolicyWebCategoryRulesOperations } from './policyWebCategoryRules';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../../api/mappings/valueRestrictions';

export const policyBlockTypePayloadToRecord = (
  blockType?: PolicyPayload['block_type']
) => restrictKeyFromValue(blockType, policyBlockType);

export const policyBlockTypeRecordToPayload = (
  blockType?: Policy['blockType']
) => restrictValueFromKey(blockType, policyBlockType);

export const PolicyOperations = getRecordOperations<Policy, PolicyPayload>({
  defaultFields: {
    countsTowardsDailyTimeQuota: false,
    blockType: 'POLICY_BLOCK_TYPE_NONE',
    appRules: Record({}),
    webRules: Record({}),
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    blockType: policyBlockTypePayloadToRecord(payload?.block_type),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    block_type: policyBlockTypeRecordToPayload(record?.blockType),
  }),
  recordDependencies: [
    {
      field: 'appRules',
      payloadField: 'app_rules',
      dependencyRecordOperations: PolicyApplicationRulesOperations,
    },
    {
      field: 'webRules',
      payloadField: 'web_rules',
      dependencyRecordOperations: PolicyWebCategoryRulesOperations,
    },
  ],
});
