import * as React from 'react';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import familyActivitiesImg from '../../../assets/base/images/wellbeing/wellbeing-activities-big.png';
import { t } from '../../../lib/i18n';
import WellbeingFeaturePage from './WellbeingFeaturePage';
import WellbeingFeatureGetStartedSection from './WellbeingFeatureGetStartedSection';
import FamilyActivitiesListSection from './FamilyActivitiesListSection';
import FamilyActivitiesMakeRoomSection from './FamilyActivitiesMakeRoomSection';

interface FamilyActivitiesProps {
  weeklyPrice: string;
  onClickButton: () => void;
  isDisabledButton?: boolean;
}

const FamilyActivities = ({
  weeklyPrice,
  onClickButton,
  isDisabledButton = false,
}: FamilyActivitiesProps) => (
  <RenderWhen screenSize={[ScreenSize.Desktop, ScreenSize.MobileOrTablet]}>
    {screenSize => (
      <WellbeingFeaturePage colorScheme="green">
        <WellbeingFeatureGetStartedSection
          screenSize={screenSize}
          weeklyPrice={weeklyPrice}
          title={t('Start your new {1}favorite family ritual{/1}', {
            1: (str: string) => (
              <span className="par-wellbeing-feature__title-highlighted">
                {str}
              </span>
            ),
          })}
          description={t(
            'Boost your family’s wellbeing with weekly activities to enjoy together. Created by experts, they’ll help your whole family experience quality time away from screens.'
          )}
          image={
            <img
              src={familyActivitiesImg}
              alt=""
              width={isDesktop(screenSize) ? 293 : 198}
              height={isDesktop(screenSize) ? 254 : 171.6}
            />
          }
          buttonText={t('Subscribe now')}
          onClickButton={onClickButton}
          isDisabledButton={isDisabledButton}
        />
        <FamilyActivitiesListSection screenSize={screenSize} />
        <FamilyActivitiesMakeRoomSection
          isDisabledButton={isDisabledButton}
          onClickButton={onClickButton}
        />
      </WellbeingFeaturePage>
    )}
  </RenderWhen>
);

export default FamilyActivities;
