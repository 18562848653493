import { connect } from 'react-redux';
import { getIsAlertNewApps, getLicense } from '../../../selectors';

import { onToggleAppRulesSetting } from '../../../actions/AppRulesActions';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import AppRulesSettingsPage from '../../../components/AppRules/AppRulesSettingsAndNotificationsPage';
import { isSettingsToggleBlocked } from '../../../helpers/premiumFeatures/isSettingsToggleBlocked';
import State, { BaseThunk } from '../../../store/state';
import { redirectToAddDeviceFlyover } from '../../../actions/redirectAddDeviceFlyover';
import { getFeatures } from '../../../ducks/features';
import { isSchoolLicenseWithValidDevices } from '../../../businessLogic/profileRules/delegation';

const mapStateToProps = (state: State, { params: { profileId } }) => {
  const license = getLicense(state);
  const features = getFeatures(state);

  return {
    isSettingTogglePremium: isSettingsToggleBlocked(license.subtype, features),
    isAlertNewApps: getIsAlertNewApps(state, profileId) as boolean,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onToggleAppRulesSetting: (target, toggleOn) =>
    dispatch(onToggleAppRulesSetting(profileId, target, toggleOn)),
});

const AppRulesSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRulesSettingsPage);

AppRulesSettingsContainer.redirect =
  ({ profileId }): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const allowAccessToRulesPage = isSchoolLicenseWithValidDevices(
      state,
      profileId
    );

    if (allowAccessToRulesPage) return;
    dispatch(
      redirectToAddDeviceFlyover(
        profileId,
        `/profiles/${profileId}/rules/appRules-settings/addDeviceFlyover`
      )
    ).then(redirectedToAddDevice => {
      if (redirectedToAddDevice) return;
      dispatch(
        redirectFreeToPremiumAction(profileId, 'rules/appRules/premiumFlyover')
      );
    });
  };

export default AppRulesSettingsContainer;
