import * as React from 'react';

import {
  FlexLayout,
  FreeText,
  Icon,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';

import { DeviceStatus } from '../../../businessLogic/devices';
import {
  convertDate,
  formatDateNiceShort,
  formatDateToWeekdayAndDay,
  formatHumanTwelveClockFormat,
  isTodayDatetime,
} from '../../../helpers/dates';
import { t } from '../../../lib/i18n';
import { classNames } from '../../../helpers';
import type { NavigateToDeviceInfoModal } from '../../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';

const getIconType = (
  status: DeviceStatus['status'],
  type: DeviceStatus['type']
) => {
  if (status === 'tampered') return IconType.exclamationCircle;
  if (
    status === 'schoolHours' ||
    status === 'safeNetwork' ||
    status === 'offline' ||
    status === 'disabled'
  )
    return IconType.infoCircle;
  if (type === 'PC' || type === 'LAPTOP') return IconType.laptop;
  if (type === 'MOBILE' || status === 'unknown') return IconType.mobile;
  throw new Error(`getIconType can't get the correct icon`);
};

const getTextClassName = (status: DeviceStatus['status']) =>
  `par-device-status-card__text__${status}`;

const getIconClassName = (status: DeviceStatus['status']) =>
  `par-device-status-card__icon__${status}`;

const getText = (
  status: DeviceStatus['status'],
  lastseen: DeviceStatus['lastseen'],
  timezone: string,
  locale: string
) => {
  if (status === 'lastActivity' && lastseen)
    return t('Last activity {{hours}}', {
      hours: isTodayDatetime(convertDate(lastseen, timezone))
        ? formatHumanTwelveClockFormat(lastseen, timezone, locale)
        : formatDateToWeekdayAndDay(lastseen, timezone, locale),
    });
  if (status === 'offline')
    return t('Last activity {{hours}}', {
      hours: formatDateNiceShort(lastseen, timezone, locale),
    });
  if (status === 'paused') return t('Paused');
  if (status === 'locked') return t('Locked');
  if (status === 'online') return t('Online');
  if (status === 'schoolHours') return t('School hours');
  if (status === 'tampered') return t('Tampered');
  if (status === 'disabled') return t('Unprotected');
  if (status === 'safeNetwork') return t('On Safe Network');
  if (status === 'outsideSchoolHours') return t('Outside school hours');
  if (status === 'unknown') return t('unknown');
};

const statusWithModal: Array<DeviceStatus['status']> = [
  'disabled',
  'offline',
  'safeNetwork',
  'schoolHours',
];

const isIconModal = (status: DeviceStatus['status']): boolean => {
  return statusWithModal.includes(status);
};

interface DeviceStatusCardProps extends DeviceStatus {
  timezone: string;
  locale: string;
  navigateToInfoModal: NavigateToDeviceInfoModal;
}
interface DevicesIconsProps {
  status: DeviceStatus['status'];
  type: DeviceStatus['type'];
  affiliation: DeviceStatus['affiliation'];
  id: number | null;
  navigateToInfoModal: NavigateToDeviceInfoModal;
}
const getDeviceStatusIcon = ({
  status,
  type,
  affiliation,
  id,
  navigateToInfoModal,
}: DevicesIconsProps) => {
  const hasModal = isIconModal(status);

  return (
    <Icon
      type={getIconType(status, type)}
      size={IconSize.lg}
      family={IconFamily.regular}
      className={classNames(
        getIconClassName(status),
        hasModal ? 'par-device-status-card__icon--clickable' : ''
      )}
      onClick={
        hasModal
          ? () => navigateToInfoModal({ status, affiliation, id })
          : undefined
      }
      testId="device-status-card__device-icon"
    />
  );
};

const DeviceStatusCard = ({
  name,
  lastseen,
  status,
  type,
  affiliation,
  id,
  timezone,
  locale,
  navigateToInfoModal,
}: DeviceStatusCardProps) => {
  return (
    <FlexLayout
      mainaxis="row"
      marginBottom="8"
      marginTop="8"
      minWidth="200"
      className="par-device-status-card__container"
    >
      <FlexLayout mainaxis="row" mainaxisAlignment="center" marginRight="16">
        {getDeviceStatusIcon({
          status,
          type,
          affiliation,
          id,
          navigateToInfoModal,
        })}
      </FlexLayout>

      <FlexLayout mainaxis="column" mainaxisAlignment="center">
        <span className="par-device-status-card__text">{name}</span>
        <FreeText className={getTextClassName(status)}>
          {getText(status, lastseen, timezone, locale)}
        </FreeText>
      </FlexLayout>
    </FlexLayout>
  );
};

export default DeviceStatusCard;
