import * as moment from 'moment';
import State from '../store/state';
import { Datetime } from '../types/dates';
import { isDatetimeIsBefore } from '../helpers/dates';

// Student policy
export const getIsFetchingStudentPolicy = (state: State): boolean =>
  state.get('studentPolicies')?.get('isFetching');

export const getStudentPolicyLastUpdated = (state: State): number =>
  state.get('studentPolicies')?.get('lastUpdated');

export const getStudentPolicyError = (state: State) =>
  state.get('studentPolicies')?.get('studentPoliciesError');

// School times

export const getSchoolTimes = (state: State) =>
  state.get('records')?.get('schoolTimes');

export const getCurrentSchoolStartDatetime = (state: State) =>
  state.getIn(['records', 'schoolTimes', 'currentStartDatetime'], undefined)
    ? (moment(
        state.getIn(
          ['records', 'schoolTimes', 'currentStartDatetime'],
          undefined
        )
      ) as Datetime)
    : undefined;

export const getCurrentSchoolEndDatetime = (state: State) =>
  state.getIn(['records', 'schoolTimes', 'currentEndDatetime'], undefined)
    ? (moment(
        state.getIn(['records', 'schoolTimes', 'currentEndDatetime'], undefined)
      ) as Datetime)
    : undefined;

export const getNextSchoolStartDatetime = (state: State) =>
  state.getIn(['records', 'schoolTimes', 'nextStartDatetime'], undefined)
    ? (moment(
        state.getIn(['records', 'schoolTimes', 'nextStartDatetime'], undefined)
      ) as Datetime)
    : undefined;

export const getNextSchoolEndDatetime = (state: State) =>
  state.getIn(['records', 'schoolTimes', 'nextEndDatetime'], undefined)
    ? (moment(
        state.getIn(['records', 'schoolTimes', 'nextEndDatetime'], undefined)
      ) as Datetime)
    : undefined;

export const getSchoolStartEnd = (state: State) => {
  const endCurrentPeriodTime = getCurrentSchoolEndDatetime(state);
  const isCurrentPeriodFinished = endCurrentPeriodTime
    ? isDatetimeIsBefore(endCurrentPeriodTime)
    : true;
  const startPeriodDatetime = isCurrentPeriodFinished
    ? getNextSchoolStartDatetime(state)
    : getCurrentSchoolStartDatetime(state);
  const endPeriodDatetime = isCurrentPeriodFinished
    ? getNextSchoolEndDatetime(state)
    : endCurrentPeriodTime;

  return { start: startPeriodDatetime, end: endPeriodDatetime };
};

// Student pause

export const getStudentPause = (state: State) =>
  state.get('records')?.get('studentPause');

export const getIsPauseActive = (state: State): boolean | undefined =>
  state.getIn(['records', 'studentPause', 'active']);

export const getIsPauseEnabled = (state: State): boolean | undefined =>
  state.getIn(['records', 'studentPause', 'enabled']);

export const getIsPauseEnabled247 = (state: State): boolean | undefined =>
  state.getIn(['records', 'studentPause', 'pause247']);

export const getEndActivePause = (state: State): Datetime | undefined =>
  moment(state.getIn(['records', 'studentPause', 'endDatetime']));

// student delegation
export const isDelegationEnabled = (state: State): boolean =>
  state.getIn(['records', 'studentDelegation', 'enabled']);

export const isStudentOptInForDelegation = (state: State): boolean =>
  state.getIn(['records', 'studentDelegation', 'optedIn']);

export const isStudentOptInForDelegationForThisAccount = (
  state: State
): boolean =>
  state.getIn(['records', 'studentDelegation', 'optedInByThisAccount']);

export const getStudentDelegation = (state: State) =>
  state.get('records')?.get('studentDelegation');
