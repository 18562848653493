import { createSelector } from 'reselect';
import type { LicenseRecord } from '../../records';
import type State from '../../store/state';
import { LicenseSubtype } from '../../records/license/types/License.types';

export const getLicense = (state: State): LicenseRecord =>
  state.get('license').get('item');

export const getPurchaseProvider = (state: State) =>
  getLicense(state).purchase?.get('provider') ?? null;

export const getLicenseDurationDays = (state: State) =>
  getLicense(state).durationDays;

export const isPurchasedSubscriptionFromGooglePlay = (state: State): boolean =>
  getPurchaseProvider(state) === 'GooglePlay';

export const getLicenseSubtype = createSelector(
  getLicense,
  license => (license?.get('subtype') ?? license?.subtype) as LicenseSubtype
);

export const getEligibleForManualTrial = (state: State): boolean =>
  getLicense(state).isEligibleForManualTrial;

/**
 * Check if we should show the conversion elements for the current affiliate
 */
export const getShowClientConversionElements = (state: State): boolean =>
  getLicense(state).affiliate.get('showClientConversionElements');
