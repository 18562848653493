import * as camelize from 'camelize';
import { fromJS } from 'immutable';
import api from '../../api';
import {
  PaymentProvider,
  PAYMENT_PROVIDER_APPLE,
  PAYMENT_PROVIDER_CHARGEBEE,
} from '../../constants';
import { isiOSPlatform } from '../../helpers';
import { captureException } from '../../helpers/sentry';
import ft, { DISABLE_PURCHASES } from '../../lib/ft';
import * as qinit from '../../qinit';
import { ProductRecord, getProductType } from '../../records/product';
import { FetchProductsOptions } from './types/FetchProductsOptions.types';
import { NormalizedProducts } from './types/NormalizedProducts.types';
import {
  ProductApiPayload,
  ProductPayload,
  ProductCompountPayload,
  ProductToOfferApiPayload,
} from './types/ProductsReponse.types';
import {
  GetProductParams,
  GetProductsParams,
} from './types/ProductsRequest.types';
import State from '../../store/state';
import { getDirectPurchaseXsource } from '../directPurchase/selectors';

export const shouldFetchProducts = () => !ft.active(DISABLE_PURCHASES);

// No experiments running on Backend side
export const getExperimentParameter = () => undefined;

export const getXSourceParameter = (state: State) => {
  const xsource = getDirectPurchaseXsource(state);
  return xsource ?? 'parents-app-upgrade-page';
};

export const isProductWithDedicatedSupport = (
  product: ProductCompountPayload
) => {
  if (
    product.product.options !== undefined &&
    product.product.options.length > 0
  ) {
    const dedicatedSupportOption = product.product.options.find(
      option => option.key === 'dedicated_support'
    );
    if (dedicatedSupportOption) {
      return dedicatedSupportOption.value === 'True';
    }
  }
  return false;
};

export const fetchTwoYearProducts = async (
  response: ProductToOfferApiPayload,
  experiment?: string,
  xsource?: string
) => {
  try {
    const twoYearProductPromises = response.productList
      .map(({ product, productWithCoupon }) =>
        fetchTwoYearProduct(
          product.code,
          experiment,
          xsource,
          productWithCoupon?.coupon
        )
      )
      .filter(Boolean);

    const twoYearProducts = await Promise.all(twoYearProductPromises);

    return twoYearProducts.filter(
      product => product !== null
    ) as ProductCompountPayload[];
  } catch (e) {
    captureException(e);
    return [];
  }
};

export const fetchTwoYearProduct = async (
  productCode: string,
  experiment?: string,
  xsource?: string,
  coupon?: string
) => {
  try {
    const productResponse = await api.product.get({
      paymentProvider: PAYMENT_PROVIDER_CHARGEBEE,
      productCode,
      duration: true,
      experiment,
      xsource,
      coupon,
    });
    const product = camelize(productResponse) as ProductApiPayload;

    // We assume the first product is the two year upsell
    const twoYearProduct =
      Array.isArray(product.upsellProductsDuration) &&
      product.upsellProductsDuration[0]
        ? { product: product.upsellProductsDuration[0] }
        : null;

    return twoYearProduct as ProductCompountPayload;
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const normalizeProducts = (
  response: ProductToOfferApiPayload,
  paymentProvider: PaymentProvider
): NormalizedProducts => {
  const { defaultProduct, productList } = response;
  const records = {
    products: {},
  };
  const result: string[] = [];
  if (productList.length) {
    productList.forEach(item => {
      const productCode = item.productWithDiscount
        ? item.productWithDiscount.code
        : item.product.code;

      const addEnvIfNeeded = (productCode: string) =>
        paymentProvider === PAYMENT_PROVIDER_APPLE
          ? `${productCode}.${qinit.env}`
          : productCode;
      const isDedicatedSupport = isProductWithDedicatedSupport(item);
      // Apple do not allow for different apps the same product code name
      // so we use this prefix to differentiate for the store. Also simplifies product management from our
      // adm (for us .par and without it is the same product - same platform, same price)
      // See: https://qustodio.atlassian.net/wiki/spaces/IOS/pages/1851654145/iOS+in-app+Purchases+configuration
      const addBundleSuffixIfNeeded = (productCode: string) =>
        isiOSPlatform() ? `${productCode}.par` : productCode;

      const code = addBundleSuffixIfNeeded(addEnvIfNeeded(productCode));
      const product = ProductRecord({
        ...item.product,
        type: getProductType(item.product.label),
        ...(item.productWithCoupon
          ? {
              amountWithDiscount: item.productWithCoupon.amount,
              discount: item.productDiscount,
              coupon: item.productWithCoupon.coupon,
              url: item.productWithCoupon.url,
            }
          : {}),
        ...(item.productWithDiscount
          ? {
              amountWithDiscount: item.productWithDiscount.amount,
              discount: item.productDiscount,
            }
          : {}),
        code,
        isDedicatedSupport,
        licenseSubtype: item.product.licenseSubType,
      });
      records.products[code] = product;
      result.push(code);
    });
  }

  const defaultProductCode = defaultProduct.productWithDiscount
    ? defaultProduct.productWithDiscount.code
    : defaultProduct.product.code;
  const defaultProviderProductCode =
    paymentProvider === PAYMENT_PROVIDER_APPLE
      ? `${defaultProductCode}.${qinit.env}`
      : defaultProductCode;
  return {
    defaultProductCode: defaultProviderProductCode,
    result: fromJS(result),
    records,
  };
};

export const fetchProductsToOffer = async (
  params: GetProductsParams,
  options: FetchProductsOptions
): Promise<ProductToOfferApiPayload> => {
  const { requestTwoYears } = options;
  const productsResponse = await api.products.get(params);
  const products = camelize(productsResponse) as ProductToOfferApiPayload;
  if (
    params.paymentProvider === PAYMENT_PROVIDER_CHARGEBEE &&
    requestTwoYears
  ) {
    const twoYearsProducts = await fetchTwoYearProducts(
      products,
      params?.experiment,
      params?.xsource
    );

    return {
      defaultProduct: products.defaultProduct,
      productList: [...products.productList, ...twoYearsProducts],
    };
  }
  return products;
};

export const fetchProduct = async (
  params: GetProductParams,
  options: FetchProductsOptions
): Promise<ProductToOfferApiPayload> => {
  const { requestTwoYears } = options;
  const productResponse = await api.product.get(params);
  const product = camelize(productResponse) as ProductApiPayload;
  const products = mapProductToProductsToOffer(product);
  if (
    params.paymentProvider === PAYMENT_PROVIDER_CHARGEBEE &&
    requestTwoYears
  ) {
    const twoYearsProducts = await fetchTwoYearProducts(
      products,
      params.experiment,
      params.xsource
    );
    return {
      defaultProduct: products.defaultProduct,
      productList: [...products.productList, ...twoYearsProducts],
    };
  }
  return products;
};

// Map the product endpoint response to productToOffer response
export const mapProductToProductsToOffer = (
  product: ProductApiPayload
): ProductToOfferApiPayload => {
  const { upsellProductsSize, ...currentProduct } = product;
  currentProduct.current = true;

  const productList: ProductCompountPayload[] = [];
  productList.push({ product: currentProduct });
  if (upsellProductsSize instanceof Array) {
    upsellProductsSize.forEach(product => {
      productList.push({ product });
    });
  }
  return {
    defaultProduct: { product: currentProduct },
    productList,
  };
};

export const mergeProductsToOffer = (
  productToOfferA: ProductToOfferApiPayload,
  ProductToOfferB: ProductToOfferApiPayload
) => ({
  defaultProduct: productToOfferA.defaultProduct,
  productList: [...productToOfferA.productList, ...ProductToOfferB.productList],
});

export const fetchChargebeeProduct = async (
  productCode: string,
  experiment?: string,
  xsource?: string,
  coupon?: string
) => {
  try {
    const productResponse = await api.product.get({
      paymentProvider: PAYMENT_PROVIDER_CHARGEBEE,
      productCode,
      duration: true,
      experiment,
      xsource,
      coupon,
    });
    const product = camelize(productResponse) as ProductPayload;

    return product;
  } catch (e) {
    captureException(e);
    throw e;
  }
};
