import { fromJS, Map, NonEmptyMapRecord } from 'immutable';
import * as R from 'ramda';
import {
  minimumPromiseDuration,
  gaScreenView,
  tapReject,
  isBrowserPlatform,
  mapCond,
  delayedModifyTargetActionCreator,
} from '../../helpers/index';
import { fetchAccount, modifyAccount, updateAccount } from '../account';

import {
  RELOAD_REQUEST,
  RELOAD_RECEIVE,
  RELOAD_ERROR,
  DELAYED_MODIFY_TIMEOUT_ID,
  REQUEST_FETCH_DATA,
  RECEIVE_DATA,
  FINISHED_LOADING_INITIAL_DATA,
  SET_CONTENT_HIDDEN,
  SET_STANDALONE_FLOW,
  SET_ACCESS_TYPE,
  SET_LOADED_ROOT,
  SET_OPENED_FROM_BACKGROUND,
  TOGGLE_FLOATING_ACTION_MENU,
  CLOSE_FLOATING_ACTION_MENU,
  IS_FRESHLY_LOGGED_OUT,
  CLEAR_FRESHLY_LOGGED_OUT,
  ROLLOUT_USER_CONFIG_DONE,
  SET_KEYBOARD_VISIBLE,
  IS_NAVIGATING_TO_PURCHASE_PRODUCT,
  AccessType,
  IS_DATA_FETCHED,
  START_LOADING_INITIAL_DATA,
  SSO_STATUS_FAILED,
  SET_IS_LOGGING_OUT,
  SET_LOCALE,
} from '../actionNames/app';

import { fetchProfilesIfNeeded } from '../profiles';
import { fetchDevices } from '../devices';
import { fetchProducts } from '../products';
import { isNewAccount, getAccountUid, getAccount } from '../../selectors';

import {
  StandaloneFlowFlag,
  QueryInitializer,
  stripTokensFromQuery,
  QueryWebAutologin,
} from '../../helpers/query';
import {
  StandaloneFlow,
  OnBoardingRoutes,
  SsoStartMode,
  ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION,
  ISOLanguage,
} from '../../constants';
import { BaseThunk } from '../../store/state';
import { getProfileIds } from '../../selectors/profile';
import {
  isConfirmedStandaloneApp,
  shouldRedirectToOnBoarding,
} from '../../selectors/app';
import { createSsoState, deserializeSsoState } from '../../helpers/sso';
import { redirectToSsoLogin, saveSsoStateToStorage } from '../../actions/sso';
import { getLoginRedirectLocation, getLocation } from '../../selectors/routing';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { Experiment } from '../../sideEffects/flags';
import { redirectIfPathDiffers } from '../../helpers/navigation';
import { getOnboardingExperimentScreen } from '../../helpers/routes';
import { LicenseType } from '../../records/license';
import { RouteLocation } from '../../types/RouteLocation.types';
import { captureException } from '../../helpers/sentry';
import { updateWebOnboardingOptionAsFinished } from '../../actions/onboarding/OnboardingActions';
import { activateRetrial } from '../../actions/RetrialActions';
import {
  getMultiPlatformNavigation,
  LOCATION_CHANGE,
} from '../../helpers/multiPlatformNavigation';
import { finishedLoadingInitialData } from './redirectAfterLogin';
// eslint-disable-next-line import/namespace
import { bootApp } from './bootApp';
import { initializeWithParams } from './initializeWithParams';
import { sendTagManagerNavigationEvent } from '../../helpers/tagManager';
import { InAppBrowserEvent } from '../../types/inAppBrowser';
import { trackAuthenticationEventsOnApps } from '../../helpers/attribution/apps';
import { navigateToDirectPurchase } from '../../actions/Navigation';
import { DirectPurchase } from '../directPurchase/types/state';

const TIMEOUT_ID_DELAYED_ACCOUNT = Symbol('TIMEOUT_ID_DELAYED_ACCOUNT');
export const SET_IS_REDIRECTING_TO_SSO_LOGIN =
  'SET_IS_REDIRECTING_TO_SSO_LOGIN';

export const defaultAppState = {
  isFetching: false,
  isReloading: false,
  isLoadingInitialData: true,
  reloadError: '',
  timeoutIds: Map(),
  isContentHidden: true,
  standaloneFlow: StandaloneFlow.None,
  accessType: AccessType.LocalStorage,
  loadedRoot: false,
  openedFromBackground: false,
  isFloatingActionMenuOpen: false,
  isFreshlyLoggedOut: false,
  isLoggingOut: false,
  rolloutUserConfigSent: false,
  isKeyboardVisibleAtPlaces: false,
  isNavigatingToPurchaseProduct: false,
  isDataFetched: false,
  ssoStatusFailed: false,
  isRedirectingToSSOLogin: false,
  locale: undefined,
};

export default function app(
  state: NonEmptyMapRecord<{
    isRedirectingToSSOLogin: boolean;
    isFetching: boolean;
    isReloading: boolean;
    isLoadingInitialData: boolean;
    reloadError: string;
    timeoutIds: Map<number, string>;
    isContentHidden: boolean;
    standaloneFlow: StandaloneFlow;
    accessType: AccessType;
    loadedRoot: boolean;
    openedFromBackground: boolean;
    isFloatingActionMenuOpen: boolean;
    isFreshlyLoggedOut: boolean;
    isLoggingOut: boolean;
    rolloutUserConfigSent: boolean;
    isKeyboardVisibleAtPlaces: boolean;
    isNavigatingToPurchaseProduct: boolean;
    isDataFetched: boolean;
    ssoStatusFailed: boolean;
    locale?: ISOLanguage;
  }> = fromJS(defaultAppState),
  action
) {
  switch (action.type) {
    case SET_IS_REDIRECTING_TO_SSO_LOGIN:
      return state.set('isRedirectingToSSOLogin', action.payload);
    case IS_DATA_FETCHED:
      return state.set('isDataFetched', action.payload);
    case REQUEST_FETCH_DATA:
      return state.set('isFetching', true);
    case RECEIVE_DATA:
      return state.set('isFetching', false);
    case RELOAD_REQUEST:
      return state.merge({ isReloading: true, reloadError: '' });
    case RELOAD_RECEIVE:
      return state.merge({ isReloading: false });
    case RELOAD_ERROR:
      return state.merge({ isReloading: false, reloadError: action.payload });
    case LOCATION_CHANGE:
      gaScreenView(action.payload);
      sendTagManagerNavigationEvent();
      return state;
    case DELAYED_MODIFY_TIMEOUT_ID:
      return state.setIn(
        ['timeoutIds', action.payload.target],
        action.payload.id
      );
    case START_LOADING_INITIAL_DATA:
      return state.set('isLoadingInitialData', true);
    case FINISHED_LOADING_INITIAL_DATA:
      return state.set('isLoadingInitialData', false);
    case SET_CONTENT_HIDDEN:
      return state.set('isContentHidden', action.payload);
    case SET_STANDALONE_FLOW:
      return state.set('standaloneFlow', action.payload);
    case SET_ACCESS_TYPE:
      return state.set('accessType', action.payload);
    case SET_LOADED_ROOT: // Handle history error https://github.com/ReactTraining/history/issues/427
      return state.set('loadedRoot', action.payload);
    case SET_OPENED_FROM_BACKGROUND:
      return state.set('openedFromBackground', action.payload);
    case TOGGLE_FLOATING_ACTION_MENU:
      return state.set(
        'isFloatingActionMenuOpen',
        !state.get('isFloatingActionMenuOpen')
      );
    case CLOSE_FLOATING_ACTION_MENU:
      return state.set('isFloatingActionMenuOpen', false);
    case IS_FRESHLY_LOGGED_OUT:
      return state.set('isFreshlyLoggedOut', true);
    case CLEAR_FRESHLY_LOGGED_OUT:
      return state.set('isFreshlyLoggedOut', false);
    case SET_IS_LOGGING_OUT:
      return state.set('isLoggingOut', true);
    case ROLLOUT_USER_CONFIG_DONE:
      return state.set('rolloutUserConfigSent', true);
    case SET_KEYBOARD_VISIBLE:
      return state.set('isKeyboardVisibleAtPlaces', action.payload);
    case IS_NAVIGATING_TO_PURCHASE_PRODUCT:
      return state.set('isNavigatingToPurchaseProduct', action.payload);
    case SSO_STATUS_FAILED:
      return state.set('ssoStatusFailed', action.payload);
    case SET_LOCALE:
      return state.set('locale', action.payload);
    default:
      return state;
  }
}

export const setLocale = (locale: ISOLanguage) => ({
  type: SET_LOCALE,
  payload: locale,
});

export const setIsRedirectingToSSOLogin = (isRedirecting: boolean) => ({
  type: SET_IS_REDIRECTING_TO_SSO_LOGIN,
  payload: isRedirecting,
});

export const setSsoStatusFailed = (failed: boolean) => ({
  type: SSO_STATUS_FAILED,
  payload: failed,
});

export function requestFetchData() {
  return {
    type: REQUEST_FETCH_DATA,
  };
}

export function receiveData() {
  return {
    type: RECEIVE_DATA,
  };
}

export const startLoadingInitialData = (): BaseThunk<void> => dispatch => {
  dispatch({ type: START_LOADING_INITIAL_DATA });
  dispatch({
    type: SET_CONTENT_HIDDEN,
    payload: true,
  });
};

export function setOpenedFromBackground(payload: boolean) {
  return {
    type: SET_OPENED_FROM_BACKGROUND,
    payload,
  };
}

export const routeToParentAppStandaloneConfirmationScreen =
  (location: RouteLocation): BaseThunk<void> =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    if (!isConfirmedStandaloneApp(getState())) {
      const redirectTo = ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION;
      if (redirectTo !== location.pathname) {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: redirectTo,
          })
        );
      }
    }
    return Promise.resolve(undefined);
  };

export const routeToOnboarding =
  (location: RouteLocation): BaseThunk<void> =>
  // eslint-disable-next-line consistent-return
  (dispatch, getState) => {
    const state = getState();
    const account = getAccount(state);
    const profileIds = getProfileIds(state);
    const navigate = getMultiPlatformNavigation();

    let redirectTo: string | undefined;

    if (
      getFeatureFlag(state, 'webOnboardingToAndroidExperiment') !==
      Experiment.Original
    ) {
      return dispatch(
        redirectIfPathDiffers(
          getOnboardingExperimentScreen(account),
          location.pathname
        )
      );
    }

    if (account.profilesCount === 0) {
      redirectTo = OnBoardingRoutes.onboardingRoot;
    } else if (account.profilesCount === 1) {
      if (account.devicesCount === 0) {
        redirectTo = `${OnBoardingRoutes.addDevice}/${profileIds.first()}`;
      } else if (account.devicesCount === 1) {
        redirectTo = `${OnBoardingRoutes.finish}/${profileIds.first()}`;
      }
    }

    if (redirectTo && redirectTo !== location.pathname) {
      if (location.pathname === '/activate-retrial') {
        dispatch(activateRetrial(redirectTo));
      }

      dispatch(
        navigate({
          type: 'inner:replace',
          src: redirectTo,
        })
      );
    }
  };

export const isSchoolOnboardingFlow = (query?: QueryInitializer) =>
  query && query.flow === 'school-onboarding';

export const routeToSchoolOnboarding =
  (query?: QueryInitializer): BaseThunk<void> =>
  dispatch => {
    if (isSchoolOnboardingFlow(query)) {
      const navigate = getMultiPlatformNavigation();
      stripTokensFromQuery(['flow']);
      dispatch(
        navigate({
          type: 'inner:replace',
          src: '/onboarding-schools/link-students',
        })
      );
    }
  };

export function reloadAppData() {
  return dispatch => {
    dispatch({
      type: RELOAD_REQUEST,
    });
    const minimum = minimumPromiseDuration();
    return dispatch(fetchAccount())
      .then(R.pipe(fetchProfilesIfNeeded, dispatch))
      .then(() => dispatch(fetchProducts()).catch(() => undefined)) // ignore products error
      .then(() => dispatch(fetchDevices()).catch(() => undefined)) // ignore devices error
      .then(minimum, minimum) // finally
      .then(() =>
        dispatch({
          type: RELOAD_RECEIVE,
        })
      )
      .catch(
        tapReject(e => {
          dispatch({
            type: RELOAD_ERROR,
            payload: e.message,
          });
        })
      );
  };
}

export function delayedModifyTimeoutId(id, target) {
  return {
    type: DELAYED_MODIFY_TIMEOUT_ID,
    payload: {
      id,
      target,
    },
  };
}

export const setStandaloneFlow = (
  flow: StandaloneFlowFlag
): {
  type: typeof SET_STANDALONE_FLOW;
  payload: StandaloneFlow;
} => ({
  type: SET_STANDALONE_FLOW,
  payload: mapCond<StandaloneFlowFlag, StandaloneFlow>(
    [
      ['purchase', StandaloneFlow.Purchase],
      ['purchase-at-limit', StandaloneFlow.PurchaseAtlimit],
    ],
    StandaloneFlow.None
  )(flow),
});

export function toggleFloatingActionMenu() {
  return {
    type: TOGGLE_FLOATING_ACTION_MENU,
    payload: {},
  };
}

export function closeFloatingActionMenu() {
  return {
    type: CLOSE_FLOATING_ACTION_MENU,
    payload: {},
  };
}

export function clearFreshlyLoggedOut() {
  return {
    type: CLEAR_FRESHLY_LOGGED_OUT,
  };
}

export function setKeyboardVisiblityAtPlaces(payload: boolean) {
  return {
    type: SET_KEYBOARD_VISIBLE,
    payload,
  };
}

export const delayedModifyAccount = (args, delay) =>
  delayedModifyTargetActionCreator(
    delayedModifyTimeoutId,
    updateAccount,
    modifyAccount,
    TIMEOUT_ID_DELAYED_ACCOUNT,
    args,
    delay
  );

export const redirectToLoginForStandaloneApps = (): BaseThunk => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(finishedLoadingInitialData());
  return dispatch(
    navigate({
      type: 'inner:replace',
      src: '/initial',
    })
  );
};

export const redirectToLogout =
  (license: LicenseType, isUnhandledError?: boolean): BaseThunk =>
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: `/logout/${license}${isUnhandledError ? '/fail' : ''}`,
      })
    );
  };

export const startSso =
  (mode: SsoStartMode): BaseThunk =>
  (dispatch, getState) => {
    if (!isBrowserPlatform()) {
      const state = getState();
      const redirectLocation = getLoginRedirectLocation(state);
      const ssoState = createSsoState({
        location: redirectLocation || getLocation(state),
      });
      dispatch(setSsoStatusFailed(false));
      dispatch(saveSsoStateToStorage(ssoState));
      return dispatch(redirectToSsoLogin(mode, ssoState))
        .then(data => {
          // TODO: because compatibility with previous auth code flow we allow here load the query and code
          // without the state. We are checked the state previously. If state is invalid this callback is not called.
          // In some point we must ban auth code flow without state like here.
          const query = `kind=auth-code&authorization_code=${data.code}&isNewAccount=${data.isNewAccount}`;
          return dispatch(initializeWithParams(query)).then(() => {
            const ssoState = deserializeSsoState(data.state);
            dispatch(
              bootApp(window.location.search, ssoState.location, false)
            ).then(() => {
              const state = getState();
              const navigate = getMultiPlatformNavigation();
              trackAuthenticationEventsOnApps({
                isNewAccount: isNewAccount(state),
                accountUid: getAccountUid(state),
              });
              dispatch(
                navigate({
                  type: 'inner:replace',
                  src: window.location,
                })
              );
            });
          });
        })
        .catch((ex: Error | InAppBrowserEvent) => {
          dispatch(setSsoStatusFailed(true));
          if (ex instanceof Error) {
            captureException(ex);
          } else {
            /**
             * It converts the InAppBrowserEvent object into a string error
             * See this sentry issue for more details:
             * https://qustodio.sentry.io/issues/3738890383/
             */
            captureException(
              `redirectToSsoLogin LoadError (code:${ex.code}): ${ex.message}`
            );
          }
          dispatch(redirectToLoginForStandaloneApps());
        });
    }
    return Promise.resolve();
  };

export const shouldRouteToParentAppStandaloneConfirmationScreen = state =>
  !isBrowserPlatform() && !isConfirmedStandaloneApp(state);

export const appSetupIncomplete = state =>
  shouldRouteToParentAppStandaloneConfirmationScreen(state) ||
  shouldRedirectToOnBoarding(state);

export const resumeSetup =
  (queryObject?: QueryInitializer) => (dispatch, getState) => {
    const state = getState();
    const location = getLocation(state);
    const navigate = getMultiPlatformNavigation();

    // disable redirect to onboarding if we are in the schools onboarding flow
    if (queryObject && isSchoolOnboardingFlow(queryObject)) {
      return dispatch(updateWebOnboardingOptionAsFinished()).then(() =>
        dispatch(routeToSchoolOnboarding(queryObject))
      );
    }

    if (queryObject && isDirectPurchaseFlow(queryObject as QueryWebAutologin)) {
      return dispatch(
        handleNavigateToDirectPurchase(queryObject as QueryWebAutologin)
      );
    }

    if (shouldRouteToParentAppStandaloneConfirmationScreen(state)) {
      return dispatch(routeToParentAppStandaloneConfirmationScreen(location));
    }

    if (shouldRedirectToOnBoarding(state)) {
      return dispatch(routeToOnboarding(location));
    }

    return dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  };

/**
 * After calculating the parameters, the SSO performs two redirects with different
 * data structures with a race condition. In some cases,
 * the direct-purchase data arrives, while in others it is
 * in the state of the SSO.
 */
export const isDirectPurchaseFlow = (
  query?: QueryWebAutologin | DirectPurchase
) => {
  if (query?.flow === 'direct-purchase') {
    return true;
  }

  if (!query?.state) {
    return false;
  }

  const state = deserializeSsoState(query.state);
  return state?.query?.flow === 'direct-purchase';
};

/**
 * After calculating the parameters, the SSO performs two redirects with different
 * data structures with a race condition. In some cases,
 * the direct-purchase data arrives, while in others it is
 * in the state of the SSO.
 */
export const getProductCodeFromQuery = (
  query: QueryWebAutologin | DirectPurchase
) => {
  const state = query.state ? deserializeSsoState(query.state) : query;
  const productCode: string = query.state ? state?.query?.cart : state?.cart;
  return productCode;
};

export const handleNavigateToDirectPurchase = (
  query: QueryWebAutologin | DirectPurchase
) => {
  const productCode = getProductCodeFromQuery(query);
  return navigateToDirectPurchase(productCode);
};
