import * as React from 'react';
import { isNil, isEmpty } from 'ramda';
import {
  ColorPicker,
  ContentSeparator,
  FlexLayout,
  TextField,
  Typography,
  TextArea,
  AlertBox,
  AlertBoxType,
  Icon,
  IconType,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import { getRoutinesIcon } from '../../helpers/routineIcons';
import RoutineIcon from '../../RoutineIcon/RoutineIcon';
import RoutineIconSelector from '../../RoutineIconSelector/RoutineIconSelector';

import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { when } from '../../../../helpers/render';
import useMergedState from '../../../../hooks/useMergedState';
import { ContextStyle, RoutineMode } from '../../routines.types';
import {
  RoutineColor,
  getRoutineColorValue,
} from '../../../../palettes/RoutineColor';

export enum SelectNameAndStyleTest {
  prefix = 'routines-multi-step-select-name-and-style',
}

export type SelectNameAndStyleInternalState = ContextStyle;

export type NameValidationErrorType = 'EMPTY' | 'INVALID' | 'MAX_LENGTH';

export interface SelectNameAndStyleProps
  extends RoutineStepWrapperHandlerProps<SelectNameAndStyleInternalState>,
    Partial<Pick<SelectNameAndStyleInternalState, 'name' | 'description'>>,
    Pick<SelectNameAndStyleInternalState, 'color' | 'icon'> {
  mode: RoutineMode;
  showBadge: boolean;
  profileName: string;
  isValidName: (name: string) => boolean;
}

const colorOptions: Array<typeof RoutineColor[keyof typeof RoutineColor]> = [
  RoutineColor.yellow,
  RoutineColor.red,
  RoutineColor.purple,
  RoutineColor.pink,
  RoutineColor.blue,
  RoutineColor.green,
  RoutineColor.marine,
];

const colorPickerPalette = colorOptions.reduce(
  (acc, color) => ({
    ...acc,
    [color]: getRoutineColorValue(color),
  }),
  {}
);

const MAX_ROUTINE_NAME_LENGTH = 28;
const isUnderMaxLength = (name: string) =>
  name.trim().length < MAX_ROUTINE_NAME_LENGTH;

const hasName = (name: string | undefined) =>
  !isNil(name) && !isEmpty(name.trim());

const getNameValidationErrorType = (name): NameValidationErrorType => {
  if (!hasName(name)) return 'EMPTY';
  if (!isUnderMaxLength(name)) return 'MAX_LENGTH';
  return 'INVALID';
};

const SelectNameAndStyle = ({
  name = '',
  color,
  icon,
  description = '',
  mode,
  showBadge = false,
  profileName,
  prev,
  close,
  next,
  isValidName,
}: SelectNameAndStyleProps) => {
  const inputNameRef = React.useRef<HTMLInputElement>(null);

  const [state, updateState] = useMergedState<SelectNameAndStyleInternalState>({
    name,
    color,
    icon,
    description,
  });

  const [uiState, updateUiState] = useMergedState<{
    hasValidationError: boolean;
    validationNameErrorType: NameValidationErrorType;
  }>({
    hasValidationError: false,
    validationNameErrorType: 'EMPTY',
  });

  const hasValidName = () =>
    hasName(state.name) &&
    isValidName(state.name) &&
    isUnderMaxLength(state.name);

  React.useEffect(() => {
    if (hasValidName()) {
      updateUiState({
        hasValidationError: false,
      });
    } else {
      const errorType = getNameValidationErrorType(state.name);
      const isMaxLengthError = errorType === 'MAX_LENGTH';

      // For a better UX:
      // Sometimes we immediatly want to show the error
      // otherwise, we set hasValidationError = true only on submit.
      const showErrorImmediately = isMaxLengthError;

      updateUiState({
        hasValidationError: showErrorImmediately,
        validationNameErrorType: getNameValidationErrorType(state.name),
      });
    }
  }, [state.name]);

  React.useEffect(() => {
    if (inputNameRef.current) {
      inputNameRef.current.focus();
    }
  }, []);

  return (
    <RoutineStepWrapper
      testId={SelectNameAndStyleTest.prefix}
      prev={prev}
      close={close}
      next={() => {
        if (hasValidName()) {
          next?.(state);
        } else {
          updateUiState({
            hasValidationError: true,
            validationNameErrorType: getNameValidationErrorType(state.name),
          });
        }
      }}
      footerText={mode === 'CREATE' ? t('Continue') : t('Save and go back')}
      footerButtonDisabled={uiState.hasValidationError}
      fixedFooter
      footerAlert={when(
        uiState.hasValidationError,
        <AlertBox
          type={AlertBoxType.error}
          rounded
          showInfoIcon
          fullWidth
          icon={<Icon type={IconType.exclamationCircle} />}
        >
          {uiState.validationNameErrorType === 'EMPTY' &&
            t('Please choose a name for this routine')}
          {uiState.validationNameErrorType === 'INVALID' &&
            t('A routine with this name already exists for {{profileName}}', {
              profileName,
            })}
          {uiState.validationNameErrorType === 'MAX_LENGTH' &&
            t('Name should not be longer than {{MAX_NAME_LENGTH}} characters', {
              MAX_NAME_LENGTH: MAX_ROUTINE_NAME_LENGTH,
            })}
        </AlertBox>
      )}
    >
      <FlexLayout mainaxis="column">
        <FlexLayout mainaxis="column" marginBottom="32">
          <Typography type="h4" weight="semibold">
            {mode === 'CREATE' && t('Choose a name and style')}
            {mode === 'EDIT' && t('Change style')}
          </Typography>
        </FlexLayout>

        <FlexLayout mainaxis="column" alignSelf="center" marginBottom="24">
          <RoutineIcon
            testId={`${SelectNameAndStyleTest.prefix}-icon`}
            showBadge={showBadge}
            icon={getRoutinesIcon(state.icon)}
            color={state.color}
            size="xlarge"
            inverted
          />
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          alignSelf="center"
          minWidth="100%"
          marginBottom="16"
          textAlignement="center"
        >
          <TextField
            testId={`${SelectNameAndStyleTest.prefix}-input-name`}
            ref={inputNameRef}
            maxlength={MAX_ROUTINE_NAME_LENGTH}
            name="routineName"
            type="text"
            placeholder={t('Name')}
            onChange={(_event, name) => updateState({ name: name.trim() })}
            defaultValue={state.name}
            block
            size="small"
            textCentered
            error={uiState.hasValidationError}
          />
        </FlexLayout>

        <FlexLayout mainaxis="column" alignSelf="center" marginBottom="16">
          <ColorPicker
            testId={`${SelectNameAndStyleTest.prefix}-color-picker`}
            palette={colorPickerPalette}
            onClick={color => updateState({ color: color as RoutineColor })}
            activeColor={state.color}
          />
        </FlexLayout>

        <FlexLayout mainaxis="column" marginBottom="24">
          <ContentSeparator />
        </FlexLayout>

        <FlexLayout mainaxis="column" alignSelf="center" marginBottom="72">
          <RoutineIconSelector
            testId={`${SelectNameAndStyleTest.prefix}-icon-selector`}
            color={state.color}
            selectedIcon={state.icon}
            onClick={icon => updateState({ icon })}
          />
        </FlexLayout>

        <FlexLayout mainaxis="column" marginBottom="8">
          <Typography type="body2">{t('Description (optional)')}</Typography>
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          alignSelf="center"
          minWidth="100%"
          marginBottom="48"
          textAlignement="center"
        >
          <TextArea
            testId={`${SelectNameAndStyleTest.prefix}-text-description`}
            maxLength={35}
            maxRows={4}
            onChange={event => updateState({ description: event.target.value })}
            defaultValue={state.description}
          />
        </FlexLayout>
      </FlexLayout>
    </RoutineStepWrapper>
  );
};

export default SelectNameAndStyle;
