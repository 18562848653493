import { Record, List } from 'immutable';
import { t } from '../../lib/i18n';
import { isValidPhoneNumber } from '../../helpers/string';
import { CallsSmsContactRecord } from '../../records/profileRules';

interface Contact {
  name?: string;
  phone?: string;
}

export const validateNewContact = (
  contact: Record<Contact>,
  {
    contacts,
    country,
  }: { contacts: List<Record<CallsSmsContactRecord>>; country: string }
) => {
  const errors: Contact = {};

  if (!contact.name) {
    errors.name = t('Field required');
  }

  if (!contact.phone) {
    errors.phone = t('Field required');
  } else if (phoneAlreadyExistIn(contacts, contact.phone)) {
    errors.phone = t(
      'This phone number already exists in your exceptions list'
    );
  } else if (!isValidPhoneNumber(contact.phone, country)) {
    errors.phone = t('Add a valid phone number');
  }

  return errors;
};

const phoneAlreadyExistIn = (contacts, phone) =>
  contacts.find(contact => contact.phone === phone);
