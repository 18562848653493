import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
} from 'styleguide-react';

const IconAllow: React.FC<{ size?: IconSize; color?: IconColor }> = ({
  size = IconSize.regular,
  color = IconColor.success,
}) => (
  <Icon
    type={IconType.check}
    size={size}
    family={IconFamily.solid}
    color={color}
    square
  />
);

export default IconAllow;
