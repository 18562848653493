import { RULE_GAMES_AND_APPS, RULE_YOUTUBE } from '../../../../constants';

const noLabelDelegationRules = [RULE_YOUTUBE, RULE_GAMES_AND_APPS];

export const isHiddenLabelOnDelegationWithDesktopDevices = ({
  profileRule,
  isDelegatedWithDesktopDevice,
  isSchoolReferralLicense,
}) => {
  if (
    noLabelDelegationRules.includes(profileRule) &&
    (isDelegatedWithDesktopDevice || isSchoolReferralLicense)
  )
    return true;

  return false;
};
