import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
  format,
} from '../../helpers/analytics';

interface SummaryAlertsTrackingData {
  count: number;
  type: 'searches';
}

const toTrackingOptions = (
  type: SummaryAlertsTrackingData['type'],
  counter: SummaryAlertsTrackingData['count']
) => {
  return format({
    options: { type, alertsCount: counter },
  });
};

export const trackCloseSummaryAlertsBanner = ({
  count,
  type,
}: SummaryAlertsTrackingData) => {
  return trackButtonClicked(
    GenericPageNames.Profile,
    ButtonNames.CloseSummaryAlertsBanner,
    toTrackingOptions(type, count)
  );
};

export const trackReviewSummaryAlertsBanner = ({
  count,
  type,
}: SummaryAlertsTrackingData) => {
  return trackButtonClicked(
    GenericPageNames.Profile,
    ButtonNames.ReviewAlertsBanner,
    toTrackingOptions(type, count)
  );
};
