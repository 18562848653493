import * as React from 'react';
import {
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  Layout,
  Typography,
} from 'styleguide-react';

interface TransparentAlertProps {
  testId?: string;
  icon: typeof IconType[keyof typeof IconType];
  text: string;
}

const RoutinesInfoAlert = ({ testId, icon, text }: TransparentAlertProps) => (
  <FlexLayout mainaxis="row" testId={testId} crossaxisAlignment="center">
    <Layout marginRight="8">
      <Icon type={icon} color={IconColor.secondary} />
    </Layout>
    <Typography type="caption1">{text}</Typography>
  </FlexLayout>
);

export default RoutinesInfoAlert;
