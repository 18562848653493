import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import FamilyTherapy from './FamilyTherapy';
import FamilyActivities from './FamilyActivities';

interface WellbeingUnsubscribedProps {
  goToTherapy: () => void;
  goToActivities: () => void;
}

const WellbeingUnsubscribed = ({
  goToTherapy,
  goToActivities,
}: WellbeingUnsubscribedProps) => {
  return (
    <FlexLayout mainaxis="column" backgroundColor="white" width="100%">
      <FamilyTherapy onClickButton={goToTherapy} />
      <FamilyActivities onClickButton={goToActivities} />
    </FlexLayout>
  );
};

export default WellbeingUnsubscribed;
