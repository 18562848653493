import * as React from 'react';
import { connect } from 'react-redux';
import RemoveInvitationModal from '../../components/Modal/RemoveInvitationModal';
import { t } from '../../lib/i18n';
import State, { Dispatch } from '../../store/state';
import { getInvitation } from '../../ducks/invitations/invitationsSelector';
import { goBackIfHistory } from '../../ducks/routing';
import { removeInvitation } from '../../ducks/invitations/invitationsActions';

export default connect(
  (state: State) => {
    const invitation = getInvitation(state)!;

    const isRemovingPendingInvitation = invitation.status === 'pending';
    const email =
      invitation.invitedAccessProfile?.email ?? invitation.invitationEmail;

    if (isRemovingPendingInvitation) {
      return {
        title: t('Cancel invitation?'),
        text: t(
          "{1}{{invitedEmail}}{/1} won't be able to join your {{shortName}} account.",
          {
            invitedEmail: email,
            1: str => <strong>{str}</strong>,
          }
        ),
        mainCtaText: t('Cancel invitation'),
        secondaryCtaText: t('Close'),
      };
    }
    return {
      title: t('Remove access?'),
      text: t(
        '{1}{{invitedEmail}}{/1} will no longer have access to your {{shortName}} account.',
        {
          invitedEmail: email,
          1: str => <strong>{str}</strong>,
        }
      ),
      mainCtaText: t('Remove'),
      secondaryCtaText: t('Cancel'),
    };
  },
  (dispatch: Dispatch) => ({
    mainCtaAction: () => {
      dispatch(removeInvitation());
    },
    secondaryCtaAction: () => {
      dispatch(goBackIfHistory());
    },
  })
)(RemoveInvitationModal);
