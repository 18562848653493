import { gaEvent } from '../../helpers';
import { navigateToAddChild } from './OnboardingActions';

export const onEnterOnboardingWelcomeModal = () => () => {
  gaEvent('wob-welcome-flyover', 'navigation', 'load');
};

export const clickCreateProfile = () => dispatch =>
  Promise.resolve(dispatch(navigateToAddChild())).then(() =>
    gaEvent('wob-welcome', 'button-create-profile', 'click')
  );
