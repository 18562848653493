import * as React from 'react';
import TimelineState from './TimelineState';
import { IconType } from 'styleguide-react';
import { t } from '../../../../lib/i18n';

interface ChildUnlinkedStateProps {
  profileName: string;
}

const ChildUnlinkedState: React.FC<ChildUnlinkedStateProps> = ({
  profileName,
}) => {
  return (
    <TimelineState
      iconType={IconType.exclamationCircle}
      title={t('No activity available')}
      text={t(
        '{{profileName}}’s school account isn’t available at the moment. Please come back later.',
        {
          profileName,
        }
      )}
    />
  );
};

export default ChildUnlinkedState;
