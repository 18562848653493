import { connect } from 'react-redux';
import { List } from 'immutable';
import * as R from 'ramda';
import { t } from '../../../lib/i18n';
import { goBackIfHistory } from '../../../ducks/routing';
import TimeSettings from '../../../components/TimeLimits/TimeSettings';
import {
  ProfileRulesRecord,
  TimeSettingsRecord,
} from '../../../records/profileRules';
import {
  getProfileRules,
  isMultiDevices,
  getMultiDeviceTimeRestrictions,
} from '../../../selectors';
import { showErrorToast } from '../../AccountSettings/helpers';
import {
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
  updateProfileRules,
  delayedModifyProfileRules,
  delayedModifyTimeLimits,
  updateTimeLimits,
  reverseTimeLimitsUpdate,
} from '../../../ducks/profileRules';
import { showToast, TOAST_ICON_WARNING } from '../../../ducks/toast';
import {
  UPDATE_PROFILE_RULES_DELAY,
  SettingsToggleNames,
} from '../../../constants';
import {
  trackTimeLimitsRule,
  TimeLimitsRuleNames,
} from '../../../helpers/analytics';
import { RuleType, setLastRuleTypeModified } from '../../../ducks/appRate';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';

const mapStateToProps = (state, { params: { profileId, deviceId } }) => {
  const timeRestrictions = isMultiDevices(state, profileId)
    ? getMultiDeviceTimeRestrictions(state, deviceId) ||
      ProfileRulesRecord().timeRestrictions
    : (getProfileRules(state, profileId) || ProfileRulesRecord())
        .timeRestrictions;
  return {
    profileId,
    deviceId,
    timeRestrictions,
    toggleDisabled: enabledCount(timeRestrictions) <= 1,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId, deviceId } }) => ({
  onToggle: (toggleTarget, toggleOn) => {
    dispatch(setLastRuleTypeModified(RuleType.DailyTimeLimits));
    dispatch(onToggle(profileId, deviceId, toggleTarget, toggleOn));
  },
  onClickDisabled: () =>
    dispatch(
      showToast(
        t('At least one of the actions needs to be active.'),
        TOAST_ICON_WARNING
      )
    ),
  onChangeDevice: device => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: `/profiles/${profileId}/rules/dailyTimeLimit/devices/${device.id}/timeSettings`,
      })
    );
  },
  onClickBackButton: () => {
    dispatch(goBackIfHistory());
  },
});

export const onToggle =
  (profileId, deviceId, toggleTarget, toggleOn) => (dispatch, getState) =>
    dispatch(
      isMultiDevices(getState(), profileId)
        ? changeTimeLimits(profileId, deviceId, toggleTarget, toggleOn)
        : changeProfileRules(profileId, toggleTarget, toggleOn)
    );

const changeProfileRules =
  (profileId, toggleTarget, toggleOn) => (dispatch, getState) => {
    const newProfileRules = setProfileRulesRecord(
      toggleTarget,
      toggleOn,
      getState(),
      profileId
    );
    if (enabledCount(newProfileRules.timeRestrictions) === 0) {
      const currentProfileRules = getProfileRules(getState(), profileId);
      dispatch(updateProfileRules(newProfileRules, profileId));
      dispatch(updateProfileRules(currentProfileRules, profileId));
      dispatch(
        showToast(
          t('At least one of the actions needs to be active.'),
          TOAST_ICON_WARNING
        )
      );
      return Promise.resolve();
    }

    // Any change done by the user must be tracked inmediately
    trackToggle(toggleTarget, toggleOn);

    return Promise.resolve(newProfileRules)
      .then(profileRules => {
        return dispatch(
          delayedModifyProfileRules(
            [profileRules, profileId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseProfileRulesUpdate());
        dispatch(showErrorToast(error));
      });
  };

const changeTimeLimits =
  (profileId, deviceId, toggleTarget, toggleOn) => (dispatch, getState) => {
    const currentTimeRestrictions = getMultiDeviceTimeRestrictions(
      getState(),
      deviceId
    );
    const newTimeRestrictions = setTimeRestrictions(
      toggleTarget,
      toggleOn,
      currentTimeRestrictions
    );
    if (enabledCount(newTimeRestrictions) === 0) {
      dispatch(updateTimeLimits(newTimeRestrictions, profileId, deviceId));
      dispatch(updateTimeLimits(currentTimeRestrictions, profileId, deviceId));
      dispatch(
        showToast(
          t('At least one of the actions needs to be active.'),
          TOAST_ICON_WARNING
        )
      );
      return Promise.resolve();
    }

    // Any change done by the user must be tracked inmediately
    trackToggle(toggleTarget, toggleOn);

    return Promise.resolve(newTimeRestrictions)
      .then(timeRestrictions => {
        return dispatch(
          delayedModifyTimeLimits(
            [timeRestrictions, profileId, deviceId],
            UPDATE_PROFILE_RULES_DELAY
          )
        );
      })
      .catch(error => {
        dispatch(requestModifyProfileRulesError());
        dispatch(reverseTimeLimitsUpdate(profileId, deviceId));
        dispatch(showErrorToast(error));
      });
  };

const setProfileRulesRecord = (toggleTarget, toggleOn, state, profileId) =>
  getProfileRules(state, profileId).update(
    'timeRestrictions',
    setTimeRestrictions(toggleTarget, toggleOn)
  );

const setTimeRestrictions = R.curry(
  (toggleTarget, toggleOn, timeRestrictions) =>
    timeRestrictions.set(toggleTarget, toggleOn)
);

const timeRestrictionFlags = (timeRestrictions: TimeSettingsRecord) => {
  return List([
    timeRestrictions.isLockNavigation,
    timeRestrictions.isLockComputer,
    timeRestrictions.isReportAlerts,
  ]);
};

const enabledCount = (timeRestrictions: TimeSettingsRecord) =>
  timeRestrictionFlags(timeRestrictions).filter(R.identity).size;

const trackToggle = (
  toggleTarget: SettingsToggleNames,
  toggleOn: boolean
): void => {
  switch (toggleTarget) {
    case SettingsToggleNames.IsLockNavigation:
      trackTimeLimitsRule(
        toggleOn
          ? TimeLimitsRuleNames.ENABLE_LOCK_NAVIGATION
          : TimeLimitsRuleNames.DISABLE_LOCK_NAVIGATION
      );
      break;
    case SettingsToggleNames.IsLockComputer:
      trackTimeLimitsRule(
        toggleOn
          ? TimeLimitsRuleNames.ENABLE_LOCK_DEVICE
          : TimeLimitsRuleNames.DISABLE_LOCK_DEVICE
      );
      break;
    case SettingsToggleNames.IsReportAlerts:
      trackTimeLimitsRule(
        toggleOn
          ? TimeLimitsRuleNames.ENABLE_ALERT_ME
          : TimeLimitsRuleNames.DISABLE_ALERT_ME
      );
      break;
    default:
      break;
  }
};

const TimeSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSettings);

export default TimeSettingsContainer;
