import { Store } from 'redux';
import { Routes } from '../constants';
import { setLoginRedirectLocation } from '../ducks/routing';
import { isBrowserPlatform } from '../helpers';
import { LOCATION_CHANGE } from '../helpers/multiPlatformNavigation';
import { getFeatureFlag } from '../selectors/featureFlags';
import { shouldBeLocked } from '../selectors/lockState';
import { RouteLocation } from '../types/RouteLocation.types';
import State, { BaseAction } from './state';

type RouteLocationAction = Required<BaseAction<string, RouteLocation>>;

const lockScreenMiddleware =
  (store: Store) =>
  (next: (action: RouteLocationAction) => void) =>
  (action: RouteLocationAction) => {
    const state = store.getState();

    const shouldIntercept =
      !isBrowserPlatform() &&
      isLockScreenActivated(state) &&
      isActionValid(action);

    if (!shouldIntercept) return next(action);

    store.dispatch(setLoginRedirectLocation(action.payload));

    // linter requires a return value
    return null;
  };

export default lockScreenMiddleware;

export const isLockScreenActivated = (state: State) =>
  getFeatureFlag(state, 'lockScreen') && shouldBeLocked(state);

export const isActionValid = (action: RouteLocationAction) =>
  action.type === LOCATION_CHANGE &&
  action?.payload &&
  action?.payload.pathname !== Routes.lockScreen;
