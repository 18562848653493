import { fromJS, NonEmptyMapRecord, Map } from 'immutable';
import * as moment from 'moment';
import api from '../api';
import { addToMoment } from '../helpers/dates';
import { APIError } from '../lib/errors';
import { studentSchoolTimesRecordOperations } from '../records/studentRules/schoolTimes';
import { studentPauseRecordOperations } from '../records/studentRules/studentPause';
import { SchoolTimesPayload } from '../records/studentRules/types/schoolTimes.type';
import { StudentPausePayload } from '../records/studentRules/types/studentPause.type';
import { getProfile } from '../selectors/profileRaw';
import State, { BaseThunk, Dispatch } from '../store/state';
import {
  StudentPoliciesAction,
  StudentPolicyError,
} from './types/action/StudentPoliciesActions.type';
import { ttl1Hour } from '../lib/QApiCache/commonCacheStrategies';
import { studentDelegationRecordOperations } from '../records/studentRules/studentDelegation';
import { studentActiveClassRecordOperations } from '../records/studentRules/studentActiveClass';
import { StudentDelegationPayload } from '../records/studentRules/types/studentDelegation.type';
import { StudentActiveClassPayload } from '../records/studentRules/types/studentActiveClass.type';

export default function studentPolicy(
  state: NonEmptyMapRecord<{
    isFetching: boolean;
    lastUpdated: number;
    lastFetchedStudentId?: number;
    studentPoliciesError?: StudentPolicyError;
  }> = fromJS({
    isFetching: false,
    lastUpdated: undefined,
    lastFetchedStudentId: undefined,
    studentPoliciesError: undefined,
  }),
  action: StudentPoliciesAction
) {
  switch (action.type) {
    case 'REQUEST_STUDENT_POLICIES':
      return state.set('isFetching', true);
    case 'RECEIVE_STUDENT_POLICIES':
    case 'RECEIVE_DELEGATION_STUDENT_POLICIES':
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
      });
    case 'CLEAR_STUDENT_POLICY':
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
      });
    case 'FINISH_LOADING_STUDENT_POLICIES':
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
      });
    case 'RESET_STUDENT_POLICY_ERROR':
      return state.merge({
        studentPoliciesError: undefined,
      });
    case 'RECEIVE_STUDENT_POLICY_ERROR':
      return state.merge({
        studentPoliciesError: action.payload,
      });
    default:
      return state;
  }
}

const getDefaultSerializedValues = () => {
  return {
    ...studentSchoolTimesRecordOperations.serialize(
      Map({
        schoolTimes: {
          ...studentSchoolTimesRecordOperations.getDefaultFieldValues(),
        },
      })
    ),
    ...studentPauseRecordOperations.serialize(
      Map({
        pause_24_7: {
          enabled:
            studentPauseRecordOperations.getDefaultFieldValues().pause247,
        },
        pause: {
          ...studentPauseRecordOperations.getDefaultFieldValues(),
        },
      })
    ),
  } as SchoolTimesPayload & StudentPausePayload;
};

export const receiveStudentsPolicies = (
  payload: SchoolTimesPayload &
    StudentPausePayload &
    StudentDelegationPayload &
    StudentActiveClassPayload
) => {
  const schoolTimes = studentSchoolTimesRecordOperations.fromPayload(payload);
  const studentPause = studentPauseRecordOperations.fromPayload(payload);
  const studentDelegation =
    studentDelegationRecordOperations.fromPayload(payload);
  const studentActiveClass =
    studentActiveClassRecordOperations.fromPayload(payload);
  return {
    type: 'RECEIVE_STUDENT_POLICIES',
    payload: {
      records: {
        schoolTimes,
        studentPause,
        studentDelegation,
        studentActiveClass,
      },
    },
    receivedAt: Date.now(),
  } as StudentPoliciesAction;
};

export const updateDelegationPolicies = (payload: StudentDelegationPayload) => {
  const studentDelegation =
    studentDelegationRecordOperations.fromPayload(payload);

  return {
    type: 'RECEIVE_DELEGATION_STUDENT_POLICIES',
    payload: {
      records: {
        studentDelegation,
      },
    },
    receivedAt: Date.now(),
  };
};

export const requestStudentPolicies = (): StudentPoliciesAction => {
  return {
    type: 'REQUEST_STUDENT_POLICIES',
  };
};

export const clearStudentPoliciesRecords = (): StudentPoliciesAction => {
  return {
    type: 'CLEAR_STUDENT_POLICY',
    payload: {
      records: {
        schoolTimes: studentSchoolTimesRecordOperations.getDefaultFieldValues(),
        studentPause: studentPauseRecordOperations.getDefaultFieldValues(),
        studentDelegation:
          studentDelegationRecordOperations.getDefaultFieldValues(),
      },
    },
    receivedAt: Date.now(),
  };
};

export const finishLoadingStudentPolicies = () => ({
  type: 'FINISH_LOADING_STUDENT_POLICIES',
  receivedAt: Date.now(),
});

export const fetchStudentPolicies =
  (profileId: number, removeCache = false) =>
  async (dispatch: Dispatch<StudentPoliciesAction>, getState: () => State) => {
    await dispatch(resetStudentPoliciesError());
    await dispatch(requestStudentPolicies());
    const profileUid = getProfile(getState(), profileId).get('uid');
    let apiResponse;
    try {
      apiResponse = await api.studentPolicies
        .withCache(ttl1Hour, { removeWhen: () => removeCache })
        .get({ profileUid });
    } catch (error) {
      if (
        error.status === 410 ||
        error.status === 403 ||
        error.status === 502
      ) {
        await dispatch(receiveStudentsPolicies(getDefaultSerializedValues()));
        return;
      }
      handleStudentPolicyError(error);
      throw error;
    }
    await dispatch(receiveStudentsPolicies(apiResponse));
  };

// Pause
export const postStudentPause =
  (profileId: number, durationInMinutes: number) =>
  async (dispatch: Dispatch<StudentPoliciesAction>, getState: () => State) => {
    const pauseEndTimeInDatetime = addToMoment(
      durationInMinutes,
      'minutes',
      moment.utc()
    );
    const profileUid = getProfile(getState(), profileId).get('uid');
    await dispatch(requestStudentPolicies());
    await api.studentPoliciesPause.post(
      {
        end_timestamp: pauseEndTimeInDatetime.unix(),
      },
      {},
      { profileUid }
    );

    await dispatch(fetchStudentPolicies(profileId));
  };

export const deleteStudentPause =
  profileId => async (dispatch: Dispatch, getState: () => State) => {
    const profileUid = getProfile(getState(), profileId).get('uid');
    await dispatch(requestStudentPolicies());
    await api.studentPoliciesPause.delete({ profileUid });
    await dispatch(fetchStudentPolicies(profileId));
  };

export const handleStudentPolicyError =
  (error: APIError): BaseThunk<void> =>
  dispatch => {
    if (error.status === 412) {
      return dispatch(receiveSummaryError(StudentPolicyError.FeatureDisabled));
    }
    if (error.status === 410) {
      return dispatch(receiveSummaryError(StudentPolicyError.Unlinked));
    }
    return dispatch(
      receiveSummaryError(StudentPolicyError.UnhandledServerError)
    );
  };

export const resetStudentPoliciesError = (): StudentPoliciesAction => ({
  type: 'RESET_STUDENT_POLICY_ERROR',
});

export const receiveSummaryError = (
  error: StudentPolicyError
): StudentPoliciesAction => ({
  type: 'RECEIVE_STUDENT_POLICY_ERROR',
  payload: error,
});
