import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import { RoutineRecord } from '../../../records/routines/types/Routine.types';

export interface CantEnableTimeSlotsModalProps {
  routine?: RoutineRecord;
  onClickClose: () => void;
}

const CantEnableTimeSlotsModal = ({
  routine,
  onClickClose,
}: CantEnableTimeSlotsModalProps) => {
  const MODAL_TEST_ID = 'cant-enable-time-slot';

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-cant-enable-time-slots-modal"
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={t('Can’t enable time slots')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-ok`}
          key="button1"
          onClick={onClickClose}
          disabled={false}
          size={ButtonSize.medium}
          block
        >
          {t('Ok')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t(
            'Some time slots overlap with {{routineName}} so can’t be enabled.',
            {
              routineName: routine?.name,
            }
          )}
        </ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default CantEnableTimeSlotsModal;
