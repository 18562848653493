import * as React from 'react';
import { Modal, Icon, IconType, Button, ButtonSize } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants/index';

const AdditionalParentWelcomeModal = ({ onClose }: { onClose: () => void }) => (
  <Modal
    testId="additionalParentWelcome"
    title={t('Welcome to {{shortName}}!')}
    header={{
      icon: <Icon type={IconType.users} />,
    }}
    onClickClose={onClose}
    height={modalDimensions.regular.height}
    width={modalDimensions.regular.width}
    buttons={[
      <Button
        key="letsStart"
        onClick={onClose}
        size={ButtonSize.medium}
        block
        testId="letsStart"
      >
        {t('Let’s start!')}
      </Button>,
    ]}
  >
    {t('You have now joined as an additional parent.')}
  </Modal>
);

export default AdditionalParentWelcomeModal;
