import * as React from 'react';
import { EventRecord } from '../../records';
import { EventType } from '../../constants';

const ActivityTimelineItemThumbnail: React.FunctionComponent<{
  event: EventRecord;
}> = ({ event }) => {
  let itemText;

  switch (event.type) {
    case EventType.FacebookSocialActivityUserSharePhoto:
      itemText = <img src={event.socialObjectPic} alt="" />;
      break;
    case EventType.FacebookSocialMediaUserPhoto:
      itemText = <img src={event.pictureBig} alt="" />;
      break;
    default:
  }

  if (!itemText) {
    return null;
  }

  return <div className="ActivityTimelineItemThumbnail">{itemText}</div>;
};

export default ActivityTimelineItemThumbnail;
