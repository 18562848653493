import * as React from 'react';
import { t } from '../../lib/i18n';
import PanicButtonRulesFlyover from './PanicButtonRulesFlyover';
import type { PanicButtonRulesAndroidFlyoverProps } from '../../containers/ProfileRules/PanicButtonRules/PanicButtonRulesAndroidFlyoverContainer';

const FLYOVER_TEST_ID = 'panic-button-rules-android-flyover';

const PanicButtonRulesAndroidFlyover = ({
  accountLocale,
  onAddDevice,
  onClickClose,
}: PanicButtonRulesAndroidFlyoverProps) => {
  return (
    <PanicButtonRulesFlyover
      accountLocale={accountLocale}
      testId={FLYOVER_TEST_ID}
      actionButton={{
        title: t('Add a device'),
        onClick: onAddDevice,
        testId: `${FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android')}
    />
  );
};

export default PanicButtonRulesAndroidFlyover;
