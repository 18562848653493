import { BiometricResult } from '../constants';
import { isBiometricError } from '../helpers/biometric';
import { captureException } from '../helpers/sentry';
import { APPThunk } from '../helpers/thunks';
import { t } from '../lib/i18n';
import { Dispatch } from '../store/state';
import { unlock, lock } from './LockScreenActions';

export const pluginIsBiometricLoginAvailable = (
  configuration: BiometricIsAvailableConfigurationProps
): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (!global.Fingerprint) {
      reject(new Error('Biometric plugin is not installed'));
    }

    global.Fingerprint.isAvailable(
      () => resolve(true),
      (error: BiometricError) => reject(error),
      configuration
    );
  });

export const pluginShowBiometricLogin = (
  configuration: BiometricShowConfigurationProps
): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (!global.Fingerprint) {
      reject(new Error('Biometric plugin is not installed'));
    }

    global.Fingerprint.show(
      configuration,
      () => resolve(true),
      (error: BiometricError) => reject(error)
    );
  });

export const showBiometricLogin = () => (dispatch: Dispatch) => {
  pluginShowBiometricLogin({
    title: t('Use Face ID or your fingerprint for faster access.'),
    description: t('Use Face ID or your fingerprint for faster access.'), // iOS
    fallbackButtonTitle: t('Cancel'),
    cancelButtonTitle: t('Cancel'), // Android only
    disableBackup: true,
  })
    .then(() => {
      dispatch(unlock());
    })
    .catch((error: Error | BiometricError) => {
      if (isBiometricError(error)) {
        // In case the login has been cancelled, we don't want to manage an error
        if (error.code !== global.Fingerprint.BIOMETRIC_DISMISSED) {
          dispatch(lock());
          // We capture the exception instead of rethrowing
          captureException(
            new Error(`BiometricError (${error.code}): ${error.message}`)
          );
        }
      } else {
        // This case should only happen if the Biometric plugin is not installed or is not available
        throw error;
      }
    });
};

export const shouldShowBiometricLogin = async (): Promise<BiometricResult> =>
  new Promise(resolve =>
    pluginIsBiometricLoginAvailable({ allowBackup: false })
      .then(() => resolve({ show: true }))
      .catch(() => resolve({ show: false }))
  );

export const checkBiometricLogin = (): APPThunk => async dispatch => {
  const biometricLogin = await shouldShowBiometricLogin();
  if (biometricLogin.show) {
    dispatch(showBiometricLogin());
  }
};
