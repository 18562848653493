import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  GlobalType,
  Link,
  Tag,
  Typography,
} from 'styleguide-react';
import WellbeingCard from '../shared/WellbeingCard';
import WellbeingGradientBackgroundImageSection from '../shared/WellbeingGradientBackgroundImageSection';
import { t } from '../../../lib/i18n';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';

interface WellbeingSubscribedToActivitiesProps {
  goToTherapy: () => void;
  onNeedHelpClick: () => void;
  nextActivity: string;
}

const WellbeingSubscribedToActivities = ({
  goToTherapy,
  nextActivity,
  onNeedHelpClick,
}: WellbeingSubscribedToActivitiesProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <FlexLayout
          mainaxis="column"
          width="100%"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
        >
          <BackgroundGradient
            gradient={
              isDesktop(screenSize)
                ? 'yolk-corner-right-large'
                : 'yolk-corner-right-small'
            }
          >
            <FlexLayout
              mainaxis="column"
              padding={isDesktop(screenSize) ? '0' : '16'}
              marginTop={isDesktop(screenSize) ? '48' : '8'}
              width="100%"
            >
              <FlexLayout mainaxis="column" alignSelf="center" maxWidth="800">
                <WellbeingCard type="activities" nextActivity={nextActivity} />
              </FlexLayout>
            </FlexLayout>
          </BackgroundGradient>

          <WellbeingGradientBackgroundImageSection showImage>
            <FlexLayout
              mainaxis="column"
              width="100%"
              marginTop="16"
              mainaxisAlignment="center"
            >
              <FlexLayout
                mainaxis={isDesktop(screenSize) ? 'row' : 'column'}
                alignSelf="center"
              >
                <Typography type="h4" weight="semibold" align="center">
                  {t('Family therapy')}
                </Typography>
                <FlexLayout
                  mainaxis="column"
                  marginLeft={isDesktop(screenSize) ? '8' : '0'}
                  marginBottom={isDesktop(screenSize) ? '0' : '24'}
                >
                  <Tag
                    type={GlobalType.error}
                    text={t('Includes wellbeing activities')}
                  />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout mainaxis="column" maxWidth="584" alignSelf="center">
                <Typography type="body1" align="center">
                  {t(
                    'Discover a unique experience that combines your child’s Qustodio insights with the latest techniques in family therapy.'
                  )}
                </Typography>
              </FlexLayout>
              <FlexLayout mainaxis="column" marginTop="16" alignSelf="center">
                <Button centered onClick={goToTherapy} size={ButtonSize.medium}>
                  {t('See how it works')}
                </Button>
              </FlexLayout>
              <FlexLayout
                mainaxis="column"
                marginTop="48"
                marginBottom="48"
                alignSelf="center"
              >
                <Link onClick={onNeedHelpClick}>
                  <Typography type="body1" weight="semibold">
                    {t('Need help? Get in touch')}
                  </Typography>
                </Link>
              </FlexLayout>
            </FlexLayout>
          </WellbeingGradientBackgroundImageSection>
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

export default WellbeingSubscribedToActivities;
