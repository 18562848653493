import * as React from 'react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  ModalStyledText,
} from 'styleguide-react';
import { LicenseRecord } from '../../records';
import { getProperLicenseText } from '../../records/license';

const WelcomeToPremiumModal: React.FunctionComponent<{
  license: LicenseRecord;
  onClickLink: () => any;
}> = ({ license, onClickLink }) => (
  <Modal
    width={modalDimensions.regular.width}
    onClickClose={onClickLink}
    header={{
      icon: <Icon type={IconType.rocket} />,
    }}
    title={t('Welcome to Premium!')}
    buttons={[
      <Button key="button" size={ButtonSize.medium} block onClick={onClickLink}>
        {t('Enjoy Premium!')}
      </Button>,
    ]}
  >
    <ModalStyledText>
      {getProperLicenseText(
        license.subtype,
        t(
          'Congratulations! Your license has been upgraded and ' +
            'you now have access to {{planName}} features on up to {{maxDevices}} devices.',
          {
            planName: 'Basic',
            maxDevices: license.maxDevices,
          }
        ),
        t(
          'Congratulations! Your license has been upgraded and ' +
            'you now have access to {{planName}} features on up to {{maxDevices}} devices.',
          {
            planName: 'Complete',
            maxDevices: license.maxDevices,
          }
        ),
        t(
          'Congratulations! Your license has been upgraded and ' +
            'you now have access to all Premium features on up to {{maxDevices}} devices.',
          {
            maxDevices: license.maxDevices,
          }
        )
      )}
    </ModalStyledText>
  </Modal>
);

export default WelcomeToPremiumModal;
