import { connect } from 'react-redux';
import SmartDevicesStatus from '../../components/SmartDevicesStatus/SmartDevicesStatus';
import {
  DeviceStatus,
  getVirtualSchoolDevice,
  toDevicesWithStatus,
} from '../../businessLogic/devices';
import { navigateToAddDeviceAction } from '../../actions/ProfileActions';
import State, { Dispatch, RouterParams } from '../../store/state';
import { fetchStudentPolicies } from '../../ducks/studentPolicies';
import { isProfileLinkedWithLinewizeStudent } from '../../selectors/profile';
import { getLocale, getTimezone } from '../../selectors';
import { navigateToSmartDeviceInfoModal } from '../../actions/Navigation';
import {
  SmartDeviceStatusLoadedAction,
  SmartDevicesAnalyticsReport,
  updateIsFetchingSmartDeviceStatusAction,
} from '../../ducks/smartDeviceStatus';
import { getIsFetchingSmartDeviceStatus } from '../../ducks/smartDeviceStatus/selector';
import { ButtonNames, trackButtonClicked } from '../../helpers/analytics';
import { getCurrentDashboardPageName } from '../../ducks/unifiedNowCard/thunks';

export interface NavigateToInfoModalProps {
  status: DeviceStatus['status'];
  affiliation: DeviceStatus['affiliation'];
  id: number | null;
}

export type NavigateToDeviceInfoModal = ({
  status,
  affiliation,
  id,
}: NavigateToInfoModalProps) => void;

export const fetchStudentPoliciesIfIsLinkedWithLinewize =
  profileId => async (dispatch: Dispatch, getState: () => State) => {
    dispatch(updateIsFetchingSmartDeviceStatusAction('LOADING'));
    const isLinked = isProfileLinkedWithLinewizeStudent(getState(), profileId);

    if (isLinked) {
      await dispatch(fetchStudentPolicies(profileId));
    }
    dispatch(updateIsFetchingSmartDeviceStatusAction('LOADED'));
  };

const mapStateToProps = (state: State, { profileId }: RouterParams) => {
  const devices = toDevicesWithStatus(profileId, state);
  const isLinked = isProfileLinkedWithLinewizeStudent(state, profileId);
  const timezone = getTimezone(state);
  const locale = getLocale(state) ?? 'en';
  return {
    profileId,
    devices: isLinked
      ? [getVirtualSchoolDevice(state, profileId), ...devices]
      : devices,
    isProfileLinkedWithLinewize: isLinked,
    timezone,
    locale,
    isLoaded: getIsFetchingSmartDeviceStatus(state) === 'LOADED',
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAddNew: () => {
      dispatch((_dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const pageName = getCurrentDashboardPageName(state);
        trackButtonClicked(pageName, ButtonNames.ProtectADevice);
      });
      dispatch(navigateToAddDeviceAction());
    },
    loadData: profileId => {
      dispatch(fetchStudentPoliciesIfIsLinkedWithLinewize(profileId));
    },
    cleanData: () => {
      dispatch(updateIsFetchingSmartDeviceStatusAction('LOADING'));
    },
    navigateToInfoModal: ({
      status,
      affiliation,
      id,
    }: NavigateToInfoModalProps) => {
      dispatch(navigateToSmartDeviceInfoModal(status, affiliation, id));
    },
    reportToAnalytics: (devices: Array<SmartDevicesAnalyticsReport>) => {
      dispatch(SmartDeviceStatusLoadedAction(devices));
    },
  };
};

const SmartDevicesStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartDevicesStatus);

export default SmartDevicesStatusContainer;
