import { GlobalType, theme } from 'styleguide-react';

export enum RoutineColor {
  yellow = 'yellow',
  red = 'red',
  purple = 'purple',
  pink = 'pink',
  blue = 'blue',
  green = 'green',
  marine = 'marine',
}

const routinesColorMap = {
  [RoutineColor.yellow]: theme.routinesPalette.yellow,
  [RoutineColor.red]: theme.routinesPalette.red,
  [RoutineColor.purple]: theme.routinesPalette.purple,
  [RoutineColor.pink]: theme.routinesPalette.pink,
  [RoutineColor.blue]: theme.routinesPalette.blue,
  [RoutineColor.green]: theme.routinesPalette.green,
  [RoutineColor.marine]: theme.routinesPalette.marine,
  // Todo: replace this with 'RoutineColor.brand' when we migrate routines sass colors to PAR
  [GlobalType.secondary]: theme.palette.secondaryLighter,
};

const routinesColorInverseMap: Record<string, RoutineColor> =
  Object.fromEntries(Object.entries(routinesColorMap).map(a => a.reverse()));

export const getRoutineColorMap = () => routinesColorMap;

export const getRoutineColorKey = (colorValue: string) => {
  if (colorValue in routinesColorInverseMap) {
    return routinesColorInverseMap[colorValue];
  }
  return undefined;
};

export const getRoutineColorValue = (colorKey: RoutineColor) => {
  if (colorKey in routinesColorMap) {
    return routinesColorMap[colorKey];
  }
  return undefined;
};
