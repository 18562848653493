import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  SafeNetworksSettingsPayload,
  SafeNetworksSettings,
} from './types/safeNetworkSetting';

export const SafeNetworksSettingsOperation = getRecordOperations<
  SafeNetworksSettings,
  SafeNetworksSettingsPayload
>({
  defaultFields: {
    isEligibleForOptInCampaign: false,
    isCampaignDismissed: false,
    disableProtectionInSafeNetwork: false,
  },
});
