import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import LocationRulesFlyover from './LocationRulesFlyover';
import type { LocationRulesAddDeviceFlyoverProps } from '../../containers/ProfileRules/LocationRules/LocationRulesAddDeviceFlyoverContainer';

const ADD_DEVICE_FLYOVER_TEST_ID = 'location-rules-add-device-flyover';

const LocationRulesAddDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickAddDevice,
}: LocationRulesAddDeviceFlyoverProps) => {
  return (
    <LocationRulesFlyover
      accountLocale={accountLocale}
      testId={ADD_DEVICE_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Add a device'),
        onClick: onClickAddDevice,
        testId: `${ADD_DEVICE_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android and iOS')}
      topbarText={t(
        'Protect an Android or iOS device to monitor your child’s location'
      )}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default LocationRulesAddDeviceFlyover;
