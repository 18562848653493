import { Record, List } from 'immutable';

export enum YoutubeMeasures {
  totalVideos = 'total_videos',
  totalSearches = 'total_searches',
  lastSearches = 'last_searches',
  lastVideos = 'last_videos',
}

type YoutubeSummary = {
  totalVideos: number;
  totalSearches: number;
  lastSearches: List<string>;
};

export type YoutubeSummaryRecord = Record<YoutubeSummary>;

export const YoutubeSummaryRecord = Record<
  YoutubeSummary,
  {
    fromPayload: (response: any) => YoutubeSummaryRecord;
  }
>({
  totalVideos: 0,
  totalSearches: 0,
  lastSearches: List(),
});

YoutubeSummaryRecord.fromPayload = payload => {
  const totalVideos = (payload[YoutubeMeasures.totalVideos] || []).reduce(
    (accum, total) => accum + total[YoutubeMeasures.totalVideos],
    0
  );
  const totalSearches = (payload[YoutubeMeasures.totalSearches] || []).reduce(
    (accum, total) => accum + total[YoutubeMeasures.totalSearches],
    0
  );
  const lastSearches = (payload[YoutubeMeasures.lastSearches] || []).map(
    search => search.text
  );
  return YoutubeSummaryRecord({
    totalVideos,
    totalSearches,
    lastSearches: List(lastSearches),
  });
};

export const createUniqueResponseObjectWithSummaryJsons = jsons => ({
  [YoutubeMeasures.totalVideos]: jsons[0],
  [YoutubeMeasures.totalSearches]: jsons[1],
  [YoutubeMeasures.lastSearches]: jsons[2],
});
