import * as React from 'react';
import App from './App';

const Root: React.FunctionComponent<{}> = () => (
  <div className="ReactRoot">
    <App />
  </div>
);

export default Root;
