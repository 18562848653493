import { useState, useEffect, PointerEvent } from 'react';

/**
 * Guesses the type of input device based on the available features and media queries.
 * It checks if the device has a coarse pointer using the matchMedia function.
 * @Returns the guessed input type, which can be either "mouse", "pen" or "touch".
 */
export const useGuessInputDevice = () => {
  const [type, setType] = useState<PointerEvent['pointerType']>('mouse'); // default value

  useEffect(() => {
    // when touch device
    if (
      window.matchMedia &&
      window.matchMedia('(any-pointer: coarse)').matches
    ) {
      setType('touch');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (e: any) => {
      if ((e as PointerEvent)?.pointerType) {
        setType((e as PointerEvent)?.pointerType);
      }
    };

    ['onPointerEnter', 'onPointerLeave'].forEach(key => {
      window.addEventListener(key.slice(2).toLowerCase(), listener);
    });

    return () => {
      ['onPointerEnter', 'onPointerLeave'].forEach(key => {
        window.removeEventListener(key.slice(2).toLowerCase(), listener);
      });
    };
  }, []);

  return type;
};
