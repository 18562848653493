import { EMPTY, Observable, merge, of, pipe } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AppAction,
  AppEpic,
  AppStateObservable,
  Dependencies,
} from '../../../epics/types';
import {
  RequestResetSummaryAlertsCounterAction,
  ReceiveResetSummaryAlertsCounterAction,
} from '../types/actions.types';
import {
  receiveResetSummaryAlertsCounterAction,
  requestResetSummaryAlertsCounterAction,
} from '../actions';
import { whenIsEnabledBySummaryAlertsFeature } from './helpers';
import { getSummaryAlertCounter } from '../selectors';
import State from '../../../store/state';
import { ofLocationChangeActionWhen } from '../../../epics/router';
import { getProfileUid } from '../../../selectors';
import {
  getTypeByFilter,
  shouldResetAlertCounterFromNavigation,
} from '../../../businessLogic/summaryAlerts';
import {
  ScreenSize,
  hasScreenSize,
} from '../../../components/RenderWhen/RenderWhen';
import { captureException } from '../../../helpers/sentry';
import { showErrorAlert } from '../../../helpers/errorHandling';

const hasAlertCounter = (state: State, profileUuid: string) => {
  return getSummaryAlertCounter(state, profileUuid, 'searches') > 0;
};

/**
 *
 * Create an observable of UUIDs, ensuring that any subscriber will at least receive the last emitted one.
 */
const getProfileUUid = (state: AppStateObservable) =>
  state.pipe(
    map(state => getProfileUid(state)!),
    filter(uuid => uuid !== undefined),
    shareReplay(1)
  );

const getResetActionsFromDispatch = ofType(
  'REQUEST_RESET_SUMMARY_ALERTS_COUNTER'
);

const getResetActionsFromNavigation =
  (profileUuids: Observable<string>) => (actions$: Observable<AppAction>) => {
    return actions$.pipe(
      ofLocationChangeActionWhen((navigationFrom, navigationTo) => {
        return shouldResetAlertCounterFromNavigation({
          navigationFrom,
          navigationTo,
          isDesktop: hasScreenSize(ScreenSize.Desktop),
        });
      }),
      /*
      Due to how the application architecture, 
      the selector that retrieves the profile UUID uses the routing state. 
      This means that depending on the route, the UUID may not be present. 
      When we navigate, the route has already disappeared, and therefore, 
      the profile UUID is no longer available. That's why we need to retrieve the latest one.
      */
      withLatestFrom(profileUuids),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_navigation, uuid]) =>
        requestResetSummaryAlertsCounterAction(uuid, 'searches')
      )
    );
  };

const doRequestToResetAlertCounterIfIsNeeded = (
  state: AppStateObservable,
  { api }: Dependencies
) =>
  pipe(
    switchMap(action =>
      of(action as RequestResetSummaryAlertsCounterAction).pipe(
        filter(action =>
          hasAlertCounter(state.value, action.payload.profileUuid)
        ),
        switchMap(async requestAction => {
          const { payload } = requestAction;
          const profileUid = payload.profileUuid;
          const alertType = getTypeByFilter(payload.type);

          await api.summaryAlertsReviewed.put({ profileUid, alertType }, {});
          return receiveResetSummaryAlertsCounterAction(
            profileUid,
            payload.type
          );
        }),
        catchError((e: Error) => {
          captureException(e);
          showErrorAlert(e);
          return EMPTY;
        })
      )
    )
  );

export const resetSummaryAlertsCounterEpic: AppEpic<
  AppAction,
  ReceiveResetSummaryAlertsCounterAction
> = (actions$, state$, dependencies) => {
  const profileUuids = getProfileUUid(state$);

  const actionsWhenIsEnabled = actions$.pipe(
    whenIsEnabledBySummaryAlertsFeature(state$)
  );

  const resetActionsFromDispatch = actionsWhenIsEnabled.pipe(
    getResetActionsFromDispatch
  );

  const resetActionsFromNavigation = actionsWhenIsEnabled.pipe(
    getResetActionsFromNavigation(profileUuids)
  );

  const resetActions = merge(
    resetActionsFromDispatch,
    resetActionsFromNavigation
  );

  return resetActions.pipe(
    doRequestToResetAlertCounterIfIsNeeded(state$, dependencies)
  );
};
