import * as React from 'react';
import { t } from '../../lib/i18n';
import Flyover from '../Flyover/Flyover';
import socialButtonImage from '../../assets/base/images/v3/flyovers/illustration_socialmedia.svg';
import { Button, ButtonSize } from 'styleguide-react';

const SocialMonitoringRulesFlyover: React.FunctionComponent<{
  onClickClose: () => any;
  onClickUpgrade: () => any;
  profileName: () => any;
}> = ({ onClickClose, onClickUpgrade, profileName }) => (
  <Flyover
    description={t(
      "Upgrade to Premium {1}to turn on advanced monitoring{/1} of {{profileName}}'s Facebook activity",
      {
        profileName,
        1: str => <strong>{str}</strong>,
      }
    )}
    title={t('Facebook Monitoring')}
    illustrationSrc={socialButtonImage}
    onClickClose={onClickClose}
    button={
      <Button size={ButtonSize.medium} onClick={onClickUpgrade}>
        {t('Upgrade to premium')}
      </Button>
    }
  />
);

export default SocialMonitoringRulesFlyover;
