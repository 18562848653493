import { map } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';

import { anyPass } from 'ramda';
import {
  NavigationParams,
  onLocation,
  toRouterParams,
  isPathName,
} from '../../router';
import {
  SetAppDayDialDayAction,
  ShowAppDayDialAction,
  toggleDayDial,
} from '../../../ducks/profileRules/epics';
import {
  loadProfileData,
  RequestProfileErrorAction,
} from '../../../ducks/profiles/epics';
import { AppAction, AppEpic, AppStateObservable } from '../../types';

const appDailyTimeLimitPageRegExp =
  /profiles\/[0-9]*\/rules\/appRules\/[a-zA-Z0-9_.-]*(\/?)$/;

const appDailyTimeLimitModalRegExp =
  /profiles\/[0-9]*\/rules\/appRules\/modal\/appDailyTimeLimit\/[a-zA-Z0-9_.-]*(\/?)$/;

/*
getPageNavigation is is used as start point to load data
*/
const getPageNavigationParams = (
  action$: Observable<AppAction>,
  state$: AppStateObservable
): Observable<NavigationParams> => {
  return action$.pipe(
    onLocation(
      anyPass([
        isPathName(appDailyTimeLimitPageRegExp),
        isPathName(appDailyTimeLimitModalRegExp),
      ])
    ),
    toRouterParams(state$)
  );
};

type AppDailyTimeLimitAction =
  | SetAppDayDialDayAction
  | ShowAppDayDialAction
  | RequestProfileErrorAction
  | RequestProfileErrorAction;

const appDailyTimeLimitEpic: AppEpic<AppAction, AppDailyTimeLimitAction> = (
  action$,
  state$,
  { api }
) => {
  const navigationParams$ = getPageNavigationParams(action$, state$);

  const profileId$ = navigationParams$.pipe(
    map<NavigationParams, string>(params => params.profileId)
  );

  const appKey$ = navigationParams$.pipe(
    map<NavigationParams, string>(params => params.appKey)
  );

  const profileAction$ = loadProfileData(action$, profileId$, api);
  const toggleDialAction$ = toggleDayDial(appKey$, state$);

  return merge(profileAction$, toggleDialAction$);
};

export default appDailyTimeLimitEpic;
