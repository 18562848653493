import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import RemoveAllExceptionsModal from '../../../components/Routines/Modals/RemoveAllExceptionsModal';
import { getActiveProfileUid, getQueryParam } from '../../../selectors';
import {
  setRoutineMachineAction,
  updateRoutine,
} from '../../../ducks/routines';
import { getRoutine } from '../../../ducks/routines/selectors';
import { RoutineOperations } from '../../../records/routines/routine';
import { List } from 'immutable';
import {
  RoutineMode,
  RoutineRuleExceptionType,
} from '../../../components/Routines/routines.types';
import { safeDispatchChangeMachineAction } from '../helpers';
import { PolicyAction } from '../../../records/routines/policy/types/Policy.types';

const updateRoutineThunk =
  (profileUid: string, routineUid: string, type: RoutineRuleExceptionType) =>
  (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const routine = getRoutine(state, routineUid);
    const updatedRoutine = routine.setIn(
      type === 'WEB'
        ? ['policy', 'webRules', 'webRulesList']
        : ['policy', 'appRules', 'appRulesList'],
      List([])
    );

    const body = RoutineOperations.serialize(updatedRoutine);
    dispatch(updateRoutine(profileUid, routineUid, body));
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
  const back = () => dispatch(goBackIfHistory());
  return {
    onClickConfirm: (
      profileUid: string | null,
      routineUid: string | null,
      mode: RoutineMode,
      type: RoutineRuleExceptionType
    ) => {
      if (mode === 'EDIT' && routineUid && profileUid) {
        dispatch(updateRoutineThunk(profileUid, routineUid, type));
      }

      if (mode === 'CREATE' && type) {
        safeDispatchChangeMachineAction(
          dispatch,
          setRoutineMachineAction({
            type: 'UPDATE_CONTEXT',
            params: {
              contentFilter:
                type === 'APP' ? { appsException: [] } : { websException: [] },
            },
          })
        );
      }

      back();
    },
    onClickClose: back,
  };
};

const mapStateToProps = (state: State) => {
  const profileUid = getActiveProfileUid(state);
  const routineUid = getQueryParam(state, 'routineUid');
  const policyAction = getQueryParam(state, 'policyAction') as PolicyAction;
  const mode = getQueryParam(state, 'mode') as RoutineMode;
  const type = getQueryParam(state, 'type') as RoutineRuleExceptionType;

  return {
    profileUid,
    routineUid,
    policyAction,
    type,
    mode,
  };
};

const RemoveAllExceptionsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveAllExceptionsModal);

export type RemoveAllExceptionsModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default RemoveAllExceptionsModalContainer;
