import { EMPTY, merge, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  AppAction,
  AppEpic,
  AppStateObservable,
  Observable,
} from '../../epics/types';
import { getAppDayDial, setAppQuotas } from '../../selectors';
import { getToday } from '../../selectors/shared';
import {
  delayedUpdateProfileRules,
  requestProfilesRulesError,
  setAppDayDialDay,
  showAppDayDial,
} from '.';
import { RequestModifyQuotaAction } from '../../actions/AppRulesActions';
import { updateProfileRulesRecord } from '../records';
import { mergeMapThunkActions } from '../../epics/operators';
import { showErrorAlert } from '../../helpers/errorHandling';
import { APIError } from '../../lib/errors';

export type SetAppDayDialDayAction = ReturnType<typeof setAppDayDialDay>;

export type ShowAppDayDialAction = ReturnType<typeof showAppDayDial>;

export type UpdateProfileRulesRecordAction = ReturnType<
  typeof updateProfileRulesRecord
>;

export type RequestProfilesRulesError = ReturnType<
  typeof requestProfilesRulesError
>;

export const toggleDayDial = (
  appKey$: Observable<string>,
  state$: AppStateObservable
): Observable<SetAppDayDialDayAction | ShowAppDayDialAction> => {
  const setDialogDayAction$ = appKey$.pipe(
    map(appKey => ({
      appKey,
      currentDay: getToday(state$.value),
    })),
    map(({ currentDay }) => setAppDayDialDay(currentDay))
  );

  const showAppDayDialAction$ = appKey$.pipe(
    map(appKey => ({
      appKey,
      showDayDialAppKey: getAppDayDial(state$.value),
    })),
    map(({ appKey, showDayDialAppKey }) =>
      showAppDayDial(showDayDialAppKey !== appKey ? appKey : null)
    )
  );

  return merge(setDialogDayAction$, showAppDayDialAction$);
};

export const modifyQuota: AppEpic<
  AppAction,
  UpdateProfileRulesRecordAction | RequestProfilesRulesError
> = (actions$, state$) => {
  return actions$.pipe(
    ofType('REQUEST_APP_RULES_MODIFY_QUOTAS'),
    switchMap((action: RequestModifyQuotaAction) => {
      return of(action).pipe(
        mergeMapThunkActions((action: RequestModifyQuotaAction) => {
          const {
            appKey,
            profileId,
            quotas,
            action: appAction,
          } = action.payload;

          const updateProfileRules = profileRules =>
            setAppQuotas(
              profileRules,
              { appKey, profileId, quotas, action: appAction },
              state$.value
            );

          return delayedUpdateProfileRules(profileId, updateProfileRules);
        }, state$),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(EMPTY);
        })
      );
    })
  );
};
