import { connect } from 'react-redux';
import {
  navigateToNextStudent,
  updateProfileBirthDateAndGoToNextStudentProfile,
} from '../../actions/onboarding/schools/OnboardingSchoolsActions';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import CompleteStudent from '../../components/Schools/CompleteStudent';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
  trackOpenedBurgerMenu,
} from '../../helpers/analytics';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { getStudentFromProfile } from '../../selectors/student';
import store from '../../store';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State, { params: { studentUid } }) => ({
  student: getStudentFromProfile(studentUid, state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { studentUid } }
) => ({
  onClickNext: (
    student: ProfileRecord,
    birthDate: ProfileRecord['birthDate']
  ) => {
    trackButtonClicked(GenericPageNames.StudentSetup, ButtonNames.Save, {
      profile_id: studentUid,
    });

    dispatch(
      updateProfileBirthDateAndGoToNextStudentProfile(
        studentUid,
        student,
        birthDate
      )
    );
  },
  onClickSkip: () => {
    trackButtonClicked(GenericPageNames.StudentSetup, ButtonNames.Skip, {
      profile_id: studentUid,
    });
    dispatch(navigateToNextStudent(studentUid));
  },
});

const CompleteStudentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteStudent);

export default OnboardingWrapper({
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(CompleteStudentContainer);

export type CompleteStudentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
