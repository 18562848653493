import * as React from 'react';
import { t } from '../../../lib/i18n';
import { CallsSMSRulesProps } from '../../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import LabeledSwitch from '../switches/LabeledSwitch';

export type CallsSMSMessagesSectionProps = Pick<
  CallsSMSRulesProps,
  | 'callsSMSRulesEnabled'
  | 'monitorSMSContentEnabled'
  | 'onToggleMonitorSmsContentEnabled'
>;

const CallsSMSMessagesSection: React.FC<CallsSMSMessagesSectionProps> = ({
  callsSMSRulesEnabled,
  monitorSMSContentEnabled,
  onToggleMonitorSmsContentEnabled,
}: CallsSMSMessagesSectionProps) => (
  <div className="messages-section">
    {LabeledSwitch({
      label: t('Show message content'),
      id: 'show-message-content',
      checked: callsSMSRulesEnabled && monitorSMSContentEnabled,
      disabled: !callsSMSRulesEnabled,
      onChange: ev => {
        onToggleMonitorSmsContentEnabled(
          (ev.target as HTMLInputElement).checked
        );
      },
      subtitle: t(
        'Display the content of incoming and outgoing messages on the timeline.'
      ),
    })}
  </div>
);

export default CallsSMSMessagesSection;
