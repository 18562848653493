import * as React from 'react';
import {
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  TextField,
} from 'styleguide-react';
import { FiltersAndSearchFormProps } from '../types/FiltersAndSearchFormProps.types';
import { t } from '../../../../../lib/i18n';

const FiltersAndSearchForm = ({
  testId,
  searchQuery,
  onChangeSearch,
}: FiltersAndSearchFormProps) => {
  return (
    <React.Fragment>
      <FlexLayout mainaxis="column" marginBottom="40">
        <form onSubmit={e => e.preventDefault()}>
          <TextField
            testId={`${testId}-input-search`}
            block
            size="small"
            iconLeft={
              <Icon
                type={IconType.search}
                color={IconColor.black}
                size={IconSize.xs}
              />
            }
            name="search"
            type="text"
            keepErrorLabelArea={false}
            placeholder={t('Search all apps')}
            onChange={onChangeSearch}
            value={searchQuery}
            canBeCleared
            autoComplete="off"
          />
        </form>
      </FlexLayout>
    </React.Fragment>
  );
};

export default FiltersAndSearchForm;
