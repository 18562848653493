/**
 * Returns the browser language predefined by the user
 */
export const getBrowserLanguage = () => navigator.language.toLocaleLowerCase();

/**
 * Returns a reference to the browser document object
 */
export const getDocument = () => document;

export const getHostname = () => window.location.hostname;
