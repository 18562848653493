import {
  PaymentProvider,
  PAYMENT_PROVIDER_GOOGLEPLAY,
  PAYMENT_PROVIDER_APPLE,
  PAYMENT_PROVIDER_CHARGEBEE,
} from '../constants';
import { isiOSPlatform, isAndroidPlatform } from './index';

export const requiresInAppPurchasesPlugin = (
  paymentProvider: PaymentProvider
): boolean =>
  paymentProvider === PAYMENT_PROVIDER_APPLE ||
  paymentProvider === PAYMENT_PROVIDER_GOOGLEPLAY;

export const resolvePaymentProvider = (
  paymentProvider: PaymentProvider | undefined
): PaymentProvider => {
  if (paymentProvider) {
    return paymentProvider;
  }
  if (isiOSPlatform()) {
    return PAYMENT_PROVIDER_APPLE;
  }
  if (isAndroidPlatform()) {
    return PAYMENT_PROVIDER_GOOGLEPLAY;
  }
  return PAYMENT_PROVIDER_CHARGEBEE;
};
