import { LocationChangeAction } from 'react-router-redux';
import { EventCategoryType } from '../../components/ActivityTimeline/types';
import { EventRecord } from '../../records';
import State from '../../store/state';
import {
  isProfileListPage,
  isProfilePage,
  isTimelinePage,
} from '../../epics/router';
import { SummaryAlertsTypes } from '../../ducks/summaryAlerts/types/SummaryAlertsState.type';
import { getFeatureByCode } from '../../ducks/features/selectors';
import { isFeatureEnabled } from '../features';

export const isEnabledSummaryAlertsFeature = (state: State) => {
  const feature = getFeatureByCode(state, 'threat_alerts_search');
  if (!feature) return false;
  return isFeatureEnabled(feature);
};

/**
 *
 * This method is used to determine if an event is of type alert or not;
 * one of the requirements is that, regardless of its type,
 * it is important to consider the counter.
 * Only the last n events should be highlighted,
 * as determined by the alert counter.
 *
 * It is important to note that the function is partially applied
 * because it needs to encapsulate the state of the counter decrement since it is used to
 * map events separated by days.
 */
export const getSummaryAlertType = (alertsCounter: number) => {
  let count = alertsCounter;
  return (event: EventRecord): EventCategoryType => {
    if (count > 0 && event.searchInappropriate) {
      count -= 1;
      return 'alert-highlighted';
    }

    if (count <= 0 && event.searchInappropriate) {
      return 'alert';
    }

    return 'default';
  };
};

/**
 *
 * Determine if it is necessary to reset the alert counter based on navigation.
 *
 * To determine if it is necessary to reset the alert counter,
 * it is important to consider that in the mobile view,
 * the timeline is on the same page as the profile, while on desktop,
 * it has its own page. When accessed through a deep link,
 * both mobile and desktop will use the timeline page with a custom filter.
 *
 * The cases in which the alerts should be reset are:
 * 1. In the mobile/tablet view, when we leave the profile page.
 * 2. In the desktop view, when we leave the timeline page.
 * 3. In the mobile/tablet/desktop view, when we leave the timeline page with a custom filter.
 *
 * The exceptions to reset the counter are:
 * 1. In the mobile view, from the profile to a custom filter on the timeline, it should not reset the counter.
 *
 */
export const shouldResetAlertCounterFromNavigation = ({
  navigationFrom,
  navigationTo,
  isDesktop,
}: {
  navigationFrom: LocationChangeAction;
  navigationTo: LocationChangeAction;
  isDesktop: boolean;
}) => {
  if (
    !isDesktop &&
    isProfileListPage(navigationFrom) &&
    isProfilePage(navigationTo)
  ) {
    return false;
  }

  if (
    !isDesktop &&
    isProfilePage(navigationFrom) &&
    isTimelinePage(navigationTo)
  ) {
    return false;
  }

  if (
    !isDesktop &&
    isProfilePage(navigationFrom) &&
    isProfileListPage(navigationFrom)
  ) {
    return true;
  }

  if (!isDesktop && isProfilePage(navigationFrom)) {
    return true;
  }

  if (isTimelinePage(navigationFrom)) {
    return true;
  }

  return false;
};

export const getTypeByFilter = (type: SummaryAlertsTypes): 'search' => {
  if (type === 'searches') return 'search';
  throw new Error(`Invalid filter for Summary Alert not ${type}`);
};
