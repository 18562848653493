import * as React from 'react';
import {
  AlertBox,
  AlertBoxType,
  Card,
  FlexLayout,
  GlobalType,
  Icon,
  IconFamily,
  IconType,
  Layout,
  Typography,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import InfoItem from './InfoItem';
import LazyImage from '../LazyImage/LazyImage';
import PageLayout, { PageLayoutProps } from '../Layout/PageLayout';
import { mergeDeepRight } from 'ramda';
import PageSectionLayout from '../Layout/PageSectionLayout';
import {
  FlyoverAlertBoxProps,
  PremiumFlyoverProps,
} from './types/FullPremiumFlyoverProps.types';
import FooterActionButtons from './FooterActionButtons';

const FULL_PREMIUM_FLYOVER_TEST_ID = 'full-premium-flyover';

const getAlertBox = (
  topbarText: string,
  alertBoxProps?: FlyoverAlertBoxProps
) => {
  return (
    <AlertBox
      fullWidth
      type={alertBoxProps?.type ? alertBoxProps.type : AlertBoxType.booster}
      className="par-full-premium-flyover__alertbox"
      icon={
        alertBoxProps?.icon ? (
          alertBoxProps.icon
        ) : (
          <Icon type={IconType.rocketLaunch} family={IconFamily.regular} />
        )
      }
    >
      {topbarText}
    </AlertBox>
  );
};

const FullPremiumFlyover = ({
  testId,
  imageSrc,
  topbarText,
  title,
  infoItems,
  actionButton,
  footNote,
  pageLayoutProps,
  alertBoxProps,
  onClickClose,
}: PremiumFlyoverProps) => {
  const componentTestId = testId ?? FULL_PREMIUM_FLYOVER_TEST_ID;
  const mergedPageLayoutProps = mergeDeepRight(
    {
      configuration: [
        {
          screenSize: ScreenSize.TabletOrDesktop,
          columns: 1,
          mainColumnBackgroundColor: 'transparent',
          mainInsideCard: false,
          mainReducedSize: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          removePaddings: true,
        },
      ] as PageLayoutProps['configuration'],
      actions: [{ actionName: 'back', onClick: onClickClose }],
    },
    pageLayoutProps ?? {}
  );

  return (
    <RenderWhen screenSize={[ScreenSize.Mobile, ScreenSize.TabletOrDesktop]}>
      {screenSize => (
        <PageLayout {...mergedPageLayoutProps}>
          {topbarText && screenSize === ScreenSize.Mobile && (
            <PageSectionLayout placeholder="card-banner">
              {getAlertBox(topbarText, alertBoxProps)}
            </PageSectionLayout>
          )}

          <PageSectionLayout side="main">
            <Card
              header={
                topbarText && screenSize === ScreenSize.TabletOrDesktop
                  ? {
                      type: GlobalType.booster,
                      banner: getAlertBox(topbarText, alertBoxProps),
                    }
                  : undefined
              }
            >
              <FlexLayout
                mainaxis="column"
                mainaxisAlignment="space-between"
                crossaxisAlignment="center"
                testId={componentTestId}
              >
                <Layout
                  marginBottom="24"
                  textAlignement="center"
                  className="par-full-premium-flyover__anim-reveal"
                  minHeight="240"
                >
                  <LazyImage src={imageSrc} width="280px" alt="premium" />
                </Layout>

                <Layout textAlignement="center">
                  <Typography
                    type="h4"
                    weight="semibold"
                    className="par-full-premium-flyover__anim-reveal"
                  >
                    <span>{title}</span>
                  </Typography>
                </Layout>

                <React.Fragment>
                  <FlexLayout
                    mainaxis={
                      screenSize === ScreenSize.Mobile ? 'column' : 'row'
                    }
                    width={
                      screenSize === ScreenSize.Mobile ? undefined : '100%'
                    }
                    paddingTop="8"
                    paddingBottom="48"
                  >
                    {infoItems.map((item, index) => (
                      <InfoItem
                        key={item.title}
                        orientation={
                          screenSize === ScreenSize.Mobile
                            ? 'vertical'
                            : 'horizontal'
                        }
                        title={item.title}
                        subtitle={item.subtitle}
                        iconType={item.iconType}
                        iconFamily={item.iconFamily}
                        animDelay={Math.min(3, index + 1)}
                      />
                    ))}
                  </FlexLayout>

                  {actionButton && (
                    <FlexLayout
                      mainaxis="column"
                      marginBottom="40"
                      crossaxisAlignment="center"
                      width="100%"
                    >
                      <FooterActionButtons
                        buttons={actionButton}
                        isMobile={screenSize === ScreenSize.Mobile}
                      />
                    </FlexLayout>
                  )}

                  {footNote && (
                    <Layout
                      textAlignement="center"
                      width="100%"
                      marginBottom="16"
                    >
                      <Typography type="body2" color="gray-dark">
                        {footNote}
                      </Typography>
                    </Layout>
                  )}
                </React.Fragment>
              </FlexLayout>
            </Card>
          </PageSectionLayout>
        </PageLayout>
      )}
    </RenderWhen>
  );
};

export default FullPremiumFlyover;
