import type {
  LinewizeEventRecord,
  LinewizeArrayEventRecord,
} from '../../records/linewizeEvent/types/linewizeEvents';

export const normalizeLinewizeEvents = (
  events: LinewizeArrayEventRecord
): Record<string, LinewizeEventRecord> => {
  return events.reduce((acc, event) => ({ ...acc, [event.key]: event }), {});
};

export const denormalizeLinewizeEvents = (
  normalizedEvents: Record<string, LinewizeEventRecord>
): Array<LinewizeEventRecord> => Object.values(normalizedEvents);

export const keysLinewizeEvent = (events: LinewizeArrayEventRecord) =>
  events.map(event => event.key);
