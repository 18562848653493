import { connect } from 'react-redux';
import { Map } from 'immutable';
import AppDailyTimeLimit, {
  AppDailyTimeLimitProps,
} from '../../components/AppDailyTimeLimit/AppDailyTimeLimit';
import State, {
  BaseThunk,
  Dispatch,
  RouterParamsProps,
} from '../../store/state';
import {
  getAppDayDialCheckedDay,
  getAppRuleApplicationByKey,
  getAppRulesApp,
} from '../../selectors/profileRules';
import { replaceS3Url } from '../../helpers';
import { setAppDayDialDay } from '../../ducks/profileRules';
import { requestModifyQuotas } from '../../actions/AppRulesActions';
import { AppRuleRecord } from '../../records/profileRules';
import { APPLICATION_ALLOWED, APPLICATION_BLOCKED } from '../../constants';
import { Weekday, weekdaysShortTranslations } from '../../helpers/dates';
import {
  everyDayWithZeroQuota,
  hasZeroQuotaInEveryDay,
} from '../../businessLogic/timeRules/timeQuota';
import { AppRuleNames, trackSetUpRuleName } from '../../helpers/analytics';

type OwnProps = RouterParamsProps<{ appKey: string; profileId: string }>;

const trackQuotaChange = (
  appName: string,
  quota: number,
  platform: string,
  selectedDay: Weekday
) => {
  let ruleName: AppRuleNames;
  switch (quota) {
    case 0:
      ruleName = AppRuleNames.BlockAppTimeLimits;
      break;
    case 1440:
      ruleName = AppRuleNames.ResetAppTimeLimits;
      break;
    default:
      ruleName = AppRuleNames.SetAppTimeLimits;
  }

  trackSetUpRuleName(
    ruleName,
    appName,
    platform,
    quota.toString(),
    weekdaysShortTranslations()[selectedDay]
  );
};

export const onChangeQuota =
  (profileId: string, appKey: string, quota: number): BaseThunk<void> =>
  (dispatch: Dispatch, getState) => {
    const state = getState();
    const selectedDay = getAppDayDialCheckedDay(state);
    const emptyQuotaMap = Map<Weekday, number>(everyDayWithZeroQuota());
    const { name: appName, platform: appPlatform } =
      getAppRuleApplicationByKey(state, profileId, appKey) || AppRuleRecord();

    const quotas = (
      getAppRulesApp(state, profileId, appKey)?.quotas || emptyQuotaMap
    ).set(selectedDay, quota);

    trackQuotaChange(appName, quota, appPlatform, selectedDay);

    dispatch(
      requestModifyQuotas({
        appKey,
        profileId,
        action: hasZeroQuotaInEveryDay(quotas.toJS())
          ? APPLICATION_BLOCKED
          : APPLICATION_ALLOWED,
        quotas,
      })
    );
  };

export const onSelectDay = (day: number) => setAppDayDialDay(day);

export const mapStateToProps = (
  state: State,
  ownProps: OwnProps
): Omit<AppDailyTimeLimitProps, 'onSelectDay' | 'onChangeQuota'> => {
  const { appKey, profileId } = ownProps.params;

  const appRule =
    getAppRuleApplicationByKey(state, profileId, appKey) || AppRuleRecord();
  const selectedDay = getAppDayDialCheckedDay(state);

  return {
    title: appRule.name,
    icon: replaceS3Url(appRule.thumbnail),
    dayQuotaMap: appRule.quotas,
    selectedDay,
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): Pick<AppDailyTimeLimitProps, 'onSelectDay' | 'onChangeQuota'> => {
  const { appKey, profileId } = ownProps.params;
  return {
    onSelectDay: (day: number) => dispatch(onSelectDay(day)),
    onChangeQuota: (quota: number) =>
      dispatch(onChangeQuota(profileId, appKey, quota)),
  };
};

const AppDailyTimeLimitContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppDailyTimeLimit);

export default AppDailyTimeLimitContainer;
