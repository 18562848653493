/**
 * Calculates the coordinates of an element relative to the document.
 *
 * @param elem - The target element.
 * @returns - An object containing the top and left coordinates of the element.
 *              If the element is null, the coordinates will be { top: 0, left: 0 }.
 */
export const getElCoordsInDocument = (
  elem: HTMLElement | null
): { top: number; left: number } => {
  if (!elem) return { top: 0, left: 0 };

  const box = elem.getBoundingClientRect();

  const elemScrollTop = elem.scrollTop || 0;
  const elemScrollLeft = elem.scrollLeft || 0;

  const top = box.top - elemScrollTop;
  const left = box.left - elemScrollLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

type ScrollOptions = {
  behavior?: 'auto' | 'instant' | 'smooth';
  left?: number;
  top?: number;
};

export const scrollElement = <T extends HTMLElement>(
  element: T,
  scrollOptions: ScrollOptions
) => {
  if (!element) return undefined;
  element.scroll({ behavior: 'smooth', ...scrollOptions });
};
