import { Map, Record } from 'immutable';
import { FeatureState } from './types/state';
import { FeaturesActions } from './types/actions';
import { Feature } from '../../records/features/types';

export const createInitialState: () => FeatureState = Record({
  status: 'IDLE',
  features: Map({}),
});

export const initialState = createInitialState();

const updateFeatures = (
  state: FeatureState,
  features: Array<Record<Feature>>
): FeatureState => {
  const updatedState = features.reduce((acc, feature) => {
    return acc.setIn(['features', feature.code], feature);
  }, state);

  return updatedState.set('status', 'IDLE');
};

const summaryAlertsReducer = (
  state: FeatureState = initialState,
  action: FeaturesActions
) => {
  switch (action.type) {
    case 'REQUEST_FEATURES':
      return state.set('status', 'LOADING');
    case 'RECEIVE_FEATURES':
      return updateFeatures(state, action.payload);
    default:
      return state;
  }
};

export default summaryAlertsReducer;
