import { connect } from 'react-redux';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import UnlockAlertsAndTimeLimitsModal from '../../components/Modal/UnlockAlertsAndTimeLimitsModal';
import State, { Dispatch } from '../../store/state';
import { canPurchase } from '../../selectors/app';
import { getAccountLocale, getQueryParam } from '../../selectors';
import {
  FeatureBlockingContexts,
  GenericPageNames,
  PartialBlockModalReason,
  trackUpgradeBlockedFeature,
} from '../../helpers/analytics';
import { navigateToUpgrade } from '../../actions/Navigation';

export enum UnlockAlertsAndTimeLimitsModalReferrer {
  gamesAndAppsAlertMe = 'games-and-apps-alert-me',
  gamesAndAppsSetLimits = 'games-and-apps-set-limits',
}

export const getReferrerFromQuery = (state: State) =>
  getQueryParam(state, 'referrer') as
    | UnlockAlertsAndTimeLimitsModalReferrer
    | undefined;

const mapStateToProps = (state: State) => {
  const accountLocale = getAccountLocale(state);
  const referrer = getReferrerFromQuery(state);
  return {
    referrer,
    accountLocale,
    canPurchase: canPurchase(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => dispatch(close()),
  onClickUpgradeNow: (
    canPurchase: boolean,
    referrer?: UnlockAlertsAndTimeLimitsModalReferrer
  ) => {
    if (!canPurchase) {
      return dispatch(close());
    }

    switch (referrer) {
      case UnlockAlertsAndTimeLimitsModalReferrer.gamesAndAppsAlertMe:
        trackUpgradeBlockedFeature(
          GenericPageNames.GamesAndAppsRules,
          FeatureBlockingContexts.PartialBlockModal,
          PartialBlockModalReason.GamesAndAppsAlertMe
        );
        break;
      case UnlockAlertsAndTimeLimitsModalReferrer.gamesAndAppsSetLimits:
        trackUpgradeBlockedFeature(
          GenericPageNames.GamesAndAppsRules,
          FeatureBlockingContexts.PartialBlockModal,
          PartialBlockModalReason.GamesAndAppsSetLimits
        );
        break;
      default:
        break;
    }

    dispatch(navigateToUpgrade());
  },
});

export type UnlockAlertsAndTimeLimitsModalProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const UnlockAlertsAndTimeLimitsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnlockAlertsAndTimeLimitsModal);

export default UnlockAlertsAndTimeLimitsModalContainer;
