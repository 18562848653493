import { createSelector } from 'reselect';
import * as Immutable from 'immutable';
import * as R from 'ramda';

import { formatDateIncludingHours } from '../helpers/dates';
import { PROFILE_STATUS_NO_DEVICE } from '../records/profile';
import { ProfileRecord } from '../records/profile/types/Profile.types';

import State from '../store/state';
import { nullableProp } from './helpers';
import { getProfileOrDefault, hasTrackableDevices } from './profileDevices';
import { isMultiDevices } from './profileRules';
import { getLocationPathname, getRouteParams } from './routing';
import { getProfile } from './profileRaw';

export const getProfileIds = (state: State) =>
  state.get('profiles').get('result');

export const isFetchingProfiles = (state: State) =>
  state.get('profiles').get('isFetching');

export const getProfilesMap = (state: State) =>
  state.get('records').get('profiles');

export const getProfileFetchStatus = (state: State) =>
  state.get('profiles').get('hasBeenFetched');

export const getCurrentProfileId = (state: State) =>
  getRouteParams(state).get('profileId');

export const getProfiles = createSelector(
  getProfileIds,
  getProfilesMap,
  (profileIds, profilesMap) =>
    profilesMap
      .valueSeq()
      .toOrderedSet()
      .filter(profile => profileIds!.contains(profile!.id))
      .sortBy(
        record => record!.name,
        (a, b) => a.localeCompare(b)
      ) as Immutable.OrderedSet<ProfileRecord>
);

export const getFirstProfile = createSelector(
  getProfiles,
  profiles => profiles.first() as ProfileRecord
);

export const getTrackableProfiles = createSelector(
  getProfiles,
  hasTrackableDevices,
  (profiles, hasTrackableDevices) =>
    profiles.filter(profile => hasTrackableDevices(profile.id))
);
export const accountHasTrackableDevices = createSelector(
  getTrackableProfiles,
  profiles => profiles.size > 0
);

export const hasReportedLocations = state => profileId =>
  getAddressLines(state, profileId).length > 0 &&
  getAddressLines(state, profileId)[0] !== '';

export const getReportedLocationsProfiles = createSelector(
  getProfiles,
  hasReportedLocations,
  (profiles, hasReportedLocations) =>
    profiles.filter(profile => hasReportedLocations(profile.id))
);

const getLocations = createSelector(
  getProfileOrDefault,
  nullableProp('location')
);

const getAddressLines = createSelector(
  getLocations,
  nullableProp('addressLines')
);

export const profileHasLocations = createSelector(
  getAddressLines,
  addressLines => addressLines.length && addressLines[0] !== ''
);

export const profileLastLocation = state => profileId =>
  profileHasLocations(state, profileId) && hasTrackableDevices(state)
    ? getAddressLines(state, profileId)[0]
    : null;

export const profileLastUpdate =
  state => (profileId, lastseen, timezone, locale) =>
    profileHasLocations(state, profileId) &&
    hasTrackableDevices(state) &&
    lastseen
      ? formatDateIncludingHours(lastseen, timezone, locale)
      : null;

export const getProfileId = createSelector(getLocationPathname, pathname => {
  let profileId = (pathname.match(/profiles\/-?[0-9]+/) || [null])[0];

  if (profileId) {
    // eslint-disable-next-line prefer-destructuring
    profileId = profileId.split('/')[1];
  }
  return profileId;
});

export const isStudentSummaryPath = createSelector(
  getLocationPathname,
  pathname => {
    const schoolPath = (pathname.match(/profiles\/-?[0-9]+\/school/) || [
      null,
    ])[0];
    return !!schoolPath;
  }
);

export const isCombinedSummaryPath = createSelector(
  getLocationPathname,
  pathname => {
    const combinedPath = (pathname.match(
      /profiles\/-?\d+\/family-and-school/
    ) || [null])[0];
    return !!combinedPath;
  }
);

export const getActiveProfile = createSelector(
  getProfileId,
  getProfilesMap,
  (profileId, profiles) => (profileId ? profiles.get(profileId) : null)
);

export const getActiveProfileUid = createSelector(getActiveProfile, profile =>
  profile ? profile.uid : null
);

export const getDeviceProfiles = createSelector(
  getProfiles,
  R.nthArg(1),
  (profiles, deviceId) =>
    profiles.filter(profile => profile.deviceIds.contains(deviceId))
);

export const isAnyProfileProtected = createSelector(
  getProfiles,
  profiles =>
    profiles.find(profile => profile.status !== PROFILE_STATUS_NO_DEVICE) !==
    undefined
);

export const hasChildsAndTrackableDevices = createSelector(
  getProfiles,
  accountHasTrackableDevices,
  (profiles, accountHasTrackableDevices) =>
    profiles.size > 0 && accountHasTrackableDevices
);

/** is not multidevice, or is multidevice but only one device */
export const isSingleDevice = createSelector(
  isMultiDevices,
  getProfileOrDefault,
  (isMultiDevices, profile) => !isMultiDevices || profile.deviceIds.size === 1
);

export const isFetchingProfile = (state: State) =>
  state.get('events').get('isFetching') ||
  state.get('summary').get('isFetching') ||
  state.get('summary').get('isSelectedDateScreenTime') ||
  state.get('summary').get('isFetchingRangeScreenTime');

export const isRefreshingProfile = (state: State) =>
  state.get('events').get('isFetchingNew') ||
  state.get('summary').get('isRefreshingSelectedDateScreenTime') ||
  state.get('summary').get('isRefreshingRangeScreenTime');

export const accountHasReportedLocations = createSelector(
  getReportedLocationsProfiles,
  reportedLocations => reportedLocations.size > 0
);

export const accountHasReportedLocationsAndTrackableDevices = createSelector(
  accountHasReportedLocations,
  accountHasTrackableDevices,
  (accountHasReportedLocations, accountHasTrackableDevices) =>
    accountHasReportedLocations && accountHasTrackableDevices
);

export const hasProfileWithActiveDevice = (state: State) =>
  getProfilesMap(state).filter((profile: ProfileRecord) =>
    profile.deviceCount !== undefined ? profile.deviceCount > 0 : false
  ).size > 0;

export const isProfileLinkedWithLinewizeStudent = createSelector(
  getProfile,
  (profile: ProfileRecord) =>
    profile ? profile.isLinewizestudentLinked : false
);

export const getProfilesWithDeviceAssigned = createSelector(
  getProfiles,
  profiles => {
    return profiles.filter(
      profile => profile.status !== PROFILE_STATUS_NO_DEVICE
    );
  }
);

export const getProfilesUidWithDeviceAssigned = createSelector(
  getProfilesWithDeviceAssigned,
  profiles => profiles.map(profile => profile.uid)
);

export const getProfileActiveRoutineUid = (
  state: State,
  profileId?: string | null
) =>
  profileId ? getProfilesMap(state).get(`${profileId}`).activeRoutine : null;

export const getProfileUid = (state: State) => {
  const profileId = getProfileId(state);

  if (!profileId) return;

  const profileRecord = getProfileOrDefault(state, profileId);
  return profileRecord.uid;
};
