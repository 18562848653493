import { StudentPolicyError } from '../../../../ducks/types/action/StudentPoliciesActions.type';
import { StudentCardPauseStatusTracking } from '../../../../epics/tracking/types';
import { isBetweenDatetimes } from '../../../../helpers/dates';
import {
  getCurrentSchoolEndDatetime,
  getCurrentSchoolStartDatetime,
  getIsPauseActive,
  getIsPauseEnabled,
  getIsPauseEnabled247,
  getStudentPolicyError,
} from '../../../../selectors/studentPolicies';
import State from '../../../../store/state';
import { Datetime } from '../../../../types/dates';

export const isSchoolTime = (
  startDatetime?: Datetime,
  endDatetime?: Datetime
): boolean => {
  if (startDatetime && endDatetime) {
    return isBetweenDatetimes(startDatetime, endDatetime);
  }
  return false;
};

export const checkIfSchoolTime = (
  startDatetime?: Datetime,
  endDatetime?: Datetime
): boolean => {
  return isSchoolTime(startDatetime, endDatetime);
};

export const shouldShowPauseButton = (
  isSchoolPeriod = false,
  isPause247Enabled = false
): boolean => {
  return (!isSchoolPeriod && !isPause247Enabled) || isPause247Enabled;
};

export const isPauseCurrentlyActive = (
  isPauseActive = false,
  isSchoolPeriod = false,
  isPause247Enabled = false
) => {
  return (
    (isPauseActive && !isSchoolPeriod) || (isPauseActive && isPause247Enabled)
  );
};

export const isFeatureDisabled = (state: State) => {
  const studentPolicyError = getStudentPolicyError(state);
  return studentPolicyError === StudentPolicyError.FeatureDisabled;
};

export const getTrackingCardStatus = (
  state: State
): StudentCardPauseStatusTracking => {
  const currentStartSchoolTimes = getCurrentSchoolStartDatetime(state);
  const currentEndSchoolTimes = getCurrentSchoolEndDatetime(state);
  const isPauseEnabled247 = getIsPauseEnabled247(state);
  const isPauseActive = getIsPauseActive(state);
  const isClassTime = isSchoolTime(
    currentStartSchoolTimes,
    currentEndSchoolTimes
  );
  if (isFeatureDisabled(state) || !getIsPauseEnabled(state))
    return 'school_feature_disabled';
  if (isPauseCurrentlyActive(isPauseActive, isClassTime, isPauseEnabled247))
    return 'school_pause_active';
  if (isPauseEnabled247) return 'school_pause_247_enabled';
  if (isClassTime) return 'school_in_session';
  return 'school_pause_enabled';
};
