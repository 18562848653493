import * as Moment from 'moment-timezone';
import * as R from 'ramda';
import { Key } from '../ducks/persistentStorage';
import State from '../store/state';

/**
 * Safely combine selectors that might return `undefined`, for example:
 *
 * selA = state => state.get('mayBeUndefined');
 * // could return an Immutable.js type or `undefined`
 *
 * selB = tryUndefined(state => selA(state).get('nestedProp'));
 * // would return `undefined` instead of throwing when selA return `undefined`
 */
type TryUndefined = {
  <R, T>(f: (arg: T) => R): (arg: T) => R | undefined;
  <R, T1, T2>(f: (arg1: T1, arg2: T2) => R): (
    arg1: T1,
    arg2: T2
  ) => R | undefined;
  <R, T1, T2, T3>(f: (arg1: T1, arg2: T2, arg3: T3) => R): (
    arg1: T1,
    arg2: T2,
    arg3: T3
  ) => R | undefined;
  <R, T1, T2, T3, T4>(f: (arg1: T1, arg2: T2, arg3: T3, arg4: T4) => R): (
    arg1: T1,
    arg2: T2,
    arg3: T3,
    arg4: T4
  ) => R | undefined;
};

export const tryUndefined: TryUndefined = f =>
  R.tryCatch(f, R.always(undefined) as any);

/**
 * Safely access a property of an object that might be `undefined`, for example:
 * selA = createSelector(someSelector, nullableProp('attr'));
 * // if someSelector returns `undefined`, then nullableProp simply returns `undefined` too
 * // no errors are thrown
 */
type NullableProp = {
  <K extends string>(key: K): NullablePropPartial<K>;
};
type NullablePropPartial<K extends string> = {
  <T extends Record<K, any> | undefined>(object: T): T extends undefined
    ? undefined
    : NonNullable<T>[K];
};

export const nullableProp: NullableProp = key => obj =>
  obj !== undefined ? obj[key as any] : undefined;

/**
 * Make a function that, given a timestamp string, will return a Moment instance. Memoized
 * to the timestamp input. A custom function to convert the timestamp to a Moment instance must be
 * provided.
 * This is useful because selectors must always return the same references for a given input (or for
 * relevant portions of the input), otherwise component will re-render unnecessarily and slow down
 * the app.
 */
export const memoizedMoment = (
  makeMoment: (timeStamp: ISODateString, ...rest: any[]) => Moment.Moment
) => {
  let lastCurrentTime: [ISODateString, Moment.Moment] | null = null;
  return (timeStamp: ISODateString, ...rest: any[]) => {
    if (lastCurrentTime !== null && timeStamp === lastCurrentTime[0]) {
      return lastCurrentTime[1];
    }
    lastCurrentTime = [timeStamp, makeMoment(timeStamp, ...rest)];
    return lastCurrentTime[1];
  };
};

export const getPersistentStorageItem = <K extends Key>(state: State, key: K) =>
  state.get('persistentStorage').get(key);
