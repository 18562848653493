import {
  DeviceMdmStatus,
  DeviceMdmStatusPayload,
} from './types/DeviceMdmStatus.types';
import { getRecordOperations } from '../recordHelpers/recordHelpers';

export const DeviceMdmStatusRecordOperations = getRecordOperations<
  DeviceMdmStatus,
  DeviceMdmStatusPayload
>({
  defaultFields: {
    isSupervised: false,
    isMdmRemovable: false,
  },
});
