import * as React from 'react';
import { t } from '../../lib/i18n';

import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import illustration from '../../assets/base/images/v3/flyovers/illustration_app_selection.svg';
import { Button, ButtonSize, FlexLayout } from 'styleguide-react';
import PreOnboardingBase from './PreOnboardingBase';

const ParentOrKidAppChoice: React.FunctionComponent<{
  accountName: string;
  onClickKid: () => any;
  onClickParent: () => any;
}> = ({ accountName, onClickKid, onClickParent }) => (
  <PageLayout
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
        hideHeader: true,
        fullHeightCard: true,
      },
      { screenSize: ScreenSize.Mobile, columns: 1, hideHeader: true },
    ]}
  >
    <FlexLayout mainaxis="column" crossaxisAlignment="center" flexGrow="2">
      <PreOnboardingBase
        descriptionAlignement="center"
        description={
          <FlexLayout mainaxis="column">
            <p>
              {t(
                'Please confirm this is your own device and you want to manage your child’s online activity from here.'
              )}{' '}
            </p>
          </FlexLayout>
        }
        title={t('Welcome, {{accountName}}', {
          accountName,
        })}
        illustrationSrc={illustration}
        button={
          <Button size={ButtonSize.big} onClick={onClickParent} block>
            {t('Confirm')}
          </Button>
        }
        bottomContent={
          <FlexLayout mainaxis="column" textAlignement="center">
            <p>
              {t('Not what you were expecting?')}
              <br />
              <span onClick={onClickKid} role="button">
                <strong>
                  {t('{1}Try Kids App instead{/1}', {
                    // eslint-disable-next-line no-script-url
                    1: str => <a href="javascript:void(0)">{str}</a>,
                  })}
                </strong>
              </span>
            </p>
          </FlexLayout>
        }
      />
    </FlexLayout>
  </PageLayout>
);

export default ParentOrKidAppChoice;
