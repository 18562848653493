import { NonEmptyMapRecord } from 'immutable';
import { ActionPayload, Action } from 'redux';
import type {
  CalendarRestrictionPauseList,
  CalendarRestrictionPause,
} from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';

export enum CalendarRestrictionPauseSuccessActions {
  GET_REQUEST_SUCCESS = 'REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS',
}

export type CalendarRestrictionPauseActionTypes =
  | 'REQUEST_CALENDAR_RESTRICTION_PAUSE'
  | 'REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS'
  | 'REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR'
  | 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE'
  | 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS'
  | 'CREATE_REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR'
  | 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE'
  | 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_SUCCESS'
  | 'DELETE_REQUEST_CALENDAR_RESTRICTION_PAUSE_ERROR';

export type CalendarRestrictionPauseAction =
  Action<CalendarRestrictionPauseActionTypes>;

export type CalendarRestrictionDeletePauseAction = ActionPayload<
  CalendarRestrictionPauseActionTypes,
  { calendarRestrictionUid: CalendarRestrictionPause['uid'] }
>;

export type CalendarRestrictionPauseActionPayload = {
  items: CalendarRestrictionPauseList;
  isFetching: boolean;
  isSavingSuccess: boolean;
  isSavingFailed: boolean;
};

export type CalendarRestrictionPauseSuccessAction = ActionPayload<
  CalendarRestrictionPauseActionTypes,
  { items: CalendarRestrictionPauseList; profileId: string }
>;

export type CalendarRestrictionPauseCreateSuccessAction = ActionPayload<
  CalendarRestrictionPauseActionTypes,
  { items: CalendarRestrictionPauseList }
>;

export type CalendarRestrictionPauseState =
  NonEmptyMapRecord<CalendarRestrictionPauseActionPayload>;
