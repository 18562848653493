import State, { BaseThunk, Dispatch } from '../../store/state';
import {
  navigateToDeleteRoutineModal,
  navigateToTimeLimitsModal,
  navigateToDeleteRoutineTimeSlotModal,
  navigateToCantEnableTimeSlotsModal,
  navigateDisconnectTheInternetModal,
  navigateLockDeviceModal,
  navigateToChangeContentFilterRulesModal,
  navigateToRemoveAllExceptionsModal,
  navigateToRoutinesInfoModal,
  navigateToUpgradeModal,
  navigateToUpgradePage,
} from '../../actions/RoutinesActions';
import { getActiveProfileUid, getProfileId } from '../../selectors';
import {
  createRoutine,
  createRoutineSchedules,
  editRoutine,
  newRoutineAction,
  requestRoutines,
  setTempRoutineUid,
  updateRoutine,
  updateRoutineSchedule,
} from '.';
import { routineContextToPayload } from './transformations';
import { mergeDeepRight } from 'ramda';
import {
  RoutineMode,
  MultiStepDataContext,
  RoutineSteps,
  RoutineRuleExceptionType,
  TimeSlot,
} from '../../components/Routines/routines.types';
import { DeepPartial } from 'redux';
import { scheduleContextToPayload } from './transformations/scheduleContextToPayload';
import { APPThunk } from '../../helpers/thunks';
import { resetRoutinesRecord, resetRoutinesSchedulesRecord } from '../records';
import { PolicyAction } from '../../records/routines/policy/types/Policy.types';

export const fetchProfileRoutines =
  (purgeCache?: boolean): APPThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profileUid = getActiveProfileUid(state);
    const includeDisabledRoutines = true;
    if (profileUid) {
      dispatch(resetRoutinesRecord());
      dispatch(resetRoutinesSchedulesRecord());
      dispatch(
        requestRoutines(profileUid, includeDisabledRoutines, purgeCache)
      );
    }
  };

export const createRoutines =
  (data: MultiStepDataContext): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getActiveProfileUid(state) as string;
    const payload = routineContextToPayload(data);
    return dispatch(createRoutine(uid, payload));
  };

export const upsertRoutineSchedule =
  (routineUid: string, slot: TimeSlot): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getActiveProfileUid(state) as string;
    const payload = scheduleContextToPayload(slot);
    if (slot?.uid) {
      return dispatch(
        updateRoutineSchedule(uid, routineUid, slot.uid, payload)
      );
    }
    return dispatch(createRoutineSchedules(uid, routineUid, payload));
  };

export const handleNavigateToModalInfo =
  (
    step: RoutineSteps,
    options?: {
      type?: MultiStepDataContext['block'];
    }
  ): BaseThunk =>
  (dispatch: Dispatch, getState) => {
    const profileId = getProfileId(getState()) as string;

    if (step === RoutineSteps.editRoutine) {
      return dispatch(navigateToTimeLimitsModal(profileId));
    }

    if (
      step === RoutineSteps.selectBlockType &&
      options?.type === 'POLICY_BLOCK_TYPE_LOCK_NAVIGATION'
    ) {
      return dispatch(navigateDisconnectTheInternetModal(profileId));
    }

    if (
      step === RoutineSteps.selectBlockType &&
      options?.type === 'POLICY_BLOCK_TYPE_LOCK_DEVICE'
    ) {
      return dispatch(navigateLockDeviceModal(profileId));
    }
  };

export const handleUpdateRoutine =
  (
    routine: MultiStepDataContext,
    data: DeepPartial<MultiStepDataContext>
  ): BaseThunk =>
  (dispatch: Dispatch, getState: () => State) => {
    const uid = getActiveProfileUid(getState()) as string;
    const routineUid = routine.uid as string;
    const updatedData = mergeDeepRight(routine, data);
    const body = routineContextToPayload(updatedData);
    dispatch(updateRoutine(uid, routineUid, body));
  };

export const handleNavigateToDeleteRoutineModal =
  (routineUid: string): BaseThunk =>
  (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState()) as string;
    dispatch(navigateToDeleteRoutineModal(profileId, routineUid));
  };

export const navigateToDeleteTimeSlotModal =
  (routineUid: string, slotUid: string, origin: string): BaseThunk =>
  (dispatch, getState) => {
    const profileId = getProfileId(getState()) as string;
    dispatch(
      navigateToDeleteRoutineTimeSlotModal(
        profileId,
        routineUid,
        slotUid,
        origin
      )
    );
  };

export const handleNavigateToCantEnableTimeSlotsModal =
  (routineUid: string): BaseThunk =>
  (dispatch, getState) => {
    const profileId = getProfileId(getState()) as string;
    dispatch(navigateToCantEnableTimeSlotsModal(profileId, routineUid));
  };

export const handleNavigateToChangeContentFilterRulesModal =
  (routineUid: string, type, policyAction, mode: RoutineMode): BaseThunk =>
  (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState()) as string;

    dispatch(
      navigateToChangeContentFilterRulesModal({
        profileId,
        routineUid,
        policyAction,
        mode,
        type,
      })
    );
  };

export const handleNavigateToRemoveAllExceptionsModal =
  (
    routineUid: string | undefined,
    type: RoutineRuleExceptionType,
    mode: RoutineMode,
    policyAction?: PolicyAction
  ): BaseThunk =>
  (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState()) as string;

    dispatch(
      navigateToRemoveAllExceptionsModal({
        profileId,
        routineUid,
        type,
        mode,
        policyAction,
      })
    );
  };

export const onEditRoutineThunk =
  (routineUid: string, track: (uid: string) => void): BaseThunk =>
  dispatch => {
    track(routineUid);
    dispatch(editRoutine(routineUid));
  };

export const onAddNewRoutineThunk =
  (track: (uid: string) => void): BaseThunk =>
  dispatch => {
    const tempRoutineUid = `tmp-routine-uid-${+Date.now()}`;
    track(tempRoutineUid);
    dispatch(newRoutineAction());
    dispatch(setTempRoutineUid(tempRoutineUid));
  };

export const handleNavigateToRoutinesInfoModal =
  (profileId: string): BaseThunk =>
  (dispatch: Dispatch) => {
    dispatch(navigateToRoutinesInfoModal(profileId));
  };

export const handleNavigateToUpgradeModal =
  (profileId: string): BaseThunk =>
  (dispatch: Dispatch) => {
    dispatch(navigateToUpgradeModal(profileId));
  };

export const handleNavigateToUpgradePage =
  (): BaseThunk => (dispatch: Dispatch) => {
    dispatch(navigateToUpgradePage());
  };
