import { Moment } from 'moment-timezone';
import {
  getIsProfileLinkedToLW,
  getIsSchoolOnlyProfile,
} from '../../containers/Dashboard/helpers/profileDashboardHelpers';
import { localNow } from '../../helpers/dates';
import { getTimezone } from '../../selectors';
import {
  getCalendarRestrictionPauseEnd,
  isCalendarRestrictionPaused,
} from '../../selectors/stateSelectors/calendarRestrictionsPause';
import {
  getEndActivePause,
  getIsPauseActive,
  getIsPauseEnabled,
} from '../../selectors/studentPolicies';
import State from '../../store/state';
import { ProfileType } from './types';

export const getProfileType = (
  state: State,
  profileId: number
): ProfileType => {
  const isLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  const isOnlySchoolProfile = getIsSchoolOnlyProfile(state, profileId);

  if (isOnlySchoolProfile) return 'school';
  if (isLinkedToSchool) {
    // TODO add delegation logic here
    return 'non_delegated';
  }
  return 'family';
};

export const getIsProfilePaused = (
  state: State,
  profileId: number
): boolean => {
  const profileType = getProfileType(state, profileId);
  const currentTime = localNow(getTimezone(state));

  if (profileType === 'school') {
    return Boolean(getIsPauseActive(state));
  }

  return isCalendarRestrictionPaused(state, currentTime);
};

export const getProfileCanBePaused = (
  state: State,
  profileId: number
): boolean => {
  const isLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  if (isLinkedToSchool) {
    return Boolean(getIsPauseEnabled(state));
  }
  return true;
};

export const getProfileEndPause = (
  state: State,
  profileId: number
): Moment | undefined => {
  const profileType = getProfileType(state, profileId);

  if (profileType === 'school') {
    return getEndActivePause(state);
  }
  // for other profiles types (family, delegated or non delegated) we should use the family end pause
  const endPauseFamily = getCalendarRestrictionPauseEnd(state);
  if (endPauseFamily) {
    return getCalendarRestrictionPauseEnd(state);
  }
  return undefined;
};
