import * as React from 'react';

import { Layout, FlexLayout } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';

import { SummaryDateRanges } from '../../constants';

import DateRangeSelectorTab from './DateRangeTab';

interface DateRangeSelectoSchoolProps {
  activeRange: SummaryDateRanges;
  onChangeDateRange: (selectDate: SummaryDateRanges) => void;
}

const DateRangeSelectorSchool: React.FunctionComponent<
  DateRangeSelectoSchoolProps
> = ({ activeRange, onChangeDateRange }: DateRangeSelectoSchoolProps) => {
  const ranges = [SummaryDateRanges.Today, SummaryDateRanges.Days7];

  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        <FlexLayout mainaxis="row" mainaxisAlignment="left">
          <Layout minWidth="240">
            <DateRangeSelectorTab
              ranges={ranges}
              activeRange={activeRange}
              onChangeDateRange={onChangeDateRange}
            />
          </Layout>
        </FlexLayout>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <FlexLayout mainaxis="row" width="100%">
          <Layout width="100%">
            <DateRangeSelectorTab
              ranges={ranges}
              activeRange={activeRange}
              onChangeDateRange={onChangeDateRange}
            />
          </Layout>
        </FlexLayout>
      </RenderWhen>
    </React.Fragment>
  );
};

export default DateRangeSelectorSchool;
