import * as React from 'react';
import { FlexLayout, FreeText, IconType, Layout } from 'styleguide-react';
import RoutineIcon, { RoutineIconProps } from '../RoutineIcon/RoutineIcon';

type IconTypeValue = typeof IconType[keyof typeof IconType];
export interface IconTextComboProps {
  title?: string;
  titleTag?: React.ReactNode;
  subtitle?: string;
  titleSize?: 'medium' | 'large';
  subtitleSize?: 'small' | 'medium' | 'large';
  icon: IconTypeValue | React.ReactNode;
  iconSize?: RoutineIconProps['size'];
  iconColor: RoutineIconProps['color'];
  iconFamily?: RoutineIconProps['family'];
  iconGap?: 'x1' | 'x2' | 'x3';
  invertColor?: boolean;
  showBadge: boolean;
}

const getTitleSize = (size: NonNullable<IconTextComboProps['titleSize']>) =>
  ({
    medium: '16px',
    large: '20px',
  }[size]);

const getSubtitleSize = (
  size: NonNullable<IconTextComboProps['subtitleSize']>
) =>
  ({
    small: '12px',
    medium: '14px',
    large: '16px',
  }[size]);

const getIconGap = (gap: IconTextComboProps['iconGap']) => {
  if (gap === 'x1') return '8';
  if (gap === 'x2') return '16';
  if (gap === 'x3') return '24';
  return undefined;
};

const Spacer = ({ space }: { space: '8' | '16' }) => (
  <Layout display="inline-block" marginRight={space} />
);

const IconTextCombo = ({
  title,
  titleTag,
  subtitle,
  titleSize = 'medium',
  subtitleSize = 'medium',
  icon,
  iconSize = 'medium',
  iconColor,
  iconFamily,
  iconGap = 'x2',
  invertColor = false,
  showBadge,
}: IconTextComboProps) => {
  return (
    <FlexLayout
      mainaxis="row"
      crossaxisAlignment="center"
      className="par-util-break-word"
    >
      <Layout marginRight={getIconGap(iconGap)}>
        {React.isValidElement(icon) ? (
          icon
        ) : (
          <RoutineIcon
            icon={icon as IconTypeValue}
            size={iconSize}
            color={iconColor}
            family={iconFamily}
            inverted={invertColor}
            showBadge={showBadge}
          />
        )}
      </Layout>
      <FlexLayout mainaxis="column">
        <FlexLayout mainaxis="row">
          {title && (
            <FreeText fontWeight="semi-bold" fontSize={getTitleSize(titleSize)}>
              {title}
              {titleTag && <Spacer space="16" />}
              {titleTag}
            </FreeText>
          )}
        </FlexLayout>
        {subtitle && (
          <FreeText
            fontWeight="normal"
            fontSize={getSubtitleSize(subtitleSize)}
          >
            {subtitle}
          </FreeText>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export default IconTextCombo;
