import { curry } from 'ramda';
import { shouldBlockThisFeature } from '.';
import { SettingsToggleNames } from '../../constants';
import { LicenseSubtype } from '../../records/license/types/License.types';
import { BlockableFeature } from './types/Feature';
import { Features } from '../../ducks/features';

const SettingsToggleNamesToFeatureMap: Partial<
  Record<SettingsToggleNames, BlockableFeature>
> = {
  // WebWebFilterToggleNames
  [SettingsToggleNames.ReportBlockedSites]: 'blockedWebsiteAlerts',
  // AppRulesToggleNames
  [SettingsToggleNames.IsAlertNewApps]: 'appDownloadAlerts',
  // SummaryAlerts
  [SettingsToggleNames.IsSearchAlertsNotificationsEnabled]:
    'isSearchAlertsNotificationsEnabled',
};

/**
 * @deprecated we will not use the license to know if a feature is blocked or not, we will use the feature always that is possible
 * Determine if a page setting toggle is a blocked premium feature acording to the user's license
 */
export const isSettingsToggleBlocked = curry(
  (
    licenseSubtype: LicenseSubtype,
    features: Features,
    setting: SettingsToggleNames
  ) => {
    const feature = SettingsToggleNamesToFeatureMap[setting];
    if (!feature) return false;

    return shouldBlockThisFeature(feature, licenseSubtype);
  }
);
