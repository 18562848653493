import * as React from 'react';
import { t } from '../../lib/i18n';
import { SOCIAL_MONITORING_RULES_STATE_MONITORING } from '../../constants';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  ContentSeparator,
  DeactivableContent,
  FlexLayout,
  InstructionBlock,
  Label,
  Layout,
  Link,
  Switch,
} from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import waitingSvg from '../../assets/base/images/v3/decorators/illustration_socialmedia_waiting.svg';
import connectedSvg from '../../assets/base/images/v3/decorators/illustration_socialmedia_connected.svg';

const SocialMonitoringProfileNotActived: React.FunctionComponent<{
  onClickAction: () => any;
  ruleEnabled: boolean;
}> = ({ onClickAction, ruleEnabled }) => (
  <FlexLayout mainaxis="column" crossaxisAlignment="center">
    <img src={waitingSvg} alt="waiting" width="224" height="224" />
    <Layout maxWidth="75%" textAlignement="center" marginTop="16">
      <InstructionBlock>
        {t("If you know your child's Facebook Credentials {1}click here{/1}.", {
          1: str => (
            <Link onClick={ruleEnabled ? onClickAction : undefined}>{str}</Link>
          ),
        })}
      </InstructionBlock>
    </Layout>
  </FlexLayout>
);

const SocialMonitoringProfileActived: React.FunctionComponent<{
  profileName: string;
}> = ({ profileName }) => (
  <FlexLayout mainaxis="column" crossaxisAlignment="center" marginTop="40">
    <img src={connectedSvg} alt="connected" width="224" height="224" />
    <Layout maxWidth="75%" textAlignement="center" marginTop="16">
      <InstructionBlock>
        {t(
          'Facebook Monitoring for {{profileName}} is {1}currently active{/1}',
          {
            profileName,
            1: str => <span>{str}</span>,
          }
        )}
      </InstructionBlock>
    </Layout>
  </FlexLayout>
);

const getMonitoringStateContent = (
  monitoringState: symbol,
  profileName: string,
  onClickAction: () => any,
  ruleEnabled: boolean
) => {
  return monitoringState === SOCIAL_MONITORING_RULES_STATE_MONITORING ? (
    <SocialMonitoringProfileActived profileName={profileName} />
  ) : (
    <SocialMonitoringProfileNotActived
      onClickAction={onClickAction}
      ruleEnabled={ruleEnabled}
    />
  );
};

interface SocialMonitoringRulesProps {
  monitoringState: symbol;
  ruleEnabled: boolean;
  profileName: string;
  onToggleSocialMonitoringEnabled: (toggleOn: boolean) => any;
  onClickAction: () => any;
}

const SocialMonitoringRules: React.FunctionComponent<
  SocialMonitoringRulesProps
> = socialMonitoringRulesProps => {
  const monitoringStateContent = getMonitoringStateContent(
    socialMonitoringRulesProps.monitoringState,
    socialMonitoringRulesProps.profileName,
    socialMonitoringRulesProps.onClickAction,
    socialMonitoringRulesProps.ruleEnabled
  );

  return (
    <PageLayout
      title={t('Social Monitoring')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
        {
          screenSize: ScreenSize.Tablet,
          mainInsideCard: true,
          columns: 1,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
    >
      <MonitoringContent
        {...socialMonitoringRulesProps}
        monitoringState={monitoringStateContent}
      />
    </PageLayout>
  );
};

const MonitoringContent: React.FunctionComponent<{
  monitoringState: JSX.Element;
  ruleEnabled: boolean;
  onToggleSocialMonitoringEnabled: (toggleOn: boolean) => any;
}> = ({ monitoringState, ruleEnabled, onToggleSocialMonitoringEnabled }) => (
  <FlexLayout mainaxis="column" className="SocialMonitoringRules">
    <FlexLayout
      mainaxis="row"
      mainaxisAlignment="space-between"
      crossaxisAlignment="center"
      flexGrow="0"
      paddingBottom="8"
    >
      <Label htmlFor="SwitchIsDailyLimit">
        {t('Enable Facebook Monitoring')}
      </Label>
      <Switch
        id="SwitchIsDailyLimit"
        checked={ruleEnabled}
        onClick={ev =>
          onToggleSocialMonitoringEnabled(
            (ev.target as HTMLInputElement).checked
          )
        }
      />
    </FlexLayout>
    <ContentSeparator />
    <FlexLayout mainaxis="column" paddingTop="24">
      <DeactivableContent disabled={!ruleEnabled}>
        {monitoringState}
      </DeactivableContent>
    </FlexLayout>
  </FlexLayout>
);

export default SocialMonitoringRules;
