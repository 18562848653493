import { Record } from 'immutable';
import {
  HoursPerDayActivity,
  HoursPerDayActivityMethods,
} from './types/HoursPerDayActivity.types';
import * as moment from 'moment-timezone';
import { API_BIRTHDATE_FORMAT } from '../../helpers/dates';
import { captureScreenTimeRangeMismatch } from '../../helpers/activity';

export const HoursPerDayActivityRecord = Record<
  HoursPerDayActivity,
  HoursPerDayActivityMethods
>({
  day: '',
  date: '',
  hours: 0,
  defaultScreenTime: null,
  routineScreenTime: null,
});

const minutesToHours = (minutes?: number) => (minutes ? minutes / 60 : 0);

HoursPerDayActivityRecord.fromPayload = payload => {
  captureScreenTimeRangeMismatch(payload);
  return HoursPerDayActivityRecord({
    date: payload.date,
    hours: minutesToHours(payload.minutes),
    day: moment(payload.date, API_BIRTHDATE_FORMAT).format('DD'),
    defaultScreenTime: payload?.default_rules_minutes
      ? minutesToHours(payload.default_rules_minutes)
      : null,
    routineScreenTime: payload?.routines_minutes
      ? payload.routines_minutes.map(routine => ({
          uid: routine.uid,
          hours: minutesToHours(routine.minutes),
        }))
      : null,
  });
};
