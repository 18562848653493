import { connect } from 'react-redux';
import * as moment from 'moment-timezone';

import { goBackIfHistory } from '../../ducks/routing';
import {
  getAccountCurrentLocalTime,
  getAccountCurrentTime,
  getActiveProfileUid,
  getExtraTimeInMinutes,
  getIfExtraTimeIsFetching,
  getProfile,
  getQueryParam,
  getScreenTimeMinutes,
  getTimeQuotas,
} from '../../selectors';
import State, {
  BaseThunk,
  Dispatch,
  RouterParamsProps,
} from '../../store/state';
import { getCurrentWeekday } from '../../businessLogic/timeRules/timeQuota';
import {
  fetchExtraTime,
  saveExtraTimeTransaction,
} from '../../ducks/extraTime';
import { showToast, TOAST_ICON_WARNING } from '../../ducks/toast';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { Events, track } from '../../helpers/analytics';
import ExtraTimeModal from '../../components/Modal/ExtraTimeModal';

export const trackQuotaEvent =
  ({
    profileId,
    trackEvent,
    spentMinutes,
    dailyLimitMinutes,
    extraTime,
    currentDateInAccountTimezone,
    edit = false,
    isQuickAction,
  }): BaseThunk<void> =>
  () => {
    const remainingQuota = dailyLimitMinutes + extraTime - spentMinutes;
    track(trackEvent, {
      profile_id: profileId,
      total_quota: dailyLimitMinutes,
      remaining_quota: remainingQuota > 0 ? remainingQuota : 0,
      extra_time: extraTime,
      date: currentDateInAccountTimezone,
      edit,
      quick_action: isQuickAction,
    });
  };

export const trackSetExtraTime =
  ({
    minutes: extraTimeMinutes,
    profileId,
    isQuickAction = false,
  }): BaseThunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const profile = getProfile(state, profileId) as ProfileRecord;
    const currentTime = getAccountCurrentLocalTime(state);
    const currentWeekday = getCurrentWeekday(moment(currentTime));
    const totalQuota = (getTimeQuotas as any)(
      state,
      profileId,
      profile.deviceIds.first(),
      currentWeekday
    );

    dispatch(
      trackQuotaEvent({
        profileId,
        trackEvent: Events.SetExtraTime,
        spentMinutes: getScreenTimeMinutes(state),
        dailyLimitMinutes: totalQuota,
        extraTime: extraTimeMinutes,
        currentDateInAccountTimezone: currentTime,
        isQuickAction,
      })
    );
  };

export const saveExtraTime =
  ({ minutes, profileId, isQuickAction }): BaseThunk<void> =>
  async dispatch => {
    dispatch(goBackIfHistory());
    dispatch(trackSetExtraTime({ minutes, profileId, isQuickAction }));
    try {
      await dispatch(saveExtraTimeTransaction({ minutes, profileId }));
    } catch (_) {
      dispatch(
        showToast(
          t('Oops! Extra time was not applied. Try again.'),
          TOAST_ICON_WARNING
        )
      );
    }
  };

const mapStateToProps = (
  state: State,
  { params: { profileId }, ...props }: RouterParamsProps
) => {
  const profile = getProfile(state, profileId);
  const currentTime = getAccountCurrentTime(state);
  const currentWeekday = getCurrentWeekday(currentTime);
  const origin = getQueryParam(state, 'origin');

  const extraTime = getExtraTimeInMinutes(state);
  const dailyLimit = (getTimeQuotas as any)(
    state,
    profileId,
    profile?.deviceIds.first() || 0,
    currentWeekday
  );

  const type = !extraTime ? 'add' : 'edit';

  return {
    type,
    dailyLimit,
    extraTime,
    origin,
    ...props,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => {
  return {
    onClickClose: () => dispatch(goBackIfHistory()),
    onSetExtraTime: (_actionType, minutes, origin) => {
      const isQuickAction = origin === 'unifiedNowCard';
      dispatch(saveExtraTime({ minutes, profileId, isQuickAction }));
    },
  };
};

const ExtraTimeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraTimeModal);

ExtraTimeModalContainer.load = () => (dispatch, getState) => {
  const uid = getActiveProfileUid(getState());
  if (!getIfExtraTimeIsFetching(getState()) && uid !== null) {
    dispatch(fetchExtraTime(uid));
  }
};

export default ExtraTimeModalContainer;
