import * as React from 'react';
import { Button, Modal, ButtonSize, ModalStyledText } from 'styleguide-react';
import { t } from '../../lib/i18n';
import type { UndoRoutineSwitchModalProps } from '../../containers/Modal/UndoRoutineSwitchModalContainer';
import IconUndo from '../Icons/IconUndo';

const UndoRoutineSwitchModal = ({
  profileUid,
  routineUid,
  scheduleUid,
  nowCardState,
  onClickClose,
  onClickConfirm,
}: UndoRoutineSwitchModalProps) => {
  const hasRequiredParams =
    profileUid && routineUid && scheduleUid && nowCardState;
  return (
    <Modal
      header={{
        icon: <IconUndo />,
      }}
      title={t('Undo routine switch')}
      size="medium"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button1"
          onClick={() => {
            if (!hasRequiredParams) return undefined;
            onClickConfirm(profileUid, routineUid, scheduleUid, nowCardState);
          }}
          size={ButtonSize.medium}
          block
          disabled={!hasRequiredParams}
          testId="confirm"
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      <ModalStyledText>{t('Return to your normal schedule.')}</ModalStyledText>
    </Modal>
  );
};
export default UndoRoutineSwitchModal;
