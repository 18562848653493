import { fromJS, NonEmptyMapRecord } from 'immutable';
import * as camelize from 'camelize';
import api from '../api';
import { LicenseRecord } from '../records';
import { tapReject } from '../helpers';

export const REQUEST_LICENSE = 'REQUEST_LICENSE';
export const REQUEST_LICENSE_ERROR = 'REQUEST_LICENSE_ERROR';
export const RECEIVE_LICENSE = 'RECEIVE_LICENSE';

export default function license(
  state: NonEmptyMapRecord<{
    item: LicenseRecord;
    isFetching: boolean;
  }> = fromJS({
    item: LicenseRecord(),
    isFetching: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_LICENSE:
      return state.merge({
        isFetching: true,
      });
    case REQUEST_LICENSE_ERROR:
      return state.merge({
        isFetching: false,
      });
    case RECEIVE_LICENSE:
      return state.merge({
        isFetching: false,
        item: action.payload,
      });
    default:
      return state;
  }
}

export function requestLicense() {
  return {
    type: REQUEST_LICENSE,
  };
}

export function requestLicenseError() {
  return {
    type: REQUEST_LICENSE_ERROR,
  };
}

export function receiveLicense(json) {
  return {
    type: RECEIVE_LICENSE,
    payload: LicenseRecord.fromPayload(camelize(json)),
    receivedAt: Date.now(),
  };
}

export function fetchLicense() {
  return dispatch => {
    dispatch(requestLicense());
    return api.license
      .get({})
      .then(json => {
        dispatch(receiveLicense(json));
      })
      .catch(tapReject(() => dispatch(requestLicenseError())));
  };
}
