import * as React from 'react';
import { Card, FlexLayout, Tag, Typography } from 'styleguide-react';
import RenderWhen, { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';
import { t } from '../../../lib/i18n';

import familyTherapyImg from '../../../assets/base/images/wellbeing/family-therapy-small.png';
import wellbeingActivitiesImg from '../../../assets/base/images/wellbeing/wellbeing-activities-small.png';
import nextActivityImg from '../../../assets/base/images/wellbeing/next-activity.png';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';

interface WellbeingCardProps {
  type: 'activities' | 'therapy';
  nextActivity?: string;
}

const isActivities = (type: WellbeingCardProps['type']) =>
  type === 'activities';

const WellbeingCard = ({ type, nextActivity }: WellbeingCardProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <FlexLayout mainaxis="column" className="par-wellbeing-card">
          <Card>
            <FlexLayout
              mainaxis="row"
              minHeight={isDesktop(screenSize) ? '240' : undefined}
              maxWidth={isDesktop(screenSize) ? '584' : '440'}
              padding="16"
            >
              {isDesktop(screenSize) && (
                <FlexLayout
                  mainaxis="column"
                  mainaxisAlignment="center"
                  crossaxisAlignment="center"
                >
                  <img
                    alt=""
                    src={
                      isActivities(type)
                        ? wellbeingActivitiesImg
                        : familyTherapyImg
                    }
                    width={157}
                  />
                </FlexLayout>
              )}
              <FlexLayout
                mainaxis="column"
                mainaxisAlignment="center"
                marginLeft={isDesktop(screenSize) ? '24' : undefined}
              >
                <FlexLayout
                  mainaxis="row"
                  marginBottom={isDesktop(screenSize) ? '0' : '16'}
                >
                  <Typography
                    type={isDesktop(screenSize) ? 'h4' : 'title3'}
                    weight="semibold"
                  >
                    {isActivities(type)
                      ? t('Wellbeing activities')
                      : t('Family therapy')}
                  </Typography>

                  <FlexLayout mainaxis="column" marginLeft="8">
                    <Tag
                      variant="rounded"
                      text={t('Active')}
                      size="small"
                      invertColor
                    />
                  </FlexLayout>
                </FlexLayout>

                <FlexLayout mainaxis="column">
                  <Typography type="body1">
                    {isActivities(type)
                      ? t(
                          'Weekly activities sent to your inbox to help you create meaningful moments with your children away from screens.'
                        )
                      : t(
                          'Weekly sessions with a professional wellbeing coach to support your family.'
                        )}
                  </Typography>
                </FlexLayout>

                {isActivities(type) && (
                  <FlexLayout mainaxis="column" marginTop="16">
                    <BackgroundGradient gradient="secondary-lighter">
                      <FlexLayout
                        mainaxis="row"
                        padding="16"
                        height="100%"
                        crossaxisAlignment="center"
                      >
                        <FlexLayout mainaxis="column" marginRight="8">
                          <img src={nextActivityImg} alt="" width="40px" />
                        </FlexLayout>
                        <FlexLayout mainaxis="column">
                          <Typography type="body2">
                            {nextActivity === 'Today'
                              ? t('Next activity delivered')
                              : t('Next activity delivered on')}
                          </Typography>
                          <Typography
                            type="body1"
                            weight="semibold"
                            className="par-wellbeing-card__next-activity"
                          >
                            {nextActivity}
                          </Typography>
                        </FlexLayout>
                      </FlexLayout>
                    </BackgroundGradient>
                  </FlexLayout>
                )}
              </FlexLayout>
            </FlexLayout>
          </Card>
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

export default WellbeingCard;
