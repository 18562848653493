import { PlaceAddressData } from '../../containers/FamilyLocator/types';
import {
  googlePlacesAutocompleteServiceInstance,
  googlePlacesPlacesServiceInstance,
} from '../wrappers';

export interface LatLng {
  latitude: number;
  longitude: number;
}

export interface Address {
  formatted: string;
  coordinates: LatLng;
  bounds: {
    northeast: LatLng;
    southwest: LatLng;
  };
}

export const resolveAddressFromString = (
  input: string,
  token: string
): Promise<PlaceAddressData[]> =>
  fetchAutocompleteResults(input, token).then(
    (googleplacesResults: AutocompleteResultPrediction[]) => {
      if (!googleplacesResults) {
        return [];
      }

      return googleplacesResults.map(result => ({
        id: result.place_id,
        main_text: result.structured_formatting.main_text,
        secondary_text: result.structured_formatting.secondary_text,
      }));
    }
  );

export const getAddressFromPlaceId = (
  placeId: string,
  token: string
): Promise<Address> =>
  new Promise(resolve => {
    const placeDetailsService = googlePlacesPlacesServiceInstance(
      document.createElement('div')
    );
    placeDetailsService.getDetails(
      {
        placeId,
        fields: ['geometry', 'formatted_address'],
        sessiontoken: token,
      },
      (result, status) => {
        throwExceptionIfUnexpectedStatus(status);
        resolve(mapPlaceDetailsToAddress(result));
      }
    );
  });

interface AutocompleteResultPrediction {
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  types: string[];
}

interface PlaceDetailsResult {
  id: string;
  formatted_address: string;
  geometry: {
    location: { lat: any; lng: any };
    location_type: string;
    viewport: any;
  };
}

class GooglePlacesError extends Error {
  status: string;

  constructor(status: string) {
    super(`GoogleGeocoding Error: ${status}`);
    this.name = 'GooglePlacesError';
    this.status = status;
  }
}

const throwExceptionIfUnexpectedStatus = (status: string) => {
  if (!['OK', 'ZERO_RESULTS'].includes(status)) {
    throw new GooglePlacesError(status);
  }
};

const mapPlaceDetailsToAddress = (
  placeDetails: PlaceDetailsResult
): Address => ({
  formatted: placeDetails.formatted_address,
  coordinates: {
    latitude: placeDetails.geometry.location.lat(),
    longitude: placeDetails.geometry.location.lng(),
  },
  bounds: {
    northeast: {
      latitude: placeDetails.geometry.viewport.getNorthEast().lat(),
      longitude: placeDetails.geometry.viewport.getNorthEast().lng(),
    },
    southwest: {
      latitude: placeDetails.geometry.viewport.getSouthWest().lat(),
      longitude: placeDetails.geometry.viewport.getSouthWest().lng(),
    },
  },
});

const fetchAutocompleteResults = (
  input: string,
  token: string
): Promise<AutocompleteResultPrediction[]> =>
  new Promise(resolve => {
    const autocompleteService = googlePlacesAutocompleteServiceInstance();
    autocompleteService.getPlacePredictions(
      {
        input,
        sessiontoken: token,
        types: ['geocode'],
      },
      (results, status) => {
        throwExceptionIfUnexpectedStatus(status);
        resolve(results);
      }
    );
  });
