import { connect } from 'react-redux';
import State, { Dispatch } from '../../store/state';
import TimelineSelector from '../../components/TimelineSelector/TimelineSelector';
import { isSchoolDashboardRoute } from '../../helpers/routes';
import {
  getLocationPathname,
  isProfileLinkedWithLinewizeStudent,
} from '../../selectors';
import { TimelineType } from '../../components/TimelineSelector/TimelineSelector.types';
import {
  navigateToFamilyTimeline,
  navigateToSchoolTimeline,
} from '../../actions/Navigation';
import flags from '../../sideEffects/flags';
import { getIsSchoolAndFamilyProfile } from '../Dashboard/helpers/profileDashboardHelpers';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';

interface TimelineSelectorContainerProps {
  profileId: string;
}

const mapStateToProps = (
  state: State,
  { profileId }: TimelineSelectorContainerProps
) => {
  const isSchoolTimeline = isSchoolDashboardRoute(getLocationPathname(state));
  const isCombinedProfile = getIsSchoolAndFamilyProfile(
    state,
    Number(profileId)
  );
  const delegatedToThisAccount = isProfileDelegatedToThisAccount(
    isDelegationEnabled(state),
    isStudentOptInForDelegationForThisAccount(state),
    isProfileLinkedWithLinewizeStudent(state, profileId)
  );
  const displayTabs =
    flags.useUnifiedDashboard.isEnabled() &&
    (isCombinedProfile || delegatedToThisAccount);
  const isOnlyDelegatedDevice = delegatedToThisAccount && !isCombinedProfile;
  return {
    tabSelected: isSchoolTimeline ? 'school' : 'family',
    displayTabs,
    isOnlyDelegatedDevice,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profileId }: TimelineSelectorContainerProps
) => {
  return {
    navigateToTimeline: (type: TimelineType) => {
      if (type === 'school') {
        dispatch(navigateToSchoolTimeline(profileId, true));
      } else if (type === 'family') {
        dispatch(
          navigateToFamilyTimeline({ profileId, replaceNavigation: true })
        );
      }
    },
  };
};

const TimelineSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineSelector);

export default TimelineSelectorContainer;
