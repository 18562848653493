import * as React from 'react';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import { NotificationRecord } from '../../records/notification';
import Notification from '../Notifications/Notification';
import { infiniteScroll } from '../../helpers/hocs';
import IconInsightsEmptyState from '../Icons/IconInsightsEmptyState';

interface InsightsNotificationListProps {
  notifications: List<NotificationRecord>;
  locale: string;
  isEmpty: boolean;
}

export const InsightsNotificationListComponent: React.FunctionComponent<
  InsightsNotificationListProps
> = ({ notifications, locale, isEmpty }) =>
  !isEmpty ? (
    <ul className="section--padded" data-testId="InsightsNotificationList">
      {notifications.map(notification => (
        <React.Fragment key={notification.uid}>
          <Notification notification={notification} locale={locale} />
        </React.Fragment>
      ))}
    </ul>
  ) : (
    <div className="InsightsNotificationList--emptyState section--padded">
      <IconInsightsEmptyState />
      <p>
        <strong>{t('No app insights yet')}</strong>
      </p>
      <p>
        {
          // eslint-disable-next-line local-rules/dirty-translation-key
          t(
            'We`ll let you know of any significant changes in the time spent on apps.'
          )
        }
      </p>
    </div>
  );

const InsightsNotificationList = infiniteScroll<InsightsNotificationListProps>(
  InsightsNotificationListComponent
);

export default InsightsNotificationList;
