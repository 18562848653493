import State from '../store/state';
import { getPersistentStorageItem } from './helpers';
import OnboardingTokenRecord from '../records/onboardingToken';
import { RemoteOnboardingUrlData } from '../types/RemoteOnboardingUrlData.types';
import { getMilisecondsTimestamp } from '../helpers/dates';
import * as qinit from '../qinit';
import { REMOTE_ONBOARDING_TOKEN_LIFESPAN_MS } from '../constants';
import { ProfileRecord } from '../records/profile/types/Profile.types';

export const getOnboardingProfile = (state: State): ProfileRecord | undefined =>
  state.get('onboarding').get('profile');

export const getOnboardingDeviceType = (state: State) =>
  state.get('onboarding').get('deviceType');

export const getOnboardingDevicePlatform = (state: State) =>
  state.get('onboarding').get('devicePlatform');

export const isOnboardingCurrentDevice = (state: State) =>
  state.get('onboarding').get('isCurrentDevice');

export const getOnboardingSocialShareType = (state: State) =>
  state.get('onboarding').get('socialShareType');

export const getOnboardingPendingDeviceConnection = (state: State) =>
  state.get('onboarding').get('pendingDeviceConnection');

export const isOnboardingVerifyingDevice = (state: State) =>
  state.get('onboarding').get('isVerifyingDevice') as boolean;

export const isAppOnboardingFinished = (state: State) =>
  getPersistentStorageItem(state, 'appOnboardingFinished') === 'true';

export const isLoadingProfiles = (state: State) =>
  state.get('onboarding').get('isLoadingProfiles') as boolean;

export const isFetchingRemoteOnboardingToken = (state: State) =>
  state.get('onboarding').get('isFetchingRemoteOnboardingToken');

export const isRemoteOnboardingTokenExpired = (state: State) =>
  state.get('onboarding').get('isOnboardingTokenExpired');

export const isPollingAccountData = (state: State) =>
  state.get('onboarding').get('isPollingAccountData');

export const getRemoteOnboardingTokenData = (
  state: State
): OnboardingTokenRecord => {
  const localStorageData = getPersistentStorageItem(
    state,
    'onboardingTokenData'
  );
  if (localStorageData === '') {
    return OnboardingTokenRecord();
  }

  return OnboardingTokenRecord.fromLocalStorage(JSON.parse(localStorageData));
};

export const getRemoteOnboardingRenewTimeout = (
  tokenData: OnboardingTokenRecord
): number => {
  return Math.max(
    0,
    tokenData.receivedAt +
      REMOTE_ONBOARDING_TOKEN_LIFESPAN_MS -
      getMilisecondsTimestamp()
  );
};

export const getRemoteOnboardingUrl = (
  tokenData: OnboardingTokenRecord
): RemoteOnboardingUrlData => {
  const strippedDomain = qinit.tenant.common.dashboard.short_url.substring(8);
  const formattedToken = tokenData.token.toUpperCase();

  return {
    raw: `${qinit.tenant.common.dashboard.short_url}/o${tokenData.token}`,
    formatted: `${strippedDomain}/o${formattedToken}`,
  };
};
