import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import {
  getLicense,
  getQueryParam,
  getProfile,
  areProfilesExceeded,
  areDevicesExceeded,
  canPurchase,
} from '../../selectors';
import { shouldShowConversionElements } from '../../records/license';
import OverLimitModal from '../../components/Modal/OverLimitModal';
import { confirmDeleteProfile } from '../../actions/ProfileActions';
import {
  ButtonNames,
  Events,
  GenericPageNames,
  track,
  trackButtonClicked,
  trackMyFamilyTopbarAction,
} from '../../helpers/analytics';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import State, { BaseThunk, Dispatch } from '../../store/state';
import { navigateToUpgrade } from '../../actions/Navigation';

export enum OverLimitModalReferrer {
  overlimitTopbar = 'overlimit-topbar',
  overlimitAccessProfile = 'overlimit-access-profile',
}

export const getProfileIdFromQuery = (state: State) =>
  getQueryParam(state, 'profileId');

export const getReferrerFromQuery = (state: State) =>
  getQueryParam(state, 'referrer') as OverLimitModalReferrer | undefined;

const trackOverlimit = (
  canPurchase: boolean,
  referrer: OverLimitModalReferrer | undefined,
  areDevicesExceeded: boolean,
  areProfilesExceeded: boolean
) => {
  if (canPurchase) {
    let reason:
      | Parameters<typeof trackMyFamilyTopbarAction>['0']['reason']
      | null = null;

    if (areDevicesExceeded && areProfilesExceeded) {
      reason = 'overlimit_profiles_and_devices';
    } else if (areDevicesExceeded) {
      reason = 'overlimit_devices';
    } else if (areProfilesExceeded) {
      reason = 'overlimit_profiles';
    }

    if (reason && referrer === OverLimitModalReferrer.overlimitTopbar) {
      trackMyFamilyTopbarAction({ reason });
    }

    if (reason && referrer === OverLimitModalReferrer.overlimitAccessProfile) {
      trackButtonClicked(GenericPageNames.MyFamily, ButtonNames.Upgrade, {
        options: JSON.stringify({
          context: 'profile_access',
          reason,
        }),
      });
    }
  }
};

const mapStateToProps = (state: State) => {
  const profileId = getProfileIdFromQuery(state);
  const referrer = getReferrerFromQuery(state);
  const profile = getProfile(state, profileId);
  const profileName = profileId && profile !== undefined ? profile.name : null;

  return {
    profileId,
    profileName,
    areProfilesExceeded: areProfilesExceeded(state),
    areDevicesExceeded: areDevicesExceeded(state),
    license: getLicense(state),
    referrer,
    canPurchase: canPurchase(state),
  };
};

const onClickFooterButton =
  (
    canPurchase: boolean,
    referrer: OverLimitModalReferrer | undefined,
    areDevicesExceeded: boolean,
    areProfilesExceeded: boolean
  ): BaseThunk =>
  (dispatch, getState) => {
    const license = getLicense(getState());

    if (!shouldShowConversionElements(license)) {
      return dispatch(goBackIfHistory());
    }

    if (!canPurchase) {
      dispatch(goBackIfHistory());
    } else {
      track(Events.ClickedOverlimitProfileDialogUpgrade);
      trackOverlimit(
        canPurchase,
        referrer,
        areDevicesExceeded,
        areProfilesExceeded
      );
      dispatch(navigateToUpgrade());
    }
  };

export const onClickDeleteDevices = (): BaseThunk => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner:replace',
      src: '/devices',
    })
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickFooterButton: (
    canPurchase: boolean,
    referrer: OverLimitModalReferrer | undefined,
    areDevicesExceeded: boolean,
    areProfilesExceeded: boolean
  ) => {
    return dispatch(
      onClickFooterButton(
        canPurchase,
        referrer,
        areDevicesExceeded,
        areProfilesExceeded
      )
    );
  },
  onClickDeleteChild: (profileId: string | null) => {
    track(Events.ClickedRemoveOverlimitProfileDialog);
    if (profileId) dispatch(confirmDeleteProfile(Number(profileId)));
  },
  onClickDeleteDevices: () => dispatch(onClickDeleteDevices()),
});
export type OverLimitModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const OverLimitModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OverLimitModal);

export default OverLimitModalContainer;
