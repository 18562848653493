import * as camelize from 'camelize';
import { fromJS, NonEmptyMapRecord, List } from 'immutable';
import {
  SearchActivityRecord,
  CallsAndSmsActivityRecord,
} from '../records/activity';
import { getSelectedDateRange } from '../selectors/index';
import api from '../api';
import { tapReject } from '../helpers';
import State, { Dispatch } from '../store/state';

import { isQuestionableWebSearchesFeactureActive } from '../selectors/featureFlags';
import { AnyError } from '../helpers/errorHandling';
import { ttl1Minute } from '../lib/QApiCache/commonCacheStrategies';

export const REQUEST_CALLS_AND_SMS = 'REQUEST_CALLS_AND_SMS';
export const RECEIVE_CALLS_AND_SMS = 'RECEIVE_CALLS_AND_SMS';
export const REQUEST_CALLS_AND_SMS_ERROR = 'REQUEST_CALLS_AND_SMS_ERROR';
export const REQUEST_SEARCHES = 'REQUEST_SEARCHES';
export const REQUEST_MORE_SEARCHES = 'REQUEST_MORE_SEARCHES';
export const RECEIVE_SEARCHES = 'RECEIVE_SEARCHES';
export const REQUEST_SEARCHES_ERROR = 'REQUEST_SEARCHES_ERROR';

export default function socialActivity(
  state: NonEmptyMapRecord<{
    callsAndSms: List<CallsAndSmsActivityRecord>;
    searches: List<SearchActivityRecord>;
    isFetching: boolean;
    isFetchingCallAndSms: boolean;
    isFetchingSearches: boolean;
    isFetchingMore: boolean;
    callAndSmsError?: AnyError;
    searchesError?: AnyError;
  }> = fromJS({
    callsAndSms: [],
    searches: [],
    isFetching: false,
    isFetchingCallAndSms: false,
    isFetchingSearches: false,
    isFetchingMore: false,
    callAndSmsError: undefined,
    searchesError: undefined,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_CALLS_AND_SMS:
      return state.merge({
        isFetching: true,
        isFetchingCallAndSms: true,
        callAndSmsError: undefined,
      });
    case REQUEST_SEARCHES:
      return state.merge({
        isFetching: true,
        isFetchingSearches: true,
        searchesError: undefined,
      });
    case REQUEST_MORE_SEARCHES:
      return state.set('isFetchingMore', true);
    case RECEIVE_CALLS_AND_SMS:
      return state.merge({
        isFetching: false,
        isFetchingCallAndSms: false,
        callsAndSms: action.payload.callsAndSms,
      });
    case RECEIVE_SEARCHES:
      return state.merge({
        isFetching: false,
        isFetchingMore: false,
        isFetchingSearches: false,
        searches: action.payload.searches,
      });
    case REQUEST_CALLS_AND_SMS_ERROR:
      return state.merge({
        isFetching: false,
        isFetchingMore: false,
        callAndSmsError: action.payload,
      });
    case REQUEST_SEARCHES_ERROR:
      return state.merge({
        isFetching: false,
        isFetchingMore: false,
        searchesError: action.payload,
      });
    default:
      return state;
  }
}

export function requestCallsAndSmsError(payload: AnyError) {
  return {
    type: REQUEST_CALLS_AND_SMS_ERROR,
    payload,
  };
}

export function requestSearchesError(payload: AnyError) {
  return {
    type: REQUEST_SEARCHES_ERROR,
    payload,
  };
}

export const requestCallsAndSms = () => {
  return {
    type: REQUEST_CALLS_AND_SMS,
  };
};

export function requestSearches() {
  return {
    type: REQUEST_SEARCHES,
  };
}

export function requestMoreSearches() {
  return {
    type: REQUEST_MORE_SEARCHES,
  };
}

export const receiveCallsAndSms = (items: List<CallsAndSmsActivityRecord>) => ({
  type: RECEIVE_CALLS_AND_SMS,
  payload: {
    callsAndSms: camelize(items).map(callsAndSmsItem => {
      return CallsAndSmsActivityRecord.fromPayload(callsAndSmsItem);
    }),
  },
});

export const receiveSearches = (items: List<SearchActivityRecord>) => ({
  type: RECEIVE_SEARCHES,
  payload: {
    searches: camelize(items).map(searchItem => {
      return SearchActivityRecord.fromPayload(searchItem);
    }),
  },
});

export const fetchCallsAndSmsIfUserIsPremiumOrTrial =
  (profileUid: string) => (dispatch: Dispatch, getState) => {
    dispatch(requestCallsAndSms());
    const { minDate, maxDate } = getSelectedDateRange(getState());
    return api.callsAndSmsSummary
      .withCache(ttl1Minute)
      .get({ profileUid, minDate, maxDate })
      .then(json => dispatch(receiveCallsAndSms(json.items)))
      .catch(tapReject(e => dispatch(requestCallsAndSmsError(e))));
  };

export const fetchSearches =
  (profileUid: string) => (dispatch: Dispatch, getState) => {
    dispatch(requestSearches());

    const queryParams = getQueryParamsFor({ state: getState(), profileUid });

    return dispatch(retrieveSearches(queryParams));
  };

export const fetchMoreSearches =
  (profileUid: string, limit: string) => (dispatch: Dispatch, getState) => {
    dispatch(requestMoreSearches());

    const queryParams = getQueryParamsFor({
      state: getState(),
      profileUid,
      limit,
    });

    return dispatch(retrieveSearches(queryParams));
  };

export const getQueryParamsFor = ({
  state,
  profileUid,
  limit,
}: {
  state: State;
  profileUid: string;
  limit?: string;
}) => {
  const { minDate, maxDate } = getSelectedDateRange(state);

  const queryParams: {
    profileUid: string;
    minDate: string;
    maxDate: string;
    limit?: string;
    filter?: SummarySearchesFilter;
  } = { profileUid, minDate, maxDate };

  if (limit) {
    queryParams.limit = limit;
  }

  if (isQuestionableWebSearchesFeactureActive(state)) {
    queryParams.filter = SummarySearchesFilter.Questionable;
  }

  return queryParams;
};

const retrieveSearches = queryParams => (dispatch: Dispatch) =>
  api.searchSummary
    .withCache(ttl1Minute)
    .get(queryParams)
    .then(json => dispatch(receiveSearches(json.items)))
    .catch(tapReject(e => dispatch(requestSearchesError(e))));

enum SummarySearchesFilter {
  Questionable = 'questionable',
}
