import * as React from 'react';
import { complement } from 'ramda';
import { RoutineRuleType } from '../../routines.types';
import { useMachine } from '@xstate/react';
import { ChooseRulesProps } from './types/ChooseRulesProps.types';
import {
  AssignAction,
  Condition,
  EventObject,
  assign,
  createMachine,
} from 'xstate';

type Context = { ruleType: RoutineRuleType };

// cond
const isRuleInvalid: Condition<Context, EventObject> = context => {
  return context.ruleType === 'DEFAULT';
};

// Actions
const updateState: AssignAction<
  Context,
  EventObject & Pick<Context, 'ruleType'>
> = assign({
  ruleType: (_context, event) => event.ruleType,
});

// Transitions
const SELECT_RULE = () => ({
  target: 'RULE_SELECTED',
  actions: [updateState],
});

const chooseRulesMachine = createMachine<Context>({
  predictableActionArguments: true,
  id: 'chooseRules',
  initial: 'IDLE',
  context: {
    ruleType: 'DEFAULT',
  },
  states: {
    IDLE: {
      on: {
        SELECT_RULE: SELECT_RULE(),
        SUBMIT: [
          { target: 'ERROR', cond: isRuleInvalid },
          {
            target: 'RULE_SELECTED',
            cond: complement(isRuleInvalid),
          },
        ],
      },
    },
    ERROR: {
      on: { SELECT_RULE: SELECT_RULE() },
    },
    RULE_SELECTED: {
      on: {
        SELECT_RULE: SELECT_RULE(),
        SUBMIT: { actions: [() => undefined] },
      },
    },
  },
});

const useChooseRules = ({
  next,
  ruleType,
  onSelectRuleType,
}: Pick<ChooseRulesProps, 'next' | 'ruleType' | 'onSelectRuleType'>) => {
  const [state, send] = useMachine(chooseRulesMachine, {
    context: { ruleType: ruleType || 'DEFAULT' },
  });

  const selectedRule = state.context.ruleType;
  const hasError = state.matches('ERROR');
  const onSubmit = React.useCallback(() => send({ type: 'SUBMIT' }), [send]);
  const onUpdateRule = React.useCallback(
    (rule: RoutineRuleType) => {
      send({ type: 'SELECT_RULE', ruleType: rule });
    },
    [send]
  );

  React.useEffect(() => {
    if (state.event.type === 'SELECT_RULE') {
      onSelectRuleType(state.context.ruleType);
    }
    if (state.event.type === 'SUBMIT' && state.value === 'RULE_SELECTED') {
      next?.(state.context.ruleType);
    }
  }, [state]);

  return {
    selectedRule,
    hasError,
    onSubmit,
    onUpdateRule,
  };
};

export default useChooseRules;
