import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  getAllCountries,
  filterCountriesByString,
} from '../../helpers/languages';
import {
  Button,
  ButtonSize,
  Dropdown,
  DropdownMenuPlacement,
  DropdownOption,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  Label,
  TextField,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayout from '../Layout/PageLayout';

const EditCountry: React.FunctionComponent<{
  country: string;
  onClickSaveButton: (countryApiCode: string) => void;
}> = ({ country, onClickSaveButton }) => (
  <PageLayout
    title={t('Edit Country')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
  >
    <EditCountryFields
      country={country}
      onClickSaveButton={onClickSaveButton}
    />
  </PageLayout>
);

export const EditCountryFields: React.FunctionComponent<{
  country: string;
  onClickSaveButton: (countryApiCode: string) => void;
}> = ({ country, onClickSaveButton }) => {
  const [selectedCountry, setSelectedCountry] = React.useState(country);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-between"
      flexGrow="2"
      matchParentHeight
    >
      <FlexLayout mainaxis="column">
        <FlexLayout mainaxis="column" marginBottom="8">
          <Label>{t('Edit Country')}</Label>
        </FlexLayout>

        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <DropdownEditCountry
            maxHeight={145}
            country={country}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            setButtonDisabled={setButtonDisabled}
          />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <DropdownEditCountry
            maxHeight={230}
            country={country}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            setButtonDisabled={setButtonDisabled}
          />
        </RenderWhen>
      </FlexLayout>
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          centered
          htmlType="submit"
          size={ButtonSize.medium}
          onClick={() => onClickSaveButton(selectedCountry)}
          testId="saveEditCountry"
          disabled={buttonDisabled}
        >
          {t('Save')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

EditCountryFields.displayName = 'EditCountryFields';

const DropdownEditCountry: React.FunctionComponent<{
  maxHeight: number;
  country: string;
  selectedCountry: string;
  setSelectedCountry: (value) => void;
  setButtonDisabled: (value) => void;
}> = ({
  maxHeight,
  country,
  selectedCountry,
  setSelectedCountry,
  setButtonDisabled,
}) => {
  const [textToFilterBy, setTextToFilterBy] = React.useState('');
  const [dropdownIsOpen, setDropdownOpeness] = React.useState(false);

  const handleChange = value => {
    setTextToFilterBy('');
    setSelectedCountry(value);
    setButtonDisabled(false);
  };
  const handleTextChange = ev => {
    setTextToFilterBy(ev.target.value);
    setButtonDisabled(true);
  };
  const handleIsDropdownOpen = newDropdownOpeness => {
    if (!dropdownIsOpen) {
      setTextToFilterBy('');
    }
    setDropdownOpeness(newDropdownOpeness);
  };
  const getSelectedCountryValue = () => {
    if (!dropdownIsOpen) {
      if (selectedCountry) {
        const countryIndex = getAllCountries().findIndex(
          country => country[0] === selectedCountry
        );
        if (countryIndex !== -1) {
          setButtonDisabled(false);
          return getAllCountries()[countryIndex][1];
        }
        return '';
      }
      return '';
    }
    return textToFilterBy;
  };
  return (
    <Dropdown
      actionElement={
        <TextField
          type="text"
          name={country}
          onChange={handleTextChange}
          value={getSelectedCountryValue()}
          testId="editCountry"
          block
          iconLeft={<Icon type={IconType.search} color={IconColor.primary} />}
          keepErrorLabelArea={false}
        />
      }
      onChange={handleChange}
      onActiveChange={handleIsDropdownOpen}
      testId="countries"
      maxHeight={maxHeight}
      menuPlacement={DropdownMenuPlacement.bottom}
      fixedMenuPlacement
    >
      {filterCountriesByString(textToFilterBy).map(countryPair => (
        <DropdownOption
          text={countryPair[1]}
          value={countryPair[0]}
          selected={countryPair[0] === selectedCountry}
          key={countryPair[0]}
        />
      ))}
    </Dropdown>
  );
};

export default EditCountry;
