import { ofType } from 'redux-observable';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  map,
  merge,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { AppActionWithPayload, AppEpic } from '../types';
import { refreshNowCardAction } from '../../ducks/unifiedNowCard/actions';
import {
  UnifiedNowCardRefreshAction,
  UnifiedNowCardUpdateOnFinishStateAction,
} from '../../ducks/unifiedNowCard/types';

const notifyIfError = catchError(() => {
  return EMPTY;
});

export const UpdateNowCardStateEpic: AppEpic<
  AppActionWithPayload,
  UnifiedNowCardRefreshAction
> = actions$ => {
  let myTimer;
  const timerDone$ = new Subject<boolean>();

  const updateNowCard$ = actions$.pipe(ofType('UPDATE_NOW_CARD_STATE'));
  const cancelUpdateNowCard$ = actions$.pipe(
    ofType('CANCEL_UPDATE_NOW_CARD_STATE')
  );

  const loadNowCardData = updateNowCard$.pipe(
    switchMap((action: UnifiedNowCardUpdateOnFinishStateAction) => {
      myTimer = timer(action.payload.durationMilliseconds).pipe(
        takeUntil(timerDone$),
        map(() => refreshNowCardAction(action.payload.profileId)),
        notifyIfError
      );
      return myTimer as Observable<UnifiedNowCardRefreshAction>;
    })
  );

  const cancelNowCardUpdate = cancelUpdateNowCard$.pipe(
    switchMap(() => {
      timerDone$.next(true);
      return EMPTY;
    })
  );

  return merge(loadNowCardData, cancelNowCardUpdate);
};

export default UpdateNowCardStateEpic;
