import { connect } from 'react-redux';
import { getAccountLocale } from '../../../selectors';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import { navigateToAddDeviceAction } from '../../../actions/ProfileActions';
import PanicButtonRulesAddDeviceFlyover from '../../../components/PanicButtonRules/PanicButtonAddDeviceFlyover';
import {
  GenericPageNames,
  ProfileRuleNames,
  trackProtectADeviceBlockedFeature,
} from '../../../helpers/analytics';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickAddDevice: () => {
    trackProtectADeviceBlockedFeature(
      GenericPageNames.PanicButtonRules,
      ProfileRuleNames.PanicButton
    );
    dispatch(navigateToAddDeviceAction());
  },
});

const PanicButtonRulesAddDeviceFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanicButtonRulesAddDeviceFlyover);

export type PanicButtonRulesAddDeviceFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default PanicButtonRulesAddDeviceFlyoverContainer;
