import { combineEpics, ofType } from 'redux-observable';
import { switchMap, first } from 'rxjs/operators';
import { LocationChangeAction } from 'react-router-redux';
import { forkJoin } from 'rxjs';
import {
  isStudentSummaryPage,
  isStudentSummarySubPage,
  ofLocationChangeActionWhen,
} from '../router';
import { Dependencies } from '../middleware';
import { AppEpicWithoutStateEffect, AppAction } from '../types';
import { doWithoutStateEffect } from '../operators';
import { not } from '../../helpers/predicates';
import { isFeatureDisabled } from '../../containers/NowCard/helpers/schools/helper';
import { getSummaryFetchError } from '../../selectors/studentSummary';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { UnifiedNowCardState } from '../../businessLogic/unifiedNowCard/types';
import { SmartDevicesWithStatus } from '../../ducks/smartDeviceStatus/types';
import { ProfileNotificationTopBarProps } from '../../components/ProfileNotificationTopBar/types';

const sendLoadPageAnalytics = (analytics: Dependencies['analytics']) => {
  const { page, PageNames } = analytics;
  page(PageNames.ProfileSchoolActivity);
};

const ensureOfStudentSummaryPagePage = ofLocationChangeActionWhen({
  to: isStudentSummaryPage,
  from: not(isStudentSummarySubPage),
});

export const loadStudentSummaryEpic: AppEpicWithoutStateEffect<AppAction> = (
  $actions,
  _$state,
  { analytics }
) => {
  return $actions.pipe(
    ensureOfStudentSummaryPagePage,
    doWithoutStateEffect(() => sendLoadPageAnalytics(analytics))
  );
};

interface PageTrackValues {
  cardStatus: UnifiedNowCardState;
  schoolHasEnabledSummary: boolean;
  schoolHasPauseEnabled: boolean;
  devicesStatus?: SmartDevicesWithStatus;
  topProfileNotificationBarState?: ProfileNotificationTopBarProps;
}

const sendStudentSummaryStatus = (
  values: PageTrackValues,
  analytics: Dependencies['analytics']
) => {
  const { format, trackPageLoaded, GenericPageNames } = analytics;

  const data = format({
    options: {
      nowCardStatus: values.cardStatus?.toLowerCase(),
      schoolHasEnabledPause: values.schoolHasPauseEnabled,
      schoolHasEnabledSummary: values.schoolHasEnabledSummary,
      devicesStatus: values.devicesStatus,
      profileTopNotification: null,
    },
  });

  trackPageLoaded(GenericPageNames.ProfileSchoolActivity, data);
};

export const studentSummaryStatusEpic: AppEpicWithoutStateEffect<
  LocationChangeAction
> = ($actions, $state, { analytics }) => {
  return $actions.pipe(
    ensureOfStudentSummaryPagePage,
    switchMap(() => {
      const $receivedStudents = $actions.pipe(
        ofType('RECEIVE_STUDENT_POLICIES'),
        first()
      );
      const $summaryFinishLoading = $actions.pipe(
        ofType('SUMMARY_FINISH_LOADING_DATA'),
        first()
      );

      const $unifiedNowCardLoading = $actions.pipe(
        ofType('NOW_CARD_LOADED_STATE'),
        first()
      );
      const $smartDeviceListLoading = $actions.pipe(
        ofType('SMART_DEVICES_UPDATE_LOADED'),
        first()
      );

      return forkJoin([
        $receivedStudents,
        $summaryFinishLoading,
        $unifiedNowCardLoading,
        $smartDeviceListLoading,
      ]).pipe(
        doWithoutStateEffect(values => {
          const cardStatus = values[2].payload.state;
          const devicesStatus = values[3].payload.devices;
          const schoolHasEnabledSummary =
            getSummaryFetchError($state.value) !==
            StudentSummaryFetchError.FeatureDisabled;
          const schoolHasPauseEnabled = !isFeatureDisabled($state.value);
          sendStudentSummaryStatus(
            {
              schoolHasEnabledSummary,
              schoolHasPauseEnabled,
              cardStatus,
              devicesStatus,
            },
            analytics
          );
        })
      );
    })
  );
};

export const studentSummaryTrackingEpics = combineEpics(
  loadStudentSummaryEpic,
  studentSummaryStatusEpic
);
