import { connect } from 'react-redux';
import * as camelize from 'camelize';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import ThankYou from '../../components/ThankYou';
import { bootApp } from '../../ducks/app/bootApp';
import { finishedLoadingInitialData } from '../../ducks/app/redirectAfterLogin';
import { Dispatch } from '../../store/state';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import type { RouteLocation } from '../../types/RouteLocation.types';
import { setStateFromStorage } from '../../ducks/persistentStorage';
import { setLocale, startLoadingInitialData } from '../../ducks/app';
import {
  TagManagerCustomEvents,
  sendTagManagerEvent,
  tagManagerSetup,
} from '../../helpers/tagManager';
import { logout } from '../../sideEffects/logout';
import { getAccount } from '../../selectors';
import {
  ChargebeeParams,
  chargebeeParamsValidKeys,
} from './types/ChargebeeParams';
import { formatCurrency } from '../../records/account';
import { ISOLanguage } from '../../constants';
import {
  PeriodUnit,
  formatChargebeeDateString,
  getDurationStringFromPeriod,
} from '../../helpers/dates';
import { navigateToWelcomeSubscriptionModal } from '../../actions/Navigation';
import { stripTokensFromQuery } from '../../helpers/query';
import { captureException } from '../../helpers/sentry';

const mapStateToProps = () => {
  try {
    /*
    This component is rendered many times, and its props depend on the query parameters of the URL.
    Sometimes it fails due to the excessive renders.
    */
    const cbQueryParams = parseChargebeeQueryParams();
    const orderAmount = formatCurrency(
      ISOLanguage.english,
      cbQueryParams.c,
      Number(cbQueryParams.ppe)
    );

    return {
      renewalDate: formatChargebeeDateString(cbQueryParams.nb, cbQueryParams.l),
      orderAmount,
      orderFrequency: getDurationStringFromPeriod(
        Number(cbQueryParams.pp),
        cbQueryParams.ppu.toLowerCase() as PeriodUnit
      ),
    };
  } catch (e) {
    captureException(e);
    return {
      renewalDate: 0,
      orderAmount: 0,
      orderFrequency: 0,
    };
  }
};

const mapDispatchToProps = (dispatch: Dispatch, props) => ({
  onClickGoToDashboard: () => {
    const location = {
      pathname: '/',
      key: null,
    } as RouteLocation;
    dispatch(startLoadingInitialData());
    return dispatch(
      bootApp(window.location.search, location, false, { resetHistory: false })
    ).then(() => {
      dispatch(checkAccountLogged());
      dispatch(finishedLoadingInitialData());
      /*
        It's necessary to delegate the resetting of the query parameters from 'chargeby' to the
        'welcomeToPremiumModal' modal
        and to prevent bootApp from redirecting to /
        because there's a race condition with the bootApp function being called twice.
        Unfortunately, it needs to be called twice to have all the data available
        to cover the purchasing scenario from the logged-in app and from another
        non-logged-in place. This container calls it once, and the loader of the
        Main component loaded at / calls it again.
        The race condition causes the subscription modal to load,
        and then bootApp requests navigation to /
        */

      cleanChargebeeQueyParams();
      dispatch(navigateToWelcomeSubscriptionModal());
    });
  },
  onClickExternalLink: (href: string) => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'external',
        src: href,
      })
    );
  },
  ...props,
});

const checkAccountLogged = () => (dispatch, getState) => {
  const account = getAccount(getState());
  const cbQueryParams = parseChargebeeQueryParams();

  // If the account currently logged is different than the account which
  // has purchased we force a logout before redirecting the user to the
  // welcome premium modal.
  if (account.uid !== cbQueryParams.aui) {
    dispatch(logout());
  }
};

const ThankYouContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou);

ThankYouContainer.load = () => (dispatch: Dispatch) => {
  const cbQueryParams = parseChargebeeQueryParams();
  dispatch(setStateFromStorage());
  dispatch(finishedLoadingInitialData());
  dispatch(setLocale(cbQueryParams.l));
  tagManagerSetup(cbQueryParams.aui, cbQueryParams.l, cbQueryParams.c);
  sendTagManagerEvent(TagManagerCustomEvents.PurchaseComplete, cbQueryParams);
};

export type ThankYouProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/**
 * Get the chargebee query parameters propaged by checkout
 * @returns object of chargebee parameters with their proper values
 */
export const parseChargebeeQueryParams = (): ChargebeeParams => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlSearchObj = camelize(Object.fromEntries(urlSearchParams));
  const filtered = Object.entries(urlSearchObj).filter(([key]) =>
    chargebeeParamsValidKeys.includes(key as keyof ChargebeeParams)
  );
  return Object.fromEntries(filtered) as ChargebeeParams;
};

export const cleanChargebeeQueyParams = () => {
  stripTokensFromQuery(
    Object.keys(decamelizeKeysDeep(parseChargebeeQueryParams()))
  );
};

export default ThankYouContainer;
