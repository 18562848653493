import * as React from 'react';
import { Card, FlexLayout, FreeText, Layout } from 'styleguide-react';
import PageLayout from '../../Layout/PageLayout';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import { t } from '../../../lib/i18n';
import DropdownFilter, { DropdownFilterProps } from './DropdownFilter';
import SchoolTimelineContainer from '../../../containers/Schools/SchoolTimeline/SchoolTimelineContainer';
import ScrollManager from '../../Layout/hooks/useKeepScroll/ScrollManager';
import PullRefresh from '../../PullRefresh/PullRefresh';
import LastUpdatedInfo from './LastUpdatedInfo';
import { LinewizeEventFilter } from '../../../ducks/linewizeEvent';
import TimelineSelectorContainer from '../../../containers/TimelineSelector/TimelineSelectorContainer';
import { getTimelineSubtitle } from '../../ActivityTimeline/activityTimelineHelpers';

const scrollKey = ScrollManager.getInstance().register('school-timeline');

export interface SchoolTimelinePageProps {
  profileId: number;
  lastUpdated: string;
  isRefreshing: boolean;
  currentFilter?: LinewizeEventFilter;
  isUnifiedProfile: boolean;
  isDelegatedToThisAccount: boolean;
  onClickGoBack: () => void;
  onChangeFilter: DropdownFilterProps['onchangeFilter'];
  onRefreshEvents: () => void;
  onDelegationInfoClick: () => void;
}
const SchoolTimelinePage = ({
  profileId,
  lastUpdated,
  isRefreshing,
  currentFilter,
  isUnifiedProfile,
  isDelegatedToThisAccount,
  onClickGoBack,
  onChangeFilter,
  onRefreshEvents,
  onDelegationInfoClick,
}: SchoolTimelinePageProps) => {
  return (
    <PageLayout
      title={t('Activity Timeline')}
      subtitle={getTimelineSubtitle({
        isUnifiedProfile,
        isDelegatedToThisAccount,
        timelineType: 'school',
        onDelegationInfoClick,
      })}
      configuration={[
        {
          screenSize: ScreenSize.All,
          pullToRefresh: (
            <PullRefresh
              isFetching={isRefreshing}
              onRefresh={onRefreshEvents}
            />
          ),
          keepScrollKey: scrollKey,
        },
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainReducedSize: true,
          keepScrollKey: scrollKey,
        },
      ]}
      actions={[
        {
          actionName: 'back',
          onClick: onClickGoBack,
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      <Card fullWidth>
        <FlexLayout mainaxis="column">
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <Layout renderAs="span" marginBottom="8">
              <FreeText fontSize="16px">
                {getTimelineSubtitle({
                  isUnifiedProfile,
                  isDelegatedToThisAccount,
                  timelineType: 'school',
                  onDelegationInfoClick,
                })}
              </FreeText>
            </Layout>
          </RenderWhen>
          <FlexLayout mainaxis="column">
            <TimelineSelectorContainer profileId={profileId.toString()} />
            <RenderWhen
              screenSize={[
                ScreenSize.Desktop,
                ScreenSize.Tablet,
                ScreenSize.Mobile,
              ]}
            >
              {screenSize => (
                <FlexLayout
                  mainaxis={
                    screenSize === ScreenSize.Desktop ? 'row' : 'column'
                  }
                  mainaxisAlignment={
                    screenSize === ScreenSize.Desktop
                      ? 'space-between'
                      : 'start'
                  }
                  crossaxisAlignment={
                    screenSize === ScreenSize.Desktop ? 'center' : 'start'
                  }
                  textAlignement="center"
                  className="par-school-timeline-page__filters-wrapper"
                >
                  <DropdownFilter
                    onchangeFilter={onChangeFilter}
                    filterValue={currentFilter}
                  />
                  <LastUpdatedInfo position="right" lastUpdated={lastUpdated} />
                </FlexLayout>
              )}
            </RenderWhen>
          </FlexLayout>
          <FlexLayout mainaxis="row" paddingBottom="24">
            <SchoolTimelineContainer profileId={profileId} />
          </FlexLayout>
        </FlexLayout>
      </Card>
    </PageLayout>
  );
};

export default SchoolTimelinePage;
