import * as React from 'react';
import {
  AvatarSize,
  FlexLayout,
  Label,
  TransparentButton,
} from 'styleguide-react';
import ProfileStatus from './ProfileStatus';
import type { ProfileListItemProps } from '../../containers/ProfileListItemContainer';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import Highlighted from '../SummaryAlerts/Highlighted/Highlighted';
import SummaryAlertsCounter from '../SummaryAlerts/SummaryAlertsCounter/SummaryAlertsCounter';

const ProfileListItem: React.FunctionComponent<ProfileListItemProps> = ({
  profile,
  onClick,
  hasTamperedDevice,
  showStatus,
  alertCounter,
}: ProfileListItemProps) => {
  const isHighlighted = alertCounter > 0;

  return (
    <Highlighted type="shadow" disabled={!isHighlighted}>
      <TransparentButton className="ProfileListItem" onClick={onClick}>
        <FlexLayout
          mainaxis="column"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          className="par-profile-list-item__container"
        >
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            crossaxisAlignment="center"
          >
            <ProfileAvatar
              size={AvatarSize.large}
              profile={profile}
              testId="profile"
            />
          </FlexLayout>
          <FlexLayout mainaxis="row" maxWidth="160">
            <Label testId="porfileListItemName" ellipsis>
              {profile.name}
            </Label>
          </FlexLayout>
          {showStatus && (
            <FlexLayout mainaxis="row" marginTop="16" marginBottom="16">
              <ProfileStatus
                status={profile.status}
                hasTamperedDevice={hasTamperedDevice}
              />
            </FlexLayout>
          )}
          {isHighlighted && <SummaryAlertsCounter count={alertCounter} />}
        </FlexLayout>
      </TransparentButton>
    </Highlighted>
  );
};

export default ProfileListItem;
