import {
  range,
  toString,
  inc,
  pipe,
  map,
  prepend,
  reverse,
  applyTo,
} from 'ramda';
import * as React from 'react';
import { DropdownOption } from 'styleguide-react';
import { AppDataArrangement } from '../../constants';

type DateRange = { value: string; year: string };

export const createDateRanges = (year): Array<DateRange> =>
  applyTo(
    year,
    pipe(
      year => reverse(range(year - 100, inc(year))),
      map(toString),
      map(year => ({ year, value: `${year}-01-01` })),
      prepend({
        year: AppDataArrangement.noBirthDayUI as string,
        value: AppDataArrangement.noBirthDayUI as string,
      })
    )
  );

export const birthDayDropdown = (): Array<JSX.Element> => {
  const years = createDateRanges(new Date().getFullYear());
  return years.map((value: DateRange) => {
    return (
      <DropdownOption text={value.year} key={value.year} value={value.value} />
    );
  });
};
