import * as React from 'react';
import { t } from '../../lib/i18n';
import { IconType } from 'styleguide-react';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';
import { ISOLanguage } from '../../constants';
import { PremiumFlyoverProps } from '../FullPremiumFlyover/types/FullPremiumFlyoverProps.types';

interface LocationRulesFlyoverProps {
  testId: string;
  accountLocale: ISOLanguage;
  actionButton: PremiumFlyoverProps['actionButton'];
  footNote?: PremiumFlyoverProps['footNote'];
  topbarText?: PremiumFlyoverProps['topbarText'];
  alertBoxProps?: PremiumFlyoverProps['alertBoxProps'];
  onClickClose: () => void;
}

const LocationRulesFlyover = ({
  testId,
  accountLocale,
  actionButton,
  footNote,
  topbarText,
  alertBoxProps,
  onClickClose,
}: LocationRulesFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Location') }}
      testId={testId}
      topbarText={topbarText}
      title={t('What can you do with location?')}
      imageSrc={`/illustrations/Location/premium_location__${accountLocale}.png`}
      actionButton={actionButton}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.eye,
          title: t('See where your child is'),
          subtitle: t('Check your child’s most recently recorded location'),
        },
        {
          iconType: IconType.listUl,
          title: t('View their location history'),
          subtitle: t('See a timeline showing where your child has been'),
        },
        {
          iconType: IconType.mapMarkedAlt,
          title: t('Check each address on the map'),
          subtitle: t(
            'Click any address your child visited to see where it is on the map'
          ),
        },
      ]}
      footNote={footNote}
      alertBoxProps={alertBoxProps}
    />
  );
};

export default LocationRulesFlyover;
