import { t } from '../../../lib/i18n';
import { SummaryDateRanges } from '../../../constants';

export const getNoActivityMsg = (
  range: SummaryDateRanges,
  isCombinedAndUndelegatedProfile: boolean,
  profileName: string
): string => {
  if (isCombinedAndUndelegatedProfile) {
    return t(
      '{{profileName}} hasn’t watched anything on YouTube on personal devices',
      {
        profileName,
      }
    );
  }
  return range === SummaryDateRanges.Today
    ? t('No video or search activity today')
    : t('No video or search activity for this period');
};

export const getNoSearchesMsg = (range: SummaryDateRanges): string =>
  range === SummaryDateRanges.Today
    ? t('No searches today')
    : t('No searches on this period');

export const getNoVideosWatchedMsg = (range: SummaryDateRanges): string =>
  range === SummaryDateRanges.Today
    ? t('No videos watched today')
    : t('No videos watched on this period');
