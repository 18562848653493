import * as React from 'react';

const CircleButton: React.FunctionComponent<{
  className: string;
  onClick?: () => any;
}> = ({ className = '', children, onClick = undefined }) => (
  <a className={`CircleButton ${className}`} onClick={onClick}>
    {children}
  </a>
);

export default CircleButton;
