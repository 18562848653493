import State from '../../store/state';

export const isFetchingInvitations = (state: State) => {
  const { isFetching, hasFetchedSuccessfullyAtleastOnce } =
    getInvitationsState(state);

  return !hasFetchedSuccessfullyAtleastOnce && isFetching;
};

export const isFetchingInvitationsFailed = (state: State) => {
  const { isFetchingFailed, hasFetchedSuccessfullyAtleastOnce } =
    getInvitationsState(state);

  return !hasFetchedSuccessfullyAtleastOnce && isFetchingFailed;
};

export const isCreatingInvitation = (state: State) =>
  getInvitationsState(state).isCreating;

export const isRemovingInvitation = (state: State) =>
  getInvitationsState(state).isRemoving;

export const getInvitation = (state: State) => {
  const { invitations } = getInvitationsState(state);

  const mostRecentAcceptedInvitation = invitations.find(
    invitation => invitation.status === 'accepted'
  );

  if (!invitations.length) {
    return undefined;
  }

  return mostRecentAcceptedInvitation || invitations[0];
};

export const getInvitationsState = (state: State) => state.get('invitations');
