import { FamilyLocatorScreen, RouteActions } from './types';

export const identifyScreen = ({
  profileId,
  action,
}: {
  profileId: string;
  action: string;
}) => {
  if (action === RouteActions.editPlace) {
    return FamilyLocatorScreen.EditPlace;
  }

  if (action === RouteActions.addPlace) {
    if (profileId === undefined) {
      return FamilyLocatorScreen.AddNewPlace;
    }
    return FamilyLocatorScreen.AddPlace;
  }

  if (!profileId) {
    return FamilyLocatorScreen.ProfilesList;
  }

  if (action === RouteActions.fullMap) {
    return FamilyLocatorScreen.MaximizedMap;
  }

  if (action === undefined && profileId !== undefined) {
    return FamilyLocatorScreen.KidTimeline;
  }

  throw new Error('No screen matches request params.');
};
