import * as qinit from '../qinit';
import { genderImageAssetFromPng, GenderImageMap } from './index';
import { MarkerAvatar } from '../records/marker';

import * as svg1 from '../assets/base/images/avatars/boy1.svg';
import * as svg2 from '../assets/base/images/avatars/boy2.svg';
import * as svg3 from '../assets/base/images/avatars/boy3.svg';
import * as svg4 from '../assets/base/images/avatars/boy4.svg';
import * as svg5 from '../assets/base/images/avatars/boy5.svg';
import * as svg6 from '../assets/base/images/avatars/boy6.svg';

export const svgAvatarsMap = {
  boy: {
    1: svg1,
    2: svg2,
    3: svg3,
    4: svg4,
    5: svg5,
    6: svg6,
  },
  girl: {
    1: svg1,
    2: svg2,
    3: svg3,
    4: svg4,
    5: svg5,
    6: svg6,
  },
};

const staticRootUrl = qinit.shared.common.s3.static.url;

const remotePng1 = `${staticRootUrl}/img/avatars/png/family-locator/pig.png`;
const remotePng2 = `${staticRootUrl}/img/avatars/png/family-locator/owl.png`;
const remotePng3 = `${staticRootUrl}/img/avatars/png/family-locator/elephant.png`;
const remotePng4 = `${staticRootUrl}/img/avatars/png/family-locator/hip.png`;
const remotePng5 = `${staticRootUrl}/img/avatars/png/family-locator/chicken.png`;
const remotePng6 = `${staticRootUrl}/img/avatars/png/family-locator/cat.png`;
const defaultPng = `${staticRootUrl}/img/avatars/png/family-locator/placeholder.png`;

export const remotePngAvatarsUrlMap: GenderImageMap = {
  boy: {
    1: remotePng1,
    2: remotePng2,
    3: remotePng3,
    4: remotePng4,
    5: remotePng5,
    6: remotePng6,
  },
  girl: {
    1: remotePng1,
    2: remotePng2,
    3: remotePng3,
    4: remotePng4,
    5: remotePng5,
    6: remotePng6,
  },
};

export const getRemoteIconUrl = (marker: MarkerAvatar) => {
  return genderImageAssetFromPng(
    remotePngAvatarsUrlMap,
    defaultPng
  )(marker.icon);
};
