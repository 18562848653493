import { connect } from 'react-redux';
import State from '../../store/state';
import { getActiveDateRangeNormalized } from '../../selectors';
import ScreenSummaryCard from '../../components/ActivityCards/ScreenSummaryCard';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

interface Props {
  profile?: ProfileRecord;
  height?: string;
  width?: string;
}

const mapStateToProps = (
  state: State,
  { profile, height, width = '100%' }: Props
) => {
  const activeDateRange = getActiveDateRangeNormalized(state);
  return {
    profile,
    height,
    width,
    activeDateRange,
  };
};

const ScreenSummaryCardContainer = connect(
  mapStateToProps,
  null
)(ScreenSummaryCard);

export default ScreenSummaryCardContainer;

export type ScreenSummaryCardProps = ReturnType<typeof mapStateToProps>;
