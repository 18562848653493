import * as React from 'react';
import { t } from '../../lib/i18n';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';
import { IconType } from 'styleguide-react';
import type { FamilyLocatorFlyoverProps } from '../../containers/FamilyLocator/FamilyLocatorFlyoverContainer';

const PREMIUM_FLYOVER_TEST_ID = 'family-locator-rules-premium-flyover';

const FamilyLocatorFlyover = ({
  canPurchase,
  accountLocale,
  onClickClose,
  onClickUpgrade,
}: FamilyLocatorFlyoverProps) => {
  const actionButtonTitle = canPurchase ? t('Upgrade') : t('Ok');
  const actionButtonFn = canPurchase ? onClickUpgrade : onClickClose;

  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Family Locator') }}
      testId={PREMIUM_FLYOVER_TEST_ID}
      topbarText={t('Upgrade your plan to enable the family locator!')}
      title={t('What can you do with the family locator?')}
      imageSrc={`/illustrations/FamilyLocator/premium_family_locator__${accountLocale}.png`}
      actionButton={{
        title: actionButtonTitle,
        onClick: actionButtonFn,
        testId: `${PREMIUM_FLYOVER_TEST_ID}-upgrade`,
      }}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.locationPlus,
          title: t('Add saved places'),
          subtitle: t(
            'Create a list of your child’s most frequently visited places'
          ),
        },
        {
          iconType: IconType.bell,
          title: t('Receive alerts'),
          subtitle: t(
            'Receive notifications when they leave or arrive at saved places'
          ),
        },
        {
          iconType: IconType.mapMarkedAlt,
          title: t('Check your family’s locations'),
          subtitle: t('See where everybody is on one map'),
        },
      ]}
      f
    />
  );
};

export default FamilyLocatorFlyover;
