import * as moment from 'moment';
import { API_BIRTHDATE_FORMAT } from '../../../helpers/dates';
import { TimeSlot } from '../../../components/Routines/routines.types';
import {
  ScheduleCreatePayload,
  ScheduleRecord,
} from '../../../records/routines/schedule/types/Schedule.types';
import { calculateDurationAsMinutes } from '../../../businessLogic/routines/routines';

export const scheduleContextToPayload = (
  slot: TimeSlot
): ScheduleCreatePayload => {
  return {
    overrides: false,
    weekdays: slot.days,
    start_time: slot.from,
    duration_minutes: calculateDurationAsMinutes(slot.from, slot.to),
    from_date: moment().format(API_BIRTHDATE_FORMAT),
    to_date: undefined,
  };
};

export const scheduleRecordToPayload = (
  schedule: ScheduleRecord
): ScheduleCreatePayload => {
  return {
    overrides: schedule.overrides,
    weekdays: schedule.weekdays,
    start_time: schedule.startTime,
    duration_minutes: schedule.durationMinutes,
    from_date: schedule.fromDate,
    to_date: schedule.toDate,
  };
};
