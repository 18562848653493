import * as Moment from 'moment-timezone';
import * as parser from 'ua-parser-js';
import { getFormValues as reduxGetFormVaules } from 'redux-form/immutable';
import {
  replace as reduxReplace,
  push as reduxPush,
  goBack as reduxGoBack,
  go as reduxGo,
  LOCATION_CHANGE as REDUX_LOCATION_CHANGE,
  RouterProps as ReduxRouterProps,
  RouterAction as ReduxRouterAction,
  syncHistoryWithStore as ReduxSyncHistoryWithStore,
  routerMiddleware as ReduxRouterMiddleware,
} from 'react-router-redux';

export const newDate = (): Moment.Moment => Moment(new Date());
export const replace = path => reduxReplace(path);
export const push = path => reduxPush(path);
export const goBack = () => reduxGoBack();
export const go = step => reduxGo(step);
export const LOCATION_CHANGE = REDUX_LOCATION_CHANGE;
export type RouterProps = ReduxRouterProps;
export type RouterAction = ReduxRouterAction;
export const syncHistoryWithStore = ReduxSyncHistoryWithStore;
export const routerMiddleware = ReduxRouterMiddleware;

export const googlePlacesAutocompleteServiceInstance = () =>
  new global.google.maps.places.AutocompleteService();

export const googlePlacesPlacesServiceInstance = element =>
  new global.google.maps.places.PlacesService(element);

export const getFormValues = formName => reduxGetFormVaules(formName);

export const parseUserAgent = (userAgent: string) => new parser(userAgent);

export const getUserAgent = () => window.navigator.userAgent;
