import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { getLicense, getAccount, isPurchaseFlow } from '../../selectors';
import * as qinit from '../../qinit';
import {
  LicenseType,
  shouldShowConversionElements,
} from '../../records/license';
import { LINK_FOOTER, ModalLink } from '../../constants';
import AtLimitModal from '../../components/Modal/AtLimitModal';
import State from '../../store/state';
import { gaEvent } from '../../helpers/index';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = (state: State) => {
  return {
    license: getLicense(state),
  };
};

const onClickLink = (link: ModalLink) => (dispatch, getState) => {
  const state = getState();
  const license = getLicense(state);
  const navigate = getMultiPlatformNavigation();
  if (shouldShowConversionElements(license)) {
    if (license.type === LicenseType.Premium) {
      dispatch(goBackIfHistory());
      dispatch(
        navigate({
          type: 'external:public:site',
          src: qinit.tenant.marketing.public_site.help_contact,
          openInIOS: false,
        })
      );
    } else {
      gaEvent(
        isPurchaseFlow(state)
          ? 'kidsapp-par-limit-flyover'
          : 'parent-par-limit-flyover',
        'upgrade-premium-btn',
        getAccount(state).id
      );
      if (link === LINK_FOOTER) {
        dispatch(
          navigate({
            type: 'inner:replace',
            src: '/upgrade',
          })
        );
      } else {
        dispatch(navigate({ type: 'inner', src: '/upgrade/premium-features' }));
      }
    }
  } else {
    dispatch(goBackIfHistory());
  }
};

const mapDispatchToProps = dispatch => ({
  onClickLink: link => dispatch(onClickLink(link)),
  onClickClose: () => dispatch(goBackIfHistory()),
});

const AtLimitModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AtLimitModal);

export default AtLimitModalContainer;
