import * as React from 'react';
import { Layout, Icon, IconType, AlertBox } from 'styleguide-react';
import { t } from '../../../lib/i18n';

import type { TamperingTopBarProps } from './types';

const getDevicesNames = (names: string[]) => ({
  deviceName: names[0],
  deviceNamesList: names.slice(0, names.length - 1).join(', '),
  lastDeviceName: names[names.length - 1],
});

const getTamperingCopy = (deviceNames: string[]) => {
  if (deviceNames.length === 1) {
    return t('Tampering detected - remove {{deviceName}} and reinstall', {
      ...getDevicesNames(deviceNames),
    });
  }

  return t(
    'Tampering detected - remove {{deviceNamesList}} and {{lastDeviceName}} and reinstall',
    {
      ...getDevicesNames(deviceNames),
    }
  );
};

export const TamperingTopBar: React.FC<TamperingTopBarProps> = (
  props: TamperingTopBarProps
) => {
  const { tamperedDevices } = props;
  const tamperedDeviceNames = tamperedDevices.map(device => device.name);
  return (
    <Layout width="100%" marginBottom="24">
      <AlertBox
        showInfoIcon
        testId="topbar-tampering"
        fullWidth
        type="error"
        icon={<Icon type={IconType.exclamationCircle} />}
        rounded
      >
        <span>
          {tamperedDeviceNames.length
            ? getTamperingCopy(tamperedDeviceNames)
            : null}
        </span>
      </AlertBox>
    </Layout>
  );
};
