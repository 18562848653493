import { Record } from 'immutable';
import * as R from 'ramda';
import intl from '../intl';
import { ISOLanguage } from '../constants';

type TokenKey = {
  initVector: string;
  key: string;
};
type TokenKeyRecord = Record<TokenKey>;
const TokenKeyRecord = Record<TokenKey, {}>({
  initVector: '',
  key: '',
});

export enum SchoolLinkedStatus {
  linked = 'linked',
  unlinked = 'unlinked',
  pendingStudentsValidation = 'pending_students_validation',
  noLinkedStudents = 'no_linked_students',
  pendingExtraStudentsValidation = 'pending_extra_students_validation',
}

export type Account = {
  id: number | undefined;
  uid: string;
  name: string;
  email: string;
  surname: string;
  fullname: string;
  locale: ISOLanguage;
  timezone: string;
  devicesCount: number;
  profilesCount: number;
  country: string;
  isDailyReport: boolean;
  isWeeklyReport: boolean;
  tokenKey: TokenKeyRecord;
  created: string;
  masterAccountId: number | null;
  masterAccountUid: string | null;
  schoolLinkedStatus: SchoolLinkedStatus | null;
  telephone: string;
  routines: boolean;
};
export type AccountRecord = Record<Account>;
export const AccountRecord = Record<
  Account,
  {
    fromPayload: Record.Parser<any, AccountRecord>;
    serialize: Record.Serializer<AccountRecord, any>;
  }
>({
  id: 0,
  uid: '',
  name: '',
  email: '',
  surname: '',
  fullname: '',
  locale: ISOLanguage.notSet,
  timezone: '',
  devicesCount: 0,
  profilesCount: 0,
  country: '',
  isDailyReport: false,
  isWeeklyReport: false,
  tokenKey: TokenKeyRecord() as TokenKeyRecord,
  created: '',
  masterAccountId: null,
  masterAccountUid: null,
  schoolLinkedStatus: null,
  telephone: '',
  routines: false,
});

AccountRecord.fromPayload = payload => {
  const fullname = `${payload.name} ${payload.surname}`;
  return AccountRecord({
    ...payload,
    fullname,
    tokenKey: TokenKeyRecord(payload.tokenKey),
  }) as AccountRecord;
};

AccountRecord.serialize = (record): Account => {
  const {
    name,
    surname,
    email,
    locale,
    fullname,
    profilesCount,
    timezone,
    uid,
    country,
    isDailyReport,
    isWeeklyReport,
    created,
    devicesCount,
    tokenKey,
    masterAccountId,
    masterAccountUid,
    schoolLinkedStatus,
    telephone,
    routines,
  } = record;
  const id = typeof record.id === 'number' ? record.id : undefined;

  return {
    id,
    uid,
    name,
    email,
    surname,
    fullname,
    locale,
    timezone,
    devicesCount,
    profilesCount,
    country,
    isDailyReport,
    isWeeklyReport,
    tokenKey,
    created,
    masterAccountId,
    masterAccountUid,
    schoolLinkedStatus,
    telephone,
    routines,
  };
};

export function formatCurrency(locale, currency, amount) {
  switch (currency) {
    case 'USD':
      return `$${amount.toFixed(2)}`;
    case 'AUD':
      return `AU$${amount.toFixed(2)}`;
    case 'EUR':
      return `${amount.toFixed(2).toString().replace('.', ',')}€`;
    case 'GBP':
      return `£${amount.toFixed(2)}`;
    default:
      return new intl.NumberFormat(
        R.replace('_', '-', locale), // PAR-1333
        { style: 'currency', currency }
      ).format(amount);
  }
}

export const isSlaveAccount = (account: AccountRecord) =>
  !!account.masterAccountId;

export const accountHasProtectedDevices = (account: AccountRecord) =>
  account.devicesCount > 0;

export default AccountRecord;
