import { ScreenSize } from '../../RenderWhen/RenderWhen';
import type { PageSectionLayoutProps } from '../PageSectionLayout';
import { BaseConfigurationProps } from '../types';
import type { PageLayoutConfigurationProps } from '../PageLayout';
import type { PageTitleActionElementProps } from '../PageTitleLayout';

const availableConfig = {
  [ScreenSize.Desktop]: [
    ScreenSize.Desktop,
    ScreenSize.TabletOrDesktop,
    ScreenSize.All,
  ],
  [ScreenSize.Mobile]: [
    ScreenSize.Mobile,
    ScreenSize.MobileOrTablet,
    ScreenSize.All,
  ],
  [ScreenSize.Tablet]: [
    ScreenSize.Tablet,
    ScreenSize.TabletOrDesktop,
    ScreenSize.MobileOrTablet,
    ScreenSize.All,
  ],
};

type FilterFunctionType = <TValue extends BaseConfigurationProps>(
  configurations: TValue[],
  target: ScreenSize
) => TValue[] | undefined;

export const filterConfigurations: FilterFunctionType = <
  TValue extends BaseConfigurationProps
>(
  configurations: TValue[] = [],
  target: ScreenSize
) =>
  configurations?.filter(configuration =>
    availableConfig[target].some(
      avalConfig => avalConfig === configuration.screenSize
    )
  );

const mergeConfigurations = <T extends PageLayoutConfigurationProps>(
  configurations: PageLayoutConfigurationProps[] = []
): T => Object.assign({}, ...(configurations ?? {})) as T;

export const filterActions = (
  actions: PageTitleActionElementProps[] = [],
  target: ScreenSize
): PageTitleActionElementProps[] | undefined =>
  actions?.filter(configuration =>
    availableConfig[target].some(
      avalConfig =>
        avalConfig === configuration.screenSize || !configuration.screenSize
    )
  );

export const filterAndMergeConfigurations = <T extends BaseConfigurationProps>(
  configurations: T[] = [],
  target: ScreenSize
): T => mergeConfigurations(filterConfigurations(configurations, target)) as T;

export const filterPageSections = <
  T extends React.ReactNode | React.ReactElement<PageSectionLayoutProps>
>(
  childrenArray: (React.ReactChild | React.ReactFragment | React.ReactPortal)[],
  conditions: (child: React.ReactElement<PageSectionLayoutProps>) => boolean
): T =>
  childrenArray.filter(child => {
    const pageSection = child as React.ReactElement<PageSectionLayoutProps>;
    if (pageSection === null) {
      return false;
    }
    return conditions(pageSection);
  }) as T;

export const findPageSection = (
  childrenArray: (React.ReactChild | React.ReactFragment | React.ReactPortal)[],
  conditions: (chid: React.ReactElement<PageSectionLayoutProps>) => boolean
): React.ReactElement<PageSectionLayoutProps> | undefined =>
  childrenArray.find(child => {
    const pageSection = child as React.ReactElement<PageSectionLayoutProps>;
    if (pageSection === null) {
      return false;
    }
    return conditions(pageSection);
  }) as React.ReactElement<PageSectionLayoutProps>;
