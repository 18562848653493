import * as Moment from 'moment';
import { NullableMap } from 'immutable';
import {
  pipe,
  groupBy,
  map,
  groupWith,
  toPairs,
  reduce,
  sort,
  evolve,
  applyTo,
} from 'ramda';

import { API_TIMESTAMP_FORMAT, convertDate } from '../../helpers/dates';
import { LinewizeEventRecord } from '../../records/linewizeEvent/types/linewizeEvents';
import type * as T from './LinewizeEvent.types';
import { computeLinewizeEventActions } from './LinewizeEventActions';
import { extractDomain } from '../../helpers/string';

export const linewizeEventsRecordToArray = (
  events: NullableMap<string, LinewizeEventRecord>
) => events.toArray();

export const orderEventsByDateDesc = sort<LinewizeEventRecord>((a, b) =>
  Moment(b.dt).diff(Moment(a.dt))
);

const orderByKeys = keyOrderedList =>
  sort<LinewizeEventRecord>((a, b) => {
    const indexA = keyOrderedList.indexOf(a.key);
    const indexB = keyOrderedList.indexOf(b.key);

    return indexA - indexB;
  });

export const getEventsLinewizeOrdered = pipe(
  linewizeEventsRecordToArray,
  orderEventsByDateDesc
);

/**
 * Order the Linewize events by keys using an array
 * as template of the desired result,
 *
 */
export const getLinewizeEventsOrdered = (events, orderKeys) => {
  return applyTo(
    events,
    pipe(linewizeEventsRecordToArray, orderByKeys(orderKeys))
  );
};

const dateYYYMMDD = (event: LinewizeEventRecord) =>
  Moment(event.dt).format('YYYY-MM-DD');

const hoursType = (event: LinewizeEventRecord): T.SchoolHours =>
  event.atSchoolHours ? 'IN_SCHOOL_HOURS' : 'OUT_SCHOOL_HOURS';

const isEqualsHours = (a: LinewizeEventRecord, b: LinewizeEventRecord) =>
  a.atSchoolHours === b.atSchoolHours;

const formatDtToTimeZone = (timeZone: string) => (event: LinewizeEventRecord) =>
  event.set(
    'dt',
    convertDate(event.get('dt'), timeZone).format(API_TIMESTAMP_FORMAT)
  );

/**
 * Group by Linewize events by first level date and second level by school time adjacent.
 *
 * @example
 * ```ts
 * groupLinewizeEventsByDateAndType(linewizeEvents);
 *
 * =>
 * [{
 *  date: "2023-01-02",
 *  events: [{
 *    type: "OUT_SCHOOL_OURS",
 *    events: [linewizeEventsRecord]
 *  }, ...]
 * }, ...]
 *
 * ```
 */
export const groupLinewizeEventsByDateAndType = (
  events: LinewizeEventRecord[],
  timeZone: string
) => {
  return applyTo(
    events,
    pipe(
      map<LinewizeEventRecord, LinewizeEventRecord>(
        formatDtToTimeZone(timeZone)
      ),
      groupBy(dateYYYMMDD),
      map<
        T.LinewizeEventsGroupedByDay,
        T.LinewizeEventsGroupedByDaySplittedByHours
      >(groupWith(isEqualsHours)),
      map<
        T.LinewizeEventsGroupedByDaySplittedByHours,
        T.LinewizeEventsGroupedByDayRecordByHours
      >(
        map<
          LinewizeEventRecord[],
          { type: T.SchoolHours; events: LinewizeEventRecord[] }
        >(events => ({
          type: hoursType(events[0] as LinewizeEventRecord),
          events,
        }))
      ),
      toPairs,
      reduce(
        (acc: T.GroupLinewizeEventsByDateAndType, [date, events]) => [
          ...acc,
          { date, events },
        ],
        []
      ),
      map(
        evolve({
          events: map(
            evolve({
              events: map(computeLinewizeEventActions),
            })
          ),
        })
      )
    )
  );
};

export const groupUrlsByHost = (urls: string[]) => {
  return applyTo(
    urls,
    pipe(
      map((url: string) => ({ title: url, url })),
      groupBy(({ url }) => extractDomain(url))
    )
  );
};
