import { connect } from 'react-redux';
import DashboardWidgetSchool from '../../../components/Dashboard/DashboardWidgets/DashboardWidgetSchool';

import State from '../../../store/state';

interface ProfileDashboardWidgetsSchoolContainerExtraProps {
  profileId: number;
}
const mapStateToProps = (
  _state: State,
  { profileId }: ProfileDashboardWidgetsSchoolContainerExtraProps
) => {
  return {
    profileId,
  };
};

const mapDispatchToProps = () => ({});

const ProfileDashboardWidgetsSchoolContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardWidgetSchool);

export type DashboardWidgetsSchoolContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default ProfileDashboardWidgetsSchoolContainer;
