import * as React from 'react';
import { t } from '../../../lib/i18n';
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  FlexLayout,
  Icon,
  IconColor,
  IconFamily,
  IconSize,
  IconType,
  Layout,
  StyledHeader,
  Svg,
  TabPanel,
  Tabs,
  TransparentButton,
  Typography,
} from 'styleguide-react';
import InfoIcon from '../../Icons/InfoIcon';
import type { ActiveViewTab, RoutinesPageProps } from './RoutinesPage';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

export const renderErrorState = () => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      matchParentHeight
    >
      <Svg height="64" width="64">
        <InfoIcon />
      </Svg>
      <FlexLayout
        maxWidth="520"
        marginTop="16"
        marginBottom="48"
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        textAlignement="center"
      >
        <FlexLayout mainaxis="column" marginBottom="16" textAlignement="center">
          <StyledHeader type="h5">
            <strong>{t('Something went wrong')}</strong>
          </StyledHeader>
        </FlexLayout>
        <p>{t('Please refresh the page or try again later.')}</p>
      </FlexLayout>
    </FlexLayout>
  );
};

type RenderRoutinesTopMenuProps = Pick<RoutinesPageProps, 'onAddNewRoutine'> & {
  testId: string;
  activeViewTab: ActiveViewTab;
  onChangeActiveViewTab: (tab: ActiveViewTab) => void;
};

export const renderRoutinesTopMenu = ({
  testId,
  activeViewTab,
  onAddNewRoutine,
  onChangeActiveViewTab,
}: RenderRoutinesTopMenuProps) => (
  <FlexLayout mainaxis="row" mainaxisAlignment="space-between">
    <Layout>{FilterTabs(activeViewTab, onChangeActiveViewTab, testId)}</Layout>
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Mobile, ScreenSize.Tablet]}
    >
      {screenSize => (
        <Button
          testId={`${testId}-add-new-routine`}
          size={
            screenSize === ScreenSize.Mobile
              ? ButtonSize.small
              : ButtonSize.medium
          }
          onClick={onAddNewRoutine}
          iconPosition={ButtonIconPosition.right}
          icon={<Icon type={IconType.plus} size={IconSize.regular} />}
        >
          {t('Add routine')}
        </Button>
      )}
    </RenderWhen>
  </FlexLayout>
);

export const renderPageSubtitle = (testId: string, onClick: () => void) => (
  <Typography type="body1">
    {t('Schedule custom rules for specific moments of the day.')}
    <TransparentButton onClick={onClick} testId={`${testId}-info-icon`}>
      <Layout marginLeft="8">
        <Icon
          type={IconType.questionCircle}
          size={IconSize.sm}
          family={IconFamily.regular}
          color={IconColor.secondary}
        />
      </Layout>
    </TransparentButton>
  </Typography>
);

export const FilterTabs = (
  activeTab: ActiveViewTab,
  onChange: (tab: ActiveViewTab) => void,
  testId: string
) => {
  return (
    <Tabs
      testId={`${testId}-view-tabs`}
      showSeparator={false}
      hugContent
      onChange={onChange}
    >
      <TabPanel
        name="list"
        content={<Icon type={IconType.listUl} />}
        active={activeTab === 'list'}
      >
        <React.Fragment />
      </TabPanel>
      <TabPanel
        name="calendar"
        content={<Icon type={IconType.calendarAlt} />}
        active={activeTab === 'calendar'}
      >
        <React.Fragment />
      </TabPanel>
    </Tabs>
  );
};

export const renderRoutinesPageSubtitle = (
  subtitle: JSX.Element | undefined,
  isRoutinesListEmpty: boolean
) => {
  if (!subtitle) return null;

  const renderContent = (screenSize: ScreenSize) => {
    if (![ScreenSize.Tablet, ScreenSize.Mobile].includes(screenSize))
      return null;

    const isMobileAndHasRoutines =
      isRoutinesListEmpty && screenSize === ScreenSize.Mobile;

    return (
      <Layout marginBottom={!isMobileAndHasRoutines ? '32' : '0'}>
        {subtitle}
      </Layout>
    );
  };

  return (
    <RenderWhen screenSize={[ScreenSize.Tablet, ScreenSize.Mobile]}>
      {screenSize => renderContent(screenSize)}
    </RenderWhen>
  );
};
