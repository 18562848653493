import * as React from 'react';
import { FlexLayout, Layout } from 'styleguide-react';
import MenuOptionsContainer from '../../../containers/MenuOptionsContainer/MenuOptionsContainer';
import SideBarContainer from '../../../containers/SideBarContainer';
import { classNames } from '../../../helpers';
import ConnectedAccountDropdown from '../../DropDown/AccountDropdown';
import connectToLayout from '../connectToLayout';
import { LayoutConfigurationProps } from '../types';

const DesktopLayout: React.FunctionComponent<{
  children: React.ReactNode;
  layoutHasProfile: boolean;
  configuration?: LayoutConfigurationProps;
}> = ({ children, layoutHasProfile, configuration }) => (
  <FlexLayout
    mainaxis="column"
    minHeight="0"
    testId="DesktopLayout_Container"
    backgroundColor="gray-ligther"
    className="DesktopLayout_Container"
  >
    {!configuration?.hideSideBar && (
      <FlexLayout
        mainaxis="column"
        height="100vh"
        width="104"
        position="absolute"
        left={0}
        top={0}
        testId="DesktopLayout_SideBar_Container"
      >
        <SideBarContainer alwaysVisible />
      </FlexLayout>
    )}

    {!configuration?.hideMenuOptions && (
      <FlexLayout
        mainaxis="column"
        position="absolute"
        height="100vh"
        top={0}
        className="DesktopLayout__menu-container"
        testId="DesktopLayout_MenuOptions_Container"
      >
        <MenuOptionsContainer deviceType="desktop" />
      </FlexLayout>
    )}

    <FlexLayout
      mainaxis="column"
      className={classNames(
        'DesktopLayout__page-container',
        layoutHasProfile && !configuration?.hideMenuOptions
          ? ''
          : 'DesktopLayout__page-container--menu-hidden',
        configuration?.hideSideBar && configuration?.hideMenuOptions
          ? 'DesktopLayout__page-container--all-menu-hidden'
          : ''
      )}
      testId="DesktopLayout_Content_Container"
    >
      {children}
    </FlexLayout>
    <Layout
      position="fixed"
      right={100}
      top={0}
      testId="DesktopLayout_AccountDropdown_Container"
    >
      <ConnectedAccountDropdown />
    </Layout>
  </FlexLayout>
);

export default connectToLayout(DesktopLayout);
