/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import * as R from 'ramda';
import { pad } from '../../helpers/string';
import { classNames } from '../../helpers';
import { t } from '../../lib/i18n';
import useSlider from './useSlider';

const haveValue = (time: number): boolean =>
  !Number.isNaN(time) && time !== undefined;

const twoDigits = R.pipe(Math.round, R.partial(R.flip(pad), [2]));

const splitTime = (value: number) => {
  const parsed = parseFloat(String(value)).toFixed(2);
  return parsed.split('.').map(time => parseInt(time, 10));
};

const parseTime = (value: number): string => {
  const [hours, minutes] = splitTime(value);
  const formattedHours = haveValue(hours) ? twoDigits(hours) : '24';
  const formattedMinutes = haveValue(minutes) ? twoDigits(0.6 * minutes) : '00';
  return `${formattedHours}:${formattedMinutes}`;
};

const getMinutes = (value: number): string => {
  const parsedTime = parseTime(value);
  return parsedTime.split(':')[1];
};

const getHours = (value: number) => {
  const parsedTime = parseTime(value);
  return parsedTime.split(':')[0];
};

type HourLimitSliderProps = {
  value: number;
  step?: number;
  label?: string;
  dayLabel: string;
  disabled?: boolean;
  onChange: (n: number) => void;
};

const HourLimitSlider: React.FC<HourLimitSliderProps> = ({
  value,
  step = 15,
  disabled,
  onChange,
  label,
  dayLabel,
}: HourLimitSliderProps) => {
  const { sliderRef, valueState } = useSlider({
    value,
    disabled,
    step,
    onChange,
  });

  return (
    <div className="HourLimitSlider__Container">
      <div
        className={classNames(
          'HourLimitSlider__TooltipWrapper',
          valueState === 0 ? 'HourLimitSlider__Control--zero' : ''
        )}
      >
        <div className="HourLimitSlider__Tooltip">
          <div className="HourLimitSlider__Tooltip__label">
            {label || t('Daily limit')}
          </div>
          <div className="HourLimitSlider__Tooltip__time">
            <div className="HourLimitSlider__Tooltip__time__container">
              <div className="HourLimitSlider__Tooltip__time--time">
                {getHours(valueState)}
              </div>

              <div className="HourLimitSlider__Tooltip__time--title">
                {t('Hours')}
              </div>
            </div>
            <div className="HourLimitSlider__Tooltip__time__container">
              <div className="HourLimitSlider__Tooltip__time--time HourLimitSlider__Tooltip__time--separator">
                :
              </div>
            </div>
            <div className="HourLimitSlider__Tooltip__time__container">
              <div className="HourLimitSlider__Tooltip__time--time">
                {getMinutes(valueState)}
              </div>

              <div className="HourLimitSlider__Tooltip__time--title">
                {t('Mins')}
              </div>
            </div>
          </div>

          <div className="HourLimitSlider__Tooltip__DayLabel">{dayLabel}</div>
        </div>
        <div ref={sliderRef} />
      </div>
    </div>
  );
};

export default HourLimitSlider;
