import * as React from 'react';
import {
  FlexLayout,
  FreeText,
  Icon,
  IconType,
  TransparentButton,
} from 'styleguide-react';

interface SchoolMiniActivityProps {
  title: string;
  subTitle: string;
  onClick: (ev: React.SyntheticEvent) => void;
}
const SchoolMiniActivity = ({
  title,
  subTitle,
  onClick,
}: SchoolMiniActivityProps) => {
  return (
    <TransparentButton
      onClick={onClick}
      testId="school-mini-activity-action-button"
    >
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        className="par-school-mini-activity-summary__summary-activity-item"
      >
        <FlexLayout mainaxis="column" textAlignement="left">
          <FreeText fontSize="16px" fontWeight="semi-bold">
            {title}
          </FreeText>
          <FreeText fontSize="16px">{subTitle}</FreeText>
        </FlexLayout>
        <Icon
          type={IconType.chevronRight}
          className="par-school-mini-activity-summary__summary-activity-action"
        />
      </FlexLayout>
    </TransparentButton>
  );
};

export default SchoolMiniActivity;
