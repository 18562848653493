import { combineEpics } from 'redux-observable';
import appDailyTimeLimitEpic from './router/appDailyTimeLimit';
import { SummaryBlockedWebActivityEpic } from './summaryBlockedWebActivity';
import { SummaryAppsAndWebActivityEpic } from './summaryAppsAndWebActivity';
import { SummarySearchActivityEpic } from './summarySearchActivity';
import { SummaryCallAndSmsActivityEpic } from './summaryCallAndSmsActivity';
import { SummaryYoutubeActivityEpic } from './summaryYoutubeActivity';
import { SummaryStudentTotalUsageEpic } from './summaryStudentTotalUsageActivity';
import { SummaryStudentCategoriesEpic } from './summaryStudentCategoriesActivity';
import { SummaryStudentAppsAndWebsEpic } from './summaryStudentAppsAndWebsActivity';
import { summaryScreenTimeActivityEpic } from './summaryScreenTimeActivity';

const dataLoaderEpics = combineEpics(
  appDailyTimeLimitEpic,
  SummaryBlockedWebActivityEpic,
  SummaryAppsAndWebActivityEpic,
  SummarySearchActivityEpic,
  SummaryCallAndSmsActivityEpic,
  SummaryYoutubeActivityEpic,
  SummaryStudentTotalUsageEpic,
  SummaryStudentCategoriesEpic,
  SummaryStudentAppsAndWebsEpic,
  summaryScreenTimeActivityEpic
);

export default dataLoaderEpics;
