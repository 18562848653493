import * as React from 'react';
import { connect } from 'react-redux';
import { Icon, IconType, AlertBox, Layout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { DeviceRecord } from '../../records/device';
import State, { Dispatch } from '../../store/state';
import { getKidTamperedDevices, getProfileId } from '../../selectors';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const TamperedDeviceBannerList: React.FunctionComponent<{
  tamperedDevices: DeviceRecord[];
  className?: string;
  rounded?: boolean;
  onClickDeviceName: (id: number) => void;
}> = ({ tamperedDevices, onClickDeviceName, rounded }) => {
  const hasTamperedDevices = tamperedDevices.length > 0;
  const onlyTwoTamperedDevices = tamperedDevices.slice(0, 2);
  if (!hasTamperedDevices) {
    return null;
  }

  return (
    <Layout width="100%">
      {onlyTwoTamperedDevices.map(({ id, name }) => (
        <TamperedDeviceBanner
          key={id}
          deviceName={name}
          onClickDeviceName={onClickDeviceName}
          id={id}
          rounded={rounded}
        />
      ))}
    </Layout>
  );
};

class TamperedDeviceBanner extends React.Component<
  {
    id: number;
    deviceName: string;
    rounded?: boolean;
    onClickDeviceName: (id: number) => any;
  },
  { isActive: boolean }
> {
  state = { isActive: true };

  handleHide = () => {
    this.setState({ isActive: false });
  };

  render() {
    const { isActive } = this.state;
    const { id, deviceName, onClickDeviceName, rounded } = this.props;
    return isActive ? (
      <AlertBox
        fullWidth
        type="error"
        icon={<Icon type={IconType.exclamationCircle} />}
        onClose={this.handleHide}
        showCloseIcon
        rounded={rounded}
      >
        <strong>{t('Tampering detected')}</strong>
        <br />
        <a
          className="TamperedDeviceBannerList__link"
          href={`#/devices/${id}`}
          onClick={evt => {
            evt.preventDefault();
            onClickDeviceName(id);
          }}
        >
          {t('Remove {{deviceName}}', { deviceName })}
        </a>
        <span>{` ${t('and reinstall')}`}</span>
      </AlertBox>
    ) : null;
  }
}

export default connect(
  (state: State, { ...props }) => ({
    tamperedDevices: getKidTamperedDevices(state, getProfileId(state)),
    ...props,
  }),
  (dispatch: Dispatch) => ({
    onClickDeviceName: id => {
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'inner',
          src: `/devices/${id}`,
        })
      );
    },
  })
)(TamperedDeviceBannerList);
