import { all, decision } from '../decision';
import { UnifiedNowCardState, UnifiedNowCardStateContext } from './types';
import { Decision } from '../decision/types';
import {
  whereProfileHasAllDevicesInSafeNetworks,
  whereProfileHasAllDevicesTampered,
  whereProfileHasAnActiveRoutine,
  whereProfileHasAnActiveRoutineNoCountingToTimeLimit,
  whereProfileHasAtLeastOneDeviceInSafeNetworks,
  whereProfileHasDeviceRestrictedTimeAndLocked,
  whereProfileHasDevicesPaused,
  whereProfileHasExtraTime,
  whereProfileHasOnlyOneDevice,
  whereProfileHasOnlyOneTamperedDevice,
  whereProfileHasOnlySchoolDevice,
  whereProfileHasReachedTimeLimitWithLockSettings,
  whereProfileHasReachedTimeLimitWithOnlyAlert,
  whereProfileIsRunningOverTimeLimit,
  whereSchoolDeviceIsDelegated,
  whereTheSchoolDeviceIsInsideSchool,
  whereTheSchoolDeviceIsOutsideSchoolOr247,
  whereTheSchoolDeviceIsPaused,
  whereTheSchoolDevicePausedIsEnabled,
  whereProfileHasAllDevicesDisabled,
  whereProfileHasAllDevicesOffline,
  whereProfileHasAnActiveRoutineBlockType,
  whereAllDevicesOfflineLongPeriod,
  whereProfileHasDeviceRestrictedTimesAndAlert,
  whereSchoolDeviceIsDelegatedToThisAccount,
  whereSchoolDeviceHasEnabledPause247,
} from './conditions';
import { always, complement, ifElse } from 'ramda';

export const unifiedNowCardDecision = (
  handler: Decision<UnifiedNowCardStateContext, UnifiedNowCardState>
) => decision<UnifiedNowCardStateContext, UnifiedNowCardState>(handler);

// decisions

const calculateUnifiedNowCardTampered = unifiedNowCardDecision({
  meta: {
    name: 'Tampered decision',
    description: 'check if the profile has one o multiple tampered devices',
  },
  cond: whereProfileHasOnlyOneTamperedDevice,
  yes: () => 'TAMPERED_SINGLE_DEVICE',
  no: () => 'TAMPERED_MULTIPLE_DEVICES',
});

const calculateUnifiedNowCardSafeNetworkHasExtraTime = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasExtraTime,
  yes: () => 'ACTIVE_SAFE_NETWORK_TIME_LIMIT_EXTRA',
  no: () => 'ACTIVE_SAFE_NETWORK_TIME_LIMIT',
});

const calculateUnifiedNowCardActiveTimeLimit = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasExtraTime,
  yes: () => 'ACTIVE_TIME_LIMIT_EXTRA',
  no: () => 'ACTIVE_TIME_LIMIT',
});

const calculateUnifiedNowCardActiveSafeNetwork = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileIsRunningOverTimeLimit,
  yes: calculateUnifiedNowCardSafeNetworkHasExtraTime,
  no: () => 'ACTIVE_SAFE_NETWORK',
});

const calculateUnifiedNowCardActiveSingleMultiple = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileIsRunningOverTimeLimit,
  yes: calculateUnifiedNowCardActiveTimeLimit,
  no: unifiedNowCardDecision({
    meta: { name: '', description: '' },
    cond: whereProfileHasOnlyOneDevice,
    yes: () => 'ACTIVE_SINGLE_DEVICE',
    no: () => 'ACTIVE_MULTIPLE_DEVICES',
  }),
});

const calculateProfileHasOneDeviceInSafeNetworks = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasAtLeastOneDeviceInSafeNetworks,
  yes: calculateUnifiedNowCardActiveSafeNetwork,
  no: calculateUnifiedNowCardActiveSingleMultiple,
});

const unifiedNowCardActiveTamperedTimeLimit = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasExtraTime,
  yes: () => 'ACTIVE_TAMPERED_TIME_LIMIT_EXTRA',
  no: () => 'ACTIVE_TAMPERED_TIME_LIMIT',
});

const unifiedNowCardActiveTampered = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileIsRunningOverTimeLimit,
  yes: unifiedNowCardActiveTamperedTimeLimit,
  no: () => 'ACTIVE_TAMPERED',
});

const calculateUnifiedNowCardOneDeviceTampered = unifiedNowCardDecision({
  meta: {
    name: 'at least one device tampered',
    description: 'check if at least one device has been tampered',
  },
  cond: whereProfileHasOnlyOneTamperedDevice,
  yes: unifiedNowCardActiveTampered,
  no: calculateProfileHasOneDeviceInSafeNetworks,
});

const calculateUnifiedNowCardTimeLimitAlert = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasReachedTimeLimitWithOnlyAlert,
  yes: () => 'TIME_LIMIT_ALERT',
  no: calculateUnifiedNowCardOneDeviceTampered,
});

const calculateUnifiedNowCardTimeRestrictionAlert = unifiedNowCardDecision({
  meta: {
    name: 'time restriction alert',
    description:
      'calculate if the user is in a time restriction and has only the alert option enabled',
  },
  cond: whereProfileHasDeviceRestrictedTimesAndAlert,
  yes: () => 'RESTRICTED_TIME_ALERT',
  no: calculateUnifiedNowCardTimeLimitAlert,
});

const calculateUnifiedNowCardRoutineTimeLimit = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasExtraTime,
  yes: () => 'ROUTINE_TIME_LIMIT_EXTRA',
  no: () => 'ROUTINE_TIME_LIMIT',
});

const calculateUnifiedNowCardRoutineTimeAlert = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasReachedTimeLimitWithOnlyAlert,
  yes: () => 'ROUTINE_TIME_LIMIT_ALERT',
  no: calculateUnifiedNowCardRoutineTimeLimit,
});

const calculateUnifiedNowCardRoutine = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileIsRunningOverTimeLimit,
  yes: calculateUnifiedNowCardRoutineTimeAlert,
  no: () => 'ROUTINE',
});

const calculateUnifiedNowCardRoutineTimeLimitNoCount = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasAnActiveRoutineNoCountingToTimeLimit,
  yes: () => 'ROUTINE_TIME_LIMIT_NO_COUNT',
  no: calculateUnifiedNowCardRoutine,
});

const calculateUnifiedNowCardRoutineBlockType = unifiedNowCardDecision({
  meta: { name: '', description: '' },
  cond: whereProfileHasAnActiveRoutineBlockType,
  yes: () => 'ROUTINE_BLOCK_TYPE',
  no: calculateUnifiedNowCardRoutineTimeLimitNoCount,
});

const calculateUnifiedNowCardActiveRoutine = unifiedNowCardDecision({
  meta: {
    name: 'Active Routine',
    description: 'check if the profile as an active routine',
  },
  cond: whereProfileHasAnActiveRoutine,
  yes: calculateUnifiedNowCardRoutineBlockType,
  no: calculateUnifiedNowCardTimeRestrictionAlert,
});

const calculateUnifiedNowCardTimeLimitReached = unifiedNowCardDecision({
  meta: {
    name: 'Time limit reached',
    description:
      'check if the user has reached the time limit and has the lock setting on',
  },
  cond: all(
    whereProfileHasReachedTimeLimitWithLockSettings,
    ifElse(
      whereProfileHasAnActiveRoutine,
      complement(whereProfileHasAnActiveRoutineNoCountingToTimeLimit),
      always(true)
    )
  ),
  yes: () => 'TIME_LIMIT_REACHED_LOCK',
  no: calculateUnifiedNowCardActiveRoutine,
});

const calculateUnifiedNowCardRestrictedTime = unifiedNowCardDecision({
  meta: {
    name: 'restricted time',
    description: 'check if the profile has a time restriction',
  },
  cond: whereProfileHasDeviceRestrictedTimeAndLocked,
  yes: () => 'RESTRICTED_TIME',
  no: calculateUnifiedNowCardTimeLimitReached,
});

const calculateUnifiedNowCardPaused = unifiedNowCardDecision({
  meta: {
    name: 'Pause',
    description:
      'check if the profile has internet paused and if it has one or more devices paused',
  },
  cond: whereProfileHasDevicesPaused,
  yes: unifiedNowCardDecision({
    meta: { name: '', description: '' },
    cond: whereProfileHasOnlyOneDevice,
    yes: () => 'INTERNET_PAUSED_SINGLE_DEVICE',
    no: () => 'INTERNET_PAUSED_MULTIPLE_DEVICES',
  }),
  no: calculateUnifiedNowCardRestrictedTime,
});

const calculateUnifiedNowCardSafeNetworks = unifiedNowCardDecision({
  meta: {
    name: 'safe networks',
    description: 'check if the profile has all the devices in a safe network',
  },
  cond: whereProfileHasAllDevicesInSafeNetworks,
  yes: () => 'SAFE_NETWORK_ALL',
  no: calculateUnifiedNowCardPaused,
});

const calculateUnifiedNowCardNoActivityAllLong = unifiedNowCardDecision({
  meta: {
    name: 'no activity duration',
    description:
      'When all devices are offline check if the inactivity has been more than 30 days or less in all devices',
  },
  cond: whereAllDevicesOfflineLongPeriod,
  yes: () => 'QUSTODIO_NO_ACTIVITY_ALL_LONG_TIME',
  no: () => 'QUSTODIO_NO_ACTIVITY_ALL',
});

const calculateUnifiedNowCardNoActivityAll = unifiedNowCardDecision({
  meta: {
    name: 'No activity',
    description:
      'When all the devices did not report any event in the last 72 h',
  },
  cond: whereProfileHasAllDevicesOffline,
  yes: calculateUnifiedNowCardNoActivityAllLong,
  no: calculateUnifiedNowCardSafeNetworks,
});

const calculateUnifiedNowCardDisabledAll = unifiedNowCardDecision({
  meta: {
    name: 'all devices disabled',
    description: 'check if all devices are disabled',
  },
  cond: whereProfileHasAllDevicesDisabled,
  yes: () => 'QUSTODIO_DISABLED_ALL',
  no: calculateUnifiedNowCardNoActivityAll,
});

const calculateUnifiedNowCardFamily = unifiedNowCardDecision({
  meta: {
    name: 'Unified now card family',
    description: 'Starting point of the family states',
  },
  cond: whereProfileHasAllDevicesTampered,
  yes: calculateUnifiedNowCardTampered,
  no: calculateUnifiedNowCardDisabledAll,
});

const calculateUnifiedNowCardSchoolActiveInsideDelegatedPaused =
  unifiedNowCardDecision({
    meta: {
      name: 'school active inside delegated paused',
      description: 'Calculates if the delegated school device is paused or not',
    },
    cond: whereTheSchoolDeviceIsPaused,
    yes: () => 'SCHOOL_ACTIVE_INSIDE_DELEGATED_PAUSED',
    no: () => 'SCHOOL_ACTIVE_INSIDE_DELEGATED_CAN_PAUSE',
  });

const calculateUnifiedNowCardSchoolActiveInsideDelegated =
  unifiedNowCardDecision({
    meta: {
      name: 'school active inside delegated',
      description:
        'calculates if the pause is enabled and if the pause 24 7 is enabled for the profile',
    },
    cond: whereTheSchoolDevicePausedIsEnabled,
    no: () => 'SCHOOL_ACTIVE_INSIDE_DELEGATED',
    yes: unifiedNowCardDecision({
      cond: whereSchoolDeviceHasEnabledPause247,
      no: () => 'SCHOOL_ACTIVE_INSIDE_DELEGATED',
      yes: calculateUnifiedNowCardSchoolActiveInsideDelegatedPaused,
    }),
  });

const calculateUnifiedNowCardSchoolDelegatedOutsideSchoolHours =
  unifiedNowCardDecision({
    meta: {
      name: 'delegated devices outside class',
      description:
        'calculates if the user is outside class hours and send it to the family branch',
    },
    cond: whereTheSchoolDeviceIsInsideSchool,
    yes: calculateUnifiedNowCardSchoolActiveInsideDelegated,
    no: calculateUnifiedNowCardFamily,
  });

const calculateUnifiedNowCardSchoolActiveDelegatedToOther =
  unifiedNowCardDecision({
    meta: {
      name: 'is delegated to other parent',
      description: 'check if the profile is delegated but to other account',
    },
    cond: whereSchoolDeviceIsDelegatedToThisAccount,
    yes: calculateUnifiedNowCardSchoolDelegatedOutsideSchoolHours,
    no: () => 'SCHOOL_ACTIVE_DELEGATED_TO_OTHER',
  });

const calculateUnifiedNowCardIsSchoolOnlyProfileInClass =
  unifiedNowCardDecision({
    meta: {
      name: 'Inside school hours',
      description:
        'where the profile is inside school hours and has pause not enabled',
    },
    cond: whereTheSchoolDeviceIsInsideSchool,
    yes: () => 'SCHOOL_ACTIVE_INSIDE',
    no: () => 'SCHOOL_ACTIVE',
  });

const calculateUnifiedNowCardIsSchoolProfilePaused = unifiedNowCardDecision({
  meta: {
    name: 'is device currently paused',
    description: 'check if the school device is paused',
  },
  cond: whereTheSchoolDeviceIsPaused,
  yes: () => 'SCHOOL_PAUSED',
  no: () => 'SCHOOL_ACTIVE_CAN_PAUSE',
});

const calculateUnifiedNowCardSchoolProfileIsOutsideClassAndWithout247Enabled =
  unifiedNowCardDecision({
    meta: {
      name: 'can school device be paused',
      description:
        "check if the profile has enabled the 24 7 pause or it's outside school hours",
    },
    cond: whereTheSchoolDeviceIsOutsideSchoolOr247,
    yes: calculateUnifiedNowCardIsSchoolProfilePaused,
    no: () => 'SCHOOL_ACTIVE_INSIDE',
  });

const calculateUnifiedNowCardSchoolPauseIsEnabled = unifiedNowCardDecision({
  meta: {
    name: 'has school enabled pause',
    description:
      'check if the profile can pause the internet in school devices',
  },
  cond: whereTheSchoolDevicePausedIsEnabled,
  yes: calculateUnifiedNowCardSchoolProfileIsOutsideClassAndWithout247Enabled,
  no: calculateUnifiedNowCardIsSchoolOnlyProfileInClass,
});

const calculateUnifiedNowCardIsDelegated = unifiedNowCardDecision({
  meta: {
    name: 'is delegated',
    description: 'check if the school device is delegated or not',
  },
  cond: whereSchoolDeviceIsDelegated,
  yes: calculateUnifiedNowCardSchoolActiveDelegatedToOther,
  no: calculateUnifiedNowCardSchoolPauseIsEnabled,
});

export const calculateUnifiedNowCardCurrentState = unifiedNowCardDecision({
  meta: {
    name: 'has only school device',
    description:
      'check whether the profile has only one school device either delegated or not',
  },
  cond: whereProfileHasOnlySchoolDevice,
  yes: calculateUnifiedNowCardIsDelegated,
  no: calculateUnifiedNowCardFamily,
});
