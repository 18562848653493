import * as React from 'react';
import { classNames } from '../../helpers';
import FixedSideBar, { Props } from '../SideBar/FixedSideBar';

const { createRef } = React as any;

const defaultTransition = 'all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1)';

class DraggableSideBar extends React.Component<Props> {
  sideBarRef = createRef<HTMLDivElement>();

  initialTouchX = 0;

  lastTouchX = 0;

  initialTouchTimeStamp = 0;

  rafPending = false;

  get sideBar() {
    return this.sideBarRef.current!;
  }

  get sideBarWidth() {
    return this.sideBar.getBoundingClientRect().width;
  }

  get events() {
    return [
      { name: 'touchstart', handler: this.handleTouchStart },
      { name: 'touchmove', handler: this.handleTouchMove },
      { name: 'touchend', handler: this.handleTouchEnd },
      { name: 'touchcancel', handler: this.handleTouchEnd },
    ];
  }

  handleSubscriptions = (operation: 'create' | 'clean') => {
    const action = {
      create: 'addEventListener',
      clean: 'removeEventListener',
    }[operation];

    const { sideBar } = this;

    this.events.forEach(({ name, handler }) => {
      sideBar[action](name, handler, true);
    });
  };

  componentDidMount() {
    this.handleSubscriptions('create');
  }

  componentWillUnmount() {
    this.handleSubscriptions('clean');
  }

  getXFromTouchEvent = evt => {
    return evt.targetTouches && evt.targetTouches[0]
      ? evt.targetTouches[0].clientX
      : 0;
  };

  handleMoveAnimation = () => {
    if (!this.rafPending) {
      /*
        Since this function is a scheduled function triggered by the browser,
        it may be possible that when it's executed a "touchend" or "touchcancel" event already happened,
        in that scenario this function shouldn't do anything.
       */
      return;
    }

    const differenceInX = this.initialTouchX - this.lastTouchX;

    let pxToMove = -differenceInX;

    if (pxToMove >= 0) {
      // This ensures that the translate x transform it's never a positive px value.
      pxToMove = 0;
    }

    const transformStyle = `translate3d(${pxToMove}px, 0, 0)`;

    this.sideBar.style.transform = transformStyle;
    this.rafPending = false;
  };

  handleEndAnimation = evt => {
    const { onClose } = this.props;

    const timeThresholdInMs = 400;
    const widthThresholdInPx = 70;

    const pxMoved = this.initialTouchX - this.lastTouchX;

    const timeElapsed = evt.timeStamp - this.initialTouchTimeStamp;

    let pxThreshold = widthThresholdInPx;

    if (timeElapsed > timeThresholdInMs) {
      /*
        The pxThreshold value contains the number of pixels that will dictate in which direction
        the sidebar will animate when the gesture ends:
        to the left (hide sidebar) or to the right (open sidebar)

        This number will be higher or lower depending on the type of gesture detected.
        If the time it takes the gesture to complete is lower than 400 milliseconds(timeThresholdInMs)
        there is an assumption made that the user did a fast swipe with the intention of closing the sidebar.

        If it took the user more than 400 milliseconds to compelte the gesture, he's probably dragging
        the sidebar and so the threshold is higher. (half of the size of the sidebar)
      */

      pxThreshold = this.sideBarWidth / 2;
    }

    const { sideBar } = this;

    if (pxMoved >= pxThreshold) {
      sideBar.style.transform = `translate3d(-110%, 0, 0)`;
      onClose();
    } else {
      sideBar.style.transform = `translate3d(0%, 0, 0)`;
    }

    sideBar.style.transition = defaultTransition;
  };

  handleTouchStart = evt => {
    const MORE_THAN_ONE_TOUCH_DETECTED = evt.touches && evt.touches.length > 1;

    if (MORE_THAN_ONE_TOUCH_DETECTED) {
      // Skip doing anything. Gestures involving more than one finger at the same time should be ignored.
      return;
    }

    this.initialTouchX = this.getXFromTouchEvent(evt);
    this.lastTouchX = this.initialTouchX;
    this.initialTouchTimeStamp = evt.timeStamp;
    this.sideBar.style.transition = 'initial';
  };

  handleTouchMove = evt => {
    const TOUCH_START_NOT_TRACKED = !this.initialTouchX;

    if (TOUCH_START_NOT_TRACKED) {
      // Skip doing anything if gesture start has not been tracked (more than one finger involved)
      return;
    }

    this.lastTouchX = this.getXFromTouchEvent(evt);

    const ANIMATION_ALREADY_SCHEDULED = this.rafPending;

    if (ANIMATION_ALREADY_SCHEDULED) {
      /*
        Skip scheduling another animation callback.
        The browser has already been notified through "requestAnimationFrame"
        and will run the function just before the next paint.
        This touch event always save the last X position (lastTouchX),
        so when the scheduled callback is triggered, will have access to the latest updated data.
      */
      return;
    }

    this.rafPending = true;

    window.requestAnimationFrame(this.handleMoveAnimation);
  };

  handleTouchEnd = evt => {
    const ONE_FINGER_STILL_TOUCHING = evt.touches && evt.touches.length > 0;

    if (ONE_FINGER_STILL_TOUCHING) {
      // Skip the cleanup and final animation till there are no more fingers touching the "touchable" element
      return;
    }

    this.rafPending = false;

    this.handleEndAnimation(evt);

    this.initialTouchX = 0;
    this.initialTouchTimeStamp = 0;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { active } = this.props;
    if (active !== nextProps.active) {
      // If the active prop changed an animation should happen

      const { sideBar } = this;

      if (nextProps.active) {
        // Open animation
        sideBar.style.transform = `translate3d(0%, 0, 0)`;
      } else {
        // Close animation
        sideBar.style.transform = `translate3d(-110%, 0, 0)`;
      }
      sideBar.style.transition = defaultTransition;
    }
  }

  render() {
    const { active, onClose } = this.props;
    return (
      <React.Fragment>
        <div
          ref={this.sideBarRef}
          className={classNames(
            'DraggableSideBar',
            active ? 'DraggableSideBar--active' : ''
          )}
        >
          <FixedSideBar expand {...this.props} />
        </div>
        <div
          onClick={onClose}
          className={classNames(
            'DraggableSideBar__overlay',
            active ? 'DraggableSideBar__overlay--active' : ''
          )}
        />
      </React.Fragment>
    );
  }
}

export default DraggableSideBar;
