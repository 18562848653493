import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import ScreenTimeModal from '../../components/Modal/ScreenTimeModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const ScreenTimeModalContainer = connect(
  null,
  mapDispatchToProps
)(ScreenTimeModal);

export default ScreenTimeModalContainer;
