import { Action } from 'redux';
import { goBackIfHistory } from '../../ducks/routing';
import { setInterval, clearInterval } from '../../lib/timeout';
import {
  getOnboardingProfile,
  isFetchingRemoteOnboardingToken,
  getRemoteOnboardingRenewTimeout,
  getRemoteOnboardingTokenData,
  getRemoteOnboardingUrl,
  isPollingAccountData,
} from '../../selectors/onboarding';
import api from '../../api';
import {
  addOnboardingSocialShareType,
  ONBOARDING_SOCIAL_SHARE_TYPE_COPY,
  setLoadingProfiles,
  remoteOnboardingTokenFetchStarted,
  remoteOnboardingTokenFetchFinished,
  remoteOnboardingTokenRefreshed,
  onboardingAccountPollingStarted,
} from '../../ducks/appOnboarding';
import { gaEvent } from '../../helpers';

import { track, Events } from '../../helpers/analytics';
import { showErrorToast } from '../../containers/AccountSettings/helpers';
import { setWebOnboardingOption, fetchAccount } from '../../ducks/account';
import { getFirstProfile } from '../../selectors/profile';
import { setItems } from '../../ducks/persistentStorage';
import OnboardingTokenRecord, { isEmpty } from '../../records/onboardingToken';
import {
  mapUserAgentOsToPlatform,
  mapPlatformToDownloadLink,
} from '../../helpers/userAgent';
import {
  OnBoardingRoutesBeta,
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
} from '../../constants';
import { hasAtLeastOneProfileAndOneDevice } from '../../selectors/account';
import { Dispatch } from '../../store/state';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

export const NO_ONBOARDING_PROFILE_ERROR = 'No onboarding profile';

export const navigateToWelcome = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(navigate({ type: 'inner', src: '/onboarding' }));
};

export const closeWelcomeModal = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return Promise.resolve(
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/onboarding',
      })
    )
  ).then(() => {
    track(Events.ClosedOnboardingWelcomeModal);
    gaEvent('wob-welcome-flyover', 'button-ok', 'click');
  });
};

export const navigateToAddChild = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(navigate({ type: 'inner', src: '/onboarding/add-child' }));
};

export const updateWebOnboardingOptionAsFinished =
  () =>
  (dispatch: Dispatch): Promise<Action<string>> =>
    api.options
      .post({ trial_journey_finished: 'true' })
      .then(result => dispatch(setWebOnboardingOption(result[0].value)));

export const navigateToSummary = () => (dispatch: Dispatch) => {
  dispatch(setLoadingProfiles(true));
  return updateWebOnboardingOptionAsFinished()(dispatch)
    .then(() => {
      const navigate = getMultiPlatformNavigation();
      return dispatch(navigate({ type: 'inner', src: '/' }));
    })
    .then(() => gaEvent('wob-finish', 'button-explore-dashboard', 'click'))
    .catch(err => {
      dispatch(setLoadingProfiles(false));
      dispatch(showErrorToast(err));
    });
};

export const navigateToFinish = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(navigate({ type: 'inner', src: '/onboarding/finish' }));
};

export const navigateToFinishBeta = () => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(
    navigate({ type: 'inner', src: '/onboarding-beta/setup-complete' })
  );
};

export const closeShareByEmailModal = () => dispatch =>
  dispatch(goBackIfHistory());

export const sendDownloadLinkEmail = email =>
  api.sendDownloadLink.post({ to_email: email });

export const socialShareCopy = () => dispatch =>
  Promise.resolve(
    dispatch(addOnboardingSocialShareType(ONBOARDING_SOCIAL_SHARE_TYPE_COPY))
  ).then(() => gaEvent('wob-share-link', 'share-via-copy', 'click'));

export const resetSocialShareType = () => dispatch =>
  dispatch(addOnboardingSocialShareType(null));

export const triggerDeviceConnectionLoop = () => (dispatch, getState) =>
  new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      const onboardingProfile = getOnboardingProfile(getState());
      if (!onboardingProfile) {
        reject(new Error(NO_ONBOARDING_PROFILE_ERROR));
        clearInterval(interval);
        return;
      }

      dispatch(() =>
        api.deviceUserProfile.get({
          profileId: (onboardingProfile as ProfileRecord).id,
        })
      )
        .then(results => {
          if (results.length) {
            resolve();
            clearInterval(interval);
          }
        })
        .catch(error => {
          reject(error);
          clearInterval(interval);
        });
    }, 2000);
  });

export const clickSupport = () => () =>
  Promise.resolve(gaEvent('wob-support', 'link-help-contact', 'click'));

export const getPreviousOnboardingScreen = (screen: OnBoardingRoutesBeta) => {
  const backNavigationMapping = {
    [OnBoardingRoutesBeta.chooseDevice]: OnBoardingRoutesBeta.addProfile,
    [OnBoardingRoutesBeta.setupRemoteDevice]: OnBoardingRoutesBeta.chooseDevice,
    [OnBoardingRoutesBeta.setupLocalDevice]: OnBoardingRoutesBeta.chooseDevice,
  };

  return backNavigationMapping[screen] || OnBoardingRoutesBeta.welcomeModal;
};

export const renewRemoteOnboardingToken = () => (dispatch, getState) => {
  const state = getState();
  if (isFetchingRemoteOnboardingToken(state)) {
    return;
  }

  dispatch(remoteOnboardingTokenFetchStarted());

  // eslint-disable-next-line consistent-return
  return fetchOnboardingToken(getFirstProfile(state).uid).then(payload => {
    dispatch(
      setItems({
        onboardingTokenData: JSON.stringify(
          OnboardingTokenRecord.fromPayload(payload).toJS()
        ),
      })
    );

    dispatch(remoteOnboardingTokenFetchFinished());
    dispatch(remoteOnboardingTokenRefreshed());
  });
};

export const fetchRemoteOnboardingTokenIfNeeded =
  () => (dispatch, getState) => {
    const state = getState();

    const tokenData = getRemoteOnboardingTokenData(state);
    if (isEmpty(tokenData)) {
      dispatch(renewRemoteOnboardingToken());
    }
  };

const fetchOnboardingToken = (profileUid: string) =>
  api.onboardingToken.post({}, null, { profileUid });

export const isValidToken = (tokenData: OnboardingTokenRecord) =>
  !isEmpty(tokenData) && getRemoteOnboardingRenewTimeout(tokenData) > 0;

// eslint-disable-next-line consistent-return
export const clickedCurrentDeviceBeta = () => (dispatch, getState) => {
  if ([PLATFORM_MAC, PLATFORM_WINDOWS].includes(mapUserAgentOsToPlatform())) {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({ type: 'inner', src: OnBoardingRoutesBeta.setupLocalDevice })
    );
  }

  const tokenData = getRemoteOnboardingTokenData(getState());
  if (isValidToken(tokenData)) {
    const navigate = getMultiPlatformNavigation();

    return navigate({
      type: 'location:href',
      src: getRemoteOnboardingUrl(tokenData).raw,
    });
    // Redirecting to FP directly
  }

  // We need to generate a new token and THEN redirect to FP
  dispatch(renewRemoteOnboardingToken()).then(() => {
    const tokenData = getRemoteOnboardingTokenData(getState());
    const navigate = getMultiPlatformNavigation();
    navigate({
      type: 'location:href',
      src: getRemoteOnboardingUrl(tokenData).raw,
    });
  });
};

export const triggerDownload = () => {
  const navigate = getMultiPlatformNavigation();
  return navigate({
    type: 'location:href',
    src: mapPlatformToDownloadLink(mapUserAgentOsToPlatform()),
  });
};

export const onEnterSetupLocalDeviceAction = () => dispatch => {
  triggerDownload();
  dispatch(loopDeviceSetupCheck());
};

export const onEnterSetupRemoteDeviceAction = () => dispatch => {
  dispatch(fetchRemoteOnboardingTokenIfNeeded());
  dispatch(loopDeviceSetupCheck());
};

const loopDeviceSetupCheck = () => (dispatch, getState) => {
  if (isPollingAccountData(getState())) {
    return;
  }

  dispatch(onboardingAccountPollingStarted());
  // eslint-disable-next-line consistent-return
  return dispatch(triggerDeviceConnectionLoopBeta()).then(() =>
    dispatch(navigateToFinishBeta())
  );
};

// Exported for testing reasons
export const triggerDeviceConnectionLoopBeta = () => (dispatch, getState) =>
  new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      dispatch(fetchAccount())
        .then(() => {
          if (hasAtLeastOneProfileAndOneDevice(getState())) {
            resolve();
            clearInterval(interval);
          }
        })
        .catch(error => {
          reject(error);
          clearInterval(interval);
        });
    }, 2000);
  });
