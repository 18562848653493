import { GlobalType, IconType } from 'styleguide-react';
import { flatten } from 'ramda';
import { t } from '../../../lib/i18n';
import { minutesToHMFormat } from '../../../helpers/dates';
import {
  ActionWrapper,
  ActionsOptionsFn,
  ActionsOptionsFnParams,
  Matcher,
  UnifiedNowCardConfig,
  UnifiedNowCardData,
} from './types';
import {
  editPause,
  optionsDailyLimitsEditRoutineAndEditRules,
  optionsDailyLimitsEditRoutineEditRulesAndAddExtraTime,
  optionsDailyLimitsEditRoutineEditRulesAndEditExtraTime,
  optionsEditPauseAndRules,
  optionsEditRestrictedTimes,
  optionsEditRestrictedTimesWithSchoolDevice,
  optionsEditRoutine,
  optionsEditRules,
  optionsAddExtraTimeAndDailyLimits,
  optionsAddExtraTimeAndDailyLimitsWithSchoolDevice,
  optionsEditExtraTimeDailyTimeLimitsAndEditRules,
  optionsEditRulesAndRestrictedTimes,
  optionsSwitchRoutine,
  optionsUndoSwitchRoutine,
} from './unifiedNowCardActionsOptions';
import {
  actionPauseButton,
  actionPlusButton,
  actionResumeButton,
} from './unifiedNowCardButtonActions';
import {
  noActivityInfoModal,
  routineWithoutTimeLimit,
} from './unifiedNowCardTitleInfoActions';
import { UnifiedNowCardState } from '../../../businessLogic/unifiedNowCard/types';
import { calcPercent } from '../../../helpers';
import { getRoutinesIcon } from '../../Routines/helpers/routineIcons';
import { UnionToIntersection } from '../../../types/UnionToIntersection';

export const mergeOptions =
  <T extends ActionsOptionsFn[]>(...actions: T) =>
  (options: UnionToIntersection<ActionsOptionsFnParams<T[number]>>) =>
    flatten(actions.map(fn => fn(options)));

const getRoutineDescription = (
  routine: UnifiedNowCardData['activeRoutine'],
  schedule: UnifiedNowCardData['activeRoutineSchedule'],
  isSwitchedRoutine: boolean
) => {
  const time = t('Until {{time}}', { time: schedule?.endTime });
  let description = routine?.description;
  if (isSwitchedRoutine) description = t('Switched routine');

  return [time, description]
    .filter(val => val != null && val !== '')
    .join(' · ');
};

export const cardStateMatcher = (
  state: UnifiedNowCardState,
  {
    profileId,
    untilTime,
    firstDeviceName,
    profileName,
    screenTime = undefined,
    dailyLimit,
    extraTime,
    schoolUntilTime,
    schoolStartTime,
    schoolEndTime,
    untilRestrictedTime,
    numOfDevicesInSafeNetworks,
    numTamperedDevices,
    numTotalDevices,
    devicesOfflineDay,
    offlineDeviceId,
    isProfileLinkedWithSchool,
    activeRoutine,
    activeRoutineSchedule,
    isActiveRoutineSwitched: isRoutineSwitched,
    isSwitchRoutineFeatureEnabled,
  }: UnifiedNowCardData,
  actionWrapper: ActionWrapper
): UnifiedNowCardConfig => {
  const screenTimeFormatted = minutesToHMFormat(screenTime || 0);
  const dailyLimitFormatted = minutesToHMFormat(dailyLimit || 0);
  const extraTimeFormatted = minutesToHMFormat(extraTime || 0);
  const totalDailyLimit = Number(dailyLimit ?? 0) + Number(extraTime ?? 0);
  const usedScreenTimePercentage =
    screenTime && totalDailyLimit
      ? calcPercent(screenTime, totalDailyLimit)
      : 0;

  const tamperingTitle = t('Tampering detected');
  const internetIsPausedTitle = t('Internet is paused');
  const schoolProtectionActiveTitle = t('School protection is active');
  const devicesLockedTitle = t('Devices are locked');
  const dailyTimeLimitReachedTitle = t('Daily time limit reached');
  const timesUpText = t("Time's up!");
  const qustodioIsActiveTitle = t('{{shortName}} is active');
  const protectionRunningSubtitle = t('Protection and rules are running');
  const onAllDevicesSubtitle = t('On all devices');
  const dailyLimitSubtitle = `${t('Limit:')} ${dailyLimitFormatted}`;
  const dailyTimeLimitAndExtraTimeSubtitle = `${t(
    'Limit:'
  )} ${dailyLimitFormatted} + ${extraTimeFormatted}`;
  const devicesConnectedToASafeNetworkSubtitle =
    numOfDevicesInSafeNetworks === 1
      ? t('1 device is connected to a Safe Network')
      : t('{{numDevices}} devices are connected to a Safe Network', {
          numDevices: numOfDevicesInSafeNetworks,
        });
  const partialTamperedSubtitle = t('On {{count}}/{{total}} devices', {
    count: numTamperedDevices,
    total: numTotalDevices,
  });
  const noActivityAllTitle = t('No activity reported for {{days}} days', {
    days: devicesOfflineDay,
  });
  const noActivityAllLongTitle = t(
    'No activity reported for more than 30 days'
  );
  const noActivityAllSubtitle = t('Check that protection is working fully');
  const qustodioIsDisabled = t('{{shortName}} has been temporarily disabled');
  const qustodioDisabledSubtitle = t(
    '{{profileName}} has full access to devices',
    {
      profileName,
    }
  );
  const routineIsActiveTitle = t('{{routineName}} is active', {
    routineName: activeRoutine?.get('name'),
  });
  const routineSubtitle = getRoutineDescription(
    activeRoutine,
    activeRoutineSchedule,
    isRoutineSwitched
  );
  const routineIcon = activeRoutine?.icon
    ? getRoutinesIcon(activeRoutine.icon)
    : undefined;

  const routineCommons = {
    gradient: true,
    shapes: true,
    type: GlobalType.default,
    routineType: activeRoutine?.color,
    title: routineIsActiveTitle,
    subtitle: routineSubtitle,
    iconType: routineIcon as string,
  };

  // missing delegation and routines cases.
  const matcher: Matcher = {
    SCHOOL_PAUSED: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: internetIsPausedTitle,
      subtitle: t('Until {{time}}', {
        time: schoolUntilTime,
      }),
      iconType: IconType.wifiSlash,
      pauseButtonIconType: IconType.playCircle,
      pauseButtonOnClick: () =>
        actionResumeButton(profileId, actionWrapper, 'SCHOOL_PAUSED'),
      actionOptions: editPause({ actionWrapper, state: 'SCHOOL_PAUSED' }),
    },
    SCHOOL_ACTIVE_CAN_PAUSE: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: schoolProtectionActiveTitle,
      subtitle: t(
        'You can pause the internet on {{profileName}}’s school device',
        {
          profileName,
        }
      ),
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'SCHOOL_ACTIVE_CAN_PAUSE'),
    },
    SCHOOL_ACTIVE: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: schoolProtectionActiveTitle,
      subtitle: protectionRunningSubtitle,
      iconType: IconType.shieldCheck,
    },
    SCHOOL_ACTIVE_INSIDE: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: t('School is in session'),
      subtitle: t('Between {{startTime}} - {{endTime}}', {
        startTime: schoolStartTime,
        endTime: schoolEndTime,
      }),
      iconType: IconType.shieldCheck,
    },
    SCHOOL_ACTIVE_INSIDE_DELEGATED: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: t('School is in session'),
      subtitle: t(
        'You can manage {{profileName}}’s school device outside of school hours ({{startTime}} - {{endTime}})',
        {
          profileName,
          startTime: schoolStartTime,
          endTime: schoolEndTime,
        }
      ),
      iconType: IconType.shieldCheck,
      actionOptions: optionsEditRules({
        profileId,
        actionWrapper,
        state: 'SCHOOL_ACTIVE_INSIDE_DELEGATED',
      }),
    },
    SCHOOL_ACTIVE_INSIDE_DELEGATED_CAN_PAUSE: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: schoolProtectionActiveTitle,
      subtitle: t(
        'You can pause the internet on {{profileName}}’s school device',
        {
          profileName,
        }
      ),
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(
          actionWrapper,
          'SCHOOL_ACTIVE_INSIDE_DELEGATED_CAN_PAUSE'
        ),
      actionOptions: optionsEditRules({
        profileId,
        actionWrapper,
        state: 'SCHOOL_ACTIVE_INSIDE_DELEGATED_CAN_PAUSE',
      }),
    },
    SCHOOL_ACTIVE_INSIDE_DELEGATED_PAUSED: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: internetIsPausedTitle,
      subtitle: t('Until {{time}}', {
        time: schoolUntilTime,
      }),
      iconType: IconType.wifiSlash,
      pauseButtonIconType: IconType.playCircle,
      pauseButtonOnClick: () =>
        actionResumeButton(
          profileId,
          actionWrapper,
          'SCHOOL_ACTIVE_INSIDE_DELEGATED_PAUSED'
        ),
      actionOptions: optionsEditPauseAndRules({
        profileId,
        actionWrapper,
        state: 'SCHOOL_ACTIVE_INSIDE_DELEGATED_PAUSED',
      }),
    },
    SCHOOL_ACTIVE_DELEGATED_TO_OTHER: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: schoolProtectionActiveTitle,
      subtitle: protectionRunningSubtitle,
      iconType: IconType.shieldCheck,
    },
    SAFE_NETWORK_ALL: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: t('Safe Network is active'),
      subtitle: t('School protection and rules are running'),
      iconType: IconType.graduationCap,
    },
    TAMPERED_SINGLE_DEVICE: {
      gradient: false,
      shapes: false,
      type: GlobalType.error,
      title: tamperingTitle,
      subtitle: t(
        'Reinstall {{shortName}} on {{deviceName}} to reactivate protection',
        {
          deviceName: firstDeviceName,
        }
      ),
      iconType: IconType.exclamationCircle,
      iconClassName: 'par-unified-now-card__icon--error',
    },
    TAMPERED_MULTIPLE_DEVICES: {
      gradient: false,
      shapes: false,
      type: GlobalType.error,
      title: tamperingTitle,
      subtitle: t(
        'Reinstall {{shortName}} on every device to reactivate protection'
      ),
      iconType: IconType.exclamationCircle,
      iconClassName: 'par-unified-now-card__icon--error',
    },
    INTERNET_PAUSED_SINGLE_DEVICE: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: internetIsPausedTitle,
      subtitle: t('Until {{time}}', { time: untilTime }),
      iconType: IconType.wifiSlash,
      pauseButtonIconType: IconType.playCircle,
      pauseButtonOnClick: () =>
        actionResumeButton(
          profileId,
          actionWrapper,
          'INTERNET_PAUSED_SINGLE_DEVICE'
        ),
      actionOptions: optionsEditPauseAndRules({
        profileId,
        actionWrapper,
        state: 'INTERNET_PAUSED_SINGLE_DEVICE',
      }),
    },
    INTERNET_PAUSED_MULTIPLE_DEVICES: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: internetIsPausedTitle,
      subtitle: t('On all devices until {{time}}', { time: untilTime }),
      iconType: IconType.wifiSlash,
      pauseButtonIconType: IconType.playCircle,
      pauseButtonOnClick: () =>
        actionResumeButton(
          profileId,
          actionWrapper,
          'INTERNET_PAUSED_MULTIPLE_DEVICES'
        ),
      actionOptions: optionsEditPauseAndRules({
        profileId,
        actionWrapper,
        state: 'INTERNET_PAUSED_MULTIPLE_DEVICES',
      }),
    },
    RESTRICTED_TIME: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: devicesLockedTitle,
      subtitle: t('A restricted time is running until {{time}}', {
        time: untilRestrictedTime,
      }),
      iconType: IconType.lock,
      actionOptions: isProfileLinkedWithSchool
        ? optionsEditRestrictedTimesWithSchoolDevice({
            profileId,
            actionWrapper,
            state,
          })
        : optionsEditRestrictedTimes({ profileId, actionWrapper, state }),
    },
    RESTRICTED_TIME_ALERT: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: t('{{profileName}} has reached a restricted time', {
        profileName,
      }),
      subtitle: t('Devices can still be used'),
      iconType: IconType.lock,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'RESTRICTED_TIME_ALERT'),
      actionOptions: optionsEditRulesAndRestrictedTimes({
        profileId,
        actionWrapper,
        state: 'RESTRICTED_TIME_ALERT',
      }),
    },
    TIME_LIMIT_REACHED_LOCK: {
      gradient: true,
      shapes: true,
      type: GlobalType.warning,
      title: devicesLockedTitle,
      subtitle: dailyTimeLimitReachedTitle,
      iconType: IconType.hourglassHalf,
      pauseButtonIconType: IconType.plusCircle,
      pauseButtonOnClick: () =>
        actionPlusButton(profileId, actionWrapper, state),
      actionOptions: mergeOptions(
        optionsUndoSwitchRoutine,
        isProfileLinkedWithSchool
          ? optionsAddExtraTimeAndDailyLimitsWithSchoolDevice
          : optionsAddExtraTimeAndDailyLimits,
        optionsSwitchRoutine
      )({
        state,
        profileId,
        actionWrapper,
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
      }),
      screenTimeFormatted,
      usedScreenTimePercentage: 100,
      timeSubtitle: timesUpText,
    },
    TIME_LIMIT_ALERT: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: dailyTimeLimitReachedTitle,
      subtitle: t('{{profileName}} can still use devices', {
        profileName,
      }),
      iconType: IconType.hourglassHalf,
      screenTimeFormatted,
      usedScreenTimePercentage: 100,
      timeSubtitle: timesUpText,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'TIME_LIMIT_ALERT'),
      actionOptions: mergeOptions(
        optionsAddExtraTimeAndDailyLimits,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'TIME_LIMIT_ALERT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_SINGLE_DEVICE: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: protectionRunningSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_SINGLE_DEVICE'),
      actionOptions: mergeOptions(
        optionsEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_SINGLE_DEVICE',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_MULTIPLE_DEVICES: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: onAllDevicesSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_MULTIPLE_DEVICES'),
      actionOptions: mergeOptions(
        optionsEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_MULTIPLE_DEVICES',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_TIME_LIMIT: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: onAllDevicesSubtitle,
      iconType: IconType.shieldCheck,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyLimitSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TIME_LIMIT'),
      actionOptions: mergeOptions(
        optionsAddExtraTimeAndDailyLimits,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TIME_LIMIT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_TIME_LIMIT_EXTRA: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: onAllDevicesSubtitle,
      iconType: IconType.shieldCheck,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyTimeLimitAndExtraTimeSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TIME_LIMIT_EXTRA'),
      actionOptions: mergeOptions(
        optionsEditExtraTimeDailyTimeLimitsAndEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TIME_LIMIT_EXTRA',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_SAFE_NETWORK: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: devicesConnectedToASafeNetworkSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_SAFE_NETWORK'),
      actionOptions: optionsEditRules({
        profileId,
        actionWrapper,
        state: 'ACTIVE_SAFE_NETWORK',
      }),
    },
    ACTIVE_SAFE_NETWORK_TIME_LIMIT: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: devicesConnectedToASafeNetworkSubtitle,
      iconType: IconType.shieldCheck,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyLimitSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_SAFE_NETWORK_TIME_LIMIT'),
      actionOptions: optionsAddExtraTimeAndDailyLimits({
        profileId,
        actionWrapper,
        state: 'ACTIVE_SAFE_NETWORK_TIME_LIMIT',
      }),
    },
    ACTIVE_SAFE_NETWORK_TIME_LIMIT_EXTRA: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: devicesConnectedToASafeNetworkSubtitle,
      iconType: IconType.shieldCheck,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyTimeLimitAndExtraTimeSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TIME_LIMIT_EXTRA'),
      actionOptions: optionsEditExtraTimeDailyTimeLimitsAndEditRules({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TIME_LIMIT_EXTRA',
      }),
    },
    ACTIVE_TAMPERED: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: partialTamperedSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TAMPERED'),
      actionOptions: mergeOptions(
        optionsEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TAMPERED',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_TAMPERED_TIME_LIMIT: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: partialTamperedSubtitle,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyLimitSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TAMPERED_TIME_LIMIT'),
      actionOptions: mergeOptions(
        optionsAddExtraTimeAndDailyLimits,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TAMPERED_TIME_LIMIT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ACTIVE_TAMPERED_TIME_LIMIT_EXTRA: {
      gradient: true,
      shapes: true,
      type: GlobalType.secondary,
      title: qustodioIsActiveTitle,
      subtitle: partialTamperedSubtitle,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyTimeLimitAndExtraTimeSubtitle,
      iconType: IconType.shieldCheck,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ACTIVE_TAMPERED_TIME_LIMIT_EXTRA'),
      actionOptions: mergeOptions(
        optionsEditExtraTimeDailyTimeLimitsAndEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        actionWrapper,
        state: 'ACTIVE_TAMPERED_TIME_LIMIT_EXTRA',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    QUSTODIO_DISABLED_ALL: {
      gradient: false,
      shapes: false,
      type: GlobalType.white,
      title: qustodioIsDisabled,
      subtitle: qustodioDisabledSubtitle,
      iconType: 'fa-shield-slash', // TODO add this icon into rocket
    },
    QUSTODIO_NO_ACTIVITY_ALL: {
      gradient: false,
      shapes: false,
      type: GlobalType.gray,
      title: noActivityAllTitle,
      subtitle: noActivityAllSubtitle,
      iconType: IconType.shieldCheck,
      onClickTitleInfo: () =>
        noActivityInfoModal(actionWrapper, state, offlineDeviceId),
    },
    QUSTODIO_NO_ACTIVITY_ALL_LONG_TIME: {
      gradient: false,
      shapes: false,
      type: GlobalType.gray,
      title: noActivityAllLongTitle,
      subtitle: noActivityAllSubtitle,
      iconType: IconType.shieldCheck,
      onClickTitleInfo: () =>
        noActivityInfoModal(actionWrapper, state, offlineDeviceId),
    },
    ROUTINE_TIME_LIMIT_NO_COUNT: {
      ...routineCommons,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ROUTINE_TIME_LIMIT_NO_COUNT'),
      actionOptions: mergeOptions(
        optionsEditRoutine,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE_TIME_LIMIT_NO_COUNT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
      onClickTitleInfo: () => routineWithoutTimeLimit(actionWrapper),
    },
    ROUTINE_BLOCK_TYPE: {
      ...routineCommons,
      actionOptions: mergeOptions(
        optionsEditRoutine,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE_BLOCK_TYPE',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ROUTINE: {
      ...routineCommons,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () => actionPauseButton(actionWrapper, 'ROUTINE'),
      actionOptions: mergeOptions(
        optionsDailyLimitsEditRoutineAndEditRules,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ROUTINE_TIME_LIMIT_ALERT: {
      ...routineCommons,
      screenTimeFormatted,
      usedScreenTimePercentage: 100,
      timeSubtitle: timesUpText,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ROUTINE_TIME_LIMIT_ALERT'),
      actionOptions: mergeOptions(
        optionsDailyLimitsEditRoutineEditRulesAndAddExtraTime,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE_TIME_LIMIT_ALERT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ROUTINE_TIME_LIMIT_EXTRA: {
      ...routineCommons,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyTimeLimitAndExtraTimeSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ROUTINE_TIME_LIMIT_EXTRA'),
      actionOptions: mergeOptions(
        optionsDailyLimitsEditRoutineEditRulesAndEditExtraTime,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE_TIME_LIMIT_EXTRA',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
    ROUTINE_TIME_LIMIT: {
      ...routineCommons,
      screenTimeFormatted,
      usedScreenTimePercentage,
      timeSubtitle: dailyLimitSubtitle,
      pauseButtonIconType: IconType.pauseCircle,
      pauseButtonOnClick: () =>
        actionPauseButton(actionWrapper, 'ROUTINE_TIME_LIMIT'),
      actionOptions: mergeOptions(
        optionsDailyLimitsEditRoutineEditRulesAndAddExtraTime,
        optionsSwitchRoutine
      )({
        profileId,
        routineUid: activeRoutine?.uid,
        scheduleUid: activeRoutineSchedule?.uid,
        actionWrapper,
        state: 'ROUTINE_TIME_LIMIT',
        isRoutineSwitched,
        isSwitchRoutineFeatureEnabled,
      }),
    },
  };

  return matcher[state];
};
