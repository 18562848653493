import { evolve, pipe } from 'ramda';
import { ProductRecord, ProductDuration } from '../records/product';
import {
  UPDATE_PROFILE_RULES_DELAY,
  SettingsToggleNames,
  EventType,
  VideoSources,
  PathTitles,
  PaymentProvider,
} from '../constants';
import { debounce, isBrowserPlatform } from '.';
import { EventRecord } from '../records';
import { safeFn } from './safeFn';
import { userAgentParser } from './userAgent';
import { getBuildPlatform, getAppVersion } from './globals';
import { ProfileFormRecord } from '../components/Profiles/ProfileForm';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import {
  getAccountMasterId,
  getAccountMasterUid,
  isSlaveAccount,
} from '../selectors';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import type { RouterProps } from './multiPlatformNavigation';
import { isSegmentAvailable } from '../sideEffects/globals/thirdPartyServices';
import { RoutineCreationMode } from '../components/Routines/routines.types';

/**
 * Segment.io Wrapper
 */

const appGenerics = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const state = require('../store').default.getState();
  const info = {
    release: getAppVersion(),
    platform: getBuildPlatform(),
    path: window.location.hash.replace('#', ''),
    fullPath: window.location.href,
    master_account_id: isSlaveAccount(state)
      ? getAccountMasterId(state)
      : undefined,
    master_account_uid: isSlaveAccount(state)
      ? getAccountMasterUid(state)
      : undefined,
  };

  return isBrowserPlatform()
    ? info
    : { ...info, bundle_id: global.BuildInfo.packageName };
};

const deviceGenerics = () => {
  return {
    device_info: userAgentParser.getResult(),
    screen_resolution: {
      width: window.screen.width,
      height: window.screen.height,
    },
    window_resolution: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };
};

export enum Events {
  ClickedCalendarPicker = 'Clicked Calendar Picker',
  OpenedPARWeb = 'Opened PAR Web',
  ApplicationOpened = 'Application Opened',
  // Buttons
  ClickedButton = 'Clicked Button',
  BtnClickRules = 'Clicked Rules',
  ClickedRefreshLocations = 'Clicked Refresh Locations',
  ClickedDirections = 'Clicked Directions',
  // Rules
  SetupRuleWeb = 'Setup Rule Web',
  SetupRuleApp = 'Setup Rule App',
  SetupRuleTime = 'Setup Rule Time',
  SetupRuleCallsSMS = 'Setup Rule Calls and SMS',
  SetupRuleSocial = 'Setup Rule Social',
  SetupRuleLocation = 'Setup Rule Location',
  SetupRulePanicBtn = 'Setup Rule Panic Button',
  VideoSetupRules = 'Setup Rule Block Video Platforms',
  TimelineCardClick = 'Clicked Timeline Card',
  LocationItemClick = 'Clicked Location Card',
  OlderLocationsLoaded = 'Loaded Older Locations',
  // Setup Device
  SetupDevice = 'Setup Device',
  ClickedDeviceAssignToProfile = 'Clicked Device Assign To Profile',
  ClickedDeviceRemove = 'Clicked Device Remove',
  ClickedDeviceEdit = 'Clicked Device Edit',
  ClickedDeviceSelect = 'Clicked Device Select',
  ClickedAddDevice = 'Clicked Add Device',
  // Profile interactions
  ClickedAddProfile = 'Clicked Add Child',
  ClickedProfileEdit = 'Clicked Profile Edit',
  ClickedWebActionVisit = 'Clicked Card Action Visit',
  ClickedWebActionViewDetails = 'Clicked Card Action View Details',

  ClickedSafeNetworkViewDetails = 'Clicked Safe Network View Details',
  ProfileUpdateAttempt = 'Profile Update Attempt',
  // Sidebar navigation
  ClickedYourDevices = 'Clicked Your Devices',
  ClickedYourAcount = 'Clicked Your Acount',
  ClickedHelpCenter = 'Clicked Help Center',
  ClickedLogout = 'Clicked Logout',
  ClickedUpgradeToPremium = 'Clicked Upgrade To Premium',
  // Account settings
  AccountSettingsChanged = 'Account Settings Changed',
  // Details
  VideoSetupBlockVideo = 'Setup Rule Block Particular Video',
  ClickedReportedVideo = 'Clicked reported video',
  ClickedSeeAllButton = 'Clicked See all button',
  ClickedActivityTimelineLink = 'Clicked Activity Timeline Link',
  // Burger Menu
  OpenedBurgerMenu = 'Opened Burger Menu',
  // Family Locator
  MenuItemClick = 'Clicked Menu Option',
  ClickedPlaceUpdate = 'Clicked Save Place Changes',
  // Rate future feature
  ClickedRateApp = 'Clicked Rate App',
  ClickedTopBannerUpgradePremium = 'Clicked Top Banner Upgrade Premium',
  TryToOpenRateNativeModal = 'Try to open rate native modal',
  // Overlimit modal
  ClickedOverlimitBannerUpgrade = 'Clicked Overlimit Banner Upgrade',
  ClickedRemoveOverlimitProfileDialog = 'Clicked Remove Overlimit Profile Dialog',
  ClickedOverlimitProfileDialogUpgrade = 'Clicked Overlimit Profile Dialog Upgrade',
  // Purchases
  ClickedPurchaseButton = 'Clicked Purchase Button',
  PurchaseReceiptReceived = 'Purchase Receipt Received',
  PurchaseCancelled = 'Purchase Cancelled',
  PurchaseFailed = 'Purchase Failed',
  PurchaseSucceed = 'Purchase Succeed',
  PurchaseInitiated = 'Purchase Initiated',
  PurchaseRequested = 'Purchase Requested',
  // Two years promotion modal
  Closed2YearPromoDialog = 'Closed 2-Year Promo Dialog',
  ClickedBuy2YearPromo = 'Clicked Buy 2-Year Promo',
  ClickedBuy1YearPromo = 'Clicked Buy 1-Year Promo',
  // Walkthrough
  StartedWalkthrough = 'Started Walkthrough',
  FinishedWalkthrough = 'Finished Walkthrough',
  // Logged out leftside fetures
  ClickedLoggedOutUpgradePremium = 'Clicked Logged Out Upgrade Premium',
  // Wob
  OnboardingClickedCopyLink = 'Clicked Onboarding Copy Link',
  OnboardingClickedSocialShareFacebook = 'Clicked Onboarding Share Link via Facebook',
  OnboardingClickedSocialShareEmail = 'Clicked Onboarding Share Link via Email',
  ClosedOnboardingWelcomeModal = 'Closed Onboarding Welcome Modal',
  // Ad-hoc trial
  ClickedDismissReTrial = 'Clicked Dismiss Gift',
  ClickedAcceptedReTrial = 'Clicked Accept Gift',
  // Rating flyover
  DismissedRatingFlyover = 'Dismissed Rating Flyover',
  ClickedRatingFlyoverAction = 'Clicked Rating Flyover Action',
  // Cards
  SwipedActivityCard = 'Swiped Activity Card',
  ClickedInfoButtoninSummaryCard = 'Clicked Info Button in Summary Card',
  ClickedSetTimeLimits = 'Clicked Set Time Limits',
  // PASA wrong app installed
  ClickedWrongAppButton = 'Clicked Wrong App Button',
  // Extra time
  ClickedExtraTime = 'Clicked Extra Time',
  SetExtraTime = 'Set Extra Time',
  // Wob experiment
  ClickedOnboardingProtectDifferentDeviceLink = 'Clicked Onboarding Protect Different Device Link',
  // iOS Consent Tracking
  ClickedIosPreConsentTrackingFormButton = 'Clicked iOS PreConsent Tracking Form Button',
  ClickedIosNativeConsentTrackingFormButton = 'Clicked iOS Native Consent Tracking Form Button',
  KidDevicePlatformValueChanged = 'Selected Kid Device Platform',
  // Multi Parent functionality
  InvitationSent = 'Invitation Sent',
  RemovedPendingInvitation = 'Removed Pending Invitation',
  RemoveExpiredInvitation = 'Removed Expired Invitation',
  RemovedAdditionalParent = 'Removed Additional Parent',
  LeftAdditionalParentAccount = 'Left Additional Parent Account',
  // School bridge
  StudentUnlinked = 'Student Unlinked',
  PageDataLoaded = 'Page Data Loaded',
  // Wellbeing
  ClickedGetStartedFamilyTherapy = 'Clicked Get Started Family Therapy',
  ClickedSubscribeFamilyTherapy = 'Clicked To Subscribe Family Therapy',
}

export enum TimeLimitsRuleNames {
  ENABLE_SCHEDULE = 'enable_schedule',
  DISABLE_SCHEDULE = 'disable_schedule',
  TIME_SCHEDULE_CHANGE = 'time_schedule_change',
  TIME_LIMITS_CHANGE = 'time_limits_change',
  ENABLE_LOCK_NAVIGATION = 'enable_lock_navigation',
  DISABLE_LOCK_NAVIGATION = 'disable_lock_navigation',
  ENABLE_LOCK_DEVICE = 'enable_lock_device',
  DISABLE_LOCK_DEVICE = 'disable_lock_device',
  ENABLE_ALERT_ME = 'enable_alert_me',
  DISABLE_ALERT_ME = 'disable_alert_me',
  ENABLE_SCHEDULE_BY_DEVICE = 'enable_schedule_by_device',
  DISABLE_SCHEDULE_BY_DEVICE = 'disable_schedule_by_device',
}

export enum WebRuleNames {
  ENABLE_WEBSITE_CATEGORY_RESTRICTIONS = 'enable_website_category_restrictions',
  DISABLE_WEBSITE_CATEGORY_RESTRICTIONS = 'disable_website_category_restrictions',
  ALLOW_CATEGORY = 'allow_category',
  ALERT_CATEGORY = 'alert_category',
  BLOCK_CATEGORY = 'block_category',
  WEBSITE_EXCEPTION_CHANGE = 'website_exception_change',
  ALLOW_UNCATEGORIZED_WEBSITES = 'allow_uncategorized_websites',
  DISALLOW_UNCATEGORIZED_WEBSITES = 'disallow_uncategorized_websites',
  ENABLE_SAVE_SEARCH = 'enable_save_search',
  DISABLE_SAVE_SEARCH = 'disable_save_search',
  ENABLE_REPORT_BLOCKED_SITES_ALERT = 'enable_blocked_sites_alert',
  DISABLE_REPORT_BLOCKED_SITES_ALERT = 'disable_blocked_sites_alert',
  ENABLE_BLOCK_UNSUPPORTED_BROWSERS = 'enable_block_unsupported_browsers',
  DISABLE_BLOCK_UNSUPPORTED_BROWSERS = 'disable_block_unsupported_browsers',
  ENABLE_SEARCH_ALERTS = 'enable_search_alerts',
  DISABLE_SEARCH_ALERTS = 'disable_search_alerts',
  ENABLE_SEARCH_ALERTS_ATTEMPT = 'enable_search_alerts_attempt',
}

export enum CallsAndSMSRuleNames {
  ENABLE_CALLS_AND_SMS_MONITORING = 'enable_calls_and_sms_monitoring',
  DISABLE_CALLS_AND_SMS_MONITORING = 'disable_calls_and_sms_monitoring',
  ENABLE_REPORT_CONTENT = 'enable_report_content',
  DISABLE_REPORT_CONTENT = 'disable_report_content',
  ENABLE_BLOCK_ALL_INCOMING_CALLS = 'enable_block_all_incoming_calls',
  DISABLE_BLOCK_ALL_INCOMING_CALLS = 'disable_block_all_incoming_calls',
  ENABLE_BLOCK_ALL_OUTGOING_CALLS = 'enable_block_all_outgoing_calls',
  DISABLE_BLOCK_ALL_OUTGOING_CALLS = 'disable_block_all_outgoing_calls',
  INDIVIDUAL_PHONE_NUMBERS_CHANGE = 'individual_phone_numbers_change',
}

export enum SocialRuleNames {
  ENABLE_SOCIAL_MONITORING = 'enable_social_monitoring',
  DISABLE_SOCIAL_MONITORING = 'disable_social_monitoring',
}

export enum LocationRuleNames {
  ENABLE_LOCATION_MONITORING = 'enable_location_monitoring',
  DISABLE_LOCATION_MONITORING = 'disable_location_monitoring',
}

export enum PanicButtonRuleNames {
  ENABLE_PANIC_BUTTON = 'enable_panic_buttton',
  DISABLE_PANIC_BUTTON = 'disable_panic_button',
}

export enum AppRuleNames {
  EnableRestrictions = 'enable_restrictions',
  DisableRestrictions = 'disable_restrictions',
  AllowApp = 'enable_app_restrictions',
  BlockApp = 'disable_app_restrictions',
  EnableNewAppAlert = 'enable_new_app_alert',
  DisableNewAppAlert = 'disable_new_app_alert',
  SetAppTimeLimits = 'set_app_time_limits',
  ResetAppTimeLimits = 'reset_app_time_limits',
  BlockAppTimeLimits = 'block_app_time_limits',
  AlwaysAllowApp = 'always_allow_app',
  AlwaysBlockApp = 'always_block_app',
}

export enum UpgradeToPremiumRuleNames {
  EXPAND_PLAN = 'expand_plan',
  UPGRADE_PLAN = 'upgrade_plan',
}

export enum ProfileRuleNames {
  DailyTimeLimits = 'daily_time_limits',
  RestrictedTimes = 'restricted_times',
  Routines = 'routines',
  WebFiltering = 'web_filtering',
  GamsAndApps = 'games_and_apps',
  Location = 'location',
  CallsAndMessages = 'calls_and_messages',
  PanicButton = 'panic_button',
  Youtube = 'youtube',
}

/**
 * Page names used by generic events (like "Clicked Button")
 */
export enum GenericPageNames {
  Account = 'Account',
  StudentList = 'Student List',
  StudentSetup = 'Student Setup',
  MyFamily = 'My Family',
  DevicesList = 'Devices List',
  ChooseAProfile = 'Choose A Profile',
  ProfileSchoolActivity = 'Profile School Activity',
  ProfileSchoolActivityAllCategories = 'Profile School Activity All Categories',
  ProfileSchoolActivityAllAppsAndWebsites = 'Profile School Activity All Apps And Websites',
  ExpertInsightsDetails = 'Expert Insights Details',
  Profile = 'Profile',
  ProfileRules = 'Profile Rules',
  LeftMenu = 'Left Menu',
  ProfileSchoolTimeline = 'Profile School Timeline',
  ProfileSchoolTimelineDetails = 'Profile School Timeline details',
  RoutinesList = 'Routines List',
  ProfileUnifiedActivity = 'Profile Unified Activity',
  Timeline = 'Timeline',
  GamesAndAppsRules = 'Games & Apps Rules',
  WebFilteringRules = 'Web Filtering Rules',
  LocationRules = 'Location Rules',
  CallsAndMessagesRules = 'Calls and Messages Rules',
  YouTubeRules = 'YouTube Rules',
  PanicButtonRules = 'Panic Button Rules',
  FamilyLocator = 'Family Locator',
  WellbeingTherapy = 'Wellbeing Therapy',
  WellbeingActivities = 'Wellbeing Activities',
  WellbeingBanner = 'Wellbeing Banner',
}

/**
 * Button names used by generic events (like "Clicked Button")
 */
export enum ButtonNames {
  DeleteAccount = 'Delete Account',
  LinkNow = 'Link Now',
  Help = 'Help',
  Save = 'Save',
  Skip = 'Skip',
  AddAChild = 'Add A Child',
  ProtectADevice = 'Protect A Device',
  SelectExistingProfile = 'Select Existing Profile',
  AddNewChild = 'Add New Child',
  DateRange = 'Date Range',
  ContactSupport = 'Contact Support',
  PauseSchool = 'Pause School',
  DeletePauseSchool = 'Delete Pause School',
  StudentPauseScheduled = 'Schedule Pause School',
  EditRestrictedTimes = 'Edit restricted times',
  AddExtraTime = 'Add extra time',
  EditDailyTimeLimits = 'Edit daily time limits',
  EditExtraTime = 'Edit extra time',
  DeletePauseInternet = 'Delete Pause Internet',
  PauseInternet = 'Pause Internet',
  EditPauseInternet = 'Edit Pause Internet',
  SchedulePauseInternet = 'Schedule Pause Internet',
  SafeNetworkToggle = 'Safe Network',
  StudentTimelineNavigation = 'Student Timeline',
  StudentSummaryNavigation = 'Student Summary',
  StudentTimelineFilter = 'Student Timeline Filter',
  StudentTimelineActionSignature = 'Student Timeline Action Expert Insight',
  StudentTimelineActionVisitUrl = 'Student Timeline Action Visit Url',
  StudentTimelineActionViewDetails = 'Student Timeline Action View Details',
  StudentTimelineActionWatchVideo = 'Student Timeline Action Watch Video',
  Rule = 'Rule',
  ViewRoutine = 'View Routine',
  AddARoutine = 'Add a Routine',
  SaveNewRoutine = 'Save New Routine',
  CalendarSlot = 'Calendar slot',
  ChangeRoutinesListView = 'Change routines list view',
  seeAllScreenTime = 'See all screen time',
  seeAllAlertedWebsites = 'See all alerted websites',
  seeAllStudentCategories = 'See all student categories',
  seeAllStudentSignatures = 'See all student signatures',
  toggleProfileDelegation = 'Toggle profile delegation',
  CloseSummaryAlertsBanner = 'Close alerts banner',
  ReviewAlertsBanner = 'Review alerts banner',
  ChangeFilter = 'Change selected filter',
  Upgrade = 'Upgrade',
  Wellbeing = 'Wellbeing',
  UndoSwitchRoutine = 'Undo Switch routine',
  ScheduleSwitchRoutine = 'Schedule Switch routine',
  SwitchRoutine = 'Switch routine',
  SeeHowItWorks = 'See how it works',
  FindOutMore = 'Find out more',
  SubscribeNow = 'Subscribe now',
  Close = 'Close',
  DiscoverMore = 'Discover More',
  StartManualTrial = 'Start manual trial',
}

/**
 * Viewed Page's names
 */
export enum PageNames {
  ProfileActivity = 'Viewed Profile Activity',
  FamilyLocator = 'Viewed Family Locator',
  FamilyLocatorKidLocation = 'Viewed Kid Location Timeline',
  FamilyLocatorPlacesList = 'Viewed Places',
  FamilyLocatorReportedLocation = 'Viewed Reported Location',
  FamilyLocatorAddPlace = 'Viewed Add Place',
  FamilyLocatorEditPlace = 'Viewed Place Details',
  YoutubeActivitySummary = 'Viewed Video Summary',
  FamilyLocatorPremiumFlyover = 'Viewed Family Locator Conversion',
  Upgrade = 'Viewed Upgrade Page',
  TwoYearPromotionDialog = 'Viewed 2-Year Promo Dialog',
  Walkthrough = 'Viewed Walkthrough',
  GiftModal = 'Viewed Gift Modal',
  RatingFlyover = 'Viewed Rating Flyover',
  // WOB Flow
  OnboardingWelcomeFlyoverActivity = 'Viewed Onboarding Welcome Flyover',
  OnBoardingIntroActivity = 'Viewed Onboarding Intro Kid',
  OnboardingAddKidActivity = 'Viewed Onboarding Add Kid',
  OnboardingKidProfileCompletedActivity = 'Viewed Onboarding Kid Profile Completed',
  OnboardingSelectDeviceActivity = 'Viewed Onboarding Select Device',
  OnboardingSelectDeviceModal = 'Viewed Onboarding Select Device Modal',
  OnboardingSetupRemoteDeviceActivity = 'Viewed Onboarding Protect Different Device',
  OnboardingCompleteActivity = 'Viewed Onboarding Complete',
  // Is Current Device
  OnboardingDownloadProtectionCurrentDevice = 'Viewed Onboarding Download Page',
  OnboardingWaitingProtectionCurrentDevice = 'Viewed Onboarding Waiting Protection Current Device',
  // Is not Current Device
  OnboardingDownloadProtectionOtherDevice = 'Viewed Onboarding Link Protection Other Device',
  OnboardingWaitingProtectionOtherDevice = 'Viewed Onboarding Waiting Protection Other Device',
  OnboardingSendLinkByEmail = 'Viewed Onboarding Send Link By Email',
  OnboardingLinkSentConfirmation = 'Viewed Onboarding Link Sent',

  OnboardingProtectionConfirmedActivity = 'Viewed Onboarding Protection Confirmed',
  // PASA wrong app installed
  ParentsAppStandaloneWrongAppInstalled = 'Viewed Wrong App',
  KidDeviceInstallInstructions = 'Viewed Kid Device Install Instructions',
  // School Bridge
  StudentList = 'Viewed Student List',
  StudentSetup = 'Viewed Student Setup',
  MyFamily = 'Viewed My Family',
  ChooseAProfile = 'Viewed Choose A Profile',
  ProfileSchoolActivity = 'Viewed Profile School Activity',
  ProfileSchoolActivityAllCategories = 'Viewed Profile School Activity All Categories',
  ProfileSchoolActivityAllAppsAndWebsites = 'Viewed Profile School Activity All Apps And Websites',
  ExpertInsightsDetails = 'Viewed Expert Insights Details',
  ProfileUnifiedActivity = 'Viewed Profile Unified Activity',
  ProfileScreenTimeDetails = 'Viewed Profile Screen time details',
}

export enum SetupVideoRuleNames {
  EnableAndroidApp = 'android_app_enabled',
  DisableAndroidApp = 'android_app_disabled',
  EnableIosApp = 'ios_app_enabled',
  DisableIosApp = 'ios_app_disabled',
  EnableWeb = 'web_enabled',
  DisableWeb = 'web_disabled',
}

export enum SettingsToggleType {
  AndroidSettingsBlocker = 'AndroidSettingsBlocker',
  AndroidQustodioConfiguration = 'AndroidQustodioConfiguration',
  IosVpnConfiguration = 'IosVpnConfiguration',
  DesktopHideQustodioConfiguration = 'DesktopHideQustodioConfiguration',
}

export enum AccountSettingsToggleType {
  DailySummaryReports = SettingsToggleNames.IsDailyReport,
  WeeklySummaryReports = SettingsToggleNames.IsWeeklyReport,
}

export enum ClickedRateAppScreens {
  timeline = 'timeline',
  rules = 'rules',
}

export const ToggleTypeToProperty = {
  [SettingsToggleNames.IsDailyReport]: 'daily_summary',
  [SettingsToggleNames.IsWeeklyReport]: 'weekly_summary',
  [SettingsToggleNames.IsLockScreenEnabled]: 'lock_screen_enabled',
};

export const AccountToggles = {
  WeeklySummaryReports: {
    enabled: 'enable_weekly_summary',
    disabled: 'disable_weekly_summary',
  },
  DailySummaryReports: {
    enabled: 'enable_daily_summary',
    disabled: 'disable_daily_summary',
  },
};

export const DeviceToggles = {
  AndroidSettingsBlocker: {
    enabled: 'enable_android_settings_blocker',
    disabled: 'disable_android_settings_blocker',
  },
  AndroidQustodioConfiguration: {
    enabled: 'enable_qustodio_settings',
    disabled: 'disable_qustodio_settings',
  },
  IosVpnConfiguration: {
    enabled: 'enable_vpn',
    disabled: 'disable_vpn',
  },
  DesktopHideQustodioConfiguration: {
    enabled: 'hide_qustodio',
    disabled: 'show_qustodio',
  },
};

export enum VideoDetailAction {
  Unknown = 'unknown',
  Unblocked = 'unblocked',
  Blocked = 'blocked',
}

export enum FamilyLocatorViews {
  FamilyLocator = 'family',
  KidLocations = 'kid',
}

export enum OnboardingPageVersion {
  original = 'original',
  experiment = 'android_onboarding_experiment',
}

export enum FeatureBlockingContexts {
  PartialBlockModal = 'partial_block_modal',
  FullBlockedFeature = 'full_blocked_feature',
}

export enum PartialBlockModalReason {
  GamesAndAppsSetLimits = 'games_and_apps_set_limits',
  GamesAndAppsAlertMe = 'games_and_apps_alert_me',
  WebFilteringBlockedSiteNotifications = 'web_filtering_blocked_site_notifications',
  WebFilteringAlertMeCategory = 'web_filtering_alert_me_category',
  WebFilteringAlertMeException = 'web_filtering_alert_me_exception',
}

export const identify = safeFn((userUid: string) => {
  if (isSegmentAvailable()) {
    window.analytics.identify(userUid);
  }
});

export const page = safeFn(
  (name: PageNames, properties: { [key: string]: unknown } = {}) => {
    if (isSegmentAvailable()) {
      window.analytics.page(name, {
        ...appGenerics(),
        ...properties,
      });
    }
  }
);

export const track = safeFn((name: Events, properties?: any) => {
  if (isSegmentAvailable()) {
    window.analytics.track(name, {
      ...appGenerics(),
      ...properties,
    });
  }
});

/**
 * Generic tracking for button clicks
 */
export const trackButtonClicked = (
  page: GenericPageNames,
  button: ButtonNames,
  extraProperties?: Record<string, unknown>
) =>
  track(Events.ClickedButton, {
    page,
    button,
    ...extraProperties,
  });

export const trackPageLoaded = (
  page: GenericPageNames,
  extraProperties?: Record<string, unknown>
) =>
  track(Events.PageDataLoaded, {
    page,
    ...extraProperties,
  });

export enum EventTypeEnum {
  website = 'website',
  call = 'call',
  sms = 'sms',
  location = 'location',
  panic = 'panicbtn',
  search = 'search',
  facebook = 'facebook',
  generic = 'generic',
  twitter = 'twitter',
  app = 'app',
  youtube = 'youtube',
}

const getEventType = (event: EventRecord): EventTypeEnum => {
  switch (event.type) {
    case EventType.App:
      return EventTypeEnum.app;
    case EventType.Youtube:
      return EventTypeEnum.youtube;
    case EventType.Web:
      return EventTypeEnum.website;
    case EventType.CallsIncoming:
    case EventType.CallsOutgoing:
    case EventType.CallsMissed:
    case EventType.CallsUnanswered:
    case EventType.CallsBlockedIncoming:
    case EventType.CallsBlockedOutgoing:
      return EventTypeEnum.call;
    case EventType.SmsIncoming:
    case EventType.SmsOutgoing:
    case EventType.SmsBlockedIncoming:
    case EventType.SmsBlockedOutgoing:
      return EventTypeEnum.sms;
    case EventType.Location:
    case EventType.LocationUnvailable:
    case EventType.LocationLast:
      return EventTypeEnum.location;
    case EventType.Panic:
      return EventTypeEnum.panic;
    case EventType.Search:
      return EventTypeEnum.search;
    case EventType.FacebookSocial:
    case EventType.FacebookSocialContact:
    case EventType.FacebookSocialNetwork:
    case EventType.FacebookSocialContactNew:
    case EventType.FacebookSocialActivity:
    case EventType.FacebookSocialActivityPost:
    case EventType.FacebookSocialActivityUserPost:
    case EventType.FacebookSocialActivityUserProfile:
    case EventType.FacebookSocialActivityUserComment:
    case EventType.FacebookSocialActivityUserLike:
    case EventType.FacebookSocialActivityUserEvent:
    case EventType.FacebookSocialActivityContactPost:
    case EventType.FacebookSocialActivityContactComment:
    case EventType.FacebookSocialActivityContactLike:
    case EventType.FacebookSocialActivityUserContactPost:
    case EventType.FacebookSocialActivityUserLink:
    case EventType.FacebookSocialActivityUserSharePhoto:
    case EventType.FacebookSocialMedia:
    case EventType.FacebookSocialMediaUserPhoto:
    case EventType.FacebookSocialMediaContactPhoto:
      return EventTypeEnum.facebook;
    case EventType.Twitter:
      return EventTypeEnum.twitter;
    default:
      return EventTypeEnum.generic;
  }
};

export enum TrackOrigin {
  PN = 'PN',
  EMAIL = 'EMAIL',
  PAR = 'PAR',
}

export const trackApplicationOpened = ({
  fromBackground = false,
}: {
  fromBackground: boolean;
}) => {
  track(Events.ApplicationOpened, {
    from_background: fromBackground,
    ...deviceGenerics(),
  });
};

export const trackWebOpened = () => {
  track(Events.OpenedPARWeb, {
    ...deviceGenerics(),
  });
};

export const youtubeActivitySummaryPageTrackerProps = (
  router: RouterProps,
  props: Record<string, any>
) => ({
  origin: router.location.query.origin || TrackOrigin.PAR,
  userId: props.accountId,
  type: EventTypeEnum.youtube,
});

export const notAvailableMsg = 'not available';

export const trackWebRules = (ruleName: WebRuleNames) =>
  track(Events.SetupRuleWeb, { rule_name: ruleName });

export const trackTimeLimitsRule = (ruleName: TimeLimitsRuleNames): void => {
  track(Events.SetupRuleTime, { rule_name: ruleName });
};

export const trackTimeRulesChange = debounce(ruleName => {
  trackTimeLimitsRule(ruleName);
}, UPDATE_PROFILE_RULES_DELAY);

export const trackCallsAndSMSRule = (ruleName: CallsAndSMSRuleNames): void => {
  track(Events.SetupRuleCallsSMS, { rule_name: ruleName });
};

export const trackSocialRules = (ruleName: SocialRuleNames) =>
  track(Events.SetupRuleSocial, { rule_name: ruleName });

export const trackLocationRule = (ruleName: LocationRuleNames): void => {
  track(Events.SetupRuleLocation, { rule_name: ruleName });
};

export const trackPanicButtonRule = (ruleName: PanicButtonRuleNames): void => {
  track(Events.SetupRulePanicBtn, { rule_name: ruleName });
};

export const trackSetupVideoRule = (
  ruleName: SetupVideoRuleNames,
  source: VideoSources = VideoSources.Unknown
): void => {
  track(Events.VideoSetupRules, {
    rule_name: ruleName,
    video_platform: source,
  });
};

export const trackTimelineCardClick = (event: EventRecord): void => {
  track(Events.TimelineCardClick, { type: getEventType(event) });
};
export const trackLocationItemClick = (event: EventRecord): void => {
  track(Events.LocationItemClick, { type: getEventType(event) });
};
export const trackAppRuleUpdate = (ruleName: AppRuleNames) =>
  track(Events.SetupRuleApp, {
    rule_name: ruleName,
    screen: 'Activity Timeline',
  });

export const trackUpgradeToPremium = (
  upgradeType: UpgradeToPremiumRuleNames,
  properties: {} = {}
): void =>
  track(Events.ClickedUpgradeToPremium, {
    ...properties,
    upgrade_type: upgradeType,
  });

export const trackDeviceSettingUpdate = (
  toggleType: SettingsToggleType,
  isEnabled: boolean
) =>
  track(Events.SetupDevice, {
    setting_name: isEnabled
      ? DeviceToggles[toggleType].enabled
      : DeviceToggles[toggleType].disabled,
  });

export const trackAccountSettingUpdate = (
  toggleType: SettingsToggleType | SettingsToggleNames,
  isEnabled: boolean
) =>
  track(Events.AccountSettingsChanged, {
    [ToggleTypeToProperty[toggleType]]: isEnabled ? 'yes' : 'no',
  });

export const trackClickedReportedVideo = (
  url: string = notAvailableMsg,
  title: string = notAvailableMsg,
  channel: string = notAvailableMsg,
  platform: string = notAvailableMsg
) => {
  track(Events.ClickedReportedVideo, {
    url,
    title,
    channel,
    video_platform: platform,
  });
};

export const trackVideoSetupBlockVideo = (
  url: string = notAvailableMsg,
  title: string = notAvailableMsg,
  category: string = notAvailableMsg,
  status: VideoDetailAction = VideoDetailAction.Unknown,
  platform: VideoSources = VideoSources.Unknown
) => {
  track(Events.VideoSetupBlockVideo, {
    url,
    title,
    category,
    status,
    video_platform: platform,
  });
};

export const trackAppRules = (ruleName: AppRuleNames) =>
  track(Events.SetupRuleApp, { rule_name: ruleName });

export const trackOlderLocationsLoaded = (): void => {
  track(Events.OlderLocationsLoaded);
};

export const trackClickedRefreshLocations = (
  view: FamilyLocatorViews
): void => {
  track(Events.ClickedRefreshLocations, { view });
};

export const trackClickedDirections = (view: FamilyLocatorViews): void => {
  track(Events.ClickedDirections, { view });
};

export const trackOpenedBurgerMenu = (): void => {
  track(Events.OpenedBurgerMenu);
};

export const trackFamilyLocatorClick = (): void =>
  trackMenuItemClick(PathTitles.familyLocator);

const trackMenuItemClick = (menuItem: PathTitles): void =>
  track(Events.MenuItemClick, { option: menuItem });

export const trackClickedRateApp = (
  profileUid: string,
  appName: string,
  appExe: string,
  appPlatform: string,
  screen: ClickedRateAppScreens
): void => {
  track(Events.ClickedRateApp, {
    screen,
    profile_uid: profileUid,
    app_name: appName,
    app_exe: appExe,
    app_platform: appPlatform,
  });
};

export const trackPlaceUpdate = (changedFields): void => {
  track(Events.ClickedPlaceUpdate, {
    update_place: Object.values(changedFields).filter(Boolean).length > 0,
    ...changedFields,
  });
};

export const trackClickedPurchaseButton = (
  provider: PaymentProvider,
  productCode: string
) => {
  track(Events.ClickedPurchaseButton, { provider, product_code: productCode });
};

export const trackPurchaseInitiated = (
  provider: PaymentProvider,
  productCode: string
) => {
  track(Events.PurchaseInitiated, {
    provider,
    product_code: productCode,
  });
};

export const trackPurchaseReceiptReceived = (
  provider: PaymentProvider,
  productCode: string
) => {
  track(Events.PurchaseReceiptReceived, {
    provider,
    product_code: productCode,
  });
};

export const trackPurchaseCancelled = (provider: PaymentProvider) => {
  track(Events.PurchaseCancelled, { provider });
};

export const trackPurchaseFailed = (provider: PaymentProvider) => {
  track(Events.PurchaseFailed, { provider });
};

export const trackPurchaseSucceed = (
  provider: PaymentProvider,
  productCode: string
) => {
  track(Events.PurchaseSucceed, { provider, product_code: productCode });
};

export const trackTwoYearsPromotionModal = (
  eventName: string,
  product: ProductRecord
) =>
  track(eventName, {
    plan: productDurationToString(product.duration),
    max_devices: product.maxDevices,
    product_code: product.code,
  });

const productDurationToString = (plan: ProductDuration) => {
  switch (plan) {
    case ProductDuration.OneYear:
      return '1-year';
    case ProductDuration.TwoYears:
      return '2-years';
    case ProductDuration.OneMonth:
      return '1-month';
    default:
      throw Error(`Invalid product duration: ${plan}`);
  }
};

export const trackViewedWalkthrough = (step: string) => {
  track(PageNames.Walkthrough, { step });
};

export const trackSuccessfulProfileEditAttempt = (
  oldValues: ProfileFormRecord,
  newValues: ProfileFormRecord
) => {
  trackProfileEditAttempt(oldValues, newValues, true);
};

export const trackFailedProfileEditAttempt = (
  oldValues: ProfileFormRecord,
  newValues: ProfileFormRecord
) => {
  trackProfileEditAttempt(oldValues, newValues, false);
};

export const trackProfileEditAttempt = (
  oldValues: ProfileFormRecord,
  newValues: ProfileFormRecord,
  wasSuccessful: boolean
) =>
  track(Events.ProfileUpdateAttempt, {
    attempt_successful: wasSuccessful,
    name_was_updated: oldValues.name !== newValues.name,
    gender_was_updated: oldValues.gender !== newValues.gender,
    birth_date_was_updated: oldValues.birthDate !== newValues.birthDate,
    avatar_was_updated: oldValues.picture !== newValues.picture,
  });

export const trackSetUpRuleName = (
  ruleName: AppRuleNames,
  appName?: string,
  platform?: string,
  quota?: string,
  dayOfTheWeek?: string
) =>
  track(Events.SetupRuleApp, {
    rule_name: ruleName,
    app_name: appName,
    app_platform: platform,
    quota,
    day_of_the_week: dayOfTheWeek,
  });

export const trackDeleteAccountButtonClicked = (page: GenericPageNames) =>
  trackButtonClicked(page, ButtonNames.DeleteAccount);

export const trackStudentUnlinked = (
  page: GenericPageNames,
  profile: ProfileRecord
) =>
  track(Events.StudentUnlinked, {
    screen: page,
    profile_id: profile.id,
  });

export const format = (data: {
  [key: string]: unknown;
  options: {
    [key: string]: unknown;
  };
}): {
  [key: string]: unknown;
  options: string;
} =>
  evolve(
    {
      options: pipe(decamelizeKeysDeep, JSON.stringify),
    },
    data
  );

export const trackSaveNewRoutine = (
  routineUid: string,
  tempRoutineUid: string,
  mode: RoutineCreationMode | null
) =>
  trackButtonClicked(
    GenericPageNames.RoutinesList,
    ButtonNames.SaveNewRoutine,
    {
      options: JSON.stringify({
        routine_uid: routineUid,
        temp_routine_uid: tempRoutineUid,
        based_on: mode || 'custom',
      }),
    }
  );

type MyFamilyTopbarActionTrackReasonType =
  | 'trial'
  | 'free'
  | 'overlimit_devices'
  | 'overlimit_profiles'
  | 'overlimit_profiles_and_devices'
  | 'premium_expires';

export const trackMyFamilyTopbarAction = ({
  reason,
}: {
  reason: MyFamilyTopbarActionTrackReasonType;
}) => {
  trackButtonClicked(GenericPageNames.MyFamily, ButtonNames.Upgrade, {
    options: JSON.stringify({
      context: 'topbar',
      reason,
    }),
  });
};

export const trackUpgradeBlockedFeature = (
  page: GenericPageNames,
  context: FeatureBlockingContexts,
  reason: PartialBlockModalReason | ProfileRuleNames | 'family_locator'
) => {
  trackButtonClicked(page, ButtonNames.Upgrade, {
    options: JSON.stringify({
      context,
      reason,
    }),
  });
};

export const trackProtectADeviceBlockedFeature = (
  page: GenericPageNames,
  reason: ProfileRuleNames
) => {
  trackButtonClicked(page, ButtonNames.ProtectADevice, {
    options: JSON.stringify({
      context: FeatureBlockingContexts.FullBlockedFeature,
      reason,
    }),
  });
};
