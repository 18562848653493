import * as React from 'react';
import type { TotalScreenTimeWidgetProps } from '../../containers/TotalScreenTimeContainer/TotalScreenTimeWidgetContainer';
import MiniActivitySummary from '../Dashboard/DashboardTopCards/MiniActivitySummary/MiniActivitySummary';
import { t } from '../../lib/i18n';
import { IconType } from 'styleguide-react';
import { minutesToHMFormat } from '../../helpers/dates';

const TotalScreenTimeWidget = ({
  screenTimeSpent,
  isFetchingInfo,
  hasError,
  loadWidget,
  navigateToDetails,
}: TotalScreenTimeWidgetProps) => {
  React.useEffect(() => {
    loadWidget();
  }, []);

  if (isFetchingInfo) {
    return (
      <MiniActivitySummary type="loading" testId="total-screen-time-widget" />
    );
  }

  if (hasError) {
    return (
      <MiniActivitySummary
        title={t("Screen time couldn't load")}
        type="error"
        testId="total-screen-time-widget"
      />
    );
  }

  return (
    <MiniActivitySummary
      title={t('Total screen time')}
      value={minutesToHMFormat(screenTimeSpent)}
      iconType={IconType.clock}
      onClick={navigateToDetails}
      type="info"
      testId="total-screen-time-widget"
    />
  );
};

export default TotalScreenTimeWidget;
