import * as React from 'react';
import { connect } from 'react-redux';
import { FlexLayout, Label, RadioButton } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacing } from 'styleguide-react/build/components/Layout/types';
import { t } from '../../lib/i18n';
import {
  getSelectedProductDuration,
  getProductsDiscount,
} from '../../selectors';
import { classNames } from '../../helpers';
import { ProductDuration } from '../../records/product';
import { productDurationChanged } from '../../ducks/products/actions';

interface Props {
  selectedProductDuration: ProductDuration;
  productsDiscount: number;
  onSelectedProductDurationChange: (productDuration: ProductDuration) => any;
}

const ProductDurationPicker: React.FunctionComponent<Props> = props => (
  <FlexLayout mainaxis="row" mainaxisAlignment="center">
    <ProductDurationPickerRadioButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      productDuration={ProductDuration.OneYear}
      marginRight="8"
    />
    <ProductDurationPickerRadioButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      productDuration={ProductDuration.TwoYears}
      marginRight="0"
    />
  </FlexLayout>
);

type ProductDurationPickerRadioButtonProps = Props & {
  productDuration: ProductDuration;
  marginRight: LayoutSpacing;
};

const ProductDurationPickerRadioButton: React.FunctionComponent<
  ProductDurationPickerRadioButtonProps
> = ({
  productDuration,
  selectedProductDuration,
  productsDiscount,
  onSelectedProductDurationChange,
  marginRight,
}: ProductDurationPickerRadioButtonProps) => {
  const radioButtonId = `show-${productDuration}-days-products`;
  const isProductDurationTwoYears =
    productDuration === ProductDuration.TwoYears;
  let labelText = t('1 year');
  if (isProductDurationTwoYears) {
    labelText = productsDiscount
      ? t('2 years - Save {{discount}}%!', {
          discount: productsDiscount,
        })
      : t('2 years');
  }
  const splitText = labelText.split('-');

  return (
    <FlexLayout
      mainaxis="row"
      crossaxisAlignment="center"
      marginRight={marginRight}
    >
      <FlexLayout mainaxis="column" marginRight="8">
        <RadioButton
          id={radioButtonId}
          testId={radioButtonId}
          value={productDuration.toString()}
          checked={productDuration === selectedProductDuration}
          onChange={() => onSelectedProductDurationChange(productDuration)}
        />
      </FlexLayout>
      <Label
        className={classNames(
          'ProductDurationPicker__radio-button-label',
          isProductDurationTwoYears && productsDiscount
            ? 'ProductDurationPicker__radio-button-label--highlighted'
            : ''
        )}
        htmlFor={radioButtonId}
      >
        <span className="ProductDurationPicker__radio-button-label--black">
          {splitText[0]}
        </span>
        {splitText[1] && ` - ${splitText[1]}`}
      </Label>
    </FlexLayout>
  );
};

const mapStateToProps = state => ({
  selectedProductDuration: getSelectedProductDuration(state),
  productsDiscount: getProductsDiscount(state),
});

const mapDispatchToProps = dispatch => ({
  onSelectedProductDurationChange: (productDuration: ProductDuration) =>
    dispatch(productDurationChanged(productDuration)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDurationPicker);
