import * as moment from 'moment';
import api from '../../api';
import { API_TIMESTAMP_FORMAT } from '../../helpers/dates';
import { executeIfAllFlags } from '../../helpers/flagInterceptors';
import {
  VideoDetailsRecord,
  getUTCDateFromVideoKey,
} from '../../records/urlDetails';
import {
  getProfileOrDefault,
  getSelectedDateRange,
  getYoutubeSummaryDetailsLastVideos,
} from '../../selectors';
import flags from '../../sideEffects/flags';
import {
  fetchYoutubeSummaryDetailLastVideosFailed,
  fetchYoutubeSummaryFailed,
  fetchingYoutubeSummary,
  fetchingYoutubeSummaryDetailsLastVideos,
  receiveMoreYoutubeSummaryDetailsLastVideos,
  receiveYoutubeSummary,
  receiveYoutubeSummaryDetailsLastVideos,
} from './actions';
import { showErrorToast } from '../../containers/AccountSettings/helpers';
import { createUniqueResponseObjectWithSummaryJsons } from '../../records/youtubeSummary';
import { trackClickedReportedVideo } from '../../helpers/analytics';
import { gaEvent } from '../../helpers/ga';
import { navigateToExternalUrl as navToExternalUrl } from '../../actions/Navigation';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

export const fetchMoreYoutubeSummaryDetailLastVideos = executeIfAllFlags(
  [flags.youtubeSummary],
  () => Promise.resolve()
)(profileId => (dispatch, getState) => {
  const state = getState();
  const profile = getProfileOrDefault(getState(), parseInt(profileId, 10));
  const last = getYoutubeSummaryDetailsLastVideos(state).last();
  if (!last) {
    return;
  }
  dispatch(fetchingYoutubeSummaryDetailsLastVideos());
  const { minDate } = getSelectedDateRange(state);
  const maxDate = getUTCDateFromVideoKey(last).format(API_TIMESTAMP_FORMAT);
  // eslint-disable-next-line consistent-return
  return api.youtubeSummaryLastVideos
    .get({
      profileUid: profile.uid,
      maxDate,
      minDate: moment(minDate).format(API_TIMESTAMP_FORMAT),
      limit: 20,
    })
    .then(json => {
      dispatch(receiveMoreYoutubeSummaryDetailsLastVideos(json));
    })
    .catch(err => {
      dispatch(fetchYoutubeSummaryDetailLastVideosFailed());
      dispatch(showErrorToast(err));
    });
});

export const fetchYoutubeSummaryDetailLastVideos = executeIfAllFlags(
  [flags.youtubeSummary],
  () => Promise.resolve()
)(profileUid => (dispatch, getState) => {
  dispatch(fetchingYoutubeSummaryDetailsLastVideos());
  const { maxDate, minDate } = getSelectedDateRange(getState());
  return api.youtubeSummaryLastVideos
    .get({
      profileUid,
      minDate,
      maxDate,
      limit: 20,
    })
    .then(json => {
      dispatch(receiveYoutubeSummaryDetailsLastVideos(json));
    })
    .catch(err => {
      dispatch(fetchYoutubeSummaryDetailLastVideosFailed());
      dispatch(showErrorToast(err));
    });
});

export const fetchYoutubeSummary = executeIfAllFlags(
  [flags.youtubeSummary],
  () => Promise.resolve()
)(profileUid => (dispatch, getState) => {
  dispatch(fetchingYoutubeSummary());
  const state = getState();
  const { maxDate, minDate } = getSelectedDateRange(state);
  return Promise.all([
    api.youtubeSummaryTotalVideos.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
    api.youtubeSummaryTotalSearches.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
    api.youtubeSummaryLastVideoSearches.withCache(ttl1Minute).get({
      profileUid,
      minDate,
      maxDate,
      limit: 5,
    }),
  ])
    .then(jsons => {
      dispatch(
        receiveYoutubeSummary(createUniqueResponseObjectWithSummaryJsons(jsons))
      );
    })
    .catch(err => {
      dispatch(fetchYoutubeSummaryFailed());
      dispatch(showErrorToast(err));
    });
});

export const navigateToExternalUrl =
  (video: VideoDetailsRecord) => dispatch => {
    trackClickedReportedVideo(
      video.url,
      video.title,
      video.channelTitle,
      video.source
    );
    gaEvent('summary-youtube-activity-page', 'url-path', 'click');
    dispatch(navToExternalUrl(video.searchUrl || video.url));
  };
