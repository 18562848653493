import { connect } from 'react-redux';
import { isBrowserPlatform } from '../../helpers/index';
import { goBackIfHistory } from '../../ducks/routing';
import { Dispatch } from '../../store/state';
import LogoutModal from '../../components/Modal/LogoutModal';
import { logout } from '../../sideEffects/logout';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch(logout()),
  onClickClose: () => dispatch(goBackIfHistory()),
});

const LogoutModalContainer = connect(null, mapDispatchToProps)(LogoutModal);

LogoutModalContainer.load = () => (dispatch: Dispatch) => {
  if (isBrowserPlatform()) {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  }
};

export default LogoutModalContainer;
