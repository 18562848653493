import * as React from 'react';
import {
  AvatarSize,
  FlexLayout,
  FreeText,
  Icon,
  IconType,
  Layout,
  List,
  RegularStyleListItem,
  Switch,
  TransparentButton,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import type { SafeNetworksSettingsProps } from '../../containers/AccountSettings/SafeNetworksSettingsContainer';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import Loader from '../base/Loader';

const SafeNetworkSettings: React.FC<SafeNetworksSettingsProps> = ({
  accountSafeNetworkSettings,
  isLoading,
  loadProfilesSafeNetworkSettings,
  toggleProfileSafeNetworkProtection,
  openInfoModal,
}: SafeNetworksSettingsProps) => {
  React.useEffect(() => {
    loadProfilesSafeNetworkSettings();
  }, []);
  const showList = !isLoading;
  return (
    <React.Fragment>
      <FlexLayout mainaxis="row" marginBottom="24">
        <FreeText fontSize="16px" fontWeight="semi-bold">
          {t('School Safe Networks')}
        </FreeText>
        <Layout renderAs="span" marginLeft="8">
          <TransparentButton
            onClick={openInfoModal}
            fillAvailableSpace
            testId="safe-networks-settings__Button--info"
          >
            <Icon
              type={IconType.infoCircle}
              testId="safe-networks-settings-info"
              color="secondary"
              size="lg"
            />
          </TransparentButton>
        </Layout>
      </FlexLayout>
      <FlexLayout mainaxis="row" marginBottom="24">
        <FreeText fontSize="16px">
          {t(
            'Improve security and connectivity on protected devices while on school Wi-Fi.'
          )}
        </FreeText>
      </FlexLayout>
      {isLoading && (
        <FlexLayout
          mainaxis="column"
          marginTop="8"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          height="56"
          testId="loader-container"
        >
          <Loader size="small" />
        </FlexLayout>
      )}
      {showList && (
        <List
          listItemMarginBottom="8"
          listItemPaddingTop="8"
          listItemPaddingBottom="8"
        >
          {accountSafeNetworkSettings.map(profileSafeNetworkData => (
            <RegularStyleListItem
              key={profileSafeNetworkData.profile.uid}
              testId="safe-networks-settings-list-item"
              title={profileSafeNetworkData.profile.name}
              icon={
                <ProfileAvatar
                  size={AvatarSize.extraExtraSmall}
                  profile={profileSafeNetworkData.profile}
                />
              }
              actionElement={
                <Switch
                  testId="safe-networks-settings-switch"
                  id={profileSafeNetworkData.profile.uid}
                  checked={profileSafeNetworkData.safeNetworkActive}
                  onClick={ev => {
                    toggleProfileSafeNetworkProtection(
                      profileSafeNetworkData.profile.uid,
                      (ev.target as HTMLInputElement).checked
                    );
                  }}
                />
              }
            />
          ))}
        </List>
      )}
    </React.Fragment>
  );
};

export default SafeNetworkSettings;
