import * as React from 'react';
import {
  FreeText,
  Icon,
  IconColor,
  IconSize,
  IconType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { classNames } from '../../../helpers';
import Loader from '../../base/Loader';

interface AddCircleButtonProps {
  disabled?: boolean;
  showLoader?: boolean;
  testId?: string;
  onAddExceptions: () => void;
}

const AddCircleButton = ({
  disabled = false,
  showLoader = false,
  testId,
  onAddExceptions,
}: AddCircleButtonProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classNames(
      'par-routines-add-circle-button',
      disabled ? 'par-routines-add-circle-button--disabled' : ''
    )}
    onClick={!disabled ? onAddExceptions : undefined}
    data-testid={testId}
  >
    <div className="par-routines-add-circle-button__container">
      <div className="par-routines-add-circle-button__icon">
        {showLoader ? (
          <Loader size="xxs" color="secondary" />
        ) : (
          <Icon
            testId={testId}
            type={IconType.plus}
            size={IconSize.regular}
            color={IconColor.secondary}
          />
        )}
      </div>

      <div>
        <FreeText className="par-routines-add-circle-button__text">
          {t('Add')}
        </FreeText>
      </div>
    </div>
  </div>
);

export default AddCircleButton;
