import { goBackIfHistory } from '../ducks/routing';
import { setLastRuleTypeModified, RuleType } from '../ducks/appRate';
import { toggleYoutubeWebRuleEnabled } from './YoutubeRuleActions';

export const close = () => dispatch => {
  dispatch(goBackIfHistory());
};

export const onClickAccept =
  (profileId: string, toggleOn: boolean) => dispatch => {
    dispatch(setLastRuleTypeModified(RuleType.YoutubeMonitoring));
    dispatch(toggleYoutubeWebRuleEnabled(profileId, toggleOn));
    dispatch(goBackIfHistory());
  };
