/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import * as qinit from '../../qinit';

interface UpgradeAppleInfoProps {
  onClickOpenLink: (link: string) => void;
}

const UpgradeAppleInfo: React.FC<UpgradeAppleInfoProps> = ({
  onClickOpenLink,
}: UpgradeAppleInfoProps) => (
  <FlexLayout mainaxis="column" marginLeft="24" marginRight="24">
    <p className="UpgradeContent__subscriptionInfo">
      {t(
        // tslint:disable-next-line:max-line-length
        '{{shortName}} Premium is available with an annual subscription. Payment will be charged to iTunes Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period. Account will be charged for renewal within 24-hours prior to the end of the current period . Subscription may be managed by the user and auto-renewal may be turned off by going to “Settings” on your iPhone or iPad. Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable. The subscription will renew at the same cost.'
      )}
    </p>
    <a
      className="UpgradeContent__policy"
      onClick={() => onClickOpenLink(qinit.tenant.mobile.policies.tos_link)}
    >
      {t('Terms of service')}
    </a>
    <a
      className="UpgradeContent__policy"
      onClick={() =>
        onClickOpenLink(qinit.tenant.mobile.policies.privacy_policy_link)
      }
    >
      {t('Privacy policy')}
    </a>
  </FlexLayout>
);

export default UpgradeAppleInfo;
