import * as React from 'react';
import { t } from '../../../lib/i18n';
import { List } from 'immutable';
import StepBar from '../../StepBar/StepBar';

const OnboardingStepBar: React.FunctionComponent<{
  activeStep: number;
  inProgress?: boolean;
}> = ({ activeStep, inProgress }) => {
  const steps = List([
    { label: t('Add a child') },
    { label: t('Add a device') },
    { label: t('Finish') },
  ]);
  return (
    <StepBar activeStep={activeStep} inProgress={inProgress} steps={steps} />
  );
};

export default OnboardingStepBar;
