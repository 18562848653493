import * as React from 'react';
import { t } from '../../lib/i18n';
import { UpgradeTag } from '../UpgradeTag/UpgradeTag';
import { FlexLayout, Typography } from 'styleguide-react';

interface WebFilterTitleProps {
  upgrade: boolean;
  size: 'large' | 'small';
}

const WebFilterAlertsTitle = ({ upgrade, size }: WebFilterTitleProps) => {
  return (
    <FlexLayout mainaxis="row" gap="8" textAlignement="center">
      <FlexLayout
        mainaxis="column"
        maxHeight={size === 'small' ? '100%' : '24'}
      >
        <Typography
          weight="semibold"
          type={size === 'small' ? 'body1' : 'h4'}
          testId="web-filter-alerts-title-text"
        >
          {t('Alerts')}
        </Typography>
      </FlexLayout>

      {upgrade && (
        <FlexLayout mainaxis="column" alignSelf="center">
          <UpgradeTag size="small" testId="web-filters" />
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default WebFilterAlertsTitle;
