import * as React from 'react';
import { FlexLayout, Typography } from 'styleguide-react';
import { classNames } from '../../../helpers';
import { t } from '../../../lib/i18n';
import {
  formatHumanTwelveClockFormat,
  isValidISODateString,
} from '../../../helpers/dates';
import Date from '../../Date/Date';

const LastUpdatedInfo = ({
  lastUpdated,
  position,
  marginTop = '0',
  marginBottom = '0',
}: {
  lastUpdated: string;
  position: 'right' | 'left';
  marginTop?: '0' | '8' | '16';
  marginBottom?: '0' | '8' | '16';
}) => {
  if (!isValidISODateString(lastUpdated)) {
    return null;
  }
  return (
    <FlexLayout
      mainaxis="row"
      className={classNames(
        'par-school-timeline-page__last-update',
        `par-school-timeline-page__last-update--${position}`
      )}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Typography type="body2" color="gray-semi">
        {`${t('Last updated')} `}
        <Date
          time={lastUpdated}
          dateFormatCallback={formatHumanTwelveClockFormat}
        />
      </Typography>
    </FlexLayout>
  );
};

export default LastUpdatedInfo;
