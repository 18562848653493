import { connect } from 'react-redux';
import State from '../../store/state';
import {
  isFetchingProfile,
  getSummaryScreenTimeActivityError,
  getSelectedDateScreenTime,
  getSelectedPastDate,
  getAccountLocale,
  getTimezone,
  getActiveDateRangeNormalized,
} from '../../selectors';
import ScreenTimeDetails from '../../components/ScreenTimeDetails/ScreenTimeDetails';
import type { ProfileRecord as ProfileRecordType } from '../../records/profile/types/Profile.types';
import { getRoutinesStatus } from '../../ducks/routines/selectors';
import { getUnfilteredRoutinesRecordsMap } from '../../ducks/routines/selectors/unfiltered';
import { formatDateNiceShort } from '../../helpers/dates';

interface Props {
  profile?: ProfileRecordType;
}

const mapStateToProps = (state: State, { profile }: Props) => {
  const activeDateRange = getActiveDateRangeNormalized(state);
  const isFetchingInfo = isFetchingProfile(state) as boolean;
  const isFetchingRoutines = getRoutinesStatus(state).get('read') === 'loading';
  const hasError = Boolean(getSummaryScreenTimeActivityError(state));
  const routines = getUnfilteredRoutinesRecordsMap(state);
  const selectedDayScreenTime = getSelectedDateScreenTime(state);

  const locale = getAccountLocale(state);
  const timezone = getTimezone(state);
  const customDay = getSelectedPastDate(state);
  const formattedSelectedDate = formatDateNiceShort(
    customDay,
    timezone,
    locale
  );

  return {
    profile,
    activeDateRange,
    formattedSelectedDate,
    routines,
    data: selectedDayScreenTime,
    isFetchingInfo: isFetchingInfo || isFetchingRoutines,
    hasError,
  };
};

const ScreenTimeDetailsContainer = connect(
  mapStateToProps,
  null
)(ScreenTimeDetails);

export default ScreenTimeDetailsContainer;

export type ScreenTimeDetailsProps = ReturnType<typeof mapStateToProps>;
