import { ProductToOfferApiPayload } from './types/ProductsReponse.types';
import { captureException } from '../../helpers/sentry/index';
import { setupProducts } from '../../helpers/purchases/inAppPurchaseProvider';
import { requiresInAppPurchasesPlugin } from '../../helpers/purchase-provider';
import {
  areUpsellProducts,
  getDefaultProductIndex,
  getPaymentProvider,
  getProductList,
  getProductsResult,
  getRouteParams,
} from '../../selectors';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { BaseThunk } from '../../store/state';
import {
  productDurationChanged,
  receiveProducts,
  requestProducts,
  requestProductsError,
} from './actions';
import {
  fetchProduct,
  fetchProductsToOffer,
  getExperimentParameter,
  shouldFetchProducts,
  mergeProductsToOffer,
  getXSourceParameter,
} from './helpers';

export const fetchProducts =
  (): BaseThunk<void> =>
  async (dispatch, getState): Promise<void> => {
    if (!shouldFetchProducts()) {
      return;
    }
    const state = getState();
    const paymentProvider = getPaymentProvider(state);
    const useChargebeeV3PC2FFlag = getFeatureFlag(state, 'useChargebeeV3PC2');
    const promotion = getRouteParams(state).get('promotion');
    const experiment = getExperimentParameter();
    const xsource = useChargebeeV3PC2FFlag
      ? getXSourceParameter(state)
      : undefined;

    dispatch(requestProducts(promotion !== undefined ? promotion : 'default'));
    try {
      let products: ProductToOfferApiPayload;

      // If a promotion param is present request those products
      // without 2 years and without dedicated support
      if (promotion !== undefined) {
        products = await fetchProductsToOffer(
          {
            paymentProvider,
            promotion,
            xsource,
          },
          {
            requestTwoYears: false,
          }
        );
      } else if (areUpsellProducts(state)) {
        products = await fetchProduct(
          {
            productCode: 'current',
            paymentProvider,
            size: true,
            xsource,
          },
          {
            requestTwoYears: true,
          }
        );
      } else if (experiment) {
        products = await fetchProductsToOffer(
          {
            paymentProvider,
            experiment,
            xsource,
          },
          {
            requestTwoYears: true,
          }
        );
      } else {
        products = await fetchProductsToOffer(
          {
            paymentProvider,
            xsource,
          },
          {
            requestTwoYears: true,
          }
        );

        if (getFeatureFlag(getState(), 'dedicatedSupportV2')) {
          const productsWithDedicatedSupport = await fetchProductsToOffer(
            {
              paymentProvider,
              promotion: 'dedicated_support',
              xsource,
            },
            {
              requestTwoYears: !requiresInAppPurchasesPlugin(paymentProvider),
            }
          );

          products = mergeProductsToOffer(
            products,
            productsWithDedicatedSupport
          );
        }
      }

      dispatch(receiveProducts(products, paymentProvider));

      // For apple and googleplay products we need to setup the in app purchase plugin
      if (requiresInAppPurchasesPlugin(paymentProvider)) {
        const productCodes = getProductsResult(getState()).toJS();
        dispatch(setupProducts(productCodes));
        return;
      }
      const productsFromState = getProductList(getState());
      const defaultProductIndex = getDefaultProductIndex(getState());
      dispatch(
        productDurationChanged(
          productsFromState.get(defaultProductIndex).duration
        )
      );
    } catch (e) {
      dispatch(requestProductsError());
      // We capture the exception here and we don't want to propage it.
      captureException(e);
    }
  };
