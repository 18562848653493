import * as React from 'react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  DeactivableContent,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
} from 'styleguide-react';
import DropDownCard from './AppRulesDropdownCard';
import { AppRuleRecord, makeAppKey } from '../../records/profileRules';
import { AppRulesCardProps } from './AppRulesCard';

interface AppRulesRightElementProps {
  app: AppRuleRecord;
  isBlockedUnsupportedBrowsers: boolean;
  enabled: boolean;
  onSelectStatus: AppRulesCardProps['onSelectStatus'];
  onClickApp: AppRulesCardProps['onClickApp'];
}

const AppRulesRightElement = ({
  app,
  isBlockedUnsupportedBrowsers,
  enabled,
  onClickApp,
  onSelectStatus,
}: AppRulesRightElementProps) => {
  const isBlockedUnsupportedBrowser =
    app.isUnsupportedBrowser && isBlockedUnsupportedBrowsers;
  const key = makeAppKey(app);

  const isCardDisabled = !enabled || isBlockedUnsupportedBrowser;
  return (
    <RenderWhen screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}>
      {screen =>
        screen === ScreenSize.Mobile && !app.blockable ? (
          <FlexLayout
            mainaxis="column"
            className="par-app-rule-card__icon-info"
          >
            <Icon
              testId="app-rule-card--mobile-info"
              type={IconType.infoCircle}
              color={IconColor.secondary}
              onClick={() => onClickApp(key)}
            />
          </FlexLayout>
        ) : (
          <DeactivableContent disabled={isCardDisabled}>
            <DropDownCard
              name={app.name}
              action={app.action}
              quotas={app.quotas}
              isDisabled={!app.blockable}
              onDisabledClick={() => onClickApp(key)}
              onChange={({ type, isMobile }) =>
                onSelectStatus({
                  type,
                  isMobile,
                  appKey: key,
                  appName: app.name,
                  appPlatform: app.platform,
                  appId: app.id as string,
                })
              }
            />
          </DeactivableContent>
        )
      }
    </RenderWhen>
  );
};

export default AppRulesRightElement;
