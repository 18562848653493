import * as React from 'react';
import {
  List,
  ListSubtitle,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';
import {
  LastBiometricValidTimeInSeconds,
  SettingsToggleNames,
} from '../../constants';
import { t } from '../../lib/i18n';

type LockScreenSettingsProps = {
  lockScreen: {
    shouldDisplayFeature: boolean;
    enabled: boolean;
  };
  onToggle: (target: SettingsToggleNames, toggleOn: boolean) => void;
};

const LockScreenSettings: React.FC<LockScreenSettingsProps> = ({
  lockScreen,
  onToggle,
}: LockScreenSettingsProps) => (
  <List
    listItemMarginBottom="8"
    listItemPaddingTop="8"
    listItemPaddingBottom="8"
  >
    <RegularStyleListItem
      key={SettingsToggleNames.IsLockScreenEnabled}
      testId="settings-biometric-listitem"
      title={t('Protect the Parents App')}
      upperSubtitle={
        <ListSubtitle testId="settings-biometric-subtitle">
          {t(
            'Require authentication after {{seconds}} seconds of inactivity.',
            {
              seconds: LastBiometricValidTimeInSeconds,
            }
          )}
        </ListSubtitle>
      }
      actionElement={
        <Switch
          testId="settings-biometric-switch"
          id={SettingsToggleNames.IsLockScreenEnabled}
          checked={lockScreen.enabled}
          onClick={ev =>
            onToggle(
              SettingsToggleNames.IsLockScreenEnabled,
              // we shouldn't have to do this cast.
              (ev.target as HTMLInputElement).checked
            )
          }
        />
      }
    />
  </List>
);

LockScreenSettings.displayName = 'LockScreenSettings';

export default LockScreenSettings;
