import * as Sentry from '@sentry/react';
import * as Rox from 'rox-browser';
import * as R from 'ramda';
import * as qinit from '../qinit';
import flags from '../sideEffects/flags';
// eslint-disable-next-line import/no-named-as-default
import store from '../store';
import {
  isRolloutUserConfigSent,
  getAccountId,
  getAccountOptionsFeatureFlags,
} from '../selectors';
import api from '../api';
import { updateAccountOptionFlags, FLAG_PREFIX } from '../ducks/account';
import { getSemver } from './string';
import { setFlags, FeatureFlags } from '../ducks/featureFlags';
import { getBuildPlatform, getAppVersion } from './globals';

export const extractTagsFromFlags = (): FeatureFlags => {
  return Object.keys(flags).reduce((acum, flagName) => {
    const flag = flags[flagName];
    // flags also are experiments with getValue instead of isEnabled
    if (flag.isEnabled) {
      // eslint-disable-next-line no-param-reassign
      acum[`${FLAG_PREFIX}_${flagName}`] = flag.isEnabled().toString();
    }
    return acum;
  }, {}) as FeatureFlags;
};

export const isEqual = (flagsFromBackend, flagsFromClient) => {
  const flagClientKeys = Object.keys(flagsFromClient);
  // Bck always persist any flag sent. So Bck always grow but we could remove
  // some flag from client. Then we have to evaluate just our client flag values
  // ignoring Bck flags not present in client side because removed.
  if (flagClientKeys.length > Object.keys(flagsFromBackend).length) {
    return false;
  }
  return !flagClientKeys.some(
    key => flagsFromBackend[key] !== (flagsFromClient[key] || false)
  );
};

export const reportFeatureFlags = props => {
  try {
    const state = store.getState();
    if (isRolloutUserConfigSent(state) && getAccountId(store.getState())) {
      if (!isEqual(getAccountOptionsFeatureFlags(state).toJS(), props)) {
        api.options.post(props).catch(() => {
          // Do nothing, this never trhow an exception breaking our app impl
        });
        store.dispatch(updateAccountOptionFlags(props));
      }
      // initial config done, unsubscribe
      return true;
    }
  } catch (err) {
    // Do nothing, this never trhow an exception breaking our app impl
    // but return true to unsubscribe
    return true;
  }
  return false;
};

const updateSentryFeatureFlagsIfNeeded = R.memoizeWith(R.toString, props => {
  Sentry.setExtras(props);
});

export const syncFeatureFlagsWithSentry = () => {
  const flags = extractTagsFromFlags();
  // rollout handler returns result sync obj with hasChanges prop, but its
  // value doesn't change if there is no remoting change. So we can not use
  // it to detect when default local false prop is updated by remote value.
  updateSentryFeatureFlagsIfNeeded(flags);
  reportFeatureFlags(flags);
  store.dispatch(setFlags());
};

export const rolloutSetup = () => {
  Rox.register('', flags);
  Rox.setup(qinit.tenant.parents_app.rollout_io.api_key, {
    configurationFetchedHandler: syncFeatureFlagsWithSentry,
    version: getSemver(getAppVersion()),
    platform: getBuildPlatform(),
    freeze: 'untilLaunch' as Rox.RoxFlagFreezeLevel,
  });
};
