import State from '../../store/state';

export const getSignatureDetails = (state: State) =>
  state.get('signatureDetails').get('result');

export const getSignatureDetailsId = (state: State) => {
  const signatureDetails = getSignatureDetails(state);
  return signatureDetails?.id ? signatureDetails.id : null;
};

export const isErrorSignatureDetails = (state: State): boolean =>
  state.getIn(['signatureDetails', 'isError']);

export const getSignatureDetailsFetchStatus = (state: State) =>
  state.get('signatureDetails').get('fetchStatus');
