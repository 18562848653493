import * as React from 'react';
import { t } from '../../lib/i18n';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';
import { IconType } from 'styleguide-react';
import { ISOLanguage } from '../../constants';
import { PremiumFlyoverProps } from '../FullPremiumFlyover/types/FullPremiumFlyoverProps.types';

interface CallsSMSRulesFlyoverProps {
  testId: string;
  accountLocale: ISOLanguage;
  actionButton: PremiumFlyoverProps['actionButton'];
  footNote?: PremiumFlyoverProps['footNote'];
  topbarText?: PremiumFlyoverProps['topbarText'];
  alertBoxProps?: PremiumFlyoverProps['alertBoxProps'];
  onClickClose: () => void;
}

const CallsSMSRulesFlyover = ({
  testId,
  accountLocale,
  actionButton,
  footNote,
  topbarText,
  alertBoxProps,
  onClickClose,
}: CallsSMSRulesFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Calls & messages') }}
      testId={testId}
      topbarText={topbarText}
      title={t('What can you do with calls & messages?')}
      imageSrc={`/illustrations/CallsAndMessages/premium_calls_and_messages__${accountLocale}.png`}
      actionButton={actionButton}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.phone,
          title: t('Monitor calls'),
          subtitle: t('See who your child exchanges calls and FaceTimes with'),
        },
        {
          iconType: IconType.messages,
          title: t('Read messages'),
          subtitle: t(
            'Keep an eye on the SMS and iMessages your child sends and receives'
          ),
        },
        {
          iconType: IconType.shieldCheck,
          title: t('Receive app alerts'),
          subtitle: t('Start monitoring with just a few quick steps'),
        },
      ]}
      footNote={footNote}
      alertBoxProps={alertBoxProps}
    />
  );
};

export default CallsSMSRulesFlyover;
