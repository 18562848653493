import * as React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import * as R from 'ramda';
import DeviceAndroid from '../../components/Devices/DeviceAndroid';
import DeviceDesktop from '../../components/Devices/DeviceDesktop';
import DeviceIos from '../../components/Devices/DeviceIos';
import {
  getLicense,
  getDevice,
  getDeviceProfiles,
  getProfileOrDefault,
  getProfiles,
} from '../../selectors';
import {
  confirmRemove,
  togglePlatformEnabled,
  toggleVPNEnabled,
  toggleProtectAndroidSettings,
  toggleProtectQustodioConfiguration,
  toggleHideQustodio,
  openDeviceLocation,
} from '../../actions/DeviceActions';
import { getProtectionStatus, isVpnEnabled } from '../../records/device';
import { fetchExtendedLocation } from '../../actions/DeviceEditActions';
import {
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
  Platform,
} from '../../constants';
import { mapCond } from '../../helpers';
import { track, Events } from '../../helpers/analytics';
import { assignProfileToDevice } from '../../actions/AssignProfileToDeviceActions';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { navigateToExternalAntiTamperingHelp } from '../../actions/Navigation';
import { DeviceRecord } from '../../records/device/types/Device.types';

const Device = <T extends { device: DeviceRecord | undefined }>(props: T) => {
  const { device } = props;
  const DeviceComponent = mapCond<
    Platform,
    typeof DeviceIos | typeof DeviceAndroid | typeof DeviceDesktop
  >(
    [
      [PLATFORM_IOS, DeviceIos],
      [PLATFORM_ANDROID, DeviceAndroid],
    ],
    DeviceDesktop
  )(device ? device.platform : PLATFORM_IOS);

  return React.createElement(DeviceComponent as any, props as any);
};

const mapStateToProps = (state, { params: { deviceId } }) => {
  const intDeviceId = parseInt(deviceId, 10);
  const device: DeviceRecord | undefined = getDevice(state, intDeviceId);
  const profiles = getDeviceProfiles(state, intDeviceId) || List();

  const desktopProps =
    device &&
    (device.platform === PLATFORM_WINDOWS || device.platform === PLATFORM_MAC)
      ? {
          defaultProfile: device.defaultProfileId
            ? getProfileOrDefault(state, device.defaultProfileId)
            : null,
        }
      : {};

  return {
    license: getLicense(state),
    device,
    profiles,
    accountProfiles: getProfiles(state),
    protectionEnabled:
      device !== undefined
        ? getProtectionStatus(device, profiles) !== 'ProtectionDisabled'
        : true,
    isDeviceTampered:
      device && getProtectionStatus(device, profiles) === 'ProtectionTampered',
    vpnEnabled: device !== undefined ? isVpnEnabled(device) : true,
    ...desktopProps,
  };
};

const mapDispatchToProps = (dispatch, { params: { deviceId } }) => ({
  onClickAssignDefaultProfileFromDropdown: profileId =>
    dispatch(assignProfileToDevice(deviceId, 1, profileId)),
  onClickAssignProfileFromDropdown: (profileId, userId) => {
    dispatch(assignProfileToDevice(deviceId, userId, profileId));
  },
  onClickOpenDropdown: isOpen => {
    if (isOpen) track(Events.ClickedDeviceAssignToProfile);
  },
  onClickMap: R.pipe(openDeviceLocation(deviceId), dispatch),
  onClickEdit: () => {
    const navigate = getMultiPlatformNavigation();
    return Promise.resolve(track(Events.ClickedDeviceEdit)).then(
      dispatch(navigate({ type: 'inner', src: `/devices/${deviceId}/edit` }))
    );
  },
  onClickDelete: () => dispatch(confirmRemove(deviceId)),
  onTogglePlatformEnabled: (toggleOn: boolean) =>
    dispatch(togglePlatformEnabled(deviceId, toggleOn)),
  onToggleVPNEnabled: (toggleOn: boolean) =>
    dispatch(toggleVPNEnabled(deviceId, toggleOn)),
  onToggleProtectAndroidSettings: (toggleOn: boolean) =>
    dispatch(toggleProtectAndroidSettings(deviceId, toggleOn)),
  onToggleProtectQustodioConfiguration: (toggleOn: boolean) =>
    dispatch(toggleProtectQustodioConfiguration(deviceId, toggleOn)),
  onToggleHideQustodio: (toggleOn: boolean) =>
    dispatch(toggleHideQustodio(deviceId, toggleOn)),
  onOpenAntiTamperingHelpLink: () =>
    dispatch(navigateToExternalAntiTamperingHelp()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    license,
    device,
    profiles,
    accountProfiles,
    defaultProfile,
    protectionEnabled,
    isDeviceTampered,
    vpnEnabled,
  } = stateProps;
  const {
    onClickAssignProfileFromDropdown,
    onClickAssignDefaultProfileFromDropdown,
    onClickOpenDropdown,
    onClickMap,
    onClickEdit,
    onClickDelete,
    onToggleVPNEnabled,
    onToggleProtectAndroidSettings,
    onToggleProtectQustodioConfiguration,
    onToggleHideQustodio,
    onOpenAntiTamperingHelpLink,
  } = dispatchProps;

  return R.cond<string, object>([
    [
      R.equals(PLATFORM_IOS),
      R.always({
        ...ownProps,
        license,
        device,
        profile: profiles.first(),
        accountProfiles,
        protectionEnabled,
        isDeviceTampered,
        vpnEnabled,
        onClickOpenDropdown,
        onClickAssignProfileFromDropdown:
          onClickAssignDefaultProfileFromDropdown,
        onClickMap,
        onClickEdit,
        onClickDelete,
        onToggleVPNEnabled,
        onOpenAntiTamperingHelpLink,
      }),
    ],
    [
      R.equals(PLATFORM_ANDROID),
      R.always({
        ...ownProps,
        license,
        device,
        profile: profiles.first(),
        accountProfiles,
        protectionEnabled,
        onClickAssignProfileFromDropdown:
          onClickAssignDefaultProfileFromDropdown,
        onClickOpenDropdown,
        onClickMap,
        onClickEdit: () => onClickEdit(PLATFORM_ANDROID),
        onClickDelete,
        onToggleProtectAndroidSettings,
        onToggleProtectQustodioConfiguration,
      }),
    ],
    [
      R.T,
      R.always({
        ...ownProps,
        license,
        device,
        defaultProfile,
        profiles,
        accountProfiles,
        protectionEnabled,
        onClickAssignProfileFromDropdown: (profileId, userUid) =>
          onClickAssignProfileFromDropdown(profileId, userUid),
        onClickOpenDropdown,
        onClickEdit,
        onClickDelete,
        onToggleHideQustodio,
      }),
    ],
  ])(device !== undefined ? device.platform : PLATFORM_IOS);
};

const DeviceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Device);

DeviceContainer.load =
  ({ deviceId }) =>
  dispatch =>
    dispatch(fetchExtendedLocation(deviceId));

export default DeviceContainer;
