import * as React from 'react';
import { t } from '../../lib/i18n';
import Loader from '../base/Loader';
import ProfileActivityPlaceholderWrapper from '../Profiles/ProfileActivityPlaceholderWrapper';

const LoadingActivityPlaceholder = () => (
  <ProfileActivityPlaceholderWrapper>
    <div className="LoadingActivityPlaceholder__content">
      <Loader size="small" color="" />
      {t('Loading activity')}...
    </div>
  </ProfileActivityPlaceholderWrapper>
);

export default LoadingActivityPlaceholder;
