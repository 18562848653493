import * as React from 'react';
import { GroupHeader, Layout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import WebFiltersNotifications, {
  WebFiltersNotificationsProps,
} from './WebFiltersNotifications';
import WebFiltersSettings, {
  WebFilterSettingsProps,
} from './WebFiltersSettings';
import WebFilterAlertsTitle from './WebFilterAlertsTitle';

export type WebFilterSettingsAndNotificationsPageProps =
  WebFilterSettingsProps & WebFiltersNotificationsProps;

// tslint:disable-next-line:max-line-length
const WebFilterSettingsAndNotificationsPage: React.FunctionComponent<
  WebFilterSettingsAndNotificationsPageProps
> = props => {
  const { ruleNeedsUpgrade } = props;

  return (
    <PageLayout
      title={t('Settings')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 1,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
    >
      <Layout>
        <GroupHeader>{t('Settings')}</GroupHeader>
        <WebFiltersSettings {...props} />
        <GroupHeader>
          <WebFilterAlertsTitle
            size="large"
            upgrade={
              ruleNeedsUpgrade('threat_alerts_search') ||
              ruleNeedsUpgrade('web_blocked_alert')
            }
          />
        </GroupHeader>
        <WebFiltersNotifications {...props} />
      </Layout>
    </PageLayout>
  );
};

export default WebFilterSettingsAndNotificationsPage;
