export const REQUEST_SUMMARY = 'REQUEST_SUMMARY';
export const RECEIVE_SUMMARY = 'RECEIVE_SUMMARY';
export const REQUEST_SUMMARY_PER_HOURS = 'REQUEST_SUMMARY_PER_HOURS';
export const REFRESH_SUMMARY_PER_HOURS = 'REFRESH_SUMMARY_PER_HOURS';
export const RECEIVE_SUMMARY_PER_HOURS = 'RECEIVE_SUMMARY_PER_HOURS';
export const REQUEST_SUMMARY_PER_DAYS = 'REQUEST_SUMMARY_PER_DAYS';
export const REFRESH_SUMMARY_PER_DAYS = 'REFRESH_SUMMARY_PER_DAYS';
export const RECEIVE_SUMMARY_PER_DAYS = 'RECEIVE_SUMMARY_PER_DAYS';
export const REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY =
  'REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY';
export const REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY =
  'REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY';
export const RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY =
  'RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY';
export const SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR =
  'SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR';
export const REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY =
  'REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY';
export const REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY =
  'REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY';
export const RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY =
  'RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY';
export const SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR =
  'SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR';
export const SUMMARY_SCREEN_TIME_ACTIVITY_ERROR =
  'SUMMARY_SCREEN_TIME_ACTIVITY_ERROR';
export const SHOW_WELCOME_MODAL = 'SHOW_WELCOME_MODAL';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
export const RESET_SUMMARY = 'RESET_SUMMARY';
export const SELECTED_PAST_DATE_CHANGED = 'SELECTED_PAST_DATE_CHANGED';
export const LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY =
  'LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY';
export const LOAD_WIDGET_BLOCKED_WEB_ACTIVITY =
  'LOAD_WIDGET_BLOCKED_WEB_ACTIVITY';
export const LOAD_WIDGET_APPS_WEB_ACTIVITY = 'LOAD_WIDGET_APPS_WEB_ACTIVITY';
export const LOAD_SEARCH_ACTIVITY = 'LOAD_SEARCH_ACTIVITY';
export const LOAD_CALL_AND_SMS_ACTIVITY = 'LOAD_CALL_AND_SMS_ACTIVITY';
export const RECEIVE_TODAY_SUMMARY_PER_HOURS =
  'RECEIVE_TODAY_SUMMARY_PER_HOURS';
