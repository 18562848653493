import { PaymentProvider } from '../../constants';
import { ProductDuration } from '../../records/product';
import { normalizeProducts } from './helpers';
import type {
  ProductsAction,
  ReceiveProductsAction,
} from './types/actions.types';

export const requestProducts = (promotion?: string): ProductsAction => ({
  type: 'REQUEST_PRODUCTS',
  payload: promotion || 'default',
});

export const requestProductsError = (): ProductsAction => ({
  type: 'REQUEST_PRODUCTS_ERROR',
});

export const startValidation = (): ProductsAction => ({
  type: 'START_VALIDATION',
});

export const finishValidation = (): ProductsAction => ({
  type: 'FINISH_VALIDATION',
});

export const timeoutValidation = (): ProductsAction => ({
  type: 'TIMEOUT_VALIDATION',
});

export const startPurchaseProcess = (): ProductsAction => ({
  type: 'START_PURCHASE_PROCESS',
});

export const finishPurchaseProcess = (): ProductsAction => ({
  type: 'FINISH_PURCHASE_PROCESS',
});

export const productDurationChanged = (
  productDuration: ProductDuration
): ProductsAction => ({
  type: 'PRODUCT_DURATION_CHANGED',
  payload: productDuration,
});

export const productChanged = (productId: string): ProductsAction => ({
  type: 'PRODUCT_CHANGED',
  payload: productId,
});

export const receiveProducts = (
  response,
  paymentProvider: PaymentProvider
): ReceiveProductsAction => {
  const payload = normalizeProducts(response, paymentProvider);
  return {
    type: 'RECEIVE_PRODUCTS',
    payload: {
      ...payload,
      reducerBehavior: { merge: true },
    },
  };
};
