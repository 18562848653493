import { ToastManager } from 'styleguide-react';
import { APIError } from '../lib/errors';
import {
  ERROR_TIMEOUT,
  ERROR_5XX,
  ERROR_4XX,
  ERROR_UNAUTHORIZED,
  ERROR_ABORT,
  ERROR_NETWORK,
} from '../constants';
import { t } from '../lib/i18n';
import { captureException } from './sentry';

export type AnyError = Error | APIError;

export const showErrorAlert = (error: AnyError, alertCallback?: Function) => {
  let title: string = t('Whoops!');
  let msg: string = t('Something went wrong. Please try again.');

  if (error instanceof APIError) {
    switch (error.type) {
      case ERROR_NETWORK:
      case ERROR_TIMEOUT:
      case ERROR_ABORT:
        title = t('Whoops!');
        msg = t(
          "Something's gone wrong. Please check your connection and try again."
        );
        break;
      case ERROR_5XX:
        title = t('Something went wrong.');
        msg = t(
          "We're having technical issues, please try again in 5 minutes."
        );
        break;
      case ERROR_4XX:
        title = t('Whoops!');
        msg = t(
          'This action is not allowed. Please contact our customer support team.'
        );
        break;
      case ERROR_UNAUTHORIZED:
        title = t('Whoops!');
        msg = t(
          "We can't seem to authorize the connection with our servers to get your data. " +
            'Try again.'
        );
        break;
      default:
        break;
    }
  } else {
    captureException(error);
  }

  ToastManager.getInstance().send(title, msg, 'error');
  if (alertCallback) {
    alertCallback();
  }
};

/**
 * Handle an APIError by navigating to and showing an error modal.
 * To be used as .catch() handler:
 *
 *     promise.catch(e => handleApiErrorMessage(e))
 */
export const handleApiErrorMessage = (error: AnyError, rethrow?: boolean) => {
  if (process.env.NODE_ENV !== 'testing') {
    console.error('handled API error', error, error.stack); // eslint-disable-line no-console
  }
  showErrorAlert(error);
  if (rethrow) {
    throw error;
  }
};
