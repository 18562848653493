import { connect } from 'react-redux';
import State, { BaseThunk, Dispatch } from '../../store/state';
import CreateRoutineMultiStep from '../../components/Routines/RoutinesMultiStep/CreateRoutineMultiStep';
import {
  navigateToChangeSetContentFilterRulesModal,
  navigateToRoutineLeaveWithoutSavingModal,
  navigateToRoutines,
} from '../../actions/RoutinesActions';
import {
  getActiveProfile,
  getAppRulesApplicationList,
  getProfileId,
  getProfileOrDefault,
  isMilitaryTimeFormat,
} from '../../selectors';
import {
  routinesCloseMenuAction,
  setRoutineMachineAction,
  setTempRoutineMode,
} from '../../ducks/routines';
import {
  routineRecordToContext,
  timeSlotToSchedule,
} from '../../ducks/routines/transformations';
import { AppRuleRecord } from '../../records/profileRules';
import { List } from 'immutable';
import {
  getAllSchedulesList,
  getEditedRoutineUid,
  getRoutine,
  getRoutineMachineAction,
  getRoutineMode,
  getRoutinesRecordsMap,
  getAllRoutinesList,
  getSchedulesStatus,
  getRoutineSchedules,
  getScheduleDetailsStatus,
  hasEditRoutinePath,
  getRoutineDetailsStatus,
} from '../../ducks/routines/selectors';
import {
  getBlockedHarmfulWebRuleException,
  safeDispatchChangeMachineAction,
  stepNeedConfirmationToClose,
} from './helpers';
import {
  RoutineCreationMode,
  RoutineMode,
  MultiStepDataContext,
  RoutineSteps,
  RoutineRuleExceptionType,
  TimeSlot,
  AppRuleType,
  WebRuleType,
} from '../../components/Routines/routines.types';
import {
  findFirstOverlapInRoutineIsEnabled,
  findFirstOverlapWithRoutineUid,
  getRoutineFeatureAccessLevel,
} from '../../businessLogic/routines/routines';
import { toInverseAction } from '../../components/Routines/helpers/steps';
import { PartialDeep } from '../../types/PartialDeep.types';
import {
  createRoutines,
  handleNavigateToCantEnableTimeSlotsModal,
  handleNavigateToChangeContentFilterRulesModal,
  handleNavigateToDeleteRoutineModal,
  handleNavigateToModalInfo,
  handleNavigateToRemoveAllExceptionsModal,
  handleNavigateToUpgradeModal,
  handleNavigateToUpgradePage,
  handleUpdateRoutine,
  navigateToDeleteTimeSlotModal,
  upsertRoutineSchedule,
} from '../../ducks/routines/thunks';
import { PolicyAction } from '../../records/routines/policy/types/Policy.types';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';
import { getFeatureByCode } from '../../ducks/features/selectors';
import { getAppCategories } from '../../ducks/appCategories/selector';
import { getTotalAppsInCategories } from '../../helpers/appsCategories';

const isOverlapped = (timeSlot: TimeSlot, state: State) => {
  const schedules = getAllSchedulesList(state).toArray();
  const routines = getRoutinesRecordsMap(state);

  const scheduleEventWithOverlap = findFirstOverlapWithRoutineUid(
    timeSlotToSchedule(timeSlot),
    schedules,
    routines
  );

  return {
    hasOverlap: Boolean(scheduleEventWithOverlap),
    routineUid: scheduleEventWithOverlap
      ? scheduleEventWithOverlap.routineUid
      : null,
    routine: scheduleEventWithOverlap
      ? getRoutine(state, scheduleEventWithOverlap.routineUid)
      : null,
  };
};

const canEnableTimeSlots = (routineUUid: string, state: State) => {
  const routineSchedules = getRoutineSchedules(state, routineUUid);
  const routines = getRoutinesRecordsMap(state);
  const schedules = getAllSchedulesList(state).toArray();
  const scheduleEventWithOverlap = findFirstOverlapInRoutineIsEnabled(
    routineSchedules,
    schedules,
    routines
  );

  return {
    hasOverlap: Boolean(scheduleEventWithOverlap),
    routineUid: scheduleEventWithOverlap
      ? scheduleEventWithOverlap.routineUid
      : null,
    routine: scheduleEventWithOverlap ? routines.get(routineUUid) : null,
  };
};

const isValidRoutineName = ({
  state,
  name,
  mode,
  routineContext,
}: {
  state: State;
  name: string;
  mode: RoutineMode;
  routineContext?: MultiStepDataContext;
}) => {
  const routines = getAllRoutinesList(state);
  const nameInLowerCase = name.toLowerCase();

  if (mode === 'CREATE') {
    const existName = routines.some(
      routine => routine?.name.toLowerCase() === nameInLowerCase
    );
    return !existName;
  }

  if (mode === 'EDIT') {
    const existName = routines.some(routine => {
      return (
        routine?.name.toLowerCase() === nameInLowerCase &&
        routine.uid !== routineContext?.uid
      );
    });
    return !existName;
  }

  return true;
};

const mapStateToProps = (state: State, props) => {
  const appRulesList = getAppRulesApplicationList(
    state,
    props.profileId
  ) as List<AppRuleRecord>;
  const mode = getRoutineMode(state);
  const routineUid = getEditedRoutineUid(state);
  const isMilitaryTime = isMilitaryTimeFormat(state);

  const routine = routineUid ? getRoutine(state, routineUid) : undefined;
  const routineSchedules = routineUid
    ? getRoutineSchedules(state, routineUid)
    : List([]);

  const routineContext = routine
    ? routineRecordToContext(routine, routineSchedules, appRulesList)
    : undefined;

  const schedulesStatus = getSchedulesStatus(state);
  const scheduleDetailsStatus = getScheduleDetailsStatus(state);
  const isFetchingTimeSlots = [
    schedulesStatus.get('read'),
    schedulesStatus.get('create'),
    scheduleDetailsStatus.get('delete'),
  ].includes('loading');

  const isUpdatingRoutine =
    getRoutineDetailsStatus(state).get('update') === 'loading';

  const routinesList = getAllRoutinesList(state);

  const licenseSubtype = getLicenseSubtype(state);
  const routineContentFilteringFeature = getFeatureByCode(
    state,
    'routine_content_filtering'
  );
  const routineBlockFeature = getFeatureByCode(state, 'routines_block');
  const featureAccessLevel = getRoutineFeatureAccessLevel(
    licenseSubtype,
    routineContentFilteringFeature,
    routineBlockFeature
  );

  const categories = getAppCategories(state);
  const totalAppsByCategory = getTotalAppsInCategories(
    state,
    categories,
    props.profileId,
    false
  );

  return {
    mode,
    routine: routineContext,
    routinesList,
    appRulesList,
    isMilitaryTime,
    profileName: getProfileOrDefault(state, props.profileId).name,
    featureAccessLevel,
    isValidName: (name: string) =>
      isValidRoutineName({ state, name, routineContext, mode }),
    machineAction: getRoutineMachineAction(state).toJS(),
    isOverlapped: (timeSlot: TimeSlot) => isOverlapped(timeSlot, state),
    isFetchingTimeSlots,
    isUpdatingRoutine,
    canEnableTimeSlots: (routineUid: string) =>
      canEnableTimeSlots(routineUid, state),
    ...props,
    categories,
    totalAppsByCategory,
  };
};

const hasExceptions = (
  type: RoutineRuleExceptionType,
  routine: MultiStepDataContext
) => {
  if (type === 'APP') return routine.contentFilter.appsException.length > 0;
  if (type === 'WEB') return routine.contentFilter.websException.length > 0;
  return false;
};

const hasRuleTypeAsDefault = (
  type: RoutineRuleExceptionType,
  routine: MultiStepDataContext
) => {
  if (type === 'APP') return routine.contentFilter.appRuleType === 'DEFAULT';
  if (type === 'WEB') return routine.contentFilter.webRuleType === 'DEFAULT';
  return false;
};

const editContentFilterRuleType =
  (
    data: {
      activeTab: RoutineRuleExceptionType;
      appRuleType: AppRuleType;
      webRuleType: WebRuleType;
    },
    routine: MultiStepDataContext,
    mode: RoutineMode
  ): BaseThunk =>
  dispatch => {
    const { appRuleType, webRuleType, activeTab } = data;
    const isApp = activeTab === 'APP';

    if (!hasExceptions(data.activeTab, routine)) {
      // When allowing web categories, we add some categories to be blocked by default
      const websException = getBlockedHarmfulWebRuleException(webRuleType);

      dispatch(
        handleUpdateRoutine(routine, {
          contentFilter: isApp
            ? { appRuleType }
            : { webRuleType, websException },
        })
      );
    } else {
      dispatch(
        handleNavigateToChangeContentFilterRulesModal(
          routine?.uid as string,
          data.activeTab,
          isApp ? data.appRuleType : data.webRuleType,
          mode
        )
      );
    }
  };

const updateExceptionsAction = (
  appRuleType: AppRuleType,
  webRuleType: WebRuleType,
  context: PartialDeep<MultiStepDataContext>
) => {
  // When allowing web categories, we add some categories to be blocked by default
  const websException = getBlockedHarmfulWebRuleException(webRuleType);

  const apps = context.contentFilter?.appsException ?? [];
  const webs = websException;

  return {
    contentFilter: {
      appRuleType,
      webRuleType,
      appsException:
        appRuleType === 'DEFAULT'
          ? apps
          : apps.map(app => ({ ...app, action: toInverseAction(appRuleType) })),
      websException:
        webRuleType === 'DEFAULT'
          ? webs
          : webs.map(web => ({ ...web, action: toInverseAction(webRuleType) })),
    },
  };
};

const createContentFilterRuleType =
  (
    data: {
      activeTab: RoutineRuleExceptionType;
      appRuleType: AppRuleType;
      webRuleType: WebRuleType;
    },
    routine: MultiStepDataContext,
    mode: RoutineMode
  ): BaseThunk =>
  dispatch => {
    const { appRuleType, webRuleType, activeTab } = data;
    const isApp = activeTab === 'APP';

    if (
      !hasExceptions(data.activeTab, routine) ||
      hasRuleTypeAsDefault(data.activeTab, routine)
    ) {
      safeDispatchChangeMachineAction(
        dispatch,
        setRoutineMachineAction({
          type: 'UPDATE_CONTEXT',
          params: updateExceptionsAction(appRuleType, webRuleType, routine),
        })
      );
    } else {
      dispatch(
        handleNavigateToChangeContentFilterRulesModal(
          routine?.uid as string,
          data.activeTab,
          isApp ? data.appRuleType : data.webRuleType,
          mode
        )
      );
    }
  };

const navigateToSetContentFilterModal =
  (type: RoutineRuleExceptionType): BaseThunk =>
  (dispatch, getState) => {
    const profileId = getProfileId(getState())!;
    dispatch(navigateToChangeSetContentFilterRulesModal({ profileId, type }));
  };

export const closeDrawerAndResetMultiStep =
  (): BaseThunk => (dispatch: Dispatch, getState) => {
    const state = getState();
    dispatch(routinesCloseMenuAction());

    safeDispatchChangeMachineAction(
      dispatch,
      setRoutineMachineAction({
        type: 'EXIT',
        params: null,
      })
    );

    if (hasEditRoutinePath(state)) {
      const profileId = getActiveProfile(state)?.id;
      if (profileId) dispatch(navigateToRoutines(profileId.toString()));
    }
  };

const handleCloseDrawer =
  (mode: RoutineMode, step: RoutineSteps, isFinalized: boolean): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const profileId = getProfileId(state);
    if (!isFinalized && stepNeedConfirmationToClose(mode, step)) {
      dispatch(
        navigateToRoutineLeaveWithoutSavingModal(profileId!, mode, step)
      );
    } else {
      dispatch(closeDrawerAndResetMultiStep());
    }
  };

const handleRemoveAllExceptions =
  (
    routineUid: string | undefined,
    type: RoutineRuleExceptionType,
    mode: RoutineMode,
    policyAction?: PolicyAction
  ): BaseThunk =>
  dispatch => {
    dispatch(
      handleNavigateToRemoveAllExceptionsModal(
        routineUid,
        type,
        mode,
        policyAction
      )
    );
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickInfo: (step: RoutineSteps, data) =>
      dispatch(handleNavigateToModalInfo(step, data)),
    createRoutine: (data: MultiStepDataContext) =>
      dispatch(createRoutines(data)),
    closeDrawer: ({
      mode,
      step,
      isFinalized,
    }: {
      mode: RoutineMode;
      step: RoutineSteps;
      isFinalized: boolean;
    }) => dispatch(handleCloseDrawer(mode, step, isFinalized)),
    editRoutine: (routine, data) =>
      dispatch(handleUpdateRoutine(routine, data)),
    upsertTimeSlot: (routineUid: string, slot: TimeSlot) =>
      dispatch(upsertRoutineSchedule(routineUid, slot)),
    deleteRoutine: uid => dispatch(handleNavigateToDeleteRoutineModal(uid)),
    deleteTimeSlot: (routineUid, timeSlotUid, origin) =>
      dispatch(navigateToDeleteTimeSlotModal(routineUid, timeSlotUid, origin)),
    goToCantEnableTimeSlots: (routineUid: string) =>
      dispatch(handleNavigateToCantEnableTimeSlotsModal(routineUid)),
    onChangeContentFilterRuleType: (data, routine, mode) => {
      if (mode === 'EDIT') {
        dispatch(editContentFilterRuleType(data, routine, mode));
      }

      if (mode === 'CREATE') {
        dispatch(createContentFilterRuleType(data, routine, mode));
      }
    },
    goToSetContentFilterRulesInfo: (type: RoutineRuleExceptionType) =>
      dispatch(navigateToSetContentFilterModal(type)),
    onRemoveAllExceptions: (
      routineUid: string | undefined,
      type: RoutineRuleExceptionType,
      mode: RoutineMode,
      policyAction?: PolicyAction
    ) => {
      dispatch(handleRemoveAllExceptions(routineUid, type, mode, policyAction));
    },
    onSetRoutineCreationMode: (mode: RoutineCreationMode) =>
      dispatch(setTempRoutineMode(mode)),
    onUpgrade: (redirectConfirmation = true) => {
      if (!redirectConfirmation) return dispatch(handleNavigateToUpgradePage());
      return dispatch((dispatch, getState) => {
        const profileId = getProfileId(getState());
        if (profileId) return dispatch(handleNavigateToUpgradeModal(profileId));
      });
    },
  };
};

const CreateRoutineMultiStepContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRoutineMultiStep);

export default CreateRoutineMultiStepContainer;
