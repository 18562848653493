import { connect } from 'react-redux';
import TimeLimitsModal from '../../../components/Routines/Modals/TimeLimitsModal';
import { goBackIfHistory } from '../../../ducks/routing';
import { Dispatch } from '../../../store/state';

const mapDispatchToProps = (dispatch: Dispatch) => {
  const back = () => dispatch(goBackIfHistory());
  return {
    onClickAccept: back,
    onClickClose: back,
  };
};

const TimeLimitsModalContainer = connect(
  null,
  mapDispatchToProps
)(TimeLimitsModal);

export default TimeLimitsModalContainer;

export type TimeLimitsModalProps = ReturnType<typeof mapDispatchToProps>;
