import { Record, List } from 'immutable';
import * as Moment from 'moment';
import { DEFAULT_PROFILE_ID } from '../../constants';
import { LocationRecord } from '../location';
import {
  Profile,
  ProfileMethods,
  ProfilePayload,
  ProfileRecord as ProfileRecordType,
} from './types/Profile.types';
import { Gender } from './types/Gender.types';
import {
  PROFILE_STATUS_NO_DEVICE,
  PROFILE_STATUS_ACTIVE,
  PROFILE_STATUS_OFFLINE,
  ProfileStatus,
} from './types/ProfileStatus.types';
import { defaultPicture, genderMap } from './gender';
import {
  toProfileRecordBirthDate,
  toServerRequestBirthDate,
} from '../../helpers/appDataArrangement';

export const ProfileRecord = Record<Profile, ProfileMethods>({
  id: DEFAULT_PROFILE_ID,
  uid: '',
  name: '',
  status: PROFILE_STATUS_NO_DEVICE,
  picture: '',
  deviceCount: 0,
  gender: Gender.MALE,
  birthDate: Moment(),
  location: LocationRecord() as LocationRecord,
  lastseen: '',
  deviceIds: List(),
  isLinewizestudentLinked: false,
  activeRoutine: null,
});

ProfileRecord.fromPayload = (payload: ProfilePayload) => {
  let status: ProfileStatus;
  if (payload.deviceCount === undefined || payload.deviceCount === 0) {
    status = PROFILE_STATUS_NO_DEVICE;
  } else if (payload.status && payload.status.isOnline) {
    status = PROFILE_STATUS_ACTIVE;
  } else {
    status = PROFILE_STATUS_OFFLINE;
  }
  const location = LocationRecord.fromProfileResponse(payload);

  const gender = genderMap[payload.gender] || Gender.MALE;

  const picture = defaultPicture(payload.picture);

  const birthDate = toProfileRecordBirthDate(payload.birthDate);

  const deviceIds = List<number>(payload.deviceIds);

  return ProfileRecord({
    id: payload.id,
    uid: payload.uid,
    name: payload.name,
    status,
    picture,
    deviceCount: payload.deviceCount,
    gender,
    birthDate,
    deviceIds,
    location,
    lastseen: payload.lastseen,
    isLinewizestudentLinked: payload.isLinewizestudentLinked || false,
    activeRoutine: payload.activeRoutine,
  }) as ProfileRecordType;
};

ProfileRecord.serialize = record => {
  const { name } = record;
  const id = typeof record.id === 'number' ? record.id : undefined;
  const picture = defaultPicture(record.picture);

  const birthDate = toServerRequestBirthDate(record.birthDate);

  const gender = (
    Object.entries(genderMap).find(([, value]) => value === record.gender) || [
      'MALE',
      Gender.MALE,
    ]
  ).shift();

  return { id, name, birthDate, gender, picture };
};

export const isNotExistingProfile = (profile: ProfileRecordType) =>
  profile.id === DEFAULT_PROFILE_ID;

export const isEmptyProfilePicture = (picture: string | undefined) =>
  picture === '';

export const getProfileNameInitial = (profileName?: string) =>
  profileName?.substring(0, 1).toUpperCase();

export const isProfileWithActiveRoutine = (profile?: ProfileRecordType) =>
  Boolean(profile) && profile?.activeRoutine != null;
