import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Icon,
  IconType,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';

const ReTrialModal: React.FunctionComponent<{
  giftCode: string;
  giftConsumedSuccessfully: boolean;
  isLoading: boolean;
  title: string;
  content: string;
  onClickReTrial: (giftCode: string) => any;
  onClickClose: (giftConsumedSuccessfully: boolean) => any;
}> = ({
  giftCode,
  giftConsumedSuccessfully,
  title,
  isLoading,
  content,
  onClickReTrial,
  onClickClose,
}) => (
  <Modal
    width={modalDimensions.regular.width}
    onClickClose={() => onClickClose(giftConsumedSuccessfully)}
    header={{
      icon: <Icon type={IconType.gift} />,
    }}
    title={title}
    buttons={[
      <Button
        key="button"
        onClick={() => onClickReTrial(giftCode)}
        size={ButtonSize.medium}
        loading={isLoading}
        disabled={giftConsumedSuccessfully || isLoading}
        block
      >
        {t('Get my premium trial')}
      </Button>,
    ]}
  >
    <ModalStyledText>{content}</ModalStyledText>
  </Modal>
);

export default ReTrialModal;
