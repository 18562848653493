/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import {
  Button,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  Typography,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import type { ActivityTimelineDelegationInfoModalProps } from '../../containers/Modal/ActivityTimelineDelegationInfoModalContainer';

const ActivityTimelineDelegationInfoModal = ({
  onClickClose,
}: ActivityTimelineDelegationInfoModalProps) => {
  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={onClickClose}
          size={ButtonSize.medium}
          testId="activity-timeline-delegation-info-modal__confirm-button"
        >
          {t('OK')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="activity-timeline-delegation-info-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" alignSelf="center">
          <Layout marginTop="16" marginBottom="24" textAlignement="center">
            <Icon type={IconType.infoCircle} color="secondary" size="x4" />
          </Layout>
          <Layout textAlignement="center" margin="8">
            <Typography type="h5" weight="semibold" align="center">
              {t('Activity timeline')}
            </Typography>
            <Layout marginBottom="16" textAlignement="center">
              <Layout marginBottom="16">
                <Typography type="body2" align="center">
                  {t(
                    'The {1}Family activity{/1} tab shows activity on personal devices and on school devices outside of school hours.',
                    { 1: str => <b>{str}</b> }
                  )}
                </Typography>
              </Layout>
              <Typography type="body2" align="center">
                {t(
                  'The {1}School activity{/1} tab shows activity on school devices during school hours.',
                  { 1: str => <b>{str}</b> }
                )}
              </Typography>
            </Layout>
          </Layout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default ActivityTimelineDelegationInfoModal;
