import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  Avatar,
  AvatarSize,
  List,
  FreeStyleListItem,
  AvatarVariant,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  Gender,
  getAvatarTabs,
  getProfileNameInitial,
} from '../../records/profile';
import { svgAvatarsMap } from '../../helpers/avatarImageMaps';
import { genderImageAssetFromPng } from '../../helpers';
import Icon from '../Icons/Icon';

type AvatarSelectionModalProps = {
  onClickAvatar: (image: string) => void;
  onClickClose: () => void;
  picture: string;
  name: string;
};

const AvatarSelectionModal: React.FunctionComponent<
  AvatarSelectionModalProps
> = ({
  onClickAvatar,
  onClickClose,
  picture,
  name,
}: AvatarSelectionModalProps) => {
  const avatarsList = getAvatarTabs([Gender.MALE], 6, picture);

  const getImage = genderImageAssetFromPng(svgAvatarsMap, svgAvatarsMap.boy[1]);

  return (
    <Modal
      onClickClose={onClickClose}
      title={t('Select an avatar')}
      buttons={[
        <Button
          onClick={onClickClose}
          size={ButtonSize.medium}
          key="modal-button-1"
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      {avatarsList.map(tab => (
        <List
          className="AvatarSelectionModal__List"
          showSeparator={false}
          key={tab.label}
        >
          {tab.avatars.map(image => (
            <FreeStyleListItem key={image}>
              <Avatar
                discarded={image !== picture}
                onClick={() => onClickAvatar(image)}
                size={AvatarSize.medium}
              >
                <Icon path={getImage(image)} />
              </Avatar>
            </FreeStyleListItem>
          ))}
          <FreeStyleListItem key="empty-avatar">
            <Avatar
              onClick={() => onClickAvatar('')}
              size={AvatarSize.medium}
              variant={AvatarVariant.text}
            >
              {getProfileNameInitial(name)}
            </Avatar>
          </FreeStyleListItem>
        </List>
      ))}
    </Modal>
  );
};

export default AvatarSelectionModal;
