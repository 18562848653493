/* eslint-disable react/jsx-key */
import * as React from 'react';
import * as Immutable from 'immutable';
import {
  Icon as CustomIcon,
  IconType,
  IconColor,
  FlexLayout,
  Label,
  List,
  RegularStyleListItem,
  AvatarSize,
  FreeStyleListItem,
  TransparentButton,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import ProfilesForDeviceDropdown from './ProfilesForDeviceDropdown';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { DeviceRecord } from '../../records/device/types/Device.types';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

type DeviceUserAssignedToProps = {
  device: DeviceRecord;
  profiles: any;
  accountProfiles: Immutable.OrderedSet<ProfileRecord>;
  onClickAssignProfileFromDropdown: (userId) => void;
  onClickOpenDropdown: () => void;
};

const DeviceUserAssignedTo: React.FunctionComponent<
  DeviceUserAssignedToProps
> = ({
  profiles,
  accountProfiles,
  device,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
}: DeviceUserAssignedToProps) => {
  return (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <FlexLayout mainaxis="column" marginRight="16" marginLeft="16">
          <List listItemPaddingBottom="8" listItemPaddingTop="8">
            {device.users
              .map(user => [user, getUserProfile(user, profiles)])
              .map(([user, profile]) => (
                <FreeStyleListItem>
                  <FlexLayout mainaxis="column">
                    <FlexLayout mainaxis="row" marginBottom="16" marginLeft="8">
                      <UserField user={user} />
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout mainaxis="column">
                    <ProfilesForDeviceDropdown
                      actionElement={
                        <List>
                          <RegularStyleListItem
                            title={profile ? profile.name : t('Not Protected')}
                            icon={
                              profile && (
                                <ProfileAvatar
                                  size={AvatarSize.extraExtraSmall}
                                  profile={profile}
                                />
                              )
                            }
                            actionElement={
                              <CustomIcon
                                type={IconType.angleDown}
                                color={IconColor.primary}
                              />
                            }
                            testId="assignedTo"
                          />
                        </List>
                      }
                      profiles={accountProfiles}
                      deviceUserId={device?.users.first().uid}
                      onClickOpenDropdown={onClickOpenDropdown}
                      onClickAssignProfileFromDropdown={
                        onClickAssignProfileFromDropdown
                      }
                      userUid={user.uid}
                    />
                  </FlexLayout>
                </FreeStyleListItem>
              ))}
          </List>
        </FlexLayout>
      </RenderWhen>

      <RenderWhen screenSize={ScreenSize.Desktop}>
        <FlexLayout mainaxis="column" mainaxisAlignment="space-between">
          <List listItemPaddingBottom="8" listItemPaddingTop="8">
            {device.users
              .map(user => [user, getUserProfile(user, profiles)])
              .map(([user, profile]) => (
                <FreeStyleListItem>
                  <FlexLayout mainaxis="row" minWidth="100%">
                    <FlexLayout mainaxis="column" minWidth="50%">
                      <FlexLayout mainaxis="row">
                        <UserField user={user} />
                      </FlexLayout>
                    </FlexLayout>
                    <FlexLayout mainaxis="column" minWidth="50%">
                      <FlexLayout
                        mainaxis="column"
                        mainaxisAlignment="flex-start"
                      >
                        <ProfilesForDeviceDropdown
                          actionElement={
                            <TransparentButton
                              fillAvailableSpace
                              onClick={() => {}}
                            >
                              <List>
                                <RegularStyleListItem
                                  title={
                                    profile ? profile.name : t('Not Protected')
                                  }
                                  icon={
                                    profile && (
                                      <ProfileAvatar
                                        size={AvatarSize.extraExtraSmall}
                                        profile={profile}
                                      />
                                    )
                                  }
                                  actionElement={
                                    <CustomIcon
                                      type={IconType.angleDown}
                                      color={IconColor.primary}
                                    />
                                  }
                                  testId="assignedTo"
                                />
                              </List>
                            </TransparentButton>
                          }
                          profiles={accountProfiles}
                          deviceUserId={device?.users.first().uid}
                          userUid={user.uid}
                          onClickOpenDropdown={onClickOpenDropdown}
                          onClickAssignProfileFromDropdown={
                            onClickAssignProfileFromDropdown
                          }
                        />
                      </FlexLayout>
                    </FlexLayout>
                  </FlexLayout>
                </FreeStyleListItem>
              ))}
          </List>
        </FlexLayout>
      </RenderWhen>
    </React.Fragment>
  );
};

const UserField: React.FunctionComponent<{ user }> = ({ user }) => (
  <React.Fragment>
    <FlexLayout mainaxis="row" marginRight="16">
      <CustomIcon type={IconType.user} color={IconColor.primary} />
    </FlexLayout>
    <Label>{user?.name}</Label>
  </React.Fragment>
);

const getUserProfile = (user, profiles) =>
  profiles.filter(profile => user.profileId === profile.id).first();

export default DeviceUserAssignedTo;
