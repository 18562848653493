import { getLicense } from '../../selectors/license';
import { isFree } from '../../records/license';
import flags from '../../sideEffects/flags';
import State from '../../store/state';
import GiftRecord from '../../records/gift';

export const getGiftsState = (state: State) => state.get('gifts');

export const getGifts = (state: State) => getGiftsState(state).get('items');

export const isFetching = (state: State) =>
  getGiftsState(state).get('isFetching');

export const isConsumingGift = (state: State) =>
  getGiftsState(state).get('isConsumingGift');

export const giftHasBeenConsumedSuccessfully = (state: State) =>
  getGiftsState(state).get('giftHasBeenConsumedSuccessfully');

const getTotalCount = (state: State) => getGiftsState(state).get('count');

export const getFirstGiftCode = (state: State) => {
  const firstGift = getGifts(state).first() || GiftRecord();
  return firstGift.get('key');
};

export const shouldShowGift = (state: State) =>
  flags.adHocTrial.isEnabled() &&
  getTotalCount(state) > 0 &&
  isFree(getLicense(state).type);
