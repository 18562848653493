import api from '../../../api';
import { AnyError } from '../../../helpers/errorHandling';
import { getProfile } from '../../../selectors';
import { APIError } from '../../../lib/errors';
import { t } from '../../../lib/i18n';
import { TOAST_ICON_WARNING, showToast } from '../../toast';
import { toggleProfileDelegation } from '../thunk';
import { navigateToDelegationEnabledModal } from '../../../actions/Navigation';
import { showErrorToast } from '../../../containers/AccountSettings/helpers';
import { captureException } from '../../../helpers/sentry';

const STUDENT_DELEGATED_TO_ANOTHER_GUARDIAN =
  'STUDENT_DELEGATED_TO_ANOTHER_GUARDIAN';
const GUARDIAN_NOT_PERMITTED_TO_DISABLE_DELEGATION =
  'GUARDIAN_NOT_PERMITTED_TO_DISABLE_DELEGATION';
const FEATURE_DISABLED = 'FEATURE_DISABLED';

export const updateDelegationRule =
  (profileId: number, enableDelegation: boolean) => (dispatch, getState) => {
    const state = getState();
    const profileUid = getProfile(state, profileId)?.uid;
    if (enableDelegation) {
      return dispatch(postDelegationRule(profileUid));
    }
    return dispatch(deleteDelegationRule(profileUid));
  };

const delegationRuleErrorHandler = (error: AnyError) => {
  return async dispatch => {
    // controlled exceptions with special messages
    let toastMessage = t('Something went wrong');
    if (error instanceof APIError) {
      const errorBody = await error.json();
      if (error.status === 403) {
        if (errorBody.error === GUARDIAN_NOT_PERMITTED_TO_DISABLE_DELEGATION) {
          toastMessage = 'Guardian not permitted to disable delegation';
        }
      }
      if (error.status === 409) {
        if (errorBody.error === STUDENT_DELEGATED_TO_ANOTHER_GUARDIAN) {
          toastMessage = 'delegated to another guardian';
        }
      }
      if (error.status === 410) {
        toastMessage = 'not claimed by this guardian (gone)';
      }
      if (error.status === 412) {
        if (errorBody?.error === FEATURE_DISABLED) {
          toastMessage = 'delegation disabled';
        }
      }
    }
    return dispatch(showToast(toastMessage, TOAST_ICON_WARNING));
  };
};

const postDelegationRule = profileUid => {
  return dispatch => {
    return api.studentPoliciesDelegate
      .post({}, null, { profileUid })
      .catch(e => dispatch(delegationRuleErrorHandler(e)));
  };
};
const deleteDelegationRule = profileUid => {
  return dispatch => {
    return api.studentPoliciesDelegate.delete({ profileUid }).catch(e => {
      return dispatch(delegationRuleErrorHandler(e));
    });
  };
};

export const enabledDelegation = (profileId: number, enabled: boolean) => {
  return async dispatch => {
    try {
      await dispatch(toggleProfileDelegation(profileId, enabled));
      if (enabled) {
        dispatch(navigateToDelegationEnabledModal());
      }
    } catch (e) {
      dispatch(showErrorToast(e));
      captureException(e);
    }
  };
};
