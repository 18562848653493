import * as React from 'react';

const MainButton: React.FunctionComponent<{
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  iconResting: any;
  iconActive: any;
  label?: string;
}> = ({
  className = '',
  style = {},
  onClick = () => {},
  iconResting = '',
  iconActive = '',
  label = null,
}) => {
  const restingClassName = 'mfb-component__main-icon--resting';
  const activeClassName = 'mfb-component__main-icon--active';
  const mainClass = `mfb-component__button--main  ${className}`;
  if (label) {
    return (
      <div
        style={style}
        className={mainClass}
        onClick={onClick}
        data-mfb-label={label}
      >
        <i className={restingClassName}>{iconResting}</i>
        <i className={activeClassName}>{iconActive}</i>
      </div>
    );
  }
  return (
    <div style={style} className={mainClass} onClick={onClick}>
      <i className={restingClassName}>{iconResting}</i>
      <i className={activeClassName}>{iconActive}</i>
    </div>
  );
};

export default MainButton;
