import { Record } from 'immutable';
import { PolicyPayload, PolicyRecord } from '../policy/types/Policy.types';
import type { ScheduleCreatePayload } from '../schedule/types/Schedule.types';
import { RoutineColor } from '../../../palettes/RoutineColor';

export const routineIconType = {
  checkCircle: 0,
  utensils: 1,
  books: 2,
  backpack: 3,
  palette: 4,
  ban: 5,
  basketball: 6,
  headSet: 7,
  music: 8,
  bullseye: 9,
  bookOpen: 10,
  gamepadModern: 11,
  moon: 12,
  home: 13,
  joystick: 14,
  shield: 15,
  lock: 16,
  wifiSlash: 17,
} as const;

export type RoutineIconType = keyof typeof routineIconType;

export type RoutineIconTypePayload =
  typeof routineIconType[keyof typeof routineIconType];

export const routineType = {
  ROUTINE_TYPE_MANAGEMENT: 0,
  ROUTINE_TYPE_BLOCKED: 1,
} as const;
export type RoutineType = keyof typeof routineType;
export type RoutineTypePayload = typeof routineType[RoutineType];

export interface RoutineBase {
  uid: string;
  name: string;
  description: string;
  color: string;
  paused: boolean;
  enabled: boolean;
}

export interface Routine extends RoutineBase {
  icon: RoutineIconType;
  color: RoutineColor;
  type: RoutineType;
  policy: PolicyRecord;
}
export interface RoutinePayload extends RoutineBase {
  icon: number;
  type: RoutineTypePayload;
  policy: PolicyPayload;
}

export interface CreateRoutinePayload
  extends Omit<RoutinePayload, 'schedules'> {
  schedules: Array<ScheduleCreatePayload>;
}

export type RoutineRecord = Record<Routine>;
