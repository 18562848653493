/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Link } from 'react-router';
import { List } from 'immutable';
import { Switch, ToastManager, ToastType } from 'styleguide-react';
import { modalNames } from '../Modal/ModalSelector';
import { CACHE_GEOCODE_KEY } from '../../constants';
import { Key } from '../../ducks/persistentStorage';
import { ExperimentRecord } from '../../records';
import { StandaloneFlowFlag } from '../../helpers/query';
import { isBrowserPlatform } from '../../helpers';
import { ScenarioName, Scenario } from '../../ducks/scenarios/scenarios';
import { getAppVersion } from '../../helpers/globals';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import IOSPurchaseInfo from './IosPurchaseInfo';

type DebugProps = {
  onClickEdit: (profileId: number) => any;
  onToggle: (key: Key) => any;
  onChangeDate: (key: Key, value: string) => any;
  onChangeLastInteractionTs: (value: string) => any;
  onClickErrorButton: (withTimeout?: boolean) => any;
  onClickSetNotificationModal: () => any;
  onChangeExperiment: (name: string, value: string) => any;
  onClickStandalone: (flow: StandaloneFlowFlag) => () => any;
  setRatingPopUpLastDate: (newDate: string) => any;
  onChangeAppRateScenario: (scenario: ScenarioName) => void;
  onClickNavigateToUpgradeWithPromotion: (promotion: string) => any;
  profileId: number;
  storageValues: any;
  experiments: List<ExperimentRecord>;
  ratingPopupLastDate: string;
  scenarios: Scenario[];
  isIOS: boolean;
};

const Debug: React.FunctionComponent<DebugProps> = ({
  onClickEdit,
  onToggle,
  onChangeDate,
  onChangeLastInteractionTs,
  onClickErrorButton,
  onClickSetNotificationModal,
  onChangeExperiment,
  onClickStandalone,
  setRatingPopUpLastDate,
  onChangeAppRateScenario,
  onClickNavigateToUpgradeWithPromotion,
  profileId,
  storageValues,
  experiments,
  ratingPopupLastDate,
  scenarios,
  isIOS,
}: DebugProps) => {
  const [promotion, setPromotion] = React.useState('');
  // eslint-disable-next-line camelcase
  const featureFlags = (active_features || []).join(' ');
  const td = content => <td style={{ verticalAlign: 'top' }}>{content}</td>;
  return (
    <PageLayout
      title="Debug Screen"
      configuration={[{ screenSize: ScreenSize.Desktop, mainInsideCard: true }]}
      actions={[
        { actionName: 'back' },
        {
          actionName: 'edit',
          onClick: () => onClickEdit(profileId),
        },
      ]}
    >
      <div style={{ color: 'white', background: 'red', padding: '1rem' }}>
        <h4>⚠️ DEBUG SCREEN</h4>
        <p>Don't worry, you didn't break anything.</p>
        <p>
          Here you can find some information about the app version, perhaps for
          help from customer support.
        </p>
        <p>
          You can go back anytime. To get rid of this screen, click "Edit" and
          change the name or year.
        </p>
      </div>
      <div className="section container">
        <h4>🛠️ Build info</h4>
        <table>
          <tbody>
            {[
              ['📦', 'version', getAppVersion()],
              ['🌍', 'env', application_version.env],
              ['🌍', 'variantDevEnv', application_version.variantDevEnv],
              ['🏠', 'tenant', application_version.tenant],
              ['📱', 'platform', application_version.platform],
              ['🖥️', 'NODE_ENV', process.env.NODE_ENV],
              ['🎌', 'active_features', featureFlags],
              [
                '🏷',
                'git tag',
                git_info.tag || <span style={{ color: 'red' }}>(none!)</span>,
              ],
              ['🌱', 'git branch', git_info.branch],
              ['🔑', 'git sha1', git_info.sha1],
            ].map(([emoji, label, value]) => (
              <tr key={label as string}>
                {td(emoji)}
                {td(label)}
                {td(value)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isBrowserPlatform() && (
        <div className="section container">
          <h4>🛠️ Cordova Build info</h4>
          <table>
            <tbody>
              {[
                ['BuildInfo.packageName', global.BuildInfo.packageName],
                ['BuildInfo.basePackageName', global.BuildInfo.basePackageName],
                ['BuildInfo.baseUrl', global.BuildInfo.baseUrl],
                ['BuildInfo.displayName', global.BuildInfo.displayName],
                ['BuildInfo.name', global.BuildInfo.name],
                ['BuildInfo.version', global.BuildInfo.version],
                ['BuildInfo.versionCode', global.BuildInfo.versionCode],
                ['BuildInfo.debug', global.BuildInfo.debug],
                ['BuildInfo.buildType', global.BuildInfo.buildType],
                ['BuildInfo.flavor', global.BuildInfo.flavor],
                ['BuildInfo.buildDate', global.BuildInfo.buildDate.toString()],
                [
                  'BuildInfo.installDate',
                  global.BuildInfo.installDate.toString(),
                ],
              ].map(([label, value]) => (
                <tr key={label as string}>
                  {td(label)}
                  {td(value)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="section container">
        <h4> 🗃 API Options</h4>
        <table>
          <tbody>
            <tr key="ratingPopupLastDate">
              {td('Rating Popup Last Date')}
              {td(
                <input
                  id="ratingPopupLastDate"
                  type="datetime-local"
                  value={ratingPopupLastDate.substring(0, 16)}
                  onChange={event => setRatingPopUpLastDate(event.target.value)}
                />
              )}
            </tr>
          </tbody>
        </table>
        <h4>💾 Storage</h4>
        <table>
          <tbody>
            {booleanKeys.map(key => (
              <tr key={`storage_${key}`}>
                {td(key)}
                {td(
                  <Switch
                    id={`storage_toggle_${key}`}
                    checked={storageValues.get(key) === 'true'}
                    onChange={() => onToggle(key)}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          {dateKeys.map(key => (
            <div key={`storage_${key}`}>
              <label htmlFor={`datetime-${key}`}>{key}</label>
              <div>
                <input
                  id={`datetime-${key}`}
                  type="datetime-local"
                  value={storageValues.get(key).substring(0, 16)}
                  onChange={event => onChangeDate(key, event.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          <label htmlFor="update_lastineraction">
            Set last interaction TS (session)
          </label>
          <div>
            <input
              id="update_lastineraction"
              type="datetime-local"
              value={storageValues.get('lastInteractionTs').substring(0, 16)}
              onChange={event => onChangeLastInteractionTs(event.target.value)}
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn"
            onClick={() => localStorage.removeItem(CACHE_GEOCODE_KEY)}
          >
            Clear geocode cache
          </button>
        </div>
      </div>
      <div className="section container">
        <h4>💣 Error handling</h4>
        <p>
          <button
            type="button"
            className="btn waves-effect"
            onClick={() => {
              throw new Error('Forced runtime error in react click handler');
            }}
          >
            Force a runtime error inside click handler
          </button>
        </p>
        <p>
          <button
            type="button"
            className="btn waves-effect"
            onClick={() => onClickErrorButton(true)}
          >
            Force a runtime error asynchrounously with timeout
          </button>
        </p>
        <p>
          <button
            type="button"
            className="btn waves-effect"
            onClick={() => onClickErrorButton()}
          >
            Force a runtime error inside dispatcher
          </button>
        </p>
        {/* We have only integrated firebase on PASA */}
        {!isBrowserPlatform() && (
          <p>
            <button
              type="button"
              className="btn waves-effect"
              onClick={() => window.FirebasePlugin.sendCrash()}
            >
              Force firebase native crash
            </button>
          </p>
        )}
      </div>
      <div className="section container">
        <h4>💬 Dialogs</h4>
        <div className="collection">
          {modalNames.map(name => (
            <Link
              to={`/debug/${profileId}/modal/${name}`}
              className="collection-item"
              key={`link_modal_${name}`}
            >
              {name}
            </Link>
          ))}
        </div>
        <button
          type="button"
          className="btn waves-effect"
          onClick={onClickSetNotificationModal}
        >
          Set custom modal
        </button>
      </div>
      <div className="section container">
        <h4>💬 Notifications</h4>
        <div className="collection">
          {['error', 'warning', 'success', 'info'].map(type => (
            <div style={{ padding: '8px' }} key={type}>
              <button
                type="button"
                className="btn"
                onClick={() =>
                  ToastManager.getInstance().send(
                    'This is the title',
                    'This is the body',
                    type as ToastType
                  )
                }
              >
                {`Open ${type} notification`}
              </button>
            </div>
          ))}
          <div style={{ padding: '8px' }}>
            <button
              type="button"
              className="btn"
              onClick={() =>
                ToastManager.getInstance().sendWithoutTimeout(
                  'This is the title',
                  'This is the body',
                  'error'
                )
              }
            >
              Open error notification no timeout
            </button>
          </div>
        </div>
      </div>
      <div className="section container">
        <h4>🔬 Experiments</h4>
        <div>
          {experiments.map(experiment => (
            <div key={experiment.name}>
              <label>
                {experiment.name}
                <input
                  defaultValue={experiment.result}
                  onChange={event =>
                    onChangeExperiment(experiment.name, event.target.value)
                  }
                />
              </label>
            </div>
          ))}
        </div>
        <h4>💸 Purchase flow</h4>
        <p>
          <button
            type="button"
            className="btn waves-effect"
            onClick={onClickStandalone('purchase')}
          >
            Reload with purchase flow
          </button>
        </p>
        <p>
          <button
            type="button"
            className="btn waves-effect"
            onClick={onClickStandalone('purchase-at-limit')}
          >
            Reload with purchase-at-limit flow
          </button>
        </p>
      </div>
      {!isBrowserPlatform() && (
        <div className="section container">
          <h4>🤩 App Rating Popup</h4>
          <p>
            Select Scenario to show popup
            <select
              onChange={event => {
                event.stopPropagation();
                onChangeAppRateScenario(event.target.value as ScenarioName);
              }}
            >
              <option value={undefined}>None</option>
              {scenarios.map(scenario => (
                <option value={scenario.name} key={scenario.name}>
                  {scenario.name}
                </option>
              ))}
            </select>
          </p>
        </div>
      )}
      <div className="section container">
        <h4>💸 Navigate to upgrade with a promotion</h4>
        <label>
          Promotion
          <input
            type="text"
            onChange={event => setPromotion(event.target.value)}
          />
        </label>
        <button
          className="btn"
          type="button"
          onClick={() => onClickNavigateToUpgradeWithPromotion(promotion)}
        >
          Navigate
        </button>
      </div>

      {isIOS && <IOSPurchaseInfo />}
    </PageLayout>
  );
};

const booleanKeys: Key[] = [
  'welcomeModalShown',
  'panicRulesTourShown',
  'externalNavigationAccepted',
];

const dateKeys: Key[] = [
  'installationTs',
  'sessionStartTs',
  'appRateRateTs',
  'appRateFeedbackTs',
  'appRateRemindMeLaterTs',
];

export default Debug;
