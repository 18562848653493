import { isProfileLinkedWithLinewizeStudent } from '../../../selectors/profile';
import { hasDeviceAssigned } from '../../../selectors/profileDevices';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../../selectors/studentPolicies';
import flags from '../../../sideEffects/flags';
import State from '../../../store/state';

export const getIsProfileLinkedToLW = (
  state: State,
  profileId: number
): boolean => {
  const isProfileLinkedToSchool = isProfileLinkedWithLinewizeStudent(
    state,
    profileId
  );
  return isProfileLinkedToSchool;
};

export const getIsSchoolOnlyProfile = (
  state: State,
  profileId: number
): boolean => {
  const profileHasDevice = hasDeviceAssigned(state, profileId);
  const isLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  const isDelegatedToThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  if (isLinkedToSchool && !profileHasDevice && !isDelegatedToThisAccount) {
    return true;
  }
  return false;
};

export const getIsSchoolAndFamilyProfile = (
  state: State,
  profileId: number
): boolean => {
  const isProfileLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  const profileHasDevice = hasDeviceAssigned(state, profileId);
  return isProfileLinkedToSchool && profileHasDevice;
};

export const getIsProfileDelegated = (
  state: State,
  profileId: number
): boolean => {
  const isProfileDelegatedToThisAccount =
    flags.useDelegation.isEnabled() &&
    isDelegationEnabled(state) &&
    isStudentOptInForDelegationForThisAccount(state);
  const isProfileLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  const isSchoolDeviceDelegated = isProfileLinkedToSchool
    ? isProfileDelegatedToThisAccount
    : false;
  return isSchoolDeviceDelegated;
};

export const isCombinedDashboardProfile = (
  state: State,
  profileId: number
): boolean =>
  getIsSchoolAndFamilyProfile(state, profileId) ||
  getIsProfileDelegated(state, profileId);
