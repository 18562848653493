import * as React from 'react';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import { Field, reduxForm } from 'redux-form/immutable';
import { PlaceRecord } from '../../records';
import * as validators from '../../lib/validators/placeFormValidators';
import {
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  Label,
  FlexLayout,
  Button,
  ButtonSize,
} from 'styleguide-react';
import InputTextField from '../Form/InputTextField';
import SliderInputField from '../Form/SliderInputField';

export const formName = 'placeAdd';

const PlaceFormComponent: React.FunctionComponent<{
  places: List<PlaceRecord>;
  className: string;
  minValue: number;
  maxValue: number;
  formatLabel: string;
  isEditForm: boolean;
  initialValues: any;
  keyboardVisible: boolean;
  onAddressClick: () => any;
  onBlurInput: () => {};
  onFocusInput: () => {};
  onRadiusChangeComplete: () => any;
  handleScrolling: () => {};
  handleSubmit: () => any;
}> = ({
  minValue,
  maxValue,
  formatLabel,
  keyboardVisible,
  handleScrolling,
  handleSubmit,
  onAddressClick,
  onBlurInput,
  onFocusInput,
  onRadiusChangeComplete,
}) => (
  <FlexLayout
    className="AddPlaceFormWrapper"
    mainaxis="column"
    flexGrow={keyboardVisible ? '0' : '1'}
    flexShrink="0"
    paddingBottom="80"
  >
    <form onSubmit={handleSubmit} id={formName}>
      <Layout>
        <Field
          name="address"
          component={InputTextField}
          type="text"
          placeholder={t('Address')}
          icon={
            <Icon
              type={IconType.search}
              color={IconColor.primary}
              size={IconSize.sm}
            />
          }
          readOnly
          onFocus={onAddressClick}
        />
      </Layout>

      <Layout marginTop="8">
        <Field
          name="name"
          component={InputTextField}
          type="text"
          placeholder={t('Place name')}
          icon={
            <Icon
              type={IconType.mapMarkerAlt}
              color={IconColor.primary}
              size={IconSize.sm}
            />
          }
          maxlength="60"
          onFocus={onFocusInput}
          onBlur={onBlurInput}
        />
      </Layout>

      <Layout marginTop="8">
        <Label>{t('Distance')}</Label>
        <Layout marginTop="24" marginBottom="24">
          <Field
            name="radius"
            component={SliderInputField}
            handleScrolling={handleScrolling}
            onRadiusChangeComplete={onRadiusChangeComplete}
            minValue={minValue}
            maxValue={maxValue}
            formatLabel={formatLabel}
          />
        </Layout>
      </Layout>
      <Layout>
        <FlexLayout mainaxis="row" mainaxisAlignment="center" flexShrink="0">
          <Button size={ButtonSize.medium} form={formName} htmlType="submit">
            {t('Save')}
          </Button>
        </FlexLayout>
      </Layout>
      {/* include an invisible submit-button so that the mobile-keyboard has a "submit" button: */}
      <button type="submit" style={{ display: 'none' }} />
    </form>
  </FlexLayout>
);

const validate = (values, { places, initialValues, isEditForm }) => {
  const errors: any = {};
  if (!values.address) {
    errors.address = t('Please enter an address');
  } else if (
    !validators.isValidAddress({
      places,
      address: values.address,
      initialValues,
      isEditForm,
    })
  ) {
    errors.address = t('This address already exists in your places');
  }
  if (!values.name.trim()) {
    errors.name = t('Please enter a name');
  } else if (
    !validators.isValidName({
      places,
      name: values.name,
      initialValues,
      isEditForm,
    })
  ) {
    errors.name = t('This name already exists in your places');
  }

  return errors;
};

export type PlaceFormType = {
  name: string;
  latitude: number;
  longitude: number;
  radius: number;
  address: string;
};

export const formRecord = (values?: PlaceFormType): PlaceRecord => {
  if (values === undefined) {
    return PlaceRecord();
  }
  const record = PlaceRecord(values);
  return record.set('radius', record.radius);
};

const PlaceForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: formRecord(),
  validate,
  destroyOnUnmount: false,
})(PlaceFormComponent);

export default PlaceForm;
