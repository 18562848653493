import { activateRetrial } from '../../actions/RetrialActions';
import { Dispatch } from '../../store/state';

const ActivateRetrialContainer = () => null;

ActivateRetrialContainer.load = () => (dispatch: Dispatch) => {
  dispatch(activateRetrial());
};

export default ActivateRetrialContainer;
