import * as React from 'react';
import { t } from '../../lib/i18n';
import EditPhoneForm, { formName, EditPhoneFormRecord } from './EditPhoneForm';
import { ButtonSize, FlexLayout, Button } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

export interface EditPhoneProps {
  initialValues: EditPhoneFormRecord;
  onFormSubmit: () => void;
  country: string;
}

const EditPhone = ({
  initialValues,
  onFormSubmit,
  country,
}: EditPhoneProps) => (
  <PageLayout
    title={t('Edit Phone')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
  >
    <EditPhoneFields
      initialValues={initialValues}
      onFormSubmit={onFormSubmit}
      country={country}
    />
  </PageLayout>
);

const EditPhoneFields: React.FunctionComponent<{
  initialValues: EditPhoneFormRecord;
  onFormSubmit: () => void;
  country: string;
}> = ({ initialValues, onFormSubmit, country }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="space-between"
    flexGrow="2"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <EditPhoneForm
        className="EditPhoneForm"
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        country={country}
      />
    </FlexLayout>
    <FlexLayout mainaxis="row" mainaxisAlignment="center">
      <Button
        centered
        size={ButtonSize.medium}
        testId="save-edit-phone"
        htmlType="submit"
        form={formName}
      >
        {t('Save')}
      </Button>
    </FlexLayout>
  </FlexLayout>
);

export default EditPhone;
