import * as React from 'react';
import { Button, ButtonSize } from 'styleguide-react';
import { t } from '../../lib/i18n';
import IconCalls from '../Icons/IconCalls';
import IconGames from '../Icons/IconGames';
import IconLocation from '../Icons/IconLocation';
import IconMonitor from '../Icons/IconMonitor';
import IconTime from '../Icons/IconTime';
import IconWorld from '../Icons/IconWorld';
import * as WorksOn from '../../assets/base/images/works-on.png';

const PremiumConversionPanel: React.FunctionComponent<{
  imagePath: string;
  onClickButtonFullProtection: () => any;
}> = ({ imagePath, onClickButtonFullProtection }) => (
  <div className="PremiumCP__features section">
    <img
      className="PremiumCP__features--deviceImg"
      alt="device"
      src={imagePath}
    />
    <p className="PremiumCP__features__header">
      {t('Start protecting your family today!')}
    </p>
    <p className="PremiumCP__features__subheader">
      {t('Build a safe and productive digital environment with {{shortName}}')}
    </p>

    <div className="PremiumCP__features--container">
      <div className="PremiumCP__features__button--container">
        <Button
          size={ButtonSize.small}
          block
          onClick={onClickButtonFullProtection}
        >
          {t('Upgrade now')}
        </Button>
      </div>
      <div>
        <div className="PremiumCP__features--block">
          <div className="PremiumCP__features--body">
            <IconWorld />
            <p className="PremiumCP__features--text">
              {t('Block inappropriate content with our smart web filters')}
            </p>
          </div>
          <div className="PremiumCP__features--body">
            <IconTime />
            <p className="PremiumCP__features--text">
              {t('Manage and limit your children’s screen time by device')}
            </p>
          </div>
        </div>

        <div className="PremiumCP__features--block">
          <div className="PremiumCP__features--body">
            <IconGames />
            <p className="PremiumCP__features--text">
              {t('Limit games and apps and block inappropriate ones')}
            </p>
          </div>
          <div className="PremiumCP__features--body">
            <IconMonitor />
            <p className="PremiumCP__features--text">
              {t('Monitor their activity on social networks')}
            </p>
          </div>
        </div>

        <div className="PremiumCP__features--block">
          <div className="PremiumCP__features--body">
            <IconLocation />
            <p className="PremiumCP__features--text">
              {t('Track your children’s Android or iOS device on a map')}
            </p>
          </div>
          <div className="PremiumCP__features--body">
            <IconCalls />
            <p className="PremiumCP__features--text">
              {t('Monitor and block your children’s calls on Android')}
            </p>
          </div>
        </div>
      </div>
      <img
        className="PremiumCP__features--worksOnImg"
        alt="works-on"
        src={WorksOn}
      />
    </div>
  </div>
);

export default PremiumConversionPanel;
