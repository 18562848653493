import * as React from 'react';
import { t } from '../../lib/i18n';
import EditNameForm, { formName, EditNameFormRecord } from './EditNameForm';
import { ButtonSize, FlexLayout, Button } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const EditName: React.FunctionComponent<{
  initialValues: EditNameFormRecord;
  onFormSubmit: () => void;
}> = ({ initialValues, onFormSubmit }) => (
  <PageLayout
    title={t('Edit Name')}
    actions={[{ actionName: 'back' }]}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 1,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
      },
    ]}
  >
    <EditNameFields initialValues={initialValues} onFormSubmit={onFormSubmit} />
  </PageLayout>
);

const EditNameFields: React.FunctionComponent<{
  initialValues: EditNameFormRecord;
  onFormSubmit: () => void;
}> = ({ initialValues, onFormSubmit }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="space-between"
    flexGrow="2"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <EditNameForm
        className="EditNameForm"
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      />
    </FlexLayout>
    <FlexLayout mainaxis="row" mainaxisAlignment="center">
      <Button
        centered
        size={ButtonSize.medium}
        testId="save-edit-name"
        htmlType="submit"
        form={formName}
      >
        {t('Save')}
      </Button>
    </FlexLayout>
  </FlexLayout>
);

export default EditName;
