import * as React from 'react';
import {
  Badge,
  List as BadgeList,
  FreeStyleListItem,
  BadgeType,
} from 'styleguide-react';
import { getCategoryLabel, CategoryRecord } from '../../records/category';
import { List } from 'immutable';

const CategoryList: React.FunctionComponent<{
  categories: List<CategoryRecord>;
  keyPrefix: string;
}> = ({ categories, keyPrefix = '' }) => (
  <div className="CategoryList">
    <BadgeList horizontalOrientation listItemPaddingRight="8">
      {categories.map(category => (
        <FreeStyleListItem key={`${keyPrefix}${category.id}`}>
          <Badge
            text={getCategoryLabel(category.id)}
            type={BadgeType.neutral}
          />
        </FreeStyleListItem>
      ))}
    </BadgeList>
  </div>
);

export default CategoryList;
