import * as React from 'react';
import RoutineStepWrapper from '../RoutineStepWrapper';
import { t } from '../../../../lib/i18n';
import { EditRoutineProps } from './types/EditRoutineProps.types';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonType,
  Card,
  CardActionTitle,
  ClickableListItem,
  FlexLayout,
  GlobalType,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
  Link,
  List,
  ListItemVerticalAlign,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';
import IconTextCombo from '../../IconTextCombo/IconTextCombo';
import NavigationOptionItem from './NavigationOptionItem';
import { getSelectedDaysText } from '../../helpers/steps';
import AddCircleButton from '../../AddCircleButton/AddCircleButton';
import { getRoutinesIcon } from '../../helpers/routineIcons';
import { classNames } from '../../../../helpers';
import { militaryTimeToAMPMFormatWithHoursAndMinutes } from '../../../../helpers/dates';
import { TimeSlot } from '../../routines.types';
import Loader from '../../../base/Loader';
import RoutinesInfoAlert from '../../RoutinesInfoAlert/RoutinesInfoAlert';

export enum EditRoutineTest {
  prefix = 'routine-edit-step',
}

const isCustomFilterType = (type: EditRoutineProps['type']) =>
  type === 'POLICY_BLOCK_TYPE_NONE';

const TimeLimitInfoAlert = ({
  timeLimitApply,
  onClick,
}: {
  timeLimitApply: EditRoutineProps['timeLimitApply'];
  onClick: () => void;
}) => (
  <RoutinesInfoAlert
    icon={timeLimitApply ? IconType.hourglassHalf : IconType.infoCircle}
    testId={`${EditRoutineTest.prefix}-time-limit-info`}
    text={
      timeLimitApply
        ? t('Daily time limits apply to this routine. {1}Read more{/1}', {
            1: (str: string) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link onClick={onClick}>
                <strong>{str}</strong>
              </Link>
            ),
          })
        : t('This routine ignores daily time limits')
    }
  />
);

const TimeSlotsList = ({
  routineUid,
  routineEnabled,
  timeSlots,
  isMilitaryTime,
  getEditTimeSlotAction,
  getDeleteTimeSlotAction,
}: {
  routineUid: string;
  routineEnabled: boolean;
  timeSlots: TimeSlot[];
  isMilitaryTime: boolean;
  getEditTimeSlotAction: (
    routineUid: string,
    scheduleId: string
  ) => JSX.Element;
  getDeleteTimeSlotAction: (scheduleId: string) => JSX.Element;
}) => {
  if (!timeSlots.length) return null;

  return (
    <List
      listItemMarginBottom="24"
      className={classNames(
        'par-edit-routine__slots-list',
        !routineEnabled ? 'par-edit-routine__slots-list--disabled' : ''
      )}
      testId={`${EditRoutineTest.prefix}-timeslots`}
    >
      {timeSlots.map(slot => {
        const from = isMilitaryTime
          ? slot.from
          : militaryTimeToAMPMFormatWithHoursAndMinutes(slot.from);

        const to = isMilitaryTime
          ? slot.to
          : militaryTimeToAMPMFormatWithHoursAndMinutes(slot.to);

        const actionElement = routineEnabled
          ? getEditTimeSlotAction(routineUid, slot.uid!)
          : getDeleteTimeSlotAction(slot.uid!);

        return (
          <RegularStyleListItem
            key={slot.uid}
            className="par-edit-routine__slots-list-item"
            disabled={!routineEnabled}
            iconVerticalAlign={ListItemVerticalAlign.center}
            title={`${from} - ${to}`}
            upperSubtitle={getSelectedDaysText(slot.days)}
            actionElement={actionElement}
            actionElementVerticalAlign={ListItemVerticalAlign.center}
          />
        );
      })}
    </List>
  );
};

const EditRoutine = ({
  routineUid,
  type,
  name,
  icon,
  color,
  description,
  paused,
  timeSlots,
  contextData,
  isMilitaryTime,
  timeLimitApply = false,
  isFetchingTimeSlots = false,
  onUpdate,
  onDelete,
  prev,
  close,
  onEditStyle,
  onEditRules,
  onEditAppRules,
  onEditWebRules,
  onChangePause,
  onEditTimeSlot,
  onDeleteTimeSlot,
  onAddTimeSlot,
  onClickTimeLimitInfo,
}: EditRoutineProps) => {
  const routineEnabled = !paused;
  const onSaveRoutine = () => {
    onUpdate(contextData);
    close();
  };

  const onDeleteRoutine = () => {
    onDelete(routineUid);
  };

  const getEditTimeSlotAction = (routineUid: string, scheduleId: string) => {
    return (
      <ClickableListItem onClick={() => onEditTimeSlot(routineUid, scheduleId)}>
        <Icon
          type={IconType.edit}
          size={IconSize.lg}
          color={IconColor.secondary}
        />
      </ClickableListItem>
    );
  };

  const getDeleteTimeSlotAction = (scheduleId: string) => {
    return (
      <ClickableListItem
        onClick={() => onDeleteTimeSlot(routineUid, scheduleId)}
      >
        <Icon
          type={IconType.trashAlt}
          size={IconSize.lg}
          color={IconColor.error}
        />
      </ClickableListItem>
    );
  };

  const toggleRoutinePaused = (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const { checked } = event.currentTarget;
    onChangePause(checked);
  };

  const isRoutineDisabledAndNoTimeSlots = !routineEnabled && !timeSlots.length;
  const hasFinishedFetchingAndHasTimeSlots =
    !isFetchingTimeSlots && timeSlots.length;

  return (
    <RoutineStepWrapper
      testId={EditRoutineTest.prefix}
      prev={prev}
      close={close}
      next={onSaveRoutine}
      footerText={t('Done')}
      fixedFooter
      footerContent={
        <FlexLayout mainaxis="column" crossaxisAlignment="center">
          <Button
            block
            buttonType={ButtonType.plain}
            size={ButtonSize.medium}
            color={ButtonColor.error}
            onClick={onDeleteRoutine}
            testId={`${EditRoutineTest.prefix}-delete-routine`}
          >
            {t('Delete routine')}
          </Button>
        </FlexLayout>
      }
    >
      <FlexLayout
        mainaxis="column"
        className={classNames(
          'par-edit-routine',
          isRoutineDisabledAndNoTimeSlots
            ? 'par-edit-routine--hide-card-body'
            : ''
        )}
      >
        <Layout marginBottom="32">
          <IconTextCombo
            showBadge={timeLimitApply}
            title={name || ''}
            subtitle={description || ''}
            titleSize="large"
            subtitleSize="medium"
            icon={getRoutinesIcon(icon)}
            iconSize="medium"
            iconColor={color}
            invertColor
          />
        </Layout>
        <Layout marginBottom="16">
          <Card
            type={GlobalType.gray}
            header={{
              title: (
                <CardActionTitle
                  icon={
                    isFetchingTimeSlots ? (
                      <Loader size="xxs" color="secondary" />
                    ) : (
                      IconType.calendarAlt
                    )
                  }
                  color={GlobalType.secondary}
                >
                  {t('Enable time slots')}
                </CardActionTitle>
              ),
              actionElement: (
                <Switch
                  testId={`${EditRoutineTest.prefix}-toggle-pause-routine`}
                  checked={routineEnabled}
                  onChange={toggleRoutinePaused}
                />
              ),
            }}
          >
            {!isFetchingTimeSlots ? (
              <TimeSlotsList
                routineUid={routineUid}
                routineEnabled={routineEnabled}
                timeSlots={timeSlots}
                isMilitaryTime={isMilitaryTime}
                getEditTimeSlotAction={getEditTimeSlotAction}
                getDeleteTimeSlotAction={getDeleteTimeSlotAction}
              />
            ) : null}

            {routineEnabled && (
              <Layout
                marginTop={hasFinishedFetchingAndHasTimeSlots ? '32' : '0'}
              >
                <AddCircleButton
                  testId={`${EditRoutineTest.prefix}-create-timeslot`}
                  onAddExceptions={onAddTimeSlot}
                />
              </Layout>
            )}
          </Card>
        </Layout>
        {isCustomFilterType(type) ? (
          <React.Fragment>
            <Layout marginBottom="16">
              <NavigationOptionItem
                title={t('Edit app rules')}
                icon={IconType.icons}
                onClick={onEditAppRules}
                testId={`${EditRoutineTest.prefix}-edit-app-rules`}
              />
            </Layout>
            <Layout marginBottom="16">
              <NavigationOptionItem
                title={t('Edit website rules')}
                icon={IconType.globe}
                onClick={onEditWebRules}
                testId={`${EditRoutineTest.prefix}-edit-web-rules`}
              />
            </Layout>
          </React.Fragment>
        ) : (
          <Layout marginBottom="16">
            <NavigationOptionItem
              title={t('Edit rules')}
              icon={IconType.cog}
              onClick={onEditRules}
              testId={`${EditRoutineTest.prefix}-edit-rules`}
            />
          </Layout>
        )}
        <Layout marginBottom="16">
          <NavigationOptionItem
            title={t('Change style')}
            icon={IconType.palette}
            onClick={onEditStyle}
            testId={`${EditRoutineTest.prefix}-edit-style`}
          />
        </Layout>
        {isCustomFilterType(type) && (
          <TimeLimitInfoAlert
            timeLimitApply={timeLimitApply}
            onClick={onClickTimeLimitInfo}
          />
        )}
      </FlexLayout>
    </RoutineStepWrapper>
  );
};

export default EditRoutine;
