import { PauseProfileText } from '../../../businessLogic/pauseProfile/types';
import { formatHumanTwelveClockFormat } from '../../../helpers/dates';
import { t } from '../../../lib/i18n';
import { DeviceRecord } from '../../../records/device/types/Device.types';
import { Datetime } from '../../../types/dates';

export type PauseInfoTextMatcher = {
  [state in PauseProfileText]: string | null;
};

interface PauseInfoTextContext {
  startPeriodDatetime?: Datetime;
  endPeriodDatetime?: Datetime;
  locale: string;
  timezone: string;
  devicesWithSafeNetworkActive: DeviceRecord[];
}

export const modalInfoTextMatcher = (
  state: PauseProfileText,
  {
    startPeriodDatetime,
    endPeriodDatetime,
    locale,
    timezone,
    devicesWithSafeNetworkActive,
  }: PauseInfoTextContext
) => {
  const deviceNameInSafeNetwork = devicesWithSafeNetworkActive[0]?.name || '';

  const matcher: PauseInfoTextMatcher = {
    NO_TEXT: null,
    SCHOOL_CAN_UNPAUSE: t(
      'School can unpause the internet during school hours ({{schoolStartTime}} - {{schoolEndTime}})',
      {
        schoolStartTime: formatHumanTwelveClockFormat(
          startPeriodDatetime,
          timezone,
          locale
        ),
        schoolEndTime: formatHumanTwelveClockFormat(
          endPeriodDatetime,
          timezone,
          locale
        ),
      }
    ),
    SCHOOL_INSIDE_CANT_PAUSE: t(
      "School device is in school hours and can't be paused"
    ),
    SCHOOL_OUTSIDE_PAUSE: t('School device will be paused after school hours'),
    SAFE_NETWORK_SINGLE_DEVICE: t(
      '{{deviceName}} is connected to a Safe Network and can’t be paused',
      {
        deviceName: deviceNameInSafeNetwork,
      }
    ),
    SAFE_NETWORK_MULTIPLE_DEVICES: t(
      '{{numDevices}} devices are connected to a Safe Network and can’t be paused',
      {
        numDevices: devicesWithSafeNetworkActive.length,
      }
    ),
    SAFE_NETWORK_SCHOOL_INSIDE_SINGLE_DEVICE: t(
      'School device and {{deviceName}} can’t be paused right now',
      {
        deviceName: deviceNameInSafeNetwork,
      }
    ),
    SAFE_NETWORK_SCHOOL_INSIDE_MULTIPLE_DEVICES: t(
      '{{numDevices}} devices will be paused after school hours',
      {
        numDevices: devicesWithSafeNetworkActive.length + 1, // we add 1 as the school device is not in a SN
      }
    ),
    STUDENT_DELEGATED_TO_OTHER_GUARDIAN: t("School device can't be paused"),
  };

  return matcher[state];
};
