import { connect } from 'react-redux';
import AddPlaceHeader from '../../components/FamilyLocator/AddPlaceHeader';
import { resetAllPlaceForms } from '../../actions/FamilyLocatorActions';
import { goBackIfHistory } from '../../ducks/routing';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = (
  _,
  props: {
    title: string;
    placeUid?: string;
    showDeleteButton?: boolean;
  }
) => ({
  title: props.title,
  showDeleteButton: !!props.showDeleteButton,
});

const mapDispatchToProps = (
  dispatch,
  props: {
    placeUid?: string;
  }
) => ({
  onClick: () => {
    dispatch(goBackIfHistory());
    return dispatch(resetAllPlaceForms());
  },
  onClickDelete: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `/familyLocator/places/place/${props.placeUid}/action/editPlace/modal/RemovePlaceModal`,
      })
    );
  },
});

const AddPlaceHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPlaceHeader);

export default AddPlaceHeaderContainer;
