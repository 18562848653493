import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';

import {
  EditPhoneFormRecord,
  formRecord,
} from '../../components/AccountSettings/EditPhoneForm';
import {
  updateAccount,
  modifyAccount,
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { AccountRecord } from '../../records';
import { getAccount, getAccountCountry } from '../../selectors';
import { showErrorToast } from './helpers';
import EditPhone from '../../components/AccountSettings/EditPhone';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';
import State, { Dispatch } from '../../store/state';

const values = getFormValues('editPhone');

const mapStateToProps = (state: State) => {
  const { telephone } = getAccount(state);

  return {
    initialValues: {
      telephone,
    },
    country: getAccountCountry(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFormSubmit: () => dispatch(onFormSubmit()),
});

const onFormSubmit = () => {
  return async (dispatch: Dispatch, getState: () => State) => {
    const form: EditPhoneFormRecord = formRecord(values(getState()));
    const updatedAccount = setAccountRecord(form, getState());

    try {
      await dispatch(updateAccount(updatedAccount));
      await dispatch(goBackIfHistory());
      await dispatch(modifyAccount(updatedAccount));
      await dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK));
    } catch (error) {
      dispatch(requestModifyAccountError());
      dispatch(reverseAccountUpdate());
      dispatch(showErrorToast(error));
    }
  };
};

const setAccountRecord = (form: EditPhoneFormRecord, state: State) => {
  const account = getAccount(state).set('telephone', form.telephone);
  return AccountRecord(account);
};

const EditNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPhone);

export default EditNameContainer;
