import * as React from 'react';
import {
  EmptyPlaceholder,
  GlobalType,
  Tag,
  Layout,
  Svg,
  FlexLayout,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { SummaryDateRanges } from '../../constants';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { API_BIRTHDATE_FORMAT } from '../../helpers/dates';
import AppWebActivityIcon from '../Icons/summary/AppWebActivityIcon';
import ScreenTimeByHourBarChart from '../ActivityHistogram/ScreenTimeByHourBarChart/ScreenTimeByHourBarChart';
import ScreenTimeByDayBarChart from '../ActivityHistogram/ScreenTimeByDayBarChart/ScreenTimeByDayBarChart';
import type { ScreenSummaryChartProps } from '../../containers/ScreenTimeSummary/ScreenSummaryChartContainer';
import Loader from '../base/Loader';
import InfoIcon from '../Icons/InfoIcon';

const getTitle = (todayOrCustomPastDay: boolean) => {
  return !todayOrCustomPastDay
    ? t('Screen time by day')
    : t('Screen time by hour');
};

const getEmptyStateCopy = (profile: ProfileRecord | undefined) => {
  return t('No activity for {{name}} during the specified period', {
    name: profile?.name,
  });
};

const renderLoadingState = () => {
  return (
    <EmptyPlaceholder
      icon={<Loader size="small" color="secondary" />}
      text={t('Loading...')}
      fillAvailableSpace
      centered
      maxWidth="75%"
      smallText
    />
  );
};

const renderErrorState = (text: string) => {
  return (
    <EmptyPlaceholder
      icon={<InfoIcon />}
      text={t("{{activityTitle}} couldn't load", {
        activityTitle: text,
      })}
      fillAvailableSpace
      centered
      maxWidth="75%"
      smallText
    />
  );
};
const renderEmptyState = (profile: ProfileRecord | undefined) => {
  return (
    <EmptyPlaceholder
      centered
      fillAvailableSpace
      maxWidth="75%"
      smallText
      icon={
        <Svg height="64" width="64" color="gray-light">
          <AppWebActivityIcon />
        </Svg>
      }
      text={getEmptyStateCopy(profile)}
    />
  );
};

const getIsScreenSummaryEmpty = ({
  hasSeparatedScreenTime,
  todayOrCustomPastDay,
  selectedDayScreenTime,
  daysRangeScreenTime,
}: Pick<
  ScreenSummaryChartProps,
  'hasSeparatedScreenTime' | 'selectedDayScreenTime' | 'daysRangeScreenTime'
> & { todayOrCustomPastDay: boolean }) => {
  const timeKey = todayOrCustomPastDay ? 'minutes' : 'hours';
  const data = todayOrCustomPastDay
    ? selectedDayScreenTime
    : daysRangeScreenTime;
  if (!hasSeparatedScreenTime) {
    return data.every(value => value?.[timeKey] === 0);
  }
  return (
    data.every(
      value => !value?.defaultScreenTime || value?.defaultScreenTime === 0
    ) &&
    data.every(
      value =>
        !value?.routineScreenTime || value?.routineScreenTime?.length === 0
    )
  );
};

const ScreenSummaryChart = ({
  profile,
  firstDay,
  lastDay,
  currentTime,
  activeDateRange,
  selectedDayScreenTime,
  daysRangeScreenTime,
  isMilitaryTimeFormat,
  selectedPastDate,
  isFetchingInfo,
  hasError,
  isMobileView = false,
  routineColorsMap,
  hasSeparatedScreenTime,
  loadWidget,
}: ScreenSummaryChartProps) => {
  React.useEffect(loadWidget, []);

  const isToday = activeDateRange === SummaryDateRanges.Today;

  const todayOrCustomPastDay = [
    SummaryDateRanges.Today,
    SummaryDateRanges.CustomPastDay,
  ].includes(activeDateRange);

  const isEmpty = getIsScreenSummaryEmpty({
    hasSeparatedScreenTime,
    todayOrCustomPastDay,
    selectedDayScreenTime,
    daysRangeScreenTime,
  });

  const title = getTitle(todayOrCustomPastDay);

  const getDate = () => {
    if (activeDateRange === SummaryDateRanges.CustomPastDay) {
      return selectedPastDate;
    }
    return t('{{firstDay}} to {{lastDay}}', { firstDay, lastDay });
  };

  const renderInfoPanel = () => {
    if (activeDateRange === SummaryDateRanges.Today) return null;
    return (
      <Layout
        marginLeft="16"
        marginBottom="16"
        testId="par-screen-summary-chart__info-panel"
      >
        <Tag type={GlobalType.gray} text={getDate()} />
      </Layout>
    );
  };

  const getContent = () => {
    if (hasError) return renderErrorState(title);
    if (isFetchingInfo) return renderLoadingState();
    if (isEmpty) return renderEmptyState(profile);

    return (
      <FlexLayout mainaxis="column" height="100%" width="100%">
        {!isMobileView && (
          <div data-testid="par-screen-summary-chart__info-panel--top">
            {renderInfoPanel()}
          </div>
        )}
        {todayOrCustomPastDay ? (
          <ScreenTimeByHourBarChart
            data={selectedDayScreenTime}
            isMilitaryTimeFormat={isMilitaryTimeFormat}
            currentHour={isToday ? `${currentTime.format('HH')}:00` : undefined}
            routineColorsMap={routineColorsMap}
            hasSeparatedScreenTime={hasSeparatedScreenTime}
          />
        ) : (
          <ScreenTimeByDayBarChart
            data={daysRangeScreenTime}
            activeDateRange={activeDateRange}
            currentDate={currentTime.format(API_BIRTHDATE_FORMAT)}
            routineColorsMap={routineColorsMap}
            hasSeparatedScreenTime={hasSeparatedScreenTime}
          />
        )}
        {isMobileView && (
          <div data-testid="par-screen-summary-chart__info-panel--bottom">
            {renderInfoPanel()}
          </div>
        )}
      </FlexLayout>
    );
  };

  return (
    <Layout height="100%" width="100%" testId="par-screen-summary-chart">
      {getContent()}
    </Layout>
  );
};

export default ScreenSummaryChart;
