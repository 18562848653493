import * as React from 'react';
import {
  Card,
  Icon,
  IconType,
  IconFamily,
  EmptyPlaceholder,
  FlexLayout,
  ContentSeparator,
  CloudList,
  Quote,
  Svg,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { CardDimensions } from '../../../constants';
import { getNoActivityMsg, getNoSearchesMsg } from './uiHelpers';
import YouTubeIcon from '../../Icons/summary/YouTubeIcon';
import type { YoutubeSummaryOverviewCardWithObserverContainerProps } from '../../../containers/Summary/YoutubeSummaryCardWithObserverContainer';
import Loader from '../../base/Loader';

const YoutubeSummaryCardWithObserver: React.FunctionComponent<
  YoutubeSummaryOverviewCardWithObserverContainerProps
> = ({
  summary,
  isEmpty,
  width,
  dateRange,
  isLoading,
  hasError,
  isCombinedProfile,
  profileName,
  onExpand,
  goToInfoModal,
  loadInfo,
}: YoutubeSummaryOverviewCardWithObserverContainerProps) => {
  React.useEffect(() => {
    loadInfo();
  }, []);
  const hasSeeAllButton = !isEmpty && onExpand;
  return (
    <React.Fragment>
      <Card
        height={CardDimensions.height}
        width={width}
        header={{
          icon: <Icon type={IconType.youtube} family={IconFamily.brands} />,
          title: t('YouTube Summary'),
          actionElement: <Icon type={IconType.infoCircle} />,
          onClickAction: goToInfoModal,
        }}
        footer={{
          actionElement:
            hasSeeAllButton && !isLoading && !hasError
              ? t('See watched videos')
              : undefined,
          onClickAction: onExpand,
        }}
      >
        {getCardContent(
          summary,
          isEmpty,
          dateRange,
          hasError,
          isLoading,
          isCombinedProfile,
          profileName
        )}
      </Card>
    </React.Fragment>
  );
};

const getCardContent = (
  summary,
  isEmpty,
  dateRange,
  hasError,
  isLoading,
  isCombinedProfile,
  profileName
) => {
  if (hasError) {
    return (
      <EmptyPlaceholder
        icon={
          <Svg height="64" width="64" color="gray-light">
            <YouTubeIcon />
          </Svg>
        }
        text={t("YouTube Summary couldn't load")}
        maxWidth="75%"
        fillAvailableSpace
        smallText
        centered
      />
    );
  }
  if (isLoading) {
    return (
      <EmptyPlaceholder
        icon={<Loader size="small" color="secondary" />}
        text={t('YouTube Summary loading...')}
        maxWidth="75%"
        fillAvailableSpace
        smallText
        centered
      />
    );
  }
  if (isEmpty) {
    return (
      <EmptyPlaceholder
        icon={
          <Svg height="64" width="64" color="gray-light">
            <YouTubeIcon />
          </Svg>
        }
        text={getNoActivityMsg(dateRange, isCombinedProfile, profileName)}
        maxWidth="75%"
        fillAvailableSpace
        smallText
        centered
      />
    );
  }

  return (
    <FlexLayout mainaxis="column" marginTop="8">
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        marginBottom="24"
      >
        <span>{t('Videos')}</span>
        <span data-testid="total-videos">{summary.totalVideos}</span>
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        marginBottom="24"
        marginTop="8"
      >
        <span>{t('Searches')}</span>
        <span data-testid="total-searches">{summary.totalSearches}</span>
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout mainaxis="column" marginTop="8">
        {summary.lastSearches && summary.lastSearches.size !== 0 ? (
          <CloudList allowedListItemType={Quote} listItemMaxWidth="45%">
            {summary.lastSearches
              .sort((search1, search2) => search2.length - search1.length)
              .map(search => (
                <Quote key={search}>{search}</Quote>
              ))}
          </CloudList>
        ) : (
          <EmptyPlaceholder
            fillAvailableSpace
            centered
            text={getNoSearchesMsg(dateRange)}
            testId="no-searches-youtube-summary-card"
            smallText
          />
        )}
      </FlexLayout>
    </FlexLayout>
  );
};

export default YoutubeSummaryCardWithObserver;
