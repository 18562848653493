/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import {
  WEEKDAYS,
  weekdaysShortTranslations,
  Weekday,
} from '../../helpers/dates';
import { classNames } from '../../helpers';
import { Map } from 'immutable';

export type SelectedDaysValueType =
  | 'secondary'
  | 'gray'
  | 'error'
  | 'secondary-solid'
  | 'error-solid';

export type SelectedDaysType = Map<Weekday, SelectedDaysValueType>;

export interface DaySelectorProps {
  selectedDays: SelectedDaysType;
  onSelectDay: (day: Weekday) => void;
}

const DaySelector = ({
  selectedDays = Map(),
  onSelectDay,
}: DaySelectorProps) => {
  return (
    <div className="par-day-selector">
      {WEEKDAYS.map(renderDayButton(onSelectDay, selectedDays))}
    </div>
  );
};

const renderDayButton =
  (onSelectDay: (day: Weekday) => void, selectedDays: SelectedDaysType) =>
  (day: Weekday, index: number) =>
    (
      <div
        data-testid={`par-day-selector-button-${day}`}
        key={index}
        onClick={() => onSelectDay(day)}
        className={classNames(
          'par-day-selector__button',
          `par-day-selector__button--${selectedDays.get(day) || 'secondary'}`
        )}
      >
        {weekdaysShortTranslations()[day]}
      </div>
    );

DaySelector.displayName = 'DaySelector';

export default DaySelector;
