import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { Dispatch } from '../../store/state';
import SafeNetworkSettingsModal from '../../components/Modal/SafeNetworkSettingsModal';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(goBackIfHistory()),
});

const SafeNetworkSettingsInfoModalContainer = connect(
  null,
  mapDispatchToProps
)(SafeNetworkSettingsModal);

export type SafeNetworkSettingsInfoModalProps = ReturnType<
  typeof mapDispatchToProps
>;

export default SafeNetworkSettingsInfoModalContainer;
