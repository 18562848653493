import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import * as R from 'ramda';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';

import { formRecord } from '../../components/AccountSettings/EditNameForm';
import {
  updateAccount,
  modifyAccount,
  requestModifyAccountError,
  reverseAccountUpdate,
} from '../../ducks/account';
import { AccountRecord } from '../../records';
import { getAccount } from '../../selectors';
import { showErrorToast } from './helpers';
import EditName from '../../components/AccountSettings/EditName';
import { showToast, TOAST_ICON_TICK } from '../../ducks/toast';

const formName = 'editName';

const values = getFormValues(formName);

const mapStateToProps = state => {
  const { id, name, surname } = getAccount(state);
  return {
    initialValues: formRecord({ id, name, surname }),
  };
};

const mapDispatchToProps = dispatch => ({
  onFormSubmit: () => dispatch(onFormSubmit()),
});

function onFormSubmit() {
  return (dispatch, getState) => {
    const form = formRecord(values(getState()));
    return Promise.resolve(setAccountRecord(form, getState()))
      .then(R.tap(R.pipe(updateAccount, dispatch)))
      .then(R.tap(() => dispatch(goBackIfHistory())))
      .then(R.pipe(modifyAccount, dispatch))
      .then(() =>
        dispatch(showToast(t('Your account is updated'), TOAST_ICON_TICK))
      )
      .catch(error => {
        dispatch(requestModifyAccountError());
        dispatch(reverseAccountUpdate());
        dispatch(showErrorToast(error));
      });
  };
}

function setAccountRecord(form, state) {
  const account = getAccount(state)
    .set('name', form.name)
    .set('surname', form.surname)
    .set('fullname', `${form.name} ${form.surname}`);
  return AccountRecord(account);
}

const EditNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditName);

export default EditNameContainer;
