import * as Sentry from '@sentry/react';
import { SentryOptions } from './types/sentry.types';
import { isBrowserPlatform } from '../index';

export const captureException = (error, options?: SentryOptions) => {
  if (options) {
    Sentry.withScope(scope => {
      if (options.extra) {
        scope.setExtras(options.extra);
      }
      if (options.fingerprint) {
        scope.setFingerprint(options.fingerprint);
      }
      if (options.level) {
        scope.setLevel(options.level);
      }
      if (options.tags) {
        scope.setTags(options.tags);
      }
      Sentry.captureException(error, scope);
    });
  } else {
    Sentry.captureException(error);
  }
};

export const getSentryIgnoreErrors = (): RegExp[] => {
  const legitApiHandleErrors = [
    // POST REQUESTS
    /POST validateReceipt 409/, // Expected backend status while we are validating a purchase
    // PUT REQUESTS
    /PUT password 400/, // This error happens when the user tries to change the password and put a wrong old password
    // GET REQUESTS
    /GET lastAttribution 404/, // If the user does not have any attribution stored we receive this 404
    // Third party Errors
    /Could not load "util"/, // This errors is caused by Google Maps Plugin when the user has connection issues
  ];

  return isBrowserPlatform()
    ? [...legitApiHandleErrors, /No access\/refresh tokens for request/]
    : legitApiHandleErrors;
};
