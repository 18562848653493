import { connect } from 'react-redux';
import { getLicense } from '../../selectors';
import WelcomeToPremiumModal from '../../components/Modal/WelcomeToPremiumModal';
import { clickModalButton } from '../../actions/UpgradeActions';

const mapStateToProps = state => ({
  license: getLicense(state),
});

const mapDispatchToProps = dispatch => ({
  onClickLink: () => dispatch(clickModalButton()),
});

const WelcomeToPremiumContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeToPremiumModal);

export default WelcomeToPremiumContainer;
