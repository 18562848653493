import * as React from 'react';
import { t } from '../../lib/i18n';
import Flyover from '../Flyover/Flyover';
import timelineButtonImage from '../../assets/base/images/v3/flyovers/illustration_timeline.svg';
import { Button, ButtonSize } from 'styleguide-react';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const SummaryPremiumFlyover: React.FunctionComponent<{
  onClickClose: () => any;
  onClickUpgrade: () => any;
}> = ({ onClickClose, onClickUpgrade }) => (
  <Flyover
    description={t(
      "Upgrade to premium to see more than your 7 day kid's usage history"
    )}
    title={t('Premium History Data')}
    illustrationSrc={timelineButtonImage}
    onClickClose={onClickClose}
    actions={[{ screenSize: ScreenSize.Desktop, actionName: 'back' }]}
    button={
      <Button size={ButtonSize.medium} onClick={onClickUpgrade}>
        {t('Upgrade to premium')}
      </Button>
    }
  />
);

export default SummaryPremiumFlyover;
