import { NonEmptyMapRecord, fromJS } from 'immutable';
import { captureException } from '../../helpers/sentry';
import {
  ERROR_PUSH_SERVICE,
  INITIALIZED_PUSH_SERVICE,
  INITIALIZE_PUSH_SERVICE,
  RECEIVE_NOTIFICATION_PUSH_SERVICE,
  RECEIVE_PERMISSION_PUSH_SERVICE,
  RECEIVE_REGISTRATION_ID_PUSH_SERVICE,
} from './actions';

export default function pushService(
  state: NonEmptyMapRecord<{
    initializing: boolean;
    initialized: boolean;
    initialize: boolean;
    error: boolean;
    errorMessage: string;
    hasPermission: boolean;
    registrationId: string;
    pushPlatform: string;
    lastNotification: any;
  }> = fromJS({
    initializing: false,
    initialized: false,
    initialize: false,
    error: false,
    errorMessage: '',
    hasPermission: false,
    registrationId: undefined,
    pushPlatform: undefined,
    lastNotification: undefined,
  }),
  action
) {
  switch (action.type) {
    case INITIALIZE_PUSH_SERVICE:
      return state.merge({
        initializing: true,
        initialized: false,
      });
    case ERROR_PUSH_SERVICE:
      captureException(action.payload, {
        extra: state.toJS(),
      });
      return state.merge({
        error: true,
        errorMessage: action.payload.message,
        initializing: false,
      });
    case RECEIVE_PERMISSION_PUSH_SERVICE:
      return state.merge({
        hasPermission: action.payload,
      });
    case INITIALIZED_PUSH_SERVICE:
      return state.merge({
        initializing: false,
        initialized: true,
      });
    case RECEIVE_REGISTRATION_ID_PUSH_SERVICE:
      return state.merge({
        registrationId: action.payload.registrationId,
        pushPlatform: action.payload.pushPlatform,
      });
    case RECEIVE_NOTIFICATION_PUSH_SERVICE:
      return state.set('lastNotification', action.payload);
    default:
      return state;
  }
}
