import {
  LinewizeEventRecord,
  WEB_APP,
} from '../../records/linewizeEvent/types/linewizeEvents';
import {
  LinewizeEventAction,
  LinewizeEventWithActions,
} from './LinewizeEvent.types';
import { checkAllUrlHaveSameHostname } from '../../helpers/string';

const hasSignatureAction = (
  action: LinewizeEventAction,
  event: LinewizeEventRecord
) =>
  action.type === 'SIGNATURE' &&
  event.hasReview &&
  event.signatureId &&
  event.signatureIdentifiedAsApp &&
  event.type === WEB_APP;

const hasLinkAction = (
  action: LinewizeEventAction,
  event: LinewizeEventRecord
) =>
  action.type === 'LINK' &&
  event.type === WEB_APP &&
  !event.signatureIdentifiedAsApp &&
  (event.urls.length === 1 || checkAllUrlHaveSameHostname(event.urls));

export const computeLinewizeEventActions = (
  event: LinewizeEventRecord
): LinewizeEventWithActions => {
  const actions: Array<LinewizeEventAction> = [
    { type: 'SIGNATURE' },
    { type: 'LINK' },
  ];

  const selectedActions = actions.filter(
    action => hasSignatureAction(action, event) || hasLinkAction(action, event)
  );

  return {
    event,
    actions: selectedActions,
  };
};
