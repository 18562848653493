import { List } from 'immutable';
import { AppCategories } from '../ducks/appCategories';
import { ProfileId } from '../records/profile';
import { getAppsInCategory, getAppsUnfilteredInCategory } from '../selectors';
import State from '../store/state';
import { AppRuleRecord } from '../records/profileRules';

/**
 * This helper returns an object with the total apps in each of the given categories
 */
export const getTotalAppsInCategories = (
  state: State,
  categories: AppCategories,
  profileId: ProfileId,
  filtered = true
) => {
  return Object.entries(categories.toObject()).reduce((acc, [key, value]) => {
    const totalToCategory = filtered
      ? (
          getAppsInCategory(
            state,
            profileId,
            value.get('categoryId')
          ) as List<AppRuleRecord>
        ).size
      : (
          getAppsUnfilteredInCategory(
            state,
            profileId,
            value.get('categoryId')
          ) as List<AppRuleRecord>
        ).size ?? 0;

    acc[key] = totalToCategory;
    return acc;
  }, {});
};
