import 'materialize-css/sass/materialize.scss';
import './styles.scss';
import './styles-inlined.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import * as qinit from './qinit';
import store from './store';
import { Dispatch } from './store/state';
import gaSetup from './helpers/ga';
import {
  isBrowserPlatform,
  decodeDeepLink,
  isiOSPlatform,
  isAndroidPlatform,
} from './helpers';
import './intl';
import Root from './containers/Root';
import timeout from './lib/timeout';
import { setOpenedFromBackground } from './ducks/app';
import { fetchProfileRules } from './ducks/profileRules';
import { getProfileOrDefault, getCurrentProfileId } from './selectors';
import { isAuthenticated } from './selectors/app';
import { trackApplicationOpened } from './helpers/analytics';
import { rolloutSetup } from './helpers/featureFlags';
import googleMapsSetup from './helpers/googleMaps';
import { getAppVersion } from './helpers/globals';
import { logoutIfParentAppNotConfirmed } from './actions/StandaloneAppActions';
import {
  demandUserPushNotificationPermissionForPASI,
  pushServiceNotReadyForAndroid,
} from './ducks/pushService';
import { logout } from './sideEffects/logout';
import { fetchAccount } from './ducks/account';
import { getSentryIgnoreErrors } from './helpers/sentry';
import {
  isLockScreenEnabled,
  shouldBeLockedOnTimeout,
} from './selectors/lockState';
import {
  updateLockStateOnPause,
  checkBackHistoryWithLockScreen,
  lock,
} from './actions/LockScreenActions';
import { getMultiPlatformNavigation } from './helpers/multiPlatformNavigation';
import {
  isSegmentAvailable,
  isSentryAvailable,
} from './sideEffects/globals/thirdPartyServices';
import {
  setSentryTags,
  setSentryUserContext,
} from './helpers/sentry/sentryAdditionalInfo';
import { maybeRequestNativeNotificationPermissions } from './ducks/app/permissions/notifications';
import '@iframe-resizer/child';

/*
 * This callback handles URLs that might be openend from the Android or
 * iOS Native Application. It is called in these two cases:
 *  - Cordova plugins that handle deeplinks
 *  - Opening scheme URLs
 *
 * In both cases and for both platforms, Android and iOS,
 * this only called while the App is running in backgound.
 *
 * If the App is not running (cold start) there are two cases depending
 * on the platform:
 * iOS: The same callback is configure in the MDMAPP to allow to open the
 * Parent's App directly to the correct URLs:
 *   i.e. .../parents-app/index.html#/profiles/-1
 * Reference:
 * https://github.com/apache/cordova-ios/blob/master/CordovaLib/Classes/Private/Plugins/CDVHandleOpenURL
 *
 * Android: Uses the same strategy as iOS but in the Native part of the
 * application, opening the WebView directly to the correct URL:
 *   i.e. .../parents-app/index.html#/profiles/-1
 * Reference:
 * dev/qustodio/src/family/java/com/qustodio/qustodioapp/parentapp/ParentAppWebViewActivity.java
 */
global.handleOpenURL = deepLink => {
  qlog(`Received deeplink '${deepLink}'`);
  const path = decodeDeepLink(deepLink);
  const navigate = getMultiPlatformNavigation();
  if (path) {
    qlog(`Decoded deeplink path '${path}'`);
    navigate({
      type: 'location:href',
      src: `#${path}`,
    });
  } else {
    qlog(`Could not decode deeplink '${deepLink}'`);
  }
};

const sentrySetup = () => {
  if (process.env.NODE_ENV === 'production' && isSentryAvailable()) {
    // Sentry JS Config
    Sentry.init({
      dsn: qinit.tenant.parents_app.sentry.dsn,
      release: getAppVersion(), // eslint-disable-line no-undef
      environment: qinit.env,
      initialScope: {
        tags: {
          tenant: qinit.tenant.common.tenant.name,
          platform: window.cordova.platformId,
          git_commit: git_info.sha1,
          bundle_id: isBrowserPlatform()
            ? 'browser'
            : global.BuildInfo.packageName,
        },
        extra: {
          device_info: global.device,
        },
      },
      beforeSend(error) {
        const state = store.getState();
        error.tags = {
          ...error.tags,
          ...setSentryTags(state),
        };
        error.user = setSentryUserContext(state);
        return error;
      },
      ignoreErrors: getSentryIgnoreErrors(),
      dist: git_info.sha1,
    });
  }
};

if (!isiOSPlatform()) {
  gaSetup(qinit);
  googleMapsSetup(qinit);
}

/**
 * To avoid extra useless typing in the Segment.io provided snippet,
 * we need to wrap it in an IIF to type window as any.
 */
/* eslint-disable */
const segmentSetup = (window: any) => {
  if (isSegmentAvailable()) {
    const segmentDomain = 'https://cdn.segment.com/';

    // Segment.io snippet
    (function () {
      const analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
          ];
          analytics.factory = function (t) {
            return function () {
              const e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (let t = 0; t < analytics.methods.length; t++) {
            const e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t, e) {
            const n = document.createElement('script');
            n.type = 'text/javascript';
            n.async = !0;
            n.src = `${segmentDomain}analytics.js/v1/${t}/analytics.min.js`;
            const a = document.getElementsByTagName('script')[0];
            a.parentNode!.insertBefore(n, a);
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = '4.1.0';
          analytics.load(qinit.tenant.parents_app.mobile.segment.key);
        }
    })();
  }
};
/* eslint:enable */

/*
 * Due a name change from snake case to camel case for consistency
 * we need to ensure that the previous localStorage data is available
 * in the new format after an App update
 * https://qustodio.atlassian.net/browse/PAR-1151
 */
function fixRefreshTokenUpdate() {
  const storage = window.localStorage;
  if (!storage.refreshToken && storage.refresh_token) {
    storage.refreshToken = storage.refresh_token;
  }
  if (!storage.accessToken && storage.access_token) {
    storage.accessToken = storage.access_token;
  }
  if (!storage.parentDeviceId && storage.parent_device_id) {
    storage.parentDeviceId = storage.parent_device_id;
  }
}

function startApp() {
  fixRefreshTokenUpdate();
  ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
    document.getElementById('root')
  );
}

document.addEventListener(
  'deviceready',
  () => {
    if (process.env.NODE_ENV !== 'testing') {
      global.platform = window.cordova.platformId;

      sentrySetup();
      segmentSetup(window);

      rolloutSetup();

      demandUserPushNotificationPermissionForPASI();

      if (isiOSPlatform()) {
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.styleDefault();
        window.StatusBar.backgroundColorByHexString('#36383B');
        global.inAppStore = window.store;
      }
    }

    if (!isBrowserPlatform()) {
      global.AppRate = window.AppRate;

      document.addEventListener('pause', onPauseApp);
      document.addEventListener('resume', onResumeApp, false);
      window.addEventListener('contextmenu', event => {
        event.preventDefault();
        event.stopPropagation();
        return false;
      });
      const onBackKeyDown = () => {
        (store.dispatch as Dispatch)(checkBackHistoryWithLockScreen());
      };
      document.addEventListener('backbutton', onBackKeyDown, false);

      window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
    }

    if (isAndroidPlatform()) {
      maybeRequestNativeNotificationPermissions();
    }

    startApp();
  },
  false
);

if (window.cordova.mock) {
  timeout(startApp, 0);
}

const onPauseApp = () => {
  if (!isBrowserPlatform()) {
    if (pushServiceNotReadyForAndroid(store.getState())) return undefined;
    if (isLockScreenEnabled(store.getState())) {
      (store.dispatch as Dispatch)(updateLockStateOnPause());
    }
    return (store.dispatch as Dispatch)(logoutIfParentAppNotConfirmed());
  }
  return undefined;
};

const onResumeApp = () => {
  store.dispatch(setOpenedFromBackground(true));

  const state = store.getState();

  if (isAuthenticated(state)) {
    if (shouldBeLockedOnTimeout(state)) {
      (store.dispatch as Dispatch)(lock());
    }

    const profileId = getCurrentProfileId(state);
    (store.dispatch as Dispatch)(fetchAccount()).catch(error => {
      if (error.status === 404) {
        (store.dispatch as Dispatch)(logout());
      }
    });
    if (profileId) {
      const profile = getProfileOrDefault(state, parseInt(profileId, 10));
      (store.dispatch as Dispatch)(
        fetchProfileRules(profileId, profile.deviceIds, true)
      );
    }

    trackApplicationOpened({ fromBackground: true });
  }
};
