import * as React from 'react';
import { Slider } from 'styleguide-react';

const SliderInputField: React.FunctionComponent<{
  id: string;
  input: any;
  minValue: number;
  maxValue: number;
  disabled?: boolean;
  readOnly: boolean;
  icon: JSX.Element;
  formatLabel: string;
  onClick: (event: MouseEvent) => any;
}> = ({
  id,
  input,
  minValue,
  maxValue,
  disabled,
  readOnly,
  formatLabel,
  onClick,
}) => (
  <Slider
    {...input}
    id={id}
    disabled={disabled}
    onClick={onClick}
    readOnly={readOnly}
    block
    minValue={minValue}
    maxValue={maxValue}
    formatLabel={formatLabel}
  />
);

export default SliderInputField;
