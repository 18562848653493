import * as R from 'ramda';
import pathtoRegexp from 'path-to-regexp';

export const ensureHashInRoute = (route?: string): string | undefined => {
  if (!route) return route;
  return R.startsWith('#', route) ? route : R.concat('#', route);
};

/**
 * Allows path pattern matching the expressJS way.
 *
 * @param path Route structure that we want to test against (read path-to-regexp docs for more info).
 * @returns A RegExp instance.
 *
 * Follow the link below to read documentation for building route matcher using express model.
 * @link https://github.com/pillarjs/path-to-regexp/tree/v0.1.7
 */
export const pathMatcher = (path: string | RegExp) => {
  return pathtoRegexp(path);
};

/**
 * Allows strict path pattern matching with optional trailing slash and (modal/:modal) route.
 *
 * @param path Route structure that we want to test against (read path-to-regexp docs for more info).
 * @param allowModal Flag to disable optional modal match.
 * @returns A RegExp instance.
 *
 * Follow the link below to read documentation for building route matcher using express model.
 * @link https://github.com/pillarjs/path-to-regexp/tree/v0.1.7
 */
export const strictPathMatcher = (path: string | RegExp, allowModal = true) => {
  const modalMatch = allowModal ? '(/modal/:modal)?' : '';
  // we do this to not pass `//` or `//modal/whatever` as valid match
  const finalPath = path === '/' ? modalMatch : `${path}${modalMatch}`;
  return pathMatcher(finalPath);
};
