import * as Rox from 'rox-browser';

export enum Experiment {
  Original = 'Original',
  VariantA = 'Variant A',
  VariantB = 'Variant B',
  VariantC = 'Variant C',
}

const createAbExperiment = () =>
  new Rox.RoxString(Experiment.Original, [
    Experiment.Original,
    Experiment.VariantA,
    Experiment.VariantB,
    Experiment.VariantC,
  ]);

const flags = {
  familyLocator: new Rox.Flag(false),
  blockUnsupportedBrowsers: new Rox.Flag(false),
  isAlertNewApps: new Rox.Flag(false),
  youtubeAndroidPlatformReporting: new Rox.Flag(false),
  youtubeIOSPlatformReporting: new Rox.Flag(false),
  youtubeSummary: new Rox.Flag(false),
  geofencing: new Rox.Flag(false),
  calendarPicker: new Rox.Flag(false),
  useSmartGeo: new Rox.Flag(false),
  insights: new Rox.Flag(false),
  upgradeToPremiumButton: createAbExperiment(),
  adHocTrial: new Rox.Flag(false),
  unifiedRouting: new Rox.Flag(false),
  timeRulesSetScenario: new Rox.Flag(false),
  appRulesSetScenario: new Rox.Flag(false),
  youtubeRulesSetScenario: new Rox.Flag(false),
  webFilterRulesSetScenario: new Rox.Flag(false),
  timelineEventActionScenario: new Rox.Flag(false),
  questionableWebSearches: createAbExperiment(),
  extraTime: new Rox.Flag(false),
  webOnboardingToAndroidExperiment: createAbExperiment(),
  iosPreConsentTrackingModal: new Rox.Flag(false),
  kidDeviceSetupFromParentsApp: createAbExperiment(),
  multiParentInitiative: new Rox.Flag(false),
  dedicatedSupportV2: new Rox.Flag(false),
  closeAccount: new Rox.Flag(false),
  lockScreen: new Rox.Flag(false),
  schoolPauseButton: new Rox.Flag(false),
  familyPauseButton: new Rox.Flag(false),
  safeNetworkSettings: new Rox.Flag(false),
  schoolTimeline: new Rox.Flag(false),
  useChargebeeV3PC2: new Rox.Flag(false),
  showTopbarFreeFeatures: new Rox.Flag(false),
  blockToFreePremiumFeatures: new Rox.Flag(false),
  useUnifiedDashboard: new Rox.Flag(false),
  useDelegation: new Rox.Flag(false),
  useDelegationAutoOptin: new Rox.Flag(false),
  tmpRoutineSwitch: new Rox.Flag(false),
  switchPlansOnPASA: new Rox.Flag(false),
  useWellbeingNewTag: new Rox.Flag(false),
  useAppCategories: new Rox.Flag(false),
  tmpAdvanceAntiTampering: new Rox.Flag(false),
};

export default flags;
