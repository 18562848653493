import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutContentSpacing } from 'styleguide-react/build/components/Layout/types';
import PageTitleLayout from '../../PageTitleLayout';
import type { PageTitleLayoutProps } from '../../PageTitleLayout';
import { DesktopPageLayoutProps } from '../types';
import { getPixels } from './getPixels';

const DesktopPageTitle: React.FC<
  PageTitleLayoutProps & DesktopPageLayoutProps
> = ({
  title,
  actions,
  configuration,
  showProfile,
  customPageTitle,
  subtitle,
  topbarChildren,
  beforePageTitle,
  afterPageTitle,
}: PageTitleLayoutProps & DesktopPageLayoutProps) => (
  <FlexLayout
    mainaxis="column"
    crossaxisAlignment={configuration?.columns === 1 ? 'center' : undefined}
    marginBottom="40"
    testId="DesktopPageLayout_PageTitle_Container"
    width="100%"
  >
    {beforePageTitle && (
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        width={getPixels(configuration) as LayoutContentSpacing}
        padding="8"
        marginBottom="16"
        marginTop="56"
        className="par-page-layout__header"
        testId="desktop_before_page_title"
      >
        {beforePageTitle}
      </FlexLayout>
    )}
    <FlexLayout
      mainaxis="row"
      mainaxisAlignment="center"
      width={getPixels(configuration) as LayoutContentSpacing}
    >
      <PageTitleLayout
        showProfile={showProfile}
        actions={actions}
        title={
          configuration?.title !== undefined ? configuration?.title : title
        }
        centered={configuration?.titleCentered}
        topBar={topbarChildren as React.ReactElement}
        customPageTitle={customPageTitle}
        subtitle={subtitle}
      />
    </FlexLayout>
    {afterPageTitle && (
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        width={getPixels(configuration) as LayoutContentSpacing}
        margin="16"
        testId="desktop_after_page_title"
      >
        {afterPageTitle}
      </FlexLayout>
    )}
  </FlexLayout>
);

export default DesktopPageTitle;
