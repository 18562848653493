import * as React from 'react';
import {
  Button,
  AlertBox,
  AlertBoxType,
  FlexLayout,
  ButtonSize,
  IconType,
  IconSize,
  Icon,
  Layout,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import logo from '../../assets/base/images/v3/logo/logo_full.svg';
import illustration from '../../assets/base/images/v3/flyovers/illustration_initial_screen.svg';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const Initial: React.FunctionComponent<{
  onClickLogin: () => any;
  onClickSignup: () => any;
  hasError: boolean;
}> = ({ onClickLogin, onClickSignup, hasError }) => (
  <PageLayout
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
        hideHeader: true,
        fullHeightCard: true,
      },
      { screenSize: ScreenSize.Mobile, columns: 1, hideHeader: true },
    ]}
  >
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      height="100%"
    >
      <FlexLayout
        maxWidth="320"
        minHeight="520"
        maxHeight="800"
        mainaxis="column"
      >
        {hasError && (
          <FlexLayout mainaxis="column" flexGrow="1" margin="8">
            <AlertBox
              type={AlertBoxType.error}
              icon={
                <Icon type={IconType.exclamationCircle} size={IconSize.lg} />
              }
              showInfoIcon
            >
              {t("Something went wrong. Let's try that again")}
            </AlertBox>
          </FlexLayout>
        )}

        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          mainaxisAlignment="center"
          flexGrow="1"
          marginBottom="48"
        >
          <img src={logo} alt="logo" width="160" height="28" />
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          flexGrow="2"
          textAlignement="center"
        >
          <img src={illustration} alt="illustration" width="176" height="176" />

          <Layout marginTop="48">
            <h4>
              <strong>{t('Digital parenting made easy')}</strong>
            </h4>
          </Layout>

          <p>
            {t(
              "Supervise your kids' screen time on all their devices, from a single dashboard."
            )}
          </p>

          <Layout marginTop="32" marginBottom="24" width="100%">
            <Button block size={ButtonSize.medium} onClick={onClickSignup}>
              {t('Get started')}
            </Button>
          </Layout>

          <FlexLayout
            mainaxis="column"
            textAlignement="center"
            margin="8"
            paddingBottom="48"
          >
            <span onClick={onClickLogin} role="button">
              <strong>
                {t('Already have an account? {1}Log in{/1}', {
                  // eslint-disable-next-line no-script-url
                  1: str => <a href="javascript:void(0)">{str}</a>,
                })}
              </strong>
            </span>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </PageLayout>
);

export default Initial;
