import * as React from 'react';
import { t } from '../../../lib/i18n';
import { downloadLink } from '../../../helpers';

import {
  CopyBox,
  FlexLayout,
  InstructionBlock,
  Layout,
} from 'styleguide-react';

const OnboardingShareSection: React.FunctionComponent<{
  shareSectionText: string;
  socialShareGroupText: string;
  showDownloadLink?: boolean;
  onClickSocialShareCopy: () => any;
}> = ({ shareSectionText, showDownloadLink, onClickSocialShareCopy }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="center"
    crossaxisAlignment="center"
  >
    <p className="OnboardingShareSection__text">
      {shareSectionText}
      <br />
      {t(
        'From there, download {{shortName}} and follow the instructions on screen.'
      )}
    </p>

    {showDownloadLink && (
      <FlexLayout mainaxis="column">
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
        >
          <CopyBox
            text={downloadLink}
            url={downloadLink}
            confirmationText={t('Copied to clipboard')}
            onClickCallback={onClickSocialShareCopy}
          />
        </FlexLayout>

        <Layout marginTop="40" textAlignement="center">
          <InstructionBlock>
            {t('Come back here when the installation is finished')}
          </InstructionBlock>
        </Layout>
      </FlexLayout>
    )}
  </FlexLayout>
);

export default OnboardingShareSection;
