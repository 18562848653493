import * as React from 'react';

const UpgradeBadge: React.FunctionComponent<{
  iconSrc: string;
  title: string;
  text: string;
}> = ({ iconSrc, title, text }) => (
  <div className="UpgradeBadge">
    <img className="UpgradeBadge__icon" alt="upgrade" src={iconSrc} />
    <div className="UpgradeBadge__text">
      <strong>{title}</strong>
      <br />
      {text}
    </div>
  </div>
);

export default UpgradeBadge;
