import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import AddDeviceInstructions from '../components/Devices/AddDeviceInstructions';
import { formName } from '../components/base/SelectAddDeviceInstructions';
import { DevicePlatform } from '../constants';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { getProfileOrDefault } from '../selectors/profileDevices';
import { trackablePage } from '../helpers/hocs';
import { PageNames } from '../helpers/analytics';
import { isProfileLinkedWithLinewizeStudent } from '../selectors';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

const values = getFormValues(formName);

const mapStateToProps = (
  state,
  { params }
): {
  platform: DevicePlatform;
  profile?: ProfileRecord;
  showProfileSummarySelector: boolean;
} => {
  const formValues = values(state);
  return {
    profile: getProfileOrDefault(state, params.profileId),
    platform: formValues ? formValues.get('platform') : DevicePlatform.None,
    showProfileSummarySelector: isProfileLinkedWithLinewizeStudent(
      state,
      params.profileId
    ),
  };
};

const mapDispatchToProps = (
  dispatch,
  { params: { profileId } }
): { onClickEdit?: () => any } => ({
  onClickEdit: () => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/edit`,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(AddDeviceInstructions, PageNames.KidDeviceInstallInstructions));
