import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

const LogoutModal: React.FunctionComponent<{
  onClickLogout: () => any;
  onClickClose: () => any;
}> = ({ onClickLogout, onClickClose }) => (
  <Modal
    size="small"
    onClickClose={onClickClose}
    title={t('Are you sure you want to log out?')}
    header={{ icon: <Icon type={IconType.questionCircle} /> }}
    buttons={[
      <Button
        key="button2"
        onClick={onClickLogout}
        size={ButtonSize.medium}
        block
      >
        {t('Log out')}
      </Button>,
      <Button
        key="button1"
        onClick={onClickClose}
        size={ButtonSize.medium}
        block
        buttonType={ButtonType.plain}
      >
        {t('Cancel')}
      </Button>,
    ]}
  />
);

export default LogoutModal;
