import {
  DAYTIME_MORNING,
  DAYTIME_AFTERNOON,
  DAYTIME_NIGHT,
  RULE_CALLS_AND_SMS,
  RULE_DAILY_TIME_LIMITS,
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_PANIC_BUTTON,
  RULE_RESTRICTED_TIMES,
  RULE_WEB_FILTERING,
  RULE_YOUTUBE,
  RULE_ROUTINES,
} from './index';

export const daytimeCollapsedPropertyName = daytime => {
  const collapsedPropertyName = {
    [DAYTIME_MORNING]: 'isMorningCollapsed',
    [DAYTIME_AFTERNOON]: 'isAfternoonCollapsed',
    [DAYTIME_NIGHT]: 'isNightCollapsed',
  }[daytime];

  return collapsedPropertyName;
};

export type ProfileRulesListType =
  | typeof RULE_WEB_FILTERING
  | typeof RULE_GAMES_AND_APPS
  | typeof RULE_DAILY_TIME_LIMITS
  | typeof RULE_ROUTINES
  | typeof RULE_RESTRICTED_TIMES
  | typeof RULE_LOCATION
  | typeof RULE_CALLS_AND_SMS
  | typeof RULE_PANIC_BUTTON
  | typeof RULE_YOUTUBE;

export const profileRulesList: ProfileRulesListType[] = [
  RULE_WEB_FILTERING,
  RULE_GAMES_AND_APPS,
  RULE_DAILY_TIME_LIMITS,
  RULE_ROUTINES,
  RULE_RESTRICTED_TIMES,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
];
