import * as React from 'react';
import ActivityStyledItem from '../../ActivityTimeline/ActivityStyledItem/ActivityStyledItem';
import {
  getCategoriesTags,
  getUpperSubtitle,
  getWebAppIcon,
} from './common/schoolEventsCommon';
import { BaseLinewizeEvent } from '../types/LinewizeEventsBase.types';
import ActivityStyledItemAction from '../../ActivityTimeline/ActivityStyledItemAction';
import {
  checkAllUrlHaveSameHostname,
  extractDomain,
} from '../../../helpers/string';
import { t } from '../../../lib/i18n';

const WebAppEvent: React.FC<BaseLinewizeEvent> = ({
  event,
  eventActions,
  onActionClick,
}) => {
  const {
    icon,
    action,
    dt,
    key,
    categoryName,
    atSchoolHours,
    urls = [],
    signatureName,
    signatureIdentifiedAsApp,
  } = event;

  const getTitle = () => {
    if (signatureIdentifiedAsApp) {
      return signatureName;
    }
    if (checkAllUrlHaveSameHostname(urls)) {
      return extractDomain(urls[0]);
    }
    return t('{{signatureName}} websites', {
      signatureName,
    });
  };

  const getEventTags = () => {
    const tags: Array<string> = [];
    if (categoryName) {
      tags.push(categoryName);
    }

    if (
      checkAllUrlHaveSameHostname(urls) &&
      signatureName &&
      !signatureIdentifiedAsApp
    ) {
      tags.push(signatureName);
    }

    return getCategoriesTags(tags, action, key);
  };

  return (
    <ActivityStyledItem
      title={getTitle()}
      icon={getWebAppIcon(icon, action, signatureIdentifiedAsApp)}
      upperSubtitle={getUpperSubtitle(action, dt)}
      tags={!signatureIdentifiedAsApp ? getEventTags() : []}
      isSchoolTime={atSchoolHours}
      actionElement={
        <ActivityStyledItemAction
          actions={eventActions}
          event={event}
          title={getTitle()}
          onActionClick={onActionClick}
        />
      }
    />
  );
};

export default WebAppEvent;
