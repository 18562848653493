import * as React from 'react';
import { formatHumanTwelveClockFormat } from '../helpers/dates';
import { t } from '../lib/i18n';
import Date from './Date/Date';
import { SchoolPeriodTimeProps } from './SchoolPeriodTime.types';

/*
 * This component return a string with a formatted dates in the account timezone
 * to show the beginning and end of a school period
 */
const SchoolPeriodTime: React.FunctionComponent<SchoolPeriodTimeProps> = ({
  periodStart,
  periodEnd,
  isTomorrow = false,
  dateFormatter = formatHumanTwelveClockFormat,
}: SchoolPeriodTimeProps) => {
  return (
    <span className="no-breaking-lines-text">
      (<Date time={periodStart} dateFormatCallback={dateFormatter} />-
      <Date time={periodEnd} dateFormatCallback={dateFormatter} />
      {isTomorrow && ` ${t('tomorrow')}`})
    </span>
  );
};

export default SchoolPeriodTime;
