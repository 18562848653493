import { connect } from 'react-redux';

import {
  linkAllPendingStudents,
  navigateToWrongInfoErrorPage,
} from '../../actions/onboarding/schools/OnboardingSchoolsActions';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';

import LinkStudents from '../../components/Schools/LinkStudents';
import { fetchStudents } from '../../ducks/students';
import { StudentAction } from '../../ducks/types/action/StudentAction.types';
import {
  getStudents,
  isCallingLinkPendingStudents,
  isFetchingStudents,
} from '../../selectors/student';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import {
  ButtonNames,
  GenericPageNames,
  PageNames,
  trackButtonClicked,
  trackOpenedBurgerMenu,
} from '../../helpers/analytics';
import { t } from '../../lib/i18n';
import store from '../../store';
import State, { Dispatch } from '../../store/state';
import { trackablePage } from '../../helpers/hocs';

const mapStateToProps = (state: State) => ({
  students: getStudents(state),
  isFetching: isFetchingStudents(state),
  isCallingLinkPendingStudents: isCallingLinkPendingStudents(state),
});

const mapDispatchToProps = (dispatch: Dispatch<StudentAction>) => ({
  onClickNext: () => {
    trackButtonClicked(GenericPageNames.StudentList, ButtonNames.LinkNow);
    dispatch(linkAllPendingStudents());
  },
  onClickSomethingNotRight: () => {
    trackButtonClicked(GenericPageNames.StudentList, ButtonNames.Help);
    dispatch(navigateToWrongInfoErrorPage());
  },
});

const LinkStudentsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(LinkStudents, PageNames.StudentList));

LinkStudentsConnected.load = () => dispatch => dispatch(fetchStudents());

export type LinkStudentsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LinkStudentsContainer = OnboardingWrapper({
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(LinkStudentsConnected);

export default LinkStudentsContainer;
