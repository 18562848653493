import * as React from 'react';
import { FlexLayout, IconAdapter, ListTitle } from 'styleguide-react';
import DayDial, { DayDialProps } from '../TimeLimits/DayDial';

type AppIconProps = {
  title: string;
  icon: string;
};

const AppIconMobile: React.FC<AppIconProps> = ({
  title,
  icon,
}: AppIconProps) => (
  <FlexLayout mainaxis="row">
    <FlexLayout mainaxis="column" alignSelf="center" marginLeft="16">
      <IconAdapter
        icon={<img alt={title} src={icon} height="48px" width="48px" />}
      />
    </FlexLayout>

    <FlexLayout
      mainaxis="column"
      alignSelf="center"
      marginLeft="16"
      marginTop="8"
    >
      <ListTitle>{title}</ListTitle>
    </FlexLayout>
  </FlexLayout>
);

export type AppDailyTimeLimitCardProps = AppIconProps &
  DayDialProps & {
    type: 'mobile' | 'desktop';
  };

const AppDailyTimeLimitCard: React.FC<AppDailyTimeLimitCardProps> = ({
  type,
  title,
  icon,
  dayQuotaMap,
  selectedDay,
  onSelectDay,
  onChangeQuota,
}: AppDailyTimeLimitCardProps) => (
  <FlexLayout mainaxis="column" maxWidth="100%">
    <FlexLayout mainaxis="column" marginBottom="48">
      {type === 'mobile' && <AppIconMobile title={title} icon={icon} />}
    </FlexLayout>

    <FlexLayout mainaxis="column">
      <DayDial
        dayQuotaMap={dayQuotaMap}
        selectedDay={selectedDay}
        onSelectDay={onSelectDay}
        onChangeQuota={onChangeQuota}
      />
    </FlexLayout>
  </FlexLayout>
);

export default AppDailyTimeLimitCard;
