import { Map } from 'immutable';
import { connect } from 'react-redux';
import State, { BaseThunk, Dispatch } from '../../../store/state';
import UnifiedNowCard from '../../../components/NowCard/unifiedNowCard/UnifiedNowCard';
import {
  cancelUpdateNowCardOnFinishState,
  loadNowCardAction,
  loadedStateNowCardAction,
  updateNowCardOnFinishState,
} from '../../../ducks/unifiedNowCard/actions';
import {
  getKidTamperedDevices,
  getProfileDevices,
} from '../../../selectors/profileDevices';
import {
  hasScreenTimeReached,
  hasTimeRestriction,
} from '../helpers/family/helper';
import { numOfDevicesDisabledInSafeNetwork } from '../../../selectors/safeNetwork';
import flags from '../../../sideEffects/flags';
import { calculateUnifiedNowCardCurrentState } from '../../../businessLogic/unifiedNowCard/unifiedNowCard';
import { getUnifiedNowCardStatus } from '../../../selectors/unifiedNowCard';
import {
  getAccountCurrentTime,
  getAccountLocale,
  getCurrentTime,
  getExtraTimeInMinutes,
  getProfile,
  getProfileRules,
  getTimeQuotas,
  getTimeRanges,
  getTimezone,
  getTodayScreenTimeMinutes,
  isMilitaryTimeFormat,
} from '../../../selectors';
import {
  getCurrentSchoolEndDatetime,
  getCurrentSchoolStartDatetime,
  getStudentPause,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../../../selectors/studentPolicies';
import { isSchoolTime } from '../helpers/schools/helper';
import { UnifiedNowCardData } from '../../../components/NowCard/unifiedNowCard/types';
import {
  WEEKDAYS,
  Weekday,
  convertDate,
  daysDiff,
  formatHumanTwelveClockFormat,
  getAllRestrictedHours,
  getRestrictedUntilHour,
  localNow,
} from '../../../helpers/dates';
import { ONE_DAY_IN_MINUTES } from '../../../constants';
import {
  getIsProfilePaused,
  getProfileEndPause,
} from '../../../ducks/pause/selectors';
import { getDevicesOffline } from '../../../businessLogic/devices';
import {
  SyntheticUnifiedNowCardState,
  UnifiedNowCardState,
} from '../../../businessLogic/unifiedNowCard/types';
import {
  getActiveRoutine,
  isRoutinesFeatureEnabled,
} from '../../../ducks/routines/selectors';
import {
  isRoutineScheduleOverridden,
  momentifySchedule,
} from '../../../ducks/routines/helpers';

const mapStateToProps = (state: State, { profileId }) => {
  const nowCardStatus = getUnifiedNowCardStatus(state);

  const accountTimezone = getTimezone(state);
  const accountLocale = getAccountLocale(state);
  const isMilitaryTime = isMilitaryTimeFormat(state);
  // Context to calculate card state
  const profile = getProfile(state, profileId);
  const profileDevices = getProfileDevices(state, profileId);
  const kidTamperedDevices = getKidTamperedDevices(state, profileId);
  const internetPaused = getIsProfilePaused(state, profileId);
  const timeRestrictions = hasTimeRestriction(state, profileId);
  const currentTimeRestrictions = getProfileRules(
    state,
    profileId
  )?.timeRestrictions;

  const screenTimeReached = hasScreenTimeReached(state, profileId, true);
  const deviceInSafeNetwork =
    numOfDevicesDisabledInSafeNetwork(state, Number(profileId)) > 0 &&
    flags.safeNetworkSettings.isEnabled();
  const numOfDevicesInSafeNetworks = flags.safeNetworkSettings.isEnabled()
    ? numOfDevicesDisabledInSafeNetwork(state, Number(profileId))
    : 0;
  const extraTimeInMinutes = getExtraTimeInMinutes(state) ?? 0;
  const studentPause = getStudentPause(state);
  const currentStartSchoolTimes = getCurrentSchoolStartDatetime(state);
  const currentEndSchoolTimes = getCurrentSchoolEndDatetime(state);
  const inSchoolPeriod = isSchoolTime(
    currentStartSchoolTimes,
    currentEndSchoolTimes
  );
  const isProfileDelegated =
    flags.useDelegation.isEnabled() && isStudentOptInForDelegation(state);
  const isProfileDelegatedToThisAccount =
    flags.useDelegation.isEnabled() &&
    isStudentOptInForDelegationForThisAccount(state);

  const deviceId = profile?.deviceIds.first() ?? 0;
  const currentTime = getAccountCurrentTime(state);
  const currentWeekday = WEEKDAYS[currentTime.isoWeekday() - 1];
  const dailyLimitMinutes = (getTimeQuotas as any)(
    state,
    profileId,
    deviceId,
    currentWeekday
  );
  const spentMinutes = getTodayScreenTimeMinutes(state);
  const extraTime = getExtraTimeInMinutes(state) || 0;
  const profileHasTimeLimitRunning = dailyLimitMinutes < ONE_DAY_IN_MINUTES;
  const routinesFeatureEnabled = isRoutinesFeatureEnabled(state);
  const isSwitchRoutineFeatureEnabled =
    routinesFeatureEnabled && flags.tmpRoutineSwitch.isEnabled();
  const { activeRoutine, activeSchedule } = getActiveRoutine(state, profileId);
  const activeRoutineSchedule = momentifySchedule(
    activeSchedule,
    isMilitaryTime,
    accountTimezone
  );
  const isActiveRoutineSwitched = isRoutineScheduleOverridden(activeSchedule);
  const activeRoutineSwitchDuration = activeRoutineSchedule?.endTimeMoment
    ? activeRoutineSchedule.endTimeMoment.diff(localNow(accountTimezone))
    : 0;

  const nowCardState =
    nowCardStatus === 'LOADED'
      ? calculateUnifiedNowCardCurrentState({
          profile,
          profileDevices,
          kidTamperedDevices,
          internetPaused,
          timeRestrictions,
          screenTimeReached,
          deviceInSafeNetwork,
          studentPause,
          inSchoolPeriod,
          numOfDevicesInSafeNetworks,
          extraTimeInMinutes,
          isLockComputerInTimeRestriction:
            currentTimeRestrictions?.isLockComputer || false,
          isLockNavigationInTimeRestrictions:
            currentTimeRestrictions?.isLockNavigation || false,
          isTimeRestrictionAlert: currentTimeRestrictions?.isReportAlerts,
          profileHasTimeLimitRunning,
          timezone: accountTimezone,
          activeRoutine,
          isRoutinesFeatureEnabled: routinesFeatureEnabled,
          isProfileDelegated,
          isProfileDelegatedToThisAccount,
        })
      : 'NON_CALCULATED';

  // const unified now card render data
  const devicesPausedUntil = getProfileEndPause(state, profileId);
  const pauseDuration = devicesPausedUntil
    ? devicesPausedUntil.diff(localNow(accountTimezone))
    : 0;

  const restrictedHours = getAllRestrictedHours({
    timeRanges:
      (getTimeRanges(state, profileId) as Map<Weekday, number>) || Map(),
    currentWeekday,
  });
  const restrictionUntilHour = getRestrictedUntilHour(
    restrictedHours,
    currentTime.hour()
  );
  const timeRestrictedUntil = currentTime
    .clone()
    .startOf('day')
    .add(restrictionUntilHour, 'hours');

  const devicesOfflineNames = getDevicesOffline(profileDevices, accountTimezone)
    .map(device => device.name)
    .toArray();
  const { days: devicesOfflineDay, offlineDeviceId } = getDevicesOffline(
    profileDevices,
    accountTimezone
  )
    .toArray()
    .reduce(
      (acc: { days: number; offlineDeviceId?: number }, curr) => {
        const lastSeen = curr.lastseen;
        const days = daysDiff(
          convertDate(lastSeen, accountTimezone),
          getCurrentTime(state)
        );
        if (days < acc.days) {
          return { days, offlineDeviceId: curr.id };
        }
        return acc;
      },
      { days: Infinity, offlineDeviceId: undefined }
    );
  const unifiedNowCardConfigData: UnifiedNowCardData = {
    profileId,
    profileName: profile?.name,
    firstDeviceName:
      profileDevices.size > 0 ? profileDevices.first()?.name : undefined,
    untilTime: internetPaused
      ? formatHumanTwelveClockFormat(
          devicesPausedUntil,
          accountTimezone,
          accountLocale
        )
      : undefined,
    screenTime: spentMinutes,
    dailyLimit: dailyLimitMinutes,
    extraTime,
    schoolEndTime: currentEndSchoolTimes
      ? formatHumanTwelveClockFormat(
          currentEndSchoolTimes,
          accountTimezone,
          accountLocale
        )
      : undefined,
    schoolStartTime: currentStartSchoolTimes
      ? formatHumanTwelveClockFormat(
          currentStartSchoolTimes,
          accountTimezone,
          accountLocale
        )
      : undefined,
    schoolUntilTime: devicesPausedUntil
      ? formatHumanTwelveClockFormat(
          devicesPausedUntil,
          accountTimezone,
          accountLocale
        )
      : undefined,
    untilRestrictedTime: timeRestrictions
      ? formatHumanTwelveClockFormat(
          timeRestrictedUntil,
          accountTimezone,
          accountLocale
        )
      : undefined,
    numOfDevicesInSafeNetworks,
    numTamperedDevices: kidTamperedDevices.length ?? 0,
    numTotalDevices: profileDevices.size ?? 0,
    devicesOffline: devicesOfflineNames,
    locale: accountLocale,
    devicesOfflineDay,
    offlineDeviceId,
    isProfileLinkedWithSchool: Boolean(profile?.isLinewizestudentLinked),
    isSwitchRoutineFeatureEnabled,
    activeRoutine,
    activeRoutineSchedule,
    isActiveRoutineSwitched,
  };

  return {
    nowCardState,
    nowCardStatus,
    profileId,
    unifiedNowCardConfigData,
    pauseDuration,
    activeRoutineSwitchDuration,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { profileId }) => {
  return {
    onLoad: () => {
      dispatch(loadNowCardAction(profileId));
    },
    actionWrapper: (action: BaseThunk<void>) => {
      dispatch(action);
    },
    reportToAnalytics: (
      state: UnifiedNowCardState | SyntheticUnifiedNowCardState
    ) => {
      dispatch(loadedStateNowCardAction(state));
    },
    updateNowCardOnFinishState: (durationMilliseconds: number) => {
      dispatch(updateNowCardOnFinishState(profileId, durationMilliseconds));
    },
    cancelUpdateNowCardOnFinishState: () => {
      dispatch(cancelUpdateNowCardOnFinishState());
    },
  };
};

const UnifiedNowCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnifiedNowCard);

export default UnifiedNowCardContainer;
