import * as Immutable from 'immutable';

import { formName as placeFormName } from '../components/FamilyLocator/PlaceForm';
import State from '../store/state';
import { tryUndefined } from './helpers';
import { PlaceRecord } from '../records';
import { PLACE_FORM_MIN_RADIUS_VALUE_METERS } from '../constants';
import { getProfile } from './profileRaw';
import { getMarkerFromPlace } from './marker';

const NAMESPACE = 'places';

export const getPlace = (state: State, placeUid): PlaceRecord =>
  state
    .get('places')
    .get('result')
    .filter(place => place.uid === placeUid)
    .first()!;

export const hasSessionToken = (state: State) =>
  state.get(NAMESPACE).get('searchSessionToken') !== '';

export const getSessionToken = (state: State) =>
  state.get(NAMESPACE).get('searchSessionToken');

export const getPlaces = (state: State) => state.get('places').get('result');

export const getRadiusAddPlaceForm = tryUndefined((state: State) =>
  state.get('form').get(placeFormName).get('values').get('radius')
);

export const didPlacesInitialFetch = (state: State) =>
  state.get('places').get('didInitialFetch');

export const isFetchingPlaces = (state: State) =>
  state.get('places').get('isFetching');

export const getAddressSearchString = (state: State) =>
  state.get('places').get('addressSearchString');

export const getAddressSearchResults = (state: State) =>
  state.get('places').get('addressSearchResults');

export const isFetchingAddresses = (state: State) =>
  state.get('places').get('isFetchingAddresses');

export const getForm = tryUndefined((state: State, formName) =>
  state.get('form').get(formName).get('values')
);

export const getPlaceToAdd = (state, profileId) => {
  const placeFormValues = getForm(state, placeFormName) || PlaceRecord();
  const profile = getProfile(state, profileId);
  return Immutable.List().push({
    latitude: placeFormValues.latitude,
    longitude: placeFormValues.longitude,
    accuracy: 0,
    title: placeFormValues.name,
    snippet: placeFormValues.address,
    label: profile.name[0],
    icon: profile.picture,
    radius: PLACE_FORM_MIN_RADIUS_VALUE_METERS,
  });
};

export const getPlaceFormMarker = (state, placeUid) => {
  return getMarkerFromPlace(state, getPlace(state, placeUid)).map(marker => {
    marker.accuracy = 0;
    return marker;
  });
};
