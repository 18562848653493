import * as React from 'react';
import { classNames } from '../../../helpers';

/*
Important: This component will be brought to Rocket 
when the concept of 'Highlighted' is sufficiently mature in design.
That's why it doesn't use the namespace and tries to follow Rocket's style guide.
*/
export type HighlightedVariants = 'shadow' | 'alert';

export interface HighlightedProps {
  type?: HighlightedVariants;
  disabled?: boolean;
  children: React.ReactElement;
}

const getVariantClass = (type: HighlightedVariants) => {
  switch (type) {
    case 'shadow':
      return 'rck-highlighted--shadow';
    case 'alert':
      return 'rck-highlighted--alert';
    default:
      throw new Error(
        `Variant ${type} not implemented for component highlighted`
      );
  }
};

/**
 *
 * When wrapping any component, it never modifies the position of the child component (display css property).
 * This allows adding and removing it without worrying about how it impacts existing elements.
 *
 */
const Highlighted = ({
  children,
  type = 'shadow',
  disabled = false,
}: HighlightedProps) => {
  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      className: classNames(
        child.props?.className,
        disabled ? '' : getVariantClass(type)
      ),
    });
  });
};

export default Highlighted;
