import { FeatureFlagsKey, FeatureFlagsRecord } from '../ducks/featureFlags';
import State from '../store/state';
import { Experiment } from '../sideEffects/flags';

export const getFeatureFlags = (state: State): FeatureFlagsRecord =>
  state.get('featureFlags');

export const getFeatureFlag = (state: State, key: FeatureFlagsKey) =>
  getFeatureFlags(state).get(key);

export const isQuestionableWebSearchesFeactureActive = (state: State) =>
  getFeatureFlag(state, 'questionableWebSearches') === Experiment.VariantA;
