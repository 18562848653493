import {
  ACCOUNT_SAFE_NETWORK_SETTINGS_ERROR,
  LOADED_ACCOUNT_SAFE_NETWORKS_SETTINGS,
  LOADING_ACCOUNT_SAFE_NETWORKS_SETTINGS,
  RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS,
} from './constants';

export const loadingAccountSafeNetworks = () => ({
  type: LOADING_ACCOUNT_SAFE_NETWORKS_SETTINGS,
});

export const loadedAccountSafeNetworks = () => ({
  type: LOADED_ACCOUNT_SAFE_NETWORKS_SETTINGS,
});

export const receiveSafeNetworks = records => ({
  type: RECEIVE_ACCOUNT_SAFE_NETWORKS_SETTINGS,
  payload: { records },
});

export const errorSafeNetwork = () => ({
  type: ACCOUNT_SAFE_NETWORK_SETTINGS_ERROR,
});
