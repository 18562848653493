import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { Feature } from './types';

export const FeaturesOperations = getRecordOperations<Feature, Feature>({
  defaultFields: {
    uid: null,
    code: null,
    status: 'not_available',
  },
});
