import { ActivityEventFilters } from '../../businessLogic/timeline/types';
import { format } from '../../helpers/analytics';

export const getTimelineFilterTrackingParams = (
  filter: ActivityEventFilters
) => {
  return format({
    options: {
      active_timeline_filter: filter,
    },
  });
};
