import * as React from 'react';
import { Button, ButtonSize, Modal, ModalStyledText } from 'styleguide-react';
import { t } from '../../lib/i18n';
import type { SchoolsWelcomeModalProps } from '../../containers/Modal/SchoolsWelcomeModalContainer';
import RocketLaunchIllustration from '../../assets/base/images/v3/flyovers/illustration_rocket_launch_qustodio_icon.svg';

const SchoolsWelcomeModal: React.FunctionComponent<
  SchoolsWelcomeModalProps
> = ({ onClickClose }: SchoolsWelcomeModalProps) => (
  <Modal
    size="medium"
    onClickClose={onClickClose}
    header={{
      illustration: (
        <img
          src={RocketLaunchIllustration}
          alt="Welcome to Qustodio"
          width="160"
        />
      ),
    }}
    title={t('You’re all set!')}
    buttons={[
      <Button
        block
        key="button-1"
        size={ButtonSize.medium}
        onClick={onClickClose}
      >
        {t('Start exploring')}
      </Button>,
    ]}
  >
    <ModalStyledText>
      {t(
        'You can now see what your child is accessing on their school device and manage the way they use it at home.'
      )}
    </ModalStyledText>
  </Modal>
);

export default SchoolsWelcomeModal;
