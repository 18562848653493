import * as camelize from 'camelize';
import { fromJS, List, Record, NonEmptyMapRecord } from 'immutable';
import { normalize, Schema, arrayOf } from 'normalizr';
import * as R from 'ramda';
import api from '../api';
import { EventRecord } from '../records';
import {
  EventType,
  ACTION_BLOCK,
  REASON_CATEGORY_BLOCK,
  REASON_DOMAIN_LIST_BLOCK,
  REASON_NOT_ALLOW_UNKNOWN_SITES,
} from '../constants';
import {
  minimumPromiseDuration,
  tapReject,
  encryptEventAsToken,
} from '../helpers';
import { parseISODateString } from '../helpers/dates';
import timeout from '../lib/timeout';
import {
  getAccount,
  getProfileOrDefault,
  getActivityTimelineFilterBy,
  hasMoreEventsTimeline,
  eventDataMatchesProfile,
} from '../selectors';
import { BaseThunk } from '../store/state';
import * as qinit from '../qinit';
import { FETCH_EVENTS_LIMIT } from '../businessLogic/events/events';
import { getActivityEventFilters } from '../businessLogic/timeline';
import { ActivityEventFilters } from '../businessLogic/timeline/types';

export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const REQUEST_EVENTS_ERROR = 'REQUEST_EVENTS_ERROR';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const REQUEST_NEW_EVENTS = 'REQUEST_NEW_EVENTS';
export const REQUEST_MORE_EVENTS = 'REQUEST_MORE_EVENTS';
export const RECEIVE_MORE_EVENTS = 'RECEIVE_MORE_EVENTS';
export const RECEIVE_MORE_EVENTS_FILTERED = 'RECEIVE_MORE_EVENTS_FILTERED';
export const REQUEST_MORE_EVENTS_ERROR = 'REQUEST_MORE_EVENTS_ERROR';
export const RECEIVE_EVENTS_MOCK = 'RECEIVE_EVENTS_MOCK';
export const SHOW_EVENT_ACTIONS = 'SHOW_EVENT_ACTIONS';
export const RECEIVE_EVENT_ADDRESS = 'RECEIVE_EVENT_ADDRESS';
export const SET_FILTER_BY = 'SET_FILTER_BY';

type EventsTimeline = {
  ids: List<string>;
  hasMore: boolean;
};
export type EventsTimelineRecord = Record<EventsTimeline>;
export const EventsTimelineRecord = Record<
  EventsTimeline,
  {
    fromPayload: Record.Parser<any, EventsTimelineRecord>;
  }
>({
  ids: List<string>(),
  hasMore: true,
});

// EventsTimelineRecord.fromPayload = payload => {
//   return EventsTimelineRecord({
//     ids: List<string>(payload.ids),
//     pageId: payload.page_id,
//   });
// };

type EventsTimelineMap = {
  [ActivityEventFilters.All]: EventsTimeline;
  [ActivityEventFilters.Apps]: EventsTimeline;
  [ActivityEventFilters.Web]: EventsTimeline;
  [ActivityEventFilters.Searches]: EventsTimeline;
  [ActivityEventFilters.Locations]: EventsTimeline;
  [ActivityEventFilters.CallsAndSMS]: EventsTimeline;
  [ActivityEventFilters.InappropriateSearches]: EventsTimeline;
};

type EventsTimelineMapRecord = Record<EventsTimelineMap>;

const EventsTimelineMapRecordInternals = (): EventsTimelineMap => ({
  [ActivityEventFilters.All]: EventsTimelineRecord(),
  [ActivityEventFilters.Apps]: EventsTimelineRecord(),
  [ActivityEventFilters.Web]: EventsTimelineRecord(),
  [ActivityEventFilters.Searches]: EventsTimelineRecord(),
  [ActivityEventFilters.Locations]: EventsTimelineRecord(),
  [ActivityEventFilters.CallsAndSMS]: EventsTimelineRecord(),
  [ActivityEventFilters.InappropriateSearches]: EventsTimelineRecord(),
});

export const EventsTimelineMapRecord = Record<
  EventsTimelineMap,
  {
    fromPayload: (payload: any[]) => EventsTimelineMapRecord;
  }
>(EventsTimelineMapRecordInternals());

EventsTimelineMapRecord.fromPayload = (payload: any[]) => {
  const eventsWithActivityEventFilter: {
    key: string;
    filter?: ActivityEventFilters[];
  }[] = payload.map(event => ({
    ...event,
    filter: getActivityEventFilters(event),
  }));

  return EventsTimelineMapRecord(
    eventsWithActivityEventFilter.reduce((acc: EventsTimelineMap, event) => {
      if (event.filter && event.filter.length > 0) {
        event.filter.forEach(filter => {
          const filterRecord = acc[filter] as EventsTimelineRecord;
          acc[filter] = filterRecord.set(
            'ids',
            filterRecord.ids.concat(event.key)
          );
        });
      }
      return acc;
    }, EventsTimelineMapRecordInternals())
  );
};

export default function events(
  state: NonEmptyMapRecord<{
    result: EventsTimelineMapRecord;
    resultById: any;
    isFetching: boolean;
    isFetchingNew: boolean;
    isFetchingMore: boolean;
    fetchMoreError: any;
    showEventActions: EventRecord | undefined;
    filterBy: ActivityEventFilters;
    lastReceiveEventsAt: number | null;
    profileUid: string | null;
  }> = fromJS({
    result: EventsTimelineMapRecord(),
    resultById: {},
    isFetching: false,
    isFetchingNew: false,
    isFetchingMore: false,
    fetchMoreError: undefined,
    showEventActions: undefined,
    filterBy: ActivityEventFilters.All,
    lastReceiveEventsAt: null,
    profileUid: null,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_EVENTS:
      return state.merge({
        isFetching: true,
      });

    case REQUEST_NEW_EVENTS:
      return state.merge({
        isFetchingNew: true,
      });

    case RECEIVE_EVENTS:
      return state.merge({
        isFetching: false,
        isFetchingNew: false,
        result: action.payload.result,
        resultById: Object.assign(state.get('resultById').toJS(), {
          [action.payload.profileUid]: action.payload.result,
        }),
        profileUid: action.payload.profileUid,
        lastReceiveEventsAt: Date.now(),
      });

    case REQUEST_MORE_EVENTS:
      return state.merge({
        isFetchingMore: true,
        fetchMoreError: undefined,
      });

    case RECEIVE_MORE_EVENTS:
      return state.merge({
        isFetchingMore: false,
        result: Object.keys(ActivityEventFilters).reduce(
          (result, filterName) => {
            const key = ActivityEventFilters[filterName];
            const filteredTimeline = state.get('result')[key];

            const filteredTimelineWithIds = filteredTimeline.set(
              'ids',
              filteredTimeline.ids.concat(
                action.payload.result[key].ids.filter(
                  id => filteredTimeline.ids.indexOf(id) < 0
                )
              )
            );

            const newFilteredTimeline = action.payload.result[key].hasMore
              ? filteredTimelineWithIds
              : filteredTimelineWithIds.set('hasMore', false);

            return result.set(key, newFilteredTimeline);
          },
          state.get('result')
        ),
      });

    case REQUEST_EVENTS_ERROR:
      return state.merge({
        isFetching: false,
        isFetchingNew: false,
      });

    case REQUEST_MORE_EVENTS_ERROR:
      return state.merge({
        isFetchingMore: false,
        fetchMoreError: action.payload.error,
      });

    case RECEIVE_EVENTS_MOCK:
      return state.merge({
        isFetching: false,
        isFetchingNew: false,
      });

    case SHOW_EVENT_ACTIONS:
      return state.set('showEventActions', action.payload);

    case SET_FILTER_BY:
      return state.set('filterBy', action.payload);
    default:
      return state;
  }
}

const eventSchema = new Schema('events', {
  idAttribute: 'key',
});

function normalizeEvents(
  response: { timeline: any[]; page_id?: string },
  filter: ActivityEventFilters
) {
  const timelineFiltered = filterBlockedEvents(response.timeline);

  const {
    entities: { events },
  } = normalize(camelize(timelineFiltered), arrayOf(eventSchema));

  const resultTimelines = EventsTimelineMapRecord.fromPayload(timelineFiltered);

  const result =
    response.page_id === '' ||
    (filter && response.timeline.length < FETCH_EVENTS_LIMIT)
      ? resultTimelines.setIn([filter, 'hasMore'], false)
      : resultTimelines;

  return {
    result,
    records: {
      events: R.map(EventRecord.fromPayload, events || []),
    },
  };
}

function filterBlockedEvents(timeline: any[]): any[] {
  const allowedBlockageReasons = {
    [EventType.Web]: [
      REASON_CATEGORY_BLOCK,
      REASON_DOMAIN_LIST_BLOCK,
      REASON_NOT_ALLOW_UNKNOWN_SITES,
    ],
  };

  return timeline.filter(event => {
    if (event.action === ACTION_BLOCK && allowedBlockageReasons[event.type]) {
      return allowedBlockageReasons[event.type].includes(event.reason);
    }
    return true;
  });
}

export function requestEvents() {
  return {
    type: REQUEST_EVENTS,
  };
}

export function requestEventsError() {
  return {
    type: REQUEST_EVENTS_ERROR,
  };
}

export function requestMoreEvents() {
  return {
    type: REQUEST_MORE_EVENTS,
  };
}

export function requestMoreEventsError(error) {
  return {
    type: REQUEST_MORE_EVENTS_ERROR,
    payload: { error },
  };
}

export function requestNewEvents() {
  return {
    type: REQUEST_NEW_EVENTS,
  };
}

export function receiveEventsMock() {
  return {
    type: RECEIVE_EVENTS_MOCK,
  };
}

export function receiveMoreEvents(
  response: { timeline: any[]; page_id: string } | any[],
  filter: ActivityEventFilters
) {
  const eventsResponse = Array.isArray(response)
    ? { timeline: response }
    : response;
  const { result, records } = normalizeEvents(eventsResponse, filter);
  return {
    type: RECEIVE_MORE_EVENTS,
    payload: {
      result,
      records,
    },
  };
}

export function receiveEvents(
  response: { timeline: any[]; page_id: string } | any[],
  filter: ActivityEventFilters,
  profileUid: string
) {
  const eventsResponse = Array.isArray(response)
    ? { timeline: response }
    : response;
  const { result, records } = normalizeEvents(eventsResponse, filter);
  return {
    type: RECEIVE_EVENTS,
    payload: {
      result,
      profileUid,
      records,
    },
  };
}

export function fetchEventsIfProfileChanged(
  profileUid,
  filterBy?: ActivityEventFilters
) {
  return (dispatch, getState) => {
    if (eventDataMatchesProfile(getState(), profileUid)) {
      return dispatch(() => Promise.resolve());
    }

    return dispatch(fetchEvents(profileUid, filterBy));
  };
}

export const fetchLocationEvents = (profileUid: string) =>
  fetchEvents(profileUid, ActivityEventFilters.Locations);

export const fetchEvents =
  (profileUid, filterBy?: ActivityEventFilters) => (dispatch, getState) => {
    dispatch(requestEvents());
    const filter = filterBy || getActivityTimelineFilterBy(getState());
    return api.events
      .get(
        {
          profileUid,
        },
        {
          limit: FETCH_EVENTS_LIMIT,
          filter,
        }
      )
      .then(response => dispatch(receiveEvents(response, filter, profileUid)))
      .catch(tapReject(() => dispatch(requestEventsError())));
  };

export const getLastEvaluatedKey = (state, filter: ActivityEventFilters) =>
  state.getIn(['events', 'result', filter, 'ids']).last();

export function shouldFetchMoreEvents(
  id: number,
  filterBy?: ActivityEventFilters
): BaseThunk<any> {
  return (dispatch, getState) => {
    const state = getState();
    const filter = filterBy || getActivityTimelineFilterBy(state);

    if (
      state.get('events').get('isFetching') ||
      state.get('events').get('isFetchingMore') ||
      !hasMoreEventsTimeline(state, filter)
    ) {
      return undefined;
    }

    return dispatch(
      fetchMoreEvents(getProfileOrDefault(state, id).uid, filter)
    );
  };
}

export function fetchMoreEvents(
  profileUid: string,
  filterBy?: ActivityEventFilters
) {
  return (dispatch, getState) => {
    dispatch(requestMoreEvents());

    const state = getState();

    const minimum = minimumPromiseDuration();

    const filter = filterBy || getActivityTimelineFilterBy(state);
    const lastEvaluatedKey = getLastEvaluatedKey(state, filter);
    const query = lastEvaluatedKey
      ? {
          page_id: lastEvaluatedKey,
          limit: FETCH_EVENTS_LIMIT,
          filter,
        }
      : {
          limit: FETCH_EVENTS_LIMIT,
          filter,
        };

    return api.events
      .get(
        {
          profileUid,
        },
        query
      )
      .then(minimum, minimum) // finally
      .then(response => {
        return response.length === 0
          ? Promise.reject(new Error('empty'))
          : response;
      })
      .then(response => {
        return dispatch(receiveMoreEvents(response, filter));
      })
      .catch(error => {
        dispatch(requestMoreEventsError(error));
        if (error.message !== 'empty') {
          throw error;
        }
      });
  };
}

export function fetchNewEvents(
  profileUid: string,
  filterBy?: ActivityEventFilters
) {
  return (dispatch, getState) => {
    dispatch(requestNewEvents());

    const minimum = minimumPromiseDuration();
    const filter = filterBy || getActivityTimelineFilterBy(getState());

    return api.events
      .get(
        {
          profileUid,
        },
        {
          filter,
        }
      )
      .then(minimum, minimum) // finally
      .then(response => dispatch(receiveEvents(response, filter, profileUid)))
      .catch(tapReject(() => dispatch(requestEventsError())));
  };
}

export function requestMoreEventsMock() {
  return dispatch => {
    dispatch(requestMoreEvents());
    return new Promise(resolve => timeout(resolve, 1500));
  };
}

export function showEventActions(event: EventRecord | undefined) {
  return {
    type: SHOW_EVENT_ACTIONS,
    payload: event,
  };
}

export const hideTimelineActionSheets = () => showEventActions(undefined);

export const getPanicLink =
  (profileId, event): BaseThunk<string> =>
  (
    // eslint-disable-next-line no-underscore-dangle
    _dispatch,
    getState
  ) =>
    R.concat(
      qinit.tenant.common.dashboard.panic_alert_url as string,
      encryptEventAsToken(
        getAccount(getState()).tokenKey,
        parseInt(profileId, 10),
        event.deviceId,
        parseISODateString(event.time)!.valueOf() / 1000
      )
    );

export const setFilter = (filter: ActivityEventFilters) => {
  return {
    type: SET_FILTER_BY,
    payload: filter,
  };
};
