import { fromJS, List, NonEmptyMapRecord } from 'immutable';
import {
  AccountRecord,
  ProfileRecord,
  ProfileRulesRecord,
  TimeSettingsRecord,
  ContactRecord,
  DeviceRecord,
} from '../records';

export const UPDATE_PREV_STATE = 'UPDATE_PREV_STATE';
export const CLEAR_PREV_STATE = 'CLEAR_PREV_STATE';
export const ACTION_TARGET_ACCOUNT = 'ACCOUNT';
export const ACTION_TARGET_PROFILE = 'PROFILE';
export const ACTION_TARGET_PROFILE_RULES = 'PROFILE_RULES';
export const ACTION_TARGET_TIME_RESTRICTIONS = 'TIME_RESTRICTIONS';
export const ACTION_TARGET_CONTACTS = 'CONTACTS';
export const ACTION_TARGET_DEVICE = 'DEVICE';

export default function previousState(
  state: NonEmptyMapRecord<{
    account: AccountRecord;
    profile: ProfileRecord;
    profileRules: ProfileRulesRecord;
    timeRestrictions: TimeSettingsRecord;
    device: DeviceRecord | null;
    contacts: List<ContactRecord>;
    isFetching: boolean;
  }> = fromJS({
    account: AccountRecord(),
    profile: ProfileRecord(),
    profileRules: ProfileRulesRecord(),
    timeRestrictions: TimeSettingsRecord(),
    device: DeviceRecord(),
    contacts: [],
    isFetching: false,
  }),
  action
) {
  switch (action.type) {
    case UPDATE_PREV_STATE:
      switch (action.target) {
        case ACTION_TARGET_ACCOUNT:
          return state.merge({
            account: AccountRecord.fromPayload(action.payload),
          });
        case ACTION_TARGET_PROFILE:
          return state.merge({
            profile: ProfileRecord.fromPayload(action.payload),
          });
        case ACTION_TARGET_PROFILE_RULES:
          return state.merge({
            profileRules: ProfileRulesRecord.fromPayload(action.payload),
          });
        case ACTION_TARGET_TIME_RESTRICTIONS:
          return state.merge({
            timeRestrictions: TimeSettingsRecord.fromPayload(action.payload),
          });
        case ACTION_TARGET_CONTACTS:
          return state.merge({
            contacts: List(action.payload.map(ContactRecord.fromPayload)),
          });
        case ACTION_TARGET_DEVICE:
          return state.merge({
            device: DeviceRecord.fromPayload(action.payload),
          });
        default:
          return state;
      }
    case CLEAR_PREV_STATE:
      switch (action.target) {
        case ACTION_TARGET_ACCOUNT:
          return state.merge({
            account: AccountRecord(),
          });
        case ACTION_TARGET_PROFILE:
          return state.merge({
            profile: ProfileRecord(),
          });
        case ACTION_TARGET_PROFILE_RULES:
          return state.merge({
            profileRules: ProfileRulesRecord(),
          });
        case ACTION_TARGET_TIME_RESTRICTIONS:
          return state.merge({
            timeRestrictions: TimeSettingsRecord(),
          });
        case ACTION_TARGET_CONTACTS:
          return state.merge({
            contacts: List<ContactRecord>(),
          });
        case ACTION_TARGET_DEVICE:
          return state.merge({
            device: null,
          });
        default:
          return state;
      }
    default:
      return state;
  }
}

export function updatePrevState(payload, target) {
  return {
    type: UPDATE_PREV_STATE,
    payload,
    target,
  };
}

export function clearPrevState(target) {
  return {
    type: CLEAR_PREV_STATE,
    target,
  };
}
