import * as React from 'react';
import {
  Button,
  ButtonIconPosition,
  Card,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  StyledHeader,
} from 'styleguide-react';
import StudentCategoriesCardContainer from '../../containers/Schools/StudentCategoriesCardContainer';
import StudentTotalUsageCardContainer from '../../containers/Schools/StudentTotalUsageCardContainer';
import StudentSignaturesCardContainer from '../../containers/Schools/StudentSignaturesCardContainer';
import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import type { StudentSummaryProps } from '../../containers/Schools/StudentSummaryContainer';
import DateRangeSelectorSchool from '../DateRangeSelector/DateRangeSelectorSchool';
import SpeedDial from '../SpeedDial/SpeedDial';
import LoadingActivityPlaceholder from '../LoadingActivityPlaceholder/LoadingActivityPlaceholder';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import ProtectDeviceBanner from '../ProtectDeviceBanner/ProtectDeviceBanner';
import flags from '../../sideEffects/flags';
import StudentSummaryError from './StudentsSummaryError';
import LastUpdatedInfo from './SchoolTimeline/LastUpdatedInfo';
import UnifiedNowCardContainer from '../../containers/NowCard/UnifiedNowCard/UnifiedNowCardContainer';
import SmartDevicesStatusContainer from '../../containers/SmartDevicesStatus/SmartDevicesStatusContainer';
import ProfileNotificationTopBarContainer from '../../containers/ProfileNotificationTopBar/ProfileNotificationTopBarContainer';

interface SpeedDialActions {
  icon: string; // update to RCK icon type once implemented
  label: string;
  onClick: () => any;
}

const StudentSummary: React.FunctionComponent<StudentSummaryProps> = ({
  activeRange,
  onChangeDateRange,
  lastUpdated,
  isMenuOpen,
  isFetchingData,
  summaryFetchError,
  profile,
  accountHasProtectedDevices,
  routerParams,
  showRulesOptionToStudentProfile,
  onClickSpeedDialAddDevices,
  onClickEdit,
  onClickGoBack,
  onToggleMenu,
  onClickContactUs,
  onClickProtectDevice,
  onClickSpeedDialSeeTimeline,
  onClickSpeedDialRules,
}: StudentSummaryProps) => {
  const speedDialActions: Array<SpeedDialActions> = [
    {
      icon: IconType.mobile,
      label: t('Add a device'),
      onClick: onClickSpeedDialAddDevices,
    },
  ];

  if (flags.schoolTimeline.isEnabled()) {
    speedDialActions.push({
      icon: IconType.listUl,
      label: t('See timeline'),
      onClick: onClickSpeedDialSeeTimeline,
    });
  }

  if (showRulesOptionToStudentProfile) {
    speedDialActions.push({
      icon: IconType.settings,
      label: t('Set rules'),
      onClick: onClickSpeedDialRules,
    });
  }

  return (
    <PageLayout
      isProfileEditable
      configuration={[
        { screenSize: ScreenSize.Desktop, columns: 1, mainReducedSize: true },
        {
          screenSize: ScreenSize.MobileOrTablet,
          showProfile: true,
          showProfileSummarySelector: true,
          title: ' ',
          mainColumnBackgroundColor: 'gray-ligther',
          removePaddings: true,
        },
      ]}
      actions={[
        {
          actionName: 'edit',
          onClick: onClickEdit,
          screenSize: ScreenSize.MobileOrTablet,
        },
        {
          actionName: 'back',
          onClick: onClickGoBack,
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      {summaryFetchError === StudentSummaryFetchError.UnhandledServerError ||
      summaryFetchError === StudentSummaryFetchError.Unlinked ? (
        <StudentSummaryError
          error={summaryFetchError}
          profileName={profile!.name}
          onClickContactUs={onClickContactUs}
        />
      ) : (
        <React.Fragment>
          <FlexLayout
            mainaxis="column"
            maxWidth="800"
            className="StudentSummary"
          >
            <Layout marginBottom="24" marginLeft="8">
              <ProfileNotificationTopBarContainer params={routerParams} />
              <StyledHeader type="h4" testId="nowCardSchoolTitle">
                {t("What's happening now")}
              </StyledHeader>
            </Layout>
            <React.Fragment>
              <Layout marginBottom="8">
                <UnifiedNowCardContainer profileId={profile!.id} />
              </Layout>
              <FlexLayout mainaxis="row">
                <SmartDevicesStatusContainer profileId={String(profile!.id)} />
              </FlexLayout>
            </React.Fragment>

            <Layout marginBottom="24" marginLeft="8">
              <StyledHeader type="h4" testId="studentSummaryTitle">
                {t('Activity summary')}
              </StyledHeader>
            </Layout>
            <FlexLayout
              mainaxis="row"
              crossaxisAlignment="center"
              mainaxisAlignment="space-between"
              marginLeft="8"
              marginRight="8"
            >
              <DateRangeSelectorSchool
                activeRange={activeRange}
                onChangeDateRange={onChangeDateRange}
              />
              {!isFetchingData && lastUpdated && (
                <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
                  <LastUpdatedInfo
                    lastUpdated={lastUpdated}
                    position="right"
                    marginBottom="0"
                  />
                </RenderWhen>
              )}
            </FlexLayout>
            {isFetchingData ? (
              <React.Fragment>
                <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
                  <FlexLayout width="800" mainaxis="column">
                    <LoadingActivityPlaceholder />
                  </FlexLayout>
                </RenderWhen>
                <RenderWhen screenSize={ScreenSize.Mobile}>
                  <LoadingActivityPlaceholder />
                </RenderWhen>
              </React.Fragment>
            ) : (
              <StudentSummaryContent
                onClickProtectDevice={onClickProtectDevice}
                onClickSpeedDialSeeTimeline={onClickSpeedDialSeeTimeline}
                accountHasProtectedDevices={accountHasProtectedDevices}
              />
            )}
            <RenderWhen screenSize={ScreenSize.Mobile}>
              <FlexLayout mainaxis="row" marginLeft="8">
                {!isFetchingData && lastUpdated && (
                  <LastUpdatedInfo
                    lastUpdated={lastUpdated}
                    position="left"
                    marginBottom="0"
                  />
                )}
              </FlexLayout>
            </RenderWhen>
          </FlexLayout>
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <SpeedDial
              isOpen={isMenuOpen}
              actions={speedDialActions}
              onToggleMenu={onToggleMenu}
            />
          </RenderWhen>
        </React.Fragment>
      )}
    </PageLayout>
  );
};

type StudentSummaryContentProps = Pick<
  StudentSummaryProps,
  | 'onClickProtectDevice'
  | 'onClickSpeedDialSeeTimeline'
  | 'accountHasProtectedDevices'
>;
const StudentSummaryContent: React.FunctionComponent<
  StudentSummaryContentProps
> = ({
  onClickProtectDevice,
  onClickSpeedDialSeeTimeline,
  accountHasProtectedDevices,
}: StudentSummaryContentProps) => {
  const showDevicesBanner = !accountHasProtectedDevices;
  return (
    <React.Fragment>
      <Layout marginBottom="8" centerX width="100%">
        <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column">
              <Layout margin="8">
                <StudentTotalUsageCardContainer />
              </Layout>
              <Layout margin="8">
                <StudentCategoriesCardContainer />
              </Layout>
            </FlexLayout>
            <FlexLayout mainaxis="column">
              <Layout margin="8">
                <StudentSignaturesCardContainer
                  extraHeight={!showDevicesBanner}
                />
              </Layout>
              {showDevicesBanner && (
                <Layout margin="8">
                  <ProtectDeviceBannerWrapper onClick={onClickProtectDevice} />
                </Layout>
              )}
            </FlexLayout>
          </FlexLayout>
          <RenderWhen screenSize={ScreenSize.Tablet}>
            <FlexLayout
              mainaxis="row"
              mainaxisAlignment="center"
              margin="8"
              width="100%"
            >
              <Button
                centered
                icon={<Icon type={IconType.chevronRight} />}
                iconPosition={ButtonIconPosition.right}
                onClick={onClickSpeedDialSeeTimeline}
              >
                {t('View timeline')}
              </Button>
            </FlexLayout>
          </RenderWhen>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Mobile}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column" width="100%">
              <Layout margin="8">
                <StudentTotalUsageCardContainer />
              </Layout>
              <FlexLayout
                mainaxis="row"
                mainaxisAlignment="center"
                margin="8"
                width="100%"
              >
                <Button
                  centered
                  icon={<Icon type={IconType.chevronRight} />}
                  iconPosition={ButtonIconPosition.right}
                  onClick={onClickSpeedDialSeeTimeline}
                >
                  {t('View timeline')}
                </Button>
              </FlexLayout>
              <Layout margin="8">
                <StudentCategoriesCardContainer />
              </Layout>
              <Layout margin="8">
                <StudentSignaturesCardContainer extraHeight={false} />
              </Layout>
              {showDevicesBanner && (
                <Layout margin="8">
                  <ProtectDeviceBannerWrapper onClick={onClickProtectDevice} />
                </Layout>
              )}
            </FlexLayout>
          </FlexLayout>
        </RenderWhen>
      </Layout>
    </React.Fragment>
  );
};

interface ProtectDeviceBannerWrapperProps {
  onClick: () => void;
}

// TODO move to new dashboard
export const ProtectDeviceBannerWrapper: React.FunctionComponent<
  ProtectDeviceBannerWrapperProps
> = ({ onClick }: ProtectDeviceBannerWrapperProps) => (
  <Card
    testId="protect-device-banner"
    className="StudentSummary__card ProtectDeviceBanner__card"
  >
    <ProtectDeviceBanner compact onClick={onClick} />
  </Card>
);

export default StudentSummary;
