import { fromJS, List, Map, NonEmptyMapRecord } from 'immutable';
import {
  CHANGE_DATE_RANGE,
  RECEIVE_SUMMARY,
  RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY,
  RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY,
  RECEIVE_SUMMARY_PER_DAYS,
  RECEIVE_SUMMARY_PER_HOURS,
  RECEIVE_TODAY_SUMMARY_PER_HOURS,
  REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY,
  REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY,
  REFRESH_SUMMARY_PER_DAYS,
  REFRESH_SUMMARY_PER_HOURS,
  REQUEST_SUMMARY,
  REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY,
  REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY,
  REQUEST_SUMMARY_PER_DAYS,
  REQUEST_SUMMARY_PER_HOURS,
  RESET_SUMMARY,
  SELECTED_PAST_DATE_CHANGED,
  SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR,
  SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR,
  SUMMARY_SCREEN_TIME_ACTIVITY_ERROR,
} from './constants';
import { SummaryDateRanges } from '../../constants';
import { HoursPerDayActivityRecord } from '../../records/activity/types/HoursPerDayActivity.types';
import { MinutesPerHourActivityRecord } from '../../records/activity/types/MinutesPerHourActivity.types';
import { ActivityRecord } from '../../records/activity';
import { API_BIRTHDATE_FORMAT, getMoment } from '../../helpers/dates';
import { AnyError } from '../../helpers/errorHandling';

export const getDefaultSummaryState = () => ({
  item: Map({
    total: 0,
    sessionTime: 0,
  }),
  isFetching: false,
  activeDateRange: SummaryDateRanges.Today,
  isSelectedDateScreenTime: false,
  isRefreshingSelectedDateScreenTime: false,
  isFetchingRangeScreenTime: false,
  isRefreshingRangeScreenTime: false,
  isFetchingBlockedWebActivity: false,
  isRefreshingBlockedWebActivity: false,
  selectedDateScreenTime: List(),
  todayScreenTime: List(),
  rangeScreenTime: List(),
  appsAndWebActivity: List(),
  blockedWebActivity: List(),
  selectedPastDate: getMoment().format(API_BIRTHDATE_FORMAT),
  summaryAppAndWebError: undefined,
  summaryBlockedWebError: undefined,
  summaryScreenTimeActivityError: undefined,
});

export default function summary(
  state: NonEmptyMapRecord<{
    item: NonEmptyMapRecord<{
      total: number;
      sessionTime: number;
    }>;
    isFetching: boolean;
    activeDateRange: SummaryDateRanges;
    isSelectedDateScreenTime: boolean;
    isRefreshingSelectedDateScreenTime: boolean;
    isFetchingRangeScreenTime: boolean;
    isRefreshingRangeScreenTime: boolean;
    isFetchingAppsAndWebActivity: boolean;
    isRefreshingAppsAndWebActivity: boolean;
    isFetchingBlockedWebActivity: boolean;
    isRefreshingBlockedWebActivity: boolean;
    selectedDateScreenTime: List<MinutesPerHourActivityRecord>;
    todayScreenTime: List<MinutesPerHourActivityRecord>;
    rangeScreenTime: List<HoursPerDayActivityRecord>;
    appsAndWebActivity: List<ActivityRecord>;
    blockedWebActivity: List<ActivityRecord>;
    selectedPastDate: string;
    summaryAppAndWebError?: AnyError;
    summaryBlockedWebError?: AnyError;
    summaryScreenTimeActivityError?: AnyError;
  }> = fromJS(getDefaultSummaryState()),
  action
) {
  switch (action.type) {
    case RESET_SUMMARY:
      return fromJS(getDefaultSummaryState()).merge({
        isSelectedDateScreenTime: true,
        isFetchingRangeScreenTime: true,
      });
    case REQUEST_SUMMARY:
      return state.merge({
        isFetching: true,
      });
    case RECEIVE_SUMMARY:
      return state.merge({
        isFetching: false,
        item: Map({
          total: action.payload.total,
          sessionTime: action.payload.session_time || 0,
        }),
      });
    case CHANGE_DATE_RANGE:
      return state.set('activeDateRange', action.payload);
    case REQUEST_SUMMARY_PER_HOURS:
      return state.merge({
        isSelectedDateScreenTime: true,
        summaryScreenTimeActivityError: undefined,
      });
    case REFRESH_SUMMARY_PER_HOURS:
      return state.merge({
        isRefreshingSelectedDateScreenTime: true,
        summaryScreenTimeActivityError: undefined,
      });
    case RECEIVE_SUMMARY_PER_HOURS:
      return state.merge({
        isSelectedDateScreenTime: false,
        isRefreshingSelectedDateScreenTime: false,
        selectedDateScreenTime: action.payload,
      });
    case RECEIVE_TODAY_SUMMARY_PER_HOURS:
      return state.merge({
        todayScreenTime: action.payload,
      });
    case REQUEST_SUMMARY_PER_DAYS:
      return state.merge({
        isFetchingRangeScreenTime: true,
        summaryScreenTimeActivityError: undefined,
      });
    case REFRESH_SUMMARY_PER_DAYS:
      return state.merge({
        isRefreshingRangeScreenTime: true,
        summaryScreenTimeActivityError: undefined,
      });
    case RECEIVE_SUMMARY_PER_DAYS:
      return state.merge({
        isFetchingRangeScreenTime: false,
        isRefreshingRangeScreenTime: false,
        rangeScreenTime: action.payload,
      });
    case REFRESH_SUMMARY_APPS_AND_WEB_ACTIVITY:
      return state.merge({
        isRefreshingAppsAndWebActivity: true,
        summaryAppAndWebError: undefined,
      });
    case REQUEST_SUMMARY_APPS_AND_WEB_ACTIVITY:
      return state.merge({
        isFetchingAppsAndWebActivity: true,
        summaryAppAndWebError: undefined,
      });
    case SUMMARY_APPS_AND_WEB_ACTIVITY_ERROR:
      return state.merge({
        summaryAppAndWebError: action.payload,
      });
    case RECEIVE_SUMMARY_APPS_AND_WEB_ACTIVITY:
      return state.merge({
        isFetchingAppsAndWebActivity: false,
        isRefreshingAppsAndWebActivity: false,
        appsAndWebActivity: action.payload,
      });
    case REFRESH_SUMMARY_BLOCKED_WEB_ACTIVITY:
      return state.merge({
        isRefreshingBlockedWebActivity: true,
        summaryBlockedWebError: undefined,
      });
    case REQUEST_SUMMARY_BLOCKED_WEB_ACTIVITY:
      return state.merge({
        isFetchingBlockedWebActivity: true,
        summaryBlockedWebError: undefined,
      });
    case SUMMARY_BLOCKED_WEB_ACTIVITY_ERROR:
      return state.merge({
        summaryBlockedWebError: action.payload,
      });
    case RECEIVE_SUMMARY_BLOCKED_WEB_ACTIVITY:
      return state.merge({
        isFetchingBlockedWebActivity: false,
        isRefreshingBlockedWebActivity: false,
        blockedWebActivity: action.payload,
      });
    case SELECTED_PAST_DATE_CHANGED:
      return state.set('selectedPastDate', action.payload);
    case SUMMARY_SCREEN_TIME_ACTIVITY_ERROR:
      return state.merge({
        summaryScreenTimeActivityError: action.payload,
      });
    default:
      return state;
  }
}
