import { connect } from 'react-redux';
import CustomNotificationModal from '../../components/Modal/CustomNotificationModal';
import { redirect, close } from '../../ducks/notifications';

const mapStateToProps = state => ({
  modal: state.getIn(['notifications', 'modal']),
});

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(close()),
  onRedirect: (source: string) => (href: string) =>
    dispatch(redirect(source)(href)),
});

const CustomNotificationModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomNotificationModal);

export default CustomNotificationModalContainer;
