import { Record } from 'immutable';
import { applyBooleanMappings } from '../../api/mappings/boolean';
import { keyValuesToObject } from '../../api/mappings/keyValues';
import {
  DeviceOptions,
  DeviceOptionsMethods,
  DeviceOptionsPayload,
  DeviceOptionsRecord as DeviceOptionsRecordType,
} from './types/DeviceOptions.types';

export const DeviceOptionsRecord = Record<DeviceOptions, DeviceOptionsMethods>({
  enabledCallsAndMessagesMonitoring: false,
});

DeviceOptionsRecord.fromPayload = payload =>
  DeviceOptionsRecord(
    applyBooleanMappings(
      keyValuesToObject<DeviceOptionsPayload, DeviceOptions>(payload)
    )
  ) as DeviceOptionsRecordType;

DeviceOptionsRecord.serialize = record =>
  ({
    ...record.toJS(),
  } as DeviceOptions);
