import { equals, inc } from 'ramda';
import { requestLocationIfNeeded } from '../ducks/devices';
import { APPThunk } from '../helpers/thunks';
import { getProfileLastLocation } from '../selectors';
import State, { Dispatch } from '../store/state';

const sleep = (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));

type Options = {
  intents: number;
  dispatch: Dispatch;
  getState: () => State;
  profileId: string;
  thunk: APPThunk;
  retryIntents: number;
  currentLastLocation;
  retryTime: number;
};

const dispatchThunkWithRetry = async (options: Options) => {
  const {
    intents,
    dispatch,
    getState,
    profileId,
    thunk,
    retryIntents,
    currentLastLocation,
    retryTime,
  } = options;
  const result = await thunk(dispatch, getState);
  const updatedLastLocation = getProfileLastLocation(getState(), profileId);

  if (
    intents < retryIntents &&
    equals(updatedLastLocation, currentLastLocation)
  ) {
    await sleep(retryTime);
    return dispatchThunkWithRetry({
      intents: inc(intents),
      dispatch,
      getState,
      profileId,
      thunk,
      retryIntents,
      currentLastLocation,
      retryTime,
    });
  }

  return result;
};

export const locationPushAndRetryWith = (
  thunk: APPThunk,
  {
    profileId,
    retryTime,
    retryIntents,
  }: { profileId: string; retryTime: number; retryIntents: number }
): APPThunk => {
  return async (dispatch, getState) => {
    await dispatch(requestLocationIfNeeded(profileId));

    return dispatchThunkWithRetry({
      intents: 1,
      dispatch,
      getState,
      profileId,
      thunk,
      retryIntents,
      currentLastLocation: getProfileLastLocation(getState(), profileId),
      retryTime,
    });
  };
};
