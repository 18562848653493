import * as React from 'react';
import { Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { FlexLayout, Label } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH } from '../../constants';
import { isValidEmail } from '../../helpers/string';
import InputTextField from '../Form/InputTextField';

export const formName = 'editEmail';

export const saveEmailButtonId = 'SaveEmailButton';
export const confirmEmailButtonId = 'ConfirmEmailButton';
export const confirmPasswordButtonId = 'PonfirmPasswordButtonId';

type EditEmailFormProps = {
  handleSubmit: () => any;
  className?: string;
};

const EditEmailForm: React.FunctionComponent<EditEmailFormProps> = ({
  handleSubmit,
  className = '',
}: EditEmailFormProps) => (
  <form className={`Form ${className}`} onSubmit={handleSubmit} id={formName}>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={saveEmailButtonId}>{t('New email')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Field
        id={saveEmailButtonId}
        name="email"
        component={InputTextField}
        type="email"
        placeholder={t('New email')}
        testId="newEmail"
      />
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={confirmEmailButtonId}>{t('Confirm new email')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Field
        id={confirmEmailButtonId}
        name="confirmEmail"
        component={InputTextField}
        type="email"
        placeholder={t('Confirm new email')}
        testId="confirmNewEmail"
      />
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={confirmPasswordButtonId}>
        {`${t('Current password')}  *`}
      </Label>
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <Field
        id={confirmPasswordButtonId}
        name="password"
        component={InputTextField}
        type="password"
        placeholder={t('Password')}
        testId="newEmailConfirmPassword"
      />
    </FlexLayout>
    {/* include an invisible submit-button so that the mobile-keyboard
      has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

interface FormError {
  [key: string]: string;
}

const validate = (values: EditEmailFormRecord, { currentEmail }) => {
  const errors: FormError = {};

  // email validation implemented as in http://redux-form.com/6.0.0-alpha.4/examples/syncValidation/
  if (!values.email) {
    errors.email = t('Field required');
  } else if (values.email.toLowerCase() === currentEmail.toLowerCase()) {
    errors.email = t("That is your account's current email!");
  } else if (!isValidEmail(values.email)) {
    errors.email = t('Invalid email address');
  } else if (values.email.length > MAX_EMAIL_LENGTH) {
    errors.email = t(
      'Email should not be longer than {{MAX_EMAIL_LENGTH}} characters',
      {
        MAX_EMAIL_LENGTH,
      }
    );
  }
  if (!values.confirmEmail) {
    errors.confirmEmail = t('Field required');
  } else if (values.email.toLowerCase() !== values.confirmEmail.toLowerCase()) {
    errors.confirmEmail = t('Emails must match');
  }
  if (!values.password) {
    errors.password = t('Field required');
  } else if (values.password.length > MAX_PASSWORD_LENGTH) {
    errors.password = t(
      `Password should not be longer than ${MAX_PASSWORD_LENGTH} characters`
    );
  }
  return errors;
};

export type EditEmailFormRecord = {
  id: string;
  email: string;
  confirmEmail: string;
  password: string;
};

export const FormRecord = Record<EditEmailFormRecord, {}>({
  id: '',
  email: '',
  confirmEmail: '',
  password: '',
});

export const formRecord = (values?: EditEmailFormRecord) => {
  if (values === undefined) {
    return FormRecord();
  }

  const record = values instanceof Record ? values : FormRecord(values);

  return record;
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: formRecord(),
  validate,
})(EditEmailForm);
