/* eslint-disable react/require-default-props */
import * as React from 'react';
import { connect } from 'react-redux';
import {
  ActionInput,
  ActionInputIconPosition,
  Dropdown,
  DropdownOption,
  Icon,
  IconType,
} from 'styleguide-react';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { t } from '../../lib/i18n';
import { getProfileId, isStudentSummaryPath } from '../../selectors';
import State, { BaseThunk, Dispatch } from '../../store/state';
import flags from '../../sideEffects/flags';

type ProfileSummarySelectorDropdownProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { className?: string };

const ProfileSummarySelectorDropdown: React.FunctionComponent<
  ProfileSummarySelectorDropdownProps
> = ({
  className,
  isStudentSummary,
  onClickFamily,
  onClickSchool,
}: ProfileSummarySelectorDropdownProps) => {
  const options = [
    {
      value: 'School',
      text: t('School'),
      onClick: onClickSchool,
    },
    {
      value: 'Family',
      text: t('Family'),
      onClick: onClickFamily,
    },
  ];

  if (flags.useUnifiedDashboard.isEnabled()) {
    return null;
  }

  return (
    <Dropdown
      actionElement={
        <ActionInput
          icon={<Icon type={IconType.angleDown} />}
          iconPosition={ActionInputIconPosition.right}
          text={isStudentSummary ? t('School') : t('Family')}
          block
          color="primary"
        />
      }
      className={className}
      portalId="portal-dropdown"
    >
      {options.map(option => (
        <DropdownOption
          key={option.value}
          value={option.value}
          text={option.text}
          onClick={option.onClick}
        />
      ))}
    </Dropdown>
  );
};

const mapStateToProps = (state: State) => ({
  isStudentSummary: isStudentSummaryPath(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickFamily: () => dispatch(navigateToFamilySummary()),
  onClickSchool: () => dispatch(navigateToSchoolSummary()),
});

export const navigateToFamilySummary =
  (): BaseThunk<void> => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const profileId = getProfileId(getState());
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}`,
      })
    );
  };

export const navigateToSchoolSummary =
  (): BaseThunk<void> => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const profileId = getProfileId(getState());
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/school/summary`,
      })
    );
  };

const ProfileSummarySelectorDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSummarySelectorDropdown);

export default ProfileSummarySelectorDropdownContainer;
