import * as React from 'react';
import type { SmartDeviceInfoModalProps } from '../../containers/Modal/SmartDeviceInfoModalContainer';
import { t } from '../../lib/i18n';
import {
  AlertBox,
  Button,
  ButtonSize,
  ButtonType,
  FlexLayout,
  Icon,
  IconType,
  Layout,
  Modal,
  ModalButtonsAlignment,
  ModalStyledText,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { DeviceStatus } from '../../businessLogic/devices';

const getTitle = (
  status: DeviceStatus['status'],
  affiliation: DeviceStatus['affiliation'],
  isProfilePaused: boolean,
  { daysUntilLastSeen }
) => {
  if (status === 'disabled')
    return t('{{shortName}} has been temporarily disabled');
  if (status === 'offline')
    return t('No activity for {{days}} days', {
      days: daysUntilLastSeen,
    });
  if (status === 'safeNetwork') return t('School Safe Network');
  if (status === 'schoolHours' && affiliation === 'school' && isProfilePaused)
    return t('School device');
  if (status === 'schoolHours' && affiliation === 'school' && !isProfilePaused)
    return t('School hours');
  if (isProfilePaused && affiliation === 'school' && status !== 'paused')
    return t('School device');
  throw new Error(`Cant get the correct title for the status ${status}`);
};

const getText = (
  status: DeviceStatus['status'],
  affiliation: DeviceStatus['affiliation'],
  isProfilePaused: boolean,
  { deviceName, schoolStart, schoolEnd, daysUntilLastSeen, isPause247Enabled }
) => {
  if (status === 'disabled')
    return (
      <ModalStyledText marginBottom="8">
        {t('You have switched off protection and monitoring for this device.')}
      </ModalStyledText>
    );
  if (status === 'offline')
    return (
      <ModalStyledText marginBottom="8">
        {t(
          'We haven’t detected any activity on {{deviceName}} for {{days}} days.',
          {
            deviceName,
            days: daysUntilLastSeen,
          }
        )}
      </ModalStyledText>
    );
  if (status === 'safeNetwork')
    return (
      <ModalStyledText marginBottom="8">
        {t(
          'When a device is connected to a Safe Network, it will benefit from improved security and better connectivity. Coming soon for iOS.'
        )}
      </ModalStyledText>
    );
  if (status === 'schoolHours' && affiliation === 'school') {
    if (isProfilePaused && !isPause247Enabled)
      return (
        <React.Fragment>
          <ModalStyledText marginBottom="8">
            {t(
              "School device isn't paused because it's in school hours ({{schoolStartTime}} - {{schoolEndTime}}).",
              {
                schoolStartTime: schoolStart,
                schoolEndTime: schoolEnd,
              }
            )}
          </ModalStyledText>
          <ModalStyledText marginBottom="8">
            {t(
              'It will be automatically paused at {{schoolEndTime}} if pause is still active.',
              { schoolEndTime: schoolEnd }
            )}
          </ModalStyledText>
        </React.Fragment>
      );

    return (
      <ModalStyledText marginBottom="8">
        {t(
          'During school hours ({{schoolStartTime}} - {{schoolEndTime}}), school can pause the internet and block inappropriate content on school devices.',
          {
            schoolStartTime: schoolStart,
            schoolEndTime: schoolEnd,
          }
        )}
      </ModalStyledText>
    );
  }
  throw new Error(`getText can't get the correct text for status ${status}`);
};

const getButtons = (
  status: DeviceStatus['status'],
  { onClickClose, onNavigateToHelp, onNavigateToSafeNetworkSettings }
) => {
  const buttons = [
    <Button
      key="button"
      onClick={onClickClose}
      size={ButtonSize.medium}
      block
      testId="smart-device-info-modal__confirm-button"
    >
      {t('Ok')}
    </Button>,
  ];
  if (status === 'offline') {
    buttons.push(
      <Button
        buttonType={ButtonType.plain}
        key="button-need-help"
        onClick={onNavigateToHelp}
        size={ButtonSize.medium}
        block
        testId="smart-device-info-modal__help-button"
      >
        {t('Need help?')}
      </Button>
    );
  }
  if (status === 'safeNetwork') {
    buttons.push(
      <Button
        buttonType={ButtonType.plain}
        key="button-safe-network-settings"
        onClick={onNavigateToSafeNetworkSettings}
        size={ButtonSize.medium}
        block
        testId="smart-device-info-modal__safe-network-settings-button"
      >
        {t('Go to settings')}
      </Button>
    );
  }
  return buttons;
};

const statusWithInfoBox: Array<DeviceStatus['status']> = [
  'offline',
  'safeNetwork',
];
const getInfoBoxText = (status: DeviceStatus['status'], { deviceName }) => {
  if (status === 'offline')
    return t(
      'Browse the internet for a few seconds on {{deviceName}} to check that protection is working.',
      {
        deviceName,
      }
    );
  if (status === 'safeNetwork')
    return t('Your rules will be temporarily overridden by school protection.');
};
const getInfoBox = (status: DeviceStatus['status'], { deviceName }) => {
  return statusWithInfoBox.includes(status) ? (
    <Layout marginTop="16">
      <AlertBox type="info" centered>
        {getInfoBoxText(status, { deviceName })}
      </AlertBox>
    </Layout>
  ) : null;
};

const SmartDeviceInfoModal = ({
  status,
  affiliation,
  isProfilePaused,
  schoolStart,
  schoolEnd,
  deviceName,
  daysUntilLastSeen,
  isPause247Enabled,
  onClickClose,
  onNavigateToHelp,
  onNavigateToSafeNetworkSettings,
}: SmartDeviceInfoModalProps) => {
  // on close the modal it re-render itself with null values,
  // that causes and exception,
  // to avoid that if the state is null we render nothing
  if (status === null) return null;
  return (
    <Modal
      title={getTitle(status, affiliation, isProfilePaused, {
        daysUntilLastSeen,
      })}
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            color="secondary"
            size="regular"
            testId="smart-device-info-modal__Icon"
          />
        ),
      }}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={getButtons(status, {
        onClickClose,
        onNavigateToHelp,
        onNavigateToSafeNetworkSettings,
      })}
      buttonsAlignment={ModalButtonsAlignment.column}
      testId="smart-device-info-modal"
    >
      <FlexLayout mainaxis="column" height="100%">
        <FlexLayout mainaxis="column" maxWidth="240" alignSelf="center">
          {getText(status, affiliation, isProfilePaused, {
            deviceName,
            schoolStart,
            schoolEnd,
            daysUntilLastSeen,
            isPause247Enabled,
          })}
          {getInfoBox(status, { deviceName })}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export default SmartDeviceInfoModal;
