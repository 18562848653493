// TODO this is a temporal file to make everything compatible remove and refactor imports
import { DeviceRecord } from './device';

export * from './device';
export * from './devicePlatform';
export * from './deviceProtectionStatus';
export * from './deviceSettings';
export * from './userDevice';
export {
  Alert,
  AlertRecord,
  Device,
  DeviceMethods,
  DevicePayload,
} from './types/Device.types';
export * from './types/DeviceProtectionStatus.types';
export {
  DeviceSettingsMethods,
  DeviceSettingsPayload,
  DeviceSettings,
} from './types/DeviceSettings.types';
export {
  UserDeviceMethods,
  UserDevicePayload,
  UserDevice,
  UserDeviceStatus,
  UserDeviceStatusRecord,
} from './types/UserDevice.types';

export default DeviceRecord;
