import { SideBarMode } from '../constants/index';
import { fromJS, NonEmptyMapRecord } from 'immutable';

const SIDEBAR_STATE_CHANGED = 'SIDEBAR_STATE_CHANGED';
const SIDEBAR_STATE_MODE_CHANGED = 'SIDEBAR_STATE_MODE_CHANGED';
const ACTIVITY_SWIPER_INDEX_CHANGED = 'ACTIVITY_SWIPER_INDEX_CHANGED';
const CLOSE_CALLS_SMS_TRACKING_MODAL = 'CLOSE_CALLS_SMS_TRACKING_MODAL';

export default function ui(
  state: NonEmptyMapRecord<{
    isSideBarActive: boolean;
    sideBarMode: SideBarMode;
    activitySwiperIndex: number;
    hasCallsSMSTrackingModalBeenClosedOnce: boolean;
  }> = fromJS({
    isSideBarActive: false,
    sideBarMode: 'regular',
    activitySwiperIndex: 0,
    hasCallsSMSTrackingModalBeenClosedOnce: false,
  }),
  action
) {
  if (action.type === SIDEBAR_STATE_CHANGED) {
    return state.merge({ isSideBarActive: action.payload });
  }

  if (action.type === SIDEBAR_STATE_MODE_CHANGED) {
    return state.merge({ sideBarMode: action.payload });
  }

  if (action.type === ACTIVITY_SWIPER_INDEX_CHANGED) {
    return state.merge({ activitySwiperIndex: action.payload });
  }

  if (action.type === CLOSE_CALLS_SMS_TRACKING_MODAL) {
    return state.merge({
      hasCallsSMSTrackingModalBeenClosedOnce: true,
    });
  }

  return state;
}

export const sideBarStateChanged = (isSideBarActive: boolean) => {
  return { type: SIDEBAR_STATE_CHANGED, payload: isSideBarActive };
};

export const sideBarStateModeChanged = (
  mode: 'regular' | 'onboarding' = 'regular'
) => {
  return { type: SIDEBAR_STATE_MODE_CHANGED, payload: mode };
};

export const activitySwiperIndexChanged = (newIndex: number) => {
  return { type: ACTIVITY_SWIPER_INDEX_CHANGED, payload: newIndex };
};

export const closeCallsSMSTrackingModal = () => {
  return {
    type: CLOSE_CALLS_SMS_TRACKING_MODAL,
  };
};
