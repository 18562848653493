import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ModalStyledText,
  DurationInput,
  Layout,
} from 'styleguide-react';

import { modalDimensions } from '../../constants';
import {
  addToMoment,
  durationInMinutes,
  formatHumanTwelveClockFormat,
  isTodayDatetime,
} from '../../helpers/dates';
import { t } from '../../lib/i18n';
import { Datetime } from '../../types/dates';
import SchoolPeriodTime from '../SchoolPeriodTime';
import type { StudentPauseModalProps } from '../../containers/Modal/StudentPauseModalContainer';

const StudentPauseModal: React.FunctionComponent<StudentPauseModalProps> = ({
  isPauseActive,
  isPauseEnabled247,
  startPeriodDatetime,
  endPeriodDatetime,
  isPeriodFinished,
  endActivePause,
  isDoingPolicyRequest,
  initialPauseDuration,
  locale,
  timezone,
  onClickClose,
  onSchedulePause,
  isAfterPeriodStart,
}: StudentPauseModalProps) => {
  // if pause is active get end of pause to show the correct duration
  const [pauseDurationInMin, setPauseDurationInMin] = React.useState(
    isPauseActive && endActivePause
      ? durationInMinutes(endActivePause)
      : initialPauseDuration
  );
  const [pauseEndDatetime, setPauseEndDatetime] = React.useState<
    Datetime | undefined
  >(undefined);
  const [isDuringPeriod, setIsDuringPeriod] = React.useState(false);

  React.useEffect(() => {
    // check if a pause is ongoing
    const endDurationDatetime = addToMoment(pauseDurationInMin, 'minutes');
    setPauseEndDatetime(endDurationDatetime);
  }, [pauseDurationInMin]);

  const onChangeDurationHandler = (durationInMin: number) => {
    let totalTimeDuration = durationInMin;
    const endDurationDatetime = addToMoment(totalTimeDuration, 'minutes');
    const isPausedDuringNextPeriod = isAfterPeriodStart(
      endDurationDatetime,
      startPeriodDatetime
    );
    if (isPausedDuringNextPeriod) {
      setIsDuringPeriod(true);
      if (!isPauseEnabled247 && startPeriodDatetime) {
        totalTimeDuration = durationInMinutes(startPeriodDatetime);
      }
    } else if (isDuringPeriod) {
      setIsDuringPeriod(false);
    }
    setPauseDurationInMin(totalTimeDuration);
  };
  const onSubmitDuration = () => {
    onSchedulePause(pauseDurationInMin);
  };

  const pausedUntilTomorrow = pauseEndDatetime
    ? !isTodayDatetime(pauseEndDatetime)
    : false;

  const pausedUntilText = pausedUntilTomorrow
    ? 'Until {{time}} tomorrow'
    : 'Until {{time}}';

  const time = formatHumanTwelveClockFormat(pauseEndDatetime, timezone, locale);
  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      title={t('Pause the internet for...')}
      buttons={[
        <Button
          key="confirm-button-modal"
          onClick={() => {
            onSubmitDuration();
          }}
          loading={isDoingPolicyRequest}
        >
          {t('Confirm')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <Layout marginBottom="16">
        <DurationInput
          value={pauseDurationInMin}
          maxMinutes={24 * 60}
          onChange={onChangeDurationHandler}
        />
      </Layout>
      {pauseEndDatetime && (
        <ModalStyledText>
          <strong>{t(pausedUntilText, { time })}</strong>
        </ModalStyledText>
      )}
      {startPeriodDatetime &&
        endPeriodDatetime &&
        ((isDuringPeriod && !isPauseEnabled247) || isPauseEnabled247) && (
          <ModalStyledText
            className={!isPauseEnabled247 && 'modal__text--danger'}
          >
            {isPauseEnabled247
              ? t(
                  'School can unpause the internet during school hours {1}school period{/1}',
                  {
                    1: () => (
                      <SchoolPeriodTime
                        dateFormatter={formatHumanTwelveClockFormat}
                        periodStart={startPeriodDatetime}
                        periodEnd={endPeriodDatetime}
                        isTomorrow={isPeriodFinished}
                      />
                    ),
                  }
                )
              : t(
                  'The internet will be unpaused when school starts at {1}school period{/1}',
                  {
                    1: () => (
                      <SchoolPeriodTime
                        dateFormatter={formatHumanTwelveClockFormat}
                        periodStart={startPeriodDatetime}
                        periodEnd={endPeriodDatetime}
                        isTomorrow={isPeriodFinished}
                      />
                    ),
                  }
                )}
          </ModalStyledText>
        )}
    </Modal>
  );
};
export default StudentPauseModal;
