import * as React from 'react';
import { Link } from 'react-router';
import {
  GroupHeader,
  InstructionBlock,
  FlexLayout,
  List as WebBrowserList,
  ClickableListItem,
  Switch,
  DeactivableContent,
  FreeStyleListItem,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import YoutubeRulesList from './YoutubeRulesList';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import type { YoutubeRulesProps } from '../../containers/YoutubeRules/YoutubeRulesContainer';

const YoutubeRules = ({
  profile,
  youtubeDeviceRules,
  webRuleEnabled,
  appRulesEnabled,
  isBlockedUnsupportedBrowsers,
  onClickBack,
  onClickWeb,
  onSelectStatus,
  onClickApp,
}: YoutubeRulesProps) => {
  const renderContent = () => (
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="column">
        <InstructionBlock testId="YoutubeRulesInstructionBlock">
          {t(
            'Set rules for {1}{{profileName}}{/1}’s YouTube access and monitor activity {2}here{/2}.',
            {
              1: str => <strong>{str}</strong>,
              profileName: profile.name,
              2: str => (
                <Link to={`/profiles/${profile.id}/youtube-activity`}>
                  {str}
                </Link>
              ),
            }
          )}
        </InstructionBlock>
      </FlexLayout>
      <FlexLayout mainaxis="column" marginTop="24">
        <GroupHeader>
          {t('Block or allow access to YouTube.com', {
            1: str => <strong>{str}</strong>,
          })}
        </GroupHeader>
      </FlexLayout>
      <FlexLayout mainaxis="column" marginTop="32">
        <WebBrowserList testId="YotubeRulesWebBrowserList">
          <FreeStyleListItem testId="YoutubeRulesEnableWebListItem">
            <FlexLayout
              mainaxis="row"
              mainaxisAlignment="space-between"
              crossaxisAlignment="center"
            >
              <DeactivableContent disabled={!webRuleEnabled}>
                <ClickableListItem onClick={() => onClickWeb(webRuleEnabled)}>
                  {t('Browser access to YouTube.com')}
                </ClickableListItem>
              </DeactivableContent>
              <FlexLayout mainaxis="row">
                <Switch
                  id="youtube_web_rule"
                  checked={webRuleEnabled}
                  onChange={onClickWeb(webRuleEnabled)}
                  testId="YoutubeRulesEnableWeb"
                />
              </FlexLayout>
            </FlexLayout>
          </FreeStyleListItem>
        </WebBrowserList>
      </FlexLayout>
      <FlexLayout mainaxis="column" marginTop="32" marginBottom="32">
        <GroupHeader>
          {t('Block or set time limits for the YouTube app', {
            1: str => <strong>{str}</strong>,
          })}
        </GroupHeader>
      </FlexLayout>
      <YoutubeRulesList
        profile={profile}
        list={youtubeDeviceRules}
        appRulesEnabled={appRulesEnabled}
        isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
        onSelectStatus={onSelectStatus}
        onClickApp={onClickApp}
      />
    </FlexLayout>
  );

  return (
    <PageLayout
      title={t('YouTube Monitoring')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
        {
          screenSize: ScreenSize.Tablet,
          mainInsideCard: true,
          columns: 1,
        },
      ]}
      actions={[
        {
          actionName: 'back',
          onClick: onClickBack,
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      {renderContent()}
    </PageLayout>
  );
};

export default YoutubeRules;
