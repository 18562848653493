export interface ScrollSettings {
  position: number | null;
}

class ScrollManager {
  private static instance: ScrollManager;

  private map: Map<string, ScrollSettings>;

  private constructor() {
    this.map = new Map();
  }

  public static getInstance(): ScrollManager {
    if (!ScrollManager.instance) {
      ScrollManager.instance = new ScrollManager();
    }

    return ScrollManager.instance;
  }

  public register(scrollKey: string): string {
    if (this.map.has(scrollKey)) {
      throw Error(`ScrollManager - scroll key ${scrollKey} already added`);
    }

    this.map.set(scrollKey, {
      position: null,
    });

    return scrollKey;
  }

  public get(scrollKey: string): ScrollSettings | undefined {
    return this.map.get(scrollKey);
  }

  public has(scrollKey: string): boolean {
    return this.map.has(scrollKey);
  }

  public updatePosition(scrollKey: string, position: number): void {
    if (this.has(scrollKey)) {
      const settings = this.map.get(scrollKey);
      if (settings) {
        settings.position = position;
        this.map.set(scrollKey, settings);
      }
    }
  }

  public reset(scrollKey: string): void {
    if (this.has(scrollKey)) {
      this.map.set(scrollKey, {
        position: null,
      });
    }
  }

  public hasPosition(scrollKey: string): boolean {
    return this.has(scrollKey) && this.get(scrollKey)?.position !== null;
  }

  public unregisterAll(): void {
    this.map.clear();
  }
}

export default ScrollManager;
