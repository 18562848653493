import { SchoolReferralCampaignCodes } from '../../constants';
import { SchoolLinkedStatus } from '../../records/account';
import { getSchoolLinkedStatus } from '../../selectors/stateSelectors/account';
import { getLicense } from '../../selectors/stateSelectors/license';
import State from '../../store/state';

/**
 * This function will return whether this is a valid school account
 *
 * @param state
 * @returns boolean
 */
export const isSchoolAccount = (state: State) => {
  // Due to an existing bug on LW, we are not unlinking school accounts
  // but we have decided to keep those account still as valid school accounts
  return getSchoolLinkedStatus(state) === SchoolLinkedStatus.linked;
};

export const isSchoolReferral = (state: State) => {
  // this is something temporary until we design a better solution
  // we will check the campaign code.
  return SchoolReferralCampaignCodes.includes(getLicense(state).name);
};
