import * as R from 'ramda';
import { Mapping, Mappings } from './types/Mapping.types';

/**
 * Look for the first applicable mapping and get the value.
 *
 * @param mappings List of possible mappings applicable to the value
 * @param value Value to be mapped
 * @returns if the mapping is applicable, the value transformed, if not, the
 * value as it is
 */
const applyValueMappings =
  <Out, In = string>(mappings: Mappings<Out, In>) =>
  (value: In): Out | In =>
    // Look for the first applicable mapping
    R.find<Mapping<Out, In>>(mapping => mapping.isApplicable(value))(
      mappings
      // If found, apply it, of not, return it as it is
    )?.getValue(value) ?? value;

/**
 * For all the values in an object, look for the first applicable mapping and
 * get the value.
 *
 * @param mappings List of possible mappings applicable to the value
 * @returns the value transformed if the mapping is found in the mapping list
 * for the value, if not, the value as it is
 */
export const applyMappings = <Out, In = string>(mappings: Mappings<Out, In>) =>
  R.mapObjIndexed(applyValueMappings(mappings));
