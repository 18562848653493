import { GlobalType, theme } from 'styleguide-react';
import { RoutineColor } from '../palettes/RoutineColor';

export enum ParColor {
  secondaryLighter = 'secondaryLighter',
}

export type Color = GlobalType | RoutineColor | ParColor;

export const getColor = (color: Color) => {
  const colorMappings: Record<Color, string> = {
    [GlobalType.primary]: theme.palette.primary,
    [GlobalType.secondary]: theme.palette.secondary,
    [GlobalType.gray]: theme.palette.gray,
    [GlobalType.warning]: theme.palette.warning,
    [GlobalType.error]: theme.palette.error,
    [GlobalType.white]: theme.palette.white,
    [GlobalType.school]: theme.palette.school,
    [GlobalType.marketing]: theme.palette.marketingOrange,
    [GlobalType.decorationGreen]: theme.palette.decorationGreen,
    [GlobalType.decorationBlue]: theme.palette.decorationBlue,
    [GlobalType.decorationOrange]: theme.palette.decorationOrange,
    [GlobalType.success]: theme.palette.success,
    [GlobalType.booster]: theme.palette.booster,
    [GlobalType.default]: theme.palette.primary,
    [RoutineColor.yellow]: theme.routinesPalette.yellow,
    [RoutineColor.red]: theme.routinesPalette.red,
    [RoutineColor.purple]: theme.routinesPalette.purple,
    [RoutineColor.pink]: theme.routinesPalette.pink,
    [RoutineColor.blue]: theme.routinesPalette.blue,
    [RoutineColor.green]: theme.routinesPalette.green,
    [RoutineColor.marine]: theme.routinesPalette.marine,
    [ParColor.secondaryLighter]: theme.palette.secondaryLighter,
  };

  const mappedColor = colorMappings[color];
  if (mappedColor) return mappedColor;
  return undefined;
};
