import * as React from 'react';
import type { WebsiteBlockedWidgetProps } from '../../containers/ActivitySummary/WebsiteBlockedWidgetContainer';
import MiniActivitySummary from '../Dashboard/DashboardTopCards/MiniActivitySummary/MiniActivitySummary';
import { t } from '../../lib/i18n';
import { IconType } from 'styleguide-react';

const getNoActivityMessage = (combinedProfileWithoutDelegation: boolean) => {
  if (combinedProfileWithoutDelegation)
    return t('No website alerts on personal devices');
  return t('No website alerts');
};

const WebsiteBlockedWidget = ({
  totalBlockedSites,
  isFetchingInfo,
  hasError,
  combinedProfileWithoutDelegation,
  loadWidget,
  navigateToDetails,
}: WebsiteBlockedWidgetProps) => {
  React.useEffect(() => {
    loadWidget();
  }, []);
  const testId = 'website-blocked-widget';
  if (hasError) {
    return (
      <MiniActivitySummary
        title={t("Website alerts couldn't load")}
        type="error"
        testId={testId}
      />
    );
  }

  if (isFetchingInfo) {
    return <MiniActivitySummary type="loading" testId={testId} />;
  }

  if (totalBlockedSites === 0) {
    return (
      <MiniActivitySummary
        title={getNoActivityMessage(combinedProfileWithoutDelegation)}
        type="success"
        testId={testId}
      />
    );
  }

  return (
    <MiniActivitySummary
      title={t('Website alerts')}
      value={totalBlockedSites.toString()}
      iconType={IconType.ban}
      onClick={navigateToDetails}
      type="blocked"
      testId={testId}
    />
  );
};

export default WebsiteBlockedWidget;
