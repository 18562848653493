import * as React from 'react';
import { Layout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PremiumFeaturesInfo, { DisplayMode } from './PremiumFeaturesInfo';

const UpgradePremiumFeatures: React.FunctionComponent = () => (
  <React.Fragment>
    <h3 className="UpgradePremiumFeatures__title">
      {t(
        'Superior Features that make {{shortName}} Premium great for Parents!'
      )}
    </h3>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <PremiumFeaturesInfo displayMode={DisplayMode.table} />
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <Layout paddingBottom="24">
        <PremiumFeaturesInfo displayMode={DisplayMode.list} />
        <div className="UpgradePremiumFeatures__availableSoon">
          {t('* Available soon for iOS devices.')}
        </div>
      </Layout>
    </RenderWhen>
  </React.Fragment>
);

export default UpgradePremiumFeatures;
