import * as React from 'react';
import {
  Card,
  ClickableListItem,
  EmptyPlaceholder,
  List,
  ListSubtitle,
  ListTitle,
  RegularStyleListItem,
  Svg,
  Icon,
  IconType,
  FlexLayout,
  FreeText,
  Layout,
} from 'styleguide-react';
import type { StudentSignaturesCardProps } from '../../containers/Schools/StudentSignaturesCardWithObserverContainer';
import { classNames } from '../../helpers';
import { t } from '../../lib/i18n';
import { SignaturesActivity } from '../../records/studentActivity/types/signatures.types';
import InfoIcon from '../Icons/InfoIcon';
import IconRecommendation from './IconRecommendation';
import SchoolFeatureDisabled from './SchoolFeatureDisabled';
import StudentSummaryError from './StudentsSummaryError';
import LoadingContent from '../Dashboard/Loading/LoadingContent';

const formatPercent = (percentage: number): string => `${percentage}%`;

type IconPercentageProps = {
  signaturesActivity: SignaturesActivity;
  showPercent: boolean;
  onClickSignature: (id: SignaturesActivity['id']) => void;
};

const IconPercentage: React.FunctionComponent<IconPercentageProps> = ({
  signaturesActivity,
  showPercent,
  onClickSignature,
}: IconPercentageProps) => {
  return (
    <FlexLayout
      mainaxis="row"
      crossaxisAlignment="center"
      mainaxisAlignment="space-between"
      minWidth={showPercent ? '104' : 'auto'}
    >
      {showPercent && (
        <Layout marginRight="40">
          <FreeText fontSize="14px" color="gray">
            {formatPercent(signaturesActivity.usagePercentage)}
          </FreeText>
        </Layout>
      )}
      <ClickableListItem
        key={signaturesActivity.id}
        onClick={() => onClickSignature(signaturesActivity.id)}
      >
        <IconRecommendation type={signaturesActivity.recommendation} />
      </ClickableListItem>
    </FlexLayout>
  );
};

const UrlIcon: React.FunctionComponent<Partial<SignaturesActivity>> = ({
  iconUrl,
  name,
}: Partial<SignaturesActivity>) => (
  // Referrer policy fixes issues with LW images not loading from google services PAR-4947
  <img src={iconUrl} alt={name} width="24px" referrerPolicy="no-referrer" />
);

const StudentSignaturesCardWithObserver: React.FunctionComponent<
  StudentSignaturesCardProps
> = ({
  signaturesActivity,
  onClickSignature,
  onClickInfo,
  maxSignatureActivityItems = Infinity,
  showPercent = false,
  onClickSeeAll,
  single = false,
  isFeatureDisabled,
  extraHeight = false,
  isLoadingSignatureInfo,
  profileName,
  errorOnloading,
  loadInfo,
  onClickContactUs,
}: StudentSignaturesCardProps) => {
  React.useEffect(() => {
    loadInfo();
  }, []);
  const selectedSignaturesActivity = signaturesActivity
    .filter(signatureActivity => signatureActivity.usagePercentage > 0)
    .take(maxSignatureActivityItems);
  const cardContent = () => {
    if (isFeatureDisabled) {
      return <SchoolFeatureDisabled />;
    }
    if (errorOnloading) {
      return (
        <StudentSummaryError
          error={errorOnloading}
          profileName={profileName}
          onClickContactUs={onClickContactUs}
        />
      );
    }
    if (isLoadingSignatureInfo) {
      return (
        <LoadingContent
          text={t('Loading {{profileName}} most used apps and websites', {
            profileName,
          })}
        />
      );
    }
    if (selectedSignaturesActivity.size) {
      return (
        <List>
          {selectedSignaturesActivity.map(data => (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <RegularStyleListItem
              key={data.id}
              icon={<UrlIcon iconUrl={data.iconUrl} name={data.name} />}
              title={
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <ClickableListItem
                  key={data.id}
                  onClick={() => onClickSignature(data.id)}
                >
                  <ListTitle isBold>{data.name}</ListTitle>
                </ClickableListItem>
              }
              upperSubtitle={
                <ClickableListItem
                  key={data.id}
                  onClick={() => onClickSignature(data.id)}
                >
                  <ListSubtitle allowLines="1">
                    {data.categoryName}
                  </ListSubtitle>
                </ClickableListItem>
              }
              iconVerticalAlign="center"
              actionElement={
                <IconPercentage
                  signaturesActivity={data}
                  showPercent={showPercent}
                  onClickSignature={onClickSignature}
                />
              }
            />
          ))}
        </List>
      );
    }
    return (
      <EmptyPlaceholder
        icon={
          <Svg height="64" width="64" color="gray-light">
            <InfoIcon />
          </Svg>
        }
        fillAvailableSpace
        centered
        smallText
        text={t('No activity during this period')}
      />
    );
  };
  return (
    <Card
      className={classNames(
        'StudentSummary__card',
        extraHeight && 'StudentSummary__card--extra-height',
        single ? 'StudentSummary__card--single' : ''
      )}
      header={{
        title: t('Most used apps & websites'),
        actionElement: (
          <Icon
            type={IconType.infoCircle}
            testId="student-signatures-card-info"
          />
        ),
        onClickAction: onClickInfo,
      }}
      footer={
        selectedSignaturesActivity.size &&
        maxSignatureActivityItems !== Infinity
          ? {
              actionElement: t('See all'),
              onClickAction: onClickSeeAll,
            }
          : undefined
      }
    >
      {cardContent()}
    </Card>
  );
};

export default StudentSignaturesCardWithObserver;
