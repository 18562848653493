import { connect } from 'react-redux';
import { goBackIfHistory } from '../ducks/routing';
import SummaryPremiumFlyover from '../components/Profiles/SummaryPremiumFlyover';
import { gaEvent } from '../helpers/ga';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => {
    gaEvent('more-history-data-flyover-premium', 'click', 'close');
    return dispatch(goBackIfHistory());
  },
  onClickUpgrade: () => {
    const navigate = getMultiPlatformNavigation();
    gaEvent('more-history-data-flyover-premium', 'click', 'upgrade');
    return dispatch(navigate({ type: 'inner', src: '/upgrade' }));
  },
});

const SummaryPremiumFlyoverContainer = connect(
  null,
  mapDispatchToProps
)(SummaryPremiumFlyover);

export default SummaryPremiumFlyoverContainer;
