import { OrderedMap, Record } from 'immutable';
import { AppCategoriesActions, AppCategoriesState } from './types';
import { AppCategory } from '../../records/appCategories/types';

export const createInitialState: () => AppCategoriesState = Record({
  status: 'IDLE',
  appCategories: OrderedMap({}),
});

export const initialState = createInitialState();

export const updateAppCategories = (
  state: AppCategoriesState,
  appCategories: Array<Record<AppCategory>>
): AppCategoriesState => {
  const updatedState = appCategories.reduce((acc, appCategory) => {
    return acc.setIn(['appCategories', appCategory.categoryId], appCategory);
  }, state);

  return updatedState.set('status', 'IDLE');
};

const appCategoriesReducer = (
  state: AppCategoriesState = initialState,
  action: AppCategoriesActions
) => {
  switch (action.type) {
    case 'REQUEST_APP_CATEGORIES':
      return state.set('status', 'LOADING');
    case 'RECEIVE_APP_CATEGORIES':
      return updateAppCategories(state, action.payload);
    default:
      return state;
  }
};

export default appCategoriesReducer;
