import {
  goToEditDailyLimit,
  goToEditProfileRules,
  goToEditProfileRulesTimeRestrictions,
} from '../../../actions/NowCardActions';
import { ActionOptions, ActionOptionsParam, ActionsOptions } from './types';
import { t } from '../../../lib/i18n';
import { IconType } from 'styleguide-react';
import {
  navigateToExtraTimeModal,
  navigateToPauseProfileModal,
  navigateToRoutineSwitchModal,
  navigateToUndoRoutineSwitchModal,
} from '../../../actions/Navigation';
import { trackQuickActionClick } from '../../../ducks/unifiedNowCard/thunks';
import { ButtonNames } from '../../../helpers/analytics';
import { navigateToEditRoutine } from '../../../actions/RoutinesActions';

// Utils
// -------------------------

const maybeOption = <T,>(cond: boolean | undefined, options: T): T[] =>
  cond ? [options] : [];

// Actions
// -------------------------

const actionEditRules = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionOptions => ({
  text: t('Edit rules'),
  icon: IconType.slidersHorizontal,
  value: 'edit-rules',
  onClick: () => {
    actionWrapper(trackQuickActionClick(ButtonNames.Rule, { state }));
    actionWrapper(goToEditProfileRules(profileId));
  },
});

const actionEditRestrictedTimes = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionOptions => ({
  text: t('Edit restricted times'),
  icon: IconType.lock,
  value: 'edit-restricted-times',
  onClick: () => {
    actionWrapper(
      trackQuickActionClick(ButtonNames.EditRestrictedTimes, { state })
    );
    actionWrapper(goToEditProfileRulesTimeRestrictions(profileId));
  },
});

const actionEditExtraTime = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionOptions => ({
  text: t('Edit extra time'),
  icon: IconType.plus,
  value: 'edit-extra-times',
  onClick: () => {
    actionWrapper(trackQuickActionClick(ButtonNames.EditExtraTime, { state }));
    actionWrapper(
      navigateToExtraTimeModal(String(profileId), 'unifiedNowCard')
    );
  },
});

const actionAddExtraTime = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionOptions => ({
  text: t('Add extra time'),
  icon: IconType.plus,
  value: 'add-extra-times',
  onClick: () => {
    actionWrapper(trackQuickActionClick(ButtonNames.AddExtraTime, { state }));
    actionWrapper(
      navigateToExtraTimeModal(String(profileId), 'unifiedNowCard')
    );
  },
});

const actionEditTimeLimit = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionOptions => ({
  text: t('Edit daily time limits'),
  icon: IconType.edit,
  value: 'edit-daily-time-limit',
  onClick: () => {
    actionWrapper(
      trackQuickActionClick(ButtonNames.EditDailyTimeLimits, { state })
    );
    actionWrapper(goToEditDailyLimit(profileId));
  },
});

const actionEditPause = ({
  actionWrapper,
  state,
}: ActionOptionsParam<'actionWrapper' | 'state'>): ActionOptions => ({
  text: t('Edit pause'),
  icon: IconType.pauseCircle,
  value: 'edit-pause',
  onClick: () => {
    actionWrapper(
      trackQuickActionClick(ButtonNames.EditPauseInternet, { state })
    );
    actionWrapper(navigateToPauseProfileModal());
  },
});

const actionSetPause = ({
  actionWrapper,
}: ActionOptionsParam<'actionWrapper'>): ActionOptions => ({
  text: t('Pause the internet /verb/'),
  icon: IconType.pauseCircle,
  value: 'set-pause',
  onClick: () => {
    actionWrapper(navigateToPauseProfileModal());
  },
});

const actionEditRoutine = ({
  profileId,
  routineUid,
  actionWrapper,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'routineUid'
>): ActionOptions => ({
  text: t('Edit routine'),
  icon: IconType.calendarEdit,
  value: 'edit-routine',
  onClick: () => {
    actionWrapper(navigateToEditRoutine({ profileId, routineUid }));
  },
});

const actionUndoSwitchRoutine = ({
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'actionWrapper' | 'state' | 'routineUid' | 'scheduleUid'
>): ActionOptions => ({
  text: t('Undo routine switch'),
  // FIXME(rocket): Replace this with the corresponding IconType
  icon: 'fa-shuffle',
  value: 'undo-routine-switch',
  onClick: () => {
    actionWrapper(
      navigateToUndoRoutineSwitchModal(routineUid, scheduleUid, state)
    );
  },
});

const actionSwitchRoutine = ({
  actionWrapper,
  state,
}: ActionOptionsParam<'actionWrapper' | 'state'>): ActionOptions => ({
  text: t('Switch routine'),
  // FIXME(rocket): Replace this with the corresponding IconType
  icon: 'fa-shuffle',
  value: 'routine-switch',
  onClick: () => {
    actionWrapper(trackQuickActionClick(ButtonNames.SwitchRoutine, { state }));
    actionWrapper(navigateToRoutineSwitchModal());
  },
});

// Option groups
// -------------------------

export const optionsEditRules = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionsOptions => [actionEditRules({ profileId, actionWrapper, state })];

export const optionsEditRestrictedTimes = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionsOptions => [
  actionEditRestrictedTimes({ profileId, actionWrapper, state }),
];

export const optionsEditRestrictedTimesWithSchoolDevice = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionsOptions => [
  actionEditRestrictedTimes({ profileId, actionWrapper, state }),
  actionSetPause({ actionWrapper }),
];

export const optionsEditRulesAndRestrictedTimes = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<'profileId' | 'actionWrapper' | 'state'>) => [
  actionEditRestrictedTimes({ profileId, actionWrapper, state }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const optionsAddExtraTimeAndDailyLimits = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<'profileId' | 'actionWrapper' | 'state'>) => [
  actionAddExtraTime({ profileId, actionWrapper, state }),
  actionEditTimeLimit({ profileId, actionWrapper, state }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const optionsAddExtraTimeAndDailyLimitsWithSchoolDevice = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<'profileId' | 'actionWrapper' | 'state'>) => [
  actionAddExtraTime({ profileId, actionWrapper, state }),
  actionEditTimeLimit({ profileId, actionWrapper, state }),
  actionEditRules({ profileId, actionWrapper, state }),
  actionSetPause({ actionWrapper }),
];

export const optionsEditExtraTimeDailyTimeLimitsAndEditRules = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionsOptions => [
  actionEditExtraTime({ profileId, actionWrapper, state }),
  actionEditTimeLimit({ profileId, actionWrapper, state }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const optionsEditPauseAndRules = ({
  profileId,
  actionWrapper,
  state,
}: ActionOptionsParam<
  'profileId' | 'actionWrapper' | 'state'
>): ActionsOptions => [
  actionEditPause({ actionWrapper, state }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const editPause = ({
  actionWrapper,
  state,
}: ActionOptionsParam<'actionWrapper' | 'state'>): ActionsOptions => [
  actionEditPause({ actionWrapper, state }),
];

export const optionsEditRoutine = ({
  profileId,
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
  isRoutineSwitched,
}: ActionOptionsParam<
  | 'profileId'
  | 'routineUid'
  | 'scheduleUid'
  | 'actionWrapper'
  | 'state'
  | 'isRoutineSwitched'
>) => [
  ...maybeOption(
    isRoutineSwitched,
    actionUndoSwitchRoutine({ routineUid, scheduleUid, actionWrapper, state })
  ),
  actionEditRoutine({ profileId, routineUid: routineUid || '', actionWrapper }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const optionsDailyLimitsEditRoutineAndEditRules = ({
  profileId,
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
  isRoutineSwitched,
}: ActionOptionsParam<
  | 'profileId'
  | 'routineUid'
  | 'scheduleUid'
  | 'actionWrapper'
  | 'state'
  | 'isRoutineSwitched'
>) => [
  ...maybeOption(
    isRoutineSwitched,
    actionUndoSwitchRoutine({ routineUid, scheduleUid, actionWrapper, state })
  ),
  actionEditTimeLimit({ profileId, actionWrapper, state }),
  actionEditRoutine({
    profileId,
    routineUid: routineUid || '',
    actionWrapper,
  }),
  actionEditRules({ profileId, actionWrapper, state }),
];

export const optionsDailyLimitsEditRoutineEditRulesAndAddExtraTime = ({
  profileId,
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
  isRoutineSwitched,
}: ActionOptionsParam<
  | 'profileId'
  | 'routineUid'
  | 'scheduleUid'
  | 'actionWrapper'
  | 'state'
  | 'isRoutineSwitched'
>) => [
  ...maybeOption(
    isRoutineSwitched,
    actionUndoSwitchRoutine({ routineUid, scheduleUid, actionWrapper, state })
  ),
  actionAddExtraTime({ profileId, actionWrapper, state }),
  ...optionsDailyLimitsEditRoutineAndEditRules({
    profileId,
    routineUid,
    scheduleUid,
    actionWrapper,
    state,
    // This function also shows "undo switch routine", we already include this option above
    isRoutineSwitched: false,
  }),
];

export const optionsDailyLimitsEditRoutineEditRulesAndEditExtraTime = ({
  profileId,
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
  isRoutineSwitched,
}: ActionOptionsParam<
  | 'profileId'
  | 'routineUid'
  | 'scheduleUid'
  | 'actionWrapper'
  | 'state'
  | 'isRoutineSwitched'
>) => [
  ...maybeOption(
    isRoutineSwitched,
    actionUndoSwitchRoutine({ routineUid, scheduleUid, actionWrapper, state })
  ),
  actionEditExtraTime({ profileId, actionWrapper, state }),
  ...optionsDailyLimitsEditRoutineAndEditRules({
    profileId,
    routineUid,
    scheduleUid,
    actionWrapper,
    state,
    // This function also shows "undo switch routine", we already include this option above
    isRoutineSwitched: false,
  }),
];

export const optionsSwitchRoutine = ({
  state,
  actionWrapper,
  isRoutineSwitched,
  isSwitchRoutineFeatureEnabled,
}: ActionOptionsParam<
  | 'actionWrapper'
  | 'profileId'
  | 'isRoutineSwitched'
  | 'isSwitchRoutineFeatureEnabled'
  | 'state'
>) => [
  ...maybeOption(
    isSwitchRoutineFeatureEnabled && !isRoutineSwitched,
    actionSwitchRoutine({
      state,
      actionWrapper,
    })
  ),
];

export const optionsUndoSwitchRoutine = ({
  routineUid,
  scheduleUid,
  actionWrapper,
  state,
  isRoutineSwitched,
}: ActionOptionsParam<
  'routineUid' | 'scheduleUid' | 'actionWrapper' | 'state' | 'isRoutineSwitched'
>) => [
  ...maybeOption(
    isRoutineSwitched,
    actionUndoSwitchRoutine({ routineUid, scheduleUid, actionWrapper, state })
  ),
];
