import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import ChangeContentFilterRulesModal from '../../../components/Routines/Modals/ChangeContentFilterRulesModal';
import { getActiveProfileUid, getQueryParam } from '../../../selectors';
import { PolicyAction } from '../../../records/routines/policy/types/Policy.types';
import {
  setRoutineMachineAction,
  updateRoutine,
} from '../../../ducks/routines';
import { getRoutine } from '../../../ducks/routines/selectors';
import { RoutineOperations } from '../../../records/routines/routine';
import { List } from 'immutable';
import {
  RoutineMode,
  RoutineRuleExceptionType,
} from '../../../components/Routines/routines.types';
import {
  getBlockedHarmfulWebRuleException,
  safeDispatchChangeMachineAction,
} from '../helpers';
import { PolicyWebCategoryOperations } from '../../../records/routines/policy/policyWebCategory';

const updateRoutineThunk =
  (
    routineUid: string,
    type: RoutineRuleExceptionType,
    policyAction: PolicyAction
  ) =>
  (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const uid = getActiveProfileUid(state)!;
    const routine = getRoutine(state, routineUid);
    let updatedRoutine = routine;

    if (type === 'APP') {
      updatedRoutine = updatedRoutine
        .setIn(['policy', 'appRules', 'appRulesList'], List([]))
        .setIn(['policy', 'appRules', 'defaultAction'], policyAction);
    }

    if (type === 'WEB') {
      const webRulesList = getBlockedHarmfulWebRuleException(policyAction).map(
        webCategory => PolicyWebCategoryOperations.create(webCategory)
      );

      updatedRoutine = updatedRoutine
        .setIn(['policy', 'webRules', 'webRulesList'], List(webRulesList))
        .setIn(['policy', 'webRules', 'defaultAction'], policyAction);
    }

    const body = RoutineOperations.serialize(updatedRoutine);
    dispatch(updateRoutine(uid, routineUid, body));
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
  const back = () => dispatch(goBackIfHistory());
  return {
    onClickChange: (
      routineUid: string | null,
      mode: RoutineMode,
      type: RoutineRuleExceptionType,
      policyAction: PolicyAction
    ) => {
      if (mode === 'EDIT') {
        dispatch(updateRoutineThunk(routineUid!, type, policyAction));
      }

      if (mode === 'CREATE') {
        const webRulesList = getBlockedHarmfulWebRuleException(policyAction);
        safeDispatchChangeMachineAction(
          dispatch,
          setRoutineMachineAction({
            type: 'UPDATE_CONTEXT',
            params: {
              contentFilter:
                type === 'APP'
                  ? { appRuleType: policyAction, appsException: [] }
                  : { webRuleType: policyAction, websException: webRulesList },
            },
          })
        );
      }

      back();
    },
    onClickClose: back,
  };
};

const mapStateToProps = (state: State) => {
  const profileUid = getActiveProfileUid(state);
  const routineUid = getQueryParam(state, 'routineUid');
  const policyAction = getQueryParam(state, 'policyAction');
  const mode = getQueryParam(state, 'mode') as RoutineMode;
  const type = getQueryParam(state, 'type') as RoutineRuleExceptionType;

  return {
    type,
    profileUid,
    routineUid,
    policyAction: policyAction as PolicyAction,
    mode,
  };
};

const ChangeContentFilterRulesModalContainerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeContentFilterRulesModal);

export type ChangeContentFilterRulesModalProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default ChangeContentFilterRulesModalContainerContainer;
