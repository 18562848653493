import { OrderedSet } from 'immutable';
import { ProfileRecord } from '../profile/types/Profile.types';
import { DeviceRecord } from './types/Device.types';
import { DeviceProtectionStatus } from './types/DeviceProtectionStatus.types';
import { EventType } from '../../constants';

export const getProtectionStatus: (
  device: DeviceRecord,
  profiles: OrderedSet<ProfileRecord>
) => DeviceProtectionStatus = (device, profiles) => {
  if (device.alerts.unauthorizedRemove) {
    return 'ProtectionTampered';
  }
  if (
    device.users.every(
      user => user?.getIn(['status', 'disableProtection', 'status']) || false
    )
  ) {
    return 'ProtectionDisabled';
  }
  if (
    device.users.every(
      user =>
        user?.getIn(['status', 'safeNetwork', 'status']) ===
          EventType.SafeNetworkDisablingProtection || false
    )
  ) {
    return 'SafeNetwork';
  }
  if (profiles.size === 0) {
    return 'ProtectionUnprotected';
  }
  return 'ProtectionEnabled';
};

export const getTampering = () => false;
