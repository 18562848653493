import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable, merge } from 'rxjs';
import { is, pipe } from 'ramda';
import { StateObservable } from 'redux-observable';
import { Action } from 'redux';
import { ObservableOperator, SideEffect } from './types';
import { APPThunk } from '../helpers/thunks';
import State, { Dispatch } from '../store/state';

/*
doWithoutStateEffect:
This operator is very useful for triggering side effects that depend on actions 
but have no effect on state changes. 

A good example of use is sending analytics
*/
export const doWithoutStateEffect = <I>(
  sideEffect: SideEffect<I>
): ObservableOperator<I, unknown> =>
  pipe(
    tap(sideEffect),
    switchMap(() => EMPTY)
  );

/**
 * Transforms a thunk into an observable.
 * It is very useful to reuse a sequence of actions dispatched by the thunk.
 * If used with asynchronous thunks you must ensure that they return a promise to ensure observables complete correctly.
 *
 * IMPORTANT: is recommended to use mergeMapThunkActions to avoid having in mind detail of the use of mergeMap
 *
 * IMPORTANT: This method must be used together with mergeMap to launch
 * the sequence of actions that dispatches the thunk to the store.
 *
 * Example:
 * ```ts
 * const profileEpic = (actions$, state$) => {
 *  return actions$.pipe(
 *    ofType('LOAD_PROFILE_DATA'),
 *    mergeMap((action) => thunkToObservable(fetchProfileThunk(action.id), state$))
 *  );
 * }
 * ```
 */
export const thunkToObservable = <ActionType = Action>(
  thunk: APPThunk,
  state$: StateObservable<State>
): Observable<ActionType> => {
  return new Observable(observer => {
    const getState: () => State = () => state$.value;

    const dispatch: Dispatch = action =>
      typeof action === 'function'
        ? action(dispatch, getState)
        : observer.next(action);

    try {
      const action = thunk(dispatch, getState);

      if (is(Promise, action)) {
        action
          .then(() => {
            return observer.complete();
          })
          .catch(e => observer.error(e));
      } else if (is(Observable, action)) {
        action.subscribe({
          next: x => observer.next(x),
          error: e => observer.error(e),
          complete: () => observer.complete(),
        });
      } else {
        observer.complete();
      }
    } catch (e) {
      observer.error(e);
    }
  });
};

/**
 *
 * This operator puts all actions dispatched in the returned thunk in observable context.
 * Concurrency can be specified as optional parameter.
 * IMPORTANT: THIS OPERATOR MUST RETURN A THUNK IN THE PROVIDED CALLBACK.

 * Example:
 * ```ts
 * const profileEpic = (actions$, state$) => {
 *  return actions$.pipe(
 *    ofType('LOAD_PROFILE_DATA'),
 *    mergeMapThunkActions((action) => loadProfile(action.payload.profileId), state$)
 *                                   // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ must return a thunk            
 *  );
 * }
 * ```
 */
export const mergeMapThunkActions = <ActionInput>(
  createThunk: (action: ActionInput) => APPThunk,
  state$: StateObservable<State>,
  concurrent?: number
) =>
  mergeMap((action: ActionInput) => {
    const thunk = createThunk(action);
    const observable = thunkToObservable(thunk, state$);
    return observable;
  }, concurrent);

// eslint-disable-next-line no-console
export const log = tag => tap(data => console.log(tag, data));

// f :: Observable -> Observablr
export const mergeMapAllThunkActions = <ActionInput>(
  fns: Array<(action: ActionInput) => APPThunk>,
  state$: StateObservable<State>,
  concurrent?: number
) =>
  mergeMap((action: ActionInput) => {
    const thunks = fns.map(createThunk => createThunk(action));
    const observables = thunks.map(thunk => thunkToObservable(thunk, state$));
    return merge(...observables);
  }, concurrent);

export const mergeMapThunkActionsWithoutStateEffect = <ActionInput>(
  createThunk: (action: ActionInput) => APPThunk,
  state$: StateObservable<State>,
  concurrent?: number
) =>
  pipe(
    mergeMapThunkActions(createThunk, state$, concurrent),
    switchMap(() => EMPTY)
  );
