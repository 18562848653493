import * as React from 'react';
import {
  FlexLayout,
  Icon,
  IconFamily,
  IconType,
  Layout,
} from 'styleguide-react';
import {
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_MAC,
  PLATFORM_WINDOWS,
} from '../../constants';
import { t } from '../../lib/i18n';
import { AppRuleRecord } from '../../records/profileRules';

const AppRulesPlatform: React.FunctionComponent<{
  app: AppRuleRecord;
}> = ({ app }) => {
  switch (app.platform) {
    case PLATFORM_IOS:
      return (
        <FlexLayout mainaxis="row">
          <Icon
            type={IconType.apple}
            family={IconFamily.brands}
            testId="AppRulesAppPlatformIconIos"
          />
          <Layout marginLeft="8" testId="AppRulesAppPlatformNameIos">
            {t('iOS')}
          </Layout>
        </FlexLayout>
      );
    case PLATFORM_ANDROID:
      return (
        <FlexLayout mainaxis="row">
          <Icon
            type={IconType.android}
            family={IconFamily.brands}
            testId="AppRulesAppPlatformIconAndroid"
          />
          <Layout marginLeft="8" testId="AppRulesAppPlatformNameAndroid">
            {t('Android')}
          </Layout>
        </FlexLayout>
      );
    case PLATFORM_WINDOWS:
      return (
        <FlexLayout mainaxis="row">
          <Icon
            type={IconType.windows}
            family={IconFamily.brands}
            testId="AppRulesAppPlatformIconWindow"
          />
          <Layout marginLeft="8" testId="AppRulesAppPlatformNameWindows">
            {t('Windows')}
          </Layout>
        </FlexLayout>
      );
    case PLATFORM_MAC:
      return (
        <FlexLayout mainaxis="row">
          <Icon
            type={IconType.apple}
            family={IconFamily.brands}
            testId="AppRulesAppPlatformIconMac"
          />
          <Layout marginLeft="8" testId="AppRulesAppPlatformNameMac">
            {t('Mac')}
          </Layout>
        </FlexLayout>
      );
    default:
      return null;
  }
};

export default React.memo(AppRulesPlatform);
