import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  combineLatest,
  from,
  map,
  merge,
  mergeMap,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { AppEpic } from '../../../epics/types';
import type {
  RoutineDetailsReceiveAction,
  RoutineStatusAction,
  CreateRoutineAction,
  RoutinesReceiveAction,
  RoutinesRequestAction,
  SetTempRoutineUidAction,
  RoutineSchedulesRequestAction,
  RoutineBulkSchedulesRequestAction,
} from '../types';
import {
  receiveRoutines,
  setRoutinesStatus,
  receiveRoutineDetails,
  setTempRoutineUid,
  requestRoutineSchedules,
  requestBulkRoutineSchedules,
} from '../actions';
import { APIError } from '../../../lib/errors';
import { showErrorAlert } from '../../../helpers/errorHandling';
import { RoutineOperations } from '../../../records/routines/routine';
import { RoutinePayload } from '../../../records/routines/types/Routine.types';
import { normalizeRoutinesData } from '../transformations';
import { CollectionResponse } from '../../../types/api';
import { getTempRoutine } from '../selectors';
import { trackSaveNewRoutine } from '../../../helpers/analytics';
import {
  ttl10Seconds,
  ttlZero,
} from '../../../lib/QApiCache/commonCacheStrategies';
import { RECEIVE_PROFILE_RULES } from '../../profileRules';
import { Action } from 'redux';
import { isRoutineEnabled } from '../helpers';

type RequestOutputActions =
  | RoutinesReceiveAction
  | RoutineStatusAction
  | RoutineSchedulesRequestAction
  | RoutineBulkSchedulesRequestAction;

export const requestRoutinesEpic: AppEpic<
  RoutinesRequestAction | Action<typeof RECEIVE_PROFILE_RULES>,
  RequestOutputActions
> = (actions$, _state$, { api }) => {
  /**
   * The first time we load the profile page we want to wait for the profile rules to finish fetching.
   *
   * With this Observable we listen and sync ROUTINES_RECEIVE and RECEIVE_PROFILE_RULES events
   * using combineLatest operator. So if RECEIVE_PROFILE_RULES have been recieved only then we can add routines payload to the state.
   *
   * We have to use shareReplay operator so the first and the subsequent api calls for fetching routines don't fail or get ignored.
   * With this operator we keep the RECEIVE_PROFILE_RULES event for the whole lifecycle of the app.
   */
  const profileRulesReponse = actions$.pipe(
    ofType('RECEIVE_PROFILE_RULES'),
    shareReplay(1)
  );

  const routineRequest = actions$.pipe<RoutinesRequestAction>(
    ofType('ROUTINES_REQUEST')
  );

  const loadingActions = routineRequest.pipe(
    map(() => setRoutinesStatus('read', 'loading'))
  );

  const routineResponse = routineRequest.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, includeDisabled, purgeCache } }) =>
          combineLatest([
            api.routines
              .withCache(purgeCache ? ttlZero : ttl10Seconds)
              .get<CollectionResponse<RoutinePayload>>({
                profileUid,
                includeDisabled,
              }),
            of({ profileUid, purgeCache }),
          ])
        ),
        map(([{ items_list: routinesPayload }, { profileUid, purgeCache }]) => {
          const routines = routinesPayload.map(routine =>
            RoutineOperations.fromPayload(routine)
          );
          return { routines, purgeCache, profileUid };
        })
      );
    })
  );

  const syncActions = combineLatest([routineResponse, profileRulesReponse]);

  const requestActions = syncActions.pipe(
    switchMap(([{ routines }]) => {
      return from([
        setRoutinesStatus('read', 'success'),
        receiveRoutines(normalizeRoutinesData(routines, 'uid')),
      ]);
    }),
    catchError((e: APIError) => {
      showErrorAlert(e);
      return of(setRoutinesStatus('read', 'error'));
    })
  );

  /**
   * We are loading all the schedules when routines are fetched and this is mainly being done when:
   * - Profile page is loaded
   * - user resumes their app in mobile
   *
   * Why load all the routines + schedules at once in the profile page?
   * - we need to load all the routines when the profile is loaded for the summary cards.
   * - We cannot fetch schedule without first routines being fetch and received.
   * - We cannot fetch schedules in RoutinesContainer's onLoad hook because it's executed on every route change (ie. modals)
   * - We cannot use useEffect + load function in RoutinesPage because with any update to the routines list will re-trigger fetching.
   */
  const requestAllScheduleAction = routineResponse.pipe(
    switchMap(action =>
      of(action).pipe(
        map(({ routines, purgeCache, profileUid }) => {
          const enabledRoutinesUids = routines
            .filter(routine => isRoutineEnabled(routine))
            .map(routine => routine.uid);

          return requestBulkRoutineSchedules(
            enabledRoutinesUids,
            profileUid,
            purgeCache
          );
        })
      )
    )
  );

  return merge(loadingActions, requestActions, requestAllScheduleAction);
};

type CreateOutputActions =
  | RoutineDetailsReceiveAction
  | RoutineStatusAction
  | SetTempRoutineUidAction
  | RoutineSchedulesRequestAction;

export const createRoutinesEpic: AppEpic<
  CreateRoutineAction,
  CreateOutputActions
> = (actions$, state$, { api }) => {
  const createActions = actions$.pipe(ofType('ROUTINES_CREATE'));

  const loadingActions = createActions.pipe(
    map(() => setRoutinesStatus('create', 'loading'))
  );

  const requestActions = createActions.pipe(
    switchMap(action => {
      return of(action).pipe(
        switchMap(({ payload: { profileUid, body } }) =>
          combineLatest([
            api.routines.post<RoutinePayload>(body, null, {
              profileUid,
            }),
            of({ profileUid }),
          ])
        ),
        mergeMap(([routinePayload, { profileUid }]) => {
          const tempRoutine = getTempRoutine(state$.value);
          const tempRoutineUid = tempRoutine.get('uid');

          if (routinePayload?.uid && tempRoutineUid) {
            trackSaveNewRoutine(
              routinePayload.uid,
              tempRoutineUid,
              tempRoutine.get('creationMode')
            );
          }

          const finishSequenceActions: CreateOutputActions[] = [
            setTempRoutineUid(null),
            setRoutinesStatus('create', 'success'),
            receiveRoutineDetails(
              RoutineOperations.fromPayload(routinePayload)
            ),
          ];

          if (routinePayload?.uid) {
            finishSequenceActions.push(
              requestRoutineSchedules(profileUid, routinePayload.uid)
            );
          }

          return from(finishSequenceActions);
        }),
        catchError((e: APIError) => {
          showErrorAlert(e);
          return of(setRoutinesStatus('create', 'error'));
        })
      );
    })
  );

  return merge(loadingActions, requestActions);
};

const routinesEpics = combineEpics(requestRoutinesEpic, createRoutinesEpic);

export default routinesEpics;
