import { ofType } from 'redux-observable';
import { map } from 'rxjs';
import { RECEIVE_ACCOUNT } from '../account';
import { setLocale } from './index';
import { AppActionWithPayload, AppEpic } from '../../epics/types';

type SetLocaleAction = ReturnType<typeof setLocale>;

export const setLocaleFromAccountEpic: AppEpic<
  AppActionWithPayload,
  SetLocaleAction
> = actions$ =>
  actions$.pipe(
    ofType(RECEIVE_ACCOUNT),
    map(action => setLocale(action.payload.locale))
  );
