import * as React from 'react';
import { connect } from 'react-redux';
import { getComponentToRender } from './variants';
import { FeatureFlagsExperimentsKey } from '../ducks/featureFlags';

const mapStateToProps = (
  state,
  {
    experimentName,
    componentId,
    ...props
  }: {
    experimentName: FeatureFlagsExperimentsKey;
    componentId: string;
    children: JSX.Element;
  }
) => {
  return {
    componentToRender: getComponentToRender(state)(experimentName, componentId),
    ...props,
  };
};

const Experiment = connect(mapStateToProps)(
  ({ children, componentToRender, ...props }) => {
    const ComponentToRender = componentToRender;
    if (ComponentToRender === undefined) {
      return children;
    }

    return <ComponentToRender {...props} />;
  }
);

export default Experiment;
