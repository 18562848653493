import { connect } from 'react-redux';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import RemoveDeviceModal from '../../components/Modal/RemoveDeviceModal';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { getQueryParam } from '../../selectors';
import { removeDeviceAction } from '../../actions/DeviceActions';
import { getDevice } from '../../selectors/device';
import { navigateToExternalAntiTamperingHelp } from '../../actions/Navigation';
import { getDeviceAdvanceProtectionStatus } from '../../businessLogic/devices';

export const getDeviceIdQueryParam = (state: State) =>
  getQueryParam(state, 'deviceId');

const mapStateToProps = (
  state: State,
  { params: { deviceId } }: RouterParamsProps<{ deviceId?: string }>
) => {
  const device = deviceId ? getDevice(state, Number(deviceId)) : undefined;
  const advanceProtectionStatus = getDeviceAdvanceProtectionStatus(device);
  return {
    deviceId,
    advanceProtectionStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(close()),
  onOpenHelpArticle: () => {
    dispatch(navigateToExternalAntiTamperingHelp());
  },
  onRemove: (deviceId: string) => {
    const id = Number(deviceId);
    if (!Number.isNaN(id)) {
      dispatch(removeDeviceAction(id));
    }
    dispatch(close());
  },
});

export type RemoveDeviceModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const RemoveDeviceModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveDeviceModal);

export default RemoveDeviceModalContainer;
