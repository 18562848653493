import * as Moment from 'moment';
import { isNil } from 'ramda';
import { AppDataArrangement } from '../constants';
import type { ProfilePayload } from '../records/profile';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { API_BIRTHDATE_FORMAT } from './dates';

export const toUIYear = (
  birthDate?: string | number | ProfileRecord['birthDate']
): string => {
  const ensuredBirthDate = String(birthDate);
  if (
    !ensuredBirthDate ||
    AppDataArrangement.noBirthDayServerResponse.includes(ensuredBirthDate)
  ) {
    return AppDataArrangement.noBirthDayUI;
  }

  if (AppDataArrangement.noBirthDayServerResponse.includes(ensuredBirthDate)) {
    return AppDataArrangement.noBirthDayUI;
  }

  if (Moment.isMoment(birthDate)) {
    return birthDate.format(API_BIRTHDATE_FORMAT).replace('-01-01', '');
  }

  return ensuredBirthDate.replace('-01-01', '');
};

export const toProfileRecordBirthDate = (
  birthDate?: ProfilePayload['birthDate']
): ProfileRecord['birthDate'] => {
  if (!birthDate) return Moment();
  if (birthDate === AppDataArrangement.noBirthDayUI) return null;
  return Moment(birthDate, API_BIRTHDATE_FORMAT);
};

export const toServerRequestBirthDate = (
  birthDate: ProfileRecord['birthDate']
): ProfilePayload['birthDate'] => {
  return isNil(birthDate) ? birthDate : birthDate.format(API_BIRTHDATE_FORMAT);
};
