import { AuthorizationApplication } from '../constants';
import * as qinit from '../qinit';
import { isAndroidPlatform, isiOSPlatform } from '.';

export const getAuthorizationApplicationThroughAuthCodeFlowAndPlatform =
  (): AuthorizationApplication => {
    if (isAndroidPlatform()) {
      return AuthorizationApplication.standaloneAndroid;
    }
    if (isiOSPlatform()) {
      return AuthorizationApplication.standaloneIos;
    }

    return AuthorizationApplication.authorizationCode;
  };

export const getRedirectUri = (
  authorizationApplication?: AuthorizationApplication
): string => {
  switch (authorizationApplication) {
    case AuthorizationApplication.web:
      return qinit.tenant.parents_app.platforms.web.redirect_uri;

    default:
      return qinit.tenant.parents_app.parents_app_link;
  }
};
