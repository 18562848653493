import * as React from 'react';
import { FlexLayout, Typography } from 'styleguide-react';
import { ScreenSize } from '../../RenderWhen/RenderWhen';

interface WellbeingUnsubscribedSectionProps {
  screenSize: ScreenSize;
  classPrefix: string;
  desktopTitle: React.ReactNode;
  mobileTitle: React.ReactNode;
  subtitle: string;
  description: React.ReactNode;
  tag?: React.ReactNode;
  image: React.ReactNode;
  button: React.ReactNode;
  paddingBottom?: '0' | '16' | '24';
  leftColVerticalAlign?: 'start' | 'center';
  leftColHorizontalAlign?: 'start' | 'center';
}

export const WellbeingUnsubscribedSection = ({
  screenSize,
  classPrefix,
  leftColVerticalAlign = 'center',
  leftColHorizontalAlign = 'center',
  paddingBottom = screenSize === ScreenSize.Desktop ? '24' : '16',
  desktopTitle,
  mobileTitle,
  subtitle,
  description,
  tag,
  image,
  button,
}: WellbeingUnsubscribedSectionProps) => {
  const isDesktop = () => screenSize === ScreenSize.Desktop;

  return (
    <FlexLayout
      className={classPrefix}
      mainaxis={isDesktop() ? 'row' : 'column'}
      paddingLeft={isDesktop() ? '32' : '24'}
      paddingRight={isDesktop() ? '32' : '24'}
      paddingTop={isDesktop() ? '24' : '16'}
      paddingBottom={paddingBottom}
      crossaxisAlignment={isDesktop() ? 'stretch' : 'center'}
      width="100%"
      gap={isDesktop() ? '32' : '16'}
    >
      <FlexLayout
        className={`${classPrefix}__col-left`}
        mainaxis="column"
        mainaxisAlignment={leftColVerticalAlign}
        crossaxisAlignment={leftColHorizontalAlign}
        width="100%"
        maxWidth="320"
      >
        {isDesktop() && tag}
        {image}
      </FlexLayout>

      <FlexLayout
        className={`${classPrefix}__col-right`}
        gap="16"
        mainaxis="column"
        width="100%"
        crossaxisAlignment={isDesktop() ? 'start' : 'center'}
        mainaxisAlignment="center"
        paddingTop={isDesktop() ? '56' : '16'}
        paddingBottom={isDesktop() ? '56' : '16'}
      >
        {isDesktop() && <Typography type="body1">{subtitle}</Typography>}
        {isDesktop() ? desktopTitle : mobileTitle}

        <FlexLayout
          mainaxis="column"
          width="100%"
          maxWidth="520"
          mainaxisAlignment={isDesktop() ? 'left' : 'center'}
          textAlignement={isDesktop() ? 'left' : 'center'}
        >
          {description}
        </FlexLayout>

        {!isDesktop() && tag}
        {button}
      </FlexLayout>
    </FlexLayout>
  );
};
