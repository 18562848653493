import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { getProfileOrDefault } from '../../selectors';
import PanicRulesTourModal from '../../components/Modal/PanicRulesTourModal';

const mapStateToProps = (state, { params: { profileId } }) => ({
  profile: getProfileOrDefault(state, profileId),
});

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickClose: () =>
    dispatch(replace(`profiles/${profileId}/rules/panicButtonRules`)),
});

const PanicRulesTourModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanicRulesTourModal);

export default PanicRulesTourModalContainer;
