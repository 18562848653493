import { commonMapInFn } from '../../api/mappings/common';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import { StudentPause, StudentPausePayload } from './types/studentPause.type';

export const studentPauseRecordOperations = getRecordOperations<
  StudentPause,
  StudentPausePayload
>({
  defaultFields: {
    enabled: false,
    active: false,
    endDatetime: '',
    pause247: false,
  },
  mapIn: payload => {
    const commonMappedResult = commonMapInFn(payload);
    return {
      ...commonMappedResult.pause,
      endDatetime: commonMappedResult.pause.endDatetime,
      pause247: commonMappedResult?.pause247?.enabled,
    };
  },
});
