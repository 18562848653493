import { Record } from 'immutable';
import { AppCategory } from '../../records/appCategories/types';

export const requestAppCategoriesAction = () => ({
  type: 'REQUEST_APP_CATEGORIES' as const,
});

export const receiveAppCategoriesAction = (
  appCategories: Array<Record<AppCategory>>
) => ({
  type: 'RECEIVE_APP_CATEGORIES' as const,
  payload: appCategories,
});
