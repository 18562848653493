import * as React from 'react';
import { t } from '../../lib/i18n';
import * as moneyBadgeSrc from '../../assets/base/images/money-badge.png';
import * as pcMagSrc from '../../assets/base/images/pcmag.png';
import UpgradeBadge from './UpgradeBadge';
import { ProductDuration } from '../../records/product';

const UpgradeInfo: React.FunctionComponent<{
  areUpsellProducts: boolean;
  planDuration: ProductDuration;
}> = ({ areUpsellProducts, planDuration }) =>
  areUpsellProducts ? (
    <React.Fragment>
      <div className="UpgradeInfo__contact-support">
        <strong>{t('Need a bigger plan?')}</strong>{' '}
        <a href="mailto:premiumsupport@qustodio.com">
          {t('Contact premiumsupport@qustodio.com')}
        </a>
      </div>
      <div className="UpgradeInfo__badge UpgradeInfo__badge--stretch">
        <UpgradeBadge
          iconSrc={pcMagSrc}
          title={t('Editor rating: Excellent')}
          text={t(
            // tslint:disable-next-line:max-line-length
            '“This impressive parental control system includes just about every feature you might want, and it’s stylish, too.”'
          )}
        />
        <UpgradeBadge
          iconSrc={moneyBadgeSrc}
          title={t('100% Money Back Guarantee!')}
          text={t(
            'We offer a 100% money back guarantee within 30 days of payment.'
          )}
        />
      </div>
    </React.Fragment>
  ) : (
    <div className="UpgradeInfo__badge">
      {planDuration !== ProductDuration.OneMonth ? (
        <img
          className="UpgradeInfo__badge-image"
          alt="upgrade-info"
          src={moneyBadgeSrc}
        />
      ) : (
        ''
      )}
      <div className="UpgradeInfo__badge-text">
        {planDuration === ProductDuration.OneMonth
          ? t(
              'We will bill you upon subscription and then automatically every 30 days.'
            )
          : t('30-day 100% money-back guarantee.')}
      </div>
    </div>
  );

export default UpgradeInfo;
