import * as React from 'react';
import { Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { isValidDomainException, extractDomain } from '../../helpers/string';
import InputTextField from '../Form/InputTextField';

export const formName = 'addDeviceException';
export const addDomainExceptionFieldId = 'AddExceptionButton';

const AddDomainExceptionForm: React.FunctionComponent<{
  handleSubmit: () => any;
}> = ({ handleSubmit }) => (
  <form className="Form" onSubmit={handleSubmit} id={formName}>
    <div className="Form__fieldset_row">
      <Field
        id={addDomainExceptionFieldId}
        name="domain"
        component={InputTextField}
        type="text"
        placeholder={t('i.e.: google.com')}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="off"
      />
    </div>

    {/* include an invisible submit-button so that the mobile-keyboard
      has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

const validate = (values: FormValues, { domains }) => {
  const errors: { domain?: string } = {};
  if (!values.domain) {
    errors.domain = t('Field required');
  } else if (domains.get(extractDomain(values.domain.toLowerCase()))) {
    errors.domain = t('Website is already in list');
  } else if (!isValidDomainException(values.domain)) {
    errors.domain = t('You must add a valid website name');
  }
  return errors;
};

type FormValues = Readonly<{
  domain: string;
  action: string;
}>;
export type FormRecord = Record<FormValues>;
const FormRecord = Record({
  domain: '',
  action: '',
});

const formRecord = (values?: FormValues | FormRecord) => {
  if (values === undefined) {
    return FormRecord();
  }
  return values instanceof Record ? values : FormRecord(values);
};

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: formRecord(),
  validate,
})(AddDomainExceptionForm);
