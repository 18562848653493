import { fromJS, NonEmptyMapRecord } from 'immutable';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import { t } from '../lib/i18n';
import {
  getAccount,
  getParentDeviceId,
  getParentDevice,
  getParentDeviceVersion,
  getPersistentStorageItem,
} from '../selectors';
import {
  getPushRegistrationId,
  getPushPlatform,
  hasPushPermission,
} from '../selectors/pushService';
import api from '../api';
import { setItem, removeItem } from './persistentStorage';
import { BuildPlatform } from '../constants';
import State, { BaseThunk, Dispatch } from '../store/state';
import { getBuildPlatform, getAppVersion } from '../helpers/globals';
import { isAndroidPlatform } from '../helpers';
import { logout } from '../sideEffects/logout';
import { APPThunk } from '../helpers/thunks';

export const NON_EXISTING_PARENT_DEVICE_ID = -1;

export const REQUEST_CREATE_PARENT_DEVICE = 'REQUEST_CREATE_PARENT_DEVICE';
export const REQUEST_UPDATE_PARENT_DEVICE = 'REQUEST_UPDATE_PARENT_DEVICE';
export const REQUEST_REMOVE_PARENT_DEVICE = 'REQUEST_REMOVE_PARENT_DEVICE';
export const REQUEST_PARENT_DEVICE = 'REQUEST_PARENT_DEVICE';
export const RECEIVE_PARENT_DEVICE = 'RECEIVE_PARENT_DEVICE';
export const RECEIVE_PARENT_DEVICE_VERSION = 'RECEIVE_PARENT_DEVICE_VERSION';
export const REMOVE_PARENT_DEVICE = 'REMOVE_PARENT_DEVICE';

type ParentDevice = NonEmptyMapRecord<{
  account: number;
  created: string;
  enabled: boolean;
  id: number;
  modified: string;
  name: string;
  platform: number;
  push_token: string | null;
  registration_id: string | null;
  timezone: string;
  version: string;
  visits: number;
}>;
type StateSlice = NonEmptyMapRecord<{
  item: ParentDevice | null;
  isFetching: boolean;
  version: string | null;
}>;
export default function parentDevice(
  state: StateSlice = fromJS({
    item: null,
    isFetching: false,
    version: null,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_CREATE_PARENT_DEVICE:
    case REQUEST_UPDATE_PARENT_DEVICE:
    case REQUEST_REMOVE_PARENT_DEVICE:
      return state.set('isFetching', true);
    case RECEIVE_PARENT_DEVICE:
      return state.merge({
        isFetching: false,
        item: action.payload,
      });
    case RECEIVE_PARENT_DEVICE_VERSION:
      return state.merge({
        version: action.payload,
      });
    case REMOVE_PARENT_DEVICE:
      return state.merge({
        isFetching: false,
        item: null,
      });
    default:
      return state;
  }
}

export const parentDeviceExists = (state: State) => {
  // android passes query parent_device_id=-1 if not exists
  const parentDeviceId = getParentDeviceId(state);
  return parentDeviceId && parentDeviceId !== NON_EXISTING_PARENT_DEVICE_ID;
};

export function requestCreateParentDevice() {
  return {
    type: REQUEST_CREATE_PARENT_DEVICE,
  };
}

export function requestParentDevice() {
  return {
    type: REQUEST_PARENT_DEVICE,
  };
}

export function receiveParentDevice(parentDevice: ParentDevice) {
  return {
    type: RECEIVE_PARENT_DEVICE,
    payload: parentDevice,
  };
}

export function receiveParentDeviceVersion(version) {
  return {
    type: RECEIVE_PARENT_DEVICE_VERSION,
    payload: version,
  };
}

export function fetchParentDeviceIfNeeded(): BaseThunk<Promise<any>> {
  return (dispatch, getState) => {
    const parentDevice = getParentDevice(getState());
    if (parentDevice) {
      return Promise.resolve(parentDevice);
    }
    return dispatch(fetchParentDevice());
  };
}

export const fetchParentDevice = (): BaseThunk<Promise<any>> => {
  return (dispatch, getState) => {
    const parentDeviceId = getParentDeviceId(getState());
    dispatch(requestParentDevice());
    return api.parentDevices.get({ parentDeviceId }).then(json => {
      const parentDevice = fromJS(json);
      dispatch(receiveParentDevice(parentDevice));
      return parentDevice;
    });
  };
};

export function updateParentDevicePushPermission(): APPThunk {
  return (_dispatch, getState) => {
    const parentDeviceId = getParentDeviceId(getState());
    const hasPermission = hasPushPermission(getState());
    return api.parentDevicesOptions.post(
      {
        'permission-push': hasPermission,
      },
      null,
      { parentDeviceId }
    );
  };
}

export function updateParentDeviceLockScreenStatus(
  lockScreenStatus: boolean
): APPThunk {
  return (_dispatch, getState) => {
    const parentDeviceId = getParentDeviceId(getState());
    return api.parentDevicesOptions.post(
      {
        'lockscreen-enabled': lockScreenStatus,
      },
      null,
      { parentDeviceId }
    );
  };
}

export function updateParentDeviceVersionIfNeeded() {
  return (dispatch, getState) => {
    if (getBuildPlatform() === BuildPlatform.browser) {
      return Promise.resolve();
    }

    const lastAppVersion = getPersistentStorageItem(
      getState(),
      'lastAppVersion'
    );
    const appVersion = getParentDeviceVersion(getState());
    if (
      !parentDeviceExists(getState()) ||
      lastAppVersion === appVersion ||
      appVersion === null
    ) {
      return Promise.resolve();
    }

    return dispatch(fetchParentDeviceIfNeeded()).then(parentDevice => {
      const body = decamelizeKeysDeep(
        parentDevice
          .merge({
            version: appVersion,
          })
          .toJS()
      );
      return api.parentDevices
        .put({ parentDeviceId: parentDevice.get('id') }, body)
        .then(json => {
          dispatch(setItem('lastAppVersion', appVersion));
          dispatch(receiveParentDevice(json));
        });
    });
  };
}

export function updateParentDeviceRegistrationId() {
  return (dispatch, getState) => {
    return dispatch(fetchParentDeviceIfNeeded())
      .then(parentDevice => {
        const registrationId = getPushRegistrationId(getState());
        const pushPlatform = getPushPlatform(getState());
        if (!registrationId) {
          throw new Error('Parent device push registration id not available');
        }
        if (!pushPlatform) {
          throw new Error('Parent device push platform not available');
        }
        const body = decamelizeKeysDeep(
          parentDevice
            .merge({
              push_platform: getPushPlatform(getState()),
              push_token: getPushRegistrationId(getState()),
            })
            .toJS()
        );
        return api.parentDevices.put(
          { parentDeviceId: parentDevice.get('id') },
          body
        );
      })
      .then(json => dispatch(receiveParentDevice(json)));
  };
}

const storedParentDeviceDisabled = async (dispatch: Dispatch) => {
  try {
    await dispatch(fetchParentDevice());
    return false;
  } catch (apiError) {
    if (apiError.status === 404) {
      return true;
    }
    throw apiError;
  }
};

export function createParentDeviceIfNeeded() {
  return async (dispatch, getState) => {
    const platform = getBuildPlatform();
    if (platform === BuildPlatform.browser) {
      return Promise.resolve();
    }

    // If parent device which exists in the state is disabled, we force a logout and reset the app data
    // otherwise means the parent device stored is active, so we don't create a new one
    if (parentDeviceExists(getState())) {
      return (await storedParentDeviceDisabled(dispatch))
        ? dispatch(logout())
        : Promise.resolve();
    }

    return dispatch(
      createParentDevice({
        name: t("Parent's device {{name}}", {
          name: getAccount(getState()).name,
        }),
        platform: getDevicePlatformId(platform),
        version: getAppVersion(),
        timezone: getAccount(getState()).timezone,
        account: getAccount(getState()).id,
        // push notifications format is different in standalone Android because
        // it uses most recent version of the plugin.
        // BCK checks is_standalone and platform to apply it or not
        push_platform: getPushPlatform(getState()),
        push_token: getPushRegistrationId(getState()),
        is_standalone: isAndroidPlatform(),
      })
    );
  };
}

export function removeParentDevice(): BaseThunk<Promise<any>> {
  return (dispatch, getState) =>
    Promise.resolve()
      .then(() => dispatch(requestRemoveParentDevice()))
      .then(() => {
        const parentDeviceId = getParentDeviceId(getState());
        return api.parentDevices.delete({ parentDeviceId });
      })
      .then(() => {
        dispatch(removeItem('parentDeviceId'));
        dispatch(removeItem('lastRegistrationId'));
        dispatch(removeItem('lastPushPermission'));
        dispatch(removedParentDevice());
      });
}

export function requestRemoveParentDevice() {
  return {
    type: REQUEST_REMOVE_PARENT_DEVICE,
  };
}

export function removedParentDevice() {
  return {
    type: REMOVE_PARENT_DEVICE,
  };
}

export const createParentDevice = body => {
  return (dispatch, getState) =>
    Promise.resolve()
      .then(() => dispatch(requestCreateParentDevice()))
      .then(() => api.parentDevices.post(body))
      .then(json => {
        dispatch(receiveParentDevice(json));
        dispatch(setItem('parentDeviceId', json.id.toString()));
        const pushRegistrationId = body.push_token;
        const hasPermission = hasPushPermission(getState());
        if (pushRegistrationId) {
          dispatch(setItem('lastRegistrationId', pushRegistrationId));
          dispatch(setItem('lastPushPermission', hasPermission.toString()));
          dispatch(setItem('lastAppVersion', body.version));
          return dispatch(updateParentDevicePushPermission());
        }
        return Promise.resolve();
      })
      .then(() => dispatch(setApiParentDeviceId()));
};

const getDevicePlatformId = globalPlatform => {
  switch (globalPlatform) {
    case BuildPlatform.ios:
      return 4;
    case BuildPlatform.android:
    default:
      return 2; // default to Android - app should only be present on ios or Android devices anyway
  }
};

// eslint-disable-next-line no-underscore-dangle
export const setApiParentDeviceId = () => (_dispatch, getState) =>
  api.setParentDeviceId(getPersistentStorageItem(getState(), 'parentDeviceId'));
