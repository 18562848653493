import * as React from 'react';
import { Card, FlexLayout, GlobalType } from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';
import { classNames } from '../../../../helpers';

interface UnifiedNowCardWrapperProps {
  type?: GlobalType;
  gradient?: boolean;
  shapes?: boolean;
  className?: string;
  testId?: string;
  children: React.ReactNode;
}

const UnifiedNowCardWrapper = ({
  type,
  gradient = false,
  shapes = false,
  className,
  testId,
  children,
}: UnifiedNowCardWrapperProps) => {
  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screen => (
        <Card
          type={type}
          backgroundGradient={gradient}
          backgroundShapes={shapes}
          className={classNames('par-unified-now-card', className)}
          testId={testId}
        >
          <FlexLayout
            mainaxis={screen === ScreenSize.Mobile ? 'column' : 'row'}
            crossaxisAlignment={
              screen !== ScreenSize.Mobile ? 'center' : undefined
            }
            className="par-unified-now-card__wrapper"
          >
            {children}
          </FlexLayout>
        </Card>
      )}
    </RenderWhen>
  );
};

export default UnifiedNowCardWrapper;
