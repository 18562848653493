import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  Typography,
} from 'styleguide-react';

const HowToAssignProfileToAccountModal: React.FunctionComponent<{
  onClickClose: () => void;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Assign individual computer user accounts to a child')}
    header={{ icon: <Icon type={IconType.userFriends} /> }}
    size="small"
    buttons={[
      <Button
        onClick={onClickClose}
        size={ButtonSize.medium}
        block
        key="HowToAssignProfileModal__button"
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    <Typography type="body1">
      {t(
        'You can assign an individual computer account to a child, or leave it unprotected.'
      )}
    </Typography>
  </Modal>
);

export default HowToAssignProfileToAccountModal;
