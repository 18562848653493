import * as React from 'react';
import { t } from '../../../lib/i18n';
import { YoutubeSummaryRecord } from '../../../records/youtubeSummary';
import { SummaryDateRanges, CardDimensions } from '../../../constants';
import { getNoActivityMsg, getNoSearchesMsg } from './uiHelpers';
import {
  Card,
  Icon,
  IconType,
  IconFamily,
  EmptyPlaceholder,
  FlexLayout,
  ContentSeparator,
  CloudList,
  Quote,
  Svg,
} from 'styleguide-react';
import YouTubeIcon from '../../Icons/summary/YouTubeIcon';
import type { YoutubeSummaryCardProps } from '../../../containers/Summary/YoutubeSummaryCardContainer';
import { WithUpgradeTag } from '../../UpgradeTag/UpgradeTag';

const YoutubeSummaryCard = ({
  summary,
  isEmpty,
  width,
  dateRange,
  isCombinedAndUndelegatedProfile,
  profileName,
  isYoutubeFeatureBlocked,
  onExpand,
  goToInfoModal,
}: YoutubeSummaryCardProps) => {
  return (
    <React.Fragment>
      <Card
        height={CardDimensions.height}
        width={width}
        header={{
          icon: <Icon type={IconType.youtube} family={IconFamily.brands} />,
          title: t('YouTube Summary'),
          actionElement: <Icon type={IconType.infoCircle} />,
          onClickAction: goToInfoModal,
        }}
        footer={{
          actionElement: getFooterButtonText(
            isEmpty,
            onExpand,
            isYoutubeFeatureBlocked
          ),
          onClickAction: () => onExpand(isYoutubeFeatureBlocked),
        }}
      >
        {getCardContent(
          summary,
          isEmpty,
          dateRange,
          isCombinedAndUndelegatedProfile,
          profileName,
          isYoutubeFeatureBlocked
        )}
      </Card>
    </React.Fragment>
  );
};

const getFooterButtonText = (
  isEmpty: boolean,
  onExpand: ((flag: boolean) => void) | undefined,
  isYoutubeFeatureBlocked: boolean
) => {
  if (isEmpty) return undefined;
  if (isYoutubeFeatureBlocked) return t('Upgrade to see more');
  return onExpand ? t('See watched videos') : undefined;
};

const getCardContent = (
  summary: YoutubeSummaryRecord,
  isEmpty: boolean,
  dateRange: SummaryDateRanges,
  isCombinedAndUndelegatedProfile: boolean,
  profileName: string | undefined,
  isYoutubeFeatureBlocked: boolean
) =>
  isEmpty ? (
    <EmptyPlaceholder
      icon={
        <Svg height="64" width="64" color="gray-light">
          <YouTubeIcon />
        </Svg>
      }
      text={getNoActivityMsg(
        dateRange,
        isCombinedAndUndelegatedProfile,
        profileName ?? ''
      )}
      maxWidth="75%"
      fillAvailableSpace
      smallText
      centered
    />
  ) : (
    <FlexLayout mainaxis="column" marginTop="8">
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        marginBottom="24"
      >
        <span>{t('Videos')}</span>
        <WithUpgradeTag
          when={isYoutubeFeatureBlocked}
          text={() => t('Upgrade for details')}
          align="start"
          gap="16"
        >
          <span data-testid="total-videos">{summary.totalVideos}</span>
        </WithUpgradeTag>
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        marginBottom="24"
        marginTop="8"
      >
        <span>{t('Searches')}</span>
        <WithUpgradeTag
          when={isYoutubeFeatureBlocked}
          text={() => t('Upgrade for details')}
          align="start"
          gap="16"
        >
          <span data-testid="total-searches">{summary.totalSearches}</span>
        </WithUpgradeTag>
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout mainaxis="column" marginTop="8">
        {getYoutubeSearchCloudList(summary, dateRange, isYoutubeFeatureBlocked)}
      </FlexLayout>
    </FlexLayout>
  );

const getYoutubeSearchCloudList = (
  summary: YoutubeSummaryRecord,
  dateRange: SummaryDateRanges,
  isYoutubeFeatureBlocked: boolean
) => {
  const noSearchTermsFound =
    !summary.lastSearches || summary.lastSearches.size === 0;

  if (noSearchTermsFound) {
    return (
      <EmptyPlaceholder
        fillAvailableSpace
        centered
        text={getNoSearchesMsg(dateRange)}
        testId="no-searches-youtube-summary-card"
        smallText
      />
    );
  }

  if (isYoutubeFeatureBlocked) return null;

  return (
    <CloudList allowedListItemType={Quote} listItemMaxWidth="45%">
      {summary.lastSearches
        .sort((search1, search2) => search2.length - search1.length)
        .map(search => (
          <Quote key={search}>{search}</Quote>
        ))}
    </CloudList>
  );
};

export default YoutubeSummaryCard;
