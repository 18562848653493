import * as React from 'react';
import { t } from '../../lib/i18n';
import { Modal, ModalStyledText } from 'styleguide-react';
import { modalDimensions } from '../../constants';

const CallsSMSBlockedSectionModal: React.FunctionComponent<{
  onClickClose: () => void;
}> = ({ onClickClose }) => (
  <Modal
    title={t('Call Exceptions')}
    onClickClose={onClickClose}
    width={modalDimensions.regular.width}
    height={modalDimensions.regular.height}
  >
    <ModalStyledText>
      {t(
        'You must first enable Calls & SMS monitoring to set call blocking rules for a specific contact.'
      )}
    </ModalStyledText>
  </Modal>
);

export default CallsSMSBlockedSectionModal;
