import * as React from 'react';
import { connect } from 'react-redux';
import { Icon, IconType, Svg } from 'styleguide-react';
import { t } from '../../lib/i18n';
import State, { Dispatch, BaseThunk } from '../../store/state';
import {
  getCallsAndSmsActivity,
  getSearchActivity,
  isFetchingMoreSocialActivity,
  getActiveDateRange,
  getSummaryAppsAndWebActivity,
  getSummaryBlockedWebActivity,
  getLicense,
  getSummaryAppAndWebError,
  getSummaryAppAndWebIsLoading,
  getSummaryBlockedWebIsLoading,
  getSummaryBlockedWebError,
  isFetchingCallAndSms,
  isFetchingSearches,
  getCallAndSmsError,
  getSearchesError,
} from '../../selectors';
import {
  navigateToTopActivity,
  navigateToBlockedWebActivity,
  navigateToCallsAndSmsActivity,
  navigateToSearchActivity,
  openSummaryCardInfoModalFor,
} from '../../actions/ProfileActions';
import { activitySwiperIndexChanged } from '../../ducks/ui';
import { DisplayUnit } from '../../components/ActivitySummary/ActivityList';
import ActivitySummaryListWithObserver from '../../components/ActivitySummary/ActivitySummaryListWithObserver';

import { ActivityType } from '../../components/ActivitySummary/sharedModels';

import { fetchMoreSearches } from '../../ducks/socialActivity';
import { track, Events } from '../../helpers/analytics';
import AppWebActivityIcon from '../../components/Icons/summary/AppWebActivityIcon';
import BlockedActivityIcon from '../../components/Icons/summary/BlockedActivityIcon';
import CallsSMSIcon from '../../components/Icons/summary/CallsSMSIcon';
import SearchesIcon from '../../components/Icons/summary/SearchesIcon';
import {
  loadAppsAndWebActivityCard,
  loadBlockedWebActivityCard,
  loadSearchActivityCard,
  loadCallAndSmsActivityCard,
} from '../../ducks/summary';
import {
  ProfileId,
  ProfileRecord,
} from '../../records/profile/types/Profile.types';
import { isPremiumOrTrial } from '../../records/license';

interface Props {
  profile: ProfileRecord;
  type: ActivityType;
  hasExpandLink?: boolean;
  showAllAvailableActivity?: boolean;
  displayAll?: boolean;
}

const mapStateToProps = (
  state: State,
  { profile, type, showAllAvailableActivity = false }: Props
) => {
  const title = {
    [ActivityType.AppsAndWebActivity]: t('Apps and Web Activity'),
    [ActivityType.BlockedWebs]: t('Blocked web activity'),
    [ActivityType.CallsAndSms]: t('Calls & messages'),
    [ActivityType.Search]: t('Search activity'),
    [ActivityType.QuestionableWebSearches]: t('Questionable Web Searches'),
  }[type];

  const iconEmptyCard = {
    [ActivityType.AppsAndWebActivity]: (
      <Svg height="64" width="64" color="gray-light">
        <AppWebActivityIcon />
      </Svg>
    ),
    [ActivityType.BlockedWebs]: (
      <Svg height="64" width="64" color="gray-light">
        <BlockedActivityIcon />
      </Svg>
    ),
    [ActivityType.CallsAndSms]: (
      <Svg height="64" width="64" color="gray-light">
        <CallsSMSIcon />
      </Svg>
    ),
    [ActivityType.Search]: (
      <Svg height="64" width="64" color="gray-light">
        <SearchesIcon />
      </Svg>
    ),
    [ActivityType.QuestionableWebSearches]: (
      <Svg height="64" width="64" color="gray-light">
        <SearchesIcon />
      </Svg>
    ),
  }[type];

  const icon = {
    [ActivityType.AppsAndWebActivity]: <Icon type={IconType.chartBar} />,
    [ActivityType.BlockedWebs]: <Icon type={IconType.globe} />,
    [ActivityType.CallsAndSms]: <Icon type={IconType.phoneAlt} />,
    [ActivityType.Search]: <Icon type={IconType.search} />,
    [ActivityType.QuestionableWebSearches]: (
      <Icon type={IconType.searchMinus} />
    ),
  }[type];

  const displayUnit = {
    [ActivityType.AppsAndWebActivity]: DisplayUnit.Minutes,
    [ActivityType.BlockedWebs]: DisplayUnit.Minutes,
    [ActivityType.CallsAndSms]: DisplayUnit.Minutes,
    [ActivityType.Search]: DisplayUnit.Minutes,
    [ActivityType.QuestionableWebSearches]: DisplayUnit.Minutes,
  }[type];

  const activityList = {
    [ActivityType.AppsAndWebActivity]: () =>
      getSummaryAppsAndWebActivity(state),
    [ActivityType.BlockedWebs]: () => getSummaryBlockedWebActivity(state),
    [ActivityType.CallsAndSms]: () => getCallsAndSmsActivity(state),
    [ActivityType.Search]: () => getSearchActivity(state),
    [ActivityType.QuestionableWebSearches]: () => getSearchActivity(state),
  }[type]();

  const needsPagination =
    type === ActivityType.Search ||
    type === ActivityType.QuestionableWebSearches;

  const loadingText = t('{{activityTitle}} loading...', {
    activityTitle: title,
  });

  const errorText = t("{{activityTitle}} couldn't load", {
    activityTitle: title,
  });

  return {
    profileUid: profile.uid,
    type,
    title,
    iconEmptyCard,
    icon,
    displayUnit,
    showAllAvailableActivity,
    needsPagination,
    activeDateRange: getActiveDateRange(state),
    isFetching: isFetchingMoreSocialActivity(state),
    noActivityText: getTextIfNoActivityAtCard(type, profile.name),
    items: showAllAvailableActivity
      ? activityList
      : filterItemsByType(activityList, type),
    isFetchingInfo: getIsLoading(state, type),
    hasAnyError: getSummaryError(state, type),
    loadingText,
    errorText,
  };
};

const filterItemsByType = (items, type: ActivityType) => {
  const numItemsByType =
    {
      [ActivityType.QuestionableWebSearches]: 6,
    }[type] || 5;

  return items.take(numItemsByType);
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profile, type, hasExpandLink = true }: Props
) => {
  return {
    onExpand: hasExpandLink
      ? () => dispatch(handleOnExpand({ profileId: profile.id, type }))
      : undefined,
    handleMore: (limit: string) =>
      dispatch(fetchMoreSearches(profile.uid.toString(), limit)),
    goToInfoModal: () => {
      dispatch(handleGoToInfoModal(type));
    },
    loadWidget: () => {
      dispatch(loadWidgetOfType(type, profile.id.toString()));
    },
  };
};

export const handleGoToInfoModal =
  (type): BaseThunk<void> =>
  dispatch => {
    const trackingHandlerFor = {
      [ActivityType.AppsAndWebActivity]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Apps and Web Activity',
        });
      },
      [ActivityType.Top]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Top Activity',
        });
      },
      [ActivityType.Questionable]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Questionable Activity',
        });
      },
      [ActivityType.CallsAndSms]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Calls & SMS Activity',
        });
      },
      [ActivityType.Search]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Search Activity',
        });
      },
      [ActivityType.QuestionableWebSearches]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Questionable Web Searches Activity',
        });
      },
      [ActivityType.BlockedWebs]: () => {
        track(Events.ClickedInfoButtoninSummaryCard, {
          card: 'Blocked Webs Activity',
        });
      },
    };
    trackingHandlerFor[type]();
    dispatch(openSummaryCardInfoModalFor(type));
  };

export const handleOnExpand =
  ({ profileId, type }): BaseThunk<void> =>
  dispatch => {
    const expandHandlersForActivityTypes = {
      [ActivityType.AppsAndWebActivity]: () => {
        dispatch(activitySwiperIndexChanged(1));
        dispatch(navigateToTopActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Apps and web activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.BlockedWebs]: () => {
        dispatch(activitySwiperIndexChanged(2));
        dispatch(navigateToBlockedWebActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Blocked web activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.Search]: () => {
        dispatch(activitySwiperIndexChanged(4));
        dispatch(navigateToSearchActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Search Activity',
          category: 'Activity cards',
        });
      },
      [ActivityType.QuestionableWebSearches]: () => {
        dispatch(activitySwiperIndexChanged(4));
        dispatch(navigateToSearchActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Questionable Web Searches',
          category: 'Activity cards',
        });
      },
      [ActivityType.CallsAndSms]: () => {
        dispatch(activitySwiperIndexChanged(5));
        dispatch(navigateToCallsAndSmsActivity(profileId));
        track(Events.ClickedSeeAllButton, {
          page: 'Calls & SMS',
          category: 'Activity cards',
        });
      },
    };

    expandHandlersForActivityTypes[type]();
  };

const getTextIfNoActivityAtCard = (
  type: ActivityType,
  name: string
): string => {
  switch (type) {
    case ActivityType.CallsAndSms:
      return t(
        'No calls & SMS activity for {{name}} during the specified period',
        { name }
      );
    case ActivityType.Search:
      return t(
        'No Search activity for {{name}} reported on Youtube, Google or Bing',
        {
          name,
        }
      );
    case ActivityType.QuestionableWebSearches:
      return t(
        'No Search activity for {{name}} reported on Youtube, Google or Bing',
        {
          name,
        }
      );
    default:
      return t('No activity for {{name}} during the specified period', {
        name,
      });
  }
};

const loadWidgetOfType =
  (type: ActivityType, profileId: ProfileId): BaseThunk<void> =>
  (dispatch, getState) => {
    switch (type) {
      case ActivityType.BlockedWebs:
        dispatch(loadBlockedWebActivityCard({ profileId }));
        break;
      case ActivityType.AppsAndWebActivity:
        dispatch(loadAppsAndWebActivityCard({ profileId }));
        break;
      case ActivityType.Search:
        dispatch(loadSearchActivityCard({ profileId }));
        break;
      case ActivityType.CallsAndSms:
        if (isPremiumOrTrial(getLicense(getState()).type)) {
          dispatch(loadCallAndSmsActivityCard({ profileId }));
        }
        break;
      default:
        return null;
    }
    return null;
  };

const getIsLoading = (state: State, type: ActivityType) => {
  switch (type) {
    case ActivityType.AppsAndWebActivity:
      return getSummaryAppAndWebIsLoading(state);
    case ActivityType.BlockedWebs:
      return getSummaryBlockedWebIsLoading(state);
    case ActivityType.CallsAndSms:
      return isFetchingCallAndSms(state);
    case ActivityType.Search:
      return isFetchingSearches(state);
    default:
      return false;
  }
};

const getSummaryError = (state: State, type: ActivityType) => {
  switch (type) {
    case ActivityType.AppsAndWebActivity:
      return getSummaryAppAndWebError(state);
    case ActivityType.BlockedWebs:
      return getSummaryBlockedWebError(state);
    case ActivityType.CallsAndSms:
      return getCallAndSmsError(state);
    case ActivityType.Search:
      return getSearchesError(state);
    default:
      return undefined;
  }
};

const ActivitySummaryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitySummaryListWithObserver);

export default ActivitySummaryListContainer;
