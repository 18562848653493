import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { DeleteTimeSlotModalProps } from '../../../containers/Routines/Modal/DeleteTimeSlotModalContainer';

const DeleteTimeSlotModal = ({
  profileUid,
  routineUid,
  timeSlotUid,
  routineName,
  from,
  to,
  origin,
  onClickDelete,
  onClickClose,
}: DeleteTimeSlotModalProps) => {
  const MODAL_TEST_ID = 'delete-time-slot-modal';

  if (!profileUid || !routineUid || !timeSlotUid) return null;

  const confirmDeleteTimeSlot = () => {
    if (profileUid && routineUid && timeSlotUid && origin) {
      onClickDelete(profileUid, routineUid, timeSlotUid, origin);
    }
  };

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-delete-timeslot-modal"
      header={{
        icon: (
          <Icon
            type={IconType.trashAlt}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.error}
          />
        ),
      }}
      title={t('Delete time slot?')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-delete`}
          key="button1"
          onClick={confirmDeleteTimeSlot}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Delete')}
        </Button>,
        <Button
          testId={`${MODAL_TEST_ID}-cancel`}
          key="button2"
          onClick={onClickClose}
          size={ButtonSize.medium}
          disabled={false}
          buttonType={ButtonType.secondary}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t('{{routineName}} will no longer be scheduled from{{time}}.', {
            routineName,
            time: `${from} - ${to}`,
          })}
        </ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default DeleteTimeSlotModal;
