import * as React from 'react';
import { Button, ButtonSize, ModalInfo, Typography } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import getInTouchImg from '../../../assets/base/images/wellbeing/get-in-touch.png';
import { WellbeingSupportEmailAddress } from '../../../businessLogic/wellbeing/types';

interface ContactUsModalProps {
  recipientAddress: WellbeingSupportEmailAddress;
  emailSubject: string;
  onClickClose: () => void;
  onContactUsClick: (
    recipientAddress: WellbeingSupportEmailAddress,
    emailSubject: string
  ) => void;
}

const ContactUsModal = ({
  recipientAddress,
  emailSubject,
  onClickClose,
  onContactUsClick,
}: ContactUsModalProps) => {
  return (
    <ModalInfo
      size="medium"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button"
          onClick={() => onContactUsClick(recipientAddress, emailSubject)}
          size={ButtonSize.small}
          block
          testId="Wellbeing-get-in-touch-modal__confirm-button"
        >
          {t('Contact us')}
        </Button>,
      ]}
      testId="Wellbeing-get-in-touch"
      title={t('Get in touch')}
      header={{
        illustration: (
          <img src={getInTouchImg} width="130px" alt="Enabled Delegation" />
        ),
      }}
    >
      <Typography type="body2" align="center" renderAs="p">
        {t(
          'Contact us if you have any questions or want to amend your subscription.'
        )}
      </Typography>
    </ModalInfo>
  );
};

export default ContactUsModal;
