import { getTypeByFilter } from '../../businessLogic/summaryAlerts';
import State from '../../store/state';
import {
  ProfileUuid,
  SummaryAlertsState,
  SummaryAlertsTypes,
} from './types/SummaryAlertsState.type';

export const getSummaryAlerts = (state: State) => state.get('summaryAlerts');

const viewedPath = (profileUuid: ProfileUuid, type: SummaryAlertsTypes) => [
  profileUuid,
  getTypeByFilter(type),
  'viewed',
];

const counterPath = (profileUuid: ProfileUuid, type: SummaryAlertsTypes) => [
  profileUuid,
  getTypeByFilter(type),
  'count',
];

export const setViewed = (
  state: SummaryAlertsState,
  profileUuid: ProfileUuid,
  type: SummaryAlertsTypes,
  viewed: boolean
) => state.setIn(viewedPath(profileUuid, type), viewed);

export const getSummaryAlertViewed = (
  state: State,
  profileUuid: ProfileUuid,
  type: SummaryAlertsTypes
) => {
  const viewed = getSummaryAlerts(state).getIn(viewedPath(profileUuid, type));
  return viewed === undefined ? true : viewed;
};

export const setCounter = (
  state: SummaryAlertsState,
  profileUuid: ProfileUuid,
  type: SummaryAlertsTypes,
  counter: number
) => state.setIn(counterPath(profileUuid, type), counter);

export const getSummaryAlertCounter = (
  state: State,
  profileUuid: ProfileUuid,
  type: SummaryAlertsTypes
): number => {
  const count = getSummaryAlerts(state).getIn(counterPath(profileUuid, type));
  return count === undefined ? 0 : count;
};

export const reset = (
  state: SummaryAlertsState,
  profileUuid: ProfileUuid,
  type: SummaryAlertsTypes
) => {
  const stateWithCounterUpdated = setCounter(state, profileUuid, type, 0);
  return setViewed(stateWithCounterUpdated, profileUuid, type, true);
};
