import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import { t } from '../../lib/i18n';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import { getDeviceNames } from './getDeviceNames';
import PageLayout from '../Layout/PageLayout';
import { CallsSMSRulesProps } from '../../types/rules/profile/callsAndMessages/CallsSMSRules.types';
import CallsSMSActivateSwitch from './switches/CallsSMSActivateSwitch';
import CallsSMSMessagesSection from './sections/CallsSMSMessagesSection';
import CallsSMSTabs from './sections/CallsSMSTabs';

const CallSMSRules: React.FC<CallsSMSRulesProps> = (
  props: CallsSMSRulesProps
) => {
  const { compatibleIphoneDevices, compatibleAndroidDevices } = props;

  const hasCompatibleIphoneDevices = compatibleIphoneDevices.count() !== 0;
  const hasCompatibleAndroidDevices = compatibleAndroidDevices.count() !== 0;

  const deviceNames = getDeviceNames({
    compatibleAndroidDevices,
    compatibleIphoneDevices,
  });

  const shouldHideIosRules =
    hasCompatibleIphoneDevices && !hasCompatibleAndroidDevices;

  return (
    <PageLayout
      title={t('Calls & messages')}
      configuration={[
        { screenSize: ScreenSize.Desktop, mainInsideCard: true },
        { screenSize: ScreenSize.Tablet, mainInsideCard: true, columns: 1 },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
      className="calls-and-messages"
    >
      <FlexLayout mainaxis="column" padding="16" className="calls-and-messages">
        {CallsSMSActivateSwitch({ deviceNames, ...props })}
        {shouldHideIosRules === true
          ? CallsSMSMessagesSection(props)
          : CallsSMSTabs({ ...props, hasCompatibleIphoneDevices })}
      </FlexLayout>
    </PageLayout>
  );
};

export default CallSMSRules;
