import { InAppBrowserConfig } from '../constants';

export const serializeInAppBrowserConfig = (
  config: InAppBrowserConfig
): string =>
  Object.keys(config).reduce(
    (accum, value) =>
      `${value}=${config[value]}${accum !== '' ? ',' : ''}${accum}`,
    ''
  );

export const awaitInAppBrowserEvent = (
  ref: EventTarget,
  eventName: 'exit' | 'loadstart' | 'loadstop' | 'beforeload' | 'message'
): Promise<Event> => {
  return new Promise((resolve, reject) => {
    ref.addEventListener(eventName, (event: Event) => resolve(event));
    ref.addEventListener('loaderror', (event: Event) => reject(event));
  });
};
