import * as React from 'react';
import * as Inmutable from 'immutable';
import { t } from '../../lib/i18n';
import {
  ActivityRecord,
  ActivityTypes,
  AppActivityRecord,
  WebDomainActivityRecord,
  CallsAndSmsActivityRecord,
  SearchActivityRecord,
} from '../../records/activity';
import {
  ActivityIconSizes,
  WebSummaryCardActivityIcon,
  AppSummaryCardActivityIcon,
} from '../Activity/ActivityIcons';
import {
  PLATFORM_UNKNOWN,
  ACTION_ALERT,
  REASON_DOMAIN_LIST_ALERT,
} from '../../constants';
import { getCategoryLabel } from '../../records/category';
import { ActivityType } from './sharedModels';
import { capitalize } from '../../helpers/string';
import {
  List,
  CompactStyleListItem,
  Quote,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  IconSize,
  IconFamily,
  Layout,
  ContentSeparator,
} from 'styleguide-react';
import { minutesToHMFormat } from '../../helpers/dates';
import { mapPlatformToPlatformName } from '../../records/device';
import { classNames } from '../../helpers';
import { WithUpgradeTag } from '../UpgradeTag/UpgradeTag';

export enum DisplayUnit {
  None = 'none',
  Minutes = 'minutes',
  Percentage = 'percentage',
  Times = 'times',
}

const ActivityList: React.FunctionComponent<{
  profileUid: string;
  type: ActivityType;
  items: Inmutable.List<ActivityRecord>;
  showAllAvailableActivity?: boolean;
  displayUnit?: DisplayUnit;
  isFeaturePremium?: boolean;
}> = ({
  profileUid,
  type,
  items,
  showAllAvailableActivity = false,
  isFeaturePremium = false,
}) => {
  switch (type) {
    case ActivityType.QuestionableWebSearches:
      return (
        <QuestionableSearchesSummaryList
          profileUid={profileUid}
          items={items as Inmutable.List<SearchActivityRecord>}
        />
      );
    case ActivityType.AppsAndWebActivity:
      return <AppsAndWebActivityList profileUid={profileUid} items={items} />;
    case ActivityType.BlockedWebs:
      return <BlockedWebActivityList profileUid={profileUid} items={items} />;
    case ActivityType.Search:
      return (
        <SearchActivityList
          profileUid={profileUid}
          items={items as Inmutable.List<SearchActivityRecord>}
        />
      );
    case ActivityType.CallsAndSms:
      return (
        <CallsAndSMSList
          profileUid={profileUid}
          items={items as Inmutable.List<CallsAndSmsActivityRecord>}
          showAllAvailableActivity={showAllAvailableActivity}
          isFeaturePremium={isFeaturePremium}
        />
      );
    default:
      return null;
  }
};

const SearchActivityList: React.FunctionComponent<{
  profileUid: string;
  items: Inmutable.List<SearchActivityRecord>;
}> = ({ profileUid, items }) => (
  <List testId="SearchActivity">
    {items.map((item, ndx) => (
      <CompactStyleListItem
        testId={item.term}
        key={`search_${profileUid}_${item.id || ndx}`}
        bottomPadding="extra"
        title={
          <span>
            <FlexLayout mainaxis="row" display="inline-flex">
              <Quote>{capitalize(item.term)}</Quote>
            </FlexLayout>
          </span>
        }
        rightSubtitle={
          item.count === 1
            ? t('1 time')
            : t('{{count}} times', { count: item.count })
        }
      />
    ))}
  </List>
);

const totalCallsAndMessages = (
  items: Inmutable.List<CallsAndSmsActivityRecord>
) =>
  items.reduce(
    (acc, item) => {
      acc.calls += item.calls ?? 0;
      acc.sms += item.messages ?? 0;
      return acc;
    },
    { calls: 0, sms: 0 }
  );

const CallsAndSMSList: React.FunctionComponent<{
  profileUid: string;
  items: Inmutable.List<CallsAndSmsActivityRecord>;
  showAllAvailableActivity: boolean;
  isFeaturePremium: boolean;
}> = ({ profileUid, items, isFeaturePremium }) => {
  if (isFeaturePremium) {
    const total = totalCallsAndMessages(items);
    return (
      <FlexLayout testId="CallSMS" mainaxis="column" marginTop="8">
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="space-between"
          marginBottom="24"
        >
          <span>{t('Calls')}</span>
          <WithUpgradeTag
            when={isFeaturePremium}
            text={() => t('Upgrade for details')}
            align="start"
            gap="16"
          >
            <span data-testid="total-videos">{total.calls}</span>
          </WithUpgradeTag>
        </FlexLayout>
        <ContentSeparator />
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="space-between"
          marginBottom="24"
          marginTop="8"
        >
          <span>{t('Messages')}</span>
          <WithUpgradeTag
            when={isFeaturePremium}
            text={() => t('Upgrade for details')}
            align="start"
            gap="16"
          >
            <span data-testid="total-searches">{total.sms}</span>
          </WithUpgradeTag>
        </FlexLayout>
      </FlexLayout>
    );
  }

  return (
    <List testId="CallSMS">
      {items.map(item => (
        <CompactStyleListItem
          extraRightSpace
          bottomPadding="extra"
          testId={item.socialContactId}
          key={`callsAndSms_${profileUid}_${item.socialContactId}`}
          title={item.socialContactName || item.socialContactId}
          rightTitle={
            <FlexLayout mainaxis="row" mainaxisAlignment="space-around">
              {item.blockedAttempts ? (
                <Layout renderAs="span" display="inline-block" minWidth="48">
                  <Layout renderAs="span" display="inline-block" minWidth="32">
                    <Icon
                      type={IconType.phoneSlash}
                      color={IconColor.error}
                      size={IconSize.sm}
                      family={IconFamily.solid}
                    />
                  </Layout>
                  {item.blockedAttempts}
                </Layout>
              ) : (
                <Layout renderAs="span" display="inline-block" minWidth="48">
                  <Layout renderAs="span" display="inline-block" minWidth="32">
                    <Icon
                      type={IconType.phone}
                      color={IconColor.regular}
                      size={IconSize.sm}
                      family={IconFamily.solid}
                    />
                  </Layout>
                  {item.calls}
                </Layout>
              )}
              <Layout renderAs="span" display="inline-block" minWidth="48">
                <Layout renderAs="span" display="inline-block" minWidth="32">
                  <Icon
                    type={IconType.comments}
                    color={IconColor.regular}
                    size={IconSize.sm}
                  />
                </Layout>
                {item.messages}
              </Layout>
            </FlexLayout>
          }
        />
      ))}
    </List>
  );
};

const QuestionableSearchesSummaryList: React.FunctionComponent<{
  profileUid: string;
  items: Inmutable.List<SearchActivityRecord>;
}> = ({ profileUid, items }) => (
  <List testId="QuestionableSearchActivity">
    {items.map(item => (
      <CompactStyleListItem
        testId={item.term}
        title={capitalize(item.term)}
        key={`questionableSearch_${profileUid}_${item.id}`}
        rightSubtitle={
          item.count === 1
            ? t('1 time')
            : t('{{count}} times', { count: item.count })
        }
      />
    ))}
  </List>
);

const AppsAndWebActivityList: React.FunctionComponent<{
  profileUid: string;
  items: Inmutable.List<ActivityRecord>;
}> = ({ profileUid, items }) => {
  const formattedItems = items.map(item =>
    item.type === ActivityTypes.WebDomain
      ? getWebDomainItem(profileUid, item as WebDomainActivityRecord)
      : getAppItem(profileUid, item as AppActivityRecord)
  );

  return (
    <List className="ActivityList__apps-and-web-list">
      {formattedItems.map(({ key, ...formatedItem }) => (
        <CompactStyleListItem key={key} {...formatedItem} />
      ))}
    </List>
  );
};

const getWebDomainItem = (
  profileUid: string,
  item: WebDomainActivityRecord
) => {
  const isAlertedWebDomain = item.action === ACTION_ALERT;
  const domainAlertSubtitle =
    item.reason === REASON_DOMAIN_LIST_ALERT
      ? t('Browsing alert')
      : t('Alerted category');

  const subtitle = isAlertedWebDomain ? domainAlertSubtitle : t('Web');

  return {
    testId: item.host,
    subtitle,
    title: item.host,
    rightSubtitle: minutesToHMFormat(item.minutes),
    key: `webActivity_${profileUid}_${item.host}`,
    subtitleClassName: isAlertedWebDomain
      ? 'ActivityList__alerted-web_domain'
      : undefined,
    icon: (
      <WebSummaryCardActivityIcon
        size={ActivityIconSizes.Small}
        className={classNames(
          'SummaryCardActivityIcon',
          isAlertedWebDomain ? 'SummaryCardActivityIcon--warning' : ''
        )}
      />
    ),
  };
};

const getAppItem = (profileUid: string, item: AppActivityRecord) => {
  const subtitle =
    item.platform !== PLATFORM_UNKNOWN
      ? t('{{platform}} app', {
          platform: mapPlatformToPlatformName(item.platform),
        })
      : t('App');

  return {
    testId: item.name,
    subtitle,
    title: item.name,
    rightSubtitle: minutesToHMFormat(item.minutes),
    key: `appActivity_${profileUid}_${item.exe}`,
    icon: (
      <AppSummaryCardActivityIcon
        size={ActivityIconSizes.Small}
        platform={item.platform}
        src={item.thumbnail}
        className="SummaryCardActivityIcon"
      />
    ),
  };
};

const BlockedWebActivityList: React.FunctionComponent<{
  profileUid: string;
  items: Inmutable.List<ActivityRecord>;
}> = ({ profileUid, items }) => {
  const formattedItems = items
    .map(item => {
      const webItem = item as WebDomainActivityRecord;
      return {
        testId: webItem.host,
        title: webItem.host,
        key: `blockedWebActivity_${profileUid}_${webItem.host}`,
        subtitle: webItem.categories
          .map(category => getCategoryLabel(category.id))
          .join(', '),
        rightSubtitle:
          webItem.blockedPages === 1
            ? t('1 time')
            : t('{{count}} times', { count: webItem.blockedPages }),
      };
    })
    .toJS();
  return (
    <List>
      {formattedItems.map(({ key, ...formatedItem }) => (
        <CompactStyleListItem key={key} {...formatedItem} />
      ))}
    </List>
  );
};

export default ActivityList;
