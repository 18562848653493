import flags from '../../sideEffects/flags';

export const isValidProfileForDelegation = (
  isDelegationEnabled: boolean,
  isLinkedWithLinewize: boolean
) => {
  return (
    flags.useDelegation.isEnabled() &&
    isDelegationEnabled &&
    isLinkedWithLinewize
  );
};

export const hasProfileBeenDelegated = (
  isDelegationEnabled: boolean,
  isProfileOptIn: boolean,
  isLinkedWithLinewize: boolean
) => {
  return (
    isValidProfileForDelegation(isDelegationEnabled, isLinkedWithLinewize) &&
    isProfileOptIn
  );
};

export const isProfileDelegatedToThisAccount = (
  isDelegationEnabled: boolean,
  isOptInInThisAccount: boolean,
  isLinkedWithLinewize: boolean
) => {
  return (
    isValidProfileForDelegation(isDelegationEnabled, isLinkedWithLinewize) &&
    isOptInInThisAccount
  );
};
