/* eslint-disable func-names */
export type Flag = { isEnabled: () => boolean };

export const executeIfAllFlags: any =
  (flagArray: Flag[], returnValueWhenFail?: any) =>
  (fn: any, ctx?: any): any =>
    function (...args) {
      if (
        flagArray.reduce(
          (accum: boolean, evalFlag: Flag) => accum && evalFlag.isEnabled(),
          true
        )
      ) {
        return fn.apply(ctx, args);
      }
      return returnValueWhenFail;
    };

export const executeIfSomeFlag: any =
  (flagArray: Flag[], returnValueWhenFail?: any) =>
  (fn: any, ctx?: any): any =>
    function (...args) {
      if (
        flagArray.reduce(
          (accum: boolean, evalFlag: Flag) => accum || evalFlag.isEnabled(),
          false
        )
      ) {
        return fn.apply(ctx, args);
      }
      return returnValueWhenFail;
    };
