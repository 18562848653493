import { fromJS, Map } from 'immutable';
import type { RoutinesState, RoutinesPayload, RoutineAction } from './types';

export const initialState: RoutinesState = fromJS({
  mode: null,
  activeMenuStep: null,
  isOpenMenu: false,
  editedRoutine: null,
  tempRoutine: Map({
    uid: null,
    creationMode: null,
  }),
  machineAction: {
    type: null,
    params: null,
  },
  routinesStatus: Map({ read: 'idle', create: 'idle' }),
  routineDetailsStatus: Map({
    read: 'idle',
    create: 'idle',
    delete: 'idle',
    update: 'idle',
  }),
  schedulesStatus: Map({ read: 'idle', create: 'idle' }),
  scheduleDetailsStatus: Map({
    update: 'idle',
    delete: 'idle',
  }),
} as RoutinesPayload);

const routines = (
  state: RoutinesState = initialState,
  action: RoutineAction
) => {
  switch (action.type) {
    case 'ROUTINES_EDIT':
      return state.merge({
        mode: 'EDIT',
        editedRoutine: action.payload.routineUid,
        isOpenMenu: true,
        machineAction: {
          type: null,
          params: null,
        },
      });
    case 'ROUTINES_NEW':
      return state.merge({
        mode: 'CREATE',
        editedRoutine: null,
        isOpenMenu: true,
        machineAction: {
          type: null,
          params: null,
        },
      });
    case 'ROUTINES_SET_IS_OPEN_MENU':
      return state.merge({
        isOpenMenu: action.payload,
        editedRoutine: action.payload ? state.get('editedRoutine') : null,
        mode: action.payload ? state.get('mode') : null,
      });
    case 'ROUTINES_SET_MENU_TRANSITION':
      return state.merge({
        activeMenuStep: action.payload.activeMenuStep,
        isOpenMenu: action.payload.isOpenMenu,
        mode: action.payload ? state.get('mode') : null,
      });
    case 'ROUTINES_STATUS': {
      if (!state.get('routinesStatus').has(action.payload.type)) return state;
      return state.setIn(
        ['routinesStatus', action.payload.type],
        action.payload.status
      );
    }
    case 'ROUTINES_RECEIVE':
      return state.setIn(['routinesStatus', 'read'], 'settled');
    case 'ROUTINE_DETAILS_STATUS':
      if (!state.get('routineDetailsStatus').has(action.payload.type))
        return state;
      return state.setIn(
        ['routineDetailsStatus', action.payload.type],
        action.payload.status
      );
    case 'ROUTINE_DETAILS_RECEIVE':
      return state.setIn(['routineDetailsStatus', 'read'], 'settled');
    case 'ROUTINE_SCHEDULES_STATUS':
      if (!state.get('schedulesStatus').has(action.payload.type)) return state;
      return state.setIn(
        ['schedulesStatus', action.payload.type],
        action.payload.status
      );
    case 'ROUTINE_SCHEDULE_DETAILS_STATUS':
      if (!state.get('scheduleDetailsStatus').has(action.payload.type))
        return state;
      return state.setIn(
        ['scheduleDetailsStatus', action.payload.type],
        action.payload.status
      );
    case 'ROUTINES_FINISH':
      return state.merge({
        mode: null,
        editedRoutine: null,
        activeMenuStep: null,
        isOpenMenu: false,
        machineAction: {
          type: null,
          params: null,
        },
      });
    case 'ROUTINES_SET_MACHINE_ACTION':
      return state.merge({
        machineAction: action.payload,
      });
    case 'ROUTINES_SET_TEMP_ROUTINE_UID':
      return state.setIn(['tempRoutine', 'uid'], action.payload.uid);
    case 'ROUTINES_SET_TEMP_ROUTINE_CREATION_MODE':
      return state.setIn(
        ['tempRoutine', 'creationMode'],
        action.payload.creationMode
      );
    default:
      return state;
  }
};

export default routines;
