import * as React from 'react';
import { connect } from 'react-redux';
import { FlexLayout, InstructionBlock } from 'styleguide-react';
import { clickSupport } from '../../actions/onboarding/OnboardingActions';
import { classNames } from '../../helpers';
import * as qinit from '../../qinit';
import { t } from '../../lib/i18n';
import smileySvg from '../../assets/base/images/icons/smiley.svg';

interface OnboardingSupportContainerProps {
  onClick: () => any;
  className?: string;
}

const OnboardingSupportContainer: React.FunctionComponent<
  OnboardingSupportContainerProps
> = ({ className, onClick }: OnboardingSupportContainerProps) => (
  <FlexLayout
    mainaxis="row"
    mainaxisAlignment="center"
    crossaxisAlignment="flex-start"
    marginTop="24"
    marginLeft="24"
    marginRight="24"
    paddingBottom="48"
    className={classNames('Support', className)}
  >
    <FlexLayout mainaxis="column" marginRight="8">
      <img
        className={classNames('Support__icon', className)}
        src={smileySvg}
        alt="smiley face"
      />
    </FlexLayout>
    <InstructionBlock
      className={classNames('Support__text', className)}
      testId="onboardingSupportMessage"
    >
      {`${t('We’re here to help.')} `}
      <a
        href={qinit.tenant.marketing.public_site.help_download}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        <strong>{t('See how to install {{shortName}}.')}</strong>
      </a>
    </InstructionBlock>
  </FlexLayout>
);

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(clickSupport()),
});

export default connect(null, mapDispatchToProps)(OnboardingSupportContainer);
