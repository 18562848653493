import * as React from 'react';
import { Typography } from 'styleguide-react';

interface AppRulesGroupedTotalAppsProps {
  filteredAppsTotal: number;
  categoryAppsTotal: number;
}

const AppRulesGroupedTotalApps = ({
  filteredAppsTotal,
  categoryAppsTotal,
}: AppRulesGroupedTotalAppsProps) => {
  const showTotalCategoryApps = filteredAppsTotal !== categoryAppsTotal;
  return (
    <Typography type="title2">
      {filteredAppsTotal}
      {showTotalCategoryApps && (
        <Typography type="body2" color="gray-light" renderAs="span">
          /{categoryAppsTotal}
        </Typography>
      )}
    </Typography>
  );
};

export default AppRulesGroupedTotalApps;
