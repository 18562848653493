import * as React from 'react';
import {
  GlobalType,
  Icon,
  IconSize,
  IconType,
  Layout,
  Link,
  List,
  SelectableListItem,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineRulesListItem, {
  RoutineRulesListItemProps,
} from '../../RoutineRulesList/RoutineRulesListItem';

import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { RoutineMode, MultiStepDataContext } from '../../routines.types';
import { when } from '../../../../helpers/render';

export enum SelectBlockTypeTest {
  prefix = 'routine-select-block-type-step',
}

type RoutineBlockTypes = MultiStepDataContext['block'];

export interface SelectRoutineBlockTypeProps
  extends RoutineStepWrapperHandlerProps<RoutineBlockTypes> {
  mode: RoutineMode;
  type: RoutineBlockTypes;
  name: string;
  onClickMoreInfo: (type: RoutineBlockTypes) => void;
}

const MoreInfo = ({ description, onClick, type, testId }) => (
  <Layout>
    <Typography type="body2">{description}</Typography>
    <Layout marginTop="8">
      <Link
        testId={testId}
        onClick={e => {
          e.stopPropagation();
          onClick(type);
        }}
      >
        <Typography type="body2" weight="semibold">
          {t('More info')}
        </Typography>
      </Link>
    </Layout>
  </Layout>
);

const getOptionsConfig = ({
  next,
  mode,
  onClickMoreInfo,
  onChangeType,
  typeSelected,
}: {
  next: SelectRoutineBlockTypeProps['next'];
  mode: RoutineMode;
  onClickMoreInfo: (type: RoutineBlockTypes) => void;
  onChangeType: (type: RoutineBlockTypes) => void;
  typeSelected: RoutineBlockTypes;
}) => {
  const blockTypes: Record<'lockNavigation' | 'lockDevice', RoutineBlockTypes> =
    {
      lockNavigation: 'POLICY_BLOCK_TYPE_LOCK_NAVIGATION',
      lockDevice: 'POLICY_BLOCK_TYPE_LOCK_DEVICE',
    };

  const options = [
    {
      testId: `${SelectBlockTypeTest.prefix}-option-disconnect-internet`,
      title: t('Disconnect the internet'),
      subtitle: isCreateMode(mode) ? (
        t('Prevent web browsing and internet access')
      ) : (
        <MoreInfo
          testId={`${SelectBlockTypeTest.prefix}-disconnect-internet-info`}
          description={t('Prevent web browsing and internet access')}
          type={blockTypes.lockNavigation}
          onClick={onClickMoreInfo}
        />
      ),
      icon: isCreateMode(mode) ? (
        IconType.wifiSlash
      ) : (
        <Icon
          type={IconType.wifiSlash}
          color={GlobalType.secondary}
          size={IconSize.lg}
        />
      ),
      iconColor: isCreateMode(mode) ? GlobalType.error : GlobalType.secondary,
      onClick: isCreateMode(mode)
        ? () => next?.(blockTypes.lockNavigation)
        : () => onChangeType(blockTypes.lockNavigation),
      type: blockTypes.lockNavigation,
      selected: typeSelected === blockTypes.lockNavigation,
    },
    {
      testId: `${SelectBlockTypeTest.prefix}-option-lock-device`,
      title: t('Lock devices'),
      subtitle: isCreateMode(mode) ? (
        t('Block apps and log out of active sessions')
      ) : (
        <MoreInfo
          testId={`${SelectBlockTypeTest.prefix}-lock-device-info`}
          description={t('Block apps and log out of active sessions')}
          type={blockTypes.lockDevice}
          onClick={onClickMoreInfo}
        />
      ),
      icon: isCreateMode(mode) ? (
        IconType.lock
      ) : (
        <Icon
          type={IconType.lock}
          color={GlobalType.secondary}
          size={IconSize.lg}
        />
      ),
      iconColor: isCreateMode(mode) ? GlobalType.error : GlobalType.secondary,
      onClick: isCreateMode(mode)
        ? () => next?.(blockTypes.lockDevice)
        : () => onChangeType(blockTypes.lockDevice),
      type: blockTypes.lockDevice,
      selected: typeSelected === blockTypes.lockDevice,
    },
  ];

  return options;
};

const isCreateMode = (mode: SelectRoutineBlockTypeProps['mode']) =>
  mode === 'CREATE';

const isEditMode = (mode: SelectRoutineBlockTypeProps['mode']) =>
  mode === 'EDIT';

const SelectRoutineBlockType = ({
  prev,
  close,
  next,
  mode,
  type,
  name,
  onClickMoreInfo,
}: SelectRoutineBlockTypeProps) => {
  const [typeSelected, setTypeSelected] =
    React.useState<RoutineBlockTypes>(type);

  const options = getOptionsConfig({
    next,
    mode,
    onClickMoreInfo,
    onChangeType: setTypeSelected,
    typeSelected,
  });

  return (
    <RoutineStepWrapper
      testId={SelectBlockTypeTest.prefix}
      prev={prev}
      close={close}
      next={() => next?.(typeSelected)}
      fixedFooter
      footerText={isEditMode(mode) ? t('Save') : undefined}
    >
      <Layout>
        <Typography type="h4" weight="semibold" className="par-util-break-word">
          {isCreateMode(mode) && t('Select the block you want to use')}
          {isEditMode(mode) &&
            t('Edit {{routineName}} rules', { routineName: name })}
        </Typography>

        {when(
          isEditMode(mode),
          <Layout marginTop="24">
            <Typography type="body2">
              {t('Select the block you want to use during this routine.')}
            </Typography>
          </Layout>
        )}
      </Layout>

      {isCreateMode(mode) && (
        <Layout marginTop="40">
          <List allowCustomListItemStyle={RoutineRulesListItem}>
            {options.map(option => (
              <RoutineRulesListItem
                key={option.title}
                {...(option as RoutineRulesListItemProps)}
              />
            ))}
          </List>
        </Layout>
      )}

      {isEditMode(mode) && (
        <Layout marginTop="40">
          <List
            allowCustomListItemStyle={SelectableListItem}
            listItemMarginBottom="16"
          >
            {options.map(option => (
              <SelectableListItem
                key={option.title}
                {...option}
                type="checkbox"
              />
            ))}
          </List>
        </Layout>
      )}
    </RoutineStepWrapper>
  );
};

export default SelectRoutineBlockType;
