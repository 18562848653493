import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import CallsSMSBlockedSectionModal from '../../components/Modal/CallsSMSBlockedSectionModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const CallsSMSBlockedSectionModalContainer = connect(
  null,
  mapDispatchToProps
)(CallsSMSBlockedSectionModal);

export default CallsSMSBlockedSectionModalContainer;
