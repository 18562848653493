import { ProductRecord } from '../../records';
import State from '../../store/state';

export const getDirectPurchase = (state: State) => state.get('directPurchase');

export const getDirectPurchaseProduct = (state: State): ProductRecord | null =>
  state.getIn(['directPurchase', 'product']);

export const getDirectPurchaseXsource = (state: State): string | null =>
  state.getIn(['directPurchase', 'pg']);
