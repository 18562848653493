import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { goBackIfHistory } from '../../ducks/routing';
import { isFree } from '../../records/license';
import RatingAppBadFeedbackModal from '../../components/Modal/RatingAppBadFeedbackModal';
import { track, Events } from '../../helpers/analytics';
import { getLicense } from '../../selectors/license';
import * as qinit from '../../qinit';
import { BaseThunk } from '../../store/state';
import { getCurrentScenario } from '../../selectors';
import { handleClose } from '../../actions/appRate';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => {
  const isFreeLicense = isFree(getLicense(state).type);
  return {
    isFree: isFreeLicense,
    text: isFreeLicense
      ? t(
          'Check out our Help Center for tips and solutions to common user issues.'
        )
      : t(
          'Our Customer Support team is here to help and answer your questions!'
        ),
    buttonText: isFreeLicense
      ? t('Go to Qustodio Help Center')
      : t('Contact us'),
  };
};
const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(handleClose());
  },
  goToQustodioHelpCenter: () => {
    dispatch(handleGoToQustodioHelpCenter());
  },
  openMailApp: () => {
    dispatch(handleOpenMailApp());
  },
});

const handleGoToQustodioHelpCenter =
  (): BaseThunk<void> => (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedRatingFlyoverAction, {
      action: 'help_center',
      scenario: getCurrentScenario(getState()),
    });
    dispatch(goBackIfHistory());
    dispatch(
      navigate({
        type: 'external',
        src: qinit.tenant.marketing.public_site.general_help_link,
      })
    );
  };

const handleOpenMailApp = (): BaseThunk<void> => (dispatch, getState) => {
  const navigate = getMultiPlatformNavigation();
  track(Events.ClickedRatingFlyoverAction, {
    action: 'customer_support',
    scenario: getCurrentScenario(getState()),
  });
  dispatch(goBackIfHistory());
  dispatch(
    navigate({
      type: 'external',
      src: qinit.tenant.marketing.public_site.help_contact,
    })
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RatingAppBadFeedbackModal);
