import * as React from 'react';
import { connect } from 'react-redux';
import {
  StyledHeader,
  FlexLayout,
  InstructionBlock,
  List,
  RegularStyleListItem,
  Icon,
  IconType,
  IconColor,
  IconSize,
  TransparentButton,
} from 'styleguide-react';
import AdditionalParentForm from './AdditionalParentForm';
import Loader from '../base/Loader';
import { t } from '../../lib/i18n';
import State, { Dispatch } from '../../store/state';
import { getAccountEmail } from '../../selectors';
import {
  isCreatingInvitation,
  getInvitation,
  isRemovingInvitation,
  isFetchingInvitations,
  isFetchingInvitationsFailed,
} from '../../ducks/invitations/invitationsSelector';
import {
  createInvitation,
  fetchInvitations,
  removeInvitation,
} from '../../ducks/invitations/invitationsActions';
import { pushRelativeLocation } from '../../actions/Navigation';

type Invitation = ReturnType<typeof getInvitation>;

export const AdditionalParentComponent: React.FC<AdditionalParentProps> = ({
  onClickInformationIcon,
  ...props
}: AdditionalParentProps) => {
  return (
    <AdditionalParentLayout onClickInformationIcon={onClickInformationIcon}>
      {additionalParentBody(props)}
    </AdditionalParentLayout>
  );
};

const AdditionalParentLayout = ({
  children,
  onClickInformationIcon,
}: {
  children: React.ReactNode;
  onClickInformationIcon: AdditionalParentProps['onClickInformationIcon'];
}) => (
  <div data-testid="AdditionParent">
    <StyledHeader type="h4">{t('Additional parent')}</StyledHeader>
    <FlexLayout mainaxis="column" marginTop="16" marginBottom="24">
      <InstructionBlock>
        {t('Invite  another parent to your {{shortName}} account.')}
        <TransparentButton
          onClick={onClickInformationIcon}
          testId="AdditionalParent__Button--info"
        >
          <Icon type={IconType.infoCircle} color={IconColor.secondary} />
        </TransparentButton>
      </InstructionBlock>
      {children}
    </FlexLayout>
  </div>
);

type AdditionalParentBodyProps = Omit<
  AdditionalParentProps,
  'onClickInformationIcon'
>;
const additionalParentBody: React.FC<AdditionalParentBodyProps> = ({
  isFetchingInvitation,
  isFetchingInvitationFailed,
  isCreatingInvitation,
  isRemovingInvitation,
  invitation,
  currentAccountEmailAddress,
  onRemoveInvitation,
  onCreateInvitation,
  onRefetchInvitations,
}: AdditionalParentBodyProps) => {
  if (isFetchingInvitationFailed) {
    return (
      <FlexLayout
        mainaxis="column"
        marginTop="32"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        height="56"
      >
        <FlexLayout mainaxis="row">
          <TransparentButton
            onClick={onRefetchInvitations}
            testId="refetchInvitations"
          >
            <Icon
              type={IconType.syncAlt}
              size={IconSize.lg}
              color={IconColor.neutral}
            />
          </TransparentButton>
        </FlexLayout>
        <FlexLayout mainaxis="row" marginTop="8">
          <InstructionBlock>
            {t('Something went wrong. Click to retry.')}
          </InstructionBlock>
        </FlexLayout>
      </FlexLayout>
    );
  }

  if (isFetchingInvitation || isCreatingInvitation || isRemovingInvitation) {
    return (
      <FlexLayout
        mainaxis="column"
        marginTop="32"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        height="56"
      >
        <Loader size="small" />
      </FlexLayout>
    );
  }

  if (invitation) {
    const title: string = {
      pending: t('Pending...'),
      expired: t('Invitation expired'),
      accepted: invitation.invitedAccessProfile?.name,
    }[invitation.status];

    const email =
      invitation.invitedAccessProfile?.email ?? invitation.invitationEmail;

    return (
      <FlexLayout mainaxis="column" height="56" marginTop="32">
        <InvitationView
          title={title}
          email={email}
          onRemoveInvitation={() => onRemoveInvitation(invitation)}
        />
      </FlexLayout>
    );
  }

  return (
    <AdditionalParentForm
      currentAccountEmailAddress={currentAccountEmailAddress}
      onSubmit={formValues => {
        onCreateInvitation(formValues.get('email'));
      }}
    />
  );
};

const InvitationView = ({
  title,
  email,
  onRemoveInvitation,
}: {
  title: string;
  email: string;
  onRemoveInvitation: () => void;
}) => (
  <List>
    <RegularStyleListItem
      title={title}
      upperSubtitle={email}
      actionElement={
        <TransparentButton
          onClick={onRemoveInvitation}
          testId="removeInvitation"
        >
          <Icon
            type={IconType.trashAlt}
            color={IconColor.error}
            size={IconSize.regular}
          />
        </TransparentButton>
      }
    />
  </List>
);

const mapStateToProps = (state: State) => ({
  isFetchingInvitation: isFetchingInvitations(state),
  isFetchingInvitationFailed: isFetchingInvitationsFailed(state),
  isCreatingInvitation: isCreatingInvitation(state),
  isRemovingInvitation: isRemovingInvitation(state),
  invitation: getInvitation(state),
  currentAccountEmailAddress: getAccountEmail(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRemoveInvitation: (invitation: Exclude<Invitation, undefined>) => {
    /*
        When the displayed invitation it's an expired one. Upon clicking the trash icon, we want to remove it directly.
        For pending or accepted invitations we want to display a confirmation modal before procedding to the deletion.
      */
    if (invitation.status === 'expired') {
      dispatch(removeInvitation());
    } else {
      dispatch(pushRelativeLocation(`modal/RemoveInvitationModal`));
    }
  },
  onCreateInvitation: (email: string) => {
    dispatch(createInvitation(email));
  },
  onRefetchInvitations: () => {
    dispatch(fetchInvitations());
  },
  onClickInformationIcon: () => {
    dispatch(pushRelativeLocation(`modal/AdditionalParentInformationModal`));
  },
});

type AdditionalParentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AdditionalParent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalParentComponent);

export default AdditionalParent;
