import * as React from 'react';
import IconGift from '../Icons/IconGift';
import { classNames } from '../../helpers';

export type GiftLevel = 'brand' | 'white';

const Gift: React.FunctionComponent<{
  level?: GiftLevel;
  className?: string;
  background?: boolean;
  onClick?: () => any;
}> = ({ level, className, background, onClick }) => {
  const giftColor = level ? `Gift--${level}` : '';
  const backgroundColor = background
    ? `Gift__background Gift__background--${level}`
    : '';
  return (
    <i
      className={classNames(`Gift ${giftColor} ${backgroundColor}`, className)}
      onClick={onClick}
    >
      <IconGift />
    </i>
  );
};

export default Gift;
