import createLogger from 'redux-logger';
import { Iterable } from 'immutable';
import * as R from 'ramda';
import { toSerializable } from '../helpers/state';

export const envMiddlewares = [
  createLogger({
    collapsed: true,
    stateTransformer: state => {
      const isImmutable = Iterable.isIterable(state);

      return {
        state: isImmutable ? state.toJS() : state,
        immutableSerialization: isImmutable ? toSerializable(state) : state,
      };
    },
    diff: false,
  }),
];

export const envEnhancers = [
  window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line
    ? window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    : R.identity,
];
