import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  List,
  RegularStyleListItem,
  Icon,
  IconColor,
  Typography,
  Button,
  ButtonSize,
} from 'styleguide-react';
import IconAllow from '../Icons/Actions/IconAllow';

const TrustedContactsLegendModal: React.FunctionComponent<{
  onClickClose: () => void;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    header={{
      // FIXME(rocket): Implement this variant in rocket
      icon: (
        <Icon
          className="fa-regular fa-user-group"
          color={IconColor.secondary}
        />
      ),
    }}
    title={t('Trusted contacts')}
    className="TrustedContactsLegendModal"
    size="small"
    buttons={[
      <Button
        key="button1"
        onClick={onClickClose}
        size={ButtonSize.medium}
        block
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    <List showSeparator={false} listItemMarginBottom="8">
      <RegularStyleListItem
        icon={<IconAllow />}
        title={
          <Typography type="body1" align="left">
            {t('Trusted contact is confirmed and will receive panic alerts')}
          </Typography>
        }
      />
      <RegularStyleListItem
        icon={<IconAllow color={IconColor.warning} />}
        title={
          <Typography type="body1" align="left">
            {t(
              'Trusted contact is invited, but will not receive alerts until confirmed'
            )}
          </Typography>
        }
      />
      <RegularStyleListItem
        icon={<IconAllow color={IconColor.neutral} />}
        title={
          <Typography type="body1" align="left">
            {t('Trusted contact is not selected and will not receive alerts')}
          </Typography>
        }
      />
    </List>
  </Modal>
);

export default TrustedContactsLegendModal;
