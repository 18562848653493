import { IconType } from 'styleguide-react';
import { t } from '../lib/i18n';
import { capitalize } from '../helpers/string';
import {
  ProductRecord,
  ProductSize,
  getProductTypeAsString,
} from '../records/product';
import { LicenseSubtype } from '../records/license/types/License.types';

export const getProductFeatures = (
  productType: ProductSize,
  licenseSubtype: LicenseSubtype
) => {
  const basicProductFeatures = [
    t('Games & apps blocking'),
    t('Daily time Limits'),
    t('Web filtering'),
    t('Location monitoring'),
    t('Pause internet access /noun/'),
  ];

  const featuresTitle = t('{{planName}} includes:', {
    planName: capitalize(getProductTypeAsString(productType, licenseSubtype)),
  });

  const features = {
    title: featuresTitle,
    list: basicProductFeatures,
  };

  if (licenseSubtype === 'premium_basic') {
    return features;
  }

  features.list = [
    ...basicProductFeatures,
    t('Custom routines'),
    t('Custom alerts'),
    t('Games & apps time limits'),
    t('Calls & messages monitoring*'),
    t('Unlimited devices*'),
  ];

  return features;
};

export const getProductIcon = (licenseSubtype: LicenseSubtype) => {
  return licenseSubtype === 'premium_basic'
    ? IconType.lockAlt
    : IconType.shieldAlt;
};

export const getProductSubtitle = (licenseSubtype: LicenseSubtype): string => {
  return licenseSubtype === 'premium_basic'
    ? t('The tools you need for core protection.')
    : t('Advanced safety features with full customization.');
};

export const isProductHighlighted = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype === 'premium_complete';

export const isCarePlusCheckedByDefault = (licenseSubtype: LicenseSubtype) =>
  licenseSubtype !== 'premium_basic';

export const getProductRibbon = (product: ProductRecord) => {
  if (product.discount) {
    return {
      text: t('save {{discount}}%', { discount: product.discount }),
    };
  }
  if (product.licenseSubtype === 'premium_complete') {
    return { text: t('Most popular') };
  }

  return undefined;
};
