import {
  getOnboardingProfile,
  getOnboardingDevicePlatform,
} from '../../selectors';
import { fetchProfile } from '../../ducks/profiles';
import {
  addProfile,
  addOnboardingDevicePlatform,
  saveIsOnboardingCurrentDevice,
  updatePendingDeviceConnection,
  ONBOARDING_CURRENT_DEVICE_ANSWER_NO,
} from '../../ducks/appOnboarding';
import {
  mapUserAgentOsToPlatform,
  mapPlatformToDownloadLink,
} from '../../helpers/userAgent';
import { resetSocialShareType } from './OnboardingActions';
import { getLocationPathname, gaEvent } from '../../helpers';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

export const onEnterOnboardingAddDevice =
  ({ profileId }: { profileId: string }) =>
  (dispatch, getState) => {
    dispatch(resetSocialShareType());
    dispatch(addOnboardingDevicePlatform(mapUserAgentOsToPlatform()));
    const currentOnboardingProfile = getOnboardingProfile(getState());
    if (currentOnboardingProfile) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return dispatch(fetchProfile(profileId))
      .then(action => action.payload.records.profiles[profileId])
      .then(profile => {
        dispatch(addProfile(profile));
        dispatch(updatePendingDeviceConnection(true));
      })
      .catch(error => {
        throw new Error(error);
      });
  };

export const clickDownloadButton =
  (retry?: boolean) => (dispatch, getState) => {
    const profile = getOnboardingProfile(getState());

    if (!profile) {
      return;
    }

    const platform = getOnboardingDevicePlatform(getState());
    const platformDownloadLink = mapPlatformToDownloadLink(platform);
    const currentLocation = getLocationPathname(getState());
    const navigate = getMultiPlatformNavigation();

    if (platformDownloadLink === null) {
      dispatch(
        saveIsOnboardingCurrentDevice(ONBOARDING_CURRENT_DEVICE_ANSWER_NO)
      );
    }

    const redirectLocation = `/onboarding/download/${profile.id}/pending-device-connection-download`;

    if (redirectLocation !== currentLocation.toLowerCase()) {
      dispatch(navigate({ type: 'inner', src: redirectLocation }));
    }

    gaEvent(
      'wob-add-device',
      retry ? 'button-download-try-again' : 'button-download',
      'click'
    );

    navigate({
      type: 'location:href',
      src: platformDownloadLink,
    });
  };
