import * as React from 'react';
import {
  EventRecord,
  LicenseRecord,
  ContactRecord,
  DeviceRecord,
  ProfileRecord,
} from '../../records';
import ActivityTimelineItemTop from './ActivityTimelineItemTop';
import ActivityTimelineItemTimeSpent from './ActivityTimelineItemTimeSpent';
import ActivityTimelineItemIcon from './ActivityTimelineItemIcon';
import ActivityTimelineItemLabel from './ActivityTimelineItemLabel';
import ActivityTimelineItemText from './ActivityTimelineItemText';
import ActivityTimelineItemThumbnail from './ActivityTimelineItemThumbnail';
import ActivityTimelineItemBadge from './ActivityTimelineItemBadge';
import CategoryList from './CategoryList';
import ActivityTimelineItemSublabel from './ActivityTimelineItemSublabel';
import ActivityTimelineItemQuote from './ActivityTimelineItemQuote';
import { EventType } from '../../constants';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import ActivityTimelineItemTopRight from './ActivityTimelineItemTopRight';
import { isSafetyNet } from '../../records/category';
import Highlighted from '../SummaryAlerts/Highlighted/Highlighted';
import { EventCategoryType, isTypeAlertHighlighted } from './types';
import InappropriateCategoryList from './InappropriateCategoryList';
import { FlexLayout } from 'styleguide-react';
import { List } from 'immutable';

type Props<T> = React.FunctionComponent<
  {
    event: EventRecord;
    license: LicenseRecord;
    profile: ProfileRecord;
    device: DeviceRecord;
    contact: ContactRecord;
    timezone: string;
    displayTopRight: boolean;
    onClick: (event: EventRecord) => void;
    onClickLabelIcon: (event: EventRecord) => () => void;
    isBlockable: boolean;
    onInfoClick: (event: EventRecord) => void;
    type: EventCategoryType;
    onSafetyNetClick: () => void;
    isDelegatedWithPCDevices: boolean;
  } & T
>;

const ActivityTimelineItemDot = () => (
  <div className="par-activity-timeline-item-dot" />
);

const ActivityTimelineItem: Props<{}> = ({ onClick, ...props }) => {
  const { event, type } = props;
  const handleClick = () => onClick(event);

  const isItemClickable =
    ![
      EventType.App,
      EventType.CallsIncoming,
      EventType.CallsOutgoing,
      EventType.CallsMissed,
      EventType.CallsUnanswered,
      EventType.CallsBlockedIncoming,
      EventType.CallsBlockedOutgoing,
      EventType.SmsIncoming,
      EventType.SmsOutgoing,
      EventType.SmsBlockedIncoming,
      EventType.SmsBlockedOutgoing,
      EventType.Panic,
    ].includes(event.type) && !isSafetyNet(event.categories);

  const isAlertHighlighted = isTypeAlertHighlighted(type);

  return (
    <Highlighted disabled={!isAlertHighlighted} type="alert">
      <FlexLayout mainaxis="row">
        {isAlertHighlighted && <ActivityTimelineItemDot />}
        <div className="ActivityTimelineItem">
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <ActivityTimelineItemContent onClick={handleClick} {...props} />
          </RenderWhen>
          <RenderWhen screenSize={ScreenSize.Desktop}>
            <ActivityTimelineItemContent
              onClick={isItemClickable ? handleClick : () => {}}
              {...props}
            />
          </RenderWhen>
        </div>
      </FlexLayout>
    </Highlighted>
  );
};

const ActivityTimelineItemContent: Props<{ onClick: () => void }> = ({
  event,
  device,
  profile,
  license,
  contact,
  timezone,
  onClick,
  onClickLabelIcon,
  displayTopRight,
  isBlockable,
  onInfoClick,
  type,
  onSafetyNetClick,
  isDelegatedWithPCDevices,
}) => {
  const searchCategories = event.searchCategories || List();

  return (
    <div className="ActivityTimelineItem__inside" onClick={onClick}>
      <ActivityTimelineItemIcon event={event} type={type} />
      <ActivityTimelineItemTimeSpent event={event} />
      {displayTopRight && (
        <ActivityTimelineItemTopRight profileId={profile.id} />
      )}
      <ActivityTimelineItemTop event={event} timezone={timezone} type={type} />
      <ActivityTimelineItemLabel
        event={event}
        license={license}
        contact={contact}
        onClickLabelIcon={onClickLabelIcon(event)}
        isBlockable={isBlockable}
        onInfoClick={() => onInfoClick(event)}
        onSafetyNetClick={onSafetyNetClick}
        isDelegatedWithPCDevices={isDelegatedWithPCDevices}
      />
      <ActivityTimelineItemText event={event} profileName={profile.name} />
      <ActivityTimelineItemThumbnail event={event} />
      {showCategories(event) && (
        <CategoryList categories={event.categories} keyPrefix={event.key} />
      )}
      <ActivityTimelineItemSublabel
        event={event}
        deviceName={device ? device.name : ''}
        deviceCount={profile.deviceCount}
        license={license}
        isDelegatedWithPCDevices={isDelegatedWithPCDevices}
      />
      <ActivityTimelineItemQuote
        event={event}
        license={license}
        type={type}
        isDelegatedWithPCDevices={isDelegatedWithPCDevices}
      />
      {searchCategories.size > 0 && (
        <InappropriateCategoryList categories={searchCategories} />
      )}
      <ActivityTimelineItemBadge
        event={event}
        license={license}
        isDelegatedWithPCDevices={isDelegatedWithPCDevices}
      />
    </div>
  );
};

const showCategories = (event: EventRecord) => {
  switch (event.type) {
    case EventType.Youtube:
      return false;
    case EventType.Web:
      return !isSafetyNet(event.categories);
    default:
      return true;
  }
};

export default ActivityTimelineItem;
