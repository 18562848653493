import { commonMapInFn, commonMapOutFn } from '../../api/mappings/common';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import type {
  CalendarRestrictionPause,
  CalendarRestrictionPausePayload,
} from './types/CalendarRestriction.types';

export const CalendarRestrictionPauseOperation = getRecordOperations<
  CalendarRestrictionPause,
  CalendarRestrictionPausePayload
>({
  defaultFields: {
    uid: null,
    accountUid: null,
    profileUid: null,
    deviceUid: null,
    restrictionType: 'PAUSE', // Constant value for type pause
    usageType: 0, // Constant value for type pause
    duration: 0, // Constant value for type pause
    rrule: null,
    rruleDtstart: null,
    rruleUntil: null,
    createdAt: null,
    updatedAt: null,
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    restrictionType: 'PAUSE',
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    restriction_type: 3,
  }),
});
