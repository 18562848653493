import * as React from 'react';
import { Icon, IconSize, IconType } from 'styleguide-react';
import Menu from './SpeedDialMenu';
import ChildButton from './SpeedDialChildButton';
import MainButton from './SpeedDialMainButton';
import { classNames } from '../../helpers';

type SpeedDialChildrenProps = {
  label: string;
  onClick: () => void;
  icon: IconType;
};

type SpeedDialProps = {
  isOpen: boolean;
  onToggleMenu: () => void;
  actions: SpeedDialChildrenProps[];
};

const SpeedDial: React.FunctionComponent<SpeedDialProps> = ({
  isOpen,
  onToggleMenu,
  actions,
}: SpeedDialProps) => {
  const iconBlueClassName = 'SpeedDial__Icon SpeedDial__Icon--blue';
  return (
    <React.Fragment>
      <div
        className={`SpeedDial__Layer ${
          !isOpen ? 'SpeedDial__Layer--hidden' : ''
        }`}
        onClick={onToggleMenu}
      />
      <Menu isOpen={isOpen} onToggle={onToggleMenu}>
        <MainButton
          className={classNames('SpeedDial__mainButton')}
          iconResting={
            <Icon
              type={IconType.sliders}
              size={IconSize.lg}
              className="SpeedDial__Icon"
            />
          }
          iconActive={
            <Icon
              type={IconType.times}
              size={IconSize.lg}
              className="SpeedDial__Icon"
            />
          }
        />
        {actions.map(child => (
          <ChildButton
            key={child.label}
            icon={
              <Icon
                type={child.icon}
                size={IconSize.lg}
                className={iconBlueClassName}
              />
            }
            label={child.label}
            onClick={child.onClick}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default SpeedDial;
