import { EMPTY, iif, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';

import { captureException } from '../../helpers/sentry';

import { AppAction, AppEpic } from '../../epics/types';
import { ReceiveAppsCategoriesAction } from './types';
import { requestReceiveAppCategories } from './helpers';
import flags from '../../sideEffects/flags';

export const loadAppCategoriesEpic: AppEpic<
  AppAction,
  ReceiveAppsCategoriesAction
> = (actions$, _state$, { api }) => {
  return actions$.pipe(
    ofType('REQUEST_APP_CATEGORIES'),
    mergeMap(action =>
      iif(
        () => flags.useAppCategories.isEnabled(),
        of(action).pipe(
          mergeMap(() => requestReceiveAppCategories(api)),
          catchError((e: Error) => {
            captureException(e);
            return EMPTY;
          })
        ),
        EMPTY
      )
    )
  );
};
