import { OrderedSet } from 'immutable';
import { connect } from 'react-redux';
import * as R from 'ramda';
import DeviceEdit from '../components/Device/DeviceEdit';
import { getDevice, getAllDevices } from '../selectors';
import { save } from '../actions/DeviceEditActions';
import { DeviceRecord } from '../records/device/types/Device.types';

const mapStateToProps = (state, { params: { deviceId } }) => ({
  device: getDevice(state, deviceId),
  deviceNames: otherDeviceNames(
    getAllDevices(state),
    getDevice(state, deviceId)
  ),
});

const otherDeviceNames = (
  allDevices: OrderedSet<DeviceRecord>,
  thisDevice: DeviceRecord | undefined
): OrderedSet<string> =>
  thisDevice
    ? allDevices.map(R.prop('name')).filter(name => name !== thisDevice.name)
    : OrderedSet<string>();

const mapDispatchToProps = (dispatch, { params: { deviceId } }) => ({
  onSave: () => dispatch(save(deviceId)),
});

const DeviceEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceEdit);

export default DeviceEditContainer;
