import * as React from 'react';
import { AppRuleRecord } from '../../records/profileRules';
import { FlexLayout, Typography } from 'styleguide-react';
import AppRulesPlatform from './AppRulesPlatform';
import { t } from '../../lib/i18n';

const AppRulesGroupedSubtitle = ({ app }: { app: AppRuleRecord }) => (
  <FlexLayout mainaxis="row" className="par-app-rule-card__item-subtitle">
    <AppRulesPlatform app={app} />
    {app.ageRating && (
      <React.Fragment>
        <span className="par-app-rule-card__item-subtitle--separator">|</span>
        <Typography type="caption1">
          {t('Ages {{age}}+', { age: app.ageRating })}
        </Typography>{' '}
      </React.Fragment>
    )}
  </FlexLayout>
);

export default AppRulesGroupedSubtitle;
