import { connect } from 'react-redux';
import AdditionalParentWelcomeModal from '../../components/Modal/AdditionalParentWelcomeModal';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { Dispatch } from '../../store/state';

export default connect(null, (dispatch: Dispatch) => ({
  onClose: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  },
}))(AdditionalParentWelcomeModal);
