import * as React from 'react';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Icon,
  IconType,
  IconColor,
} from 'styleguide-react';
import { ActivateRetrialStatus } from '../../actions/RetrialActions';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';

interface ActivateRetrialModalProps {
  status: ActivateRetrialStatus;
  licenseDurationDays: number;
  onClickLink: () => void;
}

const ActivateRetrialModal: React.FunctionComponent<
  ActivateRetrialModalProps
> = ({
  status,
  licenseDurationDays,
  onClickLink,
}: ActivateRetrialModalProps) => {
  const title =
    status === ActivateRetrialStatus.Succeed
      ? t('Enjoy {{days}} days of {{shortName}} Premium', {
          days: licenseDurationDays,
        })
      : t('Free trial already used');

  const description =
    status === ActivateRetrialStatus.Succeed
      ? t(
          'You now have access to all Premium features for an extra {{days}} days. It’s on us!',
          { days: licenseDurationDays }
        )
      : t(
          'Looks like you’ve already used an extra free trial recently and can’t redeem another one.'
        );

  const ctaText =
    status === ActivateRetrialStatus.Succeed
      ? t('Explore Premium')
      : t('Got it');

  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickLink}
      header={{
        icon:
          status === ActivateRetrialStatus.Succeed ? (
            <Icon type={IconType.gift} />
          ) : (
            <Icon type={IconType.exclamationCircle} color={IconColor.error} />
          ),
      }}
      title={title}
      buttons={[
        <Button
          onClick={onClickLink}
          size={ButtonSize.medium}
          block
          key="ActivateRetrialModal__button"
        >
          {ctaText}
        </Button>,
      ]}
    >
      <ModalStyledText>{description}</ModalStyledText>
    </Modal>
  );
};

export default ActivateRetrialModal;
