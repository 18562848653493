import * as React from 'react';
import {
  FlexLayout,
  Switch,
  ContentSeparator,
  Card,
  Layout,
  GlobalType,
  InstructionBlock,
  FilterGroup,
  Filter,
  DeactivableContent,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import SearchForm from './SearchForm';
import { SettingsToggleNames } from '../../constants';
import AppRulesCardList from './AppRulesCardList';
import AppRulesNotifications from './AppRulesNotifications';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';
import type { AppRulesProps } from '../../containers/ProfileRules/AppRules/AppRulesContainer';
import { renderAppRulesEmptyState } from './EmptyStates/AppRulesEmptyStates';

type FiltersAndSearchFormType = Pick<
  AppRulesProps,
  | 'appRulesEnabled'
  | 'activeFilter'
  | 'noAppsDetected'
  | 'appEnhancedCategories'
  | 'activeFilterAppCategory'
  | 'onClickFilter'
  | 'onChangeSearch'
  | 'searchQuery'
>;
type SectionBodyType = Pick<
  AppRulesProps,
  | 'applicationList'
  | 'appRulesEnabled'
  | 'activeFilter'
  | 'noAppsDetected'
  | 'profileName'
  | 'isBlockedUnsupportedBrowsers'
  | 'onSelectStatus'
  | 'onClickApp'
  | 'categories'
  | 'totalAppsByCategory'
  | 'searchQuery'
>;

const AppRules = ({
  profileName,
  applicationList,
  appRulesEnabled,
  activeFilterAppCategory,
  isBlockedUnsupportedBrowsers,
  activeFilter,
  noAppsDetected,
  searchQuery,
  appEnhancedCategories,
  isAlertNewApps,
  categories,
  totalAppsByCategory,
  isSettingTogglePremium,
  onToggleAppRulesEnabled,
  onClickBack,
  onChangeSearch,
  onClickFilter,
  onToggleAppRulesSetting,
  onClickSettings,
  onSelectStatus,
  onClickApp,
}: AppRulesProps) => (
  <PageLayout
    title={t('Games & Apps')}
    configuration={[
      { screenSize: ScreenSize.Desktop, mainInsideCard: true },
      { screenSize: ScreenSize.Tablet, mainInsideCard: true, columns: 2 },
    ]}
    actions={[
      {
        actionName: 'back',
        onClick: onClickBack,
        screenSize: ScreenSize.MobileOrTablet,
      },
      {
        actionName: 'settings',
        onClick: onClickSettings,
        screenSize: ScreenSize.Mobile,
      },
    ]}
  >
    <PageSectionLayout side="main">
      <FlexLayout mainaxis="column">
        <Layout>
          <SectionHeader
            appRulesEnabled={appRulesEnabled}
            onToggleAppRulesEnabled={onToggleAppRulesEnabled}
          />
          <FiltersAndSearchForm
            appRulesEnabled={appRulesEnabled}
            noAppsDetected={noAppsDetected}
            activeFilterAppCategory={activeFilterAppCategory}
            activeFilter={activeFilter}
            searchQuery={searchQuery}
            appEnhancedCategories={appEnhancedCategories}
            onClickFilter={onClickFilter}
            onChangeSearch={onChangeSearch}
          />
          <SectionBody
            appRulesEnabled={appRulesEnabled}
            applicationList={applicationList}
            noAppsDetected={noAppsDetected}
            activeFilter={activeFilter}
            profileName={profileName}
            isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
            onSelectStatus={onSelectStatus}
            onClickApp={onClickApp}
            categories={categories}
            totalAppsByCategory={totalAppsByCategory}
            searchQuery={searchQuery}
          />
        </Layout>
      </FlexLayout>
    </PageSectionLayout>
    <PageSectionLayout side="right">
      {renderSettingsView(
        isAlertNewApps,
        isSettingTogglePremium,
        onToggleAppRulesSetting
      )}
    </PageSectionLayout>
  </PageLayout>
);

const SectionHeader = ({
  appRulesEnabled,
  onToggleAppRulesEnabled,
}: Pick<AppRulesProps, 'appRulesEnabled' | 'onToggleAppRulesEnabled'>) => (
  <React.Fragment>
    {renderGlobalSwitch(appRulesEnabled, onToggleAppRulesEnabled)}
    <FlexLayout mainaxis="row" marginTop="32" marginBottom="48">
      <InstructionBlock>
        {t('Allow, block, or set time limits for your child’s apps.')}
      </InstructionBlock>
    </FlexLayout>
  </React.Fragment>
);

const FiltersAndSearchForm = ({
  appRulesEnabled,
  activeFilter,
  searchQuery,
  noAppsDetected,
  appEnhancedCategories,
  activeFilterAppCategory,
  onClickFilter,
  onChangeSearch,
}: FiltersAndSearchFormType) => {
  if (noAppsDetected) return null;

  return (
    <DeactivableContent
      disabled={!appRulesEnabled}
      testId="AppRuleFiltersAndSearchForm"
    >
      <Layout marginBottom="16">
        <FilterGroup active={activeFilter} testId="AppRules">
          {appEnhancedCategories.map(category => (
            <Filter
              key={category.filter}
              id={category.filter}
              testId={category.filter}
              text={category.label}
              type={category.color}
              onClick={onClickFilter(category)}
            />
          ))}
        </FilterGroup>
      </Layout>

      <FlexLayout mainaxis="column" marginBottom="40">
        <SearchForm
          onChange={onChangeSearch}
          value={searchQuery}
          placeholder={activeFilterAppCategory?.searchPlaceholder}
        />
      </FlexLayout>
    </DeactivableContent>
  );
};

const SectionBody = ({
  profileName,
  applicationList,
  activeFilter,
  isBlockedUnsupportedBrowsers,
  appRulesEnabled,
  noAppsDetected,
  categories,
  totalAppsByCategory,
  searchQuery,
  onSelectStatus,
  onClickApp,
}: SectionBodyType) => {
  const isAppsListEmpty = !applicationList || applicationList.size === 0;
  if (isAppsListEmpty) {
    return renderAppRulesEmptyState({
      noAppsDetected,
      activeFilter,
    });
  }

  return (
    <AppRulesCardList
      profileName={profileName}
      list={applicationList}
      isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
      enabled={appRulesEnabled}
      onSelectStatus={onSelectStatus}
      onClickApp={onClickApp}
      categories={categories}
      totalAppsByCategory={totalAppsByCategory}
      searchQuery={searchQuery}
    />
  );
};

const renderSettingsView = (
  isAlertNewApps: boolean,
  isSettingTogglePremium: (setting: SettingsToggleNames) => boolean,
  onToggleAppRulesSetting: (
    target: SettingsToggleNames,
    toggleOn: boolean
  ) => void
) => (
  <Card
    type={GlobalType.white}
    header={{
      title: t('Notifications'),
      border: true,
    }}
    className={classNames(
      DesktopLayoutScssUtils.CardRight,
      TabletLayoutScssUtils.CardRight
    )}
  >
    <FlexLayout mainaxis="column">
      <Layout marginBottom="32">
        <AppRulesNotifications
          isAlertNewApps={isAlertNewApps}
          isSettingTogglePremium={isSettingTogglePremium}
          onToggleAppRulesSetting={onToggleAppRulesSetting}
        />
      </Layout>
    </FlexLayout>
  </Card>
);

const renderGlobalSwitch = (appRulesEnabled, onToggleAppRulesEnabled) => (
  <React.Fragment>
    <FlexLayout
      mainaxis="row"
      mainaxisAlignment="space-between"
      marginBottom="8"
    >
      <InstructionBlock>{t('Enable games & apps rules')}</InstructionBlock>
      <Switch
        id="appRulesEnabled"
        onChange={onToggleAppRulesEnabled}
        checked={appRulesEnabled}
        testId="appRulesEnabled"
      />
    </FlexLayout>
    <ContentSeparator />
  </React.Fragment>
);

export default AppRules;
