import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import { connect } from 'react-redux';
import OnboardingWelcome from '../../components/Onboarding/Views/OnboardingWelcome';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { clickCreateProfile } from '../../actions/onboarding/OnboardingWelcomeActions';
import { trackablePage } from '../../helpers/hocs';
import { PageNames, trackOpenedBurgerMenu } from '../../helpers/analytics';
import { t } from '../../lib/i18n';
import store from '../../store';

const mapDispatchToProps = dispatch => ({
  onClickNext: () => dispatch(clickCreateProfile()),
});

const OnboardingWelcomeContainer = connect(
  null,
  mapDispatchToProps
)(trackablePage(OnboardingWelcome, PageNames.OnBoardingIntroActivity));

export default OnboardingWrapper({
  showSupport: true,
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(OnboardingWelcomeContainer);
