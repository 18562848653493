import { connect } from 'react-redux';
import Login from '../components/Login/Login';
import { clearFreshlyLoggedOut } from '../ducks/app';
import { SET_LOADED_ROOT } from '../ducks/actionNames/app';
import * as qinit from '../qinit';
import { isFreshlyLoggedOut } from '../selectors';
import { getImagePathByLanguage } from '../helpers/images';
import * as DeviceImagePathEn from '../assets/base/images/device/device__en.png';
import * as DeviceImagePathEs from '../assets/base/images/device/device__es.png';
import * as DeviceImagePathDe from '../assets/base/images/device/device__de.png';
import * as DeviceImagePathFr from '../assets/base/images/device/device__fr.png';
import * as DeviceImagePathIt from '../assets/base/images/device/device__it.png';
import * as DeviceImagePathJa from '../assets/base/images/device/device__ja.png';
import * as DeviceImagePathPt from '../assets/base/images/device/device__pt_BR.png';
import * as DeviceImagePathZh from '../assets/base/images/device/device__zh-Hans.png';
import { track, Events } from '../helpers/analytics';
import { loginForm } from '../actions/login';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { clearOnLogout, markAsFreshlyLoggedOut } from '../sideEffects/logout';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';

const imagesByLanguage = [
  DeviceImagePathEn,
  DeviceImagePathEs,
  DeviceImagePathDe,
  DeviceImagePathFr,
  DeviceImagePathIt,
  DeviceImagePathJa,
  DeviceImagePathPt,
  DeviceImagePathZh,
];

const mapStateToProps = (state, { params, location }) => ({
  forgotPasswordUrl: qinit.tenant.dashboard.wizard.forget_password_link,
  modal: { params, location },
  autoFocus: !isFreshlyLoggedOut(state),
  license: params.license,
  imagePath: getImagePathByLanguage(imagesByLanguage),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(loginForm()),
  onClickButtonFullProtection: () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedLoggedOutUpgradePremium);
    dispatch(
      navigate({
        type: 'external',
        src: qinit.tenant.marketing.public_site.premium_plans_link,
      })
    );
  },
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

LoginContainer.load = () => (dispatch, getState) => {
  dispatch(clearOnLogout());
  dispatch(markAsFreshlyLoggedOut());
  // Handle history error https://github.com/ReactTraining/history/issues/427
  dispatch({ type: SET_LOADED_ROOT, payload: false });

  if (isFreshlyLoggedOut(getState())) {
    dispatch(clearFreshlyLoggedOut());
  }

  return dispatch(finishedLoadingInitialData());
};

export default LoginContainer;
