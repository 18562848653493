import * as React from 'react';
import {
  Card,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  List,
  ListItemVerticalAlign,
  RegularStyleListItem,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { CardButtonWithIconText } from '../Button/CardButton';
import { SafeNetworkEvent } from '../../records/event';
import { formatDateIncludingHours } from '../../helpers/dates';
import { ISOLanguage } from '../../constants';

export interface UrlDetailsSafeNetworkProps {
  event: SafeNetworkEvent;
  timezone: string;
  locale: ISOLanguage;
  onClickAdjustSettings: () => void;
  onClickReadHelpArticle: () => void;
}

export const UrlDetailsSafeNetwork = ({
  event,
  timezone,
  locale,
  onClickAdjustSettings,
  onClickReadHelpArticle,
}: UrlDetailsSafeNetworkProps) => {
  return (
    <React.Fragment>
      <Card className="UrlDetailsSafeNetwork__Main">
        <FlexLayout mainaxis="row">
          <List listItemMarginTop="16" listItemPaddingBottom="8">
            {event.network.name && (
              <RegularStyleListItem
                key="name"
                icon={<Icon type={IconType.wifi} />}
                title={t('Safe Network')}
                upperSubtitle={event.network.name}
                centerMiddleContent
                iconVerticalAlign={ListItemVerticalAlign.top}
              />
            )}
            <RegularStyleListItem
              key="time"
              icon={<Icon type={IconType.clock} />}
              title={t('Connection start time')}
              upperSubtitle={formatDateIncludingHours(
                event.time,
                timezone,
                locale
              )}
              centerMiddleContent
              iconVerticalAlign={ListItemVerticalAlign.top}
            />
            <RegularStyleListItem
              key="device-name"
              icon={<Icon type={IconType.phoneLaptop} />}
              title={t('Device name')}
              upperSubtitle={event.device.name}
              centerMiddleContent
              iconVerticalAlign={ListItemVerticalAlign.top}
            />
            <RegularStyleListItem
              key="about"
              icon={<Icon type={IconType.infoCircle} />}
              title={t('About Safe Networks')}
              upperSubtitle={t(
                'When a device is connected to a Safe Network, it benefits from improved security and better connectivity. Your {{shortName}} rules are temporarily overridden by school protection for the best experience.'
              )}
              centerMiddleContent
              iconVerticalAlign={ListItemVerticalAlign.top}
            />
          </List>
        </FlexLayout>
      </Card>
      <CardButtonWithIconText
        onClick={onClickAdjustSettings}
        icon={
          <Icon
            type={IconType.cog}
            color={IconColor.secondary}
            size={IconSize.lg}
          />
        }
        text={t('Adjust settings')}
      />
      <CardButtonWithIconText
        onClick={onClickReadHelpArticle}
        icon={
          <Icon
            type="fa fa-arrow-up-right-from-square"
            color={IconColor.secondary}
            size={IconSize.lg}
          />
        }
        text={t('Read help article')}
      />
    </React.Fragment>
  );
};
