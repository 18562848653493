import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import { FlexLayoutMainaxisAlignment } from 'styleguide-react/build/components/Layout/types';
import { FlexLayout, StyledHeader } from 'styleguide-react';

export interface PreOnboardingBaseProps {
  description: string | JSX.Element;
  title?: string;
  illustrationSrc: string;
  button: JSX.Element;
  bottomContent?: JSX.Element;
}

const PreOnboardingBase: React.FC<
  PreOnboardingBaseProps & {
    alignement?: FlexLayoutMainaxisAlignment;
    descriptionAlignement?: FlexLayoutMainaxisAlignment;
  }
> = ({
  description,
  title,
  illustrationSrc,
  button,
  bottomContent,
  alignement,
  descriptionAlignement,
}) => (
  <FlexLayout mainaxis="column" minWidth="100%" flexGrow="2">
    <FlexLayout
      mainaxis="column"
      crossaxisAlignment="center"
      mainaxisAlignment={alignement}
      flexGrow="2"
      matchParentHeight
      testId="Flyover__container"
      marginRight="24"
      marginLeft="24"
    >
      <FlexLayout
        testId="Flyover__description-container"
        mainaxis="column"
        flexGrow="2"
        mainaxisAlignment={descriptionAlignement}
        minHeight="75%"
      >
        <FlexLayout
          mainaxis="column"
          marginBottom="48"
          crossaxisAlignment="center"
        >
          <img src={illustrationSrc} alt="illustration" />
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          marginBottom="16"
          crossaxisAlignment="center"
          textAlignement="center"
        >
          <StyledHeader type="h2">
            <strong>{title}</strong>
          </StyledHeader>
        </FlexLayout>

        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          marginBottom="48"
          textAlignement="center"
        >
          {description}
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        mainaxis="column"
        crossaxisAlignment="center"
        testId="Flyover__bottomContent"
        width="100%"
      >
        <FlexLayout
          flexGrow="1"
          mainaxis="column"
          width="100%"
          maxWidth="400"
          mainaxisAlignment="center"
          marginBottom="32"
        >
          {button}
        </FlexLayout>

        <FlexLayout
          flexGrow="0"
          mainaxis="column"
          textAlignement="center"
          minHeight="72"
        >
          {bottomContent}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  </FlexLayout>
);

export default PreOnboardingBase;
