import { Dispatch } from '../../store/state';
import { requestFeaturesAction } from './actions';
import api from '../../api';
import { requestReceiveFeatures } from './helpers';

export const fetchFeatures = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestFeaturesAction());
    const featuresAction = await requestReceiveFeatures(api);
    dispatch(featuresAction);
  };
};
