/* eslint-disable react/state-in-constructor */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import {
  AvatarSize,
  Icon as CustomIcon,
  IconType,
  PageTitle,
  ProfileInfoHeader,
} from 'styleguide-react';
import Loader from '../base/Loader';
import { mapProfileStatusToMessageStatus } from '../../records/profile';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { when } from '../../helpers/render';

class KidLocationsHistoryHeader extends React.Component<{
  profile: ProfileRecord;
  onBack: () => void;
  onClickRefreshLocations: (profileUid: string, stopRefresh: () => any) => any;
  isLocationRuleEnable: boolean;
}> {
  state = {
    isRefreshing: false,
  };

  stopRefresh = () => {
    this.setState({ isRefreshing: false });
  };

  render() {
    const { profile, isLocationRuleEnable } = this.props;
    const { isRefreshing } = this.state;

    return (
      <PageTitle
        className="Header--isProfile"
        testId="pageTitle"
        size="small"
        profileInfoHeader={
          <ProfileInfoHeader
            name={profile.name}
            size="small"
            status={profile.status}
            statusMessage={mapProfileStatusToMessageStatus(profile.status)}
            avatar={<ProfileAvatar size={AvatarSize.small} profile={profile} />}
          />
        }
        title=""
        actions={[
          { actionName: 'back', onClick: this.props.onBack },
          isRefreshing
            ? {
                actionName: 'loading',
                icon: when(
                  isLocationRuleEnable,
                  <div className="KidLocationsHistory__container-loader">
                    <Loader
                      className="KidLocationsHistory__loader"
                      size=""
                      color=""
                    />
                  </div>
                ),
                onClick: () => {},
              }
            : {
                actionName: 'refresh',
                icon: when(
                  isLocationRuleEnable,
                  <CustomIcon
                    type={IconType.syncAlt}
                    testId="family-locator-refresh-location"
                  />
                ),
                onClick: () => {
                  this.setState({ isRefreshing: true });
                  this.props.onClickRefreshLocations(
                    profile.uid,
                    this.stopRefresh
                  );
                },
              },
        ]}
      />
    );
  }
}

export default KidLocationsHistoryHeader;
