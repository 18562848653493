import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import HowToAssignProfileToAccountModal from '../../components/Modal/HowToAssignProfileToAccountModal';

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
});

const HowToAssignProfileToAccountModalContainer = connect(
  null,
  mapDispatchToProps
)(HowToAssignProfileToAccountModal);

export default HowToAssignProfileToAccountModalContainer;
