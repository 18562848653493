import * as React from 'react';
import { t } from '../../lib/i18n';
import KidLocationsHistoryContainer from '../../containers/FamilyLocator/KidLocationsHistoryContainer';
import DynamicMap from '../DynamicMap/DynamicMap';
import FamilyLocatorContainer from '../../containers/FamilyLocator/FamilyLocatorContainer';
import FamilyLocatorHeaderContainer from '../../containers/FamilyLocator/FamilyLocatorHeaderContainer';
import KidLocationsHistoryHeaderContainer from '../../containers/FamilyLocator/KidLocationsHistoryHeaderContainer';
import { FamilyLocatorScreen } from '../../containers/FamilyLocator/types';
import { classNames } from '../../helpers';
import { isAddPlaceScreen } from '../../helpers/dynmap';
// tslint:disable-next-line:max-line-length
import KidLocationsHistoryItemHeaderContainer from '../../containers/FamilyLocator/KidLocationsHistoryItemHeaderContainer';
import AddPlaceContainer from '../../containers/FamilyLocator/AddPlaceContainer';
import flags from '../../sideEffects/flags';
import AddNewPlaceContainer from '../../containers/FamilyLocator/AddNewPlaceContainer';
import AddPlaceHeaderContainer from '../../containers/FamilyLocator/AddPlaceHeaderContainer';
import { Marker } from '../../records/marker';
import EditPlaceContainer from '../../containers/FamilyLocator/EditPlaceContainer';
import { FlexLayout, SafeArea } from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import PageSectionLayout from '../Layout/PageSectionLayout';

class MapWrapper extends React.Component<{
  markers: any;
  noEvents: boolean;
  activeScreen: FamilyLocatorScreen;
  profileId: number;
  eventKey: string;
  hasValidEventAddress: boolean;
  markersCoordinatesString: any;
  showPlaceholder: boolean;
  radius: number | undefined;
  isKeyboardVisible: boolean;
  hasFetchedAllProfileRules: boolean;
  isUsingImperialSystem: boolean;
  onClickPlaces: () => any;
  onClickDirections: (marker: Marker) => any;
}> {
  state = {
    mapReady: false,
    switchingToFullscreen: false,
    isMapResizing: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isKeyboardVisible } = this.props;
    if (
      [FamilyLocatorScreen.AddPlace, FamilyLocatorScreen.AddNewPlace].includes(
        nextProps.activeScreen
      ) &&
      isKeyboardVisible !== nextProps.isKeyboardVisible &&
      !nextProps.isKeyboardVisible
    ) {
      this.mapResizingHandler();
    }
  }

  mapReadyHandler = () => {
    this.setState({
      mapReady: true,
      switchingToFullscreen: false,
      isMapResizing: false,
    });
  };

  switchToFullScreenCallback = () => {
    this.setState({ switchingToFullscreen: true });
  };

  mapResizingHandler = () => {
    this.setState({ isMapResizing: true });
  };

  mapDidUpdateCallback = () => {
    const { switchingToFullscreen, isMapResizing } = this.state;
    if (switchingToFullscreen) {
      this.setState({ switchingToFullscreen: false });
    }
    if (isMapResizing) {
      this.setState({ isMapResizing: false });
    }
  };

  render() {
    const {
      markers,
      activeScreen,
      markersCoordinatesString,
      profileId,
      eventKey,
      hasValidEventAddress,
      showPlaceholder,
      radius,
      isKeyboardVisible,
      hasFetchedAllProfileRules,
      isUsingImperialSystem,
      noEvents,
      onClickPlaces,
      onClickDirections,
    } = this.props;

    const { switchingToFullscreen, isMapResizing, mapReady } = this.state;

    return (
      <PageLayout
        configuration={[
          {
            screenSize: ScreenSize.All,
            columns: 1,
            disableScroll: true,
          },
        ]}
      >
        <PageSectionLayout placeholder="page-title">
          {activeScreen === FamilyLocatorScreen.ProfilesList && (
            <FamilyLocatorHeaderContainer />
          )}

          {activeScreen === FamilyLocatorScreen.KidTimeline && (
            <KidLocationsHistoryHeaderContainer params={{ profileId }} />
          )}

          {isAddPlaceScreen(activeScreen) && (
            <AddPlaceHeaderContainer
              title={
                FamilyLocatorScreen.EditPlace === activeScreen
                  ? t('Place details')
                  : t('Add a place')
              }
              showDeleteButton={FamilyLocatorScreen.EditPlace === activeScreen}
              placeUid={
                FamilyLocatorScreen.EditPlace === activeScreen
                  ? markers.first().id
                  : undefined
              }
            />
          )}

          {activeScreen === FamilyLocatorScreen.MaximizedMap && (
            <KidLocationsHistoryItemHeaderContainer
              params={{
                marker: markers.last(),
                backClickCallback: this.mapResizingHandler,
              }}
            />
          )}
        </PageSectionLayout>
        <PageSectionLayout side="main">
          <FlexLayout
            mainaxis="column"
            position="relative"
            className="MapWrapper"
            height="100%"
            minHeight="0"
          >
            <DynamicMap
              markers={markers}
              fullMap={
                activeScreen === FamilyLocatorScreen.MaximizedMap ||
                switchingToFullscreen
              }
              showPlaceholder={
                showPlaceholder ||
                isKeyboardVisible ||
                switchingToFullscreen ||
                isMapResizing
              }
              isDirectionIconEnabled={[
                FamilyLocatorScreen.ProfilesList,
                FamilyLocatorScreen.KidTimeline,
              ].includes(activeScreen)}
              markersCoordinatesString={markersCoordinatesString}
              onClickPlaces={onClickPlaces}
              onReady={this.mapReadyHandler}
              didUpdateCallback={this.mapDidUpdateCallback}
              activeScreen={activeScreen}
              onClickDirections={onClickDirections}
              radius={radius}
              isUsingImperialSystem={isUsingImperialSystem}
              hideMap={isKeyboardVisible && isAddPlaceScreen(activeScreen)}
              shouldRender={
                hasFetchedAllProfileRules ||
                ![
                  FamilyLocatorScreen.ProfilesList,
                  FamilyLocatorScreen.KidTimeline,
                ].includes(activeScreen)
              }
            />

            <FlexLayout
              mainaxis="column"
              flexGrow="2"
              height="100%"
              minHeight="0"
              scrollY
              className={classNames(
                'MapWrapper__sibling',
                mapReady ? 'MapWrapper__sibling--lifted' : '',
                noEvents
                  ? 'MapWrapper__sibling--full-screen'
                  : 'MapWrapper__sibling--lowered'
              )}
            >
              {activeScreen === FamilyLocatorScreen.ProfilesList && (
                <FamilyLocatorContainer />
              )}

              {[
                FamilyLocatorScreen.AddNewPlace,
                FamilyLocatorScreen.AddPlace,
              ].includes(activeScreen) && (
                <AddPlaceContainer
                  params={{
                    eventKey,
                    mapResizingHandler: this.mapResizingHandler,
                    historyStepsBack:
                      activeScreen === FamilyLocatorScreen.AddPlace ? 2 : 1,
                  }}
                />
              )}

              {FamilyLocatorScreen.EditPlace === activeScreen && (
                <EditPlaceContainer
                  params={{
                    placeUid: markers.first().id,
                    mapResizingHandler: this.mapResizingHandler,
                    historyStepsBack: 1,
                  }}
                />
              )}

              {activeScreen === FamilyLocatorScreen.KidTimeline && (
                <KidLocationsHistoryContainer
                  params={{
                    profileId,
                    switchToFullScreenCallback: this.switchToFullScreenCallback,
                  }}
                />
              )}
            </FlexLayout>

            {activeScreen === FamilyLocatorScreen.MaximizedMap &&
              flags.geofencing.isEnabled() &&
              hasValidEventAddress && (
                <AddNewPlaceContainer
                  params={{
                    mapResizingHandler: this.mapResizingHandler,
                  }}
                />
              )}
            <SafeArea />
          </FlexLayout>
        </PageSectionLayout>
      </PageLayout>
    );
  }
}

export default MapWrapper;
