import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import State, { Dispatch } from '../../../store/state';
import DeleteRoutineModal from '../../../components/Routines/Modals/DeleteRoutineModal';
import { getActiveProfileUid, getQueryParam } from '../../../selectors';
import { deleteRoutine } from '../../../ducks/routines';
import { getRoutine } from '../../../ducks/routines/selectors';

const mapDispatchToProps = (dispatch: Dispatch) => {
  const back = () => dispatch(goBackIfHistory());
  return {
    onClickDelete: (profileUid: string, routineUid: string) => {
      dispatch(deleteRoutine(profileUid, routineUid));
      back();
    },
    onClickClose: back,
  };
};

const mapStateToProps = (state: State) => {
  const profileUid = getActiveProfileUid(state);
  const routineUid = getQueryParam(state, 'routineUid');
  return {
    profileUid,
    routineUid,
    routineName: routineUid ? getRoutine(state, routineUid).name : '',
  };
};

const DeleteRoutineModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRoutineModal);

export type DeleteRoutineModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default DeleteRoutineModalContainer;
