import * as React from 'react';
import { connect } from 'react-redux';
import { t } from '../../lib/i18n';

import { isBrowserPlatform } from '../../helpers';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { seeLocationsClickHandler } from '../../actions/FamilyLocatorActions';

const ActivityTimelineItemTopRightComponent: React.FunctionComponent<{
  shouldRender: boolean;
  handleClick: (event) => any;
}> = ({ shouldRender, handleClick }) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <div className="ActivityTimelineItemTopRight">
      <div>
        <a onClick={handleClick}>{t('See locations')}</a>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  shouldRender: getFeatureFlag(state, 'familyLocator') && !isBrowserPlatform(),
});

const mapDispatchToProps = (dispatch, { profileId }) => ({
  handleClick: event => {
    event.preventDefault();
    event.stopPropagation();
    return dispatch(seeLocationsClickHandler(profileId));
  },
});

const ActivityTimelineItemTopRight = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTimelineItemTopRightComponent);

export default ActivityTimelineItemTopRight;
