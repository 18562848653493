import * as React from 'react';
import {
  FlexLayout,
  FreeStyleListItem,
  InstructionBlock,
  List,
  StyledInstruction,
} from 'styleguide-react';

const OrderedList: React.FunctionComponent<{
  items: string[];
  className?: string;
}> = ({ items }) => (
  <List listItemPaddingBottom="8" listItemPaddingTop="8">
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <FreeStyleListItem key={index}>
        <FlexLayout mainaxis="row" crossaxisAlignment="flex-start">
          <FlexLayout mainaxis="column" marginRight="8">
            <InstructionBlock>
              <StyledInstruction color="primary">{`${
                index + 1
              } - `}</StyledInstruction>
            </InstructionBlock>
          </FlexLayout>
          <InstructionBlock className="OrderedListItem__text">
            {item}
          </InstructionBlock>
        </FlexLayout>
      </FreeStyleListItem>
    ))}
  </List>
);

export default OrderedList;
