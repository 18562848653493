/* eslint-disable react/jsx-props-no-spreading */
// Too many props to write them one by one

import * as React from 'react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayout from '../Layout/PageLayout';
import FlyoverContent from './FlyoverContent';
import type { PageTitleActionElementProps } from '../Layout/PageTitleLayout';

export interface FlyoverProps {
  onClickClose?: () => void;
  description: string | JSX.Element;
  canClose?: boolean;
  title?: string;
  illustrationSrc: string;
  button: JSX.Element;
  bottomContent?: JSX.Element;
  actions?: PageTitleActionElementProps[];
}
const Flyover: React.FunctionComponent<FlyoverProps> = ({
  title,
  actions,
  ...restProps
}: FlyoverProps) => (
  <PageLayout
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        title,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 1,
        mainInsideCard: true,
        hideHeader: true,
        fullHeightCard: true,
      },
      { screenSize: ScreenSize.Mobile, columns: 1, hideHeader: true },
    ]}
    actions={actions}
  >
    <RenderWhen screenSize={ScreenSize.Mobile}>
      <FlyoverContent
        title={title}
        actions={actions}
        {...restProps}
        alignement="space-between"
      />
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Tablet}>
      <FlyoverContent title={title} actions={actions} {...restProps} />
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlyoverContent
        title={title}
        actions={actions}
        {...restProps}
        alignement="center"
      />
    </RenderWhen>
  </PageLayout>
);

export default Flyover;
