import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  shouldShowConversionElements,
  LicenseRecord,
} from '../../records/license';
import {
  Modal,
  Button,
  ButtonSize,
  FlexLayout,
  Layout,
  Typography,
} from 'styleguide-react';
import type {
  DeviceOverLimitModalActionType,
  DeviceOverLimitModalProps,
} from '../../containers/Modal/DeviceOverLimitModalContainer';
import {
  isFree as isFreeSubtype,
  isPremiumBasic,
  isPremiumComplete,
  isPremiumGrandfather,
  isSchoolFree,
} from '../../records/license/helpers';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import shieldWithRocketIcon from '../../assets/base/images/ShieldWithRocket.png';
import { LicenseSubtype } from '../../records/license/types/License.types';

const isFree = (licenseSubtype: LicenseSubtype) => {
  return isFreeSubtype(licenseSubtype) || isSchoolFree(licenseSubtype);
};

const getTitle = (license: LicenseRecord, hasAvailableProducts: boolean) => {
  if (!shouldShowConversionElements(license)) return t('Device limit reached');
  if (isFree(license.subtype)) return t('Device limit reached');
  if (isPremiumBasic(license.subtype)) return t('Expand your plan');
  if (isPremiumComplete(license.subtype)) return t('Protect more devices');
  if (isPremiumGrandfather(license.subtype)) {
    if (hasAvailableProducts) {
      return t('Expand your plan');
    }
    return t('Device limit reached');
  }
  return null;
};

const getBody = (license: LicenseRecord, canPurchase: boolean) => {
  if (!shouldShowConversionElements(license)) {
    return (
      <React.Fragment>
        {t(
          'Your current plan limits you to core protection for up to {{maxDevices}} devices.',
          { maxDevices: license.maxDevices }
        )}
      </React.Fragment>
    );
  }
  if (isFree(license.subtype)) {
    return (
      <React.Fragment>
        {t('Your plan limits protection to 1 device.')}
        <Layout marginTop="8">
          {t(
            'Upgrade to Premium to protect all your devices with advanced features.'
          )}
        </Layout>
      </React.Fragment>
    );
  }
  if (isPremiumBasic(license.subtype)) {
    return (
      <React.Fragment>
        {t(
          'Your current plan limits you to core protection for up to {{maxDevices}} devices.',
          { maxDevices: license.maxDevices }
        )}
        <Layout marginTop="8">
          {canPurchase
            ? t(
                'Expand your plan to access advanced safety features for an unlimited number of devices.'
              )
            : t(
                'Contact our support team to expand your plan and access advanced safety features for an unlimited number of devices.'
              )}
        </Layout>
      </React.Fragment>
    );
  }
  if (isPremiumComplete(license.subtype)) {
    return (
      <React.Fragment>
        {t(
          'Qustodio is designed exclusively for families. If you need to protect more than 25 devices, please contact our support team to verify your requirements.'
        )}
      </React.Fragment>
    );
  }
  if (isPremiumGrandfather(license.subtype)) {
    return (
      <React.Fragment>
        {t(
          'Your current plan limits you to core protection for up to {{maxDevices}} devices.',
          { maxDevices: license.maxDevices }
        )}
        {!canPurchase && (
          <Layout marginTop="8">
            {t(
              'Contact our support team to expand your plan and protect your whole family with advanced safety features.'
            )}
          </Layout>
        )}
      </React.Fragment>
    );
  }

  return null;
};

const getActionType = (
  license: LicenseRecord,
  canPurchase: boolean,
  hasAvailableProducts: boolean
): DeviceOverLimitModalActionType => {
  if (!shouldShowConversionElements(license)) return 'ok';
  if (isFree(license.subtype)) return 'upgrade';
  if (isPremiumBasic(license.subtype)) return canPurchase ? 'upgrade' : 'ok';
  if (isPremiumComplete(license.subtype)) return 'support';
  if (isPremiumGrandfather(license.subtype)) {
    if (!canPurchase) return 'ok'; // PASx
    return hasAvailableProducts ? 'upgrade' : 'support';
  }
  return null;
};

const getActionCopy = (type: DeviceOverLimitModalActionType) => {
  if (type === 'upgrade') return t('Upgrade now');
  if (type === 'support') return t('Contact support');
  return t('Ok');
};

const DeviceOverLimitModal = ({
  license,
  canPurchase,
  hasAvailableProducts,
  onClickFooterButton,
  onClickClose,
}: DeviceOverLimitModalProps) => {
  const actionType = getActionType(license, canPurchase, hasAvailableProducts);
  return (
    <RenderWhen screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}>
      {screenSize => (
        <Modal
          size="medium"
          animationEnabled
          onClickClose={onClickClose}
          buttons={[
            <Button
              key="button"
              size={ButtonSize.medium}
              onClick={() => onClickFooterButton(actionType)}
              testId="primary-action"
              block
            >
              {getActionCopy(actionType)}
            </Button>,
          ]}
        >
          <FlexLayout
            height="100%"
            mainaxis="column"
            mainaxisAlignment="center"
          >
            <Layout textAlignement="center" marginTop="32" marginBottom="32">
              <img
                src={shieldWithRocketIcon}
                width="154px"
                alt="qustodio-shield"
              />
            </Layout>

            <Typography type="h5" weight="semibold">
              {getTitle(license, hasAvailableProducts)}
            </Typography>

            <FlexLayout mainaxis="column" crossaxisAlignment="center">
              <Layout
                maxWidth={screenSize === ScreenSize.Mobile ? undefined : '75%'}
              >
                <Layout marginBottom="16">
                  {getBody(license, canPurchase)}
                </Layout>
              </Layout>
            </FlexLayout>
          </FlexLayout>
        </Modal>
      )}
    </RenderWhen>
  );
};

export default DeviceOverLimitModal;
