import * as R from 'ramda';
import { getLicense } from '../selectors/license';
import { isBrowserPlatform } from '../helpers';
import {
  getParentDeviceId,
  getParentDevice,
  isLoggingOut,
  isAuthenticated,
} from '../selectors';
import { removeParentDevice } from '../ducks/parentDevice';
import * as qinit from '../qinit';
import { BaseThunk } from '../store/state';
import {
  IS_FRESHLY_LOGGED_OUT,
  CLEAR,
  SET_IS_LOGGING_OUT,
} from '../ducks/actionNames/app';
import {
  redirectToLoginForStandaloneApps,
  redirectToLogout,
} from '../ducks/app';
import { resetBraze } from './globals';
import { isSSOEnabled } from '../helpers/sso';
import { captureException } from '../helpers/sentry';
import {
  getMultiPlatformNavigation,
  RouterAction,
} from '../helpers/multiPlatformNavigation';

export const clearOnLogout = (): BaseThunk<void> => (dispatch, getState) => {
  dispatch(
    // eslint-disable-next-line no-underscore-dangle
    clear(
      getState().filter((_value, key) =>
        R.contains(key, ['routing', 'persistentStorage'])
      )
    )
  );
};

type LogoutOptions = {
  showErrMsgInLogoutPageForWeb: boolean;
};

export const logout =
  (
    options: LogoutOptions = { showErrMsgInLogoutPageForWeb: false }
  ): BaseThunk<any> =>
  dispatch => {
    return dispatch(
      isBrowserPlatform()
        ? logoutBrowser(options.showErrMsgInLogoutPageForWeb)
        : resetApp()
    );
  };

const logoutBrowser =
  (showErrMsgInLogoutPage: boolean): BaseThunk<Promise<any>> =>
  (dispatch, getState) => {
    const license = getLicense(getState()).type;

    if (isSSOEnabled()) {
      dispatch(redirectToLogout(license, showErrMsgInLogoutPage));
    } else {
      const navigate = getMultiPlatformNavigation();
      dispatch(
        navigate({
          type: 'inner',
          src: `/login/${license}`,
        })
      );
    }

    return Promise.resolve();
  };

const resetApp =
  (): BaseThunk<Promise<any> | RouterAction> => async (dispatch, getState) => {
    const state = getState();
    if (isLoggingOut(state)) {
      return Promise.resolve();
    }
    dispatch(setIsLoggingOut());

    if (isAuthenticated(state)) {
      try {
        await dispatch(removeParentDevice());
      } catch (error) {
        const parentDevice = getParentDevice(state);
        captureException(error, {
          extra: {
            parentDeviceId: getParentDeviceId(state),
            parentDevice: parentDevice ? parentDevice.toJS() : null,
          },
        });
      }
    }

    await dispatch(clearOnLogout());
    resetBraze();
    return dispatch(redirectToLoginForStandaloneApps());
  };

export const fetchFamilyPortalLogout =
  (): BaseThunk<Promise<any>> => dispatch =>
    dispatch(() =>
      fetch(qinit.tenant.common.dashboard.logout_link).catch(() => {
        // This catch block avoids reporting the CORS error to Sentry
      })
    );

export const setIsLoggingOut = () => {
  return {
    type: SET_IS_LOGGING_OUT,
  };
};

export const markAsFreshlyLoggedOut = () => {
  return {
    type: IS_FRESHLY_LOGGED_OUT,
  };
};

export const clear = (payload = {}) => {
  return {
    type: CLEAR,
    payload,
  };
};
