import * as React from 'react';
import { classNames } from '../../../helpers';
import {
  FlexLayout,
  Icon,
  IconAdapter,
  IconType,
  Layout,
  ListItemTopPadding,
  ListSubtitle,
  ListTitle,
  Quote,
  Tag,
} from 'styleguide-react';

interface ActivityStyledItemProps {
  icon: JSX.Element | string;
  iconAltText?: string;
  title: string;
  upperSubtitle?: string | JSX.Element;
  lowerSubtitle?: string | JSX.Element;
  rightText?: string;
  tags?: ReturnType<typeof Tag>[];
  quoteText?: string;
  actionElement?: JSX.Element;
  className?: string;
  isSchoolTime?: boolean;
  onClick?: (ev: React.SyntheticEvent) => void;
}

const ActivityStyledItem: React.FunctionComponent<ActivityStyledItemProps> = ({
  icon,
  iconAltText,
  title,
  upperSubtitle,
  lowerSubtitle,
  rightText,
  tags,
  quoteText,
  className = '',
  actionElement,
  isSchoolTime = false,
}) => {
  return (
    <div
      className={classNames(
        'par-activity-styled-item',
        `par-activity-styled-item--${ListItemTopPadding.large}`,
        isSchoolTime && 'par-activity-styled-item--school-time',
        className
      )}
      data-testid="ActivityStyledItem__Container"
    >
      <div
        className="par-activity-styled-item__left"
        data-testid="ActivityStyledItem__Icon"
      >
        <IconAdapter icon={icon} altText={iconAltText} />
      </div>
      <div className="par-activity-styled-item__middle-left">
        {upperSubtitle &&
          (typeof upperSubtitle === 'string' ? (
            <ListSubtitle
              className="par-activity-styled-item__subtitle"
              testId="ActivityStyledItem__Middle-subtitle"
            >
              {upperSubtitle}
            </ListSubtitle>
          ) : (
            React.cloneElement(upperSubtitle, {
              className: 'par-activity-styled-item__subtitle',
            })
          ))}
        <ListTitle
          className="par-activity-styled-item__title"
          testId="ActivityStyledItem__Title"
        >
          {title}
        </ListTitle>
        {!!tags && !!tags.length && (
          <FlexLayout
            mainaxis="row"
            className="par-activity-styled-item__tag-container"
          >
            {React.Children.map(tags, child => {
              const tag = child as React.ReactElement<typeof Tag>;
              if (tag === null) {
                return null;
              }
              return tag;
            })}
          </FlexLayout>
        )}
        {lowerSubtitle &&
          (typeof lowerSubtitle === 'string' ? (
            <ListSubtitle
              className="par-activity-styled-item__subtitle"
              testId="ActivityStyledItem__Lower-subtitle"
            >
              {lowerSubtitle}
            </ListSubtitle>
          ) : (
            React.cloneElement(lowerSubtitle, {
              className: 'par-activity-styled-item__subtitle',
            })
          ))}
        {quoteText && (
          <Quote
            className="par-activity-styled-item__quote"
            testId="ActivityStyledItem__Quote"
          >
            <Layout renderAs="span" display="inline-block" paddingRight="8">
              <Icon type={IconType.search} color="gray" />
            </Layout>
            {quoteText}
          </Quote>
        )}
      </div>
      <div className="par-activity-styled-item__right">
        {rightText && (
          <p className="par-activity-styled-item__right__text">{rightText}</p>
        )}
        {actionElement && (
          <div
            className="par-activity-styled-item__right__action-element"
            data-testid="ActivityStyledItem__Action"
          >
            {actionElement}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityStyledItem;
