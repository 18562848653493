/* eslint-disable global-require */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const qinit = require('../qinit');

if (
  (process.env.NODE_ENV !== 'production' || qinit.verbose) &&
  process.env.NODE_ENV !== 'testing'
) {
  module.exports = require('./configureStore.dev');
} else {
  module.exports = require('./configureStore.prod');
}
