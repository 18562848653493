import * as moment from 'moment';
import { Map } from 'immutable';
import { DATE_FORMAT_HOUR } from '../../../helpers/dates';
import { TimeSlot } from '../../../components/Routines/routines.types';
import { ScheduleRecord } from '../../../records/routines/schedule/types/Schedule.types';
import { ScheduleOperations } from '../../../records/routines/schedule/schedule';
import { calculateDurationAsMinutes } from '../../../businessLogic/routines/routines';

/** Transforms an array to an immutable Map with the option of choosing a key from the data object as an index. */
export const normalizeRoutinesData = <T, K extends keyof T>(
  data: Array<T>,
  keyAsIndex: K
): Map<string, T> =>
  Map({
    ...data.reduce(
      (acc, item) => ({ ...acc, [item[keyAsIndex] as K]: item }),
      {}
    ),
  });

export const denormalizeRoutinesData = <T>(
  normalizedData: Map<string, T>
): Array<T> => normalizedData.valueSeq().toArray();

export const calculateTimeSlotFrom = (data: ScheduleRecord) => {
  return data?.startTime
    ? moment(data.startTime, 'HH:mm:ss').format(DATE_FORMAT_HOUR)
    : undefined;
};

export const calculateTimeSlotTo = (data: ScheduleRecord) => {
  const startTime = moment(data?.startTime, DATE_FORMAT_HOUR);
  const endTime = startTime.add(data?.durationMinutes, 'minutes');
  return endTime.format(DATE_FORMAT_HOUR);
};

export const timeSlotToSchedule = (timeSlot: TimeSlot): ScheduleRecord => {
  return ScheduleOperations.create({
    uid: timeSlot.uid,
    routineUid: timeSlot.routineUid,
    weekdays: timeSlot.days,
    startTime: timeSlot.from,
    durationMinutes: calculateDurationAsMinutes(timeSlot.from, timeSlot.to),
  });
};
