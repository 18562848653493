import * as React from 'react';
import { connect } from 'react-redux';
import { getAccount } from '../../selectors';
import { formatDateIncludingHours } from '../../helpers/dates';

const mapStateToProps = (state, props) => ({
  ...props,
  ...getAccount(state).toJS(),
});

const DateFormat: React.FunctionComponent<{
  time: string;
  dateFormatCallback: (
    time: string,
    timezone: string,
    locale?: string
  ) => string;
  timezone: string;
  locale?: string;
  className?: string;
}> = ({
  time,
  dateFormatCallback = formatDateIncludingHours,
  timezone,
  locale,
  className,
}) => (
  <span className={className}>
    {dateFormatCallback(time, timezone, locale)}
  </span>
);

const Date = connect(mapStateToProps, null)(DateFormat);

export default Date;
