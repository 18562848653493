import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import { getLicense } from '../../selectors';
import * as qinit from '../../qinit';
import ExpandPlanModal from '../../components/Modal/ExpandPlanModal';
import { isBrowserPlatform, isiOSPlatform } from '../../helpers';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => ({
  license: getLicense(state),
  shouldShowContactInfo: !isiOSPlatform(),
});

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(goBackIfHistory()),
  onClickLink: () => {
    const navigate = getMultiPlatformNavigation();
    if (isBrowserPlatform()) {
      return dispatch(navigate({ type: 'inner:replace', src: '/upgrade' }));
    }
    dispatch(goBackIfHistory());
    return dispatch(
      navigate({
        type: 'external:public:site',
        src: qinit.tenant.marketing.public_site.help_contact,
        openInIOS: false,
      })
    );
  },
});

const ExpandPlanModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpandPlanModal);

export default ExpandPlanModalContainer;
