import * as debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import {
  eventHasIrregularStatus,
  getAccount,
  getAccountCurrentTime,
  getActivityTimelineFilterBy,
  getAppRuleApplicationByEvent,
  getEventDays,
  getEvents,
  getLicense,
  getLocation,
  getLocationRulesEnabled,
  getProfileDesktopDelegatedDevices,
  getProfileLastLocation,
  getProfileOrDefault,
  getRouteParams,
  getTimezone,
  isFetchingNewEvents,
  isProfileLinkedWithLinewizeStudent,
  showFetchingMore,
} from '../../selectors';
import ActivityTimeline from '../../components/ActivityTimeline/ActivityTimeline';
import {
  setFilter,
  shouldFetchMoreEvents,
  showEventActions,
} from '../../ducks/events';

import { EventType } from '../../constants';
import { isFree } from '../../records/license';
import { EventRecord } from '../../records';
import ft, { DISABLE_URL_MONITORING } from '../../lib/ft';
import { isBrowserPlatform, wrapStopPropagation } from '../../helpers';
// noinspection ES6PreferShortImport Simplifying this import breaks spying on gaEvent @ tests
import { gaEvent } from '../../helpers/ga';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
  trackTimelineCardClick,
} from '../../helpers/analytics';
import { refreshLastLocation } from '../../ducks/profiles';
import { RouteActions } from '../FamilyLocator/types';
import flags from '../../sideEffects/flags';
import { locationPushAndRetryWith } from '../../actions/location';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { isSafeNetwork } from '../../records/event';
import {
  getAppKeyFromEvent,
  isBlockableEvent,
} from '../../businessLogic/events/events';
import {
  navigateToActivityTimelineDelegationInfoModal,
  navigateToFamilyTimeline,
  navigateToNonBlockableAppModalFrom,
  navigateToSafetyNetInfoModal,
} from '../../actions/Navigation';
import State, { BaseThunk } from '../../store/state';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  getSummaryAlertType,
  isEnabledSummaryAlertsFeature,
} from '../../businessLogic/summaryAlerts';
import { ActivityEventFilters } from '../../businessLogic/timeline/types';
import { getTimelineFilterTrackingParams } from '../TimelineSelector/TimelineSelectorTracking';
import { getSummaryAlertCounter } from '../../ducks/summaryAlerts';
import { navigateToYoutubeAppRules } from '../../actions/YoutubeRuleActions';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { isEventPremiumYoutube } from '../../components/ActivityTimeline/activityTimelineHelpers';
import { isSchoolAccount } from '../../businessLogic/account';

const MAX_NUMBER_OF_EVENTS_REQUESTED = 20;

const isDelegatedWithPCDevices = (state: State, profileId: number) => {
  const linkedToSchool = isSchoolAccount(state);
  return (
    !getProfileDesktopDelegatedDevices(state, profileId).isEmpty() &&
    linkedToSchool
  );
};

const clickEvent = (event: EventRecord, profileId: number): BaseThunk => {
  return (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();

    trackTimelineCardClick(event);
    const profile = getProfileOrDefault(getState(), profileId);
    const license = getLicense(getState());
    const hasPremiumRuleAccess = !isFree(license.type);
    switch (event.type) {
      case EventType.App:
        dispatch(clickAppEvent(event));
        break;
      case EventType.Youtube:
        dispatch(clickVideoEvent(event, profile));
        break;
      case EventType.Web:
        dispatch(clickWebEvent(event, profile));
        break;
      case EventType.CallsIncoming:
      case EventType.CallsOutgoing:
      case EventType.CallsMissed:
      case EventType.CallsUnanswered:
      case EventType.CallsBlockedIncoming:
      case EventType.CallsBlockedOutgoing:
      case EventType.SmsIncoming:
      case EventType.SmsOutgoing:
      case EventType.SmsBlockedIncoming:
      case EventType.SmsBlockedOutgoing:
        if (!hasPremiumRuleAccess) {
          dispatch(
            navigate({
              type: 'inner',
              src: `/profiles/${profile.id}/rules/callsSMSRules/premiumFlyover`,
            })
          );
        } else {
          dispatch(clickCallsSmsEvent(event));
        }
        break;
      case EventType.Location:
      case EventType.LocationUnvailable:
      case EventType.LocationLast: {
        const action = getLocationEventAction(
          event,
          profile,
          hasPremiumRuleAccess
        );
        action && dispatch(action);
        break;
      }
      case EventType.Panic:
        if (!hasPremiumRuleAccess) {
          dispatch(
            navigate({
              type: 'inner',
              src: `/profiles/${profileId}/rules/panicButtonRules/premiumFlyover`,
            })
          );
        } else {
          dispatch(clickPanicEvent(event));
        }
        break;
      case EventType.SafeNetworkDisablingProtection:
      case EventType.SafeNetworkEnablingProtection:
        dispatch(clickSafeNetworkEvent(event, profile));
        break;
      case EventType.Search:
        dispatch(clickSearchEvent(event, profile));
        break;
      default:
        break;
    }
  };
};

const getLocationEventAction = (
  event: EventRecord,
  profile: ProfileRecord,
  isPremium: boolean
) => {
  const navigate = getMultiPlatformNavigation();

  if (eventHasIrregularStatus(event.location)) {
    return null;
  }

  if (!isPremium) {
    return navigate({
      type: 'inner',
      src: `/profiles/${profile.id}/rules/locationRules/premiumFlyover`,
    });
  }

  if (!isBrowserPlatform() && flags.familyLocator.isEnabled()) {
    return navigate({
      type: 'inner',
      src: `/familyLocator/${profile.id}/location/${event.key}/action/${RouteActions.fullMap}`,
    });
  }

  return navigate({
    type: 'map:location',
    label: profile.name,
    src: event.location,
  });
};

const clickLabelIconEvent = event => dispatch => {
  trackTimelineCardClick(event);
  dispatch(showEventActions(event));
};

export const clickVideoEvent =
  (event: EventRecord, profile: ProfileRecord): BaseThunk =>
  (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    const license = getLicense(getState());
    const isPCDelegated = isDelegatedWithPCDevices(getState(), profile.id);
    if (isEventPremiumYoutube(license, event, isPCDelegated)) {
      return dispatch(navigateToYoutubeAppRules(profile.id));
    }

    gaEvent('activity-youtube-timeline', 'youtube-video', 'click');
    dispatch(
      navigate({
        type: 'inner',
        src: {
          pathname: `/profiles/${profile.id}/url-details/${encodeURI(
            event.key
          )}`,
        },
      })
    );
  };

export const clickSafeNetworkEvent = (event, profile) => dispatch => {
  const navigate = getMultiPlatformNavigation();

  gaEvent('safe-network-timeline', 'safe-network-event-item', 'click');
  dispatch(
    navigate({
      type: 'inner',
      src: {
        pathname: `/profiles/${profile.id}/url-details/${encodeURI(event.key)}`,
      },
    })
  );
};

export const clickWebEvent = (event, profile) => dispatch => {
  const navigate = getMultiPlatformNavigation();

  if (ft.active(DISABLE_URL_MONITORING)) {
    dispatch(showEventActions(event));
  } else {
    gaEvent('activity-web-timeline', 'domain-name', 'click');
    dispatch(
      navigate({
        type: 'inner',
        src: {
          pathname: `/profiles/${profile.id}/url-details/${encodeURI(
            event.key
          )}`,
        },
      })
    );
  }
};

export const clickAppEvent = event => dispatch => {
  dispatch(showEventActions(event));
};

export const clickCallsSmsEvent = event => dispatch => {
  dispatch(showEventActions(event));
};

const clickPanicEvent = event => dispatch => {
  dispatch(showEventActions(event));
};

export const clickSearchEvent =
  (event: EventRecord, profile: ProfileRecord): BaseThunk =>
  (dispatch, getState) => {
    const license = getLicense(getState());
    const isPCDelegated = isDelegatedWithPCDevices(getState(), profile.id);
    if (isEventPremiumYoutube(license, event, isPCDelegated)) {
      return dispatch(navigateToYoutubeAppRules(profile.id));
    }
  };

const mapStateToProps = (state, { profile }) => {
  const timezone = getTimezone(state);
  const account = getAccount(state);
  const isFetchingAny =
    state.getIn(['profiles', 'isFetching']) ||
    state.getIn(['summary', 'isFetching']) ||
    state.getIn(['events', 'isFetching']);
  const eventCount = getEvents(state).size;

  const hasDelegationEnabled = isDelegationEnabled(state);
  const delegatedToThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  const isDelegatedToTheAccount = isProfileDelegatedToThisAccount(
    hasDelegationEnabled,
    delegatedToThisAccount,
    isProfileLinkedWithLinewizeStudent(state, profile.id)
  );

  const alertCounter = getSummaryAlertCounter(state, profile.uid, 'searches');

  return {
    currentTime: getAccountCurrentTime(state),
    license: getLicense(state),
    profile,
    timezone,
    locale: account.locale,
    lastLocation: getProfileLastLocation(state, profile.id),
    days: getEventDays(state, profile.id),
    hasNoEvents: !isFetchingAny && eventCount === 0,
    isFetching: isFetchingAny,
    isRefreshingLastLocation: isFetchingNewEvents(state),
    showFetchingMore: showFetchingMore(state, MAX_NUMBER_OF_EVENTS_REQUESTED),
    filterBy: getActivityTimelineFilterBy(state),
    isLocationRuleEnable: getLocationRulesEnabled(state, profile.id),
    isEventBlockable: event => {
      const app = getAppRuleApplicationByEvent(state, profile.id, event);
      if (!app) return true; // to keep previous behavior
      return isBlockableEvent(event, app);
    },
    isDelegatedToTheAccount,
    isEnabledSummaryAlertsFeature: isEnabledSummaryAlertsFeature(state),
    getEventAlertType: getSummaryAlertType(alertCounter),
    isDelegatedWithPCDevices: isDelegatedWithPCDevices(state, profile.id),
  };
};

const handleRefreshLastLocation = debounce(
  (dispatch, profileId) => {
    return dispatch(
      locationPushAndRetryWith(refreshLastLocation(profileId), {
        profileId,
        retryTime: 1500,
        retryIntents: 3,
      })
    );
  },
  600,
  {
    leading: true,
    trailing: false,
  }
);

const handleChangeActiveFilterThunk = (): BaseThunk => (dispatch, getState) => {
  const filter = getRouteParams(getState()).get('filter');

  if (filter) {
    dispatch(setFilter(filter as ActivityEventFilters));
  }
};
/**
 * Update the filter in the URL.
 * For the needs of using the filter in the URL, if it is detected that there is a consistency filter,
 * it should be updated when the filter changes in the selector.
 */
export const handleUpdateRouteThunk =
  (updatedFilter: ActivityEventFilters, profileId: string): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const filterInUrl = getRouteParams(state).get('filter');

    if (!profileId) {
      return;
    }

    if (filterInUrl) {
      dispatch(
        navigateToFamilyTimeline({
          profileId,
          replaceNavigation: true,
          filter: updatedFilter,
        })
      );
    }
  };

const trackFilterChangedThunk =
  (filter: ActivityEventFilters): BaseThunk =>
  (_dispatch, getState) => {
    const state = getState();
    const location = getLocation(state);
    const isInTimelinePage = location.pathname.includes('activity-timeline');

    trackButtonClicked(
      isInTimelinePage ? GenericPageNames.Timeline : GenericPageNames.Profile,
      ButtonNames.ChangeFilter,
      getTimelineFilterTrackingParams(filter)
    );
  };

const mapDispatchToProps = (dispatch, { profile }) => ({
  onClickRefreshLastLocation: () =>
    handleRefreshLastLocation(dispatch, profile.id),
  onClick: event => dispatch(clickEvent(event, profile.id)),
  onClickLabelIcon: event =>
    (event.type === EventType.Web && !ft.active(DISABLE_URL_MONITORING)) ||
    event.type === EventType.Youtube ||
    isSafeNetwork(event)
      ? wrapStopPropagation(() => dispatch(clickLabelIconEvent(event)))
      : () => dispatch(clickLabelIconEvent(event)),
  onChangeFilter: filter => {
    dispatch(handleUpdateRouteThunk(filter, profile.id));
    dispatch(setFilter(filter));
    dispatch(shouldFetchMoreEvents(parseInt(profile.id, 10), filter));
    dispatch(trackFilterChangedThunk(filter));
  },
  handleMore: () => dispatch(shouldFetchMoreEvents(parseInt(profile.id, 10))),
  onInfoClick: (event: EventRecord) => {
    const appKey = getAppKeyFromEvent(event);
    const profileId = String(profile.id);
    return dispatch(
      navigateToNonBlockableAppModalFrom('timeline', profileId, appKey)
    );
  },
  onLoad: () => {
    dispatch(handleChangeActiveFilterThunk());
  },
  onSafetyNetClick: () => {
    dispatch(navigateToSafetyNetInfoModal());
  },
  onDelegationInfoClick: () => {
    dispatch(navigateToActivityTimelineDelegationInfoModal());
  },
});

const ActivityTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTimeline);

export default ActivityTimelineContainer;
