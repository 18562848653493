import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import type { YoutubeAddDeviceFlyoverProps } from '../../containers/YoutubeRules/YoutubeAddDeviceFlyoverContainer';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';

const ADD_DEVICE_FLYOVER_TEST_ID = 'youtube-rules-add-device-flyover';

const YoutubeAddDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickAddDevice,
}: YoutubeAddDeviceFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('YouTube Monitoring') }}
      testId={ADD_DEVICE_FLYOVER_TEST_ID}
      topbarText={t('Protect a personal device to monitor YouTube')}
      title={t('What can you do with YouTube monitoring?')}
      imageSrc={`/illustrations/Youtube/premium_youtube-rules__${accountLocale}.png`}
      actionButton={{
        title: t('Add a device'),
        onClick: onClickAddDevice,
        testId: `${ADD_DEVICE_FLYOVER_TEST_ID}-upgrade`,
      }}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.search,
          title: t('View searches'),
          subtitle: t('See exactly what your child is searching for'),
        },
        {
          iconType: IconType.film,
          title: t('See what your child watched'),
          subtitle: t('Ensure they’re sticking to appropriate content'),
        },
        {
          iconType: IconType.stopWatch,
          title: t('Set healthy limits'),
          subtitle: t(
            'Encourage intentional viewing with time limits and blocks'
          ),
        },
      ]}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default YoutubeAddDeviceFlyover;
