import { fromJS, NonEmptyMapRecord } from 'immutable';
import flags, { Experiment } from '../sideEffects/flags';

export const defaultState = {
  familyLocator: false,
  blockUnsupportedBrowsers: false,
  isAlertNewApps: false,
  youtubeAndroidPlatformReporting: false,
  youtubeIOSPlatformReporting: false,
  optOutBanner: false,
  youtubeSummary: false,
  geofencing: false,
  calendarPicker: false,
  useSmartGeo: false,
  insights: false,
  upgradeToPremiumButton: Experiment.Original,
  adHocTrial: false,
  unifiedRouting: false,
  timeRulesSetScenario: false,
  appRulesSetScenario: false,
  youtubeRulesSetScenario: false,
  webFilterRulesSetScenario: false,
  isMontlyLicense: false,
  questionableWebSearches: Experiment.Original,
  extraTime: false,
  newAppRulesUI: false,
  webOnboardingToAndroidExperiment: Experiment.Original,
  iosPreConsentTrackingModal: false,
  kidDeviceSetupFromParentsApp: Experiment.Original,
  multiParentInitiative: false,
  dedicatedSupportV2: false,
  closeAccount: false,
  lockScreen: false,
  schoolPauseButton: false,
  familyPauseButton: false,
  schoolTimeline: false,
  useChargebeeV3PC2: false,
  showTopbarFreeFeatures: false,
  blockToFreePremiumFeatures: false,
  useUnifiedDashboard: false,
  useDelegation: false,
  useDelegationAutoOptin: false,
  tmpRoutineSwitch: false,
  switchPlansOnPASA: false,
  useAppCategories: false,
  tmpAdvanceAntiTampering: false,
};

export const SET_FLAGS = 'SET_FLAGS';

export interface Experiments {
  upgradeToPremiumButton: Experiment;
  questionableWebSearches: Experiment;
  webOnboardingToAndroidExperiment: Experiment;
  kidDeviceSetupFromParentsApp: Experiment;
}

export interface Flags {
  familyLocator: boolean;
  blockUnsupportedBrowsers: boolean;
  isAlertNewApps: boolean;
  youtubeAndroidPlatformReporting: boolean;
  youtubeIOSPlatformReporting: boolean;
  optOutBanner: boolean;
  youtubeSummary: boolean;
  geofencing: boolean;
  calendarPicker: boolean;
  useSmartGeo: boolean;
  insights: boolean;
  adHocTrial: boolean;
  unifiedRouting: boolean;
  timeRulesSetScenario: boolean;
  appRulesSetScenario: boolean;
  youtubeRulesSetScenario: boolean;
  webFilterRulesSetScenario: boolean;
  isMontlyLicense: boolean;
  extraTime: boolean;
  newAppRulesUI: boolean;
  iosPreConsentTrackingModal: boolean;
  multiParentInitiative: boolean;
  dedicatedSupportV2: boolean;
  closeAccount: boolean;
  lockScreen: boolean;
  schoolPauseButton: boolean;
  familyPauseButton: boolean;
  schoolTimeline: boolean;
  useChargebeeV3PC2: boolean;
  showTopbarFreeFeatures: boolean;
  blockToFreePremiumFeatures: boolean;
  useUnifiedDashboard: boolean;
  useDelegation: boolean;
  useDelegationAutoOptin: boolean;
  tmpRoutineSwitch: boolean;
  switchPlansOnPASA: boolean;
  tmpAdvanceAntiTampering: boolean;
}

export type FeatureFlagsExperimentsKey = keyof Experiments;

export type FeatureFlags = Flags & Experiments;

export type FeatureFlagsKey = keyof FeatureFlags;

export type FeatureFlagsRecord = NonEmptyMapRecord<FeatureFlags>;

export default function featureFlags(
  state: FeatureFlagsRecord = fromJS(defaultState),
  action
) {
  switch (action.type) {
    case SET_FLAGS:
      return state.merge(action.payload);
    default:
      return state;
  }
}

const flagsToState = (): FeatureFlags => {
  return Object.keys(flags).reduce((acum, flagName) => {
    const flag = flags[flagName];
    const modAcum = { ...acum };
    modAcum[flagName] = flag.isEnabled ? flag.isEnabled() : flag.getValue();
    return modAcum;
  }, {}) as FeatureFlags;
};

export const setFlags = () => ({
  type: SET_FLAGS,
  payload: flagsToState(),
});
