import { connect } from 'react-redux';
import {
  accountHasReportedLocationsAndTrackableDevices,
  getAccount,
  getAllProfileRules,
  getLicense,
  getTrackableProfiles,
  hasChildsAndTrackableDevices,
  isFetchingProfiles,
  hasFetchedAllProfileRules,
} from '../../selectors';
import FamilyLocator from '../../components/FamilyLocator/FamilyLocator';
import { isBrowserPlatform } from '../../helpers';
import { isFree } from '../../records/license';
import { handleRefresh } from '../../actions/FamilyLocatorActions';
import { fetchProfiles } from '../../ducks/profiles';
import {
  trackClickedRefreshLocations,
  FamilyLocatorViews,
  PageNames,
} from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import { fetchLocationEvents } from '../../ducks/events';
import { fetchAllProfileRules } from '../../ducks/profileRules';
import { getFeatureFlag } from '../../selectors/featureFlags';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => {
  return {
    account: getAccount(state),
    profiles: getTrackableProfiles(state),
    allProfileRules: getAllProfileRules(state),
    hasFetchedProfileRules: hasFetchedAllProfileRules(state),
    handleRefresh,
    showMap: accountHasReportedLocationsAndTrackableDevices(state),
    showProfiles: hasChildsAndTrackableDevices(state),
    isRefreshingLocations: isFetchingProfiles(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickRefreshLastLocation: stopRefresh => {
    trackClickedRefreshLocations(FamilyLocatorViews.FamilyLocator);
    handleRefresh(dispatch, [dispatch(fetchProfiles())], stopRefresh);
  },
});

const FamilyLocatorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(FamilyLocator, PageNames.FamilyLocator));

FamilyLocatorContainer.redirect = () => (dispatch, getState) => {
  const state = getState();
  const navigate = getMultiPlatformNavigation();

  if (!getFeatureFlag(state, 'familyLocator') || isBrowserPlatform()) {
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: `/`,
      })
    );
  }

  if (isFree(getLicense(state).type)) {
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: '/familyLocator/premiumFlyover',
      })
    );
  }

  const trackableProfiles = getTrackableProfiles(state);

  const profileIdOfUniqueKid =
    trackableProfiles.size === 1 ? trackableProfiles.toJS()[0].id : null;
  if (profileIdOfUniqueKid) {
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: `/familyLocator/${profileIdOfUniqueKid}/locations`,
      })
    );
  }
  if (!hasFetchedAllProfileRules(getState())) {
    dispatch(fetchAllProfileRules(trackableProfiles));
  }

  trackableProfiles.forEach(
    profile => profile && dispatch(fetchLocationEvents(profile.uid))
  );
};

export default FamilyLocatorContainer;
