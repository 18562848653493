import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import PanicButtonRulesFlyover from './PanicButtonRulesFlyover';
import type { PanicButtonRulesStartTrialFlyoverProps } from '../../containers/ProfileRules/PanicButtonRules/PanicButtonStartTrialFlyoverContainer';
import { SchoolTrialDuration } from '../../constants';

const START_TRIAL_FLYOVER_TEST_ID = 'panic-button-rules-start-trial-flyover';

const PanicButtonRulesStartTrialFlyover = ({
  accountLocale,
  onClickClose,
  onClickStartTrial,
}: PanicButtonRulesStartTrialFlyoverProps) => {
  return (
    <PanicButtonRulesFlyover
      accountLocale={accountLocale}
      testId={START_TRIAL_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Try for free'),
        onClick: onClickStartTrial,
        testId: `${START_TRIAL_FLYOVER_TEST_ID}-start-trial`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android')}
      topbarText={t('Try the panic button free for {{days}} days!', {
        days: SchoolTrialDuration,
      })}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default PanicButtonRulesStartTrialFlyover;
