import { fromJS, Record } from 'immutable';
import { snakeCaseToCamelCase } from '../../api/mappings/case';
import {
  UserDevice,
  UserDeviceMethods,
  UserDeviceRecord as UserDeviceRecordType,
  UserDeviceStatusRecord,
} from './types/UserDevice.types';

export const UserDeviceRecord = Record<UserDevice, UserDeviceMethods>({
  id: 0,
  uid: '',
  name: '',
  status: Record({
    disableProtection: {
      status: false,
    },
    vpnDisable: {
      status: false,
    },
  }) as UserDeviceStatusRecord,
  profileId: 0,
  isOnline: null,
  lastseen: null,
});

UserDeviceRecord.serialize = (record: UserDeviceRecordType) => ({
  ...record.toJS(),
});

UserDeviceRecord.fromPayload = payload =>
  UserDeviceRecord({
    ...snakeCaseToCamelCase(payload),
    status: fromJS(payload.status) as UserDeviceStatusRecord,
  }) as UserDeviceRecordType;
