import {
  RULE_LOCATION,
  RULE_PANIC_BUTTON,
  RULE_CALLS_AND_SMS,
} from '../../../../constants';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

const trialValidRules = [RULE_LOCATION, RULE_PANIC_BUTTON, RULE_CALLS_AND_SMS];

export const isVisibleStartTrialLabel = ({
  profileRule,
  isValidForTrial,
}: ProfileRulesLabelPredicatesProps): boolean => {
  if (isValidForTrial) {
    return trialValidRules.includes(profileRule);
  }

  return false;
};
