import * as React from 'react';
import { classNames } from '../../helpers';
import IconApp from '../Icons/IconApp';
import IconCallIncoming from '../Icons/IconCallIncoming';
import IconCallOutgoing from '../Icons/IconCallOutgoing';
import IconCallMissed from '../Icons/IconCallMissed';
import IconSmsIncoming from '../Icons/IconSmsIncoming';
import IconSmsOutgoing from '../Icons/IconSmsOutgoing';
import IconSos from '../Icons/IconSos';
import IconYoutube from '../Icons/IconYoutube';
import IconTwitter from '../Icons/IconTwitter';

import * as LocationImage from '../../assets/base/images/v3/icons/activity/location.svg';
import * as LocationUnavailableImage from '../../assets/base/images/icons/events/location--unavailable.png';
import * as LocationPanicImage from '../../assets/base/images/icons/events/location--panicMode.png';
import * as FacebookImage from '../../assets/base/images/icons/events/facebook.png';
import IconOther from '../Icons/IconOther';
import IconProfile from '../Icons/IconProfile';
import { Platform } from '../../constants';
import IconWorld from '../Icons/IconWorld';
import { Icon, IconColor, IconSize, IconType } from 'styleguide-react';
import {
  EventCategoryType,
  isTypeAlert,
  isTypeAlertHighlighted,
} from '../ActivityTimeline/types';

export enum ActivityIconSizes {
  Big = 'big',
  Small = 'small',
}

export enum ActivityColors {
  Success = 'success',
  Danger = 'danger',
  Neutral = 'neutral',
  Warning = 'warning',
}

export enum WebActivityColors {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
}

export enum SocialActivityPlatforms {
  Facebook = 'facebook',
  Twitter = 'twitter',
}

export enum LocationActivityState {
  Unavailable = 'unavailable',
  Panic = 'panic',
}

export enum PanicActivityState {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

interface IActivityIconProps {
  size?: ActivityIconSizes;
  className?: string;
}

const ActivityIcon: React.FunctionComponent<IActivityIconProps> = ({
  size = ActivityIconSizes.Big,
  className,
  children,
}) => (
  <div
    className={classNames('ActivityIcon', `ActivityIcon--${size}`, className)}
  >
    {children}
  </div>
);

export const AppActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    isIOS: boolean;
    src?: string;
  }
> = props => {
  const { src, isIOS, className: classNameProp } = props;

  const className = classNames(
    src && isIOS ? 'ActivityIcon--iosApp' : '',
    classNameProp
  );
  return (
    <ActivityIcon {...props} className={className}>
      {src ? (
        <img role="presentation" alt="activity" src={src} />
      ) : (
        <span className="ActivityIcon__placeholder-icon">
          <IconApp />
        </span>
      )}
    </ActivityIcon>
  );
};

export const WebActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: WebActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--web',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconWorld />
    </ActivityIcon>
  );
};

export const IncomingCallActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: ActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconCallIncoming />
    </ActivityIcon>
  );
};

export const OutgoingCallActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: ActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconCallOutgoing />
    </ActivityIcon>
  );
};

export const MissedCallActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: ActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconCallMissed />
    </ActivityIcon>
  );
};

export const IncomingSmsActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: ActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconSmsIncoming />
    </ActivityIcon>
  );
};

export const OutgoingSmsActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    color?: ActivityColors;
  }
> = props => {
  const { color, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        color ? `ActivityIcon--${color}` : '',
        className
      )}
    >
      <IconSmsOutgoing />
    </ActivityIcon>
  );
};

const getSearchActivityIconClass = (type: EventCategoryType) => {
  if (isTypeAlertHighlighted(type)) {
    return 'ActivityIcon--search--alert-highlighted';
  }

  if (isTypeAlert(type)) {
    return 'ActivityIcon--search--alert';
  }

  return '';
};

const getSearchActivityIconColor = (type: EventCategoryType) => {
  if (isTypeAlertHighlighted(type)) {
    return IconColor.contrast;
  }

  if (isTypeAlert(type)) {
    return IconColor.warning;
  }

  return IconColor.neutral;
};

export const SearchActivityIcon: React.FunctionComponent<
  IActivityIconProps & { type: EventCategoryType }
> = props => {
  const { className, type } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--search',
        className,
        getSearchActivityIconClass(type)
      )}
    >
      <Icon
        type={IconType.search}
        color={getSearchActivityIconColor(type)}
        size={IconSize.lg}
      />
    </ActivityIcon>
  );
};

export const YouTubeActivityIcon: React.FunctionComponent<
  IActivityIconProps
> = props => {
  const { className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames('ActivityIcon--youtube', className)}
    >
      <IconYoutube />
    </ActivityIcon>
  );
};

export const PanicActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    state: PanicActivityState;
  }
> = props => {
  const { state, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--event',
        `ActivityIcon--panic-${state}`,
        className
      )}
    >
      <IconSos />
    </ActivityIcon>
  );
};

const locationImages = {
  unavailable: LocationUnavailableImage,
  panic: LocationPanicImage,
};

export const LocationActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    state?: LocationActivityState;
  }
> = props => {
  const { state, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--location',
        state ? `ActivityIcon--location-${state}` : '',
        className
      )}
    >
      <img
        role="presentation"
        alt="location"
        src={state ? locationImages[state] : LocationImage}
      />
    </ActivityIcon>
  );
};

export const SocialActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    platform: SocialActivityPlatforms;
  }
> = props => {
  const { platform, className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames(
        'ActivityIcon--social',
        `ActivityIcon--social-${platform}`,
        className
      )}
    >
      {platform === 'facebook' ? (
        <img role="presentation" alt="facebook" src={FacebookImage} />
      ) : (
        <IconTwitter />
      )}
    </ActivityIcon>
  );
};

export const OtherActivityIcon: React.FunctionComponent<
  IActivityIconProps
> = props => {
  const { className: classNameProp } = props;
  const className = classNames('ActivityIcon--other', classNameProp);
  return (
    <ActivityIcon {...props} className={className}>
      <IconOther />
    </ActivityIcon>
  );
};

export const CallAndSmsIcon: React.FunctionComponent<
  IActivityIconProps
> = props => {
  const { className: classNameProp } = props;
  const className = classNames('ActivityIcon--calls-and-sms', classNameProp);
  return (
    <div {...props} className={className}>
      <IconProfile />
    </div>
  );
};

export const WebSummaryCardActivityIcon: React.FunctionComponent<
  IActivityIconProps
> = props => {
  const { className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames('ActivityIcon--event', className)}
    >
      <i className="far fa-globe" />
    </ActivityIcon>
  );
};

export const AppSummaryCardActivityIcon: React.FunctionComponent<
  IActivityIconProps & {
    src?: string;
    platform: Platform;
  }
> = props => {
  const { className, platform, src } = props;

  const getPlatformIconClass = () => {
    const platformIconClass = {
      PLATFORM_UNKNOWN: 'far fa-phone-laptop',
      PLATFORM_WINDOWS: 'fab fa-windows',
      PLATFORM_MAC: 'fab fa-apple',
      PLATFORM_ANDROID: 'fab fa-android',
      PLATFORM_IOS: 'fab fa-apple',
      PLATFORM_CHROME: 'fab fa-chrome',
    };
    return platformIconClass[platform];
  };
  return (
    <ActivityIcon
      {...props}
      className={classNames('ActivityIcon--event', className)}
    >
      {src ? (
        <img role="presentation" alt="actvity" src={src} />
      ) : (
        <i className={getPlatformIconClass()} />
      )}
    </ActivityIcon>
  );
};

export const SafeNetworkActivityIcon: React.FunctionComponent<
  IActivityIconProps
> = props => {
  const { className } = props;
  return (
    <ActivityIcon
      {...props}
      className={classNames('ActivityIcon--safe-network', className)}
    >
      <Icon
        type={IconType.graduationCap}
        color={IconColor.secondary}
        size={IconSize.lg}
      />
    </ActivityIcon>
  );
};
