import { LocationChangeAction } from 'react-router-redux';
import { AppEpicWithoutStateEffect, Dependencies } from '../types';
import { isSchoolTimelinePage, onLocation } from '../router';
import { first, switchMap } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { doWithoutStateEffect } from '../operators';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import {
  getLinewizeEventsStatus,
  getTimelineAllowedEnabled,
  getTimelineBlockedEnabled,
  getTimelineSearchEnabled,
  getTimelineVideoEnabled,
} from '../../ducks/linewizeEvent/selector';

interface PageTrackValues {
  schoolHasEnabledTimeline: boolean;
  schoolHasEnabledSearch: boolean;
  schoolHasEnabledVideo: boolean;
  profileIsUnlinked: boolean;
}

const sendStudentTimelinePageInfo = (
  values: PageTrackValues,
  analytics: Dependencies['analytics']
) => {
  const { format, trackPageLoaded, GenericPageNames } = analytics;

  const data = format({
    options: decamelizeKeysDeep(values),
  });

  trackPageLoaded(GenericPageNames.ProfileSchoolTimeline, data);
};

/**
 * This function is subscribe to the actions emitted by the app,
 * when it detects a locationChange action check if the action
 * leads to the school timeline page, if it leads and receive the first event of one of the types:
 *  - LINEWIZE_EVENTS_SUCCESS
 *  - LINEWIZE_EVENTS_EXCEPTION
 *  - LINEWIZE_EVENTS_ERROR
 * get from teh state the values need to report to ANL,
 * and send the pageLoaded tracking with the data
 *
 */
export const studentTimelinePageLoadEpic: AppEpicWithoutStateEffect<
  LocationChangeAction
> = ($actions, $state, { analytics }) => {
  return $actions.pipe(
    onLocation(isSchoolTimelinePage),
    switchMap(() => {
      return $actions.pipe(
        ofType(
          'LINEWIZE_EVENTS_SUCCESS',
          'LINEWIZE_EVENTS_EXCEPTION',
          'LINEWIZE_EVENTS_ERROR'
        ),
        first(),
        doWithoutStateEffect(() => {
          const state = $state.value;
          const values = {
            schoolHasEnabledTimeline:
              getLinewizeEventsStatus(state) !== 'REPORT_DISABLED',
            schoolHasEnabledSearch: getTimelineSearchEnabled(state),
            schoolHasEnabledVideo: getTimelineVideoEnabled(state),
            schoolHasEnabledBlocked: getTimelineBlockedEnabled(state),
            schoolHasEnabledAllowed: getTimelineAllowedEnabled(state),
            profileIsUnlinked:
              getLinewizeEventsStatus(state) === 'CHILD_UNLINKED',
          };
          sendStudentTimelinePageInfo(values, analytics);
        })
      );
    })
  );
};

export const studentTimelinePageTrackingEpic = combineEpics(
  studentTimelinePageLoadEpic
);
