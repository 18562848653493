import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  WebfilterSection,
  WEBFILTER_CATEGORIES_SECTION,
} from '../../constants';
import {
  Modal,
  List,
  RegularStyleListItem,
  Button,
  ButtonSize,
  Icon,
  IconType,
  IconColor,
  Typography,
} from 'styleguide-react';
import IconAlert from '../Icons/Actions/IconAlert';
import IconBlock from '../Icons/Actions/IconBlock';
import IconIgnore from '../Icons/Actions/IconIgnore';
import IconAllow from '../Icons/Actions/IconAllow';

const sectionSwitch =
  (activeSection: WebfilterSection) => (categoryElement, exceptionsElement) =>
    activeSection === WEBFILTER_CATEGORIES_SECTION
      ? categoryElement
      : exceptionsElement;

const WebFiltersLegendModal: React.FunctionComponent<{
  onClickClose: () => void;
  activeSection: WebfilterSection;
}> = ({ onClickClose, activeSection }) => {
  const switchSection = sectionSwitch(activeSection);
  return (
    <Modal
      size="small"
      title={switchSection(
        t('Web category options'),
        t('Web exception options')
      )}
      header={{
        icon: <Icon type={IconType.infoCircle} color={IconColor.secondary} />,
      }}
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button1"
          onClick={onClickClose}
          size={ButtonSize.medium}
          block
        >
          {t('Ok')}
        </Button>,
      ]}
    >
      {switchSection(
        <List showSeparator={false} listItemMarginBottom="8">
          <RegularStyleListItem
            icon={<IconAllow />}
            title={
              <Typography align="left" type="body1">
                {t('Allow: Allows access to all websites in a category')}
              </Typography>
            }
          />
          <RegularStyleListItem
            icon={<IconAlert />}
            title={
              <Typography align="left" type="body1">
                {t('Alert me: Allows access, but sends you a notification')}
              </Typography>
            }
          />
          <RegularStyleListItem
            icon={<IconBlock />}
            title={
              <Typography align="left" type="body1">
                {t('Block: Blocks all websites in a category')}
              </Typography>
            }
          />
        </List>,
        <List showSeparator={false} listItemMarginBottom="16">
          <RegularStyleListItem
            icon={<IconAllow />}
            title={
              <Typography align="left" type="body1">
                {t('Allow: Always allows the website to be visited')}
              </Typography>
            }
          />
          <RegularStyleListItem
            icon={<IconAlert />}
            title={
              <Typography align="left" type="body1">
                {t('Alert me: Allows access, but sends you a notification')}
              </Typography>
            }
          />
          <RegularStyleListItem
            icon={<IconBlock />}
            title={
              <Typography align="left" type="body1">
                {t('Block: Always blocks the website')}
              </Typography>
            }
          />
          <RegularStyleListItem
            icon={<IconIgnore />}
            title={
              <Typography align="left" type="body1">
                {t(
                  "Ignore: Doesn't report any activity when the website is visited"
                )}
              </Typography>
            }
          />
        </List>
      )}
    </Modal>
  );
};

export default WebFiltersLegendModal;
