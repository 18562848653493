import * as React from 'react';
import { Menu } from 'styleguide-react';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import type { MenuOptionsProps } from '../../containers/MenuOptionsContainer/MenuOptionsContainer';

class MenuOptions extends React.Component<MenuOptionsProps> {
  state = {};

  render() {
    const {
      menuOptions,
      isDataReady,
      selectedRule,
      shouldRender,
      deviceType,
      onClick,
    } = this.props;
    return (
      <React.Fragment>
        {shouldRender ? (
          <Menu
            selectedRule={selectedRule}
            onClick={onClick}
            isDataReady={isDataReady}
            items={menuOptions}
            scrollable={deviceType === 'desktop'}
            testId="profileOptions"
            expanded={deviceType === 'mobile'}
            profileInfoHeader={
              deviceType === 'desktop' ? (
                <ProfileHeader screenSize={ScreenSize.Desktop} />
              ) : undefined
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default MenuOptions;
