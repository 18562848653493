import * as React from 'react';
import { classNames } from '../../helpers';

type BorderShape = 'wave' | 'curve';
type BackgroundShape =
  | 'yolk-small'
  | 'yolk-large'
  | 'secondary-lighter'
  | 'yolk-corner-right-large'
  | 'yolk-corner-right-small'
  | 'yolk-corner-button-right-large';
export interface BackgroundGradientProps {
  gradient: BackgroundShape;
  topShape?: BorderShape;
  bottomShape?: BorderShape;
  children: JSX.Element | JSX.Element[];
}

const BackgroundGradient = ({
  children,
  gradient,
  topShape,
  bottomShape,
}: BackgroundGradientProps) => (
  <div
    className={classNames(
      'par-background-gradient',
      `par-background-gradient--bg-shape-${gradient}`,
      topShape ? `par-background-gradient--top-shape-${topShape}` : '',
      bottomShape ? `par-background-gradient--bottom-shape-${bottomShape}` : ''
    )}
  >
    <div className="par-background-gradient__content">{children}</div>
  </div>
);

export default BackgroundGradient;
