import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  Layout,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { TimeLimitsModalProps } from '../../../containers/Routines/Modal/TimeLimitsModalContainer';

const TimeLimitsModal = ({
  onClickAccept,
  onClickClose,
}: TimeLimitsModalProps) => {
  const MODAL_TEST_ID = 'time-limits-modal';

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-time-limits-modal"
      header={{
        icon: (
          <Icon
            type={IconType.infoCircle}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={t('Daily time limits apply')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-ok`}
          key="button"
          onClick={onClickAccept}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Got it')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <Layout testId="time-limits-modal-body">
          <ModalStyledText test>
            {t(
              'Devices will be blocked if a daily time limit is reached during the routine.'
            )}
          </ModalStyledText>
        </Layout>
      </FlexLayout>
    </Modal>
  );
};
export default TimeLimitsModal;
