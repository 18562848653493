import * as React from 'react';
import { FlexLayout, Layout, StyledHeader } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacing } from 'styleguide-react/build/components/Layout/types';
import { t } from '../../../lib/i18n';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import AddChildForm from '../Form/AddChildForm';

const OnboardingAddChild: React.FunctionComponent<{
  onClickSaveAndContinue: () => any;
  onChangeGender: () => any;
}> = ({ onClickSaveAndContinue, onChangeGender }) => {
  const renderContent = (margins?: LayoutSpacing) => (
    <FlexLayout
      mainaxis="column"
      crossaxisAlignment="center"
      matchParentHeight
      marginLeft={margins}
      marginRight={margins}
    >
      <Layout marginBottom="24">
        <StyledHeader type="h5">
          <strong>
            {t('Enter the following details to create your child’s profile:')}
          </strong>
        </StyledHeader>
      </Layout>

      <AddChildForm
        onSubmit={onClickSaveAndContinue}
        onChangeGender={onChangeGender}
      />
    </FlexLayout>
  );
  return (
    <Layout minHeight="100%" height="100%">
      <RenderWhen screenSize={ScreenSize.Desktop}>
        {renderContent('112')}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderContent()}
      </RenderWhen>
    </Layout>
  );
};

export default OnboardingAddChild;
