import { push, replace, goBack, go } from '../lib/wrappers';
import { LocationRecord } from '../records';
import { Marker } from '../records/marker';
import {
  openDirections,
  openLinkInBrowser,
  openLocation,
  navigateToLink,
  openPublicSiteHelpLinkInBrowser,
} from '../sideEffects/browser';

/*
centralizes navigation types and constants
*/
export {
  LOCATION_CHANGE,
  RouterProps,
  RouterAction,
  syncHistoryWithStore,
  routerMiddleware,
} from '../lib/wrappers';

type Pathname = { pathname: string; query?: any };

export type HrefTarget =
  | '_self'
  | '_blank'
  | '_system'
  | '_parent'
  | '_top'
  | ''
  | undefined;

export type Navigation = {
  type:
    | 'location:href'
    | 'inner'
    | 'inner:replace'
    | 'inner:back'
    | 'inner:go'
    | 'external'
    | 'external:public:site'
    | 'email'
    | 'map:location'
    | 'map:direction';
  src: string | Marker | Pathname | LocationRecord | null | number;
  target?: HrefTarget;
  event?: Event;
  label?: string;
  openInIOS?: boolean;
};

export type NavigateStrategy = (params: Navigation) => any;

const createMultiPlatformNavigation =
  <NavigationStrategyReturnType = any>(navigate: NavigateStrategy) =>
  (event?: Event) =>
  (params: Navigation): NavigationStrategyReturnType =>
    navigate({ event, ...params });

export const getMultiPlatformNavigation = createMultiPlatformNavigation(
  (params: Navigation) => {
    if (params.event) {
      params.event.preventDefault();
    }

    switch (params.type) {
      case 'location:href':
        return navigateToLink(params.src as string);
      case 'inner':
        return push(params.src as string | Pathname); // Side Effect .. expect use dispatch
      case 'inner:replace':
        return replace(params.src as string); // Side Effect .. expect use dispatch
      case 'inner:back':
        return goBack(); // Side Effect .. expect use dispatch
      case 'inner:go':
        return go(params.src as number); // Side Effect .. expect use dispatch
      case 'external':
        return openLinkInBrowser(
          params.src as string,
          params.target || '_system'
        ); // Side Effect .. expect use dispatch and depends of cordova override window.cordova.InAppBrowser.open
      case 'external:public:site':
        return openPublicSiteHelpLinkInBrowser(
          params.src as string,
          params.openInIOS
        );
      case 'map:location':
        return openLocation(params.src as LocationRecord, params.label); // Thunk
      case 'map:direction':
        return openDirections(params.src as Marker); // Thunk
      default:
        throw new Error('Navigation type not defined.');
    }
  }
);
