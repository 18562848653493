import * as React from 'react';
import { MapRecord } from 'immutable';
import { FlexLayout, Button, ButtonSize, Label } from 'styleguide-react';
import { reduxForm, Field } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import InputTextField from '../Form/InputTextField';
import { isValidEmail } from '../../helpers/string';
import { MAX_EMAIL_LENGTH } from '../../constants';

const formName = 'inviteAdditionalParent';
const invitationEmailAddressFieldId = 'invitationEmailAddressField';

const AdditionalParentForm = ({
  handleSubmit,
}: {
  handleSubmit: () => void;
}) => (
  <form id={formName} onSubmit={handleSubmit}>
    <FlexLayout mainaxis="column" marginTop="24">
      <Label htmlFor={invitationEmailAddressFieldId}>
        {t('Email address')}
      </Label>

      <FlexLayout mainaxis="column" marginTop="8">
        <Field
          id={invitationEmailAddressFieldId}
          name="email"
          type="email"
          component={InputTextField}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          maxlength={MAX_EMAIL_LENGTH}
          testId="inviteAdditionalParent"
        />
      </FlexLayout>
    </FlexLayout>
    <FlexLayout mainaxis="row">
      <Button
        size={ButtonSize.medium}
        htmlType="submit"
        testId="inviteAdditionalParent"
      >
        {t('Invite now')}
      </Button>
    </FlexLayout>
  </form>
);

const validate = (
  values: MapRecord<{ email: string }>,
  { currentAccountEmailAddress }: { currentAccountEmailAddress: string }
) => {
  const email = values.get('email');

  if (!email) {
    return {
      email: t('Enter an email'),
    };
  }

  if (!isValidEmail(email)) {
    return {
      email: t('Invalid email address'),
    };
  }

  if (currentAccountEmailAddress.toLowerCase() === email.toLowerCase()) {
    return {
      email: t("You can't use your own email address"),
    };
  }

  return {};
};

const AdditionalParentFormConnectedToReduxForm = reduxForm({
  form: formName,
  validate,
})(AdditionalParentForm);

export default AdditionalParentFormConnectedToReduxForm;
