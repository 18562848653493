import * as React from 'react';
import * as R from 'ramda';
import { Location } from 'history';
import GenericFeatureUpgradeModalContainer from '../../containers/Modal/GenericFeatureUpgradeModalContainer';
import WelcomeToPremiumModalContainer from '../../containers/Modal/WelcomeToPremiumModalContainer';
import RenewalModalContainer from '../../containers/Modal/RenewalModalContainer';
import AtLimitModalContainer from '../../containers/Modal/AtLimitModalContainer';
import AvatarSelectionModalContainer from '../../containers/Modal/AvatarSelectionModalContainer';
import OverLimitModalContainer from '../../containers/Modal/OverLimitModalContainer';
import WelcomeModalContainer from '../../containers/Modal/WelcomeModalContainer';
import ExpandPlanModalContainer from '../../containers/Modal/ExpandPlanModalContainer';
import WebFiltersLegendModalContainer from '../../containers/Modal/WebFiltersLegendModalContainer';
import CallsSMSBlockedSectionModalContainer from '../../containers/Modal/CallsSMSBlockedSectionModalContainer';
import PanicRulesTourModalContainer from '../../containers/Modal/PanicRulesTourModalContainer';
import TrustedContactsLegendModalContainer from '../../containers/Modal/TrustedContactsLegendModalContainer';
import HowToAssignUsersModalContainer from '../../containers/Modal/HowToAssignUsersModalContainer';
import HowToAssignProfileToAccountModalContainer from '../../containers/Modal/HowToAssignProfileToAccountModalContainer';
import CustomNotificationModalContainer from '../../containers/Modal/CustomNotificationModalContainer';
import ExternalUrlNavigationModalContainer from '../../containers/Modal/ExternalUrlNavigationModalContainer';
import YoutubeAgreementWebBlockModalContainer from '../../containers/Modal/YoutubeAgreementWebBlockModalContainer';
import UnsupportedBrowsersBlockedModalContainer from '../../containers/Modal/UnsupportedBrowsersBlockedModalContainer';
import RemovePlaceModalContainer from '../../containers/Modal/RemovePlaceModalContainer';
import { RouterParamsProps } from '../../store/state';
import PlacesLimitReachedModalContainer from '../../containers/Modal/PlacesLimitReachedModalContainer';
import ScreenTimeModalContainer from '../../containers/Modal/ScreenTimeModalContainer';
import {
  LocationServicesDisabledInstructionsAndroidModalContainer,
  LocationServicesDisabledInstructionsIosModalContainer,
  MissingPermissionsInstructionsAndroidModalContainer,
  MissingPermissionsInstructionsIosModalContainer,
} from './FixLocationProblemsModal';
import TwoYearsPromotionModalContainer from '../../containers/Modal/TwoYearsPromotionModalContainer';
import ReTrialModalContainer from '../../containers/Modal/ReTrialModalContainer';
import RatingAppBadFeedbackModalContainer from '../../containers/Modal/RatingAppBadFeedbackModalContainer';
import SummaryCardInfoModalContainer from '../../containers/Modal/SummaryCardInfoModalContainer';
import IosPreConsentTrackingModalContainer from '../../containers/Modal/IosPreConsentTrackingModalContainer';
import RemoveInvitationModalContainer from '../../containers/Modal/RemoveInvitationModalContainer';
import AdditionalParentWelcomeModalContainer from '../../containers/Modal/AdditionalParentWelcomeModalContainer';
import CloseAdditionalParentAccountModalContainer from '../../containers/Modal/CloseAdditionalParentAccountModalContainer';
import LogoutModalContainer from '../../containers/Modal/LogoutModalContainer';
import SchoolsWelcomeModalContainer from '../../containers/Modal/SchoolsWelcomeModalContainer';
import SchoolSummaryInfoModalContainer from '../../containers/Modal/SchoolSummaryInfoModalContainer';
import EditEmailConfirmationModalContainer from '../../containers/Modal/EditEmailConfirmationModalContainer';
import ActivateRetrialModalContainer from '../../containers/Modal/ActivateRetrialModalContainer';
import AdditionalParentInformationModalContainer from '../../containers/Modal/AdditionalParentInformationModalContainer';
import StudentPauseModalContainer from '../../containers/Modal/StudentPauseModalContainer';
import StudentPauseInfoModalContainer from '../../containers/Modal/StudentPauseInfoModalContainer';
import PauseTheInternetModalContainer from '../../containers/Modal/PauseTheInternetModalContainer/PauseTheInternetModalContainer';
import AppDailyTimeLimitModalContainer from '../../containers/Modal/AppDailyTimeLimitModalContainer';
import SafeNetworkSettingsInfoModalContainer from '../../containers/Modal/SafeNetworkSettingsInfoModalContainer';
import SafeNetworkAllowModalContainer from '../../containers/Modal/SafeNetworkAllowModalContainer';
import ExtraTimeModalContainer from '../../containers/Modal/ExtraTimeModalContainer';
import NonBlockableAppModalContainer from '../../containers/Modal/NonBlockableAppModalContainer';
import SafeNetworkInfoModalContainer from '../../containers/Modal/SafeNetworkInfoModalContainer';
import TimeLimitsModalContainer from '../../containers/Routines/Modal/TimeLimitsModalContainer';
import DeleteTimeSlotModalContainer from '../../containers/Routines/Modal/DeleteTimeSlotModalContainer';
import DeleteRoutineModalContainer from '../../containers/Routines/Modal/DeleteRoutineModalContainer';
import CantEnableTimeSlotsModalContainer from '../../containers/Routines/Modal/CantEnableTimeSlotsModalContainer';
import DisconnectTheInternetModalContainer from '../../containers/Routines/Modal/DisconnectTheInternetModalContainer';
import LockDeviceModalContainer from '../../containers/Routines/Modal/LockDeviceModalContainer';
import ChangeContentFilterRulesModalContainer from '../../containers/Routines/Modal/ChangeContentFilterRulesModalContainer';
import SetContentFilterRulesModalContainer from '../../containers/Routines/Modal/SetContentFilterRulesModalContainer';
import LeaveWithoutSavingModalContainer from '../../containers/Routines/Modal/LeaveWithoutSavingModalContainer';
import RoutineWithoutTimeLimitInfoModalContainer from '../../containers/Modal/RoutineWithoutTimeLimitInfoModalContainer';
import TotalScreenTimeInfoModalContainer from '../../containers/Modal/TotalScreenTimeInfoModalContainer';
import UnlockCompleteModalContainer from '../../containers/Modal/UnlockCompleteModalContainer';
import PauseProfileModalContainer from '../../containers/Modal/PauseProfileModalContainer';
import SmartDeviceInfoModalContainer from '../../containers/Modal/SmartDeviceInfoModalContainer';
import RemoveAllExceptionsModalContainer from '../../containers/Routines/Modal/RemoveAllExceptionsModalContainer';
import RoutinesInfoModalContainer from '../../containers/Modal/RoutinesInfoModalContainer';
import NowCardInfoModalContainer from '../../containers/Modal/NowCardInfoModalContainer';
import DelegationEnabledModalContainer from '../../containers/Modal/DelegationEnabledModalContainer/DelegationEnabledModalContainer';
import DelegationRuleInfoModalContainer from '../../containers/Modal/DelegationRuleInfoModalContainer';
import UnlockAlertsAndTimeLimitsModalContainer from '../../containers/Modal/UnlockAlertsAndTimeLimitsModalContainer';
import SafetyNetInfoModalContainer from '../../containers/Modal/SafetyNetInfoModalContainer';
import SetupAlertsModalContainer from '../../containers/Modal/SetupAlertsModalContainer';
import ActivityTimelineDelegationInfoModalContainer from '../../containers/Modal/ActivityTimelineDelegationInfoModalContainer';
import DeviceOverLimitModalContainer from '../../containers/Modal/DeviceOverLimitModalContainer';
import DelegationOptOutModalContainer from '../../containers/Modal/DelegationOptOutModalContainer';
import UndoRoutineSwitchModalContainer from '../../containers/Modal/UndoRoutineSwitchModalContainer';
import RoutineSwitchModalContainer from '../../containers/Modal/RoutineSwitchModalContainer';
import WellbeingContactUsModalContainer from '../../containers/Modal/Wellbeing/WellbeingContactUsModalContainer';
import ProfileAtLimitModalContainer from '../../containers/Modal/ProfileAtLimitModalContainer';
import RemoveDeviceModalContainer from '../../containers/Modal/RemoveDeviceModalContainer';

const modals = {
  UnsupportedBrowsersBlockedModal: UnsupportedBrowsersBlockedModalContainer,
  GenericFeatureUpgradeModal: GenericFeatureUpgradeModalContainer,
  WelcomeToPremiumModal: WelcomeToPremiumModalContainer,
  RenewalModal: RenewalModalContainer,
  AtLimitModal: AtLimitModalContainer,
  AvatarSelectionModal: AvatarSelectionModalContainer,
  OverLimitModal: OverLimitModalContainer,
  WelcomeModal: WelcomeModalContainer,
  ExpandPlanModal: ExpandPlanModalContainer,
  WebFiltersLegendModal: WebFiltersLegendModalContainer,
  CallsSMSBlockedSectionModal: CallsSMSBlockedSectionModalContainer,
  PanicRulesTourModal: PanicRulesTourModalContainer,
  TrustedContactsLegendModal: TrustedContactsLegendModalContainer,
  HowToAssignUsersModal: HowToAssignUsersModalContainer,
  HowToAssignProfileToAccountModal: HowToAssignProfileToAccountModalContainer,
  CustomNotification: CustomNotificationModalContainer,
  ExternalUrlNavigationModal: ExternalUrlNavigationModalContainer,
  YoutubeAgreementWebBlockModal: YoutubeAgreementWebBlockModalContainer,
  RemovePlaceModal: RemovePlaceModalContainer,
  PlacesLimitReachedModal: PlacesLimitReachedModalContainer,
  ScreenTimeModal: ScreenTimeModalContainer,
  TwoYearsPromotionModal: TwoYearsPromotionModalContainer,
  ReTrialModal: ReTrialModalContainer,
  RatingAppBadFeedbackModal: RatingAppBadFeedbackModalContainer,
  MissingPermissionsInstructionsIosModal:
    MissingPermissionsInstructionsIosModalContainer,
  MissingPermissionsInstructionsAndroidModal:
    MissingPermissionsInstructionsAndroidModalContainer,
  LocationServicesDisabledInstructionsIosModal:
    LocationServicesDisabledInstructionsIosModalContainer,
  LocationServicesDisabledInstructionsAndroidModal:
    LocationServicesDisabledInstructionsAndroidModalContainer,
  LogoutModal: LogoutModalContainer,
  SummaryCardInfoModal: SummaryCardInfoModalContainer,
  IosPreConsentTrackingModal: IosPreConsentTrackingModalContainer,
  RemoveInvitationModal: RemoveInvitationModalContainer,
  AdditionalParentWelcomeModal: AdditionalParentWelcomeModalContainer,
  CloseAdditionalParentAccountModal: CloseAdditionalParentAccountModalContainer,
  SchoolsWelcomeModal: SchoolsWelcomeModalContainer,
  SchoolSummaryInfoModal: SchoolSummaryInfoModalContainer,
  EditEmailConfirmationModal: EditEmailConfirmationModalContainer,
  ActivateRetrialModal: ActivateRetrialModalContainer,
  AdditionalParentInformationModal: AdditionalParentInformationModalContainer,
  schoolPause: StudentPauseModalContainer,
  schoolPauseInfo: StudentPauseInfoModalContainer,
  PauseTheInternet: PauseTheInternetModalContainer,
  appDailyTimeLimit: AppDailyTimeLimitModalContainer,
  SafeNetworkSettingsInfoModal: SafeNetworkSettingsInfoModalContainer,
  SafeNetworkAllowModal: SafeNetworkAllowModalContainer,
  SafeNetworkInfoModal: SafeNetworkInfoModalContainer,
  ExtraTimeModal: ExtraTimeModalContainer,
  NonBlockableAppModal: NonBlockableAppModalContainer,
  TimeLimitsModal: TimeLimitsModalContainer,
  DeleteTimeSlotModal: DeleteTimeSlotModalContainer,
  DeleteRoutineModal: DeleteRoutineModalContainer,
  CantEnableTimeSlotsModal: CantEnableTimeSlotsModalContainer,
  DisconnectTheInternetModal: DisconnectTheInternetModalContainer,
  LockDeviceModal: LockDeviceModalContainer,
  ChangeContentFilterRulesModal: ChangeContentFilterRulesModalContainer,
  SetContentFilterRulesModal: SetContentFilterRulesModalContainer,
  LeaveWithoutSavingModal: LeaveWithoutSavingModalContainer,
  RoutineWithoutTimeLimit: RoutineWithoutTimeLimitInfoModalContainer,
  TotalScreenTimeInfo: TotalScreenTimeInfoModalContainer,
  UnlockComplete: UnlockCompleteModalContainer,
  PauseProfile: PauseProfileModalContainer,
  DeviceInfo: SmartDeviceInfoModalContainer,
  RemoveAllExceptionsModal: RemoveAllExceptionsModalContainer,
  RoutinesInfoModal: RoutinesInfoModalContainer,
  UnifiedNowCardInfoModal: NowCardInfoModalContainer,
  DelegationEnabled: DelegationEnabledModalContainer,
  DelegationRuleInfo: DelegationRuleInfoModalContainer,
  UnlockAlertsAndTimeLimitsModal: UnlockAlertsAndTimeLimitsModalContainer,
  SafetyNetInfo: SafetyNetInfoModalContainer,
  SetupAlertsModal: SetupAlertsModalContainer,
  ActivityTimelineDelegationInfo: ActivityTimelineDelegationInfoModalContainer,
  DeviceOverLimitModal: DeviceOverLimitModalContainer,
  DelegationOptOut: DelegationOptOutModalContainer,
  UndoRoutineSwitch: UndoRoutineSwitchModalContainer,
  RoutineSwitch: RoutineSwitchModalContainer,
  WellbeingContactUs: WellbeingContactUsModalContainer,
  ProfileAtLimitModal: ProfileAtLimitModalContainer,
  RemoveDeviceModal: RemoveDeviceModalContainer,
};

export const modalNames = Object.keys(modals) as (keyof typeof modals)[];

interface ModalProps extends RouterParamsProps<{ modal: string[] }> {
  location: Location;
}
const ModalSelector: React.FunctionComponent<ModalProps> = ({
  params,
  location,
}: ModalProps) => {
  const modal: string | undefined = R.when<string[], string | undefined>(
    R.is(Array),
    R.last
  )(params.modal);
  if (!modal) {
    return null;
  }
  if (!modals[modal]) {
    throw Error(`Incorrect Modal Path: ${modal}`);
  }
  return React.createElement(modals[modal], { location, params });
};

export default ModalSelector;
