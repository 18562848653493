import { connect } from 'react-redux';
import { getFormValues, isInvalid } from 'redux-form/immutable';
import { formRecord, formName } from '../components/Profiles/ProfileForm';
import ProfileAdd from '../components/Profiles/ProfileAdd';
import { createProfile } from '../ducks/profiles';
import { ProfileRecord } from '../records';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { areProfilesAtLimit, areProfilesExceeded } from '../selectors';
import { fetchAccount } from '../ducks/account';

const values = getFormValues(formName);
const invalid = isInvalid(formName);

const mapStateToProps = state => {
  return {
    form: formRecord(values(state)),
    disabled: invalid(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onNextClick: () =>
    dispatch((dispatch, getState) =>
      dispatch(showCreateProfile(formRecord(values(getState()))))
    ),
});

const ProfileAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileAdd);

export function showCreateProfile(form) {
  return dispatch => {
    const navigate = getMultiPlatformNavigation();
    return (
      dispatch(
        createProfile(
          ProfileRecord.serialize(ProfileRecord.fromPayload(form.toJS()))
        )
      )
        .then(action =>
          dispatch(
            navigate({
              type: 'inner:replace',
              src: `/profiles/${action.payload.result}/add-device`,
            })
          )
        )
        /** After adding a new profile, we refetch user account to get the updated profile count. */
        .then(() => dispatch(fetchAccount()))
        .catch((error: AnyError) => handleApiErrorMessage(error))
    );
  };
}

ProfileAddContainer.redirect = () => (dispatch, getState) => {
  const state = getState();
  const navigate = getMultiPlatformNavigation();
  if (areProfilesExceeded(state)) {
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/modal/OverLimitModal',
      })
    );
  } else if (areProfilesAtLimit(state)) {
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/modal/ProfileAtLimitModal',
      })
    );
  }
};

export default ProfileAddContainer;
