import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import type { PanicButtonRulesAddDeviceFlyoverProps } from '../../containers/ProfileRules/PanicButtonRules/PanicButtonAddDeviceFlyoverContainer';
import PanicButtonRulesFlyover from './PanicButtonRulesFlyover';

const ADD_DEVICE_FLYOVER_TEST_ID = 'panic-button-rules-add-device-flyover';

const PanicButtonRulesAddDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickAddDevice,
}: PanicButtonRulesAddDeviceFlyoverProps) => {
  return (
    <PanicButtonRulesFlyover
      accountLocale={accountLocale}
      testId={ADD_DEVICE_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Add a device'),
        onClick: onClickAddDevice,
        testId: `${ADD_DEVICE_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android')}
      topbarText={t('Protect an Android device to enable the panic button')}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default PanicButtonRulesAddDeviceFlyover;
