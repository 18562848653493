import * as React from 'react';
import { t } from '../../lib/i18n';
import { shouldShowConversionElements } from '../../records/license';
import {
  Modal,
  Button,
  ModalStyledText,
  ButtonSize,
  Icon,
  IconType,
  IconColor,
  AlertBox,
  AlertBoxType,
  ButtonType,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import type { OverLimitModalProps } from '../../containers/Modal/OverLimitModalContainer';

const getModalInfo = (
  areProfilesExceeded: boolean,
  areDevicesExceeded: boolean
) => {
  if (areProfilesExceeded && !areDevicesExceeded) {
    return t(
      'Reactivate protection by upgrading your plan or removing profiles from your account.'
    );
  }

  if (areDevicesExceeded && !areProfilesExceeded) {
    return t(
      'Reactivate protection by upgrading your plan or removing devices from your account.'
    );
  }

  return t(
    'Reactivate protection by upgrading your plan or removing devices or profiles from your account.'
  );
};

const getRemoveProfileButton = (
  show: boolean,
  profileName: string | null,
  onClick: () => void
) =>
  !show
    ? []
    : [
        <Button
          key="button-2"
          size={ButtonSize.small}
          buttonType={ButtonType.plain}
          onClick={onClick}
          testId="delete-profile-action"
        >
          {t('Remove {{profileName}} profile', {
            profileName,
          })}
        </Button>,
      ];

const getRemoveDevicesButton = (show: boolean, onClick: () => void) =>
  !show
    ? []
    : [
        <Button
          key="button-3"
          size={ButtonSize.small}
          buttonType={ButtonType.plain}
          onClick={onClick}
          testId="delete-devices-action"
        >
          {t('Remove devices')}
        </Button>,
      ];

const OverLimitModal = ({
  license,
  profileId,
  profileName,
  canPurchase,
  areProfilesExceeded,
  areDevicesExceeded,
  referrer,
  onClickFooterButton,
  onClickDeleteChild,
  onClickClose,
  onClickDeleteDevices,
}: OverLimitModalProps) => {
  const isPartner = !shouldShowConversionElements(license);
  const showDeleteProfileAction = areProfilesExceeded && Boolean(profileId);
  const showDeleteDevicesAction = areDevicesExceeded;
  const infoText = getModalInfo(areProfilesExceeded, areDevicesExceeded);

  return (
    <Modal
      onClickClose={onClickClose}
      width={modalDimensions.regular.width}
      header={{
        icon: (
          <Icon type={IconType.exclamationCircle} color={IconColor.error} />
        ),
      }}
      title={t('Account limit exceeded')}
      buttons={[
        ...(!isPartner
          ? [
              <Button
                key="button"
                size={ButtonSize.medium}
                onClick={() =>
                  onClickFooterButton(
                    canPurchase,
                    referrer,
                    areDevicesExceeded,
                    areProfilesExceeded
                  )
                }
                testId="primary-action"
              >
                {canPurchase ? t('Upgrade now') : t('Ok')}
              </Button>,
            ]
          : []),
        ...getRemoveProfileButton(showDeleteProfileAction, profileName, () =>
          onClickDeleteChild(profileId)
        ),
        ...getRemoveDevicesButton(
          showDeleteDevicesAction,
          onClickDeleteDevices
        ),
      ]}
    >
      <ModalStyledText>
        {!isPartner
          ? t(
              'You’ve exceeded your account limit and no longer have access to protect your devices.'
            )
          : // copy for partners
            t(
              'Your account is currently over the limit. ' +
                "To unblock go to Settings and you can remove a device or child that you don't need to protect anymore."
            )}
      </ModalStyledText>

      {!isPartner && (
        <ModalStyledText>
          <AlertBox testId="par-overlimit-modal-alert" type={AlertBoxType.info}>
            {infoText}
          </AlertBox>
        </ModalStyledText>
      )}
    </Modal>
  );
};

export default OverLimitModal;
