import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import { hashHistory, createMemoryHistory } from 'react-router';
import thunk from 'redux-thunk';
import { fromJS } from 'immutable';
import * as Sentry from '@sentry/react';

import { envMiddlewares, envEnhancers } from './configureStore';
import * as reducers from '../reducers';
import withScroll from '../withscroll';
import sessionMiddleware from './sessionMiddleware';
import { CLEAR } from '../ducks/actionNames/app';
import { isTesting } from '../helpers/env';
import lockScreenMiddleware from './lockScreenMiddleware';
import {
  syncHistoryWithStore,
  routerMiddleware,
} from '../helpers/multiPlatformNavigation';
import { rootEpic, createAppEpicMiddleware } from '../epics';

export const historyType = isTesting() ? createMemoryHistory() : hashHistory;

export const createEnhancer = () => {
  const epicMiddleware = createAppEpicMiddleware();

  const middleware = [
    thunk,
    routerMiddleware(historyType),
    sessionMiddleware,
    lockScreenMiddleware,
    ...envMiddlewares,
    epicMiddleware,
  ];

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  const enhancers = [applyMiddleware(...middleware), ...envEnhancers];

  const enhancer = compose(...enhancers, sentryReduxEnhancer);

  return {
    epicMiddleware,
    enhancer,
  };
};

const appReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  const newState = action.type === CLEAR ? fromJS(action.payload) : state;
  return appReducer(newState, action);
};

export const createHistory = store => {
  const history = withScroll(
    syncHistoryWithStore(historyType, store, {
      selectLocationState: state => ({
        locationBeforeTransitions: state.getIn([
          'routing',
          'locationBeforeTransitions',
        ]),
      }),
    }),
    store
  );

  return history;
};

export const setupStore = initialState => {
  const { enhancer, epicMiddleware } = createEnhancer();
  const store = createStore(rootReducer, initialState, enhancer);
  const history = createHistory(store);
  epicMiddleware.run(rootEpic);
  return { store, history };
};
