/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { List } from 'immutable';
import { FlexLayout, Layout } from 'styleguide-react';
import { AccountRecord, ProductRecord, LicenseRecord } from '../../records';
import {
  PaymentProvider,
  PAYMENT_PROVIDER_APPLE,
  PAYMENT_PROVIDER_GOOGLEPLAY,
} from '../../constants';
import { ProductDuration } from '../../records/product';
import ProductDurationPicker from './ProductDurationPicker';
import { isBrowserPlatform } from '../../helpers';
import { shouldShowTwoYearsPromotionModal } from '../../actions/UpgradeActions';
import { trackClickedPurchaseButton } from '../../helpers/analytics';
import ProductList from './ProductList';
import UpgradeHero from './UpgradeHero';
import UpgradePremiumFeatures from './UpgradePremiumFeatures';
import UpgradeInfo from './UpgradeInfo';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import CustomPlanBox from './CustomPlanBox';
import { t } from '../../lib/i18n';
import { isFreeOrTrial } from '../../records/license';
import { getEquivalentProductWithDedicatedSupport } from '../../selectors';
import UpgradeAppleInfo from './UpgradeAppleInfo';
import type { UpgradeProps } from '../../containers/UpgradeContainer';

class UpgradeContent extends React.Component<
  UpgradeProps,
  {
    selectedProductCode: null | string;
  }
> {
  constructor(props: UpgradeProps) {
    super(props);
    this.state = {
      selectedProductCode: null,
    };
  }

  handleClickProduct = (product: ProductRecord) => {
    const {
      isPurchaseInProgress,
      paymentProvider,
      isRenew,
      areTwoYearProductsAvailable,
      areUpsellProducts,
      showTwoYearsPromotionModal,
      onClickProduct,
    } = this.props;

    if (isPurchaseInProgress) return;

    trackClickedPurchaseButton(paymentProvider, product.code);
    if (
      shouldShowTwoYearsPromotionModal(
        product.duration,
        isRenew,
        areTwoYearProductsAvailable,
        areUpsellProducts
      )
    ) {
      showTwoYearsPromotionModal(product.code);
    } else {
      this.setState({ selectedProductCode: product.code });
      onClickProduct(product.code);
    }
  };

  render() {
    const {
      productList,
      productListDedicatedSupport,
      account,
      license,
      planDuration,
      areTwoYearProductsAvailable,
      areUpsellProducts,
      isPurchaseInProgress,
      isProductDurationOneYear,
      isRenew,
      showTwoYearsPromotionModal,
      paymentProvider,
      isEnabledDedicatedSupportV2,
      onClickProduct,
      onClickOpenLink,
    } = this.props;
    const { selectedProductCode } = this.state;
    return (
      <FlexLayout mainaxis="column" mainaxisAlignment="center" flexShrink="0">
        <UpgradeHero />

        {isBrowserPlatform() &&
          areTwoYearProductsAvailable &&
          !areUpsellProducts && <ProductDurationPicker />}
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <ProductList
            account={account}
            planDuration={planDuration}
            license={license}
            products={productList}
            productListDedicatedSupport={productListDedicatedSupport}
            selectedProductCode={selectedProductCode}
            areUpsellProducts={areUpsellProducts}
            onClickProduct={this.handleClickProduct}
            isPurchaseInProgress={isPurchaseInProgress}
            isProductDurationOneYear={isProductDurationOneYear}
            isEnabledDedicatedSupportV2={isEnabledDedicatedSupportV2}
          />
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          {productListMobile(
            account,
            license,
            planDuration,
            productList,
            areUpsellProducts,
            isPurchaseInProgress,
            isProductDurationOneYear,
            selectedProductCode,
            isRenew,
            paymentProvider,
            showTwoYearsPromotionModal,
            areTwoYearProductsAvailable,
            isEnabledDedicatedSupportV2,
            onClickProduct,
            productListDedicatedSupport
          )}
        </RenderWhen>
        <FlexLayout
          mainaxis="row"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          marginLeft="24"
          marginRight="24"
        >
          <p className="UpgradeContent__additionalInfo">
            {t('*Panic Button is only available for Android')}
          </p>
        </FlexLayout>
        {paymentProvider === PAYMENT_PROVIDER_APPLE ? (
          <UpgradeAppleInfo onClickOpenLink={onClickOpenLink} />
        ) : (
          <UpgradeInfo
            areUpsellProducts={areUpsellProducts}
            planDuration={planDuration}
          />
        )}

        <UpgradePremiumFeatures />
      </FlexLayout>
    );
  }
}

const productListMobile = (
  account: AccountRecord,
  license: LicenseRecord,
  planDuration: ProductDuration,
  productList: List<ProductRecord>,
  areUpsellProducts: boolean,
  isPurchaseInProgress: boolean,
  isProductDurationOneYear: boolean,
  selectedProductCode: null | string,
  isRenew: boolean,
  paymentProvider: PaymentProvider,
  showTwoYearsPromotionModal: (productId: string) => any,
  areTwoYearProductsAvailable: boolean,
  isEnabledDedicatedSupportV2: boolean,
  onClickProduct: (productId: string) => any,
  productListDedicatedSupport?: List<ProductRecord>
) => {
  const recomendedPlan = productList.find(product => product.maxDevices === 10);
  let mobileProductList = List<ProductRecord>();

  if (
    recomendedPlan &&
    isFreeOrTrial(license.type) &&
    isProductDurationOneYear
  ) {
    mobileProductList = productList
      .filter(product => product.maxDevices !== 10)
      .unshift(recomendedPlan);
  } else {
    mobileProductList = productList;
  }

  return (
    <Layout marginTop="32">
      {mobileProductList.size > 0 ? (
        <FlexLayout mainaxis="column" mainaxisAlignment="center">
          {mobileProductList.map((product: ProductRecord) => {
            const upsellProduct = areUpsellProducts && !product.current;
            const productDedicatedSupport =
              getEquivalentProductWithDedicatedSupport(
                product,
                productListDedicatedSupport
              );

            const props = {
              account,
              product,
              productDedicatedSupport,
              license,
              isPurchaseInProgress,
              planDuration,
              selectedProductCode,
              upsellProduct,
              areUpsellProducts,
              isProductDurationOneYear,
              key: product.code,
              isEnabledDedicatedSupportV2,
              onClickProduct: () => {
                if (
                  paymentProvider === PAYMENT_PROVIDER_APPLE ||
                  paymentProvider === PAYMENT_PROVIDER_GOOGLEPLAY
                ) {
                  if (!isPurchaseInProgress) {
                    handleClickProduct(
                      paymentProvider,
                      onClickProduct,
                      product,
                      isRenew,
                      showTwoYearsPromotionModal,
                      areTwoYearProductsAvailable,
                      areUpsellProducts
                    );
                  }
                } else {
                  handleClickProduct(
                    paymentProvider,
                    onClickProduct,
                    product,
                    isRenew,
                    showTwoYearsPromotionModal,
                    areTwoYearProductsAvailable,
                    areUpsellProducts
                  );
                }
              },
            };

            return (
              <FlexLayout
                key={product.code}
                mainaxis="column"
                crossaxisAlignment="center"
                marginRight="32"
                marginBottom="24"
                marginLeft="32"
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <CustomPlanBox {...props} />
              </FlexLayout>
            );
          })}
        </FlexLayout>
      ) : null}
    </Layout>
  );
};

const handleClickProduct = (
  paymentProvider,
  onClickProduct,
  product: ProductRecord,
  isRenew: boolean,
  showTwoYearsPromotionModal: (productId: string) => any,
  areTwoYearProductsAvailable: boolean,
  areUpsellProducts: boolean
) => {
  trackClickedPurchaseButton(paymentProvider, product.code);
  if (
    shouldShowTwoYearsPromotionModal(
      product.duration,
      isRenew,
      areTwoYearProductsAvailable,
      areUpsellProducts
    )
  ) {
    showTwoYearsPromotionModal(product.code);
  } else {
    onClickProduct(product.code);
  }
};

export default UpgradeContent;
