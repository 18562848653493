import { BaseThunk } from '../store/state';
import { fromJS, NonEmptyMapRecord, List } from 'immutable';
import api from '../api';
import PlaceRecord from '../records/place';
import { tapReject } from '../helpers';
import { getPlaces, didPlacesInitialFetch } from '../selectors';
import { PlaceAddressData } from '../containers/FamilyLocator/types';

export const REQUEST_PLACES = 'REQUEST_PLACES';
export const RECEIVE_PLACES = 'RECEIVE_PLACES';
export const REQUEST_PLACES_ERROR = 'REQUEST_PLACES_ERROR';
export const REQUEST_ADD_PLACE = 'REQUEST_ADD_PLACE';
export const PLACE_REMOVE = 'PLACE_REMOVE';
export const PLACE_UPDATE = 'PLACE_UPDATE';
export const SEARCH_STRING_UPDATED = 'SEARCH_STRING_UPDATED';
export const SEARCH_RESULTS_UPDATED = 'SEARCH_RESULTS_UPDATED';
export const SEARCH_FETCHING_ADDRESSES = 'SEARCH_FETCHING_ADDRESSES';

export const PLACE_SESSION_TOKEN_REFRESH = 'PLACE_SESSION_TOKEN_REFRESH';

export default function places(
  state: NonEmptyMapRecord<{
    result: List<PlaceRecord>;
    isFetching: boolean;
    isFetchingAddresses: boolean;
    didInitialFetch: boolean;
    addressSearchString: string;
    addressSearchResults: PlaceAddressData[];
    searchSessionToken: string;
  }> = fromJS({
    result: [],
    isFetching: false,
    isFetchingAddresses: false,
    didInitialFetch: false,
    addressSearchString: '',
    addressSearchResults: [],
    searchSessionToken: '',
  }),
  action
) {
  switch (action.type) {
    case REQUEST_PLACES:
    case REQUEST_ADD_PLACE:
      return state.set('isFetching', true);
    case SEARCH_FETCHING_ADDRESSES:
      return state.set('isFetchingAddresses', action.payload.value);
    case SEARCH_STRING_UPDATED:
      return state.set('addressSearchString', action.payload.searchString);
    case SEARCH_RESULTS_UPDATED:
      return state.set('addressSearchResults', action.payload.results);
    case PLACE_UPDATE:
      return state.merge({
        isFetching: false,
        result: replaceStorePlace(state.get('result'), action.payload.data),
      });
    case RECEIVE_PLACES:
      return state.merge({
        isFetching: false,
        didInitialFetch: true,
        result: action.payload.records.places,
      });
    case REQUEST_PLACES_ERROR:
      return state.merge({
        isFetching: false,
      });
    case PLACE_REMOVE:
      return state.merge({
        isFetching: false,
        result: state
          .get('result')
          .filter(place => place.uid !== action.payload),
      });
    case PLACE_SESSION_TOKEN_REFRESH:
      return state.set('searchSessionToken', action.payload.value);
    default:
      return state;
  }
}

const replaceStorePlace = (places: List<PlaceRecord>, updatedPlace) =>
  places
    .filter(item => item.uid !== updatedPlace.uid)
    .push(PlaceRecord.fromPayload(updatedPlace))
    .sortBy(
      record => record!.name,
      (a, b) => a.localeCompare(b)
    );

export const requestPlacesError = () => {
  return {
    type: REQUEST_PLACES_ERROR,
  };
};

export function requestPlaces() {
  return {
    type: REQUEST_PLACES,
  };
}

export function receivePlaces(json) {
  return {
    type: RECEIVE_PLACES,
    payload: {
      records: {
        places: json.map(
          place => PlaceRecord.fromPayload(place) || PlaceRecord()
        ),
      },
    },
  };
}

export function updateFetchingAddresses(value: boolean) {
  return {
    type: SEARCH_FETCHING_ADDRESSES,
    payload: {
      value,
    },
  };
}

export function updateSearchString(searchString: string) {
  return {
    type: SEARCH_STRING_UPDATED,
    payload: {
      searchString,
    },
  };
}

export function updateSearchResults(results: PlaceAddressData[]) {
  return {
    type: SEARCH_RESULTS_UPDATED,
    payload: {
      results,
    },
  };
}

export function requestAddPlace() {
  return {
    type: REQUEST_ADD_PLACE,
  };
}

export function addPlace(body) {
  return dispatch => {
    dispatch(requestAddPlace());
    return api.places
      .post(body)
      .catch(tapReject(() => dispatch(requestPlacesError())));
  };
}
export const updateStorePlace = data => {
  return {
    type: PLACE_UPDATE,
    payload: {
      data,
    },
  };
};

export const updatePlace =
  (placeUid: string, placeData: PlaceRecord) => dispatch => {
    dispatch(requestPlaces());
    return api.place
      .put({ placeUid }, placeData)
      .then(() => dispatch(updateStorePlace(placeData)))
      .catch(tapReject(() => dispatch(requestPlacesError())));
  };

export function fetchPlaces() {
  return dispatch => {
    dispatch(requestPlaces());
    return api.places
      .get({})
      .then(json => dispatch(receivePlaces(json)))
      .catch(tapReject(() => dispatch(requestPlacesError())));
  };
}

export function fetchPlacesIfNeeded(): BaseThunk<Promise<any>> {
  return (dispatch, getState) => {
    const state = getState();
    if (didPlacesInitialFetch(state)) {
      return Promise.resolve(getPlaces(state));
    }
    return dispatch(fetchPlaces());
  };
}

export const removePlace = (place: PlaceRecord) => dispatch => {
  dispatch(requestPlaces());
  return api.place
    .delete({ placeUid: place.uid })
    .then(() => dispatch(removePlaceFromState(place.uid)));
};

export function removePlaceFromState(placeUid: string) {
  return {
    type: PLACE_REMOVE,
    payload: placeUid,
  };
}

export const updateToken = (value: number) => ({
  type: PLACE_SESSION_TOKEN_REFRESH,
  payload: {
    value,
  },
});
