import { AnyError } from '../errorHandling';
import { captureException } from '../sentry';

export const schoolOnboardingErrorLog = (error: AnyError) => {
  captureException(error, {
    level: 'log',
    tags: {
      schoolOnboarding: true,
    },
  });
};
