import * as React from 'react';
import WellbeingList from '../shared/WellbeingList';
import { t } from '../../../lib/i18n';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';
import { FlexLayout } from 'styleguide-react';
import { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';

interface FamilyTherapyListSectionProps {
  screenSize: ScreenSize;
}

const FamilyTherapyListSection = ({
  screenSize,
}: FamilyTherapyListSectionProps) => {
  return (
    <BackgroundGradient gradient="yolk-corner-button-right-large">
      <FlexLayout mainaxis="column" marginBottom="32">
        <WellbeingList
          iconTitle={isDesktop(screenSize)}
          colorScheme="red"
          title={t('What’s included?')}
          items={[
            t('Weekly 45-minute sessions'),
            t('Guidance based on your child’s online activity*'),
            t('Choice of phone and videocalls'),
            t('Weekly family wellbeing activities'),
          ]}
        />
      </FlexLayout>
    </BackgroundGradient>
  );
};

export default FamilyTherapyListSection;
