import { fromJS, NonEmptyMapRecord, Map, List } from 'immutable';
import * as Moment from 'moment-timezone';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import * as R from 'ramda';
import { Dispatch } from 'redux';
import api from '../api';
import { DeviceRecord } from '../records/device/device';
import { minutesCountDiff } from '../helpers/dates';
import {
  LOCATION_REQUEST_DEBOUNCE_TIME,
  DEVICE_TYPE_NONE,
  DeviceTypeName,
} from '../constants';
import {
  getProfileDevices,
  getCurrentTime,
  getDevice,
  getPrevDeviceState,
} from '../selectors';
import {
  updatePrevState,
  clearPrevState,
  ACTION_TARGET_DEVICE,
} from './previousState';
import { updateDeviceRecord } from './records';
import State, { BaseThunk } from '../store/state';
import {
  DevicePayload,
  DeviceRecord as DeviceRecordType,
} from '../records/device/types/Device.types';
import { DeviceOptionsPayload } from '../records/device/types/DeviceOptions.types';
import { DeviceSettingsPayload } from '../records/device/types/DeviceSettings.types';
import { DeviceAction } from './types/action/DeviceAction.types';
import { UserDeviceRecord } from '../records/device/userDevice';
import { UserDeviceRecord as UserDeviceRecordType } from '../records/device/types/UserDevice.types';
import {
  isMobilePlatform,
  mapPlatform,
} from '../records/device/devicePlatform';
import { mergeListObjListFields } from '../helpers/object';
import { ttl3Seconds } from '../lib/QApiCache/commonCacheStrategies';

export const REQUEST_DEVICES = 'REQUEST_DEVICES';
export const RECEIVE_DEVICES = 'RECEIVE_DEVICES';
export const UPDATE_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE';
export const UPDATE_LOCATION_REQUESTED = 'UPDATE_LOCATION_REQUESTED';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const REQUEST_UPDATE_ASSIGNED_PROFILE =
  'REQUEST_UPDATE_ASSIGNED_PROFILE';
export const REQUEST_UPDATE_ASSIGNED_PROFILE_ERROR =
  'REQUEST_UPDATE_ASSIGNED_PROFILE_ERROR';
export const RECEIVE_UPDATE_ASSIGNED_PROFILE =
  'RECEIVE_UPDATE_ASSIGNED_PROFILE';

export default function devices(
  state: NonEmptyMapRecord<{
    result: List<string | number>;
    isFetching: boolean;
    isUpdatingAssignedProfile: boolean;
    deviceType: DeviceTypeName;
    locationRequested: Map<string, Moment.Moment>;
    lastUpdated: number;
  }> = fromJS({
    result: [],
    isFetching: false,
    isUpdatingAssignedProfile: false,
    deviceType: DEVICE_TYPE_NONE,
    locationRequested: Map<string, Moment.Moment>(),
    lastUpdated: undefined,
  }),
  action: DeviceAction
) {
  switch (action.type) {
    case REQUEST_DEVICES:
      return state.set('isFetching', true);
    case RECEIVE_DEVICES:
      return state.merge({
        isFetching: false,
        lastUpdated: action.receivedAt,
        result: List(action.payload.result),
      });
    case UPDATE_DEVICE_TYPE:
      return state.set('deviceType', action.payload);
    case UPDATE_LOCATION_REQUESTED:
      return state.setIn(
        ['locationRequested', action.payload.deviceUId],
        action.payload.currentTime
      );
    case REMOVE_DEVICE:
      return state.merge({
        isFetching: false,
        result: state
          .get('result')
          .filterNot(value => value === action.payload),
      });
    case REQUEST_UPDATE_ASSIGNED_PROFILE:
      return state.set('isUpdatingAssignedProfile', true);
    case REQUEST_UPDATE_ASSIGNED_PROFILE_ERROR:
      return state.set('isUpdatingAssignedProfile', false);
    case RECEIVE_UPDATE_ASSIGNED_PROFILE:
      return state.set('isUpdatingAssignedProfile', false);
    default:
      return state;
  }
}

const denormalizeDevice = (deviceDataSerialized: Partial<DevicePayload>) =>
  decamelizeKeysDeep(R.omit(['settings', 'options'], deviceDataSerialized));

const denormalizeDeviceSettings = decamelizeKeysDeep;

// ACTIONS
function requestDevices(): DeviceAction {
  return {
    type: REQUEST_DEVICES,
  };
}

export const receiveDevices = R.pipe(
  R.map(DeviceRecord.fromPayload),
  (devices: DeviceRecordType[]) =>
    ({
      type: RECEIVE_DEVICES,
      payload: {
        result: devices.map(device => device.id),
        records: {
          devices: devices.reduce((acc, d) => ({ ...acc, [d.id]: d }), {}),
        },
      },
      receivedAt: Date.now(),
    } as DeviceAction)
);

// ------------------------------------------------------------

// REQUESTS
// -------------------------------------------------------------
export const fetchMainDevicesInfo = () =>
  api.devices.withCache(ttl3Seconds).get({});

const fetchDeviceSettings = (
  device: DevicePayload
): Promise<DeviceSettingsPayload> =>
  api.deviceSettings
    .withCache(ttl3Seconds)
    .get({ deviceId: device.uid }) as Promise<DeviceSettingsPayload>;

const fetchDeviceOptions = (
  device: DevicePayload
): Promise<DeviceOptionsPayload> =>
  isMobilePlatform(mapPlatform(device.platform))
    ? api.deviceOptions.withCache(ttl3Seconds).get<DeviceOptionsPayload>({
        deviceId: device.uid,
      })
    : Promise.resolve({} as DeviceOptionsPayload);
// ------------------------------------------------------------

const mergeDevicesAndSettings = mergeListObjListFields<
  DevicePayload,
  'settings'
>('settings');

const mergeDevicesAndOptions = mergeListObjListFields<DevicePayload, 'options'>(
  'options'
);

export const fetchDevices = () => (dispatch: Dispatch<DeviceAction>) => {
  dispatch(requestDevices());
  return fetchMainDevicesInfo().then(devices =>
    Promise.all([
      ...devices.map(fetchDeviceSettings),
      ...devices.map(fetchDeviceOptions),
    ]).then(devicesDetails => {
      const [settings, options] = [
        devicesDetails.slice(0, devices.length) as DeviceSettingsPayload[],
        devicesDetails.slice(devices.length) as DeviceOptionsPayload[],
      ];

      R.pipe(
        mergeDevicesAndOptions(options),
        mergeDevicesAndSettings(settings),
        receiveDevices,
        dispatch
      )(devices);
    })
  );
};

export function updateDeviceType(type: DeviceTypeName): DeviceAction {
  return {
    type: UPDATE_DEVICE_TYPE,
    payload: type,
  };
}

export function setLocationRequestTime(
  currentTime: Moment.Moment,
  deviceUId: string
): DeviceAction {
  return {
    type: UPDATE_LOCATION_REQUESTED,
    payload: {
      deviceUId,
      currentTime,
    },
  };
}

/**
 *  @returns mobile devices attached to a profile
 */
function findProfileMobileDevices(state: State, profileId: string) {
  return getProfileDevices(state, profileId).filter(
    device => device.type === 'MOBILE'
  );
}

/**
 * @returns true if (1) or (2) is true:
 * 1. last location-request is undefined or has expired
 * 2. time of last location is undefined or has expired
 */
function isLocationRequestNeeded(state: State, device: DeviceRecordType) {
  const currentTime = getCurrentTime(state);

  const lastLocationRequest = state
    .get('devices')
    .get('locationRequested')
    .get(device.uid);
  const locationRequestExpired =
    !lastLocationRequest ||
    minutesCountDiff(currentTime, Moment(lastLocationRequest)) >=
      LOCATION_REQUEST_DEBOUNCE_TIME;
  const lastLocationTime = device.get('location')?.get('time');
  const locationTimeExpired =
    !lastLocationTime ||
    minutesCountDiff(currentTime, Moment(lastLocationTime)) >=
      LOCATION_REQUEST_DEBOUNCE_TIME;
  return locationRequestExpired && locationTimeExpired;
}

export const requestLocationPush = (deviceUId: string) =>
  api.pushNotification
    .post(
      {
        name: 'update_location',
      },
      null,
      { deviceUId }
    )
    .then(() => Promise.resolve(deviceUId));

export const requestLocationIfNeeded =
  (profileId: string): BaseThunk<Promise<DeviceAction[]>, DeviceAction> =>
  (dispatch, getState) => {
    const state = getState();

    return Promise.all(
      // pick only mobile device objects that are connected to profile
      findProfileMobileDevices(state, profileId)
        // leave devices out that already have been requested
        .filter(device => isLocationRequestNeeded(state, device))
        .map((device: DeviceRecordType) => device.uid)
        .map(deviceUId => requestLocationPush(deviceUId))
        .map(promise =>
          promise.then(deviceUId =>
            dispatch(setLocationRequestTime(getCurrentTime(state), deviceUId))
          )
        )
        .toJS() // Promise.all doesn't accept ImmutableJS objects
    );
  };

export const panicPush = (deviceUId: string) =>
  api.pushNotification.post(
    {
      name: 'disable_panic_mode',
    },
    null,
    { deviceUId }
  );

export const updateDevice =
  (device: DeviceRecordType): BaseThunk =>
  (dispatch, getState) => {
    const prevDevice = getDevice(getState(), device.id);
    if (prevDevice) {
      const prevRecord = DeviceRecord.serialize(prevDevice);
      dispatch(updatePrevState(prevRecord, ACTION_TARGET_DEVICE));
    }
    return dispatch(updateDeviceRecord(device));
  };

export const modifyDevice = (device: DeviceRecordType) => () =>
  api.device.put(
    { deviceId: device.id },
    denormalizeDevice(DeviceRecord.serialize(device))
  );

export const modifyDeviceUserStatus =
  (device: { users: UserDeviceRecordType[]; uid: string }) => () =>
    Promise.all(
      device.users.map((user: UserDeviceRecordType) =>
        api.deviceUserStatus.post(
          decamelizeKeysDeep(UserDeviceRecord.serialize(user).status),
          null,
          { deviceUId: device.uid, userUId: user.uid }
        )
      )
    );

export const modifyDeviceSettings = (device: DeviceRecordType) => () =>
  api.deviceSettings.put(
    { deviceId: device.uid },
    denormalizeDeviceSettings(DeviceRecord.serialize(device).settings)
  );

export const revertDeviceUpdate = (): BaseThunk => (dispatch, getState) => {
  const prevState = getPrevDeviceState(getState());
  dispatch(updateDeviceRecord(prevState));
  dispatch(clearPrevState(ACTION_TARGET_DEVICE));
};

export const removeDevice =
  (device: { uid: string; id: string }) => (dispatch: Dispatch<DeviceAction>) =>
    api.device
      .delete({ deviceId: device.uid })
      .then(() => dispatch(removeDeviceFromState(device.id)));

export function removeDeviceFromState(deviceId: string): DeviceAction {
  return {
    type: REMOVE_DEVICE,
    payload: deviceId,
    receivedAt: Date.now(),
  };
}

/**
 * Performs an update of the user/s profile depending deviceUserId and profileId
 * - If there is a deviceUserId and a profileId assign the profile to an account (user device)
 * - If there is not a deviceUserId but a profileId assign the profile to all accounts (user device)
 * - If there is a deviceUserId but not a profileId unassign the profile from an account (user device)
 * - In other case unassign default the profile from all accounts (user devices)
 */
export const updateUserDeviceProfile =
  (
    device: DeviceRecordType,
    userDeviceUid?: string,
    profileId?: number | null
  ): BaseThunk =>
  dispatch => {
    if (userDeviceUid && profileId) {
      return dispatch(
        assignProfileToUserDevice(device, userDeviceUid, profileId)
      );
    }
    if (!userDeviceUid && profileId) {
      return dispatch(assignDefaultProfileToDevice(device, profileId));
    }
    if (userDeviceUid && !profileId) {
      return dispatch(unassignProfileFromUserDevice(device, userDeviceUid));
    }

    return dispatch(unassignDefaultProfileFromDevice(device));
  };

const updateUserDeviceRecordProfile = (
  device: DeviceRecordType,
  userDeviceUid: string,
  profileId: number | null
) => {
  const userDeviceIndex = device.users.findIndex(
    user => user.uid === userDeviceUid
  );
  return device.setIn(
    ['users', userDeviceIndex],
    device.users.get(userDeviceIndex).set('profileId', profileId)
  );
};

const updateDefaultUserDeviceRecordProfile = (
  device: DeviceRecordType,
  profileId: number | null
) =>
  device
    .setIn(
      ['users'],
      device.users.map(user => user.set('profileId', profileId))
    )
    .setIn(['defaultProfileId'], profileId);

const updateUserProfileInDeviceRecord =
  ({
    device,
    userDeviceUid,
    profileId,
  }: {
    device: DeviceRecordType;
    profileId?: number;
    userDeviceUid?: string;
  }) =>
  (dispatch: Dispatch) =>
    R.pipe(
      () =>
        userDeviceUid
          ? updateUserDeviceRecordProfile(
              device,
              userDeviceUid,
              profileId ?? null
            )
          : updateDefaultUserDeviceRecordProfile(device, profileId ?? null),
      record => updateDeviceRecord(record),
      dispatch
    );

const assignProfileToUserDevice =
  (
    device: DeviceRecordType,
    userDeviceUid: string,
    profileId: number
  ): BaseThunk =>
  (dispatch: Dispatch) =>
    api.deviceUserProfile
      .post(
        {
          user_uid: userDeviceUid,
          mid: device.uid,
        },
        null,
        {
          profileId,
        }
      )
      .then(
        updateUserProfileInDeviceRecord({
          device,
          userDeviceUid,
          profileId,
        })(dispatch)
      );

const assignDefaultProfileToDevice =
  (device: DeviceRecordType, profileId: number): BaseThunk =>
  dispatch =>
    Promise.all(
      device.users
        .map(user =>
          api.deviceUserProfile.post(
            {
              user_uid: user.uid,
              mid: device.uid,
            },
            null,
            {
              profileId,
            }
          )
        )
        .toJS()
    ).then(
      updateUserProfileInDeviceRecord({
        device,
        profileId,
      })(dispatch)
    );

const unassignProfileFromUserDevice =
  (device: DeviceRecordType, userDeviceUid: string): BaseThunk =>
  dispatch =>
    api.deviceUser
      .post(
        [
          {
            profile_id: null,
            user_uid: userDeviceUid,
          },
        ],
        null,
        {
          deviceUId: device.uid,
        }
      )
      .then(
        updateUserProfileInDeviceRecord({
          device,
          userDeviceUid,
        })(dispatch)
      );

const unassignDefaultProfileFromDevice =
  (device: DeviceRecordType): BaseThunk =>
  dispatch =>
    Promise.all(
      device.users
        .map(user =>
          api.deviceUser.post(
            [
              {
                profile_id: null,
                user_uid: user.uid,
              },
            ],
            null,
            {
              deviceUId: device.uid,
            }
          )
        )
        .toJS()
    ).then(updateUserProfileInDeviceRecord({ device })(dispatch));

export function requestUpdateAssignedProfile(): DeviceAction {
  return {
    type: REQUEST_UPDATE_ASSIGNED_PROFILE,
  };
}

export function requestUpdateAssignedProfileError(): DeviceAction {
  return {
    type: REQUEST_UPDATE_ASSIGNED_PROFILE_ERROR,
  };
}

export function receiveUpdateAssignedProfile(): DeviceAction {
  return {
    type: RECEIVE_UPDATE_ASSIGNED_PROFILE,
  };
}
