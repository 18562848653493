import { connect } from 'react-redux';
import { close } from '../../actions/ExternalUrlNavigationModalActions';
import { remove } from '../../actions/PlaceActions';

import RemovePlaceModal from '../../components/Modal/RemovePlaceModal';
import { getPlace } from '../../selectors';
import { navigateBack } from '../../ducks/routing';
import { resetAllPlaceForms } from '../../actions/FamilyLocatorActions';
import { PlaceRecord } from '../../records';

export const mapStateToProps = (state, { params: { placeUid } }) => {
  return {
    place: getPlace(state, placeUid) || PlaceRecord,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(close()),
  onRemovalConfirm: placeUid =>
    dispatch(() => {
      dispatch(resetAllPlaceForms());
      dispatch(navigateBack(-2));
      dispatch(remove(placeUid));
    }),
});

const RemovePlaceModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemovePlaceModal);

export default RemovePlaceModalContainer;
