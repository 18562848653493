import * as React from 'react';
import { Icon, IconSize, IconType, TransparentButton } from 'styleguide-react';

const UnifiedNowCardTitleInfo = ({ onClick }) => {
  return (
    <TransparentButton
      onClick={onClick}
      testId="unified-now-card__info-title"
      className="par-unified-now-card__info-title-button"
    >
      <Icon
        type={IconType.infoCircle}
        size={IconSize.lg}
        className="par-unified-now-card__info-title"
      />
    </TransparentButton>
  );
};

export default UnifiedNowCardTitleInfo;
