/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {
  Card,
  CardActionTitle,
  GlobalType,
  Icon,
  IconColor,
  IconSize,
  IconType,
} from 'styleguide-react';

interface NavigationOptionItemProps {
  title: string;
  icon: typeof IconType[keyof typeof IconType];
  testId?: string;
  onClick: () => void;
}

const NavigationOptionItem = ({
  title,
  icon,
  testId,
  onClick,
}: NavigationOptionItemProps) => {
  return (
    <Card
      testId={testId}
      type={GlobalType.gray}
      header={{
        title: (
          <CardActionTitle icon={icon} color={GlobalType.secondary}>
            {title}
          </CardActionTitle>
        ),
        actionElement: (
          <Icon
            type={IconType.chevronRight}
            size={IconSize.lg}
            color={IconColor.secondary}
          />
        ),
        wrapper: props => (
          <a
            {...props}
            onClick={e => {
              e.preventDefault();
              onClick();
            }}
          />
        ),
      }}
    />
  );
};

export default NavigationOptionItem;
