import * as React from 'react';
import {
  Avatar,
  AvatarSize,
  AvatarVariant,
  Icon as StyledIcon,
} from 'styleguide-react';
import { genderImageAssetFromPng } from '../../helpers';
import { svgAvatarsMap } from '../../helpers/avatarImageMaps';
import {
  getProfileNameInitial,
  isEmptyProfilePicture,
} from '../../records/profile';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import Icon from '../Icons/Icon';

type ProfileAvatarProps = {
  profile: ProfileRecord;
  size?: AvatarSize;
  icon?: typeof StyledIcon;
  onClick?: () => void;
  disabled?: boolean;
  discarded?: boolean;
  testId?: string;
};

const ProfileAvatar: React.FunctionComponent<ProfileAvatarProps> = ({
  profile,
  size,
  icon,
  onClick,
  disabled,
  discarded,
  testId,
}: ProfileAvatarProps) => {
  const getImage = genderImageAssetFromPng(svgAvatarsMap, svgAvatarsMap.boy[1]);

  const getAvatarContent = () => {
    if (isEmptyProfilePicture(profile.picture)) {
      return getProfileNameInitial(profile.name);
    }
    return <Icon path={getImage(profile.picture)} />;
  };

  const variant = isEmptyProfilePicture(profile.picture)
    ? AvatarVariant.text
    : AvatarVariant.default;

  return (
    <Avatar
      size={size}
      icon={icon}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      discarded={discarded}
      testId={testId}
      emptyBackground={isEmptyProfilePicture(profile.picture)}
    >
      {getAvatarContent()}
    </Avatar>
  );
};

export default ProfileAvatar;
