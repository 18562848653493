// https://qustodio.atlassian.net/wiki/display/BCK/Android+Auto-Login
import { fromJS, NonEmptyMapRecord } from 'immutable';
import * as queryString from 'query-string';
import api from '../api';
import * as qinit from '../qinit';
import { gaEvent, tapReject, isBrowserPlatform } from '../helpers';
import { handleApiErrorMessage, AnyError } from '../helpers/errorHandling';
import timeout from '../lib/timeout';
import { getAccount } from '../selectors';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

export const REQUEST_AUTOLOGIN_TOKEN = 'REQUEST_AUTOLOGIN_TOKEN';
export const REQUEST_AUTOLOGIN_TOKEN_ERROR = 'REQUEST_AUTOLOGIN_TOKEN_ERROR';
export const RECEIVE_AUTOLOGIN_TOKEN = 'RECEIVE_AUTOLOGIN_TOKEN';

export function requestAutologinToken() {
  return {
    type: REQUEST_AUTOLOGIN_TOKEN,
  };
}

export function requestAutologinTokenError() {
  return {
    type: REQUEST_AUTOLOGIN_TOKEN_ERROR,
  };
}

export function receiveAutologinToken() {
  return {
    type: RECEIVE_AUTOLOGIN_TOKEN,
  };
}

export function getAutologinUrl({ portalUrl, accountId, ssoToken, returnUrl }) {
  const query = queryString.stringify({
    account_id: accountId,
    sso_token: ssoToken,
    return_url: returnUrl,
  });
  return `${portalUrl}/login/sso?${query}`;
}

export function doAutologinToPortal(returnUrl) {
  return (dispatch, getState) => {
    const navigate = getMultiPlatformNavigation();
    gaEvent('spinner-to-fp', returnUrl || '/', '');
    dispatch(requestAutologinToken());
    return api.autologin
      .post({})
      .then(response => {
        return new Promise<string>(resolve => {
          timeout(
            () => {
              dispatch(receiveAutologinToken());
              const ssoToken = response.token;
              resolve(
                getAutologinUrl({
                  portalUrl: qinit.tenant.common.dashboard.home_link,
                  accountId: getAccount(getState()).id,
                  ssoToken,
                  returnUrl,
                })
              );
            },
            isBrowserPlatform() ? 0 : 700
          );
        }).then(url =>
          dispatch(
            isBrowserPlatform()
              ? navigate({
                  type: 'location:href',
                  src: url,
                })
              : navigate({
                  type: 'external',
                  src: url,
                })
          )
        );
      })
      .catch(tapReject(() => dispatch(requestAutologinTokenError())))
      .catch((error: AnyError) => handleApiErrorMessage(error));
  };
}

export default function reducer(
  state: NonEmptyMapRecord<{
    isFetching: boolean;
    error: boolean;
  }> = fromJS({
    isFetching: false,
    error: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_AUTOLOGIN_TOKEN:
      return state.merge({
        isFetching: true,
        error: false,
      });
    case REQUEST_AUTOLOGIN_TOKEN_ERROR:
      return state.merge({
        isFetching: false,
        error: true,
      });
    case RECEIVE_AUTOLOGIN_TOKEN:
      return state.merge({
        isFetching: false,
        error: false,
      });
    default:
      return state;
  }
}
