import * as React from 'react';
import { OrderedSet } from 'immutable';
import {
  Avatar,
  AvatarSize,
  Dropdown,
  DropdownAlignment,
  DropdownMenuPlacement,
  DropdownOption,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { ProfileRecord } from '../../records/profile/types/Profile.types';

type ProfilesForDeviceDropdownProps = {
  actionElement: JSX.Element;
  profiles: OrderedSet<ProfileRecord>;
  userUid?: string;
  testId?: string;
  onClickOpenDropdown: () => void;
  onClickAssignProfileFromDropdown: (profileId, userUid) => void;
};

const ProfilesForDeviceDropdown: React.FunctionComponent<
  ProfilesForDeviceDropdownProps
> = ({
  actionElement,
  profiles,
  userUid,
  testId,
  onClickOpenDropdown,
  onClickAssignProfileFromDropdown,
}: ProfilesForDeviceDropdownProps) => (
  <Dropdown
    testId={testId}
    actionElement={actionElement}
    maxHeight={200}
    minWidth={180}
    menuPlacement={DropdownMenuPlacement.bottom}
    fixedMenuPlacement
    alignment={DropdownAlignment.center}
    onActiveChange={onClickOpenDropdown}
    onChange={profileId => onClickAssignProfileFromDropdown(profileId, userUid)}
  >
    <DropdownOption
      text={t("Don't protect")}
      value=""
      icon={<Avatar size={AvatarSize.extraExtraSmall} />}
    />
    {profiles.map(profile => (
      <DropdownOption
        text={profile.name}
        value={profile.id}
        key={profile.id}
        icon={
          <ProfileAvatar size={AvatarSize.extraExtraSmall} profile={profile} />
        }
      />
    ))}
  </Dropdown>
);

export default ProfilesForDeviceDropdown;
