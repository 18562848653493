/* eslint-disable react/prop-types */
import * as React from 'react';
import { Layout } from 'styleguide-react';
import StudentSignaturesCardContainer from '../../../containers/Schools/StudentSignaturesCardContainer';
import PageLayout from '../../Layout/PageLayout';
import { ScreenSize } from '../../RenderWhen/RenderWhen';
import * as Records from '../../../records/profile/types/Profile.types';
import { SummaryDateRanges } from '../../../constants';
import DateRangeSelectorSchool from '../../DateRangeSelector/DateRangeSelectorSchool';

type AllSignaturesComponentType = React.FunctionComponent<{
  profile: Records.ProfileRecord;
  activeDataRange: SummaryDateRanges;
  onChangeDateRange: (dateRange: SummaryDateRanges) => void;
  maxSignatureActivityItems: number;
  showPercent: boolean;
  single: boolean;
}>;

const AllSignatures: AllSignaturesComponentType = ({
  profile,
  activeDataRange,
  onChangeDateRange,
  maxSignatureActivityItems,
  showPercent,
  single,
}) => (
  <PageLayout
    title={profile.name}
    configuration={[{ screenSize: ScreenSize.Desktop }]}
    actions={[{ actionName: 'back' }]}
  >
    <Layout marginBottom="8">
      <DateRangeSelectorSchool
        activeRange={activeDataRange}
        onChangeDateRange={onChangeDateRange}
      />
    </Layout>
    <Layout scrollY>
      <StudentSignaturesCardContainer
        maxSignatureActivityItems={maxSignatureActivityItems}
        showPercent={showPercent}
        single={single}
      />
    </Layout>
  </PageLayout>
);

export default AllSignatures;
