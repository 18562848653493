import { connect } from 'react-redux';
import * as R from 'ramda';
import { t } from '../../../lib/i18n';
import * as qinit from '../../../qinit';
import LocationRules from '../../../components/LocationRules/LocationRules';
import { dispatchModifyProfileRules } from '../../../actions/ProfileRulesActions';
import { delayedUpdateProfileRules } from '../../../ducks/profileRules';
import {
  getLocationRulesEnabled,
  getLocationRulesUpdateFrequency,
  getProfileMobileDevices,
  getProfileRules,
  getProfileDevicePlatformCount,
} from '../../../selectors';
import { gaEvent } from '../../../helpers/ga';
import { PLATFORM_ANDROID, PLATFORM_IOS } from '../../../constants';
import {
  trackLocationRule,
  LocationRuleNames,
} from '../../../helpers/analytics';
import { redirectFreeToPremiumAction } from '../../../actions/RedirectFreeToPremiumAction';
import { getFeatureFlag } from '../../../selectors/featureFlags';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { redirectToAddDeviceFlyover } from '../../../actions/redirectAddDeviceFlyover';
import { redirectToStartTrialFlyover } from '../../../actions/redirectStartTrialFlyover';

const PERIOD_SECONDS_MIN = 5 * 60;
const PERIOD_SECONDS_MAX = (23 * 60 + 55) * 60;

const isNotZero = R.complement(R.equals(0));

const mapStateToProps = (state, { params: { profileId } }) => ({
  locationEnabled: getLocationRulesEnabled(state, profileId) || false,
  updateFrequencySeconds: getLocationRulesUpdateFrequency(state, profileId),
  canChooseUpdateFrequency:
    isNotZero(
      getProfileDevicePlatformCount(state, profileId)(PLATFORM_ANDROID)
    ) && !getFeatureFlag(state, 'useSmartGeo'),
  hasIOSDevices: isNotZero(
    getProfileDevicePlatformCount(state, profileId)(PLATFORM_IOS)
  ),
});

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickMoreInfo: () => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'external:public:site',
        src: qinit.tenant.marketing.public_site
          .help_setup_location_update_frequency,
        openInIOS: false,
      })
    );
  },
  onToggleLocationEnabled: (toggleOn: boolean) => {
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleLocationRulesEnabled(toggleOn)
      )
    );
    gaEvent('location-rules', toggleOn ? 'on' : 'off', 'switch-enable');
    trackLocationRule(
      toggleOn
        ? LocationRuleNames.ENABLE_LOCATION_MONITORING
        : LocationRuleNames.DISABLE_LOCATION_MONITORING
    );
  },
  onChange: minutes =>
    dispatch((dispatch, getState) => {
      const seconds = minutes * 60;
      if (seconds === getLocationRulesUpdateFrequency(getState(), profileId)) {
        return;
      }
      const roundedSeconds = R.clamp(
        PERIOD_SECONDS_MIN,
        PERIOD_SECONDS_MAX,
        Math.ceil(seconds / 300) * 300
      );
      dispatch(
        delayedUpdateProfileRules(
          profileId,
          updateProfileRulesFrequency(roundedSeconds)
        )
      ).then(() => gaEvent('location-rules', 'click', 'time-selector'));
    }),
  onClickAuto: () =>
    navigator.notification.alert(
      t(
        // tslint:disable-next-line:max-line-length
        'iOS Location updating is automatic and occurs only while the device is in motion. The frequency of updates is about every 5 minutes.'
      ),
      () => undefined,
      t('iOS Location Tracking'),
      t('I got it')
    ),
});

const toggleLocationRulesEnabled = toggleOn => (state, profileId) =>
  getProfileRules(state, profileId).setIn(['location', 'enabled'], toggleOn);

const updateProfileRulesFrequency = seconds => profileRules =>
  profileRules.setIn(['location', 'locationUpdateFrequency'], seconds);

const LocationRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRules);

LocationRulesContainer.redirect = params => async (dispatch, getState) => {
  const profileId = parseInt(params.profileId, 10);
  const state = getState();
  const navigate = getMultiPlatformNavigation();
  await dispatch(
    redirectToAddDeviceFlyover(
      params.profileId,
      `/profiles/${profileId}/rules/locationRules/addDeviceFlyover`
    )
  ).then(redirectedToAddDevice => {
    if (redirectedToAddDevice) return;
    dispatch(
      redirectToStartTrialFlyover(
        `/profiles/${profileId}/rules/locationRules/startTrial`
      )
    ).then(redirectedToTrial => {
      if (redirectedToTrial) return;
      dispatch(
        redirectFreeToPremiumAction(
          params.profileId,
          'rules/locationRules/premiumFlyover'
        )
      ).then(wasRedirected => {
        if (!wasRedirected) {
          const hasMobileDevices = !getProfileMobileDevices(
            state,
            profileId
          ).isEmpty();
          if (!hasMobileDevices) {
            dispatch(
              navigate({
                type: 'inner:replace',
                src: `/profiles/${profileId}/rules/locationRules/mobileFlyover`,
              })
            );
          }
        }
      });
    });
  });
};

export default LocationRulesContainer;
