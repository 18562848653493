import { Dependencies } from '../../epics/middleware';
import { FeaturesOperations } from '../../records/features/features';
import {
  FeatureCode,
  FeaturePayload,
  FeatureStatus,
} from '../../records/features/types';
import { receiveFeaturesAction } from './actions';
import { Features } from './types/state';

export const requestReceiveFeatures = async (api: Dependencies['api']) => {
  const response = await api.features.get<FeaturePayload>({});

  const features = response.features.map(feature =>
    FeaturesOperations.fromPayload(feature)
  );
  return receiveFeaturesAction(features);
};

export const getStatusFromFeatures = (
  features: Features,
  code: FeatureCode
): FeatureStatus => {
  return features.getIn([code, 'status']);
};
