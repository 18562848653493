import * as React from 'react';
import {
  Card,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  TransparentButton,
} from 'styleguide-react';
import { classNames } from '../../../../helpers';
import Loader from '../../../base/Loader';
import { CardDimensions } from '../../../../constants';
import RenderWhen, { ScreenSize } from '../../../RenderWhen/RenderWhen';

export interface MiniActivitySummaryProps {
  className?: string;
  title?: string;
  value?: string;
  iconType?: typeof IconType[keyof typeof IconType];
  type: 'info' | 'loading' | 'error' | 'success' | 'no-activity' | 'blocked';
  testId: string;
  onClick?: (ev: React.SyntheticEvent) => void;
}

const MiniActivitySummary = ({
  title,
  value,
  iconType,
  className,
  type,
  testId,
  onClick,
}: MiniActivitySummaryProps) => {
  const getLeftIcon = () => {
    switch (type) {
      case 'error':
        return (
          <Icon
            type={IconType.exclamationCircle}
            size={IconSize.lg}
            color={IconColor.secondary}
            className="par-mini-activity-summary__left-icon par-mini-activity-summary__left-icon--error"
          />
        );
      case 'loading':
        return (
          <FlexLayout mainaxis="row" mainaxisAlignment="center" width="100%">
            <Loader size="xs" color="secondary" />
          </FlexLayout>
        );
      case 'success':
        return (
          <Icon
            type={IconType.thumbsUp}
            size={IconSize.lg}
            color={IconColor.grayLight}
            className="par-mini-activity-summary__left-icon par-mini-activity-summary__left-icon--success"
          />
        );
      case 'no-activity':
        return (
          <Icon
            type={iconType}
            size={IconSize.lg}
            color={IconColor.grayLight}
            className={classNames(
              'par-mini-activity-summary__left-icon',
              `par-mini-activity-summary__left-icon--no-activity`
            )}
          />
        );
      default:
        return (
          <Icon
            type={iconType}
            size={IconSize.lg}
            color={IconColor.secondary}
            className={classNames(
              'par-mini-activity-summary__left-icon',
              `par-mini-activity-summary__left-icon--${type}`
            )}
          />
        );
    }
  };

  const getMobileContent = () => {
    switch (type) {
      case 'loading':
        return (
          <FlexLayout
            mainaxis="row"
            crossaxisAlignment="center"
            minHeight="100%"
          >
            {getLeftIcon()}
          </FlexLayout>
        );
      case 'blocked':
      case 'info':
      case 'no-activity':
        return (
          <React.Fragment>
            <FlexLayout
              mainaxis="row"
              className={classNames(
                'par-mini-activity-summary__title-wrapper',
                'par-mini-activity-summary__title-wrapper--start',
                `par-mini-activity-summary__title--${type}`
              )}
            >
              {title && (
                <span
                  className={classNames(
                    'par-mini-activity-summary__title',
                    `par-mini-activity-summary__title--${type}`
                  )}
                >
                  {title}
                </span>
              )}
            </FlexLayout>
            <FlexLayout
              mainaxis="row"
              className="par-mini-activity-summary__value-wrapper"
              width="100%"
              crossaxisAlignment="center"
            >
              {getLeftIcon()}
              <span
                className={classNames(
                  'par-mini-activity-summary__value',
                  `par-mini-activity-summary__value--${type}`
                )}
              >
                {value}
              </span>
            </FlexLayout>
          </React.Fragment>
        );
      case 'error':
      case 'success':
      default:
        return (
          <React.Fragment>
            <FlexLayout mainaxis="row">{getLeftIcon()}</FlexLayout>
            <FlexLayout mainaxis="row">
              {title && (
                <span
                  className={classNames(
                    'par-mini-activity-summary__title',
                    `par-mini-activity-summary__title--${type}`
                  )}
                >
                  {title}
                </span>
              )}
            </FlexLayout>
          </React.Fragment>
        );
    }
  };

  const activitySummaryContent = (
    <React.Fragment>
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        <FlexLayout
          mainaxis="row"
          crossaxisAlignment="center"
          textAlignement="left"
          wrap="nowrap"
          padding="8"
          className="par-mini-activity-summary__wrapper"
        >
          {getLeftIcon()}
          {title && (
            <span
              className={classNames(
                'par-mini-activity-summary__title',
                `par-mini-activity-summary__title--${type}`
              )}
            >
              {title}
            </span>
          )}
          {value && (
            <span
              className={classNames(
                'par-mini-activity-summary__value',
                `par-mini-activity-summary__value--${type}`
              )}
            >
              {value}
            </span>
          )}
          {onClick && (
            <Icon
              type={IconType.chevronRight}
              color={IconColor.secondary}
              className={`par-mini-activity-summary__action-icon--${type}`}
            />
          )}
        </FlexLayout>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          textAlignement="left"
          wrap="nowrap"
          className="par-mini-activity-summary__wrapper"
        >
          {getMobileContent()}
        </FlexLayout>
      </RenderWhen>
    </React.Fragment>
  );

  const getActivitySummaryContent = () => {
    if (onClick) {
      return (
        <TransparentButton fillAvailableSpace onClick={onClick}>
          {activitySummaryContent}
        </TransparentButton>
      );
    }
    return activitySummaryContent;
  };

  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screenSize => (
        <Card
          className={classNames(
            'par-mini-activity-summary',
            className,
            `par-mini-activity-summary--${type}`
          )}
          testId={testId}
          width={
            screenSize === ScreenSize.Desktop ? CardDimensions.width : '50%'
          }
        >
          {getActivitySummaryContent()}
        </Card>
      )}
    </RenderWhen>
  );
};

export default MiniActivitySummary;
