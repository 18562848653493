import { Record } from 'immutable';
import {
  MinutesPerHourActivity,
  MinutesPerHourActivityMethods,
} from './types/MinutesPerHourActivity.types';
import { captureScreenTimeSelectedDayMismatch } from '../../helpers/activity';

export const MinutesPerHourActivityRecord = Record<
  MinutesPerHourActivity,
  MinutesPerHourActivityMethods
>({
  hour: '00:00',
  minutes: 0,
  defaultScreenTime: null,
  routineScreenTime: null,
});

const secondsToMinutes = (seconds?: number) =>
  seconds ? Math.ceil(seconds / 60) : 0;

MinutesPerHourActivityRecord.fromPayload = payload => {
  captureScreenTimeSelectedDayMismatch(payload);
  return MinutesPerHourActivityRecord({
    hour: payload.hour,
    minutes: secondsToMinutes(payload.screen_time_seconds),
    defaultScreenTime: payload?.default_rules_screen_time
      ? secondsToMinutes(payload.default_rules_screen_time)
      : null,
    routineScreenTime: payload?.routines_screen_time
      ? payload.routines_screen_time.map(routine => ({
          uid: routine.uid,
          minutes: secondsToMinutes(routine.seconds),
        }))
      : null,
  });
};
