import { connect } from 'react-redux';
import { Dispatch } from '../../../store/state';
import { goBackIfHistory } from '../../../ducks/routing';
import DelegationEnabledModal from '../../../components/Modal/DelegationEnabledModal';
import { postDelegationRulesModalDismissed } from '../../../ducks/account';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickClose: () => {
      dispatch(postDelegationRulesModalDismissed());
      dispatch(goBackIfHistory());
    },
  };
};

const DelegationEnabledModalContainer = connect(
  null,
  mapDispatchToProps
)(DelegationEnabledModal);

export type DelegationEnabledModalProps = ReturnType<typeof mapDispatchToProps>;

export default DelegationEnabledModalContainer;
