import * as React from 'react';
import { EmptyPlaceholder, Svg } from 'styleguide-react';
import { t } from '../../lib/i18n';
import InfoIcon from '../Icons/InfoIcon';

const SchoolFeatureDisabled: React.FC<{}> = () => (
  <EmptyPlaceholder
    icon={
      <Svg
        height="64"
        width="64"
        color="gray-light"
        testId="feature-disabled-icon"
      >
        <InfoIcon />
      </Svg>
    }
    fillAvailableSpace
    centered
    smallText
    text={t('Your school hasn’t enabled activity monitoring for parents')}
  />
);

export default SchoolFeatureDisabled;
