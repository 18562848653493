import { connect } from 'react-redux';
import ExternalUrlNavigationModal from '../../components/Modal/ExternalUrlNavigationModal';
import { getPersistentStorageItem } from '../../selectors';
import {
  close,
  navigateToLink,
  clickCheckbox,
} from '../../actions/ExternalUrlNavigationModalActions';

const mapStateToProps = (state, { params: { url, domain } }) => ({
  url,
  domain,
  checkboxChecked: !!getPersistentStorageItem(
    state,
    'externalNavigationAccepted'
  ),
});

const mapDispatchToProps = (dispatch, { params: { url } }) => ({
  onClickClose: () => dispatch(close()),
  onClickLink: () => dispatch(navigateToLink(url)),
  onClickCheckbox: isChecked => dispatch(clickCheckbox(isChecked)),
});

const ExternalUrlNavigationModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalUrlNavigationModal);

export default ExternalUrlNavigationModalContainer;
