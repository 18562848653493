import { goBackIfHistory } from '../ducks/routing';
import { getCurrentScenario } from '../selectors';
import * as Moment from 'moment-timezone';
import { BaseThunk, Dispatch } from '../store/state';
import api from '../api';
import { setRatingPopupLastDate } from '../ducks/account';
import { track, Events } from '../helpers/analytics';

export const markRatingPopupAsViewed =
  (): BaseThunk<any> => (dispatch: Dispatch) => {
    const now = Moment().toString();
    api.guestAccountOptions.post({ rating_popup_last_date: now });
    return dispatch(setRatingPopupLastDate(now));
  };

export const handleClose = (): BaseThunk<void> => (dispatch, getState) => {
  track(Events.DismissedRatingFlyover, {
    scenario: getCurrentScenario(getState()),
  });
  dispatch(goBackIfHistory());
};
