import * as React from 'react';
import {
  AlertBox,
  AlertBoxType,
  Avatar,
  AvatarSize,
  Button,
  Card,
  FlexLayout,
  FreeStyleListItem,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
  Layout,
  List,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import SmartAppBannerContainer from '../../containers/SmartAppBanner/SmartAppBannerContainer';
import ToolTip from '../ToolTip/ToolTip';
import BottomBar from '../BottomBar/BottomBar';
import PullRefresh from '../PullRefresh/PullRefresh';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { profileLimitExceeded } from '../../records/license';
import RenderWhen, {
  hasScreenSize,
  ScreenSize,
} from '../RenderWhen/RenderWhen';
import ProfileListItemContainer from '../../containers/ProfileListItemContainer';
import Experiment from '../../experiments/experiment';
import { isBrowserPlatform } from '../../helpers';
import Gift from '../Gift/Gift';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import ProfileStartFlyover from './ProfileStartFlyover';
import type { ProfileListProps } from '../../containers/ProfileListContainer';
import { ProfileStatus } from '../../records/profile';
import ProtectDeviceBanner from '../ProtectDeviceBanner/ProtectDeviceBanner';
import ProtectDeviceRowCard from './ProtectDeviceRowCard';
import TopbarContainer from '../../containers/Topbar/TopbarContainer';
import WellbeingBanner from '../Wellbeing/WellbeingBanner';

const ProfileList: React.FunctionComponent<ProfileListProps> = ({
  profiles,
  profileCount,
  license,
  isRefreshing,
  showGift,
  isTopbarVisible,
  isAccountLinkedToSchool,
  shouldDisplayWellbeingNewTag,
  isWellbeingBannerVisible,
  accountHasProtectedDevices,
  schoolOnboardingDone,
  onProfileListItemClick,
  onProfileAddClick,
  onIconMenuClick,
  onRefresh,
  onClickUpgradeToPremium,
  onClickProtectDevice,
  onCloseWellbeingBanner,
  onClickWellbeingBannerButton,
  onLoad,
}: ProfileListProps) => {
  const title = t('Your Family');

  // To acomodate the schools profiles design with the touchpoint banner a the bottom, we cannot use the built-in card from `PageLayout`,
  // so when the account is linked to schools, we wrap our content in a card component instead of using `mainInsideCard` prop.
  const usingBuiltInCardLayout = !isAccountLinkedToSchool;

  const showSchoolOnboardingDoneAlertbox =
    isAccountLinkedToSchool &&
    !accountHasProtectedDevices &&
    schoolOnboardingDone;

  const getAddDeviceListItem = () => {
    if (isAccountLinkedToSchool && !accountHasProtectedDevices) return null;

    return renderAddChild({
      title: t('Add a child'),
      testId: 'addChild',
      onClick: onProfileAddClick,
    });
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  return (
    <PageLayout
      title={title}
      className="ProfileList"
      configuration={[
        {
          screenSize: ScreenSize.TabletOrDesktop,
          columns: 1,
          mainInsideCard: usingBuiltInCardLayout,
          titleCentered: true,
        },
        {
          screenSize: ScreenSize.All,
          pullToRefresh: (
            <PullRefresh isFetching={isRefreshing} onRefresh={onRefresh} />
          ),
        },
        {
          screenSize: ScreenSize.MobileOrTablet,
          removePaddings: true,
        },
      ]}
      actions={[
        {
          actionName: 'menu',
          onClick: onIconMenuClick,
          screenSize: ScreenSize.MobileOrTablet,
        },
        showGift
          ? {
              actionName: 'gift',
              screenSize: ScreenSize.MobileOrTablet,
              icon: (
                <Gift
                  className="ProfileList__Gift"
                  level={isBrowserPlatform() ? 'white' : 'brand'}
                  background
                />
              ),
            }
          : { actionName: '' },
      ]}
    >
      <PageSectionLayout placeholder="topbar">
        {isTopbarVisible && (
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <SmartAppBannerContainer />
          </RenderWhen>
        )}
      </PageSectionLayout>

      <PageSectionLayout placeholder="card-banner">
        {(usingBuiltInCardLayout || hasScreenSize(ScreenSize.Mobile)) && (
          <TopbarContainer />
        )}
      </PageSectionLayout>

      <PageSectionLayout side="main">
        {!usingBuiltInCardLayout &&
          hasScreenSize(ScreenSize.TabletOrDesktop) && <TopbarContainer />}

        <WrapInCard when={!usingBuiltInCardLayout}>
          {profileCount === 0 && (
            <FlexLayout mainaxis="column" crossaxisAlignment="center">
              <ProfileStartFlyover onClickStart={onProfileAddClick} />
            </FlexLayout>
          )}

          {showSchoolOnboardingDoneAlertbox && (
            <FlexLayout mainaxis="column" marginBottom="16">
              {SchoolOnboardingDoneAlertbox()}
            </FlexLayout>
          )}

          {profileCount !== 0 && (
            <Experiment
              experimentName="kidDeviceSetupFromParentsApp"
              componentId="profileList"
            >
              <List
                useNativeColumns={false}
                testId="profileListWrapper"
                columns={hasScreenSize(ScreenSize.Desktop) ? 3 : 2}
                horizontalOrientation={hasScreenSize(ScreenSize.MobileOrTablet)}
                allowWrapping={hasScreenSize(ScreenSize.MobileOrTablet)}
              >
                {profiles.map(
                  renderProfile.bind(
                    null,
                    onProfileListItemClick,
                    isAccountLinkedToSchool
                  )
                )}
                {getAddDeviceListItem()}
              </List>
            </Experiment>
          )}
          {isWellbeingBannerVisible && (
            <WellbeingBanner
              shouldDisplayWellbeingNewTag={shouldDisplayWellbeingNewTag}
              onCloseBanner={onCloseWellbeingBanner}
              onClickButton={onClickWellbeingBannerButton}
            />
          )}
        </WrapInCard>

        {isAccountLinkedToSchool && (
          <React.Fragment>
            {!accountHasProtectedDevices ? (
              <Layout marginTop="16" className="ProtectDeviceLayout">
                <ProtectDeviceBanner
                  compact={hasScreenSize(ScreenSize.MobileOrTablet)}
                  onClick={() => onClickProtectDevice(true)}
                />
              </Layout>
            ) : (
              <ProtectDeviceRowCard
                onClick={() => onClickProtectDevice(false)}
              />
            )}
          </React.Fragment>
        )}
      </PageSectionLayout>

      <PageSectionLayout placeholder="after-pullrefresh">
        {profileLimitExceeded(license, profileCount) && (
          <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
            <Experiment
              experimentName="kidDeviceSetupFromParentsApp"
              componentId="addProfile"
            >
              <BottomBar fixed>
                <React.Fragment>
                  <ToolTip className="OverLimitAlert__ToolTip">
                    <strong>{t('Plan limit reached')}</strong>
                    <p>
                      {t(
                        'Expand your plan to continue protecting your kids and their devices.'
                      )}
                    </p>
                  </ToolTip>
                  <Button
                    className="ProfileList__upgrade-premium-button"
                    onClick={onClickUpgradeToPremium}
                  >
                    {t('Upgrade now')}
                  </Button>
                </React.Fragment>
              </BottomBar>
            </Experiment>
          </RenderWhen>
        )}
      </PageSectionLayout>
    </PageLayout>
  );
};

const SchoolOnboardingDoneAlertbox = () => (
  <AlertBox
    className="SchoolOnboardingDoneAlertbox"
    data-testId="AlertBox--get-started"
    type={AlertBoxType.info}
    icon={<Icon type={IconType.rocket} size={IconSize.lg} />}
    showInfoIcon
    rounded
  >
    {t(
      '{1}Let’s get started!{/1} Select your child to view their online school activity.',
      { 1: (str: string) => <strong>{str}</strong> }
    )}
  </AlertBox>
);

const renderProfile = (
  onClick: (id: number, status?: ProfileStatus) => void,
  isAccountLinkedToSchool: boolean,
  profile: ProfileRecord,
  index: number
) => (
  <FreeStyleListItem noBorder>
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      key={index}
      margin="16"
    >
      <ProfileListItemContainer
        showStatus={!isAccountLinkedToSchool}
        profileId={profile.id}
        onClick={() => onClick(profile.id, profile.status)}
      />
    </FlexLayout>
  </FreeStyleListItem>
);

const renderAddChild = ({
  title,
  onClick,
  testId,
}: {
  title: React.ReactElement;
  onClick: () => void;
  testId: string;
}) => (
  <FreeStyleListItem>
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      flexGrow="1"
      paddingTop="24"
      paddingBottom="24"
      paddingLeft="56"
      paddingRight="56"
      margin="16"
    >
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
      >
        <Avatar
          data-testId={`Avatar__${testId}`}
          emptyBackground
          size={AvatarSize.large}
          onClick={onClick}
        >
          <Icon
            color={IconColor.regular}
            type={IconType.plus}
            size={IconSize.x2}
          />
        </Avatar>
      </FlexLayout>
      <Label data-testId={`Label__${testId}`} className="ProfileList__addChild">
        {title}
      </Label>
    </FlexLayout>
  </FreeStyleListItem>
);

type WrapInCardProps = {
  when: boolean;
  children: React.ReactNode;
};
const WrapInCard: React.FunctionComponent<WrapInCardProps> = ({
  when,
  children,
}: WrapInCardProps) => {
  if (!when) return <React.Fragment>{children}</React.Fragment>;

  return (
    <Card className="ProfileList__CardWrapper">
      <Layout className="CardWrapper__Layout">{children}</Layout>
    </Card>
  );
};

export default ProfileList;
