import * as React from 'react';
import { GlobalType, IconSize, IconType, Tag } from 'styleguide-react';
import { getDeviceAdvanceProtectionStatus } from '../../businessLogic/devices';
import { DeviceRecord } from '../../records/device/types/Device.types';
import { t } from '../../lib/i18n';
import { isAdvanceAntiTamperingFeatureEnabled } from '../../businessLogic/advanceAntiTampering';
import { DeviceProtectionStatus } from '../../records/device';

type DeviceItemAntiTamperingStatusProps = {
  device: DeviceRecord;
  protectionStatus: DeviceProtectionStatus;
};

const DeviceItemAntiTamperingStatus = ({
  device,
  protectionStatus,
}: DeviceItemAntiTamperingStatusProps) => {
  if (protectionStatus === 'ProtectionTampered') return null;
  if (!isAdvanceAntiTamperingFeatureEnabled()) return null;

  const antiTamperingStatus = getDeviceAdvanceProtectionStatus(device);
  if (antiTamperingStatus === 'unknown') return null;
  if (antiTamperingStatus === 'disabled') {
    return (
      <Tag
        testId="ios-tamperable-tag"
        text={t('Anti-tampering')}
        // FIXME(rocket): Replace this with the corresponding IconType
        iconType="fa-shield-slash"
        iconProps={{
          type: 'fa-shield-slash',
          size: IconSize.lg,
        }}
        type={GlobalType.gray}
      />
    );
  }

  return (
    <Tag
      testId="ios-non-tamperable-tag"
      text={t('Anti-tampering')}
      iconType={IconType.shieldCheck}
      iconProps={{
        type: IconType.shieldCheck,
        size: IconSize.lg,
      }}
      type={GlobalType.success}
    />
  );
};

export default DeviceItemAntiTamperingStatus;
