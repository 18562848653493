import { goBackIfHistory } from '../ducks/routing';
import { getProfileRules, getYoutubeAppRules } from '../selectors';
import { getToday } from '../selectors/shared';
import { dispatchModifyProfileRules } from './ProfileRulesActions';
import {
  WEBFILTER_BLOCK,
  WEBFILTER_ALLOW,
  DOMAIN_YOUTUBE,
  PLATFORM_ANDROID,
  SUBDOMAIN_MOBILE_YOUTUBE,
  VideoSources,
} from '../constants';
import { showYoutubeDayDial, setYoutubeDayDial } from '../ducks/profileRules';
import State, { BaseThunk } from '../store/state';
import { trackSetupVideoRule, SetupVideoRuleNames } from '../helpers/analytics';
import { toggleAppEnabled } from './AppRulesActions';
import { AppRuleRecord, makeAppKey } from '../records/profileRules';
import { setLastRuleTypeModified, RuleType } from '../ducks/appRate';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

const setWebRules = (state: State, profileId: string, action) =>
  getProfileRules(state, profileId)
    .setIn(['web', 'domains', DOMAIN_YOUTUBE], action)
    .setIn(['web', 'domains', SUBDOMAIN_MOBILE_YOUTUBE], action);

export const clickBack = () => dispatch => {
  dispatch(goBackIfHistory());
};

export const toggleDayDial = (id: string) => (dispatch, getState) => {
  const currentDay = getToday(getState());
  const showDayDialAppKey = getState()
    .get('profileRules')
    .get('youtubeRuleDayDial');
  dispatch(setYoutubeDayDial(currentDay)); // preselect current day
  // hide dial if already shown for that app
  dispatch(showYoutubeDayDial(showDayDialAppKey !== id ? id : null));
};

const toggleYoutubeWebRule =
  (toggleOn: boolean) => (state: State, profileId: string) =>
    setWebRules(state, profileId, toggleOn ? WEBFILTER_BLOCK : WEBFILTER_ALLOW);

export const toggleYoutubeWebRuleEnabled =
  (profileId: string, toggleOn: boolean) => dispatch => {
    trackSetupVideoRule(
      toggleOn ? SetupVideoRuleNames.DisableWeb : SetupVideoRuleNames.EnableWeb,
      VideoSources.Youtube
    );
    dispatch(
      dispatchModifyProfileRules(profileId, toggleYoutubeWebRule(toggleOn))
    );
  };

export const openYoutubeModal = (profileId, toggleOn) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/rules/youtube-rules/modal/YoutubeAgreementWebBlockModal/toggleOn/${toggleOn}`,
    })
  );
};

export const userAgreementWeb =
  (profileId: string, toggleOn: boolean) => dispatch => {
    if (toggleOn) {
      dispatch(setLastRuleTypeModified(RuleType.YoutubeMonitoring));
      dispatch(toggleYoutubeWebRuleEnabled(profileId, toggleOn));
    } else {
      dispatch(openYoutubeModal(profileId, toggleOn));
    }
  };

const getTrackInfo = (
  rule: AppRuleRecord,
  toggleOn: boolean
): SetupVideoRuleNames => {
  if (rule.platform === PLATFORM_ANDROID) {
    return toggleOn
      ? SetupVideoRuleNames.EnableAndroidApp
      : SetupVideoRuleNames.DisableAndroidApp;
  }
  return toggleOn
    ? SetupVideoRuleNames.EnableIosApp
    : SetupVideoRuleNames.DisableIosApp;
};

export const toggleYoutubeAppEnabled =
  (profileId: string, appKey: string, toggleOn: boolean) =>
  (dispatch, getState) => {
    const currRule = getYoutubeAppRules(getState(), profileId).find(
      rule => rule && makeAppKey(rule) === appKey
    );

    if (!currRule) {
      throw new Error(`Could not find app "${appKey}"`);
    }

    trackSetupVideoRule(getTrackInfo(currRule, toggleOn), VideoSources.Youtube);
    return dispatch(toggleAppEnabled(profileId, appKey, toggleOn));
  };

export const navigateToYoutubeAppRules =
  (profileId: string | number): BaseThunk =>
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/rules/youtube-rules`,
      })
    );
  };
