import * as React from 'react';
import {
  Icon,
  IconColor,
  IconFamily,
  GlobalType,
  IconType,
  FlexLayout,
  ActionInput,
  Dropdown,
  DropdownOption,
  IconSize,
  DeactivableContent,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { when } from '../../helpers/render';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  hasFullQuotaInEveryDay,
  hasZeroQuotaInEveryDay,
} from '../../businessLogic/timeRules/timeQuota';
import { AppRuleRecord } from '../../records/profileRules';
import { APPLICATION_BLOCKED } from '../../constants';

export type DropdownType = 'Blocked' | 'Allowed' | 'SetLimits';

type DropdownOptionTypes = [DropdownType, DropdownType, ...DropdownType[]];

export type DropdownComponentsFactory = {
  type: DropdownType;
  showInOrder: DropdownOptionTypes;
  DropdownStatus: JSX.Element;
  DropdownOption: JSX.Element;
};

type DropdownComponentsFactoryProps = {
  type: DropdownType;
  dropdownOptionText: string;
  dropdownStatusText: string;
  textColor: GlobalType;
  iconType: string;
  iconFamily: IconFamily;
  iconColor: IconColor;
  showInOrder: DropdownOptionTypes;
  testIdPrefix: string;
  isDisabled: boolean;
};

type ResponsiveLayoutProps = {
  children: ({ isMobile }: { isMobile: boolean }) => JSX.Element;
};

export const ResponsiveLayout = ({ children }: ResponsiveLayoutProps) => (
  <React.Fragment>
    <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
      <FlexLayout mainaxis="column" minWidth="200">
        {children({ isMobile: false })}
      </FlexLayout>
    </RenderWhen>

    <RenderWhen screenSize={ScreenSize.Mobile}>
      <FlexLayout mainaxis="column">{children({ isMobile: true })}</FlexLayout>
    </RenderWhen>
  </React.Fragment>
);

interface DropDownLayoutProps {
  isMobile: boolean;
  children: React.ReactNode;
}

const DropDownLayout = ({ isMobile, children }: DropDownLayoutProps) => {
  return isMobile ? (
    <FlexLayout mainaxis="row" className="AppRulesDropDownStatus__pull-down">
      {children}
    </FlexLayout>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

const dropdownComponentsFactory = ({
  type,
  textColor,
  iconType,
  iconFamily,
  iconColor,
  dropdownOptionText,
  dropdownStatusText,
  showInOrder,
  testIdPrefix,
  isDisabled,
}: DropdownComponentsFactoryProps): DropdownComponentsFactory => {
  const status = (
    <ResponsiveLayout>
      {({ isMobile }) => (
        <DropDownLayout isMobile={isMobile}>
          <ActionInput
            className={
              !isMobile ? 'AppRulesDropDownStatus__actionInput--desktop' : ''
            }
            testId={
              isDisabled
                ? `disabled-info-${testIdPrefix}-${type}`
                : `status-${testIdPrefix}-${type}`
            }
            compact={isMobile}
            textColor={when(!isMobile, textColor)}
            text={when(
              !isMobile,
              <DeactivableContent disabled={isDisabled}>
                <span title={t(dropdownStatusText)}>
                  {t(dropdownStatusText)}
                </span>
              </DeactivableContent>
            )}
            iconLeft={
              <Icon
                square
                type={iconType}
                color={iconColor}
                family={iconFamily}
                size={when(isMobile, IconSize.lg)}
              />
            }
            iconRight={
              <Icon
                type={isDisabled ? IconType.infoCircle : IconType.angleDown}
                color={isDisabled ? IconColor.black : IconColor.neutral}
              />
            }
          />
        </DropDownLayout>
      )}
    </ResponsiveLayout>
  );

  const option = (
    <DropdownOption
      key={type}
      value={type}
      text={t(dropdownOptionText)}
      icon={
        <Icon square type={iconType} color={iconColor} family={iconFamily} />
      }
    />
  );

  return {
    type,
    showInOrder,
    DropdownStatus: status,
    DropdownOption: option,
  };
};

export type DropDownCardProps = {
  quotas: AppRuleRecord['quotas'];
  name: string;
  action: AppRuleRecord['action'];
  isDisabled: boolean;
  onChange: (options: { type: DropdownType; isMobile: boolean }) => void;
  onDisabledClick: () => void;
};

const createDropdownComponents = ({
  testIdPrefix,
  selectedType,
  isDisabled,
}: {
  testIdPrefix: string;
  selectedType: DropdownType;
  isDisabled: boolean;
}): DropdownComponentsFactory[] => {
  return [
    dropdownComponentsFactory({
      type: 'Allowed',
      dropdownStatusText: 'Allowed',
      dropdownOptionText: 'Allow',
      textColor: isDisabled ? GlobalType.gray : GlobalType.primary,
      iconType: IconType.check,
      iconFamily: IconFamily.solid,
      iconColor: isDisabled ? IconColor.grayLight : IconColor.primary,
      showInOrder: ['Blocked', 'SetLimits'],
      testIdPrefix,
      isDisabled,
    }),
    dropdownComponentsFactory({
      type: 'Blocked',
      dropdownStatusText: 'Blocked',
      dropdownOptionText: 'Block',
      textColor: GlobalType.error,
      iconType: IconType.ban,
      iconFamily: IconFamily.solid,
      iconColor: IconColor.error,
      showInOrder: ['Allowed', 'SetLimits'],
      testIdPrefix,
      isDisabled,
    }),
    dropdownComponentsFactory({
      type: 'SetLimits',
      dropdownStatusText: 'Limits set',
      dropdownOptionText:
        selectedType === 'SetLimits' ? 'Edit limits' : 'Set limits',
      textColor: GlobalType.secondary,
      iconType: IconType.stopWatch,
      iconFamily: IconFamily.regular,
      iconColor: IconColor.secondary,
      showInOrder: ['Allowed', 'Blocked', 'SetLimits'],
      testIdPrefix,
      isDisabled,
    }),
  ];
};

const selectDropDownComponentFactory = (
  selectedType: DropdownType,
  components: ReadonlyArray<DropdownComponentsFactory>
) => {
  const selectedAction = components.find(({ type }) => selectedType === type);
  return selectedAction as DropdownComponentsFactory;
};

const selectDropdownOptions = (
  selected: DropdownComponentsFactory,
  components: ReadonlyArray<DropdownComponentsFactory>
): JSX.Element[] => {
  const { showInOrder } = selected;

  return [...components]
    .sort((x, y) => showInOrder.indexOf(x.type) - showInOrder.indexOf(y.type))
    .filter(({ type }) => showInOrder.includes(type))
    .map(({ DropdownOption }) => DropdownOption);
};

const getStatusFromQuota = (quotas: AppRuleRecord['quotas']): DropdownType => {
  const quotasObj = quotas.toJS();
  if (hasFullQuotaInEveryDay(quotasObj)) return 'Allowed';
  if (hasZeroQuotaInEveryDay(quotasObj)) return 'Blocked';
  return 'SetLimits';
};

const getDropdownCardTypeStatus = (
  action: AppRuleRecord['action'],
  quotas: AppRuleRecord['quotas']
): DropdownType => {
  if (action === APPLICATION_BLOCKED) return 'Blocked';
  return getStatusFromQuota(quotas);
};

interface ClickableProps {
  onClick?: () => void;
  children: React.ReactNode;
  className: string;
}

const Clickable = ({ onClick, children, ...props }: ClickableProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div onClick={onClick} {...props}>
    {children}
  </div>
);

const DropDownCard = ({
  name,
  quotas,
  action,
  isDisabled,
  onChange,
  onDisabledClick,
}: DropDownCardProps) => {
  const initialType = getDropdownCardTypeStatus(action, quotas);
  const [type, setType] = React.useState<DropdownType>(initialType);
  React.useEffect(() => setType(initialType), [initialType]);

  const components = createDropdownComponents({
    testIdPrefix: name,
    selectedType: type,
    isDisabled,
  });
  const factorySelected = selectDropDownComponentFactory(type, components);

  return (
    <ResponsiveLayout>
      {({ isMobile }) => (
        <Clickable
          data-testId="AppRulesCard"
          className="AppRulesCard__Clickable"
          onClick={isDisabled ? onDisabledClick : undefined}
        >
          <Dropdown
            actionElement={factorySelected.DropdownStatus}
            asModal={isMobile}
            header={when(isMobile, <strong>{name}</strong>)}
            footer={when(isMobile, <strong>{t('Cancel')}</strong>)}
            onChange={(selectedType: DropdownType) =>
              onChange({ type: selectedType, isMobile })
            }
            testId="AppRulesCard"
          >
            {isDisabled
              ? []
              : selectDropdownOptions(factorySelected, components)}
          </Dropdown>
        </Clickable>
      )}
    </ResponsiveLayout>
  );
};

export default DropDownCard;
