import { KeyValues } from './types/KeyValue.types';

export const keyValuesToObject = <In extends KeyValues, Out>(data: In) =>
  Array.isArray(data)
    ? data?.reduce(
        (acc, cur) => ({ ...acc, [cur.key]: cur.value }),
        {} as Out
      ) ?? {}
    : {};

export const objectToKeyValues = <In, Out extends KeyValues>(data: In) =>
  Object.entries(data).map(([key, value]) => ({
    key,
    value,
  })) as Out;
