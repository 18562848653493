import * as React from 'react';
import {
  Icon,
  Layout,
  List,
  SelectableListItem,
  Typography,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import RoutineStepWrapper, {
  RoutineStepWrapperHandlerProps,
} from '../RoutineStepWrapper';
import { PolicyAction } from '../../../../records/routines/policy/types/Policy.types';
import { routineWebCategoriesIconsList } from '../../helpers/routineIcons';
import { RoutineWebCategory } from '../../../../records/routines/policy/types/PolicyWebCategory.types';
import { RoutineMode, WebRuleExceptions } from '../../routines.types';
import { toInverseAction } from '../../helpers/steps';
import { getCategoryLabelById } from '../../../../records/category';

const SELECT_WEB_CATEGORY_TEST_ID = 'routines-select-web-category';
export interface SelectCategoryExceptionsProps
  extends RoutineStepWrapperHandlerProps<WebRuleExceptions> {
  mode: RoutineMode;
  action: PolicyAction;
  selectedWebCategories: WebRuleExceptions;
}

const SelectCategoryExceptions = ({
  mode,
  action,
  selectedWebCategories,
  prev,
  close,
  next,
}: SelectCategoryExceptionsProps) => {
  const [state, updateState] = React.useState<RoutineWebCategory[]>(
    selectedWebCategories?.map(webCategory => webCategory.category) || []
  );

  const onItemClick = (selectedCategory: RoutineWebCategory) => {
    if (state.includes(selectedCategory)) {
      updateState(state =>
        state.filter(category => category !== selectedCategory)
      );
      return;
    }
    updateState(state => [...state, selectedCategory]);
  };

  const transformState = (webCategoryNames: RoutineWebCategory[]) =>
    webCategoryNames.map(category => ({
      action: toInverseAction(action),
      category,
    }));

  return (
    <RoutineStepWrapper
      testId={SELECT_WEB_CATEGORY_TEST_ID}
      prev={prev}
      close={close}
      next={() => next?.(transformState(state))}
      footerText={mode === 'EDIT' ? t('Save and go back') : t('Save')}
      fixedFooter
    >
      <Layout marginBottom="40">
        <Typography type="h4" weight="semibold">
          {t('Add category exceptions')}
        </Typography>
        <Typography type="body2">
          {action === 'POLICY_ACTION_BLOCKED' &&
            t(
              'Select the categories you want to {1}allow{/1}. Your default website exceptions will also apply.',
              {
                1: str => <strong>{str}</strong>,
              }
            )}
          {action === 'POLICY_ACTION_ALLOWED' &&
            t(
              'Select the categories you want to {1}block{/1}. Your default website exceptions will also apply.',
              {
                1: str => <strong>{str}</strong>,
              }
            )}
        </Typography>
      </Layout>

      <List
        listItemMarginBottom="16"
        onClickItem={id => onItemClick(id as RoutineWebCategory)}
      >
        {Object.entries(routineWebCategoriesIconsList).map(
          ([category, icon]) => (
            <SelectableListItem
              testId={`${SELECT_WEB_CATEGORY_TEST_ID}-${category}`}
              key={category}
              id={category}
              selected={state.includes(category as RoutineWebCategory)}
              icon={<Icon type={icon} />}
              title={getCategoryLabelById(category as RoutineWebCategory)}
            />
          )
        )}
      </List>
    </RoutineStepWrapper>
  );
};

export default SelectCategoryExceptions;
