import { connect } from 'react-redux';
import {
  openSchoolSummaryInfoModalFor,
  openSeeAllSignatureModalInfo,
} from '../../actions/SchoolActions';
import StudentSignaturesCardWithObserver from '../../components/Schools/StudentSignaturesCardWithObserver';
import { StudentSummaryCardMaxItems } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { getProfile, getProfileId } from '../../selectors';
import {
  getStudentSummarySignatures,
  getSummaryFetchSignaturesError,
  isFetchingSignatures,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';
import { loadStudentMostUsedSignaturesActivity } from '../../ducks/studentSummary';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { createMySchoolEmailHref } from '../../helpers/email';
import { t } from '../../lib/i18n';
import { navigateToSchoolSignature } from '../../actions/Navigation';

const onClickSignature =
  (signatureId: string) => (dispatch: Dispatch, getState: () => State) => {
    const profileId = getProfileId(getState()) as string;
    return dispatch(navigateToSchoolSignature(profileId, signatureId));
  };

const mapStateToProps = (state: State, props) => ({
  signaturesActivity: getStudentSummarySignatures(state),
  maxSignatureActivityItems:
    props.maxSignatureActivityItems || StudentSummaryCardMaxItems.signatures,
  showPercent: props.showPercent || false,
  single: props.single || false,
  isFeatureDisabled:
    getSummaryFetchSignaturesError(state) ===
    StudentSummaryFetchError.FeatureDisabled,
  extraHeight: props.extraHeight || false,
  errorOnloading: getSummaryFetchSignaturesError(state),
  isLoadingSignatureInfo: isFetchingSignatures(state),
  profileName: getProfile(state, props.profileId)?.name || '',
});

const mapDispatchToProps = (dispatch: Dispatch, props) => ({
  onClickSignature: (signatureId: string) => {
    dispatch(onClickSignature(signatureId));
  },
  onClickInfo: () =>
    dispatch(openSchoolSummaryInfoModalFor('MostUsedAppsAndWebsites')),
  onClickSeeAll: () => dispatch(openSeeAllSignatureModalInfo()),
  loadInfo: () =>
    dispatch(
      loadStudentMostUsedSignaturesActivity({ profileId: props.profileId })
    ),
  onClickContactUs: (event: Event): void => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.ContactSupport
    );
    const navigate = getMultiPlatformNavigation(event);

    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(t('Error School dashboard activity')),
    });
  },
});

const StudentAppsAndWebsitesCardWithObserverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSignaturesCardWithObserver);

export type StudentSignaturesCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentAppsAndWebsitesCardWithObserverContainer;
