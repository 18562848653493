import * as React from 'react';
import { Modal, ModalStyledText } from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { t } from '../../lib/i18n';
import SchoolPeriodTime from '../SchoolPeriodTime';
import { StudentPauseInfoModalProps } from './types/StudentPauseInfoModal.type';

const StudentPauseInfoModal: React.FunctionComponent<
  StudentPauseInfoModalProps
> = ({
  startNextPeriod,
  endNextPeriod,
  onClickClose,
}: StudentPauseInfoModalProps) => {
  return (
    <Modal
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      title={t('Pause the internet /verb/')}
      header={{
        // todo add to rocket the pause icon, and update this one.
        icon: (
          <React.Fragment>
            <div
              className="rck-icon rck-icon--secondary rck-modal__header-icon"
              aria-hidden="true"
            >
              <i className="far fa-pause-circle" />
            </div>
          </React.Fragment>
        ),
      }}
    >
      <ModalStyledText>
        {t('Block the internet on school devices to create screen-free time.')}
      </ModalStyledText>
      <ModalStyledText>
        {t(
          'During school hours {1}school period{/1} school can unpause the internet at any time, or prevent you from pausing.',
          {
            1: () =>
              startNextPeriod && endNextPeriod ? (
                <SchoolPeriodTime
                  periodStart={startNextPeriod}
                  periodEnd={endNextPeriod}
                />
              ) : (
                ''
              ),
          }
        )}
      </ModalStyledText>
    </Modal>
  );
};

export default StudentPauseInfoModal;
