import { getFormValues } from 'redux-form/immutable';
import * as R from 'ramda';
import { t } from '../lib/i18n';
import { goBackIfHistory } from '../ducks/routing';
import { getDevice } from '../selectors';
import { DeviceRecord } from '../records/device';
import {
  updateDevice,
  modifyDevice,
  revertDeviceUpdate,
} from '../ducks/devices';
import { updateDeviceRecord } from '../ducks/records';
import { showToast, TOAST_ICON_WARNING, TOAST_ICON_TICK } from '../ducks/toast';
import { formName } from '../components/Device/DeviceEdit';
import { staticMapUrl } from '../lib/geocode';
import { hasPendingMap, makeMapImage } from '../records/location';
import { BaseThunk } from '../store/state';
import { captureException } from '../helpers/sentry';

const values = getFormValues(formName);

export const save = deviceId => (dispatch, getState) => {
  const name = values(getState()).get('name');
  return Promise.resolve(getDevice(getState(), deviceId)!)
    .then(device => device.set('name', name))
    .then(device => {
      dispatch(updateDevice(device));
      return device;
    })
    .then(device => {
      dispatch(goBackIfHistory());
      return device;
    })
    .then(R.pipe(modifyDevice, dispatch))
    .then(
      R.tap(() =>
        dispatch(
          showToast([t('Device name has been updated')], TOAST_ICON_TICK)
        )
      )
    )
    .catch(() => {
      dispatch(revertDeviceUpdate());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
};

export const fetchExtendedLocation =
  (deviceId: number): BaseThunk<any> =>
  (dispatch, getState) => {
    return Promise.resolve(getDevice(getState(), deviceId)!)
      .then(fetchMap)
      .then(updateRecordAction(getDevice(getState(), deviceId)))
      .then(dispatch);
  };

const fetchMap = (device: DeviceRecord): Promise<DeviceRecord> =>
  hasPendingMap(device.location)
    ? Promise.resolve(device.location)
        .then(R.props(['latitude', 'longitude']))
        .then(R.apply(staticMapUrl))
        .then(makeMapImage)
        .then(map => device.set('location', device.location.set('map', map)))
        .catch(error => {
          captureException(error, {
            extra: {
              message: `could not get static map image "${[
                device.location.latitude,
                device.location.longitude,
              ].toString()}"`,
            },
          });
          return device;
        })
    : Promise.resolve(device);

const updateRecordAction = current =>
  R.ifElse(
    R.complement(maybeUpdated => maybeUpdated.equals(current)),
    R.unary(updateDeviceRecord),
    R.always({ type: 'void' })
  );
