import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs';
import type { AppAction, AppEpic } from '../../epics/types';
import type {
  RoutinesSetIsOpenMenuAction,
  RoutinesSetMenuTransitionAction,
} from './types';
import { routinesSetIsOpenMenuAction } from './actions';
import { getIsOpenMenu } from './selectors';
import routinesEpics from './epics/routinesEpic';
import routineDetailsEpics from './epics/routineDetailsEpic';
import routineSchedulesEpic from './epics/routineSchedulesEpic';
import routineScheduleDetailsEpic from './epics/routineScheduleDetailsEpic';
import { abortWhenRoutinesFlagDisabled } from './epics/operators';
import type State from '../../store/state';

export const handleOpenMenu: AppEpic<
  AppAction,
  RoutinesSetMenuTransitionAction | RoutinesSetIsOpenMenuAction
> = (actions$, state$) => {
  const toggleMenu = actions$.pipe(
    ofType('ROUTINES_TOGGLE_MENU'),
    map(() => routinesSetIsOpenMenuAction(!getIsOpenMenu(state$.value)))
  );

  return toggleMenu;
};

const routinesCombinedEpic = combineEpics<AppAction, AppAction, State>(
  handleOpenMenu,
  routinesEpics,
  routineDetailsEpics,
  routineSchedulesEpic,
  routineScheduleDetailsEpic
);

export const routinesGrandEpic: AppEpic<AppAction, AppAction> = (
  actions$,
  state$,
  $deps
) => {
  const actions = actions$.pipe(abortWhenRoutinesFlagDisabled(state$));
  return routinesCombinedEpic(actions, state$, $deps);
};

export default routinesGrandEpic;
