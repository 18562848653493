import { change } from 'redux-form';
import { getFormValues } from 'redux-form/immutable';
import { createProfile, modifyProfile } from '../../ducks/profiles';
import {
  formRecord,
  formName,
} from '../../components/Onboarding/Form/AddChildForm';
import { ProfileRecord } from '../../records';
import { getProfile } from '../../selectors/profileRaw';
import { getOnboardingProfile } from '../../selectors/onboarding';
import { addProfile } from '../../ducks/appOnboarding';
import { Gender, defaultPicture } from '../../records/profile';
import { OnBoardingRoutes, OnBoardingRoutesBeta } from '../../constants';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

export const saveChildAndContinue =
  (nextRoute: OnBoardingRoutes | OnBoardingRoutesBeta) =>
  (dispatch, getState) => {
    const values = getFormValues(formName);
    const form = formRecord(values(getState())) as any;
    const onboardingProfile = getOnboardingProfile(getState());

    const persistActionCreator = onboardingProfile
      ? () =>
          modifyProfile(
            ProfileRecord.serialize(
              ProfileRecord.fromPayload(
                form.set('id', onboardingProfile.id).toJS()
              )
            )
          )
      : () =>
          createProfile(
            ProfileRecord.serialize(ProfileRecord.fromPayload(form.toJS()))
          );

    return dispatch(persistActionCreator())
      .then(action => action.payload.result)
      .then(profileId => {
        const profile = getProfile(getState(), profileId);
        const navigate = getMultiPlatformNavigation();
        dispatch(addProfile(profile));
        return dispatch(
          navigate({
            type: 'inner',
            src: nextRoute.replace('{{profileId}}', profileId),
          })
        );
      })
      .catch(error => {
        throw new Error(error);
      });
  };

export const resetSelectedAvatar = gender => dispatch =>
  dispatch(
    // TODO: Remove this logic from web onboarding since the gender is not needed to choose the avatar
    change(
      formName,
      'picture',
      defaultPicture(
        gender === Gender.MALE || gender === Gender.UNSPECIFIED
          ? 'boy_1.png'
          : 'girl_1.png'
      )
    )
  );
