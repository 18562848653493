import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  FlexLayout,
  FreeStyleListItem,
  Icon,
  IconSize,
  IconType,
  List,
  PlanBox,
  RibbonType,
} from 'styleguide-react';
import { AccountRecord, LicenseRecord, ProductRecord } from '../../records';
import {
  ProductDuration,
  getPrice,
  getDisplayDuration,
  getPricePerMonth,
  getProductTypeAsString,
  getUpsellFullPrice,
  ProductSize,
  getPriceDS,
} from '../../records/product';
import { isFreeOrTrial } from '../../records/license';
import { classNames } from '../../helpers';

const CustomPlanBox: React.FunctionComponent<{
  account: AccountRecord;
  className?: string;
  product: ProductRecord;
  productDedicatedSupport?: ProductRecord;
  license: LicenseRecord;
  isPurchaseInProgress: boolean;
  isProductDurationOneYear: boolean;
  planDuration: ProductDuration;
  selectedProductCode: null | string;
  upsellProduct: boolean;
  areUpsellProducts: boolean;
  useDefaultWidthValues?: boolean;
  isEnabledDedicatedSupportV2: boolean;
  onClickProduct: (product: ProductRecord) => any;
}> = ({
  account,
  className,
  product,
  productDedicatedSupport,
  license,
  isPurchaseInProgress,
  isProductDurationOneYear,
  planDuration,
  selectedProductCode,
  upsellProduct,
  areUpsellProducts,
  isEnabledDedicatedSupportV2,
  onClickProduct,
}) => {
  const isCheckboxSelectedByDefault = product.type !== ProductSize.small;
  const [checkboxSelected, setCheckbox] = React.useState(
    isCheckboxSelectedByDefault
  );

  const getPlanOptionalText = (product: ProductRecord, upsell: boolean) =>
    upsell
      ? `${t('Thereafter')} ${getUpsellFullPrice(
          account,
          product
        )}/${getDisplayDuration(product)}`
      : `${t('Same as')} ${getPricePerMonth(
          account,
          product,
          planDuration
        )}/${t('month')}`;

  const planHighlighted =
    isFreeOrTrial(license.type) &&
    product.type === ProductSize.medium &&
    isProductDurationOneYear;
  const isProductWithDedicatedSupport =
    (isEnabledDedicatedSupportV2 &&
      productDedicatedSupport?.isDedicatedSupport) ||
    false;
  const carePlusPrice =
    productDedicatedSupport &&
    isProductWithDedicatedSupport &&
    product.type === ProductSize.small ? (
      <span>{getPriceDS(account, product, productDedicatedSupport)}</span>
    ) : (
      <span className="CustomPlanBox__text-highlighted">
        {t('Free Forever')}
      </span>
    );
  const checkboxSelectedForDedicatedSupportProduct =
    productDedicatedSupport &&
    checkboxSelected &&
    isProductWithDedicatedSupport;
  const carePlusIncluded =
    areUpsellProducts && product.current && product.isDedicatedSupport;

  const getSelectedProduct = () =>
    checkboxSelectedForDedicatedSupportProduct && productDedicatedSupport
      ? productDedicatedSupport
      : product;

  return (
    <div
      className={classNames(
        'CustomPlanBox',
        isProductWithDedicatedSupport ? 'CustomPlanBox--dedicated_support' : ''
      )}
    >
      <PlanBox
        className={className}
        ribbon={ribbon(planHighlighted, product)}
        title={t(
          `${getProductTypeAsString(product.type, product.licenseSubtype)}`
        )}
        testId={`${getProductTypeAsString(
          product.type,
          product.licenseSubtype
        )}`}
        deviceInformation={t('{{maxDevices}} devices', {
          maxDevices: product.maxDevices,
        })}
        button={{
          text: upsellProduct ? t('Upgrade now!') : t('Buy now!'),
          isLoading:
            (selectedProductCode === product.code ||
              (checkboxSelected &&
                selectedProductCode === productDedicatedSupport?.code)) &&
            isPurchaseInProgress,
          onClick: () => onClickProduct(getSelectedProduct()),
        }}
        price={getPrice(account, getSelectedProduct())}
        years={upsellProduct ? undefined : getDisplayDuration(product)}
        priceAdditionalInformation={
          planDuration !== ProductDuration.OneMonth
            ? getPlanOptionalText(getSelectedProduct(), upsellProduct)
            : ''
        }
        current={product.current}
        currentText={t('Your current plan')}
        highlighted={planHighlighted}
        hasDiscount={Boolean(product.discount)}
        carePlusInfo={
          isProductWithDedicatedSupport || carePlusIncluded
            ? {
                label: (
                  <React.Fragment>
                    {carePlusIncluded ? (
                      <FlexLayout mainaxis="row" marginRight="8">
                        <strong>
                          <span>{t('Care Plus included')}</span>
                        </strong>
                      </FlexLayout>
                    ) : (
                      <strong>
                        <span>{`${t('Care Plus')} / `}</span>
                        {carePlusPrice}
                      </strong>
                    )}
                  </React.Fragment>
                ),
                showCheckbox: isProductWithDedicatedSupport,
                carePlusIncluded,
                isChecked: checkboxSelected,
                onClick: () => setCheckbox(!checkboxSelected),
                description: t(
                  'Enhance your Premium experience by adding Care Plus, which includes these exclusive features:'
                ),
                list: (
                  <FlexLayout mainaxis="column" paddingBottom="8">
                    <List listItemMarginTop="8">
                      {[
                        {
                          text: t('Priority phone support'),
                          icon: IconType.phone,
                        },
                        {
                          text: t('Ongoing check-ins'),
                          icon: IconType.userFriends,
                        },
                        {
                          text: t('Personalised help'),
                          icon: IconType.handSparkles,
                        },
                        {
                          text: t('Available in English & Spanish'),
                          icon: IconType.dots,
                        },
                      ].map(feature => featureItem(feature.text, feature.icon))}
                    </List>
                  </FlexLayout>
                ),
              }
            : undefined
        }
      />
    </div>
  );
};

export const ribbon = (planHighlighted: boolean, product: ProductRecord) => {
  if (product.discount) {
    return {
      text: t('save {{discount}}%', { discount: product.discount }),
      type: RibbonType.cornerLeft,
    };
  }
  if (planHighlighted) {
    return {
      text: t('Best value'),
      type: RibbonType.cornerRight,
    };
  }
  return undefined;
};

const featureItem = (text, iconType) => (
  <FreeStyleListItem key={text}>
    <FlexLayout mainaxis="row" marginTop="8">
      <FlexLayout mainaxis="row" marginRight="8" minWidth="24">
        <Icon type={iconType} size={IconSize.sm} />
      </FlexLayout>
      <p className="rck-plan-box__checkbox-description">{text}</p>
    </FlexLayout>
  </FreeStyleListItem>
);

export default CustomPlanBox;
