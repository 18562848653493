import * as React from 'react';
import { t } from '../../lib/i18n';
import { AlertBoxType, Icon, IconFamily, IconType } from 'styleguide-react';
import type { AppRulesAddDeviceFlyoverProps } from '../../containers/ProfileRules/AppRules/AppRulesAddDeviceFlyoverContainer';
import AppRulesFlyover from './AppRulesFlyover';

const ADD_DEVICE_FLYOVER_TEST_ID = 'app-rules-add-device-flyover';

const AppRulesAddDeviceFlyover = ({
  accountLocale,
  onClickClose,
  onClickAddDevice,
}: AppRulesAddDeviceFlyoverProps) => {
  return (
    <AppRulesFlyover
      accountLocale={accountLocale}
      testId={ADD_DEVICE_FLYOVER_TEST_ID}
      actionButton={{
        title: t('Add a device'),
        onClick: onClickAddDevice,
        testId: `${ADD_DEVICE_FLYOVER_TEST_ID}-add-device`,
      }}
      onClickClose={onClickClose}
      topbarText={t('Protect a personal device to manage games & apps')}
      alertBoxProps={{
        icon: (
          <Icon type={IconType.exclamationCircle} family={IconFamily.solid} />
        ),
        type: AlertBoxType.info,
      }}
    />
  );
};

export default AppRulesAddDeviceFlyover;
