import * as React from 'react';
import { connect } from 'react-redux';
import State from '../../store/state';
import { getLocale } from '../../selectors';

const EnsureTranslations = ({ children }: { children: React.ReactNode }) => (
  <React.Fragment>{children}</React.Fragment>
);

const mapStateToPops = (state: State) => ({
  locale: getLocale(state),
});

/**
 * For translations to work, it's crucial to be connected to the state of locales so that React reacts when its value changes.
 * This is necessary because of how t() function works for translations
 * it's not a component and operates outside React's lifecycle.
 * It's also important to note that in other scenarios it works because components in the rendering tree are consuming the state of locales.
 */
const EnsureTranslationsContainer = connect(mapStateToPops)(EnsureTranslations);

export default EnsureTranslationsContainer;
