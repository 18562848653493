import * as React from 'react';
import {
  ComparisonTable,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import { t } from '../../../lib/i18n';

const UpgradePremiumFeaturesB = () => {
  return (
    <FlexLayout
      mainaxis="column"
      textAlignement="center"
      className="exp-upgrade-premium-features"
      marginTop="72"
      marginRight="16"
      marginLeft="16"
    >
      <h2 className="exp-upgrade-premium-features__title">
        {t('Learn more about what’s included')}
      </h2>
      <PlanNamesTable />
      <ComparisonTable
        data={{
          head: [{ icon: IconType.user, text: t('Safety') }],
          rows: [
            [t('Safe Search'), true, true],
            [t('Web filtering'), true, true],
            [t('Games & apps blocking'), true, true],
            [t('Web activity alerts'), null, true],
            [t('Blocked website alerts'), null, true],
          ],
        }}
        key="safety"
      />
      <ComparisonTable
        data={{
          head: [{ icon: IconType.balanceScale, text: t('Balance') }],
          rows: [
            [t('Daily time Limits'), true, true],
            [t('Pause internet access /noun/'), true, true],
            [t('Device blocking routines'), true, true],
            [t('Custom app & website rules routines'), null, true],
            [t('Games & apps time limits'), null, true],
          ],
        }}
        key="balance"
      />
      <ComparisonTable
        data={{
          head: [{ icon: IconType.bell, text: t('Visibility') }],
          rows: [
            [t('30-day activity reports'), true, true],
            [`${t('Panic button')}*`, true, true],
            [t('App download alerts'), null, true],
            [t('YouTube monitoring'), null, true],
            [t('Calls & messages monitoring*'), null, true],
          ],
        }}
        key="visibility"
      />
      <ComparisonTable
        data={{
          head: [{ icon: IconType.mapMarkerAlt, text: t('Location') }],
          rows: [
            [t('Location monitoring'), true, true],
            [t('Saved places alerts'), null, true],
          ],
        }}
        key="location"
      />
      <ComparisonTable
        data={{
          head: [{ icon: IconType.headSet, text: t('Support') }],
          rows: [
            [t('Premium email support'), true, true],
            [t('Care Plus'), null, true],
          ],
        }}
        key="support"
      />
      <RenderWhen screenSize={ScreenSize.TabletOrDesktop}>
        <ComparisonTable
          data={{
            head: [
              { icon: IconType.phoneLaptop, text: t('Coverage') },
              { text: t('Up to 5 devices protected') },
              { text: t('Unlimited devices protected*') },
            ],
            rows: [],
          }}
          key="coverage-desktop"
        />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Mobile}>
        <ComparisonTable
          data={{
            head: [{ icon: IconType.phoneLaptop, text: t('Coverage') }],
            rows: [[t('Devices protected'), '5', t('Unlimited*')]],
          }}
          key="coverage-mobile"
        />
      </RenderWhen>

      <p className="exp-upgrade-premium-features__additional-info">
        {t(
          '*Panic button is only available for Android. Calls & messages for iOS requires a Mac computer. Unlimited includes your family devices and does not apply to schools or businesses.'
        )}
      </p>
    </FlexLayout>
  );
};

const PlanNamesTable = () => (
  <Table border="none">
    <TableHead>
      <TableRow>
        <TableCell head />
        <TableCell>
          <div className="exp-plan-name-cell">
            <Icon
              type={IconType.lockAlt}
              color={IconColor.greenDecorationContrast}
              className="exp-plan-name-cell__icon"
            />
            {t('Basic')}
          </div>
        </TableCell>
        <TableCell>
          <div className="exp-plan-name-cell">
            <Icon
              type={IconType.shieldAlt}
              color={IconColor.greenDecorationContrast}
              className="exp-plan-name-cell__icon"
            />
            {t('Complete')}
          </div>
        </TableCell>
      </TableRow>
    </TableHead>
  </Table>
);

export default UpgradePremiumFeaturesB;
