import { combineEpics } from 'redux-observable';
import { loadSummaryAlertsEpic } from './loadSummaryAlerts';
import { resetSummaryAlertsCounterEpic } from './resetSummaryAlertsCounter';

export * from './loadSummaryAlerts';
export * from './resetSummaryAlertsCounter';

export const summaryAlertsEpics = combineEpics(
  resetSummaryAlertsCounterEpic,
  loadSummaryAlertsEpic
);
