import * as moment from 'moment';
import * as Moment from 'moment-timezone';
import { Map } from 'immutable';
import * as R from 'ramda';
import { t } from '../lib/i18n';
import {
  Datetime,
  MomentIsBetweenInclusivity,
  TimeUnits,
} from '../types/dates';
import {
  toLocaleString,
  fromDateFormattedString,
  normalizeOptions,
} from '@qustodio/date-time';

export const API_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_BIRTHDATE_FORMAT = 'YYYY-MM-DD';
export const HUMAN_12CLOCK_FORMAT = 'LT';
export const HUMAN_DATE_FORMAT = 'MMM. D, YYYY';
export const HUMAN_DATE_FORMAT_MONTH_DAY = 'MMM D';
export const HUMAN_FULL_DATE_FORMAT = 'LT - LL';
export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_HOUR = 'HH:mm';
export const DATE_FORMAT_PRETTY = 'dddd MMMM D';
export const DATE_FORMAT_INCLUDING_HOURS = 'MMM D, YYYY, h:mma';
export const API_TIMESTAMP_FORMAT_WITH_T = 'YYYY-MM-DDTHH:mm:ss';
export const API_TIMESTAMP_FORMAT_WITHOUT_SEPARATORS = 'YYYYMMDDTHHmmss';
export const DATE_FORMAT_DAY_MONTH = 'DD/MM';
export const DATE_FORMAT_WEEKDAY_AND_DAY = 'dddd DD';

export const MINS_IN_A_DAY = 1440;
export const SECONDS_IN_A_MINUTE = 60;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MINUTES_IN_AN_HOUR = SECONDS_IN_A_MINUTE;
export const MAX_NUMBER_OF_MONTHS_TO_DISPLAY_FROM_DATE_FORMAT = 2;
export const MINS_IN_A_WEEK = 10080;

export const MINUTES_IN_WEEK_STARTING_ON = {
  MO: 0,
  TU: 1440,
  WE: 2880,
  TH: 4320,
  FR: 5760,
  SA: 7200,
  SU: 8640,
};

export interface Duration {
  hours: number;
  minutes: number;
}

export type PeriodUnit = 'day' | 'week' | 'month' | 'year';

export const adaptLocale = locale => (locale !== 'zh-Hans' ? locale : 'zh-CN');

export const minutesToHours = (minutes: number): Duration => {
  return {
    hours: Math.floor(minutes / 60),
    minutes: Math.floor(minutes % 60),
  };
};

export const minutesToHMinFormat = (minutes: number): string => {
  const duration = minutesToHours(minutes);
  return duration.hours === 0
    ? `${duration.minutes}${t('min')}`
    : `${duration.hours}${t('h')} ${duration.minutes}${t('min')}`;
};

export const minutesToHMFormat = (minutes: number): string => {
  const duration = minutesToHours(minutes);
  const zeroHours = duration.hours === 0;
  const zeroMinutes = duration.minutes === 0;

  if (zeroHours && zeroMinutes) {
    return `0${t('h')} 0${t('m')}`;
  }

  if (zeroHours) {
    return `${duration.minutes}${t('m')}`;
  }

  if (zeroMinutes) {
    return `${duration.hours}${t('h')}`;
  }

  return `${duration.hours}${t('h')} ${duration.minutes}${t('m')}`;
};

export function minutesToDayPercentage(minutes) {
  return minutes / (24 * 60);
}

export function getMilisecondsTimestamp() {
  return new Date().valueOf();
}

export function secondsToDayPercentage(seconds) {
  return minutesToDayPercentage(seconds / 60);
}

export function convertDate(
  time: Moment.MomentInput,
  timezone: string
): Moment.Moment {
  return Moment.tz(Moment.utc(time), timezone);
}

export function formatDate(time, timezone) {
  return convertDate(time, timezone).format(DATE_FORMAT);
}

export const dateToDaySeconds = R.pipe(
  convertDate,
  momentDate => momentDate.hours() * 3600 + momentDate.minutes() * 60
);

export const secondsToHourFormat = (seconds = 0) =>
  Moment.utc(Moment.duration(seconds, 'seconds').asMilliseconds()).format(
    DATE_FORMAT_HOUR
  );

export const hourFormatToSeconds = hourString =>
  Moment.duration(hourString).as('seconds');

export function formatDateNice(time, timezone, locale = 'en') {
  return convertDate(time, timezone)
    .locale(adaptLocale(locale))
    .format(DATE_FORMAT_PRETTY);
}
export function formatDateNiceShort(time, timezone, locale = 'en') {
  const momentObject = convertDate(time, timezone);
  const userLocale = locale === 'pt_BR' ? 'pt-BR' : adaptLocale(locale);

  return momentToLocaleDateString(momentObject, userLocale);
}

export const momentToLocaleDateString = (
  momentObject: moment.Moment,
  locale?: string
) =>
  momentObject.toDate().toLocaleDateString(locale || momentObject.locale(), {
    month: 'long',
    day: 'numeric',
  });

export function formatDateIncludingHours(time, timezone, locale = 'en') {
  return convertDate(time, timezone)
    .locale(adaptLocale(locale))
    .format(DATE_FORMAT_INCLUDING_HOURS);
}

export function formatHumanFullDate(time, timezone, locale = 'en') {
  return convertDate(time, timezone)
    .locale(adaptLocale(locale))
    .format(HUMAN_FULL_DATE_FORMAT);
}

export function formatHumanTwelveClockFormat(time, timezone, locale = 'en') {
  return convertDate(time, timezone)
    .locale(adaptLocale(locale))
    .format(HUMAN_12CLOCK_FORMAT);
}

export const formatHumanTwelveClockAMPMFormat = (time, timezone) =>
  formatHumanTwelveClockFormat(time, timezone);

export function getDayOfWeekConverted(time, timezone): Weekday {
  return WEEKDAYS[parseInt(convertDate(time, timezone).format('E'), 10) - 1];
}

// this name is not correct this is comparing if 2 dates are the same day, not today!
export function isTodaysDate(currentTime, time, timezone) {
  const currentTimeConverted = convertDate(currentTime.clone(), timezone);
  const timeConverted = convertDate(time, timezone);
  return currentTimeConverted
    .startOf('day')
    .isSame(timeConverted.startOf('day'));
}

/**
 * Compares 2 strings to check if they are the same day,
 * converts both string into the given timezone,
 * and compares both moment object until the day.
 *
 * valid input strings 'YYYY-MM-DD'
 *
 */
export const isSameDate = (
  currentTime: string,
  time: string,
  timezone: string
) => {
  const currentTimeConverted = Moment.tz(currentTime, timezone)
    .hour(0)
    .minute(0)
    .second(0);
  const timeConverted = Moment.tz(time, timezone).hour(0).minute(0).second(0);

  return timeConverted.isSame(currentTimeConverted, 'day');
};

export const dateWithoutTime = (time, timezone) =>
  convertDate(time, timezone).startOf('day');

/**
 * Format the expiry date
 */
export const expiryDate = (locale, dateString) => {
  const dateMoment = Moment(dateString, API_TIMESTAMP_FORMAT);
  switch (locale) {
    case 'es':
    case 'pt_BR':
      return dateMoment.locale(adaptLocale(locale)).format('D [de] MMMM YYYY');
    case 'it':
      return dateMoment.locale(adaptLocale(locale)).format('D MMMM YYYY');
    case 'fr':
    case 'de':
      return dateMoment.locale(adaptLocale(locale)).format('Do MMMM YYYY');
    default:
      return dateMoment.locale(adaptLocale(locale)).format('MMMM Do, YYYY');
  }
};

/**
 * Format the expiry date short
 */
export const expiryDateShort = (locale, dateString) => {
  const dateMoment = Moment(dateString, API_TIMESTAMP_FORMAT);
  switch (locale) {
    case 'es':
    case 'it':
    case 'fr':
    case 'de':
      return dateMoment.locale(adaptLocale(locale)).format('MMM D, YYYY');
    case 'pt_BR':
    default:
      return dateMoment.locale(adaptLocale(locale)).format(HUMAN_DATE_FORMAT);
  }
};

export enum Weekday {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export const WEEKDAYS_WORKING: Weekday[] = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
];
export const WEEKDAYS_WEEKEND: Weekday[] = [Weekday.Saturday, Weekday.Sunday];

export const WEEKDAYS: Weekday[] = [...WEEKDAYS_WORKING, ...WEEKDAYS_WEEKEND];

/** Create a "key -> weekday-symbol" map by providing a list of keys, for
 * monday to sunday.
 * Example:
 * apiWeekdayKeys(['l', 'm', 'mx', 'j', 'v', 's', 'd']);
 * returns:
 * {l: Symbol('MONDAY'), m: Symbol('TUESDAY'), mx: Symbol('WEDENSDAY'), ...etc}
 */
export const mapWeekDays = R.zipObj(WEEKDAYS);

export const isHourState = (timeRanges, weekday, hour) =>
  // hour is element of [0,23]
  // weekday is element of [Weekday.Monday,..., Weekday.Sunday]
  Boolean((1 << hour) & timeRanges.get(weekday)); // eslint-disable-line no-bitwise

export const weekdaysShortTranslations = () =>
  mapWeekDays([t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa'), t('Su')]);

export const weekdaysShort3letterTranslations = () =>
  mapWeekDays([
    t('Mon'),
    t('Tue'),
    t('Wed'),
    t('Thu'),
    t('Fri'),
    t('Sat'),
    t('Sun'),
  ]);

export const isValidISODateString = dateString =>
  Moment(dateString, Moment.ISO_8601).isValid();

/**
 * Count the number of days difference between two dates.
 * Ignores the time part of day.
 * The two arguments must be "moment" instances.
 */
export const dayCountDiff = R.curry(
  (from: Moment.Moment, to: Moment.Moment) => {
    return from.clone().startOf('day').diff(to.clone().startOf('day'), 'days');
  }
);

export const minutesCountDiff = R.curry(
  (from: Moment.Moment, to: Moment.Moment) =>
    from.clone().diff(to.clone(), 'minutes')
);

/**
 * Joins all arguments into a list, and then maps each item to a "moment" instance.
 * If any argument already is a moment instance it is left untouched.
 * Useful for composing with other date functions.
 */
export const parseDates: (
  format: string
) => (...dates: (Moment.Moment | string)[]) => Moment.Moment[] = format =>
  R.unapply<any>(
    R.map(
      R.when(R.complement(Moment.isMoment), R.partialRight(Moment, [format]))
    )
  );

/**
 * Parses an ISO-8601 timestamp string into a Moment object, or
 * returns undefined if it failes to parse.
 */
export const parseISODateString = (
  isoDateString: ISODateString | string
): Moment.Moment => Moment(isoDateString, Moment.ISO_8601);

export const ISODateStringFromMoment = (moment: Moment.Moment): ISODateString =>
  moment.utc().toISOString();

export const fromStringToUTCISOString = (date: string): ISODateString =>
  ISODateStringFromMoment(Moment.utc(date));

export const localNow = (timezone: string): Moment.Moment => {
  return Moment.tz(Moment.utc(), timezone);
};

export const getYearsFromNow = (moment: Moment.Moment): number =>
  Math.abs(moment.diff(new Date(), 'years'));

export const getYearsOld = (moment: Moment.Moment): string => {
  const yearsOld = getYearsFromNow(moment);
  if (yearsOld === 1) {
    return `${yearsOld} ${t('year old')}`;
  }

  return `${yearsOld} ${t('years old')}`;
};

export const formatHoursFromISO = (time: string, timezone: string) =>
  convertDate(time, timezone).format(DATE_FORMAT_HOUR);

export const formatHours = (moment: Moment.Moment) =>
  moment.format(DATE_FORMAT_HOUR);

export const formatDayToAPI = (moment: Moment.Moment) =>
  moment.format(API_BIRTHDATE_FORMAT);

export const addHours = (hours: number, moment: Moment.Moment) =>
  moment.clone().add(hours, 'hours');

export const addToMoment = (
  timeToAdd: number,
  timeUnit: TimeUnits,
  moment: Moment.Moment = getMoment()
) => moment.clone().add(timeToAdd, timeUnit);

export const subtractToMoment = (
  timeToSubtract: number,
  timeUnit: TimeUnits,
  moment: Moment.Moment = getMoment()
) => moment.clone().subtract(timeToSubtract, timeUnit);

export const militaryTimeToAMPMFormat = time =>
  Moment(time, 'HH:mm').format('ha');

export const militaryTimeToAMPMFormatWithHoursAndMinutes = time =>
  Moment(time, 'HH:mm').format('h:mm a');

export const AMPMTimeToMilitaryFormatWithHoursAndMinutes = (time: string) =>
  Moment(time, 'h:mm a').format('HH:mm');

/**
 * Count days left from startDate to endDate. If timezone is set,
 * returns days left between startDate to endDate in UTC format.
 * @param {Moment.Moment | string } startDate a moment instance, or a timestamp-string
 * @param {Moment.Moment | string } endDate a moment instance, or a timestamp-string
 * @param {string}                  timezone timezone string
 * @returns {number} number of days between startDate and endDate
 */
export const daysDiff = (
  startDate: Moment.Moment | string,
  endDate: Moment.Moment | string,
  timezone?: string
): number => {
  let retStartDate;
  let retEndDate;
  if (typeof timezone !== 'undefined' && timezone.length > 0) {
    const tzStartDate = Moment(startDate).tz(timezone);
    const tzEndDate = Moment(endDate).tz(timezone);
    retStartDate = Moment(tzStartDate).utc();
    retEndDate = Moment(tzEndDate).utc();
  } else {
    retStartDate = Moment(startDate);
    retEndDate = Moment(endDate);
  }

  // Get the start of day moment of each date and compare
  const startOfDayStartDate = retStartDate.startOf('day');
  const startOfDayEndDate = retEndDate.startOf('day');

  return startOfDayEndDate.diff(startOfDayStartDate, 'days');
};

/**
 * Get an array of restricted hours given timeRanges and the currentWeekday.
 * Example output: [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]
 */
export const getAllRestrictedHours = ({
  timeRanges,
  currentWeekday,
}: {
  timeRanges: Map<Weekday, number>;
  currentWeekday: Weekday;
}): number[] => {
  const allHours = Array(25)
    .fill(null)
    .map((_, hour) => hour);

  return allHours.filter(hour => isHourState(timeRanges, currentWeekday, hour));
};

/**
 * Get an array of hourRanges given an array of restricted hours
 * Example output: [['01:00', '02:00'],['04:00', '05:00'], ['07:00', '08:00']]
 */
export const getRestrictedHourRanges = (hours: number[]) => {
  const lastEl = arr => arr[arr.length - 1];

  const formatRange = range => [
    `${range[0].toString().padStart(2, '0')}:00`,
    `${range[range.length - 1].toString().padStart(2, '0')}:00`,
  ];

  if (!hours.length) {
    return [];
  }

  if (hours.length === 1) {
    return [formatRange([hours[0], hours[0] + 1])];
  }

  return hours
    .reduce((ranges, hour, index) => {
      if (index === 0) {
        ranges.push([hour]);
        return ranges;
      }

      const lastRange = lastEl(ranges);
      const lastHourInRange = lastEl(lastRange);

      if (lastHourInRange + 1 === hour) {
        lastRange.push(hour);
      } else {
        ranges.push([hour]);
      }

      return ranges;
    }, [] as any)
    .map(range => formatRange([range[0], range[range.length - 1] + 1]));
};

const hoursMod = (hour: number) => hour % 24;

/**
 * Function to get the nearest restriction end time.
 * @param hoursList list of restrictions hours starting from index 0
 * @param currentHour Current hour from index 0
 * @returns hour till the restriction ends (we add 1h)
 *
 * Example:
 * currentHour: 2
 * hours: [1, 2, 3, 4, 6]
 * outputs: (4 + 1) => 5
 */
export const getRestrictedUntilHour = (
  hoursList: number[],
  currentHour: number
) => {
  const EOH = 1; // end of hour
  const hours = hoursList.filter(hour => hour >= currentHour);

  if (!hours.length) return null;
  if (!hours.includes(currentHour)) return null;
  if (hours.length === 1) return hoursMod(hours[0] + EOH);
  while (hours.length > 1) {
    if (hours[1] - hours[0] > 1) return hoursMod(hours[0] + EOH);
    hours.shift();
  }
  return hoursMod(hours[0] + EOH);
};

export const timeFrom = (
  time: string,
  timezone: string,
  locale: string,
  now: Moment.Moment = Moment()
): string => {
  Moment.relativeTimeRounding(Math.floor);

  const convertedDate = convertDate(time, timezone).locale(adaptLocale(locale));
  const timeDifference = Moment.duration(now.diff(convertedDate));
  if (timeDifference.asSeconds() <= SECONDS_IN_A_MINUTE) {
    return t('Now').toLowerCase();
  }
  if (
    timeDifference.months() >= MAX_NUMBER_OF_MONTHS_TO_DISPLAY_FROM_DATE_FORMAT
  ) {
    return convertedDate.format(HUMAN_DATE_FORMAT);
  }
  return convertedDate.from(now);
};

export const getNotificationDateFormatted = (
  date: moment.Moment,
  locale: string
): string => date.locale(adaptLocale(locale)).format('MMMM Do');

export const getMoment = () => moment();

export const getCurrentLocalTimeForTimezone = (
  timezone: string,
  format: string
) => {
  return Moment.tz(Moment(), timezone).format(format);
};

export const daysFrom = (dateString: string) =>
  dayCountDiff(Moment(), Moment(dateString));

/**
 * Convert a date to DD/MM
 * @param {Moment.Moment | string } date a moment instance, or a timestamp-string
 * @returns {string} string containing day and month: DD/MM
 */
export const dateFormatToDayMonth = (date: Moment.Moment | string) => {
  return Moment(date).format(DATE_FORMAT_DAY_MONTH);
};

/**
 * compare if the actual moment is between the two provided date times
 *
 * @param startDatetime
 * @param endDateTime
 * @returns
 */
export const isBetweenDatetimes = (
  startDatetime: Datetime,
  endDateTime: Datetime
): boolean => {
  return moment().isBetween(startDatetime, endDateTime);
};

export const isDatetimeBetweenDatetimes = (
  dateTime: Datetime,
  startDatetime: Datetime,
  endDateTime: Datetime,
  inclusivity?: MomentIsBetweenInclusivity
): boolean =>
  dateTime.isBetween(startDatetime, endDateTime, undefined, inclusivity);

export const isTodayDatetime = (compareDate: Datetime): boolean => {
  return getMoment().isSame(compareDate, 'day');
};

/**
 * compares two date times and return if the first argument is before the second one,
 * in case of being the same date time it will return false, as the same moment is not before itself.
 *
 * @param endDatetime
 * @param compareDatetime optional if not given get the local machine date time
 * @param timeUnit time unit to use in comparison
 * @returns
 */
export const isDatetimeIsBefore = (
  endDatetime: Datetime,
  compareDatetime: Datetime = getMoment(),
  timeUnit: TimeUnits = 'minutes'
): boolean => {
  return endDatetime.isBefore(compareDatetime, timeUnit);
};

export const isDatetimeTheSameOrBefore = (
  dateTime: Datetime,
  dateTimeToBeCompared: Datetime = getMoment(),
  timeUnit: TimeUnits = 'minutes'
): boolean => {
  return dateTime.isSameOrBefore(dateTimeToBeCompared, timeUnit);
};

export const isDatetimeTheSameOrAfter = (
  dateTime: Datetime,
  dateTimeToBeCompared: Datetime = getMoment(),
  timeUnit: TimeUnits = 'minutes'
): boolean => {
  return dateTime.isSameOrAfter(dateTimeToBeCompared, timeUnit);
};

/**
 * Returns the duration of a time period in minutes
 *
 * @param endDatetime
 * @param startDatetime optional if not given get the local machine date time
 * @returns duration in minutes as a number
 */
export const durationInMinutes = (
  endDatetime: Datetime,
  startDatetime: Datetime = moment()
): number => {
  const duration = moment.duration(endDatetime.diff(startDatetime));
  const minutes = duration.asMinutes();
  return Math.round(minutes);
};

/**
 * Try to get the user TZ from the browser,
 * if you need to use a TZ use the one from the account record,
 * this one is only to be used to send to analytics.
 */
export const getUserLocalTimezone = (): string => {
  return Moment?.tz?.guess() || '';
};

export const isTomorrow = (
  time: Moment.Moment,
  currentTime: Moment.Moment
): boolean => {
  return (
    time
      .clone()
      .startOf('day')
      .diff(currentTime.clone().startOf('day'), 'days') === 1
  );
};

export const formatHuman12Clocks = (date: string) => {
  return moment(date, API_TIMESTAMP_FORMAT).format(HUMAN_12CLOCK_FORMAT);
};

export function formatDateString(date, locale = 'en') {
  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export const getDurationStringFromPeriod = (
  period: number,
  periodUnit: PeriodUnit
) => {
  if (period === 1) {
    return t(periodUnit);
  }

  if (periodUnit === 'day') {
    return t('{{period}} days', { period });
  }
  if (periodUnit === 'month') {
    return t('{{period}} months', { period });
  }
  if (periodUnit === 'week') {
    return t('{{period}} weeks', { period });
  }
  return t('{{period}} years', { period });
};

export const formatDateToWeekdayAndDay = (
  date: string,
  timezone: string,
  locale = 'en'
) => {
  return convertDate(date, timezone)
    .locale(adaptLocale(locale))
    .format(DATE_FORMAT_WEEKDAY_AND_DAY);
};

/**
 * Returns minutes from the start of the day till `time`
 * @param time HH:mm
 * @param timezone account timezone
 * @returns {number}
 */
export const getMinutesFromStartOfDay = (
  time: string,
  timezone: string
): number =>
  Moment.tz(moment(time, DATE_FORMAT_HOUR), timezone).diff(
    Moment.tz(moment('00:00', DATE_FORMAT_HOUR), timezone),
    'minutes'
  );

/** Returns minutes from the week for a given `time` */
export const getTimeMinutesOfWeek = (time: Datetime, timezone: string) => {
  const weekdayIndex = getDayOfWeekConverted(time, timezone);
  const weekDayStartMinutes = MINS_IN_A_DAY * weekdayIndex;
  const minutesFromStartOfDay = getMinutesFromStartOfDay(
    formatHours(time),
    timezone
  );

  const timeWeekMinutes = weekDayStartMinutes + minutesFromStartOfDay;
  return timeWeekMinutes;
};

/**
 * Formats a time range and removes duplicate AM/PM indicators if start and end times are in the same meridiem.
 *
 * @param startTime - (HH:mm) The start time in military format.
 * @param endTime - (HH:mm) The end time in military format.
 * @returns The formatted time range.
 *
 * @example
 * const startTime = "10:00";
 * const endTime = "11:45";
 * const formattedTimeRange = formatAndRemoveDuplicateMeridiem(startTime, endTime);
 * // Result: "10 - 11:45am"
 *
 * @example
 * const startTime = "10:00";
 * const endTime = "13:00";
 * const formattedTimeRange = formatAndRemoveDuplicateMeridiem(startTime, endTime);
 * // Result: "10am - 1pm"
 */
export const formatAndRemoveDuplicateMeridiem = (
  startTime: string,
  endTime: string
): string => {
  const startMoment = moment(startTime, DATE_FORMAT_HOUR);
  const endMoment = moment(endTime, DATE_FORMAT_HOUR);

  const startMeridiem = startMoment.format('a');
  const endMeridiem = endMoment.format('a');

  let formattedStartTime = startMoment.format('h:mm').replace(':00', '');
  const formattedEndTime = endMoment.format('h:mma').replace(':00', '');

  if (startMeridiem !== endMeridiem) {
    formattedStartTime += startMeridiem;
  }

  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const getCurrentTimeFromSystem = () => moment();

export const getTimeFromFormattedString = (date: string) => moment(date);

/**
 * Define the date format based on the locale: 'dd-MMM-yyyy' for 'es', 'fr', 'pt-BR', 'it', 'de' and 'dd-五月-yyyy' for 'zh-Hans'
 */
export const formatChargebeeDateString = (date: string, locale: string) => {
  try {
    const format = locale === 'zh-Hans' ? 'dd-五月-yyyy' : 'dd-MMM-yyyy';
    const dt = fromDateFormattedString(date, format, { locale });
    const dtLocale = normalizeOptions(dt, { locale });

    return toLocaleString(dtLocale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (e) {
    return date.split('-').join(' ');
  }
};
