import * as React from 'react';
import { LinewizeEventActions } from '../../../../records/linewizeEvent/types/linewizeEvents';
import { ACTION_BLOCK } from '../../../../constants';
import {
  FreeText,
  GlobalType,
  Icon,
  IconColor,
  IconFamily,
  IconType,
  Tag,
} from 'styleguide-react';
import { t } from '../../../../lib/i18n';
import {
  HUMAN_12CLOCK_FORMAT,
  parseISODateString,
} from '../../../../helpers/dates';
import { classNames } from '../../../../helpers';

export const getUpperSubtitle = (
  action: LinewizeEventActions,
  time: string
) => {
  const timeFormatted = parseISODateString(time).format(HUMAN_12CLOCK_FORMAT);
  const prepend = ' - ';

  return (
    <span>
      {timeFormatted}
      {action === ACTION_BLOCK && (
        <React.Fragment>
          {prepend} <FreeText color="error-dark">{t('Blocked')}</FreeText>
        </React.Fragment>
      )}
    </span>
  );
};

export const getCategoriesTags = (
  categories: string[],
  action: LinewizeEventActions,
  key: string
): ReturnType<typeof Tag>[] => {
  return categories.map(category => (
    <Tag
      key={`${key}-${category}`}
      text={category}
      variant="squared"
      type={action === ACTION_BLOCK ? GlobalType.error : GlobalType.secondary}
    />
  ));
};

export const SchoolTimelineIcon = (
  iconType: typeof IconType[keyof typeof IconType],
  color: IconColor,
  className: string,
  family?: IconFamily
) => (
  <Icon
    type={iconType}
    size="x2"
    color={color}
    family={family || IconFamily.regular}
    className={classNames('par-activity-styled-item__icon-wrapper', className)}
  />
);

export const getWebAppIcon = (icon, action, isApp) => {
  if (icon && isApp) {
    return icon;
  }
  if (action === ACTION_BLOCK) {
    return SchoolTimelineIcon(
      IconType.ban,
      IconColor.error,
      'par-activity-styled-item__icon-wrapper--error'
    );
  }
  return SchoolTimelineIcon(
    IconType.globe,
    IconColor.secondary,
    'par-activity-styled-item__icon-wrapper--secondary'
  );
};
