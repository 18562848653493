import * as React from 'react';
import timePickerHelpers from './helpers';

const { getFormattedTime, getNormalisedTime, isInputTimeValid } =
  timePickerHelpers;

/**
 * __useTimePicker__
 *
 * Simple hook to extract logic from TimePicker component.
 *
 * Handles the following:
 * - the input value
 * - validation
 * - exports a handler for free value input (takes time value depending on the clock format, ex.: hh:mm am or HH:mm)
 * - exports a handler for controlled value input (from existent list, takes only 24h clock format time value)
 *
 * @param initialValue default time value (HH:mm) in 24h clock format
 * @param isMilitaryTime 24h clock or am/pm clock flag
 */
const useTimePicker = (initialValue: string, isMilitaryTime: boolean) => {
  const [inputValue, setInputValue] = React.useState<{
    text: string;
    value: string | null;
  }>({
    value: initialValue,
    text: getFormattedTime(isMilitaryTime, initialValue),
  });

  const invalid = inputValue.value === null;

  /**
   * @param inputText is the formatted time value that is only shown
   * as the input value. The format depends on the the `isMilitaryTime`
   * - HH:mm
   * - hh:mm aa
   */
  const onInputChangeHandler = React.useCallback(
    (inputText: string) => {
      const text = inputText.trim();
      const isValidValue = isInputTimeValid(isMilitaryTime, text);

      setInputValue({
        value: isValidValue ? getNormalisedTime(isMilitaryTime, text) : null,
        text: inputText,
      });
    },
    [setInputValue, isMilitaryTime]
  );

  /**
   * @param inputValue is the underling time value in 24h format
   * that is passed around to other components.
   * - HH:mm
   */
  const onDropdownChangeHandler = React.useCallback(
    (inputValue: string) => {
      const value = inputValue.trim();
      setInputValue({
        value,
        text: getFormattedTime(isMilitaryTime, value),
      });
    },
    [setInputValue, isMilitaryTime]
  );

  return {
    inputValue,
    invalid,
    onInputChangeHandler,
    onDropdownChangeHandler,
  };
};

export default useTimePicker;
