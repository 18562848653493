import {
  navigateToNowCardInfoModal,
  navigateToRoutineWithoutDailyTimeLimitInfoModal,
} from '../../../actions/Navigation';
import { UnifiedNowCardState } from '../../../businessLogic/unifiedNowCard/types';
import { ActionWrapper } from './types';

export const routineWithoutTimeLimit = (dispatch: ActionWrapper) => {
  dispatch(navigateToRoutineWithoutDailyTimeLimitInfoModal());
};

export const noActivityInfoModal = (
  dispatch: ActionWrapper,
  state: UnifiedNowCardState,
  deviceId?: number
) => {
  dispatch(navigateToNowCardInfoModal(state, deviceId));
};
