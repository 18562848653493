import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  LocationRecord,
  Status,
  STATUS_NONE,
  STATUS_PENDING,
  getAddressStatus,
  STATUS_AVAILABLE,
} from '../../records/location';
import DateIncludingHours from '../Date/DateIncludingHours';
import {
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
} from 'styleguide-react';

const Location: React.FunctionComponent<{
  location: LocationRecord;
}> = ({ location }) => {
  const status = getAddressStatus(location);

  const locationTime = status === STATUS_AVAILABLE && location.time && (
    <DateIncludingHours time={location.time} />
  );

  return (
    <FlexLayout mainaxis="row" marginTop="24" marginBottom="24">
      <FlexLayout mainaxis="row" marginRight="8" crossaxisAlignment="center">
        {addressIcon(status)}
      </FlexLayout>
      <Label>{addressLabel(status, location)}</Label>
      {locationTime}
    </FlexLayout>
  );
};

const addressLabel = (status: Status, location: LocationRecord) => {
  switch (status) {
    case STATUS_NONE:
      return t('Last location not available');
    case STATUS_PENDING:
      return t('Updating location...');
    default:
      return location.addressLines[0];
  }
};

const addressIcon = (status: Status) => {
  if (status === STATUS_NONE) {
    return (
      <Icon
        type={IconType.mapMarkerSlash}
        color={IconColor.primary}
        size={IconSize.lg}
      />
    );
  }
  return (
    <Icon
      type={IconType.mapMarkerAlt}
      color={IconColor.primary}
      size={IconSize.lg}
    />
  );
};

export default Location;
