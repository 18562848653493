import {
  SmartDeviceStatusStatus,
  SmartDevicesAnalyticsReport,
  SmartDevicesStatusActions,
} from './types';

export const updateIsFetchingSmartDeviceStatusAction = (
  status: SmartDeviceStatusStatus
): SmartDevicesStatusActions => ({
  type: 'SMART_DEVICES_UPDATE_FETCHING',
  payload: { status },
});

export const SmartDeviceStatusLoadedAction = (
  devices: Array<SmartDevicesAnalyticsReport>
): SmartDevicesStatusActions => ({
  type: 'SMART_DEVICES_UPDATE_LOADED',
  payload: { devices },
});
