import * as React from 'react';
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  FlexLayout,
  Icon,
  IconSize,
  IconType,
  Layout,
  Typography,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import LazyImage from '../../LazyImage/LazyImage';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import { classNames } from '../../../helpers';
import { ISOLanguage } from '../../../constants';

const ROUTINE_EMPTY_LIST_TEST_ID = 'routine-empty-list';

interface RoutinesEmptyStateProps {
  accountLocale: ISOLanguage;
  onAddNewRoutine: () => void;
}

const RoutinesEmptyState = ({
  accountLocale,
  onAddNewRoutine,
}: RoutinesEmptyStateProps) => (
  <FlexLayout mainaxis="column" testId={ROUTINE_EMPTY_LIST_TEST_ID}>
    <RenderWhen screenSize={[ScreenSize.TabletOrDesktop, ScreenSize.Mobile]}>
      {screenSize => (
        <FlexLayout
          mainaxis="column"
          className={classNames(
            'par-routine-empty-state__container',
            screenSize === ScreenSize.Mobile &&
              'par-routine-empty-state__container--compact'
          )}
        >
          <Layout textAlignement="center" minHeight="280">
            <LazyImage
              src={`/illustrations/Routines/illustration_premium_routines__${accountLocale}.png`}
              width="290px"
              alt={t('Create your first routine')}
            />
          </Layout>

          <Typography
            type="title1"
            align="center"
            weight="semibold"
            marginBottom="8"
          >
            {t('Create your first routine')}
          </Typography>

          <Typography type="body1" align="center" marginBottom="48">
            {t(
              'Set special app, website, and time limit rules for activities like homework and sleep to ensure healthy habits.'
            )}
          </Typography>

          <Button
            testId={`${ROUTINE_EMPTY_LIST_TEST_ID}-add-routine`}
            size={ButtonSize.medium}
            onClick={onAddNewRoutine}
            iconPosition={ButtonIconPosition.right}
            icon={<Icon type={IconType.plus} size={IconSize.regular} />}
          >
            {t('Add a routine')}
          </Button>
        </FlexLayout>
      )}
    </RenderWhen>
  </FlexLayout>
);

export default RoutinesEmptyState;
