import * as React from 'react';
import { t } from '../../lib/i18n';
import { Modal, Button, ButtonSize } from 'styleguide-react';
import { modalDimensions } from '../../constants';

const UnsupportedBrowsersBlockedModal: React.FunctionComponent<{
  appName: string;
  onClickLink: () => any;
  onClickClose: () => any;
}> = ({ appName, onClickLink, onClickClose }) => (
  <Modal
    title={t('Unsupported browsers are currently blocked')}
    onClickClose={onClickClose}
    height={modalDimensions.regular.height}
    width={modalDimensions.regular.width}
    buttons={[
      <Button key="button" block size={ButtonSize.medium} onClick={onClickLink}>
        {t('Go to Settings')}
      </Button>,
    ]}
  >
    {t(
      'You need to disable blocking of unsupported browsers to enable {{appName}}.',
      {
        appName,
      }
    )}
  </Modal>
);

export default UnsupportedBrowsersBlockedModal;
