import { connect } from 'react-redux';
import ProfileListItem from '../components/Profiles/ProfileListItem';
import { getProfile, getKidHasTamparedDevice } from '../selectors';
import State from '../store/state';
import { ensureGetSummaryAlertCounter } from './ProfileNotificationTopBar/summaryAlertsHelper';

const mapStateToProps = (
  state: State,
  {
    profileId,
    onClick,
    showStatus,
  }: {
    profileId: number;
    onClick: () => void;
    showStatus: boolean;
  }
) => {
  const profile = getProfile(state, profileId);
  const alertCounter = ensureGetSummaryAlertCounter(state, profile?.uid);

  return {
    onClick,
    showStatus,
    hasTamperedDevice: getKidHasTamparedDevice(state, profileId),
    profile,
    alertCounter,
  };
};

const ProfileListItemContainer = connect(mapStateToProps)(ProfileListItem);

export type ProfileListItemProps = ReturnType<typeof mapStateToProps>;

export default ProfileListItemContainer;
