import { connect } from 'react-redux';
import {
  close,
  onClickAccept,
} from '../../actions/YoutubeAgreementWebBlockActions';
import { getProfile } from '../../selectors';
import YoutubeAgreementWebBlock from '../../components/Modal/YoutubeAgreementWebBlock';

const mapStateToProps = (state, { params: { profileId } }) => ({
  profileName: getProfile(state, profileId).get('name') || '',
});

const mapDispatchToProps = (dispatch, { params: { profileId, toggleOn } }) => ({
  onClickClose: () => {
    dispatch(close());
  },
  onClickConfirm: () => {
    dispatch(onClickAccept(profileId, toggleOn === 'true'));
  },
});

const YoutubeAgreementWebBlockModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubeAgreementWebBlock);

export default YoutubeAgreementWebBlockModalContainer;
