import {
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
  RULE_ROUTINES,
} from '../../../../constants';
import { isTrial } from '../../../../records/license';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

const trialDaysLeftProfileRules = [
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
  RULE_ROUTINES,
];

const trialDaysLeftForSchoolLicensesRules = [
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
];

export const isVisibleTrialDaysLeftLabel = ({
  profileRule,
  license,
  licenseDaysLeft,
  showConversion,
  isAccountLinkedToSchool,
  isSchoolReferralLicense,
}: ProfileRulesLabelPredicatesProps) => {
  if (!showConversion) return false;

  const trial = isTrial(license.type);
  const rules =
    isAccountLinkedToSchool || isSchoolReferralLicense
      ? trialDaysLeftForSchoolLicensesRules
      : trialDaysLeftProfileRules;

  return trial && licenseDaysLeft > 0 && rules.includes(profileRule);
};
