import * as React from 'react';
import { t } from '../../lib/i18n';
import SchoolsOnboardingErrorContent, {
  SchoolsOnboardingErrorContentDispatchProps,
} from './SchoolsOnboardingErrorContent';

const SchoolsOnboardingErrorWrongInfo: React.FunctionComponent<
  SchoolsOnboardingErrorContentDispatchProps
> = ({
  onClickContactUs,
  onClickNavigateBack,
}: SchoolsOnboardingErrorContentDispatchProps) => (
  <SchoolsOnboardingErrorContent
    title={t('Something not right?')}
    subtitle={t(
      'If there are any issues with the information given, please contact customer support for help.'
    )}
    showBackButton
    onClickContactUs={onClickContactUs}
    onClickNavigateBack={onClickNavigateBack}
  />
);

export default SchoolsOnboardingErrorWrongInfo;
