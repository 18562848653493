import { connect } from 'react-redux';
import YoutubeRules from '../../components/YoutubeRules/YoutubeRules';
import {
  getYoutubeAppRules,
  getProfileOrDefault,
  isYoutubeWebRuleEnabled,
  getAppRulesEnabled,
  getIsBlockedUnsupportedBrowsers,
  getLicense,
} from '../../selectors';
import {
  clickBack,
  userAgreementWeb,
  toggleYoutubeAppEnabled,
} from '../../actions/YoutubeRuleActions';
import { changeQuota } from '../../actions/AppRulesActions';
import { redirectFreeToPremiumAction } from '../../actions/RedirectFreeToPremiumAction';
import { setLastRuleTypeModified, RuleType } from '../../ducks/appRate';
import { Dispatch } from '../../store/state';
import { createOnSelectStatusHandler } from '../ProfileRules/AppRules/AppRulesContainer';
import { AppRuleRecord } from '../../records/profileRules';
import { List } from 'immutable';
import { navigateToNonBlockableAppModalFrom } from '../../actions/Navigation';
import { AppRulesCardProps } from '../../components/AppRules/AppRulesCard';
import { shouldBlockThisFeature } from '../../helpers/premiumFeatures';
import { showPremiumFlyover } from '../../actions/PremiumFeaturesActions';
import { redirectToAddDeviceFlyover } from '../../actions/redirectAddDeviceFlyover';
import { isSchoolLicenseWithValidDevices } from '../../businessLogic/profileRules/delegation';

const mapStateToProps = (state, { params: { profileId } }) => {
  return {
    youtubeDeviceRules: getYoutubeAppRules(
      state,
      profileId
    ) as List<AppRuleRecord>,
    profile: getProfileOrDefault(state, profileId),
    webRuleEnabled: isYoutubeWebRuleEnabled(state, profileId) as boolean,
    appRulesEnabled: getAppRulesEnabled(state, profileId) as boolean,
    isBlockedUnsupportedBrowsers: getIsBlockedUnsupportedBrowsers(
      state,
      profileId
    ) as boolean,
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId } }) => ({
  onClickBack: () => dispatch(clickBack()),
  onClickWeb: (toggleOn: boolean) => () =>
    dispatch(userAgreementWeb(profileId, toggleOn)),
  onSelectStatus: (data: Parameters<AppRulesCardProps['onSelectStatus']>[0]) =>
    dispatch(createOnSelectStatusHandler(data, profileId)),
  onClickApp: appKey =>
    dispatch(navigateToNonBlockableAppModalFrom('appRules', profileId, appKey)),
});

export const handleToggleYoutubeAppEnabled =
  ({ profileId, appKey, toggleOn }) =>
  (dispatch: Dispatch) => {
    dispatch(setLastRuleTypeModified(RuleType.YoutubeMonitoring));
    dispatch(toggleYoutubeAppEnabled(profileId, appKey, toggleOn));
  };

export const handleChangeQuota =
  ({ selectedDay, appKey, quota, profileId }) =>
  (dispatch: Dispatch) => {
    dispatch(setLastRuleTypeModified(RuleType.YoutubeMonitoring));
    dispatch(changeQuota(profileId, selectedDay, appKey, quota));
  };

const YoutubeRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubeRules);

export type YoutubeRulesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

YoutubeRulesContainer.redirect =
  ({ profileId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const license = getLicense(state);

    const allowAccessToRulesPage = isSchoolLicenseWithValidDevices(
      state,
      profileId
    );

    if (allowAccessToRulesPage) return;
    await dispatch(
      redirectToAddDeviceFlyover(
        profileId,
        `/profiles/${profileId}/rules/youtube-rules/addDeviceFlyover`
      )
    ).then(redirectedToAddDevice => {
      if (redirectedToAddDevice) return;
      if (shouldBlockThisFeature('youtubeRules', license.subtype)) {
        return dispatch(
          showPremiumFlyover(
            `/profiles/${profileId}/rules/youtube-rules/premiumFlyover`
          )
        );
      }
      dispatch(
        redirectFreeToPremiumAction(
          profileId,
          'rules/youtube-rules/premiumFlyover'
        )
      );
    });
  };

export default YoutubeRulesContainer;
