import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  ModalStyledText,
  Typography,
  ButtonType,
  Icon,
  IconType,
  GlobalType,
  ButtonIconPosition,
  IconFamily,
  AlertBox,
  AlertBoxType,
  Layout,
} from 'styleguide-react';
import type { RemoveDeviceModalProps } from '../../containers/Modal/RemoveDeviceModalContainer';

const RemoveDeviceModal = ({
  advanceProtectionStatus,
  deviceId,
  onClose,
  onRemove,
  onOpenHelpArticle,
}: RemoveDeviceModalProps) => {
  if (!deviceId) return null;
  return (
    <Modal
      size="medium"
      onClickClose={onClose}
      header={{
        icon: (
          <Icon type={IconType.exclamationTriangle} color={GlobalType.error} />
        ),
      }}
      buttons={
        advanceProtectionStatus === 'enabled'
          ? removeNonTamperableIosDeviceModalButtons(
              deviceId,
              onRemove,
              onOpenHelpArticle
            )
          : defaultModalButtons(deviceId, onRemove, onClose)
      }
    >
      <Typography type="h5" weight="semibold">
        {t('Remove this device?')}
      </Typography>

      <ModalStyledText marginBottom="16">
        {t(
          'All account and usage information associated with this device will be deleted from {{shortName}} and this device will no longer be protected.'
        )}
      </ModalStyledText>

      {advanceProtectionStatus === 'enabled' && (
        <Layout marginTop="32" textAlignement="left">
          <AlertBox
            type={AlertBoxType.error}
            icon={IconType.infoCircle}
            fullWidth
            centered={false}
          >
            {t(
              'Please uninstall Qustodio Advance before removing this device.'
            )}
          </AlertBox>
        </Layout>
      )}
    </Modal>
  );
};

const defaultModalButtons = (
  deviceId: string,
  onRemove: RemoveDeviceModalProps['onRemove'],
  onClose: RemoveDeviceModalProps['onClose']
) => [
  <Button
    key="remove"
    block
    size={ButtonSize.medium}
    testId="confirm-remove-device"
    onClick={() => onRemove(deviceId)}
  >
    {t('Remove')}
  </Button>,
  <Button
    key="cancel"
    block
    buttonType={ButtonType.secondary}
    size={ButtonSize.medium}
    onClick={onClose}
  >
    {t('Cancel')}
  </Button>,
];

const removeNonTamperableIosDeviceModalButtons = (
  deviceId: string,
  onRemove: RemoveDeviceModalProps['onRemove'],
  onOpenHelpArticle: RemoveDeviceModalProps['onOpenHelpArticle']
) => [
  <Button
    key="cancel"
    block
    size={ButtonSize.medium}
    onClick={onOpenHelpArticle}
    icon={
      <Icon type={IconType.arrowUpRightFromSquare} family={IconFamily.solid} />
    }
    iconPosition={ButtonIconPosition.right}
  >
    {t('Uninstall Qustodio Advance')}
  </Button>,
  <Button
    key="remove"
    block
    size={ButtonSize.medium}
    buttonType={ButtonType.plain}
    testId="confirm-remove-device"
    onClick={() => onRemove(deviceId)}
  >
    {t('Remove anyway')}
  </Button>,
];

export default RemoveDeviceModal;
