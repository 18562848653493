import * as React from 'react';
import { OrderedSet } from 'immutable';
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  FlexLayout,
  Icon,
  IconColor,
  List as RegularStyleList,
  EmptyPlaceholder,
  RegularStyleListItem,
  IconSize,
  IconType,
  ClickableListItem,
  IconFamily,
  ListTitle,
  ListSubtitle,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { DeviceProtectionStatus } from '../../records/device';
import { joinObjectSet } from '../../helpers/string';
import { mapCond } from '../../helpers';
import { PLATFORM_ANDROID, PLATFORM_IOS, PLATFORM_MAC } from '../../constants';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import MobileLayoutScssUtils from '../Layout/mobile/MobileLayoutScssUtils';
import type { DeviceListProps } from '../../containers/Devices/DeviceListContainer';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import DeviceItemAntiTamperingStatus from './DeviceItemAntiTamperingStatus';

const DeviceList = (deviceListProps: DeviceListProps) => {
  return (
    <PageLayout
      title={t('Your devices')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          useNonFlexLayout: true,
        },
      ]}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
    >
      <DevicesListFields {...deviceListProps} />
    </PageLayout>
  );
};

const DevicesListFields = ({
  devicesWithProfilesAndStatus,
  onDeviceClick,
  onDeviceAddClick,
}: DeviceListProps) => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-between"
      flexGrow="2"
      className={MobileLayoutScssUtils.HeightWithoutPageTitle}
    >
      {devicesWithProfilesAndStatus.size ? (
        <FlexLayout mainaxis="column" flexGrow="2">
          <RegularStyleList
            listItemMarginTop="8"
            listItemPaddingTop="8"
            listItemMarginBottom="8"
            listItemPaddingBottom="8"
            testId="devices"
          >
            {devicesWithProfilesAndStatus.map(
              ({ device, profiles, status }) => (
                <RegularStyleListItem
                  key={device.id}
                  title={
                    <ClickableListItem onClick={onDeviceClick(device.id)}>
                      <ListTitle allowLines="1">{device.name}</ListTitle>
                    </ClickableListItem>
                  }
                  upperSubtitle={
                    <ClickableListItem onClick={onDeviceClick(device.id)}>
                      <ListSubtitle allowLines="1">
                        {assignedToText(profiles)}
                      </ListSubtitle>
                    </ClickableListItem>
                  }
                  lowerSubtitle={
                    <ClickableListItem onClick={onDeviceClick(device.id)}>
                      <ListSubtitle allowLines="1">
                        {statusText(status)}
                      </ListSubtitle>
                    </ClickableListItem>
                  }
                  actionElementVerticalAlign="center"
                  iconVerticalAlign="center"
                  icon={
                    <Icon
                      type={deviceStatusIcon(device.platform)}
                      color={deviceStatusIconColor(status)}
                      size={deviceStatusIconSize(device.platform)}
                      family={IconFamily.brands}
                      square
                    />
                  }
                  actionElement={
                    <ClickableListItem onClick={onDeviceClick(device.id)}>
                      <FlexLayout
                        mainaxis="row"
                        mainaxisAlignment="center"
                        gap="16"
                      >
                        <DeviceItemAntiTamperingStatus
                          device={device}
                          protectionStatus={status}
                        />
                        <Icon
                          color={IconColor.primary}
                          size={IconSize.lg}
                          type={IconType.angleRight}
                          onClick={onDeviceClick(device.id)}
                        />
                      </FlexLayout>
                    </ClickableListItem>
                  }
                />
              )
            )}
          </RegularStyleList>
        </FlexLayout>
      ) : null}

      {!devicesWithProfilesAndStatus.size && (
        <FlexLayout
          mainaxis="column"
          crossaxisAlignment="center"
          mainaxisAlignment="center"
          marginTop="32"
          flexGrow="2"
        >
          <EmptyPlaceholder
            testId="devices"
            text={t(
              "You don't have any devices protected by {{shortName}} yet"
            )}
          />
        </FlexLayout>
      )}

      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="center"
        marginTop="24"
        paddingBottom="32"
        crossaxisAlignment="center"
      >
        <Button
          onClick={onDeviceAddClick}
          size={ButtonSize.medium}
          icon={<Icon type="fas fa-plus" color={IconColor.contrast} />}
          iconPosition={ButtonIconPosition.right}
          testId="addDevice"
        >
          {t('Add Device')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

const assignedToText = (profiles: OrderedSet<ProfileRecord>) =>
  profiles.size === 0
    ? t('No child assigned')
    : t('Assigned to: {{profileNames}}', {
        profileNames: joinObjectSet(profiles, 'name', ', '),
      });

const statusText = (status: DeviceProtectionStatus) => {
  switch (status) {
    case 'ProtectionEnabled':
      return t('Protection Enabled');
    case 'ProtectionUnprotected':
      return t('Not protecting anyone');
    case 'ProtectionTampered':
      return t('Device Tampered');
    case 'ProtectionDisabled':
      return t('Protection Disabled');
    default:
      return '';
  }
};

const deviceStatusIcon = mapCond(
  [
    [PLATFORM_ANDROID, IconType.android],
    [PLATFORM_IOS, IconType.apple],
    [PLATFORM_MAC, IconType.apple],
  ],
  IconType.windows
);

const deviceStatusIconSize = mapCond(
  [
    [PLATFORM_ANDROID, IconSize.x2],
    [PLATFORM_IOS, IconSize.x2],
    [PLATFORM_MAC, IconSize.x2],
  ],
  IconSize.x2
);

const deviceStatusIconColor = mapCond<DeviceProtectionStatus, string>(
  [
    ['ProtectionUnprotected', IconColor.error],
    ['ProtectionDisabled', IconColor.neutral],
    ['ProtectionTampered', IconColor.error],
  ],
  IconColor.success
);

export default DeviceList;
