import { ofType } from 'redux-observable';
import { map, switchMap, catchError, of, merge, throttleTime } from 'rxjs';
import { MinutesPerHourActivityPayload } from '../../records/activity/types/MinutesPerHourActivity.types';
import { HoursPerDayActivityPayload } from '../../records/activity/types/HoursPerDayActivity.types';
import {
  LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY,
  formatTo24HoursScreenTime,
  fetchSelectedDateScreenTimePerDay,
  fetchSelectedDateScreenTimePerHour,
  receiveSummaryPerDays,
  receiveSummaryPerHours,
  requestSummaryPerDays,
  requestSummaryPerHours,
  screenTimeActivityError,
} from '../../ducks/summary';
import { HoursPerDayActivityRecord } from '../../records/activity';
import { ProfileId } from '../../records/profile/types/Profile.types';
import { getProfile, getTimezone } from '../../selectors';
import { AppActionWithPayload, AppEpic } from '../types';
import { summaryErrorHandler } from '../helpers/dataLoaderHelpers';
import State from '../../store/state';
import { List } from 'immutable';

type RequestSummaryPerDaysAction = ReturnType<typeof requestSummaryPerDays>;
type RequestSummaryPerHoursAction = ReturnType<typeof requestSummaryPerHours>;

type ReceiveSummaryApiCallResponse<T = unknown[]> = T;
type ReceiveSummaryPerDaysAction = ReturnType<typeof receiveSummaryPerDays>;
type ReceiveSummaryPerHoursAction = ReturnType<typeof receiveSummaryPerHours>;

interface LoadSummaryScreenTimeActivityAction {
  payload: {
    profileId: ProfileId;
  };
}

const TTL_TIME = 60000;

const notifyIfError = catchError(e => {
  return of(summaryErrorHandler(e, screenTimeActivityError));
});

const toReceiveSummaryPerHoursAction = (
  state: State,
  data: ReceiveSummaryApiCallResponse<MinutesPerHourActivityPayload[]>
): ReceiveSummaryPerHoursAction => {
  return receiveSummaryPerHours(
    formatTo24HoursScreenTime(data, getTimezone(state))
  );
};

const toReceiveSummaryPerDaysAction = (
  data: ReceiveSummaryApiCallResponse<HoursPerDayActivityPayload[]>
): ReceiveSummaryPerDaysAction => {
  return receiveSummaryPerDays(
    List(data.map(HoursPerDayActivityRecord.fromPayload))
  );
};

export const summaryScreenTimeActivityEpic: AppEpic<
  AppActionWithPayload,
  ReceiveSummaryPerDaysAction &
    ReceiveSummaryPerHoursAction &
    RequestSummaryPerDaysAction &
    RequestSummaryPerHoursAction
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_WIDGET_SUMMARY_SCREEN_TIME_ACTIVITY),
    throttleTime(TTL_TIME),
    map((action: LoadSummaryScreenTimeActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  const loadingActionSummaryPerHours = profile$.pipe(
    map(() => requestSummaryPerHours())
  );
  const requestActionsSummaryPerHours = profile$.pipe(
    switchMap(profile => {
      return of(profile).pipe(
        switchMap(profile =>
          fetchSelectedDateScreenTimePerHour(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ),
        map(response => toReceiveSummaryPerHoursAction(state$.value, response)),
        notifyIfError
      );
    })
  );

  const loadingActionsSummaryPerDays = profile$.pipe(
    map(() => requestSummaryPerDays())
  );
  const requestActionsSummaryPerDays = profile$.pipe(
    switchMap(profile => {
      return of(profile).pipe(
        switchMap(profile =>
          fetchSelectedDateScreenTimePerDay(api, {
            profileId: profile?.id,
            state: state$.value,
          })
        ),
        map(response => toReceiveSummaryPerDaysAction(response)),
        notifyIfError
      );
    })
  );

  return merge(
    merge(loadingActionSummaryPerHours, requestActionsSummaryPerHours),
    merge(loadingActionsSummaryPerDays, requestActionsSummaryPerDays)
  );
};
