import { createSelector } from 'reselect';
import { getAccountCurrentTime } from './account';
import State from '../store/state';
import { formatDayToAPI } from '../helpers/dates';
import { SummaryDateRanges } from '../constants';

export const getStudentSummarySignatures = (state: State) =>
  state.get('studentSummary').get('signaturesData');

export const getStudentSummaryTotalUsage = (state: State) =>
  state.get('studentSummary').get('totalUsageData');

export const getStudentSummaryCategories = (state: State) =>
  state.get('studentSummary').get('categoriesData');

export const getStudentSummaryActiveDateRange = (state: State) =>
  state.get('studentSummary').get('activeDateRange');

export const getStudentSummaryLastUpdated = (state: State) =>
  state.get('studentSummary').get('lastUpdated');

export const getStudentSummaryLastResultProfileUid = (state: State) =>
  state.get('studentSummary').get('resultProfileUid');

export const isStudentSummaryDataStale = (state: State) => {
  const lastFetchedTime = state.get('studentSummary').get('lastFetched');
  if (!lastFetchedTime) return true;
  return Date.now() - lastFetchedTime > 1000 * 60 * 5; // 5 minutes
};

export const getSummarySelectedPastDate = (state: State) =>
  state.get('studentSummary').get('selectedPastDate');

export const getSelectedDateRange = createSelector(
  getAccountCurrentTime,
  getStudentSummaryActiveDateRange,
  getSummarySelectedPastDate,
  (currentTime, numberOfDays, pastDate) => {
    if (numberOfDays === SummaryDateRanges.CustomPastDay) {
      return {
        minDate: pastDate,
        maxDate: pastDate,
      };
    }
    return {
      minDate: formatDayToAPI(
        currentTime.clone().subtract(numberOfDays - 1, 'd')
      ),
      maxDate: formatDayToAPI(currentTime),
    };
  }
);

export const isFetchingSummaries = (state: State) =>
  state.get('studentSummary').get('initialLoading') ||
  state.get('studentSummary').get('isFetchingTotalUsage') ||
  state.get('studentSummary').get('isFetchingSignatures') ||
  state.get('studentSummary').get('isFetchingCategories');

export const getSummaryFetchError = (state: State) =>
  state.get('studentSummary').get('summaryFetchError');

export const isFetchingTotalUsage = (state: State) =>
  state.get('studentSummary').get('isFetchingTotalUsage');

export const isFetchingCategories = (state: State) =>
  state.get('studentSummary').get('isFetchingCategories');

export const isFetchingSignatures = (state: State) =>
  state.get('studentSummary').get('isFetchingSignatures');

export const getSummaryFetchTotalUsageError = (state: State) =>
  state.get('studentSummary').get('summaryFetchTotalUsageError');

export const getSummaryFetchCategoriesError = (state: State) =>
  state.get('studentSummary').get('summaryFetchCategoriesError');

export const getSummaryFetchSignaturesError = (state: State) =>
  state.get('studentSummary').get('summaryFetchSignaturesError');
