import { ofType } from 'redux-observable';
import {
  Observable,
  catchError,
  concat,
  filter,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { List } from 'immutable';
import {
  LOAD_WIDGET_APPS_WEB_ACTIVITY,
  receiveSummaryAppsAndWebActivity,
  requestSummaryAppsAndWebActivity,
  summaryAppsAndWebsActivityError,
} from '../../ducks/summary';
import { AppActionWithPayload, AppEpic } from '../types';
import { getProfile, getSelectedDateRange } from '../../selectors';
import { ActivitySummaryFilters } from '../../types/summary';
import {
  ActivityTypes,
  AppActivityRecord,
  WebDomainActivityRecord,
} from '../../records/activity';
import { replaceS3Url } from '../../helpers';
import * as iconAppDefault from '../../assets/base/images/icons/icon-app-default.png';
import { ProfileId } from '../../records/profile';
import {
  getNow,
  requestProfileFilter,
  summaryErrorHandler,
} from '../helpers/dataLoaderHelpers';
import { ttl1Minute } from '../../lib/QApiCache/commonCacheStrategies';

type ReceiveSummaryAppsAndWebActivityResponse = { items: AppActivityRecord[] };

type ReceiveSummaryAppsAndWebActivityAction = ReturnType<
  typeof receiveSummaryAppsAndWebActivity
>;

type RequestSummaryAppsAndWebs = ReturnType<
  typeof requestSummaryAppsAndWebActivity
>;

interface LoadSummaryAppsAndWebActivityAction {
  payload: {
    profileId: ProfileId;
  };
}

const notifyIfError = catchError(e => {
  return of(summaryErrorHandler(e, summaryAppsAndWebsActivityError));
});

const getSummaryDomainAndAppsActivity = (
  api,
  { profileUid, state }
): Promise<ReceiveSummaryAppsAndWebActivityResponse> => {
  const { minDate, maxDate } = getSelectedDateRange(state);
  return api.summaryDomainsAndApps.withCache(ttl1Minute).get({
    profileUid,
    filter: ActivitySummaryFilters.NotBlocked,
    minDate,
    maxDate,
  });
};

const toReceiveSummaryAppWebActivityAction = (
  response: ReceiveSummaryAppsAndWebActivityResponse
): ReceiveSummaryAppsAndWebActivityAction => {
  return receiveSummaryAppsAndWebActivity(
    List(
      response.items
        .map(item =>
          item.type === ActivityTypes.App
            ? {
                ...item,
                thumbnail:
                  item.thumbnail && item.thumbnail.length > 0
                    ? replaceS3Url(item.thumbnail)
                    : iconAppDefault,
              }
            : item
        )
        .map(item =>
          item.type === ActivityTypes.WebDomain
            ? WebDomainActivityRecord.fromPayload(item)
            : AppActivityRecord.fromPayload(item)
        )
    )
  );
};

export const SummaryAppsAndWebActivityEpic: AppEpic<
  AppActionWithPayload,
  ReceiveSummaryAppsAndWebActivityAction & RequestSummaryAppsAndWebs
> = (actions$, state$, { api }) => {
  const profile$ = actions$.pipe(
    ofType(LOAD_WIDGET_APPS_WEB_ACTIVITY),
    map((action: LoadSummaryAppsAndWebActivityAction) =>
      getProfile(state$.value, action.payload.profileId)
    )
  );

  let lastFetchTime = getNow(state$.value);
  let lastProfileFetched;

  const loadData = profile$.pipe(
    filter(profile =>
      requestProfileFilter({
        profile,
        state: state$.value,
        lastFetchTime,
        lastProfileFetched,
      })
    ),
    switchMap(profile => {
      lastProfileFetched = profile?.uid;
      lastFetchTime = getNow(state$.value);
      return concat(
        of(requestSummaryAppsAndWebActivity()),
        from(
          getSummaryDomainAndAppsActivity(api, {
            profileUid: profile?.uid,
            state: state$.value,
          })
        ).pipe(
          map(list => toReceiveSummaryAppWebActivityAction(list)),
          notifyIfError
        )
      ) as Observable<
        ReceiveSummaryAppsAndWebActivityAction & RequestSummaryAppsAndWebs
      >;
    })
  );

  return loadData;
};

export default SummaryAppsAndWebActivityEpic;
