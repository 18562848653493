import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import RoutineWithoutTimeLimitInfoModal from '../../components/Modal/RoutineWithoutTimeLimitInfoModal';
import {
  getAccountCurrentTime,
  getProfile,
  getTimeQuotas,
  getTodayScreenTimeMinutes,
} from '../../selectors';
import { WEEKDAYS, minutesToHMFormat } from '../../helpers/dates';
import { calcPercent } from '../../helpers';

const ensurePercent = (screenTime: number, dayTimeLimit: number) => {
  /* 
  At the user interface level, it doesn't make sense to display the time bar with a 
  percentage if the quota has been exceeded. 
  */
  if (Number.isNaN(dayTimeLimit) || Number.isNaN(screenTime)) {
    return 0;
  }

  if (screenTime >= dayTimeLimit) {
    return 100;
  }

  return calcPercent(screenTime, dayTimeLimit);
};

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const screenTime = getTodayScreenTimeMinutes(state);
  const deviceId = getProfile(state, profileId)?.deviceIds?.first() || 0;
  const currentTime = getAccountCurrentTime(state);
  const today = WEEKDAYS[currentTime.isoWeekday() - 1];
  const dayTimeLimit = (getTimeQuotas as any)(
    state,
    profileId,
    deviceId,
    today
  );
  const consumedPercent = ensurePercent(screenTime, dayTimeLimit);

  return {
    screenTime: minutesToHMFormat(screenTime),
    screenTimeDailyLimit: minutesToHMFormat(dayTimeLimit),
    consumedPercent,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(goBackIfHistory());
  },
});

const RoutineWithoutTimeLimitInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutineWithoutTimeLimitInfoModal);

export type RoutineWithoutTimeLimitInfoModalProps = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

export default RoutineWithoutTimeLimitInfoModalContainer;
