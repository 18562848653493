import * as React from 'react';
import { classNames } from '../../helpers';

const ProgressBar: React.FunctionComponent<{
  className?: string;
  style?: React.CSSProperties;
  percent: number;
  warning: boolean;
}> = ({ className, style, percent, warning }) => {
  const pathStyle = {
    strokeDasharray: '100px, 100px',
    strokeDashoffset: `${100 - percent}px`,
    transition: 'stroke-dashoffset 0.3s ease 0s, stroke 0.3s linear',
  };
  const strokeWidth = 5;

  const center = strokeWidth / 2;
  const right = 100 - center;
  const pathString = `M ${center},${center}
          L ${right},${center}`;
  const viewBoxString = `10 0 88 ${strokeWidth}`;

  const strokeClass = classNames(
    'ProgressBar__path',
    percent === 100 || warning ? 'ProgressBar__path--warning' : ''
  );

  return (
    <svg
      className={`ProgressBar ${className}`}
      viewBox={viewBoxString}
      preserveAspectRatio="none"
      style={style}
    >
      <path
        className="ProgressBar__trail"
        d={pathString}
        strokeWidth={5}
        fillOpacity="0"
      />
      <path
        className={strokeClass}
        d={pathString}
        strokeWidth={strokeWidth}
        fillOpacity="0"
        style={pathStyle}
      />
    </svg>
  );
};

export default ProgressBar;
