import * as R from 'ramda';
import { isBrowserPlatform } from '../../helpers/index';
import {
  updateParentDeviceVersionIfNeeded,
  createParentDeviceIfNeeded,
} from '../parentDevice';
import { updateCurrentTime, tick } from '../times';
import { initializePushService } from '../pushService';
import {
  setStorageListener,
  setMomentLocale,
  setupBraze,
} from '../../sideEffects/globals';
import { logout } from '../../sideEffects/logout';
import {
  getAccessType,
  isPurchaseFlow,
  isOpenedFromBackground,
  getPersistentStorageItem,
  getCurrentTimeRaw,
  getAccount,
  getDefaultProductCode,
  getProduct,
} from '../../selectors';

import { parseQuery, QueryWebAutologin } from '../../helpers/query';
import { TICK_INTERVAL_MS, SESSION_TIMEOUT_MS } from '../../constants';
import { APIError, SessionTimeoutError } from '../../lib/errors';
import { openInitialScreen } from '../../actions/Navigation';
import { BaseThunk } from '../../store/state';
import {
  trackApplicationOpened,
  trackWebOpened,
} from '../../helpers/analytics';
import {
  sendTagManagerEvent,
  TagManagerCustomEvents,
  tagManagerSetup,
} from '../../helpers/tagManager';
import {
  setLoginRedirectLocationIfNeeded,
  resetHistory,
  shouldRedirectToInitialScreen,
} from '../routing';
import { isRequestingTokensForWebClient } from '../../helpers/sso';
import {
  startWebSSOIfConditionsAreMet,
  resetHistoryAfterWebSSOAuthFlowEnds,
} from '../../actions/sso';
import { setupAdjustIfNeeded } from '../../lib/vendor/adjust';
import { reportAttribution, getAttributionFrom } from '../attribution';
import { welcomeNewAdditionalParent } from '../invitations/invitationsActions';
import { RouteLocation } from '../../types/RouteLocation.types';
import { captureException } from '../../helpers/sentry';
import { isLockScreenEnabled } from '../../selectors/lockState';
import { lock } from '../../actions/LockScreenActions';
import { RouterAction } from '../../helpers/multiPlatformNavigation';
import { IS_DATA_FETCHED } from '../actionNames/app';
import { initializeWithParams } from './initializeWithParams';
import { isValidISODateString } from '../../helpers/dates';
import { setItem } from '../persistentStorage';
import { configureRollout } from './configureRollout';
import { fetchAppData } from './fetchAppData';
import { fetchProducts } from '../products';
import { AccountRecord, ProductRecord } from '../../records';
import { ensureSetDirectPurchaseData } from '../directPurchase/thunks';

export const setIsDataFetched = isFetched => {
  return {
    type: IS_DATA_FETCHED,
    payload: isFetched,
  };
};

/**
 * Gets installationTs or set it if it does not exist, and returns the value
 */
function setInstallDate() {
  return (dispatch, getState) =>
    Promise.resolve(
      getPersistentStorageItem(getState(), 'installationTs')
    ).then(
      R.when(R.complement(isValidISODateString), () =>
        dispatch(setItem('installationTs', getCurrentTimeRaw(getState())))
      )
    );
}

/**
 * Sets sessionStartTs and returns new value
 */
function setDailySession() {
  return (dispatch, getState) =>
    Promise.resolve(
      dispatch(setItem('sessionStartTs', getCurrentTimeRaw(getState())))
    );
}

/**
 * Set installationTs if not exists, set sessionStartTs
 */
export function checkSession() {
  return dispatch => {
    return Promise.resolve(dispatch(setInstallDate())).then(() => {
      dispatch(setDailySession());
    });
  };
}
/**
 * Initialize app
 * 1. Get parameters
 * 2. Load persistent data from storage
 * 3. Fetch all initial data from API
 * Must return a RouterAction to trigger a redirect in routes.tsx, or null if no
 * redirect should happen.
 */
export const bootApp = (
  query: string,
  location: RouteLocation,
  isColdStart: boolean,
  options: { resetHistory: boolean } = { resetHistory: true }
): BaseThunk<Promise<RouterAction | null>> => {
  return async (dispatch, getState) => {
    try {
      await dispatch(updateCurrentTime());
      await dispatch(setIsDataFetched(false));
      await dispatch(initializeWithParams(query));
      await dispatch(setStorageListener());
      const isRedirected = await dispatch(
        startWebSSOIfConditionsAreMet(location)
      );

      if (isRedirected) {
        return Promise.resolve(null);
      }

      await dispatch(checkSession());
      await dispatch(setLoginRedirectLocationIfNeeded(location));

      const shouldRedirectToInitial = dispatch(shouldRedirectToInitialScreen());

      if (shouldRedirectToInitial) {
        if (navigator.splashscreen) {
          navigator.splashscreen.hide();
        }
        // logout to force to follow the regular flow when user is not logged in and redirected to the initial screen
        // clear all possible old data before has been redirected
        return dispatch(logout());
      }

      await dispatch(fetchAppData());
      await dispatch(setMomentLocale());
      await dispatch(createParentDeviceIfNeeded());
      await dispatch(updateParentDeviceVersionIfNeeded());

      await dispatch(
        tick(
          TICK_INTERVAL_MS,
          isBrowserPlatform() ? SESSION_TIMEOUT_MS : undefined
        )
      );

      setupAdjustIfNeeded();
      await dispatch(configureRollout());
      await dispatch(ensureSetDirectPurchaseData());
      /**
       * We need to fetch the products after configuring rollout
       * because of pricing experiments, we need to know the variant
       * before fetching the products to apply for experiment promotions
       * if it is necessary
       */
      await dispatch(fetchProducts());
      const result = await dispatch(openInitialScreen());

      if (navigator.splashscreen) {
        navigator.splashscreen.hide();
      }

      // Track session init
      const state = getState();
      if (isBrowserPlatform()) {
        trackWebOpened();
      } else if (!isPurchaseFlow(state)) {
        trackApplicationOpened({
          fromBackground: isOpenedFromBackground(state),
        });
      }
      tagManagerSetup(
        (getAccount(getState()) as AccountRecord).uid,
        (getAccount(getState()) as AccountRecord).locale,
        (
          getProduct(
            getState(),
            getDefaultProductCode(getState())
          ) as ProductRecord
        )?.currency || 'USD'
      );
      sendTagManagerEvent(TagManagerCustomEvents.AccountLoggedIn, {
        accountLogged: true,
      });

      const parsedQuery = parseQuery(query) as QueryWebAutologin;
      const isFinishingWebSSOFlow = isRequestingTokensForWebClient(parsedQuery);

      if (isFinishingWebSSOFlow) {
        reportAttribution({
          attribution: getAttributionFrom(parsedQuery),
          type: parsedQuery.isNewAccount ? 'registration' : 'visit',
        });

        await dispatch(
          resetHistoryAfterWebSSOAuthFlowEnds(location, parsedQuery.state)
        );
      } else {
        if (isBrowserPlatform()) {
          reportAttribution({
            attribution: getAttributionFrom(location.query),
            type: 'visit',
          });
        }

        if (options?.resetHistory) {
          /*
          There's a scenario where redirection shouldn't occur,
          which is when we want to display the welcome subscription modal because
          this method is also called in the onload of the main component.
          */
          dispatch(resetHistory(location));
        }

        // Moved the push service initialize to the end of the bootapp
        // to avoid a race condition between reset history and the PN deep links
        // we have decided that it makes more sense to do this at the end of the boot app.
        // see https://qustodio.atlassian.net/browse/PAR-5237 for more info
        if (!isBrowserPlatform()) {
          await dispatch(initializePushService());
        }
        await dispatch(setupBraze());
      }

      dispatch(welcomeNewAdditionalParent());

      // We use isColdStart variable to determine if the app was terminated and opened again, we show
      // the lock screen even if it's been less than minimum time required to show lock screen.
      if (isLockScreenEnabled(state) && isColdStart) {
        dispatch(lock());
      }

      return Promise.resolve(result);
    } catch (error) {
      if (navigator.splashscreen) {
        navigator.splashscreen.hide();
      }

      if (APIError.isUnauthorizedAuthorization(error)) {
        return dispatch(logout({ showErrMsgInLogoutPageForWeb: true }));
      }
      if (APIError.isMissingAuthTokens(error)) {
        captureException(error, {
          extra: { accessType: getAccessType(getState()) },
        });
        dispatch(logout({ showErrMsgInLogoutPageForWeb: true }));

        // block promise: this shows spinner while redirecting to FP logout page
        return new Promise(() => undefined);
      }

      if (error instanceof SessionTimeoutError) {
        dispatch(logout({ showErrMsgInLogoutPageForWeb: true }));

        // block promise: this shows spinner while redirecting to FP logout page
        return new Promise(() => undefined);
      }

      /**
       * For any unknown errors during the bootApp in web, we want to end up in a clean
       * state, so we call the logout function.
       *
       * Example of errors: any 5XX response coming from the backend, any javascript runtime error, ...
       *
       * In some other platforms such as PASI/PASA, any error throw during the
       * bootApp it's handled with a different set of side-effects.
       *
       * After making the SSO for web public, if we end up removing the login page,
       * we'll end up with only two callers of bootApp. In such scenario we can remove,
       * this entire catch handler and define a new simpler one for each call-site.
       */
      if (isBrowserPlatform()) {
        return dispatch(logout({ showErrMsgInLogoutPageForWeb: true }));
      }

      throw error;
    }
  };
};
