import { connect } from 'react-redux';
import IosPreConsentTrackingModal from '../../components/Modal/IosPreConsentTrackingModal';
import { BaseThunk } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import { requestTrackingConsentForIosDevices } from '../../lib/wrappers/adjust';
import { track, Events } from '../../helpers/analytics';
import { getCurrentScenario } from '../../selectors';

const mapDispatchToProps = dispatch => ({
  onClickAllow: () => {
    dispatch(handleClickButton('accept'));
  },
});

export const handleClickButton =
  (action: 'accept' | 'deny'): BaseThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const scenario = getCurrentScenario(getState());

    track(Events.ClickedIosPreConsentTrackingFormButton, {
      option: action,
      scenario,
    });

    const userHasGivenConsentToTrack =
      await requestTrackingConsentForIosDevices();
    track(Events.ClickedIosNativeConsentTrackingFormButton, {
      option: userHasGivenConsentToTrack ? 'accept' : 'deny',
      scenario,
    });

    dispatch(goBackIfHistory());
  };

export default connect(null, mapDispatchToProps)(IosPreConsentTrackingModal);
