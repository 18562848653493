import * as React from 'react';
import {
  FlexLayout,
  FreeText,
  Icon,
  IconColor,
  IconFamily,
} from 'styleguide-react';

interface TimelineStateProps {
  iconType: string;
  text: string;
  title?: string;
  iconFamily?: string;
}

const TimelineState: React.FC<TimelineStateProps> = ({
  iconType,
  text,
  title,
  iconFamily = IconFamily.regular,
}) => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      textAlignement="center"
      className="par-time-line-state__wrapper"
    >
      <Icon
        type={iconType}
        size="x2"
        color={IconColor.grayLight}
        family={iconFamily}
        testId="timeline-state-icon"
      />
      {title && (
        <FreeText fontSize="semi-bold" testId="timeline-state-title">
          {title}
        </FreeText>
      )}
      <FreeText testId="timeline-state-text">{text}</FreeText>
    </FlexLayout>
  );
};

export default TimelineState;
