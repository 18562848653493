import { connect } from 'react-redux';
import SideBar from '../components/SideBar/SideBar';
import * as qinit from '../qinit';
import { isBrowserPlatform } from '../helpers';
import {
  track,
  trackUpgradeToPremium,
  Events,
  UpgradeToPremiumRuleNames,
  trackFamilyLocatorClick,
  trackButtonClicked,
  GenericPageNames,
  ButtonNames,
} from '../helpers/analytics';
import { logout } from '../sideEffects/logout';
import {
  getAccount,
  getLicense,
  getCurrentTime,
  isSideBarActive,
  getSelectedPathTitle,
  isFamilyLocatorAccentActive,
  getSideBarMode,
  isCurrentProductDedicatedSupport,
} from '../selectors';
import { shouldRedirectToOnBoarding } from '../selectors/app';
import ft, { DISABLE_DEVICE_MANAGEMENT } from '../lib/ft';
import { LINK_FAMILY_LOCATOR } from '../constants';
import { isPremium } from '../records/license';
import { sideBarStateChanged } from '../ducks/ui';
import { setFamilyLocatorAsVisitedIfNeeded } from '../ducks/account';
import { getFeatureFlag } from '../selectors/featureFlags';
import { shouldShowGift } from '../ducks/gifts/giftsSelectors';
import { simpleTemplate } from '../helpers/string';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { navigateToWellbeing } from '../actions/Navigation';
import {
  isWellbeingFeatureEnabled,
  shouldDisplayWellbeingNewTag,
} from '../businessLogic/wellbeing/wellbeing';
import { isSchoolAccount } from '../businessLogic/account';
import { startManualTrial } from '../actions/StartTrialActions';

const mapStateToProps = (
  state,
  { alwaysVisible }: { alwaysVisible?: boolean }
) => {
  return {
    selectedPathTitle: getSelectedPathTitle(state),
    account: getAccount(state),
    license: getLicense(state),
    currentTime: getCurrentTime(state),
    disableDeviceManagement:
      ft.active(DISABLE_DEVICE_MANAGEMENT) || shouldRedirectToOnBoarding(state),
    active:
      alwaysVisible !== undefined ? alwaysVisible : isSideBarActive(state),
    isEnabledFamilyLocator: getFeatureFlag(state, 'familyLocator'),
    isEnabledWellbeing: isWellbeingFeatureEnabled(state),
    shouldDisplayWellbeingNewTag: shouldDisplayWellbeingNewTag(),
    showFamilyLocatorAccent: isFamilyLocatorAccentActive(state),
    showGift: shouldShowGift(state),
    disableFamilyManagement: shouldRedirectToOnBoarding(state),
    disableAccountManagement: shouldRedirectToOnBoarding(state),
    disableLicenseBadge: shouldRedirectToOnBoarding(state),
    sideBarMode: getSideBarMode(state),
    licenseIsWithDedicatedSupport: isCurrentProductDedicatedSupport(state),
    isAccountLinkedToSchool: isSchoolAccount(state),
  };
};

const mapDispatchToProps = dispatch => {
  const closeSidebar = () => {
    dispatch(sideBarStateChanged(false));
  };
  const navigate = getMultiPlatformNavigation();

  return {
    onHelpCenterClick: e => {
      e.preventDefault();
      e.stopPropagation();
      track(Events.ClickedHelpCenter);
      dispatch(
        navigate({
          type: 'external',
          src: qinit.tenant.marketing.public_site.general_help_link,
        })
      );
      closeSidebar();
    },
    onAccountClick: () => {
      track(Events.ClickedYourAcount);
      closeSidebar();
    },
    onDevicesClick: () => {
      track(Events.ClickedYourDevices);
      closeSidebar();
    },
    onFamilyLocatorClick: () => {
      trackFamilyLocatorClick();
      dispatch(setFamilyLocatorAsVisitedIfNeeded());
      dispatch(
        navigate({
          type: 'inner',
          src: LINK_FAMILY_LOCATOR,
        })
      );
      closeSidebar();
    },
    onWellbeingClick: () => {
      trackButtonClicked(GenericPageNames.LeftMenu, ButtonNames.Wellbeing);
      dispatch(navigateToWellbeing());
      closeSidebar();
    },
    onLogoutClick: () => {
      track(Events.ClickedLogout);
      return isBrowserPlatform()
        ? dispatch(logout())
        : dispatch(
            navigate({
              type: 'inner',
              src: '/logout/modal/LogoutModal',
            })
          );
    },
    onBadgeClick: license => () => {
      trackUpgradeToPremium(
        isPremium(license.type)
          ? UpgradeToPremiumRuleNames.EXPAND_PLAN
          : UpgradeToPremiumRuleNames.UPGRADE_PLAN
      );
      closeSidebar();
    },
    onClickTryNow: () => {
      dispatch(navigate({ src: '/schools/choose-a-profile', type: 'inner' }));
      closeSidebar();
    },
    onClickStartTrial: () => {
      dispatch(startManualTrial());
      closeSidebar();
    },
    onClose: () => dispatch(sideBarStateChanged(false)),
    onClickGift: () => {
      dispatch(navigate({ type: 'inner', src: '/trial/modal/ReTrialModal' }));
      closeSidebar();
    },
    onCarePlusContactClick: (locale: string) =>
      dispatch(
        navigate({
          type: 'external',
          src: simpleTemplate(
            qinit.tenant.marketing.public_site.care_plus_contact,
            {
              lang: locale,
            }
          ),
        })
      ),
  };
};

const SideBarContainer = connect(mapStateToProps, mapDispatchToProps)(SideBar);

export default SideBarContainer;
