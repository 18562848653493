import { connect } from 'react-redux';
import { logStatsNotificationEvent } from '../../actions/ProfileActions';
import ProfileNotificationTopBar from '../../components/ProfileNotificationTopBar';
import { doAutologinToPortal } from '../../ducks/autologin';
import {
  getAccountCurrentTime,
  getKidDevices,
  getKidTamperedDevices,
  getLicense,
  getProfile,
  getProfileCount,
  getProfileOrDefault,
  getTimezone,
} from '../../selectors';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../../store/state';
import type { LicenseRecord } from '../../records/license';
import {
  getLicenseDaysLeft,
  LicenseType,
  profileLimitExceeded,
} from '../../records/license';
import { profileHasBeenInSafeNetwork } from '../../selectors/safeNetwork';
import {
  getProfileSafeNetworkSettings,
  shouldSeeCampaign,
} from '../../selectors/safeNetworksSettings';
import { pushRelativeLocation } from '../../actions/Navigation';
import { ComponentProps } from 'react';
import { dismissCampaign } from '../../ducks/safeNetworksSettings';
import { getProfileNotificationTopBarLoaded } from '../../ducks/profileNotificationTopBar/selector';
import {
  profileNotificationTopBarLoadedState,
  profileNotificationTopBarLoading,
} from '../../ducks/profileNotificationTopBar';
import { shouldLoadData } from '../../businessLogic/profileNotificationTopBar/profileNotificationTopBar';
import { topBarProfileNotificationStates } from '../../components/ProfileNotificationTopBar/types';
import flags from '../../sideEffects/flags';
import {
  isDelegationEnabled,
  isStudentOptInForDelegation,
} from '../../selectors/studentPolicies';
import {
  safeGetSummaryAlertCounter,
  summaryAlertsMapDispatchToProps,
  summaryAlertsMapStateToProps,
} from './summaryAlertsThunks';
import { requestSummaryAlertsAction } from '../../ducks/summaryAlerts';
import { requestFeaturesAction } from '../../ducks/features';
import { goToEditProfileRules } from '../../actions/NowCardActions';

const reportToAnalyticsThunk =
  (topBarState: topBarProfileNotificationStates) =>
  (dispatch: Dispatch, getState: () => State) => {
    if (topBarState === 'summaryAlerts') {
      const state = getState();
      const count = safeGetSummaryAlertCounter(state);
      return dispatch(
        profileNotificationTopBarLoadedState({
          type: 'searches',
          alertsCount: count,
        })
      );
    }
    return dispatch(profileNotificationTopBarLoadedState(topBarState));
  };

const getConversionLink = (license: LicenseRecord): string =>
  license.type === LicenseType.Premium ? '/renew' : '/upgrade';

const mapStateToProps = (
  state: State,
  props: { params: RouterParams }
): Partial<ComponentProps<typeof ProfileNotificationTopBar>> => {
  const { profileId } = props.params;
  const _profileId = Number(profileId);
  const license = getLicense(state);
  const currentTime = getAccountCurrentTime(state);
  const timezone = getTimezone(state);
  const profileCount = getProfileCount(state);
  const profile = getProfileOrDefault(state, _profileId);
  const hasBeenInSafeNetwork = profileHasBeenInSafeNetwork(state, _profileId);
  const isEligibleForOptInCampaign = shouldSeeCampaign(state, profile.uid);
  const isDelegationEnabledForTheProfile =
    flags.useDelegation.isEnabled() &&
    profile.isLinewizestudentLinked &&
    isDelegationEnabled(state);
  const isDelegated =
    flags.useDelegation.isEnabled() && isStudentOptInForDelegation(state);
  return {
    isLoading: getProfileNotificationTopBarLoaded(state),
    tamperedDevices: getKidTamperedDevices(state, _profileId),
    devices: getKidDevices(state, _profileId),
    profile,
    license,
    timezone,
    daysToExpire: getLicenseDaysLeft(license, currentTime, timezone),
    conversionLink: getConversionLink(license),
    isProfileLimitExceeded: profileLimitExceeded(license, profileCount),
    profileCount,
    profileName: profile.name,
    hasBeenInSafeNetwork,
    isEligibleForOptInCampaign,
    currentTime,
    isDelegationEnabledForTheProfile,
    isDelegated,
    ...summaryAlertsMapStateToProps(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
): Partial<ComponentProps<typeof ProfileNotificationTopBar>> => {
  return {
    onClickStatsNotification: () => {
      dispatch(logStatsNotificationEvent('click-bar'));
      dispatch(doAutologinToPortal(`/user-activity/logbook/user/${profileId}`));
    },
    onClickAllow: () => {
      dispatch(pushRelativeLocation('modal/SafeNetworkAllowModal'));
    },
    onClickDelegation: () => {
      dispatch(goToEditProfileRules(Number(profileId)));
    },
    onClickClose: () => {
      dispatch((dispatch, getState) => {
        const profile = getProfile(getState(), profileId);
        dispatch(dismissCampaign(profile.uid));
      });
    },
    onLoad: () => {
      dispatch((dispatch, getState) => {
        const state = getState();
        const profile = getProfile(state, profileId);
        const devices = getKidDevices(state, profileId);
        const checkSafeNetworks = profileHasBeenInSafeNetwork(
          state,
          Number(profileId)
        );
        const safeNetworkSettings = getProfileSafeNetworkSettings(
          state,
          profile.uid
        );
        if (
          shouldLoadData(
            profile,
            devices,
            safeNetworkSettings,
            checkSafeNetworks
          )
        ) {
          dispatch(profileNotificationTopBarLoading());
        }

        // Always check for new alerts upon entering the profile.
        dispatch(requestSummaryAlertsAction());
        dispatch(requestFeaturesAction());
      });
    },
    reportToAnalytics: (topBarState: topBarProfileNotificationStates) => {
      dispatch(reportToAnalyticsThunk(topBarState));
    },
    ...summaryAlertsMapDispatchToProps(dispatch),
  };
};
const ProfileNotificationTopBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileNotificationTopBar);

export type PlaygroundProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default ProfileNotificationTopBarContainer;
