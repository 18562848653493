import * as React from 'react';
import {
  ActionInput,
  Dropdown,
  DropdownOption,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Layout,
} from 'styleguide-react';
import { LinewizeEventFilter } from '../../../ducks/linewizeEvent';
import { t } from '../../../lib/i18n';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';

export interface DropdownFilterProps {
  filterValue?: LinewizeEventFilter;
  onchangeFilter: (filter: LinewizeEventFilter) => void;
}

const getText = (filter: LinewizeEventFilter) => {
  if (filter === 'videos') return t('Videos');
  if (filter === 'web_apps') return t('Websites & apps');
  if (filter === 'searches') return t('Searches');
  if (filter === 'blocked') return t('Blocked');
  return t('All activity');
};

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  filterValue = 'all',
  onchangeFilter,
}) => {
  const [filter, setFilter] = React.useState<LinewizeEventFilter>(filterValue);
  return (
    <RenderWhen
      screenSize={[ScreenSize.Desktop, ScreenSize.Tablet, ScreenSize.Mobile]}
    >
      {screen => (
        <FlexLayout
          mainaxis="column"
          crossaxisAlignment={
            screen === ScreenSize.Desktop ? 'start' : 'center'
          }
        >
          <Layout minWidth="240">
            <Dropdown
              onChange={filter => {
                setFilter(filter);
                onchangeFilter(filter);
              }}
              portalId="portal-dropdown"
              actionElement={
                <ActionInput
                  block
                  iconRight={
                    <Icon
                      type={IconType.angleDown}
                      color={IconColor.black}
                      size={IconSize.lg}
                    />
                  }
                  iconLeft={
                    <Icon
                      type="far fa-filter"
                      color={IconColor.secondary}
                      size={IconSize.lg}
                    />
                  }
                  text={getText(filter)}
                />
              }
              testId="school-timeline-filter"
            >
              <DropdownOption text={getText('all')} value="all" selected />
              <DropdownOption text={getText('videos')} value="videos" />
              <DropdownOption text={getText('web_apps')} value="web_apps" />
              <DropdownOption text={getText('searches')} value="searches" />
              <DropdownOption text={getText('blocked')} value="blocked" />
            </Dropdown>
          </Layout>
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

export default DropdownFilter;
