import * as React from 'react';
import { t } from '../../lib/i18n';
import SchoolsOnboardingErrorContent, {
  SchoolsOnboardingErrorContentDispatchProps,
} from './SchoolsOnboardingErrorContent';

const SchoolsOnboardingError: React.FunctionComponent<
  SchoolsOnboardingErrorContentDispatchProps
> = ({ onClickContactUs }: SchoolsOnboardingErrorContentDispatchProps) => (
  <SchoolsOnboardingErrorContent
    title={t('Something went wrong')}
    subtitle={t('Please try again later or contact us.')}
    onClickContactUs={onClickContactUs}
  />
);

export default SchoolsOnboardingError;
