import * as React from 'react';
import { Typography } from 'styleguide-react';

const UnifiedNowCardSubtitle = ({ subtitle }) => {
  return (
    <Typography type="body1" className="par-unified-now-card__subtitle">
      {subtitle}
    </Typography>
  );
};

export default UnifiedNowCardSubtitle;
