import { applyMappings } from './applier';
import { Mappings } from './types/Mapping.types';

const booleanMappings: Mappings<boolean> = [
  {
    isApplicable: value => value === 'true' || value === 'True',
    getValue: () => true,
  },
  {
    isApplicable: value => value === 'false' || value === 'False',
    getValue: () => false,
  },
];

/**
 * Try to transform the string values of the given object to boolean, and if is
 * not possible, return the value as it is.
 *
 * @example
 * ```
 * applyBooleanMappings({ a: 'true', b: 'false', c: 'test' });
 * ```
 * returns
 * ```
 * { a: true, b: false, c: 'test' }
 * ```
 */
export const applyBooleanMappings = applyMappings<boolean>(booleanMappings);
