import * as R from 'ramda';
import { PushPluginConfig } from '../../constants';
import {
  decodeDeepLink,
  gaCampaignFromUrl,
  gaEvent,
  isAndroidPlatform,
  isiOSPlatform,
} from '../../helpers';
import { APPThunk } from '../../helpers/thunks';
import {
  getPushRegistrationId,
  hasPushPermission,
  isInitialized,
  isInitializing,
} from '../../selectors/pushService';
import {
  INITIALIZE_PUSH_SERVICE,
  errorPushService,
  initializedPushService,
  receiveNotificationPushService,
  receivePushPermission,
  receivePushRegistrationId,
} from './actions';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import {
  fetchNotification,
  setModal,
  setNotificationDictionary,
  toModal,
} from '../notifications';
import { setItem } from '../persistentStorage';
import {
  parentDeviceExists,
  updateParentDevicePushPermission,
  updateParentDeviceRegistrationId,
} from '../parentDevice';
import { getPersistentStorageItem } from '../../selectors';
import * as qinit from '../../qinit';
import { normalizePushNotificationPayload } from './helpers';
import {
  FirebasePluginNotificationPayload,
  PushPluginNotificationData,
  PushPluginNotificationPayload,
} from './types/PushPayload';

export function initializePushService(): APPThunk {
  return (dispatch, getState) => {
    if (isInitializing(getState()) || isInitialized(getState())) {
      return Promise.resolve();
    }
    dispatch({ type: INITIALIZE_PUSH_SERVICE });
    if (isAndroidPlatform()) {
      dispatch(initializePushServiceAndroid());
    }
    if (isiOSPlatform()) {
      dispatch(initializePushServiceiOS());
    }
    return Promise.resolve();
  };
}

export const initializePushServiceAndroid = (): APPThunk => dispatch => {
  // This will create the default channel to receive push notification on Qustodio app
  // To make changes to the default channel's settings, create a channel with the id "PushPluginChannel"
  // before calling the PushNotification.init function.
  // It's safe to call this many times
  // Android standalone uses a most recent version of the plugin, so we have to do things in this way
  window.PushNotification.createChannel(null, null, {
    id: PushPluginConfig.CHANNEL_ID,
    description: PushPluginConfig.CHANNEL_DESCRIPTION,
    importance: PushPluginConfig.CHANNEL_IMPORTANCE,
    visibility: PushPluginConfig.CHANNEL_VISIBILITY,
    vibration: true,
  });

  const push = window.PushNotification.init({
    android: { icon: PushPluginConfig.NOTIFICATION_ICON },
  });
  window.push = push;

  push.on('error', e => dispatch(errorPushService(e)));
  // TODO: pushPlatform variable doesn't exist on the plugin itself, it exist on QAN which makes an overwrite
  //  to PushPlugin.java, pushPlatform on the latest version of the plugin is called registrationType, so new plugin
  //  is currently returning undefined for pushPlatform
  push.on(
    'registration',
    ({ registrationId, pushPlatform, registrationType }) =>
      Promise.resolve(registrationId)
        .then(registrationId =>
          dispatch(
            updatePushRegistrationId(
              registrationId,
              R.unless(
                R.flip(R.contains)(['FCM', 'GCM', 'ADM']),
                invalidPushPlatform => {
                  throw new Error(
                    `Invalid push platform '${invalidPushPlatform}'`
                  );
                }
              )(
                pushPlatform ||
                  registrationType ||
                  qinit.push_api_platform.toUpperCase()
              )
            )
          )
        )
        .then(() => dispatch(initializedPushService()))
  );

  // It is used to listen for Android push notifications.
  push.on('notification', (data: PushPluginNotificationPayload) =>
    dispatch(receivePushNotification(normalizePushNotificationPayload(data)))
  );
  return Promise.resolve();
};

export const initializePushServiceiOS = (): APPThunk => dispatch => {
  window.FirebasePlugin.onApnsTokenReceived(
    async (apnsToken: string) => {
      await dispatch(updatePushRegistrationId(apnsToken, 'PARENTS_ONLY_APNS'));
      dispatch(initializedPushService());
    },
    (e: Error) => {
      dispatch(errorPushService(e));
    }
  );

  // It is used to listen for IOS push notifications.
  window.FirebasePlugin.onMessageReceived(
    (message: FirebasePluginNotificationPayload) => {
      dispatch(
        receivePushNotification(normalizePushNotificationPayload(message))
      );
    },
    (e: Error) => {
      dispatch(errorPushService(e));
    }
  );
};

export const updatePushRegistrationId =
  (registrationId, pushPlatform): APPThunk =>
  dispatch =>
    Promise.resolve()
      .then(() =>
        dispatch(receivePushRegistrationId(registrationId, pushPlatform))
      )
      .then(() => dispatch(updateParentDeviceRegistrationIdIfNeeded()))
      .then(() => dispatch(updatePushPermission()));

export const updatePushPermission = (): APPThunk => dispatch =>
  new Promise((resolve, reject) => {
    if (isAndroidPlatform()) {
      window.PushNotification.hasPermission(({ isEnabled }) => {
        Promise.resolve()
          .then(() => dispatch(receivePushPermission(isEnabled)))
          .then(() => dispatch(updateParentDevicePushPermissionIfNeeded()))
          .then(resolve)
          .catch(reject);
      });
    }

    if (isiOSPlatform()) {
      window.FirebasePlugin.hasPermission(hasPermission => {
        Promise.resolve()
          .then(() => dispatch(receivePushPermission(hasPermission)))
          .then(() => dispatch(updateParentDevicePushPermissionIfNeeded()))
          .then(resolve)
          .catch(reject);
      });
    }
  });

export const updateParentDevicePushPermissionIfNeeded =
  (): APPThunk => (dispatch, getState) => {
    const pushPermission = hasPushPermission(getState());
    const lastPushPermission = getPersistentStorageItem(
      getState(),
      'lastPushPermission'
    );
    if (
      parentDeviceExists(getState()) &&
      pushPermission.toString() !== lastPushPermission
    ) {
      return Promise.resolve()
        .then(() => dispatch(updateParentDevicePushPermission()))
        .then(() =>
          dispatch(setItem('lastPushPermission', pushPermission.toString()))
        );
    }
    return Promise.resolve();
  };

export const updateParentDeviceRegistrationIdIfNeeded =
  (): APPThunk => (dispatch, getState) => {
    const registrationId = getPushRegistrationId(getState());
    const lastRegistrationId = getPersistentStorageItem(
      getState(),
      'lastRegistrationId'
    );
    if (
      parentDeviceExists(getState()) &&
      registrationId !== lastRegistrationId
    ) {
      return Promise.resolve()
        .then(() => dispatch(updateParentDeviceRegistrationId()))
        .then(() =>
          dispatch(setItem('lastRegistrationId', registrationId.toString()))
        );
    }
    return Promise.resolve();
  };

export const receivePushNotification =
  (data: PushPluginNotificationData): APPThunk =>
  // A push notification can be receive when:
  // - The app is in foreground, but not showing the notification in system's notifications tray.
  //   Then the values for additionalData are { "foreground": true, "coldstart": false }
  // - The user opens a system's notification, the app receives that notification
  //   If app was closed, the received values for additionalData are:
  //     { "foreground": false, "coldstart": true }
  //   Else:
  //     { "foreground": false, "coldstart": false }
  //
  // `data` format:
  //
  //   {
  //     "additionalData": {
  //       "customField": "whatever",
  //       "foreground": false,
  //       "coldstart": true
  //     },
  //     "message": "Testing.. (8)",
  //     "count": 1,
  //     "sound": "default"
  //   }
  dispatch => {
    const navigate = getMultiPlatformNavigation();
    dispatch(receiveNotificationPushService(data));

    if (data.additionalData && typeof data.additionalData === 'object') {
      const payload = data.additionalData;

      if (!payload.foreground) {
        if (payload.deep_link) {
          gaCampaignFromUrl(payload.deep_link);
          const path = decodeDeepLink(payload.deep_link);
          if (!path) {
            throw new Error(
              `Received "deep_link" but did not match URL scheme: "${payload.deep_link}"`
            );
          }
          dispatch(navigate({ type: 'inner', src: path }));
        }
        if (payload.notification_template) {
          dispatch(
            navigate({ type: 'inner', src: '/modal/CustomNotification' })
          );
          dispatch(setModal(toModal(null)(payload.notification_template)));
        } else if (payload.notification_key) {
          dispatch(
            navigate({ type: 'inner', src: '/modal/CustomNotification' })
          );
          dispatch(setNotificationDictionary(payload.notification_dict));
          dispatch(fetchNotification(payload.notification_key));
        }
        gaEvent('push-notification', 'text-alert', 'accept');
      }
    }
  };
