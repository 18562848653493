import * as React from 'react';
import {
  Card,
  ContentSeparator,
  FlexLayout,
  GlobalType,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
  Layout,
  Switch,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import RestrictedTimesGrid from './RestrictedTimesGrid';
import DeviceSelectionBar from '../../containers/DeviceSelectionBarContainer';
import { Weekday } from '../../helpers/dates';
import { DeviceRecord } from '../../records';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import TimeSettingsContainer from '../../containers/ProfileRules/TimeLimits/TimeSettingsContainer';
import PageLayoutSection from '../Layout/PageSectionLayout';
import TimeNotificationsContainer from '../../containers/ProfileRules/TimeLimits/TimeNotificationsContainer';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';

interface RestrictedTimesProps {
  profileId: string;
  deviceId: string;
  timeSettings: any;
  isMorningCollapsed: boolean;
  isAfternoonCollapsed: boolean;
  isNightCollapsed: boolean;
  onToggle: (toggleOn: boolean) => any;
  onClickSettings: () => any;
  onToggleDayTime: (daytime: any, isCollapsed: boolean) => any;
  onCellTouchEnd: (daytime: any, weekday: Weekday, hour: number) => any;
  onChangeDevice: (device: DeviceRecord) => any;
}

const RestrictedTimes = (restrictedTimesProps: RestrictedTimesProps) => {
  return (
    <PageLayout
      title={t('Restricted Times')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
        {
          screenSize: ScreenSize.Tablet,
          mainInsideCard: true,
          columns: 2,
        },
      ]}
      actions={[
        { actionName: 'back', screenSize: ScreenSize.MobileOrTablet },
        {
          actionName: 'settings',
          screenSize: ScreenSize.Mobile,
          icon: (
            <Icon
              type={IconType.settings}
              size={IconSize.lg}
              color={IconColor.primary}
            />
          ),
          onClick: restrictedTimesProps.onClickSettings,
        },
      ]}
    >
      <PageLayoutSection side="main">
        <div className="RestrictedTimes__content">
          <Grid {...restrictedTimesProps} />
        </div>
      </PageLayoutSection>
      <PageLayoutSection side="right">
        <FlexLayout mainaxis="column">
          <Layout marginBottom="32">
            <Card
              type={GlobalType.white}
              header={{
                title: t('Settings'),
                border: true,
              }}
              className={classNames(
                DesktopLayoutScssUtils.CardRight,
                TabletLayoutScssUtils.CardRight
              )}
            >
              <TimeSettingsContainer
                params={{
                  profileId: restrictedTimesProps.profileId,
                  deviceId: restrictedTimesProps.deviceId,
                }}
              />
            </Card>
          </Layout>
          <Layout marginBottom="32">
            <Card
              type={GlobalType.white}
              header={{
                title: t('Notifications'),
                border: true,
              }}
              className={classNames(
                DesktopLayoutScssUtils.CardRight,
                TabletLayoutScssUtils.CardRight
              )}
            >
              <TimeNotificationsContainer
                params={{
                  profileId: restrictedTimesProps.profileId,
                  deviceId: restrictedTimesProps.deviceId,
                }}
              />
            </Card>
          </Layout>
        </FlexLayout>
      </PageLayoutSection>
    </PageLayout>
  );
};
const Grid: React.FunctionComponent<{
  profileId: string;
  deviceId: string;
  timeSettings: any;
  isMorningCollapsed: boolean;
  isAfternoonCollapsed: boolean;
  isNightCollapsed: boolean;
  onToggle: (toggleOn: boolean) => any;
  onToggleDayTime: (daytime: any, isCollapsed: boolean) => any;
  onCellTouchEnd: (daytime: any, weekday: Weekday, hour: number) => any;
  onChangeDevice: (device: DeviceRecord) => any;
}> = ({
  profileId,
  deviceId,
  timeSettings,
  isMorningCollapsed,
  isAfternoonCollapsed,
  isNightCollapsed,
  onToggle,
  onToggleDayTime,
  onCellTouchEnd,
  onChangeDevice,
}) => (
  <FlexLayout mainaxis="column">
    <DeviceSelectionBar
      profileId={profileId}
      deviceId={deviceId}
      onChangeDevice={onChangeDevice}
    />
    <FlexLayout
      mainaxis="row"
      mainaxisAlignment="space-between"
      crossaxisAlignment="center"
      flexGrow="0"
      paddingBottom="8"
    >
      <Label htmlFor="SwitchIsDailyLimit">
        {t('Enable time restrictions')}
      </Label>
      <Switch
        id="SwitchIsDailyLimit"
        testId="SwitchIsDailyLimit"
        checked={timeSettings.isDailyLimit}
        onClick={ev => onToggle((ev.target as HTMLInputElement).checked)}
      />
    </FlexLayout>

    <ContentSeparator />

    <FlexLayout mainaxis="column">
      <RestrictedTimesGrid
        isDisabled={!timeSettings.isDailyLimit}
        onToggleDayTime={onToggleDayTime}
        isMorningCollapsed={isMorningCollapsed}
        isAfternoonCollapsed={isAfternoonCollapsed}
        isNightCollapsed={isNightCollapsed}
        timeRanges={timeSettings.timeRanges}
        onCellTouchEnd={onCellTouchEnd}
      />
    </FlexLayout>
  </FlexLayout>
);
export default RestrictedTimes;
