import { connect } from 'react-redux';
import State, { Dispatch } from '../../../store/state';
import { getQueryParam } from '../../../selectors';
import {
  RoutineMode,
  RoutineSteps,
} from '../../../components/Routines/routines.types';
import LeaveWithoutSavingModal from '../../../components/Routines/Modals/LeaveWithoutSavingModal';
import { closeDrawerAndResetMultiStep } from '../CreateRoutineMultiStepContainer';
import { navigateToRoutines } from '../../../actions/RoutinesActions';

const mapDispatchToProps = (dispatch: Dispatch, { params: { profileId } }) => {
  const back = () => dispatch(navigateToRoutines(profileId));
  return {
    onClickLeave: () => {
      dispatch(closeDrawerAndResetMultiStep());
      back();
    },
    onClickClose: back,
  };
};

const mapStateToProps = (state: State) => {
  const mode = getQueryParam(state, 'mode') as RoutineMode;
  const step = getQueryParam(state, 'step') as RoutineSteps;
  return { mode, step };
};

const LeaveWithoutSavingModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveWithoutSavingModal);

export type LeaveWithoutSavingModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default LeaveWithoutSavingModalContainer;
