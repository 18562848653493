import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import LocationRulesPremiumFlyover from '../../../components/LocationRules/LocationRulesPremiumFlyover';
import { getAccountLocale } from '../../../selectors';
import {
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
  trackUpgradeBlockedFeature,
} from '../../../helpers/analytics';
import { navigateToUpgrade } from '../../../actions/Navigation';
import State, { Dispatch } from '../../../store/state';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickUpgrade: () => {
    trackUpgradeBlockedFeature(
      GenericPageNames.LocationRules,
      FeatureBlockingContexts.FullBlockedFeature,
      ProfileRuleNames.Location
    );
    dispatch(navigateToUpgrade());
  },
});

const LocationRulesPremiumFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationRulesPremiumFlyover);

export type LocationRulesPremiumFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default LocationRulesPremiumFlyoverContainer;
