import { MINS_IN_A_DAY } from '../../helpers/dates';
import type { Decision } from '../decision/types';
import { decision, where } from '../decision';
import {
  isNotReported,
  isReported,
  containsRoutines,
  isScreenTimeGreaterThanDailyTimeLimits,
  isScreenTimeGreaterThanDailyTimeLimitsWithExtraTime,
  isForOneDay,
  isForMoreThanOneDays,
} from './predicates';

import type {
  ScreenTimeData,
  DefaultRulesScreenTime,
  ScreenTimeReport,
} from './types';

export const sumScreenTime = (report: Array<ScreenTimeReport>) =>
  report.reduce((total, item) => total + item.minutes, 0);

export const screenTimeDecision = (
  handler: Decision<ScreenTimeData, DefaultRulesScreenTime>
) => decision<ScreenTimeData, DefaultRulesScreenTime>(handler);

const whereScreenTimeIsNotReported = where<ScreenTimeData>({
  screenTime: isNotReported,
  dailyTimeLimits: isNotReported,
  extraTime: isNotReported,
});

const screenTimeIsNotReported = (): DefaultRulesScreenTime => ({
  screenTime: 0,
  availableScreenTime: MINS_IN_A_DAY,
  remainingScreenTime: MINS_IN_A_DAY,
});

const whereOnlyScreenTimeIsReportedAndDailyTimeLimitBlocked =
  where<ScreenTimeData>({
    screenTime: isReported,
    dailyTimeLimits: isNotReported,
    extraTime: isNotReported,
  });

const whereNotScreenTimeWithDailyTimeLimitReported = where<ScreenTimeData>({
  screenTime: isNotReported,
  dailyTimeLimits: isReported,
});

const onlyScreenTimeIsReportedWith24h = ({
  screenTime,
}: ScreenTimeData): DefaultRulesScreenTime => ({
  screenTime,
  availableScreenTime: MINS_IN_A_DAY,
  remainingScreenTime: MINS_IN_A_DAY - screenTime,
});

const onlyScreenTimeIsReported = ({
  screenTime,
}: ScreenTimeData): DefaultRulesScreenTime => ({
  screenTime,
  availableScreenTime: screenTime,
  remainingScreenTime: 0,
});

const whereScreenTimeAndDailyTimeLimitsReported = where<ScreenTimeData>({
  screenTime: isReported,
  dailyTimeLimits: isReported,
  extraTime: isNotReported,
});

const whereScreenTimeAndDailyTimeLimitsAndExtraTimeReported =
  where<ScreenTimeData>({
    screenTime: isReported,
    extraTime: isReported,
    dailyTimeLimits: isReported,
  });

const whereScreenTimeAndExtraTimeReported = where<ScreenTimeData>({
  screenTime: isReported,
  extraTime: isReported,
  dailyTimeLimits: isNotReported,
});

const whereDailyTimeLimitsTimeUp = where<ScreenTimeData>({
  screenTime: isScreenTimeGreaterThanDailyTimeLimits,
});

const whereDailyTimeLimitsWithExtraTimeTimeUp = where<ScreenTimeData>({
  screenTime: isScreenTimeGreaterThanDailyTimeLimitsWithExtraTime,
});

const screenTimeTimeUp = ({ screenTime }) => ({
  screenTime,
  availableScreenTime: 0,
  remainingScreenTime: 0,
});

const calculateDefaultRulesScreenTime = screenTimeDecision({
  cond: where({
    reportedDays: isForMoreThanOneDays,
  }),
  yes: ({ screenTime }) => ({
    screenTime,
    availableScreenTime: screenTime,
    remainingScreenTime: 0,
  }),
  no: screenTimeDecision({
    cond: where({
      extraTime: isReported,
      dailyTimeLimits: isNotReported,
      screenTime: isNotReported,
    }),
    yes: ({ screenTime }) => ({
      availableScreenTime: MINS_IN_A_DAY,
      remainingScreenTime: MINS_IN_A_DAY,
      screenTime,
    }),
    no: screenTimeDecision({
      cond: where({
        extraTime: isNotReported,
        dailyTimeLimits: isReported,
        screenTime: isNotReported,
      }),
      yes: ({ dailyTimeLimits, screenTime }) => ({
        availableScreenTime: dailyTimeLimits,
        remainingScreenTime: dailyTimeLimits,
        screenTime,
      }),
      no: screenTimeDecision({
        cond: where({
          screenTime: isReported,
          dailyTimeLimits: isNotReported,
          extraTime: isReported,
        }),
        yes: ({ screenTime }) => ({
          availableScreenTime: MINS_IN_A_DAY,
          remainingScreenTime: MINS_IN_A_DAY - screenTime,
          screenTime,
        }),
        no: screenTimeDecision({
          cond: where({
            extraTime: isReported,
            dailyTimeLimits: isReported,
            screenTime: isNotReported,
          }),
          yes: ({ dailyTimeLimits, extraTime, screenTime }) => ({
            availableScreenTime: dailyTimeLimits + extraTime,
            remainingScreenTime: dailyTimeLimits + extraTime,
            screenTime,
          }),
          no: screenTimeDecision({
            meta: {
              name: 'Default rules -> no screen time + no daily time limits + no extra time',
              description: `Use as available screen time and remaining screen time MINS_IN_A_DAY ${MINS_IN_A_DAY}`,
            },
            cond: whereScreenTimeIsNotReported,
            yes: screenTimeIsNotReported,
            no: screenTimeDecision({
              meta: {
                name: 'Default rules -> screen time + no daily time limits + no extra time',
                description: `Use as screen time the time reported, as available screen time and remaining screen time MINS_IN_A_DAY ${MINS_IN_A_DAY}`,
              },
              cond: whereOnlyScreenTimeIsReportedAndDailyTimeLimitBlocked,
              yes: onlyScreenTimeIsReportedWith24h,
              no: screenTimeDecision({
                meta: {
                  name: 'Default rules -> no screen time + daily time limits',
                  description: `Use as screen time 0, as available screen daily time limits`,
                },
                cond: whereNotScreenTimeWithDailyTimeLimitReported,
                yes: ({ dailyTimeLimits, extraTime }) => ({
                  availableScreenTime: dailyTimeLimits + extraTime,
                  screenTime: 0,
                  remainingScreenTime: dailyTimeLimits + extraTime,
                }),
                no: screenTimeDecision({
                  meta: {
                    name: 'Default rules -> screen time + daily time limits + no extra time',
                    description: `Use as screen time the time reported, as available screen the daily time limit reported an as available screen time: daily time limits - screen time`,
                  },
                  cond: whereScreenTimeAndDailyTimeLimitsReported,
                  yes: screenTimeDecision({
                    meta: {
                      name: 'default rules -> screen time + daily time limits + no extra tine',
                      description:
                        'Maybe screen time can be greater than daily time limits',
                    },
                    cond: whereDailyTimeLimitsTimeUp,
                    yes: screenTimeTimeUp,
                    no: ({ screenTime, dailyTimeLimits }) => ({
                      screenTime,
                      availableScreenTime: dailyTimeLimits,
                      remainingScreenTime: dailyTimeLimits - screenTime,
                    }),
                  }),
                  no: screenTimeDecision({
                    meta: {
                      name: 'Default rules -> screen time + daily time limits + extra time',
                      description: `Use as screen time the time reported, as available screen the daily time limit reported + extra time adn as available screen time: daily time limits + extra time - screen time`,
                    },
                    cond: whereScreenTimeAndDailyTimeLimitsAndExtraTimeReported,
                    yes: screenTimeDecision({
                      meta: {
                        name: 'Default rules -> screen time + daily time limits + extra time',
                        description: `Maybe screen time >= daily time limits + extra time`,
                      },
                      cond: whereDailyTimeLimitsWithExtraTimeTimeUp,
                      yes: screenTimeTimeUp,
                      no: ({ screenTime, dailyTimeLimits, extraTime }) => ({
                        screenTime,
                        availableScreenTime: dailyTimeLimits + extraTime,
                        remainingScreenTime:
                          dailyTimeLimits + extraTime - screenTime,
                      }),
                    }),
                    no: context => {
                      throw new Error(
                        `Invalid case for business logic Default rules screen time. Context: ${JSON.stringify(
                          context
                        )}`
                      );
                    },
                  }),
                }),
              }),
            }),
          }),
        }),
      }),
    }),
  }),
});

const calculateRoutinesScreenTime = screenTimeDecision({
  meta: {
    name: 'Routines -> reported days',
    description: 'Maybe can be 1 or > 1',
  },
  cond: where({
    reportedDays: isForOneDay,
  }),
  yes: screenTimeDecision({
    meta: {
      name: 'Routines -> no reported data',
      description: 'Screen time = 0',
    },
    cond: where({
      screenTime: isNotReported,
    }),
    yes: () => ({
      screenTime: 0,
      availableScreenTime: 0,
      remainingScreenTime: 0,
    }),
    no: screenTimeDecision({
      meta: {
        name: 'Routines -> screen time reported',
        description: `Total time = 24h`,
      },
      cond: whereOnlyScreenTimeIsReportedAndDailyTimeLimitBlocked,
      yes: onlyScreenTimeIsReported,
      no: screenTimeDecision({
        meta: {
          name: 'Routines -> screen time and daily time limits',
          description: `Total time = daily time limits`,
        },
        cond: whereScreenTimeAndDailyTimeLimitsReported,
        yes: ({ screenTime, dailyTimeLimits }) => {
          const remainingScreenTime = Math.max(0, dailyTimeLimits - screenTime);
          const availableScreenTime = screenTime + remainingScreenTime;

          return {
            screenTime,
            availableScreenTime,
            remainingScreenTime,
          };
        },
        no: screenTimeDecision({
          meta: {
            name: 'Routines -> screen time + daily time limits + extra time',
            description: `Total time = screenTime - remaining screen time + extra time`,
          },
          cond: whereScreenTimeAndDailyTimeLimitsAndExtraTimeReported,
          yes: ({ screenTime, dailyTimeLimits, extraTime }) => {
            const remainingScreenTime = Math.max(
              0,
              dailyTimeLimits + extraTime - screenTime
            );
            const availableScreenTime = screenTime + remainingScreenTime;

            return {
              screenTime,
              availableScreenTime,
              remainingScreenTime,
            };
          },
          no: screenTimeDecision({
            meta: {
              name: 'Routines -> screen time + extra time',
              description: `Total time = screenTime + remaining screen time + extra time`,
            },
            cond: whereScreenTimeAndExtraTimeReported,
            yes: ({ screenTime, extraTime }) => {
              const remainingScreenTime = Math.max(0, extraTime - screenTime);
              const availableScreenTime = screenTime + remainingScreenTime;

              return {
                screenTime,
                availableScreenTime,
                remainingScreenTime,
              };
            },
            no: () => {
              throw new Error(
                'Total screen time pie chart decision tree: Unreachable state'
              );
            },
          }),
        }),
      }),
    }),
  }),
  no: ({ screenTime }) => ({
    screenTime,
    availableScreenTime: screenTime,
    remainingScreenTime: 0,
  }),
});

export const calculateScreenTime = screenTimeDecision({
  meta: {
    name: 'Extratime for default rules or routines',
    description: 'Depends if extratime contains routines',
  },
  cond: where({
    screenTimeRoutines: containsRoutines,
  }),
  yes: calculateRoutinesScreenTime,
  no: calculateDefaultRulesScreenTime,
});
