import { connect } from 'react-redux';
import ProtectProfilesList from '../../components/Schools/ProtectProfilesList';
import {
  ButtonNames,
  GenericPageNames,
  PageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import { trackablePage } from '../../helpers/hocs';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import { getProfiles } from '../../selectors';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State) => ({
  accountProfiles: getProfiles(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const navigate = getMultiPlatformNavigation();

  return {
    onClickProtectProfile: (profileId: number) => {
      trackButtonClicked(
        GenericPageNames.ChooseAProfile,
        ButtonNames.SelectExistingProfile,
        { profile_id: profileId }
      );
      dispatch(
        navigate({ type: 'inner', src: `/profiles/${profileId}/add-device` })
      );
    },
    onClickAddNewChild: () => {
      trackButtonClicked(
        GenericPageNames.ChooseAProfile,
        ButtonNames.AddNewChild
      );
      dispatch(
        navigate({
          type: 'inner',
          src: '/profiles/add',
        })
      );
    },
  };
};

const ProtectProfilesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(ProtectProfilesList, PageNames.ChooseAProfile));

export type ProtectProfilesListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default ProtectProfilesListContainer;
