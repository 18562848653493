import * as React from 'react';
import { t } from '../../lib/i18n';
import FullPremiumFlyover from '../FullPremiumFlyover/FullPremiumFlyover';
import { IconType } from 'styleguide-react';
import { ISOLanguage } from '../../constants';
import { PremiumFlyoverProps } from '../FullPremiumFlyover/types/FullPremiumFlyoverProps.types';

interface PanicButtonRulesFlyoverProps {
  testId: string;
  accountLocale: ISOLanguage;
  actionButton: PremiumFlyoverProps['actionButton'];
  footNote?: PremiumFlyoverProps['footNote'];
  topbarText?: PremiumFlyoverProps['topbarText'];
  alertBoxProps?: PremiumFlyoverProps['alertBoxProps'];
  onClickClose: () => void;
}

const PanicButtonRulesFlyover = ({
  testId,
  accountLocale,
  footNote,
  topbarText,
  actionButton,
  alertBoxProps,
  onClickClose,
}: PanicButtonRulesFlyoverProps) => {
  return (
    <FullPremiumFlyover
      pageLayoutProps={{ title: t('Panic Button') }}
      testId={testId}
      topbarText={topbarText}
      title={t('What can you do with the panic button?')}
      imageSrc={`/illustrations/PanicButton/premium_panic-button__${accountLocale}.png`}
      actionButton={actionButton}
      onClickClose={onClickClose}
      infoItems={[
        {
          iconType: IconType.smile,
          title: t('Keep your child safe'),
          subtitle: t(
            'Make it easy for your child to alert you when they’re in trouble'
          ),
        },
        {
          iconType: IconType.userFriends,
          title: t('Add trusted contacts'),
          subtitle: t(
            'Create a list of trusted contacts for your child to notify'
          ),
        },
        {
          iconType: IconType.bell,
          title: t('Receive alerts'),
          subtitle: t(
            'Enable your child to send their location by SMS or email in seconds'
          ),
        },
      ]}
      footNote={footNote}
      alertBoxProps={alertBoxProps}
    />
  );
};

export default PanicButtonRulesFlyover;
