import { getFormValues } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import { PageNames } from '../../helpers/analytics';
import {
  getEventByKey,
  getPlaces,
  isUsingImperialSystem,
  getRadiusAddPlaceForm,
} from '../../selectors/index';
import { formRecord, formName } from '../../components/FamilyLocator/PlaceForm';
import AddPlace from '../../components/FamilyLocator/AddPlace';
import {
  createPlace,
  addressClickHandler,
} from '../../actions/FamilyLocatorActions';
import { trackablePage } from '../../helpers/hocs';
import { setKeyboardVisiblityAtPlaces } from '../../ducks/app';
import { PlaceRecord } from '../../records';
import {
  PLACE_FORM_MIN_RADIUS_VALUE_METERS,
  NO_EVENT_KEY,
} from '../../constants';
import { getBuildPlatform } from '../../helpers/globals';

export const MIN_RADIUS_VALUE_FEET = 350;
export const MAX_RADIUS_VALUE_METERS = 200;
export const MAX_RADIUS_VALUE_FEET = 650;

const isFormEmpty = formValues => {
  return formValues === undefined || formValues.address === '';
};

const getInitialValues = (state, eventKey, radius: number): PlaceRecord => {
  if (eventKey === NO_EVENT_KEY) {
    return PlaceRecord({ radius });
  }

  return PlaceRecord.fromLocationAndRadius({
    location: getEventByKey(state, eventKey).location,
    radius,
  });
};

type paramsType = {
  eventKey: string;
  mapResizingHandler: () => any;
  historyStepsBack: number;
};

const mapStateToProps = (state, { params: { eventKey = NO_EVENT_KEY } }) => {
  const formValues = getFormValues(formName)(state);
  const isUsingFeet = isUsingImperialSystem(state);
  return {
    places: getPlaces(state),
    platform: getBuildPlatform(),
    radius: getRadiusAddPlaceForm(state),
    initialValues: isFormEmpty(formValues)
      ? formRecord(
          getInitialValues(
            state,
            eventKey,
            isUsingFeet
              ? MIN_RADIUS_VALUE_FEET
              : PLACE_FORM_MIN_RADIUS_VALUE_METERS
          ).toJS()
        )
      : formRecord(formValues),
    isUsingFeet: isUsingImperialSystem,
    formatLabel: isUsingFeet ? t('f') : t('m'),
    maxValue: isUsingFeet ? MAX_RADIUS_VALUE_FEET : MAX_RADIUS_VALUE_METERS,
    minValue: isUsingFeet
      ? MIN_RADIUS_VALUE_FEET
      : PLACE_FORM_MIN_RADIUS_VALUE_METERS,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    params: { eventKey = NO_EVENT_KEY, mapResizingHandler, historyStepsBack },
  }: { params: paramsType }
) => ({
  onSaveClick: () =>
    dispatch((dispatch, getState) => {
      dispatch(
        createPlace(
          isUsingImperialSystem(getState()),
          formRecord(getFormValues(formName)(getState())).toJS(),
          historyStepsBack
        )
      );
    }),
  keyboardHideHandler: () => dispatch(setKeyboardVisiblityAtPlaces(false)),
  keyboardShowHandler: () => dispatch(setKeyboardVisiblityAtPlaces(true)),
  onAddressClick: () => dispatch(addressClickHandler(eventKey)),
  switchToShowHalfScreenCallback: () => mapResizingHandler(),
});

const AddPlaceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(AddPlace, PageNames.FamilyLocatorAddPlace));

export default AddPlaceContainer;
