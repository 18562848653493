import { commonMapInFn } from '../../api/mappings/common';
import { getRecordOperations } from '../recordHelpers/recordHelpers';
import {
  StudentDelegation,
  StudentDelegationPayload,
} from './types/studentDelegation.type';

export const studentDelegationRecordOperations = getRecordOperations<
  StudentDelegation,
  StudentDelegationPayload
>({
  defaultFields: {
    enabled: false,
    optedIn: false,
    optedInByThisAccount: false,
  },
  mapIn: payload => {
    const commonMappedResult = commonMapInFn(payload);
    return {
      ...(commonMappedResult as { delegation: StudentDelegation }).delegation,
    };
  },
});
