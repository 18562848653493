/* eslint-disable camelcase */
import { Record } from 'immutable';
import type {
  PolicyApplicationRulesPayload,
  PolicyApplicationRulesRecord,
} from './PolicyApplicationRules.types';
import type {
  PolicyWebCategoryRulesPayload,
  PolicyWebCategoryRulesRecord,
} from './PolicyWebCategoryRules.types';

export const policyBlockType = {
  POLICY_BLOCK_TYPE_NONE: 0,
  POLICY_BLOCK_TYPE_LOCK_NAVIGATION: 1,
  POLICY_BLOCK_TYPE_LOCK_DEVICE: 2,
} as const;
export type PolicyBlockType = keyof typeof policyBlockType;
export type PolicyBlockTypePayload = typeof policyBlockType[PolicyBlockType];

export const policyAction = {
  POLICY_ACTION_ALLOWED: 0,
  POLICY_ACTION_BLOCKED: 1,
} as const;
export type PolicyAction = keyof typeof policyAction;
export type PolicyActionPayload = typeof policyAction[PolicyAction];

export interface Policy {
  countsTowardsDailyTimeQuota: boolean;
  blockType: PolicyBlockType;
  appRules: PolicyApplicationRulesRecord;
  webRules: PolicyWebCategoryRulesRecord;
}

export interface PolicyPayload {
  counts_towards_daily_time_quota: boolean;
  block_type: PolicyBlockTypePayload;
  app_rules: PolicyApplicationRulesPayload;
  web_rules: PolicyWebCategoryRulesPayload;
}

export type PolicyRecord = Record<Policy>;
