import * as React from 'react';
import { Record } from 'immutable';
import { Label, Layout } from 'styleguide-react';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import { validateNewContact } from './validateNewContact';
import InputTextField from '../Form/InputTextField';

export const formName = 'callsSMSRulesAddContact';

const CallsSMSRulesAddContactForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} id={formName}>
    <Layout marginBottom="8">
      <Label>{t('Name')}</Label>
    </Layout>

    <Field
      name="name"
      component={InputTextField}
      type="text"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="off"
    />
    <Layout marginTop="8" marginBottom="8">
      <Label>{t('Phone number')}</Label>
    </Layout>
    <Field
      name="phone"
      component={InputTextField}
      type="tel"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="off"
    />

    {/* include an invisible submit-button so that the mobile-keyboard
      has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

const AddContactFormRecord = Record({
  name: '',
  phone: '',
});

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: AddContactFormRecord(),
  validate: (contact, auxiliaryData) =>
    validateNewContact(contact, auxiliaryData),
})(CallsSMSRulesAddContactForm);
