import * as React from 'react';
import { IconType, IconColor, InstructionCard, Layout } from 'styleguide-react';
import { ensureHashInRoute } from '../../helpers/routing';
import { t } from '../../lib/i18n';

export const MissingPermissionsCard: React.FunctionComponent<{
  title: string;
  description: string;
  isDetailed: boolean;
  helpTipData: { text: string; url: string };
}> = ({ title, description, isDetailed, helpTipData }) => (
  <Layout margin="16" marginTop="24">
    <InstructionCard
      title={title}
      description={description}
      instructions={helpTipData.text}
      compact={!isDetailed}
      url={ensureHashInRoute(helpTipData.url)}
      iconType={IconType.mapMarkerSlash}
      iconColor={IconColor.error}
      iconAsLink
    />
  </Layout>
);

export const LocationServicesDisabledCard: React.FunctionComponent<{
  name: string;
  currentUrl: string;
  helpTipUrl: string;
  isDetailed: boolean;
}> = ({ name, isDetailed, helpTipUrl }) => (
  <MissingPermissionsCard
    title={t('Location Services are OFF')}
    isDetailed={isDetailed}
    description={t(
      'Turn Location Services ON to be able to see where {{name}} is.',
      {
        name,
      }
    )}
    helpTipData={{
      text: t('See how to turn Location Services ON'),
      url: helpTipUrl,
    }}
  />
);

export const LocationTrackingOffCard: React.FunctionComponent<{
  name: string;
  currentUrl: string;
  helpTipUrl: string;
  isDetailed: boolean;
}> = ({ name, isDetailed, helpTipUrl }) => (
  <MissingPermissionsCard
    title={t('Tracking for {{name}} is OFF', { name })}
    isDetailed={isDetailed}
    description={t("Turn location tracking ON to see {{name}}'s location.", {
      name,
    })}
    helpTipData={{
      text: t('Turn location tracking ON'),
      url: helpTipUrl,
    }}
  />
);

export const PermissionsRevokedCard: React.FunctionComponent<{
  name: string;
  currentUrl: string;
  helpTipUrl: string;
  isDetailed: boolean;
}> = ({ name, isDetailed, helpTipUrl }) => (
  <MissingPermissionsCard
    title={t('Location permissions needed')}
    isDetailed={isDetailed}
    description={t(
      'Allow app permissions for location to see where {{name}} is.',
      {
        name,
      }
    )}
    helpTipData={{
      text: t('See how to allow permissions'),
      url: helpTipUrl,
    }}
  />
);
