import { connect } from 'react-redux';
import { t } from '../../lib/i18n';
import {
  getFirstGiftCode,
  isConsumingGift,
  giftHasBeenConsumedSuccessfully,
  shouldShowGift,
} from '../../ducks/gifts/giftsSelectors';
import { Events, PageNames, track } from '../../helpers/analytics';
import ReTrialModal from '../../components/Modal/ReTrialModal';
import {
  consumeGift,
  setGiftHasBeenConsumedSuccessfully,
} from '../../ducks/gifts/gifts';
import { goBackIfHistory } from '../../ducks/routing';
import { Dispatch } from '../../store/state';
import { trackablePage } from '../../helpers/hocs';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = state => {
  const giftConsumedSuccessfully = giftHasBeenConsumedSuccessfully(state);
  const giftCode = getFirstGiftCode(state) || '';
  return {
    giftCode,
    giftConsumedSuccessfully,
    isLoading: isConsumingGift(state),
    title: giftConsumedSuccessfully
      ? t('Welcome to your free 3 day Premium trial!')
      : t('Go Premium for 3 days, free!'),
    content: giftConsumedSuccessfully
      ? t(
          'Enjoy the best of {{shortName}} on your kid’s device. Head over to Rules to customize your protection.'
        )
      : t(
          'Monitor apps and games, track your family’s location, receive extended online activity reports and much more with our exclusive 3 day trial.'
        ),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickReTrial: (giftCode: string) => {
    dispatch(consumeGift({ code: giftCode }));
    track(Events.ClickedAcceptedReTrial, { gift: 'ad-hoc trial' });
  },
  onClickClose: (giftHasBeenConsumedSuccessfully: boolean) => {
    dispatch(goBackIfHistory());
    if (!giftHasBeenConsumedSuccessfully) {
      track(Events.ClickedDismissReTrial, { gift: 'ad-hoc trial' });
    }
  },
});

const ReTrialModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  trackablePage(ReTrialModal, PageNames.GiftModal, () => ({
    gift: 'ad-hoc trial',
  }))
);

ReTrialModalContainer.load = () => (dispatch: Dispatch, getState) => {
  if (!shouldShowGift(getState())) {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/',
      })
    );
  } else {
    dispatch(setGiftHasBeenConsumedSuccessfully(false));
  }
};

export default ReTrialModalContainer;
