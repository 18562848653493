import * as R from 'ramda';
import { t } from '../lib/i18n';
import { showToast, TOAST_ICON_TICK, TOAST_ICON_WARNING } from '../ducks/toast';
import { getDevice, getProfileOrDefault } from '../selectors';
import { PLATFORM_ANDROID, PLATFORM_CHROME, PLATFORM_IOS } from '../constants';
import { getUserByUid } from '../records/device';
import {
  updateUserDeviceProfile,
  modifyDevice,
  requestUpdateAssignedProfileError,
  receiveUpdateAssignedProfile,
  requestUpdateAssignedProfile,
} from '../ducks/devices';
import { fetchProfiles } from '../ducks/profiles';
import type { DeviceRecord } from '../records/device/types/Device.types';

export const assignProfileToDevice =
  (deviceId, deviceUserId, profileId) => (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);
    const profile = profileId
      ? getProfileOrDefault(getState(), profileId)
      : null;
    if (profile) {
      R.cond([
        [
          R.isNil,
          () =>
            R.pipe(assignProfileToAllDeviceUsers, dispatch)(device, profile),
        ],
        [
          R.T,
          () =>
            dispatch(assignProfileToDeviceUser(device, deviceUserId, profile)),
        ],
      ])(deviceUserId);
    } else {
      dispatch(unAssignProfileFromDevice(deviceId, deviceUserId));
    }
  };

export const unAssignProfileFromDevice =
  (deviceId, deviceUserId) => (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);
    if (device) {
      if (deviceUserId) {
        dispatch(unAssignProfileFromDeviceUser(device, deviceUserId));
      } else {
        dispatch(unAssignProfileFromAllDeviceUsers(device));
      }
    }
  };

const assignProfileToDeviceUser =
  (device, deviceUserId, profile) => dispatch => {
    dispatch(requestUpdateAssignedProfile());
    dispatch(updateUserDeviceProfile(device, deviceUserId, profile.id))
      .then(() => dispatch(fetchProfiles()))
      .then(() => {
        const toastMessage =
          device.platform === PLATFORM_IOS ||
          device.platform === PLATFORM_ANDROID ||
          device.platform === PLATFORM_CHROME
            ? t('This device is now assigned to {{profileName}}', {
                profileName: profile.name,
              })
            : t(
                'The desktop account {{userName}} is now assigned to {{profileName}}',
                {
                  userName: getUserByUid(device, deviceUserId).name,
                  profileName: profile.name,
                }
              );

        dispatch(showToast(toastMessage, TOAST_ICON_TICK));
      })
      .then(() => dispatch(receiveUpdateAssignedProfile()))
      .catch(() => {
        dispatch(requestUpdateAssignedProfileError());
        dispatch(
          showToast(
            [t('Something went wrong.'), t('Please try again.')],
            TOAST_ICON_WARNING
          )
        );
      });
  };

const assignProfileToAllDeviceUsers = (device, profile) => dispatch => {
  dispatch(requestUpdateAssignedProfile());
  dispatch(updateUserDeviceProfile(device, null, profile.id))
    .then(() =>
      dispatch(modifyDevice(device.set('defaultProfileId', profile.id)))
    )
    .then(() => dispatch(fetchProfiles()))
    .then(() =>
      dispatch(
        showToast(
          t(
            'All user accounts on this device are now assigned to {{profileName}}',
            {
              profileName: profile.name,
            }
          ),
          TOAST_ICON_TICK
        )
      )
    )
    .then(() => dispatch(receiveUpdateAssignedProfile()))
    .catch(() => {
      dispatch(requestUpdateAssignedProfileError());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
};

const unAssignProfileFromDeviceUser =
  (device: DeviceRecord, deviceUserId) => dispatch => {
    dispatch(requestUpdateAssignedProfile());
    dispatch(updateUserDeviceProfile(device, deviceUserId, null))
      .then(() => dispatch(fetchProfiles()))
      .then(() => {
        const toastMessage =
          device.platform === PLATFORM_IOS ||
          device.platform === PLATFORM_ANDROID ||
          device.platform === PLATFORM_CHROME
            ? t('This device is no longer protected')
            : t('The desktop account {{userName}} is no longer protected', {
                userName: getUserByUid(device, deviceUserId).name,
              });

        dispatch(showToast(toastMessage, TOAST_ICON_TICK));
      })
      .then(() => dispatch(receiveUpdateAssignedProfile()))
      .catch(() => {
        dispatch(requestUpdateAssignedProfileError());
        dispatch(
          showToast(
            [t('Something went wrong.'), t('Please try again.')],
            TOAST_ICON_WARNING
          )
        );
      });
  };

const unAssignProfileFromAllDeviceUsers =
  (device: DeviceRecord) => dispatch => {
    dispatch(requestUpdateAssignedProfile());
    dispatch(updateUserDeviceProfile(device, null, null))
      .then(() => dispatch(modifyDevice(device.set('defaultProfileId', null))))
      .then(() => dispatch(fetchProfiles()))
      .then(() =>
        dispatch(
          showToast(
            t('All user accounts on this device are no longer protected.'),
            TOAST_ICON_TICK
          )
        )
      )
      .then(() => dispatch(receiveUpdateAssignedProfile()))
      .catch(() => {
        dispatch(requestUpdateAssignedProfileError());
        dispatch(
          showToast(
            [t('Something went wrong.'), t('Please try again.')],
            TOAST_ICON_WARNING
          )
        );
      });
  };
