import State, { BaseThunk, Dispatch } from '../../store/state';
import { SET_CURRENT_SCENARIO, RuleType, showAppRate } from '../appRate';
import {
  getLastLocationPathname,
  getLastRuleTypeModified,
  getCurrentScenario,
  getLocationPathname,
  isAnyRuleEditedInTimeline,
  getDaysSinceAccountCreated,
  getPersistentStorageItem,
} from '../../selectors';
import { shouldShowRatingPopup } from '../../helpers/appRate';
import flags from '../../sideEffects/flags';
import { pushRelativeLocation } from '../../actions/Navigation';
import { isiOSPlatform } from '../../helpers';
import { canRequestTrackingConsentForIosDevices } from '../../lib/wrappers/adjust';
import { daysFrom } from '../../helpers/dates';
import { getFeatureFlag } from '../../selectors/featureFlags';

export enum ScenarioName {
  TimeRulesSet = 'Time Rules Set',
  WebFilterRulesSet = 'Web Filter Rules Set',
  TimelineEventAction = 'Timeline Event Action',
  AppRulesSet = 'App Rules Set',
  YouTubeRulesSet = 'YouTube Rules Set',
}

export interface Scenario {
  name: ScenarioName;
  flag: () => boolean;
  checkConditions: (state: State) => boolean;
}

const rulesPath = /^\/profiles\/(-1|[0-9]+)\/rules\/?$/;

export const scenarios: Scenario[] = [
  {
    name: ScenarioName.TimeRulesSet,
    flag: () => flags.timeRulesSetScenario.isEnabled(),
    checkConditions: (state: State) => {
      const dailyTimeLimitsPath =
        /^\/profiles\/(-1|[0-9]+)\/rules\/dailyTimeLimit\/devices\/-?[0-9]+\/?$/;

      const currentPageIsRuleList = rulesPath.test(getLocationPathname(state));
      const previousPageWasDailyTimeLimitRules = dailyTimeLimitsPath.test(
        getLastLocationPathname(state)
      );
      return (
        currentPageIsRuleList &&
        previousPageWasDailyTimeLimitRules &&
        getLastRuleTypeModified(state) === RuleType.DailyTimeLimits
      );
    },
  },
  {
    name: ScenarioName.WebFilterRulesSet,
    flag: () => flags.webFilterRulesSetScenario.isEnabled(),
    checkConditions: (state: State) => {
      const webFiltersPath = /^\/profiles\/(-1|[0-9]+)\/rules\/webFilters\/?$/;

      const currentPageIsRuleList = rulesPath.test(getLocationPathname(state));
      const previousPageWasWebFiltering = webFiltersPath.test(
        getLastLocationPathname(state)
      );

      return (
        currentPageIsRuleList &&
        previousPageWasWebFiltering &&
        getLastRuleTypeModified(state) === RuleType.WebFiltering
      );
    },
  },
  {
    name: ScenarioName.AppRulesSet,
    flag: () => flags.appRulesSetScenario.isEnabled(),
    checkConditions: (state: State) => {
      const appRulesPath = /^\/profiles\/(-1|[0-9]+)\/rules\/appRules\/?$/;

      const currentPageIsRuleList = rulesPath.test(getLocationPathname(state));
      const previousPageWasAppRules = appRulesPath.test(
        getLastLocationPathname(state)
      );

      return (
        currentPageIsRuleList &&
        previousPageWasAppRules &&
        getLastRuleTypeModified(state) === RuleType.GamesAndApps
      );
    },
  },
  {
    name: ScenarioName.YouTubeRulesSet,
    flag: () => flags.youtubeRulesSetScenario.isEnabled(),
    checkConditions: (state: State) => {
      const youtubeRulesPath =
        /^\/profiles\/(-1|[0-9]+)\/rules\/youtube-rules\/?$/;

      const currentPageIsRuleList = rulesPath.test(getLocationPathname(state));
      const previousPageWasYoutubeRules = youtubeRulesPath.test(
        getLastLocationPathname(state)
      );

      return (
        currentPageIsRuleList &&
        previousPageWasYoutubeRules &&
        getLastRuleTypeModified(state) === RuleType.YoutubeMonitoring
      );
    },
  },
  {
    name: ScenarioName.TimelineEventAction,
    flag: () => flags.timelineEventActionScenario.isEnabled(),
    checkConditions: (state: State) => {
      const timelinePath = /^\/profiles\/(-1|[0-9]+)?$/;
      const currentPageIsTimeline = timelinePath.test(
        getLocationPathname(state)
      );
      return currentPageIsTimeline && isAnyRuleEditedInTimeline(state);
    },
  },
];

export const setCurrentScenario = (currentScenario: Scenario | undefined) => ({
  type: SET_CURRENT_SCENARIO,
  payload: currentScenario !== undefined ? currentScenario.name : undefined,
});

export const evaluateScenarios =
  (scenarios: Scenario[]): BaseThunk<any> =>
  (dispatch, getState) => {
    const state = getState();
    const scenario = scenarios.find(
      scenario => scenario.flag() && scenario.checkConditions(state)
    );
    return dispatch(setCurrentScenario(scenario));
  };

export const showInAppRatingPopupForCurrentScenario =
  () => async (dispatch: Dispatch, getState: () => State) => {
    await dispatch(evaluateScenarios(scenarios));
    const state = getState();

    const currentScenarioExist = getCurrentScenario(state) !== undefined;

    const shouldShowRatingAppModal =
      shouldShowRatingPopup(state) && currentScenarioExist;

    if (shouldShowRatingAppModal) {
      dispatch(showAppRate());
    }
  };

export const shouldShowIosPreConsentTrackingModal = async (state: State) => {
  if (
    !getFeatureFlag(state, 'iosPreConsentTrackingModal') ||
    !isiOSPlatform()
  ) {
    return false;
  }

  const qustodioCanAskTrackingPermission =
    await canRequestTrackingConsentForIosDevices();

  const userHasCreatedAccountAtLeast2DayAgo =
    getDaysSinceAccountCreated(state) >= 2;

  const closedDateForIosPreConsentTrackingModal = getPersistentStorageItem(
    state,
    'closedDateForIosPreConsentTrackingModal'
  );

  const userHasCloseTheModalInThePast =
    closedDateForIosPreConsentTrackingModal !== '';

  const userHasClosedTheModalAtLeast14DaysAgo =
    userHasCloseTheModalInThePast &&
    daysFrom(closedDateForIosPreConsentTrackingModal) >= 14;

  return (
    qustodioCanAskTrackingPermission &&
    userHasCreatedAccountAtLeast2DayAgo &&
    (!userHasCloseTheModalInThePast || userHasClosedTheModalAtLeast14DaysAgo)
  );
};

export const showIosPreConsentTrackingModalForCurrentScenario =
  () => async (dispatch: Dispatch, getState: () => State) => {
    await dispatch(evaluateScenarios(scenarios));
    const state = getState();

    const currentScenarioExist = getCurrentScenario(state) !== undefined;

    if (currentScenarioExist) {
      dispatch(pushRelativeLocation('modal/IosPreConsentTrackingModal'));
    }
  };
