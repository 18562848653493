import { connect } from 'react-redux';
import GenericFeatureUpgradeModal from '../../components/Modal/GenericFeatureUpgradeModal';
import { goBackIfHistory } from '../../ducks/routing';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapDispatchToProps = dispatch => ({
  onClickLink: (link: string) => {
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner:replace',
        src: link,
      })
    );
  },
  onClickClose: () => dispatch(goBackIfHistory()),
});

const GenericFeatureUpgradeModalContainer = connect(
  null,
  mapDispatchToProps
)(GenericFeatureUpgradeModal);

export default GenericFeatureUpgradeModalContainer;
