import * as React from 'react';
import { Label, Layout } from 'styleguide-react';
import {
  ProfileStatus as ProfileStatusType,
  PROFILE_STATUS_ACTIVE,
  PROFILE_STATUS_NO_DEVICE,
  PROFILE_STATUS_OFFLINE,
  PROFILE_STATUS_STANDBY,
} from '../../records/profile';
import { classNames } from '../../helpers';
import { t } from '../../lib/i18n';

interface ProfileStatusProps {
  status: ProfileStatusType | undefined;
  hasTamperedDevice?: boolean;
}

const ProfileStatus: React.FunctionComponent<ProfileStatusProps> = ({
  status,
  hasTamperedDevice,
}: ProfileStatusProps) => {
  let label: string;
  let statusClass: string;

  switch (status) {
    case PROFILE_STATUS_ACTIVE:
      label = t('Active');
      statusClass = 'ProfileStatus--active';
      break;
    case PROFILE_STATUS_NO_DEVICE:
      label = t('Connect a device');
      statusClass = 'ProfileStatus--no-device';
      break;
    case PROFILE_STATUS_OFFLINE:
      label = t('Offline');
      statusClass = 'ProfileStatus--offline';
      break;
    case PROFILE_STATUS_STANDBY:
      label = t('Standby');
      statusClass = 'ProfileStatus--standby';
      break;
    default:
      label = t('Unknown');
      statusClass = 'ProfileStatus--unknown';
  }

  if (hasTamperedDevice) {
    label = t('Tampering detected');
    statusClass = 'ProfileStatus--tampered-device';
  }

  return (
    <Layout textAlignement="center">
      <Label className={classNames('ProfileStatus', statusClass)}>
        {label}
      </Label>
    </Layout>
  );
};

export default ProfileStatus;
