import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
import type { DashboardTopCardsContainerProps } from '../../../containers/Dashboard/DashboardTopCards/ProfileDashboardTopCards';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import TotalScreenTimeWidgetContainer from '../../../containers/TotalScreenTimeContainer/TotalScreenTimeWidgetContainer';
import WebsiteBlockedWidgetContainer from '../../../containers/ActivitySummary/WebsiteBlockedWidgetContainer';
import StudentTopWidgetsContainer from '../../../containers/Schools/StudentTopWidgetsContainer';

const ProfileDashboardTopCards = ({
  profileId,
  isProfileLinkedToSchool,
  isDelegateToThisAccount,
  isOnlySchoolProfile,
}: DashboardTopCardsContainerProps) => {
  const shouldShowFamilyRow =
    !isOnlySchoolProfile || (isOnlySchoolProfile && isDelegateToThisAccount);

  const schoolRow = (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <FlexLayout
          mainaxis="row"
          wrap="wrap"
          margin={screenSize === ScreenSize.Desktop ? '8' : '16'}
        >
          <FlexLayout
            mainaxis="column"
            flexBasis="0"
            flexGrow="1"
            flexShrink="1"
          >
            <StudentTopWidgetsContainer profileId={profileId} />
          </FlexLayout>
        </FlexLayout>
      )}
    </RenderWhen>
  );

  const familyRow = (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <FlexLayout
          mainaxis={
            isProfileLinkedToSchool && screenSize === ScreenSize.Desktop
              ? 'column'
              : 'row'
          }
          wrap="nowrap"
          className="par-mini-activity__container"
          margin={screenSize === ScreenSize.Desktop ? '8' : '16'}
        >
          <TotalScreenTimeWidgetContainer profileId={profileId} />
          <WebsiteBlockedWidgetContainer profileId={profileId} />
        </FlexLayout>
      )}
    </RenderWhen>
  );
  return (
    <RenderWhen
      screenSize={[ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop]}
    >
      {screenSize => (
        <FlexLayout
          mainaxis={screenSize === ScreenSize.Desktop ? 'row' : 'column'}
          wrap="nowrap"
          width="100%"
        >
          {shouldShowFamilyRow && familyRow}
          {isProfileLinkedToSchool && schoolRow}
        </FlexLayout>
      )}
    </RenderWhen>
  );
};

export default ProfileDashboardTopCards;
