import * as React from 'react';
import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import DeviceSelectionBar from '../../containers/DeviceSelectionBarContainer';
import TimeSettings, { TimeSettingsProps } from './TimeSettings';
import TimeNotifications, { TimeNotificationsProps } from './TimeNotifications';
import { DeviceRecord } from '../../records';
import { FlexLayout, GroupHeader } from 'styleguide-react';

export interface TimeSettingsAndNotificationsPageProps
  extends TimeNotificationsProps,
    TimeSettingsProps {
  onChangeDevice: (device: DeviceRecord) => any;
  profileId: string;
  deviceId: string;
}

const TimeSettingsAndNotificationsPage: React.FunctionComponent<
  TimeSettingsAndNotificationsPageProps
> = props => {
  const { profileId, deviceId, onChangeDevice, onClickBackButton } = props;
  return (
    <PageLayout
      title={t('Time settings')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 1,
        },
      ]}
      actions={[{ actionName: 'back', onClick: onClickBackButton }]}
    >
      <FlexLayout mainaxis="column">
        <DeviceSelectionBar
          profileId={profileId}
          deviceId={deviceId}
          onChangeDevice={onChangeDevice}
        />
        <GroupHeader>{t('Settings')}</GroupHeader>
        <TimeSettings {...props} />
        <FlexLayout mainaxis="column" marginTop="16">
          <GroupHeader>{t('Notifications')}</GroupHeader>
          <TimeNotifications {...props} />
        </FlexLayout>
      </FlexLayout>
    </PageLayout>
  );
};

export default TimeSettingsAndNotificationsPage;
