import * as React from 'react';
import { EventRecord } from '../../records';
import { ACTION_BLOCK, EventType } from '../../constants';
import { minutesToHMinFormat } from '../../helpers/dates';

const ActivityTimelineItemTimeSpent: React.FunctionComponent<{
  event: EventRecord;
}> = ({ event }) => {
  const { minutes, type, action } = event;
  if (
    type === EventType.Search ||
    (type === EventType.Web && action.action === ACTION_BLOCK) ||
    type === EventType.Youtube
  ) {
    return null;
  }

  const prefix = minutes === 1 ? '<' : '';
  return (
    <div className="ActivityTimelineItem__timeSpent">
      {minutes ? `${prefix}${minutesToHMinFormat(minutes)}` : ''}
    </div>
  );
};

export default ActivityTimelineItemTimeSpent;
