/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FlexLayout, Label, Switch, Icon } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { IconProps } from 'styleguide-react/build/components/Icons/types/IconProps.types';
// eslint-disable-next-line import/no-unresolved
import { SwitchProps } from 'styleguide-react/build/components/Switch/Switch';
import { RequiredFields } from '../../../types/RequiredFields.types';

export type LabeledSwitchProps = RequiredFields<SwitchProps, 'id'> & {
  label: string | React.ComponentType;
  subtitle?: string;
  icon?: IconProps;
  title?: boolean;
};
const LabeledSwitch: React.FC<LabeledSwitchProps> = (
  props: LabeledSwitchProps
) => {
  const { label, subtitle, icon, title = true, id } = props;
  return (
    <FlexLayout
      mainaxis="column"
      className="labeled-switch"
      marginTop="16"
      marginBottom="16"
    >
      <FlexLayout
        mainaxis="row"
        mainaxisAlignment="space-between"
        crossaxisAlignment="center"
        flexGrow="1"
      >
        {icon && (
          <FlexLayout mainaxis="column" marginRight="16">
            <Icon {...icon} />
          </FlexLayout>
        )}
        <FlexLayout mainaxis="column" flexGrow="1">
          <Label htmlFor={id}>
            <span className={title ? 'rck-fw-semibold' : ''}>{label}</span>
          </Label>
        </FlexLayout>
        <FlexLayout mainaxis="column">
          <Switch {...props} />
        </FlexLayout>
      </FlexLayout>
      {subtitle && (
        <FlexLayout mainaxis="row" marginTop="8">
          <span>{subtitle}</span>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

export default LabeledSwitch;
