import * as Sentry from '@sentry/react';
import { loadAuth } from '../../actions/Authentication';
import { isBrowserPlatform } from '../../helpers';
import { getAppVersion } from '../../helpers/globals';
import { resolvePaymentProvider } from '../../helpers/purchase-provider';
import { parseQuery } from '../../helpers/query';
// eslint-disable-next-line import/no-cycle
import { receiveParentDeviceVersion } from '../parentDevice';
import {
  clearStorage,
  setItem,
  setMemoryStorageObject,
  setStateFromStorage,
} from '../persistentStorage';
// eslint-disable-next-line import/no-cycle
import { setStandaloneFlow } from '.';
// eslint-disable-next-line import/no-cycle
import { setIsNewAccount } from '../account';

/**
 * Initialize state from persistent storage and query params.
 */
export const initializeWithParams = (query: string) => dispatch => {
  const queryObject = parseQuery(query);
  // autologin auth-code: discard any stored data
  if (queryObject.kind === 'auth-code') {
    dispatch(clearStorage());
  }

  // standalone flow: ignore and do not save anything to stored data
  if (queryObject.standalone) {
    dispatch(setMemoryStorageObject());
  }

  dispatch(setStateFromStorage());
  return dispatch(loadAuth(queryObject))
    .then(() => {
      if (!isBrowserPlatform()) {
        dispatch(receiveParentDeviceVersion(getAppVersion()));
      } else if (queryObject.appVersion) {
        dispatch(receiveParentDeviceVersion(queryObject.appVersion));
      }
    })
    .then(() =>
      dispatch(
        setItem(
          'paymentProvider',
          resolvePaymentProvider(queryObject.paymentProvider)
        )
      )
    )
    .then(() => {
      if (queryObject.track) {
        Sentry.setTag('track', queryObject.track);
      }
      return dispatch(setItem('track', queryObject.track || 'production'));
    })
    .then(() => {
      if (queryObject.standalone) {
        dispatch(setStandaloneFlow(queryObject.standalone));
      }

      if (queryObject.isNewAccount === 'true') {
        dispatch(setIsNewAccount());
      }
    });
};
