import * as React from 'react';
import { ProfileInfoHeader } from 'styleguide-react';
import { connect } from 'react-redux';
import State from '../../store/state';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { getProfileOrDefault } from '../../selectors/profileDevices';
import { mapProfileStatusToMessageStatus } from '../../records/profile';
import {
  getProfileId,
  isProfileLinkedWithLinewizeStudent,
} from '../../selectors/profile';
import { track, Events } from '../../helpers/analytics';
import { isProfileHeaderEditable } from '../../selectors/layout';
import ProfileSummarySelectorDropdown from '../DropDown/ProfileSummarySelectorDropdown';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import {
  getIsSchoolAndFamilyProfile,
  getIsSchoolOnlyProfile,
} from '../../containers/Dashboard/helpers/profileDashboardHelpers';

export interface ProfileHeaderProps {
  editable?: boolean;
  screenSize?: ScreenSize;
}

export interface ConnectedProfileHeaderProps extends ProfileHeaderProps {
  profile: ProfileRecord;
  statusMessage?: string;
  shouldShowProfileSummarySelectorDropdown: boolean;
  onEdit: () => void;
}

const editProfile = () => (dispatch, getState) => {
  track(Events.ClickedProfileEdit);
  const navigate = getMultiPlatformNavigation();
  dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${getProfileId(getState())}/edit`,
    })
  );
};

const ProfileHeader: React.FunctionComponent<ConnectedProfileHeaderProps> = ({
  profile,
  statusMessage,
  screenSize,
  editable,
  shouldShowProfileSummarySelectorDropdown,
  onEdit,
}: ConnectedProfileHeaderProps) => (
  <ProfileInfoHeader
    name={profile.name}
    size={
      screenSize === ScreenSize.Mobile || screenSize === ScreenSize.Tablet
        ? 'small'
        : 'regular'
    }
    status={profile.status}
    statusMessage={statusMessage}
    avatar={<ProfileAvatar profile={profile} />}
    // TODO remove this action element and the component once we finish delegation and combined dashboard
    // this will be done in PAR-5798
    actionElement={
      shouldShowProfileSummarySelectorDropdown && (
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <ProfileSummarySelectorDropdown />
        </RenderWhen>
      )
    }
    onEdit={editable ? onEdit : undefined}
  />
);

const getShowStatusMessage = (state: State, profile: ProfileRecord) => {
  const shouldShowStatusMessage =
    !profile.isLinewizestudentLinked ||
    (!getIsSchoolOnlyProfile(state, profile.id) &&
      getIsSchoolAndFamilyProfile(state, profile.id));

  return shouldShowStatusMessage;
};

const mapStateToProps = (state: State, props: ProfileHeaderProps) => {
  const profile = getProfileOrDefault(
    state,
    parseInt(getProfileId(state) ?? '', 10)
  );

  const shouldShowStatusMessage = getShowStatusMessage(state, profile);

  return {
    editable: isProfileHeaderEditable(state),
    screenSize: props.screenSize,
    profile,
    statusMessage:
      shouldShowStatusMessage &&
      mapProfileStatusToMessageStatus(profile.status),
    shouldShowProfileSummarySelectorDropdown:
      isProfileLinkedWithLinewizeStudent(state, profile.id),
    ...props,
  } as ConnectedProfileHeaderProps;
};

const mapDispatchToProps = dispatch => ({
  onEdit: () => {
    dispatch(editProfile());
  },
});

const ConnectedProfileHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileHeader);

export default ConnectedProfileHeader;
