import {
  PolicyAction,
  PolicyBlockType,
} from '../../records/routines/policy/types/Policy.types';
import {
  Routine,
  RoutineType,
  RoutineIconType,
  RoutineRecord,
} from '../../records/routines/types/Routine.types';
import { Schedule } from '../../records/routines/schedule/types/Schedule.types';
import { PolicyWebCategory } from '../../records/routines/policy/types/PolicyWebCategory.types';
import { PolicyApplication } from '../../records/routines/policy/types/PolicyApplication.types';
import { RoutineColor } from '../../palettes/RoutineColor';

export enum RoutineFeatureAccessLevel {
  /** Access to the full feature */
  full = 'full',
  /** Can access the routines page but with premium flyover. In case of downgrade can only view routines. */
  guest = 'guest',
  /** Authorization for only actions related to blocking routines */
  routineBlocking = 'routineBlocking',
  /** Cannot access the feature at all. */
  none = 'none',
}

export enum RoutineSteps {
  idle = 'idle',
  selectPreset = 'selectPreset',
  premiumBasicSelectRoutineType = 'premiumBasicSelectRoutineType',
  premiumUpgrade = 'premiumUpgrade',
  selectRoutineType = 'selectRoutineType',
  chooseAppRules = 'chooseAppRules',
  chooseWebRules = 'chooseWebRules',
  chooseTimeLimitOption = 'chooseTimeLimitOption',
  selectBlockType = 'selectBlockType',
  chooseNameAndStyle = 'chooseNameAndStyle',
  scheduleATimeSlot = 'scheduleATimeSlot',
  selectAppsException = 'selectAppsException',
  selectWebsException = 'selectWebsException',
  editRoutine = 'editRoutine',
}

export type RoutineRuleExceptionType = 'WEB' | 'APP';
export type RoutineMode = 'CREATE' | 'EDIT' | null;
export type RoutineCreationMode = 'custom' | 'preset';
export type WebRuleType = PolicyAction | 'DEFAULT';
export type AppRuleType = PolicyAction | 'DEFAULT';
export type RoutineRuleType = AppRuleType | WebRuleType;
export type AppRuleExceptions = Array<PolicyApplication>;
export type WebRuleExceptions = Array<PolicyWebCategory>;

export type TimeLimitType =
  | 'DEFAULT'
  | 'APPLY_DAILY_TIME_LIMITS'
  | 'DONT_APPLY_DAILY_TIME_LIMIT';

export interface TimeSlot {
  from: Schedule['startTime'];
  to: Schedule['startTime'];
  days: Schedule['weekdays'];
  uid?: string;
  routineUid?: string;
}

export interface ContextContentFilter {
  timeLimitType: TimeLimitType;
  webRuleType: WebRuleType;
  appRuleType: AppRuleType;
  appsException: AppRuleExceptions;
  websException: WebRuleExceptions;
  activeTab: RoutineRuleExceptionType;
}

export interface ContextStyle {
  name: Routine['name'];
  description: Routine['description'];
  color: RoutineColor;
  icon: RoutineIconType;
}

export interface MultiStepDataContext {
  uid?: RoutineRecord['uid'];
  paused: boolean;
  type: RoutineType;
  mode: RoutineMode;
  finished: boolean;
  editedTimeSlotUid?: string;
  featureAccessLevel: RoutineFeatureAccessLevel;
  contentFilter: ContextContentFilter;
  block: PolicyBlockType;
  style: ContextStyle;
  timeSlot: Array<TimeSlot>;
}

export type MultiStepTransition =
  | RoutineRuleExceptionType
  | 'CONTINUE'
  | 'STYLE'
  | 'EDIT_RULES'
  | 'EDIT_APP_RULES'
  | 'EDIT_WEB_RULES'
  | 'SCHEDULE_TIME_SLOT'
  | 'UPGRADE_TO_PREMIUM';

export type MultiStepContext<T = MultiStepDataContext> = {
  stepsStack: Array<RoutineSteps>;
  data: T;
};

export interface MultiStepNextEvent<T = MultiStepDataContext> {
  type: 'NEXT';
  data: T;
  transition: MultiStepTransition;
}

export interface MultiStepPrevEvent {
  type: 'PREV';
}

export interface MultiStepUpdateEvent<T = MultiStepDataContext> {
  type: 'UPDATE_CONTEXT';
  data: T;
}

export interface MultiStepExitEvent {
  type: 'EXIT';
}

export interface MultiStepResetDataContextInCreateModeEvent {
  type: 'RESET_DATA_CONTEXT_IN_CREATE_MODE';
}
