import { Map } from 'immutable';
import State from '../../../store/state';

export const getUnfilteredRoutinesRecordsMap = (state: State) =>
  state.get('records').get('routines') || Map({});

export const getUnfilteredRoutinesList = (state: State) =>
  getUnfilteredRoutinesRecordsMap(state).toList();

export const getUnfilteredSchedulesRecordsMap = (state: State) =>
  state.get('records').get('routinesSchedules') || Map({});

export const getRoutineUnfilteredSchedules = (
  state: State,
  routineUid: string
) =>
  getUnfilteredSchedulesRecordsMap(state)
    .valueSeq()
    .filter(schedule => schedule.routineUid === routineUid)
    .toList();
