import { DeepPartial } from 'redux';
import { Map } from 'immutable';
import { setupStore, createEnhancer } from './setupStore';
import State from './state';

export { historyType, rootReducer } from './setupStore';

export const { enhancer } = createEnhancer();

const initialState: DeepPartial<State> = Map();

const { store, history } = setupStore(initialState);

export { history };
export default store;
