import * as React from 'react';
import Swiper from 'react-id-swiper';
import { Card, FlexLayout, Layout } from 'styleguide-react';
import { CardDimensions } from '../../../constants';
import ActivitySummaryListContainerWithObserver from '../../../containers/ActivitySummary/ActivitySummaryListWithObserverContainer';
import { ActivityType } from '../../ActivitySummary/sharedModels';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import type { DashboardWidgetsFamilyContainerProps } from '../../../containers/Dashboard/DashboardWidgets/ProfileDashboardWidgetsFamilyContainer';
import YoutubeSummaryCardWithObserverContainer from '../../../containers/Summary/YoutubeSummaryCardWithObserverContainer';
import { Events, track } from '../../../helpers/analytics';
import { captureException } from '../../../helpers/sentry';
import InsightButton from '../../Insights/InsightButton';
import ActivityTimelineContainer from '../../../containers/ActivityTimeline/ActivityTimelineContainer';
import TotalScreenTimeCardContainer from '../../../containers/TotalScreenTimeContainer/TotalScreenTimeCardContainer';
import ScreenSummaryCardContainer from '../../../containers/ScreenTimeSummary/ScreenSummaryCardContainer';
import MobileScreenSummaryCardContainer from '../../../containers/ScreenTimeSummary/MobileScreenSummaryCardContainer';
import { ProfileRecord } from '../../../records/profile/types/Profile.types';

const renderScreenTimeSummaryCards = (profile?: ProfileRecord) => {
  const renderMobile = (
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout mainaxis="row">
        <Layout margin="16" width="100%">
          <MobileScreenSummaryCardContainer
            profile={profile}
            height={CardDimensions.height}
          />
        </Layout>
      </FlexLayout>
    </RenderWhen>
  );

  const renderTablet = (
    <React.Fragment>
      <FlexLayout mainaxis="row" width="100%" margin="16">
        <Layout width="50%" marginRight="8">
          <ScreenSummaryCardContainer
            profile={profile}
            height={CardDimensions.height}
          />
        </Layout>
        <Layout width="50%">
          <TotalScreenTimeCardContainer
            profile={profile}
            height={CardDimensions.height}
          />
        </Layout>
      </FlexLayout>
    </React.Fragment>
  );

  const renderDesktop = (
    <React.Fragment>
      <FlexLayout mainaxis="row">
        <Layout margin="8">
          <ScreenSummaryCardContainer
            profile={profile}
            height={CardDimensions.height}
            width={CardDimensions.width}
          />
        </Layout>
        <Layout margin="8">
          <TotalScreenTimeCardContainer
            profile={profile}
            height={CardDimensions.height}
            width={CardDimensions.width}
          />
        </Layout>
      </FlexLayout>
    </React.Fragment>
  );

  return {
    mobile: renderMobile,
    desktop: renderDesktop,
    tablet: renderTablet,
  };
};

const DashboardWidgetsFamily = ({
  profile,
  showCallsSMSCard,
  showYoutubeCard,
  activitySwiperIndex,
  showInsights,
}: DashboardWidgetsFamilyContainerProps) => {
  const swiperRef = React.createRef<any>();
  const swiperOptions = {
    pagination: {
      el: '.swiper-pagination',
    },
    initialSlide: activitySwiperIndex,
    on: {
      slideChange: () => {
        const swiperWrapper = swiperRef.current;
        try {
          if (swiperWrapper?.props?.children && swiperWrapper?.swiper) {
            const activityCardIndex = swiperWrapper.swiper.realIndex;
            const activityCard =
              swiperWrapper.props.children[activityCardIndex];
            const activityCardTrackableKey =
              activityCard.props['data-trackable-key'];

            track(Events.SwipedActivityCard, {
              page: activityCardTrackableKey,
              category: 'Activity cards',
            });
          }
        } catch (err) {
          captureException(err, {
            extra: {
              message:
                'ActivitySummary Swiper slideChange() function has failed',
            },
          });
        }
      },
    },
    spaceBetween: 10,
    slidesPerView: 'auto',
    centeredSlides: true,
  };
  return (
    <React.Fragment>
      <Layout marginBottom="8" centerX width="100%">
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <FlexLayout mainaxis="column">
            {renderScreenTimeSummaryCards(profile).desktop}
            <FlexLayout mainaxis="row">
              <Layout margin="8">
                <ActivitySummaryListContainerWithObserver
                  data-testid="blockedWebActivitySummaryCard"
                  type={ActivityType.BlockedWebs}
                  profile={profile}
                  width={CardDimensions.width}
                  height={CardDimensions.height}
                />
              </Layout>
              <Layout margin="8">
                <ActivitySummaryListContainerWithObserver
                  data-testid="topActivitySummaryCard"
                  type={ActivityType.AppsAndWebActivity}
                  profile={profile}
                  width={CardDimensions.width}
                  height={CardDimensions.height}
                />
              </Layout>
            </FlexLayout>
            <FlexLayout mainaxis="row">
              <Layout margin="8">
                <ActivitySummaryListContainerWithObserver
                  data-testid="searchActivitySummaryCard"
                  type={ActivityType.Search}
                  profile={profile}
                  width={CardDimensions.width}
                  height={CardDimensions.height}
                />
              </Layout>
              <Layout margin="8">
                {showCallsSMSCard && (
                  <ActivitySummaryListContainerWithObserver
                    data-testid="callsAndSmsActivitySummaryCard"
                    type={ActivityType.CallsAndSms}
                    profile={profile}
                    width={CardDimensions.width}
                    height={CardDimensions.height}
                  />
                )}
              </Layout>
            </FlexLayout>
            {showYoutubeCard && (
              <FlexLayout mainaxis="row">
                <Layout margin="8">
                  {showYoutubeCard && (
                    <YoutubeSummaryCardWithObserverContainer
                      profile={profile}
                      needsBorder
                      width={CardDimensions.width}
                    />
                  )}
                </Layout>
              </FlexLayout>
            )}
          </FlexLayout>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Tablet}>
          <div>
            {renderScreenTimeSummaryCards(profile).tablet}
            <Swiper {...swiperOptions} ref={swiperRef}>
              <div data-trackable-key="Top Activity Card">
                <FlexLayout mainaxis="row">
                  <Layout width="50%" marginRight="16" marginLeft="16">
                    <ActivitySummaryListContainerWithObserver
                      data-testid="blockedWebActivitySummaryCard"
                      type={ActivityType.BlockedWebs}
                      profile={profile}
                      height={CardDimensions.height}
                    />
                  </Layout>
                  <Layout width="50%" marginRight="16" marginLeft="16">
                    <ActivitySummaryListContainerWithObserver
                      data-testid="topActivitySummaryCard"
                      type={ActivityType.AppsAndWebActivity}
                      profile={profile}
                      height={CardDimensions.height}
                    />
                  </Layout>
                </FlexLayout>
              </div>
              <div data-trackable-key="Youtube Activity Card">
                <FlexLayout mainaxis="row">
                  <Layout width="50%" marginRight="16" marginLeft="16">
                    <ActivitySummaryListContainerWithObserver
                      data-testid="searchActivitySummaryCard"
                      type={ActivityType.Search}
                      profile={profile}
                      height={CardDimensions.height}
                    />
                  </Layout>
                  {showYoutubeCard && (
                    <Layout width="50%" marginRight="16" marginLeft="16">
                      <YoutubeSummaryCardWithObserverContainer
                        profile={profile}
                      />
                    </Layout>
                  )}
                  {!showYoutubeCard && showCallsSMSCard && (
                    <Layout width="50%" marginRight="16" marginLeft="16">
                      <ActivitySummaryListContainerWithObserver
                        data-testid="callsAndSmsActivitySummaryCard"
                        type={ActivityType.CallsAndSms}
                        profile={profile}
                        height={CardDimensions.height}
                      />
                    </Layout>
                  )}
                </FlexLayout>
              </div>
              <div>
                {showYoutubeCard && showCallsSMSCard && (
                  <div data-trackable-key="Calls and SMS Activity Card">
                    <FlexLayout mainaxis="row">
                      <Layout width="50%" marginRight="16" marginLeft="16">
                        <ActivitySummaryListContainerWithObserver
                          data-testid="callsAndSmsActivitySummaryCard"
                          type={ActivityType.CallsAndSms}
                          profile={profile}
                          height={CardDimensions.height}
                        />
                      </Layout>
                    </FlexLayout>
                  </div>
                )}
              </div>
            </Swiper>
            {showInsights && <InsightButton />}
            <Card fullHeight fullWidth>
              <ActivityTimelineContainer profile={profile} />
            </Card>
          </div>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Mobile}>
          {renderScreenTimeSummaryCards(profile).mobile}
          <div>
            <Swiper {...swiperOptions} ref={swiperRef}>
              <div data-trackable-key="Blocked Web Activity Card">
                <Layout marginLeft="16" marginRight="16">
                  <ActivitySummaryListContainerWithObserver
                    data-testid="blockedWebActivitySummaryCard"
                    type={ActivityType.BlockedWebs}
                    profile={profile}
                    height={CardDimensions.height}
                  />
                </Layout>
              </div>
              <div data-trackable-key="Top Activity Card">
                <Layout marginLeft="16" marginRight="16">
                  <ActivitySummaryListContainerWithObserver
                    data-testid="topActivitySummaryCard"
                    type={ActivityType.AppsAndWebActivity}
                    profile={profile}
                    height={CardDimensions.height}
                  />
                </Layout>
              </div>
              <div data-trackable-key="Search Activity Card">
                <Layout marginLeft="16" marginRight="16">
                  <ActivitySummaryListContainerWithObserver
                    data-testid="searchActivitySummaryCard"
                    type={ActivityType.Search}
                    profile={profile}
                    height={CardDimensions.height}
                  />
                </Layout>
              </div>
              {showYoutubeCard && (
                <div data-trackable-key="Search Activity Card">
                  <Layout marginLeft="16" marginRight="16">
                    <YoutubeSummaryCardWithObserverContainer
                      profile={profile}
                    />
                  </Layout>
                </div>
              )}
              {showCallsSMSCard && (
                <div data-trackable-key="Calls and SMS Activity Card">
                  <Layout marginLeft="16" marginRight="16">
                    <ActivitySummaryListContainerWithObserver
                      data-testid="callsAndSmsActivitySummaryCard"
                      type={ActivityType.CallsAndSms}
                      profile={profile}
                      height={CardDimensions.height}
                    />
                  </Layout>
                </div>
              )}
            </Swiper>
            {showInsights && <InsightButton />}
            <Card fullHeight fullWidth>
              <ActivityTimelineContainer profile={profile} />
            </Card>
          </div>
        </RenderWhen>
      </Layout>
    </React.Fragment>
  );
};

export default DashboardWidgetsFamily;
