import * as moment from 'moment';
import * as braze from '@braze/web-sdk';
import api from '../api';
// eslint-disable-next-line import/no-cycle
import { getAccountLocale, getAccount } from '../selectors/account';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { logout } from './logout';
import * as qinit from '../qinit';
import { isBrowserPlatform, isAndroidPlatform } from '../helpers';
import { AccountRecord } from '../records';
import { isSlaveAccount } from '../records/account';
import { createRequestParamsTransformer } from '../lib/api/transformers';
// eslint-disable-next-line import/no-cycle
import { isBrazeAvailable } from './globals/thirdPartyServices';
import { notifyLogoutAction } from '../epics/contentCommunication/actions';

export const setApiAccount = (id: string, uid: string) => {
  api.setAccountId(id);
  api.setAccountUid(uid);
};

export const injectRequestParamsTransformerIfNeeded = (
  account: AccountRecord
) => {
  if (!isSlaveAccount(account)) {
    return;
  }

  // When more than one transformer is necessary, execution order may be important!!
  api.addRequestTransformer(
    createRequestParamsTransformer({
      endpoints: [
        'accessProfile',
        'activateRetrial',
        'calendarRestrictions',
        'callsAndSmsSummary',
        'contact',
        'contacts',
        'devices',
        'device',
        'deviceRules',
        'deviceSettings',
        'deviceOptions',
        'deviceUser',
        'deviceUserEvents',
        'deviceUserProfile',
        'deviceUserStatus',
        'events',
        'license',
        'notificationCenter',
        'onboardingToken',
        'option',
        'options',
        'place',
        'places',
        'profiles',
        'profileRules',
        'profileVideoRules',
        'pushNotification',
        'screenTimePerDay',
        'searchSummary',
        'summary',
        'summaryDomainsAndApps',
        'summaryAlerts',
        'summaryAlertsReviewed',
        'timeLimits',
        'urlDetails',
        'youtubeSummaryTotalVideos',
        'youtubeSummaryTotalSearches',
        'youtubeSummaryLastVideoSearches',
        'youtubeSummaryLastVideos',
        'summaryStudentTotalUsage',
        'summaryStudentSignatures',
        'summaryStudentCategories',
        'signatureDetails',
        'studentPolicies',
        'studentPoliciesPause',
        'routines',
        'routinesDetails',
        'routinesSchedules',
        'routinesSchedulesDetails',
        'safeNetworksSettings',
        'studentEvents',
        'studentPoliciesDelegate',
      ],
      swappableParams: {
        accountId: account.masterAccountId,
        accountUid: account.masterAccountUid,
      },
    })
  );
};

// set in setStorageListener function
let callLogoutRef;

const callLogout = dispatch => ev => {
  // When the localStorage is cleared, kick the user out
  if (ev.key === null) {
    dispatch(notifyLogoutAction());
    if (callLogoutRef) {
      window.removeEventListener('storage', callLogoutRef);
    }
    dispatch(logout());
  }
};

export const setStorageListener = () => dispatch => {
  // This closes the session in all browser tabs when cleanup the storage when user logout
  // all tabs are attached to the same storage so all of them will be called.
  if (isBrowserPlatform()) {
    if (callLogoutRef) {
      window.removeEventListener('storage', callLogoutRef);
    }
    callLogoutRef = callLogout(dispatch);
    window.addEventListener('storage', callLogoutRef);
  }
};

export function setMomentLocale() {
  // eslint-disable-next-line no-underscore-dangle

  return (_dispatch, getState) => {
    let locale = getAccountLocale(getState());

    if (locale === 'zh-Hans') {
      locale = 'zh-cn';
    }

    return moment.locale(locale);
  };
}

export const setupBraze = () => (dispatch, getState) => {
  if (!isBrazeAvailable()) {
    return;
  }

  const state = getState();
  const account = getAccount(state);
  if (!isBrowserPlatform()) {
    dispatch(setupBrazeStandalone(account));
  } else {
    setupBrazeBrowser(account);
  }
};

export const setupBrazeBrowser = (account: AccountRecord) => {
  braze.initialize(qinit.tenant.parents_app.web.braze.key, {
    enableHtmlInAppMessages: true,
    baseUrl: qinit.shared.common.braze.api_web_endpoint,
  });
  braze.wipeData();
  braze.automaticallyShowInAppMessages();
  braze.changeUser(account.uid);
  window.braze = braze;
};

const setupBrazeAndroid = () => () => {
  BrazePlugin.startSessionTracking();
  BrazePlugin.enableSdk();
};

const setupBrazeForAllStandalone = (account: AccountRecord) => {
  BrazePlugin.changeUser(account.uid);
};

export const setupBrazeStandalone = (account: AccountRecord) => dispatch => {
  if (isAndroidPlatform()) {
    dispatch(setupBrazeAndroid());
  }
  setupBrazeForAllStandalone(account);
};

export const resetBraze = () => {
  if (isAndroidPlatform()) {
    BrazePlugin.disableSdk();
    BrazePlugin.wipeData();
  }
};
