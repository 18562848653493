import * as React from 'react';
import { classNames } from '../../helpers';

const Balloon: React.FunctionComponent<{
  text: string;
  className?: string;
}> = ({ text, className }) => (
  <div className={classNames('Balloon', className)}>
    <span>{text}</span>
  </div>
);

export default Balloon;
