import { getRecordOperations } from '../../recordHelpers/recordHelpers';
import {
  PolicyWebCategory,
  PolicyWebCategoryPayload,
  RoutineWebCategory,
} from './types/PolicyWebCategory.types';
import { policyAction } from './types/Policy.types';
import { commonMapInFn, commonMapOutFn } from '../../../api/mappings/common';
import {
  restrictKeyFromValue,
  restrictValueFromKey,
} from '../../../api/mappings/valueRestrictions';
import { webCategories } from '../../category';

export const policyWebCategoryMapping: Record<RoutineWebCategory, number> =
  webCategories;

export const PolicyWebCategoryOperations = getRecordOperations<
  PolicyWebCategory,
  PolicyWebCategoryPayload
>({
  defaultFields: {
    category: null,
    action: null,
  },
  mapIn: payload => ({
    ...commonMapInFn(payload),
    action: restrictKeyFromValue(payload?.action, policyAction),
    category: restrictKeyFromValue(payload?.category, policyWebCategoryMapping),
  }),
  mapOut: record => ({
    ...commonMapOutFn(record),
    action: restrictValueFromKey(record?.action, policyAction),
    category: restrictValueFromKey(record?.category, policyWebCategoryMapping),
  }),
});
