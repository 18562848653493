import { SettingsToggleNames } from '../constants';
import { showErrorToast } from '../containers/AccountSettings/helpers';
import { SetupAlertsModalReferrer } from '../containers/Modal/SetupAlertsModalContainer';
import {
  delayedModifyProfileRules,
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
} from '../ducks/profileRules';
import { WebRuleNames, trackWebRules } from '../helpers/analytics';
import { getProfileRules } from '../selectors';
import State, { BaseThunk } from '../store/state';
import { showSetupAlertsModal } from './PremiumFeaturesActions';

const mapToggleTargetToProfileRulesParentKey = {
  [SettingsToggleNames.UnknownSites]: 'web',
  [SettingsToggleNames.SafeSearch]: 'web',
  [SettingsToggleNames.ReportBlockedSites]: 'web',
  [SettingsToggleNames.BlockedUnsupportedBrowsers]: 'unsupportedBrowsers',
  [SettingsToggleNames.IsSearchAlertsNotificationsEnabled]: 'web',
};

const setProfileRulesRecord = (
  toggleTarget: SettingsToggleNames,
  toggleOn: boolean,
  state: State,
  profileId: string
) =>
  getProfileRules(state, profileId).setIn(
    [mapToggleTargetToProfileRulesParentKey[toggleTarget], toggleTarget],
    toggleOn
  );

const trackToggle = (toggleTarget: string, toggleOn: boolean): void => {
  switch (toggleTarget) {
    case SettingsToggleNames.SafeSearch:
      trackWebRules(
        toggleOn
          ? WebRuleNames.ENABLE_SAVE_SEARCH
          : WebRuleNames.DISABLE_SAVE_SEARCH
      );
      break;
    case SettingsToggleNames.UnknownSites:
      trackWebRules(
        toggleOn
          ? WebRuleNames.ALLOW_UNCATEGORIZED_WEBSITES
          : WebRuleNames.DISALLOW_UNCATEGORIZED_WEBSITES
      );
      break;
    case SettingsToggleNames.ReportBlockedSites:
      trackWebRules(
        toggleOn
          ? WebRuleNames.ENABLE_REPORT_BLOCKED_SITES_ALERT
          : WebRuleNames.DISABLE_REPORT_BLOCKED_SITES_ALERT
      );
      break;
    case SettingsToggleNames.BlockedUnsupportedBrowsers:
      trackWebRules(
        toggleOn
          ? WebRuleNames.ENABLE_BLOCK_UNSUPPORTED_BROWSERS
          : WebRuleNames.DISABLE_BLOCK_UNSUPPORTED_BROWSERS
      );
      break;
    case SettingsToggleNames.IsSearchAlertsNotificationsEnabled:
      trackWebRules(
        toggleOn
          ? WebRuleNames.ENABLE_SEARCH_ALERTS
          : WebRuleNames.DISABLE_SEARCH_ALERTS
      );
      break;
    default:
      break;
  }
};

const trackSearchAlertsToggleAttempt = (toggleTarget: string): void => {
  if (toggleTarget === SettingsToggleNames.IsSearchAlertsNotificationsEnabled) {
    trackWebRules(WebRuleNames.ENABLE_SEARCH_ALERTS_ATTEMPT);
  }
};

export const handleToggleWebFilterSetting =
  ({ target, toggleOn, profileId, needLicenseUpgrade = false }): BaseThunk =>
  dispatch => {
    if (needLicenseUpgrade) {
      trackSearchAlertsToggleAttempt(target);

      return dispatch(
        showSetupAlertsModal(
          SetupAlertsModalReferrer.webFilteringSiteNotifications
        )
      );
    }
    trackToggle(target, toggleOn);
    return dispatch((dispatch, getState) =>
      Promise.resolve(
        setProfileRulesRecord(target, toggleOn, getState(), profileId)
      )
        .then(profileRules => {
          return dispatch(
            delayedModifyProfileRules([profileRules, profileId], 1000)
          );
        })
        .catch(error => {
          dispatch(requestModifyProfileRulesError());
          dispatch(reverseProfileRulesUpdate());
          dispatch(showErrorToast(error));
        })
    );
  };
