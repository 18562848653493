import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';

import { AppEpicWithoutStateEffect } from '../types';
import { whenIsPageInIframe, sendMessageToPublicSite } from './helpers';
import { notifyNavigationAction } from './actions';
import { doWithoutStateEffect } from '../operators';

type NavigationAction = ReturnType<typeof notifyNavigationAction>;

export const notifyNavigationToParent: AppEpicWithoutStateEffect<
  NavigationAction
> = $actions => {
  return $actions.pipe(
    /*
    In the case of direct purchase,
    it is necessary for the purchase links to open in the same window as the public site.
    This is done to maintain the current behavior.
    */
    ofType('NOTIFY_NAVIGATION'),
    whenIsPageInIframe(),
    doWithoutStateEffect((action: NavigationAction) =>
      sendMessageToPublicSite({
        type: 'PAR_NAVIGATION',
        payload: action.payload,
      })
    ),
    catchError(() => {
      return EMPTY;
    })
  );
};
