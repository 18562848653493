import * as React from 'react';
import { Modal, Icon, Button, ButtonSize } from 'styleguide-react';
import { t } from '../../lib/i18n';

const SummaryCardInfoModal: React.FunctionComponent<{
  title: string;
  text: string;
  icon: typeof Icon;
  onClose: () => void;
}> = ({ title, text, icon, onClose }) => (
  <Modal
    title={title}
    header={{
      icon,
    }}
    onClickClose={onClose}
    size="small"
    buttons={[
      <Button
        onClick={onClose}
        size={ButtonSize.medium}
        block
        key="SummaryCard__button"
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    {text}
  </Modal>
);

export default SummaryCardInfoModal;
