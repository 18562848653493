import { fromJS, Set, NonEmptyMapRecord } from 'immutable';
import * as camelize from 'camelize';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import { normalize, Schema, arrayOf } from 'normalizr';
import * as R from 'ramda';
import { Action } from 'redux';
import { t } from '../../lib/i18n';

import api from '../../api';
import { ProfileRecord } from '../../records';
import { tapReject, minimumPromiseDuration } from '../../helpers';
import { showToast, TOAST_ICON_TICK, TOAST_ICON_WARNING } from '../toast';
import {
  getProfile,
  getProfileOrDefault,
  getAccount,
  getProfilesMap,
} from '../../selectors';
import { deleteProfileRecord, updateProfileRecord } from '../records';
import { APPThunk } from '../../helpers/thunks';
import { ttl3Seconds } from '../../lib/QApiCache/commonCacheStrategies';
import { EmptyAction } from '../../store/state';

export const REQUEST_PROFILES = 'REQUEST_PROFILES';
export const REQUEST_PROFILES_ERROR = 'REQUEST_PROFILES_ERROR';
export const RECEIVE_PROFILES = 'RECEIVE_PROFILES';
export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const REQUEST_CREATE_PROFILE = 'REQUEST_CREATE_PROFILE';
export const REQUEST_MODIFY_PROFILE = 'REQUEST_MODIFY_PROFILE';
export const REQUEST_REMOVE_PROFILE = 'REQUEST_REMOVE_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const RECEIVE_PROFILE_ADDRESS = 'RECEIVE_PROFILE_ADDRESS';

export default function profiles(
  state: NonEmptyMapRecord<{
    result: Set<number>;
    isFetching: boolean;
    hasBeenFetched: boolean;
  }> = fromJS({
    result: Set(),
    isFetching: false,
    hasBeenFetched: false,
  }),
  action
) {
  switch (action.type) {
    case REQUEST_PROFILE:
    case REQUEST_PROFILES:
    case REQUEST_CREATE_PROFILE:
    case REQUEST_MODIFY_PROFILE:
      return state.set('isFetching', true);
    case REQUEST_PROFILES_ERROR:
      return state.set('isFetching', false);
    case RECEIVE_PROFILES:
      return state.merge({
        isFetching: false,
        hasBeenFetched: true,
        result: Set(action.payload.result),
      });
    case RECEIVE_PROFILE:
      return state.merge({
        isFetching: false,
        result: state.get('result').add(action.payload.result),
      });
    case REMOVE_PROFILE:
      return state.merge({
        isFetching: false,
        result: state.get('result').delete(action.payload.result),
      });

    default:
      return state;
  }
}

const profileSchema = new Schema('profiles');

function normalizeProfiles(json) {
  const normalized = normalize(camelize(json), arrayOf(profileSchema));
  const records = {
    profiles: {},
  };
  if (normalized.entities.profiles) {
    for (const [id, profile] of Object.entries(normalized.entities.profiles)) {
      records.profiles[id] = ProfileRecord.fromPayload(profile);
    }
  }
  return Object.assign(normalized, { records });
}

const denormalizeProfile = decamelizeKeysDeep;

export function requestProfiles() {
  return {
    type: REQUEST_PROFILES,
  };
}

export function requestProfilesError(): Action<typeof REQUEST_PROFILES_ERROR> {
  return {
    type: REQUEST_PROFILES_ERROR,
  };
}

export function receiveProfiles(response) {
  const { result, records } = normalizeProfiles(response);
  return {
    type: RECEIVE_PROFILES,
    payload: {
      result,
      records,
    },
    receivedAt: Date.now(),
  };
}

export function fetchProfiles(): APPThunk {
  return dispatch => {
    dispatch(requestProfiles());
    return api.profiles
      .get({})
      .then(json => dispatch(receiveProfiles(json)))
      .catch(tapReject(() => dispatch(requestProfilesError())));
  };
}

export function fetchProfile(profileId: string) {
  return dispatch => {
    dispatch(requestProfiles());
    return api.profiles
      .withCache(ttl3Seconds)
      .get({ profileId })
      .then(json => dispatch(receiveProfile(json)))
      .catch(tapReject(() => dispatch(requestProfilesError())));
  };
}

function shouldFetchProfiles(state) {
  return getAccount(state).id && !state.isFetching;
}

export function fetchProfilesIfNeeded() {
  return (dispatch, getState) =>
    shouldFetchProfiles(getState())
      ? dispatch(fetchProfiles())
      : Promise.resolve();
}

export function requestCreateProfile() {
  return {
    type: REQUEST_CREATE_PROFILE,
  };
}

export function createProfile(profile) {
  return dispatch => {
    dispatch(requestCreateProfile());
    return api.profiles
      .post(denormalizeProfile(profile))
      .then(json => dispatch(receiveProfile(json)));
  };
}

export function modifyProfile(profile) {
  return dispatch => {
    const profileId = profile.id;
    dispatch(requestModifyProfile());
    return api.profiles
      .put({ profileId }, denormalizeProfile(profile))
      .then(json => dispatch(receiveProfile(json)));
  };
}

export function requestModifyProfile() {
  return {
    type: REQUEST_MODIFY_PROFILE,
  };
}

export function receiveProfile(response) {
  const { result, records } = normalizeProfiles([response]);
  return {
    type: RECEIVE_PROFILE,
    payload: {
      result: R.last(result),
      records,
    },
    receivedAt: Date.now(),
  };
}

const removeProfileCompleted = (profileName = 'Sample Kid') =>
  showToast(
    t("{{profileName}}'s profile was successfully removed", {
      profileName,
    }),
    TOAST_ICON_TICK
  );

export function requestRemoveProfile() {
  return {
    type: REQUEST_REMOVE_PROFILE,
  };
}

export const removeProfile = profileId => (dispatch, getState) => {
  dispatch(requestRemoveProfile());
  return api.profiles
    .delete({ profileId })
    .then(() =>
      dispatch(
        removeProfileCompleted(getProfile(getState(), profileId).get('name'))
      )
    )
    .then(() => dispatch(removedProfile(profileId)))
    .then(() => dispatch(deleteProfileRecord(profileId)));
};

export function removedProfile(profileId) {
  return {
    type: REMOVE_PROFILE,
    payload: {
      result: profileId,
    },
    receivedAt: Date.now(),
  };
}

export const receiveProfileAddress =
  (profileId, addressLines) => (dispatch, getState) =>
    dispatch({
      type: RECEIVE_PROFILE_ADDRESS,
      payload: {
        records: {
          profiles: {
            [profileId]: getProfileOrDefault(getState(), profileId).setIn(
              ['location', 'addressLines'],
              addressLines
            ),
          },
        },
      },
    });

export const refreshLastLocation = profileId => dispatch => {
  dispatch(requestProfiles());

  return api.profiles
    .get({ profileId })
    .then(minimumPromiseDuration(600))
    .then(json => {
      dispatch(receiveProfile(json));
      dispatch(showToast(t('Location updated'), TOAST_ICON_TICK));
    })
    .catch(
      tapReject(() => {
        dispatch(requestProfilesError());

        return dispatch(
          showToast(t('Location update failed'), TOAST_ICON_WARNING)
        );
      })
    );
};

export const updateProfileActiveRoutineAction = (
  profilesMap: ReturnType<typeof getProfilesMap>,
  profileId: string,
  routineUid: string | null
): ReturnType<typeof updateProfileRecord> | EmptyAction => {
  if (!profileId) return { type: 'void' };

  const updatedProfile = profilesMap
    .get(profileId)
    .set('activeRoutine', routineUid);

  return updateProfileRecord(updatedProfile, profileId);
};
