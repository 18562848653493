import * as React from 'react';
import { t } from '../../lib/i18n';
import { Field, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';
import { Button, ButtonSize } from 'styleguide-react';
import FormField from '../Form/deprecated/FormField';
import * as Logo from '../../assets/base/images/qustodio-login.svg';
import ModalSelector from '../Modal/ModalSelector';
import * as qinit from '../../qinit';
import { LicenseType, isFreeOrTrial } from '../../records/license';
import { FormNames } from '../../constants';
import PremiumConversionPanel from '../Logout/PremiumConvertionsPanel';

const Login: React.FunctionComponent<{
  forgotPasswordUrl: string;
  modal: {
    params: any;
    location: any;
  };
  autoFocus: boolean;
  license: LicenseType;
  imagePath: string;
  onSubmit: () => any;
  onClickButtonFullProtection: () => any;
}> = ({
  forgotPasswordUrl,
  license,
  modal,
  autoFocus,
  imagePath,
  onSubmit,
  onClickButtonFullProtection,
}) => (
  <div className="Login Screen">
    <ModalSelector params={modal.params} location={modal.location} />
    <div className="Screen__content">
      <div className="Login__content">
        <div className="Login__form section">
          <div className="section container">
            <a href={qinit.tenant.marketing.public_site.link}>
              <img className="Login__logo" alt="logo" src={Logo} />
            </a>
            <div className="Login__header">{t('Welcome to {{shortName}}')}</div>
            <div className="Login__subheader">
              {t('Please, enter your details below')}
            </div>
            <LoginForm onSubmit={onSubmit} autoFocus={autoFocus} />
            <div className="Login__button--container">
              <Button size={ButtonSize.small} block onClick={onSubmit}>
                {t('Log in')}
              </Button>
            </div>
            <a href={forgotPasswordUrl} className="Login__passwordLink">
              {t('Forgot your password?')}
            </a>
          </div>
        </div>

        {isFreeOrTrial(license) && (
          <PremiumConversionPanel
            imagePath={imagePath}
            onClickButtonFullProtection={onClickButtonFullProtection}
          />
        )}
      </div>
    </div>
  </div>
);

export default Login;

const validate = (values: Map<string, string>) =>
  Map({ username: '', password: '' })
    .merge(values)
    .reduce(
      (errors, value, key) =>
        !value ? { [key]: fieldError(key), ...errors } : errors,
      {}
    );

const fieldError = (key: string) => {
  switch (key) {
    case 'username':
      return t('Please enter a valid email address');
    case 'password':
    default:
      return t('Please enter a valid password');
  }
};

const LoginForm = reduxForm({
  form: FormNames.login,
  enableReinitialize: true,
  validate,
})(({ handleSubmit, autoFocus }) => {
  return (
    <form
      className="Form Login--form"
      onSubmit={handleSubmit}
      id={FormNames.login}
    >
      <div className="Form__fieldset_row">
        <Field
          name="username"
          component={FormField}
          type="email"
          autoFocus={autoFocus}
          placeholder={t('Email address')}
        />
      </div>
      <div className="Form__fieldset_row">
        <Field
          name="password"
          component={FormField}
          type="password"
          placeholder={t('Password')}
        />
      </div>
    </form>
  );
});
