export const CLEAR = 'CLEAR';
export const RELOAD_REQUEST = 'RELOAD_REQUEST';
export const RELOAD_RECEIVE = 'RELOAD_RECEIVE';
export const RELOAD_ERROR = 'RELOAD_ERROR';
export const DELAYED_MODIFY_TIMEOUT_ID = 'DELAYED_MODIFY_TIMEOUT_ID';
export const REQUEST_FETCH_DATA = 'REQUEST_FETCH_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const START_LOADING_INITIAL_DATA = 'START_LOADING_INITIAL_DATA';
export const FINISHED_LOADING_INITIAL_DATA = 'FINISHED_LOADING_INITIAL_DATA';
export const SET_CONTENT_HIDDEN = 'SET_CONTENT_HIDDEN';
export const SET_STANDALONE_FLOW = 'SET_STANDALONE_FLOW';
export const SET_ACCESS_TYPE = 'SET_ACCESS_TYPE';
export const SET_LOADED_ROOT = 'SET_LOADED_ROOT';
export const SET_OPENED_FROM_BACKGROUND = 'SET_OPENED_FROM_BACKGROUND';
export const TOGGLE_FLOATING_ACTION_MENU = 'TOGGLE_FLOATING_ACTION_MENU';
export const CLOSE_FLOATING_ACTION_MENU = 'CLOSE_FLOATING_ACTION_MENU';
export const IS_FRESHLY_LOGGED_OUT = 'IS_FRESHLY_LOGGED_OUT';
export const CLEAR_FRESHLY_LOGGED_OUT = 'CLEAR_FRESHLY_LOGGED_OUT';
export const SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT';
export const ROLLOUT_USER_CONFIG_DONE = 'ROLLOUT_USER_CONFIG_DONE';
export const SET_KEYBOARD_VISIBLE = 'SET_KEYBOARD_VISIBLE';
export const IS_NAVIGATING_TO_PURCHASE_PRODUCT =
  'IS_NAVIGATING_TO_PURCHASE_PRODUCT';
export const IS_DATA_FETCHED = 'IS_DATA_FETCHED';
export const SSO_STATUS_FAILED = 'SSO_STATUS';
export const SET_LOCALE = 'SET_LOCALE';

export enum AccessType {
  AuthCode,
  QueryParams,
  LocalStorage,
}
