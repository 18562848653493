import { LocationRecord } from '../../records/location';
import { getAccountLocale } from '../../selectors';
import { insertQueryParam } from '../../helpers/string';
import { getOS, OS, Browser, getBrowser } from '../../helpers/userAgent';
import { Marker } from '../../records/marker';
import { isiOSPlatform } from '../../helpers';
import { EmptyAction } from '../../store/state';
import type { HrefTarget } from '../../helpers/multiPlatformNavigation';

export { getDocument } from './core';

/**
 * Open a link in browser
 */
export const openLinkInBrowser = (
  url: string,
  target: HrefTarget = '_system'
): EmptyAction => {
  windowOpen(url, target);
  return { type: 'void' };
};

export const navigateToLink = (url: string) => {
  window.location.href = url;
  return { type: 'void' };
};

const windowOpen = (url: string, target: HrefTarget) => {
  if (window?.cordova?.InAppBrowser?.open) {
    // when in PASX, use InAppBrowser to open a link
    return window.cordova.InAppBrowser.open(url, target);
  }
  return window.open(url, target);
};

/**
 * Open a "latlng" location with Android or iOS protocols. Triggers opening of
 * the "map" app if available.
 * Currently there is no fallback when in web browser mode.
 */
export const openLocation =
  (location: LocationRecord, label = '') =>
  dispatch => {
    let target: HrefTarget = '_system';
    if (getOS() === OS.IOS && getBrowser() === Browser.Chrome) {
      target = '';
    }

    dispatch(openLinkInBrowser(getLocationUrl(location, label), target));
  };

export const openDirections = (marker: Marker) => dispatch => {
  let target: HrefTarget = '_system';
  if (getOS() === OS.IOS && getBrowser() === Browser.Chrome) {
    target = '';
  }

  dispatch(openLinkInBrowser(getDirectionsUrl(marker), target));
};

export const getLocationUrl = (location: LocationRecord, label = '') => {
  const { latitude, longitude } = location;

  switch (getOS()) {
    case OS.IOS:
      return `maps://?q=${latitude},${longitude}`;
    case OS.Android:
      if (!runtime_flags.skipGooglePlayServices) {
        return `geo://0,0?q=${latitude},${longitude}(${label})`;
      }
      // Needed to duplicate this return because typescript would fail with the following error:
      // TS7029: Fallthrough case in switch.
      // (/* falls through */ didn't work either)
      return `http://maps.google.com/maps?q=${latitude},${longitude}`;

    default:
      return `http://maps.google.com/maps?q=${latitude},${longitude}`;
  }
};

export const getDirectionsUrl = (marker: Marker) => {
  const { latitude, longitude } = marker;

  switch (getOS()) {
    case OS.IOS:
      return `maps://?destination=${latitude},${longitude}&daddr=${latitude},${longitude}`;
    case OS.Android:
      return `https://www.google.com/maps/dir/?api=1&parameters&destination=${latitude},${longitude}`;
    default:
      return `http://maps.google.com/maps?destination=${latitude},${longitude}`;
  }
};

/**
 * Take a public site url (or any url) and add or overwrite the query parameter
 * "locale" to the given value.
 */
export const formatPublicSiteHelpLink = (locale: string, url: string) =>
  insertQueryParam(['locale', locale])(url);

/**
 * Open a public site help link wih "?locale=(account's locale)" query param
 */
export const openPublicSiteHelpLinkInBrowser =
  (url: string, openInIOS = false) =>
  (dispatch, getState) => {
    return dispatch(
      isiOSPlatform() && !openInIOS
        ? { type: 'void' }
        : openLinkInBrowser(
            formatPublicSiteHelpLink(getAccountLocale(getState()), url),
            '_system'
          )
    );
  };
