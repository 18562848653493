import * as React from 'react';
import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  FlexLayout,
  Layout,
  StyledHeader,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import BannerFamilyImage from '../../assets/base/images/banner-family-1.png';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

interface ProtectDeviceBannerProps {
  onClick: () => void;
  compact?: boolean;
}

const ProtectDeviceBanner: React.FunctionComponent<
  ProtectDeviceBannerProps
> = ({ onClick, compact }: ProtectDeviceBannerProps) => {
  let footer = <React.Fragment />;
  let text = t(
    "Install Qustodio on your kids' personal devices to access advanced tools like app blocking and custom web filters. {1}Learn more{/1}",
    {
      1: (str: string) => (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.qustodio.com/myschool-family-features"
          onClick={() => {
            const navigate = getMultiPlatformNavigation();

            return navigate({
              type: 'external',
              src: 'https://www.qustodio.com/myschool-family-features',
            });
          }}
        >
          <strong>{str}</strong>
        </a>
      ),
    }
  );

  // Mobile version
  if (compact) {
    text = t('Keep your kids safe and secure on their own devices too.');
    footer = (
      <Button
        buttonType={ButtonType.plain}
        size={ButtonSize.medium}
        onClick={() => {
          const navigate = getMultiPlatformNavigation();
          return navigate({
            type: 'external',
            src: 'https://www.qustodio.com/myschool-family-features',
          });
        }}
      >
        {t('Learn more')}
      </Button>
    );
  }

  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      className={`ProtectDeviceBanner ${
        compact ? 'ProtectDeviceBanner--compact' : ''
      }`}
    >
      <Card className="Card">
        <FlexLayout mainaxis="row" className="Card__flex-container">
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            className="left-column"
          >
            <img src={BannerFamilyImage} alt="banner" />
          </FlexLayout>
          <FlexLayout
            mainaxis="column"
            mainaxisAlignment="center"
            className="right-column"
          >
            <FlexLayout
              mainaxis="row"
              mainaxisAlignment="space-between"
              crossaxisAlignment="center"
              className="right-column__flex-container"
            >
              <FlexLayout mainaxis="column" className="Banner__text">
                <StyledHeader type="h5">
                  <strong>{t('Protect your personal devices')}</strong>
                </StyledHeader>
                <p>{text}</p>
              </FlexLayout>
              <FlexLayout
                mainaxis="column"
                crossaxisAlignment="center"
                className="Banner__actions"
              >
                <Layout marginBottom="8">
                  <Button
                    onClick={onClick}
                    size={ButtonSize.medium}
                    buttonType={ButtonType.secondary}
                    data-testid="ProtectDeviceBanner__Button--protect-a-device"
                  >
                    {t('Protect a device')}
                  </Button>
                </Layout>
                {footer}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};

export default ProtectDeviceBanner;
