import { Record } from 'immutable';
import * as R from 'ramda';
import { camelCaseToSnakeCase } from '../../api/mappings/case';
import {
  PLATFORM_WINDOWS,
  PLATFORM_MAC,
  PLATFORM_ANDROID,
  PLATFORM_IOS,
  PLATFORM_UNKNOWN,
  Platform,
  PLATFORM_CHROME,
} from '../../constants';
import { t } from '../../lib/i18n';
import {
  DeviceSettingsPlatformMethods,
  DeviceSettingsPlatform,
  DeviceSettingsPlatformRecord as DeviceSettingsPlatformRecordType,
} from './types/DevicePlatform';

export const DeviceSettingsPlatformRecord = Record<
  DeviceSettingsPlatform,
  DeviceSettingsPlatformMethods
>({
  lockAndroidSettings: false,
  lockQustodioConfiguration: false,
  lockIncognitoBrowser: false,
});

DeviceSettingsPlatformRecord.serialize = record =>
  camelCaseToSnakeCase({
    ...record.toJS(),
  }) as DeviceSettingsPlatformRecordType;

DeviceSettingsPlatformRecord.fromPayload = payload =>
  DeviceSettingsPlatformRecord({
    ...payload,
  }) as DeviceSettingsPlatformRecordType;

export const platformMap = {
  0: PLATFORM_WINDOWS,
  1: PLATFORM_MAC,
  2: PLATFORM_ANDROID,
  4: PLATFORM_IOS,
  5: PLATFORM_CHROME,
};

export const mapPlatform = (platformId: number | undefined): Platform =>
  platformId !== undefined && platformMap[platformId]
    ? platformMap[platformId]
    : PLATFORM_UNKNOWN;

export const inverseMapPlatform: (platform: Platform) => number = R.prop(
  R.__,
  R.invertObj(platformMap)
) as any;

export const mapPlatformToPlatformName = (platform: Platform): string => {
  return new Map([
    [PLATFORM_ANDROID, 'Android'],
    [PLATFORM_IOS, 'iOS'],
    [PLATFORM_MAC, 'Mac'],
    [PLATFORM_WINDOWS, 'Windows'],
    [PLATFORM_CHROME, 'Chromebook'],
    [PLATFORM_UNKNOWN, t('device')],
  ]).get(platform) as string;
};

export const isMobilePlatform = (platform: Platform) =>
  platform && (platform === PLATFORM_ANDROID || platform === PLATFORM_IOS);

export const isDesktopPlatform = (platform: Platform) =>
  platform &&
  (platform === PLATFORM_MAC ||
    platform === PLATFORM_WINDOWS ||
    platform === PLATFORM_CHROME);
