import { FormNames, Routes, LockState } from '../constants';
import { getAccount, getLocation, getHistory } from '../selectors';
import { login } from './login';
import { setLoginRedirectLocation, goBackIfHistory } from '../ducks/routing';
import { setItem } from '../ducks/persistentStorage';
import { getLockStatusState } from '../selectors/lockState';
import { redirectAfterLogin } from '../ducks/app/redirectAfterLogin';
import { START_LOADING_INITIAL_DATA } from '../ducks/actionNames/app';
import { APPThunk } from '../helpers/thunks';
import { Dispatch } from '../store/state';
import { getFeatureFlag } from '../selectors/featureFlags';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';

// state actions
export const toggleLockScreen =
  (enabled: boolean): APPThunk =>
  dispatch => {
    return dispatch(setLockState(enabled ? 'unlocked' : 'disabled'));
  };

export const updateLockStateOnPause = (): APPThunk => (dispatch, getState) => {
  const state = getState();
  const currentLockState = getLockStatusState(state);

  // we update the timestamp upon pausing the app
  dispatch(setLockState(currentLockState));
};

export const lock = () => (dispatch: Dispatch) => {
  dispatch(setLockState('locked'));
  dispatch(redirectToLockScreen());
};

export const unlock = () => (dispatch: Dispatch) => {
  // This shows our global spinner
  dispatch({ type: START_LOADING_INITIAL_DATA });
  dispatch(setLockState('unlocked'));
  dispatch(redirectAfterLogin());
};

export const setLockState =
  (lockState: LockState): APPThunk =>
  (dispatch, getState) => {
    if (!getFeatureFlag(getState(), 'lockScreen')) {
      return dispatch(disableLockScreen());
    }

    return dispatch(
      setItem(
        'lockState',
        JSON.stringify({ state: lockState, timestamp: Date.now() })
      )
    );
  };

export const disableLockScreen = (): APPThunk => dispatch => {
  dispatch(
    setItem(
      'lockState',
      JSON.stringify({ state: 'disabled', timestamp: Date.now() })
    )
  );
};

// navigation actions

export const doLoginWithPassword = (): APPThunk => (dispatch, getState) => {
  const values = getState().get('form').get(FormNames.lockScreen).get('values');
  const account = getAccount(getState());

  return dispatch(login(account.email, values.get('password')));
};

export const redirectToLockScreen = (): APPThunk => (dispatch, getState) => {
  const location = getLocation(getState());
  const navigate = getMultiPlatformNavigation();
  if (location && location.pathname.indexOf(Routes.lockScreen) === -1) {
    dispatch(setLoginRedirectLocation(location));
  }
  dispatch(
    navigate({
      type: 'inner:replace',
      src: Routes.lockScreen,
    })
  );
};

export const checkBackHistoryWithLockScreen =
  (): APPThunk => (dispatch, getState) => {
    const state = getState();
    const history = getHistory(state);
    const currLocation = getLocation(state);
    const navigate = getMultiPlatformNavigation();
    if (
      currLocation.pathname === Routes.root ||
      currLocation.pathname === Routes.lockScreen
    ) {
      return;
    }
    if (
      history.length === 0 ||
      (history.length === 1 && history[0].pathname === Routes.lockScreen)
    ) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: '/',
        })
      );
    } else {
      dispatch(goBackIfHistory());
    }
  };
