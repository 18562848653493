import * as React from 'react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import Layout from './Layout';
import PageLayout from './PageLayout';
import LogoSvg from '../../assets/base/images/logo-green.svg';
import LogoMobileSvg from '../../assets/base/images/v3/logo/logo.svg';
import maskSvg from '../../assets/base/images/onboarding/mask.svg';

/**
 * This layout is a copy from MainOnboarding layout, I split it in an isolate
 * component to be able to use it outside onboarding because the MainOnboarding
 * component is very coupled to the business logic associated to the onboarding,
 * both school and family
 */
const SingleLayout = ({ children }) => (
  <div className="par-single-layout">
    {/* @todo We should be able to pass this header to Layout component instead of putting here */}
    <div className="par-single-layout__header">
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <img className="par-single-layout__logo" alt="logo" src={LogoSvg} />
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <img
          className="par-single-layout__logo"
          alt="logo"
          src={LogoMobileSvg}
        />
      </RenderWhen>
    </div>
    <Layout
      configurations={[
        {
          screenSize: ScreenSize.Desktop,
          hideSideBar: true,
          hideMenuOptions: true,
        },
      ]}
    >
      <PageLayout
        configuration={[
          {
            screenSize: ScreenSize.Desktop,
            columns: 1,
            mainInsideCard: true,
            cardCentered: true,
            addMarginTopToCenter: true,
            hidePageTitle: true,
            hideHeader: true,
          },
          {
            screenSize: ScreenSize.MobileOrTablet,
            columns: 1,
            mainInsideCard: true,
            cardCentered: true,
            hidePageTitle: true,
            hideHeader: true,
          },
        ]}
      >
        {children}
      </PageLayout>
    </Layout>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <img className="par-single-layout__mask" alt="mask" src={maskSvg} />
    </RenderWhen>
  </div>
);

export default SingleLayout;
