import {
  fromJS,
  Set,
  Map,
  NonEmptyMapRecord,
  OrderedSet,
  List,
} from 'immutable';
import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import { normalize, Schema } from 'normalizr';
import * as camelize from 'camelize';
import * as R from 'ramda';
import { ActionPayload } from 'redux';
import api from '../../api';
import {
  ProfileRulesRecord,
  AppRulesRecord,
  TimeSettingsRecord,
  getAppIcons,
  VideoRulesRecord,
  AppRuleRecord,
} from '../../records/profileRules';
import { ContactRecord } from '../../records/contact';
import {
  getProfileRules,
  getMultiDeviceTimeRestrictions,
  getPrevProfileRulesState,
  getProfileOrDefault,
} from '../../selectors';
import {
  updatePrevState,
  clearPrevState,
  ACTION_TARGET_PROFILE_RULES,
  ACTION_TARGET_TIME_RESTRICTIONS,
} from '../previousState';
import { delayedModifyTimeoutId } from '../app';
import { delayedModifyTargetActionCreator, tapReject } from '../../helpers';
import { Weekday } from '../../helpers/dates';
import {
  WEBFILTER_CATEGORIES_SECTION,
  CALLSMS_RULES_SECTION,
  WebfilterSection,
  WebfilterAction,
  AppFilter,
  CallsSmsRulesSection,
  CallsSmsEditOption,
  CallSmsRulesAction,
  APPLICATION_ALLOWED,
  FILTER_APPS_ALL,
} from '../../constants';
import { showErrorToast } from '../../containers/AccountSettings/helpers';
import ft, { DISABLE_MULTIDEVICES } from '../../lib/ft';
import { BaseThunk } from '../../store/state';
import { replaceAppId } from '../../helpers/profileRules';
import { daytimeCollapsedPropertyName } from '../../constants/profileRules';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { fetchProfileRoutines } from '../routines/thunks';
import { ttl3Seconds } from '../../lib/QApiCache/commonCacheStrategies';
import { requestAppCategoriesAction } from '../appCategories';
import flags from '../../sideEffects/flags';

export const REQUEST_ALL_PROFILE_RULES = 'REQUEST_ALL_PROFILE_RULES';
export const RECEIVED_ALL_PROFILE_RULES = 'RECEIVED_ALL_PROFILE_RULES';
export const REQUEST_PROFILE_RULES = 'REQUEST_PROFILE_RULES';
export const REQUEST_PROFILE_RULES_ERROR = 'REQUEST_PROFILE_RULES_ERROR';
export const RECEIVE_PROFILE_RULES = 'RECEIVE_PROFILE_RULES';
export const UPDATE_CHECKED_DAY = 'UPDATE_CHECKED_DAY';
export const DELAYED_MODIFY_PROFILE_RULES_TIMEOUT_ID =
  'DELAYED_MODIFY_PROFILE_RULES_TIMEOUT_ID';
export const REQUEST_MODIFY_PROFILE_RULES = 'REQUEST_MODIFY_PROFILE_RULES';
export const RECEIVE_MODIFIED_PROFILE_RULES = 'RECEIVE_MODIFIED_PROFILE_RULES';
export const REQUEST_MODIFY_PROFILES_RULES_ERROR =
  'REQUEST_MODIFY_PROFILES_RULES_ERROR';
export const TOGGLE_DAYTIME_COLLAPSE = 'TOGGLE_DAYTIME_COLLAPSE';
export const COLLAPSE_ALL_DAYTIMES = 'COLLAPSE_ALL_DAYTIMES';
export const SET_WEBFILTER_ACTIVE_SECTION = 'SET_WEBFILTER_ACTIVE_SECTION';
export const EDIT_DOMAIN_EXCEPTION = 'EDIT_DOMAIN_EXCEPTION';
export const SET_SELECTED_DOMAIN_ACTION = 'SET_SELECTED_DOMAIN_ACTION';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const SET_APPFILTER_SEARCH = 'SET_APPFILTER_SEARCH';
export const SET_APP_FILTER = 'SET_APP_FILTER';
export const SHOW_APP_DAY_DIAL = 'SHOW_APP_DAY_DIAL';
export const SET_APP_DAY_DIAL_DAY = 'SET_APP_DAY_DIAL_DAY';
export const SET_CALLSMS_ACTIVE_SECTION = 'SET_CALLSMS_ACTIVE_SECTION';
export const SET_CALLSMS_BLOCKING_OPTION = 'SET_CALLSMS_BLOCKING_OPTION';
export const SET_SELECTED_CONTACT_ACTION = 'SET_SELECTED_CONTACT_ACTION';
export const SET_CALLSMS_ACTIVE_CONTACT = 'SET_CALLSMS_ACTIVE_CONTACT';
export const SHOW_YOUTUBE_DAY_DIAL = 'SHOW_YOUTUBE_DAY_DIAL';
export const SET_YOUTUBE_DAY_DIAL_DAY = 'SET_YOUTUBE_DAY_DIAL_DAY';
export const SET_UNSUPPORTED_BROWSER_NAME_SELECTED =
  'SET_UNSUPPORTED_BROWSER_NAME_SELECTED';
export const TOGGLE_IS_ALERT_NEW_APPS = 'TOGGLE_IS_ALERT_NEW_APPS';
export const SHOW_APP_PREMIUM_FLYOVER = 'SHOW_APP_PREMIUM_FLYOVER';

const TIMEOUT_ID_DELAYED_PROFILE_RULES = Symbol(
  'TIMEOUT_ID_DELAYED_PROFILE_RULES'
);

type StateSlice = {
  result: Set<number>;
  hasFetchedAllRules: boolean;
  isFetching: boolean;
  hasBeenFetched: boolean;
  isUpdating: boolean;
  isUpdatePending: boolean;
  checkedDay: Weekday | null;
  isMorningCollapsed: boolean;
  isAfternoonCollapsed: boolean;
  isNightCollapsed: boolean;
  webFiltersActiveSection: WebfilterSection;
  editDomainException: WebfilterAction | null;
  selectedDomainAction: WebfilterAction | null;
  editCategory: {
    category: number;
    label: string;
    action: WebfilterAction;
  } | null;
  appPremiumFlyoverShown: boolean;
  appFilter: AppFilter;
  appFilterSearch: string;
  appDayDial: string | null;
  appDayDialCheckedDay: Weekday;
  youtubeRuleDayDial: string | null;
  youtubeRuleDayDialCheckedDay: Weekday;
  callsSMSActiveSection: CallsSmsRulesSection;
  callsSMSBlockingOption: CallsSmsEditOption | null;
  selectedContactAction: CallSmsRulesAction | null;
  callsSMSActiveContact: ContactRecord | null;
  unsupportedBrowserNameSelected: string;
  isAlertNewApps: boolean;
};
export default function profileRules(
  state: NonEmptyMapRecord<StateSlice> = fromJS({
    result: Set(),
    hasFetchedAllRules: false,
    isFetching: false,
    hasBeenFetched: false,
    isUpdating: false,
    isUpdatePending: false,
    checkedDay: null,
    isMorningCollapsed: true,
    isAfternoonCollapsed: true,
    isNightCollapsed: true,
    webFiltersActiveSection: WEBFILTER_CATEGORIES_SECTION,
    editDomainException: null,
    selectedDomainAction: null,
    editCategory: null,
    appPremiumFlyoverShown: false,
    appCategorySection: null,
    appFilter: FILTER_APPS_ALL,
    appFilterSearch: '',
    appDayDial: null,
    appDayDialCheckedDay: Weekday.Sunday,
    youtubeRuleDayDial: null,
    youtubeRuleDayDialCheckedDay: Weekday.Sunday,
    callsSMSActiveSection: CALLSMS_RULES_SECTION,
    callsSMSBlockingOption: null,
    selectedContactAction: null,
    callsSMSActiveContact: null,
    unsupportedBrowserNameSelected: '',
    isAlertNewApps: false,
  } as StateSlice),
  action
) {
  switch (action.type) {
    case REQUEST_ALL_PROFILE_RULES:
      return state.set('hasFetchedAllRules', false);
    case RECEIVED_ALL_PROFILE_RULES:
      return state.set('hasFetchedAllRules', true);
    case REQUEST_PROFILE_RULES:
      return state.set('isFetching', true);
    case REQUEST_PROFILE_RULES_ERROR:
      return state.set('isFetching', false);
    case RECEIVE_PROFILE_RULES:
      return state.merge({
        isFetching: false,
        hasBeenFetched: true,
        result: state.get('result').add(action.payload.result),
      });
    case UPDATE_CHECKED_DAY:
      return state.set('checkedDay', action.payload);
    case REQUEST_MODIFY_PROFILE_RULES:
      return state.set('isUpdating', true);
    case RECEIVE_MODIFIED_PROFILE_RULES:
      return state.set('isUpdating', false);
    case REQUEST_MODIFY_PROFILES_RULES_ERROR:
      return state.set('isUpdating', false);
    case TOGGLE_DAYTIME_COLLAPSE: {
      const newCollapsedState = {
        isMorningCollapsed: true,
        isAfternoonCollapsed: true,
        isNightCollapsed: true,
      };

      newCollapsedState[daytimeCollapsedPropertyName(action.payload.daytime)] =
        action.payload.collapsed;

      return state.merge(newCollapsedState);
    }
    case COLLAPSE_ALL_DAYTIMES:
      return defaultCollapseDayTimesProps(state);
    case SET_WEBFILTER_ACTIVE_SECTION:
      return state.set('webFiltersActiveSection', action.payload);
    case EDIT_DOMAIN_EXCEPTION:
      return state.set('editDomainException', action.payload);
    case SET_SELECTED_DOMAIN_ACTION:
      return state.set('selectedDomainAction', action.payload);
    case EDIT_CATEGORY:
      return state.set('editCategory', action.payload);
    case SET_APPFILTER_SEARCH:
      return state.set('appFilterSearch', action.payload);
    case SET_APP_FILTER:
      return state.set('appFilter', action.payload);
    case SHOW_APP_DAY_DIAL:
      return state.set('appDayDial', action.payload);
    case SET_APP_DAY_DIAL_DAY:
      return state.set('appDayDialCheckedDay', action.payload);
    case SHOW_YOUTUBE_DAY_DIAL:
      return state.set('youtubeRuleDayDial', action.payload);
    case SET_YOUTUBE_DAY_DIAL_DAY:
      return state.set('youtubeRuleDayDialCheckedDay', action.payload);
    case SET_CALLSMS_ACTIVE_SECTION:
      return state.set('callsSMSActiveSection', action.payload);
    case SET_CALLSMS_BLOCKING_OPTION:
      return state.set('callsSMSBlockingOption', action.payload);
    case SET_SELECTED_CONTACT_ACTION:
      return state.set('selectedContactAction', action.payload);
    case SET_CALLSMS_ACTIVE_CONTACT:
      return state.set('callsSMSActiveContact', action.payload);
    case SET_UNSUPPORTED_BROWSER_NAME_SELECTED:
      return state.set('unsupportedBrowserNameSelected', action.payload);
    case TOGGLE_IS_ALERT_NEW_APPS:
      return state.set('isAlertNewApps', action.payload);
    case SHOW_APP_PREMIUM_FLYOVER:
      return state.set('appPremiumFlyoverShown', action.payload);
    default:
      return state;
  }
}

export const denormalizeProfileRules = profileRules =>
  R.set(
    R.lensPath(['web', 'domains']), // rolls back decamelized domains
    R.clone(R.path(['web', 'domains'], profileRules)),
    decamelizeKeysDeep(R.dissocPath(['web', 'domains'], profileRules))
  );

const profileRulesSchema = new Schema('profileRules', {
  idAttribute: 'profile',
});

export const normalizeProfileRules = (json, multiDeviceJsons) => {
  const videos = { ...json.videos };
  const {
    result,
    entities: { profileRules },
  } = normalize({ ...camelize(json), videos }, profileRulesSchema);

  const recordProfileRules = Object.assign(
    {},
    ...Object.keys(profileRules).map(key => {
      const rules = profileRules[key];
      return {
        [key]: ProfileRulesRecord.fromPayload({
          ...rules,
          // we don't want domains to be camelized because the domainnames are stored as keys
          web: { ...rules.web, domains: { ...json.web.domains } },
        }),
      };
    })
  );

  return {
    result: [result],
    records: R.merge(
      {
        profileRules: recordProfileRules,
        appIcons: getAppIcons(recordProfileRules[json.profile]),
      },
      multiDeviceJsons
        ? {
            timeRestrictions: multiDeviceJsons.reduce(
              (records, [deviceId, json]) =>
                R.set(
                  R.lensProp(deviceId.toString()),
                  TimeSettingsRecord.fromPayload(camelize(json)),
                  records
                ),
              {}
            ),
          }
        : {}
    ),
  };
};

function defaultCollapseDayTimesProps(state) {
  return state.merge(
    Map({
      isMorningCollapsed: true,
      isAfternoonCollapsed: true,
      isNightCollapsed: true,
    })
  );
}

export function requestAllProfileRules() {
  return {
    type: REQUEST_ALL_PROFILE_RULES,
  };
}
export function receivedAllProfileRules() {
  return {
    type: RECEIVED_ALL_PROFILE_RULES,
  };
}

export function requestProfileRules() {
  return {
    type: REQUEST_PROFILE_RULES,
  };
}

export function requestProfilesRulesError() {
  return {
    type: REQUEST_PROFILE_RULES_ERROR,
  };
}

function fetchProfileRulesAndTimeLimits(
  profileId,
  deviceIds,
  fetchAppRules
): BaseThunk<Promise<any>> {
  return dispatch => {
    return api.profileRules
      .withCache(ttl3Seconds)
      .get({
        profileId,
        fetchAppRules,
      })
      .then(json =>
        json.time_restrictions.is_multi_devices && deviceIds.size > 0
          ? fetchTimeLimits(profileId, deviceIds).then(multiDeviceJsons => [
              json,
              multiDeviceJsons,
            ])
          : [json]
      )
      .then(([json, multiDeviceJsons]) =>
        dispatch(receiveProfileRules(json, multiDeviceJsons))
      )
      .catch(tapReject(() => dispatch(requestProfilesRulesError())));
  };
}

/**
 * Fetch profile rules with multidevice-timelimits if necessary.
 * If no deviceIds list given, then multidevice-timelimits will not be fetched.
 *
 * Fetch profile routines if account has the routines flag enabled.
 */
export function fetchProfileRules(
  profileId,
  deviceIds: List<number>,
  purgeRoutinesCache?: boolean
): BaseThunk<Promise<any>> {
  return dispatch => {
    dispatch(fetchProfileRoutines(purgeRoutinesCache));
    dispatch(requestProfileRules());
    if (flags.useAppCategories.isEnabled()) {
      dispatch(requestAppCategoriesAction());
    }
    return dispatch(fetchProfileRulesAndTimeLimits(profileId, deviceIds, true));
  };
}

export function fetchAllProfileRules(
  profiles: OrderedSet<ProfileRecord>
): BaseThunk<Promise<any>> {
  return dispatch => {
    dispatch(requestAllProfileRules());
    if (flags.useAppCategories.isEnabled()) {
      dispatch(requestAppCategoriesAction());
    }
    return Promise.all(
      profiles.toArray().map((profile: ProfileRecord) => {
        return dispatch(
          fetchProfileRulesAndTimeLimits(profile.id, profile.deviceIds, false)
        );
      })
    ).then(() => dispatch(receivedAllProfileRules()));
  };
}

const fetchTimeLimits = (profileId, deviceIds): Promise<any[]> =>
  ft.active(DISABLE_MULTIDEVICES)
    ? // fetch only first device
      api.timeLimits
        .get({ profileId, deviceId: deviceIds.first() })
        .then(json => [[deviceIds.first(), json]])
    : // fetch all devices
      Promise.all(
        deviceIds.map(deviceId =>
          api.timeLimits
            .get({ profileId, deviceId })
            .then(json => [deviceId, json])
        )
      );

export function receiveProfileRules(response, multiDeviceResponses?) {
  const { result, records } = normalizeProfileRules(
    response,
    multiDeviceResponses
  );
  return {
    type: RECEIVE_PROFILE_RULES,
    payload: {
      result: R.last(result),
      records,
    },
  };
}

export function updateCheckedDay(day) {
  return {
    type: UPDATE_CHECKED_DAY,
    payload: day,
  };
}

export function requestModifyProfileRules() {
  return {
    type: REQUEST_MODIFY_PROFILE_RULES,
  };
}

export function requestModifyProfileRulesError() {
  return {
    type: REQUEST_MODIFY_PROFILES_RULES_ERROR,
  };
}

export function receiveModifiedProfileRules() {
  return {
    type: RECEIVE_MODIFIED_PROFILE_RULES,
  };
}

export function toggleDayTimeCollapse(daytime, collapsed) {
  return {
    type: TOGGLE_DAYTIME_COLLAPSE,
    payload: { daytime, collapsed },
  };
}

export function collapseAllDayTimes() {
  return {
    type: COLLAPSE_ALL_DAYTIMES,
  };
}

export function updateProfileRules(profileRules, profileId) {
  return (dispatch, getState) => {
    const prevProfileRules = ProfileRulesRecord.serialize(
      getProfileRules(getState(), profileId)!
    );
    const currentPrevState = getPrevProfileRulesState(getState());
    const isPrevStateCleared = currentPrevState.equals(ProfileRulesRecord());
    // only update previous state if it was cleared on receiving an api response
    // this way, in case of need to revert, you will always revert to the last
    // successfully saved state
    if (isPrevStateCleared) {
      dispatch(updatePrevState(prevProfileRules, ACTION_TARGET_PROFILE_RULES));
    }
    dispatch(receiveProfileRules(ProfileRulesRecord.serialize(profileRules)));
  };
}

export function reverseProfileRulesUpdate() {
  return (dispatch, getState) => {
    const prevProfileRulesState = getPrevProfileRulesState(getState());
    dispatch(
      receiveProfileRules(ProfileRulesRecord.serialize(prevProfileRulesState))
    );
    dispatch(clearPrevState(ACTION_TARGET_PROFILE_RULES));
  };
}

export const modifyVideoProfileRules = (profileRules, profileId, source) => {
  return (dispatch, getState) => {
    dispatch(requestModifyProfileRules());
    const profile = getProfileOrDefault(getState(), profileId);
    return Promise.resolve()
      .then(() =>
        api.profileVideoRules.put(
          {
            profileUid: profile.uid,
            source,
          },
          VideoRulesRecord.serialize(profileRules.get('videos'))[source]
        )
      )
      .then(() => dispatch(receiveModifiedProfileRules()))
      .then(() => dispatch(clearPrevState(ACTION_TARGET_PROFILE_RULES)));
  };
};

const replaceApplicationListRules = (
  profileRules: ProfileRulesRecord,
  applicationList: List<AppRuleRecord>
) => profileRules.setIn(['appRules', 'applicationList'], applicationList);

const filterAppRulesToSave = (
  profileRules: ProfileRulesRecord
): ProfileRulesRecord => {
  const { appRules } = profileRules;

  if (!appRules.isApplicationList) {
    return replaceApplicationListRules(profileRules, List());
  }

  const doesRuleExist = (rule: AppRuleRecord) => rule.id !== null;

  const isDefaultQuota = quota => quota === 1440;
  const hasNonDefaultQuotas = (rule: AppRuleRecord) =>
    rule.quotas.filter(quota => isDefaultQuota(quota)).size !==
    rule.quotas.size;

  const hasNonDefaultAction = (rule: AppRuleRecord) =>
    rule.action !== APPLICATION_ALLOWED;

  const newAppsWithRules = appRules.applicationList.filter(
    rule =>
      !doesRuleExist(rule) &&
      (hasNonDefaultQuotas(rule) || hasNonDefaultAction(rule))
  );

  const appsWithRules = appRules.applicationList.filter(doesRuleExist);
  const appRulesToChange = appsWithRules.concat(newAppsWithRules);

  return replaceApplicationListRules(profileRules, appRulesToChange);
};

/**
 * Save profile rules to backend.
 * If multidevice AND a deviceId was specified, then also save the timelimits of
 * that device.
 */
export function modifyProfileRules(
  profileRules: ProfileRulesRecord,
  profileId,
  deviceId
) {
  return dispatch => {
    dispatch(requestModifyProfileRules());
    return Promise.resolve()
      .then(() =>
        profileRules.timeRestrictions.isMultiDevices && deviceId
          ? api.timeLimits.put(
              { profileId, deviceId },
              denormalizeProfileRules(
                TimeSettingsRecord.serialize(profileRules.timeRestrictions)
              )
            )
          : undefined
      )
      .then(() =>
        api.profileRules
          .put(
            { profileId, fetchAppRules: true },
            denormalizeProfileRules(
              ProfileRulesRecord.serialize(filterAppRulesToSave(profileRules))
            )
          )
          .then(json => {
            const appRules = profileRules.appRules.applicationList;
            const newRules = AppRulesRecord.fromPayload(
              camelize(json).appRules
            ).applicationList.filter(newRule => {
              return !appRules.find(rule => rule.id === newRule.id);
            });

            if (newRules.size > 0) {
              const updatedProfileRules = newRules.reduce(
                (currentProfileRules, appRule) =>
                  replaceAppId(currentProfileRules, appRule),
                profileRules
              );
              dispatch(updateProfileRules(updatedProfileRules, profileId));
            }
          })
          .then(() => dispatch(receiveModifiedProfileRules()))
          .then(() => dispatch(clearPrevState(ACTION_TARGET_PROFILE_RULES)))
      );
  };
}

export const delayedModifyProfileVideoRules = (args, delay) =>
  delayedModifyTargetActionCreator(
    delayedModifyTimeoutId,
    updateProfileRules,
    modifyVideoProfileRules,
    TIMEOUT_ID_DELAYED_PROFILE_RULES,
    args,
    delay
  );

export const delayedModifyProfileRules = (args, delay) =>
  delayedModifyTargetActionCreator(
    delayedModifyTimeoutId,
    updateProfileRules,
    modifyProfileRules,
    TIMEOUT_ID_DELAYED_PROFILE_RULES,
    args,
    delay
  );

export const delayedUpdateProfileRules =
  (profileId, profileRulesUpdater) => (dispatch, getState) =>
    // the callback-call could be wrapped in `dispatch( )` if necessary
    Promise.resolve(profileRulesUpdater(getProfileRules(getState(), profileId)))
      .then(profileRules => {
        return dispatch(
          delayedModifyProfileRules([profileRules, profileId], 1000)
        );
      })
      .catch(
        tapReject(error => {
          dispatch(requestModifyProfileRulesError());
          dispatch(reverseProfileRulesUpdate());
          dispatch(showErrorToast(error));
        })
      );

export const delayedModifyTimeLimits = (args, delay) =>
  delayedModifyTargetActionCreator(
    delayedModifyTimeoutId,
    updateTimeLimits,
    modifyTimeLimits,
    TIMEOUT_ID_DELAYED_PROFILE_RULES,
    args,
    delay
  );

export const updateTimeLimits =
  (timeRestrictions, profileId, deviceId) => (dispatch, getState) => {
    const prevTimeRestrictions = TimeSettingsRecord.serialize(
      getMultiDeviceTimeRestrictions(getState(), deviceId)
    );
    const currentPrevState = getState().getIn([
      'prevState',
      'timeRestrictions',
    ]);
    const isPrevStateCleared = currentPrevState.equals(TimeSettingsRecord());
    // only update previous state if it was cleared on receiving an api response
    // this way, in case of need to revert, you will always revert to the last
    // successfully saved state
    if (isPrevStateCleared) {
      dispatch(
        updatePrevState(prevTimeRestrictions, ACTION_TARGET_TIME_RESTRICTIONS)
      );
    }
    dispatch(
      receiveProfileRules(
        ProfileRulesRecord.serialize(getProfileRules(getState(), profileId)!),
        [[deviceId, TimeSettingsRecord.serialize(timeRestrictions)]]
      )
    );
  };

export function reverseTimeLimitsUpdate(profileId, deviceId) {
  return (dispatch, getState) => {
    const prevTimeLimitsState = getState().getIn([
      'prevState',
      'timeRestrictions',
    ]);
    dispatch(
      receiveProfileRules(
        ProfileRulesRecord.serialize(getProfileRules(getState(), profileId)!),
        [[deviceId, TimeSettingsRecord.serialize(prevTimeLimitsState)]]
      )
    );
    dispatch(clearPrevState(ACTION_TARGET_TIME_RESTRICTIONS));
  };
}

const modifyTimeLimits =
  (timeRestrictions, profileId, deviceId) => dispatch => {
    dispatch(requestModifyProfileRules());
    return api.timeLimits
      .put(
        { profileId, deviceId },
        denormalizeProfileRules(TimeSettingsRecord.serialize(timeRestrictions))
      )
      .then(() => dispatch(receiveModifiedProfileRules()))
      .then(() => dispatch(clearPrevState(ACTION_TARGET_TIME_RESTRICTIONS)));
  };

export const setWebFilterActiveSection = section => ({
  type: SET_WEBFILTER_ACTIVE_SECTION,
  payload: section,
});

export const editDomainException = domain => ({
  type: EDIT_DOMAIN_EXCEPTION,
  payload: domain,
});

export const setSelectedDomainAction = (action: WebfilterAction) => ({
  type: SET_SELECTED_DOMAIN_ACTION,
  payload: action,
});

export const editCategory = (category?: any) => ({
  type: EDIT_CATEGORY,
  payload: category,
});

export const setAppFilterSearch = search => ({
  type: SET_APPFILTER_SEARCH,
  payload: search,
});

export const setAppFilter = filter => ({
  type: SET_APP_FILTER,
  payload: filter,
});

export const showAppDayDial = appKey => ({
  type: SHOW_APP_DAY_DIAL,
  payload: appKey,
});

export const setAppDayDialDay = (
  day: number
): ActionPayload<typeof SET_APP_DAY_DIAL_DAY, number> => ({
  type: SET_APP_DAY_DIAL_DAY,
  payload: day,
});

export const showYoutubeDayDial = key => ({
  type: SHOW_YOUTUBE_DAY_DIAL,
  payload: key,
});

export const setYoutubeDayDial = day => ({
  type: SET_YOUTUBE_DAY_DIAL_DAY,
  payload: day,
});

export const setCallsSMSActiveSection = section => ({
  type: SET_CALLSMS_ACTIVE_SECTION,
  payload: section,
});

export const setCallsSMSBlockingOption = (option?: CallsSmsEditOption) => ({
  type: SET_CALLSMS_BLOCKING_OPTION,
  payload: option,
});

export const setSelectedContactAction = action => ({
  type: SET_SELECTED_CONTACT_ACTION,
  payload: action,
});

export const setCallsSMSActiveContact = contact => ({
  type: SET_CALLSMS_ACTIVE_CONTACT,
  payload: contact,
});

export const setUnsupportedBrowserNameSelected = name => ({
  type: SET_UNSUPPORTED_BROWSER_NAME_SELECTED,
  payload: name,
});

export const setIsAlertNewApps = toggleOn => ({
  type: TOGGLE_IS_ALERT_NEW_APPS,
  payload: toggleOn,
});

export const showAppPremiumFlyover = value => ({
  type: SHOW_APP_PREMIUM_FLYOVER,
  payload: value,
});
