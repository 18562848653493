import * as React from 'react';
import {
  ContentSeparator,
  CustomStyleListItem,
  DeactivableContent,
  FlexLayout,
  Layout,
  ListTitle,
  ListBckImageIcon,
} from 'styleguide-react';
import { AppRuleRecord } from '../../records/profileRules';
import { replaceS3Url } from '../../helpers';
import AppRulesPlatform from './AppRulesPlatform';
import { DropdownType } from './AppRulesDropdownCard';
import AppRulesRightElement from './AppRulesRightElement';

export type AppRulesCardProps = {
  app: AppRuleRecord;
  enabled: boolean;
  isBlockedUnsupportedBrowsers: boolean;
  icon?: JSX.Element;
  info?: JSX.Element;
  onSelectStatus: (options: {
    type: DropdownType;
    isMobile: boolean;
    appKey: string;
    appName: string;
    appPlatform: string;
    appId: string;
  }) => void;
  onClickApp: (appKey: string) => void;
};

const AppRulesCard: React.FunctionComponent<AppRulesCardProps> = ({
  app,
  enabled,
  isBlockedUnsupportedBrowsers,
  icon,
  info,
  onSelectStatus,
  onClickApp,
}: AppRulesCardProps) => {
  const isBlockedUnsupportedBrowser =
    app.isUnsupportedBrowser && isBlockedUnsupportedBrowsers;

  const isCardDisabled = !enabled || isBlockedUnsupportedBrowser;

  return (
    <CustomStyleListItem
      testId="AppRulesCard"
      footerContent={
        <DeactivableContent
          disabled={isCardDisabled}
          testId="AppRulesDeactivableFooter"
        >
          <FlexLayout
            mainaxis="column"
            flexGrow="1"
            testId="AppRulesDeactivableFooter"
          >
            <ContentSeparator />
            <FlexLayout
              mainaxis="column"
              paddingLeft="48"
              paddingTop="8"
              crossaxisAlignment="start"
            >
              <Layout marginTop="16">{info}</Layout>
            </FlexLayout>
          </FlexLayout>
        </DeactivableContent>
      }
      rightContent={
        <AppRulesRightElement
          app={app}
          enabled={enabled}
          onClickApp={onClickApp}
          isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowser}
          onSelectStatus={onSelectStatus}
        />
      }
      title={
        <DeactivableContent
          disabled={isCardDisabled}
          testId="AppRulesDeactivableTitle"
          className="AppRulesCard__ListTitle"
        >
          <ListTitle parentTestId="AppRuleAppItem" testId="AppRulesAppTitle">
            {app.name}
          </ListTitle>
        </DeactivableContent>
      }
      icon={
        <DeactivableContent
          disabled={isCardDisabled}
          testId="AppRulesDeactivableIcon"
        >
          {icon ?? <ListBckImageIcon url={replaceS3Url(app.thumbnail)} />}
        </DeactivableContent>
      }
    >
      <DeactivableContent
        disabled={isCardDisabled}
        testId="AppRulesDeactivableBody"
      >
        <FlexLayout
          mainaxis="row"
          paddingBottom="8"
          testId="AppRulesDeactivableBody"
        >
          <AppRulesPlatform app={app} />
        </FlexLayout>
      </DeactivableContent>
    </CustomStyleListItem>
  );
};

export default AppRulesCard;
