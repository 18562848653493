import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { LeaveWithoutSavingModalProps } from '../../../containers/Routines/Modal/LeaveWithoutSavingModalContainer';

const LeaveWithoutSavingModal = ({
  mode,
  step,
  onClickLeave,
  onClickClose,
}: LeaveWithoutSavingModalProps) => {
  const MODAL_TEST_ID = 'routines-leave-without-saving-modal';

  if (!mode && !step) {
    return null;
  }
  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-leave-without-saving"
      header={{
        icon: (
          <Icon
            type={IconType.exclamationCircle}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={t('Leave without saving?')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-leave`}
          key="button1"
          onClick={() => onClickLeave()}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Leave')}
        </Button>,
        <Button
          testId={`${MODAL_TEST_ID}-cancel`}
          key="button2"
          onClick={onClickClose}
          buttonType={ButtonType.secondary}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {mode === 'CREATE'
            ? t('Your routine hasn’t been created yet and won’t be saved.')
            : t('Any changes you’ve made to this routine will be lost.')}
        </ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default LeaveWithoutSavingModal;
