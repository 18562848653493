import * as camelize from 'camelize';
import { Dependencies } from '../../epics/types';
import { AppCategoriesOperations } from '../../records/appCategories/appCategories';
import { AppCategoriesPayload } from '../../records/appCategories/types';
import { receiveAppCategoriesAction } from './actions';
import { ttl10Seconds } from '../../lib/QApiCache/commonCacheStrategies';

export const requestReceiveAppCategories = async (api: Dependencies['api']) => {
  const response = camelize(
    await api.appCategories
      .withCache(ttl10Seconds)
      .get<AppCategoriesPayload>({})
  );
  const appCategories = response.appCategories.map(category =>
    AppCategoriesOperations.fromPayload(category)
  );
  return receiveAppCategoriesAction(appCategories);
};
