import * as React from 'react';
import { t } from '../../lib/i18n';
import DeviceSelectionBar from '../../containers/DeviceSelectionBarContainer';
import DayDial from './DayDial';
import { TimeSettingsRecord } from '../../records/profileRules';
import { Weekday } from '../../helpers/dates';
import { DeviceRecord } from '../../records';
import {
  Card,
  FlexLayout,
  GlobalType,
  Icon,
  IconSize,
  IconType,
  InstructionBlock,
  Layout,
} from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import PageLayoutSection from '../Layout/PageSectionLayout';
import TimeSettingsContainer from '../../containers/ProfileRules/TimeLimits/TimeSettingsContainer';
import TimeNotificationsContainer from '../../containers/ProfileRules/TimeLimits/TimeNotificationsContainer';
import { classNames } from '../../helpers';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';

interface DailyTimeLimitProps {
  profileId: string;
  deviceId: string;
  onClickDay: (day: Weekday) => any;
  onClickSettings: () => any;
  onChangeMinutes: (day: Weekday) => (minutes: number) => any;
  onChangeDevice: (device: DeviceRecord) => any;
  onClickMoreInfo: (deviceId: string) => any;
  timeSettings: TimeSettingsRecord;
  checkedDay: Weekday;
}

const DailyTimeLimit: React.FunctionComponent<
  DailyTimeLimitProps
> = dailyTimeLimitProps => (
  <PageLayout
    title={t('Daily Time Limits')}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        mainInsideCard: true,
        columns: 2,
      },
      {
        screenSize: ScreenSize.Tablet,
        mainInsideCard: true,
        columns: 2,
      },
    ]}
    actions={[
      { actionName: 'back', screenSize: ScreenSize.MobileOrTablet },
      {
        actionName: 'settings',
        screenSize: ScreenSize.Mobile,
        icon: <Icon type={IconType.settings} size={IconSize.lg} />,
        onClick: dailyTimeLimitProps.onClickSettings,
      },
    ]}
  >
    <PageLayoutSection side="main">
      <TimeLimitsDayDial {...dailyTimeLimitProps} />
    </PageLayoutSection>
    <PageLayoutSection side="right">
      <FlexLayout mainaxis="column">
        <Layout marginBottom="32">
          <Card
            type={GlobalType.white}
            header={{
              title: t('Settings'),
              border: true,
            }}
            className={classNames(
              DesktopLayoutScssUtils.CardRight,
              TabletLayoutScssUtils.CardRight
            )}
          >
            <TimeSettingsContainer
              params={{
                profileId: dailyTimeLimitProps.profileId,
                deviceId: dailyTimeLimitProps.deviceId,
              }}
            />
          </Card>
        </Layout>
        <Layout marginBottom="32">
          <Card
            type={GlobalType.white}
            header={{
              title: t('Notifications'),
              border: true,
            }}
            className={classNames(
              DesktopLayoutScssUtils.CardRight,
              TabletLayoutScssUtils.CardRight
            )}
          >
            <TimeNotificationsContainer
              params={{
                profileId: dailyTimeLimitProps.profileId,
                deviceId: dailyTimeLimitProps.deviceId,
              }}
            />
          </Card>
        </Layout>
      </FlexLayout>
    </PageLayoutSection>
  </PageLayout>
);

const TimeLimitsDayDial: React.FunctionComponent<{
  profileId: string;
  deviceId: string;
  timeSettings: TimeSettingsRecord;
  checkedDay: Weekday;
  onClickDay: (day: Weekday) => any;
  onChangeMinutes: (day: Weekday) => (minutes: number) => any;
  onChangeDevice: (device: DeviceRecord) => any;
  onClickMoreInfo: (deviceId: string) => any;
}> = ({
  profileId,
  deviceId,
  timeSettings,
  checkedDay,
  onClickDay,
  onChangeMinutes,
  onChangeDevice,
  onClickMoreInfo,
}) => (
  <FlexLayout mainaxis="column">
    <DeviceSelectionBar
      profileId={profileId}
      deviceId={deviceId}
      onChangeDevice={onChangeDevice}
    />
    <FlexLayout mainaxis="column" paddingBottom="24">
      <InstructionBlock className="DailyTimeLimit__Instruction">
        {`${t(
          "Tap a day to set your child's daily screen time allowance for that day."
        )} `}
        <a onClick={() => onClickMoreInfo(deviceId)}>
          {' '}
          <Icon type={IconType.questionCircle} />{' '}
        </a>
      </InstructionBlock>
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <DayDial
        dayQuotaMap={timeSettings.quotas}
        selectedDay={checkedDay}
        onSelectDay={onClickDay}
        onChangeQuota={onChangeMinutes(checkedDay)}
      />
    </FlexLayout>
  </FlexLayout>
);
export default DailyTimeLimit;
