import { Record as ImmutableRecord } from 'immutable';
import { t } from '../lib/i18n';
import { IconType } from 'styleguide-react';
import { EventRecord } from './event';

export type CategoryRecord = { id: number };
export const CategoryRecord = ImmutableRecord<
  CategoryRecord,
  {
    fromPayload: ImmutableRecord.Parser<any, CategoryRecord>;
  }
>({
  id: -1,
});

CategoryRecord.fromPayload = payload => {
  return CategoryRecord({
    id: payload,
  });
};

export const getCategoryLabel = (category: number): string => {
  const categoryLabel = categoryLabels()
    .filter(categoryLabel => categoryLabel.category === category)
    .shift();
  return categoryLabel ? categoryLabel.label : '';
};

export const getCategoryLabelById = (categoryId: string): string => {
  const id = webCategories[categoryId];
  const categoryLabel = categoryLabels()
    .filter(categoryLabel => categoryLabel.category === id)
    .shift();
  return categoryLabel ? categoryLabel.label : '';
};

export const webCategories = {
  educational: 4,
  government: 9,
  entertainment: 6,
  searchPortals: 17,
  news: 13,
  sports: 20,
  business: 26,
  health: 10,
  technology: 27,
  games: 8,
  travel: 22,
  religion: 16,
  shopping: 18,
  employment: 5,
  webmail: 25,
  forums: 30,
  socialNetwork: 19,
  chat: 2,
  fileSharing: 28,
  gambling: 7,
  proxiesLoopholes: 29,
  violence: 23,
  weapons: 24,
  profanity: 15,
  matureContent: 12,
  pornography: 14,
  alcohol: 1,
  drugs: 3,
  tobacco: 21,
} as const;

/**
 * Although a category, we don't show this category explicitly
 * in the UI, nor we allow it within rules. The timeline handles
 * this explicitly.
 */
export const hiddenCategories = {
  safetyNet: 40,
} as const;

export type WebCategory = keyof typeof webCategories;

export const categoryLabels = (): {
  category: number;
  label: string;
}[] => [
  {
    category: webCategories.educational,
    label: t('Educational'),
  },
  {
    category: webCategories.government,
    label: t('Government'),
  },
  {
    category: webCategories.entertainment,
    label: t('Entertainment'),
  },
  {
    category: webCategories.searchPortals,
    label: t('Search Portals'),
  },
  {
    category: webCategories.news,
    label: t('News'),
  },
  {
    category: webCategories.sports,
    label: t('Sports'),
  },
  {
    category: webCategories.business,
    label: t('Business'),
  },
  {
    category: webCategories.health,
    label: t('Health'),
  },
  {
    category: webCategories.technology,
    label: t('Technology'),
  },
  {
    category: webCategories.games,
    label: t('Games'),
  },
  {
    category: webCategories.travel,
    label: t('Travel'),
  },
  {
    category: webCategories.religion,
    label: t('Religion'),
  },
  {
    category: webCategories.shopping,
    label: t('Shopping'),
  },
  {
    category: webCategories.employment,
    label: t('Employment'),
  },
  {
    category: webCategories.webmail,
    label: t('Webmail'),
  },
  {
    category: webCategories.forums,
    label: t('Forums'),
  },
  {
    category: webCategories.socialNetwork,
    label: t('Social Network'),
  },
  {
    category: webCategories.chat,
    label: t('Chat'),
  },
  {
    category: webCategories.fileSharing,
    label: t('File Sharing'),
  },
  {
    category: webCategories.gambling,
    label: t('Gambling'),
  },
  {
    category: webCategories.proxiesLoopholes,
    label: t('Proxies/Loopholes'),
  },
  {
    category: webCategories.violence,
    label: t('Violence'),
  },
  {
    category: webCategories.weapons,
    label: t('Weapons'),
  },
  {
    category: webCategories.profanity,
    label: t('Profanity'),
  },
  {
    category: webCategories.matureContent,
    label: t('Mature Content'),
  },
  {
    category: webCategories.pornography,
    label: t('Pornography'),
  },
  {
    category: webCategories.alcohol,
    label: t('Alcohol'),
  },
  {
    category: webCategories.drugs,
    label: t('Drugs'),
  },
  {
    category: webCategories.tobacco,
    label: t('Tobacco'),
  },
];

export const categoryIcons: Record<
  WebCategory,
  typeof IconType[keyof typeof IconType]
> = {
  educational: IconType.book,
  government: IconType.buildingColumns,
  entertainment: IconType.photoFilmMusic,
  searchPortals: IconType.search,
  news: IconType.newspaper,
  sports: IconType.sportsball,
  business: IconType.briefcase,
  health: IconType.heartPulse,
  technology: IconType.phoneLaptop,
  games: IconType.gamepad,
  travel: IconType.plane,
  religion: IconType.placeOfWorship,
  shopping: IconType.cartShopping,
  employment: IconType.building,
  webmail: IconType.envelope,
  forums: IconType.peopleGroup,
  socialNetwork: IconType.shareNodes,
  chat: IconType.comments,
  fileSharing: IconType.folderOpen,
  gambling: IconType.slotMachine,
  proxiesLoopholes: IconType.server,
  violence: IconType.handFist,
  weapons: IconType.gun,
  profanity: IconType.faceSwear,
  matureContent: IconType.timesCircle,
  pornography: IconType.xmarksLines,
  alcohol: IconType.beerMug,
  drugs: IconType.pills,
  tobacco: IconType.smoking,
};

export const isSafetyNet = (categories: EventRecord['categories']): boolean =>
  Boolean(
    categories.find(category => category?.id === hiddenCategories.safetyNet)
  );

export const harmfulWebCategories: WebCategory[] = [
  'gambling',
  'proxiesLoopholes',
  'violence',
  'weapons',
  'profanity',
  'matureContent',
  'pornography',
  'alcohol',
  'drugs',
  'tobacco',
];
