import { connect } from 'react-redux';
import * as R from 'ramda';
import { DeviceRecord } from '../records';
import {
  getDevice,
  isLastDeviceOfSortedDevices,
  isFirstDeviceOfSortedDevices,
  getNextDeviceFromSortedDevices,
  getPrevDeviceFromSortedDevices,
  isSingleDevice,
  isMultiDevices,
} from '../selectors';
import SelectionBar from '../components/base/SelectionBar';
import ft, { DISABLE_MULTIDEVICES } from '../lib/ft';

const mapStateToProps = (state, { profileId, deviceId }) => ({
  hasNext: !isLastDeviceOfSortedDevices(state, profileId, deviceId),
  hasPrev: !isFirstDeviceOfSortedDevices(state, profileId, deviceId),
  title: R.defaultTo(DeviceRecord(), getDevice(state, deviceId)).name,
  hidden:
    ft.active(DISABLE_MULTIDEVICES) ||
    isSingleDevice(state, profileId) ||
    !isMultiDevices(state, profileId),
});

const mapDispatchToProps = (
  dispatch,
  { profileId, deviceId, onChangeDevice }
) => ({
  onClickNext: () =>
    dispatch((_dispatch, getState) => {
      const nextDevice = getNextDeviceFromSortedDevices(
        getState(),
        profileId,
        deviceId
      );
      if (nextDevice) {
        onChangeDevice(nextDevice);
      }
    }),
  onClickPrev: () =>
    dispatch((_dispatch, getState) => {
      const prevDevice = getPrevDeviceFromSortedDevices(
        getState(),
        profileId,
        deviceId
      );
      if (prevDevice) {
        onChangeDevice(prevDevice);
      }
    }),
});

const DeviceSelectionBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionBar);

export default DeviceSelectionBarContainer;
