import { connect } from 'react-redux';
import DashboardWidgetsFamily from '../../../components/Dashboard/DashboardWidgets/DashboardWidgetsFamily';
import { getActivitySwiperIndex, getProfile } from '../../../selectors';

import State from '../../../store/state';
import ft, { DISABLE_CALLS_SMS_RULES } from '../../../lib/ft';
import flags from '../../../sideEffects/flags';

interface ProfileDashboardWidgetsFamilyContainerExtraProps {
  profileId: number;
}
const mapStateToProps = (
  state: State,
  { profileId }: ProfileDashboardWidgetsFamilyContainerExtraProps
) => {
  return {
    profileId,
    profile: getProfile(state, profileId),
    showCallsSMSCard: !ft.active(DISABLE_CALLS_SMS_RULES),
    showYoutubeCard: flags.youtubeSummary.isEnabled(),
    activitySwiperIndex: getActivitySwiperIndex(state),
    showInsights: flags.insights.isEnabled(),
  };
};

const mapDispatchToProps = () => ({});

const ProfileDashboardWidgetsFamilyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardWidgetsFamily);

export type DashboardWidgetsFamilyContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default ProfileDashboardWidgetsFamilyContainer;
