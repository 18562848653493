import { connect } from 'react-redux';
import { loadBlockedWebActivityCard } from '../../ducks/summary/actions';
import State, { Dispatch } from '../../store/state';
import WebsiteBlockedWidget from '../../components/ActivitySummary/WebsiteBlockedWidget';
import {
  getSummaryBlockedWebActivity,
  getSummaryBlockedWebError,
  getSummaryBlockedWebIsLoading,
} from '../../selectors/summary';
import { navigateToBlockedWebActivity } from '../../actions/ProfileActions';
import { ButtonNames, trackButtonClicked } from '../../helpers/analytics';
import { getCurrentDashboardPageName } from '../../ducks/unifiedNowCard/thunks';
import { isProfileDelegatedToThisAccount } from '../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { getProfileId } from '../../selectors';
import {
  getIsProfileLinkedToLW,
  getIsSchoolAndFamilyProfile,
} from '../Dashboard/helpers/profileDashboardHelpers';

interface WebsitesBlockedWidgetContainerProps {
  profileId: string;
}

const mapStateToProps = (state: State) => {
  const totalOfBlockedSites = getSummaryBlockedWebActivity(state)
    .toArray()
    .reduce((acc, cur) => {
      return acc + cur.pages;
    }, 0);
  const delegationEnabled = isDelegationEnabled(state);
  const optInByThisAccount = isStudentOptInForDelegationForThisAccount(state);
  const profileId = Number(getProfileId(state));
  const isLinkedToLinewize = getIsProfileLinkedToLW(state, profileId);
  const delegatedToThisAccount = isProfileDelegatedToThisAccount(
    delegationEnabled,
    optInByThisAccount,
    isLinkedToLinewize
  );
  const isCombinedProfile = getIsSchoolAndFamilyProfile(state, profileId);

  return {
    totalBlockedSites: totalOfBlockedSites,
    isFetchingInfo: getSummaryBlockedWebIsLoading(state),
    hasError: getSummaryBlockedWebError(state),
    combinedProfileWithoutDelegation:
      isCombinedProfile && !delegatedToThisAccount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profileId }: WebsitesBlockedWidgetContainerProps
) => ({
  loadWidget: () => {
    dispatch(loadBlockedWebActivityCard({ profileId }));
  },
  navigateToDetails: () => {
    dispatch((_dispatch, getState) => {
      const state = getState();
      const pageName = getCurrentDashboardPageName(state);

      trackButtonClicked(pageName, ButtonNames.seeAllAlertedWebsites);
    });
    dispatch(navigateToBlockedWebActivity(profileId));
  },
});

const WebsiteBlockedWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteBlockedWidget);

export type WebsiteBlockedWidgetProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default WebsiteBlockedWidgetContainer;
