export enum FamilyLocatorScreen {
  ProfilesList = 'Profiles List',
  KidTimeline = 'Kid Timeline',
  MaximizedMap = 'Maximized Map',
  AddPlace = 'Add Place',
  AddNewPlace = 'Add New Place',
  EditPlace = 'Edit Place',
}

export enum RouteActions {
  addPlace = 'addPlace',
  editPlace = 'editPlace',
  fullMap = 'fullMap',
}

export interface PlaceAddressData {
  id: string;
  main_text: string;
  secondary_text: string;
}
