import { Record } from 'immutable';
import {
  ProductAddonsOffer,
  Addons,
} from '../../records/productAddonsOffer/types';

export const requestProductAddonsOfferAction = (addons: Array<Addons>) => ({
  type: 'REQUEST_PRODUCT_ADDONS_OFFER' as const,
  payload: { addons },
});

export const receiveProductAddonsOfferAction = (
  products: Array<Record<ProductAddonsOffer>>
) => ({
  type: 'RECEIVE_PRODUCT_ADDONS_OFFER' as const,
  payload: { products },
});
