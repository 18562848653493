import { Record, Map } from 'immutable';

type AccountOptions = {
  flags: Map<string, string>;
  family_locator_visited: boolean;
  web_onboarding_finished: boolean;
  rating_popup_last_date: string;
  additional_parent_welcome_modal_viewed: boolean;
  multiparent_feature_flag_enabled: boolean;
  delegation_rules_modal_dismissed: boolean;
  additional_parent_delegation_rules_modal_dismissed: boolean;
};

export type AccountOptionsRecord = Record<AccountOptions>;

export const AccountOptionsRecord = Record<
  AccountOptions,
  {
    fromPayload: (response: any) => AccountOptionsRecord;
  }
>({
  flags: Map(),
  family_locator_visited: false,
  web_onboarding_finished: false,
  rating_popup_last_date: '',
  additional_parent_welcome_modal_viewed: false,
  multiparent_feature_flag_enabled: false,
  delegation_rules_modal_dismissed: false,
  additional_parent_delegation_rules_modal_dismissed: false,
});

AccountOptionsRecord.fromPayload = (payload: any) => {
  return AccountOptionsRecord({
    ...payload,
  });
};

export default AccountOptionsRecord;
