import { List } from 'immutable';
import { Moment } from 'moment';
import * as React from 'react';
import {
  FlexLayout,
  StyledHeader,
  EmptyPlaceholder,
  Layout,
  Spinner,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile';
import LocationList from '../LocationList/LocationList';
import { DayRecord } from '../../selectors/event';

interface KidLocationsHistoryProps {
  locale: string;
  country: string;
  profile: ProfileRecord;
  hasIrregularStatus: boolean;
  statusCard: JSX.Element;
  detailedStatusCard: JSX.Element;
  days: List<DayRecord>;
  currentTime: Moment;
  timezone: string;
  hasNoEvents: boolean;
  hasMoreEvents: boolean;
  isFetching: boolean;
  showFetchingMore: boolean;
  handleMore: (profileId: number) => void;
  clickLocation: (event: any) => any;
}

const KidLocationsHistory: React.FunctionComponent<
  KidLocationsHistoryProps
> = ({
  locale,
  country,
  profile,
  hasIrregularStatus,
  statusCard,
  detailedStatusCard,
  days,
  currentTime,
  timezone,
  hasNoEvents,
  hasMoreEvents,
  isFetching,
  showFetchingMore,
  handleMore,
  clickLocation,
}) => {
  const renderEmpty = () => (
    <FlexLayout mainaxis="column" flexGrow="1">
      {hasIrregularStatus && detailedStatusCard !== null ? (
        <FlexLayout
          mainaxis="column"
          flexGrow="1"
          crossaxisAlignment="center"
          marginTop="40"
          paddingLeft="8"
          paddingRight="8"
        >
          {detailedStatusCard}
        </FlexLayout>
      ) : (
        <FlexLayout
          mainaxis="column"
          flexGrow="1"
          crossaxisAlignment="center"
          marginTop="40"
          paddingTop="72"
          paddingLeft="8"
          paddingRight="8"
        >
          <Layout marginBottom="16">
            <StyledHeader type="h4">
              {t("{{kidName}}'s location history", { kidName: profile.name })}
            </StyledHeader>
          </Layout>

          <EmptyPlaceholder
            text={t(
              'Here you will see where the the kid {{kidName}} has been',
              { kidName: profile.name }
            )}
          />
        </FlexLayout>
      )}
    </FlexLayout>
  );

  return (
    <React.Fragment>
      {hasNoEvents ? (
        renderEmpty()
      ) : (
        <FlexLayout mainaxis="column" matchParentHeight>
          {isFetching ? (
            <FlexLayout
              mainaxis="column"
              mainaxisAlignment="center"
              crossaxisAlignment="center"
              flexGrow="1"
            >
              <Spinner size="small" color="contrast" />
            </FlexLayout>
          ) : (
            <React.Fragment>
              {hasIrregularStatus && statusCard}
              <LocationList
                days={days}
                currentTime={currentTime}
                timezone={timezone}
                locale={locale}
                country={country}
                showFetchingMore={showFetchingMore}
                handleMore={() => handleMore(profile.id)}
                kidName={profile.name}
                hasMoreEvents={hasMoreEvents}
                clickLocation={clickLocation}
              />
            </React.Fragment>
          )}
        </FlexLayout>
      )}
    </React.Fragment>
  );
};

export default KidLocationsHistory;
