import { NonEmptyMapRecord, fromJS } from 'immutable';
import * as Moment from 'moment-timezone';
import { ISODateStringFromMoment } from '../helpers/dates';
import {
  getCurrentTime,
  getLastInteractionTs,
  isAuthenticated,
} from '../selectors';
import { logout } from '../sideEffects/logout';
import { setInterval, now, clearInterval } from '../lib/timeout';
import { SessionTimeoutError } from '../lib/errors';
import { BaseThunk } from '../store/state';
import { notifyLogoutAction } from '../epics/contentCommunication/actions';

export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const TICK = 'TICK';

export default function times(
  state: NonEmptyMapRecord<{
    currentTime: ISODateString;
  }> = fromJS({
    currentTime: ISODateStringFromMoment(Moment(now())),
  }),
  action
) {
  switch (action.type) {
    case UPDATE_CURRENT_TIME:
      return state.merge({
        currentTime: action.payload,
      });

    default:
      return state;
  }
}

/**
 * Parameter is `never` because it is only used by some tests, that should have mocked `now()` instead.
 */
export function updateCurrentTime(time: never = Moment(now()) as never) {
  return {
    type: UPDATE_CURRENT_TIME,
    payload: ISODateStringFromMoment(time),
  };
}

/**
 * Shortcut to update current time and return it
 */
export const currentTime =
  (): BaseThunk<Moment.Moment> => (dispatch, getState) => {
    dispatch(updateCurrentTime());
    return getCurrentTime(getState());
  };

/**
 * Start ticking each `interval` milliseconds.
 * Will dispatch TICK each interval with UTC time as string, and checks
 * lastInteractionTs against current time to logout user after sessionTimeoutMs
 * of inactivity.
 */
let tickIntervalId;
export function tick(
  interval: number,
  sessionTimeoutMs: number | undefined
): BaseThunk<Promise<undefined | void>> {
  return (dispatch, getState) => {
    const lastInteractionTs = getLastInteractionTs(getState());
    if (isSessionExpired(sessionTimeoutMs, lastInteractionTs)) {
      dispatch(notifyLogoutAction());
      return Promise.reject(new SessionTimeoutError());
    }

    if (shouldSetTickInterval()) {
      tickIntervalId = setInterval(() => {
        dispatch({ type: TICK });

        const state = getState();

        if (!isAuthenticated(state)) {
          tickIntervalId = clearInterval(tickIntervalId);
          return;
        }

        if (isSessionExpired(sessionTimeoutMs, getLastInteractionTs(state))) {
          dispatch(notifyLogoutAction());
          dispatch(logout());
          tickIntervalId = clearInterval(tickIntervalId);
        }
      }, interval);
    }

    return Promise.resolve(undefined);
  };
}

export const shouldSetTickInterval = () => tickIntervalId === undefined;

const isSessionExpired = (
  sessionTimeoutMs: number | undefined,
  lastInteractionTs: Moment.Moment | undefined
) =>
  sessionTimeoutMs !== undefined &&
  lastInteractionTs !== undefined &&
  Moment(now()).utc().diff(lastInteractionTs) > sessionTimeoutMs;
