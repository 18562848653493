import * as React from 'react';
import {
  Card,
  FlexLayout,
  FreeStyleListItem,
  EmptyPlaceholder,
  List,
  ListTitle,
  Svg,
  Icon,
  IconType,
} from 'styleguide-react';
import type { StudentCategoriesCardProps } from '../../containers/Schools/StudentCategoriesCardWithObserverContainer';
import { classNames } from '../../helpers';
import { t } from '../../lib/i18n';
import { CategoriesActivity } from '../../records/studentActivity/types/categories.types';
import InfoIcon from '../Icons/InfoIcon';
import SchoolFeatureDisabled from './SchoolFeatureDisabled';
import LoadingContent from '../Dashboard/Loading/LoadingContent';
import StudentSummaryError from './StudentsSummaryError';

const StudentCategoriesCardWithObserver: React.FunctionComponent<
  StudentCategoriesCardProps
> = ({
  categoriesActivity,
  isFeatureDisabled,
  onClickInfo,
  onClickSeeAll,
  maxCategoriesActivityItems = Infinity,
  single = false,
  isLoadingCategories,
  profileName,
  errorOnLoading,
  loadInfo,
  onClickContactUs,
}: StudentCategoriesCardProps) => {
  React.useEffect(() => {
    loadInfo();
  }, []);
  const categoriesActivitySelected = categoriesActivity
    .filter(categoryActivty => categoryActivty.usagePercentage > 0)
    .take(maxCategoriesActivityItems);

  const cardContent = () => {
    if (isFeatureDisabled) {
      return <SchoolFeatureDisabled />;
    }
    if (errorOnLoading) {
      return (
        <StudentSummaryError
          error={errorOnLoading}
          profileName={profileName}
          onClickContactUs={onClickContactUs}
        />
      );
    }
    if (isLoadingCategories) {
      return (
        <LoadingContent
          text={t('Loading {{profileName}} categories', {
            profileName,
          })}
        />
      );
    }
    if (categoriesActivitySelected.size) {
      return (
        <List>
          {categoriesActivitySelected.map((data: CategoriesActivity) => (
            <FreeStyleListItem
              key={data.categoryName}
              className="CategoryListItem"
            >
              <CategoryListItem data={data} />
            </FreeStyleListItem>
          ))}
        </List>
      );
    }
    return (
      <EmptyPlaceholder
        icon={
          <Svg height="64" width="64" color="gray-light">
            <InfoIcon />
          </Svg>
        }
        fillAvailableSpace
        centered
        smallText
        text={t('No activity during this period')}
      />
    );
  };

  return (
    <Card
      className={classNames(
        'StudentSummary__card',
        single ? 'StudentSummary__card--single' : ''
      )}
      header={{
        title: t('Most used categories'),
        actionElement: (
          <Icon
            type={IconType.infoCircle}
            testId="student-most-used-categories-card-info"
          />
        ),
        onClickAction: onClickInfo,
      }}
      footer={
        categoriesActivitySelected.size &&
        maxCategoriesActivityItems !== Infinity
          ? { actionElement: t('See all'), onClickAction: onClickSeeAll }
          : undefined
      }
    >
      {cardContent()}
    </Card>
  );
};

type CategoryIconProps = {
  iconUrl: string;
  categoryName: string;
};

const CategoryIcon: React.FunctionComponent<CategoryIconProps> = ({
  iconUrl,
  categoryName,
}: CategoryIconProps) => (
  <Svg>
    <img src={iconUrl} role="presentation" alt={categoryName} />
  </Svg>
);

type CategoryListItemProps = {
  data: CategoriesActivity;
};

// TODO: This is a temporary ListItem we should remake the current listItems
export const CategoryListItem: React.FunctionComponent<
  CategoryListItemProps
> = ({ data }: CategoryListItemProps) => (
  <React.Fragment>
    <FlexLayout mainaxis="row" mainaxisAlignment="space-between">
      <FlexLayout mainaxis="row">
        <div className="CategoryListItem__icon">
          <CategoryIcon
            iconUrl={data.parentCategoryIcon}
            categoryName={data.parentCategoryName}
          />
        </div>
        <ListTitle isBold={false}>{data.categoryName}</ListTitle>
      </FlexLayout>
      <p className="CategoryListItem__percentage">{`${data.usagePercentage}%`}</p>
    </FlexLayout>
  </React.Fragment>
);

export default StudentCategoriesCardWithObserver;
