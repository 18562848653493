import { connect } from 'react-redux';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import { getQueryParam } from '../../selectors/routing';
import { navigateToExternalNoActivityHelp } from '../../actions/Navigation';
import NowCardInfoModal from '../../components/Modal/NowCardInfoModal';
import { getTimezone } from '../../selectors/account';
import { getCurrentTime, getDevice, getProfile } from '../../selectors';
import { convertDate, daysDiff } from '../../helpers/dates';
import { UnifiedNowCardState } from '../../businessLogic/unifiedNowCard/types';

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const profile = getProfile(state, profileId);
  const status = getQueryParam(state, 'state') as UnifiedNowCardState;
  const deviceId = Number(getQueryParam(state, 'id'));
  const timezone = getTimezone(state);
  const device = deviceId ? getDevice(state, deviceId) : undefined;
  const daysUntilLastSeen = device?.lastseen
    ? daysDiff(convertDate(device?.lastseen, timezone), getCurrentTime(state))
    : 0;

  return {
    status,
    daysUntilLastSeen,
    profileName: profile?.name,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onNavigateToNoActivityHelp: () => {
      dispatch(navigateToExternalNoActivityHelp());
    },
  };
};

const NowCardInfoModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NowCardInfoModal);

export type NowCardInfoModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default NowCardInfoModalContainer;
