import { Record } from 'immutable';
import { DeviceSettingsPlatformRecord } from './devicePlatform';
import { DeviceSettingsPlatformRecord as DeviceSettingsPlatformRecordType } from './types/DevicePlatform';
import {
  DeviceSettings,
  DeviceSettingsMethods,
  DeviceSettingsRecord as DeviceSettingsRecordType,
} from './types/DeviceSettings.types';

export const DeviceSettingsRecord = Record<
  DeviceSettings,
  DeviceSettingsMethods
>({
  platform: DeviceSettingsPlatformRecord() as DeviceSettingsPlatformRecordType,
});

DeviceSettingsRecord.fromPayload = payload =>
  DeviceSettingsRecord({
    platform: DeviceSettingsPlatformRecord.fromPayload(payload?.platform ?? {}),
  }) as DeviceSettingsRecordType;

DeviceSettingsRecord.serialize = record => ({
  ...record.toJS(),
});
