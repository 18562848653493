import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Button,
  ButtonSize,
  Icon,
  IconType,
  Modal,
  Typography,
} from 'styleguide-react';

const HowToAssignUsersModal: React.FunctionComponent<{
  onClickClose: () => void;
}> = ({ onClickClose }) => (
  <Modal
    onClickClose={onClickClose}
    title={t('Assign all computer user accounts to a child')}
    header={{
      icon: <Icon type={IconType.laptop} />,
    }}
    size="small"
    buttons={[
      <Button
        onClick={onClickClose}
        size={ButtonSize.medium}
        block
        key="HowAssignUserModal__button"
      >
        {t('Ok')}
      </Button>,
    ]}
  >
    <Typography type="body1">
      {t(
        'Select a child and {{shortName}} will apply their rules to all computer accounts, including new and guest accounts.'
      )}
    </Typography>
  </Modal>
);

export default HowToAssignUsersModal;
