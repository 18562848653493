import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  devicesString,
  profilesString,
  LicenseRecord,
  getProperLicenseText,
} from '../../records/license';
import {
  Modal,
  ModalStyledText,
  Icon,
  IconType,
  Button,
  ButtonSize,
} from 'styleguide-react';
import { modalDimensions } from '../../constants';
import { isBrowserPlatform } from '../../helpers/index';

const ExpandPlanModal: React.FunctionComponent<{
  license: LicenseRecord;
  shouldShowContactInfo: boolean;
  onClickClose: () => any;
  onClickLink: () => any;
}> = ({ license, onClickClose, onClickLink }) => (
  <Modal
    onClickClose={onClickClose}
    width={modalDimensions.regular.width}
    header={{
      icon: <Icon type={IconType.rocket} />,
    }}
    title={t('Expand your plan')}
    buttons={
      isBrowserPlatform()
        ? [
            <Button
              key="button"
              size={ButtonSize.medium}
              block
              onClick={onClickLink}
            >
              {t('Expand Now')}
            </Button>,
          ]
        : undefined
    }
  >
    {isBrowserPlatform() && (
      <React.Fragment>
        {getProperLicenseText(
          license.subtype,
          <React.Fragment>
            <ModalStyledText testId="plan-limits">
              {t(
                'Your current plan limits you to core protection for up to {{maxDevices}}.',
                {
                  maxDevices: devicesString(license),
                }
              )}
            </ModalStyledText>
            <ModalStyledText testId="plan-limits-2">
              {t(
                'Expand your plan to access advanced safety features for an unlimited number of devices.'
              )}
            </ModalStyledText>
          </React.Fragment>,
          <ModalStyledText testId="plan-limits">
            {t(
              'Your current plan gives you advanced safety for all your family devices.'
            )}
          </ModalStyledText>,
          <ModalStyledText testId="plan-limits">
            {t(
              'Your current plan limits protection to {{maxDevices}} and {{maxProfiles}}.',
              {
                maxDevices: devicesString(license),
                maxProfiles: profilesString(license),
              }
            )}
          </ModalStyledText>
        )}
      </React.Fragment>
    )}

    {!isBrowserPlatform() && (
      <React.Fragment>
        {getProperLicenseText(
          license.subtype,
          <React.Fragment>
            <ModalStyledText testId="plan-limits-mobile">
              {t(
                'Your current plan limits you to core protection for up to {{maxDevices}}.',
                {
                  maxDevices: devicesString(license),
                }
              )}
            </ModalStyledText>
            <ModalStyledText testId="plan-limits-mobile-2">
              {t(
                'Contact us to expand your plan and access advanced safety features for an unlimited number of devices.'
              )}
            </ModalStyledText>
          </React.Fragment>,
          <React.Fragment>
            <ModalStyledText testId="plan-limits-mobile">
              {t(
                'Your current plan gives you advanced safety for all your family devices.'
              )}
            </ModalStyledText>
            <ModalStyledText testId="plan-limits-mobile-2">
              {t(
                'Contact us to expand your plan and access advanced safety features for an unlimited number of devices.'
              )}
            </ModalStyledText>
          </React.Fragment>,
          <ModalStyledText testId="plan-limits-mobile">
            {t(
              'Your current plan limits protection to {{maxDevices}} and {{maxProfiles}}. Contact us to switch to a bigger plan.',
              {
                maxDevices: devicesString(license),
                maxProfiles: profilesString(license),
              }
            )}
          </ModalStyledText>
        )}
      </React.Fragment>
    )}
  </Modal>
);

export default ExpandPlanModal;
