import { fromJS, List, NonEmptyMapRecord } from 'immutable';
import { ProductDuration } from '../../records/product';
import { ProductsAction } from './types/actions.types';

export const initialState = {
  defaultProductCode: null,
  result: List<string>(),
  isFetching: false,
  isValidating: false,
  error: null,
  loaded: false,
  notification: null,
  selectedProductDuration: ProductDuration.OneYear,
  selectedProduct: '',
  isPurchaseInProgress: false,
  lastProductsRequested: 'default',
};

export default function products(
  state: NonEmptyMapRecord<{
    defaultProductCode: string | null;
    result: List<string>;
    isFetching: boolean;
    isValidating: boolean;
    error: string | null;
    loaded: boolean;
    notification: null;
    selectedProductDuration: ProductDuration;
    selectedProduct: string;
    isPurchaseInProgress: boolean;
    lastProductsRequested: string;
  }> = fromJS(initialState),
  action: ProductsAction
) {
  switch (action.type) {
    case 'REQUEST_PRODUCTS':
      return state.merge({
        isFetching: true,
        lastProductsRequested: action.payload,
      });
    case 'RECEIVE_PRODUCTS':
      return state.merge({
        isFetching: false,
        loaded: true,
        ...action.payload,
      });
    case 'REQUEST_PRODUCTS_ERROR':
      return state.merge({
        isFetching: false,
      });
    case 'START_VALIDATION':
      return state.merge({
        isValidating: true,
      });
    case 'FINISH_VALIDATION':
      return state.merge({
        isValidating: false,
      });
    case 'TIMEOUT_VALIDATION':
      return state.merge({
        isValidating: false,
        error: 'Validation timeout',
      });
    case 'PRODUCT_DURATION_CHANGED':
      return state.merge({
        selectedProductDuration: action.payload,
      });
    case 'PRODUCT_CHANGED':
      return state.merge({
        selectedProduct: action.payload,
      });
    case 'START_PURCHASE_PROCESS':
      return state.set('isPurchaseInProgress', true);
    case 'FINISH_PURCHASE_PROCESS':
      return state.set('isPurchaseInProgress', false);
    default:
      return state;
  }
}
