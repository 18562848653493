import { showStatsNotification } from '../../../actions/ProfileActions';
import type { LogBookTopBarProps } from './types';

/*
Given a kid’s profile with the existing conditions to show the logbook when 
accessing the playground url and no tampering topbar is displayed then the logbook topbar is displayed

Given a kid’s profile with the existing conditions to show the logbook 
when accessing the playground url and the tampering topbar is displayed then the logbook topbar is not displayed

Given the logbook topbar is displayed when the users clicks on it 
then they’re redirected to the web logbook summary
*/

export const isVisibleLogBookTapBar = ({
  license,
  currentTime,
  timezone,
}: LogBookTopBarProps) => showStatsNotification(license, currentTime, timezone);
