/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import UpgradeContent from './UpgradeContent';

import { t } from '../../lib/i18n';
import PageLayout from '../Layout/PageLayout';
import type { UpgradeProps } from '../../containers/UpgradeContainer';
import { isPremiumGrandfather } from '../../records/license/helpers';
import UpgradeContentB from './newExperience/UpgradeContentB';
import Loader from '../base/Loader';
import { FlexLayout } from 'styleguide-react';

const Upgrade = (props: UpgradeProps) => {
  const { isRenew, areUpsellProducts, license, isFetching, productList } =
    props;
  return (
    <PageLayout
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
        },
        {
          screenSize: ScreenSize.MobileOrTablet,
          title: isRenew && !areUpsellProducts ? t('Renew') : t('Upgrade'),
          mainColumnBackgroundColor: 'gray-ligther',
          removePaddings: true,
        },
      ]}
      actions={[
        {
          actionName: 'back',
          screenSize: ScreenSize.MobileOrTablet,
        },
      ]}
    >
      {isPremiumGrandfather(license.subtype) ? (
        <UpgradeContent {...props} />
      ) : isFetching || productList.size === 0 ? (
        <FlexLayout
          mainaxis="column"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
          height="100%"
        >
          <Loader size="small" color="secondary" />
        </FlexLayout>
      ) : (
        <UpgradeContentB {...props} />
      )}
    </PageLayout>
  );
};

export default Upgrade;
