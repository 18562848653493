import { t } from '../../../../lib/i18n';
import { RoutineColor } from '../../../../palettes/RoutineColor';
import { RoutineIconType } from '../../../../records/routines/types/Routine.types';
import { RoutinePresetType, routinePresets } from '../../helpers/presets';
import { getTimeSlotWeekDays, getTimeSlotWeekend } from '../../helpers/steps';
import {
  ContextContentFilter,
  ContextStyle,
  MultiStepDataContext,
  TimeSlot,
} from '../../routines.types';
import { SelectPresetProps } from './types/SelectPresetProps.types';
import { isAppRuleDetectedBrowser } from '../../../../helpers/appRules';
import { getHarmfulWebRuleExceptions } from '../../../../containers/Routines/helpers';

type Options = { appRulesList: SelectPresetProps['appRulesList'] };

export const getPresetIcon = (preset: RoutinePresetType): RoutineIconType => {
  const iconsMap: Record<RoutinePresetType, RoutineIconType> = {
    [routinePresets.Study]: 'bookOpen',
    [routinePresets.Entertainment]: 'gamepadModern',
    [routinePresets.Focus]: 'bullseye',
    [routinePresets.Bedtime]: 'moon',
  };

  return iconsMap[preset];
};

export const getPresetColor = (preset: RoutinePresetType): RoutineColor => {
  const colorMap: Record<RoutinePresetType, RoutineColor> = {
    [routinePresets.Study]: RoutineColor.yellow,
    [routinePresets.Entertainment]: RoutineColor.green,
    [routinePresets.Focus]: RoutineColor.blue,
    [routinePresets.Bedtime]: RoutineColor.marine,
  };

  return colorMap[preset];
};

export const getPresetTitle = (preset: RoutinePresetType): string =>
  ({
    [routinePresets.Study]: t('Study'),
    [routinePresets.Entertainment]: t('Entertainment'),
    [routinePresets.Focus]: t('Focus'),
    [routinePresets.Bedtime]: t('Bedtime'),
  }[preset]);

export const getPresetSubtitle = (preset: RoutinePresetType): string =>
  ({
    [routinePresets.Study]: t('Only educational websites'),
    [routinePresets.Entertainment]: t('All games & apps allowed'),
    [routinePresets.Focus]: t('No internet connection'),
    [routinePresets.Bedtime]: t('Devices blocked completely'),
  }[preset]);

const getContextStyle = (preset: RoutinePresetType): ContextStyle => ({
  name: getPresetTitle(preset),
  description: getPresetSubtitle(preset),
  color: getPresetColor(preset),
  icon: getPresetIcon(preset),
});

const getContextType = (
  preset: RoutinePresetType
): MultiStepDataContext['type'] => {
  const colorMap: Record<RoutinePresetType, MultiStepDataContext['type']> = {
    [routinePresets.Study]: 'ROUTINE_TYPE_MANAGEMENT',
    [routinePresets.Entertainment]: 'ROUTINE_TYPE_MANAGEMENT',
    [routinePresets.Focus]: 'ROUTINE_TYPE_BLOCKED',
    [routinePresets.Bedtime]: 'ROUTINE_TYPE_BLOCKED',
  };

  return colorMap[preset];
};

const getContextBlockType = (
  preset: RoutinePresetType
): MultiStepDataContext['block'] => {
  const blockMap: Record<RoutinePresetType, MultiStepDataContext['block']> = {
    [routinePresets.Study]: 'POLICY_BLOCK_TYPE_NONE',
    [routinePresets.Entertainment]: 'POLICY_BLOCK_TYPE_NONE',
    [routinePresets.Focus]: 'POLICY_BLOCK_TYPE_LOCK_NAVIGATION',
    [routinePresets.Bedtime]: 'POLICY_BLOCK_TYPE_LOCK_DEVICE',
  };

  return blockMap[preset];
};

const getContextTimeSlot = (preset: RoutinePresetType): Array<TimeSlot> => {
  const colorMap: Record<RoutinePresetType, TimeSlot> = {
    [routinePresets.Study]: {
      from: '09:00',
      to: '17:00',
      days: getTimeSlotWeekDays(),
    },
    [routinePresets.Entertainment]: {
      from: '09:00',
      to: '19:00',
      days: getTimeSlotWeekend(),
    },
    [routinePresets.Focus]: {
      from: '17:00',
      to: '19:00',
      days: getTimeSlotWeekDays(),
    },
    [routinePresets.Bedtime]: {
      from: '21:00',
      to: '07:00',
      days: ['MO', 'TU', 'WE', 'TH', 'SU'],
    },
  };

  return [colorMap[preset]];
};

const getContextContentFilter = (
  preset: RoutinePresetType,
  options: Options
): ContextContentFilter => {
  const defaultValues: ContextContentFilter = {
    activeTab: 'APP',
    timeLimitType: 'DONT_APPLY_DAILY_TIME_LIMIT',
    webRuleType: 'DEFAULT',
    appRuleType: 'DEFAULT',
    appsException: [],
    websException: [],
  };

  const contentFilterMap: Record<RoutinePresetType, ContextContentFilter> = {
    [routinePresets.Study]: {
      ...defaultValues,
      appRuleType: 'POLICY_ACTION_BLOCKED',
      webRuleType: 'POLICY_ACTION_BLOCKED',
      appsException:
        options?.appRulesList
          ?.filter(isAppRuleDetectedBrowser)
          .map(({ name, exe, platform }) => ({
            name,
            exe,
            platform,
            action: 'POLICY_ACTION_ALLOWED',
          }))
          .toJS() || [],
      websException: [
        {
          category: 'educational',
          action: 'POLICY_ACTION_ALLOWED',
        },
        {
          category: 'searchPortals',
          action: 'POLICY_ACTION_ALLOWED',
        },
      ],
    },
    [routinePresets.Entertainment]: {
      ...defaultValues,
      appRuleType: 'POLICY_ACTION_ALLOWED',
      webRuleType: 'POLICY_ACTION_ALLOWED',
      websException: getHarmfulWebRuleExceptions('POLICY_ACTION_BLOCKED'),
    },
    [routinePresets.Focus]: defaultValues,
    [routinePresets.Bedtime]: defaultValues,
  };

  return contentFilterMap[preset];
};

export const getPresetContext = (
  preset: RoutinePresetType,
  options: Options
): MultiStepDataContext => {
  const contextMap: MultiStepDataContext = {
    mode: 'CREATE',
    paused: false,
    finished: false,
    type: getContextType(preset),
    style: getContextStyle(preset),
    timeSlot: getContextTimeSlot(preset),
    block: getContextBlockType(preset),
    contentFilter: getContextContentFilter(preset, options),
  };

  return contextMap;
};
