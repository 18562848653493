import { connect } from 'react-redux';
import { PageNames } from '../helpers/analytics';
import {
  showTwoYearsPromotionModal,
  clickProduct,
  clickPremiumFeatures,
} from '../actions/UpgradeActions';

import Upgrade from '../components/Upgrade/Upgrade';
import {
  getAccount,
  getLicense,
  getSelectedProductList,
  getPaymentProvider,
  canPurchase,
  getSelectedProductDuration,
  areUpsellProducts,
  isPurchaseInProgress,
  getAreTwoYearProductsAvailable,
  getRouteParams,
} from '../selectors';
import { isPremium } from '../records/license';
import { fetchProducts } from '../ducks/products';
import { fetchAttributionLastSource } from '../ducks/attribution';
import { isBrowserPlatform } from '../helpers';
import { trackablePage } from '../helpers/hocs';
import { ProductDuration, ProductRecord } from '../records/product';
import { getFeatureFlag } from '../selectors/featureFlags';
import {
  getSelectedProductWithDedicatedSupportList,
  getProductListFilteredByIfIsDedicatedSupport,
  isFetchingProducts,
  filterProductsByPaymentProvider,
  shouldRequestDefaultProducts,
} from '../selectors/product';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { goBack } from '../lib/wrappers';
import State from '../store/state';
import { orderProductsByPreferences } from '../businessLogic/products/products';
import { getBuildPlatform } from '../helpers/globals';
import flags from '../sideEffects/flags';
import { List } from 'immutable';
import { ScreenSize, hasScreenSize } from '../components/RenderWhen/RenderWhen';

const orderProducts = (products: List<ProductRecord>) => {
  return orderProductsByPreferences(products, {
    platform: getBuildPlatform(),
    switchPlansOnAndroid: flags.switchPlansOnPASA.isEnabled(),
    isMobileScreen: hasScreenSize(ScreenSize.Mobile),
  });
};

const getProductList = (state: State) => {
  const products = areUpsellProducts(state)
    ? getProductListFilteredByIfIsDedicatedSupport(state)
    : filterProductsByPaymentProvider(
        getSelectedProductList(state),
        getPaymentProvider(state)
      );

  return orderProducts(products);
};

const getProductListWithDedicatedSupport = (state: State) => {
  const products = filterProductsByPaymentProvider(
    getSelectedProductWithDedicatedSupportList(state),
    getPaymentProvider(state)
  );

  return orderProducts(products);
};

const mapStateToProps = state => {
  return {
    isFetching: isFetchingProducts(state),
    isRenew: isPremium(getLicense(state).type),
    paymentProvider: getPaymentProvider(state),
    productList: getProductList(state),
    productListDedicatedSupport: getProductListWithDedicatedSupport(state),
    account: getAccount(state),
    planDuration: getSelectedProductDuration(state),
    areTwoYearProductsAvailable: getAreTwoYearProductsAvailable(state),
    areUpsellProducts: areUpsellProducts(state),
    license: getLicense(state),
    isPurchaseInProgress: isPurchaseInProgress(state),
    isProductDurationOneYear:
      getSelectedProductDuration(state) === ProductDuration.OneYear,
    isEnabledDedicatedSupportV2: getFeatureFlag(
      state,
      'dedicatedSupportV2'
    ) as boolean,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickProduct: (productId: string) => dispatch(clickProduct(productId)),
  onClickOpenLink: (link: string) => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'external:public:site',
        src: link,
        openInIOS: true,
      })
    );
  },
  onClickPremiumFeatures: () => dispatch(clickPremiumFeatures()),
  showTwoYearsPromotionModal: (productId: string) => {
    dispatch(showTwoYearsPromotionModal(productId));
  },
});

const UpgradeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(Upgrade, PageNames.Upgrade));

UpgradeContainer.load = () => (dispatch, getState) => {
  if (isBrowserPlatform()) {
    dispatch(fetchAttributionLastSource());
  }
  // We want to avoid fetching products if it is not necessary
  if (
    getRouteParams(getState()).get('promotion') !== undefined ||
    shouldRequestDefaultProducts(getState())
  ) {
    dispatch(fetchProducts());
  }
};

UpgradeContainer.redirect = () => (dispatch, getState) => {
  if (!canPurchase(getState())) {
    dispatch(goBack());
  }
};

export type UpgradeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default UpgradeContainer;
