import * as R from 'ramda';
import { Map } from 'immutable';
import { ActionPayload } from 'redux';
import { t } from '../lib/i18n';
import { redirectFreeToPremiumAction } from './RedirectFreeToPremiumAction';
import {
  getIsBlockedUnsupportedBrowsers,
  getProfileRules,
  getAppRulesApp,
  indexOfApp,
  getLicense,
} from '../selectors/index';
import { dispatchModifyProfileRules } from './ProfileRulesActions';
import {
  APPLICATION_ALLOWED,
  APPLICATION_BLOCKED,
  SettingsToggleNames,
  AppCategory,
  ApplicationAction,
} from '../constants';
import {
  setAppFilterSearch,
  setAppFilter,
  setUnsupportedBrowserNameSelected,
  delayedModifyProfileRules,
  requestModifyProfileRulesError,
  reverseProfileRulesUpdate,
  setIsAlertNewApps,
} from '../ducks/profileRules';
import { delayedModifyTargetActionCreator } from '../helpers';
import { delayedModifyTimeoutId } from '../ducks/app';
import { gaEvent } from '../helpers/ga';
import State, { BaseThunk } from '../store/state';
import { showErrorToast } from '../containers/AccountSettings/helpers';
import { trackAppRules, AppRuleNames } from '../helpers/analytics';
import { makeAppKey } from '../records/profileRules';
import { setLastRuleTypeModified, RuleType } from '../ducks/appRate';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { Weekday } from '../helpers/dates';
import { showUnlockAlertsAndTimeLimitsModal } from './PremiumFeaturesActions';
import { isSettingsToggleBlocked } from '../helpers/premiumFeatures/isSettingsToggleBlocked';
import { UnlockAlertsAndTimeLimitsModalReferrer } from '../containers/Modal/UnlockAlertsAndTimeLimitsModalContainer';
import { getFeatures } from '../ducks/features';
import { isSchoolLicenseWithValidDevices } from '../businessLogic/profileRules/delegation';

export const toggleAppRulesEnabled =
  (profileId: string, toggleOn: boolean) => async (dispatch, getState) => {
    const isDelegatedPCTypeProfile = isSchoolLicenseWithValidDevices(
      getState(),
      profileId
    );
    const isRedirectedToPremiumFlyover = !isDelegatedPCTypeProfile
      ? await dispatch(
          redirectFreeToPremiumAction(
            profileId,
            'rules/appRules/premiumFlyover',
            true
          )
        )
      : false;

    if (!isRedirectedToPremiumFlyover) {
      dispatch(setAppFilterSearch(''));
      dispatch(setLastRuleTypeModified(RuleType.GamesAndApps));
      return dispatch(
        dispatchModifyProfileRules(profileId, (state, profileId) =>
          getProfileRules(state, profileId).setIn(
            ['appRules', 'isApplicationList'],
            toggleOn
          )
        )
      );
    }
    return isRedirectedToPremiumFlyover;
  };

export const toggleAppEnabled =
  (profileId: string, appKey: string, toggleOn: boolean) =>
  (dispatch, getState) =>
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        toggleProfileRulesAppEnabled(appKey, toggleOn)
      )
    ).then(
      R.tap(() => {
        const app = getAppRulesApp(getState(), profileId, appKey);
        gaEvent(
          'apps-on-off',
          toggleOn ? 'on' : 'off',
          app ? makeAppKey(app) : ''
        );
      })
    );

export const clickCategory = (category: AppCategory) => dispatch => {
  dispatch(setAppFilterSearch(''));
  dispatch(setAppFilter(category.filter));
};

/*
  TODO: Refactor changeQuota and it's dependencies.
  The control flow introduced by it's dependencies is redundant
    and complicates the reading and modification of the code

  Only being used in youtube container
*/
export const changeQuota =
  (
    profileId: string,
    selectedDay: any,
    appKey: string,
    quota: number,
    shouldUnblockApp = false
  ) =>
  dispatch =>
    dispatch(
      dispatchModifyProfileRules(
        profileId,
        setAppQuota(appKey, selectedDay, quota, shouldUnblockApp)
      )
    );

const setAppProp = (
  state: State,
  profileId: string,
  appKey: string,
  path: string[],
  value: any
) =>
  getProfileRules(state, profileId).setIn(
    [
      'appRules',
      'applicationList',
      indexOfApp(state, profileId, appKey) as any,
      ...path,
    ],
    value
  );

const toggleProfileRulesAppEnabled =
  (appKey: string, toggleOn: boolean) => (state: State, profileId: string) =>
    setAppProp(
      state,
      profileId,
      appKey,
      ['action'],
      toggleOn ? APPLICATION_ALLOWED : APPLICATION_BLOCKED
    );

const setAppQuota =
  (
    appKey: string,
    selectedDay: string,
    quota: any,
    shouldUnblockApp: boolean
  ) =>
  (state: State, profileId: string) => {
    const appIndex = indexOfApp(state, profileId, appKey) as any;
    const profileRules = getProfileRules(state, profileId);
    let app = profileRules.get('appRules').get('applicationList').get(appIndex);

    app = app.setIn(['quotas', selectedDay], quota);

    if (shouldUnblockApp) {
      app = app.set('action', APPLICATION_ALLOWED);
    }

    return profileRules.setIn(['appRules', 'applicationList', appIndex], app);
  };

const TIMEOUT_ID_SEARCH = Symbol('TIMEOUT_ID_SEARCH');

export const delayedSearch = delay => search =>
  delayedModifyTargetActionCreator(
    delayedModifyTimeoutId,
    undefined,
    setSearchQuery,
    TIMEOUT_ID_SEARCH,
    [search],
    delay
  );

const setSearchQuery =
  (search: string): BaseThunk =>
  dispatch => {
    dispatch(setAppFilterSearch(search));
  };

export const clearSearchQuery = (): BaseThunk => dispatch => {
  dispatch(setAppFilterSearch(''));
};

export const clickApp =
  (profileId: string, appKey: string) => (dispatch, getState) => {
    const app = getAppRulesApp(getState(), profileId, appKey);
    if (!app) {
      throw new Error(`clicked on unknown app key ${appKey}`);
    }
    if (
      app.isUnsupportedBrowser &&
      getIsBlockedUnsupportedBrowsers(getState(), profileId)
    ) {
      dispatch(setUnsupportedBrowserNameSelected(app.name));
      dispatch(goToAppRulesModal(profileId));
      return;
    }
    if (app.blockable) {
      return;
    }
    gaEvent('apps-on-off', 'blocked', makeAppKey(app));
    navigator.notification.alert(
      t(
        // tslint:disable-next-line:max-line-length
        "{{shortName}} can report {{appName}}'s activity on iOS devices but can not block this application at the moment. We hope to provide you this feature as soon as possible.",
        {
          appName: app.name,
        }
      ),
      () => undefined,
      app.name,
      t('I understand')
    );
  };

export const goToAppRulesModal = (profileId: string) => dispatch => {
  const navigate = getMultiPlatformNavigation();
  return dispatch(
    navigate({
      type: 'inner',
      src: `/profiles/${profileId}/rules/appRules/modal/UnsupportedBrowsersBlockedModal`,
    })
  );
};

const setProfileRulesRecord = (
  toggleTarget: SettingsToggleNames,
  toggleOn: boolean,
  state: State,
  profileId: string
) => getProfileRules(state, profileId).setIn([toggleTarget], toggleOn);

export const toggleAppRulesSetting = (
  profileId: string,
  target: SettingsToggleNames,
  toggleOn: boolean
) => {
  trackAppRules(
    toggleOn ? AppRuleNames.EnableNewAppAlert : AppRuleNames.DisableNewAppAlert
  );
  return (dispatch, getState) => {
    dispatch(setIsAlertNewApps(toggleOn));
    const rules = setProfileRulesRecord(
      target,
      toggleOn,
      getState(),
      profileId
    );

    return Promise.resolve(
      dispatch(delayedModifyProfileRules([rules, profileId], 1000))
    ).catch(err => {
      dispatch(requestModifyProfileRulesError());
      dispatch(reverseProfileRulesUpdate());
      dispatch(showErrorToast(err));
    });
  };
};

export type RequestModifyQuotaAction = ActionPayload<
  'REQUEST_APP_RULES_MODIFY_QUOTAS',
  {
    appKey: string;
    profileId: string;
    quotas: Map<Weekday, number>;
    action: ApplicationAction;
  }
>;

export const requestModifyQuotas = ({
  appKey,
  profileId,
  quotas,
  action,
}: {
  appKey: string;
  profileId: string;
  quotas: Map<Weekday, number>;
  action: ApplicationAction;
}): RequestModifyQuotaAction => ({
  type: 'REQUEST_APP_RULES_MODIFY_QUOTAS',
  payload: {
    appKey,
    profileId,
    quotas,
    action,
  },
});

export const onToggleAppRulesSetting =
  (
    profileId: string,
    target: SettingsToggleNames,
    toggleOn: boolean
  ): BaseThunk =>
  (dispatch, getState) => {
    const state = getState();
    const license = getLicense(state);
    const features = getFeatures(state);

    const settingsToggleBlocked = isSettingsToggleBlocked(
      license.subtype,
      features,
      target
    );
    if (
      target === SettingsToggleNames.IsAlertNewApps &&
      settingsToggleBlocked
    ) {
      return dispatch(
        showUnlockAlertsAndTimeLimitsModal(
          UnlockAlertsAndTimeLimitsModalReferrer.gamesAndAppsAlertMe
        )
      );
    }
    dispatch(setLastRuleTypeModified(RuleType.GamesAndApps));
    dispatch(toggleAppRulesSetting(profileId, target, toggleOn));
  };
