import * as React from 'react';
import { t } from '../../lib/i18n';
import IconCircleChart from '../Icons/IconCircleChart';
import ProfileActivityPlaceholderWrapper from '../Profiles/ProfileActivityPlaceholderWrapper';

const EmptyActivityPlaceholder = ({ profileName }) => (
  <ProfileActivityPlaceholderWrapper>
    <IconCircleChart />
    <span>{t('Waiting for activity')}...</span>
    <span>
      {t('No activity for {{name}} yet', {
        name: profileName,
      })}
    </span>
  </ProfileActivityPlaceholderWrapper>
);

export default EmptyActivityPlaceholder;
