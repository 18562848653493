import * as React from 'react';
import {
  Button,
  ButtonType,
  FlexLayout,
  Typography,
  ButtonSize,
} from 'styleguide-react';
import { t } from '../../lib/i18n';

interface WelcomeBackProps {
  goToHome: () => void;
}

const WelcomeBack = ({ goToHome }: WelcomeBackProps) => {
  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      height="296"
      backgroundColor="white"
    >
      <Typography type="h3" weight="semibold" align="center">
        {t('Welcome back')}
      </Typography>
      <Typography type="body1" align="center">
        {t('Looks like you already have a Qustodio Premium subscription.')}
      </Typography>

      <FlexLayout
        mainaxis="column"
        marginTop="16"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        padding="24"
        width="360"
        alignSelf="center"
      >
        <Button
          size={ButtonSize.medium}
          buttonType={ButtonType.primary}
          onClick={goToHome}
        >
          {t('Go to my Family Portal')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

export default WelcomeBack;
