import { connect } from 'react-redux';
import WellbeingActivities from '../../components/Wellbeing/WellbeingActivities';
import State, { Dispatch } from '../../store/state';
import { trackSubscribeNowButtonClicked } from './tracking';
import {
  ensureWellbeingFeatureNavigation,
  openWellbeingActivitiesSubscriptionWebview,
} from './thunks';
import {
  getProductAddonOfferStatus,
  getProductAddonOffer,
} from '../../ducks/productAddonsOffer';
import { getWellbeingSubscriptionStatus } from '../../businessLogic/wellbeing/wellbeing';
import { getFeatureStatus } from '../../ducks/features';
import {
  calculateWeeklyPrice,
  formatProductAddonPrice,
} from '../../businessLogic/productAddonsOffer/productAddonsOffer';

const mapStateToProps = (state: State) => {
  const isLoading =
    getProductAddonOfferStatus(state) === 'LOADING' ||
    getFeatureStatus(state) === 'LOADING';

  const productAddon = getProductAddonOffer(state, 'wellbeing_activities');

  return {
    subscriptionStatus: getWellbeingSubscriptionStatus(state),
    isLoading,
    weeklyPrice: productAddon
      ? formatProductAddonPrice(calculateWeeklyPrice(productAddon))
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    subscribeToActivities: () => {
      trackSubscribeNowButtonClicked();
      dispatch(openWellbeingActivitiesSubscriptionWebview());
    },
    onLoad: () => {
      dispatch(
        ensureWellbeingFeatureNavigation({
          type: 'wellbeing_activities',
          allowPurchase: status => status === 'none',
        })
      );
    },
  };
};

const WellbeingActivitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WellbeingActivities);

export type WellbeingActivitiesContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default WellbeingActivitiesContainer;
