import * as React from 'react';
import * as Immutable from 'immutable';
import { Link } from 'react-router';
import { t } from '../../lib/i18n';
import { ProfileRecord } from '../../records/profile';
import { DeviceRecord } from '../../records/device';
import { getPlatformName } from '../../helpers/string';
import DeviceUserAssignedTo from './DeviceUserAssignedTo';
import {
  Card,
  DeactivableContent,
  FlexLayout,
  GlobalType,
  GroupHeader,
  Icon,
  IconColor,
  IconSize,
  IconType,
  List,
  RegularStyleListItem,
  Switch,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import DeviceAssignedTo from './DeviceAssignedTo';
import DateIncludingHours from '../Date/DateIncludingHours';
import DeviceEnableProtection from './DeviceEnableProtection';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';

const DeviceDesktop: React.FunctionComponent<{
  device: DeviceRecord;
  defaultProfile: ProfileRecord;
  profiles: Immutable.List<ProfileRecord>;
  accountProfiles: Immutable.OrderedSet<ProfileRecord>;
  profile: ProfileRecord;
  protectionEnabled: boolean;
  vpnEnabled: boolean;
  onClickMap: () => any;
  onClickEdit: () => any;
  onClickDelete: () => any;
  onToggleHideQustodio: (checked: boolean) => any;
  onClickAssignProfileFromDropdown: () => void;
  onClickOpenDropdown: () => any;
}> = ({
  device,
  defaultProfile,
  profiles,
  accountProfiles,
  protectionEnabled,
  onClickEdit,
  onClickDelete,
  onToggleHideQustodio,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
}) => {
  return (
    <PageLayout
      title={device !== undefined ? device.name : ''}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 2,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 2,
          mainInsideCard: true,
        },
      ]}
      actions={[
        { actionName: 'back' },
        { actionName: 'edit', onClick: onClickEdit },
        {
          actionName: 'delete',
          onClick: onClickDelete,
          iconColor: IconColor.error,
        },
      ]}
    >
      <PageSectionLayout side="main">
        <DeactivableContent disabled={!protectionEnabled}>
          <DeviceEnableProtection protectionEnabled={protectionEnabled} />

          <DeviceDesktopFields
            device={device}
            defaultProfile={defaultProfile}
            profiles={profiles}
            accountProfiles={accountProfiles}
            onToggleHideQustodio={onToggleHideQustodio}
            onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
            onClickOpenDropdown={onClickOpenDropdown}
          />
        </DeactivableContent>
      </PageSectionLayout>
      <PageSectionLayout side="right">
        <Card
          type={GlobalType.white}
          header={{
            title: t('Settings'),
            border: true,
          }}
          className={classNames(
            DesktopLayoutScssUtils.CardRight,
            TabletLayoutScssUtils.CardRight
          )}
        >
          <DeactivableContent disabled={!protectionEnabled}>
            <FlexLayout mainaxis="column" marginTop="16" marginBottom="16">
              {desktopSettingsSwitch(device, onToggleHideQustodio)}
            </FlexLayout>
          </DeactivableContent>
        </Card>
      </PageSectionLayout>
    </PageLayout>
  );
};

const DeviceDesktopFields: React.FunctionComponent<{
  device: DeviceRecord;
  defaultProfile: ProfileRecord;
  profiles: Immutable.List<ProfileRecord>;
  accountProfiles: Immutable.OrderedSet<ProfileRecord>;
  onToggleHideQustodio: (checked: boolean) => any;
  onClickAssignProfileFromDropdown: () => void;
  onClickOpenDropdown: () => any;
}> = ({
  device,
  defaultProfile,
  profiles,
  accountProfiles,
  onToggleHideQustodio,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
}) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="flex-start"
    flexGrow="0"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <GroupHeader
        useAsLink={Link}
        helpUrl={`/devices/${device.id}/modal/HowToAssignUsersModal`}
        testId="assignedTo"
      >
        {t('Assigned to:')}
      </GroupHeader>
    </FlexLayout>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout mainaxis="column" margin="16">
        <DeviceAssignedTo
          device={device}
          profile={defaultProfile}
          profiles={accountProfiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceDesktop"
        />
      </FlexLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlexLayout mainaxis="column" marginTop="16" marginBottom="16">
        <DeviceAssignedTo
          device={device}
          profile={defaultProfile}
          profiles={accountProfiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceDesktop"
        />
      </FlexLayout>
    </RenderWhen>

    {device && device.users.size > 1 && (
      <React.Fragment>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column" flexGrow="1">
              <GroupHeader>
                {t('{{platformName}} accounts', {
                  platformName: getPlatformName(device),
                })}
              </GroupHeader>
            </FlexLayout>
            <FlexLayout mainaxis="column" flexGrow="1">
              <GroupHeader
                useAsLink={Link}
                helpUrl={`/devices/${device.id}/modal/HowToAssignProfileToAccountModal`}
                testId="howToAssignProfileToAccount"
              >
                {t('Child Assigned')}
              </GroupHeader>
            </FlexLayout>
          </FlexLayout>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <FlexLayout mainaxis="row">
            <FlexLayout mainaxis="column" flexGrow="1">
              <GroupHeader
                useAsLink={Link}
                helpUrl={`/devices/${device.id}/modal/HowToAssignProfileToAccountModal`}
                testId="howToAssignProfileToAccount"
              >
                {`${t('{{platformName}} accounts', {
                  platformName: getPlatformName(device),
                })} / ${t('Child Assigned')}`}
              </GroupHeader>
            </FlexLayout>
          </FlexLayout>
        </RenderWhen>

        <FlexLayout mainaxis="column" marginBottom="16" marginTop="16">
          <DeviceUserAssignedTo
            device={device}
            profiles={profiles}
            accountProfiles={accountProfiles}
            onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
            onClickOpenDropdown={onClickOpenDropdown}
          />
        </FlexLayout>
      </React.Fragment>
    )}

    <RenderWhen screenSize={ScreenSize.Mobile}>
      <FlexLayout mainaxis="column">
        <GroupHeader>{t('Settings')}</GroupHeader>
      </FlexLayout>
      <FlexLayout mainaxis="column" margin="16">
        {desktopSettingsSwitch(device, onToggleHideQustodio)}
      </FlexLayout>
    </RenderWhen>

    {device && (
      <React.Fragment>
        <FlexLayout mainaxis="column">
          <GroupHeader>{t('Last Activity:')}</GroupHeader>
        </FlexLayout>
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          <FlexLayout
            mainaxis="row"
            marginTop="16"
            marginBottom="16"
            marginRight="16"
            marginLeft="16"
          >
            <FlexLayout mainaxis="row" marginRight="16">
              <Icon
                type={IconType.calendarAlt}
                color={IconColor.primary}
                size={IconSize.lg}
              />
            </FlexLayout>
            <DateIncludingHours time={device.lastseen} />
          </FlexLayout>
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          <FlexLayout mainaxis="row" marginTop="16" marginBottom="16">
            <FlexLayout mainaxis="row" marginRight="16">
              <Icon
                type={IconType.calendarAlt}
                color={IconColor.primary}
                size={IconSize.lg}
              />
            </FlexLayout>
            <DateIncludingHours time={device.lastseen} />
          </FlexLayout>
        </RenderWhen>
      </React.Fragment>
    )}
  </FlexLayout>
);

const desktopSettingsSwitch = (device, onToggleHideQustodio) => (
  <List
    listItemMarginTop="8"
    listItemPaddingTop="8"
    listItemMarginBottom="8"
    listItemPaddingBottom="8"
  >
    <RegularStyleListItem
      title={t('Hide {{shortName}} on this device')}
      upperSubtitle={t('The {{shortName}} application will be hidden.')}
      actionElement={
        <Switch
          checked={device && !!device.hideTrayicon}
          testId="switchHideQustodio"
          onClick={ev =>
            onToggleHideQustodio((ev.target as HTMLInputElement).checked)
          }
        />
      }
    />
  </List>
);

export default DeviceDesktop;
