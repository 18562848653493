import type { TamperingTopBarProps } from './types';

/*
Given a kid’s profile with 1 tampered device and at least 1 non-tampered device when accessing the playground 
url then the tampering topbar for 1 device is displayed

Given a kid’s profile with more than 1 tampered device and at least 1 non-tampered device
when accessing the playground url
then the tampering topbar for multiple devices is displayed

Given a kid’s profile with all their devices tampered (1 or more than 1)
when accessing the playground url
then the tampering topbar is not displayed
*/
type isVisible = (props: TamperingTopBarProps) => boolean;

export const isVisibleTamperingTopBar: isVisible = ({
  tamperedDevices,
  devices,
}: TamperingTopBarProps) => {
  return (
    tamperedDevices.length > 0 && tamperedDevices.length !== devices.length
  );
};
