import * as React from 'react';
import { Card, FlexLayout, FreeText, Layout } from 'styleguide-react';
import { t } from '../../../lib/i18n';
import Loader from '../../base/Loader';

const SignatureLoadingPlaceholder: React.FunctionComponent = () => (
  <Card fullWidth className="SignatureLoadingPlaceholder">
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="center"
      crossaxisAlignment="center"
      padding="24"
    >
      <Layout renderAs="span" marginBottom="8">
        <Loader size="small" color="" />
      </Layout>
      <FreeText color="gray-semi" fontSize="12px">
        {t('Loading insights')}...
      </FreeText>
    </FlexLayout>
  </Card>
);

export default SignatureLoadingPlaceholder;
