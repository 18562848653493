import * as React from 'react';
import { Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { t } from '../../lib/i18n';
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  FormNames,
} from '../../constants';
import InputTextField from '../Form/InputTextField';
import { FlexLayout, Label } from 'styleguide-react';

export type PasswordForm = {
  id: string;
  password: string;
  passwordNew: string;
  confirmPasswordNew: string;
};

export type PasswordFormRecord = Record<PasswordForm>;

export const currentPasswordId = 'CurrentPasswordId';
export const newPasswordId = 'NewPasswordId';
export const repeatNewPasswordId = 'RepeatNewPasswordId';

export const EditPasswordForm: React.FunctionComponent<{
  className: string;
  handleSubmit: () => void;
}> = ({ className = '', handleSubmit }) => (
  <form
    className={`Form ${className}`}
    onSubmit={handleSubmit}
    id={FormNames.editPassword}
  >
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={currentPasswordId}>{t('Current password')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Field
        name="password"
        component={InputTextField}
        type="password"
        placeholder={t('Current password')}
      />
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={newPasswordId}>{t('New password')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Field
        name="passwordNew"
        component={InputTextField}
        type="password"
        placeholder={t('New password')}
      />
    </FlexLayout>
    <FlexLayout mainaxis="column" marginBottom="8">
      <Label htmlFor={repeatNewPasswordId}>{t('Repeat new password')}</Label>
    </FlexLayout>
    <FlexLayout mainaxis="column">
      <Field
        name="confirmPasswordNew"
        component={InputTextField}
        type="password"
        placeholder={t('Repeat new password')}
      />
    </FlexLayout>
    {/* include an invisible submit-button so that the mobile-keyboard
    has a "submit" button: */}
    <button type="submit" style={{ display: 'none' }} />
  </form>
);

const validate = values => {
  const errors = {
    password: '',
    passwordNew: '',
    confirmPasswordNew: '',
  };
  // email validation implemented as in http://redux-form.com/6.0.0-alpha.4/examples/syncValidation/
  if (!values.password) {
    errors.password = t('Field required');
  } else if (values.password.length > MAX_PASSWORD_LENGTH) {
    errors.password = t(
      'Password should not be longer than {{length}} characters',
      { length: MAX_PASSWORD_LENGTH }
    );
  } else if (values.password.length < MIN_PASSWORD_LENGTH) {
    errors.password = t(
      'Password should be longer than {{length}} characters',
      { length: MIN_PASSWORD_LENGTH }
    );
  }
  if (!values.passwordNew) {
    errors.passwordNew = t('Field required');
  } else if (values.passwordNew.length > MAX_PASSWORD_LENGTH) {
    errors.passwordNew = t(
      'Password should not be longer than {{length}} characters',
      { length: MAX_PASSWORD_LENGTH }
    );
  } else if (values.passwordNew.length < MIN_PASSWORD_LENGTH) {
    errors.passwordNew = t(
      'Password should be longer than {{length}} characters',
      { length: MIN_PASSWORD_LENGTH }
    );
  }
  if (!values.confirmPasswordNew) {
    errors.confirmPasswordNew = t('Field required');
  } else if (values.passwordNew !== values.confirmPasswordNew) {
    errors.confirmPasswordNew = t('Passwords must match');
  }
  return errors;
};

export const FormRecord = Record<PasswordForm, {}>({
  id: '',
  password: '',
  passwordNew: '',
  confirmPasswordNew: '',
});

export const formRecord = (
  values: PasswordFormRecord | PasswordForm | undefined
): PasswordFormRecord => {
  if (values === undefined) {
    return FormRecord();
  }

  const record = values instanceof Record ? values : FormRecord(values);

  return record as PasswordFormRecord;
};

const EditPasswordFormConnected = reduxForm({
  form: FormNames.editPassword,
  enableReinitialize: true,
  initialValues: formRecord(undefined),
  validate: values => validate(values.toJS()),
})(EditPasswordForm);

export default EditPasswordFormConnected;
