import { connect } from 'react-redux';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
  Events,
  track,
  trackStudentUnlinked,
} from '../../helpers/analytics';
import { addDevice } from '../../actions/ProfileActions';
import StudentSummary from '../../components/Schools/StudentSummary';
import { fetchStudentSummaryData } from '../../ducks/studentSummary';
import {
  getActiveProfileUid,
  getProfileId,
  hasProfileWithActiveDevice,
  isFetchingProfile,
  isRefreshingProfile,
} from '../../selectors/profile';
import {
  getStudentSummaryActiveDateRange,
  getStudentSummaryLastUpdated,
  getSummaryFetchError,
  isFetchingSummaries,
} from '../../selectors/studentSummary';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { SummaryDateRanges } from '../../constants/index';
import { isFloatingActionMenuOpen } from '../../selectors';
import { getProfile } from '../../selectors/profileRaw';
import {
  closeFloatingActionMenu,
  toggleFloatingActionMenu,
} from '../../ducks/app';
import { selectedNewDateRange } from '../../actions/StudentSummaryActions';

import { goBackIfHistory } from '../../ducks/routing';
import { createMySchoolEmailHref } from '../../helpers/email';
import { t } from '../../lib/i18n';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import { APPThunk } from '../../helpers/thunks';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import flags from '../../sideEffects/flags';
import { isStudentOptInForDelegationForThisAccount } from '../../selectors/studentPolicies';
import { showRulesNavigationToStudentProfile } from '../../helpers/menu';
import { fetchStudentPolicies } from '../../ducks/studentPolicies';

const mapStateToProps = (state: State, routerParams: RouterParamsProps) => {
  const profile = getProfile(state, routerParams.params.profileId);
  const summaryFetchError = getSummaryFetchError(state);
  if (summaryFetchError === StudentSummaryFetchError.Unlinked) {
    trackStudentUnlinked(GenericPageNames.ProfileSchoolActivity, profile);
  }
  const showRulesOptionToStudentProfile = showRulesNavigationToStudentProfile(
    state,
    profile!.id
  );

  return {
    activeRange: getStudentSummaryActiveDateRange(state),
    lastUpdated: getStudentSummaryLastUpdated(state),
    isMenuOpen: isFloatingActionMenuOpen(state),
    // TODO: Since this container is loaded under /profiles/pf_id route,
    // it is executing the load from ProfileNewContainer and fetching
    // profile data if it doesn't exist in the state.
    // We need to figure out a better way to handle this.
    isFetchingData:
      (isFetchingProfile(state) && !isRefreshingProfile(state)) ||
      (isFetchingSummaries(state) && !summaryFetchError),
    summaryFetchError,
    profile,
    accountHasProtectedDevices: hasProfileWithActiveDevice(state),
    routerParams: routerParams.params,
    showRulesOptionToStudentProfile,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  onChangeDateRange: (dateRange: SummaryDateRanges) => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.DateRange,
      { options: { range: `${dateRange}d` } }
    );
    dispatch(selectedNewDateRange(dateRange, profileId));
  },
  onToggleMenu: () => {
    dispatch(toggleFloatingActionMenu());
  },
  onClickSpeedDialAddDevices: () => {
    dispatch(closeFloatingActionMenu());
    dispatch(addDevice(profileId));
  },
  onClickSpeedDialSeeTimeline: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(closeFloatingActionMenu());
    trackButtonClicked(
      GenericPageNames.LeftMenu,
      ButtonNames.StudentTimelineNavigation,
      { profile_id: profileId }
    );
    dispatch(
      navigate({
        type: 'inner',
        src: `/profiles/${profileId}/school/timeline`,
      })
    );
  },
  onClickSpeedDialRules: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(closeFloatingActionMenu());
    trackButtonClicked(GenericPageNames.LeftMenu, ButtonNames.Rule, {
      profile_id: profileId,
    });
    dispatch(closeFloatingActionMenu());
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/rules` })
    );
  },
  onClickEdit: () => {
    const navigate = getMultiPlatformNavigation();
    track(Events.ClickedProfileEdit);
    return dispatch(
      navigate({ type: 'inner', src: `/profiles/${profileId}/edit` })
    );
  },
  onClickGoBack: () => {
    dispatch(goBackIfHistory());
  },
  onClickContactUs: (event: Event): void => {
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.ContactSupport
    );
    const navigate = getMultiPlatformNavigation(event);

    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(t('Error School dashboard activity')),
    });
  },
  onClickProtectDevice: () => {
    const navigate = getMultiPlatformNavigation();
    trackButtonClicked(
      GenericPageNames.ProfileSchoolActivity,
      ButtonNames.ProtectADevice,
      {
        onboarding_banner: true,
      }
    );
    dispatch(navigate({ type: 'inner', src: '/schools/choose-a-profile' }));
  },
});

const StudentSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSummary);

export type StudentSummaryProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

StudentSummaryContainer.load = (): APPThunk => async (dispatch, getState) => {
  const state = getState();
  const profileUid = getActiveProfileUid(state);

  if (profileUid !== null) {
    const profileId = Number(getProfileId(state));
    await dispatch(fetchStudentPolicies(profileId));

    const navigate = getMultiPlatformNavigation();
    if (
      flags.useDelegation.isEnabled() &&
      isStudentOptInForDelegationForThisAccount(getState())
    ) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${profileId}/family-and-school`,
        })
      );
    }

    await dispatch(fetchStudentSummaryData(profileUid));
  }
};

export default StudentSummaryContainer;
