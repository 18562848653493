import * as React from 'react';
import {
  DropdownElementOrientation,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
  StyledHeader,
  TransparentButton,
} from 'styleguide-react';
import { accountActionsClick } from '../../actions/ActionSheetActions';
import DropDown from './DropDown';
import { getAccountActions } from './DropDownActions';
import { formatActionsToOptions } from './DropDownActionSheet';
import State, { Dispatch } from '../../store/state';
import { getAccount, isCurrentProductDedicatedSupport } from '../../selectors';
import { connect } from 'react-redux';
import { shouldShowGift } from '../../ducks/gifts/giftsSelectors';
import { AccountActions } from '../../constants';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const AccountDropdown: React.FC<{
  accountName: string;
  showGift: boolean;
  isCurrentProductDedicatedSupport: boolean;
  onClickGift: () => void;
  onClickOption: (action: AccountActions) => () => void;
}> = ({
  accountName,
  showGift,
  isCurrentProductDedicatedSupport,
  onClickGift,
  onClickOption,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return accountName ? (
    <DropDown
      useSinglePortalHook={false}
      options={formatActionsToOptions(
        getAccountActions(onClickOption, isCurrentProductDedicatedSupport)
      )}
      menuOrietation={DropdownElementOrientation.left}
      minWidth={400}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      actionElement={
        <TransparentButton>
          <FlexLayout
            mainaxis="row"
            mainaxisAlignment="space-around"
            crossaxisAlignment="center"
            margin="24"
            marginRight="0"
            marginBottom="0"
            minHeight="40"
          >
            {showGift && (
              <FlexLayout
                mainaxis="column"
                mainaxisAlignment="center"
                crossaxisAlignment="center"
                marginRight="16"
              >
                <TransparentButton onClick={onClickGift}>
                  <Icon
                    type={IconType.gift}
                    color={IconColor.regular}
                    size={IconSize.lg}
                  />
                </TransparentButton>
              </FlexLayout>
            )}
            <FlexLayout
              mainaxis="column"
              mainaxisAlignment="center"
              crossaxisAlignment="center"
            >
              <StyledHeader type="h4">
                <strong>{accountName}</strong>
              </StyledHeader>
            </FlexLayout>
            <FlexLayout
              mainaxis="column"
              mainaxisAlignment="center"
              marginLeft="16"
            >
              <Icon
                type={isOpen ? IconType.angleUp : IconType.angleDown}
                color={IconColor.regular}
                size={IconSize.lg}
              />
            </FlexLayout>
          </FlexLayout>
        </TransparentButton>
      }
    />
  ) : null;
};

const ConnectedAccountDropdown = connect(
  (state: State) => ({
    accountName: getAccount(state)?.fullname,
    showGift: shouldShowGift(state),
    isCurrentProductDedicatedSupport: isCurrentProductDedicatedSupport(state),
  }),
  (dispatch: Dispatch) => ({
    onClickGift: () => {
      const navigate = getMultiPlatformNavigation();

      dispatch(
        navigate({
          type: 'inner',
          src: '/trial/modal/ReTrialModal',
        })
      );
    },
    onClickOption: (action: AccountActions) => () =>
      dispatch(accountActionsClick(action)),
  })
)(AccountDropdown);

export default ConnectedAccountDropdown;
