import * as React from 'react';
import { FlexLayout } from 'styleguide-react';
// eslint-disable-next-line import/no-unresolved
import { LayoutContentSpacing } from 'styleguide-react/build/components/Layout/types';
import useKeepScroll from '../hooks/useKeepScroll/useKeepScroll';
import usePullToRefresh from '../hooks/usePullToRefresh';
import type { PageTitleLayoutProps } from '../PageTitleLayout';
import { DesktopMinHeightCard } from './page/constants';
import DesktopCard from './page/DesktopCard';
import DesktopPageTitle from './page/DesktopPageTitle';
import { getPixels } from './page/getPixels';
import { DesktopPageLayoutProps } from './types';

type DesktopPageLayoutAndTitleProps = PageTitleLayoutProps &
  DesktopPageLayoutProps;
const DesktopPageLayout: React.FunctionComponent<
  DesktopPageLayoutAndTitleProps
> = (props: DesktopPageLayoutAndTitleProps) => {
  const {
    configuration,
    mainChildren: mainChildrenProp,
    beforeCardChildren,
    cardBannerChildren,
    beforePullRefreshChildren,
    afterPullRefreshChildren,
    afterCardChildren,
    rightChildren,
  } = props;

  const divScroll = React.useRef<HTMLDivElement>(null);

  const mainChildren = usePullToRefresh(
    divScroll,
    mainChildrenProp,
    configuration?.pullToRefresh
  );

  useKeepScroll(divScroll, configuration?.keepScrollKey);

  return (
    <FlexLayout
      mainaxis="column"
      height="100%"
      className="par-page-layout par-page-layout--size-desktop"
      testId="DesktopPageLayout_Container"
      scrollY={!configuration?.disableScroll}
      ref={divScroll}
      marginLeft="24"
    >
      {!configuration?.hidePageTitle && <DesktopPageTitle {...props} />}
      <FlexLayout
        mainaxis="column"
        flexGrow="2"
        testId="DesktopPageLayout_Content_Container"
      >
        <FlexLayout
          mainaxis="row"
          height="100%"
          testId="DesktopPageLayout_ColumnsRow_Container"
          marginTop={configuration?.addMarginTopToCenter ? '80' : undefined}
          mainaxisAlignment={
            configuration?.columns === 1 &&
            !(configuration?.cardCentered === false)
              ? 'center'
              : undefined
          }
        >
          <FlexLayout
            mainaxis="column"
            minHeight="100%"
            className="par-page-layout__content"
            testId="DesktopPageLayout_ColumnMain_Container"
            mainaxisAlignment={
              configuration?.cardCentered ? 'center' : undefined
            }
          >
            {beforeCardChildren}
            <FlexLayout
              mainaxis="column"
              width={getPixels(configuration) as LayoutContentSpacing}
              className="par-page-layout__card-container"
              testId="DesktopPageLayout_ColumnMain_Card_Container"
              minHeight={DesktopMinHeightCard}
            >
              {configuration?.mainInsideCard && (
                <DesktopCard {...props} mainChildren={mainChildren} />
              )}

              {!configuration?.mainInsideCard && (
                <FlexLayout
                  mainaxis="column"
                  margin="8"
                  marginRight="16"
                  testId="DesktopPageLayout_NoCard_Banner_Container"
                >
                  {cardBannerChildren}
                </FlexLayout>
              )}

              {!configuration?.mainInsideCard && beforePullRefreshChildren}

              {!configuration?.mainInsideCard && mainChildren}

              {!configuration?.mainInsideCard && afterPullRefreshChildren}
            </FlexLayout>
            {afterCardChildren}
          </FlexLayout>

          {rightChildren &&
            (!configuration ||
              (configuration?.columns !== 1 && (
                <FlexLayout
                  mainaxis="column"
                  height="100%"
                  testId="DesktopPageLayout_ColumnRight_Container"
                  marginLeft="24"
                  marginRight="24"
                >
                  {rightChildren}
                </FlexLayout>
              )))}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
export default DesktopPageLayout;
