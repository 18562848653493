import * as React from 'react';
import { t } from '../../../lib/i18n';
import { FlexLayout, Icon, IconType, PlanBoxExtended } from 'styleguide-react';
import { AccountRecord, ProductRecord } from '../../../records';
import {
  ProductDuration,
  getPrice,
  getDisplayDuration,
  getPricePerMonth,
  getProductTypeAsString,
  getUpsellFullPrice,
  ProductSize,
  getPriceDS,
} from '../../../records/product';
import {
  getProductFeatures,
  getProductIcon,
  getProductRibbon,
  getProductSubtitle,
  isCarePlusCheckedByDefault,
  isProductHighlighted,
} from '../../../businessLogic/products';

interface CustomPlanBoxBProps {
  account: AccountRecord;
  product: ProductRecord;
  productDedicatedSupport?: ProductRecord;
  isPurchaseInProgress: boolean;
  planDuration: ProductDuration;
  selectedProductCode: null | string;
  upsellProduct: boolean;
  areUpsellProducts: boolean;
  isEnabledDedicatedSupportV2: boolean;
  onClickProduct: (product: ProductRecord) => void;
}

const CustomPlanBoxB = ({
  account,
  product,
  productDedicatedSupport,
  isPurchaseInProgress,
  planDuration,
  selectedProductCode,
  upsellProduct,
  areUpsellProducts,
  isEnabledDedicatedSupportV2,
  onClickProduct,
}: CustomPlanBoxBProps) => {
  const [checkboxSelected, setCheckbox] = React.useState(
    isCarePlusCheckedByDefault(product.licenseSubtype)
  );

  const getPlanOptionalText = (product: ProductRecord, upsell: boolean) =>
    upsell
      ? `${t('Thereafter')} ${getUpsellFullPrice(
          account,
          product
        )}/${getDisplayDuration(product)}`
      : `${t('Same as')} ${getPricePerMonth(
          account,
          product,
          planDuration
        )}/${t('month')}`;

  const isProductWithDedicatedSupport =
    (isEnabledDedicatedSupportV2 &&
      productDedicatedSupport?.isDedicatedSupport) ||
    false;

  const carePlusPrice =
    productDedicatedSupport &&
    isProductWithDedicatedSupport &&
    getPriceDS(account, product, productDedicatedSupport);

  const checkboxSelectedForDedicatedSupportProduct =
    productDedicatedSupport &&
    checkboxSelected &&
    isProductWithDedicatedSupport;

  const carePlusIncluded =
    areUpsellProducts && product.current && product.isDedicatedSupport;

  const getCarePlusLabel = () => {
    if (carePlusIncluded) {
      return (
        <FlexLayout mainaxis="row" marginRight="8">
          {t('Care Plus included')}
        </FlexLayout>
      );
    }
    return product.type === ProductSize.large
      ? t('{1}Care Plus{/1} included free', {
          1: (str: string) => <strong>{str}</strong>,
        })
      : t('{1}Add Care Plus{/1} for {{price}}', {
          1: (str: string) => <strong>{str}</strong>,
          price: carePlusPrice,
        });
  };

  const getSelectedProduct = () =>
    checkboxSelectedForDedicatedSupportProduct && productDedicatedSupport
      ? productDedicatedSupport
      : product;

  return (
    <PlanBoxExtended
      key={product.code}
      icon={getProductIcon(product.licenseSubtype) as IconType}
      title={getProductTypeAsString(product.type, product.licenseSubtype)}
      subtitle={getProductSubtitle(product.licenseSubtype)}
      price={getPrice(account, getSelectedProduct())}
      years={upsellProduct ? undefined : getDisplayDuration(product)}
      priceAdditionalInformation={getPlanOptionalText(
        getSelectedProduct(),
        upsellProduct
      )}
      deviceInformation="" // This props should be opcional, will fix on Rocket
      highlighted={isProductHighlighted(product.licenseSubtype)}
      current={product.current}
      currentText={t('Your current plan')}
      addonInfo={
        isProductWithDedicatedSupport || carePlusIncluded
          ? {
              label: getCarePlusLabel(),
              showCheckbox: isProductWithDedicatedSupport,
              isChecked: checkboxSelected,
              onClick: () => setCheckbox(!checkboxSelected),
              description: t(
                'Enhance your Premium experience by adding Care Plus, which includes these exclusive features:'
              ),
              list: [
                {
                  text: t('Priority phone support'),
                  icon: <Icon type={IconType.phone} />,
                },
                {
                  text: t('Ongoing check-ins'),
                  icon: <Icon type={IconType.userFriends} />,
                },
                {
                  text: t('Personalised help'),
                  icon: <Icon type={IconType.handSparkles} />,
                },
                {
                  text: t('Available in English & Spanish'),
                  icon: <Icon type={IconType.dots} />,
                },
              ],
            }
          : undefined
      }
      ribbon={getProductRibbon(product)}
      button={{
        text: upsellProduct ? t(`Upgrade now!`) : `${t('Buy now!')}`,
        isLoading:
          (selectedProductCode === product.code ||
            (checkboxSelected &&
              selectedProductCode === productDedicatedSupport?.code)) &&
          isPurchaseInProgress,
        onClick: () => {
          onClickProduct(getSelectedProduct());
        },
      }}
      features={getProductFeatures(product.type, product.licenseSubtype)}
    />
  );
};

export default CustomPlanBoxB;
