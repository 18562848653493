import { fromJS, NonEmptyMapRecord, List } from 'immutable';
import GiftRecord from '../../records/gift';
import api from '../../api';
import { tapReject } from '../../helpers';
import { showErrorToast } from '../../containers/AccountSettings/helpers';
import { fetchLicense } from '../license';
import { goBackIfHistory } from '../routing';

export const REQUEST_GIFTS = 'REQUEST_GIFTS';
export const RECEIVE_GIFTS = 'RECEIVE_GIFTS';
export const REQUEST_GIFTS_ERROR = 'REQUEST_GIFTS_ERROR';
export const REQUEST_CONSUME_GIFT = 'REQUEST_CONSUME_GIFT';
export const REQUEST_CONSUME_GIFT_SUCCESSFULL =
  'REQUEST_CONSUME_GIFT_SUCCESSFULL';
export const REQUEST_CONSUME_GIFT_ERROR = 'REQUEST_CONSUME_GIFT_ERROR';
export const SET_GIFT_HAS_BEEN_CONSUMED_SUCCESSFULLY =
  'SET_GIFT_HAS_BEEN_CONSUMED_SUCCESSFULLY';

export const initialGiftsState = {
  items: [],
  count: 0,
  isFetching: false,
  isConsumingGift: false,
  giftHasBeenConsumedSuccessfully: false,
};

export default function gifts(
  state: NonEmptyMapRecord<{
    items: List<GiftRecord>;
    count: number;
    isFetching: boolean;
    isConsumingGift: boolean;
    giftHasBeenConsumedSuccessfully: boolean;
  }> = fromJS(initialGiftsState),
  action
) {
  switch (action.type) {
    case REQUEST_GIFTS:
      return state.set('isFetching', true);
    case RECEIVE_GIFTS:
      return state.merge({
        isFetching: false,
        items: action.payload.gifts,
        count: action.payload.count,
      });
    case REQUEST_GIFTS_ERROR:
      return state.merge({
        isFetching: false,
      });
    case REQUEST_CONSUME_GIFT:
      return state.set('isConsumingGift', true);
    case REQUEST_CONSUME_GIFT_SUCCESSFULL:
      return state.merge({
        isConsumingGift: false,
        giftHasBeenConsumedSuccessfully: true,
      });
    case REQUEST_CONSUME_GIFT_ERROR:
      return state.merge({
        isConsumingGift: false,
        giftHasBeenConsumedSuccessfully: false,
      });
    case SET_GIFT_HAS_BEEN_CONSUMED_SUCCESSFULLY:
      return state.merge({
        giftHasBeenConsumedSuccessfully: action.payload,
      });
    default:
      return state;
  }
}

export const requestGiftsError = () => {
  return {
    type: REQUEST_GIFTS_ERROR,
  };
};

export function requestGifts() {
  return {
    type: REQUEST_GIFTS,
  };
}

export function receiveGifts(gifts) {
  return {
    type: RECEIVE_GIFTS,
    payload: {
      gifts: gifts.map(gift => GiftRecord.fromPayload(gift) || GiftRecord()),
      count: gifts.length,
    },
  };
}

export function fetchGifts() {
  return dispatch => {
    dispatch(requestGifts());
    return api.giftNotifications
      .get({})
      .then(json => {
        const notificationsFilterByGifts = json.items_list.filter(
          gift => gift.type === 'GIFT' && gift.key === 'RETRIAL_GIFT_2020'
        );
        dispatch(receiveGifts(notificationsFilterByGifts));
      })
      .catch(tapReject(() => dispatch(requestGiftsError())));
  };
}

export const requestConsumeGiftsError = () => {
  return {
    type: REQUEST_CONSUME_GIFT_ERROR,
  };
};

export function requestConsumeGift() {
  return {
    type: REQUEST_CONSUME_GIFT,
  };
}

export function requestConsumeGiftSuccessful() {
  return {
    type: REQUEST_CONSUME_GIFT_SUCCESSFULL,
  };
}

export const consumeGift = body => {
  return dispatch => {
    dispatch(requestConsumeGift());
    return api.consumeGift
      .post(body)
      .then(() => {
        dispatch(requestConsumeGiftSuccessful());
        dispatch(fetchLicense());
        dispatch(fetchGifts());
      })
      .catch(
        tapReject(err => {
          dispatch(goBackIfHistory());
          dispatch(requestConsumeGiftsError());
          dispatch(showErrorToast(err));
        })
      );
  };
};

export function setGiftHasBeenConsumedSuccessfully(newValue) {
  return {
    type: SET_GIFT_HAS_BEEN_CONSUMED_SUCCESSFULLY,
    payload: newValue,
  };
}
