import * as React from 'react';
import { t } from '../../lib/i18n';
import OrderedList from '../Lists/OrderedList';

import * as qinit from '../../qinit';
import { DevicePlatform } from '../../constants';
import * as IconLogoKids from '../../assets/base/images/logo-kids-app.svg';
import Icon from '../Icons/Icon';
import { Svg } from 'styleguide-react';

const getInstallInstructions = (platform: DevicePlatform) => {
  switch (platform) {
    case DevicePlatform.Android:
      return [
        t("Open Google Play on your child's Android"),
        t('Download and log in to {1}Kids App {{shortName}}{/1}', {
          1: str => (
            <strong className="app-icon">
              {' '}
              <Svg height="24" width="24">
                <Icon path={IconLogoKids} />{' '}
              </Svg>{' '}
              {str}{' '}
            </strong>
          ),
        }),
        t(
          'Confirm that you want to protect the device and then follow the steps on screen.'
        ),
      ];
    case DevicePlatform.Ios:
      return [
        t("Open the App Store on your child's iOS"),
        t('Download and log in to {1}Kids App {{shortName}}{/1}', {
          1: str => (
            <strong className="app-icon">
              {' '}
              <Svg height="24" width="24">
                <Icon path={IconLogoKids} />{' '}
              </Svg>{' '}
              {str}{' '}
            </strong>
          ),
        }),
        t(
          'Confirm that you want to protect the device and then follow the steps on screen.'
        ),
      ];
    case DevicePlatform.Windows:
      return [
        t('Use your child’s device to visit {1}{{downloadLink}}{/1}', {
          1: str => <strong>{str}</strong>,
          downloadLink:
            qinit.tenant.common.endpoint.download_choose_your_os.replace(
              /^https?:\/\//,
              ''
            ),
        }),
        t('Click {1}Protect this Windows{/1} to download {{shortName}}', {
          1: str => <strong>{str}</strong>,
        }),
        t('Click the {{shortName}}.exe file to install'),
        t('Log in and follow the steps on screen'),
      ];
    case DevicePlatform.Mac:
      return [
        t('Use your child’s Mac to visit {1}{{downloadLink}}{/1}', {
          1: str => <strong>{str}</strong>,
          downloadLink:
            qinit.tenant.common.endpoint.download_choose_your_os.replace(
              /^https?:\/\//,
              ''
            ),
        }),
        t('Click {1}Protect this Mac{/1} to download {{shortName}}', {
          1: str => <strong>{str}</strong>,
        }),
        t('Click the {{shortName}}.dmg file to install'),
        t('Log in and follow the steps on screen'),
      ];
    case DevicePlatform.Kindle:
      return [
        t("Open the Amazon Store on your child's Kindle"),
        t('Download and log in to {1}{{shortName}}{/1}', {
          1: str => (
            <strong className="app-icon">
              {' '}
              <Svg height="24" width="24">
                <Icon path={IconLogoKids} />
              </Svg>{' '}
              {str}{' '}
            </strong>
          ),
        }),
        t(
          'Confirm that you want to protect the device and then follow the steps on screen.'
        ),
      ];
    case DevicePlatform.Chromebook:
      return [
        t('Open Google Play on your child’s Chromebook'),
        t('Download and log in to {1}Kids App {{shortName}}{/1}', {
          1: str => (
            <strong className="app-icon">
              {' '}
              <Svg height="24" width="24">
                <Icon path={IconLogoKids} />{' '}
              </Svg>{' '}
              {str}{' '}
            </strong>
          ),
        }),
        t(
          'Confirm that you want to protect the device and then follow the steps on screen.'
        ),
        t('Go to the Chrome Store and add the {1}{{shortName}} extension{/1}', {
          1: str => <strong>{str}</strong>,
        }),
      ];
    default:
      return null;
  }
};

export const SetupDeviceInstructionsList: React.FunctionComponent<{
  platform?: DevicePlatform;
  className?: string;
}> = ({ platform = DevicePlatform.None, className }) => {
  const instructions: string[] = getInstallInstructions(platform) || [];
  return <OrderedList items={instructions} className={className} />;
};
