import { t } from '../../lib/i18n';
import {
  ProfileStatus,
  PROFILE_STATUS_ACTIVE,
  PROFILE_STATUS_NO_DEVICE,
  PROFILE_STATUS_OFFLINE,
  PROFILE_STATUS_STANDBY,
} from './types/ProfileStatus.types';

export const mapProfileStatusToMessageStatus = (
  status?: ProfileStatus
): string => {
  switch (status) {
    case PROFILE_STATUS_ACTIVE:
      return t('Active');
    case PROFILE_STATUS_NO_DEVICE:
      return t('Connect a device');
    case PROFILE_STATUS_OFFLINE:
      return t('Offline');
    case PROFILE_STATUS_STANDBY:
      return t('Standby');
    default:
      return t('Unknown');
  }
};
