import { localNow } from '../../helpers/dates';
import { AnyError, showErrorAlert } from '../../helpers/errorHandling';
import { getTimezone } from '../../selectors';

export const getNow = state => {
  return localNow(getTimezone(state));
};

export const requestProfileFilter = ({
  profile,
  lastProfileFetched,
  state,
  lastFetchTime,
}) => {
  if (!lastProfileFetched || lastProfileFetched !== profile?.uid) {
    return true;
  }
  if (getNow(state).diff(lastFetchTime, 'seconds') > 2) {
    return true;
  }
  return false;
};

export const summaryErrorHandler = <T>(
  error: AnyError,
  fn: (error: AnyError) => T
) => {
  showErrorAlert(error);
  return fn(error);
};
