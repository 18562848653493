import * as React from 'react';
import Balloon from '../base/Balloon';
import {
  getNewNotificationsCountByFilter,
  getProfileId,
  getProfileOrDefault,
} from '../../selectors';
import State from '../../store/state';
import {
  fetchNewCount,
  setFilterWithProfileUid,
} from '../../ducks/notificationCenter';
import { connect } from 'react-redux';
import { NotificationType } from '../../constants';

const getBalloonText = (newNotifications: number): string => {
  if (newNotifications === 0) return '';
  return newNotifications > 9 ? '+9' : newNotifications.toString();
};

export class InsightsNewBalloonComponent extends React.Component<{
  newNotifications: number;
  onFetchNewNotifications: () => void;
}> {
  componentDidMount() {
    const { onFetchNewNotifications } = this.props;
    onFetchNewNotifications();
  }

  render() {
    const { newNotifications } = this.props;
    return newNotifications > 0 ? (
      <Balloon text={getBalloonText(newNotifications)} />
    ) : null;
  }
}

const mapStateToProps = (state: State) => ({
  newNotifications: getNewNotificationsCountByFilter(state, {
    profileUid: getProfileOrDefault(
      state,
      parseInt(getProfileId(state) || '', 10)
    ).uid,
    type: NotificationType.ApplicationUsageIncreased,
  }),
});

const mapDispatchToProps = dispatch => ({
  onFetchNewNotifications: () => {
    dispatch(
      setFilterWithProfileUid(NotificationType.ApplicationUsageIncreased)
    );
    dispatch(fetchNewCount());
  },
});

const InsightBalloon = connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsNewBalloonComponent);

export default InsightBalloon;
