import * as React from 'react';
import { Link } from 'react-router';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import { isiOSPlatform } from '../../helpers';
import { PanicRulesContactStatus } from '../../constants';
import { ContactRecord } from '../../records/contact';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import DropDownActionSheet from '../DropDown/DropDownActionSheet';
import {
  Icon,
  IconColor,
  IconFamily,
  IconType,
  GroupHeader,
  InstructionBlock,
  Switch,
  List as ContactList,
  RegularStyleListItem,
  TransparentButton,
  Button,
  FlexLayout,
  ListSubtitle,
  ContentSeparator,
  Label,
  ButtonSize,
} from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import IconAllow from '../Icons/Actions/IconAllow';
import MobileLayoutScssUtils from '../Layout/mobile/MobileLayoutScssUtils';

const PanicButtonRules: React.FunctionComponent<{
  profileId: string;
  accountHolder: Readonly<{
    name: string;
    contact: string;
  }>;
  profileName: string;
  panicButtonEnabled: boolean;
  contacts: List<ContactRecord>;
  profileContacts: List<ContactRecord>;
  selectedContactToEdit: ContactRecord;
  onTogglePanicButtonRulesEnabled: (toggleOn: boolean) => void;
  onClickInviteContact: () => Promise<any>;
  onClickContact: (contact?: ContactRecord) => () => Promise<any>;
  onClickAccountHolder: () => any;
  onClickMoreInfo: () => any;
}> = ({
  profileId,
  profileName,
  accountHolder,
  panicButtonEnabled,
  onTogglePanicButtonRulesEnabled,
  onClickInviteContact,
  contacts,
  profileContacts,
  selectedContactToEdit,
  onClickContact,
  onClickMoreInfo,
  onClickAccountHolder,
}) => {
  const renderContent = () => (
    <FlexLayout
      mainaxis="column"
      flexGrow="2"
      className={MobileLayoutScssUtils.HeightWithoutPageTitle}
    >
      <FlexLayout mainaxis="row" mainaxisAlignment="space-between" flexGrow="0">
        <Label htmlFor="SwitchPanicButton">{t('Enable Panic Button')}</Label>
        <Switch
          id="SwitchPanicButton"
          checked={panicButtonEnabled}
          onClick={() => onTogglePanicButtonRulesEnabled(!panicButtonEnabled)}
          disabled={false}
        />
      </FlexLayout>
      <ContentSeparator />
      <FlexLayout mainaxis="column" marginTop="32" flexGrow="0">
        <InstructionBlock>
          {t(
            // tslint:disable-next-line:max-line-length
            "Shows a Panic Button inside the {{shortName}} app on {{profileName}}'s Android phone that can send alerts to Trusted Contacts.",
            {
              profileName,
            }
          )}
          &nbsp;
          {!isiOSPlatform() && (
            <a onClick={onClickMoreInfo}>
              <Icon type={IconType.questionCircle} />
            </a>
          )}
        </InstructionBlock>
      </FlexLayout>
      <FlexLayout mainaxis="column" marginTop="24" flexGrow="0">
        <GroupHeader
          useAsLink={Link}
          helpUrl={`/profiles/${profileId}/rules/panicButtonRules/modal/TrustedContactsLegendModal`}
        >
          {t("{{profileName}}'s Trusted Contacts", { profileName })}
        </GroupHeader>
      </FlexLayout>
      <FlexLayout
        mainaxis="column"
        paddingTop="32"
        paddingBottom="32"
        flexGrow="2"
      >
        <ContactList disabled={!panicButtonEnabled} listItemMarginBottom="16">
          <RegularStyleListItem
            title={accountHolder.name}
            upperSubtitle={accountHolder.contact}
            key={-1}
            icon={<IconAllow />}
            actionElement={
              <TransparentButton
                onClick={panicButtonEnabled ? onClickAccountHolder : undefined}
              >
                <Icon
                  type={IconType.lockAlt}
                  color={IconColor.primary}
                  family={IconFamily.regular}
                />
              </TransparentButton>
            }
          />
          {contacts.map(contact => (
            <RegularStyleListItem
              title={contact.name}
              key={contact.id}
              className={getIconColor(profileContacts)(contact)}
              upperSubtitle={
                <ListSubtitle>
                  {contact.contact}
                  {contact.status === PanicRulesContactStatus.Pending ? (
                    <div>
                      <i>{t('Awaiting confirmation....')}</i>
                    </div>
                  ) : null}
                </ListSubtitle>
              }
              icon={
                <IconAllow color={getIconColor(profileContacts)(contact)} />
              }
              actionElement={
                <TransparentButton
                  onClick={
                    panicButtonEnabled ? onClickContact(contact) : undefined
                  }
                >
                  <DropDownActionSheet
                    selectedActionSheetKey={contact.id}
                    title={getActionSheetTitle(selectedContactToEdit)}
                  />
                </TransparentButton>
              }
            />
          ))}
        </ContactList>
      </FlexLayout>
      <FlexLayout mainaxis="row" mainaxisAlignment="center">
        <Button
          size={ButtonSize.medium}
          disabled={!panicButtonEnabled}
          onClick={onClickInviteContact}
        >
          {t('invite trusted contact')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
  return (
    <PageLayout
      title={t('Panic Button')}
      actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Mobile,
          useNonFlexLayout: true,
        },
      ]}
    >
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        {renderContent()}
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Desktop}>{renderContent()}</RenderWhen>
    </PageLayout>
  );
};

PanicButtonRules.displayName = 'PanicButtonRules';

const getIconColor =
  (profileContacts: List<ContactRecord>) => (contact: ContactRecord) => {
    if (
      !profileContacts.some(
        profileContact =>
          profileContact !== undefined &&
          profileContact.contact === contact.contact
      )
    ) {
      return IconColor.neutral;
    }
    if (contact.status === PanicRulesContactStatus.Pending) {
      return IconColor.warning;
    }

    return IconColor.success;
  };

const getActionSheetTitle = contact =>
  contact
    ? t("{{name}}'s Alerts", {
        name: contact.name,
      })
    : '';

export default PanicButtonRules;
