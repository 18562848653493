import { Record } from 'immutable';
import { Feature } from '../../records/features/types';

export const requestFeaturesAction = () => ({
  type: 'REQUEST_FEATURES' as const,
});

export const receiveFeaturesAction = (features: Array<Record<Feature>>) => ({
  type: 'RECEIVE_FEATURES' as const,
  payload: features,
});
