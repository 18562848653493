import { RULE_LOCATION, RULE_CALLS_AND_SMS } from '../../../../constants';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

// set of rules that only apply to Android or IOs devices
const androidAndIosRules = [RULE_LOCATION, RULE_CALLS_AND_SMS];

export const isVisibleAndroidAndIosLabel = ({
  profileRule,
  profileAndroidDevicesCount,
  profileIosDevicesCount,
}: ProfileRulesLabelPredicatesProps) => {
  // this should only be shown to the user if the account has neither ios nor android devices
  if (!profileAndroidDevicesCount && !profileIosDevicesCount) {
    return androidAndIosRules.includes(profileRule);
  }

  return false;
};
