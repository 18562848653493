import * as React from 'react';
import { Record, List } from 'immutable';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import * as R from 'ramda';
import { t } from '../../../lib/i18n';
import { Gender, getAvatarTabs } from '../../../records/profile';
import { connect } from 'react-redux';
import { getForm } from '../../../selectors';

import {
  ActionInput,
  ActionInputIconPosition,
  Dropdown,
  DropdownMenuPlacement,
  DropdownOption,
  FlexLayout,
  Icon,
  IconSize,
  IconType,
  Layout,
  Button,
  StyledHeader,
  Label,
  ButtonSize,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../../RenderWhen/RenderWhen';
import InputTextField from '../../Form/InputTextField';
import AvatarRadioButtonGroup from '../../Form/AvatarRadioButtonGroup';
// eslint-disable-next-line import/no-unresolved
import { LayoutSpacing } from 'styleguide-react/build/components/Layout/types';

export const formName = 'addChild';

const addChildFormNameFieldId = 'AddChildFormNameFieldId';
const addChildFormBirthYearFieldId = 'AddChildFormBirthYearFieldId';
const addChildFormGenderFieldId = 'AddChildFormGenderFieldId';

const avatarTabs = getAvatarTabs([Gender.MALE, Gender.FEMALE], 6);

const AddChildForm: React.FunctionComponent<{
  className?: string;
  handleSubmit: () => any;
  submitting: boolean;
  formRecord: AddChildFormRecord;
  disableSubmit: boolean;
  onChangeGender: (gener: Gender) => void;
}> = ({
  handleSubmit,
  submitting,
  formRecord,
  disableSubmit,
  onChangeGender,
}) => {
  const currentYear = new Date().getFullYear();
  const yearsOptions = Array.from(Array(100).keys())
    .map(year => currentYear - year)
    .map(year => (
      <DropdownOption text={`${year}`} key={year} value={`${year}-01-01`} />
    ));
  const isGenderSelected = formRecord.gender != null;
  const genderTabIndex = formRecord.gender === Gender.FEMALE ? 1 : 0;

  const renderFields = (
    mainaxis: 'row' | 'column',
    marginLeft?: LayoutSpacing
  ) => (
    <FlexLayout
      marginTop="8"
      mainaxis={mainaxis}
      mainaxisAlignment="space-around"
    >
      <Layout minWidth="240">
        <FlexLayout mainaxis="column" marginBottom="8">
          <Label htmlFor={addChildFormNameFieldId}>{t('Name')}</Label>
        </FlexLayout>

        <Field
          name="name"
          id={addChildFormNameFieldId}
          component={InputTextField}
          type="text"
        />
      </Layout>

      <Layout minWidth="240" marginBottom="24" marginLeft={marginLeft}>
        <FlexLayout mainaxis="column" marginBottom="8">
          <Label htmlFor={addChildFormBirthYearFieldId}>
            {t('Birth year')}
          </Label>
        </FlexLayout>

        <DropdownYearsProfileForm yearsOptions={yearsOptions} minWidth={100} />
      </Layout>

      <Layout minWidth="240" marginBottom="24" marginLeft={marginLeft}>
        <FlexLayout mainaxis="column" marginBottom="8">
          <Label htmlFor={addChildFormGenderFieldId}>{t('Gender')}</Label>
        </FlexLayout>

        <DropdownGenderProfileForm minWidth={180} onChange={onChangeGender} />
      </Layout>
    </FlexLayout>
  );

  return (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-around"
      flexGrow="2"
      matchParentHeight
    >
      <form onSubmit={handleSubmit} id={formName}>
        <RenderWhen screenSize={ScreenSize.Desktop}>
          {renderFields('row', '16')}
        </RenderWhen>
        <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
          {renderFields('column')}
        </RenderWhen>
        <FlexLayout
          mainaxis="column"
          mainaxisAlignment="center"
          crossaxisAlignment="center"
        >
          <Layout marginTop="32" marginBottom="24">
            <StyledHeader type="h5">
              <strong>{t('Select an Avatar')}</strong>
            </StyledHeader>
          </Layout>
          <AvatarRadioButtonGroup
            avatars={List(avatarTabs[genderTabIndex].avatars)}
            name="picture"
            disabled={!isGenderSelected}
            selectedValue={formRecord.picture}
          />
        </FlexLayout>
      </form>
      <FlexLayout
        mainaxis="column"
        mainaxisAlignment="center"
        crossaxisAlignment="center"
        marginTop="48"
        flexGrow="0"
      >
        <Button
          htmlType="submit"
          size={ButtonSize.medium}
          form={formName}
          disabled={disableSubmit || submitting}
          loading={submitting}
        >
          {t('Save and continue')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};

const DropdownGenderProfileForm: React.FunctionComponent<{
  minWidth: number;
  onChange: (value: Gender) => void;
}> = ({ minWidth, onChange }) => {
  return (
    <Field
      name="gender"
      id={addChildFormGenderFieldId}
      component={({ input: props }) => (
        <Dropdown
          actionElement={
            <ActionInput
              {...props}
              block
              iconPosition={ActionInputIconPosition.right}
              text={getGenderText(props.value)}
              icon={<Icon type={IconType.angleDown} size={IconSize.x2} />}
              onKeyDown={(ev: React.SyntheticEvent) => {
                ev.preventDefault();
              }}
            />
          }
          testId="gender"
          onChange={value => {
            props.onChange(value);
            onChange(value);
          }}
          fixedMenuPlacement
          minWidth={minWidth}
          menuPlacement={DropdownMenuPlacement.bottom}
        >
          <DropdownOption
            text={t('Boy')}
            value={Gender.MALE}
            key={Gender.MALE}
            selected={props.value === Gender.MALE}
          />
          <DropdownOption
            text={t('Girl')}
            value={Gender.FEMALE}
            key={Gender.FEMALE}
            selected={props.value === Gender.FEMALE}
          />
          <DropdownOption
            text={t('Prefer not to say')}
            value={Gender.UNSPECIFIED}
            key={Gender.UNSPECIFIED}
            selected={props.value === Gender.UNSPECIFIED}
          />
        </Dropdown>
      )}
    />
  );
};

const DropdownYearsProfileForm: React.FunctionComponent<{
  yearsOptions: JSX.Element[];
  minWidth: number;
}> = ({ yearsOptions, minWidth }) => {
  return (
    <Field
      name="birthDate"
      id={addChildFormBirthYearFieldId}
      component={({ input: props }) => (
        <Dropdown
          minWidth={minWidth}
          maxHeight={200}
          actionElement={
            <ActionInput
              {...props}
              block
              text={props.value.replace('-01-01', '')}
              iconPosition={ActionInputIconPosition.right}
              icon={<Icon type={IconType.angleDown} size={IconSize.x2} />}
              onKeyDown={(ev: React.SyntheticEvent) => {
                ev.preventDefault();
              }}
            />
          }
          testId="birthDate"
          onChange={props.onChange}
          fixedMenuPlacement
          menuPlacement={DropdownMenuPlacement.bottom}
        >
          {yearsOptions}
        </Dropdown>
      )}
    />
  );
};

const getGenderText = gender => {
  switch (gender) {
    case Gender.FEMALE:
      return t('Girl');
    case Gender.MALE:
      return t('Boy');
    case Gender.UNSPECIFIED:
      return t('Prefer not to say');
    default:
      return '';
  }
};

const validate = values => {
  const errors: {
    name?: string;
    gender?: string;
    birthDate?: string;
    picture?: string;
  } = {};
  const requiredFieldErrorMessage = t('This field is required');

  if (!values.name) {
    errors.name = requiredFieldErrorMessage;
  }

  if (values.name && values.name.length > 40) {
    errors.name = t('This name is too long (40 characters max.)');
  }

  if (!values.birthDate) {
    errors.birthDate = requiredFieldErrorMessage;
  }

  if (!values.gender) {
    errors.gender = requiredFieldErrorMessage;
  }

  if (!values.picture) {
    errors.picture = requiredFieldErrorMessage;
  }

  return errors;
};

export type AddChildFormRecord = {
  id: number | null;
  name: string | null;
  gender: Gender | null;
  birthDate: string | null;
  picture: string | null;
};

export const AddChildFormRecord = Record<AddChildFormRecord, {}>({
  id: null,
  name: null,
  gender: null,
  birthDate: null,
  picture: null,
});

export const formRecord = (values: AddChildFormRecord): AddChildFormRecord => {
  if (R.isNil(values)) {
    return AddChildFormRecord();
  }

  return values instanceof AddChildFormRecord
    ? values
    : AddChildFormRecord(values);
};

const AddChildFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  validate: (values: any) => validate(values.toJS()),
  destroyOnUnmount: false,
})(AddChildForm);

const mapStateToProps = (state, ownProps: any) => {
  return {
    disableSubmit: Object.values(
      validate((getFormValues(formName)(state) ?? AddChildFormRecord()).toJS())
    ).some(value => value !== undefined),
    formRecord: formRecord(getForm(state, formName) as AddChildFormRecord),
    ...ownProps,
  };
};

const AddChildFormConnected = connect(mapStateToProps)(AddChildFormReduxForm);

export default AddChildFormConnected;
