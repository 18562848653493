import * as R from 'ramda';
import { t } from '../lib/i18n';
import { goBackIfHistory } from '../ducks/routing';
import { gaEvent } from '../helpers';
import { showToast, TOAST_ICON_TICK, TOAST_ICON_WARNING } from '../ducks/toast';
import {
  updateDevice,
  modifyDevice,
  modifyDeviceUserStatus,
  modifyDeviceSettings,
  revertDeviceUpdate,
  removeDevice,
} from '../ducks/devices';
import { deleteDeviceRecord } from '../ducks/records';
import { fetchProfiles } from '../ducks/profiles';
import { getDevice } from '../selectors';
import {
  track,
  Events,
  trackDeviceSettingUpdate,
  SettingsToggleType,
} from '../helpers/analytics';
import { getMultiPlatformNavigation } from '../helpers/multiPlatformNavigation';
import { APPThunk } from '../helpers/thunks';

export const confirmRemove =
  (deviceId: number): APPThunk =>
  dispatch => {
    track(Events.ClickedDeviceRemove);
    const navigate = getMultiPlatformNavigation();
    return dispatch(
      navigate({
        type: 'inner',
        src: `/devices/${deviceId}/modal/RemoveDeviceModal`,
      })
    );
  };

export const removeDeviceAction =
  (deviceId: number): APPThunk =>
  (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);

    const promise = device
      ? dispatch(removeDevice({ uid: device.uid, id: device.id.toString() }))
          .then(() => dispatch(goBackIfHistory()))
          .then(() =>
            dispatch(
              showToast(
                t('{{deviceName}} has been removed from {{shortName}}', {
                  deviceName: device.name,
                }),
                TOAST_ICON_TICK
              )
            )
          )
          .then(() => dispatch(fetchProfiles()))
          .then(() => dispatch(deleteDeviceRecord(device.id)))
      : // eslint-disable-next-line prefer-promise-reject-errors
        Promise.reject('');

    return promise.catch(() => {
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
  };

const updateDeviceUsersStatusProperty =
  (property, gaTrackEvent = () => {}, analyticsTrack: Function = () => {}) =>
  (deviceId, value) =>
  (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);

    let promise;

    if (device) {
      const users = device
        .get('users')!
        .map(user => user.setIn(['status', property, 'status'], !value));

      promise = Promise.resolve(device.set('users', users))
        .then(R.tap(R.pipe(updateDevice, dispatch)))
        .then(R.pipe(modifyDeviceUserStatus, dispatch))
        .then(gaTrackEvent)
        .then(analyticsTrack(value));
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      promise = Promise.reject('');
    }

    return promise.catch(() => {
      dispatch(revertDeviceUpdate());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
  };

export const togglePlatformEnabled = updateDeviceUsersStatusProperty(
  'disableProtection',
  () => gaEvent('device-view', 'protection-toggle', 'click')
);

export const toggleVPNEnabled = updateDeviceUsersStatusProperty(
  'vpnDisable',
  () => gaEvent('ios-device-view', 'qustodio-vpn-toggle ', 'click'),
  isEnabled =>
    trackDeviceSettingUpdate(SettingsToggleType.IosVpnConfiguration, isEnabled)
);

const updateDeviceSetting =
  (settingName, gaTrackEvent = () => {}, analyticsTrack) =>
  (deviceId, value) =>
  (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);

    const promise = device
      ? Promise.resolve(
          device.setIn(['settings', 'platform', settingName], value)
        )
          .then(R.tap(R.pipe(updateDevice, dispatch)))
          .then(R.pipe(modifyDeviceSettings, dispatch))
          .then(gaTrackEvent)
          .then(analyticsTrack(value))
      : // eslint-disable-next-line prefer-promise-reject-errors
        Promise.reject('');

    return promise.catch(() => {
      dispatch(revertDeviceUpdate());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
  };

export const toggleProtectAndroidSettings = updateDeviceSetting(
  'lockAndroidSettings',
  () => gaEvent('android-device-view', 'android-settings-toggle', 'click'),
  isEnabled =>
    trackDeviceSettingUpdate(
      SettingsToggleType.AndroidSettingsBlocker,
      isEnabled
    )
);

export const toggleProtectQustodioConfiguration = updateDeviceSetting(
  'lockQustodioConfiguration',
  () => gaEvent('android-device-view', 'qustodio-settings-toggle', 'click'),
  isEnabled =>
    trackDeviceSettingUpdate(
      SettingsToggleType.AndroidQustodioConfiguration,
      isEnabled
    )
);

export const toggleHideQustodio =
  (deviceId, toggleOn) => (dispatch, getState) => {
    const device = getDevice(getState(), deviceId);

    const promise = device
      ? Promise.resolve(device.set('hideTrayicon', toggleOn ? 1 : 0))
          .then(R.tap(R.pipe(updateDevice, dispatch)))
          .then(R.pipe(modifyDevice, dispatch))
          .then(() => gaEvent('pc-device-view', 'hide-toggle', 'click'))
          .then(() =>
            trackDeviceSettingUpdate(
              SettingsToggleType.DesktopHideQustodioConfiguration,
              toggleOn
            )
          )
      : // eslint-disable-next-line prefer-promise-reject-errors
        Promise.reject('');

    return promise.catch(() => {
      dispatch(revertDeviceUpdate());
      dispatch(
        showToast(
          [t('Something went wrong.'), t('Please try again.')],
          TOAST_ICON_WARNING
        )
      );
    });
  };

export const openDeviceLocation = deviceId => () => (dispatch, getState) => {
  const device = getDevice(getState(), deviceId);
  const navigate = getMultiPlatformNavigation();

  if (device && device.location.longitude) {
    dispatch(
      navigate({
        type: 'map:location',
        src: device.location,
        label: device.name,
      })
    );
  }
};
