import * as React from 'react';
import { FlexLayout, Typography } from 'styleguide-react';
import { t } from '../../../../../lib/i18n';
import TimePicker from '../../../../TimePicker/TimePicker';

type TimeRange = {
  from: string;
  to: string;
};

interface TimeRangeDropdownProps {
  testId: string;
  label: string;
  value: string;
  isMilitaryTime: boolean;
  onChange: (value: string) => void;
  onValidationError: (invalid: boolean) => void;
}

const TimeRangeDropdown = ({
  testId,
  label,
  value,
  isMilitaryTime,
  onChange,
  onValidationError,
}: TimeRangeDropdownProps) => {
  return (
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="column" marginBottom="8">
        <Typography type="body2">{label}</Typography>
      </FlexLayout>
      <TimePicker
        testId={testId}
        onChange={onChange}
        value={value}
        isMilitaryTime={isMilitaryTime}
        okText={t('Ok')}
        cancelText={t('Cancel')}
        onValidationError={onValidationError}
      />
    </FlexLayout>
  );
};

type Label = 'from' | 'to';

export interface TimeRangeSelectProps {
  isMilitaryTime: boolean;
  timeRangeValues: TimeRange;
}

interface TimeRangeSelectComponentProps extends TimeRangeSelectProps {
  testId: string;
  onChangeTimeRange: (label: Label, value: string) => void;
  onValidationError: (label: Label, invalid: boolean) => void;
}

const TimeRangeSelect = ({
  testId,
  isMilitaryTime,
  timeRangeValues,
  onChangeTimeRange,
  onValidationError,
}: TimeRangeSelectComponentProps) => {
  const labels: Array<Label> = ['from', 'to'];

  return (
    <FlexLayout
      testId={testId}
      mainaxis="column"
      className="rck-routines-time-range-select"
    >
      <FlexLayout mainaxis="column" marginBottom="24">
        <Typography type="body1">{t('Time:')}</Typography>
      </FlexLayout>

      <FlexLayout
        mainaxis="row"
        className="rck-routines-time-range-select__range"
      >
        {labels.map(label => {
          return (
            <FlexLayout
              mainaxis="column"
              key={label}
              className="rck-routines-time-range-select__dropdown"
              width="50%"
            >
              <TimeRangeDropdown
                testId={`${testId}-${label}`}
                label={label === 'from' ? t('From') : t('To')}
                value={timeRangeValues[label]}
                isMilitaryTime={isMilitaryTime}
                onChange={value => onChangeTimeRange(label, value)}
                onValidationError={invalid => onValidationError(label, invalid)}
              />
            </FlexLayout>
          );
        })}
      </FlexLayout>
    </FlexLayout>
  );
};

export default TimeRangeSelect;
