import * as React from 'react';
import { List } from 'immutable';
import { t } from '../../lib/i18n';
import { NotificationRecord } from '../../records/notification';
import InsightsNotificationList from './InsightsNotificationList';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const InsightsNotificationPage: React.FunctionComponent<{
  notifications: List<NotificationRecord>;
  locale: string;
  isFetching: boolean;
  hasMore: boolean;
  isEmpty: boolean;
  handleMore: () => void;
}> = ({ notifications, locale, isFetching, hasMore, handleMore, isEmpty }) => (
  <PageLayout
    title={t('App Insigths')}
    actions={[{ actionName: 'back', screenSize: ScreenSize.MobileOrTablet }]}
  >
    <InsightsNotificationList
      notifications={notifications}
      locale={locale}
      isFetching={isFetching}
      hasMore={hasMore}
      handleMore={handleMore}
      isEmpty={isEmpty}
    />
  </PageLayout>
);

export default InsightsNotificationPage;
