import * as React from 'react';
import { Marker } from '../../records/marker';

const DirectionsButton: React.FunctionComponent<{
  className: string;
  marker: Marker | undefined;
  onClick: (marker) => any;
}> = ({ className = '', children, marker, onClick }) => (
  <a
    className={`DirectionsButton ${className}`}
    onClick={() => {
      return onClick(marker);
    }}
  >
    {children}
  </a>
);

export default DirectionsButton;
