import * as React from 'react';
import {
  Avatar,
  AvatarSize,
  AvatarVariant,
  Card,
  FlexLayout,
  StyledHeader,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { SignatureReviewer } from '../../../records/studentActivity/signature/types/SignatureReviewer.types';

const ExpertReviewerCard: React.FunctionComponent<SignatureReviewer> = ({
  name,
  description,
  profileImageUrl,
}: SignatureReviewer) => (
  <Card className="ExpertReviewerCard" fullWidth>
    <StyledHeader type="h5">
      <strong>{t('Expert reviewer')}</strong>
    </StyledHeader>
    <FlexLayout mainaxis="column" className="content">
      <Avatar variant={AvatarVariant.image} size={AvatarSize.large}>
        <img src={profileImageUrl} alt="profile pic" />
      </Avatar>
      <FlexLayout mainaxis="column" className="expert-desc">
        <StyledHeader type="h4">{name}</StyledHeader>
        <p>{description}</p>
      </FlexLayout>
    </FlexLayout>
  </Card>
);

export default ExpertReviewerCard;
