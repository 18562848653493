import * as React from 'react';
import { MultiStep } from 'styleguide-react';

import SelectRoutineType from '../steps/SelectRoutineType/SelectRoutineType';
import SelectRoutineBlockType from '../steps/SelectBlockType/SelectBlockType';
import SelectNameAndStyle from '../steps/SelectNameAndStyle/SelectNameAndStyle';
import ScheduleAtimeSlot from '../steps/ScheduleAtimeSlot/ScheduleAtimeSlot';
import SelectAppExceptions from '../steps/SelectAppExceptions/SelectAppExceptions';
import SelectCategoryExceptions from '../steps/SelectCategoryExceptions/SelectCategoryExceptions';

import {
  RoutineCreationMode,
  RoutineMode,
  MultiStepDataContext,
  RoutineSteps,
  RoutineRuleExceptionType,
  TimeLimitType,
  TimeSlot,
  AppRuleType,
  WebRuleType,
  RoutineFeatureAccessLevel,
} from '../routines.types';
import { AppRuleRecord } from '../../../records/profileRules';
import { List } from 'immutable';
import { DataContext, useCreateMultiStepMachine } from './multiStepMachine';
import { PolicyApplication } from '../../../records/routines/policy/types/PolicyApplication.types';
import { PolicyWebCategory } from '../../../records/routines/policy/types/PolicyWebCategory.types';
import {
  RoutineIconType,
  RoutineRecord,
} from '../../../records/routines/types/Routine.types';
import { PartialDeep } from '../../../types/PartialDeep.types';
import { difference, update } from 'ramda';
import { exhaustiveCheck } from '../../../helpers/validations';
import EditRoutine from '../steps/EditRoutine/EditRoutine';
import { DeepPartial } from 'redux';
import { RoutineMachineAction } from '../../../ducks/routines';
import { RoutineColor } from '../../../palettes/RoutineColor';
import { PolicyAction } from '../../../records/routines/policy/types/Policy.types';
import SelectPreset from '../steps/SelectPreset/SelectPreset';
import { getUniqueName, isEditRoutine } from '../helpers/steps';
import ChooseAppRules from '../steps/ChooseRules/ChooseAppRules';
import ChooseWebRules from '../steps/ChooseRules/ChooseWebRules';
import ChooseTimeLimit from '../steps/ChooseTimeLimit/ChooseTimeLimit';
import PremiumBasicSelectRoutineType from '../steps/PremiumBasicSelectRoutineType/PremiumBasicSelectRoutineType';
import PremiumUpgradeInfo from '../steps/PremiumUpgradeInfo/PremiumUpgradeInfo';
import { AppCategories } from '../../../ducks/appCategories';

export interface Overlap {
  hasOverlap: boolean;
  routineUid: string | null;
  routine: RoutineRecord | null;
}

export interface CreateRoutineMultiStepProps {
  mode: RoutineMode;
  featureAccessLevel: RoutineFeatureAccessLevel;
  routine?: MultiStepDataContext;
  routinesList: List<RoutineRecord>;
  appsList: List<AppRuleRecord>;
  activeStep: RoutineSteps | null;
  appRulesList: List<AppRuleRecord>;
  paused: boolean;
  isMilitaryTime: boolean;
  machineAction: RoutineMachineAction;
  profileName: string;
  isFetchingTimeSlots?: boolean;
  isUpdatingRoutine?: boolean;
  categories: AppCategories;
  isValidName: (name: string) => boolean;
  editRoutine: (
    routine: DeepPartial<MultiStepDataContext>,
    data: Partial<MultiStepDataContext>
  ) => void;
  deleteRoutine: (uid: string) => void;
  closeDrawer: (options: {
    mode: RoutineMode;
    step: RoutineSteps;
    isFinalized: boolean;
  }) => void;
  onClickInfo: (
    step: RoutineSteps,
    options?: {
      type?: MultiStepDataContext['block'];
      mode?: RoutineMode;
      timeLimitType?: TimeLimitType;
    }
  ) => void;
  createRoutine: <T>(data: T) => void;
  upsertTimeSlot: (routineUid: string, slot: TimeSlot) => void;
  removeExceptions: (
    type: RoutineRuleExceptionType,
    exceptions:
      | ReadonlyArray<PolicyApplication>
      | ReadonlyArray<PolicyWebCategory>,
    context: PartialDeep<MultiStepDataContext>
  ) => PartialDeep<MultiStepDataContext>;
  deleteTimeSlot: (
    routineUid: string,
    timeSlotUid: string,
    origin: 'detail' | 'edit'
  ) => void;
  isOverlapped: (timeSlot: TimeSlot) => Overlap;
  canEnableTimeSlots: (routineUid: string) => Overlap;
  goToCantEnableTimeSlots: (routineUid: string) => void;
  onChangeContentFilterRuleType: (
    data: {
      activeTab: RoutineRuleExceptionType;
      appRuleType: AppRuleType;
      webRuleType: WebRuleType;
    },
    routine: DeepPartial<MultiStepDataContext>,
    mode: RoutineMode
  ) => void;
  goToSetContentFilterRulesInfo: (type: RoutineRuleExceptionType) => void;
  onRemoveAllExceptions: (
    routineUid: string | undefined,
    type: RoutineRuleExceptionType,
    mode: RoutineMode,
    policyAction?: PolicyAction
  ) => void;
  onSetRoutineCreationMode: (mode: RoutineCreationMode) => void;
  onUpgrade: (redirectConfirmation: boolean) => void;
}

const removeExceptions = (
  type: RoutineRuleExceptionType,
  exceptions:
    | ReadonlyArray<PolicyApplication>
    | ReadonlyArray<PolicyWebCategory>,
  context: PartialDeep<MultiStepDataContext>
) => {
  if (type === 'WEB') {
    return {
      contentFilter: {
        websException: difference(
          context.contentFilter?.websException ?? [],
          exceptions as ReadonlyArray<PolicyWebCategory>
        ),
      },
    };
  }

  if (type === 'APP') {
    return {
      contentFilter: {
        appsException: difference(
          context.contentFilter?.appsException ?? [],
          exceptions as ReadonlyArray<PolicyApplication>
        ),
      },
    };
  }

  return exhaustiveCheck(type);
};

const findEditedTimeSlot = (data: DataContext) => {
  return data.timeSlot?.find(
    timeSlot => timeSlot?.uid === data.editedTimeSlotUid
  );
};

const updateTimeSlot = (timeSlot: TimeSlot, routine: DataContext) => {
  const pos = routine.timeSlot?.findIndex(
    timeSlot => timeSlot?.uid === routine.editedTimeSlotUid
  );
  return update(pos!, timeSlot, routine.timeSlot!);
};

const getTimeSlotByMode = (mode: RoutineMode, routine: DataContext) => {
  const timeSlot =
    mode === 'EDIT'
      ? findEditedTimeSlot(routine)
      : // for when creating from a preset
        routine?.timeSlot?.[0] || null;

  const days = timeSlot?.days || [];

  const timeRangeValues = timeSlot
    ? { from: timeSlot?.from, to: timeSlot?.to }
    : { from: '09:00', to: '17:00' };

  const timeSlotUid = mode === 'EDIT' ? timeSlot?.uid : undefined;

  return {
    days,
    timeRangeValues,
    timeSlotUid,
  };
};

const isTimeSlotToAdd = (timeSlot: TimeSlot) => !timeSlot.uid;

const addTimeSlot = (timeSlot: TimeSlot, routine: DataContext) =>
  routine.timeSlot?.concat(timeSlot);

const upsertTimesSlot = (
  routine: PartialDeep<MultiStepDataContext>,
  timeSlot: TimeSlot
) =>
  isTimeSlotToAdd(timeSlot)
    ? addTimeSlot(timeSlot, routine)
    : updateTimeSlot(timeSlot, routine);

const doesTimeLimitApply = (timeLimitType?: TimeLimitType) =>
  timeLimitType === 'APPLY_DAILY_TIME_LIMITS';

const onRemoveAllExceptionsHandler =
  (state, mode, actions, onRemoveAllExceptions, editRoutine) =>
  (exceptions, type, removeAll) => {
    if (removeAll) {
      const policyAction =
        type === 'APP'
          ? state.context.data.contentFilter?.appRuleType
          : state.context.data.contentFilter?.webRuleType;

      onRemoveAllExceptions(
        state.context.data.uid,
        type,
        mode,
        policyAction as PolicyAction
      );
    } else {
      const data = removeExceptions(type, exceptions, state.context.data);
      actions.updateContext(data);
      mode === 'EDIT' && editRoutine(state.context.data, data);
    }
  };

const getContentFilterState = (
  state: ReturnType<typeof useCreateMultiStepMachine>[0]
) => state.context.data.contentFilter;

const CreateRoutineMultiStep = ({
  mode,
  routine,
  routinesList,
  appRulesList,
  isMilitaryTime,
  featureAccessLevel,
  machineAction = { type: null, params: null },
  isValidName,
  profileName,
  isOverlapped,
  isFetchingTimeSlots,
  isUpdatingRoutine,
  categories,
  totalAppsByCategory,
  closeDrawer,
  onClickInfo,
  createRoutine,
  editRoutine,
  deleteRoutine,
  upsertTimeSlot,
  deleteTimeSlot,
  canEnableTimeSlots,
  goToCantEnableTimeSlots,
  onChangeContentFilterRuleType,
  goToSetContentFilterRulesInfo,
  onRemoveAllExceptions,
  onSetRoutineCreationMode,
  onUpgrade,
}: CreateRoutineMultiStepProps) => {
  const [state, actions] = useCreateMultiStepMachine({
    routine,
    mode,
    machineAction,
    onFinish: createRoutine,
    featureAccessLevel,
  });

  const timeLimitApply = doesTimeLimitApply(
    state.context.data.contentFilter?.timeLimitType
  );

  const handleExit = ({ isFinalized }: { isFinalized: boolean }) => {
    closeDrawer({
      mode: state.context.data.mode!,
      step: state.value as RoutineSteps,
      isFinalized,
    });
  };

  const allRoutineNames = React.useMemo(
    () => routinesList.map(routine => routine.name).toArray(),
    [routinesList]
  );

  return (
    <MultiStep
      activeStep={state.value as RoutineSteps}
      steps={[
        {
          name: RoutineSteps.selectPreset,
          step: () => (
            <SelectPreset
              appRulesList={appRulesList}
              next={contextData => {
                if (contextData) {
                  const updatedContextData = { ...contextData };
                  updatedContextData.style.name = getUniqueName(
                    allRoutineNames,
                    contextData.style.name
                  );
                  // when preset routine
                  actions.next(updatedContextData, 'SCHEDULE_TIME_SLOT');
                  onSetRoutineCreationMode('preset');
                } else {
                  // when custom routine
                  actions.resetDataContextInCreateMode();
                  actions.next({}, 'EDIT_RULES');
                  onSetRoutineCreationMode('custom');
                }
              }}
              close={() => handleExit({ isFinalized: false })}
            />
          ),
        },
        {
          name: RoutineSteps.premiumBasicSelectRoutineType,
          step: () => (
            <PremiumBasicSelectRoutineType
              close={() => handleExit({ isFinalized: false })}
              next={block => {
                actions.resetDataContextInCreateMode();
                onSetRoutineCreationMode('custom');

                if (!block || block === 'POLICY_BLOCK_TYPE_NONE') {
                  // When "block" is null, we will redirect the user to the upgrade page
                  actions.next({}, 'UPGRADE_TO_PREMIUM');
                } else {
                  actions.next({ block, type: 'ROUTINE_TYPE_BLOCKED' });
                }
              }}
            />
          ),
        },
        {
          name: RoutineSteps.premiumUpgrade,
          step: () => (
            <PremiumUpgradeInfo
              close={() => {
                // We want to finalize the flow if mode is create with guest access (no confirmation needed)
                const isFinalized =
                  mode === 'CREATE' &&
                  featureAccessLevel === RoutineFeatureAccessLevel.guest;

                handleExit({ isFinalized });
              }}
              prev={
                // For 'guest' access level the drawer is opened from
                // idle state to this one so we do not want to show
                // the 'back' button.
                //
                // In a future refactor task, logic like this will be moved
                // to the state machine to have only one source of truth.
                featureAccessLevel === RoutineFeatureAccessLevel.guest ||
                (mode === 'EDIT' &&
                  featureAccessLevel ===
                    RoutineFeatureAccessLevel.routineBlocking)
                  ? undefined
                  : actions.prev
              }
              next={() => {
                // we want to directly navigate to upgrade page (with no confirmation) when guest access
                const shouldShowRedirectConfirmation =
                  featureAccessLevel !== RoutineFeatureAccessLevel.guest;

                return onUpgrade(shouldShowRedirectConfirmation);
              }}
              onDelete={
                // 'guest' access level users cannot remove routines in edit mode
                featureAccessLevel !== RoutineFeatureAccessLevel.guest &&
                isEditRoutine(mode) &&
                state.context.data.uid
                  ? () => deleteRoutine(state.context.data.uid as string)
                  : undefined
              }
            />
          ),
        },
        {
          name: RoutineSteps.selectRoutineType,
          step: () => (
            <SelectRoutineType
              prev={actions.prev}
              next={type => {
                if (type === 'ROUTINE_TYPE_MANAGEMENT') {
                  actions.updateContext({ block: 'POLICY_BLOCK_TYPE_NONE' });
                }
                actions.next({ type });
              }}
              close={() => handleExit({ isFinalized: false })}
            />
          ),
        },
        {
          name: RoutineSteps.chooseAppRules,
          step: () => (
            <ChooseAppRules
              mode={mode}
              prev={actions.prev}
              close={() => handleExit({ isFinalized: false })}
              next={appRuleType => {
                actions.next({ contentFilter: { appRuleType } });
              }}
              isUpdatingRoutine={isUpdatingRoutine || false}
              ruleType={state.context.data.contentFilter?.appRuleType}
              exceptions={state.context.data.contentFilter?.appsException}
              onSelectRuleType={appRuleType => {
                if (appRuleType === getContentFilterState(state)?.appRuleType)
                  return undefined;

                onChangeContentFilterRuleType(
                  {
                    appRuleType,
                    activeTab: 'APP',
                    webRuleType:
                      state.context.data.contentFilter?.webRuleType ||
                      'DEFAULT',
                  },
                  state.context.data,
                  mode
                );
              }}
              onRemoveExceptions={onRemoveAllExceptionsHandler(
                state,
                mode,
                actions,
                onRemoveAllExceptions,
                editRoutine
              )}
              onAddExceptions={contentFilter => {
                if (contentFilter.appRuleType !== 'DEFAULT') {
                  actions.next({ contentFilter }, 'APP');
                } else {
                  goToSetContentFilterRulesInfo('APP');
                }
              }}
            />
          ),
        },
        {
          name: RoutineSteps.chooseWebRules,
          step: () => (
            <ChooseWebRules
              mode={mode}
              prev={actions.prev}
              close={() => handleExit({ isFinalized: false })}
              next={webRuleType => {
                actions.next({ contentFilter: { webRuleType } });
              }}
              isUpdatingRoutine={isUpdatingRoutine || false}
              ruleType={state.context.data.contentFilter?.webRuleType}
              exceptions={state.context.data.contentFilter?.websException}
              onSelectRuleType={webRuleType => {
                if (webRuleType === getContentFilterState(state)?.webRuleType)
                  return undefined;

                onChangeContentFilterRuleType(
                  {
                    webRuleType,
                    activeTab: 'WEB',
                    appRuleType:
                      state.context.data.contentFilter?.appRuleType ||
                      'DEFAULT',
                  },
                  state.context.data,
                  mode
                );
              }}
              onRemoveExceptions={onRemoveAllExceptionsHandler(
                state,
                mode,
                actions,
                onRemoveAllExceptions,
                editRoutine
              )}
              onAddExceptions={contentFilter => {
                if (contentFilter.webRuleType !== 'DEFAULT') {
                  actions.next({ contentFilter }, 'WEB');
                } else {
                  goToSetContentFilterRulesInfo('WEB');
                }
              }}
            />
          ),
        },
        {
          name: RoutineSteps.chooseTimeLimitOption,
          step: () => (
            <ChooseTimeLimit
              prev={actions.prev}
              close={() => handleExit({ isFinalized: false })}
              next={timeLimitType => {
                actions.next({ contentFilter: { timeLimitType } });
              }}
              timeLimitType={state.context.data.contentFilter?.timeLimitType}
            />
          ),
        },
        {
          name: RoutineSteps.selectBlockType,
          step: () => (
            <SelectRoutineBlockType
              mode={mode}
              type={state.context.data.block!}
              name={state.context.data.style?.name || ''}
              onClickMoreInfo={type =>
                onClickInfo(RoutineSteps.selectBlockType, { type })
              }
              close={() => handleExit({ isFinalized: false })}
              prev={actions.prev}
              next={block => {
                actions.next({ block });
                mode === 'EDIT' && editRoutine(state.context.data, { block });
              }}
            />
          ),
        },
        {
          name: RoutineSteps.chooseNameAndStyle,
          step: () => (
            <SelectNameAndStyle
              showBadge={timeLimitApply}
              mode={mode}
              name={state.context.data.style?.name || ''}
              description={state.context.data.style?.description || ''}
              color={state.context.data.style?.color || RoutineColor.yellow}
              icon={state.context.data.style?.icon || 'backpack'}
              profileName={profileName}
              isValidName={isValidName}
              close={() => handleExit({ isFinalized: false })}
              prev={actions.prev}
              next={style => {
                actions.next({ style });
                mode === 'EDIT' && editRoutine(state.context.data, { style });
              }}
            />
          ),
        },
        {
          name: RoutineSteps.scheduleATimeSlot,
          step: () => {
            const { data } = state.context;
            const { timeRangeValues, days, timeSlotUid } = getTimeSlotByMode(
              mode,
              data
            );

            return (
              <ScheduleAtimeSlot
                mode={mode}
                showBadge={timeLimitApply}
                name={state.context.data.style?.name as string}
                description={state.context.data.style?.description as string}
                color={state.context.data.style?.color as RoutineColor}
                icon={state.context.data.style?.icon as RoutineIconType}
                isMilitaryTime={isMilitaryTime}
                timeRangeValues={timeRangeValues}
                timeSlotUid={timeSlotUid}
                routineUid={state.context.data.uid}
                isOverlapped={isOverlapped}
                days={days}
                close={() => handleExit({ isFinalized: false })}
                prev={actions.prev}
                next={timeSlot => {
                  if (mode === 'CREATE') {
                    actions.complete({ timeSlot: [timeSlot] });
                    handleExit({ isFinalized: true });
                  }

                  if (mode === 'EDIT') {
                    const routine = state.context.data;
                    const updatedTimeSlot = upsertTimesSlot(routine, timeSlot);
                    const data = { timeSlot: updatedTimeSlot };

                    actions.next(data);
                    if (routine?.uid) upsertTimeSlot(routine.uid, timeSlot);
                  }
                }}
                onDelete={(routineUid, timeSlotUid) =>
                  deleteTimeSlot(routineUid!, timeSlotUid!, 'detail')
                }
              />
            );
          },
        },
        {
          name: RoutineSteps.selectAppsException,
          step: () => (
            <SelectAppExceptions
              mode={mode}
              action={state.context.data.contentFilter?.appRuleType as any}
              appRulesList={appRulesList}
              categories={categories}
              totalAppsByCategory={totalAppsByCategory}
              selectedApps={state.context.data.contentFilter?.appsException}
              prev={actions.prev}
              close={() => handleExit({ isFinalized: false })}
              next={appsException => {
                const data = { contentFilter: { appsException } };
                actions.next(data);
                mode === 'EDIT' && editRoutine(state.context.data, data);
              }}
            />
          ),
        },
        {
          name: RoutineSteps.selectWebsException,
          step: () => (
            <SelectCategoryExceptions
              mode={mode}
              action={state.context.data.contentFilter?.webRuleType as any}
              selectedWebCategories={
                state.context.data.contentFilter?.websException
              }
              prev={actions.prev}
              close={() => handleExit({ isFinalized: false })}
              next={websException => {
                const data = { contentFilter: { websException } };
                actions.next(data);
                mode === 'EDIT' && editRoutine(state.context.data, data);
              }}
            />
          ),
        },
        {
          name: RoutineSteps.editRoutine,
          step: () => (
            <EditRoutine
              timeLimitApply={timeLimitApply}
              routineUid={state.context.data.uid!}
              type={state.context.data.block!}
              name={state.context.data.style?.name}
              icon={state.context.data.style?.icon}
              color={state.context.data.style?.color}
              description={state.context.data.style?.description}
              paused={state.context.data.paused!}
              timeSlots={state.context.data.timeSlot}
              isFetchingTimeSlots={isFetchingTimeSlots}
              contextData={state.context.data}
              isMilitaryTime={isMilitaryTime}
              onUpdate={() => handleExit({ isFinalized: false })}
              onDelete={() => {
                /*
                Must put state machine to state idle using actions.exit but uses hook
                because the delete confirmation is done in a modal(has no reference to the state machine).
                */
                deleteRoutine(state.context.data.uid);
              }}
              close={() => handleExit({ isFinalized: false })}
              onEditStyle={() => actions.transition('STYLE')}
              onChangePause={() => {
                const overlap = canEnableTimeSlots(state.context.data.uid!);
                const { paused } = state.context.data;

                if (!paused || !overlap.hasOverlap) {
                  const data = { paused: !state.context.data.paused };
                  actions.updateContext(data);
                  mode === 'EDIT' && editRoutine(state.context.data, data);
                } else {
                  goToCantEnableTimeSlots(overlap.routineUid!);
                }
              }}
              onEditRules={() => actions.transition('EDIT_RULES')}
              onEditAppRules={() => actions.transition('EDIT_APP_RULES')}
              onEditWebRules={() => actions.transition('EDIT_WEB_RULES')}
              onDeleteTimeSlot={(routineUid, timeSlotUid) =>
                deleteTimeSlot(routineUid, timeSlotUid, 'edit')
              }
              onEditTimeSlot={(_routineUid, timeSlotUid) => {
                actions.next(
                  { editedTimeSlotUid: timeSlotUid },
                  'SCHEDULE_TIME_SLOT'
                );
              }}
              onAddTimeSlot={() => {
                actions.next(
                  { editedTimeSlotUid: undefined },
                  'SCHEDULE_TIME_SLOT'
                );
              }}
              onClickTimeLimitInfo={() => onClickInfo(RoutineSteps.editRoutine)}
            />
          ),
        },
      ]}
    />
  );
};

export default CreateRoutineMultiStep;
