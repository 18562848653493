import { createSelector } from 'reselect';

import State from '../store/state';
import {
  MENU_OPTION_ACTIVITY_TIMELINE,
  MENU_OPTION_SUMMARY,
  PathTitles,
  ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION,
  ROUTE_PARENTS_APP_STANDALONE_WRONG_APP,
  MENU_OPTION_RULES,
  MENU_OPTION_STUDENT_ACTIVITY_TIMELINE,
  MENU_OPTION_STUDENT_SUMMARY,
  MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY,
} from '../constants';
import { strictPathMatcher } from '../helpers/routing';

export const getRouteParams = (state: State) =>
  state.get('routing').get('routeParams');

export const getHistoryKeys = (state: State) =>
  state
    .get('routing')
    .get('historyKeys')
    .toArray()
    .filter(key => key !== null);

export const getHistory = (state: State) =>
  state
    .get('routing')
    .get('history')
    .toArray()
    .filter(item => item.key !== null);

export const getLoginRedirectLocation = (state: State) =>
  state.get('routing').get('loginRedirectLocation');

export const getLocation = (state: State) =>
  state.get('routing').get('locationBeforeTransitions') || {
    pathname: '/',
    key: null,
    query: {},
  };

export const getLocationPathname = createSelector(
  getLocation,
  location => location.pathname
);

export const getLastLocationPathname = state =>
  state.get('routing').get('lastLocationPathname');

export const getSelectedRule = createSelector(getLocationPathname, pathname => {
  const profileBasePath = `/profiles/[0-9]+`;
  const rules = {
    'activity-timeline': MENU_OPTION_ACTIVITY_TIMELINE,
    rules: MENU_OPTION_RULES,
    'school/summary': MENU_OPTION_STUDENT_SUMMARY,
    'school/timeline': MENU_OPTION_STUDENT_ACTIVITY_TIMELINE,
    'family-and-school': MENU_OPTION_FAMILY_AND_SCHOOL_SUMMARY,
  };

  if (strictPathMatcher(profileBasePath).test(pathname)) {
    return MENU_OPTION_SUMMARY;
  }

  const rulePath = Object.keys(rules).find(rulePath =>
    strictPathMatcher(`${profileBasePath}/${rulePath}`).test(pathname)
  );

  return rulePath ? rules[rulePath] : null;
});

export const getSelectedPathTitle = createSelector(
  getLocationPathname,
  pathname => {
    const currentSelectedPath = (pathname.match(/[\w-]+/) || [])[0];

    const pathTitles = {
      'family-locator': PathTitles.familyLocator,
      devices: PathTitles.yourDevices,
      'account-settings': PathTitles.yourAccount,
      wellbeing: PathTitles.wellbeing,
      'wellbeing-therapy': PathTitles.wellbeing,
      'wellbeing-activities': PathTitles.wellbeing,
      modal: PathTitles.helpCenter,
    };

    return pathTitles[currentSelectedPath] || PathTitles.yourFamily;
  }
);

export const getQueryParam = (state: State, paramName: string) => {
  const location = getLocation(state);
  return location.query[paramName] || null;
};

export const getIsUpgradePageVisible = state =>
  getLocationPathname(state) === '/upgrade';

export const isUserInConfirmationFlowScreen = state => {
  const pathname = getLocationPathname(state);
  return (
    pathname.indexOf(ROUTE_PARENTS_APP_STANDALONE_CONFIRMATION) !== -1 ||
    pathname.indexOf(ROUTE_PARENTS_APP_STANDALONE_WRONG_APP) !== -1
  );
};

export const isSchoolOnboardingPath = (state: State) => {
  const pathname = getLocationPathname(state);
  return pathname.indexOf('/onboarding-schools/') === 0;
};

export const isRulesOrSubPagePath = (state: State) => {
  const pathname = getLocationPathname(state);
  const rulePathWithSubpages = /\/rules\/?.*$/;
  return rulePathWithSubpages.test(pathname);
};
