import * as React from 'react';

import { Record } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';

import {
  Icon,
  Label,
  Dropdown,
  IconType,
  IconSize,
  FlexLayout,
  ActionInput,
  DropdownOption,
  DropdownMenuPlacement,
  ActionInputIconPosition,
  Layout,
  DropdownElementOrientation,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import InputTextField from '../Form/InputTextField';
import { Gender } from '../../records/profile';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { toUIYear } from '../../helpers/appDataArrangement';
import { birthDayDropdown } from '../BirthDateDropdown/BirthDateDropdown';
import { AppDataArrangement } from '../../constants';

export const formName = 'profileAdd';

type ProfileFormComponentsProps = { handleSubmit: () => void };

type ReduxFormComponentProps = {
  input: { value: string; onChange: () => void };
};

export const ProfileFormComponent: React.FunctionComponent<
  ProfileFormComponentsProps
> = ({ handleSubmit }: ProfileFormComponentsProps) => {
  const yearsOptions = birthDayDropdown();

  return (
    <FlexLayout mainaxis="column" padding="32" flexGrow="2">
      <form onSubmit={handleSubmit} id={formName}>
        <Layout>
          <Layout marginBottom="8">
            <Label htmlFor="name">{t('Name')}</Label>
          </Layout>
          <Field
            block
            name="name"
            type="text"
            component={InputTextField}
            placeholder={t("Child's first name")}
          />
        </Layout>
        <FlexLayout
          marginTop="32"
          mainaxis="row"
          mainaxisAlignment="space-around"
        >
          <Layout paddingRight="16" minWidth="50%">
            <Label htmlFor="gender">{t('Gender')}</Label>
          </Layout>

          <Layout paddingLeft="16" minWidth="50%">
            <Label htmlFor="birthDate">{t('Birth year')}</Label>
          </Layout>
        </FlexLayout>
        <FlexLayout
          marginTop="8"
          mainaxis="row"
          mainaxisAlignment="space-around"
        >
          <Layout paddingRight="16" minWidth="50%">
            <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
              <DropdownGenderProfileForm minWidth={210} />
            </RenderWhen>
            <RenderWhen screenSize={ScreenSize.Desktop}>
              <DropdownGenderProfileForm />
            </RenderWhen>
          </Layout>
          <Layout paddingLeft="16" minWidth="50%">
            <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
              <DropdownYearsProfileForm
                yearsOptions={yearsOptions}
                minWidth={null}
                maxWidth={70}
              />
            </RenderWhen>
            <RenderWhen screenSize={ScreenSize.Desktop}>
              <DropdownYearsProfileForm
                yearsOptions={yearsOptions}
                minWidth={null}
                maxWidth={null}
              />
            </RenderWhen>
          </Layout>
        </FlexLayout>
        {/* include an invisible submit-button so that the mobile-keyboard
          has a "submit" button: */}
        <FlexLayout mainaxis="row">
          <button type="submit" style={{ display: 'none' }} />
        </FlexLayout>
      </form>
    </FlexLayout>
  );
};

type DropdownGenderProfileFormProps = {
  minWidth?: number;
};

const DropdownGenderProfileFormComponent = (
  { input }: ReduxFormComponentProps,
  minWidth
) => {
  return (
    <Dropdown
      actionElement={
        <ActionInput
          {...input}
          block
          placeholder={t('Select a gender')}
          iconPosition={ActionInputIconPosition.right}
          text={getGenderText(input.value)}
          icon={<Icon type={IconType.angleDown} size={IconSize.x2} />}
          onKeyDown={(ev: React.SyntheticEvent) => {
            ev.preventDefault();
          }}
        />
      }
      testId="gender"
      onChange={input.onChange}
      fixedMenuPlacement
      minWidth={minWidth}
      attachedMenuOriention={DropdownElementOrientation.right}
      menuPlacement={DropdownMenuPlacement.top}
    >
      <DropdownOption
        text={t('Boy')}
        value={Gender.MALE}
        key={Gender.MALE}
        selected={input.value === Gender.MALE}
      />
      <DropdownOption
        text={t('Girl')}
        value={Gender.FEMALE}
        key={Gender.FEMALE}
        selected={input.value === Gender.FEMALE}
      />
      <DropdownOption
        text={t('Prefer not to say')}
        value={Gender.UNSPECIFIED}
        key={Gender.UNSPECIFIED}
        selected={input.value === Gender.UNSPECIFIED}
      />
    </Dropdown>
  );
};
const DropdownGenderProfileForm: React.FunctionComponent<
  DropdownGenderProfileFormProps
> = ({ minWidth }: DropdownGenderProfileFormProps) => {
  return (
    <Field
      name="gender"
      component={reduxFormProps => {
        return DropdownGenderProfileFormComponent(reduxFormProps, minWidth);
      }}
    />
  );
};

type DropdownYearsProfileFormProps = {
  yearsOptions: JSX.Element[];
  minWidth: number | null;
  maxWidth: number | null;
};

const DropdownYearsProfileFormComponent = (
  { input }: ReduxFormComponentProps,
  yearsOptions,
  minWidth,
  maxWidth
) => {
  return (
    <Dropdown
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={200}
      actionElement={
        <ActionInput
          {...input}
          block
          text={toUIYear(input.value)}
          iconPosition={ActionInputIconPosition.right}
          icon={<Icon type={IconType.angleDown} size={IconSize.x2} />}
          onKeyDown={(ev: React.SyntheticEvent) => {
            ev.preventDefault();
          }}
        />
      }
      testId="birthDate"
      onChange={input.onChange}
      fixedMenuPlacement
      menuPlacement={DropdownMenuPlacement.top}
    >
      {yearsOptions}
    </Dropdown>
  );
};
export const DropdownYearsProfileForm: React.FunctionComponent<
  DropdownYearsProfileFormProps
> = ({ yearsOptions, minWidth, maxWidth }: DropdownYearsProfileFormProps) => {
  return (
    <Field
      name="birthDate"
      component={props => {
        return DropdownYearsProfileFormComponent(
          props,
          yearsOptions,
          minWidth,
          maxWidth
        );
      }}
    />
  );
};

const getGenderText = gender => {
  switch (gender) {
    case Gender.FEMALE:
      return t('Girl');
    case Gender.MALE:
      return t('Boy');
    case Gender.UNSPECIFIED:
    default:
      return t('Prefer not to say');
  }
};

const validate = values => {
  const errors: any = {};
  if (!values.name) {
    errors.name = t('Field required');
  }

  return errors;
};

type ProfileFormProps = {
  id: number;
  name: string;
  gender: Gender;
  birthDate: string;
  picture: string;
};
export type ProfileFormRecord = Record<ProfileFormProps>;
export const ProfileFormRecord = Record<ProfileFormProps, {}>({
  id: 0,
  name: '',
  gender: Gender.MALE,
  birthDate: AppDataArrangement.noBirthDayUI,
  picture: '',
});

export const formRecord = (
  values?: ProfileFormProps | ProfileFormRecord
): Partial<ProfileFormRecord> => {
  if (values === undefined) {
    return ProfileFormRecord();
  }

  return ProfileFormRecord(values);
};

const ProfileForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: formRecord(),
  validate: values => validate(values.toJS()),
  destroyOnUnmount: false,
})(ProfileFormComponent);

export default ProfileForm;
