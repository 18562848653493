window.cordova = window.cordova || {
  platformId: 'browser',
  mock: true,
};

// eslint-disable-next-line import/no-dynamic-require
module.exports = require(`./qinit.${
  window.cordova.platformId === 'test-platform'
    ? 'browser'
    : window.cordova.platformId
}`);
