import { createSelector } from 'reselect';
import * as Immutable from 'immutable';

import { MinutesPerHourActivityRecord } from '../records/activity/types/MinutesPerHourActivity.types';
import { HoursPerDayActivityRecord } from '../records/activity/types/HoursPerDayActivity.types';
import { formatDayToAPI } from '../helpers/dates';
import { SummaryDateRanges } from '../constants';
import State from '../store/state';
import { getAccountCurrentTime } from './account';
import * as moment from 'moment';
import { calculateTotalScreenTimeWithRoutines } from '../businessLogic/screenTime/total';
import { getUnfilteredRoutinesList } from '../ducks/routines/selectors/unfiltered';

export const getSummary = (state: State) => state.get('summary').get('item');

export const getSelectedDateScreenTime = (
  state: State
): Immutable.List<MinutesPerHourActivityRecord> =>
  state.get('summary').get('selectedDateScreenTime');

export const getTodayScreenTime = (
  state: State
): Immutable.List<MinutesPerHourActivityRecord> =>
  state.get('summary').get('todayScreenTime');

export const getRangeScreenTime = (
  state: State
): Immutable.List<HoursPerDayActivityRecord> =>
  state.get('summary').get('rangeScreenTime');

export const getActiveDateRange = (state: State): SummaryDateRanges =>
  state.get('summary').get('activeDateRange');

export const getSummaryPercentageActivity = (state: State) =>
  state.get('summary').get('percentageActivity');

export const getSummaryAppsAndWebActivity = (state: State) =>
  state.get('summary').get('appsAndWebActivity');

export const getSummaryBlockedWebActivity = (state: State) =>
  state.get('summary').get('blockedWebActivity');

export const getYoutubeSummary = (state: State) =>
  state.get('youtubeSummary').get('summary');

export const getIsYoutubeSummaryLoading = (state: State) =>
  state.get('youtubeSummary').get('isLoadingSummary');

export const getYoutubeSummaryDetailsLastVideos = (state: State) =>
  state.get('youtubeSummary').get('lastVideos');

export const isLoadingLastVideos = (state: State) =>
  state.get('youtubeSummary').get('isLoadingLastVideos');

export const hasMoreLastVideosToLoad = (state: State) =>
  state.get('youtubeSummary').get('hasMoreLastVideosToLoad');

export const getYoutubeSummarySelectedLastVideo = (state: State) =>
  state.get('youtubeSummary').get('selectedLastVideo');

export const hasYoutubeSummaryData = createSelector(
  getYoutubeSummary,
  summary =>
    summary.get('totalSearches') !== 0 || summary.get('totalVideos') !== 0
);

export const hasYoutubeSummaryError = (state: State) =>
  state.get('youtubeSummary').get('youtubeSummaryError');

export const getSelectedPastDate = createSelector(
  getActiveDateRange,
  state => state.get('summary').get('selectedPastDate'),
  (activeDateRange, selectedPastDate) => {
    const isCalendarPickerSelected =
      activeDateRange === SummaryDateRanges.CustomPastDay;
    return isCalendarPickerSelected ? selectedPastDate : undefined;
  }
);

export const getActiveDateRangeNormalized = createSelector(
  getActiveDateRange,
  getSelectedPastDate,
  (activeDateRange, selectedDateRange) => {
    if (activeDateRange !== SummaryDateRanges.CustomPastDay) {
      return activeDateRange;
    }
    return moment(selectedDateRange).isBefore(moment(), 'day')
      ? activeDateRange
      : SummaryDateRanges.Today;
  }
);

export const getSelectedDateRange = createSelector(
  getAccountCurrentTime,
  getActiveDateRange,
  getSelectedPastDate,
  (currentTime, numberOfDays, selectedPastDate) => {
    if (numberOfDays === SummaryDateRanges.CustomPastDay) {
      return {
        minDate: selectedPastDate,
        maxDate: selectedPastDate,
      };
    }

    return {
      minDate: formatDayToAPI(
        currentTime.clone().subtract(numberOfDays - 1, 'd')
      ),
      maxDate: formatDayToAPI(currentTime),
    };
  }
);

export const getScreenTimeMinutes = (state: State) => {
  const summary = getSelectedDateScreenTime(state);
  const routines = getUnfilteredRoutinesList(state);
  return calculateTotalScreenTimeWithRoutines(summary, routines);
};

export const getTodayScreenTimeMinutes = (state: State) => {
  const summary = getTodayScreenTime(state);
  const routines = getUnfilteredRoutinesList(state);
  return calculateTotalScreenTimeWithRoutines(summary, routines);
};

export const getAverageScreenTime = (state: State, numberOfDays: number) => {
  const totalScreenTime = getRangeScreenTime(state)
    .takeLast(numberOfDays)
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.hours,
      0
    );
  return totalScreenTime / numberOfDays;
};

export const getMultiDeviceTimeRestrictions = (state: State, deviceId) =>
  state.get('records').get('timeRestrictions').get(deviceId.toString());

export const getTotalDefaultRulesScreenTimeForDateRange = createSelector(
  getActiveDateRange,
  getRangeScreenTime,
  (
    activeDateRange: SummaryDateRanges,
    rangeScreenTime: Immutable.List<HoursPerDayActivityRecord>
  ) =>
    activeDateRange !== SummaryDateRanges.CustomPastDay &&
    activeDateRange !== SummaryDateRanges.Today
      ? rangeScreenTime
          .slice(-1 * activeDateRange)
          .reduce(
            (total, screenTime) =>
              (screenTime?.defaultScreenTime || 0) * 60 + total,
            0
          )
      : null
);

export const getSummaryAppAndWebIsLoading = (state: State) =>
  state.get('summary').get('isFetchingAppsAndWebActivity');

export const getSummaryAppAndWebError = (state: State) =>
  state.get('summary').get('summaryAppAndWebError');

export const getSummaryBlockedWebIsLoading = (state: State) =>
  state.get('summary').get('isFetchingBlockedWebActivity');

export const getSummaryBlockedWebError = (state: State) =>
  state.get('summary').get('summaryBlockedWebError');

export const getSummaryScreenTimeActivityError = (state: State) =>
  state.get('summary').get('summaryScreenTimeActivityError');

export const getSelectedDateDefaultRulesScreenTimeMinutes = (state: State) =>
  getSelectedDateScreenTime(state).reduce(
    (result, screenTime) => result + (screenTime?.defaultScreenTime || 0),
    0
  );

export const getSelectedDateRoutinesScreenTimeMinutes = (state: State) =>
  getSelectedDateScreenTime(state).reduce((acc, { routineScreenTime }) => {
    (routineScreenTime || []).forEach(({ uid, minutes }) => {
      if (acc[uid]) acc[uid] += minutes;
      else acc[uid] = minutes;
    });

    return acc;
  }, {} as Record<string, number>);

export const getTotalRoutinesScreenTimeForDateRange = createSelector(
  getActiveDateRange,
  getRangeScreenTime,
  (
    activeDateRange: SummaryDateRanges,
    rangeScreenTime: Immutable.List<HoursPerDayActivityRecord>
  ) =>
    activeDateRange !== SummaryDateRanges.CustomPastDay &&
    activeDateRange !== SummaryDateRanges.Today
      ? rangeScreenTime
          .slice(-1 * activeDateRange)
          .map(item => item.routineScreenTime)
          .reduce((map, routineScreenTime) => {
            (routineScreenTime || []).forEach(({ uid, hours }) => {
              if (map[uid]) map[uid] += hours * 60;
              else map[uid] = hours * 60;
            });
            return map;
          }, {} as Record<string, number>)
      : null
);

export const hasSeparatedScreenTimeForSelectedDate = createSelector(
  getSelectedDateScreenTime,
  (selectedDateScreenTime: Immutable.List<MinutesPerHourActivityRecord>) =>
    selectedDateScreenTime.some(
      item => item?.defaultScreenTime != null || item?.routineScreenTime != null
    )
);

export const hasSeparatedScreenTimeForDateRange = createSelector(
  getRangeScreenTime,
  (rangeScreenTime: Immutable.List<HoursPerDayActivityRecord>) =>
    rangeScreenTime.some(
      item => item?.defaultScreenTime != null || item?.routineScreenTime != null
    )
);
