import {
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_GAMES_AND_APPS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
} from '../../../../constants';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

// set of rules that only apply to Protect a device
const protectADeviceRules = [
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_GAMES_AND_APPS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
];

export const isVisibleProtectADeviceLabel = ({
  profileRule,
  isDelegatedToThisAccount,
  totalDevicesCount,
}: ProfileRulesLabelPredicatesProps) => {
  // this should only be shown to the user if it's delegated to this account and has no device
  if (isDelegatedToThisAccount && !totalDevicesCount) {
    return protectADeviceRules.includes(profileRule);
  }

  return false;
};
