import * as React from 'react';
import { Quote } from 'styleguide-react';
import { EventRecord } from '../../records';
import { LicenseRecord, isFree } from '../../records/license';
import { safeParseJSON, isNonEmptyString } from '../../helpers/string';

import { EventType } from '../../constants';
import { captureException } from '../../helpers/sentry';
import { EventCategoryType, isTypeAlertHighlighted } from './types';
import { classNames } from '../../helpers';
import { isEventPremiumYoutube } from './activityTimelineHelpers';

const eventQuote = (
  event: EventRecord,
  license: LicenseRecord,
  isDelegatedWithPCDevices: boolean
) => {
  switch (event.type) {
    case EventType.FacebookSocialActivityContactPost:
    case EventType.FacebookSocialActivityUserComment:
    case EventType.FacebookSocialActivityUserLink:
    case EventType.FacebookSocialActivityUserPost:
      return isFree(license.type) ? undefined : event.text;
    case EventType.Search: {
      if (isEventPremiumYoutube(license, event, isDelegatedWithPCDevices))
        return undefined;
      return event.text;
    }

    case EventType.SmsIncoming:
    case EventType.SmsBlockedIncoming:
    case EventType.SmsOutgoing:
    case EventType.SmsBlockedOutgoing: {
      if (!isFree(license.type) && isNonEmptyString(event.text)) {
        const json = safeParseJSON(e =>
          captureException(e, {
            extra: {
              event,
            },
          })
        )(event.text);
        if (json && json.message) {
          return json.message;
        }
      }
      return undefined;
    }
    default:
      return undefined;
  }
};

const ActivityTimelineItemQuote: React.FunctionComponent<{
  event: EventRecord;
  license: LicenseRecord;
  type: EventCategoryType;
  isDelegatedWithPCDevices: boolean;
}> = ({ event, license, type, isDelegatedWithPCDevices }) => {
  const quote = eventQuote(event, license, isDelegatedWithPCDevices);
  return quote ? (
    <Quote
      className={classNames(
        'ActivityTimelineItemQuote',
        isTypeAlertHighlighted(type) ? 'ActivityTimelineItemQuote--alert' : ''
      )}
      maxLines="none"
    >
      {quote}
    </Quote>
  ) : null;
};

export default ActivityTimelineItemQuote;
