export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export interface GenderMap {
  MALE: Gender.MALE;
  FEMALE: Gender.FEMALE;
  UNSPECIFIED: Gender.UNSPECIFIED;
}
