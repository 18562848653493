import { connect } from 'react-redux';
import UndoRoutineSwitchModal from '../../components/Modal/UndoRoutineSwitchModal';
import State, { Dispatch, RouterParamsProps } from '../../store/state';
import { goBackIfHistory } from '../../ducks/routing';
import { deleteRoutineSchedule } from '../../ducks/routines';
import { ButtonNames } from '../../helpers/analytics';
import { trackQuickActionClick } from '../../ducks/unifiedNowCard/thunks';
import { UnifiedNowCardState } from '../../businessLogic/unifiedNowCard/types';
import { getActiveProfileUid, getQueryParam } from '../../selectors';
import { TOAST_ICON_TICK, showToast } from '../../ducks/toast';
import { t } from '../../lib/i18n';
import { loadNowCardAction } from '../../ducks/unifiedNowCard';
import { Action } from 'redux';

export const getRoutineUidQueryParam = (state: State) =>
  getQueryParam(state, 'routineUid');

export const getScheduleUidQueryParam = (state: State) =>
  getQueryParam(state, 'scheduleUid');

export const getNowCardStateQueryParam = (state: State) =>
  getQueryParam(state, 'nowCardState') as UnifiedNowCardState;

const mapStateToProps = (state: State) => ({
  profileUid: getActiveProfileUid(state),
  routineUid: getRoutineUidQueryParam(state),
  scheduleUid: getScheduleUidQueryParam(state),
  nowCardState: getNowCardStateQueryParam(state),
});
export const onUndoSwitchRoutineSuccessActions =
  (profileId: string) => (): Action[] =>
    [
      loadNowCardAction(Number(profileId)),
      showToast(t('Normal schedule resumed'), TOAST_ICON_TICK),
    ];

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  onClickClose: () => dispatch(goBackIfHistory()),
  onClickConfirm: (
    profileUid: string,
    routineUid: string,
    scheduleUid: string,
    nowCardState: UnifiedNowCardState
  ) => {
    dispatch(
      trackQuickActionClick(ButtonNames.UndoSwitchRoutine, {
        state: nowCardState,
      })
    );
    dispatch(
      deleteRoutineSchedule({
        profileUid,
        routineUid,
        scheduleUid,
        onSuccess: onUndoSwitchRoutineSuccessActions(profileId),
      })
    );
    dispatch(goBackIfHistory());
  },
});

const UndoRoutineSwitchModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UndoRoutineSwitchModal);

export type UndoRoutineSwitchModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default UndoRoutineSwitchModalContainer;
