import { connect } from 'react-redux';
import { selectedNewDateRangeForProfile } from '../../actions/ProfileActions';
import ProfileDashboard from '../../components/Dashboard/ProfileDashboard';
import { SummaryDateRanges } from '../../constants';
import { toggleFloatingActionMenu } from '../../ducks/app';
import { goBackIfHistory } from '../../ducks/routing';
import { activitySwiperIndexChanged } from '../../ducks/ui';
import { gaEvent } from '../../helpers';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';
import {
  getProfileDevicesSortedByName,
  isProfileLinkedWithLinewizeStudent,
  hasDeviceAssigned,
  isFloatingActionMenuOpen,
  getActiveDateRange,
} from '../../selectors';
import State, {
  Dispatch,
  RouterParams,
  RouterParamsProps,
} from '../../store/state';
import { getIsSchoolOnlyProfile } from './helpers/profileDashboardHelpers';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const doNothing = () => {};

const mapStateToProps = (state: State, { params }: RouterParamsProps) => {
  const profileId = parseInt(params.profileId, 10);

  const profileDeviceByName = getProfileDevicesSortedByName(state, profileId);
  const totalDevices = profileDeviceByName.size;

  const isSchoolOnlyProfile = getIsSchoolOnlyProfile(state, profileId);

  return {
    profileId,
    showProfileSummarySelector: false,
    isFetchingNewEvents: false,
    isFetching: false,
    totalDevices,
    profileDeviceByName,
    isSchoolOnlyProfile,
    isMenuOpen: isFloatingActionMenuOpen(state),
    activeDateRange: getActiveDateRange(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
) => ({
  handleRefresh: doNothing,
  onScroll: doNothing,
  onClickEdit: doNothing,
  onClickGoBack: () => {
    dispatch(activitySwiperIndexChanged(0));
    dispatch(goBackIfHistory());
  },
  onChangeDateRange: (range: SummaryDateRanges) =>
    dispatch(selectedNewDateRangeForProfile(range, profileId)),
  // Floating button + menu
  onToggleMenu: () => {
    gaEvent('kid-profile-time-floating-button', 'click', 'open-close');
    return dispatch(toggleFloatingActionMenu());
  },
});

const ProfileDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDashboard);

export type DashboardContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

ProfileDashboardContainer.redirect =
  (params: RouterParams) => (dispatch: Dispatch, getState: () => State) => {
    const profileId = parseInt(params.profileId, 10);
    const state = getState();

    const navigate = getMultiPlatformNavigation();

    const isProfileLinkedToSchool = isProfileLinkedWithLinewizeStudent(
      state,
      profileId
    );
    const profileHasDevices = hasDeviceAssigned(state, profileId);

    if (!isProfileLinkedToSchool && !profileHasDevices) {
      dispatch(
        navigate({
          type: 'inner:replace',
          src: `/profiles/${profileId}/add-device`,
        })
      );
    }
  };

export default ProfileDashboardContainer;
