import { goBackIfHistory } from '../ducks/routing';
import { setItem } from '../ducks/persistentStorage';
import { track, Events } from '../helpers/analytics';
import { isConfirmedStandaloneApp } from '../selectors/app';
import { logout } from '../sideEffects/logout';
import { isUserInConfirmationFlowScreen } from '../selectors/routing';

export const trackWrongAppEventAndGoBack = () => dispatch => {
  track(Events.ClickedWrongAppButton, { button: 'back' });
  dispatch(goBackIfHistory());
};

export const markParentAppStandaloneAsConfirmed = () => dispatch =>
  dispatch(setItem('parentAppStandaloneConfirmed', 'true'));

// eslint-disable-next-line consistent-return
export const logoutIfParentAppNotConfirmed = () => (dispatch, getState) => {
  if (
    isUserInConfirmationFlowScreen(getState()) &&
    !isConfirmedStandaloneApp(getState())
  ) {
    return dispatch(logout());
  }
};
