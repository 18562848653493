import { getFormattedAddress } from './location';
import { ProfileRecord } from '.';
import PlaceRecord from './place';

export enum MarkerAvatarSafetyStatus {
  MARKER_AVATAR_SAFETY_STATUS_NEUTRAL = 'neutral',
  MARKER_AVATAR_SAFETY_STATUS_WARNING = 'warning',
  MARKER_AVATAR_SAFETY_STATUS_TRACKING_DISABLED = 'tracking-disabled',
}

export type Marker = {
  latitude: number;
  longitude: number;
  accuracy: number;
  label: string;
  id: number | string;
  title: string;
  snippet: string;
  timestamp: string;
};

export type MarkerPin = Marker;
export type MarkerAvatar = Marker & {
  safetyStatus: MarkerAvatarSafetyStatus;
  icon: string;
};
export const isMarkerAvatar = (
  item: Marker | MarkerAvatar
): item is MarkerAvatar =>
  typeof (item as MarkerAvatar).safetyStatus !== 'undefined';

export const fromProfile = (profile: ProfileRecord) => ({
  latitude: profile.location.latitude,
  longitude: profile.location.longitude,
  accuracy: profile.location.accuracy,
  title: profile.name,
  snippet: getFormattedAddress(profile.location),
  label: profile.name[0],
  icon: profile.picture,
  id: profile.id,
  time: profile.location.time,
  safetyStatus: MarkerAvatarSafetyStatus.MARKER_AVATAR_SAFETY_STATUS_NEUTRAL,
});

export const fromPlace = (place: PlaceRecord) => ({
  latitude: place.latitude,
  longitude: place.longitude,
  accuracy: place.radius,
  title: place.name,
  snippet: place.name,
  label: place.name,
  icon: '',
  id: place.uid,
  time: '',
});

export const getSafetyStatus = (
  profileIrregular: boolean,
  hasTrackingDisabled: boolean
) => {
  if (hasTrackingDisabled) {
    return MarkerAvatarSafetyStatus.MARKER_AVATAR_SAFETY_STATUS_TRACKING_DISABLED;
  }

  if (profileIrregular) {
    return MarkerAvatarSafetyStatus.MARKER_AVATAR_SAFETY_STATUS_WARNING;
  }

  return MarkerAvatarSafetyStatus.MARKER_AVATAR_SAFETY_STATUS_NEUTRAL;
};
