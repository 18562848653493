import * as React from 'react';
import {
  List as RegularStyleList,
  RegularStyleListItem,
  Switch,
  FlexLayout,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { SettingsToggleNames } from '../../constants';

export interface WebFilterSettingsProps {
  isAllowUnknownSites: boolean;
  isSafeSearch: boolean;
  isBlockedUnsupportedBrowsers: boolean;
  isEnabledBlockUnsupportedBrowsers: boolean;
  onToggleWebFilterSetting: (
    target: SettingsToggleNames,
    checked: boolean,
    needLicenseUpgrade?: boolean
  ) => void;
}

const WebFiltersSettings: React.FunctionComponent<WebFilterSettingsProps> = ({
  isAllowUnknownSites,
  isSafeSearch,
  isBlockedUnsupportedBrowsers,
  isEnabledBlockUnsupportedBrowsers,
  onToggleWebFilterSetting,
}) => (
  <FlexLayout mainaxis="column" marginTop="16" marginBottom="24">
    <RegularStyleList
      listItemMarginBottom="8"
      listItemPaddingTop="8"
      listItemPaddingBottom="8"
    >
      {isEnabledBlockUnsupportedBrowsers ? (
        <RegularStyleListItem
          title={t('Block unsupported browsers')}
          upperSubtitle={t(
            'When enabled, all browsers that are not supported by {{shortName}} will be blocked.'
          )}
          key={SettingsToggleNames.BlockedUnsupportedBrowsers}
          actionElement={
            <Switch
              checked={isBlockedUnsupportedBrowsers}
              onClick={ev => {
                onToggleWebFilterSetting(
                  SettingsToggleNames.BlockedUnsupportedBrowsers,
                  (ev.target as HTMLInputElement).checked
                );
              }}
            />
          }
        />
      ) : null}

      <RegularStyleListItem
        title={t('Block unknown websites')}
        upperSubtitle={t(
          'When enabled, {{shortName}} will block access to all websites that cannot be categorized.'
        )}
        checked={!isAllowUnknownSites}
        actionElement={
          <Switch
            checked={!isAllowUnknownSites}
            onClick={ev => {
              onToggleWebFilterSetting(
                SettingsToggleNames.UnknownSites,
                !(ev.target as HTMLInputElement).checked
              );
            }}
          />
        }
      />

      <RegularStyleListItem
        title={t('Enforce Safe Search')}
        upperSubtitle={t(
          'When enabled, {{shortName}} will limit search results to eliminate potentially harmful content.'
        )}
        actionElement={
          <Switch
            checked={isSafeSearch}
            onClick={ev => {
              onToggleWebFilterSetting(
                SettingsToggleNames.SafeSearch,
                (ev.target as HTMLInputElement).checked
              );
            }}
          />
        }
      />
    </RegularStyleList>
  </FlexLayout>
);

export default WebFiltersSettings;
