import * as React from 'react';
import * as moment from 'moment';
import { t } from '../../lib/i18n';
import IconArrowUpward from '../Icons/IconArrowUpward';
import {
  getNotificationDateFormatted,
  minutesToHMinFormat,
} from '../../helpers/dates';
import { classNames, isiOSPlatform } from '../../helpers';

const UsageIncreasedNotification: React.FunctionComponent<{
  appIconPath: string;
  title: string;
  date: moment.Moment;
  increment: number;
  locale: string;
}> = ({ appIconPath, title, date, increment, locale }) => (
  <div className="Notification-UsageIncreased">
    <div
      className={classNames(
        'Notification-UsageIncreased___icon',
        isiOSPlatform() ? 'Notification-UsageIncreased___icon--iosApp' : ''
      )}
    >
      <img role="presentation" alt="app-icon" src={appIconPath} />
    </div>
    <div className="Notification-UsageIncreased__content">
      <div className="Notification-UsageIncreased__content--top">
        <strong className="Notification-UsageIncreased__content__title">
          {title}
        </strong>
        <span className="Notification-UsageIncreased__content__date">
          {getNotificationDateFormatted(date, locale)}
        </span>
      </div>
      <div className="Notification-UsageIncreased__content--bottom">
        <strong className="Notification-UsageIncreased__content__inc">
          {`${t('up')} ${minutesToHMinFormat(increment)}`}
        </strong>
        <span className="Notification-UsageIncreased__content__iconUp">
          <IconArrowUpward />
        </span>
      </div>
    </div>
  </div>
);

export default UsageIncreasedNotification;
