import { isProfileSubPage, ofLocationChangeActionWhen } from '../router';
import { AppAction, AppEpicWithoutStateEffect } from '../types';
import { not } from '../../helpers/predicates';
import { EMPTY, catchError, from, mergeMap, of } from 'rxjs';
import { clearStudentPoliciesRecords } from '../../ducks/studentPolicies';

const ensureOfProfilePage = ofLocationChangeActionWhen({
  to: not(isProfileSubPage),
  from: isProfileSubPage,
});

export const unloadProfileDataEpic: AppEpicWithoutStateEffect<
  AppAction
> = $actions => {
  return $actions.pipe(
    ensureOfProfilePage,
    mergeMap(() => from([clearStudentPoliciesRecords()])),
    catchError(() => {
      return of(EMPTY);
    })
  );
};
