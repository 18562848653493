import * as qinit from '../qinit';
import { BuildPlatform } from '../constants/index';
import { getBuildPlatform } from './globals';

export const getDownloadLinkByBuildPlatform = (): string => {
  const link = {
    [BuildPlatform.android]:
      qinit.tenant.common.downloads.adjust_track?.parent_app_redirect_to_kid ||
      qinit.tenant.common.downloads.android,
    [BuildPlatform.ios]:
      qinit.tenant.common.downloads.adjust_track
        ?.ios_parent_app_redirect_to_kid || qinit.tenant.common.downloads.ios,
  }[getBuildPlatform()];

  if (!link) {
    throw new Error(
      `There is no download link for this platform: ${getBuildPlatform()}`
    );
  }

  return link;
};
