import { map } from 'ramda';
import { DetailsItemRecord } from '../records/urlDetails';
import State from '../store/state';
import { createSelector } from './registry/core';

const urlDetailsSelectors = {
  isUrlDetailsFetching: (state: State) =>
    state.get('urlDetails').get('isFetching'),

  getUrlDetailsByEventKey: (state: State, key: string) =>
    state.get('records').get('urlDetails').get(key.toString()),

  getShowDetailsAction: (state: State): DetailsItemRecord | undefined =>
    state.get('urlDetails').get('showDetailsAction'),
};

export type UrlDetailsSelectors = typeof urlDetailsSelectors;

export default map(createSelector, urlDetailsSelectors) as UrlDetailsSelectors;

export const {
  isUrlDetailsFetching,
  getUrlDetailsByEventKey,
  getShowDetailsAction,
} = urlDetailsSelectors;
