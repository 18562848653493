import * as decamelizeKeysDeep from 'decamelize-keys-deep';
import {
  finishValidation,
  startValidation,
  timeoutValidation,
} from '../../../ducks/products';
import { currentTime } from '../../../ducks/times';
import { APIError } from '../../../lib/errors';
import { getLicense, getPaymentProvider } from '../../../selectors';
import { BaseThunk, Dispatch } from '../../../store/state';
import { captureException } from '../../sentry';
import {
  getNativeTransaction,
  getTransactionFromPlatform,
  logPurchase,
} from './helpers';
import { PurchaseError } from '../../../lib/errors/purchaseError';
import { timeoutPromise } from '../..';
import api from '../../../api';
import { PAYMENT_PROVIDER_APPLE } from '../../../constants';
import { ValidateTransactionResponse } from './types/validateTransactionResponse.type';
import { isPremium } from '../../../records/license';

export const validator =
  (
    receipt: CdvPurchase.Validator.Request.Body,
    callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>
  ): BaseThunk<Promise<void>> =>
  (dispatch, getState) => {
    logPurchase('[validator] receipt', receipt);
    const license = getLicense(getState());
    if (isPremium(license.type)) {
      callback({
        ok: false,
        code: CdvPurchase.ErrorCode.FINISH,
      });
      return Promise.resolve();
    }
    return (
      dispatch(validateReceipt(receipt))
        .catch((error: APIError) => {
          if (error.status !== 409) {
            throw error;
          }
        })
        .then(() => {
          const transaction = getTransactionFromPlatform(receipt.transaction);
          if (transaction.id === undefined) {
            throw new Error('[validator] transaction.id is undefined');
          }
          return dispatch(
            pollValidation(transaction.id, currentTimestamp(dispatch))
          );
        })
        .then(() => {
          if (receipt.id === undefined) {
            throw new Error('[validator] receipt.id is undefined');
          }
          /**
           * receipt.transaction can't be undefined here, otherwise
           * an exception would have been thrown.
           */
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const nativeTransaction = getNativeTransaction(receipt.transaction!);
          callback({
            ok: true,
            data: {
              id: receipt.id,
              latest_receipt: true,
              transaction: nativeTransaction,
            },
          });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch(error => {
          captureException(error);
          callback({
            ok: false,
            code: CdvPurchase.ErrorCode.VERIFICATION_FAILED,
          });
        })
    );
  };

const currentTimestamp = (dispatch: Dispatch): number =>
  dispatch(currentTime()).valueOf();

const validateReceipt =
  (receipt: CdvPurchase.Validator.Request.Body): BaseThunk<Promise<void>> =>
  (dispatch, getState) => {
    dispatch(startValidation());
    const paymentProvider = getPaymentProvider(getState());

    if (!receipt.transaction) {
      return Promise.reject(
        new Error('[validateReceipt] transaction is undefined')
      );
    }

    const data =
      paymentProvider === PAYMENT_PROVIDER_APPLE
        ? {
            transactionId: (
              receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple
            ).id,
            receipt: (
              receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionApple
            ).appStoreReceipt,
          }
        : {
            productId: receipt.id,
            productType: receipt.type,
            receipt: (
              receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle
            ).receipt,
            signature: (
              receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle
            ).signature,
            transactionId: (
              receipt.transaction as CdvPurchase.Validator.Request.ApiValidatorBodyTransactionGoogle
            ).id,
            type: receipt.transaction.type,
          };

    return api.validateReceipt.post(decamelizeKeysDeep(data), null, {
      paymentProvider,
    });
  };

const pollValidation =
  (transactionId: string, startTs: number): BaseThunk<Promise<void>> =>
  (dispatch, getState) =>
    api.validateTransaction
      .get({
        transactionId,
        paymentProvider: getPaymentProvider(getState()),
      })
      .catch(apiError => {
        if (apiError.status === 404) {
          return apiError.json();
        }
        throw apiError;
      })
      .then((response): Promise<void> | void => {
        const validateResponse = response as ValidateTransactionResponse;
        if (validateResponse.id && validateResponse.id !== transactionId) {
          throw new PurchaseError(
            "[pollValidation] transaction.id to poll doesn't exist",
            transactionId,
            response
          );
        } else if (validateResponse.status === 'PROCESSED') {
          dispatch(finishValidation());
          return;
        } else if (currentTimestamp(dispatch) - startTs > 30000) {
          dispatch(timeoutValidation());
          throw new PurchaseError(
            '[pollValidation] Purchase validation timed out',
            transactionId,
            response
          );
        }
        return timeoutPromise(1000, () =>
          dispatch(pollValidation(transactionId, startTs))
        );
      });
