import * as React from 'react';
import { EmptyPlaceholder } from 'styleguide-react';
import Loader from '../../base/Loader';

interface LoadingContentProps {
  text: string;
}

const LoadingContent: React.FC<LoadingContentProps> = ({
  text,
}: LoadingContentProps) => {
  return (
    <EmptyPlaceholder
      icon={<Loader size="small" color="secondary" />}
      fillAvailableSpace
      centered
      smallText
      text={text}
    />
  );
};

export default LoadingContent;
