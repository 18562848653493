import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  RegularStyleListItem,
  List,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';

export interface DisconnectTheInternetModalProps {
  onClickClose: () => void;
}

const DisconnectTheInternetModal = ({
  onClickClose,
}: DisconnectTheInternetModalProps) => {
  const MODAL_TEST_ID = 'disconnect-the-internet-modal';

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-disconnect-the-internet-modal"
      header={{
        icon: (
          <Icon
            type={IconType.wifiSlash}
            size={IconSize.xs}
            family={IconFamily.regular}
            color={IconColor.secondary}
          />
        ),
      }}
      title={t('Disconnect the internet')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-go-it`}
          key="button1"
          onClick={onClickClose}
          disabled={false}
          size={ButtonSize.medium}
          block
        >
          {t('Got it')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t('Disconnect the internet has the following effects:')}
        </ModalStyledText>

        <List listItemMarginBottom="16">
          <RegularStyleListItem
            title={t('Windows and Mac computers')}
            upperSubtitle={t('Access to web browsers is blocked.')}
            iconVerticalAlign="top"
            icon={<Icon type={IconType.laptop} color={IconColor.black} />}
          />
          <RegularStyleListItem
            title={t('Android devices')}
            upperSubtitle={t(
              'All apps are blocked apart from basic apps like calendar, calculator, and recent calls.'
            )}
            iconVerticalAlign="top"
            icon={
              <Icon
                type={IconType.android}
                color={IconColor.black}
                family={IconFamily.brands}
              />
            }
          />
          <RegularStyleListItem
            title={t('iPhones and iPads')}
            upperSubtitle={t(
              'The internet connection is blocked. This means that online apps can’t be accessed, but any that are offline can.'
            )}
            iconVerticalAlign="top"
            icon={
              <Icon
                type={IconType.apple}
                color={IconColor.black}
                family={IconFamily.brands}
                size={IconSize.lg}
              />
            }
          />
        </List>
      </FlexLayout>
    </Modal>
  );
};
export default DisconnectTheInternetModal;
