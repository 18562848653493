import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import CallsSMSRulesPremiumFlyover from '../../../components/CallsSMSRules/CallsSMSRulesPremiumFlyover';
import { canPurchase, getAccountLocale } from '../../../selectors';
import State, { Dispatch } from '../../../store/state';
import {
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
  trackUpgradeBlockedFeature,
} from '../../../helpers/analytics';
import { navigateToUpgrade } from '../../../actions/Navigation';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
  canPurchase: canPurchase(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickUpgrade: () => {
    trackUpgradeBlockedFeature(
      GenericPageNames.CallsAndMessagesRules,
      FeatureBlockingContexts.FullBlockedFeature,
      ProfileRuleNames.CallsAndMessages
    );
    dispatch(navigateToUpgrade());
  },
});

export type CallsSMSRulesPremiumFlyoverProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const CallsSMSRulesPremiumFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsSMSRulesPremiumFlyover);

export default CallsSMSRulesPremiumFlyoverContainer;
