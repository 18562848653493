import * as React from 'react';
import { debounce } from '../../helpers';
import { getDocument } from '../../sideEffects/browser';
import Loader from '../base/Loader';
import { Card, GlobalType } from 'styleguide-react';
import {
  CardHeaderProps,
  CardFooterProps,
  // eslint-disable-next-line import/no-unresolved
} from 'styleguide-react/build/components/Card/Card';

class SummaryCard extends React.Component<
  {
    icon?: JSX.Element;
    width?: string;
    height?: string;
    body: JSX.Element;
    header?: CardHeaderProps;
    footer?: CardFooterProps;
    isEmpty: boolean;
    displayAll?: boolean;
    needsPagination?: boolean;
    isFetchingMore?: boolean;
    itemsLength?: number;
    showAllAvailableActivity?: boolean;
    activeDateRange?: number;
    needsMargin?: boolean;
    type?: GlobalType | undefined;
    onExpand?: () => {};
    handleMore?: (limit: string) => {};
  },
  {
    scrollToTopVisible: boolean;
    limit: number;
    hasMoreItems: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      scrollToTopVisible: false,
      limit: 20,
      hasMoreItems: true,
    };
  }

  componentDidMount() {
    const { needsPagination } = this.props;
    if (needsPagination) {
      this.registerScrollHandlers();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { itemsLength, activeDateRange } = this.props;
    const { limit } = this.state;
    if (
      nextProps.itemsLength !== 0 &&
      itemsLength === nextProps.itemsLength &&
      nextProps.itemsLength < limit &&
      !nextProps.isFetchingMore
    ) {
      this.setState({ hasMoreItems: false });
    }
    if (nextProps.activeDateRange !== activeDateRange) {
      this.setState({ hasMoreItems: true, limit: 20 });
    }
  }

  componentWillUnmount() {
    const { needsPagination } = this.props;
    if (needsPagination) {
      this.unregisterScrollHandlers();
    }
  }

  registerScrollHandlers = () => {
    getDocument().addEventListener('scroll', this.onScroll, true);
  };

  unregisterScrollHandlers = () => {
    getDocument().removeEventListener('scroll', this.onScroll, true);
  };

  onScroll = debounce(({ target }) => {
    const { handleMore } = this.props;
    const targetContainer = this.getTarget(target);
    if (
      targetContainer.scrollTop + targetContainer.clientHeight >=
      targetContainer.scrollHeight - 100
    ) {
      if (this.shouldHandleMore() && handleMore) {
        const { limit } = this.state;
        this.setState({ limit: limit + 20 });
        // eslint-disable-next-line react/destructuring-assignment
        handleMore(this.state.limit.toString());
      }
    }

    const isOverTheTop = targetContainer.scrollTop > window.innerHeight;
    // eslint-disable-next-line react/destructuring-assignment
    if (isOverTheTop !== this.state.scrollToTopVisible) {
      this.setState({ scrollToTopVisible: isOverTheTop });
    }
  }, 100);

  render() {
    const {
      width,
      height,
      displayAll,
      body,
      header,
      footer,
      isFetchingMore,
      type,
    } = this.props;
    const { hasMoreItems } = this.state;
    return (
      <React.Fragment>
        <Card
          height={height}
          width={width}
          header={header}
          footer={footer}
          type={type}
          displayAll={displayAll}
        >
          {body}
        </Card>

        {isFetchingMore && hasMoreItems && (
          <div className="ActivityCard__content-loader">
            <Loader size="small" color="" />
          </div>
        )}
      </React.Fragment>
    );
  }

  getTarget = target => {
    return target === getDocument().scrollingElement || target === getDocument()
      ? getDocument().scrollingElement || getDocument().documentElement
      : target;
  };

  shouldHandleMore = () => {
    const { handleMore, isFetchingMore, showAllAvailableActivity } = this.props;
    const { hasMoreItems } = this.state;
    return (
      handleMore && hasMoreItems && !isFetchingMore && showAllAvailableActivity
    );
  };
}

export default SummaryCard;
