import { connect } from 'react-redux';
import SchoolTimeline, {
  SchoolTimelineProps,
} from '../../../components/Schools/SchoolTimeline/SchoolTimeline';
import State, { Dispatch } from '../../../store/state';
import {
  getLinewizeEventsFilter,
  getLinewizeEventsHasMore,
  getLinewizeEventsRecords,
  getLinewizeEventsStatus,
  getLinewizeEventsUuids,
} from '../../../ducks/linewizeEvent/selector';
import {
  getLinewizeEventsOrdered,
  groupLinewizeEventsByDateAndType,
} from '../../../businessLogic/LinewizeEvents/LinewizeEvent';
import {
  fetchLinewizeEvents,
  fetchLinewizeEventsPaginated,
} from '../../../ducks/linewizeEvent/thunk';
import { navigateToSchoolSignature } from '../../../actions/Navigation';
import { getMultiPlatformNavigation } from '../../../helpers/multiPlatformNavigation';
import { getTimezone, getProfile, getAccountLocale } from '../../../selectors';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../../helpers/analytics';
import { LinewizeEventTypes } from '../../../records/linewizeEvent/types/linewizeEvents';
import { camelCaseToSnakeCase } from '../../../api/mappings/case';
import { getIsSchoolAndFamilyProfile } from '../../Dashboard/helpers/profileDashboardHelpers';
import { isProfileDelegatedToThisAccount } from '../../../businessLogic/delegation/delegation';
import {
  isDelegationEnabled,
  isStudentOptInForDelegationForThisAccount,
} from '../../../selectors/studentPolicies';

const mapStateToProps = (
  state: State,
  { profileId }
): Partial<SchoolTimelineProps> => {
  const linewizeEvents = getLinewizeEventsRecords(state);
  const timezone = getTimezone(state);
  const eventKeysOrdered = getLinewizeEventsUuids(state);

  const linewizeEventsGroupedByDateAndState = groupLinewizeEventsByDateAndType(
    getLinewizeEventsOrdered(linewizeEvents, eventKeysOrdered.toJS()),
    timezone
  );
  const profileName = getProfile(state, profileId)?.name || '';
  const isCombined = getIsSchoolAndFamilyProfile(state, profileId);
  const isDelegated = isProfileDelegatedToThisAccount(
    isDelegationEnabled(state),
    isStudentOptInForDelegationForThisAccount(state),
    true
  );
  return {
    events: linewizeEventsGroupedByDateAndState,
    isFetching:
      getLinewizeEventsStatus(state) === 'LOADING' &&
      linewizeEventsGroupedByDateAndState.length > 0,
    hasMore: getLinewizeEventsHasMore(state),
    profileName,
    currentFilter: getLinewizeEventsFilter(state)?.first() || 'all',
    timeLineStatus: getLinewizeEventsStatus(state),
    accountLocale: getAccountLocale(state),
    timezone,
    isCombined,
    isDelegated,
  };
};

interface TrackEventsOptions {
  eventKey: string;
  eventTitle: string;
  eventType: LinewizeEventTypes;
}
const trackTimelineAction = (
  button: ButtonNames,
  options: TrackEventsOptions
) => {
  trackButtonClicked(GenericPageNames.ProfileSchoolTimeline, button, {
    options: JSON.stringify(camelCaseToSnakeCase(options)),
  });
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { profileId }
): Partial<SchoolTimelineProps> => ({
  onLoad: () => {
    dispatch((dispatch: Dispatch, getState: () => State) => {
      const state = getState();
      const filter = getLinewizeEventsFilter(state).first() || 'all';
      const events = getLinewizeEventsRecords(state);
      if (events.size === 0) {
        return dispatch(fetchLinewizeEvents(profileId, filter));
      }
    });
  },
  handleMore: () => {
    dispatch(fetchLinewizeEventsPaginated(profileId));
  },
  onActionClick: (event, action) => {
    const navigate = getMultiPlatformNavigation();
    const trackOptions: TrackEventsOptions = {
      eventKey: event.key,
      eventTitle: event.signatureName || '',
      eventType: event.type,
    };
    if (action.type === 'SIGNATURE') {
      trackTimelineAction(
        ButtonNames.StudentTimelineActionSignature,
        trackOptions
      );
      dispatch(navigateToSchoolSignature(profileId, event.signatureId!));
    }

    if (action.type === 'WATCH_VIDEO') {
      trackTimelineAction(ButtonNames.StudentTimelineActionWatchVideo, {
        ...trackOptions,
        eventTitle: event.title!,
      });
      dispatch(
        navigate({
          type: 'external',
          src: event.url!,
        })
      );
    }

    if (action.type === 'LINK') {
      trackTimelineAction(
        ButtonNames.StudentTimelineActionViewDetails,
        trackOptions
      );
      dispatch(
        navigate({
          type: 'external',
          src: event.urls[0],
        })
      );
    }
  },
});

const SchoolTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolTimeline);

export default SchoolTimelineContainer;
