import * as Rox from 'rox-browser';
import * as moment from 'moment';
import {
  getAccount,
  getPersistentStorageItem,
  getLicense,
} from '../../selectors';
import { BaseThunk } from '../../store/state';
import { getAppVersion, getBuildPlatform } from '../../helpers/globals';
import * as qinit from '../../qinit';
import { setFlags } from '../featureFlags';
import { ROLLOUT_USER_CONFIG_DONE } from '../actionNames/app';
import { isLoggedFromAdmin } from '../../sideEffects/globals/thirdPartyServices';

export const rolloutUserConfigSent = () => ({
  type: ROLLOUT_USER_CONFIG_DONE,
});

// eslint-disable-next-line no-underscore-dangle
export const configureRollout = (): BaseThunk<void> => (dispatch, getState) => {
  const state = getState();
  const account = getAccount(state);
  const license = getLicense(state);
  const track = getPersistentStorageItem(state, 'track');

  Rox.setCustomStringProperty('accountEmail', account.email);
  Rox.setCustomStringProperty('accountIdAsString', account.id.toString());
  Rox.setCustomStringProperty('accountUid', account.uid.toLowerCase());
  Rox.setCustomStringProperty('accountCountry', account.country);
  Rox.setCustomStringProperty('accountLocale', account.locale);
  Rox.setCustomNumberProperty(
    'accountCreationDateTimestamp',
    moment(account.created).toDate().getTime()
  );
  Rox.setCustomStringProperty('licenseName', license.name);
  Rox.setCustomNumberProperty(
    'licenseStartDateTimestamp',
    moment(license.startDate).toDate().getTime()
  );
  Rox.setCustomStringProperty('licenseSubtype', license.subtype);
  Rox.setCustomStringProperty('sourceCampaign', license.sourceCampaign);
  Rox.setCustomStringProperty('tenantName', qinit.tenant.common.tenant.name);
  Rox.setCustomStringProperty('version', getAppVersion());
  // eslint-disable-next-line no-undef
  Rox.setCustomStringProperty('variant', application_version.variant);
  Rox.setCustomStringProperty('platform', getBuildPlatform());
  Rox.setCustomStringProperty('track', track);
  Rox.setCustomBooleanProperty(
    'googlePlayServicesEnabled',
    !runtime_flags.skipGooglePlayServices
  );
  Rox.setCustomBooleanProperty('isLoggedFromAdmin', isLoggedFromAdmin());

  // after update experiment with custom string we need to update flag values according to them
  // unfreeze allow us update all the flags
  Rox.unfreeze();

  dispatch(setFlags());

  dispatch(rolloutUserConfigSent());
};
