import * as React from 'react';
import { getRoutinesIcon } from '../helpers/routineIcons';
import RoutineIcon from '../RoutineIcon/RoutineIcon';
import { RoutineIconType } from '../../../records/routines/types/Routine.types';
import { RoutineColor } from '../../../palettes/RoutineColor';

export interface RoutineIconSelectorProps {
  selectedIcon?: RoutineIconType;
  color?: RoutineColor;
  onClick: (name: RoutineIconType) => void;
  testId?: string;
}

const iconOptions: RoutineIconType[] = [
  'backpack',
  'utensils',
  'books',
  'checkCircle',
  'palette',
  'ban',
  'basketball',
  'headSet',
  'music',
  'bullseye',
  'bookOpen',
  'gamepadModern',
  'moon',
  'home',
  'joystick',
];

const RoutineIconSelector = ({
  testId,
  selectedIcon,
  color,
  onClick,
}: RoutineIconSelectorProps) => {
  return (
    <div data-testid={testId} className="par-routine-icon-selector">
      {iconOptions.map(icon => (
        <RoutineIcon
          testId={testId ? `${testId}-${icon}` : testId}
          key={icon}
          selected={icon === selectedIcon}
          icon={getRoutinesIcon(icon)}
          color={color}
          size="large"
          onClick={() => onClick(icon)}
        />
      ))}
    </div>
  );
};

export default RoutineIconSelector;
