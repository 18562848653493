import type { BaseThunk, Dispatch } from '../../store/state';
import State from '../../store/state';
import { Profile } from '../../records/profile/types/Profile.types';
import {
  CalendarRestrictionsResponse,
  UnifiedPauseCreatePauseAction,
} from './types';
import {
  createRequestCalendarRestrictionPauseAction,
  createRequestCalendarRestrictionPauseErrorAction,
  createRequestCalendarRestrictionPauseSuccessAction,
  deleteRequestCalendarRestrictionPauseAction,
  deleteRequestCalendarRestrictionPauseErrorAction,
  deleteRequestCalendarRestrictionPauseSuccessAction,
  requestCalendarRestrictionPauseErrorAction,
  requestCalendarRestrictionPauseSuccessAction,
} from '../calendarRestrictionsPause';
import api from '../../api';
import { tapReject } from '../../helpers';
import { toActionFormat } from './helpers';
import {
  getActiveCalendarRestriction,
  isCalendarRestrictionPaused,
  toCalendarRestrictionDateStringFormat,
} from '../../selectors/stateSelectors/calendarRestrictionsPause';
import { CalendarRestrictionPauseOperation } from '../../records/calendarRestrictionsPause/CalendarRestrictionPause';
import { List } from 'immutable';
import { fetchStudentPolicies } from '../studentPolicies';
import {
  getAccountCurrentTime,
  getAccountUid,
  getTimezone,
  isProfileLinkedWithLinewizeStudent,
} from '../../selectors';
import { getProfile } from '../../selectors/profileRaw';
import { addToMoment, localNow } from '../../helpers/dates';
import { CalendarRestrictionPausePayload } from '../../records/calendarRestrictionsPause/types/CalendarRestriction.types';
import {
  getIsPauseEnabled,
  getIsPauseEnabled247,
  getSchoolStartEnd,
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { isSchoolTime } from '../../containers/NowCard/helpers/schools/helper';
import { ButtonNames, trackButtonClicked } from '../../helpers/analytics';
import { camelCaseToSnakeCase } from '../../api/mappings/case';
import { getCurrentDashboardPageName } from '../unifiedNowCard/thunks';
import { canSchoolProfileBePaused } from '../../businessLogic/pauseProfile/pauseProfile';

const schoolProfileCanBePaused = (state: State, profileId: number) => {
  return canSchoolProfileBePaused({
    isLinkedWithSchool: isProfileLinkedWithLinewizeStudent(state, profileId),
    isPauseEnabled: getIsPauseEnabled(state) ?? false,
    isDelegationEnabled: isDelegationEnabled(state),
    isProfileOptedInForDelegation: isStudentOptInForDelegation(state),
    isProfileDelegatedToThisAccount:
      isStudentOptInForDelegationForThisAccount(state),
  });
};

export const postProfilePause =
  (duration: number, profileId: Profile['id']) =>
  async (
    dispatch: Dispatch<UnifiedPauseCreatePauseAction>,
    getState: () => State
  ) => {
    const state = getState();
    const accountUid = getAccountUid(state);
    const profile = getProfile(state, profileId);
    const profileUid = profile!.uid;
    const timezone = getTimezone(state);
    const currentTime = localNow(timezone);
    const dateEnd = addToMoment(duration, 'minutes', currentTime);
    const start = toCalendarRestrictionDateStringFormat(currentTime.clone());
    const end = toCalendarRestrictionDateStringFormat(dateEnd);

    const hasPauseActive = isCalendarRestrictionPaused(state, currentTime);

    if (hasPauseActive) {
      // if the family pause is active we need to remove it before adding a new one
      // otherwise we will have all tha pause stacked, and once one finish we could fall to the previous one
      await dispatch(deleteProfilePause(profileId));
    }

    await dispatch(createRequestCalendarRestrictionPauseAction());
    const restriction = CalendarRestrictionPauseOperation.create({
      accountUid,
      profileUid,
      rrule: `DTSTART:${start}\nRRULE:FREQ=DAILY;COUNT=1;UNTIL=${end}`,
    });
    try {
      if (duration > 0) {
        const promises = [
          api.calendarRestrictions.post(
            CalendarRestrictionPauseOperation.serialize(restriction),
            null,
            {
              accountUid,
              profileUid,
            }
          ),
        ];

        if (schoolProfileCanBePaused(state, profileId)) {
          promises.push(
            api.studentPoliciesPause.post(
              {
                end_timestamp: dateEnd.utc().unix(),
              },
              {},
              { profileUid }
            )
          );
        }
        const [responseFamily] = await Promise.all(promises);

        await dispatch(
          createRequestCalendarRestrictionPauseSuccessAction(
            List([
              CalendarRestrictionPauseOperation.fromPayload(
                responseFamily as CalendarRestrictionPausePayload
              ),
            ])
          )
        );
      }
      await dispatch(fetchProfilePause(profileId, true));
    } catch (e) {
      tapReject(() =>
        dispatch(createRequestCalendarRestrictionPauseErrorAction())
      );
      // resume internet on both sides
      dispatch(deleteProfilePause(profileId));
    }
  };

export const fetchProfilePause =
  (profileId: number, cleanCache = false) =>
  async (
    dispatch: Dispatch<UnifiedPauseCreatePauseAction>,
    getState: () => State
  ) => {
    const state = getState();
    const profile = getProfile(state, profileId);
    const profileUid = profile!.uid;
    try {
      const familyResponse = await api.calendarRestrictions.get({
        profileUid,
        filter: 'newest_today_pause_internet',
      });
      if (schoolProfileCanBePaused(state, Number(profileId))) {
        await dispatch(fetchStudentPolicies(Number(profileId), cleanCache));
      }
      dispatch(
        requestCalendarRestrictionPauseSuccessAction(
          toActionFormat(familyResponse as CalendarRestrictionsResponse),
          String(profileId)
        )
      );
    } catch (e) {
      tapReject(() => dispatch(requestCalendarRestrictionPauseErrorAction()));
    }
  };

export const deleteProfilePause =
  (profileId: number, refreshStudentInfo = false) =>
  async (
    dispatch: Dispatch<UnifiedPauseCreatePauseAction>,
    getState: () => State
  ) => {
    const state = getState();
    const accountUid = getAccountUid(state);
    const profile = getProfile(state, profileId);
    const profileUid = profile!.uid;
    const calendarRestrictionUid = getActiveCalendarRestriction(state)?.uid;
    try {
      dispatch(deleteRequestCalendarRestrictionPauseAction());

      const promises = [
        api.calendarRestrictions.delete({
          calendarRestrictionUid,
          profileUid,
          accountUid,
        }),
      ];
      const pauseSchoolProfile = schoolProfileCanBePaused(state, profileId);
      if (pauseSchoolProfile) {
        promises.push(api.studentPoliciesPause.delete({ profileUid }));
      }
      await Promise.all(promises);

      dispatch(
        deleteRequestCalendarRestrictionPauseSuccessAction(
          calendarRestrictionUid
        )
      );

      if (refreshStudentInfo && pauseSchoolProfile) {
        dispatch(fetchStudentPolicies(profileId, true));
      }
    } catch (e) {
      tapReject(() =>
        dispatch(deleteRequestCalendarRestrictionPauseErrorAction())
      );
    }
  };

export const trackPauseButtonClick =
  (duration: number): BaseThunk =>
  (_dispatch, getState) => {
    const state = getState();

    const currentTime = getAccountCurrentTime(state);
    const endPause = currentTime.clone().add('minutes', duration);
    const { start, end } = getSchoolStartEnd(state);
    const trackOptions = {
      quickAction: true,
      endPauseTimeStamp: endPause.unix(),
      userLocalTimezone: getTimezone(state),
      isSchoolTime: isSchoolTime(start, end),
      pauseEnabled247: getIsPauseEnabled247(state),
      duration,
    };

    const pageName = getCurrentDashboardPageName(state);
    trackButtonClicked(pageName, ButtonNames.SchedulePauseInternet, {
      options: JSON.stringify(camelCaseToSnakeCase(trackOptions)),
    });
  };
