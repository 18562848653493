import { getFormValues } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { PageNames, trackPlaceUpdate } from '../../helpers/analytics';
import {
  getPlaces,
  isUsingImperialSystem,
  getPlace,
  getRadiusAddPlaceForm,
} from '../../selectors/index';
import { formRecord, formName } from '../../components/FamilyLocator/PlaceForm';
import AddPlace from '../../components/FamilyLocator/AddPlace';
import {
  navigateToResolveAddress,
  updatePlace,
} from '../../actions/FamilyLocatorActions';
import { trackablePage } from '../../helpers/hocs';
import { setKeyboardVisiblityAtPlaces } from '../../ducks/app';
import { PlaceRecord } from '../../records';
import { getUpdatedFields } from '../../records/place';
import { NO_EVENT_KEY } from '../../constants';
import { getFormData } from '../../lib/presenters/EditPlacePresenter';
import { getBuildPlatform } from '../../helpers/globals';

type paramsType = {
  placeUid: string;
  mapResizingHandler: () => any;
  historyStepsBack: number;
};

const mapStateToProps = (state, { params: { placeUid } }) => {
  const place = getPlace(state, placeUid);
  const radius = getRadiusAddPlaceForm(state);
  const places = getPlaces(state);
  const isUsingFeet = isUsingImperialSystem(state);
  const { minValue, maxValue, formatLabel, initialValues } = getFormData(
    place,
    isUsingFeet
  );

  return {
    places,
    radius,
    minValue,
    maxValue,
    formatLabel,
    initialValues,
    isEditForm: true,
    platform: getBuildPlatform(),
    isUsingFeet: isUsingImperialSystem,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    params: { placeUid = NO_EVENT_KEY, mapResizingHandler, historyStepsBack },
  }: { params: paramsType }
) => ({
  onSaveClick: () =>
    dispatch((dispatch, getState) => {
      trackPlaceUpdate(
        getUpdatedFields(
          getPlace(getState(), placeUid),
          PlaceRecord.fromPayload(
            formRecord(getFormValues(formName)(getState()))
          )
        )
      );

      dispatch(
        updatePlace(
          placeUid,
          isUsingImperialSystem(getState()),
          formRecord(getFormValues(formName)(getState())).toJS(),
          historyStepsBack
        )
      );
    }),
  onAddressClick: () => dispatch(navigateToResolveAddress()),
  keyboardHideHandler: () => dispatch(setKeyboardVisiblityAtPlaces(false)),
  keyboardShowHandler: () => dispatch(setKeyboardVisiblityAtPlaces(true)),
  switchToShowHalfScreenCallback: () => mapResizingHandler(),
});

const EditPlaceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(trackablePage(AddPlace, PageNames.FamilyLocatorEditPlace));

export default EditPlaceContainer;
