import * as React from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Rectangle,
  Bar,
} from 'recharts';
import { getTopAndBottomSegmentsOfEachBar, getCategories } from './helpers';
import { classNames } from '../../helpers';
import { StackedBarChartProps } from './types/StackedBarChartProps.types';
import { theme } from 'styleguide-react';
import { StackedBarChartData } from './types/StackedBarChartData.types';
import { isTesting } from '../../helpers/env';

const BAR_RADIUS = 24;
const DEFAULT_BAR_WIDTH = 22;
const DEFAULT_BAR_SPACING = 11;

const StackedBarChart = React.memo(
  ({
    data,
    className,
    barSize = DEFAULT_BAR_WIDTH,
    barSpacing = DEFAULT_BAR_SPACING,
    xDataKey,
    xAxisTicks,
    yAxisDomain,
    segmentsColorMappings,
    children,
    margin,
    activeCategory,
    fixedWidth = true,
    showCartesianGrid = true,
    childrenAtStart = false,
    xAxisTickFormatter,
    onMouseOverBar,
  }: StackedBarChartProps) => {
    const chartWidth = (barSize + barSpacing) * ((data?.length || 1) + 1);
    const [chartData, setChartData] = React.useState<StackedBarChartData[]>(
      data || []
    );
    const [categories, setCategories] = React.useState<string[]>([]);
    const [firstAndLastBarCategories, setFirstAndLastBarCategories] =
      React.useState<ReturnType<typeof getTopAndBottomSegmentsOfEachBar>>({});

    React.useEffect(() => {
      if (data) {
        const categoriesList = getCategories(data, [xDataKey, 'total']);
        const segments = getTopAndBottomSegmentsOfEachBar(
          data.reduce((acc, curr) => ({ ...acc, [curr[xDataKey]]: curr }), {}),
          categoriesList
        );

        setChartData(data);
        setFirstAndLastBarCategories(segments);
        setCategories(categoriesList);
      }
    }, [data]);

    const getWidth = () => {
      if (typeof fixedWidth === 'boolean') {
        return fixedWidth ? chartWidth : '100%';
      }
      return fixedWidth(chartWidth);
    };

    if (
      !(
        chartData.length &&
        categories &&
        Object.keys(firstAndLastBarCategories).length
      )
    ) {
      return null;
    }

    // We need this to avoid rendering on the tests,
    // as recharts have some rendering issues in the test environment
    if (isTesting()) {
      return <div>StackedBarChart</div>;
    }

    return (
      <ResponsiveContainer
        className={classNames('par-stacked-bar-chart', className)}
        width={getWidth()}
        height="100%"
        debounce={300}
      >
        <ComposedChart
          data={chartData}
          margin={{
            bottom: 10,
            left: 10,
            ...margin,
          }}
        >
          <YAxis domain={yAxisDomain} hide />
          {showCartesianGrid && (
            <CartesianGrid
              horizontal={false}
              strokeDasharray="5 5"
              shapeRendering="crispEdges"
              className="par-stacked-bar-chart__grid"
            />
          )}
          <XAxis
            dataKey={xDataKey}
            ticks={xAxisTicks}
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={({ payload, x, y, className }) => {
              return (
                <TickContent
                  x={x}
                  y={y}
                  className={classNames(
                    className,
                    `par-stacked-bar-chart__xtick`,
                    payload?.value === activeCategory ? 'active' : ''
                  )}
                >
                  {xAxisTickFormatter
                    ? xAxisTickFormatter(payload.value)
                    : payload.value}
                </TickContent>
              );
            }}
          />

          {childrenAtStart && children ? children : null}

          {categories.map(category => (
            <Bar
              key={category}
              dataKey={category}
              stackId="stackedBarChart"
              fill={
                segmentsColorMappings?.[category] || theme.palette.secondary
              }
              barSize={barSize}
              shape={props => {
                if (!props.radius?.length) props.radius = [0, 0, 0, 0];
                if (
                  props.tooltipPayload[0].dataKey ===
                  firstAndLastBarCategories[props[xDataKey]].first
                ) {
                  props.radius[0] = BAR_RADIUS;
                  props.radius[1] = BAR_RADIUS;
                }
                if (
                  props.tooltipPayload[0].dataKey ===
                  firstAndLastBarCategories[props[xDataKey]].last
                ) {
                  props.radius[2] = BAR_RADIUS;
                  props.radius[3] = BAR_RADIUS;
                }

                return (
                  <Rectangle
                    {...props}
                    className="par-stacked-bar-chart__bar"
                  />
                );
              }}
              // We have to increase the hover/touch area of the bar to
              // avoid any visual glitches on interaction.
              background={props => (
                <Rectangle {...props} width={props.width * 2} />
              )}
              onPointerEnter={onMouseOverBar('in')}
              onPointerLeave={onMouseOverBar('out')}
            />
          ))}

          {!childrenAtStart && children ? children : null}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
);

const TickContent = ({ x, y, children, className }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={12} textAnchor="middle" className={className}>
        {children}
      </text>
    </g>
  );
};

export default StackedBarChart;
