import * as React from 'react';
import { LazyImageProps } from './types/LazyImage.types';
import { safeFn } from '../../helpers';

const importResource = safeFn(async (src: string) => {
  try {
    let cleanSrc = src;
    // we are only interested in the path after ../lazy/
    const separateBase = src.split('/lazy/');
    if (separateBase.length > 1) {
      [, cleanSrc] = separateBase;
    }

    // https://webpack.js.org/api/module-methods/#webpackmode
    const { default: bundlePath } = await import(
      /* webpackMode: "lazy-once" */
      `../../assets/base/images/lazy/${cleanSrc.replace(/^\/+/, '')}`
    );
    return Promise.resolve<string>(bundlePath);
  } catch (error) {
    throw new Error(`[Error loading image: ${src}]: ${error}`);
  }
});

/**
 * LazyImage
 *
 * Dynamically load an image.
 *
 * There are some rules that should be followed for this component to work:
 * - Your dynamic asset __MUST__ be placed under `src/assets/base/images/lazy/`
 *
 * Why:
 * We do this because Webpack performs a static analysis at build time.
 * It doesn't try to infer variables which that `import(test)` could be anything,
 * hence it cannot resolve a path passed as variable.
 *
 * Webpack need to know at least some part of the path to create a chunk with the
 * resolved paths (static bundle paths) for all the resources under the partial
 * path provided. So in order to not resolve a large ammount of resources we will
 * use `src/assets/base/images/lazy/` as our base folder for any image that needs
 * to be loaded dynamically.
 *
 * You can check the following links to see why we do this:
 * @link https://github.com/webpack/webpack/issues/6680#issuecomment-370800037
 * @link [Error: Cannot find module with dynamic import](https://github.com/webpack/webpack/issues/6680)
 */
const LazyImage = React.memo(
  ({ src: providedSrc, alt, ...props }: LazyImageProps) => {
    const [resolvedSrc, setResolvedSrc] = React.useState<string | undefined>(
      undefined
    );

    React.useEffect(() => {
      if (!providedSrc) return undefined;

      importResource(providedSrc).then(bundlePath => {
        setResolvedSrc(bundlePath);
      });
    }, [providedSrc]);

    if (!resolvedSrc) return null;

    return <img {...props} alt={alt} src={resolvedSrc} />;
  }
);

LazyImage.displayName = 'LazyImage';

export default LazyImage;
