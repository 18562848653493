import * as React from 'react';
import { BarChartDataType } from './types/BarChartDataType.types';
import { ScreenTimeHistogramDataType } from './types/ScreenTimeDataType.types';
import { theme } from 'styleguide-react';
import { ActiveBarData } from '../StackedBarChart/types/RechartsActiveBarData.types';
import { getElCoordsInDocument } from '../../helpers/dom';
import { List, Map } from 'immutable';
import { MinutesPerHourActivity } from '../../records/activity/types/MinutesPerHourActivity.types';
import { HoursPerDayActivity } from '../../records/activity/types/HoursPerDayActivity.types';
import { SummaryDateRanges } from '../../constants';
import {
  RoutineColor,
  getRoutineColorValue,
} from '../../palettes/RoutineColor';

export const transformToBarChartScreenTimeData = <T>(
  data: ScreenTimeHistogramDataType<T>[],
  isScreenTimeDataSeparated: boolean,
  { xDataKey, totalKey }: { xDataKey: string; totalKey: 'hours' | 'minutes' }
): Record<string, BarChartDataType<T>> => {
  const transformedData: Record<string, BarChartDataType<T>> = {};

  data.forEach((entry: ScreenTimeHistogramDataType<T>) => {
    const xDataKeyValue = entry[xDataKey] as string;

    transformedData[xDataKeyValue] = transformedData[xDataKeyValue] || {
      [xDataKey]: xDataKeyValue,
      total: entry[totalKey],
    };

    // if routine and default_rules times are not present, 'minutes' | 'hours' value will be used as default rules time.
    // This is here for retro-compatibility.
    if (!isScreenTimeDataSeparated) {
      transformedData[xDataKeyValue] = {
        ...transformedData[xDataKeyValue],
        default: entry[totalKey],
      };
    } else {
      transformedData[xDataKeyValue] = {
        ...transformedData[xDataKeyValue],
        default: entry.defaultScreenTime,
      };

      if (entry.routineScreenTime?.length) {
        entry.routineScreenTime.forEach(routine => {
          transformedData[xDataKeyValue] = {
            ...transformedData[xDataKeyValue],
            [routine.uid]: routine[totalKey],
          };
        });
      }
    }
  });

  return transformedData;
};

export const getBarSegmentColorMappings = (
  colors: Map<string, RoutineColor> = Map({})
): Record<string, string> => ({
  default: theme.palette.secondary,
  ...Object.entries(colors.toJS() as Record<string, RoutineColor>).reduce(
    (acc, [uid, routineColorKey]) => ({
      ...acc,
      [uid]: getRoutineColorValue(routineColorKey),
    }),
    {}
  ),
});

export const getMaxValue = (data: BarChartDataType[]) => {
  return Math.max(...data.map(entry => entry.total));
};

export const getAverageValue = (data: BarChartDataType[]) =>
  data.map(entry => entry.total).reduce((a, b) => a + b, 0) / data.length;

export const generate24HRange = (max?: string) => {
  let hasReachedUpperLimit = false;
  const hours: string[] = [];
  const hoursRange = new Array(24).fill(0).map((_, hour) => hour);

  hoursRange.forEach(hour => {
    const hhmmString = `${String(hour).padStart(2, '0')}:00`;
    if (max) {
      if (!hasReachedUpperLimit) hours.push(hhmmString);
      if (hhmmString === max) hasReachedUpperLimit = true;
    } else {
      hours.push(hhmmString);
    }
  });
  return hours;
};

export const addEmptyValuesToData = <T>(
  data: T,
  categories: string[],
  predicate: (category: string) => unknown
) => {
  const newData = {} as T;
  categories.forEach(category => {
    if (!data[category]) {
      newData[category] = predicate(category);
    } else {
      newData[category] = data[category];
    }
  });

  return newData;
};

export const calculateActiveBarCoords = (
  newData: ActiveBarData,
  currentData: ActiveBarData | null,
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const { top, left } = getElCoordsInDocument(containerRef?.current);

  const tooltipTopPosition = newData.y + top;
  const tooltipLeftPosition = newData.x + newData.width / 2 + left;

  // avoid unnecesary updates
  if (
    currentData?.x === tooltipLeftPosition &&
    currentData?.y === tooltipTopPosition
  ) {
    return currentData;
  }

  return {
    ...newData,
    x: tooltipLeftPosition,
    y: tooltipTopPosition,
  };
};

export const transformHoursDataToChartData = <T extends { hour: string }>(
  data: List<MinutesPerHourActivity>,
  isScreenTimeDataSeparated: boolean,
  maxCategoryValue?: string
): BarChartDataType<T>[] => {
  const parsedData = transformToBarChartScreenTimeData<T>(
    data.toJS(),
    isScreenTimeDataSeparated,
    {
      xDataKey: 'hour',
      totalKey: 'minutes',
    }
  );

  const categories = generate24HRange();

  const paddedData = maxCategoryValue
    ? addEmptyValuesToData(parsedData, categories, category => ({
        hour: category,
        total: 0,
      }))
    : parsedData;

  return Object.values(paddedData).sort((a, b) => a.hour.localeCompare(b.hour));
};

export const transformDaysDataToChartData = <T extends { date: string }>(
  data: List<HoursPerDayActivity>,
  isScreenTimeDataSeparated: boolean
): BarChartDataType<T>[] => {
  const parsedData = transformToBarChartScreenTimeData<T>(
    data.toJS(),
    isScreenTimeDataSeparated,
    {
      xDataKey: 'date',
      totalKey: 'hours',
    }
  );

  return Object.values(parsedData).sort((a, b) => a.date.localeCompare(b.date));
};

export const getBestFitChartWidth = (
  calculatedWidthByRecharts: number,
  containerWidth: number | undefined,
  activeDateRange?: SummaryDateRanges
) => {
  if (activeDateRange === SummaryDateRanges.Days7) return '100%';
  if (containerWidth) {
    if (calculatedWidthByRecharts < containerWidth) {
      return containerWidth;
    }
  }
  return calculatedWidthByRecharts;
};
