import { connect } from 'react-redux';
import ProfileDashboardTopCards from '../../../components/Dashboard/DashboardTopCards/ProfileDashboardTopCards';
import {
  getIsProfileLinkedToLW,
  getIsSchoolOnlyProfile,
} from '../helpers/profileDashboardHelpers';
import { isStudentOptInForDelegationForThisAccount } from '../../../selectors/studentPolicies';

const mapStateToProps = (state, { profileId }) => {
  const isProfileLinkedToSchool = getIsProfileLinkedToLW(state, profileId);
  const isOnlySchoolProfile = getIsSchoolOnlyProfile(state, profileId);
  const isDelegateToThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  return {
    isProfileLinkedToSchool,
    isOnlySchoolProfile,
    isDelegateToThisAccount,
    profileId,
  };
};

const mapDispatchToProps = () => ({});

const ProfileDashboardTopCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDashboardTopCards);

export type DashboardTopCardsContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

export default ProfileDashboardTopCardsContainer;
