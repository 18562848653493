import * as React from 'react';
import WellbeingList from '../shared/WellbeingList';
import { t } from '../../../lib/i18n';
import BackgroundGradient from '../../BackgroundGradient/BackgroundGradient';
import { FlexLayout } from 'styleguide-react';
import { ScreenSize, isDesktop } from '../../RenderWhen/RenderWhen';

interface FamilyActivitiesListSectionProps {
  screenSize: ScreenSize;
}

const FamilyActivitiesListSection = ({
  screenSize,
}: FamilyActivitiesListSectionProps) => {
  return (
    <BackgroundGradient gradient="yolk-corner-button-right-large">
      <FlexLayout mainaxis="column" marginBottom="32">
        <WellbeingList
          iconTitle={isDesktop(screenSize)}
          colorScheme="green"
          title={t('Activities for every family')}
          items={[
            t('Delivered weekly to your email inbox'),
            t('Suitable for all families and children of all ages'),
            t('Created by professionals in family wellbeing'),
            t('Easy to enjoy! No other resources needed'),
          ]}
        />
      </FlexLayout>
    </BackgroundGradient>
  );
};

export default FamilyActivitiesListSection;
