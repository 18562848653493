import * as React from 'react';
import { t } from '../../lib/i18n';
import DeviceAssignedTo from './DeviceAssignedTo';
import Location from './Location';
import LocationMap from './LocationMap';
import { LicenseRecord, isPremium } from '../../records/license';
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Card,
  ContentSeparator,
  DeactivableContent,
  EmptyPlaceholder,
  FlexLayout,
  GlobalType,
  GroupHeader,
  Icon,
  IconColor,
  IconSize,
  IconType,
  Label,
  List,
  RegularStyleListItem,
  Switch,
  TransparentButton,
} from 'styleguide-react';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { OrderedSet } from 'immutable';
import DeviceEnableProtection from './DeviceEnableProtection';
import PageLayout from '../Layout/PageLayout';
import PageSectionLayout from '../Layout/PageSectionLayout';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';
import { DeviceRecord } from '../../records/device/types/Device.types';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { isAdvanceAntiTamperingFeatureEnabled } from '../../businessLogic/advanceAntiTampering';
import { getDeviceAdvanceProtectionStatus } from '../../businessLogic/devices';
import { DeviceAdvanceProtectionStatus } from '../../businessLogic/devices/types';

const DeviceIos: React.FunctionComponent<{
  license: LicenseRecord;
  device: DeviceRecord;
  profile: ProfileRecord;
  accountProfiles: OrderedSet<ProfileRecord>;
  protectionEnabled: boolean;
  vpnEnabled: boolean;
  isDeviceTampered: boolean;
  onClickAssignProfileFromDropdown: () => void;
  onClickOpenDropdown: () => void;
  onClickMap: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onTogglePlatformEnabled: (checked: boolean) => void;
  onToggleVPNEnabled: (checked: boolean) => void;
  onOpenAntiTamperingHelpLink: () => void;
}> = ({
  license,
  device,
  profile,
  accountProfiles,
  protectionEnabled,
  vpnEnabled,
  isDeviceTampered,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
  onClickMap,
  onClickEdit,
  onClickDelete,
  onTogglePlatformEnabled,
  onToggleVPNEnabled,
  onOpenAntiTamperingHelpLink,
}) => (
  <PageLayout
    title={device !== undefined ? device.name : ''}
    configuration={[
      {
        screenSize: ScreenSize.Desktop,
        columns: 2,
        mainInsideCard: true,
      },
      {
        screenSize: ScreenSize.Tablet,
        columns: 2,
        mainInsideCard: true,
      },
    ]}
    actions={[
      { actionName: 'back' },
      { actionName: 'edit', onClick: onClickEdit },
      {
        actionName: 'delete',
        onClick: onClickDelete,
        iconColor: IconColor.error,
      },
    ]}
  >
    <PageSectionLayout side="main">
      <DeactivableContent disabled={!protectionEnabled}>
        <DeviceEnableProtection
          protectionEnabled={protectionEnabled}
          onTogglePlatformEnabled={onTogglePlatformEnabled}
        />

        <DeviceIosFields
          device={device}
          profile={profile}
          profiles={accountProfiles}
          license={license}
          vpnEnabled={vpnEnabled}
          isDeviceTampered={isDeviceTampered}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          onToggleVPNEnabled={onToggleVPNEnabled}
          onClickMap={onClickMap}
          onOpenAntiTamperingHelpLink={onOpenAntiTamperingHelpLink}
        />
      </DeactivableContent>
    </PageSectionLayout>
    <PageSectionLayout side="right">
      <Card
        type={GlobalType.white}
        header={{
          title: t('Settings'),
          border: true,
        }}
        className={classNames(
          DesktopLayoutScssUtils.CardRight,
          TabletLayoutScssUtils.CardRight
        )}
      >
        <DeactivableContent disabled={!protectionEnabled}>
          <FlexLayout mainaxis="column" marginTop="8" marginBottom="8">
            {iosSettingsSwitch(vpnEnabled, onToggleVPNEnabled)}
          </FlexLayout>
        </DeactivableContent>
      </Card>
    </PageSectionLayout>
  </PageLayout>
);

const DeviceIosFields: React.FunctionComponent<{
  license: LicenseRecord;
  device: DeviceRecord;
  profile: ProfileRecord;
  profiles: OrderedSet<ProfileRecord>;
  vpnEnabled: boolean;
  isDeviceTampered: boolean;
  onClickAssignProfileFromDropdown: () => void;
  onClickOpenDropdown: () => void;
  onToggleVPNEnabled: (checked: boolean) => void;
  onClickMap: () => void;
  onOpenAntiTamperingHelpLink: () => void;
}> = ({
  license,
  device,
  profile,
  profiles,
  vpnEnabled,
  isDeviceTampered,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
  onToggleVPNEnabled,
  onClickMap,
  onOpenAntiTamperingHelpLink,
}) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="flex-start"
    flexGrow="0"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <GroupHeader>{t('Assigned to:')}</GroupHeader>
    </FlexLayout>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout mainaxis="column" margin="16" marginBottom="32">
        <DeviceAssignedTo
          device={device}
          profile={profile}
          profiles={profiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceIos"
        />
      </FlexLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlexLayout mainaxis="column" marginTop="16" marginBottom="32">
        <DeviceAssignedTo
          device={device}
          profile={profile}
          profiles={profiles}
          onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
          onClickOpenDropdown={onClickOpenDropdown}
          testId="deviceIos"
        />
      </FlexLayout>
    </RenderWhen>
    <ContentSeparator />

    <AdvanceAntiTamperingSection
      device={device}
      isDeviceTampered={isDeviceTampered}
      onOpenHelpLink={onOpenAntiTamperingHelpLink}
    />

    <RenderWhen screenSize={ScreenSize.Mobile}>
      <FlexLayout mainaxis="column" marginTop="16">
        <GroupHeader>{t('Settings')}</GroupHeader>
      </FlexLayout>
      <FlexLayout mainaxis="column" margin="16" marginBottom="32">
        {iosSettingsSwitch(vpnEnabled, onToggleVPNEnabled)}
      </FlexLayout>
      <ContentSeparator />
    </RenderWhen>
    <FlexLayout mainaxis="column" marginTop="16">
      <GroupHeader>{t('Last Location')}</GroupHeader>
    </FlexLayout>
    <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
      <FlexLayout
        mainaxis="column"
        marginBottom="8"
        marginRight="16"
        marginLeft="16"
      >
        {deviceLocationLicenseMessage(license, device) ||
          deviceLocationUnassignedMessage(profile) ||
          deviceLocation(device ? device.location : null)(onClickMap)}
      </FlexLayout>
    </RenderWhen>
    <RenderWhen screenSize={ScreenSize.Desktop}>
      <FlexLayout mainaxis="column" marginBottom="8">
        {deviceLocationLicenseMessage(license, device) ||
          deviceLocationUnassignedMessage(profile) ||
          deviceLocation(device ? device.location : null)(onClickMap)}
      </FlexLayout>
    </RenderWhen>
  </FlexLayout>
);

const iosSettingsSwitch = (vpnEnabled, onToggleVPNEnabled) => (
  <List
    listItemMarginTop="8"
    listItemPaddingTop="8"
    listItemMarginBottom="8"
    listItemPaddingBottom="8"
  >
    <RegularStyleListItem
      title={t('Enable {{shortName}} VPN')}
      upperSubtitle={t('Allows you to supervise internet and apps activity.')}
      actionElement={
        <Switch
          checked={vpnEnabled}
          testId="vpnEnabled"
          onClick={ev =>
            onToggleVPNEnabled((ev.target as HTMLInputElement).checked)
          }
        />
      }
    />
  </List>
);

const deviceLocationLicenseMessage = (
  license: LicenseRecord,
  device?: DeviceRecord
) =>
  !isPremium(license.type) || device === undefined ? (
    <FlexLayout mainaxis="column" marginTop="24">
      <EmptyPlaceholder
        text={t("To see device's location, upgrade to premium")}
      />
    </FlexLayout>
  ) : null;

const deviceLocationUnassignedMessage = (profile: ProfileRecord) =>
  profile == null && (
    <FlexLayout mainaxis="row" marginTop="24" marginBottom="24">
      <FlexLayout mainaxis="row" marginRight="8">
        <Icon
          type={IconType.mapMarkerSlash}
          color={IconColor.primary}
          size={IconSize.lg}
        />
      </FlexLayout>
      <Label>{t('Device is unassigned, Location not available')}</Label>
    </FlexLayout>
  );

const deviceLocation = location => onClick =>
  [
    <Location location={location} key="1" />,
    <FlexLayout mainaxis="column" key="1">
      <TransparentButton onClick={onClick}>
        <LocationMap location={location} />
      </TransparentButton>
    </FlexLayout>,
  ];

export const AdvanceAntiTamperingSection = ({
  device,
  isDeviceTampered,
  onOpenHelpLink,
}: {
  device: DeviceRecord;
  isDeviceTampered: boolean;
  onOpenHelpLink: () => void;
}) => {
  if (!isAdvanceAntiTamperingFeatureEnabled()) return null;
  const protectionStatus = getDeviceAdvanceProtectionStatus(device);
  if (isDeviceTampered || protectionStatus === 'unknown') return null;

  return (
    <React.Fragment>
      <FlexLayout
        testId="anti-tampering-section"
        mainaxis="row"
        mainaxisAlignment="space-between"
        crossaxisAlignment="center"
        marginTop="16"
      >
        <GroupHeader>{t('Anti-tampering')}</GroupHeader>
        {advanceAntiTamperingLink(protectionStatus, onOpenHelpLink)}
      </FlexLayout>
      <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
        <FlexLayout margin="16" mainaxis="column" marginBottom="32">
          {advanceAntiTamperingDescription(protectionStatus)}
        </FlexLayout>
      </RenderWhen>
      <RenderWhen screenSize={ScreenSize.Desktop}>
        <FlexLayout mainaxis="column" marginTop="16" marginBottom="32">
          {advanceAntiTamperingDescription(protectionStatus)}
        </FlexLayout>
      </RenderWhen>

      <ContentSeparator />
    </React.Fragment>
  );
};

const advanceAntiTamperingLink = (
  status: DeviceAdvanceProtectionStatus,
  onOpenHelpLink: () => void
) => {
  let text: string = t('Go to help center');
  if (status === 'disabled') text = t('Learn more');

  return (
    <Button
      size={ButtonSize.medium}
      buttonType={ButtonType.plain}
      icon={<Icon type={IconType.arrowUpRightFromSquare} />}
      iconPosition={ButtonIconPosition.right}
      onClick={onOpenHelpLink}
    >
      {text}
    </Button>
  );
};

const advanceAntiTamperingDescription = (
  status: DeviceAdvanceProtectionStatus
) => {
  if (status === 'enabled') {
    return t(
      'Qustodio Advance anti-tampering protection is active. See how to adjust your settings in the help center.'
    );
  }
  return t(
    'Install Qustodio Advance to prevent your child from removing Qustodio.'
  );
};

export default DeviceIos;
