import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  EmptyPlaceholder,
  FlexLayout,
  GroupHeader,
  IconColor,
  Layout,
  List,
} from 'styleguide-react';
import { EventRecord, UrlDetailsEvent, UrlDetailsRecord } from '../../records';
import {
  DetailsItemRecord,
  filterBlockedUrlDetailsItem,
  filterNonBlockedUrlDetailsItem,
  filterUrlDetailsPages,
  filterUrlDetailsVideos,
  UrlDetailsPageRecord,
  VideoDetailsRecord,
} from '../../records/urlDetails';
import { EventType, ISOLanguage } from '../../constants';
import UrlDetailsSiteInfo from './UrlDetailsSiteInfo';
import UrlDetailsVideoInfo from './UrlDetailsVideoInfo';
import UrlDetailsDomainTrust from './UrlDetailsDomainTrust';
import UrlDetailsVideoListItem from './UrlDetailsVideoListItem';
import UrlDetailsSiteListItem from './UrlDetailsSiteListItem';
import DropDownActionSheet, {
  getDropDownActionSheetTitle,
} from '../DropDown/DropDownActionSheet';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import {
  UrlDetailsSafeNetwork,
  UrlDetailsSafeNetworkProps,
} from './UrlDetailsSafeNetwork';
import {
  isSafeNetwork,
  isSafeNetworkDisablingProtection,
  isSafeNetworkEnablingProtection,
} from '../../records/event';

const UrlDetailsPage: React.FunctionComponent<{
  isFetching: boolean;
  event: EventRecord | undefined;
  urlDetails: UrlDetailsRecord;
  webFilterEvent?: EventRecord | undefined;
  youtubeEvent?: EventRecord | undefined;
  detailsActionSelected?: DetailsItemRecord | undefined;
  timezone: string;
  locale: ISOLanguage;
  onClickRuleOptions: (event?: EventRecord) => any;
  onWebActionSheetClick: () => any;
  onClickPage: (page: UrlDetailsEvent) => any;
  onClickScreenshot: () => any;
  onClickAction: (item: DetailsItemRecord) => any;
  onDetailActionSheetClick: (item: DetailsItemRecord | undefined) => any;
  onClickAdjustSettings: UrlDetailsSafeNetworkProps['onClickAdjustSettings'];
  onClickReadHelpArticle: UrlDetailsSafeNetworkProps['onClickReadHelpArticle'];
}> = ({
  isFetching,
  event,
  urlDetails,
  timezone,
  locale,
  onClickRuleOptions,
  onClickPage,
  onClickScreenshot,
  onClickAction,
  onClickAdjustSettings,
  onClickReadHelpArticle,
}) => {
  const getHeaderTitle = (event?: EventRecord) => {
    if (!event) return '';

    if (event.type === EventType.Youtube) {
      return t('YouTube');
    }
    if (isSafeNetworkDisablingProtection(event)) {
      return t('School Safe Network connected');
    }
    if (isSafeNetworkEnablingProtection(event)) {
      return t('School Safe Network disconnected');
    }
    return event.host;
  };
  const { items } = urlDetails;

  const blockedPages = filterBlockedUrlDetailsItem(
    filterUrlDetailsPages(items)
  );
  const visitedPages = filterNonBlockedUrlDetailsItem(
    filterUrlDetailsPages(items)
  );
  const blockedVideos = filterBlockedUrlDetailsItem(
    filterUrlDetailsVideos(items)
  );
  const visitedVideos = filterNonBlockedUrlDetailsItem(
    filterUrlDetailsVideos(items)
  );
  const hasContent =
    blockedPages.size !== 0 ||
    visitedVideos.size !== 0 ||
    blockedVideos.size !== 0 ||
    visitedPages.size !== 0;

  const getNoDataMessage = () =>
    isFetching ? (
      <EmptyPlaceholder text={t('Loading...')} />
    ) : (
      <EmptyPlaceholder text={t('Information not available')} />
    );

  const renderDetails = () => {
    const type = event !== undefined ? event.type : EventType.Unknown;
    if (isSafeNetwork(event)) {
      return (
        <UrlDetailsSafeNetwork
          event={event}
          timezone={timezone}
          locale={locale}
          onClickAdjustSettings={onClickAdjustSettings}
          onClickReadHelpArticle={onClickReadHelpArticle}
        />
      );
    }

    return (
      <FlexLayout mainaxis="column">
        {type === EventType.Youtube && (
          <UrlDetailsVideoInfo
            event={event}
            items={urlDetails.items}
            onClick={onClickScreenshot}
          />
        )}
        {type !== EventType.Youtube && (
          <FlexLayout mainaxis="column">
            <UrlDetailsSiteInfo
              event={event}
              urlDetails={urlDetails}
              onClick={onClickScreenshot}
            />
            <UrlDetailsDomainTrust reputation={urlDetails.reputation} />
          </FlexLayout>
        )}
        {hasContent ? (
          <div>
            {blockedVideos.size !== 0 && (
              <FlexLayout mainaxis="column">
                <Layout marginBottom="24" marginTop="24">
                  <GroupHeader>{t('Blocked videos')}</GroupHeader>
                </Layout>
                <List
                  allowCustomListItemStyle={UrlDetailsVideoListItem}
                  listItemMarginTop="24"
                >
                  {blockedVideos.map(item => (
                    <UrlDetailsVideoListItem
                      video={item as VideoDetailsRecord}
                      onClickPage={onClickPage}
                      onClickAction={onClickAction}
                      key={item.key}
                    />
                  ))}
                </List>
              </FlexLayout>
            )}
            {visitedVideos.size !== 0 && (
              <FlexLayout mainaxis="column">
                <Layout marginBottom="24" marginTop="24">
                  <GroupHeader>{t('Watched videos')}</GroupHeader>
                </Layout>
                <List
                  allowCustomListItemStyle={UrlDetailsVideoListItem}
                  listItemMarginTop="24"
                >
                  {visitedVideos.map(item => (
                    <UrlDetailsVideoListItem
                      video={item as VideoDetailsRecord}
                      onClickPage={onClickPage}
                      onClickAction={onClickAction}
                      key={item.key}
                    />
                  ))}
                </List>
              </FlexLayout>
            )}
            {blockedPages.size !== 0 && (
              <FlexLayout mainaxis="column">
                <Layout marginBottom="24" marginTop="24">
                  <GroupHeader>{t('Blocked websites')}</GroupHeader>
                </Layout>
                <List
                  allowCustomListItemStyle={UrlDetailsSiteListItem}
                  listItemMarginTop="24"
                >
                  {blockedPages.map(item => (
                    <UrlDetailsSiteListItem
                      site={item as UrlDetailsPageRecord}
                      onClickPage={onClickPage}
                      key={item.key}
                    />
                  ))}
                </List>
              </FlexLayout>
            )}
            {visitedPages.size !== 0 && (
              <FlexLayout mainaxis="column">
                <Layout marginBottom="24" marginTop="24">
                  <GroupHeader>{t('Visited')}</GroupHeader>
                </Layout>
                <List
                  allowCustomListItemStyle={UrlDetailsSiteListItem}
                  listItemMarginTop="24"
                >
                  {visitedPages.map(item => (
                    <UrlDetailsSiteListItem
                      site={item as UrlDetailsPageRecord}
                      onClickPage={onClickPage}
                      key={item.key}
                    />
                  ))}
                </List>
              </FlexLayout>
            )}
          </div>
        ) : (
          getNoDataMessage()
        )}
      </FlexLayout>
    );
  };

  const mainInsideCard = !isSafeNetwork(event);
  const configuration = [
    {
      screenSize: ScreenSize.Desktop,
      mainInsideCard,
      columns: 2,
    },
    {
      screenSize: ScreenSize.Tablet,
      mainInsideCard,
      columns: 1,
    },
  ];

  const actions = [{ actionName: 'back' }];
  const editable = !isSafeNetwork(event);
  if (editable) {
    actions.push({
      actionName: 'edit',
      onClick: onClickRuleOptions(event),
      iconColor: IconColor.regular,
      icon: (
        <DropDownActionSheet
          isVisit
          selectedActionSheetKey={event ? event.key : undefined}
          title={event ? getDropDownActionSheetTitle(event) : undefined}
        />
      ),
    });
  }

  return (
    <PageLayout
      title={getHeaderTitle(event)}
      configuration={configuration}
      actions={actions}
    >
      {renderDetails()}
    </PageLayout>
  );
};

export default UrlDetailsPage;
