import flags from '../../sideEffects/flags';
import { decision } from '../decision';
import { Decision } from '../decision/types';
import {
  hasMultipleDevicesInASafeNetwork,
  hasProfileSchool247Enabled,
  hasSomeDeviceInSafeNetworks,
  isDelegatedToOtherGuardian,
  isPausePartiallyInSchoolPeriod,
  isWholePauseDuringSchoolPeriod,
  profileHasSchoolDevice,
} from './conditions';
import {
  PauseProfileText,
  PauseProfileDecisionContext,
  SchoolProfileCanBePausedProps,
} from './types';

export const pauseProfileDecision = (
  handler: Decision<PauseProfileDecisionContext, PauseProfileText>
) => decision<PauseProfileDecisionContext, PauseProfileText>(handler);

export const safeNetworkSchoolHoursDecision = pauseProfileDecision({
  meta: {
    name: 'safe network and school hours',
    description:
      'check if the profile has multiple devices in the safe network during school time',
  },
  cond: hasMultipleDevicesInASafeNetwork,
  yes: () => 'SAFE_NETWORK_SCHOOL_INSIDE_MULTIPLE_DEVICES',
  no: () => 'SAFE_NETWORK_SCHOOL_INSIDE_SINGLE_DEVICE',
});

export const safeNetworkDecision = pauseProfileDecision({
  meta: {
    name: 'multiple devices in Safe network?',
    description: 'check howe many devices are in a safe network',
  },
  cond: hasMultipleDevicesInASafeNetwork,
  yes: () => 'SAFE_NETWORK_MULTIPLE_DEVICES',
  no: () => 'SAFE_NETWORK_SINGLE_DEVICE',
});

const pauseInSafeNetworkInsideSchoolPeriod = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: isWholePauseDuringSchoolPeriod,
  yes: safeNetworkSchoolHoursDecision,
  no: safeNetworkDecision,
});

const safeNetworkProfileDelegatedToOtherGuardian = pauseProfileDecision({
  cond: isDelegatedToOtherGuardian,
  yes: () => 'STUDENT_DELEGATED_TO_OTHER_GUARDIAN',
  no: pauseInSafeNetworkInsideSchoolPeriod,
});

const deviceInSafeNetworkDecision = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: profileHasSchoolDevice,
  yes: safeNetworkProfileDelegatedToOtherGuardian,
  no: safeNetworkDecision,
});

const pauseIsPartialInSchoolHours = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: isPausePartiallyInSchoolPeriod,
  yes: () => 'SCHOOL_OUTSIDE_PAUSE',
  no: () => 'NO_TEXT',
});

const pauseIsInsideSchoolPeriodDecision = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: isWholePauseDuringSchoolPeriod,
  yes: () => 'SCHOOL_INSIDE_CANT_PAUSE',
  no: pauseIsPartialInSchoolHours,
});

const profileHas247Decision = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: hasProfileSchool247Enabled,
  yes: () => 'SCHOOL_CAN_UNPAUSE',
  no: pauseIsInsideSchoolPeriodDecision,
});

const noDeviceInSafeNetworkCheckDelegateToOtherGuardian = pauseProfileDecision({
  cond: isDelegatedToOtherGuardian,
  yes: () => 'STUDENT_DELEGATED_TO_OTHER_GUARDIAN',
  no: profileHas247Decision,
});

const noDeviceInSafeNetworksDecision = pauseProfileDecision({
  meta: { name: '', description: '' },
  cond: profileHasSchoolDevice,
  yes: noDeviceInSafeNetworkCheckDelegateToOtherGuardian,
  no: () => 'NO_TEXT',
});

export const pauseProfileTextDecision = pauseProfileDecision({
  meta: {
    name: 'is in safe network',
    description: 'check if the profile has devices in a Safe Network',
  },
  cond: hasSomeDeviceInSafeNetworks,
  yes: deviceInSafeNetworkDecision,
  no: noDeviceInSafeNetworksDecision,
});

/**
 * Returns whether delegation settings allows the account to pause.
 *
 * This method does not check whether pause is enabled or the account
 * is linewize.
 */
export const delegationAllowsPausing = (
  delegationEnabled: boolean,
  studentOptIn: boolean,
  studentOptInInThisAccount: boolean
): boolean => {
  if (!flags.useDelegation.isEnabled() || !delegationEnabled) return true;
  return !studentOptIn || studentOptInInThisAccount;
};

/**
 * This functions centralizes if a school profile can be paused, this includes a school delegated profile
 * use this before adding / removing a LW pause
 */
export const canSchoolProfileBePaused = ({
  isLinkedWithSchool,
  isPauseEnabled,
  isDelegationEnabled,
  isProfileDelegatedToThisAccount,
  isProfileOptedInForDelegation,
}: SchoolProfileCanBePausedProps) => {
  if (!isLinkedWithSchool) {
    return false;
  }

  if (!isPauseEnabled) {
    return false;
  }
  return delegationAllowsPausing(
    isDelegationEnabled,
    isProfileOptedInForDelegation,
    isProfileDelegatedToThisAccount
  );
};
