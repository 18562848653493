import { connect } from 'react-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import AppRulesPremiumFlyover from '../../../components/AppRules/AppRulesPremiumFlyover';
import {
  FeatureBlockingContexts,
  GenericPageNames,
  ProfileRuleNames,
  trackUpgradeBlockedFeature,
} from '../../../helpers/analytics';
import State, { Dispatch } from '../../../store/state';
import { getAccountLocale } from '../../../selectors';
import { navigateToUpgrade } from '../../../actions/Navigation';

const mapStateToProps = (state: State) => ({
  accountLocale: getAccountLocale(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickUpgrade: () => {
    trackUpgradeBlockedFeature(
      GenericPageNames.GamesAndAppsRules,
      FeatureBlockingContexts.FullBlockedFeature,
      ProfileRuleNames.GamsAndApps
    );
    dispatch(navigateToUpgrade());
  },
});

const AppRulesPremiumFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRulesPremiumFlyover);

export type AppRulesPremiumFlyoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default AppRulesPremiumFlyoverContainer;
