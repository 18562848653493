import { connect } from 'react-redux';
import MapWrapper from '../../components/FamilyLocator/MapWrapper';
import {
  getMarkersCoordinatesString,
  getMarkersByScreen,
  navigateToPlaces,
  navigateToDirections,
  getViewFromScreen,
} from '../../actions/FamilyLocatorActions';
import { identifyScreen } from './router';
import flags from '../../sideEffects/flags';
import { fetchPlacesIfNeeded } from '../../ducks/places';
import { getProfile } from '../../selectors/profileRaw';
import { trackClickedDirections } from '../../helpers/analytics';
import { Marker } from '../../records/marker';
import { FamilyLocatorScreen } from './types';
import State, { Dispatch } from '../../store/state';
import { feetToMeters } from '../../helpers/conversions';
import { NO_EVENT_KEY } from '../../constants';
import {
  getRegularStatusProfileLocationEventIds,
  getEventByKey,
} from '../../selectors/event';
import { isUsingImperialSystem } from '../../selectors/account';
import { getRadiusAddPlaceForm } from '../../selectors/place';
import { getIsKeyboardVisibleAtPlaces } from '../../selectors/app';
import { hasFetchedAllProfileRules } from '../../selectors/profileRules';
import { hasValidAddress } from '../../records/location';

export const mapStateToProps = (
  state: State,
  { params: { profileId, action, eventKey = NO_EVENT_KEY, placeUid } }
) => {
  const activeScreen = identifyScreen({ profileId, action });
  const radiusInMeters = getRadiusAddPlaceForm(state);
  const noEvents =
    activeScreen === FamilyLocatorScreen.KidTimeline &&
    getRegularStatusProfileLocationEventIds(
      state,
      getProfile(state, profileId).uid
    ).size === 0;
  const markers = getMarkersByScreen(state, activeScreen, {
    profileId,
    action,
    eventKey,
    placeUid,
  });
  return {
    markers,
    noEvents,
    activeScreen,
    profileId,
    isUsingImperialSystem: isUsingImperialSystem(state),
    eventKey,
    hasValidEventAddress:
      eventKey !== NO_EVENT_KEY &&
      hasValidAddress(getEventByKey(state, eventKey).location),
    radius: isUsingImperialSystem(state)
      ? Math.round(feetToMeters(radiusInMeters))
      : radiusInMeters,
    showPlaceholder: markers.size === 0,
    markersCoordinatesString: getMarkersCoordinatesString(markers),
    isKeyboardVisible: getIsKeyboardVisibleAtPlaces(state),
    hasFetchedAllProfileRules: hasFetchedAllProfileRules(state),
  };
};

const mapDispatchToProps = (dispatch, { params: { profileId, action } }) => ({
  onClickPlaces: () => dispatch(navigateToPlaces()),
  onClickDirections: (marker: Marker) => {
    dispatch(() =>
      trackClickedDirections(
        getViewFromScreen(identifyScreen({ profileId, action }))
      )
    );
    return dispatch(navigateToDirections(marker));
  },
});

const MapWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapWrapper);

MapWrapperContainer.load = () => (dispatch: Dispatch) => {
  if (flags.geofencing.isEnabled()) {
    dispatch(fetchPlacesIfNeeded());
  }
};

export default MapWrapperContainer;
