import { connect } from 'react-redux';
import { SET_LOADED_ROOT } from '../ducks/actionNames/app';
import Logout from '../components/Logout/Logout';
import { startWebSSOIfConditionsAreMet } from '../actions/sso';
import { isRedirectingToSSOLogin } from '../selectors/app';
import {
  clearOnLogout,
  fetchFamilyPortalLogout,
  markAsFreshlyLoggedOut,
} from '../sideEffects/logout';
import {
  TagManagerCustomEvents,
  sendTagManagerEvent,
} from '../helpers/tagManager';
import { isBrowserPlatform } from '../helpers';
import { finishedLoadingInitialData } from '../ducks/app/redirectAfterLogin';

const mapStateToProps = (state, { params }) => ({
  error: params.error === 'fail',
  loading: isRedirectingToSSOLogin(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: () =>
    dispatch(
      startWebSSOIfConditionsAreMet({
        pathname: '/',
        key: null,
        query: {},
      })
    ),
});

const LogoutContainer = connect(mapStateToProps, mapDispatchToProps)(Logout);

LogoutContainer.load = () => dispatch => {
  dispatch(clearOnLogout());

  dispatch(markAsFreshlyLoggedOut());
  sendTagManagerEvent(TagManagerCustomEvents.AccountLoggedOut, {
    accountLogged: false,
  });

  if (isBrowserPlatform()) {
    dispatch(fetchFamilyPortalLogout());
  }

  // Handle history error https://github.com/ReactTraining/history/issues/427
  dispatch({ type: SET_LOADED_ROOT, payload: false });

  return dispatch(finishedLoadingInitialData());
};

export default LogoutContainer;
