import { chain, invertObj, map, pipe, uniq } from 'ramda';
import { List, Record as ImmutableRecord } from 'immutable';
import { RoutineRecord } from '../../../records/routines/types/Routine.types';
import { CalendarViewData } from '../../CalendarView/types/CalendarView.types';
import {
  SCHEDULE_WEEKDAYS,
  Schedule,
  ScheduleRecord,
} from '../../../records/routines/schedule/types/Schedule.types';
import {
  MINS_IN_A_DAY,
  MINUTES_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
  getMinutesFromStartOfDay,
  mapWeekDays,
  formatAndRemoveDuplicateMeridiem,
  secondsToHourFormat,
} from '../../../helpers/dates';
import { RoutineColor } from '../../../palettes/RoutineColor';
import {
  SplitJoinSlotData,
  splitJoinSlotsWithOverlaps,
} from './overlappingHelper';
import { t } from '../../../lib/i18n';

type ExtendedScheduleRecord = ImmutableRecord<
  Schedule & {
    /** we need a unique id because we break the same slot in 2 parts when it overflows to the next day */
    uniqueId: string;
    weekdayName: typeof SCHEDULE_WEEKDAYS[number];
    weekdayNumber: number;
    startTimeMinutes: number;
    isOverflown: boolean;
    label: string;
    groupedScheduleUids: string[];
  }
>;

// Routines calendar view data with metadata types attached
export type RoutinesCalendarViewData = CalendarViewData<{
  /**
   * - Grouped slot = length > 1.
   * - Single routine slot =  length === 1.
   */
  routineUids?: string[];
}>;

const MINUTES_30 = MINUTES_IN_AN_HOUR / 2;
const weekdaysArray = mapWeekDays(SCHEDULE_WEEKDAYS);
const weekdaysInvArray = invertObj(weekdaysArray);

export const getExtendScheduleRecord = (
  schedule: ScheduleRecord,
  primaryWeekday: Schedule['weekdays'][number],
  timezone: string
): ExtendedScheduleRecord => {
  const startTimeMinutes = getMinutesFromStartOfDay(
    schedule.startTime,
    timezone
  );

  const uniqueId = `${primaryWeekday}-${startTimeMinutes}-${schedule.durationMinutes}=${schedule.uid}`;

  return ImmutableRecord({
    ...schedule.toJS(),
    uniqueId,
    weekdayName: primaryWeekday,
    weekdayNumber: Number(weekdaysInvArray[primaryWeekday]),
    startTimeMinutes,
    isOverflown: false,
    label: '',
    groupedScheduleUids: [schedule.uid],
  })();
};

/**
 * Separates routine schedules into weekdays and performs additional transformations.
 *
 * @param routinesList - List of routine records.
 * @param timezone - Timezone string for schedule time calculations.
 * @param schedule - schedule records associated with routine.
 *
 * @returns An array of extended schedule records, each representing a weekday schedule.
 * The records include additional properties like uniqueId, weekdayName, startTimeMinutes, isOverflown, label,
 * and groupedScheduleUids for further processing and display.
 *
 * @throws If a schedule is not associated with any routine.
 */
export const separateSchedulesIntoWeekdays =
  (routinesList: List<RoutineRecord>, timezone: string) =>
  (schedule: ScheduleRecord): ExtendedScheduleRecord[] => {
    const separatedSchedules: ExtendedScheduleRecord[] = [];

    const routine = routinesList.find(
      routine => routine.uid === schedule.routineUid
    );

    if (!routine) {
      throw Error(
        `Schedule "${schedule.uid}" is not associated to any routine!`
      );
    }

    // filter paused and disabled routines
    if (!(routine.enabled && !routine.paused)) return [];

    // Create a new instance of schedule for each day of the week that it applies to.
    schedule?.weekdays.forEach(weekday => {
      separatedSchedules.push(
        getExtendScheduleRecord(schedule, weekday, timezone)
      );
    });

    return separatedSchedules;
  };

/**
 * Calculates and assigns a human-readable label to a schedule based on its start time,
 * end time, and the specified time format (military or AM/PM).
 *
 * @param isMilitaryTime - Indicates whether the time should be displayed in military format.
 * @returns A function that takes a schedule and returns a new schedule with an added 'label'
 * property representing the formatted time range.
 */
export const calculateScheduleLabel =
  (isMilitaryTime: boolean) =>
  (schedule: ExtendedScheduleRecord): ExtendedScheduleRecord => {
    const { durationMinutes, startTimeMinutes } = schedule;
    const startTime = secondsToHourFormat(
      startTimeMinutes * SECONDS_IN_A_MINUTE
    );
    const endTime = secondsToHourFormat(
      (startTimeMinutes + durationMinutes) * SECONDS_IN_A_MINUTE
    );

    if (isMilitaryTime) {
      const formattedTime = `${startTime} - ${endTime}`;
      return schedule.set('label', formattedTime);
    }

    // AM/PM clock
    const formattedTime = formatAndRemoveDuplicateMeridiem(startTime, endTime);
    return schedule.set('label', formattedTime);
  };

/**
 * Separates overflown schedules, where the duration extends beyond a single day,
 * into multiple schedules, adjusting start times and durations accordingly.
 *
 * @param schedules - Array of schedules representing schedules.
 * @returns An array of schedules with overflown schedules separated.
 */
export const separateOverflownSchedules = (
  schedule: ExtendedScheduleRecord
): ExtendedScheduleRecord[] => {
  const fullDayMinutes = MINS_IN_A_DAY;
  const separatedSchedules: ExtendedScheduleRecord[] = [];

  // cut overflowing schedules and add the remainder at the beginning of the next weekday
  const { weekdayNumber, startTimeMinutes, durationMinutes } = schedule;

  const scheduleOverflowMinutes =
    startTimeMinutes + durationMinutes - fullDayMinutes;

  // No overflow
  if (scheduleOverflowMinutes <= 0) return [schedule];

  // substract overflown minutes from the schedule's starting day's part
  separatedSchedules.push(
    schedule.set('durationMinutes', durationMinutes - scheduleOverflowMinutes)
  );

  // add the remainder minutes to the next day starting from 00:00
  const nextWeekdayMumber = (weekdayNumber + 1) % SCHEDULE_WEEKDAYS.length;
  const nextWeekdayName = weekdaysArray[nextWeekdayMumber];
  separatedSchedules.push(
    schedule
      .set('startTime', '00:00')
      .set('startTimeMinutes', 0)
      .set('durationMinutes', scheduleOverflowMinutes)
      .set('weekdayNumber', nextWeekdayMumber)
      .set('weekdayName', nextWeekdayName)
      .set('weekdays', [nextWeekdayName])
      .set('isOverflown', true)
  );

  return separatedSchedules;
};

/** @param time minutes from the start of the day */
export const adjustTimeToNearestHour = (time: number) => {
  const minutes = time % MINUTES_IN_AN_HOUR;
  const baseHour = time - minutes;
  if (minutes >= 0 && minutes <= 10) return baseHour;
  if (minutes > 10 && minutes <= 50) return baseHour + MINUTES_30;
  if (minutes > 50 && minutes <= 59) return baseHour + MINUTES_IN_AN_HOUR;

  return time;
};

/**
 * Rounds the start time and end time of a schedule to the nearest 30-minute mark (:00, :30, :60)
 * while ensuring a minimum duration of 30 minutes for schedules of span shorter than 30min.
 *
 * @param schedule - The schedule to be rounded.
 * @returns A new schedule with rounded start time, end time,
 * and adjusted duration to meet the minimum 30-minute requirement.
 */
export const roundScheduleTo30MinSlots = (
  schedule: ExtendedScheduleRecord
): ExtendedScheduleRecord => {
  const { durationMinutes, startTimeMinutes } = schedule;
  const endTimeMinutes = startTimeMinutes + durationMinutes;

  // round start time
  const roundedStartTime = adjustTimeToNearestHour(startTimeMinutes);

  // round end time
  const roundedEndTime = adjustTimeToNearestHour(endTimeMinutes);

  // min limit of 30 minutes
  let roundedDuration = roundedEndTime - roundedStartTime;
  if (roundedDuration < MINUTES_30) roundedDuration = MINUTES_30;

  return schedule
    .set('startTimeMinutes', roundedStartTime)
    .set('durationMinutes', roundedDuration)
    .set(
      'startTime',
      secondsToHourFormat(roundedStartTime * MINUTES_IN_AN_HOUR)
    );
};

/**
 * Groups overlapping schedules within each weekday and calculates schedule data,
 * including adjustments for overlapping slots and creation of grouped schedules.
 *
 * @param schedules - The schedules to be grouped and processed.
 * @returns An array of schedules with adjusted properties
 * to handle overlapping time slots and grouped schedules.
 */
const calculateScheduleGroups = (
  schedules: ExtendedScheduleRecord[]
): ExtendedScheduleRecord[] => {
  const groupedSchedules: ExtendedScheduleRecord[] = [];

  SCHEDULE_WEEKDAYS.forEach(weekday => {
    // pick all the schedules for a specific day
    const weekdaySechdules = schedules.filter(
      schedule => schedule.weekdayName === weekday
    );

    // transform schedule data to calculate overlaps nad adjustments
    const splitJoinData: SplitJoinSlotData[] = weekdaySechdules.map(
      schedule => ({
        ids: [schedule.uniqueId],
        start: schedule.startTimeMinutes,
        end: schedule.startTimeMinutes + schedule.durationMinutes,
      })
    );

    // calcualate overlaps and schedule start and end time adjustments
    const adjustedData = splitJoinSlotsWithOverlaps(splitJoinData);

    const extendedScheduleData: ExtendedScheduleRecord[] = adjustedData.map(
      entry => {
        if (entry.ids.length === 1) {
          // slot has no overlap
          const scheduleEntry = weekdaySechdules.find(
            schedule => schedule.uniqueId === entry.ids[0]
          );

          if (scheduleEntry) {
            return scheduleEntry
              .set('startTimeMinutes', entry.start)
              .set('durationMinutes', entry.end - entry.start)
              .set(
                'startTime',
                secondsToHourFormat(entry.start * SECONDS_IN_A_MINUTE)
              );
          }
        } else {
          // overlap slot
          const scheduleEntry = weekdaySechdules.find(
            schedule => schedule.uniqueId === entry.ids[0]
          );

          if (scheduleEntry) {
            // get schedule.uid's from calculated unique ids
            const groupedScheduleUids = entry.ids.reduce((acc, id) => {
              const sourceSchedule = schedules.find(
                schedule => schedule.uniqueId === id
              );

              return [...acc, ...(sourceSchedule?.groupedScheduleUids ?? [])];
            }, [] as string[]);

            return scheduleEntry
              .set('routineUid', '') // a grouped slot does not have routine uid
              .set('label', 'grouped')
              .set('uid', entry.ids.join('&'))
              .set('startTimeMinutes', entry.start)
              .set('durationMinutes', entry.end - entry.start)
              .set(
                'startTime',
                secondsToHourFormat(entry.start * SECONDS_IN_A_MINUTE)
              )
              .set(
                'groupedScheduleUids',
                uniq([scheduleEntry.uid, ...groupedScheduleUids])
              );
          }
        }

        throw Error(
          'Error ocurred while calculating the calendar overlapping data'
        );
      }
    );

    groupedSchedules.push(...extendedScheduleData);
  });

  return groupedSchedules;
};

const convertSingleSlotIntoCalendarData = (
  routine: RoutineRecord,
  schedule: ExtendedScheduleRecord
): RoutinesCalendarViewData => {
  const {
    uid,
    label: scheduleLabel,
    startTime: time,
    durationMinutes: duration,
    weekdayName: weekday,
    isOverflown,
  } = schedule;

  const routineUids = [routine.uid];
  const description = [routine.name];
  const tooltipLight = routine?.color === RoutineColor.marine;
  const bgBolor = routine?.color;
  const label = !isOverflown ? scheduleLabel : undefined;

  return {
    uid,
    label,
    shortLabel: undefined,
    description,
    bgBolor,
    tooltipLight,
    time,
    duration,
    weekday,
    isGrouped: false,
    metadata: { routineUids },
  };
};

const convertGroupedSlotIntoCalendarData =
  (routinesList: List<RoutineRecord>, routineSchedules: List<ScheduleRecord>) =>
  (schedule: ExtendedScheduleRecord): RoutinesCalendarViewData => {
    const {
      uid,
      startTime: time,
      durationMinutes: duration,
      weekdayName: weekday,
    } = schedule;

    const routineUids: string[] = [];
    const description: string[] = [];

    const groupedSlotsCount = schedule.groupedScheduleUids.length;
    const label = t('+{{num}} routines', { num: groupedSlotsCount });
    const shortLabel = `+${groupedSlotsCount}`;

    // gather all the routine names of the grouped schedules uids
    schedule.groupedScheduleUids.forEach(suid => {
      const originalSchedule = routineSchedules.find(s => s.uid === suid);

      if (originalSchedule) {
        const refRoutine = routinesList.find(
          routine => routine.uid === originalSchedule.routineUid
        );

        if (refRoutine) {
          routineUids.push(refRoutine.uid);
          description.push(refRoutine.name);
        }
      }
    });

    return {
      uid,
      label,
      shortLabel,
      description: uniq(description),
      time,
      duration,
      weekday,
      isGrouped: true,
      tooltipLight: undefined,
      bgBolor: undefined,
      metadata: {
        routineUids: uniq(routineUids),
      },
    };
  };

/**
 * Converts a schedule to RoutinesCalendarViewData, providing information
 * for rendering the schedule in a calendar view, including details about linked routines,
 * labels, and metadata.
 *
 * @param routinesList - List of routines for reference.
 * @param routineSchedules - List of schedules associated with routines.
 * @param schedule - The schedule to be converted.
 * @returns An object containing data for rendering the schedule
 * in a calendar view, including labels, descriptions, time, duration, and metadata.
 */
export const convertExtendedScheduleToCalendarData =
  (routinesList: List<RoutineRecord>, routineSchedules: List<ScheduleRecord>) =>
  (schedule: ExtendedScheduleRecord): RoutinesCalendarViewData => {
    const isGroupedSlot = schedule.groupedScheduleUids.length > 1;

    if (isGroupedSlot) {
      return convertGroupedSlotIntoCalendarData(
        routinesList,
        routineSchedules
      )(schedule);
    }

    // Get the linked routine to the schedule
    const routine = routinesList.find(
      routine => routine.uid === schedule.routineUid
    );

    if (!routine)
      throw Error(
        `Could not find any routine associated to the the schedule: ${schedule.uid}`
      );

    return convertSingleSlotIntoCalendarData(routine, schedule);
  };

/**
 * Converts a list of routines and their associated schedules into an array of
 * RoutinesCalendarViewData for rendering in a calendar view. The conversion involves
 * calculating labels, handling overflows, grouping schedules, and formatting time.
 *
 * @param routinesList - List of routines to be converted.
 * @param routineSchedules - List of schedules associated with routines.
 * @param isMilitaryTime - Indicates whether the time should be displayed in military format.
 * @param timezone - Timezone to be considered in schedule calculations.
 * @returns An array of RoutinesCalendarViewData objects
 * containing data for rendering routines and their schedules in a calendar view.
 */
export const routinesToCalendarData = (
  routinesList: List<RoutineRecord>,
  routineSchedules: List<ScheduleRecord>,
  isMilitaryTime: boolean,
  timezone: string
): RoutinesCalendarViewData[] => {
  const transformSchedules = pipe(
    chain(separateSchedulesIntoWeekdays(routinesList, timezone)),
    map(calculateScheduleLabel(isMilitaryTime)),
    map(roundScheduleTo30MinSlots),
    chain(separateOverflownSchedules),
    calculateScheduleGroups,
    map(convertExtendedScheduleToCalendarData(routinesList, routineSchedules))
  );

  return transformSchedules(routineSchedules.toArray());
};
