import * as React from 'react';
import {
  Button,
  Modal,
  ModalButtonsAlignment,
  ButtonSize,
  FlexLayout,
  Icon,
  IconType,
  IconSize,
  IconFamily,
  IconColor,
  ModalStyledText,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';
import { modalDimensions } from '../../../constants';
import type { DeleteRoutineModalProps } from '../../../containers/Routines/Modal/DeleteRoutineModalContainer';

const DeleteRoutineModal = ({
  profileUid,
  routineUid,
  routineName,
  onClickDelete,
  onClickClose,
}: DeleteRoutineModalProps) => {
  const MODAL_TEST_ID = 'delete-routine-modal';

  const confirmDeleteRoutine = () => {
    if (profileUid && routineUid) {
      onClickDelete(profileUid, routineUid);
    }
  };

  return (
    <Modal
      testId={MODAL_TEST_ID}
      className="par-routines-delete-routine-modal"
      header={{
        icon: (
          <Icon
            type={IconType.trashAlt}
            size={IconSize.lg}
            family={IconFamily.regular}
            color={IconColor.error}
          />
        ),
      }}
      title={t('Delete routine?')}
      width={modalDimensions.regular.width}
      onClickClose={onClickClose}
      buttons={[
        <Button
          testId={`${MODAL_TEST_ID}-delete`}
          key="button1"
          onClick={confirmDeleteRoutine}
          size={ButtonSize.medium}
          disabled={false}
          block
        >
          {t('Delete')}
        </Button>,
        <Button
          testId={`${MODAL_TEST_ID}-cancel`}
          key="button2"
          onClick={onClickClose}
          size={ButtonSize.medium}
          disabled={false}
          buttonType={ButtonType.secondary}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
      buttonsAlignment={ModalButtonsAlignment.column}
    >
      <FlexLayout mainaxis="column" height="100%">
        <ModalStyledText>
          {t(
            'The rules and time slots you created for {1}{{routineName}}{/1} will be lost.',
            {
              1: str => <strong>{str}</strong>,
              routineName,
            }
          )}
        </ModalStyledText>
      </FlexLayout>
    </Modal>
  );
};
export default DeleteRoutineModal;
