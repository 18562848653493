import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { goBackIfHistory } from '../../../ducks/routing';
import SocialMonitoringRulesPremiumFlyover from '../../../components/SocialMonitoringRules/SocialMonitoringRulesPremiumFlyover';
import { gaEvent } from '../../../helpers/ga';
import { getProfileOrDefault } from '../../../selectors';

const mapStateToProps = (state, { params: { profileId } }) => ({
  profileName: getProfileOrDefault(state, profileId).get('name'),
});

const mapDispatchToProps = dispatch => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
    gaEvent('rules-list-social-monitoring-flyover-premium', 'click', 'close');
  },
  onClickUpgrade: () => {
    dispatch(push('/upgrade'));
    gaEvent('rules-list-social-monitoring-flyover-premium', 'click', 'upgrade');
  },
});

const SocialMonitoringRulesPremiumFlyoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMonitoringRulesPremiumFlyover);

export default SocialMonitoringRulesPremiumFlyoverContainer;
