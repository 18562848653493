import * as Moment from 'moment-timezone';
import {
  updateLastInteractionTs,
  UPDATE_LAST_INTERACTION_TS,
} from '../ducks/persistentStorage';
import { TICK } from '../ducks/times';
import { now } from '../lib/timeout';

let timeoutId: number | null = null;
const sessionMiddleware = store => next => action => {
  if (action.type === TICK || action.type === UPDATE_LAST_INTERACTION_TS) {
    return next(action);
  }

  if (timeoutId !== null) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(
    () => store.dispatch(updateLastInteractionTs(Moment(now()).utc().format())),
    1000
  );

  return next(action);
};

export default sessionMiddleware;
