import { connect } from 'react-redux';
import { goBackIfHistory } from '../../ducks/routing';
import {
  getLicense,
  canPurchase,
  filterProductsByPaymentProvider,
  getPaymentProvider,
  getSelectedProductList,
} from '../../selectors';
import {
  ButtonNames,
  GenericPageNames,
  trackButtonClicked,
} from '../../helpers/analytics';
import State, { Dispatch } from '../../store/state';
import {
  navigateToGeneralHelp,
  navigateToUpgrade,
} from '../../actions/Navigation';
import DeviceOverLimitModal from '../../components/Modal/DeviceOverLimitModal';
import { getLicenseSubtype } from '../../selectors/stateSelectors/license';

export type DeviceOverLimitModalActionType =
  | 'upgrade'
  | 'support'
  | 'ok'
  | null;

const mapStateToProps = (state: State) => {
  const productsAvailables = filterProductsByPaymentProvider(
    getSelectedProductList(state),
    getPaymentProvider(state)
  );

  const hasAvailableProducts = productsAvailables.count() > 0;

  return {
    license: getLicense(state),
    licenseSubtype: getLicenseSubtype(state),
    canPurchase: canPurchase(state),
    hasAvailableProducts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickClose: () => {
    dispatch(goBackIfHistory());
  },
  onClickFooterButton: (actionType: DeviceOverLimitModalActionType) => {
    if (actionType === 'upgrade') {
      trackButtonClicked(GenericPageNames.DevicesList, ButtonNames.Upgrade, {
        options: JSON.stringify({
          context: 'add_device',
          reason: 'overlimit_devices',
        }),
      });
      return dispatch(navigateToUpgrade());
    }

    if (actionType === 'support') {
      dispatch(goBackIfHistory());
      return dispatch(navigateToGeneralHelp());
    }

    // close
    return dispatch(goBackIfHistory());
  },
});
export type DeviceOverLimitModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const DeviceOverLimitModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceOverLimitModal);

export default DeviceOverLimitModalContainer;
