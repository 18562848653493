import State, { Dispatch } from '../../store/state';
import {
  getSummaryAlertCounter,
  requestResetSummaryAlertsCounterAction,
} from '../../ducks/summaryAlerts';
import { getProfileId, getProfileOrDefault } from '../../selectors';
import { SummaryAlertsTypes } from '../../ducks/summaryAlerts/types/SummaryAlertsState.type';

const getProfileUid = (state: State) => {
  const profileId = getProfileId(state);

  if (!profileId) return;

  const profileRecord = getProfileOrDefault(state, profileId);
  return profileRecord.uid;
};

export const ensureGetSummaryAlertCounter = (
  state: State,
  profileUuid?: string
) => {
  return profileUuid
    ? getSummaryAlertCounter(state, profileUuid, 'searches')
    : 0;
};

const resetAlertsCounterThunk =
  (type: SummaryAlertsTypes) => (dispatch: Dispatch, getState: () => State) => {
    const profileUid = getProfileUid(getState());
    if (!profileUid) return;
    dispatch(requestResetSummaryAlertsCounterAction(profileUid, type));
  };

export const summaryAlertsMapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCloseSummaryAlerts: () => dispatch(resetAlertsCounterThunk('searches')),
    onReviewSummaryAlerts: () => {
      // eslint-disable-next-line no-console
      console.log(
        '@todo: pending implementation go to timeline alert filters.'
      );
    },
  };
};

export const summaryAlertsMapStateToProps = (state: State) => {
  const profileUid = getProfileUid(state);
  const alertsCount = ensureGetSummaryAlertCounter(state, profileUid);

  return {
    alertsCount,
  };
};
