import * as React from 'react';
import {
  Avatar,
  AvatarSize,
  FlexLayout,
  IconColor,
  IconType,
  Label,
  List,
  RegularStyleListItem,
  Icon as CustomIcon,
  TransparentButton,
  Layout,
} from 'styleguide-react';
import { OrderedSet } from 'immutable';
import { t } from '../../lib/i18n';
import { PLATFORM_MAC, PLATFORM_WINDOWS } from '../../constants';
import ProfilesForDeviceDropdown from './ProfilesForDeviceDropdown';
import DateIncludingHours from '../Date/DateIncludingHours';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { ProfileRecord } from '../../records/profile/types/Profile.types';
import { DeviceRecord } from '../../records/device/types/Device.types';

type DeviceAssignedToProps = {
  device: DeviceRecord | undefined;
  profile: ProfileRecord | undefined;
  profiles: OrderedSet<ProfileRecord>;
  testId: string;
  onClickAssignProfileFromDropdown: () => any;
  onClickOpenDropdown: () => any;
};

const DeviceAssignedTo: React.FunctionComponent<DeviceAssignedToProps> = ({
  device,
  profile,
  profiles,
  testId,
  onClickAssignProfileFromDropdown,
  onClickOpenDropdown,
}: DeviceAssignedToProps) => {
  if (profile) {
    return (
      <ProfilesForDeviceDropdown
        actionElement={
          <TransparentButton fillAvailableSpace onClick={() => {}}>
            <List>
              <RegularStyleListItem
                title={profile.name}
                upperSubtitle={
                  <Layout textAlignement="left">
                    <span>{t('Last Activity:')}</span>{' '}
                    {profile.lastseen ? (
                      <DateIncludingHours time={profile.lastseen} />
                    ) : (
                      t('Information not available')
                    )}
                  </Layout>
                }
                icon={
                  <ProfileAvatar
                    size={AvatarSize.extraExtraSmall}
                    profile={profile}
                  />
                }
                actionElement={
                  <CustomIcon
                    type={IconType.angleDown}
                    color={IconColor.primary}
                  />
                }
                testId="assignedTo"
              />
            </List>
          </TransparentButton>
        }
        profiles={profiles}
        deviceUserId={device?.users.first().uid}
        testId={testId}
        onClickOpenDropdown={onClickOpenDropdown}
        onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
      />
    );
  }
  return (
    <React.Fragment>
      <ProfilesForDeviceDropdown
        actionElement={
          <TransparentButton fillAvailableSpace onClick={() => {}}>
            <FlexLayout
              mainaxis="row"
              mainaxisAlignment="space-between"
              flexGrow="1"
              matchParentHeight
            >
              <FlexLayout mainaxis="row" crossaxisAlignment="center">
                <FlexLayout mainaxis="row" marginRight="8">
                  <Avatar size={AvatarSize.extraExtraSmall} />
                </FlexLayout>
                <FlexLayout mainaxis="column">
                  {device && (
                    <Label>{getAssignedToText(device.platform)}</Label>
                  )}
                </FlexLayout>
              </FlexLayout>
              <FlexLayout mainaxis="row">
                <CustomIcon
                  type={IconType.angleDown}
                  color={IconColor.primary}
                />
              </FlexLayout>
            </FlexLayout>
          </TransparentButton>
        }
        profiles={profiles}
        deviceUserId={device?.users.first().uid}
        testId={testId}
        onClickOpenDropdown={onClickOpenDropdown}
        onClickAssignProfileFromDropdown={onClickAssignProfileFromDropdown}
      />
    </React.Fragment>
  );
};

const getAssignedToText = (platform: string) => {
  switch (platform) {
    case PLATFORM_MAC:
      return t('Assign all Mac accounts');
    case PLATFORM_WINDOWS:
      return t('Assign all Windows accounts');
    default:
      return t('Assign profile for this device');
  }
};

export default DeviceAssignedTo;
