import { List } from 'immutable';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { DeviceRecord } from '../records/device/types/Device.types';

export const profileHasVirtualDevice = (profile: ProfileRecord): boolean => {
  // TODO once we have delegation in place, we should check if has a delegated device
  return profile.isLinewizestudentLinked;
};

export const totalDevicesInProfileWithVirtualDevices = (
  profile: ProfileRecord,
  profileDevices: List<DeviceRecord>
): number => {
  return profileHasVirtualDevice(profile)
    ? profileDevices.size + 1
    : profileDevices.size;
};
