import * as React from 'react';
import { t } from '../../lib/i18n';
import { getAllLanguages, getLanguage } from '../../helpers/languages';
import { ISOLanguage } from '../../constants';
import {
  ActionInput,
  ActionInputIconPosition,
  Button,
  ButtonSize,
  Dropdown,
  DropdownMenuPlacement,
  DropdownOption,
  FlexLayout,
  Icon,
  IconColor,
  IconType,
  Label,
} from 'styleguide-react';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';

const EditLanguage: React.FunctionComponent<{
  locale: ISOLanguage;
  onClickSaveButton: (language: ISOLanguage) => any;
}> = ({ locale, onClickSaveButton }) => {
  const [selectedLocale, setSelectedLocale] = React.useState(locale);

  return (
    <PageLayout
      title={t('Edit Language')}
      actions={[{ actionName: 'back' }]}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          columns: 1,
          mainInsideCard: true,
        },
        {
          screenSize: ScreenSize.Tablet,
          columns: 1,
          mainInsideCard: true,
        },
      ]}
    >
      <EditLanguageDropdown
        maxHeight={145}
        locale={selectedLocale}
        setSelectedLocale={setSelectedLocale}
        onClickSaveButton={onClickSaveButton}
      />
    </PageLayout>
  );
};

const EditLanguageDropdown: React.FunctionComponent<{
  maxHeight: number;
  locale: ISOLanguage;
  setSelectedLocale: (language: ISOLanguage) => any;
  onClickSaveButton: (language: ISOLanguage) => any;
}> = ({ maxHeight, locale, setSelectedLocale, onClickSaveButton }) => (
  <FlexLayout
    mainaxis="column"
    mainaxisAlignment="space-between"
    flexGrow="2"
    matchParentHeight
  >
    <FlexLayout mainaxis="column">
      <FlexLayout mainaxis="column" marginBottom="8">
        <Label>{t('Edit Language')}</Label>
      </FlexLayout>
      <Dropdown
        actionElement={
          <ActionInput
            type="text"
            text={getLanguage(locale)}
            readOnly
            testId="editLanguage"
            block
            icon={<Icon type={IconType.angleDown} color={IconColor.primary} />}
            iconPosition={ActionInputIconPosition.right}
          />
        }
        maxHeight={maxHeight}
        onChange={setSelectedLocale}
        testId="editLanguage"
        menuPlacement={DropdownMenuPlacement.bottom}
        fixedMenuPlacement
      >
        {getAllLanguages().map(language => (
          <DropdownOption
            text={language[1]}
            value={language[0]}
            selected={language[0] === locale}
            key={language[0]}
          />
        ))}
      </Dropdown>
    </FlexLayout>
    <FlexLayout mainaxis="row" mainaxisAlignment="center">
      <Button
        centered
        size={ButtonSize.medium}
        htmlType="submit"
        onClick={() => onClickSaveButton(locale)}
        testId="saveEditLanguage"
      >
        {t('Save')}
      </Button>
    </FlexLayout>
  </FlexLayout>
);

export default EditLanguage;
