import * as React from 'react';
import { connect } from 'react-redux';
import UnifiedNowCardContainer from './UnifiedNowCardContainer';

const mapStateToProps = (_state, { params }) => {
  const profileId = Number(params.profileId);

  return {
    profileId,
  };
};

const PageContent = ({ profileId }) => {
  return (
    <div>
      <p>This is a dev page</p>
      <UnifiedNowCardContainer profileId={profileId} />
    </div>
  );
};

const UnifiedNowCardDevPage = connect(mapStateToProps)(PageContent);

export default UnifiedNowCardDevPage;
