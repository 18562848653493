import * as React from 'react';
import { t } from '../../lib/i18n';
import {
  Modal,
  Button,
  ButtonSize,
  FlexLayout,
  Layout,
  Typography,
} from 'styleguide-react';
import type { SetupAlertsModalProps } from '../../containers/Modal/SetupAlertsModalContainer';
import RocketLaunchIllustration from '../../assets/base/images/v3/flyovers/illustration_rocket_launch_qustodio_icon.svg';

const SetupAlertsModal = ({
  canPurchase,
  referrer,
  onClickUpgradeNow,
  onClickClose,
}: SetupAlertsModalProps) => (
  <Modal
    size="medium"
    animationEnabled
    onClickClose={onClickClose}
    buttons={[
      <Button
        block
        key="button"
        size={ButtonSize.medium}
        onClick={() => onClickUpgradeNow(canPurchase, referrer)}
      >
        {canPurchase ? t('Upgrade now') : t('OK')}
      </Button>,
    ]}
  >
    <FlexLayout height="100%" mainaxis="column" mainaxisAlignment="center">
      <Layout textAlignement="center" minHeight="240">
        <img
          src={RocketLaunchIllustration}
          width="280px"
          alt="rocket launch illustration"
        />
      </Layout>
      <Typography type="h5" weight="semibold">
        {t('Set up alerts')}
      </Typography>
      <FlexLayout mainaxis="column" crossaxisAlignment="center">
        <Layout maxWidth="360">
          <Typography type="body1" marginBottom="16">
            {t(
              'Receive alerts when your child tries to access a website you’ve blocked or searches for something you should know about.'
            )}
          </Typography>
          {!canPurchase ? (
            <Typography type="body1">
              {t('Contact our support team to upgrade and set up alerts.')}
            </Typography>
          ) : null}
        </Layout>
      </FlexLayout>
    </FlexLayout>
  </Modal>
);

export default SetupAlertsModal;
