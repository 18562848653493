import * as React from 'react';
import { Link } from 'react-router';
import { OrderedMap, List } from 'immutable';
import {
  Tabs,
  TabPanel,
  Button,
  ButtonSize,
  FlexLayout,
  Layout,
  DropdownElementOrientation,
  Card,
  GlobalType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import {
  WEBFILTER_CATEGORIES_SECTION,
  WEBFILTER_EXCEPTIONS_SECTION,
  SettingsToggleNames,
  EDIT_CATEGORY_ALL,
} from '../../constants';
import { categoryLabels } from '../../records/category';
import { WebCategoryRecord } from '../../records/profileRules';
import { formatActionsToOptions } from '../DropDown/DropDownActionSheet';
import { getWebFilterCategoryActions } from '../DropDown/DropDownActions';
import DropDown from '../DropDown/DropDown';
import WebFiltersSettings from './WebFiltersSettings';
import WebFiltersExceptions from './WebFiltersExceptions';
import WebFiltersCategories from './WebFiltersCategories';
import PageLayout from '../Layout/PageLayout';
import { ScreenSize } from '../RenderWhen/RenderWhen';
import PageSectionLayout from '../Layout/PageSectionLayout';
import WebFiltersNotifications from './WebFiltersNotifications';
import { classNames } from '../../helpers';
import DesktopLayoutScssUtils from '../Layout/desktop/DesktopLayoutScssUtils';
import TabletLayoutScssUtils from '../Layout/tablet/TabletLayoutScssUtils';
import WebFilterAlertsTitle from './WebFilterAlertsTitle';
import { FeatureCode } from '../../records/features/types';

const bulkCategoryActions = (onClickReset: () => void, onClick: () => void) =>
  getWebFilterCategoryActions(onClick).concat([
    [
      onClickReset,
      undefined,
      t('Restore default values'),
      'Restore-default-values',
    ],
  ]);

interface WebsiteFilteringProps {
  profileId: string;
  activeSection: any;
  categoryRestrictionsEnabled: boolean;
  categories: List<WebCategoryRecord>;
  domains: OrderedMap<string, string>;
  editDomainException: string;
  editCategory: any;
  isAllowUnknownSites: boolean;
  isSafeSearch: boolean;
  isReportBlockedSites: boolean;
  isReportBlockedSummaryAlerts: boolean;
  isBlockedUnsupportedBrowsers: boolean;
  isEnabledBlockUnsupportedBrowsers: boolean;
  onClickCategory: (category: any) => any;
  onClickBulkChange: () => any;
  onClickDomainException: (domain?: any) => any;
  onEditCategories: (categories: any) => any;
  onResetCategories: () => any;
  onToggleWebFilterEnabled: (toggleOn: boolean) => any;
  setActiveSection: (v1: any) => any;
  onToggleWebFilterSetting: (
    target: SettingsToggleNames,
    toggleOn: boolean,
    needLicenseUpgrade?: boolean
  ) => any;
  onClickSettings: () => void;
  ruleNeedsUpgrade: (code: FeatureCode) => boolean;
  shouldHideRule: (code: FeatureCode) => boolean;
}

const WebsiteFiltering = ({
  profileId,
  activeSection,
  categoryRestrictionsEnabled,
  categories,
  domains,
  editCategory,
  editDomainException,
  isAllowUnknownSites,
  isSafeSearch,
  isReportBlockedSites,
  isReportBlockedSummaryAlerts,
  isBlockedUnsupportedBrowsers,
  isEnabledBlockUnsupportedBrowsers,
  onEditCategories,
  onResetCategories,
  onClickCategory,
  onClickBulkChange,
  onClickDomainException,
  onToggleWebFilterEnabled,
  setActiveSection,
  onToggleWebFilterSetting,
  onClickSettings,
  ruleNeedsUpgrade,
  shouldHideRule,
}: WebsiteFilteringProps) => {
  const handleTabClick = name => {
    setActiveSection(name);
  };

  const renderContent = () => (
    <FlexLayout
      mainaxis="column"
      mainaxisAlignment="space-between"
      matchParentHeight
    >
      <Tabs
        onChange={handleTabClick}
        animateOnChange={false}
        testId="web-filtering-tabs"
      >
        <TabPanel
          name={WEBFILTER_CATEGORIES_SECTION}
          content={t('Categories')}
          testId={`tabs-header-${WEBFILTER_CATEGORIES_SECTION}`}
          active={activeSection === WEBFILTER_CATEGORIES_SECTION}
        >
          <WebFiltersCategories
            profileId={profileId}
            categories={categories}
            categoryRestrictionsEnabled={categoryRestrictionsEnabled}
            onToggleWebFilterEnabled={onToggleWebFilterEnabled}
            onClickCategory={onClickCategory}
          />
        </TabPanel>

        <TabPanel
          name={WEBFILTER_EXCEPTIONS_SECTION}
          content={t('Exceptions')}
          testId={`tabs-header-${WEBFILTER_EXCEPTIONS_SECTION}`}
          active={activeSection === WEBFILTER_EXCEPTIONS_SECTION}
        >
          <WebFiltersExceptions
            domains={domains}
            profileId={profileId}
            editDomainException={editDomainException}
            onClickDomainException={onClickDomainException}
          />
        </TabPanel>
      </Tabs>

      {activeSection === WEBFILTER_CATEGORIES_SECTION && (
        <Layout paddingBottom="40">
          <FlexLayout mainaxis="row" mainaxisAlignment="center">
            <DropDown
              disabled={!categoryRestrictionsEnabled}
              active={editCategory === EDIT_CATEGORY_ALL}
              options={formatActionsToOptions(
                bulkCategoryActions(
                  onResetCategories,
                  onEditCategories(categoryLabels().map(item => item.category))
                )
              )}
              onClose={() => onClickCategory(null)}
              menuOrietation={DropdownElementOrientation.center}
              minWidth={400}
              actionElement={
                <Button
                  centered
                  size={ButtonSize.medium}
                  testId="categories-switch-change-all"
                  onClick={onClickBulkChange}
                  disabled={!categoryRestrictionsEnabled}
                >
                  {t('Change all')}
                </Button>
              }
            />
          </FlexLayout>
        </Layout>
      )}

      {activeSection === WEBFILTER_EXCEPTIONS_SECTION && (
        <Layout paddingBottom="40">
          <FlexLayout
            mainaxis="row"
            mainaxisAlignment="center"
            testId="add-exception-wrapper"
          >
            <Link
              to={`/profiles/${profileId}/rules/webFilters/addDomainException`}
            >
              <Button
                size={ButtonSize.medium}
                testId="exceptions-add-exception-button"
              >
                {t('Add Exception')}
              </Button>
            </Link>
          </FlexLayout>
        </Layout>
      )}
    </FlexLayout>
  );

  return (
    <PageLayout
      title={t('Web filtering')}
      configuration={[
        {
          screenSize: ScreenSize.Desktop,
          mainInsideCard: true,
          columns: 2,
        },
        {
          screenSize: ScreenSize.Tablet,
          mainInsideCard: true,
          columns: 2,
        },
      ]}
      actions={[
        { actionName: 'back', screenSize: ScreenSize.MobileOrTablet },
        {
          actionName: 'settings',
          onClick: onClickSettings,
          screenSize: ScreenSize.Mobile,
        },
      ]}
    >
      <PageSectionLayout side="main">{renderContent()}</PageSectionLayout>
      <PageSectionLayout side="right">
        <FlexLayout mainaxis="column">
          <Layout marginBottom="32">
            <Card
              type={GlobalType.white}
              header={{
                title: t('Settings'),
                border: true,
              }}
              className={classNames(
                DesktopLayoutScssUtils.CardRight,
                TabletLayoutScssUtils.CardRight
              )}
            >
              <WebFiltersSettings
                isAllowUnknownSites={isAllowUnknownSites}
                isSafeSearch={isSafeSearch}
                isBlockedUnsupportedBrowsers={isBlockedUnsupportedBrowsers}
                isEnabledBlockUnsupportedBrowsers={
                  isEnabledBlockUnsupportedBrowsers
                }
                onToggleWebFilterSetting={onToggleWebFilterSetting}
              />
            </Card>
          </Layout>
          <Layout marginBottom="32">
            <Card
              type={GlobalType.white}
              header={{
                title: (
                  <WebFilterAlertsTitle
                    size="small"
                    upgrade={
                      ruleNeedsUpgrade('threat_alerts_search') ||
                      ruleNeedsUpgrade('web_blocked_alert')
                    }
                  />
                ),
                border: true,
              }}
              className={classNames(
                DesktopLayoutScssUtils.CardRight,
                TabletLayoutScssUtils.CardRight
              )}
            >
              <WebFiltersNotifications
                isReportBlockedSites={isReportBlockedSites}
                isReportBlockedSummaryAlerts={isReportBlockedSummaryAlerts}
                ruleNeedsUpgrade={ruleNeedsUpgrade}
                shouldHideRule={shouldHideRule}
                onToggleWebFilterSetting={onToggleWebFilterSetting}
              />
            </Card>
          </Layout>
        </FlexLayout>
      </PageSectionLayout>
    </PageLayout>
  );
};

export default WebsiteFiltering;
