import * as appOnboardingDuck from 'immutable';
import { ProfileRecord } from '../records/profile/types/Profile.types';
import { Platform, DevicePlatform, DeviceType } from '../constants/index';

export const ADD_ONBOARDING_PROFILE = 'ADD_ONBOARDING_PROFILE';
export const RECEIVE_ONBOARDING = 'RECEIVE_ONBOARDING';
export const RECEIVE_ONBOARDING_TOKEN = 'RECEIVE_ONBOARDING TOKEN';
export const ADD_ONBOARDING_DEVICE_TYPE = 'ADD_ONBOARDING_DEVICE_TYPE';
export const ADD_ONBOARDING_DEVICE_PLATFORM = 'ADD_ONBOARDING_DEVICE_PLATFORM';
export const ADD_ONBOARDING_SOCIAL_SHARE_TYPE =
  'ADD_ONBOARDING_SOCIAL_SHARE_TYPE';
export const SAVE_IS_ONBOARDING_CURRENT_DEVICE =
  'SAVE_IS_ONBOARDING_CURRENT_DEVICE';
export const UPDATE_ONBOARDING_PENDING_DEVICE_CONNECTION =
  'UPDATE_ONBOARDING_PENDING_DEVICE_CONNECTION';
export const SET_IS_VERIFYING_DEVICE = 'SET_IS_VERIFYING_DEVICE';

export type OnboardingDeviceType =
  | 'ONBOARDING_DEVICE_TYPE_MOBILE'
  | 'ONBOARDING_DEVICE_TYPE_DESKTOP'
  | 'ONBOARDING_DEVICE_TYPE_TABLET';
export const ONBOARDING_DEVICE_TYPE_MOBILE: OnboardingDeviceType =
  'ONBOARDING_DEVICE_TYPE_MOBILE';
export const ONBOARDING_DEVICE_TYPE_TABLET: OnboardingDeviceType =
  'ONBOARDING_DEVICE_TYPE_TABLET';
export const ONBOARDING_DEVICE_TYPE_DESKTOP: OnboardingDeviceType =
  'ONBOARDING_DEVICE_TYPE_DESKTOP';

export type OnboardingSocialShareType =
  | 'ONBOARDING_SOCIAL_SHARE_TYPE_EMAIL'
  | 'ONBOARDING_SOCIAL_SHARE_TYPE_COPY';
export const ONBOARDING_SOCIAL_SHARE_TYPE_EMAIL: OnboardingSocialShareType =
  'ONBOARDING_SOCIAL_SHARE_TYPE_EMAIL';
export const ONBOARDING_SOCIAL_SHARE_TYPE_COPY: OnboardingSocialShareType =
  'ONBOARDING_SOCIAL_SHARE_TYPE_COPY';

export type OnboardingCurrentDeviceAnswer =
  | 'ONBOARDING_CURRENT_DEVICE_ANSWER_YES'
  | 'ONBOARDING_CURRENT_DEVICE_ANSWER_NO'
  | 'ONBOARDING_CURRENT_DEVICE_ANSWER_UNKNOWN';
export const ONBOARDING_CURRENT_DEVICE_ANSWER_YES: OnboardingCurrentDeviceAnswer =
  'ONBOARDING_CURRENT_DEVICE_ANSWER_YES';
export const ONBOARDING_CURRENT_DEVICE_ANSWER_NO: OnboardingCurrentDeviceAnswer =
  'ONBOARDING_CURRENT_DEVICE_ANSWER_NO';
export const ONBOARDING_CURRENT_DEVICE_ANSWER_UNKNOWN: OnboardingCurrentDeviceAnswer =
  'ONBOARDING_CURRENT_DEVICE_ANSWER_UNKNOWN';

export const SET_LOADING_PROFILES = 'LOADING_PROFILES';

export const REMOTE_ONBOARDING_TOKEN_FETCH_STARTED =
  'REMOTE_ONBOARDING_TOKEN_FETCH_STARTED';
export const REMOTE_ONBOARDING_TOKEN_FETCH_FINISHED =
  'REMOTE_ONBOARDING_TOKEN_FETCH_FINISHED';
export const REMOTE_ONBOARDING_TOKEN_EXPIRED =
  'REMOTE_ONBOARDING_TOKEN_EXPIRED';
export const REMOTE_ONBOARDING_TOKEN_REFRESHED =
  'REMOTE_ONBOARDING_TOKEN_REFRESHED';
export const ONBOARDING_ACCOUNT_POLLING_STARTED =
  'ONBOARDING_ACCOUNT_POLLING_STARTED';

export default function onboarding(
  state = appOnboardingDuck.fromJS({
    profile: undefined,
    deviceType: null,
    devicePlatform: null,
    socialShareType: null,
    isCurrentDevice: ONBOARDING_CURRENT_DEVICE_ANSWER_UNKNOWN,
    pendingDeviceConnection: false,
    isVerifyingDevice: false,
    isLoadingProfiles: false,
    onboardingTokenData: null,
    isFetchingRemoteOnboardingToken: false,
    isOnboardingTokenExpired: false,
  }),
  action
) {
  switch (action.type) {
    case ADD_ONBOARDING_PROFILE:
      return state.set('profile', action.payload.profile);
    case RECEIVE_ONBOARDING:
      return state.merge(action.payload);
    case RECEIVE_ONBOARDING_TOKEN:
      return state.set(
        'onboardingTokenData',
        action.payload.onboardingTokenData
      );
    case ADD_ONBOARDING_DEVICE_TYPE:
      return state.set('deviceType', action.payload.onboardingDeviceType);
    case ADD_ONBOARDING_DEVICE_PLATFORM:
      return state.set(
        'devicePlatform',
        action.payload.onboardingDevicePlatform
      );
    case ADD_ONBOARDING_SOCIAL_SHARE_TYPE:
      return state.set(
        'socialShareType',
        action.payload.onboardingSocialShareType
      );
    case SAVE_IS_ONBOARDING_CURRENT_DEVICE:
      return state.set('isCurrentDevice', action.payload.isCurrentDevice);
    case UPDATE_ONBOARDING_PENDING_DEVICE_CONNECTION:
      return state.set(
        'pendingDeviceConnection',
        action.payload.pendingDeviceConnection
      );
    case SET_IS_VERIFYING_DEVICE:
      return state.set('isVerifyingDevice', action.payload.isVerifyingDevice);
    case SET_LOADING_PROFILES:
      return state.set('isLoadingProfiles', action.payload);
    case REMOTE_ONBOARDING_TOKEN_FETCH_STARTED:
      return state.set('isFetchingRemoteOnboardingToken', true);
    case REMOTE_ONBOARDING_TOKEN_FETCH_FINISHED:
      return state.set('isFetchingRemoteOnboardingToken', false);
    case REMOTE_ONBOARDING_TOKEN_EXPIRED:
      return state.set('isOnboardingTokenExpired', true);
    case REMOTE_ONBOARDING_TOKEN_REFRESHED:
      return state.set('isOnboardingTokenExpired', false);
    case ONBOARDING_ACCOUNT_POLLING_STARTED:
      return state.set('isPollingAccountData', true);
    default:
      return state;
  }
}

export function addProfile(profile: ProfileRecord) {
  return {
    type: ADD_ONBOARDING_PROFILE,
    payload: {
      profile,
    },
  };
}

export function addOnboardingDeviceType(
  onboardingDeviceType: OnboardingDeviceType | DeviceType | null
) {
  return {
    type: ADD_ONBOARDING_DEVICE_TYPE,
    payload: {
      onboardingDeviceType,
    },
  };
}

export function addOnboardingDevicePlatform(
  onboardingDevicePlatform: DevicePlatform | Platform
) {
  return {
    type: ADD_ONBOARDING_DEVICE_PLATFORM,
    payload: {
      onboardingDevicePlatform,
    },
  };
}

export function addOnboardingSocialShareType(
  onboardingSocialShareType: OnboardingSocialShareType | null
) {
  return {
    type: ADD_ONBOARDING_SOCIAL_SHARE_TYPE,
    payload: {
      onboardingSocialShareType,
    },
  };
}

export function saveIsOnboardingCurrentDevice(
  isCurrentDevice: OnboardingCurrentDeviceAnswer
) {
  return {
    type: SAVE_IS_ONBOARDING_CURRENT_DEVICE,
    payload: {
      isCurrentDevice,
    },
  };
}

export function updatePendingDeviceConnection(
  pendingDeviceConnection: boolean | null
) {
  return {
    type: UPDATE_ONBOARDING_PENDING_DEVICE_CONNECTION,
    payload: {
      pendingDeviceConnection,
    },
  };
}

export function receiveOnboarding(payload: { profile: ProfileRecord }) {
  return {
    type: RECEIVE_ONBOARDING,
    payload,
  };
}

export function setIsVerifyingDevice(isVerifyingDevice: boolean) {
  return {
    type: SET_IS_VERIFYING_DEVICE,
    payload: {
      isVerifyingDevice,
    },
  };
}

export const setLoadingProfiles = (loading: boolean) => ({
  type: SET_LOADING_PROFILES,
  payload: loading,
});

export const remoteOnboardingTokenFetchStarted = () => ({
  type: REMOTE_ONBOARDING_TOKEN_FETCH_STARTED,
});

export const remoteOnboardingTokenFetchFinished = () => ({
  type: REMOTE_ONBOARDING_TOKEN_FETCH_FINISHED,
});

export const remoteOnboardingTokenRefreshed = () => ({
  type: REMOTE_ONBOARDING_TOKEN_REFRESHED,
});

export const remoteOnboardingTokenExpired = () => ({
  type: REMOTE_ONBOARDING_TOKEN_EXPIRED,
});

export const onboardingAccountPollingStarted = () => ({
  type: ONBOARDING_ACCOUNT_POLLING_STARTED,
});
