import { connect } from 'react-redux';
import PauseTheInternetModal from '../../../components/Modal/PauseTheInternetModal';
import { goBackIfHistory } from '../../../ducks/routing';
import { getQueryParam, getTimezone } from '../../../selectors';
import { getAccountLocale } from '../../../selectors/account';
import State, { Dispatch, RouterParamsProps } from '../../../store/state';
import { hasPauseInternetOverlap } from '../../../selectors/entitySelectors/pauseInternetOverlap';
import { getCalendarRestrictionPauseEnd } from '../../../selectors/stateSelectors/calendarRestrictionsPause';
import { durationInMinutes, localNow } from '../../../helpers/dates';
import {
  PauseTheInternetModalDispatchProps,
  PauseTheInternetModalProps,
  PauseTheInternetModalStateProps,
} from '../../../components/Modal/types/PauseTheInternetModal.types';
import { getFeatureFlag } from '../../../selectors/featureFlags';
import {
  createCalendarRestrictionPause,
  editCalendarRestrictionPause,
  unPauseInternet,
} from './thunks';

const mapStateToProps = (
  state: State,
  { params: { profileId }, ...props }: RouterParamsProps
): PauseTheInternetModalStateProps => {
  const timezone = getTimezone(state);
  const currentTime = localNow(timezone);
  const pausedUntilTime = getCalendarRestrictionPauseEnd(state);
  const pausedUntilTimeMinutes =
    pausedUntilTime && currentTime.isBefore(pausedUntilTime)
      ? durationInMinutes(pausedUntilTime, currentTime.clone())
      : 0;

  const familyPauseButtonFeatureFlag = getFeatureFlag(
    state,
    'familyPauseButton'
  ) as boolean;

  const type = getQueryParam(
    state,
    'action'
  ) as PauseTheInternetModalProps['type'];

  return {
    type,
    pausedUntilTimeMinutes,
    timezone,
    locale: getAccountLocale(state) as string,
    getCurrentTime: localNow,
    familyPauseButtonFeatureFlag,
    isOverlapped: hasPauseInternetOverlap(state, parseInt(profileId, 10)),
    ...props,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { params: { profileId } }: RouterParamsProps
): PauseTheInternetModalDispatchProps => {
  return {
    onClickClose: () => {
      dispatch(goBackIfHistory());
    },
    onSetPauseDuration: (actionType, minutes) => {
      if (minutes === 0) {
        dispatch(unPauseInternet(parseInt(profileId, 10)));
      } else if (actionType === 'edit') {
        dispatch(editCalendarRestrictionPause(minutes, profileId));
      } else {
        dispatch(createCalendarRestrictionPause(minutes, profileId));
      }
      dispatch(goBackIfHistory());
    },
  };
};

const PauseTheInternetModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PauseTheInternetModal);

export default PauseTheInternetModalContainer;
