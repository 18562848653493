import { connect } from 'react-redux';
import {
  showInAppRatingPopupForCurrentScenario,
  shouldShowIosPreConsentTrackingModal,
  showIosPreConsentTrackingModalForCurrentScenario,
} from '../../ducks/scenarios/scenarios';
import State, {
  Dispatch,
  BaseThunk,
  RouterParamsProps,
  RouterParams,
} from '../../store/state';
import ProfileRules from '../../components/ProfileRules/ProfileRules';
import { List } from 'immutable';
import {
  profileRulesList,
  ProfileRulesListType,
} from '../../constants/profileRules';
import { flagFilterProfileRules } from '../../businessLogic/profileRules/flagFilterRules';
import { clickRule } from '../../actions/ProfileRulesActions';
import { getLicense } from '../../selectors/license';
import { getLicenseDaysLeft } from '../../records/license';
import { redirectToAddDeviceIfNoDeviceAssignedTo } from '../../actions/ProfileActions';
import {
  getAccountCurrentTime,
  getProfile,
  getProfileAndroidDevices,
  getProfileDevices,
  getProfileIOSDevices,
} from '../../selectors';
import flags from '../../sideEffects/flags';
import {
  getIsFetchingStudentPolicy,
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../../selectors/studentPolicies';
import { fetchStudentPolicies } from '../../ducks/studentPolicies';
import {
  getAccountOptionsDelegationRulesModalDismissed,
  getAdditionalParentAccountOptionsDelegationRulesModalDismissed,
  getTimezone,
  isAccountRoutinesFlagEnabled,
  isSlaveAccount,
} from '../../selectors/stateSelectors/account';
import {
  getIsSchoolAndFamilyProfile,
  isCombinedDashboardProfile,
} from '../Dashboard/helpers/profileDashboardHelpers';
import {
  hasProfileBeenDelegated,
  isProfileDelegatedToThisAccount,
  isValidProfileForDelegation,
} from '../../businessLogic/delegation/delegation';
import {
  navigateToDelegationEnabledModal,
  navigateToDelegationOptOutModal,
  navigateToDelegationRuleInfoModal,
} from '../../actions/Navigation';
import { getProfileRulesLabel } from '../../components/ProfileRules/ProfileRulesLabel/ProfileRulesLabel';
import { enabledDelegation } from '../../ducks/profileRules/delegation/thunk';
import { isSchoolLicenseWithValidDevices } from '../../businessLogic/profileRules/delegation';
import { getEligibleForManualTrial } from '../../selectors/stateSelectors/license';
import { isSchoolAccount, isSchoolReferral } from '../../businessLogic/account';

const prepareRulesList = (rulesList: ProfileRulesListType[], state: State) => {
  const license = getLicense(state);
  const filteredRules = flagFilterProfileRules(
    rulesList,
    isAccountRoutinesFlagEnabled(state),
    license.subtype
  );
  return filteredRules;
};

const mapStateToProps = (
  state: State,
  { params: { profileId } }: RouterParamsProps
) => {
  const _profileId = Number(profileId);
  const isLinkedWithSchool = Boolean(
    getProfile(state, profileId)?.isLinewizestudentLinked
  );
  const delegationEnabled = isDelegationEnabled(state);
  const studentOptIn = isStudentOptInForDelegation(state);
  const studentOptInForThisAccount =
    isStudentOptInForDelegationForThisAccount(state);
  const isCombinedProfile = isCombinedDashboardProfile(state, _profileId);
  const isFamilyAndSchoolProfile = getIsSchoolAndFamilyProfile(
    state,
    _profileId
  );

  const isProfileValidForDelegation = isValidProfileForDelegation(
    delegationEnabled,
    isLinkedWithSchool
  );
  const isProfileDelegated = hasProfileBeenDelegated(
    delegationEnabled,
    studentOptIn,
    isLinkedWithSchool
  );
  const isProfileDelegatedToTheAccount = isProfileDelegatedToThisAccount(
    delegationEnabled,
    studentOptInForThisAccount,
    isLinkedWithSchool
  );
  const shouldShowDelegation =
    isProfileValidForDelegation &&
    (!isProfileDelegated || isProfileDelegatedToTheAccount);

  const shouldRulesBeEnabled =
    !isLinkedWithSchool ||
    isProfileDelegatedToTheAccount ||
    isFamilyAndSchoolProfile;

  const shouldShowTheDelegationAlertBox =
    isProfileValidForDelegation &&
    !isProfileDelegated &&
    !isFamilyAndSchoolProfile;

  const isFetchingStudentData = getIsFetchingStudentPolicy(state);

  const license = getLicense(state);
  const currentTime = getAccountCurrentTime(state);
  const timezone = getTimezone(state);
  const licenseDaysLeft = getLicenseDaysLeft(license, currentTime, timezone);
  const profileIosDevicesCount = getProfileIOSDevices(state, profileId).size;
  const profileAndroidDevicesCount = getProfileAndroidDevices(
    state,
    profileId
  ).size;
  const totalDevicesCount = getProfileDevices(state, profileId).size;

  const isDelegatedWithDesktopDevice = isSchoolLicenseWithValidDevices(
    state,
    profileId
  );
  const isSchoolReferralLicense = isSchoolReferral(state);
  const isValidForTrial = getEligibleForManualTrial(state);
  const isAccountLinkedToSchool = isSchoolAccount(state);

  const getFooterType = getProfileRulesLabel({
    license,
    licenseDaysLeft,
    profileAndroidDevicesCount,
    profileIosDevicesCount,
    totalDevicesCount,
    isDelegatedToThisAccount: isProfileDelegatedToTheAccount,
    isDelegatedWithDesktopDevice,
    isValidForTrial,
    isAccountLinkedToSchool,
    isSchoolReferralLicense,
  });

  return {
    profileId,
    rulesList: List(prepareRulesList(profileRulesList, state)),
    getFooterType,
    isLinkedWithSchool,
    shouldShowDelegation,
    isProfileDelegated,
    isCombinedProfile,
    isProfileDelegatedToTheAccount,
    shouldRulesBeEnabled,
    shouldShowTheDelegationAlertBox,
    isFetchingStudentData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showInAppRatingPopup: () => dispatch(handleShowInAppRatingPopup()),
  onClick: (profileId: number, ruleKey: string) =>
    dispatch(clickRule(profileId)(ruleKey)),
  onToggleDelegation: (profileId: number, enabled: boolean) => {
    if (enabled) {
      dispatch(enabledDelegation(profileId, enabled));
    } else {
      dispatch(navigateToDelegationOptOutModal());
    }
  },
  onLoadStudentPolicies: (profileId: number) => {
    dispatch(fetchStudentPolicies(profileId));
  },
  onClickDelegationRuleInfo: () => {
    dispatch(navigateToDelegationRuleInfoModal());
  },
});

export const handleShowInAppRatingPopup =
  (): BaseThunk<void> => async (dispatch, getState) => {
    if (await shouldShowIosPreConsentTrackingModal(getState())) {
      dispatch(showIosPreConsentTrackingModalForCurrentScenario());
    } else {
      dispatch(showInAppRatingPopupForCurrentScenario());
    }
  };

const ProfileRulesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileRules);

const isDelegationRulesModalDismissed = (state: State) => {
  return isSlaveAccount(state)
    ? getAdditionalParentAccountOptionsDelegationRulesModalDismissed(state)
    : getAccountOptionsDelegationRulesModalDismissed(state);
};

ProfileRulesContainer.redirect =
  (params: RouterParams): BaseThunk =>
  async (dispatch, getState) => {
    let state = getState();
    const profile = getProfile(state, params.profileId);
    if (
      !flags.useDelegation.isEnabled() ||
      (flags.useDelegation.isEnabled() && !profile?.isLinewizestudentLinked)
    ) {
      dispatch(redirectToAddDeviceIfNoDeviceAssignedTo(params.profileId));
    }
    // show delegation rules modal the first time a user access
    // we store if the account or slave account has seen this in the account options
    // we also update this if a user enables delegation manually, so when accessing again we do not show the modal again
    if (
      flags.useDelegationAutoOptin.isEnabled() &&
      profile?.isLinewizestudentLinked
    ) {
      await dispatch(fetchStudentPolicies(profile.id));
      state = getState();
      if (
        isProfileDelegatedToThisAccount(
          isDelegationEnabled(state),
          isStudentOptInForDelegationForThisAccount(state),
          profile.isLinewizestudentLinked
        ) &&
        !isDelegationRulesModalDismissed(state)
      ) {
        dispatch(navigateToDelegationEnabledModal());
      }
    }
  };

export default ProfileRulesContainer;

export type ProfileRulesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
