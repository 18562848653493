import * as React from 'react';
import * as Moment from 'moment-timezone';
import { t } from '../../lib/i18n';

import {
  LicenseRecord,
  expiresSoon,
  isAutoRenewal,
  isPremium,
  isTrial,
  LicenseType,
  licenseDisplayName,
} from '../../records/license';
import { ButtonSize, BadgeType, Button } from 'styleguide-react';

export const licenseAction = (license, currentTime, timezone) => {
  if (isAutoRenewal(license.renewal)) {
    return t('Expand your plan');
  }
  if (expiresSoon(license, currentTime, timezone)) {
    return t('Renew now');
  }
  if (isPremium(license.type)) {
    return t('Expand your plan');
  }
  if (isTrial(license.type)) {
    return t('Upgrade now!');
  }
  return t('Upgrade now!');
};
// TODO:marketing
// only used in the sidebar license badge
export const getColorByLicense = (license, showColor) => {
  if (!showColor) return BadgeType.brand;

  switch (license.type) {
    case LicenseType.Premium:
      return BadgeType.brand;
    case LicenseType.Trial:
      return BadgeType.warning;
    default:
      return BadgeType.success;
  }
};

// TODO: the button size is incorrect we need to update this button once we update buttons in rocket
const LicenseBadge: React.FunctionComponent<{
  license: LicenseRecord;
  showAction: boolean;
  currentTime: Moment.Moment;
  timezone: string;
}> = ({ license, showAction, currentTime, timezone }) => (
  <Button size={ButtonSize.medium}>
    {showAction
      ? licenseAction(license, currentTime, timezone)
      : licenseDisplayName(license).toUpperCase()}
  </Button>
);

export default LicenseBadge;
