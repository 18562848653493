import * as React from 'react';
import ProfileSummarySelectorDropdown from '../DropDown/ProfileSummarySelectorDropdown';
import flags from '../../sideEffects/flags';

// eslint-disable-next-line @typescript-eslint/ban-types
const ProfileSummarySelector: React.FunctionComponent<{}> = () => {
  if (flags.useUnifiedDashboard.isEnabled()) {
    return null;
  }
  return (
    <div className="ProfileSummarySelector">
      <ProfileSummarySelectorDropdown className="ProfileSummarySelector__dropdown" />
    </div>
  );
};

export default ProfileSummarySelector;
