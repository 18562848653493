import {
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
  RULE_ROUTINES,
} from '../../../../constants';
import { shouldBlockThisFeature } from '../../../../helpers/premiumFeatures';
import { BlockableFeature } from '../../../../helpers/premiumFeatures/types/Feature';
import { isTrial, isFree } from '../../../../records/license';
import { LicenseSubtype } from '../../../../records/license/types/License.types';
import { ProfileRulesLabelPredicatesProps } from '../types/ProfileRulesLabel.types';

const upgradeTrialOrFreeProfileRules = [
  RULE_GAMES_AND_APPS,
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
  RULE_YOUTUBE,
  RULE_ROUTINES,
];

const upgradeTrialOrFreeProfileRulesForSchools = [
  RULE_LOCATION,
  RULE_CALLS_AND_SMS,
  RULE_PANIC_BUTTON,
];

const partialShouldBlockFeature =
  (licenseSubtype: LicenseSubtype) => (feature: BlockableFeature) =>
    shouldBlockThisFeature(feature, licenseSubtype);

export const isVisibleUpgradeLabel = ({
  license,
  profileRule,
  licenseDaysLeft,
  showConversion,
  isAccountLinkedToSchool,
  isSchoolReferralLicense,
}: ProfileRulesLabelPredicatesProps) => {
  // we want to show the upgrade label if it's a school free license
  if (!showConversion && license.subtype !== 'school_free') return false;

  const isTrialLicense = isTrial(license.type);
  const isFreeLicense = isFree(license.type);
  const shouldBlockFeature = partialShouldBlockFeature(license.subtype);

  // If its trial license's last day, we show upgrade tag for all the rules in the upgrade list.
  if (isTrialLicense && licenseDaysLeft <= 0) {
    return isAccountLinkedToSchool || isSchoolReferralLicense
      ? upgradeTrialOrFreeProfileRulesForSchools.includes(profileRule)
      : upgradeTrialOrFreeProfileRules.includes(profileRule);
  }
  // We are ignoring the `routineContentFiltering` flag because we don't have a case where
  // `routineBlocking` = false and `routineContentFiltering` = true.
  if (profileRule === RULE_ROUTINES) {
    return shouldBlockFeature('routineBlocking');
  }
  if (profileRule === RULE_YOUTUBE) {
    return shouldBlockFeature('youtubeRules');
  }
  if (profileRule === RULE_CALLS_AND_SMS) {
    return shouldBlockFeature('callsSMSRules');
  }
  if (isFreeLicense) {
    return upgradeTrialOrFreeProfileRules.includes(profileRule);
  }
  return false;
};
