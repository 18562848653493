import * as React from 'react';
import { t } from '../../lib/i18n';
import type { PanicButtonRulesPremiumFlyoverProps } from '../../containers/ProfileRules/PanicButtonRules/PanicButtonRulesPremiumFlyoverContainer';
import PanicButtonRulesFlyover from './PanicButtonRulesFlyover';

const PREMIUM_FLYOVER_TEST_ID = 'panic-button-rules-premium-flyover';

const PanicButtonRulesPremiumFlyover = ({
  accountLocale,
  onClickClose,
  onClickUpgrade,
}: PanicButtonRulesPremiumFlyoverProps) => {
  return (
    <PanicButtonRulesFlyover
      accountLocale={accountLocale}
      testId={PREMIUM_FLYOVER_TEST_ID}
      topbarText={t('{1}Upgrade your plan{/1} to enable the panic button!', {
        1: x => x,
      })}
      actionButton={{
        title: t('Upgrade'),
        onClick: onClickUpgrade,
        testId: `${PREMIUM_FLYOVER_TEST_ID}-upgrade`,
      }}
      onClickClose={onClickClose}
      footNote={t('*Available for Android')}
    />
  );
};

export default PanicButtonRulesPremiumFlyover;
