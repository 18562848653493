import { Moment } from 'moment';
import {
  getWellbeingActivitiesFeature,
  getWellbeingTherapyFeature,
} from '../../ducks/features';
import State from '../../store/state';
import { isFeatureEnabled, isFeatureRequireUpgrade } from '../features';
import {
  WellbeingSubscriptionStatus,
  WellbeingSupportEmailAddress,
} from './types';
import { t } from '../../lib/i18n';
import { Email } from '../../constants';
import { getPersistentStorageItem } from '../../selectors';
import flags from '../../sideEffects/flags';

export const isWellbeingFeatureEnabled = (state: State): boolean => {
  const wellbeingTherapy = getWellbeingTherapyFeature(state);
  const wellbeingActivities = getWellbeingActivitiesFeature(state);
  const isTherapyEnabled =
    wellbeingTherapy &&
    (isFeatureEnabled(wellbeingTherapy) ||
      isFeatureRequireUpgrade(wellbeingTherapy));
  const isActivitiesEnabled =
    wellbeingActivities &&
    (isFeatureEnabled(wellbeingActivities) ||
      isFeatureRequireUpgrade(wellbeingActivities));

  return Boolean(isTherapyEnabled || isActivitiesEnabled);
};

export const getWellbeingSubscriptionStatus = (
  state: State
): WellbeingSubscriptionStatus => {
  const therapy = getWellbeingTherapyFeature(state);
  const activities = getWellbeingActivitiesFeature(state);

  if (isWellbeingFeatureEnabled(state)) {
    if (therapy && !isFeatureRequireUpgrade(therapy)) {
      return 'both';
    }

    if (activities && !isFeatureRequireUpgrade(activities)) {
      return 'activities_only';
    }
  }

  return 'none';
};

/**
 * Calculate the next wellbeing date, that is to say, return the date of the next Saturday.
 */
export const getNextWellbeingSession = (date: Moment) => {
  return date.day() === 6 ? date.clone() : date.clone().endOf('week');
};

export const formatWellbeingSession = (session: Moment, today: Moment) => {
  return session.isSame(today) ? 'Today' : session.format('MMMM D, YYYY');
};

/**
 * Contact support modal.
 */
export const getWellbeingSupportAddress = (
  subscriptionStatus: WellbeingSubscriptionStatus
): WellbeingSupportEmailAddress => {
  return subscriptionStatus === 'both' ? Email.therapy : Email.support;
};

export const getWellbeingSupportEmailSubject = (
  subscriptionStatus: WellbeingSubscriptionStatus,
  accountEmail: string
): string => {
  return subscriptionStatus === 'both'
    ? t('My Family Therapy Subscription [{{accountEmail}}]', { accountEmail })
    : t('My Wellbeing Activity Subscription [{{accountEmail}}]', {
        accountEmail,
      });
};

export const shouldDisplayWellbeingBanner = state => {
  if (!isWellbeingFeatureEnabled(state)) {
    return false;
  }

  if (getPersistentStorageItem(state, 'wellbeingBannerClosed') === 'true') {
    return false;
  }

  return getWellbeingSubscriptionStatus(state) === 'none';
};

export const shouldDisplayWellbeingNewTag = () => {
  return flags.useWellbeingNewTag.isEnabled();
};
