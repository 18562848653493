import { Record } from 'immutable';

export type Gift = {
  key: string;
  type: string;
};

type GiftRecord = Record<Gift>;

const GiftRecord = Record<
  Gift,
  {
    serialize: Record.Serializer<Record<Gift>, Gift>;
    fromPayload: Record.Parser<Gift, Record<Gift>>;
  }
>({
  key: '',
  type: '',
});

GiftRecord.fromPayload = payload => GiftRecord(payload);

GiftRecord.serialize = record => record.toJS();

export default GiftRecord;
