import { assign, createMachine } from 'xstate';
import { allPass, both, complement, either, mergeDeepRight } from 'ramda';
import {
  MultiStepDataContext,
  RoutineSteps,
  MultiStepContext,
  MultiStepNextEvent,
  MultiStepPrevEvent,
  MultiStepTransition,
  MultiStepUpdateEvent,
  MultiStepExitEvent,
  RoutineMode,
  MultiStepResetDataContextInCreateModeEvent,
  RoutineFeatureAccessLevel,
} from '../routines.types';
import { PartialDeep } from '../../../types/PartialDeep.types';

import { useMachine } from '@xstate/react';
import * as React from 'react';
import { RoutineMachineAction } from '../../../ducks/routines';

export type DataContext = PartialDeep<MultiStepDataContext>;
type Context = MultiStepContext<DataContext>;
type NextEvent = MultiStepNextEvent<DataContext>;
type UpdateContextEvent = MultiStepUpdateEvent<DataContext>;
type Events =
  | NextEvent
  | MultiStepPrevEvent
  | UpdateContextEvent
  | MultiStepExitEvent
  | MultiStepResetDataContextInCreateModeEvent;

const updateContext = (step: RoutineSteps) =>
  assign({
    data: (context: Context, event: Events) => {
      if (event.type === 'NEXT') {
        return mergeDeepRight(context.data, event.data);
      }
      return context.data;
    },
    stepsStack: (context: Context, event: Events) => {
      if (event.type === 'NEXT') {
        return context.stepsStack.concat(step);
      }
      if (event.type === 'PREV') {
        return context.stepsStack.slice(0, -1);
      }
      return context.stepsStack;
    },
  });

const updateDataContext = assign({
  data: (context: Context, event: UpdateContextEvent) =>
    mergeDeepRight(context.data, event.data),
});

const fromPreviousStep = (step: RoutineSteps) => (context: Context) => {
  const prev = context.stepsStack[context.stepsStack.length - 2];
  return prev === step;
};

const isRoutineType = (type: DataContext['type']) => (_ctx, event: NextEvent) =>
  event.data.type === type;

const isRoutineContextType = (type: DataContext['type']) => ctx =>
  ctx.data.type === type;

const isMode = (mode: RoutineMode) => (context: Context) =>
  context.data.mode === mode;

const isTransitionType =
  (transition: MultiStepTransition) => (_ctx, event: NextEvent) =>
    event.transition === transition;

const hasFullFeatureAccess = () => (context: Context) =>
  context.data.featureAccessLevel === RoutineFeatureAccessLevel.full;

const hasRoutineBlockingFeatureAccess = () => (context: Context) =>
  context.data.featureAccessLevel === RoutineFeatureAccessLevel.routineBlocking;

const hasGuestFeatureAccess = () => (context: Context) =>
  context.data.featureAccessLevel === RoutineFeatureAccessLevel.guest;

const getInitialState = () => RoutineSteps.idle;

const getContext = (data: DataContext = {}) => {
  const initialContext: Context = {
    stepsStack: [getInitialState()],
    data: {
      uid: undefined,
      mode: null,
      paused: false,
      finished: false,
      type: undefined,
      block: 'POLICY_BLOCK_TYPE_NONE',
      editedTimeSlotUid: undefined,
      featureAccessLevel: RoutineFeatureAccessLevel.none,
      contentFilter: {
        appRuleType: 'DEFAULT',
        webRuleType: 'DEFAULT',
        appsException: [],
        websException: [],
      },
      style: {
        name: undefined,
        color: undefined,
        icon: undefined,
        description: undefined,
      },
      timeSlot: [],
    },
  };

  return mergeDeepRight(initialContext, { data });
};

const resetContext = assign(getContext());

const resetDataContextInCreateMode = assign({
  data: () => getContext({ mode: 'CREATE' }).data,
});

const EXIT = {
  target: getInitialState(),
  actions: [resetContext],
};

const UPDATE_CONTEXT = {
  actions: [updateDataContext],
};

const routinesMachine = createMachine<Context, Events>({
  id: 'create-routine',
  predictableActionArguments: true,
  initial: getInitialState(),
  context: getContext(),
  states: {
    [getInitialState()]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        NEXT: [
          {
            target: RoutineSteps.premiumBasicSelectRoutineType,
            cond: both(isMode('CREATE'), hasRoutineBlockingFeatureAccess()),
            actions: [
              updateContext(RoutineSteps.premiumBasicSelectRoutineType),
            ],
          },
          {
            target: RoutineSteps.selectPreset,
            cond: both(isMode('CREATE'), hasFullFeatureAccess()),
            actions: [updateContext(RoutineSteps.selectPreset)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: allPass([
              isMode('EDIT'),
              either(
                hasFullFeatureAccess(),
                both(
                  hasRoutineBlockingFeatureAccess(),
                  isRoutineContextType('ROUTINE_TYPE_BLOCKED')
                )
              ),
            ]),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.premiumUpgrade,
            cond: either(
              hasGuestFeatureAccess(),
              allPass([
                isMode('EDIT'),
                hasRoutineBlockingFeatureAccess(),
                isRoutineContextType('ROUTINE_TYPE_MANAGEMENT'),
              ])
            ),
            actions: [updateContext(RoutineSteps.premiumUpgrade)],
          },
        ],
      },
    },
    [RoutineSteps.selectPreset]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        RESET_DATA_CONTEXT_IN_CREATE_MODE: {
          actions: [resetDataContextInCreateMode],
        },
        NEXT: [
          {
            target: RoutineSteps.selectRoutineType,
            cond: isTransitionType('EDIT_RULES'),
            actions: [updateContext(RoutineSteps.selectRoutineType)],
          },
          {
            target: RoutineSteps.scheduleATimeSlot,
            cond: isTransitionType('SCHEDULE_TIME_SLOT'),
            actions: [updateContext(RoutineSteps.scheduleATimeSlot)],
          },
        ],
      },
    },
    [RoutineSteps.premiumBasicSelectRoutineType]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        RESET_DATA_CONTEXT_IN_CREATE_MODE: {
          actions: [resetDataContextInCreateMode],
        },
        NEXT: [
          {
            target: RoutineSteps.premiumUpgrade,
            cond: isTransitionType('UPGRADE_TO_PREMIUM'),
            actions: [updateContext(RoutineSteps.premiumUpgrade)],
          },
          {
            target: RoutineSteps.chooseNameAndStyle,
            cond: complement(isTransitionType('UPGRADE_TO_PREMIUM')),
            actions: [updateContext(RoutineSteps.chooseNameAndStyle)],
          },
        ],
      },
    },
    [RoutineSteps.premiumUpgrade]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.premiumBasicSelectRoutineType,
            cond: fromPreviousStep(RoutineSteps.premiumBasicSelectRoutineType),
            actions: [
              updateContext(RoutineSteps.premiumBasicSelectRoutineType),
            ],
          },
        ],
      },
    },
    [RoutineSteps.selectRoutineType]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.selectPreset,
            actions: [updateContext(RoutineSteps.selectPreset)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.chooseAppRules,
            cond: isRoutineType('ROUTINE_TYPE_MANAGEMENT'),
            actions: [updateContext(RoutineSteps.chooseAppRules)],
          },
          {
            target: RoutineSteps.selectBlockType,
            cond: isRoutineType('ROUTINE_TYPE_BLOCKED'),
            actions: [updateContext(RoutineSteps.selectBlockType)],
          },
        ],
      },
    },
    [RoutineSteps.selectBlockType]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.selectRoutineType,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.selectRoutineType)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.chooseNameAndStyle,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.chooseNameAndStyle)],
          },
        ],
      },
    },
    [RoutineSteps.chooseAppRules]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.selectRoutineType,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.selectRoutineType)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.chooseWebRules,
            cond: both(isMode('CREATE'), isTransitionType('CONTINUE')),
            actions: [updateContext(RoutineSteps.chooseWebRules)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: both(isMode('EDIT'), isTransitionType('CONTINUE')),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.selectAppsException,
            cond: isTransitionType('APP'),
            actions: [updateContext(RoutineSteps.selectAppsException)],
          },
        ],
      },
    },
    [RoutineSteps.chooseWebRules]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.chooseAppRules,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.chooseAppRules)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.chooseTimeLimitOption,
            cond: both(isMode('CREATE'), isTransitionType('CONTINUE')),
            actions: [updateContext(RoutineSteps.chooseTimeLimitOption)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: both(isMode('EDIT'), isTransitionType('CONTINUE')),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.selectWebsException,
            cond: isTransitionType('WEB'),
            actions: [updateContext(RoutineSteps.selectWebsException)],
          },
        ],
      },
    },
    [RoutineSteps.chooseTimeLimitOption]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.chooseWebRules,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.chooseWebRules)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.chooseNameAndStyle,
            cond: both(isMode('CREATE'), isTransitionType('CONTINUE')),
            actions: [updateContext(RoutineSteps.chooseNameAndStyle)],
          },
        ],
      },
    },
    [RoutineSteps.chooseNameAndStyle]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.chooseTimeLimitOption,
            cond: fromPreviousStep(RoutineSteps.chooseTimeLimitOption),
            actions: [updateContext(RoutineSteps.chooseTimeLimitOption)],
          },
          {
            target: RoutineSteps.selectBlockType,
            cond: fromPreviousStep(RoutineSteps.selectBlockType),
            actions: [updateContext(RoutineSteps.selectBlockType)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: fromPreviousStep(RoutineSteps.editRoutine),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.premiumBasicSelectRoutineType,
            cond: fromPreviousStep(RoutineSteps.premiumBasicSelectRoutineType),
            actions: [
              updateContext(RoutineSteps.premiumBasicSelectRoutineType),
            ],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.scheduleATimeSlot,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.scheduleATimeSlot)],
          },
        ],
      },
    },
    [RoutineSteps.scheduleATimeSlot]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: [
          {
            target: RoutineSteps.selectPreset,
            cond: fromPreviousStep(RoutineSteps.selectPreset),
            actions: [updateContext(RoutineSteps.selectPreset)],
          },
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: RoutineSteps.chooseNameAndStyle,
            cond: isMode('CREATE'),
            actions: [updateContext(RoutineSteps.chooseNameAndStyle)],
          },
        ],
        NEXT: [
          {
            target: RoutineSteps.editRoutine,
            cond: isMode('EDIT'),
            actions: [updateContext(RoutineSteps.editRoutine)],
          },
          {
            target: getInitialState(),
            cond: isMode('CREATE'),
            actions: [updateContext(getInitialState())],
          },
        ],
      },
    },
    [RoutineSteps.selectAppsException]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: {
          target: RoutineSteps.chooseAppRules,
          actions: [updateContext(RoutineSteps.chooseAppRules)],
        },
        NEXT: {
          target: RoutineSteps.chooseAppRules,
          actions: [updateContext(RoutineSteps.chooseAppRules)],
        },
      },
    },
    [RoutineSteps.selectWebsException]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        PREV: {
          target: RoutineSteps.chooseWebRules,
          actions: [updateContext(RoutineSteps.chooseWebRules)],
        },
        NEXT: {
          target: RoutineSteps.chooseWebRules,
          actions: [updateContext(RoutineSteps.chooseWebRules)],
        },
      },
    },
    [RoutineSteps.editRoutine]: {
      on: {
        EXIT,
        UPDATE_CONTEXT,
        NEXT: [
          {
            target: RoutineSteps.chooseNameAndStyle,
            cond: isTransitionType('STYLE'),
            actions: [updateContext(RoutineSteps.chooseNameAndStyle)],
          },
          {
            target: RoutineSteps.selectBlockType,
            cond: both(
              isTransitionType('EDIT_RULES'),
              isRoutineContextType('ROUTINE_TYPE_BLOCKED')
            ),
            actions: [updateContext(RoutineSteps.selectBlockType)],
          },
          {
            target: RoutineSteps.chooseAppRules,
            cond: both(
              isTransitionType('EDIT_APP_RULES'),
              isRoutineContextType('ROUTINE_TYPE_MANAGEMENT')
            ),
            actions: [updateContext(RoutineSteps.chooseAppRules)],
          },
          {
            target: RoutineSteps.chooseWebRules,
            cond: both(
              isTransitionType('EDIT_WEB_RULES'),
              isRoutineContextType('ROUTINE_TYPE_MANAGEMENT')
            ),
            actions: [updateContext(RoutineSteps.chooseWebRules)],
          },
          {
            target: RoutineSteps.scheduleATimeSlot,
            cond: isTransitionType('SCHEDULE_TIME_SLOT'),
            actions: [updateContext(RoutineSteps.scheduleATimeSlot)],
          },
        ],
      },
    },
  },
});

export default routinesMachine;

interface UseCreateMultiStepMachine {
  onFinish: (data: DataContext) => void;
  routine?: MultiStepDataContext;
  mode: RoutineMode;
  featureAccessLevel: RoutineFeatureAccessLevel;
  machineAction: RoutineMachineAction;
}

export const createActions = send => {
  return {
    next: (data: DataContext, transition: MultiStepTransition = 'CONTINUE') => {
      return send({ type: 'NEXT', data, transition });
    },
    prev: () => send({ type: 'PREV' }),
    exit: () => send({ type: 'EXIT' }),
    updateContext: (data: DataContext | MultiStepDataContext) =>
      send({ type: 'UPDATE_CONTEXT', data }),
    complete: (
      data: DataContext,
      transition: MultiStepTransition = 'CONTINUE'
    ) =>
      send({
        type: 'NEXT',
        data: mergeDeepRight({ finished: true }, data),
        transition,
      }),
    transition: (transition: MultiStepTransition) =>
      send({ type: 'NEXT', data: {}, transition }),
    resetDataContextInCreateMode: () =>
      send({ type: 'RESET_DATA_CONTEXT_IN_CREATE_MODE' }),
  };
};

export const useCreateMultiStepMachine = ({
  mode,
  routine,
  machineAction,
  featureAccessLevel,
  onFinish,
}: UseCreateMultiStepMachine) => {
  const context = getContext(routine);
  const [state, send] = useMachine(routinesMachine, { context });
  const actions = createActions(send);

  React.useEffect(() => {
    //  Allow call side effect when creation is done
    if (state.context.data.finished) {
      onFinish(state.context.data);
    }
  }, [state.context]);

  React.useEffect(() => {
    /*
    Since the multi step exists throughout the entire lifecycle of the app, 
    in some scenarios it's necessary to perform actions in the state machine 
    of UI parts that aren't part of the multi step such as confirmation modals. 
    This hook allows for actions to be taken in the state machine using changes in 
    the global state of the app."
    */
    if (machineAction.type === 'PREV') {
      actions.prev();
    }

    if (machineAction.type === 'EXIT') {
      /*
      It is necessary to delay the action because 
      if it is done immediately it looks strange 
      when closing the drawer
      */
      setTimeout(() => actions.exit(), 250);
    }

    if (
      machineAction.type === 'UPDATE_CONTEXT' &&
      machineAction.params !== null
    ) {
      actions.updateContext(machineAction.params);
    }
  }, [machineAction]);

  React.useEffect(() => {
    if (state.value === getInitialState()) {
      /* 
      Machine is present throughout the application's lifecycle and has two possible 
      modes, edit and create. 
      In order to transition from idle to edit or create, 
      it is necessary to hydrate the routine or default values that will be used. 
      This part allows for the transition from idle to edit or create.
      */
      actions.updateContext(
        getContext({ ...routine, mode, featureAccessLevel }).data
      );
      actions.next({});
    }

    if (mode === null) {
      actions.exit();
    }

    if (mode === 'EDIT') {
      /*
      When its edit mode must hydrate context so that changes are reflected in ui, 
      this is especially necessary because we have opted for an eager updating strategy
      */
      actions.updateContext(
        getContext({ ...routine, mode, featureAccessLevel }).data
      );
    }
  }, [mode, routine]);

  return [state, actions] as const;
};
