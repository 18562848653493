import * as React from 'react';
import {
  Modal,
  Icon,
  IconType,
  Button,
  ButtonSize,
  IconColor,
  ButtonType,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { modalDimensions } from '../../constants/index';

const CloseAdditionalParentAccountModal = ({
  masterAccountName,
  onCloseAccount,
  onCloseModal,
}: {
  masterAccountName: string;
  onCloseAccount: () => Promise<void>;
  onCloseModal: () => void;
}) => {
  const [isClosingAccount, setIsClosingAccount] = React.useState(false);

  const handleCloseAccount = async () => {
    setIsClosingAccount(true);

    try {
      await onCloseAccount();
    } catch (_) {
      setIsClosingAccount(false);
    }
  };

  return (
    <Modal
      testId="closeAdditionalParentAccount"
      title={t('Leave and close account?')}
      header={{
        icon: (
          <Icon type={IconType.exclamationCircle} color={IconColor.error} />
        ),
      }}
      onClickClose={onCloseModal}
      height={448}
      width={modalDimensions.regular.width}
      buttons={[
        <Button
          key="button1"
          onClick={handleCloseAccount}
          size={ButtonSize.medium}
          block
          testId="closeAccount"
          disabled={isClosingAccount}
          loading={isClosingAccount}
        >
          {t('Leave & close account')}
        </Button>,
        <Button
          key="button2"
          block
          buttonType={ButtonType.secondary}
          size={ButtonSize.medium}
          onClick={onCloseModal}
          testId="closeModal"
        >
          {t('Cancel')}
        </Button>,
      ]}
    >
      {t(
        "You'll no longer have access to supervise {{masterAccountName}}'s family and all your data will be removed from {{shortName}}.",
        {
          masterAccountName,
        }
      )}
    </Modal>
  );
};

export default CloseAdditionalParentAccountModal;
