import * as React from 'react';
import { Link } from 'react-router';
import { List } from 'immutable';
import {
  List as RegularStyleList,
  RegularStyleListItem,
  InstructionBlock,
  Switch,
  ClickableListItem,
  ListTitle,
  FlexLayout,
  Label,
  ContentSeparator,
  TransparentButton,
  IconType,
  Icon,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { categoryLabels } from '../../records/category';
import RenderWhen, { ScreenSize } from '../RenderWhen/RenderWhen';
import { WebCategoryRecord } from '../../records/profileRules';
import DropDownActionSheet from '../DropDown/DropDownActionSheet';
import WebFiltersMapToIcon from './WebFiltersMapToIcon';

// attaches action (BLOCK, ALERT, ALLOW, ...) to each categoryLabel
// [categoryLabels] -> [categoryRecords] -> [categoryLabelsWithActions]
const attachAction = (
  categoryLabels,
  categoryRecords: List<WebCategoryRecord>
) =>
  categoryLabels.map(categoryLabel => {
    const record = categoryRecords
      ? categoryRecords.find(item => item.category === categoryLabel.category)
      : undefined;
    return {
      ...categoryLabel,
      action: record ? record.action : undefined,
    };
  });

const WebFiltersCategories: React.FunctionComponent<{
  profileId: string;
  categories: List<WebCategoryRecord>;
  categoryRestrictionsEnabled: boolean;
  onToggleWebFilterEnabled: (toggleOn: boolean) => any;
  onClickCategory: (category: WebCategoryRecord) => any;
}> = ({
  profileId,
  categories,
  categoryRestrictionsEnabled,
  onToggleWebFilterEnabled,
  onClickCategory,
}) => (
  <FlexLayout mainaxis="column">
    <FlexLayout
      mainaxis="row"
      marginTop="24"
      marginBottom="8"
      mainaxisAlignment="space-between"
      crossaxisAlignment="center"
    >
      <Label>{t('Web category filters')}</Label>

      <Switch
        checked={categoryRestrictionsEnabled}
        testId="categories-switch-global"
        onClick={ev => {
          onToggleWebFilterEnabled((ev.target as HTMLInputElement).checked);
        }}
      />
    </FlexLayout>

    <ContentSeparator />

    <FlexLayout mainaxis="column" marginTop="16" marginBottom="8">
      <InstructionBlock>
        {t('Click on a web category below to set filtering rules.')} &nbsp;
        <Link
          to={`/profiles/${profileId}/rules/webFilters/modal/WebFiltersLegendModal`}
        >
          <Icon type={IconType.questionCircle} />
        </Link>
      </InstructionBlock>
    </FlexLayout>

    <FlexLayout mainaxis="column" marginBottom="24">
      <RegularStyleList
        listItemMarginTop="8"
        listItemPaddingTop="8"
        listItemMarginBottom="8"
        listItemPaddingBottom="8"
        disabled={!categoryRestrictionsEnabled}
      >
        {attachAction(categoryLabels(), categories).map(category => {
          const clickHandler = () => {
            if (!categoryRestrictionsEnabled) {
              return;
            }
            onClickCategory(category);
          };

          return (
            <RegularStyleListItem
              title={
                <React.Fragment>
                  <RenderWhen screenSize={ScreenSize.MobileOrTablet}>
                    <ClickableListItem onClick={clickHandler}>
                      <ListTitle isBold={false}>{category.label}</ListTitle>
                    </ClickableListItem>
                  </RenderWhen>
                  <RenderWhen screenSize={ScreenSize.Desktop}>
                    <ListTitle isBold={false}>{category.label}</ListTitle>
                  </RenderWhen>
                </React.Fragment>
              }
              key={category.category}
              icon={<WebFiltersMapToIcon action={category.action} />}
              actionElement={
                <TransparentButton
                  testId="list-item-dots-wrapper-clickable"
                  onClick={clickHandler}
                >
                  <DropDownActionSheet
                    title={category.label}
                    selectedActionSheetKey={category.label}
                  />
                </TransparentButton>
              }
            />
          );
        })}
      </RegularStyleList>
    </FlexLayout>
  </FlexLayout>
);

export default WebFiltersCategories;
