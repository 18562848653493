import * as React from 'react';
import {
  Button,
  Modal,
  ButtonSize,
  ModalStyledText,
  FlexLayout,
  Layout,
  Dropdown,
  DropdownOption,
  Icon,
  ActionInput,
  IconType,
  IconSize,
  ActionInputIconPosition,
  Typography,
  ButtonType,
  DurationInput,
} from 'styleguide-react';
import { t } from '../../lib/i18n';
import { List } from 'immutable';
import { RoutineRecord } from '../../records/routines/types/Routine.types';
import { getRoutinesIcon } from '../Routines/helpers/routineIcons';
import RoutineIcon from '../Routines/RoutineIcon/RoutineIcon';
import { Moment } from 'moment-timezone';
import { Datetime } from '../../types/dates';
import { doesRoutineConsumeDailyTimeQuota } from '../../ducks/routines/helpers';

export type SwitchRoutineChoice =
  | '15m'
  | '30m'
  | '1h'
  | 'next_routine'
  | 'custom';

export type SwitchRoutineFnParams = {
  currentTime: Datetime;
  timezone: string;
  profileId: string;
  profileUid: string;
  routine: RoutineRecord;
  choice: SwitchRoutineChoice;
  duration: number;
};

export type RoutineSwitchModalProps = {
  profileId: string;
  currentTime: Moment;
  timezone: string;
  profileUid: string | null;
  routinesList: List<RoutineRecord>;
  timeToNextSchedule?: number;
  onClickClose: () => void;
  onSwitchRoutine: (options: SwitchRoutineFnParams) => void;
};

const durationOptions: Record<SwitchRoutineChoice, () => string> = {
  '15m': () => t('15 minutes'),
  '30m': () => t('30 minutes'),
  '1h': () => t('1 hour'),
  next_routine: () => t('Until next routine'),
  custom: () => t('Custom'),
};

const durationMinutesMap: Record<SwitchRoutineChoice, number> = {
  '15m': 15,
  '30m': 30,
  '1h': 60,
  custom: 0,
  next_routine: 0,
};

const RoutineSwitchModal = ({
  profileId,
  currentTime,
  timezone,
  profileUid,
  routinesList,
  timeToNextSchedule,
  onClickClose,
  onSwitchRoutine,
}: RoutineSwitchModalProps) => {
  const [openDropdown, setOpenDropdown] = React.useState<
    'routine' | 'duration'
  >();
  const [selectedRoutine, setSelectedRoutine] = React.useState<RoutineRecord>();
  const [selectedDuration, setSelectedDuration] =
    React.useState<SwitchRoutineChoice>();
  const [duration, setDuration] = React.useState(0);

  React.useLayoutEffect(() => {
    const modalContainer = document.querySelector<HTMLDivElement>(
      '.par-routine-switch-modal'
    );
    if (!modalContainer) return undefined;

    const listener = () => {
      setOpenDropdown(undefined);
    };
    modalContainer.addEventListener('click', listener);
    return () => {
      modalContainer.removeEventListener('click', listener);
    };
  }, [setOpenDropdown]);

  const isCustomDuration = selectedDuration === 'custom';
  const isSubmitDisabled =
    !profileUid || !selectedRoutine || !selectedDuration || !duration;

  const onChangeSelectedRoutine = (routineUid: string) => {
    const routine = routinesList.find(routine => routine.uid === routineUid);
    setSelectedRoutine(routine);
  };

  const onChangeDurationChoice = (choice: SwitchRoutineChoice) => {
    let duration = durationMinutesMap[choice];
    if (choice === 'next_routine' && timeToNextSchedule) {
      duration = timeToNextSchedule;
    }

    setSelectedDuration(choice);
    setDuration(duration);
  };

  const onSubmitSwitch = () => {
    if (isSubmitDisabled) return undefined;
    onSwitchRoutine({
      currentTime,
      timezone,
      profileId,
      profileUid,
      duration,
      routine: selectedRoutine,
      choice: selectedDuration,
    });
  };

  const getDurationChoiceOptions = () => {
    return Object.entries(durationOptions).filter(([key]) => {
      // We do not show the "next routine" option if there is not next event available
      if (!timeToNextSchedule && key === 'next_routine') return false;
      return true;
    });
  };

  return (
    <Modal
      className="par-routine-switch-modal"
      title={t('Switch routine')}
      size="medium"
      onClickClose={onClickClose}
      buttons={[
        <Button
          key="button1"
          onClick={onSubmitSwitch}
          size={ButtonSize.medium}
          block
          disabled={isSubmitDisabled}
          testId="switch-now"
        >
          {t('Switch now')}
        </Button>,
        <Button
          key="button2"
          onClick={onClickClose}
          size={ButtonSize.medium}
          buttonType={ButtonType.plain}
          block
        >
          {t('Cancel')}
        </Button>,
      ]}
    >
      <ModalStyledText marginBottom="24">
        {t('Switch to another routine without affecting your normal schedule.')}
      </ModalStyledText>
      <FlexLayout mainaxis="column">
        <Layout marginBottom="32">
          <Layout width="100%" textAlignement="left" marginBottom="8">
            <Typography type="body2">{t('Switch to:')}</Typography>
          </Layout>

          {openDropdown === 'routine' && (
            // Dropdown triggered programmatically.
            <Dropdown
              testId="select-routine"
              actionElement={<React.Fragment />}
              showSelectedOption
              maxHeight={240}
              onChange={onChangeSelectedRoutine}
              active
              onActiveChange={(isActive: boolean) => {
                setOpenDropdown(isActive ? 'routine' : undefined);
              }}
            >
              {routinesList.map(routine => (
                <DropdownOption
                  key={routine.uid}
                  text={routine.name}
                  value={routine.uid}
                  selected={routine.uid === selectedRoutine?.uid}
                  icon={
                    <RoutineIcon
                      size="small"
                      color={routine.color}
                      icon={getRoutinesIcon(routine.icon)}
                      showBadge={doesRoutineConsumeDailyTimeQuota(routine)}
                    />
                  }
                />
              ))}
            </Dropdown>
          )}
          <ActionInput
            icon={<Icon type={IconType.angleDown} size={IconSize.lg} />}
            iconPosition={ActionInputIconPosition.right}
            block
            readOnly
            type="text"
            testId="select-routine"
            placeholder={t('Select routine')}
            text={selectedRoutine?.name}
            iconLeft={
              selectedRoutine ? (
                <RoutineIcon
                  size="small"
                  color={selectedRoutine.color}
                  icon={getRoutinesIcon(selectedRoutine.icon)}
                  showBadge={doesRoutineConsumeDailyTimeQuota(selectedRoutine)}
                />
              ) : undefined
            }
            onClick={() => {
              setOpenDropdown('routine');
            }}
          />
        </Layout>
        <Layout marginBottom="16">
          <Layout width="100%" textAlignement="left" marginBottom="8">
            <Typography type="body2">{t('Apply for:')}</Typography>
          </Layout>

          {openDropdown === 'duration' && (
            // Dropdown triggered programmatically.
            <Dropdown
              testId="select-duration"
              actionElement={<React.Fragment />}
              showSelectedOption
              maxHeight={240}
              onChange={onChangeDurationChoice}
              active
              onActiveChange={(isActive: boolean) => {
                setOpenDropdown(isActive ? 'duration' : undefined);
              }}
            >
              {getDurationChoiceOptions().map(([key, valueFn]) => (
                <DropdownOption
                  key={key}
                  value={key}
                  text={valueFn()}
                  selected={key === selectedDuration}
                />
              ))}
            </Dropdown>
          )}

          <ActionInput
            icon={<Icon type={IconType.angleDown} size={IconSize.lg} />}
            iconPosition={ActionInputIconPosition.right}
            block
            readOnly
            type="text"
            text={
              selectedDuration ? durationOptions[selectedDuration]() : undefined
            }
            testId="select-duration"
            placeholder={t('Select duration')}
            onClick={() => {
              setOpenDropdown('duration');
            }}
          />
        </Layout>
        {isCustomDuration ? (
          <Layout marginBottom="16" testId="custom-duration">
            <DurationInput
              testId="custom-duration"
              className="par-routine-switch-modal__duration-input"
              labels={{
                hours: t('h'),
                minutes: t('m'),
              }}
              value={duration}
              onChange={value => setDuration(value)}
            />
          </Layout>
        ) : null}
      </FlexLayout>
    </Modal>
  );
};
export default RoutineSwitchModal;
