import { isNil } from 'ramda';
/**
 * Given a value and a object of possible values, return the key if the value
 * is in the object, otherwise return null
 *
 * @param value - value to check
 * @param restrictions - possible values
 */
export const restrictKeyFromValue = <
  T extends Record<string | number | symbol, unknown>,
  K extends keyof T = keyof T,
  V extends T[K] | unknown = unknown
>(
  value: V,
  restrictions: T
): K | null => {
  if (isNil(value)) {
    return null;
  }
  const foundEntry = Object.entries(restrictions).find(
    ([, restrictionValue]) => restrictionValue === value
  );

  return foundEntry ? (foundEntry[0] as K) : null;
};

/**
 * Given a key and a object of possible values, return the value if the key is
 * in the object, otherwise return null
 *
 * @param key - key to check
 * @param restrictions - possible values
 */
export const restrictValueFromKey = <
  T extends Record<string | number | symbol, unknown>,
  K extends keyof T | string | number | symbol = keyof T
>(
  key: K | undefined,
  restrictions: T
): T[K] | null => {
  if (isNil(key) || isNil(restrictions[key])) {
    return null;
  }

  return restrictions[key] as T[K];
};
