import * as React from 'react';
import { PageTitle } from 'styleguide-react';

// eslint-disable-next-line react/prefer-stateless-function
class KidLocationsHistoryItemHeader extends React.Component<{
  day: string;
  hour: string;
  onClick: () => any;
}> {
  render() {
    const { day, hour, onClick } = this.props;
    return (
      <PageTitle
        title={`${day} - ${hour}`}
        testId="pageTitle"
        size="small"
        actions={[{ actionName: 'back', onClick }]}
      />
    );
  }
}

export default KidLocationsHistoryItemHeader;
