import * as React from 'react';
import {
  Card,
  FlexLayout,
  Icon,
  IconColor,
  IconSize,
  IconType,
} from 'styleguide-react';
import { t } from '../../../lib/i18n';

type SignatureAdditionalLinkCardProps = {
  link: string;
  text: string;
  onClick: () => void;
};

const SignatureAdditionalLinkCard: React.FunctionComponent<
  SignatureAdditionalLinkCardProps
> = ({ link, text, onClick }: SignatureAdditionalLinkCardProps) => (
  <div className="SignatureAdditionalLinkCard">
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <Card fullWidth>
        <FlexLayout mainaxis="row" className="container">
          <span className="link-text">{t(text)}</span>
          <Icon
            type={IconType.angleRight}
            size={IconSize.lg}
            color={IconColor.grayLight}
          />
        </FlexLayout>
      </Card>
    </a>
  </div>
);

export default SignatureAdditionalLinkCard;
