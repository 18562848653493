import {
  hasProfileBeenDelegated,
  isProfileDelegatedToThisAccount,
  isValidProfileForDelegation,
} from '../businessLogic/delegation/delegation';
import { isProfileLinkedWithLinewizeStudent } from '../selectors/profile';
import {
  getIsFetchingStudentPolicy,
  isDelegationEnabled,
  isStudentOptInForDelegation,
  isStudentOptInForDelegationForThisAccount,
} from '../selectors/studentPolicies';
import State from '../store/state';

export const showRulesNavigationToStudentProfile = (
  state: State,
  profileId: number
): boolean => {
  if (getIsFetchingStudentPolicy(state)) return false;

  const isLinkedWithSchool = Boolean(
    isProfileLinkedWithLinewizeStudent(state, profileId)
  );
  const isProfileDelegationEnabled = isDelegationEnabled(state);
  const isOptInForDelegation = isStudentOptInForDelegation(state);
  const isOptInInThisAccount = isStudentOptInForDelegationForThisAccount(state);

  const showNavigationToRules =
    isValidProfileForDelegation(
      isProfileDelegationEnabled,
      isLinkedWithSchool
    ) &&
    (!hasProfileBeenDelegated(
      isProfileDelegationEnabled,
      isOptInForDelegation,
      isLinkedWithSchool
    ) ||
      isProfileDelegatedToThisAccount(
        isProfileDelegationEnabled,
        isOptInInThisAccount,
        isLinkedWithSchool
      ));

  return showNavigationToRules;
};
