import { connect } from 'react-redux';
import {
  openSchoolSummaryInfoModalFor,
  openSeeAllCategories,
} from '../../actions/SchoolActions';
import StudentCategoriesCard from '../../components/Schools/StudentCategoriesCard';
import { StudentSummaryCardMaxItems } from '../../constants';
import { StudentSummaryFetchError } from '../../ducks/types/action/StudentSummaryAction.types';
import {
  getStudentSummaryCategories,
  getSummaryFetchError,
} from '../../selectors/studentSummary';
import State, { Dispatch } from '../../store/state';

const mapStateToProps = (state: State, props) => ({
  categoriesActivity: getStudentSummaryCategories(state),
  maxCategoriesActivityItems:
    props.maxCategoriesActivityItems || StudentSummaryCardMaxItems.categories,
  single: props.single || false,
  isFeatureDisabled:
    getSummaryFetchError(state) === StudentSummaryFetchError.FeatureDisabled,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClickInfo: () => {
    dispatch(openSchoolSummaryInfoModalFor('MostUsedCategories'));
  },
  onClickSeeAll: () => dispatch(openSeeAllCategories()),
});

const StudentCategoriesCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentCategoriesCard);

export type StudentCategoriesCardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default StudentCategoriesCardContainer;
