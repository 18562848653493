import { connect } from 'react-redux';
import OnboardingWrapper from '../../components/Onboarding/Views/OnboardingWrapper';
import { ScreenSize } from '../../components/RenderWhen/RenderWhen';
import { sideBarStateChanged, sideBarStateModeChanged } from '../../ducks/ui';
import { trackOpenedBurgerMenu } from '../../helpers/analytics';
import { t } from '../../lib/i18n';
import store from '../../store';
import { Dispatch } from '../../store/state';
import SchoolsOnboardingErrorWrongInfo from '../../components/Schools/SchoolsOnboardingErrorWrongInfo';
import { SchoolsOnboardingErrorContentDispatchProps } from '../../components/Schools/SchoolsOnboardingErrorContent';
import { createMySchoolEmailHref } from '../../helpers/email';
import { getMultiPlatformNavigation } from '../../helpers/multiPlatformNavigation';

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: Dispatch
): SchoolsOnboardingErrorContentDispatchProps => ({
  onClickContactUs: (event: Event): void => {
    const navigate = getMultiPlatformNavigation(event);
    return navigate({
      type: 'location:href',
      src: createMySchoolEmailHref(
        t('Error school onboarding connecting child')
      ),
    });
  },
  onClickNavigateBack: () => {
    const navigate = getMultiPlatformNavigation();
    dispatch(
      navigate({
        type: 'inner:replace',
        src: '/onboarding-schools/link-students',
      })
    );
  },
});

const SchoolsOnboardingErrorWrongInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolsOnboardingErrorWrongInfo);

export default OnboardingWrapper({
  configuration: [
    {
      screenSize: ScreenSize.Desktop,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: true,
      addMarginTopToCenter: true,
    },
    {
      title: t('Set up'),
      screenSize: ScreenSize.MobileOrTablet,
      columns: 1,
      mainInsideCard: true,
      cardCentered: true,
      hidePageTitle: false,
    },
  ],
  actions: [
    {
      actionName: 'menu',
      onClick: () => {
        trackOpenedBurgerMenu();
        store.dispatch(sideBarStateModeChanged('onboarding'));
        return store.dispatch(sideBarStateChanged(true));
      },
      screenSize: ScreenSize.MobileOrTablet,
    },
  ],
})(SchoolsOnboardingErrorWrongInfoContainer);
