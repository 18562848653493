import { connect } from 'react-redux';
import {
  getAccountCurrentTime,
  getLicense,
  getProfileCount,
  getTimezone,
} from '../../selectors';
import State from '../../store/state';
import { getAccountDeviceCount } from '../../selectors/stateSelectors/account';
import Topbar from '../../components/Topbar/Topbar';

const mapStateToProps = (state: State) => {
  const license = getLicense(state);
  const currentTime = getAccountCurrentTime(state);
  const timezone = getTimezone(state) as string;
  const profileCount = getProfileCount(state);
  const deviceCount = getAccountDeviceCount(state) as number;

  return {
    license,
    profileCount,
    deviceCount,
    currentTime,
    timezone,
  };
};

const TopbarContainer = connect(mapStateToProps, null)(Topbar);

export type TopbarProps = ReturnType<typeof mapStateToProps>;

export default TopbarContainer;
