import * as React from 'react';
import { Icon, IconColor, IconType } from 'styleguide-react';
import { classNames } from '../../../../helpers';

interface UnifiedNowCardIconProps {
  iconType: typeof IconType[keyof typeof IconType];
  iconColor?: IconColor;
  iconClassName?: string;
}

const UnifiedNowCardIcon = ({
  iconType,
  iconColor,
  iconClassName,
}: UnifiedNowCardIconProps) => {
  return (
    <Icon
      type={iconType}
      color={iconColor}
      circle
      className={classNames('par-unified-now-card__icon', iconClassName)}
      testId="unified-now-card-icon"
    />
  );
};

export default UnifiedNowCardIcon;
